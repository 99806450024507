import React, { useContext } from 'react';
import { Box, Container, Grid, useMediaQuery, useTheme } from '@mui/material';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Button, Typography } from '../../../../../atoms';
import { Chip } from '../../../../../moleculas';
import { UserContext } from '../../../../../../context';
import { useLandingPageConfig } from '../../../../../../utils';
import { RolesMap } from '../../../../../../constants/enums';
import { ReactComponent as ExploreMoreDesktopIllustration } from '../../../../../../resources/images/opportunities/landing-page/opportunities-explore-more-desktop.svg';
import { ReactComponent as ExploreMoreMobileIllustration } from '../../../../../../resources/images/opportunities/landing-page/opportunities-explore-more-mobile.svg';
import { ReactComponent as ExploreMoreDesktopParentIllustration } from '../../../../../../resources/images/opportunities/landing-page/opportunities-explore-more-parent.svg';
import { ReactComponent as ExploreMoreDesktopTeacherIllustration } from '../../../../../../resources/images/opportunities/landing-page/opportunities-explore-more-teacher.svg';

const ScreenMode = {
  DESKTOP: 'desktop',
  MOBILE: 'mobile',
};

const OpportunitiesExploreMoreSectionConfig = [
  {
    buttonTitle: 'Go to Train AYO',
    chipLabel: 'For students',
    className: 'student',
    description: 'The mystery cards are waiting for you in Train AYO',
    title: 'Eager to explore your opportunities?',
    userRole: RolesMap.STUDENT,
    illustration: {
      loggedIn: {
        [ScreenMode.DESKTOP]: ExploreMoreDesktopIllustration,
        [ScreenMode.MOBILE]: ExploreMoreMobileIllustration,
      },
      loggedOut: {
        [ScreenMode.DESKTOP]: ExploreMoreDesktopIllustration,
        [ScreenMode.MOBILE]: ExploreMoreMobileIllustration,
      },
    },
  },
  {
    buttonTitle: 'Go to (My) students',
    chipLabel: 'For educators',
    className: 'teacher',
    description: 'Check students’ profiles!',
    title: 'Wondering which opportunities your students explore in AYO?',
    illustration: {
      loggedIn: {
        [ScreenMode.DESKTOP]: ExploreMoreDesktopIllustration,
        [ScreenMode.MOBILE]: ExploreMoreMobileIllustration,
      },
      loggedOut: {
        [ScreenMode.DESKTOP]: ExploreMoreDesktopTeacherIllustration,
        [ScreenMode.MOBILE]: ExploreMoreDesktopTeacherIllustration,
      },
    },
    userRole: RolesMap.TEACHER,
  },
  {
    buttonTitle: 'Go to Family feed',
    chipLabel: 'For parents',
    className: 'parent',
    description: 'Check your kid(s)’ profile!',
    title: 'Fancy knowing which opportunities your kid(s) explored in AYO?',
    illustration: {
      loggedIn: {
        [ScreenMode.DESKTOP]: ExploreMoreDesktopIllustration,
        [ScreenMode.MOBILE]: ExploreMoreMobileIllustration,
      },
      loggedOut: {
        [ScreenMode.DESKTOP]: ExploreMoreDesktopParentIllustration,
        [ScreenMode.MOBILE]: ExploreMoreDesktopParentIllustration,
      },
    },
    userRole: RolesMap.GUARDIAN,
  },
];

const OpportunitiesExploreMoreSection = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const isWidthUpMd = useMediaQuery(theme.breakpoints.up('md'));

  const { state: userState } = useContext(UserContext);
  const userRole = userState?.profile?.role;

  const { landingPageLoginSectionConfig, landingPageRedirectHandler } = useLandingPageConfig(
    OpportunitiesExploreMoreSectionConfig,
  );

  if (!landingPageLoginSectionConfig?.length) {
    return null;
  }

  return (
    <Box py={isWidthUpSm ? 10 : 5}>
      <Container>
        <Grid container spacing={3}>
          {landingPageLoginSectionConfig.map((sectionItem, index) => {
            const screenMode = isWidthUpSm ? ScreenMode.DESKTOP : ScreenMode.MOBILE;
            const Illustration = !userRole
              ? sectionItem.illustration.loggedOut[screenMode]
              : sectionItem.illustration.loggedIn[screenMode];
            return (
              <Grid key={sectionItem.className} item sm={index ? 6 : 12} xs={12}>
                <Box
                  className={classnames(
                    'ayo-opportunities-explore-more-section',
                    `ayo-opportunities-explore-more-section--${sectionItem.className}`,
                    {
                      [`ayo-opportunities-explore-more-section--${sectionItem.className}--logged-out`]:
                        !userRole,
                    },
                  )}
                >
                  <Box mt={5} mx={isWidthUpMd ? 5 : 2} my={5}>
                    <Chip label={t(sectionItem.chipLabel)} />
                    <Typography component="h2" isLightText={!index} paragraph variant="h2">
                      {t(sectionItem.title)}
                    </Typography>
                    <Typography component="p" isLightText={!index} variant="subtitle1">
                      {t(sectionItem.description)}
                    </Typography>
                  </Box>
                  <Box mb={index && isWidthUpMd ? 2 : 0} width="100%">
                    <Button
                      className={classnames({
                        'ayo-opportunities-explore-more-section__button': !index,
                      })}
                      gaLabel={userRole ? sectionItem.buttonTitle : sectionItem.label}
                      onClick={() =>
                        landingPageRedirectHandler(
                          sectionItem.loginProvider,
                          sectionItem.loginRedirectKey,
                          sectionItem.opportunitiesRedirectUrl,
                        )
                      }
                      variant={index ? 'secondary' : 'text'}
                    >
                      {t(userRole ? sectionItem.buttonTitle : sectionItem.label)}
                    </Button>
                    <Box
                      className={classnames({
                        [`ayo-opportunities-explore-more-section--${sectionItem.className}--logged-out__illustration-container`]:
                          !userRole,
                      })}
                      mt={3}
                    >
                      <Illustration
                        className={classnames({
                          'ayo-svg-illustration': !index,
                        })}
                      />
                    </Box>
                  </Box>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </Box>
  );
};

export default OpportunitiesExploreMoreSection;
