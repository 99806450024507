import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button } from '../../atoms';
import { rootRoute } from '../../../constants/routes';

const PreviousPageButton = ({ variant }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const goBackButtonHandler = () => {
    if (history.length > 2) history.goBack();
    else history.replace(rootRoute);
  };

  return (
    <Button
      gaLabel="Back to the previous page"
      id="goBackButton"
      isAdaptive
      onClick={goBackButtonHandler}
      variant={variant}
    >
      {t('Back to previous page')}
    </Button>
  );
};

PreviousPageButton.propTypes = {
  variant: PropTypes.oneOf(['primary', 'secondary', 'text']),
};

PreviousPageButton.defaultProps = {
  variant: 'primary',
};

export default PreviousPageButton;
