import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { DeviceTypesMap } from './constants/enums';

const getI18NextConfig = (translationsUrl) => ({
  backend: {
    loadPath: translationsUrl,
  },
  debug: process.env.NODE_ENV !== 'production',
  detection: {
    caches:
      localStorage.getItem('ayo_device_type') === DeviceTypesMap.PRIVATE
        ? ['localStorage']
        : ['sessionStorage'],
    lookupLocalStorage: 'i18nextLng',
    lookupSessionStorage: 'i18nextLng',
  },
  fallbackLng: 'en',
  initImmediate: false, // TODO: In new version of i18next we set this to false for loading translations without setTimeout
  interpolation: {
    escapeValue: false,
  },
  nonExplicitSupportedLngs: true,
  supportedLngs: ['en', 'es'],
});

i18n
  .use(Backend)
  .use(detector)
  .use(initReactI18next)
  .init(getI18NextConfig('/locales/{{lng}}.json'));

export default i18n;
