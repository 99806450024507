import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CloseIcon } from '../../../resources/icons/close_red.svg';
import EditingButton from '../editing-button/EditingButton';

const DeleteItemWrapper = ({ ariaLabel, className, disabled, gaLabel, mainContent, onDelete }) => {
  const [isActive, setIsActive] = useState(null);
  const { t } = useTranslation();
  const wrapperRef = useRef();

  return !disabled ? (
    <Box
      ref={wrapperRef}
      className={classnames('ayo-delete-item-wrapper', className)}
      onBlur={() => setIsActive(false)}
      onFocus={() => setIsActive(true)}
      onKeyDown={(e) => {
        if (e.key === 'Delete') {
          onDelete();
        }
      }}
      onMouseLeave={() => setIsActive(false)}
      onMouseOver={() => {
        if (!isActive) {
          setIsActive(true);
        }
      }}
      tabIndex="0"
    >
      <span aria-label={ariaLabel} role="button">
        {mainContent}
      </span>
      <span className="sr-only">{t('Press delete key to remove element')}</span>
      {isActive && (
        <Box className="ayo-delete-item-wrapper__delete-button">
          <EditingButton
            gaLabel={gaLabel}
            icon={<CloseIcon />}
            iconPosition="end"
            onClick={onDelete}
            tabIndex={-1}
          />
        </Box>
      )}
    </Box>
  ) : (
    mainContent
  );
};

DeleteItemWrapper.propTypes = {
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  gaLabel: PropTypes.string,
  mainContent: PropTypes.node.isRequired,
  onDelete: PropTypes.func.isRequired,
};

DeleteItemWrapper.defaultProps = {
  ariaLabel: '',
  className: '',
  disabled: false,
  gaLabel: '',
};

export default DeleteItemWrapper;
