import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import classNames from 'classnames';

const rootSpacingValue = 8;
const rootGridSize = 12;
const sidesCount = 2;

const setGridRowEnd = (gridItemRef, spacingY) => {
  setTimeout(() => {
    if (gridItemRef.current) {
      const gridRowEnd = Math.round(
        gridItemRef.current.firstChild.clientHeight + spacingY * rootSpacingValue,
      );
      gridItemRef.current.style.setProperty('grid-row-end', `span ${gridRowEnd}`);
    }
  }, 0);
};

const DynamicMasonryGridItem = ({ children, className, spacingX, spacingY }) => {
  const gridItemRef = useRef(null);
  const setDynamicMasonryGridRowEnd = useCallback(() => {
    setGridRowEnd(gridItemRef, spacingY);
  }, [spacingY]);

  useEffect(() => {
    if (gridItemRef.current) {
      const marginX = (spacingX * rootSpacingValue) / sidesCount;
      const marginY = (spacingY * rootSpacingValue) / sidesCount;
      gridItemRef.current.style.setProperty('margin', `${marginY}px ${marginX}px`);
      setGridRowEnd(gridItemRef, spacingY);
    }
    window.addEventListener('resize', setDynamicMasonryGridRowEnd);
    return () => window.removeEventListener('resize', setDynamicMasonryGridRowEnd);
  }, [children, setDynamicMasonryGridRowEnd, spacingX, spacingY]);

  return (
    <Box
      ref={gridItemRef}
      className={`${classNames(`ayo-dynamic-grid__item`, { [`${className}`]: className })}`}
    >
      {children}
    </Box>
  );
};

DynamicMasonryGridItem.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  spacingX: PropTypes.number.isRequired,
  spacingY: PropTypes.number.isRequired,
};

DynamicMasonryGridItem.defaultProps = {
  className: '',
};

const DynamicGrid = ({ gridItems, columnsCount, spacingX, spacingY, isHorizontalOrder }) => {
  const gridRef = useRef(null);
  const columnArray = Array.from({ length: columnsCount });

  useEffect(() => {
    if (gridRef.current && !isHorizontalOrder) {
      gridRef.current.style.setProperty(
        'grid-template-columns',
        `repeat(auto-fill, ${100 / columnsCount}%)`,
      );
    }
  }, [columnsCount, isHorizontalOrder, spacingX]);

  return (
    <Box className="ayo-dynamic-grid" mb={isHorizontalOrder && -spacingY}>
      {isHorizontalOrder ? (
        <Grid className="ayo-dynamic-grid--horizontal" container spacing={spacingX}>
          {columnArray.map((column, columnIdx) => (
            <Grid key={`column-${columnIdx}`} item xs={rootGridSize / columnsCount}>
              {gridItems
                .filter((gridItem, gridIdx) => gridIdx % columnsCount === columnIdx)
                .map((element) => (
                  <Box key={element.key} mb={spacingY}>
                    {element}
                  </Box>
                ))}
            </Grid>
          ))}
        </Grid>
      ) : (
        <Box
          ref={gridRef}
          className="ayo-dynamic-grid--masonry"
          mx={-spacingX / sidesCount}
          my={-spacingY / sidesCount}
        >
          {gridItems.map((gridItem) => (
            <DynamicMasonryGridItem
              key={gridItem.key}
              className={gridItem.props.className}
              spacingX={spacingX}
              spacingY={spacingY}
            >
              {gridItem}
            </DynamicMasonryGridItem>
          ))}
        </Box>
      )}
    </Box>
  );
};

DynamicGrid.propTypes = {
  gridItems: PropTypes.arrayOf(PropTypes.node),
  columnsCount: PropTypes.number,
  spacingX: PropTypes.number,
  spacingY: PropTypes.number,
  isHorizontalOrder: PropTypes.bool,
};

DynamicGrid.defaultProps = {
  gridItems: [],
  columnsCount: 3,
  spacingX: 3,
  spacingY: 3,
  isHorizontalOrder: false,
};

export default DynamicGrid;
