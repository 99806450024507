import { useCallback } from 'react';

import { apiRoute, linksRoute } from '../../constants/routes';
import useAxios, { StatusCodeMap } from '../HttpClient';

const useLinksService = () => {
  const { post } = useAxios();

  const postLinks = useCallback(
    async (links) => {
      const { data } = await post({ enabled: true }, `${apiRoute}${linksRoute}`, links, {
        skipDefaultErrorHandlers: [StatusCodeMap.BAD_REQUEST, StatusCodeMap.SERVER_ERROR],
      });
      return data.entity;
    },
    [post],
  );

  return { postLinks };
};

export default useLinksService;
