import { useCallback } from 'react';

import useAxios, { StatusCodeMap } from '../HttpClient';
import { apiRoute, eventsRoute, resourcesRoute, studentsRoute } from '../../constants/routes';

const useEventsService = () => {
  const { get, put, post, httpDelete } = useAxios();

  const getEvents = useCallback(
    async (resourceType, resourceId, page, pageSize) => {
      const { data } = await get(
        { enabled: true },
        `${apiRoute}${eventsRoute}?resourceType=${resourceType}&page=${page}&pageSize=${
          pageSize || 20
        }`,
      );

      return data.entity;
    },
    [get],
  );

  const getStudentEvents = useCallback(
    async (resourceType, resourceId, page, pageSize, studentId) => {
      const { data } = await get(
        { enabled: true },
        `${apiRoute}${eventsRoute}${studentsRoute}/${studentId}?resourceType=${resourceType}&page=${page}&pageSize=${
          pageSize || 20
        }`,
      );

      return data.entity;
    },
    [get],
  );

  const getEventsByResourceId = useCallback(
    async (resourceType, resourceId, page, pageSize) => {
      const { data } = await get(
        { enabled: true },
        `${apiRoute}${eventsRoute}${resourcesRoute}/${resourceId}?resourceType=${resourceType}&page=${page}&pageSize=${
          pageSize || 20
        }`,
      );

      return data.entity;
    },
    [get],
  );

  const getEventById = useCallback(
    async (eventId) => {
      const { data } = await get({ enabled: true }, `${apiRoute}${eventsRoute}/${eventId}`);

      return data.entity;
    },
    [get],
  );

  const postEvent = useCallback(
    async (eventBody) => {
      const { data } = await post({ enabled: true }, `${apiRoute}${eventsRoute}`, eventBody, {
        skipDefaultErrorHandlers: [
          StatusCodeMap.NOT_FOUND,
          StatusCodeMap.SERVER_ERROR,
          StatusCodeMap.BAD_REQUEST,
        ],
      });
      return data.entity;
    },
    [post],
  );

  const updateEvent = useCallback(
    async (eventId, eventBody) => {
      const { data } = await put(
        { enabled: true },
        `${apiRoute}${eventsRoute}/${eventId}`,
        eventBody,
        {
          skipDefaultErrorHandlers: [
            StatusCodeMap.NOT_FOUND,
            StatusCodeMap.SERVER_ERROR,
            StatusCodeMap.BAD_REQUEST,
          ],
        },
      );
      return data.entity;
    },
    [put],
  );

  const deleteEvent = useCallback(
    (eventId) =>
      httpDelete({ enabled: true }, `${apiRoute}${eventsRoute}/${eventId}`, undefined, {
        skipDefaultErrorHandlers: [
          StatusCodeMap.BAD_REQUEST,
          StatusCodeMap.SERVER_ERROR,
          StatusCodeMap.NOT_FOUND,
        ],
      }),
    [httpDelete],
  );

  return {
    deleteEvent,
    getEventById,
    getEvents,
    getEventsByResourceId,
    getStudentEvents,
    postEvent,
    updateEvent,
  };
};

export default useEventsService;
