import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, Box } from '@mui/material';

import { AccordionClassGroupHeader, AccordionItem } from '../../../moleculas';
import StudentsGridItem from '../components/students-grid-item/StudentsGridItem';
import StudentsGridItemWrapper from '../components/students-grid-item-wrapper/StudentsGridItemWrapper';
import { PeriodDisplayName, Typography } from '../../../atoms';
import { getUniqueStudents } from '../../../../utils';

const studentGradeMap = {
  1: 'st',
  2: 'nd',
  3: 'rd',
};

export const CustomPeriodLabel = ({ label }) => <PeriodDisplayName period={label} />;

CustomPeriodLabel.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

const GradeGroupItem = ({
  accordionIndex,
  studentsList,
  grade,
  studentAriaLabelConstructor,
  studentCardRedirectLink,
  studentStatusIcon,
  studentWrapperConstructor,
  CustomGradeLabel,
}) => {
  const { t } = useTranslation();

  const gradeLabel = `${grade}${
    !Number.isNaN(parseInt(grade, 10)) ? `${t(studentGradeMap[grade] || 'th')} ` : ''
  }${!Number.isNaN(parseInt(grade, 10)) ? `${t('grade')}` : ''} `;

  const hasSubGroups = !Array.isArray(studentsList);

  const studentsCount = hasSubGroups
    ? getUniqueStudents(Object.values(studentsList).flat()).length
    : studentsList.length;
  const getSummaryLabel = useCallback(
    (count, courseName) =>
      t('accordion class group header label', { context: 'student', count, courseName }),
    [t],
  );

  return (
    <AccordionItem
      bodyEl={
        <Grid container>
          {hasSubGroups ? (
            <Grid item xs={12}>
              {Object.entries(studentsList).map(([groupName, groupStudents]) => (
                <Box key={groupName} mb={2}>
                  <Typography isLabel variant="body2">
                    {groupName}
                  </Typography>
                  <Grid container>
                    {groupStudents.map((item) => (
                      <StudentsGridItemWrapper
                        key={item.id}
                        studentId={item.id}
                        studentWrapperConstructor={studentWrapperConstructor}
                      >
                        <StudentsGridItem
                          href={studentCardRedirectLink(item.id)}
                          item={item}
                          studentAriaLabel={studentAriaLabelConstructor?.(item.id)}
                          studentStatusIcon={studentStatusIcon?.(item.id)}
                        />
                      </StudentsGridItemWrapper>
                    ))}
                  </Grid>
                </Box>
              ))}
            </Grid>
          ) : (
            studentsList.map((item) => (
              <StudentsGridItemWrapper
                key={item.id}
                studentId={item.id}
                studentWrapperConstructor={studentWrapperConstructor}
              >
                <StudentsGridItem
                  href={studentCardRedirectLink(item.id)}
                  item={item}
                  studentAriaLabel={studentAriaLabelConstructor?.(item.id)}
                  studentStatusIcon={studentStatusIcon?.(item.id)}
                />
              </StudentsGridItemWrapper>
            ))
          )}
        </Grid>
      }
      className="grade-accordion"
      gaLabel="Grade"
      headerEl={
        <Box key={grade}>
          <AccordionClassGroupHeader count={studentsCount}>
            {CustomGradeLabel ? <CustomGradeLabel label={grade} /> : gradeLabel}
          </AccordionClassGroupHeader>
        </Box>
      }
      isDefaultExpanded={accordionIndex === 0}
      summaryLabel={getSummaryLabel(studentsCount, gradeLabel)}
    />
  );
};

GradeGroupItem.propTypes = {
  studentsList: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  grade: PropTypes.string.isRequired,
  studentCardRedirectLink: PropTypes.func.isRequired,
  accordionIndex: PropTypes.number.isRequired,
  studentStatusIcon: PropTypes.node,
  studentWrapperConstructor: PropTypes.func,
  studentAriaLabelConstructor: PropTypes.func,
  CustomGradeLabel: PropTypes.element,
};

GradeGroupItem.defaultProps = {
  studentStatusIcon: null,
  studentWrapperConstructor: null,
  studentAriaLabelConstructor: null,
  CustomGradeLabel: null,
};

const AccordionGradeView = ({
  students,
  studentCardRedirectLink,
  studentStatusIcon,
  studentWrapperConstructor,
  studentAriaLabelConstructor,
  CustomGradeLabel,
  showSubgroups,
}) => {
  const isSimpleStudentList = Array.isArray(students);

  return (
    <Grid
      container
      direction={isSimpleStudentList ? 'row' : 'column'}
      wrap={isSimpleStudentList ? 'wrap' : 'nowrap'}
    >
      {isSimpleStudentList
        ? students.map((student) => (
            <StudentsGridItemWrapper
              key={student.id}
              studentId={student.id}
              studentWrapperConstructor={studentWrapperConstructor}
            >
              <StudentsGridItem
                href={studentCardRedirectLink(student.id)}
                item={student}
                studentAriaLabel={studentAriaLabelConstructor?.(student.id)}
                studentStatusIcon={studentStatusIcon?.(student.id)}
              />
            </StudentsGridItemWrapper>
          ))
        : Object.entries(students).map(([grade, studentsList], index) => (
            <Box key={`${grade}-${index}`} mt={5}>
              <GradeGroupItem
                accordionIndex={index}
                CustomGradeLabel={CustomGradeLabel}
                grade={grade}
                studentAriaLabelConstructor={studentAriaLabelConstructor}
                studentCardRedirectLink={studentCardRedirectLink}
                studentsList={
                  showSubgroups
                    ? studentsList
                    : getUniqueStudents(Object.values(studentsList).flat())
                }
                studentStatusIcon={studentStatusIcon}
                studentWrapperConstructor={studentWrapperConstructor}
              />
            </Box>
          ))}
    </Grid>
  );
};

AccordionGradeView.propTypes = {
  students: PropTypes.instanceOf(Object).isRequired,
  studentCardRedirectLink: PropTypes.func.isRequired,
  studentStatusIcon: PropTypes.node,
  studentWrapperConstructor: PropTypes.func,
  studentAriaLabelConstructor: PropTypes.func,
  CustomGradeLabel: PropTypes.element,
  showSubgroups: PropTypes.bool,
};

AccordionGradeView.defaultProps = {
  studentStatusIcon: null,
  studentWrapperConstructor: null,
  studentAriaLabelConstructor: null,
  CustomGradeLabel: null,
  showSubgroups: false,
};

export default AccordionGradeView;
