import React, { useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Box, Divider } from '@mui/material';
import { Bar, BarChart, LabelList, ResponsiveContainer, XAxis, YAxis } from 'recharts';

import {
  GaActions,
  GaCategories,
  HighlightsInitiatives,
  SingleBarVariants,
  StudentFiltersSpecialValues,
} from '../../../../../constants/enums';
import { useSearchParams } from '../../../../../hooks';
import {
  GA,
  getFilterValueParts,
  isFilterItemDisabled,
  isSimpleFilterSelected,
  transformSearchToObject,
  updateAllOtherFilter,
  updatePendingFilter,
  updateRadioFilter,
  updateSimpleFilter,
} from '../../../../../utils';
import TooltipedLabel from '../single-bar-label/TooltipedLabel';
import SingleBarShape from '../single-bar-shape/SingleBarShape';
import { FilterUpdateActions } from '../../../../../hooks/use-filter-update-state/useFilterUpdateState';
import MultilineAxisLabel from '../multiline-axis-label/MultilineAxisLabel';

const getTooltipText = (payload, totalStudentsCount, t) => (
  <>
    {`${
      Object.values(StudentFiltersSpecialValues).includes(payload.name)
        ? t(`analytics.highlights.${payload.name}`)
        : payload.name
    } (${payload.value})`}
    <br />
    {payload.value === totalStudentsCount && t("Can't filter by all students")}
    {payload.value === 0 && t("Can't filter by 0 students")}
  </>
);

const BAR_MARGIN = { top: 64, bottom: 64, left: 16 };
const MAX_BAR_WIDTH = 40;
const BAR_RADIUS = 6;
const HighlightsBarChart = ({
  stats,
  totalStudentsCount,
  initiative,
  mainChartColor,
  pendingChartColor,
  onFiltersUpdate,
  defaultStats,
  shouldSortMainStats,
  displayAllOther,
  gaLabel,
  pendingKey,
  mainBarVariant,
  MainBarShape,
  mainStatsSorting,
  isRadioSelection,
}) => {
  const initiativeStats = useMemo(() => stats[initiative.toLowerCase()], [stats, initiative]);
  const mainInitiativeStats = useMemo(() => {
    const resultStats = { ...defaultStats, ...initiativeStats };
    delete resultStats[pendingKey];
    delete resultStats[StudentFiltersSpecialValues.ALL_OTHER];
    return resultStats;
  }, [defaultStats, initiativeStats, pendingKey]);

  const allOtherStats = useMemo(
    () => ({
      [StudentFiltersSpecialValues.ALL_OTHER]:
        initiativeStats[StudentFiltersSpecialValues.ALL_OTHER] || 0,
    }),
    [initiativeStats],
  );

  const pendingStats = useMemo(
    () => ({
      [pendingKey]: initiativeStats[pendingKey] || 0,
    }),
    [initiativeStats, pendingKey],
  );

  const mainBarData = useMemo(() => {
    const data = Object.entries({
      ...mainInitiativeStats,
      ...(displayAllOther ? allOtherStats : {}),
    }).reduce((acc, [name, value]) => [...acc, { name, value }], []);
    if (shouldSortMainStats || mainStatsSorting) {
      data.sort(
        mainStatsSorting ||
          ((a, b) => {
            if (a.name === StudentFiltersSpecialValues.ALL_OTHER) {
              return 1;
            }
            if (b.name === StudentFiltersSpecialValues.ALL_OTHER) {
              return -1;
            }
            return b.value - a.value;
          }),
      );
    }
    return data;
  }, [allOtherStats, displayAllOther, mainInitiativeStats, shouldSortMainStats, mainStatsSorting]);

  const pendingBarData = useMemo(
    () =>
      Object.entries(pendingStats).reduce((acc, [name, value]) => [...acc, { name, value }], []),
    [pendingStats],
  );

  const { t } = useTranslation();

  const tickFormatter = useCallback(
    (value) => {
      const result = Object.values(StudentFiltersSpecialValues).includes(value)
        ? t(`analytics.highlights.${value}`)
        : value;
      return result;
    },
    [t],
  );

  const search = useSearchParams();

  const filters = useMemo(() => transformSearchToObject(search), [search]);

  const history = useHistory();

  const onBarClick = useCallback(
    (barName) => {
      if (!onFiltersUpdate || isFilterItemDisabled(initiativeStats[barName], totalStudentsCount))
        return;
      let newFilters;
      if (barName === StudentFiltersSpecialValues.ALL_OTHER) {
        newFilters = updateAllOtherFilter(
          filters,
          initiative,
          Object.keys(mainInitiativeStats),
          stats,
        );
      } else {
        newFilters = isRadioSelection
          ? updateRadioFilter(barName, initiative, filters, stats)
          : updateSimpleFilter(
              barName,
              initiative,
              filters,
              Object.keys(mainInitiativeStats),
              stats,
            );
      }
      onFiltersUpdate({
        type: FilterUpdateActions.UPDATE,
        data: { filters, value: barName, category: initiative, stats, t },
      });
      history.replace({ search: new URLSearchParams(newFilters).toString() });
      GA.logInteraction({
        category: GaCategories.BEHAVIOR,
        action: GaActions.GRAPH_PART_CLICKED,
        label: `${gaLabel} - ${barName}`,
      });
    },
    [
      initiativeStats,
      totalStudentsCount,
      onFiltersUpdate,
      filters,
      initiative,
      stats,
      t,
      history,
      gaLabel,
      mainInitiativeStats,
      isRadioSelection,
    ],
  );

  const onPendingBarClick = useCallback(
    (barName) => {
      if (!onFiltersUpdate || isFilterItemDisabled(initiativeStats[barName], totalStudentsCount))
        return;
      const newFilters = updatePendingFilter(filters, initiative, stats);
      onFiltersUpdate({
        type: FilterUpdateActions.UPDATE,
        data: {
          filters,
          value: StudentFiltersSpecialValues.PENDING,
          category: initiative,
          stats,
          t,
        },
      });
      history.replace({ search: new URLSearchParams(newFilters).toString() });
      GA.logInteraction({
        category: GaCategories.BEHAVIOR,
        action: GaActions.GRAPH_PART_CLICKED,
        label: `${gaLabel} - ${barName}`,
      });
    },
    [
      initiativeStats,
      totalStudentsCount,
      filters,
      initiative,
      stats,
      onFiltersUpdate,
      t,
      history,
      gaLabel,
    ],
  );

  const maxBarHeight = useMemo(
    () => Math.max(...Object.values(initiativeStats)),
    [initiativeStats],
  );

  const { filtersList } = useMemo(() => getFilterValueParts(filters, stats), [filters, stats]);

  const isMainChartAvailable = useMemo(
    () => !!Object.keys(mainInitiativeStats).length,
    [mainInitiativeStats],
  );

  const isPendingChartAvailable = useMemo(() => true, []);

  return (
    <Box display="flex" flex="1" justifyContent="space-around">
      {isMainChartAvailable && (
        <ResponsiveContainer className="goals-chart-container" height="100%" width="85%">
          <BarChart data={mainBarData} margin={BAR_MARGIN}>
            <XAxis
              axisLine={false}
              dataKey="name"
              interval={0}
              tick={<MultilineAxisLabel variant={mainBarVariant} />}
              tickFormatter={tickFormatter}
              tickLine={false}
              type="category"
            />
            <YAxis domain={[0, maxBarHeight]} hide />
            <Bar
              dataKey="value"
              fill={mainChartColor}
              isAnimationActive={false}
              maxBarSize={MAX_BAR_WIDTH}
              onClick={(barData) => onBarClick(barData.name)}
              radius={[BAR_RADIUS, BAR_RADIUS, BAR_RADIUS, BAR_RADIUS]}
              shape={
                <MainBarShape
                  getTooltipText={getTooltipText}
                  isActive={(payload) =>
                    isSimpleFilterSelected(
                      payload.name,
                      initiative,
                      filters.filterCategory,
                      filtersList,
                    )
                  }
                  onClick={(barData) => onBarClick(barData.name)}
                  totalStudentsCount={totalStudentsCount}
                  variant={mainBarVariant}
                />
              }
            >
              <LabelList
                className="bar-label-outside"
                content={
                  <TooltipedLabel
                    getTooltipText={getTooltipText}
                    onClick={onBarClick}
                    totalStudentsCount={totalStudentsCount}
                  />
                }
                offset={16}
                position="top"
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      )}
      {isMainChartAvailable && isPendingChartAvailable && <Divider orientation="vertical" />}
      {isPendingChartAvailable && (
        <ResponsiveContainer className="goals-chart-container" height="100%" width="15%">
          <BarChart data={pendingBarData} margin={BAR_MARGIN}>
            <XAxis
              axisLine={false}
              dataKey="name"
              interval={0}
              tick={<MultilineAxisLabel />}
              tickFormatter={tickFormatter}
              tickLine={false}
              type="category"
            />
            <YAxis domain={[0, maxBarHeight]} hide />
            <Bar
              dataKey="value"
              fill={pendingChartColor}
              isAnimationActive={false}
              maxBarSize={MAX_BAR_WIDTH}
              onClick={(barData) => onPendingBarClick(barData.name)}
              radius={[BAR_RADIUS, BAR_RADIUS, BAR_RADIUS, BAR_RADIUS]}
              shape={
                <SingleBarShape
                  getTooltipText={getTooltipText}
                  isActive={() =>
                    isSimpleFilterSelected(
                      StudentFiltersSpecialValues.PENDING,
                      initiative,
                      filters.filterCategory,
                      filtersList,
                    )
                  }
                  onClick={(barData) => onPendingBarClick(barData.name)}
                  totalStudentsCount={totalStudentsCount}
                />
              }
            >
              <LabelList
                className="bar-label-outside"
                content={
                  <TooltipedLabel
                    getTooltipText={getTooltipText}
                    onClick={onPendingBarClick}
                    totalStudentsCount={totalStudentsCount}
                  />
                }
                offset={16}
                position="top"
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      )}
    </Box>
  );
};

HighlightsBarChart.propTypes = {
  stats: PropTypes.instanceOf(Object).isRequired,
  totalStudentsCount: PropTypes.number.isRequired,
  initiative: PropTypes.oneOf(Object.keys(HighlightsInitiatives)).isRequired,
  mainChartColor: PropTypes.string.isRequired,
  pendingChartColor: PropTypes.string.isRequired,
  onFiltersUpdate: PropTypes.func.isRequired,
  gaLabel: PropTypes.string.isRequired,
  defaultStats: PropTypes.instanceOf(Object),
  shouldSortMainStats: PropTypes.bool,
  displayAllOther: PropTypes.bool,
  pendingKey: PropTypes.string,
  mainBarVariant: PropTypes.oneOf(Object.values(SingleBarVariants)),
  MainBarShape: PropTypes.elementType,
  isRadioSelection: PropTypes.bool,
  mainStatsSorting: PropTypes.func,
};

HighlightsBarChart.defaultProps = {
  defaultStats: {},
  shouldSortMainStats: false,
  displayAllOther: false,
  pendingKey: StudentFiltersSpecialValues.PENDING,
  mainBarVariant: SingleBarVariants.DEFAULT,
  MainBarShape: SingleBarShape,
  isRadioSelection: false,
  mainStatsSorting: null,
};

export default HighlightsBarChart;
