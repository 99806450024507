import { useCallback } from 'react';

import useAxios, { StatusCodeMap } from '../HttpClient';
import { apiRoute, followUpRoute } from '../../constants/routes';
import { FollowUpTypesMap } from '../../constants/enums';

const useEndorsementsService = () => {
  const { get, httpDelete, post } = useAxios();

  const getEndorsementsQuestion = useCallback(
    async (endorsementReasonId, lang) => {
      const { data } = await get(
        { enabled: true },
        `${apiRoute}${followUpRoute}/question?resourceType=${FollowUpTypesMap.ENDORSEMENT}&lang=${lang}&resourceId=${endorsementReasonId}`,
      );
      return data.entity;
    },
    [get],
  );

  const postEndorsementReason = useCallback(
    (questionId, endorsementReasonId, endorsementReasons) =>
      post(
        { enabled: true },
        `${apiRoute}${followUpRoute}/question/${questionId}/resources/${endorsementReasonId}`,
        { answers: endorsementReasons },
        {
          skipDefaultErrorHandlers: [StatusCodeMap.SERVER_ERROR, StatusCodeMap.NOT_FOUND],
        },
      ),
    [post],
  );

  const deleteEndorsementReason = useCallback(
    (questionId, endorsementReasonId) =>
      httpDelete(
        { enabled: true },
        `${apiRoute}${followUpRoute}/question/${questionId}/resources/${endorsementReasonId}`,
        undefined,
        {
          skipDefaultErrorHandlers: [
            StatusCodeMap.BAD_REQUEST,
            StatusCodeMap.SERVER_ERROR,
            StatusCodeMap.NOT_FOUND,
          ],
        },
      ),
    [httpDelete],
  );

  return {
    getEndorsementsQuestion,
    postEndorsementReason,
    deleteEndorsementReason,
  };
};

export default useEndorsementsService;
