import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { ActionsMenu, Card, LinearProgress } from '../../atoms';
import { GA } from '../../../utils';
import { GaActions, GaCategories } from '../../../constants/enums';
import TextWithTooltip from '../tooltip-wrappers/text-with-tooltip/TextWithTooltip';
import { NewFeatureHotspot } from '../new-feature-hotspot/NewFeatureHotspot';

const CardRelativeContainer = ({ children }) => (
  <div className="ayo-clickable-card-container">{children}</div>
);

CardRelativeContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const ClickableCard = React.forwardRef(
  (
    {
      actionButtons,
      actions,
      ariaLabel,
      cardHeader,
      className,
      gaLabel,
      href,
      isActive,
      isDisabled,
      imageSrc,
      isNewTab,
      mainContent,
      mediaContent,
      newFeatureId,
      newFeatureLabel,
      onClick,
      progressValue,
      statusIcon,
      statusText,
      title,
      titleRowsCount,
      titleWrap,
    },
    ref,
  ) => {
    const { t } = useTranslation();

    const CardWrapper = actions ? CardRelativeContainer : React.Fragment;
    return (
      <CardWrapper>
        <Card
          ref={ref}
          aria-label={`${title} ${statusText || ''} ${ariaLabel ?? ''} ${
            isActive === null ? '' : t(isActive ? 'Filled profile' : 'Empty profile')
          }`}
          bottomRibbon={progressValue !== null ? <LinearProgress value={progressValue} /> : null}
          cardHeader={cardHeader}
          className={classnames('ayo-clickable-card', className, {
            'ayo-clickable-card--disabled': isDisabled,
            'ayo-clickable-card--inactive': !onClick && !href,
            'ayo-clickable-card--w-action-button': !!actionButtons,
          })}
          component={href ? RouterLink : 'button'}
          disabled={isDisabled}
          href={href}
          imageSrc={imageSrc}
          leftRibbon={isActive ? <Box className="ayo-clickable-card__leftRibbon" /> : null}
          mainContent={
            mainContent || (
              <Box
                alignItems="flex-start"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <div
                  className={classnames('ayo-clickable-card__content-container', {
                    'ayo-clickable-card__content-container--w-status-icon': !!statusIcon,
                  })}
                >
                  <Box display="flex" flexDirection="row" width="100%">
                    <TextWithTooltip
                      className="ayo-clickable-card__title"
                      noWrap={titleWrap}
                      rowsCount={titleRowsCount ?? (titleWrap ? 2 : 1)}
                      title={title}
                      titleVariant="subtitle2"
                      tooltipClassName="ayo-clickable-card__wrapped-text-tooltip"
                      typographyProps={{
                        align: 'left',
                        paragraph: !!statusText,
                      }}
                    />
                    {newFeatureId && (
                      <NewFeatureHotspot id={newFeatureId} isClickable label={newFeatureLabel} />
                    )}
                  </Box>
                  {statusText && (
                    <TextWithTooltip
                      className="ayo-clickable-card__status"
                      rowsCount={titleWrap ? 2 : 1}
                      title={statusText}
                      titleVariant="caption"
                      typographyProps={{ align: 'left', isLabel: true }}
                    />
                  )}
                </div>
                {statusIcon}
                {actions && <Box className="actions-spacer" ml={1} />}
              </Box>
            )
          }
          mediaContent={mediaContent}
          onClick={() => {
            if (gaLabel) {
              GA.logInteraction({
                category: GaCategories.BEHAVIOR,
                action: GaActions.CARD_CLICK,
                label: gaLabel,
              });
            }
            if (onClick) {
              onClick();
            }
          }}
          target={isNewTab ? '_blank' : '_self'}
          underline={isActive != null ? 'none' : undefined}
        />
        {actions && (
          <Box className="actions-container" ml={1}>
            <ActionsMenu
              gaLabel="Action menu"
              id={`clickable-card-actions--${title}`}
              menuItems={actions}
            />
          </Box>
        )}
        {actionButtons && <Box className="action-button-container">{actionButtons}</Box>}
      </CardWrapper>
    );
  },
);

ClickableCard.propTypes = {
  actionButtons: PropTypes.node,
  actions: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  ariaLabel: PropTypes.string,
  cardHeader: PropTypes.node,
  className: PropTypes.string,
  gaLabel: PropTypes.string,
  href: PropTypes.string,
  isActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
  imageSrc: PropTypes.string,
  isNewTab: PropTypes.bool,
  mainContent: PropTypes.node,
  mediaContent: PropTypes.elementType,
  newFeatureId: PropTypes.string,
  newFeatureLabel: PropTypes.string,
  onClick: PropTypes.func,
  progressValue: PropTypes.number,
  statusIcon: PropTypes.node,
  statusText: PropTypes.string,
  title: PropTypes.string,
  titleRowsCount: PropTypes.number,
  titleWrap: PropTypes.bool,
};

ClickableCard.defaultProps = {
  actionButtons: null,
  actions: null,
  ariaLabel: '',
  cardHeader: null,
  className: '',
  gaLabel: '',
  href: null,
  isActive: null,
  isDisabled: false,
  imageSrc: '',
  isNewTab: false,
  mainContent: null,
  mediaContent: null,
  newFeatureId: '',
  newFeatureLabel: '',
  onClick: null,
  progressValue: null,
  statusIcon: null,
  statusText: null,
  title: '',
  titleRowsCount: null,
  titleWrap: false,
};

export default ClickableCard;
