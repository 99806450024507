import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Button } from '../../../atoms';
import { PageWrapper } from '../../../organisms';
import { RolesMap } from '../../../../constants/enums';
import { trainAyoRoute } from '../../../../constants/routes';
import {
  AppActions,
  AppContext,
  QuestionnaireStateActions,
  QuestionnaireStateContext,
  UserContext,
} from '../../../../context';
import { useSearchParams } from '../../../../hooks';
import { useQuestionnaireService } from '../../../../services';
import {
  extractSkippedQuestions,
  getQuestionnaireType,
  isAnswersEmpty,
  isQuestionnaireCompleted,
  transformSearchToObject,
} from '../../../../utils';

import Questionnaire from './components/questionnaire/Questionnaire';

const TrainAyoQuestionnairePage = () => {
  const { i18n, t } = useTranslation();
  const history = useHistory();

  const { dispatch: dispatchAppState } = useContext(AppContext);
  const { state: userState } = useContext(UserContext);
  const { state: questionnaireState, dispatch: dispatchQuestionnaireState } =
    useContext(QuestionnaireStateContext);
  const { questionnaireId: activeQuestionnaireId, activeDependentProfile } = questionnaireState;

  const commonBackToConfig = useMemo(
    () => ({
      backToText: t('Back to Train AYO'),
      backToLink: trainAyoRoute,
    }),
    [t],
  );

  const search = useSearchParams();
  const { redirectTo } = useMemo(() => transformSearchToObject(search), [search]);
  const redirectRef = useRef();

  useEffect(() => {
    if (redirectTo) {
      redirectRef.current = redirectTo;
      history.replace({ search: null });
    }
  });

  const backToConfig = useMemo(
    () => ({
      [RolesMap.STUDENT]:
        userState.profile?.pendingQuestionnaires?.some(
          (questionnaire) => questionnaire.isSubmitted,
        ) || userState.profile?.completedQuestionnaires?.length > 0
          ? commonBackToConfig
          : null,
      [RolesMap.TEACHER]: commonBackToConfig,
      [RolesMap.ADMINISTRATOR]: commonBackToConfig,
      [RolesMap.GUARDIAN]: {
        backToText: t('Back to kid(s) selection'),
        backToLink: redirectRef.current,
      },
    }),
    [commonBackToConfig, userState.profile, t],
  );

  const [questions, setQuestions] = useState([]);
  const [questionnaireType, setQuestionnaireType] = useState(null);

  const { getQuestions, postQuestion } = useQuestionnaireService();

  const [skippedQuestionsIds, setSkippedQuestionsIds] = useState(null);

  const getQuestionnaire = useCallback(
    async (questionnaireId, personId) => {
      const questionnaireData = await getQuestions(
        questionnaireId,
        i18n.language,
        userState?.dependentProfiles ? personId : null,
      );
      const [lastQuestion] = questionnaireData.questions.slice(-1);
      const isQuestionnaireSubmitted =
        lastQuestion.skipped || !isAnswersEmpty(lastQuestion.answers);
      if (isQuestionnaireSubmitted) {
        if (questionnaireState.activeQuestionIds?.length) {
          questionnaireData.questions = questionnaireData.questions.filter(({ id }) =>
            questionnaireState.activeQuestionIds.some(
              (activeQuestionId) => id === activeQuestionId,
            ),
          );
        } else {
          const filteredQuestions = extractSkippedQuestions(questionnaireData.questions);
          const skippedQuestionsIdsData = filteredQuestions.map(({ id }) => id);
          setSkippedQuestionsIds(skippedQuestionsIdsData);
          // Need to store this data if changing lng  during the questionnaire
          dispatchQuestionnaireState({
            type: QuestionnaireStateActions.SET_ACTIVE_QUESTION_IDS,
            data: skippedQuestionsIdsData,
          });
        }
      }
      setQuestions(questionnaireData.questions);
      setQuestionnaireType(getQuestionnaireType(questionnaireData.title));
      return questionnaireData;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatchQuestionnaireState, getQuestions, i18n.language, userState?.dependentProfiles],
  );

  useEffect(() => {
    if (activeQuestionnaireId) {
      getQuestionnaire(activeQuestionnaireId, activeDependentProfile?.id);
    } else {
      history.push(trainAyoRoute);
    }
  }, [activeQuestionnaireId, activeDependentProfile?.id, getQuestionnaire, history, i18n.language]);

  const submitRedirectHandler = useCallback(
    () => history.push(userState?.dependentProfiles?.length ? redirectRef.current : trainAyoRoute),
    [history, userState?.dependentProfiles?.length],
  );

  const setShouldReloadQuestionnaireState = useCallback(
    (value) => {
      dispatchQuestionnaireState({
        type: QuestionnaireStateActions.SET_SHOULD_RELOAD_QUESTIONNAIRE_STATE,
        data: value,
      });
    },
    [dispatchQuestionnaireState],
  );

  const getSuccessSnackBarText = useCallback(
    (questionList, dependentProfileName) => {
      const dependentProfilesPendingQuestionnaires = userState?.dependentProfiles?.filter(
        (dependentProfile) => dependentProfile.pendingQuestionnaires.length,
      );

      return userState?.dependentProfiles &&
        (dependentProfilesPendingQuestionnaires?.length > 1 ||
          !isQuestionnaireCompleted(questionList))
        ? t('You have submitted the questionnaire about', {
            username: dependentProfileName,
          })
        : t('Thanks for your answers! Your input is valuable to AYO');
    },
    [t, userState?.dependentProfiles],
  );

  const proceedToQuestionnaireEnd = useCallback(
    (questionList, dependentProfileName) => {
      setShouldReloadQuestionnaireState(true);
      dispatchAppState({
        type: AppActions.SET_SNACKBAR_STATUS,
        data: {
          text: getSuccessSnackBarText(questionList, dependentProfileName),
          type: 'success',
        },
      });
      submitRedirectHandler();
    },
    [
      dispatchAppState,
      getSuccessSnackBarText,
      setShouldReloadQuestionnaireState,
      submitRedirectHandler,
    ],
  );

  const onRetryHandler = useCallback(
    (questionnaireId, answerRequestBody, questionList, dependentProfileName) => {
      postQuestion(questionnaireId, answerRequestBody)
        .then(() => proceedToQuestionnaireEnd(questionList, dependentProfileName))
        .catch(submitRedirectHandler);
    },
    [postQuestion, proceedToQuestionnaireEnd, submitRedirectHandler],
  );

  const onSubmitFailed = useCallback(
    (questionnaireId, answerRequestBody, questionList, dependentProfileName) => {
      setShouldReloadQuestionnaireState(true);
      dispatchAppState({
        type: AppActions.SET_SNACKBAR_STATUS,
        data: {
          text: t(`Couldn't submit the questionnaire, please try once more`),
          type: 'error',
          action: (
            <Button
              autoFocus
              onClick={() =>
                onRetryHandler(
                  questionnaireId,
                  answerRequestBody,
                  questionList,
                  dependentProfileName,
                )
              }
            >
              {t('Retry')}
            </Button>
          ),
        },
      });
      submitRedirectHandler();
    },
    [dispatchAppState, onRetryHandler, setShouldReloadQuestionnaireState, submitRedirectHandler, t],
  );

  const isGuardian = userState?.profile?.role === RolesMap.GUARDIAN;

  return (
    <PageWrapper
      backToLink={backToConfig[userState.profile?.role]?.backToLink}
      backToText={backToConfig[userState.profile?.role]?.backToText}
      className="ayo-questionnaire-page"
      mainElementClassName="ayo-questionnaire-page--main-wrapper"
    >
      {questions.length > 0 && (
        <Questionnaire
          key={questions[0].htmlLabel || questions[0].htmlDescription}
          canSwitchDisplayedQuestions={isGuardian}
          onQuestionsEnd={proceedToQuestionnaireEnd}
          onSubmitFailed={onSubmitFailed}
          questionnaireType={questionnaireType}
          questions={questions}
          skippedQuestionsIds={skippedQuestionsIds}
        />
      )}
    </PageWrapper>
  );
};

export default TrainAyoQuestionnairePage;
