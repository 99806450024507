import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

import { Typography } from '../../../../../../../atoms';
import { DaySchedule } from '../../../../../../../moleculas';
import { useLessonPlannerData } from '../../../../../../../../hooks';
import { Semesters } from '../../../../../../../../constants/enums';

const RotationDaysSchedule = ({ semester, schoolYear }) => {
  const { lessonPlannerData, setRotationDaysDaySchedule } = useLessonPlannerData();

  const { rotationDaysSchedule } = lessonPlannerData.stateData.semestersSchedule.find(
    (stateSemester) => stateSemester.semester === semester,
  );

  const { t } = useTranslation();

  return (
    <Box
      className="ayo-rotation-days-schedule"
      style={{
        gridTemplateColumns: `repeat(${rotationDaysSchedule.daysSchedule?.length}, 1fr)`,
      }}
    >
      {rotationDaysSchedule.daysSchedule.map((daySchedule, index) => (
        <Box key={daySchedule.rotationDay}>
          <Typography variant="subtitle2">{`${t('Day')} ${daySchedule.rotationDay}`}</Typography>
          <Box mt={2}>
            <DaySchedule
              dayIndex={index}
              onChange={setRotationDaysDaySchedule}
              periods={daySchedule.coursesPeriods}
              schoolYear={schoolYear}
              semester={semester}
            />
          </Box>
        </Box>
      ))}
    </Box>
  );
};

RotationDaysSchedule.propTypes = {
  semester: PropTypes.oneOf(Object.values(Semesters)).isRequired,
  schoolYear: PropTypes.number.isRequired,
};

export default RotationDaysSchedule;
