import React, { useState, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';

import { deepEqual } from '../../../../../../utils';

const CourseHighlight = ({ el }) => {
  const getSpotlightStyles = (element) => {
    if (!element) return {};
    const { height } = element.getBoundingClientRect();
    const { left, width } = document.querySelector('main').getBoundingClientRect();

    let positionTop = element.offsetTop;

    let { offsetParent } = element;

    while (offsetParent) {
      positionTop += offsetParent.offsetTop;
      offsetParent = offsetParent.offsetParent;
    }

    return {
      height,
      width,
      top: positionTop,
      left,
      position: 'absolute',
      backgroundColor: 'gray',
      pointerEvents: 'none',
    };
  };

  const [spotlightStyles, setSpotlightStyles] = useState({});

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const newStyles = getSpotlightStyles(el);
    if (!deepEqual(spotlightStyles, newStyles)) {
      setSpotlightStyles(newStyles);
    }
  });

  const [mouseOverSpotlight, setMouseOverSpotlight] = useState(false);
  const handleMouseMove = useCallback(
    (e) => {
      const { height, left, position, top, width } = spotlightStyles;

      const offsetY = position === 'fixed' ? e.clientY : e.pageY;
      const offsetX = position === 'fixed' ? e.clientX : e.pageX;
      const inSpotlightHeight = offsetY >= top && offsetY <= top + height;
      const inSpotlightWidth = offsetX >= left && offsetX <= left + width;
      const inSpotlight = inSpotlightWidth && inSpotlightHeight;

      if (inSpotlight !== mouseOverSpotlight) {
        setMouseOverSpotlight(inSpotlight);
      }
    },
    [mouseOverSpotlight, spotlightStyles],
  );

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, [handleMouseMove]);

  return ReactDOM.createPortal(
    <div
      className="overlay"
      style={{
        pointerEvents: mouseOverSpotlight ? 'none' : 'auto',
        height: document.querySelector('body').clientHeight,
      }}
    >
      <div className="highlight" style={spotlightStyles} />
    </div>,
    document.querySelector('body'),
  );
};

export default CourseHighlight;
