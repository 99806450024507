/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

import { Typography } from '../../atoms';
import { mediaBlock } from '../../../constants/propTypes';
import { MediaCard, YoutubeLinkButton } from '../../moleculas';

const MediaBlock = ({ config }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const isWidthUpMd = useMediaQuery(theme.breakpoints.up('md'));

  const mediaItems = [];

  config.items.forEach((item) => {
    mediaItems.push(
      <Box className="ayo-home-page__media-block--card" mb={isWidthUpMd ? 7 : 3}>
        <MediaCard
          articleLink={item.link}
          cardAction={t(item.actionTxt)}
          cardMediaImage={item.img}
          cardMediaImageAlt={t(item.imgAlt)}
          content={t(item.content)}
          contentLabel={t(item.contentLabel)}
          date={Date.parse(item.date)}
          mediaClickable={item.mediaClickable}
          pt={isWidthUpSm ? 4 : 3}
          sourceName={t(item.sourceName)}
          title={t(item.title)}
        />
      </Box>,
    );
  });

  return (
    <Box className="ayo-home-page__media-block" pt={isWidthUpMd ? 10 : 5}>
      <Container>
        <Box pt={isWidthUpMd ? 3 : 0}>
          <Typography variant="h1">{t(config.title)}</Typography>
          <Box mb={isWidthUpSm ? 5 : 3} pt={isWidthUpSm ? 3 : 2.4}>
            <Typography variant="body1">{t(config.subTitle)}</Typography>
          </Box>
        </Box>
      </Container>
      {mediaItems}
      <Box mb={isWidthUpSm ? 11 : 5} mt={isWidthUpSm ? 3 : 2}>
        <YoutubeLinkButton />
      </Box>
    </Box>
  );
};

MediaBlock.propTypes = {
  config: mediaBlock,
};

MediaBlock.defaultProps = {
  config: {},
};

export default MediaBlock;
