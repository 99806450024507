import React from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { ActionsMenu } from '../../../../atoms';
import { EditingButton } from '../../../../moleculas';
import { ClubsStatuses } from '../../../../../constants/club-hub';
import { ReactComponent as ActiveIcon } from '../../../../../resources/icons/check_circle_green.svg';
import { ReactComponent as DeclineIcon } from '../../../../../resources/icons/close_red.svg';

const ClubDataGridActions = ({ config }) => {
  const { t } = useTranslation();

  return (
    <Box
      className="ayo-club-hub__club-data-grid-actions"
      display="flex"
      justifyContent={config.isPending ? 'center' : 'flex-end'}
      width="100%"
    >
      {config.isPending ? (
        <Box
          className="ayo-club-hub__club-data-grid-actions__container"
          display="flex"
          flexDirection="row"
          gap={1}
          justifyContent="space-between"
          width="100%"
        >
          <EditingButton
            className={`ayo-club-hub__club-data-grid-actions--${ClubsStatuses.ACTIVE}`}
            gaLabel={config.activeButton.label}
            icon={<ActiveIcon />}
            iconPosition="end"
            onClick={config.activeButton.handler}
            text={t(config.activeButton.label)}
          />
          <EditingButton
            className={`ayo-club-hub__club-data-grid-actions--${ClubsStatuses.DECLINED}`}
            gaLabel={config.declinedButton.label}
            icon={<DeclineIcon />}
            iconPosition="end"
            onClick={config.declinedButton.handler}
            text={t(config.declinedButton.label)}
          />
        </Box>
      ) : (
        <ActionsMenu
          gaLabel="Club Hub action menu"
          id={`clubs-management-actions--${config.id}`}
          menuItems={[
            {
              text: t(config.label),
              icon: config.icon,
              handler: config.handler,
              id: config.id,
              gaLabel: config.label,
            },
          ]}
          noIconsRecolor
        />
      )}
    </Box>
  );
};

ClubDataGridActions.propTypes = {
  config: PropTypes.instanceOf(Object).isRequired,
};
export default ClubDataGridActions;
