import React from 'react';
import { Box, Breadcrumbs } from '@mui/material';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Link } from '../../atoms';
import { ReactComponent as NavigationBackChevron } from '../../../resources/icons/chevron_right.svg';
import TextWithTooltip from '../tooltip-wrappers/text-with-tooltip/TextWithTooltip';

const BreadcrumbsNavigation = ({ navItems }) => {
  const { t } = useTranslation();
  return (
    <Breadcrumbs
      aria-label={t('Breadcrumbs')}
      className="ayo-breadcrumb-navigation"
      separator={<NavigationBackChevron />}
    >
      {navItems.map(({ gaLabel, label, redirectUrl, cropped }) => (
        <Box key={label} minWidth={cropped ? 'initial' : 'max-content'}>
          {redirectUrl ? (
            <Link component={RouterLink} gaLabel={gaLabel} to={redirectUrl} underline="none">
              <TextWithTooltip title={label} />
            </Link>
          ) : (
            <TextWithTooltip title={label} titleVariant="body2" />
          )}
        </Box>
      ))}
    </Breadcrumbs>
  );
};

BreadcrumbsNavigation.propTypes = {
  navItems: PropTypes.arrayOf(
    PropTypes.shape({
      gaLabel: PropTypes.string,
      label: PropTypes.string,
      redirectUrl: PropTypes.string,
    }),
  ).isRequired,
};

export default BreadcrumbsNavigation;
