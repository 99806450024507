import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Collapse,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import PropTypes from 'prop-types';

import { DotIndicator, Typography } from '../../atoms';
import { LogoLink, NewFeatureHotspot } from '../../moleculas';
import { AppActions, AppContext, UserContext } from '../../../context';
import { NavItemsMap } from '../../../constants/pages';
import { useNotificationsData } from '../../../hooks';
import { NewFeaturesIdsMap } from '../../../tours/common/NewFeaturesItemsProvider';
import { AppRoutes, settingsPageRoute } from '../../../constants/routes';
import { RolesMap } from '../../../constants/enums';
import { ReactComponent as GearIcon } from '../../../resources/icons/gear.svg';
import { ReactComponent as ChevronUp } from '../../../resources/icons/chevron_up.svg';
import { ReactComponent as ChevronDown } from '../../../resources/icons/chevron_down.svg';

import NavItemTooltipWrapper from './components/nav-item-tooltip-wrapper/NavItemTooltipWrapper';

const settings = 'Settings';
const settingsId = 'nav-drawer-settings';

const NavItemConditions = {
  [RolesMap.GUARDIAN]: {
    [AppRoutes.TRAIN_AYO]: (userState) => !!userState.dependentProfiles.length,
  },
};

const NavItem = ({ item, isActiveItem, isFirst, handleNavItemClick }) => {
  const Icon = item.icon;
  const { state: userState } = useContext(UserContext);
  const hasPrivilege =
    !item.privileges ||
    item.privileges.some((privilege) => userState.profile?.privileges?.includes(privilege));
  const isItemVisible = !item.isHidden && hasPrivilege;

  const { t } = useTranslation();

  const { state: appState, dispatch: dispatchAppState } = useContext(AppContext);

  const isDropdownItem = !!item.subItems;
  const isOpened = isDropdownItem && appState.openedNavItem === item.value;

  const hasActiveSubItem =
    isDropdownItem && item.subItems.some((subItem) => isActiveItem(subItem.value));

  const openNavDropdown = () =>
    dispatchAppState({
      type: AppActions.SET_OPENED_NAV_ITEM,
      data: isOpened ? null : item.value,
    });

  useEffect(() => {
    // first dropdown item should be expanded by default
    if (isDropdownItem && isFirst && !appState.openedNavItem) {
      dispatchAppState({
        type: AppActions.SET_OPENED_NAV_ITEM,
        data: item.value,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isItemVisible ? (
    <NavItemTooltipWrapper isVisible={item.isWrapperVisible} itemText={item.text}>
      <div>
        <ListItem
          aria-current={isActiveItem(item.value) ? 'page' : false}
          button
          className={`${classNames({
            active: isActiveItem(item.value) || (!isOpened && hasActiveSubItem),
          })}`}
          disableRipple
          id={item.id}
          onClick={item.subItems ? openNavDropdown : () => handleNavItemClick(item.value)}
        >
          {Icon && (
            <ListItemIcon>
              <Icon />
            </ListItemIcon>
          )}
          <ListItemText>
            <Typography component="a" variant="subtitle2">
              {t(item.text)}
            </Typography>
          </ListItemText>
          {isDropdownItem && isOpened && (
            <ListItemIcon>
              <ChevronUp />
            </ListItemIcon>
          )}
          {isDropdownItem && !isOpened && (
            <ListItemIcon>
              <ChevronDown />
            </ListItemIcon>
          )}
          {item.indicators}
        </ListItem>
        {!!item.subItems && (
          <Collapse in={isOpened} unmountOnExit>
            <List className="inner-list items-list">
              {item.subItems.map((subItem) => (
                <NavItem
                  key={subItem.text}
                  handleNavItemClick={handleNavItemClick}
                  isActiveItem={isActiveItem}
                  item={subItem}
                />
              ))}
            </List>
          </Collapse>
        )}
      </div>
    </NavItemTooltipWrapper>
  ) : null;
};

const itemPropTypes = PropTypes.shape({
  text: PropTypes.string,
  icon: PropTypes.element,
  subItems: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  indicators: PropTypes.node,
  value: PropTypes.string,
  isWrapperVisible: PropTypes.bool,
  privileges: PropTypes.arrayOf(PropTypes.string),
  id: PropTypes.string,
  isHidden: PropTypes.bool,
});

NavItem.propTypes = {
  item: itemPropTypes.isRequired,
  isActiveItem: PropTypes.func.isRequired,
  isFirst: PropTypes.bool,
  handleNavItemClick: PropTypes.func.isRequired,
};

NavItem.defaultProps = {
  isFirst: false,
};

const NavDrawer = () => {
  const { state: appState, dispatch: dispatchAppState } = useContext(AppContext);
  const { state: userState } = useContext(UserContext);
  const { isNotificationsAvailable, isPortfolioHasUpdates } = useNotificationsData();
  const history = useHistory();
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpLg = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });

  const DynamicItems = useMemo(
    () => ({
      [RolesMap.STUDENT]: [
        {
          id: 'nav-drawer-my-portfolio',
          isWrapperVisible: isPortfolioHasUpdates,
          indicators: [
            <DotIndicator
              key="student-portfolio-indicator"
              invisible={!isPortfolioHasUpdates}
              overlap="circular"
            />,
          ],
        },
      ],
      [RolesMap.TEACHER]: [
        {
          id: 'nav-drawer-train-ayo',
          indicators: [
            <NewFeatureHotspot
              key="teacher-train-ayo-new-feature-tour"
              id={NewFeaturesIdsMap.PARENT_QUESTIONNAIRE}
              isClickable
              label={t('Train AYO')}
            />,
          ],
        },
      ],
    }),
    [t, isPortfolioHasUpdates],
  );

  const navItems = useMemo(() => {
    const role = userState.profile?.role;
    const staticItems = NavItemsMap[role]?.main;
    const dynamicItems = DynamicItems[role];
    const result = staticItems?.map((item) => {
      let mergedItem = item;
      dynamicItems?.some((dynamicItem) => {
        if (item.id === dynamicItem.id) {
          mergedItem = { ...item, ...dynamicItem };
          return true;
        }
        return false;
      });
      return mergedItem;
    });
    return result?.filter((item) => {
      const itemConditionEntry = Object.entries(NavItemConditions[role] || {}).find(
        ([itemValue]) => item.value === itemValue,
      );
      return itemConditionEntry ? itemConditionEntry[1](userState) : !!item;
    });
  }, [DynamicItems, userState]);

  const handleNavDrawerClose = useCallback(() => {
    dispatchAppState({
      type: AppActions.SET_NAV_DRAWER_OPEN,
      data: false,
    });
  }, [dispatchAppState]);

  const handleNavItemClick = useCallback(
    (value) => {
      if (!isWidthUpLg) {
        dispatchAppState({
          type: AppActions.SET_NAV_DRAWER_OPEN,
          data: false,
        });
      }
      history.push(value);
    },
    [dispatchAppState, history, isWidthUpLg],
  );
  const isActiveItem = (value) => value && history.location.pathname.includes(value.toLowerCase());
  const secondaryNavItems = NavItemsMap[userState.profile?.role]?.secondary;

  return navItems ? (
    <Drawer
      className="ayo-nav-drawer"
      ModalProps={{ disableEnforceFocus: true }}
      onClose={handleNavDrawerClose}
      open={isWidthUpLg || appState.navDrawerOpen}
      style={{ zIndex: 1200 }}
      variant={isWidthUpLg ? 'permanent' : 'temporary'}
    >
      <Box component="nav" height="100%">
        <List
          sx={{ height: '100%', boxSizing: 'border-box', display: 'flex', flexDirection: 'column' }}
        >
          <Box mb={2}>
            <LogoLink />
          </Box>
          <Box display="flex" flex="1" flexDirection="column" justifyContent="space-between">
            <Box className="items-list">
              {navItems.map((item, i) => (
                <NavItem
                  key={item.text}
                  handleNavItemClick={handleNavItemClick}
                  isActiveItem={isActiveItem}
                  isFirst={i === 0}
                  item={item}
                />
              ))}
            </Box>
            {(isNotificationsAvailable || secondaryNavItems?.length) && (
              <Box>
                <Divider />
                <Box className="items-list">
                  {secondaryNavItems?.map((item) => (
                    <NavItem
                      key={item.text}
                      handleNavItemClick={handleNavItemClick}
                      isActiveItem={isActiveItem}
                      item={item}
                    />
                  ))}
                  {isNotificationsAvailable && (
                    <ListItem
                      key={settings}
                      aria-current={isActiveItem(settings) ? 'page' : false}
                      button
                      className={`${classNames({
                        active: isActiveItem(settings),
                      })}`}
                      disableRipple
                      id={settingsId}
                      onClick={() => handleNavItemClick(settingsPageRoute)}
                    >
                      <ListItemIcon>
                        <GearIcon />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography component="a" variant="subtitle2">
                          {t(settings)}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  )}
                </Box>
              </Box>
            )}
          </Box>
        </List>
      </Box>
    </Drawer>
  ) : null;
};

export default NavDrawer;
