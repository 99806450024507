import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, useMediaQuery, useTheme } from '@mui/material';

import { Typography } from '../../../../../atoms';

const OpportunitiesDefinitionSection = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });

  return (
    <Box
      alignItems="center"
      className="ayo-opportunities-definition-section"
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      <img
        alt={t('Assets opportunities definition section alt')}
        src={t(
          isWidthUpSm
            ? 'Assets opportunities definition section desktop illustration'
            : 'Assets opportunities definition section mobile illustration',
        )}
      />
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        mb={isWidthUpSm ? 0 : 10}
        mt={3}
        mx={2}
      >
        <Typography
          align="center"
          className="ayo-opportunities-page__content--typography-blue"
          component="h1"
          paragraph
          variant="h1"
        >
          {t('Explore mysterious opportunities')}
        </Typography>
        <Typography
          align="center"
          className="ayo-opportunities-definition-section__description"
          variant="body2"
        >
          {t(
            'Start exploring mysteries by revealing new opportunities and deciding if you want to keep them in your lifetime adventure!',
          )}
        </Typography>
      </Box>
    </Box>
  );
};

export default OpportunitiesDefinitionSection;
