import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Timeline,
  TimelineConnector,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab';
import { Box } from '@mui/material';
import { useLocation } from 'react-router-dom';

import { Button } from '../../../../atoms';
import { ReactComponent as ExpandIcon } from '../../../../../resources/icons/chevron_down.svg';
import { ReactComponent as CollapseIcon } from '../../../../../resources/icons/chevron_up.svg';
import { ReactComponent as ReplyIcon } from '../../../../../resources/icons/reply_arrow.svg';
import CommentsCard from '../comments-card/CommentsCard';
import CommentsForm from '../comments-form/CommentsForm';

const RepliesBlock = ({
  addNewCommentHandler,
  deleteCommentHandler,
  discardButtonId,
  editCommentHandler,
  isLeaveDialogDisabled,
  outlined,
  page,
  parentCommentId,
  parentCommentTitle,
  relatedUserId,
  replies,
  resourceId,
  unreadReplies,
}) => {
  const { t } = useTranslation();
  const { hash } = useLocation();
  const [isRepliesCollapsed, setIsRepliesCollapsed] = useState(true);
  const [isTextCollapsed, setIsTextCollapsed] = useState(true);
  const [isReplyFormVisible, setIsReplyFormVisible] = useState(false);

  const replyHandler = useCallback(() => {
    setIsRepliesCollapsed(false);
    setIsTextCollapsed(false);
    setIsReplyFormVisible(!isReplyFormVisible);
  }, [isReplyFormVisible]);

  useEffect(() => {
    if (unreadReplies?.length) {
      setIsRepliesCollapsed(false);
    }
    if (hash) {
      const replayHashId = +hash.slice(hash.lastIndexOf('-') + 1);
      if (replies?.some(({ id }) => id === replayHashId)) {
        setIsRepliesCollapsed(false);
      }
    }
  }, [hash, replies, unreadReplies?.length]);

  return (
    <>
      {!isRepliesCollapsed && (
        <Timeline>
          {replies.map((reply) => (
            <TimelineItem key={reply.id}>
              <TimelineSeparator>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineOppositeContent>
                <Box className="ayo-comment-card__timeline" />
                <CommentsCard
                  comment={reply}
                  deleteCommentHandler={deleteCommentHandler}
                  discardButtonId={discardButtonId}
                  editCommentHandler={editCommentHandler}
                  isTextCollapsed={isTextCollapsed}
                  parentId={parentCommentId}
                  resourceId={resourceId}
                  title={`Re: ${parentCommentTitle}`}
                  unreadItem={unreadReplies?.find(
                    (unreadReply) => unreadReply.resourceId === reply.id,
                  )}
                />
              </TimelineOppositeContent>
            </TimelineItem>
          ))}
          {isReplyFormVisible && (
            <TimelineItem>
              <TimelineSeparator>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineOppositeContent>
                <Box className="ayo-comment-card__timeline" />
                <CommentsForm
                  addNewCommentHandler={addNewCommentHandler}
                  isLeaveDialogDisabled={isLeaveDialogDisabled}
                  outlined={outlined}
                  page={page}
                  parentCommentId={parentCommentId}
                  parentCommentTitle={parentCommentTitle}
                  relatedUserId={relatedUserId}
                  replyHandler={replyHandler}
                  resourceId={resourceId}
                />
              </TimelineOppositeContent>
            </TimelineItem>
          )}
        </Timeline>
      )}
      <Box pt={1}>
        {!isReplyFormVisible && (
          <Box display="flex">
            <Box mr={2}>
              <Button endIcon={<ReplyIcon />} gaLabel="Reply" onClick={replyHandler} variant="text">
                {t('Reply')}
              </Button>
            </Box>
            {!!replies.length && (
              <Button
                endIcon={isRepliesCollapsed ? <ExpandIcon /> : <CollapseIcon />}
                gaLabel={isRepliesCollapsed ? 'View replies' : 'Hide replies'}
                onClick={() => {
                  setIsRepliesCollapsed(!isRepliesCollapsed);
                  setIsTextCollapsed(false);
                  setIsReplyFormVisible(false);
                }}
              >
                {isRepliesCollapsed
                  ? `${t('View replies')} (${replies.length})`
                  : t('Hide replies')}
              </Button>
            )}
          </Box>
        )}
      </Box>
    </>
  );
};
RepliesBlock.propTypes = {
  addNewCommentHandler: PropTypes.func.isRequired,
  deleteCommentHandler: PropTypes.func.isRequired,
  discardButtonId: PropTypes.string,
  editCommentHandler: PropTypes.func.isRequired,
  isLeaveDialogDisabled: PropTypes.bool,
  outlined: PropTypes.bool,
  page: PropTypes.string.isRequired,
  parentCommentId: PropTypes.number.isRequired,
  parentCommentTitle: PropTypes.string.isRequired,
  relatedUserId: PropTypes.number.isRequired,
  replies: PropTypes.arrayOf(
    PropTypes.shape({
      author: PropTypes.string,
      authorId: PropTypes.number,
      body: PropTypes.string,
      createdDate: PropTypes.string,
      edited: PropTypes.bool,
      id: PropTypes.number,
      replies: PropTypes.arrayOf(PropTypes.string),
      title: PropTypes.string,
    }),
  ),
  resourceId: PropTypes.number,
  unreadReplies: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
};
RepliesBlock.defaultProps = {
  replies: [],
  isLeaveDialogDisabled: false,
  outlined: false,
  resourceId: null,
  discardButtonId: null,
  unreadReplies: [],
};

export default RepliesBlock;
