import React, { useEffect, useContext, useMemo, useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Grid, Box, Container } from '@mui/material';
import PropTypes from 'prop-types';

import { Typography } from '../../../../../atoms';
import { ClickableCard, InformationalMessage, LinkWithTooltip } from '../../../../../moleculas';
import { PageWrapper } from '../../../../../organisms';
import { UserActions, UserContext } from '../../../../../../context';
import { useOneTimeActionService, useProfileService } from '../../../../../../services';
import { trainAyoRoute } from '../../../../../../constants/routes';

const TrainAyoKidsSelectionPage = ({
  activityCriterion,
  initiativeRoute,
  notificationAction,
  notificationText,
  progressAction,
  secondaryText,
  title,
}) => {
  const { t } = useTranslation();
  const { state: userState, dispatch: dispatchUserState } = useContext(UserContext);
  const [kids, setKids] = useState(null);

  const { postOneTimeAction } = useOneTimeActionService();
  const { getParentDependentProfiles } = useProfileService();

  useEffect(() => {
    getParentDependentProfiles(activityCriterion).then((kidsData) => setKids(kidsData));
  }, [activityCriterion, getParentDependentProfiles]);

  useEffect(() => {
    if (userState.oneTimeActions && !userState.oneTimeActions.includes(progressAction)) {
      postOneTimeAction(progressAction);
      dispatchUserState({
        type: UserActions.SET_ONE_TIME_ACTION,
        data: progressAction,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isNotificationVisible = useMemo(
    () =>
      userState.oneTimeActions &&
      !userState.oneTimeActions.includes(notificationAction) &&
      !!kids?.length,
    [userState.oneTimeActions, notificationAction, kids],
  );

  const handleActiveNotification = useCallback(() => {
    postOneTimeAction(notificationAction);
    dispatchUserState({
      type: UserActions.SET_ONE_TIME_ACTION,
      data: notificationAction,
    });
  }, [dispatchUserState, postOneTimeAction, notificationAction]);

  return (
    <PageWrapper
      backToLink={trainAyoRoute}
      backToText={t('Go to Train AYO')}
      mainElementClassName="ayo-train-ayo-kids-selection-page"
    >
      <Container>
        <Grid container sm={6} xs={12}>
          <Box>
            <Typography component="h1" paragraph variant="h1">
              {t(title)}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body2">{t(secondaryText)}</Typography>
          </Box>
          {isNotificationVisible && (
            <Box mt={5}>
              <InformationalMessage
                onClick={handleActiveNotification}
                text={
                  <Trans
                    components={{
                      green: <span className="ayo-informational-message__description__indicator" />,
                    }}
                    i18nKey={notificationText}
                  />
                }
              />
            </Box>
          )}
        </Grid>
        <Box mb={3} mt={5}>
          <Grid container direction="row" item spacing={2} xs={12}>
            {kids?.map((kid, index) => (
              <Grid key={kid.id} item sm={3} xs={12}>
                <ClickableCard
                  gaLabel={`Kid card ${index + 1} out of ${kids?.length}`}
                  href={`${trainAyoRoute}/${kid.id}${initiativeRoute}`}
                  isActive={kid.active}
                  title={`${kid.firstName}`}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box>
          <LinkWithTooltip tooltipPlacement="bottom-start" />
        </Box>
      </Container>
    </PageWrapper>
  );
};

TrainAyoKidsSelectionPage.propTypes = {
  activityCriterion: PropTypes.string,
  initiativeRoute: PropTypes.string,
  notificationAction: PropTypes.string,
  notificationText: PropTypes.string,
  progressAction: PropTypes.string,
  secondaryText: PropTypes.string,
  title: PropTypes.string,
};

TrainAyoKidsSelectionPage.defaultProps = {
  activityCriterion: '',
  initiativeRoute: '',
  notificationAction: '',
  notificationText: '',
  progressAction: '',
  secondaryText: '',
  title: '',
};

export default TrainAyoKidsSelectionPage;
