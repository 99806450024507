import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import classNames from 'classnames';

import { Typography } from '../../../../atoms';
import { DeleteItemWrapper, InitiativeEmptyStateBlock } from '../../../../moleculas';
import {
  getFormattedDate,
  PortfolioEntitiesData,
  PortfolioItemsToAddData,
} from '../../../../../utils';
import { portfolioSectionConfig } from '../../../../../constants/propTypes';
import { AcademicRecordNamesMap, PortfolioSectionTypes } from '../../../../../constants/enums';
import { ReactComponent as AcademicRecordEmptyState } from '../../../../../resources/images/portfolio/academic_record_empty_state.svg';
import PortfolioSection from '../../components/portfolio-section/PortfolioSection';
import PortfolioAcademicRecordCard from '../../components/portfolio-academic-record-card/PortfolioAcademicRecordCard';

const PortfolioAcademicRecordSection = ({ config, sectionIndex, isLastSection }) => {
  const { i18n, t } = useTranslation();
  const [academicRecord, setAcademicRecord] = useState([]);
  const {
    addPortfolioItemHandler,
    deletePortfolioItemHandler,
    hasData,
    isEditMode,
    isVisible,
    metaData,
    reflectionHandler,
    sectionData,
    updatePortfolioHandler,
    onUpdatePositionHandler,
  } = config;
  const { entities, reflection } = sectionData;

  useEffect(() => {
    setAcademicRecord(
      PortfolioEntitiesData[PortfolioSectionTypes.PORTFOLIO__ACADEMIC_RECORD](
        entities,
        metaData,
        'type',
      ),
    );
  }, [entities, metaData]);

  const updatedAt = useMemo(() => {
    const recordWithDate = academicRecord.find((record) => !!record.dateTime);
    let formattedDate = null;

    if (recordWithDate) {
      formattedDate = getFormattedDate(new Date(recordWithDate.dateTime), i18n.language, {
        day: 'numeric',
        weekday: 'short',
        month: 'long',
      });
    }

    return formattedDate;
  }, [academicRecord, i18n.language]);

  const academicRecordToAdd = useMemo(
    () =>
      PortfolioItemsToAddData[PortfolioSectionTypes.PORTFOLIO__ACADEMIC_RECORD](
        entities,
        metaData,
        'type',
      ).map(({ type }) => ({
        id: type,
        name: t(AcademicRecordNamesMap[type].title),
      })),
    [entities, metaData, t],
  );

  const handleSectionUpdate = useCallback(() => {
    const academicRecordToUpdate = metaData.filter((testScore) =>
      academicRecord.some((item) => item.type === testScore.type),
    );

    updatePortfolioHandler({
      ...sectionData,
      entities: [...academicRecordToUpdate],
    });
  }, [academicRecord, metaData, sectionData, updatePortfolioHandler]);

  if (!isVisible) {
    return null;
  }

  return (
    <PortfolioSection
      body={
        <>
          <Grid columnSpacing={3} container mt={3}>
            {academicRecord.map(({ type, value }) => (
              <Grid key={type} item sm={3}>
                <DeleteItemWrapper
                  disabled={!isEditMode}
                  gaLabel="Remove academic record"
                  mainContent={<PortfolioAcademicRecordCard type={type} value={value} />}
                  onDelete={() => {
                    deletePortfolioItemHandler(academicRecord, 'type', type, sectionData);
                  }}
                />
              </Grid>
            ))}
          </Grid>
          {isEditMode && !!updatedAt && (
            <Typography
              className="ayo-portfolio__academic-record-section__caption"
              variant="body2"
            >{`${t('Your Academic record was updated in AYO on')} ${updatedAt}`}</Typography>
          )}
        </>
      }
      className={`${classNames({
        'ayo-portfolio__academic-record-section': hasData,
      })}`}
      description={isEditMode ? 'Here, you can see the highlights of your record.' : ''}
      emptyState={
        !hasData ? (
          <InitiativeEmptyStateBlock
            body={isEditMode ? t('It will appear here once AYO discovers it.') : ''}
            illustration={<AcademicRecordEmptyState />}
            title={t('No information about academic record so far')}
          />
        ) : null
      }
      isEditMode={isEditMode}
      isLastSection={isLastSection}
      itemsToAdd={academicRecordToAdd}
      onAddItems={(itemsToAdd) =>
        addPortfolioItemHandler(metaData, itemsToAdd, 'type', sectionData)
      }
      onChangeReflection={reflectionHandler}
      onSectionUpdate={handleSectionUpdate}
      onUpdatePositionHandler={onUpdatePositionHandler}
      reflection={reflection}
      sectionIndex={sectionIndex}
      sectionKey={PortfolioSectionTypes.PORTFOLIO__ACADEMIC_RECORD}
      showActionsMenu={hasData && isEditMode}
      showAddButton={!!academicRecordToAdd?.length && isEditMode}
      theme={hasData ? 'dark' : 'light'}
      title={t('Academic record')}
    />
  );
};

PortfolioAcademicRecordSection.propTypes = {
  config: portfolioSectionConfig(
    PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
  ),
  sectionIndex: PropTypes.number.isRequired,
  isLastSection: PropTypes.number.isRequired,
};

PortfolioAcademicRecordSection.defaultProps = {
  config: {},
};

export default PortfolioAcademicRecordSection;
