import React, { useState } from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { DynamicGrid } from '../../../../atoms';
import { InitiativeEmptyStateBlock } from '../../../../moleculas';
import { PortfolioSectionTypes } from '../../../../../constants/enums';
import PortfolioSection from '../../components/portfolio-section/PortfolioSection';
import PortfolioFilePreviewDialog from '../../components/portfolio-file-preview-dialog/PortfolioFilePreviewDialog';
import { ReactComponent as EssaysEmptyStateIllustration } from '../../../../../resources/images/portfolio/essay_empty_state.svg';

import EssayCard from './essay-card/EssayCard';

const PortfolioSharedEssaysSection = ({ sectionData, token }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpMd = useMediaQuery(theme.breakpoints.up('md'));

  const [isPreviewDialogOpen, setIsPreviewDialogOpen] = useState(false);
  const [previewEssayIndex, setPreviewEssayIndex] = useState(null);

  return (
    <Box>
      <PortfolioSection
        body={
          <Box mt={3}>
            <DynamicGrid
              columnsCount={isWidthUpMd ? 3 : 2}
              gridItems={sectionData.entities.map((essay, index) => (
                <EssayCard
                  key={essay.id}
                  id={essay.id}
                  isEditMode={false}
                  onRead={() => {
                    setPreviewEssayIndex(index);
                    setIsPreviewDialogOpen(true);
                  }}
                  textJson={essay.textJson}
                  token={token}
                />
              ))}
              isHorizontalOrder
            />
          </Box>
        }
        className={`${classNames('ayo-full-screen-portfolio-section', {
          'ayo-portfolio__essays-section ayo-portfolio__essay-background':
            sectionData.entities.length,
        })}`}
        emptyState={
          !sectionData.entities.length ? (
            <InitiativeEmptyStateBlock
              illustration={<EssaysEmptyStateIllustration />}
              title={t('No application essays created so far')}
            />
          ) : null
        }
        reflection={sectionData.reflection}
        sectionKey={PortfolioSectionTypes.PORTFOLIO__ESSAYS}
        title="Application essays"
      />
      <PortfolioFilePreviewDialog
        isOpen={isPreviewDialogOpen}
        onClose={() => {
          setIsPreviewDialogOpen(false);
          setPreviewEssayIndex(null);
        }}
        textJson={sectionData.entities[previewEssayIndex]?.textJson}
        title={t('Application essay preview')}
        token={token}
      />
    </Box>
  );
};

PortfolioSharedEssaysSection.propTypes = {
  sectionData: PropTypes.instanceOf(Object),
  token: PropTypes.string,
};

PortfolioSharedEssaysSection.defaultProps = {
  sectionData: {},
  token: null,
};

export default PortfolioSharedEssaysSection;
