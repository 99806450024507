import { useCallback } from 'react';

import useAxios, { StatusCodeMap } from '../HttpClient';
import {
  activityLogRoute,
  apiRoute,
  approveRoute,
  contentRoute,
  visibleRoute,
} from '../../constants/routes';

const useContentModerationService = () => {
  const { get, post, put } = useAxios();

  const getContentModerationData = useCallback(
    async (type, schoolLevel) => {
      const { data } = await get(
        { enabled: true },
        `${apiRoute}${contentRoute}/${type}?schoolLevel=${schoolLevel}`,
      );
      return data.entity;
    },
    [get],
  );

  const postContentVisibility = useCallback(
    async (
      entityKeys,
      isVisible,
      schoolLevel,
      actionEntityKey,
      actionTranslationKey,
      hasChildEntities = false,
    ) => {
      const postBody = {
        entityKeys,
        isVisible,
        schoolLevel,
        actionEntityKey,
        actionTranslationKey,
        hasChildEntities,
      };
      const { data } = await post(
        { enabled: false },
        `${apiRoute}${contentRoute}${visibleRoute}`,
        postBody,
        {
          skipDefaultErrorHandlers: [StatusCodeMap.SERVER_ERROR, StatusCodeMap.NOT_FOUND],
        },
      );
      return data;
    },
    [post],
  );

  const postContentTranslation = useCallback(
    async (actionEntityKey, actionTranslationKey, lang, updatedTranslations, isLoaderEnabled) => {
      const translations = Object.values(updatedTranslations).reduce(
        (acc, value) => ({ ...acc, [value.translationKey]: value.ES_US_updated }),
        {},
      );
      const postBody = {
        actionEntityKey,
        actionTranslationKey,
        lang,
        translations,
      };
      const { data } = await post(
        { enabled: isLoaderEnabled },
        `${apiRoute}${contentRoute}/translate`,
        postBody,
        {
          skipDefaultErrorHandlers: [StatusCodeMap.SERVER_ERROR, StatusCodeMap.NOT_FOUND],
        },
      );
      return data;
    },
    [post],
  );

  const postContentApprove = useCallback(
    async (entityKey, isApproved, translationKey) => {
      const postBody = {
        entityKey,
        isApproved,
        translationKey,
      };
      const { data } = await post(
        { enabled: true },
        `${apiRoute}${contentRoute}${approveRoute}`,
        postBody,
        {
          skipDefaultErrorHandlers: [StatusCodeMap.SERVER_ERROR, StatusCodeMap.NOT_FOUND],
        },
      );

      return data;
    },
    [post],
  );

  const getActivityLogData = useCallback(
    async (type, schoolLevel, page) => {
      const { data } = await get(
        { enabled: true },
        `${apiRoute}${activityLogRoute}${contentRoute}?contentType=${type}&page=${page}&schoolLevel=${schoolLevel}&size=20`,
      );
      return data.entity;
    },
    [get],
  );

  const getActivityLogUnreadCount = useCallback(
    async (type, schoolLevel) => {
      const { data } = await get(
        { enabled: true },
        `${apiRoute}${activityLogRoute}${contentRoute}/countUnread?contentType=${type}&schoolLevel=${schoolLevel}`,
      );
      return data.entity.count;
    },
    [get],
  );

  const putReadActivityLogItem = useCallback(
    (idsArray) =>
      put(
        { enabled: false },
        `${apiRoute}${activityLogRoute}${contentRoute}/markRead`,
        { ids: idsArray },
        {
          skipDefaultErrorHandlers: [StatusCodeMap.NOT_FOUND, StatusCodeMap.SERVER_ERROR],
        },
      ),
    [put],
  );

  const putReadAllActivityLogItems = useCallback(
    (type, schoolLevel) =>
      put(
        { enabled: false },
        `${apiRoute}${activityLogRoute}${contentRoute}/markAllRead?contentType=${type}&schoolLevel=${schoolLevel}`,
        null,
        {
          skipDefaultErrorHandlers: [StatusCodeMap.NOT_FOUND, StatusCodeMap.SERVER_ERROR],
        },
      ),
    [put],
  );

  return {
    getContentModerationData,
    postContentApprove,
    postContentVisibility,
    postContentTranslation,
    getActivityLogData,
    getActivityLogUnreadCount,
    putReadActivityLogItem,
    putReadAllActivityLogItems,
  };
};

export default useContentModerationService;
