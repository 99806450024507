/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Autocomplete, Divider } from '@mui/material';

import { SpeechButton, TextField, Typography } from '../../atoms';
import STTTooltip from '../stt-tooltip/STTTooltip';
import { Highlighter, GA } from '../../../utils';
import { GaActions, GaCategories, KeyboardMap } from '../../../constants/enums';
import { emailAllowedFilterRE } from '../../../constants/regexps';
import useSTT from '../../../services/speech-to-text/useSTT';
import { ReactComponent as SearchIcon } from '../../../resources/icons/searchfield_search.svg';
import { ReactComponent as ClearIcon } from '../../../resources/icons/close_light.svg';

const SearchField = React.forwardRef(
  (
    {
      apiRef,
      className,
      criterias,
      defaultValue,
      inputRE,
      label,
      maxRowsCount,
      minInputLength,
      onChange,
      onOptionClick,
      onSearch,
      outlined,
      noOptionsText,
      searchOnChange,
      suggestions,
      variant,
      gaLabel,
      freeSolo,
      ...rest
    },
    ref,
  ) => {
    const [searchActive, setSearchActive] = useState(false);

    const [searchValue, setSearchValue] = useState(defaultValue);

    const [highlightedSuggestion, setHighlightedSuggestion] = useState(null);

    const clearButtonRef = useRef();

    const sendGoogleAnalytics = useCallback(
      (action) => {
        GA.logInteraction({
          category: GaCategories.BEHAVIOR,
          action,
          label: gaLabel,
        });
      },
      [gaLabel],
    );

    const executeSearch = useCallback(
      (isReset) => {
        const isValid = searchValue && searchValue.length >= minInputLength;
        setSearchActive(isValid && !isReset);
        setHighlightedSuggestion(null);
        if (gaLabel && isValid) {
          sendGoogleAnalytics(GaActions.SEARCH_EXECUTE);
        }
        if (isReset) {
          setSearchValue('');
        }
        onSearch(isValid && !isReset ? searchValue : null);
      },
      [gaLabel, minInputLength, onSearch, searchValue, sendGoogleAnalytics],
    );

    const { t } = useTranslation();

    useEffect(() => {
      let timer;
      if (searchOnChange) {
        if (searchValue) {
          timer = setTimeout(() => {
            executeSearch();
          }, 1000);
        } else {
          executeSearch(true);
        }
      }
      return () => {
        if (timer) {
          clearTimeout(timer);
        }
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue]);

    const {
      startRecording,
      stopRecording,
      isRecording,
      identifiedText: plainIdentifiedText,
      analyzer,
      socket,
      error,
    } = useSTT();

    const identifiedText = useMemo(
      () =>
        plainIdentifiedText
          .map((sentence) => sentence.trimStart()[0].toUpperCase() + sentence.trimStart().slice(1))
          .join(' '),
      [plainIdentifiedText],
    );

    const inputRef = useRef();

    const [isRecordingActivated, setIsRecordingActivated] = useState(false);

    const onRecordingStart = useCallback(() => {
      startRecording();
      if (!isRecordingActivated) {
        setIsRecordingActivated(true);
        sendGoogleAnalytics(GaActions.STT_OPEN);
        inputRef.current.focus();
      }
    }, [isRecordingActivated, sendGoogleAnalytics, startRecording]);

    const onRecordingEnd = useCallback(() => {
      stopRecording();
      setIsRecordingActivated(false);
      sendGoogleAnalytics(GaActions.STT_CLOSE);
      if (identifiedText) inputRef.current.focus();
    }, [identifiedText, sendGoogleAnalytics, stopRecording]);

    const handleChange = useCallback(
      (e, value) => {
        if (!e?.isSTT && isRecordingActivated) {
          onRecordingEnd();
        }
        const filteredInput = value
          ? value
              .match(inputRE)
              .map((substring) => substring.trimStart())
              .join(' ')
          : '';
        setSearchValue(filteredInput);
        onChange?.(filteredInput);
        setHighlightedSuggestion(null);
        if (e?.isSTT) {
          inputRef.current.scrollLeft = inputRef.current.scrollWidth;
        }
      },
      [inputRE, isRecordingActivated, onChange, onRecordingEnd],
    );

    const onRecognitionChange = useCallback(
      (recognizedText) => {
        if (searchValue === recognizedText) return;
        inputRef.current.value = recognizedText;
        handleChange({ target: inputRef.current, isSTT: true }, recognizedText);
      },
      [handleChange, searchValue],
    );

    useEffect(() => {
      if (isRecording) {
        onRecognitionChange(identifiedText);
      }
    }, [identifiedText, isRecording, onRecognitionChange]);

    const handleKeyDown = useCallback(
      (e) => {
        if (e.key === KeyboardMap.ENTER) {
          if (highlightedSuggestion?.id) {
            onOptionClick(highlightedSuggestion?.id);
          } else {
            executeSearch(e.target === clearButtonRef.current);
          }
        }
      },
      [executeSearch, highlightedSuggestion, onOptionClick],
    );

    useEffect(() => {
      if (!socket && identifiedText && isRecordingActivated) {
        onRecordingEnd();
      }
    }, [identifiedText, isRecordingActivated, onRecordingEnd, socket]);

    const handleClearClick = useCallback(() => {
      executeSearch(true);
      if (gaLabel) {
        sendGoogleAnalytics(GaActions.SEARCH_CLEAR);
      }
    }, [executeSearch, gaLabel, sendGoogleAnalytics]);

    const handleSearchClick = useCallback(() => {
      executeSearch();
      onRecordingEnd();
    }, [executeSearch, onRecordingEnd]);

    const renderInput = useCallback(
      (params) => {
        const childrenArray = React.Children.toArray(params.InputProps.endAdornment.props.children);
        let resetWithDisabledRipple;
        const searchWithDisabledRipple = React.cloneElement(
          childrenArray[childrenArray.length - 1],
          {
            disableRipple: true,
            onClick: () => {
              handleSearchClick();
              childrenArray[childrenArray.length - 1].props.onClick();
            },
            className: classNames(
              childrenArray[childrenArray.length - 1].props.className,
              'ayo-icon-button',
            ),
            tabindex: 0,
            'aria-label': null,
          },
        );
        if (childrenArray.length > 1) {
          resetWithDisabledRipple = React.cloneElement(childrenArray[0], {
            disableRipple: true,
            onClick: () => {
              handleClearClick();
              childrenArray[0].props.onClick();
            },
            className: classNames(childrenArray[0].props.className, 'ayo-icon-button', {
              'MuiAutocomplete-clearIndicatorDirty': searchValue.length,
            }),
            tabindex: 0,
            'aria-label': null,
          });
        }
        const newChildren = [
          <SpeechButton
            key="speech-btn"
            isActive={isRecordingActivated}
            onClick={isRecordingActivated ? onRecordingEnd : onRecordingStart}
          />,
          searchWithDisabledRipple,
        ];
        if (resetWithDisabledRipple) {
          newChildren.unshift(
            <Divider
              key="actions-divider"
              className={classNames('adornment-separator', { isVisible: searchValue.length })}
              orientation="vertical"
            />,
          );
          newChildren.unshift(resetWithDisabledRipple);
        }
        const newAdornment = React.cloneElement(params.InputProps.endAdornment, {
          children: newChildren,
        });
        const newParams = { ...params };
        newParams.InputProps.endAdornment = newAdornment;
        return (
          <TextField
            {...newParams}
            InputLabelProps={{ className: 'ayo-textfield__input-label with-search' }}
            label={label}
            onKeyDown={handleKeyDown}
            outlined={outlined}
          />
        );
      },
      [
        handleClearClick,
        handleKeyDown,
        handleSearchClick,
        isRecordingActivated,
        label,
        onRecordingEnd,
        onRecordingStart,
        outlined,
        searchValue.length,
      ],
    );

    const renderOption = (props, option) => {
      const str = `${option.name}`;
      return (
        <li {...props}>
          <Typography variant="body2">{Highlighter(str, [searchValue])}</Typography>
        </li>
      );
    };

    const filterOptions = useCallback(
      (options, params) => {
        const filtered = [];
        if (
          !isRecordingActivated &&
          suggestions.length &&
          params.inputValue.length >= minInputLength
        ) {
          criterias.some((criteria) => {
            suggestions.some((item) => {
              if (
                item[criteria].toString().toLowerCase().includes(params.inputValue.toLowerCase()) &&
                !filtered.find((x) => x.id === item.id)
              ) {
                filtered.push(item);
              }
              return filtered.length >= maxRowsCount;
            });
            return filtered.length >= maxRowsCount;
          });
        }
        return filtered;
      },
      [criterias, isRecordingActivated, maxRowsCount, minInputLength, suggestions],
    );

    useEffect(() => {
      if (apiRef) {
        // eslint-disable-next-line no-param-reassign
        apiRef.current = { clearSearch: handleClearClick };
      }

      return () => {
        if (apiRef) {
          // eslint-disable-next-line no-param-reassign
          apiRef.current = null;
        }
      };
    }, [apiRef, handleClearClick]);

    const handleOptionClick = useCallback(() => {
      const id = highlightedSuggestion?.id;
      onOptionClick(id);
    }, [highlightedSuggestion, onOptionClick]);

    const closeTooltips = useCallback(() => setIsRecordingActivated(false), []);

    const resetText = useCallback(() => onRecognitionChange(''), [onRecognitionChange]);

    return (
      <STTTooltip
        analyzer={analyzer}
        closeTooltips={closeTooltips}
        error={error}
        gaLabel={gaLabel}
        hasRecognizedText={!!identifiedText}
        isConnected={!!socket}
        isRecording={isRecording}
        isRecordingActivated={isRecordingActivated}
        resetText={resetText}
        startRecording={onRecordingStart}
        stopAndClose={onRecordingEnd}
      >
        <Autocomplete
          ref={(paramRef) => {
            if (!paramRef) return;
            if (ref) ref(paramRef);
            inputRef.current = paramRef.querySelector('input');
          }}
          className={`${classNames('ayo-searchfield', {
            [`${className}`]: className,
            'ayo-searchfield--active': searchActive,
            variant,
          })}`}
          clearIcon={<ClearIcon ref={clearButtonRef} className="ayo-searchfield__action-icon" />}
          clearOnBlur={false}
          clearText={t('Clear')}
          closeText={t('Search')}
          filterOptions={filterOptions}
          forcePopupIcon
          freeSolo={freeSolo}
          getOptionLabel={(option) => option.name || option}
          inputValue={searchValue || ''}
          ListboxProps={{
            onClick: handleOptionClick,
            className: classNames('MuiAutocomplete-listbox', variant),
          }}
          noOptionsText={noOptionsText}
          onClose={() => setHighlightedSuggestion(null)}
          onHighlightChange={(e, option) => {
            if (option) {
              setHighlightedSuggestion(option);
            }
          }}
          onInputChange={handleChange}
          openOnFocus
          openText={t('Search')}
          options={suggestions}
          popupIcon={<SearchIcon className="ayo-searchfield__action-icon" />}
          renderInput={renderInput}
          renderOption={renderOption}
          role="search"
          {...rest}
        />
      </STTTooltip>
    );
  },
);

SearchField.propTypes = {
  apiRef: PropTypes.shape({ current: PropTypes.instanceOf(Object) }),
  className: PropTypes.string,
  criterias: PropTypes.arrayOf(PropTypes.string),
  defaultValue: PropTypes.string,
  gaLabel: PropTypes.string,
  inputRE: PropTypes.instanceOf(RegExp),
  label: PropTypes.string,
  maxRowsCount: PropTypes.number,
  minInputLength: PropTypes.number,
  noOptionsText: PropTypes.string,
  onChange: PropTypes.func,
  onOptionClick: PropTypes.func,
  onSearch: PropTypes.func,
  outlined: PropTypes.bool,
  searchOnChange: PropTypes.bool,
  suggestions: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  variant: PropTypes.oneOf(['light', 'dark']),
  freeSolo: PropTypes.bool,
};

SearchField.defaultProps = {
  apiRef: null,
  className: '',
  criterias: [],
  defaultValue: '',
  gaLabel: '',
  inputRE: emailAllowedFilterRE,
  label: '',
  maxRowsCount: 0,
  minInputLength: 1,
  noOptionsText: 'Nothing found',
  onChange: null,
  onOptionClick: () => {},
  onSearch: () => {},
  outlined: false,
  searchOnChange: false,
  suggestions: [],
  variant: 'light',
  freeSolo: true,
};

export default SearchField;
