import React, { useContext, useState, useEffect, useCallback, useMemo } from 'react';
import { Box, Container, Grid, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Typography } from '../../atoms';
import { BadgeCard, GoToStudentProfileBlock } from '../../moleculas';
import { PageWrapper, TryMobileInformationalMessage } from '../../organisms';
import { myProfileRoute } from '../../../constants/routes';
import { GaActions, GaCategories } from '../../../constants/enums';
import { UserContext } from '../../../context';
import { useStudentsService } from '../../../services';
import { focusNextItem, GA } from '../../../utils';

import BadgesDialog from './components/badges-dialog/BadgesDialog';

const digitalDiscoverer = 'login-first';

const BadgesPage = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const [badges, setBadges] = useState(null);
  const [activeBadgeIndex, setActiveBadgeIndex] = useState(null);
  const [isBadgesDialogOpen, setIsBadgesDialogOpen] = useState(false);
  const { state: userState } = useContext(UserContext);
  const studentId = userState.profile?.id;
  const { getStudentBadges } = useStudentsService();
  const isAnyBadgeAchieved = useMemo(
    () => badges?.some((badgeItem) => badgeItem.createdDate),
    [badges],
  );
  const isMobileAppDownloaded = useMemo(
    () => !!badges?.find((badge) => badge.nameKey === digitalDiscoverer)?.createdDate,
    [badges],
  );

  const onCardClickHandler = useCallback((index) => {
    setActiveBadgeIndex(index);
    setIsBadgesDialogOpen(true);
    GA.logInteraction({
      category: GaCategories.BEHAVIOR,
      action: GaActions.CARD_CLICK,
      label: 'Badge card',
    });
  }, []);

  const closeDialogHandler = useCallback(() => {
    focusNextItem(activeBadgeIndex, badges?.length, '.ayo-badge-card');
    setIsBadgesDialogOpen(false);
    GA.logInteraction({
      category: GaCategories.BEHAVIOR,
      action: GaActions.BUTTON_CLICK,
      label: 'Close badge dialog',
    });
  }, [activeBadgeIndex, badges]);

  const onNavigationChange = useCallback(
    (index) => {
      if (activeBadgeIndex > 0 || activeBadgeIndex < badges.length - 1) {
        setActiveBadgeIndex(index);
      }
    },
    [badges, activeBadgeIndex],
  );

  useEffect(() => {
    getStudentBadges(studentId).then((badgesData) => {
      setBadges(badgesData);
    });
  }, [getStudentBadges, studentId]);

  return (
    <PageWrapper backToLink={myProfileRoute} backToText={t('Back to My profile')}>
      <Container>
        <Box mb={isWidthUpSm ? 5 : 3}>
          <Typography component="h1" variant="h1">
            {t('My badges')}
          </Typography>
        </Box>
        <Box mb={3}>
          <TryMobileInformationalMessage />
        </Box>
        <Typography component="h2" variant="h2">
          {t('Badge collection')}
        </Typography>
        <Box mb={isWidthUpSm ? 10 : 5} mt={3}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            maxWidth="initial"
            spacing={3}
            xs={12}
          >
            {badges?.map((badge, index) => (
              <Grid key={badge.id} item md={2} xs={6}>
                <BadgeCard
                  key={badge.key}
                  badge={badge}
                  index={index}
                  onCardClickHandler={onCardClickHandler}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
        {isAnyBadgeAchieved && (
          <Box>
            <GoToStudentProfileBlock />
          </Box>
        )}
        <BadgesDialog
          badges={badges}
          index={activeBadgeIndex}
          isMobileAppDownloaded={isMobileAppDownloaded}
          isOpen={isBadgesDialogOpen}
          navigationHandler={onNavigationChange}
          onClose={closeDialogHandler}
          studentId={studentId}
        />
      </Container>
    </PageWrapper>
  );
};

export default BadgesPage;
