import React from 'react';
import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { AchievementCard, InitiativeEmptyStateBlock } from '../../../../moleculas';
import { convertDataToAchievement } from '../../../../../utils';
import { PortfolioSectionTypes } from '../../../../../constants/enums';
import { ReactComponent as AchievementsProfileImage } from '../../../../../resources/images/achievements_profile_image.svg';
import PortfolioSection from '../../components/portfolio-section/PortfolioSection';

const PortfolioSharedAchievementsSection = ({ sectionData }) => {
  const { t } = useTranslation();

  return (
    <PortfolioSection
      body={
        <Box mt={3}>
          <Grid className="ayo-portfolio__achievements-section-content" container spacing={3}>
            {sectionData.entities.map((achievement) => (
              <Grid key={achievement.name} item sm={4}>
                <AchievementCard
                  achievementData={convertDataToAchievement(achievement)}
                  elevation={1}
                  tabIndex="0"
                  titleVariant="p"
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      }
      className={`${classNames('ayo-full-screen-portfolio-section', {
        'ayo-portfolio__achievements-section': sectionData.entities.length,
      })}`}
      emptyState={
        !sectionData.entities.length && (
          <InitiativeEmptyStateBlock
            illustration={<AchievementsProfileImage alt={t('A hand holding a trophy')} />}
            title={t('No information about achievements and participation so far')}
          />
        )
      }
      reflection={sectionData?.reflection}
      sectionKey={PortfolioSectionTypes.PORTFOLIO__ACHIEVEMENTS}
      title={t('Achievements & participation')}
    />
  );
};

PortfolioSharedAchievementsSection.propTypes = {
  sectionData: PropTypes.instanceOf(Object).isRequired,
};

export default PortfolioSharedAchievementsSection;
