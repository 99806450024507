import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Hotspot } from '../../atoms';
import { TextBadge } from '../../moleculas';
import { capitalize } from '../../../utils';
import { PublicationStatuses } from '../../../constants/enums';

const PublicationStatusBadge = ({ variant, className }) => {
  const { t } = useTranslation();

  return (
    <TextBadge
      className={classNames('ayo-status-badge', {
        'ayo-status-badge--draft': variant === PublicationStatuses.DRAFT,
        'ayo-status-badge--published': variant === PublicationStatuses.PUBLISHED,
        [`${className}`]: className,
      })}
      icon={<Hotspot />}
      text={t(capitalize(PublicationStatuses[variant]))}
      textVariant="caption"
    />
  );
};

PublicationStatusBadge.propTypes = {
  variant: PropTypes.oneOf(Object.values(PublicationStatuses)),
  className: PropTypes.string,
};

PublicationStatusBadge.defaultProps = {
  variant: PublicationStatuses.DRAFT,
  className: '',
};

export default PublicationStatusBadge;
