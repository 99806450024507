export const homePageLandingBlock = 'LandingBlock';
export const homePageVisionBlock = 'VisionBlock';
export const homePagePersonalizationBlock = 'PersonalizationBlock';
export const homePagePersonasBlock = 'PersonasBlock';
export const homePageRoadmapBlock = 'RoadmapBlock';
export const homePageQuoteBlock = 'QuoteBlock';
export const homePageInitiativesBlock = 'InitiativesBlock';
export const homePageMediaBlock = 'MediaBlock';
export const homePageTryMobileAppBlock = 'TryMobileAppBlock';

export default {
  homePageLandingBlock,
  homePageVisionBlock,
  homePagePersonalizationBlock,
  homePagePersonasBlock,
  homePageRoadmapBlock,
  homePageQuoteBlock,
  homePageInitiativesBlock,
  homePageMediaBlock,
  homePageTryMobileAppBlock,
};
