import { LoginProvidersMap, RolesMap } from '../constants/enums';
import { dallasNewsArticleRoute, telemundodallasVideoRoute } from '../constants/routes';
import { checkEnvironmentVariable } from '../utils/app-helpers/appHelpers';

export const AnalyticsData = {
  schoolName: 'Mesquite Test',
  stats: {
    students: {
      withConsent: 12500,
      loggedIn: 1234,
      questionnaireResponded: 0,
    },
    initiatives: {
      interests: {
        have: 125,
        notHave: 10,
      },
      opportunities: {
        have: 256,
        notHave: 36,
      },
      leadershipAttributes: {
        have: 568,
        notHave: 321,
      },
      extracurriculars: {
        have: 785,
        notHave: 45,
      },
      endorsements: {
        selected: 0,
        switchedByType: 26,
        switchedByReason: 0,
        pending: 45,
        details: {
          STEM: {
            9: 7,
            10: 0,
            11: 19,
            12: 7,
          },
          PUBLIC_SERVICES: {
            9: 12,
            10: 0,
            11: 0,
            12: 12,
          },
          MULTIDISCIPLINARY_STUDIES: {
            9: 12,
            10: 0,
            11: 3,
            12: 6,
          },
          ARTS_AND_HUMANITIES: {
            9: 18,
            10: 0,
            11: 16,
            12: 8,
          },
          BUSINESS_AND_INDUSTRY: {
            9: 12,
            10: 0,
            11: 12,
            12: 22,
          },
        },
      },
      achievements: {
        have: 698,
        notHave: 45,
      },
      goals: {
        have: 356,
        notHave: 256,
      },
      parentsAnswers: {
        have: 74500,
        notHave: 74000,
      },
    },
    teachers: {
      loggedIn: 777,
    },
    parents: {
      loggedIn: 50,
      skippedTermsAndConditions: 2,
      questionnaireResponded: 1,
    },
  },
};

export const EmailValidatorErrorsMap = {
  EMPTY_EMAIL: 'Instruction: please enter your email first.',
  INVALID_EMAIL: 'Error: please enter valid email.',
};

export const doneAdministratorProfile = {
  success: true,
  completedQuestionnaires: [6],
  pendingQuestionnaires: [],
  isFirstLogin: false,
  firstName: 'Dima',
  userType: 'CLASSLINK',
  birthDate: '2000-08-19',
  policyAccepted: true,
  role: RolesMap.ADMINISTRATOR,
  currentCampus: 'Agnew High School',
  currentCampusSchoolLevel: 'HIGH',
  schools: {
    HIGH: [
      {
        schoolName: 'Agnew High School',
        active: true,
        rolesInSchool: ['ADMINISTRATOR'],
      },
    ],
  },
};

export const doneStudentProfile = {
  id: 1,
  success: true,
  completedQuestionnaires: [
    {
      id: 1,
      questionnaireTitle: 'Initial Profiling',
      isSubmitted: true,
      numberOfSkippedQuestions: 0,
      numberOfAnsweredQuestions: 3,
      totalNumberOfQuestions: 3,
    },
    {
      id: 5,
      questionnaireTitle: 'Progressive Profiling',
      isSubmitted: true,
      numberOfSkippedQuestions: 0,
      numberOfAnsweredQuestions: 3,
      totalNumberOfQuestions: 3,
    },
  ],
  pendingQuestionnaires: [],
  isFirstLogin: false,
  firstName: 'Dima',
  userType: 'CLASSLINK',
  birthDate: '2000-08-19',
  policyAccepted: true,
  role: RolesMap.STUDENT,
};

export const notificationData = {
  WEB_HIDDEN: [
    {
      id: 398,
      resource: 'EXTRACURRICULAR',
      action: 'UPDATE',
      initiator: 'AYO',
      notificationSources: ['WEB_HIDDEN'],
      resourceId: 1555,
      markedAsRead: false,
      createdDate: '2022-02-06T18:30:34',
      metadata: {
        initiatorInfo: null,
        relatedUserInfo: null,
        info: {
          goalName: null,
          laName: 'communication',
          laType: 'Leading self',
          resourceName: 'Photo',
        },
      },
    },
    {
      id: 338,
      resource: 'INTEREST',
      action: 'UPDATE',
      initiator: 'AYO',
      notificationSources: ['WEB_HIDDEN'],
      resourceId: 15355,
      markedAsRead: false,
      createdDate: '2022-02-06T18:30:34',
      metadata: {
        initiatorInfo: null,
        relatedUserInfo: null,
        info: null,
      },
    },
    {
      id: 152,
      resource: 'INTEREST',
      action: 'UPDATE',
      initiator: 'AYO',
      notificationSources: ['WEB_HIDDEN'],
      resourceId: 15355,
      markedAsRead: false,
      createdDate: '2022-02-06T18:30:34',
      metadata: {
        initiatorInfo: null,
        relatedUserInfo: null,
        info: null,
      },
    },
    {
      id: 3981,
      resource: 'EXTRACURRICULAR',
      action: 'UPDATE',
      initiator: 'AYO',
      notificationSources: ['WEB_HIDDEN'],
      resourceId: 1585,
      markedAsRead: false,
      createdDate: '2023-02-06T18:30:34',
      metadata: {
        initiatorInfo: null,
        relatedUserInfo: null,
        info: {
          goalName: null,
          laName: 'communication',
          laType: 'Leading self',
          resourceName: 'Soccer',
        },
      },
    },
  ],
};

export const initialStudentProfile = {
  id: 1,
  success: true,
  completedQuestionnaires: [
    {
      id: 1,
      questionnaireTitle: 'Initial Profiling',
      isSubmitted: true,
      numberOfSkippedQuestions: 0,
      numberOfAnsweredQuestions: 3,
      totalNumberOfQuestions: 3,
    },
  ],
  pendingQuestionnaires: [
    {
      id: 5,
      questionnaireTitle: 'Progressive Profiling',
      isSubmitted: false,
      numberOfSkippedQuestions: 0,
      numberOfAnsweredQuestions: 0,
      totalNumberOfQuestions: 3,
    },
  ],
  isFirstLogin: false,
  firstName: 'Dima',
  userType: 'CLASSLINK',
  birthDate: '2000-08-19',
  policyAccepted: true,
  role: RolesMap.STUDENT,
};

export const progressiveStudentProfile = {
  id: 1,
  success: true,
  completedQuestionnaires: [
    {
      id: 1,
      questionnaireTitle: 'Initial Profiling',
      isSubmitted: true,
      numberOfSkippedQuestions: 0,
      numberOfAnsweredQuestions: 3,
      totalNumberOfQuestions: 3,
    },
  ],
  pendingQuestionnaires: [
    {
      id: 5,
      questionnaireTitle: 'Progressive Profiling',
      isSubmitted: false,
      numberOfSkippedQuestions: 0,
      numberOfAnsweredQuestions: 1,
      totalNumberOfQuestions: 3,
    },
  ],
  isFirstLogin: false,
  firstName: 'Dima',
  userType: 'CLASSLINK',
  birthDate: '2000-08-19',
  policyAccepted: true,
  role: RolesMap.STUDENT,
};

export const leadershipAttributesInitState = {
  leadershipAttributes: [],
  graphData: [],
  activeStudent: null,
  assessmentId: null,
  isDialogOpen: false,
  evidencesOfGrowth: [],
  goalRelatedRubric: null,
};

export const doneTeacherProfile = {
  success: true,
  completedQuestionnaires: [
    {
      id: 6,
      questionnaireTitle: 'Initial Profiling',
      isSubmitted: true,
      numberOfSkippedQuestions: 0,
      numberOfAnsweredQuestions: 5,
      totalNumberOfQuestions: 5,
    },
  ],
  pendingQuestionnaires: [],
  isFirstLogin: false,
  firstName: 'Dima',
  userType: 'CLASSLINK',
  birthDate: '2000-08-19',
  policyAccepted: true,
  role: RolesMap.TEACHER,
  currentCampus: 'Agnew High School',
  currentCampusSchoolLevel: 'HIGH',
  schools: {
    HIGH: [
      {
        schoolName: 'Agnew High School',
        active: true,
        rolesInSchool: ['TEACHER'],
      },
    ],
  },
};

export const parentDoneProfile = {
  success: true,
  completedQuestionnaires: [6],
  pendingQuestionnaires: [],
  isFirstLogin: false,
  firstName: 'Dima',
  userType: 'SKYWARD',
  birthDate: '2000-08-19',
  policyAccepted: true,
  role: RolesMap.GUARDIAN,
};

export const studentGrades = [
  {
    averageGrade: 10,
    courseName: 'MATH GEOM P-AP H',
    courseNumber: 1,
  },
  {
    averageGrade: 12,
    courseName: 'Theology III',
    courseNumber: 2,
  },
  {
    averageGrade: 25,
    courseName: 'Path College Career IV',
    courseNumber: 3,
  },
  {
    averageGrade: 7,
    courseName: 'MUSIC HISTORY & LIT',
    courseNumber: 4,
  },
  {
    averageGrade: 18,
    courseName: '8BSS1',
    courseNumber: 5,
  },
];

export const doneOtherProfile = {
  success: true,
  completedQuestionnaires: [6],
  pendingQuestionnaires: [],
  isFirstLogin: false,
  firstName: 'Dima',
  userType: 'CLASSLINK',
  birthDate: '2000-08-19',
  policyAccepted: true,
  role: RolesMap.OTHER,
};

export const childHasPendingQuestionnaires = [
  {
    id: 36,
    email: '4ayostudent@mesquiteisd.org',
    firstName: 'Olivia',
    lastName: '',
    birthDate: '1998-07-24T12:06:39.894',
    loginId: '4ayostudent',
    policyAccepted: true,
    userType: 'CLASS_LINK',
    pendingQuestionnaires: [
      {
        id: 7,
        questionnaireTitle: 'Initial Profiling',
        isSubmitted: false,
        numberOfSkippedQuestions: 0,
        numberOfAnsweredQuestions: 3,
        totalNumberOfQuestions: 5,
      },
    ],
    completedQuestionnaires: [],
    lang: 'EN_US',
    role: RolesMap.STUDENT,
  },
];

export const childHasPendingSubmittedQuestionnaires = [
  {
    id: 36,
    email: '4ayostudent@mesquiteisd.org',
    firstName: 'Olivia',
    lastName: '',
    birthDate: '1998-07-24T12:06:39.894',
    loginId: '4ayostudent',
    policyAccepted: true,
    userType: 'CLASS_LINK',
    pendingQuestionnaires: [
      {
        id: 7,
        questionnaireTitle: 'Initial Profiling',
        isSubmitted: true,
        numberOfSkippedQuestions: 2,
        numberOfAnsweredQuestions: 3,
        totalNumberOfQuestions: 5,
      },
    ],
    completedQuestionnaires: [],
    lang: 'EN_US',
    role: RolesMap.STUDENT,
  },
];

export const childWithCompleteQuestionnaires = [
  {
    id: 36,
    email: '4ayostudent@mesquiteisd.org',
    firstName: 'Olivia',
    lastName: '',
    birthDate: '1998-07-24T12:06:39.894',
    loginId: '4ayostudent',
    policyAccepted: true,
    userType: 'CLASS_LINK',
    pendingQuestionnaires: [],
    completedQuestionnaires: [
      {
        id: 7,
        questionnaireTitle: 'Initial Profiling',
        isSubmitted: true,
        numberOfSkippedQuestions: 0,
        numberOfAnsweredQuestions: 5,
        totalNumberOfQuestions: 5,
      },
    ],
    lang: 'EN_US',
    role: RolesMap.STUDENT,
  },
];

export const twoChildrenPending = [
  {
    id: 22,
    email: '3ayostudent@mesquiteisd.org',
    role: 'STUDENT',
    firstName: 'Colten',
    lastName: '',
    birthDate: '2007-07-24T12:06:39.894',
    loginId: '3ayostudent',
    policyAccepted: true,
    userType: 'CLASS_LINK',
    pendingQuestionnaires: [
      {
        id: 7,
        questionnaireTitle: 'Initial Profiling',
        isSubmitted: false,
        numberOfSkippedQuestions: 0,
        numberOfAnsweredQuestions: 1,
        totalNumberOfQuestions: 5,
      },
    ],
    completedQuestionnaires: [],
    lang: 'EN_US',
  },
  {
    id: 36,
    email: '4ayostudent@mesquiteisd.org',
    role: 'STUDENT',
    firstName: 'Olivia',
    lastName: '',
    birthDate: '1998-07-24T12:06:39.894',
    loginId: '4ayostudent',
    policyAccepted: true,
    userType: 'CLASS_LINK',
    pendingQuestionnaires: [
      {
        id: 7,
        questionnaireTitle: 'Initial Profiling',
        isSubmitted: true,
        numberOfSkippedQuestions: 2,
        numberOfAnsweredQuestions: 3,
        totalNumberOfQuestions: 5,
      },
    ],
    completedQuestionnaires: [],
    lang: 'EN_US',
  },
];
export const oneChildPending = [
  {
    id: 22,
    email: '3ayostudent@mesquiteisd.org',
    role: 'STUDENT',
    firstName: 'Colten',
    lastName: '',
    birthDate: '2007-07-24T12:06:39.894',
    loginId: '3ayostudent',
    policyAccepted: true,
    userType: 'CLASS_LINK',
    pendingQuestionnaires: [
      {
        id: 7,
        questionnaireTitle: 'Initial Profiling',
        isSubmitted: false,
        numberOfSkippedQuestions: 0,
        numberOfAnsweredQuestions: 3,
        totalNumberOfQuestions: 5,
      },
    ],
    completedQuestionnaires: [],
    lang: 'EN_US',
  },
  {
    id: 36,
    email: '4ayostudent@mesquiteisd.org',
    role: 'STUDENT',
    firstName: 'Olivia',
    lastName: '',
    birthDate: '1998-07-24T12:06:39.894',
    loginId: '4ayostudent',
    policyAccepted: true,
    userType: 'CLASS_LINK',
    pendingQuestionnaires: [],
    completedQuestionnaires: [
      {
        id: 7,
        questionnaireTitle: 'Initial Profiling',
        isSubmitted: true,
        numberOfSkippedQuestions: 0,
        numberOfAnsweredQuestions: 5,
        totalNumberOfQuestions: 5,
      },
    ],
    lang: 'EN_US',
  },
];

export const question = {
  id: 1,
  skipped: false,
  hidden: false,
  mandatory: true,
  options: [
    {
      id: 1,
      htmlLabel: 'first',
      htmlType: 'TEXT_AREA',
      htmlValue: 'first',
      htmlName: 'first',
      rowsAmount: 2,
      activatorOptionId: 2,
    },
    {
      id: 2,
      skipped: false,
      hidden: false,
      htmlLabel: 'second',
      htmlType: 'RADIO',
      htmlValue: 'true',
      htmlName: 'second',
      rowsAmount: 2,
      activatorOptionId: 1,
    },
    {
      id: 3,
      skipped: false,
      hidden: false,
      htmlLabel: 'third',
      htmlType: 'CHECKBOX',
      htmlValue: 'third',
      htmlName: 'third',
      rowsAmount: 2,
      activatorOptionId: 1,
    },
  ],
};

export const questions = [
  {
    id: 1,
    parentQuestionId: null,
    htmlLabel: 'What do you like?',
    htmlDescription:
      'I know you answered this question previously, but would you mind telling me if anything has changed for you since then?',
    options: [
      {
        id: 1,
        htmlType: 'TEXT_AREA',
        htmlName: 'like',
        htmlLabel: null,
        htmlValue: 'like',
        htmlPlaceholder: 'What do you like?',
        activatorOptionId: null,
        rowsAmount: 3,
      },
    ],
    answers: ['football', 'games'],
    dependentQuestions: null,
    skipped: false,
    mandatory: false,
    editable: true,
    visible: true,
  },
  {
    id: 2,
    parentQuestionId: 1,
    htmlLabel: 'What are you good at?',
    htmlDescription:
      'Thanks for replying. I have one more thing to ask, despite you answering this one previously too.',
    options: [
      {
        id: 2,
        htmlType: 'TEXT_AREA',
        htmlName: 'good',
        htmlLabel: null,
        htmlValue: 'good',
        htmlPlaceholder: 'What are you good at?',
        activatorOptionId: null,
        rowsAmount: 3,
      },
    ],
    answers: [],
    dependentQuestions: null,
    skipped: false,
    mandatory: false,
    editable: true,
    visible: true,
  },
  {
    id: 3,
    parentQuestionId: 2,
    htmlLabel: 'What did you do during your summer break?',
    htmlDescription: 'Thanks for sharing this.\n And one more to go.',
    options: [
      {
        id: 3,
        htmlType: 'TEXT_AREA',
        htmlName: 'did',
        htmlLabel: null,
        htmlValue: 'did',
        htmlPlaceholder: 'What did you do during your summer break?',
        activatorOptionId: null,
        rowsAmount: 3,
      },
    ],
    answers: [],
    dependentQuestions: null,
    skipped: false,
    mandatory: false,
    editable: true,
    visible: true,
  },
];

export const questionsSkipped = [
  {
    id: 1,
    parentQuestionId: null,
    htmlLabel: 'What do you like?',
    htmlDescription:
      'I know you answered this question previously, but would you mind telling me if anything has changed for you since then?',
    options: [
      {
        id: 1,
        htmlType: 'TEXT_AREA',
        htmlName: 'like',
        htmlLabel: null,
        htmlValue: 'like',
        htmlPlaceholder: 'What do you like?',
        activatorOptionId: null,
        rowsAmount: 3,
      },
    ],
    answers: ['football', 'games'],
    dependentQuestions: null,
    skipped: false,
    mandatory: false,
    editable: true,
    visible: true,
  },
  {
    id: 2,
    parentQuestionId: 1,
    htmlLabel: 'What are you good at?',
    htmlDescription:
      'Thanks for replying. I have one more thing to ask, despite you answering this one previously too.',
    options: [
      {
        id: 2,
        htmlType: 'TEXT_AREA',
        htmlName: 'good',
        htmlLabel: null,
        htmlValue: 'good',
        htmlPlaceholder: 'What are you good at?',
        activatorOptionId: null,
        rowsAmount: 3,
      },
    ],
    answers: [],
    dependentQuestions: null,
    skipped: true,
    mandatory: false,
    editable: true,
    visible: true,
  },
  {
    id: 3,
    parentQuestionId: 2,
    htmlLabel: 'What did you do during your summer break?',
    htmlDescription: 'Thanks for sharing this.\n And one more to go.',
    options: [
      {
        id: 3,
        htmlType: 'TEXT_AREA',
        htmlName: 'did',
        htmlLabel: null,
        htmlValue: 'did',
        htmlPlaceholder: 'What did you do during your summer break?',
        activatorOptionId: null,
        rowsAmount: 3,
      },
    ],
    answers: [],
    dependentQuestions: null,
    skipped: true,
    mandatory: false,
    editable: true,
    visible: true,
  },
];

export const questionsForHelper = [
  {
    id: 1,
    answers: [],
    skipped: true,
    hidden: true,
  },
  {
    id: 2,
    answers: [],
    skipped: false,
    hidden: false,
  },
  {
    id: 3,
    answers: [],
    skipped: false,
    hidden: false,
  },
  {
    id: 4,
    answers: [],
    skipped: false,
    hidden: false,
  },
  {
    id: 5,
    answers: [],
    skipped: false,
    hidden: false,
  },
];

export const guardians = [
  {
    answer: 'sport',
    firstName: 'Mom',
    relationship: 'mother',
  },
  {
    answer: 'computer games',
    firstName: 'Dad',
    relationship: 'father',
  },
];

export const student = {
  firstName: 'Bob',
  grade: 9,
  id: 1,
  lastName: 'Bobson',
  schoolName: 'Mesquit ISD',
};

export const person = {
  firstName: 'Bob',
  id: 1,
  login: 'Bobson',
  email: 'bobson@example.com',
  role: 'STUDENT',
};

export const studentList = [
  {
    id: 1,
    name: 'John',
    courses: ['Maths'],
    active: true,
  },
  {
    id: 2,
    name: 'Bob',
    courses: ['Art'],
    active: false,
  },
];

export const studentAllInterests = {
  selectedInterests: [
    {
      id: 1,
      name: 'Humanities',
      reaction: '1',
    },
    {
      id: 2,
      name: 'Board Games',
      reaction: '2',
    },
  ],
  suggestedInterests: [
    {
      id: 50,
      name: 'Adult',
      reaction: '0',
    },
  ],
};

export const studentsAchievements = [
  {
    category: 'Music achievements',
    name: 'Best vocal',
    year: [2020],
  },
  {
    category: 'Sport achievements',
    name: 'Varsity Football',
    year: [2021],
  },
  {
    category: 'Sport achievements',
    name: 'Varsity Football',
    year: [2020],
  },
];

export const studentsOpportunities = {
  studentId: 1234,
  suggestedOpportunities: [
    {
      category: 'Art',
      name: 'Acoustic music and musical environment',
      interested: null,
      confidence: 1,
    },
    {
      category: 'Art',
      name: 'Entertainment',
      interested: null,
      confidence: 0.7,
    },
    {
      category: 'Courses',
      name: 'Cooking',
      interested: null,
      confidence: 0.4,
    },
  ],
  selectedOpportunities: [
    {
      category: 'Sport',
      name: 'Football',
      interested: true,
      updatedDate: '2021-09-27T21:48:17',
    },
    {
      category: 'Science',
      name: 'History',
      interested: false,
      updatedDate: '2021-09-28T11:43:41.519Z',
    },
  ],
};

export const studentsOpportunitiesEmptyState = {
  studentId: 1234,
  suggestedOpportunities: [],
  selectedOpportunities: [],
};

export const interestsTestData = {
  title: 'Test Interests',
  isWithEmoji: true,
  emptyStateText:
    'The list of your interests is empty. Please select what you like below by clicking on the interest with a “+”.',
  studentAllInterests,
};

export const extracurriculars = [
  {
    id: 101,
    year: '2022',
    name: 'Chess',
  },
  {
    id: 103,
    year: '2022',
    name: 'ESports',
  },
  {
    id: 102,
    year: '2022',
    name: 'Photo',
  },
  {
    id: 103,
    year: '2022',
    name: 'Photojournalism',
  },
  {
    id: 104,
    year: '2022',
    name: 'Anime',
  },
  {
    id: 105,
    year: '2022',
    name: 'Manga',
  },
  {
    id: 106,
    year: '2022',
    name: 'Karate',
  },
  {
    id: 107,
    year: '2021',
    name: 'Fellowship of Christian Athletes',
  },
  {
    id: 108,
    year: '2021',
    name: 'Cheerleading',
  },
  {
    id: 109,
    year: '2021',
    name: 'Chess',
  },
  {
    id: 110,
    year: '2021',
    name: 'Soccer',
  },
  {
    id: 111,
    year: '2021',
    name: 'Baseball',
  },
];

export const teacherProfile = {
  role: RolesMap.TEACHER,
};

export const questionAnswers = {
  studentId: 40,
  firstName: '1ayostudent',
  guardians: [
    {
      id: 7,
      firstName: 'Mom',
      lastName: 'Guardian',
      relationshipId: 'mother',
      questionnaires: [
        {
          id: 4,
          title: 'Initial Profiling',
          answers: [
            {
              questionId: 24,
              questionName: 'What motivates $firstName$?',
              answers: ['sport'],
            },
            {
              questionId: 25,
              questionName: "What are $firstName$'s strengths?",
              answers: [],
            },
            {
              questionId: 26,
              questionName: 'What do you think $firstName$ would need my help/support with?',
              answers: ['with math'],
            },
            {
              questionId: 27,
              questionName:
                'What special interests, sport activities, and/or hobbies does $firstName$ have?',
              answers: [],
            },
            {
              questionId: 28,
              questionName: 'What extracurricular activities does $firstName$ participate in?',
              answers: [],
            },
            {
              questionId: 29,
              questionName: 'How do you think $firstName$ learns best?',
              answers: [],
            },
            {
              questionId: 30,
              questionName:
                'What is the thing you hear $firstName$ complain the most about, in regards to school?',
              answers: [],
            },
            {
              questionId: 31,
              questionName: 'As a parent, what makes you most proud of $firstName$?',
              answers: [],
            },
            {
              questionId: 32,
              questionName: 'Is there anything else you would like me to know about $firstName$?',
              answers: [],
            },
          ],
        },
      ],
    },
    {
      id: 8,
      firstName: 'Dad',
      lastName: 'Guardian',
      relationshipId: 'father',
      questionnaires: [
        {
          id: 4,
          title: 'Initial Profiling',
          answers: [
            {
              questionId: 24,
              questionName: 'What motivates $firstName$?',
              answers: ['computer games'],
            },
            {
              questionId: 25,
              questionName: "What are $firstName$'s strengths?",
              answers: [],
            },
            {
              questionId: 26,
              questionName: 'What do you think $firstName$ would need my help/support with?',
              answers: [],
            },
            {
              questionId: 27,
              questionName:
                'What special interests, sport activities, and/or hobbies does $firstName$ have?',
              answers: [],
            },
            {
              questionId: 28,
              questionName: 'What extracurricular activities does $firstName$ participate in?',
              answers: [],
            },
            {
              questionId: 29,
              questionName: 'How do you think $firstName$ learns best?',
              answers: ['history'],
            },
            {
              questionId: 30,
              questionName:
                'What is the thing you hear $firstName$ complain the most about, in regards to school?',
              answers: [],
            },
            {
              questionId: 31,
              questionName: 'As a parent, what makes you most proud of $firstName$?',
              answers: [],
            },
            {
              questionId: 32,
              questionName: 'Is there anything else you would like me to know about $firstName$?',
              answers: [],
            },
          ],
        },
      ],
    },
    {
      id: 9,
      firstName: 'Uncle',
      lastName: 'Guardian',
      relationshipId: 'uncle',
      questionnaires: [
        {
          id: 4,
          title: 'Initial Profiling',
          answers: [],
        },
      ],
    },
  ],
};

export const testArticleBlockConfig = {
  title: 'Our test vision',
  textItems: [
    {
      variant: 'body1',
      className: 'ayo-test-classname',
      align: 'center',
      textNodes: [
        {
          translationText:
            'AYO is here to help students reveal their potential so that each student could experience an individualized learning approach',
        },
      ],
    },
    {
      variant: 'body1',
      align: 'center',
      textNodes: [
        {
          translationText:
            'AYO never intrudes into the learning process or makes decisions for any of its users',
        },
        {
          isEmpty: true,
        },
        {
          translationText: 'AYO neither judges any of its users nor makes any conclusions',
        },
        {
          isEmpty: true,
        },
      ],
    },
  ],
};

export const testLandingBlockConfig = {
  imageTitle: 'Two students and a teacher, at a crossroads',
  titleStart: 'A unique journey',
  titleEnd: 'in the educational landscape',
  titleBottom: `If you're a student, parent, teacher, coach or counselor - start your journey with AYO`,
  loginOptions: [
    {
      loginProvider: LoginProvidersMap.CLASSLINK,
      userRole: RolesMap.STUDENT,
      label: 'Student login',
      disabled: checkEnvironmentVariable('REACT_APP_DISABLE_STUDENT_LOGIN', 'true'),
    },
    {
      loginProvider: LoginProvidersMap.SKYWARD,
      userRole: RolesMap.GUARDIAN,
      label: 'Parent login',
      disabled: checkEnvironmentVariable('REACT_APP_DISABLE_GUARDIAN_LOGIN', 'true'),
    },
    {
      loginProvider: LoginProvidersMap.CLASSLINK,
      userRole: RolesMap.TEACHER,
      label: 'Educator login',
      disabled: checkEnvironmentVariable('REACT_APP_DISABLE_TEACHER_LOGIN', 'true'),
    },
  ],
  instructionText:
    'For other members of Mesquite ISD community please scroll down and find the Inspiration Book',
  mainImg: '',
};

export const testMediaBlockConfig = {
  title: 'Test for AYO in media',
  subTitle: 'Check out the latest articles and videos from the media around us',
  items: [
    {
      link: telemundodallasVideoRoute,
      actionTxt: 'Watch the video',
      img: '',
      imgAlt: 'A picture of a video preview',
      content: 'Test content',
      contentLabel: 'Video content',
      date: '2020-11-23',
      sourceName: 'Telemundodallas',
      title: 'Innovador sistema de educación en Mesquite',
      mediaClickable: true,
    },
    {
      link: dallasNewsArticleRoute,
      actionTxt: 'Read the article',
      img: '',
      imgAlt: 'A woman is helping a student child to study by using a laptop',
      content: 'Test content',
      contentLabel: '',
      date: '2020-09-14',
      sourceName: 'Dallas News',
      title: 'Mesquite ISD looks to bring education into the digital age with AYO project',
    },
  ],
};

export const testImgWithCaptionConfig = {
  className: 'ayo-test-class',
  altTxt: 'alt text',
  src: 'some/file',
  caption: {
    align: 'center',
    className: 'ayo-test-caption-class',
    textNodes: [],
    variant: 'body',
  },
};

export const testDataGridConfig = {
  columns: [
    { field: 'name', headerName: 'Name', flex: 0.4, searchable: true },
    { field: 'email', headerName: 'Email', flex: 0.4 },
    { field: 'role', headerName: 'Role', flex: 0.2 },
  ],
  rows: [
    { name: 'Dima', email: 'mariajose@classlink.com', role: 'Admin', id: 1 },
    { name: 'Ivan', email: 'mariajose@classlink.com', role: 'Admin', id: 2 },
    { name: 'Sergii', email: 'mariajose@classlink.com', role: 'Teacher', id: 3 },
  ],
  searchValue: 'Dima',
};

export const testNumericFieldConfig = {
  defaultValue: 5,
  maxValue: 10,
  minValue: 2,
};

export const testTimelineBlockConfig = {
  items: [
    {
      content: {
        variant: 'body3',
        className: 'ayo-timeline--current',
        text: 'This is happening now',
      },
      opposite: {
        variant: 'body2',
        text: 'AYO is getting to know each student personally to learn about their individual passions and aptitudes',
      },
    },
    {
      content: {
        variant: 'body2',
        text: 'AYO will learn and understand each individual student as they grow up and change to help them build their own educational path',
      },
    },
    {
      opposite: {
        variant: 'body2',
        text: 'AYO assists students in their learning by helping them make informed choices to maximize their potential',
      },
    },
  ],
};

export const testPersonasBlockConfig = {
  title: 'Test title',
  personaItems: [
    {
      img: 'studentImage',
      avatarTxt: 'A portrait of a student',
      title: 'Students',
      text: 'AYO focuses on students as individuals by asking questions and encouraging them to learn more about themselves. AYO helps students understand their potential based on where their passions and aptitudes align',
    },
    {
      img: 'educatorImage',
      avatarTxt: 'A portrait of a teacher',
      title: 'Educators',
      text: 'AYO is also built for educators to help them individualize their teaching approach to meet the needs of each student',
    },
    {
      img: 'parentImage',
      avatarTxt: 'A portrait of a parent',
      title: 'Parents',
      text: 'AYO aims at helping parents support their children throughout their school years by providing an unbiased overview of their child’s talents and passions',
    },
  ],
};

export const testPersonalizationBlockConfig = {
  mainTitle: 'Test Main title',
  firstSubtitle: 'AYO is here to help with',
  firstSubtitleBold: 'Inspiration Book',
  firstSubtitleEnd: 'to learn more about AYO',
  downloadOptions: [
    {
      ariaLabel: 'Download Inspiration Book in English',
      gaLabel: 'Download Inspiration Book in English',
      label: 'Download in English',
      link: 'downloadInspirationBookEnglishRoute',
    },
    {
      ariaLabel: 'Descargarlo Libro de inspiración en Español',
      gaLabel: 'Download Inspiration Book in Spanish',
      label: 'Descargarlo en Español',
      link: 'downloadInspirationBookSpanishRoute',
    },
  ],
  illustrationText:
    'Three recent high school graduates are jumping and celebrating their graduation',
  illustrationImg: 'visionImage',
};

export const testQuoteBlock = {
  avatarTxt: 'Dr. Rivera',
  avatarImg: 'riveraImage',
  name: 'Dr. Rivera',
  title: 'Mesquite ISD Superintendant',
  text: 'The education...',
};

export const testRoadMapBlock = {
  timeline: testTimelineBlockConfig,
  caption: {
    variant: 'h2',
    align: 'center',
    component: 'h2',
    textNodes: [
      {
        translationText: 'AYO launches this year and is going to unfold in the years to come',
      },
      {
        isEmpty: true,
      },
      {
        translationText: 'Here is how AYO is going to affect learning in the near future',
      },
    ],
  },
  titleTop: {
    variant: 'subtitle1',
    align: 'h3',
    isParagraph: true,
    textNodes: [
      {
        translationText: 'Before AYO',
      },
    ],
  },
  subTitleTop: {
    variant: 'body2',
    align: 'center',
    textNodes: [
      {
        translationText: 'Students studied...',
      },
    ],
  },
  titleBottom: {
    variant: 'subtitle1',
    align: 'h3',
    isParagraph: true,
    textNodes: [
      {
        translationText: 'With AYO',
      },
    ],
  },
  subTitleBottom: {
    variant: 'body2',
    align: 'center',
    textNodes: [
      {
        translationText: 'Each student receives a tailored learning experience',
      },
      {
        isEmpty: true,
      },
      {
        translationText:
          'Students are able to make informed choices as they understand where their potential lies',
      },
    ],
  },
};

export const DislikedInterestsTestData = {
  buttonText: ['descktopButton', 'mobileButton'],
  secondaryText: ['testText', 'testSecondaryText'],
  title: 'Not Interested',
  interests: [
    {
      id: 27,
      name: 'Hybrid & Alternative Vehicles',
      reaction: 0,
    },
    {
      id: 28,
      name: 'Motorcycles',
      reaction: 0,
    },
    {
      id: 29,
      name: 'Off-Road Vehicles',
      reaction: 0,
    },
  ],
};

export const exploreInterests = [
  {
    id: 1,
    name: 'Humanities',
    reaction: null,
  },
  {
    id: 2,
    name: 'Board Games',
    reaction: null,
  },
  {
    id: 3,
    name: 'Books & Literature',
    reaction: null,
  },
  {
    id: 4,
    name: 'TV & Video',
    reaction: null,
  },
  {
    id: 5,
    name: 'Movies',
    reaction: null,
  },
  {
    id: 6,
    name: 'Music',
    reaction: null,
  },
  {
    id: 7,
    name: 'Performing Arts',
    reaction: null,
  },
  {
    id: 8,
    name: 'Visual Art & Design',
    reaction: null,
  },
  {
    id: 9,
    name: 'Cooking & Recipes',
    reaction: null,
  },
  {
    id: 10,
    name: 'Computer & Video Games',
    reaction: null,
  },
  {
    id: 11,
    name: 'Outdoors',
    reaction: null,
  },
  {
    id: 12,
    name: 'Radio Control & Modeling',
    reaction: null,
  },
  {
    id: 13,
    name: 'Sports',
    reaction: null,
  },
  {
    id: 14,
    name: 'Languages',
    reaction: null,
  },
  {
    id: 15,
    name: 'Philosophy',
    reaction: null,
  },
  {
    id: 16,
    name: 'Cartoons',
    reaction: null,
  },
  {
    id: 17,
    name: 'Bars, Clubs & Nightlife',
    reaction: null,
  },
  {
    id: 18,
    name: 'Humor',
    reaction: null,
  },
  {
    id: 19,
    name: 'Political Humor',
    reaction: null,
  },
  {
    id: 20,
    name: 'Radio',
    reaction: null,
  },
  {
    id: 21,
    name: 'Religious Music',
    reaction: null,
  },
  {
    id: 22,
    name: 'Rock Music',
    reaction: null,
  },
  {
    id: 23,
    name: 'World Music',
    reaction: null,
  },
  {
    id: 24,
    name: 'Bicycles & Accessories',
    reaction: null,
  },
  {
    id: 25,
    name: 'Bike Parts & Repair',
    reaction: null,
  },
  {
    id: 26,
    name: 'Classic Vehicles',
    reaction: null,
  },
  {
    id: 27,
    name: 'Hybrid & Alternative Vehicles',
    reaction: null,
  },
  {
    id: 28,
    name: 'Motorcycles',
    reaction: null,
  },
  {
    id: 29,
    name: 'Off-Road Vehicles',
    reaction: null,
  },
  {
    id: 30,
    name: 'Vehicle Codes & Driving Laws',
    reaction: null,
  },
  {
    id: 31,
    name: 'Vehicle Parts & Services',
    reaction: null,
  },
  {
    id: 32,
    name: 'Used Vehicles',
    reaction: 0,
  },
  {
    id: 33,
    name: 'Vehicle',
    reaction: 0,
  },
  {
    id: 34,
    name: 'Fitness',
    reaction: 0,
  },
  {
    id: 35,
    name: 'Art',
    reaction: 0,
  },
];

export const interestsPlotData = [
  {
    name: 'Adult',
    r: '1',
    randomSpacer: '3',
    reaction: '1',
  },
  {
    name: 'Humanities',
    r: '1',
    randomSpacer: '3',
    reaction: '1',
  },
  {
    name: 'Board Games',
    r: '2',
    randomSpacer: '3',
    reaction: '2',
  },
];

export const interestPlotEmptyStateText = {
  header: 'Test header',
  main: 'No interests selected',
};

export const studentGoalsData = [
  {
    id: 2,
    studentId: 1,
    status: 'COMPLETED',
    name: 'Make a project on geography',
    description: 'description',
    milestones: [
      {
        id: 1,
        name: 'Identify the topic of the project',
        dueDate: '2021-09-27',
        status: 'COMPLETED',
        createdDate: '2021-09-10T07:45:42.000Z',
        updatedDate: '2021-09-10T07:45:42.000Z',
      },
      {
        id: 2,
        name: 'Create presentation',
        dueDate: '2021-10-10',
        status: 'COMPLETED',
        createdDate: '2021-09-10T07:45:42.000Z',
        updatedDate: '2021-09-10T07:45:42.000Z',
      },
    ],
    createdDate: '2021-09-10T07:45:42.000Z',
    updatedDate: '2021-09-10T07:45:42.000Z',
  },
  {
    id: 1,
    studentId: 1,
    status: 'IN_PROGRESS',
    name: 'Get better in Biology',
    description: 'Explore four units before the fall break',
    milestones: [
      {
        id: 1,
        name: 'Finish Unit 1: Biochemistry',
        dueDate: '2021-09-08',
        status: 'COMPLETED',
        createdDate: '2021-09-03T13:35:36.000Z',
        updatedDate: '2021-09-08T06:40:42.000Z',
      },
      {
        id: 2,
        name: 'Finish Unit 2: Metabolic Processes',
        dueDate: '2021-09-14',
        status: 'COMPLETED',
        createdDate: '2021-09-03T13:36:36.000Z',
        updatedDate: '2021-09-14T08:45:42.000Z',
      },
      {
        id: 3,
        name: 'Finish Unit 3: Molecular Genetics',
        dueDate: '2021-09-25',
        status: 'IN_PROGRESS',
        createdDate: '2021-09-15T16:06:39.732844',
        updatedDate: '2021-09-15T16:06:39.732852',
      },
      {
        id: 4,
        name: 'Finish Unit 4: Homeostasis',
        dueDate: '2021-09-30',
        status: 'IN_PROGRESS',
        createdDate: '2021-09-03T13:40:42.000Z',
        updatedDate: '2021-09-03T13:40:42.000Z',
      },
    ],
    createdDate: '2021-09-03T13:38:36.000Z',
    updatedDate: '2021-09-15T16:06:39.732852',
  },
];

export const studentGoalData = {
  id: 1,
  studentId: 1,
  status: 'IN_PROGRESS',
  name: 'Get better in Biology',
  description: 'Explore four units before the fall break',
  milestones: [
    {
      id: 1,
      name: 'Finish Unit 1: Biochemistry',
      dueDate: '2021-09-08',
      status: 'COMPLETED',
      createdDate: '2021-09-03T13:35:36.000Z',
      updatedDate: '2021-09-08T06:40:42.000Z',
    },
    {
      id: 2,
      name: 'Finish Unit 2: Metabolic Processes',
      dueDate: '2021-09-14',
      status: 'COMPLETED',
      createdDate: '2021-09-03T13:36:36.000Z',
      updatedDate: '2021-09-14T08:45:42.000Z',
    },
    {
      id: 3,
      name: 'Finish Unit 3: Molecular Genetics',
      dueDate: '2021-09-25',
      status: 'IN_PROGRESS',
      createdDate: '2021-09-15T16:06:39.732844',
      updatedDate: '2021-09-15T16:06:39.732852',
    },
    {
      id: 4,
      name: 'Finish Unit 4: Homeostasis',
      dueDate: '2021-09-30',
      status: 'IN_PROGRESS',
      createdDate: '2021-09-03T13:40:42.000Z',
      updatedDate: '2021-09-03T13:40:42.000Z',
    },
  ],
  createdDate: '2021-09-03T13:38:36.000Z',
  updatedDate: '2021-09-15T16:06:39.732852',
};

export const studentEvidencesData = [
  {
    id: 1,
    studentId: 1,
    title: 'New Year party organization',
    reflection: 'Organized a New Year party. Everybody was happy!',
    link: 'https://en.wikipedia.org/wiki/New_Year%27s_Eve',
    attachments: [
      {
        id: 1,
        resourceId: 1,

        ownerId: 1,
        fileName: 'NY_party.jpeg',
      },
    ],
    updatedDate: '2021-12-09T11:19:18.293463',
    evidenceResourcesByType: {
      LEADERSHIP_ATTRIBUTE: {
        parentResourceId: 'empathy',
        resourceType: 'LEADERSHIP_ATTRIBUTE',
      },
    },
  },
  {
    id: 11,
    studentId: 1,
    title: 'Gamechanger party organization',
    reflection: 'Organized a New Year party. Everybody was happy!',
    link: 'https://en.wikipedia.org/wiki/New_Year%27s_Eve',
    attachments: [
      {
        id: 1,
        resourceId: 1,
        ownerId: 1,
        fileName: 'NY_party.jpeg',
      },
      {
        id: 12,
        resourceId: 1,
        ownerId: 1,
        fileName: 'NY_party.jpeg',
      },
    ],
    updatedDate: '2021-12-09T11:19:18.293463',
    evidenceResourcesByType: {
      LEADERSHIP_ATTRIBUTE: {
        parentResourceId: 'empathy',
        resourceType: 'LEADERSHIP_ATTRIBUTE',
      },
      GOAL: {
        parentResourceId: '1',
        resourceId: '1',
        resourceType: 'GOAL',
      },
    },
  },
  {
    id: 12,
    studentId: 1,
    title: 'Win the battle',
    reflection: 'Organized a New Year party. Everybody was happy!',
    link: 'https://en.wikipedia.org/wiki/New_Year%27s_Eve',
    attachments: [
      {
        id: 1,
        resourceId: 1,
        ownerId: 1,
        fileName: 'NY_party.jpeg',
      },
    ],
    updatedDate: '2021-12-09T11:19:18.293463',
    evidenceResourcesByType: {
      LEADERSHIP_ATTRIBUTE: {
        parentResourceId: 'empathy',
        resourceType: 'LA',
      },
      GOAL: {
        parentResourceId: '4',
        resourceId: '1',
        resourceType: 'GOAL',
      },
    },
  },
  {
    id: 3,
    studentId: 1,
    title: 'One more New Year party organization',
    reflection: '',
    link: '',
    attachments: [
      {
        id: 1,
        resourceId: 1,
        ownerId: 1,
        fileName: 'NY_party.jpeg',
      },
      {
        id: 12,
        resourceId: 1,
        ownerId: 1,
        fileName: 'NY_party.jpeg',
      },
    ],
    updatedDate: '2021-12-09T11:19:18.293463',
    evidenceResourcesByType: {
      LEADERSHIP_ATTRIBUTE: {
        parentResourceId: 'collaboration',
        resourceType: 'LEADERSHIP_ATTRIBUTE',
      },
      GOAL: {
        parentResourceId: '1',
        resourceId: '3',
        resourceType: 'GOAL',
      },
    },
  },
];

export const studentTourState = {
  forceTourOpen: null,
  newFeaturesSeen: [],
  tourStatus: { 'my-profile-tour': { status: 'FINISHED' } },
};

export const portfolioAchievements = [
  {
    category: 'Music Achievements',
    name: 'Best vocal',
    year: [2020, 2021],
  },
  {
    category: 'Sport Achievements',
    name: 'Varsity Football',
    year: [2021],
  },
  {
    category: 'Sport Achievements',
    name: 'Basketball',
    year: [2018],
  },
  {
    category: 'Varsity Baseball',
    name: 'Soccer',
    year: [2019],
  },
  {
    category: 'Participation in competitions',
    name: 'Academic Decathlon',
    year: [2021],
  },
  {
    category: 'National Societies Membership',
    name: 'NJHC Goebel',
    year: [2020],
  },
  {
    category: 'Academic Achievements',
    name: 'A in AP U.S. History',
    year: [2018],
  },
];

export const aptitudes = [
  {
    type: 'ARTISTIC',
    score: 0.917177225381704,
  },
  {
    type: 'PHYSICAL',
    score: 0.86175340865834,
  },
  {
    type: 'VERBAL',
    score: 0.917177225381704,
  },
  {
    type: 'NUMERICAL',
    score: 0.86175340865834,
  },
];

export const endorsements = {
  endorsements: [
    {
      id: 1,
      endorsementName: 'Business and Industry',
      nameTranslationKey: 'business_and_industry_394179f5df664678763c82308e82270d',
      createdDate: '2021-03-06 13:30:00',
      shareAvailable: true,
      questionId: 32,
      sharedDetails: [
        {
          optionId: 1,
          value: "Didn't learn enough about all endorsements.",
          optionType: 'CHECKBOX',
          activatorOptionId: null,
          selected: true,
          htmlPlaceholder: null,
        },
        {
          optionId: 2,
          value: "Didn't know how to apply endorsements in practice.",
          optionType: 'CHECKBOX',
          activatorOptionId: null,
          selected: false,
          htmlPlaceholder: null,
        },
        {
          optionId: 3,
          value: "Didn't realize my vocation when choosing the endorsement.",
          optionType: 'CHECKBOX',
          activatorOptionId: null,
          selected: false,
          htmlPlaceholder: null,
        },
        {
          optionId: 4,
          value: 'Switched my hobby or interest area.',
          optionType: 'CHECKBOX',
          activatorOptionId: null,
          selected: false,
          htmlPlaceholder: null,
        },
        {
          optionId: 5,
          value: 'Picked a trending direction and realized I’m not into it.',
          optionType: 'CHECKBOX',
          activatorOptionId: null,
          selected: true,
          htmlPlaceholder: null,
        },
        {
          optionId: 6,
          value: 'Have schedule conflicts.',
          optionType: 'CHECKBOX',
          activatorOptionId: null,
          selected: true,
          htmlPlaceholder: null,
        },
        {
          optionId: 7,
          value: 'Other',
          optionType: 'CHECKBOX',
          activatorOptionId: null,
          selected: true,
          htmlPlaceholder: null,
        },
        {
          optionId: 8,
          value: 'had no time',
          optionType: 'TEXT_AREA',
          activatorOptionId: 7,
          selected: null,
          htmlPlaceholder: 'Add your thoughts',
        },
      ],
    },
  ],
  interestedEndorsements: [
    {
      id: 11,
      endorsementName: 'Business and Industry',
      nameTranslationKey: 'business_and_industry_394179f5df664678763c82308e82270d',
      updatedDate: '2021-03-06 13:30:00',
    },
  ],
};

export const studentPortfolio = {
  entity: {
    id: 1,
    sections: {
      PORTFOLIO__PERSONAL_INFO: {
        id: 1,
        reflection: null,
        isInitialState: true,
        name: 'Calvin Roberts',
        email: 'croberts@mesquiteisd.org',
        school: 'Berry Middle School',
        phone: '(545) 454-5454',
        sectionSettings: [
          {
            name: 'DISPLAY_SECTION',
            value: '1',
            defaultValue: '1',
          },
        ],
        summary: null,
      },
      PORTFOLIO__INTERESTS: {
        id: 2,
        reflection: null,
        isInitialState: true,
        entities: [],
        sectionSettings: [
          {
            name: 'DISPLAY_SECTION',
            value: '1',
            defaultValue: '1',
          },
        ],
      },
      PORTFOLIO__APTITUDES: {
        id: 3,
        reflection: null,
        isInitialState: true,
        entities: [],
        sectionSettings: [
          {
            name: 'DISPLAY_SECTION',
            value: '1',
            defaultValue: '1',
          },
        ],
      },
      PORTFOLIO__ENDORSEMENTS: {
        id: 4,
        reflection: null,
        isInitialState: true,
        entities: [],
        sectionSettings: [
          {
            name: 'DISPLAY_SECTION',
            value: '1',
            defaultValue: '1',
          },
        ],
      },
      PORTFOLIO__LEADERSHIP_ATTRIBUTES: {
        id: 5,
        reflection: null,
        isInitialState: true,
        entities: [],
        sectionSettings: [
          {
            name: 'DISPLAY_MATURITY_GRAPH',
            value: null,
            defaultValue: '1',
          },
          {
            name: 'DISPLAY_SECTION',
            value: '1',
            defaultValue: '1',
          },
        ],
      },
      PORTFOLIO__EXTRACURRICULARS: {
        id: 6,
        reflection: null,
        isInitialState: true,
        entities: [],
        sectionSettings: [
          {
            name: 'DISPLAY_SECTION',
            value: '1',
            defaultValue: '1',
          },
        ],
      },
      PORTFOLIO__ACHIEVEMENTS: {
        id: 7,
        reflection: null,
        isInitialState: true,
        entities: [],
        sectionSettings: [
          {
            name: 'DISPLAY_SECTION',
            value: '1',
            defaultValue: '1',
          },
        ],
      },
    },
  },
  errorCode: null,
  errorMessage: null,
};

export const mockBadge = {
  completionLevels: [],
  defaultLevels: [
    {
      levelKey: 'first',
    },
    {
      levelKey: 'second',
    },
    {
      levelKey: 'third',
    },
  ],
  image: {},
  nameKey: 'badge',
  rating: [
    {
      levelKey: 'first',
    },
    {
      levelKey: 'second',
    },
    {
      levelKey: 'third',
    },
  ],
};

export const postConfig = {
  id: 1,
  title: 'Feed Post 1',
  isAuthor: true,
  description: 'Created',
  author: 'Teacher',
  category: 'ANNOUNCEMENTS',
  status: 'NEW',
  sharingLevel: 'DISTRICT',
  selectedEntities: [
    {
      id: 1234,
      name: 'Mesquite ISD',
    },
  ],
  createdDate: '2023-07-25T12:46:05Z',
  updatedDate: '2023-07-25T12:46:05Z',
  attachments: [],
};

export const studentEndorsements = {
  endorsements: [
    {
      id: 1,
      endorsementName: 'Business and Industry',
      nameTranslationKey: 'business_and_industry_394179f5df664678763c82308e82270d',
      createdDate: '2021-03-06 13:30:00',
      shareAvailable: true,
      questionId: 32,
      sharedDetails: [
        {
          optionId: 1,
          value: "Didn't learn enough about all endorsements.",
          optionType: 'CHECKBOX',
          activatorOptionId: null,
          selected: true,
          htmlPlaceholder: null,
        },
        {
          optionId: 2,
          value: "Didn't know how to apply endorsements in practice.",
          optionType: 'CHECKBOX',
          activatorOptionId: null,
          selected: false,
          htmlPlaceholder: null,
        },
        {
          optionId: 3,
          value: "Didn't realize my vocation when choosing the endorsement.",
          optionType: 'CHECKBOX',
          activatorOptionId: null,
          selected: false,
          htmlPlaceholder: null,
        },
        {
          optionId: 4,
          value: 'Switched my hobby or interest area.',
          optionType: 'CHECKBOX',
          activatorOptionId: null,
          selected: false,
          htmlPlaceholder: null,
        },
        {
          optionId: 5,
          value: 'Picked a trending direction and realized I’m not into it.',
          optionType: 'CHECKBOX',
          activatorOptionId: null,
          selected: true,
          htmlPlaceholder: null,
        },
        {
          optionId: 6,
          value: 'Have schedule conflicts.',
          optionType: 'CHECKBOX',
          activatorOptionId: null,
          selected: true,
          htmlPlaceholder: null,
        },
        {
          optionId: 7,
          value: 'Other',
          optionType: 'CHECKBOX',
          activatorOptionId: null,
          selected: true,
          htmlPlaceholder: null,
        },
        {
          optionId: 8,
          value: 'had no time',
          optionType: 'TEXT_AREA',
          activatorOptionId: 7,
          selected: null,
          htmlPlaceholder: 'Add your thoughts',
        },
      ],
    },
  ],
  interestedEndorsements: [
    {
      id: 11,
      endorsementName: 'Business and Industry',
      nameTranslationKey: 'business_and_industry_394179f5df664678763c82308e82270d',
      updatedDate: '2021-03-06 13:30:00',
    },
  ],
};

export default {
  EmailValidatorErrorsMap,
};
