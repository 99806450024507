import React, { useState } from 'react';
import { DragDropContext as BeautifulDragDropContext } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';

const dragHandleUsageInstructions =
  'Press space bar to start dragging. When dragging, use the arrow keys to move the item around and to scroll the page, and escape key—to cancel. You can move the card only to the groups available on the screen. Press space to drop the card into a group. Some screen readers may require you to be in focus mode or to use your pass through key';
const DragDropContext = ({ children, onDragStart, onDragUpdate, onDragEnd, instructionText }) => {
  const [isDragging, setIsDragging] = useState(false);

  return (
    <BeautifulDragDropContext
      dragHandleUsageInstructions={instructionText}
      onBeforeCapture={() => setIsDragging(true)}
      onDragEnd={(result, provided) => {
        setIsDragging(false);
        onDragEnd(result, provided);
      }}
      onDragStart={onDragStart}
      onDragUpdate={onDragUpdate}
    >
      {children}
      {isDragging && (
        <style>
          {`body {
                cursor: move;
              }`}
        </style>
      )}
    </BeautifulDragDropContext>
  );
};

DragDropContext.propTypes = {
  children: PropTypes.node.isRequired,
  onDragStart: PropTypes.func,
  onDragEnd: PropTypes.func,
  onDragUpdate: PropTypes.func,
  instructionText: PropTypes.string,
};

DragDropContext.defaultProps = {
  onDragStart: () => {},
  onDragEnd: () => {},
  onDragUpdate: () => {},
  instructionText: dragHandleUsageInstructions,
};

export default DragDropContext;
