import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Grid, Box, Container, useMediaQuery, useTheme } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Button, DotIndicator, Link, Typography } from '../../../atoms';
import {
  LinkWithTooltip,
  ClickableCard,
  InitiativeEmptyStateBlock,
  InformationalMessage,
  DisabledControlWithTooltip,
  NewFeatureHotspot,
  StudentConsentButton,
  PageEndScrollToTopButton,
  MlChip,
} from '../../../moleculas';
import { PageWrapper } from '../../../organisms';
import { FamilyFeedActions, FamilyFeedContext, UserContext } from '../../../../context';
import { familyFeedActivitiesRoute, familyFeedRoute } from '../../../../constants/routes';
import { useNotificationsData, useOneTimeAction, useSearchParams } from '../../../../hooks';
import { NotificationResourcesMap, OneTimeActionsMap } from '../../../../constants/enums';
import { useFeedService } from '../../../../services';
import {
  checkEnvironmentVariable,
  getNormalizedLanguageCode,
  transformSearchToObject,
} from '../../../../utils';
import { ReactComponent as StudentProfileIcon } from '../../../../resources/icons/my_profile.svg';
import { ReactComponent as NoFamilyActivitySuggestions } from '../../../../resources/images/no_family_activity_suggestions.svg';
import { NewFeaturesIdsMap } from '../../../../tours/common/NewFeaturesItemsProvider';
import PostsFilter from '../../family-feed/components/posts-filter/PostsFilter';
import { SuggestedFamilyActivityCardPreview } from '../components/family-activity-card/FamilyActivityCard';
import FamilyActivityDialog from '../components/family-activity-dialog/FamilyActivityDialog';
import FeedBlock from '../../family-feed/components/feed-block/FeedBlock';
import FamilyFeedEvents from '../../family-feed/components/family-feed-events/FamilyFeedEvents';

const IS_FAMILY_ACTIVITIES_AVAILABLE = checkEnvironmentVariable(
  'REACT_APP_IS_FAMILY_ACTIVITIES_AVAILABLE',
  'true',
);

const FamilyActivitiesSuggested = ({ studentId }) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isWidthUpMd = useMediaQuery(theme.breakpoints.up('md'));

  const { state: familyFeedState, dispatch: dispatchFamilyFeedState } =
    useContext(FamilyFeedContext);
  const { familyActivitiesSuggested } = familyFeedState;

  const { getFamilyActivitiesSuggested } = useFeedService();

  const [learningMoreItem, setLearningMoreItem] = useState(null);

  const handleFamilyActivityDialogOpen = useCallback(
    (id) => {
      setLearningMoreItem(familyActivitiesSuggested.find((activity) => activity.id === id));
    },
    [familyActivitiesSuggested],
  );

  const handleFamilyActivityDialogClose = useCallback(() => {
    setLearningMoreItem(null);
  }, []);

  const suggestedActivities = isWidthUpMd ? (
    <Box className="family-activities-suggested-block" p={3}>
      <Box display="flex" flexDirection="column" rowGap={2}>
        <Box>
          <Box columnGap={1} display="flex" justifyContent="space-between" mb={1}>
            <Typography variant="subtitle1">{t('Suggestions for Family activities')}</Typography>
            <MlChip noWrap />
          </Box>
          <Typography variant="body2">
            {t('Here, you can find suggestions for activities paragraph')}
          </Typography>
        </Box>
        {familyActivitiesSuggested?.slice(0, 5).map(({ id, title, imageAltText, imageUrl }) => (
          <SuggestedFamilyActivityCardPreview
            key={id}
            imgAlt={imageAltText}
            imgSrc={imageUrl}
            onLearnMoreClick={() => handleFamilyActivityDialogOpen(id)}
            title={title}
          />
        ))}
        <Link
          component={RouterLink}
          gaLabel="Show all Family activities"
          to={familyFeedActivitiesRoute.replace(':studentId', studentId)}
        >
          {t('Show all Family activities')}
        </Link>
      </Box>
    </Box>
  ) : (
    <ClickableCard
      ariaLabel={t('Show suggestions for Family activities')}
      className="family-activities-suggested-block__show-button suggested-family-activity-background"
      gaLabel="Show suggestions for Family activities"
      href={familyFeedActivitiesRoute.replace(':studentId', studentId)}
      mainContent={
        <Box columnGap={1} display="flex" justifyContent="space-between">
          <Typography variant="subtitle2">{t('Show suggestions for Family activities')}</Typography>
          <MlChip noWrap />
        </Box>
      }
      title={t('Show suggestions for Family activities')}
    />
  );

  const emptyState = (
    <InitiativeEmptyStateBlock
      body={t('As soon as AYO discovers them, the right ones will appear here')}
      hint={
        <Link
          component={RouterLink}
          gaLabel="Show all Family activities"
          to={familyFeedActivitiesRoute.replace(':studentId', studentId)}
        >
          {t('Show all Family activities')}
        </Link>
      }
      illustration={<NoFamilyActivitySuggestions />}
      title={t('No suggestions so far')}
    />
  );

  useEffect(() => {
    // initial fetch and re-fetch on studentId/language change
    getFamilyActivitiesSuggested(studentId, getNormalizedLanguageCode(i18n.language), true).then(
      (data) => {
        dispatchFamilyFeedState({
          type: FamilyFeedActions.SET_FAMILY_ACTIVITIES_SUGGESTED,
          data,
        });
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentId, i18n.language]);

  return (
    <Grid item xs={12}>
      {familyActivitiesSuggested?.length ? suggestedActivities : emptyState}
      <FamilyActivityDialog
        isOpen={!!learningMoreItem}
        item={learningMoreItem}
        onClose={handleFamilyActivityDialogClose}
        onLike={() => {}}
      />
    </Grid>
  );
};

FamilyActivitiesSuggested.propTypes = {
  studentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

const KidsListPage = () => {
  const { t } = useTranslation();
  const { state: userState } = useContext(UserContext);
  const { state: familyFeedState } = useContext(FamilyFeedContext);
  const { postFilterCategories } = familyFeedState;

  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  const kidsCount = userState.dependentProfiles?.length;

  const search = useSearchParams();
  const { studentId, postId } = transformSearchToObject(search);

  const [selectedKid, setSelectedKid] = useState(
    userState.dependentProfiles?.find(({ id }) => id === Number(studentId)) ||
      userState.dependentProfiles?.[0],
  );

  const { actionState: greenRibbonNotificationState, onAction: handleGreenRibbonNotification } =
    useOneTimeAction(OneTimeActionsMap.STUDENTS_ACTIVITY_NOTIFICATION);

  const { notificationsList } = useNotificationsData();

  const allPostsNotifications = useMemo(
    () =>
      notificationsList?.WEB_HIDDEN?.filter(
        (notification) =>
          notification.resource === NotificationResourcesMap.FEED_POST_NEW_INDICATOR,
      ),
    [notificationsList],
  );

  const studentFeedPostsStatuses = useMemo(
    () =>
      userState.dependentProfiles?.reduce((acc, children) => {
        const unreadChildrenPostNotification = allPostsNotifications?.find(
          (notification) =>
            !notification.markedAsRead &&
            notification.metadata.info.relatedUsersIds.includes(children.id),
        );
        return { ...acc, [children.id]: !!unreadChildrenPostNotification };
      }, {}),
    [allPostsNotifications, userState.dependentProfiles],
  );

  const { isGuardianWithoutStudentConsent } = userState;

  const showFamilyActivities = useMemo(
    () => !isGuardianWithoutStudentConsent && selectedKid && IS_FAMILY_ACTIVITIES_AVAILABLE,
    [isGuardianWithoutStudentConsent, selectedKid],
  );

  const guardianWithKidsContent = !isGuardianWithoutStudentConsent && (
    <>
      <Grid item xs={12}>
        <Typography mb={3} variant="h2">
          {t('My kid(s)')}
        </Typography>
        {!greenRibbonNotificationState && (
          <Box mb={3}>
            <InformationalMessage
              onClick={handleGreenRibbonNotification}
              text={
                <Trans
                  components={{
                    green: <span className="ayo-informational-message__description__indicator" />,
                  }}
                  i18nKey="A green ribbon means there’s information in the profile"
                />
              }
            />
          </Box>
        )}
      </Grid>
      <Grid container item spacing={2} xs={12}>
        {userState.dependentProfiles?.map((student, index) => (
          <Grid key={student.id} item sm={3} xs={12}>
            <DisabledControlWithTooltip
              showTooltip={studentFeedPostsStatuses[student.id]}
              title={t('There are new posts in your kid’s feed.')}
            >
              <ClickableCard
                ariaLabel={`${student.id === selectedKid.id ? t('selected') : ''}`}
                className={classNames('ayo-children-card', {
                  selected: student.id === selectedKid.id,
                })}
                gaLabel={`Kid card ${index + 1} out of ${kidsCount}`}
                isActive={student.active}
                onClick={() => setSelectedKid(student)}
                statusIcon={studentFeedPostsStatuses[student.id] && <DotIndicator />}
                title={`${student.firstName}`}
              />
            </DisabledControlWithTooltip>
          </Grid>
        ))}
      </Grid>
      <Grid item xs={12}>
        <Box mb={8} pt={isWidthUpSm ? 3 : 4}>
          <LinkWithTooltip tooltipPlacement="bottom-start" />
        </Box>
      </Grid>
    </>
  );

  const guardianWithoutKidsContent = isGuardianWithoutStudentConsent && (
    <Grid item mb={3} md={6} xs={12}>
      <Typography variant="body2">
        {t('Guardian without student consent feed description')}
      </Typography>
      <Box mt={1}>{isGuardianWithoutStudentConsent && <StudentConsentButton />}</Box>
    </Grid>
  );

  return (
    <PageWrapper
      backToLink="/"
      backToText={t('Go to Main page')}
      mainElementClassName="ayo-kids-page__main"
    >
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <Box
              alignItems="flex-start"
              display="flex"
              flexDirection={isWidthUpSm ? 'row' : 'column'}
              justifyContent="space-between"
              mb={!isGuardianWithoutStudentConsent ? 5 : 1}
            >
              <Box alignItems="center" display="flex" flexDirection="row">
                <Typography variant="h1">{t('Family feed')}</Typography>
                <NewFeatureHotspot
                  id={NewFeaturesIdsMap.POST_MESSAGES}
                  isClickable
                  label={t('Messages')}
                />
              </Box>
            </Box>
          </Grid>
          {isGuardianWithoutStudentConsent ? guardianWithoutKidsContent : guardianWithKidsContent}
          <Grid item xs={12}>
            {selectedKid && (
              <Box alignItems="center" display="flex" justifyContent="space-between" mb={3}>
                <Typography variant="subtitle1">
                  {t('Childrens journey', { name: selectedKid.firstName })}
                </Typography>
                <Button
                  className="go-to-kid-profile"
                  component={RouterLink}
                  endIcon={<StudentProfileIcon />}
                  gaLabel="Go to profile"
                  to={`${familyFeedRoute}/${selectedKid.id}`}
                  variant="text"
                >
                  {t('Go to profile')}
                </Button>
              </Box>
            )}
          </Grid>
          <Grid
            container
            flexDirection={isWidthUpSm ? 'row' : 'column-reverse'}
            item
            spacing={isWidthUpSm ? 3 : 4}
          >
            <Grid container flexDirection="column" item md={8} sm={7}>
              <Typography mb={3} variant="h2">
                {t('Posts')}
              </Typography>
              {!!postFilterCategories?.length && (
                <Box mb={4}>
                  <PostsFilter />
                </Box>
              )}
              {showFamilyActivities && !isWidthUpSm && (
                <Box mb={3}>
                  <FamilyActivitiesSuggested studentId={selectedKid.id} />
                </Box>
              )}
              <FeedBlock
                postId={Number(postId)}
                studentId={selectedKid?.id}
                studentSchool={selectedKid?.schoolName}
              />
            </Grid>
            <Grid item md={4} sm={5}>
              <Box alignItems="center" display="flex" mb={3}>
                <Typography mb={3} variant="h2">
                  {t('Upcoming events')}
                </Typography>
                <NewFeatureHotspot
                  id={NewFeaturesIdsMap.FEED_EVENTS}
                  isClickable
                  label={t('nft.feed_events.title.viewer')}
                />
              </Box>
              <FamilyFeedEvents studentId={selectedKid.id} />
              {showFamilyActivities && isWidthUpSm && (
                <Box mt={3}>
                  <FamilyActivitiesSuggested studentId={selectedKid.id} />
                </Box>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <PageEndScrollToTopButton />
    </PageWrapper>
  );
};

export default KidsListPage;
