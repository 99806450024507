import { Container } from '@mui/material';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Typography } from '../../atoms';
import { useTeachersService } from '../../../services';
import { UserContext } from '../../../context';
import { campusSelectionRoute } from '../../../constants/routes';
import { ReactComponent as ExitIcon } from '../../../resources/icons/exit.svg';

const DEMO_STUDENT_SUFFIX = '_demo_student';

const DemoUserSnackbar = () => {
  const { state: userState } = useContext(UserContext);

  const { t } = useTranslation();

  const isDemoUser = userState?.profile?.loginId?.endsWith(DEMO_STUDENT_SUFFIX);

  const { postDemoSwitch } = useTeachersService();

  if (!isDemoUser) return null;

  return (
    <div className="demo-user-snackbar__container">
      <div className="demo-user-snackbar__sticky">
        <Container>
          <div className="demo-user-snackbar">
            <Typography variant="body2">{t('You’re currently using AYO as a student.')}</Typography>
            <Button
              endIcon={<ExitIcon />}
              gaLabel="Exit student demo mode"
              onClick={() => {
                postDemoSwitch().then(() => {
                  window.location.assign(campusSelectionRoute);
                });
              }}
            >
              {t('Exit student mode')}
            </Button>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default DemoUserSnackbar;
