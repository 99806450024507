import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';

import { Typography, Button } from '../../../atoms';
import { useCommentsData } from '../../../../hooks';
import { ReactComponent as AddIcon } from '../../../../resources/icons/add.svg';
import CommentsCard from '../comments-components/comments-card/CommentsCard';
import CommentsForm from '../comments-components/comments-form/CommentsForm';
import CommentsCreateDialog from '../comments-components/comments-create-dialog/CommentsCreateDialog';
import RepliesBlock from '../comments-components/replies-block/RepliesBlock';

const CommentsBlock = ({
  page,
  relatedUserId,
  discardButtonId,
  resourceId,
  outterDialogConfig,
  commentIdToDelete,
}) => {
  const { t } = useTranslation();
  const [isFormVisible, setIsFormVisible] = useState(false);

  const {
    addNewCommentHandler,
    comments,
    deleteCommentHandler,
    editCommentHandler,
    getComments,
    setComments,
  } = useCommentsData();

  const onShowFormHandler = () => {
    setIsFormVisible(!isFormVisible);
  };

  useEffect(() => {
    if (page) {
      setComments([]);
      getComments(page, relatedUserId, resourceId).then((commentsData) =>
        setComments(commentsData),
      );
    }
  }, [page, relatedUserId, resourceId, getComments, setComments]);

  useEffect(() => {
    if (commentIdToDelete) {
      deleteCommentHandler(commentIdToDelete);
    }
  }, [commentIdToDelete, deleteCommentHandler]);

  return (
    <Grid className="ayo-comments-block" container>
      <Grid item md={6} sm={8}>
        <Box alignItems="center" display="flex" pb={3}>
          <Typography component="h2" variant="subtitle1">
            {t('Comments')} {`(${comments.length})`}
          </Typography>
        </Box>
        {!isFormVisible ? (
          <Button
            endIcon={<AddIcon />}
            gaLabel="Create comment"
            onClick={onShowFormHandler}
            variant="text"
          >
            {t('Add a comment')}
          </Button>
        ) : (
          <CommentsForm
            addNewCommentHandler={addNewCommentHandler}
            page={page}
            relatedUserId={relatedUserId}
            resourceId={resourceId}
            showFormHandler={onShowFormHandler}
          />
        )}
        {comments.map((comment) => (
          <Box key={comment.id} pt={3}>
            <CommentsCard
              comment={comment}
              deleteCommentHandler={deleteCommentHandler}
              discardButtonId={discardButtonId}
              editCommentHandler={editCommentHandler}
              hasReplies={!!comment.replies?.length}
              isTextCollapsed
            />
            <RepliesBlock
              addNewCommentHandler={addNewCommentHandler}
              deleteCommentHandler={deleteCommentHandler}
              discardButtonId={discardButtonId}
              editCommentHandler={editCommentHandler}
              page={page}
              parentCommentId={comment.id}
              parentCommentTitle={comment.title}
              relatedUserId={relatedUserId}
              replies={comment.replies}
              resourceId={resourceId}
            />
          </Box>
        ))}
      </Grid>
      {outterDialogConfig && (
        <CommentsCreateDialog
          addNewCommentHandler={addNewCommentHandler}
          helperText={outterDialogConfig.helperText}
          isOpen={outterDialogConfig.isOpen}
          onAfterSubmit={outterDialogConfig.onSubmitHandler}
          onCancel={outterDialogConfig.onCancel}
          onClose={outterDialogConfig.onClose}
          page={page}
          relatedUserId={relatedUserId}
          resourceId={resourceId}
          submitButtonText={outterDialogConfig.submitButtonText}
          title={outterDialogConfig.title}
        />
      )}
    </Grid>
  );
};

CommentsBlock.propTypes = {
  discardButtonId: PropTypes.string,
  outterDialogConfig: PropTypes.instanceOf(Object),
  page: PropTypes.string,
  relatedUserId: PropTypes.number,
  resourceId: PropTypes.number,
  commentIdToDelete: PropTypes.number,
};

CommentsBlock.defaultProps = {
  page: '',
  discardButtonId: null,
  relatedUserId: null,
  resourceId: null,
  outterDialogConfig: null,
  commentIdToDelete: null,
};

export default CommentsBlock;
