import React, { useCallback, useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import useSnackbar from '../use-snackbar/useSnackbar';
import { useClubHubService } from '../../services';
import { ClubRights, ClubsMembershipStatuses, ClubsStatuses } from '../../constants/club-hub';
import { Button } from '../../components/atoms';
import { UserContext } from '../../context';
import { useProfile } from '../../utils';
import { PrivilegesMap, RolesMap } from '../../constants/enums';

const StatusSnackBars = {
  [ClubsStatuses.ACTIVE]: {
    success: 'The club’s clubName request has been approved. The club is active now!',
    error: 'AYO couldn’t approve the request for the club clubName. Please try once more.',
  },
  [ClubsStatuses.INACTIVE]: {
    success:
      'The clubName name has become inactive, and the reason for it has been sent to the creator.',
    error:
      'AYO couldn’t make the club clubName inactive, and no notification was sent to the creator. Please try once more.',
  },
  [ClubsStatuses.DECLINED]: {
    success:
      'The club’s clubName request has been declined, and the reason for declining has been sent.',
    error:
      'AYO couldn’t decline the request for the club clubName, and no notification was sent to the creator. Please try once more.',
  },
};
const useClubHub = () => {
  const { t } = useTranslation();

  const { setSnackBarStatus } = useSnackbar();

  const { updateClubStatus } = useClubHubService();

  const { state: userState } = useContext(UserContext);

  const { role, id: userId } = userState.profile;

  const isStudent = role === RolesMap.STUDENT;
  const isEducator = [RolesMap.TEACHER, RolesMap.ADMINISTRATOR].includes(role);

  const { getPrivilegeStatus } = useProfile();

  const isClubsManager = getPrivilegeStatus(PrivilegesMap.CLUB_HUB_MANAGER);

  const getClubRights = useCallback(
    (clubData) => {
      const { status, membershipStatus, ownerId, settings } = clubData || {};

      const isClubOwner = ownerId === userId;

      const isClubActive = status === ClubsStatuses.ACTIVE;

      return {
        [ClubRights.CHANGE_SETTINGS]: isClubOwner && isClubActive,
        [ClubRights.CHANGE_MEMBERSHIP_STATUS]: isStudent,
        [ClubRights.CREATE_POST]:
          (isClubOwner ||
            (!isEducator &&
              settings?.MEMBERS_PARENTS_POSTING_GRANTED &&
              membershipStatus === ClubsMembershipStatuses.JOINED)) &&
          isClubActive,
        [ClubRights.CREATE_EVENTS]: isClubOwner && isClubActive,
        [ClubRights.EDIT_CLUB]: isClubOwner,
        [ClubRights.MODERATE_CLUB]: isClubsManager && status !== ClubsStatuses.ACTIVE,
        [ClubRights.POST_REPLIES]:
          isClubActive && isEducator ? isClubOwner : settings?.COMMENTS_GRANTED && isClubActive,
        [ClubRights.VIEW_CLUB_STATUS]: isClubOwner || isClubsManager,
        [ClubRights.VIEW_CLUB_STATUS_BANNER]: isStudent
          ? [
              ClubsMembershipStatuses.PENDING_REQUEST,
              ClubsMembershipStatuses.REQUEST_REJECTED,
            ].includes(membershipStatus)
          : isClubOwner
          ? status !== ClubsStatuses.ACTIVE
          : [ClubsStatuses.INACTIVE, ClubsStatuses.DECLINED].includes(status),
        [ClubRights.VIEW_CLUB_VISIBILITY]: isClubOwner || isClubsManager,
        [ClubRights.VIEW_MEMBERS_TABLE]: isClubOwner || isClubsManager,
        [ClubRights.VIEW_POSTS_FILTERS]: isClubOwner || isClubsManager,
        [ClubRights.VIEW_POSTS]: isEducator
          ? isClubOwner || isClubsManager
          : membershipStatus === ClubsMembershipStatuses.JOINED,
        [ClubRights.VIEW_EVENTS]: isClubOwner || isClubsManager || isStudent,
      };
    },
    [isClubsManager, isEducator, isStudent, userId],
  );

  const clubStatusChangeHandler = useCallback(
    (clubId, clubName, reason, newStatus, onStatusChange) => {
      updateClubStatus(clubId, reason, newStatus)
        .then(() => {
          setSnackBarStatus({
            text: (
              <Trans
                components={{ b: <strong /> }}
                i18nKey={StatusSnackBars[newStatus].success}
                values={{ clubName }}
              />
            ),
            type: 'success',
          });
          onStatusChange();
        })
        .catch(() => {
          setSnackBarStatus({
            text: (
              <Trans
                components={{ b: <strong /> }}
                i18nKey={StatusSnackBars[newStatus].error}
                values={{ clubName }}
              />
            ),
            type: 'error',
            action: (
              <Button
                gaLabel="Retry"
                onClick={() =>
                  clubStatusChangeHandler(clubId, clubName, reason, newStatus, onStatusChange)
                }
              >
                {t('Retry')}
              </Button>
            ),
          });
        });
    },
    [setSnackBarStatus, t, updateClubStatus],
  );
  return {
    clubStatusChangeHandler,
    getClubRights,
  };
};

export default useClubHub;
