import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';

import { Button, Typography } from '../../../../atoms';
import { ReactComponent as NotificationBell } from '../../../../../resources/images/portfolio/notification-bell.svg';
import { NotificationSourcesMap } from '../../../../../constants/enums';

const PortfolioUpdateNotification = ({
  title,
  updateNotificationItems,
  notifications,
  onUpdate,
}) => {
  const { t } = useTranslation();
  const updateNotifications = useCallback(() => {
    updateNotificationItems(
      notifications.map((item) => ({ ...item, markedAsRead: true })),
      NotificationSourcesMap.WEB_HIDDEN,
    );
  }, [notifications, updateNotificationItems]);

  return (
    <Grid container>
      <Grid item md={10}>
        <Box
          alignItems="center"
          className="ayo-portfolio-notification"
          display="flex"
          justifyContent="space-between"
        >
          <Box alignItems="center" display="flex">
            <NotificationBell />
            <Box ml={1}>
              <Typography variant="body2">
                <Trans
                  components={{ b: <b /> }}
                  i18nKey="There are new items in your section Let’s update them!"
                  values={{ section: t(title) }}
                />
              </Typography>
            </Box>
          </Box>
          <Box display="flex">
            <Button gaLabel="Discard" onClick={updateNotifications} variant="text">
              {t('Discard')}
            </Button>
            <Box ml={3}>
              <Button gaLabel="Update" onClick={onUpdate} variant="primary">
                {t('Update')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

PortfolioUpdateNotification.propTypes = {
  updateNotificationItems: PropTypes.func.isRequired,
  notifications: PropTypes.instanceOf(Object).isRequired,
  title: PropTypes.string.isRequired,
  onUpdate: PropTypes.func,
};

PortfolioUpdateNotification.defaultProps = {
  onUpdate: () => {},
};

export default PortfolioUpdateNotification;
