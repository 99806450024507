import { useContext, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { AppContext, AppActions, UserContext, UserActions } from '../../context';
import { useClassLinkTokenService, useNotificationsService } from '../../services';
import {
  DefaultGAUserProperties,
  LocalStorageKeysMap,
  LoginProvidersMap,
  RolesMap,
} from '../../constants/enums';
import { loginRoute, rootRoute } from '../../constants/routes';
import { RolesMainPages } from '../../constants/pages';
import { setUserProperties } from '../analytics/Analytics';

const skywardLogout = () => {
  window.location.assign(`${window.location.origin}/api/saml/logout?local=true`);
};

const useLogin = () => {
  const { state: appState, dispatch: dispatchAppState } = useContext(AppContext);
  const { dispatch: dispatchUserState } = useContext(UserContext);
  const { revokeToken } = useClassLinkTokenService();
  const { closeNotificationsSocket } = useNotificationsService();
  const history = useHistory();

  const clientId = process.env.REACT_APP_CLASSLINK_CLIENT_ID;

  const isClassLinkLogin = appState.loginProvider === LoginProvidersMap.CLASSLINK;

  const classlinkLogin = useCallback(() => {
    dispatchAppState({ type: AppActions.SET_LOGIN_PROVIDER, data: LoginProvidersMap.CLASSLINK });
    window.location.assign(
      `https://launchpad.classlink.com/oauth2/v2/auth?scope=profile&redirect_uri=${window.location.origin}/classlink-login&client_id=${clientId}&response_type=code`,
    );
  }, [clientId, dispatchAppState]);

  const skywardLogin = useCallback(() => {
    dispatchAppState({ type: AppActions.SET_LOGIN_PROVIDER, data: LoginProvidersMap.SKYWARD });
    window.location.assign(`${window.location.origin}/api/saml/login`);
  }, [dispatchAppState]);

  const googleLogin = useCallback(() => {
    dispatchAppState({ type: AppActions.SET_LOGIN_PROVIDER, data: LoginProvidersMap.GOOGLE });
    window.location.assign(
      `https://accounts.google.com/o/oauth2/v2/auth?client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}&response_type=code&redirect_uri=${window.location.origin}/oauth-login&scope=https://www.googleapis.com/auth/userinfo.profile%20https://www.googleapis.com/auth/userinfo.email`,
    );
  }, [dispatchAppState]);

  const loginProvidersHandlers = useMemo(
    () => ({
      [LoginProvidersMap.CLASSLINK]: classlinkLogin,
      [LoginProvidersMap.SKYWARD]: skywardLogin,
      [LoginProvidersMap.GOOGLE]: googleLogin,
    }),
    [classlinkLogin, skywardLogin, googleLogin],
  );

  const proceedToLogin = useCallback(
    (forcedLoginProvider) => {
      loginProvidersHandlers[forcedLoginProvider || appState.loginProvider]();
    },
    [appState.loginProvider, loginProvidersHandlers],
  );

  const classLinkLogout = useCallback(() => {
    revokeToken()
      .then(() => {
        dispatchUserState({
          type: UserActions.SET_USER_PROFILE,
          data: null,
        });
      })
      .then(() => {
        history.push(rootRoute);
      });
  }, [dispatchUserState, history, revokeToken]);

  const logout = useCallback(() => {
    closeNotificationsSocket();
    if (isClassLinkLogin) {
      classLinkLogout();
    } else {
      skywardLogout();
    }
    setUserProperties(DefaultGAUserProperties);
  }, [classLinkLogout, closeNotificationsSocket, isClassLinkLogin]);

  const signInHandler = useCallback(
    (provider, loginRedirectKey, loginRedirectUrl, currentCampus) => {
      sessionStorage.removeItem(LocalStorageKeysMap.SKYWARD_LOGIN_REDIRECT_URL);
      sessionStorage.removeItem(LocalStorageKeysMap.CLASSLINK_LOGIN_REDIRECT_URL);
      if (currentCampus) {
        localStorage.setItem(LocalStorageKeysMap.AYO_CURRENT_CAMPUS, currentCampus);
      }
      if (loginRedirectKey && loginRedirectUrl) {
        sessionStorage.setItem(loginRedirectKey, loginRedirectUrl);
      } else if (provider === LoginProvidersMap.SKYWARD) {
        sessionStorage.setItem(
          LocalStorageKeysMap.SKYWARD_LOGIN_REDIRECT_URL,
          RolesMainPages[RolesMap.GUARDIAN],
        );
      }
      dispatchAppState({ type: AppActions.SET_LOGIN_PROVIDER, data: provider });
      history.push(loginRoute);
    },
    [dispatchAppState, history],
  );

  return { proceedToLogin, logout, signInHandler };
};

export default useLogin;
