import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { SharingLevels } from '../../../../constants/family-feed';
import SelectableEntities from '../selectable-entities/SelectableEntities';
import BlockHeader from '../block-header/BlockHeader';

const DistrictsBlock = ({ columnsCount, districts, selected, onChange }) => {
  const handleChange = useCallback(
    (e, value) => {
      onChange(e, value, 'name');
    },
    [onChange],
  );

  return (
    <>
      <BlockHeader title={SharingLevels.DISTRICT.title} />
      <SelectableEntities
        columnsCount={columnsCount}
        entities={districts}
        onChange={handleChange}
        selected={selected}
      />
    </>
  );
};

DistrictsBlock.propTypes = {
  columnsCount: PropTypes.number,
  districts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ).isRequired,
};

DistrictsBlock.defaultProps = {
  columnsCount: 2,
};

export default DistrictsBlock;
