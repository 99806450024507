/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery';
import { useTheme } from '@mui/material';

import { Button } from '../../atoms';

const LoginButton = ({ onClick, disabled, label }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Box
      boxSizing="border-box"
      pb={isWidthUpSm ? 4 : 1}
      pt={1}
      px={isWidthUpSm ? 2 : 0}
      width="100%"
    >
      <Button
        disabled={disabled}
        fullWidth
        gaLabel={label}
        onClick={() => onClick()}
        variant="primary"
      >
        {t(label)}
      </Button>
    </Box>
  );
};

LoginButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  label: PropTypes.string,
};

LoginButton.defaultProps = {
  onClick: () => {},
  disabled: false,
  label: '',
};

export default LoginButton;
