import React, { useEffect, useState, useMemo, useContext, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import Joyride, { EVENTS, STATUS, ACTIONS } from 'react-joyride';
import { useTheme, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { TourActions, TourContext } from '../context';
import { useProfileService } from '../services';
import { GaActions, GaCategories } from '../constants/enums';
import { GA } from '../utils';
import { checkEnvironmentVariable } from '../utils/app-helpers/appHelpers';

import MainTourItemsProvider from './common/MainTourItemsProvider';

const TourStatuses = {
  IN_PROGRESS: 'IN_PROGRESS',
  FINISHED: 'FINISHED',
};

const logGAInteraction = (gaAction, gaLabel) =>
  GA.logInteraction({
    category: GaCategories.BEHAVIOR,
    action: gaAction,
    label: gaLabel,
  });

const TourGALabels = {
  LAST_STEP: 'Tour last step',
  STEP: (step) => `Tour step: ${step}`,
  WELCOME_MODAL: 'Tour Welcome modal',
};

const AYO_FONT_FAMILY = ['Lato', 'sans-serif'];

const Tour = ({ steps, isRunning, name }) => {
  const [stepIndex, setStepIndex] = useState(0);
  const [canRun, setCanRun] = useState(false);
  const [overlayHeight, setOverlayHeight] = useState(document.body?.offsetHeight);
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const isWidthUpXl = useMediaQuery(theme.breakpoints.up('xl'));
  const { t } = useTranslation();
  const { postTourStatus } = useProfileService();

  const { state: tourState, dispatch: dispatchTourState } = useContext(TourContext);

  const localization = useMemo(
    () => ({
      back: t('Back'),
      close: t('Close'),
      last: t('Got it'),
      next: t('Next'),
      skip: t('Skip the tour'),
    }),
    [t],
  );

  const updateTourProgress = useCallback(
    (newStatus) => {
      const newToursStatus = { ...tourState.toursStatus, [name]: { ...newStatus } };
      postTourStatus(newToursStatus).then(() => {
        dispatchTourState({ type: TourActions.SET_TOUR_STATUS, data: newToursStatus });
      });
    },
    [dispatchTourState, name, postTourStatus, tourState],
  );

  const isFirstTour = useRef();

  const setTourOverlayHeight = useCallback(() => {
    setOverlayHeight(document.body ? document.body?.offsetHeight - 1 : 0);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', setTourOverlayHeight);
    return () => window.removeEventListener('scroll', setTourOverlayHeight);
  }, [setTourOverlayHeight]);

  useEffect(() => {
    if (checkEnvironmentVariable('REACT_APP_DISABLE_TOURS', 'true') || !steps.length) return;
    if (tourState.forceTourOpen) {
      const initStartingStep = steps[1].stepInit || (() => Promise.resolve(true));
      initStartingStep().then(() => {
        setStepIndex(1);
        setCanRun(true);
        updateTourProgress({ lastStep: steps[1].id, status: TourStatuses.IN_PROGRESS });
        dispatchTourState({ type: TourActions.SET_FORCE_TOUR_OPEN, data: false });
      });
    } else if (tourState.forceTourOpen === null) {
      const tourProgress = tourState?.toursStatus && tourState?.toursStatus[name];
      if (!tourProgress || tourProgress.status === TourStatuses.IN_PROGRESS) {
        setCanRun(false);
        let startingStepId = tourProgress?.lastStep ?? steps[0].id;
        let startingStepIndex = steps.findIndex((step) => step.id === startingStepId);
        if (startingStepIndex === -1) {
          startingStepId += 1;
          startingStepIndex = steps.findIndex((step) => step.id === startingStepId);
        }
        const initStartingStep = steps[startingStepIndex].stepInit || (() => Promise.resolve(true));
        initStartingStep().then(() => {
          setStepIndex(startingStepIndex);
          setCanRun(true);
          updateTourProgress({
            lastStep: startingStepId,
            status: TourStatuses.IN_PROGRESS,
          });
        });
        if (!tourProgress) {
          isFirstTour.current = true;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, steps, tourState.forceTourOpen]);

  const handleTourCallback = useCallback(
    (data) => {
      const { action, index, status, type, step } = data;
      if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
        // Update state to advance the tour
        const stepEndHandler = () => {
          const nextIndex = index + (action === ACTIONS.PREV ? -1 : 1);
          if (steps[nextIndex]) {
            updateTourProgress({ lastStep: steps[nextIndex].id, status: TourStatuses.IN_PROGRESS });
          }
          setStepIndex(nextIndex);
          if (action === ACTIONS.NEXT) {
            if (nextIndex === steps.length - 1) {
              logGAInteraction(GaActions.TOUR_LAST_STEP_REACHED, TourGALabels.LAST_STEP);
            } else if (nextIndex === 1) {
              logGAInteraction(GaActions.TOUR_WALK_THROUGH, TourGALabels.WELCOME_MODAL);
            } else if (nextIndex === steps.length) {
              logGAInteraction(GaActions.TOUR_GOT_IT, TourGALabels.LAST_STEP);
            } else {
              logGAInteraction(GaActions.TOUR_NEXT_STEP, TourGALabels.STEP(nextIndex));
            }
          } else if (action === ACTIONS.PREV) {
            logGAInteraction(GaActions.TOUR_BACK_TO_PREV_STEP, TourGALabels.STEP(nextIndex));
          }
        };
        const afterStep =
          (action === ACTIONS.PREV ? step.onBackButton : step.onNextButton) ||
          (() => Promise.resolve());
        afterStep().then(stepEndHandler);
      } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
        setCanRun(false);
        updateTourProgress({ status: TourStatuses.FINISHED });
        if (status === STATUS.SKIPPED) {
          updateTourProgress({ lastStep: steps[steps.length - 1].id });
          if (stepIndex === 0 && isFirstTour.current) {
            logGAInteraction(GaActions.FIRST_TOUR_SKIPPED, TourGALabels.WELCOME_MODAL);
          } else {
            logGAInteraction(GaActions.FIRST_TOUR_SKIPPED, TourGALabels.STEP(stepIndex + 1));
          }
        }
      }
    },
    [stepIndex, steps, updateTourProgress],
  );

  const modifiedSteps = useMemo(
    () =>
      steps.map((step, idx) => {
        const title = step.title && `${step.title} ${idx}/${steps.length - 1}`;
        return { ...step, hideCloseButton: true, title };
      }),
    [steps],
  );

  return (
    checkEnvironmentVariable('REACT_APP_DISABLE_TOURS', 'false') &&
    !!modifiedSteps.length && (
      <Joyride
        callback={handleTourCallback}
        continuous
        debug
        disableCloseOnEsc
        disableOverlayClose
        disableScrolling
        disableScrollParentFix
        floaterProps={{ styles: { floater: { filter: null } }, hideArrow: true }}
        locale={localization}
        run={Boolean(canRun && isRunning)}
        scrollDuration={0}
        showSkipButton
        spotlightPadding={10}
        stepIndex={stepIndex}
        steps={modifiedSteps}
        styles={{
          beacon: {
            visibility: 'hidden',
          },
          buttonNext: {
            backgroundColor: '#6065a8',
            border: '2px solid transparent',
            borderRadius: 16,
            fontFamily: AYO_FONT_FAMILY,
            fontSize: '1.333rem',
            lineHeight: '2.0625rem',
            outline: 'none',
            padding: '12px 16px',
          },
          buttonBack: {
            border: '2px solid transparent',
            borderRadius: 16,
            color: '#6065a8',
            fontFamily: AYO_FONT_FAMILY,
            fontSize: '1.333rem',
            lineHeight: '2.0625rem',
            padding: '12px 16px',
          },
          options: {
            zIndex: 1299,
            overlayColor: 'rgba(0,0,0,.7)',
          },
          spotlight: {
            borderRadius: 16,
          },
          tooltip: {
            borderRadius: 16,
            padding: 24,
            width: isWidthUpXl ? 520 : isWidthUpSm ? 420 : 'calc(100vw - 18px)',
            height: stepIndex === 0 && !isWidthUpSm ? 'calc(100vh - 18px)' : '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          },
          tooltipContainer: {
            textAlign: 'left',
          },
          tooltipContent: {
            padding: '16px 0',
            fontFamily: AYO_FONT_FAMILY,
            fontWeight: 'normal',
            fontSize: '1.333rem',
            lineHeight: '2.25rem',
            color: 'rgba(0, 0, 0, 0.75)',
          },
          tooltipTitle: {
            fontFamily: AYO_FONT_FAMILY,
            fontWeight: 'bold',
            fontSize: '1.833rem',
            lineHeight: '2.75rem',
            color: 'rgba(0, 0, 0, 0.85)',
          },
          buttonSkip: {
            border: '2px solid transparent',
            borderRadius: 16,
            color: '#636363',
            fontFamily: AYO_FONT_FAMILY,
            fontSize: '1.333rem',
            lineHeight: '2.0625rem',
            padding: '12px 16px',
          },
          overlay: {
            height: `${overlayHeight}px`,
          },
        }}
      />
    )
  );
};

Tour.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      styles: PropTypes.instanceOf(Object),
      content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      id: PropTypes.number,
      locale: PropTypes.instanceOf(Object),
      target: PropTypes.string,
      placement: PropTypes.oneOf(['bottom', 'top', 'right', 'right-start', 'left', 'center']),
      hideBackButton: PropTypes.bool,
      onNextButton: PropTypes.func,
      onBackButton: PropTypes.func,
      stepInit: PropTypes.func,
    }),
  ).isRequired,
  isRunning: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
};

const MainTour = () => {
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
  const isWidthUpLg = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });

  const tourConfig = MainTourItemsProvider(isWidthUpLg, isWidthUpSm);

  return <Tour isRunning name={tourConfig.tourName} steps={tourConfig.tourItems} />;
};

export default MainTour;
