import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { InputsValidationErrors, InputsValidationRules } from '../../../../../../constants/enums';

const useAssignmentInstructionState = (startValue, instructionText, isRequired) => {
  const [instruction, setInstruction] = useState(startValue);
  const [error, setError] = useState('');

  const { t } = useTranslation();

  const maxLengthRule = isRequired
    ? InputsValidationRules.MAX_INPUT_LENGTH
    : InputsValidationRules.MAX_DESCRIPTION_LENGTH;

  const validate = () => {
    let errorText;
    if (instruction && instruction.length > maxLengthRule) {
      errorText = InputsValidationErrors(t, maxLengthRule).MAX_ERROR_TEXT;
    }
    if (isRequired && instruction?.length < InputsValidationRules.MIN_INPUT_LENGTH) {
      errorText = InputsValidationErrors(t, InputsValidationRules.MIN_INPUT_LENGTH).MIN_ERROR_TEXT;
    }
    if (errorText) {
      setError(errorText);
      return [{ errorText, lvl3Label: instructionText || t('Instructions (optional)') }];
    }
    return [];
  };

  const onChange = (newValue) => {
    setInstruction(newValue);
    setError('');
  };

  return { state: instruction, onChange, error, validate };
};

export default useAssignmentInstructionState;
