import React, { useContext, useMemo, useState } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { Grid, Container, Box } from '@mui/material';

import { Typography, Link } from '../../../atoms';
import { AccordionItem } from '../../../moleculas';
import { BottomBar, PageWrapper, FeedbackForm } from '../../../organisms';
import { UserContext } from '../../../../context';
import { privacyPolicyRoute, emailAyoMisdRoute } from '../../../../constants/routes';
import { ReactComponent as SuccessIcon } from '../../../../resources/icons/check_circle_outline_large.svg';
import { FeedbackPrefixTypes } from '../../../../constants/enums';

const FaqPage = () => {
  const { t } = useTranslation();
  const [isQuestionSubmitted, setIsQuestionSubmitted] = useState(false);

  const { state: userState } = useContext(UserContext);
  const profileNoNav = !userState.profile || userState.noNavigation;

  const accordionItemsArray = useMemo(
    () => [
      {
        headerEl: t('What is AYO?'),
        bodyEl: (
          <>
            <Typography paragraph variant="body2">
              {t(
                'AYO is a free online platform within Mesquite Independent School District that aims at helping students to unlock their individual potential',
              )}
              .
            </Typography>
            <Typography variant="body2">
              {t('AYO also assists educators in applying Individualized Learning strategies')}.{' '}
              {t(
                'This online platform and the future app provide insights required to coach students on their unique path through today’s educational landscape',
              )}
              .
            </Typography>
          </>
        ),
      },
      {
        headerEl: t('Who owns AYO?'),
        bodyEl: (
          <Typography variant="body2">
            {t('AYO is owned and operated by Mesquite Independent School District')}.
          </Typography>
        ),
      },
      {
        headerEl: t('Who can use AYO?'),
        bodyEl: (
          <Typography variant="body2">
            {t(
              'As of its launch in September 2020, AYO is available for students of Mesquite ISD schools, their legal guardians, and teachers who work at MISD',
            )}
            .{' '}
            {t('Eventually, AYO will be available for other educators counselors, principals, etc')}
            .
          </Typography>
        ),
      },
      {
        headerEl: t('How is AYO going to help students?'),
        bodyEl: (
          <Typography variant="body2">
            {t(
              "In order to help each student to discover their potential AYO gathers and analyzes information about the student's individual interests, passions, skills, and aptitudes",
            )}
            .{' '}
            {t(
              "AYO then finds where these attributes align to visualize one's potential and inform student choice as they grow and develop",
            )}
            .
          </Typography>
        ),
      },
      {
        headerEl: t('How can AYO help Teachers?'),
        bodyEl: (
          <Typography variant="body2">
            {t('Teachers are the first educators who will be able to access the AYO platform')}.{' '}
            {t(
              'AYO will assist teachers in providing personalized learning and help them to know their students better by sharing insights about student’s potential, their individual interests, aptitudes, etc',
            )}
            .
          </Typography>
        ),
      },
      {
        headerEl: t('How can AYO help Parents/Legal Guardians?'),
        bodyEl: (
          <Typography variant="body2">
            {t(
              'AYO is going to assist student’s legal guardians to participate and better contribute towards their child’s educational path and their future, by helping them to understand their child’s potential',
            )}
            .
          </Typography>
        ),
      },
      {
        headerEl: t('How can I log in to AYO?'),
        bodyEl: (
          <>
            <Typography paragraph variant="body2">
              {t('Students and Teachers can log in to AYO using their Classlink accounts')}.
            </Typography>
            <Typography paragraph variant="body2">
              {t(
                'After clicking the Student login or Educator login button you will be redirected to ClassLink for authorization',
              )}
              . {t('Please select your school on the Find your login page screen')}.{' '}
              {t(
                'After selecting your school you will be redirected to the Mesquite ISD Portal Page, where you will be able to log in using your ClassLink credentials',
              )}
              . {t('After this, you will be redirected to AYO')}.{' '}
            </Typography>
            <Typography paragraph variant="body2">
              {t('Legal Guardians can log in to AYO using their Skyward accounts')}.
            </Typography>
            <Typography variant="body2">
              {t(
                'After clicking the Parent login button you will be redirected to the Skyward Login page for authorization',
              )}
              .
            </Typography>
          </>
        ),
      },
      {
        headerEl: t('What regulations is AYO compliant with?'),
        bodyEl: (
          <>
            <Typography paragraph variant="body2">
              {t(
                'All the data we collect from students, their family, legal guardians, educators, and other AYO users is stored and processed in compliance with the existing regulations',
              )}
              :
            </Typography>
            <Typography paragraph variant="body2">
              <ul>
                <li>
                  <span className="list-item-text">
                    {t('HIPAA - Health Insurance Portability and Accountability Act')}
                  </span>
                </li>
                <li>
                  <span className="list-item-text">
                    {t('IDEA - Individuals with Disabilities Education Act')}
                  </span>
                </li>
                <li>
                  <span className="list-item-text">
                    {t("COPPA - Children's Online Privacy Protection Act")}
                  </span>
                </li>
                <li>
                  <span className="list-item-text">
                    {t('CIPA - Children’s Internet Protection Act')}
                  </span>
                </li>
                <li>
                  <span className="list-item-text">
                    {t('ADA - Americans with Disabilities Act')}
                  </span>
                </li>
                <li>
                  <span className="list-item-text">
                    {t('Section 508 of the Rehabilitation Act - Accessibility standard')}
                  </span>
                </li>
                <li>
                  <span className="list-item-text">
                    {t('FERPA - Family Educational Rights and Privacy Act')}
                  </span>
                </li>
                <li>
                  <span className="list-item-text">
                    {t('PPRA - Protection of Pupil Rights Amendment')}
                  </span>
                </li>
              </ul>
            </Typography>
            <Typography variant="body2">
              {t('For more details on information collection, please see AYO’s')}{' '}
              <Link component={RouterLink} to={privacyPolicyRoute}>
                {t('Privacy Policy')}
              </Link>{' '}
              {t("AYO's")}.
            </Typography>
          </>
        ),
      },
      {
        headerEl: t('What data does AYO collect?'),
        bodyEl: (
          <>
            <Typography paragraph variant="body2">
              {t('AYO collects three types of information from its users')}:
            </Typography>
            <Typography paragraph variant="body2">
              <ol>
                <li>
                  <span className="list-item-text">
                    {t(
                      'non-personally identifiable information that is routinely gathered as users navigate through our website',
                    )}
                    .
                  </span>
                </li>
                <li>
                  <span className="list-item-text">
                    {t(
                      'personally identifiable information voluntarily supplied by you to Mesquite ISD during enrollment and through educational software used in the District',
                    )}
                    .
                  </span>
                </li>
                <li>
                  <span className="list-item-text">
                    {t(
                      'Directory Information collected as a part of the educational process at Mesquite ISD schools',
                    )}
                    .
                  </span>
                </li>
              </ol>
            </Typography>
            <Typography variant="body2">
              {t('For more details on information collection, please see AYO’s')}{' '}
              <Link component={RouterLink} to={privacyPolicyRoute}>
                {t('Privacy Policy')}
              </Link>{' '}
              {t("AYO's")}.
            </Typography>
          </>
        ),
      },
      {
        headerEl: t('Which principles in regard to users’ data does AYO follow?'),
        bodyEl: (
          <>
            <Typography paragraph variant="body2">
              {t(
                'AYO follows these principles in regard to the data we collect from our users within AYO',
              )}
              :
            </Typography>
            <Typography paragraph variant="body2">
              <ul>
                <li>
                  <span className="list-item-text">
                    {t(
                      'Users - educators, parents, guardians, students, and others own their data',
                    )}
                  </span>
                </li>
                <li>
                  <span className="list-item-text">
                    {t('Data serves educational purposes only')}
                  </span>
                </li>
                <li>
                  <span className="list-item-text">
                    {t(
                      'Parents, legal Guardians have input, access, and choice in the student’s pathways',
                    )}
                  </span>
                </li>
                <li>
                  <span className="list-item-text">
                    {t('Data is a tool for us, not the answer')}.{' '}
                    {t('AYO will never make any decisions or conclusions for its users')}.
                  </span>
                </li>
                <li>
                  <span className="list-item-text">
                    {t(
                      'We use data to maximize equitable choice, passion, and options for students, parents, legal guardians, and educators',
                    )}
                  </span>
                </li>
                <li>
                  <span className="list-item-text">
                    {t('AYO follows Mesquite ISD core values')}
                  </span>
                </li>
                <li>
                  <span className="list-item-text">
                    {t('Using AYO, we develop the positive and not react to the negative')}
                  </span>
                </li>
                <li>
                  <span className="list-item-text">
                    {t(
                      'MISD takes accountability for all decisions, even when they are data-driven',
                    )}
                  </span>
                </li>
              </ul>
            </Typography>
            <Typography paragraph variant="body2">
              {t('AYO also follows these principles of caution')}:
            </Typography>
            <Typography paragraph variant="body2">
              <ul>
                <li>
                  <span className="list-item-text">
                    {t('Data is not shared beyond student, educator, and parent or legal guardian')}
                  </span>
                </li>
                <li>
                  <span className="list-item-text">
                    {t(
                      'Regardless of the data, we receive, students are not broken and do not need to be fixed',
                    )}
                  </span>
                </li>
                <li>
                  <span className="list-item-text">
                    {t('Data will not outweigh the decisions of educational professionals')}
                  </span>
                </li>
              </ul>
            </Typography>
            <Typography variant="body2">
              {t('For more details on information collection, please see AYO’s')}{' '}
              <Link component={RouterLink} to={privacyPolicyRoute}>
                {t('Privacy Policy')}
              </Link>{' '}
              {t("AYO's")}.
            </Typography>
          </>
        ),
      },
      {
        headerEl: t('How does AYO treat Students’ data?'),
        bodyEl: (
          <>
            <Typography paragraph variant="body2">
              {t(
                'Students’ data is stored and processed according to the Family Educational Rights and Privacy Act (FERPA) and applicable state laws regarding privacy of student records or PII',
              )}
              .
            </Typography>
            <Typography paragraph variant="body2">
              {t(
                'Additionally, data of students under the age of 13 is stored and processed in accordance with Children’s Online Privacy Protection Act (COPPA)',
              )}
              .
            </Typography>
            <Typography variant="body2">
              {t('For more details on information collection, please see AYO’s')}{' '}
              <Link component={RouterLink} to={privacyPolicyRoute}>
                {t('Privacy Policy')}
              </Link>{' '}
              {t("AYO's")}.
            </Typography>
          </>
        ),
      },
      {
        headerEl: t('How does AYO treat the data of Legal Guardians/Parents?'),
        bodyEl: (
          <>
            <Typography paragraph variant="body2">
              {t(
                'Legal guardians’ data is stored and processed according to the Family Educational Rights and Privacy Act (FERPA) and applicable state laws regarding the privacy of student records or PII',
              )}
              .
            </Typography>
            <Typography variant="body2">
              {t('For more details on information collection, please see AYO’s')}{' '}
              <Link component={RouterLink} to={privacyPolicyRoute}>
                {t('Privacy Policy')}
              </Link>{' '}
              {t("AYO's")}.
            </Typography>
          </>
        ),
      },
      {
        headerEl: t('How does AYO treat Teachers’ data?'),
        bodyEl: (
          <Typography variant="body2">
            {t('Teachers’ data is stored and processed in accordance with AYO')}{' '}
            <Link component={RouterLink} to={privacyPolicyRoute}>
              {t('Privacy Policy')}
            </Link>
            .
          </Typography>
        ),
      },
      {
        headerEl: t('Can I remove my/my child’s data from AYO?'),
        bodyEl: (
          <Typography variant="body2">
            {t('Yes, you can')}.{' '}
            {t(
              'AYO is compliant with FERPA and COPPA, so the data is fully owned by the one who provides it',
            )}
            .{' '}
            {t(
              'To request the deletion of your data from the AYO system, please contact campus administrator',
            )}
          </Typography>
        ),
      },
      {
        headerEl: t('Can I export my/my child’s data from AYO?'),
        bodyEl: (
          <Typography variant="body2">
            {t('Yes, you can')}.{' '}
            {t(
              'AYO is compliant with FERPA and COPPA, so the data is fully owned by the one who provides it',
            )}
            .{' '}
            {t(
              'To request an export of your data from the AYO system, please contact campus administrator',
            )}
          </Typography>
        ),
      },
      {
        headerEl: `${t('I am not an Educator/Student, but I wish to help')}. ${t(
          'Can I help AYO in any way?',
        )}`,
        bodyEl: (
          <Typography variant="body2">
            {t(
              'If you wish to help AYO please leave us your feedback on the Landing page though the Feedback form, or send an email to Mesquite ISD Administration',
            )}{' '}
            <Link href={emailAyoMisdRoute}>myayo@mesquiteisd.org</Link>.
          </Typography>
        ),
      },
    ],
    [t],
  );

  return (
    <PageWrapper
      backToLink="/"
      backToText={profileNoNav ? t('Back to main page') : ''}
      className={classnames('ayo-faq-page', {
        'ayo-faq-page--w-bg': !profileNoNav,
      })}
      customFooter={<BottomBar simple={!!userState.profile} />}
    >
      <Container
        className={classnames('ayo-faq-page--questions-container', {
          'no-padding': userState.profile,
        })}
      >
        <Grid container justifyContent={profileNoNav ? 'center' : 'flex-start'}>
          <Grid item sm={8} xs={12}>
            <Typography
              align={profileNoNav ? 'center' : 'left'}
              component="h1"
              paragraph
              variant="h1"
            >
              {t('Frequently Asked Questions')}
            </Typography>
            <Box mb={5}>
              <Typography align={profileNoNav ? 'center' : 'left'} variant="body2">
                {t('We have compiled a list of questions that can help you understand AYO')}.{' '}
                {userState.profile &&
                  t(
                    'If you have not found your, do not hesitate to submit a form with it and we will contact you asap!',
                  )}
              </Typography>
            </Box>
            {accordionItemsArray.map((item, i) => (
              <Box key={item.headerEl} mb={1}>
                <AccordionItem
                  bodyEl={item.bodyEl}
                  gaLabel="FAQ item"
                  headerEl={
                    <Typography component="p" variant="subtitle2">
                      {item.headerEl}
                    </Typography>
                  }
                  isDefaultExpanded={i === 0}
                />
              </Box>
            ))}
          </Grid>
        </Grid>
      </Container>
      {userState.profile ? (
        <Box className="ayo-faq-page--ask-a-question">
          <Container>
            <Grid container justifyContent={profileNoNav ? 'center' : 'flex-start'}>
              <Grid
                alignItems={profileNoNav ? 'center' : 'flex-start'}
                container
                direction="column"
                item
                justifyContent="center"
                md={!profileNoNav && 8}
                sm={profileNoNav ? 7 : 8}
                xs={12}
              >
                {!isQuestionSubmitted ? (
                  <FeedbackForm
                    feedbackPrefix={FeedbackPrefixTypes.QUESTION}
                    fieldLabel={t('Add your question here')}
                    gaLabel="Submit question"
                    onSubmit={() => setIsQuestionSubmitted(true)}
                    text={[t('Do not hesitate to ask AYO!')]}
                    title={t('Have not found your answer?')}
                  />
                ) : (
                  <>
                    <SuccessIcon />
                    <Typography align={profileNoNav ? 'center' : 'flex-start'} variant="h2">
                      {t('Thank you for your question!')}
                    </Typography>
                  </>
                )}
              </Grid>
            </Grid>
          </Container>
        </Box>
      ) : null}
    </PageWrapper>
  );
};

export default FaqPage;
