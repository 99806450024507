const LEADERSHIP_ATTRIBUTES_GRAPH = [
  {
    key: 'collaboration',
    maturityLevels: {
      LEADING_OTHERS: {
        totalGroups: 2,
        completedGroups: 0,
      },
      LEADING_SELF: {
        totalGroups: 2,
        completedGroups: 0,
      },
      LEADING_CHANGE: {
        totalGroups: 2,
        completedGroups: 0,
      },
    },
  },
  {
    key: 'communication',
    maturityLevels: {
      LEADING_OTHERS: {
        totalGroups: 5,
        completedGroups: 0,
      },
      LEADING_SELF: {
        totalGroups: 4,
        completedGroups: 0,
      },
      LEADING_CHANGE: {
        totalGroups: 2,
        completedGroups: 0,
      },
    },
  },
  {
    key: 'courage',
    maturityLevels: {
      LEADING_OTHERS: {
        totalGroups: 6,
        completedGroups: 0,
      },
      LEADING_SELF: {
        totalGroups: 4,
        completedGroups: 0,
      },
      LEADING_CHANGE: {
        totalGroups: 5,
        completedGroups: 0,
      },
    },
  },
  {
    key: 'determination',
    maturityLevels: {
      LEADING_OTHERS: {
        totalGroups: 5,
        completedGroups: 0,
      },
      LEADING_SELF: {
        totalGroups: 4,
        completedGroups: 0,
      },
      LEADING_CHANGE: {
        totalGroups: 2,
        completedGroups: 0,
      },
    },
  },
  {
    key: 'drive',
    maturityLevels: {
      LEADING_OTHERS: {
        totalGroups: 3,
        completedGroups: 0,
      },
      LEADING_SELF: {
        totalGroups: 4,
        completedGroups: 0,
      },
      LEADING_CHANGE: {
        totalGroups: 2,
        completedGroups: 0,
      },
    },
  },
  {
    key: 'empathy',
    maturityLevels: {
      LEADING_OTHERS: {
        totalGroups: 3,
        completedGroups: 0,
      },
      LEADING_SELF: {
        totalGroups: 4,
        completedGroups: 0,
      },
      LEADING_CHANGE: {
        totalGroups: 2,
        completedGroups: 0,
      },
    },
  },
  {
    key: 'encouragement',
    maturityLevels: {
      LEADING_OTHERS: {
        totalGroups: 2,
        completedGroups: 0,
      },
      LEADING_SELF: {
        totalGroups: 4,
        completedGroups: 0,
      },
      LEADING_CHANGE: {
        totalGroups: 2,
        completedGroups: 0,
      },
    },
  },
  {
    key: 'goal-orientation',
    maturityLevels: {
      LEADING_OTHERS: {
        totalGroups: 3,
        completedGroups: 0,
      },
      LEADING_SELF: {
        totalGroups: 4,
        completedGroups: 0,
      },
      LEADING_CHANGE: {
        totalGroups: 2,
        completedGroups: 0,
      },
    },
  },
  {
    key: 'growth-mindset',
    maturityLevels: {
      LEADING_OTHERS: {
        totalGroups: 3,
        completedGroups: 0,
      },
      LEADING_SELF: {
        totalGroups: 4,
        completedGroups: 0,
      },
      LEADING_CHANGE: {
        totalGroups: 2,
        completedGroups: 0,
      },
    },
  },
  {
    key: 'integrity',
    maturityLevels: {
      LEADING_OTHERS: {
        totalGroups: 3,
        completedGroups: 0,
      },
      LEADING_SELF: {
        totalGroups: 4,
        completedGroups: 0,
      },
      LEADING_CHANGE: {
        totalGroups: 2,
        completedGroups: 0,
      },
    },
  },
  {
    key: 'open-mindedness',
    maturityLevels: {
      LEADING_OTHERS: {
        totalGroups: 3,
        completedGroups: 0,
      },
      LEADING_SELF: {
        totalGroups: 4,
        completedGroups: 0,
      },
      LEADING_CHANGE: {
        totalGroups: 2,
        completedGroups: 0,
      },
    },
  },
  {
    key: 'passion',
    maturityLevels: {
      LEADING_OTHERS: {
        totalGroups: 3,
        completedGroups: 0,
      },
      LEADING_SELF: {
        totalGroups: 4,
        completedGroups: 0,
      },
      LEADING_CHANGE: {
        totalGroups: 2,
        completedGroups: 0,
      },
    },
  },
  {
    key: 'resourcefulness',
    maturityLevels: {
      LEADING_OTHERS: {
        totalGroups: 3,
        completedGroups: 0,
      },
      LEADING_SELF: {
        totalGroups: 4,
        completedGroups: 0,
      },
      LEADING_CHANGE: {
        totalGroups: 2,
        completedGroups: 0,
      },
    },
  },
  {
    key: 'service',
    maturityLevels: {
      LEADING_OTHERS: {
        totalGroups: 3,
        completedGroups: 0,
      },
      LEADING_SELF: {
        totalGroups: 4,
        completedGroups: 0,
      },
      LEADING_CHANGE: {
        totalGroups: 2,
        completedGroups: 0,
      },
    },
  },
  {
    key: 'vision',
    maturityLevels: {
      LEADING_OTHERS: {
        totalGroups: 3,
        completedGroups: 0,
      },
      LEADING_SELF: {
        totalGroups: 4,
        completedGroups: 0,
      },
      LEADING_CHANGE: {
        totalGroups: 2,
        completedGroups: 0,
      },
    },
  },
];

export default LEADERSHIP_ATTRIBUTES_GRAPH;
