/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Box, Grid, IconButton } from '@mui/material';
import classNames from 'classnames';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { TextField, Typography } from '../../../../../atoms';
import AssignmentNoEditTooltip from '../../assignment-no-edit-tooltip/AssignmentNoEditTooltip';
import { GA } from '../../../../../../utils';
import { GaActions, GaCategories } from '../../../../../../constants/enums';
import { ReactComponent as DatePickerIcon } from '../../../../../../resources/icons/date_picker.svg';

const AssignmentDeadlineComponent = ({
  deadlineData,
  handleChange,
  validateDeadline,
  disabled,
}) => {
  const { t } = useTranslation();
  const { fieldLabel, value, errorMessage, title, key } = deadlineData;

  return (
    <Box>
      <Typography aria-label={`${t(title)} (${t('required')})`} paragraph variant="subtitle2">
        {t(title)}*
      </Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <AssignmentNoEditTooltip showTooltip={disabled}>
          <DateTimePicker
            disabled={disabled}
            disablePast
            onChange={(newValue) => {
              handleChange(newValue);
              GA.logInteraction({
                category: GaCategories.BEHAVIOR,
                action: GaActions.DATE_PIKER_DATE_CLICK,
                label: `Deadline for submission`,
              });
            }}
            PaperProps={{
              className: classNames('ayo-date-picker__calendar'),
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                error={!!errorMessage}
                fullWidth
                helperText={errorMessage}
                inputProps={{ ...params.inputProps, placeholder: fieldLabel }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                  endAdornment: (
                    <IconButton
                      // eslint-disable-next-line react/prop-types
                      {...params.InputProps.endAdornment?.props.children.props}
                      className="ayo-icon-button"
                      disableRipple
                    >
                      <DatePickerIcon />
                    </IconButton>
                  ),
                }}
                onBlur={() => validateDeadline([key])}
                outlined
                required
              />
            )}
            value={value}
            views={['day', 'hours', 'minutes']}
          />
        </AssignmentNoEditTooltip>
      </LocalizationProvider>
    </Box>
  );
};

const deadlineType = PropTypes.shape({
  errorMessage: PropTypes.string,
  fieldLabel: PropTypes.string,
  value: PropTypes.string,
  disabledDayText: PropTypes.string,
  title: PropTypes.string,
  handleChange: PropTypes.func,
  onErrorHandler: PropTypes.func,
  key: PropTypes.string.isRequired,
});

const deadlinesMainProps = {
  handleChange: PropTypes.func.isRequired,
  validateDeadline: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

AssignmentDeadlineComponent.propTypes = {
  deadlineData: deadlineType.isRequired,
  ...deadlinesMainProps,
};

const AssignmentDeadlines = ({ deadlines, handleChange, validateDeadline, disabled }) =>
  !!deadlines.length && (
    <Grid container spacing={3}>
      {deadlines.map((deadline) => (
        <Grid key={deadline.key} item xl={7} xs={6}>
          <AssignmentDeadlineComponent
            deadlineData={deadline}
            disabled={disabled}
            handleChange={(newValue) => handleChange(deadline.key, newValue)}
            validateDeadline={validateDeadline}
          />
        </Grid>
      ))}
    </Grid>
  );

AssignmentDeadlines.propTypes = {
  deadlines: deadlineType.isRequired,
  ...deadlinesMainProps,
};

export default AssignmentDeadlines;
