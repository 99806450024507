import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Box, Divider } from '@mui/material';

import { ActionsMenu, Card, DotIndicator, Typography } from '../../../../atoms';
import { EditingButton, TextWithTooltip } from '../../../../moleculas';
import { ConfirmDialog } from '../../../confirm-dialog/ConfirmDialog';
import { AppActions, AppContext, UserContext } from '../../../../../context';
import { getFormattedDate } from '../../../../../utils';
import { useInView, useNotificationsData } from '../../../../../hooks';
import { commentsDialog } from '../../../../../constants/routes';
import { ActionButtonsIdsMap, NotificationSourcesMap } from '../../../../../constants/enums';
import { ReactComponent as ExpandIcon } from '../../../../../resources/icons/chevron_down.svg';
import { ReactComponent as CollapseIcon } from '../../../../../resources/icons/chevron_up.svg';
import { ReactComponent as DeleteIcon } from '../../../../../resources/icons/delete_primary.svg';
import { ReactComponent as CreateIcon } from '../../../../../resources/icons/create.svg';
import CommentsEditDialog from '../comments-edit-dialog/CommentsEditDialog';

const CommentsCard = ({
  comment,
  hasReplies,
  deleteCommentHandler,
  discardButtonId,
  editCommentHandler,
  isTextCollapsed,
  parentId,
  resourceId,
  title,
  unreadItem,
}) => {
  const { t, i18n } = useTranslation();
  const commentBodyRef = useRef(null);
  const { state: userState } = useContext(UserContext);
  const [isCommentCollapsed, setIsCommentCollapsed] = useState(true);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isTextCropped, setIsTextCropped] = useState(false);
  const { dispatch: dispatchAppState } = useContext(AppContext);
  const setIsDirty = useCallback(
    (value) => {
      dispatchAppState({ type: AppActions.SET_IS_DIRTY, data: value });
    },
    [dispatchAppState],
  );

  const isAuthor = comment.authorId === userState.profile.id;

  const { updateNotificationItems } = useNotificationsData();

  const onDeleteHandler = useCallback(() => {
    setIsDeleteDialogOpen(true);
  }, []);
  const onEditComment = useCallback(() => {
    setIsEditDialogOpen(true);
  }, []);
  const cancelEditMode = () => setIsEditDialogOpen(false);
  const onDeleteDialogCloseHandler = () => setIsDeleteDialogOpen(false);
  const onCommentDeleteHandler = (id) => deleteCommentHandler(id, parentId);
  const onCommentsEditDialogCloseHandler = () => {
    setIsEditDialogOpen(false);
    setIsDirty(false);
  };

  useEffect(() => {
    setIsTextCropped(
      commentBodyRef.current?.parentElement.scrollHeight >
        commentBodyRef.current?.parentElement.clientHeight,
    );
    setIsCommentCollapsed(isTextCollapsed);
  }, [comment, isTextCollapsed]);

  const defaultMenuItems = useMemo(
    () => [
      {
        text: t('Edit'),
        icon: CreateIcon,
        handler: onEditComment,
        id: `${ActionButtonsIdsMap.EDIT}-${comment.id}`,
        gaLabel: 'Edit comment',
      },
      {
        text: t('Delete'),
        icon: DeleteIcon,
        handler: onDeleteHandler,
        id: `${ActionButtonsIdsMap.DELETE}-${comment.id}`,
        gaLabel: 'Delete comment',
      },
    ],
    [comment, onEditComment, onDeleteHandler, t],
  );

  const menuItemsConfig = useMemo(
    () =>
      defaultMenuItems.filter((buttonConfig) => buttonConfig.id.split('-')[0] !== discardButtonId),
    [discardButtonId, defaultMenuItems],
  );

  const markAsRead = useCallback(() => {
    if (unreadItem && !unreadItem?.markedAsRead) {
      updateNotificationItems(
        [{ ...unreadItem, markedAsRead: true }],
        NotificationSourcesMap.WEB_HIDDEN,
      );
    }
  }, [unreadItem, updateNotificationItems]);

  const { ref } = useInView(markAsRead, 3000, unreadItem?.markedAsRead);

  return (
    <>
      <Card
        ref={ref}
        className="ayo-comment-card"
        id={`${commentsDialog.replace('#', '')}-${comment.id}`}
        mainContent={
          <Box display="flex" flexDirection="row" position="relative">
            {unreadItem && <DotIndicator ariaLabel={t('New')} />}
            <Box width="100%">
              <Box display="flex" justifyContent="space-between">
                <Box className="ayo-comment-card__title-box">
                  <TextWithTooltip
                    headerLevel={parentId ? 4 : 3}
                    title={title || comment.title}
                    titleVariant="subtitle2"
                  />
                </Box>
                {isAuthor && (
                  <Box id={`comments_card_${comment.id}`} ml={1}>
                    <ActionsMenu
                      className="ayo-comment-card__action-buttons"
                      id="Comments actions"
                      menuItems={menuItemsConfig}
                    />
                  </Box>
                )}
              </Box>
              <Box alignItems="center" display="flex" minHeight={24}>
                <Typography isLabel variant="body3">
                  {getFormattedDate(comment.createdDate, i18n.language)}
                </Typography>
                {comment.edited && (
                  <>
                    <Divider
                      className="ayo-comment-card__divider"
                      flexItem
                      orientation="vertical"
                    />
                    <Typography isLabel variant="body3">
                      {t('Edited')}
                    </Typography>
                  </>
                )}
              </Box>
              <Box
                className={classnames('ayo-comment-card__body', {
                  'ayo-comment-card__body--collapsed': isCommentCollapsed,
                })}
                mb={1}
                mt={1}
              >
                <Typography variant="body2">
                  <span ref={commentBodyRef}>{comment.body}</span>
                </Typography>
              </Box>
              {isTextCropped &&
                (isCommentCollapsed ? (
                  <EditingButton
                    gaLabel="Read more"
                    icon={<ExpandIcon />}
                    iconPosition="end"
                    onClick={() => {
                      setIsCommentCollapsed(false);
                    }}
                    text={t('Read more')}
                  />
                ) : (
                  <EditingButton
                    gaLabel="Read less"
                    icon={<CollapseIcon />}
                    iconPosition="end"
                    onClick={() => {
                      setIsCommentCollapsed(true);
                    }}
                    text={t('Read less')}
                  />
                ))}
              <Box pt={3}>
                <Typography isLabel variant="body3">
                  {t('Added by', { author: comment.author })}
                </Typography>
              </Box>
            </Box>
          </Box>
        }
      />
      <ConfirmDialog
        cancelButtonTitle="Don't delete"
        className="ayo-comment-card__delete-dialog"
        confirmButtonTitle="Delete the comment"
        isOpen={isDeleteDialogOpen}
        onClose={onDeleteDialogCloseHandler}
        onConfirm={() => onCommentDeleteHandler(comment.id)}
        text={
          hasReplies
            ? 'All replies under this comment will be deleted as well. AYO won’t be able to restore this content.'
            : 'AYO won’t be able to restore it again(educator)'
        }
        title="Should we delete your comment?"
      />
      <CommentsEditDialog
        cancelEditMode={cancelEditMode}
        className="ayo-comment-card__edit-dialog"
        commentId={comment.id}
        commentValueEdit={comment.body}
        editCommentHandler={editCommentHandler}
        isOpen={isEditDialogOpen}
        onClose={onCommentsEditDialogCloseHandler}
        parentCommentId={parentId}
        resourceId={resourceId}
        subjectValueEdit={comment.title || title}
        submitButtonText="Save"
        title="Edit a comment"
      />
    </>
  );
};

CommentsCard.propTypes = {
  comment: PropTypes.shape({
    author: PropTypes.string,
    authorId: PropTypes.number,
    body: PropTypes.string,
    createdDate: PropTypes.string,
    edited: PropTypes.bool,
    id: PropTypes.number,
    replies: PropTypes.arrayOf(
      PropTypes.shape({
        author: PropTypes.string,
        authorId: PropTypes.number,
        body: PropTypes.string,
        createdDate: PropTypes.string,
        edited: PropTypes.bool,
        id: PropTypes.number,
        replies: PropTypes.arrayOf(PropTypes.string),
        title: PropTypes.string,
      }),
    ),
    title: PropTypes.string,
  }).isRequired,
  hasReplies: PropTypes.bool,
  deleteCommentHandler: PropTypes.func,
  discardButtonId: PropTypes.string,
  editCommentHandler: PropTypes.func,
  isTextCollapsed: PropTypes.bool,
  unreadItem: PropTypes.bool,
  parentId: PropTypes.number,
  resourceId: PropTypes.number,
  title: PropTypes.string,
};

CommentsCard.defaultProps = {
  hasReplies: false,
  deleteCommentHandler: () => {},
  discardButtonId: null,
  editCommentHandler: () => {},
  isTextCollapsed: true,
  unreadItem: false,
  parentId: null,
  resourceId: null,
  title: '',
};

export default CommentsCard;
