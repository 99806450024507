import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Divider } from '@mui/material';
import PropTypes from 'prop-types';

import { Link, Tooltip, Typography } from '../../../../../../atoms';
import { Chip, TextWithTooltip } from '../../../../../../moleculas';
import { eventType } from '../../../../../../../constants/propTypes';
import { urlAllowedValidationRE } from '../../../../../../../constants/regexps';
import {
  EventSharingGroupsLabel,
  EventsLocationTypes,
} from '../../../../../../../constants/events';
import {
  getDuration,
  getFormattedDurationRange,
  getFullSchoolName,
} from '../../../../../../../utils';
import { ReactComponent as CalendarIcon } from '../../../../../../../resources/icons/date_picker_black.svg';
import { ReactComponent as LocationIcon } from '../../../../../../../resources/icons/location.svg';
import { ReactComponent as UsersIcon } from '../../../../../../../resources/icons/users.svg';
import { ReactComponent as AcademicCapIcon } from '../../../../../../../resources/icons/academic_cap.svg';

const EventDetails = ({ event, showSharingLabels }) => {
  const { t, i18n } = useTranslation();

  const {
    description,
    goingCount,
    interestedCount,
    startDate,
    endDate,
    locationType,
    locationPlacement,
    resourceType,
    sharingGroups,
    selectedEntities,
  } = event || {};

  const isOnlineEvent = locationType === EventsLocationTypes.ONLINE;

  // TODO: remove once the interested functionality is implemented
  const showInterestedLabels = false;

  return (
    <Box
      className="ayo-events-block__dialog__details"
      display="flex"
      flexDirection="column"
      gap={4}
      mt={4}
    >
      <Box alignItems="flex-start" display="flex" flexDirection="row" gap={2}>
        <LocationIcon />
        <Box display="flex" flexDirection="column" gap={1}>
          {isOnlineEvent && <Chip className="ayo-events-block__location" label={t('Online')} />}
          {urlAllowedValidationRE.test(locationPlacement) ? (
            <Link gaLabel="Event location" href={locationPlacement} target="_blank">
              <TextWithTooltip rowsCount={3} title={locationPlacement} />
            </Link>
          ) : (
            <Typography variant="body2">{locationPlacement}</Typography>
          )}
        </Box>
      </Box>
      <Box alignItems="flex-start" display="flex" flexDirection="row" gap={2}>
        <CalendarIcon />
        <Typography variant="body2">{`${getFormattedDurationRange(
          startDate,
          endDate,
          i18n.language,
        )} (${getDuration(startDate, endDate, t)})`}</Typography>
      </Box>
      {description && (
        <Typography className="ayo-events-block__dialog__details__description" variant="body2">
          {description}
        </Typography>
      )}
      {showSharingLabels && (
        <Box display="flex" flexDirection="row" gap={2}>
          <Tooltip
            enterTouchDelay={0}
            leaveTouchDelay={5000}
            title={
              <Typography isLightText variant="caption">
                {!!sharingGroups?.length &&
                  t(EventSharingGroupsLabel[resourceType][sharingGroups.join('_')]?.label)}
              </Typography>
            }
          >
            <Box className="ayo-post-card__footer__icon">
              <UsersIcon />
            </Box>
          </Tooltip>
          {!!selectedEntities?.length && (
            <Tooltip
              enterTouchDelay={0}
              leaveTouchDelay={5000}
              title={
                <Box>
                  {selectedEntities.map(({ name }) => (
                    <Typography key={name} isLightText variant="caption">
                      {getFullSchoolName(name)}
                    </Typography>
                  ))}
                </Box>
              }
            >
              <Box className="ayo-post-card__footer__icon">
                <AcademicCapIcon aria-label={selectedEntities.map(({ name }) => name).join(', ')} />
              </Box>
            </Tooltip>
          )}
        </Box>
      )}
      {showInterestedLabels && (
        <Box alignItems="center" display="flex" flexDirection="row" gap={2}>
          <Typography variant="caption">{`${goingCount} ${t('going')}`}</Typography>
          <Divider className="ayo-events-block__divider" orientation="vertical" />
          <Typography variant="caption">
            {t('interested count', {
              count: interestedCount,
            })}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

EventDetails.propTypes = {
  event: eventType.isRequired,
  showSharingLabels: PropTypes.bool,
};

EventDetails.defaultProps = {
  showSharingLabels: false,
};

export default EventDetails;
