import React, { useCallback, useContext, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Box, Container, Grid, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Typography } from '../../../atoms';
import {
  ClickableGalleryItem,
  LeadershipAttributesGraphButton,
  NewFeatureHotspot,
  YoutubeCard,
} from '../../../moleculas';
import {
  EvidenceOfGrowthBlock,
  LeadershipAttributesGraphDialog,
  PageWrapper,
  SuggestedActivitiesBlock,
  StudentGeneralInfo,
} from '../../../organisms';
import {
  LeadershipAttributesContext,
  LeadershipAttributesActions,
  UserContext,
  UserActions,
} from '../../../../context';
import { useOneTimeActionService } from '../../../../services';
import { OneTimeActionsMap, RolesMap } from '../../../../constants/enums';
import {
  trainAyoLeadershipAttributesStudentSelectionRoute,
  leadershipRoute,
  trainAyoRoute,
} from '../../../../constants/routes';
import { findCompletedLevels } from '../../../../utils';
import { NewFeaturesIdsMap } from '../../../../tours/common/NewFeaturesItemsProvider';
import { evidencesConfigByType } from '../../../../constants/configs';
import {
  useEvidencesData,
  useLeadershipAttributesData,
  useSharedStudentData,
} from '../../../../hooks';

const BackToConfig = {
  [RolesMap.TEACHER]: {
    BACK_TO_LINK: trainAyoLeadershipAttributesStudentSelectionRoute,
    BACK_TO_TEXT: 'Go to all students',
  },
  [RolesMap.ADMINISTRATOR]: {
    BACK_TO_LINK: trainAyoLeadershipAttributesStudentSelectionRoute,
    BACK_TO_TEXT: 'Go to all students',
  },
  [RolesMap.STUDENT]: {
    BACK_TO_LINK: trainAyoRoute,
    BACK_TO_TEXT: 'Go to Train AYO',
  },
  [RolesMap.GUARDIAN]: {
    BACK_TO_LINK: trainAyoLeadershipAttributesStudentSelectionRoute,
    BACK_TO_TEXT: 'Back to kid(s) selection',
  },
};

const LeadershipAttributesAssessmentToolPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { dispatch: dispatchLeadershipAttributes } = useContext(LeadershipAttributesContext);
  const { state: userState, dispatch: dispatchUserState } = useContext(UserContext);
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const match = useRouteMatch();
  const { studentId } = match.params;
  const { graphData, leadershipAttributes } = useLeadershipAttributesData(studentId);
  const { activeStudent } = useSharedStudentData(studentId);
  const { getLAChipLabel, relatedEvidences } = useEvidencesData(
    studentId,
    evidencesConfigByType.leadership_attribute.type,
  );
  const isGraphAvailable = findCompletedLevels(graphData);
  const { role } = userState.profile;
  const isEducator = role === RolesMap.TEACHER || role === RolesMap.ADMINISTRATOR;
  const isGuardian = role === RolesMap.GUARDIAN;
  const isStudent = role === RolesMap.STUDENT;
  const { postOneTimeAction } = useOneTimeActionService();

  useEffect(() => {
    if (isStudent) {
      if (!userState.oneTimeActions.includes(OneTimeActionsMap.TRAIN_AYO_LA_OPEN)) {
        postOneTimeAction(OneTimeActionsMap.TRAIN_AYO_LA_OPEN);
        dispatchUserState({
          type: UserActions.SET_ONE_TIME_ACTION,
          data: OneTimeActionsMap.TRAIN_AYO_LA_OPEN,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToAssessementToolHandler = useCallback(
    (index) => {
      dispatchLeadershipAttributes({
        type: LeadershipAttributesActions.SET_ACTIVE_ASSESSMENT_IDX,
        data: index,
      });
      history.push(`${trainAyoRoute}/${studentId}${leadershipRoute}/${index}`);
    },
    [dispatchLeadershipAttributes, history, studentId],
  );

  const hasGeneralInfoHeader = (isEducator || isGuardian) && activeStudent;
  return (
    <PageWrapper
      backToLink={t(BackToConfig[role].BACK_TO_LINK)}
      backToText={t(BackToConfig[role].BACK_TO_TEXT)}
    >
      <Container>
        <Box pb={isWidthUpSm ? 10 : 5}>
          {hasGeneralInfoHeader && <StudentGeneralInfo student={activeStudent} />}
          <Box
            display="flex"
            flexDirection={isWidthUpSm ? 'row' : 'column'}
            justifyContent="space-between"
          >
            <Box width={isWidthUpSm ? 'auto' : '100%'}>
              <Box display="flex" justifyContent="space-between">
                <Box alignItems="center" display="flex">
                  <Typography component={hasGeneralInfoHeader ? 'h2' : 'h1'} paragraph variant="h2">
                    {t('Leadership Attributes')}
                  </Typography>
                  <Box mb={1}>
                    <NewFeatureHotspot id={NewFeaturesIdsMap.ASSESSMENT_TOOL} isClickable />
                  </Box>
                </Box>
              </Box>
              <Typography variant="body2">
                {t('Select an Attribute that you would like to share insights for with AYO')}
              </Typography>
            </Box>
            <Box pt={isWidthUpSm ? 0 : 3}>
              {isGraphAvailable && <LeadershipAttributesGraphButton />}
            </Box>
          </Box>
          <Grid className="ayo-leadership-assessment-home-page__images">
            {leadershipAttributes.map((attribute, idx) => (
              <ClickableGalleryItem
                key={attribute.key}
                attribute={attribute}
                className="ayo-leadership-assessment-home-page__card"
                clickHandler={() => goToAssessementToolHandler(idx)}
                index={idx}
                label={attribute.status}
                section="assesmentToolBlockConfig"
              />
            ))}
            <YoutubeCard />
          </Grid>
        </Box>
        <Box mb={isWidthUpSm ? 10 : 5}>
          <EvidenceOfGrowthBlock
            evidenceType={evidencesConfigByType.leadership_attribute.type}
            getChipLabelHandler={getLAChipLabel}
            headerLevel={hasGeneralInfoHeader ? 3 : 2}
            isActionButtonsAvailable
            relatedEvidences={relatedEvidences}
            studentId={studentId}
            titleVariant={isGuardian ? 'subtitle1' : 'h2'}
          />
        </Box>
        <Box mb={isWidthUpSm ? 10 : 5}>
          <SuggestedActivitiesBlock />
        </Box>
        <LeadershipAttributesGraphDialog />
      </Container>
    </PageWrapper>
  );
};

export default LeadershipAttributesAssessmentToolPage;
