import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { RadioGroup } from '../../../atoms';
import {
  FeedPostSharingGroups,
  FeedPostSharingGroupsKeys,
  PostsSourceMap,
  SharingLevels,
} from '../../../../constants/family-feed';
import { useFamilyFeed } from '../../../../hooks';
import { removeObjectKeys } from '../../../../utils';
import { GaActions, GaCategories } from '../../../../constants/enums';
import GA from '../../../../utils/analytics/Analytics';

const formatSharingGroups = (value, expectedType) => {
  let result = value;

  if (expectedType === 'array') {
    result = Array.isArray(value) ? value : value.split(',');
  } else if (expectedType === 'string') {
    result = Array.isArray(value) ? value.join(',') : value;
  }

  return result;
};

const updatedSharingGroups = removeObjectKeys(FeedPostSharingGroups, [
  FeedPostSharingGroupsKeys.STUDENTS,
]);

const SharingLevelToSharingGroupsMap = {
  [SharingLevels.DISTRICT.value]: Object.values(updatedSharingGroups),
  [SharingLevels.CAMPUS.value]: Object.values(updatedSharingGroups),
  [SharingLevels.CLASS.value]: [
    updatedSharingGroups.PARENTS_STUDENTS,
    updatedSharingGroups.PARENTS,
  ],
  [SharingLevels.PERSONALIZED.value]: [
    updatedSharingGroups.PARENTS_STUDENTS,
    updatedSharingGroups.PARENTS,
  ],
};

const RolesVisibilityPicker = ({ onChange, sharingGroups, sharingLevel, title }) => {
  const { t } = useTranslation();

  const { getSharingGroupsText } = useFamilyFeed();

  const [newSharingGroups, setNewSharingGroups] = useState(
    formatSharingGroups(SharingLevelToSharingGroupsMap[sharingLevel][0].value, 'string'),
  );

  const radioGroupOptions = useMemo(
    () =>
      SharingLevelToSharingGroupsMap[sharingLevel].map((item) => ({
        value: formatSharingGroups(item.value, 'string'),
        text: t(item.text),
      })),
    [sharingLevel, t],
  );

  const handleChange = useCallback(
    (e) => {
      GA.logInteraction({
        category: GaCategories.BEHAVIOR,
        action: GaActions.RADIOBUTTON_CLICK,
        label: getSharingGroupsText(
          formatSharingGroups(e.target.value, 'array'),
          PostsSourceMap.FAMILY,
        ),
      });

      onChange(formatSharingGroups(e.target.value, 'array'));
    },
    [getSharingGroupsText, onChange],
  );

  useEffect(() => {
    if (sharingGroups) {
      setNewSharingGroups(formatSharingGroups(sharingGroups, 'string'));
    } else if (!sharingGroups) {
      setNewSharingGroups(
        formatSharingGroups(SharingLevelToSharingGroupsMap[sharingLevel][0].value, 'string'),
      );

      onChange(formatSharingGroups(SharingLevelToSharingGroupsMap[sharingLevel][0].value, 'array'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sharingGroups, sharingLevel]);

  return (
    <RadioGroup
      caption={t(title)}
      captionComponentType="h4"
      className="ayo-feed-roles-visibility-picker"
      onChange={handleChange}
      options={radioGroupOptions}
      value={newSharingGroups}
    />
  );
};

RolesVisibilityPicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  sharingGroups: PropTypes.arrayOf(PropTypes.string),
  sharingLevel: PropTypes.string.isRequired,
  title: PropTypes.string,
};

RolesVisibilityPicker.defaultProps = {
  sharingGroups: null,
  title: 'Who should be able to see the post',
};

export default RolesVisibilityPicker;
