import React, { useCallback, useMemo, useState } from 'react';
import { Box, DialogActions, DialogContent, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Button, Dialog, DialogTitle, Tooltip, Typography } from '../../../../atoms';
import { Chip, MlChip, PopupActionsButtons } from '../../../../moleculas';
import { ReactComponent as ClosePrimaryIcon } from '../../../../../resources/icons/close_primary.svg';
import { ReactComponent as ArrowsInCircleIcon } from '../../../../../resources/icons/arrows_in_circle.svg';
import { formatObjKeysToWords, formatTag, isEmpty } from '../../../../../utils';

export const ClubTagsBlock = ({ allTagsData, selectedTagsData, onDelete, onReset }) => {
  const { t } = useTranslation();

  const getTagsDataLength = useCallback((data) => Object.values(data).flat().length, []);

  const hasUserChanges = useMemo(
    () =>
      !isEmpty(allTagsData) &&
      getTagsDataLength(selectedTagsData) !== getTagsDataLength(allTagsData),
    [getTagsDataLength, selectedTagsData, allTagsData],
  );

  return (
    <Box mb={3}>
      <Box backgroundColor="#FFCC80" borderRadius="16px" mb={2} p={3}>
        <Box display="flex" justifyContent="space-between" mb={2}>
          <Typography component="h3" variant="subtitle2">
            {t('Assigned tags')}
          </Typography>
          <Tooltip title={t('tags insights badge tooltip')}>
            <MlChip text={t('AYO insight')} />
          </Tooltip>
        </Box>
        <Box>
          {!isEmpty(selectedTagsData) && !!getTagsDataLength(selectedTagsData) ? (
            Object.entries(selectedTagsData).map(
              ([tagGroup, tags]) =>
                !isEmpty(tags) && (
                  <Box key={tagGroup} mb={4}>
                    <Typography mb={1} variant="subtitle2">
                      {t(formatObjKeysToWords(tagGroup, true))}
                    </Typography>
                    <Grid container spacing={1.5}>
                      {tags.map((tag) => (
                        <Grid key={tag} item>
                          <Chip
                            ariaLabel={formatTag(tag)}
                            deleteIcon={<ClosePrimaryIcon />}
                            gaLabel="Club description tag"
                            label={formatTag(tag)}
                            onDelete={() => onDelete(tag, tagGroup)}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                ),
            )
          ) : (
            <Typography variant="body2">
              {t('You removed all tags. If you require the tags back, please reset them.')}
            </Typography>
          )}
        </Box>
      </Box>
      {hasUserChanges && (
        <Button
          endIcon={<ArrowsInCircleIcon />}
          gaLabel="Reset all tags"
          onClick={onReset}
          variant="text"
        >
          {t('Reset all tags')}
        </Button>
      )}
    </Box>
  );
};

ClubTagsBlock.propTypes = {
  allTagsData: PropTypes.instanceOf(Object).isRequired,
  selectedTagsData: PropTypes.instanceOf(Object).isRequired,
  onDelete: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
};

const ClubTagsDialog = ({ isOpen, tagsData, onClose, onSave }) => {
  const { t } = useTranslation();

  const [selectedTagsData, setSelectedTagsData] = useState(tagsData);

  const resetTags = useCallback(() => {
    setSelectedTagsData({ ...tagsData });
  }, [tagsData]);

  const handleDeleteTag = useCallback((tag, tagGroup) => {
    setSelectedTagsData((prevState) => {
      const newState = {
        ...prevState,
        [tagGroup]: prevState[tagGroup].filter((item) => item !== tag),
      };

      if (isEmpty(newState[tagGroup])) delete newState[tagGroup];

      return newState;
    });
  }, []);

  return (
    <Dialog
      className="ayo-club-hub-details__edit-dialog__tags"
      gaLabel="Club tags dialog"
      onClose={onClose}
      open={isOpen}
    >
      <DialogTitle disableTypography>
        <Typography component="h2" mb={1} variant="h2">
          {t('There’s one more step before you send this club for approval')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <>
          <Typography mb={3} variant="body2">
            {t('AYO has analyzed the content of the page and added the following tags paragraph')}
          </Typography>
          <ClubTagsBlock
            allTagsData={tagsData}
            onDelete={handleDeleteTag}
            onReset={resetTags}
            selectedTagsData={selectedTagsData}
          />
        </>
      </DialogContent>
      <DialogActions>
        <PopupActionsButtons
          primaryButtonGaLabel="Send for approval"
          primaryButtonHandler={() => onSave(selectedTagsData)}
          primaryButtonText={t('Send for approval')}
          secondaryButtonGaLabel="Cancel"
          secondaryButtonHandler={onClose}
          secondaryButtonText={t('Cancel')}
        />
      </DialogActions>
    </Dialog>
  );
};

ClubTagsDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  tagsData: PropTypes.instanceOf(Object),
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

ClubTagsDialog.defaultProps = {
  tagsData: {},
};

export default ClubTagsDialog;
