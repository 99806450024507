import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { apiRoute } from '../../../constants/routes';

const getAttachmentSrc = ({ id, ownerId }) => `${apiRoute}/attachments/${id}/owners/${ownerId}`;

const Banner = ({ image }) => {
  const imageSrc = image instanceof File ? URL.createObjectURL(image) : getAttachmentSrc(image);
  const { t } = useTranslation();
  return <img alt={t('Banner')} className="ayo-banner" height={250} src={imageSrc} width="100%" />;
};

Banner.propTypes = {
  image: PropTypes.instanceOf(Object).isRequired,
};

export default Banner;
