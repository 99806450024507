import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Box, Grid, useTheme, useMediaQuery } from '@mui/material';

import { Typography } from '../../../atoms';
import { TermsConditionsContent } from '../../../moleculas';
import { BottomBar, PageWrapper } from '../../../organisms';

const TermsConditionsPage = () => {
  const { t } = useTranslation();

  const urlParams = new URLSearchParams(window.location.search);
  const readOnly = urlParams.get('readOnly');
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  const Content = (
    <Container>
      <Grid container direction="column">
        <Grid container item>
          <Grid item sm={7}>
            <Box mb={isWidthUpSm ? 3 : 1.5}>
              <Typography component="h1" variant="h1">
                {t('Terms & Conditions')}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <TermsConditionsContent />
      </Grid>
    </Container>
  );

  return readOnly ? (
    <Box pt={5}>{Content}</Box>
  ) : (
    <PageWrapper
      backToLink="/"
      backToText={t('Back to main page')}
      className="ayo-terms-conditions-page"
      customFooter={<BottomBar />}
      noNav
    >
      {Content}
    </PageWrapper>
  );
};

export default TermsConditionsPage;
