import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Grid } from '@mui/material';

import { Checkbox, FormControlLabel, Link, Typography } from '../../../../../atoms';
import AssignmentNoEditTooltip from '../../assignment-no-edit-tooltip/AssignmentNoEditTooltip';
import { myStudentsRoute } from '../../../../../../constants/routes';
import { ReactComponent as LinkIcon } from '../../../../../../resources/icons/link.svg';
import UnavailableStudentsMessage from '../../unavailable-students-message/UnavailableStudentsMessage';

const AssignmentStudents = ({ students, title, handleChange, errorMessage, disabled }) => {
  const { t } = useTranslation();

  const selectAllValue = students?.every((student) => student.value);

  const selectAllHandler = useCallback(
    (e) =>
      handleChange(
        students.map((student) => student.email),
        e.target.checked,
      ),
    [students, handleChange],
  );

  return (
    <Box className="ayo-lesson-assignment-students">
      <Typography aria-label={`${title} (${t('required')})`} paragraph variant="subtitle2">
        {title}*
      </Typography>
      {students ? (
        <Grid columnSpacing={2} container rowSpacing={1}>
          <Grid item md={4}>
            <FormControlLabel
              className={classnames('ayo-lesson-assignment-students__form-control', {
                'ayo-lesson-assignment-students__form-control--error': errorMessage,
              })}
              control={
                <AssignmentNoEditTooltip showTooltip={disabled}>
                  <Checkbox
                    checked={selectAllValue}
                    disabled={disabled}
                    gaLabel="Select all"
                    onChange={selectAllHandler}
                    value={selectAllValue}
                  />
                </AssignmentNoEditTooltip>
              }
              label={t('Select all')}
            />
          </Grid>
          {students.map((student) => (
            <Grid key={student.id} item md={4}>
              <FormControlLabel
                className={classnames('ayo-lesson-assignment-students__form-control', {
                  'ayo-lesson-assignment-students__form-control--error': errorMessage,
                })}
                control={
                  <AssignmentNoEditTooltip showTooltip={disabled}>
                    <Checkbox
                      checked={student.value}
                      disabled={disabled}
                      gaLabel="Assignment student"
                      onChange={(e) => handleChange([student.email], e.target.checked)}
                      value={student.value}
                    />
                  </AssignmentNoEditTooltip>
                }
                label={
                  <Link
                    component={RouterLink}
                    gaLabel="Asignment student profile"
                    icon={<LinkIcon />}
                    target="_blank"
                    to={`${myStudentsRoute}/${student.id}`}
                    underline="none"
                  >
                    <Typography
                      className="ayo-lesson-assignment-students__form-control__student-label__text"
                      variant="subtitle2"
                    >
                      {student.name}
                    </Typography>
                  </Link>
                }
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <UnavailableStudentsMessage />
      )}
      {errorMessage && (
        <Typography
          className="ayo-lesson-assignment-students__error-message"
          role="alert"
          variant="body2"
        >
          {errorMessage}
        </Typography>
      )}
    </Box>
  );
};

AssignmentStudents.propTypes = {
  students: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  disabled: PropTypes.bool,
  title: PropTypes.string,
  errorMessage: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
};

AssignmentStudents.defaultProps = {
  students: null,
  disabled: false,
  title: 'Assigned students',
  errorMessage: null,
};

export default AssignmentStudents;
