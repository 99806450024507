/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Accordion as MUIAccordion, AccordionSummary as MUIAccordionSummary } from '@mui/material';

import { ReactComponent as OpenIcon } from '../../../resources/icons/plus_open_sign.svg';
import { ReactComponent as CloseIcon } from '../../../resources/icons/minus_close_sign.svg';
import { GaActions, GaCategories } from '../../../constants/enums';
import { GA } from '../../../utils';

const Accordion = ({ children, className, expanded, gaLabel, onChange, ...props }) => {
  const onChangeHandler = useCallback(
    (e, newExpanded) => {
      if (gaLabel) {
        GA.logInteraction({
          category: GaCategories.BEHAVIOR,
          action: GaActions.ACCORDION_TOGLE,
          label: `${gaLabel} - ${newExpanded ? 'expanded' : 'collapsed'}`,
        });
      }
      onChange(e, newExpanded);
    },
    [gaLabel, onChange],
  );
  return (
    <MUIAccordion
      className={classnames('ayo-accordion', className)}
      expanded={expanded}
      onChange={onChangeHandler}
      variant="outlined"
      {...props}
    >
      {children}
    </MUIAccordion>
  );
};

export const AccordionSummary = ({ ariaLabel, children, className, iconCollapse, iconExpand }) => (
  <MUIAccordionSummary
    aria-label={ariaLabel}
    className={classnames('ayo-accordion__summary', className)}
  >
    {iconCollapse}
    {iconExpand}
    {children}
  </MUIAccordionSummary>
);

Accordion.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
    .isRequired,
  className: PropTypes.string,
  expanded: PropTypes.bool.isRequired,
  gaLabel: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

Accordion.defaultProps = {
  className: '',
  gaLabel: '',
};

AccordionSummary.propTypes = {
  ariaLabel: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
    .isRequired,
  className: PropTypes.string,
  iconCollapse: PropTypes.node,
  iconExpand: PropTypes.node,
};

AccordionSummary.defaultProps = {
  ariaLabel: null,
  className: '',
  iconCollapse: <CloseIcon className="ayo-accordion__close-icon" />,
  iconExpand: <OpenIcon className="ayo-accordion__open-icon" />,
};

export default Accordion;
