import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Typography } from '../../../atoms';

const BlockHeader = ({ title, subtitle }) => {
  const { t } = useTranslation();

  return (
    <>
      <Typography component="h3" mb={1} variant="subtitle2">
        {t(title)}
      </Typography>
      {!!subtitle && (
        <Typography mb={2} variant="body2">
          {t(subtitle)}
        </Typography>
      )}
    </>
  );
};

BlockHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

BlockHeader.defaultProps = {
  subtitle: '',
};

export default BlockHeader;
