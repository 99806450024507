import React, { useCallback, useContext, useEffect, useState, useMemo, useRef } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Box, Container, Tab, useMediaQuery, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { Button, TabPanel, Tabs, Typography } from '../../../atoms';
import {
  Carousel,
  GoToStudentProfileBlock,
  InitiativeEmptyStateBlock,
  NewFeatureHotspot,
  StudentFilters,
} from '../../../moleculas';
import { PageWrapper, OpportunityCard } from '../../../organisms';
import { AppActions, AppContext, UserActions, UserContext } from '../../../../context';
import { useOneTimeActionService, useStudentsService } from '../../../../services';
import {
  GA,
  getFilterValueParts,
  getNormalizedLanguageCode,
  sortArrayByKey,
  transformSearchToObject,
  useProfile,
} from '../../../../utils';
import { trainAyoRoute } from '../../../../constants/routes';
import {
  GaActions,
  GaCategories,
  KeyboardMap,
  OneTimeActionsMap,
} from '../../../../constants/enums';
import { ReactComponent as TwistedArrow } from '../../../../resources/icons/arrow_twisted_right.svg';
import { ReactComponent as FullScreenIcon } from '../../../../resources/icons/fullscreen.svg';
import { ReactComponent as FullScreenExitIcon } from '../../../../resources/icons/fullscreen_exit.svg';
import { ReactComponent as ThumbsDownIcon } from '../../../../resources/icons/thumbs_down.svg';
import { ReactComponent as ThumbsUpIcon } from '../../../../resources/icons/thumbs_up.svg';
import { ReactComponent as EmptyCollectionIllustration } from '../../../../resources/images/opportunities_collection_empty.svg';
import OpportunitiesTypeFilters from '../../../organisms/opportunities-block/filters/OpportunitiesTypeFilter';
import { useSearchParams } from '../../../../hooks';
import { OpportunitiesFilterCategory } from '../../../../constants/filter-categories';
import { NewFeaturesIdsMap } from '../../../../tours/common/NewFeaturesItemsProvider';

const opportunityUpdater = (opportunities, index, isInterested, isViewed) => ({
  ...opportunities[index],
  interested: isInterested !== undefined ? isInterested : opportunities[index].interested,
  viewed: isViewed !== undefined ? isViewed : opportunities[index].viewed,
});

const OpportunitiesTabsMap = {
  EXPLORATION: 'Exploration',
  MY_COLLECTION: 'My collection',
};

const EmptyStateBase = ({ title, body, button }) => (
  <InitiativeEmptyStateBlock
    body={body}
    customButton={button}
    illustration={<EmptyCollectionIllustration />}
    title={title}
  />
);

EmptyStateBase.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  button: PropTypes.element,
};

EmptyStateBase.defaultProps = {
  button: null,
};

const FiltersEmptyState = ({ onFiltersReset }) => {
  const { t } = useTranslation();
  return (
    <EmptyStateBase
      body={t('Please reset the filters to see the cards')}
      button={
        <Button gaLabel="Empty state - Reset filters" onClick={onFiltersReset} variant="primary">
          {t('Reset filters')}
        </Button>
      }
      title={t('All decks are empty')}
    />
  );
};

FiltersEmptyState.propTypes = {
  onFiltersReset: PropTypes.func.isRequired,
};

const FiltersOneDeckEmptyState = ({ onFiltersReset }) => {
  const { t } = useTranslation();
  return (
    <EmptyStateBase
      body={t('Please reset the filters to see the cards')}
      button={
        <Button gaLabel="Empty deck - Reset filters" onClick={onFiltersReset} variant="primary">
          {t('Reset filters')}
        </Button>
      }
      title={t('The deck is empty')}
    />
  );
};

FiltersOneDeckEmptyState.propTypes = {
  onFiltersReset: PropTypes.func.isRequired,
};

const ExplorationEmptyState = ({
  onFiltersReset,
  initialCategoryOpportunitiesCount,
  withFilters,
}) => {
  const { t } = useTranslation();
  return withFilters && initialCategoryOpportunitiesCount ? (
    <FiltersOneDeckEmptyState onFiltersReset={onFiltersReset} />
  ) : (
    <EmptyStateBase
      body={t('Don’t worry, it’s about to be updated soon!')}
      title={t('Exploration deck is empty')}
    />
  );
};

ExplorationEmptyState.propTypes = {
  onFiltersReset: PropTypes.func.isRequired,
  initialCategoryOpportunitiesCount: PropTypes.number,
  withFilters: PropTypes.bool,
};

ExplorationEmptyState.defaultProps = {
  initialCategoryOpportunitiesCount: 0,
  withFilters: false,
};

const MyCollectionEmptyState = ({
  onStartExploring,
  onFiltersReset,
  initialCategoryOpportunitiesCount,
  withFilters,
}) => {
  const { t } = useTranslation();
  return withFilters && !!initialCategoryOpportunitiesCount ? (
    <FiltersOneDeckEmptyState onFiltersReset={onFiltersReset} />
  ) : (
    <EmptyStateBase
      body={t('Go to Exploration and find what you’re into to form your personalized collection')}
      button={
        <Button
          gaLabel="My collection empty state - Start exploring"
          onClick={onStartExploring}
          variant="primary"
        >
          {t('Start exploring')}
        </Button>
      }
      title={t('My collection deck is empty')}
    />
  );
};

MyCollectionEmptyState.propTypes = {
  onStartExploring: PropTypes.func.isRequired,
  onFiltersReset: PropTypes.func.isRequired,
  initialCategoryOpportunitiesCount: PropTypes.number,
  withFilters: PropTypes.bool,
};

MyCollectionEmptyState.defaultProps = {
  initialCategoryOpportunitiesCount: 0,
  withFilters: false,
};

const NoOpportunitiesEmptyState = () => {
  const { t } = useTranslation();
  return (
    <EmptyStateBase
      body={t('Hopefully, you’ll see something new and exciting here soon!')}
      title={t('All decks are empty')}
    />
  );
};

const OpportunitiesCardHelper = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const { getOneTimeActionStatus } = useProfile();

  return isWidthUpSm && !getOneTimeActionStatus(OneTimeActionsMap.EXPLORE_OPPORTUNITY_FLIP_CARD) ? (
    <Box className="ayo-flippable-helper">
      <TwistedArrow />
      <Typography variant="body3">{t('Click the flip icon to see more details')}</Typography>
    </Box>
  ) : null;
};

const Filters = {
  [OpportunitiesFilterCategory]: {
    name: 'Type',
    FilterBox: OpportunitiesTypeFilters,
    isDisabled: () => false,
  },
};

const ExploreOpportunitiesPage = () => {
  const { i18n, t } = useTranslation();
  const { state: userState, dispatch: dispatchUserState } = useContext(UserContext);
  const { dispatch: dispatchAppState } = useContext(AppContext);
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const currentStudentId = userState.profile.id;

  const [suggestedOpportunities, setSuggestedOpportunities] = useState(null);
  const [selectedOpportunities, setSelectedOpportunities] = useState(null);
  const { getStudentOpportunities, postStudentOpportunity } = useStudentsService();
  const { postOneTimeAction } = useOneTimeActionService();

  const [activeTab, setActiveTab] = useState(OpportunitiesTabsMap.EXPLORATION);

  const [isFullScreenEntered, setIsFullScreenEntered] = useState(false);
  const toggleFullScreen = () => setIsFullScreenEntered((state) => !state);

  const [activeOpportunityIndex, setActiveOpportunityIndex] = useState(0);

  const search = useSearchParams();
  const filters = useMemo(() => transformSearchToObject(search), [search]);

  const { filtersList } = getFilterValueParts(filters);

  const displayedSelectedOpportunities = useMemo(
    () =>
      filtersList.length
        ? selectedOpportunities?.filter((opportunity) => filtersList.includes(opportunity.category))
        : selectedOpportunities,
    [filtersList, selectedOpportunities],
  );

  const displayedSuggestedOpportunities = useMemo(
    () =>
      filtersList.length
        ? suggestedOpportunities?.filter((opportunity) =>
            filtersList.includes(opportunity.category),
          )
        : suggestedOpportunities,
    [filtersList, suggestedOpportunities],
  );

  const history = useHistory();

  const suggestedCarouselApiRef = useRef();
  const selectedCarouselApiRef = useRef();

  useEffect(() => {
    const opportunitiesArray =
      activeTab === OpportunitiesTabsMap.EXPLORATION ? displayedSuggestedOpportunities : null;
    if (
      opportunitiesArray &&
      opportunitiesArray[activeOpportunityIndex] &&
      !opportunitiesArray[activeOpportunityIndex].viewed
    ) {
      const updatedList = JSON.parse(JSON.stringify(suggestedOpportunities));
      const updatedOpportunity = opportunityUpdater(
        opportunitiesArray,
        activeOpportunityIndex,
        null,
        true,
      );
      const replacementIndex = suggestedOpportunities.findIndex(
        (opportunity) => opportunity.entityKey === updatedOpportunity.entityKey,
      );
      updatedList[replacementIndex] = updatedOpportunity;
      setSuggestedOpportunities(updatedList);
      postStudentOpportunity(updatedOpportunity, currentStudentId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeOpportunityIndex, activeTab, displayedSuggestedOpportunities]);

  useEffect(() => {
    if (!userState.oneTimeActions.includes(OneTimeActionsMap.EXPLORE_OPPORTUNITY_OPEN)) {
      postOneTimeAction(OneTimeActionsMap.EXPLORE_OPPORTUNITY_OPEN);
      dispatchUserState({
        type: UserActions.SET_ONE_TIME_ACTION,
        data: OneTimeActionsMap.EXPLORE_OPPORTUNITY_OPEN,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getStudentOpportunities(currentStudentId, false, getNormalizedLanguageCode(i18n.language)).then(
      (opportunities) => {
        setSuggestedOpportunities(
          sortArrayByKey(
            sortArrayByKey(
              opportunities?.suggestedOpportunities.filter(
                (opportunity) => opportunity.interested === null,
              ),
              'confidence',
            ),
            'updatedDate',
            'date',
          ).sort((a, b) => !!a.viewed - !!b.viewed),
        );
        setSelectedOpportunities(
          sortArrayByKey(
            opportunities?.selectedOpportunities.filter((opportunity) => opportunity.interested),
            'updatedDate',
            'date',
          ),
        );
      },
    );
  }, [
    currentStudentId,
    getStudentOpportunities,
    setSelectedOpportunities,
    setSuggestedOpportunities,
    i18n.language,
  ]);

  const handleTabClick = (e, value) => {
    GA.logInteraction({
      category: GaCategories.BEHAVIOR,
      action: GaActions.TAB_CLICK,
      label: value,
    });
    setActiveTab(value);
    setActiveOpportunityIndex(0);
  };

  const submitReaction = useCallback(
    (reactionValue, onReactionSubmit) => {
      GA.logInteraction({
        category: GaCategories.BEHAVIOR,
        action: GaActions.BUTTON_CLICK,
        label: reactionValue ? 'Like' : reactionValue === false ? 'Dislike' : 'Undo',
      });
      const displayedOpportunitiesArray =
        activeTab === OpportunitiesTabsMap.EXPLORATION
          ? displayedSuggestedOpportunities
          : displayedSelectedOpportunities;
      const [allOpportunitiesArray, setAllOpportunitiesArray] =
        activeTab === OpportunitiesTabsMap.EXPLORATION
          ? [suggestedOpportunities, setSuggestedOpportunities]
          : [selectedOpportunities, setSelectedOpportunities];
      const updatedList = JSON.parse(JSON.stringify(allOpportunitiesArray));
      const prevReaction = displayedOpportunitiesArray[activeOpportunityIndex].interested;
      const updatedOpportunity = opportunityUpdater(
        displayedOpportunitiesArray,
        activeOpportunityIndex,
        prevReaction === reactionValue
          ? null
          : reactionValue === null
          ? prevReaction
          : reactionValue,
        undefined,
      );
      const replacementIndex = allOpportunitiesArray.findIndex(
        (opportunity) => opportunity.entityKey === updatedOpportunity.entityKey,
      );
      updatedList[replacementIndex] = updatedOpportunity;
      setAllOpportunitiesArray(updatedList);
      postStudentOpportunity(updatedOpportunity, currentStudentId).then(() => {
        if (reactionValue === null || prevReaction !== reactionValue) {
          onReactionSubmit();
        }
      });
    },
    [
      activeOpportunityIndex,
      activeTab,
      currentStudentId,
      displayedSelectedOpportunities,
      displayedSuggestedOpportunities,
      postStudentOpportunity,
      selectedOpportunities,
      suggestedOpportunities,
    ],
  );

  const handleUndoButtonClick = useCallback(() => {
    submitReaction(null, () => {
      dispatchAppState({
        type: AppActions.SET_SNACKBAR_STATUS,
        data: null,
      });
    });
  }, [dispatchAppState, submitReaction]);

  const handleLikeButtonClick = useCallback(() => {
    submitReaction(true, () => {
      dispatchAppState({
        type: AppActions.SET_SNACKBAR_STATUS,
        data: {
          text: t('Behold! One more opportunity was added to your collection'),
          type: 'success',
        },
      });
    });
  }, [dispatchAppState, submitReaction, t]);

  const handleDislikeButtonClick = useCallback(() => {
    submitReaction(false, () => {
      dispatchAppState({
        type: AppActions.SET_SNACKBAR_STATUS,
        data: {
          text: t('Thanks for sharing! AYO won’t show this card for you anymore'),
          type: 'success',
          action: (
            <Button autoFocus onClick={handleUndoButtonClick}>
              {t('Undo')}
            </Button>
          ),
        },
      });
    });
  }, [dispatchAppState, handleUndoButtonClick, submitReaction, t]);

  const hasOpportunities = !!selectedOpportunities?.length || !!suggestedOpportunities?.length;

  const hasDisplayedOpportunities =
    !!displayedSelectedOpportunities?.length || !!displayedSuggestedOpportunities?.length;

  return (
    <PageWrapper
      backToLink={trainAyoRoute}
      backToText={t('Go to Train AYO')}
      mainElementClassName="ayo-opportunities"
    >
      <Container>
        <Box mb={isWidthUpSm ? 5 : 4}>
          <Box alignItems="center" display="flex">
            <Typography component="h1" paragraph variant="h1">
              {t('My opportunities')}
            </Typography>
            <NewFeatureHotspot
              id={NewFeaturesIdsMap.OPPORTUNITIES}
              isClickable
              label={t('Opportunities')}
            />
          </Box>
          <Typography className="ayo-opportunities__subtitle" variant="body2">
            {t('Feel free to explore opportunities to shape your collection!')}
          </Typography>
          <Typography variant="body2">
            {t('Just react to what AYO suggests to you with “thumbs up” or “thumbs down”')}
          </Typography>
        </Box>
        <Box
          className={classnames('ayo-opportunities__container', {
            'ayo-opportunities__container--full': isFullScreenEntered,
          })}
          mb={isWidthUpSm ? 10 : 5}
        >
          {hasOpportunities ? (
            <>
              <Box
                display="flex"
                justifyContent={isWidthUpSm ? 'flex-end' : 'space-between'}
                pb={3}
                pt={isWidthUpSm ? 3 : 1.5}
                px={isWidthUpSm ? 3 : 1.5}
              >
                {!isWidthUpSm && (
                  <Button
                    className="ayo-full-screen-btn"
                    gaLabel={
                      isFullScreenEntered ? 'Exit full screen mode' : 'Enter full screen mode'
                    }
                    isIconButton
                    onClick={toggleFullScreen}
                  >
                    {isFullScreenEntered ? <FullScreenExitIcon /> : <FullScreenIcon />}
                  </Button>
                )}
                <StudentFilters
                  filtersConfig={Filters}
                  minimizeMobile
                  onFiltersUpdate={() => {
                    suggestedCarouselApiRef.current?.resetCarouselPosition();
                    selectedCarouselApiRef.current?.resetCarouselPosition();
                    dispatchAppState({
                      type: AppActions.SET_SNACKBAR_STATUS,
                      data: { text: t('Apply opportunities filter'), type: 'success' },
                    });
                  }}
                  // Prevent overflow modifier for right side does not allow to scroll page lower than filter by menu on mobiles for some reason
                  popperEventsEnabled={isWidthUpSm}
                  selectedInitiative={OpportunitiesFilterCategory}
                  withChips={false}
                />
              </Box>
              {hasDisplayedOpportunities ? (
                <>
                  <Tabs onChange={handleTabClick} value={activeTab} variant="standard">
                    <Tab
                      disableRipple
                      label={t(OpportunitiesTabsMap.EXPLORATION)}
                      value={OpportunitiesTabsMap.EXPLORATION}
                    />
                    <Tab
                      disableRipple
                      label={t(OpportunitiesTabsMap.MY_COLLECTION)}
                      value={OpportunitiesTabsMap.MY_COLLECTION}
                    />
                  </Tabs>
                  <TabPanel
                    className="ayo-opportunities__container__content"
                    currentTabValue={activeTab}
                    value={OpportunitiesTabsMap.EXPLORATION}
                  >
                    {displayedSuggestedOpportunities?.length > 0 ? (
                      <>
                        <Carousel
                          apiRef={suggestedCarouselApiRef}
                          items={displayedSuggestedOpportunities.map((opportunity, idx) => (
                            <OpportunityCard
                              key={opportunity?.name}
                              isActiveCard={activeOpportunityIndex === idx}
                              opportunity={opportunity}
                              showMysteryCard
                            />
                          ))}
                          keyField="entityKey"
                          onActiveItemIndexChange={(index) => setActiveOpportunityIndex(index)}
                          variant="3d"
                        />
                        {displayedSuggestedOpportunities[activeOpportunityIndex]?.description && (
                          <OpportunitiesCardHelper />
                        )}
                      </>
                    ) : (
                      <ExplorationEmptyState
                        initialCategoryOpportunitiesCount={suggestedOpportunities?.length || 0}
                        onFiltersReset={() => history.push({ search: null })}
                        withFilters={!!filtersList.length}
                      />
                    )}
                  </TabPanel>
                  <TabPanel
                    className="ayo-opportunities__container__content"
                    currentTabValue={activeTab}
                    value={OpportunitiesTabsMap.MY_COLLECTION}
                  >
                    {displayedSelectedOpportunities?.length > 0 ? (
                      <>
                        <Carousel
                          apiRef={selectedCarouselApiRef}
                          items={displayedSelectedOpportunities.map((opportunity, idx) => (
                            <OpportunityCard
                              key={opportunity?.name}
                              isActiveCard={activeOpportunityIndex === idx}
                              opportunity={opportunity}
                              showActionsButtons
                            />
                          ))}
                          keyField="entityKey"
                          onActiveItemIndexChange={(index) => setActiveOpportunityIndex(index)}
                          variant="3d"
                        />
                        {displayedSelectedOpportunities[activeOpportunityIndex]?.description && (
                          <OpportunitiesCardHelper />
                        )}
                      </>
                    ) : (
                      <MyCollectionEmptyState
                        initialCategoryOpportunitiesCount={selectedOpportunities?.length || 0}
                        onFiltersReset={() => history.push({ search: null })}
                        onStartExploring={() => {
                          setActiveTab(OpportunitiesTabsMap.EXPLORATION);
                          setActiveOpportunityIndex(0);
                        }}
                        withFilters={!!filtersList.length}
                      />
                    )}
                  </TabPanel>
                </>
              ) : (
                <FiltersEmptyState onFiltersReset={() => history.push({ search: null })} />
              )}
              {((activeTab === OpportunitiesTabsMap.EXPLORATION &&
                !!displayedSuggestedOpportunities?.length) ||
                (activeTab === OpportunitiesTabsMap.MY_COLLECTION &&
                  !!displayedSelectedOpportunities?.length)) && (
                <Box display="flex" justifyContent="center" pb={4} pt={2}>
                  <ThumbsDownIcon
                    aria-label={t('Thumbs Down')}
                    className={classnames('ayo-thumbs-down-btn', {
                      selected:
                        (activeTab === OpportunitiesTabsMap.EXPLORATION &&
                          displayedSuggestedOpportunities[activeOpportunityIndex].interested ===
                            false) ||
                        (activeTab === OpportunitiesTabsMap.MY_COLLECTION &&
                          displayedSelectedOpportunities[activeOpportunityIndex].interested ===
                            false),
                    })}
                    onClick={handleDislikeButtonClick}
                    onKeyDown={(e) => {
                      if (e.key === KeyboardMap.ENTER) {
                        handleDislikeButtonClick();
                      }
                    }}
                    role="button"
                    tabIndex="0"
                  />
                  <ThumbsUpIcon
                    aria-label={t('Thumbs Up')}
                    className={classnames('ayo-thumbs-up-btn', {
                      selected:
                        (activeTab === OpportunitiesTabsMap.EXPLORATION &&
                          displayedSuggestedOpportunities[activeOpportunityIndex].interested) ||
                        (activeTab === OpportunitiesTabsMap.MY_COLLECTION &&
                          displayedSelectedOpportunities[activeOpportunityIndex].interested),
                    })}
                    onClick={handleLikeButtonClick}
                    onKeyDown={(e) => {
                      if (e.key === KeyboardMap.ENTER) {
                        handleLikeButtonClick();
                      }
                    }}
                    role="button"
                    tabIndex="0"
                  />
                </Box>
              )}
            </>
          ) : (
            <NoOpportunitiesEmptyState />
          )}
        </Box>
        {selectedOpportunities?.filter((opportunity) => opportunity.interested).length !== 0 && (
          <GoToStudentProfileBlock />
        )}
      </Container>
    </PageWrapper>
  );
};

export default ExploreOpportunitiesPage;
