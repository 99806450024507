import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ActionsMenu } from '../../../../atoms';
import { ReactComponent as SortIcon } from '../../../../../resources/icons/sorting.svg';
import { PortfolioSortingConfig, PublicationStatuses } from '../../../../../constants/enums';

const PortfolioSortButton = ({ onOptionClick, status, sortingOption }) => {
  const { t } = useTranslation();

  const newestOnTopOpitons = useMemo(
    () =>
      status === PublicationStatuses.PUBLISHED
        ? PortfolioSortingConfig.BY_NEWEST_PUBLISHED_ON_TOP
        : PortfolioSortingConfig.BY_NEWEST_ON_TOP,
    [status],
  );
  const oldestOnTopOpitons = useMemo(
    () =>
      status === PublicationStatuses.PUBLISHED
        ? PortfolioSortingConfig.BY_OLDEST_PUBLISHED_ON_TOP
        : PortfolioSortingConfig.BY_OLDEST_ON_TOP,
    [status],
  );

  return (
    <ActionsMenu
      activatorIcon={<SortIcon />}
      gaLabel="sort by"
      id="sort-by"
      label={t('Sort by')}
      menuItems={[
        {
          selected: sortingOption === PortfolioSortingConfig.BY_RECENTLY_CHANGE,
          text: t('Recently edited'),
          ariaLabel: `${t('Recently edited')}${
            sortingOption === PortfolioSortingConfig.BY_RECENTLY_CHANGE ? `- ${t('selected')}` : ''
          }`,
          handler: () => {
            onOptionClick(PortfolioSortingConfig.BY_RECENTLY_CHANGE);
          },
          id: `${status}-sort-by-recently-changed`,
          gaLabel: 'sort by Recently edited',
        },
        {
          selected: sortingOption === newestOnTopOpitons,
          text: t('Newest on top'),
          ariaLabel: `${t('Newest on top')}${
            sortingOption === newestOnTopOpitons ? `- ${t('selected')}` : ''
          }`,
          handler: () => {
            onOptionClick(newestOnTopOpitons);
          },
          id: `${status}-sort-by-newest-on-top`,
          gaLabel: 'sort by Newest on top',
        },
        {
          selected: sortingOption === oldestOnTopOpitons,
          text: t('Oldest on top'),
          ariaLabel: `${t('Oldest on top')}${
            sortingOption === oldestOnTopOpitons ? `- ${t('selected')}` : ''
          }`,
          handler: () => {
            onOptionClick(oldestOnTopOpitons);
          },
          id: `${status}-sort-by-oldest-on-top`,
          gaLabel: 'Sort by Oldest on top',
        },
      ]}
      noIconsRecolor
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    />
  );
};

PortfolioSortButton.propTypes = {
  onOptionClick: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  sortingOption: PropTypes.instanceOf(Object).isRequired,
};

export default PortfolioSortButton;
