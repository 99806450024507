import React, { useState, useEffect, useCallback, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Grid, useTheme, useMediaQuery } from '@mui/material';

import { LinearProgress, RadioGroup, Typography } from '../../../../../atoms';
import { Question, ScreenCenteredContainer } from '../../../../../moleculas';
import { LeaveDialog } from '../../../../../organisms';
import { useOneTimeActionService, useQuestionnaireService } from '../../../../../../services';
import {
  isAnswersEmpty,
  getFirstUnansweredQuestion,
  hideUnmetConditionsQuestions,
  handleNewAnswersDependencies,
  getTotalQuestionsAmount,
  getPassedQuestionsAmount,
  GA,
  useProfile,
  getQuestionnaireType,
} from '../../../../../../utils';
import {
  AppActions,
  AppContext,
  QuestionnaireStateActions,
  QuestionnaireStateContext,
  UserActions,
  UserContext,
} from '../../../../../../context';
import {
  GaCategories,
  OneTimeActionsMap,
  QuestionnaireStatusMap,
  QuestionnaireNamesByTypesMap,
  RolesMap,
} from '../../../../../../constants/enums';
import { ReactComponent as TeacherIllustration } from '../../../../../../resources/images/teacher_profiling_illustration.svg';
import { ReactComponent as ParentIllustration } from '../../../../../../resources/images/parent_profiling_illustration.svg';
import { ReactComponent as StudentInitialIllustration } from '../../../../../../resources/images/student_initial_profiling_illustration.svg';
import { ReactComponent as StudentProgressiveIllustration } from '../../../../../../resources/images/student_progressive_profiling_illustration.svg';
import SkippedConfirmDialog from '../skipped-confirm-dialog/SkippedConfirmDialog';

const getAnswersMap = (question) =>
  Array.isArray(question?.answers)
    ? question.answers.reduce((acc, answer) => {
        acc[question.options.find((option) => option.id === answer.optionId)?.htmlName] = {
          ...answer,
        };
        return acc;
      }, {})
    : question?.answers || {};

const getSkippedQuestions = (questions) =>
  questions.reduce((obj, question) => {
    if (question.skipped) {
      Object.assign(obj, { [question.id]: true });
    }
    return obj;
  }, {});

const Questionnaire = ({
  onQuestionsEnd,
  onSubmitFailed,
  questionnaireType,
  questions,
  skippedQuestionsIds,
  canSwitchDisplayedQuestions,
}) => {
  const { i18n, t } = useTranslation();
  const { getQuestionsAnswers, postQuestion } = useQuestionnaireService();
  const { state: appState, dispatch: dispatchAppState } = useContext(AppContext);
  const { state: questionnaireState, dispatch: dispatchQuestionnaireState } =
    useContext(QuestionnaireStateContext);
  const { state: userState, dispatch: dispatchUserState } = useContext(UserContext);

  const { getOneTimeActionStatus } = useProfile();
  const { postOneTimeAction } = useOneTimeActionService();

  const profileData = userState.profile;
  const isStudent = userState.profile.role === RolesMap.STUDENT;

  const { resolver } = appState.onLogout;

  const [showAllQuestions, setShowAllQuestions] = useState(canSwitchDisplayedQuestions);

  const { questionnaireId } = questionnaireState;
  const { activeQuestionIndex } = questionnaireState;

  const [modifiedQuestions, setModifiedQuestions] = useState(
    useMemo(() => hideUnmetConditionsQuestions(questions), [questions]),
  );

  const getDisplayedModifiedQuestions = useCallback(
    (paramShowAllQuestions = showAllQuestions) => {
      let newModifiedQuestions;
      if (!paramShowAllQuestions && skippedQuestionsIds) {
        newModifiedQuestions = hideUnmetConditionsQuestions(
          modifiedQuestions.filter((question) => skippedQuestionsIds.includes(question.id)),
        );
      } else {
        newModifiedQuestions = modifiedQuestions;
      }
      return newModifiedQuestions;
    },
    [modifiedQuestions, showAllQuestions, skippedQuestionsIds],
  );

  // Array used to save info about question visibility and saving already entered answers
  const modifiedDisplayedQuestions = useMemo(
    () => getDisplayedModifiedQuestions(),
    [getDisplayedModifiedQuestions],
  );

  const [startTime, setStartTime] = useState();

  const activeQuestion = modifiedDisplayedQuestions[activeQuestionIndex];

  const isLastQuestion = useMemo(
    () =>
      modifiedDisplayedQuestions.slice(activeQuestionIndex).filter((question) => !question.hidden)
        .length === 1,
    [activeQuestionIndex, modifiedDisplayedQuestions],
  );

  const setActiveQuestionIndex = useCallback(
    (index) =>
      dispatchQuestionnaireState({
        type: QuestionnaireStateActions.SET_ACTIVE_QUESTION_INDEX,
        data: {
          activeQuestionIndex: index,
        },
      }),
    [dispatchQuestionnaireState],
  );

  const { activeQuestionAnswers } = questionnaireState;
  const setActiveQuestionAnswers = useCallback(
    (dispatchAnswers) =>
      dispatchQuestionnaireState({
        type: QuestionnaireStateActions.SET_ACTIVE_QUESTION_ANSWERS,
        data: {
          activeQuestionAnswers: dispatchAnswers,
        },
      }),
    [dispatchQuestionnaireState],
  );

  const [skippedQuestions, setSkippedQuestions] = useState({});

  const [isSkippedDialogOpen, setIsSkippedDialogOpen] = useState(false);

  const [previousYearQuestionnaire, setPreviousYearQuestionnaire] = useState();

  const setIsDirty = useCallback(
    (value) => {
      dispatchAppState({ type: AppActions.SET_IS_DIRTY, data: value });
    },
    [dispatchAppState],
  );

  useEffect(() => {
    if (activeQuestionIndex === null) {
      setActiveQuestionIndex(
        skippedQuestionsIds ? 0 : getFirstUnansweredQuestion(modifiedDisplayedQuestions),
      );
    } else {
      // Set previously set answers for current question if changing lng
      const questionsCopy = [...modifiedQuestions];
      questionsCopy[activeQuestionIndex].answers = activeQuestionAnswers;
      setModifiedQuestions(questionsCopy);
    }
    setStartTime(Date.now());
    if (questionnaireState.activeDependentProfile) {
      getQuestionsAnswers(i18n.language, questionnaireState.activeDependentProfile.id).then(
        (response) => {
          const previousYearQuestionnaires = response.guardians[0]?.questionnaires.filter(
            (x) =>
              x.state === QuestionnaireStatusMap.CLOSE &&
              getQuestionnaireType(x.title) === questionnaireType,
          );
          if (previousYearQuestionnaires.length) {
            setPreviousYearQuestionnaire(previousYearQuestionnaires[0]);
          }
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (questionnaireType) {
      const relatedOneTimeActions =
        questionnaireType === QuestionnaireNamesByTypesMap.PROGRESSIVE
          ? OneTimeActionsMap.PROGRESSIVE_PROFILING_OPEN
          : OneTimeActionsMap.TRAIN_AYO_START;
      if (!getOneTimeActionStatus(relatedOneTimeActions)) {
        postOneTimeAction(relatedOneTimeActions);
        dispatchUserState({
          type: UserActions.SET_ONE_TIME_ACTION,
          data: relatedOneTimeActions,
        });
      }
    }
  }, [dispatchUserState, getOneTimeActionStatus, postOneTimeAction, questionnaireType]);

  const getNextAvailableQuestionIndex = useCallback(() => {
    let nextQuestionIndex = activeQuestionIndex + 1;
    while (modifiedDisplayedQuestions[nextQuestionIndex]?.hidden) {
      nextQuestionIndex += 1;
    }
    return nextQuestionIndex;
  }, [activeQuestionIndex, modifiedDisplayedQuestions]);

  const getPreviousAvailableQuestionIndex = useCallback(() => {
    let previousQuestionIndex = activeQuestionIndex - 1;
    let previousQuestion = modifiedDisplayedQuestions[previousQuestionIndex];
    while (previousQuestion?.hidden || (previousQuestion && !previousQuestion.editable)) {
      previousQuestionIndex -= 1;
      previousQuestion = modifiedDisplayedQuestions[previousQuestionIndex];
    }
    return previousQuestionIndex;
  }, [activeQuestionIndex, modifiedDisplayedQuestions]);

  const previousAvailableQuestionIndex = useMemo(
    () => getPreviousAvailableQuestionIndex(),
    [getPreviousAvailableQuestionIndex],
  );

  const goToPreviousQuestion = useCallback(() => {
    setActiveQuestionIndex(previousAvailableQuestionIndex);
  }, [previousAvailableQuestionIndex, setActiveQuestionIndex]);

  const goToNextQuestion = useCallback(() => {
    const nextAvailableQuestionIndex = getNextAvailableQuestionIndex();
    if (!modifiedDisplayedQuestions[nextAvailableQuestionIndex]) {
      onQuestionsEnd(true, true);
    } else {
      setActiveQuestionIndex(nextAvailableQuestionIndex);
      dispatchAppState({
        type: AppActions.SET_SNACKBAR_STATUS,
        data: {
          text: t('Your answer has been successfully updated in your kid’s profile'),
          type: 'success',
        },
      });
    }
    dispatchQuestionnaireState({
      type: QuestionnaireStateActions.SET_SHOULD_RELOAD_QUESTIONNAIRE_STATE,
      data: true,
    });
  }, [
    dispatchAppState,
    dispatchQuestionnaireState,
    getNextAvailableQuestionIndex,
    modifiedDisplayedQuestions,
    onQuestionsEnd,
    setActiveQuestionIndex,
    t,
  ]);

  const handleAnswerChange = useCallback(
    (e, customOptions) => {
      let newAnswers = {};
      if (e && (e.target.checked === false || !e.target.value)) {
        const answersCopy = { ...activeQuestionAnswers };
        delete answersCopy[e.target.name];

        const dependentOptions = activeQuestion.options.filter(
          (option) => option.activatorOptionId === +e.target.dataset.optionid,
        );
        dependentOptions.forEach((option) => delete answersCopy[option.htmlName]);

        newAnswers = answersCopy;
        setIsDirty(false);
      } else {
        newAnswers = customOptions?.overrideAnswers ?? {
          ...activeQuestionAnswers,
          [e.target.name]: {
            value: e.target.value,
            optionId: +e.target.dataset.optionid,
          },
        };
        if (!appState.isDirty) {
          setIsDirty(true);
        }
      }
      setActiveQuestionAnswers(newAnswers);
      const questionsCopy = [...modifiedQuestions];
      const questionIndex = modifiedQuestions.findIndex(
        (question) => question.id === modifiedDisplayedQuestions[activeQuestionIndex].id,
      );
      questionsCopy[questionIndex].answers = newAnswers;
      setModifiedQuestions(
        handleNewAnswersDependencies(activeQuestion.dependentQuestions, newAnswers, questionsCopy),
      );
    },
    [
      appState.isDirty,
      setActiveQuestionAnswers,
      setIsDirty,
      modifiedDisplayedQuestions,
      modifiedQuestions,
      activeQuestionIndex,
      activeQuestionAnswers,
      activeQuestion,
    ],
  );

  const submitAndProceedToNext = useCallback(() => {
    GA.logTimeElapsed(startTime, {
      category: GaCategories.BEHAVIOR,
      variable: 'Time Spent',
      label: `Question: ${activeQuestion.htmlLabel}`,
    });

    const onNextFailed = () =>
      dispatchAppState({
        type: AppActions.SET_SNACKBAR_STATUS,
        data: {
          text: t('Failed to update your answer in your kid’s profile Please try once more'),
          type: 'error',
        },
      });

    const afterAnswerRequested = () => {
      setModifiedQuestions(
        handleNewAnswersDependencies(
          activeQuestion.dependentQuestions,
          activeQuestionAnswers,
          modifiedQuestions,
        ),
      );
      setStartTime(Date.now());
      return isLastQuestion
        ? onQuestionsEnd(modifiedQuestions, questionnaireState.activeDependentProfile?.firstName)
        : goToNextQuestion();
    };

    const answerRequestBody = {
      questionId: activeQuestion.id,
      personId: questionnaireState.activeDependentProfile?.id,
      skipped: isAnswersEmpty(activeQuestionAnswers),
      answers: isAnswersEmpty(activeQuestionAnswers)
        ? []
        : [...Object.values(activeQuestionAnswers)],
    };

    if (answerRequestBody.skipped) {
      setSkippedQuestions((state) => ({ ...state, [activeQuestion.id]: true }));
    } else if (skippedQuestions[activeQuestion.id]) {
      delete skippedQuestions[activeQuestion.id];
    }

    if (
      isLastQuestion &&
      !isSkippedDialogOpen &&
      (Object.keys(skippedQuestions).length > 0 || answerRequestBody.skipped)
    ) {
      setIsSkippedDialogOpen(true);
    } else {
      postQuestion(questionnaireId, answerRequestBody)
        .then(afterAnswerRequested)
        .catch(() =>
          isLastQuestion
            ? onSubmitFailed(
                questionnaireId,
                answerRequestBody,
                modifiedQuestions,
                questionnaireState.activeDependentProfile?.firstName,
              )
            : onNextFailed(),
        )
        .finally(setIsDirty(false));
    }
  }, [
    activeQuestion,
    activeQuestionAnswers,
    dispatchAppState,
    goToNextQuestion,
    isLastQuestion,
    isSkippedDialogOpen,
    modifiedQuestions,
    onQuestionsEnd,
    onSubmitFailed,
    postQuestion,
    questionnaireId,
    questionnaireState.activeDependentProfile?.firstName,
    questionnaireState.activeDependentProfile?.id,
    setIsDirty,
    skippedQuestions,
    startTime,
    t,
  ]);

  const onLeaveDialogHandler = useCallback(() => {
    if (resolver) {
      resolver(false);
    }
  }, [resolver]);

  // Update answer when changing between questions
  useEffect(() => {
    setActiveQuestionAnswers(getAnswersMap(activeQuestion));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeQuestionIndex]);

  useEffect(() => {
    setSkippedQuestions(getSkippedQuestions(modifiedDisplayedQuestions));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const totalQuestionsAmount = useMemo(
    () => getTotalQuestionsAmount(modifiedDisplayedQuestions),
    [modifiedDisplayedQuestions],
  );

  const passedQuestionsAmount = useMemo(
    () => getPassedQuestionsAmount(modifiedDisplayedQuestions, activeQuestionIndex),
    [modifiedDisplayedQuestions, activeQuestionIndex],
  );

  const hasLabeledQuestions = useMemo(
    () => modifiedDisplayedQuestions?.some((question) => question.htmlDescription),
    [modifiedDisplayedQuestions],
  );

  const extendedLayoutIllustrationMap = useMemo(
    () => ({
      [RolesMap.TEACHER]: {
        Illustration: TeacherIllustration,
        label: t('A teacher’s table with a plant pot, globe, apple, pencil and a pile of books'),
      },
      [RolesMap.GUARDIAN]: {
        Illustration: ParentIllustration,
        label: t(
          'A kids room with a violet chair, table with a plant pot, joystick, pencil and a sound speaker',
        ),
      },
      [RolesMap.STUDENT]: {
        [QuestionnaireNamesByTypesMap.INITIAL]: {
          Illustration: StudentInitialIllustration,
          label: t('A telescope in the countryside'),
        },
        [QuestionnaireNamesByTypesMap.PROGRESSIVE]: {
          Illustration: StudentProgressiveIllustration,
          label: t(
            'A portrait of Mona Lisa, popcorn, ballet shoes, camping tent with fire, air balloon and notes on the shelf indicating students’ potential interests',
          ),
        },
      },
    }),
    [t],
  );

  const QuestionnaireIllustration = useMemo(
    () =>
      isStudent
        ? extendedLayoutIllustrationMap[profileData?.role][questionnaireType]
        : extendedLayoutIllustrationMap[profileData?.role],
    [extendedLayoutIllustrationMap, isStudent, profileData?.role, questionnaireType],
  );

  const theme = useTheme();
  const isWidthUpLg = useMediaQuery(theme.breakpoints.up('lg'));

  return activeQuestion ? (
    <>
      <ScreenCenteredContainer>
        <Grid container justifyContent={isWidthUpLg ? 'space-between' : 'center'}>
          {canSwitchDisplayedQuestions && !!skippedQuestionsIds?.length && (
            <Grid
              container
              item
              justifyContent={isWidthUpLg ? 'space-between' : 'center'}
              mb={5}
              xs={12}
            >
              <Grid item md={6} xs={12}>
                <Box bgcolor="white" borderRadius={2} display="flex" gap={3} p={3}>
                  <Typography component="h1" variant="subtitle2">
                    {t('Show questions')}
                  </Typography>
                  <RadioGroup
                    onChange={(e) => {
                      const newShowAllQuestionsValue = e.target.value === 'ALL';
                      setShowAllQuestions(newShowAllQuestionsValue);
                      setActiveQuestionIndex(0);
                      const newDisplayedQuestions =
                        getDisplayedModifiedQuestions(newShowAllQuestionsValue);
                      setActiveQuestionAnswers(getAnswersMap(newDisplayedQuestions[0]));
                    }}
                    options={[
                      { text: t('All (questions)'), value: 'ALL' },
                      { text: t('Skipped only'), value: 'SKIPPED' },
                    ]}
                    row
                    value={showAllQuestions ? 'ALL' : 'SKIPPED'}
                  />
                </Box>
              </Grid>
            </Grid>
          )}
          <Grid item md={6} xs={12}>
            <Question
              key={activeQuestion.id}
              answers={activeQuestionAnswers}
              dependentProfile={questionnaireState.activeDependentProfile}
              hasLabel={hasLabeledQuestions}
              isLast={isLastQuestion}
              onNext={submitAndProceedToNext}
              onPrevious={previousAvailableQuestionIndex < 0 ? null : goToPreviousQuestion}
              previousYearAnswers={{
                startTime: previousYearQuestionnaire?.startTime,
                question: previousYearQuestionnaire?.answers?.find(
                  (x) => x.questionName === activeQuestion.htmlLabel,
                ),
              }}
              question={activeQuestion}
              setAnswer={handleAnswerChange}
            />
          </Grid>
          {isWidthUpLg && QuestionnaireIllustration && (
            <Grid container item justifyContent="center" md={5}>
              <Grid className="ayo-questionnaire-page__questionnaire-illustration" item>
                <QuestionnaireIllustration.Illustration
                  aria-label={QuestionnaireIllustration.label}
                  className="ayo-svg-illustration"
                  role="img"
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </ScreenCenteredContainer>
      <SkippedConfirmDialog
        isOpen={isSkippedDialogOpen}
        onConfirm={submitAndProceedToNext}
        setIsOpen={setIsSkippedDialogOpen}
      />
      <LeaveDialog
        onSecondaryClick={onLeaveDialogHandler}
        primaryButtonTitle="Yes, I want to leave"
        secondaryButtonTitle="I want to stay"
        shouldResetNextLocationRef
        text="If you leave AYO will still save your progress"
        title="Are you sure you want to leave?"
      />
      {!activeQuestion.dependentQuestions && (
        <Box bottom="0" position="absolute" width="100%">
          <LinearProgress value={(passedQuestionsAmount / totalQuestionsAmount) * 100} />
        </Box>
      )}
    </>
  ) : null;
};

Questionnaire.propTypes = {
  onQuestionsEnd: PropTypes.func.isRequired,
  onSubmitFailed: PropTypes.func.isRequired,
  questionnaireType: PropTypes.string.isRequired,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      editable: PropTypes.bool,
      htmlDescription: PropTypes.string,
      htmlLabel: PropTypes.string,
      options: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
      text: PropTypes.string,
      id: PropTypes.number,
      hidden: PropTypes.boolean,
      answers: PropTypes.arrayOf(
        PropTypes.shape({
          optionId: PropTypes.number,
          value: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
  canSwitchDisplayedQuestions: PropTypes.bool,
  skippedQuestionsIds: PropTypes.arrayOf(PropTypes.number),
};

Questionnaire.defaultProps = {
  canSwitchDisplayedQuestions: false,
  skippedQuestionsIds: null,
};

export default Questionnaire;
