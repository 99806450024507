import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '../../../../../atoms';

const DisplayAssignmentInstruction = ({ instruction }) => (
  <Typography variant="body2">{instruction}</Typography>
);

DisplayAssignmentInstruction.propTypes = {
  instruction: PropTypes.string,
};

DisplayAssignmentInstruction.defaultProps = {
  instruction: null,
};

export default DisplayAssignmentInstruction;
