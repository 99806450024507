import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Box, Container, Grid, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { ActionsMenu, Button, Tooltip, Typography } from '../../../atoms';
import { BackNavigationLink, TextWithTooltip } from '../../../moleculas';
import { MobileNotSupported, PublicationStatusBadge, TopBar } from '../../../organisms';
import { useLessonPlannerData, useSearchParams } from '../../../../hooks';
import { UserContext } from '../../../../context';
import { RolesMap } from '../../../../constants/enums';
import { lessonTitlePlaceholder } from '../../../../constants/lesson-planner';
import {
  getFormattedDate,
  getFullName,
  isCurrentYear,
  transformSearchToObject,
} from '../../../../utils';
import { ReactComponent as CreateIcon } from '../../../../resources/icons/create.svg';
import { ReactComponent as LockWithKeyhole } from '../../../../resources/icons/lock_with_keyhole.svg';
import { ReactComponent as MoreHorizIcon } from '../../../../resources/icons/more_horiz.svg';
import ManageAccessDialog from '../components/manage-access-dialog/ManageAccessDialog';
import SharedLessonBadge from '../components/shared-lesson-badge/SharedLessonBadge';

import LessonPageStudent from './lesson-pages-by-role/LessonPageStudent';
import LessonPageTeacher from './lesson-pages-by-role/LessonPageTeacher';
import LessonPageComments from './components/lesson-page-comments/LessonPageComments';

export const LessonHeader = ({
  backToLink,
  isEditable,
  isOwner,
  isPublished,
  isStudent,
  lessonData,
  onEditClick,
  redirectTo,
}) => {
  const { i18n, t } = useTranslation();

  const { shareLesson } = useLessonPlannerData();

  const [isManageAccessDialogOpen, setIsManageAccessDialogOpen] = useState(false);

  const {
    params: { lessonId },
  } = useRouteMatch();

  const backToText = t(
    isStudent ? 'Back to My classes' : redirectTo ? 'Back to My calendar' : 'Back to My lessons',
  );

  const isStaticHeader = useMemo(
    () => (!isOwner && isPublished) || (isOwner && !isEditable) || isStudent,
    [isEditable, isOwner, isPublished, isStudent],
  );

  const formattedDate = useMemo(() => {
    const date = dayjs(lessonData?.classMetadata.classDate);
    const formatted = getFormattedDate(date.toDate(), i18n.language, {
      day: 'numeric',
      weekday: 'short',
      month: 'long',
    });

    return isCurrentYear(date) ? formatted : `${formatted}, ${date.get('year')}`;
  }, [i18n.language, lessonData?.classMetadata.classDate]);

  const handleAccessChange = useCallback(
    (teachersWithAccess, id) => {
      shareLesson(id, teachersWithAccess);
    },
    [shareLesson],
  );

  const actions = useMemo(
    () =>
      isEditable ? (
        <Button
          endIcon={<LockWithKeyhole />}
          gaLabel="Manage access"
          onClick={() => {
            setIsManageAccessDialogOpen(true);
          }}
        >
          {t('Manage access')}
        </Button>
      ) : (
        <ActionsMenu
          ActivatorComponent={Button}
          activatorIcon={<MoreHorizIcon />}
          activatorProps={{
            label: 'Actions',
          }}
          activatorVariant="text"
          id="lesson-repository-actions"
          label="Actions"
          menuItems={[
            {
              text: t('Edit'),
              icon: CreateIcon,
              handler: onEditClick,
              id: 'edit lesson',
              gaLabel: 'Edit lesson',
            },
            {
              text: t('Manage access'),
              icon: LockWithKeyhole,
              handler: () => setIsManageAccessDialogOpen(true),
              id: 'manage access',
              gaLabel: 'Manage access',
            },
          ]}
        />
      ),
    [isEditable, onEditClick, t],
  );

  const sharedWithList = useMemo(
    () => lessonData?.sharedWith?.map((item) => getFullName(item)),
    [lessonData?.sharedWith],
  );

  return (
    <Box
      className={classNames('ayo-lesson-page__header', {
        'ayo-lesson-page__header--static': isStaticHeader,
      })}
      pb={4}
      pt={isStaticHeader ? 0 : 2}
    >
      <Box>
        {!isStaticHeader ? (
          <Container>
            <BackNavigationLink backToLink={backToLink} text={backToText} />
          </Container>
        ) : (
          <TopBar backToLink={backToLink} backToText={backToText} noNav />
        )}
      </Box>
      {!!lessonData && (
        <Container>
          <Grid
            alignItems="center"
            container
            flexDirection="row"
            item
            justifyContent="space-between"
            pb={2}
            wrap="nowrap"
            xs={12}
          >
            <Box alignItems="center" display="flex">
              <TextWithTooltip
                headerLevel={1}
                rowsCount={2}
                title={
                  isStaticHeader || !isOwner
                    ? lessonData.title
                    : t(isPublished ? 'Editing lesson' : lessonTitlePlaceholder)
                }
                titleVariant="h1"
              />
              {!isStudent && !!lessonData.status && (
                <PublicationStatusBadge variant={lessonData.status} />
              )}
              {isOwner && !!sharedWithList?.length && (
                <Tooltip title={`${t('The lesson is shared with')} ${sharedWithList.join(', ')}.`}>
                  <Box ml={4}>
                    <SharedLessonBadge
                      ariaLabel={`${t('The lesson is shared with')} ${sharedWithList.join(', ')}.`}
                      count={sharedWithList.length}
                    />
                  </Box>
                </Tooltip>
              )}
            </Box>
            {!isStudent && (
              <Box ml={3}>
                <LessonPageComments isOwner={isOwner} lessonId={lessonId} />
              </Box>
            )}
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="space-between">
              <Box columnGap={8} display="flex" justifyContent="flex-start">
                <Box>
                  <Typography
                    className="ayo-lesson-page__header__label"
                    component="h4"
                    variant="body2"
                  >
                    {t('Period')}
                  </Typography>
                  <Typography component="p" variant="subtitle2">
                    {lessonData.classMetadata.classesPeriod.periodName}
                  </Typography>
                </Box>
                <Box maxWidth="30%">
                  <Typography
                    className="ayo-lesson-page__header__label"
                    component="h4"
                    variant="body2"
                  >
                    {t('Sections')}
                  </Typography>
                  <Typography component="p" variant="subtitle2">
                    {lessonData.classMetadata.classesPeriod.courses
                      .map(({ className }) => className)
                      .join(', ')}
                  </Typography>
                </Box>
                {!isOwner && !isStudent && (
                  <Box>
                    <Typography
                      className="ayo-lesson-page__header__label"
                      component="h4"
                      variant="body2"
                    >
                      {t('Educator')}
                    </Typography>
                    <Typography component="p" variant="subtitle2">
                      {getFullName(lessonData.owner)}
                    </Typography>
                  </Box>
                )}
                <Box>
                  <Typography
                    className="ayo-lesson-page__header__label"
                    component="h4"
                    variant="body2"
                  >
                    {t(isOwner ? 'Scheduled for' : 'Lesson date')}
                  </Typography>
                  <Box alignItems="center" display="flex">
                    <Typography component="p" variant="subtitle2">
                      {formattedDate}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              {isOwner && !!lessonId && !!lessonData && (
                <>
                  {actions}
                  <ManageAccessDialog
                    isOpen={isManageAccessDialogOpen}
                    lessonData={lessonData}
                    onClose={() => setIsManageAccessDialogOpen(false)}
                    onSave={(teachersWithAccess) =>
                      handleAccessChange(teachersWithAccess, lessonId)
                    }
                  />
                </>
              )}
            </Box>
          </Grid>
        </Container>
      )}
    </Box>
  );
};

LessonHeader.propTypes = {
  backToLink: PropTypes.string.isRequired,
  isEditable: PropTypes.bool,
  isOwner: PropTypes.bool,
  isPublished: PropTypes.bool,
  isStudent: PropTypes.bool,
  lessonData: PropTypes.instanceOf(Object).isRequired,
  onEditClick: PropTypes.func,
  redirectTo: PropTypes.string,
};

LessonHeader.defaultProps = {
  isEditable: false,
  isOwner: false,
  isPublished: false,
  isStudent: false,
  onEditClick: null,
  redirectTo: null,
};

const LessonPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { state: userState } = useContext(UserContext);

  const isStudent = useMemo(
    () => userState.profile?.role === RolesMap.STUDENT,
    [userState.profile?.role],
  );

  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });

  const search = useSearchParams();
  const { redirectTo } = useMemo(() => transformSearchToObject(search), [search]);
  const redirectRef = useRef();

  useEffect(() => {
    if (redirectTo) {
      redirectRef.current = redirectTo;
      history.replace({ search: null });
    }
  });

  return (
    <>
      {isWidthUpSm ? (
        isStudent ? (
          <LessonPageStudent Header={LessonHeader} redirectTo={redirectRef.current} />
        ) : (
          <LessonPageTeacher Header={LessonHeader} redirectTo={redirectRef.current} />
        )
      ) : (
        <MobileNotSupported
          explanation={t(
            'Please open My lessons section with a tablet or desktop version to use this functionality',
          )}
        />
      )}
    </>
  );
};

export default LessonPage;
