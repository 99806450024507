import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { PageWrapper } from '../../../organisms';
import { useClassLinkTokenService } from '../../../../services';
import { rootRoute } from '../../../../constants/routes';
import { useProfile } from '../../../../utils';

const ClasslinkLoginPage = () => {
  const history = useHistory();
  const { postToken } = useClassLinkTokenService();
  const { loadProfileAndProceed } = useProfile();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    if (code) {
      postToken(code).then(() => {
        loadProfileAndProceed({
          shouldProceed: true,
          shouldReplaceHistory: true,
        });
      });
    } else {
      history.replace(rootRoute);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <PageWrapper />;
};

export default ClasslinkLoginPage;
