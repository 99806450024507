import React from 'react';
import PropTypes from 'prop-types';

import interestReflection from '../../../resources/icons/interest_reflection.svg';

const CommentPositions = {
  TOP_RIGHT: 'top-right',
  OUTSIDE_RIGHT: 'outside-right',
};

const getCommentPositionX = (circleX, circleR, commentSize, commentPosition) => {
  switch (commentPosition) {
    case CommentPositions.TOP_RIGHT:
      return circleX + circleR * Math.cos(Math.PI / 4) - commentSize / 2;
    case CommentPositions.OUTSIDE_RIGHT:
      return circleX + circleR;
    default:
      return circleX + circleR * Math.cos(Math.PI / 4) - commentSize / 2;
  }
};

const getCommentPositionY = (circleY, circleR, commentSize, commentPosition) => {
  switch (commentPosition) {
    case CommentPositions.TOP_RIGHT:
      return circleY - circleR * Math.sin(Math.PI / 4) - commentSize / 2;
    case CommentPositions.OUTSIDE_RIGHT:
      return circleY - circleR;
    default:
      return circleY - circleR * Math.sin(Math.PI / 4) - commentSize / 2;
  }
};

const BubbleCircles = ({
  circle,
  buffRadiusIncrease,
  buffStrokeWidth,
  withComment,
  commentPosition,
  commentSize,
}) => {
  const maximumBubbleRadius =
    circle.r + buffRadiusIncrease * Math.max((circle.reaction ?? 1) - 1, 0);
  return (
    <>
      <circle
        className="inner-circle"
        cx={circle.x}
        cy={circle.y}
        fill={circle.color}
        r={circle.r}
      />
      {circle.reaction > 1 && (
        <>
          <circle
            cx={circle.x}
            cy={circle.y}
            fill="none"
            r={circle.r + buffRadiusIncrease}
            stroke="url(#primary_gradient_0)"
            strokeWidth={buffStrokeWidth}
          />
          <circle
            className="outer-main-circle"
            cx={circle.x}
            cy={circle.y}
            fill="none"
            r={circle.r + buffRadiusIncrease}
            stroke="url(#primary_gradient_1)"
            strokeWidth={buffStrokeWidth}
          />
          <circle
            cx={circle.x - circle.r - buffRadiusIncrease}
            cy={circle.y}
            fill="url(#secondary_gradient)"
            r={buffStrokeWidth * 1.5}
          />
        </>
      )}
      {circle.reaction > 2 && (
        <>
          <circle
            cx={circle.x}
            cy={circle.y}
            fill="none"
            r={circle.r + buffRadiusIncrease * 2}
            stroke="url(#primary_gradient_0)"
            strokeWidth={buffStrokeWidth * 2}
          />
          <circle
            className="outer-main-circle"
            cx={circle.x}
            cy={circle.y}
            fill="none"
            r={circle.r + buffRadiusIncrease * 2}
            stroke="url(#primary_gradient_1)"
            strokeWidth={buffStrokeWidth * 2}
          />
          <circle
            cx={circle.x - (circle.r + buffRadiusIncrease * 2) * Math.cos(Math.PI / 4)}
            cy={circle.y - (circle.r + buffRadiusIncrease * 2) * Math.sin(Math.PI / 4)}
            fill="#B0D475"
            r={buffStrokeWidth * 2}
          />
        </>
      )}
      {withComment && (
        <image
          height={commentSize}
          href={interestReflection}
          width={commentSize}
          x={getCommentPositionX(circle.x, maximumBubbleRadius, commentSize, commentPosition)}
          y={getCommentPositionY(circle.y, maximumBubbleRadius, commentSize, commentPosition)}
        />
      )}
    </>
  );
};

BubbleCircles.propTypes = {
  circle: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
    r: PropTypes.number,
    reaction: PropTypes.number,
    color: PropTypes.string,
  }).isRequired,
  buffRadiusIncrease: PropTypes.number.isRequired,
  buffStrokeWidth: PropTypes.number.isRequired,
  withComment: PropTypes.bool,
  commentPosition: PropTypes.oneOf(Object.values(CommentPositions)),
  commentSize: PropTypes.number,
};

BubbleCircles.defaultProps = {
  withComment: false,
  commentPosition: CommentPositions.TOP_RIGHT,
  commentSize: 24,
};

export default BubbleCircles;
