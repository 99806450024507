import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Tab } from '@mui/material';
import PropTypes from 'prop-types';

import { TabPanel, Tabs, Typography } from '../../../../../../../atoms';
import { DaySchedule } from '../../../../../../../moleculas';
import { workingDaysConfig } from '../../../../../../../../constants/configs';
import { ReactComponent as DayOffImage } from '../../../../../../../../resources/images/day_off_image.svg';
import { useLessonPlannerData } from '../../../../../../../../hooks';
import { Semesters } from '../../../../../../../../constants/enums';

const RotationWeek = ({ weekSchedule, weekIndex, semester, schoolYear }) => {
  const { t } = useTranslation();
  const { setRotationWeeksDaySchedule } = useLessonPlannerData();
  return (
    <Box className="ayo-rotation-weeks-schedule__week-block">
      {workingDaysConfig.map(({ value }) => {
        const daySchedule = weekSchedule.daysSchedule?.find(({ day }) => day === value);
        const dayScheduleIndex = weekSchedule.daysSchedule?.findIndex(({ day }) => day === value);
        return (
          <Box key={value} height="100%">
            <Typography variant="subtitle2">{t(`weekdays.${value}`)}</Typography>
            <Box height="100%" mt={2} position="relative">
              {daySchedule ? (
                <DaySchedule
                  dayIndex={dayScheduleIndex}
                  onChange={setRotationWeeksDaySchedule}
                  periods={daySchedule.coursesPeriods}
                  schoolYear={schoolYear}
                  semester={semester}
                  weekIndex={weekIndex}
                />
              ) : (
                <Box className="ayo-rotation-weeks-schedule__week-block__empty" px={3}>
                  <Box my={10}>
                    <Typography variant="subtitle2">
                      {t('No planning for this day. Enjoy your day off!')}
                    </Typography>
                    <Box mt={3}>
                      <DayOffImage />
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

RotationWeek.propTypes = {
  weekIndex: PropTypes.bool.isRequired,
  weekSchedule: PropTypes.shape({
    rotationWeekNumber: PropTypes.number,
    daysSchedule: PropTypes.arrayOf(
      PropTypes.shape({
        day: PropTypes.string,
        courses: PropTypes.arrayOf(
          PropTypes.shape({
            courseName: PropTypes.string,
            orderNumber: PropTypes.number,
          }),
        ),
      }),
    ),
    workingDays: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  semester: PropTypes.oneOf(Object.values(Semesters)).isRequired,
  schoolYear: PropTypes.number.isRequired,
};

const RotationWeeksSchedule = ({ semester, schoolYear }) => {
  const { t } = useTranslation();
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const { lessonPlannerData } = useLessonPlannerData();

  const { rotationWeeksSchedule } = lessonPlannerData.stateData.semestersSchedule.find(
    (stateSemester) => stateSemester.semester === semester,
  );

  const handleTabClick = useCallback((e, value) => setActiveTabIndex(value), []);

  return (
    <Box className="ayo-rotation-weeks-schedule">
      {rotationWeeksSchedule.length > 1 ? (
        <Box>
          <Tabs onChange={handleTabClick} value={activeTabIndex} variant="standard">
            {rotationWeeksSchedule.map((rotationWeek, rotationWeekIndex) => (
              <Tab
                key={rotationWeek.rotationWeekNumber}
                disableRipple
                label={`${t('Week')} ${rotationWeek.rotationWeekNumber}`}
                value={rotationWeekIndex}
              />
            ))}
          </Tabs>
          <Box mt={3}>
            {rotationWeeksSchedule.map((rotationWeek, rotationWeekIndex) => (
              <TabPanel
                key={rotationWeek.rotationWeekNumber}
                currentTabValue={activeTabIndex}
                index={rotationWeekIndex}
                value={rotationWeekIndex}
              >
                <RotationWeek
                  schoolYear={schoolYear}
                  semester={semester}
                  weekIndex={rotationWeekIndex}
                  weekSchedule={rotationWeek}
                />
              </TabPanel>
            ))}
          </Box>
        </Box>
      ) : (
        rotationWeeksSchedule.map((rotationWeek, rotationWeekIndex) => (
          <Box key={rotationWeek.rotationWeekNumber}>
            <RotationWeek
              semester={semester}
              weekIndex={rotationWeekIndex}
              weekSchedule={rotationWeek}
            />
          </Box>
        ))
      )}
    </Box>
  );
};

RotationWeeksSchedule.propTypes = {
  semester: PropTypes.oneOf(Object.values(Semesters)).isRequired,
  schoolYear: PropTypes.number.isRequired,
};

export default RotationWeeksSchedule;
