import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { FixedSizeGrid } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import PropTypes from 'prop-types';

import { Checkbox, FormControlLabel, Link, Typography } from '../../../atoms';
import {
  SearchNoResults,
  InitiativeEmptyStateBlock,
  SearchField,
  TextWithTooltip,
} from '../../../moleculas';
import { useFamilyFeed } from '../../../../hooks';
import { SharingLevels } from '../../../../constants/family-feed';
import { loginAllowedFilterRE } from '../../../../constants/regexps';
import { ReactComponent as LinkIcon } from '../../../../resources/icons/link.svg';
import { ReactComponent as PersonalizedLevelEmptyState } from '../../../../resources/images/family_feed_selected_entities_empty_state.svg';
import BlockHeader from '../block-header/BlockHeader';
import SelectableEntities from '../selectable-entities/SelectableEntities';

const PersonalizedEntityComponent = ({ id, name, isSelected, onChange }) => {
  const { getStudentRedirectLink } = useFamilyFeed();

  return (
    <FormControlLabel
      className="share-with-item"
      control={
        <Checkbox checked={isSelected} gaLabel="Share with entity" onChange={onChange} value={id} />
      }
      label={
        <Link
          component={RouterLink}
          gaLabel="Student profile"
          icon={<LinkIcon />}
          target="_blank"
          to={getStudentRedirectLink(id)}
          underline="none"
        >
          <TextWithTooltip
            className="personalized-block__student-label"
            title={name}
            titleVariant="subtitle2"
          />
        </Link>
      }
    />
  );
};

PersonalizedEntityComponent.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

const DEFAULT_HEIGHT = 50;
const DEFAULT_MARGIN = 8;

const ListOfPersonalizedEntities = ({ columnsCount, entities, selected, onChange }) => {
  const theme = useTheme();
  const isWidthUpMd = useMediaQuery(theme.breakpoints.up('md'));

  const columnCount = isWidthUpMd ? columnsCount : 1;
  const rowCount = Math.ceil(entities.length / columnCount);

  const getItemIndex = useCallback(
    (rowIndex, columnIndex) => rowIndex * columnCount + columnIndex,
    [columnCount],
  );

  return (
    <Box className="personalized-block__content" minHeight={100}>
      <AutoSizer>
        {({ height, width }) => {
          const actualWidth = width - 8;
          return (
            <FixedSizeGrid
              columnCount={columnCount}
              columnWidth={isWidthUpMd ? actualWidth / columnsCount : actualWidth}
              height={height}
              rowCount={rowCount}
              rowHeight={DEFAULT_HEIGHT}
              width={width}
            >
              {({ columnIndex, rowIndex, style }) => {
                const index = getItemIndex(rowIndex, columnIndex);
                if (index >= entities.length) return null;
                const { id, name } = entities[index];

                return (
                  <div
                    key={`${name} - ${id}`}
                    style={{
                      ...style,
                      right: style.right + DEFAULT_MARGIN,
                      top: style.top + DEFAULT_MARGIN,
                      width: style.width - DEFAULT_MARGIN,
                      height: style.height - DEFAULT_MARGIN,
                    }}
                  >
                    <PersonalizedEntityComponent
                      id={id}
                      isSelected={!!selected.find((x) => x.id === id && x.name === name)}
                      name={name}
                      onChange={(e) => onChange(e, { id, name })}
                    />
                  </div>
                );
              }}
            </FixedSizeGrid>
          );
        }}
      </AutoSizer>
    </Box>
  );
};

ListOfPersonalizedEntities.propTypes = {
  columnsCount: PropTypes.number.isRequired,
  entities: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      label: PropTypes.string,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    }),
  ).isRequired,
};

const FULL_HEIGHT_NUMBER_OF_ENTITIES = 20;

const PersonalizedBlock = ({ columnsCount, students, onChange, outlinedSearch, selected }) => {
  const { t } = useTranslation();

  const { formatEntities } = useFamilyFeed();

  const [searchValue, setSearchValue] = useState(null);
  const [searchedStudents, setSearchedStudents] = useState(null);

  const searchFieldRef = useRef();

  const selectableEntities = useMemo(
    () =>
      students
        ? formatEntities(students, {
            id: 'id',
            name: 'name',
            login: 'login',
          })
        : [],
    [formatEntities, students],
  );

  const handleSearch = useCallback((value, searchIn) => {
    if (value) {
      setSearchValue(value);
      setSearchedStudents(
        searchIn.filter(
          (student) =>
            student.name?.toLowerCase().includes(value.toLowerCase()) ||
            student.login?.toLowerCase().includes(value.toLowerCase()),
        ),
      );
    } else {
      setSearchValue(null);
      setSearchedStudents(null);
    }
  }, []);

  const resetSearch = useCallback(() => {
    searchFieldRef.current.clearSearch();
  }, []);

  return students.length ? (
    <Box className="personalized-block">
      <BlockHeader
        subtitle="Select student(s) or search for one"
        title={SharingLevels.PERSONALIZED.title}
      />
      <Box mb={3}>
        <SearchField
          apiRef={searchFieldRef}
          fullWidth
          gaLabel="Students search"
          inputRE={loginAllowedFilterRE}
          label={t('Search by name or ID')}
          minInputLength={3}
          onSearch={(value) => handleSearch(value, selectableEntities)}
          outlined={outlinedSearch}
          searchOnChange
        />
      </Box>
      {searchValue ? (
        searchedStudents?.length ? (
          <Box>
            <Typography mb={2} paragraph variant="body2">
              <Trans
                components={{ b: <strong /> }}
                i18nKey="search result text"
                values={{ count: searchedStudents.length, searchValue }}
              />
            </Typography>
            {searchedStudents.length > FULL_HEIGHT_NUMBER_OF_ENTITIES ? (
              <ListOfPersonalizedEntities
                columnsCount={columnsCount}
                entities={searchedStudents}
                onChange={onChange}
                selected={selected}
              />
            ) : (
              <SelectableEntities
                columnsCount={columnsCount}
                entities={searchedStudents}
                EntityComponent={PersonalizedEntityComponent}
                onChange={onChange}
                selected={selected}
              />
            )}
          </Box>
        ) : (
          <Box>
            <SearchNoResults
              explanation="AYO couldn’t find any students with the name or ID searchValue. Please check your spelling and repeat your search."
              onResetSearch={resetSearch}
              resetLinkText="Show all students from the list"
              searchValue={searchValue}
            />
          </Box>
        )
      ) : (
        <>
          {selectableEntities.length > FULL_HEIGHT_NUMBER_OF_ENTITIES ? (
            <ListOfPersonalizedEntities
              columnsCount={columnsCount}
              entities={selectableEntities}
              onChange={onChange}
              selected={selected}
            />
          ) : (
            <SelectableEntities
              columnsCount={columnsCount}
              entities={selectableEntities}
              EntityComponent={PersonalizedEntityComponent}
              onChange={onChange}
              selected={selected}
            />
          )}
        </>
      )}
    </Box>
  ) : (
    <InitiativeEmptyStateBlock
      illustration={<PersonalizedLevelEmptyState />}
      title={t('You don’t have any students yet')}
    />
  );
};

PersonalizedBlock.propTypes = {
  columnsCount: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  outlinedSearch: PropTypes.bool,
  students: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string,
    }),
  ).isRequired,
  selected: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ).isRequired,
};

PersonalizedBlock.defaultProps = {
  columnsCount: 2,
  outlinedSearch: false,
};

export default PersonalizedBlock;
