import React, { useContext, useEffect, useState } from 'react';
import { Box, Container, Grid, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Typography } from '../../../../../../atoms';
import {
  ClickableGalleryItem,
  InformationalMessage,
  YoutubeLinkButton,
} from '../../../../../../moleculas';
import { LeadershipAttributesDialog } from '../../../../../../organisms';
import { UserActions, UserContext } from '../../../../../../../context';
import { useOneTimeActionService } from '../../../../../../../services';
import { useProfile } from '../../../../../../../utils';
import LEADERSHIP_ATTRIBUTES_GALERY from '../../../../../../../constants/leadership-attributes-galery';
import { OneTimeActionsMap } from '../../../../../../../constants/enums';
import { ReactComponent as LeadershipMaturityLevelMax } from '../../../../../../../resources/images/leadership_maturity_level_max.svg';
import { ReactComponent as LeadershipMaturityLevelMedium } from '../../../../../../../resources/images/leadership_maturity_level_medium.svg';
import { ReactComponent as LeadershipMaturityLevelMin } from '../../../../../../../resources/images/leadership_maturity_level_min.svg';
import { ReactComponent as LeadershipMaturityLevelArrow } from '../../../../../../../resources/images/leadership_maturity_level_arrow.svg';

const LeadershipAttributesGallerySection = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { state: userState, dispatch: dispatchUserState } = useContext(UserContext);
  const { getOneTimeActionStatus } = useProfile();
  const { postOneTimeAction } = useOneTimeActionService();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  const [isLeadershipAttributesDialogOpen, setIsLeadershipAttributesDialogOpen] = useState(false);
  const [galeryNotificationHidden, setGaleryNotificationHidden] = useState(false);
  const [activeAttributeIdx, setActiveAttributeIdx] = useState(null);

  useEffect(() => {
    setGaleryNotificationHidden(
      getOneTimeActionStatus(OneTimeActionsMap.STUDENT_LEADERSHIP_COMPETENCIES_NOTIFICATION),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState.oneTimeActions]);

  const closeDialogHandler = () => {
    setTimeout(() => {
      document
        .querySelectorAll('.ayo-leadership-attributes-galery__images-block__images .ayo-card')
        [activeAttributeIdx].focus();
    }, 0);
    setIsLeadershipAttributesDialogOpen(false);
  };

  const galeryNotificationHandler = () => {
    postOneTimeAction(OneTimeActionsMap.STUDENT_LEADERSHIP_COMPETENCIES_NOTIFICATION);
    dispatchUserState({
      type: UserActions.SET_ONE_TIME_ACTION,
      data: OneTimeActionsMap.STUDENT_LEADERSHIP_COMPETENCIES_NOTIFICATION,
    });
  };

  const showDetailsHandler = (idx) => {
    setActiveAttributeIdx(idx);
    setIsLeadershipAttributesDialogOpen(true);
  };

  const navigationHandler = (idx) => {
    if (activeAttributeIdx > 0 || activeAttributeIdx < LEADERSHIP_ATTRIBUTES_GALERY.length - 1) {
      setActiveAttributeIdx(idx);
    }
  };

  return (
    <Box className="ayo-leadership-attributes-galery" mt={isWidthUpSm ? 12 : 7}>
      <Container className="ayo-leadership-attributes-galery__maturity-text">
        <Typography variant="h2">{t('Maturity levels')}</Typography>
        <Typography variant="body2">
          {t(
            'Guided by AYO and meaningful feedback from teachers, the student will enhance leadership skills using a three-pronged approach',
          )}
        </Typography>
      </Container>
      <Container className="ayo-leadership-attributes-galery__maturity-levels">
        <Box>
          <Typography component="h3" paragraph variant="subtitle2">
            {t('Leading self')}
          </Typography>
          <LeadershipMaturityLevelMin
            aria-label={t('A sketch of two circles in a frame')}
            role="img"
          />
        </Box>
        <Box px={3} py={isWidthUpSm ? 7 : 4}>
          <LeadershipMaturityLevelArrow className="ayo-leadership-attributes-galery__maturity-levels__arrow" />
        </Box>
        <Box>
          <Typography component="h3" paragraph variant="subtitle2">
            {t('Leading others')}
          </Typography>
          <LeadershipMaturityLevelMedium
            aria-label={t('A sketch of two gray masks in a frame')}
            role="img"
          />
        </Box>
        <Box px={3} py={isWidthUpSm ? 7 : 4}>
          <LeadershipMaturityLevelArrow className="ayo-leadership-attributes-galery__maturity-levels__arrow" />
        </Box>
        <Box>
          <Typography component="h3" paragraph variant="subtitle2">
            {t('Leading change')}
          </Typography>
          <LeadershipMaturityLevelMax
            aria-label={t('A picture of one red and one green theater masks in a frame')}
            role="img"
          />
        </Box>
      </Container>
      <Container className="ayo-leadership-attributes-galery__leadership-attributes-text">
        <Typography variant="h2">{t('Leadership Attributes')}</Typography>
        <Typography variant="body2">
          {t(
            'Here is a list of leadership competencies designed by a team of educational specialists',
          )}
        </Typography>
      </Container>
      {!galeryNotificationHidden && userState.oneTimeActions && (
        <Box pt={2} px={1}>
          <InformationalMessage
            onClick={galeryNotificationHandler}
            text={t('Select a picture to explore more')}
          />
        </Box>
      )}
      <Grid
        className="ayo-leadership-attributes-galery__images-block__images"
        container
        lg={10}
        md={10}
        pt={isWidthUpSm ? 5 : 4}
        sm={12}
        xl={7}
      >
        {LEADERSHIP_ATTRIBUTES_GALERY.map((attribute, idx) => (
          <ClickableGalleryItem
            key={attribute.key}
            attribute={attribute}
            className="ayo-leadership-attributes-galery__images-block__card"
            clickHandler={() => showDetailsHandler(idx)}
            index={idx}
            section="landingBlockConfig"
          />
        ))}
      </Grid>
      <Box pb={isWidthUpSm ? 8.5 : 5} pt={isWidthUpSm ? 8 : 4} width="100%">
        <YoutubeLinkButton />
      </Box>
      <LeadershipAttributesDialog
        attributes={LEADERSHIP_ATTRIBUTES_GALERY}
        index={activeAttributeIdx}
        isOpen={isLeadershipAttributesDialogOpen}
        navigationHandler={navigationHandler}
        onClose={closeDialogHandler}
      />
    </Box>
  );
};

export default LeadershipAttributesGallerySection;
