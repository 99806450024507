import React from 'react';
import PropTypes from 'prop-types';
import { Box, Collapse } from '@mui/material';

import { FormControlLabel, Radio, Typography } from '../../atoms';
import DisabledControlWithTooltip from '../tooltip-wrappers/disabled-control-with-tooltip/DisabledControlWithTooltip';

const RadioExpandPanel = ({ body, checked, disabled, gaLabel, title, tooltipTitle, onChange }) => (
  <Box>
    <DisabledControlWithTooltip
      placement="bottom-start"
      showTooltip={disabled}
      title={tooltipTitle}
    >
      <FormControlLabel
        control={<Radio checked={checked} gaLabel={gaLabel} onChange={onChange} />}
        disabled={disabled}
        label={
          <Typography component="h3" variant="subtitle2">
            {title}
          </Typography>
        }
      />
    </DisabledControlWithTooltip>
    <Collapse in={checked}>{body}</Collapse>
  </Box>
);

RadioExpandPanel.propTypes = {
  body: PropTypes.element.isRequired,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  gaLabel: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  tooltipTitle: PropTypes.string,
};

RadioExpandPanel.defaultProps = {
  disabled: false,
  gaLabel: '',
  tooltipTitle: '',
};

export default RadioExpandPanel;
