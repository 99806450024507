/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Box, Link as MUILink } from '@mui/material';

import { GA } from '../../../utils';
import { GaActions, GaCategories } from '../../../constants/enums';

const Link = ({
  children,
  className,
  disabled,
  gaLabel,
  onClick,
  isContained,
  icon,
  iconPosition,
  underline,
  ...rest
}) => {
  const [active, setActive] = useState(false);

  return (
    <MUILink
      className={`${classNames('ayo-link', {
        [`${className}`]: className,
        'Mui-active': active,
        'Mui-disabled': disabled,
        'ayo-link--contained': isContained,
      })}`}
      onClick={(e) => {
        if (disabled) {
          e.preventDefault();
        }
        if (gaLabel) {
          GA.logInteraction({
            category: GaCategories.BEHAVIOR,
            action: GaActions.LINK_CLICK,
            label: gaLabel,
          });
        }
        onClick(e);
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          setActive(true);
        }
      }}
      onKeyUp={(e) => {
        if (e.key === 'Enter') {
          setActive(false);
        }
      }}
      onMouseDown={() => {
        setActive(true);
      }}
      onMouseUp={() => {
        setActive(false);
      }}
      tabIndex={disabled ? -1 : 0}
      underline={underline}
      {...rest}
    >
      {icon ? (
        <Box display="inline-flex" gap={0.5}>
          {iconPosition === 'start' && icon}
          <Box className="ayo-link--with-icon">{children}</Box>
          {iconPosition === 'end' && icon}
        </Box>
      ) : (
        <>{children}</>
      )}
    </MUILink>
  );
};

Link.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Object)])
    .isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  gaLabel: PropTypes.string,
  icon: PropTypes.node,
  iconPosition: PropTypes.oneOf(['start', 'end']),
  isContained: PropTypes.bool,
  onClick: PropTypes.func,
  underline: PropTypes.string,
};

Link.defaultProps = {
  className: '',
  disabled: false,
  gaLabel: '',
  isContained: false,
  icon: null,
  iconPosition: 'end',
  onClick: () => {},
  underline: 'always',
};

export default Link;
