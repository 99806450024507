/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useRef } from 'react';
import { Box, IconButton } from '@mui/material';
import { LocalizationProvider, MonthPicker as MUIMonthPicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { PickersPopper } from '@mui/x-date-pickers/internals/components/PickersPopper';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import { Typography } from '../../atoms';
import { GA } from '../../../utils';
import { DatePickersLocales, GaActions, GaCategories } from '../../../constants/enums';
import { ReactComponent as DatePickerIcon } from '../../../resources/icons/date_picker.svg';
import { ReactComponent as ChevronLeft } from '../../../resources/icons/chevron_left.svg';
import { ReactComponent as ChevronRight } from '../../../resources/icons/chevron_right.svg';

const MonthPicker = ({
  gaLabel,
  isOpen,
  setIsOpen,
  onClose,
  date,
  onChange,
  minDate,
  maxDate,
  ...rest
}) => {
  const inputRef = useRef();

  const { i18n } = useTranslation();

  const onPickerChange = (clickedDate) => {
    if (gaLabel) {
      GA.logInteraction({
        category: GaCategories.BEHAVIOR,
        action: GaActions.DATE_PICKER_DATE_CLICK,
        label: gaLabel,
      });
    }
    onChange(clickedDate);
    setIsOpen(false);
  };

  const formatMonthDate = useCallback((dateToFormat) => dayjs(dateToFormat).format('MM-YYYY'), []);

  return (
    <>
      <IconButton
        ref={inputRef}
        className="ayo-icon-button"
        disableRipple
        onClick={() => setIsOpen((prevOpen) => !prevOpen)}
      >
        <DatePickerIcon />
      </IconButton>
      <LocalizationProvider
        adapterLocale={DatePickersLocales[i18n.language]}
        dateAdapter={AdapterDayjs}
      >
        <PickersPopper
          anchorEl={inputRef.current}
          onClose={() => setIsOpen(false)}
          open={isOpen}
          PaperProps={{ className: 'ayo-date-picker__calendar ayo-month-picker' }}
          role="dialog"
        >
          <Box
            alignItems="center"
            className="ayo-month-picker__heading"
            display="flex"
            justifyContent="space-between"
          >
            <IconButton
              disabled={date.year(date.year() - 1).isBefore(dayjs(minDate), 'year')}
              onClick={() =>
                onChange(
                  date
                    .year(date.year() - 1)
                    .startOf('year')
                    .isBefore(dayjs(minDate))
                    ? dayjs(minDate)
                    : date.year(date.year() - 1).startOf('year'),
                )
              }
            >
              <ChevronLeft />
            </IconButton>
            <Typography variant="subtitle2">{date.year()}</Typography>
            <IconButton
              disabled={date.year(date.year() + 1).isAfter(dayjs(maxDate), 'year')}
              onClick={() => onChange(date.year(date.year() + 1).startOf('year'))}
            >
              <ChevronRight />
            </IconButton>
          </Box>
          <MUIMonthPicker
            autoFocus
            date={date}
            disableHighlightToday
            maxDate={formatMonthDate(maxDate)}
            minDate={formatMonthDate(minDate)}
            onChange={onPickerChange}
            {...rest}
          />
        </PickersPopper>
      </LocalizationProvider>
    </>
  );
};

MonthPicker.propTypes = {
  gaLabel: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  date: PropTypes.instanceOf(Object),
  onChange: PropTypes.func.isRequired,
  minDate: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  maxDate: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
};

MonthPicker.defaultProps = {
  gaLabel: null,
  date: null,
  minDate: null,
  maxDate: null,
};

export default MonthPicker;
