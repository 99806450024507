import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import { Button, Typography } from '../../../../atoms';
import { ScreenCenteredContainer } from '../../../../moleculas';
import { ReactComponent as CreatePortfolioImage } from '../../../../../resources/images/portfolio/create-portfolio.svg';

const CreatePortfolioSection = ({ createPortfolioHandler }) => {
  const { t } = useTranslation();

  return (
    <Box className="ayo-create-portfolio-section">
      <ScreenCenteredContainer>
        <Box className="ayo-create-portfolio-section__content">
          <Box pb={3}>
            <CreatePortfolioImage
              alt={t('A suitcase filled with objects used for various activities')}
            />
          </Box>
          <Box alignItems="center" display="flex" flexDirection="column">
            <Box pb={1}>
              <Typography component="h1" variant="h1">
                {t('My portfolio')}
              </Typography>
            </Box>
            <Box pb={1}>
              <Typography variant="body2">
                <Trans components={{ br: <br /> }} i18nKey="Create portfolio paragraph" />
              </Typography>
            </Box>
            <Button
              className="ayo-create-portfolio-section__content__button"
              fullWidth
              gaLabel="Create a Portfolio"
              onClick={createPortfolioHandler}
              variant="primary"
            >
              {t('Create a portfolio')}
            </Button>
          </Box>
        </Box>
      </ScreenCenteredContainer>
    </Box>
  );
};

CreatePortfolioSection.propTypes = {
  createPortfolioHandler: PropTypes.func,
};

CreatePortfolioSection.defaultProps = {
  createPortfolioHandler: () => {},
};

export default CreatePortfolioSection;
