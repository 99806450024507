import React, { useCallback, useState } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Button, ErrorWrapper, Typography } from '../../../../atoms';
import { ChipsBlock } from '../../../../organisms';
import { useSnackbar } from '../../../../../hooks';
import { ClubKeys } from '../../../../../constants/club-hub';
import { ReactComponent as UsersIcon } from '../../../../../resources/icons/users.svg';
import ClubVisibilityDialog from '../club-visibility-dialog/ClubVisibilityDialog';

const ClubVisibility = ({ error, onChange, schools }) => {
  const { t } = useTranslation();

  const [isVisibilityDialogOpen, setIsVisibilityDialogOpen] = useState(false);

  const { setSnackBarStatus } = useSnackbar();

  const schoolDeleteHandler = useCallback(
    (item) => {
      onChange({ [ClubKeys.SCHOOLS]: schools.filter((school) => school !== item.name) });
    },
    [onChange, schools],
  );

  const schoolSaveHandler = useCallback(
    (key, value) => {
      onChange(key, value);
      setSnackBarStatus({
        text: t('The club availability details were added.'),
        type: 'success',
      });
    },
    [onChange, setSnackBarStatus, t],
  );

  return (
    <ErrorWrapper errorMessage={error}>
      <Box className="ayo-club-hub-create-page__description" p={3}>
        <Typography
          aria-label={`${t('Club availability')} ${t('required')}`}
          component="h3"
          variant="subtitle1"
        >
          {`${t('Club availability')}*`}
        </Typography>
        <Typography mb={5} mt={2} variant="body2">
          {t('Please add who can see the club’s primary info and have the ability to apply.')}
        </Typography>
        <ChipsBlock
          actionButtons={
            <Button
              endIcon={<UsersIcon />}
              gaLabel="Specify club availability"
              onClick={() => {
                setIsVisibilityDialogOpen(true);
              }}
            >
              {t('Specify club availability')}
            </Button>
          }
          chipsTitleHeaderLevel={4}
          clearAllHandler={() => onChange({ [ClubKeys.SCHOOLS]: [] })}
          editableChipsTitle={t('Added')}
          entities={schools.map((school) => ({ id: school, name: school }))}
          onDeleteHandler={schoolDeleteHandler}
          withEditableChips
        />
        <ClubVisibilityDialog
          entities={schools}
          isOpen={isVisibilityDialogOpen}
          onClose={() => {
            setIsVisibilityDialogOpen(false);
          }}
          onSave={schoolSaveHandler}
          primaryButtonText="Apply"
        />
      </Box>
    </ErrorWrapper>
  );
};

ClubVisibility.propTypes = {
  error: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  schools: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ClubVisibility;
