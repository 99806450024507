import React, { useCallback, useState } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Box, Container, Grid, useMediaQuery, useTheme } from '@mui/material';

import { Button, Typography } from '../../../../atoms';
import { FeedbackForm } from '../../../../organisms';
import { ReactComponent as SuccessIcon } from '../../../../../resources/icons/check_circle_outline_large.svg';
import { ReactComponent as ErrorIcon } from '../../../../../resources/icons/error_outline.svg';

const FeedbackFormContainer = () => {
  const { t } = useTranslation();

  const theme = useTheme();
  const isWidthUpMd = useMediaQuery(theme.breakpoints.up('md'), { noSsr: true });
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isFormErrored, setIsFormErrored] = useState(false);

  const handleOnSubmit = useCallback((success) => {
    if (!success) {
      setIsFormErrored(true);
    }
    setIsFormSubmitted(true);
  }, []);

  const handleResetForm = useCallback(() => {
    if (!isFormSubmitted) return;
    setIsFormSubmitted(false);
    setIsFormErrored(false);
  }, [isFormSubmitted]);

  return (
    <Box
      className={classnames('ayo-students-list-page__feedback', {
        error: isFormErrored,
      })}
      mt={isWidthUpMd ? 7 : 5}
    >
      <Container>
        <Grid container direction="column" item justifyContent="center" md={7} xs={12}>
          <Box pb={isWidthUpMd ? 10 : 26} pl={isWidthUpMd && 4} pt={isWidthUpMd ? 10 : 7.5}>
            {!isFormSubmitted ? (
              <FeedbackForm
                fieldLabel={t('Your feedback')}
                gaLabel="Submit feedback"
                isDarkVariant
                isOneTimeFeedback
                onSubmit={handleOnSubmit}
                text={[t('How do you feel about your experience with AYO?')]}
                title={t('Tell us a few words about your AYO experience')}
              />
            ) : !isFormErrored ? (
              <Box py={2}>
                <SuccessIcon />
                <Typography isLightText variant="h2">
                  {t('Thank you for your input!')}
                </Typography>
                <Box pt={1}>
                  <Typography isLightText variant="body2">
                    {t('Your feedback is valuable for AYO')}
                  </Typography>
                </Box>
              </Box>
            ) : (
              <Box py={2}>
                <ErrorIcon />
                <Typography variant="h2">{t('Oops')}</Typography>
                <Typography variant="h2">{t('Something went wrong')}</Typography>
                <Box pt={1}>
                  <Typography variant="body2">
                    {t('We couldn’t send your message')} {t('Please try once more')}
                  </Typography>
                </Box>
                <Box pr={isWidthUpMd && 10} pt={3} width={isWidthUpSm ? '50%' : '100%'}>
                  <Button fullWidth gaLabel="Retry" onClick={handleResetForm} variant="primary">
                    {t('Retry')}
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </Grid>
      </Container>
    </Box>
  );
};

export default FeedbackFormContainer;
