import { ReactComponent as AbstractReasoningIllustration } from '../resources/images/aptitudes/abstract_reasoning_color.svg';
import { ReactComponent as ArtisticIllustration } from '../resources/images/aptitudes/artistic_color.svg';
import { ReactComponent as LanguageIllustration } from '../resources/images/aptitudes/language_color.svg';
import { ReactComponent as MechanicalIllustration } from '../resources/images/aptitudes/mechanical_color.svg';
import { ReactComponent as NumericalIllustration } from '../resources/images/aptitudes/numerical_color.svg';
import { ReactComponent as OrganizationalIllustration } from '../resources/images/aptitudes/organizational_color.svg';
import { ReactComponent as PerceptualIllustration } from '../resources/images/aptitudes/perceptual_color.svg';
import { ReactComponent as PhysicalIllustration } from '../resources/images/aptitudes/physical_color.svg';
import { ReactComponent as SpatialIllustration } from '../resources/images/aptitudes/spatial_color.svg';
import { ReactComponent as VerbalIllustration } from '../resources/images/aptitudes/verbal_color.svg';

import { Aptitudes } from './enums';

export const AptitudesColorIllustrations = {
  [Aptitudes.ABSTRACT_REASONING]: {
    illustration: AbstractReasoningIllustration,
    alt: 'A Rubik’s cube',
  },
  [Aptitudes.ARTISTIC]: {
    illustration: ArtisticIllustration,
    alt: 'An easel with a painting',
  },
  [Aptitudes.LANGUAGE]: {
    illustration: LanguageIllustration,
    alt: 'Bubbles representing speaking',
  },
  [Aptitudes.MECHANICAL]: {
    illustration: MechanicalIllustration,
    alt: 'A robot',
  },
  [Aptitudes.NUMERICAL]: {
    illustration: NumericalIllustration,
    alt: 'A calculator with numbers',
  },
  [Aptitudes.ORGANIZATIONAL]: {
    illustration: OrganizationalIllustration,
    alt: 'A blackboard with two stickers and a star',
  },
  [Aptitudes.PERCEPTUAL]: {
    illustration: PerceptualIllustration,
    alt: 'Silhouettes of two faces',
  },
  [Aptitudes.PHYSICAL]: {
    illustration: PhysicalIllustration,
    alt: 'A hand holding a dumbbell',
  },
  [Aptitudes.SPATIAL]: {
    illustration: SpatialIllustration,
    alt: 'A 3D object',
  },
  [Aptitudes.VERBAL]: {
    illustration: VerbalIllustration,
    alt: 'Two notebooks',
  },
};

export const AptitudesGalleryItems = [
  {
    key: 'Abstract reasoning',
    landingBlockConfig: {
      imageDesktop: AptitudesColorIllustrations[Aptitudes.ABSTRACT_REASONING].illustration,
    },
    alt: AptitudesColorIllustrations[Aptitudes.ABSTRACT_REASONING].alt,
    definition:
      'The ability to quickly reason with information to solve new, unfamiliar problems, independent of any prior knowledge. ie. thinking logically, math or computer science',
    details: [
      'Understanding and thinking through complex concepts',
      'Thinking about ideas and principles that are symbolic or hypothetical',
      'Exhibiting humor and imagination',
      'Reasoning and solving problems in unique ways',
      'Describing things with metaphors',
      'Speaking about things figuratively',
      'Analyzing a situation',
      'Noticing relationships or patterns',
      'Forming theories about why things happen',
    ],
  },
  {
    key: 'Artistic',
    landingBlockConfig: {
      imageDesktop: AptitudesColorIllustrations[Aptitudes.ARTISTIC].illustration,
    },
    alt: AptitudesColorIllustrations[Aptitudes.ARTISTIC].alt,
    definition:
      'The ability to create a work that is pleasing to see or hear. ie. creativity, artistic talents, musical abilities, dramatic talents',
    details: [
      'Learns visually',
      'Enjoys finding ways to be creative',
      'Finds it pleasing to arrange things in visually stimulating ways',
    ],
  },
  {
    key: 'Language',
    landingBlockConfig: {
      imageDesktop: AptitudesColorIllustrations[Aptitudes.LANGUAGE].illustration,
    },
    alt: AptitudesColorIllustrations[Aptitudes.LANGUAGE].alt,
    definition:
      'The ability to learn languages. ie. meaning of words, correct spelling, meaning of idioms',
    details: [
      'Learns the structure of a new language easily',
      'Memorizes words and phrases quickly',
      'Excels in learning how to read and write in a new language',
    ],
  },
  {
    key: 'Mechanical',
    landingBlockConfig: {
      imageDesktop: AptitudesColorIllustrations[Aptitudes.MECHANICAL].illustration,
    },
    alt: AptitudesColorIllustrations[Aptitudes.MECHANICAL].alt,
    definition:
      'The ability to understand mechanical and physical concepts. ie. practical applications of mechanics, robotics and physics',
    details: [
      'Understands how parts of a machine work together',
      'Enjoys building, taking apart, or fixing objects',
      'Learns through hands-on activities',
      'Understands how and why complex parts work',
    ],
  },
  {
    key: 'Numerical',
    landingBlockConfig: {
      imageDesktop: AptitudesColorIllustrations[Aptitudes.NUMERICAL].illustration,
    },
    alt: AptitudesColorIllustrations[Aptitudes.NUMERICAL].alt,
    definition:
      'The ability to make decisions and inferences based on numerical data. ie. performing numerical operations, math and calculations',
    details: [
      'Has excellent problem-solving skills',
      'Enjoys thinking about abstract ideas',
      'Likes conducting scientific experiments',
      'Solves complex computations',
    ],
  },
  {
    key: 'Organizational',
    landingBlockConfig: {
      imageDesktop: AptitudesColorIllustrations[Aptitudes.ORGANIZATIONAL].illustration,
    },
    alt: AptitudesColorIllustrations[Aptitudes.ORGANIZATIONAL].alt,
    definition:
      "The ability to organize and operate efficiently. ie. planning, doing, evaluating your and other's actions",
    details: [
      'Arranges and operates efficiently',
      'Enjoys planning trips and creating slides for presentations',
      'Enjoys organizing and prioritizing tasks and data',
    ],
  },
  {
    key: 'Perceptual',
    landingBlockConfig: {
      imageDesktop: AptitudesColorIllustrations[Aptitudes.PERCEPTUAL].illustration,
    },
    alt: AptitudesColorIllustrations[Aptitudes.PERCEPTUAL].alt,
    definition:
      'The ability to deal with and give meaning to sensory stimuli. ie. comparing information and identification of similarities',
    details: [
      'Identifies and processes environmental information',
      'Distinguishes between different odors or musical pitches',
      'Discriminates between different shades of color',
      'Integrates stimuli with pre-existing knowledge and motor capabilities to act accordingly',
      'Compares letters, numbers, objects, pictures, or patterns accurately',
    ],
  },
  {
    key: 'Physical',
    landingBlockConfig: {
      imageDesktop: AptitudesColorIllustrations[Aptitudes.PHYSICAL].illustration,
    },
    alt: AptitudesColorIllustrations[Aptitudes.PHYSICAL].alt,
    definition:
      'This is a natural physical ability. ie. bodily strength, coordination, lifting weights, aerobic exercise',
    details: [
      'Demonstrates hand-eye and physical coordination',
      'Exhibits strength and agility',
      'Has skill for dancing and sports',
      'Enjoys creating things with your hands',
      'Remembers by doing rather than hearing or seeing',
    ],
  },
  {
    key: 'Spatial',
    landingBlockConfig: {
      imageDesktop: AptitudesColorIllustrations[Aptitudes.SPATIAL].illustration,
    },
    alt: AptitudesColorIllustrations[Aptitudes.SPATIAL].alt,
    definition:
      'The ability to think about and manipulate objects in three dimensions. ie. imagination of objects in space, geometry, 3D shaping',
    details: [
      'Understands relationship between objects',
      'Thinks visually',
      'Imagines or creates 3D models easily',
      'Excels at putting puzzles together',
      'Interprets pictures, graphs, and charts well',
      'Enjoys drawing, painting, and the visual arts',
      'Recognizes patterns easily',
    ],
  },
  {
    key: 'Verbal',
    landingBlockConfig: {
      imageDesktop: AptitudesColorIllustrations[Aptitudes.VERBAL].illustration,
    },
    alt: AptitudesColorIllustrations[Aptitudes.VERBAL].alt,
    definition:
      'The ability to understand and reason using concepts framed in words. ie. drawing correct meaning from written information',
    details: [
      'Remembers written and spoken information',
      'Enjoys reading and writing',
      'Debates or gives persuasive speeches',
      'Explains things well',
      'Uses humor when telling stories',
    ],
  },
];

export default {
  AptitudesColorIllustrations,
  AptitudesGalleryItems,
};
