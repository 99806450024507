import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { EditingButton } from '../../moleculas';
import { ReactComponent as NavigationBackArrow } from '../../../resources/icons/go_back.svg';

const BottomNavigation = ({
  attributes,
  index,
  navigationHandler,
  nextButtonTitle,
  previousButtonTitle,
  attributeKey,
}) => {
  const { t } = useTranslation();
  const isFirstAttribute = useMemo(() => index === 0, [index]);
  const isLastAttribute = useMemo(
    () => index + 1 === attributes.length,
    [attributes.length, index],
  );

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" mb={1}>
        <Box>
          {!isFirstAttribute && (
            <EditingButton
              ariaLabel={`${t(previousButtonTitle)}: ${t(
                `${attributes[index - 1]?.[attributeKey]}`,
              )}`}
              gaLabel={previousButtonTitle}
              icon={<NavigationBackArrow />}
              iconPosition="start"
              onClick={() => navigationHandler(index - 1)}
              text={t(attributes[index - 1]?.[attributeKey])}
            />
          )}
        </Box>
        <Box>
          {!isLastAttribute && (
            <EditingButton
              ariaLabel={`${t(nextButtonTitle)}: ${t(`${attributes[index + 1]?.[attributeKey]}`)}`}
              className="ayo-bottom-navigation__button-next"
              gaLabel={nextButtonTitle}
              icon={<NavigationBackArrow className="ayo-bottom-navigation__button-next-icon" />}
              iconPosition="end"
              onClick={() => navigationHandler(index + 1)}
              text={t(attributes[index + 1]?.[attributeKey])}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default BottomNavigation;

BottomNavigation.propTypes = {
  attributes: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  index: PropTypes.number.isRequired,
  navigationHandler: PropTypes.func.isRequired,
  nextButtonTitle: PropTypes.string,
  previousButtonTitle: PropTypes.string,
  attributeKey: PropTypes.string.isRequired,
};

BottomNavigation.defaultProps = {
  nextButtonTitle: 'Next Attribute',
  previousButtonTitle: 'Previous Attribute',
};
