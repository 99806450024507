import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

import { Typography } from '../../atoms';
import Chip from '../chips/chip/Chip';

const TagsBlock = ({ label, tagsList }) => {
  const { t } = useTranslation();
  return (
    <Box className="ayo-tags-block">
      <Typography isLabel variant="body3">
        {t(label)}
      </Typography>
      {tagsList.map((tag) => (
        <Box key={tag} display="inline-block" pr={1} pt={1}>
          <Chip isCaption label={tag} />
        </Box>
      ))}
    </Box>
  );
};

TagsBlock.propTypes = {
  label: PropTypes.string,
  tagsList: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TagsBlock.defaultProps = {
  label: '',
};

export default TagsBlock;
