import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { PageIllustrationBlock, ScreenCenteredContainer } from '../../moleculas';
import { ReactComponent as AccessForbiddenImage } from '../../../resources/images/error_restricted_access_admin.svg';

const MobileNotSupported = ({ explanation }) => {
  const { t } = useTranslation();

  return (
    <ScreenCenteredContainer>
      <PageIllustrationBlock
        Illustration={AccessForbiddenImage}
        mainHeaderText={t('Oops! Mobile version is not supported')}
        secondaryHeaderText={[explanation]}
      />
    </ScreenCenteredContainer>
  );
};

MobileNotSupported.propTypes = {
  explanation: PropTypes.string.isRequired,
};

export default MobileNotSupported;
