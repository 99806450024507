import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, DialogActions, DialogContent, Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import { Button, Dialog, Image, Typography } from '../../../../../atoms';
import { DialogErrorContent } from '../../../../../moleculas';
import { LeaveDialog } from '../../../../../organisms';
import { AppActions, AppContext } from '../../../../../../context';
import { useFollowUpService, useProfileService } from '../../../../../../services';
import { focusDialogCloseButton, getNormalizedLanguageCode } from '../../../../../../utils';
import { AnalyticTypesMap, FollowUpTypesMap } from '../../../../../../constants/enums';
import {
  moodsRoute,
  recommendationsRoute,
  trainAyoRoute,
} from '../../../../../../constants/routes';
import { ReactComponent as BackArrowIcon } from '../../../../../../resources/icons/chevron_left_primary.svg';

const MoodQuestionsDialog = ({ isOpen, moodDetails, onClose }) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { state: appState, dispatch: dispatchAppState } = useContext(AppContext);
  const { sendAyoAnalytic } = useProfileService();

  const [followUpQuestions, setFollowUpQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);
  const [isFormErrored, setIsFormErrored] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const activeQuestion = useMemo(
    () => followUpQuestions[activeQuestionIndex],
    [activeQuestionIndex, followUpQuestions],
  );

  const isLastQuestion = useMemo(
    () => activeQuestionIndex + 1 === followUpQuestions.length,
    [activeQuestionIndex, followUpQuestions.length],
  );

  const { getFollowUpQuestions, postFollowUpAnswers } = useFollowUpService();

  const { id, mood, studentId } = moodDetails;

  useEffect(() => {
    if (!followUpQuestions.length && isOpen) {
      getFollowUpQuestions(FollowUpTypesMap.MOOD, getNormalizedLanguageCode(i18n.language)).then(
        (data) => {
          setFollowUpQuestions(
            data.filter(({ resourceEntry }) => resourceEntry === mood)[0].followUpQuestions,
          );
          setActiveQuestionIndex(0);
        },
      );
    }
  }, [followUpQuestions, getFollowUpQuestions, i18n.language, isOpen, mood]);

  const setIsDirty = useCallback(
    (value) => {
      dispatchAppState({ type: AppActions.SET_IS_DIRTY, data: value });
    },
    [dispatchAppState],
  );

  const onAnswerHandler = useCallback(
    (question, answer, questionId) => {
      const questionIndex = answers.findIndex((answerItem) => answerItem.question === question);
      const newAnswers =
        questionIndex >= 0
          ? Object.assign([...answers], {
              [questionIndex]: {
                question,
                answer,
                questionId,
              },
            })
          : [...answers, { question, answer, questionId }];
      setAnswers(newAnswers);
      if (!isLastQuestion) {
        setActiveQuestionIndex((prevState) => prevState + 1);
      }
      setIsDirty(!!newAnswers.length);
      focusDialogCloseButton();
    },
    [answers, isLastQuestion, setIsDirty],
  );

  const onSkipHandler = useCallback(
    (question) => {
      const newAnswers = answers.filter((answer) => answer.question !== question);
      setAnswers(newAnswers);
      setActiveQuestionIndex((prevState) => prevState + 1);
      setIsDirty(!!newAnswers.length);
      focusDialogCloseButton();
    },
    [answers, setIsDirty],
  );

  const onPreviousHandler = useCallback(() => {
    setActiveQuestionIndex((prevState) => prevState - 1);
    focusDialogCloseButton();
  }, []);

  const answersPostBody = useMemo(
    () => ({
      resourceType: FollowUpTypesMap.MOOD,
      resourceId: id,
      userId: studentId,
      answers,
    }),
    [answers, id, studentId],
  );

  const recommendationsPageRoute = useMemo(
    () => `${trainAyoRoute}${moodsRoute}/${id}/${mood}${recommendationsRoute}`,
    [id, mood],
  );

  const onSubmitHandler = useCallback(() => {
    setIsFormSubmitted(true);
    if (answers.length) {
      postFollowUpAnswers(answersPostBody, true)
        .then(() => {
          history.push(recommendationsPageRoute);
          setIsDirty(false);
          onClose();
        })
        .catch(() => {
          setIsFormErrored(true);
          if (appState.isLeaveDialogOpen) {
            dispatchAppState({ type: AppActions.SET_IS_LEAVE_DIALOG_OPEN, data: false });
          }
        });
    } else {
      history.push(recommendationsPageRoute);
    }
  }, [
    answers.length,
    answersPostBody,
    appState.isLeaveDialogOpen,
    dispatchAppState,
    history,
    onClose,
    postFollowUpAnswers,
    recommendationsPageRoute,
    setIsDirty,
  ]);

  const onCloseHandler = useCallback(() => {
    history.push(recommendationsPageRoute);
    if (isFormErrored && isFormSubmitted) {
      setIsDirty(false);
      onClose();
    } else if (!appState.isDirty) {
      onClose();
    }
  }, [
    appState.isDirty,
    history,
    isFormErrored,
    isFormSubmitted,
    onClose,
    recommendationsPageRoute,
    setIsDirty,
  ]);

  const onRetryHandler = useCallback(() => {
    postFollowUpAnswers(answersPostBody, true).then(() => {
      setIsDirty(false);
      sendAyoAnalytic(AnalyticTypesMap.MOOD_COMMENT);
      history.push(recommendationsPageRoute);
    });
  }, [
    answersPostBody,
    history,
    postFollowUpAnswers,
    recommendationsPageRoute,
    setIsDirty,
    sendAyoAnalytic,
  ]);

  return (
    <Dialog
      className="ayo-mood-questions-dialog"
      gaLabel="Mood questions dialog"
      maxWidth={700}
      onClose={onCloseHandler}
      open={isOpen && followUpQuestions.length}
      scroll="paper"
      transitionDuration={500}
    >
      {!isFormErrored ? (
        <>
          {activeQuestion && (
            <>
              <DialogContent>
                <Box display="flex" justifyContent="center" position="relative">
                  <Box left={0} position="absolute" top={0}>
                    <Typography isLabel variant="body2">
                      {`${activeQuestionIndex + 1}/${followUpQuestions.length}`}
                    </Typography>
                  </Box>
                  <Image
                    key={activeQuestionIndex}
                    alt={activeQuestion.altText}
                    className="ayo-mood-questions-dialog__image"
                    src={activeQuestion.imageUrl}
                  />
                </Box>
                <Box mb={3} mt={5}>
                  <Typography component="p" variant="subtitle1">
                    {activeQuestion.question}
                  </Typography>
                </Box>
              </DialogContent>
              <DialogActions>
                <Box display="flex" flexDirection="column" width="100%">
                  <Grid container spacing={3}>
                    {activeQuestion.followUpAnswerOptions.map((answer, index) => {
                      const isSelected = answers.some(
                        (activeAnswer) =>
                          activeAnswer.question === activeQuestion.question &&
                          activeAnswer.answer === answer.answerKey,
                      );
                      return (
                        <Grid key={answer.id} item xs={6}>
                          <Button
                            aria-label={
                              isSelected ? `${answer.answerKey}${t('Selected')}` : answer.answerKey
                            }
                            className={`${classNames({
                              [`ayo-mood-questions-dialog__button--selected`]: isSelected,
                            })}`}
                            fullWidth
                            gaLabel={index ? 'Yes' : 'No'}
                            onClick={() =>
                              onAnswerHandler(
                                activeQuestion.question,
                                answer.answerKey,
                                activeQuestion.id,
                              )
                            }
                            variant="secondary"
                          >
                            {answer.answerKey}
                          </Button>
                        </Grid>
                      );
                    })}
                  </Grid>
                  <Box display="flex" flexDirection="row" mt={5}>
                    {!!activeQuestionIndex && (
                      <Box mr={2}>
                        <Button
                          aria-label={t('Go back to the previous question')}
                          gaLabel="Back"
                          isIconButton
                          onClick={onPreviousHandler}
                          variant="secondary"
                        >
                          <BackArrowIcon />
                        </Button>
                      </Box>
                    )}
                    <Button
                      fullWidth
                      gaLabel={!isLastQuestion ? 'Skip' : 'Submit'}
                      onClick={() =>
                        !isLastQuestion ? onSkipHandler(activeQuestion.question) : onSubmitHandler()
                      }
                      variant={!isLastQuestion ? 'secondary' : 'primary'}
                    >
                      {t(!isLastQuestion ? 'Skip' : 'Submit')}
                    </Button>
                  </Box>
                </Box>
              </DialogActions>
            </>
          )}
        </>
      ) : (
        <DialogErrorContent
          gaLabel="Retry submit follow-up questions"
          isErrored={isFormErrored}
          isSubmitted={isFormSubmitted}
          onRetry={onRetryHandler}
          text={t('Please try once more')}
        />
      )}
      <LeaveDialog
        onPrimaryClick={onSubmitHandler}
        primaryButtonTitle="Save and leave"
        secondaryButtonTitle="Yes, I want to leave"
      />
    </Dialog>
  );
};

MoodQuestionsDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  moodDetails: PropTypes.shape({
    id: PropTypes.number,
    studentId: PropTypes.number,
    mood: PropTypes.string,
    createdDate: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default MoodQuestionsDialog;
