/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { Checkbox, FormControlLabel, Typography } from '../../../../../atoms';
import {
  getFilterValueParts,
  isSimpleFilterSelected,
  updateSimpleFilter,
} from '../../../../../../utils';
import {
  ClubHubModerationFilterCategory,
  ContentModerationFilterCategory,
  FamilyFeedFilterCategory,
} from '../../../../../../constants/filter-categories';

const UserPrivilegesFilters = ({ description, menuFilters, setMenuFilters, stats, type }) => {
  const { t } = useTranslation();
  const { filtersList } = getFilterValueParts(menuFilters);

  const handleFiltersChange = useCallback(
    (e) => {
      const newFilters = updateSimpleFilter(e.target.value, type, menuFilters, stats[type]);
      setMenuFilters(newFilters);
    },
    [menuFilters, setMenuFilters, stats, type],
  );

  return (
    <Box className="filters-controls-box" display="flex" flexDirection="column">
      {!!description && <Typography variant="subtitle2">{t(description)}</Typography>}
      {stats[type]?.map((privilege) => (
        <FormControlLabel
          key={privilege}
          control={
            <Checkbox
              checked={isSimpleFilterSelected(
                privilege,
                type,
                menuFilters.filterCategory,
                filtersList,
              )}
              gaLabel={privilege}
              name="privilegeType"
              onChange={handleFiltersChange}
              value={privilege}
            />
          }
          label={t(`privileges.${privilege}`)}
        />
      ))}
    </Box>
  );
};

UserPrivilegesFilters.propTypes = {
  description: PropTypes.string,
  menuFilters: PropTypes.shape({ filterValue: PropTypes.string, filterCategory: PropTypes.string })
    .isRequired,
  setMenuFilters: PropTypes.func.isRequired,
  stats: PropTypes.instanceOf(Object).isRequired,
  type: PropTypes.string.isRequired,
};

UserPrivilegesFilters.defaultProps = {
  description: '',
};

export const ContentModerationPrivilegesFilters = (props) => (
  <UserPrivilegesFilters {...props} type={ContentModerationFilterCategory} />
);

export const FamilyFeedPrivilegesFilters = (props) => (
  <UserPrivilegesFilters
    description="Educators distributed by their user rights for posts and events creation in the Family feed"
    {...props}
    type={FamilyFeedFilterCategory}
  />
);

export const ClubHubModerationPrivilegesFilters = (props) => (
  <UserPrivilegesFilters
    {...props}
    description="Educators distributed by their clubs moderation rights"
    type={ClubHubModerationFilterCategory}
  />
);
