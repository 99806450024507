import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Divider } from '@mui/material';
import classNames from 'classnames';

import { ActionsMenu, Typography } from '../../../../../atoms';
import { AccordionItem } from '../../../../../moleculas';
import { formatSectionId } from '../../../../../../utils';

const SectionHeader = ({ title }) => {
  const { t } = useTranslation();

  return (
    <Box
      alignItems="center"
      className="ayo-lesson-page__section__header"
      display="flex"
      flex={1}
      pb={3}
    >
      <Typography className="ayo-lesson-page__section__title" variant="body2">
        {t(title)}
      </Typography>
      <Divider className="ayo-lesson-page__section__divider" />
    </Box>
  );
};

SectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
};

export const StandardSectionWrapper = ({ children, isStatic, title }) => {
  const hasContent = !!children.filter(Boolean).length;

  return (
    <Box
      className={classNames('ayo-lesson-page__section', {
        'ayo-lesson-page__section--open': hasContent,
      })}
    >
      {!isStatic && <SectionHeader title={title} />}
      {children}
    </Box>
  );
};

StandardSectionWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  isStatic: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

export const AccordionSectionWrapper = ({ children, title, onOpen, onOpened }) => {
  const [isOpen, setIsOpen] = useState(true);
  const hasContent = !!children.filter(Boolean).length;

  const handleOpenClose = useCallback(() => setIsOpen((prevState) => !prevState), []);

  return hasContent ? (
    <AccordionItem
      bodyEl={children}
      className={classNames('ayo-lesson-page__section', {
        'ayo-lesson-page__section--open': isOpen,
      })}
      expanded={isOpen}
      gaLabel="Lessons"
      headerEl={<SectionHeader title={title} />}
      id={formatSectionId(title)}
      onChange={handleOpenClose}
      TransitionProps={{
        onEntering: () => onOpen?.(),
        onEntered: () => onOpened?.(),
      }}
    />
  ) : null;
};

AccordionSectionWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  onOpen: PropTypes.func,
  onOpened: PropTypes.func,
  title: PropTypes.string.isRequired,
};

AccordionSectionWrapper.defaultProps = {
  onOpen: null,
  onOpened: null,
};

const WrapperComponentsMap = {
  accordion: AccordionSectionWrapper,
  standard: StandardSectionWrapper,
};

const LessonPageSection = ({ isStatic, sectionConfig, title, wrapperType }) => {
  const { t } = useTranslation();

  const visibleItems = sectionConfig.content.filter((item) => item.isVisible);
  const hiddenItems = sectionConfig.content.filter((item) => item.isRemovable && !item.isVisible);

  const WrapperComponent = WrapperComponentsMap[wrapperType];

  return (
    <WrapperComponent
      isStatic={isStatic}
      onOpen={sectionConfig.onOpen}
      onOpened={sectionConfig.onOpened}
      title={title}
    >
      {!!visibleItems.length && visibleItems.map((item) => ({ ...item.body, key: item.text }))}
      {!isStatic && hiddenItems.length > 0 && (
        <ActionsMenu
          activatorIcon={null}
          activatorVariant="secondary"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          gaLabel="Add a block"
          id={`add-block-${title}`}
          label={t('Add a block')}
          menuItems={hiddenItems.map((item) => ({
            text: t(item.text),
            handler: () => item.onAdd(),
            gaLabel: `Add ${item.text} block`,
          }))}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        />
      )}
    </WrapperComponent>
  );
};

LessonPageSection.propTypes = {
  isStatic: PropTypes.bool,
  sectionConfig: PropTypes.instanceOf(Object).isRequired,
  title: PropTypes.string.isRequired,
  wrapperType: PropTypes.oneOf(['accordion', 'standard']),
};

LessonPageSection.defaultProps = {
  isStatic: false,
  wrapperType: 'accordion',
};

export default LessonPageSection;
