import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import PropTypes from 'prop-types';

import { ActionsMenu, Button, Link, Typography } from '../../../../../atoms';
import { TextWithTooltip } from '../../../../../moleculas';
import { ConfirmDialog } from '../../../../../organisms';
import { formatUrl, getClubFormValidation, isEmpty } from '../../../../../../utils';
import { ClubKeys, ClubRequirementsKeys } from '../../../../../../constants/club-hub';
import { ReactComponent as AddIcon } from '../../../../../../resources/icons/add.svg';
import { ReactComponent as CreateIcon } from '../../../../../../resources/icons/create.svg';
import { ReactComponent as DeleteIcon } from '../../../../../../resources/icons/delete_primary.svg';
import { ReactComponent as LinkIcon } from '../../../../../../resources/icons/add_link.svg';
import ClubRequirements from '../../../club-hub-components/club-requirements/ClubRequirements';
import ClubEditDialog from '../club-edit-dialog/ClubEditDialog';

const ClubDetailsRequirements = ({ isEditable, onSave, requirements }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const [newClubRequirements, setNewClubRequirements] = useState(requirements);
  const [errors, setErrors] = useState({});

  const requirementsEditHandler = useCallback(() => {
    setNewClubRequirements({
      ...requirements,
      [ClubRequirementsKeys.LINKS]: requirements[ClubRequirementsKeys.LINKS].length
        ? requirements[ClubRequirementsKeys.LINKS].map((link) => link.url)
        : [''],
    });
    setIsEditDialogOpen(true);
    setErrors({});
  }, [requirements]);

  const requirementsDeleteHandler = useCallback(() => {
    onSave(
      {
        [ClubKeys.REQUIREMENTS]: {
          [ClubRequirementsKeys.DESCRIPTION]: '',
          [ClubRequirementsKeys.IS_VISIBLE]: false,
          [ClubRequirementsKeys.LINKS]: [],
        },
      },
      {
        text: t('The requirements for joining and staying have been deleted.'),
        type: 'delete',
      },
      {
        text: t(
          'AYO couldn’t delete the requirements for joining and staying. Please try once more.',
        ),
        type: 'error',
      },
    );
    setIsDeleteDialogOpen(false);
  }, [onSave, t]);

  const requirementsChangeHandler = useCallback(
    (changes) => {
      const changesKeys = Object.keys(changes);

      setNewClubRequirements((prevState) => ({ ...prevState, ...changes }));

      if (!isEmpty(errors)) {
        setErrors((prevState) =>
          changesKeys.reduce(
            (acc, current) => ({
              ...acc,
              [current]: '',
            }),
            prevState,
          ),
        );
      }
    },
    [errors],
  );

  const requirementsSaveHandler = useCallback(() => {
    const clubRequirements = {
      ...newClubRequirements,
      [ClubRequirementsKeys.IS_VISIBLE]: true,
      [ClubRequirementsKeys.LINKS]: newClubRequirements[ClubRequirementsKeys.LINKS]
        .filter((link) => link)
        .map((link) => formatUrl(link)),
    };
    const { isFormValid, validationErrors } = getClubFormValidation(
      {
        [ClubKeys.REQUIREMENTS]: clubRequirements,
      },
      t,
    );
    setErrors(validationErrors[ClubKeys.REQUIREMENTS]);
    if (isFormValid) {
      onSave({ [ClubKeys.REQUIREMENTS]: clubRequirements });
      setIsEditDialogOpen(false);
    }
  }, [newClubRequirements, onSave, t]);

  return (
    <>
      {requirements.isVisible ? (
        <Box className="ayo-club-hub-details__requirements" mt={isWidthUpSm ? 3 : 2} p={3}>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Typography component="h3" variant="subtitle2">
              {t('Requirements for joining and staying')}
            </Typography>
            {isEditable && (
              <ActionsMenu
                id="club-hub-requirements-actions"
                menuItems={[
                  {
                    text: t('Edit'),
                    icon: CreateIcon,
                    handler: requirementsEditHandler,
                    id: 'club-requirements-edit',
                    gaLabel: 'Edit',
                  },
                  {
                    text: t('Delete'),
                    icon: DeleteIcon,
                    handler: () => {
                      setIsDeleteDialogOpen(true);
                    },
                    id: 'club-requirements-delete',
                    gaLabel: 'Delete',
                  },
                ]}
              />
            )}
          </Box>
          <Typography
            className="ayo-club-hub-details__requirements__description"
            mt={2}
            variant="body2"
          >
            {requirements.description}
          </Typography>
          {!!requirements.links.length && (
            <>
              <Typography component="h4" mb={1} mt={3} variant="subtitle2">
                {`${t('Link')}(s)`}
              </Typography>
              {requirements.links.map((link) => (
                <Link
                  key={link.url}
                  className="ayo-club-requirements-link"
                  href={link.url}
                  icon={<LinkIcon />}
                  iconPosition="start"
                  target="_blank"
                >
                  <TextWithTooltip title={link.url} />
                </Link>
              ))}
            </>
          )}
        </Box>
      ) : isEditable ? (
        <Box mt={3}>
          <Button
            endIcon={<AddIcon />}
            gaLabel="Add requirements for joining and staying"
            onClick={requirementsEditHandler}
          >
            {t('Add requirements for joining and staying')}
          </Button>
        </Box>
      ) : null}
      {isEditable && (
        <>
          <ClubEditDialog
            className="ayo-club-hub-details__edit-dialog__requirements"
            dialogContent={
              <Box px={1}>
                <ClubRequirements
                  description={newClubRequirements[ClubRequirementsKeys.DESCRIPTION]}
                  error={errors?.[ClubRequirementsKeys.DESCRIPTION]}
                  label={t('Add the requirements here')}
                  links={newClubRequirements[ClubRequirementsKeys.LINKS]}
                  onChange={requirementsChangeHandler}
                />
              </Box>
            }
            dialogTitle={t(
              requirements.isVisible
                ? 'Edit - Requirements for joining and staying'
                : 'Add requirements for joining and staying',
            )}
            isOpen={isEditDialogOpen}
            onClose={() => {
              setIsEditDialogOpen(false);
            }}
            onSave={requirementsSaveHandler}
            submitButtonTitle={requirements.isVisible ? 'Save changes' : 'Save'}
          />
          <ConfirmDialog
            cancelButtonTitle="Cancel"
            confirmButtonTitle="Delete requirements"
            isOpen={isDeleteDialogOpen}
            onClose={() => setIsDeleteDialogOpen(false)}
            onConfirm={requirementsDeleteHandler}
            text="Once you delete the requirements for joining and staying, they will be removed from the Club’s page. However, you’ll be able to add them again later."
            title="Are you sure you want to delete the requirements for joining and staying?"
          />
        </>
      )}
    </>
  );
};

ClubDetailsRequirements.propTypes = {
  isEditable: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  requirements: PropTypes.shape({
    description: PropTypes.string,
    isVisible: PropTypes.bool,
    links: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  }).isRequired,
};

export default ClubDetailsRequirements;
