/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab';

import { Timeline, Typography } from '../../atoms';
import { timelineBlock } from '../../../constants/propTypes';

const TimelineContainer = ({ config }) => {
  const { t } = useTranslation();

  const items = [];

  config.items.forEach((item) => {
    const parts = [];

    if (item.content) {
      parts.push(
        <TimelineContent>
          <Typography className={item.content.className} variant={item.content.variant}>
            {t(item.content.text)}
          </Typography>
        </TimelineContent>,
      );
    } else {
      parts.push(<TimelineContent />);
    }

    parts.push(
      <TimelineSeparator>
        <TimelineDot />
        <TimelineConnector />
      </TimelineSeparator>,
    );

    if (item.opposite) {
      parts.push(
        <TimelineOppositeContent>
          <Typography className={item.opposite.className} variant={item.opposite.variant}>
            {t(item.opposite.text)}
          </Typography>
        </TimelineOppositeContent>,
      );
    } else {
      parts.push(<TimelineOppositeContent />);
    }

    items.push(<TimelineItem>{parts}</TimelineItem>);
  });

  return <Timeline>{items}</Timeline>;
};

TimelineContainer.propTypes = {
  config: timelineBlock,
};

TimelineContainer.defaultProps = {
  config: {},
};

export default TimelineContainer;
