import React from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { Box, DialogActions, DialogContent, useMediaQuery, useTheme } from '@mui/material';

import { Button, Dialog, Link, Typography } from '../../../../../atoms';
import { lifeLineRoute } from '../../../../../../constants/routes';
import { ReactComponent as SupportDialogImage } from '../../../../../../resources/images/moods/support-dialog.svg';

const MoodSupportDialog = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const isWidthUpXl = useMediaQuery(theme.breakpoints.up('xl'));

  return (
    <Dialog
      className="ayo-mood-dialog"
      gaLabel="Mood support dialog"
      maxWidth={700}
      onClose={onClose}
      open={isOpen}
      scroll="paper"
    >
      <DialogContent>
        <Box alignItems="center" display="flex" flexDirection="column">
          <Box>
            <SupportDialogImage />
          </Box>
          <Box mb={1} mt={3}>
            <Typography align="center" component="h2" variant="h2">
              <Trans components={{ br: <br /> }} i18nKey="988 24/7 Crisis & Support" />
            </Typography>
          </Box>
          <Typography align="center" display="inline" variant="body2">
            <Trans
              components={{
                Link: <Link gaLabel="Go to 988" href={lifeLineRoute} isContained target="_blank" />,
              }}
              i18nKey="988 support"
            />
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box mt={3} px={isWidthUpXl ? 12 : isWidthUpSm ? 8 : 0} width="100%">
          <Button
            fullWidth
            gaLabel="Go to 988"
            onClick={() => window.open(lifeLineRoute, '_blank')}
            target="_blank"
            variant="primary"
          >
            {t('Go to 988')}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

MoodSupportDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default MoodSupportDialog;
