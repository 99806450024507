import React, { useState, useCallback, useMemo, useEffect, useContext } from 'react';
import { Box, Grid, useTheme, useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { Snackbar, Typography, Button, SkeletonMask } from '../../atoms';
import {
  ExtracurricularsYear,
  GoToStudentProfileBlock,
  InitiativeEmptyStateBlock,
} from '../../moleculas';
import { useLazyDataLoad, useSharedStudentData } from '../../../hooks';
import { useStudentsService } from '../../../services';
import { getCurrentSchoolYear } from '../../../utils';
import { trainAyoExtracurricularsAppRoute } from '../../../constants/routes';
import { ReactComponent as VectorIcon } from '../../../resources/icons/vector.svg';
import { ReactComponent as ExtracurricularsEmptyStateIllustration } from '../../../resources/images/extracurriculars_boards.svg';
import { UserContext } from '../../../context';
import { RolesMap } from '../../../constants/enums';

const ExtracurricularsSection = ({
  studentId,
  isEditable,
  isLazyLoad,
  title,
  titleVariant,
  extracurriculars: propsExtracurriculars,
  withAdjustButton,
  text,
  headerLevel,
}) => {
  const currentScoolYear = getCurrentSchoolYear();
  const { t } = useTranslation();

  const { getStudentExtracurriculars, getAvailableExtracurriculars, postStudentExtracurriculars } =
    useStudentsService();
  const [studentExtracurriculars, setStudentExtracurriculars] = useState([]);
  const [availableExtracurriculars, setAvailableExtracurriculars] = useState([]);
  const [saveSnackbarStatus, setSaveSnackbarStatus] = useState(null);

  const { activeStudent } = useSharedStudentData(isEditable ? studentId : null);

  const loadStudentExtracurriculars = useCallback(
    (onLoad) => {
      if (Number.isInteger(studentId) || typeof studentId === 'string') {
        getStudentExtracurriculars(studentId, isLazyLoad).then((extracurriculars) => {
          setStudentExtracurriculars(extracurriculars);
          if (onLoad) {
            onLoad();
          }
        });
      }
    },
    [getStudentExtracurriculars, isLazyLoad, studentId],
  );

  const { isDataLoaded, loadActivatorRef } = useLazyDataLoad(
    () =>
      new Promise((resolve) => {
        loadStudentExtracurriculars(resolve);
      }),
  );

  useEffect(() => {
    if (activeStudent?.schoolName) {
      if (!isLazyLoad) {
        loadStudentExtracurriculars();
      }

      if (isEditable) {
        getAvailableExtracurriculars(activeStudent.schoolName).then((extracurriculars) => {
          setAvailableExtracurriculars(extracurriculars);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStudent?.schoolName]);

  useEffect(() => {
    if (propsExtracurriculars) {
      setStudentExtracurriculars(propsExtracurriculars);
    }
  }, [propsExtracurriculars]);

  const handleSaveError = useCallback(
    (extracurricularsRestorer) => {
      setStudentExtracurriculars(extracurricularsRestorer);
      setSaveSnackbarStatus({
        text: t("Couldn't save the changes Please try editing once more"),
        type: 'error',
      });
    },
    [t],
  );

  const onExtracurricularDelete = useCallback(
    (item, sendRequest = true) => {
      const newExtracurriculars = studentExtracurriculars.filter(
        (extracurricular) => extracurricular !== item,
      );
      setStudentExtracurriculars(newExtracurriculars);
      if (!sendRequest) return;
      const savedExtracurriculars = studentExtracurriculars;
      postStudentExtracurriculars(studentId, newExtracurriculars).catch(() => {
        handleSaveError(savedExtracurriculars);
      });
    },
    [handleSaveError, postStudentExtracurriculars, studentExtracurriculars, studentId],
  );

  const onExtracurricularEdit = useCallback(
    (extracurricular, newValue) => {
      if (!newValue.trim()) {
        onExtracurricularDelete(extracurricular, false);
        return;
      }
      const newExtracurricular = { ...extracurricular, name: newValue.trim(), isEditable: false };
      const extracurricularIndex = studentExtracurriculars.findIndex(
        (item) => item === extracurricular,
      );
      const newExtracurriculars = [...studentExtracurriculars];
      newExtracurriculars[extracurricularIndex] = newExtracurricular;
      setStudentExtracurriculars(newExtracurriculars);
      postStudentExtracurriculars(studentId, newExtracurriculars).catch(() => {
        handleSaveError((currentStudentExtracurriculars) =>
          currentStudentExtracurriculars.filter(
            (currentExtracurricular) => currentExtracurricular !== newExtracurricular,
          ),
        );
      });
    },
    [
      handleSaveError,
      onExtracurricularDelete,
      postStudentExtracurriculars,
      studentExtracurriculars,
      studentId,
    ],
  );

  const onExtracurricularChange = useCallback(
    (extracurricular, newValue) => {
      const newExtracurricular = { ...extracurricular, currentValue: newValue.trim() };
      const extracurricularIndex = studentExtracurriculars.findIndex(
        (item) => item === extracurricular,
      );
      const newExtracurriculars = [...studentExtracurriculars];
      newExtracurriculars[extracurricularIndex] = newExtracurricular;
      setStudentExtracurriculars(newExtracurriculars);
    },
    [studentExtracurriculars],
  );

  const onExtracurricularAdd = useCallback(
    (extracurricular) => {
      const newExtracurriculars = [extracurricular, ...studentExtracurriculars];
      setStudentExtracurriculars(newExtracurriculars);
      if (!extracurricular.isEditable) {
        postStudentExtracurriculars(studentId, newExtracurriculars).catch(() => {
          handleSaveError((currentStudentExtracurriculars) =>
            currentStudentExtracurriculars.filter(
              (currentExtracurricular) => currentExtracurricular !== extracurricular,
            ),
          );
        });
      }
    },
    [handleSaveError, postStudentExtracurriculars, studentExtracurriculars, studentId],
  );

  const extracurricularsByYear = useMemo(
    () =>
      studentExtracurriculars?.reduce(
        (acc, extracurricular) => ({
          ...acc,
          [extracurricular.year]: [...(acc[extracurricular.year] || []), extracurricular],
        }),
        {},
      ),
    [studentExtracurriculars],
  );

  const timelineConfig = useMemo(
    () => [
      { label: t('This year'), year: currentScoolYear },
      { label: t('Last year'), year: currentScoolYear - 1, withEditButton: true },
    ],
    [currentScoolYear, t],
  );
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  const hasAdjustButton = studentExtracurriculars.length && withAdjustButton;

  const { state: userState } = useContext(UserContext);

  const emptyState =
    userState.profile.role === RolesMap.STUDENT ? (
      <InitiativeEmptyStateBlock
        body={t('It must be useful for you in future')}
        buttonContent={t('Share in Train AYO')}
        buttonGaLabel="Share in Train AYO - Extracurriculars"
        buttonLink={trainAyoExtracurricularsAppRoute}
        illustration={<ExtracurricularsEmptyStateIllustration />}
        showNavigationButton
        title={t('How about sharing your extracurriculars with AYO?')}
      />
    ) : (
      <InitiativeEmptyStateBlock
        illustration={<ExtracurricularsEmptyStateIllustration />}
        title={t('Extracurriculars were not shared so far')}
      />
    );

  return (
    <>
      <Box mb={isEditable ? (isWidthUpSm ? 10 : 5) : 0}>
        <Grid className="ayo-extracurriculars-block" container xs={12}>
          <Grid item xs={12}>
            <Box
              ref={isLazyLoad ? loadActivatorRef : null}
              alignItems={hasAdjustButton && !isWidthUpSm ? 'flex-start' : 'center'}
              display="flex"
              flexDirection={hasAdjustButton && !isWidthUpSm ? 'column' : 'row'}
              justifyContent={hasAdjustButton && isWidthUpSm ? 'space-between' : 'flex-start'}
              mb={text ? 1 : 3}
              width="100%"
            >
              <Typography component={`h${headerLevel}`} variant={titleVariant}>
                {title}
              </Typography>
              {hasAdjustButton ? (
                <Button
                  component={RouterLink}
                  endIcon={<VectorIcon />}
                  gaLabel="Share in Train AYO - Extracurriculars"
                  to={trainAyoExtracurricularsAppRoute}
                >
                  {t('Share in Train AYO')}
                </Button>
              ) : null}
            </Box>
          </Grid>
          {text && (
            <Grid item sm={6} xs={12}>
              <Box mb={5}>
                <Typography variant="body2">{text}</Typography>
              </Box>
            </Grid>
          )}
          <Grid container item xs={12}>
            {studentExtracurriculars.length || isEditable ? (
              timelineConfig.map((timelineItem) => (
                <Grid
                  key={timelineItem.label}
                  className="extracurriculars-years-container"
                  item
                  sm={6}
                  xs={12}
                >
                  <ExtracurricularsYear
                    availableExtracurriculars={availableExtracurriculars}
                    extracurriculars={extracurricularsByYear?.[timelineItem.year] || []}
                    headerLevel={headerLevel + 1}
                    isEditing={isEditable}
                    label={timelineItem.label}
                    onExtracurricularAdd={onExtracurricularAdd}
                    onExtracurricularChange={onExtracurricularChange}
                    onExtracurricularDelete={onExtracurricularDelete}
                    onExtracurricularEdit={onExtracurricularEdit}
                    year={timelineItem.year}
                  />
                </Grid>
              ))
            ) : isLazyLoad && !isDataLoaded ? (
              <SkeletonMask />
            ) : (
              emptyState
            )}
          </Grid>
          <Snackbar
            onClose={() => {
              setSaveSnackbarStatus(null);
              setTimeout(
                () => document.querySelector('.ayo-extracurriculars-edit-button')?.focus(),
                0,
              );
            }}
            open={saveSnackbarStatus}
            text={<div role="alert">{saveSnackbarStatus?.text}</div>}
            variant={saveSnackbarStatus?.type}
          />
        </Grid>
      </Box>
      {isEditable &&
        studentExtracurriculars.filter((extracurricular) => !extracurricular.isEditable).length !==
          0 && <GoToStudentProfileBlock />}
    </>
  );
};

ExtracurricularsSection.propTypes = {
  studentId: PropTypes.number,
  isEditable: PropTypes.bool,
  isLazyLoad: PropTypes.bool,
  title: PropTypes.string.isRequired,
  titleVariant: PropTypes.string,
  extracurriculars: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      id: PropTypes.number,
    }),
  ),
  withAdjustButton: PropTypes.bool,
  text: PropTypes.string,
  headerLevel: PropTypes.number,
};

ExtracurricularsSection.defaultProps = {
  studentId: null,
  isEditable: false,
  isLazyLoad: false,
  titleVariant: 'subtitle1',
  extracurriculars: null,
  withAdjustButton: false,
  text: null,
  headerLevel: 2,
};

export default ExtracurricularsSection;
