import React, { useContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, DialogActions, DialogContent } from '@mui/material';
import dayjs from 'dayjs';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import {
  Button,
  Card,
  Checkbox,
  CourseDisplayName,
  Dialog,
  DialogTitle,
  FormControlLabel,
  PeriodDisplayName,
  Typography,
} from '../../atoms';
import { useLessonPlannerData } from '../../../hooks';
import { GA, sortArrayByKey } from '../../../utils';
import { LessonPlannerActions, LessonPlannerContext, UserContext } from '../../../context';
import {
  myClassesLessonRoute,
  myLessonsLessonCreateRoute,
  myLessonsLessonViewRoute,
} from '../../../constants/routes';
import { GaActions, GaCategories, RolesMap } from '../../../constants/enums';
import { classPropType } from '../../../constants/propTypes';
import { NewFeaturesIdsMap } from '../../../tours/common/NewFeaturesItemsProvider';
import { NewFeatureHotspot } from '../new-feature-hotspot/NewFeatureHotspot';
import { ReactComponent as PlusIcon } from '../../../resources/icons/plus.svg';
import LessonCard from '../lesson-card/LessonCard';
import PopupActionsButtons from '../popup-actions-buttons/PopupActionsButtons';

const SectionsOptionsDialog = ({ courses, isOpen, onClose, onSave }) => {
  const [selectedCourses, setSelectedCourses] = useState([]);

  const selectedCoursesNumbers = selectedCourses.map(({ courseNumber }) => courseNumber);

  const { t } = useTranslation();

  const [error, setError] = useState('');

  const closeWithCleanup = useCallback(() => {
    setError('');
    onClose();
  }, [onClose]);

  return (
    <Dialog onClose={closeWithCleanup} open={isOpen}>
      <DialogTitle>
        <Typography mb={3} variant="subtitle1">
          {t('Select the sections for your lesson')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={2}>
          {courses.map((course) => (
            <FormControlLabel
              key={course.id}
              control={
                <Checkbox
                  className={classNames('ayo-class-sections-selector__checkbox', {
                    'ayo-class-sections-selector__checkbox--error': !!error,
                  })}
                  onChange={() => {
                    setSelectedCourses((prevCourses) =>
                      selectedCoursesNumbers.includes(course.courseNumber)
                        ? prevCourses.filter(
                            (selectedCourse) => selectedCourse.courseNumber !== course.courseNumber,
                          )
                        : [...prevCourses, course],
                    );
                    setError('');
                  }}
                  selected={selectedCoursesNumbers.includes(course.courseNumber)}
                  value={course.courseNumber}
                />
              }
              label={course.className}
            />
          ))}
          {error && (
            <Typography isError variant="caption">
              {error}
            </Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <PopupActionsButtons
          primaryButtonHandler={() => {
            if (selectedCourses.length) {
              onSave(selectedCourses);
            } else {
              setError(t('Please select at least 1 section to start creating content.'));
            }
          }}
          primaryButtonText={t('Add content')}
          secondaryButtonHandler={closeWithCleanup}
          secondaryButtonText={t('Cancel')}
        />
      </DialogActions>
    </Dialog>
  );
};

SectionsOptionsDialog.propTypes = {
  courses: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

const CalendarClassCard = ({ classEntry, date, showSection }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { state: userState } = useContext(UserContext);
  const { dispatch: dispatchLessonPlannerState } = useContext(LessonPlannerContext);
  const { scheduleAvailablePeriods } = useLessonPlannerData();

  const location = useLocation();

  const isStudent = useMemo(
    () => userState.profile?.role === RolesMap.STUDENT,
    [userState.profile?.role],
  );

  const handleAddContent = useCallback(
    (courses) => {
      const lessonData = {
        classMetadata: {
          courseNumber: classEntry.courseNumber,
          courseSource: classEntry.courseSource,
          classId: classEntry.classId,
          className: classEntry.className,
          period: classEntry.period,
          classesPeriod: { periodName: classEntry.periodName, courses },
          classDate: date.format('YYYY-MM-DD'),
        },
        owner: {
          id: userState.profile?.id,
        },
        sharedWith: [],
        openedFromDate: date,
      };

      dispatchLessonPlannerState({
        type: LessonPlannerActions.SET_LESSON_DATA,
        data: lessonData,
      });

      const redirectUrl = `${myLessonsLessonCreateRoute}?redirectTo=${encodeURIComponent(
        location.pathname + location.search,
      )}`;

      history.push(redirectUrl);
    },
    [
      classEntry,
      date,
      dispatchLessonPlannerState,
      history,
      location.pathname,
      location.search,
      userState.profile?.id,
    ],
  );

  const [isCoursesDialogOpen, setIsCoursesDialogOpen] = useState(false);

  const handleAddContentClick = useCallback(() => {
    setIsCoursesDialogOpen(true);
  }, []);

  const sortedLessons = useMemo(
    () => sortArrayByKey(classEntry.lessonsMetadata, 'createdDate', 'date', 'increase'),
    [classEntry.lessonsMetadata],
  );

  const handleLessonCardClick = useCallback(
    (id) => {
      GA.logInteraction({
        category: GaCategories.BEHAVIOR,
        action: GaActions.CARD_CLICK,
        label: `Lesson card`,
      });

      const url = `${(isStudent ? myClassesLessonRoute : myLessonsLessonViewRoute).replace(
        ':lessonId',
        id,
      )}?redirectTo=${encodeURIComponent(location.pathname + location.search)}`;

      history.push(url);
    },
    [history, isStudent, location.pathname, location.search],
  );

  useEffect(() => {
    document.querySelectorAll('.add-content__hotspot')?.forEach((el, index) => {
      if (index) {
        el.setAttribute('class', 'add-content__hotspot--hidden');
      }
    });
  }, []);

  return (
    <>
      <Card
        className="ayo-classes-calendar-day-card"
        elevation={0}
        mainContent={
          <Box>
            <Box
              bgcolor={
                scheduleAvailablePeriods?.find(
                  (period) => classEntry.periodName === period.periodName,
                )?.color || classEntry.color
              }
              className="class-header"
            >
              <Typography variant="subtitle2">
                {showSection ? (
                  <CourseDisplayName
                    className={classEntry.className}
                    period={classEntry.periodName}
                  />
                ) : (
                  <PeriodDisplayName period={classEntry.periodName} />
                )}
              </Typography>
            </Box>
            <Box
              alignItems="center"
              className="class-content"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              rowGap={2}
            >
              {sortedLessons?.map((lesson) => (
                <LessonCard
                  key={lesson.id}
                  availableCourses={!showSection && classEntry.courses}
                  courses={lesson.classData.classesPeriod.courses}
                  isStudent={isStudent}
                  lessonInfo={lesson.lessonInfo}
                  onClick={() => handleLessonCardClick(lesson.id)}
                  status={lesson.status}
                  title={lesson.title}
                />
              ))}
              {!isStudent && !dayjs(date).isBefore(dayjs(), 'day') && (
                <Button
                  className="class-content__add-button"
                  endIcon={
                    <Box alignItems="center" display="flex" flexDirection="row">
                      <PlusIcon />
                      <NewFeatureHotspot
                        className="add-content__hotspot"
                        id={NewFeaturesIdsMap.LESSON_DESIGNER}
                        isClickable
                        label={t('Lesson designer')}
                      />
                    </Box>
                  }
                  fullWidth
                  gaLabel="Add content"
                  onClick={handleAddContentClick}
                  variant="text"
                >
                  {t('Add content')}
                </Button>
              )}
            </Box>
          </Box>
        }
      />
      <SectionsOptionsDialog
        courses={classEntry.courses}
        isOpen={isCoursesDialogOpen}
        onClose={() => setIsCoursesDialogOpen(false)}
        onSave={(courses) => handleAddContent(courses)}
      />
    </>
  );
};

CalendarClassCard.propTypes = {
  classEntry: PropTypes.shape(classPropType).isRequired,
  date: PropTypes.exact(dayjs.Dayjs).isRequired,
  showSection: PropTypes.bool,
};

CalendarClassCard.defaultProps = {
  showSection: false,
};

export default CalendarClassCard;
