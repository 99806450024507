import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

import { Tooltip, Typography } from '../../atoms';
import { MoodNames, Moods, MoodsAndSupportIllustrations } from '../../../constants/moods';
import { ReactComponent as AlertIcon } from '../../../resources/icons/alert.svg';

const MoodCheckinInfo = ({ mood, checkinTime, timeLabel, titleHeadingLevel, isNegativeTrend }) => {
  const { illustration: MoodIllustration } = MoodsAndSupportIllustrations[mood];
  const { t } = useTranslation();

  return (
    <Box display="flex" gridGap={16}>
      <Tooltip enterTouchDelay={0} leaveTouchDelay={5000} title={t(MoodNames[mood])}>
        <MoodIllustration aria-label={t(MoodNames[mood])} role="img" />
      </Tooltip>
      <Box ml={2}>
        <Box display="flex" justifyContent="space-between" mb={1}>
          <Typography
            className="ayo-mood-heading"
            component={titleHeadingLevel}
            variant="subtitle2"
          >
            {t(MoodNames[mood])}
          </Typography>
          {isNegativeTrend && <AlertIcon />}
        </Box>
        <Box>
          <Typography isLabel variant="body3">
            {timeLabel} {checkinTime}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

MoodCheckinInfo.propTypes = {
  mood: PropTypes.oneOf(Object.values(Moods)).isRequired,
  checkinTime: PropTypes.string.isRequired,
  timeLabel: PropTypes.string,
  titleHeadingLevel: PropTypes.string,
  isNegativeTrend: PropTypes.bool,
};

MoodCheckinInfo.defaultProps = {
  timeLabel: null,
  isNegativeTrend: false,
  titleHeadingLevel: 'h4',
};

export default MoodCheckinInfo;
