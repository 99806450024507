import DOMPurify from 'dompurify';

export const getDangerouslySetInnerHtml = (html) => {
  const sanitizedHtml = DOMPurify.sanitize(html, {
    ALLOWED_TAGS: ['a', 'b', 'strong'],
  });
  const tempElement = document.createElement('div');
  tempElement.innerHTML = sanitizedHtml;
  const aTags = tempElement.querySelectorAll('a');
  aTags.forEach((aTag) => {
    aTag.setAttribute('target', '_blank');
  });

  return { __html: tempElement.innerHTML };
};
