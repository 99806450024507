import React from 'react';
import { Box, DialogContent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Button, Dialog, DialogTitle, Typography } from '../../../../atoms';
import { PopupActionsButtons, STTTextField } from '../../../../moleculas';

const RenameDialog = ({ error, inputValue, isOpen, onClose, onInputChange, onRename }) => {
  const { t } = useTranslation();

  return (
    <Dialog className="ayo-lesson-planner-rename" onClose={onClose} open={isOpen}>
      <DialogTitle className="ayo-lesson-planner-rename__title" disableTypography>
        <Typography variant="subtitle1">{t('Rename the planner')}</Typography>
      </DialogTitle>
      <DialogContent>
        <Box pb={5} pt={4.5}>
          <STTTextField
            className="ayo-lesson-planner-rename__input"
            error={!!error}
            fullWidth
            gaLabel="Lesson planner name"
            helperText={error ?? ''}
            inputProps={{
              required: true,
              autoComplete: 'off',
            }}
            label={t('Name')}
            name={t('Name')}
            onChange={onInputChange}
            outlined
            required
            value={inputValue}
          />
        </Box>
        <PopupActionsButtons
          primaryButtonComponent={
            <Button fullWidth gaLabel="Save" onClick={onRename} variant="primary">
              {t('Save')}
            </Button>
          }
          secondaryButtonGaLabel="Cancel"
          secondaryButtonHandler={onClose}
          secondaryButtonText={t('Cancel')}
        />
      </DialogContent>
    </Dialog>
  );
};

RenameDialog.propTypes = {
  error: PropTypes.string,
  inputValue: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onRename: PropTypes.func.isRequired,
};

RenameDialog.defaultProps = {
  error: '',
  inputValue: '',
};

export default RenameDialog;
