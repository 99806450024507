import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { BottomBar, PageWrapper } from '../../organisms';
import { initiativesRoute } from '../../../constants/routes';

import {
  OpportunitiesDefinitionSection,
  OpportunitiesExploreMoreSection,
  OpportunitiesGoalSection,
  OpportunitiesHowItWorksSection,
  OpportunitiesPurposeSection,
} from './components';

const OpportunitiesPage = () => {
  const { t } = useTranslation();

  return (
    <PageWrapper
      backToLink={initiativesRoute}
      backToText={t('Go to AYO’s initiatives page')}
      className="ayo-opportunities-page"
      customFooter={<BottomBar noNav />}
      noNav
    >
      <Box className="ayo-opportunities-page__content">
        <OpportunitiesDefinitionSection />
        <OpportunitiesGoalSection />
        <OpportunitiesPurposeSection />
        <OpportunitiesHowItWorksSection />
        <OpportunitiesExploreMoreSection />
      </Box>
    </PageWrapper>
  );
};

export default OpportunitiesPage;
