import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Grid, useTheme, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Button, SkeletonMask, Typography } from '../../atoms';
import {
  NewFeatureHotspot,
  InitiativeEmptyStateBlock,
  LearnMoreProfileBlock,
} from '../../moleculas';
import { NewFeaturesIdsMap } from '../../../tours/common/NewFeaturesItemsProvider';
import EvidenceOfGrowthBlock from '../evidence-of-growth/evidence-of-growth-block/EvidenceOfGrowthBlock';
import LeadershipAttributesGraph from '../leadership-attributes-graph/LeadershipAttributesGraph';
import { UserContext } from '../../../context';
import { leadershipRoute, trainAyoRoute } from '../../../constants/routes';
import { RolesMap } from '../../../constants/enums';
import { findCompletedLevels } from '../../../utils';
import { ReactComponent as EmptyLAIllustration } from '../../../resources/images/no_la_illustration.svg';
import { ReactComponent as VectorIcon } from '../../../resources/icons/vector.svg';
import { ReactComponent as ChevronIcon } from '../../../resources/icons/chevron_left.svg';
import { useEvidencesData, useLeadershipAttributesData } from '../../../hooks';
import { evidencesConfigByType } from '../../../constants/configs';

const HeaderBlock = React.forwardRef(({ titleVariant, title }, ref) => {
  const { t } = useTranslation();
  return (
    <Box ref={ref} alignItems="center" display="flex">
      <Typography paragraph variant={titleVariant}>
        {t(title)}
      </Typography>
      <Box mb={1}>
        <NewFeatureHotspot id={NewFeaturesIdsMap.LEADERSHIP_ATTRIBUTES} isClickable />
      </Box>
    </Box>
  );
});

HeaderBlock.propTypes = {
  titleVariant: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

const LeadershipProfileSection = ({ isLazyLoad, studentId, titleVariant }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const { state: userState } = useContext(UserContext);
  const { graphData, lazyLoadConfig } = useLeadershipAttributesData(studentId, isLazyLoad);

  const { getLAChipLabel, relatedEvidences } = useEvidencesData(
    studentId,
    evidencesConfigByType.leadership_attribute.type,
  );
  const isGraphAvailable = findCompletedLevels(graphData);

  const isStudent = userState.profile.role === RolesMap.STUDENT;

  const LAEmptyStateBlock = (
    <Grid container>
      <Grid item xs={12}>
        <HeaderBlock
          title={isStudent ? 'My Leadership Attributes' : 'Leadership Attributes'}
          titleVariant={!isStudent && !isGraphAvailable ? 'subtitle1' : titleVariant}
        />
      </Grid>
      <Grid item xs={12}>
        <InitiativeEmptyStateBlock
          body={t('But you can start working on this now!')}
          buttonContent={t('Contribute in Train AYO')}
          buttonGaLabel="Contribute in Train AYO - LA"
          buttonLink={`${trainAyoRoute}/${studentId}${leadershipRoute}`}
          illustration={<EmptyLAIllustration />}
          secondaryButtonContent={
            <>
              {t('Learn more')}
              <Box display="flex" ml={1}>
                <ChevronIcon className="reversed" />
              </Box>
            </>
          }
          secondaryButtonGaLabel="Learn more - LA"
          secondaryButtonLink={leadershipRoute}
          showNavigationButton
          title={t('No Leadership Attributes assessed so far')}
        />
      </Grid>
    </Grid>
  );

  return (
    <Box>
      {isLazyLoad && !lazyLoadConfig?.isDataLoaded ? (
        <>
          <HeaderBlock
            ref={isLazyLoad ? lazyLoadConfig?.loadActivatorRef : null}
            title={isStudent ? 'My Leadership Attributes' : 'Leadership Attributes'}
            titleVariant={titleVariant}
          />
          <SkeletonMask />
        </>
      ) : (
        <Box className="ayo-leadership-attributes">
          {isGraphAvailable ? (
            <>
              <HeaderBlock
                title={isStudent ? 'My Leadership Attributes' : 'Leadership Attributes'}
                titleVariant={titleVariant}
              />
              <Grid container>
                <Grid item xs={12}>
                  <Box
                    alignItems="flex-start"
                    display="flex"
                    flexDirection={isWidthUpSm ? 'row' : 'column-reverse'}
                    justifyContent={isWidthUpSm ? 'space-between' : 'flex-start'}
                    mb={3}
                  >
                    <LearnMoreProfileBlock
                      gaLabel="Learn more about Leadership Attributes"
                      redirectRoute={leadershipRoute}
                    />
                    <Box mb={isWidthUpSm ? 0 : 2}>
                      <Button
                        component={RouterLink}
                        endIcon={<VectorIcon />}
                        gaLabel="Contribute LA in Train AYO"
                        to={`${trainAyoRoute}/${studentId}${leadershipRoute}`}
                      >
                        {t('Contribute in Train AYO')}
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Box mb={3}>
                <Typography variant="subtitle1">{t('Maturity graph')}</Typography>
              </Box>
              <Box
                className="ayo-leadership-attributes__graph-container"
                pb={isWidthUpSm ? 5 : 2}
                pt={isWidthUpSm ? 4 : 2}
                px={isWidthUpSm ? 4 : 2}
              >
                <Grid md={10} sm={8}>
                  <LeadershipAttributesGraph />
                </Grid>
              </Box>
            </>
          ) : (
            LAEmptyStateBlock
          )}
          <Box mt={3}>
            <EvidenceOfGrowthBlock
              evidenceType={evidencesConfigByType.leadership_attribute.type}
              getChipLabelHandler={getLAChipLabel}
              headerLevel={3}
              isLazyLoad={isLazyLoad}
              readOnlyMode
              relatedEvidences={relatedEvidences}
              studentId={studentId}
              titleVariant="subtitle1"
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

LeadershipProfileSection.propTypes = {
  isLazyLoad: PropTypes.bool,
  studentId: PropTypes.number.isRequired,
  titleVariant: PropTypes.string.isRequired,
};

LeadershipProfileSection.defaultProps = {
  isLazyLoad: false,
};

export default LeadershipProfileSection;
