import React, { useReducer, createContext } from 'react';
import PropTypes from 'prop-types';

export const LessonPlannerContext = createContext();

export const LessonPlannerActions = {
  SET_AVAILABLE_COURSES: 'SET_AVAILABLE_COURSES',
  SET_LESSON_PLANNER_DATA: 'SET_LESSON_PLANNER_DATA',
  SET_LESSON_PLANNERS: 'SET_LESSON_PLANNERS',
  ADD_TEACHER_DATE_DATA: 'ADD_TEACHER_DATE_DATA',
  ADD_STUDENT_DATE_DATA: 'ADD_STUDENT_DATE_DATA',
  SET_PICKER_DATES: 'SET_PICKER_DATES',
  SET_LESSONS_METADATA: 'SET_LESSONS_METADATA',
  SET_LESSON_DATA: 'SET_LESSON_DATA',
  SET_TEKS_DATA: 'SET_TEKS_DATA',
  SET_SEMESTERS_DAYS_CONFIG: 'SET_SEMESTERS_DAYS_CONFIG',
  SET_TEACHERS_LIST: 'SET_TEACHERS_LIST',
  SET_AVAILABLE_PERIODS: 'SET_AVAILABLE_PERIODS',
  SET_SCHEDULE_AVAILABLE_PERIODS: 'SET_SCHEDULE_AVAILABLE_PERIODS',
};

const initState = {
  availableCourses: {},
  availablePeriods: {},
  scheduleAvailablePeriods: {},
  lessonPlannerData: null,
  lessonPlanners: null,
  teacherDatesData: {},
  studentDatesData: {},
  pickerDates: {},
  lessonsMetadata: [],
  lessonData: null,
  teksData: null,
  semestersDaysConfig: {},
  teachersList: null,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case LessonPlannerActions.SET_AVAILABLE_COURSES:
      return {
        ...state,
        availableCourses: { ...state.availableCourses, [action.data.campus]: action.data.courses },
      };
    case LessonPlannerActions.SET_AVAILABLE_PERIODS:
      return {
        ...state,
        availablePeriods: {
          ...state.availablePeriods,
          [action.data.campus]: action.data.periods,
        },
      };
    case LessonPlannerActions.SET_SCHEDULE_AVAILABLE_PERIODS:
      return {
        ...state,
        scheduleAvailablePeriods: {
          ...state.scheduleAvailablePeriods,
          [action.data.scheduleId]: action.data.periods,
        },
      };
    case LessonPlannerActions.SET_LESSON_PLANNER_DATA:
      return { ...state, lessonPlannerData: action.data };
    case LessonPlannerActions.SET_LESSON_PLANNERS:
      return { ...state, lessonPlanners: action.data };
    case LessonPlannerActions.ADD_TEACHER_DATE_DATA:
      return {
        ...state,
        teacherDatesData: {
          ...state.teacherDatesData,
          [action.data.plannerId]: {
            ...(state.teacherDatesData[action.data.plannerId] ?? {}),
            ...action.data.teacherDatesData,
          },
        },
      };
    case LessonPlannerActions.ADD_STUDENT_DATE_DATA:
      return {
        ...state,
        studentDatesData: {
          ...state.studentDatesData,
          ...action.data,
        },
      };
    case LessonPlannerActions.SET_PICKER_DATES:
      return {
        ...state,
        pickerDates: action.data.plannerId
          ? {
              ...state.pickerDates,
              [action.data.plannerId]: action.data.dates,
            }
          : {
              ...action.data,
            },
      };
    case LessonPlannerActions.SET_LESSONS_METADATA:
      return {
        ...state,
        lessonsMetadata: action.data,
      };
    case LessonPlannerActions.SET_LESSON_DATA:
      return {
        ...state,
        lessonData: action.data,
      };
    case LessonPlannerActions.SET_TEKS_DATA: {
      return {
        ...state,
        teksData: action.data
          ? {
              ...state.teksData,
              ...action.data,
            }
          : null,
      };
    }
    case LessonPlannerActions.SET_SEMESTERS_DAYS_CONFIG:
      return {
        ...state,
        semestersDaysConfig: action.data,
      };
    case LessonPlannerActions.SET_TEACHERS_LIST:
      return {
        ...state,
        teachersList: action.data,
      };
    default:
      throw new Error();
  }
};

export const LessonPlannerContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initState);

  return (
    <LessonPlannerContext.Provider value={{ state, dispatch }}>
      {children}
    </LessonPlannerContext.Provider>
  );
};

LessonPlannerContextProvider.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
};
