import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

import { ActionsMenu, Card, Typography, Button } from '../../../atoms';
import { Chip, TextWithTooltip } from '../../../moleculas';
import EvidenceOfGrowthAddEditDialog from '../evidence-of-growth-add-dialog/EvidenceOfGrowthAddEditDialog';
import EvidenceOfGrowthViewDialog from '../evidence-of-growt-view-dialog/EvidenceOfGrowthViewDialog';
import { ConfirmDialog } from '../../confirm-dialog/ConfirmDialog';
import { UserContext } from '../../../../context';
import useEvidenceHelpers from '../../../../utils/evidence-helpers/useEvidenceHelpers';
import {
  AttachmentsResourcesTypes,
  InputsValidationRules,
  RolesMap,
} from '../../../../constants/enums';
import {
  evidenceOfGrowthItem,
  goalActionStepItem,
  goalDetailsItem,
} from '../../../../constants/propTypes';
import { GoalsMilestonesStatuses } from '../../../../constants/goals';
import { ReactComponent as AttachIcon } from '../../../../resources/icons/attach_black.svg';
import { ReactComponent as ChevronIcon } from '../../../../resources/icons/chevron_left.svg';
import { ReactComponent as CreateIcon } from '../../../../resources/icons/create.svg';
import { ReactComponent as DeleteIcon } from '../../../../resources/icons/delete_primary.svg';
import { ReactComponent as DescriptionIcon } from '../../../../resources/icons/description.svg';
import { ReactComponent as ExportIcon } from '../../../../resources/icons/export_bold.svg';

const EvidenceOfGrowthCard = ({
  actionSteps,
  chipLabel,
  evidenceOfGrowth,
  evidenceType,
  goalDetails,
  headerLevel,
  isActionButtonsAvailable,
  readOnlyMode,
  studentId,
}) => {
  const { t } = useTranslation();
  const { state: userState } = useContext(UserContext);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isViewDetailesDialogOpen, setIsViewDetailesDialogOpen] = useState(false);
  const isStudent = userState.profile.role === RolesMap.STUDENT;
  const onViewDialogCloseHandler = () => setIsViewDetailesDialogOpen(false);
  const onDeleteDialogCloseHandler = () => setIsDeleteDialogOpen(false);

  const { evidenceDeleteHandler, evidenceChangeHandler } = useEvidenceHelpers(
    studentId,
    evidenceType,
  );

  const onDeleteEvidence = useCallback(
    (id) => {
      evidenceDeleteHandler(id);
      setIsDeleteDialogOpen(false);
    },
    [evidenceDeleteHandler],
  );

  const isGoalCompleted = useMemo(
    () =>
      evidenceType === AttachmentsResourcesTypes.GOAL &&
      goalDetails?.status === GoalsMilestonesStatuses.COMPLETED,
    [evidenceType, goalDetails],
  );

  return (
    <Card
      className="ayo-evidence-of-growth"
      elevation={!readOnlyMode ? 1 : 0}
      mainContent={
        <Box width="100%">
          <Box display="flex" justifyContent="space-between">
            <Chip
              isCaption
              label={chipLabel}
              multiline
              rowsCount={chipLabel.length > InputsValidationRules.MAX_TITLE_LENGTH ? 4 : 6}
            />
            {isStudent && !readOnlyMode && isActionButtonsAvailable && !isGoalCompleted && (
              <Box ml={1}>
                <ActionsMenu
                  id="Evidence actions"
                  menuItems={[
                    {
                      text: t('Edit'),
                      icon: CreateIcon,
                      handler: () => {
                        setIsEditDialogOpen(true);
                      },
                      id: 'edit',
                      gaLabel: 'Edit evidence of growth',
                    },
                    {
                      text: t('Delete'),
                      icon: DeleteIcon,
                      handler: () => {
                        setIsDeleteDialogOpen(true);
                      },
                      id: 'delete',
                      gaLabel: 'Delete Evidence of growth',
                    },
                  ]}
                />
              </Box>
            )}
          </Box>
          <Box my={3}>
            <TextWithTooltip
              headerLevel={headerLevel}
              title={evidenceOfGrowth.title}
              titleVariant="subtitle2"
            />
          </Box>
          {evidenceOfGrowth.reflection && (
            <Box display="flex" pb={1}>
              <Box pr={1}>
                <DescriptionIcon />
              </Box>
              <Typography variant="body2">{t('Notes')}</Typography>
            </Box>
          )}
          {evidenceOfGrowth.link && (
            <Box display="flex" pb={1}>
              <Box pr={1}>
                <ExportIcon />
              </Box>
              <Typography variant="body2">{t('Link')}</Typography>
            </Box>
          )}
          {!!evidenceOfGrowth.attachments.length && (
            <Box display="flex" pb={1}>
              <Box pr={1}>
                <AttachIcon />
              </Box>
              <Typography variant="body2">
                {`${t('Attachments')} (${evidenceOfGrowth.attachments.length})`}
              </Typography>
            </Box>
          )}
          {!readOnlyMode && (
            <Box mt={5}>
              <Button
                gaLabel="View details about Evidence of Growth"
                onClick={() => setIsViewDetailesDialogOpen(true)}
              >
                {t('View details')}
                <Box display="flex" ml={1}>
                  <ChevronIcon className="reversed" />
                </Box>
              </Button>
            </Box>
          )}
          <EvidenceOfGrowthViewDialog
            actionStep={actionSteps?.find(
              (milestone) =>
                milestone.id ===
                Number(
                  evidenceOfGrowth.evidenceResourcesByType[AttachmentsResourcesTypes.GOAL]
                    ?.resourceId,
                ),
            )}
            chipLabel={chipLabel}
            className="ayo-evidence-of-growth"
            evidenceOfGrowth={evidenceOfGrowth}
            evidenceType={evidenceType}
            goalDetails={goalDetails}
            onClose={onViewDialogCloseHandler}
            open={isViewDetailesDialogOpen}
          />
          <ConfirmDialog
            cancelButtonTitle="Do not delete"
            className="ayo-evidence-of-growth"
            confirmButtonTitle="Delete evidence"
            isOpen={isDeleteDialogOpen}
            onClose={onDeleteDialogCloseHandler}
            onConfirm={() => onDeleteEvidence(evidenceOfGrowth.id)}
            text="AYO won’t be able to restore it again(student)"
            title="Should we delete your evidence?"
          />
          <EvidenceOfGrowthAddEditDialog
            actionSteps={actionSteps}
            assessmentKey={
              evidenceOfGrowth.evidenceResourcesByType[
                AttachmentsResourcesTypes.LEADERSHIP_ATTRIBUTE
              ]?.parentResourceId
            }
            chipLabel={chipLabel}
            className="ayo-evidence-of-growth"
            evidenceChangeHandler={evidenceChangeHandler}
            evidenceType={evidenceType}
            goalDetails={goalDetails}
            onClose={() => setIsEditDialogOpen(false)}
            open={isEditDialogOpen}
            title={t('Edit evidence')}
            valuesToEdit={evidenceOfGrowth}
          />
        </Box>
      }
    />
  );
};

EvidenceOfGrowthCard.propTypes = {
  actionSteps: PropTypes.arrayOf(goalActionStepItem),
  chipLabel: PropTypes.string,
  evidenceOfGrowth: evidenceOfGrowthItem,
  evidenceType: PropTypes.string,
  goalDetails: goalDetailsItem,
  headerLevel: PropTypes.number,
  isActionButtonsAvailable: PropTypes.bool,
  readOnlyMode: PropTypes.bool,
  studentId: PropTypes.number.isRequired,
};

EvidenceOfGrowthCard.defaultProps = {
  actionSteps: null,
  chipLabel: '',
  evidenceOfGrowth: null,
  evidenceType: '',
  goalDetails: null,
  headerLevel: 3,
  isActionButtonsAvailable: true,
  readOnlyMode: true,
};

export default EvidenceOfGrowthCard;
