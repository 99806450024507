import React, { useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { useLocation } from 'react-router-dom';

import DisplayAssignmentDeadlines from '../../display-components/display-assignment-deadlines/DisplayAssignmentDeadlines';
import DisplayAssignmentTitle from '../../display-components/display-assignment-title/DisplayAssignmentTitle';
import GoogleClassroomLink from '../../display-components/google-classroom-link/GoogleClassroomLink';
import DisplayAssignmentStudents from '../../display-components/display-assignment-students/DisplayAssignmentStudents';
import DisplayAssignmentMaterials from '../../display-components/display-assignment-materials/DisplayAssignmentMaterials';
import { staticAssignmentType } from '../../../../../../constants/propTypes';
import DisplayAssignmentChoicesPath from '../../display-components/display-assignment-choices-path/DisplayAssignmentChoicesPath';
import { assignmentHash } from '../../../../../../constants/routes';

const AnnouncementStaticAssignment = ({
  assignment,
  courseStudents,
  choices,
  isOwner,
  onChoicesChange,
  onChoicesSelectedChange,
  canSelectChoices,
  hideAssignedStudents,
}) => {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash?.includes(assignmentHash)) {
      const assignmentElement = document.getElementById(`${hash.replace('#', '')}`);
      if (assignmentElement) {
        assignmentElement.scrollIntoView();
      }
    }
  }, [hash]);

  return (
    <Grid container id={`${assignmentHash.replace('#', '')}-${assignment.id}`}>
      <Grid item xs={12}>
        <Grid item xs={8}>
          <Box mb={1}>
            <DisplayAssignmentTitle title={assignment.description} />
          </Box>
          <Box mb={3}>
            <GoogleClassroomLink href={assignment.classroomLink} />
          </Box>
          {assignment.postingDate && (
            <Box mb={3}>
              <DisplayAssignmentDeadlines
                deadlines={[{ title: 'Posted in Google Classroom', value: assignment.postingDate }]}
              />
            </Box>
          )}
        </Grid>
      </Grid>
      <Grid container direction="row" item justifyContent="space-between" xs={12}>
        <Grid item xs={7}>
          {!hideAssignedStudents && (
            <Box>
              <DisplayAssignmentStudents
                assignedCount={assignment.assignedStudents}
                isOwner={isOwner}
                students={courseStudents?.filter((student) =>
                  assignment.assignedStudentsEmails.includes(student.email),
                )}
              />
            </Box>
          )}
        </Grid>
        <Grid item xs={4}>
          <DisplayAssignmentMaterials
            attachments={assignment.attachments}
            links={assignment.links}
          />
        </Grid>
        <Grid xs={12}>
          <DisplayAssignmentChoicesPath
            assignmentEndDate={assignment.submissionDeadline}
            assignmentId={assignment.id}
            canSelectChoices={canSelectChoices}
            choices={choices}
            onChoicesChange={onChoicesChange}
            onChoicesSelectedChange={onChoicesSelectedChange}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

AnnouncementStaticAssignment.propTypes = staticAssignmentType;

export default AnnouncementStaticAssignment;
