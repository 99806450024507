import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { usePromiseTracker } from 'react-promise-tracker';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { AppContext } from '../../../context';
import { Typography } from '../../atoms';
import loadingImg from '../../../resources/icons/loading.gif';

const Loader = ({ className, isGlobal, isLoading, labelText, typographyVariant }) => {
  const { state } = useContext(AppContext);
  const { promiseInProgress } = usePromiseTracker();
  const { t } = useTranslation();

  const isVisible = isGlobal ? promiseInProgress : isLoading;

  return isVisible ? (
    <div
      className={classNames('ayo-simple-loader', className, {
        'ayo-simple-loader--global': isGlobal,
      })}
    >
      <Typography role="alert" variant={typographyVariant}>
        {state.loaderConfig?.loaderText || t(labelText)}
      </Typography>
      <img alt={t('Loading')} src={loadingImg} />
    </div>
  ) : null;
};

Loader.propTypes = {
  className: PropTypes.string,
  isGlobal: PropTypes.bool,
  isLoading: PropTypes.bool,
  labelText: PropTypes.string,
  typographyVariant: PropTypes.string,
};

Loader.defaultProps = {
  className: '',
  isGlobal: false,
  isLoading: false,
  labelText: 'Please wait a moment',
  typographyVariant: 'body1',
};

export default Loader;
