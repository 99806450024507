import React, { useReducer, createContext } from 'react';
import PropTypes from 'prop-types';

export const TourContext = createContext();

export const TourActions = {
  SET_FORCE_TOUR_OPEN: 'FORCE_TOUR_OPEN',
  SET_TOUR_STATUS: 'SET_TOUR_STATUS',
  ADD_SEEN_NEW_FEATURES: 'ADD_SEEN_NEW_FEATURES',
  SET_NEW_FEATURES_TOUR_STATE: 'SET_NEW_FEATURES_TOUR_STATE',
};

const initState = {
  forceTourOpen: null,
  toursStatus: null,
  newFeaturesSeen: [],
  isNewFeaturesTourOpened: null,
  newFeaturesFocusId: null,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case TourActions.SET_FORCE_TOUR_OPEN:
      return { ...state, forceTourOpen: action.data };
    case TourActions.SET_TOUR_STATUS:
      return {
        ...state,
        toursStatus: action.data,
        newFeaturesSeen: action.data?.['new-features']?.seen || [],
      };
    case TourActions.ADD_SEEN_NEW_FEATURES: {
      const newSeenItems = [...state.newFeaturesSeen, ...action.data];
      return {
        ...state,
        newFeaturesSeen: newSeenItems,
        toursStatus: { ...state.toursStatus, 'new-features': { seen: newSeenItems } },
      };
    }
    case TourActions.SET_NEW_FEATURES_TOUR_STATE:
      return {
        ...state,
        isNewFeaturesTourOpened: action.data.isNewFeaturesTourOpened,
        newFeaturesFocusId: action.data.newFeaturesFocusId,
      };
    default:
      throw new Error();
  }
};

export const TourContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initState);

  return <TourContext.Provider value={{ state, dispatch }}>{children}</TourContext.Provider>;
};

TourContextProvider.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
};
