const getEmoji = (symbol, label) => ({ symbol, ariaLabel: label });

export const booksEmoji = getEmoji('📚', 'Books');
export const televisionEmoji = getEmoji('📺', 'Television');
export const movieCameraEmoji = getEmoji('🎥', 'Movie camera');
export const musicalNoteEmoji = getEmoji('🎵', 'Musical note');
export const performingArtsEmoji = getEmoji('🎭', 'Performing arts');
export const artistPaletteEmoji = getEmoji('🎨', 'Artist palette');
export const cookingEmoji = getEmoji('🍳', 'Cooking');
export const gameDieEmoji = getEmoji('🎲', 'Game die');
export const videoGameEmoji = getEmoji('🎮', 'Video game');
export const campingEmoji = getEmoji('🏕️', 'Camping');
export const joystickAndSmallAirplaneEmoji = getEmoji('🕹️🛩️', 'Joystick and small airplane');
export const soccerBallEmoji = getEmoji('⚽', 'Soccer ball');
export const dogEmoji = getEmoji('🐕', 'Dog');
export const inputLatinLettersEmoji = getEmoji('🔤', 'Input latin letters');
export const thinkingFaceEmoji = getEmoji('🤔', 'Thinking face');
export const graduationCapEmoji = getEmoji('🎓', 'Graduation cap');
export const personEmoji = getEmoji('🧑', 'Person');
export const crownEmoji = getEmoji('👑', 'Crown');
export const superheroEmoji = getEmoji('🦸', 'Superhero');
export const superheroAndThoughtBalloonEmoji = getEmoji('🦸💭', 'Superhero and thought balloon');
export const superheroJapaneseCastleEmoji = getEmoji('🦸🏯', 'Superhero Japanese castle');
export const superheroAndTelevisionEmoji = getEmoji('🦸📺', 'Superhero and television');
export const manSingerMSTEmoji = getEmoji('👨🏽‍🎤', 'Man Singer Medium Skin Tone');
export const filmFramesAndTelevisionEmoji = getEmoji('🎞️📺', 'Film frames and television');
export const studioMicrophoneEmoji = getEmoji('🎙️', 'Studio microphone');
export const partyPopperEmoji = getEmoji('🎉', 'Party popper');
export const cocktailBarAndManDancingEmoji = getEmoji('🍸🕺', 'Cocktail bar and man dancing');
export const guitarEmoji = getEmoji('🎸', 'Guitar');
export const briefcaseEmoji = getEmoji('💼', 'Briefcase');
export const filmFramesEmoji = getEmoji('🎞️', 'Film frames');
export const cinemaEmoji = getEmoji('🎦', 'Cinema');
export const puzzlePieceAndThinkingFaceEmoji = getEmoji('🧩🤔', 'Puzzle piece and thinking face');
export const highVoltageEmoji = getEmoji('⚡', 'High voltage');
export const squintingFaceWithTongueAndMemoEmoji = getEmoji(
  '😝 📝',
  'Squinting face with tongue and memo',
);
export const rollingOnTheFloorLaughingEmoji = getEmoji('🤣', '');
export const pictureAndVideoCameraEmoji = getEmoji('🖼️📹', 'Picture and Video camera');
export const rollingOnFloorLaughingEmoji = getEmoji('🤣', 'Rolling on the floor laughing');
export const opticalDiskEmoji = getEmoji('💿', 'Optical disk');
export const violinEmoji = getEmoji('🎻', 'Violin');
export const controlKnobsAndWomanDancingEmoji = getEmoji('🎛️💃', 'Control knobs and woman dancing');
export const levelSliderEmoji = getEmoji('🎚️', 'Level slider');
export const saxophoneEmoji = getEmoji('🎷', 'Saxophone');
export const graduationCapAndMusicalNoteEmoji = getEmoji(
  '🎓 🎵',
  'Graduation cap and musical note',
);
export const controlKnobsEmoji = getEmoji('🎛️', 'Control knobs');
export const speakerHighVolumeEmoji = getEmoji('🔊', 'Speaker high volume');
export const musicalNoteAndFilmFramesEmoji = getEmoji('🎵🎞️', 'Musical note and film frames');
export const singerEmoji = getEmoji('🧑‍🎤', 'Singer');
export const radioEmoji = getEmoji('📻', 'Radio');
export const churchAndMusicalNoteEmoji = getEmoji('⛪🎵', 'Church and musical note');
export const signOfTheHornsEmoji = getEmoji('🤘', 'Sign of the horns');
export const musicalNoteAndCrownEmoji = getEmoji('🎵👑', 'Musical note and crown');
export const musicalNoteAndGlobeWithMeridiansEmoji = getEmoji(
  '🎵🌐',
  'Musical note and globe with meridians',
);
export const ghostEmoji = getEmoji('👻', 'Ghost');
export const newspaperAndGlobeWithMeridiansEmoji = getEmoji(
  '📰🌐',
  'Newspaper and globe with meridians',
);
export const framedPictureAndGlobeWithMeridiansEmoji = getEmoji(
  '🖼️🌐',
  'Framed picture and globe with meridians',
);
export const circusTentEmoji = getEmoji('🎪', 'Circus tent');
export const womanDancingEmoji = getEmoji('💃', 'Woman dancing');
export const elfEmoji = getEmoji('🧝', 'Elf');
export const performingArtsAndMisocalNoteEmoji = getEmoji(
  '🎭🎵',
  'Performing arts and misocal note',
);
export const videoCameraAndGlobeWithMeridiansEmoji = getEmoji(
  '📹 🌐',
  'Video camera and globe with meridians',
);
export const houseEmoji = getEmoji('🏠', 'House');
export const framesPictureAndCameraEmoji = getEmoji('🖼️📷', 'Frames picture and camera');
export const automobileEmoji = getEmoji('🚗', 'Automobile');
export const bicycleEmoji = getEmoji('🚲', 'Bicycle');
export const bicycleAndWrenchEmoji = getEmoji('🚲🔧', 'Bicycle and wrench');
export const personRowingBoatEmoji = getEmoji('🚣', 'Person rowing boat');
export const motorBoatEmoji = getEmoji('🛥️', 'Motor boat');
export const deliveryTruckEmoji = getEmoji('🚚', 'Delivery truck');
export const automobileAndElectricPlugEmoji = getEmoji('🚗🔌', 'Automobile and electric plug');
export const automobileAndMountainEmoji = getEmoji('🚗⛰️', 'Automobile and mountain');
export const stopSignAndWarningEmoji = getEmoji('🛑⚠️', 'Stop sign and warning');
export const automobileAndMemoEmoji = getEmoji('🚗📝', 'Automobile and memo');
export const automobileAndGearEmoji = getEmoji('🚗⚙️', 'Automobile and gear');
export const fuelPumpEmoji = getEmoji('⛽', 'Fuel pump');
export const automobileAndHammerAndWrenchEmoji = getEmoji(
  '🚗🛠️',
  'Automobile and hammer and wrench',
);
export const automobileAndPriceTagEmoji = getEmoji('🚗🏷️', 'Automobile and price tag');
export const automobileAndChartDecreasingEmoji = getEmoji(
  '🚗📉',
  'Automobile and chart decreasing',
);
export const automobileAndRacingCarEmoji = getEmoji('🚗🏎️', 'Automobile and racing car');
export const nailPolishAndLipstickEmoji = getEmoji('💅💄', 'Nail polish and lipstick');
export const womanAndCrownEmoji = getEmoji('👩👑', 'Woman and crown');
export const flexedBicepsAndPaintbrushEmoji = getEmoji('💪🖌️', 'Flexed biceps and paintbrush');
export const personGettingMassageEmoji = getEmoji('💆', 'Person getting massage');
export const personAndSyringeEmoji = getEmoji('🧑💉', 'Person and syringe');
export const personAndSparklesEmoji = getEmoji('🧑✨', 'Person and sparkles');
export const soapAndLotionBottleEmoji = getEmoji('🧼🧴', 'Soap and lotion bottle');
export const lipstickEmoji = getEmoji('💄', 'Lipstick');
export const noseAndBouquetEmoji = getEmoji('👃💐', 'Nose and bouquet');
export const nailPolishEmoji = getEmoji('💅', 'Nail polish');
export const barberPoleEmoji = getEmoji('💈', 'Barber pole');
export const highHeeledShoeAndNecktieEmoji = getEmoji('👠👔', 'High-heeled shoe and necktie');
export const dressAndSparklesEmoji = getEmoji('👗✨', 'Dress and sparkles');
export const flexedBicepsEmoji = getEmoji('💪', 'Flexed biceps');
export const personCurlyHairEmoji = getEmoji('🧑‍🦱', 'Person curly hair');
export const personBaldEmoji = getEmoji('🧑‍🦲', 'Person bald');
export const womanInSteamyRoomEmoji = getEmoji('🧖‍♀️', 'Woman in Steamy Room');
export const womanRunningAndGreenSaladEmoji = getEmoji('🏃‍♀️🥗', 'Woman running and green salad');
export const booksAndChildEmoji = getEmoji('📚🧒', 'Books and child');
export const booksAndLaptopEmoji = getEmoji('📚💻', 'Books and laptop');
export const princessAndRollingOnTheFloorLaughingEmoji = getEmoji(
  '👸🤣',
  'Princess and Rolling on the floor laughing',
);
export const booksAndWritingHandEmoji = getEmoji('📚 ✍️', 'Books and writing hand');
export const briefcaseAndFactoryEmoji = getEmoji('💼🏭', 'Briefcase and factory');
export const newspaperAndLoudspeakerEmoji = getEmoji('📰📢', 'Newspaper and loudspeaker');
export const rocketEmoji = getEmoji('🚀', 'Rocket');
export const farmerEmoji = getEmoji('🧑‍🌾', 'Farmer');
export const tractorEmoji = getEmoji('🚜', 'Tractor');
export const evergreenTreeEmoji = getEmoji('🌲', 'Evergreen tree');
export const cowFaceAndPigFaceEmoji = getEmoji('🐮🐷', 'Cow face and pig face');
export const automobileAndFactoryEmoji = getEmoji('🚗🏭', 'Automobile and factory');
export const briefcaseAndGraduationCapEmoji = getEmoji('💼🎓', 'Briefcase and graduation cap');
export const briefcaseAndDollarBanknoteEmoji = getEmoji('💼💵', 'Briefcase and dollar banknote');
export const moneyBagAndLightBulbEmoji = getEmoji('💰💡', 'Money bag and light bulb');
export const briefcaseAndGearEmoji = getEmoji('💼⚙️', 'Briefcase and gear');
export const briefcaseAndPageFacingUpEmoji = getEmoji('💼📄', 'Briefcase and page facing up');
export const officeWorkerEmoji = getEmoji('🧑‍💼', 'Office worker');
export const briefcaseAndHandshakeEmoji = getEmoji('💼🤝', 'Briefcase and handshake');
export const directHitAndHandshakeEmoji = getEmoji('🎯🤝', 'Direct hit and handshake');
export const necktieAndCocktailGlassEmoji = getEmoji('👔🍸', 'Necktie and cocktail glass');
export const shoppingCartAndMeridiansEmoji = getEmoji('🛒🌐', 'Shopping cart and meridians');
export const fireExtinguisherAndPoliceOficerEmoji = getEmoji(
  '🧯👮',
  'Fire Extinguisher and police officer',
);
export const officeBuildingEmoji = getEmoji('🏢', 'Office building');
export const paperClipAndFileFolderEmoji = getEmoji('📎📁', 'Paper clip and file folder');
export const writingHandAndMemoEmoji = getEmoji('✍️📝', 'Writing hand and memo');
export const testTubeAndMicroscopeEmoji = getEmoji('🧪🔬', 'Test tube and microscope');
export const soapAndTestTubeEmoji = getEmoji('🧼🧪', 'Soap and test tube');
export const cupWithStrawEmoji = getEmoji('🥤', 'Cup with a straw');
export const buildingConstructionEmoji = getEmoji('🏗️', 'Building construction');
export const brickEmoji = getEmoji('🧱', 'Brick');
export const lightBulbAndElectricPlugEmoji = getEmoji('💡🔌', 'Light bulb and electric plug');
export const sunAndBatteryEmoji = getEmoji('☀️🔋', 'Sun and battery');
export const manCookAndHotelEmoji = getEmoji('👨‍🍳🏨', 'Man cook and hotel');
export const partyPopperAndMemoEmoji = getEmoji('🎉📝', 'Party popper and memo');
export const manCookEmoji = getEmoji('👨‍🍳', 'Man cook');
export const buildingConstructionAndHammerAndWrenchEmoji = getEmoji(
  '🏗️🛠️',
  'Building construction and hammer and wrench',
);
export const factoryEmoji = getEmoji('🏭', 'Factory');
export const pickEmoji = getEmoji('⛏️', 'Pick');
export const pageFacingUpAndPrinterEmoji = getEmoji('📄🖨️', 'Page facing up and printer');
export const shoppingBagsAndLaptopEmoji = getEmoji('🛍️💻', 'Shopping bags and laptop');
export const briefcaseAndTelescopeEmoji = getEmoji('💼🔭', 'Briefcase and telescope');
export const threadEmoji = getEmoji('🧵', 'Thread');
export const shipAndLightTrainEmoji = getEmoji('🚢🚈', 'Ship and light train');
export const postboxAndPackageEmoji = getEmoji('📮📦', 'Postbox and package');
export const shipEmoji = getEmoji('🚢', 'Ship');
export const deliveryTruckAndPackageEmoji = getEmoji('🚚📦', 'Delivery truck and package');
export const pButtonEmoji = getEmoji('🅿️', 'P button');
export const lightRailEmoji = getEmoji('🚈', 'Light rail');
export const tramAndOncomingBusEmoji = getEmoji('🚊🚍', 'Tram and oncoming bus');
export const desktopComputerAndMobilePhoneEmoji = getEmoji(
  '🖥️📱',
  'Desktop computer and mobile phone',
);
export const desktopComputerAndTriangularRulerEmoji = getEmoji(
  '🖥️📐',
  'Desktop computer and triangular ruler',
);
export const computerMouseAndPrinterEmoji = getEmoji('🖱️🖨️', 'Computer mouse and printer');
export const desktopComputerAndComputerDiskEmoji = getEmoji(
  '🖥️💽',
  'Desktop computer and computer disk',
);
export const headphonesAndKeyboardEmoji = getEmoji('🎧⌨️', 'Headphones and keyboard');
export const desktopComputerEmoji = getEmoji('🖥️', 'Desktop computer');
export const desktopComputerLockedWithTheKeyEmoji = getEmoji(
  '💻🔐',
  'Desktop computer locked with the key',
);
export const unlockedAndWarningEmoji = getEmoji('🔓⚠️', 'Unlocked and warning');
export const printerAndMobilePhoneEmoji = getEmoji('🖨️📱', 'Printer and mobile phone');
export const cameraEmoji = getEmoji('📷', 'Camera');
export const automobileAndSpeakerHighVolumeEmoji = getEmoji(
  '🚗🔊',
  'Automobile and speaker high volume',
);
export const smallAirplaneAndJoystickEmoji = getEmoji('🛩️🕹️', 'Small airplane and joystick');
export const videogameEmoji = getEmoji('🎮', 'Videogame');
export const satelliteAndMobilePhoneEmoji = getEmoji('🛰️📱', 'Satellite and mobile phone');
export const cameraAndTelevisionEmoji = getEmoji('📷📺', 'Camera and television');
export const electricPlugEmoji = getEmoji('🔌', 'Electric plug');
export const tRexEmoji = getEmoji('🦖', 'T-Rex');
export const laptopAndBarChartEmoji = getEmoji('💻📊', 'Laptop and bar chart');
export const laptopAndShakingHandsEmoji = getEmoji('💻🤝', 'Laptop and shaking hands');
export const globeWithMeridiansAndShieldEmoji = getEmoji('🌐🛡️', 'Globe with meridians and shield');
export const briefcaseAndManTechnologistEmoji = getEmoji('💼👨‍💻', 'Briefcase and man technologist');
export const laptopAndGearEmoji = getEmoji('💻⚙️', 'Laptop and gear');
export const globeWithMeridiansAndManTechnologistEmoji = getEmoji(
  '🌐👨‍💻',
  'Globe with meridians and man technologist',
);
export const musicalNoteAndManTechnologistEmoji = getEmoji(
  '🎵👨‍💻',
  'Musical note and man technologist',
);
export const lockedWithKeyAndManTechnologistEmoji = getEmoji(
  '🔐👨‍💻',
  'Locked with key and man technologist',
);
export const dollarBanknoteEmoji = getEmoji('💵', 'Dollar banknote');
export const abacusAndBarChartEmoji = getEmoji('🧮📊', 'Abacus and bar chart');
export const receiptEmoji = getEmoji('🧾', 'Receipt');
export const receiptAndBarChartEmoji = getEmoji('🧾📊', 'Receipt and bar chart');
export const bankEmoji = getEmoji('🏦', 'Bank');
export const creditCardEmoji = getEmoji('💳', 'Credit card');
export const creditCardAndBarChartEmoji = getEmoji('💳📊', 'Credit card and bar chart');
export const foldedHandAndMoneyBagEmoji = getEmoji('🙏💰', 'Folded hand and money bag');
export const dollarBanknoteAndBarChartEmoji = getEmoji('💵📊', 'Dollar banknote and bar chart');
export const olderPersonMDSTAndDollarBanknoteEmoji = getEmoji(
  '🧓🏾💵',
  'Older Person Medium-Dark Skin Tone and dollar banknote',
);
export const graduationCapAndDollarBanknoteEmoji = getEmoji(
  '🎓💵',
  'Graduation cap and dollar banknote',
);
export const memoAndDollarBanknoteEmoji = getEmoji('📝💵', 'Memo and dollar banknote');
export const medicalSymbolAndDollarBanknoteEmoji = getEmoji(
  '⚕️💵',
  'Medical Symbol and dollar banknote',
);
export const chartIncreasingAndDollarBanknoteEmoji = getEmoji(
  '📈💵',
  'Chart increasing and dollar banknote',
);
export const currencyExchangeEmoji = getEmoji('💱', 'Currency exchange');
export const chartIncreasingAndChartDecreasingEmoji = getEmoji(
  '📈📉',
  'Chart increasing and chart decreasing',
);
export const hamburgerAndCupWithStrawEmoji = getEmoji('🍔🥤', 'Hamburger and Cup with Straw');
export const glassOfMilkEmoji = getEmoji('🥛', 'Glass of Milk');
export const cocktailGlassEmoji = getEmoji('🍸', 'Cocktail Glass');
export const hotBeverageEmoji = getEmoji('☕', 'Hot Beverage');
export const redAppleAndCupwithStrawEmoji = getEmoji('🍎🥤', 'Red Apple and Cup with Straw');
export const manCookLSTEmoji = getEmoji('👨🏻‍🍳', 'Man Cook Light Skin Tone');
export const cutOfMeatEmoji = getEmoji('🥩', 'Cut of Meat');
export const iceCreamEmoji = getEmoji('🍨', 'Ice Cream');
export const potOfFoodEmoji = getEmoji('🍲', 'Pot of Food');
export const hamburgerEmoji = getEmoji('🍔', 'Hamburger');
export const breadEmoji = getEmoji('🍞', 'Bread');
export const pancakesEmoji = getEmoji('🥞', 'Pancakes');
export const lollipopEmoji = getEmoji('🍭', 'Lollipop');
export const spaghettiEmoji = getEmoji('🍝', 'Spaghetti');
export const poultryLegAndFriedShrimpEmoji = getEmoji('🍗🍤', 'Poultry Leg and Fried Shrimp');
export const cookieEmoji = getEmoji('🍪', 'Cookie');
export const frenchFriesEmoji = getEmoji('🍟', 'French Fries');
export const pizzaEmoji = getEmoji('🍕', 'Pizza');
export const thumbsUpEmoji = getEmoji('👍', 'Thumbs Up');
export const flyingDiscEmoji = getEmoji('🥏', 'Flying Disc');
export const worldMapAndGameDieEmoji = getEmoji('🗺️🎲', 'World Map and Game Die');
export const chessPawnEmoji = getEmoji('♟️', 'Chess Pawn');
export const castleEmoji = getEmoji('🏰', 'Castle');
export const jokerEmoji = getEmoji('🃏', 'Joker');
export const slotMachineEmoji = getEmoji('🎰', 'Slot Machine');
export const slightlySmilingFaceAndVideoGameEmoji = getEmoji(
  '🎮🙂',
  'Slightly Smiling Face and Video Game',
);
export const videoGameAndChequeredFlagEmoji = getEmoji('🎮🏁', 'Video Game and Chequered Flag');
export const videoGameAndCrossedSwordsEmoji = getEmoji('🎮⚔️', 'Video Game and Crossed Swords');
export const videoGameAndMusicalNoteEmoji = getEmoji('🎮🎵', 'Video Game and Musical Note');
export const videoGameAndGlobeShowingAmericasEmoji = getEmoji(
  '🎮🌎',
  'Video Game and Globe Showing Americas',
);
export const videoGameAndPistolEmoji = getEmoji('🎮🔫', 'Video Game and Pistol');
export const videoGameAndAirplaneEmoji = getEmoji('🎮✈️', 'Video Game and Airplane');
export const videoGameAndSoccerBallEmoji = getEmoji('🎮⚽', 'Video Game and Soccer Ball');
export const videoGameAndThinkingFaceEmoji = getEmoji('🎮🤔', 'Video Game and Thinking Face');
export const videoGameAndDesktopComputerEmoji = getEmoji('🎮🖥️', 'Video Game and Desktop Computer');
export const topHatEmoji = getEmoji('🎩', 'Top Hat');
export const moneyWithWingsEmoji = getEmoji('💸', 'Money with Wings');
export const familyManWomanBoyBoyEmoji = getEmoji('👨‍👩‍👦‍👦', 'Family Man, Woman, Boy, Boy');
export const puzzlePieceEmoji = getEmoji('🧩', 'Puzzle Piece');
export const fairyEmoji = getEmoji('🧚', 'Fairy');
export const pingPongEmoji = getEmoji('🏓', 'Ping Pong');
export const pool8BallEmoji = getEmoji('🎱', 'Pool 8 Ball');
export const inputLatinUppercaseEmoji = getEmoji('🔠', 'Input Latin Uppercase');
export const oldManMLSTAndOldWomanLSTEmoji = getEmoji(
  '👴🏽👵🏻',
  'Old Man Medium-Light Skin Tone and Old Woman Light Skin Tone',
);
export const faceWithThermometerEmoji = getEmoji('🤒', 'Face with Thermometer');
export const bacteriaEmoji = getEmoji('🦠', 'Bacteria');
export const sneezingFaceEmoji = getEmoji('🤧', 'Sneezing Face');
export const legEmoji = getEmoji('🦵', 'Leg');
export const syringeAndCandyEmoji = getEmoji('💉🍬', 'Syringe and Candy');
export const earMDSTAndNoseMDSTEmoji = getEmoji(
  '👂🏾👃🏾',
  'Ear Medium-Dark Skin Tone and Nose Medium-Dark Skin Tone',
);
export const nauseatedFaceEmoji = getEmoji('🤢', 'Nauseated Face');
export const woozyFaceEmoji = getEmoji('🥴', 'Woozy Face');
export const microbeEmoji = getEmoji('🦠', 'Microbe');
export const faceWithHeadBandageAndLevelSliderEmoji = getEmoji(
  '🤕🎚️',
  'Face with Head-Bandage and Level Slider',
);
export const faceExhalingEmoji = getEmoji('😮‍💨', 'Face Exhaling');
export const zombieEmoji = getEmoji('🧟', 'Zombie');
export const sleepingFaceEmoji = getEmoji('😴', 'Sleeping Face');
export const womanHealthWorkerMSTAndGraduationCapEmoji = getEmoji(
  '👩🏽‍⚕️🎓',
  'Woman Health Worker Medium Skin Tone and Graduation Cap',
);
export const ambulanceAndHospitalEmoji = getEmoji('🚑🏥', 'Ambulance and Hospital');
export const hospitalAndManHealthWorkerEmoji = getEmoji('🏥👨‍⚕️', 'Hospital and Man Health Worker');
export const hospitalEmoji = getEmoji('🏥', 'Hospital');
export const manGettingMassageMDSTEmoji = getEmoji(
  '💆🏾‍♂️',
  'Man Getting Massage Medium-Dark Skin Tone',
);
export const maleSignEmoji = getEmoji('♂️', 'Male Sign');
export const sunWithFaceEmoji = getEmoji('🌞', 'Sun with Face');
export const worriedFaceEmoji = getEmoji('😟', 'Worried Face');
export const disappointedFaceEmoji = getEmoji('😞', 'Disappointed Face');
export const olderPersonMLSTEmoji = getEmoji('🧓🏼', 'Older Person Medium-Light Skin Tone');
export const huggingFaceAndManHealthWorkerEmoji = getEmoji(
  '🤗👨‍⚕️',
  'Hugging face and Man Health Worker',
);
export const bentoBoxEmoji = getEmoji('🍱', 'Bento Box');
export const broccoliEmoji = getEmoji('🥦', 'Broccoli');
export const pillEmoji = getEmoji('💊', 'Pill');
export const toothEmoji = getEmoji('🦷', 'Tooth');
export const alembicEmoji = getEmoji('⚗️', 'Alembic');
export const syringeEmoji = getEmoji('💉', 'Syringe');
export const faceWithMedicalMaskEmoji = getEmoji('😷', 'Face with Medical Mask');
export const maleSignFemaleSignEmoji = getEmoji('♂️♀️', 'Male Sign Female Sign');
export const dizzyFaceEmoji = getEmoji('😵', 'Dizzy Face');
export const wineGlassAndExclamationMarkEmoji = getEmoji('🍷❗', 'Wine Glass and Exclamation Mark');
export const wineGlassAndMedicalSymbolEmoji = getEmoji('🍷⚕️', 'Wine Glass and Medical Symbol');
export const pillAndFlexedBicepsEmoji = getEmoji('💊💪', 'Pill and Flexed Biceps');
export const eyeEmoji = getEmoji('👁️', 'Eye');
export const eyesEmoji = getEmoji('👀', 'Eyes');
export const femaleSignEmoji = getEmoji('♀️', 'Female Sign');
export const directHitEmoji = getEmoji('🎯', 'Direct Hit');
export const handshakeEmoji = getEmoji('🤝', 'Handshake');
export const childAndHandshakeEmoji = getEmoji('🧒🤝', 'Child and Handshake');
export const yarnEmoji = getEmoji('🧶', 'Yarn');
export const threadAndScissorsEmoji = getEmoji('🧵✂️', 'Thread and Scissors');
export const rosetteEmoji = getEmoji('🏵️', 'Rosette');
export const fishingPoleEmoji = getEmoji('🎣', 'Fishing Pole');
export const pistolEmoji = getEmoji('🔫', 'Pistol');
export const locomotiveEmoji = getEmoji('🚂', 'Locomotive');
export const sparklesEmoji = getEmoji('✨', 'Sparkles');
export const christmasTreeEmoji = getEmoji('🎄', 'Christmas Tree');
export const weddingEmoji = getEmoji('💒', 'Wedding');
export const waterWaveEmoji = getEmoji('🌊', 'Water Wave');
export const personRowingBoatMSTEmoji = getEmoji('🚣🏽', 'Person Rowing Boat Medium Skin Tone');
export const womanSwimmingDSTAndWomanSurfingEmoji = getEmoji(
  '🏊🏿‍♀️🏄‍♀️',
  'Woman Swimming Dark Skin Tone and Woman Surfing',
);
export const bedAndShowerEmoji = getEmoji('🛏️🚿', 'Bed and Shower');
export const bathtubEmoji = getEmoji('🛁', 'Bathtub');
export const houseAndHammerEmoji = getEmoji('🏠🔨', 'House and Hammer');
export const broomAndSoapEmoji = getEmoji('🧹🧼', 'Broom and Soap');
export const framedPictureEmoji = getEmoji('🖼️', 'Framed Picture');
export const lightBulbEmoji = getEmoji('💡', 'Light Bulb');
export const couchAndLampEmoji = getEmoji('🛋️', 'Couch and Lamp');
export const houseAndSparklesEmoji = getEmoji('🏠✨', 'House and Sparkles');
export const gearAndElectricPlugEmoji = getEmoji('⚙️🔌', 'Gear and Electric Plug');
export const footprintsEmoji = getEmoji('👣', 'Footprints');
export const paintbrushEmoji = getEmoji('🖌️', 'Paintbrush');
export const toiletAndWrenchEmoji = getEmoji('🚽🔧', 'Toilet and Wrench');
export const lockedEmoji = getEmoji('🔒', 'Locked');
export const packageEmoji = getEmoji('📦', 'Package');
export const waterwaveAndWomanInSteamyRoomEmoji = getEmoji(
  '🌊🧖‍♀️',
  'Waterwave and Woman in Steamy Room',
);
export const thermometerAndControlKnobsEmoji = getEmoji('🌡️🎛️', 'Thermometer and Control Knobs');
export const fireEmoji = getEmoji('🔥', 'Fire');
export const forkAndKnifeWithPlateEmoji = getEmoji('🍽️', 'Fork and Knife with Plate');
export const waffleEmoji = getEmoji('🧇', 'Waffle');
export const basketEmoji = getEmoji('🧺', 'Basket');
export const cycloneEmoji = getEmoji('🌀', 'Cyclone');
export const babyMDSTEmoji = getEmoji('👶🏾', 'Baby Medium-Dark Skin Tone');
export const antAndProhibitedEmoji = getEmoji('🐜🚫', 'Ant and Prohibited');
export const umbrellaOnGroundEmoji = getEmoji('⛱️', 'Umbrella on Ground');
export const herbAndScissorsEmoji = getEmoji('🌿✂️', 'Herb and Scissors');
export const eMailEmoji = getEmoji('📧', 'E-Mail');
export const pagerEmoji = getEmoji('📟', 'Pager');
export const selfieDSTAndSpeechBalloonEmoji = getEmoji(
  '🤳🏿💬',
  'Selfie Dark Skin Tone and Speech Balloon',
);
export const antennaBarsEmoji = getEmoji('📶', 'Antenna Bars');
export const antennaBarsAndPlusEmoji = getEmoji('📶➕', 'Antenna Bars and Plus');
export const officeBuildingAndGlobeWithMeridiansEmoji = getEmoji(
  '🏢🌐',
  'Office Building and Globe with Meridians',
);
export const officeBuildingAndSatelliteAntennaEmoji = getEmoji(
  '🏢📡',
  'Office Building and Satellite Antenna',
);
export const laptopEmoji = getEmoji('💻', 'Laptop');
export const laptopAndHandshakeEmoji = getEmoji('💻🤝', 'Laptop and Handshake');
export const womanTechnologistLSTEmoji = getEmoji('👩🏻‍💻', 'Woman Technologist Light Skin Tone');
export const manTeacherAndLaptopEmoji = getEmoji('👨‍🏫💻', 'Man Teacher and Laptop');
export const houseAndBooksEmoji = getEmoji('🏠📚', 'House and Books');
export const backpackEmoji = getEmoji('🎒', 'Backpack');
export const clipboardEmoji = getEmoji('📋', 'Clipboard');
export const crayonEmoji = getEmoji('🖍️', 'Crayon');
export const hammerAndWrenchAndSchoolEmoji = getEmoji('🛠️🏫', 'Hammer and Wrench and School');
export const chartIncreasingAndThinkingFaceEmoji = getEmoji(
  '📈🤔',
  'Chart Increasing and Thinking Face',
);
export const checkMarkEmoji = getEmoji('✔️', 'Check Mark');
export const balanceScaleAndClassicalBuildingEmoji = getEmoji(
  '⚖️🏛️',
  'Balance Scale and Classical Building',
);
export const womanJudgeMSTEmoji = getEmoji('👩🏽‍⚖️', 'Woman Judge Medium Skin Tone');
export const statueOfLibertyEmoji = getEmoji('🗽', 'Statue of Liberty');
export const dollarBanknoteAndAnguishedFaceEmoji = getEmoji(
  '💵😧',
  'Dollar Banknote and Anguished Face',
);
export const balanceScaleAndGraduationCapEmoji = getEmoji(
  '⚖️🎓',
  'Balance Scale and Graduation Cap',
);
export const balanceScaleAndOfficeBuildingEmoji = getEmoji(
  '⚖️🏢',
  'Balance Scale and Office Building',
);
export const militaryMedalEmoji = getEmoji('🎖️', 'Military Medal');
export const policeCarEmoji = getEmoji('🚓', 'Police Car');
export const detectiveEmoji = getEmoji('🕵️', 'Detective');
export const ambulanceEmoji = getEmoji('🚑', 'Ambulance');
export const womanPoliceOfficerDSTEmoji = getEmoji('👮🏿‍♀️', 'Woman Police Officer Dark Skin Tone');
export const policeCarLightEmoji = getEmoji('🚨', 'Police Car Light');
export const familyManGirlBoyEmoji = getEmoji('👨‍👧‍👦', 'Family Man, Girl, Boy');
export const moneyBagEmoji = getEmoji('💰', 'Money Bag');
export const officeBuildingAndMegaphoneEmoji = getEmoji('🏢📣', 'Office Building and Megaphone');
export const dollarBanknoteAndChartIncreasingEmoji = getEmoji(
  '💵📈',
  'Dollar Banknote and Chart Increasing',
);
export const medicalSymbolEmoji = getEmoji('⚕️', 'Medical Symbol');
export const sunBehindCloudEmoji = getEmoji('⛅', 'Sun Behind Cloud');
export const manTechnologistEmoji = getEmoji('👨‍💻', 'Man Technologist');
export const selfieEmoji = getEmoji('🤳', 'Selfie');
export const sparklingHeartEmoji = getEmoji('💖', 'Sparkling Heart');
export const coupleWithHeartWomanManEmoji = getEmoji('👩‍❤️‍👨', 'Couple with Heart Woman, Man');
export const beatingHeartEmoji = getEmoji('💓', 'Beating Heart');
export const hundredPointsEmoji = getEmoji('💯', 'Hundred Points');
export const fileCabinetEmoji = getEmoji('🗄️', 'File Cabinet');
export const globeWithMeridiansEmoji = getEmoji('🌐', 'Globe with Meridians');
export const flowerPlayingCardsEmoji = getEmoji('🎴', 'Flower Playing Cards');
export const cityscapeAtDuskEmoji = getEmoji('🌆', 'Cityscape at Dusk');
export const mobilePhoneWithArrowEmoji = getEmoji('📲', 'Mobile Phone with Arrow');
export const flowerPlayingCardsAndLinkEmoji = getEmoji('🎴🔗', 'Flower Playing Cards and Link');
export const selfieAndLinkEmoji = getEmoji('🤳🔗', 'Selfie and Link');
export const mobilePhoneEmoji = getEmoji('📱', 'Mobile Phone');
export const womanAndManHoldingHandsLSTMDSTEmoji = getEmoji(
  '👩🏻‍🤝‍👨🏾',
  'Woman and Man Holding Hands Light Skin Tone Medium-Dark Skin Tone',
);
export const familyManGirlEmoji = getEmoji('👨‍👧', 'Family Man, Girl');
export const familyEmoji = getEmoji('👪', 'Family');
export const leftAngerBubbleEmoji = getEmoji('🗮', 'Left Anger Bubble');
export const childMSTEmoji = getEmoji('🧒🏽', 'Child Medium Skin Tone');
export const joystickEmoji = getEmoji('🕹️', 'Joystick');
export const placeOfWorshipEmoji = getEmoji('🛐', 'Place of Worship');
export const oldManMLSTAndOldWomanDSTEmoji = getEmoji(
  '👴🏼👵🏿',
  'Old Man Medium-Light Skin Tone and and Old Woman Dark Skin Tone',
);
export const balanceScaleEmoji = getEmoji('⚖️', 'Balance Scale');
export const doveEmoji = getEmoji('🕊️', 'Dove');
export const rainbowFlagEmoji = getEmoji('🏳️‍🌈', 'Rainbow Flag');
export const seedlingEmoji = getEmoji('🌱', 'Seedling');
export const raisedFistEmoji = getEmoji('✊', 'Raised Fist');
export const dollarBanknoteAndAnxiousFacewithSweatEmoji = getEmoji(
  '💵😰',
  'Dollar Banknote and Anxious Face with Sweat',
);
export const constructionWorkerEmoji = getEmoji('👷', 'Construction Worker');
export const handshakeMediumLightSkinToneEmoji = getEmoji(
  '🤝🏼',
  'Handshake Medium-Light Skin Tone',
);
export const chartIncreasingEmoji = getEmoji('📈', 'Chart Increasing');
export const unicornEmoji = getEmoji('🦄', 'Unicorn');
export const boneEmoji = getEmoji('🦴', 'Bone');
export const catAndMedicalSymbolEmoji = getEmoji('🐈⚕️', 'Cat and Medical Symbol');
export const pawPrintsEmoji = getEmoji('🐾', 'Paw Prints');
export const parrotEmoji = getEmoji('🦜', 'Parrot');
export const catFaceEmoji = getEmoji('🐱', 'Cat Face');
export const dogFaceEmoji = getEmoji('🐶', 'Dog Face');
export const lizardEmoji = getEmoji('🦎', 'Lizard');
export const tropicalFishEmoji = getEmoji('🐠', 'Tropical Fish');
export const horseFaceEmoji = getEmoji('🐴', 'Horse Face');
export const rabbitEmoji = getEmoji('🐇', 'Rabbit');
export const snakeEmoji = getEmoji('🐍', 'Snake');
export const gorillaEmoji = getEmoji('🦍', 'Gorilla');
export const houseWithGardenEmoji = getEmoji('🏡', 'House with Garden');
export const housesEmoji = getEmoji('🏘️', 'Houses');
export const houseWithGardenAndWhiteFlagEmoji = getEmoji(
  '🏡🏳️',
  'House with Garden and White Flag',
);
export const convenienceStoreEmoji = getEmoji('🏪', 'Convenience Store');
export const roundPushpinEmoji = getEmoji('📍', 'Round Pushpin');
export const cityscapeEmoji = getEmoji('🏙️', 'Cityscape');
export const cityscapeAndHeavyDollarSignEmoji = getEmoji('🏙️💲', 'Cityscape and Heavy Dollar Sign');
export const houseAndSailboatEmoji = getEmoji('🏠⛵', 'House and Sailboat');
export const cityscapeAndWomanOfficeWorkerMDSTEmoji = getEmoji(
  '🏙️👩🏾‍💼',
  'Cityscape and Woman Office Worker Medium-Dark Skin Tone',
);
export const bookmarkTabsEmoji = getEmoji('📑', 'Bookmark Tabs');
export const cardIndexDividersEmoji = getEmoji('🗂️', 'Card Index Dividers');
export const cardIndexEmoji = getEmoji('📇', 'Card Index');
export const ledgerEmoji = getEmoji('📒', 'Ledger');
export const memoEmoji = getEmoji('📝', 'Memo');
export const abacusEmoji = getEmoji('🧮', 'Abacus');
export const openBookEmoji = getEmoji('📖', 'Open Book');
export const pageFacingUpEmoji = getEmoji('📄', 'Page Facing Up');
export const cardFileBoxEmoji = getEmoji('🗃️', 'Card File Box');
export const calendarEmoji = getEmoji('📅', 'Calendar');
export const globeShowingEuropeAfricaEmoji = getEmoji('🌍', 'Globe Showing Europe-Africa');
export const scrollEmoji = getEmoji('📜', 'Scroll');
export const dragonEmoji = getEmoji('🐉', 'Dragon');
export const japaneseCongratulationsButtonEmoji = getEmoji(
  '㊗️',
  'Japanese “Congratulations” Button',
);
export const bookEmoji = getEmoji('🕮', 'Book');
export const classicalBuildingEmoji = getEmoji('🏛️', 'Classical Building');
export const microscopeEmoji = getEmoji('🔬', 'Microscope');
export const shootingStarEmoji = getEmoji('🌠', 'Shooting Star');
export const DNAEmoji = getEmoji('🧬', 'DNA');
export const brainEmoji = getEmoji('🧠', 'Brain');
export const testTubeEmoji = getEmoji('🧪', 'Test Tube');
export const womanTechnologistEmoji = getEmoji('👩‍💻', 'Woman Technologist');
export const globeShowingAmericasEmoji = getEmoji('🌎', 'Globe Showing Americas');
export const sunBehindRainCloudEmoji = getEmoji('🌦️', 'Sun Behind Rain Cloud');
export const volcanoEmoji = getEmoji('🌋', 'Volcano');
export const deciduousTreeEmoji = getEmoji('🌳', 'Deciduous Tree');
export const globeShowingAmericasAndThermometerEmoji = getEmoji(
  '🌎🌡️',
  'Globe Showing Americas and Thermometer',
);
export const satelliteAntennaEmoji = getEmoji('📡', 'Satellite Antenna');
export const robotEmoji = getEmoji('🤖', 'Robot');
export const inputNumbersEmoji = getEmoji('🔢', 'Input Numbers');
export const barChartEmoji = getEmoji('📊', 'Bar Chart');
export const atomSymbolEmoji = getEmoji('⚛️', 'Atom Symbol');
export const schoolEmoji = getEmoji('🏫', 'School');
export const shoppingBagsEmoji = getEmoji('🛍️', 'Shopping Bags');
export const amphoraEmoji = getEmoji('🏺', 'Amphora');
export const tShirtEmoji = getEmoji('👕', 'T-Shirt');
export const runningShirtEmoji = getEmoji('🎽', 'Running Shirt');
export const jeansEmoji = getEmoji('👖', 'Jeans');
export const childAndWomansClothesEmoji = getEmoji('🩳👚', 'Child and Woman’s Clothes');
export const ribbonEmoji = getEmoji('🎀', 'Ribbon');
export const reminderRibbonEmoji = getEmoji('🎗️', 'Reminder ribbon');
export const glassesEmoji = getEmoji('👓', 'Glasses');
export const mansShoeEmoji = getEmoji('👞', 'Man’s Shoe');
export const personInTuxedoEmoji = getEmoji('🤵', 'Person in Tuxedo');
export const billedCapEmoji = getEmoji('🧢', 'Billed Cap');
export const necktieEmoji = getEmoji('👔', 'Necktie');
export const womanSwimmingEmoji = getEmoji('🏊‍♀️', 'Woman Swimming');
export const bikiniEmoji = getEmoji('👙', 'Bikini');
export const dressEmoji = getEmoji('👗', 'Dress');
export const framedPictureAndPersonRaisingHandEmoji = getEmoji(
  '🖼️🙋',
  'Framed Picture and Person Raising Hand',
);
export const newspaperEmoji = getEmoji('📰', 'Newspaper');
export const manOfficeWorkerEmoji = getEmoji('👨‍💼', 'Man Office Worker');
export const shieldEmoji = getEmoji('🛡️', 'Shield');
export const labelEmoji = getEmoji('🏷️', 'Label');
export const starEmoji = getEmoji('⭐', 'Star');
export const dvdEmoji = getEmoji('📀', 'DVD');
export const counterclockwiseArrowsButtonEmoji = getEmoji('🔄', 'Counterclockwise Arrows Button');
export const heartWithRibbonEmoji = getEmoji('💝', 'Heart with Ribbon');
export const loveLetterEmoji = getEmoji('💌', 'Love Letter');
export const bouquetEmoji = getEmoji('💐', 'Bouquet');
export const wrappedGiftEmoji = getEmoji('🎁', 'Wrapped Gift');
export const ringEmoji = getEmoji('💍', 'Ring');
export const departmentStoreEmoji = getEmoji('🏬', 'Department Store');
export const cameraAndMovieCameraEmoji = getEmoji('📷🎥', 'Camera and Movie Camera');
export const cigaretteEmoji = getEmoji('🚬', 'Cigarette');
export const gearEmoji = getEmoji('⚙️', 'Gear');
export const japaneseDollsEmoji = getEmoji('🎎', 'Japanese Dolls');
export const kickScooterEmoji = getEmoji('🛴', 'Kick Scooter');
export const teddyBearEmoji = getEmoji('🧸', 'Teddy Bear');
export const horseRacingEmoji = getEmoji('🏇', 'Horse racing');
export const schoolAndTrophyEmoji = getEmoji('🏫🏆', 'School and Trophy');
export const personFencingEmoji = getEmoji('🤺', 'Person Fencing');
export const boxingGloveEmoji = getEmoji('🥊', 'Boxing Glove');
export const martialArtsUniformEmoji = getEmoji('🥋', 'Martial Arts Uniform');
export const peopleWrestlingEmoji = getEmoji('🤼', 'People Wrestling');
export const racingCarEmoji = getEmoji('🏎️', 'Racing Car');
export const cityscapeAndRacingCarEmoji = getEmoji('🏙️🏎️', 'Cityscape and Racing Car');
export const personRaisingHandAndSportsMedalEmoji = getEmoji(
  '🙋🏅',
  'Person Raising Hand and Sports Medal',
);
export const personBikingEmoji = getEmoji('🚴', 'Person Biking');
export const womanGolfingEmoji = getEmoji('🏌🏿‍♀️', 'Woman Golfing Dark Skin Tone');
export const personCartwheelingMSTEmoji = getEmoji('🤸🏽‍♂️', 'Man Cartwheeling Medium Skin Tone');
export const iceSkateEmoji = getEmoji('⛸️', 'Ice Skate');
export const womanRunningEmoji = getEmoji('🏃🏽‍♀️', 'Woman Running Medium Skin Tone');
export const firstPlaceMedalEmoji = getEmoji('🥇', 'First Place Medal');
export const olympicRingsEmoji = getEmoji('◯‍◯‍◯‍◯‍◯', 'Olympic rings');
export const motorcycleEmoji = getEmoji('🏍️', 'Motorcycle');
export const tennisEmoji = getEmoji('🎾', 'Tennis');
export const sportsMedalEmoji = getEmoji('🏅', 'Sports Medal');
export const skisAndGogglesEmoji = getEmoji('🎿🥽', 'Skis and goggles');
export const personWithSkullcapEmoji = getEmoji('👲', 'Person With Skullcap');
export const boyAndGirlEmoji = getEmoji('👦👧🏿', 'Boy and Girl Dark Skin Tone');
export const americanFootballEmoji = getEmoji('🏈', 'American football');
export const australianFootballEmoji = getEmoji('🇦🇺🏈', 'Flag Australia and American football');
export const baseballEmoji = getEmoji('⚾', 'Baseball');
export const basketballEmoji = getEmoji('🏀', 'Basketball');
export const personCartwheelingEmoji = getEmoji('🤸', 'Person Cartwheeling');
export const cricketGameEmoji = getEmoji('🏏', 'Cricket game');
export const iceHockeyEmoji = getEmoji('🏒', 'Ice hockey');
export const rugbyFootballEmoji = getEmoji('🏉', 'Rugby football');
export const volleyballEmoji = getEmoji('🏐', 'Volleyball');
export const personPlayingWaterPoloEmoji = getEmoji('🤽', 'Person Playing Water Polo');
export const womanSurfingEmoji = getEmoji('🏄🏾‍♀️', 'Woman Surfing Medium-Dark Skin Tone');
export const womanSwimmingMSTEmoji = getEmoji('🏊🏽‍♀️', 'Woman Swimming Medium Skin Tone');
export const snowflakeAndTrophyEmoji = getEmoji('❄️🏆', 'Snowflake and Trophy');
export const skierAndSnowboarderEmoji = getEmoji('⛷️🏂', 'Skier and Snowboarder');
export const luggageEmoji = getEmoji('🧳', 'Luggage');
export const airplaneEmoji = getEmoji('✈️', 'Airplane');
export const pButtonAndLightRailEmoji = getEmoji('🅿️🚈', 'P button and light rail');
export const busAndLightRailEmoji = getEmoji('🚌🚈', 'Bus and light rail');
export const taxiAndAutomobileEmoji = getEmoji('🚘🚖', 'Oncoming taxi and oncoming automobile');
export const shipAndAirplaneEmoji = getEmoji('🚢✈️', 'Ship and airplane');
export const hotelEmoji = getEmoji('🏨', 'Hotel');
export const personInBedEmoji = getEmoji('🛌', 'Person in bed');
export const desertIslandEmoji = getEmoji('🏝️', 'Desert island');
export const worldMapEmoji = getEmoji('🗺️', 'World map');
export const beachWithUmbrellaEmoji = getEmoji('🏖️', 'Beach with umbrella');
export const skierEmoji = getEmoji('⛷️', 'Skier');
export const nationalParkEmoji = getEmoji('🏞️', 'National park');
export const rollerCoasterEmoji = getEmoji('🎢', 'Roller coaster');
export const pandaEmoji = getEmoji('🐼', 'Panda');
export const cookingWareDiningWareEmoji = getEmoji('🍳🍽️', 'Cooking and Fork and Knife with Plate');

export const interestEmojis = {
  autos_vehicles_85d8b59dc19da58902a371e07fd032a5: automobileEmoji,
  cooking_ware_dining_ware_207a747b69a316e1bad7294289eecf67: cookingWareDiningWareEmoji,
  grants_scholarships_financial_aid_8731cb522be470e99f42ef59f8d5f18c:
    graduationCapAndDollarBanknoteEmoji,
  books_literature_49f796f863309b147aa64b314a5734b0: booksEmoji,
  tv_video_885c825bf7c942dee35021af422f69aa: televisionEmoji,
  movies_55ba44c548d3ebafd9f70e64a7f232b0: movieCameraEmoji,
  performing_arts_0a5c6ab3cf66c507f4043d3a6fe962dd: performingArtsEmoji,
  visual_art_design_7e93e4be3508b6d52631d4d009060efc: artistPaletteEmoji,
  cooking_recipes_88c69f24d2ea36f50432038bbf9a3c17: cookingEmoji,
  board_games_51f521783d287c11490b723c6328d05a: gameDieEmoji,
  computer_video_games_d22fafedd97e624ad79e3798ae101388: videoGameEmoji,
  outdoors_af453c2cbb8ec14e92e27b11c2238f12: campingEmoji,
  radio_control_modeling_f34f8d424e9d49d6070a5f8aac4951fb: joystickAndSmallAirplaneEmoji,
  sports_088495f30901580ddd5171531cd26649: soccerBallEmoji,
  pets_animals_a6c2fe7db94a8c6acce86e31e96cc65d: dogEmoji,
  philosophy_225ab6cdf797eae79495ff584a46dfd2: thinkingFaceEmoji,
  humanities_77ae5f42f89ad37b468feb17346a0e06: graduationCapEmoji,
  adult_2e85a22d0ffa063c211acd8f34952360: personEmoji,
  arts_entertainment_b8aaa40e71f56a5acf89bbc9f2fa9a31: performingArtsEmoji,
  celebrities_entertainment_news_54ec4cfd185492c127410a6887ca30c7: crownEmoji,
  comics_animation_84bfe85a99b5401169a8f5e02430af8f: superheroAndThoughtBalloonEmoji,
  comics_51af637aed604c1d5376026ae625111e: superheroEmoji,
  anime_manga_0c0d8de04c50640948cb630dd38de6c8: superheroJapaneseCastleEmoji,
  cartoons_f7a05acc169a7bd453eba7d65af2312d: superheroAndTelevisionEmoji,
  entertainment_industry_1c5dff9098c294f44167b2cca928eaf1: manSingerMSTEmoji,
  film_tv_industry_8b758ed4cf348f047e4a7302a9e18f47: filmFramesAndTelevisionEmoji,
  recording_industry_dc85e702ce9ddf61beb36b42d13cbd7b: studioMicrophoneEmoji,
  events_listings_30f1f2fbb75e66c7f7e80c11e03aab25: partyPopperEmoji,
  bars_clubs_nightlife_34635c2594528106f555c8c54104b0ae: cocktailBarAndManDancingEmoji,
  concerts_music_festivals_aa9d927608ba31307a52873e6c36f89e: guitarEmoji,
  expos_conventions_b6a1ae170e0b84ba1b3bf311c771ad7b: briefcaseEmoji,
  film_festivals_f19c110b6295a3b71c919af6a6354cd1: filmFramesEmoji,
  movie_listings_theater_showtimes_f25cecc3b03954bffc82c2701388aa3d: cinemaEmoji,
  fun_trivia_06df14b52472e51b64b1b312dab2770d: puzzlePieceAndThinkingFaceEmoji,
  flashbased_entertainment_b91623a057ba07edb0950b634ac8f222: tRexEmoji,
  fun_tests_silly_surveys_075faf18058c84f7e7cc211bc48165c0: squintingFaceWithTongueAndMemoEmoji,
  humor_7a5b5501705f07767ec8bed743855a01: rollingOnFloorLaughingEmoji,
  funny_pictures_videos_b8b5b2cc68be3d2e18cd2efccc950468: pictureAndVideoCameraEmoji,
  political_humor_3b939f19e3ef6ab112c8142b07f7a489: rollingOnFloorLaughingEmoji,
  music_audio_fe327d98e5c7e07c869deabc1335517a: musicalNoteEmoji,
  cd_audio_shopping_2dbfe81424285ea756061de1d949c154: opticalDiskEmoji,
  classical_music_ff9ed784e558828bc3cb2874264661f4: violinEmoji,
  country_music_4f6bae80e130e20f5509fac0f1fb028a: guitarEmoji,
  dance_electronic_music_bac02ac6ccc6943f406e4353b8130a95: controlKnobsAndWomanDancingEmoji,
  experimental_industrial_music_2cc74b9e8977ccb5dd5a9d042f0050f4: levelSliderEmoji,
  jazz_blues_507b0283cb61534417f2170076327a02: saxophoneEmoji,
  music_education_instruction_792c45da28ae642e680ac410db83d393: graduationCapAndMusicalNoteEmoji,
  music_equipment_technology_a716bd346584b507971a8c057c6d0db0: controlKnobsEmoji,
  music_reference_27a0b62b2b5b88e0548c871e8a401242: controlKnobsEmoji,
  music_streams_downloads_3cdc5c6d029422fd2a978b17a4e76f9c: speakerHighVolumeEmoji,
  music_videos_705d987adfac80d8706a9b5df2dd96bb: musicalNoteAndFilmFramesEmoji,
  pop_music_7634f69738bf27acea2d5d43a10a313d: singerEmoji,
  radio_a398fb77df76e6153df57cd65fd0a7c5: radioEmoji,
  religious_music_8ff6926e4dc2c288a6fd1c2345f14821: churchAndMusicalNoteEmoji,
  rock_music_81cbc63d584ac4469109a1ceb3bac892: signOfTheHornsEmoji,
  soundtracks_0426ed22f63175cd1770aa7dd4cb136a: musicalNoteAndFilmFramesEmoji,
  urban_hiphop_18b8259dbfa0357bdf46bf94d5facbe3: musicalNoteAndCrownEmoji,
  world_music_edd44ef9cc610c3f6da92c6436193b4f: musicalNoteAndGlobeWithMeridiansEmoji,
  offbeat_54c8350de6813b1453e4c7602a2c7916: ghostEmoji,
  occult_paranormal_2454a2d0baf909777aafd62113a02466: ghostEmoji,
  online_media_b2c64b1dc385414e5ccf114513214af6: newspaperAndGlobeWithMeridiansEmoji,
  online_image_galleries_d4bb932e2355f2ebadc09a9d860e4b91: framedPictureAndGlobeWithMeridiansEmoji,
  acting_theater_2aa1f1050dbb1c9c9cfb6bd15c9c812c: performingArtsEmoji,
  circus_c5191509f4f6afc6f1a111663950e52d: circusTentEmoji,
  dance_3355d92c04a3332339b767f9278405ff: womanDancingEmoji,
  magic_2f3a4fccca6406e35bcf33e92dd93135: elfEmoji,
  opera_9ed987966c06808b50f9fddc24931bd1: performingArtsAndMisocalNoteEmoji,
  online_video_1aebfcce673fbb6eff0117eee5bcf7f3: videoCameraAndGlobeWithMeridiansEmoji,
  tv_commercials_1dfac74702ae4fb95a7eb8de03bd7836: televisionEmoji,
  tv_shows_programs_99ba76bd9f240e28763d526c8dbaf3e6: televisionEmoji,
  architecture_efe79c321127e9bcc1ad7f6956d52ca6: houseEmoji,
  art_museums_galleries_c9a7ee41f77f1b37acc4ff44f49ab192: classicalBuildingEmoji,
  design_31c13f47ad87dd7baa2d558a91e0fbb9: artistPaletteEmoji,
  painting_6a7c1b1d588cd51d12ad44324d614fed: artistPaletteEmoji,
  photographic_digital_arts_15b2cb90c10fbfaafe4bfa1fc06ddb33: framesPictureAndCameraEmoji,
  bicycles_accessories_d5fb1c1be5f961b708ffcc6f241958a5: bicycleEmoji,
  bike_parts_repair_1d75ae39e2fc892ece55b0ba00db3432: bicycleAndWrenchEmoji,
  bmx_bikes_52a01b046191b6498658183288b40fe5: bicycleEmoji,
  boats_watercraft_fb65a1d532affd74099aeae6ee0ec16a: personRowingBoatEmoji,
  campers_rvs_45da0f9d563cae2c298d2675f5ec2235: motorBoatEmoji,
  classic_vehicles_969479f54177175ffd584e236ea6c90f: automobileEmoji,
  cargo_trucks_trailers_be03519da22e197f21ed26ddc8b3ac35: deliveryTruckEmoji,
  motor_vehicles_by_type_e8e630deff59a57743b7dd3051e984cb: automobileEmoji,
  hybrid_alternative_vehicles_f988d17ef64c11abc69e7ae42dde3d5f: automobileAndElectricPlugEmoji,
  motorcycles_d3b49bcac8c46d4e541302aaacaebd50: motorcycleEmoji,
  offroad_vehicles_0db778590a3dc344878d403191bbc4e5: automobileAndMountainEmoji,
  trucks_suvs_ffc4b5948c1f41b95d193d235f0e2bf9: deliveryTruckEmoji,
  vehicle_codes_driving_laws_13e6c5d767c560a26cd03820d9bdd260: stopSignAndWarningEmoji,
  vehicle_licensing_registration_f1e99436d2c05e82af3c80f6aa622d48: automobileAndMemoEmoji,
  vehicle_parts_services_12e3e2011e0e8f638dc64540553ef483: automobileAndGearEmoji,
  gas_prices_vehicle_fueling_dd70b86c9d9484fafde9a0d055b61d47: fuelPumpEmoji,
  vehicle_parts_accessories_8f176915732a5257876d347b6d905356: automobileAndGearEmoji,
  vehicle_repair_maintenance_919cbb31f84187319fc2ec2b829a6077: automobileAndHammerAndWrenchEmoji,
  vehicle_shopping_f095ecf8c3a5c0696fb2138f9d1c8217: automobileAndPriceTagEmoji,
  used_vehicles_d3bf6a3d89bc906514af25d9747f03a2: automobileAndChartDecreasingEmoji,
  vehicle_shows_770fcdde929beae9d3fb73501542c2fd: automobileAndRacingCarEmoji,
  beauty_fitness_578ae79094949cd58585cce482afe8cb: nailPolishAndLipstickEmoji,
  beauty_pageants_c3320ba145255a8bd968f24734385078: womanAndCrownEmoji,
  body_art_c082a9f2ebaa55fcec7ca75d0971481f: flexedBicepsAndPaintbrushEmoji,
  cosmetic_procedures_55c2aebb06718ac82c2a4251b0ca2bd6: personGettingMassageEmoji,
  cosmetic_surgery_9bf7eb7818e4945ed659f5ea7433b6e4: personAndSyringeEmoji,
  cosmetology_beauty_professionals_87fe5bc8fa0de5c112d482a0030b7b15: personAndSparklesEmoji,
  face_body_care_fa35a357f507a4e685e8482b5476d941: personGettingMassageEmoji,
  hygiene_toiletries_dbe0b2b7d600b5a39e931bd6daa2e4dd: soapAndLotionBottleEmoji,
  makeup_cosmetics_08ccabbdc6ea4d4021d08de5c6372e6e: lipstickEmoji,
  perfumes_fragrances_6405d6ab9069768c1a5e7406fdc719c9: noseAndBouquetEmoji,
  skin_nail_care_b0a72c4b8c11fb1c25af4b63fc38707e: nailPolishEmoji,
  unwanted_body_facial_hair_removal_46572bd1dbeaa145c1c1d9ca3c753b36: barberPoleEmoji,
  fashion_style_96a7fc282f7329c759e6bbe165db274e: highHeeledShoeAndNecktieEmoji,
  fashion_designers_collections_2d28ebcd475049f12b3924f3da272ae5: dressAndSparklesEmoji,
  fitness_31db504ef095675f8bc7ba8f06882f4e: flexedBicepsEmoji,
  hair_care_c0418fa27ed9a1f39e740b0e2c94e814: personCurlyHairEmoji,
  hair_loss_d4385cc3720884e4b0a1ebf67568eb72: personBaldEmoji,
  spas_beauty_services_2b62fef73cf859df5d3797c99b3d442e: womanInSteamyRoomEmoji,
  massage_therapy_8317c44d882f6375a5606f5b69994b81: personGettingMassageEmoji,
  weight_loss_48dd865e1ce334d3cb0915689e92a9d1: womanRunningAndGreenSaladEmoji,
  childrens_literature_0451671d87e6a47c972dcc1977f36f17: booksAndChildEmoji,
  ebooks_164c57815c8c89c9c9d8ba89104082d6: booksAndLaptopEmoji,
  fan_fiction_b00f66384486db9a5730325e70592821: princessAndRollingOnTheFloorLaughingEmoji,
  literary_classics_e6202513e079db0c29d527588332d2da: openBookEmoji,
  poetry_0a9db264dffd68c8f8b6e591835c526b: openBookEmoji,
  writers_resources_b8c7bad709132f8d58f80350025d46be: booksAndWritingHandEmoji,
  business_industrial_6c50b41e31a34b70e296967923a7ec41: briefcaseAndFactoryEmoji,
  public_relations_e32eb9aeb3312b0476e3142e03616460: newspaperAndLoudspeakerEmoji,
  space_technology_8d66a58a088c8658a3ad835679270a5d: rocketEmoji,
  agriculture_forestry_17442728162119990a1f07645d0dd3c6: farmerEmoji,
  agricultural_equipment_bb68feacb2bdb94615aa61f26d9409ec: tractorEmoji,
  forestry_c5f3f4bf24a80012adf7aaa358a6b131: evergreenTreeEmoji,
  livestock_6df887e16616fe6b6eb8ca4a7964c933: cowFaceAndPigFaceEmoji,
  automotive_industry_be5223e1c651b432edf20d03946ca147: automobileAndFactoryEmoji,
  business_education_28dacc25c7f17a567293b3995d801998: briefcaseAndGraduationCapEmoji,
  business_finance_60dcb43da34e0bb45f029ed0f36b6e42: briefcaseAndDollarBanknoteEmoji,
  venture_capital_ed23fb42f7731482c4030e2256819aed: moneyBagAndLightBulbEmoji,
  business_operations_100768a7881bd0ee16f21a45016e6f00: briefcaseAndGearEmoji,
  business_plans_presentations_0b37ebf7f1fadcf857c9ca3a3a267093: briefcaseAndPageFacingUpEmoji,
  management_d10af457daa1deed54e2c36b5f295e7e: officeWorkerEmoji,
  business_services_22ab0941092c6be4f9ab98b2c4d2c919: briefcaseAndHandshakeEmoji,
  consulting_072d5206d03ffe26d884cd389fa32fec: directHitAndHandshakeEmoji,
  corporate_events_a69016a287a5d8b17d071b38257faba8: necktieAndCocktailGlassEmoji,
  ecommerce_services_3b29dbd7984de1134ad42519b5de0107: shoppingCartAndMeridiansEmoji,
  fire_security_services_053ebe0ec5c219c7ae221abf175479d0: fireExtinguisherAndPoliceOficerEmoji,
  office_services_6dd33553c1271ea4329e003f56caa931: officeBuildingEmoji,
  office_supplies_2a7f7607031cb5deac9a771abc4d8a40: paperClipAndFileFolderEmoji,
  writing_editing_services_4bac686c1d8e58574cb6a0dd65a61558: writingHandAndMemoEmoji,
  chemicals_industry_c1e86210872e1e282f83df2640db1b25: testTubeAndMicroscopeEmoji,
  cleaning_agents_0fc3f76b3193f1c01378d543ee731b46: soapAndTestTubeEmoji,
  plastics_polymers_93e368fc8e99768f69e0425932475246: cupWithStrawEmoji,
  construction_maintenance_e368c7efed1bce13dc6016220ae1e665: buildingConstructionEmoji,
  building_materials_supplies_c441591d487a3044d6bb2d847f0a2c71: brickEmoji,
  energy_utilities_847d6e68142ba8c171b71308f65d281f: highVoltageEmoji,
  electricity_641b2b00d03f9990007869f7cc5281e4: lightBulbAndElectricPlugEmoji,
  oil_gas_88dca7da675c08622502d4b320bc01f3: fuelPumpEmoji,
  renewable_alternative_energy_98d74a064bf1a69d8c66ea3d9520f159: sunAndBatteryEmoji,
  hospitality_industry_7d17e62eb65e7bd30c5a474e855d8c68: manCookAndHotelEmoji,
  event_planning_361d1cd3cd69eda0ebce7a97d164df1b: partyPopperAndMemoEmoji,
  food_service_270a3188af2e810ea8a901739ce206eb: manCookEmoji,
  industrial_materials_equipment_93bb6b1fa51118f6eb1f93cda283f965:
    buildingConstructionAndHammerAndWrenchEmoji,
  heavy_machinery_ab57704fbed44803d68d884565090eeb: tractorEmoji,
  manufacturing_8d31d9aa2193cbd93731d668fe8caadc: factoryEmoji,
  metals_mining_b8f053c925cf0119baf5443b83e5769c: pickEmoji,
  precious_metals_e1e01ff1e30b61588e4991b17371c660: ringEmoji,
  pharmaceuticals_biotech_e9a1dc5820e2a47c2aa1f81225541e75: pillEmoji,
  printing_publishing_d523ab7118ed84c3e9e4d555f10112a3: pageFacingUpAndPrinterEmoji,
  retail_trade_ed3318c8e866997850baf09eab8039d5: shoppingBagsEmoji,
  retail_equipment_technology_23323f2dfca352ab6a894ca3764b37e4: shoppingBagsAndLaptopEmoji,
  mlm_business_opportunities_5394dab3e068feaa2e2bd37eb0a7c695: briefcaseAndTelescopeEmoji,
  textiles_nonwovens_d9c9ca1d704a9fdc12153d82743e1028: threadEmoji,
  transportation_logistics_9ead79d4296963b3af6c6a6a893d545e: shipAndLightTrainEmoji,
  freight_trucking_d7e156b4dc2d05f1e1ebcc9523886700: deliveryTruckEmoji,
  mail_package_delivery_dd6082e29b3002f9c6f016da2dc95749: postboxAndPackageEmoji,
  maritime_transport_bfebed053a0ac36c4da4b4c6e5672f16: shipEmoji,
  moving_relocation_da7b37e79fcca64ea1416b4bd5726d01: deliveryTruckAndPackageEmoji,
  packaging_869b299375bab3ba9331a5ce61517631: packageEmoji,
  parking_3ac156eead4ae6b40e9c498d532b4448: pButtonEmoji,
  rail_transport_49b1ba25903f3917cff08a732425873b: lightRailEmoji,
  urban_transport_366e874db7fb984bb760e7cec99a0731: tramAndOncomingBusEmoji,
  computers_electronics_dde12543fd26e60820153667d60145be: desktopComputerAndMobilePhoneEmoji,
  cad_cam_e8e72938873c19af4d026a68d92878b4: desktopComputerAndTriangularRulerEmoji,
  computer_hardware_bc26cccfcdff5b7f3bded4501de89977: computerMouseAndPrinterEmoji,
  computer_components_bb085d0af93f7dd55a17fa7b6b01a923: desktopComputerEmoji,
  computer_drives_storage_6ff08721d225407bf31245e15bddd8ec: desktopComputerAndComputerDiskEmoji,
  computer_peripherals_214486ca545c9bd32c1e4ac043b1d06d: headphonesAndKeyboardEmoji,
  desktop_computers_a88bf46b6b033138e8b1c7a432032a7a: desktopComputerEmoji,
  laptops_notebooks_7241eec1f1fb7f2ab563492cd09f876e: laptopEmoji,
  computer_security_9c8fa1abbf10e2243a3a1fb62c831549: desktopComputerLockedWithTheKeyEmoji,
  hacking_cracking_b19af7efeeaca2505eb79c9f1afd4015: unlockedAndWarningEmoji,
  consumer_electronics_16e48e18594f75c368914c631db601e1: printerAndMobilePhoneEmoji,
  audio_equipment_5ead94a90d57e711a1f34c70a9f95cb3: speakerHighVolumeEmoji,
  camera_photo_equipment_abc3af45922e2a3ec5d73f6230861637: cameraEmoji,
  car_electronics_c14b71b7c63734e90d33507ed94b8c84: automobileAndSpeakerHighVolumeEmoji,
  drones_rc_aircraft_924cf9f23681f2d208e89b4d15a4b512: smallAirplaneAndJoystickEmoji,
  game_systems_consoles_c4cfa3a3a3569b298cbe170a5979087e: videogameEmoji,
  gps_navigation_da4c3428ca0b173c6b1ea416db5c7585: satelliteAndMobilePhoneEmoji,
  tv_video_equipment_eba360c113cc2c2ba428e98a010d480f: cameraAndTelevisionEmoji,
  electronics_electrical_cbf146af31b8307e40d48b57e9c222c3: desktopComputerEmoji,
  electronic_components_6f61e5b7a66c37bab1796d380f60d659: desktopComputerEmoji,
  power_supplies_7c7aef547bbbf890d69d1076364b876a: electricPlugEmoji,
  enterprise_technology_9ff908cbd82f1c495c80805c582c0257: laptopEmoji,
  data_management_50ee6e2f83c3ef1735883d9ca0b7fe47: laptopAndBarChartEmoji,
  networking_200176387d36476522d4b488e8597847: laptopAndShakingHandsEmoji,
  data_formats_protocols_dc2028bd90b706832e20069e234c0cdd: laptopEmoji,
  network_monitoring_management_4bcc21911cf8d66fdc3e2efceccea220: laptopAndBarChartEmoji,
  vpn_remote_access_8b434d43a0e716039297d20234940327: globeWithMeridiansAndShieldEmoji,
  programming_7e73d06707f5fb75ec77cc5f2bd9bb92: manTechnologistEmoji,
  java_programming_language_b31037efebbf03f71f72956700e6a04e: manTechnologistEmoji,
  software_f9fa10ba956cacf91d7878861139efb9: manTechnologistEmoji,
  business_productivity_software_dc0b4cf18821ef4b2b0ca28987b6d767: briefcaseAndManTechnologistEmoji,
  device_drivers_1615396a5745e440f2e0272be7359578: laptopAndGearEmoji,
  internet_software_5245a8ef1e951f897d7b03afea63f69f: globeWithMeridiansAndManTechnologistEmoji,
  multimedia_software_f0f5efc75d3da64752df9b6b136ba29f: musicalNoteAndManTechnologistEmoji,
  operating_systems_be271009935d0128acb1b390692254c4: laptopEmoji,
  software_utilities_8a6f358eb79eb494232322bdf9b1c2a9: lockedWithKeyAndManTechnologistEmoji,
  finance_57336afd1f4b40dfd9f5731e35302fe5: dollarBanknoteEmoji,
  accounting_auditing_ec18922cc343d29c53d21f26a06323b5: abacusAndBarChartEmoji,
  billing_invoicing_c7f90603de0980d63aaff3722461cd94: receiptEmoji,
  tax_preparation_planning_1244117ebfcc6f7863a52935459e5c93: receiptAndBarChartEmoji,
  banking_6351e4efddc359eca697894df2bfd02d: bankEmoji,
  credit_lending_d70416bd1bfc7702f16001d9f8d8b176: creditCardEmoji,
  credit_cards_62a95e54cac3a328b9966b3d1b287a0d: creditCardEmoji,
  credit_reporting_monitoring_5d970c62b497f3b32e08c75a7893a255: creditCardAndBarChartEmoji,
  loans_f806527a4b4ecb554edad98174334a70: foldedHandAndMoneyBagEmoji,
  financial_planning_management_ddad2afb0de65b7636a0041c78240780: dollarBanknoteAndBarChartEmoji,
  retirement_pension_d717d463b5766cde94ae138e8056b552: olderPersonMDSTAndDollarBanknoteEmoji,
  study_grants_scholarships_953feac3a24d3408f292453532a1de36: graduationCapAndDollarBanknoteEmoji,
  insurance_b4628fe4f5f38e5b293be2024ce95239: memoAndDollarBanknoteEmoji,
  health_insurance_ffc1646c3c85b43546202112bcbc0bb3: medicalSymbolAndDollarBanknoteEmoji,
  investing_610264491fdf7f3be32d04c3b7c453cf: chartIncreasingAndDollarBanknoteEmoji,
  commodities_futures_trading_cf7dadafef13046ba7e0665ff599891c:
    chartIncreasingAndDollarBanknoteEmoji,
  currencies_foreign_exchange_b8ab1517d486ad49725997fdd598cead: currencyExchangeEmoji,
  stocks_bonds_83e13aa7b41228218a130677c7c5652a: chartIncreasingAndChartDecreasingEmoji,
  food_drink_5f4233aa8721d1bf592b36c8015387bb: hamburgerAndCupWithStrawEmoji,
  beverages_eb693dc3e09666bb339be2c419d0478e: glassOfMilkEmoji,
  alcoholic_beverages_18f8057d60eabf37aeec0be3425b9b02: cocktailGlassEmoji,
  coffee_tea_2dc1069efb2e5d91506ae44d3a010c67: hotBeverageEmoji,
  juice_57f7e96f370e04546066ee921424c760: redAppleAndCupwithStrawEmoji,
  soft_drinks_38e7e737f2c73ed4fe5005b008327278: cupWithStrawEmoji,
  bbq_grilling_f2eabfeec0dd87404de51cdc8b876045: cutOfMeatEmoji,
  desserts_b7f8f342d75c02699b7873f497f4251c: iceCreamEmoji,
  soups_stews_8251af8ea5c85a42e794a382eb54a049: potOfFoodEmoji,
  food_62506be34d574da4a0d158a67253ea99: hamburgerEmoji,
  food_grocery_retailers_f5f424521aecb3e8dc50f193359db72f: convenienceStoreEmoji,
  baked_goods_1815fe7132776f0a71a1baa09b8a72f8: breadEmoji,
  breakfast_foods_7de9ff7fbcc61bb221273b7665335aef: pancakesEmoji,
  candy_sweets_c7ba4eb03c2861410cc3075ce6a81151: lollipopEmoji,
  grains_pasta_8faac1d6ad5bf459b6e710aa7200bda4: spaghettiEmoji,
  meat_seafood_9e285d3cb8e2a3be6994d27c674b2cc0: poultryLegAndFriedShrimpEmoji,
  snack_foods_24969c3f8aa1565e8fd0bfe3c9fbc15e: cookieEmoji,
  restaurants_49cfbe257fc2454107e5b4daf9f83e13: forkAndKnifeWithPlateEmoji,
  fast_food_c112cb8b36d69b8331f2957a6a465b6f: frenchFriesEmoji,
  pizzerias_a0a779718bf6e5c43545cf2e6838c2fe: pizzaEmoji,
  restaurant_reviews_reservations_a664ced7fbb84a5b49498db344e40037: thumbsUpEmoji,
  games_9cfa7aefcc61936b70aaec6729329eda: flyingDiscEmoji,
  arcade_coinop_games_f3274f78fa4aa46e84a572c9f59d5c9d: joystickEmoji,
  chess_abstract_strategy_games_74c1c66baa1959ce75c007fc5dc83f8c: chessPawnEmoji,
  miniatures_wargaming_af70e78b4de3eea851047f85cc8c78d8: castleEmoji,
  card_games_64cbbfbf36a47f179e3c202a107705ef: jokerEmoji,
  collectible_card_games_76b6eb8a1ca5705289baa2b06d0cdb5a: flowerPlayingCardsEmoji,
  poker_casino_games_c4f9901091b331455f992c4eef95d473: slotMachineEmoji,
  casual_games_e1e722686af2d145bdc4033814afd307: slightlySmilingFaceAndVideoGameEmoji,
  driving_racing_games_79b23c5457fabffbffcf269355661aac: videoGameAndChequeredFlagEmoji,
  fighting_games_aa6c8468f8a915e07d261c738e87f62a: videoGameAndCrossedSwordsEmoji,
  music_dance_games_e004b5815df0bf3c639f74bb200ddcb7: videoGameAndMusicalNoteEmoji,
  sandbox_games_ac7b605f74910a3321a9f91f508ec3f8: videoGameAndGlobeShowingAmericasEmoji,
  shooter_games_9261c4b6370e25e21ef0e2626c5e6cbb: videoGameAndPistolEmoji,
  simulation_games_bdc1bf1e6008c4938d7110b698d3c017: videoGameAndAirplaneEmoji,
  sports_games_67ede2fd19c9006d8226c74e0166494d: videoGameAndSoccerBallEmoji,
  strategy_games_d4b624d25117c545dd1bee7dbe0ed099: videoGameAndThinkingFaceEmoji,
  video_game_emulation_22dc9106ea6b2a781da187f87eec6635: videoGameAndDesktopComputerEmoji,
  familyoriented_games_activities_c72ff12bc52784f3e544a58756b9e18e: gameDieEmoji,
  drawing_coloring_2a5ecf34e205ca285a2d4334f20f211d: artistPaletteEmoji,
  dressup_fashion_games_91a1c52c2d1c5b624935ad2e7706b4fa: topHatEmoji,
  gambling_10f6bf38326f4dceefafef2616e86ac7: gameDieEmoji,
  lottery_f6eff9e0848e2d5b1d1da205925d3f99: moneyWithWingsEmoji,
  massively_multiplayer_games_e8f917e4aec073cb040ec11183fa15e2: familyManWomanBoyBoyEmoji,
  puzzles_brainteasers_5dbb56b96e81f04b1437527420216ba0: puzzlePieceEmoji,
  roleplaying_games_0a0a84bbab6a1d22d28f45d728828670: fairyEmoji,
  table_games_4825bb1f4a511ed9d5555ad03798f300: pingPongEmoji,
  billiards_dcffcb328215e331284aacb75781da68: pool8BallEmoji,
  word_games_c3e54456c6193f69f4fc057b83bdcec6: inputLatinUppercaseEmoji,
  health_555bf8344ca0caf09b42f55e185526d8: medicalSymbolEmoji,
  aging_geriatrics_ed73e19f212da5832d4dc579eb4c453f: oldManMLSTAndOldWomanLSTEmoji,
  health_conditions_159bcf1317440e13e5a08e72ed1f7b15: faceWithThermometerEmoji,
  aids_hiv_97508a798e6fab8733ec7fd8e346a6b7: bacteriaEmoji,
  allergies_2c527dd68e63f119442f4352f2a0b950: sneezingFaceEmoji,
  arthritis_33e241041546f27407dd511037a7c92b: legEmoji,
  cancer_a99dca5593185c498b63a5eed917bd4f: reminderRibbonEmoji,
  diabetes_c35b49da1b6dd679a48d67f456bc7aaf: syringeAndCandyEmoji,
  ear_nose_throat_d7c5123e626d5dfa06ec72394987b4cb: earMDSTAndNoseMDSTEmoji,
  eating_disorders_16bdb27244938b3a52185cb204c6fd3a: nauseatedFaceEmoji,
  endocrine_conditions_54ed4da844d2bfd50ad5558e8ee41660: woozyFaceEmoji,
  genetic_disorders_3c04835ba5417a0d2bc4bd5174271513: DNAEmoji,
  heart_hypertension_4f98465a125a412465d49e033873b02f: beatingHeartEmoji,
  infectious_diseases_43536905255af19ae1193a5f87283285: microbeEmoji,
  neurological_conditions_a22a189de1887ddbd88694f83ff5a205: brainEmoji,
  obesity_18e18bfdf3683b3349dab906586edd78: balanceScaleEmoji,
  pain_management_433733dd0db1891a732b2db4bac30cb2: faceWithHeadBandageAndLevelSliderEmoji,
  respiratory_conditions_ba65e7113a83e821cfa6412100d739c6: faceExhalingEmoji,
  skin_conditions_f71cd47127b3cba5ed02291676cf84d4: zombieEmoji,
  sleep_disorders_7810aab3ea71533d1ffcb1d04502917a: sleepingFaceEmoji,
  health_education_medical_training_d71eb82b0b16ccafa84521a19eedf64d:
    womanHealthWorkerMSTAndGraduationCapEmoji,
  health_foundations_medical_research_866ce115afd8dbf550996ecca247b1cd: testTubeEmoji,
  medical_devices_equipment_4537a54be2e3c5081fa09cc1e529e326: microscopeEmoji,
  medical_facilities_services_6c1d5d08679544e7027914a117500ca1: ambulanceAndHospitalEmoji,
  doctors_offices_82dfe1c7b446249aadbf41b85811eb55: hospitalAndManHealthWorkerEmoji,
  hospitals_treatment_centers_e596e39a23871fb7fa7b77651e2f91b6: hospitalEmoji,
  medical_procedures_02ab4cd21c34791639da27171732f6f4: syringeEmoji,
  physical_therapy_4fa680009624c212e90008cc87c543a7: manGettingMassageMDSTEmoji,
  mens_health_27223fc415b5b2802b4fc6c2f9a39359: maleSignEmoji,
  mental_health_aebb2a305925fb34cc0cc9cdf449d009: sunWithFaceEmoji,
  anxiety_stress_365b12b3b0a820242a76980dc15509a4: worriedFaceEmoji,
  depression_28c5f9ffd175dcd53aa3e9da9b00dde7: disappointedFaceEmoji,
  nursing_5676cfe1da29ab42311b21eca342a9b3: olderPersonMLSTEmoji,
  assisted_living_long_term_care_5211a9a6584e9c49dc76ac8faeef3cf4:
    huggingFaceAndManHealthWorkerEmoji,
  nutrition_0595a7566805535e079f18da58ad7a2d: bentoBoxEmoji,
  special_restricted_diets_6042e13359e30dd573cd35765aa88af6: broccoliEmoji,
  vitamins_supplements_5c4946b3d333526f4ef51ea0d9a365d4: pillEmoji,
  oral_dental_care_760354ebad846248dbf9a0a6a24469ca: toothEmoji,
  pharmacy_bf4e28785ab0560951dd0766f8059c4a: alembicEmoji,
  drugs_medications_09d6338333f6308a7b6411e8f6603a76: syringeEmoji,
  public_health_d0b2a33f657c547786d645c5afd96cbd: ambulanceEmoji,
  occupational_health_safety_0418507cb806f6d1edcdc8e1a5799cce: faceWithMedicalMaskEmoji,
  reproductive_health_ddcf6bcaf51c4bab6cfa596ff14af81c: maleSignFemaleSignEmoji,
  substance_abuse_ee0288fe0626a667f6c65c48df5a5271: dizzyFaceEmoji,
  drug_alcohol_testing_cc445671c742ca4b6ae5fa35d2afc894: wineGlassAndExclamationMarkEmoji,
  drug_alcohol_treatment_931e1f534f24f4d12fbdb6e1e5a351b6: wineGlassAndMedicalSymbolEmoji,
  smoking_smoking_cessation_1d5d8f462d5003c88a30966667b11539: cigaretteEmoji,
  steroids_performanceenhancing_drugs_42b743fd2d965fae480c29a017dd36c3: pillAndFlexedBicepsEmoji,
  vision_care_bd23b9b7ebfe02361be9898361009c8f: eyeEmoji,
  eyeglasses_contacts_b73c7557614657a9081a8153ab240dd5: glassesEmoji,
  womens_health_b42ef27b6301a071cb267b485f67a974: femaleSignEmoji,
  hobbies_leisure_78f15cb1a386877ed3e381bfbd430c2b: directHitEmoji,
  clubs_organizations_1f39ca2b4d51841b79532dd3e16e5679: handshakeEmoji,
  youth_organizations_resources_865f4e59c012f5ac71eab2b6512e333b: childAndHandshakeEmoji,
  crafts_841d8ef1086cb094f9218af74d10490f: yarnEmoji,
  fiber_textile_arts_5b646d77a8ec4123f5265f9f22b35f26: threadAndScissorsEmoji,
  merit_prizes_contests_bcb24f598320cb45aca1e377c10fd1d8: rosetteEmoji,
  fishing_819a07afa4abc8660ec9042038a8c597: fishingPoleEmoji,
  hiking_camping_d8dd78c421a0a0384e7459a00381dedd: campingEmoji,
  paintball_7529bcfbfbd960846f8e5891872672e2: pistolEmoji,
  model_trains_railroads_497a9dd428af44919b57c62cdac8bdb0: locomotiveEmoji,
  special_occasions_eb974b0a69ddbad8b06cdeb519d06119: sparklesEmoji,
  holidays_seasonal_events_190e6b7e81662103084773aab789bcee: christmasTreeEmoji,
  weddings_bf760eeb4cab0e970c836dc84130e037: weddingEmoji,
  water_activities_1a3d71d218d30e7d151c27a4a34f038c: waterWaveEmoji,
  boating_2e95b8e77f524c8851da3c6985edfeac: personRowingBoatMSTEmoji,
  surf_swim_f86ac78e7a5eacadab9d918c6a99c5ad: womanSwimmingDSTAndWomanSurfingEmoji,
  home_garden_f1b6f3e93a40e94b15df7a66bf504131: houseWithGardenEmoji,
  bed_bath_f2e743886768f271a1f31933f4d5fb46: bedAndShowerEmoji,
  bathroom_67518d646b9c9868b149f513ba47af66: bathtubEmoji,
  domestic_services_61d5e99b4d3b3f7c8124a5a98e5996e7: houseAndHammerEmoji,
  cleaning_services_02f611565873b4dda2a84b02f522536c: broomAndSoapEmoji,
  gardening_landscaping_db0e89f39bb4ab1dbd4a2a3a15aca72e: deciduousTreeEmoji,
  home_interior_decor_4906d7118ef4e7f7074d42aa0833640f: framedPictureEmoji,
  home_appliances_42964726d33ae2a51297fcd0bc50ea6a: televisionEmoji,
  home_furnishings_6fd70b16fbe075d6077a0c5e73e4e3a1: couchAndLampEmoji,
  curtains_window_treatments_08a11215acde637a7930a8a8e7f5361e: housesEmoji,
  kitchen_dining_furniture_f0310a8c40fe4f17ff5936a27e736303: couchAndLampEmoji,
  lamps_lighting_9a3d2596ac11aede7e0478e80f6bf5a0: lightBulbEmoji,
  living_room_furniture_e3244526dc45d7cfb41fb7ddcc521227: couchAndLampEmoji,
  rugs_carpets_610324996c689a873007825245f8bcb1: footprintsEmoji,
  home_improvement_b5ee54bba2ae28a03173618f93df9835: houseAndSparklesEmoji,
  construction_power_tools_b8d2c7dcdcd6bacb5ab09aeed6e224ce: gearAndElectricPlugEmoji,
  doors_windows_a5183563a5a75dc6c7d5641cdace9939: housesEmoji,
  flooring_7535b9c4b2b5233af59055adec684a9d: footprintsEmoji,
  house_painting_finishing_0083d15153fac3510630c3d2af31e7f9: paintbrushEmoji,
  plumbing_6676d258083f1727d47ad7e89855d5cf: toiletAndWrenchEmoji,
  home_safety_security_2ecb1d7dfb7be1ca6a3b1393c900017a: lockedEmoji,
  home_storage_shelving_f77f29d9b054c563506330a2a99c48e9: packageEmoji,
  home_swimming_pools_saunas_spas_672057e7a2b71b63ed03c38f7c217622:
    waterwaveAndWomanInSteamyRoomEmoji,
  hvac_climate_control_6f8d53da3c75f6cbb039310ab47c3008: thermometerAndControlKnobsEmoji,
  fireplaces_stoves_2d6430608e4dd92e8f64e62abce2b0e7: fireEmoji,
  kitchen_dining_b6fb5352d637bbd564b3293bd6014664: forkAndKnifeWithPlateEmoji,
  major_kitchen_appliances_5f2d4612c836d84831d9ece7750f014e: cookingEmoji,
  small_kitchen_appliances_1308401b4f19e473d45553cae6e5c340: cookingEmoji,
  laundry_fd330c14f753f2d92cb3ce4239934dc2: basketEmoji,
  washers_dryers_eadc5143e9a5358344cd1c070c3e619e: cycloneEmoji,
  nursery_playroom_b4ab08ac712cce70ccefebe0ca529e52: babyMDSTEmoji,
  pest_control_7d1b4f2a854a41064948c5173f982e9d: antAndProhibitedEmoji,
  yard_patio_3e37c3883c64c0bb202a341b6afeaceb: umbrellaOnGroundEmoji,
  lawn_mowers_6f3ae2e830957935c0a5f42e15914bd6: herbAndScissorsEmoji,
  internet_telecom_85e5b752d05311ec53879688be92a241: globeWithMeridiansEmoji,
  communications_equipment_7daa4c09fa104709f872c2021343f012: satelliteAntennaEmoji,
  radio_equipment_7c1aaa41c5c1b13bb4334d92738ba3cc: radioEmoji,
  email_messaging_2046b847f34b784178afc23a0dc54fe8: eMailEmoji,
  text_instant_messaging_b91c272ef1f9887bd24888ab211e232d: pagerEmoji,
  voice_video_chat_240cfd347ba011b2e348ccb4058bc4ac: selfieDSTAndSpeechBalloonEmoji,
  mobile_wireless_fa258c8791ba21e808ba07ddc51c38e2: antennaBarsEmoji,
  mobile_wireless_accessories_412663936e9f44bd21c6ee78f4a279ad: antennaBarsAndPlusEmoji,
  mobile_apps_addons_21a4c9467cef52813bce76fff50548f1: mobilePhoneWithArrowEmoji,
  mobile_phones_c69848d5e019251815bf12f7d12f1555: mobilePhoneEmoji,
  service_providers_da560b02cedc75249951c57cb615f31e: officeBuildingAndGlobeWithMeridiansEmoji,
  cable_satellite_providers_5e062d5c07dd29829f6db3b113e17ac9:
    officeBuildingAndSatelliteAntennaEmoji,
  web_services_e614a2f52f40a0c07ad9a5064b4c3c70: laptopEmoji,
  affiliate_programs_191456dfde664ff875f35aadbe0cc7e2: laptopAndHandshakeEmoji,
  web_design_development_48792a1f59f15e27553fa327a182b293: womanTechnologistLSTEmoji,
  jobs_education_5e1808f5fd786e1050b3c12c7770b6b1: briefcaseEmoji,
  education_d0bb80aabb8619b6e35113f02e72752b: schoolEmoji,
  colleges_universities_8b729bd54630d0fd9c154a41ebbba22c: graduationCapEmoji,
  distance_learning_5f7cdf086acf776c7ef5c93c1e0d771e: manTeacherAndLaptopEmoji,
  homeschooling_cde0b3a7260d1ecac766a611bf7ba949: houseAndBooksEmoji,
  primary_secondary_schooling_k12_a1f3d68133fbf9faf9b75924df95bbb1: backpackEmoji,
  standardized_admissions_tests_ac8048beec9ec153d4f0d259acc8e545: clipboardEmoji,
  teaching_classroom_resources_37844678e17989d68a8666f55f4c5fc2: crayonEmoji,
  training_certification_c14802952f7da8dcb84c3b29cf3582cd: scrollEmoji,
  vocational_continuing_education_5b235a13031c03ec4c887b07957e6541: hammerAndWrenchAndSchoolEmoji,
  jobs_27a06a9e3d5e7f67eb604a39536208c9: constructionWorkerEmoji,
  career_resources_planning_eadb99237b05e6d95948a891dc8a5999: chartIncreasingAndThinkingFaceEmoji,
  job_listings_fe34e356574d85638dc01102b462c094: checkMarkEmoji,
  resumes_portfolios_8bcae965f615af8ab44800e910bcb9b2: bookmarkTabsEmoji,
  law_government_e24be6711a671adca175834d17bd1575: balanceScaleAndClassicalBuildingEmoji,
  government_30618461f5ff731e12c61bae54582b80: classicalBuildingEmoji,
  courts_judiciary_28842341d7233d901adebe9a335658a2: womanJudgeMSTEmoji,
  visa_immigration_a6b8cc0d3c23a6b59ff25bdc0b4d5745: statueOfLibertyEmoji,
  legal_2fbd4ee396cdac223059952a7fe01e54: balanceScaleEmoji,
  bankruptcy_4e6cf0d9607806bca5b4480eb5d3ee18: dollarBanknoteAndAnguishedFaceEmoji,
  legal_education_a708382e4ea76bf1d3f9429bd8bb3d64: balanceScaleAndGraduationCapEmoji,
  legal_services_39bc785c70c96d2a856704623839b12e: balanceScaleAndOfficeBuildingEmoji,
  military_31ba6d3619a6d70c983151afa0764de4: militaryMedalEmoji,
  public_safety_b14eeae21bd1315a3f117fe228d888cf: policeCarEmoji,
  crime_justice_95bd843eeac4bfc24acfa08b98152428: detectiveEmoji,
  emergency_services_33f3924c0328cd54f308b6599601e971: ambulanceEmoji,
  law_enforcement_f18f3012b9f996750bfb35d3f30e4d3e: womanPoliceOfficerDSTEmoji,
  security_products_services_f13a9a1c6aee3a4806a5260b554f56d6: policeCarLightEmoji,
  social_services_86943b3c15559deed482ebe5f3481b77: familyManGirlBoyEmoji,
  news_508c75c8507a2ae5223dfd2faeb98122: newspaperEmoji,
  business_news_013135fec364968c4129738fadfc04c1: moneyBagEmoji,
  company_news_4f85bd7d20c424b7c6f3bdc892795804: officeBuildingAndMegaphoneEmoji,
  financial_markets_news_64b7e4aa89b241e7d17236b384a641a6: dollarBanknoteAndChartIncreasingEmoji,
  scandals_investigations_780b5858f036efebb48e7448dffcee72: highVoltageEmoji,
  health_news_79f76332bfcd4ea969c9001de49dbe54: medicalSymbolEmoji,
  politics_a0f15d09df04d9ad657ce26e371ddf9a: classicalBuildingEmoji,
  sports_news_459e654a49d4f8c12b29ba6c231ba506: basketballEmoji,
  weather_aab92e69374e4c7b8c6741fe02e574b9: sunBehindCloudEmoji,
  online_communities_fc14339124e474789a9d96d51116576c: manTechnologistEmoji,
  blogging_resources_services_1ba824778798388b274aa45dbccd5d4f: selfieEmoji,
  dating_personals_c2e3c884fa255286b94bb360078c749e: sparklingHeartEmoji,
  matrimonial_services_e89709175d36e8f28e79018e9346d309: coupleWithHeartWomanManEmoji,
  personals_6db78568b4f6fb6a0f8e5f1c1af9502b: beatingHeartEmoji,
  photo_rating_sites_72a5114e57a07ca9fd9731b002b76672: hundredPointsEmoji,
  file_sharing_hosting_f9c5cf3779275d36bc379c721a5634e8: fileCabinetEmoji,
  online_goodies_543cc13fff0637c9e949b463782afaa6: globeWithMeridiansEmoji,
  clip_art_animated_gifs_8e98b3e95d6d5bd536d926c2ddc4dd73: flowerPlayingCardsEmoji,
  skins_themes_wallpapers_3099e7d97146dca296846b9329219e65: cityscapeAtDuskEmoji,
  social_network_apps_addons_b3acacdec68d1c1f56462bd5ac5ee8b6: mobilePhoneWithArrowEmoji,
  photo_video_sharing_d6178ee7474157a0bf1b0009289791c3: flowerPlayingCardsAndLinkEmoji,
  photo_image_sharing_9d1096ef569eaf235949e024bc418223: selfieAndLinkEmoji,
  social_networks_eb76995068fe180abf62c4b3597a4460: mobilePhoneEmoji,
  virtual_worlds_0944af78695e5bf3e62fb6379acbee54: womanTechnologistEmoji,
  people_society_b3b399dee4d6649ecb8332287e4e1d43: womanAndManHoldingHandsLSTMDSTEmoji,
  family_relationships_01d74e8940f2f1149f83d4d1098d8d44: familyManGirlEmoji,
  family_0d3fda0bdbb9d619e09cdf3eecba7999: familyEmoji,
  marriage_772b76917b47b88838f3c947de6d917a: ringEmoji,
  troubled_relationships_e77404f6fecc1711299ce2466945f45e: leftAngerBubbleEmoji,
  kids_teens_c18685d99f1d46d885593944428721c8: childMSTEmoji,
  childrens_interests_2dc022748bcc0a353e3bb26f50295b9f: teddyBearEmoji,
  teen_interests_c32f7b7da8bbcdec3140f975be0fc6db: joystickEmoji,
  religion_belief_7a9e5858a924054fb46202d696ea1052: placeOfWorshipEmoji,
  seniors_retirement_eaaa0f33e6959e8091fd16e477e94452: oldManMLSTAndOldWomanDSTEmoji,
  social_issues_advocacy_27ea94ce5c9f94489e7bfadd483eca72: balanceScaleEmoji,
  charity_philanthropy_08a4334879d62af13e1cb269b7d258db: doveEmoji,
  discrimination_identity_relations_5dcf6bf9961119db9cb53e34cc6fab5c: rainbowFlagEmoji,
  green_living_environmental_issues_5da9d44ae0167f55672d55d55ece9571: seedlingEmoji,
  human_rights_liberties_e5c443f2fb7f1b1deffb8100a3cab326: raisedFistEmoji,
  poverty_hunger_fdeebbcab6fae3c16b2be5d9c86f29e3: dollarBanknoteAndAnxiousFacewithSweatEmoji,
  work_labor_issues_de11ed67f4b9da720e8b485233a103c1: constructionWorkerEmoji,
  social_sciences_2683bc345ac5b5701333190d02c7e472: handshakeMediumLightSkinToneEmoji,
  economics_6b9ed75dae7a1e692073fc400b558ea4: chartIncreasingEmoji,
  political_science_eaefa004bd27cdfd8c52a3afde8011c7: classicalBuildingEmoji,
  psychology_1231d487d9ac27b6579556329bf2a71b: brainEmoji,
  subcultures_niche_interests_1cf65412be6cabe0d6f5e3ced52653b4: unicornEmoji,
  pet_food_supplies_ba63b3734fd34dc8272423f21f7b2da1: boneEmoji,
  veterinarians_c3543acef45de75086e319eaf2374a2a: catAndMedicalSymbolEmoji,
  pets_d0d64110d9563247dad835d349e9b507: pawPrintsEmoji,
  birds_75c73f175a85cee384f32a5608eb67d1: parrotEmoji,
  cats_0832c1202da8d382318e329a7c133ea0: catFaceEmoji,
  dogs_d28d2d3560fa76f0dbb1a452f8c38169: dogFaceEmoji,
  exotic_pets_eca6856ca790ef0689b8851608369f42: lizardEmoji,
  fish_aquaria_81341837fe82077062027f8e9568e193: tropicalFishEmoji,
  horses_52b14869c15726dda86b87cb93666a74: horseFaceEmoji,
  rabbits_rodents_e9c33bd7ba29b8dfe657b39599fd089f: rabbitEmoji,
  reptiles_amphibians_33ed0348b3cc217c16efec03f734ec0b: snakeEmoji,
  wildlife_fce889a721a6df4592df07e53fdbb47a: gorillaEmoji,
  real_estate_54d98854697bedf25869bec45f30369e: houseWithGardenEmoji,
  real_estate_listings_3052fe574e52cd85b01a6053dc31b6b3: housesEmoji,
  bankowned_foreclosed_properties_b93749a034fa83a8f80f62475c3765e8:
    houseWithGardenAndWhiteFlagEmoji,
  commercial_properties_82aa8a56c0bf6457f4554e0702859b9c: convenienceStoreEmoji,
  lots_land_3163d274e40d253f8605ab1ddc56b153: roundPushpinEmoji,
  residential_rentals_c3a62d42192eb7ff289d1db32cb0b16a: cityscapeEmoji,
  residential_sales_55703780a0d1531c7a521cac7f3a06a9: cityscapeAndHeavyDollarSignEmoji,
  timeshares_vacation_properties_57f092545de8dfd1e24644733993546f: houseAndSailboatEmoji,
  real_estate_services_6c599e087025a76466add6e7039a76e0: cityscapeAndWomanOfficeWorkerMDSTEmoji,
  reference_b8af13ea9c8fe890c9979a1fa8dbde22: bookmarkTabsEmoji,
  directories_listings_e0b22244ab7a4082efb22da165074260: cardIndexDividersEmoji,
  business_personal_listings_c1a5d35c8f19e7c1ec7e42a79509466b: cardIndexEmoji,
  general_reference_b7b720f86ac20eac4c9037e841665d6b: ledgerEmoji,
  biographies_quotations_9c917638f159e727f15ef89b0e4c9864: memoEmoji,
  calculators_reference_tools_363fe04f9f6b20ea21fcae40d589bd6b: abacusEmoji,
  dictionaries_encyclopedias_edb264688cb49a8d9d9e839b2ae764ef: openBookEmoji,
  forms_guides_templates_9af5d86d69449230c8cbf1f366f0d19a: pageFacingUpEmoji,
  public_records_5a955960c2648f4cd691edcdcd86ba4e: cardFileBoxEmoji,
  time_calendars_3c3b5925aef5e957926b86fe7cf76b7f: calendarEmoji,
  geographic_reference_e7ccee28bcbc2bc7345c0340f19cad00: globeShowingEuropeAfricaEmoji,
  maps_7e94476d62556cc8501e3df5b8d6470d: worldMapEmoji,
  history_3cd15f8f2940aff879df34df4e5c2cd1: scrollEmoji,
  myth_folklore_218f8c8eb3eb3f39beada8721abdbe5f: dragonEmoji,
  language_resources_af5acee774bed4a8938b0f5867c79d34: inputLatinLettersEmoji,
  foreign_language_resources_759740a2b6a1ab02df04599b99de67d5: japaneseCongratulationsButtonEmoji,
  libraries_museums_94079e13d81e6f4a6b06ff4e5f49109d: booksEmoji,
  museums_1d4719fb5e236361019241010e1fbe69: classicalBuildingEmoji,
  science_fb5c7f9bb4b32ce2f3bff4662f1ab27b: microscopeEmoji,
  astronomy_27e15e728c02855914e5eb19147f2b75: shootingStarEmoji,
  biological_sciences_041f9b5f156b900423130ee96e4a24e7: DNAEmoji,
  neuroscience_89d0af5bce37ee110fd8614220b031b8: brainEmoji,
  chemistry_dce09f281c35f49c2f58ea7580b530b7: testTubeEmoji,
  computer_science_508b547f71d0a2d436a8024479162662: womanTechnologistEmoji,
  earth_sciences_bb2fefb7b706e1c4191f2e8e23670797: globeShowingAmericasEmoji,
  atmospheric_science_d305e816d4fc5e80e09d049197820f4a: sunBehindRainCloudEmoji,
  geology_7d85d9b8aa39cf03661a367d394d1a21: volcanoEmoji,
  ecology_environment_74656c59df7e36df2f90be14e517d42f: deciduousTreeEmoji,
  climate_change_global_warming_69c67ded38865c3d6c57c4729553c8f0:
    globeShowingAmericasAndThermometerEmoji,
  engineering_technology_63ee14e1d4eda44c2c53aab7355e749e: satelliteAntennaEmoji,
  robotics_9cafaa453dfe12efaa19f7aa6a0b61f3: robotEmoji,
  mathematics_6ae28a55456b101be8261e5dee44cd3e: inputNumbersEmoji,
  statistics_a912a94d79b5124d876951f96ebb256f: barChartEmoji,
  physics_8cfb10d3dd0ae49a87320653cbfa587e: atomSymbolEmoji,
  scientific_institutions_b75cf98580e69ccd04c184450f9067b8: schoolEmoji,
  sensitive_subjects_0fa975fca9b971e1361cf72b55d5548b: thinkingFaceEmoji,
  shopping_b2fe440cb7a0b127f1a90ffea296313b: shoppingBagsEmoji,
  antiques_collectibles_2045e6f046c90d26c6217a76c1607ea7: amphoraEmoji,
  apparel_2fcbff5f9416f04eb81b1b8faa01eeee: tShirtEmoji,
  athletic_apparel_ea9201e97f1bc315653443a4cefe5165: runningShirtEmoji,
  casual_apparel_addc565ff3e23d348178117679722f37: jeansEmoji,
  childrens_clothing_171084c1951bf63d0fad463083ff57c8: childAndWomansClothesEmoji,
  clothing_accessories_b2bc087a42ec2c3300a39eacab020e72: ribbonEmoji,
  costumes_f0fa9e58caec5689f4f0d26f908eab54: performingArtsEmoji,
  eyewear_e6d6038b90ffa835564a48df1cbf8fc6: glassesEmoji,
  footwear_569084f9cab6eba99f49d630b036171b: mansShoeEmoji,
  formal_wear_955b9fb396b3f2ce330714c59361beeb: personInTuxedoEmoji,
  headwear_35147ad01df80c7dbae09928ba53da80: billedCapEmoji,
  mens_clothing_4a13693bd6fbefaaa735c3e97f8c4f7d: necktieEmoji,
  swimwear_14a1315b8fc302eceb86e74a4cf0b278: womanSwimmingEmoji,
  undergarments_54668f3bb3c88664020040dfec3045f2: bikiniEmoji,
  womens_clothing_40c6982af9f9525ab2f0aeeebb28c388: dressEmoji,
  auctions_1c44b3008dd1a89803d9b2f2bd58e57a: framedPictureAndPersonRaisingHandEmoji,
  classifieds_3aefd569f6cd881e5671a6ae723fe6bf: newspaperEmoji,
  consumer_resources_d191eaa30fadfc42fac30b61563cd2da: manOfficeWorkerEmoji,
  consumer_advocacy_protection_f8aa5f6eb8afcd8b794d1fd0c538e89d: shieldEmoji,
  coupons_discount_offers_f30ec86724f06e3e56e763ef9199afc4: labelEmoji,
  product_reviews_price_comparisons_e8091da9f245deb7e48738103fbede8a: starEmoji,
  entertainment_media_94854d52a5014b798de9b32d9fc873dc: dvdEmoji,
  entertainment_media_rentals_78506fb2ce83898d2fe81b93687bda6e: counterclockwiseArrowsButtonEmoji,
  gifts_special_event_items_8a5d41dc9e4579f8fe64fdb14f160b95: heartWithRibbonEmoji,
  cards_greetings_1364582bc36ee0e52da898e47a4fbb4b: loveLetterEmoji,
  flowers_7d37c580f9c36fa004af865448a6e278: bouquetEmoji,
  gifts_b4e6d54e333042b19efe280d940dddc2: wrappedGiftEmoji,
  luxury_goods_f46949d8edad1b3e0512055bf65cbdd7: ringEmoji,
  mass_merchants_department_stores_443557921d8d0f070a0ebfe3ac4e4201: departmentStoreEmoji,
  photo_video_services_14807bc4ae2e2c22a7aeda3b42e3bf99: cameraAndMovieCameraEmoji,
  tobacco_products_866e0a652ee1766579cf696af71d9da8: cigaretteEmoji,
  toys_efacc176332496ff6574f42eba0c6ab0: teddyBearEmoji,
  building_toys_9c2dbe17b4c202b754ea0f55b46a5a6d: gearEmoji,
  diecast_toy_vehicles_42e18357e264516d444c8a9232471fc6: automobileEmoji,
  dolls_accessories_4e7b8851da6312f0e28870de4262f0d7: japaneseDollsEmoji,
  rideon_toys_wagons_8a5e8ae062f4f727cbe1d3ec88820d98: kickScooterEmoji,
  stuffed_toys_e494f49a82bff371960e1a23093473a5: teddyBearEmoji,
  animal_sports_532fa85383939beab04d0c68842dafe1: horseRacingEmoji,
  college_sports_67572c9644b40891ff8830849cc932c0: schoolAndTrophyEmoji,
  combat_sports_2e84a471849a175bd949aa3efa6bdae1: personFencingEmoji,
  boxing_4684c60ddc71bc393a1bf007f5452372: boxingGloveEmoji,
  martial_arts_a071d53557daba5ac64d0ca76f8ac039: martialArtsUniformEmoji,
  wrestling_f1800eb9b742b093359106546b9451a0: peopleWrestlingEmoji,
  extreme_sports_25021ff0fc749db7d5a83e6598fcb5da: racingCarEmoji,
  drag_street_racing_833d963aac594923cf51deeff201758c: cityscapeAndRacingCarEmoji,
  fantasy_sports_d67354e24f1b55061777f8ed8cfce96d: videoGameEmoji,
  individual_sports_ded48b9ea897ab68a3e907ad3e358984: personRaisingHandAndSportsMedalEmoji,
  cycling_190e678e13b41dd6757f4de34946ff87: personBikingEmoji,
  golf_c6cf642b8f1cac1101e23a06aa63600e: womanGolfingEmoji,
  gymnastics_e8fa75814f543086d4e1675dd7857001: personCartwheelingMSTEmoji,
  racquet_sports_0ee6db0dd1a009416d3ff4a36824d57a: tennisEmoji,
  skate_sports_68f5c516c60e931a90ccf5e553931b9b: iceSkateEmoji,
  track_field_0085d3d85ef7d6627e9342a883e4e438: womanRunningEmoji,
  international_sports_competitions_4b45298724e466761a2d85cb4026dff6: firstPlaceMedalEmoji,
  olympics_a5349f533e3aa3ccbc27de2638da38d6: olympicRingsEmoji,
  motor_sports_85765fee1e3ce5d5931f4cd0a3c21731: motorcycleEmoji,
  sporting_goods_69725ccdbd971da6748302616533f155: tennisEmoji,
  sports_memorabilia_9932f779ac10ce13779888eb42e5c03e: sportsMedalEmoji,
  winter_sports_equipment_a396fc7825582e2fcd4c4bc4f98494e7: skisAndGogglesEmoji,
  sports_coaching_training_b4d9745a33ebc651ef3e85c1cb3f0e3e: personWithSkullcapEmoji,
  team_sports_91900e8b7ee6a23788e7f7cd518418e2: boyAndGirlEmoji,
  american_football_d8aba0ccbefadc4236e0ac48c1f73d9c: americanFootballEmoji,
  australian_football_ba5af5497d35e6aeb16de1aba9694274: australianFootballEmoji,
  baseball_276f8db0b86edaa7fc805516c852c889: baseballEmoji,
  basketball_d0199f51d2728db6011945145a1b607a: basketballEmoji,
  cheerleading_29042ae8f65e15a8d0812c056b603887: personCartwheelingEmoji,
  cricket_247184f5fcf8c0afea1291676dc6df8f: cricketGameEmoji,
  hockey_df0349ce110b69f03b4def8012ae4970: iceHockeyEmoji,
  rugby_24908ce13bfd3ec802bfc0ba32fc79e8: rugbyFootballEmoji,
  soccer_da443a0ad979d5530df38ca1a74e4f80: soccerBallEmoji,
  volleyball_91111fb1f8b088438d80367df81cb6cf: volleyballEmoji,
  water_sports_2ead2e426969e178426350d02aee2647: personPlayingWaterPoloEmoji,
  surfing_b94f90bac6b3877b0ca3b87faba854de: womanSurfingEmoji,
  swimming_f853b10af73a04a4c8b35b2ade1f40b9: womanSwimmingMSTEmoji,
  winter_sports_5827f762ef4a4d1c7038d768ab525900: snowflakeAndTrophyEmoji,
  ice_skating_db897ede3b2e5e2cbb1c5c57a8eabd0c: iceSkateEmoji,
  skiing_snowboarding_4dfee148a8ce4647397cf78575401893: skierAndSnowboarderEmoji,
  travel_69266c67e75c946ef9b4144b0554326d: luggageEmoji,
  air_travel_155c0619e2a52ffd71ad0b4c3dc14783: airplaneEmoji,
  airport_parking_transportation_75f0eac4f5f285a894eb1fc3c93521e6: pButtonAndLightRailEmoji,
  bus_rail_8a570e064f051aaaaca8dc3684c4ea63: busAndLightRailEmoji,
  car_rental_taxi_services_e0fbae165a2983afbcf5eaf40f4a7ff8: taxiAndAutomobileEmoji,
  cruises_charters_55b3d55959dab1f9b7aa13cf041ccf9c: shipAndAirplaneEmoji,
  hotels_accommodations_d90014b882b2f72ef6cbf74c84b4ff2b: hotelEmoji,
  vacation_rentals_shortterm_stays_87959942f2894c92a94a06e0bee843c5: personInBedEmoji,
  specialty_travel_97dbef0d972eafa0841a6f16d2f3b779: desertIslandEmoji,
  tourist_destinations_0a3dc77d4fe60d543710ac01084b8f01: worldMapEmoji,
  beaches_islands_ca60e2874ad2f9e0d178ca9766777cb1: beachWithUmbrellaEmoji,
  mountain_ski_resorts_bfd8a2f0b19717a109dc7e61a2081bea: skierEmoji,
  regional_parks_gardens_7084506a1496dc940f161d8032a28f04: nationalParkEmoji,
  theme_parks_54d9dd4c433a48e555d949fddd8b99ef: rollerCoasterEmoji,
  zoosaquariumspreserves_995d65062ee52ca57d3ecaabbec2db72: pandaEmoji,
};
