import React from 'react';
import PropTypes from 'prop-types';

import { HighlightsInitiatives } from '../../../../constants/enums';
import TopFiltersList from '../components/TopFiltersList/TopFiltersList';
import { MoodValues } from '../../../../constants/moods';

const MoodsFilters = ({ menuFilters, setMenuFilters, stats, totalStudentsCount }) => (
  <TopFiltersList
    columnsNumber={2}
    initiative={HighlightsInitiatives.MOODS}
    isRadioSelection
    mainEntriesSorting={([aName], [bName]) => MoodValues[aName] - MoodValues[bName]}
    menuFilters={menuFilters}
    setMenuFilters={setMenuFilters}
    stats={stats}
    title="Moods Highlights info"
    totalStudentsCount={totalStudentsCount}
  />
);

MoodsFilters.propTypes = {
  menuFilters: PropTypes.instanceOf(Object).isRequired,
  setMenuFilters: PropTypes.func.isRequired,
  stats: PropTypes.instanceOf(Object).isRequired,
  totalStudentsCount: PropTypes.number.isRequired,
};

export default MoodsFilters;
