import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import classnames from 'classnames';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Container, Grid, useMediaQuery, useTheme } from '@mui/material';
import dayjs from 'dayjs';

import { Button, Dialog, Typography } from '../../../atoms';
import {
  MoodCheckinInfo,
  EditingButton,
  InitiativeEmptyStateBlock,
  DialogSuccessContent,
} from '../../../moleculas';
import {
  CommentsBlock,
  PageWrapper,
  InterventionAlert,
  StudentGeneralInfo,
  MoodActivitiesBlock,
} from '../../../organisms';
import { AppActions, AppContext, UserContext } from '../../../../context';
import { useSearchParams, useSharedStudentData } from '../../../../hooks';
import {
  getFullDateAndHoursString,
  getFullName,
  getIsDomElementCropped,
  getNormalizedLanguageCode,
} from '../../../../utils';
import { useNotificationsService, useStudentsService } from '../../../../services';
import {
  ActionButtonsIdsMap,
  CommentsPagesMap,
  AlertsActions,
  AlertsStates,
  RolesMap,
  StudentsPage,
} from '../../../../constants/enums';
import { checkinFiltersValuesMap, MoodNames } from '../../../../constants/moods';
import { ReactComponent as EmptyMoodDetailsIllustration } from '../../../../resources/images/mood_details_no_selection.svg';
import { ReactComponent as EmptyFollowUpsIllustration } from '../../../../resources/images/mood_details_no_follow_ups.svg';
import { ReactComponent as EmptyActivitiesIllustration } from '../../../../resources/images/mood_details_no_activities.svg';
import { ReactComponent as MoodAlertIcon } from '../../../../resources/icons/alert_light.svg';
import { ReactComponent as ExpandIcon } from '../../../../resources/icons/chevron_down.svg';
import { ReactComponent as CollapseIcon } from '../../../../resources/icons/chevron_up.svg';
import { ReactComponent as SuccessImage } from '../../../../resources/images/message_sent.svg';

import MoodGraph from './components/mood-graph/MoodGraph';
import FollowUpQuestionsBlock from './components/follow-up-questions-block/FollowUpQuestionsBlock';
import NegativeTrendAlert from './components/negative-trend-alert/NegativeTrendAlert';
import MoodActivityLog from './components/mood-activity-log/MoodActivityLog';

const MOOD_COMMENT_PADDING = 16;

const getDefaultGraphRange = (moodDate) => {
  switch (true) {
    case dayjs(moodDate).isAfter(dayjs().subtract(1, 'week'), 'day'):
      return checkinFiltersValuesMap.LAST_WEEK;
    case dayjs(moodDate).isAfter(dayjs().subtract(1, 'month'), 'day'):
      return checkinFiltersValuesMap.LAST_MONTH;
    case dayjs(moodDate).isAfter(dayjs().subtract(1, 'year'), 'day'):
      return checkinFiltersValuesMap.LAST_YEAR;
    default:
      return checkinFiltersValuesMap.ALL;
  }
};

const MoodDetailsPage = () => {
  const { i18n, t } = useTranslation();

  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  const match = useRouteMatch();
  const personId = match.params.id;

  const [moodData, setMoodData] = useState();
  const [selectedGraphPoint, setSelectedGraphPoint] = useState(null);
  const [defaultGraphRange, setDefaultGraphRange] = useState('');
  const [isMoodCommentCropped, setIsDescriptionCropped] = useState(null);
  const [isMoodCommentCollapsed, setIsMoodCommentCollapsed] = useState(true);
  const [isCommentDialogOpen, setIsCommentDialogOpen] = useState(false);
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [commentIdToDelete, setCommentIdToDelete] = useState(null);
  const [moodCommentId, setMoodCommentId] = useState(null);
  const { state: userState } = useContext(UserContext);
  const { dispatch: dispatchAppState } = useContext(AppContext);
  const moodCommentRef = useRef(null);
  const { profile } = userState;

  const [interventionsAlerts, setInterventionsAlerts] = useState([]);

  const selectedInterventionAlert = useMemo(
    () => interventionsAlerts.find(({ resourceId }) => resourceId === selectedGraphPoint?.mood.id),
    [interventionsAlerts, selectedGraphPoint?.mood.id],
  );

  const isAlertAcknowledged = useMemo(
    () => selectedInterventionAlert?.state === AlertsStates.ACKNOWLEDGED,
    [selectedInterventionAlert?.state],
  );

  const showInterventionAlert = useMemo(
    () =>
      (selectedInterventionAlert?.state === AlertsStates.EMAIL_SEND &&
        profile?.role === RolesMap.ADMINISTRATOR) ||
      (selectedInterventionAlert?.state === AlertsStates.ACKNOWLEDGED &&
        selectedInterventionAlert?.metadata?.stateUpdateInitiator.id === profile?.id),
    [profile, selectedInterventionAlert],
  );

  const {
    closeStudentTrendNotification,
    getMoodPointDetails,
    getStudentMoodDetails,
    postNegativeTrendAcknowledge,
    postInterventionsAcknowledge,
  } = useStudentsService();
  const { getNotificationsAlerts, putReadNotificationsAlert } = useNotificationsService();

  const goodMoodArray = useMemo(() => ['HAPPY', 'CALM', 'SILLY'], []);
  const parentRoute = StudentsPage[profile?.role];
  const isCommentsAvailable =
    profile?.role === RolesMap.ADMINISTRATOR || profile?.role === RolesMap.TEACHER;

  const { activeStudent } = useSharedStudentData(personId);

  const urlParams = useSearchParams();
  const trendId = urlParams.get('trendId') || moodData?.trend?.id;
  const moodId = +urlParams.get('moodId');
  const shouldAcknowledge = urlParams.get('shouldAcknowledge');

  const formatMoodDetailsData = useCallback(
    (moodDetails) => ({
      ...moodDetails,
      moods: moodDetails?.moods?.map((item) => ({
        ...item,
        dateLabel: new Date(item.createdDate).toLocaleDateString(i18n.language, {
          month: 'short',
          day: '2-digit',
          weekday: 'short',
          year: 'numeric',
        }),
        moodLabel: t(MoodNames[item.mood]),
      })),
    }),
    [i18n.language, t],
  );

  const loadMoodData = useCallback(
    (timeRange, setGraphPoint) => {
      getStudentMoodDetails(personId, timeRange).then((moodDetails) => {
        setSelectedGraphPoint(null);
        setMoodData(formatMoodDetailsData(moodDetails));
        if (setGraphPoint) {
          setGraphPoint(moodDetails.moods.find(({ id }) => id === moodId));
        }
      });
    },
    [getStudentMoodDetails, moodId, personId, formatMoodDetailsData],
  );

  const loadMoodDetails = useCallback(
    (mood) => {
      getMoodPointDetails(personId, mood.id, getNormalizedLanguageCode(i18n.language)).then(
        (moodPointDetails) => {
          const moodDetailsData = { moodDetails: moodPointDetails, mood };
          setSelectedGraphPoint(moodDetailsData);
        },
      );
    },
    [getMoodPointDetails, i18n.language, personId],
  );

  const isGoodMood = useMemo(
    () => goodMoodArray.includes(selectedGraphPoint?.mood?.mood),
    [selectedGraphPoint, goodMoodArray],
  );

  const onTrendAlertClose = useCallback(() => {
    closeStudentTrendNotification(personId, moodData?.trend?.id).then(() => {
      setMoodData((prevState) => ({
        ...prevState,
        trend: null,
      }));
    });
  }, [closeStudentTrendNotification, moodData?.trend?.id, personId]);

  const interventionsAcknowledgeHandler = useCallback(() => {
    const { id, resourceId } = selectedInterventionAlert;
    postInterventionsAcknowledge(personId, resourceId, AlertsStates.ACKNOWLEDGED, true)
      .then(() => {
        const alertIndex = interventionsAlerts.findIndex((alert) => alert.id === id);

        const updateInitiatorData = {
          id: profile.id,
          firstName: profile.firstName,
          lastName: profile.lastName,
        };
        setInterventionsAlerts((prevState) =>
          Object.assign([...prevState], {
            [alertIndex]: {
              ...prevState[alertIndex],
              state: AlertsStates.ACKNOWLEDGED,
              stateUpdateDate: new Date(),
              metadata: {
                stateUpdateInitiator: updateInitiatorData,
              },
            },
          }),
        );
        dispatchAppState({
          type: AppActions.SET_SNACKBAR_STATUS,
          data: {
            text: t('You acknowledged the need successfully'),
            type: 'success',
          },
        });
      })
      .catch(() => {
        dispatchAppState({
          type: AppActions.SET_SNACKBAR_STATUS,
          data: {
            text: `${t('Something went wrong')}. ${t('Please try once more')}`,
            type: 'error',
            action: (
              <Button autoFocus gaLabel="Retry" onClick={interventionsAcknowledgeHandler}>
                {t('Retry')}
              </Button>
            ),
          },
        });
      });
  }, [
    dispatchAppState,
    interventionsAlerts,
    personId,
    postInterventionsAcknowledge,
    profile,
    selectedInterventionAlert,
    t,
  ]);

  const findCommentHandler = useCallback(() => {
    setIsSuccessDialogOpen(false);
    setTimeout(() => {
      const commentActionButtons = document.querySelectorAll(
        `#comments_card_${moodCommentId} > button`,
      );
      if (commentActionButtons) {
        commentActionButtons[0].focus();
      }
    }, 0);
  }, [moodCommentId]);

  const interventionsCloseHandler = useCallback(
    (commentId) => {
      const { id, resourceId } = selectedInterventionAlert;
      postInterventionsAcknowledge(personId, resourceId, AlertsStates.CLOSED, true)
        .then(() => {
          setMoodCommentId(commentId);
          putReadNotificationsAlert(selectedInterventionAlert.id);
          setInterventionsAlerts((prevState) => prevState.filter((alert) => alert.id !== id));
          setIsCommentDialogOpen(false);
          setIsSuccessDialogOpen(true);
        })
        .catch(() => {
          setCommentIdToDelete(commentId);
          setIsCommentDialogOpen(false);
        });
    },
    [
      personId,
      postInterventionsAcknowledge,
      putReadNotificationsAlert,
      selectedInterventionAlert,
      setIsCommentDialogOpen,
      setIsSuccessDialogOpen,
    ],
  );

  const negativeTrendAcknowledgeHandler = useCallback(
    (withErrorHandlers) => {
      if (!moodData) return;
      postNegativeTrendAcknowledge(personId, trendId, withErrorHandlers)
        .then(() => {
          setMoodData((prevState) => ({
            ...prevState,
            trend: { ...prevState.trend, acknowledged: true },
          }));
          if (withErrorHandlers) {
            dispatchAppState({
              type: AppActions.SET_SNACKBAR_STATUS,
              data: {
                text: t('You acknowledged the trend successfully'),
                type: 'success',
              },
            });
          }
        })
        .catch(() => {
          if (withErrorHandlers) {
            dispatchAppState({
              type: AppActions.SET_SNACKBAR_STATUS,
              data: {
                text: `${t('Something went wrong')}. ${t('Please try once more')}`,
                type: 'error',
                action: (
                  <Button autoFocus gaLabel="Retry" onClick={negativeTrendAcknowledgeHandler}>
                    {t('Retry')}
                  </Button>
                ),
              },
            });
          }
        });
    },
    [dispatchAppState, personId, postNegativeTrendAcknowledge, t, trendId, moodData],
  );

  useEffect(() => {
    if (shouldAcknowledge && trendId && moodData?.trend && !moodData?.trend?.acknowledged) {
      negativeTrendAcknowledgeHandler();
    }
  }, [moodData?.trend, negativeTrendAcknowledgeHandler, shouldAcknowledge, trendId]);

  useEffect(() => {
    if (!moodId) {
      setMoodData((prevState) => formatMoodDetailsData(prevState));
    }
  }, [formatMoodDetailsData, moodId]);

  useEffect(() => {
    setCommentIdToDelete(null);
  }, []);

  useEffect(() => {
    getNotificationsAlerts(personId).then((data) =>
      setInterventionsAlerts(
        data.filter(({ action }) => action === AlertsActions.INTERVENTION_REQUEST),
      ),
    );
  }, [getNotificationsAlerts, personId]);

  useEffect(() => {
    if (moodId) {
      getMoodPointDetails(personId, moodId, getNormalizedLanguageCode(i18n.language)).then(
        (moodPointDetails) => {
          const setGraphPoint = (mood) => {
            setSelectedGraphPoint({ moodDetails: moodPointDetails, mood });
          };
          const graphRange = getDefaultGraphRange(moodPointDetails.createdDate);
          setDefaultGraphRange(graphRange);
          loadMoodData(graphRange, setGraphPoint);
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language, moodId, personId]);

  useEffect(() => {
    setIsMoodCommentCollapsed(true);
    setIsDescriptionCropped(
      getIsDomElementCropped(
        moodCommentRef.current?.offsetHeight,
        MOOD_COMMENT_PADDING,
        moodCommentRef.current?.offsetParent?.clientHeight,
      ),
    );
  }, [selectedGraphPoint, i18n.language]);

  const selectedActivitiesCards = useMemo(
    () => ({ currentMoodActivities: selectedGraphPoint?.moodDetails.activities }),
    [selectedGraphPoint],
  );

  const closeCaseCommentDialogConfig = useMemo(
    () => ({
      isOpen: isCommentDialogOpen,
      onClose: () => setIsCommentDialogOpen(false),
      onCancel: () => setIsCommentDialogOpen(false),
      helperText:
        'Before you close the case, add a comment to inform other educators about the follow-up steps with the student.',
      title: 'Add a comment',
      submitButtonText: 'Submit and close',
      onSubmitHandler: interventionsCloseHandler,
    }),
    [isCommentDialogOpen, interventionsCloseHandler],
  );

  const lastMoodCheckInDate = useMemo(() => {
    const mostRecentDate = moodData?.moods?.reduce(
      (maxDate, currentMood) => Math.max(new Date(currentMood.createdDate), maxDate),
      0,
    );

    return mostRecentDate && new Date(mostRecentDate).toISOString();
  }, [moodData?.moods]);

  return (
    <PageWrapper
      backToLink={`${parentRoute}/${personId}`}
      backToText={t('Back to Username´s profile', {
        username: activeStudent?.firstName,
      })}
    >
      <Box pb={isWidthUpSm ? 10 : 5}>
        <Container>
          <StudentGeneralInfo student={activeStudent} />
          {userState.profile?.role === RolesMap.ADMINISTRATOR && (
            <Box display="flex" justifyContent="flex-end">
              <MoodActivityLog studentId={personId} />
            </Box>
          )}
          <Box my={5}>
            <Typography component="h2" variant="h2">
              {t('Mood check-ins')}
            </Typography>
          </Box>
          {showInterventionAlert && (
            <Box mb={moodData?.trend ? 0 : 3}>
              <InterventionAlert
                action={
                  <Button
                    gaLabel={!isAlertAcknowledged ? 'Acknowledge the need' : 'Close the case'}
                    onClick={
                      !isAlertAcknowledged
                        ? interventionsAcknowledgeHandler
                        : () => setIsCommentDialogOpen(true)
                    }
                    variant={!isAlertAcknowledged ? 'text' : 'primary'}
                  >
                    {t(!isAlertAcknowledged ? 'Acknowledge the need' : 'Close the case')}
                  </Button>
                }
                alertDetails={selectedInterventionAlert}
                studentName={getFullName(activeStudent)}
                translationKey={`interventionAlerts.administrator.${selectedInterventionAlert.state}`}
              />
            </Box>
          )}
          {moodData?.trend && (
            <NegativeTrendAlert
              acknowledgeHandler={() => negativeTrendAcknowledgeHandler(true)}
              daysCount={moodData.trend.daysCount}
              emailSendDate={moodData.trend.emailSendDate || lastMoodCheckInDate}
              icon={<MoodAlertIcon />}
              isAcknowledged={moodData.trend.acknowledged}
              onClose={onTrendAlertClose}
            />
          )}
          <Box mb={3}>
            <Typography component="h3" variant="subtitle1">
              {t('Analytics')}
            </Typography>
          </Box>
          <MoodGraph
            data={moodData?.moods}
            defaultGraphRange={defaultGraphRange}
            defaultMoodId={moodId || null}
            onActiveMoodChange={(mood) => {
              loadMoodDetails(mood);
            }}
            onTimeRangeChange={loadMoodData}
          />
          <Box mb={3} mt={8}>
            <Typography component="h3" variant="subtitle1">
              {t('Details')}
            </Typography>
          </Box>
          {selectedGraphPoint ? (
            <Box>
              <Grid container item spacing={3}>
                <Grid item md={isGoodMood ? 6 : 4} xs={12}>
                  <Box mb={1}>
                    <Typography component="h4" paragraph variant="subtitle2">
                      {t('Mood')}
                    </Typography>
                  </Box>
                  <Box className="ayo-mood-details-page">
                    <MoodCheckinInfo
                      checkinTime={getFullDateAndHoursString(
                        selectedGraphPoint.mood.createdDate,
                        i18n.language,
                      )}
                      mood={selectedGraphPoint.mood.mood}
                      titleHeadingLevel="h5"
                    />
                    <Box mt={3}>
                      <Typography component="h5" variant="subtitle2">
                        {t('Student’s comment')}
                      </Typography>
                      <Typography
                        className={classnames('ayo-mood-details-page__description', {
                          'ayo-mood-details-page__description--collapsed': !isMoodCommentCollapsed,
                        })}
                        variant="body2"
                      >
                        {selectedGraphPoint.moodDetails?.moodComment ? (
                          <span ref={moodCommentRef}>
                            {selectedGraphPoint.moodDetails?.moodComment}
                          </span>
                        ) : (
                          t('No comment added by the student')
                        )}
                      </Typography>
                      {isMoodCommentCropped && (
                        <Box my={1}>
                          <EditingButton
                            gaLabel={isMoodCommentCollapsed ? 'Show more' : 'Show less'}
                            icon={isMoodCommentCollapsed ? <ExpandIcon /> : <CollapseIcon />}
                            iconPosition="end"
                            onClick={() => {
                              setIsMoodCommentCollapsed(!isMoodCommentCollapsed);
                            }}
                            text={t(isMoodCommentCollapsed ? 'Show more' : 'Show less')}
                          />
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Grid>
                {!isGoodMood && (
                  <Grid item md={4} xs={12}>
                    <Typography component="h4" variant="subtitle2">
                      {t('Follow-up questions')}
                    </Typography>
                    <Box mt={2}>
                      {selectedGraphPoint.moodDetails.followUps.length ? (
                        <FollowUpQuestionsBlock
                          firstname={activeStudent?.firstName}
                          questions={selectedGraphPoint.moodDetails.followUps}
                        />
                      ) : (
                        <InitiativeEmptyStateBlock
                          illustration={<EmptyFollowUpsIllustration />}
                          title={t('No answers provided by the student')}
                        />
                      )}
                    </Box>
                  </Grid>
                )}
                <Grid item md={isGoodMood ? 6 : 4} xs={12}>
                  <>
                    <Typography component="h4" variant="subtitle2">
                      {t('SEL strategies selected')}
                    </Typography>
                    <Box mt={2}>
                      {selectedGraphPoint.moodDetails.activities.length ? (
                        <MoodActivitiesBlock
                          activitiesCards={selectedActivitiesCards}
                          cardGridTargetSize={6}
                          cardHeadingLevel={2}
                          gaLabel="Mood recommendation"
                          isCurrentMoodActivities
                          withCroppedActivities
                        />
                      ) : (
                        <InitiativeEmptyStateBlock
                          illustration={<EmptyActivitiesIllustration />}
                          title={t('No strategies selected by the student')}
                        />
                      )}
                    </Box>
                  </>
                </Grid>
              </Grid>
              {isCommentsAvailable && (
                <Box mt={isWidthUpSm ? 8 : 5}>
                  <CommentsBlock
                    commentIdToDelete={commentIdToDelete}
                    discardButtonId={ActionButtonsIdsMap.DELETE}
                    outterDialogConfig={closeCaseCommentDialogConfig}
                    page={CommentsPagesMap.MOOD_DETAILS}
                    relatedUserId={personId}
                    resourceId={selectedGraphPoint.mood.id}
                  />
                </Box>
              )}
            </Box>
          ) : (
            <InitiativeEmptyStateBlock
              illustration={<EmptyMoodDetailsIllustration />}
              title={t('Select any point from the graph to reveal more information')}
            />
          )}
        </Container>
        <Dialog onClose={() => setIsSuccessDialogOpen(false)} open={isSuccessDialogOpen}>
          <DialogSuccessContent
            buttonClickHandler={findCommentHandler}
            buttonText="Show my comment"
            gaLabel="Show my comment"
            isFormSubmitted
            successImage={
              <SuccessImage
                aria-label={t('A piece of paper with something written on it and a message Sent')}
                role="img"
              />
            }
            text="You can see it in the mood details"
            title="Thank you for your comment!"
          />
        </Dialog>
      </Box>
    </PageWrapper>
  );
};

export default MoodDetailsPage;
