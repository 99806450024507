import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { useSearchParams } from '../../../../hooks';
import { transformSearchToObject } from '../../../../utils';
import { AnalyticsStatsKeys, HighlightsInitiatives } from '../../../../constants/enums';
import PieChartComponent from '../components/pie-chart/PieChartComponent';

const OpportunitiesHighlights = ({ stats, totalStudentsCount, onFiltersUpdate }) => {
  const search = useSearchParams();
  const filters = useMemo(() => transformSearchToObject(search), [search]);

  return (
    <PieChartComponent
      key={filters.filterValue}
      filterCategory={HighlightsInitiatives.OPPORTUNITIES}
      gaLabel="Opportunities"
      onFiltersUpdate={onFiltersUpdate}
      pieColors={['#FFC107', '#FEF9CD']}
      statKey={AnalyticsStatsKeys.OPPORTUNITIES}
      stats={stats}
      totalStudentsCount={totalStudentsCount}
    />
  );
};

OpportunitiesHighlights.propTypes = {
  stats: PropTypes.instanceOf(Object).isRequired,
  totalStudentsCount: PropTypes.number.isRequired,
  onFiltersUpdate: PropTypes.func.isRequired,
};

export default OpportunitiesHighlights;
