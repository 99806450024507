import React from 'react';

import BaseStaticAssignment from '../base-static-assignment/BaseStaticAssignment';
import { staticAssignmentType } from '../../../../../../constants/propTypes';

const getDisplayDatesWithoutDeadline = () => [
  { title: 'Posted in Google Classroom', field: 'postingDate' },
];

const StaticAssignmentNoDeadline = ({
  assignment,
  canSelectChoices,
  choices,
  courseStudents,
  isOwner,
  onChoicesChange,
  onChoicesSelectedChange,
  hideAssignedStudents,
}) => (
  <BaseStaticAssignment
    assignment={assignment}
    canSelectChoices={canSelectChoices}
    choices={choices}
    courseStudents={courseStudents}
    datesCreator={getDisplayDatesWithoutDeadline}
    hideAssignedStudents={hideAssignedStudents}
    isOwner={isOwner}
    onChoicesChange={onChoicesChange}
    onChoicesSelectedChange={onChoicesSelectedChange}
  />
);

StaticAssignmentNoDeadline.propTypes = staticAssignmentType;

export default StaticAssignmentNoDeadline;
