import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { Box, Container, useMediaQuery, useTheme } from '@mui/material';

import { Button, Typography } from '../../atoms';
import { InitiativeEmptyStateBlock, NewFeatureHotspot } from '../../moleculas';
import { ConfirmDialog, MobileNotSupported, PageWrapper } from '../../organisms';
import { NewFeaturesIdsMap } from '../../../tours/common/NewFeaturesItemsProvider';
import { getGroupedArrayByKey, sortArrayByKey } from '../../../utils';
import { usePortfolioData, useSharedStudentData } from '../../../hooks';
import { ReactComponent as PublishedPortfoliosEmptyStateIllustration } from '../../../resources/images/portfolio/published_empty_state.svg';
import { ReactComponent as DraftPortfoliosEmptyStateIllustration } from '../../../resources/images/portfolio/draft_empty_state.svg';
import { ReactComponent as OtherRolesEmptyStateIllustration } from '../../../resources/images/portfolio/empty_state_not_student.svg';
import { ReactComponent as PlusIcon } from '../../../resources/icons/plus.svg';
import {
  PortfolioSortingConfig,
  PublicationStatuses,
  RolesMap,
  StudentsPage,
} from '../../../constants/enums';
import { UserContext } from '../../../context';
import { familyFeedRoute } from '../../../constants/routes';

import CreatePortfolioSection from './components/create-portfolio-section/CreatePortfolioSection';
import PortfolioSectionByStatus from './components/portfolio-section-by-status/PortfolioSectionByStatus';

const sectionsSubtitleByRole = {
  [RolesMap.STUDENT]: {
    subtitle: '',
    PUBLISHED:
      'Here are your published portfolios. They are visible to your educators and parents in AYO.',
    DRAFT:
      'Here is your work in progress. It’s visible to your educators and parents in AYO unless you change the settings.',
  },
  [RolesMap.TEACHER]: {
    subtitle: 'This is the place in AYO where you can see your students’ portfolios.',
    PUBLISHED: 'Here are your student’s completed portfolios.',
    DRAFT: 'Here are your student’s portfolios in progress.',
  },
  [RolesMap.ADMINISTRATOR]: {
    subtitle: 'This is the place in AYO where you can see your students’ portfolios.',
    PUBLISHED: 'Here are your student’s completed portfolios.',
    DRAFT: 'Here are your student’s portfolios in progress.',
  },
  [RolesMap.GUARDIAN]: {
    subtitle: 'This is the place in AYO where you can see your kid’s portfolios.',
    PUBLISHED: 'Here are your kid’s completed portfolios.',
    DRAFT: 'Here are your kid’s portfolios in progress.',
  },
};

const PortfolioMainPage = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const match = useRouteMatch();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
  const { state: userState } = useContext(UserContext);
  const { studentId } = match.params;
  const { activeStudent } = useSharedStudentData(studentId);

  const isStudent = userState.profile.role === RolesMap.STUDENT;
  const isGuardian = userState.profile.role === RolesMap.GUARDIAN;

  const [publishedSortingOptions, setPublishedSortingOptions] = useState(
    PortfolioSortingConfig.BY_NEWEST_PUBLISHED_ON_TOP,
  );
  const [draftSortingOptions, setDraftSortingOptions] = useState(
    PortfolioSortingConfig.BY_NEWEST_ON_TOP,
  );
  const [isPublishedCardsCollapsed, setIsPublishedCardsCollapsed] = useState(true);
  const [isDraftCardsCollapsed, setIsDraftCardsCollapsed] = useState(true);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [portfolioToDeleteId, setPortfolioToDeleteId] = useState(null);

  const {
    allStudentPortfolios,
    createPortfolioHandler,
    deletePortfolioHandler,
    getPublishedPortfolioActions,
    unpublishPortfolio,
  } = usePortfolioData();

  const groupedPortfolios = useMemo(
    () => getGroupedArrayByKey(allStudentPortfolios, 'status'),
    [allStudentPortfolios],
  );

  const onDeleteDialogCloseHandler = () => setIsDeleteDialogOpen(false);
  const onPortfolioDeleteHandler = useCallback(() => {
    deletePortfolioHandler(portfolioToDeleteId, PublicationStatuses.PUBLISHED);
    setPortfolioToDeleteId(null);
    setIsDeleteDialogOpen(false);
  }, [portfolioToDeleteId, deletePortfolioHandler]);

  const publishedPortfolios = useMemo(() => {
    if (groupedPortfolios?.PUBLISHED) {
      return sortArrayByKey(
        groupedPortfolios?.PUBLISHED,
        publishedSortingOptions.key,
        'date',
        publishedSortingOptions.order,
      );
    }
    return [];
  }, [groupedPortfolios, publishedSortingOptions]);

  const draftPortfolios = useMemo(() => {
    if (groupedPortfolios?.DRAFT) {
      return sortArrayByKey(
        groupedPortfolios?.DRAFT,
        draftSortingOptions.key,
        'date',
        draftSortingOptions.order,
      );
    }
    return [];
  }, [groupedPortfolios, draftSortingOptions]);

  const deleteHandler = useCallback((id) => {
    setPortfolioToDeleteId(id);
    setIsDeleteDialogOpen(true);
  }, []);

  const backToProfileLink = useMemo(
    () =>
      isGuardian
        ? `${familyFeedRoute}/${studentId}`
        : `${StudentsPage[userState.profile?.role]}/${studentId}`,
    [isGuardian, studentId, userState.profile?.role],
  );

  return (
    <PageWrapper
      backToLink={!isStudent && backToProfileLink}
      backToText={
        !isStudent &&
        t('Back to Username´s profile', {
          username: activeStudent?.firstName,
        })
      }
      mainElementClassName="ayo-portfolio-main-page"
    >
      {isWidthUpSm ? (
        <Container>
          {allStudentPortfolios && allStudentPortfolios.length ? (
            <Box>
              <Box alignItems="center" display="flex">
                <Typography component="h1" variant="h1">
                  {t(isStudent ? 'My portfolio(s)' : 'Username´s portfolio', {
                    username: activeStudent?.firstName,
                  })}
                </Typography>
                {isStudent && (
                  <NewFeatureHotspot
                    id={NewFeaturesIdsMap.PORTFOLIO_MULTIPLE}
                    isClickable
                    label={t('Create multiple portfolios')}
                  />
                )}
              </Box>
              <Box mb={3}>
                <Typography maxWidth="50%" variant="body2">
                  {t(sectionsSubtitleByRole[userState?.profile.role].subtitle)}
                </Typography>
              </Box>
              <Box mt={5}>
                <Typography component="h2" variant="h2">
                  {t('Published')}
                </Typography>
                {publishedPortfolios?.length ? (
                  <PortfolioSectionByStatus
                    deleteHandler={isStudent ? deleteHandler : null}
                    getPublishedPortfolioActions={getPublishedPortfolioActions}
                    isCardsCollapsed={isPublishedCardsCollapsed}
                    portfolios={publishedPortfolios}
                    sectionSubtitle={t(sectionsSubtitleByRole[userState?.profile.role].PUBLISHED)}
                    setIsCardsCropped={setIsPublishedCardsCollapsed}
                    setSortingOptions={setPublishedSortingOptions}
                    sortingOption={publishedSortingOptions}
                    status={PublicationStatuses.PUBLISHED}
                    unpublishHandler={unpublishPortfolio}
                  />
                ) : (
                  <Box mt={3}>
                    <InitiativeEmptyStateBlock
                      body={
                        isStudent &&
                        t(
                          'As soon as you publish one, it will appear here, and it will be visible to your educators and parents in AYO.',
                        )
                      }
                      illustration={<PublishedPortfoliosEmptyStateIllustration />}
                      title={t('No published portfolios so far')}
                    />
                  </Box>
                )}
                <Box mt={5}>
                  <Typography component="h2" variant="h2">
                    {t('Drafts')}
                  </Typography>
                  {draftPortfolios?.length ? (
                    <PortfolioSectionByStatus
                      isCardsCollapsed={isDraftCardsCollapsed}
                      portfolios={draftPortfolios}
                      sectionSubtitle={t(sectionsSubtitleByRole[userState?.profile.role].DRAFT)}
                      setIsCardsCropped={setIsDraftCardsCollapsed}
                      setSortingOptions={setDraftSortingOptions}
                      sortingOption={draftSortingOptions}
                    />
                  ) : (
                    <Box mt={3}>
                      {isStudent ? (
                        <Typography maxWidth="50%" variant="body2">
                          {t(
                            'You don’t have any portfolios in progress now. You can always create a new one as soon as you need it.',
                          )}
                        </Typography>
                      ) : (
                        <InitiativeEmptyStateBlock
                          body={t('')}
                          illustration={<DraftPortfoliosEmptyStateIllustration />}
                          title={t('No drafts created so far')}
                        />
                      )}
                    </Box>
                  )}
                </Box>
              </Box>
              {isStudent && (
                <Box mt={3}>
                  <Button
                    endIcon={<PlusIcon />}
                    gaLabel="Create new portfolio"
                    onClick={createPortfolioHandler}
                  >
                    {t('Create new')}
                  </Button>
                </Box>
              )}
            </Box>
          ) : isStudent ? (
            allStudentPortfolios !== null && (
              <CreatePortfolioSection createPortfolioHandler={createPortfolioHandler} />
            )
          ) : (
            <Box>
              <Typography component="h1" variant="h1">
                {t('Username´s portfolio', { username: activeStudent?.firstName })}
              </Typography>
              <Box mb={3} mt={1}>
                <Typography variant="body2">
                  {t(sectionsSubtitleByRole[userState?.profile.role].subtitle)}
                </Typography>
              </Box>
              <InitiativeEmptyStateBlock
                illustration={<OtherRolesEmptyStateIllustration />}
                title={t('No portfolios created so far')}
              />
            </Box>
          )}
        </Container>
      ) : (
        <MobileNotSupported
          explanation={t(
            'Please open My portfolio section with a tablet or desktop version to use this functionality',
          )}
        />
      )}
      <ConfirmDialog
        cancelButtonTitle="Cancel"
        confirmButtonTitle="Delete portfolio"
        isOpen={isDeleteDialogOpen}
        onClose={onDeleteDialogCloseHandler}
        onConfirm={() => onPortfolioDeleteHandler(portfolioToDeleteId)}
        text="AYO won’t be able to restore it"
        title="Are you sure you want to delete your portfolio?"
      />
    </PageWrapper>
  );
};

export default PortfolioMainPage;
