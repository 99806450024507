import React from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { DynamicGrid } from '../../../../atoms';
import { AptitudeInfoCard, InitiativeEmptyStateBlock } from '../../../../moleculas';
import { PortfolioSectionTypes } from '../../../../../constants/enums';
import PortfolioSection from '../../components/portfolio-section/PortfolioSection';
import { ReactComponent as EmptyStateIllustration } from '../../../../../resources/images/aptitudes_empty_state.svg';

const PortfolioSharedAptitudesSection = ({ sectionData }) => {
  const { t } = useTranslation();

  return (
    <PortfolioSection
      body={
        <Box mt={3}>
          <DynamicGrid
            gridItems={sectionData.entities.map((aptitude) => (
              <AptitudeInfoCard key={aptitude.type} aptitude={aptitude.type} titleComponent="h3" />
            ))}
            isHorizontalOrder
          />
        </Box>
      }
      className={`${classNames('ayo-full-screen-portfolio-section', {
        'ayo-portfolio__aptitudes-section': sectionData.entities.length,
      })}`}
      emptyState={
        !sectionData.entities.length ? (
          <InitiativeEmptyStateBlock
            illustration={<EmptyStateIllustration />}
            title={t('No aptitudes found so far')}
          />
        ) : null
      }
      reflection={sectionData.reflection}
      sectionKey={PortfolioSectionTypes.PORTFOLIO__APTITUDES}
      title={t('Aptitudes')}
    />
  );
};

PortfolioSharedAptitudesSection.propTypes = {
  sectionData: PropTypes.instanceOf(Object).isRequired,
};

export default PortfolioSharedAptitudesSection;
