import React, { useCallback, useMemo, useState } from 'react';
import { Box, Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { PreviewBox, Typography } from '../../../../atoms';
import { PreviewGallery, TextWithTooltip } from '../../../../moleculas';
import { GA, getFileTypeFromExtension } from '../../../../../utils';
import { GaActions, GaCategories } from '../../../../../constants/enums';
import { IconsByTypeMap } from '../../../../../constants/fileTypes';
import { apiRoute } from '../../../../../constants/routes';

const PortfolioAttachments = ({
  attachments,
  gridItemBreakpoint,
  title,
  titleComponent,
  token,
}) => {
  const { t } = useTranslation();
  const [galleryActiveIndex, setGalleryActiveIndex] = useState(null);
  const galleryItems = useMemo(
    () => ({
      onSlide: (newIndex) => {
        setGalleryActiveIndex(newIndex);
        GA.logInteraction({
          category: GaCategories.BEHAVIOR,
          action: GaActions.BUTTON_CLICK,
          label: 'File preview - slide change',
        });
      },
      onThumbnailClick: () => {
        GA.logInteraction({
          category: GaCategories.BEHAVIOR,
          action: GaActions.THUMBNAIL_CLICK,
          label: 'File preview - thumb',
        });
      },
      startIndex: galleryActiveIndex !== -1 ? galleryActiveIndex : 0,
      items: attachments.map((item, i) => ({
        // Can be url or any text, as long as we provide renderThumbInner function
        thumbnail: item.id,
        renderThumbInner: () => {
          const Icon = IconsByTypeMap[getFileTypeFromExtension(item.fileName)];
          return (
            <>
              <Icon />
              <TextWithTooltip title={item.fileName} titleVariant="subtitle2" />
            </>
          );
        },
        renderItem: () => (
          <PreviewBox
            key={item.id}
            src={`${apiRoute}/attachments/${item.id}/owners/${item.ownerId}${
              token ? `/shared/${token}` : ''
            }`}
            tabIndex={galleryActiveIndex === i ? 0 : -1}
            type={getFileTypeFromExtension(item.fileName)}
          />
        ),
      })),
    }),
    [attachments, galleryActiveIndex, token],
  );

  const onAttachmentClick = useCallback((i) => {
    setGalleryActiveIndex(i);
    GA.logInteraction({
      category: GaCategories.BEHAVIOR,
      action: GaActions.BUTTON_CLICK,
      label: 'File preview - item click',
    });
  }, []);

  return (
    <Box className="ayo-portfolio-attachments">
      <Box mb={1}>
        <Typography component={titleComponent} variant="subtitle2">
          {t(title)}
        </Typography>
      </Box>
      <Grid container spacing={1}>
        {attachments.map((attachment, i) => {
          const Icon = IconsByTypeMap[getFileTypeFromExtension(attachment.fileName)];
          return (
            <Grid key={attachment.id} item xs={gridItemBreakpoint}>
              <Button
                aria-label={attachment.fileName}
                className="ayo-portfolio-attachments__icon-wrapper"
                disableRipple
                fullWidth
                onClick={() => {
                  onAttachmentClick(i);
                }}
              >
                <Icon />
              </Button>
              <Typography variant="body3">
                <Box display="flex" flexDirection="row" justifyContent="center" px={0.5}>
                  <span className="ayo-portfolio-attachments__file-title">
                    {attachment.fileName.substring(0, attachment.fileName.lastIndexOf('.'))}
                  </span>
                  <span className="ayo-portfolio-attachments__file-type">
                    {attachment.fileName.substring(
                      attachment.fileName.lastIndexOf('.'),
                      attachment.fileName.length,
                    )}
                  </span>
                </Box>
              </Typography>
            </Grid>
          );
        })}
      </Grid>
      {galleryActiveIndex !== null && (
        <PreviewGallery
          galleryItems={galleryItems}
          isOpen={galleryActiveIndex >= 0}
          onClose={() => setGalleryActiveIndex(-1)}
        />
      )}
    </Box>
  );
};

PortfolioAttachments.propTypes = {
  attachments: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  gridItemBreakpoint: PropTypes.number,
  title: PropTypes.string,
  titleComponent: PropTypes.string,
  token: PropTypes.string.isRequired,
};

PortfolioAttachments.defaultProps = {
  title: 'Attachments',
  titleComponent: 'h4',
  gridItemBreakpoint: 4,
};

export default PortfolioAttachments;
