import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Box, DialogActions, DialogContent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Button, Dialog, DialogTitle, RadioGroup, Typography } from '../../../../../atoms';
import {
  DisabledControlWithTooltip,
  EditingButton,
  InformationalCaption,
  PopupActionsButtons,
  STTTextField,
} from '../../../../../moleculas';
import { ReactComponent as CloseIcon } from '../../../../../../resources/icons/close.svg';
import { ReactComponent as PlusIcon } from '../../../../../../resources/icons/plus.svg';
import { ReactComponent as CloseRedIcon } from '../../../../../../resources/icons/close_red.svg';
import { ConfirmDialog, RichTextEditor } from '../../../../../organisms';
import { choicesPathLayerType } from '../../../../../../constants/propTypes';
import { ChoiceOptionsColorVariants } from '../../assignment-insights/shared';
import ChoicesPathOptionBubble from '../../choices-path-option-bubble/ChoicesPathOptionBubble';
import {
  GaActions,
  GaCategories,
  InputsValidationErrors,
  InputsValidationRules,
} from '../../../../../../constants/enums';
import { formatUrl, GA, shuffle } from '../../../../../../utils';
import { AppActions, AppContext } from '../../../../../../context';
import { urlAllowedValidationRE } from '../../../../../../constants/regexps';
import { useSnackbar } from '../../../../../../hooks';

const MAX_LAYERS = 3;

const createDefaultChoice = (level) => ({
  layer: level,
  title: '',
  description: '',
  options: [],
});

const createDefaultChoices = () => ({ isMultipleOptions: true, layers: [createDefaultChoice(0)] });

const FORMATTING_OPTIONS = ['styling', 'lists', 'links'];
const EditOptionDialog = ({ isOpen, onClose, currentOption, onSave }) => {
  const { t } = useTranslation();
  const [newValue, setNewValue] = useState(currentOption);
  useEffect(() => {
    setNewValue(currentOption);
  }, [currentOption, isOpen]);
  const [error, setError] = useState('');
  const editorRef = useRef();
  const { setSnackBarStatus } = useSnackbar();
  const onSaveClick = useCallback(() => {
    let errorText = '';
    const rawData = editorRef.current.getText();
    if (rawData.trim().length < InputsValidationRules.MIN_INPUT_LENGTH) {
      errorText = InputsValidationErrors(t, InputsValidationRules.MIN_INPUT_LENGTH).MIN_ERROR_TEXT;
    }
    if (rawData.trim().length > InputsValidationRules.MAX_INPUT_LENGTH) {
      errorText = InputsValidationErrors(t, InputsValidationRules.MAX_INPUT_LENGTH).MAX_ERROR_TEXT;
    }
    if (errorText) {
      setError(errorText);
      return;
    }
    onSave(newValue);
    onClose();
  }, [newValue, onClose, onSave, t]);

  return (
    <Dialog
      className="choices-path__edit-dialog"
      gaLabel="Add / adjust choice option"
      onClose={onClose}
      open={isOpen}
    >
      <DialogTitle disableTypography>
        <Box mb={3}>
          <Typography variant="subtitle1">{t('Add / adjust choice option')}</Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box mb={1}>
          <Typography variant="subtitle2">{t('Description')}*</Typography>
        </Box>
        <RichTextEditor
          bounds=".MuiDialogContent-root"
          defaultFormat="bold"
          editorRef={editorRef}
          errorMsg={error}
          formattingOptions={FORMATTING_OPTIONS}
          linkSaveCallback={(origSave, tooltip) => () => {
            const url = formatUrl(tooltip.textbox.value);
            if (!urlAllowedValidationRE.test(url)) {
              setSnackBarStatus({
                text: t('Invalid link format'),
                type: 'error',
              });
              return;
            }
            origSave.apply(tooltip);
          }}
          onChange={(_, value) => {
            setError('');
            setNewValue(value);
          }}
          placeholder={t('Add description of the choice here')}
          value={newValue}
        />
      </DialogContent>
      <DialogActions>
        <Box mt={2}>
          <PopupActionsButtons
            primaryButtonGaLabel="Save choice option"
            primaryButtonHandler={onSaveClick}
            primaryButtonText={t('Save')}
            secondaryButtonGaLabel="Cancel choice option add / adjust"
            secondaryButtonHandler={onClose}
            secondaryButtonText={t('Cancel')}
          />
        </Box>
      </DialogActions>
    </Dialog>
  );
};

EditOptionDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  currentOption: PropTypes.string,
  onSave: PropTypes.func.isRequired,
};

EditOptionDialog.defaultProps = {
  currentOption: '',
};

const MAX_OPTIONS_COUNT = 12;

const generateNewColor = (options) => {
  const availableColors = Object.keys(ChoiceOptionsColorVariants);
  const usedColors = options.map((option) => option.color);
  const shuffledColors = shuffle(availableColors);
  const newColor = shuffledColors.find((color) => !usedColors.includes(color));
  return newColor;
};

const ChoicesPathTypes = {
  MULTIPLE: 'multiple',
  ONE: 'one',
};

const AssignmentChoicesPath = ({
  choices,
  onChoicesChange,
  onLayerChange,
  onChoicesDelete,
  onChoicesLayerDelete,
  errors,
  isPublished,
}) => {
  const [isDeleteChoicesOpen, setIsDeleteChoicesOpen] = useState(false);
  const [layerToDelete, setLayerToDelete] = useState(null);
  const { t } = useTranslation();

  const isMaxLayersReached = choices?.layers.length >= MAX_LAYERS;

  const [editingOption, setEditingOption] = useState(null);

  const { dispatch: dispatchAppContext } = useContext(AppContext);

  const showSnackbar = (text, type) =>
    dispatchAppContext({ type: AppActions.SET_SNACKBAR_STATUS, data: { type, text } });

  const showSuccessSnackbar = (text) => showSnackbar(text, 'success');

  const showDeleteSnackbar = (text) => showSnackbar(text, 'delete');

  const onOptionChange = (layerIdx, optionIdx, newValue) => {
    const newOptions = [...choices.layers[layerIdx].options];
    if (newValue) {
      const color = newOptions[optionIdx]?.color ?? generateNewColor(newOptions);
      newOptions[optionIdx] = {
        ...(newOptions[optionIdx] ?? {}),
        color,
        textJson: newValue,
      };
    } else {
      newOptions.splice(optionIdx, 1);
    }
    onLayerChange(layerIdx, {
      options: newOptions,
    });
  };

  return choices ? (
    <Box className="choices-path__container">
      <Box display="flex" justifyContent="space-between">
        <Box alignItems="center" display="flex" gap={1} mb={3}>
          <Typography variant="h2">{t('Choices path')}</Typography>
          <InformationalCaption showTooltip title="Choices path tooltip" />
        </Box>
        <Button
          aria-label={t('Delete')}
          gaLabel="Delete choices path"
          isIconButton
          onClick={() => setIsDeleteChoicesOpen(true)}
        >
          <CloseIcon height="24px" width="24px" />
        </Button>
      </Box>
      <Box mb={2}>
        <Typography variant="subtitle2">{t('Choices selection')}</Typography>
      </Box>
      <Box display="flex" gap={3} mb={5}>
        <Typography variant="body2">{t('Students can select')}</Typography>
        <RadioGroup
          onChange={(e) => {
            onChoicesChange({ isMultipleOptions: e.target.value === ChoicesPathTypes.MULTIPLE });
          }}
          options={[
            { text: t('Multiple options'), value: ChoicesPathTypes.MULTIPLE },
            { text: t('One option'), value: ChoicesPathTypes.ONE },
          ]}
          row
          value={choices.isMultipleOptions ? ChoicesPathTypes.MULTIPLE : ChoicesPathTypes.ONE}
        />
      </Box>
      <Box display="flex" flexDirection="column" gap={2}>
        {choices.layers.map((layer, layerIdx) => {
          const layerErrors = errors[layer.id ?? layer.tempId] ?? {};
          return (
            <Box key={layer.level} className="choices-path__layer-container">
              <Box display="flex" justifyContent="space-between">
                <Box alignItems="center" display="flex" gap={1} mb={3}>
                  <Typography variant="subtitle1">
                    {t('Step')} {layer.layer + 1}
                  </Typography>
                  <InformationalCaption showTooltip title="Choices path step tooltip" />
                </Box>
                <Button
                  aria-label={t('Delete')}
                  disabled={choices.layers.length === 1}
                  gaLabel="Delete choice path layer"
                  isIconButton
                  onClick={() => setLayerToDelete(layer.layer)}
                >
                  <CloseIcon height="24px" width="24px" />
                </Button>
              </Box>
              <Box mb={4}>
                <Box mb={2}>
                  <Typography variant="subtitle2">{t('Add step title')}</Typography>
                </Box>
                <STTTextField
                  error={!!layerErrors.title}
                  fullWidth
                  helperText={layerErrors.title?.errorText}
                  onChange={(e) => onLayerChange(layer.layer, { title: e.target.value })}
                  outlined
                  placeholder={t('Step title')}
                  value={layer.title}
                />
              </Box>
              <Box mb={4}>
                <Box mb={2}>
                  <Typography variant="subtitle2">{t('Add step description')}</Typography>
                </Box>
                <STTTextField
                  error={!!layerErrors.description}
                  fullWidth
                  helperText={layerErrors.description?.errorText}
                  multiline
                  onChange={(e) => onLayerChange(layer.layer, { description: e.target.value })}
                  outlined
                  placeholder={t('Step description')}
                  rowsCount={2}
                  value={layer.description}
                />
              </Box>
              <Box>
                <Box mb={2}>
                  <Typography variant="subtitle2">{t('Add choice options')}</Typography>
                </Box>
                <Box className="choices-path__options-container">
                  {layer.options.map((choiceOption, choiceIdx) => (
                    <Box key={choiceIdx} position="relative">
                      <ChoicesPathOptionBubble
                        color={choiceOption.color}
                        onClick={() => {
                          setEditingOption({ layer: layer.layer, option: choiceIdx });
                          GA.logInteraction({
                            category: GaCategories.BEHAVIOR,
                            action: GaActions.BUBBLE_CLICK,
                            label: 'Add choice option',
                          });
                        }}
                        text={choiceOption.textJson}
                        variant={ChoiceOptionsColorVariants[choiceOption.color]}
                      />
                      <EditingButton
                        aria-label={t('Delete')}
                        className="choices-path__option-delete"
                        icon={<CloseRedIcon />}
                        onClick={() => {
                          onOptionChange(layerIdx, choiceIdx, null);
                          showDeleteSnackbar(t('The choice option has been deleted.'));
                          GA.logInteraction({
                            category: GaCategories.BEHAVIOR,
                            action: GaActions.BUBBLE_CLICK,
                            label: 'Delete choice option',
                          });
                        }}
                      />
                    </Box>
                  ))}
                  {layer.options.length < MAX_OPTIONS_COUNT && (
                    <Box>
                      <Box
                        className="choices-path__option clickable"
                        onClick={() =>
                          setEditingOption({ layer: layer.layer, option: layer.options.length })
                        }
                        tabIndex="0"
                      >
                        <PlusIcon />
                      </Box>
                    </Box>
                  )}
                </Box>
                {!!layerErrors.options && (
                  <Typography className="ayo-validation-error" variant="body2">
                    {layerErrors.options.errorText}
                  </Typography>
                )}
              </Box>
            </Box>
          );
        })}
      </Box>
      <Box mt={5}>
        <DisabledControlWithTooltip
          showTooltip={isMaxLayersReached}
          title={t("You can't add more than 3 layers.")}
          wrapperClassName="fit-content-container"
        >
          <Button
            disabled={isMaxLayersReached}
            endIcon={<PlusIcon />}
            gaLabel="Add one more choice path layer"
            onClick={() =>
              onLayerChange(choices.layers.length, createDefaultChoice(choices.layers.length))
            }
          >
            {t('Add one more step')}
          </Button>
        </DisabledControlWithTooltip>
      </Box>
      <ConfirmDialog
        cancelButtonTitle="Cancel"
        confirmButtonTitle="Delete choices path"
        isOpen={isDeleteChoicesOpen}
        onClose={() => setIsDeleteChoicesOpen(false)}
        onConfirm={() => {
          onChoicesDelete(choices.id);
          setIsDeleteChoicesOpen(false);
          showDeleteSnackbar(t('The choices path has been deleted.'));
        }}
        text={
          isPublished
            ? 'Published choices path delete text'
            : 'You have already added some content here'
        }
        title="Choices path delete title"
        variant="delete"
      />
      <ConfirmDialog
        cancelButtonTitle="Cancel"
        confirmButtonTitle="Delete step"
        isOpen={layerToDelete !== null}
        onClose={() => setLayerToDelete(null)}
        onConfirm={() => {
          onChoicesLayerDelete(choices.id, choices.layers[layerToDelete].id, layerToDelete);
          setLayerToDelete(null);
        }}
        text={
          isPublished
            ? 'Published choices path delete text'
            : 'You have already added some content here'
        }
        title="Are you sure you want to delete this step?"
        variant="delete"
      />
      <EditOptionDialog
        currentOption={
          choices.layers[editingOption?.layer]?.options[editingOption?.option]?.textJson ??
          '{ "ops": [{ "insert": "\\n" }] }'
        }
        isOpen={!!editingOption}
        onClose={() => setEditingOption(null)}
        onSave={(newValue) => {
          onOptionChange(editingOption.layer, editingOption.option, newValue);
          showSuccessSnackbar(t('The choice option has been successfully added!'));
        }}
      />
    </Box>
  ) : (
    <Button
      className="choices-path__add-path"
      endIcon={<PlusIcon />}
      fullWidth
      gaLabel="Add choices path"
      onClick={() => {
        onChoicesChange(createDefaultChoices());
        showSuccessSnackbar(
          t(
            'The choices path has been successfully added! Feel free to populate it for your students.',
          ),
        );
      }}
      variant="text"
    >
      {t('Add choices path')}
    </Button>
  );
};

AssignmentChoicesPath.propTypes = {
  choices: PropTypes.shape(choicesPathLayerType),
  onChoicesChange: PropTypes.func.isRequired,
  onLayerChange: PropTypes.func.isRequired,
  onChoicesDelete: PropTypes.func.isRequired,
  onChoicesLayerDelete: PropTypes.func.isRequired,
  errors: PropTypes.instanceOf(Object),
  isPublished: PropTypes.bool,
};

AssignmentChoicesPath.defaultProps = {
  choices: null,
  errors: {},
  isPublished: false,
};

export default AssignmentChoicesPath;
