import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Dropdown } from '../../../../../moleculas';
import { ClubKeys, ClubTypes } from '../../../../../../constants/club-hub';

const ClubType = ({ clubType, onChange }) => {
  const { t } = useTranslation();

  return (
    <Dropdown
      fullWidth
      gaLabel="Club type"
      handleChange={(e) => {
        onChange({ [ClubKeys.TYPE]: e.target.value });
      }}
      isResponsive
      label={t('Club type')}
      options={Object.values(ClubTypes).map((type) => ({
        value: type,
        label: t(`club_hub_types.${type}`),
      }))}
      value={clubType}
    />
  );
};

ClubType.propTypes = {
  clubType: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ClubType;
