import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../../atoms';
import { LeadershipAttributesActions, LeadershipAttributesContext } from '../../../context';
import { ReactComponent as ChartIcon } from '../../../resources/icons/web.svg';

const LeadershipAttributesGraphButton = () => {
  const { t } = useTranslation();
  const { dispatch: dispatchLeadershipAttributes } = useContext(LeadershipAttributesContext);
  const openGraphDialogHandler = () => {
    dispatchLeadershipAttributes({
      type: LeadershipAttributesActions.SET_DIALOG_OPEN_STATE,
      data: true,
    });
  };
  return (
    <Button
      gaLabel="See maturity graph"
      onClick={openGraphDialogHandler}
      startIcon={<ChartIcon />}
      variant="text"
    >
      {t('See maturity graph')}
    </Button>
  );
};

export default LeadershipAttributesGraphButton;
