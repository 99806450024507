import { useContext, useCallback, useEffect } from 'react';

import useLazyDataLoad from '../use-lazy-data-load/useLazyDataLoad';
import useSharedStudentData from '../use-shared-student-data/useSharedStudentData';
import { useProfileService } from '../../services';
import { StatusCodeMap } from '../../services/HttpClient';
import {
  LeadershipAttributesContext,
  LeadershipAttributesActions,
  UserContext,
  AppContext,
  AppActions,
} from '../../context';
import LEADERSHIP_ATTRIBUTES_GALERY from '../../constants/leadership-attributes-galery';
import { mergeArrayOfObjectsHelper } from '../../utils/array-helpers/arrayHelpers';
import { Resourses, RolesMap } from '../../constants/enums';

let isLoading = false;

const useLeadershipAttributesData = (studentId, isLazyLoad) => {
  const {
    getLeadershipAttributesGraphData,
    getLeadershipAttributes,
    createLeadershipAttributesData,
    getSuggestedActivities,
  } = useProfileService();

  const { state: leadershipAttributesState, dispatch: dispatchLeadershipAttributes } = useContext(
    LeadershipAttributesContext,
  );
  const { graphData, leadershipAttributes, suggestedActivities } = leadershipAttributesState;

  const { activeStudent } = useSharedStudentData(studentId);

  const { state: appState, dispatch: dispatchAppState } = useContext(AppContext);

  const { state: userState } = useContext(UserContext);

  const getLeadershipAttributesInfo = useCallback(
    async (onLoad) => {
      if (!studentId || isLoading) return;
      const isAttributesReloadNeeded = appState.sectionsToReload.includes(
        Resourses.LEADERSHIP_ATTRIBUTES,
      );
      const isDataUpdateNeeded =
        (graphData.length === 0 && leadershipAttributes.length === 0) ||
        +activeStudent?.id !== +studentId ||
        isAttributesReloadNeeded;
      const updatedLeadershipInfo = {};
      isLoading = true;
      if (isDataUpdateNeeded) {
        updatedLeadershipInfo.graphData = await getLeadershipAttributesGraphData(
          studentId,
          isLazyLoad || isAttributesReloadNeeded,
        );
        if (userState.profile.role === RolesMap.STUDENT) {
          updatedLeadershipInfo.suggestedActivities = await getSuggestedActivities(
            studentId,
            isLazyLoad || isAttributesReloadNeeded,
          );
        }
        getLeadershipAttributes(studentId, isLazyLoad || isAttributesReloadNeeded).then(
          async (response) => {
            let leadershipAttributesData;
            if (response.status === StatusCodeMap.NO_CONTENT) {
              leadershipAttributesData = await createLeadershipAttributesData(
                studentId,
                isLazyLoad || isAttributesReloadNeeded,
              );
              updatedLeadershipInfo.graphData = await getLeadershipAttributesGraphData(
                studentId,
                isLazyLoad,
              );
            } else {
              leadershipAttributesData = response.data;
            }
            const mergedAttributes = mergeArrayOfObjectsHelper(
              LEADERSHIP_ATTRIBUTES_GALERY,
              leadershipAttributesData,
              'key',
            );
            updatedLeadershipInfo.leadershipAttributes = mergedAttributes;
            isLoading = false;
            dispatchLeadershipAttributes({
              type: LeadershipAttributesActions.SET_LEADERSHIP_ATTRIBUTES_ASSESSMENT_DATA,
              data: updatedLeadershipInfo,
            });
            if (isAttributesReloadNeeded) {
              const filteredSection = appState.sectionsToReload.filter(
                (section) => section !== Resourses.LEADERSHIP_ATTRIBUTES,
              );
              dispatchAppState({
                type: AppActions.UPDATE_SECTIONS_TO_RELOAD,
                data: filteredSection,
              });
            }
            if (onLoad) {
              onLoad();
            }
          },
        );
      } else {
        isLoading = false;
        if (onLoad) {
          onLoad();
        }
      }
    },
    [
      activeStudent,
      appState.sectionsToReload,
      createLeadershipAttributesData,
      dispatchAppState,
      dispatchLeadershipAttributes,
      getLeadershipAttributesGraphData,
      getLeadershipAttributes,
      getSuggestedActivities,
      graphData,
      isLazyLoad,
      leadershipAttributes,
      studentId,
      userState.profile,
    ],
  );

  const { isDataLoaded, loadActivatorRef } = useLazyDataLoad(
    () =>
      new Promise((resolve) => {
        getLeadershipAttributesInfo(resolve);
      }),
  );

  useEffect(() => {
    if (!isLazyLoad) {
      getLeadershipAttributesInfo();
    }
  }, [getLeadershipAttributesInfo, isLazyLoad, studentId, appState.sectionsToReload]);

  const updateLeadershipAttributeEntity = useCallback(
    (entity, value, mergeKey) => {
      const newData = {
        [entity]: mergeArrayOfObjectsHelper(leadershipAttributesState[entity], [value], mergeKey),
      };
      dispatchLeadershipAttributes({
        type: LeadershipAttributesActions.SET_LEADERSHIP_ATTRIBUTES_ASSESSMENT_DATA,
        data: newData,
      });
    },
    [dispatchLeadershipAttributes, leadershipAttributesState],
  );

  return {
    activeStudent,
    graphData,
    lazyLoadConfig: { isDataLoaded, loadActivatorRef },
    leadershipAttributes,
    suggestedActivities,
    updateLeadershipAttributeEntity,
  };
};

export default useLeadershipAttributesData;
