/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef, useState } from 'react';
import { Box, ClickAwayListener, Divider, Popper } from '@mui/material';
import { Autocomplete } from '@mui/lab';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { Card, Typography } from '../../atoms';
import { Highlighter } from '../../../utils';
import StudentExtracurricularItem from '../student-extracurricular-item/StudentExtracurricularItem';
import EditableChip from '../chips/editable-chip/EditableChip';
import Chip from '../chips/chip/Chip';

const CustomAutocompletePopper = ({ modifiers, ...props }) => (
  <Popper
    {...props}
    className="ayo-input-chip-autocomplete-popper"
    modifiers={{ ...modifiers, flip: { enabled: false } }}
    placement="bottom-start"
  />
);

CustomAutocompletePopper.propTypes = {
  modifiers: PropTypes.instanceOf(Object),
};

CustomAutocompletePopper.defaultProps = {
  modifiers: {},
};

const MAX_AUTOCOMPLETE_ITEMS = 5;
const MIN_AUTOCOMPLETE_INPUT_CHARS = 2;

const ExtracurricularsYear = ({
  availableExtracurriculars,
  onExtracurricularDelete,
  onExtracurricularAdd,
  onExtracurricularChange,
  onExtracurricularEdit,
  extracurriculars,
  label,
  isEditing,
  year,
  headerLevel,
}) => {
  const containerRef = useRef(null);

  const [highlightedSuggestion, setHiglightedSuggestion] = useState(null);

  const { t } = useTranslation();

  const [groupedAvailableExtracurriculars, setGroupedAvailableExtracurriculars] = useState({});

  useEffect(() => {
    const groups = availableExtracurriculars
      .filter(
        (extracurricular) =>
          !extracurriculars.find(
            (currentExtracurricular) => currentExtracurricular.name === extracurricular.name,
          ),
      )
      .reduce((acc, extracurricular) => {
        const group = extracurricular.type || 'Other';
        return { ...acc, [group]: [...(acc[group] || []), extracurricular] };
      }, {});
    setGroupedAvailableExtracurriculars(groups);
  }, [availableExtracurriculars, extracurriculars]);

  return (
    <Card
      className="ayo-extracurricular-year-card"
      elevation={isEditing ? 1 : 0}
      mainContent={
        <Box width="100%">
          <Typography component={`h${headerLevel}`} paragraph variant="subtitle2">
            {label}
          </Typography>
          {isEditing && (
            <Box mb={2} mt={3}>
              <Typography component={`h${headerLevel + 1}`} variant="subtitle2">
                {t('Added')}
              </Typography>
            </Box>
          )}
          <Box ref={containerRef}>
            {isEditing && (
              <Box display="inline-block" mb={1} mr={1}>
                <Chip
                  className="ayo-add-extracurricular-button"
                  gaLabel="Add extracurricular"
                  label={t('+ Add yours')}
                  onClick={() =>
                    onExtracurricularAdd({ year, isEditable: true, name: '', tempId: uuid() })
                  }
                />
              </Box>
            )}
            {isEditing || extracurriculars.length ? (
              extracurriculars.map((extracurricular) =>
                !extracurricular.isEditable ? (
                  <StudentExtracurricularItem
                    key={
                      extracurricular.id ||
                      extracurricular.tempId ||
                      extracurricular.year + extracurricular.name
                    }
                    extracurricular={extracurricular.name}
                    mode={isEditing ? 'delete' : null}
                    onClick={isEditing ? () => onExtracurricularDelete(extracurricular) : undefined}
                    yearLabel={label}
                  />
                ) : (
                  <Autocomplete
                    className="ayo-input-chip-autocomplete"
                    filterOptions={(options, params) => {
                      if (params.inputValue.length < MIN_AUTOCOMPLETE_INPUT_CHARS) return [];
                      let result = [];
                      for (
                        let i = 0;
                        i < options.length && result.length < MAX_AUTOCOMPLETE_ITEMS;
                        i += 1
                      ) {
                        const option = options[i];
                        if (
                          option.name
                            .toString()
                            .toLowerCase()
                            .includes(params.inputValue.toLowerCase())
                        ) {
                          result = [...result, option];
                        }
                      }
                      return result;
                    }}
                    freeSolo
                    getOptionLabel={(option) => option.name}
                    inputValue={extracurricular.currentValue || ''}
                    ListboxProps={{
                      onClick: () => {
                        onExtracurricularEdit(extracurricular, highlightedSuggestion.name);
                      },
                    }}
                    onHighlightChange={(e, option) => {
                      setHiglightedSuggestion(option);
                    }}
                    openOnFocus
                    options={availableExtracurriculars}
                    PopperComponent={CustomAutocompletePopper}
                    renderInput={(params) => {
                      const inputContentEditableProps = {
                        ...params.inputProps,
                        role: 'textbox',
                        contentEditable: true,
                        style: { minWidth: '20px' },
                        className: 'ayo-input-chip-textfield',
                        'aria-multiline': false,
                        'aria-label': t('Enter new extracurricular title'),
                        onBlur: (e) => {
                          onExtracurricularEdit(extracurricular, e.target.innerText);
                          params.inputProps.onBlur();
                        },
                        onInput: (e) => {
                          onExtracurricularChange(extracurricular, e.target.innerText);
                        },
                        onKeyDown: (e) => {
                          if (e.key === 'Enter') {
                            e.preventDefault();
                            containerRef.current
                              .querySelector('.ayo-add-extracurricular-button')
                              .focus();
                            if (highlightedSuggestion) {
                              onExtracurricularEdit(extracurricular, highlightedSuggestion.name);
                              e.target.innerText = highlightedSuggestion.name;
                            }
                          }
                        },
                        tabIndex: '0',
                        suppressContentEditableWarning: true,
                      };
                      return (
                        <ClickAwayListener
                          onClickAway={(e) => {
                            if (
                              document
                                .querySelector('.ayo-input-chip-autocomplete-popper')
                                ?.contains(e.target)
                            )
                              return;
                            document.activeElement.blur();
                          }}
                        >
                          <Box display="inline-block" maxWidth="100%" mb={1} mr={1}>
                            <EditableChip
                              ref={params.InputProps.ref}
                              autoFocusInner
                              className="ayo-input-chip"
                              label={extracurricular.name}
                              labelComponentProps={inputContentEditableProps}
                              mode="delete"
                              onDelete={() => onExtracurricularDelete(extracurricular)}
                            />
                          </Box>
                        </ClickAwayListener>
                      );
                    }}
                    renderOption={(props, option, params) => (
                      <li {...props}>
                        <Typography variant="body2">
                          {Highlighter(option.name, [params.inputValue])}
                        </Typography>
                      </li>
                    )}
                  />
                ),
              )
            ) : (
              <Typography variant="body2">{t('No information so far')}</Typography>
            )}
          </Box>
          {Object.keys(groupedAvailableExtracurriculars).length ? (
            <>
              <Divider />
              <Box mb={2} mt={3}>
                <Typography component={`h${headerLevel + 1}`} variant="subtitle2">
                  {t('To add')}
                </Typography>
              </Box>
              {Object.entries(groupedAvailableExtracurriculars)
                .sort(([groupNameA], [groupNameB]) => groupNameA.localeCompare(groupNameB))
                .map(([groupName, groupItems]) => (
                  <Box key={groupName} mb={3}>
                    <Box mb={1}>
                      <Typography component={`h${headerLevel + 2}`} isLabel variant="caption">
                        {t(groupName)}
                      </Typography>
                    </Box>
                    {groupItems.map((extracurricular) => (
                      <StudentExtracurricularItem
                        key={extracurricular.name}
                        extracurricular={extracurricular.name}
                        mode="add"
                        onClick={() =>
                          onExtracurricularAdd({ name: extracurricular.name, year, tempId: uuid() })
                        }
                        yearLabel={label}
                      />
                    ))}
                  </Box>
                ))}
            </>
          ) : null}
        </Box>
      }
    />
  );
};

ExtracurricularsYear.propTypes = {
  availableExtracurriculars: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
  onExtracurricularDelete: PropTypes.func.isRequired,
  onExtracurricularAdd: PropTypes.func.isRequired,
  onExtracurricularChange: PropTypes.func.isRequired,
  onExtracurricularEdit: PropTypes.func.isRequired,
  extracurriculars: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      year: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      id: PropTypes.number,
    }),
  ),
  label: PropTypes.string,
  isEditing: PropTypes.bool,
  headerLevel: PropTypes.number,
  year: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

ExtracurricularsYear.defaultProps = {
  availableExtracurriculars: [],
  extracurriculars: null,
  label: '',
  isEditing: true,
  headerLevel: 3,
};

export default ExtracurricularsYear;
