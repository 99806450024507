import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { useMediaQuery, useTheme } from '@mui/material';
import YouTube from 'react-youtube';

import { Avatar, Typography } from '../../atoms';
import { quoteBlock } from '../../../constants/propTypes';

const QuoteBlock = ({ config }) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const isWidthUpMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Container>
      <Typography
        align="center"
        isLightText
        mb={isWidthUpSm ? 5 : 0}
        mt={isWidthUpSm ? 0 : 3}
        variant="h1"
      >
        {t(config.text)}
      </Typography>
      <Grid
        container
        direction={isWidthUpSm ? 'row' : 'column'}
        justifyContent="center"
        spacing={isWidthUpSm ? 8 : 3}
      >
        <Grid item justify="flex-end">
          <Box pt={isWidthUpSm ? 10 : 4}>
            <Box display="flex" justifyContent={isWidthUpMd ? 'flex-end' : 'center'} pb={2}>
              <Avatar alt={config.avatarTxt} size="3x" src={config.avatarImg} />
            </Box>
            <Typography align={isWidthUpMd ? 'right' : 'center'} component="p" variant="h2">
              {t(config.name)}
            </Typography>
            <Typography align={isWidthUpSm ? 'right' : 'center'} paragraph variant="body2">
              {t(config.title)}
            </Typography>
          </Box>
        </Grid>
        <Grid item lg={6} sm={12} xl={8} xs={12}>
          <YouTube
            opts={{
              width: '100%',
              minHeight: '223px',
              playerVars: {
                rel: 0,
              },
            }}
            videoId={config.videoId}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

QuoteBlock.propTypes = {
  config: quoteBlock,
};

QuoteBlock.defaultProps = {
  config: {},
};

export default QuoteBlock;
