import React from 'react';
import PropTypes from 'prop-types';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Typography } from '../../atoms';
import { Chip, EditingButton } from '../../moleculas';
import { ReactComponent as CloseIcon } from '../../../resources/icons/close.svg';

const ChipsBlock = ({
  chipsTitleHeaderLevel,
  entities,
  onDeleteHandler,
  clearAllHandler,
  actionButtons,
  withEditableChips,
  editableChipsTitle,
  notEditableChipsTitle,
  subtitle,
  icon,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });

  return (
    <Box className="ayo-chips-block">
      {withEditableChips ? (
        <Box>
          {!!entities.length && (
            <Box mt={3}>
              <Typography component={`h${chipsTitleHeaderLevel}`} mb={1} variant="subtitle2">
                {editableChipsTitle}
              </Typography>
              {!!subtitle && (
                <Typography mb={1} variant="body2">
                  {subtitle}
                </Typography>
              )}
              <Box display="flex" flexWrap="wrap" gap={1} mb={2}>
                {entities.map((item) => (
                  <Box
                    key={`${item.id} - ${item.name}`}
                    className="ayo-chips-block__wrapper"
                    maxWidth={isWidthUpSm ? '45%' : '100%'}
                  >
                    <Chip
                      ariaLabel={t('Remove item by pressing delete', { item: item.name })}
                      className="ayo-chips-block__delete-chip"
                      deleteIcon={<CloseIcon />}
                      gaLabel="Remove who can see the post"
                      icon={icon}
                      label={item.name}
                      onDelete={() => onDeleteHandler(item)}
                    />
                  </Box>
                ))}
                {entities.length > 1 && (
                  <EditingButton
                    className="ayo-chips-block__clear-all-chips"
                    icon={<CloseIcon />}
                    iconPosition="end"
                    onClick={clearAllHandler}
                    text={t('Clear all')}
                  />
                )}
              </Box>
            </Box>
          )}
          {actionButtons}
        </Box>
      ) : (
        <Box>
          {!!entities.length && (
            <Box mb={2} mt={5}>
              <Typography component="h3" mb={1} variant="subtitle2">
                {notEditableChipsTitle}
              </Typography>
              {!!subtitle && (
                <Typography mb={1} variant="body2">
                  {subtitle}
                </Typography>
              )}
              <Box display="flex" flexWrap="wrap" gap={1} mt={1}>
                {entities.map((item) => (
                  <Chip
                    key={item.id}
                    ariaLabel={item.name}
                    className="ayo-chips-block__chip"
                    icon={icon}
                    label={item.name}
                  />
                ))}
              </Box>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

ChipsBlock.propTypes = {
  chipsTitleHeaderLevel: PropTypes.number,
  entities: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  onDeleteHandler: PropTypes.func.isRequired,
  clearAllHandler: PropTypes.func.isRequired,
  actionButtons: PropTypes.node,
  withEditableChips: PropTypes.bool.isRequired,
  editableChipsTitle: PropTypes.string,
  notEditableChipsTitle: PropTypes.string,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  icon: PropTypes.node,
};

ChipsBlock.defaultProps = {
  chipsTitleHeaderLevel: 3,
  entities: [],
  actionButtons: null,
  editableChipsTitle: '',
  notEditableChipsTitle: '',
  subtitle: '',
  icon: null,
};

export default ChipsBlock;
