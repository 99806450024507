import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Container } from '@mui/material';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { Typography } from '../../../atoms';
import { BackNavigationLink, CampusLabel } from '../../../moleculas';
import { PageWrapper } from '../../../organisms';
import { useLessonPlannerData } from '../../../../hooks';
import { myLessonsRoute, notFoundPageRoute } from '../../../../constants/routes';
import { LessonPlannerStates, Semesters } from '../../../../constants/enums';
import { ReactComponent as CloseIcon } from '../../../../resources/icons/close_primary_simple.svg';
import { AppActions, AppContext, UserContext } from '../../../../context';
import PlannerSchedulePage from '../planner-schedule/PlannerSchedulePage';

import BasicSettings from './components/basic-settings/BasicSettings';
import CoursesSettings from './components/courses-settings/CoursesSettings';

const LessonPlannerCreatePage = () => {
  const { t } = useTranslation();

  const { setUpLessonPlannerData, lessonPlannerData } = useLessonPlannerData();

  const { state: userState } = useContext(UserContext);

  const history = useHistory();

  const { dispatch: dispatchAppState } = useContext(AppContext);

  const {
    params: { scheduleId },
  } = useRouteMatch();

  useEffect(() => {
    if (!userState.profile?.currentCampusSchoolLevel) return;
    setUpLessonPlannerData(userState.profile.isQuestTeacher).catch(() =>
      history.replace(notFoundPageRoute),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState.profile.currentCampusSchoolLevel]);

  if (
    lessonPlannerData?.state === LessonPlannerStates.CREATED &&
    +lessonPlannerData?.id === +scheduleId
  ) {
    return <PlannerSchedulePage />;
  }

  return (
    <PageWrapper
      customFooter={<></>}
      customHeader={
        <Container>
          <Box py={3}>
            <BackNavigationLink
              backToLink={myLessonsRoute}
              icon={<CloseIcon />}
              onClick={() => {
                if (lessonPlannerData?.state !== LessonPlannerStates.CREATING_ROTATION_DATA) {
                  dispatchAppState({
                    type: AppActions.SET_SNACKBAR_STATUS,
                    data: {
                      text: t('Your progress on the lesson planner creation has been saved.'),
                      type: 'success',
                    },
                  });
                }
              }}
              text={t('Quit lesson planner setup')}
            />
          </Box>
        </Container>
      }
      mainElementClassName="ayo-lesson-planner"
      noi18n
      noNav
    >
      {lessonPlannerData ? (
        <Container>
          <Typography component="h1" variant="h1">
            {t('Lesson planner setup')}
          </Typography>
          <CampusLabel />
          {lessonPlannerData.state === LessonPlannerStates.CREATING_ROTATION_DATA && (
            <BasicSettings />
          )}
          {lessonPlannerData.state === LessonPlannerStates.CREATING_COURSE_SCHEDULE_FALL && (
            <CoursesSettings
              schoolYear={lessonPlannerData.stateData.schoolYear}
              semester={Semesters.FALL}
              semesterName="Fall"
              year={lessonPlannerData.stateData.schoolYear - 1}
            />
          )}
          {lessonPlannerData.state === LessonPlannerStates.CREATING_COURSE_SCHEDULE_SPRING && (
            <CoursesSettings
              schoolYear={lessonPlannerData.stateData.schoolYear}
              semester={Semesters.SPRING}
              semesterName="Spring"
              year={lessonPlannerData.stateData.schoolYear}
            />
          )}
        </Container>
      ) : null}
    </PageWrapper>
  );
};

export default LessonPlannerCreatePage;
