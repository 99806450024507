import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Fade } from '@mui/material';

import { Dialog } from '../../atoms';
import {
  ClassLinkLogoutContent,
  SessionTimeoutContent,
  SessionExpiredContent,
} from '../../moleculas';
import { AppActions, AppContext, UserContext } from '../../../context';
import { DeviceTypesMap, LoginProvidersMap } from '../../../constants/enums';
import {
  useProfileService,
  useClassLinkTokenService,
  useNotificationsService,
} from '../../../services';
import { rootRoute } from '../../../constants/routes';

const MAX_TIME_LEFT = 60;

let timer;

const SessionTimeoutDialog = () => {
  const { state: appState, dispatch: dispatchAppState } = useContext(AppContext);
  const { state: userState } = useContext(UserContext);
  const [timeLeft, setTimeLeft] = useState(MAX_TIME_LEFT);
  const [showClassLinkReminder, setShowClassLinkReminder] = useState(false);
  const [sessionExpired, setSessionExpired] = useState(false);

  const isPublicClassLinkDevice =
    appState.deviceType === DeviceTypesMap.PUBLIC &&
    appState.loginProvider === LoginProvidersMap.CLASSLINK;

  const history = useHistory();
  const { getProfile } = useProfileService();
  const { revokeToken } = useClassLinkTokenService();
  const { closeNotificationsSocket } = useNotificationsService();

  const clearTimer = () => {
    clearInterval(timer);
    timer = null;
    setTimeLeft(MAX_TIME_LEFT);
  };

  const clearState = useCallback(() => {
    setShowClassLinkReminder(false);
    setSessionExpired(false);
    dispatchAppState({
      type: AppActions.SET_IDLE_TIMEOUT_EXPIRED,
      data: false,
    });
  }, [dispatchAppState]);

  const handleAlertClose = useCallback(() => {
    clearTimer();
    clearState();
    getProfile();
  }, [clearState, getProfile]);

  const redirectToHomePage = useCallback(() => {
    clearState();
    history.push(rootRoute);
  }, [clearState, history]);

  useEffect(() => {
    // If user sees expiration dialog and on other tab session is extended, we need to close dialog and reset timer
    if (!appState.idleTimeoutExpired) {
      clearTimer();
      clearState();
    }
  }, [appState.idleTimeoutExpired, clearState]);

  useEffect(() => {
    // If user is logged in and session is about to expire, we give him 60 sec to extend session or logout
    if (
      userState.profile &&
      appState.idleTimeoutExpired &&
      !sessionExpired &&
      timeLeft === MAX_TIME_LEFT
    ) {
      timer = setInterval(() => {
        setTimeLeft((time) => time - 1);
      }, 1000);
    }
    // If time is up and session is expired, we are forcing log out and revoke token
    if (userState.profile && timer && timeLeft < 1) {
      clearTimer();
      closeNotificationsSocket();
      if (appState.loginProvider === LoginProvidersMap.CLASSLINK) {
        revokeToken(true);
      }
      setSessionExpired(true);
    }
  }, [
    closeNotificationsSocket,
    revokeToken,
    sessionExpired,
    timeLeft,
    appState.idleTimeoutExpired,
    appState.loginProvider,
    userState.profile,
  ]);

  return (
    <Dialog
      containerClassName="ayo-session-timeout-dialog--content"
      onClose={!sessionExpired ? handleAlertClose : redirectToHomePage}
      open={!!userState.profile && !!appState.idleTimeoutExpired}
    >
      <>
        {!sessionExpired && !showClassLinkReminder && (
          <SessionTimeoutContent onLoginButtonClick={handleAlertClose} timeLeft={timeLeft} />
        )}
        {!sessionExpired && showClassLinkReminder && (
          <Fade in={showClassLinkReminder} timeout={1000}>
            <ClassLinkLogoutContent />
          </Fade>
        )}
        {sessionExpired && (
          <Fade in={sessionExpired} timeout={1000}>
            <SessionExpiredContent
              isPublicClassLinkDevice={isPublicClassLinkDevice}
              onGoToLandingButtonClick={redirectToHomePage}
            />
          </Fade>
        )}
      </>
    </Dialog>
  );
};

export default SessionTimeoutDialog;
