import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { Box, Container, useMediaQuery, useTheme } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import { MobileNotSupported, PageWrapper } from '../../organisms';
import { Button, DynamicGrid, Typography } from '../../atoms';
import { CampusLabel, DisabledControlWithTooltip, NewFeatureHotspot } from '../../moleculas';
import { campusSelectionRoute, createRoute, myLessonsRoute } from '../../../constants/routes';
import {
  AppActions,
  AppContext,
  LessonPlannerActions,
  LessonPlannerContext,
  UserContext,
} from '../../../context';
import { useLessonsService, useTeachersService } from '../../../services';
import { StatusCodeMap } from '../../../services/HttpClient';
import { sortArrayByKey, useProfile } from '../../../utils';
import { useLessonPlannerData } from '../../../hooks';
import { ReactComponent as PlusIcon } from '../../../resources/icons/plus.svg';
import { NewFeaturesIdsMap } from '../../../tours/common/NewFeaturesItemsProvider';

import LessonPlannerCard from './components/lesson-planner-card/LessonPlannerCard';
import LessonsRepositoryBlock from './components/lesson-repository-block/LessonsRepositoryBlock';

const MyLessonsPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });

  const { dispatch: dispatchAppState } = useContext(AppContext);
  const { state: userState } = useContext(UserContext);
  const { state: lessonPlannerState, dispatch: dispatchLessonPlannerState } =
    useContext(LessonPlannerContext);

  const {
    getLessonScheduleCreationState,
    deleteLessonSchedule,
    patchLessonSchedule,
    getTeachersList,
  } = useTeachersService();
  const { getLessonsMetadata, getSharedLessonsMetadata } = useLessonsService();

  const { getIsCampusSelectionAvailable } = useProfile();
  const { lessonPlannerData, dispatchLessonPlannerData } = useLessonPlannerData();
  const { lessonsMetadata, lessonPlanners, teachersList } = lessonPlannerState;

  const [sharedLessons, setSharedLessons] = useState([]);

  useEffect(() => {
    if (userState.profile.currentCampus) {
      getLessonScheduleCreationState(userState.profile.currentCampus).then((data) => {
        dispatchLessonPlannerState({
          type: LessonPlannerActions.SET_LESSON_PLANNERS,
          data,
        });
      });

      if (!teachersList) {
        getTeachersList(userState.profile.currentCampus).then((data) => {
          dispatchLessonPlannerState({
            type: LessonPlannerActions.SET_TEACHERS_LIST,
            data,
          });
        });
      }

      getLessonsMetadata(userState.profile.currentCampus).then((data) => {
        dispatchLessonPlannerState({
          type: LessonPlannerActions.SET_LESSONS_METADATA,
          data,
        });
      });

      if (!sharedLessons.length) {
        getSharedLessonsMetadata(userState.profile.currentCampus).then((data) => {
          setSharedLessons(data);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDeletePlanner = useCallback(
    (id) => {
      deleteLessonSchedule(id)
        .then((response) => {
          if (response.status === StatusCodeMap.OK) {
            getLessonsMetadata(userState.profile.currentCampus).then((data) => {
              dispatchLessonPlannerState({
                type: LessonPlannerActions.SET_LESSONS_METADATA,
                data,
              });
            });

            dispatchLessonPlannerState({
              type: LessonPlannerActions.SET_LESSON_PLANNERS,
              data: lessonPlanners.filter((planner) => planner.id !== id),
            });

            dispatchAppState({
              type: AppActions.SET_SNACKBAR_STATUS,
              data: {
                text: t('The lesson planner has been deleted'),
                type: 'delete',
              },
            });
          }
        })
        .catch(() => {
          dispatchAppState({
            type: AppActions.SET_SNACKBAR_STATUS,
            data: {
              text: t('Failed to delete the lesson planner. Please try once more'),
              type: 'error',
            },
          });
        });
    },
    [
      deleteLessonSchedule,
      dispatchAppState,
      dispatchLessonPlannerState,
      getLessonsMetadata,
      lessonPlanners,
      t,
      userState.profile.currentCampus,
    ],
  );

  const onRenamePlanner = useCallback(
    (id, scheduleNewName) => {
      const lessonPlanner = lessonPlanners.find((planner) => planner.id === id);
      const newLessonPlanner = {
        ...lessonPlanner,
        stateData: {
          ...lessonPlanner.stateData,
          scheduleName: scheduleNewName,
        },
      };

      patchLessonSchedule({ scheduleName: scheduleNewName }, id)
        .then(() => {
          dispatchLessonPlannerState({
            type: LessonPlannerActions.SET_LESSON_PLANNERS,
            data: [...lessonPlanners.filter((planner) => planner.id !== id), newLessonPlanner],
          });

          dispatchAppState({
            type: AppActions.SET_SNACKBAR_STATUS,
            data: {
              text: t('Thanks for your changes! Your input has been saved'),
              type: 'success',
            },
          });
        })
        .catch(() => {
          dispatchAppState({
            type: AppActions.SET_SNACKBAR_STATUS,
            data: {
              text: t('AYO couldn’t save your edits Please try once more'),
              type: 'error',
            },
          });
        });
    },
    [dispatchAppState, dispatchLessonPlannerState, lessonPlanners, patchLessonSchedule, t],
  );

  const onLessonPlannerClick = useCallback(
    (id) => {
      history.push(`${myLessonsRoute}/${id}`);
    },
    [history],
  );

  return (
    <PageWrapper
      backToLink={getIsCampusSelectionAvailable() ? campusSelectionRoute : null}
      backToText={getIsCampusSelectionAvailable() ? t('Go to campus selection') : ''}
      mainElementClassName="ayo-my-lessons-page"
    >
      <Container>
        {isWidthUpSm ? (
          <Box pb={5}>
            <Box alignItems="center" display="flex">
              <Typography component="h1" variant="h1">
                {t('My lessons')}
              </Typography>
              <NewFeatureHotspot id={NewFeaturesIdsMap.LESSON_PLANNER_ALL} isClickable />
            </Box>
            <CampusLabel />
            <Box pb={5}>
              <Typography isLabel paragraph variant="body2">
                <Trans components={{ br: <br /> }} i18nKey="Space to design lessons paragraph" />
              </Typography>
            </Box>
            <Box>
              <Typography variant="h2">{t('My lesson planners')}</Typography>
            </Box>
            <Box pb={2}>
              {lessonPlanners?.length ? (
                <Box pt={3}>
                  <DynamicGrid
                    columnsCount={3}
                    gridItems={sortArrayByKey(lessonPlanners, 'createdDate', 'date').map(
                      (planner) => (
                        <LessonPlannerCard
                          key={planner.id}
                          createdDate={planner.createdDate}
                          id={planner.id}
                          onClick={onLessonPlannerClick}
                          onDelete={onDeletePlanner}
                          onRename={onRenamePlanner}
                          scheduleName={planner.stateData.scheduleName}
                          schoolYear={planner.stateData.schoolYear}
                          semesters={
                            planner.stateData.semestersSchedule
                              ?.map((semesterSchedule) => semesterSchedule.semester)
                              .sort() ?? []
                          }
                        />
                      ),
                    )}
                    isHorizontalOrder
                  />
                </Box>
              ) : (
                <Box pt={1}>
                  <Typography isLabel paragraph variant="body2">
                    <Trans components={{ br: <br /> }} i18nKey="No lesson planner yet paragraph" />
                  </Typography>
                </Box>
              )}
            </Box>
            <DisabledControlWithTooltip
              showTooltip={!userState.profile.currentCampus}
              title={<Trans components={{ br: <br /> }} i18nKey="AYO is preparing data tooltip" />}
              wrapperClassName="add-lesson__link__wrapper"
            >
              <Button
                className="add-lesson__link"
                component={RouterLink}
                disabled={!userState.profile.currentCampus}
                endIcon={<PlusIcon />}
                gaLabel="Create a planner"
                onClick={() => {
                  if (lessonPlannerData) {
                    dispatchLessonPlannerData(null);
                  }
                }}
                to={`${myLessonsRoute}${createRoute}`}
                variant="text"
              >
                {t('Create a planner')}
              </Button>
            </DisabledControlWithTooltip>
            <LessonsRepositoryBlock myLessons={lessonsMetadata} sharedLessons={sharedLessons} />
          </Box>
        ) : (
          <MobileNotSupported
            explanation={t(
              'Please open My lessons section with a tablet or desktop version to use this functionality',
            )}
          />
        )}
      </Container>
    </PageWrapper>
  );
};

export default MyLessonsPage;
