import React from 'react';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import PropTypes from 'prop-types';

import { Typography } from '../../../../atoms';
import FamilyActivityCard from '../family-activity-card/FamilyActivityCard';

const FamilyActivitiesBlock = ({ title, subheader, items, isSuggested, onLearnMoreClick }) => {
  const theme = useTheme();
  const isWidthUpMd = useMediaQuery(theme.breakpoints.up('md'), { noSsr: true });

  return (
    <Grid container item mb={isSuggested ? 8 : 3} xs={12}>
      <Grid item mb={3} xs={12}>
        <Typography component="h2" variant={isWidthUpMd ? 'h2' : 'subtitle1'}>
          {title}
        </Typography>
      </Grid>
      {!!subheader && (
        <Grid item mb={3}>
          {subheader}
        </Grid>
      )}
      <Grid container item spacing={3} xs={12}>
        {items?.map(({ id, title: cardTitle, description, imgAltText, imageUrl }, i) => (
          <FamilyActivityCard
            key={id}
            description={description}
            imgAlt={imgAltText}
            imgSrc={imageUrl}
            isSuggested={isSuggested}
            onLearnMoreClick={() => onLearnMoreClick(items, id, i, isSuggested)}
            title={cardTitle}
          />
        ))}
      </Grid>
    </Grid>
  );
};

FamilyActivitiesBlock.propTypes = {
  title: PropTypes.string.isRequired,
  subheader: PropTypes.node,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      activityUrl: PropTypes.string,
      imageUrl: PropTypes.string,
      insightType: PropTypes.string,
      insightValue: PropTypes.shape({
        name: PropTypes.string,
        nameKey: PropTypes.string,
      }),
    }),
  ),
  isSuggested: PropTypes.bool,
  onLearnMoreClick: PropTypes.func.isRequired,
};

FamilyActivitiesBlock.defaultProps = {
  subheader: null,
  items: [],
  isSuggested: false,
};

export default FamilyActivitiesBlock;
