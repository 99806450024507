import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';

import { Button } from '../../../atoms';
import { PageIllustrationBlock, ScreenCenteredContainer } from '../../../moleculas';
import { PageWrapper } from '../../../organisms';
import {
  downloadInspirationBookSpanishRoute,
  downloadInspirationBookEnglishRoute,
} from '../../../../constants/routes';
import { ReactComponent as WellBeBackTeacherImage } from '../../../../resources/images/profiling_well_be_back_teacher.svg';
import { ReactComponent as DownloadIcon } from '../../../../resources/icons/download.svg';

const OtherRolePage = () => {
  const { t } = useTranslation();

  return (
    <PageWrapper>
      <ScreenCenteredContainer>
        <PageIllustrationBlock
          Illustration={WellBeBackTeacherImage}
          illustrationAriaLabel={t(
            'An apple, three paperclips, a pen, three papers with Thank you words on one of these',
          )}
          mainHeaderText={t('Thank you for your answer!')}
          secondaryHeaderText={[
            `${t(
              'Students, their parents, teachers, coaches, and counselors are the first to try AYO',
            )}. ${t(
              "If you don't fall into any of these groups, don't worry, you still can contribute",
            )}. ${t('Please download the Inspiration Book, to see how you can help')}.`,
          ]}
        />
        <Grid container justifyContent="center">
          <Grid container item md={6} spacing={2}>
            <Grid item sm={6} xs={12}>
              <Button
                component="a"
                download
                endIcon={<DownloadIcon />}
                fullWidth
                gaLabel="Download Inspiration Book in English"
                href={downloadInspirationBookEnglishRoute}
              >
                Download in English
              </Button>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Button
                component="a"
                download
                endIcon={<DownloadIcon />}
                fullWidth
                gaLabel="Download Inspiration Book in Spanish"
                href={downloadInspirationBookSpanishRoute}
              >
                Descargarlo en Español
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </ScreenCenteredContainer>
    </PageWrapper>
  );
};

export default OtherRolePage;
