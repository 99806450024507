import React from 'react';

import { AssignmentTypes } from '../../../../../constants/enums';
import { editAssignmentType } from '../../../../../constants/propTypes';
import BasicAssignment from '../edit-assignments/basic-assignment/BasicAssignment';
import ShortAnswerAssignment from '../edit-assignments/short-answer-assignment/ShortAnswerAssignment';
import MultipleChoiceAssignment from '../edit-assignments/multiple-choice-assignment/MultipleChoiceAssignment';
import AnnouncementAssignment from '../edit-assignments/announcement-assignment/AnnouncementAssignment';
import AnnouncementStaticAssignment from '../display-assignments/announcement-static-assignment/AnnouncementStaticAssignment';
import MaterialAssignment from '../edit-assignments/material-assignment/MaterialAssignment';
import StaticAssignmentWithDeadline from '../display-assignments/static-assignment-with-deadline/StaticAssignmentWithDeadline';
import StaticAssignmentNoDeadline from '../display-assignments/static-assignment-no-deadline/StaticAssignmentNoDeadline';

const AssignmentViews = {
  [AssignmentTypes.ASSIGNMENT]: BasicAssignment,
  [AssignmentTypes.SHORT_ANSWER]: ShortAnswerAssignment,
  [AssignmentTypes.MULTIPLE_CHOICE]: MultipleChoiceAssignment,
  [AssignmentTypes.ANNOUNCEMENT]: AnnouncementAssignment,
  [AssignmentTypes.MATERIAL]: MaterialAssignment,
};

const AssignmentStaticViews = {
  [AssignmentTypes.ASSIGNMENT]: StaticAssignmentWithDeadline,
  [AssignmentTypes.SHORT_ANSWER]: StaticAssignmentWithDeadline,
  [AssignmentTypes.MULTIPLE_CHOICE]: StaticAssignmentWithDeadline,
  [AssignmentTypes.ANNOUNCEMENT]: AnnouncementStaticAssignment,
  [AssignmentTypes.MATERIAL]: StaticAssignmentNoDeadline,
};

const AssignmentRenderer = ({
  assignment,
  onChange,
  validatorRef,
  isStatic,
  courseStudents,
  onDelete,
  onReorder,
  isFirst,
  isLast,
  isOwner,
  choices,
  onChoicesChange,
  onChoicesDelete,
  onChoicesLayerDelete,
  onChoicesSelectedChange,
  canSelectChoices,
  hideAssignedStudents,
}) => {
  const AssignmentTypeView = isStatic
    ? AssignmentStaticViews[assignment.workType]
    : AssignmentViews[assignment.workType];
  return (
    <AssignmentTypeView
      assignment={assignment}
      canSelectChoices={canSelectChoices}
      choices={choices}
      courseStudents={courseStudents}
      hideAssignedStudents={hideAssignedStudents}
      isFirst={isFirst}
      isLast={isLast}
      isOwner={isOwner}
      onChange={onChange}
      onChoicesChange={onChoicesChange}
      onChoicesDelete={onChoicesDelete}
      onChoicesLayerDelete={onChoicesLayerDelete}
      onChoicesSelectedChange={onChoicesSelectedChange}
      onDelete={onDelete}
      onReorder={onReorder}
      validatorRef={validatorRef}
    />
  );
};

AssignmentRenderer.propTypes = editAssignmentType;

export default AssignmentRenderer;
