import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, DialogContent, useMediaQuery, useTheme } from '@mui/material';
import PropTypes from 'prop-types';

import { Typography, Dialog, DialogTitle, Button } from '../../../atoms';
import { Chip } from '../../../moleculas';
import EvidenceOfGrowthDetails from '../evidence-of-growth-details/EvidenceOfGrowthDetails';
import {
  evidenceOfGrowthItem,
  goalActionStepItem,
  goalDetailsItem,
} from '../../../../constants/propTypes';

const DIALOG_MAX_WIDTH = 700;

const EvidenceOfGrowthViewDialog = ({
  actionStep,
  chipLabel,
  className,
  evidenceOfGrowth,
  evidenceType,
  goalDetails,
  onClose,
  open,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Dialog
      className={className}
      gaLabel="Evidence view dialog"
      maxWidth={DIALOG_MAX_WIDTH}
      onClose={onClose}
      open={open}
    >
      <DialogTitle disableTypography>
        <Box mb={3}>
          <Typography variant="h2">{t('View evidence details')}</Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        {!goalDetails && (
          <Box display="flex" flexDirection="column-reverse">
            <Box my={3}>
              <Typography variant="subtitle1">{evidenceOfGrowth.title}</Typography>
            </Box>
            <Box>
              <Box mb={1}>
                <Typography component="h4" variant="subtitle2">
                  {t('Related Attribute')}
                </Typography>
              </Box>
              <Chip isCaption label={chipLabel} />
            </Box>
          </Box>
        )}
        <EvidenceOfGrowthDetails
          actionStep={actionStep}
          evidenceOfGrowth={evidenceOfGrowth}
          evidenceType={evidenceType}
          goalDetails={goalDetails}
        />
        <Box maxWidth={isWidthUpSm ? '60%' : '80%'} mt={5}>
          <Button fullWidth gaLabel="Close" onClick={onClose} variant="primary">
            {t('Close')}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

EvidenceOfGrowthViewDialog.propTypes = {
  actionStep: goalActionStepItem,
  chipLabel: PropTypes.string,
  className: PropTypes.string,
  evidenceOfGrowth: PropTypes.shape(evidenceOfGrowthItem).isRequired,
  evidenceType: PropTypes.string,
  goalDetails: goalDetailsItem,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

EvidenceOfGrowthViewDialog.defaultProps = {
  actionStep: null,
  chipLabel: '',
  className: '',
  evidenceType: '',
  goalDetails: null,
};

export default EvidenceOfGrowthViewDialog;
