import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, useState } from 'react';
import dayjs from 'dayjs';

import {
  InputsValidationErrors,
  InputsValidationRules,
  ValidationRules,
} from '../../constants/enums';
import { EventKeys } from '../../constants/events';
import { validateProperty } from '../../utils';
import { useEventsService } from '../../services';
import useSnackbar from '../use-snackbar/useSnackbar';

const EventSnackBars = {
  POST: {
    success: 'The event has been successfully created.',
    error: 'AYO couldn’t create the event. Please try once more.',
  },
  UPDATE: {
    success: 'Thanks for your changes! Your input has been saved',
    error: 'AYO couldn’t save your edits Please try once more',
  },
  DELETE: {
    success: 'The event has been deleted.',
    error: 'AYO couldn’t delete the event. Please try once more.',
  },
};

const EVENTS_DEFAULT_PAGE = 1;

const getIsEventBeforeMaxDate = (events, eventStartDate) => {
  const maxStartDate = events.reduce(
    (maxDate, { startDate }) => (startDate > maxDate ? startDate : maxDate),
    events.length > 0 ? events[0].startDate : null,
  );
  return dayjs(eventStartDate).isBefore(dayjs(maxStartDate));
};
const useEvents = (resourceType, resourceId, pageSize, studentId) => {
  const { t } = useTranslation();

  const [events, setEvents] = useState([]);
  const [totalElements, setTotalElement] = useState(null);
  const [currentPage, setCurrentPage] = useState(EVENTS_DEFAULT_PAGE);

  const { setSnackBarStatus } = useSnackbar();

  const eventErrors = useMemo(
    () => ({
      [EventKeys.NAME]: {
        [ValidationRules.EMPTY_STRING]: 'Please add an event name.',
        [ValidationRules.SHORT_STRING]: InputsValidationErrors(
          t,
          InputsValidationRules.MIN_INPUT_LENGTH,
        ).MIN_ERROR_TEXT,
        [ValidationRules.LONG_STRING]: InputsValidationErrors(
          t,
          InputsValidationRules.MAX_INPUT_LENGTH,
        ).MAX_ERROR_TEXT,
      },
      [EventKeys.LOCATION_PLACEMENT]: {
        STRING: {
          [ValidationRules.EMPTY_STRING]: 'Please add a place.',
          [ValidationRules.SHORT_STRING]: InputsValidationErrors(
            t,
            InputsValidationRules.MIN_INPUT_LENGTH,
          ).MIN_ERROR_TEXT,
          [ValidationRules.LONG_STRING]: InputsValidationErrors(
            t,
            InputsValidationRules.MAX_TITLE_LENGTH,
          ).MAX_ERROR_TEXT,
        },
        LINK: {
          [ValidationRules.EMPTY_STRING]: 'Please add a place.',
          [ValidationRules.INVALID_LINK]: InputsValidationErrors(t).LINK_ERROR_TEXT,
          [ValidationRules.LONG_STRING]: InputsValidationErrors(
            t,
            InputsValidationRules.MAX_LINK_INPUT_LENGTH,
          ).MAX_ERROR_TEXT,
        },
      },
      [EventKeys.START_DATE]: {
        [ValidationRules.EMPTY_DATE]: 'Please add start date and time.',
        [ValidationRules.INVALID_DATE]:
          'The date is invalid, please use mm/dd/yyyy, hh:mm AM/PM format.',
        [ValidationRules.DATE_BEFORE_CURRENT]: "The date can't be sooner than the current date.",
        [ValidationRules.DATE_AFTER_TARGET]: "The start date can't be later than the end date.",
      },
      [EventKeys.END_DATE]: {
        [ValidationRules.EMPTY_DATE]: 'Please add end date and time.',
        [ValidationRules.INVALID_DATE]:
          'The date is invalid, please use mm/dd/yyyy, hh:mm AM/PM format.',
        [ValidationRules.DATE_BEFORE_CURRENT]: "The date can't be sooner than the current date.",
      },
      [EventKeys.DESCRIPTION]: {
        [ValidationRules.LONG_STRING]: InputsValidationErrors(
          t,
          InputsValidationRules.MAX_INPUT_LENGTH,
        ).MAX_ERROR_TEXT,
      },
      [EventKeys.SHARING_GROUPS]: {
        [ValidationRules.EMPTY_DATE]:
          'Select at least one checkbox to specify who can see the event.',
      },
    }),
    [t],
  );

  const {
    getEvents,
    getEventsByResourceId,
    getStudentEvents,
    postEvent,
    updateEvent,
    deleteEvent,
  } = useEventsService();

  const getEventsData = useMemo(
    () => (studentId ? getStudentEvents : resourceId ? getEventsByResourceId : getEvents),
    [getEvents, getEventsByResourceId, getStudentEvents, resourceId, studentId],
  );

  const validateEvent = useCallback(
    (event, isPlacementLink, skippedKeys = null) => {
      const errors = Object.fromEntries(
        Object.entries(eventErrors).map(([key, value]) => {
          if (skippedKeys && skippedKeys.includes(key)) {
            return [key, null];
          }

          const rules =
            key === EventKeys.LOCATION_PLACEMENT
              ? isPlacementLink
                ? value.LINK
                : value.STRING
              : value;

          return [
            key,
            validateProperty(event[key], rules, {
              minStringLength: InputsValidationRules.MIN_INPUT_LENGTH,
              maxStringLength: isPlacementLink
                ? InputsValidationRules.MAX_LINK_INPUT_LENGTH
                : InputsValidationRules.MAX_INPUT_LENGTH,
              targetDate: event.endDate,
            }),
          ];
        }),
      );

      return {
        isFormValid: !Object.values(errors).some((value) => value),
        errors,
      };
    },
    [eventErrors],
  );

  const hasItemToLoad = useMemo(
    () => totalElements > events.length,
    [events.length, totalElements],
  );

  const loadEventsData = useCallback(
    (page, shouldUpdateLastPage = false, eventId = null) => {
      getEventsData(resourceType, resourceId, page, pageSize, studentId).then(
        ({ events: dataEvents, totalElements: elementsCount }) => {
          setEvents((state) => {
            let newEvents = [];
            if (shouldUpdateLastPage) {
              newEvents = state
                .splice(-dataEvents.length, dataEvents.length, ...dataEvents)
                .filter(({ id }) => id !== eventId);
            } else {
              newEvents = [...state, ...dataEvents];
            }
            return newEvents;
          });

          setTotalElement(elementsCount);
        },
      );
    },
    [getEventsData, pageSize, resourceId, resourceType, studentId],
  );

  const loadMoreHandler = useCallback(() => {
    setCurrentPage((prevPage) => {
      const newPage = prevPage + 1;
      loadEventsData(newPage);
      return newPage;
    });
  }, [loadEventsData]);

  const eventPostHandler = useCallback(
    (eventBody) => {
      postEvent(eventBody)
        .then((data) => {
          setSnackBarStatus({
            text: t(EventSnackBars.POST.success),
            type: 'success',
          });
          if (
            getIsEventBeforeMaxDate(events, data.startDate) &&
            (hasItemToLoad || totalElements === pageSize)
          ) {
            setEvents((state) => [...state.slice(0, -1), data]);
          }
          if (!hasItemToLoad && totalElements < pageSize) {
            setEvents((state) => [...state, data]);
          }
          setTotalElement((state) => state + 1);
        })
        .catch(() => {
          setSnackBarStatus({
            text: t(EventSnackBars.POST.error),
            type: 'error',
          });
        });
    },
    [events, hasItemToLoad, pageSize, postEvent, setSnackBarStatus, t, totalElements],
  );

  const eventUpdateHandler = useCallback(
    (eventBody) => {
      updateEvent(eventBody.id, eventBody)
        .then((data) => {
          setSnackBarStatus({
            text: t(EventSnackBars.UPDATE.success),
            type: 'success',
          });
          if (!getIsEventBeforeMaxDate(events, data.startDate) && hasItemToLoad) {
            loadEventsData(currentPage, true, data.id);
          } else {
            setEvents((state) =>
              state.map((item, i) =>
                i === state.findIndex(({ id }) => id === data.id) ? data : item,
              ),
            );
          }
        })
        .catch(() => {
          setSnackBarStatus({
            text: t(EventSnackBars.UPDATE.error),
            type: 'error',
          });
        });
    },
    [currentPage, events, hasItemToLoad, loadEventsData, setSnackBarStatus, t, updateEvent],
  );

  const eventDeleteHandler = useCallback(
    (event) => {
      deleteEvent(event.id)
        .then(() => {
          setSnackBarStatus({
            text: t(EventSnackBars.DELETE.success),
            type: 'delete',
          });
          if (hasItemToLoad) {
            loadEventsData(currentPage, true, event.id);
          } else {
            setEvents((state) => state.filter(({ id }) => id !== event.id));
            setTotalElement((state) => state - 1);
          }
        })
        .catch(() => {
          setSnackBarStatus({
            text: t(EventSnackBars.DELETE.error),
            type: 'error',
          });
        });
    },
    [currentPage, deleteEvent, hasItemToLoad, loadEventsData, setSnackBarStatus, t],
  );

  return {
    eventDeleteHandler,
    eventPostHandler,
    eventUpdateHandler,
    events,
    hasItemToLoad,
    loadEventsData,
    loadMoreHandler,
    totalElements,
    validateEvent,
  };
};

export default useEvents;
