import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useProfileService } from '../../services';
import { SharedDataActions, SharedDataContext } from '../../context';

let isLoading = false;

const useEvidencesData = (studentId, evidenceType, skipLoadData) => {
  const { t } = useTranslation();
  const { state: sharedDataState, dispatch: dispatchSharedData } = useContext(SharedDataContext);
  const { evidencesOfGrowth } = sharedDataState;
  const { getEvidenceOfGrowth } = useProfileService();

  const loadEvidences = useCallback(() => {
    getEvidenceOfGrowth(studentId).then((evidences) => {
      dispatchSharedData({
        type: SharedDataActions.SET_EVIDENCES_OF_GROWTH,
        data: evidences,
      });
      isLoading = false;
    });
  }, [dispatchSharedData, getEvidenceOfGrowth, studentId]);

  useEffect(() => {
    if (isLoading || skipLoadData) return;
    isLoading = true;
    loadEvidences();
  }, [studentId, dispatchSharedData, getEvidenceOfGrowth, loadEvidences, skipLoadData]);

  const relatedEvidences = useMemo(
    () =>
      evidencesOfGrowth.filter((evidence) =>
        Object.keys(evidence.evidenceResourcesByType).includes(evidenceType),
      ),
    [evidencesOfGrowth, evidenceType],
  );

  const getLAChipLabel = useCallback(
    (evidence) => t(evidence.evidenceResourcesByType.LEADERSHIP_ATTRIBUTE.parentResourceId),
    [t],
  );

  return {
    getLAChipLabel,
    loadEvidences,
    relatedEvidences,
  };
};

export default useEvidencesData;
