import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Box } from '@mui/material';

import { Card, Typography } from '../../../atoms';
import { TextWithTooltip } from '../../../moleculas';
import { getImageSrc } from '../../../../utils';
import ClubStatus from '../club-status/ClubStatus';

const ClubHubCard = ({
  actions,
  clubName,
  imageInfo,
  joined,
  membersCount,
  redirectUrl,
  showMembershipLabel,
  status,
}) => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Card
      ariaLabel={clubName}
      cardHeader={status ? <ClubStatus status={status} /> : null}
      className={classnames('ayo-club-hub__card', 'ayo-clickable-card', {
        'ayo-club-hub__card--joined': joined,
      })}
      gaLabel={clubName}
      imageSrc={imageInfo ? getImageSrc(imageInfo.id, imageInfo.ownerId) : ''}
      mainContent={
        <Box>
          <TextWithTooltip
            className="ayo-clickable-card__title"
            rowsCount={2}
            title={clubName}
            titleVariant="subtitle2"
            tooltipClassName="ayo-clickable-card__wrapped-text-tooltip"
            typographyProps={{
              align: 'left',
              paragraph: true,
            }}
          />
          <Box
            alignItems="center"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Typography isLabel isLightText={joined} variant="body2">{`${membersCount || 0} ${t(
              'joined',
            )}`}</Typography>
            {showMembershipLabel && (
              <Typography isLightText my={1.5} variant="body2">
                {t('Joined')}
              </Typography>
            )}
            {actions}
          </Box>
        </Box>
      }
      mediaContent={imageInfo ? 'img' : 'div'}
      onClick={(e) => {
        e.preventDefault();
        history.push(redirectUrl);
      }}
      tabIndex="0"
    />
  );
};

ClubHubCard.propTypes = {
  actions: PropTypes.node,
  clubName: PropTypes.string.isRequired,
  imageInfo: PropTypes.shape({
    id: PropTypes.number,
    ownerId: PropTypes.number,
    fileName: PropTypes.string,
    updatedDate: PropTypes.string,
  }),
  joined: PropTypes.bool,
  membersCount: PropTypes.number.isRequired,
  redirectUrl: PropTypes.string.isRequired,
  showMembershipLabel: PropTypes.bool,
  status: PropTypes.string,
};

ClubHubCard.defaultProps = {
  actions: null,
  imageInfo: null,
  joined: false,
  showMembershipLabel: false,
  status: null,
};
export default ClubHubCard;
