import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const useAssignmentStudentsState = (studentsList, checkedEmails, studentsText) => {
  const [students, setStudents] = useState(
    studentsList
      ?.map((student) => ({
        ...student,
        value: checkedEmails?.includes(student.email) ?? false,
      }))
      .sort((a, b) => (a.value === b.value ? a.name.localeCompare(b.name) : a.value ? -1 : 1)),
  );
  const [error, setError] = useState('');

  const { t } = useTranslation();
  const validate = () => {
    if (checkedEmails.length < 1) {
      const errorText = t('Please select at least 1 student to push the assignment to GC.');
      setError(errorText);
      return [{ errorText, lvl3Label: studentsText || t('Assigned students') }];
    }
    return [];
  };

  const onChange = (emails, value) => {
    setStudents((prevStudents) => {
      const studentsCopy = [...prevStudents];
      emails.forEach((email) => {
        const editedUserIndex = studentsCopy.findIndex((student) => student.email === email);
        studentsCopy[editedUserIndex].value = value;
      });
      return studentsCopy;
    });
    setError('');
  };

  return { state: students, onChange, error, validate };
};

export default useAssignmentStudentsState;
