import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { Button } from '../../atoms';
import { PageIllustrationBlock, ScreenCenteredContainer } from '../../moleculas';
import { PageWrapper } from '../../organisms';
import { useProfileService } from '../../../services';
import { useSearchParams } from '../../../hooks';
import { rootRoute } from '../../../constants/routes';
import { ReactComponent as UnsubscribeImage } from '../../../resources/images/unsubscribe_page.svg';

const UnsubscribePage = () => {
  const { t } = useTranslation();

  const { updateEmailNotification } = useProfileService();

  const urlParams = useSearchParams();
  const subscriptionToken = urlParams.get('subscriptionToken');

  useEffect(() => {
    updateEmailNotification(subscriptionToken);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper
      customFooter={<Box mt={9} />}
      mainElementClassName="ayo-unsubscribe-page"
      noi18n
      noNav
    >
      <ScreenCenteredContainer>
        <PageIllustrationBlock
          Illustration={UnsubscribeImage}
          illustrationAriaLabel={t('A crying letter')}
          mainHeaderText={t('You have successfully unsubscribed from AYO’s emails')}
          secondaryHeaderText={[
            t(
              'However, you can always subscribe back in your settings for receiving the most valuable updates about AYO',
            ),
          ]}
        />
        <Button
          component={RouterLink}
          gaLabel="AYO Main page"
          isAdaptive
          to={rootRoute}
          variant="primary"
        >
          {t('AYO Main page')}
        </Button>
      </ScreenCenteredContainer>
    </PageWrapper>
  );
};

export default UnsubscribePage;
