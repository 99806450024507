import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Container, Grid, useMediaQuery, useTheme } from '@mui/material';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';

import { Card, RadioGroup, Typography } from '../../atoms';
import { InformationalMessage, NewFeatureHotspot } from '../../moleculas';
import { BottomBar, PageWrapper } from '../../organisms';
import {
  LessonPlannerActions,
  LessonPlannerContext,
  UserActions,
  UserContext,
} from '../../../context';
import { useOneTimeActionService } from '../../../services';
import { getFullName, getInnerAppRedirectUrl, useProfile } from '../../../utils';
import {
  LocalStorageKeysMap,
  OneTimeActionsMap,
  SchoolLevels,
  KeyboardMap,
} from '../../../constants/enums';
import { ReactComponent as CampusSelectionImage } from '../../../resources/images/campus-selection.svg';
import { ReactComponent as ElementarySchoolIcon } from '../../../resources/icons/elementary_school.svg';
import { ReactComponent as HighSchoolIcon } from '../../../resources/icons/high_school.svg';
import { ReactComponent as MiddleSchoolIcon } from '../../../resources/icons/middle_school.svg';
import { NewFeaturesIdsMap } from '../../../tours/common/NewFeaturesItemsProvider';
import NewFeaturesTour from '../../../tours/NewFeaturesTour';

const sortSchoolsByStatusAndByName = (schools) =>
  schools?.sort((a, b) => b.active - a.active || a.schoolName.localeCompare(b.schoolName));

const RolesLabels = {
  ADMINISTRATOR: 'Administrator',
  TEACHER: 'Teacher',
};

const CampusSelectionPage = () => {
  const { t } = useTranslation();

  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  const history = useHistory();

  const { state: userState, dispatch: dispatchUserState } = useContext(UserContext);
  const { dispatch: dispatchLessonPlannerState } = useContext(LessonPlannerContext);
  const profile = userState?.profile;
  const schools = profile?.schools;

  const { getOneTimeActionStatus, getIsCampusSelectionAvailable } = useProfile();
  const { postOneTimeAction } = useOneTimeActionService();

  const CampusSelectionPageConfig = useMemo(
    () => [
      {
        title: 'Elementary schools',
        icon: ElementarySchoolIcon,
        schoolLevel: SchoolLevels.ELEMENTARY,
        schools: sortSchoolsByStatusAndByName(schools[SchoolLevels.ELEMENTARY]),
      },
      {
        title: 'Middle schools',
        icon: MiddleSchoolIcon,
        schoolLevel: SchoolLevels.MIDDLE,
        schools: sortSchoolsByStatusAndByName(schools[SchoolLevels.MIDDLE]),
      },
      {
        title: 'High schools',
        icon: HighSchoolIcon,
        schoolLevel: SchoolLevels.HIGH,
        schools: sortSchoolsByStatusAndByName(schools[SchoolLevels.HIGH]),
      },
    ],
    [schools],
  );

  const [isInfoMessageVisible, setIsInfoMessageVisible] = useState(false);

  useEffect(() => {
    localStorage.removeItem(LocalStorageKeysMap.AYO_CURRENT_CAMPUS);
    if (sessionStorage.getItem(LocalStorageKeysMap.AYO_EMAIL_REDIRECT)) {
      sessionStorage.removeItem(LocalStorageKeysMap.CLASSLINK_LOGIN_REDIRECT_URL);
      sessionStorage.removeItem(LocalStorageKeysMap.AYO_EMAIL_REDIRECT);
    }
    setIsInfoMessageVisible(
      !getOneTimeActionStatus(OneTimeActionsMap.ACTIVITY_SCHOOLS_NOTIFICATION),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onInfoMessageHide = useCallback(() => {
    setIsInfoMessageVisible(false);
    postOneTimeAction(OneTimeActionsMap.ACTIVITY_SCHOOLS_NOTIFICATION);
    dispatchUserState({
      type: UserActions.SET_ONE_TIME_ACTION,
      data: OneTimeActionsMap.ACTIVITY_SCHOOLS_NOTIFICATION,
    });
  }, [dispatchUserState, postOneTimeAction]);

  const handleCardClick = useCallback(
    (schoolLevel, schoolName, roleInSchool) => {
      dispatchUserState({
        type: UserActions.SET_USER_PROFILE_CAMPUS,
        data: { schoolLevel, schoolName },
      });
      // clear lessons metadata as it's campus sensitive
      dispatchLessonPlannerState({
        type: LessonPlannerActions.SET_LESSONS_METADATA,
        data: [],
      });

      // clear teachers list as it's campus sensitive
      dispatchLessonPlannerState({
        type: LessonPlannerActions.SET_TEACHERS_LIST,
        data: null,
      });

      const hasDifferentSchoolRole = roleInSchool && roleInSchool !== profile.role;
      const redirectUrl = getInnerAppRedirectUrl(
        hasDifferentSchoolRole ? { id: profile.id, role: roleInSchool } : profile,
      );
      sessionStorage.removeItem(LocalStorageKeysMap.CLASSLINK_LOGIN_REDIRECT_URL);

      setTimeout(() => {
        sessionStorage.setItem(LocalStorageKeysMap.AYO_ROLE_IN_SCHOOL, roleInSchool);
        window.location.assign(redirectUrl);
      }, 0);
    },
    [dispatchLessonPlannerState, dispatchUserState, profile],
  );

  useEffect(() => {
    if (!getIsCampusSelectionAvailable()) {
      history.replace(getInnerAppRedirectUrl(profile));
    }
  }, [getIsCampusSelectionAvailable, history, profile]);

  const handleKeyDown = (e, schoolLevel, schoolName) => {
    if (
      [
        KeyboardMap.ARROW_DOWN,
        KeyboardMap.ARROW_UP,
        KeyboardMap.ARROW_RIGHT,
        KeyboardMap.ARROW_LEFT,
      ].includes(e.key)
    ) {
      e.preventDefault();
    }
    if (e.key === KeyboardMap.ENTER) {
      handleCardClick(schoolLevel, schoolName, e.target.value);
    }
  };

  return (
    <PageWrapper
      key={userState.profile?.currentCampus}
      customFooter={<BottomBar noFAQ noNav simple />}
      mainElementClassName="ayo-campus-selection"
      noNav
      noNotifications
    >
      <Container>
        <Box mb={isWidthUpSm ? 10 : 5}>
          <Box alignItems="center" display="flex" flexDirection="column" minWidth="100%">
            <Typography align="center" component="h1" variant="h1">
              {t('Welcome to AYO, userName!', {
                userName: getFullName(profile),
              })}
            </Typography>
            <Box my={5}>
              <CampusSelectionImage
                aria-label={t('A landscape with a school building and trees')}
                className="ayo-svg-illustration"
              />
            </Box>
          </Box>
          <Box>
            <Box alignItems="center" display="flex" mb={1}>
              <Typography component="h2" variant="h2">
                {t('My campuses')}
              </Typography>
              <NewFeatureHotspot
                id={NewFeaturesIdsMap.MULTI_CAMPUS_SUPPORT}
                isClickable
                label={t('My campuses')}
              />
            </Box>
            <Typography display={isWidthUpSm ? 'block' : 'inline'} variant="body2">
              {t('Here you can see the list of all the campuses that you teach in')}{' '}
            </Typography>
            <Typography display={isWidthUpSm ? 'block' : 'inline'} variant="body2">
              {t('Select a campus to proceed')}
            </Typography>
            {isInfoMessageVisible && (
              <Box mt={2}>
                <InformationalMessage
                  onClick={onInfoMessageHide}
                  text={
                    <Trans
                      components={{
                        green: (
                          <span className="ayo-informational-message__description__indicator" />
                        ),
                      }}
                      i18nKey="A green ribbon means that there are students in the campus"
                    />
                  }
                />
              </Box>
            )}
            <Box mt={5} width="100%">
              <Grid container spacing={10}>
                {CampusSelectionPageConfig.map(
                  (item) =>
                    !!item.schools?.length && (
                      <Grid key={item.id} item xs={12}>
                        <Box display="flex" flexDirection="row" mb={3}>
                          <Box mr={3}>
                            <item.icon className="ayo-campus-selection__icon" />
                          </Box>
                          <Typography component="h3" variant="subtitle1">
                            {t(item.title)}
                          </Typography>
                        </Box>
                        <Grid container spacing={3}>
                          {item.schools.map((school) => (
                            <Grid key={school.schoolName} item lg={3} md={4} sm={6} xs={12}>
                              <Card
                                className="ayo-campus-selection__card ayo-clickable-card"
                                leftRibbon={
                                  school.active ? (
                                    <Box className="ayo-clickable-card__leftRibbon" />
                                  ) : null
                                }
                                mainContent={
                                  <Box>
                                    <Typography align="start" paragraph variant="subtitle2">
                                      {school.schoolName}
                                    </Typography>
                                    <RadioGroup
                                      ariaLabel={`${t('Choose the role')} ${school.schoolName} ${t(
                                        school.active ? 'Filled profile' : 'Empty profile',
                                      )}`}
                                      className={classnames({
                                        'ayo-radiogroup--multiple': school.rolesInSchool.length > 1,
                                      })}
                                      onChange={(e) =>
                                        handleCardClick(
                                          item.schoolLevel,
                                          school.schoolName,
                                          e.target.value,
                                        )
                                      }
                                      onKeyDown={(e) =>
                                        handleKeyDown(e, item.schoolLevel, school.schoolName)
                                      }
                                      options={school.rolesInSchool.map((role) => ({
                                        text: t(RolesLabels[role]),
                                        value: role,
                                      }))}
                                      tabIndex="0"
                                    />
                                  </Box>
                                }
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                    ),
                )}
              </Grid>
            </Box>
          </Box>
        </Box>
        <NewFeaturesTour />
      </Container>
    </PageWrapper>
  );
};

export default CampusSelectionPage;
