import { Box } from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Button, Typography } from '../../atoms';
import { ReactComponent as DragDropIcon } from '../../../resources/icons/drag_and_drop.svg';
import { ReactComponent as AttachIcon } from '../../../resources/icons/attach.svg';

export const DragContent = ({
  avatarType,
  body,
  hideDragAndDrop,
  openFileBrowserHandler,
  titleHeaderLevel,
}) => {
  const { t } = useTranslation();

  return (
    <div className={classNames('drop-zone')}>
      <Box alignItems="center" display="flex" flexDirection="column" justifyContent="center">
        {!hideDragAndDrop && (
          <>
            <DragDropIcon />
            <Box mb={2}>
              <Typography component={`h${titleHeaderLevel}`} isLabel variant="subtitle2">
                {t('Drag and drop a photo here')}
              </Typography>
            </Box>
          </>
        )}
        {body}
        <Button
          className="drop-zone__open-button"
          endIcon={<AttachIcon />}
          gaLabel={`${avatarType} avatar - Add an attachment`}
          onClick={openFileBrowserHandler}
          variant="text"
        >
          {t('Add an attachment')}
        </Button>
      </Box>
    </div>
  );
};

DragContent.propTypes = {
  avatarType: PropTypes.isRequired,
  body: PropTypes.node,
  hideDragAndDrop: PropTypes.bool,
  openFileBrowserHandler: PropTypes.func.isRequired,
  titleHeaderLevel: PropTypes.number,
};

DragContent.defaultProps = {
  body: null,
  hideDragAndDrop: false,
  titleHeaderLevel: 4,
};

export const DropContent = () => {
  const { t } = useTranslation();
  return (
    <Box alignItems="center" className="drop-here" display="flex" justifyContent="center">
      <Box
        alignItems="center"
        className="drop-here__main"
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <DragDropIcon />
        <Typography variant="h2">{t('Drop file to upload')}</Typography>
      </Box>
      <Box className="drop-here__sup" gap={1}>
        <DragDropIcon />
        <Typography variant="subtitle2">{t('Drop file to upload')}</Typography>
      </Box>
    </Box>
  );
};

export default { DragContent, DropContent };
