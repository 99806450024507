import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Card, Typography } from '../../../atoms';

const AptitudeCardBase = ({
  IllustrationComponent,
  className,
  description,
  footer,
  name,
  titleComponent,
}) => (
  <Card
    className={classNames('ayo-aptitude-card', className)}
    mainContent={
      <div>
        <div className="ayo-aptitude-card__main-content">
          {IllustrationComponent && (
            <IllustrationComponent className="ayo-aptitude-card__illustration" />
          )}
          <div>
            <div>
              <Typography component={titleComponent} variant="subtitle2">
                {name}
              </Typography>
            </div>
            <div>
              <Typography variant="caption">{description}</Typography>
            </div>
          </div>
        </div>
        {footer}
      </div>
    }
  />
);

AptitudeCardBase.propTypes = {
  IllustrationComponent: PropTypes.elementType,
  className: PropTypes.string,
  description: PropTypes.string,
  footer: PropTypes.node,
  name: PropTypes.string,
  titleComponent: PropTypes.string,
};

AptitudeCardBase.defaultProps = {
  IllustrationComponent: null,
  className: '',
  description: '',
  footer: null,
  name: '',
  titleComponent: 'h3',
};

export default AptitudeCardBase;
