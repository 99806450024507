import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

import { EmailValidatorErrorsMap } from '../../test-data/constants';
import { emailRE, urlAllowedValidationRE } from '../../constants/regexps';
import { ValidationRules } from '../../constants/enums';

dayjs.extend(isSameOrAfter);

export const getEmailValidationError = (email) => {
  if (!email) {
    return EmailValidatorErrorsMap.EMPTY_EMAIL;
  }
  if (!emailRE.test(email)) {
    return EmailValidatorErrorsMap.INVALID_EMAIL;
  }
  return '';
};

export const validateProperty = (value, rules, config = {}) => {
  const { minStringLength, maxStringLength, targetDate } = config;

  switch (true) {
    case ValidationRules.EMPTY_STRING in rules && !value?.trim():
      return rules.EMPTY_STRING;

    case ValidationRules.EMPTY_ARRAY in rules && !value?.length:
      return rules.EMPTY_ARRAY;

    case ValidationRules.SHORT_STRING in rules && value?.trim()?.length < minStringLength:
      return rules.SHORT_STRING;

    case ValidationRules.LONG_STRING in rules && value?.length > maxStringLength:
      return rules.LONG_STRING;

    case ValidationRules.EMPTY_DATE in rules && !value:
      return rules.EMPTY_DATE;

    case ValidationRules.INVALID_DATE in rules && !dayjs(value).isValid():
      return rules.INVALID_DATE;

    case ValidationRules.INVALID_LINK in rules && !urlAllowedValidationRE.test(value):
      return rules.INVALID_LINK;

    case ValidationRules.DATE_BEFORE_CURRENT in rules && dayjs(value).isBefore(dayjs()):
      return rules.DATE_BEFORE_CURRENT;

    case ValidationRules.DATE_BEFORE_TARGET in rules &&
      targetDate &&
      dayjs(value).isBefore(dayjs(targetDate)):
      return rules.DATE_BEFORE_TARGET;

    case ValidationRules.DATE_AFTER_TARGET in rules &&
      targetDate &&
      dayjs(value).isSameOrAfter(dayjs(targetDate)):
      return rules.DATE_AFTER_TARGET;

    default:
      return null;
  }
};
