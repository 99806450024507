import { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCommentsService } from '../../services';
import { focusDialogCloseButton, mergeArrayOfObjectsHelper } from '../../utils';
import { StatusCodeMap } from '../../services/HttpClient';
import { AppActions, AppContext } from '../../context';

const useCommentsData = (
  { showStatusSnackBar, withErrorHandlers, isDialog } = {
    showStatusSnackBar: false,
    withErrorHandlers: false,
    isDialog: false,
  },
) => {
  const { t } = useTranslation();
  const [comments, setComments] = useState([]);
  const { dispatch: dispatchAppState } = useContext(AppContext);

  const { getComments, deleteComment, postComment, updateComment } = useCommentsService();

  const addNewCommentHandler = useCallback(
    (postBody, parentId, onAfterSubmit) => {
      postComment(postBody, withErrorHandlers)
        .then((response) => {
          if (parentId) {
            comments.find((comment) => comment.id === parentId).replies.push(response);
            setComments([...comments]);
          } else setComments([...comments, response]);
          if (onAfterSubmit) {
            onAfterSubmit(response.id);
          }
          if (showStatusSnackBar) {
            dispatchAppState({
              type: AppActions.SET_SNACKBAR_STATUS,
              data: {
                text: t(
                  parentId
                    ? 'Your reply has been successfully added!'
                    : 'Your comment has been successfully added!',
                ),
                type: 'success',
              },
            });
          }
        })
        .catch(() => {
          if (showStatusSnackBar) {
            dispatchAppState({
              type: AppActions.SET_SNACKBAR_STATUS,
              data: {
                text: t(
                  parentId
                    ? 'Failed to add your reply. Please try once more.'
                    : 'Failed to add your comment. Please try once more.',
                ),
                type: 'error',
              },
            });
          }
        })
        .finally(() => {
          if (isDialog) {
            focusDialogCloseButton();
          }
        });
    },
    [comments, dispatchAppState, isDialog, postComment, showStatusSnackBar, t, withErrorHandlers],
  );

  const editCommentHandler = useCallback(
    (commentId, putBody, parentId) => {
      updateComment(commentId, putBody)
        .then((response) => {
          if (parentId) {
            const commentRepliesArray = comments.find((comment) => comment.id === parentId).replies;
            const updatedParentArray = mergeArrayOfObjectsHelper(
              commentRepliesArray,
              [response],
              'id',
            );
            comments.find((comment) => comment.id === parentId).replies = updatedParentArray;
            setComments([...comments]);
          } else setComments(mergeArrayOfObjectsHelper(comments, [response], 'id'));
        })
        .finally(() => {
          if (isDialog) {
            focusDialogCloseButton();
          }
        });
    },
    [comments, isDialog, updateComment],
  );

  const deleteCommentHandler = useCallback(
    (id, parentId) => {
      deleteComment(id)
        .then((response) => {
          if (response.status === StatusCodeMap.NO_CONTENT) {
            if (parentId) {
              const newArray = comments
                .find((comment) => comment.id === parentId)
                .replies.filter((comment) => comment.id !== id);
              comments.find((comment) => comment.id === parentId).replies = newArray;
              setComments([...comments]);
            } else {
              setComments(comments.filter((comment) => comment.id !== id));
            }
          }
        })
        .finally(() => {
          if (isDialog) {
            focusDialogCloseButton();
          }
        });
    },
    [deleteComment, comments, isDialog],
  );

  return {
    addNewCommentHandler,
    comments,
    deleteCommentHandler,
    editCommentHandler,
    getComments,
    setComments,
  };
};

export default useCommentsData;
