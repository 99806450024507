import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Tooltip } from '../../../atoms';

const NegativeMoodTrendTooltip = ({ children, negativeMoodDaysCount }) => {
  const { t } = useTranslation();
  return (
    <Tooltip
      enterTouchDelay={0}
      leaveTouchDelay={5000}
      title={t('Negative mood trend for COUNT days', { daysCount: negativeMoodDaysCount })}
    >
      {children}
    </Tooltip>
  );
};

NegativeMoodTrendTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  negativeMoodDaysCount: PropTypes.number,
};

NegativeMoodTrendTooltip.defaultProps = {
  negativeMoodDaysCount: 0,
};

export default NegativeMoodTrendTooltip;
