import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { DialogActions, DialogContent } from '@mui/material';
import dayjs from 'dayjs';

import { PopupActionsButtons, SideDialog } from '../../../../moleculas';
import { eventType } from '../../../../../constants/propTypes';
import {
  DefaultSharingGroups,
  EventDialogModes,
  EventKeys,
  EventResourceTypes,
  EventSharingGroups,
  EventSharingLevel,
  EventsLocationTypes,
} from '../../../../../constants/events';
import { SharingLevels } from '../../../../../constants/family-feed';
import { shortLinkRegex } from '../../../../../constants/regexps';
import { useEvents } from '../../../../../hooks';
import { filterUniqueValuesByKey, focusDialogCloseButton, formatUrl } from '../../../../../utils';
import { UserContext } from '../../../../../context';

import EventVisibilityButton from './components/event-visibility-button/EventVisibilityButton';
import EventDialogTitle from './components/event-dialog-title/EventDialogTitle';
import EventDetails from './components/event-details/EventDetails';
import EventForm from './components/event-form/EventForm';
import EventVisibilitySettings from './components/event-visibility-settings/EventVisibilitySettings';

const EventDialog = ({
  event,
  isOpen,
  mode,
  onClose,
  onCreate,
  onDelete,
  onDialogModeChange,
  onUpdate,
  resourceId,
  resourceType,
  sharingLevelOptions,
  showSharingLabels,
}) => {
  const { t } = useTranslation();

  const { state: userState } = useContext(UserContext);

  const [newEvent, setNewEvent] = useState(
    event || {
      [EventKeys.NAME]: '',
      [EventKeys.DESCRIPTION]: '',
      [EventKeys.START_DATE]: '',
      [EventKeys.END_DATE]: '',
      [EventKeys.LOCATION_TYPE]: EventsLocationTypes.OFFLINE,
      [EventKeys.LOCATION_PLACEMENT]: '',
      [EventKeys.RESOURCE_TYPE]: resourceType,
      [EventKeys.RESOURCE_ID]: resourceId,
      [EventKeys.SHARING_LEVEL]:
        (sharingLevelOptions && Object.values(sharingLevelOptions)[0].sharingLevel.value) ||
        EventSharingLevel[resourceType],
      [EventKeys.SHARING_GROUPS]:
        resourceType === EventResourceTypes.CLUB_HUB ? [EventSharingGroups.STUDENTS] : null,
      [EventKeys.SELECTED_ENTITIES]: null,
    },
  );

  const getDefaultSharedData = useCallback(
    (eventData) => ({
      [EventKeys.SHARING_LEVEL]: eventData[EventKeys.SHARING_LEVEL],
      [EventKeys.SHARING_GROUPS]:
        eventData[EventKeys.SHARING_GROUPS] ||
        DefaultSharingGroups[eventData[EventKeys.SHARING_LEVEL]],
      [EventKeys.SELECTED_ENTITIES]:
        eventData[EventKeys.SELECTED_ENTITIES] ||
        (eventData[EventKeys.SHARING_LEVEL] === SharingLevels.DISTRICT.value
          ? sharingLevelOptions[eventData[EventKeys.SHARING_LEVEL]].selectedEntities
          : []),
    }),
    [sharingLevelOptions],
  );

  const [sharedData, setSharedData] = useState(getDefaultSharedData(newEvent));

  const [isVisibilitySettingsOpen, setIsVisibilitySettingsOpen] = useState(false);

  const [validationErrors, setValidationErrors] = useState({});

  const { validateEvent } = useEvents();

  const isEventInProgress = useMemo(
    () => mode === EventDialogModes.EDIT && dayjs(event[EventKeys.START_DATE]).isBefore(dayjs()),
    [event, mode],
  );

  const primaryButtonText = useMemo(
    () =>
      isVisibilitySettingsOpen
        ? 'Apply'
        : mode === EventDialogModes.CREATE
        ? 'Create event'
        : 'Save changes',
    [isVisibilitySettingsOpen, mode],
  );

  const visibilityButtonHandler = useCallback(() => {
    setIsVisibilitySettingsOpen(true);
    focusDialogCloseButton();
    if (validationErrors[EventKeys.SHARING_GROUPS]) {
      setValidationErrors((state) => ({ ...state, [EventKeys.SHARING_GROUPS]: null }));
    }
  }, [validationErrors]);

  const sharingLevelChangeHandler = useCallback((value) => {
    setSharedData({
      [EventKeys.SHARING_LEVEL]: value,
      [EventKeys.SHARING_GROUPS]: DefaultSharingGroups[value],
      [EventKeys.SELECTED_ENTITIES]: [],
    });
  }, []);

  const sharingGroupsChangeHandler = useCallback((value) => {
    setSharedData((state) => ({ ...state, [EventKeys.SHARING_GROUPS]: value }));
  }, []);

  const selectedEntitiesChangeHandler = useCallback((e, value, comparisonKey = 'id') => {
    setSharedData((state) => {
      let newState;

      const { selectedEntities } = state;

      if (Array.isArray(value)) {
        newState = {
          ...state,
          selectedEntities: e.target.checked
            ? filterUniqueValuesByKey([...selectedEntities, ...value], comparisonKey)
            : [
                ...selectedEntities.filter(
                  (oldItem) =>
                    !value.find((newItem) => oldItem[comparisonKey] === newItem[comparisonKey]),
                ),
              ],
        };
      } else {
        newState = {
          ...state,
          selectedEntities: e.target.checked
            ? [...selectedEntities, value]
            : selectedEntities.filter((x) => x[comparisonKey] !== value[comparisonKey]),
        };
      }

      return newState;
    });
  }, []);

  const selectedEntitiesDeleteHandler = useCallback(
    (entities) => {
      if (entities?.length) {
        setNewEvent((state) => ({ ...state, [EventKeys.SELECTED_ENTITIES]: entities }));
        setSharedData((state) => ({ ...state, [EventKeys.SELECTED_ENTITIES]: entities }));
      } else {
        const newSharedData = {
          [EventKeys.SHARING_LEVEL]: Object.values(sharingLevelOptions)[0].sharingLevel.value,
          [EventKeys.SHARING_GROUPS]: null,
          [EventKeys.SELECTED_ENTITIES]: null,
        };
        setNewEvent((state) => {
          const newState = { ...state, ...newSharedData };

          setSharedData(getDefaultSharedData(newState));

          return newState;
        });
      }
    },
    [getDefaultSharedData, sharingLevelOptions],
  );

  const applyVisibilitySettings = useCallback(() => {
    setNewEvent((state) => ({ ...state, ...sharedData }));
    setIsVisibilitySettingsOpen(false);
  }, [sharedData]);

  const eventChangeHandler = useCallback(
    (key, value) => {
      setNewEvent((state) => ({ ...state, [key]: value }));
      if (validationErrors[key]) {
        setValidationErrors((state) => ({ ...state, [key]: '' }));
      }
    },
    [validationErrors],
  );

  const dateValidationHandler = useCallback(
    (key, value) => {
      const eventBody = { ...newEvent, [key]: value };
      const { errors } = validateEvent(eventBody);
      setValidationErrors((state) => ({
        ...state,
        [EventKeys.START_DATE]: isEventInProgress
          ? null
          : eventBody[EventKeys.START_DATE] && errors[EventKeys.START_DATE],
        [EventKeys.END_DATE]: eventBody[EventKeys.END_DATE] && errors[EventKeys.END_DATE],
      }));
    },
    [isEventInProgress, newEvent, validateEvent],
  );

  const onSubmit = useCallback(() => {
    const placement = newEvent[EventKeys.LOCATION_PLACEMENT];
    const isPlacementLink = shortLinkRegex.test(placement.trim());

    const eventPostBody = {
      ...newEvent,
      [EventKeys.LOCATION_PLACEMENT]: isPlacementLink ? formatUrl(placement) : placement,
    };

    const skippedValidationKeys = [];

    if (resourceType === EventResourceTypes.CLUB_HUB) {
      skippedValidationKeys.push([EventKeys.SHARING_GROUPS]);
    }

    if (isEventInProgress) {
      skippedValidationKeys.push([EventKeys.START_DATE]);
    }

    const { errors, isFormValid } = validateEvent(
      eventPostBody,
      isPlacementLink,
      skippedValidationKeys,
    );

    if (isFormValid) {
      if (mode === EventDialogModes.CREATE) {
        onCreate(eventPostBody);
      } else {
        onUpdate(eventPostBody);
      }
      onClose();
    } else {
      setValidationErrors(errors);
    }
  }, [newEvent, resourceType, isEventInProgress, validateEvent, mode, onClose, onCreate, onUpdate]);

  if (!mode) {
    return null;
  }

  return (
    <SideDialog
      className="ayo-events-block__dialog"
      gaLabel="Event dialog"
      isOpen={isOpen}
      onClose={onClose}
      transitionDuration={{ exit: 0 }}
    >
      <EventDialogTitle
        isEditable={event?.ownerId === userState.profile.id}
        isVisibilitySettingOpen={isVisibilitySettingsOpen}
        mode={mode}
        name={event?.name}
        onBackButtonClick={() => {
          setIsVisibilitySettingsOpen(false);
          setSharedData(getDefaultSharedData(newEvent));
          focusDialogCloseButton();
        }}
        onDelete={() => onDelete(event)}
        onEdit={() => {
          onDialogModeChange(EventDialogModes.EDIT);
          focusDialogCloseButton();
        }}
      />
      <DialogContent>
        {mode === EventDialogModes.VIEW ? (
          <EventDetails event={event} showSharingLabels={showSharingLabels} />
        ) : isVisibilitySettingsOpen ? (
          <EventVisibilitySettings
            onSelectedEntitiesChange={selectedEntitiesChangeHandler}
            onSharingGroupsChange={sharingGroupsChangeHandler}
            onSharingLevelChange={sharingLevelChangeHandler}
            sharedData={sharedData}
            sharingLevelOptions={sharingLevelOptions}
          />
        ) : (
          <EventForm
            dateValidation={dateValidationHandler}
            errors={validationErrors}
            event={newEvent}
            isEventInProgress={isEventInProgress}
            onChange={eventChangeHandler}
            visibilityButton={
              sharingLevelOptions && (
                <EventVisibilityButton
                  error={validationErrors[EventKeys.SHARING_GROUPS]}
                  onDelete={selectedEntitiesDeleteHandler}
                  onVisibilityButtonClick={visibilityButtonHandler}
                  resourceType={resourceType}
                  sharedData={{
                    sharingGroups: newEvent.sharingGroups,
                    selectedEntities: newEvent.selectedEntities,
                  }}
                />
              )
            }
          />
        )}
      </DialogContent>
      {[EventDialogModes.EDIT, EventDialogModes.CREATE].includes(mode) && (
        <DialogActions>
          <PopupActionsButtons
            primaryButtonGaLabel={primaryButtonText}
            primaryButtonHandler={isVisibilitySettingsOpen ? applyVisibilitySettings : onSubmit}
            primaryButtonText={t(primaryButtonText)}
            secondaryButtonGaLabel="Cancel"
            secondaryButtonHandler={onClose}
            secondaryButtonText={t('Cancel')}
          />
        </DialogActions>
      )}
    </SideDialog>
  );
};

EventDialog.propTypes = {
  event: eventType.isRequired,
  isOpen: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onDialogModeChange: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  resourceId: PropTypes.number.isRequired,
  resourceType: PropTypes.string.isRequired,
  sharingLevelOptions: PropTypes.instanceOf(Object),
  showSharingLabels: PropTypes.bool,
};

EventDialog.defaultProps = {
  sharingLevelOptions: null,
  showSharingLabels: false,
};

export default EventDialog;
