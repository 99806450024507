import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Container, useMediaQuery, useTheme, ClickAwayListener } from '@mui/material';

import { Button, Typography } from '../../atoms';
import { InformationalCaption, InformationalMessage, MlChip, STTTextField } from '../../moleculas';
import {
  MobileNotSupported,
  PageWrapper,
  PortfolioSections,
  PublicationStatusBadge,
} from '../../organisms';
import { usePortfolioData, usePrint, useSharedStudentData } from '../../../hooks';
import { useProfile } from '../../../utils';
import { UserActions, UserContext } from '../../../context';
import { useOneTimeActionService, useStudentsService } from '../../../services';
import {
  InputsValidationErrors,
  InputsValidationRules,
  KeyboardMap,
  OneTimeActionsMap,
  PublicationStatuses,
  RolesMap,
} from '../../../constants/enums';
import { ReactComponent as SettingsIcon } from '../../../resources/icons/settings.svg';
import { ReactComponent as CreateIcon } from '../../../resources/icons/create.svg';
import { ReactComponent as NavigationBackChevron } from '../../../resources/icons/chevron_left.svg';
import {
  familyFeedRoute,
  myStudentsRoute,
  portfoliosRoute,
  studentsRoute,
} from '../../../constants/routes';

import PortfolioSettingsDialog from './components/portfolio-settings-dialog/PortfolioSettingsDialog';
import StudentPortfolioButtonsBlock from './components/student-portfolio-buttons-block/StudentPortfolioButtonsBlock';

const PortfolioDetailsPage = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
  const portfolioRef = useRef();
  const { printHandler } = usePrint(
    portfolioRef,
    'ayo-portfolio-details-page--publish-mode ayo-portfolio-print-view',
  );

  const {
    portfolioData,
    isEditMode,
    setIsEditMode,
    deletePortfolioHandler,
    sectionsToAnimate,
    studentId,
    studentPortfolio,
    settingsHandler,
    portfolioSettings,
    getSectionPortfolioData,
    changePortfolioVisibilityHandler,
    getPublishedPortfolioActions,
    unpublishPortfolio,
  } = usePortfolioData();
  const [titleEditMode, setTitleEditMode] = useState(false);
  const [portfolioTitle, setPortfolioTitle] = useState('');
  const [titleBackup, setTitleBackup] = useState(null);
  const [portfolioTitleErrorMessage, setPortfolioTitleErrorMessage] = useState('');
  const [isSettingsDialogOpen, setIsSettingsDialogOpen] = useState(false);
  const [publishModeView, setPublishModeView] = useState(false);
  const { patchStudentPortfolio } = useStudentsService();
  const { activeStudent } = useSharedStudentData(studentId);
  const { getOneTimeActionStatus } = useProfile();
  const { postOneTimeAction } = useOneTimeActionService();
  const { state: userState, dispatch: dispatchUserState } = useContext(UserContext);
  const [isNotificationVisible, setIsNotificationVisible] = useState(false);
  const isStudent = userState.profile.role === RolesMap.STUDENT;
  const textByRoleCofig = useMemo(
    () => ({
      [RolesMap.STUDENT]: {
        backToLink: portfoliosRoute,
        backToText: t('Back to My portfolio'),
        helperText: <Trans components={{ br: <br /> }} i18nKey="Create portfolio paragraph" />,
      },
      [RolesMap.TEACHER]: {
        backToLink: `${myStudentsRoute}/${activeStudent?.id}${portfoliosRoute}`,
        backToText: t('Back to Username´s portfolio', {
          username: activeStudent?.firstName,
        }),
        helperText: t('This is the place in AYO where the student creates their portfolio.'),
      },
      [RolesMap.ADMINISTRATOR]: {
        backToLink: `${studentsRoute}/${activeStudent?.id}${portfoliosRoute}`,
        backToText: t('Back to Username´s portfolio', {
          username: activeStudent?.firstName,
        }),
        helperText: t('This is the place in AYO where the student creates their portfolio.'),
      },
      [RolesMap.GUARDIAN]: {
        backToLink: `${familyFeedRoute}/${activeStudent?.id}${portfoliosRoute}`,
        backToText: t('Back to Username´s portfolio', {
          username: activeStudent?.firstName,
        }),
        helperText: t('This is the place in AYO where your kid creates their portfolio'),
      },
    }),
    [t, activeStudent],
  );

  useEffect(() => {
    setPortfolioTitle(studentPortfolio?.title);
    if (isStudent && studentPortfolio) {
      if (userState.isPortfolioEditMode || studentPortfolio?.status === PublicationStatuses.DRAFT) {
        setIsEditMode(true);
        setPublishModeView(false);
      } else {
        setPublishModeView(true);
      }
    } else {
      setPublishModeView(studentPortfolio?.status === PublicationStatuses.PUBLISHED);
    }
  }, [
    studentPortfolio,
    setIsEditMode,
    dispatchUserState,
    isStudent,
    userState.isPortfolioEditMode,
  ]);

  const titleRef = useRef();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [isEditMode, publishModeView]);

  useEffect(() => {
    setIsNotificationVisible(!getOneTimeActionStatus(OneTimeActionsMap.PORTFOLIO_NOTIFICATION));
  }, [getOneTimeActionStatus]);

  useEffect(() => {
    if (portfolioTitle?.trim().length < InputsValidationRules.MIN_INPUT_LENGTH) {
      setPortfolioTitleErrorMessage(
        InputsValidationErrors(t, InputsValidationRules.MIN_INPUT_LENGTH).MIN_ERROR_TEXT,
      );
    } else if (portfolioTitle?.trim().length > InputsValidationRules.MAX_TITLE_LENGTH) {
      setPortfolioTitleErrorMessage(
        InputsValidationErrors(t, InputsValidationRules.MAX_TITLE_LENGTH).MAX_ERROR_TEXT,
      );
    } else {
      setPortfolioTitleErrorMessage(null);
    }
  }, [portfolioTitle, t, portfolioTitleErrorMessage]);

  const ayoNotificationHandler = useCallback(() => {
    postOneTimeAction(OneTimeActionsMap.PORTFOLIO_NOTIFICATION);
    dispatchUserState({
      type: UserActions.SET_ONE_TIME_ACTION,
      data: OneTimeActionsMap.PORTFOLIO_NOTIFICATION,
    });
  }, [dispatchUserState, postOneTimeAction]);

  const previewModeHandler = useCallback(() => {
    setPublishModeView((prevState) => !prevState);
    setIsEditMode((prevState) => !prevState);
  }, [setPublishModeView, setIsEditMode]);

  const onHandleSaveTitle = useCallback(() => {
    if (!studentPortfolio) return;
    if (!portfolioTitleErrorMessage && portfolioTitle !== studentPortfolio.title) {
      const requestBody = {
        title: portfolioTitle,
      };
      patchStudentPortfolio(studentId, studentPortfolio.id, requestBody, false);
    } else {
      setPortfolioTitle(titleBackup);
    }
    setTitleEditMode(false);
  }, [
    studentPortfolio,
    studentId,
    portfolioTitle,
    patchStudentPortfolio,
    portfolioTitleErrorMessage,
    titleBackup,
  ]);

  const onTitleChange = useCallback((e) => {
    setPortfolioTitle(e.target.value);
  }, []);

  const WrapperContainer = useMemo(() => (publishModeView ? Box : Container), [publishModeView]);
  const isDraftPreviewMode = useMemo(
    () => studentPortfolio?.status === PublicationStatuses.DRAFT && publishModeView,
    [studentPortfolio, publishModeView],
  );

  return (
    <PageWrapper
      backToLink={!publishModeView && textByRoleCofig[userState.profile.role].backToLink}
      backToText={!publishModeView && textByRoleCofig[userState.profile.role].backToText}
      customFooter={publishModeView && <></>}
      customHeader={publishModeView && <></>}
      mainElementClassName={classNames('ayo-portfolio-details-page', {
        'ayo-portfolio-details-page--publish-mode': publishModeView,
      })}
      noNav={publishModeView}
    >
      {isWidthUpSm ? (
        <WrapperContainer
          ref={portfolioRef}
          onKeyDown={(e) => {
            setTimeout(() => {
              if (
                e.key === KeyboardMap.TAB &&
                titleEditMode &&
                !titleRef?.current?.contains(document.activeElement)
              ) {
                onHandleSaveTitle();
              }
            }, 0);
          }}
        >
          <Box ml={8} my={3}>
            {publishModeView && (
              <Button
                className="ayo-back-navigation-link"
                component={isDraftPreviewMode ? 'button' : RouterLink}
                gaLabel="Back to my draft"
                onClick={previewModeHandler}
                to={textByRoleCofig[userState.profile.role].backToLink}
              >
                <NavigationBackChevron />
                {t(
                  isDraftPreviewMode
                    ? 'Back to my draft'
                    : textByRoleCofig[userState.profile.role].backToText,
                )}
              </Button>
            )}
          </Box>
          <Box
            className="ayo-portfolio-details-page__title"
            display="flex"
            justifyContent="space-between"
            mb={publishModeView && 3}
            mx={publishModeView && 9}
          >
            <Box display="flex">
              <Box ref={titleRef} display="flex">
                {!titleEditMode ? (
                  <Typography component="h1" variant="h1">
                    {portfolioTitle}
                  </Typography>
                ) : (
                  <ClickAwayListener onClickAway={onHandleSaveTitle}>
                    <span>
                      <STTTextField
                        autoFocus
                        className="ayo-portfolio-details-page__title-textfield"
                        error={!!portfolioTitleErrorMessage}
                        gaLabel="Comment subject"
                        helperText={portfolioTitleErrorMessage}
                        inputProps={{
                          'aria-labelledby': 'portfolio-title',
                          autoComplete: 'off',
                        }}
                        multiline
                        name={t('title')}
                        onChange={onTitleChange}
                        outlined={false}
                        value={portfolioTitle}
                      />
                    </span>
                  </ClickAwayListener>
                )}
              </Box>
              {isStudent && !titleEditMode && isEditMode && (
                <Box ml={3}>
                  <Button
                    aria-label={t('Edit portfolio title')}
                    gaLabel="Edit Title"
                    isIconButton
                    onClick={() => {
                      setTitleEditMode(true);
                      setTitleBackup(portfolioTitle);
                    }}
                  >
                    <CreateIcon />
                  </Button>
                </Box>
              )}
              <Box pt={2}>
                <PublicationStatusBadge
                  className="ayo-portfolio-details-page__status-badge"
                  variant={studentPortfolio?.status}
                />
              </Box>
            </Box>
            <Box>
              {isStudent &&
                studentPortfolio?.status === PublicationStatuses.PUBLISHED &&
                !isEditMode && (
                  <StudentPortfolioButtonsBlock
                    deletePortfolioHandler={deletePortfolioHandler}
                    getPublishedPortfolioActions={getPublishedPortfolioActions}
                    headerActions
                    isEditMode={isEditMode}
                    portfolioData={portfolioData}
                    portfolioId={studentPortfolio?.id}
                    portfolioTitle={portfolioTitle}
                    previewModeHandler={previewModeHandler}
                    printHandler={printHandler}
                    publishModeView={publishModeView}
                    setIsEditMode={setIsEditMode}
                    setPublishModeView={setPublishModeView}
                    status={studentPortfolio?.status}
                    studentId={studentId}
                    unpublishPortfolioHandler={unpublishPortfolio}
                    userRole={userState.profile.role}
                  />
                )}
            </Box>
          </Box>
          {!publishModeView && (
            <>
              <Box maxWidth="50%" mb={3} mt={1}>
                <Typography component="p" variant="body2">
                  {textByRoleCofig[userState.profile.role].helperText}
                </Typography>
                {isStudent && (
                  <Typography variant="body2">
                    <Trans components={{ b: <b /> }} i18nKey="AYO auto-saves your progress" />
                  </Typography>
                )}
              </Box>
              {isNotificationVisible && (
                <Box className="ayo-maturity-levels-box__notification" mb={5}>
                  <InformationalMessage
                    onClick={ayoNotificationHandler}
                    text={
                      <Trans
                        components={{
                          ayoChip: <MlChip />,
                        }}
                        i18nKey="Ayo portfolio chip notification"
                      />
                    }
                  />
                </Box>
              )}
              {isStudent && (
                <Box my={2.5}>
                  <Button
                    endIcon={<SettingsIcon />}
                    gaLabel="Settings"
                    onClick={() => setIsSettingsDialogOpen(true)}
                  >
                    {t('Settings')}
                  </Button>
                </Box>
              )}
            </>
          )}
          {studentPortfolio && (
            <PortfolioSections
              loadSectionDataHandler={getSectionPortfolioData}
              portfolio={studentPortfolio}
              portfolioData={portfolioData}
              portfolioSettings={portfolioSettings}
              sectionsOrder={studentPortfolio.portfolioSectionsOrder}
              sectionsToAnimate={sectionsToAnimate}
            />
          )}
          {isStudent ? (
            <Box mx={publishModeView && 10} my={5}>
              {isDraftPreviewMode && studentPortfolio && !studentPortfolio.visible && (
                <Box mb={3} mt={5} width="50%">
                  <InformationalCaption
                    title={t(
                      'As soon as you publish, your portfolio will become visible to your educators and parents in AYO.',
                    )}
                  />
                </Box>
              )}
              <StudentPortfolioButtonsBlock
                deletePortfolioHandler={deletePortfolioHandler}
                getPublishedPortfolioActions={getPublishedPortfolioActions}
                isEditMode={isEditMode}
                portfolioData={portfolioData}
                portfolioId={studentPortfolio?.id}
                portfolioTitle={portfolioTitle}
                previewModeHandler={previewModeHandler}
                printHandler={printHandler}
                publishModeView={publishModeView}
                setIsEditMode={setIsEditMode}
                setPublishModeView={setPublishModeView}
                status={studentPortfolio?.status}
                studentId={studentId}
                unpublishPortfolioHandler={unpublishPortfolio}
                userRole={userState.profile.role}
              />
            </Box>
          ) : (
            <Box m={publishModeView ? 5 : 0}>
              <Button
                component={RouterLink}
                gaLabel="Back to portfolios"
                to={textByRoleCofig[userState.profile.role].backToLink}
                variant="primary"
              >
                {t(textByRoleCofig[userState.profile.role].backToText)}
              </Button>
            </Box>
          )}
          <PortfolioSettingsDialog
            changePortfolioVisibilityHandler={changePortfolioVisibilityHandler}
            isOpen={isSettingsDialogOpen}
            isPortfolioVisible={studentPortfolio?.visible}
            isWithVisibilitySetting={studentPortfolio?.status === PublicationStatuses.DRAFT}
            portfolioSettings={portfolioSettings}
            setIsDialogOpen={setIsSettingsDialogOpen}
            settingsHandler={settingsHandler}
            studentId={studentId}
            studentPortfolioId={studentPortfolio?.id}
          />
        </WrapperContainer>
      ) : (
        <MobileNotSupported
          explanation={t(
            "Please open the student's portfolio section with a tablet or desktop version to use this functionality",
          )}
        />
      )}
    </PageWrapper>
  );
};
export default PortfolioDetailsPage;
