import React, { useCallback, useMemo, useState } from 'react';
import { Box, Grid, Tab, useMediaQuery, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { TabPanel, Tabs } from '../../../../../atoms';
import { GaActions, GaCategories } from '../../../../../../constants/enums';
import { ClubRights } from '../../../../../../constants/club-hub';
import { GA } from '../../../../../../utils';

import ClubPostSection from './sections/post-section/ClubPostSection';
import ClubEventsSection from './sections/events-section/ClubEventsSection';

const SectionsTabsConfig = {
  POSTS: {
    value: 'POSTS',
    label: 'Posts',
  },
  EVENTS: {
    value: 'EVENTS',
    label: 'Events',
  },
};

const ClubPostAndEventsSection = ({ clubId, clubRights, isClubOwner }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpMd = useMediaQuery(theme.breakpoints.up('md'));

  const [activeTab, setActiveTab] = useState(SectionsTabsConfig.POSTS.value);

  const tabsChangeHandler = useCallback((e, value) => {
    setActiveTab(value);
    GA.logInteraction({
      category: GaCategories.BEHAVIOR,
      action: GaActions.TAB_CLICK,
      label: SectionsTabsConfig[value].label,
    });
  }, []);

  const showTabs = useMemo(
    () => !isWidthUpMd && clubRights[ClubRights.VIEW_EVENTS],
    [clubRights, isWidthUpMd],
  );

  return showTabs ? (
    <Box>
      <Tabs onChange={tabsChangeHandler} value={activeTab}>
        <Tab
          disableRipple
          label={t(SectionsTabsConfig.POSTS.label)}
          value={SectionsTabsConfig.POSTS.value}
        />
        <Tab
          disableRipple
          label={t(SectionsTabsConfig.EVENTS.label)}
          value={SectionsTabsConfig.EVENTS.value}
        />
      </Tabs>
      <Box mt={5}>
        <TabPanel currentTabValue={activeTab} value={SectionsTabsConfig.POSTS.value}>
          <ClubPostSection clubId={clubId} clubRights={clubRights} isClubOwner={isClubOwner} />
        </TabPanel>
        <TabPanel currentTabValue={activeTab} value={SectionsTabsConfig.EVENTS.value}>
          <ClubEventsSection clubId={clubId} clubRights={clubRights} />
        </TabPanel>
      </Box>
    </Box>
  ) : (
    <Grid container mt={5} spacing={3}>
      <Grid item md={7.8} xs={12}>
        <ClubPostSection clubId={clubId} clubRights={clubRights} isClubOwner={isClubOwner} />
      </Grid>
      {clubRights[ClubRights.VIEW_EVENTS] && (
        <Grid item md={4.2} xs={12}>
          <ClubEventsSection clubId={clubId} clubRights={clubRights} />
        </Grid>
      )}
    </Grid>
  );
};

ClubPostAndEventsSection.propTypes = {
  clubId: PropTypes.string.isRequired,
  clubRights: PropTypes.instanceOf(Object).isRequired,
  isClubOwner: PropTypes.bool.isRequired,
};
export default ClubPostAndEventsSection;
