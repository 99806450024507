/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { PickersDay } from '@mui/x-date-pickers';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { DatePicker } from '../../atoms';
import { getActiveWeekBoundaryDays } from '../../../utils';

const WeekPicker = ({ minDate, maxDate, value, ...pickerProps }) => {
  const [hoveredDate, setHoveredDate] = useState(null);

  const [activeWeekStart, activeWeekEnd] = getActiveWeekBoundaryDays(value, minDate, maxDate);

  const [activeWeekStartHover, activeWeekEndHover] = getActiveWeekBoundaryDays(
    hoveredDate,
    minDate,
    maxDate,
  );

  const renderWeekPickerDay = (date, selectedDates, pickersDayProps) => {
    const isBetween = date.isBetween(activeWeekStart, activeWeekEnd, 'day', '[]');
    const isFirstDay = date.isSame(activeWeekStart, 'day');
    const isLastDay = date.isSame(activeWeekEnd, 'day');

    const isBetweenHover =
      hoveredDate && date.isBetween(activeWeekStartHover, activeWeekEndHover, 'day', '[]');
    const isFirstDayHover = hoveredDate && date.isSame(activeWeekStartHover, 'day');
    const isLastDayHover = hoveredDate && date.isSame(activeWeekEndHover, 'day');

    const [weekStart, weekEnd] = getActiveWeekBoundaryDays(date, minDate, maxDate);

    return (
      <PickersDay
        aria-label={`Week from ${weekStart.format('D')} to ${weekEnd.format('D MMMM')}`}
        className={classNames({
          'is-between-selected': isBetween,
          'is-first-selected': isFirstDay,
          'is-last-selected': isLastDay,
          'is-between-hover': !isBetween && isBetweenHover,
          'is-first-hover': !isBetween && isFirstDayHover,
          'is-last-hover': !isBetween && isLastDayHover,
        })}
        disableMargin
        onMouseEnter={() => setHoveredDate(date)}
        onMouseLeave={() => setHoveredDate(null)}
        {...pickersDayProps}
      />
    );
  };

  return (
    <DatePicker
      className="week-picker"
      renderDay={renderWeekPickerDay}
      {...pickerProps}
      maxDate={maxDate}
      minDate={minDate}
      value={value}
    />
  );
};

WeekPicker.propTypes = {
  minDate: PropTypes.instanceOf(Object),
  maxDate: PropTypes.instanceOf(Object),
  value: PropTypes.instanceOf(Object),
};

WeekPicker.defaultProps = {
  minDate: null,
  maxDate: null,
  value: null,
};

export default WeekPicker;
