import { byteToMegaByteCoeff } from '../../constants/values';
import { ContentTypesMap } from '../../constants/enums';
import { apiRoute } from '../../constants/routes';

export const getFileTypeFromExtension = (fileName) => {
  const parts = fileName.split('.');
  const extension = parts[parts.length - 1];
  switch (extension) {
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
      return ContentTypesMap.IMAGE;
    case 'mp3':
      return ContentTypesMap.MP3;
    case 'mp4':
      return ContentTypesMap.MP4;
    case 'pdf':
      return ContentTypesMap.PDF;
    default:
      return ContentTypesMap.FILE;
  }
};

export const fileFormatStringBuilder = (extensions, t) =>
  `${extensions
    .slice(0, extensions.length - 1)
    .join(', ')
    .toUpperCase()}
    ${extensions.length > 1 ? t('or') : ''}
    ${extensions[extensions.length - 1].toUpperCase()}`;

export const getFileErrors = (file, validations, t) => {
  const { allowedExtensions, maxFileSize } = validations;
  let errors = [];
  if (
    !file.name ||
    (allowedExtensions.length && allowedExtensions.indexOf(file.name.split('.').pop()) === -1)
  ) {
    errors = [
      ...errors,
      `${t('Upload failed, please try')} ${fileFormatStringBuilder(allowedExtensions, t)}`,
    ];
  }
  if (file.size > maxFileSize) {
    errors = [
      ...errors,
      `${t('Upload failed, file exceeded')} ${maxFileSize / byteToMegaByteCoeff} MB`,
    ];
  }
  return errors;
};

export const stopPlayback = (selectors) =>
  document.querySelectorAll(selectors)?.forEach((el) => {
    if (el.currentTime > 0 && !el.paused && !el.ended) {
      el.pause();
      // eslint-disable-next-line no-param-reassign
      el.currentTime = 0;
    }
  });

const buildFormData = (formData, object, dontNotationKeys = [], parentKey = null) => {
  Object.entries(object).forEach(([key, value]) => {
    const dotNotation = dontNotationKeys.includes(parentKey);
    const currentKey = parentKey
      ? dotNotation
        ? `${parentKey}.${key}`
        : `${parentKey}[${key}]`
      : key;
    if (value || typeof value === 'boolean') {
      if (Array.isArray(value) && value.length > 0) {
        if (value.some((el) => el instanceof File)) {
          value.forEach((file) => formData.append(currentKey, file));
        } else {
          value.forEach((entity, index) => {
            if (typeof entity === 'string') {
              formData.append(`${currentKey}[${index}]`, entity);
            } else {
              Object.entries(entity).forEach(([entityKey, entityValue]) => {
                if (entityValue || Number.isInteger(entityValue)) {
                  formData.append(`${currentKey}[${index}].${entityKey}`, entityValue);
                }
              });
            }
          });
        }
      } else if (value instanceof Object && !Array.isArray(value) && !(value instanceof File)) {
        buildFormData(formData, value, dontNotationKeys, key);
      } else {
        formData.append(currentKey, value);
      }
    }
  });
};

export const getFormDataObject = (postBody, dotNotationKeys = []) => {
  const formData = new FormData();

  buildFormData(formData, postBody, dotNotationKeys);

  return formData;
};

export const getImageSrc = (id, ownerId) => `${apiRoute}/attachments/${id}/owners/${ownerId}`;
