import React, { useEffect } from 'react';

import { PageWrapper } from '../../../organisms';
import { useLogin } from '../../../../utils';
import { useSearchParams } from '../../../../hooks';
import { LocalStorageKeysMap } from '../../../../constants/enums';

const EmailProceedPage = () => {
  const { signInHandler } = useLogin();
  const urlParams = useSearchParams();

  useEffect(() => {
    const loginProvider = urlParams.get('loginProvider').replace('_', '');
    const loginRedirectKey = urlParams.get('loginRedirectKey');
    const loginRedirectUrl = urlParams.get('loginRedirectUrl');
    const currentCampus = urlParams.get('schoolName');
    const userRole = urlParams.get('userRole');
    if (userRole) {
      sessionStorage.setItem(LocalStorageKeysMap.AYO_ROLE_IN_SCHOOL, userRole);
    }
    sessionStorage.setItem(LocalStorageKeysMap.AYO_EMAIL_REDIRECT, true);
    signInHandler(loginProvider, loginRedirectKey, loginRedirectUrl, currentCampus);
  }, [signInHandler, urlParams]);

  return <PageWrapper />;
};

export default EmailProceedPage;
