import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';

import { SkeletonMask } from '../../../../atoms';
import { useLazyDataLoad } from '../../../../../hooks';
import { SectionHashes } from '../../../../../constants/routes';

const PortfolioLoadingWrapper = ({
  hasData,
  isLazyLoad,
  loadSectionDataHandler,
  portfolioSection,
  sectionKey,
}) => {
  const match = useRouteMatch();
  const { urlPortfolioId } = match.params;
  const { isDataLoaded, loadActivatorRef } = useLazyDataLoad(
    () => loadSectionDataHandler(sectionKey),
    [urlPortfolioId],
  );

  useEffect(() => {
    if (!isLazyLoad) {
      loadSectionDataHandler(sectionKey);
    }
  }, [isLazyLoad, loadSectionDataHandler, sectionKey]);

  return (
    <Box id={SectionHashes[sectionKey].replace('#', '')}>
      <Box ref={!isLazyLoad || hasData ? null : loadActivatorRef} />
      {isDataLoaded || hasData ? (
        portfolioSection
      ) : (
        <Box mb={5}>
          <SkeletonMask />
        </Box>
      )}
    </Box>
  );
};

PortfolioLoadingWrapper.propTypes = {
  hasData: PropTypes.bool.isRequired,
  isLazyLoad: PropTypes.bool.isRequired,
  loadSectionDataHandler: PropTypes.func.isRequired,
  portfolioSection: PropTypes.node.isRequired,
  sectionKey: PropTypes.string.isRequired,
};

export default PortfolioLoadingWrapper;
