import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Timeline as MUITimeline } from '@mui/lab';

const Timeline = ({ align, children, className }) => (
  <MUITimeline align={align} className={classnames('ayo-timeline', className)}>
    {children}
  </MUITimeline>
);

Timeline.propTypes = {
  align: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
    .isRequired,
  className: PropTypes.string,
};

Timeline.defaultProps = {
  align: 'left',
  className: '',
};

export default Timeline;
