import React, { useCallback, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { Button, Tooltip, Typography } from '../../../../atoms';
import { ClickableCard, TextWithTooltip } from '../../../../moleculas';
import { PortfolioUnpublishDialog } from '../../../../organisms';
import { UserActions, UserContext } from '../../../../../context';
import { getRelativeDateTime } from '../../../../../utils';
import {
  familyFeedRoute,
  myStudentsRoute,
  portfoliosRoute,
  studentsRoute,
} from '../../../../../constants/routes';
import { PublicationStatuses, RolesMap } from '../../../../../constants/enums';
import { ReactComponent as LinkIcon } from '../../../../../resources/icons/link_dark.svg';
import { ReactComponent as UsersIcon } from '../../../../../resources/icons/users.svg';
import { ReactComponent as UserIcon } from '../../../../../resources/icons/person_grey.svg';
import { ReactComponent as PublishedPortfolioImage } from '../../../../../resources/images/portfolio/published_portfolio.svg';
import { ReactComponent as DraftPortfolioImage } from '../../../../../resources/images/portfolio/draft_portfolio.svg';
import PortfolioGetLinkDialog from '../portfolio-get-link-dialog/PortfolioGetLinkDialog';

const PortfolioCard = ({
  getPublishedPortfolioActions,
  deleteHandler,
  unpublishHandler,
  portfolio,
  withActions,
}) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isUnpublishDialogOpen, setUnpublishDialogOpen] = useState(false);

  const { state: userState, dispatch: dispatchUserState } = useContext(UserContext);
  const studentId = match.params.studentId || userState.profile.id;
  const isStudent = userState.profile.role === RolesMap.STUDENT;

  const redirectToPortfolioLink = useMemo(
    () => ({
      [RolesMap.STUDENT]: `${portfoliosRoute}/${portfolio.id}`,
      [RolesMap.TEACHER]: `${myStudentsRoute}/${studentId}${portfoliosRoute}/${portfolio.id}`,
      [RolesMap.ADMINISTRATOR]: `${studentsRoute}/${studentId}${portfoliosRoute}/${portfolio.id}`,
      [RolesMap.GUARDIAN]: `${familyFeedRoute}/${studentId}${portfoliosRoute}/${portfolio.id}`,
    }),
    [studentId, portfolio.id],
  );

  const portfolioDate = useMemo(
    () =>
      t(
        `${
          portfolio.status === PublicationStatuses.PUBLISHED ? 'Published time' : 'Last update time'
        }`,
        {
          time: getRelativeDateTime(portfolio.updatedDate, i18n.language, t),
        },
      ),
    [portfolio, i18n, t],
  );

  const portfolioVisibilityStatus = useMemo(
    () =>
      portfolio.visible
        ? t('Visible to your educators and parents in AYO.')
        : t('Visible only to you.'),
    [portfolio, t],
  );

  const portfolioCardAriaLabel = useMemo(
    () => `${portfolioVisibilityStatus}: ${portfolio.title} - ${portfolioDate}`,
    [portfolio, portfolioDate, portfolioVisibilityStatus],
  );

  const onCardClickHandler = useCallback(() => {
    dispatchUserState({
      type: UserActions.SET_USER_PORTFOLIO_EDIT_MODE,
      data: false,
    });
    history.push(redirectToPortfolioLink[userState.profile.role]);
  }, [dispatchUserState, history, redirectToPortfolioLink, userState.profile.role]);

  const editHandler = useCallback(() => {
    dispatchUserState({
      type: UserActions.SET_USER_PORTFOLIO_EDIT_MODE,
      data: true,
    });
    history.push(`${portfoliosRoute}/${portfolio.id}`);
  }, [dispatchUserState, history, portfolio.id]);

  const onDeleteHandler = useCallback(() => {
    deleteHandler(portfolio.id);
  }, [deleteHandler, portfolio.id]);

  return (
    <>
      <ClickableCard
        actionButtons={
          userState.profile.role === RolesMap.STUDENT &&
          portfolio.status === PublicationStatuses.PUBLISHED && (
            <Box>
              <Button
                gaLabel="Get link"
                onClick={() => setIsDialogOpen(true)}
                startIcon={<LinkIcon />}
              >
                {t('Get link')}
              </Button>
              <PortfolioGetLinkDialog
                isDialogOpen={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                portfolioId={portfolio.id}
                studentId={userState.profile.id}
              />
            </Box>
          )
        }
        actions={
          withActions &&
          getPublishedPortfolioActions(
            editHandler,
            () => setUnpublishDialogOpen(true),
            onDeleteHandler,
          )
        }
        ariaLabel={portfolioCardAriaLabel}
        gaLabel="Portfolio card click"
        mainContent={
          <Box display="flex">
            <Box height="100%">
              {portfolio.status === PublicationStatuses.PUBLISHED ? (
                <PublishedPortfolioImage />
              ) : (
                <DraftPortfolioImage />
              )}
            </Box>
            <Box flex={1} ml={2}>
              <Box
                alignItems="baseline"
                display="flex"
                justifyContent="space-between"
                mb={withActions ? 2 : 1}
                width={withActions ? '80%' : '100%'}
              >
                <TextWithTooltip
                  className="ayo-portfolio-main-page__title"
                  rowsCount={2}
                  title={portfolio.title}
                  titleVariant="subtitle2"
                  typographyProps={{ align: 'left' }}
                />
                {portfolio.status === PublicationStatuses.DRAFT && isStudent && (
                  <Box className="ayo-portfolio-main-page__visibility-svg">
                    <Tooltip
                      enterTouchDelay={0}
                      leaveTouchDelay={5000}
                      placement="bottom"
                      tabIndex={0}
                      title={portfolioVisibilityStatus}
                    >
                      {portfolio.visible ? <UsersIcon /> : <UserIcon />}
                    </Tooltip>
                  </Box>
                )}
              </Box>
              <Typography isLabel variant="caption" width="max-content">
                {portfolioDate}
              </Typography>
            </Box>
          </Box>
        }
        onClick={onCardClickHandler}
      />
      <PortfolioUnpublishDialog
        isOpen={isUnpublishDialogOpen}
        onClose={() => setUnpublishDialogOpen(false)}
        onUnpublish={unpublishHandler}
        portfolioId={portfolio.id}
        portfolioTitle={portfolio.title}
      />
    </>
  );
};

PortfolioCard.propTypes = {
  deleteHandler: PropTypes.func,
  getPublishedPortfolioActions: PropTypes.func,
  portfolio: PropTypes.instanceOf(Object).isRequired,
  unpublishHandler: PropTypes.func,
  withActions: PropTypes.bool,
};

PortfolioCard.defaultProps = {
  deleteHandler: () => {},
  getPublishedPortfolioActions: () => {},
  unpublishHandler: () => {},
  withActions: false,
};

export default PortfolioCard;
