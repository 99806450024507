import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Container, Grid, useMediaQuery, useTheme } from '@mui/material';

import { Card, Typography } from '../../../../../atoms';
import { ReactComponent as AptitudesBenefitAYODesktop } from '../../../../../../resources/images/aptitudes/landing-page/benefit-card-ayo-desktop.svg';
import { ReactComponent as AptitudesBenefitAYOMobile } from '../../../../../../resources/images/aptitudes/landing-page/benefit-card-ayo-mobile.svg';
import { ReactComponent as AptitudesBenefitEducatorsDesktop } from '../../../../../../resources/images/aptitudes/landing-page/benefit-card-educators-desktop.svg';
import { ReactComponent as AptitudesBenefitEducatorsMobile } from '../../../../../../resources/images/aptitudes/landing-page/benefit-card-educators-mobile.svg';
import { ReactComponent as AptitudesBenefitParentsDesktop } from '../../../../../../resources/images/aptitudes/landing-page/benefit-card-parents-desktop.svg';
import { ReactComponent as AptitudesBenefitParentsMobile } from '../../../../../../resources/images/aptitudes/landing-page/benefit-card-parents-mobile.svg';
import { ReactComponent as AptitudesBenefitStudentsDesktop } from '../../../../../../resources/images/aptitudes/landing-page/benefit-card-students-desktop.svg';
import { ReactComponent as AptitudesBenefitStudentsMobile } from '../../../../../../resources/images/aptitudes/landing-page/benefit-card-students-mobile.svg';

const AptitudesBenefitSectionConfig = [
  {
    alt: 'A student surrounded with a lunch bag, a school bag, and earphones',
    description:
      'By knowing their strengths and powers, students can apply them in the most suitable areas to become successful',
    illustration: {
      desktop: AptitudesBenefitStudentsDesktop,
      mobile: AptitudesBenefitStudentsMobile,
    },
    title: 'Students',
  },
  {
    alt: 'A teacher surrounded with a cup of coffee, a badge, and glasses',
    description:
      'The aptitudes section can be an additional source of information for educators, providing valuable insights for learning activities',
    illustration: {
      desktop: AptitudesBenefitEducatorsDesktop,
      mobile: AptitudesBenefitEducatorsMobile,
    },
    title: 'Educators',
  },
  {
    alt: 'A couple surrounded with a dog, a plant and crayons',
    description:
      'Parents can better understand their kids’ strengths and help them figure out their future pathway within the school and beyond',
    illustration: {
      desktop: AptitudesBenefitParentsDesktop,
      mobile: AptitudesBenefitParentsMobile,
    },
    title: 'Parents',
  },
  {
    alt: 'AYO logo and some graphs',
    description:
      'After learning students’ superpowers, AYO can improve the suggestions in the app, making them more accurate and useful',
    illustration: {
      desktop: AptitudesBenefitAYODesktop,
      mobile: AptitudesBenefitAYOMobile,
    },
    title: 'AYO',
  },
];

const AptitudesBenefitSection = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Box
      className="ayo-aptitudes-benefit-section"
      pb={isWidthUpSm ? 15 : 3}
      pt={isWidthUpSm ? 15 : 13}
    >
      <Container>
        <Box mb={isWidthUpSm ? 5 : 3}>
          <Typography component="h2" variant="h2">
            {t('Who will benefit from aptitudes in AYO?')}
          </Typography>
        </Box>
        <Grid container spacing={3}>
          {AptitudesBenefitSectionConfig.map((benefitCard) => (
            <Grid key={benefitCard.title} item sm={6} xs={12}>
              <Card
                cardHeader={
                  <Box ml={isWidthUpSm ? 1 : 0} mr={isWidthUpSm ? 8 : 0} mt={isWidthUpSm ? 1 : 0}>
                    <Typography component="h3" variant="subtitle2">
                      {t(benefitCard.title)}
                    </Typography>
                    <Box mb={isWidthUpSm ? 3 : 2} mt={1}>
                      <Typography variant="body2">{t(benefitCard.description)}</Typography>
                    </Box>
                  </Box>
                }
                className="ayo-aptitudes-benefit-section__card"
                elevation={0}
                mediaContent={
                  isWidthUpSm ? benefitCard.illustration.desktop : benefitCard.illustration.mobile
                }
                mediaContentAriaLabel={t(benefitCard.alt)}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default AptitudesBenefitSection;
