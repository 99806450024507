/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Rating as MUIRating } from '@mui/material';
import classnames from 'classnames';

import { GA } from '../../../utils';
import { GaActions, GaCategories } from '../../../constants/enums';

const Rating = ({ className, gaLabel, onChange, ...rest }) => (
  <MUIRating
    className={classnames(className)}
    onChange={(e) => {
      if (gaLabel) {
        GA.logInteraction({
          category: GaCategories.BEHAVIOR,
          action: GaActions.RATING_CHANGE,
          label: gaLabel,
        });
      }
      onChange(e);
    }}
    {...rest}
  />
);

Rating.propTypes = {
  className: PropTypes.string,
  gaLabel: PropTypes.string,
  onChange: PropTypes.func,
};

Rating.defaultProps = {
  className: '',
  gaLabel: '',
  onChange: null,
};

export default Rating;
