import React from 'react';
import PropTypes from 'prop-types';
import { LinearProgress as MUILinearProgress } from '@mui/material';

const LinearProgress = ({ value }) => (
  <MUILinearProgress
    className="ayo-linear-progress"
    value={value}
    variant={value !== null ? 'determinate' : 'indeterminate'}
  />
);

LinearProgress.propTypes = {
  value: PropTypes.number,
};

LinearProgress.defaultProps = {
  value: null,
};

export default LinearProgress;
