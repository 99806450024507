import React from 'react';
import PropTypes from 'prop-types';

import { TabPanel } from '../../atoms';

const TabPanelWrapper = ({ children, value, currentValue, isWrap }) =>
  isWrap ? (
    <TabPanel currentTabValue={currentValue} value={value}>
      {children}
    </TabPanel>
  ) : (
    <>{children}</>
  );

TabPanelWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  currentValue: PropTypes.node.isRequired,
  isWrap: PropTypes.bool,
  value: PropTypes.node.isRequired,
};

TabPanelWrapper.defaultProps = {
  isWrap: true,
};

export default TabPanelWrapper;
