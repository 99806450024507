import { ReactComponent as FileIcon } from '../resources/icons/file.svg';
import { ReactComponent as LinkPdf } from '../resources/icons/link_pdf.svg';
import { ReactComponent as LinkImage } from '../resources/icons/link_img.svg';
import { ReactComponent as LinkMp3 } from '../resources/icons/link_mp3.svg';
import { ReactComponent as LinkMp4 } from '../resources/icons/link_mp4.svg';
import { ReactComponent as LinkSheets } from '../resources/icons/link_sheets.svg';
import { ReactComponent as LinkSlides } from '../resources/icons/link_slides.svg';
import { ReactComponent as LinkForms } from '../resources/icons/link_forms.svg';
import { ReactComponent as LinkDrawing } from '../resources/icons/link_drawing.svg';
import { ReactComponent as LinkYoutube } from '../resources/icons/link_youtube.svg';
import { ReactComponent as LinkGeneral } from '../resources/icons/link_general.svg';

import { ContentTypesMap } from './enums';

export const IconsByTypeMap = {
  [ContentTypesMap.DRAWING]: LinkDrawing,
  [ContentTypesMap.FILE]: FileIcon,
  [ContentTypesMap.FORMS]: LinkForms,
  [ContentTypesMap.GENERAL]: LinkGeneral,
  [ContentTypesMap.IMAGE]: LinkImage,
  [ContentTypesMap.MP3]: LinkMp3,
  [ContentTypesMap.MP4]: LinkMp4,
  [ContentTypesMap.PDF]: LinkPdf,
  [ContentTypesMap.SHEETS]: LinkSheets,
  [ContentTypesMap.SLIDES]: LinkSlides,
  [ContentTypesMap.YOUTUBE]: LinkYoutube,
};

export default { IconsByTypeMap };
