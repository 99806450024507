import React, { useState, useRef, useContext, useCallback, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';
import PropTypes from 'prop-types';

import { Button, Typography } from '../../../../../atoms';
import { AppActions, AppContext } from '../../../../../../context';
import { useAccordionToggleState } from '../../../../../../hooks';
import { useContentModerationService } from '../../../../../../services';
import { ContentModerationTypesMap, SchoolLevels } from '../../../../../../constants/enums';

import InterestModerationNode from './components/interest-moderation-node/InterestModerationNode';

const getUpdatedChildren = (childItems, newValue, entitiesListUpdater, entitiesToChange) => {
  if (!childItems) return childItems;
  return childItems.map((item) => {
    const shouldUpdateItem = !entitiesToChange || entitiesToChange.includes(item.entityKey);
    if (item.isVisible !== newValue) {
      entitiesListUpdater(item.entityKey);
    }
    return shouldUpdateItem
      ? {
          ...item,
          isVisible: newValue,
          childItems: getUpdatedChildren(
            item.childItems,
            newValue,
            entitiesListUpdater,
            entitiesToChange,
          ),
        }
      : { ...item };
  });
};

const replaceOldInterest = (interests, newInterest) => {
  const interestReplacer = (interest) => {
    if (!interest) return interest;
    if (interest.entityKey === newInterest.entityKey) {
      return newInterest;
    }
    return {
      ...interest,
      childItems: interest.childItems?.map((childInterest) => interestReplacer(childInterest)),
    };
  };

  return interests.map((interest) => interestReplacer(interest));
};

const InterestsModeration = ({ schoolLevel, isShowHideGranted, isEditApproveGranted }) => {
  const [interestsContent, setInterestsContent] = useState(null);

  const { dispatch: dispatchAppState } = useContext(AppContext);

  const { t } = useTranslation();

  const lastSwitchAction = useRef();

  const { getContentModerationData, postContentVisibility } = useContentModerationService();

  useEffect(() => {
    getContentModerationData(ContentModerationTypesMap.INTERESTS, schoolLevel).then(
      (interestsContentData) => setInterestsContent(interestsContentData),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onEntityChange = (newEntity) => {
    const newInterests = replaceOldInterest(interestsContent, newEntity);
    setInterestsContent(newInterests);
  };

  const onInterestApprove = (interest, newApproved) => {
    const newInterest = { ...interest, isApproved: newApproved };
    onEntityChange(newInterest);
  };

  const onTranslationChange = (interest, newState) => {
    const newInterest = { ...interest, ...newState };
    onEntityChange(newInterest);
  };

  const toggleVisibility = useCallback(
    (interest, newValue, parentInterests, entitiesToChange = null) => {
      let updatedEntities = [interest.entityKey];
      const entitiesListUpdater = (key) => updatedEntities.push(key);
      const newInterest = {
        ...interest,
        isVisible: newValue,
        childItems: getUpdatedChildren(
          interest.childItems,
          newValue,
          entitiesListUpdater,
          entitiesToChange,
        ),
      };
      let newInterestsState = interestsContent;
      if (newValue && parentInterests) {
        parentInterests
          .map((parentInterest) => ({ ...parentInterest, isVisible: newValue }))
          .forEach((parentInterest) => {
            newInterestsState = replaceOldInterest(newInterestsState, parentInterest);
          });
        updatedEntities = [
          ...updatedEntities,
          ...(parentInterests?.map((parentInterest) => parentInterest.entityKey) || []),
        ];
      }
      newInterestsState = replaceOldInterest(newInterestsState, newInterest);
      setInterestsContent(newInterestsState);
      lastSwitchAction.current = {
        interest,
        newValue,
        touchedInterests: updatedEntities,
        parentInterests,
      };
      return postContentVisibility(
        entitiesToChange || updatedEntities,
        newValue,
        schoolLevel,
        interest.entityKey,
        interest.keys.name.translationKey,
        !!interest.childItems,
      );
    },
    [interestsContent, postContentVisibility, schoolLevel],
  );

  const onUndo = useCallback(() => {
    const { interest, newValue, touchedInterests, parentInterests } = lastSwitchAction.current;
    toggleVisibility(interest, !newValue, parentInterests, touchedInterests);
    dispatchAppState({ type: AppActions.SET_SNACKBAR_STATUS, data: null });
  }, [dispatchAppState, toggleVisibility]);

  const dispatchResponseSnackbar = useCallback(
    (interest, newValue, actionHandler, failed = false) => {
      const interestName = interest.keys.name.EN_US;
      if (failed) {
        const newStatusLabel = newValue ? 'show' : 'hide';
        dispatchAppState({
          type: AppActions.SET_SNACKBAR_STATUS,
          data: {
            text: (
              <Trans
                components={{ b: <strong /> }}
                i18nKey={
                  interest.childItems
                    ? `Failed to ${newStatusLabel} all categories for category, please try once more`
                    : `Failed to ${newStatusLabel} category, please try once more`
                }
                values={{ name: interestName }}
              />
            ),
            type: 'error',
            action: (
              <Button autoFocus onClick={actionHandler}>
                {t('Retry')}
              </Button>
            ),
          },
        });
      } else {
        const newStatusLabel = newValue ? 'shown' : 'hidden';
        dispatchAppState({
          type: AppActions.SET_SNACKBAR_STATUS,
          data: {
            text: (
              <Trans
                components={{ b: <strong /> }}
                i18nKey={
                  interest.childItems
                    ? `All categories for category are ${newStatusLabel} for students in AYO`
                    : `Category is ${newStatusLabel} for students in AYO`
                }
                values={{ name: interestName }}
              />
            ),
            type: 'success',
            action: (
              <Button autoFocus onClick={actionHandler}>
                {t('Undo')}
              </Button>
            ),
          },
        });
      }
    },
    [dispatchAppState, t],
  );

  const onRetry = useCallback(() => {
    const savedInterestsState = interestsContent;
    const { interest, newValue, parentInterests } = lastSwitchAction.current;
    toggleVisibility(interest, newValue, parentInterests).catch(() => {
      setInterestsContent(savedInterestsState);
      dispatchResponseSnackbar(interest, newValue, onRetry, true);
    });
    dispatchAppState({ type: AppActions.SET_SNACKBAR_STATUS, data: null });
  }, [dispatchAppState, dispatchResponseSnackbar, interestsContent, toggleVisibility]);

  const onVisibilitySwitch = (interest, newValue, parentInterests) => {
    const savedInterestsState = interestsContent;
    toggleVisibility(interest, newValue, parentInterests)
      .then(() => {
        dispatchResponseSnackbar(interest, newValue, onUndo);
      })
      .catch(() => {
        setInterestsContent(savedInterestsState);
        dispatchResponseSnackbar(interest, newValue, onRetry, true);
      });
  };

  const [openedInterestKey, toggleOpenedInterestKey] = useAccordionToggleState();

  if (!interestsContent) return null;
  return (
    <Box className="interests-moderation__container" role="table">
      <Grid className="interests-grid-header" container justifyContent="space-between" role="row">
        <Grid item xs={4}>
          <Typography isLabel role="columnheader" variant="caption">
            {t('Original (EN)')}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography isLabel role="columnheader" variant="caption">
            {t('Translation (ES)')}
          </Typography>
        </Grid>
        {isEditApproveGranted && (
          <>
            <Grid container item justifyContent="center" xs={1}>
              <Typography isLabel role="columnheader" variant="caption">
                {t('Approve')}
              </Typography>
            </Grid>
            <Grid container item justifyContent="center" xs={1}>
              <Typography isLabel role="columnheader" variant="caption">
                {t('Edit')}
              </Typography>
            </Grid>
          </>
        )}
        {isShowHideGranted && (
          <Grid item xs={1}>
            <Typography isLabel role="columnheader" variant="caption">
              {t('Show/Hide')}
            </Typography>
          </Grid>
        )}
      </Grid>
      {interestsContent.map((interest) => (
        <Box key={interest.entityKey} mb={2}>
          <InterestModerationNode
            baseHeadingLevel={2}
            expandedInterestKey={openedInterestKey}
            interest={interest}
            isEditApproveGranted={isEditApproveGranted}
            isShowHideGranted={isShowHideGranted}
            level={0}
            onApprove={onInterestApprove}
            onExpanded={toggleOpenedInterestKey}
            onTranslationChange={onTranslationChange}
            onVisibilitySwitch={onVisibilitySwitch}
          />
        </Box>
      ))}
    </Box>
  );
};

InterestsModeration.propTypes = {
  schoolLevel: PropTypes.oneOf(Object.values(SchoolLevels)),
  isShowHideGranted: PropTypes.bool,
  isEditApproveGranted: PropTypes.bool,
};

InterestsModeration.defaultProps = {
  schoolLevel: SchoolLevels.ELEMENTARY,
  isShowHideGranted: null,
  isEditApproveGranted: null,
};

export default InterestsModeration;
