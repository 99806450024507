import { useCallback, useContext } from 'react';

import { AppActions, AppContext } from '../../context';

const useSnackbar = () => {
  const { dispatch: dispatchAppState } = useContext(AppContext);

  const setSnackBarStatus = useCallback(
    (value) => {
      if (!value) return;
      dispatchAppState({ type: AppActions.SET_SNACKBAR_STATUS, data: value });
    },
    [dispatchAppState],
  );

  return {
    setSnackBarStatus,
  };
};

export default useSnackbar;
