import React, { useCallback, useContext } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';

import { EditingButton } from '../../../../../moleculas';
import { useContentModerationService } from '../../../../../../services';
import { ReactComponent as CheckOutlineIcon } from '../../../../../../resources/icons/check_outline.svg';
import { AppActions, AppContext } from '../../../../../../context';

const ApproveModerationToggleButton = ({ entity, onChange }) => {
  const { postContentApprove } = useContentModerationService();

  const {
    isApproved,
    entityKey,
    keys: {
      name: { translationKey, EN_US: name },
    },
  } = entity;

  const { dispatch: dispatchAppState } = useContext(AppContext);

  const onApprove = useCallback(() => {
    onChange(entity, !isApproved);
    postContentApprove(entityKey, !isApproved, translationKey)
      .then(() => {
        const newApproveLabel = isApproved ? 'unapproved' : 'approved';
        dispatchAppState({
          type: AppActions.SET_SNACKBAR_STATUS,
          data: {
            text: (
              <Trans
                components={{ b: <b /> }}
                i18nKey="Translation approve for entity has been changed"
                values={{
                  entityName: name,
                  statusLabel: newApproveLabel,
                }}
              />
            ),
            type: isApproved ? 'success_outlined' : 'success',
          },
        });
      })
      .catch(() => {
        onChange(entity, isApproved);
        const newApproveLabel = isApproved ? 'unapprove' : 'approve';
        dispatchAppState({
          type: AppActions.SET_SNACKBAR_STATUS,
          data: {
            text: (
              <Trans
                components={{ b: <b /> }}
                i18nKey="Failed to change translation approve for entity"
                values={{
                  entityName: name,
                  statusLabel: newApproveLabel,
                }}
              />
            ),
            type: 'error',
          },
        });
      });
  }, [
    dispatchAppState,
    entity,
    entityKey,
    isApproved,
    name,
    onChange,
    postContentApprove,
    translationKey,
  ]);

  return (
    <EditingButton
      ariaLabel={`${isApproved ? 'Unapprove' : 'Approve'} ${entity.keys.name.EN_US}`}
      className={classnames('approve-button', { 'approve-button--approved': isApproved })}
      icon={<CheckOutlineIcon />}
      onClick={onApprove}
    />
  );
};

ApproveModerationToggleButton.propTypes = {
  entity: PropTypes.shape({
    isApproved: PropTypes.bool,
    entityKey: PropTypes.string,
    keys: PropTypes.instanceOf(Object),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ApproveModerationToggleButton;
