import React, { useCallback, useState, useContext, useMemo, useEffect } from 'react';
import classnames from 'classnames';
import { Container, Grid, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Typography } from '../../atoms';
import { CampusLabel } from '../../moleculas';
import {
  AccordionGradeView,
  FeedbackPopup,
  HighlightsBlock,
  InterventionRequiredGroupView,
  NoStudentsScreen,
  PageWrapper,
  SearchStudentsContainer,
} from '../../organisms';
import { UserActions, UserContext } from '../../../context';
import {
  FeedbackFunctionalityKeysMap,
  HighlightsInitiatives,
  LocalStorageKeysMap,
  OneTimeActionsMap,
  StudentActivityCriterions,
} from '../../../constants/enums';
import { useAnalyticsService, useOneTimeActionService } from '../../../services';
import FeedbackFormContainer from '../my-students/students-list-page/components/FeedbackFormContainer';
import {
  useProfile,
  transformSearchToObject,
  sanitizeFilters,
  getNormalizedLanguageCode,
  useEmotionalLearning,
} from '../../../utils';
import {
  useFiltersLastActionState,
  useSearchParams,
  useStudentsData,
  useStudentsFiltersSnackbars,
} from '../../../hooks';
import { campusSelectionRoute } from '../../../constants/routes';
import { ReactComponent as FeedbackGreenRibbonsImage } from '../../../resources/images/feedback_green_ribbons.svg';

const FEEDBACK_MAX_STUDENT_PROFILES_VISITED = 5;

const isStudentActive = (student) => student.active;

const StudentsPage = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const filterParams = useSearchParams();

  const filters = useMemo(() => transformSearchToObject(filterParams), [filterParams]);

  const [searchValue, setSearchValue] = useState('');

  const [stats, setStats] = useState(null);

  const { getHighlights } = useAnalyticsService();

  const { state: userState, dispatch: dispatchUserState } = useContext(UserContext);

  useEffect(() => {
    getHighlights(
      null,
      getNormalizedLanguageCode(i18n.language),
      userState.profile?.currentCampus,
    ).then((highlightsData) => setStats(highlightsData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language, userState.profile?.currentCampus]);

  const [totalStudentsState, setTotalStudentsState] = useState(null);
  const {
    suggestions,
    students,
    filteredStudents,
    totalStudentsCount,
    filteredStudentsCount,
    shouldRenderStudentsList,
    studentsMoodsTrends,
    studentsInterventionRequire,
  } = useStudentsData({
    activityCriterion: StudentActivityCriterions.ANY,
    searchValue,
    withMoodsTrend: true,
    filterString: new URLSearchParams(
      filters.sanitize ? sanitizeFilters(filters, stats, totalStudentsState, history) : filters,
    ).toString(),
  });

  useEffect(() => {
    setTotalStudentsState(totalStudentsCount);
  }, [totalStudentsCount]);

  const handleSearch = useCallback(
    (newValue) => {
      setSearchValue(newValue);
      history.replace({ search: null });
    },
    [history],
  );

  const getStudentProfileRedirectLink = (id) => `/students/${id}`;

  const activeUsersHintHidden = useMemo(
    () => userState.oneTimeActions.includes(OneTimeActionsMap.STUDENTS_ACTIVITY_NOTIFICATION),
    [userState],
  );

  const { postOneTimeAction } = useOneTimeActionService();

  const handleActiveNotification = useCallback(() => {
    postOneTimeAction(OneTimeActionsMap.STUDENTS_ACTIVITY_NOTIFICATION);
    dispatchUserState({
      type: UserActions.SET_ONE_TIME_ACTION,
      data: OneTimeActionsMap.STUDENTS_ACTIVITY_NOTIFICATION,
    });
  }, [dispatchUserState, postOneTimeAction]);

  const { getOneTimeActionStatus } = useProfile();

  const isFeedbackFormVisible = useMemo(
    () => !getOneTimeActionStatus(OneTimeActionsMap.MY_STUDENTS_FEEDBACK),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const [filtersUpdateState, updateFiltersLastActionState] = useFiltersLastActionState();

  const hasActiveStudent = useMemo(() => suggestions?.some(isStudentActive), [suggestions]);

  useStudentsFiltersSnackbars(
    filteredStudents,
    filteredStudentsCount,
    students,
    totalStudentsCount,
    filtersUpdateState,
  );

  const [currentHighlightsInitiative, setCurrentHighlightsInitiative] = useState();

  const {
    showInterventionRequestSection,
    negativeMoodTrendIcon,
    negativeMoodTrendTooltip,
    negativeMoodTrendAriaLabel,
  } = useEmotionalLearning(currentHighlightsInitiative, studentsMoodsTrends, filterParams);

  const { getIsCampusSelectionAvailable } = useProfile();

  return (
    <PageWrapper
      backToLink={getIsCampusSelectionAvailable() ? campusSelectionRoute : null}
      backToText={getIsCampusSelectionAvailable() ? t('Go to campus selection') : ''}
      mainElementClassName={classnames('ayo-students-list-page', {
        'main-wrapper': shouldRenderStudentsList(suggestions),
      })}
    >
      <Container>
        {shouldRenderStudentsList(suggestions) ? (
          <Grid container>
            <Grid container direction="column" item md={6} sm={8} xl={7} xs={12}>
              <Box>
                <Typography component="h1" variant="h1">
                  {t('Students')}
                </Typography>
              </Box>
              <CampusLabel />
              <Box mb={3}>
                <Typography isLabel variant="body2">
                  {t('Administrators students list label')}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <SearchStudentsContainer
                notification={{
                  handler: handleActiveNotification,
                  text: 'A green ribbon means there’s information in the profile',
                  visible: !activeUsersHintHidden,
                }}
                onSearch={handleSearch}
                searchValue={searchValue}
                studentCardRedirectLink={getStudentProfileRedirectLink}
                students={students}
                suggestions={suggestions}
              />
            </Grid>
            {!searchValue && (
              <Grid item xs={12}>
                <HighlightsBlock
                  infoMessage="Highlights graph informational message"
                  initiatives={[
                    HighlightsInitiatives.MOODS,
                    HighlightsInitiatives.INTERESTS,
                    HighlightsInitiatives.APTITUDES,
                    HighlightsInitiatives.ENDORSEMENTS,
                    HighlightsInitiatives.OPPORTUNITIES,
                    HighlightsInitiatives.LEADERSHIP_ATTRIBUTES,
                    HighlightsInitiatives.EXTRACURRICULARS,
                    HighlightsInitiatives.ACHIEVEMENTS,
                    HighlightsInitiatives.GOALS,
                    HighlightsInitiatives.PARENTS_ANSWERS,
                    HighlightsInitiatives.PORTFOLIO,
                  ]}
                  onFiltersUpdate={updateFiltersLastActionState}
                  onInitiativeChange={setCurrentHighlightsInitiative}
                  stats={stats}
                  title="Campus highlights for"
                  totalStudentsCount={totalStudentsCount}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              {showInterventionRequestSection &&
                !searchValue &&
                !!studentsInterventionRequire?.length && (
                  <InterventionRequiredGroupView
                    studentAriaLabelConstructor={negativeMoodTrendAriaLabel()}
                    studentCardRedirectLink={getStudentProfileRedirectLink}
                    studentsList={studentsInterventionRequire}
                    studentStatusIcon={negativeMoodTrendIcon()}
                    studentWrapperConstructor={negativeMoodTrendTooltip()}
                  />
                )}
            </Grid>
            <Grid item xs={12}>
              <AccordionGradeView
                studentAriaLabelConstructor={negativeMoodTrendAriaLabel()}
                studentCardRedirectLink={getStudentProfileRedirectLink}
                students={filteredStudents || students}
                studentStatusIcon={negativeMoodTrendIcon()}
                studentWrapperConstructor={negativeMoodTrendTooltip()}
              />
            </Grid>
            {isFeedbackFormVisible && (
              <Grid item xs={12}>
                <Box mb={10}>
                  <FeedbackFormContainer />
                </Box>
              </Grid>
            )}
          </Grid>
        ) : (
          <NoStudentsScreen />
        )}
      </Container>
      {+localStorage.getItem(LocalStorageKeysMap.AYO_STUDENT_PROFILES_VISITED) >=
        FEEDBACK_MAX_STUDENT_PROFILES_VISITED &&
        hasActiveStudent && (
          <FeedbackPopup
            CustomMainContent={<FeedbackGreenRibbonsImage />}
            functionalityKey={FeedbackFunctionalityKeysMap.GREEN_RIBBONS}
            isAutoShow
            title={t('Are the green ribbons helpful?')}
            type="yesno"
            variant="dark"
          />
        )}
    </PageWrapper>
  );
};

export default StudentsPage;
