import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Container, Grid, useMediaQuery, useTheme } from '@mui/material';
import classNames from 'classnames';

import { Image, Typography } from '../../../../../atoms';

const LessonPlannerAboutSectionConfig = [
  {
    headerEl: 'Grouping students for assignments',
    bodyEl:
      'AYO is here to assist you with its advanced Artificial Intelligence models and pipelines.',
    imageAlt: 'Three students are working on a project, standing around a large desk.',
    imageSrc: '/media/lesson-planner/about_section_grouping.png',
    imageDecorationsSrc: '/media/lesson-planner/about_section_grouping_dots.svg',
  },
  {
    headerEl: 'Motivating students to make meaningful choices during their educational journey',
    bodyEl:
      'Instead of sticking to the Tic-tac-toe boards, consider creating layered choices that provide students with a range of options for completing assignments.',
    imageAlt: 'Two little kids are sitting on the floor and looking at hand-made books.',
    imageSrc: '/media/lesson-planner/about_section_motivating.png',
    imageDecorationsSrc: '/media/lesson-planner/about_section_motivating_dots.svg',
  },
  {
    headerEl: 'Collaborating with your colleagues',
    bodyEl:
      'AYO offers useful tools for you to collaborate on the lesson creation and preparation, such as comments and sharing lessons.',
    imageAlt: 'A teacher and a student with a laptop in a classroom.',
    imageSrc: '/media/lesson-planner/about_section_collaborating.png',
    imageDecorationsSrc: '/media/lesson-planner/about_section_collaborating_dots.svg',
  },
];

const LessonPlannerAboutSection = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const isWidthUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const isWidthUpXl = useMediaQuery(theme.breakpoints.up('xl'));
  return (
    <Box className="ayo-lesson-planner-landing__about-section">
      <Box
        className="ayo-lesson-planner-landing__about-section__header"
        display="flex"
        justifyContent="center"
        px={3}
        py={isWidthUpSm ? 10.5 : 10}
      >
        <Typography align="center" component="h2" variant="h1">
          {t(
            'There’s still something truly exceptional about the lesson planner which can make your lessons effective and unique',
          )}
        </Typography>
      </Box>
      <Box className="ayo-lesson-planner-landing__about-section__main" py={isWidthUpSm ? 20 : 10}>
        <Container>
          <Grid container justifyContent="center">
            <Grid
              container
              direction="column"
              item
              md={6}
              sm={8}
              spacing={isWidthUpSm ? 15 : 5}
              xl={7}
              xs={12}
            >
              {LessonPlannerAboutSectionConfig.map((item, index) => (
                <Grid
                  key={item.headerEl}
                  alignItems="center"
                  container
                  direction="column"
                  item
                  justifyContent="center"
                  px={1}
                >
                  <Box mb={5} position="relative">
                    <Image
                      alt={t(item.imageAlt)}
                      className="ayo-lesson-planner-landing__about-section__main__image"
                      src={item.imageSrc}
                    />
                    <Image
                      className={`${classNames(
                        'ayo-lesson-planner-landing__about-section__main__decorations',
                        {
                          'ayo-lesson-planner-landing__about-section__main__decorations--even':
                            index % 2 !== 0,
                        },
                      )}`}
                      src={item.imageDecorationsSrc}
                    />
                  </Box>
                  <Typography align="center" component="h3" variant="h2">
                    {t(item.headerEl)}
                  </Typography>
                  <Box mt={1} px={isWidthUpXl ? 4 : isWidthUpMd ? 8 : 0}>
                    <Typography align="center" variant="body2">
                      {t(item.bodyEl)}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default LessonPlannerAboutSection;
