import { useCallback } from 'react';

import useAxios from '../HttpClient';
import { apiRoute, agreementRoute } from '../../constants/routes';

const url = `${apiRoute}${agreementRoute}`;

const useAgreementService = () => {
  const { post } = useAxios();
  const postAgreement = useCallback(
    (value, loaderText) => post({ enabled: true, loaderText }, url, { accepted: value }),
    [post],
  );

  return { postAgreement };
};

export default useAgreementService;
