import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import classNames from 'classnames';

import { Card, Typography } from '../../../../atoms';
import { InitiativeEmptyStateBlock } from '../../../../moleculas';
import { PortfolioSectionTypes } from '../../../../../constants/enums';
import { ReactComponent as EndorsementIllustration } from '../../../../../resources/images/portfolio/endorsement-image.svg';
import { ReactComponent as EndorsementsEmptyStateIllustration } from '../../../../../resources/images/endorsements_empty_state.svg';
import PortfolioSection from '../../components/portfolio-section/PortfolioSection';

const PortfolioSharedEndorsementsSection = ({ sectionData }) => {
  const { t } = useTranslation();

  return (
    <PortfolioSection
      body={
        <Box>
          {sectionData.entities.map(({ endorsementName, id }) => (
            <Card
              key={id}
              className="ayo-portfolio__endorsements-section__card"
              mainContent={
                <div className="ayo-portfolio__endorsements-section__card__content">
                  <EndorsementIllustration />
                  <Typography
                    className="ayo-portfolio__endorsements-section__card__content__title"
                    component="h3"
                    variant="subtitle2"
                  >
                    {endorsementName}
                  </Typography>
                </div>
              }
            />
          ))}
        </Box>
      }
      className={`${classNames('ayo-full-screen-portfolio-section', {
        'ayo-portfolio__endorsements-section': sectionData.entities.length,
      })}`}
      emptyState={
        !sectionData.entities.length ? (
          <InitiativeEmptyStateBlock
            illustration={<EndorsementsEmptyStateIllustration />}
            title={t('No information about endorsements so far')}
          />
        ) : null
      }
      reflection={sectionData.reflection}
      sectionKey={PortfolioSectionTypes.PORTFOLIO__ENDORSEMENTS}
      title={t('Endorsements')}
    />
  );
};

PortfolioSharedEndorsementsSection.propTypes = {
  sectionData: PropTypes.instanceOf(Object).isRequired,
};

export default PortfolioSharedEndorsementsSection;
