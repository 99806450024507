import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Container, Grid, Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { Switch, Typography } from '../../../../../atoms';
import { CampusLabel } from '../../../../../moleculas';
import {
  AccordionGradeView,
  CustomPeriodLabel,
  HighlightsBlock,
  NoStudentsScreen,
  PageWrapper,
} from '../../../../../organisms';
import SearchStudentsContainer from '../../../../../organisms/search-students-container/SearchStudentsContainer';
import { AppActions, AppContext, UserActions, UserContext } from '../../../../../../context';
import { useAnalyticsService, useOneTimeActionService } from '../../../../../../services';
import { trainAyoRoute } from '../../../../../../constants/routes';
import {
  useFiltersLastActionState,
  useSearchParams,
  useStudentsData,
} from '../../../../../../hooks';
import {
  getNormalizedLanguageCode,
  sanitizeFilters,
  transformSearchToObject,
} from '../../../../../../utils';
import { FilterActions, RolesMap } from '../../../../../../constants/enums';

const TrainAyoStudentSelectionPage = ({
  title,
  secondaryText,
  notificationText,
  progressAction,
  notificationAction,
  initiativeRoute,
  activityCriterion,
  highlightsValue,
}) => {
  const [searchValue, setSearchValue] = useState('');

  const { t, i18n } = useTranslation();
  const history = useHistory();

  const { state: userState, dispatch: dispatchUserState } = useContext(UserContext);

  const isTeacher = userState.profile.role === RolesMap.TEACHER;

  const { postOneTimeAction } = useOneTimeActionService();

  useEffect(() => {
    if (userState.oneTimeActions && !userState.oneTimeActions.includes(progressAction)) {
      postOneTimeAction(progressAction);
      dispatchUserState({
        type: UserActions.SET_ONE_TIME_ACTION,
        data: progressAction,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isActiveLANotificationVisible = useMemo(
    () => userState.oneTimeActions && !userState.oneTimeActions.includes(notificationAction),
    [userState.oneTimeActions, notificationAction],
  );

  const handleActiveLANotification = useCallback(() => {
    postOneTimeAction(notificationAction);
    dispatchUserState({
      type: UserActions.SET_ONE_TIME_ACTION,
      data: notificationAction,
    });
  }, [dispatchUserState, postOneTimeAction, notificationAction]);

  const getStudentAssesmentLink = (id) => `${trainAyoRoute}/${id}${initiativeRoute}`;

  const filterParams = useSearchParams();

  const filters = useMemo(() => transformSearchToObject(filterParams), [filterParams]);

  const [stats, setStats] = useState(null);

  const { getHighlights } = useAnalyticsService();

  useEffect(() => {
    if (!highlightsValue) return;
    getHighlights(
      highlightsValue,
      getNormalizedLanguageCode(i18n.language),
      userState.profile?.currentCampus,
    ).then((highlightsData) => setStats(highlightsData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highlightsValue, i18n.language, userState.profile?.currentCampus]);

  const [totalStudentsState, setTotalStudentsState] = useState(null);

  const {
    filteredStudents,
    filteredStudentsCount,
    shouldRenderStudentsList,
    suggestions,
    students,
    totalStudentsCount,
  } = useStudentsData({
    activityCriterion,
    searchValue,
    filterString: new URLSearchParams(
      filters.sanitize ? sanitizeFilters(filters, stats, totalStudentsState, history) : filters,
    ).toString(),
  });

  useEffect(() => {
    setTotalStudentsState(totalStudentsCount);
  }, [totalStudentsCount]);

  const { dispatch: dispatchAppState } = useContext(AppContext);

  const [filtersUpdateState, updateFiltersLastActionState] = useFiltersLastActionState();

  useEffect(() => {
    if (filteredStudents !== undefined) {
      if (totalStudentsCount === filteredStudentsCount) {
        history.replace({ search: null });
        dispatchAppState({
          type: AppActions.SET_SNACKBAR_STATUS,
          data: { text: t('Reset students filters snackbar'), type: 'error' },
        });
      } else {
        if (!filtersUpdateState) return;
        let snackbarText;
        if (filtersUpdateState.action === FilterActions.ADD) {
          if (filtersUpdateState.value) {
            snackbarText = t('Apply one filter snackbar', { value: filtersUpdateState.value });
          } else {
            snackbarText = t('Apply filters snackbar');
          }
        }
        if (filtersUpdateState.action === FilterActions.CLEAR) {
          if (filtersUpdateState.value) {
            snackbarText = t('Clear one filter snackbar', { value: filtersUpdateState.value });
          } else {
            snackbarText = t('Clear filters snackbar');
          }
        }
        dispatchAppState({
          type: AppActions.SET_SNACKBAR_STATUS,
          data: { text: snackbarText, type: 'success' },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchAppState, filteredStudents, history, students]);
  const handleSearch = useCallback(
    (newValue) => {
      setSearchValue(newValue);
      history.replace({ search: null });
    },
    [history],
  );

  const [showSections, setShowSections] = useState(true);

  return (
    <PageWrapper
      backToLink={trainAyoRoute}
      backToText={t('Go to Train AYO')}
      mainElementClassName={classnames('ayo-train-ayo-student-selection-page', {
        fullHeight: shouldRenderStudentsList(suggestions),
      })}
    >
      {shouldRenderStudentsList(suggestions) ? (
        <Container>
          <Box mb={5}>
            <Grid container>
              <Grid direction="column" item md={6} sm={8} xl={7} xs={12}>
                <Box>
                  <Typography component="h1" variant="h1">
                    {t(title)}
                  </Typography>
                </Box>
                <CampusLabel />
                <Box mb={3}>
                  <Typography variant="body2">{t(secondaryText)}</Typography>
                </Box>
              </Grid>
              <Grid xs={12}>
                <SearchStudentsContainer
                  notification={{
                    handler: handleActiveLANotification,
                    text: notificationText,
                    visible: isActiveLANotificationVisible,
                  }}
                  onSearch={handleSearch}
                  searchValue={searchValue}
                  studentCardRedirectLink={getStudentAssesmentLink}
                  students={students}
                  suggestions={suggestions}
                />
              </Grid>
              {highlightsValue && !searchValue && (
                <Grid item xs={12}>
                  <HighlightsBlock
                    infoMessage="Highlights graph informational message"
                    onFiltersUpdate={updateFiltersLastActionState}
                    selectedInitiative={highlightsValue}
                    stats={stats}
                    title={title}
                    totalStudentsCount={totalStudentsCount}
                  />
                </Grid>
              )}
              {isTeacher && (
                <Grid container gap={2} item justifyContent="flex-end" mt={4} xs={12}>
                  <Typography variant="subtitle2">
                    <b>{t('View sections')}</b>
                  </Typography>
                  <Switch
                    checkedLabel={t('On')}
                    onChange={(value) => setShowSections(value)}
                    uncheckedLabel={t('Off')}
                    value={showSections}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <AccordionGradeView
                  CustomGradeLabel={isTeacher ? CustomPeriodLabel : null}
                  showSubgroups={showSections}
                  studentCardRedirectLink={getStudentAssesmentLink}
                  students={filteredStudents || students}
                />
              </Grid>
            </Grid>
          </Box>
        </Container>
      ) : (
        <NoStudentsScreen />
      )}
    </PageWrapper>
  );
};

TrainAyoStudentSelectionPage.propTypes = {
  title: PropTypes.string,
  secondaryText: PropTypes.string,
  notificationText: PropTypes.string,
  progressAction: PropTypes.string,
  notificationAction: PropTypes.string,
  initiativeRoute: PropTypes.string,
  activityCriterion: PropTypes.string,
  highlightsValue: PropTypes.string,
};

TrainAyoStudentSelectionPage.defaultProps = {
  title: '',
  secondaryText: '',
  notificationText: '',
  progressAction: '',
  notificationAction: '',
  initiativeRoute: '',
  activityCriterion: '',
  highlightsValue: null,
};

export default TrainAyoStudentSelectionPage;
