import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

import { Button } from '../../../atoms';
import { InitiativeEmptyStateBlock } from '../../../moleculas';
import { eventType } from '../../../../constants/propTypes';
import { EventDialogModes } from '../../../../constants/events';
import { sortArrayByKey } from '../../../../utils';
import { UserContext } from '../../../../context';
import { ReactComponent as AddIcon } from '../../../../resources/icons/add.svg';
import { ReactComponent as ChevronRight } from '../../../../resources/icons/chevron_right.svg';
import EventCard from '../components/event-card/EventCard';
import EventsActions from '../components/events-actions/EventsActions';

const EventsSideBlock = ({
  allEventUrl,
  emptyStateConfig,
  events,
  fullLabel,
  isEditable,
  onDelete,
  onEventDialogAction,
}) => {
  const { t } = useTranslation();

  const { state: userState } = useContext(UserContext);

  return (
    <Box className="ayo-events-block__side-block">
      {events?.length ? (
        <Box display="flex" flexDirection="column" gap={3}>
          {isEditable && (
            <Button
              className="ayo-events-block__add-button"
              endIcon={<AddIcon />}
              gaLabel="Add an event"
              onClick={() => onEventDialogAction(EventDialogModes.CREATE)}
            >
              {t('Add an event')}
            </Button>
          )}
          <Grid container spacing={3}>
            {sortArrayByKey(events, 'startDate', 'date', 'increase').map((event) => (
              <Grid key={event.id} item xs={12}>
                <EventCard
                  actions={
                    event.ownerId === userState.profile.id ? (
                      <EventsActions
                        onDelete={() => onDelete(event)}
                        onEdit={() => onEventDialogAction(EventDialogModes.EDIT, event)}
                      />
                    ) : null
                  }
                  event={event}
                  fullLabel={fullLabel}
                  onClick={() => onEventDialogAction(EventDialogModes.VIEW, event)}
                />
              </Grid>
            ))}
          </Grid>
          <Button
            className="ayo-events-block__side-block__show-all"
            component={RouterLink}
            endIcon={<ChevronRight />}
            gaLabel="Show all upcoming events"
            to={allEventUrl}
          >
            {t('Show all upcoming events')}
          </Button>
        </Box>
      ) : (
        <InitiativeEmptyStateBlock
          customButton={
            isEditable && (
              <Button
                endIcon={<AddIcon />}
                gaLabel="Add an event"
                onClick={() => onEventDialogAction(EventDialogModes.CREATE)}
              >
                {t('Create an event')}
              </Button>
            )
          }
          illustration={emptyStateConfig.illustration}
          title={emptyStateConfig.title}
        />
      )}
    </Box>
  );
};

EventsSideBlock.propTypes = {
  allEventUrl: PropTypes.string.isRequired,
  emptyStateConfig: PropTypes.instanceOf(Object).isRequired,
  events: PropTypes.arrayOf(eventType).isRequired,
  fullLabel: PropTypes.bool,
  isEditable: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
  onEventDialogAction: PropTypes.func.isRequired,
};

EventsSideBlock.defaultProps = {
  fullLabel: false,
  isEditable: false,
};

export default EventsSideBlock;
