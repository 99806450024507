import React, { useCallback, useContext, useState } from 'react';
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Typography } from '../../../../../atoms';
import { MoodCard, MoodGetHelpCard } from '../../../../../moleculas';
import { UserContext } from '../../../../../../context';
import { useProfileService, useStudentsService } from '../../../../../../services';
import { SupportTypesMap, MoodNames, Moods } from '../../../../../../constants/moods';
import MoodSupportDialog from '../mood-support-dialog/MoodSupportDialog';
import MoodDialog from '../mood-dialog/MoodDialog';
import MoodQuestionsDialog from '../mood-questions-dialog/MoodQuestionsDialog';
import { AnalyticTypesMap } from '../../../../../../constants/enums';

const MoodsBlock = ({ isWorkingHours }) => {
  const { t } = useTranslation();
  const { state: userState } = useContext(UserContext);
  const studentId = userState.profile?.id;
  const { postStudentMood } = useStudentsService();
  const { sendAyoAnalytic } = useProfileService();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  const [isSupportDialogOpen, setIsSupportDialogOpen] = useState(false);
  const [isMoodDialogOpen, setIsMoodDialogOpen] = useState(false);
  const [isQuestionsDialogOpen, setQuestionsDialogOpen] = useState(false);
  const [moodDetails, setMoodDetails] = useState({});

  const handleCardClick = useCallback(
    (mood) => {
      if (mood === SupportTypesMap.GET_SUPPORT) {
        setIsSupportDialogOpen(true);
      } else if (mood) {
        postStudentMood(studentId, mood).then((data) => {
          setMoodDetails(data);
          sendAyoAnalytic(AnalyticTypesMap.MOOD);
          setIsMoodDialogOpen(true);
        });
      }
    },
    [studentId, postStudentMood, sendAyoAnalytic],
  );

  return (
    <Box className="ayo-train-ayo-moods">
      {isWorkingHours ? (
        <Box>
          <Box pb={3}>
            <Typography variant="h2">{t('How are you feeling today?')}</Typography>
          </Box>
          <Grid container spacing={isWidthUpSm ? 3 : 1}>
            {Object.values({ ...Moods, ...SupportTypesMap }).map((mood) => (
              <Grid key={mood} item md={2} xs={4}>
                <MoodCard
                  gaLabel={MoodNames[mood]}
                  isSupport={mood === SupportTypesMap.GET_SUPPORT}
                  mood={mood}
                  onClick={() => {
                    handleCardClick(mood);
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      ) : (
        <MoodGetHelpCard needToTalk onClick={() => setIsSupportDialogOpen(true)} />
      )}
      <MoodSupportDialog
        isOpen={isSupportDialogOpen}
        onClose={() => setIsSupportDialogOpen(false)}
      />
      <MoodDialog
        isOpen={isMoodDialogOpen}
        moodDetails={moodDetails}
        onClose={() => {
          setIsMoodDialogOpen(false);
        }}
        onQuestionsDialogOpen={() => setQuestionsDialogOpen(true)}
      />
      <MoodQuestionsDialog
        isOpen={isQuestionsDialogOpen}
        moodDetails={moodDetails}
        onClose={() => setQuestionsDialogOpen(false)}
      />
    </Box>
  );
};

MoodsBlock.propTypes = {
  isWorkingHours: PropTypes.bool.isRequired,
};

export default MoodsBlock;
