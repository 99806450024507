/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import MUITypography from '@mui/material/Typography';

const ComponentsMap = {
  display: 'h1',
  h1: 'h2',
  h2: 'h2',
  subtitle1: 'h3',
  subtitle2: 'h4',
};

const Typography = React.forwardRef(
  ({ children, className, isDisabled, isError, isLabel, isLightText, variant, ...rest }, ref) => (
    <MUITypography
      ref={ref}
      className={`${classNames(`ayo-typography--${variant}`, {
        [`${className}`]: className,
        'ayo-typography--label': isLabel,
        'ayo-typography--text-light': isLightText,
        'ayo-typography--disabled': isDisabled,
        'ayo-typography--error': isError,
      })}`}
      component={ComponentsMap[variant]}
      {...rest}
    >
      {children}
    </MUITypography>
  ),
);

Typography.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.node]).isRequired,
  className: PropTypes.string,
  isError: PropTypes.bool,
  isLabel: PropTypes.bool,
  isLightText: PropTypes.bool,
  isDisabled: PropTypes.bool,
  variant: PropTypes.oneOf([
    'display',
    'h1',
    'h2',
    'h3',
    'inherit',
    'subtitle1',
    'subtitle2',
    'label-highlighted',
    'body1',
    'body2',
    'body3',
    'caption',
    'quote',
  ]),
};

Typography.defaultProps = {
  className: '',
  isError: false,
  isLabel: false,
  isLightText: false,
  isDisabled: false,
  variant: 'body1',
};

export default Typography;
