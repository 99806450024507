import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, DialogActions, DialogContent } from '@mui/material';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { Dialog, DialogTitle, Switch, Typography } from '../../../../atoms';
import { PopupActionsButtons } from '../../../../moleculas';
import { SwitchLabels } from '../../../../../constants/enums';

const PortfolioUnpublishDialog = ({
  isOpen,
  onClose,
  onUnpublish,
  portfolioId,
  portfolioTitle,
  redirectUrl,
}) => {
  const { t } = useTranslation();

  const history = useHistory();

  const [isPortfolioVisible, setIsPortfolioVisible] = useState(true);

  const unublishHandler = useCallback(() => {
    onUnpublish(portfolioId, isPortfolioVisible, () => {
      onClose();
      if (redirectUrl) {
        history.push(redirectUrl);
      }
    });
  }, [history, isPortfolioVisible, onClose, onUnpublish, portfolioId, redirectUrl]);

  return (
    <Dialog gaLabel="Portfolio - unpublish dialog" onClose={onClose} open={isOpen}>
      <DialogTitle disableTypography>
        <Typography component="h2" variant="subtitle1">
          {`${t('Unpublish')} ${portfolioTitle}`}
        </Typography>
        <DialogContent>
          <Typography mb={3} mt={2} variant="body2">
            {t('You are about to unpublish your portfolio and move it to Drafts.')}
          </Typography>
          <Typography component="h3" variant="subtitle2">
            {t('Visibility')}
          </Typography>
          <Box display="flex" flexDirection="row" justifyContent="space-between" mb={5} mt={1}>
            <Typography variant="body2">
              {t('Make this draft visible to my educators and parents in AYO.')}
            </Typography>
            <Box>
              <Switch
                ariaLabel={t('Make this draft visible to my educators and parents in AYO.')}
                checkedLabel={SwitchLabels.on}
                gaLabel="Visibility"
                onChange={(value) => setIsPortfolioVisible(value)}
                uncheckedLabel={SwitchLabels.off}
                value={isPortfolioVisible}
              />
            </Box>
          </Box>
          <DialogActions>
            <PopupActionsButtons
              primaryButtonGaLabel="Unpublish"
              primaryButtonHandler={unublishHandler}
              primaryButtonText={t('Unpublish')}
              secondaryButtonGaLabel="Cancel"
              secondaryButtonHandler={() => onClose()}
              secondaryButtonText={t('Cancel')}
            />
          </DialogActions>
        </DialogContent>
      </DialogTitle>
    </Dialog>
  );
};

PortfolioUnpublishDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onUnpublish: PropTypes.func.isRequired,
  portfolioId: PropTypes.number.isRequired,
  portfolioTitle: PropTypes.string.isRequired,
  redirectUrl: PropTypes.string,
};

PortfolioUnpublishDialog.defaultProps = {
  redirectUrl: '',
};

export default PortfolioUnpublishDialog;
