import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Divider, useMediaQuery, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Card, Typography } from '../../../../atoms';
import { InformationalCaption, NewFeatureHotspot } from '../../../../moleculas';
import { UserContext } from '../../../../../context';
import { SchoolLevels, StudentFiltersSpecialValues } from '../../../../../constants/enums';
import { NewFeaturesIdsMap } from '../../../../../tours/common/NewFeaturesItemsProvider';
import AnalyticsStatValue from '../analytics-stat-value/AnalyticsStatValue';

const HeatChartRangeTypes = ['low', 'medium', 'high', 'very-high'];

const EndorsementsStatsCategories = {
  interested: 'interested',
  pending: 'endorsements',
  selected: 'endorsements',
  switchedByReason: 'endorsementsReason',
  switchedByType: 'endorsementsTypes',
};

const getRanges = (values) => {
  const maxValue = Math.max(...values);
  const minValue = Math.min(...values);
  const minHeat = maxValue ? minValue / maxValue : maxValue;
  const step = (1 - minHeat) / HeatChartRangeTypes.length;

  const ranges = HeatChartRangeTypes.map((rangeType, index) => ({
    range: [minHeat + step * index, minHeat + step * (index + 1)],
    rangeType,
  }));

  return values.map((value) => {
    const valueShare = maxValue ? value / maxValue : maxValue;
    return {
      value,
      rangeType: ranges.find(({ range }) =>
        valueShare === 1
          ? valueShare >= range[0] && valueShare <= range[1]
          : valueShare >= range[0] && valueShare < range[1],
      )?.rangeType,
    };
  });
};

const HeatMapChart = ({ endorsementDetails, statKey }) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const isWidthUpMd = useMediaQuery(theme.breakpoints.up('md'));

  const yLabels = useMemo(() => Object.keys(endorsementDetails), [endorsementDetails]);
  const xLabels = useMemo(
    () => [
      ...new Set(
        Object.values(endorsementDetails)
          .map(Object.keys)
          .reduce((a, b) => a.concat(b), []),
      ),
    ],
    [endorsementDetails],
  );

  return (
    <Box
      className="ayo-heat-map-chart"
      display="flex"
      flexDirection="column"
      mb={isWidthUpSm ? 9 : 0}
      ml={isWidthUpSm && xLabels.length > 1 ? 8 : isWidthUpSm ? 6 : 0}
      mt={isWidthUpSm ? 5 : 0}
      p={isWidthUpSm ? 3 : 2}
      width={isWidthUpSm ? '100%' : 'initial'}
    >
      <Box
        className="ayo-heat-map-chart__title"
        mb={isWidthUpSm ? 5 : 3.5}
        pr={isWidthUpSm ? 0 : 4}
      >
        <Typography display="inline" variant="subtitle2">
          {t('Switched endorsements within grades')}
        </Typography>
        <Box alignItems="center" display="inline-flex" ml={2}>
          <InformationalCaption
            showTooltip
            title="The graph represents the read-only information"
          />
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        height="100%"
        pl={isWidthUpMd ? 9 : 0}
        pr={isWidthUpMd && xLabels.length > 1 ? 9 : isWidthUpMd ? 10 : 0}
      >
        <Box className="ayo-heat-map-chart__y-legend" mr={isWidthUpSm ? 2 : 1}>
          {yLabels.map((yLabel) => (
            <Box key={yLabel} alignItems="center" display="flex">
              <Typography aria-hidden="true" isLabel variant="caption">
                {t(`analytics.${statKey}.endorsementDetails.${yLabel}`)}
              </Typography>
            </Box>
          ))}
        </Box>
        <Box
          className="ayo-heat-map-chart__chart-container"
          style={{
            gridTemplateColumns: `repeat(${xLabels.length}, 1fr)`,
          }}
        >
          {xLabels.map((xLabel, xLabelIndex) => {
            const heatMapValues = getRanges(
              yLabels.map((yLabel) => endorsementDetails[yLabel][xLabels[xLabelIndex]] || 0),
            );
            return (
              <Box
                key={xLabels[xLabelIndex]}
                alignItems="center"
                display="flex"
                flexDirection="column"
              >
                <Box className="sr-only">{`${xLabels[xLabelIndex]}${t('th')}${t('grade')}`}</Box>
                <Box
                  key={xLabel}
                  className="ayo-heat-map-chart__chart-container__column"
                  minWidth="100%"
                >
                  {heatMapValues.map(({ value, rangeType }, index) => (
                    <Box
                      key={`${value}-${rangeType}-${yLabels[index]}`}
                      className={classnames(
                        'ayo-heat-map-chart__chart-container__column__item',
                        `ayo-heat-map-chart__chart-container__column__item--${rangeType}`,
                      )}
                    >
                      <Box className="sr-only">
                        {t(`analytics.${statKey}.endorsementDetails.${yLabels[index]}`)}
                      </Box>
                      <Typography component="p" variant="subtitle2">
                        {value}
                      </Typography>
                    </Box>
                  ))}
                </Box>
                <Box mt={1}>
                  <Typography aria-hidden="true" isLabel variant="caption">
                    {`${xLabels[xLabelIndex]}${t('th')}`}
                  </Typography>
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

HeatMapChart.propTypes = {
  endorsementDetails: PropTypes.instanceOf(Object).isRequired,
  statKey: PropTypes.string.isRequired,
};

const HeatMapStatCard = ({ statKey, statsCategory, statValue }) => {
  const { t } = useTranslation();
  const heatMapChartRef = useRef(null);
  const { state: userState } = useContext(UserContext);
  const currentCampusSchoolLevel = userState.profile?.currentCampusSchoolLevel;

  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const isWidthUpMd = useMediaQuery(theme.breakpoints.up('md'));

  const { details, ...clickableEndorsementStats } = useMemo(() => statValue, [statValue]);

  const clickableStatsEntries = useMemo(
    () => Object.entries(clickableEndorsementStats),
    [clickableEndorsementStats],
  );

  const isSimpleCard = useMemo(
    () => clickableStatsEntries.length === 1,
    [clickableStatsEntries.length],
  );

  useEffect(() => {
    if (heatMapChartRef.current) {
      const heatMapParentElement = heatMapChartRef.current?.parentElement;
      if (heatMapParentElement) {
        heatMapParentElement.style.setProperty(
          'grid-column',
          `1 / span ${
            currentCampusSchoolLevel === SchoolLevels.HIGH && isWidthUpMd
              ? 3
              : isWidthUpSm && details
              ? 2
              : 1
          }`,
        );
      }
    }
  }, [isWidthUpMd, isWidthUpSm, currentCampusSchoolLevel, details]);

  return (
    <Card
      ref={heatMapChartRef}
      elevation={0}
      mainContent={
        <Box className="ayo-heat-map-stat-card">
          <Box display="flex" flexDirection="row">
            <Typography component="h4" paragraph variant="subtitle2">
              {t(`analytics.${statKey}.name`)}
            </Typography>
            <NewFeatureHotspot
              id={NewFeaturesIdsMap.ENDORSEMENTS_ANALYTICS_WIDGET}
              isClickable
              label={t('Endorsements')}
            />
          </Box>
          <Box display="flex" flexDirection={isWidthUpSm ? 'row' : 'column'} position="relative">
            <Box mt={!isSimpleCard ? 1 : 0}>
              <Box className="ayo-heat-map-stat-card__stats-container">
                {clickableStatsEntries
                  .filter(([statName]) => statName !== StudentFiltersSpecialValues.PENDING)
                  .map(([name, value], index) => (
                    <Box key={name} mb={!isSimpleCard ? 3 : 0} width={isWidthUpSm ? '100%' : '50%'}>
                      <AnalyticsStatValue
                        filterCategory={EndorsementsStatsCategories[Object.keys(statValue)[index]]}
                        filterValue={Object.keys(statValue)[index]}
                        statsCategory={statsCategory}
                        statsValue={value}
                        withFilters
                      />
                      <Box mt={1}>
                        <Typography isLabel variant="caption">
                          {t(`analytics.${statKey}.${name}`)}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
              </Box>
              {!isSimpleCard && (
                <Box>
                  <Divider className="ayo-heat-map-stat-card__divider" orientation="horizontal" />
                  <Box mb={isWidthUpSm ? 0 : 5} mt={3}>
                    <AnalyticsStatValue
                      filterCategory={EndorsementsStatsCategories.pending}
                      filterValue={StudentFiltersSpecialValues.PENDING}
                      statsCategory={statsCategory}
                      statsValue={clickableEndorsementStats.pending}
                      withFilters={!!clickableEndorsementStats.pending}
                    />
                    <Box mt={1}>
                      <Typography isLabel variant="caption">
                        {t(`analytics.${statKey}.pending`)}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
            {details && <HeatMapChart endorsementDetails={details} statKey={statKey} />}
          </Box>
        </Box>
      }
    />
  );
};

HeatMapStatCard.propTypes = {
  statKey: PropTypes.string.isRequired,
  statsCategory: PropTypes.string.isRequired,
  statValue: PropTypes.instanceOf(Object).isRequired,
};

export default HeatMapStatCard;
