import { useCallback } from 'react';

import useAxios, { StatusCodeMap } from '../HttpClient';
import {
  apiRoute,
  clubHubRoute,
  managementRoute,
  membersRoute,
  metadataRoute,
  requestRoute,
  studentsRoute,
} from '../../constants/routes';
import { getFormDataObject } from '../../utils/file-helpers/fileHelpers';

const useClubHubService = () => {
  const { get, put, post, patch, httpDelete } = useAxios();

  const getClubsList = useCallback(async () => {
    const { data } = await get({ enabled: true }, `${apiRoute}${clubHubRoute}${metadataRoute}`);
    return data.entity;
  }, [get]);

  const getStudentClubs = useCallback(
    async (studentId) => {
      const { data } = await get(
        { enabled: true },
        `${apiRoute}${clubHubRoute}${studentsRoute}/${studentId}`,
      );
      return data.entity;
    },
    [get],
  );

  const getClub = useCallback(
    async (clubId) => {
      const { data } = await get({ enabled: true }, `${apiRoute}${clubHubRoute}/${clubId}`);
      return data.entity;
    },
    [get],
  );

  const getClubsManagementList = useCallback(
    async ({ name, page, pageSize, status }) => {
      const { data } = await get(
        { enabled: true },
        `${apiRoute}${clubHubRoute}${managementRoute}?page=${page}&pageSize=${pageSize}&status=${status}${
          name ? `&name=${name}` : ''
        }`,
      );
      return data.entity;
    },
    [get],
  );

  const getClubsMembersList = useCallback(
    async ({ clubId, name, page, pageSize, status }) => {
      const { data } = await get(
        { enabled: true },
        `${apiRoute}${clubHubRoute}/${clubId}${membersRoute}?page=${page}&pageSize=${pageSize}&status=${status}${
          name ? `&name=${name}` : ''
        }`,
      );
      return data.entity;
    },
    [get],
  );

  const updateClubStatus = useCallback(
    async (id, reason, status) => {
      const { data } = await put(
        { enabled: true },
        `${apiRoute}${clubHubRoute}/${id}${managementRoute}`,
        { reason, status },
        {
          skipDefaultErrorHandlers: [
            StatusCodeMap.NOT_FOUND,
            StatusCodeMap.SERVER_ERROR,
            StatusCodeMap.BAD_REQUEST,
            StatusCodeMap.SERVICE_UNAVAILABLE,
          ],
        },
      );
      return data;
    },
    [put],
  );

  const postClub = useCallback(
    async (clubRequestBody, dotNotationKeys) => {
      const { data } = await post(
        { enabled: true },
        `${apiRoute}${clubHubRoute}`,
        getFormDataObject(clubRequestBody, dotNotationKeys),
        {
          'Content-Type': 'multipart/form-data',
          skipDefaultErrorHandlers: [StatusCodeMap.BAD_REQUEST, StatusCodeMap.NOT_FOUND],
        },
      );
      return data.entity;
    },
    [post],
  );

  const updateClub = useCallback(
    async (clubRequestBody, clubId, dotNotationKeys) => {
      const { data } = await patch(
        { enabled: true },
        `${apiRoute}${clubHubRoute}/${clubId}`,
        getFormDataObject(clubRequestBody, dotNotationKeys),
        {
          'Content-Type': 'multipart/form-data',
          skipDefaultErrorHandlers: [StatusCodeMap.BAD_REQUEST, StatusCodeMap.NOT_FOUND],
        },
      );
      return data.entity;
    },
    [patch],
  );

  const updateClubMembershipStatus = useCallback(
    async (clubId, studentId, membershipStatus, declinedReason) => {
      const { data } = await patch(
        { enabled: true },
        `${apiRoute}${clubHubRoute}/${clubId}${membersRoute}/${studentId}`,
        { membershipStatus, declinedReason },
        {
          skipDefaultErrorHandlers: [
            StatusCodeMap.NOT_FOUND,
            StatusCodeMap.SERVER_ERROR,
            StatusCodeMap.BAD_REQUEST,
            StatusCodeMap.SERVICE_UNAVAILABLE,
          ],
        },
      );
      return data;
    },
    [patch],
  );

  const postStudentJoinRequest = useCallback(
    async (id, membershipStatus, notes, links) => {
      const { data } = await post(
        { enabled: true },
        `${apiRoute}${clubHubRoute}/${id}${requestRoute}`,
        { membershipStatus, notes, links },
        {
          skipDefaultErrorHandlers: [
            StatusCodeMap.NOT_FOUND,
            StatusCodeMap.SERVER_ERROR,
            StatusCodeMap.BAD_REQUEST,
            StatusCodeMap.SERVICE_UNAVAILABLE,
          ],
        },
      );
      return data.entity;
    },
    [post],
  );

  const getClubsPosts = useCallback(
    async (clubId, page, pageSize, enableLoadMask, filterCategory) => {
      const { data } = await get(
        { enabled: enableLoadMask },
        `${apiRoute}${clubHubRoute}/${clubId}/posts?page=${page}&pageSize=${
          pageSize || 20
        }&sharingGroup=${filterCategory}`,
      );

      return data.entity;
    },
    [get],
  );

  const postClubsPost = useCallback(
    async (body, clubId) => {
      const { data } = await post(
        { enabled: true },
        `${apiRoute}${clubHubRoute}/${clubId}/posts`,
        body,
        {
          'Content-Type': 'multipart/form-data',
          skipDefaultErrorHandlers: [
            StatusCodeMap.BAD_REQUEST,
            StatusCodeMap.NOT_FOUND,
            StatusCodeMap.SERVER_ERROR,
          ],
        },
      );

      return data.entity;
    },
    [post],
  );

  const postCommentToPost = useCallback(
    async (body, clubId, postId) => {
      const { data } = await post(
        { enabled: true },
        `${apiRoute}${clubHubRoute}/${clubId}/posts/${postId}/comments`,
        body,
        {
          skipDefaultErrorHandlers: [
            StatusCodeMap.BAD_REQUEST,
            StatusCodeMap.NOT_FOUND,
            StatusCodeMap.SERVER_ERROR,
          ],
        },
      );

      return data.entity;
    },
    [post],
  );

  const postClubSettings = useCallback(
    async (body, clubId) => {
      const { data } = await post(
        { enabled: true },
        `${apiRoute}${clubHubRoute}/${clubId}/settings`,
        body,
        {
          skipDefaultErrorHandlers: [
            StatusCodeMap.BAD_REQUEST,
            StatusCodeMap.NOT_FOUND,
            StatusCodeMap.SERVER_ERROR,
          ],
        },
      );

      return data.entity;
    },
    [post],
  );

  const updateClubPost = useCallback(
    async (body, postId, clubId) => {
      const { data } = await put(
        { enabled: true },
        `${apiRoute}${clubHubRoute}/${clubId}/posts/${postId}`,
        body,
        {
          'Content-Type': 'multipart/form-data',
          skipDefaultErrorHandlers: [StatusCodeMap.BAD_REQUEST, StatusCodeMap.NOT_FOUND],
        },
      );
      return data.entity;
    },
    [put],
  );

  const updatePostReply = useCallback(
    async (body, clubId, postId, commentId) => {
      const { data } = await put(
        { enabled: true },
        `${apiRoute}${clubHubRoute}/${clubId}/posts/${postId}/comments/${commentId}`,
        body,
        {
          'Content-Type': 'multipart/form-data',
          skipDefaultErrorHandlers: [StatusCodeMap.BAD_REQUEST, StatusCodeMap.NOT_FOUND],
        },
      );
      return data.entity;
    },
    [put],
  );

  const deleteClubsPost = useCallback(
    (postId, clubId) =>
      httpDelete(
        { enabled: true },
        `${apiRoute}${clubHubRoute}/${clubId}/posts/${postId}`,
        undefined,
        {
          skipDefaultErrorHandlers: [
            StatusCodeMap.BAD_REQUEST,
            StatusCodeMap.SERVER_ERROR,
            StatusCodeMap.NOT_FOUND,
          ],
        },
      ),
    [httpDelete],
  );

  const deletePostReply = useCallback(
    (postId, clubId, commentId) =>
      httpDelete(
        { enabled: true },
        `${apiRoute}${clubHubRoute}/${clubId}/posts/${postId}/comments/${commentId}`,
        undefined,
        {
          skipDefaultErrorHandlers: [
            StatusCodeMap.BAD_REQUEST,
            StatusCodeMap.SERVER_ERROR,
            StatusCodeMap.NOT_FOUND,
          ],
        },
      ),
    [httpDelete],
  );

  const getClubTags = useCallback(
    async (description, enableLoadMask = true) => {
      const { data } = await post({ enabled: enableLoadMask }, `${apiRoute}/tags`, {
        description,
      });

      return data.entity;
    },
    [post],
  );

  return {
    getClub,
    getClubsList,
    getClubsManagementList,
    getClubsMembersList,
    getClubsPosts,
    getStudentClubs,
    deleteClubsPost,
    postClub,
    postClubsPost,
    postClubSettings,
    postCommentToPost,
    postStudentJoinRequest,
    updateClub,
    updateClubStatus,
    updateClubMembershipStatus,
    updateClubPost,
    updatePostReply,
    deletePostReply,
    getClubTags,
  };
};

export default useClubHubService;
