import React, { useCallback, useMemo } from 'react';
import { Box, Grid } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Link, Tooltip, Typography } from '../../../../../atoms';
import { DataGrid, TextWithTooltip } from '../../../../../moleculas';
import { ClubStatus } from '../../../../../organisms';
import { getRelativeDateTime } from '../../../../../../utils';
import { ClubsManagementQueryParams } from '../../../../../../constants/club-hub';
import { clubHubRoute } from '../../../../../../constants/routes';
import {
  NotificationResourcesMap,
  NotificationSourcesMap,
} from '../../../../../../constants/enums';
import { useNotificationsData } from '../../../../../../hooks';
import { ReactComponent as CreateIcon } from '../../../../../../resources/icons/create.svg';

import ClubsDataGridActions from './components/clubs-data-grid-actions/ClubsDataGridActions';

const ClubsDataGrid = ({
  clubsList,
  onPageChange,
  onStatusChange,
  pageSize,
  searchValue,
  selectedPage,
  totalRowCount,
}) => {
  const { t, i18n } = useTranslation();

  const { getUnreadNotificationsByResourceAndId } = useNotificationsData();

  const dataGridColumnDefinitions = useMemo(
    () => [
      {
        field: 'clubName',
        headerName: t('Club name'),
        flex: 0.22,
        searchable: true,
        renderCell: ({ id, value }) => (
          <Box display="flex" flexDirection="row">
            <Link gaLabel={`Club: ${value}`} href={`${clubHubRoute}/${id}`} underline="none">
              <TextWithTooltip className="ayo-link" title={value} />
            </Link>
            {!!getUnreadNotificationsByResourceAndId(
              NotificationSourcesMap.WEB_HIDDEN,
              NotificationResourcesMap.CLUB_HUB_NEW_INDICATOR,
              id,
            )?.length && (
              <Box className="ayo-club-hub__management__clubs-data-grid__updates-icon" ml={1}>
                <Tooltip
                  title={t(
                    'There were some changes of the content in the primary info of the club.',
                  )}
                >
                  <CreateIcon
                    ariaLabel={t(
                      'There were some changes of the content in the primary info of the club.',
                    )}
                    tabIndex="0"
                  />
                </Tooltip>
              </Box>
            )}
          </Box>
        ),
      },
      {
        field: 'creator',
        headerName: t('Creator'),
        flex: 0.16,
        renderCell: ({ value }) => <TextWithTooltip title={value} titleVariant="body2" />,
      },
      {
        field: 'createdDate',
        headerName: t('Date created'),
        flex: 0.17,
        renderCell: ({ value }) => (
          <TextWithTooltip
            title={getRelativeDateTime(value, i18n.language, t)}
            titleVariant="body2"
          />
        ),
      },
      {
        field: 'status',
        headerName: t('Status'),
        flex: 0.18,
        renderCell: ({ row, value }) => <ClubStatus reason={row.reason} status={value} />,
      },
      {
        headerName: t('Actions'),
        headerAlign: 'right',
        flex: 0.27,
        renderCell: ({ id, row }) => (
          <ClubsDataGridActions
            clubName={row.clubName}
            id={id}
            onStatusChange={onStatusChange}
            status={row.status}
          />
        ),
      },
    ],
    [getUnreadNotificationsByResourceAndId, i18n.language, onStatusChange, t],
  );

  const getRowSpacing = useCallback(
    (params) => ({
      top: params.isFirstVisible ? 8 : 4,
      bottom: params.isLastVisible ? 0 : 4,
    }),
    [],
  );

  const onPageChangeHandler = useCallback(
    (pageNumber) => {
      onPageChange({ [ClubsManagementQueryParams.PAGE]: pageNumber });
    },
    [onPageChange],
  );

  return (
    <Box className="ayo-club-hub__management__clubs-data-grid" mt={5}>
      {searchValue && !!totalRowCount && (
        <Grid item sm={12}>
          <Typography paragraph variant="body2">
            <Trans
              components={{ b: <b /> }}
              i18nKey="search result text"
              values={{ count: totalRowCount, searchValue }}
            />
          </Typography>
        </Grid>
      )}
      <DataGrid
        columns={dataGridColumnDefinitions}
        disableColumnResize
        getRowSpacing={getRowSpacing}
        hideFooterPagination={totalRowCount <= pageSize}
        onPageChange={onPageChangeHandler}
        rowCount={totalRowCount}
        rows={clubsList}
        searchValue={searchValue}
        selectedPage={selectedPage}
      />
    </Box>
  );
};

ClubsDataGrid.propTypes = {
  clubsList: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  onPageChange: PropTypes.func.isRequired,
  onStatusChange: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
  searchValue: PropTypes.string,
  selectedPage: PropTypes.number.isRequired,
  totalRowCount: PropTypes.number.isRequired,
};

ClubsDataGrid.defaultProps = {
  clubsList: [],
  searchValue: '',
};

export default ClubsDataGrid;
