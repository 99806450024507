import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Box, Divider, Grid } from '@mui/material';

import { Checkbox, FormControlLabel, Radio, Typography } from '../../../../atoms';
import { StudentFiltersSpecialValues, StudentFiltersTypes } from '../../../../../constants/enums';
import {
  updateSimpleFilter,
  updateAllOtherFilter,
  updatePendingFilter,
  getFilterValueParts,
  StudentFiltersStrategy,
  isFilterItemDisabled,
  isSimpleFilterSelected,
  updateRadioFilter,
} from '../../../../../utils';
import DisabledControlWithTooltip from '../../../tooltip-wrappers/disabled-control-with-tooltip/DisabledControlWithTooltip';
import { filtersListSeparator } from '../../../../../constants/values';

const TopFiltersList = ({
  defaultStats,
  stats,
  initiative,
  setMenuFilters,
  menuFilters,
  totalStudentsCount,
  title,
  displayAllOther,
  pendingKey,
  checkAllName,
  columnsNumber,
  isRadioSelection,
  mainEntriesSorting,
}) => {
  const initiativeStats = stats[initiative.toLowerCase()];

  const topFiltersStats = useMemo(() => {
    const topFiltersStatsValues = initiativeStats ? { ...defaultStats, ...initiativeStats } : {};
    delete topFiltersStatsValues[StudentFiltersSpecialValues.ALL_OTHER];
    delete topFiltersStatsValues[pendingKey];
    return topFiltersStatsValues;
  }, [initiativeStats, pendingKey, defaultStats]);

  const onFilterChange = useCallback(
    (e) => {
      const interestOptionsList = Object.keys(topFiltersStats);
      const newFilters = isRadioSelection
        ? updateRadioFilter(e.target.value, initiative, menuFilters, stats)
        : updateSimpleFilter(e.target.value, initiative, menuFilters, interestOptionsList, stats);
      setMenuFilters(newFilters);
    },
    [topFiltersStats, initiative, menuFilters, stats, setMenuFilters, isRadioSelection],
  );

  const onAllOtherChange = useCallback(() => {
    const interestOptionsList = Object.keys(topFiltersStats);
    const newFilters = updateAllOtherFilter(menuFilters, initiative, interestOptionsList, stats);
    setMenuFilters(newFilters);
  }, [topFiltersStats, menuFilters, initiative, stats, setMenuFilters]);

  const onPendingChange = useCallback(() => {
    const newFilters = updatePendingFilter(menuFilters, initiative, stats);
    setMenuFilters(newFilters);
  }, [stats, initiative, menuFilters, setMenuFilters]);

  const { filterCriterion, filtersList } = getFilterValueParts(menuFilters, stats);

  const { t } = useTranslation();

  const FiltersStrategy = StudentFiltersStrategy[initiative];

  const allOtherValue = FiltersStrategy.getStatValue(stats, StudentFiltersSpecialValues.ALL_OTHER);
  const pendingValue = FiltersStrategy.getStatValue(stats, pendingKey);

  const onAllAttributesChange = useCallback(
    (e) => {
      const filterAttributes = e.target.checked
        ? Object.keys(topFiltersStats).filter(
            (filter) => !isFilterItemDisabled(topFiltersStats[filter], totalStudentsCount),
          )
        : [];
      setMenuFilters({
        filterCategory: initiative,
        filterValue: `${filterCriterion || StudentFiltersTypes.INCLUDE}:${filterAttributes.join(
          filtersListSeparator,
        )}`,
      });
    },
    [topFiltersStats, setMenuFilters, initiative, filterCriterion, totalStudentsCount],
  );

  const isAllChecked = Object.entries(topFiltersStats)
    .filter(([, filterStat]) => !isFilterItemDisabled(filterStat, totalStudentsCount))
    .every(([filterName]) => menuFilters.filterValue?.includes(filterName));

  const MainControlComponent = isRadioSelection ? Radio : Checkbox;

  const mainEntries = Object.entries(topFiltersStats);

  if (mainEntriesSorting) {
    mainEntries.sort(mainEntriesSorting);
  }

  return (
    <Box>
      <Box className="filters-controls-box" display="flex" flexDirection="column">
        {title && <Typography variant="subtitle2">{t(title)}</Typography>}
        <Grid className="filters-controls-grid" container>
          {checkAllName && (
            <Grid xs={12 / columnsNumber}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isAllChecked}
                    gaLabel={`Student Filters - ${initiative} - All Attributes`}
                    onChange={onAllAttributesChange}
                    value="ALL"
                  />
                }
                label={t(checkAllName)}
              />
            </Grid>
          )}
          {mainEntries.map(([filterKey, filterValue]) => {
            const isValueObject = typeof filterValue === 'object';
            const filterName = isValueObject ? filterValue.name : filterKey;
            const statValue = isValueObject ? filterValue.count : filterValue;
            const isDisabled = isFilterItemDisabled(statValue, totalStudentsCount);
            const filterDisplayName = Object.values(StudentFiltersSpecialValues).includes(
              filterName,
            )
              ? t(`analytics.highlights.${filterName}`)
              : filterName;
            return (
              <Grid key={filterKey} item xs={12 / columnsNumber}>
                <DisabledControlWithTooltip
                  showTooltip={isDisabled}
                  title={
                    <>
                      {FiltersStrategy.getFilterDisplayName(
                        filterCriterion,
                        filterDisplayName,
                        statValue,
                        stats,
                      )}
                      <br />
                      {statValue === totalStudentsCount && t("Can't filter by all students")}
                      {statValue === 0 && t("Can't filter by 0 students")}
                    </>
                  }
                >
                  <FormControlLabel
                    control={
                      <MainControlComponent
                        checked={isSimpleFilterSelected(
                          filterKey,
                          initiative,
                          menuFilters.filterCategory,
                          filtersList,
                        )}
                        disabled={isDisabled}
                        gaLabel={`Student Filters - ${initiative} - ${filterKey}`}
                        onChange={onFilterChange}
                        value={filterKey}
                      />
                    }
                    label={FiltersStrategy.getFilterDisplayName(
                      filterCriterion,
                      filterDisplayName,
                      statValue,
                      stats,
                    )}
                  />
                </DisabledControlWithTooltip>
              </Grid>
            );
          })}
          {initiativeStats && displayAllOther && (
            <Grid item xs={12 / columnsNumber}>
              <DisabledControlWithTooltip
                showTooltip={isFilterItemDisabled(allOtherValue, totalStudentsCount)}
                title={
                  <>
                    {FiltersStrategy.getFilterDisplayName(
                      filterCriterion,
                      t(`analytics.highlights.${StudentFiltersSpecialValues.ALL_OTHER}`),
                      allOtherValue || 0,
                      stats,
                    )}
                    <br />
                    {allOtherValue === totalStudentsCount && t("Can't filter by all students")}
                    {allOtherValue && t("Can't filter by 0 students")}
                  </>
                }
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isSimpleFilterSelected(
                        StudentFiltersSpecialValues.ALL_OTHER,
                        initiative,
                        menuFilters.filterCategory,
                        filtersList,
                      )}
                      disabled={isFilterItemDisabled(allOtherValue, totalStudentsCount)}
                      gaLabel={`Student Filters - ${initiative} - ${StudentFiltersSpecialValues.ALL_OTHER}`}
                      onChange={onAllOtherChange}
                      value={StudentFiltersSpecialValues.ALL_OTHER}
                    />
                  }
                  label={`${t(`analytics.highlights.${StudentFiltersSpecialValues.ALL_OTHER}`)} (${
                    allOtherValue || 0
                  })`}
                />
              </DisabledControlWithTooltip>
            </Grid>
          )}
          {initiativeStats && (
            <>
              <Divider />
              <Grid item xs={12}>
                <DisabledControlWithTooltip
                  showTooltip={isFilterItemDisabled(pendingValue, totalStudentsCount)}
                  title={
                    <>
                      {FiltersStrategy.getFilterDisplayName(
                        filterCriterion,
                        t(`analytics.highlights.${pendingKey}`),
                        pendingValue,
                        stats,
                      )}
                      <br />
                      {pendingValue === totalStudentsCount && t("Can't filter by all students")}
                      {pendingValue === 0 && t("Can't filter by 0 students")}
                    </>
                  }
                >
                  <FormControlLabel
                    control={
                      <Radio
                        checked={isSimpleFilterSelected(
                          StudentFiltersSpecialValues.PENDING,
                          initiative,
                          menuFilters.filterCategory,
                          filtersList,
                        )}
                        disabled={isFilterItemDisabled(pendingValue, totalStudentsCount)}
                        gaLabel={`Student Filters - ${initiative} - ${pendingKey}`}
                        onChange={onPendingChange}
                        value={pendingKey}
                      />
                    }
                    label={`${t(`analytics.highlights.${pendingKey}`)} (${pendingValue})`}
                  />
                </DisabledControlWithTooltip>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

TopFiltersList.propTypes = {
  defaultStats: PropTypes.instanceOf(Object),
  stats: PropTypes.instanceOf(Object).isRequired,
  initiative: PropTypes.string.isRequired,
  setMenuFilters: PropTypes.func.isRequired,
  menuFilters: PropTypes.instanceOf(Object).isRequired,
  totalStudentsCount: PropTypes.number.isRequired,
  title: PropTypes.string,
  displayAllOther: PropTypes.bool,
  pendingKey: PropTypes.string,
  checkAllName: PropTypes.string,
  columnsNumber: PropTypes.number,
  isRadioSelection: PropTypes.bool,
  mainEntriesSorting: PropTypes.func,
};

TopFiltersList.defaultProps = {
  defaultStats: {},
  title: null,
  displayAllOther: false,
  pendingKey: StudentFiltersSpecialValues.PENDING,
  checkAllName: null,
  columnsNumber: 1,
  isRadioSelection: false,
  mainEntriesSorting: null,
};

export default TopFiltersList;
