import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import PropTypes from 'prop-types';

import { Typography } from '../../../../../atoms';
import ClubDetailsDescription from '../../components/club-hub-details-description/ClubDetailsDescription';
import { ClubKeys } from '../../../../../../constants/club-hub';
import ClubDetailsRequirements from '../../components/club-details-requirements/ClubDetailsRequirements';
import ClubDetailsLinks from '../../components/club-details-links/ClubDetailsLinks';

const ClubAboutSection = ({ clubData, clubId, getIsFieldEditable, updateClubHandler }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Box>
      <Typography mb={3} mt={5} variant="h2">
        {t('About club')}
      </Typography>
      <Grid container item spacing={isWidthUpSm ? 3 : 2}>
        <Grid item sm={7} xs={12}>
          <ClubDetailsDescription
            currentDescription={clubData.description}
            currentTags={clubData.tags}
            id={clubId}
            isEditable={getIsFieldEditable(ClubKeys.DESCRIPTION)}
            onSave={updateClubHandler}
          />
          {isWidthUpSm && (
            <ClubDetailsRequirements
              isEditable={getIsFieldEditable(ClubKeys.REQUIREMENTS)}
              onSave={updateClubHandler}
              requirements={clubData.requirements}
            />
          )}
        </Grid>
        <Grid item sm={5} xs={12}>
          <ClubDetailsLinks
            currentLinks={clubData.links}
            isEditable={getIsFieldEditable(ClubKeys.LINKS)}
            onSave={updateClubHandler}
          />
        </Grid>
      </Grid>
      {!isWidthUpSm && (
        <ClubDetailsRequirements
          isEditable={getIsFieldEditable(ClubKeys.REQUIREMENTS)}
          onSave={updateClubHandler}
          requirements={clubData.requirements}
        />
      )}
    </Box>
  );
};

ClubAboutSection.propTypes = {
  clubData: PropTypes.instanceOf(Object).isRequired,
  clubId: PropTypes.number.isRequired,
  getIsFieldEditable: PropTypes.func.isRequired,
  updateClubHandler: PropTypes.func.isRequired,
};

export default ClubAboutSection;
