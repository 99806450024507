import { Moods } from './moods';
import {
  ClubHubModerationFilterCategory,
  ContentModerationFilterCategory,
  FamilyFeedFilterCategory,
} from './filter-categories';

export const AchievementsCategories = {
  ACADEMIC_ACHIEVEMENTS: 'Academic Achievements',
  MUSIC_ACHIEVEMENTS: 'Music Achievements',
  NATIONAL_SOCIETIES_MEMBERSHIP: 'National Societies Membership',
  PARTICIPATION_IN_COMPETITIONS: 'Participation in competitions',
  SPORT_ACHIEVEMENTS: 'Sport Achievements',
};

export const ActionButtonsIdsMap = {
  EDIT: 'EDIT',
  DELETE: 'DELETE',
};

export const AlertsStates = {
  ACKNOWLEDGED: 'ACKNOWLEDGED',
  CLOSED: 'CLOSED',
  EMAIL_SEND: 'EMAIL_SEND',
  REQUESTED: 'REQUESTED',
};

export const AlertsActions = {
  INTERVENTION_REQUEST: 'INTERVENTION_REQUEST',
};

export const AnalyticsStatsKeys = {
  ACHIEVEMENTS: 'achievements',
  APTITUDES: 'aptitudes',
  ENDORSEMENTS: 'endorsements',
  ENDORSEMENTS_INTERESTED: 'interested',
  ENDORSEMENTS_TYPES: 'endorsementsTypes',
  ENDORSEMENTS_REASONS: 'endorsementsReason',
  EXTRACURRICULARS: 'extracurriculars',
  GOALS: 'goals',
  INTERESTS: 'interests',
  LEADERSHIP_ATTRIBUTES: 'leadershipAttributes',
  LOGGED_IN: 'loggedIn',
  MOODS: 'moods',
  SEL_RECOMMENDATIONS: 'strategies',
  OPPORTUNITIES: 'opportunities',
  PARENTS_ANSWERS: 'parentsAnswers',
  PORTFOLIO: 'portfolio',
};

export const AnalyticsStatsCategories = {
  INITIATIVES: 'initiatives',
  STUDENTS: 'students',
  TEACHERS: 'teachers',
  PARENTS: 'parents',
};

export const CertificateKeys = {
  ATTACHMENTS: 'attachments',
  DESCRIPTION: 'description',
  EXPIRATION_DATE: 'expirationDate',
  LINK: 'link',
  OBTAINING_DATE: 'obtainingDate',
  ORGANIZATION: 'organization',
  TITLE: 'title',
};

export const CommentsPagesMap = {
  MOOD_DETAILS: 'mood-details',
};

export const ContentTypesMap = {
  DRAWING: 'DRAWING',
  FORMS: 'FORMS',
  GENERAL: 'GENERAL',
  IMAGE: 'IMAGE',
  MP3: 'MP3',
  MP4: 'MP4',
  PDF: 'PDF',
  SHEETS: 'SHEETS',
  SLIDES: 'SLIDES',
  YOUTUBE: 'YOUTUBE',
};

export const DeviceTypesMap = {
  PUBLIC: 'PUBLIC',
  PRIVATE: 'PRIVATE',
};

export const KeyboardMap = {
  ENTER: 'Enter',
  SPACE: ' ',
  ARROW_DOWN: 'ArrowDown',
  ARROW_UP: 'ArrowUp',
  ARROW_LEFT: 'ArrowLeft',
  ARROW_RIGHT: 'ArrowRight',
  ESCAPE: 'Escape',
  TAB: 'Tab',
};

export const InterestsTypeMap = {
  SELECTED_INTERESTS: 'selectedInterests',
  SUGGESTED_INTERESTS: 'suggestedInterests',
};

export const NotificationTypesMap = {
  UI_NOTIFICATIONS: 'UI_NOTIFICATIONS',
  EMAIL_NOTIFICATION: 'EMAIL_NOTIFICATION',
  PORTFOLIO: 'PORTFOLIO',
};

export const NotificationResourcesMap = {
  ACHIEVEMENT: 'ACHIEVEMENT',
  AVATAR: 'AVATAR',
  CLUB_HUB_NEW_INDICATOR: 'CLUB_HUB_NEW_INDICATOR',
  CLUB_HUB_MANAGE: 'CLUB_HUB_MANAGE',
  CLUB_HUB_MANAGE_MEMBER: 'CLUB_HUB_MANAGE_MEMBER',
  CLUB_POST: 'CLUB_POST',
  CLUB_POST_COMMENT: 'CLUB_POST_COMMENT',
  COMMENT_REPLY_TO_LESSON_OWNER: 'COMMENT_REPLY_TO_LESSON_OWNER',
  COMMENT_TO_LESSON_OWNER: 'COMMENT_TO_LESSON_OWNER',
  EXTRACURRICULAR: 'EXTRACURRICULAR',
  FAMILY_FEED_EVENT: 'FAMILY_FEED_EVENT',
  GOAL: 'GOAL',
  INTEREST: 'INTEREST',
  LAG: 'LAG',
  LA_COMMENT: 'LA_COMMENT',
  LESSON_COMMENT_NEW_INDICATOR: 'LESSON_COMMENT_NEW_INDICATOR',
  LESSON_SHARE: 'LESSON_SHARE',
  LESSON_SHARE_UPDATE: 'LESSON_SHARE_UPDATE',
  MATURITY_GRAPH: 'MATURITY_GRAPH',
  MILESTONE: 'MILESTONE',
  MOOD_INTERVENTION: 'MOOD_INTERVENTION',
  OPPORTUNITY: 'OPPORTUNITY',
  QUESTIONNAIRE: 'QUESTIONNAIRE',
  RECOMMENDATION_REQUEST: 'RECOMMENDATION_REQUEST',
  RECOMMENDATION_REQUEST_NEW_INDICATOR: 'RECOMMENDATION_REQUEST_NEW_INDICATOR',
  CHOICE_BOARDS: 'CHOICE_BOARDS',
  CHOICE_BOARDS_HIDDEN: 'CHOICE_BOARDS_HIDDEN',
  REPLY_TO_ALL_THREAD: 'REPLY_TO_ALL_THREAD',
  REPLY_TO_COMMENT_OWNER: 'REPLY_TO_COMMENT_OWNER',
  FEED_POST_NEW_INDICATOR: 'FEED_POST_NEW_INDICATOR',
  CONVERSATION: 'CONVERSATION',
};

export const NotificationSourcesMap = {
  WEB_GENERAL: 'WEB_GENERAL',
  WEB_HIDDEN: 'WEB_HIDDEN',
};

export const NotificationInitiatorsMap = {
  AYO: 'AYO',
  SYSTEM: 'SYSTEM',
  USER: 'USER',
};

export const NotificationNamesMap = {
  settings: {
    UI_NOTIFICATIONS__MY_PROFILE: 'My profile',
    UI_NOTIFICATIONS__MY_STUDENTS: 'My students',
    UI_NOTIFICATIONS__MY_CLASSES: 'My classes',
    UI_NOTIFICATIONS__MY_LESSONS: 'My lessons',
    UI_NOTIFICATIONS__MY_KIDS: 'Family feed section',
    UI_NOTIFICATIONS__STUDENTS: 'Students',
    UI_NOTIFICATIONS__TRAIN_AYO: 'Train AYO',
    UI_NOTIFICATIONS__CLUB_HUB: 'Club Hub',
    EMAIL_NOTIFICATION: 'Receive emails',
    PORTFOLIO__INTERESTS: 'Interests',
    PORTFOLIO__APTITUDES: 'Aptitudes',
    PORTFOLIO__EMPLOYMENT: 'Employment',
    PORTFOLIO__ENDORSEMENTS: 'Endorsements',
    PORTFOLIO__EXTRACURRICULARS: 'Extracurriculars',
    PORTFOLIO__ACHIEVEMENTS: 'Achievements & participation',
    PORTFOLIO__LEADERSHIP_ATTRIBUTES: 'Leadership Attributes',
    PORTFOLIO__PERSONAL_INFO: 'Personal information',
    PORTFOLIO__CERTIFICATES: 'Certificates',
    PORTFOLIO__ACADEMIC_RECORD: 'Academic record',
    PORTFOLIO__ESSAYS: 'Essays',
    PORTFOLIO__RECOMMENDATIONS: 'Recommendations',
    PORTFOLIO__TEST_SCORES: 'Test scores',
    PORTFOLIO__VOLUNTEERING: 'Volunteering',
  },
  statuses: {
    EMAIL_NOTIFICATION: 'Email notifications',
    UI_NOTIFICATIONS__MY_KIDS: 'Family feed snackbar',
    UI_NOTIFICATIONS__MY_LESSONS: 'My lessons',
    UI_NOTIFICATIONS__MY_PROFILE: 'My profile',
    UI_NOTIFICATIONS__MY_STUDENTS: 'My students',
    UI_NOTIFICATIONS__MY_CLASSES: 'My classes',
    UI_NOTIFICATIONS__STUDENTS: 'Students',
    UI_NOTIFICATIONS__TRAIN_AYO: 'Train AYO',
    UI_NOTIFICATIONS__CLUB_HUB: 'Club Hub',
  },
};

export const SwitchLabels = {
  on: 'On',
  off: 'Off',
};

export const FeedbackFunctionalityKeysMap = {
  GREEN_RIBBONS: 'GREEN_RIBBONS',
  INTERESTS_UNIVERSE: 'INTERESTS_UNIVERSE',
  MY_GOALS: 'MY_GOALS',
};

export const AnalyticTypesMap = {
  MOOD: 'MOOD',
  MOOD_COMMENT: 'MOOD_COMMENT',
  SEL_RECOMMENDATION: 'SEL_RECOMMENDATION',
  FAMILY_ACTIVITY_VIEW_CLICK: 'FAMILY_ACTIVITY_VIEW_CLICK',
  FAMILY_ACTIVITY_CATEGORY_CLICK: 'FAMILY_ACTIVITY_CATEGORY_CLICK',
};

export const FeedbackPrefixTypes = {
  QUESTION: 'QUESTION',
  LESSON_PLANNER: 'LESSON PLANNER',
};

export const GaCategories = {
  BEHAVIOR: 'Behavior',
};

export const AttachmentsResourcesTypes = {
  ASSIGNMENT_MATERIAL: 'ASSIGNMENT_MATERIAL',
  GOAL: 'GOAL',
  LEADERSHIP_ATTRIBUTE: 'LEADERSHIP_ATTRIBUTE',
  LESSON_MATERIAL: 'LESSON_MATERIAL',
  LESSON_TEXT_EDITOR: 'LESSON_TEXT_EDITOR',
  LESSON_AGENDA_BOARD_AGENDA: 'LESSON_AGENDA_BOARD_AGENDA',
  LESSON_AGENDA_BOARD_TEKS: 'LESSON_AGENDA_BOARD_TEKS',
  LESSON_AGENDA_BOARD_REMINDERS: 'LESSON_AGENDA_BOARD_REMINDERS',
  PORTFOLIO_AVATAR: 'PORTFOLIO_AVATAR',
  PORTFOLIO_ESSAYS: 'PORTFOLIO_ESSAYS',
  PORTFOLIO_RECOMMENDATION_TEXT_EDITOR: 'PORTFOLIO_RECOMMENDATION_TEXT_EDITOR',
  USER_AVATAR: 'USER_AVATAR',
};

export const GaActions = {
  ACCORDION_TOGLE: 'Accordion togle',
  ADD_INTEREST: 'Add interest',
  ALERT_CLICK: 'Alert click',
  BUBBLE_CLICK: 'Bubble click',
  BUTTON_CLICK: 'Button click',
  CARD_CLICK: 'Card click',
  CHECKBOX_CLICK: 'Checkbox click',
  CHIP_CLICK: 'Chip click',
  CHIP_DELETE_CLICK: 'Chip delete click',
  DATE_PICKER_DATE_CLICK: 'Date picker date accepted',
  DATE_PICKER_INPUT_FINISH_TYPING: 'Date picker input finish typing',
  DATE_PIKER_DATE_CLICK: 'Date picker date accepted',
  DELETE_INTEREST: 'Delete interest',
  DROPDOWN_CHANGE: 'Dropdown change',
  DROPDOWN_OPEN: 'Dropdown open',
  EXTENDABLE_MENU_ITEM_CLICK: 'Clicked extendable menu item',
  FILTER_DROPDOWN_CLICK: 'Filter dropdown click',
  FIRST_TOUR_SKIPPED: 'First tour skipped at the start',
  GENERAL_TOUR_SKIPPED: 'General tour skipped',
  GRAPH_PART_CLICKED: 'Graph part clicked',
  ICON_HOVER: 'Icon hover',
  INPUT_FINISH_TYPING: 'Input finish typing',
  INTEREST_REACTION_CHANGE: 'Interest reaction change',
  LINK_CLICK: 'Link click',
  NEW_FEATURES_HOTSPOT_CLICK: 'New Features Hotspot click',
  NEW_FEATURES_TOUR_ALL_READ: 'New features tour all items read',
  NEW_FEATURES_TOUR_MARK_ALL_AS_READ: 'New Features Tour mark all as read',
  RADIOBUTTON_CLICK: 'Radiobutton click',
  RATING_CHANGE: 'Rating change',
  REACTED_WITH_NOT_INTERESTED: 'Reacted with not interested',
  REACTION_DIALOG_OPEN: 'Interest reaction dialog open',
  SEARCH_CLEAR: 'Search clear',
  SEARCH_EXECUTE: 'Search execute',
  STT: 'Speech-to-text',
  STT_CLOSE: 'Speech-to-text close',
  STT_OPEN: 'Speech-to-text open',
  SUGGESTION_CLICK: 'Suggestion click',
  SWITCH_TOGLE: 'Switch togle',
  TAB_CLICK: 'Tab click',
  THUMBNAIL_CLICK: 'Thumbnail click',
  TOUR_BACK_TO_PREV_STEP: 'Tour back to previous step',
  TOUR_GOT_IT: 'Got it',
  TOUR_LAST_STEP_REACHED: 'Tour last step reached',
  TOUR_NEXT_STEP: 'Tour next step',
  TOUR_WALK_THROUGH: 'Tour walk through',
  VIDEO_GUIDE_WATCHED: 'Video guide watched',
  ZOOM_SLIDER_VALUE_CHANGE: 'Zoom slider value change',
};

export const GaUserProperties = {
  CAMPUS: 'campus',
  CHILDREN_COUNT: 'children_count',
  GRADE: 'grade',
  SCHOOL_LEVEL: 'school_level',
  USER_ROLE: 'user_role',
};

export const DefaultGAUserProperties = {
  [GaUserProperties.CAMPUS]: null,
  [GaUserProperties.CHILDREN_COUNT]: null,
  [GaUserProperties.GRADE]: null,
  [GaUserProperties.SCHOOL_LEVEL]: null,
  [GaUserProperties.USER_ROLE]: null,
};

export const LanguagesMap = {
  EN_US: 'en-US',
  ES_US: 'es-US',
};

export const LoginProvidersMap = {
  CLASSLINK: 'CLASSLINK',
  SKYWARD: 'SKYWARD',
  GOOGLE: 'GOOGLE',
};

export const PrivilegesMap = {
  CLUB_HUB_MANAGER: 'CLUB_HUB_MANAGER',
  CONTENT_MODERATION_EDIT_APPROVE: 'CONTENT_MODERATION_EDIT_APPROVE',
  CONTENT_MODERATION_SHOW_HIDE: 'CONTENT_MODERATION_SHOW_HIDE',
  LESSON_PLANNER_CREATE_PUBLISH: 'LESSON_PLANNER_CREATE_PUBLISH',
  USER_DATA_MANAGER: 'USER_DATA_MANAGER',
  USER_PERMISSION_MANAGER: 'USER_PERMISSION_MANAGER',
  FAMILY_FEED_CREATOR_DISTRICT: 'FAMILY_FEED_CREATOR_DISTRICT',
  FAMILY_FEED_CREATOR_CAMPUS: 'FAMILY_FEED_CREATOR_CAMPUS',
  FAMILY_FEED_CREATOR_CLASS: 'FAMILY_FEED_CREATOR_CLASS',
  FAMILY_FEED_CREATOR_PERSONALIZED: 'FAMILY_FEED_CREATOR_PERSONALIZED',
  DISTRICT_ACCESS: 'DISTRICT_ACCESS',
};

export const EditablePrivilegesByGroupMap = {
  [ContentModerationFilterCategory]: [
    PrivilegesMap.CONTENT_MODERATION_SHOW_HIDE,
    PrivilegesMap.CONTENT_MODERATION_EDIT_APPROVE,
  ],
  [FamilyFeedFilterCategory]: [
    PrivilegesMap.FAMILY_FEED_CREATOR_DISTRICT,
    PrivilegesMap.FAMILY_FEED_CREATOR_CAMPUS,
    PrivilegesMap.FAMILY_FEED_CREATOR_CLASS,
    PrivilegesMap.FAMILY_FEED_CREATOR_PERSONALIZED,
  ],
  [ClubHubModerationFilterCategory]: [PrivilegesMap.CLUB_HUB_MANAGER],
};

export const FollowUpTypesMap = {
  ENDORSEMENT: 'ENDORSEMENT',
  MOOD: 'MOOD',
};

export const RolesMap = {
  TEACHER: 'TEACHER',
  STUDENT: 'STUDENT',
  GUARDIAN: 'GUARDIAN',
  AYO_ADMIN: 'AYO_ADMIN',
  OTHER: 'OTHER',
  ADMINISTRATOR: 'ADMINISTRATOR',
};

export const OpportunitiesCategoriesMap = {
  Activity: 'Activities',
  Certification: 'Certifications',
  Course: 'Courses',
  Extracurricular: 'Extracurriculars',
  'Library Video': 'Library Videos',
  'Library Book': 'Library Books',
  Studies: 'Studies',
  Volunteering: 'Volunteering',
};

export const NotificationsAlowedRolesMap = [
  RolesMap.STUDENT,
  RolesMap.TEACHER,
  RolesMap.GUARDIAN,
  RolesMap.ADMINISTRATOR,
];

export const AdminActionsTypesMap = {
  DELETE: 'DELETE',
  EXPORT: 'EXPORT',
};

export const OneTimeActionsMap = {
  ACTIVITY_GOALS_NOTIFICATION: 'ACTIVITY_GOALS_NOTIFICATION',
  ACTIVITY_LA_NOTIFICATION: 'ACTIVITY_LA_NOTIFICATION',
  ACTIVITY_SCHOOLS_NOTIFICATION: 'ACTIVITY_SCHOOLS_NOTIFICATION',
  ADJUST_EXTRACURRICULARS_OPEN: 'ADJUST_EXTRACURRICULARS_OPEN',
  APTITUDES_GALLERY_NOTIFICATION: 'APTITUDES_GALLERY_NOTIFICATION',
  ASSIGNMENTS_INSIGHTS_NOTIFICATION: 'ASSIGNMENTS_INSIGHTS_NOTIFICATION',
  CREATE_LESSON_NOTIFICATION: 'CREATE_LESSON_NOTIFICATION',
  CLUB_SETTINGS_REPLIES: 'CLUB_SETTINGS_REPLIES',
  CLUB_SETTINGS_POSTS: 'CLUB_SETTINGS_POSTS',
  EXPLORE_ENDORSEMENTS_OPEN: 'EXPLORE_ENDORSEMENTS_OPEN',
  EXPLORE_GOALS_OPEN: 'EXPLORE_GOALS_OPEN',
  EXPLORE_INTERESTS_OPEN: 'EXPLORE_INTERESTS_OPEN',
  EXPLORE_OPPORTUNITY_OPEN: 'EXPLORE_OPPORTUNITY_OPEN',
  EXPLORE_OPPORTUNITY_FLIP_CARD: 'EXPLORE_OPPORTUNITY_FLIP_CARD',
  HIGHLIGHTS_GRAPHS_NOTIFICATION: 'HIGHLIGHTS_GRAPHS_NOTIFICATION',
  INTERESTS_UNIVERSE_REACTION: 'INTERESTS_UNIVERSE_REACTION',
  INTRODUCTION_SCREEN: 'INTRODUCTION_SCREEN',
  LEADERSHIP_ML_INPUT_NOTIFICATION: 'LEADERSHIP_ML_INPUT_NOTIFICATION',
  MY_STUDENTS_DRAG_DROP: 'MY_STUDENTS_DRAG_DROP',
  MY_STUDENTS_FEEDBACK: 'MY_STUDENTS_FEEDBACK',
  MY_STUDENTS_GROUP_INTEREST_CHANGE: 'MY_STUDENTS_GROUP_INTEREST_CHANGE',
  MY_STUDENTS_SNACKBAR: 'MY_STUDENTS_SNACKBAR',
  PORTFOLIO_NOTIFICATION: 'PORTFOLIO_NOTIFICATION',
  PROGRESSIVE_PROFILING_OPEN: 'PROGRESSIVE_PROFILING_OPEN',
  STUDENTS_ACTIVITY_NOTIFICATION: 'STUDENTS_ACTIVITY_NOTIFICATION',
  STUDENT_LEADERSHIP_COMPETENCIES_NOTIFICATION: 'STUDENT_LEADERSHIP_COMPETENCIES_NOTIFICATION',
  TEACHER_PAGE_ROLE: 'TEACHER_PAGE_ROLE',
  TRAIN_AYO_LA_OPEN: 'TRAIN_AYO_LA_OPEN',
  TRAIN_AYO_START: 'TRAIN_AYO_START',
  TRY_MOBILE_APP_NOTIFICATION: 'TRY_MOBILE_APP_NOTIFICATION',
  LESSONS_PLANNER_DRAG_DROP: 'LESSONS_PLANNER_DRAG_DROP',
  LESSON_PLANNER_LANDING_NOTIFICATION: 'LESSON_PLANNER_LANDING_NOTIFICATION',
  UNCATEGORISED_INSIGHT_INSTRUCTION: 'UNCATEGORISED_INSIGHT_INSTRUCTION',
  NO_KIDS_CONSENT_SCREEN: 'NO_KIDS_CONSENT_SCREEN',
};

export const AdminActionsStatusesMap = {
  DELETION_IN_PROGRESS: 'deletion',
  EXPORT_IN_PROGRESS: 'export',
};

export const GuideTypes = {
  VIDEO: 'video',
  DETAILED: 'detailed',
};

export const QuestionnairesTypes = {
  FOLLOW_UP: 'FOLLOW_UP',
  INITIAL: 'INITIAL',
};

export const QuestionnaireNamesByTypesMap = {
  INITIAL: 'Initial Profiling',
  PROGRESSIVE: 'Progressive Profiling',
  FOLLOWUP: 'Followup questionnaire',
};

export const QuestionnaireStatusMap = {
  OPEN: 'OPEN',
  CLOSE: 'CLOSE',
};

export const SupportOptionsMap = {
  FEEDBACK: 'feedback',
  REPORT_ISSUE: 'reportIssue',
};

export const InitiativesFilterQueryParams = {
  FILTER_CATEGORY: 'filterCategory',
  FILTER_VALUE: 'filterValue',
};

export const InterestTypes = {
  SUGGESTED: 'suggested',
  OTHER: 'other',
  LIKED: 'liked',
  NOT_INTERESTED: 'not-interested',
};

export const InterestBubbleConfig = {
  [InterestTypes.SUGGESTED]: { r: 5, color: '#FFC107', textZoomRatio: 5, viewR: 24 },
  [InterestTypes.OTHER]: { r: 3, color: '#C1C0C9', textZoomRatio: 5 },
  [InterestTypes.LIKED]: { r: 9, color: '#6065A8', textZoomRatio: 4, viewR: 47 },
  [InterestTypes.NOT_INTERESTED]: { r: 3, color: '#3E364A', textZoomRatio: 5 },
};

export const InterestReactionNames = {
  0: 'Not interested',
  1: 'It’s okay',
  2: 'Like it',
  3: 'Love it!',
};

export const InterestSectionsMap = {
  EXPLORE_SECTION: 'explore',
  SUGGESTED_SECTION: 'suggested',
  NOT_INTERESTED_SECTION: 'not interested',
};

export const ChipDisplayModes = {
  ADD: 'add',
  DELETE: 'delete',
};

export const STTErrorsMap = {
  SERVER_ERROR: 'ServerError',
  NOT_ALLOWED_ERROR: 'NotAllowedError',
  NOT_FOUND_ERROR: 'NotFoundError',
  NOT_READABLE_ERROR: 'NotReadableError',
};

export const LessonPlannerStates = {
  CREATED: 'CREATED',
  CREATING_COURSE_SCHEDULE_FALL: 'CREATING_COURSE_SCHEDULE_FALL',
  CREATING_COURSE_SCHEDULE_SPRING: 'CREATING_COURSE_SCHEDULE_SPRING',
  CREATING_ROTATION_DATA: 'CREATING_ROTATION_DATA',
};

export const LogLevelsMap = {
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS',
};

export const LocalStorageKeysMap = {
  AYO_ACTIVE_USERS_HINT_HIDDEN: 'ayo_active_users_hint_hidden',
  AYO_CURRENT_CAMPUS: 'ayo_current_campus',
  AYO_DEVICE_TYPE: 'ayo_device_type',
  AYO_EMAIL_REDIRECT: 'ayo_email_redirect',
  AYO_LOGIN_PROVIDER: 'ayo_login_provider',
  AYO_RESET_TIMER: 'ayo_reset_timer',
  AYO_ROLE_IN_SCHOOL: 'ayo_role_in_school',
  AYO_STUDENT_PROFILES_VISITED: 'ayo_student_profiles_visited',
  CLASSLINK_LOGIN_REDIRECT_URL: 'classLinkLoginRedirectURL',
  SKYWARD_LOGIN_REDIRECT_URL: 'skywardLoginRedirectURL',
  GUARDIAN_DELAYED_CONSENT: 'guardian_delayed_consent',
};

export const InputsValidationRules = {
  MAX_COMMENT_LENGTH: 250,
  MAX_EDIT_CONTENT_INPUT_LENGTH: 300,
  MAX_INPUT_LENGTH: 1500,
  MAX_LINK_INPUT_LENGTH: 2000,
  MAX_TEXT_AREA_LENGTH: 3000,
  MAX_TITLE_LENGTH: 150,
  MIN_INPUT_LENGTH: 3,
  MIN_PHONE_LENGTH: 10,
  MAX_DESCRIPTION_LENGTH: 10_000,
  MIN_MESSAGE_LENGTH: 1,
};

export const ValidationRules = {
  EMPTY_STRING: 'EMPTY_STRING',
  SHORT_STRING: 'SHORT_STRING',
  LONG_STRING: 'LONG_STRING',
  EMPTY_ARRAY: 'EMPTY_ARRAY',
  EMPTY_DATE: 'EMPTY_DATE',
  INVALID_DATE: 'INVALID_DATE',
  INVALID_LINK: 'INVALID_LINK',
  DATE_BEFORE_CURRENT: 'DATE_BEFORE_CURRENT',
  DATE_BEFORE_TARGET: 'DATE_BEFORE_TARGET',
  DATE_AFTER_TARGET: 'DATE_AFTER_TARGET',
};

export const questionOptionTypes = {
  RADIO: 'RADIO',
  TEXT_AREA: 'TEXT_AREA',
  CHECKBOX: 'CHECKBOX',
};

export const InputsValidationErrors = (t, num) => ({
  MIN_ERROR_TEXT: t('Please increase your input at least to num symbols', { num }),
  MAX_ERROR_TEXT: t('Please decrease your input to num symbols', { num }),
  LINK_ERROR_TEXT: t('Invalid link format'),
  EMAIL_ERROR_TEXT: t('Please insert a correct email address'),
  REQUIRED_ERROR_TEXT: t('This is a required field Please provide information to proceed'),
  DATE_ERROR_TEXT: t('The date is invalid, please use the mm/dd/yyyy format'),
});

export const StudentActivityCriterions = {
  ANY: 'ANY',
  LEADERSHIP_ATTRIBUTE: 'LEADERSHIP_ATTRIBUTE',
  GOAL: 'GOAL',
};

export const LeadershipAttributesStatuses = {
  NEW: 'New',
  ONGOING: 'Ongoing',
};

export const Resourses = {
  LEADERSHIP_ATTRIBUTES: 'leadershipAttributes',
};

export const ResourcesToReloadMap = {
  LAG: Resourses.LEADERSHIP_ATTRIBUTES,
  MATURITY_GRAPH: Resourses.LEADERSHIP_ATTRIBUTES,
};

export const LeadershipAttributes = {
  COLLABORATION: 'collaboration',
  COMMUNICATION: 'communication',
  COURAGE: 'courage',
  DETERMINATION: 'determination',
  DRIVE: 'drive',
  EMPATHY: 'empathy',
  ENCOURAGEMENT: 'encouragement',
  GOAL_ORIENTATION: 'goal-orientation',
  GROWTH_MINDSET: 'growth-mindset',
  INTEGRIY: 'integrity',
  OPEN_MINDEDNESS: 'open-mindedness',
  PASSION: 'passion',
  RESOURCEFULNESS: 'resourcefulness',
  SERVICE: 'service',
  VISION: 'vision',
};

export const HighlightsInitiatives = {
  ACHIEVEMENTS: 'ACHIEVEMENTS',
  APTITUDES: 'APTITUDES',
  LEADERSHIP_ATTRIBUTES: 'LEADERSHIP_ATTRIBUTES',
  INTERESTS: 'INTERESTS',
  GOALS: 'GOALS',
  EXTRACURRICULARS: 'EXTRACURRICULARS',
  PARENTS_ANSWERS: 'PARENTS_ANSWERS',
  PORTFOLIO: 'PORTFOLIO',
  OPPORTUNITIES: 'OPPORTUNITIES',
  MOODS: 'MOODS',
  MOODS_READ_ONLY: 'MOODS_READ_ONLY',
  SEL_RECOMMENDATIONS: 'SEL_RECOMMENDATIONS',
  ENDORSEMENTS: 'ENDORSEMENTS',
  ENDORSEMENTS_INTERESTED: 'ENDORSEMENTS_INTERESTED',
  ENDORSEMENTS_REASONS: 'ENDORSEMENTS_REASONS',
  ENDORSEMENTS_TYPES: 'ENDORSEMENTS_TYPES',
};

export const StudentFiltersTypes = {
  INCLUDE: 'INCLUDE',
  PENDING: 'PENDING',
  EXCLUDE: 'EXCLUDE',
};

export const GoalsHighlightsKeys = {
  NEW_GOALS: 'newGoals',
  IN_PROGRESS_GOALS: 'inProgressGoals',
  COMPLETED_GOALS: 'completedGoals',
};

export const EmploymentTypesMap = {
  FULLTIME: 'Full-time',
  PARTTIME: 'Part-time',
  INTERNSHIP: 'Internship',
  FREELANCE: 'Freelance',
  SEASONAL: 'Seasonal',
};

export const Aptitudes = {
  ARTISTIC: 'ARTISTIC',
  LANGUAGE: 'LANGUAGE',
  VERBAL: 'VERBAL',
  ORGANIZATIONAL: 'ORGANIZATIONAL',
  PHYSICAL: 'PHYSICAL',
  NUMERICAL: 'NUMERICAL',
  ABSTRACT_REASONING: 'ABSTRACT_REASONING',
  SPATIAL: 'SPATIAL',
  PERCEPTUAL: 'PERCEPTUAL',
  MECHANICAL: 'MECHANICAL',
};

export const StudentFiltersSpecialValues = {
  ALL_OTHER: 'allOther',
  HAVE: 'have',
  INCLUDE: 'developing',
  PENDING: 'pending',
  DIDNT_SHARE: "Didn't share",
  NOT_DISCOVERED: 'notDiscovered',
  [GoalsHighlightsKeys.NEW_GOALS]: GoalsHighlightsKeys.NEW_GOALS,
  [GoalsHighlightsKeys.IN_PROGRESS_GOALS]: GoalsHighlightsKeys.IN_PROGRESS_GOALS,
  [GoalsHighlightsKeys.COMPLETED_GOALS]: GoalsHighlightsKeys.COMPLETED_GOALS,
  ...AchievementsCategories,
  ...Aptitudes,
  ...Moods,
};

export const StudentsPage = {
  [RolesMap.TEACHER]: '/my-students',
  [RolesMap.ADMINISTRATOR]: '/students',
};

export const SingleBarVariants = {
  TIGHT: 'TIGHT',
  DEFAULT: 'DEFAULT',
};

export const FilterActions = {
  ADD: 'ADD',
  CLEAR: 'CLEAR',
};

export const ActivitiesTypes = {
  BOOKS: 'BOOKS',
  ACTIVITIES: 'ACTIVITIES',
  COURSES: 'COURSES',
  VIDEOS: 'VIDEOS',
};

export const ActivitiesFilterCategories = {
  LEADERSHIP_ATTRIBUTES: 'ACTIVITIES_LA',
  TYPE: 'ACTIVITIES_TYPE',
};

export const SchoolLevels = {
  ELEMENTARY: 'ELEMENTARY',
  MIDDLE: 'MIDDLE',
  HIGH: 'HIGH',
};

export const ContentSwitchLabels = {
  on: 'On (short translation)',
  off: 'Off (short translation)',
};

export const ContentModerationTypesMap = {
  INTERESTS: 'interests',
  OPPORTUNITIES: 'opportunities',
};

export const AptitudesInfo = {
  [Aptitudes.ARTISTIC]: {
    name: 'Artistic',
    description: 'Creativity, artistic talents, musical abilities, dramatic talents',
  },
  [Aptitudes.LANGUAGE]: {
    name: 'Language',
    description: 'Meaning of the words, correct spelling, meaning of idioms',
  },
  [Aptitudes.VERBAL]: {
    name: 'Verbal',
    description: 'Drawing correct meaning from written information',
  },
  [Aptitudes.ORGANIZATIONAL]: {
    name: 'Organizational',
    description: "Planning, doing, and evaluating your and others' actions",
  },
  [Aptitudes.PHYSICAL]: {
    name: 'Physical',
    description: 'Bodily strength, coordination, lifting weights, aerobic exercise',
  },
  [Aptitudes.NUMERICAL]: {
    name: 'Numerical',
    description: 'Performing numerical operations, math, and calculations',
  },
  [Aptitudes.ABSTRACT_REASONING]: {
    name: 'Abstract reasoning',
    description: 'Thinking logically, math and computer science',
  },
  [Aptitudes.SPATIAL]: {
    name: 'Spatial',
    description: 'Imagination of objects in space, geometry, 3D shaping',
  },
  [Aptitudes.PERCEPTUAL]: {
    name: 'Perceptual',
    description: 'Comparing information and identification of similarities',
  },
  [Aptitudes.MECHANICAL]: {
    name: 'Mechanical',
    description: 'Practical applications of mechanics, robotics, and physics',
  },
};

export const ReflectionTypes = {
  INTEREST: 'INTEREST',
  MOOD: 'MOOD',
  STUDENT_PORTFOLIO: 'STUDENT_PORTFOLIO',
};

export const LessonsScheduleSettings = {
  SCHEDULE_NAME: 'scheduleName',
  SCHOOL_NAME: 'schoolName',
  SEMESTERS: 'semestersSchedule',
  ROTATION_DAYS_SCHEDULE: 'rotationDaysSchedule',
  ROTATION_WEEKS_SCHEDULE: 'rotationWeeksSchedule',
  SCHOOL_YEAR: 'schoolYear',
};

export const Semesters = {
  FALL: 'FALL',
  SPRING: 'SPRING',
};

export const TestTypeNameMap = {
  AP: 'Advanced Placement exams (AP)',
  ACT: 'American College Testing (ACT)',
  SAT: 'Scholastic Aptitude Test (SAT)',
};

export const AcademicRecordNamesMap = {
  GPA: {
    title: 'Cumulative GPA',
    emptyState: 'No information about Cumulative GPA so far.',
  },
  RANK: {
    title: 'Class rank',
    emptyState: 'No information about Class rank so far.',
  },
  PERCENTILE: {
    title: 'Top percentage',
    emptyState: 'No information about Top percentage so far.',
  },
  GRADUATION_YEAR: {
    title: 'Graduation year',
    emptyState: 'No information about Graduation year so far.',
  },
};

export const PortfolioSectionTypes = {
  PORTFOLIO__PERSONAL_INFO: 'PORTFOLIO__PERSONAL_INFO',
  PORTFOLIO__INTERESTS: 'PORTFOLIO__INTERESTS',
  PORTFOLIO__APTITUDES: 'PORTFOLIO__APTITUDES',
  PORTFOLIO__ENDORSEMENTS: 'PORTFOLIO__ENDORSEMENTS',
  PORTFOLIO__LEADERSHIP_ATTRIBUTES: 'PORTFOLIO__LEADERSHIP_ATTRIBUTES',
  PORTFOLIO__EXTRACURRICULARS: 'PORTFOLIO__EXTRACURRICULARS',
  PORTFOLIO__ACHIEVEMENTS: 'PORTFOLIO__ACHIEVEMENTS',
  PORTFOLIO__ESSAYS: 'PORTFOLIO__ESSAYS',
  PORTFOLIO__ACADEMIC_RECORD: 'PORTFOLIO__ACADEMIC_RECORD',
  PORTFOLIO__CERTIFICATES: 'PORTFOLIO__CERTIFICATES',
  PORTFOLIO__EMPLOYMENT: 'PORTFOLIO__EMPLOYMENT',
  PORTFOLIO__RECOMMENDATIONS: 'PORTFOLIO__RECOMMENDATIONS',
  PORTFOLIO__TEST_SCORES: 'PORTFOLIO__TEST_SCORES',
  PORTFOLIO__VOLUNTEERING: 'PORTFOLIO__VOLUNTEERING',
};

export const PortfolioNotificationSectionMap = {
  [PortfolioSectionTypes.PORTFOLIO__PERSONAL_INFO]: null,
  [PortfolioSectionTypes.PORTFOLIO__INTERESTS]: 'INTEREST',
  [PortfolioSectionTypes.PORTFOLIO__EMPLOYMENT]: 'EMPLOYMENT',
  [PortfolioSectionTypes.PORTFOLIO__APTITUDES]: 'APTITUDE',
  [PortfolioSectionTypes.PORTFOLIO__ENDORSEMENTS]: 'ENDORSEMENT',
  [PortfolioSectionTypes.PORTFOLIO__LEADERSHIP_ATTRIBUTES]: 'LAG',
  [PortfolioSectionTypes.PORTFOLIO__EXTRACURRICULARS]: 'EXTRACURRICULAR',
  [PortfolioSectionTypes.PORTFOLIO__ACHIEVEMENTS]: 'ACHIEVEMENT',
  [PortfolioSectionTypes.PORTFOLIO__ACADEMIC_RECORD]: 'ACADEMIC_RECORD',
  [PortfolioSectionTypes.PORTFOLIO__CERTIFICATES]: 'CERTIFICATE',
  [PortfolioSectionTypes.PORTFOLIO__RECOMMENDATIONS]: 'RECOMMENDATION',
  [PortfolioSectionTypes.PORTFOLIO__TEST_SCORES]: 'TEST_SCORES',
  [PortfolioSectionTypes.PORTFOLIO__VOLUNTEERING]: 'VOLUNTEERING',
};

export const PortfolioSectionSettings = {
  DISPLAY_SECTION: {
    type: 'DISPLAY_SECTION',
  },
  DISPLAY_MATURITY_GRAPH: {
    type: 'DISPLAY_MATURITY_GRAPH',
    label: 'Maturity graph',
  },
};

export const Directions = {
  UP: 'UP',
  DOWN: 'DOWN',
};

export const PortfolioSectionsAnimations = {
  [Directions.DOWN]: {
    hide: ['fade-out-bottom', 'fade-out-top'],
    show: ['fade-in-bottom', 'fade-in-top'],
  },
  [Directions.UP]: {
    hide: ['fade-out-top', 'fade-out-bottom'],
    show: ['fade-in-top', 'fade-in-bottom'],
  },
};

export const PublicationStatuses = {
  DRAFT: 'DRAFT',
  PUBLISHED: 'PUBLISHED',
  DELETED: 'DELETED',
};

export const PortfolioSortingConfig = {
  BY_RECENTLY_CHANGE: { key: 'updatedDate', order: 'decrease' },
  BY_NEWEST_PUBLISHED_ON_TOP: { key: 'publishedDate', order: 'decrease' },
  BY_OLDEST_PUBLISHED_ON_TOP: { key: 'publishedDate', order: 'increase' },
  BY_NEWEST_ON_TOP: { key: 'createdDate', order: 'decrease' },
  BY_OLDEST_ON_TOP: { key: 'createdDate', order: 'increase' },
};

export const CourseSources = {
  COURSE: 'COURSE',
  ACTIVITY: 'ACTIVITY',
};

export const DatePickersLocales = {
  'en-US': 'en',
  'es-US': 'es',
};

export const AssignmentTypes = {
  ASSIGNMENT: 'ASSIGNMENT',
  SHORT_ANSWER: 'SHORT_ANSWER_QUESTION',
  MULTIPLE_CHOICE: 'MULTIPLE_CHOICE_QUESTION',
  ANNOUNCEMENT: 'ANNOUNCEMENT',
  MATERIAL: 'MATERIAL',
};

export const AssignmentFields = {
  TITLE: 'TITLE',
  INSTRUCTION: 'INSTRUCTION',
  STUDENTS: 'STUDENTS',
  POINTS: 'POINTS',
  DEADLINES: 'DEADLINES',
  SETTINGS: 'SETTINGS',
  MATERIALS: 'MATERIALS',
  OPTIONS: 'OPTIONS',
  CHOICES: 'CHOICES',
};

export const AssignmentInsightsCategories = {
  INTERESTS: 'INTERESTS',
  EXTRACURRICULARS: 'EXTRACURRICULARS',
  ENDORSEMENTS: 'ENDORSEMENTS',
};

export const FeedPostCategories = {
  ACHIEVEMENTS: 'ACHIEVEMENTS',
  ALL_UPDATES: 'ALL_UPDATES',
  ALERTS: 'ALERTS',
  ANNOUNCEMENTS: 'ANNOUNCEMENTS',
  BADGES: 'BADGES',
  CLUBS: 'CLUBS',
  GOALS: 'GOALS',
  INITIATIVES_EVENTS: 'INITIATIVES_EVENTS',
  INTERESTS: 'INTERESTS',
  MOOD_CHECK_IN: 'MOOD_CHECK_IN',
  OPPORTUNITIES: 'OPPORTUNITIES',
  PORTFOLIOS: 'PORTFOLIOS',
  STUDENT_SUPPORT: 'STUDENT_SUPPORT',
  UNCATEGORIZED: 'UNCATEGORIZED',
};

export const FeedPostTypes = {
  CREATED_BY_USER: 'CREATED_BY_USER',
};

export const FeedPostCategoriesLabels = {
  [FeedPostCategories.ACHIEVEMENTS]: 'Achievements & participation',
  [FeedPostCategories.ALL_UPDATES]: 'All updates',
  [FeedPostCategories.ALERTS]: 'Alerts',
  [FeedPostCategories.ANNOUNCEMENTS]: 'Announcements',
  [FeedPostCategories.BADGES]: 'Badges',
  [FeedPostCategories.CLUBS]: 'Clubs',
  [FeedPostCategories.GOALS]: 'Goals',
  [FeedPostCategories.INITIATIVES_EVENTS]: 'Initiatives & Events',
  [FeedPostCategories.INTERESTS]: 'Interests',
  [FeedPostCategories.MOOD_CHECK_IN]: 'Mood check-in',
  [FeedPostCategories.OPPORTUNITIES]: 'Opportunities',
  [FeedPostCategories.PORTFOLIOS]: 'Portfolios',
  [FeedPostCategories.STUDENT_SUPPORT]: 'Student support',
  [FeedPostCategories.UNCATEGORIZED]: 'Uncategorized',
};

export const FeedPostStatuses = {
  NEW: 'NEW',
  EDITED: 'EDITED',
  DELETED: 'DELETED',
};

export const minProperDate = '1900-01-01';

export const SchoolNameMapping = {
  Academy: 'Mesquite Academy',
  'ACHZIGER EL': 'Achziger Elementary School',
  'AGNEW MIDDLE': 'Agnew Middle School',
  AYO: 'AYO',
  'AUSTIN EL': 'Austin Elementary School',
  'BEASLEY EL': 'Beasley Elementary School',
  'BERRY MIDDLE': 'Berry Middle School',
  'BLACK EL': 'Black Elementary School',
  'CANNADAY EL': 'Cannaday Elementary School',
  'CROSS EL': 'Cross Elementary School',
  'FLORENCE EL': 'Florence Elementary School',
  'FLOYD EL': 'Floyd Elementary School',
  'FRASIER MIDDLE': 'Frasier Middle School',
  'GALLOWAY EL': 'Galloway Elementary School',
  'GENTRY EL': 'Gentry Elementary School',
  'GRAY EL': 'Gray Elementary School',
  'HANBY EL': 'Hanby Elementary School',
  'HENRIE EL': 'Henrie Elementary School',
  'HODGES EL': 'Hodges Elementary School',
  'HORN H S': 'John Horn High School',
  JJAEP: 'JJAEP',
  'KIMBALL EL': 'Kimball Elementary School',
  'KIMBROUGH MIDDLE': 'Kimbrough Middle School',
  'LAWRENCE EL': 'Lawrence Elementary School',
  'Learning Center': 'Learning Center',
  'MACKEY EL': 'Mackey Elementary School',
  'MCDONALD MIDDLE': 'McDonald Middle School',
  'MCKENZIE EL': 'McKenzie Elementary School',
  'MCWHORTER EL': 'McWhorter Elementary School',
  'MESQUITE H S': 'Mesquite High School',
  'Mesquite Test School': 'Mesquite Test School',
  'Mesquite ISD': 'Mesquite ISD',
  'MOSS EL': 'Moss Elementary School',
  'MOTLEY EL': 'Motley Elementary School',
  'NEW MIDDLE': 'A.C. New Middle School',
  'NORTH MESQUITE H S': 'North Mesquite High School',
  'PIRRUNG EL': 'Pirrung Elementary School',
  'PORTER EL': 'Porter Elementary School',
  'POTEET H S': 'Poteet High School',
  'PRICE EL': 'Price Elementary School',
  'RANGE EL': 'Range Elementary School',
  'RUGEL EL': 'Rugel Elementary School',
  'RUTHERFORD EL': 'Rutherford Elementary School',
  'SEABOURN EL': 'Seabourn Elementary School',
  'SHANDS EL': 'Shands Elementary School',
  'SHAW EL': 'Shaw Elementary School',
  'SMITH EL': 'Smith Elementary School',
  'TERRY MIDDLE': 'Terry Middle School',
  'THOMPSON EL': 'Thompson Elementary School',
  'TISINGER EL': 'Tisinger Elementary School',
  'TOSCH EL': 'Tosch Elementary School',
  'VANGUARD H S': 'Vanguard High School',
  'VANSTON MIDDLE': 'Vanston Middle School',
  'WEST MESQUITE H S': 'West Mesquite High School',
  'WILKINSON MIDDLE': 'Wilkinson Middle School',
  'WOOLLEY MIDDLE': 'Woolley Middle School',
};

export const AvatarTypes = {
  AYO: 'AYO',
  PERSON: 'PERSON',
  SCHOOL: 'SCHOOL',
  DISTRICT: 'DISTRICT',
};

export const FamilyActivitiesCategoriesTypes = {
  APTITUDE: 'APTITUDE',
  INTEREST: 'INTEREST',
};

export default {
  AchievementsCategories,
  ActionButtonsIdsMap,
  AdminActionsStatusesMap,
  AdminActionsTypesMap,
  AnalyticsStatsKeys,
  AnalyticsStatsCategories,
  AnalyticTypesMap,
  CertificateKeys,
  ChipDisplayModes,
  CommentsPagesMap,
  ContentModerationTypesMap,
  ContentSwitchLabels,
  ContentTypesMap,
  DeviceTypesMap,
  AttachmentsResourcesTypes,
  FeedbackFunctionalityKeysMap,
  FeedbackPrefixTypes,
  FeedPostCategories,
  FeedPostCategoriesLabels,
  FeedPostStatuses,
  InitiativesFilterQueryParams,
  InputsValidationErrors,
  InputsValidationRules,
  InterestsTypeMap,
  KeyboardMap,
  LanguagesMap,
  LessonPlannerStates,
  LocalStorageKeysMap,
  LoginProvidersMap,
  minProperDate,
  NotificationResourcesMap,
  NotificationSourcesMap,
  OpportunitiesCategoriesMap,
  PortfolioSectionTypes,
  PortfolioSectionSettings,
  QuestionnairesTypes,
  QuestionnaireNamesByTypesMap,
  ResourcesToReloadMap,
  RolesMap,
  STTErrorsMap,
  SchoolLevels,
  SchoolNameMapping,
  StudentActivityCriterions,
  StudentsPage,
  SupportOptionsMap,
  SwitchLabels,
};
