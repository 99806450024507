import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { TextField, Typography } from '../../../../../atoms';
import AssignmentNoEditTooltip from '../../assignment-no-edit-tooltip/AssignmentNoEditTooltip';
import { InputsValidationRules } from '../../../../../../constants/enums';

const AssignmentInstruction = ({
  fieldLabel,
  gaLabel,
  value,
  title,
  handleChange,
  disabled,
  errorMessage,
  required,
}) => {
  const { t } = useTranslation();
  return (
    <Box>
      <Typography
        aria-label={required ? `${t(title)} (${t('required')})` : t(title)}
        paragraph
        variant="subtitle2"
      >
        {t(title)}
        {required && '*'}
      </Typography>
      <AssignmentNoEditTooltip showTooltip={disabled}>
        <TextField
          disabled={disabled}
          error={errorMessage !== ''}
          fullWidth
          gaLabel={gaLabel}
          helperText={errorMessage}
          label={t(fieldLabel)}
          maxLength={
            required
              ? InputsValidationRules.MAX_INPUT_LENGTH
              : InputsValidationRules.MAX_DESCRIPTION_LENGTH
          }
          multiline
          onChange={(e) => handleChange(e.target.value)}
          outlined
          required={required}
          rowsCount={5}
          value={value}
        />
      </AssignmentNoEditTooltip>
    </Box>
  );
};

AssignmentInstruction.propTypes = {
  gaLabel: PropTypes.string,
  fieldLabel: PropTypes.string,
  value: PropTypes.string,
  title: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  required: PropTypes.bool,
};

AssignmentInstruction.defaultProps = {
  gaLabel: null,
  value: null,
  title: 'Instructions (optional)',
  fieldLabel: 'Add instructions here',
  errorMessage: '',
  required: false,
};

export default React.memo(AssignmentInstruction);
