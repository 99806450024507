/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import 'dayjs/locale/en';
import 'dayjs/locale/es';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, useControlled } from '@mui/material';

import { DatePickersLocales, GaActions, GaCategories } from '../../../constants/enums';
import { GA } from '../../../utils';
import { ReactComponent as DatePickerIcon } from '../../../resources/icons/date_picker.svg';
import Tooltip from '../tooltip/Tooltip';
import TextField from '../textfield/TextField';

const calendarPositionSettings = {
  small: { left: '15px', top: '30px' },
  large: { left: '30px', top: '15px' },
};

const DatePicker = ({
  className,
  dayOfWeekFormatter,
  disabledDayText,
  disablePast,
  disabled,
  gaLabel,
  label,
  invalidDateMessage,
  minDateMessage,
  onChange,
  onError,
  outlined,
  value,
  views,
  shouldDisabledDate,
  showDaysOutsideCurrentMonth,
  minDate,
  maxDate,
  renderInput,
  isOpen,
  setIsOpen: setIsCalendarOpenProp,
  renderDay,
}) => {
  const { i18n } = useTranslation();
  const [isCalendarOpen, setIsCalendarOpenUncontrolled] = useControlled({
    controlled: isOpen,
    default: false,
    name: 'DatePicker',
    state: 'opened',
  });
  const setIsCalendarOpen = setIsCalendarOpenProp || setIsCalendarOpenUncontrolled;
  const [isToolTipOpen, setIsToolTipOpen] = useState(false);
  const datePickerRef = useRef();
  const inputTitleId = `date-${label.split(' ').join('-')}`;
  const sendGoogleAnalytics = useCallback(
    (action) => {
      if (gaLabel) {
        GA.logInteraction({
          category: GaCategories.BEHAVIOR,
          action,
          label: gaLabel,
        });
      }
    },
    [gaLabel],
  );
  const onDateAcceptHandler = useCallback(() => {
    setIsCalendarOpen(false);
    setIsToolTipOpen(false);
    sendGoogleAnalytics(GaActions.DATE_PICKER_DATE_CLICK);
  }, [sendGoogleAnalytics, setIsCalendarOpen]);

  const windowWidth = window.innerWidth;

  const addTooltipHandlers = useCallback(() => {
    if (disabledDayText) {
      setTimeout(() => {
        document.querySelectorAll('.MuiDayPicker-weekContainer').forEach((weeksWrapper) => {
          weeksWrapper.querySelectorAll('button').forEach((daysWrapper) => {
            if (daysWrapper.classList.contains('Mui-disabled')) {
              daysWrapper.removeAttribute('disabled');
              daysWrapper.addEventListener('mouseover', () => {
                setIsToolTipOpen(true);
              });
              daysWrapper.addEventListener('mouseleave', () => setIsToolTipOpen(false));
            }
          });
        });
      }, 0);
    }
  }, [disabledDayText]);

  const onCalendarOpen = () => {
    setIsCalendarOpen(true);
  };

  // Adding handler with effect, so it would work when using controlled picker and opening it with external elements
  useEffect(() => {
    if (isCalendarOpen) {
      addTooltipHandlers();
    }
  }, [isCalendarOpen, addTooltipHandlers]);

  const onBlurHandler = useCallback(() => {
    sendGoogleAnalytics(GaActions.DATE_PICKER_INPUT_FINISH_TYPING);
  }, [sendGoogleAnalytics]);

  return (
    <Tooltip open={isToolTipOpen} placement="bottom" title={disabledDayText}>
      <Box ref={datePickerRef} className="ayo-date-picker">
        <LocalizationProvider
          adapterLocale={DatePickersLocales[i18n.language]}
          dateAdapter={AdapterDayjs}
        >
          <DesktopDatePicker
            className={classnames('ayo-textfield', 'ayo-date-picker__wrapper', {
              'ayo-textfield--outlined': outlined,
              className,
            })}
            dayOfWeekFormatter={dayOfWeekFormatter}
            disabled={disabled}
            disablePast={disablePast}
            disableToolbar
            format="MM/dd/yyyy"
            FormHelperTextProps={{
              role: 'alert',
            }}
            gaLabel={gaLabel}
            InputLabelProps={{ id: inputTitleId }}
            inputProps={{
              'aria-labelledby': inputTitleId,
              autoComplete: 'off',
            }}
            inputVariant="outlined"
            invalidDateMessage={invalidDateMessage}
            invalidLabel={invalidDateMessage}
            label={label}
            maxDate={maxDate}
            minDate={minDate}
            minDateMessage={minDateMessage}
            onAccept={onDateAcceptHandler}
            onBlur={onBlurHandler}
            onChange={onChange}
            onClose={() => setIsCalendarOpen(false)}
            onError={(error) => onError(error)}
            onMonthChange={addTooltipHandlers}
            onOpen={onCalendarOpen}
            open={isCalendarOpen}
            PaperProps={{
              className: classnames('ayo-date-picker__calendar', className),
              style:
                windowWidth < 400 ? calendarPositionSettings.small : calendarPositionSettings.large,
            }}
            renderDay={renderDay}
            renderInput={renderInput}
            required
            shouldDisableDate={shouldDisabledDate}
            showDaysOutsideCurrentMonth={showDaysOutsideCurrentMonth}
            value={value}
            variant="inline"
            views={views}
          />
        </LocalizationProvider>
      </Box>
    </Tooltip>
  );
};

DatePicker.propTypes = {
  dayOfWeekFormatter: PropTypes.func,
  disabledDayText: PropTypes.string,
  disabled: PropTypes.bool,
  gaLabel: PropTypes.string,
  label: PropTypes.string,
  invalidDateMessage: PropTypes.string,
  minDateMessage: PropTypes.string,
  onChange: PropTypes.func,
  onError: PropTypes.func,
  outlined: PropTypes.bool,
  value: PropTypes.instanceOf(Date),
  views: PropTypes.arrayOf(PropTypes.oneOf(['day', 'month', 'year'])),
  shouldDisabledDate: PropTypes.func,
  renderInput: PropTypes.func,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  disablePast: PropTypes.bool,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  showDaysOutsideCurrentMonth: PropTypes.bool,
  renderDay: PropTypes.func,
  className: PropTypes.string,
};

DatePicker.defaultProps = {
  dayOfWeekFormatter: null,
  disabledDayText: '',
  gaLabel: null,
  label: null,
  invalidDateMessage: '',
  minDateMessage: '',
  onChange: () => {},
  onError: () => {},
  outlined: false,
  value: '',
  views: ['day'],
  shouldDisabledDate: null,
  renderInput: (props) => (
    <TextField
      {...props}
      fullWidth
      InputProps={{
        endAdornment: (
          <IconButton
            // eslint-disable-next-line react/prop-types
            {...props.InputProps.endAdornment.props.children.props}
            className="ayo-icon-button"
            disableRipple
          >
            <DatePickerIcon />
          </IconButton>
        ),
      }}
    />
  ),
  disablePast: false,
  disabled: false,
  minDate: null,
  maxDate: null,
  isOpen: undefined,
  setIsOpen: null,
  showDaysOutsideCurrentMonth: false,
  renderDay: null,
  className: null,
};

export default DatePicker;
