import React, { useEffect, useMemo, useState } from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

import { useProfileService } from '../../../services';
import { Typography } from '../../atoms';
import { MobileNotSupported } from '../../organisms';
import PortfolioSharedPersonalInformationSection from '../../organisms/portfolio-sections/sections/portfolio-personal-information-section/PortfolioSharedPersonalInformationSection';
import { PortfolioSectionTypes } from '../../../constants/enums';
import { getOrderedSectionsByVisibility } from '../../../utils';
import {
  PortfolioSharedAcademicRecordSection,
  PortfolioSharedAchievementsSection,
  PortfolioSharedAptitudesSection,
  PortfolioSharedCertificatesSection,
  PortfolioSharedEmploymentsSection,
  PortfolioSharedEndorsementsSection,
  PortfolioSharedEssaysSection,
  PortfolioSharedExtracurricularsSection,
  PortfolioSharedInterestsSection,
  PortfolioSharedLeadershipAttributesSection,
  PortfolioSharedRecommendationsSection,
  PortfolioSharedTestScoresSection,
  PortfolioSharedVolunteeringSection,
} from '../../organisms/portfolio-sections/sections';

const PortfolioSharedPage = () => {
  const { t } = useTranslation();
  const match = useRouteMatch();
  const { token } = match.params;
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
  const { getSharedPortfolioData } = useProfileService();
  const [studentPortfolio, setStudentPortfolio] = useState(null);

  const PortfolioSharedComponents = useMemo(
    () => ({
      [PortfolioSectionTypes.PORTFOLIO__PERSONAL_INFO]: (
        <PortfolioSharedPersonalInformationSection
          avatar={studentPortfolio?.avatar}
          sectionData={studentPortfolio?.sections?.PORTFOLIO__PERSONAL_INFO}
          token={token}
        />
      ),
      [PortfolioSectionTypes.PORTFOLIO__ACHIEVEMENTS]: (
        <PortfolioSharedAchievementsSection
          sectionData={studentPortfolio?.sections?.PORTFOLIO__ACHIEVEMENTS}
        />
      ),
      [PortfolioSectionTypes.PORTFOLIO__APTITUDES]: (
        <PortfolioSharedAptitudesSection
          sectionData={studentPortfolio?.sections?.PORTFOLIO__APTITUDES}
        />
      ),
      [PortfolioSectionTypes.PORTFOLIO__CERTIFICATES]: (
        <PortfolioSharedCertificatesSection
          sectionData={studentPortfolio?.sections?.PORTFOLIO__CERTIFICATES}
          token={token}
        />
      ),
      [PortfolioSectionTypes.PORTFOLIO__EMPLOYMENT]: (
        <PortfolioSharedEmploymentsSection
          sectionData={studentPortfolio?.sections?.PORTFOLIO__EMPLOYMENT}
          token={token}
        />
      ),
      [PortfolioSectionTypes.PORTFOLIO__ENDORSEMENTS]: (
        <PortfolioSharedEndorsementsSection
          sectionData={studentPortfolio?.sections?.PORTFOLIO__ENDORSEMENTS}
        />
      ),
      [PortfolioSectionTypes.PORTFOLIO__ESSAYS]: (
        <PortfolioSharedEssaysSection
          sectionData={studentPortfolio?.sections?.PORTFOLIO__ESSAYS}
          token={token}
        />
      ),
      [PortfolioSectionTypes.PORTFOLIO__EXTRACURRICULARS]: (
        <PortfolioSharedExtracurricularsSection
          sectionData={studentPortfolio?.sections?.PORTFOLIO__EXTRACURRICULARS}
        />
      ),
      [PortfolioSectionTypes.PORTFOLIO__INTERESTS]: (
        <PortfolioSharedInterestsSection
          sectionData={studentPortfolio?.sections?.PORTFOLIO__INTERESTS}
        />
      ),
      [PortfolioSectionTypes.PORTFOLIO__LEADERSHIP_ATTRIBUTES]: (
        <PortfolioSharedLeadershipAttributesSection
          sectionData={studentPortfolio?.sections?.PORTFOLIO__LEADERSHIP_ATTRIBUTES}
        />
      ),
      [PortfolioSectionTypes.PORTFOLIO__RECOMMENDATIONS]: (
        <PortfolioSharedRecommendationsSection
          sectionData={studentPortfolio?.sections?.PORTFOLIO__RECOMMENDATIONS}
          token={token}
        />
      ),
      [PortfolioSectionTypes.PORTFOLIO__VOLUNTEERING]: (
        <PortfolioSharedVolunteeringSection
          sectionData={studentPortfolio?.sections?.PORTFOLIO__VOLUNTEERING}
          token={token}
        />
      ),
      [PortfolioSectionTypes.PORTFOLIO__TEST_SCORES]: (
        <PortfolioSharedTestScoresSection
          sectionData={studentPortfolio?.sections?.PORTFOLIO__TEST_SCORES}
        />
      ),
      [PortfolioSectionTypes.PORTFOLIO__ACADEMIC_RECORD]: (
        <PortfolioSharedAcademicRecordSection
          sectionData={studentPortfolio?.sections?.PORTFOLIO__ACADEMIC_RECORD}
        />
      ),
    }),
    [studentPortfolio, token],
  );

  useEffect(() => {
    getSharedPortfolioData(token).then((portfolioData) => {
      setStudentPortfolio({
        ...portfolioData,
        visibleSections: getOrderedSectionsByVisibility(portfolioData).visibleKeys,
      });
    });
  }, [getSharedPortfolioData, token]);

  return (
    <Box className="ayo-full-screen-portfolio-section">
      {isWidthUpSm ? (
        <>
          <Box mx={9} my={5}>
            {studentPortfolio && (
              <Typography variant="h1">
                {`${studentPortfolio?.sections?.PORTFOLIO__PERSONAL_INFO?.name} - ${studentPortfolio?.title}`}
              </Typography>
            )}
          </Box>
          <Box mt={2}>
            {studentPortfolio &&
              studentPortfolio?.visibleSections.map((key) => (
                <Box key={key}>{PortfolioSharedComponents[key]}</Box>
              ))}
          </Box>
        </>
      ) : (
        <MobileNotSupported
          explanation={t(
            "Please open the student's portfolio section with a tablet or desktop version to use this functionality",
          )}
        />
      )}
    </Box>
  );
};

export default PortfolioSharedPage;
