import React, { useContext, useRef, useEffect, useCallback } from 'react';
import { useHistory, Prompt } from 'react-router-dom';

import { AppActions, AppContext } from '../../../context';

const DirtyPrompt = () => {
  const { state: appState, dispatch: dispatchAppState } = useContext(AppContext);
  const history = useHistory();

  const nextLocationRef = useRef();

  const openLeaveDialog = useCallback(
    (nextLocation) => {
      dispatchAppState({
        type: AppActions.SET_IS_LEAVE_DIALOG_OPEN,
        data: true,
      });
      nextLocationRef.current = nextLocation;
    },
    [dispatchAppState],
  );

  useEffect(() => {
    if (appState.isLeaveDialogOpen === null && (appState.isDirty || appState.isBackgroundSync)) {
      nextLocationRef.current = null;
    }
  }, [appState.isBackgroundSync, appState.isDirty, appState.isLeaveDialogOpen]);

  useEffect(() => {
    if (appState.isDirty || appState.isBackgroundSync) {
      const onLogout = () => {
        dispatchAppState({ type: AppActions.SET_IS_LEAVE_DIALOG_OPEN, data: true });
        return new Promise((resolve) => {
          dispatchAppState({ type: AppActions.SET_LOGOUT_HANDLER, data: { resolver: resolve } });
        });
      };
      dispatchAppState({ type: AppActions.SET_LOGOUT_HANDLER, data: { waiter: onLogout } });
    } else {
      dispatchAppState({
        type: AppActions.SET_LOGOUT_HANDLER,
        data: { waiter: Promise.resolve(), resolver: () => {} },
      });
    }
  }, [appState.isBackgroundSync, appState.isDirty, dispatchAppState]);

  const beforeUnloadHandler = useCallback(
    () => (appState.isDirty || appState.isBackgroundSync ? true : null),
    [appState.isBackgroundSync, appState.isDirty],
  );

  useEffect(() => {
    window.onbeforeunload = beforeUnloadHandler;

    return () => {
      window.onbeforeunload = undefined;
    };
  }, [beforeUnloadHandler]);

  useEffect(() => {
    if (!(appState.isDirty || appState.isBackgroundSync) && nextLocationRef.current) {
      history.push(`${nextLocationRef.current.pathname}${nextLocationRef.current.search}`);
      nextLocationRef.current = null;
    }
  }, [appState.isBackgroundSync, appState.isDirty, history]);

  return (
    <Prompt
      message={(nextLocation) => {
        openLeaveDialog(nextLocation);
        return false;
      }}
      when={(appState.isDirty || appState.isBackgroundSync) && !appState.idleTimeoutExpired}
    />
  );
};

export default DirtyPrompt;
