import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

import { ActivitiesFilterCategories, ActivitiesTypes } from '../../../../constants/enums';
import { getFilterValueParts, isSimpleFilterSelected, updateSimpleFilter } from '../../../../utils';
import { Checkbox, FormControlLabel, Typography } from '../../../atoms';

const ActivitiesTypeFilters = ({ menuFilters, setMenuFilters, stats }) => {
  const { filtersList } = getFilterValueParts(menuFilters, stats);

  const onChange = useCallback(
    (e) => {
      const optionsList = Object.values(ActivitiesTypes);
      const newFilters = updateSimpleFilter(
        e.target.value,
        ActivitiesFilterCategories.TYPE,
        menuFilters,
        optionsList,
        stats,
      );
      setMenuFilters(newFilters);
    },
    [menuFilters, setMenuFilters, stats],
  );

  const { t } = useTranslation();

  return (
    <Box>
      <Box className="filters-controls-box" display="flex" flexDirection="column">
        <Typography variant="subtitle2">{t('Filter by types of AYO suggestions')}</Typography>
        {Object.entries(stats[ActivitiesFilterCategories.TYPE]).map(([type, statValue]) => (
          <FormControlLabel
            key={type}
            control={
              <Checkbox
                checked={isSimpleFilterSelected(
                  type,
                  ActivitiesFilterCategories.TYPE,
                  menuFilters.filterCategory,
                  filtersList,
                )}
                gaLabel={`Activities Filters - Type - ${type}`}
                onChange={onChange}
                value={type}
              />
            }
            label={`${t(type)} (${statValue})`}
          />
        ))}
      </Box>
    </Box>
  );
};

ActivitiesTypeFilters.propTypes = {
  menuFilters: PropTypes.shape({ filterValue: PropTypes.string, filterCategory: PropTypes.string })
    .isRequired,
  setMenuFilters: PropTypes.func.isRequired,
  stats: PropTypes.instanceOf(Object).isRequired,
};

export default ActivitiesTypeFilters;
