import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

import { Typography } from '../../atoms';
import { GaActions, GaCategories } from '../../../constants/enums';
import { authorContactInfo } from '../../../constants/propTypes';
import { GA } from '../../../utils';
import ContactInfoPopover from '../contact-popover-info/ContactInfoPopover';

const ClickableAuthorWithPopover = React.forwardRef(
  ({ author, authorName, className, typographyVariant }) => {
    const anchorElRef = useRef(null);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleOpenPopover = () => {
      setAnchorEl(anchorElRef.current);
      GA.logInteraction({
        category: GaCategories.BEHAVIOR,
        action: GaActions.BUTTON_CLICK,
        label: 'Contact info popover open',
      });
    };
    const handleClosePopover = () => {
      setAnchorEl(null);
      GA.logInteraction({
        category: GaCategories.BEHAVIOR,
        action: GaActions.BUTTON_CLICK,
        label: 'Contact info popover close',
      });
    };

    return (
      <Box>
        <Typography
          ref={anchorElRef}
          className={className}
          component="h2"
          onClick={handleOpenPopover}
          onKeyPress={handleOpenPopover}
          role="link"
          tabIndex={0}
          variant={typographyVariant}
        >
          {authorName || author?.name}
        </Typography>
        <ContactInfoPopover
          anchorEl={anchorEl}
          author={author}
          authorName={authorName || author?.name}
          onClose={handleClosePopover}
        />
      </Box>
    );
  },
);

ClickableAuthorWithPopover.propTypes = {
  author: authorContactInfo,
  authorName: PropTypes.string,
  className: PropTypes.string,
  typographyVariant: PropTypes.string,
};

ClickableAuthorWithPopover.defaultProps = {
  author: null,
  authorName: null,
  className: 'ayo-post-card__title',
  typographyVariant: 'subtitle2',
};

export default ClickableAuthorWithPopover;
