import React from 'react';
import PropTypes from 'prop-types';

import { isPeriodDisplayed } from '../../../utils';

const CourseDisplayName = ({ className, period }) => (
  <span>
    <span>{className}</span>
    {isPeriodDisplayed(period) && <>&nbsp; {`- ${period}`}</>}
  </span>
);

CourseDisplayName.propTypes = {
  className: PropTypes.string.isRequired,
  period: PropTypes.number,
};

CourseDisplayName.defaultProps = {
  period: null,
};

export default CourseDisplayName;
