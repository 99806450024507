import { useCallback } from 'react';

import useAxios, { StatusCodeMap } from '../HttpClient';
import { answersRoute, apiRoute, followUpRoute } from '../../constants/routes';

const useFollowUpService = () => {
  const { get, post } = useAxios();

  const getFollowUpQuestions = useCallback(
    async (resourceType, lang) => {
      const { data } = await get(
        { enabled: true },
        `${apiRoute}${followUpRoute}/questions?resourceType=${resourceType}&lang=${lang}`,
      );
      return data;
    },
    [get],
  );

  const postFollowUpAnswers = useCallback(
    (answersRequestBody, withErrorHandlers) =>
      post({ enabled: true }, `${apiRoute}${followUpRoute}${answersRoute}`, answersRequestBody, {
        skipDefaultErrorHandlers: withErrorHandlers && [
          StatusCodeMap.NOT_FOUND,
          StatusCodeMap.SERVER_ERROR,
        ],
      }),
    [post],
  );

  return {
    getFollowUpQuestions,
    postFollowUpAnswers,
  };
};

export default useFollowUpService;
