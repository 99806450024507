import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, useMediaQuery, useTheme } from '@mui/material';

import { DynamicGrid } from '../../../../atoms';
import { PortfolioSectionTypes } from '../../../../../constants/enums';
import { ReactComponent as RecommendationsEmptyStateIllustration } from '../../../../../resources/images/portfolio/recommendations_emty_state.svg';
import PortfolioSection from '../../components/portfolio-section/PortfolioSection';
import PortfolioFilePreviewDialog from '../../components/portfolio-file-preview-dialog/PortfolioFilePreviewDialog';
import { InitiativeEmptyStateBlock } from '../../../../moleculas';

import RecommendationCard from './portfolio-recommendation-card/RecommendationCard';

const PortfolioSharedRecommendationsSection = ({ sectionData, token }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpMd = useMediaQuery(theme.breakpoints.up('md'));

  const [isPreviewDialogOpen, setIsPreviewDialogOpen] = useState(false);
  const [activeRecommendationIndex, setActiveRecommendationIndex] = useState(null);

  return (
    <>
      <PortfolioSection
        body={
          <Box mt={3}>
            <DynamicGrid
              columnsCount={isWidthUpMd ? 3 : 2}
              gridItems={sectionData.entities.map((recommendation, index) => (
                <RecommendationCard
                  key={recommendation.id}
                  readHandler={() => {
                    setActiveRecommendationIndex(index);
                    setIsPreviewDialogOpen(true);
                  }}
                  recommendation={recommendation}
                  token={token}
                />
              ))}
              isHorizontalOrder
            />
          </Box>
        }
        className={`${classNames('ayo-full-screen-portfolio-section', {
          'ayo-portfolio-recommendations-section': sectionData.entities.length,
        })}`}
        emptyState={
          !sectionData.entities.length ? (
            <InitiativeEmptyStateBlock
              illustration={<RecommendationsEmptyStateIllustration />}
              title={t('No information about recommendations so far')}
            />
          ) : null
        }
        reflection={sectionData.reflection}
        sectionKey={PortfolioSectionTypes.PORTFOLIO__RECOMMENDATIONS}
        title={t('Recommendations')}
      />
      <PortfolioFilePreviewDialog
        isOpen={isPreviewDialogOpen}
        onClose={() => {
          setIsPreviewDialogOpen(false);
          setActiveRecommendationIndex(null);
        }}
        textJson={sectionData.entities[activeRecommendationIndex]?.reachTextEditorData.textJson}
        title="Recommendation preview"
        token={token}
      />
    </>
  );
};

PortfolioSharedRecommendationsSection.propTypes = {
  sectionData: PropTypes.instanceOf(Object),
  token: PropTypes.string,
};

PortfolioSharedRecommendationsSection.defaultProps = {
  sectionData: {},
  token: null,
};

export default PortfolioSharedRecommendationsSection;
