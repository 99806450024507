import { useCallback } from 'react';

import useAxios, { StatusCodeMap } from '../HttpClient';
import {
  apiRoute,
  coursesRoute,
  lessonsRoute,
  assignmentsRoute,
  teksRoute,
  metadataRoute,
  insightsRoute,
  suggestionsRoute,
  teachersRoute,
  schoolsDaysConfigRoute,
  sharedRoute,
  shareRoute,
  scheduleRoute,
  periodsRoute,
} from '../../constants/routes';

const useTeachersService = () => {
  const { get, httpDelete, post, put } = useAxios();

  const getLessonById = useCallback(
    async (lessonId) => {
      const { data } = await get({ enabled: true }, `${apiRoute}${lessonsRoute}/${lessonId}`);

      return data.entity;
    },
    [get],
  );

  const postLesson = useCallback(
    async (body, disableLoader) => {
      const finalBody = {
        ...body,
        lessonId: body.classMetadata.classId,
        courses: body.classMetadata.classesPeriod.courses,
      };
      const { data } = await post(
        { enabled: !disableLoader },
        `${apiRoute}${lessonsRoute}`,
        finalBody,
        {
          skipDefaultErrorHandlers: [
            StatusCodeMap.BAD_REQUEST,
            StatusCodeMap.NOT_FOUND,
            StatusCodeMap.SERVER_ERROR,
          ],
        },
      );

      return data.entity;
    },
    [post],
  );

  const putLesson = useCallback(
    async (id, body, disableLoader) => {
      const transformedBody = {
        ...body,
        lessonId: body.classMetadata.classId,
        courses: body.classMetadata.classesPeriod.courses,
      };
      const newBody = JSON.parse(JSON.stringify(transformedBody));
      newBody.reachTextEditorData.attachmentsIds = body.reachTextEditorData.attachments.map(
        (item) => item.id,
      );
      newBody.materialsData.attachmentsIds = body.materialsData.attachments.map((item) => item.id);
      newBody.agendaBoardData.agenda.attachmentsIds = body.agendaBoardData.agenda.attachments?.map(
        (item) => item.id,
      );
      newBody.agendaBoardData.teks.attachmentsIds = body.agendaBoardData.teks.attachments?.map(
        (item) => item.id,
      );
      newBody.agendaBoardData.reminders.attachmentsIds =
        body.agendaBoardData.reminders.attachments?.map((item) => item.id);
      delete newBody.reachTextEditorData.attachments;
      delete newBody.materialsData.attachments;
      delete newBody.agendaBoardData.agenda.attachments;
      delete newBody.agendaBoardData.teks.attachments;
      delete newBody.agendaBoardData.reminders.attachments;
      const { data } = await put(
        { enabled: !disableLoader },
        `${apiRoute}${lessonsRoute}/${id}`,
        newBody,
        {
          skipDefaultErrorHandlers: [
            StatusCodeMap.BAD_REQUEST,
            StatusCodeMap.NOT_FOUND,
            StatusCodeMap.SERVER_ERROR,
          ],
        },
      );

      return data.entity;
    },
    [put],
  );

  const deleteLesson = useCallback(
    async (id) =>
      httpDelete({ enabled: true }, `${apiRoute}${lessonsRoute}/${id}`, undefined, {
        skipDefaultErrorHandlers: [
          StatusCodeMap.BAD_REQUEST,
          StatusCodeMap.NOT_FOUND,
          StatusCodeMap.SERVER_ERROR,
        ],
      }),
    [httpDelete],
  );

  const getLessonsMetadata = useCallback(
    async (schoolName) => {
      const { data } = await get(
        { enabled: true },
        `${apiRoute}${lessonsRoute}${metadataRoute}?schoolName=${schoolName}`,
      );

      return data.entity;
    },
    [get],
  );

  const getSharedLessonsMetadata = useCallback(
    async (schoolName) => {
      const { data } = await get(
        { enabled: true },
        `${apiRoute}${lessonsRoute}${metadataRoute}${sharedRoute}?schoolName=${schoolName}`,
      );

      return data.entity;
    },
    [get],
  );

  const getLessonAssignments = useCallback(
    async (id) => {
      const { data } = await get(
        { enabled: true },
        `${apiRoute}${lessonsRoute}/${id}${assignmentsRoute}`,
      );
      return data.entity;
    },
    [get],
  );

  const postLessonAssignments = useCallback(
    async (lessonId, assignments, silent = false) => {
      const newAssignments = assignments.map((assignment) => {
        const newAssignment = JSON.parse(JSON.stringify(assignment));
        newAssignment.attachmentsIds = assignment.attachments.map((attachment) => attachment?.id);
        newAssignment.links = assignment.links.map((link) => link?.url);
        delete newAssignment.attachments;
        return newAssignment;
      });

      const { data } = await post(
        { enabled: !silent },
        `${apiRoute}${lessonsRoute}/${lessonId}${assignmentsRoute}`,
        { classroomAssignments: newAssignments },
        {
          skipDefaultErrorHandlers: [
            StatusCodeMap.BAD_REQUEST,
            StatusCodeMap.NOT_FOUND,
            StatusCodeMap.SERVER_ERROR,
          ],
        },
      );
      return data.entity;
    },
    [post],
  );

  const deleteLessonAssignment = useCallback(
    async (lessonId, assignmentId) =>
      httpDelete(
        { enabled: false },
        `${apiRoute}${lessonsRoute}/${lessonId}${assignmentsRoute}/${assignmentId}`,
        undefined,
        {
          skipDefaultErrorHandlers: [
            StatusCodeMap.BAD_REQUEST,
            StatusCodeMap.NOT_FOUND,
            StatusCodeMap.SERVER_ERROR,
          ],
        },
      ),
    [httpDelete],
  );

  const getTeks = useCallback(
    async (courseNumbers) => {
      const { data } = await get(
        { enabled: true },
        `${apiRoute}${teksRoute}${coursesRoute}/${courseNumbers.join(',')}`,
      );
      return data.entity;
    },
    [get],
  );

  const getSuggestedTeks = useCallback(
    async (courseNumbers, classDate) => {
      const { data } = await get(
        { enabled: true },
        `${apiRoute}${teksRoute}${coursesRoute}/${courseNumbers.join(
          ',',
        )}${suggestionsRoute}?classDate=${classDate}`,
      );
      return data.entity;
    },
    [get],
  );

  const getLessonAssignmentInsights = useCallback(
    async (schoolName, courseNumbers, period, lang, groupBy) => {
      const { data } = await get(
        { enabled: true },
        `${apiRoute}${lessonsRoute}${assignmentsRoute}${insightsRoute}?lang=${lang}&schoolName=${schoolName}&courseNumbers=${courseNumbers.join(
          ',',
        )}&period=${period}${groupBy ? `&groupBy=${groupBy}` : ''}`,
      );
      return data.entity;
    },
    [get],
  );

  const getSchoolsDaysConfig = useCallback(async () => {
    const { data } = await get(
      { enabled: true },
      `${apiRoute}${teachersRoute}${schoolsDaysConfigRoute}`,
    );
    return data.entity;
  }, [get]);

  const getAssignmentChoices = useCallback(
    async (lessonId, assignmentId) => {
      const { data } = await get(
        { enabled: true },
        `${apiRoute}${lessonsRoute}/${lessonId}${assignmentsRoute}/${assignmentId}/choices`,
      );
      return data.entity;
    },
    [get],
  );

  const postAssignmentChoices = useCallback(
    async (lessonId, assignmentId, choices, silent) => {
      const { data } = await post(
        { enabled: !silent },
        `${apiRoute}${lessonsRoute}/${lessonId}${assignmentsRoute}/${assignmentId}/choices`,
        choices,
      );
      return data.entity;
    },
    [post],
  );

  const putAssignmentChoices = useCallback(
    async (lessonId, assignmentId, choices, silent) => {
      const { data } = await put(
        { enabled: !silent },
        `${apiRoute}${lessonsRoute}/${lessonId}${assignmentsRoute}/${assignmentId}/choices/${choices.id}`,
        choices,
      );
      return data.entity;
    },
    [put],
  );

  const deleteAssignmentChoices = useCallback(
    async (lessonId, assignmentId, choicesId) => {
      const { data } = await httpDelete(
        { enabled: true },
        `${apiRoute}${lessonsRoute}/${lessonId}${assignmentsRoute}/${assignmentId}/choices/${choicesId}`,
      );
      return data.entity;
    },
    [httpDelete],
  );

  const deleteAssignmentChoicesLayer = useCallback(
    async (lessonId, assignmentId, choicesId, layerId) => {
      const { data } = await httpDelete(
        { enabled: true },
        `${apiRoute}${lessonsRoute}/${lessonId}${assignmentsRoute}/${assignmentId}/choices/${choicesId}/layers/${layerId}`,
      );
      return data.entity;
    },
    [httpDelete],
  );

  const postChoicesSelected = useCallback(
    (lessonId, assignmentId, choicesId, selectedInfo) =>
      post(
        { enabled: false },
        `${apiRoute}${lessonsRoute}/${lessonId}${assignmentsRoute}/${assignmentId}/choices/${choicesId}/selected`,
        selectedInfo,
        {
          skipDefaultErrorHandlers: [
            StatusCodeMap.BAD_REQUEST,
            StatusCodeMap.NOT_FOUND,
            StatusCodeMap.SERVER_ERROR,
          ],
        },
      ),
    [post],
  );

  const postLessonShare = useCallback(
    (lessonId, body) =>
      post({ enabled: true }, `${apiRoute}${lessonsRoute}/${lessonId}${shareRoute}`, body, {
        skipDefaultErrorHandlers: [
          StatusCodeMap.BAD_REQUEST,
          StatusCodeMap.NOT_FOUND,
          StatusCodeMap.SERVER_ERROR,
        ],
      }),
    [post],
  );

  const getSchedulePeriods = useCallback(
    async (scheduleId, loaderText) => {
      const { data } = await get(
        { enabled: true, loaderText },
        `${apiRoute}${lessonsRoute}${scheduleRoute}/${scheduleId}${periodsRoute}`,
      );
      return data.entity;
    },
    [get],
  );

  return {
    getLessonById,
    postLesson,
    putLesson,
    deleteLesson,
    getLessonsMetadata,
    getSharedLessonsMetadata,
    getLessonAssignments,
    postLessonAssignments,
    deleteLessonAssignment,
    getTeks,
    getSuggestedTeks,
    getLessonAssignmentInsights,
    getSchoolsDaysConfig,
    postAssignmentChoices,
    putAssignmentChoices,
    getAssignmentChoices,
    deleteAssignmentChoices,
    deleteAssignmentChoicesLayer,
    postChoicesSelected,
    postLessonShare,
    getSchedulePeriods,
  };
};

export default useTeachersService;
