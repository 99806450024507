import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Box, Divider } from '@mui/material';

import { Button } from '../../../../atoms';
import AssignmentNoEditTooltip from '../assignment-no-edit-tooltip/AssignmentNoEditTooltip';
import { ReactComponent as CloseIcon } from '../../../../../resources/icons/close.svg';
import { ReactComponent as ExpandIcon } from '../../../../../resources/icons/chevron_down.svg';
import { ReactComponent as CollapseIcon } from '../../../../../resources/icons/chevron_up.svg';

const AssignmentsActionButtons = ({
  downButtonHandler,
  upButtonHandler,
  isLast,
  isFirst,
  upButtonAriaLabel,
  downButtonAriaLabel,
  deleteButtonAriaLabel,
  deleteOptionHandler,
  disabled,
  deleteDisabled,
}) => {
  const { t } = useTranslation();
  return (
    <Box display="flex">
      <AssignmentNoEditTooltip showTooltip={disabled}>
        <Button
          aria-label={t(deleteButtonAriaLabel)}
          disabled={disabled || deleteDisabled}
          isIconButton
          onClick={deleteOptionHandler}
        >
          <CloseIcon height="24px" width="24px" />
        </Button>
      </AssignmentNoEditTooltip>
      <Divider
        className="ayo-lesson-assignment-action-buttons__divider"
        flexItem
        orientation="vertical"
      />
      <AssignmentNoEditTooltip showTooltip={disabled}>
        <Button
          aria-label={t(downButtonAriaLabel)}
          disabled={disabled || isLast}
          gaLabel={downButtonAriaLabel}
          isIconButton
          onClick={downButtonHandler}
        >
          <ExpandIcon />
        </Button>
      </AssignmentNoEditTooltip>
      <Box ml={2}>
        <AssignmentNoEditTooltip showTooltip={disabled}>
          <Button
            aria-label={t(upButtonAriaLabel)}
            disabled={disabled || isFirst}
            gaLabel={upButtonAriaLabel}
            isIconButton
            onClick={upButtonHandler}
          >
            <CollapseIcon />
          </Button>
        </AssignmentNoEditTooltip>
      </Box>
    </Box>
  );
};

AssignmentsActionButtons.propTypes = {
  isLast: PropTypes.bool,
  isFirst: PropTypes.bool,
  upButtonAriaLabel: PropTypes.string,
  downButtonAriaLabel: PropTypes.string,
  deleteButtonAriaLabel: PropTypes.string,
  deleteOptionHandler: PropTypes.func.isRequired,
  downButtonHandler: PropTypes.func.isRequired,
  upButtonHandler: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  deleteDisabled: PropTypes.bool,
};

AssignmentsActionButtons.defaultProps = {
  isLast: false,
  isFirst: false,
  upButtonAriaLabel: 'Move up',
  downButtonAriaLabel: 'Move down',
  deleteButtonAriaLabel: 'Delete',
  disabled: false,
  deleteDisabled: false,
};

export default AssignmentsActionButtons;
