import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

import { Tooltip, Typography, Link } from '../../../atoms';

const LinkWithTooltip = ({ tooltipPlacement }) => {
  const { t } = useTranslation();

  const [isTooltipOpened, setIsTooltipOpened] = useState(false);

  const toggleTooltip = useCallback(() => setIsTooltipOpened(!isTooltipOpened), [isTooltipOpened]);

  const closeTooltip = useCallback(() => setIsTooltipOpened(false), []);

  return (
    <Tooltip
      className="ayo-link-with-tooltip"
      disableFocusListener
      disableHoverListener
      onClickAway={closeTooltip}
      onClose={closeTooltip}
      open={isTooltipOpened}
      placement={tooltipPlacement}
      title={
        <Box>
          <Typography display="inline" variant="caption">
            {t(
              "This may have happened because you haven't provided consent for your child to use AYO, your child isn't enrolled in Mesquite ISD, or AYO needs some time to load information about your child. Please contact your child's school to provide consent to utilize AYO",
            )}
          </Typography>
        </Box>
      }
      variant="light"
    >
      <div>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link component="button" gaLabel="Haven’t found children..." onClick={toggleTooltip}>
          {t('Haven’t found any of your kids in the list?')}
        </Link>
      </div>
    </Tooltip>
  );
};

LinkWithTooltip.propTypes = {
  tooltipPlacement: PropTypes.string,
};

LinkWithTooltip.defaultProps = {
  tooltipPlacement: 'bottom',
};

export default LinkWithTooltip;
