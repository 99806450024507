import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { SkeletonMask, Typography } from '../../atoms';
import {
  AchievementCard,
  InformationalCaption,
  InitiativeEmptyStateBlock,
  NewFeatureHotspot,
} from '../../moleculas';
import { useLazyDataLoad } from '../../../hooks';
import { useStudentsService } from '../../../services';
import { NewFeaturesIdsMap } from '../../../tours/common/NewFeaturesItemsProvider';
import { convertDataToAchievement } from '../../../utils';
import { ReactComponent as AchievementsProfileImage } from '../../../resources/images/achievements_profile_image.svg';

const INFO_TOOLTIP_MAX_WIDTH_SM = 340;

const AchievementsSection = ({ isLazyLoad, studentId, titleVariant, title }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  const [achievements, setAchievements] = useState([]);
  const { getStudentAchievements } = useStudentsService();

  const loadStudentAchievements = useCallback(
    (onLoad) => {
      getStudentAchievements(studentId, isLazyLoad).then((studentAchievements) => {
        if (studentAchievements.length) {
          setAchievements(
            studentAchievements.map((achievement) => convertDataToAchievement(achievement)),
          );
        }
        if (onLoad) {
          onLoad();
        }
      });
    },
    [getStudentAchievements, isLazyLoad, studentId],
  );

  const { isDataLoaded, loadActivatorRef } = useLazyDataLoad(
    () =>
      new Promise((resolve) => {
        loadStudentAchievements(resolve);
      }),
  );

  useEffect(() => {
    if (!isLazyLoad) {
      loadStudentAchievements();
    }
  }, [loadStudentAchievements, isLazyLoad, studentId]);

  return (
    <Box
      className="ayo-achievements-section"
      mb={5}
      mr={achievements.length && !isWidthUpSm ? 2 : 0}
    >
      <Box
        ref={isLazyLoad ? loadActivatorRef : null}
        className={classnames(
          'ayo-achievements-section__title',
          `ayo-achievements-section__title--${titleVariant}`,
          { 'ayo-achievements-section__title__empty-state': !achievements.length },
        )}
        mb={3}
      >
        <Typography
          component={titleVariant === 'subtitle1' ? 'h3' : 'h2'}
          display="inline"
          variant={titleVariant}
        >
          {t(title)}
        </Typography>
        <Box alignItems={!!achievements.length && 'center'} display="inline-flex">
          {!!achievements.length && (
            <InformationalCaption
              showTooltip
              title="The year dates are represented in the school year format"
              tooltipMaxWidth={isWidthUpSm ? null : INFO_TOOLTIP_MAX_WIDTH_SM}
              tooltipPlacement={isWidthUpSm ? 'bottom-start' : 'bottom'}
            />
          )}
          <span>
            <NewFeatureHotspot id={NewFeaturesIdsMap.ACHIEVEMENTS} isClickable />
          </span>
        </Box>
      </Box>
      {achievements.length ? (
        <>
          <Grid container spacing={3}>
            {achievements.map((achievement) => (
              <Grid key={achievement.name} item md={4} sm={6} xs={12}>
                <AchievementCard achievementData={achievement} titleVariant={titleVariant} />
              </Grid>
            ))}
          </Grid>
        </>
      ) : isLazyLoad && !isDataLoaded ? (
        <SkeletonMask />
      ) : (
        <InitiativeEmptyStateBlock
          illustration={<AchievementsProfileImage alt={t('A hand holding a trophy')} />}
          title={t('No information about achievements and participation so far')}
        />
      )}
    </Box>
  );
};

AchievementsSection.propTypes = {
  isLazyLoad: PropTypes.bool,
  studentId: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  titleVariant: PropTypes.string,
};

AchievementsSection.defaultProps = {
  isLazyLoad: false,
  titleVariant: 'subtitle1',
};

export default AchievementsSection;
