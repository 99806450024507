import React from 'react';
import PropTypes from 'prop-types';

import { Chip } from '../../../../moleculas';
import { interestEmojis } from '../../../../../constants/emojis';
import { AssignmentInsightsCategories } from '../../../../../constants/enums';
import { assignmentInsightGroupnameType } from '../../../../../constants/propTypes';

const getGroupNameEmoji = (type, groupName) => {
  switch (type) {
    case AssignmentInsightsCategories.INTERESTS:
      return interestEmojis[groupName.nameKey];
    default:
      return null;
  }
};

const AssignmentGroupNameChip = ({ type, groupName }) => (
  <Chip emoji={getGroupNameEmoji(type, groupName)} label={groupName.value} />
);

AssignmentGroupNameChip.propTypes = {
  type: PropTypes.string.isRequired,
  groupName: PropTypes.arrayOf(PropTypes.shape(assignmentInsightGroupnameType)).isRequired,
};

export default AssignmentGroupNameChip;
