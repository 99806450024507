import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

import { Button, Typography } from '../../../atoms';
import { InitiativeEmptyStateBlock, NewFeatureHotspot } from '../../../moleculas';
import { ClubsMembershipStatuses } from '../../../../constants/club-hub';
import { RolesMap } from '../../../../constants/enums';
import { clubHubRoute } from '../../../../constants/routes';
import { UserContext } from '../../../../context';
import { useClubHubService } from '../../../../services';
import { sortArrayByKey } from '../../../../utils';
import { ReactComponent as ClubHubEmptyState } from '../../../../resources/images/club_hub_profile_empty_state.svg';
import { ReactComponent as ClubHubIcon } from '../../../../resources/icons/club_hub.svg';
import { NewFeaturesIdsMap } from '../../../../tours/common/NewFeaturesItemsProvider';

import ClubsChipsBlocks from './components/clubs-chips-blocks/ClubsChipsBlocks';
import ClubsCardsBlocks from './components/clubs-cards-blocks/ClubsCardsBlocks';

const ClubsEmptyStateConfig = {
  [RolesMap.STUDENT]: {
    title: 'Let’s explore the school clubs available for you!',
    body: 'There may be something interesting for you',
  },
  [RolesMap.TEACHER]: {
    title: 'No information about clubs so far',
  },
  [RolesMap.ADMINISTRATOR]: {
    title: 'No information about clubs so far',
  },
  [RolesMap.GUARDIAN]: {
    title: 'No information about your child’s clubs so far',
  },
};

const ClubHubSection = ({ studentId }) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  const { state: userState } = useContext(UserContext);
  const { role } = userState.profile;
  const isStudent = role === RolesMap.STUDENT;

  const [joinedClubs, setJoinedClubs] = useState([]);
  const [requestedClubs, setRequestedClubs] = useState([]);

  const { getStudentClubs } = useClubHubService();

  useEffect(() => {
    getStudentClubs(studentId).then((data) => {
      const sortedClubs = sortArrayByKey(data, 'membershipChangeDate', 'date');
      setJoinedClubs(
        sortedClubs.filter(
          ({ membershipStatus }) => membershipStatus === ClubsMembershipStatuses.JOINED,
        ),
      );
      setRequestedClubs(
        sortedClubs.filter(
          ({ membershipStatus }) => membershipStatus !== ClubsMembershipStatuses.JOINED,
        ),
      );
    });
  }, [getStudentClubs, studentId]);

  const hasClubs = useMemo(
    () => !!joinedClubs.length || !!requestedClubs.length,
    [joinedClubs.length, requestedClubs.length],
  );

  return (
    <Box className="ayo-club-sections" mb={isStudent ? 10 : 0}>
      <Box
        display="flex"
        flexDirection={isWidthUpSm ? 'row' : 'column'}
        justifyContent="space-between"
        mb={3}
      >
        <Box alignItems="center" display="flex" flexDirection="row">
          <Typography variant={isStudent ? 'h2' : 'subtitle1'}>
            {t(isStudent ? 'My clubs' : 'Clubs')}
          </Typography>
          <NewFeatureHotspot
            id={NewFeaturesIdsMap.CLUB_HUB_STUDENT_PROFILE}
            isClickable
            label={t('Clubs')}
          />
        </Box>
        {isStudent && hasClubs && (
          <Button
            component={RouterLink}
            endIcon={<ClubHubIcon />}
            gaLabel="View in Club Hub"
            to={clubHubRoute}
          >
            {t('View in Club Hub')}
          </Button>
        )}
      </Box>
      {hasClubs ? (
        <>
          {isStudent ? (
            <ClubsChipsBlocks joinedClubs={joinedClubs} requestedClubs={requestedClubs} />
          ) : (
            <ClubsCardsBlocks
              joinedClubs={joinedClubs}
              requestedClubs={requestedClubs}
              studentId={studentId}
            />
          )}
        </>
      ) : (
        <InitiativeEmptyStateBlock
          body={isStudent ? t(ClubsEmptyStateConfig[role].body) : ''}
          buttonContent={t('Explore clubs in Club Hub')}
          buttonGaLabel="Explore clubs in Club Hub"
          buttonLink={clubHubRoute}
          illustration={<ClubHubEmptyState />}
          showNavigationButton={isStudent}
          title={t(ClubsEmptyStateConfig[role].title)}
        />
      )}
    </Box>
  );
};

ClubHubSection.propTypes = {
  studentId: PropTypes.number.isRequired,
};

export default ClubHubSection;
