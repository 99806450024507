import React from 'react';
import PropTypes from 'prop-types';

import { AchievementsCategories, HighlightsInitiatives } from '../../../../constants/enums';
import TopFiltersList from '../components/TopFiltersList/TopFiltersList';

const defaultAchievementStats = Object.values(AchievementsCategories).reduce(
  (stats, category) => ({ ...stats, [category]: 0 }),
  {},
);

const AchievementsFilters = ({ menuFilters, setMenuFilters, stats, totalStudentsCount }) => (
  <TopFiltersList
    defaultStats={defaultAchievementStats}
    initiative={HighlightsInitiatives.ACHIEVEMENTS}
    menuFilters={menuFilters}
    setMenuFilters={setMenuFilters}
    stats={stats}
    title="Distribution by achievement categories"
    totalStudentsCount={totalStudentsCount}
  />
);

AchievementsFilters.propTypes = {
  menuFilters: PropTypes.instanceOf(Object).isRequired,
  setMenuFilters: PropTypes.func.isRequired,
  stats: PropTypes.instanceOf(Object).isRequired,
  totalStudentsCount: PropTypes.number.isRequired,
};

export default AchievementsFilters;
