import { useCallback } from 'react';

import useAxios from '../HttpClient';
import { apiRoute, analyticsApiRoute, highlightsRoute, QueryParams } from '../../constants/routes';

const useAnalyticsService = () => {
  const { get } = useAxios();
  const getHighlights = useCallback(
    async (initiative, lang, currentCampus) => {
      const { data } = await get(
        { enabled: true },
        `${apiRoute}${analyticsApiRoute}${highlightsRoute}${initiative ? `/${initiative}` : ''}?${
          QueryParams.LANG
        }=${lang}&schoolName=${currentCampus}`,
      );
      return data.entity;
    },
    [get],
  );

  const getDistrictReport = useCallback(
    async (analyticType, timerPeriod, lang) => {
      const { data } = await get(
        { enabled: true },
        `${apiRoute}${analyticsApiRoute}/district/reports?analyticType=${analyticType}&timePeriod=${timerPeriod}${
          lang ? `&lang=${lang}` : ''
        }`,
      );

      return data.entity;
    },
    [get],
  );

  return { getHighlights, getDistrictReport };
};

export default useAnalyticsService;
