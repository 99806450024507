import React, { useCallback, useContext, useRef, useState } from 'react';
import {
  Box,
  ClickAwayListener,
  Grid,
  Popper,
  Step,
  StepButton,
  Stepper,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import { Button, Card, Tooltip, Typography } from '../../../../../atoms';
import {
  Chip,
  InformationalCaption,
  InformationalMessage,
  InitiativeEmptyStateBlock,
} from '../../../../../moleculas';
import { choicesPathType } from '../../../../../../constants/propTypes';
import { RichTextEditor } from '../../../../../organisms';
import { GA, getIsDomElementCropped } from '../../../../../../utils';
import { ReactComponent as ChevronLeftIcon } from '../../../../../../resources/icons/chevron_left.svg';
import { ReactComponent as ChevronRightIcon } from '../../../../../../resources/icons/chevron_right.svg';
import { ReactComponent as CurlyArrow } from '../../../../../../resources/icons/arrow_right_curly.svg';
import { ReactComponent as CurlyArrowBottom } from '../../../../../../resources/icons/arrow_bottom_curly.svg';
import { ReactComponent as ScatchArrow } from '../../../../../../resources/icons/arrow_right_scatch.svg';
import { ReactComponent as ScatchArrowBottom } from '../../../../../../resources/icons/arrow_bottom_scatch.svg';
import { ReactComponent as CheckCircle } from '../../../../../../resources/icons/check_circle.svg';
import { ReactComponent as DeleteIcon } from '../../../../../../resources/icons/close_red.svg';
import { ReactComponent as DeletedItemIcon } from '../../../../../../resources/icons/delete.svg';
import { ReactComponent as EditItemIcon } from '../../../../../../resources/icons/error_outline.svg';
import { ReactComponent as ChoicesAvailableIllustration } from '../../../../../../resources/images/choices_available.svg';
import { ReactComponent as NoChoicesIllustration } from '../../../../../../resources/images/choices-path/no_choices_selected.svg';
import {
  ChoiceOptionsColorVariants,
  convertChoiceColorToShadowColor,
} from '../../assignment-insights/shared';
import ChoicesPathOptionBubble from '../../choices-path-option-bubble/ChoicesPathOptionBubble';
import {
  GaActions,
  GaCategories,
  NotificationResourcesMap,
  NotificationSourcesMap,
} from '../../../../../../constants/enums';
import { AppActions, AppContext } from '../../../../../../context';
import { useNotificationsData } from '../../../../../../hooks';

const FullOptionTextPopper = ({ anchorEl, isOpen, text }) => (
  <Popper
    anchorEl={anchorEl}
    className="choices-path-display__full-text__popover"
    disablePortal
    open={isOpen}
    placement="bottom-end"
  >
    <Card mainContent={<RichTextEditor isStatic value={text} />} />
  </Popper>
);

FullOptionTextPopper.propTypes = {
  anchorEl: PropTypes.element,
  isOpen: PropTypes.bool,
  text: PropTypes.string.isRequired,
};

FullOptionTextPopper.defaultProps = {
  anchorEl: null,
  isOpen: false,
};

const OptionBubbleWithFullView = ({ text, color, variant, onClick, isSelected }) => {
  const [isTextCropped, setIsTextCropped] = useState(false);
  const [popperAnchorEl, setPopperAnchorEl] = useState(null);
  const showMoreRef = useRef();
  const { t } = useTranslation();

  const calculateCropped = (node) => {
    if (node !== null) {
      const editorElement = node.querySelector('.ql-editor');
      setIsTextCropped(
        editorElement
          ? getIsDomElementCropped(editorElement.scrollHeight, 0, editorElement.clientHeight)
          : false,
      );
    }
  };

  return (
    <>
      <ChoicesPathOptionBubble
        ref={calculateCropped}
        additionalContent={
          isTextCropped && (
            <ClickAwayListener
              onClickAway={() => {
                if (popperAnchorEl) {
                  setPopperAnchorEl(null);
                }
              }}
            >
              <div>
                <Button
                  ref={showMoreRef}
                  className="choices-path__option__show-more"
                  gaLabel="Show more choice option"
                  onClick={(e) => {
                    setPopperAnchorEl(popperAnchorEl ? null : showMoreRef.current);
                    e.stopPropagation();
                  }}
                >
                  {t('Show more')}
                </Button>
              </div>
            </ClickAwayListener>
          )
        }
        color={color}
        controlIcons={
          onClick ? (
            <>
              <CheckCircle className="checked-icon" />
              <DeleteIcon className="delete-icon" />
            </>
          ) : null
        }
        isSelected={isSelected}
        onClick={onClick}
        text={text}
        variant={variant}
      />
      <FullOptionTextPopper anchorEl={popperAnchorEl} isOpen={!!popperAnchorEl} text={text} />
    </>
  );
};

OptionBubbleWithFullView.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  variant: PropTypes.string,
  onClick: PropTypes.func,
  isSelected: PropTypes.bool,
};

OptionBubbleWithFullView.defaultProps = {
  variant: 'dark',
  onClick: null,
  isSelected: false,
};

const ChoicesPathStepperControllButton = ({ ariaLabel, children, onClick, isHidden, gaLabel }) => (
  <Button
    aria-label={ariaLabel}
    className="choices-path-display__stepper-controll-button"
    gaLabel={gaLabel}
    isHidden={isHidden}
    isIconButton
    onClick={onClick}
    variant="secondary"
  >
    {children}
  </Button>
);

ChoicesPathStepperControllButton.propTypes = {
  ariaLabel: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  isHidden: PropTypes.bool,
  gaLabel: PropTypes.string.isRequired,
};

ChoicesPathStepperControllButton.defaultProps = {
  isHidden: false,
};

const SubmittedChoiceBubble = ({ text, color }) => {
  const bubbleRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <ClickAwayListener
        onClickAway={() => {
          if (isOpen) {
            setIsOpen(false);
          }
        }}
      >
        <Box
          ref={bubbleRef}
          className="choices-path-submitted__bubble"
          onClick={() => setIsOpen((prevOpen) => !prevOpen)}
          style={{
            '--main-color': color,
            '--main-shadow-color': convertChoiceColorToShadowColor(color),
          }}
          tabIndex="0"
        />
      </ClickAwayListener>
      <FullOptionTextPopper anchorEl={bubbleRef.current} isOpen={isOpen} text={text} />
    </>
  );
};

SubmittedChoiceBubble.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

const hasChoicesSelected = (choices) =>
  choices.layers.some((layer) => layer.options.some((option) => option.isSelected));

const DisplayAssignmentChoicesPath = ({
  choices,
  canSelectChoices,
  onChoicesChange,
  onChoicesSelectedChange,
  assignmentEndDate,
  assignmentId,
}) => {
  const { t } = useTranslation();
  const [activeLayerIdx, setActiveLayerIdx] = useState(0);
  const activeLayer = choices?.layers[activeLayerIdx];

  const mainViewRef = useRef();

  const scrollContainerIntoView = useCallback(() => {
    mainViewRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, []);

  const onNextLayerClick = useCallback(() => {
    setActiveLayerIdx((prevIndex) => prevIndex + 1);
    scrollContainerIntoView();
  }, [scrollContainerIntoView]);

  const onPreviousLayerClick = useCallback(() => {
    setActiveLayerIdx((prevIndex) => prevIndex - 1);
    scrollContainerIntoView();
  }, [scrollContainerIntoView]);

  const isNewChoicesBoard = choices && !choices.isStarted;

  const { dispatch: dispatchAppContext } = useContext(AppContext);

  const showSuccessSnackbar = useCallback(
    (text) =>
      dispatchAppContext({ type: AppActions.SET_SNACKBAR_STATUS, data: { type: 'success', text } }),
    [dispatchAppContext],
  );

  const onSubmitReset = useCallback(() => {
    onChoicesChange({ ...choices, isSubmitted: false });
    setActiveLayerIdx(0);
    onChoicesSelectedChange(choices.id, {
      isSubmitted: false,
      removedOptions: [],
      selectedOptions: [],
    });
  }, [choices, onChoicesChange, onChoicesSelectedChange]);

  const onChoiceStart = useCallback(() => {
    onChoicesChange({ ...choices, isStarted: true });
    onChoicesSelectedChange(choices.id, {
      isSubmitted: false,
      removedOptions: [],
      selectedOptions: [],
    });
  }, [choices, onChoicesSelectedChange, onChoicesChange]);

  const onChoicesSubmit = useCallback(() => {
    onChoicesChange({ ...choices, isSubmitted: true });
    onChoicesSelectedChange(choices.id, {
      isSubmitted: true,
      removedOptions: [],
      selectedOptions: [],
    }).then(() => {
      showSuccessSnackbar(
        t(
          'Your choices have been successfully submitted. Have a look at your choices path journey!',
        ),
      );
    });
  }, [choices, onChoicesChange, onChoicesSelectedChange, showSuccessSnackbar, t]);

  const { notificationsList, updateNotificationItems } = useNotificationsData();

  const allChoiceBoardsNotifications = notificationsList?.WEB_HIDDEN?.filter(
    (notification) => notification.resource === NotificationResourcesMap.CHOICE_BOARDS_HIDDEN,
  );

  const unreadEditPathHiddenNotifications = allChoiceBoardsNotifications?.filter(
    (notification) =>
      notification.resourceId === assignmentId &&
      notification.action === 'UPDATE' &&
      !notification.markedAsRead,
  );

  const unreadDeletePathHiddenNotifications = allChoiceBoardsNotifications?.filter(
    (notification) =>
      notification.resourceId === assignmentId &&
      notification.action === 'DELETE' &&
      !notification.markedAsRead,
  );

  const theme = useTheme();

  const isWidthUpLg = useMediaQuery(theme.breakpoints.up('lg'));

  if (!choices && unreadDeletePathHiddenNotifications?.length) {
    return (
      <Box>
        <Typography mb={3} variant="subtitle1">
          {t(canSelectChoices ? 'My choices path' : 'Choices path')}
        </Typography>
        <Box mb={3}>
          <InformationalMessage
            icon={<DeletedItemIcon />}
            onClick={() =>
              updateNotificationItems(
                unreadDeletePathHiddenNotifications.map((notification) => ({
                  ...notification,
                  markedAsRead: true,
                })),
                NotificationSourcesMap.WEB_HIDDEN,
              )
            }
            text={t('The teacher has deleted the choices path. It’s not available anymore.')}
          />
        </Box>
      </Box>
    );
  }

  if (!choices) return null;

  const isFirstLayerActive = activeLayerIdx === 0;
  const isLastLayerActive = activeLayerIdx === choices.layers.length - 1;

  const onChoiceToggle = (layerIdx, optionIdx) => {
    const newLayers = [...choices.layers];
    const newLayer = { ...newLayers[layerIdx] };
    const newOptions = [...newLayer.options];
    const oldOption = newOptions[optionIdx];
    let selectedOptions = [];
    let removedOptions = [];
    if (!choices.isMultipleOptions) {
      const alreadySelectedOptionIdx = newOptions.findIndex((option) => option.isSelected);
      if (alreadySelectedOptionIdx !== -1) {
        const alreadySelectedOption = newOptions[alreadySelectedOptionIdx];
        removedOptions = [alreadySelectedOption.id];
        newOptions[alreadySelectedOptionIdx] = { ...alreadySelectedOption, isSelected: false };
      }
    }
    if (oldOption.isSelected) {
      removedOptions = [oldOption.id];
    } else {
      selectedOptions = [oldOption.id];
    }
    newOptions[optionIdx] = { ...oldOption, isSelected: !oldOption.isSelected };
    newLayer.options = newOptions;
    newLayers[layerIdx] = newLayer;
    onChoicesChange({ ...choices, layers: newLayers });
    onChoicesSelectedChange(choices.id, {
      selectedOptions,
      removedOptions,
      isSubmitted: false,
    }).then(() => {
      showSuccessSnackbar(
        oldOption.isSelected
          ? t('The choice has been successfully unselected!')
          : t('The choice has been successfully selected!'),
      );
    });
  };

  const layersGrids = choices.layers.map((layer) => (
    <Grid key={layer.id} container item justifyContent="center" lg={3} xs={12}>
      <Grid className="choices-path-submitted__layer" item lg={12} md={4} xs={6}>
        {layer.options
          .filter((choice) => choice.isSelected)
          .map((choice) => (
            <Box key={choice.id} className="choices-path-submitted__layer__item">
              <SubmittedChoiceBubble color={choice.color} text={choice.textJson} />
            </Box>
          ))}
      </Grid>
    </Grid>
  ));

  if (layersGrids.length > 1) {
    layersGrids.splice(1, 0, isWidthUpLg ? <CurlyArrow /> : <CurlyArrowBottom />);
  }

  if (layersGrids.length > 3) {
    layersGrids.splice(3, 0, isWidthUpLg ? <ScatchArrow /> : <ScatchArrowBottom />);
  }

  const hasSelectedChoices = hasChoicesSelected(choices);

  const isChoicesSubmissionDateEnded = dayjs().isAfter(dayjs(assignmentEndDate));
  const isChoiceSubmitted = choices.isSubmitted || isChoicesSubmissionDateEnded;

  const submittedEmptyView = (
    <>
      <NoChoicesIllustration />
      <Typography align="center" maxWidth="50%" mb={5} mt={3} variant="body2">
        {isChoicesSubmissionDateEnded
          ? t('You haven’t made any choices for this assignment.')
          : t(
              "You haven't made any choices for this assignment. You still have some time to do that until the submission date!",
            )}
      </Typography>
      {!isChoicesSubmissionDateEnded && (
        <Button
          gaLabel="Student choices selection - Select choices "
          onClick={onSubmitReset}
          variant="dark"
        >
          {t('Select choices')}
        </Button>
      )}
    </>
  );

  const submittedView = (
    <Box className="choices-path-submitted__container">
      {hasSelectedChoices ? (
        <>
          <Box mb={1}>
            <Typography variant="subtitle1">{t('Well done!')}</Typography>
          </Box>
          <Box mb={5}>
            <Typography variant="body2">{t('Hopefully, you enjoyed your journey!')}</Typography>
          </Box>
          <Grid alignItems="center" container justifyContent="center" mb={5} rowGap={3}>
            {layersGrids}
          </Grid>
          <Box mb={5}>
            <Typography variant="caption">
              {t('This is your path. Click on a bubble to see the choice details.')}
            </Typography>
          </Box>
          {!isChoicesSubmissionDateEnded && (
            <Tooltip title={t('Go back to your choices and change the path.')} variant="dark">
              <Button
                gaLabel="Student choices selection - My path looks different"
                onClick={onSubmitReset}
                variant="dark"
              >
                {t('My path looks different')}
              </Button>
            </Tooltip>
          )}
        </>
      ) : (
        submittedEmptyView
      )}
    </Box>
  );

  const onPathUpdateNotificationClick = () => {
    if (!unreadEditPathHiddenNotifications?.length) return;
    updateNotificationItems(
      unreadEditPathHiddenNotifications.map((notification) => ({
        ...notification,
        markedAsRead: true,
      })),
      NotificationSourcesMap.WEB_HIDDEN,
    );
  };

  const nextButton = canSelectChoices ? (
    <Button
      className="choices-path-display__primary-control"
      gaLabel="Next choices path layer"
      onClick={() => {
        onNextLayerClick();
        onPathUpdateNotificationClick();
      }}
      variant="primary"
    >
      {t('Next')}
    </Button>
  ) : (
    <ChoicesPathStepperControllButton
      ariaLabel={t('Next')}
      gaLabel="Next choices path layer"
      isHidden={isLastLayerActive}
      onClick={onNextLayerClick}
    >
      <ChevronRightIcon />
    </ChoicesPathStepperControllButton>
  );

  const infoCaptionPerson = canSelectChoices ? 'You' : 'Students';

  return (
    <Box>
      <Box alignItems="center" display="flex" gap={1} mb={2}>
        <Typography variant="subtitle1">
          {t(canSelectChoices ? 'My choices path' : 'Choices path')}
        </Typography>
        {canSelectChoices && !isChoicesSubmissionDateEnded && (
          <Chip className="choices-path-display__action-chip" label={t('Action required')} />
        )}
      </Box>
      {canSelectChoices && !isChoicesSubmissionDateEnded && (
        <Typography my={2} variant="body2">
          {t('Please select how you’re going to perform the assignment.')}
        </Typography>
      )}
      {!isChoicesSubmissionDateEnded && (
        <Box mb={4}>
          <InformationalCaption
            title={t(
              choices.isMultipleOptions
                ? `${infoCaptionPerson} can select multiple choice options in each section.`
                : `${infoCaptionPerson} can select one choice option in each section.`,
            )}
          />
        </Box>
      )}
      {!!unreadEditPathHiddenNotifications?.length && (
        <Box mb={3}>
          <InformationalMessage
            icon={<EditItemIcon className="choice-boards__update-notification__icon" />}
            onClick={() => {
              updateNotificationItems(
                unreadEditPathHiddenNotifications.map((notification) => ({
                  ...notification,
                  markedAsRead: true,
                })),
                NotificationSourcesMap.WEB_HIDDEN,
              );
            }}
            text={t(
              "The teacher has made some changes to the choices path. You'll need to refresh the page to see them. Please review the changes and adjust accordingly.",
            )}
          />
        </Box>
      )}
      {canSelectChoices && isChoiceSubmitted ? (
        submittedView
      ) : isNewChoicesBoard && canSelectChoices ? (
        <InitiativeEmptyStateBlock
          body={t(
            'The teacher has prepared some suggestions on how you can perform this assignment. Check them and track your progress in AYO!',
          )}
          customButton={
            <Button
              fullWidth
              gaLabel="My path looks start"
              onClick={onChoiceStart}
              variant="primary"
            >
              {t('Start!')}
            </Button>
          }
          illustration={<ChoicesAvailableIllustration />}
        />
      ) : (
        <Box
          ref={mainViewRef}
          className={classNames('choices-path-display__container', `layer-${activeLayerIdx}`)}
        >
          <Box mb={5}>
            <Stepper activeStep={activeLayerIdx} alternativeLabel nonLinear>
              {choices.layers.map((layer, idx) => {
                const hasSelected = layer.options.some((option) => option.isSelected);
                const isCompleted = activeLayerIdx > idx && hasSelected;
                return (
                  <Step key={layer.layer} completed={isCompleted}>
                    <StepButton
                      icon={isCompleted ? <CheckCircle /> : null}
                      onClick={() => {
                        setActiveLayerIdx(idx);
                        onPathUpdateNotificationClick();
                      }}
                    >
                      <Typography variant="label-highlighted">{layer.title} </Typography>
                    </StepButton>
                  </Step>
                );
              })}
            </Stepper>
          </Box>
          <Box
            alignItems="center"
            className="choices-path-display__layer-info"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mb={5}
          >
            <Box mb={1}>
              <Typography align="center" variant="subtitle1">
                {activeLayer.title}
              </Typography>
            </Box>
            <Typography align="center" variant="body2">
              {activeLayer.description}
            </Typography>
          </Box>
          <Box className="choices-path__options-container" justifyContent="center" mb={5}>
            {activeLayer.options.map((option, optionIdx) => (
              <Box key={`${activeLayer.id}-${option.id}`}>
                <OptionBubbleWithFullView
                  color={option.color}
                  isSelected={option.isSelected}
                  onClick={
                    canSelectChoices
                      ? () => {
                          onChoiceToggle(activeLayerIdx, optionIdx);
                          onPathUpdateNotificationClick();
                          GA.logInteraction({
                            category: GaCategories.BEHAVIOR,
                            action: GaActions.BUBBLE_CLICK,
                            label: 'Add choice option',
                          });
                        }
                      : undefined
                  }
                  text={option.textJson}
                  variant={ChoiceOptionsColorVariants[option.color]}
                />
              </Box>
            ))}
          </Box>
          {canSelectChoices && !isLastLayerActive && (
            <Typography align="center" isLightText mb={2} variant="subtitle2">
              {t('Are you ready to proceed with the next choice(s)? Click the button Next!')}
            </Typography>
          )}
          <Box display="flex" gap={2} justifyContent="center">
            {choices.layers.length !== 1 && (
              <ChoicesPathStepperControllButton
                ariaLabel={t('Previous')}
                gaLabel="Previous choices path layer"
                isHidden={isFirstLayerActive}
                onClick={onPreviousLayerClick}
              >
                <ChevronLeftIcon />
              </ChoicesPathStepperControllButton>
            )}
            {!isLastLayerActive && nextButton}
            {isLastLayerActive && canSelectChoices && (
              <Button
                className="choices-path-display__primary-control"
                gaLabel="Student choices selection done"
                onClick={() => {
                  onChoicesSubmit();
                  onPathUpdateNotificationClick();
                }}
                variant="primary"
              >
                {t('I’m done')}
              </Button>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

DisplayAssignmentChoicesPath.propTypes = {
  choices: PropTypes.shape(choicesPathType),
  canSelectChoices: PropTypes.bool,
  onChoicesChange: PropTypes.func,
  onChoicesSelectedChange: PropTypes.func,
  assignmentEndDate: PropTypes.string.isRequired,
  assignmentId: PropTypes.number.isRequired,
};

DisplayAssignmentChoicesPath.defaultProps = {
  choices: null,
  canSelectChoices: false,
  onChoicesChange: null,
  onChoicesSelectedChange: null,
};

export default DisplayAssignmentChoicesPath;
