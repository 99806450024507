import { useCallback } from 'react';

import useAxios from '../../HttpClient';
import { classlinkAuthRoute } from '../../../constants/routes';

const url = `${classlinkAuthRoute}`;

const useClassLinkTokenService = () => {
  const { post } = useAxios();
  const postToken = useCallback((value) => post({ enabled: true }, url, { code: value }), [post]);
  const revokeToken = useCallback(
    (isTimeout) =>
      post({ enabled: true }, `${url}/revoke${isTimeout ? '?timeoutLogOut=true' : ''}`),
    [post],
  );

  return { postToken, revokeToken };
};

export default useClassLinkTokenService;
