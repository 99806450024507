import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import { Button, Dialog, PreviewBox, TextField, Typography } from '../../../../../atoms';
import {
  DisabledControlWithTooltip,
  EditingButton,
  FileLinkRemovableItem,
  PopupActionsButtons,
  PreviewGallery,
  TextWithTooltip,
} from '../../../../../moleculas';
import { AppActions, AppContext, UserContext } from '../../../../../../context';
import {
  AttachmentsResourcesTypes,
  GaActions,
  GaCategories,
  InputsValidationErrors,
  InputsValidationRules,
} from '../../../../../../constants/enums';
import { IconsByTypeMap } from '../../../../../../constants/fileTypes';
import { urlAllowedValidationRE } from '../../../../../../constants/regexps';
import { apiRoute } from '../../../../../../constants/routes';
import { byteToMegaByteCoeff } from '../../../../../../constants/values';
import { useAttachmentsService, useLinksService } from '../../../../../../services';
import {
  formatUrl,
  GA,
  getFileErrors,
  getFileTypeFromExtension,
  stopPlayback,
} from '../../../../../../utils';
import { ReactComponent as AddLink } from '../../../../../../resources/icons/add_link.svg';
import { ReactComponent as AddAttachment } from '../../../../../../resources/icons/attach_bold.svg';

const MAX_FILES_LINKS_COUNT = 20;

const CHUNK_SIZE = 5 * byteToMegaByteCoeff;

const initialLinkState = { url: '', errorMsg: '' };

const AddLinkDialog = ({ existingFilesCount, existingLinks, isOpen, onSubmit, onClose }) => {
  const { t } = useTranslation();
  const [linksArr, setLinksArr] = useState([initialLinkState]);

  const isOverLimit =
    existingLinks.length + linksArr.length + existingFilesCount >= MAX_FILES_LINKS_COUNT;

  const handleTextFieldChange = useCallback(
    (value, i) => {
      const updatedArray = JSON.parse(JSON.stringify(linksArr));
      updatedArray[i].url = value;
      setLinksArr(updatedArray);
    },
    [linksArr],
  );

  const handleAddButtonClick = useCallback(() => {
    let hasErrors = false;
    const validatedArray = linksArr.map((link, i) => {
      const formattedUrl = formatUrl(link.url);

      const validatedLink = { url: link.url };
      if (
        !urlAllowedValidationRE.test(formattedUrl) ||
        formattedUrl.length > InputsValidationRules.MAX_LINK_INPUT_LENGTH
      ) {
        validatedLink.errorMsg = InputsValidationErrors(t).LINK_ERROR_TEXT;
        hasErrors = true;
      } else if (
        existingLinks.some((existingLink) => existingLink.url === formattedUrl) ||
        linksArr.some((prevLink, j) => j < i && formatUrl(prevLink.url) === formattedUrl)
      ) {
        validatedLink.errorMsg = t("You've already added this link before");
        hasErrors = true;
      } else {
        validatedLink.errorMsg = '';
      }
      return validatedLink;
    });
    setLinksArr(() => {
      if (!hasErrors) {
        onSubmit(validatedArray.map((x) => formatUrl(x.url)));
      }
      return validatedArray;
    });
  }, [existingLinks, linksArr, onSubmit, t]);

  useEffect(() => {
    if (isOpen) {
      setLinksArr([initialLinkState]);
    }
  }, [isOpen]);

  return (
    <Dialog gaLabel="Add link(s)" onClose={onClose} open={isOpen}>
      <DialogTitle>
        <Box mb={3}>
          <Typography component="h2" variant="h2">
            {t('Add link(s)')}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box mb={3}>
          <Typography variant="body2">
            {t('Add link(s) with online formats of DOC, XLS, PPTX')}
          </Typography>
        </Box>
        {linksArr.map((link, i) => (
          <Box key={i} pb={3}>
            <TextField
              error={!!link.errorMsg}
              fullWidth
              helperText={link.errorMsg}
              onChange={(e) => handleTextFieldChange(e.target.value, i)}
              outlined
              value={link.url}
            />
          </Box>
        ))}
        <Box mb={5} mt={2}>
          <DisabledControlWithTooltip
            showTooltip={isOverLimit}
            title={t('You can add a maximum of 20 files and links to 1 block')}
          >
            <EditingButton
              disabled={isOverLimit}
              gaLabel="Add a link"
              icon={<AddLink />}
              iconPosition="end"
              onClick={() => setLinksArr((state) => [...state, initialLinkState])}
              text={t('Add a link')}
            />
          </DisabledControlWithTooltip>
        </Box>
      </DialogContent>
      <DialogActions>
        <PopupActionsButtons
          primaryButtonHandler={handleAddButtonClick}
          primaryButtonText={t('Add link(s)')}
          secondaryButtonHandler={onClose}
          secondaryButtonText={t('Cancel')}
        />
      </DialogActions>
    </Dialog>
  );
};

AddLinkDialog.propTypes = {
  existingFilesCount: PropTypes.number.isRequired,
  existingLinks: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const LessonPageMaterialsContent = ({
  allowedExtensions,
  isDisabled,
  isStatic,
  resourceId,
  resourceType,
  maxFileSize,
  onBeforeUpload,
  onChange,
  values,
  disabledTooltipText,
  onInProgressChange,
}) => {
  const { t } = useTranslation();
  const fileInputRef = useRef();
  const { postLinks } = useLinksService();
  const { postChunk, patchChunk } = useAttachmentsService();
  const [isAddLinkDialogVisible, setIsAddLinkDialogVisible] = useState(false);
  const [galleryActiveIndex, setGalleryActiveIndex] = useState(null);
  const [filesInProgress, setFilesInProgress] = useState([]);
  const { dispatch: dispatchAppState } = useContext(AppContext);
  const { state: userState } = useContext(UserContext);

  const count = values.links?.length + values.attachments?.length + filesInProgress.length;

  const isOverLimit = count >= MAX_FILES_LINKS_COUNT;

  const filteredFilesSetter = useCallback(
    (files, id) => {
      const updatedFiles = JSON.parse(JSON.stringify(files));
      const returnVal = updatedFiles.filter((x) => x.id !== id);
      if (returnVal.length === 0) {
        dispatchAppState({ type: AppActions.SET_IS_BACKGROUND_SYNC, data: false });
      }
      return returnVal;
    },
    [dispatchAppState],
  );

  const removeItem = useCallback(
    (type, key, value, isError) => {
      if (type === 'attachments') {
        setFilesInProgress((state) => filteredFilesSetter(state, value));
      }
      if (!isError) {
        onChange(
          type,
          null,
          values[type].filter((x) => x[key] !== value),
        );
        dispatchAppState({
          type: AppActions.SET_SNACKBAR_STATUS,
          data: {
            text: t(
              type === 'attachments' ? 'The file has been removed' : 'The link has been removed',
            ),
            type: 'delete',
          },
        });
      }
    },
    [dispatchAppState, filteredFilesSetter, onChange, t, values],
  );

  const addLinks = useCallback(
    (newLinks) => {
      postLinks(newLinks)
        .then((metaDataArray) => {
          onChange('links', [
            ...newLinks.map((link) => {
              const { description, linkType } = metaDataArray.find((x) => x.url === link);
              return {
                url: link,
                createdDate: new Date().toISOString(),
                metaData: { description, linkType },
                text: null,
              };
            }),
          ]);
          setIsAddLinkDialogVisible(false);
          dispatchAppState({
            type: AppActions.SET_SNACKBAR_STATUS,
            data: {
              text: t('The link has been successfully added'),
              type: 'success',
            },
          });
        })
        .catch(() => {
          setIsAddLinkDialogVisible(false);
          dispatchAppState({
            type: AppActions.SET_SNACKBAR_STATUS,
            data: {
              text: t(
                'AYO couldn’t extract the link Please check if it’s working and try once more',
              ),
              type: 'error',
            },
          });
        });
    },
    [dispatchAppState, onChange, postLinks, t],
  );

  const addFile = useCallback(
    (uploadId, fileInfo) => {
      setFilesInProgress((state) => filteredFilesSetter(state, uploadId));
      onChange('attachments', [{ ...fileInfo, ownerId: userState.profile.id }]);
      dispatchAppState({
        type: AppActions.SET_SNACKBAR_STATUS,
        data: {
          text: t('The file has been successfully uploaded'),
          type: 'success',
        },
      });
    },
    [dispatchAppState, filteredFilesSetter, onChange, t, userState.profile.id],
  );

  const sliceFileAndSend = useCallback(
    (uploadId, fileInfoResponse, file, start, onChunkComplete) => {
      const fileSize = file.size;
      const fileName = file.name;
      if (start > fileSize) {
        addFile(uploadId, {
          id: fileInfoResponse.attachmentId,
          fileName,
          updatedDate: fileInfoResponse.updatedDate,
        });
        return;
      }
      const end = Math.min(start + CHUNK_SIZE, fileSize);
      const chunk = file.slice(start, end);
      patchChunk(uploadId, {
        chunk,
        range: `${start}-${end}`,
        resourceId,
        resourceType,
      })
        .then((response) => {
          const { attachmentId, updatedDate } = response;
          if (fileSize < CHUNK_SIZE) {
            addFile(uploadId, { id: attachmentId, fileName, updatedDate });
          } else {
            let proceedIfNotCancelled;
            setFilesInProgress((state) => {
              const updatedFiles = JSON.parse(JSON.stringify(state));
              const fileIndex = updatedFiles.findIndex((x) => x.id === uploadId);
              if (fileIndex !== -1) {
                updatedFiles[fileIndex].progress = Math.round((end / fileSize) * 100);
                proceedIfNotCancelled = true;
              } else {
                proceedIfNotCancelled = false;
              }
              return updatedFiles;
            });
            if (proceedIfNotCancelled) {
              onChunkComplete(uploadId, response, file, start + CHUNK_SIZE, onChunkComplete);
            }
          }
        })
        .catch(() => {
          setFilesInProgress((state) => filteredFilesSetter(state, uploadId));
          dispatchAppState({
            type: AppActions.SET_SNACKBAR_STATUS,
            data: {
              text: t('AYO couldn’t upload the file Please try once more'),
              type: 'error',
            },
          });
        });
    },
    [addFile, dispatchAppState, filteredFilesSetter, patchChunk, resourceId, resourceType, t],
  );

  const handleFileInputChange = useCallback(
    (e) => {
      const { files } = e.target;
      if (files.length) {
        dispatchAppState({ type: AppActions.SET_IS_BACKGROUND_SYNC, data: true });
        Array.prototype.forEach.call(files, (file, i) => {
          if (i + count < MAX_FILES_LINKS_COUNT) {
            const fileName = file.name;
            const fileSize = file.size;
            const errors = getFileErrors(file, { allowedExtensions, maxFileSize }, t);
            if (!errors.length) {
              postChunk({ fileName, fileSize })
                .then(({ id }) => {
                  setFilesInProgress((state) => [...state, { id, fileName, progress: 1 }]);
                  sliceFileAndSend(id, null, file, 0, sliceFileAndSend);
                })
                .catch(() => {
                  dispatchAppState({
                    type: AppActions.SET_SNACKBAR_STATUS,
                    data: {
                      text: t('AYO couldn’t upload the file Please try once more'),
                      type: 'error',
                    },
                  });
                });
            } else {
              setFilesInProgress((state) => [
                ...state,
                { id: `${fileName}${i}`, fileName, errors },
              ]);
              dispatchAppState({ type: AppActions.SET_IS_BACKGROUND_SYNC, data: false });
            }
          }
        });
        fileInputRef.current.value = null;
      }
    },
    [allowedExtensions, count, dispatchAppState, maxFileSize, postChunk, sliceFileAndSend, t],
  );

  useEffect(
    () => () => {
      setFilesInProgress([]);
      onInProgressChange?.(false);
      dispatchAppState({ type: AppActions.SET_IS_BACKGROUND_SYNC, data: false });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isStatic],
  );

  useEffect(() => {
    onInProgressChange?.(!!filesInProgress.filter((file) => !file.errors).length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesInProgress]);

  const galleryItems = useMemo(
    () => ({
      onSlide: (newIndex) => {
        setGalleryActiveIndex(newIndex);
        stopPlayback('audio, video');
        GA.logInteraction({
          category: GaCategories.BEHAVIOR,
          action: GaActions.BUTTON_CLICK,
          label: 'File preview - slide change',
        });
      },
      onThumbnailClick: () => {
        GA.logInteraction({
          category: GaCategories.BEHAVIOR,
          action: GaActions.THUMBNAIL_CLICK,
          label: 'File preview - thumb',
        });
      },
      startIndex: galleryActiveIndex !== -1 ? galleryActiveIndex : 0,
      items: values.attachments.map((item, i) => ({
        // Can be url or any text, as long as we provide renderThumbInner function
        thumbnail: item.id,
        renderThumbInner: () => {
          const Icon = IconsByTypeMap[getFileTypeFromExtension(item.fileName)];
          return (
            <>
              <Icon />
              <TextWithTooltip title={item.fileName} titleVariant="subtitle2" />
            </>
          );
        },
        renderItem: () => (
          <PreviewBox
            key={item.id}
            src={`${apiRoute}/attachments/${item.id}/owners/${item.ownerId}`}
            tabIndex={galleryActiveIndex === i ? 0 : -1}
            type={getFileTypeFromExtension(item.fileName)}
          />
        ),
      })),
    }),
    [galleryActiveIndex, values.attachments],
  );

  const isAddingDisabled = isOverLimit || isDisabled;

  return (
    <>
      {!isStatic && (
        <Box
          className="ayo-lesson-page__materials-actions-container"
          display="flex"
          justifyContent="center"
          mb={3}
        >
          <Box alignItems="center" display="flex" flexDirection="column" mr={5}>
            <DisabledControlWithTooltip
              showTooltip={isAddingDisabled}
              title={
                isDisabled
                  ? disabledTooltipText
                  : t('You can add a maximum of 20 files and links to 1 block')
              }
            >
              <Button
                aria-label={t('Add file(s)')}
                disabled={isAddingDisabled}
                gaLabel="Add file(s)"
                isIconButton
                onClick={() => {
                  if (onBeforeUpload) {
                    onBeforeUpload();
                  }
                  fileInputRef.current.click();
                }}
              >
                <AddAttachment />
              </Button>
            </DisabledControlWithTooltip>
            <Typography variant="subtitle2">{t('Add file(s)')}</Typography>
            <input
              ref={fileInputRef}
              accept={allowedExtensions.length ? `.${allowedExtensions.join(', .')}` : '*'}
              multiple
              onChange={handleFileInputChange}
              style={{ display: 'none' }}
              type="file"
            />
          </Box>
          <Box alignItems="center" display="flex" flexDirection="column">
            <DisabledControlWithTooltip
              showTooltip={isAddingDisabled}
              title={
                isDisabled
                  ? disabledTooltipText
                  : t('You can add a maximum of 20 files and links to 1 block')
              }
            >
              <Button
                aria-label={t('Add link(s)')}
                disabled={isAddingDisabled}
                gaLabel="Add link(s)"
                isIconButton
                onClick={() => setIsAddLinkDialogVisible(true)}
              >
                <AddLink />
              </Button>
            </DisabledControlWithTooltip>
            <Typography variant="subtitle2">{t('Add link(s)')}</Typography>
          </Box>
        </Box>
      )}
      {count > 0 && (
        <Box className="ayo-lesson-page__subcontainer" pb={3} px={isStatic ? 0 : 3}>
          {!isStatic && <Typography variant="subtitle2">{t('Added')}</Typography>}
          {values.links?.map((link) => (
            <FileLinkRemovableItem
              key={link.url}
              createdLabel={t('Added')}
              isDisabled={isDisabled}
              isStatic={isStatic}
              item={link}
              onRemoveClick={() => removeItem('links', 'url', link.url)}
              type="link"
            />
          ))}
          {[...values.attachments, ...filesInProgress]?.map((file, i) => (
            <FileLinkRemovableItem
              key={`${file.fileName}${i}`}
              createdLabel={t('Uploaded')}
              isDisabled={isDisabled}
              isStatic={isStatic}
              item={file}
              onItemClick={() => {
                if (i < values.attachments.length) {
                  setGalleryActiveIndex(i);
                }
              }}
              onRemoveClick={() =>
                removeItem('attachments', 'id', file.id, file.errors?.length > 0)
              }
              type="file"
            />
          ))}
        </Box>
      )}
      <AddLinkDialog
        existingFilesCount={values.attachments.length}
        existingLinks={values.links}
        isOpen={isAddLinkDialogVisible}
        onClose={() => setIsAddLinkDialogVisible(false)}
        onSubmit={addLinks}
      />
      {galleryActiveIndex !== null && (
        <PreviewGallery
          galleryItems={galleryItems}
          isOpen={galleryActiveIndex >= 0}
          onClose={() => setGalleryActiveIndex(-1)}
        />
      )}
    </>
  );
};

LessonPageMaterialsContent.propTypes = {
  allowedExtensions: PropTypes.arrayOf(PropTypes.string),
  isDisabled: PropTypes.bool,
  isStatic: PropTypes.bool,
  maxFileSize: PropTypes.number,
  onBeforeUpload: PropTypes.func,
  onChange: PropTypes.func,
  resourceId: PropTypes.number,
  resourceType: PropTypes.string,
  values: PropTypes.shape({
    links: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
    attachments: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  }).isRequired,
  disabledTooltipText: PropTypes.string,
  onInProgressChange: PropTypes.func,
};

LessonPageMaterialsContent.defaultProps = {
  allowedExtensions: [],
  isDisabled: false,
  isStatic: false,
  maxFileSize: 100 * byteToMegaByteCoeff,
  onBeforeUpload: null,
  onChange: null,
  resourceId: null,
  resourceType: AttachmentsResourcesTypes.LESSON_MATERIAL,
  disabledTooltipText: '',
  onInProgressChange: null,
};

export default LessonPageMaterialsContent;
