import React, { useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Typography } from '../../../../atoms';
import { DeleteItemWrapper, InitiativeEmptyStateBlock } from '../../../../moleculas';
import {
  filterUniqueValuesByKey,
  PortfolioEntitiesData,
  PortfolioItemsToAddData,
} from '../../../../../utils';
import { portfolioSectionConfig } from '../../../../../constants/propTypes';
import { PortfolioSectionTypes } from '../../../../../constants/enums';
import { trainAyoExtracurricularsAppRoute } from '../../../../../constants/routes';
import { ReactComponent as ExtracurricularsEmptyStateIllustration } from '../../../../../resources/images/extracurriculars_boards.svg';
import PortfolioSection from '../../components/portfolio-section/PortfolioSection';

const PortfolioExtracurricularsSection = ({ config, sectionIndex, isLastSection }) => {
  const { t } = useTranslation();
  const [portfolioExtracurriculars, setPortfolioExtracurriculars] = useState([]);
  const {
    addPortfolioItemHandler,
    deletePortfolioItemHandler,
    hasData,
    isEditMode,
    isVisible,
    metaData,
    reflectionHandler,
    sectionData,
    onUpdatePositionHandler,
  } = config;
  const { entities, reflection } = sectionData;

  useEffect(() => {
    setPortfolioExtracurriculars(
      PortfolioEntitiesData[PortfolioSectionTypes.PORTFOLIO__EXTRACURRICULARS](
        entities,
        metaData,
        'name',
      ),
    );
  }, [entities, metaData]);

  const extracurricularsToAdd = useMemo(
    () =>
      PortfolioItemsToAddData[PortfolioSectionTypes.PORTFOLIO__EXTRACURRICULARS](
        entities,
        metaData,
        'name',
      ).map(({ name }) => ({ id: name, name })),
    [entities, metaData],
  );

  if (!isVisible) {
    return null;
  }

  return (
    <PortfolioSection
      body={
        <Box
          className="ayo-portfolio-extracurriculars__content"
          display="flex"
          flexWrap="wrap"
          justifyContent="flex-start"
          maxWidth="60%"
          mt={1}
        >
          {portfolioExtracurriculars.map((extracurricular) => (
            <Box key={extracurricular.name} mr={2} mt={2}>
              <DeleteItemWrapper
                disabled={!isEditMode}
                gaLabel="Remove extracurricular"
                mainContent={
                  <Box className="ayo-portfolio-extracurriculars__content__card">
                    <Typography
                      className="ayo-portfolio-extracurriculars__content__card__title"
                      component="h3"
                      variant="subtitle2"
                    >
                      {extracurricular.name}
                    </Typography>
                  </Box>
                }
                onDelete={() => {
                  deletePortfolioItemHandler(
                    portfolioExtracurriculars,
                    'name',
                    extracurricular.name,
                    sectionData,
                  );
                }}
              />
            </Box>
          ))}
        </Box>
      }
      className={`${classNames({
        'ayo-portfolio-extracurriculars': hasData,
      })}`}
      emptyState={
        !hasData ? (
          <InitiativeEmptyStateBlock
            body={isEditMode ? t('It must be useful for you in future') : ''}
            buttonContent={t('Share in Train AYO')}
            buttonGaLabel="Share in Train AYO - Extracurriculars"
            buttonLink={trainAyoExtracurricularsAppRoute}
            illustration={<ExtracurricularsEmptyStateIllustration />}
            showNavigationButton={isEditMode}
            title={
              isEditMode
                ? t('How about sharing your extracurriculars with AYO?')
                : t('Extracurriculars were not shared so far')
            }
          />
        ) : null
      }
      isEditMode={isEditMode}
      isLastSection={isLastSection}
      itemsToAdd={extracurricularsToAdd}
      onAddItems={(itemsToAdd) =>
        addPortfolioItemHandler(
          filterUniqueValuesByKey(metaData, 'name'),
          itemsToAdd,
          'name',
          sectionData,
        )
      }
      onChangeReflection={reflectionHandler}
      onUpdatePositionHandler={onUpdatePositionHandler}
      reflection={reflection}
      sectionIndex={sectionIndex}
      sectionKey={PortfolioSectionTypes.PORTFOLIO__EXTRACURRICULARS}
      showActionsMenu={hasData && isEditMode}
      showAddButton={!!extracurricularsToAdd.length && isEditMode}
      title={t('Extracurriculars')}
    />
  );
};

PortfolioExtracurricularsSection.propTypes = {
  config: portfolioSectionConfig(
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        year: PropTypes.number,
        name: PropTypes.string,
      }),
    ),
  ),
  sectionIndex: PropTypes.number.isRequired,
  isLastSection: PropTypes.number.isRequired,
};

PortfolioExtracurricularsSection.defaultProps = {
  config: {},
};

export default PortfolioExtracurricularsSection;
