import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import classnames from 'classnames';

import { Card, Typography } from '../../atoms';

const ValidationCard = ({ children, disabled, error, helperText }) => (
  <Box className="ayo-validation-block">
    <Card
      className={`${classnames('ayo-validation-block__card', {
        'ayo-validation-block__card--error': error,
        'ayo-validation-block__card--disabled': disabled,
      })}`}
      mainContent={children}
    />
    {error && (
      <Typography className="ayo-validation-block__helper-text" role="alert" variant="body3">
        {helperText}
      </Typography>
    )}
  </Box>
);

ValidationCard.propTypes = {
  children: PropTypes.element.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
};

ValidationCard.defaultProps = {
  disabled: false,
  error: false,
  helperText: '',
};

export default ValidationCard;
