import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Typography } from '../../../../atoms';
import { InitiativeEmptyStateBlock } from '../../../../moleculas';
import { PortfolioSectionTypes } from '../../../../../constants/enums';
import { PortfolioInterestsIllustrations } from '../../../../../constants/portfolio-interests';
import { ReactComponent as InterestsMapEmptyStateIllustration } from '../../../../../resources/images/interests_map_with_wave.svg';
import PortfolioSection from '../../components/portfolio-section/PortfolioSection';

const PortfolioSharedInterestsSection = ({ sectionData }) => {
  const { t } = useTranslation();
  return (
    <PortfolioSection
      body={
        <Box className="ayo-portfolio-interests-section__container" mt={3}>
          {sectionData.entities?.map((interest, index) => {
            const illustration = PortfolioInterestsIllustrations[interest.parentNameKey];
            return (
              <Box key={interest.id} pt={index % 2 ? 4 : 0}>
                <Box
                  alignItems="center"
                  className="ayo-portfolio-interests-section__card"
                  display="flex"
                  flexDirection="column"
                  p={1}
                >
                  {illustration && (
                    <Box mb={1}>
                      <illustration.image aria-label={t(illustration.alt)} />
                    </Box>
                  )}
                  <Typography align="center" component="h3" isLightText variant="body2">
                    {interest.name}
                  </Typography>
                </Box>
              </Box>
            );
          })}
        </Box>
      }
      className={`${classNames('ayo-full-screen-portfolio-section', {
        'ayo-portfolio-interests-section': sectionData.entities.length,
      })}`}
      emptyState={
        !sectionData.entities.length ? (
          <InitiativeEmptyStateBlock
            body=""
            illustration={<InterestsMapEmptyStateIllustration />}
            title={t('No shared interests so far')}
          />
        ) : null
      }
      reflection={sectionData.reflection}
      sectionKey={PortfolioSectionTypes.PORTFOLIO__INTERESTS}
      theme={sectionData.entities.length ? 'dark' : 'light'}
      title="Interests"
    />
  );
};

PortfolioSharedInterestsSection.propTypes = {
  sectionData: PropTypes.instanceOf(Object).isRequired,
};

export default PortfolioSharedInterestsSection;
