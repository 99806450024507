import React, { useCallback, useMemo, useRef } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import { Checkbox, FormControlLabel, Typography } from '../../../../../../../atoms';
import { RadioExpandPanel, ValidationCard } from '../../../../../../../moleculas';
import { useLessonPlannerData } from '../../../../../../../../hooks';
import { LessonsScheduleSettings, Semesters } from '../../../../../../../../constants/enums';
import { getIsScheduleAvailable } from '../../../../../../../../utils';

export const semestersMap = {
  fall: {
    semester: Semesters.FALL,
    label: 'Fall',
  },
  spring: {
    semester: Semesters.SPRING,
    label: 'Spring',
  },
};

const SemestersBlock = ({
  years,
  onSemesterChange,
  schoolYear,
  semesters,
  selectedSchoolYear,
  onBlur,
}) => {
  const { t } = useTranslation();

  const blockRef = useRef();
  return (
    <Box className="ayo-academic-years__semesters-block" ml={5} mr={8.5} mt={2} p={2}>
      <Typography component="h4" variant="subtitle2">
        {t('Semesters')}
      </Typography>
      <Box ref={blockRef} display="flex" flexDirection="row" mb={1} mt={2.5}>
        {years.map(({ year, semester, label }) => (
          <Box key={year} mr={5}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    selectedSchoolYear === schoolYear &&
                    semesters.some((semesterItem) => semesterItem.semester === semester)
                  }
                  gaLabel="Semesters change"
                  onBlur={onBlur}
                  onChange={(e) => onSemesterChange(e, schoolYear, semester)}
                  value={`${year}-${semester}`}
                />
              }
              label={<Typography variant="body2">{`${t(label)} ${year}`}</Typography>}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

SemestersBlock.propTypes = {
  years: PropTypes.instanceOf(Object).isRequired,
  onSemesterChange: PropTypes.func.isRequired,
  schoolYear: PropTypes.string.isRequired,
  semesters: PropTypes.arrayOf(
    PropTypes.shape({
      schoolYear: PropTypes.string,
      semester: PropTypes.string,
    }),
  ).isRequired,
  selectedSchoolYear: PropTypes.number.isRequired,
  onBlur: PropTypes.func,
};

SemestersBlock.defaultProps = {
  onBlur: () => {},
};

const AcademicYears = ({ helperText, onChange, onValidation }) => {
  const { t } = useTranslation();

  const { updateLessonPlanerCreationState, lessonPlannerData, semestersDaysConfig } =
    useLessonPlannerData();

  const semesters = useMemo(
    () => lessonPlannerData?.stateData?.semestersSchedule || [],
    [lessonPlannerData?.stateData?.semestersSchedule],
  );

  const currentSchoolYear = semestersDaysConfig?.schoolYear;

  const selectedSchoolYear = lessonPlannerData?.stateData?.schoolYear || currentSchoolYear;

  const AcademicYearsConfig = useMemo(
    () =>
      [
        {
          schoolYear: currentSchoolYear,
          years: [
            {
              year: currentSchoolYear - 1,
              ...semestersMap.fall,
              semesterStartDate: semestersDaysConfig?.semesters.FALL.start,
              semesterEndDate: semestersDaysConfig?.semesters.FALL.end,
            },
            {
              year: currentSchoolYear,
              ...semestersMap.spring,
              semesterStartDate: semestersDaysConfig?.semesters.SPRING.start,
              semesterEndDate: semestersDaysConfig?.semesters.SPRING.end,
            },
          ],
          isActive: true,
          isVisible: getIsScheduleAvailable(semestersDaysConfig?.semesters),
        },
        {
          schoolYear: currentSchoolYear + 1,
          years: [
            {
              year: currentSchoolYear,
              ...semestersMap.fall,
            },
            {
              year: currentSchoolYear + 1,
              ...semestersMap.spring,
            },
          ],
          isActive: false,
          isVisible: true,
        },
      ].filter(({ isVisible }) => isVisible),
    [currentSchoolYear, semestersDaysConfig],
  );

  const onSemesterChange = useCallback(
    (e, schoolYear, semester) => {
      const { checked } = e.target;
      onChange([LessonsScheduleSettings.SEMESTERS]);
      let newSemesters;
      const scheduleConfig = {
        rotationWeeksSchedule: semesters[0].rotationWeeksSchedule?.map((week) => ({
          ...week,
          daysSchedule: week.daysSchedule.map((day) => ({ ...day, courses: null })),
        })),
        rotationDaysSchedule: semesters[0].rotationDaysSchedule
          ? {
              ...semesters[0].rotationDaysSchedule,
              daysSchedule: semesters[0].rotationDaysSchedule.daysSchedule.map((day) => ({
                ...day,
                courses: null,
              })),
            }
          : null,
      };
      if (checked) {
        if (semesters.length === 1 && !semesters[0].semester) {
          newSemesters = [...semesters];
          newSemesters[0].semester = semester;
        } else {
          newSemesters = [...semesters, { semester, ...scheduleConfig }];
        }
      } else {
        newSemesters = semesters.filter((semesterItem) => semesterItem.semester !== semester);
        if (!newSemesters.length) {
          newSemesters = [{ semester: null, ...scheduleConfig }];
        }
      }
      updateLessonPlanerCreationState({
        settingsKeys: [LessonsScheduleSettings.SEMESTERS],
        settingsValues: [newSemesters],
      });
    },
    [onChange, semesters, updateLessonPlanerCreationState],
  );

  const onAcademicYearChange = useCallback(
    (year) => {
      onChange([LessonsScheduleSettings.SCHOOL_YEAR]);
      updateLessonPlanerCreationState({
        settingsKeys: [LessonsScheduleSettings.SCHOOL_YEAR],
        settingsValues: [year],
      });
    },
    [onChange, updateLessonPlanerCreationState],
  );

  return (
    <Box className="ayo-academic-years">
      <Typography component="h2" variant="subtitle1">
        {t('Academic years')}
      </Typography>
      <Box role="radiogroup">
        {AcademicYearsConfig.map((academicYear, index) => (
          <Box key={academicYear.label} mt={2}>
            <ValidationCard
              disabled={!academicYear.isActive}
              error={helperText && (selectedSchoolYear === academicYear.schoolYear || !index)}
              helperText={t(helperText)}
            >
              <RadioExpandPanel
                body={
                  <SemestersBlock
                    onBlur={onValidation}
                    onSemesterChange={onSemesterChange}
                    schoolYear={academicYear.schoolYear}
                    selectedSchoolYear={selectedSchoolYear}
                    semesters={semesters}
                    years={
                      semesters.length > 1
                        ? academicYear.years
                        : academicYear.years.filter(
                            ({ semesterEndDate }) =>
                              !dayjs(semesterEndDate).isBefore(dayjs(), 'day'),
                          )
                    }
                  />
                }
                checked={selectedSchoolYear === academicYear.schoolYear}
                disabled={!academicYear.isActive}
                gaLabel="Academic years selected"
                onChange={() => onAcademicYearChange(academicYear.schoolYear)}
                title={`${academicYear.years[0].year}-${academicYear.years[1].year}`}
                tooltipTitle={t('Planning for this academic year will be available later')}
              />
            </ValidationCard>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

AcademicYears.propTypes = {
  helperText: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onValidation: PropTypes.func,
};

AcademicYears.defaultProps = {
  onValidation: () => {},
};

export default AcademicYears;
