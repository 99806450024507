import React from 'react';
import { Box, DialogActions, DialogContent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Dialog, DialogTitle, Typography } from '../../../../../atoms';
import { PopupActionsButtons } from '../../../../../moleculas';

const ClubEditDialog = ({
  className,
  isOpen,
  dialogContent,
  dialogTitle,
  onClose,
  onSave,
  submitButtonTitle,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      className={classnames('ayo-club-hub-details__edit-dialog', `${className}`)}
      gaLabel="Club edit dialog"
      onClose={onClose}
      open={isOpen}
    >
      <DialogTitle disableTypography>
        <Typography component="h2" variant="h2">
          {dialogTitle}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box className="ayo-club-hub-details__edit-dialog__content">{dialogContent}</Box>
      </DialogContent>
      <DialogActions>
        <PopupActionsButtons
          primaryButtonGaLabel={submitButtonTitle}
          primaryButtonHandler={onSave}
          primaryButtonText={t(submitButtonTitle)}
          secondaryButtonGaLabel="Cancel"
          secondaryButtonHandler={onClose}
          secondaryButtonText={t('Cancel')}
        />
      </DialogActions>
    </Dialog>
  );
};

ClubEditDialog.propTypes = {
  className: PropTypes.string,
  dialogContent: PropTypes.node.isRequired,
  dialogTitle: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  submitButtonTitle: PropTypes.string,
};

ClubEditDialog.defaultProps = {
  className: '',
  submitButtonTitle: 'Save changes',
};

export default ClubEditDialog;
