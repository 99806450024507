import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

import { Button, TextField } from '../../../../atoms';
import { DisabledControlWithTooltip, EditingButton } from '../../../../moleculas';
import { formatUrl } from '../../../../../utils';
import { useSnackbar } from '../../../../../hooks';
import { SocialMediaIcons } from '../../../../../constants/social-media-links';
import { InputsValidationErrors, InputsValidationRules } from '../../../../../constants/enums';
import { urlAllowedValidationRE } from '../../../../../constants/regexps';
import { ReactComponent as AddLink } from '../../../../../resources/icons/add_link.svg';
import { ReactComponent as RemoveLink } from '../../../../../resources/icons/close_primary_simple.svg';

const MAX_LINKS_COUNT = 5;

const ClubLinkIcons = [
  { urls: ['facebook.com', 'fb.com'], icon: SocialMediaIcons.FACEBOOK },
  { urls: ['instagram.com', 'instagr.am'], icon: SocialMediaIcons.INSTAGRAM },
  { urls: ['twitter.com'], icon: SocialMediaIcons.TWITTER },
  { urls: ['youtube.com', 'youtu.be'], icon: SocialMediaIcons.YOUTUBE },
];

const getValidatedLinks = (arr, t) =>
  arr.map((link, i) => {
    const validatedLink = link;
    const formattedUrl = formatUrl(link.url);
    if (
      link.url &&
      (!urlAllowedValidationRE.test(formattedUrl) ||
        formattedUrl.length > InputsValidationRules.MAX_LINK_INPUT_LENGTH)
    ) {
      validatedLink.error = InputsValidationErrors(t).LINK_ERROR_TEXT;
    } else if (
      link.url &&
      arr.some((prevLink, j) => j < i && formatUrl(prevLink.url) === formattedUrl)
    ) {
      validatedLink.error = t("You've already added this link before");
    } else {
      validatedLink.error = '';
    }
    return validatedLink;
  });

const getLinkIcon = (link) => {
  if (urlAllowedValidationRE.test(formatUrl(link))) {
    return (
      ClubLinkIcons.find(({ urls }) => urls.some((url) => link.includes(url)))?.icon ||
      SocialMediaIcons.GENERAL
    );
  }
  return SocialMediaIcons.GENERAL;
};

const ClubLinks = ({ clubValueKey, gaLabel, links, onChange }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  const [linksArr, setLinksArr] = useState(
    links.map((link) => ({ url: link, error: '', id: uuid(), icon: getLinkIcon(link) })),
  );

  const { setSnackBarStatus } = useSnackbar();

  const isOverLimit = useMemo(() => links.length === MAX_LINKS_COUNT, [links.length]);

  const linkChangeHandler = useCallback(
    (e, linkId) => {
      const { value } = e.target;
      setLinksArr((prevState) => {
        const linkIndex = prevState.findIndex(({ id }) => id === linkId);
        const newLinks = Object.assign([...prevState], {
          [linkIndex]: {
            ...prevState[linkIndex],
            url: value,
            icon: getLinkIcon(value),
            error: '',
          },
        });

        onChange({ [clubValueKey]: newLinks.map(({ url }) => url) });

        return newLinks;
      });
    },
    [clubValueKey, onChange],
  );

  const linkAddHandler = useCallback(() => {
    setLinksArr((prevState) => {
      const newLinks = [...prevState, { url: '', error: '', id: uuid(), icon: getLinkIcon('') }];

      onChange({ [clubValueKey]: newLinks.map(({ url }) => url) });

      return newLinks;
    });
  }, [clubValueKey, onChange]);

  const linkValidationHandler = useCallback(
    (e, linkId) => {
      setLinksArr((prevState) => {
        const newLinks = getValidatedLinks(prevState, t);
        const currentLink = newLinks.find(({ id }) => id === linkId);
        if (e.target.value && !currentLink.error) {
          setSnackBarStatus({
            text: t('The link has been successfully added'),
            type: 'success',
          });
        }
        return newLinks;
      });
    },
    [setSnackBarStatus, t],
  );

  const linkRemoveHandler = useCallback(
    (linkUrl, linkId) => {
      setLinksArr((prevState) => {
        const newLinks = getValidatedLinks(
          prevState.filter(({ id }) => linkId !== id),
          t,
        );
        setSnackBarStatus({
          text: t('The link has been removed'),
          type: 'delete',
        });

        onChange({ [clubValueKey]: newLinks.map(({ url }) => url) });

        return newLinks;
      });
    },
    [clubValueKey, onChange, setSnackBarStatus, t],
  );

  return (
    <Box>
      {!!linksArr.length &&
        linksArr.map((link) => (
          <Box key={link.id} alignItems="center" display="flex" flexDirection="row" mb={3}>
            <Box alignItems="center" display="flex" minWidth="fit-content" mr={0.5}>
              <link.icon />
            </Box>
            <TextField
              error={!!link.error}
              fullWidth
              gaLabel={gaLabel}
              helperText={link.error}
              label={t('Add a link here')}
              onBlur={(e) => linkValidationHandler(e, link.id)}
              onChange={(e) => linkChangeHandler(e, link.id)}
              outlined
              value={link.url}
            />
            <EditingButton
              ariaLabel="Remove a link"
              gaLabel="Remove a link"
              icon={<RemoveLink />}
              iconPosition="end"
              onClick={() => linkRemoveHandler(link.url, link.id)}
            />
          </Box>
        ))}
      <DisabledControlWithTooltip
        PopperProps={{
          style: {
            width: isWidthUpSm ? '450px' : '90%',
          },
        }}
        showTooltip={isOverLimit}
        title={t('Max links error')}
        wrapperClassName="ayo-club-hub__links__disabled-control"
      >
        <Button
          disabled={isOverLimit}
          endIcon={<AddLink />}
          gaLabel="Add a link"
          onClick={linkAddHandler}
        >
          {t('Add a link')}
        </Button>
      </DisabledControlWithTooltip>
    </Box>
  );
};

ClubLinks.propTypes = {
  clubValueKey: PropTypes.string.isRequired,
  gaLabel: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ClubLinks;
