import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

import { Button, Typography } from '../../../../../atoms';
import { DisabledControlWithTooltip, STTTextField } from '../../../../../moleculas';
import AssignmentsActionButtons from '../../assignments-action-buttons/AssignmentsActionButtons';
import AssignmentNoEditTooltip from '../../assignment-no-edit-tooltip/AssignmentNoEditTooltip';
import { ReactComponent as AddIcon } from '../../../../../../resources/icons/add.svg';
import { ReactComponent as CircleIcon } from '../../../../../../resources/icons/circle.svg';

const MAX_OPTIONS_LENGTH = 100;

const AssignmentOptions = ({ options, onChange, errorMesssage, disabled }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography aria-label={`${t('Options')} (${t('required')})`} paragraph variant="subtitle2">
        {t('Options')}*
      </Typography>
      <Box className="ayo-lesson-assignment-options">
        {options.map((option, index) => (
          <Box key={option.key} alignItems="center" display="flex" mb={2}>
            <CircleIcon />
            <Box className="ayo-lesson-assignment-options__textfield" ml={2} mr={4}>
              <AssignmentNoEditTooltip showTooltip={disabled}>
                <STTTextField
                  disabled={disabled}
                  error={errorMesssage !== ''}
                  fullWidth
                  helperText={errorMesssage}
                  label={t('Add option here')}
                  onChange={(e) => {
                    onChange(index, e.target.value);
                  }}
                  outlined
                  value={option.value}
                />
              </AssignmentNoEditTooltip>
            </Box>
            <AssignmentsActionButtons
              deleteDisabled={options.length === 1}
              deleteOptionHandler={() => onChange(index, null)}
              disabled={disabled}
              downButtonHandler={() => onChange(index, option, 'down')}
              isFirst={index === 0}
              isLast={index === options.length - 1}
              upButtonHandler={() => onChange(index, option, 'up')}
            />
          </Box>
        ))}
        <Box className="ayo-lesson-assignment-options__add-button" ml={6}>
          <DisabledControlWithTooltip
            gaLabel="disabled Add option button"
            showTooltip={options.length >= MAX_OPTIONS_LENGTH}
            title={t(
              'You can add a maximum of 100 options to 1 assignment. If you need to add more, please delete some already added ones',
            )}
          >
            <Button
              disabled={disabled || options.length >= MAX_OPTIONS_LENGTH}
              endIcon={<AddIcon />}
              gaLabel="Add option"
              onClick={() => onChange(options.length, '')}
              variant="text"
            >
              {t('Add option')}
            </Button>
          </DisabledControlWithTooltip>
        </Box>
      </Box>
    </Box>
  );
};

AssignmentOptions.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({ key: PropTypes.string, value: PropTypes.string }))
    .isRequired,
  onChange: PropTypes.func.isRequired,
  errorMesssage: PropTypes.string,
  disabled: PropTypes.bool,
};

AssignmentOptions.defaultProps = {
  errorMesssage: '',
  disabled: false,
};

export default AssignmentOptions;
