import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

const SkeletonMask = () => {
  const { t } = useTranslation();
  return (
    <Box className="ayo-skeleton-mask">
      <div className="sr-only">{t('Please wait a moment')}</div>
    </Box>
  );
};

export default SkeletonMask;
