/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import MUIRadio from '@mui/material/Radio';
import MUIRadioGroup from '@mui/material/RadioGroup';
import MUIFormControl from '@mui/material/FormControl';
import MUIFormLabel from '@mui/material/FormLabel';

import FormControlLabel from '../form-control-label/FormControlLabel';
import { GA } from '../../../utils';
import { GaActions, GaCategories } from '../../../constants/enums';

export const Radio = ({ gaLabel, onChange, ...rest }) => {
  const [active, setActive] = useState(false);

  return (
    <MUIRadio
      className={`${classNames('ayo-radio', { 'Mui-active': active })}`}
      color="primary"
      disableRipple
      onChange={(e) => {
        if (gaLabel) {
          GA.logInteraction({
            category: GaCategories.BEHAVIOR,
            action: GaActions.RADIOBUTTON_CLICK,
            label: gaLabel,
          });
        }
        onChange(e);
      }}
      onMouseDown={() => {
        setActive(true);
      }}
      onMouseUp={() => {
        setActive(false);
      }}
      {...rest}
    />
  );
};

Radio.propTypes = {
  gaLabel: PropTypes.string,
  onChange: PropTypes.func,
};

Radio.defaultProps = {
  gaLabel: '',
  onChange: () => {},
};

const RadioGroup = ({
  ariaLabel,
  caption,
  className,
  onChange,
  options,
  defaultValue,
  captionComponentType,
  ...rest
}) => {
  const [value, setValue] = useState(defaultValue);

  const handleChange = (e) => {
    setValue(e.target.value);

    onChange(e);
  };

  return (
    <MUIFormControl
      className={`${classNames('ayo-radiogroup', { [`${className}`]: className })}`}
      component="fieldset"
    >
      <MUIFormLabel component={captionComponentType || 'legend'}>{caption}</MUIFormLabel>
      <MUIRadioGroup
        aria-label={ariaLabel}
        name="options"
        onChange={handleChange}
        value={value}
        {...rest}
      >
        {options.map((option) => (
          <FormControlLabel
            key={`O_${option.value}`}
            control={<Radio />}
            disabled={option.disabled}
            label={option.text}
            value={option.value}
          />
        ))}
      </MUIRadioGroup>
    </MUIFormControl>
  );
};

RadioGroup.propTypes = {
  ariaLabel: PropTypes.string,
  caption: PropTypes.string,
  captionComponentType: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool,
      text: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  defaultValue: PropTypes.string,
};

RadioGroup.defaultProps = {
  ariaLabel: '',
  caption: '',
  captionComponentType: null,
  className: '',
  onChange: () => {},
  defaultValue: '',
};

export default RadioGroup;
