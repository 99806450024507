import React, { useState, useCallback, useContext, useRef, useMemo, useEffect } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, DialogActions, DialogContent, Grid, useMediaQuery, useTheme } from '@mui/material';

import {
  Checkbox,
  Dialog,
  DialogTitle,
  FormControlLabel,
  Slider,
  Typography,
} from '../../../../atoms';
import { InterestBubbleFigure, PopupActionsButtons, STTTextField } from '../../../../moleculas';
import { UserContext } from '../../../../../context';
import { useReflectionsService, useStudentsService } from '../../../../../services';
import {
  InputsValidationErrors,
  InputsValidationRules,
  ReflectionTypes,
} from '../../../../../constants/enums';
import { transformInterestToBubble } from '../../../../../utils';
import { ReactComponent as NotInterestedIcon } from '../../../../../resources/icons/not_interested.svg';
import { ReactComponent as ItsOkayIcon } from '../../../../../resources/icons/its_okay.svg';
import { ReactComponent as LikeItIcon } from '../../../../../resources/icons/like_it.svg';
import { ReactComponent as LoveItIcon } from '../../../../../resources/icons/love_it.svg';
import { ReactComponent as InfoIcon } from '../../../../../resources/icons/info.svg';

const NotInterestedThumb = ({ className, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <NotInterestedIcon {...props} className={classnames(className, 'thumb-0')} />
);

NotInterestedThumb.propTypes = {
  className: PropTypes.string.isRequired,
};

const LoveItThumb = ({ className, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <LoveItIcon {...props} className={classnames(className, 'thumb-last')} />
);

LoveItThumb.propTypes = {
  className: PropTypes.string.isRequired,
};

const ReactionQuestions = {
  0: 'What makes you not interested in interestName?',
  1: 'What do you think about interestName?',
  2: 'What makes you like interestName?',
  3: 'What makes you love interestName?',
};

const REACTION_BUBBLE_RADIUS = 94;
const REACTION_BUBBLE_BUFF_INCREASE = 28;
const SHARE_REFLECTION_REACTION_THRESHOLD = 1;
const REACTION_BUBBLE_PADDING = 6;

const ReactionWidget = ({
  interest,
  close,
  setLastReactedInterest,
  changeItem,
  changeReactedItem,
}) => {
  const [reactionValue, setReactionValue] = useState(interest.reaction ?? 1);
  const [reflectionValue, setReflectionValue] = useState(interest.reflection);
  const [shareReflection, setShareReflection] = useState(interest.isReflectionVisible);
  const { t } = useTranslation();

  const { state: userState } = useContext(UserContext);
  const { updateStudentInterest } = useStudentsService();

  const { current: savedReaction } = useRef(interest.reaction);

  const willDeleteReflection = useMemo(
    () =>
      interest.reflection &&
      interest.reflection === reflectionValue &&
      savedReaction !== reactionValue,
    [interest, reactionValue, reflectionValue, savedReaction],
  );

  const { postReflection, putReflection, deleteReflection } = useReflectionsService();

  const onReactionSave = useCallback(
    (share) => {
      const shouldDeleteReflection =
        willDeleteReflection || (interest.reflection && !reflectionValue);
      updateStudentInterest(userState.profile.id, {
        id: interest.id,
        reaction: reactionValue,
      }).then(() => {
        if (shouldDeleteReflection) {
          deleteReflection(interest.id, ReflectionTypes.INTEREST);
        } else if (reflectionValue) {
          const reflectionBody = {
            resourceId: interest.id,
            resourceType: ReflectionTypes.INTEREST,
            reflection: reflectionValue,
            visible: shareReflection && share,
          };
          const reflectionSaveMethod =
            interest.reflection !== null ? putReflection : postReflection;
          reflectionSaveMethod(reflectionBody);
        }
      });
      setLastReactedInterest(interest);
      changeItem(
        {
          ...interest,
          reaction: reactionValue,
          reflection: shouldDeleteReflection ? null : reflectionValue,
          isReflectionVisible: share,
        },
        share,
      );
      close();
    },
    [
      changeItem,
      updateStudentInterest,
      close,
      interest,
      reactionValue,
      setLastReactedInterest,
      userState.profile.id,
      shareReflection,
      reflectionValue,
      willDeleteReflection,
      putReflection,
      postReflection,
      deleteReflection,
    ],
  );

  const saveWithSharing = useCallback(() => onReactionSave(true), [onReactionSave]);
  const saveWithoutSharing = useCallback(() => onReactionSave(false), [onReactionSave]);

  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  const sliderMarks = useMemo(
    () => [
      { value: 0, label: t('Not interested (wrapped)') },
      { value: 1, label: t('It’s okay') },
      { value: 2, label: t('Like it (wrapped)') },
      { value: 3, label: t('Love it! (wrapped)') },
    ],
    [t],
  );

  useEffect(() => {
    const newReactedInterest = transformInterestToBubble({ ...interest, reaction: reactionValue });
    newReactedInterest.r = REACTION_BUBBLE_RADIUS;
    changeReactedItem(newReactedInterest);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeReactedItem, interest.id, reactionValue]);

  const [reflectionValidationError, setReflectionValidationError] = useState();

  const onReflectionChange = (e) => {
    setReflectionValue(e.target.value);
    setReflectionValidationError(null);
  };
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);

  const openConfirmationDialog = () => setIsConfirmationDialogOpen(true);
  const closeConfirmationDialog = () => setIsConfirmationDialogOpen(false);

  const reactionBubbleMaximumDiameter =
    2 * (REACTION_BUBBLE_PADDING + REACTION_BUBBLE_RADIUS + REACTION_BUBBLE_BUFF_INCREASE * 2);

  const shouldShareReflection =
    shareReflection && reactionValue > SHARE_REFLECTION_REACTION_THRESHOLD;

  const onProceed = () => {
    if ((reflectionValue?.length ?? 0) <= InputsValidationRules.MAX_TEXT_AREA_LENGTH) {
      if (shouldShareReflection) {
        openConfirmationDialog();
      } else {
        saveWithoutSharing();
      }
    } else {
      setReflectionValidationError(
        InputsValidationErrors(t, InputsValidationRules.MAX_TEXT_AREA_LENGTH).MAX_ERROR_TEXT,
      );
    }
  };

  return (
    <Grid alignItems="center" className="reaction-widget__container" container direction="column">
      <Grid container justifyContent="center">
        <Grid item>
          <svg
            height={reactionBubbleMaximumDiameter}
            viewBox={`0 0 ${reactionBubbleMaximumDiameter} ${reactionBubbleMaximumDiameter}`}
            width={reactionBubbleMaximumDiameter}
          >
            <g>
              <InterestBubbleFigure
                ariaLabel={interest.name}
                buffRadiusIncrease={REACTION_BUBBLE_BUFF_INCREASE}
                circle={{
                  ...interest,
                  r: REACTION_BUBBLE_RADIUS,
                  x: reactionBubbleMaximumDiameter / 2,
                  y: reactionBubbleMaximumDiameter / 2,
                }}
              />
            </g>
          </svg>
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item sm={6} xs={12}>
          <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
            mb={isWidthUpSm ? 5 : 1}
            width="100%"
          >
            <Typography align="center" className="light-text" variant="subtitle2">
              {t('Your reaction is')}
            </Typography>
            <Slider
              ariaMarks={sliderMarks}
              className={classnames(`reaction-slider-${reactionValue}`, {
                'suggested-slider': interest.suggested,
              })}
              defaultValue={2}
              marks={sliderMarks}
              max={3}
              min={0}
              onChange={(e, newValue) => setReactionValue(newValue)}
              step={1}
              ThumbComponents={{
                0: NotInterestedThumb,
                1: ItsOkayIcon,
                2: LikeItIcon,
                3: LoveItThumb,
              }}
              value={reactionValue}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item sm={6} xs={12}>
          <Box width="100%">
            <Box mb={3}>
              <Typography className="light-text" variant="subtitle2">
                {t(ReactionQuestions[reactionValue], { interestName: interest.name })}
              </Typography>
            </Box>
            <Box mb={3}>
              <STTTextField
                className="dark"
                error={reflectionValidationError}
                fullWidth
                helperText={reflectionValidationError}
                label={t('My thoughts about the interest')}
                maxLength={InputsValidationRules.MAX_TEXT_AREA_LENGTH}
                multiline
                onChange={onReflectionChange}
                rowsCount={2}
                value={reflectionValue}
              />
            </Box>
            {willDeleteReflection && (
              <Box display="flex" mb={3}>
                <InfoIcon className="info-icon" />
                <Typography className="light-text" variant="body2">
                  {t('Your comment will be deleted automatically when you submit a new reaction')}
                </Typography>
              </Box>
            )}
            {reactionValue > SHARE_REFLECTION_REACTION_THRESHOLD && (
              <Box mb={3}>
                <FormControlLabel
                  className="light-label"
                  control={
                    <Checkbox
                      checked={shareReflection}
                      gaLabel="Share comment"
                      onChange={(e) => setShareReflection(e.target.checked)}
                    />
                  }
                  label={t('Make my comment visible for my educators and parents in AYO')}
                />
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
      <PopupActionsButtons
        className="reaction-widget__actions"
        primaryButtonGaLabel="Save interest reaction"
        primaryButtonHandler={onProceed}
        primaryButtonText={t('I’m done')}
        secondaryButtonGaLabel="Cancel interest reaction"
        secondaryButtonHandler={close}
        secondaryButtonText={t('Cancel')}
      />
      <Dialog
        gaLabel="Interest Comment confirmation"
        onClose={closeConfirmationDialog}
        open={isConfirmationDialogOpen}
      >
        <DialogTitle disableTypography>
          <Box mb={2}>
            <Typography component="h2" variant="subtitle1">
              {t('Are you sure you want to show your comment to educators and parents?')}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box mb={5}>
            <Typography variant="body2">
              {t(
                'If you confirm, your comment will be visible for your educators and parents in AYO',
              )}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <PopupActionsButtons
            primaryButtonGaLabel="Confirm interest comment sharing"
            primaryButtonHandler={saveWithSharing}
            primaryButtonText={t('Show')}
            secondaryButtonGaLabel="Cancel interest comment sharing"
            secondaryButtonHandler={saveWithoutSharing}
            secondaryButtonText={t('Do not show')}
          />
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

ReactionWidget.propTypes = {
  interest: PropTypes.instanceOf(Object).isRequired,
  close: PropTypes.func.isRequired,
  setLastReactedInterest: PropTypes.func.isRequired,
  changeReactedItem: PropTypes.func.isRequired,
  changeItem: PropTypes.func.isRequired,
};

export default ReactionWidget;
