import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { InputsValidationErrors, InputsValidationRules } from '../../../../../../constants/enums';

const MAX_TITLE_LENGTH = InputsValidationRules.MAX_INPUT_LENGTH;
const MAX_DESCRIPTION_LENGTH = InputsValidationRules.MAX_INPUT_LENGTH;
const MIN_SINGLE_OPTIONS = 1;
const MIN_MULTIPLE_OPTIONS = 2;

const useAssignmentChoicePathsState = (currentState) => {
  const state = currentState;

  const onChoicesChange = (updatedChoices) => ({ ...state, ...updatedChoices });

  const [errors, setErrors] = useState({});

  const onLayerChange = (level, newLayer) => {
    const newLayers = [...state.layers];
    if (newLayer) {
      newLayers[level] = { tempId: uuid(), ...newLayers[level], ...newLayer };
    } else {
      newLayers.splice(level, 1);
    }
    const editedLayer = newLayers[level];
    setErrors((prevErrors) => ({ ...prevErrors, [editedLayer.id ?? editedLayer.tempId]: {} }));
    return { ...state, layers: newLayers };
  };

  const { t } = useTranslation();

  const validate = () => {
    if (!state) return [];
    const newErrors = {};
    const formattedErrors = {};
    const titleLabel = t('Step title');
    const descriptionLabel = t('Step description');
    const optionsLabel = t('Choice options');
    const addFormattedError = (layerId, error, field) => {
      formattedErrors[layerId] = { ...(formattedErrors[layerId] ?? {}), [field]: error };
    };
    const addError = (layerId, error) => {
      newErrors[layerId] = [...(newErrors[layerId] ?? []), error];
      if (error.lvl3Label === titleLabel) {
        addFormattedError(layerId, error, 'title');
      }
      if (error.lvl3Label === descriptionLabel) {
        addFormattedError(layerId, error, 'description');
      }
      if (error.lvl3Label === optionsLabel) {
        addFormattedError(layerId, error, 'options');
      }
    };
    state.layers.forEach((layer) => {
      const layerId = layer.id ?? layer.tempId;
      if (layer.title?.length > MAX_TITLE_LENGTH) {
        const errorText = InputsValidationErrors(t, MAX_TITLE_LENGTH).MAX_ERROR_TEXT;
        addError(layerId, { errorText, lvl3Label: titleLabel });
      }
      if (layer.description?.length > MAX_DESCRIPTION_LENGTH) {
        const errorText = InputsValidationErrors(t, MAX_DESCRIPTION_LENGTH).MAX_ERROR_TEXT;
        addError(layerId, { errorText, lvl3Label: descriptionLabel });
      }
      const minRequiredOptions = state.isMultipleOptions
        ? MIN_MULTIPLE_OPTIONS
        : MIN_SINGLE_OPTIONS;
      if (layer.options.length < minRequiredOptions) {
        const errorText = t('Choices options min options validation', {
          count: minRequiredOptions,
        });
        addError(layerId, { errorText, lvl3Label: optionsLabel });
      }
      setErrors((prevErrors) => ({ ...prevErrors, [layerId]: formattedErrors[layerId] }));
    });
    return Object.values(newErrors).flat();
  };

  return { state, onChoicesChange, onLayerChange, validate, errors };
};

export default useAssignmentChoicePathsState;
