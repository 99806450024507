import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { InitiativeEmptyStateBlock } from '../../../../../moleculas';
import { ClubsMembershipStatuses, ClubsStatuses } from '../../../../../../constants/club-hub';
import { ReactComponent as PendingStatusImage } from '../../../../../../resources/images/club_hub_pending_satus.svg';
import { ReactComponent as InactiveStatusImage } from '../../../../../../resources/images/club_hub_inactive_status.svg';
import { ReactComponent as DeclinedStatusImage } from '../../../../../../resources/images/club_hub_declined_status.svg';

const ClubDetailsStatusConfig = {
  [ClubsStatuses.PENDING]: {
    title: "The club you've created has been sent to the moderator for approval.",
    body: 'Once your club creation request is approved by the moderator, you can start making your first posts and accepting members.',
    illustration: <PendingStatusImage />,
  },
  [ClubsStatuses.INACTIVE]: {
    title: 'This club is inactive',
    illustration: <InactiveStatusImage />,
  },
  [ClubsStatuses.DECLINED]: {
    title: 'This club’s creation request has been declined',
    illustration: <DeclinedStatusImage />,
  },
  [ClubsMembershipStatuses.PENDING_REQUEST]: {
    title: 'Your membership request is waiting for approval',
    body: 'After a moderator approves your request, you’ll have access to the club’s private posts and events.',
    illustration: <PendingStatusImage />,
  },
  [ClubsMembershipStatuses.REQUEST_REJECTED]: {
    title: 'Your request to join the club has been rejected',
    illustration: <DeclinedStatusImage />,
  },
};
const ClubDetailsStatus = ({ reason, status }) => {
  const { t } = useTranslation();

  const config = useMemo(() => ClubDetailsStatusConfig[status], [status]);

  return (
    <InitiativeEmptyStateBlock
      body={reason ? `${t('The reason')}: ${reason}` : t(config.body)}
      illustration={config.illustration}
      title={t(config.title)}
    />
  );
};

ClubDetailsStatus.propTypes = {
  reason: PropTypes.string,
  status: PropTypes.string.isRequired,
};

ClubDetailsStatus.defaultProps = {
  reason: '',
};

export default ClubDetailsStatus;
