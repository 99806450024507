import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Radar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { Card, CheckboxGroup, Typography } from '../../atoms';
import { GaActions, GaCategories } from '../../../constants/enums';
import { GA } from '../../../utils';

const RadarChart = ({ axisLineColor, datasets, labels, maxValue, minValue, readOnly }) => {
  const { t } = useTranslation();

  const [modifiedDatasets, setModifiedDatasets] = useState(datasets);

  const graphData = useMemo(
    () => ({
      labels,
      datasets: modifiedDatasets,
    }),
    [labels, modifiedDatasets],
  );

  const radialLinesColorConfig = useMemo(() => {
    const arr = Array(maxValue - 1).fill('transparent');
    arr.push(axisLineColor);
    return arr;
  }, [axisLineColor, maxValue]);

  const graphOptions = useMemo(
    () => ({
      plugins: {
        legend: {
          display: false,
        },
        tooltip: false,
      },
      scales: {
        r: {
          animate: false,
          angleLines: {
            color: axisLineColor,
          },
          grid: {
            circular: true,
            color: radialLinesColorConfig,
          },
          pointLabels: {
            color: 'rgba(0, 0, 0, 0.75)',
            font: {
              family: 'Lato, sans-serif',
              size: 12,
            },
          },
          suggestedMax: maxValue,
          suggestedMin: minValue,
          ticks: {
            display: false,
          },
        },
      },
    }),
    [axisLineColor, radialLinesColorConfig, maxValue, minValue],
  );

  const checkBoxConfig = useMemo(() => {
    let defaultValues = {};
    datasets.forEach((dataset) => {
      defaultValues = {
        ...defaultValues,
        [dataset.label]: true,
      };
    });
    return {
      defaultValues,
      options: datasets.map((dataset) => ({
        className: dataset.key?.toLowerCase().replace('_', '-'),
        text: t(dataset.label),
        value: dataset.label,
      })),
    };
  }, [datasets, t]);

  const ariaLabel = useMemo(
    () =>
      t('A radar graph which is showing the current score of maturity levels for all attributes'),
    [t],
  );

  const handleLegendItemsSelection = useCallback(
    (e) => {
      if (e.target.checked) {
        datasets.some((dataset) => {
          if (dataset.label === e.target.value) {
            setModifiedDatasets((state) => [...state, dataset]);
            return true;
          }
          return false;
        });
      } else {
        let newDatasets = [];
        modifiedDatasets.forEach((dataset) => {
          if (dataset.label !== e.target.value) {
            newDatasets = [...newDatasets, dataset];
          }
        });
        setModifiedDatasets(newDatasets);
      }
      GA.logInteraction({
        category: GaCategories.BEHAVIOR,
        action: GaActions.CHECKBOX_CLICK,
        label: `Maturity level graph ${e.target.value} ${
          e.target.checked ? 'checked' : 'not checked'
        }`,
      });
    },
    [datasets, modifiedDatasets],
  );

  return (
    <div className="ayo-radar-chart">
      <Radar aria-label={ariaLabel} data={graphData} options={graphOptions} role="img" />
      <Card
        mainContent={
          !readOnly ? (
            <CheckboxGroup
              caption={t('View')}
              defaultValues={checkBoxConfig.defaultValues}
              onChange={handleLegendItemsSelection}
              options={checkBoxConfig.options}
            />
          ) : (
            <Box className="labels-group">
              <Typography variant="subtitle2">{t('View')}</Typography>
              {checkBoxConfig.options.map((option, idx) => (
                <Box key={option.text} className={option.className} mt={idx ? 3 : 1}>
                  <Typography className="view-options" variant="body2">
                    {t(option.text)}
                  </Typography>
                </Box>
              ))}
            </Box>
          )
        }
        variant="outlined"
      />
    </div>
  );
};

RadarChart.propTypes = {
  axisLineColor: PropTypes.string.isRequired,
  datasets: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  maxValue: PropTypes.number.isRequired,
  minValue: PropTypes.number.isRequired,
  readOnly: PropTypes.bool,
};

RadarChart.defaultProps = {
  readOnly: false,
};

export default RadarChart;
