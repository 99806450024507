import { useCallback } from 'react';

import useAxios from '../HttpClient';
import { cmsRoute } from '../../constants/routes';

const useCmsService = () => {
  const { get } = useAxios();

  const getBlockConfig = useCallback(
    (blockPath) => get({ enabled: true }, `${cmsRoute}/${blockPath}.json`),
    [get],
  );

  return { getBlockConfig };
};

export default useCmsService;
