import React, { useCallback, useMemo } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { InitiativeEmptyStateBlock } from '../../../moleculas';
import { SharingLevels } from '../../../../constants/family-feed';
import { useFamilyFeed } from '../../../../hooks';
import { ReactComponent as ClassLevelEmptyState } from '../../../../resources/images/family_feed_selected_entities_empty_state.svg';
import BlockHeader from '../block-header/BlockHeader';
import SelectableEntities from '../selectable-entities/SelectableEntities';
import SelectAllEntities from '../select-all-entities/SelectAllEntities';

const ClassesBlock = ({ columnsCount, classes, onChange, selected }) => {
  const { t } = useTranslation();
  const { formatClasses } = useFamilyFeed();

  const selectableEntities = useMemo(
    () => (classes ? formatClasses(classes) : []),
    [classes, formatClasses],
  );

  const handleChange = useCallback(
    (e, value) => {
      let formattedValue = { ...value };

      if (Array.isArray(value)) {
        formattedValue = value.map((item) => {
          const newItem = { ...item };
          delete newItem.label;

          return newItem;
        });
      } else {
        delete formattedValue.label;
      }

      onChange(e, formattedValue, 'name');
    },
    [onChange],
  );

  const getIsAllSelected = useCallback(
    () =>
      selectableEntities.every(({ id, name }) =>
        selected.some((item) => item.id === id && item.name === name),
      ),
    [selectableEntities, selected],
  );

  return selectableEntities?.length ? (
    <Box>
      <BlockHeader subtitle="Select one or multiple classes" title={SharingLevels.CLASS.title} />
      <SelectableEntities
        columnsCount={columnsCount}
        entities={selectableEntities}
        firstComponent={
          selectableEntities.length > 1 && (
            <SelectAllEntities
              isChecked={getIsAllSelected()}
              label="All my classes"
              onChange={(e) => {
                handleChange(e, [...selectableEntities]);
              }}
            />
          )
        }
        onChange={handleChange}
        selected={selected}
      />
    </Box>
  ) : (
    <InitiativeEmptyStateBlock
      illustration={<ClassLevelEmptyState />}
      title={t('You don’t have any classes here')}
    />
  );
};

ClassesBlock.propTypes = {
  columnsCount: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  classes: PropTypes.arrayOf(
    PropTypes.shape({
      courseNumber: PropTypes.number,
      className: PropTypes.string,
      period: PropTypes.string,
      schoolName: PropTypes.string,
    }),
  ),
  selected: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ).isRequired,
};

ClassesBlock.defaultProps = {
  columnsCount: 2,
  classes: [],
};

export default ClassesBlock;
