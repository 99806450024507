import { useState } from 'react';
import dayjs from 'dayjs';

const useAssignmentDeadlinesState = (deadlinesData) => {
  const [deadlines, setDeadlines] = useState(deadlinesData);

  const onChange = (key, value) => {
    const newDeadlines = [...deadlines];
    const replaceDeadlineIndex = newDeadlines.findIndex((deadline) => deadline.key === key);
    newDeadlines[replaceDeadlineIndex].value = value;
    newDeadlines[replaceDeadlineIndex].errorMessage = '';
    setDeadlines(newDeadlines);
  };

  const validateDeadline = (validationDeadline) => {
    const { value, compareWith } = validationDeadline;
    const compareDeadline =
      compareWith && deadlines.find((deadline) => deadline.key === compareWith.key);
    const { value: compareValue } = compareDeadline ?? {};

    const setError = (errorText) => {
      const newDeadlines = [...deadlines];
      const replaceItemIndex = newDeadlines.findIndex(
        (deadline) => deadline.key === validationDeadline.key,
      );
      newDeadlines[replaceItemIndex].errorMessage = errorText;
      setDeadlines(newDeadlines);
    };

    let errorText;

    if (!value?.isValid()) {
      errorText = validationDeadline.formatError;
    } else if (value.isBefore(dayjs(), 'minute')) {
      errorText = validationDeadline.pastTimeError;
    } else if (compareWith?.isBefore && value.isBefore(compareValue, 'minute')) {
      errorText = compareWith.isBefore;
    } else if (compareWith?.isAfter && value.isAfter(compareValue, 'minute')) {
      errorText = compareWith.isAfter;
    } else if (compareWith?.isSame && value.isSame(compareValue, 'minute')) {
      errorText = compareWith.isAfter;
    }

    if (errorText) {
      setError(errorText);
      return errorText;
    }
    return '';
  };

  const validate = (validateKeys = deadlines.map((deadline) => deadline.key)) => {
    const validatedDeadlines = deadlines.filter((deadline) => validateKeys.includes(deadline.key));
    return validatedDeadlines.reduce((acc, deadline) => {
      const validationResult = validateDeadline(deadline);
      return validationResult !== ''
        ? [...acc, { errorText: validationResult, lvl3Label: deadline.title }]
        : acc;
    }, []);
  };

  return { state: deadlines, onChange, validate };
};

export default useAssignmentDeadlinesState;
