/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Badge } from '@mui/material';

const DotIndicator = React.forwardRef(({ children, className, variant, ...rest }, ref) => (
  <Badge
    ref={ref}
    className={`${classNames('ayo-dot-indicator', {
      [`${className}`]: className,
    })}`}
    overlap="circular"
    variant={variant}
    {...rest}
  >
    {children}
  </Badge>
));

DotIndicator.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Object)]),
  className: PropTypes.string,
  variant: PropTypes.string,
};

DotIndicator.defaultProps = {
  children: '',
  className: '',
  variant: 'dot',
};

export default DotIndicator;
