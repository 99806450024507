import React, { useContext, useEffect, useState, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, useMediaQuery, useTheme } from '@mui/material';

import { Typography, DynamicGrid } from '../../atoms';
import { EditingButton, NewFeatureHotspot, StudentFilters } from '../../moleculas';
import SuggestedActivitiesCard from '../suggested-activities-card/SuggestedActivitiesCard';
import { NewFeaturesIdsMap } from '../../../tours/common/NewFeaturesItemsProvider';
import { AppActions, AppContext, UserContext } from '../../../context';
import { focusNextItem, getFilterValueParts, transformSearchToObject } from '../../../utils';
import { ReactComponent as ExpandIcon } from '../../../resources/icons/chevron_down.svg';
import { ActivitiesFilterCategories, FilterActions, RolesMap } from '../../../constants/enums';
import {
  useFiltersLastActionState,
  useLeadershipAttributesData,
  useSearchParams,
} from '../../../hooks';

import ActivitiesTypeFilters from './filters/ActivitiesTypeFilters';
import ActivitiesLAFilters from './filters/ActivitiesLAFilters';

const initialLengthByScreen = {
  desktop: 6,
  mobile: 3,
};

const Filters = {
  [ActivitiesFilterCategories.TYPE]: {
    name: 'Types of suggestions',
    FilterBox: ActivitiesTypeFilters,
    isDisabled: () => false,
  },
  [ActivitiesFilterCategories.LEADERSHIP_ATTRIBUTES]: {
    name: 'Related Attribute',
    FilterBox: ActivitiesLAFilters,
    isDisabled: () => false,
  },
};

const FilterFunctions = {
  [ActivitiesFilterCategories.TYPE]: (suggestions, filtersList) =>
    suggestions.filter((suggestion) => filtersList.includes(suggestion.resource)),
  [ActivitiesFilterCategories.LEADERSHIP_ATTRIBUTES]: (suggestions, filtersList) =>
    suggestions.filter((suggestion) =>
      filtersList.includes(suggestion.leadershipAttributeKey.toLowerCase()),
    ),
};

const SuggestedActivitiesBlock = ({ assessmentKey }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const { state: userState } = useContext(UserContext);
  const match = useRouteMatch();
  const { studentId } = match.params;
  const isStudent = userState.profile.role === RolesMap.STUDENT;
  const currentStudentId = isStudent ? userState.profile.id : studentId;
  const { suggestedActivities } = useLeadershipAttributesData(currentStudentId);
  const [suggestions, setSuggestions] = useState([]);
  const [currentSuggestionsLength, setCurrentSuggestionsLength] = useState(null);
  const [nextFocusCard, setNextFocusCard] = useState(null);
  const [stats, setStats] = useState({});

  const search = useSearchParams();
  const filters = useMemo(() => transformSearchToObject(search), [search]);
  const [filtersUpdateState, updateLastActionState] = useFiltersLastActionState();
  const { dispatch: dispatchAppState } = useContext(AppContext);
  useEffect(() => {
    let finalizedSuggestions = suggestedActivities;
    if (assessmentKey) {
      finalizedSuggestions = finalizedSuggestions.filter(
        (activity) => activity.leadershipAttributeKey.toLowerCase() === assessmentKey,
      );
    }
    const suggestionsStats = finalizedSuggestions.reduce(
      (acc, activity) => {
        acc[ActivitiesFilterCategories.LEADERSHIP_ATTRIBUTES][
          activity.leadershipAttributeKey.toLowerCase()
        ] =
          (acc[ActivitiesFilterCategories.LEADERSHIP_ATTRIBUTES][
            activity.leadershipAttributeKey.toLowerCase()
          ] || 0) + 1;
        acc[ActivitiesFilterCategories.TYPE][activity.resource] =
          (acc[ActivitiesFilterCategories.TYPE][activity.resource] || 0) + 1;
        return acc;
      },
      {
        [ActivitiesFilterCategories.LEADERSHIP_ATTRIBUTES]: {},
        [ActivitiesFilterCategories.TYPE]: {},
      },
    );
    if (filters.filterCategory) {
      const { filtersList } = getFilterValueParts(filters, {});
      finalizedSuggestions = FilterFunctions[filters.filterCategory](
        finalizedSuggestions,
        filtersList,
      );
      setCurrentSuggestionsLength(
        isWidthUpSm ? initialLengthByScreen.desktop : initialLengthByScreen.mobile,
      );
    }
    let snackbarText;
    if (filtersUpdateState) {
      if (filtersUpdateState.action === FilterActions.ADD) {
        if (filtersUpdateState.value) {
          snackbarText = t('Apply one filter snackbar', { value: filtersUpdateState.value });
        } else {
          snackbarText = t('Apply filters snackbar');
        }
      }
      if (filtersUpdateState.action === FilterActions.CLEAR) {
        if (filtersUpdateState.value) {
          snackbarText = t('Clear one filter snackbar', { value: filtersUpdateState.value });
        } else {
          snackbarText = t('Clear filters snackbar');
        }
      }
      dispatchAppState({
        type: AppActions.SET_SNACKBAR_STATUS,
        data: { text: snackbarText, type: 'success' },
      });
    }
    setSuggestions(finalizedSuggestions);
    setStats(suggestionsStats);
  }, [
    assessmentKey,
    dispatchAppState,
    filters,
    filtersUpdateState,
    isWidthUpSm,
    suggestedActivities,
    t,
  ]);

  useEffect(() => {
    if (isWidthUpSm) {
      setCurrentSuggestionsLength(initialLengthByScreen.desktop);
    } else {
      setCurrentSuggestionsLength(initialLengthByScreen.mobile);
    }
  }, [isWidthUpSm]);

  useEffect(() => {
    if (nextFocusCard) {
      focusNextItem(nextFocusCard, suggestions.length, '.ayo-suggested-activities-card');
    }
  }, [nextFocusCard, suggestions]);

  return suggestions && suggestions.length ? (
    <Box>
      <Box alignItems="center" display="flex" flexWrap="wrap" justifyContent="space-between" mb={3}>
        <Box alignItems="center" display="flex">
          <Typography component="h2" variant="h2">
            {t('AYO suggestions')}
          </Typography>
          <NewFeatureHotspot id={NewFeaturesIdsMap.LEADERSHIP_ATTRIBUTES_SUGGESTIONS} isClickable />
        </Box>
      </Box>
      <Box mb={3}>
        <StudentFilters
          filtersConfig={Filters}
          onFiltersUpdate={updateLastActionState}
          selectedInitiative={assessmentKey ? ActivitiesFilterCategories.TYPE : null}
          stats={stats}
        />
      </Box>
      <DynamicGrid
        columnsCount={isWidthUpSm ? 3 : 1}
        gridItems={suggestions.slice(0, currentSuggestionsLength).map((suggestion) => (
          <Box key={suggestion.id} className="ayo-suggested-activities-card" tabIndex="-1">
            <SuggestedActivitiesCard assessmentKey={assessmentKey} suggestedActivity={suggestion} />
          </Box>
        ))}
      />
      {currentSuggestionsLength < suggestions.length && (
        <Box display="flex" justifyContent="flex-end" pt={3}>
          <EditingButton
            gaLabel="Show more"
            icon={<ExpandIcon />}
            iconPosition="end"
            onClick={() => {
              setNextFocusCard(currentSuggestionsLength);
              setCurrentSuggestionsLength(currentSuggestionsLength + (isWidthUpSm ? 6 : 3));
            }}
            text={t('Show more')}
          />
        </Box>
      )}
    </Box>
  ) : null;
};

SuggestedActivitiesBlock.propTypes = {
  assessmentKey: PropTypes.string,
};

SuggestedActivitiesBlock.defaultProps = {
  assessmentKey: null,
};

export default SuggestedActivitiesBlock;
