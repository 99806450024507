import React, { useMemo, useRef } from 'react';
import { Box, DialogActions, DialogContent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Button, Dialog, DialogTitle, Typography } from '../../../../atoms';
import { usePrint } from '../../../../../hooks';
import RichTextEditor from '../../../rich-text-editor/RichTextEditor';
import PortfolioPrintComponent from '../portfolio-print-component/PortfoloiPrintComponent';
import { ReactComponent as DownloadIcon } from '../../../../../resources/icons/download.svg';
import { updateEmbedsSrc } from '../../../../../utils';

const PortfolioFilePreviewDialog = ({
  isOpen,
  onClose,
  textJson,
  title,
  showSaveAsPDFButton,
  token,
}) => {
  const { t } = useTranslation();
  const ref = useRef();

  const { printHandler } = usePrint(ref);

  const textEditorData = useMemo(
    () => (token && textJson ? updateEmbedsSrc(textJson, 'image', token) : textJson),
    [token, textJson],
  );

  return (
    <Dialog
      className="ayo-portfolio__preview-dialog"
      gaLabel="File preview dialog"
      maxWidth={700}
      onClose={onClose}
      open={isOpen}
    >
      <DialogTitle disableTypography>
        <Box pb={3}>
          <Typography component="h2" variant="subtitle1">
            {t(title)}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box className="ayo-portfolio__preview-dialog__content">
          <RichTextEditor isStatic value={textEditorData} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Box columnGap={3} display="flex" pt={3}>
          <Button gaLabel="Close preview window" onClick={onClose} variant="text">
            {t('Close')}
          </Button>
          {showSaveAsPDFButton && (
            <Button
              endIcon={<DownloadIcon height="24px" width="24px" />}
              gaLabel="Save as PDF"
              onClick={printHandler}
              variant="text"
            >
              {t('Save as PDF')}
            </Button>
          )}
        </Box>
      </DialogActions>
      <PortfolioPrintComponent ref={ref} value={textEditorData} />
    </Dialog>
  );
};

PortfolioFilePreviewDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  showSaveAsPDFButton: PropTypes.bool,
  textJson: PropTypes.string,
  title: PropTypes.string.isRequired,
  token: PropTypes.string,
};

PortfolioFilePreviewDialog.defaultProps = {
  showSaveAsPDFButton: false,
  textJson: null,
  token: null,
};

export default PortfolioFilePreviewDialog;
