import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { Button } from '../../atoms';
import { ReactComponent as NavigationBackChevron } from '../../../resources/icons/chevron_left.svg';

const BackNavigationLink = ({ icon, text, backToLink, onClick }) => {
  const history = useHistory();

  const navigateBack = useCallback(() => {
    if (backToLink) {
      history.push(backToLink);
      onClick?.();
    } else history.goBack();
  }, [backToLink, history, onClick]);

  return (
    <Button
      className="ayo-back-navigation-link"
      gaLabel={`${text} back navigation`}
      onClick={navigateBack}
    >
      {icon || <NavigationBackChevron />}
      {text}
    </Button>
  );
};

BackNavigationLink.propTypes = {
  icon: PropTypes.node,
  text: PropTypes.string,
  backToLink: PropTypes.string,
  onClick: PropTypes.func,
};

BackNavigationLink.defaultProps = {
  icon: null,
  text: 'Back to main page',
  backToLink: null,
  onClick: null,
};

export default BackNavigationLink;
