import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

import { Checkbox, FormControlLabel, Tooltip } from '../../../../../atoms';
import AssignmentNoEditTooltip from '../../assignment-no-edit-tooltip/AssignmentNoEditTooltip';
import { ReactComponent as InfoIcon } from '../../../../../../resources/icons/info.svg';

const AssignmentSettings = ({ settings, onSettingsChange, disabled }) => {
  const { t } = useTranslation();

  return (
    <Box>
      {settings.map((option) => (
        <Box key={option.key} className="ayo-lesson-assignment-settings" my={3}>
          <AssignmentNoEditTooltip showTooltip={disabled}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={option.value}
                  disabled={disabled}
                  gaLabel={`${option.title} ${!option.value ? 'Select' : 'Unselect'}`}
                  inputProps={{ name: option.key }}
                  onChange={(e) => onSettingsChange(option.key, e.target.checked)}
                  value={option.value}
                />
              }
              label={t(option.title)}
            />
          </AssignmentNoEditTooltip>
          {option.tooltip && (
            <Tooltip enterTouchDelay={0} leaveTouchDelay={5000} title={t(option.tooltip)}>
              <InfoIcon tabIndex="0" />
            </Tooltip>
          )}
        </Box>
      ))}
    </Box>
  );
};

AssignmentSettings.propTypes = {
  settings: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  onSettingsChange: PropTypes.func,
  disabled: PropTypes.bool,
};

AssignmentSettings.defaultProps = {
  settings: [],
  onSettingsChange: () => {},
  disabled: false,
};

export default AssignmentSettings;
