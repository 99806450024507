export const EventsLocationTypes = {
  ONLINE: 'ONLINE',
  OFFLINE: 'OFFLINE',
};

export const EventDialogModes = {
  CREATE: 'CREATE',
  EDIT: 'EDIT',
  VIEW: 'VIEW',
  VISIBILITY_SETTINGS: 'VISIBILITY_SETTINGS',
};

export const EventResourceTypes = {
  CLUB_HUB: 'CLUB_HUB',
  FAMILY_FEED: 'FAMILY_FEED',
};

export const EventKeys = {
  NAME: 'name',
  DESCRIPTION: 'description',
  LOCATION_TYPE: 'locationType',
  LOCATION_PLACEMENT: 'locationPlacement',
  START_DATE: 'startDate',
  END_DATE: 'endDate',
  RESOURCE_TYPE: 'resourceType',
  RESOURCE_ID: 'resourceId',
  SHARING_LEVEL: 'sharingLevel',
  SHARING_GROUPS: 'sharingGroups',
  SELECTED_ENTITIES: 'selectedEntities',
};

export const EventSharingLevel = {
  [EventResourceTypes.CLUB_HUB]: 'CLUB',
};

export const EventSharingGroupsLabel = {
  [EventResourceTypes.FAMILY_FEED]: {
    ALL: {
      label: 'Everyone',
    },
    GUARDIANS_STUDENTS: {
      label: 'Parents and students',
    },
    GUARDIANS: {
      label: 'Parents',
    },
    STAFF: {
      label: 'Staff representatives',
    },
  },
  [EventResourceTypes.CLUB_HUB]: {
    STUDENTS: {
      label: 'Club members can view this event.',
    },
  },
};

export const EventSharingGroups = {
  ALL: 'ALL',
  GUARDIANS: 'GUARDIANS',
  STAFF: 'STAFF',
  STUDENTS: 'STUDENTS',
};

export const DefaultSharingGroups = {
  DISTRICT: ['ALL'],
  CAMPUS: ['ALL'],
  CLASS: ['GUARDIANS', 'STUDENTS'],
  PERSONALIZED: ['GUARDIANS', 'STUDENTS'],
};
