import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import classNames from 'classnames';

import { LinearProgress, Link, Typography } from '../../atoms';
import EditingButton from '../editing-button/EditingButton';
import { IconsByTypeMap } from '../../../constants/fileTypes';
import { plugRoute } from '../../../constants/routes';
import { ContentTypesMap } from '../../../constants/enums';
import { getFileTypeFromExtension, getFormattedDate } from '../../../utils';
import { ReactComponent as ExternalLink } from '../../../resources/icons/link.svg';
import { ReactComponent as RemoveIcon } from '../../../resources/icons/delete_primary.svg';
import { ReactComponent as SuccessIcon } from '../../../resources/icons/check_circle.svg';
import { ReactComponent as FailureIcon } from '../../../resources/icons/close_red.svg';
import { ReactComponent as LinkGeneral } from '../../../resources/icons/link_general.svg';

const FileLinkRemovableItem = ({
  className,
  createdLabel,
  isDisabled,
  isStatic,
  item,
  onItemClick,
  onRemoveClick,
  type,
  withIcon,
}) => {
  const { i18n, t } = useTranslation();

  const Icon = useMemo(() => {
    switch (type) {
      case 'file':
        return IconsByTypeMap[getFileTypeFromExtension(item.fileName)];
      case 'link':
        return IconsByTypeMap[item.metaData?.linkType] ?? IconsByTypeMap[ContentTypesMap.GENERAL];
      default:
        return null;
    }
  }, [item, type]);

  const Label = useMemo(() => {
    switch (type) {
      case 'file': {
        const fileNameElement = (
          <Box display="flex" flexDirection="row">
            <span className="ayo-filelink-removable-item__link__file-title">
              {item.fileName.substring(0, item.fileName.lastIndexOf('.'))}
            </span>
            <span className="ayo-filelink-removable-item__link__file-type">
              {item.fileName.substring(item.fileName.lastIndexOf('.'), item.fileName.length)}
            </span>
          </Box>
        );
        return onItemClick ? (
          <Link
            className="ayo-filelink-removable-item__link"
            gaLabel="Materials file - open preview"
            href={plugRoute}
            icon={<div />}
            onClick={(e) => {
              e.preventDefault();
              if (onItemClick) {
                onItemClick();
              }
            }}
            underline="none"
          >
            {fileNameElement}
          </Link>
        ) : (
          <Typography variant="subtitle2">{fileNameElement}</Typography>
        );
      }
      case 'link':
        return (
          <Link
            className="ayo-filelink-removable-item__link"
            gaLabel="Materials link - open"
            href={item.url}
            icon={<ExternalLink />}
            target="_blank"
            underline="none"
          >
            {`${item.metaData?.description ?? ''}${
              item.metaData?.description && item.text ? ' - ' : ''
            }${item.text ?? ''}`}
          </Link>
        );
      default:
        return null;
    }
  }, [item, onItemClick, type]);

  const StatusText = useMemo(() => {
    switch (type) {
      case 'file':
        return item.updatedDate ? (
          <Box alignItems="center" display="flex">
            <SuccessIcon height={16} width={16} />
            <Box ml={1}>
              <Typography isLabel variant="body3">
                {`${createdLabel}: ${getFormattedDate(item.updatedDate, i18n.language)}`}
              </Typography>
            </Box>
          </Box>
        ) : null;
      case 'link':
        return item.createdDate ? (
          <Typography isLabel variant="body3">
            {`${createdLabel}: ${getFormattedDate(item.createdDate, i18n.language)}`}
          </Typography>
        ) : null;
      default:
        return null;
    }
  }, [createdLabel, i18n.language, item.createdDate, item.updatedDate, type]);

  return (
    <Box
      key={item.url || item.id}
      className={classNames('ayo-filelink-removable-item', className)}
      mt={1}
      px={2.5}
      py={1}
    >
      {withIcon && (<Icon /> || <LinkGeneral />)}
      <Box flex={1} minWidth={0} mx={2}>
        {Label}
        {StatusText}
        {item.progress && (
          <Box alignItems="center" display="flex" flexDirection="row" position="relative">
            <Typography isLabel variant="body3">
              {t('Uploading the file')}
            </Typography>
            <LinearProgress value={item.progress} />
            <Typography className="progress-label" isLabel variant="body3">
              {`${item.progress}%`}
            </Typography>
          </Box>
        )}
        {item.errors?.length > 0 && (
          <Box alignItems="center" display="flex">
            <FailureIcon height={16} width={16} />
            <Box ml={1}>
              <Typography className="error-label" isLabel variant="body3">
                {item.errors[0]}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
      {!isStatic && !isDisabled && (
        <EditingButton
          gaLabel={`Remove ${type}`}
          icon={<RemoveIcon />}
          iconPosition="end"
          onClick={onRemoveClick}
          text={t('Remove')}
        />
      )}
    </Box>
  );
};

FileLinkRemovableItem.propTypes = {
  createdLabel: PropTypes.string,
  isDisabled: PropTypes.bool,
  isStatic: PropTypes.bool,
  item: PropTypes.instanceOf(Object).isRequired,
  onItemClick: PropTypes.func,
  onRemoveClick: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['file', 'link']).isRequired,
  withIcon: PropTypes.bool,
  className: PropTypes.string,
};

FileLinkRemovableItem.defaultProps = {
  createdLabel: '',
  isDisabled: false,
  isStatic: false,
  onItemClick: null,
  withIcon: true,
  className: null,
};

export default FileLinkRemovableItem;
