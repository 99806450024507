import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { DialogContent, DialogActions, Box } from '@mui/material';

import {
  Dialog,
  DialogTitle,
  Typography,
  Button,
  Checkbox,
  FormControlLabel,
} from '../../../../../atoms';
import { PopupActionsButtons } from '../../../../../moleculas';
import { useAdminService } from '../../../../../../services';
import { AdminActionsStatusesMap } from '../../../../../../constants/enums';
import { QueryParams } from '../../../../../../constants/routes';
import { ReactComponent as DownloadIcon } from '../../../../../../resources/icons/download.svg';

const DeleteUserDataDialog = ({
  isOpen,
  onClose,
  person,
  exportDataHandler,
  setSnackbarText,
  setSnackbarOpen,
}) => {
  const { t } = useTranslation();
  const [isStatementChecked, setIsStatementChecked] = useState(false);
  const { requestDataDeletion } = useAdminService();
  const closeDialogHandler = useCallback(() => {
    setIsStatementChecked(false);
    onClose();
  }, [onClose]);
  const handleStatementChange = (e) => setIsStatementChecked(e.target.checked);
  const history = useHistory();

  const exportHandler = useCallback(() => {
    exportDataHandler(person.id);
    closeDialogHandler();
  }, [exportDataHandler, closeDialogHandler, person.id]);

  const deleteDataHandler = useCallback(() => {
    setSnackbarOpen(false);
    history.push(`?${QueryParams.DELETE_ACTION}`);
    requestDataDeletion(person.email, person.loginId, person.role)
      .catch(({ response }) => {
        const processInProgress = response.data.errorCode;
        setSnackbarText(
          t(
            'The action of name data is already in progress You will be informed by email when it is complete',
            { action: t(AdminActionsStatusesMap[processInProgress]), name: person.firstName },
          ),
        );
        setSnackbarOpen(true);
      })
      .finally(() => history.replace({ search: null }));
    closeDialogHandler();
  }, [
    closeDialogHandler,
    history,
    person,
    requestDataDeletion,
    setSnackbarOpen,
    setSnackbarText,
    t,
  ]);

  return (
    <Dialog className="ayo-delete-dialog" onClose={closeDialogHandler} open={isOpen}>
      <DialogTitle disableTypography>
        <Box pb={1} pr={4}>
          <Typography component="h2" variant="subtitle1">
            <Trans
              components={{ span: <span className="ayo-delete-dialog__firstName" /> }}
              i18nKey="Delete name data from the AYO database?"
              values={{ name: person.firstName }}
            />
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box py={2}>
          <Typography variant="subtitle2">
            <Trans
              components={{ span: <span className="ayo-delete-dialog__firstName" /> }}
              i18nKey="Please export name data first, and then delete it"
              values={{ name: person.firstName }}
            />
          </Typography>
        </Box>
        <Button download endIcon={<DownloadIcon />} onClick={exportHandler}>
          {t('Export data')}
        </Button>
        <Box py={2}>
          <Typography variant="subtitle2">
            {t('Before deleting, please accept the following statement')}
          </Typography>
        </Box>
        <Box pl={0.5}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isStatementChecked}
                onChange={handleStatementChange}
                value="consent"
              />
            }
            label={
              <>
                <Typography variant="body2">
                  {t(
                    'I understand that the user’s data will not be restored I still want to delete it',
                  )}
                </Typography>
              </>
            }
          />
        </Box>
        <Box py={2}>
          <Typography variant="body2">
            {t('You will be informed')} <b>{t('by email')}</b>{' '}
            {t('when the data deletion process is completed')}
          </Typography>
          <Typography variant="subtitle2">
            {t('This process can take up to several days')}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <PopupActionsButtons
          primaryButtonComponent={
            <Button
              disabled={!isStatementChecked}
              fullWidth
              onClick={deleteDataHandler}
              variant="danger"
            >
              {t('Delete the data')}
            </Button>
          }
          secondaryButtonHandler={closeDialogHandler}
          secondaryButtonText={t('Keep the data')}
        />
      </DialogActions>
    </Dialog>
  );
};

DeleteUserDataDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  exportDataHandler: PropTypes.func.isRequired,
  setSnackbarText: PropTypes.func.isRequired,
  setSnackbarOpen: PropTypes.func.isRequired,
  person: PropTypes.instanceOf(Object).isRequired,
};

export default DeleteUserDataDialog;
