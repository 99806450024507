/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box, useTheme, useMediaQuery } from '@mui/material';

import { ClickableCard } from '../../../../moleculas';
import { personPropShape } from '../../../../../constants/propTypes';

const StudentsGridItem = React.forwardRef(
  ({ item, href, studentStatusIcon, studentAriaLabel, ...props }, ref) => {
    const theme = useTheme();
    const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
    return (
      <Grid item lg={4} md={4} sm={6} xs={12}>
        <Box px={1.5} py={isWidthUpSm ? 1 : 0.5}>
          <div {...props} ref={ref}>
            <ClickableCard
              ariaLabel={studentAriaLabel}
              gaLabel="Student card"
              href={href}
              isActive={item.active}
              statusIcon={studentStatusIcon}
              title={item.name}
            />
          </div>
        </Box>
      </Grid>
    );
  },
);

StudentsGridItem.propTypes = {
  studentAriaLabel: PropTypes.string,
  item: PropTypes.shape({
    ...personPropShape,
    active: PropTypes.bool,
    name: PropTypes.string,
  }).isRequired,
  href: PropTypes.string.isRequired,
  studentStatusIcon: PropTypes.node,
};

StudentsGridItem.defaultProps = {
  studentStatusIcon: null,
  studentAriaLabel: null,
};

export default StudentsGridItem;
