import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { Typography } from '../../../../../atoms';
import { InitiativeEmptyStateBlock } from '../../../../../moleculas';
import { QuestionnairesTypes } from '../../../../../../constants/enums';
import { useQuestionnaireService } from '../../../../../../services';
import { ReactComponent as ParentsAboutKidEmptyStateIllustration } from '../../../../../../resources/images/parents_about_kid_empty_state.svg';
import { transformAnswerState } from '../../../../../../utils';

import QuestionnaireTypeBlock from './components/questionnaire-type-block/QuestionnaireTypeBlock';

const QuestionAnswerBlock = ({ student }) => {
  const { t, i18n } = useTranslation();
  const { getQuestionsAnswers } = useQuestionnaireService();
  const [questions, setQuestions] = useState({});

  useEffect(() => {
    if (student?.id) {
      getQuestionsAnswers(i18n.language, student.id).then((questionsAnswers) => {
        if (questionsAnswers.guardians.some((guardian) => guardian.questionnaires.length)) {
          setQuestions({
            [QuestionnairesTypes.INITIAL]: transformAnswerState(
              questionsAnswers.guardians,
              QuestionnairesTypes.INITIAL,
            ),
            [QuestionnairesTypes.FOLLOW_UP]: transformAnswerState(
              questionsAnswers.guardians,
              QuestionnairesTypes.FOLLOW_UP,
            ),
          });
        }
      });
    }
  }, [i18n.language, student?.id, getQuestionsAnswers]);

  if (!student) {
    return null;
  }

  return (
    <>
      <Box mb={3}>
        <Typography component="h2" variant="h2">
          {t('Parents about')} {student.firstName}
        </Typography>
      </Box>
      {Object.keys(questions)?.length ? (
        <>
          <QuestionnaireTypeBlock
            questions={questions[QuestionnairesTypes.INITIAL]}
            student={student}
            title={t('Tell us about your kid(s)')}
          />
          <QuestionnaireTypeBlock
            questions={questions[QuestionnairesTypes.FOLLOW_UP]}
            student={student}
            title={t('Tell us more about your kid(s)')}
            withDivider
          />
        </>
      ) : (
        <InitiativeEmptyStateBlock
          illustration={<ParentsAboutKidEmptyStateIllustration />}
          title={t('No answers from parents so far')}
        />
      )}
    </>
  );
};
QuestionAnswerBlock.propTypes = {
  student: PropTypes.instanceOf(Object),
};

QuestionAnswerBlock.defaultProps = {
  student: {},
};

export default QuestionAnswerBlock;
