import React from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { ActionsMenu, Typography } from '../../../../../../atoms';
import { Chip, Dropdown, InformationalCaption } from '../../../../../../moleculas';
import {
  assignmentInsightGroupnameType,
  assignmentStudentType,
} from '../../../../../../../constants/propTypes';
import { ReactComponent as PlusIcon } from '../../../../../../../resources/icons/plus.svg';
import { ReactComponent as MinusIcon } from '../../../../../../../resources/icons/minus.svg';
import AssignmentTypeBlock from '../../../assignment-type-block/AssignmentTypeBlock';
import AssignmentGroupNameChip from '../../../assignment-group-name-chip/AssignmentGroupNameChip';
import { getAssignmentsOptions } from '../../../edit-assignments/helpers';

const InsightAssignmentGroup = ({
  id,
  type,
  order,
  assignmentType,
  students,
  movedStudents,
  typeBlockContent,
  onStudentsGroupChange,
  onStudentsGroupDelete,
  availableGroups,
  onAssignmentTypeChange,
}) => {
  const { t } = useTranslation();

  const AssignmentsOptions = getAssignmentsOptions(t);

  return (
    <Box className="assignment-insight-selected-group">
      <Box mb={4}>
        <Typography variant="subtitle1">
          {t('Assignment')} #{order}
        </Typography>
      </Box>
      <AssignmentTypeBlock content={typeBlockContent} type={type} />
      <Box mb={4}>
        <Box mb={1}>
          <Typography variant="subtitle2">{t('Type')}</Typography>
        </Box>
        <Dropdown
          gaLabel="Assignments insights assignment type dropdown"
          handleChange={(e) => onAssignmentTypeChange(type, id, e.target.value)}
          options={AssignmentsOptions}
          outlined
          value={assignmentType}
        />
      </Box>
      <Box>
        <Box mb={2}>
          <Typography variant="subtitle2">{t('Assigned students')}</Typography>
        </Box>
        <Box display="flex" flexWrap="wrap" gap={1}>
          {students.length ? (
            students.map((student) =>
              onStudentsGroupChange ? (
                <ActionsMenu
                  ActivatorComponent={Chip}
                  activatorIcon={null}
                  activatorProps={{
                    label: student.name,
                    icon: <PlusIcon />,
                    isRtl: true,
                    className: 'editable-student',
                  }}
                  activatorVariant="secondary"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  id={student.id}
                  label={student.name}
                  menuItems={availableGroups.map((group, idx) => ({
                    id: group.id,
                    content: (
                      <Box>
                        <Typography variant="body2">
                          {t('Add to')} {t('Assignment')} #{idx + 1}
                        </Typography>
                        <Box alignItems="flex-start" display="flex" flexDirection="column" gap={1}>
                          {group.groupNames.map((groupName) => (
                            <AssignmentGroupNameChip
                              key={groupName.value}
                              groupName={groupName}
                              type={group.categoryName}
                            />
                          ))}
                        </Box>
                      </Box>
                    ),
                    handler: () => onStudentsGroupChange(group.categoryName, group.id, student.id),
                  }))}
                  noIconsRecolor
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                />
              ) : (
                <Chip key={student.id} className="static-student" label={student.name} />
              ),
            )
          ) : (
            <InformationalCaption
              title={t(
                'There are no assigned students; therefore, this assignment won’t be created.',
              )}
            />
          )}
          {movedStudents?.map((student) => (
            <Chip
              key={student.id}
              className="editable-student"
              deleteIcon={<MinusIcon />}
              label={student.name}
              onDelete={() => onStudentsGroupDelete(type, id, student.id)}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

InsightAssignmentGroup.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  order: PropTypes.number.isRequired,
  assignmentType: PropTypes.string.isRequired,
  students: PropTypes.arrayOf(assignmentStudentType).isRequired,
  movedStudents: PropTypes.arrayOf(assignmentStudentType),
  typeBlockContent: PropTypes.node.isRequired,
  onStudentsGroupChange: PropTypes.func,
  onStudentsGroupDelete: PropTypes.func,
  availableGroups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      groupNames: PropTypes.arrayOf(PropTypes.shape(assignmentInsightGroupnameType)),
      groupCategory: PropTypes.string,
    }),
  ),
  onAssignmentTypeChange: PropTypes.func.isRequired,
};

InsightAssignmentGroup.defaultProps = {
  movedStudents: null,
  availableGroups: null,
  onStudentsGroupDelete: null,
  onStudentsGroupChange: null,
};

export default InsightAssignmentGroup;
