import React from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Card, Tooltip, Typography } from '../../atoms';
import { capitalize } from '../../../utils';
import TextWithTooltip from '../tooltip-wrappers/text-with-tooltip/TextWithTooltip';

const AchievementCard = ({ achievementData, className, titleVariant, elevation }) => {
  const { t } = useTranslation();

  return (
    <Card
      className={classNames('ayo-achievement-card', className)}
      elevation={elevation}
      mainContent={
        <Box display="flex" flexDirection="row">
          {achievementData.image && (
            <Tooltip
              enterTouchDelay={0}
              leaveTouchDelay={5000}
              placement="bottom-start"
              title={t(capitalize(achievementData.category))}
            >
              <achievementData.image aria-label={t(achievementData.label)} role="img" />
            </Tooltip>
          )}
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            ml={3}
            overflow="hidden"
          >
            <TextWithTooltip
              headerLevel={titleVariant === 'subtitle1' ? 4 : titleVariant === 'p' ? null : 3}
              title={achievementData.name}
              titleVariant="subtitle2"
            />
            <Typography noWrap variant="caption">
              {achievementData.year.join(', ')}
            </Typography>
          </Box>
        </Box>
      }
    />
  );
};

AchievementCard.propTypes = {
  achievementData: PropTypes.shape({
    category: PropTypes.string,
    image: PropTypes.instanceOf(Object),
    label: PropTypes.string,
    name: PropTypes.string,
    year: PropTypes.arrayOf(PropTypes.number),
  }),
  className: PropTypes.string,
  titleVariant: PropTypes.string,
  elevation: PropTypes.number,
};

AchievementCard.defaultProps = {
  achievementData: {},
  className: '',
  titleVariant: 'subtitle1',
  elevation: 0,
};

export default AchievementCard;
