import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Box } from '@mui/material';

import { Button } from '../../atoms';
import { useLogin, useProfile } from '../../../utils';
import { useProfileService } from '../../../services';
import { AppActions, AppContext, UserContext } from '../../../context';
import { DeviceTypesMap, LanguagesMap, LoginProvidersMap } from '../../../constants/enums';
import DisabledControlWithTooltip from '../tooltip-wrappers/disabled-control-with-tooltip/DisabledControlWithTooltip';

const LanguageButtonsMap = {
  [LanguagesMap.EN_US]: {
    label: 'EN',
    ariaLabel: 'English language',
    gaLabel: 'Change language to English',
    default: true,
  },
  [LanguagesMap.ES_US]: {
    label: 'ES',
    ariaLabel: 'Spanish language',
    gaLabel: 'Change language to Spanish',
  },
};

export const LanguageButtons = ({ disabledLanguageSelectorText }) => {
  const { t, i18n } = useTranslation();
  const { patchLanguage } = useProfileService();
  const { changeLanguage } = useProfile();
  const { state: userState } = useContext(UserContext);

  const handleLanguageChangeClick = useCallback(
    (e, lng) => {
      e.preventDefault();

      if (userState.profile) {
        const value = Object.keys(LanguagesMap).find((key) => LanguagesMap[key] === lng);
        patchLanguage(value).then(() => {
          changeLanguage(lng);
        });
      } else {
        changeLanguage(lng);
      }
    },
    [changeLanguage, patchLanguage, userState],
  );

  return (
    <Box className="ayo-language-buttons" display="flex">
      {Object.keys(LanguageButtonsMap).map((key) => {
        const isDefaultLanugage = LanguageButtonsMap[key].default;
        const isCurrentLanguage = i18n.language?.split('-')[0] === key.split('-')[0];
        const handleLanguageClick = (e) => {
          handleLanguageChangeClick(e, key);
        };
        return (
          <DisabledControlWithTooltip
            key={key}
            gaLabel="disabled language button"
            showTooltip={disabledLanguageSelectorText && !isDefaultLanugage}
            title={disabledLanguageSelectorText}
          >
            <Button
              key={key}
              aria-label={`${t(LanguageButtonsMap[key].ariaLabel)} ${
                isCurrentLanguage ? t('selected') : ''
              }`}
              className={classnames({
                'Mui-hover':
                  (disabledLanguageSelectorText && isDefaultLanugage) ||
                  (!disabledLanguageSelectorText && isCurrentLanguage),
              })}
              disabled={disabledLanguageSelectorText && !isDefaultLanugage}
              gaLabel={LanguageButtonsMap[key].gaLabel}
              onClick={!disabledLanguageSelectorText && handleLanguageClick}
            >
              {t(LanguageButtonsMap[key].label)}
            </Button>
          </DisabledControlWithTooltip>
        );
      })}
    </Box>
  );
};

LanguageButtons.propTypes = {
  disabledLanguageSelectorText: PropTypes.string,
};

LanguageButtons.defaultProps = {
  disabledLanguageSelectorText: null,
};

export const LogoutButton = ({ buttonText, fullWidth }) => {
  const { t } = useTranslation();
  const { logout } = useLogin();
  const { state: appState, dispatch: dispatchAppState } = useContext(AppContext);

  const isPublicClassLinkDevice =
    appState.deviceType === DeviceTypesMap.PUBLIC &&
    appState.loginProvider === LoginProvidersMap.CLASSLINK;

  const handleLogout = useCallback(() => {
    // Prevent changes dialog from appearing, as it is handled by DirtyPrompt
    window.onbeforeunload = undefined;
    if (isPublicClassLinkDevice) {
      if (appState.classLinkReminderOpen) {
        dispatchAppState({ type: AppActions.SET_CLASSLINK_REMINDER_OPEN, data: false });
        logout();
      } else {
        dispatchAppState({ type: AppActions.SET_CLASSLINK_REMINDER_OPEN, data: true });
      }
    } else {
      logout();
    }
  }, [appState.classLinkReminderOpen, dispatchAppState, isPublicClassLinkDevice, logout]);

  const onLogoutWaiter = appState.onLogout.waiter;

  const handleLogoutClick = useCallback(() => {
    if (appState.isDirty && !appState.idleTimeoutExpired) {
      onLogoutWaiter().then((shouldLogout) => {
        if (shouldLogout) {
          handleLogout();
        }
      });
    } else {
      handleLogout();
    }
  }, [appState.isDirty, appState.idleTimeoutExpired, handleLogout, onLogoutWaiter]);

  return (
    <Button fullWidth={fullWidth} gaLabel={buttonText} onClick={handleLogoutClick}>
      {t(buttonText)}
    </Button>
  );
};

LogoutButton.propTypes = {
  buttonText: PropTypes.string,
  fullWidth: PropTypes.bool,
};

LogoutButton.defaultProps = {
  buttonText: 'Log out',
  fullWidth: false,
};

export default {
  LanguageButtons,
  LogoutButton,
};
