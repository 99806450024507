import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

import AssignmentGroupNameChip from '../assignment-group-name-chip/AssignmentGroupNameChip';
import { assignmentInsightGroupnameType } from '../../../../../constants/propTypes';

const AssignmentGroupnamesList = ({ groupNames, categoryName }) => (
  <Box display="flex" flexWrap="wrap" gap={1}>
    {groupNames.map((groupName) => (
      <AssignmentGroupNameChip key={groupName.value} groupName={groupName} type={categoryName} />
    ))}
  </Box>
);

AssignmentGroupnamesList.propTypes = {
  groupNames: PropTypes.arrayOf(PropTypes.shape(assignmentInsightGroupnameType)).isRequired,
  categoryName: PropTypes.string.isRequired,
};

export default AssignmentGroupnamesList;
