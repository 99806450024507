import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';

import { PopupActionsButtons, STTTextField } from '../../../../moleculas';
import { LeaveDialog } from '../../../leave-dialog/LeaveDialog';
import { InputsValidationErrors, InputsValidationRules } from '../../../../../constants/enums';
import { AppActions, AppContext } from '../../../../../context';

const CommentsForm = ({
  addNewCommentHandler,
  cancelEditMode,
  commentId,
  commentValueEdit,
  editCommentHandler,
  isButtonsFullWidth,
  isLeaveDialogDisabled,
  onAfterSubmit,
  onCancel,
  outlined,
  page,
  parentCommentId,
  parentCommentTitle,
  relatedUserId,
  replyHandler,
  resourceId,
  showFormHandler,
  subjectValueEdit,
  submitButtonText,
}) => {
  const { t } = useTranslation();
  const [subjectValue, setSubjectValue] = useState(subjectValueEdit);
  const [commentValue, setCommentValue] = useState(commentValueEdit);
  const [commentErrorMessage, setCommentErrorMessage] = useState('');
  const [subjectErrorMessage, setSubjectErrorMessage] = useState('');

  const { state: appState, dispatch: dispatchAppState } = useContext(AppContext);

  const setIsDirty = useCallback(
    (value) => {
      dispatchAppState({ type: AppActions.SET_IS_DIRTY, data: value });
    },
    [dispatchAppState],
  );

  const resetForm = () => {
    setSubjectValue('');
    setCommentValue('');
    setCommentErrorMessage('');
    setSubjectErrorMessage('');
  };

  useEffect(() => {
    resetForm();
    if (commentValueEdit && !isLeaveDialogDisabled) {
      setIsDirty(true);
      setSubjectValue(subjectValueEdit);
      setCommentValue(commentValueEdit);
    }
  }, [page, relatedUserId, commentValueEdit, subjectValueEdit, setIsDirty, isLeaveDialogDisabled]);

  const handleCommentChange = useCallback(
    (e) => {
      setCommentValue(e.target.value);
      if (!appState.isDirty && !isLeaveDialogDisabled) {
        setIsDirty(true);
      }
      if (commentErrorMessage) {
        setCommentErrorMessage('');
      }
    },
    [appState.isDirty, commentErrorMessage, isLeaveDialogDisabled, setIsDirty],
  );

  const handleSubjectTitleChange = useCallback(
    (e) => {
      setSubjectValue(e.target.value);
      if (!appState.isDirty && !isLeaveDialogDisabled) {
        setIsDirty(true);
      }
      if (subjectErrorMessage) {
        setSubjectErrorMessage('');
      }
    },
    [appState.isDirty, isLeaveDialogDisabled, subjectErrorMessage, setIsDirty],
  );

  const isValid = useCallback(() => {
    let commentValidationError = '';
    let subjectValidationError = '';

    if (!parentCommentId && subjectValue.length < InputsValidationRules.MIN_INPUT_LENGTH) {
      subjectValidationError = InputsValidationErrors(
        t,
        InputsValidationRules.MIN_INPUT_LENGTH,
      ).MIN_ERROR_TEXT;
    } else if (subjectValue.length > InputsValidationRules.MAX_INPUT_LENGTH) {
      subjectValidationError = InputsValidationErrors(
        t,
        InputsValidationRules.MAX_INPUT_LENGTH,
      ).MAX_ERROR_TEXT;
    }
    if (commentValue.length < InputsValidationRules.MIN_INPUT_LENGTH) {
      commentValidationError = InputsValidationErrors(
        t,
        InputsValidationRules.MIN_INPUT_LENGTH,
      ).MIN_ERROR_TEXT;
    } else if (commentValue.length > InputsValidationRules.MAX_INPUT_LENGTH) {
      commentValidationError = InputsValidationErrors(
        t,
        InputsValidationRules.MAX_INPUT_LENGTH,
      ).MAX_ERROR_TEXT;
    }

    setSubjectErrorMessage(subjectValidationError);
    setCommentErrorMessage(commentValidationError);
    return !commentValidationError && !subjectValidationError;
  }, [parentCommentId, commentValue.length, subjectValue.length, t]);

  const setIsLeaveDialogOpen = useCallback(
    (value) => {
      dispatchAppState({ type: AppActions.SET_IS_LEAVE_DIALOG_OPEN, data: value });
    },
    [dispatchAppState],
  );

  const submitComment = useCallback(() => {
    const postBody = {
      title: subjectValue || null,
      body: commentValue,
      parentCommentId,
      page,
      resourceId,
      relatedUserId,
    };
    addNewCommentHandler(postBody, postBody?.parentCommentId, onAfterSubmit);
    resetForm();
    replyHandler();
  }, [
    onAfterSubmit,
    addNewCommentHandler,
    commentValue,
    page,
    parentCommentId,
    replyHandler,
    relatedUserId,
    resourceId,
    subjectValue,
  ]);

  const onCancelFormHandler = useCallback(() => {
    if (!parentCommentId) {
      showFormHandler();
    }
    replyHandler();
    resetForm();
  }, [showFormHandler, parentCommentId, replyHandler]);

  const onCancelHandler = useCallback(() => {
    if (!isLeaveDialogDisabled) {
      setIsDirty(false);
    }
    if (commentValueEdit) {
      cancelEditMode();
    } else {
      onCancelFormHandler();
    }
    if (onCancel) {
      onCancel();
    }
  }, [
    isLeaveDialogDisabled,
    commentValueEdit,
    onCancel,
    setIsDirty,
    cancelEditMode,
    onCancelFormHandler,
  ]);

  const onSaveEditsHandler = useCallback(() => {
    const putBody = {
      title: subjectValue,
      body: commentValue,
      resourceId,
    };
    editCommentHandler(commentId, putBody, parentCommentId);
    cancelEditMode();
  }, [
    cancelEditMode,
    commentId,
    commentValue,
    editCommentHandler,
    parentCommentId,
    resourceId,
    subjectValue,
  ]);

  const handleSubmitClick = useCallback(() => {
    if (isValid()) {
      if (!isLeaveDialogDisabled) {
        setIsDirty(false);
      }
      if (commentValueEdit) {
        onSaveEditsHandler();
      } else {
        submitComment();
      }
    }
  }, [
    isValid,
    isLeaveDialogDisabled,
    commentValueEdit,
    setIsDirty,
    onSaveEditsHandler,
    submitComment,
  ]);

  const onLeaveDialogHandler = useCallback(() => {
    handleSubmitClick();
    setIsLeaveDialogOpen(false);
  }, [handleSubmitClick, setIsLeaveDialogOpen]);

  const inputLabel = t('Your comment');
  const inputTitleId = 'CommentSubject';
  const inputId = `${inputLabel.split(' ').join('-')}${parentCommentId || ''}`;
  const inputAriaLabel = `${inputLabel}${parentCommentTitle ? `-to-${parentCommentTitle}` : ''}`;

  return (
    <Box>
      <Box>
        {!parentCommentId && (
          <Box pb={3}>
            <STTTextField
              error={subjectErrorMessage !== ''}
              fullWidth
              gaLabel="Comment subject"
              helperText={subjectErrorMessage}
              InputLabelProps={{ id: inputTitleId }}
              inputProps={{
                'aria-labelledby': inputTitleId,
                required: true,
                autoComplete: 'off',
              }}
              label={t('Subject')}
              maxLength={InputsValidationRules.MAX_INPUT_LENGTH}
              name={t('Subject')}
              onChange={handleSubjectTitleChange}
              outlined={outlined}
              required
              value={subjectValue}
            />
          </Box>
        )}
        <STTTextField
          autoFocus={!!parentCommentId}
          className="ayo-comment-body"
          error={commentErrorMessage !== ''}
          fullWidth
          gaLabel={parentCommentId ? 'Reply input' : 'Comment input'}
          helperText={commentErrorMessage}
          InputLabelProps={{ id: inputId }}
          inputProps={{ 'aria-label': inputAriaLabel, required: true }}
          label={inputLabel}
          maxLength={InputsValidationRules.MAX_INPUT_LENGTH}
          multiline
          name={inputLabel}
          onChange={handleCommentChange}
          outlined={outlined}
          required
          rowsCount={commentValueEdit ? 0 : 4}
          value={commentValue}
        />
        <Box pb={2} pt={3}>
          <Grid container>
            <Grid item md={isButtonsFullWidth ? 12 : 8} xs={12}>
              <PopupActionsButtons
                primaryButtonGaLabel={submitButtonText}
                primaryButtonHandler={handleSubmitClick}
                primaryButtonText={t(submitButtonText)}
                secondaryButtonGaLabel="Cancel"
                secondaryButtonHandler={onCancelHandler}
                secondaryButtonText={t('Cancel')}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <LeaveDialog
        onPrimaryClick={onLeaveDialogHandler}
        primaryButtonTitle="Save and leave"
        secondaryButtonTitle="Do not save and leave"
        text="AYO will not get your updates until you save them Let’s save?(comments)"
        title="Should we save your changes before you leave this page?(comments)"
      />
    </Box>
  );
};

CommentsForm.propTypes = {
  addNewCommentHandler: PropTypes.func,
  cancelEditMode: PropTypes.func,
  commentId: PropTypes.number,
  commentValueEdit: PropTypes.string,
  editCommentHandler: PropTypes.func,
  isButtonsFullWidth: PropTypes.bool,
  isLeaveDialogDisabled: PropTypes.bool,
  onAfterSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  outlined: PropTypes.bool,
  page: PropTypes.string,
  parentCommentId: PropTypes.number,
  parentCommentTitle: PropTypes.string,
  relatedUserId: PropTypes.number,
  replyHandler: PropTypes.func,
  resourceId: PropTypes.number,
  showFormHandler: PropTypes.func,
  subjectValueEdit: PropTypes.string,
  submitButtonText: PropTypes.string,
};

CommentsForm.defaultProps = {
  addNewCommentHandler: () => {},
  cancelEditMode: () => {},
  commentId: '',
  commentValueEdit: '',
  editCommentHandler: () => {},
  isButtonsFullWidth: false,
  isLeaveDialogDisabled: false,
  onAfterSubmit: null,
  onCancel: null,
  outlined: false,
  page: '',
  parentCommentId: null,
  parentCommentTitle: '',
  relatedUserId: null,
  replyHandler: () => {},
  resourceId: null,
  showFormHandler: () => {},
  subjectValueEdit: '',
  submitButtonText: 'Send',
};

export default CommentsForm;
