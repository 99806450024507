import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, DialogActions, DialogContent } from '@mui/material';
import PropTypes from 'prop-types';

import { Checkbox, Dialog, DialogTitle, FormControlLabel, Typography } from '../../../../atoms';
import { PopupActionsButtons, STTTextField } from '../../../../moleculas';
import { handleTemplateString } from '../../../../../utils';
import {
  InputsValidationErrors,
  InputsValidationRules,
  questionOptionTypes,
} from '../../../../../constants/enums';
import { useEndorsementsService } from '../../../../../services';

const TEXTAREA = 'TEXTAREA';

const EndorsementDialog = ({ endorsementConfig, isOpen, onClose, updateReflectionHandler }) => {
  const { t, i18n } = useTranslation();
  const { getEndorsementsQuestion } = useEndorsementsService();

  const [question, setQuestion] = useState(null);
  const [reasonErrorMessage, setReasonErrorMessage] = useState(null);

  useEffect(() => {
    if (endorsementConfig) {
      getEndorsementsQuestion(endorsementConfig?.endorsement.id, i18n.language).then(
        (questionData) => setQuestion(questionData[0]),
      );
    }
  }, [endorsementConfig, i18n.language, getEndorsementsQuestion]);

  const isValid = useCallback(() => {
    let reasonValidationError = null;
    question.options.forEach((option) => {
      if (option.optionType === questionOptionTypes.TEXT_AREA) {
        if (option.value?.length > InputsValidationRules.MAX_TEXT_AREA_LENGTH) {
          reasonValidationError = InputsValidationErrors(
            t,
            InputsValidationRules.MAX_TEXT_AREA_LENGTH,
          ).MAX_ERROR_TEXT;
          setReasonErrorMessage(reasonValidationError);
        }
      }
    });
    return !reasonValidationError;
  }, [t, question]);

  const findQuestionIndex = useCallback(
    (optionId) => {
      const optionToChange = question.options.find((option) => option.optionId === +optionId);
      return question.options.indexOf(optionToChange);
    },
    [question],
  );

  const handleAnswerChange = useCallback(
    (e) => {
      const index = findQuestionIndex(+e.target.dataset.optionid);
      if (e.target.nodeName === TEXTAREA) {
        question.options[index].value = e.target.value;
        setReasonErrorMessage(null);
      } else {
        question.options[index].selected = e.target.checked;
      }
      setQuestion({ ...question });
    },
    [question, findQuestionIndex],
  );

  const isOptionActivated = useCallback(
    (activatorOptionId) =>
      question?.options.find((option) => option.optionId === activatorOptionId)?.selected,
    [question],
  );

  const saveHandler = useCallback(() => {
    if (isValid()) {
      const getAnswers = () => {
        const textfieldElement = document.querySelector('textarea');
        if (textfieldElement.hasAttribute('disabled')) {
          const index = findQuestionIndex(textfieldElement.getAttribute('data-optionid'));
          question.options[index].value = null;
        }
        return question.options;
      };
      updateReflectionHandler(
        question.id,
        endorsementConfig?.endorsement.id,
        getAnswers(),
        endorsementConfig?.isEditMode,
      );
      onClose();
    }
  }, [updateReflectionHandler, endorsementConfig, question, isValid, onClose, findQuestionIndex]);

  if (!endorsementConfig) {
    return null;
  }
  return (
    <Dialog className="ayo-endorsement-dialog" onClose={onClose} open={isOpen}>
      <DialogTitle disableTypography>
        <Typography variant="h2">
          {handleTemplateString(question?.question, endorsementConfig?.endorsement)}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box mb={5} mt={5}>
          {question?.options?.map((option) => {
            const isOptionActive = isOptionActivated(option.activatorOptionId);
            switch (option.optionType) {
              case questionOptionTypes.TEXT_AREA:
                return (
                  <Box>
                    <STTTextField
                      key={option.optionId}
                      disabled={!isOptionActive}
                      error={reasonErrorMessage}
                      fullWidth
                      gaLabel="Questionnaire text input"
                      helperText={t(reasonErrorMessage)}
                      inputProps={{
                        'data-optionid': option.optionId,
                        name: option.value,
                        'aria-label': option.question,
                      }}
                      label={option.htmlPlaceholder}
                      multiline
                      onChange={handleAnswerChange}
                      outlined
                      placeholder={option.htmlPlaceholder}
                      rowsCount={4}
                      value={isOptionActive ? option?.value : ''}
                    />
                    <Box mt={1}>
                      <Typography variant="body3">
                        {t('This text input is available only for “Other” option')}
                      </Typography>
                    </Box>
                  </Box>
                );
              case questionOptionTypes.CHECKBOX:
                return (
                  <Box my={3}>
                    <FormControlLabel
                      key={option.optionId}
                      control={
                        <Checkbox
                          checked={option.selected}
                          inputProps={{ 'data-optionid': option.optionId, name: option.value }}
                          onChange={handleAnswerChange}
                          value={option.value}
                        />
                      }
                      label={option.value}
                    />
                  </Box>
                );
              default:
                return null;
            }
          })}
        </Box>
      </DialogContent>
      <DialogActions>
        <PopupActionsButtons
          primaryButtonGaLabel="Save"
          primaryButtonHandler={saveHandler}
          primaryButtonText={t(endorsementConfig?.isEditMode ? 'Save changes' : 'Save')}
          secondaryButtonGaLabel="Cancel"
          secondaryButtonHandler={onClose}
          secondaryButtonText={t('Cancel')}
        />
      </DialogActions>
    </Dialog>
  );
};

EndorsementDialog.propTypes = {
  endorsementConfig: PropTypes.shape({
    endorsement: PropTypes.instanceOf(Object),
    isEditMode: PropTypes.bool,
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  updateReflectionHandler: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EndorsementDialog;
