import { useContext, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { trackPromise } from 'react-promise-tracker';
import axios from 'axios';

import { UserActions, UserContext } from '../context/user/UserContext';
import { AppActions, AppContext } from '../context/app/AppContext';
import {
  DefaultGAUserProperties,
  FeedbackFunctionalityKeysMap,
  LocalStorageKeysMap,
} from '../constants/enums';
import {
  forbiddenPageRoute,
  notFoundPageRoute,
  serverErrorPageRoute,
  serviceUnavailablePageRoute,
  timeoutErrorPageRoute,
  unauthorizedPageRoute,
  unsupportedPageRoute,
} from '../constants/routes';
import { checkEnvironmentVariable } from '../utils/app-helpers/appHelpers';
import { setUserProperties } from '../utils/analytics/Analytics';

export const StatusCodeMap = {
  OK: 200,
  NO_CONTENT: 204,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  CONFLICT: 409,
  SERVER_ERROR: 500,
  SERVICE_UNAVAILABLE: 503,
};

const defaultOptions = {
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 60000,
};

let httpClient;

let idleTimeout;

const useAxios = () => {
  const history = useHistory();
  const { dispatch: dispatchAppState } = useContext(AppContext);
  const { dispatch: dispatchUserState } = useContext(UserContext);

  const [cookies] = useCookies();
  let maxIdleTime;
  if (cookies.SERVER_SESSION_TIMEOUT) {
    maxIdleTime = (parseInt(cookies.SERVER_SESSION_TIMEOUT, 10) - 60) * 1000;
  }

  const clearUserSession = useCallback(() => {
    dispatchUserState({
      type: UserActions.SET_USER_PROFILE,
      data: null,
    });
    dispatchAppState({
      type: AppActions.SET_IS_DIRTY,
      data: null,
    });
    dispatchAppState({
      type: AppActions.SET_CLASSLINK_REMINDER_OPEN,
      data: null,
    });
    dispatchAppState({
      type: AppActions.SET_LOGIN_PROVIDER,
      data: null,
    });
    setUserProperties(DefaultGAUserProperties);
    localStorage.removeItem(LocalStorageKeysMap.AYO_LOGIN_PROVIDER);
    Object.values(FeedbackFunctionalityKeysMap).forEach((key) => sessionStorage.removeItem(key));
  }, [dispatchAppState, dispatchUserState]);

  const resetIdleTimeout = useCallback(() => {
    if (idleTimeout) {
      clearTimeout(idleTimeout);
    }
    if (maxIdleTime) {
      idleTimeout = setTimeout(() => {
        dispatchAppState({
          type: AppActions.SET_IDLE_TIMEOUT_EXPIRED,
          data: true,
        });
      }, maxIdleTime);
    }
  }, [dispatchAppState, maxIdleTime]);

  if (!httpClient) {
    httpClient = axios.create(defaultOptions);

    httpClient.interceptors.request.use((config) => {
      const newConfig = config;
      if (
        checkEnvironmentVariable('NODE_ENV', 'development') &&
        checkEnvironmentVariable('REACT_APP_USE_MOCK', 'true')
      ) {
        const parts = config.url.split('?');
        newConfig.url = config.url.endsWith('.json') ? parts[0] : `${parts[0]}.json`;
        if (parts.length > 1) {
          newConfig.url += `?${parts[1]}`;
        }
      }
      if (config.loaderEnabled) {
        dispatchAppState({
          type: AppActions.SET_LOADER_CONFIG,
          data: { loaderText: config.loaderText },
        });
      }
      resetIdleTimeout();
      // This will do the trick of notifying other browser tabs that timer was reset
      localStorage.setItem(
        LocalStorageKeysMap.AYO_RESET_TIMER,
        localStorage.getItem(LocalStorageKeysMap.AYO_RESET_TIMER) !== 'true',
      );
      return newConfig;
    });

    httpClient.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response) {
          if (
            !error.response.config.skipDefaultErrorHandlers?.find(
              (errorCode) => errorCode === error.response.status,
            )
          ) {
            if (error.response.status === StatusCodeMap.UNAUTHORIZED) {
              clearUserSession();
              if (!error.response.config.skipAuthCheck) {
                history.push(unauthorizedPageRoute);
              }
            }

            if (error.response.status === StatusCodeMap.FORBIDDEN) {
              if (error.response.data.errorCode === 'UNSUPPORTED_ROLE_ERROR') {
                dispatchUserState({
                  type: UserActions.SET_USER_PROFILE_ERROR,
                  data: error.response.data,
                });
                history.push(unsupportedPageRoute);
              } else {
                history.replace(forbiddenPageRoute);
              }
            }

            if (error.response.status === StatusCodeMap.SERVER_ERROR) {
              history.push(serverErrorPageRoute);
            }

            if (error.response.status === StatusCodeMap.SERVICE_UNAVAILABLE) {
              history.push(serviceUnavailablePageRoute);
            }

            if (error.response.status === StatusCodeMap.NOT_FOUND) {
              history.replace(notFoundPageRoute);
            }

            if (error.response.status === StatusCodeMap.BAD_REQUEST) {
              history.replace(notFoundPageRoute);
            }
          }
        } else if (error.request) {
          history.push(timeoutErrorPageRoute);
        }
        return Promise.reject(error);
      },
    );

    // This code is executed when other tab has triggered timer reset
    window.addEventListener('storage', (e) => {
      if (e.key === 'ayo_reset_timer') {
        resetIdleTimeout();
        dispatchAppState({
          type: AppActions.SET_IDLE_TIMEOUT_EXPIRED,
          data: false,
        });
      }
    });
  }

  const conditionallyTrackPromise = useCallback(
    (condition, promise) => (condition ? trackPromise(promise) : promise),
    [],
  );

  const get = useCallback(
    (loaderConfig, url, config) =>
      conditionallyTrackPromise(
        loaderConfig.enabled,
        httpClient.get(url, {
          ...config,
          loaderEnabled: loaderConfig.enabled,
          loaderText: loaderConfig.loaderText,
        }),
      ),
    [conditionallyTrackPromise],
  );

  const post = useCallback(
    (loaderConfig, url, data, config) =>
      conditionallyTrackPromise(
        loaderConfig.enabled,
        httpClient.post(url, data, {
          ...config,
          loaderEnabled: loaderConfig.enabled,
          loaderText: loaderConfig.loaderText,
        }),
      ),
    [conditionallyTrackPromise],
  );

  const patch = useCallback(
    (loaderConfig, url, data, config) =>
      conditionallyTrackPromise(
        loaderConfig.enabled,
        httpClient.patch(url, data, {
          ...config,
          loaderEnabled: loaderConfig.enabled,
          loaderText: loaderConfig.loaderText,
        }),
      ),
    [conditionallyTrackPromise],
  );

  const put = useCallback(
    (loaderConfig, url, data, config) =>
      conditionallyTrackPromise(
        loaderConfig.enabled,
        httpClient.put(url, data, {
          ...config,
          loaderEnabled: loaderConfig.enabled,
          loaderText: loaderConfig.loaderText,
        }),
      ),
    [conditionallyTrackPromise],
  );

  const httpDelete = useCallback(
    (loaderConfig, url, data, config) =>
      conditionallyTrackPromise(
        loaderConfig.enabled,
        httpClient.delete(url, {
          ...config,
          data,
          loaderEnabled: loaderConfig.enabled,
          loaderText: loaderConfig.loaderText,
        }),
      ),
    [conditionallyTrackPromise],
  );

  return { get, post, patch, put, httpDelete, httpClient };
};

export default useAxios;
