export const fillInterpolationValue = (string, interpolationValue) =>
  string?.replace(/{{(.+?)}}/g, (match, content) => {
    const newString = interpolationValue[content];
    return typeof newString === 'string' || typeof newString === 'number' ? newString : match;
  });

export const converterBooleanString = (value, toBoolean) => {
  if (toBoolean) {
    return !!parseInt(value, 10);
  }
  return value ? '1' : '0';
};

export const capitalize = (value) => {
  if (typeof value !== 'string') {
    return value;
  }

  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
};

export const formatObjKeysToWords = (str, capitalizeWords = false) =>
  capitalizeWords
    ? str
        .replace('_', ' ')
        .split(' ')
        .map((word) => capitalize(word))
        .join(' ')
    : capitalize(str.replace('_', ' '));

export const formatTag = (tag) =>
  `#${tag.toLowerCase().replaceAll('&', 'and').replaceAll(/[^\w]/g, '')}`;

export const validateStringLength = (str, limit, extremum = 'min') =>
  extremum === 'min' ? str.trim().length >= limit : str.trim().length <= limit;
