import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Container, useMediaQuery, useTheme } from '@mui/material';

import { Typography } from '../../../../../atoms';
import { ClickableGalleryItem, InformationalMessage } from '../../../../../moleculas';
import { UserActions, UserContext } from '../../../../../../context';
import { useOneTimeActionService } from '../../../../../../services';
import { useProfile } from '../../../../../../utils';
import { OneTimeActionsMap } from '../../../../../../constants/enums';
import { AptitudesGalleryItems } from '../../../../../../constants/aptitudes';
import AptitudesDialog from '../../aptitudes-dialog/AptitudesDialog';

const AptitudesGallerySection = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const { state: userState, dispatch: dispatchUserState } = useContext(UserContext);
  const { getOneTimeActionStatus } = useProfile();
  const { postOneTimeAction } = useOneTimeActionService();
  const [activeAptitudeIdx, setActiveAptitudeIdx] = useState(null);
  const [isAptitudesDialogOpen, setIsAptitudesDialogOpen] = useState(false);

  const informationalMessageHidden = useMemo(
    () => getOneTimeActionStatus(OneTimeActionsMap.APTITUDES_GALLERY_NOTIFICATION),
    [getOneTimeActionStatus],
  );

  const informationalMessageHandler = useCallback(() => {
    postOneTimeAction(OneTimeActionsMap.APTITUDES_GALLERY_NOTIFICATION);
    dispatchUserState({
      type: UserActions.SET_ONE_TIME_ACTION,
      data: OneTimeActionsMap.APTITUDES_GALLERY_NOTIFICATION,
    });
  }, [dispatchUserState, postOneTimeAction]);

  const closeDialogHandler = useCallback(() => {
    setTimeout(() => {
      document
        .querySelectorAll('.ayo-aptitudes-gallery-section__cards-container__card .ayo-card')
        [activeAptitudeIdx].focus();
    }, 0);
    setIsAptitudesDialogOpen(false);
  }, [activeAptitudeIdx]);

  const showDetailsHandler = useCallback((idx) => {
    setActiveAptitudeIdx(idx);
    setIsAptitudesDialogOpen(true);
  }, []);

  const navigationHandler = useCallback(
    (idx) => {
      if (activeAptitudeIdx > 0 || activeAptitudeIdx < AptitudesGalleryItems.length - 1) {
        setActiveAptitudeIdx(idx);
      }
      setTimeout(() => {
        document.querySelectorAll('.ayo-dialog__close-button')[0].focus();
      }, 0);
    },
    [activeAptitudeIdx],
  );

  return (
    <Box
      className="ayo-aptitudes-gallery-section"
      pb={isWidthUpSm ? 0 : 5}
      pt={isWidthUpSm ? 0 : 2}
    >
      <Container>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          mb={5}
        >
          <Typography component="h2" isLightText paragrahp variant="h2">
            {t('Aptitudes')}
          </Typography>
          <Typography isLightText variant="body2">
            {t('Here is a list of the most common aptitudes')}
          </Typography>
          {!informationalMessageHidden && userState.oneTimeActions && (
            <Box pt={2} px={1}>
              <InformationalMessage
                onClick={informationalMessageHandler}
                text={t('Select a picture to explore more')}
              />
            </Box>
          )}
        </Box>
        <Box
          alignItems="center"
          className="ayo-aptitudes-gallery-section__cards-container"
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          justifyContent="center"
        >
          {AptitudesGalleryItems.map((aptitude, idx) => (
            <ClickableGalleryItem
              key={aptitude.key}
              attribute={aptitude}
              className="ayo-aptitudes-gallery-section__cards-container__card"
              clickHandler={() => showDetailsHandler(idx)}
              index={idx}
              section="landingBlockConfig"
              titleWrap={false}
            />
          ))}
        </Box>
        <AptitudesDialog
          aptitudes={AptitudesGalleryItems}
          index={activeAptitudeIdx}
          isOpen={isAptitudesDialogOpen}
          navigationHandler={navigationHandler}
          onClose={closeDialogHandler}
        />
      </Container>
    </Box>
  );
};

export default AptitudesGallerySection;
