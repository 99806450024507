import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { ActionsMenu } from '../../../../atoms';
import { ReactComponent as CreateIcon } from '../../../../../resources/icons/create.svg';
import { ReactComponent as DeleteIcon } from '../../../../../resources/icons/delete_primary.svg';

const EventsActions = ({ onDelete, onEdit }) => {
  const { t } = useTranslation();

  return (
    <ActionsMenu
      id="events-actions"
      menuItems={[
        {
          text: t('Edit'),
          icon: CreateIcon,
          handler: onEdit,
          id: 'events-actions-edit',
          gaLabel: 'Edit',
        },
        {
          text: t('Delete'),
          icon: DeleteIcon,
          handler: onDelete,
          id: 'events-actions-delete',
          gaLabel: 'Delete',
        },
      ]}
    />
  );
};

EventsActions.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default EventsActions;
