export const isObject = (object) => object != null && typeof object === 'object';

export const deepEqual = (object, other, ignoreKeys) => {
  if (!object || !other) {
    return false;
  }

  const keys1 = Object.keys(object);
  const keys2 = Object.keys(other);

  if (keys1.length !== keys2.length) {
    return false;
  }

  return keys1.every((key) => {
    if (ignoreKeys && ignoreKeys.indexOf(key) !== -1) {
      return true;
    }
    const val1 = object[key];
    const val2 = other[key];
    const areObjects = isObject(val1) && isObject(val2);
    return !((areObjects && !deepEqual(val1, val2, ignoreKeys)) || (!areObjects && val1 !== val2));
  });
};

export const getValueByKey = (obj, key) =>
  key
    .replace(/\[([^\]]+)]/g, '.$1')
    .split('.')
    .reduce((o, p) => o[p], obj);

export const removeObjectKeys = (obj, arrayOfKeys) =>
  Object.fromEntries(Object.entries(obj).filter(([key]) => !arrayOfKeys.includes(key)));
