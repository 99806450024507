import React from 'react';
import PropTypes from 'prop-types';

const StudentsGroupHeader = ({ children, studentWrapperConstructor, studentId }) => {
  const StudentWrapperComponent = studentWrapperConstructor
    ? studentWrapperConstructor(studentId)
    : React.Fragment;

  return <StudentWrapperComponent>{children}</StudentWrapperComponent>;
};

StudentsGroupHeader.propTypes = {
  children: PropTypes.node.isRequired,
  studentId: PropTypes.number.isRequired,
  studentWrapperConstructor: PropTypes.func.isRequired,
};

export default StudentsGroupHeader;
