import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

const transformOptions = (options) => options.map((option) => ({ key: uuid(), value: option }));

const useAssignmentOptionsState = (startState) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState(transformOptions(startState));
  const [error, setError] = useState('');

  const onChange = (index, value, command) => {
    switch (command) {
      case 'up': {
        setOptions((prevOptions) => {
          const newOptions = [...prevOptions];
          newOptions[index] = prevOptions[index - 1];
          newOptions[index - 1] = prevOptions[index];
          return newOptions;
        });
        break;
      }
      case 'down': {
        setOptions((prevOptions) => {
          const newOptions = [...prevOptions];
          newOptions[index] = prevOptions[index + 1];
          newOptions[index + 1] = prevOptions[index];
          return newOptions;
        });
        break;
      }
      default: {
        setOptions((prevOptions) => {
          const updateValue =
            value === null ? undefined : { key: prevOptions[index]?.key || uuid(), value };
          const newOptions = [...prevOptions];
          newOptions.splice(index, 1, updateValue);
          return newOptions.filter(Boolean);
        });
      }
    }
    setError('');
  };

  const validate = () => {
    const validOptions = options.filter((option) => option.value);
    if (!validOptions.length) {
      const errorText = t('Please add at least 1 option. 2 or more options would be even better.');
      setError(errorText);
      return [{ errorText, lvl3Label: t('Options') }];
    }
    return [];
  };

  return { state: options, onChange, error, validate };
};

export default useAssignmentOptionsState;
