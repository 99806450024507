import React from 'react';
import PropTypes from 'prop-types';

import { HighlightsInitiatives } from '../../../../constants/enums';
import TopFiltersList from '../components/TopFiltersList/TopFiltersList';

const InterestsFilters = ({ menuFilters, setMenuFilters, stats, totalStudentsCount }) => (
  <TopFiltersList
    displayAllOther
    initiative={HighlightsInitiatives.INTERESTS}
    menuFilters={menuFilters}
    setMenuFilters={setMenuFilters}
    stats={stats}
    title="Students distributed by top trending categories"
    totalStudentsCount={totalStudentsCount}
  />
);

InterestsFilters.propTypes = {
  menuFilters: PropTypes.instanceOf(Object).isRequired,
  setMenuFilters: PropTypes.func.isRequired,
  stats: PropTypes.instanceOf(Object).isRequired,
  totalStudentsCount: PropTypes.number.isRequired,
};

export default InterestsFilters;
