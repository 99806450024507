import { ReactComponent as CollaborationImageDesktop } from '../resources/images/leadership-attributes-items/collaboration_desktop.svg';
import { ReactComponent as CollaborationImageDesktopAssessment } from '../resources/images/leadership-attributes-items/collaboration_desktop_assessment.svg';
import { ReactComponent as CourageImageDesktop } from '../resources/images/leadership-attributes-items/courage_desktop.svg';
import { ReactComponent as CourageImageDesktopAssessment } from '../resources/images/leadership-attributes-items/courage_desktop_assessment.svg';
import { ReactComponent as DeterminationImageDesktop } from '../resources/images/leadership-attributes-items/determination_desktop.svg';
import { ReactComponent as DeterminationImageDesktopAssessment } from '../resources/images/leadership-attributes-items/determination_desktop_assessment.svg';
import { ReactComponent as DriveImageDesktop } from '../resources/images/leadership-attributes-items/drive_desktop.svg';
import { ReactComponent as DriveImageDesktopAssessment } from '../resources/images/leadership-attributes-items/drive_desktop_assessment.svg';
import { ReactComponent as GoalOrientationImageDesktop } from '../resources/images/leadership-attributes-items/goal_orientation_desktop.svg';
import { ReactComponent as GoalOrientationImageDesktopAssessment } from '../resources/images/leadership-attributes-items/goal_orientation_desktop_assessment.svg';
import { ReactComponent as CommunicationImageDesktop } from '../resources/images/leadership-attributes-items/communication_desktop.svg';
import { ReactComponent as CommunicationImageDesktopAssessment } from '../resources/images/leadership-attributes-items/communication_desktop_assessment.svg';
import { ReactComponent as EmpathyImageDesktop } from '../resources/images/leadership-attributes-items/empathy_desktop.svg';
import { ReactComponent as EmpathyImageDesktopAssessment } from '../resources/images/leadership-attributes-items/empathy_desktop_assessment.svg';
import { ReactComponent as EncouragementImageDesktop } from '../resources/images/leadership-attributes-items/encouragement_desktop.svg';
import { ReactComponent as EncouragementImageDesktopAssessment } from '../resources/images/leadership-attributes-items/encouragement_desktop_assessment.svg';
import { ReactComponent as GrowthMindsetImageDesktop } from '../resources/images/leadership-attributes-items/growth_mindset_desktop.svg';
import { ReactComponent as GrowthMindsetImageDesktopAssessment } from '../resources/images/leadership-attributes-items/growth_mindset_desktop_assessment.svg';
import { ReactComponent as OpenMindednessImageDesktop } from '../resources/images/leadership-attributes-items/open_mindedness_desktop.svg';
import { ReactComponent as OpenMindednessImageDesktopAssessment } from '../resources/images/leadership-attributes-items/open_mindedness_desktop_assessment.svg';
import { ReactComponent as PassionImageDesktop } from '../resources/images/leadership-attributes-items/passion_desktop.svg';
import { ReactComponent as PassionImageDesktopAssessment } from '../resources/images/leadership-attributes-items/passion_desktop_assessment.svg';
import { ReactComponent as ResourcefulnessImageDesktop } from '../resources/images/leadership-attributes-items/resourcefulness_desktop.svg';
import { ReactComponent as ResourcefulnessImageDesktopAssessment } from '../resources/images/leadership-attributes-items/resourcefulness_desktop_assessment.svg';
import { ReactComponent as ServiceImageDesktop } from '../resources/images/leadership-attributes-items/service_desktop.svg';
import { ReactComponent as ServiceImageDesktopAssessment } from '../resources/images/leadership-attributes-items/service_desktop_assessment.svg';
import { ReactComponent as IntegrityImageDesktop } from '../resources/images/leadership-attributes-items/integrity_desktop.svg';
import { ReactComponent as IntegrityImageDesktopAssessment } from '../resources/images/leadership-attributes-items/integrity_desktop_assessment.svg';
import { ReactComponent as VisionImageDesktop } from '../resources/images/leadership-attributes-items/vision_desktop.svg';
import { ReactComponent as VisionImageDesktopAssessment } from '../resources/images/leadership-attributes-items/vision_desktop_assessment.svg';

const LEADERSHIP_ATTRIBUTES_GALERY = [
  {
    key: 'collaboration',
    landingBlockConfig: {
      imageDesktop: CollaborationImageDesktop,
    },
    assesmentToolBlockConfig: {
      imageDesktop: CollaborationImageDesktopAssessment,
    },
    alt: 'Two hands solving a puzzle',
    definition: 'Collaboration-definition',
    details: {
      leadingSelf: [
        'i-am-willing-to-listen-to-others-and-value-their-input-in-group-settings',
        'i-actively-participate-and-contribute-ideas-in-group-settings',
      ],
      leadingOthers: [
        'i-work-with-others-to-create-collective-solutions-or-products-related-to-common-goals',
        'i-seek-and-accept-input-from-global-sources-to-advance-the-creation-of-collective-solutions-and-problems',
      ],
      leadingChange: [
        'i-leverage-the-strengths-and-ideas-of-myself-and-others-when-partnering-across-global-platforms-to-create-collective-solutions-or-products',
      ],
    },
  },
  {
    key: 'communication',
    landingBlockConfig: {
      imageDesktop: CommunicationImageDesktop,
    },
    assesmentToolBlockConfig: {
      imageDesktop: CommunicationImageDesktopAssessment,
    },
    alt: 'Two chat bubbles',
    definition: 'Communication-definition',
    details: {
      leadingSelf: [
        'i-share-accurate-and-relevant-information-and-ideas',
        'i-take-an-active-role-in-discussions-using-agreed-upon-norms',
        'i-give-examples-and-evidence-to-support-my-thinking',
        'i-ask-questions-to-better-understand',
        'i-patiently-and-actively-listen-to-gain-knowledge',
        'i-take-turns-when-expressing-ideas',
        'i-make-eye-contact-and-speak-clearly',
      ],
      leadingOthers: [
        'i-ask-questions-to-understand-the-viewpoints-of-others',
        'i-build-on-ideas-shared-by-others',
        'i-encourage-everyone-to-contribute-their-ideas',
        'i-listen-in-order-to-integrate-others-ideas',
        'i-use-eye-contact-and-body-language-to-encourage-others-to-engage-in-conversation',
      ],
      leadingChange: [
        'i-use-my-voice-to-contribute-to-my-community',
        'i-lead-conversations-to-look-for-new-ideas-and-solutions',
        'i-ask-questions-and-reconsider-my-thinking',
        'i-listen-in-order-to-integrate-others',
        'i-constructively-exchange-ideas-with-a-variety-of-audiences',
      ],
    },
  },
  {
    key: 'courage',
    landingBlockConfig: {
      imageDesktop: CourageImageDesktop,
    },
    assesmentToolBlockConfig: {
      imageDesktop: CourageImageDesktopAssessment,
    },
    alt: 'A person who is ready to jump in the water',
    definition: 'Courage-definition',
    details: {
      leadingSelf: [
        'i-seek-out-opportunities-to-try-new-things-in-order-to-reach-my-goal',
        'i-take-risks-in-the-face-of-challenges',
        'i-use-experiences-as-opportunities-to-grow-my-confidence',
      ],
      leadingOthers: [
        'i-encourage-others-to-seek-out-positive-influences',
        'i-coach-others-to-take-risks-in-the-face-of-challenges',
        'i-handle-conflict-in-a-positive-manner-which-will-allow-for-progress',
        'i-seek-feedback-and-respond-accordingly',
      ],
      leadingChange: [
        'i-advocate-for-myself-and-others',
        'i-stand-up-for-my-beliefs',
        'i-have-the-confidence-to-move-forward-even-when-the-outcome-is-uncertain',
      ],
    },
  },
  {
    key: 'determination',
    landingBlockConfig: {
      imageDesktop: DeterminationImageDesktop,
    },
    assesmentToolBlockConfig: {
      imageDesktop: DeterminationImageDesktopAssessment,
    },
    alt: 'A hand reaching for the stars',
    definition: 'Determination-definition',
    details: {
      leadingSelf: [
        'i-dedicate-the-time-and-effort-needed-to-achieve-a-goal',
        'i-review-my-goals-regularly-to-determine-progress',
        'i-maintain-a-clear-focus-on-my-goal',
        'i-continue-working-even-when-challenges-arise',
      ],
      leadingOthers: [
        'i-work-with-others-to-achieve-a-goal',
        'i-coach-others-to-work-through-challenges-to-achieve-goals',
        'i-use-feedback-as-an-opportunity-to-help-others-work-towards-a-goal',
      ],
      leadingChange: [
        'i-use-adversity-as-an-opportunity-to-help-create-lasting-change',
        'i-persist-in-order-to-lead-change',
      ],
    },
  },
  {
    key: 'drive',
    landingBlockConfig: {
      imageDesktop: DriveImageDesktop,
    },
    assesmentToolBlockConfig: {
      imageDesktop: DriveImageDesktopAssessment,
    },
    alt: 'A wall hammer',
    definition: 'Drive-definition',
    details: {
      leadingSelf: [
        'i-use-my-curiosity-passions-and-aptitudes-to-create-goals',
        'i-can-describe-the-end-picture-of-a-goal',
        'i-have-a-strong-desire-and-determination-to-succeed',
        'i-am-able-to-create-a-detailed-plan-for-goal-achievement-and-stick-to-it',
      ],
      leadingOthers: [
        'i-can-share-my-curiosity-passions-and-aptitudes-with-others-to-develop-a-common-goal',
        'i-can-motivate-others-to-share-join-in-the-pursuit-of-a-goal',
        'i-can-delegate-tasks-to-accomplish-a-common-goal',
        'i-can-monitor-and-monitor-progress-and-make-adjustments-towards-completion-of-a-common-goal',
      ],
      leadingChange: [
        'i-share-how-a-common-goal-leads-to-a-positive-change',
        'i-inspire-others-to-share-a-common-purpose-and-vision',
      ],
    },
  },

  {
    key: 'empathy',
    landingBlockConfig: {
      imageDesktop: EmpathyImageDesktop,
    },
    assesmentToolBlockConfig: {
      imageDesktop: EmpathyImageDesktopAssessment,
    },
    alt: 'Two chairs next to each other',
    definition: 'Empathy-definition',
    details: {
      leadingSelf: [
        'i-cultivate-curiosity-about-others',
        'i-seek-opportunities-to-learn-the-stories-of-others',
        'i-challenge-my-own-biases',
        'i-discover-commonalities-that-i-have-with-others',
      ],
      leadingOthers: [
        'i-encourage-the-people-around-me-to-cultivate-curiosity-about-others',
        'i-encourage-those-around-me-to-share-their-stories',
        'i-model-listening-to-understand',
        'i-encourage-others-to-challenge-their-own-biases',
        'i-celebrate-differences-and-commonalities-that-i-have-with-others',
      ],
      leadingChange: [
        'i-advocate-for-others-based-on-their-stories',
        'i-collaborate-with-others-from-multiple-perspective-to-affect-change',
      ],
    },
  },
  {
    key: 'encouragement',
    landingBlockConfig: {
      imageDesktop: EncouragementImageDesktop,
    },
    assesmentToolBlockConfig: {
      imageDesktop: EncouragementImageDesktopAssessment,
    },
    alt: 'A giant sports fan’s foam hand',
    definition: 'Encouragement-definition',
    details: {
      leadingSelf: [
        'i-reflect-on-how-my-words-and-actions-affect-others',
        'i-motivate-myself-by-believing-in-my-abilities',
        'i-model-a-positive-attitude',
      ],
      leadingOthers: [
        'i-notice-others-efforts-and-praise-them',
        'i-ask-others-about-their-work-and-look-for-opportunities-to-encourage-them',
        'i-ask-my-peers-about-how-they-feel-about-their-own-success',
        'i-motivate-my-peers-by-showing-belief-and-confidence-in-their-abilities',
        'i-help-others-build-on-their-existing-interests-and-skills',
        'i-provide-informative-helpful-feedback-to-others',
      ],
      leadingChange: [
        'i-support-and-facilitate-growth-through-failure-and-success-in-a-variety-of-audiences',
        'i-reflect-on-how-my-words-and-actions-can-bring-about-change',
      ],
    },
  },
  {
    key: 'goal-orientation',
    landingBlockConfig: {
      imageDesktop: GoalOrientationImageDesktop,
    },
    assesmentToolBlockConfig: {
      imageDesktop: GoalOrientationImageDesktopAssessment,
    },
    alt: 'A runner’s shoe and a timer',
    definition: 'Goal-orientation-definition',
    details: {
      leadingSelf: [
        'i-have-a-vision-of-my-goal-and-write-it-down',
        'i-create-a-plan-and-follow-it-to-achieve-my-goals',
        'i-develop-habits-that-allow-me-to-reach-my-goals',
        'i-have-high-expectations-for-myself',
        'i-focus-on-my-tasks-and-track-my-progress',
        'i-reflect-on-my-plan-and-make-necessary-adjustments',
        'i-accept-feedback-as-an-opportunity-to-improve-my-goal',
      ],
      leadingOthers: [
        'i-help-others-develop-follow-and-reflect-on-their-plans-with-an-end-goal-in-mind',
        'i-encourage-others-as-they-are-working-toward-their-goals',
        'i-celebrate-others-when-they-accomplish-their-goals',
        'i-help-others-develop-habits-that-allow-them-to-reach-their-goals',
        'i-encourage-others-to-seek-feedback-as-an-opportunity-to-improve-a-goal',
      ],
      leadingChange: [
        'i-influence-others-to-collaborate-as-we-work-towards-common-goals',
        'goal-orientation-i-build-a-sense-of-shared-purpose-among-others',
        'i-look-for-opportunities-to-align-goals-to-bigger-causes',
      ],
    },
  },
  {
    key: 'growth-mindset',
    landingBlockConfig: {
      imageDesktop: GrowthMindsetImageDesktop,
    },
    assesmentToolBlockConfig: {
      imageDesktop: GrowthMindsetImageDesktopAssessment,
    },
    alt: 'A rope bridge',
    definition: 'Growth-mindset-definition',
    details: {
      leadingSelf: [
        'i-embrace-the-challenge-of-taking-risks',
        'i-use-my-challenges-and-failures-as-part-of-the-learning-process',
        'i-learn-through-adversity',
        'i-understand-that-there-are-no-limits-to-achievement',
      ],
      leadingOthers: [
        'i-encourage-others-to-seek-out-new-challenges-and-ideas',
        'i-help-others-succeed-in-their-pursuit-of-growth',
        'i-encourage-others-to-embrace-challenges-and-failures-as-an-opportunity-to-persevere-and-grow',
      ],
      leadingChange: [
        'challenges-and-failures-drive-me-to-look-for-possibilities-for-change-within-the-community',
        'i-initiate-and-foster-an-environment-in-which-others-value-the-growth-made-by-persevering-through-challenges-and-failures',
      ],
    },
  },
  {
    key: 'integrity',
    landingBlockConfig: {
      imageDesktop: IntegrityImageDesktop,
    },
    assesmentToolBlockConfig: {
      imageDesktop: IntegrityImageDesktopAssessment,
    },
    alt: 'A lifebelt floating in the water',
    definition: 'Integrity-definition',
    details: {
      leadingSelf: [
        'i-do-the-right-thing-even-when-no-one-is-watching',
        'i-accept-responsibility-for-my-words-and-actions',
        'i-do-not-share-private-information-about-others',
        'i-am-honest-with-myself-and-others',
      ],
      leadingOthers: [
        'i-encourage-others-to-do-the-right-thing-even-when-no-one-is-watching',
        'i-encourage-others-to-take-responsibility-of-their-words-and-actions',
        'i-discourage-others-from-sharing-private-information-about-others',
        'i-help-create-spaces-where-people-can-be-open-and-honest-in-sharing-ideas',
      ],
      leadingChange: [
        'i-tell-the-truth-and-align-my-actions-to-improve-my-community',
        'i-lead-change-through-honest-communication-and-the-exchange-of-ideas-with-others',
        'i-promote-change-in-my-community-by-leading-by-example',
        'i-build-a-community-that-values-trust',
      ],
    },
  },
  {
    key: 'open-mindedness',
    landingBlockConfig: {
      imageDesktop: OpenMindednessImageDesktop,
    },
    assesmentToolBlockConfig: {
      imageDesktop: OpenMindednessImageDesktopAssessment,
    },
    alt: 'A bright light bulb',
    definition: 'Open-mindedness-definition',
    details: {
      leadingSelf: [
        'i-try-new-things',
        'i-listen-accept-and-reflect-on-feedback',
        'i-see-things-from-multiple-perspectives',
        'i-ask-questions-in-order-to-experience-new-ideas',
      ],
      leadingOthers: [
        'i-create-an-environment-that-encourages-others-to-identify-their-interest-passions-or-aptitudes',
        'i-support-others-as-they-explore-and-pursue-their-interest',
        'i-encourage-others-to-try-new-things',
        'i-encourage-others-to-see-things-from-multiple-perspectives',
      ],
      leadingChange: [
        'i-put-new-ideas-into-action',
        'i-bring-others-together-from-diverse-perspectives-in-order-to-impact-change',
      ],
    },
  },
  {
    key: 'passion',
    landingBlockConfig: {
      imageDesktop: PassionImageDesktop,
    },
    assesmentToolBlockConfig: {
      imageDesktop: PassionImageDesktopAssessment,
    },
    alt: 'A painting and a first place medal',
    definition: 'Passion-definition',
    details: {
      leadingSelf: [
        'i-am-enthusiastic-about-my-interests',
        'i-take-the-time-and-effort-to-explore-my-interests',
        'i-identify-and-connect-to-things-that-interest-me',
      ],
      leadingOthers: [
        'i-encourages-others-to-share-their-interest',
        'i-support-others-as-they-explore-and-act-on-their-interests',
      ],
      leadingChange: [
        'i-foster-an-environment-that-connects-people-with-diverse-passions-in-order-to-support-change',
        'i-use-my-interests-and-aptitudes-for-a-greater-cause-within-a-community',
      ],
    },
  },
  {
    key: 'resourcefulness',
    landingBlockConfig: {
      imageDesktop: ResourcefulnessImageDesktop,
    },
    assesmentToolBlockConfig: {
      imageDesktop: ResourcefulnessImageDesktopAssessment,
    },
    alt: 'A collage of a lady made from vegetables',
    definition: 'Resourcefulness-definition',
    details: {
      leadingSelf: [
        'i-take-initiative',
        'i-seek-out-resources-to-solve-problems',
        'i-am-flexible',
        'i-take-risks-and-think-creatively',
        'i-am-persistent',
      ],
      leadingOthers: [
        'i-encourage-others-to-take-initiative',
        'i-urge-others-to-seek-out-help',
        'i-support-creative-thinking-and-risk-taking',
        'i-encourage-others-to-be-adaptable-in-situations',
      ],
      leadingChange: [
        'i-celebrate-resourcefulness-in-others',
        'i-create-opportunities-for-others-to-find-effective-ways-to-approach-a-challenge',
        'i-encourage-creative-thinking',
      ],
    },
  },
  {
    key: 'service',
    landingBlockConfig: {
      imageDesktop: ServiceImageDesktop,
    },
    assesmentToolBlockConfig: {
      imageDesktop: ServiceImageDesktopAssessment,
    },
    alt: 'A hand passing a binoculars to other hand',
    definition: 'service-definition',
    details: {
      leadingSelf: [
        'leading-self-i-anticipate-others-needs-and-actively-support-them',
        'leading-self-i-encourage-and-support-others-in-the-pursuit-of-their-goals',
        'leading-self-i-lead-others-to-find-compassionate-solutions-to-those-in-need',
        'i-care-about-the-well-being-and-success-of-others',
      ],
      leadingOthers: [
        'leading-others-i-anticipate-others-needs-and-actively-support-them',
        'leading-others-i-encourage-and-support-others-in-the-pursuit-of-their-goals',
        'leading-others-i-lead-others-to-find-compassionate-solutions-to-those-in-need',
      ],
      leadingChange: [
        'i-connect-with-others-in-order-to-help-the-members-of-the-community-create-positive-change',
        'i-actively-find-opportunities-for-my-community-to-seek-solutions-to-a-need',
        'i-take-action-for-the-greater-good-of-my-community-or-the-world',
      ],
    },
  },
  {
    key: 'vision',
    landingBlockConfig: {
      imageDesktop: VisionImageDesktop,
    },
    assesmentToolBlockConfig: {
      imageDesktop: VisionImageDesktopAssessment,
    },
    alt: 'Binoculars looking faraway',
    definition: 'Vision-definition',
    details: {
      leadingSelf: [
        'i-can-develop-a-purpose-based-on-my-curiosity-passions-and-aptitudes',
        'i-plan-with-the-end-in-mind',
        'i-understand-the-steps-to-fulfill-my-purpose',
        'i-share-my-vision-with-others',
      ],
      leadingOthers: [
        'i-help-others-develop-a-plan-to-fulfill-a-purpose',
        'i-recognize-and-encourage-the-value-each-person-can-contribute-toward-a-vision',
        'i-anticipate-challenges-and-provide-support-throughout-the-implementation-of-the-vision-through-reflection',
        'i-celebrate-incremental-successes-and-actions-that-align-with-and-contribute-to-the-overall-fulfillment-of-the-vision',
      ],
      leadingChange: [
        'vision-i-build-a-sense-of-shared-purpose-among-others',
        'i-look-for-opportunities-to-align-my-vision-to-a-bigger-cause',
      ],
    },
  },
];

export default LEADERSHIP_ATTRIBUTES_GALERY;
