import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

import RichTextEditor from '../../../rich-text-editor/RichTextEditor';

const PortfolioPrintComponent = React.forwardRef(({ value }, ref) => (
  <Box style={{ display: 'none' }}>
    <Box ref={ref} p={5}>
      <RichTextEditor isStatic value={value} />
    </Box>
  </Box>
));

PortfolioPrintComponent.propTypes = {
  value: PropTypes.string.isRequired,
};

export default PortfolioPrintComponent;
