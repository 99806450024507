import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Typography } from '../../../../atoms';
import { InitiativeEmptyStateBlock } from '../../../../moleculas';
import { ReactComponent as ExtracurricularsEmptyStateIllustration } from '../../../../../resources/images/extracurriculars_boards.svg';
import PortfolioSection from '../../components/portfolio-section/PortfolioSection';

const PortfolioSharedExtracurricularsSection = ({ sectionData }) => {
  const { t } = useTranslation();

  return (
    <PortfolioSection
      body={
        <Box
          className="ayo-portfolio-extracurriculars__content"
          display="flex"
          flexWrap="wrap"
          justifyContent="flex-start"
          maxWidth="60%"
          mt={1}
        >
          {sectionData.entities.map((extracurricular) => (
            <Box key={extracurricular.name} mr={2} mt={2}>
              <Box className="ayo-portfolio-extracurriculars__content__card">
                <Typography
                  className="ayo-portfolio-extracurriculars__content__card__title"
                  component="h3"
                  variant="subtitle2"
                >
                  {extracurricular.name}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      }
      className={`${classNames('ayo-full-screen-portfolio-section', {
        'ayo-portfolio-extracurriculars': sectionData.entities.length,
      })}`}
      emptyState={
        !sectionData.entities.length ? (
          <InitiativeEmptyStateBlock
            illustration={<ExtracurricularsEmptyStateIllustration />}
            title={t('Extracurriculars were not shared so far')}
          />
        ) : null
      }
      reflection={sectionData.reflection}
      title={t('Extracurriculars')}
    />
  );
};

PortfolioSharedExtracurricularsSection.propTypes = {
  sectionData: PropTypes.instanceOf(Object).isRequired,
};

export default PortfolioSharedExtracurricularsSection;
