import React, { useCallback, useEffect, useRef, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';

import { Link, Typography } from '../../atoms';
import { SearchField, InformationalMessage } from '../../moleculas';
import { GA } from '../../../utils';
import { UserContext } from '../../../context';
import { plugRoute } from '../../../constants/routes';
import { loginAllowedFilterRE } from '../../../constants/regexps';
import { GaCategories, GaActions, RolesMap } from '../../../constants/enums';

const SEARCH_CRITERIA_ARR = ['name', 'email'];

const SearchStudentsContainer = ({
  onSearch,
  searchValue,
  students,
  suggestions,
  studentCardRedirectLink,
  notification,
}) => {
  const { t } = useTranslation();

  const history = useHistory();

  const theme = useTheme();
  const isWidthUpMd = useMediaQuery(theme.breakpoints.up('md'), { noSsr: true });

  const searchFieldRef = useRef();

  const [activeUsersHintHidden, setActiveUsersHintHidden] = useState(
    localStorage.getItem('ayo_active_users_hint_hidden'),
  );

  const handleOptionClick = useCallback(
    (id) => {
      GA.logInteraction({
        category: GaCategories.BEHAVIOR,
        action: GaActions.SUGGESTION_CLICK,
        label: 'Student search',
      });
      history.push(studentCardRedirectLink(id));
    },
    [history, studentCardRedirectLink],
  );

  const handleResetClick = useCallback((e) => {
    e.preventDefault();
    searchFieldRef.current.clearSearch();
  }, []);

  useEffect(() => {
    if (suggestions && !activeUsersHintHidden) {
      const hasActiveUsers = suggestions.some((student) => student.active);
      setActiveUsersHintHidden(!hasActiveUsers);
    }
  }, [activeUsersHintHidden, suggestions]);

  const { state: userState } = useContext(UserContext);

  return (
    <>
      <Grid container direction={isWidthUpMd ? 'row' : 'column'} item xs={12}>
        <Grid item md={6} sm={8} xs={12}>
          <Box mb={isWidthUpMd ? 0 : 2} pr={isWidthUpMd ? 2 : 0}>
            <SearchField
              apiRef={searchFieldRef}
              criterias={SEARCH_CRITERIA_ARR}
              gaLabel="Student search"
              inputRE={loginAllowedFilterRE}
              label={t('Search by name or ID')}
              maxRowsCount={5}
              minInputLength={2}
              onOptionClick={handleOptionClick}
              onSearch={onSearch}
              suggestions={suggestions}
            />
          </Box>
        </Grid>
        {notification.visible && (
          <Grid item md={6} sm={8} xs={12}>
            <Box display="flex" justifyContent={isWidthUpMd ? 'flex-end' : 'flex-start'}>
              <InformationalMessage
                onClick={notification.handler}
                text={
                  <Trans
                    components={{
                      green: <span className="ayo-informational-message__description__indicator" />,
                    }}
                    i18nKey={notification.text}
                  />
                }
              />
            </Box>
          </Grid>
        )}
      </Grid>
      <Grid item sm={6} xs={12}>
        {searchValue && students.length > 0 && (
          <Box pt={4}>
            <Typography paragraph variant="body2">
              <Trans
                components={{ b: <b /> }}
                i18nKey="search result text"
                values={{ count: students?.length, searchValue }}
              />
            </Typography>
          </Box>
        )}
        {searchValue && !students.length && (
          <Box pt={4}>
            <Typography style={{ wordWrap: 'break-word' }} variant="body2">
              {t('AYO couldn’t find any students with the name or ID')}
              <b>{` "${searchValue}".`}</b>
            </Typography>
            <Typography paragraph variant="body2">
              {t('Please check your spelling and repeat your search')}
            </Typography>
            <Link href={plugRoute} onClick={handleResetClick}>
              {userState.profile.role === RolesMap.TEACHER
                ? t('Show all the students I teach')
                : t('Show all students')}
            </Link>
          </Box>
        )}
      </Grid>
    </>
  );
};

SearchStudentsContainer.propTypes = {
  onSearch: PropTypes.func.isRequired,
  searchValue: PropTypes.string,
  students: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.instanceOf(Object)),
    PropTypes.instanceOf(Object),
  ]),
  suggestions: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  notification: PropTypes.shape({
    handler: PropTypes.func,
    text: PropTypes.string,
    visible: PropTypes.bool,
  }).isRequired,
  studentCardRedirectLink: PropTypes.func.isRequired,
};

SearchStudentsContainer.defaultProps = {
  searchValue: '',
  students: [],
  suggestions: [],
};

export default SearchStudentsContainer;
