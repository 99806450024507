import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Divider, useMediaQuery, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import { Tooltip, Typography } from '../../../../atoms';
import { Chip, ClickableCard, TextWithTooltip } from '../../../../moleculas';
import { eventType } from '../../../../../constants/propTypes';
import { EventSharingGroupsLabel, EventsLocationTypes } from '../../../../../constants/events';
import { GaActions, GaCategories } from '../../../../../constants/enums';
import { capitalize, GA, getFormattedDate } from '../../../../../utils';
import { ReactComponent as MembersIcon } from '../../../../../resources/icons/users.svg';

const EventLabel = ({
  fullLabel,
  goingCount,
  interestedCount,
  isOnlineEvent,
  isWidthUpSm,
  resourceType,
  sharingGroups,
}) => {
  const { t } = useTranslation();

  // TODO: remove once the interested functionality is implemented
  const showInterestedLabels = false;

  return (
    <Box
      display="flex"
      flexDirection={!isWidthUpSm && isOnlineEvent && fullLabel ? 'column' : 'row'}
      gap={2}
    >
      {showInterestedLabels && (
        <Box alignItems="center" display="flex" flexDirection="row" gap={2}>
          <Typography variant="caption">{`${goingCount} ${t('going')}`}</Typography>
          {fullLabel && (
            <>
              <Divider className="ayo-events-block__divider" orientation="vertical" />
              <Typography variant="caption">
                {t('interested count', {
                  count: interestedCount,
                })}
              </Typography>
            </>
          )}
        </Box>
      )}
      <Box alignItems="center" display="flex" flexDirection="row" gap={2}>
        {isOnlineEvent && <Chip className="ayo-events-block__location" label={t('Online')} />}
        <Tooltip
          enterTouchDelay={0}
          leaveTouchDelay={5000}
          onOpen={(e) => {
            e.stopPropagation();
            GA.logInteraction({
              category: GaCategories.BEHAVIOR,
              action: GaActions.ICON_HOVER,
              label: 'List who can see event',
            });
          }}
          placement="bottom-start"
          tabIndex={0}
          title={
            <Typography isLightText variant="caption">
              {!!sharingGroups?.length &&
                t(EventSharingGroupsLabel[resourceType][sharingGroups.join('_')]?.label)}
            </Typography>
          }
        >
          <Box className="ayo-events-block__card__visibility-icon">
            <MembersIcon />
          </Box>
        </Tooltip>
      </Box>
    </Box>
  );
};

EventLabel.propTypes = {
  fullLabel: PropTypes.bool.isRequired,
  goingCount: PropTypes.number.isRequired,
  interestedCount: PropTypes.number.isRequired,
  isOnlineEvent: PropTypes.bool.isRequired,
  isWidthUpSm: PropTypes.bool.isRequired,
  resourceType: PropTypes.string.isRequired,
  sharingGroups: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const EventCard = ({ actions, event, fullLabel, onClick }) => {
  const { i18n } = useTranslation();

  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  const {
    name,
    startDate,
    locationType,
    interestedCount,
    goingCount,
    sharingGroups,
    resourceType,
  } = event;

  return (
    <ClickableCard
      ariaLabel={name}
      className="ayo-events-block__card"
      gaLabel="Event card"
      mainContent={
        <Box display="flex" flexDirection="row">
          <Box
            className="ayo-events-block__card__date"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            px={3}
          >
            <Typography component="p" variant="h2">
              {dayjs(startDate).date()}
            </Typography>
            <Typography variant="body3">
              {capitalize(getFormattedDate(startDate, i18n.language, { month: 'short' }))}
            </Typography>
          </Box>
          <Box p={3} width="100%">
            <Box display="flex" flexDirection="row" gap={1} justifyContent="space-between">
              <TextWithTooltip
                className="ayo-clickable-card__title"
                rowsCount={2}
                title={name}
                titleVariant="subtitle2"
                tooltipClassName="ayo-clickable-card__wrapped-text-tooltip"
                typographyProps={{
                  align: 'left',
                  paragraph: true,
                }}
              />
              {actions}
            </Box>
            <EventLabel
              fullLabel={fullLabel}
              goingCount={goingCount}
              interestedCount={interestedCount}
              isOnlineEvent={locationType === EventsLocationTypes.ONLINE}
              isWidthUpSm={isWidthUpSm}
              resourceType={resourceType}
              sharingGroups={sharingGroups}
            />
          </Box>
        </Box>
      }
      onClick={onClick}
    />
  );
};

EventCard.propTypes = {
  actions: PropTypes.node,
  event: eventType.isRequired,
  fullLabel: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

EventCard.defaultProps = {
  actions: null,
  fullLabel: false,
};

export default EventCard;
