/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Chip as MUIChip } from '@mui/material';

import EmojiDisplay from '../../../atoms/emoji-display/EmojiDisplay';
import { GA } from '../../../../utils';
import { GaActions, GaCategories } from '../../../../constants/enums';
import TextWithTooltip from '../../tooltip-wrappers/text-with-tooltip/TextWithTooltip';

const Chip = React.forwardRef(
  (
    {
      ariaLabel,
      className,
      deleteIcon,
      emoji,
      gaLabel,
      icon,
      isCaption,
      isOutlined,
      isRtl,
      label,
      labelComponentProps,
      multiline,
      onClick,
      onDelete,
      noWrap,
      rowsCount,
      ...props
    },
    ref,
  ) => (
    <MUIChip
      ref={ref}
      aria-label={ariaLabel}
      className={classnames('ayo-chip', className, {
        'ayo-chip--multiline': multiline,
        'ayo-chip--outlined': isOutlined,
        'ayo-chip--caption': isCaption,
        'ayo-chip-rtl': isRtl,
      })}
      clickable={!!onClick}
      deleteIcon={deleteIcon}
      icon={icon || (emoji && <EmojiDisplay className="ayo-chip__emoji" emoji={emoji} />)}
      label={
        <TextWithTooltip
          noWrap={noWrap}
          rowsCount={rowsCount}
          title={label}
          titleProps={labelComponentProps}
        />
      }
      onClick={(e) => {
        if (onClick) {
          if (gaLabel) {
            GA.logInteraction({
              category: GaCategories.BEHAVIOR,
              action: GaActions.CHIP_CLICK,
              label: gaLabel,
            });
          }
          onClick(e);
        }
      }}
      onDelete={onDelete || null}
      {...props}
    />
  ),
);

Chip.propTypes = {
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
  deleteIcon: PropTypes.node,
  emoji: PropTypes.shape({ ariaLabel: PropTypes.string, symbol: PropTypes.string }),
  gaLabel: PropTypes.string,
  icon: PropTypes.node,
  isCaption: PropTypes.bool,
  isOutlined: PropTypes.bool,
  isRtl: PropTypes.bool,
  label: PropTypes.string,
  labelComponentProps: PropTypes.instanceOf(Object),
  multiline: PropTypes.bool,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
  noWrap: PropTypes.bool,
  rowsCount: PropTypes.number,
};

Chip.defaultProps = {
  ariaLabel: null,
  className: '',
  deleteIcon: null,
  emoji: null,
  gaLabel: null,
  icon: null,
  isCaption: false,
  isOutlined: false,
  isRtl: false,
  label: '',
  labelComponentProps: null,
  multiline: false,
  onClick: null,
  onDelete: null,
  noWrap: false,
  rowsCount: 1,
};

export default Chip;
