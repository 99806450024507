import React, { useRef, useState, useEffect, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, useTheme, useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

import { Typography, Tooltip } from '../../atoms';
import { InterestBubbleFigure } from '../../moleculas';
import { bounds, debounce, GA, pack } from '../../../utils';
import {
  GaActions,
  GaCategories,
  InterestReactionNames,
  InterestTypes,
  KeyboardMap,
  RolesMap,
} from '../../../constants/enums';
import { UserContext } from '../../../context';
import { useSearchParams } from '../../../hooks';
import { ReactComponent as LoveItIcon } from '../../../resources/icons/interest_love_it.svg';
import { ReactComponent as LikeItIcon } from '../../../resources/icons/interest_like_it.svg';
import { ReactComponent as InterestReflectionIcon } from '../../../resources/icons/interest_reflection.svg';

import InterestDescriptionPreview from './components/InterestDescriptionPreview/InterestDescriptionPreview';

const additionalLinesTransform = (lines, width) => {
  const editedLines = [...lines];
  const lastLine = editedLines[editedLines.length - 1];
  const preLastLine = editedLines[editedLines.length - 2];
  if (lastLine.items.length === 1) {
    editedLines[editedLines.length - 2] = {
      ...preLastLine,
      items: [...preLastLine.items, lastLine.items[0]],
      width: preLastLine.width + lastLine.items[0].r * 2,
      height: Math.min(preLastLine.height, lastLine.items[0].r * 2),
    };

    editedLines[editedLines.length - 1] = {
      ...preLastLine,
      items: [],
      width: 0,
      height: 0,
    };

    for (let i = 0; i < preLastLine.items.length - 1; i += 1) {
      if (editedLines[editedLines.length - 2].width < width) {
        break;
      }
      const biggestElementIndex = editedLines[editedLines.length - 2].items.reduce(
        (acc, item, idx, arr) => (arr[acc].r > arr[idx].r || arr[idx].id === 'legend' ? acc : idx),
        0,
      );
      const [movedElement] = editedLines[editedLines.length - 2].items.splice(
        biggestElementIndex,
        1,
      );
      editedLines[editedLines.length - 2] = {
        ...editedLines[editedLines.length - 2],
        width: editedLines[editedLines.length - 2].width - movedElement.r * 2,
        height: editedLines[editedLines.length - 2].items.reduce(
          (acc, item) => Math.min(acc, item.r * 2),
          +Infinity,
        ),
      };
      editedLines[editedLines.length - 1] = {
        ...editedLines[editedLines.length - 1],
        width: editedLines[editedLines.length - 1].width + movedElement.r * 2,
        height: Math.min(editedLines[editedLines.length - 1].height, movedElement.r * 2),
        items: [...editedLines[editedLines.length - 1].items, movedElement],
      };
    }
  }
  return editedLines;
};

const PLOT_PADDING = 40;
const BUFF_CIRCLE_R_INCREASE = 16;
const LEGEND_CIRCLE_R = 140;

const InterestsViewmap = React.memo(({ interests, studentName, withDescriptions }) => {
  const history = useHistory();
  const searchParams = useSearchParams();
  const extractInterestIdFromUrl = searchParams.get('interestId');
  const interestId = parseInt(extractInterestIdFromUrl, 10);
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
  const svgRef = useRef();
  const [circles, setCircles] = useState([]);
  const [currentBounds, setCurrentBounds] = useState([]);

  const [rerenderer, setRerenderer] = useState({});
  useEffect(() => {
    const rerender = debounce(() => {
      setRerenderer({});
    }, 300);
    window.addEventListener('resize', rerender);

    return () => window.removeEventListener('resize', rerender);
  }, []);

  const [previewInterestId, setPreviewInterestId] = useState(null);

  const previewInterest = useMemo(
    () => circles?.find((circle) => circle.id === previewInterestId),
    [circles, previewInterestId],
  );

  const lastDefinitionViewed = useRef(null);

  const onInterestPress = (interest) => {
    setPreviewInterestId(interest?.id);
    if (interest) {
      lastDefinitionViewed.current = interest.id;
    }
  };

  const handleBubbleClick = (interest) => () => {
    if (interest) {
      GA.logInteraction({
        category: GaCategories.BEHAVIOR,
        action: GaActions.BUBBLE_CLICK,
        label: interest.name,
      });
    }
    onInterestPress(interest);
  };

  const onBubbleKeyDown = (interest) => (e) => {
    if (e.key === KeyboardMap.ENTER || e.key === KeyboardMap.SPACE) {
      onInterestPress(interest);
    }
  };

  useEffect(() => {
    if (!previewInterest && lastDefinitionViewed.current) {
      document.querySelector(`#interest-${lastDefinitionViewed.current}`).focus();
    }
  }, [previewInterest, circles]);

  const packedWidthRef = useRef(null);

  useEffect(() => {
    const packingWidth = svgRef.current?.clientWidth || packedWidthRef.current;
    if (!interests?.length || !packingWidth) return;
    const { circles: packedInterests } = pack(
      [
        ...interests,
        {
          r: isWidthUpSm ? LEGEND_CIRCLE_R : packingWidth / 2 - PLOT_PADDING,
          spacer: 0,
          subInterests: [],
          id: 'legend',
        },
      ],
      packingWidth - PLOT_PADDING * 2,
      {
        disableSpaceAround: isWidthUpSm,
        additionalLinesTransformation: isWidthUpSm ? additionalLinesTransform : null,
      },
    );
    const packingBounds = bounds(packedInterests, PLOT_PADDING);
    setCurrentBounds(packingBounds);
    setCircles(packedInterests.filter((circle) => circle.id !== 'legend'));
    packedWidthRef.current = packingWidth;
  }, [interests, isWidthUpSm, rerenderer]);

  const [actualBounds, setActualBounds] = useState({
    x: -PLOT_PADDING,
    y: -PLOT_PADDING,
    width: null,
    height: null,
  });

  useEffect(() => {
    setActualBounds({
      x: -PLOT_PADDING,
      y: currentBounds[1],
      width: svgRef.current?.clientWidth || packedWidthRef.current || 0,
      height: currentBounds[3],
    });
  }, [currentBounds]);

  const { state: userState } = useContext(UserContext);
  const { role } = userState.profile;
  const isEducator = role === RolesMap.TEACHER || role === RolesMap.ADMINISTRATOR;

  useEffect(() => {
    if (interestId) {
      setPreviewInterestId(interestId);
      history.replace({ search: '' });
    }
  }, [history, interestId]);

  const Legend = (
    <Box className="map-legend">
      <Box mb={3}>
        <Typography variant="subtitle2">{t('Interests legend')}</Typography>
      </Box>
      <Box mr={10}>
        <Typography variant="caption">
          <LoveItIcon
            aria-label={t('Big purple circle with two planet orbits')}
            className="legend-spot"
            role="img"
          />
          {t('Love it')}
        </Typography>
        <Typography variant="caption">
          <LikeItIcon
            aria-label={t('Big purple circle with one planet orbit')}
            className="legend-spot"
            role="img"
          />
          {t('Like it')}
        </Typography>
        {isEducator && (
          <Typography variant="caption">
            <span aria-label={t('Big yellow circle')} className="suggested legend-spot" />
            {t('Suggested by AYO')}
          </Typography>
        )}
        <Typography variant="caption">
          <InterestReflectionIcon
            aria-label={t('Student comment icon')}
            className="legend-spot comment"
            role="img"
          />
          {studentName ? t("Student's comment", { studentName }) : t('My comment')}
        </Typography>
      </Box>
    </Box>
  );

  return previewInterest ? (
    <InterestDescriptionPreview
      definitions={previewInterest.definitions}
      name={previewInterest.name}
      onBackTo={handleBubbleClick(null)}
      reflection={previewInterest.reflection}
      studentName={studentName}
    />
  ) : (
    <Box className="ayo-interests-viewmap" position="relative">
      <svg
        ref={svgRef}
        className="interests-viewmap-canvas"
        height={actualBounds.height}
        viewBox={`${actualBounds.x} ${actualBounds.y} ${actualBounds.width} ${actualBounds.height}`}
        width="100%"
      >
        <defs>
          <linearGradient
            gradientUnits="objectBoundingBox"
            id="primary_gradient_0"
            x1="0.5"
            x2="0.5"
            y1="0"
            y2="1"
          >
            <stop stopColor="#6065A8" />
            <stop offset="1" stopColor="#5C43A2" stopOpacity="0.84" />
          </linearGradient>
          <linearGradient
            gradientUnits="objectBoundingBox"
            id="primary_gradient_1"
            x1="0.7"
            x2="0.2"
            y1="0"
            y2="1"
          >
            <stop stopColor="#8E3078" stopOpacity="0.41" />
            <stop offset="1" stopOpacity="0.15" />
          </linearGradient>
          <linearGradient
            gradientUnits="objectBoundingBox"
            id="secondary_gradient"
            x1="0"
            x2="1"
            y1="0"
            y2="0"
          >
            <stop stopColor="#F6D365" />
            <stop offset="1" stopColor="#FDA085" />
          </linearGradient>
        </defs>
        <g fill="none" stroke="none">
          <rect
            fill="#1E152A"
            height={actualBounds.height}
            rx={16}
            width={actualBounds.width}
            x={actualBounds.x}
            y={actualBounds.y}
          />
          {circles?.length ? (
            <g>
              <path
                d="M-300.5 119.402C-300.5 110.96 -294.057 103.991 -285.627 103.548C-230.302 100.64 -32.3813 92.2061 107.861 111.511C227.976 128.046 353.07 185.506 484.155 184.899C583.003 184.441 663.328 131.73 795.878 104.388C964.261 69.655 1249.13 96.0064 1315.42 102.871C1323.51 103.708 1329.5 110.52 1329.5 118.647V312.192C1329.5 314.696 1330.82 317.259 1330.44 319.735C1327.51 339.096 1138.01 239.647 986.252 239.647C790.201 239.647 602.248 349.38 436.283 382.748C260.788 418.033 114.911 341.02 -102.552 341.02C-241.942 352.134 -263.878 372.759 -284.805 380.083C-293.145 383.002 -300.5 375.585 -300.5 366.748V119.402Z"
                fill="#241932"
              />
              <path
                d="M1329.5 576.203C1329.5 567.483 1322.64 560.378 1313.92 560.266C1247.79 559.409 987.853 557.441 859.232 577.07C734.943 591.501 638.484 650.916 520.475 650.31C431.489 649.853 366.119 594.978 238.846 574.203C114.62 553.924 -212.353 571.797 -285.739 576.165C-294.16 576.666 -300.5 583.634 -300.5 592.07V710.53C-300.5 712.817 -301.646 715.28 -300.747 717.383C-290.638 741.032 9.4563 668.98 61.45 668.98C237.943 668.98 414.163 814.464 563.572 847.766C721.56 882.981 852.885 806.121 1048.66 806.121C1182.05 817.911 1272.62 856.661 1313.79 866.965C1322.36 869.111 1329.5 862.337 1329.5 853.5V576.203Z"
                fill="#241932"
              />
            </g>
          ) : null}
          {circles.map((circle) => {
            const tooltipText =
              circle.interestType === InterestTypes.SUGGESTED
                ? t('AYO suggests that student is interested in interest', {
                    student: studentName,
                    interest: circle.name,
                  })
                : `${t('Student is interested in interest and reacted to it with reaction', {
                    student: studentName,
                    interest: circle.name,
                    reaction: t(InterestReactionNames[circle.reaction]).replace(/[¡!]/g, ''),
                  })}${circle.reflection ? t('and added a comment') : ''}`;
            const Wrapper = studentName
              ? ({ children }) => (
                  <Tooltip enterTouchDelay={0} leaveTouchDelay={5000} title={`${tooltipText}.`}>
                    {children}
                  </Tooltip>
                )
              : React.Fragment;
            return (
              <Wrapper key={circle.id}>
                <g>
                  <InterestBubbleFigure
                    key={circle.id}
                    ariaLabel={
                      withDescriptions
                        ? tooltipText
                        : `${circle.name}${circle.reflection ? ` ${t('Has a comment')}` : ''}`
                    }
                    buffRadiusIncrease={BUFF_CIRCLE_R_INCREASE}
                    circle={circle}
                    commentPosition="top-right"
                    commentSize={24}
                    id={`interest-${circle.id}`}
                    onClick={withDescriptions ? handleBubbleClick(circle) : undefined}
                    onKeyDown={withDescriptions ? onBubbleKeyDown(circle) : undefined}
                    role={withDescriptions ? 'button' : null}
                    tabIndex={withDescriptions ? 0 : null}
                    withComment={circle.reflection}
                    withUpperText={circle.interestType !== InterestTypes.LIKED}
                  />
                </g>
              </Wrapper>
            );
          })}
        </g>
      </svg>
      {Legend}
    </Box>
  );
});

InterestsViewmap.propTypes = {
  interests: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  studentName: PropTypes.string.isRequired,
  withDescriptions: PropTypes.bool,
};

InterestsViewmap.defaultProps = {
  withDescriptions: false,
};

export default InterestsViewmap;
