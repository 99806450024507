/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { Dot, LineChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useHistory } from 'react-router-dom';

import { Button, Typography } from '../../../../../atoms';
import { Dropdown } from '../../../../../moleculas';
import { checkinFiltersValuesMap, MoodNames, Moods } from '../../../../../../constants/moods';
import { GA, getFullDateAndHoursString } from '../../../../../../utils';
import { GaActions, GaCategories, KeyboardMap } from '../../../../../../constants/enums';
import { ReactComponent as MoodNoCheckInImage } from '../../../../../../resources/images/mood_no_check_in.svg';

const graphColorsMap = {
  MAIN_COLOR: '#6065A8',
  FOCUS_FILL_COLOR: '#FFE0B2',
  FOCUS_STROKE_COLOR: '#FFA726',
  HOVER_FILL_COLOR: '#434981',
  HOVER_STROKE_COLOR: '#BCBEE6',
};

const MoodGraph = ({
  data,
  defaultGraphRange,
  defaultMoodId,
  onActiveMoodChange,
  onTimeRangeChange,
}) => {
  const { i18n, t } = useTranslation();
  const history = useHistory();
  const theme = useTheme();
  const isWidthUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const isWidthUpXl = useMediaQuery(theme.breakpoints.up('xl'));

  const dropDownOptions = useMemo(
    () => [
      { value: checkinFiltersValuesMap.LAST_CHECK_IN, label: t('Last mood check-in') },
      { value: checkinFiltersValuesMap.TODAY, label: t('Today') },
      { value: checkinFiltersValuesMap.LAST_WEEK, label: t('Last week') },
      { value: checkinFiltersValuesMap.LAST_MONTH, label: t('Last month') },
      { value: checkinFiltersValuesMap.LAST_YEAR, label: t('Last year') },
      { value: checkinFiltersValuesMap.ALL, label: t('All') },
    ],
    [t],
  );

  const [focusedPointIndex, setFocusedPointIndex] = useState(null);
  const [hoveredPointIndex, setHoveredPointIndex] = useState(null);

  const [currentValue, setCurrentValue] = useState(checkinFiltersValuesMap.LAST_WEEK);

  useEffect(() => {
    if (!defaultMoodId) {
      onTimeRangeChange(currentValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (defaultMoodId && data) {
      const defaultMoodIndex = data?.findIndex((mood) => mood.id === defaultMoodId);
      setFocusedPointIndex(defaultMoodIndex);
      setCurrentValue(defaultGraphRange);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, defaultMoodId]);

  const handleDropdownValueChange = useCallback(
    (e) => {
      if (history.location.search) {
        history.replace({ search: null });
      }
      setCurrentValue(e.target.value);
      setFocusedPointIndex(null);
      setHoveredPointIndex(null);
      onTimeRangeChange(e.target.value);
    },
    [history, onTimeRangeChange],
  );

  const handlePointClick = useCallback(
    (index) => {
      GA.logInteraction({
        category: GaCategories.BEHAVIOR,
        action: GaActions.GRAPH_PART_CLICKED,
        label: 'Mood details - graph dot click',
      });
      setFocusedPointIndex(index);
      onActiveMoodChange(data[index]);
    },
    [data, onActiveMoodChange],
  );

  const renderActivePoint = useCallback(
    (config) => {
      const isFocusedPoint = config.index === focusedPointIndex;
      const props = {
        ...config,
        fill: graphColorsMap[isFocusedPoint ? 'FOCUS_FILL_COLOR' : 'HOVER_FILL_COLOR'],
        onClick: () => handlePointClick(config.index),
        stroke: graphColorsMap[isFocusedPoint ? 'FOCUS_STROKE_COLOR' : 'HOVER_STROKE_COLOR'],
        strokeWidth: 2,
        r: 10,
      };
      setHoveredPointIndex(config.index);
      return <Dot {...props} />;
    },
    [focusedPointIndex, handlePointClick],
  );

  const renderPoint = useCallback(
    (config) => {
      const isFocusedPoint = config.index === focusedPointIndex;
      const props = {
        ...config,
        fill: graphColorsMap[isFocusedPoint ? 'FOCUS_FILL_COLOR' : 'MAIN_COLOR'],
        r: isFocusedPoint
          ? 10
          : currentValue === checkinFiltersValuesMap.ALL ||
            currentValue === checkinFiltersValuesMap.LAST_YEAR
          ? 0
          : 4,
        stroke: isFocusedPoint ? graphColorsMap.FOCUS_STROKE_COLOR : 'transparent',
        strokeWidth: isFocusedPoint ? 2 : 0,
      };
      return <Dot {...props} />;
    },
    [currentValue, focusedPointIndex],
  );

  const renderTooltip = useCallback(() => {
    if (hoveredPointIndex === null) return null;
    return (
      <Typography isLightText variant="caption">
        <b>{data[hoveredPointIndex].moodLabel}</b>
        <br />
        <span>{getFullDateAndHoursString(data[hoveredPointIndex].createdDate, i18n.language)}</span>
      </Typography>
    );
  }, [data, hoveredPointIndex, i18n.language]);

  return (
    <div className="ayo-mood-graph">
      <Box
        alignItems={isWidthUpMd ? 'center' : 'flex-start'}
        display="flex"
        flexDirection={isWidthUpMd ? 'row' : 'column'}
        maxWidth={isWidthUpMd ? '50%' : '100%'}
        mb={2}
      >
        <Typography variant="body2">{t('Show analytics for')}</Typography>
        <Dropdown
          fullWidth
          gaLabel="Show analytics for"
          handleChange={handleDropdownValueChange}
          options={dropDownOptions}
          outlined
          value={currentValue}
        />
      </Box>
      {data ? (
        <>
          <Button
            className="sr-only"
            onClick={() => {
              setFocusedPointIndex((state) =>
                state === null || state >= data.length - 1 ? 0 : state + 1,
              );
            }}
            onKeyDown={(e) => {
              if (e.key === KeyboardMap.ENTER) {
                if (e.shiftKey) {
                  setFocusedPointIndex((state) =>
                    state === null || state <= 0 ? data.length - 1 : state - 1,
                  );
                }
                if (e.ctrlKey) {
                  handlePointClick(focusedPointIndex);
                }
                e.preventDefault();
              }
            }}
          >
            {t(
              'Use Enter / Shift+Enter keys to read information from the Mood Graph Press Ctrl+Enter to activate current data point',
            )}
          </Button>
          <div className="sr-only" role="alert">
            {t('Data point num of count', { num: focusedPointIndex + 1, count: data.length })}
            {', '}
            {data[focusedPointIndex]?.moodLabel}
            {', '}
            {getFullDateAndHoursString(data[focusedPointIndex]?.createdDate, i18n.language)}
          </div>
          <ResponsiveContainer height={400} width="100%">
            <LineChart
              data={data}
              height={300}
              margin={{
                top: 20,
                left: isWidthUpMd ? (isWidthUpXl ? 80 : 48) : -48,
                bottom: isWidthUpMd ? 20 : 80,
              }}
              width={500}
            >
              <XAxis
                dataKey="dateLabel"
                minTickGap={isWidthUpMd ? 200 : 0}
                padding={{ right: isWidthUpXl ? 96 : 72 }}
                tickLine={false}
                tickMargin={16}
              />
              <YAxis
                dataKey="moodLabel"
                domain={[
                  t(MoodNames[Moods.ANGRY]),
                  t(MoodNames[Moods.SAD]),
                  t(MoodNames[Moods.FRUSTRATED]),
                  t(MoodNames[Moods.WORRIED]),
                  t(MoodNames[Moods.TIRED]),
                  t(MoodNames[Moods.CALM]),
                  t(MoodNames[Moods.SILLY]),
                  t(MoodNames[Moods.HAPPY]),
                ]}
                tick={isWidthUpMd}
                tickLine={false}
                tickMargin={16}
                type="category"
              />
              <Tooltip content={renderTooltip} />
              <Line
                activeDot={renderActivePoint}
                dataKey="moodLabel"
                dot={renderPoint}
                isAnimationActive={false}
                stroke={graphColorsMap.MAIN_COLOR}
                strokeWidth={3}
                type="monotone"
              />
            </LineChart>
          </ResponsiveContainer>
        </>
      ) : (
        <Box alignItems="center" display="flex" flexDirection="column" py={9} textAlign="center">
          <Box pb={5}>
            <MoodNoCheckInImage
              aria-label={t('A backpack left alone on the sidewalk')}
              className="ayo-svg-illustration"
            />
          </Box>
          <Box pb={1}>
            <Typography variant="subtitle2">
              {t('Oops! Looks like there are no mood check-ins for the selected period!')}
            </Typography>
          </Box>
          <Typography variant="body2">{t('Please try another period')}</Typography>
        </Box>
      )}
    </div>
  );
};

MoodGraph.propTypes = {
  data: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  defaultGraphRange: PropTypes.string.isRequired,
  defaultMoodId: PropTypes.number.isRequired,
  onActiveMoodChange: PropTypes.func.isRequired,
  onTimeRangeChange: PropTypes.func.isRequired,
};

MoodGraph.defaultProps = {
  data: null,
};

export default MoodGraph;
