import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Avatar, Box, Popover } from '@mui/material';

import { Link, Typography, Button } from '../../atoms';
import { DisplayAvatarEditorImage } from '../../moleculas';
import { authorContactInfo } from '../../../constants/propTypes';
import { ReactComponent as EnvelopeIcon } from '../../../resources/icons/envelope_dark.svg';
import { ReactComponent as PhoneIcon } from '../../../resources/icons/phone.svg';
import { ReactComponent as CloseIcon } from '../../../resources/icons/close_light.svg';

const ContactInfoPopover = ({ anchorEl, authorName, author, onClose }) => {
  const { t } = useTranslation();
  const { avatar, email, phone, position, type } = author;

  const withContactInfo = useMemo(
    () => position === 'Educator' || type === 'SCHOOL' || type === 'DISTRICT',
    [position, type],
  );

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      className="ayo-contact-info-popover"
      onClose={onClose}
      open={Boolean(anchorEl)}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Box className="ayo-contact-info-popover__card" display="flex" gap={2} p={2}>
        <Box>
          {avatar ? (
            <DisplayAvatarEditorImage
              avatar={avatar?.image}
              avatarPlaceholder="teacher"
              avatarSize={80}
              avatarTransforms={avatar?.transforms}
            />
          ) : (
            <Avatar src={author?.profileImage} sx={{ width: 80, height: 80 }} />
          )}
        </Box>
        <Box flex={1}>
          <Box alignItems="start" display="flex" justifyContent="space-between">
            <Box mr={1}>
              <Typography component="h2" variant="subtitle2">
                {authorName}
              </Typography>
              {!!position && <Typography variant="caption">{t(`${position}`)}</Typography>}
            </Box>
            <Button
              aria-label={t('Close')}
              autoFocus
              className="ayo-contact-info-popover__card__close-btn"
              isIconButton
              onClick={onClose}
            >
              <CloseIcon />
            </Button>
          </Box>
          {withContactInfo && !!(email || phone) && (
            <Box display="flex" flexDirection="column" mt={2}>
              {!!email && (
                <Link href={`mailto:${email}`} icon={<EnvelopeIcon />} iconPosition="start">
                  {email}
                </Link>
              )}
              {!!phone && (
                <Link href={`tel:${phone}`} icon={<PhoneIcon />} iconPosition="start">
                  {phone}
                </Link>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Popover>
  );
};

ContactInfoPopover.propTypes = {
  anchorEl: PropTypes.node,
  author: authorContactInfo,
  authorName: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};
ContactInfoPopover.defaultProps = {
  anchorEl: null,
  author: null,
  authorName: null,
};

export default ContactInfoPopover;
