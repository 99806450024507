import React, { useContext } from 'react';
import { Box, Container, Grid, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery';

import { Typography } from '../../../../../../atoms';
import { RolesMap } from '../../../../../../../constants/enums';
import { ReactComponent as LeadershipAttributesHowDoesItWorkSpotGoals } from '../../../../../../../resources/images/leadership_how_does_it_work_spot_goals.svg';
import { ReactComponent as LeadershipAttributesHowDoesItWorkTakeGuidance } from '../../../../../../../resources/images/leadership_how_does_it_work_take_guidance.svg';
import { ReactComponent as LeadershipAttributesHowDoesItWorkEnjoy } from '../../../../../../../resources/images/leadership_how_does_it_work_enjoy.svg';
import { ReactComponent as LeadershipAttributesHowDoesItWorkArrow } from '../../../../../../../resources/images/leadership_how_does_it_work_arrow.svg';
import { UserContext } from '../../../../../../../context';

const LeadershipAttributesHowDoesItWorkSection = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { state: userState } = useContext(UserContext);
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const isWidthUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const isGuardian = userState.profile?.role === RolesMap.GUARDIAN;

  return (
    <Container className="ayo-leadership-attributes-how-does-it-work">
      <Box mb={isWidthUpSm ? 12.75 : 7.75} mt={isWidthUpSm ? 12 : 7} mx={isWidthUpMd ? 13.5 : 0}>
        <Grid className="ayo-leadership-attributes-how-does-it-work__content-block" container>
          <Grid
            className="ayo-leadership-attributes-how-does-it-work__text"
            container
            direction="column"
            item
            md={7}
            sm={9}
            xs={12}
          >
            <Typography paragraph variant="h2">
              {t('How does the Leadership Attributes pathway work?')}
            </Typography>
            <Typography variant="body2">
              {t(
                'In the first place, the Leadership Attributes pathway will help to create awareness',
              )}
            </Typography>
            <Typography variant="body2">
              {t(
                'Under the assessment and guidance of their teachers, each student will decide the competency to work on',
              )}
            </Typography>
            <Typography variant="body2">
              {t('Each competency will have milestones set personally by each student')}
            </Typography>
            <Typography variant="body2">
              {t(
                'By taking one competency at a time, it will be easier for students to reflect on the process',
              )}
            </Typography>
            <Typography paragraph variant="body2">
              {t(
                'Through access to a resource bank plus teacher support, students will be guided in the Leadership Attributes learning path',
              )}
            </Typography>
            {!isGuardian && (
              <Typography variant="body2">
                {t(
                  'The initial version of the Leadership attributes assessment tool is available below! It will be changing and evolving',
                )}
              </Typography>
            )}
          </Grid>
          <Grid container>
            <Grid
              alignItems={isWidthUpSm ? 'baseline' : 'center'}
              container
              direction={isWidthUpSm ? 'row' : 'column'}
              item
              justifyContent="center"
              sm={3}
              xs={12}
            >
              <Typography component="h3" variant="subtitle2">
                {t('Spot your goals')}
              </Typography>
              <LeadershipAttributesHowDoesItWorkSpotGoals
                aria-label={t('An empty canvas, a telescop and stars')}
                className="ayo-leadership-attributes-how-does-it-work__illustration"
              />
            </Grid>
            <Grid alignItems="center" container item justifyContent="center" sm={1} xs={12}>
              <LeadershipAttributesHowDoesItWorkArrow className="ayo-leadership-attributes-how-does-it-work__illustration__arrow" />
            </Grid>
            <Grid
              alignItems={isWidthUpSm ? 'baseline' : 'center'}
              className="ayo-leadership-attributes-how-does-it-work__illustration-block"
              container
              direction={isWidthUpSm ? 'row' : 'column'}
              item
              justifyContent="center"
              sm={4}
              xs={12}
            >
              <Typography component="h3" noWrap variant="subtitle2">
                {t('Take guidance and use resource bank')}
              </Typography>
              <LeadershipAttributesHowDoesItWorkTakeGuidance
                aria-label={t(
                  'One hand holding a brush for painting and another holding a pallete',
                )}
                className="ayo-leadership-attributes-how-does-it-work__illustration"
              />
            </Grid>
            <Grid alignItems="center" container item justifyContent="center" sm={1} xs={12}>
              <LeadershipAttributesHowDoesItWorkArrow className="ayo-leadership-attributes-how-does-it-work__illustration__arrow" />
            </Grid>
            <Grid
              alignItems={isWidthUpSm ? 'baseline' : 'center'}
              container
              direction={isWidthUpSm ? 'row' : 'column'}
              item
              justifyContent="center"
              sm={3}
              xs={12}
            >
              <Typography component="h3" variant="subtitle2">
                {t('Enjoy the process')}
              </Typography>
              <LeadershipAttributesHowDoesItWorkEnjoy
                aria-label={t('A completed painting, brush and a pallette')}
                className="ayo-leadership-attributes-how-does-it-work__illustration"
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default LeadershipAttributesHowDoesItWorkSection;
