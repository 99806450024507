import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { Typography } from '../../../../../../../atoms';
import { getFormattedDate } from '../../../../../../../../utils';

const AnswerItem = ({ questionnaire }) => {
  const { i18n, t } = useTranslation();

  return (
    <Box>
      {questionnaire?.map((guardiansAnswer) => (
        <Box key={`${guardiansAnswer.academicYear}-${guardiansAnswer.questionId}`}>
          <Box mt={1}>
            <div key={guardiansAnswer.firstName}>
              <Box className="ayo-student-details-page__answer" p={2}>
                <Box pb={1}>
                  <Typography isLabel variant="subtitle2">
                    {guardiansAnswer.firstName}{' '}
                    {guardiansAnswer.relationship && `(${guardiansAnswer.relationship})`}
                  </Typography>
                </Box>
                <Typography
                  className={guardiansAnswer.answer ? '' : 'ayo-student-details-page__answer__text'}
                  variant="body2"
                >
                  {guardiansAnswer.answer || t('Skipped answer')}
                </Typography>
                <Box pt={1.5}>
                  <Typography isLabel variant="body3">
                    {getFormattedDate(questionnaire[0].startTime, i18n.language, {
                      month: 'short',
                      year: 'numeric',
                    })}
                  </Typography>
                </Box>
              </Box>
            </div>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

AnswerItem.propTypes = {
  questionnaire: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
};

AnswerItem.defaultProps = {
  questionnaire: [],
};

export default AnswerItem;
