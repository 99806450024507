import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import PropTypes from 'prop-types';

import { ActionsMenu, Button, DynamicGrid, Link, Tooltip, Typography } from '../../../../../atoms';
import { Chip } from '../../../../../moleculas';
import { ConfirmDialog } from '../../../../../organisms';
import { formatUrl, getClubFormValidation } from '../../../../../../utils';
import {
  SocialMediaIcons,
  SocialMediaLabels,
  SocialMediaTypes,
} from '../../../../../../constants/social-media-links';
import { ClubKeys } from '../../../../../../constants/club-hub';
import { ReactComponent as AddLink } from '../../../../../../resources/icons/add_link.svg';
import { ReactComponent as CreateIcon } from '../../../../../../resources/icons/create.svg';
import { ReactComponent as DeleteIcon } from '../../../../../../resources/icons/delete_primary.svg';
import { ReactComponent as BadgePointerIcon } from '../../../../../../resources/icons/badge_pointer_center.svg';
import ClubEditDialog from '../club-edit-dialog/ClubEditDialog';
import ClubLinks from '../../../club-hub-components/club-links/ClubLinks';

const ClubDetailsLinks = ({ isEditable, currentLinks, onSave }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const isWidthUpMd = useMediaQuery(theme.breakpoints.up('md'));

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const [newLinks, setNewLinks] = useState([]);

  const linksChangeHandler = useCallback((change) => {
    const { links } = change;
    setNewLinks(links);
  }, []);

  const linkEditHandler = useCallback(() => {
    setIsEditDialogOpen(true);
    setNewLinks(currentLinks.length ? currentLinks.map(({ url }) => url) : ['']);
  }, [currentLinks]);

  const linksSaveHandler = useCallback(() => {
    const linksPostBody = newLinks.filter((link) => link).map((link) => formatUrl(link));
    const { isFormValid } = getClubFormValidation({ [ClubKeys.LINKS]: linksPostBody }, t);
    if (isFormValid) {
      onSave({ [ClubKeys.LINKS]: newLinks.map((link) => formatUrl(link)) });
      setIsEditDialogOpen(false);
    }
  }, [newLinks, onSave, t]);

  const linksDeleteHandler = useCallback(() => {
    onSave(
      { [ClubKeys.LINKS]: [] },
      {
        text: t('The links and social media have been removed.'),
        type: 'delete',
      },
      {
        text: t('AYO couldn’t remove links and social media. Please try once more.'),
        type: 'error',
      },
    );
    setIsDeleteDialogOpen(false);
  }, [onSave, t]);

  return (
    <>
      {currentLinks.length ? (
        <Box className="ayo-club-hub-details__links" pb={3} pt={4} px={3}>
          <Box display="flex" flexDirection="row" justifyContent="space-between" mb={2}>
            <Typography component="h3" variant="subtitle2">
              {t('Links & social media')}
            </Typography>
            {isEditable && (
              <ActionsMenu
                id="club-hub-links-actions"
                menuItems={[
                  {
                    text: t('Edit'),
                    icon: CreateIcon,
                    handler: linkEditHandler,
                    id: 'club-links-edit',
                    gaLabel: 'Edit',
                  },
                  {
                    text: t('Delete'),
                    icon: DeleteIcon,
                    handler: () => {
                      setIsDeleteDialogOpen(true);
                    },
                    id: 'club-links-delete',
                    gaLabel: 'Delete',
                  },
                ]}
              />
            )}
          </Box>
          <DynamicGrid
            columnsCount={isWidthUpMd ? 5 : isWidthUpSm ? 4 : 1}
            gridItems={currentLinks.map(({ linkType, url }, index) => {
              const LinkIcon = SocialMediaIcons[linkType];
              const websiteLabel =
                linkType === SocialMediaTypes.GENERAL
                  ? t('Website').toLowerCase()
                  : SocialMediaLabels[linkType];
              return (
                <Tooltip
                  key={`${linkType}-${index}`}
                  isDisabled={!isWidthUpSm}
                  title={t(
                    linkType !== SocialMediaTypes.GENERAL
                      ? 'Open websiteName in a new tab'
                      : 'Open a website in a new tab',
                    {
                      website: websiteLabel,
                    },
                  )}
                >
                  <Box display="flex" justifyContent="flex-start">
                    <Link
                      gaLabel="Club link & social media"
                      href={url}
                      target="_blank"
                      underline="none"
                    >
                      <Box alignItems="center" display="flex" flexDirection="row">
                        <LinkIcon
                          aria-hidden="true"
                          className="ayo-club-hub-details__links__icon"
                        />
                        {!isWidthUpSm && (
                          <Box alignItems="center" display="flex" flexDirection="row" ml={1}>
                            <BadgePointerIcon />
                            <Chip
                              className="ayo-club-hub-details__links__chip"
                              label={t(
                                linkType !== SocialMediaTypes.GENERAL
                                  ? 'Open websiteName page'
                                  : 'Open a website',
                                {
                                  website: websiteLabel,
                                },
                              )}
                            />
                          </Box>
                        )}
                      </Box>
                    </Link>
                  </Box>
                </Tooltip>
              );
            })}
          />
        </Box>
      ) : isEditable ? (
        <Box
          className="ayo-club-hub-details__links__empty-state"
          display="flex"
          justifyContent="center"
          py={6}
        >
          <Button endIcon={<AddLink />} gaLabel="Add links" onClick={linkEditHandler}>
            {t('Add links & social media')}
          </Button>
        </Box>
      ) : null}
      {isEditable && (
        <>
          <ClubEditDialog
            className="ayo-club-hub-details__edit-dialog__links"
            dialogContent={
              <ClubLinks
                clubValueKey={ClubKeys.LINKS}
                gaLabel="Link & social media"
                links={newLinks}
                onChange={linksChangeHandler}
              />
            }
            dialogTitle={t('Add links & social media')}
            isOpen={isEditDialogOpen}
            onClose={() => {
              setIsEditDialogOpen(false);
            }}
            onSave={linksSaveHandler}
          />
          <ConfirmDialog
            cancelButtonTitle="Cancel"
            confirmButtonTitle="Delete links"
            isOpen={isDeleteDialogOpen}
            onClose={() => setIsDeleteDialogOpen(false)}
            onConfirm={linksDeleteHandler}
            text="Once you delete the links and social media, they will be removed from the club’s page. However, you’ll be able to add them again later."
            title="Are you sure you want to delete all your links and social media?"
          />
        </>
      )}
    </>
  );
};

ClubDetailsLinks.propTypes = {
  isEditable: PropTypes.bool.isRequired,
  currentLinks: PropTypes.arrayOf(
    PropTypes.shape({
      linkType: PropTypes.string,
      linkUrl: PropTypes.string,
    }),
  ).isRequired,
  onSave: PropTypes.func.isRequired,
};

export default ClubDetailsLinks;
