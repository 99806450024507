import React from 'react';
import { Box, Container, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Typography } from '../../../../../../atoms';
import { ReactComponent as LeadershipAtrebuteOwerviewItemDesktop } from '../../../../../../../resources/images/leadership-attributes-what-is-section-desktop.svg';
import { ReactComponent as LeadershipAtrebuteOwerviewItemMobile } from '../../../../../../../resources/images/leadership-attributes-what-is-section-mobile.svg';

const LeadershipAttributesWhatIsSection = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Container>
      <Box className="ayo-leadership-attributes-what-is-section" mt={isWidthUpSm ? 12 : 7}>
        <Box className="ayo-leadership-attributes-what-is-section__text-block">
          <Typography paragraph variant="h2">
            {t('The goal of the Leadership Attributes pathway')}
          </Typography>
          <Typography variant="body2">
            {t(
              'Leadership Attributes—a personal development/educational path that develops leadership skills—relies on each student for optimal results',
            )}
          </Typography>
        </Box>
        <Box pt={isWidthUpSm ? 0 : 1}>
          {isWidthUpSm ? (
            <LeadershipAtrebuteOwerviewItemDesktop
              aria-label={t('Scattered pictures of the Leadership attributes')}
              className="ayo-svg-illustration--secondary"
              role="img"
            />
          ) : (
            <LeadershipAtrebuteOwerviewItemMobile
              aria-label={t('Scattered pictures of the Leadership attributes')}
              className="ayo-svg-illustration--secondary"
              role="img"
            />
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default LeadershipAttributesWhatIsSection;
