import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Box, DialogActions, DialogContent } from '@mui/material';

import {
  Dialog,
  DialogTitle,
  DotIndicator,
  FormControlLabel,
  Radio,
  Typography,
} from '../../../../../atoms';
import { PopupActionsButtons } from '../../../../../moleculas';
import { getFormattedDate } from '../../../../../../utils';

const PortfolioRecommendationRequestDialog = ({
  isOpen,
  onClose,
  studentRequests,
  requestSelectHandler,
  notificationsList,
}) => {
  const { t, i18n } = useTranslation();
  const [selectedRequest, setSelectedRequest] = useState(null);

  const requestChangeHandler = useCallback((e) => {
    const { value } = e.target;
    setSelectedRequest(+value);
  }, []);

  useEffect(() => {
    setSelectedRequest(null);
  }, [isOpen]);

  return (
    <Dialog
      className="ayo-portfolio__recommendations-request-dialog"
      onClose={onClose}
      open={isOpen}
    >
      <DialogTitle disableTypography>
        <Typography component="h2" variant="subtitle2">
          {t('Recommendation requests')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box mb={3} mt={1}>
          <Typography variant="body2">
            {t('Here, you can see all pending recommendation requests from this student')}
          </Typography>
        </Box>
        <Box mb={1}>
          {studentRequests.map((request) => {
            const isNewItem = notificationsList?.some(
              (notification) => notification.resourceId === request.id,
            );
            return (
              <Box key={request.id} mb={2}>
                <FormControlLabel
                  control={
                    <Radio
                      checked={selectedRequest === request.id}
                      gaLabel={request.name}
                      name={request.id}
                      onChange={requestChangeHandler}
                      value={request.id}
                    />
                  }
                  label={
                    <Box>
                      <Box alignItems="center" display="flex" flexDirection="row">
                        <Typography
                          className="ayo-portfolio__recommendations-request-dialog__label"
                          variant={isNewItem ? 'subtitle2' : 'body2'}
                        >
                          {request.details}
                        </Typography>
                        {isNewItem && (
                          <Box ml={1}>
                            <DotIndicator ariaLabel={t('New')} />
                          </Box>
                        )}
                      </Box>
                      <Box mt={0.5}>
                        <Typography variant="caption">{`${t('Requested on')} ${getFormattedDate(
                          request.requestedOn,
                          i18n.language,
                        )}`}</Typography>
                      </Box>
                    </Box>
                  }
                />
              </Box>
            );
          })}
        </Box>
      </DialogContent>
      <DialogActions>
        <PopupActionsButtons
          primaryButtonGaLabel="Start writing"
          primaryButtonHandler={() => requestSelectHandler(selectedRequest)}
          primaryButtonText={t('Start writing')}
          secondaryButtonGaLabel="Cancel"
          secondaryButtonHandler={onClose}
          secondaryButtonText={t('Cancel')}
        />
      </DialogActions>
    </Dialog>
  );
};

PortfolioRecommendationRequestDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  requestSelectHandler: PropTypes.func.isRequired,
  studentRequests: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  notificationsList: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
};

export default PortfolioRecommendationRequestDialog;
