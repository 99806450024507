/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment,react/prop-types */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, DialogActions, DialogContent, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { DatePicker, Dialog, DialogTitle, TextField, Typography } from '../../../../atoms';
import { PopupActionsButtons, STTTextField } from '../../../../moleculas';
import {
  CertificateKeys,
  InputsValidationErrors,
  InputsValidationRules,
  minProperDate,
} from '../../../../../constants/enums';
import { byteToMegaByteCoeff } from '../../../../../constants/values';
import { loginAllowedFilterRE, urlAllowedValidationRE } from '../../../../../constants/regexps';
import { ReactComponent as DatePickerIcon } from '../../../../../resources/icons/date_picker.svg';
import { formatUrl, transformFileUploadData } from '../../../../../utils';
import FileUpload from '../../../fileupload/FileUpload';

const InputsIds = {
  [CertificateKeys.DESCRIPTION]: 'portfolio-certificate-description',
  [CertificateKeys.LINK]: 'portfolio-certificate-link',
  [CertificateKeys.ORGANIZATION]: 'portfolio-certificate-organization',
  [CertificateKeys.TITLE]: 'portfolio-certificate-title',
};

const emptyCertificateData = {
  [CertificateKeys.DESCRIPTION]: '',
  [CertificateKeys.EXPIRATION_DATE]: null,
  [CertificateKeys.LINK]: '',
  [CertificateKeys.OBTAINING_DATE]: null,
  [CertificateKeys.ORGANIZATION]: '',
  [CertificateKeys.TITLE]: '',
};

const DatePickerInput = (props) => {
  const { t } = useTranslation();
  return (
    <TextField
      {...props}
      fullWidth
      helperText={props.error && InputsValidationErrors(t).DATE_ERROR_TEXT}
      InputProps={{
        endAdornment: (
          <IconButton
            ref={props.inputRef}
            {...props.InputProps.endAdornment.props.children.props}
            className="ayo-icon-button"
            disableRipple
          >
            <DatePickerIcon />
          </IconButton>
        ),
      }}
    />
  );
};

const getFormValidation = (values) => {
  const link = values[CertificateKeys.LINK] && formatUrl(values[CertificateKeys.LINK]);

  const validation = {
    [CertificateKeys.TITLE]:
      values[CertificateKeys.TITLE].trim().length >= InputsValidationRules.MIN_INPUT_LENGTH &&
      values[CertificateKeys.TITLE].length <= InputsValidationRules.MAX_TITLE_LENGTH,
    [CertificateKeys.ORGANIZATION]:
      values[CertificateKeys.ORGANIZATION].trim().length >=
        InputsValidationRules.MIN_INPUT_LENGTH &&
      values[CertificateKeys.ORGANIZATION].length <= InputsValidationRules.MAX_TITLE_LENGTH,
    [CertificateKeys.LINK]: link
      ? urlAllowedValidationRE.test(link) &&
        link.length <= InputsValidationRules.MAX_LINK_INPUT_LENGTH
      : true,
    [CertificateKeys.DESCRIPTION]: values[CertificateKeys.DESCRIPTION]
      ? values[CertificateKeys.DESCRIPTION].length <= InputsValidationRules.MAX_INPUT_LENGTH
      : true,
    [CertificateKeys.OBTAINING_DATE]: values[CertificateKeys.OBTAINING_DATE]
      ? dayjs(values[CertificateKeys.OBTAINING_DATE]).isValid() &&
        !dayjs(values[CertificateKeys.OBTAINING_DATE]).isBefore(minProperDate, 'day')
      : true,
    [CertificateKeys.EXPIRATION_DATE]: values[CertificateKeys.EXPIRATION_DATE]
      ? dayjs(values[CertificateKeys.EXPIRATION_DATE]).isValid() &&
        !dayjs(values[CertificateKeys.EXPIRATION_DATE]).isBefore(minProperDate, 'day')
      : true,
  };

  return {
    isFormValid: !Object.values(validation).some((value) => !value),
    minTitleLengthError:
      values[CertificateKeys.TITLE].trim().length < InputsValidationRules.MIN_INPUT_LENGTH,
    minOrganizationLengthError:
      values[CertificateKeys.ORGANIZATION].trim().length < InputsValidationRules.MIN_INPUT_LENGTH,
    invalidLinkError: !!values[CertificateKeys.LINK] && !urlAllowedValidationRE.test(link),
  };
};

const PortfolioAddCertificateDialog = ({ isOpen, onClose, onSave, certificate }) => {
  const { t } = useTranslation();

  const [certificateData, setCertificateData] = useState({});
  const [inputsErrorsMessages, setInputsErrorsMessages] = useState({});
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [removedAttachments, setRemovedAttachments] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState(null);

  const submitButtonRef = useRef();

  const certificateChangeHandler = useCallback(
    (e) => {
      const { name, value } = e.target;
      setCertificateData((state) => ({
        ...state,
        [name]: value,
      }));
      if (inputsErrorsMessages[name]) {
        setInputsErrorsMessages((state) => ({ ...state, [name]: '' }));
      }
    },
    [inputsErrorsMessages],
  );

  const certificateDateHandler = useCallback((e, key) => {
    setCertificateData((state) => ({
      ...state,
      [key]: e ? dayjs(e).format('YYYY-MM-DD') : null,
    }));
  }, []);

  const isValid = useCallback(() => {
    const formValidation = getFormValidation(certificateData);
    if (formValidation.minTitleLengthError) {
      setInputsErrorsMessages((state) => ({
        ...state,
        [CertificateKeys.TITLE]: InputsValidationErrors(t, InputsValidationRules.MIN_INPUT_LENGTH)
          .MIN_ERROR_TEXT,
      }));
    }
    if (formValidation.minOrganizationLengthError) {
      setInputsErrorsMessages((state) => ({
        ...state,
        [CertificateKeys.ORGANIZATION]: InputsValidationErrors(
          t,
          InputsValidationRules.MIN_INPUT_LENGTH,
        ).MIN_ERROR_TEXT,
      }));
    }
    if (formValidation.invalidLinkError) {
      setInputsErrorsMessages((state) => ({
        ...state,
        [CertificateKeys.LINK]: InputsValidationErrors(t).LINK_ERROR_TEXT,
      }));
    }
    return formValidation.isFormValid;
  }, [certificateData, t]);

  useEffect(() => {
    setCertificateData(certificate || emptyCertificateData);
    setFilesToUpload([]);
    setRemovedAttachments([]);
    setInputsErrorsMessages({});
    if (certificate?.[CertificateKeys.ATTACHMENTS]?.length) {
      setUploadedFiles(
        certificate[CertificateKeys.ATTACHMENTS].map((el) => ({
          file: { name: el.fileName, id: el.id },
          errors: [],
        })),
      );
    } else {
      setUploadedFiles(null);
    }
  }, [certificate, isOpen]);

  return (
    <Dialog
      gaLabel="Certificate dialog"
      onClose={onClose}
      open={isOpen}
      transitionDuration={{ exit: 0 }}
    >
      <DialogTitle disableTypography>
        <Box pb={3}>
          <Typography component="h2" variant="h2">
            {certificate ? t('Edit certificate') : t('Add certificate')}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box mb={3} mt={1}>
          <STTTextField
            error={inputsErrorsMessages[CertificateKeys.TITLE]}
            fullWidth
            gaLabel="Certificate title"
            helperText={inputsErrorsMessages[CertificateKeys.TITLE]}
            InputLabelProps={{ id: InputsIds[CertificateKeys.TITLE] }}
            inputProps={{
              'aria-labelledby': InputsIds[CertificateKeys.TITLE],
              autoComplete: 'off',
            }}
            inputRE={loginAllowedFilterRE}
            label={t('Title')}
            maxLength={InputsValidationRules.MAX_TITLE_LENGTH}
            name={CertificateKeys.TITLE}
            onChange={certificateChangeHandler}
            outlined
            required
            value={certificateData[CertificateKeys.TITLE]}
          />
        </Box>
        <Box mb={3}>
          <STTTextField
            fullWidth
            gaLabel="Certificate description"
            InputLabelProps={{ id: InputsIds[CertificateKeys.DESCRIPTION] }}
            inputProps={{
              'aria-labelledby': InputsIds[CertificateKeys.DESCRIPTION],
              autoComplete: 'off',
            }}
            label={t('Description')}
            maxLength={InputsValidationRules.MAX_INPUT_LENGTH}
            multiline
            name={CertificateKeys.DESCRIPTION}
            onChange={certificateChangeHandler}
            outlined
            rowsCount={3}
            value={certificateData[CertificateKeys.DESCRIPTION]}
          />
        </Box>
        <Box mb={3}>
          <STTTextField
            error={inputsErrorsMessages[CertificateKeys.ORGANIZATION]}
            fullWidth
            gaLabel="Certificate organization"
            helperText={inputsErrorsMessages[CertificateKeys.ORGANIZATION]}
            InputLabelProps={{ id: InputsIds[CertificateKeys.ORGANIZATION] }}
            inputProps={{
              'aria-labelledby': InputsIds[CertificateKeys.ORGANIZATION],
              autoComplete: 'off',
            }}
            inputRE={loginAllowedFilterRE}
            label={t('Organization')}
            maxLength={InputsValidationRules.MAX_TITLE_LENGTH}
            name={CertificateKeys.ORGANIZATION}
            onChange={certificateChangeHandler}
            outlined
            required
            value={certificateData[CertificateKeys.ORGANIZATION]}
          />
        </Box>
        <Box mb={3}>
          <DatePicker
            gaLabel="Certificate obtaining date"
            label={t('Date of obtaining')}
            onChange={(e) => certificateDateHandler(e, CertificateKeys.OBTAINING_DATE)}
            outlined
            renderInput={(props) => <DatePickerInput {...props} />}
            value={certificateData[CertificateKeys.OBTAINING_DATE] || null}
          />
        </Box>
        <Box mb={3}>
          <DatePicker
            gaLabel="Certificate expiration date"
            label={t('Expiration date')}
            onChange={(e) => certificateDateHandler(e, CertificateKeys.EXPIRATION_DATE)}
            outlined
            renderInput={(props) => <DatePickerInput {...props} />}
            value={certificateData[CertificateKeys.EXPIRATION_DATE] || null}
          />
        </Box>
        <Box mb={3}>
          <TextField
            error={inputsErrorsMessages[CertificateKeys.LINK]}
            fullWidth
            gaLabel="Certificate link"
            helperText={inputsErrorsMessages[CertificateKeys.LINK]}
            InputLabelProps={{ id: InputsIds[CertificateKeys.LINK] }}
            inputProps={{
              'aria-labelledby': InputsIds[CertificateKeys.LINK],
            }}
            label={t('Link')}
            maxLength={InputsValidationRules.MAX_LINK_INPUT_LENGTH}
            name={CertificateKeys.LINK}
            onChange={certificateChangeHandler}
            outlined
            value={certificateData[CertificateKeys.LINK]}
          />
        </Box>
        <Box mb={3}>
          <Box mb={1}>
            <Typography component="h3" variant="subtitle2">
              {t('Attachments')}
            </Typography>
          </Box>
          <FileUpload
            allowedExtensions={['jpg', 'jpeg', 'png', 'pdf']}
            focusableRef={submitButtonRef}
            maxAttachmentsAllowed={3}
            maxFileSize={5 * byteToMegaByteCoeff}
            onFileAdd={(file) => {
              setFilesToUpload((state) => [...state, file]);
            }}
            onFileRemove={(file) => {
              if (file.id) {
                setRemovedAttachments((state) => [
                  ...state,
                  certificate?.[CertificateKeys.ATTACHMENTS].find((el) => el.id === file.id),
                ]);
              }
              setFilesToUpload((state) => [...state.filter((x) => x !== file)]);
            }}
            uploadedFiles={uploadedFiles}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <PopupActionsButtons
          primaryButtonGaLabel={certificate ? 'Save changes' : 'Add'}
          primaryButtonHandler={() => {
            if (isValid()) {
              onSave(
                transformFileUploadData({
                  ...certificateData,
                  [CertificateKeys.LINK]:
                    certificateData[CertificateKeys.LINK] &&
                    formatUrl(certificateData[CertificateKeys.LINK]),
                  attachments: filesToUpload,
                  removedAttachments,
                }),
              );
              onClose();
            }
          }}
          primaryButtonText={certificate ? t('Save changes') : t('Add')}
          secondaryButtonGaLabel="Cancel"
          secondaryButtonHandler={onClose}
          secondaryButtonText={t('Cancel')}
        />
      </DialogActions>
    </Dialog>
  );
};

PortfolioAddCertificateDialog.propTypes = {
  certificate: PropTypes.instanceOf(Object),
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

PortfolioAddCertificateDialog.defaultProps = {
  certificate: null,
};

export default PortfolioAddCertificateDialog;
