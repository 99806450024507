import React from 'react';
import { Box, Grid } from '@mui/material';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { Card, Switch, Typography } from '../../../../../../../atoms';
import { useAccordionToggleState } from '../../../../../../../../hooks';
import ApproveModerationToggleButton from '../../../approve-moderation-toggle-button/ApproveModerationToggleButton';
import { ReactComponent as OpenIcon } from '../../../../../../../../resources/icons/plus_open_sign.svg';
import { ReactComponent as CloseIcon } from '../../../../../../../../resources/icons/minus_close_sign.svg';
import TranslateModerationButton from '../../../translate-moderation-button/TranslateModerationButton';

const ROOT_INTEREST_FONT_VARIANT = 'subtitle2';
const NESTED_INTEREST_FONT_VARIANT = 'body2';

const InterestModerationNode = ({
  interest,
  isShowHideGranted,
  isEditApproveGranted,
  level,
  onVisibilitySwitch,
  onApprove,
  onExpanded,
  onTranslationChange,
  expandedInterestKey,
  parentInterests,
  baseHeadingLevel,
}) => {
  const [openedSubInterest, toggleOpenedSubInterest] = useAccordionToggleState();

  const headerVariant = level === 0 ? ROOT_INTEREST_FONT_VARIANT : NESTED_INTEREST_FONT_VARIANT;

  const interestModerationVisibilitySwitch = (
    <Switch
      ariaLabel={`${interest.isVisible ? 'Hide' : 'Show'} ${interest.keys.name.EN_US}`}
      checkedLabel="On (short translation)"
      className="interests-moderation__switch"
      onChange={(newValue) => onVisibilitySwitch(interest, newValue, parentInterests)}
      onClick={(e) => e.stopPropagation()}
      uncheckedLabel="Off (short translation)"
      value={interest.isVisible}
    />
  );

  const isExpanded = interest.entityKey === expandedInterestKey;

  const interestRow = (
    <Grid
      className={`interest-row interest-level-${level}`}
      container
      justifyContent="space-between"
      role="row"
    >
      <Grid className="with-offset interest-cell" item role="cell" xs={4}>
        {!!interest.childItems && (
          <button
            aria-expanded={isExpanded}
            aria-label={interest.keys.name.EN_US}
            className="expansion-button"
            onClick={() => onExpanded(interest.entityKey)}
            type="button"
          >
            {isExpanded ? (
              <CloseIcon className="collapse-icon" />
            ) : (
              <OpenIcon className="expand-icon" />
            )}
          </button>
        )}
        <Typography component={`h${baseHeadingLevel + level}`} variant={headerVariant}>
          {interest.keys.name.EN_US}
        </Typography>
      </Grid>
      <Grid className="with-offset interest-cell" item role="cell" xs={4}>
        <Typography component="p" variant={headerVariant}>
          {interest.keys.name.ES_US_updated || interest.keys.name.ES_US}
        </Typography>
      </Grid>
      {isEditApproveGranted && (
        <>
          <Grid className="interest-cell center" item role="cell" xs={1}>
            <ApproveModerationToggleButton entity={interest} onChange={onApprove} />
          </Grid>
          <Grid className="interest-cell center" item role="cell" xs={1}>
            <TranslateModerationButton
              entity={interest}
              onTranslationChange={onTranslationChange}
            />
          </Grid>
        </>
      )}
      {isShowHideGranted && (
        <Grid className="interest-cell" item role="cell" xs={1}>
          {interestModerationVisibilitySwitch}
        </Grid>
      )}
    </Grid>
  );

  if (interest.childItems) {
    return (
      <Card
        className={classnames('interests-moderation__card', { 'inner-card': level })}
        elevation={0}
        mainContent={
          <Box>
            {interestRow}
            {isExpanded && (
              <Box>
                {interest.childItems.map((childInterest) => (
                  <InterestModerationNode
                    key={childInterest.entityKey}
                    baseHeadingLevel={baseHeadingLevel}
                    expandedInterestKey={openedSubInterest}
                    interest={childInterest}
                    isEditApproveGranted={isEditApproveGranted}
                    isShowHideGranted={isShowHideGranted}
                    level={level + 1}
                    onApprove={onApprove}
                    onExpanded={toggleOpenedSubInterest}
                    onTranslationChange={onTranslationChange}
                    onVisibilitySwitch={onVisibilitySwitch}
                    parentInterests={[...(parentInterests || []), interest]}
                  />
                ))}
              </Box>
            )}
          </Box>
        }
      />
    );
  }
  if (level === 0) {
    return <Card className="interests-moderation__card" elevation={0} mainContent={interestRow} />;
  }
  return interestRow;
};

InterestModerationNode.propTypes = {
  interest: PropTypes.instanceOf(Object).isRequired,
  isShowHideGranted: PropTypes.bool,
  isEditApproveGranted: PropTypes.bool,
  level: PropTypes.number,
  onVisibilitySwitch: PropTypes.func.isRequired,
  onApprove: PropTypes.func.isRequired,
  onExpanded: PropTypes.func.isRequired,
  onTranslationChange: PropTypes.func.isRequired,
  expandedInterestKey: PropTypes.string,
  parentInterests: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  baseHeadingLevel: PropTypes.number,
};

InterestModerationNode.defaultProps = {
  level: 0,
  expandedInterestKey: null,
  parentInterests: null,
  baseHeadingLevel: 2,
  isShowHideGranted: null,
  isEditApproveGranted: null,
};

export default InterestModerationNode;
