import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, DialogContent, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { DatePicker, Dialog, DialogTitle, Typography } from '../../../atoms';
import { PopupActionsButtons, STTTextField } from '../../../moleculas';
import { InputsValidationErrors, InputsValidationRules } from '../../../../constants/enums';

const INVALID_DATE_ERROR_TEXT = 'The date is invalid, please use the mm/dd/yyyy format';
const MIN_DATE_ERROR_TEXT = 'The date is sooner than the goal creation date';
const emptyGoalMilestone = {
  name: '',
  dueDate: null,
};

const ActionStepCreateDialog = ({ isOpen, onClose, onSave, milestone, milestoneIndex }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const [nameErrorMessage, setNameErrorMessage] = useState('');
  const [dateErrorMessage, setDateErrorMessage] = useState('');

  const [goalMilestone, setGoalMilestone] = useState(emptyGoalMilestone);

  const actionStepInputTitleId = 'action-step-name';

  useEffect(() => {
    if (milestone) {
      setGoalMilestone(milestone);
    }
  }, [milestone]);

  const handleGoalMilestoneChange = (e) => {
    const { name, value } = e.target;
    setNameErrorMessage('');
    setGoalMilestone((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleGoalMilestoneDateChange = (e) => {
    setGoalMilestone((prevState) => ({
      ...prevState,
      dueDate: e,
    }));
  };

  const handleGoalMilestoneDateError = (error) => {
    setDateErrorMessage(error);
  };
  const isValid = useCallback(() => {
    let nameValidationError = '';
    let dateValidationError = '';
    if (goalMilestone.name.trim().length < InputsValidationRules.MIN_INPUT_LENGTH) {
      nameValidationError = InputsValidationErrors(
        t,
        InputsValidationRules.MIN_INPUT_LENGTH,
      ).MIN_ERROR_TEXT;
    }
    if (goalMilestone.name.length > InputsValidationRules.MAX_TITLE_LENGTH) {
      nameValidationError = InputsValidationErrors(
        t,
        InputsValidationRules.MAX_TITLE_LENGTH,
      ).MAX_ERROR_TEXT;
    }

    if (!goalMilestone.dueDate) {
      setGoalMilestone((prevState) => ({
        ...prevState,
        dueDate: '',
      }));
      dateValidationError = INVALID_DATE_ERROR_TEXT;
    }
    setNameErrorMessage(nameValidationError);

    return !nameValidationError && !dateValidationError && !dateErrorMessage;
  }, [dateErrorMessage, goalMilestone.dueDate, goalMilestone.name, t]);

  const onMilestoneSaveHandler = () => {
    if (isValid()) {
      onSave(goalMilestone, milestoneIndex);
      setGoalMilestone(emptyGoalMilestone);
    }
  };

  const onDialogCloseHandler = () => {
    setNameErrorMessage('');
    onClose();
    setGoalMilestone(emptyGoalMilestone);
  };

  return (
    <Dialog className="ayo-action-step-create-dialog" onClose={onDialogCloseHandler} open={isOpen}>
      <DialogTitle disableTypography>
        <Box pb={3}>
          <Typography component="h2" variant="h2">
            {Number.isInteger(milestoneIndex)
              ? t('Edit an action step')
              : t('Create an action step')}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box pb={isWidthUpSm ? 3 : 2}>
          <STTTextField
            error={nameErrorMessage !== ''}
            fullWidth
            gaLabel={milestoneIndex ? 'Edit action step name' : 'Add action step name'}
            helperText={nameErrorMessage}
            InputLabelProps={{ id: actionStepInputTitleId }}
            inputProps={{
              'aria-labelledby': actionStepInputTitleId,
              autoComplete: 'off',
            }}
            label={`${t('Title')}`}
            maxLength={InputsValidationRules.MAX_TITLE_LENGTH}
            name="name"
            onChange={handleGoalMilestoneChange}
            outlined
            required
            value={goalMilestone.name}
          />
        </Box>
        <Box pb={dateErrorMessage ? 7 : isWidthUpSm ? 5 : 3}>
          <DatePicker
            disabledDayText={t('The deadline must be later than the goal creation date')}
            disablePast
            gaLabel={
              milestoneIndex ? 'Edit goal action step deadLine' : 'Set goal action step deadLine'
            }
            invalidDateMessage={t(INVALID_DATE_ERROR_TEXT)}
            label={`${t('Deadline in mm/dd/yyyy')}`}
            minDateMessage={t(MIN_DATE_ERROR_TEXT)}
            onChange={handleGoalMilestoneDateChange}
            onError={handleGoalMilestoneDateError}
            outlined
            value={goalMilestone.dueDate}
          />
        </Box>
        <Box>
          <PopupActionsButtons
            primaryButtonGaLabel={
              milestoneIndex ? 'Goal action step save changes' : 'Goal action step save'
            }
            primaryButtonHandler={onMilestoneSaveHandler}
            primaryButtonText={t('Save')}
            secondaryButtonGaLabel={
              milestoneIndex ? 'Cancel goal action step editing' : 'Goal action step dialog close'
            }
            secondaryButtonHandler={onDialogCloseHandler}
            secondaryButtonText={t('Cancel')}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

ActionStepCreateDialog.propTypes = {
  milestone: PropTypes.shape({
    name: PropTypes.string,
    dueDate: PropTypes.instanceOf(Date),
  }),
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  milestoneIndex: PropTypes.number,
};

ActionStepCreateDialog.defaultProps = {
  milestone: null,
  milestoneIndex: null,
};

export default ActionStepCreateDialog;
