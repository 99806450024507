import { ReactComponent as WorriedEmoji } from '../resources/images/moods/worried.svg';
import { ReactComponent as HappyEmoji } from '../resources/images/moods/happy.svg';
import { ReactComponent as AngryEmoji } from '../resources/images/moods/angry.svg';
import { ReactComponent as SadEmoji } from '../resources/images/moods/sad.svg';
import { ReactComponent as FrustratedEmoji } from '../resources/images/moods/frustrated.svg';
import { ReactComponent as SillyEmoji } from '../resources/images/moods/silly.svg';
import { ReactComponent as CalmEmoji } from '../resources/images/moods/calm.svg';
import { ReactComponent as TiredEmoji } from '../resources/images/moods/tired.svg';
import { ReactComponent as SelHelpSomeoneElse } from '../resources/images/sel_help_someone_else.svg';
import { ReactComponent as Sel478Breathing } from '../resources/images/sel_4_7_8_breathing.svg';
import { ReactComponent as SelAngerIceberg } from '../resources/images/sel_anger_iceberg.svg';
import { ReactComponent as SelAnts } from '../resources/images/sel_ants.svg';
import { ReactComponent as SelListeningToMusic } from '../resources/images/sel_listening_to_music.svg';
import { ReactComponent as SelTalkToFriendsOrFamily } from '../resources/images/sel_talk_to_friends_or_family.svg';
import { ReactComponent as SelThinkingCap } from '../resources/images/sel_thinking_cap.svg';
import { ReactComponent as SelBoxBreathing } from '../resources/images/sel_box_breathing.svg';
import { ReactComponent as SelBubbleBlowing } from '../resources/images/sel_bubble_blowing.svg';
import { ReactComponent as SelCanIControlIt } from '../resources/images/sel_can_i_control_it.svg';
import { ReactComponent as SelDragonBreathing } from '../resources/images/sel_dragon_breathing.svg';
import { ReactComponent as SelExercise } from '../resources/images/sel_exercise.svg';
import { ReactComponent as SelFindYourFocusMeditation } from '../resources/images/sel_find_your_focus_meditation.svg';
import { ReactComponent as SelGratitudeList } from '../resources/images/sel_gratitude_list.svg';
import { ReactComponent as SelGratitudeMeditation } from '../resources/images/sel_gratitude_meditation.svg';
import { ReactComponent as SelGroundingTechnique } from '../resources/images/sel_grounding_technique.svg';
import { ReactComponent as SelMeltOrFreeze } from '../resources/images/sel_melt_or_freeze.svg';
import { ReactComponent as SelMindfulBreathing } from '../resources/images/sel_mindful_breathing.svg';
import { ReactComponent as SelMindfulColoring } from '../resources/images/sel_mindful_coloring.svg';
import { ReactComponent as SelPositiveSelfTalk } from '../resources/images/sel_positive_self_talk.svg';
import { ReactComponent as SelRainbowBreathingPractice } from '../resources/images/sel_rainbow_breathing_practice.svg';
import { ReactComponent as SelStopPractice } from '../resources/images/sel_stop_practice.svg';
import { ReactComponent as SelTenseAndRelease } from '../resources/images/sel_tense_and_release.svg';
import { ReactComponent as SelThoughtBubbles } from '../resources/images/sel_thought_bubbles.svg';
import { ReactComponent as SelWritingJournal } from '../resources/images/sel_writing_journal.svg';
import { ReactComponent as SelYoga } from '../resources/images/sel_yoga.svg';

export const SupportTypesMap = {
  GET_SUPPORT: 'Get support',
};

export const Moods = {
  HAPPY: 'HAPPY',
  ANGRY: 'ANGRY',
  SAD: 'SAD',
  WORRIED: 'WORRIED',
  FRUSTRATED: 'FRUSTRATED',
  SILLY: 'SILLY',
  CALM: 'CALM',
  TIRED: 'TIRED',
};

export const MoodActivitiesTitles = {
  GRATITUDE_LIST: 'gratitude_list_b7722e7d00310826f1238e6f73b49c82',
  GRATITUDE_MEDITATION: 'gratitude_meditation_470e6123a1b331b48085c4e0a3d222aa',
  EXERCISE: 'exercise_97e96b9b630acd157725fa00eff11a80',
  MINDFUL_BREATHING: 'mindful_breathing_29cc158e35906d54e0af1f0d2d93ab51',
  DRAGON_BREATHING: 'dragon_breathing_d371faceb520442bb9a026af32a60875',
  ANGER_ICEBERG: 'anger_iceberg_4c71c657f0d9c66f7e3db83373780660',
  LISTENING_TO_MUSIC: 'listening_to_music_243beeacba259a0f287b7fad8699d2b4',
  BUBBLE_BLOWING: 'bubble_blowing_18820d057579499dee38c7d005a3c60e',
  '4-7-8_BREATHING': '4-7-8_breathing_1bdd89318bbdb1e92791ed05b22962a5',
  MELT_OR_FREEZE_ACTIVITY: 'melt_or_freeze_activity_9aedb192090cbf27173c5080b53734d0',
  POSITIVE_SELF_TALK: 'positive_self_talk_8697a4bf1ebacd4d001b6e5b3d30573d',
  THINKING_CAP: 'thinking_cap_1a4320a55a39d3c5e9b7e8c8aa3b0571',
  BOX_BREATHING: 'box_breathing_48646111ba2cb1c0ba823736fe560e78',
  STOP_PRACTICE: 'stop_practice_e1b4247606b56b99717af07e156ca612',
  MINDFUL_COLORING: 'mindful_coloring_b6d0f3cde67542dd9392a74f3f61bf59',
  CAN_I_CONTROL_IT: 'can_i_control_it_0a5860064f1ba2a575ded10722263c47',
  ANTS: 'ants_d8fbf59631148d06d6191fe742ab5232',
  GROUNDING_TECHNIQUE: 'grounding_technique_39ca47113564422814d2e0a0a3344ea1',
  YOGA: 'yoga_807659cd883fc0a63f6ce615893b3558',
  TALK_TO_FRIENDS_OR_FAMILY: 'talk_to_friends_or_family_77eb841167c2692f7b88d92a8f1a10ab',
  RAINBOW_BREATHING_PRACTICE: 'rainbow_breathing_practice_cd7ce567de038ce434219c35cae6b4b4',
  TENSE_AND_RELEASE: 'tense_and_release_7b1ca4785d640d7074cd75d07662b187',
  THOUGHT_BUBBLES: 'thought_bubbles_ef2453c38507bd1a07dabe3ec7d30a36',
  WRITING_JOURNAL: 'writing_journal_c11db2140ebb878ab35365a3e15a2d52',
  HELP_SOMEONE_ELSE: 'help_someone_else_89437dcc8ff30f3f1ea31f0469319298',
  FIND_FOCUS: 'find_focus_801c3a46a601b6193166722f333c7d9c',
};

export const MoodNames = {
  [Moods.HAPPY]: 'Happy',
  [Moods.ANGRY]: 'Angry',
  [Moods.SAD]: 'Sad',
  [Moods.WORRIED]: 'Worried',
  [Moods.FRUSTRATED]: 'Frustrated',
  [Moods.SILLY]: 'Silly',
  [Moods.CALM]: 'Calm',
  [Moods.TIRED]: 'Tired',
};

export const MoodValues = {
  [Moods.HAPPY]: 7,
  [Moods.ANGRY]: 0,
  [Moods.SAD]: 1,
  [Moods.WORRIED]: 3,
  [Moods.FRUSTRATED]: 2,
  [Moods.SILLY]: 6,
  [Moods.CALM]: 5,
  [Moods.TIRED]: 4,
};

export const MoodThresholds = {
  NEGATIVE: 2,
  POSITIVE: 5,
};

export const MoodColors = {
  NEGATIVE: '#C1292E',
  NEUTRAL: '#FFC107',
  POSITIVE: '#4CB963',
  PENDING: '#8E8A95',
};

export const MOOD_DIDNT_SHARE = "Didn't share";

export const checkinFiltersValuesMap = {
  LAST_CHECK_IN: 'LAST_CHECK_IN',
  TODAY: 'TODAY',
  LAST_WEEK: 'LAST_WEEK',
  LAST_MONTH: 'LAST_MONTH',
  LAST_YEAR: 'LAST_YEAR',
  ALL: 'ALL',
};

export const MoodsAndSupportIllustrations = {
  [Moods.HAPPY]: {
    illustration: HappyEmoji,
    alt: 'Happy. Emoji of a green planet with a face with a big smile.',
  },
  [Moods.ANGRY]: {
    illustration: AngryEmoji,
    alt: 'Angry. Emoji of a red planet with an infuriated face.',
  },
  [Moods.SAD]: {
    illustration: SadEmoji,
    alt: 'Sad. Emoji of a light purple planet with a sorrowful face.',
  },
  [Moods.WORRIED]: {
    illustration: WorriedEmoji,
    alt: 'Worried. Emoji of a yellow planet with a face with concerned expression.',
  },
  [Moods.FRUSTRATED]: {
    illustration: FrustratedEmoji,
    alt: 'Frustrated. Emoji of an orange planet with an upset face.',
  },
  [Moods.SILLY]: {
    illustration: SillyEmoji,
    alt: 'Silly. Emoji of a dark purple planet with a face that is winking and with sticked out tongue.',
  },
  [Moods.CALM]: {
    illustration: CalmEmoji,
    alt: 'Calm. Emoji of a green planet with a face with a serene expression.',
  },
  [Moods.TIRED]: {
    illustration: TiredEmoji,
    alt: 'Tired. Emoji of a light blue planet with a face with exhausted expression.',
  },
  [SupportTypesMap.GET_SUPPORT]: {
    illustration: TiredEmoji,
    alt: 'Get support. Emoji of a person stretching their arms for a hug.',
  },
};

export const MoodsActivitiesIllustrationsByTitle = {
  [MoodActivitiesTitles.GRATITUDE_LIST]: {
    image: SelGratitudeList,
    text: 'A note with some written text and a pencil',
  },
  [MoodActivitiesTitles.GRATITUDE_MEDITATION]: {
    image: SelGratitudeMeditation,
    text: 'A girl sitting in the lotus position meditating',
  },
  [MoodActivitiesTitles.EXERCISE]: {
    image: SelExercise,
    text: 'A footbal, a skate, a bottle of water and a dumbbell',
  },
  [MoodActivitiesTitles.MINDFUL_BREATHING]: {
    image: SelMindfulBreathing,
    text: 'A person with a hair made of sky',
  },
  [MoodActivitiesTitles.DRAGON_BREATHING]: {
    image: SelDragonBreathing,
    text: 'A dragon exhaling flame',
  },
  [MoodActivitiesTitles.ANGER_ICEBERG]: {
    image: SelAngerIceberg,
    text: 'An iceberg',
  },
  [MoodActivitiesTitles.LISTENING_TO_MUSIC]: {
    image: SelListeningToMusic,
    text: 'Headphones and a music player',
  },
  [MoodActivitiesTitles.BUBBLE_BLOWING]: {
    image: SelBubbleBlowing,
    text: 'Soap bubbles',
  },
  [MoodActivitiesTitles['4-7-8_BREATHING']]: {
    image: Sel478Breathing,
    text: 'Numbers 4, 7, 8 and a pair of lungs',
  },
  [MoodActivitiesTitles.MELT_OR_FREEZE_ACTIVITY]: {
    image: SelMeltOrFreeze,
    text: 'Melting ice cubes',
  },
  [MoodActivitiesTitles.POSITIVE_SELF_TALK]: {
    image: SelPositiveSelfTalk,
    text: 'A girl watching herself in the mirror',
  },
  [MoodActivitiesTitles.THINKING_CAP]: {
    image: SelThinkingCap,
    text: 'A girl touching her ears',
  },
  [MoodActivitiesTitles.BOX_BREATHING]: {
    image: SelBoxBreathing,
    text: 'A sequence of breathing',
  },
  [MoodActivitiesTitles.STOP_PRACTICE]: {
    image: SelStopPractice,
    text: 'A stop sign',
  },
  [MoodActivitiesTitles.MINDFUL_COLORING]: {
    image: SelMindfulColoring,
    text: 'A mandala',
  },
  [MoodActivitiesTitles.CAN_I_CONTROL_IT]: {
    image: SelCanIControlIt,
    text: 'A person sitting on a coach thinking',
  },
  [MoodActivitiesTitles.ANTS]: {
    image: SelAnts,
    text: 'Some ants',
  },
  [MoodActivitiesTitles.GROUNDING_TECHNIQUE]: {
    image: SelGroundingTechnique,
    text: 'A representation of 5 senses of the body',
  },
  [MoodActivitiesTitles.YOGA]: {
    image: SelYoga,
    text: 'A man doing yoga',
  },
  [MoodActivitiesTitles.TALK_TO_FRIENDS_OR_FAMILY]: {
    image: SelTalkToFriendsOrFamily,
    text: 'A kid sitting on the sofa talking to an adult',
  },
  [MoodActivitiesTitles.RAINBOW_BREATHING_PRACTICE]: {
    image: SelRainbowBreathingPractice,
    text: 'A rainbow',
  },
  [MoodActivitiesTitles.TENSE_AND_RELEASE]: {
    image: SelTenseAndRelease,
    text: 'A fist and a palm of the hand',
  },
  [MoodActivitiesTitles.THOUGHT_BUBBLES]: {
    image: SelThoughtBubbles,
    text: 'Exploding balloons',
  },
  [MoodActivitiesTitles.WRITING_JOURNAL]: {
    image: SelWritingJournal,
    text: 'A journal and a pen',
  },
  [MoodActivitiesTitles.HELP_SOMEONE_ELSE]: {
    image: SelHelpSomeoneElse,
    text: 'A pair of hands holding a heart',
  },
  [MoodActivitiesTitles.FIND_FOCUS]: {
    image: SelFindYourFocusMeditation,
    text: 'A lotus flower reflected on the water',
  },
};

export const MoodFollowUpsIllustrations = [
  {
    alt: 'A bed',
    keys: ['Did you sleep well last night?', '¿Dormiste bien anoche?'],
    imageUrl: '/media/follow-up/sleep_ico.png',
  },
  {
    alt: 'A lunch on a plate',
    keys: ['Have you eaten breakfast or lunch today?', '¿Has desayunado o almorzado hoy?'],
    imageUrl: '/media/follow-up/eat_ico.png',
  },
  {
    alt: 'A cup of hot drink and a thermometer.',
    keys: ['Are you hurt or sick?', '¿Te duele algo o estás enfermo/a?'],
    imageUrl: '/media/follow-up/hurt_sick_ico.png',
  },
  {
    alt: 'Some non-printable characters',
    keys: [
      'Is someone at school being unkind?',
      '¿Alguien en la escuela no se porta bien contigo?',
    ],
    imageUrl: '/media/follow-up/someone_unkind_ico.png',
  },
  {
    alt: 'A house with a heart',
    keys: ['Is everything okay at home?', '¿Está todo bien en casa?'],
    imageUrl: '/media/follow-up/okay_at_home_ico.png',
  },
  {
    alt: 'A child talking to an adult',
    keys: ['Do you want to talk to an adult?', '¿Quieres hablar con un adulto?'],
    imageUrl: '/media/follow-up/adult_talk_ico.png',
  },
];

export const MoodRecommendationsPageConfig = {
  [Moods.HAPPY]: {
    description:
      'Keep it up with a quick activity, and share your happiness with people around you',
    title: 'You are in the Green Zone',
  },
  [Moods.ANGRY]: {
    description:
      'Here are some ways to help you get back to the Green Zone, take a moment and check them out',
    title: 'We understand you are angry',
  },
  [Moods.SAD]: {
    description:
      'These tips and tricks can help you have a fresh look at the things that upset you',
    title: 'After rain comes the rainbow',
  },
  [Moods.WORRIED]: {
    description: 'Have a look at what AYO has prepared for you',
    title: 'We all worry sometimes but we can learn how to overcome it',
  },
  [Moods.FRUSTRATED]: {
    description: 'Have a look at some tips and tricks that could help you deal with frustration',
    title: 'Things are not always as we want them to be',
  },
  [Moods.SILLY]: {
    description:
      'Enjoy the moment! Check out some tips to get you focused and help keep you in the Green Zone',
    title: 'In a silly, goofy mood',
  },
  [Moods.CALM]: {
    description:
      'Enjoy your mood and dedicate it to things that need patience, here are some suggestions for you',
    title: 'Keep calm and dream big',
  },
  [Moods.TIRED]: {
    description:
      'You can learn how to get back to the Green Zone by exploring some tips we have prepared for you',
    title: 'Take some rest, relax and try doing something else',
  },
};

export const ActivityLogContentTypesMap = {
  MOOD_INTERVENTION_CONTENT_TYPE: 'MOOD_INTERVENTION',
  MOOD_DETAILS_COMMENT_CONTENT_TYPE: 'MOOD_DETAILS_COMMENT',
};
