import React, { useEffect, useRef, useState } from 'react';
import { Avatar, Skeleton } from '@mui/material';
import AvatarEditor from 'react-avatar-editor';
import PropTypes from 'prop-types';

import { apiRoute, attachmentsRoute } from '../../../constants/routes';
import { attachmentType, avatarTransformsType } from '../../../constants/propTypes';
import defaultProfileAvatar from '../../../resources/images/default_profile_avatar.svg';

const DisplayAvatarEditorImage = ({
  alt,
  avatar,
  avatarPlaceholder,
  avatarSize,
  avatarTransforms,
  token,
}) => {
  const editorRef = useRef();

  const [finalAvatarBlob, setFinalAvatarBlob] = useState(null);

  useEffect(() => {
    setFinalAvatarBlob(null);
  }, [avatar, avatarTransforms]);

  return finalAvatarBlob ? (
    <Avatar src={finalAvatarBlob} sx={{ width: avatarSize, height: avatarSize }} />
  ) : (
    <>
      {!avatar && (
        <Avatar alt={alt} src={avatarPlaceholder} sx={{ width: avatarSize, height: avatarSize }} />
      )}
      {!!avatar && (
        <Skeleton
          className="ayo-skeleton-mask"
          height={avatarSize}
          variant="circular"
          width={avatarSize}
        />
      )}
      {!!avatar && (
        <AvatarEditor
          ref={editorRef}
          borderRadius={250}
          height={250}
          image={`${apiRoute}${attachmentsRoute}/${avatar.id}/owners/${avatar.ownerId}${
            token ? `/shared/${token}` : ''
          }`}
          onImageChange={() => {
            if (finalAvatarBlob) {
              setFinalAvatarBlob(editorRef.current.getImage().toDataURL());
            }
          }}
          onImageReady={() => {
            setFinalAvatarBlob(editorRef.current.getImage().toDataURL());
          }}
          position={avatarTransforms}
          scale={avatarTransforms.zoom}
          style={{ display: 'none' }}
          width={250}
        />
      )}
    </>
  );
};

DisplayAvatarEditorImage.propTypes = {
  alt: PropTypes.string,
  avatar: PropTypes.shape(attachmentType),
  avatarTransforms: PropTypes.shape(avatarTransformsType),
  avatarPlaceholder: PropTypes.node,
  avatarSize: PropTypes.number.isRequired,
  token: PropTypes.string,
};

DisplayAvatarEditorImage.defaultProps = {
  alt: null,
  avatar: null,
  avatarPlaceholder: defaultProfileAvatar,
  avatarTransforms: null,
  token: null,
};

export default DisplayAvatarEditorImage;
