import { ReactComponent as AdultImage } from '../resources/images/portfolio/interests/adult.svg';
import { ReactComponent as ArtsAndEntertainmentImage } from '../resources/images/portfolio/interests/arts-and-entertainment.svg';
import { ReactComponent as AutosAndVehiclesImage } from '../resources/images/portfolio/interests/autos-and-vehicles.svg';
import { ReactComponent as BeautyAndFitnessImage } from '../resources/images/portfolio/interests/beauty-and-fitness.svg';
import { ReactComponent as BooksAndLiteratureImage } from '../resources/images/portfolio/interests/books-and-literature.svg';
import { ReactComponent as BusinessAndIndustrialImage } from '../resources/images/portfolio/interests/business-and-industrial.svg';
import { ReactComponent as ComputersAndElectronicsImage } from '../resources/images/portfolio/interests/computers-and-electronics.svg';
import { ReactComponent as FinanceImage } from '../resources/images/portfolio/interests/finance.svg';
import { ReactComponent as FoodAndDrinkImage } from '../resources/images/portfolio/interests/food-and-drink.svg';
import { ReactComponent as GamesImage } from '../resources/images/portfolio/interests/games.svg';
import { ReactComponent as HealthImage } from '../resources/images/portfolio/interests/health.svg';
import { ReactComponent as HobbiesAndLeisureImage } from '../resources/images/portfolio/interests/hobbies-and-leisure.svg';
import { ReactComponent as HomeAndGardenImage } from '../resources/images/portfolio/interests/home-and-garden.svg';
import { ReactComponent as InternetAndTelecomImage } from '../resources/images/portfolio/interests/internet-and-telecom.svg';
import { ReactComponent as JobsAndEducationImage } from '../resources/images/portfolio/interests/jobs-and-education.svg';
import { ReactComponent as LawAndGovernmentImage } from '../resources/images/portfolio/interests/law-and-government.svg';
import { ReactComponent as NewsImage } from '../resources/images/portfolio/interests/news.svg';
import { ReactComponent as OnlineCommunitiesImage } from '../resources/images/portfolio/interests/online-communities.svg';
import { ReactComponent as PeopleAndSocietyImage } from '../resources/images/portfolio/interests/people-and-society.svg';
import { ReactComponent as PetsAndAnimalsImage } from '../resources/images/portfolio/interests/pets-and-animals.svg';
import { ReactComponent as RealEstateImage } from '../resources/images/portfolio/interests/real-estate.svg';
import { ReactComponent as ReferenceImage } from '../resources/images/portfolio/interests/reference.svg';
import { ReactComponent as ScienceImage } from '../resources/images/portfolio/interests/science.svg';
import { ReactComponent as SensitiveSubjectsImage } from '../resources/images/portfolio/interests/sensitive-subjects.svg';
import { ReactComponent as ShoppingImage } from '../resources/images/portfolio/interests/shopping.svg';
import { ReactComponent as SportsImage } from '../resources/images/portfolio/interests/sports.svg';
import { ReactComponent as TravelImage } from '../resources/images/portfolio/interests/travel.svg';

export const PortfolioInterestsIllustrations = {
  adult_2e85a22d0ffa063c211acd8f34952360: {
    alt: 'An adult person standing',
    image: AdultImage,
  },
  arts_entertainment_b8aaa40e71f56a5acf89bbc9f2fa9a31: {
    alt: 'A sketch of the Mona Lisa painting',
    image: ArtsAndEntertainmentImage,
  },
  autos_vehicles_85d8b59dc19da58902a371e07fd032a5: {
    alt: 'A vintage Cadillac',
    image: AutosAndVehiclesImage,
  },
  beauty_fitness_578ae79094949cd58585cce482afe8cb: {
    alt: 'A person doing a yoga posture and a jumping rope',
    image: BeautyAndFitnessImage,
  },
  books_literature_49f796f863309b147aa64b314a5734b0: {
    alt: 'A pile of books',
    image: BooksAndLiteratureImage,
  },
  business_industrial_6c50b41e31a34b70e296967923a7ec41: {
    alt: 'A safety helmet',
    image: BusinessAndIndustrialImage,
  },
  computers_electronics_dde12543fd26e60820153667d60145be: {
    alt: 'An open laptop and a calculator',
    image: ComputersAndElectronicsImage,
  },
  finance_57336afd1f4b40dfd9f5731e35302fe5: {
    alt: 'A stack of coins',
    image: FinanceImage,
  },
  food_drink_5f4233aa8721d1bf592b36c8015387bb: {
    alt: 'An apple',
    image: FoodAndDrinkImage,
  },
  games_9cfa7aefcc61936b70aaec6729329eda: {
    alt: 'A video game joystick',
    image: GamesImage,
  },
  health_555bf8344ca0caf09b42f55e185526d8: {
    alt: 'An illustration of a heart',
    image: HealthImage,
  },
  hobbies_leisure_78f15cb1a386877ed3e381bfbd430c2b: {
    alt: 'An air balloon floating in a cloudy sky',
    image: HobbiesAndLeisureImage,
  },
  home_garden_f1b6f3e93a40e94b15df7a66bf504131: {
    alt: 'A house plant in a pot',
    image: HomeAndGardenImage,
  },
  internet_telecom_85e5b752d05311ec53879688be92a241: {
    alt: 'A paper plane flying around the globe',
    image: InternetAndTelecomImage,
  },
  jobs_education_5e1808f5fd786e1050b3c12c7770b6b1: {
    alt: 'A briefcase and a graduation hat',
    image: JobsAndEducationImage,
  },
  law_government_e24be6711a671adca175834d17bd1575: {
    alt: 'A speech stand with microphones',
    image: LawAndGovernmentImage,
  },
  news_508c75c8507a2ae5223dfd2faeb98122: {
    alt: 'A news anchor microphone',
    image: NewsImage,
  },
  online_communities_fc14339124e474789a9d96d51116576c: {
    alt: 'Three speech bubbles',
    image: OnlineCommunitiesImage,
  },
  people_society_b3b399dee4d6649ecb8332287e4e1d43: {
    alt: 'Silhouettes of a group of people',
    image: PeopleAndSocietyImage,
  },
  pets_animals_a6c2fe7db94a8c6acce86e31e96cc65d: {
    alt: 'A French Bulldog looking through his shoulder',
    image: PetsAndAnimalsImage,
  },
  real_estate_54d98854697bedf25869bec45f30369e: {
    alt: 'A skyline with skyscrapers',
    image: RealEstateImage,
  },
  reference_b8af13ea9c8fe890c9979a1fa8dbde22: {
    alt: 'A bookmark illustration',
    image: ReferenceImage,
  },
  science_fb5c7f9bb4b32ce2f3bff4662f1ab27b: {
    alt: 'An atom close up',
    image: ScienceImage,
  },
  sensitive_subjects_0fa975fca9b971e1361cf72b55d5548b: {
    alt: 'A hand and a feather levitating',
    image: SensitiveSubjectsImage,
  },
  shopping_b2fe440cb7a0b127f1a90ffea296313b: {
    alt: 'Two shopping bags',
    image: ShoppingImage,
  },
  sports_088495f30901580ddd5171531cd26649: {
    alt: 'An American football ball',
    image: SportsImage,
  },
  travel_69266c67e75c946ef9b4144b0554326d: {
    alt: 'A suitcase with wheels and a panama hat',
    image: TravelImage,
  },
};

export default { PortfolioInterestsIllustrations };
