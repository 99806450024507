import { useCallback } from 'react';

import useAxios, { StatusCodeMap } from '../HttpClient';
import {
  apiRoute,
  ayoAnalyticRoute,
  evidencesRoute,
  guardiansRoute,
  notificationsEmailUnsubscriptionRoute,
  portfolioRoute,
  profileRoute,
  settingsPageRoute,
  studentsRoute,
  suggestedActivitiesRoute,
  tourRoute,
  usersRoute,
} from '../../constants/routes';
import { converterBooleanString } from '../../utils/string-helpers/stringHelpers';

const userProfileRoute = `${apiRoute}${usersRoute}${profileRoute}`;
const tourStatusRoute = `${apiRoute}${usersRoute}${tourRoute}`;
const leadershipAttributesRoute = `${apiRoute}${studentsRoute}`;
const settingsRoute = `${apiRoute}${usersRoute}${settingsPageRoute}`;
const ayoGeneralAnalyticRoute = `${apiRoute}${ayoAnalyticRoute}`;

const useProfileService = () => {
  const { get, patch, post, put } = useAxios();
  const getProfile = useCallback(
    async (
      { skipAuthCheck, skipDefaultErrorHandlers } = { skipAuthCheck: false },
      schoolName,
      roleInSchool,
    ) => {
      const url = `${userProfileRoute}${schoolName ? `?schoolName=${schoolName}` : ''}${
        roleInSchool ? `&role=${roleInSchool}` : ''
      }`;
      const { data: profile } = await get({ enabled: true }, url, {
        skipAuthCheck,
        skipDefaultErrorHandlers,
      });
      return profile;
    },
    [get],
  );

  const getParentDependentProfiles = useCallback(
    async (activityCriterion) => {
      const { data } = await get(
        { enabled: true },
        `${apiRoute}${usersRoute}${guardiansRoute}${studentsRoute}/profiles${
          activityCriterion ? `?activityCriterion=${activityCriterion}` : ''
        }`,
      );
      return data;
    },
    [get],
  );

  const patchLanguage = useCallback(
    (value) => patch({ enabled: true }, `${userProfileRoute}/language`, { lang: value }),
    [patch],
  );

  const patchRole = useCallback(
    (value) => patch({ enabled: true }, `${userProfileRoute}/role`, { role: value }),
    [patch],
  );

  const getTourStatus = useCallback(async () => {
    const { data } = await get({ enabled: true }, tourStatusRoute, {
      skipDefaultErrorHandlers: [StatusCodeMap.NOT_FOUND, StatusCodeMap.SERVER_ERROR],
    });
    return data?.entity?.toursStatus;
  }, [get]);

  const postTourStatus = useCallback(
    (tourStatus) =>
      post(
        { enabled: false },
        tourStatusRoute,
        { toursStatus: JSON.stringify(tourStatus) },
        {
          skipDefaultErrorHandlers: [StatusCodeMap.NOT_FOUND, StatusCodeMap.SERVER_ERROR],
        },
      ),
    [post],
  );

  const getUserSettings = useCallback(async () => {
    const { data } = await get({ enabled: true }, settingsRoute);
    const { settings } = data.entity;
    const aggregationKeysMap = {};
    const groupedSettings = settings.reduce((acc, current) => {
      const settingAggregationKey = current.name.split('__')[0];
      acc[settingAggregationKey] = [
        ...(acc[settingAggregationKey] || []),
        {
          name: current.name,
          value: converterBooleanString(current.value ?? current.defaultValue, true),
        },
      ];
      if (!Object.prototype.hasOwnProperty.call(aggregationKeysMap, settingAggregationKey)) {
        aggregationKeysMap[settingAggregationKey] = current;
      }
      return acc;
    }, {});
    return groupedSettings;
  }, [get]);

  const updateUserSetting = useCallback(
    (postBody, withErrorHandlers) =>
      post({ enabled: false }, settingsRoute, postBody, {
        skipDefaultErrorHandlers: withErrorHandlers && [
          StatusCodeMap.NOT_FOUND,
          StatusCodeMap.SERVER_ERROR,
        ],
      }),
    [post],
  );

  const sendAyoAnalytic = useCallback(
    (analyticType, metadata) =>
      post({ enabled: false }, ayoGeneralAnalyticRoute, {
        analyticType,
        metadata,
      }),
    [post],
  );

  const createLeadershipAttributesData = useCallback(
    async (studentId, hideLoadMask) => {
      const data = await post(
        { enabled: !hideLoadMask },
        `${leadershipAttributesRoute}/${studentId}/leadershipAttributes`,
      );
      return data.data.entity;
    },
    [post],
  );

  const getLeadershipAttributes = useCallback(
    async (studentId, hideLoadMask) => {
      const data = await get(
        { enabled: !hideLoadMask },
        `${leadershipAttributesRoute}/${studentId}/leadershipAttributes`,
      );
      return {
        status: data.status,
        data: data.data.entity,
      };
    },
    [get],
  );

  const putMaturityLevelCompletion = useCallback(
    async (studentId, attributeId, groupId, body) => {
      const { data } = await put(
        { enabled: false },
        `${leadershipAttributesRoute}/${studentId}/leadershipAttributes/${attributeId}/groups/${groupId}`,
        body,
      );
      return [data.entity];
    },
    [put],
  );

  const getLeadershipAttributesGraphData = useCallback(
    async (studentId, hideLoadMask) => {
      const data = await get(
        { enabled: !hideLoadMask },
        `${leadershipAttributesRoute}/${studentId}/leadershipAttributes/maturityLevels`,
      );
      return data.data.entity;
    },
    [get],
  );

  const getEvidenceOfGrowth = useCallback(
    async (studentId, hideLoadMask) => {
      const data = await get(
        { enabled: !hideLoadMask },
        `${leadershipAttributesRoute}/${studentId}${evidencesRoute}`,
      );
      return data.data.entity;
    },
    [get],
  );

  const getSuggestedActivities = useCallback(
    async (studentId, hideLoadMask) => {
      const data = await get(
        { enabled: !hideLoadMask },
        `${apiRoute}${suggestedActivitiesRoute}/${studentId}`,
      );
      return data.data.entity;
    },
    [get],
  );

  const getSharedPortfolioData = useCallback(
    async (token) => {
      const data = await get(
        { enabled: true },
        `${apiRoute}${studentsRoute}${portfolioRoute}/shared/${token}`,
      );
      return data.data.entity;
    },
    [get],
  );

  const updateEmailNotification = useCallback(
    (subscriptionToken) =>
      post(
        { enabled: false },
        `${apiRoute}/${notificationsEmailUnsubscriptionRoute}?subscriptionToken=${subscriptionToken}`,
      ),
    [post],
  );

  return {
    createLeadershipAttributesData,
    getEvidenceOfGrowth,
    getLeadershipAttributes,
    getLeadershipAttributesGraphData,
    getParentDependentProfiles,
    getProfile,
    getSuggestedActivities,
    getSharedPortfolioData,
    getTourStatus,
    getUserSettings,
    patchLanguage,
    patchRole,
    postTourStatus,
    putMaturityLevelCompletion,
    sendAyoAnalytic,
    updateEmailNotification,
    updateUserSetting,
  };
};

export default useProfileService;
