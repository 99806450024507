import React, { useState, useMemo, useRef, useEffect, useCallback } from 'react';
import {
  Grid,
  Box,
  List,
  ListItem,
  ListItemIcon,
  Popper,
  ClickAwayListener,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import FocusTrap from 'focus-trap-react';
import PropTypes from 'prop-types';

import { Typography, Button, Card } from '../../atoms';
import { useSearchParams } from '../../../hooks';
import { StudentFiltersStrategy, transformSearchToObject } from '../../../utils';
import { FilterUpdateActions } from '../../../hooks/use-filter-update-state/useFilterUpdateState';
import DisabledControlWithTooltip from '../tooltip-wrappers/disabled-control-with-tooltip/DisabledControlWithTooltip';
import { HighlightsInitiatives } from '../../../constants/enums';
import { ReactComponent as FilterIcon } from '../../../resources/icons/filter_list.svg';
import { ReactComponent as ChevronDownIcon } from '../../../resources/icons/chevron_down.svg';

import FiltersChipsList from './components/FiltersChipsList/FiltersChipsList';

const FocusTrapWrapper = React.forwardRef(({ children }, ref) => (
  <FocusTrap ref={ref} focusTrapOptions={{ allowOutsideClick: true }}>
    {children}
  </FocusTrap>
));

FocusTrapWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

const FilterCategoriesFilters = {
  [HighlightsInitiatives.ENDORSEMENTS_TYPES]: HighlightsInitiatives.ENDORSEMENTS,
  [HighlightsInitiatives.ENDORSEMENTS_REASONS]: HighlightsInitiatives.ENDORSEMENTS,
};

const StudentFilters = ({
  stats,
  selectedInitiative,
  totalStudentsCount,
  onFiltersUpdate,
  filtersConfig,
  withChips,
  minimizeMobile,
  popperEventsEnabled,
}) => {
  const [openedFilter, setOpenedFilter] = useState(null);

  const [open, setOpen] = useState(false);

  const handleToggle = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, []);

  const anchorRef = useRef(null);

  const search = useSearchParams();

  const filters = useMemo(() => transformSearchToObject(search), [search]);

  const [menuFilters, setMenuFilters] = useState(filters);

  const history = useHistory();

  const theme = useTheme();

  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  useEffect(() => {
    setMenuFilters(filters);
    setOpenedFilter(FilterCategoriesFilters[filters.filterCategory] || filters.filterCategory);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const { t } = useTranslation();

  const applyFiltersHandler = useCallback(() => {
    if (
      menuFilters.filterCategory &&
      StudentFiltersStrategy[menuFilters.filterCategory].validateFilters(menuFilters, stats)
    ) {
      history.replace({
        search: new URLSearchParams(menuFilters).toString(),
      });
    } else {
      history.replace({ search: null });
    }
    onFiltersUpdate({ type: FilterUpdateActions.ADD_GENERIC });
    handleToggle();
  }, [handleToggle, history, menuFilters, onFiltersUpdate, stats]);

  const SelectedFilterBoxComponent = filtersConfig[selectedInitiative || openedFilter]?.FilterBox;
  const selectedFilterBox = SelectedFilterBoxComponent && (
    <Box
      className="filters-container"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Box mb={4}>
        <SelectedFilterBoxComponent
          menuFilters={menuFilters}
          setMenuFilters={setMenuFilters}
          stats={stats}
          totalStudentsCount={totalStudentsCount}
        />
      </Box>
      <Grid container direction={isWidthUpSm ? 'row' : 'column-reverse'} spacing={3} wrap="nowrap">
        <Grid item sm={6} xs={12}>
          <Button fullWidth gaLabel="Cancel students filters" onClick={handleToggle}>
            {t('Cancel')}
          </Button>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Button
            fullWidth
            gaLabel="Apply students filters"
            onClick={applyFiltersHandler}
            variant="primary"
          >
            {t('Apply filters')}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );

  const onItemSelection = useCallback(
    (item) => setOpenedFilter(item === openedFilter ? null : item),
    [openedFilter],
  );

  const hasFilters = useMemo(
    () =>
      Object.values(filtersConfig).every(
        (filterConfig) => !filterConfig.isDisabled(stats, totalStudentsCount),
      ),
    [filtersConfig, stats, totalStudentsCount],
  );

  const FilterContentWrapper = hasFilters ? FocusTrapWrapper : 'div';

  const isButtonMinimized = minimizeMobile && !isWidthUpSm;
  return (
    <Box className="students-filters" display="flex">
      <ClickAwayListener
        onClickAway={() => {
          if (open) {
            setOpen(false);
          }
        }}
      >
        <div>
          <Button
            ref={anchorRef}
            endIcon={!isButtonMinimized && <FilterIcon height="24" width="24" />}
            gaLabel="Filter by"
            isIconButton={isButtonMinimized}
            onClick={handleToggle}
          >
            {isButtonMinimized ? <FilterIcon height="24" width="24" /> : t('Filter by')}
          </Button>
          <Popper
            anchorEl={anchorRef.current}
            className="filters-popover"
            disablePortal
            modifiers={{
              flip: {
                enabled: false,
              },
              preventOverflow: {
                enabled: true,
                boundariesElement: document.querySelector('.ayo-page'),
              },
            }}
            onClose={handleToggle}
            open={open}
            placement="bottom-start"
            popperOptions={{ eventsEnabled: popperEventsEnabled }}
          >
            <Card
              mainContent={
                <FilterContentWrapper>
                  <Box display="flex" flexDirection="row">
                    {!selectedInitiative && (
                      <List>
                        {Object.entries(filtersConfig).map(([initiative, config]) => {
                          const isDisabled =
                            config.isDisabled?.(stats, totalStudentsCount) ?? false;
                          return (
                            <>
                              <Box margin={2}>
                                <DisabledControlWithTooltip
                                  showTooltip={isDisabled}
                                  title={t("Can't filter by all students")}
                                >
                                  <ListItem
                                    button
                                    className={classnames({
                                      active: openedFilter === initiative,
                                    })}
                                    disabled={isDisabled}
                                    disableRipple
                                    onClick={() => onItemSelection(initiative)}
                                  >
                                    <Typography component="p" variant="subtitle2">
                                      {t(config.name)}
                                    </Typography>
                                    <ListItemIcon>
                                      {isWidthUpSm ? (
                                        <ChevronDownIcon
                                          transform={`rotate(${
                                            openedFilter === initiative ? 90 : -90
                                          })`}
                                        />
                                      ) : (
                                        <ChevronDownIcon
                                          transform={`rotate(${
                                            openedFilter === initiative ? 180 : 0
                                          })`}
                                        />
                                      )}
                                    </ListItemIcon>
                                  </ListItem>
                                </DisabledControlWithTooltip>
                              </Box>
                              {openedFilter === initiative && !isWidthUpSm && selectedFilterBox}
                            </>
                          );
                        })}
                      </List>
                    )}
                    {((openedFilter && isWidthUpSm) || selectedInitiative) && selectedFilterBox}
                  </Box>
                </FilterContentWrapper>
              }
            />
          </Popper>
        </div>
      </ClickAwayListener>
      {withChips && <FiltersChipsList onFiltersUpdate={onFiltersUpdate} stats={stats} />}
    </Box>
  );
};

StudentFilters.propTypes = {
  stats: PropTypes.instanceOf(Object),
  selectedInitiative: PropTypes.string,
  totalStudentsCount: PropTypes.number,
  onFiltersUpdate: PropTypes.func.isRequired,
  filtersConfig: PropTypes.instanceOf(Object).isRequired,
  withChips: PropTypes.bool,
  minimizeMobile: PropTypes.bool,
  popperEventsEnabled: PropTypes.bool,
};

StudentFilters.defaultProps = {
  selectedInitiative: null,
  withChips: true,
  minimizeMobile: false,
  totalStudentsCount: null,
  stats: null,
  popperEventsEnabled: true,
};

export default StudentFilters;
