/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';

import { Typography } from '../../atoms';
import { personasBlock } from '../../../constants/propTypes';
import { PersonaDescription } from '../../moleculas';

const PersonasBlock = ({ config }) => {
  const { t } = useTranslation();

  const personaItems = [];

  config.personaItems.forEach((item) => {
    personaItems.push(
      <PersonaDescription
        avatarTxt={item.avatarTxt}
        img={item.img}
        text={item.text}
        title={item.title}
      />,
    );
  });

  return (
    <Container>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Typography paragraph variant="h1">
            {t(config.title)}
          </Typography>
        </Grid>
        {personaItems}
      </Grid>
    </Container>
  );
};

PersonasBlock.propTypes = {
  config: personasBlock,
};

PersonasBlock.defaultProps = {
  config: {},
};

export default PersonasBlock;
