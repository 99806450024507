import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';

import './i18n';
import { LogLevelsMap } from './constants/enums';
import { apiRoute, logRoute } from './constants/routes';
import App from './components/app/App';

window.addEventListener('error', (e) =>
  axios.post(`${apiRoute}${logRoute}`, {
    level: LogLevelsMap.ERROR,
    message: e.error.stack,
    url: window.location.href,
  }),
);

ReactDOM.render(
  <React.StrictMode>
    <React.Suspense fallback={<></>}>
      <App />
    </React.Suspense>
  </React.StrictMode>,
  document.getElementById('root'),
);
