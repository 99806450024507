import { useCallback } from 'react';

import useAxios, { StatusCodeMap } from '../HttpClient';
import {
  apiRoute,
  studentsRoute,
  groupsRoute,
  teachersRoute,
  QueryParams,
  moodsRoute,
  negativeRoute,
  inputRoute,
  lessonScheduleCreationStateRoute,
  coursesRoute,
  lessonScheduleRoute,
  demoRoute,
  switchRoute,
  listRoute,
  periodsRoute,
} from '../../constants/routes';
import { periodsGroupsClassname } from '../../constants/values';
import { getFullName } from '../../utils/data-transformation-helpers/dataTransformationHelpers';

const useTeachersService = () => {
  const { get, httpDelete, post, put, patch } = useAxios();

  const getTeacherStudents = useCallback(
    async (activityCriterion, filterParams, currentCampus) => {
      const { data } = await get(
        { enabled: true },
        `${apiRoute}${teachersRoute}${studentsRoute}${
          activityCriterion ? `?activityCriterion=${activityCriterion}` : ''
        }${filterParams ? `&${filterParams}` : ''}${
          currentCampus ? `&schoolName=${currentCampus}` : ''
        }`,
      );
      return {
        students: data.entity?.students.map((item) => ({
          ...item,
          name: getFullName(item),
        })),
      };
    },
    [get],
  );

  const getTeacherData = useCallback(async () => {
    const { data } = await get({ enabled: true }, `${apiRoute}${teachersRoute}/profile`);
    return data.entity;
  }, [get]);

  const postTeacherAvatar = useCallback(
    async (avatarRequestBody) =>
      post({ enabled: true }, `${apiRoute}/avatar`, avatarRequestBody, {
        skipDefaultErrorHandlers: [
          StatusCodeMap.NOT_FOUND,
          StatusCodeMap.BAD_REQUEST,
          StatusCodeMap.SERVER_ERROR,
        ],
      }),
    [post],
  );

  const postTeacherRejectAvatarReason = useCallback(
    async (reason, studentId) => {
      const { data } = await httpDelete(
        { enabled: true },
        `${apiRoute}/avatar/${studentId}?reason=${reason}`,
        undefined,
        {
          skipDefaultErrorHandlers: [
            StatusCodeMap.NOT_FOUND,
            StatusCodeMap.BAD_REQUEST,
            StatusCodeMap.SERVER_ERROR,
          ],
        },
      );
      return data.entity;
    },
    [httpDelete],
  );

  const deleteTeacherAvatar = useCallback(async () => {
    const { data } = await httpDelete({ enabled: true }, `${apiRoute}/avatar`);
    return data.entity;
  }, [httpDelete]);

  const getTeacherGroups = useCallback(
    async (lang, currentCampus) => {
      const { data } = await get(
        { enabled: true },
        `${apiRoute}${teachersRoute}${groupsRoute}?${QueryParams.LANG}=${lang}${
          currentCampus ? `&schoolName=${currentCampus}` : ''
        }`,
      );
      return data.entity?.groups
        .filter((group) => group.className === periodsGroupsClassname)
        .reduce((acc, group) => ({ ...acc, [group.period]: group }), {});
    },
    [get],
  );

  const postTeacherGroups = useCallback(
    async (groupConfig, type, lang, currentCampus) => {
      const { data } = await post(
        { enabled: true },
        `${apiRoute}${teachersRoute}${groupsRoute}/${type}?${QueryParams.LANG}=${lang}&schoolName=${currentCampus}`,
        groupConfig,
        { skipDefaultErrorHandlers: [StatusCodeMap.SERVER_ERROR] },
      );
      return data.entity;
    },
    [post],
  );

  const putTeacherGroups = useCallback(
    (groupSetId, action, body, config) =>
      put(
        { enabled: true },
        `${apiRoute}${teachersRoute}${groupsRoute}/${groupSetId}/${action}`,
        body,
        config,
      ),
    [put],
  );

  const getNegativeMoodTrends = useCallback(async () => {
    const { data } = await get(
      { enabled: true },
      `${apiRoute}${studentsRoute}${moodsRoute}${negativeRoute}${inputRoute}`,
    );
    return data.entity.negativeInputDaysCountByStudentId;
  }, [get]);

  const getLessonScheduleCreationStateById = useCallback(
    async (scheduleId) => {
      const { data } = await get(
        { enabled: true },
        `${apiRoute}${lessonScheduleCreationStateRoute}/${scheduleId}`,
      );
      return data.entity;
    },
    [get],
  );

  const getPlannerDataById = useCallback(
    async (plannerId, startTime, endTime) => {
      const { data } = await get(
        { enabled: true },
        `${apiRoute}${lessonScheduleRoute}/${plannerId}?startDate=${startTime}&endDate=${endTime}`,
      );
      return data.entity;
    },
    [get],
  );

  const getLessonScheduleCreationState = useCallback(
    async (schoolName) => {
      const { data } = await get(
        { enabled: true },
        `${apiRoute}${lessonScheduleCreationStateRoute}?schoolName=${schoolName}`,
      );

      return data.entity;
    },
    [get],
  );

  const postLessonScheduleCreationState = useCallback(
    async (lessonScheduleState, loaderText) => {
      const { data } = await post(
        { enabled: true, loaderText },
        `${apiRoute}${lessonScheduleCreationStateRoute}`,
        lessonScheduleState,
        {
          skipDefaultErrorHandlers: [StatusCodeMap.SERVER_ERROR, StatusCodeMap.NOT_FOUND],
        },
      );
      return data.entity;
    },
    [post],
  );

  const putLessonScheduleCreationState = useCallback(
    async (lessonScheduleState, scheduleId) => {
      const { data } = await put(
        { enabled: false },
        `${apiRoute}${lessonScheduleCreationStateRoute}/${scheduleId}`,
        lessonScheduleState,
        {
          skipDefaultErrorHandlers: [StatusCodeMap.SERVER_ERROR, StatusCodeMap.NOT_FOUND],
        },
      );
      return data.entity;
    },
    [put],
  );

  const postLessonSchedule = useCallback(
    async (lessonScheduleStateData, scheduleId, loaderText) => {
      const { data } = await post(
        { enabled: true, loaderText },
        `${apiRoute}${lessonScheduleRoute}/${scheduleId}`,
        lessonScheduleStateData,
        {
          skipDefaultErrorHandlers: [StatusCodeMap.SERVER_ERROR, StatusCodeMap.NOT_FOUND],
        },
      );
      return data.entity;
    },
    [post],
  );

  const putLessonSchedule = useCallback(
    async (lessonScheduleStateData, scheduleId, loaderText) => {
      const { data } = await put(
        { enabled: true, loaderText },
        `${apiRoute}${lessonScheduleRoute}/${scheduleId}`,
        lessonScheduleStateData,
        {
          skipDefaultErrorHandlers: [StatusCodeMap.SERVER_ERROR, StatusCodeMap.NOT_FOUND],
        },
      );
      return data.entity;
    },
    [put],
  );

  const patchLessonSchedule = useCallback(
    async (patchData, scheduleId) => {
      const { data } = await patch(
        { enabled: true },
        `${apiRoute}${lessonScheduleRoute}/${scheduleId}`,
        patchData,
        {
          skipDefaultErrorHandlers: [StatusCodeMap.SERVER_ERROR, StatusCodeMap.NOT_FOUND],
        },
      );
      return data.entity;
    },
    [patch],
  );

  const getTeacherCourses = useCallback(
    async (currentCampus, loaderText) => {
      const { data } = await get(
        { enabled: true, loaderText },
        `${apiRoute}${teachersRoute}${coursesRoute}?${
          currentCampus ? `&schoolName=${currentCampus}` : ''
        }`,
      );
      return data.entity;
    },
    [get],
  );

  const getTeacherPeriods = useCallback(
    async (currentCampus, loaderText) => {
      const { data } = await get(
        { enabled: true, loaderText },
        `${apiRoute}${teachersRoute}${coursesRoute}${periodsRoute}?${
          currentCampus ? `&schoolName=${currentCampus}` : ''
        }`,
      );
      return data.entity;
    },
    [get],
  );

  const deleteLessonSchedule = useCallback(
    async (scheduleId) =>
      httpDelete({ enabled: false }, `${apiRoute}${lessonScheduleRoute}/${scheduleId}`, undefined, {
        skipDefaultErrorHandlers: [
          StatusCodeMap.BAD_REQUEST,
          StatusCodeMap.NOT_FOUND,
          StatusCodeMap.SERVER_ERROR,
        ],
      }),
    [httpDelete],
  );

  const putLessonPlannerClassDay = useCallback(
    async (scheduleId, body) => {
      const { data } = await put(
        { enabled: true },
        `${apiRoute}${lessonScheduleRoute}/${scheduleId}/class-day`,
        body,
        {
          skipDefaultErrorHandlers: [
            StatusCodeMap.BAD_REQUEST,
            StatusCodeMap.NOT_FOUND,
            StatusCodeMap.SERVER_ERROR,
          ],
        },
      );

      return data.entity;
    },
    [put],
  );

  const postDemoSwitch = useCallback(
    () =>
      post(
        { enabled: true },
        `${apiRoute}${teachersRoute}${studentsRoute}${demoRoute}${switchRoute}`,
      ),
    [post],
  );

  const getTeachersList = useCallback(
    async (currentCampus) => {
      const { data } = await get(
        { enabled: false },
        `${apiRoute}${teachersRoute}${listRoute}?schoolName=${currentCampus}`,
      );
      return data.entity;
    },
    [get],
  );

  return {
    getLessonScheduleCreationState,
    getLessonScheduleCreationStateById,
    getNegativeMoodTrends,
    getTeacherCourses,
    getTeacherPeriods,
    getTeacherData,
    getTeacherGroups,
    getTeacherStudents,
    postLessonSchedule,
    putLessonSchedule,
    postLessonScheduleCreationState,
    postTeacherGroups,
    putLessonScheduleCreationState,
    deleteLessonSchedule,
    deleteTeacherAvatar,
    postTeacherAvatar,
    postTeacherRejectAvatarReason,
    putTeacherGroups,
    patchLessonSchedule,
    putLessonPlannerClassDay,
    getPlannerDataById,
    postDemoSwitch,
    getTeachersList,
  };
};

export default useTeachersService;
