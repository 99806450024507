import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Container, useMediaQuery, useTheme } from '@mui/material';
import dayjs from 'dayjs';

import { Typography } from '../../atoms';
import { CampusLabel, NewFeatureHotspot } from '../../moleculas';
import { MobileNotSupported, PageWrapper, WeekView } from '../../organisms';
import { useLessonPlannerStudentData, useSearchParams } from '../../../hooks';
import { getShortDate, isWeekend, transformSearchToObject } from '../../../utils';
import { NewFeaturesIdsMap } from '../../../tours/common/NewFeaturesItemsProvider';

const MyClassesPage = () => {
  const { t } = useTranslation();

  const { setSchoolDaysConfig, pickerDates } = useLessonPlannerStudentData();

  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });

  const history = useHistory();
  const search = useSearchParams();
  const { currentDate: plainCurrentDate } = transformSearchToObject(search);

  const currentDate = useMemo(
    () => plainCurrentDate && dayjs(plainCurrentDate),
    [plainCurrentDate],
  );

  const setCurrentDate = useCallback(
    (date) => {
      history.replace({
        search: new URLSearchParams({
          currentDate: getShortDate(dayjs(date)),
        }).toString(),
      });
    },
    [history],
  );

  const currentPickerDates = useMemo(() => pickerDates ?? {}, [pickerDates]);

  useEffect(() => {
    if (!currentDate) {
      history.replace({
        search: new URLSearchParams({
          currentDate: plainCurrentDate ?? getShortDate(dayjs()),
        }).toString(),
      });
    }
  }, [currentDate, history, plainCurrentDate]);

  useEffect(() => {
    if (currentDate && isWeekend(currentDate)) {
      const weekIncrement = currentDate.day() === 6 ? 1 : 0;
      setCurrentDate(currentDate.add(weekIncrement, 'week').day(1));
    }
  }, [setCurrentDate, currentDate]);

  useEffect(() => {
    setSchoolDaysConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!currentDate) return null;

  return (
    <PageWrapper mainElementClassName="ayo-my-classes-page">
      {isWidthUpSm ? (
        <Container>
          <Box>
            <Box alignItems="center" display="flex">
              <Typography component="h1" variant="h1">
                {t('My classes')}
              </Typography>
              <NewFeatureHotspot
                id={NewFeaturesIdsMap.MY_CLASSES}
                isClickable
                label={t('My classes')}
              />
            </Box>
            <CampusLabel />
          </Box>
          <Box>
            <WeekView
              currentDate={currentDate}
              currentPickerDates={currentPickerDates}
              setCurrentDate={setCurrentDate}
            />
          </Box>
        </Container>
      ) : (
        <MobileNotSupported
          explanation={t(
            'Please open My classes section with a tablet or desktop version to use this functionality',
          )}
        />
      )}
    </PageWrapper>
  );
};

export default MyClassesPage;
