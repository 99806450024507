import React, { useCallback, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Container, Grid, Toolbar, Divider, useTheme, useMediaQuery } from '@mui/material';

import { Link, Typography } from '../../atoms';
import SupportDialog from '../support-dialog/SupportDialog';
import PrivacyPolicyDialog from '../privacy-policy-dialog/PrivacyPolicyDialog';
import TermsConditionsDialog from '../terms-conditions-dialog/TermsConditionsDialog';
import { UserContext } from '../../../context';
import {
  faqRoute,
  googleEduRoute,
  privacyPolicyRoute,
  plugRoute,
  softserveRoute,
  termsConditionsRoute,
  mainYoutubeRoute,
  termsOfUseForSchoolDistrictRoute,
} from '../../../constants/routes';
import { ReactComponent as GoogleEduLogo } from '../../../resources/images/app_google_edu_logo.svg';
import { ReactComponent as SoftServeLogo } from '../../../resources/images/app_softserve_logo.svg';
import { ReactComponent as FaqIcon } from '../../../resources/icons/faq.svg';
import { ReactComponent as YouTubeIcon } from '../../../resources/icons/youtube.svg';

const PartnersBlock = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Grid container direction="column">
      <Box pb={1}>
        <Typography variant="body2">{t('Our Partners')}</Typography>
      </Box>
      <Box alignItems="center" display="flex" pb={!isWidthUpSm ? 1.5 : 0}>
        <Box pr={1}>
          <Link gaLabel="Google for Education Logo" href={googleEduRoute} target="_blank">
            <GoogleEduLogo
              aria-label={t('Google for Education Logo')}
              className="ayo-bottom-bar__google-logo"
              role="img"
            />
          </Link>
        </Box>
        <Divider
          className="ayo-bottom-bar__divider ayo-bottom-bar__divider--vertical"
          orientation="vertical"
        />
        <Box pl={1}>
          <Link gaLabel="SoftServe Logo" href={softserveRoute} target="_blank">
            <SoftServeLogo
              aria-label={t('SoftServe Logo')}
              className="ayo-bottom-bar__softserve-logo"
              role="img"
            />
          </Link>
        </Box>
      </Box>
    </Grid>
  );
};

const FooterIconLink = ({ gaLabel, icon, isExternalLink, label, link, simple }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      alignItems="center"
      display="inline-flex"
      pl={isMobile || simple ? 0 : 3}
      pr={2}
      pt={isMobile ? 2 : 0}
    >
      {!simple && icon}
      <Link
        className={`${classNames({
          'ayo-bottom-bar__link-icon': !simple,
        })}`}
        component={isExternalLink ? 'a' : RouterLink}
        gaLabel={gaLabel}
        href={link}
        target={isExternalLink ? '_blank' : '_self'}
        to={link}
      >
        {t(label)}
      </Link>
    </Box>
  );
};

FooterIconLink.propTypes = {
  gaLabel: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  isExternalLink: PropTypes.bool,
  label: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  simple: PropTypes.bool,
};

FooterIconLink.defaultProps = {
  isExternalLink: false,
  simple: false,
};

const FeedbackLink = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);

  const handleFeedbackModalOpen = useCallback(
    (e) => {
      e.preventDefault();
      setIsFeedbackModalOpen(true);
    },
    [setIsFeedbackModalOpen],
  );

  const handleFeedbackModalClose = useCallback(
    () => setIsFeedbackModalOpen(false),
    [setIsFeedbackModalOpen],
  );

  return (
    <Box alignItems="center" display="flex" pr={2} pt={isMobile ? 3 : 0}>
      <Link gaLabel="Share feedback" href={plugRoute} onClick={handleFeedbackModalOpen}>
        {t('Support')}
      </Link>
      <SupportDialog isOpen={isFeedbackModalOpen} onClose={handleFeedbackModalClose} />
    </Box>
  );
};

const LinksBlock = ({ noFAQ, noNav, simple }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [isPrivacyDialogOpen, setIsPrivacyDialogOpen] = useState(false);
  const [isTermsConditionsDialogOpen, setIsTermsConditionsDialogOpen] = useState(false);

  const { state: userState } = useContext(UserContext);
  const profileNoNav = !userState.profile || userState.noNavigation;
  const isNavigationDisabled = useMemo(() => profileNoNav || noNav, [profileNoNav, noNav]);

  return (
    <Box
      alignItems={isMobile ? 'flex-start' : 'center'}
      display="flex"
      flexDirection={isMobile ? 'column' : 'row'}
    >
      {simple && (
        <>
          {userState.profile && <FeedbackLink />}
          {isNavigationDisabled && !noFAQ && (
            <FooterIconLink
              gaLabel="Frequently Asked Questions"
              icon={<FaqIcon />}
              label="FAQ"
              link={faqRoute}
              simple
            />
          )}
          <FooterIconLink
            gaLabel="YouTube Link"
            icon={<YouTubeIcon />}
            isExternalLink
            label="YouTube"
            link={mainYoutubeRoute}
            simple
          />
        </>
      )}
      {!simple && !userState.profile && (
        <Box pr={2} pt={isMobile ? 2 : 0}>
          <Link
            gaLabel="Terms of Use for School Districts"
            href={termsOfUseForSchoolDistrictRoute}
            target="_blank"
          >
            {t('Terms of Use for School Districts')}
          </Link>
        </Box>
      )}
      <Box pr={2} pt={isMobile ? 2 : 0}>
        <Link
          component={RouterLink}
          gaLabel="Privacy Policy"
          onClick={(e) => {
            if (simple) {
              e.preventDefault();
              setIsPrivacyDialogOpen(true);
            }
          }}
          to={privacyPolicyRoute}
        >
          {t('Privacy Policy')}
        </Link>
      </Box>
      <Box pr={2} pt={isMobile ? 2 : 0}>
        <Link
          component={RouterLink}
          gaLabel="Terms and Conditions"
          onClick={(e) => {
            if (simple) {
              e.preventDefault();
              setIsTermsConditionsDialogOpen(true);
            }
          }}
          to={termsConditionsRoute}
        >
          {t('Terms & Conditions')}
        </Link>
      </Box>
      <PrivacyPolicyDialog
        isOpen={isPrivacyDialogOpen}
        onClose={() => setIsPrivacyDialogOpen(false)}
      />
      <TermsConditionsDialog
        isOpen={isTermsConditionsDialogOpen}
        onClose={() => setIsTermsConditionsDialogOpen(false)}
      />
    </Box>
  );
};

LinksBlock.propTypes = {
  noFAQ: PropTypes.bool,
  noNav: PropTypes.bool,
  simple: PropTypes.bool,
};

LinksBlock.defaultProps = {
  noFAQ: false,
  noNav: false,
  simple: false,
};

const CopyrightBlock = ({ noFAQ, noNav, simple }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      {(!simple || isMobile) && (
        <Divider className="ayo-bottom-bar__divider ayo-bottom-bar__divider--horizontal" />
      )}
      <Box py={simple && !isMobile ? 3 : 1}>
        <Container>
          <Grid
            alignItems={!isMobile ? 'center' : 'flex-start'}
            container
            direction={!isMobile ? 'row' : 'column'}
            justifyContent="space-between"
          >
            <Typography variant="body3">
              &copy;{new Date().getFullYear()}{' '}
              <Trans
                components={{ br: <br /> }}
                i18nKey="Mesquite Independent School District. All rights reserved"
              />
            </Typography>
            {!isMobile && <LinksBlock noFAQ={noFAQ} noNav={noNav} simple={simple} />}
          </Grid>
        </Container>
      </Box>
    </>
  );
};

CopyrightBlock.propTypes = {
  noFAQ: PropTypes.bool,
  noNav: PropTypes.bool,
  simple: PropTypes.bool,
};

CopyrightBlock.defaultProps = {
  noFAQ: false,
  noNav: false,
  simple: false,
};

const BottomBar = ({ noFAQ, noNav, simple }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Toolbar
      aria-label={t('About us')}
      className={`${classNames(`ayo-bottom-bar`, {
        'ayo-bottom-bar--simple': simple,
      })}`}
      disableGutters
      role="region"
    >
      {!simple && (
        <Box pb={!isMobile ? 3 : 0} pt={!isMobile ? 3 : 4}>
          <Container>
            <Box
              display="flex"
              flexDirection={isMobile ? 'column' : 'row'}
              justifyContent="space-between"
            >
              <PartnersBlock />
              <FooterIconLink
                gaLabel="Frequently Asked Questions"
                icon={<FaqIcon />}
                label="FAQ"
                link={faqRoute}
                simple={isMobile}
              />
              <FooterIconLink
                gaLabel="YouTube Link"
                icon={<YouTubeIcon />}
                isExternalLink
                label="YouTube"
                link={mainYoutubeRoute}
                simple={isMobile}
              />
            </Box>
          </Container>
        </Box>
      )}
      {isMobile && (
        <Box pb={!simple ? 2 : 0}>
          <Container>
            <LinksBlock noFAQ={noFAQ} noNav={noNav} simple={simple} />
          </Container>
        </Box>
      )}
      <CopyrightBlock noNav={noNav} simple={simple} />
    </Toolbar>
  );
};

BottomBar.propTypes = {
  noFAQ: PropTypes.bool,
  noNav: PropTypes.bool,
  simple: PropTypes.bool,
};

BottomBar.defaultProps = {
  noFAQ: false,
  noNav: false,
  simple: false,
};

export default BottomBar;
