import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Divider } from '@mui/material';
import PropTypes from 'prop-types';

import { Card, Typography } from '../../../../atoms';
import AnalyticsStatValue from '../analytics-stat-value/AnalyticsStatValue';
import { NewFeaturesIdsMap } from '../../../../../tours/common/NewFeaturesItemsProvider';
import { NewFeatureHotspot } from '../../../../moleculas';

const MultipleStatCard = ({ statKey, statsCategory, statValue, newFeatureId, newFeatureLabel }) => {
  const { t } = useTranslation();

  const isWithFilters = Boolean(statValue.have && statValue.notHave);
  return (
    <Card
      key={statKey}
      elevation={0}
      mainContent={
        <Box>
          <Box display="flex">
            <Typography component="h4" paragraph variant="subtitle2">
              {t(`analytics.${statKey}.name`)}
            </Typography>
            {newFeatureId && (
              <NewFeatureHotspot id={newFeatureId} isClickable label={t(newFeatureLabel)} />
            )}
          </Box>
          <Box
            alignItems="center"
            display="flex"
            justifyContent="space-between"
            position="relative"
          >
            <Box minWidth="45%">
              <Box mb={1}>
                <AnalyticsStatValue
                  filterCategory={statKey}
                  filterValue={Object.keys(statValue)[0]}
                  statsCategory={statsCategory}
                  statsValue={statValue.have}
                  withFilters={isWithFilters}
                />
              </Box>
              <Box>
                <Typography isLabel variant="caption">
                  {t(`analytics.${statKey}.have`)}
                </Typography>
              </Box>
            </Box>
            <Divider flexItem orientation="vertical" />
            <Box alignItems="center" display="flex" flexDirection="column" minWidth="55%" ml={2}>
              <Box>
                <Box mb={1}>
                  <AnalyticsStatValue
                    filterCategory={statKey}
                    filterValue={Object.keys(statValue)[1]}
                    statsCategory={statsCategory}
                    statsValue={statValue.notHave}
                    withFilters={isWithFilters}
                  />
                </Box>
                <Typography isLabel variant="caption">
                  {t(`analytics.${statKey}.notHave`)}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      }
    />
  );
};

MultipleStatCard.propTypes = {
  newFeatureId: PropTypes.oneOf(Object.values(NewFeaturesIdsMap)),
  newFeatureLabel: PropTypes.string,
  statKey: PropTypes.string.isRequired,
  statsCategory: PropTypes.string.isRequired,
  statValue: PropTypes.instanceOf(Object).isRequired,
};

MultipleStatCard.defaultProps = {
  newFeatureId: null,
  newFeatureLabel: null,
};

export default MultipleStatCard;
