import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import classNames from 'classnames';

import { DeleteItemWrapper, InitiativeEmptyStateBlock } from '../../../../moleculas';
import { PortfolioEntitiesData, PortfolioItemsToAddData } from '../../../../../utils';
import { portfolioSectionConfig } from '../../../../../constants/propTypes';
import { PortfolioSectionTypes, TestTypeNameMap } from '../../../../../constants/enums';
import { ReactComponent as EndorsementsEmptyStateIllustration } from '../../../../../resources/images/portfolio/test_scores_empty_state.svg';
import PortfolioSection from '../../components/portfolio-section/PortfolioSection';
import PortfolioTestScoreCard from '../../components/portfolio-test-score-card/PortfolioTestScoreCard';

const PortfolioTestScoresSection = ({ config, sectionIndex, isLastSection }) => {
  const { t } = useTranslation();
  const {
    addPortfolioItemHandler,
    deletePortfolioItemHandler,
    hasData,
    isEditMode,
    isVisible,
    metaData,
    reflectionHandler,
    sectionData,
    updatePortfolioHandler,
    onUpdatePositionHandler,
  } = config;
  const { entities, reflection } = sectionData;

  const [testScores, setTestScores] = useState([]);

  useEffect(() => {
    setTestScores(
      PortfolioEntitiesData[PortfolioSectionTypes.PORTFOLIO__TEST_SCORES](
        entities,
        metaData,
        'examName',
      ),
    );
  }, [entities, metaData]);

  const testScoresToAdd = useMemo(
    () =>
      PortfolioItemsToAddData[PortfolioSectionTypes.PORTFOLIO__TEST_SCORES](
        entities,
        metaData,
        'examName',
      ).map(({ examName }) => ({
        id: examName,
        name: TestTypeNameMap[examName],
      })),
    [entities, metaData],
  );

  const handleSectionUpdate = useCallback(() => {
    const testScoresToUpdate = metaData.filter((testScore) =>
      testScores.some((item) => item.examName === testScore.examName),
    );

    updatePortfolioHandler({
      ...sectionData,
      entities: [...testScoresToUpdate],
    });
  }, [metaData, sectionData, testScores, updatePortfolioHandler]);

  if (!isVisible) {
    return null;
  }

  return (
    <PortfolioSection
      body={
        <Grid columnSpacing={3} container mt={3}>
          {testScores.map(({ examName, score }) => (
            <Grid key={examName} item sm={4}>
              <DeleteItemWrapper
                disabled={!isEditMode}
                gaLabel="Remove test score"
                mainContent={
                  <PortfolioTestScoreCard
                    examName={examName}
                    isEditMode={isEditMode}
                    score={score}
                  />
                }
                onDelete={() => {
                  deletePortfolioItemHandler(testScores, 'examName', examName, sectionData);
                }}
              />
            </Grid>
          ))}
        </Grid>
      }
      className={`${classNames({
        'ayo-portfolio__test-scores-section': hasData,
      })}`}
      description={isEditMode ? 'Here, you can see the highlights of your test scores.' : ''}
      emptyState={
        !hasData ? (
          <InitiativeEmptyStateBlock
            body={isEditMode ? t('It will appear here once AYO discovers it.') : ''}
            illustration={<EndorsementsEmptyStateIllustration />}
            title={t('No information about test scores so far')}
          />
        ) : null
      }
      isEditMode={isEditMode}
      isLastSection={isLastSection}
      itemsToAdd={testScoresToAdd}
      onAddItems={(itemsToAdd) =>
        addPortfolioItemHandler(metaData, itemsToAdd, 'examName', sectionData)
      }
      onChangeReflection={reflectionHandler}
      onSectionUpdate={handleSectionUpdate}
      onUpdatePositionHandler={onUpdatePositionHandler}
      reflection={reflection}
      sectionIndex={sectionIndex}
      sectionKey={PortfolioSectionTypes.PORTFOLIO__TEST_SCORES}
      showActionsMenu={hasData && isEditMode}
      showAddButton={!!testScoresToAdd?.length && isEditMode}
      title={t('Test scores')}
    />
  );
};

PortfolioTestScoresSection.propTypes = {
  config: portfolioSectionConfig(
    PropTypes.arrayOf(
      PropTypes.shape({
        examName: PropTypes.string,
        score: PropTypes.number,
      }),
    ),
  ),
  sectionIndex: PropTypes.number.isRequired,
  isLastSection: PropTypes.number.isRequired,
};

PortfolioTestScoresSection.defaultProps = {
  config: {},
};

export default PortfolioTestScoresSection;
