import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Box, Grid } from '@mui/material';

import { Link, Switch, Tooltip, Typography } from '../../../../../../atoms';
import { EditingButton } from '../../../../../../moleculas';
import ApproveModerationToggleButton from '../../approve-moderation-toggle-button/ApproveModerationToggleButton';
import { GaActions, GaCategories, ContentSwitchLabels } from '../../../../../../../constants/enums';
import { GA, getIsDomElementCropped } from '../../../../../../../utils';
import { ReactComponent as LinkIcon } from '../../../../../../../resources/icons/link.svg';
import { ReactComponent as ExpandIcon } from '../../../../../../../resources/icons/chevron_down.svg';
import { ReactComponent as CollapseIcon } from '../../../../../../../resources/icons/chevron_up.svg';
import TranslateModerationButton from '../../translate-moderation-button/TranslateModerationButton';

const TitleComponent = ({ opportunity, headingLevel }) => {
  const { t } = useTranslation();

  return Object.keys(opportunity).includes('url') ? (
    <Link
      gaLabel={`Learn more at ${opportunity.source || opportunity.url}`}
      href={opportunity.url}
      target="_blank"
    >
      <Box display="flex">
        <Typography
          className="ayo-content-moderation-opportunities__title"
          component={`h${headingLevel}`}
          variant="subtitle2"
        >{`${opportunity.keys.name.EN_US}`}</Typography>
        <Tooltip
          enterTouchDelay={0}
          isDisabled={!opportunity.source}
          leaveTouchDelay={5000}
          onOpen={(e) => {
            e.stopPropagation();
            GA.logInteraction({
              category: GaCategories.BEHAVIOR,
              action: GaActions.ICON_HOVER,
              label: `Learn more at ${opportunity.source}`,
            });
          }}
          title={`${t('Learn more at')} ${opportunity.source}`}
        >
          <Box className="ayo-content-moderation-opportunities__icon">
            <LinkIcon
              aria-label={`${t(opportunity.source ? 'Learn more at' : 'Go to')} ${
                opportunity.source ? opportunity.source : opportunity.url
              }`}
              tabIndex="0"
            />
          </Box>
        </Tooltip>
      </Box>
    </Link>
  ) : (
    <Typography variant="subtitle2">{opportunity.keys.name.EN_US}</Typography>
  );
};

TitleComponent.propTypes = {
  opportunity: PropTypes.shape({
    category: PropTypes.string,
    entityKey: PropTypes.string,
    isVisible: PropTypes.bool,
    source: PropTypes.string,
    url: PropTypes.string,
    keys: PropTypes.instanceOf(Object),
  }),
  headingLevel: PropTypes.number,
};

TitleComponent.defaultProps = {
  opportunity: {},
  headingLevel: 2,
};

const ROW_MARGIN = 8;
const DESCRIPTION_PADDING = 8;

const OpportunitiesModerationRow = ({
  index,
  isShowHideGranted,
  isEditApproveGranted,
  opportunity,
  setSize,
  submitVisibility,
  updateTranslationHandler,
  approveTranslationHandler,
  headingLevel,
}) => {
  const { t } = useTranslation();
  const [isDescriptionCropped, setIsDescriptionCropped] = useState(null);
  const [isDescriptionCollapsed, setIsDescriptionCollapsed] = useState(true);

  const rowRef = useRef(null);
  const descriptionEsBodyRef = useRef(null);
  const descriptionEnBodyRef = useRef(null);

  const isDescriptionIncluded = useMemo(
    () => Object.keys(opportunity.keys).includes('description'),
    [opportunity],
  );

  useEffect(() => {
    setIsDescriptionCropped(
      getIsDomElementCropped(
        descriptionEnBodyRef.current?.parentElement.scrollHeight,
        DESCRIPTION_PADDING,
        descriptionEnBodyRef.current?.parentElement.clientHeight,
      ) ||
        getIsDomElementCropped(
          descriptionEsBodyRef.current?.parentElement.scrollHeight,
          DESCRIPTION_PADDING,
          descriptionEsBodyRef.current?.parentElement.clientHeight,
        ),
    );
  }, [opportunity]);

  useEffect(() => {
    if (isDescriptionCropped !== null) {
      setSize(index, rowRef.current?.clientHeight + ROW_MARGIN);
    }
  }, [index, isDescriptionCropped, isDescriptionCollapsed, setSize]);

  return (
    <>
      <Grid
        key={opportunity.entityKey}
        ref={rowRef}
        className="ayo-content-moderation-opportunities__row"
        container
        display="flex"
        justifyContent="space-between"
        role="row"
      >
        <Grid item role="cell" xs={4}>
          <TitleComponent headingLevel={headingLevel} opportunity={opportunity} />
        </Grid>
        <Grid item role="cell" xs={4}>
          <Box mb={1}>
            <Typography
              className="ayo-content-moderation-opportunities__title__translation"
              component="p"
              variant="subtitle2"
            >
              {opportunity.keys.name.ES_US_updated || opportunity.keys.name.ES_US}
            </Typography>
          </Box>
        </Grid>
        {isEditApproveGranted && (
          <>
            <Grid
              className="ayo-content-moderation-opportunities__row__buttons"
              item
              role="cell"
              xs={1}
            >
              <ApproveModerationToggleButton
                entity={opportunity}
                onChange={approveTranslationHandler}
              />
            </Grid>
            <Grid item role="cell" xs={1}>
              <Box mt={-2}>
                <TranslateModerationButton
                  entity={opportunity}
                  onTranslationChange={updateTranslationHandler}
                />
              </Box>
            </Grid>
          </>
        )}
        {isShowHideGranted && (
          <Grid
            className="ayo-content-moderation-opportunities__row__buttons"
            item
            role="cell"
            xs={1}
          >
            <Switch
              ariaLabel={`${opportunity.isVisible ? 'Hide' : 'Show'}${opportunity.keys.name.ES_US}`}
              checkedLabel={ContentSwitchLabels.on}
              name={opportunity.entityKey}
              onChange={(newValue) => submitVisibility(opportunity, newValue)}
              uncheckedLabel={ContentSwitchLabels.off}
              value={opportunity.isVisible}
            />
          </Grid>
        )}
        {isDescriptionIncluded && (
          <>
            <Grid item xs={4}>
              <Typography
                className={classNames('ayo-content-moderation-opportunities__description', {
                  'ayo-content-moderation-opportunities__description--collapsed':
                    !isDescriptionCollapsed,
                })}
                variant="body2"
              >
                <span ref={descriptionEnBodyRef}>{opportunity.keys.description.EN_US}</span>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                className={classNames('ayo-content-moderation-opportunities__description', {
                  'ayo-content-moderation-opportunities__description--collapsed':
                    !isDescriptionCollapsed,
                })}
                variant="body2"
              >
                <span
                  ref={descriptionEsBodyRef}
                  aria-label={`${t('Translation (ES)')} - ${
                    opportunity.keys.description.ES_US_updated || opportunity.keys.description.ES_US
                  }`}
                >
                  {opportunity.keys.description.ES_US_updated || opportunity.keys.description.ES_US}
                </span>
              </Typography>
            </Grid>
            {isEditApproveGranted && (
              <>
                <Grid item xs={1} />
                <Grid item xs={1} />
              </>
            )}
            {isShowHideGranted && <Grid item xs={1} />}
          </>
        )}
        <Grid item xs={12}>
          {isDescriptionCropped && (
            <Box my={1}>
              {isDescriptionCollapsed ? (
                <EditingButton
                  gaLabel="Show more"
                  icon={<ExpandIcon />}
                  iconPosition="end"
                  onClick={() => {
                    setIsDescriptionCollapsed(false);
                  }}
                  text={t('Show more')}
                />
              ) : (
                <EditingButton
                  gaLabel="Show less"
                  icon={<CollapseIcon />}
                  iconPosition="end"
                  onClick={() => {
                    setIsDescriptionCollapsed(true);
                  }}
                  text={t('Show less')}
                />
              )}
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  );
};

OpportunitiesModerationRow.propTypes = {
  index: PropTypes.number,
  isShowHideGranted: PropTypes.bool,
  isEditApproveGranted: PropTypes.bool,
  opportunity: PropTypes.shape({
    category: PropTypes.string,
    entityKey: PropTypes.string,
    isVisible: PropTypes.bool,
    isApproved: PropTypes.bool,
    source: PropTypes.string,
    url: PropTypes.string,
    keys: PropTypes.instanceOf(Object),
  }),
  setSize: PropTypes.func,
  submitVisibility: PropTypes.func.isRequired,
  approveTranslationHandler: PropTypes.func.isRequired,
  updateTranslationHandler: PropTypes.func.isRequired,
  headingLevel: PropTypes.number,
};

OpportunitiesModerationRow.defaultProps = {
  index: null,
  isShowHideGranted: null,
  isEditApproveGranted: null,
  opportunity: {},
  setSize: () => {},
  headingLevel: 2,
};

export default OpportunitiesModerationRow;
