import React, { useReducer, createContext } from 'react';
import PropTypes from 'prop-types';

import { NavItemsMap } from '../../constants/pages';
import { LocalStorageKeysMap } from '../../constants/enums';

export const UserContext = createContext();

export const UserActions = {
  SET_USER_PROFILE: 'SET_USER_PROFILE',
  SET_USER_PROFILE_CAMPUS: 'SET_USER_PROFILE_CAMPUS',
  SET_USER_PROFILE_POLICY: 'SET_USER_PROFILE_POLICY',
  SET_USER_PROFILE_ROLE: 'SET_USER_PROFILE_ROLE',
  SET_USER_PROFILE_ERROR: 'SET_USER_PROFILE_ERROR',
  SET_ONE_TIME_ACTION: 'SET_ONE_TIME_ACTION',
  SET_USER_PORTFOLIO_EDIT_MODE: 'SET_USER_PORTFOLIO_EDIT_MODE',
  SET_IS_ENDORSEMENTS_AVAILABLE: 'SET_IS_ENDORSEMENTS_AVAILABLE',
  UPDATE_USER_SETTINGS: 'UPDATE_USER_SETTINGS',
};

const initState = {
  dependentProfiles: null,
  noNavigation: null,
  oneTimeActions: null,
  profile: null,
  profileError: null,
  settings: null,
  isEndorsementsAvailable: null,
  isPortfolioEditMode: false,
  isGuardianWithoutStudentConsent: false,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case UserActions.SET_USER_PROFILE:
      if (action.data?.profile?.currentCampus) {
        localStorage.setItem(
          LocalStorageKeysMap.AYO_CURRENT_CAMPUS,
          action.data.profile.currentCampus,
        );
      } else {
        localStorage.removeItem(LocalStorageKeysMap.AYO_CURRENT_CAMPUS);
      }
      return action.data
        ? {
            ...state,
            dependentProfiles: action.data.dependentProfiles,
            noNavigation: !NavItemsMap[action.data.profile.role]?.main,
            oneTimeActions: action.data.oneTimeActions,
            profile: { ...action.data.profile },
            settings: { ...action.data.settings },
            profileError: null,
            isGuardianWithoutStudentConsent: action.data.isGuardianWithoutStudentConsent,
          }
        : {
            dependentProfiles: null,
            noNavigation: null,
            oneTimeActions: null,
            profile: null,
            profileError: null,
            isGuardianWithoutStudentConsent: false,
          };
    case UserActions.SET_USER_PROFILE_CAMPUS:
      localStorage.setItem(LocalStorageKeysMap.AYO_CURRENT_CAMPUS, action.data.schoolName);
      return {
        ...state,
        profile: {
          ...state.profile,
          currentCampus: action.data.schoolName,
          currentCampusSchoolLevel: action.data.schoolLevel,
        },
      };
    case UserActions.SET_USER_PROFILE_POLICY:
      return {
        ...state,
        profile: { ...state.profile, policyAccepted: action.data },
      };
    case UserActions.SET_USER_PROFILE_ROLE:
      return {
        ...state,
        noNavigation: !NavItemsMap[action.data]?.main,
        profile: { ...state.profile, role: action.data },
      };
    case UserActions.SET_USER_PROFILE_ERROR:
      return { ...state, profile: null, profileError: action.data };
    case UserActions.SET_ONE_TIME_ACTION:
      return {
        ...state,
        oneTimeActions: [...state.oneTimeActions, action.data],
      };
    case UserActions.SET_IS_ENDORSEMENTS_AVAILABLE:
      return {
        ...state,
        isEndorsementsAvailable: action.data,
      };
    case UserActions.SET_USER_PORTFOLIO_EDIT_MODE:
      return {
        ...state,
        isPortfolioEditMode: action.data,
      };
    case UserActions.UPDATE_USER_SETTINGS: {
      const settingsCategoryArrayCopy = [...state.settings[action.data.parentCategory]];
      action.data.settigsToChange.forEach(({ name, value }) => {
        const settingToChangeIndex = state.settings[action.data.parentCategory].findIndex(
          (setting) => setting.name === name,
        );
        const settingToChange = { ...settingsCategoryArrayCopy[settingToChangeIndex] };
        if (settingToChange.value !== value) {
          settingToChange.value = value;
        }
        settingsCategoryArrayCopy[settingToChangeIndex] = settingToChange;
      });
      return {
        ...state,
        settings: {
          ...state.settings,
          [action.data.parentCategory]: settingsCategoryArrayCopy,
        },
      };
    }
    default:
      throw new Error();
  }
};

export const UserContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initState);

  return <UserContext.Provider value={{ state, dispatch }}>{children}</UserContext.Provider>;
};

UserContextProvider.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
};
