/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, useLocation } from 'react-router-dom';

import { PageWrapper, Welcome } from '../../components/organisms';
import { UserActions, UserContext } from '../../context';
import { useOneTimeActionService } from '../../services';
import { useProfile } from '../profile-helpers/profileHelpers';
import {
  campusSelectionRoute,
  forbiddenPageRoute,
  roleQuestionRoute,
  SupportedOldRoutes,
} from '../../constants/routes';
import { LocalStorageKeysMap, OneTimeActionsMap, RolesMap } from '../../constants/enums';

// TODO: refactor to fix asynchronous bugs
// we're trying to access profile data when it has not been set to the context yet
const PrivateRoute = ({
  allowedRoles,
  noAuthCheck,
  noConsent,
  noPolicyCheck,
  privileges,
  ...rest
}) => {
  const { pathname } = useLocation();

  const { state: userState, dispatch: dispatchUserState } = useContext(UserContext);
  const { getIsUnder18, getProfileState, getOneTimeActionStatus, getIsCampusSelectionAvailable } =
    useProfile();
  const { postOneTimeAction } = useOneTimeActionService();

  const [routesToReplace, setRoutesToReplace] = useState(null);

  const [hasAccess, setHasAccess] = useState(undefined);
  const hasAccessToPage = useCallback(
    (profile) => {
      const roleCheck = allowedRoles?.includes(profile.role);
      const privilegesCheck =
        !privileges || privileges.some((privilege) => profile.privileges?.includes(privilege));
      const consentCheck = noConsent || !profile.isGuardianWithoutStudentConsent;

      return roleCheck && privilegesCheck && consentCheck;
    },
    [allowedRoles, noConsent, privileges],
  );
  const isStudentUnder18Intro =
    userState?.profile?.role === RolesMap.STUDENT &&
    getIsUnder18(userState?.profile) &&
    !getOneTimeActionStatus(OneTimeActionsMap.INTRODUCTION_SCREEN);

  const isTeacherRoleQuestion =
    userState?.profile?.role === RolesMap.TEACHER &&
    !getOneTimeActionStatus(OneTimeActionsMap.TEACHER_PAGE_ROLE);

  const isAdmin = userState?.profile?.role === RolesMap.AYO_ADMIN;

  const hasGuardianDelayedConsent = sessionStorage.getItem(
    LocalStorageKeysMap.GUARDIAN_DELAYED_CONSENT,
  );

  const isGuardianWithoutStudentConsent =
    userState?.profile?.role === RolesMap.GUARDIAN &&
    userState?.dependentProfiles?.length === 0 &&
    !hasGuardianDelayedConsent;

  const showWelcome =
    !noPolicyCheck &&
    (!userState?.profile?.policyAccepted ||
      isStudentUnder18Intro ||
      isGuardianWithoutStudentConsent) &&
    !isAdmin;
  const showRoleQuestion =
    !showWelcome && !noAuthCheck && isTeacherRoleQuestion && pathname !== roleQuestionRoute;
  const showForbidden = !noAuthCheck && hasAccess === false;
  const passedPageAccessCheck = !showForbidden && hasAccess !== undefined;

  const showCampusSelection =
    userState.profile &&
    !userState.profile.currentCampus &&
    getIsCampusSelectionAvailable() &&
    !noAuthCheck &&
    pathname !== campusSelectionRoute &&
    pathname !== roleQuestionRoute;

  useEffect(() => {
    setRoutesToReplace(SupportedOldRoutes.find(({ oldRoute }) => pathname.includes(oldRoute)));
    if (!userState.profile) {
      getProfileState({ skipAuthCheck: noAuthCheck })
        .then((profile) => {
          setHasAccess(!allowedRoles || hasAccessToPage(profile));
        })
        .catch(() => setHasAccess(noAuthCheck));
    } else {
      setHasAccess(!allowedRoles || hasAccessToPage(userState.profile));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <>
      {showWelcome && (
        <PageWrapper noNav>
          <Welcome
            onProceed={() => {
              if (isStudentUnder18Intro) {
                postOneTimeAction(OneTimeActionsMap.INTRODUCTION_SCREEN).then(() => {
                  dispatchUserState({
                    type: UserActions.SET_ONE_TIME_ACTION,
                    data: OneTimeActionsMap.INTRODUCTION_SCREEN,
                  });
                });
              } else {
                dispatchUserState({ type: UserActions.SET_USER_PROFILE_POLICY, data: true });
              }
            }}
          />
        </PageWrapper>
      )}
      {showRoleQuestion && <Redirect to={roleQuestionRoute} />}
      {routesToReplace && (
        <Redirect to={pathname.replace(routesToReplace.oldRoute, routesToReplace.newRoute)} />
      )}
      {showForbidden && <Redirect to={forbiddenPageRoute} />}
      {showCampusSelection && passedPageAccessCheck && <Redirect to={campusSelectionRoute} />}
      {!showWelcome && passedPageAccessCheck && !showRoleQuestion && !showCampusSelection && (
        <Route {...rest} />
      )}
    </>
  );
};

PrivateRoute.propTypes = {
  allowedRoles: PropTypes.arrayOf(PropTypes.string),
  noAuthCheck: PropTypes.bool,
  noConsent: PropTypes.bool,
  noPolicyCheck: PropTypes.bool,
  privileges: PropTypes.arrayOf(PropTypes.string),
};

PrivateRoute.defaultProps = {
  allowedRoles: null,
  noAuthCheck: false,
  noConsent: false,
  noPolicyCheck: false,
  privileges: null,
};

export default PrivateRoute;
