import React, { useState, useEffect, useContext, useMemo, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Grid, Container, Box, useMediaQuery, useTheme } from '@mui/material';

import { Typography, RadioGroup, ActionsMenu, Link, Snackbar } from '../../../atoms';
import {
  DataGrid,
  NewFeatureHotspot,
  SearchField,
  StudentFilters as PopupFilters,
} from '../../../moleculas';
import { PageWrapper, MobileNotSupported } from '../../../organisms';
import { useAdminService } from '../../../../services';
import {
  AdminActionsStatusesMap,
  EditablePrivilegesByGroupMap,
  PrivilegesMap,
  RolesMap,
} from '../../../../constants/enums';
import { AdminGridContext, AdminGridActions, UserContext } from '../../../../context';
import {
  ClubHubModerationFilterCategory,
  ContentModerationFilterCategory,
  FamilyFeedFilterCategory,
} from '../../../../constants/filter-categories';
import { QueryParams } from '../../../../constants/routes';
import { NewFeaturesIdsMap } from '../../../../tours/common/NewFeaturesItemsProvider';
import {
  capitalize,
  getFilterValueParts,
  transformSearchToObject,
  useProfile,
} from '../../../../utils';
import { ReactComponent as ExportIcon } from '../../../../resources/icons/export.svg';
import { ReactComponent as DeleteIcon } from '../../../../resources/icons/delete.svg';
import { ReactComponent as ModerateIcon } from '../../../../resources/icons/moderator.svg';
import { ReactComponent as SortedDescIcon } from '../../../../resources/icons/sorted_desc.svg';

import DeleteUserDataDialog from './components/delete-user-data-dialog/DeleteUserDataDialog';
import ManageUserPrivilegesDialog from './components/manage-user-privileges-dialog/ManageUserPrivilegesDialog';
import {
  ClubHubModerationPrivilegesFilters,
  ContentModerationPrivilegesFilters,
  FamilyFeedPrivilegesFilters,
} from './components/user-privileges-filters/UserPrivilegesFilters';

const MenuCell = ({ colDef, row, value }) => {
  const { t } = useTranslation();
  const { state: adminGridState } = useContext(AdminGridContext);

  const { getPrivilegeStatus } = useProfile();

  const isUserPrivilegesManager = getPrivilegeStatus(PrivilegesMap.USER_PERMISSION_MANAGER);
  const isUserDataManager = getPrivilegeStatus(PrivilegesMap.USER_DATA_MANAGER);

  const dataManagersConfig = useMemo(
    () => [
      {
        text: t('Export data'),
        icon: ExportIcon,
        handler: () => colDef.actionsHandlers.export(value),
        gaLabel: 'Export data',
        id: `Export Data ${value}`,
      },
      {
        text: t('Delete data'),
        icon: DeleteIcon,
        handler: () => colDef.actionsHandlers.delete(value),
        gaLabel: 'Delete data',
        id: `Delete Data ${value}`,
      },
    ],
    [colDef.actionsHandlers, t, value],
  );

  const privilegesManagersConfig = useMemo(
    () => [
      {
        text: (
          <Box alignItems="center" display="flex">
            {t('Manage user rights')}
            <NewFeatureHotspot
              id={NewFeaturesIdsMap.USER_MANAGEMENT}
              isClickable
              label={t('Manage user rights')}
            />
          </Box>
        ),
        icon: ModerateIcon,
        gaLabel: 'Manage user rights',
        handler: () => colDef.actionsHandlers.managePrivileges(value),
        id: `Manage Privileges ${value}`,
      },
    ],
    [colDef.actionsHandlers, t, value],
  );

  const [menuActions, setMenuActions] = useState([]);

  useEffect(() => {
    let config = [];
    if (isUserDataManager) {
      config = [...config, ...dataManagersConfig];
    }
    if (
      isUserPrivilegesManager &&
      adminGridState.selectedUsersRole === RolesMap.TEACHER &&
      row.role?.toUpperCase() !== RolesMap.OTHER
    ) {
      config = [...config, ...privilegesManagersConfig];
    }
    setMenuActions(config);
  }, [
    adminGridState.selectedUsersRole,
    dataManagersConfig,
    isUserDataManager,
    isUserPrivilegesManager,
    privilegesManagersConfig,
    row.role,
  ]);

  return menuActions.length ? (
    <ActionsMenu id={value} menuItems={menuActions} paperClassName="ayo-admin-page__actions-menu" />
  ) : (
    <div />
  );
};

MenuCell.propTypes = {
  colDef: PropTypes.instanceOf(Object).isRequired,
  row: PropTypes.instanceOf(Object).isRequired,
  value: PropTypes.string.isRequired,
};

const SortedDescHeaderRenderer = (params) => {
  const { colDef } = params;
  return (
    <Typography className="ayo-data-grid-header__sorted--desc" variant="caption">
      {colDef.headerName} <SortedDescIcon />
    </Typography>
  );
};

const RolesContentMap = {
  [RolesMap.STUDENT]: {
    noResultsText: 'AYO couldn’t find any students having the name, email or ID',
    searchLabel: 'Search by name, email or ID',
    resetButtonText: 'Reset the search results to see all students',
  },
  [RolesMap.TEACHER]: {
    noResultsText: 'AYO couldn’t find any educators having the name, email or ID',
    searchLabel: 'Search by name, email or ID',
    resetButtonText: 'Reset the search results to see all educators',
  },
  [RolesMap.GUARDIAN]: {
    noResultsText: 'AYO couldn’t find any parents having the name or ID',
    searchLabel: 'Search by name or ID',
    resetButtonText: 'Reset the search results to see all parents',
  },
};

const NoResultsFound = ({ role, search, onResetClick }) => {
  const { t } = useTranslation();
  return (
    <Grid item sm={12}>
      <Box mb={4}>
        <Typography variant="body2">
          {t(RolesContentMap[role].noResultsText)} <b>{`"${search}"`}</b>.
        </Typography>
        <Typography variant="body2">
          {t('Please choose another user type or repeat your request')}.
        </Typography>
      </Box>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link component="button" onClick={onResetClick}>
        {t(RolesContentMap[role].resetButtonText)}
      </Link>
    </Grid>
  );
};

NoResultsFound.propTypes = {
  role: PropTypes.oneOf([RolesMap.STUDENT, RolesMap.TEACHER, RolesMap.GUARDIAN]).isRequired,
  search: PropTypes.string.isRequired,
  onResetClick: PropTypes.func.isRequired,
};

const transformUsersData = (usersData) =>
  usersData.map((user) => ({
    ...user,
    fullName: `${user.firstName}${user.middleName ? ` ${user.middleName}` : ''}${
      user.lastName ? ` ${user.lastName}` : ''
    }`,
    role: capitalize(user.role),
  }));

const AdminPage = () => {
  const { t } = useTranslation();
  const [usersList, setUsersList] = useState([]);
  const { getAdminUsersList, requestDataExport } = useAdminService();
  const [isSnackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarText, setSnackbarText] = useState('');
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isManagePrivilegesDialogOpen, setIsManagePrivilegesDialogOpen] = useState(false);
  const [activeUser, setActiveUser] = useState({});
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
  const history = useHistory();
  const { getPrivilegeStatus } = useProfile();

  const isUserPrivilegesManager = getPrivilegeStatus(PrivilegesMap.USER_PERMISSION_MANAGER);

  const filterOptions = useMemo(
    () => [
      { text: t('Students'), value: RolesMap.STUDENT },
      { text: t('Educators'), value: RolesMap.TEACHER },
      { text: t('Parents'), value: RolesMap.GUARDIAN },
    ],
    [t],
  );

  const PrivilegeGroupsTooltipTransformers = useMemo(
    () => ({
      [ContentModerationFilterCategory]: (values) => {
        const privilegesList = values.map((privilege) => t(`privileges.${privilege}`)).join(', ');
        return t('Content privileges', { privileges: privilegesList });
      },
      [FamilyFeedFilterCategory]: (values) => {
        const privilegesList = values
          .map((privilege) => t(`privileges.family_feed.short.${privilege}`))
          .join(', ');
        return t('Family feed privileges', {
          privileges: privilegesList,
          count: values.length,
        });
      },
      [ClubHubModerationFilterCategory]: () => t('moderate the clubs'),
    }),
    [t],
  );

  const RolesColumnDefinitionsBuilder = useMemo(
    () => ({
      [RolesMap.STUDENT]: (actionsHandlers) => [
        {
          field: 'fullName',
          headerName: t('Name'),
          flex: 0.35,
          renderHeader: SortedDescHeaderRenderer,
          searchable: true,
        },
        { field: 'email', headerName: t('Email'), flex: 0.35, searchable: true },
        { field: 'role', headerName: t('Role'), flex: 0.2 },
        { field: 'id', headerName: t('Action'), renderCell: MenuCell, flex: 0.1, actionsHandlers },
      ],
      [RolesMap.TEACHER]: (actionsHandlers) => [
        {
          field: 'fullName',
          headerName: t('Name'),
          flex: 0.35,
          renderHeader: SortedDescHeaderRenderer,
          tooltipCfg: isUserPrivilegesManager
            ? {
                converter: (value) => t(`privileges.${value}`),
                filter: (values) =>
                  values.filter(
                    (item) =>
                      !(
                        item === PrivilegesMap.USER_DATA_MANAGER ||
                        item === PrivilegesMap.USER_PERMISSION_MANAGER ||
                        item === PrivilegesMap.LESSON_PLANNER_CREATE_PUBLISH
                      ),
                  ),
                field: 'privileges',
                icon: ModerateIcon,
                text: 'The user has assigned rights to values',
                transform: (values) => {
                  if (!values) return null;
                  const privilegesEntries = Object.entries(EditablePrivilegesByGroupMap);
                  const groupedValues = values.reduce((acc, privilege) => {
                    const [privilegeGroup] =
                      privilegesEntries.find(([, privileges]) => privileges.includes(privilege)) ??
                      [];
                    return {
                      ...acc,
                      [privilegeGroup]: [...(acc[privilegeGroup] ?? []), privilege],
                    };
                  }, {});
                  const transformedGroups = Object.entries(groupedValues)
                    .map(([group, groupValues]) =>
                      PrivilegeGroupsTooltipTransformers[group]?.(groupValues),
                    )
                    .filter(Boolean);
                  return !!transformedGroups?.length && `${transformedGroups.join(', ')}.`;
                },
              }
            : null,
          searchable: true,
        },
        { field: 'email', headerName: t('Email'), flex: 0.35, searchable: true },
        { field: 'role', headerName: t('Role'), flex: 0.2 },
        { field: 'id', headerName: t('Action'), renderCell: MenuCell, flex: 0.1, actionsHandlers },
      ],
      [RolesMap.GUARDIAN]: (actionsHandlers) => [
        {
          field: 'fullName',
          headerName: t('Name'),
          flex: 0.35,
          renderHeader: SortedDescHeaderRenderer,
          searchable: true,
        },
        { field: 'loginId', headerName: t('ID'), flex: 0.35, searchable: true },
        { field: 'role', headerName: t('Role'), flex: 0.2 },
        { field: 'id', headerName: t('Action'), renderCell: MenuCell, flex: 0.1, actionsHandlers },
      ],
    }),
    [isUserPrivilegesManager, t, PrivilegeGroupsTooltipTransformers],
  );

  const [pageSize] = useState(12);
  const [totalRowCount, setTotalRowCount] = useState(0);

  const { state: adminGridState, dispatch: dispatchAdminGrid } = useContext(AdminGridContext);
  const { state: userState } = useContext(UserContext);

  const isAyoAdmin = userState.profile?.role === RolesMap.AYO_ADMIN;

  const snackbarhandleClose = () => {
    setSnackbarOpen(false);
  };
  const findActiveUser = useCallback(
    (userId) => usersList.find((user) => user.id === userId),
    [usersList],
  );

  const deleteDataHandler = useCallback(
    (userId) => {
      setActiveUser(findActiveUser(userId));
      setSnackbarOpen(false);
      setIsDeleteDialogOpen(true);
    },
    [findActiveUser],
  );

  const closeDeleteDialog = () => setIsDeleteDialogOpen(false);

  const exportDataHandler = useCallback(
    (userId) => {
      const person = findActiveUser(userId);
      setSnackbarOpen(false);
      history.push(`?${QueryParams.EXPORT_ACTION}`);
      requestDataExport(person.email, person.loginId, person.role)
        .then(() => {
          setSnackbarText(
            t(
              'You will be informed by email when the data export process is completed This process can take up to several days',
            ),
          );
          setSnackbarOpen(true);
        })
        .catch(({ response }) => {
          const processInProgress = response.data.errorCode;
          setSnackbarText(
            t(
              'The action of name data is already in progress You will be informed by email when it is complete',
              { action: t(AdminActionsStatusesMap[processInProgress]), name: person.firstName },
            ),
          );
          setSnackbarOpen(true);
        })
        .finally(() => {
          history.replace({ search: null });
        });
      setIsDeleteDialogOpen(false);
    },
    [findActiveUser, history, requestDataExport, t],
  );

  const managePrivilegesHandler = useCallback(
    (userId) => {
      setActiveUser(findActiveUser(userId));
      setIsManagePrivilegesDialogOpen(true);
    },
    [findActiveUser],
  );

  const closeManagePrivilegesDialog = () => setIsManagePrivilegesDialogOpen(false);

  const [selectedPage, setSelectedPage] = useState(1);

  const setSelectedUsersRole = (role) =>
    dispatchAdminGrid({ type: AdminGridActions.SET_SELECTED_USERS_ROLE, data: role });

  const setSearchParameters = (search) =>
    dispatchAdminGrid({ type: AdminGridActions.SET_SEARCH_PARAMETER, data: search });

  const isTeachersGrid = adminGridState.selectedUsersRole === RolesMap.TEACHER;

  const reloadUsersList = useCallback(() => {
    const search = new URLSearchParams(window.location.search);
    const filters = transformSearchToObject(search);
    const { filtersList } = isTeachersGrid ? getFilterValueParts(filters) : {};

    getAdminUsersList({
      page: selectedPage,
      pageSize,
      privileges: filtersList,
      usersRole: adminGridState.selectedUsersRole,
      search: adminGridState.searchParameter,
    }).then((usersListData) => {
      setUsersList(transformUsersData(usersListData.users));
      setTotalRowCount(usersListData.totalElements);
    });
  }, [
    adminGridState.searchParameter,
    adminGridState.selectedUsersRole,
    getAdminUsersList,
    isTeachersGrid,
    pageSize,
    selectedPage,
  ]);

  useEffect(() => {
    reloadUsersList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminGridState, selectedPage]);

  const onPageChange = (pageNumber) => {
    // This line is needed because onPageChange is also triggered by DataGrid on some initial configurations
    if (!pageNumber) return;
    setSelectedPage(pageNumber);
  };

  const onSearch = (value) => {
    setSearchParameters(value?.trim());
    setSelectedPage(1);
  };

  const searchApiRef = useRef();

  const onSelectedUserRoleChange = (e) => {
    setSelectedPage(1);
    searchApiRef.current.clearSearch();
    setSelectedUsersRole(e.target.value);
  };

  const selectedColumnDefinitions = useMemo(
    () =>
      RolesColumnDefinitionsBuilder[adminGridState.selectedUsersRole]({
        export: exportDataHandler,
        delete: deleteDataHandler,
        managePrivileges: managePrivilegesHandler,
      }),
    [
      adminGridState.selectedUsersRole,
      deleteDataHandler,
      exportDataHandler,
      managePrivilegesHandler,
      RolesColumnDefinitionsBuilder,
    ],
  );

  return (
    <PageWrapper noi18n={isAyoAdmin} noNav={isAyoAdmin}>
      <Container>
        {isWidthUpSm ? (
          <Grid
            alignItems="flex-start"
            className="ayo-admin-page"
            container
            justifyContent="flex-start"
          >
            <Grid item md={6} sm={12}>
              <Typography component="h1" paragraph variant="h1">
                {t('User management')}
              </Typography>
              <Typography isLabel paragraph variant="body2">
                {t('Some users may not be listed here - paragraph')}
              </Typography>
            </Grid>
            <Grid container item xs={12}>
              <Box className="ayo-admin-page__filters" mb={5} mt={3}>
                <RadioGroup
                  ariaLabel={t('Choose the role')}
                  onChange={onSelectedUserRoleChange}
                  options={filterOptions}
                  row
                  value={adminGridState.selectedUsersRole}
                />
                <Grid item md={4} sm={5}>
                  <SearchField
                    apiRef={searchApiRef}
                    gaLabel="Admin search"
                    label={t(RolesContentMap[adminGridState.selectedUsersRole].searchLabel)}
                    onSearch={onSearch}
                  />
                </Grid>
              </Box>
            </Grid>
            {adminGridState.searchParameter && !!totalRowCount && (
              <Grid item sm={12}>
                <Typography paragraph variant="body2">
                  <Trans
                    components={{ b: <b /> }}
                    i18nKey="search result text"
                    values={{ count: totalRowCount, searchValue: adminGridState.searchParameter }}
                  />
                </Typography>
              </Grid>
            )}
            {isUserPrivilegesManager && isTeachersGrid && (
              <Box mb={6}>
                <PopupFilters
                  filtersConfig={{
                    [ContentModerationFilterCategory]: {
                      name: `privileges.${ContentModerationFilterCategory}`,
                      FilterBox: ContentModerationPrivilegesFilters,
                      isDisabled: () => false,
                    },
                    [FamilyFeedFilterCategory]: {
                      name: 'Family feed',
                      FilterBox: FamilyFeedPrivilegesFilters,
                      isDisabled: () => false,
                    },
                    [ClubHubModerationFilterCategory]: {
                      name: `privileges.${ClubHubModerationFilterCategory}`,
                      FilterBox: ClubHubModerationPrivilegesFilters,
                      isDisabled: () => false,
                    },
                  }}
                  onFiltersUpdate={() => {
                    if (adminGridState.selectedPage !== 1) {
                      dispatchAdminGrid({ type: AdminGridActions.SET_SELECTED_PAGE, data: 1 });
                    } else {
                      reloadUsersList();
                    }
                  }}
                  stats={EditablePrivilegesByGroupMap}
                />
              </Box>
            )}
            {totalRowCount ? (
              <Grid className="ayo-admin-page__table" container item sm={12}>
                <DataGrid
                  columns={selectedColumnDefinitions}
                  disableColumnResize
                  hideFooterPagination={totalRowCount <= pageSize}
                  onPageChange={onPageChange}
                  rowCount={totalRowCount}
                  rows={usersList}
                  searchValue={adminGridState.searchParameter}
                  selectedPage={selectedPage}
                />
                <DeleteUserDataDialog
                  exportDataHandler={exportDataHandler}
                  isOpen={isDeleteDialogOpen}
                  onClose={closeDeleteDialog}
                  person={activeUser}
                  setSnackbarOpen={setSnackbarOpen}
                  setSnackbarText={setSnackbarText}
                />
                <ManageUserPrivilegesDialog
                  isOpen={isManagePrivilegesDialogOpen}
                  onClose={closeManagePrivilegesDialog}
                  onPrivilegesUpdate={reloadUsersList}
                  person={activeUser}
                />
                <Snackbar onClose={snackbarhandleClose} open={isSnackbarOpen} text={snackbarText} />
              </Grid>
            ) : adminGridState.searchParameter ? (
              <NoResultsFound
                onResetClick={searchApiRef.current?.clearSearch}
                role={adminGridState.selectedUsersRole}
                search={adminGridState.searchParameter}
              />
            ) : null}
          </Grid>
        ) : (
          <MobileNotSupported
            explanation={t(
              'Please open the administration section with a tablet or desktop version to use this functionality',
            )}
          />
        )}
      </Container>
    </PageWrapper>
  );
};

export default AdminPage;
