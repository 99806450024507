import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { DialogContent, DialogActions, Box } from '@mui/material';
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Dialog, DialogTitle, Typography } from '../components/atoms';
import { TourContext, TourActions, UserContext } from '../context';
import { useProfileService } from '../services';
import { EditingButton, InformationalCaption, NewFeatureHotspot } from '../components/moleculas';
import { format10NumToWords, GA } from '../utils';
import { GaActions, GaCategories } from '../constants/enums';
import { ReactComponent as EnvelopeIcon } from '../resources/icons/envelope.svg';

import NewFeaturesItemsProvider from './common/NewFeaturesItemsProvider';

const NewFeatureItem = ({ time, title, mainContent, shouldScroll, isSeen, onItemSeen, id }) => {
  const { ref: startRef, inView: startInView } = useInView({ threshold: 1 });
  const { ref: endRef, inView: endInView } = useInView({ threshold: 1 });
  const itemRef = useRef();
  const [wasStartSeen, setWasStartSeen] = useState(false);
  const [wasEndSeen, setWasEndSeen] = useState(false);

  useEffect(() => {
    if (startInView && !wasStartSeen) {
      setWasStartSeen(true);
    }
    if (endInView && !wasEndSeen) {
      setWasEndSeen(true);
    }
  }, [startInView, endInView, wasStartSeen, wasEndSeen]);

  useEffect(() => {
    if (wasStartSeen && wasEndSeen && !isSeen) {
      onItemSeen(id);
    }
  }, [wasStartSeen, wasEndSeen, onItemSeen, id, isSeen]);

  useEffect(() => {
    if (shouldScroll) {
      itemRef.current.scrollIntoView();
    }
  }, [shouldScroll]);

  return (
    <Box ref={itemRef} className="ayo-features-tour__new-feature-item" mb={5} tabIndex="0">
      <Box mb={1}>
        <Typography isLabel variant="caption">
          {time}
        </Typography>
      </Box>
      <Box alignItems="center" display="flex" flexDirection="row" mb={1}>
        <div ref={startRef} />
        <Typography variant="subtitle1">{title}</Typography>
        <NewFeatureHotspot id={id} />
      </Box>
      <Box>{mainContent}</Box>
      <div ref={endRef} />
    </Box>
  );
};

const NewFeaturesTour = () => {
  const tourContentRef = useRef();

  const { state: toursState, dispatch: dispatchTourState } = useContext(TourContext);

  const { postTourStatus } = useProfileService();

  const onItemSeen = useCallback(
    (id) => {
      dispatchTourState({ type: TourActions.ADD_SEEN_NEW_FEATURES, data: [id] });
      postTourStatus({
        ...toursState.toursStatus,
        'new-features': { seen: [...toursState.newFeaturesSeen, id] },
      });
    },
    [dispatchTourState, postTourStatus, toursState.newFeaturesSeen, toursState.toursStatus],
  );

  const NewFeatureTourItems = NewFeaturesItemsProvider().filter(Boolean);

  const updatesCount = useMemo(() => {
    let counter = 0;
    NewFeatureTourItems.forEach((item) => {
      if (!toursState.newFeaturesSeen || !toursState.newFeaturesSeen.find((x) => x === item.id)) {
        counter += 1;
      }
    });
    return counter;
  }, [NewFeatureTourItems, toursState.newFeaturesSeen]);

  const closeNewFeaturesTour = useCallback(() => {
    dispatchTourState({
      type: TourActions.SET_NEW_FEATURES_TOUR_STATE,
      data: { isNewFeaturesTourOpened: false, newFeaturesFocusId: null },
    });
  }, [dispatchTourState]);

  const shouldSendAllReadGA = useRef();

  const markAllAsRead = useCallback(() => {
    const newIDs = NewFeatureTourItems.reduce(
      (acc, feature) =>
        toursState.newFeaturesSeen.some((seen) => seen === feature.id) ? acc : [...acc, feature.id],
      [],
    );
    dispatchTourState({ type: TourActions.ADD_SEEN_NEW_FEATURES, data: newIDs });
    postTourStatus({
      ...toursState.toursStatus,
      'new-features': { seen: [...toursState.newFeaturesSeen, ...newIDs] },
    });
    GA.logInteraction({
      category: GaCategories.BEHAVIOR,
      action: GaActions.NEW_FEATURES_TOUR_MARK_ALL_AS_READ,
    });
    shouldSendAllReadGA.current = false;
  }, [
    NewFeatureTourItems,
    dispatchTourState,
    postTourStatus,
    toursState.newFeaturesSeen,
    toursState.toursStatus,
  ]);

  const { t } = useTranslation();

  useEffect(() => {
    if (NewFeatureTourItems.length > toursState.newFeaturesSeen.length) {
      shouldSendAllReadGA.current = true;
    }
    if (
      NewFeatureTourItems.length === toursState.newFeaturesSeen.length &&
      shouldSendAllReadGA.current
    ) {
      GA.logInteraction({
        category: GaCategories.BEHAVIOR,
        action: GaActions.NEW_FEATURES_TOUR_ALL_READ,
        label: 'New features dialog',
      });
    }
  }, [NewFeatureTourItems.length, toursState.newFeaturesSeen.length]);

  const { state: userState } = useContext(UserContext);

  const { isGuardianWithoutStudentConsent } = userState;

  const additionalInformationalText = isGuardianWithoutStudentConsent
    ? 'Guardian without student consent NFT info'
    : null;

  return (
    <Dialog
      className="ayo-features-tour"
      onClose={closeNewFeaturesTour}
      open={!!toursState.isNewFeaturesTourOpened}
    >
      <DialogTitle disableTypography>
        <Box mb={5}>
          <Box mb={1}>
            <Typography variant="h2">{t("What's new")}</Typography>
          </Box>
          <Typography isLabel mb={additionalInformationalText ? 3 : 0} variant="body2">
            {updatesCount
              ? t(`You have number recent update${updatesCount > 1 ? 's' : ''}`, {
                  number: format10NumToWords(updatesCount, t),
                })
              : t('No recent items - you are all up-to-date')}
          </Typography>
          {additionalInformationalText && (
            <InformationalCaption title={additionalInformationalText} />
          )}
        </Box>
      </DialogTitle>
      <DialogContent ref={tourContentRef}>
        {NewFeatureTourItems.map((newFeatureItem, idx) => (
          <NewFeatureItem
            key={newFeatureItem.id}
            id={newFeatureItem.id}
            isLast={idx === NewFeatureTourItems.length - 1}
            isSeen={toursState.newFeaturesSeen.some((item) => item === newFeatureItem.id)}
            mainContent={newFeatureItem.mainContent}
            onItemSeen={onItemSeen}
            shouldScroll={toursState.newFeaturesFocusId === newFeatureItem.id}
            time={newFeatureItem.time}
            title={newFeatureItem.title}
          />
        ))}
      </DialogContent>
      {updatesCount ? (
        <DialogActions>
          <Box mt={5}>
            <EditingButton
              icon={<EnvelopeIcon />}
              iconPosition="end"
              onClick={markAllAsRead}
              text={t('Mark all as read')}
            />
          </Box>
        </DialogActions>
      ) : null}
    </Dialog>
  );
};

NewFeatureItem.propTypes = {
  time: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  mainContent: PropTypes.node.isRequired,
  shouldScroll: PropTypes.bool,
  isSeen: PropTypes.bool,
  onItemSeen: PropTypes.func,
  id: PropTypes.string.isRequired,
};

NewFeatureItem.defaultProps = {
  shouldScroll: false,
  isSeen: false,
  onItemSeen: () => {},
};

export default NewFeaturesTour;
