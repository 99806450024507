import { ReactComponent as StudentsIcon } from '../resources/icons/students.svg';
import { ReactComponent as MyKidsImage } from '../resources/icons/my_kids.svg';
import { ReactComponent as TrainAyoIcon } from '../resources/icons/vector.svg';
import { ReactComponent as MyFeedIcon } from '../resources/icons/my_feed.svg';
import { ReactComponent as MyPortfolio } from '../resources/icons/my_portfolio.svg';
import { ReactComponent as MyProfile } from '../resources/icons/my_profile.svg';
import { ReactComponent as AnalyticsIcon } from '../resources/icons/analytics.svg';
import { ReactComponent as ContentIcon } from '../resources/icons/content.svg';
import { ReactComponent as UsersIcon } from '../resources/icons/users.svg';
import { ReactComponent as MyLessonsIcon } from '../resources/icons/my_lessons.svg';
import { ReactComponent as MyClassesIcon } from '../resources/icons/date_picker_black.svg';
import { ReactComponent as ClubHubIcon } from '../resources/icons/club_hub.svg';

import { RolesMap, PrivilegesMap } from './enums';
import { AppRoutes } from './routes';

export const DropdownNavItemValues = {
  ADMINISTRATION: 'ADMINISTRATION',
};

const TEACHER_PAGES = [
  {
    text: 'Administration',
    id: 'nav-drawer-family-feed-administration',
    value: DropdownNavItemValues.ADMINISTRATION,
    subItems: [
      {
        icon: StudentsIcon,
        text: 'My students',
        value: AppRoutes.STUDENTS_LIST,
        id: 'nav-drawer-my-students',
      },
    ],
  },
  {
    icon: MyLessonsIcon,
    text: 'My lessons',
    value: AppRoutes.MY_LESSONS,
    id: 'nav-drawer-my-lessons',
    privileges: [PrivilegesMap.LESSON_PLANNER_CREATE_PUBLISH],
  },
  {
    icon: MyKidsImage,
    text: 'Family feed',
    value: AppRoutes.FAMILY_FEED_CREATION_CENTER,
    id: 'nav-drawer-family-feed-creation-center',
    privileges: [
      PrivilegesMap.FAMILY_FEED_CREATOR_CAMPUS,
      PrivilegesMap.FAMILY_FEED_CREATOR_DISTRICT,
      PrivilegesMap.FAMILY_FEED_CREATOR_CLASS,
      PrivilegesMap.FAMILY_FEED_CREATOR_PERSONALIZED,
    ],
  },
  {
    icon: ClubHubIcon,
    text: 'Club Hub',
    value: AppRoutes.CLUB_HUB,
    id: 'nav-drawer-club-hub',
  },
  {
    icon: ContentIcon,
    privileges: [
      PrivilegesMap.CONTENT_MODERATION_EDIT_APPROVE,
      PrivilegesMap.CONTENT_MODERATION_SHOW_HIDE,
    ],
    text: 'Content',
    value: AppRoutes.CONTENT,
    id: 'nav-drawer-content',
  },
  {
    icon: TrainAyoIcon,
    text: 'Train AYO',
    value: AppRoutes.TRAIN_AYO,
    id: 'nav-drawer-train-ayo',
  },
];

const TEACHER_SECONDARY_PAGES = [
  {
    icon: MyProfile,
    text: 'My profile',
    value: AppRoutes.MY_PROFILE_TEACHER,
    id: 'nav-drawer-my-profile-teacher',
  },
];

const PARENT_PAGES = [
  {
    icon: MyKidsImage,
    text: 'Family feed',
    value: AppRoutes.FAMILY_FEED,
    id: 'nav-drawer-my-kids',
  },
  {
    icon: TrainAyoIcon,
    text: 'Train AYO',
    value: AppRoutes.TRAIN_AYO,
    id: 'nav-drawer-train-ayo',
  },
];

const STUDENT_PAGES = [
  {
    icon: MyProfile,
    text: 'My profile',
    value: AppRoutes.MY_PROFILE,
    id: 'nav-drawer-my-profile',
  },
  {
    icon: MyPortfolio,
    text: 'My portfolio',
    value: AppRoutes.PORTFOLIOS,
    id: 'nav-drawer-my-portfolio',
  },
  {
    icon: MyClassesIcon,
    text: 'My classes',
    value: AppRoutes.MY_CLASSES,
    id: 'nav-drawer-my-classes',
  },
  {
    icon: MyFeedIcon,
    text: 'My feed',
    value: AppRoutes.FAMILY_FEED_MY_FEED,
    id: 'nav-drawer-my-feed',
  },
  {
    icon: ClubHubIcon,
    text: 'Club Hub',
    value: AppRoutes.CLUB_HUB,
    id: 'nav-drawer-club-hub',
  },
  {
    icon: TrainAyoIcon,
    text: 'Train AYO',
    value: AppRoutes.TRAIN_AYO,
    id: 'nav-drawer-train-ayo',
  },
];

const ADMINISTRATOR_PAGES = [
  {
    text: 'Administration',
    id: 'nav-drawer-family-feed-administration',
    value: DropdownNavItemValues.ADMINISTRATION,
    subItems: [
      {
        icon: UsersIcon,
        text: 'Users',
        value: AppRoutes.ADMIN,
        id: 'nav-drawer-users',
        privileges: [PrivilegesMap.USER_DATA_MANAGER, PrivilegesMap.USER_PERMISSION_MANAGER],
      },
      {
        icon: AnalyticsIcon,
        text: 'Analytics',
        value: AppRoutes.ANALYTICS,
        id: 'nav-drawer-analytics',
      },
      {
        icon: StudentsIcon,
        text: 'Students',
        value: AppRoutes.STUDENTS,
        id: 'nav-drawer-students',
      },
    ],
  },
  {
    icon: MyKidsImage,
    text: 'Family feed',
    value: AppRoutes.FAMILY_FEED_CREATION_CENTER,
    id: 'nav-drawer-family-feed-creation-center',
    privileges: [
      PrivilegesMap.FAMILY_FEED_CREATOR_CAMPUS,
      PrivilegesMap.FAMILY_FEED_CREATOR_DISTRICT,
      PrivilegesMap.FAMILY_FEED_CREATOR_CLASS,
      PrivilegesMap.FAMILY_FEED_CREATOR_PERSONALIZED,
    ],
  },
  {
    icon: ClubHubIcon,
    text: 'Club Hub',
    value: AppRoutes.CLUB_HUB,
    id: 'nav-drawer-club-hub',
  },
  {
    icon: ContentIcon,
    privileges: [
      PrivilegesMap.CONTENT_MODERATION_EDIT_APPROVE,
      PrivilegesMap.CONTENT_MODERATION_SHOW_HIDE,
    ],
    text: 'Content',
    value: AppRoutes.CONTENT,
    id: 'nav-drawer-content',
  },
  {
    icon: TrainAyoIcon,
    text: 'Train AYO',
    value: AppRoutes.TRAIN_AYO,
    id: 'nav-drawer-train-ayo',
  },
];

const ADMINISTRATOR_SECONDARY_PAGES = [
  {
    icon: MyProfile,
    text: 'My profile',
    value: AppRoutes.MY_PROFILE_TEACHER,
    id: 'nav-drawer-my-profile-teacher',
  },
];

export const NavItemsMap = {
  [RolesMap.TEACHER]: { main: TEACHER_PAGES, secondary: TEACHER_SECONDARY_PAGES },
  [RolesMap.GUARDIAN]: { main: PARENT_PAGES },
  [RolesMap.STUDENT]: { main: STUDENT_PAGES },
  [RolesMap.ADMINISTRATOR]: { main: ADMINISTRATOR_PAGES, secondary: ADMINISTRATOR_SECONDARY_PAGES },
};

export const RolesMainPages = {
  [RolesMap.TEACHER]: AppRoutes.STUDENTS_LIST,
  [RolesMap.OTHER]: AppRoutes.OTHER_ROLE,
  [RolesMap.GUARDIAN]: AppRoutes.FAMILY_FEED,
  [RolesMap.STUDENT]: AppRoutes.MY_PROFILE,
  [RolesMap.AYO_ADMIN]: AppRoutes.ADMIN,
  [RolesMap.ADMINISTRATOR]: AppRoutes.ANALYTICS,
};

export default { NavItemsMap, RolesMainPages };
