import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Typography } from '../../../../../atoms';
import { AccordionItem } from '../../../../../moleculas';
import { ReactComponent as LeadershipMaturityLeadingSelf } from '../../../../../../resources/images/leadership_maturity_level_leading_self.svg';
import { ReactComponent as LeadershipMaturityLeadingOthers } from '../../../../../../resources/images/leadership_maturity_level_leading_others.svg';
import { ReactComponent as LeadershipMaturityLeadingChange } from '../../../../../../resources/images/leadership_maturity_level_leading_change.svg';
import MaturityLevelRubric from '../maturity-level-rubric/MaturityLevelRubric';

export const MaturityLevelImageMap = {
  LEADING_SELF: {
    image: LeadershipMaturityLeadingSelf,
    alt: 'A sketch of two circles in a frame',
  },
  LEADING_OTHERS: {
    image: LeadershipMaturityLeadingOthers,
    alt: 'A sketch of two gray masks in a frame',
  },
  LEADING_CHANGE: {
    image: LeadershipMaturityLeadingChange,
    alt: 'A picture of one red and one green theater masks in a frame',
  },
};

const MaturityLevelsBox = ({
  accordionIndex,
  attributeChangeHandler,
  maturityLevels,
  leadershipAttributeGroupKey,
  title,
  goals,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const MaturityLevelImage = MaturityLevelImageMap[title].image;

  if (!maturityLevels.length) {
    return null;
  }

  return (
    <Box mb={5}>
      <AccordionItem
        bodyEl={
          <Box alignItems="center" display="flex" justifyContent="space-between">
            <Grid item lg={9}>
              {maturityLevels.map((maturityLevel) => (
                <MaturityLevelRubric
                  key={maturityLevel.id}
                  attributeChangeHandler={attributeChangeHandler}
                  goals={goals}
                  leadershipAttributeGroupKey={leadershipAttributeGroupKey}
                  maturityLevel={maturityLevel}
                  title={title}
                />
              ))}
            </Grid>
            {isWidthUpSm && (
              <Box mt={-6} pr={6}>
                <MaturityLevelImage aria-label={t(MaturityLevelImageMap[title].alt)} role="img" />
              </Box>
            )}
          </Box>
        }
        className="ayo-maturity-levels-box__accordion"
        gaLabel="Maturity box item"
        headerEl={
          <Typography component="h3" variant="subtitle2">
            {t(title)}
          </Typography>
        }
        isDefaultExpanded={accordionIndex === 0}
      />
    </Box>
  );
};

MaturityLevelsBox.propTypes = {
  accordionIndex: PropTypes.number.isRequired,
  attributeChangeHandler: PropTypes.func.isRequired,
  maturityLevels: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      groupKey: PropTypes.string,
      completed: PropTypes.bool,
    }),
  ),
  goals: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      leadershipAttributeGroupId: PropTypes.number,
      status: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  title: PropTypes.string.isRequired,
  leadershipAttributeGroupKey: PropTypes.string.isRequired,
};

MaturityLevelsBox.defaultProps = {
  maturityLevels: [],
  goals: [],
};

export default MaturityLevelsBox;
