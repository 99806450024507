/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

import { InformationalCaption } from '../../../moleculas';
import { HighlightsInitiatives, SingleBarVariants } from '../../../../constants/enums';
import { MOOD_DIDNT_SHARE, MoodColors } from '../../../../constants/moods';
import { useEmotionalLearning } from '../../../../utils';
import SingleBarShape from '../components/single-bar-shape/SingleBarShape';
import HighlightsBarChart from '../components/highlights-bar-chart/HighlightsBarChart';

const MoodBar = ({ payload, ...props }) => {
  const { getMoodColor } = useEmotionalLearning();

  return <SingleBarShape {...props} forcedFill={getMoodColor(payload?.name)} payload={payload} />;
};

MoodBar.propTypes = {
  payload: PropTypes.shape({ name: PropTypes.string }),
};

MoodBar.defaultProps = {
  payload: null,
};

const MoodsHighlights = ({ stats, totalStudentsCount, onFiltersUpdate }) => {
  const { moodsSorter } = useEmotionalLearning();

  return (
    <Box display="flex" height={350}>
      <InformationalCaption
        isLight
        showTooltip
        title="Moods Highlights info"
        tooltipPlacement="right"
      />
      <HighlightsBarChart
        gaLabel="Moods Highlights Graph"
        initiative={HighlightsInitiatives.MOODS}
        isRadioSelection
        MainBarShape={MoodBar}
        mainBarVariant={SingleBarVariants.TIGHT}
        mainStatsSorting={(a, b) => moodsSorter(a, b, 'name')}
        onFiltersUpdate={onFiltersUpdate}
        pendingChartColor={MoodColors.PENDING}
        pendingKey={MOOD_DIDNT_SHARE}
        stats={stats}
        totalStudentsCount={totalStudentsCount}
      />
    </Box>
  );
};

MoodsHighlights.propTypes = {
  stats: PropTypes.instanceOf(Object).isRequired,
  totalStudentsCount: PropTypes.number.isRequired,
  onFiltersUpdate: PropTypes.func.isRequired,
};

export default MoodsHighlights;
