import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { Box, Container } from '@mui/material';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import { PageWrapper } from '../../../../organisms';
import { getFormattedDate, isCurrentYear } from '../../../../../utils';
import { useLessonsService } from '../../../../../services';
import { useLessonPlannerData } from '../../../../../hooks';
import { LessonPageBlocksConfig } from '../../../../../constants/lesson-planner';
import { myClassesRoute } from '../../../../../constants/routes';
import AssignmentRenderer from '../../assignment-components/assignment-renderer/AssignmentRenderer';
import LessonPageAgendaBoard from '../components/lesson-page-agenda-board/LessonPageAgendaBoard';
import LessonPageSection from '../components/lesson-page-section/LessonPageSection';

const LessonPageStudent = ({ Header, redirectTo }) => {
  const { i18n } = useTranslation();
  const {
    params: { lessonId },
  } = useRouteMatch();
  const { getLessonById, getLessonAssignments, getAssignmentChoices, postChoicesSelected } =
    useLessonsService();
  const { assignmentsSorter } = useLessonPlannerData();

  const lessonIdNumber = useMemo(() => {
    const converted = parseInt(lessonId, 10);

    return Number.isSafeInteger(converted) ? converted : lessonId;
  }, [lessonId]);

  const [lessonData, setLessonData] = useState(null);
  const [assignments, setAssignments] = useState([]);

  const formattedDate = useMemo(() => {
    const date = dayjs(lessonData?.classMetadata.classDate);
    const formatted = getFormattedDate(date.toDate(), i18n.language, {
      day: 'numeric',
      weekday: 'short',
      month: 'long',
    });

    return isCurrentYear(date) ? formatted : `${formatted}, ${date.get('year')}`;
  }, [i18n.language, lessonData?.classMetadata.classDate]);

  const [choices, setChoices] = useState({});

  const onChoicesChange = (assignmentId) => (newChoices) => {
    if (assignmentId) {
      setChoices({ ...choices, [assignmentId]: newChoices });
    }
  };

  useEffect(() => {
    if (lessonIdNumber) {
      getLessonById(lessonIdNumber).then((data) => {
        setLessonData(data);
      });

      getLessonAssignments(lessonIdNumber).then((assignmentsData) => {
        const assignmentsIds = assignmentsData.map((assignment) => assignment.id);
        Promise.all(
          assignmentsIds.map((assignmentId) => getAssignmentChoices(lessonIdNumber, assignmentId)),
        ).then((choicesData) => {
          setChoices(
            choicesData.reduce(
              (acc, choice, idx) => ({ ...acc, [assignmentsIds[idx]]: choice }),
              {},
            ),
          );
          setAssignments(assignmentsData.sort(assignmentsSorter));
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const choicesSelectedPostingCreator = (assignmentId) => (choicesId, selectedInfo) =>
    postChoicesSelected(lessonIdNumber, assignmentId, choicesId, selectedInfo);

  return (
    <PageWrapper
      className="ayo-lesson-page"
      customHeader={
        <Header backToLink={redirectTo ?? myClassesRoute} isStudent lessonData={lessonData} />
      }
      mainElementClassName="ayo-lesson-page__main"
      noNav
    >
      {!!lessonData && (
        <Container>
          <Box mb={10}>
            {lessonData.agendaBoardData.isVisible && (
              <LessonPageSection
                isStatic
                sectionConfig={{
                  content: [
                    {
                      body: (
                        <Box id={LessonPageBlocksConfig.agendaBoard.key.id}>
                          <LessonPageAgendaBoard
                            isStatic
                            lessonId={lessonId}
                            metaData={{
                              classDate: formattedDate,
                              title: lessonData.title,
                            }}
                            showFullScreenButton={false}
                            values={lessonData.agendaBoardData}
                          />
                        </Box>
                      ),
                      isVisible: true,
                    },
                  ],
                }}
                title={LessonPageBlocksConfig.agendaBoard.key.text}
              />
            )}
            {!!assignments.length && (
              <LessonPageSection
                isStatic
                sectionConfig={{
                  content: [
                    {
                      body: (
                        <Box id={LessonPageBlocksConfig.assignments.key.id}>
                          {assignments.map((assignment, index) => (
                            <Box key={assignment.id || assignment.tempId} mb={5}>
                              <AssignmentRenderer
                                assignment={assignment}
                                canSelectChoices
                                choices={choices[assignment.id]}
                                hideAssignedStudents
                                isFirst={!index}
                                isLast={index === assignments.length - 1}
                                isStatic
                                onChoicesChange={onChoicesChange(assignment.id)}
                                onChoicesSelectedChange={choicesSelectedPostingCreator(
                                  assignment.id,
                                )}
                              />
                            </Box>
                          ))}
                        </Box>
                      ),
                      isVisible: true,
                    },
                  ],
                }}
                title={LessonPageBlocksConfig.assignments.key.text}
              />
            )}
          </Box>
        </Container>
      )}
    </PageWrapper>
  );
};

LessonPageStudent.propTypes = {
  Header: PropTypes.node.isRequired,
  redirectTo: PropTypes.string,
};

LessonPageStudent.defaultProps = {
  redirectTo: null,
};

export default LessonPageStudent;
