import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { Alert, Button, Typography } from '../../../../../atoms';
import { getRelativeDateTime } from '../../../../../../utils';
import { UserContext } from '../../../../../../context';
import { RolesMap } from '../../../../../../constants/enums';

const NegativeTrendAlert = ({
  acknowledgeHandler,
  daysCount,
  emailSendDate,
  icon,
  isAcknowledged,
  onClose,
  studentName,
}) => {
  const { i18n, t } = useTranslation();
  const dateLabel = getRelativeDateTime(emailSendDate, i18n.language, t);
  const { state: userState } = useContext(UserContext);

  const showAcknowledgeButton =
    !isAcknowledged && userState.profile?.role === RolesMap.ADMINISTRATOR;

  return (
    <Alert
      action={
        showAcknowledgeButton ? (
          <Button gaLabel="Acknowledge" onClick={acknowledgeHandler} variant="text">
            {t('Acknowledge')}
          </Button>
        ) : null
      }
      body={
        !showAcknowledgeButton && (
          <Typography variant="caption">
            {!isAcknowledged
              ? `${t(
                  dateLabel.includes(t('Today')) || dateLabel.includes(t('Yesterday'))
                    ? 'A counselor was notified'
                    : 'A counselor was notified on',
                )} ${dateLabel}`
              : t('Acknowledged by the counselor')}
          </Typography>
        )
      }
      className={classnames('ayo-negative-trend-alert', {
        action: showAcknowledgeButton,
      })}
      gaLabel="Negative trend alert"
      icon={icon}
      onClose={onClose}
      open
      title={
        <Typography variant="caption">
          <Trans
            components={{ b: <b /> }}
            i18nKey="Alerting trend! COUNT days of negative mood"
            values={{ daysCount, studentName }}
          />
        </Typography>
      }
    />
  );
};

NegativeTrendAlert.propTypes = {
  acknowledgeHandler: PropTypes.func,
  daysCount: PropTypes.number,
  emailSendDate: PropTypes.string,
  icon: PropTypes.node,
  isAcknowledged: PropTypes.bool,
  onClose: PropTypes.func,
  studentName: PropTypes.string,
};

NegativeTrendAlert.defaultProps = {
  acknowledgeHandler: null,
  daysCount: null,
  emailSendDate: '',
  icon: null,
  isAcknowledged: false,
  onClose: () => {},
  studentName: '',
};

export default NegativeTrendAlert;
