/* eslint-disable import/no-extraneous-dependencies */
import React, { useCallback, useContext, useEffect, useState, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import Quill from 'quill';
import ReactQuill from 'react-quill-with-table';
import 'react-quill-with-table/dist/quill.snow.css';
import ImageResize from 'quill-image-resize';

import { Typography } from '../../atoms';
import { FileLinkRemovableItem } from '../../moleculas';
import { AppActions, AppContext, UserContext } from '../../../context';
import { GaActions, GaCategories, KeyboardMap } from '../../../constants/enums';
import { apiRoute } from '../../../constants/routes';
import { byteToMegaByteCoeff } from '../../../constants/values';
import { useAttachmentsService, useLinksService } from '../../../services';
import { formatUrl, GA, getFileErrors } from '../../../utils';

import AddEditTablePopover from './components/AddEditTablePopover';

Quill.register('modules/imageResize', ImageResize);

const Link = Quill.import('formats/link');
Link.sanitize = formatUrl;

const buttonLabelsMap = {
  '.ql-bold': { propertyName: 'aria-label', text: 'Bold' },
  '.ql-italic': { propertyName: 'aria-label', text: 'Italic' },
  '.ql-underline': { propertyName: 'aria-label', text: 'Underline' },
  '.ql-align[value]': { propertyName: 'aria-label', text: 'Align left' },
  '.ql-align[value=center]': { propertyName: 'aria-label', text: 'Align center' },
  '.ql-align[value=right]': { propertyName: 'aria-label', text: 'Align right' },
  '.ql-align[value=justify]': { propertyName: 'aria-label', text: 'Align justify' },
  '.ql-list[value=bullet]': { propertyName: 'aria-label', text: 'Bullets' },
  '.ql-list[value=ordered]': { propertyName: 'aria-label', text: 'Numbering' },
  '.ql-link': { propertyName: 'aria-label', text: 'Add hyperlink' },
  '.ql-image': { propertyName: 'aria-label', text: 'Add image' },
  '.ql-table': { propertyName: 'title', text: 'Adding, formatting or deleting tables' },
};

const allToolbarItems = {
  headers: [{ header: [1, 2, 3, 4, false] }],
  styling: ['bold', 'italic', 'underline'],
  alignment: [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
  lists: [{ list: 'bullet' }, { list: 'ordered' }],
  links: ['link'],
  images: ['image'],
  tables: ['table'],
};

const ALLOWED_EXTENSIONS = ['jpg', 'jpeg', 'png', 'gif'];
const MAX_FILE_SIZE = 5 * byteToMegaByteCoeff;

const RichTextEditor = ({
  attachments,
  bounds,
  errorMsg,
  defaultFormat,
  formattingOptions,
  isStatic,
  links,
  onBeforeImageUpload,
  onChange,
  ownerId,
  placeholder,
  resourceId,
  resourceType,
  value,
  withLinksExtraction,
  editorRef,
  linkSaveCallback,
}) => {
  const { t } = useTranslation();
  const ref = useRef();
  const fileInputRef = useRef();
  const origSaveFnRef = useRef();
  const [tablePopupAnchorEl, setTablePopupAnchorEl] = useState();
  const [isTableEditing, setIsTableEditing] = useState(false);
  const { dispatch: dispatchAppState } = useContext(AppContext);
  const { state: userState } = useContext(UserContext);

  const { postLinks } = useLinksService();
  const { patchChunk, postChunk } = useAttachmentsService();

  /*
   * Before formatting we need to check in which context we currently are.
   * For example, inside tables we are not supporting all format options, thus returning if this condition is true
   */
  const conditionalFormatHandler = useCallback((format, val) => {
    const tableModule = ref.current?.getEditor().getModule('table');
    if (tableModule) {
      const [table] = tableModule.getTable();
      if (table) {
        return;
      }
    }
    ref.current.getEditor().format(format, val);
  }, []);

  const imageHandler = useCallback(() => {
    if (onBeforeImageUpload) {
      onBeforeImageUpload();
    }
    fileInputRef.current.click();
  }, [onBeforeImageUpload]);

  const tableHandler = useCallback((val) => {
    setIsTableEditing(!val);
    setTablePopupAnchorEl(ref.current.editingArea.parentNode.querySelector('.ql-table'));
  }, []);

  const toolbarItems = useMemo(
    () => formattingOptions.map((key) => allToolbarItems[key]),
    [formattingOptions],
  );

  const editorModules = useMemo(
    () => ({
      toolbar: {
        container: toolbarItems,
        handlers: {
          header: (val) => {
            conditionalFormatHandler('header', val);
          },
          list: (val) => {
            conditionalFormatHandler('list', val);
          },
          image: imageHandler,
          table: tableHandler,
        },
      },
      table: true,
      imageResize: !isStatic
        ? {
            modules: ['Resize', 'DisplaySize'],
          }
        : null,
    }),
    [conditionalFormatHandler, imageHandler, tableHandler, toolbarItems, isStatic],
  );

  const removeLink = useCallback(
    (url) => {
      onChange?.(
        'links',
        links.filter((x) => x.url !== url),
      );
      dispatchAppState({
        type: AppActions.SET_SNACKBAR_STATUS,
        data: { text: t('The link has been removed'), type: 'delete' },
      });
    },
    [dispatchAppState, links, onChange, t],
  );

  const handleFileInputChange = useCallback(
    (e) => {
      const file = e.target.files[0];
      const errors = getFileErrors(
        file,
        { allowedExtensions: ALLOWED_EXTENSIONS, maxFileSize: MAX_FILE_SIZE },
        t,
      );
      if (!errors.length) {
        const fileName = file.name;
        const fileSize = file.size;
        const onFail = () => {
          dispatchAppState({
            type: AppActions.SET_SNACKBAR_STATUS,
            data: {
              text: t('AYO couldn’t upload the file Please try once more'),
              type: 'error',
            },
          });
        };
        const postChunkRequestBody = { fileName, fileSize };
        if (ownerId) {
          postChunkRequestBody.ownerId = ownerId;
        }
        postChunk(postChunkRequestBody, true)
          .then(({ id }) => {
            const patchChunkRequestBody = {
              chunk: file,
              range: `0-${fileSize}`,
              resourceId,
              resourceType,
            };
            if (ownerId) {
              patchChunkRequestBody.ownerId = ownerId;
            }
            patchChunk(id, patchChunkRequestBody, true)
              .then((response) => {
                const { attachmentId, updatedDate } = response;
                const range = ref.current.getEditorSelection();
                const url = `${apiRoute}/attachments/${attachmentId}/owners/${
                  ownerId || userState.profile?.id
                }`;
                ref.current.getEditor().insertEmbed(range?.index, 'image', url);
                ref.current
                  .getEditor()
                  .setSelection(range?.index + 1 || 1, 0, Quill.sources.SILENT);
                onChange?.('attachments', [
                  ...attachments,
                  {
                    id: attachmentId,
                    fileName,
                    ownerId: ownerId || userState.profile?.id,
                    updatedDate,
                  },
                ]);
                dispatchAppState({
                  type: AppActions.SET_SNACKBAR_STATUS,
                  data: {
                    text: t('The file has been successfully uploaded'),
                    type: 'success',
                  },
                });
              })
              .catch(onFail);
          })
          .catch(onFail);
      } else {
        dispatchAppState({
          type: AppActions.SET_SNACKBAR_STATUS,
          data: {
            text: `${errors[0]}.`,
            type: 'error',
          },
        });
      }
      fileInputRef.current.value = null;
    },
    [
      attachments,
      dispatchAppState,
      onChange,
      ownerId,
      patchChunk,
      postChunk,
      resourceId,
      resourceType,
      t,
      userState.profile?.id,
    ],
  );

  /*
   * After closing Table popover, the Editor will re-render.
   * We need to restore previous cursor position (if it was set) within table
   */
  const handleCloseAndRestoreSelection = useCallback(() => {
    const editor = ref.current.getEditor();
    const range = editor.getSelection();
    setTablePopupAnchorEl(null);
    if (range) {
      setTimeout(() => {
        editor.setSelection(range.index, range.length, Quill.sources.SILENT);
      }, 0);
    }
  }, []);

  const showErrorSnackbar = useCallback(() => {
    dispatchAppState({
      type: AppActions.SET_SNACKBAR_STATUS,
      data: {
        text: t('AYO couldn’t extract the link Please check if it’s working and try once more'),
        type: 'error',
      },
    });
  }, [dispatchAppState, t]);

  const getHeadingLabel = useCallback(
    (val) =>
      val ? `${t('richTextEditorLabels.Heading')} ${val}` : t('richTextEditorLabels.Normal'),
    [t],
  );

  /*
   * Override translation of selected value in the Heading dropdown.
   * We are using data-text as an only option to set custom text
   */
  const overrideHeadersDropdownValue = useCallback(() => {
    setTimeout(() => {
      const labelEl = ref.current?.editingArea.parentNode.querySelector('.ql-picker-label');
      const labelVal = labelEl?.getAttribute('data-value');
      labelEl?.setAttribute('data-text', getHeadingLabel(labelVal));
    }, 100);
  }, [getHeadingLabel]);

  /*
   * Override translations for Heading dropdown options.
   * We are using data-text as an only option to set custom text
   */
  const overrideHeadersDropdownOptions = useCallback(() => {
    ref.current.editingArea.parentNode
      .querySelector('.ql-picker-options')
      ?.childNodes.forEach((optionEl) => {
        const optionVal = optionEl.getAttribute('data-value');
        optionEl.setAttribute('data-text', getHeadingLabel(optionVal));
      });
  }, [getHeadingLabel]);

  const overrideButtonsAriaAndGALabels = useCallback(() => {
    Object.entries(buttonLabelsMap).forEach(([key, val]) => {
      const buttonEl = ref.current.editingArea.parentNode.querySelector(key);
      buttonEl?.setAttribute(val.propertyName, t(`richTextEditorLabels.${val.text}`));
      buttonEl?.addEventListener('click', () =>
        GA.logInteraction({
          category: GaCategories.BEHAVIOR,
          action: GaActions.BUTTON_CLICK,
          label: val.text,
        }),
      );
    });
  }, [t]);

  /*
   * Override translations for Add Link tooltip.
   * We are using data-text as an only option to set custom text
   */
  const overrideLinkTooltipLabels = useCallback(() => {
    const { tooltip } = ref.current.getEditor().theme;
    const actionBtn = tooltip.root.querySelector('.ql-action');
    const removeBtn = tooltip.root.querySelector('.ql-remove');
    tooltip.textbox.setAttribute('aria-label', t('Enter link'));
    tooltip.textbox.removeAttribute('data-link');
    tooltip.textbox.removeAttribute('placeholder');
    tooltip.root.setAttribute('data-text-edit', t('Enter link'));
    tooltip.root.setAttribute('data-text', t('Visit URL'));
    actionBtn?.setAttribute('tabindex', 0);
    actionBtn?.setAttribute('data-text-edit', t('Save'));
    actionBtn?.setAttribute('data-text', t('Edit'));
    removeBtn?.setAttribute('tabindex', 0);
    removeBtn?.setAttribute('data-text', t('Remove'));
  }, [t]);

  /*
   * Override default Quill handlers.
   * We are dropping TAB button Quill support and rely on browser implementation.
   * For Add Link we are extending default Save handler with additional processing of the inserted link
   */
  const overrideEditorHandlers = useCallback(() => {
    const editor = ref.current.getEditor();
    delete editor.getModule('keyboard').bindings[KeyboardMap.TAB];
    if (withLinksExtraction || linkSaveCallback) {
      const { tooltip } = editor.theme;
      if (!origSaveFnRef.current) {
        origSaveFnRef.current = tooltip.save;
      }
      tooltip.save = linkSaveCallback
        ? linkSaveCallback(origSaveFnRef.current, tooltip)
        : () => {
            const text = editor.getText(editor.selection.savedRange);
            const url = tooltip.textbox.value;
            if (!links.find((x) => x.url === url)) {
              const formattedUrl = formatUrl(url);

              postLinks([formattedUrl])
                .then((metaDataArray) => {
                  const metaData = metaDataArray?.[0];
                  if (metaData) {
                    origSaveFnRef.current?.apply(tooltip);
                    onChange?.('links', [
                      ...links,
                      {
                        createdDate: new Date().toISOString(),
                        metaData: {
                          description: metaData.description,
                          linkType: metaData.linkType,
                        },
                        text,
                        url: metaData.url,
                      },
                    ]);
                  } else {
                    showErrorSnackbar();
                  }
                })
                .catch(() => {
                  showErrorSnackbar();
                });
            } else {
              origSaveFnRef.current?.apply(tooltip);
            }
          };
    }
  }, [links, onChange, postLinks, showErrorSnackbar, withLinksExtraction, linkSaveCallback]);

  /*
   * While in table context we are visually disabling not supported formats
   */
  const disableButtonsInTableMode = useCallback(() => {
    const editorEl = ref.current?.editingArea.parentNode;
    const tableModule = ref.current?.getEditor().getModule('table');
    if (!tableModule || !editorEl) {
      return;
    }
    const [table] = tableModule.getTable();
    if (table) {
      editorEl.querySelectorAll('.ql-list').forEach((el) => el.setAttribute('disabled', true));
      editorEl.querySelector('.ql-picker-label').setAttribute('disabled', true);
    } else {
      editorEl.querySelectorAll('.ql-list').forEach((el) => el.removeAttribute('disabled'));
      editorEl.querySelector('.ql-picker-label')?.removeAttribute('disabled');
    }
  }, []);

  useEffect(() => {
    const editorEl = ref.current?.editingArea.parentNode;
    if (editorEl) {
      overrideButtonsAriaAndGALabels();
      overrideHeadersDropdownOptions();
      overrideLinkTooltipLabels();
    }
  }, [
    attachments.length,
    isStatic,
    overrideButtonsAriaAndGALabels,
    overrideHeadersDropdownOptions,
    overrideLinkTooltipLabels,
  ]);

  useEffect(() => {
    const editorEl = ref.current?.editingArea.parentNode;
    if (editorEl) {
      overrideEditorHandlers();
    }
  }, [isStatic, overrideEditorHandlers]);

  return (
    <>
      {isStatic ? (
        <ReactQuill
          className="ayo-rich-text-editor ayo-rich-text-editor--static"
          modules={editorModules}
          readOnly
          theme="snow"
          value={JSON.parse(value)}
        />
      ) : (
        <ReactQuill
          ref={(node) => {
            if (editorRef) {
              // eslint-disable-next-line no-param-reassign
              editorRef.current = node?.getEditor();
            }
            ref.current = node;
          }}
          bounds={bounds}
          className={classNames('ayo-rich-text-editor', {
            'ayo-rich-text-editor--w-error': errorMsg,
          })}
          modules={editorModules}
          onBlur={() => {
            GA.logInteraction({
              category: GaCategories.BEHAVIOR,
              action: GaActions.INPUT_FINISH_TYPING,
              label: 'Text editor',
            });
          }}
          onChange={(content, delta, source, editor) => {
            const data = JSON.stringify(editor.getContents());
            if (data !== value) {
              onChange?.('textJson', data, editor.getText());
            }

            const updatedAttachments = attachments.reduce(
              (acc, attachment) =>
                data.indexOf(attachment.id) !== -1 ? [...acc, attachment] : acc,
              [],
            );
            if (updatedAttachments.length !== attachments.length) {
              onChange?.('attachments', updatedAttachments);
            }
          }}
          onChangeSelection={() => {
            overrideHeadersDropdownValue();
            disableButtonsInTableMode();
          }}
          onFocus={(range, source, editor) => {
            if (defaultFormat && !range.index && !range.length && editor.getLength() === 1) {
              ref.current?.getEditor().format(defaultFormat, 'true');
            }
          }}
          placeholder={placeholder}
          readOnly={false}
          theme="snow"
          value={JSON.parse(value)}
        />
      )}
      {errorMsg && (
        <Typography className="error-msg" variant="body3">
          {errorMsg}
        </Typography>
      )}
      {links.length > 0 && (
        <Box
          className="ayo-rich-text-editor__links"
          mt={isStatic ? 0 : 3}
          pb={3}
          px={isStatic ? 0 : 3}
        >
          <Typography variant="subtitle2">{t('Links extracted')}</Typography>
          {links.map((link) => (
            <FileLinkRemovableItem
              key={link.url}
              createdLabel={t('Extracted')}
              isStatic={isStatic}
              item={link}
              onRemoveClick={() => removeLink(link.url)}
              type="link"
            />
          ))}
        </Box>
      )}
      <AddEditTablePopover
        anchorEl={tablePopupAnchorEl}
        editorRef={ref}
        isEditing={isTableEditing}
        onClose={handleCloseAndRestoreSelection}
      />
      {/* TODO: Extract basic FileUpload component into atoms
          as it starts to be duplicating between
          FileUpload, RichTextEditor, LessonPageMaterialsContent */}
      <input
        ref={fileInputRef}
        accept={ALLOWED_EXTENSIONS.join(', ')}
        multiple
        onChange={handleFileInputChange}
        style={{ display: 'none' }}
        type="file"
      />
    </>
  );
};

RichTextEditor.propTypes = {
  attachments: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  bounds: PropTypes.string,
  defaultFormat: PropTypes.string,
  errorMsg: PropTypes.string,
  formattingOptions: PropTypes.arrayOf(PropTypes.string),
  isStatic: PropTypes.bool,
  links: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  onBeforeImageUpload: PropTypes.func,
  onChange: PropTypes.func,
  ownerId: PropTypes.number,
  placeholder: PropTypes.string,
  resourceId: PropTypes.string,
  resourceType: PropTypes.string,
  value: PropTypes.string,
  withLinksExtraction: PropTypes.bool,
  editorRef: PropTypes.shape({ current: PropTypes.instanceOf(Object) }),
  linkSaveCallback: PropTypes.func,
};

RichTextEditor.defaultProps = {
  attachments: [],
  bounds: null,
  defaultFormat: '',
  errorMsg: '',
  formattingOptions: ['headers', 'styling', 'alignment', 'lists', 'links', 'images', 'tables'],
  isStatic: false,
  links: [],
  onBeforeImageUpload: null,
  onChange: null,
  ownerId: null,
  placeholder: '',
  resourceId: null,
  resourceType: '',
  value: null,
  withLinksExtraction: false,
  editorRef: null,
  linkSaveCallback: null,
};

export default RichTextEditor;
