import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import EditableChip from '../chips/editable-chip/EditableChip';
import { ChipDisplayModes } from '../../../constants/enums';

const ModesLabels = {
  [ChipDisplayModes.DELETE]: 'Delete extracurricular from some year extracurriculars',
};

const StudentExtracurricularItem = ({ extracurricular, mode, onClick, yearLabel }) => {
  const { t } = useTranslation();
  return (
    <Box display="inline-block" maxWidth="100%" pb={1} pr={1}>
      <EditableChip
        ariaLabel={t(ModesLabels[mode], { extracurricularName: extracurricular, year: yearLabel })}
        label={extracurricular}
        mode={mode}
        onClick={onClick}
        onDelete={mode === ChipDisplayModes.DELETE ? onClick : undefined}
      />
    </Box>
  );
};

StudentExtracurricularItem.propTypes = {
  extracurricular: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(Object.values(ChipDisplayModes)),
  onClick: PropTypes.func,
  yearLabel: PropTypes.string,
};

StudentExtracurricularItem.defaultProps = {
  mode: null,
  onClick: undefined,
  yearLabel: null,
};

export default StudentExtracurricularItem;
