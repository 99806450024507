/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { ReactComponent as Lightbulb } from '../../../../resources/icons/lightbulb.svg';
import Chip from '../chip/Chip';

const MlChip = React.forwardRef(({ ariaLabel, className, text, ...rest }, ref) => (
  <Chip
    ref={ref}
    ariaLabel={ariaLabel}
    className={classnames('ayo-ml-input-chip', className)}
    icon={<Lightbulb />}
    isRtl
    label={text}
    {...rest}
  />
));

MlChip.propTypes = {
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
  text: PropTypes.string,
};

MlChip.defaultProps = {
  ariaLabel: null,
  className: '',
  text: 'AYO',
};

export default MlChip;
