import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';

import { Typography } from '../../../../atoms';
import { TimelineChip } from '../../../../moleculas';
import { ReactComponent as EndorsementsTimeLineDotActive } from '../../../../../resources/images/timeline_dot.svg';
import { ReactComponent as EndorsementsTimeLineDotInactive } from '../../../../../resources/images/timeline_dot_light.svg';
import EndorsementCard from '../endorsement-card/EndorsementCard';
import { getSortedAndTrimmedEndorsements } from '../../../../../constants/endorsements';

const MAX_ENDORSEMENT_LENGTH = 3;

const SelectedEndorsements = ({
  endorsements,
  isStudent,
  isReadOnlyMode,
  deleteReflectionHandler,
  setActiveEndorsementConfig,
  setIsDialogOpen,
}) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  const getMonthYearString = (date) =>
    new Date(date).toLocaleDateString(i18n.language, {
      month: 'short',
      year: 'numeric',
    });

  const endorsementsToDisplay = useMemo(
    () => getSortedAndTrimmedEndorsements(endorsements, 'createdDate', MAX_ENDORSEMENT_LENGTH),
    [endorsements],
  );

  return (
    <Box>
      <Box className="ayo-endorsements__grid-block">
        <TimelineChip
          className="ayo-endorsements__label"
          title={t(isStudent ? 'My current' : 'Current')}
        />
        <Grid container direction="row" justify="flex-start" spacing={isWidthUpSm ? 3 : 8} xs={12}>
          {endorsementsToDisplay.map((item, i) => (
            <>
              <Grid
                key={item.id}
                className={`${classNames('ayo-endorsements__grid-item', {
                  'last-item': i === endorsementsToDisplay.length - 1,
                })}`}
                item
                md={4}
                sm={4}
                xs={12}
              >
                <Box
                  className={`${classNames('ayo-endorsements__timeline', {
                    'last-item': i === endorsementsToDisplay.length - 1,
                  })}`}
                  mb={isWidthUpSm ? 2 : 0}
                >
                  <Typography variant="caption">
                    {`${
                      i !== 0
                        ? `${getMonthYearString(item.createdDate)} - ${getMonthYearString(
                            endorsementsToDisplay[i - 1]?.createdDate,
                          )}`
                        : `${getMonthYearString(item.createdDate)} - ${t('Current')}`
                    }`}
                  </Typography>
                  {i === 0 ? (
                    <EndorsementsTimeLineDotActive className="ayo-endorsements__timeline-dot" />
                  ) : (
                    <EndorsementsTimeLineDotInactive className="ayo-endorsements__timeline-dot--inactive" />
                  )}
                </Box>
                <EndorsementCard
                  endorsement={item}
                  isActive={i === 0}
                  isReadOnlyMode={isReadOnlyMode}
                  isStudent={isStudent}
                  onDeleteReason={deleteReflectionHandler}
                  setActiveEndorsementConfig={setActiveEndorsementConfig}
                  setIsDialogOpen={setIsDialogOpen}
                />
              </Grid>
            </>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

SelectedEndorsements.propTypes = {
  endorsements: PropTypes.instanceOf(Object).isRequired,
  isStudent: PropTypes.bool.isRequired,
  isReadOnlyMode: PropTypes.bool,
  deleteReflectionHandler: PropTypes.func.isRequired,
  setActiveEndorsementConfig: PropTypes.func.isRequired,
  setIsDialogOpen: PropTypes.func.isRequired,
};

SelectedEndorsements.defaultProps = {
  isReadOnlyMode: false,
};

export default SelectedEndorsements;
