import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as WarningIcon } from '../../../../../resources/icons/warning.svg';
import { Typography } from '../../../../atoms';

const UnavailableStudentsMessage = () => {
  const { t } = useTranslation();
  return (
    <Box alignItems="center" display="flex" gap={2}>
      <WarningIcon />
      <Typography isLabel variant="body2">
        {t('The list of students is not available to you here.')}
      </Typography>
    </Box>
  );
};
export default UnavailableStudentsMessage;
