import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '../../../../../atoms';

const DisplayAssignmentTitle = ({ title }) => (
  <Typography paragraph variant="h2">
    {title}
  </Typography>
);

DisplayAssignmentTitle.propTypes = {
  title: PropTypes.string,
};

DisplayAssignmentTitle.defaultProps = {
  title: 'Title',
};

export default DisplayAssignmentTitle;
