import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

import { Typography, BubbleCircles } from '../../atoms';
import { InterestTypes } from '../../../constants/enums';

const UPPERTEXT_HEIGHT = 18;
const UPPERTEXT_MARGIN = 8;

const InterestBubbleFigure = ({
  circle,
  ariaLabel,
  buffRadiusIncrease,
  onClick,
  tabIndex,
  id,
  role,
  onKeyDown,
  withUpperText,
  withComment,
  commentPosition,
  commentSize,
}) => (
  <g
    key={circle.id}
    aria-label={ariaLabel}
    className={`interest-figure interest-${circle.interestType}`}
    id={id}
    onClick={onClick}
    onKeyDown={onKeyDown}
    role={role}
    tabIndex={tabIndex}
  >
    <defs>
      <linearGradient
        gradientUnits="objectBoundingBox"
        id="primary_gradient_0"
        x1="0.5"
        x2="0.5"
        y1="0"
        y2="1"
      >
        <stop stopColor="#6065A8" />
        <stop offset="1" stopColor="#5C43A2" stopOpacity="0.84" />
      </linearGradient>
      <linearGradient
        gradientUnits="objectBoundingBox"
        id="primary_gradient_1"
        x1="0.7"
        x2="0.2"
        y1="0"
        y2="1"
      >
        <stop stopColor="#8E3078" stopOpacity="0.41" />
        <stop offset="1" stopOpacity="0.15" />
      </linearGradient>
      <linearGradient
        gradientUnits="objectBoundingBox"
        id="secondary_gradient"
        x1="0"
        x2="1"
        y1="0"
        y2="0"
      >
        <stop stopColor="#F6D365" />
        <stop offset="1" stopColor="#FDA085" />
      </linearGradient>
    </defs>
    <BubbleCircles
      buffRadiusIncrease={buffRadiusIncrease}
      buffStrokeWidth={3}
      circle={circle}
      commentPosition={commentPosition}
      commentSize={commentSize}
      withComment={withComment}
    />
    {!withUpperText && (
      <foreignObject
        height={circle.r * 2}
        width={circle.r * 2}
        x={circle.x - circle.r}
        y={circle.y - circle.r}
      >
        <Box alignItems="center" display="flex" height="100%" justifyContent="center" width="100%">
          <Typography align="center" className="innertext" component="p" variant="body3">
            {circle.name}
          </Typography>
        </Box>
      </foreignObject>
    )}
    {circle.interestType === InterestTypes.SUGGESTED && withUpperText && (
      <foreignObject
        className="uppertext-object"
        height={UPPERTEXT_HEIGHT}
        width={circle.r * 2}
        x={circle.x}
        y={circle.y - circle.r - UPPERTEXT_HEIGHT - UPPERTEXT_MARGIN}
      >
        <Box
          alignItems="center"
          className="uppertext-container"
          display="flex"
          height="100%"
          justifyContent="center"
          width="100%"
        >
          <Typography align="center" component="p" title={circle.name} variant="subtitle2">
            {circle.name}
          </Typography>
        </Box>
      </foreignObject>
    )}
  </g>
);

InterestBubbleFigure.propTypes = {
  circle: PropTypes.shape({
    name: PropTypes.string,
    reaction: PropTypes.number,
    x: PropTypes.number,
    y: PropTypes.number,
    r: PropTypes.number,
    interestType: PropTypes.oneOf(Object.values(InterestTypes)),
    id: PropTypes.number,
  }).isRequired,
  ariaLabel: PropTypes.string,
  buffRadiusIncrease: PropTypes.number,
  onClick: PropTypes.func,
  tabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  role: PropTypes.string,
  onKeyDown: PropTypes.func,
  withUpperText: PropTypes.bool,
  withComment: PropTypes.bool,
  commentPosition: PropTypes.string,
  commentSize: PropTypes.number,
};

InterestBubbleFigure.defaultProps = {
  ariaLabel: null,
  buffRadiusIncrease: 16,
  onClick: undefined,
  tabIndex: null,
  id: null,
  role: null,
  onKeyDown: undefined,
  withUpperText: false,
  withComment: false,
  commentPosition: 'top-right',
  commentSize: 24,
};

export default InterestBubbleFigure;
