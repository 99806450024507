/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Tooltip as MUITooltip, Box, ClickAwayListener } from '@mui/material';

import Button from '../button/Button';
import { ReactComponent as CloseIcon } from '../../../resources/icons/close_dark.svg';

const Tooltip = ({
  children,
  className,
  isDisabled,
  variant,
  title,
  onClose,
  onClickAway,
  enterTouchDelay,
  leaveTouchDelay,
  ...props
}) => {
  const { t } = useTranslation();

  const titleWithCloseButton = (
    <Box alignItems="flex-start" display="flex" flexDirection="row">
      {title}
      {onClose && (
        <Box pl={3}>
          <Button
            aria-label={t('Close')}
            autoFocus
            className="ayo-tooltip__close-button"
            onClick={onClose}
          >
            <CloseIcon />
          </Button>
        </Box>
      )}
    </Box>
  );

  const mainTooltipBody = (
    <MUITooltip
      classes={{
        tooltip: classnames('ayo-tooltip', `ayo-tooltip--${variant}`, className),
        popper: classnames('ayo-tooltip-popper', `ayo-tooltip-popper--${variant}`),
      }}
      disableFocusListener={isDisabled}
      disableHoverListener={isDisabled}
      disableTouchListener={isDisabled}
      enterTouchDelay={enterTouchDelay}
      leaveTouchDelay={leaveTouchDelay}
      PopperProps={{
        disablePortal: onClose,
      }}
      title={onClose ? titleWithCloseButton : title}
      {...props}
    >
      {children}
    </MUITooltip>
  );

  return onClickAway ? (
    <ClickAwayListener onClickAway={onClickAway}>
      <span>{mainTooltipBody}</span>
    </ClickAwayListener>
  ) : (
    mainTooltipBody
  );
};

Tooltip.propTypes = {
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  variant: PropTypes.oneOf(['light', 'dark']),
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  onClose: PropTypes.func,
  onClickAway: PropTypes.func,
  enterTouchDelay: PropTypes.number,
  leaveTouchDelay: PropTypes.number,
};

Tooltip.defaultProps = {
  className: '',
  isDisabled: false,
  variant: 'dark',
  onClickAway: null,
  onClose: null,
  enterTouchDelay: 0,
  leaveTouchDelay: 5000,
};

export default Tooltip;
