import React from 'react';
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Card, Typography } from '../../../atoms';
import { GA } from '../../../../utils';
import { mainYoutubeRoute } from '../../../../constants/routes';
import { GaActions, GaCategories } from '../../../../constants/enums';
import { ReactComponent as LinkIcon } from '../../../../resources/icons/link.svg';

const YoutubeCard = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const onCardClick = () => {
    GA.logInteraction({
      category: GaCategories.BEHAVIOR,
      action: GaActions.LINK_CLICK,
      label: 'YouTube link',
    });
    window.open(mainYoutubeRoute, '_blank');
  };

  return (
    <Grid
      className="ayo-youtube-card"
      container
      item
      justifyContent={isWidthUpSm ? 'center' : 'flex-start'}
      md={3}
      sm={4}
      xs={9}
    >
      <Card
        className="ayo-clickable-card"
        component="button"
        mainContent={
          <Box display="flex" flexDirection="column">
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Typography component="p" variant="subtitle2">
                {t('AYO is on YouTube!')}
              </Typography>
              <Box className="ayo-youtube__link-icon">
                <LinkIcon />
              </Box>
            </Box>
            <Box className="ayo-youtube-card__caption">
              <Typography variant="caption">
                {t('Go and check out the content that we prepared for you!')}
              </Typography>
            </Box>
          </Box>
        }
        onClick={onCardClick}
      />
    </Grid>
  );
};

export default YoutubeCard;
