import React from 'react';
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { Button } from '../../atoms';
import { myProfileRoute } from '../../../constants/routes';
import { ReactComponent as GoToMyProfileIllustration } from '../../../resources/images/go_to_my_profile_illustration.svg';

const BackToStudentProfileBlock = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Grid alignItems="center" container direction="column" item xs={12}>
      <Box mb={3}>
        <GoToMyProfileIllustration
          aria-label={t('A kid asking "What will the results look like on my profile?"')}
          role="img"
        />
      </Box>
      <Box mb={isWidthUpSm ? 10 : 5}>
        <Button
          component={RouterLink}
          gaLabel="Go to My profile"
          id="my-profile-button"
          to={myProfileRoute}
          variant="secondary"
        >
          {t('Go to My profile')}
        </Button>
      </Box>
    </Grid>
  );
};

export default BackToStudentProfileBlock;
