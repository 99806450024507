import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Box, DialogContent, useTheme, useMediaQuery, DialogActions } from '@mui/material';

import { Button, Dialog, DialogTitle, Typography } from '../../../atoms';
import { AccordionItem } from '../../../moleculas';
import EvidenceOfGrowthDetails from '../../evidence-of-growth/evidence-of-growth-details/EvidenceOfGrowthDetails';
import { SharedDataContext } from '../../../../context';
import { getFormattedDate } from '../../../../utils';
import { goalActionStepItem, goalDetailsItem } from '../../../../constants/propTypes';
import { AttachmentsResourcesTypes } from '../../../../constants/enums';
import { ReactComponent as FolderIcon } from '../../../../resources/icons/folder.svg';
import { ReactComponent as MilestoneIcon } from '../../../../resources/icons/date_picker_black.svg';
import { ReactComponent as NotesIcon } from '../../../../resources/icons/description.svg';

export const ActionStepReflectionViewDialog = ({ actionStep, goalDetails, isOpen, onClose }) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const isWidthUpXl = useMediaQuery(theme.breakpoints.up('xl'));
  const { state: sharedDataState } = useContext(SharedDataContext);
  return (
    <Dialog
      className="ayo-action-step-reflection-view-dialog"
      fullScreen={!isWidthUpSm}
      gaLabel="View action step details"
      maxWidth={isWidthUpXl && 700}
      onClose={onClose}
      open={isOpen}
    >
      <DialogTitle disableTypography>
        <Box mb={5}>
          <Typography component="h2" variant="h2">
            {t('View action step details')}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box mb={5}>
          <Typography component="h3" variant="subtitle1">
            {actionStep.name}
          </Typography>
          <Box mb={1} mt={1}>
            <Box display="flex" flexDirection="row">
              <Box mr={1.5}>
                <MilestoneIcon />
              </Box>
              <Box display="flex" flexDirection="row">
                <Typography align="left" component="p" noWrap variant="subtitle2">
                  {t('Deadline')}:
                </Typography>
                <Box ml={1}>
                  <Typography component="p" variant="body2">
                    {getFormattedDate(actionStep.dueDate, i18n.languages)}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box pb={2} pt={3}>
              <Box mb={2}>
                <Typography
                  align="left"
                  className="ayo-goal-milestone-card__deadline"
                  component="p"
                  noWrap
                  variant="subtitle2"
                >
                  <FolderIcon />
                  {t('Evidence of growth')}
                </Typography>
              </Box>
              {actionStep.evidenceIds?.length ? (
                actionStep.evidenceIds.map((evidenceId) => {
                  const evidence = sharedDataState.evidencesOfGrowth.find(
                    (el) => el.id === evidenceId,
                  );
                  return (
                    <Box key={evidenceId}>
                      <AccordionItem
                        bodyEl={
                          <EvidenceOfGrowthDetails
                            evidenceOfGrowth={evidence}
                            evidenceType={AttachmentsResourcesTypes.GOAL}
                            goalDetails={goalDetails}
                            variant="accordion"
                          />
                        }
                        gaLabel="Action step evidence of growth"
                        headerEl={
                          <Typography noWrap variant="subtitle2">
                            {evidence?.title}
                          </Typography>
                        }
                      />
                    </Box>
                  );
                })
              ) : (
                <Typography align="left" component="p" noWrap variant="body2">
                  {t('No evidence of growth provided so far')}
                </Typography>
              )}
            </Box>
            <Box display="flex" flexDirection="row" mt={3}>
              <Box mr={1.5}>
                <NotesIcon />
              </Box>
              <Typography align="left" component="p" noWrap variant="subtitle2">
                {t('Reflection')}
              </Typography>
            </Box>
          </Box>
          {actionStep.reflection ? (
            <Typography component="p" variant="body2">
              {actionStep.reflection}
            </Typography>
          ) : (
            <Typography align="left" component="p" noWrap variant="body2">
              {t('No reflection provided so far')}
            </Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Box minWidth={isWidthUpSm ? '60%' : '80%'}>
          <Button fullWidth gaLabel="Close" onClick={onClose} variant="primary">
            {t('Close')}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

ActionStepReflectionViewDialog.propTypes = {
  actionStep: goalActionStepItem.isRequired,
  goalDetails: goalDetailsItem.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ActionStepReflectionViewDialog;
