/* eslint-disable react/jsx-filename-extension */
import React, { useContext, useCallback, useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { Button } from '../../components/atoms';
import { useReflectionsService, useStudentsService } from '../../services';
import { UserContext } from '../../context';
import {
  LanguagesMap,
  PortfolioSectionSettings,
  PortfolioSectionTypes,
  PortfolioSectionsAnimations,
  PublicationStatuses,
  ReflectionTypes,
  RolesMap,
} from '../../constants/enums';
import useLeadershipAttributesData from '../use-leadership-attributes-data/useLeadershipAttributesData';
import useSnackbar from '../use-snackbar/useSnackbar';
import {
  mergeArrayOfObjectsHelper,
  getNormalizedLanguageCode,
  findCompletedLevels,
  converterBooleanString,
  transformInterestsMetaData,
  transformCertificateId,
  getPortfolioMetadata,
  getOrderedSectionsByVisibility,
} from '../../utils';
import { portfoliosRoute } from '../../constants/routes';
import { ReactComponent as CreateIcon } from '../../resources/icons/create.svg';
import { ReactComponent as HideIcon } from '../../resources/icons/hide.svg';
import { ReactComponent as DeleteIcon } from '../../resources/icons/delete_primary.svg';

let isLoading = false;

const PortfolioEntitiesIdsTypes = {
  PORTFOLIO__INTERESTS: 'id',
  PORTFOLIO__APTITUDES: 'type',
  PORTFOLIO__ENDORSEMENTS: 'id',
  PORTFOLIO__EXTRACURRICULARS: 'name',
  PORTFOLIO__ACHIEVEMENTS: 'name',
  PORTFOLIO__LEADERSHIP_ATTRIBUTES: 'key',
  PORTFOLIO__CERTIFICATES: 'id',
  PORTFOLIO__EMPLOYMENT: 'id',
  PORTFOLIO__ESSAYS: 'id',
  PORTFOLIO__ACADEMIC_RECORD: 'type',
  PORTFOLIO__RECOMMENDATIONS: 'id',
  PORTFOLIO__TEST_SCORES: 'examName',
  PORTFOLIO__VOLUNTEERING: 'id',
};

const transformPortfolioRequestBody = (portfolioRequestBody, sectionName) => ({
  ...portfolioRequestBody,
  entities: portfolioRequestBody.entities.map((entity) => JSON.stringify(entity)),
  entitiesIds: portfolioRequestBody.entities.map((entity) =>
    sectionName === PortfolioSectionTypes.PORTFOLIO__CERTIFICATES
      ? transformCertificateId(entity)
      : entity[PortfolioEntitiesIdsTypes[sectionName]]?.toString(),
  ),
});

const transformPortfolioRequestMetaData = (metaData) => ({
  ...metaData,
  [PortfolioSectionTypes.PORTFOLIO__CERTIFICATES]: [],
  [PortfolioSectionTypes.PORTFOLIO__EMPLOYMENT]: [],
  [PortfolioSectionTypes.PORTFOLIO__ESSAYS]: [],
  [PortfolioSectionTypes.PORTFOLIO__RECOMMENDATIONS]: [],
  [PortfolioSectionTypes.PORTFOLIO__VOLUNTEERING]: [],
  [PortfolioSectionTypes.PORTFOLIO__PERSONAL_INFO]: [],
});

const getIsSectionHasData = (key, data, selectedEntities) => {
  switch (key) {
    case PortfolioSectionTypes.PORTFOLIO__PERSONAL_INFO:
      return true;
    case PortfolioSectionTypes.PORTFOLIO__INTERESTS:
      return selectedEntities.length || transformInterestsMetaData(data).length;
    case PortfolioSectionTypes.PORTFOLIO__LEADERSHIP_ATTRIBUTES:
      return selectedEntities.length || findCompletedLevels(data);
    case PortfolioSectionTypes.PORTFOLIO__ENDORSEMENTS:
      return selectedEntities.length || data.endorsements.length;
    case PortfolioSectionTypes.PORTFOLIO__CERTIFICATES:
      return (
        selectedEntities.length ||
        data.suggestedCertificates.length ||
        data.submittedCertificates.length
      );
    default:
      return selectedEntities.length || data.length;
  }
};

const LanguageDependentSections = [
  PortfolioSectionTypes.PORTFOLIO__INTERESTS,
  PortfolioSectionTypes.PORTFOLIO__ENDORSEMENTS,
];

const usePortfolioData = () => {
  const { i18n, t } = useTranslation();
  const match = useRouteMatch();
  const history = useHistory();

  const {
    getStudentInterests,
    getStudentAptitudes,
    getStudentExtracurriculars,
    getStudentAchievements,
    getStudentEndorsements,
    getStudentPortfolio,
    createStudentPortfolio,
    updateStudentPortfolio,
    updateStudentPortfolioSettings,
    getStudentCertificates,
    getStudentEmployment,
    getStudentEssays,
    getStudentRecommendations,
    getStudentVolunteering,
    getAllStudentPortfolios,
    deleteStudentPortfolio,
    getStudentTeachers,
    getStudentRecommendationsRequests,
    getStudentTestScores,
    getStudentAcademicRecord,
    patchStudentPortfolio,
  } = useStudentsService();

  const { postReflection, deleteReflection, putReflection } = useReflectionsService();

  const { state: userState } = useContext(UserContext);

  const [portfolioMetaData, setPortfolioMetaData] = useState({});
  const [studentPortfolio, setStudentPortfolio] = useState();
  const [isEditMode, setIsEditMode] = useState(false);
  const [allStudentPortfolios, setAllStudentPortfolios] = useState(null);
  const [studentTeachers, setStudentTeachers] = useState([]);
  const [sectionsToAnimate, setSectionsToAnimate] = useState([]);
  const [recommendationsRequests, setRecommendationsRequests] = useState([]);
  const studentId = match.params.studentId || userState?.profile.id;
  const { urlPortfolioId } = match.params;
  const { graphData, leadershipAttributes } = useLeadershipAttributesData(studentId);
  const isStudent = userState.profile.role === RolesMap.STUDENT;
  const isEducator =
    userState.profile?.role === RolesMap.ADMINISTRATOR ||
    userState.profile?.role === RolesMap.TEACHER;

  const { setSnackBarStatus } = useSnackbar();

  const portfolioSettings = useMemo(
    () =>
      studentPortfolio &&
      Object.entries(studentPortfolio.sections)
        .map(([key, value]) => ({
          sectionName: key,
          sectionSettings: value?.sectionSettings?.filter(
            ({ name }) => name === PortfolioSectionSettings.DISPLAY_SECTION.type,
          ),
        }))
        .sort((a, b) => a.sectionName.localeCompare(b.sectionName)),
    [studentPortfolio],
  );

  const downloadHandlersMap = useMemo(
    () => ({
      PORTFOLIO__INTERESTS: () =>
        getStudentInterests(studentId, getNormalizedLanguageCode(i18n.language), !isLoading).then(
          (data) => data,
        ),
      PORTFOLIO__APTITUDES: getStudentAptitudes,
      PORTFOLIO__ENDORSEMENTS: getStudentEndorsements,
      PORTFOLIO__EXTRACURRICULARS: getStudentExtracurriculars,
      PORTFOLIO__ACHIEVEMENTS: getStudentAchievements,
      PORTFOLIO__CERTIFICATES: getStudentCertificates,
      PORTFOLIO__EMPLOYMENT: getStudentEmployment,
      PORTFOLIO__ESSAYS: getStudentEssays,
      PORTFOLIO__ACADEMIC_RECORD: getStudentAcademicRecord,
      PORTFOLIO__RECOMMENDATIONS: getStudentRecommendations,
      PORTFOLIO__TEST_SCORES: getStudentTestScores,
      PORTFOLIO__VOLUNTEERING: getStudentVolunteering,
    }),
    [
      getStudentAptitudes,
      getStudentEndorsements,
      getStudentExtracurriculars,
      getStudentAchievements,
      getStudentCertificates,
      getStudentEmployment,
      getStudentAcademicRecord,
      getStudentEssays,
      getStudentRecommendations,
      getStudentTestScores,
      getStudentVolunteering,
      getStudentInterests,
      studentId,
      i18n.language,
    ],
  );

  const updateAvatar = useCallback((newInfo) => {
    setStudentPortfolio((prevState) => ({ ...prevState, avatar: newInfo }));
    setPortfolioMetaData((prevState) => ({
      ...prevState,
      [PortfolioSectionTypes.PORTFOLIO__PERSONAL_INFO]: {
        ...prevState[PortfolioSectionTypes.PORTFOLIO__PERSONAL_INFO],
        avatar: newInfo,
      },
    }));
  }, []);

  const onUpdatePositionHandler = useCallback(
    (index1, index2, direction) => {
      if (!studentPortfolio) return;
      setSectionsToAnimate({
        sections: [index1, index2],
        animations: PortfolioSectionsAnimations[direction].hide,
      });
      const newOrder = [...studentPortfolio.portfolioSectionsOrder];
      [newOrder[index1], newOrder[index2]] = [newOrder[index2], newOrder[index1]];
      patchStudentPortfolio(
        studentId,
        studentPortfolio?.id,
        { portfolioSectionsOrder: newOrder },
        false,
      ).then(() => {
        setStudentPortfolio({ ...studentPortfolio, portfolioSectionsOrder: newOrder });
        setSectionsToAnimate({
          sections: [index1, index2],
          animations: PortfolioSectionsAnimations[direction].show,
        });
      });
    },
    [patchStudentPortfolio, studentId, studentPortfolio],
  );

  const getEntityData = useCallback(
    (key) => {
      switch (key) {
        case PortfolioSectionTypes.PORTFOLIO__PERSONAL_INFO:
          return {
            avatar: studentPortfolio?.avatar,
            updateAvatar,
            studentId,
            portfolioId: studentPortfolio?.id,
          };
        case PortfolioSectionTypes.PORTFOLIO__LEADERSHIP_ATTRIBUTES:
          return mergeArrayOfObjectsHelper(leadershipAttributes, graphData, 'key');
        default:
          return downloadHandlersMap[PortfolioSectionTypes[key]]?.(studentId, !isLoading);
      }
    },
    [
      studentPortfolio,
      updateAvatar,
      studentId,
      leadershipAttributes,
      graphData,
      downloadHandlersMap,
    ],
  );

  const getNewPortfolioTitle = useCallback(() => {
    const portfoliosWithDefaultName = allStudentPortfolios?.filter(({ title }) =>
      title
        .toLowerCase()
        .startsWith(
          `${i18n.language === LanguagesMap.EN_US ? 'new portfolio' : 'nuevo portafolio'}`,
        ),
    );
    const newTitle = `${
      i18n.language === LanguagesMap.EN_US ? 'New portfolio' : 'Nuevo portafolio'
    } ${portfoliosWithDefaultName.length + 1}`;
    return newTitle;
  }, [allStudentPortfolios, i18n]);

  const deletePortfolioHandler = useCallback(
    (portfolioToDeleteId, portfolioStatus) => {
      deleteStudentPortfolio(studentId, portfolioToDeleteId)
        .then(() => {
          if (allStudentPortfolios) {
            const filteredPortfolios = allStudentPortfolios.filter(
              (portfolio) => portfolio.id !== portfolioToDeleteId,
            );
            setAllStudentPortfolios(filteredPortfolios);
          }
          setSnackBarStatus({
            text: t(
              portfolioStatus === PublicationStatuses.PUBLISHED
                ? 'Your portfolio has been deleted.'
                : 'Your draft has been deleted.',
            ),
            type: 'success',
          });
          history.push(portfoliosRoute);
        })
        .catch(() => {
          setSnackBarStatus({
            text: `${t('Something went wrong')}. ${t('Please try once more')}`,
            type: 'error',
            action: (
              <Button
                autoFocus
                gaLabel="Retry"
                onClick={() => deletePortfolioHandler(portfolioToDeleteId)}
              >
                {t('Retry')}
              </Button>
            ),
          });
        });
    },
    [deleteStudentPortfolio, studentId, allStudentPortfolios, setSnackBarStatus, t, history],
  );

  const getIsVisible = useCallback(
    (settings, key, metaData, sectionData) => {
      const { value, defaultValue } = settings;
      const graphSettings = sectionData?.sectionSettings.find(
        ({ name }) => name === PortfolioSectionSettings.DISPLAY_MATURITY_GRAPH.type,
      );
      const isVisible = converterBooleanString(value ?? defaultValue, true);
      switch (true) {
        case isStudent:
          return isVisible;
        case isEducator && key === PortfolioSectionTypes.PORTFOLIO__RECOMMENDATIONS:
          return isVisible;
        case Boolean(getIsSectionHasData(key, metaData, sectionData.entities)) &&
          key !== PortfolioSectionTypes.PORTFOLIO__PERSONAL_INFO:
          return (
            (!!sectionData.entities?.length ||
              converterBooleanString(graphSettings?.value ?? graphSettings?.defaultValue, true)) &&
            isVisible
          );
        default:
          return isVisible;
      }
    },
    [isEducator, isStudent],
  );

  const settingsHandler = useCallback(
    (settings) => {
      if (!studentPortfolio) return;
      updateStudentPortfolioSettings(studentId, studentPortfolio.id, settings).then(() => {
        const newPortfolioData = { ...studentPortfolio };
        settings.forEach(({ sectionName, sectionSettings }) => {
          if (!converterBooleanString(sectionSettings[0].value, true)) {
            const sectionIndex = studentPortfolio.portfolioSectionsOrder.findIndex(
              (item) => item === sectionName,
            );
            newPortfolioData.portfolioSectionsOrder.push(
              newPortfolioData.portfolioSectionsOrder.splice(sectionIndex, 1)[0],
            );
          }
          newPortfolioData.sections[sectionName] = {
            ...newPortfolioData.sections[sectionName],
            sectionSettings: newPortfolioData.sections[sectionName].sectionSettings.map(
              (sectionSetting) =>
                sectionSettings.find(({ name }) => name === sectionSetting.name) || sectionSetting,
            ),
          };
        });
        patchStudentPortfolio(
          studentId,
          studentPortfolio?.id,
          { portfolioSectionsOrder: newPortfolioData.portfolioSectionsOrder },
          false,
        ).then(() =>
          setStudentPortfolio((prevState) => ({
            ...prevState,
            sections: newPortfolioData.sections,
            portfolioSectionsOrder:
              getOrderedSectionsByVisibility(newPortfolioData).allOrderedSections,
          })),
        );
      });
    },
    [studentId, studentPortfolio, updateStudentPortfolioSettings, patchStudentPortfolio],
  );

  const reflectionHandler = useCallback(
    (key, id, reflection, newReflection) => {
      setStudentPortfolio((prevState) => {
        if (!prevState) return prevState;
        return {
          ...prevState,
          sections: {
            ...prevState.sections,
            [key]: {
              ...prevState.sections[key],
              reflection: newReflection,
            },
          },
        };
      });
      if (newReflection === null) {
        deleteReflection(id, ReflectionTypes.STUDENT_PORTFOLIO, true);
      } else if (reflection !== null) {
        putReflection(
          {
            resourceId: id,
            resourceType: ReflectionTypes.STUDENT_PORTFOLIO,
            reflection: newReflection,
            visible: true,
          },
          true,
        );
      } else {
        postReflection(
          {
            resourceId: id,
            resourceType: ReflectionTypes.STUDENT_PORTFOLIO,
            reflection: newReflection,
            visible: true,
          },
          true,
        );
      }
    },
    [deleteReflection, postReflection, putReflection],
  );

  const deletePortfolioItemHandler = useCallback(
    (items, itemIdKey, id, sectionData, sectionName, portfolioId) => {
      const newEntities = items.filter((item) => item[itemIdKey] !== id);
      const newSectionData = {
        ...sectionData,
        entities: newEntities,
        isInitialState: false,
      };
      setStudentPortfolio((prevState) => {
        if (!prevState) return prevState;
        return {
          ...prevState,
          sections: {
            ...prevState.sections,
            [sectionName]: {
              ...newSectionData,
            },
          },
        };
      });
      updateStudentPortfolio(
        studentId,
        portfolioId,
        sectionName,
        transformPortfolioRequestBody(newSectionData, sectionName),
      );
    },
    [studentId, updateStudentPortfolio],
  );

  const addPortfolioItemHandler = useCallback(
    (items, itemsToAdd, itemIdKey, sectionData, sectionName, portfolioId, sectionSettings) => {
      const settings = sectionSettings
        ? sectionData.sectionSettings.map(
            (sectionSetting) =>
              sectionSettings.find(({ name }) => name === sectionSetting.name) || sectionSetting,
          )
        : sectionData.sectionSettings;
      const newItems = items
        ? items.filter((item) =>
            itemsToAdd.some((itemToAdd) => itemToAdd === item[itemIdKey].toString()),
          )
        : itemsToAdd;
      const newSectionData = {
        ...sectionData,
        entities: [...sectionData.entities, ...newItems],
        isInitialState: false,
        sectionSettings: settings,
      };
      setStudentPortfolio((prevState) => {
        if (!prevState) return prevState;
        return {
          ...prevState,
          sections: {
            ...prevState.sections,
            [sectionName]: {
              ...newSectionData,
            },
          },
        };
      });
      updateStudentPortfolio(
        studentId,
        portfolioId,
        sectionName,
        transformPortfolioRequestBody(newSectionData, sectionName),
      );
    },
    [studentId, updateStudentPortfolio],
  );

  const updatePortfolioHandler = useCallback(
    (sectionData, sectionName, portfolioId) => {
      setStudentPortfolio((prevState) => {
        if (!prevState) return prevState;
        return {
          ...prevState,
          sections: {
            ...prevState.sections,
            [sectionName]: {
              ...sectionData,
            },
          },
        };
      });
      updateStudentPortfolio(
        studentId,
        portfolioId,
        sectionName,
        transformPortfolioRequestBody(sectionData, sectionName),
      );
    },
    [studentId, updateStudentPortfolio],
  );

  const changePortfolioVisibilityHandler = useCallback((value) => {
    setStudentPortfolio((prevState) => ({
      ...prevState,
      visible: value,
    }));
  }, []);

  const updatePortfolioMetaData = useCallback((data, key) => {
    setPortfolioMetaData((prevState) => ({
      ...prevState,
      [key]: data,
    }));
  }, []);

  const portfolioData = useMemo(() => {
    const data = {};
    if (studentPortfolio) {
      const { sections, id } = studentPortfolio;
      Object.entries(portfolioMetaData).forEach(([key, value]) => {
        data[key] = {
          isEditMode,
          isVisible: getIsVisible(
            sections[key].sectionSettings.find(
              ({ name }) => name === PortfolioSectionSettings.DISPLAY_SECTION.type,
            ),
            key,
            value,
            sections[key],
          ),
          metaData: value,
          sectionData: sections[key],
          hasData: Boolean(getIsSectionHasData(key, value, sections[key].entities)),
          studentTeachers,
          recommendationsRequests,
          portfolioId: id,
          isPortfolioVisible: studentPortfolio.visible,
          onUpdatePositionHandler,
          changePortfolioVisibilityHandler,
          reflectionHandler: (reflection, newReflection) =>
            reflectionHandler(key, sections[key].id, reflection, newReflection),
          deletePortfolioItemHandler: (items, itemIdKey, itemId, sectionData) =>
            deletePortfolioItemHandler(items, itemIdKey, itemId, sectionData, key, id),
          addPortfolioItemHandler: (items, itemsToAdd, itemIdKey, sectionData, sectionSettings) =>
            addPortfolioItemHandler(
              items,
              itemsToAdd,
              itemIdKey,
              sectionData,
              key,
              id,
              sectionSettings,
            ),
          updatePortfolioHandler: (sectionData) => updatePortfolioHandler(sectionData, key, id),
          updatePortfolioMetaData: (newData) => updatePortfolioMetaData(newData, key),
        };
      });
    }
    return data;
  }, [
    addPortfolioItemHandler,
    deletePortfolioItemHandler,
    getIsVisible,
    portfolioMetaData,
    recommendationsRequests,
    reflectionHandler,
    studentPortfolio,
    studentTeachers,
    updatePortfolioHandler,
    updatePortfolioMetaData,
    onUpdatePositionHandler,
    isEditMode,
    changePortfolioVisibilityHandler,
  ]);

  const getSectionPortfolioData = useCallback(
    async (key) => {
      const metaData = await getEntityData(key, true);
      if (key === PortfolioSectionTypes.PORTFOLIO__RECOMMENDATIONS) {
        if (isStudent) {
          getStudentTeachers(studentId, true).then((data) => setStudentTeachers(data));
        }
        if (isEducator) {
          getStudentRecommendationsRequests(studentId, true).then((data) =>
            setRecommendationsRequests(data),
          );
        }
      }
      setPortfolioMetaData((state) => ({
        ...state,
        [key]: metaData,
      }));
    },
    [
      getEntityData,
      getStudentRecommendationsRequests,
      getStudentTeachers,
      isEducator,
      isStudent,
      studentId,
    ],
  );

  const createPortfolioHandler = useCallback(() => {
    const initialData = {};
    isLoading = true;
    const getInitialData = async () => {
      await Promise.all(
        Object.entries(PortfolioSectionTypes).map(async ([key]) => {
          initialData[key] = await getEntityData(key);
        }),
      );
      return initialData;
    };
    getInitialData().then((initialMetaData) => {
      const portfolioSectionsEntities = Object.assign(
        ...Object.entries(transformPortfolioRequestMetaData(initialMetaData)).map(
          ([key, value]) => ({
            [key]: {
              entities: value
                ? getPortfolioMetadata(key, value).map((entity) => JSON.stringify(entity))
                : value,
              entitiesIds: value
                ? getPortfolioMetadata(key, value).map((entity) =>
                    entity[PortfolioEntitiesIdsTypes[key]]?.toString(),
                  )
                : value,
            },
          }),
        ),
      );
      const title = getNewPortfolioTitle();
      const requestBody = { title, portfolioSectionsEntities };
      createStudentPortfolio(studentId, requestBody).then((data) => {
        setStudentPortfolio(data);
        setPortfolioMetaData(initialData);
        history.push(`${portfoliosRoute}/${data.id}`);
        isLoading = false;
      });
    });
  }, [createStudentPortfolio, getEntityData, studentId, history, getNewPortfolioTitle]);

  const getPublishedPortfolioActions = useCallback(
    (onEdit, onUnpublish, onDelete) => [
      {
        text: t('Edit'),
        icon: CreateIcon,
        handler: onEdit,
        id: 'portfolio-edit',
        gaLabel: 'Edit',
      },
      {
        text: t('Unpublish'),
        icon: HideIcon,
        handler: onUnpublish,
        id: 'portfolio-unpublish',
        gaLabel: 'Unpublish',
      },
      {
        text: t('Delete'),
        icon: DeleteIcon,
        handler: onDelete,
        id: 'portfolio-delete',
        gaLabel: 'Delete',
        className: 'ayo-portfolio-delete-action',
      },
    ],
    [t],
  );

  const unpublishPortfolio = useCallback(
    (portfolioId, visible, action) => {
      patchStudentPortfolio(
        studentId,
        portfolioId,
        { status: PublicationStatuses.DRAFT, visible },
        true,
        true,
      )
        .then((data) => {
          setSnackBarStatus({
            text: t('Your portfolio has been unpublished and moved to Drafts.'),
            type: 'success',
          });
          if (allStudentPortfolios) {
            const index = allStudentPortfolios.findIndex(({ id }) => id === portfolioId);
            setAllStudentPortfolios((prevState) =>
              Object.assign([...prevState], {
                [index]: {
                  ...prevState[index],
                  status: PublicationStatuses.DRAFT,
                  visible,
                  updatedDate: data.updatedDate,
                },
              }),
            );
          }
        })
        .catch(() => {
          setSnackBarStatus({
            text: `${t('Something went wrong')}. ${t('Please try once more')}`,
            type: 'error',
            action: (
              <Button
                autoFocus
                gaLabel="Retry"
                onClick={() => unpublishPortfolio(portfolioId, visible)}
              >
                {t('Retry')}
              </Button>
            ),
          });
        })
        .finally(() => {
          if (action) {
            action();
          }
        });
    },
    [allStudentPortfolios, patchStudentPortfolio, setSnackBarStatus, studentId, t],
  );

  useEffect(() => {
    setPortfolioMetaData({});
    if (studentId && !urlPortfolioId) {
      getAllStudentPortfolios(studentId).then((data) => {
        setAllStudentPortfolios(data);
      });
    }
    if (urlPortfolioId) {
      getStudentPortfolio(studentId, urlPortfolioId).then((data) => {
        setStudentPortfolio(data);
      });
    }
  }, [
    getAllStudentPortfolios,
    studentId,
    setAllStudentPortfolios,
    getStudentPortfolio,
    urlPortfolioId,
  ]);

  useEffect(() => {
    // Update language dependent sections while language is changed
    if (studentPortfolio) {
      isLoading = true;
      const updatedData = {};
      const getUpdatedData = async () => {
        await Promise.all(
          Object.entries(portfolioMetaData).map(async ([key, value]) => {
            updatedData[key] = LanguageDependentSections.includes(key)
              ? await getEntityData(key)
              : value;
          }),
        );
        return updatedData;
      };
      getUpdatedData().then((data) => {
        setPortfolioMetaData(data);
        isLoading = false;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return {
    allStudentPortfolios,
    changePortfolioVisibilityHandler,
    createPortfolioHandler,
    deletePortfolioHandler,
    getPublishedPortfolioActions,
    getSectionPortfolioData,
    isEditMode,
    portfolioData,
    portfolioSettings,
    sectionsToAnimate,
    setIsEditMode,
    settingsHandler,
    studentId,
    studentPortfolio,
    unpublishPortfolio,
  };
};

export default usePortfolioData;
