import React from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Card, Tooltip, Typography } from '../../atoms';
import { GA } from '../../../utils';
import { GaActions, GaCategories } from '../../../constants/enums';
import { MoodNames, Moods, MoodsAndSupportIllustrations } from '../../../constants/moods';
import { ReactComponent as SupportGirlIcon } from '../../../resources/images/moods/support_girl.svg';

const MoodCard = ({ gaLabel, isActiveCard, isSupport, mood, onClick }) => {
  const { t } = useTranslation();
  const { illustration: MoodIllustration, alt } = MoodsAndSupportIllustrations[mood];

  return (
    <Box className="ayo-mood-card__wrapper">
      <Card
        className={classNames('ayo-mood-card', {
          'ayo-clickable-card': !!onClick && isActiveCard,
          'ayo-mood-card__support': isSupport,
        })}
        component={!!onClick && isActiveCard ? 'button' : 'div'}
        elevation={!!onClick && isActiveCard ? 1 : 0}
        mainContent={
          <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
            height="100%"
            justifyContent="space-between"
          >
            <Box alignItems="center" className="ayo-mood-card__illustration" display="flex">
              {isSupport ? (
                <Tooltip disableTouchListener placement="right-end" title={t(mood)}>
                  <SupportGirlIcon aria-label={t(alt)} role="img" />
                </Tooltip>
              ) : (
                <Tooltip disableTouchListener placement="right-end" title={t(MoodNames[mood])}>
                  <MoodIllustration aria-label={t(alt)} role="img" />
                </Tooltip>
              )}
            </Box>
            <Typography className="ayo-mood-card__title" component="h3" variant="subtitle2">
              {t(isSupport ? mood : MoodNames[mood])}
            </Typography>
          </Box>
        }
        onClick={() => {
          if (gaLabel) {
            GA.logInteraction({
              category: GaCategories.BEHAVIOR,
              action: GaActions.BUTTON_CLICK,
              label: gaLabel,
            });
          }

          if (onClick) {
            onClick();
          }
        }}
      />
    </Box>
  );
};

MoodCard.propTypes = {
  gaLabel: PropTypes.string,
  mood: PropTypes.oneOf(Object.values(Moods).concat(['Get support'])),
  isActiveCard: PropTypes.bool,
  isSupport: PropTypes.bool,
  onClick: PropTypes.func,
};

MoodCard.defaultProps = {
  gaLabel: '',
  mood: '',
  isActiveCard: true,
  isSupport: false,
  onClick: null,
};

export default MoodCard;
