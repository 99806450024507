import React, { useMemo, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import React3DCarousel from 'react-spring-3d-carousel';
import ReactSliderCarousel from 'react-multi-carousel';
import { useTranslation } from 'react-i18next';
import { Box, useMediaQuery, useTheme } from '@mui/material';

import { Button } from '../../atoms';
import { GaActions, GaCategories } from '../../../constants/enums';
import { GA } from '../../../utils';
import { ReactComponent as ChevronLeftIcon } from '../../../resources/icons/chevron_left.svg';
import { ReactComponent as ChevronRightIcon } from '../../../resources/icons/chevron_right.svg';
import { defaultSliderResponsiveConfig } from '../../../constants/carouselSliderConfigs';

const CarouselVariantsMap = {
  '3D': '3d',
  SLIDER: 'slider',
};

// Each card in carousel will be receiving isActiveCard prop
// This card should implement tabIndex behavior based on this value
const Carousel = ({
  buttonsVariant,
  items,
  onActiveItemIndexChange,
  variant,
  apiRef,
  sliderResponsiveConfig,
  sliderSettings,
}) => {
  const { t } = useTranslation();
  const [activeItemIndex, setActiveItemIndex] = useState(0);

  const sliderConfig = sliderResponsiveConfig || defaultSliderResponsiveConfig;
  let visibleItems = sliderConfig.mobile.items;
  const theme = useTheme();
  if (useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true })) {
    visibleItems = sliderConfig.tablet.items;
  }
  if (useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true })) {
    visibleItems = sliderConfig.desktop.items;
  }
  if (useMediaQuery(theme.breakpoints.up('xl'), { noSsr: true })) {
    visibleItems = sliderConfig.largeDesktop.items;
  }

  const ref = useRef();

  const cards = useMemo(
    () =>
      variant === CarouselVariantsMap['3D']
        ? items.map((item, i) => ({
            key: i,
            content: {
              ...item,
              props: {
                ...item.props,
                style:
                  items.length > 2 &&
                  ((activeItemIndex === 0 && i === items.length - 1) ||
                    (activeItemIndex === items.length - 1 && i === 0))
                    ? { display: 'none' }
                    : null,
              },
            },
            onClick: () => {
              if (i === activeItemIndex) return;
              GA.logInteraction({
                category: GaCategories.BEHAVIOR,
                action: GaActions.CARD_CLICK,
                label: i < activeItemIndex ? 'Previous' : 'Next',
              });
              setActiveItemIndex(() => {
                onActiveItemIndexChange(i);
                return i;
              });
            },
          }))
        : items.map((item, i) => ({
            ...item,
            props: {
              ...item.props,
              isActiveCard: i >= activeItemIndex && i < activeItemIndex + visibleItems,
            },
          })),
    [activeItemIndex, items, onActiveItemIndexChange, variant, visibleItems],
  );

  useEffect(() => {
    if (apiRef) {
      // eslint-disable-next-line no-param-reassign
      apiRef.current = {
        resetCarouselPosition: () => {
          setActiveItemIndex(0);
          onActiveItemIndexChange(0);
        },
      };
    }

    return () => {
      if (apiRef) {
        // eslint-disable-next-line no-param-reassign
        apiRef.current = null;
      }
    };
  }, [apiRef, onActiveItemIndexChange]);

  return (
    <Box
      className={classNames('ayo-carousel', {
        'ayo-carousel--slider': variant === CarouselVariantsMap.SLIDER,
      })}
    >
      {items.length > 1 && (
        <Button
          aria-label={t('Previous')}
          isIconButton
          onClick={() => {
            GA.logInteraction({
              category: GaCategories.BEHAVIOR,
              action: GaActions.BUTTON_CLICK,
              label: 'Previous',
            });
            setActiveItemIndex((i) => {
              onActiveItemIndexChange(i - 1);
              return i - 1;
            });
            if (ref.current) {
              ref.current.previous();
            }
          }}
          style={{
            visibility: activeItemIndex > 0 || sliderSettings.infinite ? 'visible' : 'hidden',
          }}
          variant={buttonsVariant}
        >
          <ChevronLeftIcon height={40} width={40} />
        </Button>
      )}
      <Box className="ayo-carousel__wrapper">
        {variant === CarouselVariantsMap['3D'] && (
          <React3DCarousel
            goToSlide={activeItemIndex}
            offsetRadius={1}
            showNavigation={false}
            slides={cards}
          />
        )}
        {variant === CarouselVariantsMap.SLIDER && (
          <ReactSliderCarousel
            ref={ref}
            arrows={false}
            draggable={false}
            infinite={sliderSettings.infinite}
            keyBoardControl={sliderSettings.keyBoardControl}
            partialVisible
            renderDotsOutside={sliderSettings.renderDotsOutside}
            responsive={sliderConfig}
            showDots={sliderSettings.showDots && items.length > 1}
          >
            {cards}
          </ReactSliderCarousel>
        )}
      </Box>
      {items.length > 1 && (
        <Button
          aria-label={t('Next')}
          isIconButton
          onClick={() => {
            GA.logInteraction({
              category: GaCategories.BEHAVIOR,
              action: GaActions.BUTTON_CLICK,
              label: 'Next',
            });
            setActiveItemIndex((i) => {
              onActiveItemIndexChange(i + 1);
              return i + 1;
            });
            if (ref.current) {
              ref.current.next();
            }
          }}
          style={{
            visibility: sliderSettings.infinite
              ? 'visible'
              : activeItemIndex >= cards.length - 1 ||
                (ref.current && activeItemIndex > items.length - ref.current.state.slidesToShow - 1)
              ? 'hidden'
              : 'visible',
          }}
          variant={buttonsVariant}
        >
          <ChevronRightIcon height={40} width={40} />
        </Button>
      )}
    </Box>
  );
};
Carousel.propTypes = {
  items: PropTypes.arrayOf(PropTypes.node).isRequired,
  onActiveItemIndexChange: PropTypes.func,
  apiRef: PropTypes.shape({ current: PropTypes.instanceOf(Object) }),
  sliderResponsiveConfig: PropTypes.instanceOf(Object),
  sliderSettings: PropTypes.shape({
    infinite: PropTypes.bool,
    showDots: PropTypes.bool,
    renderDotsOutside: PropTypes.bool,
    keyBoardControl: PropTypes.bool,
  }),
  variant: PropTypes.oneOf([CarouselVariantsMap['3D'], CarouselVariantsMap.SLIDER]).isRequired,
  buttonsVariant: PropTypes.string,
};

Carousel.defaultProps = {
  onActiveItemIndexChange: () => {},
  sliderResponsiveConfig: null,
  apiRef: null,
  buttonsVariant: 'text',
  sliderSettings: {
    infinite: false,
    showDots: false,
    renderDotsOutside: false,
    keyBoardControl: true,
  },
};

export default Carousel;
