import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles';

import {
  AppContextProvider,
  TourContextProvider,
  UserContextProvider,
  QuestionnaireStateContextProvider,
  AdminGridContextProvider,
  LeadershipAttributesContextProvider,
  NotificationsContextProvider,
  SharedDataContextProvider,
  LessonPlannerContextProvider,
  FamilyFeedContextProvider,
  ChatContextProvider,
} from '../../context';
import {
  // Public pages
  AptitudesPage,
  HomePage,
  LessonPlannerPage,
  LoginPage,
  ClasslinkLoginPage,
  EmailProceedPage,
  OpportunitiesPage,
  PrivacyPolicyPage,
  TermsConditionsPage,
  CollaboratePage,
  FaqPage,
  // Private pages
  AnalyticsPage,
  CampusSelectionPage,
  ContentEditPage,
  ContentMainPage,
  FamilyActivitiesPage,
  FamilyFeedEventsPage,
  FamilyFeedCreationCenterPage,
  FollowupQuestionnaireSelectPage,
  InitialQuestionnaireSelectPage,
  TrainAyoPage,
  TrainAyoStartPage,
  TrainAyoQuestionnairePage,
  MyClassesPage,
  MyLessonsPage,
  MyProfilePage,
  MyProfileTeacherPage,
  GoalsPage,
  GoalCreatePage,
  GoalDetailsPage,
  GoalEditPage,
  GoalsStudentSelectionPage,
  EndorsementsPage,
  ExploreOpportunitiesPage,
  SettingsPage,
  StudentsListPage,
  StudentDetailsPage,
  LeadershipAttributesPage,
  LeadershipAttributesAssessmentHomePage,
  LeadershipAttributesAssessmentToolPage,
  LessonPage,
  LessonPlannerCreatePage,
  KidsListPage,
  ExploreInterestsPage,
  ExtracurricularsPage,
  InitiativesPage,
  LeadershipAttributesStudentSelectionPage,
  StudentsPage,
  RoleQuestionPage,
  // Admin interface
  AdminPage,
  DownloadExportPage,
  // Error and support pages
  UnauthorizedPage,
  UnsupportedPage,
  TimeoutErrorPage,
  ServerErrorPage,
  ServiceUnavailablePage,
  ForbiddenPage,
  NotFoundPage,
  OtherRolePage,
  UnsubscribePage,
  IssueReportPage,
  MoodDetailsPage,
  BadgesPage,
  MoodsPage,
  MoodRecommendationsPage,
  PortfolioDetailsPage,
  PortfolioMainPage,
  PortfolioSharedPage,
  ClubHubPage,
  ClubHubCreatePage,
  ClubHubDetailsPage,
  MyFeedPage,
  ClubEventsPage,
} from '../pages';
import { DirtyPrompt } from '../organisms';
import { PrivilegesMap, RolesMap } from '../../constants/enums';
import {
  AppRoutes,
  aptitudesRoute,
  trainAyoInterestsAppRoute,
  trainAyoExtracurricularsAppRoute,
  trainAyoLeadershipAttributesStudentSelectionRoute,
  trainAyoGoalsStudentSelectionRoute,
  opportunitiesRoute,
  trainAyoEndorsementsRoute,
  badgesRoute,
  trainAyoMoodsRoute,
  trainAyoMoodRecommendationsRoute,
  portfoliosRoute,
  portfolioDetailsRoute,
  lessonPlannerRoute,
} from '../../constants/routes';
import { checkEnvironmentVariable, GA, PrivateRoute } from '../../utils';
import './App.scss';

const IS_FAMILY_ACTIVITIES_AVAILABLE = checkEnvironmentVariable(
  'REACT_APP_IS_FAMILY_ACTIVITIES_AVAILABLE',
  'true',
);

const App = () => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider
      theme={createTheme({
        breakpoints: {
          values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
          },
        },
      })}
    >
      <AppContextProvider>
        <UserContextProvider>
          <TourContextProvider>
            <SharedDataContextProvider>
              <LeadershipAttributesContextProvider>
                <QuestionnaireStateContextProvider>
                  <NotificationsContextProvider>
                    <LessonPlannerContextProvider>
                      <FamilyFeedContextProvider>
                        <Router>
                          <ChatContextProvider>
                            {GA.init() && <GA.RouteTracker />}
                            <DirtyPrompt />
                            <Switch>
                              <Route exact path={AppRoutes.HOME}>
                                <HomePage />
                              </Route>
                              <PrivateRoute
                                exact
                                noAuthCheck
                                noConsent
                                noPolicyCheck
                                path={AppRoutes.INITIATIVES}
                              >
                                <InitiativesPage />
                              </PrivateRoute>
                              <PrivateRoute
                                exact
                                noAuthCheck
                                noConsent
                                noPolicyCheck
                                path={AppRoutes.LEADERSHIP_ATTRIBUTES}
                              >
                                <LeadershipAttributesPage />
                              </PrivateRoute>
                              <PrivateRoute
                                exact
                                noAuthCheck
                                noConsent
                                noPolicyCheck
                                path={aptitudesRoute}
                              >
                                <AptitudesPage />
                              </PrivateRoute>
                              <PrivateRoute
                                exact
                                noAuthCheck
                                noConsent
                                noPolicyCheck
                                path={opportunitiesRoute}
                              >
                                <OpportunitiesPage />
                              </PrivateRoute>
                              <PrivateRoute
                                exact
                                noAuthCheck
                                noConsent
                                noPolicyCheck
                                path={lessonPlannerRoute}
                              >
                                <LessonPlannerPage />
                              </PrivateRoute>
                              <Route exact path={AppRoutes.LOGIN}>
                                <LoginPage />
                              </Route>
                              <Route exact path={AppRoutes.CLASSLINK_LOGIN}>
                                <ClasslinkLoginPage />
                              </Route>
                              <Route exact path="/oauth-login">
                                <ClasslinkLoginPage />
                              </Route>
                              <Route exact noConsent path={AppRoutes.PRIVACY}>
                                <PrivacyPolicyPage />
                              </Route>
                              <Route exact noConsent path={AppRoutes.TERMS}>
                                <TermsConditionsPage />
                              </Route>
                              <Route exact path={AppRoutes.COLLABORATE}>
                                <CollaboratePage />
                              </Route>
                              <Route exact path={AppRoutes.EMAIL_PROCEED}>
                                <EmailProceedPage />
                              </Route>
                              <Route exact path={AppRoutes.UNSUBSCRIBE}>
                                <UnsubscribePage />
                              </Route>
                              <Route exact path={AppRoutes.PORTFOLIO_SHARED}>
                                <PortfolioSharedPage />
                              </Route>
                              <PrivateRoute
                                exact
                                noAuthCheck
                                noConsent
                                noPolicyCheck
                                path={AppRoutes.FAQ}
                              >
                                <FaqPage />
                              </PrivateRoute>
                              <PrivateRoute
                                allowedRoles={[
                                  RolesMap.STUDENT,
                                  RolesMap.TEACHER,
                                  RolesMap.GUARDIAN,
                                  RolesMap.ADMINISTRATOR,
                                ]}
                                exact
                                path={AppRoutes.TRAIN_AYO}
                              >
                                <TrainAyoPage />
                              </PrivateRoute>
                              <PrivateRoute
                                allowedRoles={[
                                  RolesMap.TEACHER,
                                  RolesMap.ADMINISTRATOR,
                                  RolesMap.GUARDIAN,
                                ]}
                                exact
                                path={trainAyoLeadershipAttributesStudentSelectionRoute}
                              >
                                <LeadershipAttributesStudentSelectionPage />
                              </PrivateRoute>
                              <PrivateRoute
                                allowedRoles={[
                                  RolesMap.STUDENT,
                                  RolesMap.TEACHER,
                                  RolesMap.ADMINISTRATOR,
                                  RolesMap.GUARDIAN,
                                ]}
                                exact
                                path={AppRoutes.TRAIN_AYO_LEADERSHIP_ATTRIBUTES}
                              >
                                <LeadershipAttributesAssessmentHomePage />
                              </PrivateRoute>
                              <PrivateRoute
                                allowedRoles={[
                                  RolesMap.STUDENT,
                                  RolesMap.TEACHER,
                                  RolesMap.ADMINISTRATOR,
                                  RolesMap.GUARDIAN,
                                ]}
                                exact
                                path={AppRoutes.TRAIN_AYO_LEADERSHIP_ATTRIBUTES_ASSESSMENT_TOOL}
                              >
                                <LeadershipAttributesAssessmentToolPage />
                              </PrivateRoute>
                              <PrivateRoute
                                allowedRoles={[RolesMap.STUDENT, RolesMap.TEACHER]}
                                exact
                                path={AppRoutes.TRAIN_AYO_START}
                              >
                                <TrainAyoStartPage />
                              </PrivateRoute>
                              <PrivateRoute
                                allowedRoles={[RolesMap.GUARDIAN]}
                                exact
                                path={AppRoutes.TRAIN_AYO_SELECT_INITIAL}
                              >
                                <InitialQuestionnaireSelectPage />
                              </PrivateRoute>
                              <PrivateRoute
                                allowedRoles={[RolesMap.GUARDIAN]}
                                exact
                                path={AppRoutes.TRAIN_AYO_SELECT_FOLLOWUP}
                              >
                                <FollowupQuestionnaireSelectPage />
                              </PrivateRoute>
                              <PrivateRoute
                                allowedRoles={[RolesMap.TEACHER, RolesMap.ADMINISTRATOR]}
                                exact
                                path={trainAyoGoalsStudentSelectionRoute}
                              >
                                <GoalsStudentSelectionPage />
                              </PrivateRoute>
                              <PrivateRoute
                                allowedRoles={[
                                  RolesMap.STUDENT,
                                  RolesMap.TEACHER,
                                  RolesMap.GUARDIAN,
                                ]}
                                exact
                                path={AppRoutes.TRAIN_AYO_QUESTIONNAIRE}
                              >
                                <TrainAyoQuestionnairePage />
                              </PrivateRoute>
                              <PrivateRoute
                                allowedRoles={[RolesMap.STUDENT]}
                                exact
                                path={AppRoutes.MY_PROFILE}
                              >
                                <MyProfilePage />
                              </PrivateRoute>
                              <PrivateRoute
                                allowedRoles={[RolesMap.TEACHER, RolesMap.ADMINISTRATOR]}
                                exact
                                path={AppRoutes.MY_PROFILE_TEACHER}
                              >
                                <MyProfileTeacherPage />
                              </PrivateRoute>
                              <PrivateRoute
                                allowedRoles={[
                                  RolesMap.STUDENT,
                                  RolesMap.TEACHER,
                                  RolesMap.GUARDIAN,
                                  RolesMap.ADMINISTRATOR,
                                ]}
                                exact
                                path={[
                                  AppRoutes.PORTFOLIO_DETAILS,
                                  `${AppRoutes.STUDENTS_LIST}/:studentId${portfolioDetailsRoute}`,
                                  `${AppRoutes.STUDENTS}/:studentId${portfolioDetailsRoute}`,
                                  `${AppRoutes.FAMILY_FEED}/:studentId${portfolioDetailsRoute}`,
                                ]}
                              >
                                <PortfolioDetailsPage />
                              </PrivateRoute>
                              <PrivateRoute
                                allowedRoles={[
                                  RolesMap.STUDENT,
                                  RolesMap.TEACHER,
                                  RolesMap.GUARDIAN,
                                  RolesMap.ADMINISTRATOR,
                                ]}
                                exact
                                path={[
                                  AppRoutes.PORTFOLIOS,
                                  `${AppRoutes.STUDENTS_LIST}/:studentId${portfoliosRoute}`,
                                  `${AppRoutes.STUDENTS}/:studentId${portfoliosRoute}`,
                                  `${AppRoutes.FAMILY_FEED}/:studentId${portfoliosRoute}`,
                                ]}
                              >
                                <PortfolioMainPage />
                              </PrivateRoute>
                              <PrivateRoute
                                allowedRoles={[RolesMap.STUDENT]}
                                exact
                                path={AppRoutes.MY_CLASSES}
                              >
                                <MyClassesPage />
                              </PrivateRoute>
                              <PrivateRoute
                                allowedRoles={[RolesMap.STUDENT]}
                                exact
                                path={badgesRoute}
                              >
                                <BadgesPage />
                              </PrivateRoute>
                              <PrivateRoute
                                allowedRoles={[RolesMap.STUDENT]}
                                exact
                                path={trainAyoInterestsAppRoute}
                              >
                                <ExploreInterestsPage />
                              </PrivateRoute>
                              <PrivateRoute
                                allowedRoles={[RolesMap.STUDENT]}
                                exact
                                path={trainAyoEndorsementsRoute}
                              >
                                <EndorsementsPage />
                              </PrivateRoute>
                              <PrivateRoute
                                allowedRoles={[RolesMap.STUDENT]}
                                exact
                                path={trainAyoExtracurricularsAppRoute}
                              >
                                <ExtracurricularsPage />
                              </PrivateRoute>
                              <PrivateRoute
                                allowedRoles={[
                                  RolesMap.STUDENT,
                                  RolesMap.TEACHER,
                                  RolesMap.ADMINISTRATOR,
                                ]}
                                exact
                                path={AppRoutes.TRAIN_AYO_GOALS_CREATE}
                              >
                                <GoalCreatePage />
                              </PrivateRoute>
                              <PrivateRoute
                                allowedRoles={[
                                  RolesMap.STUDENT,
                                  RolesMap.TEACHER,
                                  RolesMap.ADMINISTRATOR,
                                ]}
                                exact
                                path={AppRoutes.TRAIN_AYO_GOALS_EDIT}
                              >
                                <GoalEditPage />
                              </PrivateRoute>
                              <PrivateRoute
                                allowedRoles={[
                                  RolesMap.STUDENT,
                                  RolesMap.TEACHER,
                                  RolesMap.ADMINISTRATOR,
                                ]}
                                path={AppRoutes.TRAIN_AYO_GOALS_DETAILS}
                              >
                                <GoalDetailsPage />
                              </PrivateRoute>
                              <PrivateRoute
                                allowedRoles={[
                                  RolesMap.STUDENT,
                                  RolesMap.TEACHER,
                                  RolesMap.ADMINISTRATOR,
                                ]}
                                exact
                                path={AppRoutes.TRAIN_AYO_GOALS}
                              >
                                <GoalsPage />
                              </PrivateRoute>
                              <PrivateRoute
                                allowedRoles={[RolesMap.STUDENT]}
                                exact
                                path={AppRoutes.TRAIN_AYO_OPPORTUNITIES}
                              >
                                <ExploreOpportunitiesPage />
                              </PrivateRoute>
                              <PrivateRoute
                                allowedRoles={[RolesMap.TEACHER]}
                                exact
                                path={AppRoutes.STUDENTS_LIST}
                              >
                                <StudentsListPage />
                              </PrivateRoute>
                              <PrivateRoute
                                allowedRoles={[RolesMap.TEACHER]}
                                exact
                                path={AppRoutes.MY_LESSONS}
                                privileges={[PrivilegesMap.LESSON_PLANNER_CREATE_PUBLISH]}
                              >
                                <MyLessonsPage />
                              </PrivateRoute>
                              <PrivateRoute
                                allowedRoles={[RolesMap.TEACHER]}
                                exact
                                path={[AppRoutes.MY_LESSONS_CREATE, AppRoutes.MY_LESSONS_VIEW]}
                                privileges={[PrivilegesMap.LESSON_PLANNER_CREATE_PUBLISH]}
                              >
                                <LessonPlannerCreatePage />
                              </PrivateRoute>
                              <PrivateRoute
                                allowedRoles={[RolesMap.TEACHER]}
                                exact
                                path={[
                                  AppRoutes.MY_LESSONS_LESSON_CREATE,
                                  AppRoutes.MY_LESSONS_LESSON_VIEW,
                                ]}
                                privileges={[PrivilegesMap.LESSON_PLANNER_CREATE_PUBLISH]}
                              >
                                <LessonPage />
                              </PrivateRoute>
                              <PrivateRoute
                                allowedRoles={[RolesMap.STUDENT]}
                                exact
                                path={[AppRoutes.MY_CLASSES_LESSON]}
                              >
                                <LessonPage />
                              </PrivateRoute>
                              <PrivateRoute
                                allowedRoles={[RolesMap.ADMINISTRATOR]}
                                exact
                                path={AppRoutes.STUDENTS}
                              >
                                <StudentsPage />
                              </PrivateRoute>
                              <PrivateRoute
                                allowedRoles={[
                                  RolesMap.STUDENT,
                                  RolesMap.TEACHER,
                                  RolesMap.GUARDIAN,
                                  RolesMap.ADMINISTRATOR,
                                ]}
                                exact
                                path={AppRoutes.SETTINGS}
                              >
                                <SettingsPage />
                              </PrivateRoute>
                              <PrivateRoute
                                allowedRoles={[RolesMap.ADMINISTRATOR]}
                                exact
                                path={AppRoutes.ANALYTICS}
                              >
                                <AnalyticsPage />
                              </PrivateRoute>
                              <PrivateRoute
                                allowedRoles={[RolesMap.ADMINISTRATOR, RolesMap.TEACHER]}
                                exact
                                path={AppRoutes.CAMPUS_SELECTION}
                              >
                                <CampusSelectionPage />
                              </PrivateRoute>
                              <PrivateRoute
                                allowedRoles={[RolesMap.ADMINISTRATOR, RolesMap.TEACHER]}
                                exact
                                path={AppRoutes.CONTENT}
                                privileges={[
                                  PrivilegesMap.CONTENT_MODERATION_EDIT_APPROVE,
                                  PrivilegesMap.CONTENT_MODERATION_SHOW_HIDE,
                                ]}
                              >
                                <ContentMainPage />
                              </PrivateRoute>
                              <PrivateRoute
                                allowedRoles={[RolesMap.ADMINISTRATOR, RolesMap.TEACHER]}
                                exact
                                path={`${AppRoutes.CONTENT}/:level`}
                                privileges={[
                                  PrivilegesMap.CONTENT_MODERATION_EDIT_APPROVE,
                                  PrivilegesMap.CONTENT_MODERATION_SHOW_HIDE,
                                ]}
                              >
                                <ContentEditPage />
                              </PrivateRoute>
                              {IS_FAMILY_ACTIVITIES_AVAILABLE && (
                                <PrivateRoute
                                  allowedRoles={[RolesMap.GUARDIAN]}
                                  exact
                                  path={AppRoutes.FAMILY_FEED_ACTIVITIES}
                                >
                                  <FamilyActivitiesPage />
                                </PrivateRoute>
                              )}
                              <PrivateRoute
                                allowedRoles={[RolesMap.TEACHER, RolesMap.ADMINISTRATOR]}
                                exact
                                path={AppRoutes.FAMILY_FEED_CREATION_CENTER}
                                privileges={[
                                  PrivilegesMap.FAMILY_FEED_CREATOR_CAMPUS,
                                  PrivilegesMap.FAMILY_FEED_CREATOR_DISTRICT,
                                  PrivilegesMap.FAMILY_FEED_CREATOR_CLASS,
                                  PrivilegesMap.FAMILY_FEED_CREATOR_PERSONALIZED,
                                ]}
                              >
                                <FamilyFeedCreationCenterPage />
                              </PrivateRoute>
                              <PrivateRoute
                                allowedRoles={[RolesMap.TEACHER, RolesMap.ADMINISTRATOR]}
                                exact
                                path={AppRoutes.FAMILY_FEED_CREATION_CENTER_EVENTS}
                                privileges={[
                                  PrivilegesMap.FAMILY_FEED_CREATOR_CAMPUS,
                                  PrivilegesMap.FAMILY_FEED_CREATOR_DISTRICT,
                                  PrivilegesMap.FAMILY_FEED_CREATOR_CLASS,
                                  PrivilegesMap.FAMILY_FEED_CREATOR_PERSONALIZED,
                                ]}
                              >
                                <FamilyFeedEventsPage />
                              </PrivateRoute>
                              <PrivateRoute
                                allowedRoles={[RolesMap.STUDENT]}
                                exact
                                path={AppRoutes.FAMILY_FEED_MY_FEED_EVENTS}
                              >
                                <FamilyFeedEventsPage />
                              </PrivateRoute>
                              <PrivateRoute
                                allowedRoles={[RolesMap.GUARDIAN]}
                                exact
                                path={AppRoutes.FAMILY_FEED_EVENTS}
                              >
                                <FamilyFeedEventsPage />
                              </PrivateRoute>
                              <PrivateRoute
                                allowedRoles={[
                                  RolesMap.TEACHER,
                                  RolesMap.ADMINISTRATOR,
                                  RolesMap.STUDENT,
                                ]}
                                exact
                                path={AppRoutes.CLUB_HUB}
                              >
                                <ClubHubPage />
                              </PrivateRoute>
                              <PrivateRoute
                                allowedRoles={[RolesMap.TEACHER, RolesMap.ADMINISTRATOR]}
                                exact
                                path={AppRoutes.CLUB_HUB_MANAGEMENT}
                                privileges={[PrivilegesMap.CLUB_HUB_MANAGER]}
                              >
                                <ClubHubPage />
                              </PrivateRoute>
                              <PrivateRoute
                                allowedRoles={[RolesMap.TEACHER, RolesMap.ADMINISTRATOR]}
                                exact
                                path={`${AppRoutes.CLUB_HUB}${AppRoutes.CREATE}`}
                              >
                                <ClubHubCreatePage />
                              </PrivateRoute>
                              <PrivateRoute
                                allowedRoles={[
                                  RolesMap.TEACHER,
                                  RolesMap.ADMINISTRATOR,
                                  RolesMap.STUDENT,
                                ]}
                                exact
                                path={AppRoutes.CLUB_HUB_DETAILS}
                              >
                                <ClubHubDetailsPage />
                              </PrivateRoute>
                              <PrivateRoute
                                allowedRoles={[
                                  RolesMap.TEACHER,
                                  RolesMap.ADMINISTRATOR,
                                  RolesMap.STUDENT,
                                ]}
                                exact
                                path={`${AppRoutes.CLUB_HUB_DETAILS}${AppRoutes.EVENTS}`}
                              >
                                <ClubEventsPage />
                              </PrivateRoute>
                              <PrivateRoute
                                allowedRoles={[RolesMap.TEACHER]}
                                exact
                                path={`${AppRoutes.STUDENTS_LIST}/:studentId${AppRoutes.CLUB_DETAILS}/:clubId`}
                              >
                                <ClubHubDetailsPage />
                              </PrivateRoute>
                              <PrivateRoute
                                allowedRoles={[RolesMap.TEACHER]}
                                exact
                                path={`${AppRoutes.STUDENTS_LIST}/:studentId${AppRoutes.CLUB_DETAILS}/:clubId${AppRoutes.EVENTS}`}
                              >
                                <ClubEventsPage />
                              </PrivateRoute>
                              <PrivateRoute
                                allowedRoles={[RolesMap.ADMINISTRATOR]}
                                exact
                                path={`${AppRoutes.STUDENTS}/:studentId${AppRoutes.CLUB_DETAILS}/:clubId`}
                              >
                                <ClubHubDetailsPage />
                              </PrivateRoute>
                              <PrivateRoute
                                allowedRoles={[RolesMap.ADMINISTRATOR]}
                                exact
                                path={`${AppRoutes.STUDENTS}/:studentId${AppRoutes.CLUB_DETAILS}/:clubId${AppRoutes.EVENTS}`}
                              >
                                <ClubEventsPage />
                              </PrivateRoute>
                              <PrivateRoute
                                allowedRoles={[RolesMap.TEACHER]}
                                exact
                                path={AppRoutes.STUDENT_DETAILS}
                              >
                                <StudentDetailsPage />
                              </PrivateRoute>
                              <PrivateRoute
                                allowedRoles={[RolesMap.ADMINISTRATOR]}
                                exact
                                path={`${AppRoutes.STUDENTS}/:id`}
                              >
                                <StudentDetailsPage />
                              </PrivateRoute>
                              <PrivateRoute
                                allowedRoles={[RolesMap.TEACHER]}
                                exact
                                path={`${AppRoutes.STUDENTS_LIST}/:id${AppRoutes.MOOD_DETAILS}`}
                              >
                                <MoodDetailsPage />
                              </PrivateRoute>
                              <PrivateRoute
                                allowedRoles={[RolesMap.ADMINISTRATOR]}
                                exact
                                path={`${AppRoutes.STUDENTS}/:id${AppRoutes.MOOD_DETAILS}`}
                              >
                                <MoodDetailsPage />
                              </PrivateRoute>
                              <PrivateRoute
                                allowedRoles={[RolesMap.STUDENT]}
                                exact
                                path={trainAyoMoodsRoute}
                              >
                                <MoodsPage />
                              </PrivateRoute>
                              <PrivateRoute
                                allowedRoles={[RolesMap.STUDENT]}
                                exact
                                path={trainAyoMoodRecommendationsRoute}
                              >
                                <MoodRecommendationsPage />
                              </PrivateRoute>
                              <PrivateRoute
                                allowedRoles={[RolesMap.TEACHER]}
                                path={AppRoutes.MY_STUDENT_GOALS_DETAILS}
                              >
                                <GoalDetailsPage />
                              </PrivateRoute>
                              <PrivateRoute
                                allowedRoles={[RolesMap.ADMINISTRATOR]}
                                path={AppRoutes.STUDENT_GOALS_DETAILS}
                              >
                                <GoalDetailsPage />
                              </PrivateRoute>
                              <PrivateRoute
                                allowedRoles={[RolesMap.GUARDIAN]}
                                exact
                                noConsent
                                path={AppRoutes.FAMILY_FEED}
                              >
                                <KidsListPage />
                              </PrivateRoute>
                              <PrivateRoute
                                allowedRoles={[RolesMap.STUDENT]}
                                exact
                                path={AppRoutes.FAMILY_FEED_MY_FEED}
                              >
                                <MyFeedPage />
                              </PrivateRoute>
                              <PrivateRoute
                                allowedRoles={[RolesMap.TEACHER, RolesMap.OTHER]}
                                exact
                                path={AppRoutes.ROLE_QUESTION}
                              >
                                <RoleQuestionPage />
                              </PrivateRoute>
                              <PrivateRoute
                                allowedRoles={[RolesMap.GUARDIAN]}
                                exact
                                path={AppRoutes.KIDS_DETAILS}
                              >
                                <StudentDetailsPage />
                              </PrivateRoute>
                              <PrivateRoute
                                allowedRoles={[RolesMap.GUARDIAN]}
                                exact
                                path={[
                                  AppRoutes.FAMILY_FEED_CLUB_HUB_DETAILS,
                                  AppRoutes.FAMILY_FEED_PROFILE_CLUB_HUB_DETAILS,
                                ]}
                              >
                                <ClubHubDetailsPage />
                              </PrivateRoute>
                              <PrivateRoute
                                allowedRoles={[RolesMap.GUARDIAN]}
                                path={AppRoutes.KIDS_GOALS_DETAILS}
                              >
                                <GoalDetailsPage />
                              </PrivateRoute>
                              <PrivateRoute
                                allowedRoles={[RolesMap.GUARDIAN]}
                                path={AppRoutes.ISSUE_REPORT}
                              >
                                <IssueReportPage />
                              </PrivateRoute>
                              <PrivateRoute
                                allowedRoles={[RolesMap.ADMINISTRATOR, RolesMap.AYO_ADMIN]}
                                exact
                                path={AppRoutes.ADMIN}
                                privileges={[
                                  PrivilegesMap.USER_DATA_MANAGER,
                                  PrivilegesMap.USER_PERMISSION_MANAGER,
                                ]}
                              >
                                <AdminGridContextProvider>
                                  <AdminPage />
                                </AdminGridContextProvider>
                              </PrivateRoute>
                              <PrivateRoute
                                exact
                                noAuthCheck
                                noPolicyCheck
                                path={AppRoutes.DOWNLOAD_EXPORT}
                              >
                                <DownloadExportPage />
                              </PrivateRoute>
                              <PrivateRoute
                                allowedRoles={[RolesMap.OTHER]}
                                path={AppRoutes.OTHER_ROLE}
                              >
                                <OtherRolePage />
                              </PrivateRoute>
                              <PrivateRoute
                                exact
                                noAuthCheck
                                noPolicyCheck
                                path={AppRoutes.UNAUTHORIZED}
                              >
                                <UnauthorizedPage />
                              </PrivateRoute>
                              {
                                // The following page overrides PrivateRoute default redirects
                              }
                              <Route exact path={AppRoutes.UNSUPPORTED}>
                                <UnsupportedPage />
                              </Route>
                              <PrivateRoute
                                exact
                                noAuthCheck
                                noPolicyCheck
                                path={AppRoutes.TIMEOUT_ERROR}
                              >
                                <TimeoutErrorPage />
                              </PrivateRoute>
                              <PrivateRoute
                                exact
                                noAuthCheck
                                noPolicyCheck
                                path={AppRoutes.SERVER_ERROR}
                              >
                                <ServerErrorPage />
                              </PrivateRoute>
                              <PrivateRoute
                                exact
                                noAuthCheck
                                noPolicyCheck
                                path={AppRoutes.SERVICE_UNAVAILABLE}
                              >
                                <ServiceUnavailablePage />
                              </PrivateRoute>
                              <PrivateRoute
                                exact
                                noAuthCheck
                                noPolicyCheck
                                path={AppRoutes.FORBIDDEN}
                              >
                                <ForbiddenPage />
                              </PrivateRoute>
                              <PrivateRoute noAuthCheck noPolicyCheck>
                                <NotFoundPage />
                              </PrivateRoute>
                            </Switch>
                          </ChatContextProvider>
                        </Router>
                      </FamilyFeedContextProvider>
                    </LessonPlannerContextProvider>
                  </NotificationsContextProvider>
                </QuestionnaireStateContextProvider>
              </LeadershipAttributesContextProvider>
            </SharedDataContextProvider>
          </TourContextProvider>
        </UserContextProvider>
      </AppContextProvider>
    </ThemeProvider>
  </StyledEngineProvider>
);

export default App;
