import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Container, useMediaQuery, useTheme } from '@mui/material';

import { Typography } from '../../../../../atoms';

const AptitudesDefinitionSection = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });

  return (
    <Box
      className="ayo-aptitudes-definition-section"
      pb={isWidthUpSm ? 15 : 2}
      pt={isWidthUpSm ? 20 : 5}
    >
      <Container>
        <Box display="flex" justifyContent="center">
          <Box
            className="ayo-aptitudes-definition-section__content"
            display="flex"
            flexDirection={isWidthUpSm ? 'row' : 'column'}
            justifyContent="space-between"
          >
            <Typography align="left" component="h2" paragraph variant="h2">
              {t('What are aptitudes?')}
            </Typography>
            <Typography
              align="left"
              className="ayo-aptitudes-definition-section__content__description"
              variant="body2"
            >
              {t(
                'Aptitudes are someone’s strengths and abilities that relate to various areas of life. They allow you to do specific things better than the others!',
              )}
            </Typography>
          </Box>
        </Box>
      </Container>
      <Box mr={isWidthUpSm ? 0 : 3} mt={3}>
        <img
          alt={t('Assets aptitudes definition section alt')}
          src={t(
            isWidthUpSm
              ? 'Assets aptitudes definition section desktop illustration'
              : 'Assets aptitudes definition section mobile illustration',
          )}
        />
      </Box>
    </Box>
  );
};

export default AptitudesDefinitionSection;
