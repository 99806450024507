import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ChatDrawer } from '../../moleculas';
import { ChatActions, ChatContext, UserContext } from '../../../context';
import { useConversationsService } from '../../../services';
import { useSnackbar } from '../../../hooks';

import ChatContent from './components/chat-content/ChatContent';
import ListOfChats from './components/list-of-chats/ListOfChats';

const getLoadedPageNumber = (count, pageSize) => Math.ceil(count / pageSize);

const DEFAULT_CONVERSATION_PAGE_SIZE = 20;

const ConversationsChat = () => {
  const { state: chatState, dispatch: dispatchChatState } = useContext(ChatContext);
  const {
    isOpened,
    activeConversationMetadata,
    activeConversationData,
    availableConversations,
    activeConversationPage,
    isLoading,
    pushNewConversation,
  } = chatState;

  const onClose = () => {
    dispatchChatState({ type: ChatActions.SET_IS_CHAT_DRAWER_OPENED, data: false });
    dispatchChatState({ type: ChatActions.SET_AVAILABLE_CONVERSATIONS, data: null });
    dispatchChatState({ type: ChatActions.SET_ACTIVE_CONVERSATION_METADATA, data: null });
    dispatchChatState({ type: ChatActions.ADD_PUSH_NEW_CONVERSATION, data: () => {} });
  };

  const hasActiveChat = !!activeConversationMetadata;
  const hasActiveChatData = !!activeConversationData;

  const { getConversation, postChatMessage, postReadMessages } = useConversationsService();

  const { setSnackBarStatus } = useSnackbar();

  const { t } = useTranslation();

  useEffect(() => {
    if (hasActiveChat && !hasActiveChatData) {
      if (activeConversationMetadata.id) {
        getConversation(activeConversationMetadata.id, 1, DEFAULT_CONVERSATION_PAGE_SIZE, true)
          .then((data) => {
            dispatchChatState({
              type: ChatActions.SET_ACTIVE_CONVERSATION_DATA,
              data,
              page: getLoadedPageNumber(data.messages.length, DEFAULT_CONVERSATION_PAGE_SIZE),
            });
          })
          .catch(() => {
            setSnackBarStatus({ type: 'warning', text: t('Deleted conversations get error') });
            onClose();
          });
      } else {
        dispatchChatState({
          type: ChatActions.SET_ACTIVE_CONVERSATION_DATA,
          data: { totalElements: 0, messages: [] },
          page: 1,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    hasActiveChat,
    hasActiveChatData,
    activeConversationMetadata,
    getConversation,
    dispatchChatState,
  ]);

  const loadMorePosts = () => {
    if (
      activeConversationMetadata.id &&
      !isLoading &&
      activeConversationData?.messages.length < activeConversationData?.totalElements
    ) {
      dispatchChatState({ type: ChatActions.SET_IS_LOADING, data: true });
      getConversation(
        activeConversationMetadata.id,
        activeConversationPage + 1,
        DEFAULT_CONVERSATION_PAGE_SIZE,
      ).then((data) => {
        dispatchChatState({
          type: ChatActions.ADD_PAGE_MESSAGES,
          data: data.messages,
          page: activeConversationPage + 1,
        });

        dispatchChatState({ type: ChatActions.SET_IS_LOADING, data: false });
      });
    }
  };

  const { state: userState } = useContext(UserContext);

  const onPostAdd = (message) => {
    dispatchChatState({
      type: ChatActions.ADD_ACTIVE_CONVERSATION_MESSAGE,
      data: { userId: userState.profile.id, text: message, createdDate: new Date(), isRead: true },
    });
    const postBody = activeConversationMetadata.id
      ? { id: activeConversationMetadata.id, text: message }
      : {
          resourceId: activeConversationMetadata.resourceId,
          resourceType: activeConversationMetadata.resourceType,
          memberIds: activeConversationMetadata.members?.map(({ id }) => id),
          text: message,
          resourceName: activeConversationMetadata.resourceName,
          conversationName: activeConversationMetadata.conversationName,
          conversationDescription: activeConversationMetadata.conversationDescription,
        };
    postChatMessage(postBody).then((data) => {
      if (!activeConversationMetadata.id) {
        dispatchChatState({
          type: ChatActions.UPDATE_ACTIVE_CONVERSATION_METADATA,
          data: {
            id: data.conversationId,
            members: data.members,
            conversationName: data.conversationName,
            conversationDescription: data.conversationDescription,
            conversationImage: data.conversationImage,
          },
        });
        pushNewConversation({
          ...activeConversationMetadata,
          id: data.conversationId,
          members: data.members,
          lastMessage: data.messages[0],
        });
      }
    });
  };
  const onConversationSelect = (conversation) => {
    dispatchChatState({ type: ChatActions.SET_ACTIVE_CONVERSATION_METADATA, data: conversation });
  };
  const onMessageRead = (message) => {
    dispatchChatState({ type: ChatActions.MARK_MESSAGE_READ, data: message.id });
    postReadMessages(activeConversationMetadata.id, [message.id]);
  };
  return (
    <ChatDrawer gaLabel="Conversations chat" isOpen={isOpened} onClose={onClose}>
      {hasActiveChat ? (
        <ChatContent
          chatInfo={activeConversationMetadata}
          loadMore={loadMorePosts}
          members={activeConversationMetadata.members}
          messages={activeConversationData?.messages}
          onGoBack={
            availableConversations
              ? () =>
                  dispatchChatState({
                    type: ChatActions.SET_ACTIVE_CONVERSATION_METADATA,
                    data: null,
                  })
              : undefined
          }
          onMessageRead={onMessageRead}
          onPostAdd={onPostAdd}
          totalPostsNumber={activeConversationData?.totalElements}
        />
      ) : (
        <ListOfChats conversations={availableConversations} onItemClick={onConversationSelect} />
      )}
    </ChatDrawer>
  );
};

export default ConversationsChat;
