export const AppRoutes = {
  ADMIN: '/admin',
  ANALYTICS: '/analytics',
  BADGES: '/badges',
  CAMPUS_SELECTION: '/campus-selection',
  CLASSLINK_LOGIN: '/classlink-login',
  CLUB_DETAILS: '/club-details',
  CLUB_HUB: '/club-hub',
  CLUB_HUB_DETAILS: '/club-hub/:clubId',
  CLUB_HUB_MANAGEMENT: '/club-hub/management',
  COLLABORATE: '/collaborate',
  CONTENT: '/content',
  CONVERSATIONS: '/conversations',
  CREATE: '/create',
  DOWNLOAD_EXPORT: '/admin/download-export/:jobId',
  EMAIL_PROCEED: '/email-proceed',
  EMPLOYMENT: '/employment',
  ENDORSEMENTS: '/endorsements',
  EVENTS: '/events',
  EXTRACURRICULARS: '/extracurriculars',
  FAMILY_FEED: '/family-feed',
  FAMILY_FEED_ACTIVITIES: `/family-feed/activities/:studentId`,
  FAMILY_FEED_CLUB_HUB_DETAILS: '/family-feed/:studentId/club-hub/:clubId',
  FAMILY_FEED_EVENTS: '/family-feed/events',
  FAMILY_FEED_PROFILE_CLUB_HUB_DETAILS: '/family-feed/:studentId/club-details/:clubId',
  FAMILY_FEED_CREATION_CENTER: '/family-feed/creation-center',
  FAMILY_FEED_CREATION_CENTER_EVENTS: '/family-feed/creation-center/events',
  FAMILY_FEED_MY_FEED: '/family-feed/my-feed',
  FAMILY_FEED_MY_FEED_EVENTS: '/family-feed/my-feed/events',
  FAQ: '/faq',
  FORBIDDEN: '/forbidden',
  GOALS: '/goals',
  HOME: '/',
  INITIATIVES: '/initiatives',
  INTERESTS: '/interests',
  ISSUE_REPORT: '/issue-report',
  KIDS_DETAILS: '/family-feed/:id',
  KIDS_GOALS_DETAILS: '/family-feed/:studentId/goals/:goalId',
  KIDS_LIST: '/my-kids',
  LEADERSHIP_ATTRIBUTES: '/leadership-attributes',
  LEADERSHIP_ATTRIBUTES_TEACHER: '/leadership-attributes/:studentId',
  LESSON_PLANNER: '/lesson-planner',
  LOGIN: '/login',
  MAIN_CONTENT: '#main-content',
  MY_CLASSES: '/my-classes',
  MY_CLASSES_LESSON: '/my-classes/lesson/:lessonId',
  MY_LESSONS: '/my-lessons',
  MY_LESSONS_CREATE: '/my-lessons/create',
  MY_LESSONS_VIEW: '/my-lessons/:scheduleId',
  MY_LESSONS_LESSON_CREATE: '/my-lessons/lesson/create',
  MY_LESSONS_LESSON_VIEW: '/my-lessons/lesson/:lessonId',
  MOOD_DETAILS: '/mood-details',
  PORTFOLIOS: '/portfolios',
  PORTFOLIO_DETAILS: '/portfolios/:urlPortfolioId',
  PORTFOLIO_SHARED: '/portfolio/shared/:token',
  MY_PORTFOLIO_START: '/my-portfolio/start',
  MY_PROFILE: '/my-profile',
  MY_PROFILE_TEACHER: '/profile',
  MY_STUDENT_GOALS_DETAILS: '/my-students/:studentId/goals/:goalId',
  NOT_FOUND: '/not-found',
  OTHER_ROLE: '/other-role',
  PLUG: '/#',
  PRIVACY: '/privacy',
  RECOMMENDATIONS: '/recommendations',
  ROLE_QUESTION: '/role-question',
  SERVER_ERROR: '/server-error',
  SERVICE_UNAVAILABLE: '/service-unavailable',
  SETTINGS: '/settings',
  STUDENTS: '/students',
  STUDENTS_LIST: '/my-students',
  STUDENT_DETAILS: '/my-students/:id',
  STUDENT_GOALS_DETAILS: '/students/:studentId/goals/:goalId',
  TERMS: '/terms',
  TIMEOUT_ERROR: '/timeout-error',
  TRAIN_AYO: '/train-ayo',
  TRAIN_AYO_GOALS: '/train-ayo/:studentId/goals',
  TRAIN_AYO_GOALS_CREATE: '/train-ayo/:studentId/goals/create',
  TRAIN_AYO_GOALS_DETAILS: '/train-ayo/:studentId/goals/:goalId',
  TRAIN_AYO_GOALS_EDIT: '/train-ayo/:studentId/goals/:goalId/edit',
  TRAIN_AYO_LEADERSHIP_ATTRIBUTES: '/train-ayo/:studentId/leadership-attributes',
  TRAIN_AYO_LEADERSHIP_ATTRIBUTES_ASSESSMENT_TOOL:
    '/train-ayo/:studentId/leadership-attributes/:assessmentId',
  TRAIN_AYO_MOODS_RECOMMENDATIONS: '/train-ayo/moods/:moodId/:mood/recommendations',
  TRAIN_AYO_OPPORTUNITIES: '/train-ayo/opportunities',
  TRAIN_AYO_QUESTIONNAIRE: '/train-ayo/questionnaire/',
  TRAIN_AYO_SELECT_INITIAL: '/train-ayo/select/initial',
  TRAIN_AYO_SELECT_FOLLOWUP: '/train-ayo/select/followup',
  TRAIN_AYO_START: '/train-ayo/start',
  UNAUTHORIZED: '/unauthorized',
  UNSUBSCRIBE: '/unsubscribe',
  UNSUPPORTED: '/unsupported',
  VOLUNTEERING: '/volunteering',
};

const ExternalRoutes = {
  AYO_APP_STORE: 'https://apps.apple.com/ua/app/myayo/id6443446385',
  AYO_GOOGLE_PLAY_MARKET:
    'https://play.google.com/store/apps/details?id=mm.txmesquiteisd.ayoapp&pli=1',
  CLASSLINK_LAUNCHPAD: 'https://launchpad.classlink.com/',
  COLLABORATE_FORM: 'https://forms.gle/TWGShF3e9rtPwCSk6',
  DALLAS_NEWS_ARTICLE:
    'https://www.dallasnews.com/news/education/2020/09/14/mesquite-isd-looks-to-bring-education-into-the-digital-age-with-ayo-project/',
  EMAIL_TO_MISD_AYO: 'mailto:myayo@mesquiteisd.org',
  EMAIL_TO_MISD_HELPDESK: 'mailto:stuhelpdesk@mesquiteisd.org',
  GOOGLE_EDU: 'https://edu.google.com/',
  GOOGLE_TRUST: 'https://www.google.com/edu/trust',
  LIFE_LINE: 'https://988lifeline.org/chat/',
  MAIN_YOUTUBE: 'https://www.youtube.com/channel/UCKovJO7d81ZcGqR18C-TOgw',
  MICROPHONE_SETTINGS_HELP: (language) =>
    `https://support.google.com/chrome/answer/2693767?p=ui_voice_search&hl=${language}`,
  MISD_CORE_VALUES: 'https://www.mesquiteisd.org/about-us/values',
  MISD_PHONE_NUMBER: 'tel:9722886411',
  MISD_ALL_SCHOOLS: 'https://www.mesquiteisd.org/who-we-are/schools',
  SOFTSERVE: 'https://www.softserveinc.com/',
  TELEMUNDODALLAS_VIDEO:
    'https://www.telemundodallas.com/videos/innovador-sistema-de-educacion-en-mesquite/2126418/',
};

const ServiceRoutes = {
  ACADEMIC_RECORD: '/academic-record',
  ACHIEVEMENTS: '/achievements',
  ACKNOWLEDGE: '/acknowledge',
  ACTIVITY_LOG: '/activity-log',
  ADMIN: '/admin',
  ADMINISTRATORS: '/administrators',
  ADMIN_USER_DATA: '/user-data',
  AGREEMENTS: '/agreements',
  ALL_PORTFOLIOS: '/portfolio/metadata',
  ALERTS: '/alerts',
  ANALYTICS: '/analytics',
  ASSIGNMENTS: '/assignments',
  AVATAR: '/avatar',
  AYO_ANALYTIC: '/ayo-analytic',
  ANSWERS: '/answers',
  APPROVE: '/approve',
  APTITUDES: '/aptitudes',
  ATTACHMENTS_FOR_EVIDENCE: '/attachments',
  CERTIFICATES: '/certificates',
  CLUB_HUB: '/club-hub',
  CLASSLINK_AUTH: '/api/oauth/class-link/token',
  CLOSE: '/close',
  CMS: '/cms',
  COMMENTS: '/comments',
  COURSES: '/courses',
  DEMO: '/demo',
  EDIT: '/edit',
  ESSAYS: '/essays',
  EVENTS: '/events',
  EVIDENCES: '/evidences',
  EXTRACURRICULARS: '/extracurriculars',
  FAMILY_ACTIVITIES: '/family-activities',
  FEED: '/feed',
  FEEDBACKS: '/feedbacks',
  FOLLOW_UP: '/follow-up',
  FUNCTIONALITY: '/functionality',
  GET_GUARDIAN_ANSWERS: '/answers/guardians',
  GOALS: '/goals',
  GRADES: '/grades',
  GROUPS: '/groups',
  GUARDIANS: '/guardians',
  HIGHLIGHTS: '/highlights',
  INPUT: '/input',
  INSIGHTS: '/insights',
  INTERESTS: '/interests',
  INTERVENTIONS: '/interventions',
  IS_ALLOWED: '/isAllowed',
  LATEST: '/latest',
  LESSONS: '/lessons',
  LESSONS_SCHEDULE: '/lessons/schedule',
  LESSONS_SCHEDULE_CREATION_STATE: '/lessons/schedule/creation/state',
  LINKS: '/links',
  LIST: '/list',
  LOG: '/log',
  MANAGEMENT: '/management',
  MEMBERS: '/members',
  METADATA: '/metadata',
  MILESTONE: '/milestone',
  MOODS: '/moods',
  NEGATIVE: '/negative',
  NOTIFICATIONS: '/notifications',
  NOTIFICATIONS_CONNECTION: '/api/notifications',
  NOTIFICATIONS_EMAIL_UNSUBSCRIPTION: 'notifications/email/unsubscribe',
  NOTIFICATIONS_SUBSCRIPTION: '/user/topic/notifications',
  ONE_TIME_ACTION: '/actions/onetime',
  OPPORTUNITIES: '/opportunities',
  PAGINATION: '/pagination',
  PERIODS: '/periods',
  POST_ANSWERS: '/questions',
  PRIVILEGES: '/privileges',
  PORTFOLIO: '/portfolio',
  POSITION: '/position',
  PROFILE: '/profile',
  QUESTIONNAIRES: '/questionnaires',
  READ: '/read',
  REFLECTIONS: '/reflections',
  REQUEST: '/request',
  REQUESTS: '/requests',
  RESOURCES: '/resources',
  ROOT_API: '/api/v1',
  SAVE_SELECTED: '/save/selected',
  SCHEDULE: '/schedule',
  SCHOOLS_DAYS_CONFIG: '/schools/days-config',
  SECTION: '/section',
  SEL_RECOMMENDATIONS: '/sel-recommendation',
  SHARE: '/share',
  SHARED: '/shared',
  STATE: '/state',
  STT: '/api/speech-to-text',
  STUDENTS: '/students',
  SUGGESTIONS: '/suggestions',
  SUGGESTED_ACTIVITIES: '/suggestions/leadership-attributes',
  SWITCH: '/switch',
  TEACHERS: '/teachers',
  TEKS: '/teks',
  TEST_SCORES: '/test-scores',
  TICKETS: '/tickets',
  TOP: '/top',
  TOUR: '/tour',
  TREND: '/trend',
  TRENDS: '/trends',
  USERS: '/users',
  VISIBLE: '/visible',
};

const PdfFilesRoutes = {
  IB_ENGLISH: '/MISD-Inspiration-Book.pdf',
  IB_SPANISH: '/MISD-Inspiration-Book-Spanish.pdf',
  SS_DATA_PRIVACY: '/SoftServe-Security-and-Data-Privacy.pdf',
  TERMS_OF_USE: '/MISD-Ayo-Terms-of-Use.pdf',
};

export const SectionHashes = {
  ASSIGNMENT: '#assignment',
  COMMENTS_DIALOG: '#comments-dialog',
  MY_PROFILE_ACHIEVEMENTS: '#achievements-section',
  MY_PROFILE_EXTRACURRICULARS: '#extracurriculars-section',
  MY_PROFILE_LA: '#la-section',
  MY_STUDENTS_ACHIEVEMENTS: '#achievements-section',
  MY_STUDENTS_APTITUDES: '#aptitudes-section',
  MY_STUDENTS_EXTRACURRICULAR: '#extracurricular-section',
  MY_STUDENTS_GOALS: '#goals-section',
  MY_STUDENTS_INTERESTS: '#interests-section',
  MY_STUDENTS_OPPORTUNITIES: '#opportunities-section',
  LA_COMMENT: '#comments-block',
  PORTFOLIO__INTERESTS: '#portfolio-interest-section',
  PORTFOLIO__APTITUDES: '#portfolio-aptitudes-section',
  PORTFOLIO__EMPLOYMENT: '#portfolio-employment-section',
  PORTFOLIO__ENDORSEMENTS: '#portfolio-endorsements-section',
  PORTFOLIO__EXTRACURRICULARS: '#portfolio-extracurriculars-section',
  PORTFOLIO__ACHIEVEMENTS: '#portfolio-achievements-section',
  PORTFOLIO__LEADERSHIP_ATTRIBUTES: '#portfolio-la-section',
  PORTFOLIO__PERSONAL_INFO: '#portfolio-personal-info-section',
  PORTFOLIO__CERTIFICATES: '#portfolio-certificates-section',
  PORTFOLIO__ACADEMIC_RECORD: '#portfolio-academic-record-section',
  PORTFOLIO__ESSAYS: '#portfolio-essays-section',
  PORTFOLIO__RECOMMENDATIONS: '#portfolio-recommendations-section',
  PORTFOLIO__TEST_SCORES: '#portfolio-test-scores-section',
  PORTFOLIO__VOLUNTEERING: '#portfolio-volunteering-section',
};

export const QueryParams = {
  DELETE_ACTION: 'action=delete',
  EXPORT_ACTION: 'action=export',
  LANG: 'lang',
};

export const SupportedOldRoutes = [
  {
    newRoute: AppRoutes.FAMILY_FEED,
    oldRoute: AppRoutes.KIDS_LIST,
  },
];

export const academicRecordRoute = ServiceRoutes.ACADEMIC_RECORD;
export const achievementsRoute = ServiceRoutes.ACHIEVEMENTS;
export const acknowledgeRoute = ServiceRoutes.ACKNOWLEDGE;
export const activityLogRoute = ServiceRoutes.ACTIVITY_LOG;
export const adminRoute = ServiceRoutes.ADMIN;
export const administratorsRoute = ServiceRoutes.ADMINISTRATORS;
export const adminUserDataRoute = ServiceRoutes.ADMIN_USER_DATA;
export const agreementRoute = ServiceRoutes.AGREEMENTS;
export const alertsRoute = ServiceRoutes.ALERTS;
export const allPortfoliosRoute = ServiceRoutes.ALL_PORTFOLIOS;
export const analyticsApiRoute = ServiceRoutes.ANALYTICS;
export const assignmentHash = SectionHashes.ASSIGNMENT;
export const ayoAnalyticRoute = ServiceRoutes.AYO_ANALYTIC;
export const answersPost = ServiceRoutes.POST_ANSWERS;
export const answersRoute = ServiceRoutes.ANSWERS;
export const apiRoute = ServiceRoutes.ROOT_API;
export const approveRoute = ServiceRoutes.APPROVE;
export const aptitudesRoute = ServiceRoutes.APTITUDES;
export const assignmentsRoute = ServiceRoutes.ASSIGNMENTS;
export const attachmentsRoute = ServiceRoutes.ATTACHMENTS_FOR_EVIDENCE;
export const avatarRoute = ServiceRoutes.AVATAR;
export const badgesRoute = `${AppRoutes.MY_PROFILE}${AppRoutes.BADGES}`;
export const campusSelectionRoute = AppRoutes.CAMPUS_SELECTION;
export const certificatesRoute = ServiceRoutes.CERTIFICATES;
export const clubHubRoute = ServiceRoutes.CLUB_HUB;
export const clubHubDetailsRoute = AppRoutes.CLUB_HUB_DETAILS;
export const clubHubProfileDetailsRoute = AppRoutes.CLUB_DETAILS;
export const classlinkAuthRoute = ServiceRoutes.CLASSLINK_AUTH;
export const classlinkLaunchpadRoute = ExternalRoutes.CLASSLINK_LAUNCHPAD;
export const closeRoute = ServiceRoutes.CLOSE;
export const cmsRoute = ServiceRoutes.CMS;
export const collaborateFormRoute = ExternalRoutes.COLLABORATE_FORM;
export const commentsDialog = SectionHashes.COMMENTS_DIALOG;
export const commentsRoute = ServiceRoutes.COMMENTS;
export const contentRoute = AppRoutes.CONTENT;
export const conversationsRoute = AppRoutes.CONVERSATIONS;
export const coursesRoute = ServiceRoutes.COURSES;
export const createRoute = AppRoutes.CREATE;
export const dallasNewsArticleRoute = ExternalRoutes.DALLAS_NEWS_ARTICLE;
export const defaultMainRoute = AppRoutes.TRAIN_AYO;
export const demoRoute = ServiceRoutes.DEMO;
export const downloadInspirationBookEnglishRoute = PdfFilesRoutes.IB_ENGLISH;
export const downloadInspirationBookSpanishRoute = PdfFilesRoutes.IB_SPANISH;
export const downloadMobileAppAndroidRoute = ExternalRoutes.AYO_GOOGLE_PLAY_MARKET;
export const downloadMobileAppAppleRoute = ExternalRoutes.AYO_APP_STORE;
export const editRoute = ServiceRoutes.EDIT;
export const emailAyoMisdHelpdeskRoute = ExternalRoutes.EMAIL_TO_MISD_HELPDESK;
export const emailAyoMisdRoute = ExternalRoutes.EMAIL_TO_MISD_AYO;
export const endorsementsRoute = AppRoutes.ENDORSEMENTS;
export const employmentRoute = AppRoutes.EMPLOYMENT;
export const essaysRoute = ServiceRoutes.ESSAYS;
export const eventsRoute = ServiceRoutes.EVENTS;
export const evidencesRoute = ServiceRoutes.EVIDENCES;
export const extracurricularsRoute = ServiceRoutes.EXTRACURRICULARS;
export const familyActivitiesRoute = ServiceRoutes.FAMILY_ACTIVITIES;
export const familyFeedRoute = AppRoutes.FAMILY_FEED;
export const familyFeedActivitiesRoute = AppRoutes.FAMILY_FEED_ACTIVITIES;
export const familyFeedEventsRoute = AppRoutes.FAMILY_FEED_EVENTS;
export const familyFeedMyFeedRoute = AppRoutes.FAMILY_FEED_MY_FEED;
export const familyFeedMyFeedEventsRoute = AppRoutes.FAMILY_FEED_MY_FEED_EVENTS;
export const familyFeedCreationCenterRoute = AppRoutes.FAMILY_FEED_CREATION_CENTER;
export const familyFeedCreationCenterEventsRoute = AppRoutes.FAMILY_FEED_CREATION_CENTER_EVENTS;
export const faqRoute = AppRoutes.FAQ;
export const feedRoute = ServiceRoutes.FEED;
export const feedbacksRoute = ServiceRoutes.FEEDBACKS;
export const followUpRoute = ServiceRoutes.FOLLOW_UP;
export const forbiddenPageRoute = AppRoutes.FORBIDDEN;
export const functionalityRoute = ServiceRoutes.FUNCTIONALITY;
export const goalsRoute = ServiceRoutes.GOALS;
export const googleEduRoute = ExternalRoutes.GOOGLE_EDU;
export const googleTrustRoute = ExternalRoutes.GOOGLE_TRUST;
export const gradesRoute = ServiceRoutes.GRADES;
export const groupsRoute = ServiceRoutes.GROUPS;
export const guardiansGetAnswersRoute = ServiceRoutes.GET_GUARDIAN_ANSWERS;
export const guardiansRoute = ServiceRoutes.GUARDIANS;
export const highlightsRoute = ServiceRoutes.HIGHLIGHTS;
export const initiativesRoute = AppRoutes.INITIATIVES;
export const inputRoute = ServiceRoutes.INPUT;
export const insightsRoute = ServiceRoutes.INSIGHTS;
export const interestsRoute = ServiceRoutes.INTERESTS;
export const interventionsRoute = ServiceRoutes.INTERVENTIONS;
export const isAllowedRoute = ServiceRoutes.IS_ALLOWED;
export const issueReportRoute = AppRoutes.ISSUE_REPORT;
export const latestRoute = ServiceRoutes.LATEST;
export const leadershipAttributesCommentsSection = SectionHashes.LA_COMMENT;
export const leadershipRoute = AppRoutes.LEADERSHIP_ATTRIBUTES;
export const lessonPlannerRoute = AppRoutes.LESSON_PLANNER;
export const lessonsRoute = ServiceRoutes.LESSONS;
export const lessonScheduleRoute = ServiceRoutes.LESSONS_SCHEDULE;
export const lessonScheduleCreationStateRoute = ServiceRoutes.LESSONS_SCHEDULE_CREATION_STATE;
export const lifeLineRoute = ExternalRoutes.LIFE_LINE;
export const linksRoute = ServiceRoutes.LINKS;
export const listRoute = ServiceRoutes.LIST;
export const loginRoute = AppRoutes.LOGIN;
export const logRoute = ServiceRoutes.LOG;
export const microphoneSettingsHelpRoute = ExternalRoutes.MICROPHONE_SETTINGS_HELP;
export const managementRoute = ServiceRoutes.MANAGEMENT;
export const membersRoute = ServiceRoutes.MEMBERS;
export const metadataRoute = ServiceRoutes.METADATA;
export const milestoneRoute = ServiceRoutes.MILESTONE;
export const mainContentRoute = AppRoutes.MAIN_CONTENT;
export const mainYoutubeRoute = ExternalRoutes.MAIN_YOUTUBE;
export const misdCoreValuesRoute = ExternalRoutes.MISD_CORE_VALUES;
export const misdPhoneNumberRoute = ExternalRoutes.MISD_PHONE_NUMBER;
export const misdAllSchoolsRoute = ExternalRoutes.MISD_ALL_SCHOOLS;
export const moodsRoute = ServiceRoutes.MOODS;
export const moodsDetailsRoute = AppRoutes.MOOD_DETAILS;
export const moodsNegativeTrendRoute = `${ServiceRoutes.MOODS}${ServiceRoutes.NEGATIVE}${ServiceRoutes.TREND}`;
export const myClassesRoute = AppRoutes.MY_CLASSES;
export const myClassesLessonRoute = AppRoutes.MY_CLASSES_LESSON;
export const myLessonsRoute = AppRoutes.MY_LESSONS;
export const myLessonsLessonViewRoute = AppRoutes.MY_LESSONS_LESSON_VIEW;
export const myLessonsLessonCreateRoute = AppRoutes.MY_LESSONS_LESSON_CREATE;
export const myLessonsViewRoute = AppRoutes.MY_LESSONS_VIEW;
export const myStudentsRoute = AppRoutes.STUDENTS_LIST;
export const myStudentsAchievementsSection = SectionHashes.MY_STUDENTS_ACHIEVEMENTS;
export const myStudentsAptitudesSection = SectionHashes.MY_STUDENTS_APTITUDES;
export const myStudentsExtracurricularSection = SectionHashes.MY_STUDENTS_EXTRACURRICULAR;
export const myStudentsGoalsSection = SectionHashes.MY_STUDENTS_GOALS;
export const myStudentsInterestsSection = SectionHashes.MY_STUDENTS_INTERESTS;
export const myStudentsOpportunitiesSection = SectionHashes.MY_STUDENTS_OPPORTUNITIES;
export const myProfileRoute = AppRoutes.MY_PROFILE;
export const myProfileTeacherRoute = AppRoutes.MY_PROFILE_TEACHER;
export const myProfileAchievementsSection = SectionHashes.MY_PROFILE_ACHIEVEMENTS;
export const myProfileExtracurricularsSection = SectionHashes.MY_PROFILE_EXTRACURRICULARS;
export const myProfileLaSection = SectionHashes.MY_PROFILE_LA;
export const negativeRoute = ServiceRoutes.NEGATIVE;
export const notFoundPageRoute = AppRoutes.NOT_FOUND;
export const notificationsRoute = ServiceRoutes.NOTIFICATIONS;
export const notificationsConnectionRoute = ServiceRoutes.NOTIFICATIONS_CONNECTION;
export const notificationsEmailUnsubscriptionRoute =
  ServiceRoutes.NOTIFICATIONS_EMAIL_UNSUBSCRIPTION;
export const notificationsSubscriptionRoute = ServiceRoutes.NOTIFICATIONS_SUBSCRIPTION;
export const oneTimeActionRoute = ServiceRoutes.ONE_TIME_ACTION;
export const opportunitiesRoute = ServiceRoutes.OPPORTUNITIES;
export const otherRoleRoute = AppRoutes.OTHER_ROLE;
export const paginationRoute = ServiceRoutes.PAGINATION;
export const periodsRoute = ServiceRoutes.PERIODS;
export const plugRoute = AppRoutes.PLUG;
export const privacyPolicyRoute = AppRoutes.PRIVACY;
export const privilegesRoute = ServiceRoutes.PRIVILEGES;
export const portfolioDetailsRoute = AppRoutes.PORTFOLIO_DETAILS;
export const portfolioRecommendationsSection = SectionHashes.PORTFOLIO__RECOMMENDATIONS;
export const portfoliosRoute = AppRoutes.PORTFOLIOS;
export const portfolioRoute = ServiceRoutes.PORTFOLIO;
export const positionRoute = ServiceRoutes.POSITION;
export const profileRoute = ServiceRoutes.PROFILE;
export const questionnairesRoute = ServiceRoutes.QUESTIONNAIRES;
export const rootRoute = AppRoutes.HOME;
export const readRoute = ServiceRoutes.READ;
export const recommendationsRoute = AppRoutes.RECOMMENDATIONS;
export const reflectionsRoute = ServiceRoutes.REFLECTIONS;
export const reportIssueRoute = ServiceRoutes.TICKETS;
export const resourcesRoute = ServiceRoutes.RESOURCES;
export const requestRoute = ServiceRoutes.REQUEST;
export const requestsRoute = ServiceRoutes.REQUESTS;
export const roleQuestionRoute = AppRoutes.ROLE_QUESTION;
export const saveSelectedRoute = ServiceRoutes.SAVE_SELECTED;
export const shareRoute = ServiceRoutes.SHARE;
export const sharedRoute = ServiceRoutes.SHARED;
export const scheduleRoute = ServiceRoutes.SCHEDULE;
export const schoolsDaysConfigRoute = ServiceRoutes.SCHOOLS_DAYS_CONFIG;
export const sectionRoute = ServiceRoutes.SECTION;
export const selRecommendationsRoute = ServiceRoutes.SEL_RECOMMENDATIONS;
export const serverErrorPageRoute = AppRoutes.SERVER_ERROR;
export const serviceUnavailablePageRoute = AppRoutes.SERVICE_UNAVAILABLE;
export const settingsPageRoute = AppRoutes.SETTINGS;
export const softserveDataPrivacyRoute = PdfFilesRoutes.SS_DATA_PRIVACY;
export const softserveRoute = ExternalRoutes.SOFTSERVE;
export const suggestionsRoute = ServiceRoutes.SUGGESTIONS;
export const suggestedActivitiesRoute = ServiceRoutes.SUGGESTED_ACTIVITIES;
export const stateRoute = ServiceRoutes.STATE;
export const sttRoute = ServiceRoutes.STT;
export const studentsRoute = ServiceRoutes.STUDENTS;
export const switchRoute = ServiceRoutes.SWITCH;
export const teachersRoute = ServiceRoutes.TEACHERS;
export const telemundodallasVideoRoute = ExternalRoutes.TELEMUNDODALLAS_VIDEO;
export const teksRoute = ServiceRoutes.TEKS;
export const testScoresRoute = ServiceRoutes.TEST_SCORES;
export const termsConditionsRoute = AppRoutes.TERMS;
export const termsOfUseForSchoolDistrictRoute = PdfFilesRoutes.TERMS_OF_USE;
export const timeoutErrorPageRoute = AppRoutes.TIMEOUT_ERROR;
export const topRoute = ServiceRoutes.TOP;
export const tourRoute = ServiceRoutes.TOUR;
export const trainAyoRoute = AppRoutes.TRAIN_AYO;
export const trainAyoEndorsementsRoute = `${AppRoutes.TRAIN_AYO}${AppRoutes.ENDORSEMENTS}`;
export const trainAyoExtracurricularsAppRoute = `${AppRoutes.TRAIN_AYO}${AppRoutes.EXTRACURRICULARS}`;
export const trainAyoGoalsStudentSelectionRoute = `${AppRoutes.TRAIN_AYO}${AppRoutes.GOALS}`;
export const trainAyoInterestsAppRoute = `${AppRoutes.TRAIN_AYO}${AppRoutes.INTERESTS}`;
export const trainAyoLeadershipAttributesRoute = `${AppRoutes.TRAIN_AYO}${AppRoutes.LEADERSHIP_ATTRIBUTES}`;
export const trainAyoLeadershipAttributesStudentSelectionRoute = `${AppRoutes.TRAIN_AYO}${AppRoutes.LEADERSHIP_ATTRIBUTES}`;
export const trainAyoMoodsRoute = `${AppRoutes.TRAIN_AYO}${moodsRoute}`;
export const trainAyoStartRoute = AppRoutes.TRAIN_AYO_START;
export const trainAyoSelectInitialRoute = AppRoutes.TRAIN_AYO_SELECT_INITIAL;
export const trainAyoSelectFollowupRoute = AppRoutes.TRAIN_AYO_SELECT_FOLLOWUP;
export const trainAyoOpportunitiesRoute = AppRoutes.TRAIN_AYO_OPPORTUNITIES;
export const trainAyoQuestionnaireRoute = AppRoutes.TRAIN_AYO_QUESTIONNAIRE;
export const trainAyoMoodRecommendationsRoute = AppRoutes.TRAIN_AYO_MOODS_RECOMMENDATIONS;
export const trendsRoute = ServiceRoutes.TRENDS;
export const unauthorizedPageRoute = AppRoutes.UNAUTHORIZED;
export const unsupportedPageRoute = AppRoutes.UNSUPPORTED;
export const usersRoute = ServiceRoutes.USERS;
export const visibleRoute = ServiceRoutes.VISIBLE;
export const volunteeringRoute = AppRoutes.VOLUNTEERING;
