import { useCallback } from 'react';

import useAxios, { StatusCodeMap } from '../HttpClient';
import {
  AppRoutes,
  apiRoute,
  studentsRoute,
  interestsRoute,
  gradesRoute,
  extracurricularsRoute,
  goalsRoute,
  milestoneRoute,
  opportunitiesRoute,
  achievementsRoute,
  QueryParams,
  aptitudesRoute,
  moodsRoute,
  latestRoute,
  closeRoute,
  trendsRoute,
  endorsementsRoute,
  portfolioRoute,
  sectionRoute,
  isAllowedRoute,
  selRecommendationsRoute,
  saveSelectedRoute,
  settingsPageRoute,
  moodsNegativeTrendRoute,
  acknowledgeRoute,
  interventionsRoute,
  stateRoute,
  employmentRoute,
  certificatesRoute,
  essaysRoute,
  volunteeringRoute,
  recommendationsRoute,
  avatarRoute,
  allPortfoliosRoute,
  teachersRoute,
  requestsRoute,
  scheduleRoute,
  testScoresRoute,
  academicRecordRoute,
} from '../../constants/routes';
import { BadgesStatusMap, BADGES_STATIC_DATA } from '../../constants/badges';
import { PublicationStatuses } from '../../constants/enums';
import { mergeArrayOfObjectsHelper, sortArrayByKey } from '../../utils/array-helpers/arrayHelpers';
import { checkEnvironmentVariable } from '../../utils/app-helpers/appHelpers';
import { getFormDataObject } from '../../utils/file-helpers/fileHelpers';
import { transformRecommendationRequestBody } from '../../utils/portfolio-helpers/portfolioHelpers';

const useStudentsService = () => {
  const { get, httpDelete, post, put, patch } = useAxios();

  const getStudentBaseInfo = useCallback(
    async (personId, hideLoadMask) => {
      const { data } = await get(
        { enabled: !hideLoadMask },
        `${apiRoute}${studentsRoute}/${personId}`,
      );
      return data;
    },
    [get],
  );

  const postStudentAvatar = useCallback(
    async (avatarRequestBody) =>
      post({ enabled: true }, `${apiRoute}/avatar`, avatarRequestBody, {
        skipDefaultErrorHandlers: [
          StatusCodeMap.NOT_FOUND,
          StatusCodeMap.BAD_REQUEST,
          StatusCodeMap.SERVER_ERROR,
        ],
      }),
    [post],
  );

  const deleteStudentAvatar = useCallback(async () => {
    const { data } = await httpDelete({ enabled: true }, `${apiRoute}/avatar`);
    return data.entity;
  }, [httpDelete]);

  const getAllInterests = useCallback(
    async (lang) => {
      const { data } = await get(
        { enabled: true },
        `${apiRoute}${studentsRoute}${interestsRoute}?${QueryParams.LANG}=${lang}`,
      );
      return data.entity;
    },
    [get],
  );

  const getStudentInterests = useCallback(
    async (personId, lang, hideLoadMask) => {
      const { data } = await get(
        { enabled: !hideLoadMask },
        `${apiRoute}${studentsRoute}/${personId}${interestsRoute}?${QueryParams.LANG}=${lang}`,
      );
      return data.entity;
    },
    [get],
  );

  const getStudentExtracurriculars = useCallback(
    async (personId, hideLoadMask) => {
      const { data } = await get(
        { enabled: !hideLoadMask },
        `${apiRoute}${studentsRoute}/${personId}${extracurricularsRoute}`,
      );
      return data.entity.extracurriculars;
    },
    [get],
  );

  const getAvailableExtracurriculars = useCallback(
    async (schoolName) => {
      const { data } = await get(
        { enabled: true },
        `${apiRoute}${studentsRoute}${extracurricularsRoute}${
          schoolName ? `?schoolName=${schoolName}` : ''
        }`,
      );
      return data.entity.extracurriculars;
    },
    [get],
  );

  const postStudentExtracurriculars = useCallback(
    (studentId, extracurriculars) => {
      const extracurricularSendingMaping = (extracurricular) => ({
        name: extracurricular.name,
        year: extracurricular.year,
        id: extracurricular.id,
      });
      return post(
        { enabled: false },
        `${apiRoute}${studentsRoute}${extracurricularsRoute}`,
        { extracurriculars: extracurriculars.map(extracurricularSendingMaping), studentId },
        { skipDefaultErrorHandlers: [StatusCodeMap.SERVER_ERROR, StatusCodeMap.BAD_REQUEST] },
      );
    },
    [post],
  );

  const getStudentGrades = useCallback(
    async (personId) => {
      const { data } = await get(
        { enabled: true },
        `${apiRoute}${studentsRoute}/${personId}${gradesRoute}`,
      );
      return data.entity.grades;
    },
    [get],
  );

  const updateStudentInterest = useCallback(
    (personId, { id, reaction }) =>
      post(
        { enabled: false },
        `${apiRoute}${studentsRoute}/${personId}${interestsRoute}`,
        { id, reaction },
        { skipDefaultErrorHandlers: [StatusCodeMap.SERVER_ERROR] },
      ),
    [post],
  );

  const deleteStudentInterests = useCallback(
    (personId, interestsId) =>
      httpDelete(
        { enabled: false },
        `${apiRoute}${studentsRoute}/${personId}${interestsRoute}`,
        { interestsId },
        { skipDefaultErrorHandlers: [StatusCodeMap.SERVER_ERROR] },
      ),
    [httpDelete],
  );

  const getStudentGoals = useCallback(
    async (personId, hideLoadMask) => {
      const { data } = await get(
        { enabled: !hideLoadMask },
        `${apiRoute}${studentsRoute}/${personId}${goalsRoute}`,
      );
      return data.entity;
    },
    [get],
  );

  const getStudentGoalDetails = useCallback(
    async (personId, goalId) => {
      const { data } = await get(
        { enabled: true },
        `${apiRoute}${studentsRoute}/${personId}${goalsRoute}/${goalId}`,
      );
      const goalDetails = data.entity;
      return {
        ...goalDetails,
        milestones: goalDetails.milestones.sort(
          (a, b) => new Date(a.dueDate) - new Date(b.dueDate),
        ),
      };
    },
    [get],
  );

  const postStudentGoal = useCallback(
    async (goalRequestBody, personId) => {
      const { data } = await post(
        { enabled: true },
        `${apiRoute}${studentsRoute}/${personId}${goalsRoute}`,
        goalRequestBody,
      );
      return data.entity.id;
    },
    [post],
  );

  const updateStudentGoal = useCallback(
    async (goalRequestBody, goalId, personId) => {
      const { data } = await put(
        { enabled: false },
        `${apiRoute}${studentsRoute}/${personId}${goalsRoute}/${goalId}`,
        goalRequestBody,
        {
          skipDefaultErrorHandlers: [StatusCodeMap.SERVER_ERROR],
        },
      );
      const goalDetails = data.entity;
      return {
        ...goalDetails,
        milestones: sortArrayByKey(goalDetails.milestones, 'dueDate', 'date', 'increase'),
      };
    },
    [put],
  );

  const updateStudentGoalMilestone = useCallback(
    (goalRequestBody, goalId, milestoneId, personId) =>
      put(
        { enabled: false },
        `${apiRoute}${studentsRoute}/${personId}${goalsRoute}/${goalId}${milestoneRoute}/${milestoneId}`,
        goalRequestBody,
        {
          skipDefaultErrorHandlers: [StatusCodeMap.SERVER_ERROR],
        },
      ),
    [put],
  );

  const deleteStudentGoal = useCallback(
    (goalId, personId) =>
      httpDelete(
        { enabled: true },
        `${apiRoute}${studentsRoute}/${personId}${goalsRoute}/${goalId}`,
      ),
    [httpDelete],
  );

  const completeStudentGoal = useCallback(
    (goalId, personId) =>
      put(
        { enabled: true },
        `${apiRoute}${studentsRoute}/${personId}${goalsRoute}/${goalId}/complete`,
      ),
    [put],
  );

  const getStudentOpportunities = useCallback(
    async (personId, hideLoadMask, lang) => {
      const { data } = await get(
        { enabled: !hideLoadMask },
        `${apiRoute}${studentsRoute}/${personId}${opportunitiesRoute}?${QueryParams.LANG}=${lang}`,
      );
      return data.entity;
    },
    [get],
  );

  const postStudentOpportunity = useCallback(
    (opportunityRequestBody, personId) =>
      post(
        { enabled: false },
        `${apiRoute}${studentsRoute}/${personId}${opportunitiesRoute}`,
        opportunityRequestBody,
        {
          skipDefaultErrorHandlers: [StatusCodeMap.SERVER_ERROR],
        },
      ),
    [post],
  );

  const getStudentAchievements = useCallback(
    async (personId, hideLoadMask) => {
      const { data } = await get(
        { enabled: !hideLoadMask },
        `${apiRoute}${studentsRoute}/${personId}${achievementsRoute}`,
      );
      const { achievements } = data.entity;
      return sortArrayByKey(achievements, 'year').reduce((acc, el) => {
        const duplicatedValue = acc.find((x) => x.name === el.name);
        if (duplicatedValue) {
          duplicatedValue.year.push(el.year);
        } else {
          acc.push({ ...el, year: [el.year] });
        }
        return acc;
      }, []);
    },
    [get],
  );

  const getStudentAptitudes = useCallback(
    async (personId, hideLoadMask) => {
      const { data } = await get(
        { enabled: !hideLoadMask },
        `${apiRoute}${studentsRoute}/${personId}${aptitudesRoute}`,
      );
      return data.entity;
    },
    [get],
  );

  const getIsMoodAllowed = useCallback(async () => {
    const { data } = await get(
      { enabled: true },
      `${apiRoute}${studentsRoute}${moodsRoute}${isAllowedRoute}`,
    );

    return data.entity;
  }, [get]);

  const getLatestMood = useCallback(
    async (personId) => {
      const { data } = await get(
        { enabled: true },
        `${apiRoute}${studentsRoute}/${personId}${moodsRoute}${latestRoute}`,
      );
      return data.entity;
    },
    [get],
  );

  const getSELRecommendations = useCallback(
    async (lang, mood) => {
      const { data } = await get(
        { enabled: true },
        `${apiRoute}${selRecommendationsRoute}?${mood ? `mood=${mood}&` : ''}lang=${lang}`,
      );
      return data;
    },
    [get],
  );

  const getStudentMoodDetails = useCallback(
    async (personId, timeRange) => {
      const { data } = await get(
        { enabled: true },
        `${apiRoute}${studentsRoute}/${personId}${moodsRoute}/graph?period=${timeRange}`,
      );
      return data.entity;
    },
    [get],
  );

  const getActivityDetails = useCallback(
    async (activityId, lang) => {
      const { data } = await get(
        { enabled: true },
        `${apiRoute}${selRecommendationsRoute}/details/${activityId}?lang=${lang}`,
      );
      return data;
    },
    [get],
  );

  const getMoodPointDetails = useCallback(
    async (personId, moodPointId, lang) => {
      const { data } = await get(
        { enabled: true },
        `${apiRoute}${studentsRoute}/${personId}${moodsRoute}/${moodPointId}?lang=${lang}`,
      );
      const moodDetails = {
        ...data.entity,
        activities: data.entity.activities.map((activity) => ({
          ...activity,
          description: JSON.parse(activity.description.replaceAll('\n', '<br>')),
        })),
      };
      return moodDetails;
    },
    [get],
  );

  const postStudentMood = useCallback(
    async (studentId, mood) => {
      const { data } = await post({ enabled: true }, `${apiRoute}${studentsRoute}${moodsRoute}`, {
        studentId,
        mood,
      });

      return data;
    },
    [post],
  );

  const postSELRecommendationsSelected = useCallback(
    (requestBody) =>
      post(
        { enabled: true },
        `${apiRoute}${selRecommendationsRoute}${saveSelectedRoute}`,
        requestBody,
      ),
    [post],
  );

  const postNegativeTrendAcknowledge = useCallback(
    (studentId, trendId, withErrorHandlers) =>
      post(
        { enabled: true },
        `${apiRoute}${studentsRoute}/${studentId}${moodsNegativeTrendRoute}/${trendId}${acknowledgeRoute}`,
        undefined,
        {
          skipDefaultErrorHandlers: withErrorHandlers && [
            StatusCodeMap.NOT_FOUND,
            StatusCodeMap.SERVER_ERROR,
            StatusCodeMap.BAD_REQUEST,
          ],
        },
      ),
    [post],
  );

  const postInterventionsAcknowledge = useCallback(
    (studentId, moodId, state, withErrorHandlers) =>
      post(
        { enabled: true },
        `${apiRoute}${studentsRoute}/${studentId}${moodsRoute}/${moodId}${interventionsRoute}${stateRoute}`,
        { state },
        {
          skipDefaultErrorHandlers: withErrorHandlers && [
            StatusCodeMap.NOT_FOUND,
            StatusCodeMap.SERVER_ERROR,
            StatusCodeMap.BAD_REQUEST,
          ],
        },
      ),
    [post],
  );

  const getStudentEndorsements = useCallback(
    async (personId, hideLoadMask) => {
      const { data } = await get(
        { enabled: !hideLoadMask },
        `${apiRoute}${studentsRoute}/${personId}${endorsementsRoute}`,
      );
      return data.entity;
    },
    [get],
  );

  const getStudentBadges = useCallback(
    async (personId, hideLoadMask) => {
      const { data } = await get(
        { enabled: !hideLoadMask },
        `${apiRoute}${studentsRoute}/${personId}${AppRoutes.BADGES}`,
      );
      const IS_MOBILE_APP_RELEASED = checkEnvironmentVariable(
        'REACT_APP_IS_MOBILE_APP_AVAILABLE',
        'true',
      );
      const BADGES_LENGTH_IF_MOBILE_NOT_RELEASED = 15;
      const mergedData = mergeArrayOfObjectsHelper(BADGES_STATIC_DATA, data, 'nameKey');
      const badgesWithRating = mergedData.reduce(
        (acc, badge) => [
          ...acc,
          {
            ...badge,
            rating: badge?.completionLevels
              ? mergeArrayOfObjectsHelper(badge.defaultLevels, badge?.completionLevels, 'levelKey')
              : badge.defaultLevels,
            achievementStatus: badge.createdDate
              ? badge.defaultLevels.length === badge.completionLevels.length
                ? BadgesStatusMap.achieved
                : BadgesStatusMap.partiallyAchived
              : BadgesStatusMap.notAchived,
            lastAchievedDate: badge?.completionLevels
              ? new Date(
                  Math.max(
                    ...badge?.completionLevels?.map((level) => new Date(level?.completionDate)),
                  ),
                )
              : null,
          },
        ],
        [],
      );
      return IS_MOBILE_APP_RELEASED
        ? badgesWithRating
        : badgesWithRating.slice(0, BADGES_LENGTH_IF_MOBILE_NOT_RELEASED);
    },
    [get],
  );

  const closeStudentTrendNotification = useCallback(
    (personId, trendId) =>
      put(
        { enabled: false },
        `${apiRoute}${studentsRoute}/${personId}${moodsRoute}${trendsRoute}/${trendId}${closeRoute}`,
      ),
    [put],
  );

  const getStudentEmployment = useCallback(
    async (studentId, hideLoadMask) => {
      const { data } = await get(
        { enabled: !hideLoadMask },
        `${apiRoute}${studentsRoute}/${studentId}${employmentRoute}`,
      );
      return data.entity?.employments;
    },
    [get],
  );

  const postStudentEmployment = useCallback(
    async (requestBody, studentId) => {
      const { data } = await post(
        { enabled: true },
        `${apiRoute}${studentsRoute}/${studentId}${employmentRoute}`,
        getFormDataObject(requestBody),
        {
          skipDefaultErrorHandlers: [StatusCodeMap.BAD_REQUEST],
        },
      );
      return data.entity;
    },
    [post],
  );

  const updateStudentEmployment = useCallback(
    async (requestBody, studentId, employmentId) => {
      const { data } = await put(
        { enabled: true },
        `${apiRoute}${studentsRoute}/${studentId}${employmentRoute}/${employmentId}`,
        getFormDataObject(requestBody),
        {
          skipDefaultErrorHandlers: [StatusCodeMap.BAD_REQUEST],
        },
      );
      return data.entity;
    },
    [put],
  );

  const deleteStudentEmployment = useCallback(
    (studentId, employmentId) =>
      httpDelete(
        { enabled: true },
        `${apiRoute}${studentsRoute}/${studentId}${employmentRoute}/${employmentId}`,
      ),
    [httpDelete],
  );

  const getStudentVolunteering = useCallback(
    async (studentId, hideLoadMask) => {
      const { data } = await get(
        { enabled: !hideLoadMask },
        `${apiRoute}${studentsRoute}/${studentId}${volunteeringRoute}`,
      );
      return data.entity?.volunteerings;
    },
    [get],
  );

  const postStudentVolunteering = useCallback(
    async (requestBody, studentId) => {
      const { data } = await post(
        { enabled: true },
        `${apiRoute}${studentsRoute}/${studentId}${volunteeringRoute}`,
        getFormDataObject(requestBody),
        {
          skipDefaultErrorHandlers: [StatusCodeMap.BAD_REQUEST],
        },
      );
      return data.entity;
    },
    [post],
  );

  const updateStudentVolunteering = useCallback(
    async (requestBody, studentId, volunteeringId) => {
      const { data } = await put(
        { enabled: true },
        `${apiRoute}${studentsRoute}/${studentId}${volunteeringRoute}/${volunteeringId}`,
        getFormDataObject(requestBody),
        {
          skipDefaultErrorHandlers: [StatusCodeMap.BAD_REQUEST],
        },
      );
      return data.entity;
    },
    [put],
  );

  const deleteStudentVolunteering = useCallback(
    (studentId, volunteeringId) =>
      httpDelete(
        { enabled: true },
        `${apiRoute}${studentsRoute}/${studentId}${volunteeringRoute}/${volunteeringId}`,
      ),
    [httpDelete],
  );

  const getStudentPortfolio = useCallback(
    async (personId, portfolioId) => {
      const { data } = await get(
        { enabled: true },
        `${apiRoute}${studentsRoute}/${personId}${portfolioRoute}/${portfolioId}`,
      );
      return data.entity;
    },
    [get],
  );

  const patchStudentPortfolio = useCallback(
    async (personId, portfolioId, patchData, withLoader, withErrorHandlers) => {
      const { data } = await patch(
        { enabled: withLoader },
        `${apiRoute}${studentsRoute}/${personId}${portfolioRoute}/${portfolioId}`,
        patchData,
        {
          skipDefaultErrorHandlers: withErrorHandlers && [
            StatusCodeMap.NOT_FOUND,
            StatusCodeMap.SERVER_ERROR,
            StatusCodeMap.BAD_REQUEST,
          ],
        },
      );
      return data.entity;
    },
    [patch],
  );

  const getAllStudentPortfolios = useCallback(
    async (personId) => {
      const { data } = await get(
        { enabled: true },
        `${apiRoute}${studentsRoute}/${personId}${allPortfoliosRoute}`,
      );
      return data.entity;
    },
    [get],
  );

  const createStudentPortfolio = useCallback(
    async (personId, requestBody) => {
      const { data } = await post(
        { enabled: true },
        `${apiRoute}${studentsRoute}/${personId}${portfolioRoute}`,
        requestBody,
      );
      return data.entity;
    },
    [post],
  );

  const updateStudentPortfolio = useCallback(
    async (personId, portfolioId, sectionName, portfolioRequestBody) => {
      await post(
        { enabled: false },
        `${apiRoute}${studentsRoute}/${personId}${portfolioRoute}/${portfolioId}${sectionRoute}/${sectionName}`,
        portfolioRequestBody,
      );
    },
    [post],
  );

  const updateStudentPortfolioSettings = useCallback(
    (personId, portfolioId, portfolioRequestBody) =>
      post(
        { enabled: true },
        `${apiRoute}${studentsRoute}/${personId}${portfolioRoute}/${portfolioId}${settingsPageRoute}`,
        portfolioRequestBody,
        {
          skipDefaultErrorHandlers: [
            StatusCodeMap.NOT_FOUND,
            StatusCodeMap.BAD_REQUEST,
            StatusCodeMap.SERVER_ERROR,
          ],
        },
      ),
    [post],
  );

  const updateStudentPortfolioAvatar = useCallback(
    (personId, portfolioId, portfolioRequestBody) =>
      post(
        { enabled: true },
        `${apiRoute}${studentsRoute}/${personId}${portfolioRoute}/${portfolioId}${avatarRoute}`,
        portfolioRequestBody,
        {
          skipDefaultErrorHandlers: [
            StatusCodeMap.NOT_FOUND,
            StatusCodeMap.BAD_REQUEST,
            StatusCodeMap.SERVER_ERROR,
          ],
        },
      ),
    [post],
  );

  const deleteStudentPortfolioAvatar = useCallback(
    (personId, portfolioId) =>
      httpDelete(
        { enabled: true },
        `${apiRoute}${studentsRoute}/${personId}${portfolioRoute}/${portfolioId}${avatarRoute}`,
        {
          skipDefaultErrorHandlers: [
            StatusCodeMap.NOT_FOUND,
            StatusCodeMap.BAD_REQUEST,
            StatusCodeMap.SERVER_ERROR,
          ],
        },
      ),
    [httpDelete],
  );

  const deleteStudentPortfolio = useCallback(
    (studentId, portfolioId) =>
      httpDelete(
        { enabled: true },
        `${apiRoute}${studentsRoute}/${studentId}${portfolioRoute}/${portfolioId}`,
        undefined,
        {
          skipDefaultErrorHandlers: [
            StatusCodeMap.NOT_FOUND,
            StatusCodeMap.BAD_REQUEST,
            StatusCodeMap.SERVER_ERROR,
            StatusCodeMap.SERVICE_UNAVAILABLE,
          ],
        },
      ),
    [httpDelete],
  );

  const getStudentCertificates = useCallback(
    async (personId, hideLoadMask) => {
      const { data } = await get(
        { enabled: !hideLoadMask },
        `${apiRoute}${studentsRoute}/${personId}${certificatesRoute}`,
      );
      return data.entity;
    },
    [get],
  );

  const postStudentCertificate = useCallback(
    async (certificateRequestBody, personId) => {
      const { data } = await post(
        { enabled: true },
        `${apiRoute}${studentsRoute}/${personId}${certificatesRoute}`,
        getFormDataObject(certificateRequestBody),
        {
          'Content-Type': 'multipart/form-data',
          skipDefaultErrorHandlers: [StatusCodeMap.BAD_REQUEST],
        },
      );
      return data.entity;
    },
    [post],
  );

  const updateStudentCertificate = useCallback(
    async (certificateRequestBody, personId) => {
      const { data } = await put(
        { enabled: true },
        `${apiRoute}${studentsRoute}/${personId}${certificatesRoute}/${certificateRequestBody.id}`,
        getFormDataObject(certificateRequestBody),
        {
          'Content-Type': 'multipart/form-data',
          skipDefaultErrorHandlers: [StatusCodeMap.BAD_REQUEST],
        },
      );
      return data.entity;
    },
    [put],
  );

  const deleteStudentCertificate = useCallback(
    (personId, certificateId) =>
      httpDelete(
        { enabled: false },
        `${apiRoute}${studentsRoute}/${personId}${certificatesRoute}/${certificateId}`,
        undefined,
        {
          skipDefaultErrorHandlers: [
            StatusCodeMap.BAD_REQUEST,
            StatusCodeMap.SERVER_ERROR,
            StatusCodeMap.NOT_FOUND,
          ],
        },
      ),
    [httpDelete],
  );

  const getStudentEssays = useCallback(
    async (personId, hideLoadMask) => {
      const { data } = await get(
        { enabled: !hideLoadMask },
        `${apiRoute}${studentsRoute}/${personId}${essaysRoute}`,
      );
      return data.entity?.filter((item) => item.status === PublicationStatuses.PUBLISHED);
    },
    [get],
  );

  const postStudentEssay = useCallback(
    async (essay, hideLoadMask) => {
      const newEssay = JSON.parse(JSON.stringify(essay));
      newEssay.attachmentsIds = essay.attachments.map((item) => item.id);

      delete newEssay.attachments;

      const { data } = await post(
        { enabled: !hideLoadMask },
        `${apiRoute}${studentsRoute}${essaysRoute}`,
        newEssay,
      );
      return data.entity;
    },
    [post],
  );

  const updateStudentEssay = useCallback(
    async (essay, essayId, personId) => {
      const newEssay = JSON.parse(JSON.stringify(essay));
      newEssay.attachmentsIds = essay.attachments.map((item) => item.id);

      delete newEssay.attachments;

      const { data } = await put(
        { enabled: true },
        `${apiRoute}${studentsRoute}/${personId}${essaysRoute}/${essayId}`,
        newEssay,
      );
      return data.entity;
    },
    [put],
  );

  const deleteStudentEssay = useCallback(
    (personId, essayId) =>
      httpDelete(
        { enabled: false },
        `${apiRoute}${studentsRoute}/${personId}${essaysRoute}/${essayId}`,
        undefined,
        {
          skipDefaultErrorHandlers: [
            StatusCodeMap.BAD_REQUEST,
            StatusCodeMap.SERVER_ERROR,
            StatusCodeMap.NOT_FOUND,
          ],
        },
      ),
    [httpDelete],
  );

  const getStudentRecommendations = useCallback(
    async (personId, hideLoadMask) => {
      const { data } = await get(
        { enabled: !hideLoadMask },
        `${apiRoute}${studentsRoute}/${personId}${recommendationsRoute}`,
      );
      return data.entity;
    },
    [get],
  );

  const postStudentRecommendation = useCallback(
    async (personId, requestBody, hideLoadMask) => {
      const { data } = await post(
        { enabled: !hideLoadMask },
        `${apiRoute}${studentsRoute}/${personId}${recommendationsRoute}`,
        transformRecommendationRequestBody(requestBody),
      );
      return data.entity;
    },
    [post],
  );

  const updateStudentRecommendation = useCallback(
    async (personId, recommendationId, requestBody) => {
      const { data } = await put(
        { enabled: true },
        `${apiRoute}${studentsRoute}/${personId}${recommendationsRoute}/${recommendationId}`,
        transformRecommendationRequestBody(requestBody),
      );
      return data.entity;
    },
    [put],
  );

  const deleteStudentRecommendation = useCallback(
    (personId, recommendationId) =>
      httpDelete(
        { enabled: false },
        `${apiRoute}${studentsRoute}/${personId}${recommendationsRoute}/${recommendationId}`,
        undefined,
        {
          skipDefaultErrorHandlers: [
            StatusCodeMap.BAD_REQUEST,
            StatusCodeMap.SERVER_ERROR,
            StatusCodeMap.NOT_FOUND,
          ],
        },
      ),
    [httpDelete],
  );

  const getStudentTeachers = useCallback(
    async (personId, hideLoadMask) => {
      const { data } = await get(
        { enabled: !hideLoadMask },
        `${apiRoute}${studentsRoute}/${personId}${teachersRoute}`,
      );
      return data.entity;
    },
    [get],
  );

  const getStudentRecommendationsRequests = useCallback(
    async (personId, hideLoadMask) => {
      const { data } = await get(
        { enabled: !hideLoadMask },
        `${apiRoute}${studentsRoute}/${personId}${recommendationsRoute}${requestsRoute}`,
      );
      return data.entity;
    },
    [get],
  );

  const postStudentRecommendationRequest = useCallback(
    async (requestBody, studentId) => {
      const { data } = await post(
        { enabled: true },
        `${apiRoute}${studentsRoute}/${studentId}${recommendationsRoute}${requestsRoute}`,
        getFormDataObject(requestBody),
        {
          'Content-Type': 'multipart/form-data',
          skipDefaultErrorHandlers: [
            StatusCodeMap.BAD_REQUEST,
            StatusCodeMap.SERVER_ERROR,
            StatusCodeMap.NOT_FOUND,
          ],
        },
      );
      return data.entity;
    },
    [post],
  );

  const getStudentSchedule = useCallback(
    async (startDate, endDate) => {
      const { data } = await get(
        { enabled: true },
        `${apiRoute}${studentsRoute}${scheduleRoute}?startDate=${startDate}&endDate=${endDate}`,
      );
      return data.entity;
    },
    [get],
  );

  const getStudentTestScores = useCallback(
    async (studentId, hideLoadMask) => {
      const { data } = await get(
        { enabled: !hideLoadMask },
        `${apiRoute}${studentsRoute}/${studentId}${testScoresRoute}`,
      );
      return data.entity;
    },
    [get],
  );

  const getStudentSharedLink = useCallback(
    async (personId, portfolioId) => {
      const { data } = await get(
        { enabled: true },
        `${apiRoute}${studentsRoute}/${personId}${portfolioRoute}/${portfolioId}/link`,
      );
      return data.entity;
    },
    [get],
  );

  const getStudentAcademicRecord = useCallback(
    async (studentId, hideLoadMask) => {
      const { data } = await get(
        { enabled: !hideLoadMask },
        `${apiRoute}${studentsRoute}/${studentId}${academicRecordRoute}`,
      );
      return data.entity;
    },
    [get],
  );

  return {
    closeStudentTrendNotification,
    completeStudentGoal,
    createStudentPortfolio,
    deleteStudentAvatar,
    deleteStudentCertificate,
    deleteStudentEmployment,
    deleteStudentEssay,
    deleteStudentGoal,
    deleteStudentInterests,
    deleteStudentPortfolio,
    deleteStudentPortfolioAvatar,
    deleteStudentRecommendation,
    deleteStudentVolunteering,
    getActivityDetails,
    getAllInterests,
    getAllStudentPortfolios,
    getAvailableExtracurriculars,
    getIsMoodAllowed,
    getLatestMood,
    getMoodPointDetails,
    getSELRecommendations,
    getStudentAcademicRecord,
    getStudentAchievements,
    getStudentAptitudes,
    getStudentBadges,
    getStudentBaseInfo,
    getStudentCertificates,
    getStudentEmployment,
    getStudentEndorsements,
    getStudentEssays,
    getStudentExtracurriculars,
    getStudentGoalDetails,
    getStudentGoals,
    getStudentGrades,
    getStudentInterests,
    getStudentMoodDetails,
    getStudentOpportunities,
    getStudentPortfolio,
    getStudentRecommendations,
    getStudentRecommendationsRequests,
    getStudentSchedule,
    getStudentSharedLink,
    getStudentTeachers,
    getStudentTestScores,
    getStudentVolunteering,
    patchStudentPortfolio,
    postInterventionsAcknowledge,
    postNegativeTrendAcknowledge,
    postSELRecommendationsSelected,
    postStudentAvatar,
    postStudentCertificate,
    postStudentEmployment,
    postStudentEssay,
    postStudentExtracurriculars,
    postStudentGoal,
    postStudentMood,
    postStudentOpportunity,
    postStudentRecommendation,
    postStudentRecommendationRequest,
    postStudentVolunteering,
    updateStudentCertificate,
    updateStudentEmployment,
    updateStudentEssay,
    updateStudentGoal,
    updateStudentGoalMilestone,
    updateStudentInterest,
    updateStudentPortfolio,
    updateStudentPortfolioAvatar,
    updateStudentPortfolioSettings,
    updateStudentRecommendation,
    updateStudentVolunteering,
  };
};

export default useStudentsService;
