import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Typography } from '../../../../atoms';
import Chip from '../../../chips/chip/Chip';
import EditingButton from '../../../editing-button/EditingButton';
import { useSearchParams } from '../../../../../hooks';
import {
  clearFilter,
  GA,
  getFilterValueParts,
  StudentFiltersStrategy,
  transformSearchToObject,
} from '../../../../../utils';
import {
  GaActions,
  GaCategories,
  StudentFiltersSpecialValues,
} from '../../../../../constants/enums';
import { FilterUpdateActions } from '../../../../../hooks/use-filter-update-state/useFilterUpdateState';
import { ReactComponent as CloseIcon } from '../../../../../resources/icons/close.svg';
import { ReactComponent as DeleteIcon } from '../../../../../resources/icons/close_primary_dark.svg';

const FiltersChipsList = ({ stats, onFiltersUpdate }) => {
  const search = useSearchParams();

  const filters = useMemo(() => transformSearchToObject(search), [search]);

  const CurrentCategoryStrategy = StudentFiltersStrategy[filters.filterCategory];

  const { filterCriterion, filtersList } = useMemo(
    () => getFilterValueParts(filters, stats),
    [filters, stats],
  );

  const { t } = useTranslation();

  const history = useHistory();

  const onDelete = useCallback(
    (filterValue, filterDisplayName) => {
      const filterOptions =
        StudentFiltersStrategy[filters.filterCategory].getFilterOptionsList?.(stats) || [];
      const newFilters = clearFilter(filterValue, filters, stats, filterOptions);

      const validatedFilters = CurrentCategoryStrategy.validateFilters(newFilters, stats)
        ? newFilters
        : {};

      history.replace({ search: new URLSearchParams(validatedFilters).toString() });
      onFiltersUpdate({ type: FilterUpdateActions.CLEAR, data: filterDisplayName });

      GA.logInteraction({
        category: GaCategories.BEHAVIOR,
        action: GaActions.CHIP_DELETE_CLICK,
        label: 'Student filters chip',
      });
    },
    [CurrentCategoryStrategy, filters, history, onFiltersUpdate, stats],
  );

  const onClearAll = useCallback(() => {
    history.replace({ search: null });
    onFiltersUpdate({ type: FilterUpdateActions.CLEAR_ALL });
  }, [history, onFiltersUpdate]);

  const StudentFiltersSpecialValuesList = Object.values(StudentFiltersSpecialValues);
  const categoryLabel = CurrentCategoryStrategy?.getChipsLabel?.(filterCriterion, t);
  return (
    stats && (
      <>
        {categoryLabel && (
          <Typography isLabel variant="body2">
            {categoryLabel}
          </Typography>
        )}
        {filtersList.map((filter) => {
          // TODO: come up with some more generic way to generate custom names for PENDING filter
          let displayName;
          if (
            filter === StudentFiltersSpecialValues.PENDING &&
            CurrentCategoryStrategy.getPendingDisplayName
          ) {
            displayName = CurrentCategoryStrategy.getPendingDisplayName(
              CurrentCategoryStrategy.getStatValue(stats, filter, filterCriterion),
              t,
            );
          } else {
            displayName = CurrentCategoryStrategy.getFilterDisplayName(
              t(`analytics.highlights.${filterCriterion}`),
              StudentFiltersSpecialValuesList.includes(filter)
                ? t(`analytics.highlights.${filter}`)
                : t(filter),
              CurrentCategoryStrategy.getStatValue(stats, filter, filterCriterion),
              stats,
              t,
            );
          }
          return (
            <Chip
              key={filter}
              className="filter-chip"
              deleteIcon={<DeleteIcon />}
              label={displayName}
              onDelete={() => onDelete(filter, displayName)}
            />
          );
        })}
        {filtersList.length > 1 && (
          <EditingButton
            className="clear-button"
            gaLabel="Clear all student filters"
            icon={<CloseIcon height="24" width="24" />}
            iconPosition="end"
            onClick={onClearAll}
            text={t('Clear all')}
          />
        )}
      </>
    )
  );
};

FiltersChipsList.propTypes = {
  stats: PropTypes.instanceOf(Object).isRequired,
  onFiltersUpdate: PropTypes.func.isRequired,
};

export default FiltersChipsList;
