import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Highlighter } from '../../../utils';
import { interestEmojis } from '../../../constants/emojis';
import { ChipDisplayModes } from '../../../constants/enums';
import EditableChip from '../chips/editable-chip/EditableChip';
import { ReactComponent as CheckCircle } from '../../../resources/icons/check_circle.svg';

const ModesLabels = {
  [ChipDisplayModes.ADD]: 'Add interest to my interests',
  [ChipDisplayModes.DELETE]: 'Delete interest from my interests',
};

const StudentInterestItem = ({
  ariaLabel,
  className,
  highlight,
  isSelected,
  interest,
  isWithEmoji,
  label,
  mode,
  onClick,
}) => {
  const { t } = useTranslation();
  const interestEmoji = interestEmojis[interest.nameKey];

  return (
    <Box
      className="ayo-interest-chip-container"
      display="inline-block"
      maxWidth="100%"
      pb={1}
      pr={1}
    >
      <EditableChip
        ariaLabel={ariaLabel || t(ModesLabels[mode], { interestName: interest.name })}
        className={classNames('ayo-clickable-chip', className, {
          'ayo-clickable-chip--selected': isSelected,
        })}
        emoji={isWithEmoji && interestEmoji}
        icon={isSelected && <CheckCircle />}
        label={label || (highlight.length ? Highlighter(interest.name, highlight) : interest.name)}
        mode={mode}
        onClick={onClick}
        onDelete={mode === ChipDisplayModes.DELETE ? onClick : undefined}
      />
    </Box>
  );
};

export default StudentInterestItem;

StudentInterestItem.propTypes = {
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
  highlight: PropTypes.arrayOf(PropTypes.string),
  isSelected: PropTypes.bool,
  interest: PropTypes.instanceOf(Object).isRequired,
  isWithEmoji: PropTypes.bool,
  label: PropTypes.string,
  mode: PropTypes.oneOf(Object.values(ChipDisplayModes)),
  onClick: PropTypes.func,
};

StudentInterestItem.defaultProps = {
  ariaLabel: '',
  className: '',
  highlight: [],
  isSelected: false,
  isWithEmoji: null,
  label: '',
  mode: null,
  onClick: null,
};
