import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';

import { Button, TextField, Typography } from '../../../atoms';
import { Chip, Dropdown, InformationalCaption, STTTextField } from '../../../moleculas';
import FileUpload from '../../fileupload/FileUpload';
import { AppActions, AppContext, UserContext } from '../../../../context';
import { useEvidencesService } from '../../../../services';
import {
  AttachmentsResourcesTypes,
  InputsValidationErrors,
  InputsValidationRules,
  LeadershipAttributes,
} from '../../../../constants/enums';
import { byteToMegaByteCoeff } from '../../../../constants/values';
import { urlAllowedValidationRE } from '../../../../constants/regexps';
import { formatUrl } from '../../../../utils';
import {
  evidenceOfGrowthItem,
  goalActionStepItem,
  goalDetailsItem,
} from '../../../../constants/propTypes';

const EvidenceOfGrowthForm = ({
  actionSteps,
  assessmentKey,
  closeDialog,
  evidenceChangeHandler,
  goalDetails,
  isActionStepDisabled,
  valuesToEdit,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const [attributeAssessmentKey, setAttributeAssessmentKey] = useState(assessmentKey);
  const [titleValue, setTitleValue] = useState('');
  const [reflectionValue, setReflectionValue] = useState('');
  const [linkValue, setLinkValue] = useState('');
  const [titleErrorMessage, setTitleErrorMessage] = useState('');
  const [linkErrorMessage, setLinkErrorMessage] = useState('');
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [removedAttachments, setRemovedAttachments] = useState([]);
  const [removedGoalResourceId, setRemovedGoalResourceId] = useState('');
  const [goalResourceId, setGoalResourceId] = useState(actionSteps ? actionSteps[0].id : null);
  const [uploadedFiles, setUploadedFiles] = useState(null);
  const { state: userState } = useContext(UserContext);
  const { dispatch: dispatchAppState } = useContext(AppContext);

  const leadershipAttributesOptions = !goalDetails?.leadershipAttributeKey
    ? LeadershipAttributes
    : {
        [Object.keys(LeadershipAttributes).find(
          (key) => LeadershipAttributes[key] === goalDetails?.leadershipAttributeKey,
        )]: goalDetails?.leadershipAttributeKey,
      };

  const actionStepsOptions = isActionStepDisabled
    ? actionSteps
    : actionSteps?.filter(
        (actionStep) =>
          !actionStep.evidenceIds ||
          actionStep.evidenceIds?.length < 3 ||
          actionStep.id ===
            +valuesToEdit?.evidenceResourcesByType[AttachmentsResourcesTypes.GOAL]?.resourceId,
      );

  const { postEvidence, updateEvidence } = useEvidencesService();
  const submitButtonRef = useRef();

  const resetForm = () => {
    setTitleValue('');
    setReflectionValue('');
    setLinkValue('');
    setTitleErrorMessage('');
    setLinkErrorMessage('');
  };
  useEffect(() => {
    resetForm();
    if (valuesToEdit) {
      const { title, reflection, link } = valuesToEdit;
      setTitleValue(title);
      setReflectionValue(reflection);
      setLinkValue(link);
      if (valuesToEdit.attachments?.length) {
        setUploadedFiles(
          valuesToEdit.attachments.map((el) => ({
            file: { name: el.fileName, id: el.id },
            errors: [],
          })),
        );
      }
    }
  }, [valuesToEdit]);

  useEffect(() => {
    if (actionStepsOptions) {
      setGoalResourceId(actionStepsOptions[0]?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTitleChange = useCallback(
    (e) => {
      setTitleValue(e.target.value);
      if (titleErrorMessage) {
        setTitleErrorMessage('');
      }
    },
    [titleErrorMessage],
  );

  const handleReflectionChange = useCallback((e) => {
    setReflectionValue(e.target.value);
  }, []);

  const handleLinkChange = useCallback(
    (e) => {
      setLinkValue(e.target.value);
      if (linkErrorMessage) {
        setLinkErrorMessage('');
      }
    },
    [linkErrorMessage],
  );

  const handleAssessmentKeyChange = useCallback((e) => {
    setAttributeAssessmentKey(e.target.value);
  }, []);

  const handleActionStepsChange = useCallback(
    (e) => {
      const relatedActionStep =
        valuesToEdit?.evidenceResourcesByType[AttachmentsResourcesTypes.GOAL]?.resourceId;
      if (relatedActionStep) {
        setRemovedGoalResourceId(relatedActionStep);
      }
      setGoalResourceId(e.target.value);
    },
    [valuesToEdit],
  );

  const isValid = useCallback(() => {
    let titleValidationError = '';
    let linkValidationError = '';

    if (titleValue.length < InputsValidationRules.MIN_INPUT_LENGTH) {
      titleValidationError = InputsValidationErrors(
        t,
        InputsValidationRules.MIN_INPUT_LENGTH,
      ).MIN_ERROR_TEXT;
    } else if (titleValue.length > InputsValidationRules.MAX_INPUT_LENGTH) {
      titleValidationError = InputsValidationErrors(
        t,
        InputsValidationRules.MAX_INPUT_LENGTH,
      ).MAX_ERROR_TEXT;
    }
    if (linkValue.length) {
      const formattedUrl = formatUrl(linkValue);

      if (
        !urlAllowedValidationRE.test(formattedUrl) ||
        formattedUrl.length > InputsValidationRules.MAX_LINK_INPUT_LENGTH
      ) {
        linkValidationError = InputsValidationErrors(t).LINK_ERROR_TEXT;
      }
    }
    setTitleErrorMessage(titleValidationError);
    setLinkErrorMessage(linkValidationError);
    return (
      !titleValidationError &&
      !linkValidationError &&
      reflectionValue.length <= InputsValidationRules.MAX_TEXT_AREA_LENGTH
    );
  }, [titleValue, linkValue, reflectionValue, t]);

  const cancelHandler = useCallback(() => {
    resetForm();
    closeDialog();
  }, [closeDialog]);

  const submitEvidence = useCallback(() => {
    const postBody = {
      attachments: filesToUpload,
      attributeKey: attributeAssessmentKey,
      goalParentResourceId: goalDetails?.id || null,
      goalResourceId,
      link: linkValue && formatUrl(linkValue),
      reflection: reflectionValue,
      removedAttachments,
      title: titleValue,
      removedGoalResourceId,
    };
    if (valuesToEdit) {
      updateEvidence(postBody, valuesToEdit.id, userState.profile.id)
        .then((response) => {
          evidenceChangeHandler(response, valuesToEdit.id);
        })
        .catch(() => {
          dispatchAppState({
            type: AppActions.SET_SNACKBAR_STATUS,
            data: {
              text: t('AYO couldn’t save your edits Please try once more'),
              type: 'error',
            },
          });
        });
    } else {
      postEvidence(postBody, userState.profile.id)
        .then((response) => {
          evidenceChangeHandler(response);
        })
        .catch(() => {
          dispatchAppState({
            type: AppActions.SET_SNACKBAR_STATUS,
            data: {
              text: t('AYO couldn’t save your input Please try once more'),
              type: 'error',
            },
          });
        });
    }
    closeDialog();
  }, [
    filesToUpload,
    attributeAssessmentKey,
    goalDetails?.id,
    goalResourceId,
    linkValue,
    reflectionValue,
    removedAttachments,
    titleValue,
    removedGoalResourceId,
    valuesToEdit,
    closeDialog,
    updateEvidence,
    userState.profile.id,
    evidenceChangeHandler,
    dispatchAppState,
    t,
    postEvidence,
  ]);
  const handleSubmitClick = useCallback(() => {
    if (isValid()) {
      submitEvidence();
    }
  }, [isValid, submitEvidence]);

  return (
    <Box>
      <Box>
        <STTTextField
          error={titleErrorMessage !== ''}
          fullWidth
          gaLabel="Evidence title"
          helperText={titleErrorMessage}
          InputLabelProps={{ id: 'evidenceTitle' }}
          inputProps={{
            'aria-labelledby': 'evidenceTitle',
            required: true,
            autoComplete: 'off',
          }}
          label={t('Title')}
          maxLength={InputsValidationRules.MAX_INPUT_LENGTH}
          name={t('Title')}
          onChange={handleTitleChange}
          outlined
          required
          value={titleValue}
        />
        <Box py={3}>
          <STTTextField
            fullWidth
            gaLabel="Reflection"
            InputLabelProps={{ id: 'notes' }}
            inputProps={{ 'aria-labelledby': 'notes' }}
            label={t('Notes')}
            maxLength={InputsValidationRules.MAX_TEXT_AREA_LENGTH}
            multiline
            name={t('Notes')}
            onChange={handleReflectionChange}
            outlined
            rowsCount={4}
            value={reflectionValue}
          />
        </Box>
        <TextField
          error={linkErrorMessage !== ''}
          fullWidth
          gaLabel="Link input"
          helperText={linkErrorMessage}
          InputLabelProps={{
            id: 'evidenceLink',
            autoComplete: 'off',
          }}
          inputProps={{ 'aria-labelledby': 'evidenceLink' }}
          label={t('Link')}
          name="Link"
          onChange={handleLinkChange}
          outlined
          value={linkValue}
        />
        {goalDetails && (
          <Box>
            <Box position="relative" py={3}>
              <Dropdown
                disabled
                fullWidth
                label={`${t('Related goal')}*`}
                options={[
                  {
                    value: goalDetails.id,
                    label: goalDetails.name,
                  },
                ]}
                outlined
              />
            </Box>
            <Box pb={3}>
              <Dropdown
                defaultValue={
                  (isActionStepDisabled && actionSteps[0].id) ||
                  valuesToEdit?.evidenceResourcesByType[AttachmentsResourcesTypes.GOAL]?.resourceId
                }
                disabled={isActionStepDisabled}
                fullWidth
                gaLabel="Evidence related action step change"
                handleChange={handleActionStepsChange}
                label={`${t('Related action step')}*`}
                options={actionStepsOptions.map((actionStep) => ({
                  value: actionStep.id,
                  label: actionStep.name,
                }))}
                outlined
              />
            </Box>
            <Box mb={3}>
              {assessmentKey ? (
                <Box>
                  <Box mb={1}>
                    <Typography component="h3" variant="subtitle2">
                      {t('Related Attribute')}
                    </Typography>
                  </Box>
                  <Chip isCaption label={t(assessmentKey)} />
                </Box>
              ) : (
                <Box>
                  <Box mb={1.5}>
                    <Typography component="p" variant="body2">
                      {t('If the evidence is related to a Leadership Attribute select it here')}
                    </Typography>
                  </Box>
                  <Dropdown
                    fullWidth
                    gaLabel="Evidence related Attribute change"
                    handleChange={handleAssessmentKeyChange}
                    label={t('Select an Attribute')}
                    options={[
                      {
                        value: null,
                        label: t('None'),
                      },
                      ...Object.values(leadershipAttributesOptions).map((attribute) => ({
                        value: attribute,
                        label: t(attribute),
                      })),
                    ]}
                    outlined
                  />
                </Box>
              )}
            </Box>
          </Box>
        )}
        <Box mb={1} mt={3}>
          <Typography component="h3" variant="subtitle2">
            {t('Attachments')}
          </Typography>
          <Box mt={1}>
            <FileUpload
              allowedExtensions={['jpg', 'jpeg', 'png', 'gif', 'pdf', 'ppt', 'doc', 'xls', 'txt']}
              focusableRef={submitButtonRef}
              maxAttachmentsAllowed={3}
              maxFileSize={5 * byteToMegaByteCoeff}
              onFileAdd={(file) => {
                setFilesToUpload((state) => [...state, file]);
              }}
              onFileRemove={(file) => {
                if (file.id) {
                  setRemovedAttachments((state) => [
                    ...state,
                    valuesToEdit?.attachments.find((el) => el.id === file.id),
                  ]);
                }
                setFilesToUpload((state) => [...state.filter((x) => x !== file)]);
              }}
              uploadedFiles={uploadedFiles}
            />
          </Box>
        </Box>
        <Box mb={2} mt={5}>
          <InformationalCaption
            title={t('The evidence of growth is visible for your educators and parents in AYO')}
          />
        </Box>
        <Box pb={2} pt={3}>
          <Grid container direction={isWidthUpSm ? 'row' : 'column-reverse'} spacing={2}>
            <Grid item sm={6} xs={12}>
              <Button fullWidth gaLabel="Cancel" onClick={cancelHandler} variant="text">
                {t('Cancel')}
              </Button>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Button
                fullWidth
                gaLabel={valuesToEdit ? 'Save' : 'Submit'}
                onClick={handleSubmitClick}
                variant="primary"
              >
                {t(valuesToEdit ? 'Save' : 'Submit')}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

EvidenceOfGrowthForm.propTypes = {
  actionSteps: PropTypes.arrayOf(goalActionStepItem),
  assessmentKey: PropTypes.string,
  closeDialog: PropTypes.func.isRequired,
  evidenceChangeHandler: PropTypes.func,
  goalDetails: goalDetailsItem,
  isActionStepDisabled: PropTypes.bool,
  valuesToEdit: evidenceOfGrowthItem,
};

EvidenceOfGrowthForm.defaultProps = {
  actionSteps: null,
  assessmentKey: '',
  evidenceChangeHandler: () => {},
  goalDetails: null,
  isActionStepDisabled: false,
  valuesToEdit: null,
};

export default EvidenceOfGrowthForm;
