import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { Card, Typography } from '../../../../../../atoms';
import { TextBadge } from '../../../../../../moleculas';
import { AssignmentInsightsCategoriesIllustrations } from '../../shared';
import { GA } from '../../../../../../../utils';
import { GaActions, GaCategories } from '../../../../../../../constants/enums';

const InsightCategoryCard = ({
  categoryName,
  categoryDistributedStudents,
  totalStudents,
  isSuggested,
  onClick,
}) => {
  const { t } = useTranslation();
  return (
    <Card
      aria-label={`${t('AYO insight assignments card aria label', {
        categoryName: t(`assignmentsInsightCategories.${categoryName}`),
        categoryDistributedStudents,
        totalStudents,
      })} ${isSuggested ? t('Suggested') : ''}`}
      className="ayo-assignments-insights-card ayo-clickable-card"
      component="button"
      mainContent={
        <Box>
          {isSuggested && (
            <TextBadge
              className="ayo-assignments-insights-card__suggested-badge"
              text={t('Suggested')}
            />
          )}
          <Typography className="ayo-assignments-insights-card__name" variant="subtitle2">
            {t(`assignmentsInsightCategories.${categoryName}`)}
          </Typography>
          <Box className="ayo-assignments-insights-card__stats">
            <Typography variant="h1">{categoryDistributedStudents}</Typography>
            <Typography isLabel variant="body2">
              / {totalStudents}
            </Typography>
          </Box>
        </Box>
      }
      mediaContent={AssignmentInsightsCategoriesIllustrations[categoryName]}
      onClick={() => {
        onClick(categoryName);
        GA.logInteraction({
          category: GaCategories.BEHAVIOR,
          action: GaActions.CARD_CLICK,
          label: `${categoryName} Assignments insights`,
        });
      }}
    />
  );
};

InsightCategoryCard.propTypes = {
  categoryName: PropTypes.string.isRequired,
  categoryDistributedStudents: PropTypes.number.isRequired,
  totalStudents: PropTypes.number.isRequired,
  isSuggested: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

InsightCategoryCard.defaultProps = {
  isSuggested: false,
};

export default InsightCategoryCard;
