/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Box, Menu, IconButton, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Typography from '../typography/Typography';
import Button from '../button/Button';
import { GA } from '../../../utils';
import { GaCategories, GaActions } from '../../../constants/enums';
import { ReactComponent as MoreHorizIcon } from '../../../resources/icons/more_horiz.svg';

const ActionsMenu = ({
  activatorIcon,
  secondaryIcon,
  activatorVariant,
  anchorOrigin,
  gaLabel,
  id,
  label,
  menuItems,
  paperClassName,
  transformOrigin,
  disabled,
  noIconsRecolor,
  ActivatorWrapper,
  ActivatorComponent,
  activatorProps,
  onClickHandler,
  onCloseHandler,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    event.stopPropagation();
    onClickHandler?.();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event?.stopPropagation();
    onCloseHandler?.();
    setAnchorEl(null);
  };

  const { t } = useTranslation();

  return (
    <>
      {label ? (
        <ActivatorWrapper>
          <ActivatorComponent
            aria-controls={`long_menu_${id}`}
            aria-haspopup="true"
            aria-label={activatorProps.ariaLabel || label || t('Actions')}
            className={classNames('ayo-expandable-menu-title', activatorProps.classNames)}
            disabled={disabled}
            gaLabel={gaLabel}
            onClick={handleClick}
            variant={activatorVariant}
            {...activatorProps}
          >
            <Box mr={1.5}>{label}</Box>
            {secondaryIcon ? (anchorEl ? secondaryIcon : activatorIcon) : activatorIcon}
          </ActivatorComponent>
        </ActivatorWrapper>
      ) : (
        <ActivatorWrapper>
          <IconButton
            aria-controls={`long_menu_${id}`}
            aria-haspopup="true"
            aria-label="more"
            className="ayo-icon-button"
            disabled={disabled}
            disableRipple
            id={`activator_icon_${id}`}
            onClick={(e) => {
              if (gaLabel) {
                GA.logInteraction({
                  category: GaCategories.BEHAVIOR,
                  action: GaActions.BUTTON_CLICK,
                  label: gaLabel,
                });
              }
              handleClick(e);
            }}
          >
            {secondaryIcon ? (anchorEl ? secondaryIcon : activatorIcon) : activatorIcon}
          </IconButton>
        </ActivatorWrapper>
      )}
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        className="ayo-expandable-menu--popover"
        disableEnforceFocus
        id={`long_menu_${id}`}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{ className: classNames('ayo-expandable-menu', paperClassName) }}
        transformOrigin={transformOrigin}
      >
        {menuItems.map((menuItem) => (
          <MenuItem
            key={menuItem.id}
            aria-label={menuItem.ariaLabel}
            className={menuItem.className || ''}
            disableRipple
            onClick={(e) => {
              handleClose(e);
              menuItem.handler();
              if (menuItem.gaLabel) {
                GA.logInteraction({
                  category: GaCategories.BEHAVIOR,
                  action: GaActions.EXTENDABLE_MENU_ITEM_CLICK,
                  label: menuItem.gaLabel,
                });
              }
            }}
            selected={menuItem.selected}
          >
            <Box alignItems="center" display="flex">
              {menuItem.icon && (
                <menuItem.icon
                  className={classNames({
                    'no-recolor': noIconsRecolor || menuItem.className,
                  })}
                  style={{ marginRight: '8px' }}
                />
              )}
              {menuItem.text && <Typography variant="body2">{menuItem.text}</Typography>}
              {menuItem.content}
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

ActionsMenu.propTypes = {
  activatorIcon: PropTypes.node,
  secondaryIcon: PropTypes.node,
  activatorVariant: PropTypes.oneOf(['primary', 'secondary', 'text']),
  anchorOrigin: PropTypes.instanceOf(Object),
  gaLabel: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  menuItems: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  paperClassName: PropTypes.string,
  transformOrigin: PropTypes.instanceOf(Object),
  noIconsRecolor: PropTypes.bool,
  disabled: PropTypes.bool,
  ActivatorWrapper: PropTypes.element,
  ActivatorComponent: PropTypes.element,
  activatorProps: PropTypes.instanceOf(Object),
  onClickHandler: PropTypes.func,
  onCloseHandler: PropTypes.func,
};

ActionsMenu.defaultProps = {
  activatorIcon: <MoreHorizIcon />,
  secondaryIcon: null,
  activatorVariant: 'text',
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  gaLabel: '',
  label: null,
  paperClassName: '',
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
  noIconsRecolor: false,
  disabled: false,
  ActivatorWrapper: React.Fragment,
  ActivatorComponent: Button,
  activatorProps: {},
  onClickHandler: null,
  onCloseHandler: null,
};

export default ActionsMenu;
