import { useCallback, useContext } from 'react';

import { useProfile } from '../../utils';
import { UserActions, UserContext } from '../../context';
import { useOneTimeActionService } from '../../services';

const useOneTimeAction = (action) => {
  const { dispatch: dispatchUserState } = useContext(UserContext);
  const { getOneTimeActionStatus } = useProfile();
  const { postOneTimeAction } = useOneTimeActionService();
  const actionState = getOneTimeActionStatus(action);
  const onAction = useCallback(() => {
    postOneTimeAction(action);
    dispatchUserState({ type: UserActions.SET_ONE_TIME_ACTION, data: action });
  }, [action, dispatchUserState, postOneTimeAction]);

  return { actionState, onAction };
};

export default useOneTimeAction;
