import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  TimelineConnector,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab';
import classnames from 'classnames';

import { ActionsMenu, Button, Timeline, Typography } from '../../../../../../atoms';
import { Chip, InformationalCaption, TextWithTooltip } from '../../../../../../moleculas';
import {
  ActionStepCreateDialog,
  ConfirmDialog,
  EvidenceOfGrowthBlock,
  MilestoneCard,
} from '../../../../../../organisms';
import { SharedDataActions, SharedDataContext, UserContext } from '../../../../../../../context';
import { useStudentsService } from '../../../../../../../services';
import { dateToFormattedString } from '../../../../../../../utils';
import { useEvidencesData } from '../../../../../../../hooks';
import { editRoute, goalsRoute, trainAyoRoute } from '../../../../../../../constants/routes';
import { RolesMap } from '../../../../../../../constants/enums';
import { evidencesConfigByType } from '../../../../../../../constants/configs';
import {
  GoalsMilestonesStatuses,
  GoalSnackBarMessages,
  GoalsStatuses,
  GoalDialogContent,
  GoalReporterRoles,
} from '../../../../../../../constants/goals';
import { ReactComponent as AddIcon } from '../../../../../../../resources/icons/add.svg';
import { ReactComponent as CreateIcon } from '../../../../../../../resources/icons/create.svg';
import { ReactComponent as DeleteIcon } from '../../../../../../../resources/icons/delete_primary.svg';
import useSnackbar from '../../../../../../../hooks/use-snackbar/useSnackbar';

const inProgressMilestones = (milestones) =>
  milestones.filter((milestone) => milestone.status === GoalsMilestonesStatuses.IN_PROGRESS);

const dynamicGoalStatus = (goalStatus, milestones) => {
  if (goalStatus === GoalsMilestonesStatuses.COMPLETED) {
    return GoalsStatuses.COMPLETED;
  }
  if (inProgressMilestones(milestones).length === milestones.length) {
    return GoalsStatuses.CREATED;
  }
  return GoalsStatuses.IN_PROGRESS;
};

const MAX_EVIDENCES_COUNT = 3;

const GoalViewForm = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const match = useRouteMatch();
  const history = useHistory();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  const { state: userState } = useContext(UserContext);
  const { state: sharedDataState, dispatch: dispatchSharedData } = useContext(SharedDataContext);

  const {
    getStudentGoalDetails,
    updateStudentGoal,
    updateStudentGoalMilestone,
    completeStudentGoal,
    deleteStudentGoal,
  } = useStudentsService();

  const { setSnackBarStatus } = useSnackbar();

  const [studentGoal, setStudentGoal] = useState({
    name: '',
    description: '',
    milestones: [],
    leadershipAttributeGroupKey: null,
    leadershipAttributeKey: null,
    reporter: {},
  });

  const profileData = userState?.profile;
  const isStudent = profileData.role === RolesMap.STUDENT;
  const isTeacher =
    profileData.role === RolesMap.TEACHER || profileData.role === RolesMap.ADMINISTRATOR;
  const isGuardian = profileData.role === RolesMap.GUARDIAN;
  const isReporter = profileData.id === studentGoal.reporter?.id;

  const isGoalCompleted = studentGoal.status === GoalsMilestonesStatuses.COMPLETED;
  const hasGoalActionSteps = studentGoal.milestones?.length > 0;

  const { goalId, studentId } = match.params;
  const currentStudentId = isStudent ? profileData.id : studentId;

  const { loadEvidences, relatedEvidences } = useEvidencesData(
    currentStudentId,
    evidencesConfigByType.goal.type,
  );

  const relatedEvidence = relatedEvidences?.filter(
    (evidence) =>
      evidence.evidenceResourcesByType.GOAL.parentResourceId === goalId &&
      studentGoal.milestones.find(
        (milestone) =>
          milestone.id.toString() === evidence.evidenceResourcesByType.GOAL.resourceId.toString(),
      ),
  );

  const [editingMilestoneIndex, setEditingMilestoneIndex] = useState(null);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isGoalMilestoneDialog, setIsGoalMilestoneDialogOpen] = useState(false);
  const [isOneActionStepLeftStatusOpen, setIsOneActionStepLeftStatusOpen] = useState(false);

  const [accessibilityActionStepMessage, setAccessibilityActionStepMessage] = useState('');

  const goalSnackBarMessages = isGuardian
    ? {}
    : GoalSnackBarMessages[
        profileData.role === RolesMap.ADMINISTRATOR ? RolesMap.TEACHER : profileData.role
      ];

  const closeGoalMilestoneDialogHandler = () => {
    setIsGoalMilestoneDialogOpen(false);
    setEditingMilestoneIndex(null);
  };

  const openGoalMilestoneDialogHandler = useCallback(() => {
    setIsGoalMilestoneDialogOpen(true);
    setAccessibilityActionStepMessage('');
  }, []);

  const onGoalUpdateHandler = useCallback(
    (newGoal, index) => {
      updateStudentGoal(newGoal, goalId, currentStudentId)
        .then((data) => {
          setStudentGoal(data);
          if (index !== null) {
            setTimeout(() => {
              setAccessibilityActionStepMessage('Action step has been edited');
            }, 1000);
          } else {
            setSnackBarStatus({
              text: t(goalSnackBarMessages.ACTION_STEP_CREATED),
              type: 'success',
            });
          }
        })
        .catch(() => {
          setSnackBarStatus({
            text: t(goalSnackBarMessages.GOAL_EDITED_ERROR),
            type: 'error',
          });
        });
    },
    [
      updateStudentGoal,
      goalId,
      currentStudentId,
      setSnackBarStatus,
      t,
      goalSnackBarMessages.ACTION_STEP_CREATED,
      goalSnackBarMessages.GOAL_EDITED_ERROR,
    ],
  );

  const onGoalMilestonesChange = useCallback(
    (milestone, index) => {
      if (index !== null) {
        onGoalUpdateHandler(
          {
            ...studentGoal,
            milestones: Object.assign([...studentGoal.milestones], {
              [index]: {
                ...studentGoal.milestones[index],
                name: milestone.name,
                dueDate: dateToFormattedString(milestone.dueDate),
              },
            }),
          },
          index,
        );
      } else {
        onGoalUpdateHandler(
          {
            ...studentGoal,
            milestones: [
              {
                ...milestone,
                dueDate: dateToFormattedString(milestone.dueDate),
                reporterId: profileData.id,
              },
              ...studentGoal.milestones,
            ],
          },
          index,
        );
      }
      setEditingMilestoneIndex(null);
      setIsGoalMilestoneDialogOpen(false);
    },
    [onGoalUpdateHandler, studentGoal, profileData.id],
  );

  const [deletionMilestioneIndex, setDeletionMilestioneIndex] = useState(null);
  const onGoalMilestoneDeleteStart = useCallback((milestoneIndex) => {
    setDeletionMilestioneIndex(milestoneIndex);
  }, []);

  const onGoalMilestioneDeleteEnd = useCallback(() => setDeletionMilestioneIndex(null), []);

  const onGoalMilestonesDelete = useCallback(() => {
    onGoalUpdateHandler({
      ...studentGoal,
      milestones: studentGoal.milestones.filter((el, index) => index !== deletionMilestioneIndex),
    });
    setSnackBarStatus({
      text: t(goalSnackBarMessages.ACTION_STEP_DELETED),
      type: 'delete',
    });
    setDeletionMilestioneIndex(null);
  }, [
    onGoalUpdateHandler,
    studentGoal,
    setSnackBarStatus,
    t,
    goalSnackBarMessages.ACTION_STEP_DELETED,
    deletionMilestioneIndex,
  ]);

  const onDeleteDialogCloseHandler = () => setIsDeleteDialogOpen(false);

  const onGoalMilestonesEditButtonClick = useCallback((milestone, index) => {
    setIsGoalMilestoneDialogOpen(true);
    setEditingMilestoneIndex(index);
  }, []);

  const onGoalMilestonesStatusChange = useCallback(
    (e) => {
      const { checked, name } = e.target;
      if (isTeacher && checked) {
        setSnackBarStatus({
          text: t(goalSnackBarMessages.ACTION_STEP_COMPLETED),
          type: 'success',
        });
      }
      const milestoneId = name;
      const milestoneStatus = checked
        ? GoalsMilestonesStatuses.COMPLETED
        : GoalsMilestonesStatuses.IN_PROGRESS;
      const milestoneIndex = studentGoal.milestones.findIndex((el) => el.id === +name);
      setStudentGoal((prevState) => ({
        ...prevState,
        milestones: Object.assign([...prevState.milestones], {
          [milestoneIndex]: {
            ...prevState.milestones[milestoneIndex],
            status: milestoneStatus,
          },
        }),
      }));
      if (
        (checked && isTeacher) ||
        (!checked && !studentGoal.milestones[milestoneIndex].reflection)
      ) {
        updateStudentGoalMilestone(
          { newMilestoneStatus: milestoneStatus },
          studentGoal.id,
          milestoneId,
          currentStudentId,
        );
      }
    },
    [
      currentStudentId,
      goalSnackBarMessages.ACTION_STEP_COMPLETED,
      isTeacher,
      setSnackBarStatus,
      studentGoal.id,
      studentGoal.milestones,
      t,
      updateStudentGoalMilestone,
    ],
  );

  const onGoalMilestonesReflectionCreate = useCallback(
    (milestoneId, reflection) => {
      const milestoneIndex = studentGoal.milestones.findIndex((el) => el.id === milestoneId);
      setStudentGoal((prevState) => ({
        ...prevState,
        milestones: Object.assign([...prevState.milestones], {
          [milestoneIndex]: {
            ...prevState.milestones[milestoneIndex],
            status: GoalsMilestonesStatuses.COMPLETED,
            reflection,
          },
        }),
      }));
      updateStudentGoalMilestone(
        { newMilestoneStatus: GoalsMilestonesStatuses.COMPLETED, reflection },
        studentGoal.id,
        milestoneId,
        currentStudentId,
      );
      setIsOneActionStepLeftStatusOpen(true);
    },
    [currentStudentId, studentGoal.id, studentGoal.milestones, updateStudentGoalMilestone],
  );

  const onGoalMilestonesReflectionDelete = useCallback(
    (milestoneId, { shouldProceedWithDeleting }) => {
      const milestoneIndex = studentGoal.milestones.findIndex((el) => el.id === milestoneId);
      if (shouldProceedWithDeleting) {
        setStudentGoal((prevState) => ({
          ...prevState,
          milestones: Object.assign([...prevState.milestones], {
            [milestoneIndex]: {
              ...prevState.milestones[milestoneIndex],
              reflection: null,
            },
          }),
        }));
        updateStudentGoalMilestone(
          { newMilestoneStatus: GoalsMilestonesStatuses.IN_PROGRESS },
          studentGoal.id,
          milestoneId,
          currentStudentId,
        );
      } else {
        setStudentGoal((prevState) => ({
          ...prevState,
          milestones: Object.assign([...prevState.milestones], {
            [milestoneIndex]: {
              ...prevState.milestones[milestoneIndex],
              status: GoalsMilestonesStatuses.COMPLETED,
            },
          }),
        }));
      }
    },
    [currentStudentId, studentGoal.id, studentGoal.milestones, updateStudentGoalMilestone],
  );

  const onGoalCompleteHandler = useCallback(() => {
    completeStudentGoal(goalId, currentStudentId).then(() => {
      setSnackBarStatus({
        text: t(goalSnackBarMessages.GOAL_COMPLETED),
        type: 'success',
      });
      history.push(
        `${trainAyoRoute}/${studentId}${goalsRoute}${isStudent ? '?showFeedbackPopup=true' : ''}`,
      );
    });
  }, [
    completeStudentGoal,
    currentStudentId,
    goalId,
    goalSnackBarMessages.GOAL_COMPLETED,
    history,
    isStudent,
    setSnackBarStatus,
    studentId,
    t,
  ]);

  const onGoalDeleteHandler = useCallback(() => {
    onDeleteDialogCloseHandler();
    deleteStudentGoal(goalId, currentStudentId).then(() => {
      setSnackBarStatus({
        text: t(goalSnackBarMessages.GOAL_DELETED),
        type: 'delete',
      });
      history.push(`${trainAyoRoute}/${studentId}${goalsRoute}`);
      loadEvidences();
    });
  }, [
    currentStudentId,
    deleteStudentGoal,
    goalId,
    goalSnackBarMessages.GOAL_DELETED,
    history,
    loadEvidences,
    setSnackBarStatus,
    studentId,
    t,
  ]);

  const getChipLabel = useCallback(
    (evidence) =>
      studentGoal?.milestones.find(
        (milestone) =>
          milestone.id === parseInt(evidence.evidenceResourcesByType.GOAL.resourceId, 10),
      )?.name,
    [studentGoal],
  );

  useEffect(() => {
    if (goalId) {
      getStudentGoalDetails(currentStudentId, goalId).then((goal) => {
        setStudentGoal(goal);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStudentId, getStudentGoalDetails, goalId, setStudentGoal]);

  useEffect(() => {
    if (
      inProgressMilestones(studentGoal.milestones).length === 1 &&
      isOneActionStepLeftStatusOpen &&
      isStudent
    ) {
      setSnackBarStatus({
        text: t(goalSnackBarMessages?.ONE_ACTION_STEP_LEFT),
        type: 'info',
      });
    }
    setIsOneActionStepLeftStatusOpen(false);
  }, [
    goalSnackBarMessages?.ONE_ACTION_STEP_LEFT,
    isOneActionStepLeftStatusOpen,
    isStudent,
    onGoalUpdateHandler,
    setSnackBarStatus,
    studentGoal,
    studentGoal.milestones,
    t,
  ]);

  useEffect(() => {
    if (sharedDataState.relatedToEvidenceGoal) {
      setStudentGoal(sharedDataState.relatedToEvidenceGoal);
      dispatchSharedData({
        type: SharedDataActions.SET_RELATED_TO_EVIDENCE_GOAL,
        data: null,
      });
    }
  }, [dispatchSharedData, sharedDataState]);

  return (
    <Box className="ayo-goal-form">
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box display="flex" flexDirection="column" maxWidth={isWidthUpSm ? '50%' : '100%'} mb={1}>
          <TextWithTooltip
            headerLevel={1}
            rowsCount={2}
            title={studentGoal.name}
            titleVariant="h1"
          />
        </Box>
        {isReporter && !isGoalCompleted && (
          <Box ml={1}>
            <ActionsMenu
              id="goal-actions"
              menuItems={[
                {
                  text: t('Edit'),
                  icon: CreateIcon,
                  handler: () =>
                    history.push(
                      `${trainAyoRoute}/${studentId}${goalsRoute}/${goalId}${editRoute}`,
                    ),
                  id: 'edit',
                  gaLabel: 'Edit goal',
                },
                {
                  text: t('Delete'),
                  icon: DeleteIcon,
                  handler: () => {
                    setIsDeleteDialogOpen(true);
                  },
                  id: 'delete',
                  gaLabel: 'Delete a goal',
                },
              ]}
            />
          </Box>
        )}
      </Box>
      <Box display="flex" flexDirection="column" maxWidth={isWidthUpSm ? '50%' : '100%'} mb={5}>
        <TextWithTooltip rowsCount={2} title={studentGoal.description} titleVariant="body2" />
        <Box mt={1}>
          <Typography className="ayo-goal-form__status" noWrap variant="body2">
            ({t(dynamicGoalStatus(studentGoal.status, studentGoal.milestones))})
          </Typography>
        </Box>
        {studentGoal.leadershipAttributeKey && (
          <Box alignItems="center" display="flex" mt={5}>
            <Typography variant="body2">{t('Related Leadership Attribute')}</Typography>
            <Box ml={1}>
              <Chip isOutlined label={t(studentGoal.leadershipAttributeKey)} />
            </Box>
          </Box>
        )}
      </Box>
      <Grid container>
        <Grid item sm={6} xs={12}>
          <Typography component="h2" variant="subtitle1">
            {t('Action steps')}
          </Typography>
          {!isReporter && hasGoalActionSteps && !isGuardian && (
            <Box mt={1}>
              <Typography variant="body2">
                {t('You can edit or delete only the action steps that you added')}
              </Typography>
            </Box>
          )}
          {isStudent && !hasGoalActionSteps && (
            <Box mt={2}>
              <InformationalCaption title="You need at least 1 action step" />
            </Box>
          )}
          {!hasGoalActionSteps && !isStudent && (
            <Box mt={2}>
              <InformationalCaption
                title={
                  isTeacher
                    ? 'Edit the goal to add action steps'
                    : 'There are no action steps added to the goal so far'
                }
              />
            </Box>
          )}
          {hasGoalActionSteps && (
            <Box className="ayo-goal-form__milestone-timelines" mt={2}>
              <Timeline align="left">
                {studentGoal.milestones.map((milestone, index) => (
                  <TimelineItem key={milestone.id}>
                    <TimelineSeparator>
                      <TimelineDot
                        className={`${
                          milestone.status === GoalsMilestonesStatuses.COMPLETED &&
                          'ayo-timelines-completed'
                        }`}
                      />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineOppositeContent>
                      <MilestoneCard
                        actionStep={milestone}
                        className={classnames('ayo-goal-form__milestone-timelines__card', {
                          'ayo-goal-form__milestone-timelines__card--no-margin':
                            index === studentGoal.milestones.length - 1,
                        })}
                        goalDetails={(({ id, leadershipAttributeKey, name, status }) => ({
                          id,
                          leadershipAttributeKey,
                          name,
                          status,
                        }))(studentGoal)}
                        index={index}
                        onMilestoneDelete={onGoalMilestoneDeleteStart}
                        onMilestoneEdit={onGoalMilestonesEditButtonClick}
                        onReflectionCreate={onGoalMilestonesReflectionCreate}
                        onReflectionDelete={onGoalMilestonesReflectionDelete}
                        onStatusChange={onGoalMilestonesStatusChange}
                        showActionsMenu={
                          profileData.id === milestone.reporterId &&
                          !isReporter &&
                          milestone.status !== GoalsMilestonesStatuses.COMPLETED
                        }
                        showAddEvidenceButton={
                          isStudent &&
                          studentGoal.status !== GoalsMilestonesStatuses.COMPLETED &&
                          (milestone.evidenceIds?.length < MAX_EVIDENCES_COUNT ||
                            !milestone.evidenceIds)
                        }
                        showCheckbox={!isGuardian}
                        showViewButton={milestone.reflection || milestone.evidenceIds}
                        studentId={+studentId}
                      />
                    </TimelineOppositeContent>
                  </TimelineItem>
                ))}
              </Timeline>
            </Box>
          )}
          {!isReporter && !isGoalCompleted && !isGuardian && (
            <Box ml={hasGoalActionSteps ? 3.5 : 8} mt={hasGoalActionSteps ? 2 : 3}>
              <Button
                disableElevation
                disableRipple
                endIcon={<AddIcon />}
                gaLabel="Add an action step"
                onClick={openGoalMilestoneDialogHandler}
              >
                {t('Add an action step')}
              </Button>
            </Box>
          )}
        </Grid>
        {studentGoal.id && (
          <Grid item sm={12} xs={12}>
            <Box mt={isWidthUpSm ? 5 : 7}>
              <EvidenceOfGrowthBlock
                evidenceType={evidencesConfigByType.goal.type}
                filteredEvidenceByEntity={goalId}
                getChipLabelHandler={getChipLabel}
                goals={[studentGoal]}
                headerLevel={3}
                hideEmptyState={isStudent && (!studentGoal.milestones.length || relatedEvidence)}
                isActionButtonsAvailable={!isGoalCompleted}
                isAddEvidenceAvailable={
                  !isGoalCompleted &&
                  !!studentGoal.milestones.filter(
                    (milestone) =>
                      milestone.evidenceIds?.length < MAX_EVIDENCES_COUNT || !milestone.evidenceIds,
                  ).length
                }
                relatedEvidences={relatedEvidence}
                studentId={studentId}
                titleVariant="subtitle1"
              />
            </Box>
          </Grid>
        )}
        <Grid item sm={6} xs={12}>
          {(isReporter || isStudent) && !isGoalCompleted && hasGoalActionSteps && (
            <Box>
              <Box mb={5} mt={isReporter ? 5 : 3}>
                <InformationalCaption title="The goal won’t be editable once it’s completed" />
              </Box>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={9}>
                  <Button
                    fullWidth
                    gaLabel="Complete goal"
                    onClick={onGoalCompleteHandler}
                    variant="primary"
                  >
                    {t('Complete goal')}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )}
          <Box mt={5}>
            <Typography variant="body3">
              {t('Created by')}:{' '}
              {`${studentGoal.reporter?.firstName}${
                studentGoal.reporter?.lastName && ` ${studentGoal.reporter?.lastName}`
              }, ${t(GoalReporterRoles[studentGoal.reporter?.role])}`}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <ActionStepCreateDialog
        isOpen={isGoalMilestoneDialog}
        milestone={studentGoal.milestones[editingMilestoneIndex]}
        milestoneIndex={editingMilestoneIndex}
        onClose={closeGoalMilestoneDialogHandler}
        onSave={onGoalMilestonesChange}
      />
      <ConfirmDialog
        cancelButtonTitle="Don't delete"
        confirmButtonTitle="Delete"
        isOpen={isDeleteDialogOpen}
        onClose={onDeleteDialogCloseHandler}
        onConfirm={onGoalDeleteHandler}
        text={GoalDialogContent.CONFIRM_DIALOG_DELETE_GOAL.text}
        title={GoalDialogContent.CONFIRM_DIALOG_DELETE_GOAL.title}
      />
      <ConfirmDialog
        cancelButtonTitle="Don't delete"
        confirmButtonTitle="Delete"
        isOpen={Number.isInteger(deletionMilestioneIndex)}
        onClose={onGoalMilestioneDeleteEnd}
        onConfirm={onGoalMilestonesDelete}
        text={GoalDialogContent.CONFIRM_DIALOG_DELETE_ACTION_STEP.text}
        title={GoalDialogContent.CONFIRM_DIALOG_DELETE_ACTION_STEP.title}
      />
      <div className="sr-only" role="alert">
        {t(accessibilityActionStepMessage)}
      </div>
    </Box>
  );
};

export default GoalViewForm;
