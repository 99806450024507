import React, { useContext, useEffect, useMemo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Button } from '../../../atoms';
import { PageIllustrationBlock, ScreenCenteredContainer } from '../../../moleculas';
import { PageWrapper } from '../../../organisms';
import { AppContext, AppActions, UserContext } from '../../../../context';
import { RolesMap, LoginProvidersMap, LocalStorageKeysMap } from '../../../../constants/enums';
import { useLogin } from '../../../../utils';
import { rootRoute } from '../../../../constants/routes';
import { RolesMainPages } from '../../../../constants/pages';
import { ReactComponent as Error403Image } from '../../../../resources/images/error_403_forbidden.svg';

const UnsupportedPage = () => {
  const { t } = useTranslation();
  const { state: userState } = useContext(UserContext);
  const { dispatch: dispatchAppState } = useContext(AppContext);
  const { expectedRole } = userState.profileError?.entity || { expectedRole: null };

  const classlinkUserConfig = useMemo(
    () => ({
      mainText: t(
        'Please log in to your ClassLink account, choosing your school name in the search box',
      ),
      buttonText: t('Log in with ClassLink'),
      loginProvider: LoginProvidersMap.CLASSLINK,
      loginRedirectKey: LocalStorageKeysMap.CLASSLINK_LOGIN_REDIRECT_URL,
    }),
    [t],
  );

  const ExpectedRolesContentMap = useMemo(
    () => ({
      [RolesMap.GUARDIAN]: {
        mainText: t(
          'Please log in to AYO with your Skyward account choosing the “parent role” in the dropdown',
        ),
        buttonText: t('Log in with Skyward'),
        loginProvider: LoginProvidersMap.SKYWARD,
        loginRedirectKey: LocalStorageKeysMap.SKYWARD_LOGIN_REDIRECT_URL,
        loginRedirectUrl: RolesMainPages[RolesMap.GUARDIAN],
      },
      [RolesMap.STUDENT]: {
        ...classlinkUserConfig,
        loginRedirectUrl: RolesMainPages[RolesMap.STUDENT],
      },
      [RolesMap.TEACHER]: {
        ...classlinkUserConfig,
        loginRedirectUrl: RolesMainPages[RolesMap.TEACHER],
      },
    }),
    [classlinkUserConfig, t],
  );

  const currentRoleContent = ExpectedRolesContentMap[expectedRole];

  // Used in login procedure useEffect hook
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const { proceedToLogin } = useLogin();

  const startLogin = useCallback(() => {
    dispatchAppState({
      type: AppActions.SET_LOGIN_PROVIDER,
      data: currentRoleContent?.loginProvider,
    });
    setShouldRedirect(true);
  }, [currentRoleContent, dispatchAppState]);

  // Used to proceed to login after button click starts login procedure
  useEffect(() => {
    if (shouldRedirect) {
      setShouldRedirect(false);
      proceedToLogin();
      sessionStorage.setItem(
        currentRoleContent?.loginRedirectKey,
        currentRoleContent?.loginRedirectUrl,
      );
    }
  }, [
    currentRoleContent?.loginRedirectKey,
    currentRoleContent?.loginRedirectUrl,
    proceedToLogin,
    shouldRedirect,
  ]);

  const history = useHistory();

  // TODO: rethink the way how this redirect is handled with more generic approach for the application
  useEffect(() => {
    if (!userState.profileError) {
      history.push(rootRoute);
    }
  }, [history, userState]);

  return (
    userState.profileError && (
      <PageWrapper noNav>
        <ScreenCenteredContainer>
          <PageIllustrationBlock
            Illustration={Error403Image}
            illustrationAriaLabel={t(
              'Stop sign in the middle of the road surrounded by safety cones',
            )}
            mainHeaderText={t("Uh oh, you can't get to AYO from here")}
            secondaryHeaderText={[currentRoleContent.mainText]}
          />
          <Button isAdaptive onClick={startLogin} variant="primary">
            {currentRoleContent.buttonText}
          </Button>
        </ScreenCenteredContainer>
      </PageWrapper>
    )
  );
};

export default UnsupportedPage;
