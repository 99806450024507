import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { FeedbackForm, PageWrapper } from '../../organisms';
import { AppActions, AppContext } from '../../../context';
import { SupportOptionsMap } from '../../../constants/enums';
import { familyFeedRoute, issueReportRoute } from '../../../constants/routes';

const IssueReportPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { state: appState, dispatch: dispatchAppState } = useContext(AppContext);

  const [prePopulatedFeedbackData, setPrePopulatedFeedbackData] = useState(null);

  const handleSubmitClick = useCallback(
    (success, issueTitleValue, feedbackValue, filesToUpload) => {
      if (success) {
        dispatchAppState({
          type: AppActions.SET_SNACKBAR_STATUS,
          data: {
            text: t('Thank you for reporting an issue. AYO will check it as soon as possible'),
            type: 'success',
          },
        });
        history.push(familyFeedRoute);
      } else {
        dispatchAppState({
          type: AppActions.SET_SNACKBAR_STATUS,
          data: {
            text: t('We couldn´t send your issue. Please try once more'),
            type: 'error',
            action: () => ({ issueTitleValue, feedbackValue, filesToUpload }),
          },
        });
        history.push(issueReportRoute);
      }
    },
    [dispatchAppState, history, t],
  );

  useEffect(() => {
    if (appState.snackbarStatus?.action) {
      setPrePopulatedFeedbackData(appState.snackbarStatus?.action);
    } else {
      setPrePopulatedFeedbackData({
        issueTitleValue: t('Repeated consent request reminder'),
        feedbackValue: t(
          'I’ve already given consent for my kid to use AYO. However, I was notified to provide it again. Please check the issue',
        ),
        filesToUpload: [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper mainElementClassName="ayo-issue-report" noLogo noNav>
      <Container>
        <Grid container>
          <Grid item md={6} xs={12}>
            <FeedbackForm
              gaLabel="Submit an issue"
              mode={SupportOptionsMap.REPORT_ISSUE}
              onSubmit={handleSubmitClick}
              prePopulatedFormData={prePopulatedFeedbackData}
              title={t('Report an issue')}
              titleLevel="h1"
              titleVariant="subtitle1"
            />
          </Grid>
        </Grid>
      </Container>
    </PageWrapper>
  );
};

export default IssueReportPage;
