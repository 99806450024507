import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

import { Button } from '../../../../../atoms';
import { Chip } from '../../../../../moleculas';
import { ReactComponent as CreateIcon } from '../../../../../../resources/icons/create.svg';
import ClubVisibilityDialog from '../../../club-hub-components/club-visibility-dialog/ClubVisibilityDialog';

const ClubDetailsVisibility = ({ availability, isEditable, onSave }) => {
  const { t } = useTranslation();

  const [isVisibilityDialogOpen, setIsVisibilityDialogOpen] = useState(false);

  return (
    <Box className="ayo-club-hub-details__visibility" mt={3}>
      <Box display="flex" flexDirection="row">
        <Box display="flex" flexWrap="wrap" gap={1} mb={2}>
          {availability.map((item) => (
            <Box key={item}>
              <Chip key={item} label={item} />
            </Box>
          ))}
        </Box>
        {isEditable && (
          <Box mx={1}>
            <Button
              aria-label={t('Edit - Specify club availability')}
              gaLabel="Edit club visibility"
              isIconButton
              onClick={() => setIsVisibilityDialogOpen(true)}
            >
              <CreateIcon />
            </Button>
          </Box>
        )}
        {isEditable && (
          <ClubVisibilityDialog
            entities={availability}
            isOpen={isVisibilityDialogOpen}
            onClose={() => {
              setIsVisibilityDialogOpen(false);
            }}
            onSave={onSave}
            primaryButtonText="Save changes"
            title="Edit - Specify club availability"
          />
        )}
      </Box>
    </Box>
  );
};

ClubDetailsVisibility.propTypes = {
  availability: PropTypes.string.isRequired,
  isEditable: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default ClubDetailsVisibility;
