import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import classNames from 'classnames';

import { InitiativeEmptyStateBlock } from '../../../../moleculas';
import { PortfolioSectionTypes } from '../../../../../constants/enums';
import { ReactComponent as EndorsementsEmptyStateIllustration } from '../../../../../resources/images/portfolio/test_scores_empty_state.svg';
import PortfolioSection from '../../components/portfolio-section/PortfolioSection';
import PortfolioTestScoreCard from '../../components/portfolio-test-score-card/PortfolioTestScoreCard';

const PortfolioSharedTestScoresSection = ({ sectionData }) => {
  const { t } = useTranslation();

  return (
    <PortfolioSection
      body={
        <Grid columnSpacing={3} container mt={3}>
          {sectionData.entities.map(({ examName, score }) => (
            <Grid key={examName} item sm={4}>
              <PortfolioTestScoreCard examName={examName} score={score} />
            </Grid>
          ))}
        </Grid>
      }
      className={`${classNames('ayo-full-screen-portfolio-section', {
        'ayo-portfolio__test-scores-section': sectionData.entities.length,
      })}`}
      emptyState={
        !sectionData.entities.length ? (
          <InitiativeEmptyStateBlock
            illustration={<EndorsementsEmptyStateIllustration />}
            title={t('No information about test scores so far')}
          />
        ) : null
      }
      reflection={sectionData.reflection}
      sectionKey={PortfolioSectionTypes.PORTFOLIO__TEST_SCORES}
      title={t('Test scores')}
    />
  );
};

PortfolioSharedTestScoresSection.propTypes = {
  sectionData: PropTypes.instanceOf(Object).isRequired,
};

export default PortfolioSharedTestScoresSection;
