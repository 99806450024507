import React from 'react';
import { Box } from '@mui/material';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { RichTextEditor } from '../../../../organisms';
import { convertChoiceColorToShadowColor } from '../assignment-insights/shared';

const ChoicesPathOptionBubble = React.forwardRef(
  ({ onClick, color, text, variant, additionalContent, isSelected, controlIcons }, ref) => (
    <Box
      ref={ref}
      className={classNames('choices-path__option', `choices-path__option--${variant}`, {
        checked: isSelected,
        clickable: !!onClick,
      })}
      onClick={onClick}
      style={{
        '--main-color': color,
        '--main-color-rgb': convertChoiceColorToShadowColor(color),
      }}
      tabIndex={onClick ? '0' : null}
    >
      <RichTextEditor isStatic value={text} />
      {additionalContent}
      {controlIcons}
    </Box>
  ),
);

ChoicesPathOptionBubble.propTypes = {
  onClick: PropTypes.func.isRequired,
  color: PropTypes.string,
  text: PropTypes.string.isRequired,
  variant: PropTypes.string,
  additionalContent: PropTypes.node,
  isSelected: PropTypes.bool,
  controlIcons: PropTypes.node,
};

ChoicesPathOptionBubble.defaultProps = {
  variant: 'dark',
  additionalContent: null,
  isSelected: false,
  color: '#f2f3ff',
  controlIcons: null,
};

export default ChoicesPathOptionBubble;
