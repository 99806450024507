import React from 'react';
import { useTranslation } from 'react-i18next';

import { BottomBar, PageWrapper } from '../../../organisms';
import { initiativesRoute } from '../../../../constants/routes';

import {
  LeadershipAttributesDefinitionSection,
  LeadershipAttributesExploreMoreSection,
  LeadershipAttributesGallerySection,
  LeadershipAttributesWhatIsSection,
  LeadershipAttributesHowDoesItWorkSection,
  LeadershipAttributesWhoEnjoyItSection,
} from './components/sections';

const LeadershipAttributesPage = () => {
  const { t } = useTranslation();

  return (
    <PageWrapper
      backToLink={initiativesRoute}
      backToText={t('Go to AYO’s initiatives page')}
      className="ayo-leadership-attributes-page"
      customFooter={<BottomBar noNav />}
      noNav
    >
      <LeadershipAttributesDefinitionSection />
      <LeadershipAttributesWhatIsSection />
      <LeadershipAttributesGallerySection />
      <LeadershipAttributesWhoEnjoyItSection />
      <LeadershipAttributesHowDoesItWorkSection />
      <LeadershipAttributesExploreMoreSection />
    </PageWrapper>
  );
};

export default LeadershipAttributesPage;
