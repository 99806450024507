export const GoalsStatuses = {
  IN_PROGRESS: 'In progress',
  CREATED: 'New',
  COMPLETED: 'Completed',
};

export const GoalsMilestonesStatuses = {
  IN_PROGRESS: 'IN_PROGRESS',
  CREATED: 'CREATED',
  COMPLETED: 'COMPLETED',
};

export const GoalReporterRoles = {
  TEACHER: 'educator',
  STUDENT: 'student',
  ADMINISTRATOR: 'educator',
};

export const GoalSnackBarMessages = {
  STUDENT: {
    GOAL_CREATED: "The goal has been created. Hope you'll succeed in this one",
    GOAL_DELETED: 'The goal has been deleted',
    GOAL_COMPLETED: 'Hooray! The goal has been achieved',
    GOAL_EDITED_SUCCESS: 'Thanks for your changes! Your input has been saved',
    GOAL_EDITED_ERROR: 'AYO couldn’t save your edits Please try once more',
    ACTION_STEP_CREATED: 'The action step has been created. Good luck in achieving it',
    ACTION_STEP_DELETED: 'The action step has been deleted',
    ONE_ACTION_STEP_LEFT: "Wow! You're close to reaching this goal",
  },
  TEACHER: {
    GOAL_CREATED: 'The goal has been created',
    GOAL_DELETED: 'The goal has been deleted',
    GOAL_COMPLETED: 'The goal has been completed',
    GOAL_EDITED_SUCCESS: 'Thanks for your changes! Your input has been saved',
    GOAL_EDITED_ERROR: 'AYO couldn’t save your edits Please try once more',
    ACTION_STEP_CREATED: 'The action step has been created',
    ACTION_STEP_DELETED: 'The action step has been deleted',
    ACTION_STEP_COMPLETED: 'The action step has been closed',
  },
};

export const GoalDialogContent = {
  LEAVE_DIALOG_EDIT: {
    title: 'Should we save your changes before you leave this page?',
    text: 'AYO will not get your updates until you save them Let’s save?',
  },
  LEAVE_DIALOG_CREATE: {
    title: 'Are you sure you want to leave?',
    text: 'You’re half-way done with creating a goal',
  },
  CONFIRM_DIALOG_DELETE_GOAL: {
    title: 'Are you sure you want to delete the goal?',
    text: 'If you confirm the deletion, you will delete the goal and the evidence related to it',
  },
  CONFIRM_DIALOG_DELETE_ACTION_STEP: {
    title: 'Are you sure you want to delete the action step?',
    text: 'If you confirm the deletion, you will delete the action step and the evidence related to it',
  },
  CONFIRM_DIALOG_UNCHECK_ACTION_STEP: {
    STUDENT: {
      title: 'You are about to mark your action step as incomplete',
      text: 'By doing so, the reflection you provided earlier will be deleted',
    },
    TEACHER: {
      title: 'You are about to mark the action step as incomplete',
      text: 'The reflection that the student provided earlier will be deleted',
    },
  },
};

export const GoalFormMode = {
  EDIT: 'edit',
  VIEW: 'view',
  CREATE: 'create',
};

export default {
  GoalsStatuses,
  GoalSnackBarMessages,
  GoalsMilestonesStatuses,
  GoalDialogContent,
};
