import React, { useContext, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Box, DialogContent, DialogActions } from '@mui/material';

import { AppContext, AppActions } from '../../../context';
import { Dialog, DialogTitle, Typography } from '../../atoms';
import { PopupActionsButtons } from '../../moleculas';

export const LeaveDialog = ({
  primaryButtonTitle,
  onPrimaryClick,
  secondaryButtonTitle,
  onSecondaryClick,
  shouldResetNextLocationRef,
  text,
  title,
}) => {
  const { t } = useTranslation();

  const { state: appState, dispatch: dispatchAppState } = useContext(AppContext);

  const onClose = (value) =>
    dispatchAppState({ type: AppActions.SET_IS_LEAVE_DIALOG_OPEN, data: value });

  const setIsDirty = (value) => dispatchAppState({ type: AppActions.SET_IS_DIRTY, data: value });

  useEffect(
    () => () => onClose(null),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const { resolver } = appState.onLogout;

  return (
    <Dialog
      className="ayo-leave-dialog"
      gaLabel="Leave"
      onClose={() => {
        onClose(null);
        if (resolver) {
          resolver(false);
        }
      }}
      open={appState.isLeaveDialogOpen}
    >
      <DialogTitle disableTypography>
        <Box mb={2}>
          <Typography component="h2" variant="subtitle1">
            {t(title)}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box mb={5}>
          <Typography variant="body2">
            <Trans components={{ b: <br /> }} i18nKey={text} />
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <PopupActionsButtons
          primaryButtonGaLabel={primaryButtonTitle}
          primaryButtonHandler={() => {
            if (onPrimaryClick) {
              onPrimaryClick();
            }

            if (shouldResetNextLocationRef) {
              setIsDirty(false);
            }
            onClose(false);
            if (resolver) {
              resolver(true);
            }
          }}
          primaryButtonText={t(primaryButtonTitle)}
          secondaryButtonGaLabel={secondaryButtonTitle}
          secondaryButtonHandler={() => {
            if (onSecondaryClick) {
              onSecondaryClick();
            }

            if (!shouldResetNextLocationRef) {
              setIsDirty(false);
            }
            onClose(shouldResetNextLocationRef ? null : false);
            if (resolver) {
              resolver(true);
            }
          }}
          secondaryButtonText={t(secondaryButtonTitle)}
        />
      </DialogActions>
    </Dialog>
  );
};

LeaveDialog.propTypes = {
  onPrimaryClick: PropTypes.func,
  onSecondaryClick: PropTypes.func,
  primaryButtonTitle: PropTypes.string,
  secondaryButtonTitle: PropTypes.string,
  shouldResetNextLocationRef: PropTypes.bool,
  text: PropTypes.string,
  title: PropTypes.string,
};

LeaveDialog.defaultProps = {
  onPrimaryClick: null,
  onSecondaryClick: null,
  text: 'AYO will not get your updates until you save them Let’s save?',
  title: 'Should we save your changes before you leave this page?',
  primaryButtonTitle: 'Save and leave',
  shouldResetNextLocationRef: false,
  secondaryButtonTitle: 'Do not save and leave',
};

export default LeaveDialog;
