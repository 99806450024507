/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Card as MUICard, CardActions, CardContent, CardHeader, CardMedia } from '@mui/material';

const Card = React.forwardRef(
  (
    {
      actions,
      bottomRibbon,
      cardHeader,
      className,
      href,
      leftRibbon,
      mainContent,
      mediaContent,
      mediaContentAriaLabel,
      imageSrc,
      ...rest
    },
    ref,
  ) => (
    <MUICard
      ref={ref}
      className={`${classNames(`ayo-card`, { [`${className}`]: className })}`}
      to={href}
      {...rest}
    >
      {leftRibbon}
      {cardHeader && <CardHeader title={cardHeader} />}
      {mediaContent && (
        <CardMedia
          aria-label={mediaContentAriaLabel}
          className="ayo-card__media"
          component={mediaContent}
          image={imageSrc}
        />
      )}
      {mainContent && <CardContent className="ayo-card__main-content">{mainContent}</CardContent>}
      {actions && <CardActions>{actions}</CardActions>}
      {bottomRibbon}
    </MUICard>
  ),
);

Card.propTypes = {
  actions: PropTypes.node,
  bottomRibbon: PropTypes.node,
  cardHeader: PropTypes.node,
  className: PropTypes.string,
  href: PropTypes.string,
  imageSrc: PropTypes.string,
  leftRibbon: PropTypes.node,
  mainContent: PropTypes.node,
  mediaContent: PropTypes.elementType,
  mediaContentAriaLabel: PropTypes.string,
};

Card.defaultProps = {
  actions: null,
  bottomRibbon: null,
  cardHeader: null,
  className: '',
  href: null,
  imageSrc: null,
  leftRibbon: null,
  mainContent: null,
  mediaContent: null,
  mediaContentAriaLabel: '',
};

export default Card;
