import React, { useContext } from 'react';
import { Box, Container, useTheme, useMediaQuery, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Button, Typography } from '../../../../../../atoms';
import { UserContext } from '../../../../../../../context';
import { useLandingPageConfig } from '../../../../../../../utils';
import { ReactComponent as LeadershipAttributesExploreMoreGallery } from '../../../../../../../resources/images/leadership_explore_more_gallery.svg';

const LeadershipAttributesExploreMoreSection = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { state: userState } = useContext(UserContext);

  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const userRole = userState.profile?.role;

  const { landingPageLoginSectionConfig, landingPageRedirectHandler } = useLandingPageConfig();

  if (!landingPageLoginSectionConfig?.length) {
    return null;
  }

  return (
    <Container>
      <Box mb={isWidthUpSm ? 10 : 5}>
        {userRole ? (
          <Box
            alignItems="center"
            className={`${classNames(
              'ayo-leadership-attributes-explore-more',
              'ayo-leadership-attributes-explore-more__logged-in',
            )}`}
            display="flex"
            flexDirection="column"
            pb={5}
            pt={5}
            px={2}
          >
            <Box>
              <LeadershipAttributesExploreMoreGallery className="ayo-svg-illustration--secondary" />
            </Box>
            <Box
              alignItems="center"
              className="ayo-leadership-attributes-explore-more__logged-in--text-block"
              display="flex"
              flexDirection="column"
              mb={3}
            >
              <Typography
                align="center"
                component="h2"
                isLightText
                paragraph
                variant={isWidthUpSm ? 'h2' : 'subtitle1'}
              >
                {t('Try the assessment tool')}
              </Typography>
              <Typography align="center" isLightText variant="body2">
                {t('Let’s go to Train AYO and see how the tool works')}
              </Typography>
            </Box>
            <Box className="ayo-leadership-attributes-explore-more__logged-in--button-wrapper">
              <Button
                fullWidth
                gaLabel="Go to Train AYO"
                onClick={() =>
                  landingPageRedirectHandler(
                    landingPageLoginSectionConfig[0].loginProvider,
                    landingPageLoginSectionConfig[0].loginRedirectKey,
                    landingPageLoginSectionConfig[0].leadershipLoginRedirectUrl,
                  )
                }
                variant="primary"
              >
                {t('Go to Train AYO')}
              </Button>
            </Box>
          </Box>
        ) : (
          <Grid container spacing={3}>
            <Grid item sm={12}>
              <Box
                className={`${classNames(
                  'ayo-leadership-attributes-explore-more',
                  'ayo-leadership-attributes-explore-more__logged-out',
                )}`}
                pb={isWidthUpSm ? 5 : 3.5}
                pt={5}
                px={2}
              >
                <LeadershipAttributesExploreMoreGallery className="ayo-svg-illustration--secondary" />
                <Box
                  alignItems="center"
                  display="flex"
                  flexDirection="column"
                  mb={isWidthUpSm ? 3 : 1.5}
                  mt={2}
                >
                  <Typography align="center" component="h2" isLightText variant="subtitle1">
                    {t('Are you interested in exploring more')}
                  </Typography>
                  <Typography align="center" component="h3" isLightText variant="subtitle1">
                    {t('Try the assessment tool now')}
                  </Typography>
                  <Box mt={1}>
                    <Typography align="center" isLightText variant="body2">
                      {t('Let’s go to Train AYO and see how the tool works')}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  alignItems="center"
                  display="flex"
                  flexDirection={isWidthUpSm ? 'row' : 'column'}
                >
                  {landingPageLoginSectionConfig.map((item) => (
                    <Box
                      key={item.label}
                      className="ayo-leadership-attributes-explore-more__logged-out--button-wrapper"
                      mx={isWidthUpSm ? 1.5 : 0}
                      my={!isWidthUpSm ? 1.5 : 0}
                    >
                      <Button
                        fullWidth
                        gaLabel={item.label}
                        onClick={() =>
                          landingPageRedirectHandler(
                            item.loginProvider,
                            item.loginRedirectKey,
                            item.leadershipLoginRedirectUrl,
                          )
                        }
                        variant="primary"
                      >
                        {t(item.label)}
                      </Button>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Grid>
          </Grid>
        )}
      </Box>
    </Container>
  );
};

export default LeadershipAttributesExploreMoreSection;
