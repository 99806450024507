import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, useTheme, useMediaQuery } from '@mui/material';

import { Typography } from '../../atoms';

const PageIllustrationBlock = ({
  Illustration,
  illustrationAriaLabel,
  mainHeaderText,
  mainHeaderVariant,
  secondaryHeaderText,
  secondaryHeaderVariant,
}) => {
  const theme = useTheme();
  const isWidthUpXl = useMediaQuery(theme.breakpoints.up('xl'));

  return (
    <>
      <Grid container item justifyContent="center">
        <Grid item md={6} xs={12}>
          <Illustration
            aria-label={illustrationAriaLabel}
            className="ayo-svg-illustration--secondary"
            role="img"
          />
        </Grid>
      </Grid>
      <Grid container item justifyContent="center">
        <Grid
          className="ayo-page-illustration-block__content"
          item
          justifyContent="center"
          md={6}
          xs={12}
        >
          <Box
            className="ayo-page-illustration-block__content__main-header"
            pb={isWidthUpXl ? 4 : 3}
          >
            <Typography align="center" component="h1" paragraph variant={mainHeaderVariant}>
              {mainHeaderText}
            </Typography>
            {secondaryHeaderText.map((item) => (
              <Typography key={item} align="center" variant={secondaryHeaderVariant}>
                {item}
              </Typography>
            ))}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

PageIllustrationBlock.propTypes = {
  Illustration: PropTypes.elementType,
  illustrationAriaLabel: PropTypes.string,
  mainHeaderText: PropTypes.string,
  mainHeaderVariant: PropTypes.string,
  secondaryHeaderText: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  ),
  secondaryHeaderVariant: PropTypes.string,
};

PageIllustrationBlock.defaultProps = {
  Illustration: React.Fragment,
  illustrationAriaLabel: '',
  mainHeaderText: '',
  mainHeaderVariant: 'h2',
  secondaryHeaderText: [],
  secondaryHeaderVariant: 'body2',
};

export default PageIllustrationBlock;
