import { useReactToPrint } from 'react-to-print';
import { useEffect, useRef, useState } from 'react';

const usePrint = (componentRef, bodyClass) => {
  const [isPrinting, setIsPrinting] = useState(false);
  const promiseResolveRef = useRef(null);

  useEffect(() => {
    if (isPrinting && promiseResolveRef.current) {
      promiseResolveRef.current();
    }
  }, [isPrinting]);

  const printHandler = useReactToPrint({
    bodyClass,
    content: () => componentRef.current,
    onBeforeGetContent: () =>
      new Promise((resolve) => {
        promiseResolveRef.current = resolve;
        setIsPrinting(true);
      }),
    onAfterPrint: () => {
      promiseResolveRef.current = null;
      setIsPrinting(false);
    },
  });

  return { printHandler };
};

export default usePrint;
