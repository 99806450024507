import React, { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, DialogActions, DialogContent, useMediaQuery, useTheme } from '@mui/material';

import { Button, Dialog, DialogTitle, Switch, Typography } from '../../../../atoms';
import { PopupActionsButtons } from '../../../../moleculas';
import { converterBooleanString } from '../../../../../utils';
import { NotificationNamesMap, SwitchLabels } from '../../../../../constants/enums';
import { AppActions, AppContext } from '../../../../../context';
import { useStudentsService } from '../../../../../services';

const PortfolioSettingsDialog = ({
  isOpen,
  portfolioSettings,
  setIsDialogOpen,
  settingsHandler,
  isPortfolioVisible,
  isWithVisibilitySetting,
  studentId,
  studentPortfolioId,
  changePortfolioVisibilityHandler,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const { patchStudentPortfolio } = useStudentsService();
  const { dispatch: dispatchAppState } = useContext(AppContext);

  const [dialogSettings, setDialogSettings] = useState();
  const [isPortfolioVisibleSwithOn, setIsPortfolioVisibleSwithOn] = useState();
  useEffect(() => {
    if (portfolioSettings) {
      setDialogSettings(portfolioSettings);
    }
    if (isWithVisibilitySetting) {
      setIsPortfolioVisibleSwithOn(isPortfolioVisible);
    }
  }, [portfolioSettings, isOpen, isPortfolioVisible, isWithVisibilitySetting]);

  const onSwitchChangeHandler = useCallback(
    (value, sectionKey) => {
      if (!dialogSettings) return;
      const settingIndex = dialogSettings.findIndex(
        ({ sectionName }) => sectionName === sectionKey,
      );
      setDialogSettings((prevState) =>
        Object.assign([...prevState], {
          [settingIndex]: {
            ...prevState[settingIndex],
            sectionSettings: prevState[settingIndex].sectionSettings.map((sectionSetting) => ({
              ...sectionSetting,
              value: converterBooleanString(value),
            })),
          },
        }),
      );
    },
    [dialogSettings],
  );

  const saveVisibility = useCallback(
    (value) => {
      const requestBody = {
        visible: value,
      };
      changePortfolioVisibilityHandler(value);
      return patchStudentPortfolio(studentId, studentPortfolioId, requestBody, false);
    },
    [studentId, studentPortfolioId, patchStudentPortfolio, changePortfolioVisibilityHandler],
  );

  const onSaveChanges = useCallback(() => {
    if (!dialogSettings) return;
    const settingsToUpdateArray = [];
    const updatedSettings = dialogSettings.filter(({ sectionName, sectionSettings }) => {
      const previousSettings = portfolioSettings.find(
        (setting) => setting.sectionName === sectionName,
      )?.sectionSettings[0];
      return (
        converterBooleanString(previousSettings?.value ?? previousSettings?.defaultValue, true) !==
        converterBooleanString(sectionSettings[0].value ?? sectionSettings[0].defaultValue, true)
      );
    });
    if (updatedSettings.length) {
      settingsToUpdateArray.push(settingsHandler(updatedSettings));
    }
    if (isWithVisibilitySetting && isPortfolioVisibleSwithOn !== isPortfolioVisible) {
      settingsToUpdateArray.push(saveVisibility(isPortfolioVisibleSwithOn));
    }
    if (settingsToUpdateArray.length) {
      Promise.all(settingsToUpdateArray)
        .then(() => {
          dispatchAppState({
            type: AppActions.SET_SNACKBAR_STATUS,
            data: {
              text: t('Thanks for your changes! Your input has been saved'),
              type: 'success',
            },
          });
        })
        .catch(() => {
          dispatchAppState({
            type: AppActions.SET_SNACKBAR_STATUS,
            data: {
              text: t('AYO couldn’t save your edits Please try once more'),
              type: 'error',
            },
          });
        });
    }
    setIsDialogOpen(false);
  }, [
    dialogSettings,
    portfolioSettings,
    setIsDialogOpen,
    settingsHandler,
    t,
    dispatchAppState,
    isPortfolioVisible,
    isPortfolioVisibleSwithOn,
    isWithVisibilitySetting,
    saveVisibility,
  ]);

  if (!dialogSettings) {
    return null;
  }

  return (
    <Dialog
      className="ayo-portfolio-settings-dialog"
      gaLabel="portfolio settings dialog"
      onClose={() => setIsDialogOpen(false)}
      open={isWidthUpSm && isOpen}
    >
      <DialogTitle disableTypography>
        <Typography component="h2" variant="subtitle1">
          {t('Settings')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box mt={1} py={2}>
          {isWithVisibilitySetting && (
            <Box mb={5}>
              <Typography component="h3" variant="subtitle2">
                {t('Visibility')}
              </Typography>
              <Box display="flex" justifyContent="space-between">
                <Typography aria-hidden variant="body2">
                  {t('Make this draft visible to my educators and parents in AYO.')}
                </Typography>
                <Switch
                  ariaLabel={t('Make this draft visible to my educators and parents in AYO.')}
                  checkedLabel={SwitchLabels.on}
                  gaLabel="Visibility"
                  name={t('Make this draft visible to my educators and parents in AYO.')}
                  onChange={(value) => setIsPortfolioVisibleSwithOn(value)}
                  uncheckedLabel={SwitchLabels.off}
                  value={isPortfolioVisibleSwithOn}
                />
              </Box>
            </Box>
          )}
          <Typography component="h3" variant="subtitle2">
            {t('Portfolio sections')}
          </Typography>
          {dialogSettings.map(({ sectionName, sectionSettings }) => (
            <Box key={sectionName} display="flex" justifyContent="space-between">
              <Box aria-hidden mt={1}>
                <Typography variant="body2">
                  {t(NotificationNamesMap.settings[sectionName])}
                </Typography>
              </Box>
              <Box mt={1}>
                <Switch
                  ariaLabel={`${t('Switch for section', {
                    section: t(NotificationNamesMap.settings[sectionName]),
                  })}`}
                  checkedLabel={SwitchLabels.on}
                  gaLabel={sectionName}
                  name={sectionName}
                  onChange={(value, name) => onSwitchChangeHandler(value, name)}
                  uncheckedLabel={SwitchLabels.off}
                  value={converterBooleanString(
                    sectionSettings[0].value ?? sectionSettings[0].defaultValue,
                    true,
                  )}
                />
              </Box>
            </Box>
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <PopupActionsButtons
          primaryButtonComponent={
            <Button fullWidth gaLabel="Save" onClick={onSaveChanges} variant="primary">
              {t('Save')}
            </Button>
          }
          secondaryButtonGaLabel="Cancel"
          secondaryButtonHandler={() => setIsDialogOpen(false)}
          secondaryButtonText={t('Cancel')}
        />
      </DialogActions>
    </Dialog>
  );
};

PortfolioSettingsDialog.propTypes = {
  portfolioSettings: PropTypes.instanceOf(Object).isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsDialogOpen: PropTypes.func.isRequired,
  settingsHandler: PropTypes.func.isRequired,
  isPortfolioVisible: PropTypes.bool.isRequired,
  isWithVisibilitySetting: PropTypes.bool.isRequired,
  studentId: PropTypes.string.isRequired,
  studentPortfolioId: PropTypes.string.isRequired,
  changePortfolioVisibilityHandler: PropTypes.func.isRequired,
};

export default PortfolioSettingsDialog;
