import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import {
  BottomBar,
  PageWrapper,
  PersonalizationBlock,
  PersonasBlock,
  ArticleBlock,
  InitiativesBlock,
  ImgWithCaption,
  QuoteBlock,
  MediaBlock,
  LandingBlock,
  RoadmapBlock,
  TryMobileAppBlock,
} from '../../../organisms';
import {
  homePageVisionBlock,
  homePagePersonalizationBlock,
  homePagePersonasBlock,
  homePageRoadmapBlock,
  homePageQuoteBlock,
  homePageMediaBlock,
  homePageLandingBlock,
  homePageInitiativesBlock,
  homePageTryMobileAppBlock,
} from '../../../../constants/home-page-blocks';
import { LocalStorageKeysMap } from '../../../../constants/enums';
import { useProfile, checkEnvironmentVariable } from '../../../../utils';
import { useCmsService } from '../../../../services';

const IS_MOBILE_APP_AVAILABLE = checkEnvironmentVariable(
  'REACT_APP_IS_MOBILE_APP_AVAILABLE',
  'true',
);

const HomePage = () => {
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const isWidthUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const { loadProfileAndProceed } = useProfile();
  const { getBlockConfig } = useCmsService();
  const [mainPageConfig, setMainPageConfig] = useState(null);
  const [homePageComponents, setHomePageComponents] = useState(null);

  useEffect(() => {
    loadProfileAndProceed({
      shouldProceed: sessionStorage.getItem(LocalStorageKeysMap.SKYWARD_LOGIN_REDIRECT_URL),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const loadMainConfig = async () => {
      const config = await getBlockConfig('home_page');
      setMainPageConfig(config);
    };
    loadMainConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const loadConfig = async () => {
      const components = [];

      await Promise.all(
        mainPageConfig.data.blocks.map(async (block) => {
          const blockConf = await getBlockConfig(block.path);
          components.push({
            name: block.name,
            config: blockConf.data.config,
          });
        }),
      );

      const orderedComponents = [];
      mainPageConfig.data.blocks.forEach((block) => {
        orderedComponents.push(components.find((c) => c.name === block.name));
      });

      setHomePageComponents(orderedComponents);
    };

    if (mainPageConfig) {
      loadConfig();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainPageConfig]);

  return (
    <PageWrapper className="ayo-home-page" customFooter={<BottomBar noNav />} noNav>
      {homePageComponents &&
        homePageComponents
          .filter((c) => !!c.config)
          .map((component) => {
            switch (component.name) {
              case homePageLandingBlock:
                return (
                  <LandingBlock
                    className="ayo-home-page__main-block"
                    config={{
                      ...component.config,
                      loginOptions: component.config.loginOptions.map((o) => ({
                        ...o,
                        disabled: checkEnvironmentVariable(o.disabledFlag, 'true'),
                      })),
                    }}
                  />
                );
              case homePageVisionBlock:
                return (
                  <Box className="ayo-home-page__vision-block">
                    <Container>
                      <Grid container>
                        <ArticleBlock config={component.config.visionArticle} />
                        <Grid item md={6} xs={12}>
                          <Box pb={isWidthUpMd ? 16 : 5} pt={isWidthUpMd ? 16 : 2}>
                            <ImgWithCaption config={component.config.homeInspirationBookImg} />
                          </Box>
                        </Grid>
                      </Grid>
                    </Container>
                  </Box>
                );
              case homePagePersonalizationBlock:
                return (
                  <Box className="ayo-home-page__personalization-block" py={isWidthUpSm ? 14 : 5}>
                    <PersonalizationBlock config={component.config} />
                  </Box>
                );
              case homePagePersonasBlock:
                return (
                  <Box pb={isWidthUpSm ? 25 : 5} pt={isWidthUpSm ? 16 : 5}>
                    <PersonasBlock config={component.config} />
                  </Box>
                );
              case homePageRoadmapBlock:
                return (
                  <Box className="ayo-home-page__roadmap-block" py={isWidthUpSm ? 15 : 18}>
                    <RoadmapBlock config={component.config} />
                  </Box>
                );
              case homePageQuoteBlock:
                return (
                  <Box className="ayo-home-page__quote-block" py={isWidthUpSm ? 14 : 5}>
                    <QuoteBlock config={component.config} />
                  </Box>
                );
              case homePageInitiativesBlock:
                return (
                  <Box className="ayo-home-page__initiatives-block" pt={10}>
                    <InitiativesBlock config={component.config} />
                  </Box>
                );
              case homePageTryMobileAppBlock:
                return (
                  IS_MOBILE_APP_AVAILABLE && (
                    <Box className="ayo-home-page__try-mobile-app-block" pt={10}>
                      <TryMobileAppBlock config={component.config} />
                    </Box>
                  )
                );
              case homePageMediaBlock:
                return <MediaBlock config={component.config} />;
              default:
                return <div>Placeholder</div>;
            }
          })}
    </PageWrapper>
  );
};

export default HomePage;
