import { useEffect } from 'react';

import { collaborateFormRoute } from '../../../../constants/routes';

const CollaboratePage = () => {
  useEffect(() => {
    window.location.assign(collaborateFormRoute);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default CollaboratePage;
