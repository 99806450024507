import { AchievementsCategories } from '../../constants/enums';
import { ReactComponent as AcademicAchievements } from '../../resources/images/achievements/academic.svg';
import { ReactComponent as MusicAchievements } from '../../resources/images/achievements/music.svg';
import { ReactComponent as NationalSocietiesMembership } from '../../resources/images/achievements/national_societies_membership.svg';
import { ReactComponent as ParticipationInCompetitions } from '../../resources/images/achievements/participation_in_competitions.svg';
import { ReactComponent as SportsAchievements } from '../../resources/images/achievements/sport.svg';

const ACHIEVEMENTS_ILLUSTRATIONS = {
  [AchievementsCategories.ACADEMIC_ACHIEVEMENTS]: {
    image: AcademicAchievements,
    label: 'A sheet of paper with an A+ mark',
  },
  [AchievementsCategories.MUSIC_ACHIEVEMENTS]: {
    image: MusicAchievements,
    label: 'Two music notes',
  },
  [AchievementsCategories.NATIONAL_SOCIETIES_MEMBERSHIP]: {
    image: NationalSocietiesMembership,
    label: 'An Earth globe',
  },
  [AchievementsCategories.PARTICIPATION_IN_COMPETITIONS]: {
    image: ParticipationInCompetitions,
    label: 'A podium',
  },
  [AchievementsCategories.SPORT_ACHIEVEMENTS]: {
    image: SportsAchievements,
    label: 'A running shoe',
  },
};

export const convertDataToAchievement = (achievementData) => ({
  ...achievementData,
  ...ACHIEVEMENTS_ILLUSTRATIONS[achievementData.category],
});
