import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Link, Typography } from '../../atoms';
import { plugRoute } from '../../../constants/routes';

const SearchNoResults = ({ explanation, onResetSearch, resetLinkText, searchValue }) => {
  const { t } = useTranslation();

  return (
    <>
      <Typography mb={3} paragraph variant="body2">
        <Trans components={{ b: <strong /> }} i18nKey={explanation} values={{ searchValue }} />
      </Typography>
      <Link component="button" gaLabel="Reset the search" href={plugRoute} onClick={onResetSearch}>
        {t(resetLinkText)}
      </Link>
    </>
  );
};

SearchNoResults.propTypes = {
  explanation: PropTypes.string.isRequired,
  onResetSearch: PropTypes.func.isRequired,
  resetLinkText: PropTypes.string.isRequired,
  searchValue: PropTypes.string.isRequired,
};

export default SearchNoResults;
