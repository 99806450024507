import React, { useContext } from 'react';
import { Box } from '@mui/material';

import { ReactComponent as PointIcon } from '../../../resources/icons/point.svg';
import { Typography } from '../../atoms';
import { UserContext } from '../../../context';

const CampusLabel = () => {
  const { state: userState } = useContext(UserContext);

  return (
    <Box alignItems="center" display="flex" mb={2} mt={1}>
      <Box mr={1.5}>
        <PointIcon />
      </Box>
      <Typography component="p" isLabel variant="subtitle2">
        {userState?.profile?.currentCampus}
      </Typography>
    </Box>
  );
};

export default CampusLabel;
