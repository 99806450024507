import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';

import { PopupActionsButtons, STTTextField } from '../../../../../../../../../moleculas';
import {
  InputsValidationErrors,
  InputsValidationRules,
  RolesMap,
} from '../../../../../../../../../../constants/enums';
import { useSnackbar } from '../../../../../../../../../../hooks';
import { useClubHubService } from '../../../../../../../../../../services';
import { UserContext } from '../../../../../../../../../../context';

const RepliesForm = ({
  replyToEdit,
  hideFormHandler,
  onSave,
  postId,
  cancelEditMode,
  editCommentHandler,
  clubId,
}) => {
  const { t } = useTranslation();
  const [replyText, setReplyText] = useState('');
  const [replyErrorMessage, setReplyErrorMessage] = useState('');
  const { setSnackBarStatus } = useSnackbar();
  const { postCommentToPost, updatePostReply } = useClubHubService();
  const { state: userState } = useContext(UserContext);
  const isStudent = userState.profile.role === RolesMap.STUDENT;

  const isValid = useCallback(() => {
    let replyValidationError = '';

    if (replyText.length < InputsValidationRules.MIN_INPUT_LENGTH) {
      replyValidationError = InputsValidationErrors(
        t,
        InputsValidationRules.MIN_INPUT_LENGTH,
      ).MIN_ERROR_TEXT;
    } else if (replyText.length > InputsValidationRules.MAX_INPUT_LENGTH) {
      replyValidationError = InputsValidationErrors(
        t,
        InputsValidationRules.MAX_INPUT_LENGTH,
      ).MAX_ERROR_TEXT;
    }

    setReplyErrorMessage(replyValidationError);
    return !replyValidationError;
  }, [replyText.length, t]);

  const handleInputChange = useCallback(
    (e) => {
      setReplyText(e.target.value);
      if (replyErrorMessage.length) {
        setReplyErrorMessage('');
      }
    },
    [replyErrorMessage],
  );

  const resetForm = useCallback(() => {
    setReplyText('');
    setReplyErrorMessage('');
  }, []);

  const onCancelFormHandler = useCallback(() => {
    if (replyToEdit) {
      cancelEditMode();
    } else {
      hideFormHandler();
    }
    resetForm();
  }, [resetForm, replyToEdit, cancelEditMode, hideFormHandler]);

  useEffect(() => {
    resetForm();
    if (replyToEdit) {
      setReplyText(replyToEdit.comment);
    }
  }, [replyToEdit, resetForm]);

  const submitReply = useCallback(() => {
    const body = {
      comment: replyText,
    };
    postCommentToPost(body, clubId, postId)
      .then((response) => {
        onSave(postId, response);
        setSnackBarStatus({
          text: t(
            isStudent
              ? 'Your reply has been successfully added!(student)'
              : 'Your reply has been successfully added!',
          ),
          type: 'success',
        });
      })
      .catch(() => {
        setSnackBarStatus({
          text: t(
            isStudent
              ? 'Failed to add your reply. Please try once more(student)'
              : 'Failed to add your reply. Please try once more',
          ),
          type: 'error',
        });
      });
    onCancelFormHandler();
  }, [
    isStudent,
    onSave,
    postId,
    setSnackBarStatus,
    t,
    replyText,
    onCancelFormHandler,
    clubId,
    postCommentToPost,
  ]);

  const onSaveEditsHandler = useCallback(() => {
    const updatedReply = {
      comment: replyText,
    };
    updatePostReply(updatedReply, clubId, postId, replyToEdit.commentId)
      .then((response) => {
        editCommentHandler(postId, replyToEdit.commentId, response);
        setSnackBarStatus({
          text: t(
            isStudent
              ? 'Thanks for the changes! Your input has been saved(student)'
              : 'Thanks for the changes! Your input has been saved.',
          ),
          type: 'success',
        });
        onCancelFormHandler();
      })
      .catch(() => {
        setSnackBarStatus({
          text: t('AYO couldn’t save your edits Please try once more'),
          type: 'error',
        });
      });
    cancelEditMode();
  }, [
    isStudent,
    postId,
    cancelEditMode,
    replyToEdit,
    editCommentHandler,
    replyText,
    setSnackBarStatus,
    t,
    clubId,
    onCancelFormHandler,
    updatePostReply,
  ]);

  const onSubmitFormHandler = useCallback(() => {
    if (isValid()) {
      if (replyToEdit) {
        onSaveEditsHandler();
      } else {
        submitReply();
      }
    }
  }, [isValid, replyToEdit, onSaveEditsHandler, submitReply]);

  const submitButtonText = useMemo(() => (replyToEdit ? 'Save' : 'Send'), [replyToEdit]);

  return (
    <Box>
      <Box>
        <STTTextField
          autoFocus
          error={replyErrorMessage}
          fullWidth
          helperText={replyErrorMessage}
          label={t('Your reply')}
          maxLength={InputsValidationRules.MAX_TEXT_AREA_LENGTH}
          minRows={3}
          multiline
          name="post_reply"
          onChange={handleInputChange}
          outlined
          required
          value={replyText}
        />
        <Grid container>
          <Grid item md={replyToEdit ? 12 : 8} xs={12}>
            <PopupActionsButtons
              primaryButtonGaLabel={submitButtonText}
              primaryButtonHandler={onSubmitFormHandler}
              primaryButtonText={t(submitButtonText)}
              secondaryButtonGaLabel="Cancel"
              secondaryButtonHandler={onCancelFormHandler}
              secondaryButtonText={t('Cancel')}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

RepliesForm.propTypes = {
  replyToEdit: PropTypes.shape({
    commentId: PropTypes.number,
    author: {
      id: PropTypes.number,
      name: PropTypes.string,
      role: PropTypes.string,
    },
    createdDate: PropTypes.string,
    edited: PropTypes.bool,
    comment: PropTypes.string,
  }),
  hideFormHandler: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  postId: PropTypes.number.isRequired,
  clubId: PropTypes.number.isRequired,
  cancelEditMode: PropTypes.func,
  editCommentHandler: PropTypes.func,
};

RepliesForm.defaultProps = {
  replyToEdit: null,
  onSave: () => {},
  cancelEditMode: () => {},
  editCommentHandler: () => {},
};

export default RepliesForm;
