import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Grid, useTheme, useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';

import { Card, Tooltip, Typography } from '../../../atoms';
import { InitiativeEmptyStateBlock, MoodCheckinInfo, NewFeatureHotspot } from '../../../moleculas';
import { UserContext } from '../../../../context';
import { useStudentsService } from '../../../../services';
import { checkinFiltersValuesMap, Moods } from '../../../../constants/moods';
import { GA, getRelativeDateTime } from '../../../../utils';
import { GaActions, GaCategories, StudentsPage } from '../../../../constants/enums';
import { moodsDetailsRoute } from '../../../../constants/routes';
import { ReactComponent as EmptyMoodIllustration } from '../../../../resources/images/mood_empty_state.svg';
import { ReactComponent as MoodAlertIcon } from '../../../../resources/icons/alert_light.svg';
import { NewFeaturesIdsMap } from '../../../../tours/common/NewFeaturesItemsProvider';

const MoodCard = ({ mood, checkinTime, studentId, negativeTrend }) => {
  const { t, i18n } = useTranslation();
  const { state: userState } = useContext(UserContext);
  const educatorRole = userState.profile?.role;
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  const negativeTrendMessage = useMemo(
    () =>
      negativeTrend && (
        <Box alignItems="center" display="flex">
          <Box mr={1}>
            <MoodAlertIcon />
          </Box>
          <Trans
            components={{ strong: <strong /> }}
            i18nKey="Alerting trend! COUNT days of negative mood (button)"
            values={{ daysCount: negativeTrend.daysCount }}
          />
        </Box>
      ),
    [negativeTrend],
  );

  return (
    <Tooltip
      enterTouchDelay={0}
      leaveTouchDelay={5000}
      placement={isWidthUpSm ? 'right-start' : 'bottom-end'}
      title={negativeTrendMessage}
    >
      <Card
        className="ayo-clickable-card ayo-mood-card-teacher"
        component={RouterLink}
        mainContent={
          <MoodCheckinInfo
            checkinTime={getRelativeDateTime(checkinTime, i18n.language, t)}
            isNegativeTrend={!!negativeTrendMessage}
            mood={mood}
            timeLabel={`${t('Last mood check-in')}:`}
          />
        }
        onClick={() => {
          GA.logInteraction({
            category: GaCategories.BEHAVIOR,
            action: GaActions.CARD_CLICK,
            label: 'Last mood check-in',
          });
        }}
        to={`${StudentsPage[educatorRole]}/${studentId}${moodsDetailsRoute}`}
      />
    </Tooltip>
  );
};

MoodCard.propTypes = {
  mood: PropTypes.oneOf(Object.values(Moods)).isRequired,
  checkinTime: PropTypes.string.isRequired,
  studentId: PropTypes.number.isRequired,
  negativeTrend: PropTypes.instanceOf(Object),
};

MoodCard.defaultProps = {
  negativeTrend: null,
};

const MoodSectionTeacher = ({ studentId }) => {
  const { t } = useTranslation();
  const { getLatestMood, getStudentMoodDetails } = useStudentsService();
  const [latestMoodInfo, setLatestMoodInfo] = useState();
  const [negativeTrend, setNegativeTrend] = useState();

  useEffect(() => {
    if (studentId === undefined || studentId === null) return;
    getLatestMood(studentId).then((moodInfoData) => setLatestMoodInfo(moodInfoData));
    getStudentMoodDetails(studentId, checkinFiltersValuesMap.LAST_CHECK_IN).then((moodData) =>
      setNegativeTrend(moodData?.trend),
    );
  }, [studentId, getLatestMood, getStudentMoodDetails]);

  return (
    <Box className="ayo-mood-section-teacher">
      <Grid container>
        <Grid item xs={12}>
          <Box alignItems="center" display="flex" mb={3}>
            <Typography variant="subtitle1">{t('Mood check-in')}</Typography>
            <NewFeatureHotspot
              id={NewFeaturesIdsMap.MOOD_CHECK_INS}
              isClickable
              label={t('Mood check-ins')}
            />
          </Box>
        </Grid>
        {latestMoodInfo?.mood ? (
          <Grid item sm={4} xs={12}>
            <MoodCard
              checkinTime={latestMoodInfo.createdDate}
              mood={latestMoodInfo.mood}
              negativeTrend={negativeTrend}
              studentId={studentId}
            />
          </Grid>
        ) : (
          <InitiativeEmptyStateBlock
            illustration={<EmptyMoodIllustration />}
            title={t('No information about the mood so far')}
          />
        )}
      </Grid>
    </Box>
  );
};

MoodSectionTeacher.propTypes = {
  studentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

MoodSectionTeacher.defaultProps = {
  studentId: null,
};

export default MoodSectionTeacher;
