import React, { useMemo, useRef } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { ActionsMenu, Card, Typography } from '../../../../../atoms';
import { EditingButton } from '../../../../../moleculas';
import { getEssayTitle, updateEmbedsSrc } from '../../../../../../utils';
import { usePrint } from '../../../../../../hooks';
import PortfolioPrintComponent from '../../../components/portfolio-print-component/PortfoloiPrintComponent';
import { ReactComponent as EssayCardImage } from '../../../../../../resources/images/portfolio/essay_card_image.svg';
import { ReactComponent as ChevronRightIcon } from '../../../../../../resources/icons/chevron_right.svg';
import { ReactComponent as CreateIcon } from '../../../../../../resources/icons/create.svg';
import { ReactComponent as DownloadIcon } from '../../../../../../resources/icons/download.svg';

const EssayCard = ({ textJson, id, isEditMode, onEdit, onRead, token }) => {
  const { t } = useTranslation();
  const ref = useRef();

  const { printHandler } = usePrint(ref);

  const title = useMemo(() => getEssayTitle(textJson), [textJson]);
  const textEditorData = useMemo(
    () => (token ? updateEmbedsSrc(textJson, 'image', token) : textJson),
    [token, textJson],
  );

  return (
    <Card
      className="ayo-portfolio__essays-section__card"
      elevation={1}
      mainContent={
        <Box display="flex">
          <Box height="100%">
            <EssayCardImage />
          </Box>
          <Box flex={1} ml={2}>
            <Typography
              className="ayo-portfolio__essays-section__card__title"
              component="h3"
              variant="subtitle2"
            >
              {title}
            </Typography>
            <EditingButton
              className="ayo-portfolio__essays-section__card__read-button"
              gaLabel="Read essay"
              icon={<ChevronRightIcon />}
              iconPosition="end"
              onClick={onRead}
              text={t('Read')}
            />
          </Box>
          {isEditMode && (
            <>
              <Box className="actions-container" ml={1}>
                <ActionsMenu
                  gaLabel="Action menu"
                  id="ayo-portfolio-essay-card-actions"
                  menuItems={[
                    {
                      text: t('Save as PDF'),
                      icon: DownloadIcon,
                      handler: printHandler,
                      id: `save as pdf ${id}`,
                      gaLabel: 'Save as PDF',
                    },
                    {
                      text: t('Edit'),
                      icon: CreateIcon,
                      handler: onEdit,
                      id: 'edit',
                      gaLabel: 'Edit',
                    },
                  ]}
                  paperClassName="ayo-portfolio__essay-section__card__action-menu"
                />
              </Box>
              <PortfolioPrintComponent ref={ref} value={textEditorData} />
            </>
          )}
        </Box>
      }
    />
  );
};

EssayCard.propTypes = {
  textJson: PropTypes.string,
  token: PropTypes.string,
  id: PropTypes.number.isRequired,
  isEditMode: PropTypes.bool,
  onEdit: PropTypes.func,
  onRead: PropTypes.func,
};

EssayCard.defaultProps = {
  textJson: '',
  isEditMode: false,
  onEdit: () => {},
  onRead: () => {},
  token: null,
};

export default EssayCard;
