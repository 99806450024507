import React from 'react';
import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { ActionsMenu, Card, Link, Typography } from '../../../../../atoms';
import { MlChip, TextWithTooltip } from '../../../../../moleculas';
import { getFormattedDate } from '../../../../../../utils';
import { CertificateKeys } from '../../../../../../constants/enums';
import { ReactComponent as CertificatesCardIllustration } from '../../../../../../resources/images/portfolio/certificates_card_image.svg';
import { ReactComponent as AcademicCapIcon } from '../../../../../../resources/icons/academic_cap.svg';
import { ReactComponent as DateIcon } from '../../../../../../resources/icons/date_picker_dark.svg';
import { ReactComponent as DescriptionIcon } from '../../../../../../resources/icons/reflection.svg';
import { ReactComponent as EditIcon } from '../../../../../../resources/icons/create.svg';
import { ReactComponent as LinkIcon } from '../../../../../../resources/icons/link_dark.svg';
import PortfolioAttachments from '../../../components/portfolio-attachments/PortfolioAttachments';

const CertificatesLabelsConfig = {
  [CertificateKeys.ORGANIZATION]: {
    icon: AcademicCapIcon,
  },
  [CertificateKeys.OBTAINING_DATE]: {
    dateFormat: (date, lang) => getFormattedDate(date, lang),
    icon: DateIcon,
    label: 'Date of obtaining',
  },
  [CertificateKeys.EXPIRATION_DATE]: {
    dateFormat: (date, lang) => getFormattedDate(date, lang),
    icon: DateIcon,
    label: 'Expiration date',
  },
  [CertificateKeys.LINK]: {
    icon: LinkIcon,
    isLink: true,
  },
  [CertificateKeys.DESCRIPTION]: {
    icon: DescriptionIcon,
  },
};

const CertificateCard = ({ certificate, editHandler, isEditMode, isSuggested, token }) => {
  const { t, i18n } = useTranslation();
  return (
    <Card
      className="ayo-portfolio-certificates-section__card"
      elevation={1}
      mainContent={
        <Box>
          <Box
            className="ayo-portfolio-certificates-section__card__header"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            p={2}
          >
            <Box>
              <CertificatesCardIllustration />
              <Typography component="p" isLightText variant="subtitle2">
                {t('Certificates')}
              </Typography>
            </Box>
            <Box>
              {isSuggested ? (
                <Box className="ayo-portfolio-certificates-section__card__header__chip">
                  <MlChip />
                </Box>
              ) : isEditMode ? (
                <ActionsMenu
                  gaLabel="Certificate actions"
                  id={`certificate-actions-${certificate.id}`}
                  menuItems={[
                    {
                      text: t('Edit'),
                      icon: EditIcon,
                      handler: editHandler,
                      id: `edit-${certificate.id}`,
                      gaLabel: 'Edit certificate',
                    },
                  ]}
                />
              ) : null}
            </Box>
          </Box>
          <Box className="ayo-portfolio-certificates-section__card__content" px={2} py={3}>
            <Typography component="h3" variant="subtitle2">
              {certificate[CertificateKeys.TITLE]}
            </Typography>
            <Box mt={2}>
              <Grid container spacing={2}>
                {Object.entries(CertificatesLabelsConfig).map(
                  ([key, value]) =>
                    certificate[key] && (
                      <Grid
                        key={key}
                        className="ayo-portfolio-certificates-section__card__content__detail"
                        container
                        direction="column"
                        item
                      >
                        {value.label && (
                          <Box mb={0.5}>
                            <Typography component={isEditMode ? 'p' : 'h4'} variant="label">
                              {t(value.label).toUpperCase()}
                            </Typography>
                          </Box>
                        )}
                        <Box display="flex" flexDirection="row">
                          <Box mr={1}>
                            <value.icon />
                          </Box>
                          {value.isLink ? (
                            <Link
                              gaLabel="Portfolio certificates link"
                              href={certificate[key]}
                              target="_blank"
                            >
                              <TextWithTooltip className="ayo-link" title={certificate[key]} />
                            </Link>
                          ) : (
                            <Typography variant="body2">
                              {value.dateFormat
                                ? value.dateFormat(certificate[key], i18n.language)
                                : certificate[key]}
                            </Typography>
                          )}
                        </Box>
                      </Grid>
                    ),
                )}
              </Grid>
              {!!certificate[CertificateKeys.ATTACHMENTS]?.length && (
                <Box mt={3}>
                  <PortfolioAttachments
                    attachments={certificate[CertificateKeys.ATTACHMENTS]}
                    token={token}
                  />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      }
    />
  );
};

CertificateCard.propTypes = {
  certificate: PropTypes.instanceOf(Object).isRequired,
  editHandler: PropTypes.func,
  isEditMode: PropTypes.bool,
  isSuggested: PropTypes.bool,
  token: PropTypes.string.isRequired,
};

CertificateCard.defaultProps = {
  editHandler: null,
  isEditMode: false,
  isSuggested: false,
};

export default CertificateCard;
