import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { RolesMap } from '../../constants/enums';
import { UserContext } from '../../context';
import { useAdministratorsService, useTeachersService } from '../../services';
import { getCourseAggregationKey, getNormalizedLanguageCode } from '../../utils';

const SEARCH_CRITERIA_ARR = ['name', 'email'];

const defaultStudentsComparator = (a, b) => +b.active - +a.active || a.name.localeCompare(b.name);

const studentsPeriodAggregation = (teacherDependentProfiles) => {
  const groupedStudents = teacherDependentProfiles.reduce((acc, student) => {
    student.courses.forEach((course) => {
      acc[course.period] = {
        ...(acc[course.period] || {}),
        [course.className]: [...(acc[course.period]?.[course.className] || []), student],
      };
    });
    return acc;
  }, {});
  return groupedStudents;
};

const studentsClassAggregation = (teacherDependentProfiles) => {
  const groupedStudents = teacherDependentProfiles.reduce((acc, current) => {
    current.courses.forEach((course) => {
      const courseAggregationKey = getCourseAggregationKey(course);
      acc[courseAggregationKey] = [...(acc[courseAggregationKey] || []), current];
    });
    const sortedByCourse = Object.fromEntries(
      Object.entries(acc).sort((a, b) => a[0].localeCompare(b[0])),
    );
    return sortedByCourse;
  }, {});
  return groupedStudents;
};

const studentsGradeAggregation = (plainStudentList) => {
  const groupedStudents = plainStudentList.reduce(
    (acc, current) => ({
      ...acc,
      [current.grade]: [...(acc[current.grade] || []), current],
    }),
    {},
  );
  return groupedStudents;
};

const studentsInterventionRequireFiltering = (students) =>
  students?.filter(
    (student) => student.isInterventionRequired || student.isInterventionAcknowledged,
  );

export const shouldRenderStudentsList = (studentsList) =>
  studentsList === undefined || studentsList?.length;

const useStudentsData = ({
  searchValue,
  withGroups,
  activityCriterion,
  filterString,
  withMoodsTrend,
}) => {
  const { getTeacherStudents, getTeacherGroups, getNegativeMoodTrends } = useTeachersService();
  const { getStudents: getAdministratorStudents } = useAdministratorsService();
  const [suggestions, setSuggestions] = useState();
  const [students, setStudents] = useState([]);
  const [teacherGroups, setTeacherGroups] = useState(null);
  const [studentsInterventionRequire, setStudentsInterventionRequire] = useState();

  const { state: userState } = useContext(UserContext);

  const handleGroupSetChange = (group, course) => {
    setTeacherGroups((currentGroups) => {
      if (!group) {
        const newGroups = currentGroups;
        newGroups[course] = null;
        delete newGroups[course];
        return {
          ...newGroups,
        };
      }
      return {
        ...currentGroups,
        [course]: group,
      };
    });
  };

  const [schoolName, setSchoolName] = useState(null);

  const [totalStudentsCount, setTotalStudentsCount] = useState(0);
  const [filteredStudentsCount, setFilteredStudentsCount] = useState(0);

  const studentsLoader =
    userState.profile.role === RolesMap.TEACHER ? getTeacherStudents : getAdministratorStudents;
  const studentsAggregator =
    userState.profile.role === RolesMap.TEACHER
      ? studentsPeriodAggregation
      : studentsGradeAggregation;

  const { currentCampus } = userState.profile;

  const [studentsByClasses, setStudentsByClasses] = useState([]);

  const { i18n } = useTranslation();
  useEffect(() => {
    if (!suggestions) {
      // Load students list, sort and group them
      studentsLoader(activityCriterion, null, currentCampus).then((studentsData) => {
        const studentsList = studentsData.students;
        if (!studentsList) {
          setSuggestions(null);
          return;
        }
        setTotalStudentsCount(studentsData.students.length);
        setStudentsInterventionRequire(studentsInterventionRequireFiltering(studentsList));
        studentsList.sort(defaultStudentsComparator);
        setSuggestions(studentsList);
        const groupedStudents = studentsAggregator(studentsList);
        setStudentsByClasses(studentsClassAggregation(studentsList));
        setStudents(groupedStudents);
        setSchoolName(studentsData.schoolName);
      });
    } else if (searchValue) {
      // Perform search if value is entered
      const filtered = [];
      SEARCH_CRITERIA_ARR.forEach((criteria) => {
        suggestions.forEach((item) => {
          if (
            item[criteria].toString().toLowerCase().includes(searchValue.toLowerCase()) &&
            !filtered.find((x) => x.id === item.id)
          ) {
            filtered.push(item);
          }
        });
      });
      setStudents(filtered);
    } else {
      // Reset to original state when search is cleared
      const groupedStudents = studentsAggregator(suggestions);
      setStudents(groupedStudents);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, suggestions]);

  useEffect(() => {
    if (withGroups) {
      getTeacherGroups(getNormalizedLanguageCode(i18n.language), currentCampus).then(
        (teacherCoursesGroups) => {
          setTeacherGroups(teacherCoursesGroups);
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const [studentsMoodsTrends, setStudentsMoodsTrends] = useState();
  useEffect(() => {
    if (withMoodsTrend) {
      getNegativeMoodTrends().then((studentsMoodsTrendsData) =>
        setStudentsMoodsTrends(studentsMoodsTrendsData),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [filteredStudents, setFilteredStudents] = useState(undefined);

  useEffect(() => {
    if (!filterString) {
      setStudentsInterventionRequire(studentsInterventionRequireFiltering(suggestions));
      setFilteredStudents((currentFilteredStudents) =>
        currentFilteredStudents === undefined ? undefined : null,
      );
      return;
    }
    studentsLoader(activityCriterion, filterString, currentCampus).then((studentsData) => {
      const studentsList = studentsData.students;
      studentsList.sort(defaultStudentsComparator);
      setFilteredStudentsCount(studentsList.length);
      const groupedStudents = studentsAggregator(studentsList);
      setFilteredStudents(groupedStudents);
      setStudentsInterventionRequire(studentsInterventionRequireFiltering(studentsList));
    });
  }, [
    activityCriterion,
    currentCampus,
    filterString,
    studentsAggregator,
    studentsLoader,
    suggestions,
    userState.profile.role,
  ]);

  return {
    filteredStudents,
    filteredStudentsCount,
    handleGroupSetChange,
    schoolName,
    shouldRenderStudentsList,
    students,
    studentsInterventionRequire,
    studentsMoodsTrends,
    suggestions,
    teacherGroups,
    totalStudentsCount,
    studentsByClasses,
  };
};

export default useStudentsData;
