/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Avatar, Typography } from '../../atoms';

const PersonaDescription = ({ img, avatarTxt, title, text }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <Grid container item>
      <Grid alignItems="center" container item justifyContent="space-between" sm={9} xs={12}>
        <Grid item xs={3}>
          <Box pb={3}>
            <Avatar alt={t(avatarTxt)} size={isWidthUpSm ? 'full' : '4x'} src={img} />
          </Box>
        </Grid>
        <Grid item sm={8} xs={12}>
          <Typography paragraph variant="h3">
            {t(title)}
          </Typography>
          <Typography paragraph>{t(text)}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

PersonaDescription.propTypes = {
  img: PropTypes.node.isRequired,
  avatarTxt: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
};

PersonaDescription.defaultProps = {
  avatarTxt: '',
  title: '',
  text: '',
};

export default PersonaDescription;
