/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useMemo, useRef, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Grid, useMediaQuery, useTheme } from '@mui/material';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import classnames from 'classnames';

import {
  Button,
  Card,
  NumericField,
  RadioGroup,
  Snackbar,
  Typography,
  ActionsMenu,
  PeriodDisplayName,
} from '../../../../../atoms';
import {
  AccordionClassGroupHeader,
  InformationalCaption,
  InformationalMessage,
  NewFeatureHotspot,
  PopupActionsButtons,
  StudentInterestItem,
} from '../../../../../moleculas';
import { LeaveDialog } from '../../../../leave-dialog/LeaveDialog';
import CourseHighlight from '../course-highlight/CourseHighlight';
import { useTeachersService, useOneTimeActionService } from '../../../../../../services';
import { GaActions, GaCategories, OneTimeActionsMap } from '../../../../../../constants/enums';
import {
  GA,
  shuffle,
  compareItems,
  useProfile,
  getNormalizedLanguageCode,
  getFullName,
} from '../../../../../../utils';
import { UserActions, UserContext, AppActions, AppContext } from '../../../../../../context';
import { periodsGroupsClassname } from '../../../../../../constants/values';
import { ReactComponent as DeleteIcon } from '../../../../../../resources/icons/delete_primary.svg';
import { ReactComponent as InfoIcon } from '../../../../../../resources/icons/info.svg';
import { ReactComponent as PlusIcon } from '../../../../../../resources/icons/plus.svg';
import { ReactComponent as CreateIcon } from '../../../../../../resources/icons/create.svg';
import { ReactComponent as DraggableIcon } from '../../../../../../resources/icons/draggable.svg';
import { ReactComponent as DragDropIcon } from '../../../../../../resources/icons/drag_and_drop.svg';
import { NewFeaturesIdsMap } from '../../../../../../tours/common/NewFeaturesItemsProvider';
import { StatusCodeMap } from '../../../../../../services/HttpClient';
import StudentsGridItem from '../../../components/students-grid-item/StudentsGridItem';

const GroupingTypesMap = {
  BY_INTERESTS: 'interest',
  RANDOM: 'random',
};

const DeleteGroupsActionsMap = {
  ACTIVATE: 'activate',
  DEACTIVATE: 'deactivate',
  EDIT: 'edit',
};

const GROUPING_MIN_VALUE = 2;
const GROUPING_MAX_LENGTH = 2;

const DraggableClickableCard = ({ userName, isPlaceholder, ...props }) => (
  <Card
    mainContent={
      <Box alignItems="center" display="flex" justifyContent="space-between" width="100%">
        {!isPlaceholder && (
          <>
            <Typography component="p" noWrap variant="body2">
              {userName}
            </Typography>
            <DraggableIcon />
          </>
        )}
      </Box>
    }
    {...props}
  />
);

DraggableClickableCard.propTypes = {
  userName: PropTypes.string,
  isPlaceholder: PropTypes.bool,
};

DraggableClickableCard.defaultProps = {
  userName: null,
  isPlaceholder: false,
};

const DraggableGridItem = React.forwardRef(
  ({ item, isDragging, isDraggingOverOther, isPlaceholder, ...props }, ref) => {
    const theme = useTheme();
    const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });

    return (
      <Grid
        ref={ref}
        className={classnames('ayo-draggable-item', {
          'is-dragging': isDragging,
          'is-dragging-over-other': isDraggingOverOther,
        })}
        item
        lg={4}
        md={4}
        sm={6}
        xs={12}
        {...props}
      >
        <Box px={1.5} py={isWidthUpSm ? 1 : 0.5}>
          <DraggableClickableCard
            isPlaceholder={isPlaceholder}
            userName={!isPlaceholder && getFullName(item)}
          />
        </Box>
      </Grid>
    );
  },
);

DraggableGridItem.propTypes = {
  item: PropTypes.shape({ firstName: PropTypes.string, lastName: PropTypes.string }),
  isPlaceholder: PropTypes.bool,
  isDragging: PropTypes.bool,
  isDraggingOverOther: PropTypes.bool,
};

DraggableGridItem.defaultProps = {
  item: null,
  isPlaceholder: false,
  isDragging: false,
  isDraggingOverOther: false,
};

const DraggablePlaceHolder = () => (
  <DraggableGridItem className="ayo-drag-placeholder" isPlaceholder />
);

const StudentsGrid = ({
  period,
  groupSet,
  onGroupSetChange,
  currentDragItemDroppableId,
  students,
  studentCardRedirectLink,
}) => {
  const { t, i18n } = useTranslation();

  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
  const isWidthUpMd = useMediaQuery(theme.breakpoints.up('md'), { noSsr: true });

  const groupingOptions = useMemo(
    () => [
      { text: t('By similar interests'), value: GroupingTypesMap.BY_INTERESTS },
      { text: t('Randomly'), value: GroupingTypesMap.RANDOM },
    ],
    [t],
  );

  const headerContainerRef = useRef();

  const [isGroupsSettingsOpen, setIsGroupsSettingsOpen] = useState(false);

  const [currentGroupingType, setCurrentGroupingType] = useState(GroupingTypesMap.BY_INTERESTS);

  const [currentGroupingCriteria, setCurrentGroupingCriteria] = useState();

  const [groups, setGroups] = useState();

  const [groupSetId, setGroupSetId] = useState();

  const [deactivatedGroupSet, setDeactivatedGroupSet] = useState();

  const [isSubmitError, setIsSubmitError] = useState(false);

  const { postTeacherGroups, putTeacherGroups } = useTeachersService();

  const { state: userState, dispatch: dispatchUserState } = useContext(UserContext);
  const { currentCampus } = userState.profile;

  const transformGroupSet = useCallback(
    (currentGroupSet) => {
      let modifiedGroups = [];
      currentGroupSet.forEach((group) => {
        let studentsArr = [];
        group.studentIds.forEach((id) => {
          const foundStudent = students.find((student) => id === student.id);
          if (foundStudent) {
            studentsArr = [...studentsArr, foundStudent];
          }
        });
        modifiedGroups = [
          ...modifiedGroups,
          {
            name: group.name,
            studentIds: studentsArr,
            interests: group.interests,
          },
        ];
      });
      return modifiedGroups;
    },
    [students],
  );

  useEffect(() => {
    setGroupSetId(groupSet?.groupId);
    setGroups(groupSet ? transformGroupSet(groupSet.groups) : null);
  }, [groupSet, students, transformGroupSet]);

  const toggleGroupSettings = () => {
    setIsGroupsSettingsOpen((value) => !value);
  };

  const handleGroupingTypeChange = useCallback(
    (e) => {
      if (e.target.value !== currentGroupingType) {
        setCurrentGroupingType(e.target.value);
        GA.logInteraction({
          category: GaCategories.BEHAVIOR,
          action: GaActions.RADIOBUTTON_CLICK,
          label: `Grouping type: ${e.target.value}`,
        });
      }
    },
    [currentGroupingType],
  );

  const handleGroupingCriteriaChange = useCallback(
    (value) => {
      if (value !== currentGroupingCriteria) {
        setCurrentGroupingCriteria(value);
      }
    },
    [currentGroupingCriteria],
  );

  const handleGroupClick = useCallback(() => {
    const groupConfig = {
      className: periodsGroupsClassname,
      period,
    };

    if (currentGroupingType === GroupingTypesMap.RANDOM) {
      const allStudents = shuffle(students);
      const groupsArr = [];
      for (let i = 0, count = 0; i < allStudents.length; i += currentGroupingCriteria, count += 1) {
        groupsArr[count] = {
          studentIds: allStudents
            .slice(i, i + currentGroupingCriteria)
            .map((student) => student.id),
          name: `${t('Group')} ${count + 1}`,
          orderNumber: count + 1,
        };
      }
      groupConfig.groups = groupsArr;
    } else {
      groupConfig.groupSize = currentGroupingCriteria;
    }

    postTeacherGroups(
      groupConfig,
      currentGroupingType,
      getNormalizedLanguageCode(i18n.language),
      currentCampus,
    )
      .then((newGroup) => {
        onGroupSetChange(newGroup, period);
        setTimeout(() => {
          const button = headerContainerRef.current.querySelector('button');
          if (button) button.focus();
        }, 0);
      })
      .catch(() => {
        setIsSubmitError(true);
      });
  }, [
    period,
    currentGroupingCriteria,
    currentGroupingType,
    currentCampus,
    i18n.language,
    onGroupSetChange,
    postTeacherGroups,
    students,
    t,
  ]);

  const deleteGroups = useCallback(() => {
    putTeacherGroups(groupSetId, DeleteGroupsActionsMap.DEACTIVATE).then(() => {
      setIsGroupsSettingsOpen(false);
      setCurrentGroupingCriteria(GROUPING_MIN_VALUE);
      setCurrentGroupingType(GroupingTypesMap.BY_INTERESTS);
      setDeactivatedGroupSet({ ...groupSet });
      onGroupSetChange(null, period);
    });
  }, [period, groupSet, groupSetId, onGroupSetChange, putTeacherGroups]);

  const handleUnGroupClick = useCallback(() => {
    deleteGroups();
  }, [deleteGroups]);

  const [savedGroupsState, setSavedGroupsState] = useState(null);

  const handleUndoClick = useCallback(() => {
    const { groupId } = deactivatedGroupSet;
    setDeactivatedGroupSet(null);
    putTeacherGroups(groupId, DeleteGroupsActionsMap.ACTIVATE).then(() => {
      onGroupSetChange(savedGroupsState || deactivatedGroupSet, deactivatedGroupSet);
      setSavedGroupsState(null);
      setTimeout(() => {
        const button = headerContainerRef.current.querySelector('button');
        if (button) button.focus();
      }, 0);
    });
  }, [deactivatedGroupSet, onGroupSetChange, putTeacherGroups, savedGroupsState]);

  const handleRetryClick = useCallback(() => {
    setIsSubmitError(false);
    handleGroupClick();
  }, [handleGroupClick]);

  const mainContainerRef = useRef();

  const [isEditing, setIsEditing] = useState(false);

  const { dispatch: dispatchAppState } = useContext(AppContext);

  const [startTime, setStartTime] = useState(null);

  const handleEditStart = useCallback(() => {
    setIsEditing(true);
    setSavedGroupsState({
      ...groupSet,
      groups: groupSet.groups.map((group) => ({ ...group, studentIds: [...group.studentIds] })),
    });
    dispatchAppState({ type: AppActions.SET_IS_DIRTY, data: true });
    setStartTime(Date.now());
    setTimeout(() => {
      const button = mainContainerRef.current.querySelector('.ayo-draggable-item');
      if (button) button.focus();
    }, 0);
  }, [dispatchAppState, groupSet]);

  const [isEditSuccess, setIsEditSuccess] = useState(null);

  const handleEditEnd = useCallback(
    (showUI) => {
      setIsEditing(false);
      dispatchAppState({ type: AppActions.SET_IS_DIRTY, data: false });
      const filteredGroups = groupSet.groups.filter((group) => group.studentIds.length);
      if (filteredGroups.length === 1) {
        deleteGroups();
        return;
      }
      let haveChanged = false;
      const editedGroups = filteredGroups.map((group) => {
        const studentsCompare = compareItems(
          group.studentIds,
          savedGroupsState.groups.find((savedGroup) => savedGroup.name === group.name).studentIds,
        );
        if (studentsCompare !== 1) {
          haveChanged = true;
        }
        return {
          ...group,
          interests: studentsCompare !== 0 ? group.interests : null,
        };
      });
      GA.logTimeElapsed(startTime, {
        category: GaCategories.BEHAVIOR,
        variable: 'Time Spent',
        label: `Student groups manual edit`,
      });
      if (!haveChanged) {
        if (showUI) {
          setIsEditSuccess(true);
        }
        return;
      }
      onGroupSetChange({ ...groupSet, groups: editedGroups }, period);
      putTeacherGroups(
        groupSetId,
        DeleteGroupsActionsMap.EDIT,
        { groups: editedGroups },
        { skipDefaultErrorHandlers: [StatusCodeMap.SERVER_ERROR] },
      )
        .then(() => {
          if (showUI) {
            setIsEditSuccess(true);
          }
        })
        .catch(() => {
          if (showUI) {
            onGroupSetChange(savedGroupsState, period);
            setSavedGroupsState(null);
            setIsEditSuccess(false);
          }
        });
    },
    [
      period,
      deleteGroups,
      dispatchAppState,
      groupSet,
      groupSetId,
      onGroupSetChange,
      putTeacherGroups,
      savedGroupsState,
      startTime,
    ],
  );

  const handleSaveGroupChangesClick = useCallback(() => {
    handleEditEnd(true);
  }, [handleEditEnd]);

  const handleCancelClick = useCallback(() => {
    setIsEditing(false);
    onGroupSetChange(savedGroupsState, period);
    setSavedGroupsState(null);
    dispatchAppState({ type: AppActions.SET_IS_DIRTY, data: false });
    GA.logTimeElapsed(startTime, {
      category: GaCategories.BEHAVIOR,
      variable: 'Time Spent',
      label: `Student groups manual edit`,
    });
  }, [period, dispatchAppState, onGroupSetChange, savedGroupsState, startTime]);

  const [hasDragDropNotification, setHasDragDropNotification] = useState(false);
  const [hasGroupInterestChangeNotification, setHasGroupInterestChangeNotification] =
    useState(false);

  const { getOneTimeActionStatus } = useProfile();
  const { postOneTimeAction } = useOneTimeActionService();

  const handleDragDropNotification = useCallback(() => {
    postOneTimeAction(OneTimeActionsMap.MY_STUDENTS_DRAG_DROP);
    dispatchUserState({
      type: UserActions.SET_ONE_TIME_ACTION,
      data: OneTimeActionsMap.MY_STUDENTS_DRAG_DROP,
    });
  }, [dispatchUserState, postOneTimeAction]);

  const handleGroupInterestChangeNotification = useCallback(() => {
    postOneTimeAction(OneTimeActionsMap.MY_STUDENTS_GROUP_INTEREST_CHANGE);
    dispatchUserState({
      type: UserActions.SET_ONE_TIME_ACTION,
      data: OneTimeActionsMap.MY_STUDENTS_GROUP_INTEREST_CHANGE,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setHasDragDropNotification(!getOneTimeActionStatus(OneTimeActionsMap.MY_STUDENTS_DRAG_DROP));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState.oneTimeActions]);

  useEffect(() => {
    setHasGroupInterestChangeNotification(
      !getOneTimeActionStatus(OneTimeActionsMap.MY_STUDENTS_GROUP_INTEREST_CHANGE),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState.oneTimeActions]);

  const getDraggableStyle = useCallback(
    (style, snapshot) => {
      if (!snapshot.isDropAnimating) {
        return style;
      }
      const targetId = snapshot.draggingOver;
      let translate;
      if (targetId) {
        const targetPlaceHolder = document
          .querySelector(`[data-droppable-id="${targetId} - ${period}"]`)
          .querySelector('.ayo-drag-placeholder');
        const { x, y } = targetPlaceHolder.getBoundingClientRect();
        translate = `translate(${x - style.left}px, ${y - style.top}px)`;
      } else {
        translate = 'translate(0px, 0px)';
      }
      return {
        ...style,
        transform: translate,
      };
    },
    [period],
  );

  return (
    <Box ref={mainContainerRef} className="my-students-grid" mx={-1.5}>
      <Box
        ref={headerContainerRef}
        alignItems="center"
        display="flex"
        flex={1}
        justifyContent="space-between"
        pb={isWidthUpSm ? 2 : 1}
        pt={isWidthUpSm ? 6 : 4}
        px={1.5}
      >
        <AccordionClassGroupHeader count={students.length}>
          <PeriodDisplayName period={period} />
        </AccordionClassGroupHeader>
        {groups && !isEditing && (
          <Box className="ayo-action-menu-container">
            <ActionsMenu
              id="Actions"
              label={isWidthUpSm ? t('Actions') : null}
              menuItems={[
                {
                  text: t('Edit groups'),
                  icon: CreateIcon,
                  handler: handleEditStart,
                  id: 'edit',
                  gaLabel: 'Edit student groups',
                },
                {
                  text: t('Delete groups'),
                  icon: DeleteIcon,
                  handler: handleUnGroupClick,
                  id: 'delete',
                  gaLabel: 'Delete student groups',
                },
              ]}
            />
          </Box>
        )}
      </Box>
      {isEditing && hasDragDropNotification && (
        <Box mb={1} px={1.5}>
          <InformationalMessage
            icon={<DragDropIcon />}
            onClick={handleDragDropNotification}
            text={`${t('Select student card and drag and drop it to another group')}.`}
          />
        </Box>
      )}
      {isEditing && hasGroupInterestChangeNotification && (
        <Box px={1.5}>
          <InformationalMessage
            icon={<InfoIcon />}
            onClick={handleGroupInterestChangeNotification}
            text={`${t(
              'After manual editing, similar interests will disappear due to the grouping logic changes',
            )}.`}
          />
        </Box>
      )}
      {!groups &&
        (students.length >= 4 ? (
          <Box px={1.5} width={isWidthUpMd ? '50%' : 'auto'}>
            {!isGroupsSettingsOpen ? (
              <Button
                className="ayo-students-list-page__create-group"
                onClick={toggleGroupSettings}
              >
                {t('Create groups')}
                <Box display="flex" ml={1}>
                  <PlusIcon />
                </Box>
              </Button>
            ) : (
              <Card
                actions={
                  <PopupActionsButtons
                    primaryButtonComponent={
                      <Button
                        disabled={!currentGroupingCriteria}
                        fullWidth
                        gaLabel="Create group(s)"
                        onClick={handleGroupClick}
                        variant="primary"
                      >
                        {t('Apply grouping')}
                      </Button>
                    }
                    secondaryButtonGaLabel="Cancel create group(s)"
                    secondaryButtonHandler={toggleGroupSettings}
                    secondaryButtonText={t('Cancel')}
                  />
                }
                mainContent={
                  <Box display="flex" flexDirection="column">
                    <Typography paragraph variant="subtitle2">
                      {t('Create groups')}
                    </Typography>
                    <RadioGroup
                      ariaLabel={t('Create groups')}
                      defaultValue={GroupingTypesMap.BY_INTERESTS}
                      onChange={handleGroupingTypeChange}
                      options={groupingOptions}
                    />
                    <Box alignItems="center" display="flex" flexDirection="row" pt={4}>
                      <NumericField
                        defaultValue={GROUPING_MIN_VALUE}
                        maxLength={GROUPING_MAX_LENGTH}
                        maxValue={Math.floor(students.length / 2)}
                        minValue={GROUPING_MIN_VALUE}
                        onChange={handleGroupingCriteriaChange}
                        outlined
                      />
                      <Typography align="center" variant="body2">
                        {t('students per group')}
                      </Typography>
                    </Box>
                    {currentGroupingCriteria && students.length % currentGroupingCriteria !== 0 && (
                      <Box alignItems="center" display="flex" pt={2}>
                        <InformationalCaption title="The groups won't be equal due to the total number" />
                      </Box>
                    )}
                  </Box>
                }
              />
            )}
          </Box>
        ) : (
          <Box alignItems="center" display="flex" pb={1.5} px={1.5}>
            <InformationalCaption title="You need at least four students in AYO to create groups" />
          </Box>
        ))}
      <Grid container>
        {groups && (
          <>
            <div className="ayo-students-list-page__status" role="alert">
              {`${groups.length} ${t('Groups')}`}
            </div>
            {groups.map((group) =>
              isEditing ? (
                <Droppable key={group.name} direction="horizontal" droppableId={group.name}>
                  {(provided, snapshot) => (
                    <Box
                      className={classnames('ayo-students-list-page__group', {
                        'allowed-target':
                          currentDragItemDroppableId && currentDragItemDroppableId !== group.name,
                        'is-dragging-over': snapshot.isDraggingOver,
                      })}
                      mx={1.5}
                      my={1.5}
                      px={0.5}
                      py={2}
                      width="100%"
                    >
                      <Box
                        ref={provided.innerRef}
                        data-droppable-id={`${group.name} - ${period}`}
                        display="flex"
                        flexDirection="column"
                        width="100%"
                      >
                        <Box px={1.5}>
                          <Typography component="h3" variant="subtitle2">
                            {group.name}
                          </Typography>
                        </Box>
                        <Grid container item>
                          <>
                            {group.studentIds.length
                              ? group.studentIds.map((item, idx) => (
                                  <Draggable
                                    key={item.id}
                                    draggableId={String(item.id)}
                                    index={idx}
                                  >
                                    {(dragProvided, dragSnapshot) => (
                                      <>
                                        <DraggableGridItem
                                          ref={dragProvided.innerRef}
                                          item={item}
                                          {...dragProvided.draggableProps}
                                          {...dragProvided.dragHandleProps}
                                          isDragging={dragSnapshot.isDragging}
                                          isDraggingOverOther={
                                            currentDragItemDroppableId &&
                                            dragSnapshot.draggingOver &&
                                            dragSnapshot.draggingOver !== currentDragItemDroppableId
                                          }
                                          style={getDraggableStyle(
                                            dragProvided.draggableProps.style,
                                            dragSnapshot,
                                          )}
                                        />
                                        {dragSnapshot.isDragging && <DraggablePlaceHolder />}
                                      </>
                                    )}
                                  </Draggable>
                                ))
                              : !snapshot.isDraggingOver && (
                                  <Box
                                    alignItems="center"
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    minHeight="160px"
                                    padding="0 12px"
                                    width="100%"
                                  >
                                    <Typography variant="subtitle2">
                                      {t('No students here')}
                                    </Typography>
                                    <Typography align="center" variant="body2">
                                      {t('Drag and drop the student card from any group here')}
                                    </Typography>
                                  </Box>
                                )}
                            {snapshot.isDraggingOver &&
                              !group.studentIds.find(
                                (student) => student.id === +snapshot.draggingOverWith,
                              ) && <DraggablePlaceHolder />}
                          </>
                        </Grid>
                        {/* Disable react-beautiful-dnd warning */}
                        <div style={{ display: 'none' }}>{provided.placeholder}</div>
                      </Box>
                    </Box>
                  )}
                </Droppable>
              ) : (
                <Box
                  className={classnames('ayo-students-list-page__group')}
                  display="flex"
                  flexDirection="column"
                  my={1.5}
                  px={0.5}
                  py={2}
                  width="100%"
                >
                  <Box
                    alignItems="center"
                    className="ayo-students-list-page__group-header"
                    display="flex"
                    flexDirection="row"
                    flexWrap="wrap"
                    px={1.5}
                  >
                    <Typography component="h3" variant="subtitle2">
                      {group.name}
                    </Typography>
                    <Divider orientation="vertical" />
                    {group.interests?.length ? (
                      <NewFeatureHotspot id={NewFeaturesIdsMap.GROUPING_INTERESTS} isClickable />
                    ) : null}
                    <Typography
                      className="ayo-students-list-page__group-type-label"
                      variant="body2"
                    >
                      {group.interests?.length ? t('Interest') : t('Random')}
                    </Typography>
                    {group.interests?.length
                      ? group.interests.map((interest) => (
                          <StudentInterestItem
                            key={interest.nameKey}
                            interest={interest}
                            isWithEmoji
                          />
                        ))
                      : null}
                  </Box>
                  <Grid className="ayo-students-list-page__group-items" container item>
                    {group.studentIds.map((item) => (
                      <StudentsGridItem
                        key={item.id}
                        href={studentCardRedirectLink(item.id)}
                        item={item}
                      />
                    ))}
                  </Grid>
                </Box>
              ),
            )}
          </>
        )}
        {isEditing &&
          groups?.reduce((acc, group) => {
            if (group.studentIds.length) {
              return acc + 1;
            }
            return acc;
          }, 0) === 1 && (
            <Box alignItems="center" display="flex" pb={1.5} px={1.5}>
              <InformationalCaption title="The groups will disappear if you have all the students in one group" />
            </Box>
          )}
        {isEditing && groups && (
          <PopupActionsButtons
            className="ayo-editing-actions"
            primaryButtonGaLabel="Save student groups edits"
            primaryButtonHandler={handleSaveGroupChangesClick}
            primaryButtonText={t('Save changes')}
            secondaryButtonGaLabel="Cancel student groups edits"
            secondaryButtonHandler={handleCancelClick}
            secondaryButtonText={t('Cancel')}
          />
        )}
      </Grid>
      <Snackbar
        action={
          <Button autoFocus onClick={handleUndoClick}>
            {t('Undo')}
          </Button>
        }
        onClose={() => {
          setDeactivatedGroupSet(null);
          setSavedGroupsState(null);
          setTimeout(() => {
            const button =
              mainContainerRef.current.querySelector('.ayo-students-list-page__create-group') ||
              mainContainerRef.current.querySelector('.ayo-action-menu-container .ayo-icon-button');
            if (button) button.focus();
          }, 0);
        }}
        open={!!deactivatedGroupSet}
        text={
          <div role="alert">
            <span>{t('The groups for')} </span>
            <b>{period}</b>
            <span> {t('class have been deleted')}</span>
          </div>
        }
      />
      <Snackbar
        action={
          <Button autoFocus onClick={handleRetryClick}>
            {t('Retry')}
          </Button>
        }
        onClose={() => {
          setIsSubmitError(false);
          setTimeout(() => {
            const button = mainContainerRef.current.querySelector('.ayo-draggable-item');
            if (button) button.focus();
          }, 0);
        }}
        open={isSubmitError}
        text={<div role="alert">{t('Failed to create the groups, please try once more')}</div>}
      />
      <Snackbar
        action={
          <Button autoFocus onClick={handleEditStart}>
            {t('Retry')}
          </Button>
        }
        onClose={() => setIsEditSuccess(null)}
        open={isEditSuccess === false}
        text={<div role="alert">{t('Group changes were not saved, try editing again')}</div>}
        variant="error"
      />
      <Snackbar
        onClose={() => setIsEditSuccess(null)}
        open={isEditSuccess}
        text={<div role="alert">{t('Group changes saved')}</div>}
        variant="success"
      />
      {isEditing && <CourseHighlight el={mainContainerRef.current} />}
      {isEditing && <LeaveDialog onSave={handleEditEnd} />}
    </Box>
  );
};

StudentsGrid.propTypes = {
  period: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  groupSet: PropTypes.instanceOf(Object),
  isGroupView: PropTypes.bool,
  isSearchView: PropTypes.bool,
  onGroupSetChange: PropTypes.func,
  students: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  currentDragItemDroppableId: PropTypes.string,
  studentCardRedirectLink: PropTypes.func.isRequired,
};

StudentsGrid.defaultProps = {
  groupSet: null,
  isGroupView: false,
  isSearchView: false,
  onGroupSetChange: () => {},
  students: [],
  currentDragItemDroppableId: null,
};

export default StudentsGrid;
