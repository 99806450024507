import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import classNames from 'classnames';

import { Typography } from '../../atoms';

const TextBadge = ({ className, icon, text, textVariant }) => (
  <Box
    alignItems="center"
    className={classNames('ayo-text-badge', className)}
    display="flex"
    justifyContent="center"
  >
    {icon}
    <Typography variant={textVariant ?? 'body3'}>{text}</Typography>
  </Box>
);

TextBadge.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.node,
  text: PropTypes.string.isRequired,
  textVariant: PropTypes.oneOf([
    'display',
    'h1',
    'h2',
    'h3',
    'subtitle1',
    'subtitle2',
    'label-highlighted',
    'body1',
    'body2',
    'body3',
    'caption',
    'quote',
  ]),
};

TextBadge.defaultProps = {
  className: '',
  icon: null,
  textVariant: null,
};

export default TextBadge;
