import React, { useCallback, useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

import { Typography, Button, Tooltip } from '../../../../../atoms';
import { MlChip } from '../../../../../moleculas';
import {
  LeadershipAttributesActions,
  LeadershipAttributesContext,
  SharedDataContext,
  UserContext,
} from '../../../../../../context';
import { GA } from '../../../../../../utils';
import { GaActions, GaCategories, RolesMap } from '../../../../../../constants/enums';
import {
  createRoute,
  familyFeedRoute,
  goalsRoute,
  trainAyoRoute,
} from '../../../../../../constants/routes';
import { GoalsMilestonesStatuses } from '../../../../../../constants/goals';
import { ReactComponent as ArrowIcon } from '../../../../../../resources/icons/arrow_up.svg';
import { ReactComponent as Clipboard } from '../../../../../../resources/icons/clipboard.svg';
import { ReactComponent as GoalCompleted } from '../../../../../../resources/icons/check_circle.svg';
import { ReactComponent as LeadershipMaturityLikeFilled } from '../../../../../../resources/icons/leadership_attributes_thumbs_up_filled.svg';
import { ReactComponent as LeadershipMaturityLikeOutlined } from '../../../../../../resources/icons/leadership_attributes_thumbs_up_outlined.svg';
import { ReactComponent as PlusIcon } from '../../../../../../resources/icons/plus.svg';

const AYO = 'AYO';

const MaturityLevelRubricContent = ({
  groupKey,
  hasRubricGoal,
  isAyoIncluded,
  rubricGoalStatus,
  showGoalActionButton,
}) => {
  const { t } = useTranslation();
  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      width="100%"
    >
      <Box>
        <Typography className="ayo-maturity-level-rubric__level-text" variant="body2">
          {t(groupKey)}
        </Typography>
        {isAyoIncluded && <MlChip className="ayo-maturity-level-rubric__ml-input-label" />}
        {hasRubricGoal && (
          <Box className="ayo-maturity-level-rubric__goal-indicator">
            {rubricGoalStatus === GoalsMilestonesStatuses.COMPLETED ? (
              <GoalCompleted aria-label={t('A green tick sign')} role="img" />
            ) : (
              <Clipboard aria-label={t('A to do list')} role="img" />
            )}
          </Box>
        )}
      </Box>
      {showGoalActionButton && (
        <Button isIconButton tabindex="-1">
          {hasRubricGoal ? (
            <ArrowIcon className="ayo-maturity-level-rubric__arrow" />
          ) : (
            <PlusIcon />
          )}
        </Button>
      )}
    </Box>
  );
};

MaturityLevelRubricContent.propTypes = {
  groupKey: PropTypes.string.isRequired,
  hasRubricGoal: PropTypes.bool.isRequired,
  isAyoIncluded: PropTypes.bool.isRequired,
  rubricGoalStatus: PropTypes.string.isRequired,
  showGoalActionButton: PropTypes.bool.isRequired,
};

const MaturityLevelRubric = ({
  attributeChangeHandler,
  maturityLevel,
  goals,
  title,
  leadershipAttributeGroupKey,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { dispatch: dispatchLeadershipAttributes } = useContext(LeadershipAttributesContext);
  const { state: sharedState } = useContext(SharedDataContext);
  const { state: userState } = useContext(UserContext);
  const studentId = sharedState.activeStudent.id;
  const isGuardian = userState.profile.role === RolesMap.GUARDIAN;
  const isAyoIncluded = useMemo(
    () => maturityLevel.completedUserNames.includes(AYO),
    [maturityLevel.completedUserNames],
  );

  const rubricGoal = useMemo(
    () => goals.find((goal) => goal.leadershipAttributeGroupKey === maturityLevel.groupKey),
    [maturityLevel.groupKey, goals],
  );
  const goalTooltipTitle = rubricGoal ? 'View goal' : 'Create a goal';

  const goalsManageHandler = useCallback(() => {
    if (rubricGoal) {
      history.push(
        isGuardian
          ? `${familyFeedRoute}/${studentId}${goalsRoute}/${rubricGoal.id}`
          : `${trainAyoRoute}/${studentId}${goalsRoute}/${rubricGoal.id}`,
      );
    } else {
      dispatchLeadershipAttributes({
        type: LeadershipAttributesActions.SET_GOAL_RELATED_RUBRIC_DATA,
        data: {
          title: maturityLevel.groupKey,
          leadershipAttributeKey: leadershipAttributeGroupKey,
        },
      });
      history.push(`${trainAyoRoute}/${studentId}${goalsRoute}${createRoute}`);
    }
  }, [
    rubricGoal,
    history,
    isGuardian,
    studentId,
    dispatchLeadershipAttributes,
    maturityLevel.groupKey,
    leadershipAttributeGroupKey,
  ]);

  return (
    <Box className="ayo-maturity-level-rubric">
      <Button
        aria-label={`${t(maturityLevel.completed ? 'Remove like' : 'Add like')} ${t(
          `${maturityLevel.groupKey}`,
        )}`}
        gaLabel="Like"
        isIconButton
        onClick={() => attributeChangeHandler(maturityLevel, title)}
      >
        {maturityLevel.completed ? (
          <LeadershipMaturityLikeFilled />
        ) : (
          <LeadershipMaturityLikeOutlined />
        )}
      </Button>
      <Box className="ayo-maturity-level-rubric__users-count">
        {maturityLevel.completedUserNames.length > 0 && (
          <Tooltip
            aria-label={`${maturityLevel.completedUserNames.length} ${t('people liked')}: ${
              maturityLevel.completedUserNames
            }`}
            enterTouchDelay={0}
            leaveTouchDelay={5000}
            onOpen={(e) => {
              e.stopPropagation();
              GA.logInteraction({
                category: GaCategories.BEHAVIOR,
                action: GaActions.ICON_HOVER,
                label: 'List of likes',
              });
            }}
            title={
              <Box display="flex" flexDirection="column">
                {maturityLevel.completedUserNames.map((userName) => (
                  <Typography key={userName} isLightText variant="caption">
                    {userName}
                  </Typography>
                ))}
              </Box>
            }
          >
            <Box>
              <Typography aria-hidden isLabel variant="body2">
                {maturityLevel.completedUserNames.length}
              </Typography>
            </Box>
          </Tooltip>
        )}
      </Box>
      {isGuardian && !rubricGoal ? (
        <Box pl={1}>
          <MaturityLevelRubricContent
            groupKey={maturityLevel.groupKey}
            hasRubricGoal={rubricGoal}
            isAyoIncluded={isAyoIncluded}
            rubricGoalStatus={rubricGoal?.status}
            showGoalActionButton={(isGuardian && rubricGoal) || !isGuardian}
          />
        </Box>
      ) : (
        <Tooltip
          enterTouchDelay={0}
          leaveTouchDelay={5000}
          placement="bottom-end"
          title={t(goalTooltipTitle)}
        >
          <Button
            className="ayo-maturity-level-rubric__rubric-button"
            fullWidth
            onClick={goalsManageHandler}
            tabindex="0"
            variant="primary"
          >
            <MaturityLevelRubricContent
              groupKey={maturityLevel.groupKey}
              hasRubricGoal={rubricGoal}
              isAyoIncluded={isAyoIncluded}
              rubricGoalStatus={rubricGoal?.status}
              showGoalActionButton={(isGuardian && rubricGoal) || !isGuardian}
            />
          </Button>
        </Tooltip>
      )}
    </Box>
  );
};

MaturityLevelRubric.propTypes = {
  attributeChangeHandler: PropTypes.func.isRequired,
  maturityLevel: PropTypes.shape({
    id: PropTypes.number,
    groupKey: PropTypes.string,
    completed: PropTypes.bool,
    completedUserNames: PropTypes.arrayOf(PropTypes.string),
  }),
  title: PropTypes.string.isRequired,
  goals: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      leadershipAttributeGroupId: PropTypes.number,
      status: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  leadershipAttributeGroupKey: PropTypes.string,
};

MaturityLevelRubric.defaultProps = {
  maturityLevel: [],
  goals: [],
  leadershipAttributeGroupKey: '',
};

export default MaturityLevelRubric;
