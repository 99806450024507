import React, { useMemo, useRef, useState } from 'react';
import { Box, ClickAwayListener, Popper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Button, PeriodDisplayName, Typography } from '../../atoms';
import { LogLevelsMap, Semesters } from '../../../constants/enums';
import { ReactComponent as PlusIcon } from '../../../resources/icons/plus.svg';

const AddCoursesDropdown = ({
  selectedPeriods: selectedPeriodsProp,
  periodsToAdd,
  dayIndex,
  onChange,
  weekIndex,
  semester,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const selectedPeriods = useMemo(() => selectedPeriodsProp ?? [], [selectedPeriodsProp]);

  const detailsRef = useRef(null);

  const onPeriodAdd = (period) => {
    onChange(
      [...selectedPeriods, { ...period, orderNumber: selectedPeriods.length }],
      dayIndex,
      {
        [LogLevelsMap.SUCCESS]: {
          text: t('The period has been added.'),
          type: 'add',
        },
        [LogLevelsMap.ERROR]: {
          text: t('Failed to add the period. Please try once more'),
          type: 'error',
        },
      },
      weekIndex,
      semester,
    );
    setIsOpen(false);
  };

  return (
    <ClickAwayListener
      onClickAway={() => {
        if (isOpen) {
          setIsOpen(false);
        }
      }}
    >
      <Box
        className={classnames('ayo-add-courses-dropdown', {
          'ayo-add-courses-dropdown--opened': isOpen,
        })}
      >
        <Button
          ref={detailsRef}
          fullWidth
          gaLabel="Add a period"
          onClick={() => setIsOpen((prevState) => !prevState)}
          startIcon={<PlusIcon />}
          variant="text"
        >
          {t('Add a period')}
        </Button>
        <Popper
          anchorEl={detailsRef.current}
          className="ayo-add-courses-dropdown__popper"
          disablePortal={false}
          modifiers={[
            {
              name: 'flip',
              enabled: true,
            },
            {
              name: 'offset',
              options: {
                offset: [0, 8],
              },
            },
            {
              name: 'sameWidth',
              enabled: true,
              fn: ({ state }) => {
                // eslint-disable-next-line no-param-reassign
                state.styles.popper.width = `${state.rects.reference.width}px`;
              },
              phase: 'beforeWrite',
              requires: ['computeStyles'],
            },
          ]}
          open={isOpen}
          placement="bottom"
        >
          <Box className="ayo-add-courses-dropdown__popper-container">
            {periodsToAdd?.map((periodToAdd) => (
              <Box
                key={`${periodToAdd.periodName}`}
                alignItems="center"
                className={classnames('ayo-day-schedule__item')}
                display="flex"
                mb={1}
                mx={1}
                onClick={() => onPeriodAdd(periodToAdd)}
                px={3}
                style={{ backgroundColor: periodToAdd.color }}
                tabIndex={0}
              >
                <Typography variant="subtitle2">
                  <PeriodDisplayName period={periodToAdd.periodName} />
                </Typography>
              </Box>
            ))}
          </Box>
        </Popper>
      </Box>
    </ClickAwayListener>
  );
};

AddCoursesDropdown.propTypes = {
  selectedPeriods: PropTypes.arrayOf(
    PropTypes.shape({
      periodName: PropTypes.string,
      orderNumber: PropTypes.number,
    }),
  ).isRequired,
  dayIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  weekIndex: PropTypes.number,
  semester: PropTypes.oneOf(Object.values(Semesters)),
  periodsToAdd: PropTypes.arrayOf(
    PropTypes.shape({
      periodName: PropTypes.string,
      orderNumber: PropTypes.number,
    }),
  ).isRequired,
};

AddCoursesDropdown.defaultProps = {
  weekIndex: null,
  semester: '',
};

export default React.memo(AddCoursesDropdown);
