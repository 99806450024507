import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { DialogContent } from '@mui/material';

import { Dialog, DialogTitle, Typography } from '../../../../atoms';
import CommentsForm from '../comments-form/CommentsForm';

const CommentsEditDialog = ({
  className,
  onClose,
  isOpen,
  title,
  cancelEditMode,
  commentId,
  resourceId,
  commentValueEdit,
  editCommentHandler,
  parentCommentId,
  subjectValueEdit,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog className={className} onClose={onClose} open={isOpen}>
      <DialogTitle disableTypography>
        <Typography component="h2" variant="subtitle1">
          {t(title)}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <CommentsForm
          cancelEditMode={cancelEditMode}
          commentId={commentId}
          commentValueEdit={commentValueEdit}
          editCommentHandler={editCommentHandler}
          isButtonsFullWidth
          outlined
          parentCommentId={parentCommentId}
          resourceId={resourceId}
          subjectValueEdit={subjectValueEdit}
        />
      </DialogContent>
    </Dialog>
  );
};

CommentsEditDialog.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  cancelEditMode: PropTypes.func.isRequired,
  commentId: PropTypes.number.isRequired,
  commentValueEdit: PropTypes.string.isRequired,
  editCommentHandler: PropTypes.func.isRequired,
  parentCommentId: PropTypes.number.isRequired,
  subjectValueEdit: PropTypes.string,
  resourceId: PropTypes.number,
};

CommentsEditDialog.defaultProps = {
  className: '',
  subjectValueEdit: null,
  resourceId: null,
};

export default CommentsEditDialog;
