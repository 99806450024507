import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

import { Typography } from '../../../../../atoms';
import { ReactComponent as CircleIcon } from '../../../../../../resources/icons/circle.svg';

const DisplayAssignmentOptions = ({ options }) => (
  <Box>
    {options.map((option) => (
      <Box key={option} alignItems="center" display="flex" mb={2}>
        <Box display="flex" mr={2}>
          <CircleIcon />
        </Box>
        <Typography variant="body2">{option}</Typography>
      </Box>
    ))}
  </Box>
);

DisplayAssignmentOptions.propTypes = {
  options: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
};

DisplayAssignmentOptions.defaultProps = {
  options: [],
};

export default DisplayAssignmentOptions;
