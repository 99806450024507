import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  TimelineConnector,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab';
import classnames from 'classnames';

import { Button, Timeline, Typography } from '../../../../../../atoms';
import {
  InformationalCaption,
  PopupActionsButtons,
  TextWithTooltip,
} from '../../../../../../moleculas';
import {
  ActionStepCreateDialog,
  ConfirmDialog,
  LeaveDialog,
  MilestoneCard,
} from '../../../../../../organisms';
import GoalInputs from '../goal-inputs/GoalInputs';
import {
  AppActions,
  AppContext,
  LeadershipAttributesActions,
  LeadershipAttributesContext,
  UserContext,
} from '../../../../../../../context';
import { useStudentsService } from '../../../../../../../services';
import { useLeadershipAttributesData } from '../../../../../../../hooks';
import { dateToFormattedString, sortArrayByKey } from '../../../../../../../utils';
import { goalsRoute, trainAyoRoute } from '../../../../../../../constants/routes';
import {
  InputsValidationErrors,
  InputsValidationRules,
  LeadershipAttributesStatuses,
  RolesMap,
} from '../../../../../../../constants/enums';
import {
  GoalDialogContent,
  GoalFormMode,
  GoalSnackBarMessages,
  GoalsMilestonesStatuses,
} from '../../../../../../../constants/goals';
import { ReactComponent as AddIcon } from '../../../../../../../resources/icons/add.svg';
import { ReactComponent as GoalFormIllustration } from '../../../../../../../resources/images/goal-form-illustration.svg';
import { ReactComponent as SuccessIcon } from '../../../../../../../resources/icons/check_circle.svg';
import useSnackbar from '../../../../../../../hooks/use-snackbar/useSnackbar';

const isTitleValid = (title) =>
  title.trim().length >= InputsValidationRules.MIN_INPUT_LENGTH &&
  title.length <= InputsValidationRules.MAX_TITLE_LENGTH;

const GoalAddEditForm = ({ mode }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const match = useRouteMatch();
  const history = useHistory();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  const { state: userState } = useContext(UserContext);
  const { state: appState, dispatch: dispatchAppState } = useContext(AppContext);
  const { state: leadershipAttributesState, dispatch: dispatchLeadershipAttributesState } =
    useContext(LeadershipAttributesContext);

  const { goalRelatedRubricData } = leadershipAttributesState;

  const { updateLeadershipAttributeEntity, leadershipAttributes } = useLeadershipAttributesData();
  const { getStudentGoalDetails, postStudentGoal, updateStudentGoal } = useStudentsService();
  const { setSnackBarStatus } = useSnackbar();

  const { resolver } = appState.onLogout;

  const [studentGoal, setStudentGoal] = useState({
    name: '',
    description: '',
    milestones: [],
    leadershipAttributeGroupKey: null,
    leadershipAttributeKey: null,
    reporter: {},
  });
  const [isAttributeDropdownDisabled, setIsAttributeDropdownDisabled] = useState(false);

  const profileData = userState?.profile;
  const isStudent = profileData.role === RolesMap.STUDENT;
  const isTeacher =
    profileData.role === RolesMap.TEACHER || profileData.role === RolesMap.ADMINISTRATOR;

  const hasGoalActionSteps = studentGoal.milestones.length > 0;

  const { goalId, studentId } = match.params;
  const currentStudentId = isStudent ? profileData.id : studentId;

  const isCreateMode = mode === GoalFormMode.CREATE;
  const isEditMode = mode === GoalFormMode.EDIT;

  const [goalNameForHeadline, setGoalNameForHeadline] = useState('');
  const [editingMilestoneIndex, setEditingMilestoneIndex] = useState(null);

  const [isGoalMilestoneDialog, setIsGoalMilestoneDialogOpen] = useState(false);

  const [nameErrorMessage, setNameErrorMessage] = useState('');
  const [descriptionErrorMessage, setDescriptionErrorMessage] = useState('');
  const [accessibilityActionStepMessage, setAccessibilityActionStepMessage] = useState('');

  const goalSnackBarMessages =
    GoalSnackBarMessages[
      profileData.role === RolesMap.ADMINISTRATOR ? RolesMap.TEACHER : profileData.role
    ];

  const setIsDirty = useCallback(
    (value) => {
      dispatchAppState({ type: AppActions.SET_IS_DIRTY, data: value });
    },
    [dispatchAppState],
  );

  const closeGoalMilestoneDialogHandler = () => {
    setIsGoalMilestoneDialogOpen(false);
    setEditingMilestoneIndex(null);
  };

  const openGoalMilestoneDialogHandler = useCallback(() => {
    setIsGoalMilestoneDialogOpen(true);
    setAccessibilityActionStepMessage('');
  }, []);

  const onGoalChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      setNameErrorMessage('');
      setDescriptionErrorMessage('');
      const newGoalState = {
        ...studentGoal,
        [name]: value,
      };
      setStudentGoal(newGoalState);
      setIsDirty(true);
      if (
        isCreateMode &&
        !newGoalState.name.trim() &&
        !newGoalState.description.trim() &&
        !newGoalState.milestones.length
      ) {
        setIsDirty(false);
      }
    },
    [isCreateMode, setIsDirty, studentGoal],
  );

  const onGoalMilestonesChange = useCallback(
    (milestone, index) => {
      if (index !== null) {
        setStudentGoal((prevState) => ({
          ...prevState,
          milestones: sortArrayByKey(
            Object.assign([...prevState.milestones], {
              [index]: {
                ...prevState.milestones[index],
                name: milestone.name,
                dueDate: dateToFormattedString(milestone.dueDate),
              },
            }),
            'dueDate',
            'date',
            'increase',
          ),
        }));
      } else {
        setStudentGoal((prevState) => ({
          ...prevState,
          milestones: sortArrayByKey(
            [
              {
                ...milestone,
                dueDate: dateToFormattedString(milestone.dueDate),
                reporterId: profileData.id,
              },
              ...prevState.milestones,
            ],
            'dueDate',
            'date',
            'increase',
          ),
        }));
      }
      setEditingMilestoneIndex(null);
      setIsGoalMilestoneDialogOpen(false);
      setIsDirty(true);
      if (index !== null) {
        setTimeout(() => {
          setAccessibilityActionStepMessage('Action step has been edited');
        }, 1000);
      } else {
        setSnackBarStatus({
          text: t(goalSnackBarMessages.ACTION_STEP_CREATED),
          type: 'success',
        });
      }
    },
    [goalSnackBarMessages.ACTION_STEP_CREATED, profileData.id, setIsDirty, setSnackBarStatus, t],
  );

  const [deletionMilestioneIndex, setDeletionMilestioneIndex] = useState(null);
  const onGoalMilestoneDeleteStart = useCallback((milestoneIndex) => {
    setDeletionMilestioneIndex(milestoneIndex);
  }, []);

  const onGoalMilestioneDeleteEnd = useCallback(() => setDeletionMilestioneIndex(null), []);

  const onGoalMilestonesDelete = useCallback(() => {
    setStudentGoal((prevState) => ({
      ...prevState,
      milestones: prevState.milestones.filter((el, index) => index !== deletionMilestioneIndex),
    }));
    if (isEditMode) {
      setIsDirty(true);
    }
    if (
      isCreateMode &&
      !studentGoal.name &&
      !studentGoal.description &&
      !studentGoal.milestones.length <= 1
    ) {
      setIsDirty(false);
    }
    setSnackBarStatus({
      text: t(goalSnackBarMessages.ACTION_STEP_DELETED),
      type: 'delete',
    });
    setDeletionMilestioneIndex(null);
  }, [
    isEditMode,
    isCreateMode,
    studentGoal,
    setSnackBarStatus,
    t,
    goalSnackBarMessages.ACTION_STEP_DELETED,
    deletionMilestioneIndex,
    setIsDirty,
  ]);

  const onGoalMilestonesEditButtonClick = useCallback((milestone, index) => {
    setIsGoalMilestoneDialogOpen(true);
    setEditingMilestoneIndex(index);
  }, []);

  const isValid = useCallback(() => {
    let nameValidationError = '';
    let descriptionValidationError = '';
    if (studentGoal.name.trim().length < InputsValidationRules.MIN_INPUT_LENGTH) {
      nameValidationError = InputsValidationErrors(
        t,
        InputsValidationRules.MIN_INPUT_LENGTH,
      ).MIN_ERROR_TEXT;
    }
    if (studentGoal.name.length > InputsValidationRules.MAX_TITLE_LENGTH) {
      nameValidationError = InputsValidationErrors(
        t,
        InputsValidationRules.MAX_TITLE_LENGTH,
      ).MAX_ERROR_TEXT;
    }

    if (
      studentGoal.description.trim().length &&
      studentGoal.description.trim().length < InputsValidationRules.MIN_INPUT_LENGTH
    ) {
      descriptionValidationError = InputsValidationErrors(
        t,
        InputsValidationRules.MIN_INPUT_LENGTH,
      ).MIN_ERROR_TEXT;
    }
    if (
      studentGoal.description.length &&
      studentGoal.description.length > InputsValidationRules.MAX_INPUT_LENGTH
    ) {
      descriptionValidationError = InputsValidationErrors(
        t,
        InputsValidationRules.MAX_INPUT_LENGTH,
      ).MAX_ERROR_TEXT;
    }
    setNameErrorMessage(nameValidationError);
    setDescriptionErrorMessage(descriptionValidationError);
    if (isStudent) {
      return !nameValidationError && !descriptionValidationError && studentGoal.milestones.length;
    }
    return !nameValidationError && !descriptionValidationError;
  }, [isStudent, studentGoal.description, studentGoal.milestones.length, studentGoal.name, t]);

  const onGoalPostHandler = useCallback(() => {
    postStudentGoal(studentGoal, currentStudentId).then((data) => {
      setIsDirty(false);
      if (studentGoal.leadershipAttributeGroupKey) {
        const goalLeadershipAttribute = leadershipAttributes.find((attribute) =>
          Object.values(attribute.attributeGroups).some((maturityLevelStatements) =>
            maturityLevelStatements.some(
              (statement) => statement.groupKey === studentGoal.leadershipAttributeGroupKey,
            ),
          ),
        );
        goalLeadershipAttribute.status = LeadershipAttributesStatuses.ONGOING;
        updateLeadershipAttributeEntity('leadershipAttributes', goalLeadershipAttribute, 'key');
      }
      setSnackBarStatus({
        text: t(goalSnackBarMessages.GOAL_CREATED),
        type: 'success',
      });
      history.push(`${trainAyoRoute}/${studentId}${goalsRoute}/${data}`);
    });
  }, [
    postStudentGoal,
    studentGoal,
    currentStudentId,
    setIsDirty,
    setSnackBarStatus,
    t,
    goalSnackBarMessages.GOAL_CREATED,
    history,
    studentId,
    leadershipAttributes,
    updateLeadershipAttributeEntity,
  ]);

  const onGoalUpdateHandler = useCallback(
    ({ shouldRedirectToGoalsDetailsPage }) => {
      updateStudentGoal(studentGoal, goalId, currentStudentId)
        .then(() => {
          setIsDirty(false);
          if (isEditMode) {
            setSnackBarStatus({
              text: t(goalSnackBarMessages.GOAL_EDITED_SUCCESS),
              type: 'success',
            });
          }
        })
        .catch(() => {
          setIsDirty(false);
          if (isEditMode) {
            setSnackBarStatus({
              text: t(goalSnackBarMessages.GOAL_EDITED_ERROR),
              type: 'error',
            });
          }
        })
        .finally(() => {
          if (shouldRedirectToGoalsDetailsPage) {
            history.push(`${trainAyoRoute}/${studentId}${goalsRoute}/${goalId}`);
          }
        });
    },
    [
      updateStudentGoal,
      studentGoal,
      goalId,
      currentStudentId,
      setIsDirty,
      isEditMode,
      setSnackBarStatus,
      t,
      goalSnackBarMessages.GOAL_EDITED_SUCCESS,
      goalSnackBarMessages.GOAL_EDITED_ERROR,
      history,
      studentId,
    ],
  );

  const onGoalSubmit = useCallback(
    ({ shouldRedirectToGoalsDetailsPage }) => {
      if (isValid()) {
        if (isEditMode) {
          if (appState.isDirty) {
            onGoalUpdateHandler({ shouldRedirectToGoalsDetailsPage });
          } else {
            history.push(`${trainAyoRoute}/${studentId}${goalsRoute}/${goalId}`);
          }
        }
        if (isCreateMode) {
          onGoalPostHandler();
        }
      }
    },
    [
      appState.isDirty,
      goalId,
      history,
      isCreateMode,
      isEditMode,
      isValid,
      onGoalPostHandler,
      onGoalUpdateHandler,
      studentId,
    ],
  );

  const onLeaveDialogHandler = useCallback(() => {
    if (isEditMode) {
      onGoalSubmit({ shouldRedirectToGoalsDetailsPage: false });
    }
    if (isCreateMode) {
      if (resolver) {
        resolver(false);
      }
    }
  }, [isCreateMode, isEditMode, onGoalSubmit, resolver]);

  useEffect(() => {
    if (goalId) {
      getStudentGoalDetails(currentStudentId, goalId).then((goal) => {
        if (isEditMode && profileData.id !== goal.reporter?.id) {
          history.push(`${trainAyoRoute}/${studentId}${goalsRoute}/${goalId}`);
        }
        setStudentGoal(goal);
        setGoalNameForHeadline(goal.name);
        setIsAttributeDropdownDisabled(goal.leadershipAttributeKey);
      });
    }
    if (goalRelatedRubricData) {
      setStudentGoal({
        ...studentGoal,
        leadershipAttributeGroupKey: goalRelatedRubricData.title,
        name: `${t(goalRelatedRubricData.title)}`,
        leadershipAttributeKey: goalRelatedRubricData.leadershipAttributeKey,
      });
      dispatchLeadershipAttributesState({
        type: LeadershipAttributesActions.SET_GOAL_RELATED_RUBRIC_DATA,
        data: null,
      });
      setIsAttributeDropdownDisabled(true);
      setIsDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentStudentId,
    getStudentGoalDetails,
    goalId,
    setStudentGoal,
    dispatchLeadershipAttributesState,
    goalRelatedRubricData,
    setIsDirty,
  ]);

  return (
    <Box className="ayo-goal-form">
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box display="flex" flexDirection="column" maxWidth={isWidthUpSm ? '50%' : '100%'} mb={1}>
          <TextWithTooltip
            headerLevel={1}
            rowsCount={2}
            title={isCreateMode ? t('Create a goal') : goalNameForHeadline}
            titleVariant="h1"
          />
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" maxWidth={isWidthUpSm ? '50%' : '100%'} mb={5}>
        <Typography noWrap variant="body2">
          {isCreateMode
            ? t('Let’s get started with some important details!')
            : t('Let’s edit your existing goal')}
        </Typography>
        {isCreateMode && (
          <Box>
            <Typography className="ayo-goal-form__info-text" display="inline" variant="body2">
              <Trans
                components={{ b: <b /> }}
                i18nKey={
                  isStudent
                    ? 'You need at least a title and 1 action step to create a goal'
                    : 'You need to name the goal you want to create'
                }
              />{' '}
              {((isStudent && isTitleValid(studentGoal.name) && hasGoalActionSteps) ||
                (isTeacher && isTitleValid(studentGoal.name))) && <SuccessIcon />}
            </Typography>
          </Box>
        )}
      </Box>
      <Grid container>
        <Grid item sm={6} xs={12}>
          <GoalInputs
            description={studentGoal.description}
            descriptionErrorMessage={descriptionErrorMessage}
            isAttributeDropdownDisabled={isAttributeDropdownDisabled}
            isCreating={isCreateMode}
            leadershipAttributeKey={studentGoal.leadershipAttributeKey}
            name={studentGoal.name}
            nameErrorMessage={nameErrorMessage}
            onInputsChange={onGoalChange}
            showDescriptionInfo={isCreateMode && isTeacher}
          />
          <Typography component="h2" variant="subtitle1">
            {t('Action steps')}
          </Typography>
          {hasGoalActionSteps && isEditMode && (
            <Box mt={1}>
              <Typography variant="body2">
                {t('You can edit or delete only the action steps that you added')}
              </Typography>
            </Box>
          )}
          {isStudent && !hasGoalActionSteps && (
            <Box mt={2}>
              <InformationalCaption title="You need at least 1 action step" />
            </Box>
          )}
          {!hasGoalActionSteps && isTeacher && (
            <Box mt={2}>
              <InformationalCaption title="You can add action steps if you want, or leave it to the student" />
            </Box>
          )}
          {hasGoalActionSteps && (
            <Box className="ayo-goal-form__milestone-timelines" mt={2}>
              <Timeline align="left">
                {studentGoal.milestones.map((milestone, index) => (
                  <TimelineItem key={milestone.id}>
                    <TimelineSeparator>
                      <TimelineDot
                        className={`${
                          milestone.status === GoalsMilestonesStatuses.COMPLETED &&
                          'ayo-timelines-completed'
                        }`}
                      />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineOppositeContent>
                      <MilestoneCard
                        actionStep={milestone}
                        className={classnames('ayo-goal-form__milestone-timelines__card', {
                          'ayo-goal-form__milestone-timelines__card--no-margin':
                            index === studentGoal.milestones.length - 1,
                        })}
                        goalDetails={(({ id, leadershipAttributeKey, name, status }) => ({
                          id,
                          leadershipAttributeKey,
                          name,
                          status,
                        }))(studentGoal)}
                        index={index}
                        onMilestoneDelete={onGoalMilestoneDeleteStart}
                        onMilestoneEdit={onGoalMilestonesEditButtonClick}
                        showActionsButtons={
                          milestone.status !== GoalsMilestonesStatuses.COMPLETED &&
                          profileData.id === milestone.reporterId
                        }
                        studentId={+studentId}
                      />
                    </TimelineOppositeContent>
                  </TimelineItem>
                ))}
              </Timeline>
            </Box>
          )}
          <Box ml={hasGoalActionSteps ? 8 : 3} mt={hasGoalActionSteps ? 2 : 3}>
            <Button
              disableElevation
              disableRipple
              endIcon={<AddIcon />}
              gaLabel="Add an action step"
              onClick={openGoalMilestoneDialogHandler}
            >
              {t('Add an action step')}
            </Button>
          </Box>
        </Grid>
        <Grid item sm={6} xs={12}>
          {isCreateMode && (
            <Box ml={isWidthUpSm && 10} mt={!isWidthUpSm && 3}>
              <GoalFormIllustration
                aria-label={t(
                  'A giant sports fan’s foam hand with Go! Go! words and yellow stars in the air',
                )}
                className="ayo-svg-illustration--secondary"
              />
            </Box>
          )}
        </Grid>
        <Grid item sm={6} xs={12}>
          {isCreateMode && hasGoalActionSteps && isStudent && (
            <Box mt={4}>
              <InformationalCaption title="The goals are visible for your parents and teachers in AYO" />
            </Box>
          )}
          <Box mt={5}>
            <PopupActionsButtons
              primaryButtonGaLabel={isCreateMode ? 'Create a goal' : 'Save goal changes'}
              primaryButtonHandler={() => onGoalSubmit({ shouldRedirectToGoalsDetailsPage: true })}
              primaryButtonText={t(isCreateMode ? 'Create a goal' : 'Save changes')}
              secondaryButtonGaLabel={isCreateMode ? 'Cancel goal creating' : 'Cancel goal editing'}
              secondaryButtonHandler={() =>
                history.push(
                  isCreateMode
                    ? `${trainAyoRoute}/${studentId}${goalsRoute}`
                    : `${trainAyoRoute}/${studentId}${goalsRoute}/${goalId}`,
                )
              }
              secondaryButtonText={t('Cancel')}
              xsGridBreakpoint={9}
            />
          </Box>
        </Grid>
      </Grid>
      <ActionStepCreateDialog
        isOpen={isGoalMilestoneDialog}
        milestone={studentGoal.milestones[editingMilestoneIndex]}
        milestoneIndex={editingMilestoneIndex}
        onClose={closeGoalMilestoneDialogHandler}
        onSave={onGoalMilestonesChange}
      />
      <LeaveDialog
        onPrimaryClick={!isCreateMode ? onLeaveDialogHandler : null}
        onSecondaryClick={isCreateMode ? onLeaveDialogHandler : null}
        primaryButtonTitle={isCreateMode ? 'Yes, I want to leave' : 'Save and leave'}
        secondaryButtonTitle={isCreateMode ? 'I want to stay' : 'Do not save and leave'}
        shouldResetNextLocationRef={isCreateMode}
        text={
          isEditMode
            ? GoalDialogContent.LEAVE_DIALOG_EDIT.text
            : GoalDialogContent.LEAVE_DIALOG_CREATE.text
        }
        title={
          isEditMode
            ? GoalDialogContent.LEAVE_DIALOG_EDIT.title
            : GoalDialogContent.LEAVE_DIALOG_CREATE.title
        }
      />
      <ConfirmDialog
        cancelButtonTitle="Don't delete"
        confirmButtonTitle="Delete"
        isOpen={Number.isInteger(deletionMilestioneIndex)}
        onClose={onGoalMilestioneDeleteEnd}
        onConfirm={onGoalMilestonesDelete}
        text={GoalDialogContent.CONFIRM_DIALOG_DELETE_ACTION_STEP.text}
        title={GoalDialogContent.CONFIRM_DIALOG_DELETE_ACTION_STEP.title}
      />
      <div className="sr-only" role="alert">
        {t(accessibilityActionStepMessage)}
      </div>
    </Box>
  );
};

GoalAddEditForm.propTypes = {
  mode: PropTypes.oneOf([GoalFormMode.CREATE, GoalFormMode.EDIT]),
};

GoalAddEditForm.defaultProps = {
  mode: GoalFormMode.CREATE,
};

export default GoalAddEditForm;
