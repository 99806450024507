import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import { Button, Typography } from '../../../atoms';
import { InitiativeEmptyStateBlock } from '../../../moleculas';
import { eventType } from '../../../../constants/propTypes';
import { EventDialogModes, EventResourceTypes } from '../../../../constants/events';
import { UserContext } from '../../../../context';
import { capitalize, getFormattedDate, sortArrayByKey } from '../../../../utils';
import { ReactComponent as AddIcon } from '../../../../resources/icons/add.svg';
import EventCard from '../components/event-card/EventCard';
import EventsActions from '../components/events-actions/EventsActions';

const groupAndSortByMonth = (events, lang) => {
  const groupedByMonth = events.reduce((acc, event) => {
    const startDate = dayjs(event.startDate);
    const month = capitalize(getFormattedDate(event.startDate, lang, { month: 'long' }));
    const year = startDate.year();
    const key = `${year}-${month}`;

    acc[key] = acc[key] || {
      month,
      year,
      events: [],
      showYear: !dayjs().isSame(startDate, 'year'),
    };
    acc[key].events.push(event);

    return acc;
  }, {});

  return Object.values(groupedByMonth)
    .sort(
      (a, b) => a.year - b.year || dayjs(a.month, 'MMMM').month() - dayjs(b.month, 'MMMM').month(),
    )
    .map(({ events: eventsArr, ...rest }) => ({
      ...rest,
      events: sortArrayByKey(eventsArr, 'startDate', 'date', 'increase'),
    }));
};

const EventsCalendar = ({
  emptyStateConfig,
  events,
  fullLabel,
  isEditable,
  onDelete,
  onEventDialogAction,
}) => {
  const { t, i18n } = useTranslation();

  const { state: userState } = useContext(UserContext);

  const groupedEvents = useMemo(
    () => groupAndSortByMonth(events, i18n.language),
    [events, i18n.language],
  );

  return (
    <Box className="ayo-events-block__side-block">
      {events?.length ? (
        <Box display="flex" flexDirection="column" gap={3}>
          {isEditable && (
            <Button
              className="ayo-events-block__add-button"
              endIcon={<AddIcon />}
              gaLabel="Add an event"
              onClick={() => onEventDialogAction(EventDialogModes.CREATE)}
            >
              {t('Add an event')}
            </Button>
          )}
          <Grid container spacing={6}>
            {groupedEvents.map(({ month, year, events: eventsArr, showYear }) => (
              <Grid key={`${month}-${year}`} item xs={12}>
                <Typography
                  component={isEditable ? 'h2' : 'h3'}
                  mb={3}
                  variant="subtitle1"
                >{`${month}${showYear ? `, ${year}` : ''}`}</Typography>
                <Grid container spacing={3}>
                  {eventsArr.map((event) => (
                    <Grid
                      key={event.id}
                      item
                      sm={event.resourceType === EventResourceTypes.FAMILY_FEED ? 6 : 12}
                      xs={12}
                    >
                      <EventCard
                        actions={
                          event.ownerId === userState.profile.id ? (
                            <EventsActions
                              onDelete={() => onDelete(event)}
                              onEdit={() => onEventDialogAction(EventDialogModes.EDIT, event)}
                            />
                          ) : null
                        }
                        event={event}
                        fullLabel={fullLabel}
                        onClick={() => onEventDialogAction(EventDialogModes.VIEW, event)}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Box>
      ) : (
        <InitiativeEmptyStateBlock
          customButton={
            isEditable && (
              <Button
                endIcon={<AddIcon />}
                gaLabel="Add an event"
                onClick={() => onEventDialogAction(EventDialogModes.CREATE)}
              >
                {t('Create an event')}
              </Button>
            )
          }
          illustration={emptyStateConfig.illustration}
          title={emptyStateConfig.title}
        />
      )}
    </Box>
  );
};

EventsCalendar.propTypes = {
  emptyStateConfig: PropTypes.instanceOf(Object).isRequired,
  events: PropTypes.arrayOf(eventType).isRequired,
  fullLabel: PropTypes.bool,
  isEditable: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
  onEventDialogAction: PropTypes.func.isRequired,
};

EventsCalendar.defaultProps = {
  fullLabel: false,
  isEditable: false,
};

export default EventsCalendar;
