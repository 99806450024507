import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Typography } from '../../../../atoms';
import { SingleBarVariants } from '../../../../../constants/enums';

const MultilineAxisLabel = ({ x, y, width, height, tickFormatter, payload, variant }) => (
  <foreignObject className="x-axis-tick-multiline" height={height} width={width} x={x} y={y}>
    <Typography
      align="center"
      aria-hidden="true"
      className={classNames('x-axis-tick-multiline-value', variant.toLowerCase(), {
        oneword: tickFormatter(payload.value)?.split(' ').length === 1,
      })}
      title={tickFormatter(payload.value)}
      variant="body2"
    >
      {tickFormatter(payload.value)}
    </Typography>
  </foreignObject>
);

MultilineAxisLabel.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  tickFormatter: PropTypes.func,
  payload: PropTypes.shape({ value: PropTypes.string }).isRequired,
  variant: PropTypes.oneOf(Object.values(SingleBarVariants)),
};

MultilineAxisLabel.defaultProps = {
  variant: SingleBarVariants.DEFAULT,
  tickFormatter: (value) => value,
};

export default MultilineAxisLabel;
