import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Box, DialogActions, DialogContent } from '@mui/material';

import { Dialog, DialogTitle, Typography, Button } from '../../../../../atoms';
import {
  DialogErrorContent,
  DialogSuccessContent,
  Dropdown,
  PopupActionsButtons,
  STTTextField,
} from '../../../../../moleculas';
import { AppActions, AppContext, UserContext } from '../../../../../../context';
import { useStudentsService } from '../../../../../../services';
import { getFullName, transformFileUploadData } from '../../../../../../utils';
import { byteToMegaByteCoeff } from '../../../../../../constants/values';
import { InputsValidationErrors, InputsValidationRules } from '../../../../../../constants/enums';
import { ReactComponent as SuccessImage } from '../../../../../../resources/images/modal_feedback_sent.svg';
import FileUpload from '../../../../fileupload/FileUpload';

const RecommendationRequestNamesMap = {
  ATTACHMENTS: 'attachments',
  DETAILS: 'details',
  PORTFOLIO_ID: 'portfolioId',
  TEACHER_ID: 'teacherId',
};

const getFormValidation = (values) => {
  const validation = {
    [RecommendationRequestNamesMap.DETAILS]:
      values[RecommendationRequestNamesMap.DETAILS].trim().length >=
        InputsValidationRules.MIN_INPUT_LENGTH &&
      values[RecommendationRequestNamesMap.DETAILS].length <=
        InputsValidationRules.MAX_INPUT_LENGTH,
    [RecommendationRequestNamesMap.TEACHER_ID]: !!values[RecommendationRequestNamesMap.TEACHER_ID],
  };

  return {
    isFormValid: !Object.values(validation).some((value) => !value),
    minDetailsLengthError:
      values[RecommendationRequestNamesMap.DETAILS].trim().length <
      InputsValidationRules.MIN_INPUT_LENGTH,
    emptyTeacherError: !values[RecommendationRequestNamesMap.TEACHER_ID],
  };
};

const PortfolioAddRecommendationRequestDialog = ({
  isOpen,
  onClose,
  portfolioId,
  studentTeachers,
  isPortfolioVisible,
  changePortfolioVisibilityHandler,
}) => {
  const { t } = useTranslation();
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [requestData, setRequestData] = useState([]);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isFormSuccessSubmitted, setIsFormSuccessSubmitted] = useState(false);
  const [inputsErrorsMessages, setInputsErrorsMessages] = useState({});
  const submitButtonRef = useRef();
  const { dispatch: dispatchAppState } = useContext(AppContext);
  const { patchStudentPortfolio } = useStudentsService();

  const { state: userState } = useContext(UserContext);
  const studentId = userState.profile?.id;

  const { postStudentRecommendationRequest } = useStudentsService();

  useEffect(() => {
    setRequestData({
      details: '',
      teacherId: null,
      portfolioId,
    });
    setFilesToUpload([]);
    setInputsErrorsMessages({});
    setIsFormSubmitted(false);
    setIsFormSuccessSubmitted(false);
  }, [isOpen, portfolioId]);

  const makeDraftVisibleHandler = useCallback(() => {
    const requestBody = {
      visible: true,
    };
    patchStudentPortfolio(studentId, portfolioId, requestBody, true)
      .then(() => changePortfolioVisibilityHandler(true))
      .catch(() => {
        dispatchAppState({
          type: AppActions.SET_SNACKBAR_STATUS,
          data: {
            text: t('AYO couldn’t save your edits Please try once more'),
            type: 'error',
          },
        });
      });
  }, [
    changePortfolioVisibilityHandler,
    patchStudentPortfolio,
    portfolioId,
    studentId,
    dispatchAppState,
    t,
  ]);

  const requestChangeHandler = useCallback(
    (e) => {
      const { name, value } = e.target;
      setRequestData((state) => ({
        ...state,
        [name]: value,
      }));
      if (inputsErrorsMessages[name]) {
        setInputsErrorsMessages((state) => ({ ...state, [name]: '' }));
      }
    },
    [inputsErrorsMessages],
  );

  const isValid = useCallback(() => {
    const { minDetailsLengthError, emptyTeacherError, isFormValid } =
      getFormValidation(requestData);
    if (minDetailsLengthError) {
      setInputsErrorsMessages((state) => ({
        ...state,
        [RecommendationRequestNamesMap.DETAILS]: InputsValidationErrors(
          t,
          InputsValidationRules.MIN_INPUT_LENGTH,
        ).MIN_ERROR_TEXT,
      }));
    }
    if (emptyTeacherError) {
      setInputsErrorsMessages((state) => ({
        ...state,
        [RecommendationRequestNamesMap.TEACHER_ID]: InputsValidationErrors(t).REQUIRED_ERROR_TEXT,
      }));
    }
    return isFormValid;
  }, [requestData, t]);

  const requestSubmitHandler = useCallback(() => {
    if (isValid()) {
      postStudentRecommendationRequest(
        transformFileUploadData({
          ...requestData,
          attachments: filesToUpload,
        }),
        studentId,
      )
        .then(() => {
          setIsFormSuccessSubmitted(true);
        })
        .finally(() => {
          setIsFormSubmitted(true);
        });
    }
  }, [filesToUpload, isValid, postStudentRecommendationRequest, requestData, studentId]);

  return (
    <Dialog
      className="ayo-portfolio__add-recommendations-request-dialog"
      onClose={onClose}
      open={isOpen}
      transitionDuration={{ exit: 0 }}
    >
      {!isFormSubmitted ? (
        <Box>
          {isPortfolioVisible ? (
            <>
              <DialogTitle disableTypography>
                <Typography variant="h2">{t('Request a recommendation')}</Typography>
              </DialogTitle>
              <DialogContent>
                <Box
                  mb={inputsErrorsMessages[RecommendationRequestNamesMap.TEACHER_ID] ? 4 : 3}
                  mt={3}
                >
                  <Dropdown
                    error={inputsErrorsMessages[RecommendationRequestNamesMap.TEACHER_ID]}
                    fullWidth
                    gaLabel="Recommendation request educator"
                    handleChange={requestChangeHandler}
                    helperText={inputsErrorsMessages[RecommendationRequestNamesMap.TEACHER_ID]}
                    label={t('Select an educator from the list')}
                    name={RecommendationRequestNamesMap.TEACHER_ID}
                    options={studentTeachers?.map((teacher) => ({
                      value: teacher.id,
                      label: getFullName(teacher),
                    }))}
                    outlined
                    required
                    value={requestData[RecommendationRequestNamesMap.TEACHER_ID]}
                  />
                </Box>
                <Box mb={3}>
                  <STTTextField
                    error={inputsErrorsMessages[RecommendationRequestNamesMap.DETAILS]}
                    fullWidth
                    gaLabel="Recommendation request description"
                    helperText={inputsErrorsMessages[RecommendationRequestNamesMap.DETAILS]}
                    label={t('I need a recommendation for')}
                    maxLength={InputsValidationRules.MAX_INPUT_LENGTH}
                    multiline
                    name={RecommendationRequestNamesMap.DETAILS}
                    onChange={requestChangeHandler}
                    outlined
                    required
                    rowsCount={3}
                    value={requestData[RecommendationRequestNamesMap.DETAILS]}
                  />
                </Box>
                <Box mb={3}>
                  <Box mb={1}>
                    <Typography component="h3" variant="subtitle2">
                      {t('Attachments')}
                    </Typography>
                  </Box>
                  <FileUpload
                    allowedExtensions={['jpg', 'jpeg', 'png', 'pdf']}
                    focusableRef={submitButtonRef}
                    maxAttachmentsAllowed={3}
                    maxFileSize={5 * byteToMegaByteCoeff}
                    onFileAdd={(file) => {
                      setFilesToUpload((state) => [...state, file]);
                    }}
                    onFileRemove={(file) => {
                      setFilesToUpload((state) => [...state.filter((x) => x !== file)]);
                    }}
                  />
                </Box>
              </DialogContent>
              <DialogActions>
                <PopupActionsButtons
                  primaryButtonGaLabel="Request"
                  primaryButtonHandler={requestSubmitHandler}
                  primaryButtonText={t('Request')}
                  secondaryButtonGaLabel="Cancel"
                  secondaryButtonHandler={onClose}
                  secondaryButtonText={t('Cancel')}
                />
              </DialogActions>
            </>
          ) : (
            <>
              <DialogTitle disableTypography>
                <Typography component="h2" variant="subtitle1">
                  {t('Before we start, you need to make this draft visible')}
                </Typography>
              </DialogTitle>
              <DialogContent>
                <Box mb={5} mt={2}>
                  <Typography variant="body2">
                    {t(
                      'Your draft is hidden now. You need to make it visible to request a recommendation from your educator. Let’s do it?',
                    )}
                  </Typography>
                </Box>
              </DialogContent>
              <DialogActions>
                <Box width="100%">
                  <Button
                    fullWidth
                    gaLabel="Make my draft visible"
                    onClick={makeDraftVisibleHandler}
                    variant="primary"
                  >
                    {t('Make my draft visible')}
                  </Button>
                  <Box mt={2}>
                    <Button fullWidth gaLabel="Cancel" onClick={onClose}>
                      {t('Cancel')}
                    </Button>
                  </Box>
                </Box>
              </DialogActions>
            </>
          )}
        </Box>
      ) : isFormSuccessSubmitted ? (
        <DialogSuccessContent
          buttonClickHandler={onClose}
          buttonText="Close"
          gaLabel="Close"
          isFormSubmitted={isFormSubmitted}
          successImage={
            <SuccessImage aria-label={t('A green envelope with a message Sent')} role="img" />
          }
          text="AYO will notify your educator shortly"
          title="Thank you for your request!"
        />
      ) : (
        <DialogErrorContent
          gaLabel="Retry"
          isErrored={!isFormSuccessSubmitted}
          isSubmitted={isFormSubmitted}
          onRetry={requestSubmitHandler}
          text={t('Please try once more')}
        />
      )}
    </Dialog>
  );
};

PortfolioAddRecommendationRequestDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  portfolioId: PropTypes.number.isRequired,
  isPortfolioVisible: PropTypes.bool.isRequired,
  changePortfolioVisibilityHandler: PropTypes.func.isRequired,
  studentTeachers: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
};

export default PortfolioAddRecommendationRequestDialog;
