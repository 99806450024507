import { Box } from '@mui/material';
import React from 'react';

import { ScrollToTopButton } from '../../atoms';

const PageEndScrollToTopButton = () => (
  <Box className="page-end-scroll-to-top-container">
    <ScrollToTopButton navigationAnchor="main-content" />
  </Box>
);

export default PageEndScrollToTopButton;
