import React from 'react';
import { Box, DialogActions, DialogContent, Fade, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Button, Typography } from '../../atoms';
import { ReactComponent as ErrorImage } from '../../../resources/images/modal_feedback_not_sent.svg';

const DialogErrorContent = ({ gaLabel, isSubmitted, isErrored, onRetry, text }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <>
      <Fade in={isErrored} timeout={1000}>
        <Box pb={3} pt={isWidthUpSm ? 3 : 0}>
          <DialogContent>
            <ErrorImage aria-label={t('A red envelope with a message Not sent')} role="img" />
            <Box pb={1} pt={3}>
              <Typography component="h1" variant="subtitle1">
                {t('Oops')}
              </Typography>
              <Typography component="h1" variant="subtitle1">
                {t('Something went wrong')}
              </Typography>
            </Box>
            <Typography variant="body2">{text}</Typography>
          </DialogContent>
        </Box>
      </Fade>
      <Fade in={isSubmitted} timeout={1000}>
        <DialogActions>
          <Box width={isWidthUpSm ? '50%' : '100%'}>
            <Button fullWidth gaLabel={gaLabel} onClick={onRetry} variant="primary">
              {t('Retry')}
            </Button>
          </Box>
        </DialogActions>
      </Fade>
    </>
  );
};

DialogErrorContent.propTypes = {
  gaLabel: PropTypes.string.isRequired,
  isSubmitted: PropTypes.bool.isRequired,
  isErrored: PropTypes.bool.isRequired,
  onRetry: PropTypes.func.isRequired,
  text: PropTypes.func.isRequired,
};

export default DialogErrorContent;
