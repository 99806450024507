import React, { useCallback } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Checkbox, FormControlLabel, Typography } from '../../../../../../../atoms';
import { ValidationCard } from '../../../../../../../moleculas';
import { useLessonPlannerData } from '../../../../../../../../hooks';
import { LessonsScheduleSettings } from '../../../../../../../../constants/enums';
import { workingDaysConfig } from '../../../../../../../../constants/configs';

const CheckBoxesGroup = ({ onChange, selectedDays, weekNumber, onBlur }) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between" pr={5} width="100%">
      {workingDaysConfig.map((workingDay) => (
        <Box key={workingDay.label}>
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedDays?.some((day) => day === workingDay.value)}
                gaLabel="Working days selected"
                name={workingDay.value}
                onBlur={onBlur}
                onChange={(e) => onChange(e, weekNumber)}
              />
            }
            label={
              <Typography variant="body2">
                {t(`workingDaysLabels.short.${workingDay.label}`)}
              </Typography>
            }
          />
        </Box>
      ))}
    </Box>
  );
};

CheckBoxesGroup.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedDays: PropTypes.arrayOf(PropTypes.string).isRequired,
  weekNumber: PropTypes.number,
  onBlur: PropTypes.func,
};

CheckBoxesGroup.defaultProps = {
  weekNumber: null,
  onBlur: () => {},
};

const WorkingDays = ({ helperText, onChange, onValidation }) => {
  const { t } = useTranslation();

  const {
    updateLessonPlanerCreationState,
    rotationDaysSchedule,
    rotationWeeksSchedule,
    lessonPlannerData,
  } = useLessonPlannerData();

  const setWorkingDays = useCallback(
    (e) => {
      const { name, checked } = e.target;
      const workingDays =
        rotationDaysSchedule?.workingDays || rotationWeeksSchedule[0]?.workingDays || [];
      onChange([
        LessonsScheduleSettings.ROTATION_DAYS_SCHEDULE,
        LessonsScheduleSettings.ROTATION_WEEKS_SCHEDULE,
      ]);

      const newWorkingDays = checked
        ? [...workingDays, name]
        : workingDays.filter((day) => day !== name);

      const newSemesters = lessonPlannerData.stateData.semestersSchedule.map((semester) => {
        const changingScheduleKey = rotationDaysSchedule
          ? LessonsScheduleSettings.ROTATION_DAYS_SCHEDULE
          : LessonsScheduleSettings.ROTATION_WEEKS_SCHEDULE;
        if (rotationDaysSchedule) {
          return {
            ...semester,
            [changingScheduleKey]: {
              ...semester[changingScheduleKey],
              workingDays: newWorkingDays,
            },
          };
        }
        return {
          ...semester,
          [changingScheduleKey]: semester[changingScheduleKey].map((week) => {
            const { daysSchedule } = week;
            const newDaysSchedule = checked
              ? [
                  ...daysSchedule.map((day) => ({ ...day, courses: day.courses ?? null })),
                  { day: name, courses: null },
                ]
              : daysSchedule.filter(({ day }) => day !== name);
            return {
              ...week,
              workingDays: newWorkingDays,
              daysSchedule: newDaysSchedule,
            };
          }),
        };
      });
      updateLessonPlanerCreationState({
        settingsKeys: [LessonsScheduleSettings.SEMESTERS],
        settingsValues: [newSemesters],
      });
    },
    [
      lessonPlannerData,
      onChange,
      rotationDaysSchedule,
      rotationWeeksSchedule,
      updateLessonPlanerCreationState,
    ],
  );

  const selectedWorkingDays =
    rotationDaysSchedule?.workingDays || rotationWeeksSchedule[0]?.workingDays || [];

  return (
    <Box mb={8} mt={8}>
      <Box mb={2}>
        <Typography component="h2" variant="subtitle1">
          {t('Your working days')}
        </Typography>
      </Box>
      <ValidationCard error={helperText} helperText={t(helperText)}>
        <Box pr={5}>
          <CheckBoxesGroup
            onBlur={onValidation(
              rotationDaysSchedule
                ? LessonsScheduleSettings.ROTATION_DAYS_SCHEDULE
                : LessonsScheduleSettings.ROTATION_WEEKS_SCHEDULE,
            )}
            onChange={setWorkingDays}
            selectedDays={selectedWorkingDays}
          />
        </Box>
      </ValidationCard>
    </Box>
  );
};

WorkingDays.propTypes = {
  helperText: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onValidation: PropTypes.func,
};

WorkingDays.defaultProps = {
  onValidation: () => {},
};

export default WorkingDays;
