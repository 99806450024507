import React, { useCallback, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { Button, Typography } from '../../../../../atoms';
import { ConfirmDialog } from '../../../../../organisms';
import { ReactComponent as CloseIcon } from '../../../../../../resources/icons/close.svg';
import { NewFeaturesIdsMap } from '../../../../../../tours/common/NewFeaturesItemsProvider';
import { NewFeatureHotspot } from '../../../../../moleculas';

const LessonPageRemovableBlock = ({
  blockContent,
  confirmDialogData,
  id,
  isDefaultContent,
  isStatic,
  onRemove,
  title,
}) => {
  const { t } = useTranslation();

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const currentBlockRef = useRef();
  const removeBtnRef = useRef();

  const handleSectionRemove = useCallback(() => {
    onRemove();

    setIsConfirmDialogOpen(false);
  }, [onRemove]);

  const isRenderTitle = useMemo(() => !isStatic || title !== t('Text'), [isStatic, t, title]);

  return (
    <>
      <Box
        ref={currentBlockRef}
        className={classNames('ayo-lesson-page__section__content', {
          'ayo-lesson-page__section__content--static': isStatic,
        })}
        id={id}
      >
        <Box
          alignItems="center"
          display="flex"
          justifyContent="space-between"
          mb={isRenderTitle ? 3 : 0}
        >
          <Box alignItems="center" display="flex">
            {isRenderTitle && <Typography variant="h2">{title}</Typography>}
            {title === t('Materials') ? (
              <NewFeatureHotspot
                id={NewFeaturesIdsMap.LESSON_DESIGNER_MATERIALS}
                isClickable
                label={t('Materials')}
              />
            ) : null}
          </Box>
          {!isStatic && (
            <Button
              ref={removeBtnRef}
              aria-label={t('Close')}
              gaLabel={`Close ${title} block`}
              isIconButton
              onClick={isDefaultContent ? handleSectionRemove : () => setIsConfirmDialogOpen(true)}
            >
              <CloseIcon height="24" width="24" />
            </Button>
          )}
        </Box>
        {blockContent}
      </Box>
      {confirmDialogData && (
        <ConfirmDialog
          cancelButtonTitle="Cancel"
          confirmButtonTitle={confirmDialogData.confirmButtonTitle}
          isOpen={isConfirmDialogOpen}
          onClose={() => setIsConfirmDialogOpen(false)}
          onConfirm={handleSectionRemove}
          text={confirmDialogData.text}
          title={confirmDialogData.title}
        />
      )}
    </>
  );
};

LessonPageRemovableBlock.propTypes = {
  blockContent: PropTypes.node.isRequired,
  confirmDialogData: PropTypes.shape({
    confirmButtonTitle: PropTypes.string,
    text: PropTypes.string,
    title: PropTypes.string,
  }),
  id: PropTypes.string.isRequired,
  isDefaultContent: PropTypes.bool,
  isStatic: PropTypes.bool,
  onRemove: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

LessonPageRemovableBlock.defaultProps = {
  confirmDialogData: null,
  isDefaultContent: false,
  isStatic: false,
};

export default LessonPageRemovableBlock;
