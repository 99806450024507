import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { Typography } from '../../../../../atoms';
import { STTTextField } from '../../../../../moleculas';
import { UserContext } from '../../../../../../context';
import { useLessonPlannerData } from '../../../../../../hooks';
import {
  InputsValidationErrors,
  InputsValidationRules,
  LessonPlannerStates,
  LessonsScheduleSettings,
  Semesters,
} from '../../../../../../constants/enums';
import ContinueButton from '../continue-button/ContinueButton';
import { myLessonsRoute } from '../../../../../../constants/routes';
import { useTeachersService } from '../../../../../../services';
import { getIsScheduleAvailable } from '../../../../../../utils';

import AcademicYears from './components/academic-years/AcademicYears';
import ScheduleTypes from './components/schedule-types/ScheduleTypes';
import WorkingDays from './components/working-days/WorkingDays';

const SemesterCreationStates = {
  FALL: LessonPlannerStates.CREATING_COURSE_SCHEDULE_FALL,
  SPRING: LessonPlannerStates.CREATING_COURSE_SCHEDULE_SPRING,
};

const BasicSettings = () => {
  const { t } = useTranslation();
  const {
    params: { scheduleId },
  } = useRouteMatch();

  const [validationErrors, setValidationErrors] = useState({});
  const [scheduleName, setScheduleName] = useState('');

  const {
    updateLessonPlanerCreationState,
    rotationDaysSchedule,
    rotationWeeksSchedule,
    lessonPlannerData,
    isLessonPlannerNameUnique,
    semestersDaysConfig,
  } = useLessonPlannerData();

  useEffect(() => {
    if (lessonPlannerData) {
      setScheduleName(lessonPlannerData.stateData.scheduleName);
    }
  }, [lessonPlannerData]);

  const resetValidationErrors = useCallback((settingsKeys) => {
    setValidationErrors((prevState) => ({
      ...prevState,
      ...Object.assign(...settingsKeys.map((key) => ({ [key]: '' }))),
    }));
  }, []);

  const onNameChange = useCallback(
    (e) => {
      resetValidationErrors([LessonsScheduleSettings.SCHEDULE_NAME]);
      setScheduleName(e.target.value);
    },
    [resetValidationErrors],
  );

  const isValid = useCallback(
    (setting, plannerData = lessonPlannerData) => {
      const validationErrorMessages = {};
      const isSettingValidated = (settingName) => !setting || settingName === setting;
      if (
        plannerData?.stateData?.scheduleName.trim().length <
          InputsValidationRules.MIN_INPUT_LENGTH &&
        isSettingValidated(LessonsScheduleSettings.SCHEDULE_NAME)
      ) {
        validationErrorMessages[LessonsScheduleSettings.SCHEDULE_NAME] = InputsValidationErrors(
          t,
          InputsValidationRules.MIN_INPUT_LENGTH,
        ).MIN_ERROR_TEXT;
      }
      if (
        plannerData?.stateData?.scheduleName.length > InputsValidationRules.MAX_TITLE_LENGTH &&
        isSettingValidated(LessonsScheduleSettings.SCHEDULE_NAME)
      ) {
        validationErrorMessages[LessonsScheduleSettings.SCHEDULE_NAME] = InputsValidationErrors(
          t,
          InputsValidationRules.MAX_TITLE_LENGTH,
        ).MAX_ERROR_TEXT;
      }
      if (
        !isLessonPlannerNameUnique(plannerData?.stateData?.scheduleName, plannerData?.id) &&
        isSettingValidated(LessonsScheduleSettings.SCHEDULE_NAME)
      ) {
        validationErrorMessages[LessonsScheduleSettings.SCHEDULE_NAME] = t(
          'This name already exists, please change it',
        );
      }
      if (
        !plannerData?.stateData?.semestersSchedule?.length ||
        (!plannerData?.stateData?.semestersSchedule?.some((semester) => semester.semester) &&
          isSettingValidated(LessonsScheduleSettings.SEMESTERS))
      ) {
        validationErrorMessages[LessonsScheduleSettings.SEMESTERS] = getIsScheduleAvailable(
          semestersDaysConfig.semesters,
        )
          ? 'Please select at least 1 semester for scheduling'
          : 'The semesters will be available in AYO shortly';
      }
      if (
        rotationDaysSchedule &&
        !rotationDaysSchedule?.workingDays?.length &&
        isSettingValidated(LessonsScheduleSettings.ROTATION_DAYS_SCHEDULE)
      ) {
        validationErrorMessages[LessonsScheduleSettings.ROTATION_DAYS_SCHEDULE] =
          'Please select your working days';
      }
      if (
        rotationWeeksSchedule &&
        rotationWeeksSchedule?.some(({ workingDays }) => !workingDays?.length) &&
        isSettingValidated(LessonsScheduleSettings.ROTATION_WEEKS_SCHEDULE)
      ) {
        validationErrorMessages[LessonsScheduleSettings.ROTATION_WEEKS_SCHEDULE] =
          'Please select your working days';
      }
      const newValidationErrors = setting
        ? { ...validationErrors, ...validationErrorMessages }
        : validationErrorMessages;
      setValidationErrors(newValidationErrors);
      return !Object.keys(validationErrorMessages).length;
    },
    [
      isLessonPlannerNameUnique,
      lessonPlannerData,
      rotationDaysSchedule,
      rotationWeeksSchedule,
      semestersDaysConfig.semesters,
      t,
      validationErrors,
    ],
  );

  const history = useHistory();

  const { putLessonScheduleCreationState, postLessonScheduleCreationState } = useTeachersService();

  const onNextButtonClick = useCallback(() => {
    if (isValid()) {
      const hasFallSemester = lessonPlannerData.stateData.semestersSchedule.find(
        (semesterSchedule) => semesterSchedule.semester === Semesters.FALL,
      );
      const newState = hasFallSemester
        ? SemesterCreationStates.FALL
        : SemesterCreationStates.SPRING;
      const newPlannerData = { ...lessonPlannerData, state: newState };
      if (!scheduleId) {
        postLessonScheduleCreationState(newPlannerData, t('Preparing your schedule setup...')).then(
          ({ id }) => {
            history.replace(`${myLessonsRoute}/${id}`);
            updateLessonPlanerCreationState({
              state: newState,
              id,
            });
          },
        );
      } else {
        putLessonScheduleCreationState(newPlannerData, scheduleId);
        updateLessonPlanerCreationState({ state: newState });
      }
    }
  }, [
    history,
    isValid,
    updateLessonPlanerCreationState,
    lessonPlannerData,
    scheduleId,
    putLessonScheduleCreationState,
    postLessonScheduleCreationState,
    t,
  ]);

  const onValidation =
    (setting, plannerData = lessonPlannerData) =>
    () =>
      isValid(setting, plannerData);

  const { state: userState } = useContext(UserContext);

  return (
    <Grid container>
      <Grid item md={6} xl={7} xs={12}>
        <Box mb={6}>
          <Typography variant="body2">
            {t(
              'Before planning the lessons, AYO needs to have your planner and schedule set up so that you’ll see your curriculum priorities and plan accordingly.',
            )}
          </Typography>
        </Box>
        <Box mb={8}>
          <Typography component="h2" variant="subtitle1">
            {t('Name')}
          </Typography>
          <Box mt={3}>
            <STTTextField
              error={validationErrors[LessonsScheduleSettings.SCHEDULE_NAME] || ''}
              fullWidth
              gaLabel="Lesson Planner name change"
              helperText={validationErrors[LessonsScheduleSettings.SCHEDULE_NAME]}
              InputLabelProps={{ id: 'lesson-planner-name' }}
              inputProps={{
                'aria-labelledby': 'lesson-planner-name',
                autoComplete: 'off',
              }}
              label={t('Name')}
              maxLength={InputsValidationRules.MAX_TITLE_LENGTH}
              name="schedule-name"
              onBlur={() => {
                const updatedState = updateLessonPlanerCreationState({
                  settingsKeys: [LessonsScheduleSettings.SCHEDULE_NAME],
                  settingsValues: [scheduleName],
                });
                onValidation(LessonsScheduleSettings.SCHEDULE_NAME, updatedState)();
              }}
              onChange={onNameChange}
              required
              value={scheduleName}
            />
          </Box>
        </Box>
        <AcademicYears
          helperText={validationErrors[LessonsScheduleSettings.SEMESTERS]}
          onChange={resetValidationErrors}
          onValidation={onValidation(LessonsScheduleSettings.SEMESTERS)}
        />
        <ScheduleTypes
          availableTypes={[
            LessonsScheduleSettings.ROTATION_WEEKS_SCHEDULE,
            !userState.profile.isQuestTeacher && LessonsScheduleSettings.ROTATION_DAYS_SCHEDULE,
          ].filter(Boolean)}
          onChange={resetValidationErrors}
        />
        <WorkingDays
          helperText={
            validationErrors[LessonsScheduleSettings.ROTATION_DAYS_SCHEDULE] ||
            validationErrors[LessonsScheduleSettings.ROTATION_WEEKS_SCHEDULE]
          }
          onChange={resetValidationErrors}
          onValidation={onValidation}
        />
        <ContinueButton buttonName="Next" isPreviousButtonHidden onNext={onNextButtonClick} />
      </Grid>
    </Grid>
  );
};

export default BasicSettings;
