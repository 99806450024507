import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@mui/material';

import { ReactComponent as MicrophoneIcon } from '../../../resources/icons/input_microphone.svg';

const SpeechButton = ({ disabled, onClick, isActive }) => {
  const { t } = useTranslation();

  return (
    <IconButton
      className={classnames('ayo-icon-button', { active: isActive })}
      disabled={disabled}
      disableRipple
      onClick={onClick}
      title={isActive ? t('Stop voice input') : t('Voice input')}
    >
      <MicrophoneIcon />
    </IconButton>
  );
};

SpeechButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
};

SpeechButton.defaultProps = {
  disabled: false,
};

export default SpeechButton;
