import { RolesMap } from './enums';
import { familyFeedCreationCenterRoute, familyFeedMyFeedRoute, familyFeedRoute } from './routes';

export const mesquiteDistrictLabel = 'Mesquite ISD';

export const FeedPostKeys = {
  DESCRIPTION: 'description',
  TITLE: 'title',
  LINK: 'link',
  SELECTED_ENTITIES: 'selectedEntities',
  SHARING_LEVEL: 'sharingLevel',
  SHARING_GROUPS: 'sharingGroups',
};

export const SharingLevels = {
  DISTRICT: {
    label: 'District level',
    title: 'Districts',
    value: 'DISTRICT',
  },
  CAMPUS: {
    label: 'Campus level',
    title: 'Campuses',
    value: 'CAMPUS',
  },
  CLASS: {
    label: 'Class level',
    title: 'Classes',
    value: 'CLASS',
  },
  PERSONALIZED: {
    label: 'Personalized',
    title: 'Students',
    value: 'PERSONALIZED',
  },
};

export const FeedPostAuthors = {
  AYO: 'AYO',
  MISD: mesquiteDistrictLabel,
};

export const FeedPostSharingGroupsKeys = {
  ALL: 'ALL',
  STAFF: 'STAFF',
  GUARDIANS: 'GUARDIANS',
  STUDENTS: 'STUDENTS',
};

export const ClubsPostSharingGroupsKeys = {
  ALL: 'ALL',
  MEMBERS: 'MEMBERS',
  GUARDIANS: 'GUARDIANS',
};

export const FeedPostSharingGroups = {
  ALL: {
    text: 'All',
    label: 'Everyone',
    clubLabel: 'This post is available to existing members and non-members of this club.',
    value: [FeedPostSharingGroupsKeys.ALL],
  },
  PARENTS_STUDENTS: {
    text: 'Parents and students',
    label: 'Parents and students',
    clubLabel: 'Club members can view this post.',
    value: [FeedPostSharingGroupsKeys.GUARDIANS, FeedPostSharingGroupsKeys.STUDENTS],
  },
  STUDENTS: {
    text: 'Parents and students',
    label: 'Parents and students',
    clubLabel: 'Club members can view this post.',
    value: [FeedPostSharingGroupsKeys.STUDENTS],
  },
  PARENTS: {
    text: 'Parents',
    label: 'Parents',
    clubLabel: 'Parents of the club members can view this post.',
    value: [FeedPostSharingGroupsKeys.GUARDIANS],
  },
  STAFF: {
    text: 'Staff representatives',
    label: 'Staff representatives',
    value: [FeedPostSharingGroupsKeys.STAFF],
  },
};

export const ClubPostSharingGroups = {
  ALL: {
    text: 'All',
    label: 'Everyone',
    value: [FeedPostSharingGroupsKeys.ALL],
  },
  MEMBERS: {
    text: 'Club members can view this post.',
    label: 'Club members can view this post.',
    value: [ClubsPostSharingGroupsKeys.MEMBERS],
  },
  GUARDIANS: {
    text: 'Club members can view this post.',
    label: 'Parents of the club members can view this post.',
    value: [ClubsPostSharingGroupsKeys.GUARDIANS],
  },
};

export const PostsSourceMap = {
  FAMILY: 'FAMILY',
  CLUBS: 'CLUBS',
};

export const PostsSharingGroupsBySourceMap = {
  FAMILY: FeedPostSharingGroups,
  CLUBS: ClubPostSharingGroups,
};

export const FamilyFeedPages = {
  [RolesMap.TEACHER]: familyFeedCreationCenterRoute,
  [RolesMap.ADMINISTRATOR]: familyFeedCreationCenterRoute,
  [RolesMap.GUARDIAN]: familyFeedRoute,
  [RolesMap.STUDENT]: familyFeedMyFeedRoute,
};
