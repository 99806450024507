import React, { useContext, useEffect } from 'react';
import { Grid, Container } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ExtracurricularsSection, PageWrapper } from '../../../organisms';
import { UserActions, UserContext } from '../../../../context';
import { trainAyoRoute } from '../../../../constants/routes';
import { useOneTimeActionService } from '../../../../services';
import { OneTimeActionsMap } from '../../../../constants/enums';

const ExtracurricularsPage = () => {
  const { t } = useTranslation();

  const { state: userState, dispatch: dispatchUserState } = useContext(UserContext);

  const { postOneTimeAction } = useOneTimeActionService();

  useEffect(() => {
    if (!userState.oneTimeActions.includes(OneTimeActionsMap.ADJUST_EXTRACURRICULARS_OPEN)) {
      postOneTimeAction(OneTimeActionsMap.ADJUST_EXTRACURRICULARS_OPEN);
      dispatchUserState({
        type: UserActions.SET_ONE_TIME_ACTION,
        data: OneTimeActionsMap.ADJUST_EXTRACURRICULARS_OPEN,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <PageWrapper
      backToLink={trainAyoRoute}
      backToText={t('Go to Train AYO')}
      mainElementClassName="ayo-extracurriculars"
    >
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <ExtracurricularsSection
              headerLevel={1}
              isEditable
              studentId={userState.profile.id}
              text={t('Here, you can select the extracurriculars for this and last year')}
              title={t('My extracurriculars')}
              titleVariant="h1"
            />
          </Grid>
        </Grid>
      </Container>
    </PageWrapper>
  );
};

export default ExtracurricularsPage;
