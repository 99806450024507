import React from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Typography } from '../../../../atoms';
import { DisplayAvatarEditorImage } from '../../../../moleculas';
import { ReactComponent as AcademicCapIcon } from '../../../../../resources/icons/academic_cap.svg';
import { ReactComponent as EnvelopeIcon } from '../../../../../resources/icons/envelope_dark.svg';
import defaultAvatarImage from '../../../../../resources/images/default_avatar.svg';
import PortfolioSection from '../../components/portfolio-section/PortfolioSection';

const PortfolioSharedPersonalInformationSection = ({ avatar, sectionData, token }) => {
  const { t } = useTranslation();

  return (
    <PortfolioSection
      body={
        <Box>
          <Box alignItems="center" display="flex" flexDirection="row">
            <Box mr={1}>
              <AcademicCapIcon />
            </Box>
            <Typography variant="body2">{sectionData?.school}</Typography>
          </Box>
          <Box my={5}>
            <Typography component="h3" paragraph variant="subtitle2">
              {t('Contacts')}
            </Typography>
            {sectionData?.email ? (
              <Box alignItems="center" display="flex" flexDirection="row">
                <Box mr={1}>
                  <EnvelopeIcon />
                </Box>
                <Typography variant="body2">{sectionData.email}</Typography>
              </Box>
            ) : (
              <Typography variant="body2">{t('No contacts provided so far')}</Typography>
            )}
          </Box>
          <Typography component="h3" paragraph variant="subtitle2">
            {t('Summary')}
          </Typography>
          {sectionData?.summary ? (
            <Typography variant="body2">{sectionData.summary}</Typography>
          ) : (
            <Typography variant="body2">{t('No summary provided so far')}</Typography>
          )}
        </Box>
      }
      className="ayo-personal-information-section ayo-full-screen-portfolio-section"
      mediaContent={
        <Box>
          <DisplayAvatarEditorImage
            avatar={avatar?.image}
            avatarPlaceholder={defaultAvatarImage}
            avatarTransforms={avatar?.transforms}
            token={token}
          />
        </Box>
      }
      reflection={sectionData?.reflection}
      title={sectionData?.name}
    />
  );
};

PortfolioSharedPersonalInformationSection.propTypes = {
  sectionData: PropTypes.instanceOf(Object).isRequired,
  avatar: PropTypes.instanceOf(Object),
  token: PropTypes.string.isRequired,
};

PortfolioSharedPersonalInformationSection.defaultProps = {
  avatar: null,
};

export default PortfolioSharedPersonalInformationSection;
