/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import { Button, ErrorWrapper, TextField } from '../../../../atoms';
import {
  Dropdown,
  NewFeatureHotspot,
  PopupActionsButtons,
  STTTextField,
} from '../../../../moleculas';
import { ImageFilePreviewWithAltDescription } from '../../../../moleculas/image-file-preview/ImageFilePreview';
import { ChipsBlock, FileUpload } from '../../../../organisms';
import { useFamilyFeed } from '../../../../../hooks';
import {
  focusDialogCloseButton,
  formatUrl,
  getSharingGroupsLabel,
  validateStringLength,
} from '../../../../../utils';
import { urlAllowedValidationRE } from '../../../../../constants/regexps';
import { byteToMegaByteCoeff } from '../../../../../constants/values';
import { FeedPostKeys, FeedPostSharingGroups } from '../../../../../constants/family-feed';
import {
  FeedPostCategories,
  FeedPostCategoriesLabels,
  FeedPostStatuses,
  InputsValidationErrors,
  InputsValidationRules,
} from '../../../../../constants/enums';
import { ReactComponent as UsersIcon } from '../../../../../resources/icons/users.svg';
import { ReactComponent as AcademicCapIcon } from '../../../../../resources/icons/academic_cap.svg';
import { NewFeaturesIdsMap } from '../../../../../tours/common/NewFeaturesItemsProvider';
import {
  postSliderSettings,
  postsSliderResponsiveConfig,
} from '../../../../../constants/carouselSliderConfigs';

const getFormValidation = (values) => {
  const validation = {
    [FeedPostKeys.TITLE]:
      !values[FeedPostKeys.TITLE] ||
      validateStringLength(
        values[FeedPostKeys.TITLE],
        InputsValidationRules.MAX_TITLE_LENGTH,
        'max',
      ),
    [FeedPostKeys.DESCRIPTION]:
      validateStringLength(
        values[FeedPostKeys.DESCRIPTION],
        InputsValidationRules.MIN_INPUT_LENGTH,
      ) &&
      validateStringLength(
        values[FeedPostKeys.DESCRIPTION],
        InputsValidationRules.MAX_TEXT_AREA_LENGTH,
        'max',
      ),
    [FeedPostKeys.LINK]:
      !values[FeedPostKeys.LINK]?.length ||
      (urlAllowedValidationRE.test(formatUrl(values[FeedPostKeys.LINK])) &&
        formatUrl(values[FeedPostKeys.LINK]).length <= InputsValidationRules.MAX_LINK_INPUT_LENGTH),
    [FeedPostKeys.SELECTED_ENTITIES]: values[FeedPostKeys.SELECTED_ENTITIES].length > 0,
  };

  return {
    isFormValid: !Object.values(validation).some((value) => !value),
    minDescriptionLengthError: !validateStringLength(
      values[FeedPostKeys.DESCRIPTION],
      InputsValidationRules.MIN_INPUT_LENGTH,
    ),
    linkError: !validation[FeedPostKeys.LINK],
    minSelectedEntitiesError: !validation[FeedPostKeys.SELECTED_ENTITIES],
  };
};

const inputsDefaultData = {
  [FeedPostKeys.ALT_TEXT]: '',
  [FeedPostKeys.TITLE]: '',
  [FeedPostKeys.DESCRIPTION]: '',
  [FeedPostKeys.LINK]: '',
};

const sharedWithDefaultData = {
  [FeedPostKeys.SELECTED_ENTITIES]: [],
  [FeedPostKeys.SHARING_LEVEL]: null,
  [FeedPostKeys.SHARING_GROUPS]: null,
};

const PostForm = ({
  onClose,
  onShareWithDataChange,
  onVisibilityButtonClick,
  postDataToEdit,
  sharedWithData,
  additionalInfo,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });

  const { addPost, updatePost } = useFamilyFeed();

  const categoryOptions = useMemo(
    () => [
      { value: FeedPostCategories.UNCATEGORIZED, label: t(FeedPostCategoriesLabels.UNCATEGORIZED) },
      { value: FeedPostCategories.ALERTS, label: t(FeedPostCategoriesLabels.ALERTS) },
      { value: FeedPostCategories.ANNOUNCEMENTS, label: t(FeedPostCategoriesLabels.ANNOUNCEMENTS) },
      {
        value: FeedPostCategories.INITIATIVES_EVENTS,
        label: t(FeedPostCategoriesLabels.INITIATIVES_EVENTS),
      },
      {
        value: FeedPostCategories.STUDENT_SUPPORT,
        label: t(FeedPostCategoriesLabels.STUDENT_SUPPORT),
      },
    ],
    [t],
  );

  const initialPostData = useMemo(() => {
    if (postDataToEdit) {
      return {
        [FeedPostKeys.TITLE]: postDataToEdit[FeedPostKeys.TITLE],
        [FeedPostKeys.DESCRIPTION]: postDataToEdit[FeedPostKeys.DESCRIPTION],
        [FeedPostKeys.LINK]: postDataToEdit[FeedPostKeys.LINK],
      };
    }
    return inputsDefaultData;
  }, [postDataToEdit]);

  const initialCategoryOption = useMemo(
    () => (postDataToEdit ? postDataToEdit.category : FeedPostCategories.UNCATEGORIZED),
    [postDataToEdit],
  );

  const [categoryOption, setCategoryOption] = useState(initialCategoryOption);
  const [inputFieldsData, setInputFieldsData] = useState(initialPostData);
  const [dataErrorsMessages, setDataErrorsMessages] = useState({});
  const [attachments, setAttachments] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState(null);
  const [removedAttachments, setRemovedAttachments] = useState([]);
  const [altTexts, setAltTexts] = useState({});

  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (postDataToEdit?.attachments.length) {
      setUploadedFiles(
        postDataToEdit?.attachments.map((el) => ({
          file: { name: el.fileName, id: el.id, ownerId: el.ownerId, src: el.src },
          errors: [],
        })),
      );
      setAltTexts(
        postDataToEdit?.attachments.reduce(
          (acc, el) => ({ ...acc, [el.fileName]: el?.altText || '' }),
          {},
        ),
      );
    }
  }, [postDataToEdit]);

  const handleTouch = useCallback(() => {
    if (!isActive && !postDataToEdit) {
      setIsActive(() => true);
    }
  }, [isActive, postDataToEdit]);

  const handleAltText = useCallback(
    (altText, file) => {
      const altTextsCopy = { ...altTexts };
      altTextsCopy[file.name] = altText;
      setAltTexts(altTextsCopy);
      if (file.id && !attachments.some((item) => item.name === file.name)) {
        setAttachments((state) => [...state, file]);
      }
    },
    [altTexts, attachments],
  );

  const handleInputChange = useCallback(
    (e) => {
      const { name, value } = e.target;

      setInputFieldsData((state) => ({
        ...state,
        [name]: value,
      }));

      if (dataErrorsMessages[name]) {
        setDataErrorsMessages((state) => ({ ...state, [name]: '' }));
      }
    },
    [dataErrorsMessages],
  );

  const isValid = useCallback(() => {
    const formValidation = getFormValidation({
      ...inputFieldsData,
      selectedEntities: sharedWithData.selectedEntities,
    });
    const isAltTextsValid = !Object.values(altTexts).some(
      (text) => text.length > InputsValidationRules.MAX_INPUT_LENGTH,
    );

    if (formValidation.minDescriptionLengthError) {
      setDataErrorsMessages((state) => ({
        ...state,
        [FeedPostKeys.DESCRIPTION]: InputsValidationErrors(
          t,
          InputsValidationRules.MIN_INPUT_LENGTH,
        ).MIN_ERROR_TEXT,
      }));
    }
    if (formValidation.linkError) {
      setDataErrorsMessages((state) => ({
        ...state,
        [FeedPostKeys.LINK]: InputsValidationErrors(t).LINK_ERROR_TEXT,
      }));
    }
    if (formValidation.minSelectedEntitiesError) {
      setDataErrorsMessages((state) => ({
        ...state,
        [FeedPostKeys.SELECTED_ENTITIES]: t(
          'Select at least one checkbox to specify who can see the post.',
        ),
      }));
    }
    return formValidation.isFormValid && isAltTextsValid;
  }, [sharedWithData, inputFieldsData, altTexts, t]);

  const handleReset = useCallback(() => {
    if (onClose) {
      onClose();
    }
    onShareWithDataChange(sharedWithDefaultData);
    setCategoryOption(FeedPostCategories.UNCATEGORIZED);
    setInputFieldsData(inputsDefaultData);
    setAttachments([]);
    setAltTexts({});
    setDataErrorsMessages({});
    setIsActive(() => false);
    setUploadedFiles([]);
    setRemovedAttachments([]);
  }, [onClose, onShareWithDataChange]);

  const handleEdit = useCallback(() => {
    if (isValid()) {
      const formattedLink =
        inputFieldsData[FeedPostKeys.LINK] && formatUrl(inputFieldsData[FeedPostKeys.LINK]);

      const attachmentsBody = attachments.map((attachment) => {
        if (attachment.id) {
          return { ...attachment, altText: altTexts[attachment.name] };
        }
        return {
          file: attachment,
          altText: altTexts[attachment.name] || null,
        };
      });

      const body = {
        ...inputFieldsData,
        ...sharedWithData,
        [FeedPostKeys.LINK]: formattedLink,
        id: postDataToEdit?.id,
        status: FeedPostStatuses.EDITED,
        category: categoryOption,
        attachments: attachments.length ? attachmentsBody : null,
        removedAttachments: removedAttachments.length ? removedAttachments : null,
      };
      onClose();
      updatePost(body).then((posted) => {
        if (posted) {
          handleReset();
        }
      });
    }
  }, [
    altTexts,
    isValid,
    inputFieldsData,
    sharedWithData,
    postDataToEdit?.id,
    categoryOption,
    attachments,
    removedAttachments,
    onClose,
    updatePost,
    handleReset,
  ]);

  const handleCreate = useCallback(() => {
    if (isValid()) {
      const formattedLink =
        inputFieldsData[FeedPostKeys.LINK] && formatUrl(inputFieldsData[FeedPostKeys.LINK]);
      const attachmentsBody = attachments.map((attachment) => ({
        file: attachment,
        altText: altTexts[attachment.name],
      }));
      const body = {
        ...inputFieldsData,
        ...sharedWithData,
        [FeedPostKeys.LINK]: formattedLink,
        category: categoryOption,
        status: FeedPostStatuses.NEW,
        attachments: attachments.length ? attachmentsBody : null,
      };

      addPost(body).then((posted) => {
        if (posted) {
          handleReset();
        }
      });
    }
  }, [
    isValid,
    categoryOption,
    inputFieldsData,
    attachments,
    sharedWithData,
    addPost,
    handleReset,
    altTexts,
  ]);

  const handleChipDelete = useCallback(
    (item) => {
      onShareWithDataChange({
        ...sharedWithData,
        [FeedPostKeys.SHARING_LEVEL]:
          sharedWithData[FeedPostKeys.SELECTED_ENTITIES].length <= 1
            ? null
            : sharedWithData[FeedPostKeys.SHARING_LEVEL],
        [FeedPostKeys.SELECTED_ENTITIES]: sharedWithData[FeedPostKeys.SELECTED_ENTITIES].filter(
          (x) => x.id !== item.id || x.name !== item.name,
        ),
      });
      if (postDataToEdit) {
        focusDialogCloseButton();
      }
    },
    [onShareWithDataChange, postDataToEdit, sharedWithData],
  );

  const handleOptionChange = useCallback(
    (event) => {
      handleTouch();
      setCategoryOption(event.target.value);
    },
    [handleTouch],
  );

  return (
    <Box className="ayo-feed-post">
      <Box className="ayo-feed-post__form">
        <Box className="ayo-feed-post__form__inputs">
          <Dropdown
            fullWidth
            gaLabel="Post category"
            handleChange={handleOptionChange}
            label={t('Category')}
            options={categoryOptions}
            outlined
            value={categoryOption}
          />
          <STTTextField
            error={!!dataErrorsMessages[FeedPostKeys.TITLE]}
            fullWidth
            helperText={dataErrorsMessages[FeedPostKeys.TITLE]}
            label={t('Title')}
            maxLength={InputsValidationRules.MAX_TITLE_LENGTH}
            name={FeedPostKeys.TITLE}
            onChange={handleInputChange}
            onFocus={handleTouch}
            outlined
            value={inputFieldsData[FeedPostKeys.TITLE]}
          />
          <STTTextField
            error={dataErrorsMessages[FeedPostKeys.DESCRIPTION]}
            fullWidth
            helperText={dataErrorsMessages[FeedPostKeys.DESCRIPTION]}
            label={t('Post text')}
            maxLength={InputsValidationRules.MAX_TEXT_AREA_LENGTH}
            minRows={3}
            multiline
            name={FeedPostKeys.DESCRIPTION}
            onChange={handleInputChange}
            onFocus={handleTouch}
            outlined
            required
            value={inputFieldsData[FeedPostKeys.DESCRIPTION]}
          />
          <Box alignItems="center" display="flex">
            <TextField
              error={!!dataErrorsMessages[FeedPostKeys.LINK]}
              fullWidth
              gaLabel="Link input"
              helperText={dataErrorsMessages[FeedPostKeys.LINK]}
              InputLabelProps={{
                id: 'feed-post-link',
              }}
              inputProps={{ 'aria-labelledby': 'feed-post-link', autoComplete: 'off' }}
              label={t('Link')}
              name={FeedPostKeys.LINK}
              onChange={handleInputChange}
              onFocus={handleTouch}
              outlined
              value={inputFieldsData[FeedPostKeys.LINK]}
            />
            {!postDataToEdit && (
              <NewFeatureHotspot
                id={NewFeaturesIdsMap.POST_LINK}
                isClickable
                label={t('Link in a post')}
              />
            )}
          </Box>
        </Box>
        <FileUpload
          allowedExtensions={['jpg', 'jpeg', 'png']}
          FileThumbnail={ImageFilePreviewWithAltDescription}
          FileThumbnailProps={{
            altTexts,
            onAltChange: handleAltText,
            inputFieldName: 'alt text',
          }}
          label={t('Add image(s)')}
          maxAttachmentsAllowed={6}
          maxFileSize={10 * byteToMegaByteCoeff}
          onFileAdd={(newFile) => {
            handleTouch();
            setAttachments((prevState) => [...prevState, newFile]);
          }}
          onFileRemove={(file) => {
            if (file.id) {
              setRemovedAttachments((state) => [
                ...state,
                postDataToEdit.attachments.find((el) => el.id === file.id),
              ]);
            }
            setAttachments((state) => [...state.filter((x) => x !== file)]);
          }}
          sliderResponsiveConfig={postsSliderResponsiveConfig}
          sliderSettings={postSliderSettings}
          uploadedFiles={uploadedFiles}
        />
        {(isActive || postDataToEdit) && (
          <Box mt={3}>
            <ChipsBlock
              actionButtons={
                <ErrorWrapper errorMessage={dataErrorsMessages[FeedPostKeys.SELECTED_ENTITIES]}>
                  <Button
                    endIcon={<UsersIcon />}
                    gaLabel="Specify who can see the post"
                    onClick={onVisibilityButtonClick}
                  >
                    {t('Specify who can see the post')}
                  </Button>
                </ErrorWrapper>
              }
              clearAllHandler={() => onShareWithDataChange(sharedWithDefaultData)}
              editableChipsTitle={t('Who can see the post')}
              entities={sharedWithData[FeedPostKeys.SELECTED_ENTITIES]}
              icon={<AcademicCapIcon />}
              notEditableChipsTitle={t('Who can see the post')}
              onDeleteHandler={handleChipDelete}
              subtitle={
                sharedWithData[FeedPostKeys.SHARING_GROUPS] ? (
                  <Trans
                    components={{ b: <b /> }}
                    i18nKey="Who can see the post groups"
                    values={{
                      group: getSharingGroupsLabel(
                        sharedWithData[FeedPostKeys.SHARING_GROUPS],
                        FeedPostSharingGroups,
                      ),
                    }}
                  />
                ) : null
              }
              withEditableChips
            />
          </Box>
        )}
        {additionalInfo}
      </Box>
      {(isActive || postDataToEdit) && (
        <Box maxWidth={isWidthUpSm && !postDataToEdit ? '60%' : '100%'} mt={5}>
          <PopupActionsButtons
            primaryButtonGaLabel={postDataToEdit ? 'Save changes' : 'Post'}
            primaryButtonHandler={postDataToEdit ? handleEdit : handleCreate}
            primaryButtonText={postDataToEdit ? t('Save changes') : t('Post')}
            secondaryButtonGaLabel={postDataToEdit ? 'Cancel' : 'Discard'}
            secondaryButtonHandler={handleReset}
            secondaryButtonText={postDataToEdit ? t('Cancel') : t('Discard')}
          />
        </Box>
      )}
    </Box>
  );
};

PostForm.propTypes = {
  additionalInfo: PropTypes.node,
  onClose: PropTypes.func,
  onShareWithDataChange: PropTypes.func.isRequired,
  onVisibilityButtonClick: PropTypes.func.isRequired,
  postDataToEdit: PropTypes.shape({
    attachments: PropTypes.arrayOf(
      PropTypes.shape({
        fileName: PropTypes.string,
        id: PropTypes.number,
        ownerId: PropTypes.number,
        updatedDate: PropTypes.string,
      }),
    ),
    createdDate: PropTypes.string,
    category: PropTypes.string,
    description: PropTypes.string,
    id: PropTypes.number,
    selectedEntities: PropTypes.arrayOf(PropTypes.string),
    sharingLevel: PropTypes.string,
    title: PropTypes.string,
  }),
  sharedWithData: PropTypes.shape({
    selectedEntities: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ),
    sharingLevel: PropTypes.string,
  }).isRequired,
};

PostForm.defaultProps = {
  additionalInfo: null,
  onClose: null,
  postDataToEdit: null,
};

export default PostForm;
