import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, DialogActions, DialogContent } from '@mui/material';

import { Button, Dialog, DialogTitle, Snackbar, TextField, Typography } from '../../../../atoms';
import { ReactComponent as LinkIcon } from '../../../../../resources/icons/link_dark.svg';
import { useStudentsService } from '../../../../../services';

const PortfolioGetLinkDialog = ({ onClose, isDialogOpen, studentId, portfolioId }) => {
  const { t } = useTranslation();
  const [link, setLink] = useState('');
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const { getStudentSharedLink } = useStudentsService();

  const copyLinkHandler = useCallback(() => {
    navigator.clipboard.writeText(link);
    setIsSnackbarOpen(true);
  }, [link]);

  useEffect(() => {
    if (isDialogOpen) {
      getStudentSharedLink(studentId, portfolioId).then((data) => {
        setLink(`${window.location.origin}/portfolio/shared/${data}`);
      });
    }
  }, [isDialogOpen, studentId, portfolioId, getStudentSharedLink]);

  return (
    <Box>
      <Dialog gaLabel="Get link" onClose={onClose} open={isDialogOpen}>
        <DialogTitle>
          <Typography paragraph variant="subtitle1">
            {t('Get link')}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box mt={1}>
            <TextField
              fullWidth
              gaLabel="Shared portfolio Link"
              name="Shared portfolio Link"
              outlined
              value={link}
            />
          </Box>
          <Box mb={5} mt={2}>
            <Button endIcon={<LinkIcon />} gaLabel="Copy link" onClick={copyLinkHandler}>
              {t('Copy link')}
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box width="50%">
            <Button fullWidth gaLabel="Done - Get link" onClick={onClose} variant="primary">
              {t('Done')}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <Snackbar
        className="ayo-portfolio-get-link-dialog"
        onClose={() => setIsSnackbarOpen(false)}
        open={isSnackbarOpen}
        text={t('Link copied')}
        variant="success"
      />
    </Box>
  );
};

PortfolioGetLinkDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  isDialogOpen: PropTypes.bool,
  studentId: PropTypes.string.isRequired,
  portfolioId: PropTypes.string.isRequired,
};

PortfolioGetLinkDialog.defaultProps = {
  isDialogOpen: false,
};

export default PortfolioGetLinkDialog;
