import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Link as RouterLink, useRouteMatch } from 'react-router-dom';

import { Button, Card, Typography } from '../../../../atoms';
import { DeleteItemWrapper, InitiativeEmptyStateBlock } from '../../../../moleculas';
import { LeadershipAttributesGraph } from '../../..';
import { UserContext } from '../../../../../context';
import {
  converterBooleanString,
  PortfolioEntitiesData,
  PortfolioItemsToAddData,
} from '../../../../../utils';
import { portfolioSectionConfig } from '../../../../../constants/propTypes';
import { PortfolioSectionSettings, PortfolioSectionTypes } from '../../../../../constants/enums';
import { leadershipRoute, trainAyoRoute } from '../../../../../constants/routes';
import { ReactComponent as EmptyLAIllustration } from '../../../../../resources/images/no_la_illustration.svg';
import { ReactComponent as VectorIcon } from '../../../../../resources/icons/vector.svg';
import PortfolioSection from '../../components/portfolio-section/PortfolioSection';

const PortfolioLeadershipAttributesSection = ({
  config,
  publishModeView,
  sectionIndex,
  isLastSection,
}) => {
  const [portfolioAttributes, setPortfolioAttributes] = useState([]);
  const { t } = useTranslation();
  const match = useRouteMatch();
  const { state: userState } = useContext(UserContext);
  const studentId = match.params.studentId || userState?.profile.id;

  const {
    addPortfolioItemHandler,
    deletePortfolioItemHandler,
    hasData,
    isEditMode,
    isVisible,
    maxItemsToDisplay,
    metaData,
    reflectionHandler,
    sectionData,
    updatePortfolioHandler,
    onUpdatePositionHandler,
  } = config;
  const { entities, isInitialState, reflection, sectionSettings } = sectionData;
  const isGraphVisible = useMemo(() => {
    const graphSettings = sectionSettings.find(
      ({ name }) => name === PortfolioSectionSettings.DISPLAY_MATURITY_GRAPH.type,
    );
    return converterBooleanString(graphSettings.value ?? graphSettings.defaultValue, true);
  }, [sectionSettings]);

  useEffect(() => {
    setPortfolioAttributes(
      PortfolioEntitiesData[PortfolioSectionTypes.PORTFOLIO__LEADERSHIP_ATTRIBUTES](
        entities,
        metaData,
        'key',
      ),
    );
  }, [entities, isInitialState, maxItemsToDisplay, metaData]);

  const attributesToAdd = useMemo(
    () =>
      PortfolioItemsToAddData[PortfolioSectionTypes.PORTFOLIO__LEADERSHIP_ATTRIBUTES](
        entities,
        metaData,
        'id',
      ).map(({ id, key }) => ({ id: id.toString(), name: key })),
    [entities, metaData],
  );

  const onGraphRemove = useCallback(() => {
    const graphSettingsIndex = sectionSettings.findIndex(
      ({ name }) => PortfolioSectionSettings.DISPLAY_MATURITY_GRAPH.type === name,
    );
    updatePortfolioHandler({
      ...sectionData,
      entities: portfolioAttributes,
      isInitialState: false,
      sectionSettings: Object.assign([...sectionSettings], {
        [graphSettingsIndex]: {
          ...sectionSettings[graphSettingsIndex],
          value: converterBooleanString(false),
        },
      }),
    });
  }, [portfolioAttributes, sectionData, sectionSettings, updatePortfolioHandler]);

  if (!isVisible) {
    return null;
  }

  return (
    <PortfolioSection
      body={
        <Box>
          {!!portfolioAttributes.length && (
            <Box mt={5}>
              <Box mb={3}>
                <Typography component="h3" variant="subtitle2">
                  {t('Leadership skills')}
                </Typography>
              </Box>
              <Grid
                className="ayo-portfolio__leadership-attributes-section__images-block"
                container
                spacing={3}
              >
                {portfolioAttributes.map((attribute) => {
                  const Illustration = attribute.landingBlockConfig?.imageDesktop;
                  return (
                    attribute && (
                      <Grid key={attribute.key} item md={2} sm={4}>
                        <DeleteItemWrapper
                          disabled={!isEditMode}
                          gaLabel="Remove leadership attribute"
                          mainContent={
                            <Card
                              key={attribute.key}
                              className="ayo-portfolio__leadership-attributes-section__card"
                              mainContent={
                                <Box>
                                  {Object.keys(Illustration).length !== 0 && (
                                    <Illustration aria-label={attribute.alt} />
                                  )}
                                  <Typography
                                    className="ayo-portfolio__leadership-attributes-section__card__title"
                                    component="h4"
                                    variant="subtitle2"
                                  >
                                    {t(attribute.key)}
                                  </Typography>
                                </Box>
                              }
                            />
                          }
                          onDelete={() => {
                            deletePortfolioItemHandler(
                              portfolioAttributes,
                              'id',
                              attribute.id,
                              sectionData,
                            );
                          }}
                        />
                      </Grid>
                    )
                  );
                })}
              </Grid>
            </Box>
          )}
          {isGraphVisible && (
            <Box mt={5}>
              <Typography component="h3" variant="subtitle2">
                {t('Maturity graph')}
              </Typography>
              <Box className="ayo-portfolio__leadership-attributes-section__graph">
                <DeleteItemWrapper
                  disabled={!isEditMode}
                  gaLabel="Remove maturity graph"
                  mainContent={<LeadershipAttributesGraph readOnly />}
                  onDelete={onGraphRemove}
                />
              </Box>
            </Box>
          )}
          {!isEditMode && !publishModeView && (
            <Box mt={5}>
              <Button
                component={RouterLink}
                endIcon={<VectorIcon />}
                gaLabel="Contribute in Train AYO"
                to={`${trainAyoRoute}/${studentId}${leadershipRoute}`}
              >
                {t('Contribute in Train AYO')}
              </Button>
            </Box>
          )}
        </Box>
      }
      className={`${classNames({
        'ayo-portfolio__leadership-attributes-section': hasData,
      })}`}
      emptyState={
        !hasData ? (
          <InitiativeEmptyStateBlock
            body={
              isEditMode
                ? t(
                    'But you can start working on this now! Ask your educators to assess your attributes in Train AYO',
                  )
                : t('But you can start working on this now!')
            }
            buttonContent={t('Contribute in Train AYO')}
            buttonGaLabel="Contribute in Train AYO - LA"
            buttonLink={`${trainAyoRoute}/${studentId}${leadershipRoute}`}
            illustration={<EmptyLAIllustration />}
            showNavigationButton
            title={t('No Leadership Attributes assessed so far')}
          />
        ) : null
      }
      isEditMode={isEditMode}
      isLastSection={isLastSection}
      itemsToAdd={attributesToAdd}
      onAddItems={(itemsToAdd, settings) =>
        addPortfolioItemHandler(metaData, itemsToAdd, 'id', sectionData, settings)
      }
      onChangeReflection={reflectionHandler}
      onUpdatePositionHandler={onUpdatePositionHandler}
      reflection={reflection}
      sectionIndex={sectionIndex}
      sectionKey={PortfolioSectionTypes.PORTFOLIO__LEADERSHIP_ATTRIBUTES}
      settings={sectionSettings.filter(
        ({ name }) => name === PortfolioSectionSettings.DISPLAY_MATURITY_GRAPH.type,
      )}
      showActionsMenu={hasData && isEditMode}
      showAddButton={(attributesToAdd?.length || !isGraphVisible) && isEditMode}
      showAYOChip
      title={t('Leadership Attributes')}
    />
  );
};

PortfolioLeadershipAttributesSection.propTypes = {
  config: portfolioSectionConfig(PropTypes.instanceOf(Object)),
  publishModeView: PropTypes.bool,
  sectionIndex: PropTypes.number.isRequired,
  isLastSection: PropTypes.number.isRequired,
};

PortfolioLeadershipAttributesSection.defaultProps = {
  config: {},
  publishModeView: false,
};

export default PortfolioLeadershipAttributesSection;
