import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Slider } from '../../../../../atoms';
import { ReactComponent as ExtremelyHardIcon } from '../../../../../../resources/icons/extremely_hard.svg';
import { ReactComponent as HardIcon } from '../../../../../../resources/icons/hard.svg';
import { ReactComponent as NeutralIcon } from '../../../../../../resources/icons/neutral.svg';
import { ReactComponent as EasyIcon } from '../../../../../../resources/icons/easy.svg';
import { ReactComponent as ExtremelyEasyIcon } from '../../../../../../resources/icons/extremely_easy.svg';

const ExtremelyHardIconCmp = ({ className, ...rest }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <ExtremelyHardIcon {...rest} className={classnames(className, 'thumb-0')} />
);

ExtremelyHardIconCmp.propTypes = {
  className: PropTypes.string,
};

ExtremelyHardIconCmp.defaultProps = {
  className: '',
};

const ExtremelyEasyIconCmp = ({ className, ...rest }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <ExtremelyEasyIcon {...rest} className={classnames(className, 'thumb-last')} />
);

ExtremelyEasyIconCmp.propTypes = {
  className: PropTypes.string,
};

ExtremelyEasyIconCmp.defaultProps = {
  className: '',
};

const ScaleRatingSlider = ({ onChange }) => {
  const { t } = useTranslation();

  const sliderMarks = useMemo(
    () => [
      { value: -2, label: t('Extremely hard') },
      { value: -1, label: t('Hard') },
      { value: 0, label: t('Neutral') },
      { value: 1, label: t('Easy') },
      { value: 2, label: t('Extremely easy') },
    ],
    [t],
  );

  return (
    <Slider
      ariaMarks={sliderMarks}
      defaultValue={0}
      marks={sliderMarks}
      max={2}
      min={-2}
      onChange={onChange}
      ThumbComponents={{
        '-2': ExtremelyHardIconCmp,
        '-1': HardIcon,
        0: NeutralIcon,
        1: EasyIcon,
        2: ExtremelyEasyIconCmp,
      }}
    />
  );
};

ScaleRatingSlider.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default ScaleRatingSlider;
