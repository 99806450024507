import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';

import { Typography } from '../../../../atoms';
import { Carousel, TimelineChip } from '../../../../moleculas';
import EndorsementCard from '../endorsement-card/EndorsementCard';
import { getSortedAndTrimmedEndorsements } from '../../../../../constants/endorsements';
import { endorsementsSliderResponsiveConfig } from '../../../../../constants/carouselSliderConfigs';

const MAX_INTERESTED_ENDORSEMENT_LENGTH = 5;

const MIN_LENGTH_FOR_SLIDER = 3;

const InterestedEndorsements = ({ endorsements, isStudent }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  const endorsementsToDisplay = useMemo(
    () =>
      getSortedAndTrimmedEndorsements(
        endorsements,
        'updatedDate',
        MAX_INTERESTED_ENDORSEMENT_LENGTH,
      ),
    [endorsements],
  );

  return (
    <Box className="ayo-endorsements">
      <Box mb={3}>
        <Typography variant="body2">
          {t(
            `${
              isStudent
                ? 'Items that I like, without enrolling yet'
                : 'Items liked, without enrolling yet'
            }`,
          )}
        </Typography>
      </Box>
      <Box>
        {endorsements?.length < MIN_LENGTH_FOR_SLIDER ? (
          <Box className="ayo-endorsements__grid-block__interested">
            <TimelineChip className="ayo-endorsements__label" title={t('Latest preference')} />
            <Grid
              container
              direction="row"
              justify="flex-start"
              spacing={isWidthUpSm ? 3 : 8}
              xs={12}
            >
              {endorsementsToDisplay.map((item, i) => (
                <>
                  <Grid
                    key={item.id}
                    className={`${classNames('ayo-endorsements__grid-item__interested', {
                      'last-item': i === endorsementsToDisplay.length - 1,
                    })}`}
                    item
                    md={4}
                    sm={4}
                    xs={12}
                  >
                    <EndorsementCard endorsement={item} isInterestedEndorsements isReadOnlyMode />
                  </Grid>
                </>
              ))}
            </Grid>
          </Box>
        ) : (
          <Box>
            <Carousel
              items={endorsementsToDisplay.map((item, index) => (
                <Box key={item?.id} mr={3}>
                  <Box aria-hidden={index !== 0} className="ayo-endorsements__label-wrapper">
                    {index === 0 && <TimelineChip title={t('Latest preference')} />}
                  </Box>
                  <EndorsementCard endorsement={item} isInterestedEndorsements isReadOnlyMode />
                </Box>
              ))}
              sliderResponsiveConfig={endorsementsSliderResponsiveConfig}
              variant="slider"
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

InterestedEndorsements.propTypes = {
  endorsements: PropTypes.instanceOf(Object).isRequired,
  isStudent: PropTypes.bool.isRequired,
};

export default InterestedEndorsements;
