/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { ChipDisplayModes } from '../../../../constants/enums';
import { ReactComponent as DeleteIcon } from '../../../../resources/icons/close_primary.svg';
import { ReactComponent as PlusIcon } from '../../../../resources/icons/plus.svg';
import Chip from '../chip/Chip';

const EditableChip = React.forwardRef(
  ({ autoFocusInner, mode, ariaLabel, emoji, label, onClick, onDelete, ...props }, ref) => {
    const chipRef = useRef();

    useEffect(() => {
      if (autoFocusInner) {
        const tabableElement = chipRef.current.querySelector('[tabindex="0"]');
        if (tabableElement) {
          tabableElement.focus();
        }
      }
    }, [autoFocusInner]);

    return (
      <Chip
        ref={(reference) => {
          if (ref) {
            if (typeof ref === 'function') {
              ref(reference);
            } else {
              // eslint-disable-next-line no-param-reassign
              ref.current = reference;
            }
          }
          chipRef.current = reference;
        }}
        ariaLabel={ariaLabel}
        deleteIcon={<DeleteIcon />}
        emoji={emoji}
        icon={mode === ChipDisplayModes.ADD && <PlusIcon />}
        isRtl={mode === ChipDisplayModes.ADD}
        label={label}
        onClick={onClick}
        onDelete={mode === ChipDisplayModes.DELETE ? onDelete : undefined}
        {...props}
      />
    );
  },
);

EditableChip.propTypes = {
  autoFocusInner: PropTypes.bool,
  mode: PropTypes.oneOf(Object.values(ChipDisplayModes)),
  ariaLabel: PropTypes.string,
  emoji: PropTypes.shape({ symbol: PropTypes.string, label: PropTypes.string }),
  label: PropTypes.string,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
};

EditableChip.defaultProps = {
  autoFocusInner: false,
  mode: null,
  ariaLabel: '',
  emoji: null,
  label: '',
  onClick: undefined,
  onDelete: undefined,
};

export default EditableChip;
