import React, { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { useRouteMatch } from 'react-router-dom';

import { Typography, Button, DynamicGrid } from '../../../atoms';
import { EditingButton, InitiativeEmptyStateBlock, NewFeatureHotspot } from '../../../moleculas';
import EvidenceOfGrowthAddEditDialog from '../evidence-of-growth-add-dialog/EvidenceOfGrowthAddEditDialog';
import EvidenceOfGrowthCard from '../evidence-of-growth-card/EvidenceOfGrowthCard';
import { UserContext } from '../../../../context';
import { focusNextItem } from '../../../../utils';
import { AttachmentsResourcesTypes, RolesMap } from '../../../../constants/enums';
import { attachmentsRoute, apiRoute } from '../../../../constants/routes';
import { evidencesConfigByType } from '../../../../constants/configs';
import { goalItem } from '../../../../constants/propTypes';
import { ReactComponent as AddIcon } from '../../../../resources/icons/add.svg';
import { ReactComponent as DownloadIcon } from '../../../../resources/icons/download.svg';
import { ReactComponent as ExpandIcon } from '../../../../resources/icons/chevron_down.svg';
import { NewFeaturesIdsMap } from '../../../../tours/common/NewFeaturesItemsProvider';
import useEvidenceHelpers from '../../../../utils/evidence-helpers/useEvidenceHelpers';
import { useStudentsService } from '../../../../services';

const evidenceLengthStep = 3;
const relatedGoal = (goals, relatedGoalId) =>
  goals?.find((goal) => goal.id === Number(relatedGoalId));
const relatedGoalsDetail = ({ id, leadershipAttributeKey, name, status }) => ({
  id,
  leadershipAttributeKey,
  name,
  status,
});

const EvidenceOfGrowthBlock = ({
  assessmentKey,
  evidenceType,
  filteredEvidenceByEntity,
  getChipLabelHandler,
  goals,
  headerLevel,
  hideEmptyState,
  isActionButtonsAvailable,
  isAddEvidenceAvailable,
  readOnlyMode,
  relatedEvidences,
  studentId,
  titleVariant,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const isWidthUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const match = useRouteMatch();
  const { state: userState } = useContext(UserContext);
  const isStudent = userState.profile.role === RolesMap.STUDENT;
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [currentEvidenceLength, setCurrentEvidencesLength] = useState(evidenceLengthStep);
  const { getStudentGoals } = useStudentsService();

  const [evidences, setEvidences] = useState([]);
  const [relatedGoals, setRelatedGoals] = useState(null);
  const [nextFocusCard, setNextFocusCard] = useState(null);
  const EvidenceEmptyStateIllustration =
    evidencesConfigByType[evidenceType.toLowerCase()]?.emptyStateImg;
  const titleByRole = isStudent ? 'Showing my growth' : 'Evidence of growth';
  const emptyStateTitle = t('No evidence of growth added so far');
  const title = readOnlyMode ? (!relatedEvidences?.length ? null : titleByRole) : titleByRole;
  const isDownloadAllAvailable = useCallback(() => {
    if (evidences.length) {
      return evidences.some((evidence) => evidence.attachments.length);
    }
    return false;
  }, [evidences]);

  const { goalId } = match.params;

  const showAddEvidenceDialog = () => {
    setIsAddDialogOpen(true);
  };
  const downloadLink = `${apiRoute}${attachmentsRoute}/owners/${studentId}?resourceType=${evidenceType}`;

  const { evidenceChangeHandler } = useEvidenceHelpers(studentId, evidenceType);

  useEffect(() => {
    if (assessmentKey) {
      setEvidences(
        relatedEvidences.filter(
          (evidence) =>
            evidence.evidenceResourcesByType.LEADERSHIP_ATTRIBUTE.parentResourceId ===
            assessmentKey,
        ),
      );
    } else setEvidences(relatedEvidences);
  }, [assessmentKey, relatedEvidences]);

  useEffect(() => {
    if (nextFocusCard) {
      focusNextItem(nextFocusCard, relatedEvidences.length, '.ayo-evidence-of-growth');
    }
  }, [nextFocusCard, relatedEvidences]);

  useEffect(() => {
    if (!goals) {
      getStudentGoals(studentId, true).then((studentGoals) => {
        setRelatedGoals(studentGoals);
      });
    } else {
      setRelatedGoals(goals);
    }
  }, [getStudentGoals, goals, studentId]);

  return (
    <Box>
      {title && (
        <Box
          alignItems="center"
          display="flex"
          flexWrap="wrap"
          justifyContent="space-between"
          pb={3}
        >
          <Box alignItems="center" display="flex">
            <Typography component={`h${headerLevel}`} variant={titleVariant}>
              {t(title)}
            </Typography>
            <NewFeatureHotspot id={NewFeaturesIdsMap.EVIDENCES} isClickable />
          </Box>
          {!readOnlyMode && isDownloadAllAvailable() && (
            <Button
              component="a"
              download
              endIcon={<DownloadIcon width={24} />}
              gaLabel="Download all"
              href={
                filteredEvidenceByEntity
                  ? `${downloadLink}&resourceKey=${filteredEvidenceByEntity}`
                  : `${downloadLink}`
              }
            >
              {t('Download all')}
            </Button>
          )}
        </Box>
      )}
      {evidences.length ? (
        <DynamicGrid
          columnsCount={isWidthUpMd ? 3 : isWidthUpSm ? 2 : 1}
          gridItems={evidences.slice(0, currentEvidenceLength).map((evidence) => {
            const evidenceRelatedGoal = relatedGoal(
              relatedGoals,
              evidence.evidenceResourcesByType[AttachmentsResourcesTypes.GOAL]?.parentResourceId,
            );
            return (
              <EvidenceOfGrowthCard
                key={`${evidence.id}`}
                actionSteps={evidenceRelatedGoal?.milestones}
                assessmentKey={assessmentKey}
                chipLabel={getChipLabelHandler(evidence)}
                evidenceOfGrowth={evidence}
                evidenceType={evidenceType}
                goalDetails={evidenceRelatedGoal && relatedGoalsDetail(evidenceRelatedGoal)}
                headerLevel={headerLevel + 1}
                isActionButtonsAvailable={isActionButtonsAvailable}
                readOnlyMode={readOnlyMode}
                studentId={studentId}
              />
            );
          })}
          isHorizontalOrder={!readOnlyMode}
        />
      ) : hideEmptyState ? (
        <Typography variant="body2">{`${emptyStateTitle}.`}</Typography>
      ) : (
        <InitiativeEmptyStateBlock
          body={t(
            isStudent ? evidencesConfigByType[evidenceType.toLowerCase()]?.emptyStateText : null,
          )}
          illustration={
            <EvidenceEmptyStateIllustration
              aria-label={t(evidencesConfigByType[evidenceType.toLowerCase()]?.emptyStateAlt)}
              role="img"
            />
          }
          newFeaturesTour={
            title ? null : <NewFeatureHotspot id={NewFeaturesIdsMap.EVIDENCES} isClickable />
          }
          title={emptyStateTitle}
        />
      )}
      <Box alignItems="center" display="flex" justifyContent="space-between" mt={3}>
        <Box>
          {isStudent && isAddEvidenceAvailable && (
            <Button
              endIcon={<AddIcon />}
              gaLabel="Add an evidence"
              onClick={showAddEvidenceDialog}
              variant="text"
            >
              {t('Add evidence')}
            </Button>
          )}
        </Box>
        {currentEvidenceLength < evidences.length && (
          <Box>
            <EditingButton
              gaLabel="Show more"
              icon={<ExpandIcon />}
              iconPosition="end"
              onClick={() => {
                setNextFocusCard(currentEvidenceLength);
                setCurrentEvidencesLength(currentEvidenceLength + evidenceLengthStep);
              }}
              text={t('Show more')}
            />
          </Box>
        )}
      </Box>
      <EvidenceOfGrowthAddEditDialog
        actionSteps={goalId && relatedGoal(goals, goalId)?.milestones}
        assessmentKey={assessmentKey || relatedGoal(goals, goalId)?.leadershipAttributeKey}
        className="ayo-evidence-of-growth"
        evidenceChangeHandler={evidenceChangeHandler}
        goalDetails={goalId && relatedGoal(goals, goalId)}
        onClose={() => setIsAddDialogOpen(false)}
        open={isAddDialogOpen}
        title={t('Add evidence of growth')}
      />
    </Box>
  );
};

EvidenceOfGrowthBlock.propTypes = {
  assessmentKey: PropTypes.string,
  evidenceType: PropTypes.string,
  filteredEvidenceByEntity: PropTypes.string,
  getChipLabelHandler: PropTypes.func,
  goals: PropTypes.arrayOf(goalItem),
  headerLevel: PropTypes.number,
  hideEmptyState: PropTypes.bool,
  isActionButtonsAvailable: PropTypes.bool,
  isAddEvidenceAvailable: PropTypes.bool,
  readOnlyMode: PropTypes.bool,
  relatedEvidences: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  studentId: PropTypes.number,
  titleVariant: PropTypes.string,
};

EvidenceOfGrowthBlock.defaultProps = {
  assessmentKey: null,
  evidenceType: '',
  filteredEvidenceByEntity: null,
  getChipLabelHandler: null,
  goals: null,
  headerLevel: 2,
  hideEmptyState: false,
  isActionButtonsAvailable: false,
  isAddEvidenceAvailable: false,
  readOnlyMode: false,
  relatedEvidences: [],
  studentId: null,
  titleVariant: 'subtitle1',
};

export default EvidenceOfGrowthBlock;
