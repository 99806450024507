export const getQuestionnaireTemplateStringRE = () => new RegExp(/\$.*?\$/, 'g');

export const emailAllowedValidationRE = /^([-\p{L}0-9@_.' ])+$/iu;
export const emailAllowedFilterRE = /([-\p{L}0-9@_.' ])+/giu;

export const emailRE = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

export const interestAllowedValidationRE = /^([-\p{L}&,() ])+$/iu;
export const interestAllowedFilterRE = /([-\p{L}&,() ])+/giu;
export const teksAllowedFilterRE = /([-\p{L}0-9_,.:;() '])+/giu;
export const lastSymbolDotRE = /\.$/;

export const regexpEscapeCharactersRE = /[.*+?^$&{}()|[\]\\]/g;

export const loginAllowedFilterRE = /([-\p{L}0-9@_' ])+/giu;
export const urlAllowedValidationRE = /^(https):\/\/[-a-zA-Z0-9@:%_+~#=.]+\.[a-zA-Z0-9()]{2,6}\b/;

export const digitsOnly = /^\d+$/;

export const shortLinkRegex = /^[a-zA-Z0-9_-]+\.[a-zA-Z]{2,6}$/;

export const getNumberInputDigitsRE = (digitsNumber) =>
  new RegExp(`^(?:[1-9][0-9]{0,${digitsNumber - 1}})$`);
