import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import AptitudeCardBase from '../aptitude-card-base/AptitudeCardBase';
import { Aptitudes, AptitudesInfo } from '../../../../constants/enums';
import { AptitudesColorIllustrations } from '../../../../constants/aptitudes';

const AptitudeInfoCard = ({ aptitude, className, titleComponent }) => {
  const { t } = useTranslation();
  return (
    <AptitudeCardBase
      className={className}
      description={t(AptitudesInfo[aptitude].description)}
      IllustrationComponent={AptitudesColorIllustrations[aptitude].illustration}
      name={t(AptitudesInfo[aptitude].name)}
      titleComponent={titleComponent}
    />
  );
};

AptitudeInfoCard.propTypes = {
  aptitude: PropTypes.oneOf(Object.values(Aptitudes)).isRequired,
  className: PropTypes.string,
  titleComponent: PropTypes.string,
};

AptitudeInfoCard.defaultProps = {
  className: '',
  titleComponent: 'h3',
};

export default AptitudeInfoCard;
