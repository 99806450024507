import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Toolbar, Container, Box, Grid, useTheme, useMediaQuery } from '@mui/material';

import { Typography, Link } from '../../atoms';
import { termsConditionsRoute, privacyPolicyRoute } from '../../../constants/routes';
import { LinkWithSnackbar } from '../../moleculas';

const DataProcessingFooter = ({ disableTranslation }) => {
  const { t: translation } = useTranslation();
  const theme = useTheme();
  const isWidthUpMd = useMediaQuery(theme.breakpoints.up('md'));

  const t = useMemo(
    () => (disableTranslation ? (text) => text : translation),
    [disableTranslation, translation],
  );

  const snackbarBody = (
    <Box>
      <Typography paragraph variant="caption">
        {t(
          'As AYO uses third party services (ClassLink, Skyward) to authenticate its users, we store the username (usually email address) of user who logs into AYO to check the role and permissions of the user',
        )}{' '}
        {t(
          'This information is being temporary stored and is removed after first login, unless user agreed to AYO',
        )}{' '}
        <Link component={RouterLink} gaLabel="Terms & Conditions" to={termsConditionsRoute}>
          {t('Terms & Conditions')}
        </Link>{' '}
        {t('and')}{' '}
        <Link component={RouterLink} gaLabel="Privacy Policy" to={privacyPolicyRoute}>
          {t('Privacy Policy')}
        </Link>
      </Typography>
      <Typography variant="caption">
        {t('Please read')}{' '}
        <Link component={RouterLink} gaLabel="Terms & Conditions" to={termsConditionsRoute}>
          {t('Terms & Conditions')}
        </Link>{' '}
        {t('and')}{' '}
        <Link component={RouterLink} gaLabel="Privacy Policy" to={privacyPolicyRoute}>
          {t('Privacy Policy')}
        </Link>{' '}
        {t('for more details')}.
      </Typography>
    </Box>
  );

  return (
    <Toolbar className="ayo-bottom-bar ayo-data-processing--toolbar" disableGutters>
      <Container>
        <Box py={isWidthUpMd ? 2.5 : 2}>
          <Grid alignItems="center" container>
            <Box>
              <Typography align="center" display="inline" variant="body3">
                {t('By proceeding with login you allow AYO to store your username')}
              </Typography>{' '}
              <LinkWithSnackbar
                className="small"
                linkAnchor="Here is why we do it"
                snackbarBody={snackbarBody}
              />
            </Box>
          </Grid>
        </Box>
      </Container>
    </Toolbar>
  );
};

DataProcessingFooter.propTypes = {
  disableTranslation: PropTypes.bool,
};

DataProcessingFooter.defaultProps = {
  disableTranslation: false,
};

export default DataProcessingFooter;
