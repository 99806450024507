import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { Button } from '../../../../../atoms';
import { useClubHub } from '../../../../../../hooks';
import { ClubsStatuses } from '../../../../../../constants/club-hub';
import { clubHubRoute, managementRoute } from '../../../../../../constants/routes';
import ClubsDeactivationDialog from '../../../club-hub-components/clubs-deactivation-dialog/ClubsDeactivationDialog';

const ClubModeratorActions = ({ clubId, clubName, status }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  const history = useHistory();

  const { clubStatusChangeHandler } = useClubHub();

  const [isDeactivationDialogOpen, setIsDeactivationDialogOpen] = useState(false);
  const [deactivationDialogStatus, setDeactivationDialogStatus] = useState(null);

  const statusChangeHandler = useCallback(() => {
    history.push(`${clubHubRoute}${managementRoute}`);
  }, [history]);

  return (
    <Box className="ayo-club-hub-details__moderator-actions" mt={5} p={isWidthUpSm ? 5 : 3}>
      <Box
        display="flex"
        flexDirection={isWidthUpSm ? 'row' : 'column'}
        justifyContent="space-between"
      >
        <Button
          gaLabel="Make active"
          onClick={() =>
            clubStatusChangeHandler(
              clubId,
              clubName,
              null,
              ClubsStatuses.ACTIVE,
              statusChangeHandler,
            )
          }
          variant="primary"
        >
          {t('Make active')}
        </Button>
        {status === ClubsStatuses.PENDING && (
          <Box mt={isWidthUpSm ? 0 : 2}>
            <Button
              className="ayo-club-hub-details__moderator-actions__decline"
              gaLabel="Decline"
              onClick={() => {
                setDeactivationDialogStatus(ClubsStatuses.DECLINED);
                setIsDeactivationDialogOpen(true);
              }}
            >
              {t('Decline')}
            </Button>
          </Box>
        )}
      </Box>
      {deactivationDialogStatus && (
        <ClubsDeactivationDialog
          clubName={clubName}
          isOpen={isDeactivationDialogOpen}
          onClose={() => {
            setIsDeactivationDialogOpen(false);
            setDeactivationDialogStatus(null);
          }}
          onSubmit={(reason, newStatus) =>
            clubStatusChangeHandler(clubId, clubName, reason, newStatus, statusChangeHandler)
          }
          status={deactivationDialogStatus}
        />
      )}
    </Box>
  );
};

ClubModeratorActions.propTypes = {
  clubId: PropTypes.number.isRequired,
  clubName: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};
export default ClubModeratorActions;
