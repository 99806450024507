export const focusNextItem = (nextItemIdx, itemsTotalLength, itemsSelector) => {
  setTimeout(() => {
    const newFocus =
      document.querySelectorAll(itemsSelector)[
        nextItemIdx < itemsTotalLength - 1 ? nextItemIdx : itemsTotalLength - 1
      ];
    if (newFocus) {
      newFocus.focus();
    }
  }, 0);
};

export const focusDialogCloseButton = () => {
  setTimeout(() => {
    document.querySelectorAll('.ayo-dialog__close-button')[0]?.focus();
  }, 0);
};
