import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const Hotspot = ({ className, dark, onClick, withAnimation, label }) => {
  const WrapperComponent = onClick ? 'button' : 'div';
  return (
    <WrapperComponent
      aria-label={label}
      className={classnames('ayo-hotspot', className, {
        clickable: onClick,
        pulsating: withAnimation,
      })}
      onClick={onClick}
      type={onClick ? 'button' : undefined}
    >
      <span className="ayo-hotspot--inner" />
      <span className={classnames('ayo-hotspot--outer', { dark })} />
    </WrapperComponent>
  );
};

Hotspot.propTypes = {
  className: PropTypes.string,
  dark: PropTypes.bool,
  onClick: PropTypes.func,
  withAnimation: PropTypes.bool,
  label: PropTypes.string,
};

Hotspot.defaultProps = {
  className: '',
  dark: false,
  onClick: null,
  withAnimation: false,
  label: '',
};

export default Hotspot;
