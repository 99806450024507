import React from 'react';
import PropTypes from 'prop-types';

import { HighlightsInitiatives, StudentFiltersSpecialValues } from '../../../../constants/enums';
import TopFiltersList from '../components/TopFiltersList/TopFiltersList';

const AptitudesFilters = ({ menuFilters, setMenuFilters, stats, totalStudentsCount }) => (
  <TopFiltersList
    checkAllName="All aptitudes"
    columnsNumber={2}
    initiative={HighlightsInitiatives.APTITUDES}
    mainEntriesSorting={([aName], [bName]) => aName.localeCompare(bName)}
    menuFilters={menuFilters}
    pendingKey={StudentFiltersSpecialValues.NOT_DISCOVERED}
    setMenuFilters={setMenuFilters}
    stats={stats}
    totalStudentsCount={totalStudentsCount}
    withAllButton
  />
);

AptitudesFilters.propTypes = {
  menuFilters: PropTypes.instanceOf(Object).isRequired,
  setMenuFilters: PropTypes.func.isRequired,
  stats: PropTypes.instanceOf(Object).isRequired,
  totalStudentsCount: PropTypes.number.isRequired,
};

export default AptitudesFilters;
