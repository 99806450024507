import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { Typography } from '../../atoms';
import { Carousel, EditingButton, FileListItem, NewFeatureHotspot } from '../../moleculas';
import { byteToMegaByteCoeff } from '../../../constants/values';
import { fileFormatStringBuilder, getFileErrors } from '../../../utils';
import { NewFeaturesIdsMap } from '../../../tours/common/NewFeaturesItemsProvider';
import { ReactComponent as AttachIcon } from '../../../resources/icons/attach.svg';

const FileUpload = ({
  allowedExtensions,
  FileThumbnail,
  FileThumbnailProps,
  focusableRef,
  label,
  maxAttachmentsAllowed,
  maxFileSize,
  onFileAdd,
  onFileChange,
  onFileRemove,
  sliderSettings,
  sliderResponsiveConfig,
  uploadedFiles,
}) => {
  const { t } = useTranslation();
  const fileInputRef = useRef();
  const [filesAdded, setFilesAdded] = useState([]);
  const [editingFileName, setEditingFileName] = useState(null);

  const handleRemoveButtonClick = useCallback(
    (item) => {
      setFilesAdded((state) => [...state.filter((x) => x.file !== item.file)]);
      if (!item.errors.length) {
        onFileRemove(item.file);
      }
      if (focusableRef && focusableRef.current) {
        focusableRef.current.focus();
      }
    },
    [focusableRef, onFileRemove],
  );

  const handleInputChange = useCallback(
    (e) => {
      const { files } = e.target;
      if (files.length) {
        let validatedFiles = [];

        const limit = maxAttachmentsAllowed - filesAdded.filter((x) => !x.errors.length).length;

        if (editingFileName) {
          const file = files[0];
          const errors = getFileErrors(file, { allowedExtensions, maxFileSize }, t);

          if (!errors.length) {
            onFileAdd(file);
          }

          setFilesAdded((prevState) => [
            ...prevState.filter((item) => item.file.name !== editingFileName),
            { file, errors },
          ]);
          setEditingFileName(null);
        } else {
          Array.prototype.forEach.call(files, (file) => {
            const errors = getFileErrors(file, { allowedExtensions, maxFileSize }, t);
            if (
              (uploadedFiles
                ? !filesAdded.some((x) => x.file.name === file.name)
                : !filesAdded.some(
                    (x) => x.file.name === file.name && x.file.size === file.size,
                  )) &&
              validatedFiles.length < limit
            ) {
              if (!errors.length) {
                onFileAdd(file);
              }
              validatedFiles = [...validatedFiles, { file, errors }];
            }
          });
          const modifiedFilesAdded = [];
          filesAdded.forEach((el) =>
            el.errors.length && validatedFiles.length
              ? modifiedFilesAdded.unshift(validatedFiles.shift())
              : modifiedFilesAdded.push(el),
          );
          setFilesAdded([...validatedFiles, ...modifiedFilesAdded]);
        }

        if (focusableRef && focusableRef.current) {
          focusableRef.current.focus();
        }
        fileInputRef.current.value = null;
      }
    },
    [
      allowedExtensions,
      editingFileName,
      filesAdded,
      focusableRef,
      maxAttachmentsAllowed,
      maxFileSize,
      onFileAdd,
      t,
      uploadedFiles,
    ],
  );

  useEffect(() => {
    if (uploadedFiles) {
      setFilesAdded(uploadedFiles);
    }
  }, [uploadedFiles]);

  const carouselFileNodes = useMemo(() => {
    if (sliderSettings) {
      const noErrorItems = [];
      const errorItems = [];
      filesAdded.forEach((file) =>
        file.errors.length ? errorItems.push(file) : noErrorItems.push(file),
      );
      const images = noErrorItems.map(
        (item, index) =>
          FileThumbnail && (
            <FileThumbnail
              key={item.file.name}
              index={index}
              item={item}
              onRemove={handleRemoveButtonClick}
              withMultipleImages={noErrorItems.length > 1}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...FileThumbnailProps}
            />
          ),
      );
      const errors = errorItems.map((error) => (
        <FileListItem key={error.file.name} item={error} onRemove={handleRemoveButtonClick} />
      ));
      return {
        images,
        errors,
      };
    }
    return null;
  }, [filesAdded, FileThumbnail, handleRemoveButtonClick, sliderSettings, FileThumbnailProps]);

  return (
    <div className="ayo-fileupload">
      {carouselFileNodes ? (
        <>
          <Carousel
            buttonsVariant="text-primary"
            items={carouselFileNodes.images}
            sliderResponsiveConfig={sliderResponsiveConfig}
            sliderSettings={sliderSettings}
            variant="slider"
          />
          {carouselFileNodes.errors}
        </>
      ) : (
        filesAdded.map((item) =>
          FileThumbnail && !item.errors?.length ? (
            <FileThumbnail
              key={item.file.name}
              item={item}
              onChange={() => {
                fileInputRef.current.click();
                setEditingFileName(item.file.name);
                onFileChange();
              }}
              onRemove={handleRemoveButtonClick}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...FileThumbnailProps}
            />
          ) : (
            <FileListItem key={item.file.name} item={item} onRemove={handleRemoveButtonClick} />
          ),
        )
      )}
      {filesAdded.filter((x) => !x.errors.length).length < maxAttachmentsAllowed && (
        <Box display="flex">
          <EditingButton
            className="ayo-fileupload__add-btn"
            gaLabel="File Upload - Attach"
            icon={<AttachIcon />}
            iconPosition="end"
            onClick={() => {
              fileInputRef.current.click();
            }}
            text={t(label)}
          />
          <NewFeatureHotspot id={NewFeaturesIdsMap.SUPPORT_ATTACHMENTS} isClickable />
        </Box>
      )}
      {maxAttachmentsAllowed && maxFileSize && allowedExtensions.length && (
        <Box pt={0.5}>
          <Typography isLabel variant="body3">
            {maxAttachmentsAllowed === 1
              ? `${t('Max. 1 attachment up to')} ${maxFileSize / byteToMegaByteCoeff} MB ${t(
                  'in',
                )} ${fileFormatStringBuilder(allowedExtensions, t)} ${t('formats')}.`
              : `${t('Max')} ${maxAttachmentsAllowed} ${t('attachments')}
            ${maxFileSize / byteToMegaByteCoeff} ${t('MB each in')}
            ${fileFormatStringBuilder(allowedExtensions, t)} ${t('formats')}.`}
          </Typography>
        </Box>
      )}
      <input
        ref={fileInputRef}
        accept={allowedExtensions.length ? `.${allowedExtensions.join(', .')}` : '*'}
        multiple
        onChange={handleInputChange}
        style={{ display: 'none' }}
        type="file"
      />
    </div>
  );
};

FileUpload.propTypes = {
  allowedExtensions: PropTypes.arrayOf(PropTypes.string),
  FileThumbnail: PropTypes.elementType,
  FileThumbnailProps: PropTypes.instanceOf(Object),
  focusableRef: PropTypes.instanceOf(Object),
  label: PropTypes.string,
  maxAttachmentsAllowed: PropTypes.number,
  maxFileSize: PropTypes.number,
  onFileAdd: PropTypes.func,
  onFileRemove: PropTypes.func,
  onFileChange: PropTypes.func,
  uploadedFiles: PropTypes.arrayOf(
    PropTypes.shape({
      file: {
        name: PropTypes.string,
        id: PropTypes.number,
      },
      errors: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
  sliderSettings: PropTypes.shape({
    infinite: PropTypes.bool,
    showDots: PropTypes.bool,
    renderDotsOutside: PropTypes.bool,
  }),
  sliderResponsiveConfig: PropTypes.instanceOf(Object),
};

FileUpload.defaultProps = {
  allowedExtensions: [],
  FileThumbnail: null,
  FileThumbnailProps: null,
  focusableRef: null,
  label: 'Add an attachment',
  maxAttachmentsAllowed: Number.MAX_VALUE,
  maxFileSize: Number.MAX_VALUE,
  onFileAdd: () => {},
  onFileRemove: () => {},
  onFileChange: () => {},
  uploadedFiles: [],
  sliderSettings: null,
  sliderResponsiveConfig: null,
};

export default FileUpload;
