import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { Grid, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { Link, Typography } from '../../atoms';
import { privacyPolicyRoute, misdPhoneNumberRoute, rootRoute } from '../../../constants/routes';

const TermsConditionsContent = ({ fullWidth }) => {
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  const { t } = useTranslation();

  return (
    <Grid container item>
      <Grid item sm={fullWidth ? 12 : 7} xs={12}>
        <Box mb={3}>
          <Typography isLabel variant="body2">
            {t('Terms & Conditions last updated text')}
          </Typography>
        </Box>
        <Box mb={3}>
          <Typography variant="body2">
            {t('Please read these terms and conditions carefully before using Our Service')}
          </Typography>
        </Box>
        <Box mb={isWidthUpSm ? 7 : 4}>
          <Typography component="p" variant="subtitle2">
            {t(
              'BY ACCESSING THE AYO PLATFORM, YOU REPRESENT AND WARRANT THAT YOU HAVE READ AND UNDERSTAND THESE TERMS AND CONDITIONS AND AGREE TO COMPLY WITH AND BE BOUND BY THESE TERMS AND CONDITIONS',
            )}
          </Typography>
        </Box>
        <Box mb={isWidthUpSm ? 5 : 3}>
          <Typography component="h2" variant="h3">
            {t('Interpretation and Definitions')}
          </Typography>
        </Box>
        <Box mb={isWidthUpSm ? 2 : 1}>
          <Typography variant="subtitle1">{t('Interpretation')}</Typography>
        </Box>
        <Box mb={isWidthUpSm ? 4 : 2}>
          <Typography paragraph variant="body2">
            {t('Interpretation paragraph 1')}
          </Typography>
          <Typography variant="body2">{t('Interpretation paragraph 2')}</Typography>
        </Box>
        <Box mb={isWidthUpSm ? 2 : 1}>
          <Typography variant="subtitle1">{t('Definitions')}</Typography>
        </Box>
        <Box mb={isWidthUpSm ? 4 : 2}>
          <Typography paragraph variant="body2">
            {t('Definitions list header')}
          </Typography>
          <Typography variant="body2">
            <ul>
              <li>
                <Trans components={{ b: <b /> }} i18nKey="Affiliate meaning" />
              </li>
              <li>
                <Trans components={{ b: <b /> }} i18nKey="District meaning" />
              </li>
              <li>
                <Trans components={{ b: <b /> }} i18nKey="Country meaning" />
              </li>
              <li>
                <Trans components={{ b: <b /> }} i18nKey="Device meaning" />
              </li>
              <li>
                <Trans components={{ b: <b /> }} i18nKey="Service meaning" />
              </li>
              <li>
                <Trans
                  components={{ b: <b />, Link: <Link component={RouterLink} to={rootRoute} /> }}
                  i18nKey="AYO platform meaning"
                />
              </li>
              <li>
                <Trans components={{ b: <b /> }} i18nKey="Terms and Conditions meaning" />
              </li>
              <li>
                <Trans
                  components={{ b: <b /> }}
                  i18nKey="Third-party Social Media Service meaning"
                />
              </li>
              <li>
                <Trans components={{ b: <b /> }} i18nKey="You meaning" />
              </li>
            </ul>
          </Typography>
        </Box>
        <Box mb={isWidthUpSm ? 2 : 1}>
          <Typography variant="subtitle1">{t('Acknowledgment')}</Typography>
        </Box>
        <Box mb={isWidthUpSm ? 7 : 4}>
          <Typography paragraph variant="body2">
            {t('Acknowledgment paragraph 1')}
          </Typography>
          <Typography paragraph variant="body2">
            {t('Acknowledgment paragraph 2')}
          </Typography>
          <Typography paragraph variant="body2">
            {t('Acknowledgment paragraph 3')}
          </Typography>
          <Typography paragraph variant="body2">
            {t('Acknowledgment paragraph 4')}
          </Typography>
          <Typography paragraph variant="body2">
            <Trans
              components={{ Link: <Link component={RouterLink} to={privacyPolicyRoute} /> }}
              i18nKey="Acknowledgment paragraph 5"
            />
          </Typography>
          <Typography variant="body2">{t('Acknowledgment paragraph 6')}</Typography>
        </Box>
        <Box mb={isWidthUpSm ? 3 : 2}>
          <Typography component="h2" variant="h3">
            {t('Links to Other Websites')}
          </Typography>
        </Box>
        <Box mb={isWidthUpSm ? 7 : 4}>
          <Typography paragraph variant="body2">
            {t('Links to Other Websites paragraph 1')}
          </Typography>
          <Typography paragraph variant="body2">
            {t('Links to Other Websites paragraph 2')}
          </Typography>
          <Typography variant="body2">{t('Links to Other Websites paragraph 3')}</Typography>
        </Box>
        <Box mb={isWidthUpSm ? 3 : 2}>
          <Typography component="h2" variant="h3">
            {t('Termination')}
          </Typography>
        </Box>
        <Box mb={isWidthUpSm ? 7 : 4}>
          <Typography paragraph variant="body2">
            {t('Termination paragraph 1')}
          </Typography>
          <Typography variant="body2">{t('Termination paragraph 2')}</Typography>
        </Box>
        <Box mb={isWidthUpSm ? 3 : 2}>
          <Typography component="h2" variant="h3">
            {t('Limitation of Liability')}
          </Typography>
        </Box>
        <Box mb={isWidthUpSm ? 7 : 4}>
          <Typography paragraph variant="body2">
            {t('Limitation of Liability paragraph 1')}
          </Typography>
          <Typography paragraph variant="body2">
            {t('Limitation of Liability paragraph 2')}
          </Typography>
          <Typography paragraph variant="body2">
            {t('Limitation of Liability paragraph 3')}
          </Typography>
          <Typography variant="body2">{t('Limitation of Liability paragraph 4')}</Typography>
        </Box>
        <Box mb={isWidthUpSm ? 3 : 2}>
          <Typography component="h2" variant="h3">
            {t('AS IS and AS AVAILABLE Disclaimer')}
          </Typography>
        </Box>
        <Box mb={isWidthUpSm ? 7 : 4}>
          <Typography paragraph variant="body2">
            {t('AS IS and AS AVAILABLE Disclaimer paragraph 1')}
          </Typography>
          <Typography paragraph variant="body2">
            {t('AS IS and AS AVAILABLE Disclaimer paragraph 2')}
          </Typography>
          <Typography variant="body2">
            {t('AS IS and AS AVAILABLE Disclaimer paragraph 3')}
          </Typography>
        </Box>
        <Box mb={isWidthUpSm ? 3 : 2}>
          <Typography component="h2" variant="h3">
            {t('Governing Law')}
          </Typography>
        </Box>
        <Box mb={isWidthUpSm ? 7 : 4}>
          <Typography paragraph variant="body2">
            {t('Governing Law paragraph 1')}
          </Typography>
          <Typography paragraph variant="body2">
            {t('Governing Law paragraph 2')}
          </Typography>
          <Typography variant="body2">
            <ul>
              <li>
                <Trans components={{ b: <b /> }} i18nKey="HIPAA with tags" />
              </li>
              <li>
                <Trans components={{ b: <b /> }} i18nKey="IDEA with tags" />
              </li>
              <li>
                <Trans components={{ b: <b /> }} i18nKey="COPPA with tags" />
              </li>
              <li>
                <Trans components={{ b: <b /> }} i18nKey="CIPA with tags" />
              </li>
              <li>
                <Trans components={{ b: <b /> }} i18nKey="ADA with tags" />
              </li>
              <li>
                <Trans components={{ b: <b /> }} i18nKey="Section 508 with tags" />
              </li>
              <li>
                <Trans components={{ b: <b /> }} i18nKey="FERPA with tags" />
              </li>
              <li>
                <Trans components={{ b: <b /> }} i18nKey="PPRA with tags" />
              </li>
              <li>
                <Trans components={{ b: <b /> }} i18nKey="FL (LOCAL) with tags" />
              </li>
            </ul>
          </Typography>
        </Box>
        <Box mb={isWidthUpSm ? 3 : 2}>
          <Typography component="h2" variant="h3">
            {t('Disputes Resolution')}
          </Typography>
        </Box>
        <Box mb={isWidthUpSm ? 10 : 5}>
          <Typography variant="body2">{t('Disputes Resolution paragraph')}</Typography>
        </Box>
        <Box mb={isWidthUpSm ? 3 : 2}>
          <Typography component="h2" variant="h3">
            <Trans components={{ b: <b /> }} i18nKey="For the European Union (EU) Users" />
          </Typography>
        </Box>
        <Box mb={isWidthUpSm ? 7 : 4}>
          <Typography variant="body2">
            {t('For the European Union (EU) Users paragraph')}
          </Typography>
        </Box>
        <Box mb={isWidthUpSm ? 3 : 2}>
          <Typography component="h2" variant="h3">
            {t('The United States Legal Compliance')}
          </Typography>
        </Box>
        <Box mb={isWidthUpSm ? 7 : 4}>
          <Typography variant="body2">
            {t('The United States Legal Compliance paragraph')}
          </Typography>
        </Box>
        <Box mb={isWidthUpSm ? 5 : 3}>
          <Typography component="h2" variant="h3">
            {t('Severability and Waiver')}
          </Typography>
        </Box>
        <Box mb={isWidthUpSm ? 2 : 1}>
          <Typography variant="subtitle1">{t('Severability')}</Typography>
        </Box>
        <Box mb={isWidthUpSm ? 4 : 2}>
          <Typography variant="body2">{t('Severability paragraph')}</Typography>
        </Box>
        <Box mb={isWidthUpSm ? 2 : 1}>
          <Typography variant="subtitle1">{t('Waiver')}</Typography>
        </Box>
        <Box mb={isWidthUpSm ? 4 : 2}>
          <Typography variant="body2">{t('Waiver paragraph')}</Typography>
        </Box>
        <Box mb={isWidthUpSm ? 2 : 1}>
          <Typography variant="subtitle1">{t('Translation Interpretation')}</Typography>
        </Box>
        <Box mb={isWidthUpSm ? 4 : 2}>
          <Typography paragraph variant="body2">
            {t('Translation Interpretation paragraph 1')}
          </Typography>
          <Typography variant="body2">{t('Translation Interpretation paragraph 2')}</Typography>
        </Box>
        <Box mb={isWidthUpSm ? 2 : 1}>
          <Typography variant="subtitle1">{t('Changes to These Terms and Conditions')}</Typography>
        </Box>
        <Box mb={isWidthUpSm ? 4 : 2}>
          <Typography paragraph variant="body2">
            {t('Changes to These Terms and Conditions paragraph 1')}
          </Typography>
          <Typography variant="body2">
            {t('Changes to These Terms and Conditions paragraph 2')}
          </Typography>
        </Box>
        <Box mb={isWidthUpSm ? 2 : 1}>
          <Typography variant="subtitle1">{t('Contact Us')}</Typography>
        </Box>
        <Box mb={isWidthUpSm ? 20 : 10}>
          <Typography paragraph variant="body2">
            {t('Contact Us paragraph')}
          </Typography>
          <Typography variant="body2">
            <ul>
              <li>
                <Trans
                  components={{ b: <b />, Link: <Link href={misdPhoneNumberRoute} /> }}
                  i18nKey="By phone number"
                />
              </li>
              <li>{t('By mail 3819 Towne Crossing | Mesquite | TX 75150')}</li>
            </ul>
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

TermsConditionsContent.propTypes = {
  fullWidth: PropTypes.bool,
};

TermsConditionsContent.defaultProps = {
  fullWidth: false,
};

export default TermsConditionsContent;
