import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { InputsValidationErrors, InputsValidationRules } from '../../../../../../constants/enums';

const useAssignmentTitleState = (defaultTitle, titleText) => {
  const [title, setTitle] = useState(defaultTitle || '');
  const [error, setError] = useState('');

  const { t } = useTranslation();

  const validate = () => {
    let errorText;
    if (title.length > InputsValidationRules.MAX_INPUT_LENGTH) {
      errorText = InputsValidationErrors(t, InputsValidationRules.MAX_INPUT_LENGTH).MAX_ERROR_TEXT;
    }
    if (title.length < InputsValidationRules.MIN_INPUT_LENGTH) {
      errorText = InputsValidationErrors(t, InputsValidationRules.MIN_INPUT_LENGTH).MIN_ERROR_TEXT;
    }
    if (errorText) {
      setError(errorText);
      return [{ errorText, lvl3Label: titleText || t('Title') }];
    }
    return [];
  };

  const onChange = (value) => {
    setTitle(value);
    setError('');
  };

  return { state: title, onChange, validate, error };
};

export default useAssignmentTitleState;
