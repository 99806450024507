import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Container, Grid, useMediaQuery, useTheme } from '@mui/material';
import { Link as RouterLink, useHistory } from 'react-router-dom';

import { Button, Typography } from '../../../atoms';
import { NewFeatureHotspot, PopupActionsButtons } from '../../../moleculas';
import { LeaveDialog, PageWrapper } from '../../../organisms';
import { AppActions, AppContext } from '../../../../context';
import { useClubHubService } from '../../../../services';
import { useSnackbar } from '../../../../hooks';
import { NewFeaturesIdsMap } from '../../../../tours/common/NewFeaturesItemsProvider';
import {
  deepEqual,
  formatUrl,
  getClubFormValidation,
  isEmpty,
  transformFileUploadData,
} from '../../../../utils';
import { clubHubRoute } from '../../../../constants/routes';
import {
  ClubDotNotationKeys,
  ClubKeys,
  ClubRequirementsKeys,
  ClubTypes,
} from '../../../../constants/club-hub';
import { ReactComponent as CloseIcon } from '../../../../resources/icons/close_primary_simple.svg';
import ClubName from '../club-hub-components/club-name/ClubName';
import ClubDescription from '../club-hub-components/club-description/ClubDescription';
import ClubVisibility from '../club-hub-components/club-visibility/ClubVisibility';
import ClubLinks from '../club-hub-components/club-links/ClubLinks';
import ClubBanner from '../club-hub-components/club-banner/ClubBanner';
import ClubTagsDialog from '../club-hub-components/club-tags-dialog/ClubTagsDialog';

import ClubType from './components/club-type/ClubType';
import ClubCreateRequirements from './components/club-create-requirements/ClubCreateRequirements';

const clubEmptyData = {
  [ClubKeys.ATTACHMENTS]: [],
  [ClubKeys.CLUB_NAME]: '',
  [ClubKeys.DESCRIPTION]: '',
  [ClubKeys.LINKS]: [''],
  [ClubKeys.SCHOOLS]: [],
  [ClubKeys.TYPE]: ClubTypes.CLUB,
  [ClubKeys.REQUIREMENTS]: {
    [ClubRequirementsKeys.DESCRIPTION]: '',
    [ClubRequirementsKeys.IS_VISIBLE]: false,
    [ClubRequirementsKeys.LINKS]: [''],
  },
};

const transformClubLinks = (links) => links.filter((link) => link).map((link) => formatUrl(link));

const ClubHubCreatePage = () => {
  const { t } = useTranslation();

  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  const history = useHistory();

  const { state: appState, dispatch: dispatchAppState } = useContext(AppContext);

  const { getClubTags, postClub } = useClubHubService();
  const { setSnackBarStatus } = useSnackbar();

  const [clubData, setClubData] = useState(clubEmptyData);
  const [clubErrors, setClubErrors] = useState({});
  const [tagsData, setTagsData] = useState(null);
  const [isTagsDialogOpen, setIsTagsDialogOpen] = useState(false);

  const clubsDataChangeHandler = useCallback(
    (changes) => {
      const changesKeys = Object.keys(changes);

      setClubData((prevState) => ({ ...prevState, ...changes }));

      if (!isEmpty(clubErrors)) {
        setClubErrors((prevState) =>
          changesKeys.reduce(
            (acc, current) => ({
              ...acc,
              [current]: '',
            }),
            prevState,
          ),
        );
      }
    },
    [clubErrors],
  );

  const { resolver } = appState.onLogout;

  const onLeaveDialogHandler = useCallback(() => {
    if (resolver) {
      resolver(false);
    }
  }, [resolver]);

  const setIsDirty = useCallback(
    (value) => {
      dispatchAppState({ type: AppActions.SET_IS_DIRTY, data: value });
    },
    [dispatchAppState],
  );

  const handlePostClub = useCallback(
    (body) =>
      postClub(transformFileUploadData(body), ClubDotNotationKeys)
        .then(() => {
          setIsDirty(false);
          history.push(clubHubRoute);
          setSnackBarStatus({
            text: (
              <Trans
                components={{ b: <strong /> }}
                i18nKey="The club’s clubName request has been sent for approval."
                values={{ clubName: clubData[ClubKeys.CLUB_NAME] }}
              />
            ),
            type: 'success',
          });
        })
        .catch(() => {
          setSnackBarStatus({
            text: (
              <Trans
                components={{ b: <strong /> }}
                i18nKey="AYO couldn’t send your request for the club clubName. Please try once more."
                values={{ clubName: clubData[ClubKeys.CLUB_NAME] }}
              />
            ),
            type: 'error',
            action: (
              <Button autoFocus gaLabel="Retry" onClick={() => handlePostClub(body)}>
                {t('Retry')}
              </Button>
            ),
          });
        }),
    [clubData, history, postClub, setIsDirty, setSnackBarStatus, t],
  );

  const submitHandler = useCallback(
    (tags) => {
      const clubPostBody = {
        ...clubData,
        [ClubKeys.LINKS]: transformClubLinks(clubData[ClubKeys.LINKS]),
        [ClubKeys.REQUIREMENTS]: {
          ...clubData[ClubKeys.REQUIREMENTS],
          [ClubRequirementsKeys.LINKS]: transformClubLinks(
            clubData[ClubKeys.REQUIREMENTS][ClubRequirementsKeys.LINKS],
          ),
        },
      };
      const { isFormValid, validationErrors } = getClubFormValidation(clubPostBody, t);
      setClubErrors(validationErrors);
      if (isFormValid) {
        if (isTagsDialogOpen) {
          if (!isEmpty(tags)) clubPostBody[ClubKeys.TAGS] = tags;

          handlePostClub(clubPostBody);
          setIsTagsDialogOpen(false);
        } else {
          getClubTags(clubPostBody[ClubKeys.DESCRIPTION]).then((data) => {
            if (!isEmpty(data)) {
              setTagsData(data);
              setIsTagsDialogOpen(true);
            } else {
              handlePostClub(clubPostBody);
            }
          });
        }
      }
    },
    [clubData, getClubTags, handlePostClub, isTagsDialogOpen, t],
  );

  useEffect(() => {
    if (deepEqual(clubEmptyData, clubData)) {
      setIsDirty(false);
    } else {
      setIsDirty(true);
    }
  }, [clubData, setIsDirty]);

  return (
    <PageWrapper
      customFooter={<></>}
      customHeader={
        <Container>
          <Box mt={3}>
            <Button
              className="ayo-club-hub-create-page__back-button"
              component={RouterLink}
              disableElevation
              disableRipple
              gaLabel="Quit club creation"
              startIcon={<CloseIcon />}
              to={clubHubRoute}
            >
              {t('Quit club creation')}
            </Button>
          </Box>
        </Container>
      }
      mainElementClassName="ayo-club-hub-create-page"
      noi18n
      noNav
    >
      <Container>
        <Box alignItems="center" display="flex">
          <Typography component="h1" variant="h1">
            {t('New club creation')}
          </Typography>
          <NewFeatureHotspot
            id={NewFeaturesIdsMap.CLUB_HUB_TAGS}
            isClickable
            label={t('nft.club_tags.title')}
          />
        </Box>
        <Typography maxWidth={isWidthUpSm ? '45%' : '100%'} mt={1} variant="body2">
          {t('You can create and manage your own club in AYO!')}
        </Typography>
        <Typography mb={3} mt={5} variant="h2">
          {t('Banner')}
        </Typography>
        <Box>
          <ClubBanner onChange={clubsDataChangeHandler} value={clubData[ClubKeys.ATTACHMENTS]} />
        </Box>
        <Typography mb={3} mt={5} variant="h2">
          {t('Primary info')}
        </Typography>
        <Grid container item mb={isWidthUpSm ? 3 : 2} spacing={isWidthUpSm ? 3 : 2}>
          <Grid item sm={7} xs={12}>
            <Typography component="h3" mb={2} variant="subtitle2">{`${t(
              'Club name',
            )}*`}</Typography>
            <ClubName
              error={clubErrors[ClubKeys.CLUB_NAME]}
              label={t('Add a name for your club')}
              onChange={clubsDataChangeHandler}
              value={clubData[ClubKeys.CLUB_NAME]}
            />
          </Grid>
          <Grid item sm={5} xs={12}>
            <Typography component="h3" mb={2} variant="subtitle2">{`${t(
              'Club type',
            )}*`}</Typography>
            <ClubType clubType={clubData[ClubKeys.TYPE]} onChange={clubsDataChangeHandler} />
          </Grid>
        </Grid>
        <Box mb={isWidthUpSm ? 3 : 2}>
          <ClubVisibility
            error={clubErrors[ClubKeys.SCHOOLS]}
            onChange={clubsDataChangeHandler}
            schools={clubData[ClubKeys.SCHOOLS]}
          />
        </Box>
        <Grid container item spacing={isWidthUpSm ? 3 : 2}>
          <Grid item sm={7} xs={12}>
            <Box
              className="ayo-club-hub-create-page__description"
              pb={clubErrors[ClubKeys.DESCRIPTION] ? 4 : 3}
              pt={3}
              px={3}
            >
              <Typography component="h3" mb={2} variant="subtitle1">
                {`${t('Description')}*`}
              </Typography>
              <Typography mb={2} variant="body2">
                {t(
                  "Please describe the club's purpose, who might be interested in joining, what students will learn, and what activities the club will offer. Use at least 75 words.",
                )}
              </Typography>
              <ClubDescription
                error={clubErrors[ClubKeys.DESCRIPTION]}
                onChange={clubsDataChangeHandler}
                value={clubData[ClubKeys.DESCRIPTION]}
              />
            </Box>
            {isWidthUpSm && (
              <Box mt={3}>
                <ClubCreateRequirements
                  error={clubErrors[ClubKeys.REQUIREMENTS]?.[ClubRequirementsKeys.DESCRIPTION]}
                  onChange={clubsDataChangeHandler}
                  requirements={clubData[ClubKeys.REQUIREMENTS]}
                />
              </Box>
            )}
          </Grid>
          <Grid item sm={5} xs={12}>
            <Box className="ayo-club-hub-create-page__links" p={3}>
              <Typography component="h3" mb={3} variant="subtitle1">
                {t('Links & social media')}
              </Typography>
              <ClubLinks
                clubValueKey={ClubKeys.LINKS}
                gaLabel="Link & social media"
                links={clubData[ClubKeys.LINKS]}
                onChange={clubsDataChangeHandler}
              />
            </Box>
          </Grid>
        </Grid>
        {!isWidthUpSm && (
          <Box mt={2}>
            <ClubCreateRequirements
              error={clubErrors[ClubKeys.REQUIREMENTS]?.[ClubRequirementsKeys.DESCRIPTION]}
              onChange={clubsDataChangeHandler}
              requirements={clubData[ClubKeys.REQUIREMENTS]}
            />
          </Box>
        )}
        <Grid container item mb={8} mt={isWidthUpSm ? 10 : 5}>
          <Grid item lg={5} sm={7} xl={5} xs={12}>
            <PopupActionsButtons
              primaryButtonGaLabel="Send for approval"
              primaryButtonHandler={() => submitHandler()}
              primaryButtonText={t('Send for approval')}
              secondaryButtonGaLabel="Cancel"
              secondaryButtonHandler={() => history.push(clubHubRoute)}
              secondaryButtonText={t('Cancel')}
            />
          </Grid>
        </Grid>
        <ClubTagsDialog
          key={tagsData}
          isOpen={isTagsDialogOpen}
          onClose={() => setIsTagsDialogOpen(false)}
          onSave={submitHandler}
          tagsData={tagsData}
        />
        <LeaveDialog
          onPrimaryClick={onLeaveDialogHandler}
          primaryButtonTitle="Leave the page"
          secondaryButtonTitle="Cancel"
          shouldResetNextLocationRef
          text="AYO won’t save your progress if you leave."
          title="Are you sure you want to leave the club creation page?"
        />
      </Container>
    </PageWrapper>
  );
};

export default ClubHubCreatePage;
