import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, DialogContent } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';

import { Typography, Button, DialogTitle, DotIndicator } from '../../../atoms';
import { SideDialog, SortButton, TextBadge } from '../../../moleculas';
import { sortArrayByKey } from '../../../../utils';
import { useCommentsData, useNotificationsData } from '../../../../hooks';
import { commentsDialog } from '../../../../constants/routes';
import { NotificationSourcesMap } from '../../../../constants/enums';
import { ReactComponent as AddIcon } from '../../../../resources/icons/add.svg';
import { ReactComponent as CommentIcon } from '../../../../resources/icons/comment_primary.svg';
import CommentsForm from '../comments-components/comments-form/CommentsForm';
import CommentsCard from '../comments-components/comments-card/CommentsCard';
import RepliesBlock from '../comments-components/replies-block/RepliesBlock';

const CommentsSortOptions = {
  NEWEST_ON_TOP: {
    label: 'Newest comments on top',
    value: 'decrease',
  },
  OLDEST_ON_TOP: {
    label: 'Oldest comments on top',
    value: 'increase',
  },
};

const getDeletedUnreadItems = (unreadItems, comments) =>
  comments.length
    ? unreadItems?.reduce((result, unreadItem) => {
        if (
          !unreadItem?.metadata?.info?.relatedCommentId &&
          !comments.some(({ id }) => id === unreadItem.resourceId)
        ) {
          result.push({ ...unreadItem });
        } else {
          const relatedCommentReplies = comments.find(
            ({ id }) => id === unreadItem?.metadata?.info?.relatedCommentId,
          );
          if (
            relatedCommentReplies &&
            !relatedCommentReplies.replies.some(({ id }) => id === unreadItem.resourceId)
          ) {
            result.push({ ...unreadItem });
          }
        }
        return result;
      }, [])
    : [];

const CommentsDialog = ({ emptyState, page, relatedUserId, resourceId, unreadItems }) => {
  const { t } = useTranslation();
  const [isCommentsDialogOpen, setIsCommentsDialogOpen] = useState(false);
  const [commentsSortOption, setCommentsSortOption] = useState(CommentsSortOptions.NEWEST_ON_TOP);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [deletedUnreadItems, setDeletedUnreadItems] = useState([]);

  const { hash, pathname } = useLocation();
  const history = useHistory();

  const { updateNotificationItems } = useNotificationsData();

  const {
    addNewCommentHandler,
    comments,
    deleteCommentHandler,
    editCommentHandler,
    getComments,
    setComments,
  } = useCommentsData({ showStatusSnackBar: true, withErrorHandlers: true, isDialog: true });

  const commentsDialogOpenHandler = useCallback(() => {
    getComments(page, relatedUserId, resourceId).then((commentsData) =>
      setComments(sortArrayByKey(commentsData, 'createdDate', 'date')),
    );
    setIsCommentsDialogOpen(true);
    setIsFormVisible(false);
    setCommentsSortOption(CommentsSortOptions.NEWEST_ON_TOP);
  }, [getComments, page, relatedUserId, resourceId, setComments]);

  const dialogCloseHandler = useCallback(() => {
    if (hash) {
      history.replace({ ...pathname, hash: '' });
    }
    setIsCommentsDialogOpen(false);
    setDeletedUnreadItems([]);
  }, [hash, history, pathname]);

  useEffect(() => {
    if (hash?.includes(commentsDialog)) {
      commentsDialogOpenHandler();
      setTimeout(() => {
        const el = document.getElementById(hash.replace('#', ''));
        if (el) {
          el.scrollIntoView();
          el.focus();
        }
      }, 0);
    }
  }, [commentsDialogOpenHandler, hash]);

  useEffect(() => {
    if (isCommentsDialogOpen) {
      setDeletedUnreadItems(getDeletedUnreadItems(unreadItems, comments));
    }
  }, [comments, isCommentsDialogOpen, unreadItems]);

  useEffect(() => {
    deletedUnreadItems.forEach((item) => {
      updateNotificationItems([{ ...item, markedAsRead: true }], NotificationSourcesMap.WEB_HIDDEN);
    });
  });

  return (
    <Box>
      <Button
        aria-label={`${t('Comments')} ${t('You have number new comments', {
          number: unreadItems?.length,
        })}`}
        className="ayo-comments-dialog__button"
        gaLabel="Comments"
        onClick={commentsDialogOpenHandler}
      >
        {t('Comments')}
        <Box ml={1}>
          <DotIndicator invisible={!unreadItems?.length} overlap="circular">
            <CommentIcon />
          </DotIndicator>
        </Box>
      </Button>
      <SideDialog
        backDropClassName="ayo-comments-dialog__back-drop"
        className="ayo-comments-dialog"
        gaLabel="Comments dialog"
        isOpen={isCommentsDialogOpen}
        onClose={dialogCloseHandler}
      >
        <DialogTitle disableTypography>
          <Box alignItems="center" display="flex" flexDirection="row">
            <Typography variant="h2">{t('Comments')}</Typography>
            {!!unreadItems?.length && <TextBadge text={unreadItems.length} />}
          </Box>
        </DialogTitle>
        <DialogContent>
          {(!isFormVisible || !!comments.length) && (
            <Box display="flex" flexDirection="row" justifyContent="space-between" mt={5}>
              <Box>
                {!isFormVisible && (
                  <Button
                    endIcon={<AddIcon />}
                    gaLabel="Add a comment"
                    onClick={() => setIsFormVisible(true)}
                  >
                    {t('Add a comment')}
                  </Button>
                )}
              </Box>
              {!!comments.length && (
                <SortButton
                  menuItems={[
                    {
                      text: t(CommentsSortOptions.NEWEST_ON_TOP.label),
                      handler: () => setCommentsSortOption(CommentsSortOptions.NEWEST_ON_TOP),
                      id: 'newest-comments-on-top',
                      gaLabel: CommentsSortOptions.NEWEST_ON_TOP.label,
                      selected:
                        commentsSortOption.value === CommentsSortOptions.NEWEST_ON_TOP.value,
                    },
                    {
                      text: t(CommentsSortOptions.OLDEST_ON_TOP.label),
                      handler: () => setCommentsSortOption(CommentsSortOptions.OLDEST_ON_TOP),
                      id: 'oldest-comments-on-top',
                      gaLabel: CommentsSortOptions.OLDEST_ON_TOP.label,
                      selected:
                        commentsSortOption.value === CommentsSortOptions.OLDEST_ON_TOP.value,
                    },
                  ]}
                />
              )}
            </Box>
          )}
          {isFormVisible && (
            <Box mt={comments.length ? 2 : 5}>
              <CommentsForm
                addNewCommentHandler={addNewCommentHandler}
                isLeaveDialogDisabled
                onCancel={() => setIsFormVisible(false)}
                outlined
                page={page}
                relatedUserId={relatedUserId}
                resourceId={resourceId}
              />
            </Box>
          )}
          <Box>
            {comments.length
              ? sortArrayByKey(comments, 'createdDate', 'date', commentsSortOption.value).map(
                  (comment) => (
                    <Box key={comment.id} className="ayo-comments-block" pt={3}>
                      <CommentsCard
                        comment={comment}
                        deleteCommentHandler={deleteCommentHandler}
                        editCommentHandler={editCommentHandler}
                        hasReplies={!!comment.replies?.length}
                        isTextCollapsed
                        resourceId={resourceId}
                        unreadItem={unreadItems?.find(
                          (unreadComment) => unreadComment.resourceId === comment.id,
                        )}
                      />
                      <RepliesBlock
                        addNewCommentHandler={addNewCommentHandler}
                        deleteCommentHandler={deleteCommentHandler}
                        editCommentHandler={editCommentHandler}
                        isLeaveDialogDisabled
                        outlined
                        page={page}
                        parentCommentId={comment.id}
                        parentCommentTitle={comment.title}
                        relatedUserId={relatedUserId}
                        replies={comment.replies}
                        resourceId={resourceId}
                        unreadReplies={unreadItems?.filter((item) =>
                          comment.replies.some(({ id }) => id === item.resourceId),
                        )}
                      />
                    </Box>
                  ),
                )
              : !isFormVisible
              ? emptyState
              : null}
          </Box>
        </DialogContent>
      </SideDialog>
    </Box>
  );
};

CommentsDialog.propTypes = {
  emptyState: PropTypes.node,
  page: PropTypes.string,
  relatedUserId: PropTypes.number,
  resourceId: PropTypes.number,
  unreadItems: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
};

CommentsDialog.defaultProps = {
  emptyState: null,
  page: '',
  relatedUserId: null,
  resourceId: null,
  unreadItems: [],
};

export default CommentsDialog;
