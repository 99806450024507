import React from 'react';
import PropTypes from 'prop-types';

import { Dialog } from '../../../../atoms';
import PostVisibilitySettings from '../post-visibility-settings/PostVisibilitySettings';

const SharePostWithDialog = ({ isOpen, onClose, onSave, sharedWithData }) => (
  <Dialog
    className="ayo-share-post-with-dialog"
    gaLabel="Specify who can see the post"
    onClose={onClose}
    open={isOpen}
  >
    <PostVisibilitySettings onClose={onClose} onSave={onSave} sharedWithData={sharedWithData} />
  </Dialog>
);

SharePostWithDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  sharedWithData: PropTypes.shape({
    selectedEntities: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ),
    sharingLevel: PropTypes.string,
  }).isRequired,
};

export default SharePostWithDialog;
