/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useMemo } from 'react';
import { Box } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Rating, Typography } from '../../../../../../../atoms';
import { RadioExpandPanel, ValidationCard } from '../../../../../../../moleculas';
import { UserContext } from '../../../../../../../../context';
import { useLessonPlannerData } from '../../../../../../../../hooks';
import { LessonsScheduleSettings } from '../../../../../../../../constants/enums';
import { scheduleTypesConfig } from '../../../../../../../../constants/configs';

const RotationItemsComponent = ({ config, values }) => {
  const { value, className, ...rest } = config;
  return (
    <Box
      {...rest}
      className={classnames(className, 'ayo-rotation-block__item')}
      mr={2}
      role="button"
    >
      <Typography component="p" variant="subtitle2">
        {values[value].value}
      </Typography>
    </Box>
  );
};

RotationItemsComponent.propTypes = {
  config: PropTypes.instanceOf(Object).isRequired,
  values: PropTypes.instanceOf(Object).isRequired,
};

const ScheduleTypes = ({ onChange, availableTypes }) => {
  const { t } = useTranslation();

  const { state: userState } = useContext(UserContext);

  const currentCampusSchoolLevel = userState.profile?.currentCampusSchoolLevel;

  const {
    updateLessonPlanerCreationState,
    rotationDaysSchedule,
    rotationWeeksSchedule,
    lessonPlannerData,
    setRotationWeeksSchedule,
    setRotationDaysSchedule,
  } = useLessonPlannerData();

  const stateData = lessonPlannerData?.stateData;

  const scheduleSettings = useMemo(
    () =>
      scheduleTypesConfig.sort(({ defaultSchoolLevels }) =>
        defaultSchoolLevels.some((school) => school === currentCampusSchoolLevel) ? -1 : 1,
      ),
    [currentCampusSchoolLevel],
  );

  const rotationSelectedValue = useMemo(
    () => ({
      [LessonsScheduleSettings.ROTATION_DAYS_SCHEDULE]:
        rotationDaysSchedule?.daysSchedule?.length || 0,
      [LessonsScheduleSettings.ROTATION_WEEKS_SCHEDULE]: rotationWeeksSchedule?.length || 0,
    }),
    [rotationDaysSchedule?.daysSchedule?.length, rotationWeeksSchedule?.length],
  );

  const onRotationEdit = (rotationType, daysCount) => {
    onChange([
      LessonsScheduleSettings.ROTATION_WEEKS_SCHEDULE,
      LessonsScheduleSettings.ROTATION_DAYS_SCHEDULE,
    ]);
    let newSemesters;
    if (rotationType === LessonsScheduleSettings.ROTATION_DAYS_SCHEDULE) {
      newSemesters = stateData.semestersSchedule.map((semester) => ({
        ...semester,
        [LessonsScheduleSettings.ROTATION_DAYS_SCHEDULE]: setRotationDaysSchedule(daysCount),
        [LessonsScheduleSettings.ROTATION_WEEKS_SCHEDULE]: null,
      }));
    } else if (rotationType === LessonsScheduleSettings.ROTATION_WEEKS_SCHEDULE) {
      newSemesters = stateData.semestersSchedule.map((semester) => ({
        ...semester,
        [LessonsScheduleSettings.ROTATION_DAYS_SCHEDULE]: null,
        [LessonsScheduleSettings.ROTATION_WEEKS_SCHEDULE]: setRotationWeeksSchedule(
          daysCount,
          semester.rotationWeeksSchedule,
        ),
      }));
    }
    updateLessonPlanerCreationState({
      settingsKeys: [LessonsScheduleSettings.SEMESTERS],
      settingsValues: [newSemesters],
    });
  };

  const onRotationChange = (e) => {
    const { name, value } = e.target;
    onRotationEdit(name, value);
  };

  const onRotationTypeChange = (rotationType) => {
    onRotationEdit(rotationType);
  };

  const activeSchedule = useMemo(
    () => scheduleSettings.find(({ key }) => stateData?.semestersSchedule[0][key]).key,
    [stateData, scheduleSettings],
  );

  return (
    <Box mt={8} role="radiogroup">
      <Typography component="h2" variant="subtitle1">
        {t('What type of schedule do you have?')}
      </Typography>
      {scheduleSettings.map(
        (scheduleType) =>
          availableTypes.includes(scheduleType.key) && (
            <Box key={scheduleType.key} mt={2}>
              <ValidationCard>
                <RadioExpandPanel
                  body={
                    <Box ml={5} mr={7}>
                      <Typography variant="body2">{t(scheduleType.title)}</Typography>
                      <Box
                        alignItems="center"
                        display="flex"
                        flexDirection={
                          scheduleType.key === LessonsScheduleSettings.ROTATION_WEEKS_SCHEDULE
                            ? 'row'
                            : 'row-reverse'
                        }
                        justifyContent="start"
                        mb={1}
                        mt={3}
                      >
                        <Box mr={2}>
                          <Typography variant="body2">{t(scheduleType.valuesLabel)}</Typography>
                        </Box>
                        <Box role="radiogroup">
                          <Rating
                            className="ayo-rotation-block"
                            gaLabel={`${scheduleType.valuesLabel} change`}
                            getLabelText={(value) => scheduleType.values[value].label}
                            IconContainerComponent={(config) => (
                              <RotationItemsComponent
                                config={config}
                                values={scheduleType.values}
                              />
                            )}
                            max={Object.keys(scheduleType.values).length}
                            name={scheduleType.key}
                            onChange={onRotationChange}
                            value={rotationSelectedValue[scheduleType.key]}
                          />
                        </Box>
                      </Box>
                      <Typography isLabel variant="caption">
                        <Trans components={{ br: <br /> }} i18nKey={scheduleType.description} />
                      </Typography>
                    </Box>
                  }
                  checked={scheduleType.key === activeSchedule}
                  onChange={() => onRotationTypeChange(scheduleType.key)}
                  title={t(scheduleType.label)}
                />
              </ValidationCard>
            </Box>
          ),
      )}
    </Box>
  );
};

ScheduleTypes.propTypes = {
  onChange: PropTypes.func.isRequired,
  availableTypes: PropTypes.arrayOf(PropTypes.string),
};

ScheduleTypes.defaultProps = {
  availableTypes: [
    LessonsScheduleSettings.ROTATION_DAYS_SCHEDULE,
    LessonsScheduleSettings.ROTATION_WEEKS_SCHEDULE,
  ],
};

export default ScheduleTypes;
