import { useCallback } from 'react';

import { apiRoute, attachmentsRoute } from '../../constants/routes';
import useAxios, { StatusCodeMap } from '../HttpClient';

const useAttachmentsService = () => {
  const { post, patch } = useAxios();

  const postChunk = useCallback(
    async (fileInfoBody, enableLoadMask) => {
      const { data } = await post(
        { enabled: enableLoadMask },
        `${apiRoute}${attachmentsRoute}/chunks`,
        fileInfoBody,
        {
          skipDefaultErrorHandlers: [StatusCodeMap.BAD_REQUEST, StatusCodeMap.SERVER_ERROR],
        },
      );
      return data.entity;
    },
    [post],
  );

  const patchChunk = useCallback(
    async (id, fileChunkParams, enableLoadMask) => {
      const { chunk, range, resourceId, resourceType } = fileChunkParams;
      const formData = new FormData();
      formData.append('chunk', chunk);
      formData.append('range', range);
      formData.append('resourceId', resourceId);
      formData.append('resourceTypes', [resourceType]);
      if (fileChunkParams.ownerId) {
        formData.append('ownerId', fileChunkParams.ownerId);
      }
      const { data } = await patch(
        { enabled: enableLoadMask },
        `${apiRoute}${attachmentsRoute}/chunks/${id}`,
        formData,
        {
          skipDefaultErrorHandlers: [StatusCodeMap.BAD_REQUEST, StatusCodeMap.SERVER_ERROR],
        },
      );
      return data.entity;
    },
    [patch],
  );

  return { postChunk, patchChunk };
};

export default useAttachmentsService;
