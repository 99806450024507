import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DialogContentText, Box, DialogContent, useTheme, useMediaQuery } from '@mui/material';

import { Dialog, DialogTitle, Typography } from '../../../../atoms';
import { BottomNavigation } from '../../../../organisms';

const AptitudesDialog = ({ aptitudes, index, isOpen, navigationHandler, onClose }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const Illustration = aptitudes[index]?.landingBlockConfig.imageDesktop;

  return (
    <Dialog
      className="ayo-aptitudes-dialog"
      gaLabel="Aptitudes dialog"
      onClose={onClose}
      open={isOpen}
      scroll="paper"
    >
      <DialogTitle disableTypography>
        <Box
          alignItems={isWidthUpSm ? 'center' : 'flex-start'}
          display="flex"
          flexDirection={isWidthUpSm ? 'row' : 'column'}
          justifyContent="space-between"
          mb={4}
        >
          <Box mb={!isWidthUpSm ? 2 : 0} mr={4}>
            {Illustration && (
              <Illustration
                aria-label={t(aptitudes[index]?.alt)}
                className="ayo-aptitudes-dialog__illustration"
              />
            )}
          </Box>
          <Box display="flex" flexDirection="column">
            <Typography component="h2" variant="subtitle1">
              {t(aptitudes[index]?.key)}
            </Typography>
            <Box mt={1}>
              <Typography variant="body2">{t(aptitudes[index]?.definition)}</Typography>
            </Box>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Box className="ayo-aptitudes-dialog__text" p={2}>
            <Typography component="h3" variant="subtitle2">
              {t('Examples')}
            </Typography>
            <ul>
              {aptitudes[index]?.details.map((definition) => (
                <li key={definition}>{t(definition)}</li>
              ))}
            </ul>
          </Box>
        </DialogContentText>
      </DialogContent>
      <Box mt={5.5}>
        <BottomNavigation
          attributeKey="key"
          attributes={aptitudes}
          index={index}
          navigationHandler={navigationHandler}
          nextButtonTitle="Next aptitude"
          previousButtonTitle="Previous aptitude"
        />
      </Box>
    </Dialog>
  );
};

AptitudesDialog.propTypes = {
  aptitudes: PropTypes.arrayOf(
    PropTypes.shape({
      alt: PropTypes.string,
      definition: PropTypes.string,
      details: PropTypes.arrayOf(PropTypes.string),
      illustrations: PropTypes.node,
      key: PropTypes.string,
      landingBlockConfig: PropTypes.shape({
        imageDesktop: PropTypes.node,
      }),
      name: PropTypes.string,
    }),
  ).isRequired,
  index: PropTypes.number,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  navigationHandler: PropTypes.func.isRequired,
};

AptitudesDialog.defaultProps = {
  index: 0,
};

export default AptitudesDialog;
