import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Box, Container, Grid, useMediaQuery, useTheme } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import { Typography } from '../../../atoms';
import {
  GoToStudentProfileBlock,
  InformationalMessage,
  LeadershipAttributesGraphButton,
  MlChip,
  NewFeatureHotspot,
} from '../../../moleculas';
import {
  BottomNavigation,
  EvidenceOfGrowthBlock,
  LeadershipAttributesGraphDialog,
  PageWrapper,
  SuggestedActivitiesBlock,
  StudentGeneralInfo,
  CommentsBlock,
} from '../../../organisms';
import {
  LeadershipAttributesActions,
  LeadershipAttributesContext,
  UserActions,
  UserContext,
} from '../../../../context';
import {
  leadershipAttributesCommentsSection,
  leadershipRoute,
  notFoundPageRoute,
  trainAyoRoute,
} from '../../../../constants/routes';
import {
  useOneTimeActionService,
  useProfileService,
  useStudentsService,
} from '../../../../services';
import {
  findCompletedLevels,
  getFullName,
  mergeArrayOfObjectsHelper,
  useProfile,
} from '../../../../utils';
import {
  LeadershipAttributesStatuses,
  OneTimeActionsMap,
  RolesMap,
} from '../../../../constants/enums';
import { NewFeaturesIdsMap } from '../../../../tours/common/NewFeaturesItemsProvider';
import { evidencesConfigByType } from '../../../../constants/configs';
import {
  useEvidencesData,
  useLeadershipAttributesData,
  useSharedStudentData,
} from '../../../../hooks';

import MaturityLevelsBox from './components/maturity-levels-box/MaturityLevelsBox';

const AssessmentToolDescriptionConfig = {
  [RolesMap.TEACHER]:
    'Like the statements that correspond to the student’s behavior or click on any of them to create a goal If you want to know who liked any of the statements, hover over the number next to the like button to find out who are these people',
  [RolesMap.ADMINISTRATOR]:
    'Like the statements that correspond to the student’s behavior or click on any of them to create a goal If you want to know who liked any of the statements, hover over the number next to the like button to find out who are these people',
  [RolesMap.STUDENT]:
    'Like the statements that you feel are relevant to you or click on them to create a goal If you are curious to see who liked a statement, hover over the number next to the Like button to find out who are these people Let’s try!',
  [RolesMap.GUARDIAN]:
    'You can like the statements that correspond to the kid’s behavior. If you want to know who else reacted to any of them, hover over the number next to the Like to find out. Also, you can view the goal created out of a Leadership Attribute',
};

const LeadershipAttributesAssessmentToolPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const [isAyoMlNotificationVisible, setIsAyoMlNotificationVisible] = useState(false);
  const [studentGoals, setStudentGoals] = useState([]);
  const { dispatch: dispatchLeadershipAttributes } = useContext(LeadershipAttributesContext);
  const { state: userState, dispatch: dispatchUserState } = useContext(UserContext);
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const { studentId, assessmentId } = match.params;
  const { getOneTimeActionStatus } = useProfile();
  const { getStudentGoals } = useStudentsService();
  const { postOneTimeAction } = useOneTimeActionService();
  const { putMaturityLevelCompletion, getLeadershipAttributes } = useProfileService();
  const { graphData, leadershipAttributes, updateLeadershipAttributeEntity } =
    useLeadershipAttributesData(studentId);
  const activeAttribute = leadershipAttributes[assessmentId];
  const { activeStudent } = useSharedStudentData(studentId);
  const isGraphAvailable = findCompletedLevels(graphData);
  const { getLAChipLabel, relatedEvidences } = useEvidencesData(
    studentId,
    evidencesConfigByType.leadership_attribute.type,
  );
  const { role } = userState.profile;
  const isEducator = role === RolesMap.TEACHER || role === RolesMap.ADMINISTRATOR;
  const isStudent = role === RolesMap.STUDENT;
  const navigationHandler = useCallback(
    (idx) => {
      if (assessmentId > 0 || assessmentId < leadershipAttributes.length - 1) {
        dispatchLeadershipAttributes({
          type: LeadershipAttributesActions.SET_ACTIVE_ASSESSMENT_IDX,
          data: idx,
        });
        history.push(`${trainAyoRoute}/${studentId}${leadershipRoute}/${idx}`);
      }
    },
    [assessmentId, leadershipAttributes.length, dispatchLeadershipAttributes, history, studentId],
  );

  const attributeChangeHandler = useCallback(
    (maturityLevel, maturityLevelKey) => {
      const body = {
        id: maturityLevel.id,
        completed: !maturityLevel.completed,
      };
      const updatedAttribute = {
        attributeGroups: JSON.parse(JSON.stringify(activeAttribute.attributeGroups)),
        id: activeAttribute.id,
        key: activeAttribute.key,
        status: activeAttribute.status,
      };
      const updatedAttributeBackup = JSON.parse(JSON.stringify(updatedAttribute));
      putMaturityLevelCompletion(
        activeStudent.id,
        activeAttribute.id,
        maturityLevel.id,
        body,
      ).catch(() => {
        dispatchLeadershipAttributes({
          type: LeadershipAttributesActions.SET_LEADERSHIP_ATTRIBUTES,
          data: mergeArrayOfObjectsHelper(leadershipAttributes, [updatedAttributeBackup], 'key'),
        });
      });
      Object.values(updatedAttribute.attributeGroups).some((attributeGroup) =>
        attributeGroup.some((item) => {
          if (item.groupKey === maturityLevel.groupKey) {
            const updatedItem = item;
            const name = getFullName(userState.profile);
            if (!item.completed) {
              updatedItem.completedUserNames.unshift(name);
            } else {
              const index = updatedItem.completedUserNames.indexOf(name);
              if (index > -1) {
                updatedItem.completedUserNames.splice(index, 1);
              }
            }
            updatedItem.completed = !item.completed;
            if (userState.profile.role !== RolesMap.STUDENT) {
              const updatedGraphData = JSON.parse(JSON.stringify(graphData));
              const updatedGraphAttribute = JSON.parse(
                JSON.stringify(
                  updatedGraphData.find(
                    (graphAttribute) => graphAttribute.key === activeAttribute.key,
                  ),
                ),
              );
              const updatedGraphMaturityLevel = {
                ...updatedGraphAttribute.maturityLevels[maturityLevelKey],
              };
              if (updatedItem.completedUserNames.length === 1 && updatedItem.completed) {
                updatedGraphMaturityLevel.completedGroups += 1;
              } else if (!updatedItem.completedUserNames.length) {
                updatedGraphMaturityLevel.completedGroups -= 1;
              }
              updatedGraphAttribute.maturityLevels[maturityLevelKey] = updatedGraphMaturityLevel;
              if (isEducator) {
                updateLeadershipAttributeEntity('graphData', updatedGraphAttribute, 'key');
              }
            }
            return true;
          }
          return false;
        }),
      );
      updatedAttribute.status = LeadershipAttributesStatuses.ONGOING;
      updateLeadershipAttributeEntity('leadershipAttributes', updatedAttribute, 'key');
    },
    [
      activeAttribute?.attributeGroups,
      activeAttribute?.id,
      activeAttribute?.key,
      activeAttribute?.status,
      putMaturityLevelCompletion,
      activeStudent?.id,
      updateLeadershipAttributeEntity,
      dispatchLeadershipAttributes,
      leadershipAttributes,
      userState.profile,
      graphData,
      isEducator,
    ],
  );

  const ayoMLNotificationHandler = useCallback(() => {
    postOneTimeAction(OneTimeActionsMap.LEADERSHIP_ML_INPUT_NOTIFICATION);
    dispatchUserState({
      type: UserActions.SET_ONE_TIME_ACTION,
      data: OneTimeActionsMap.LEADERSHIP_ML_INPUT_NOTIFICATION,
    });
  }, [dispatchUserState, postOneTimeAction]);

  useEffect(() => {
    setIsAyoMlNotificationVisible(
      !getOneTimeActionStatus(OneTimeActionsMap.LEADERSHIP_ML_INPUT_NOTIFICATION),
    );
    getStudentGoals(studentId).then((goals) => {
      setStudentGoals(goals);
    });
  }, [
    userState.oneTimeActions,
    dispatchLeadershipAttributes,
    getLeadershipAttributes,
    getOneTimeActionStatus,
    getStudentGoals,
    studentId,
    activeAttribute,
  ]);

  useEffect(() => {
    if (leadershipAttributes.length) {
      if (assessmentId < 0 || assessmentId > leadershipAttributes.length - 1) {
        history.replace(notFoundPageRoute);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessmentId, leadershipAttributes]);

  return (
    <PageWrapper
      backToLink={`${trainAyoRoute}/${studentId}${leadershipRoute}`}
      backToText={isEducator ? t('Back to all Attributes') : t('Back to Leadership Attributes')}
    >
      <Container>
        {!isStudent && (
          <Box>
            <StudentGeneralInfo student={activeStudent} />
          </Box>
        )}
        <Box
          display="flex"
          flexDirection={isWidthUpSm ? 'row' : 'column'}
          justifyContent="space-between"
          pb={3}
        >
          <Grid container item md={7}>
            <Box alignItems="center" display="flex">
              <Typography
                component={!isStudent ? 'h2' : 'h1'}
                paragraph
                variant={!isStudent ? 'h2' : 'h1'}
              >
                {t('Assessment')}
              </Typography>
              <Box mb={1}>
                <NewFeatureHotspot id={NewFeaturesIdsMap.LEADERSHIP_ATTRIBUTES_GOALS} isClickable />
              </Box>
            </Box>
            <Typography variant="body2">{t(AssessmentToolDescriptionConfig[role])}</Typography>
          </Grid>
          <Box pt={isWidthUpSm ? 0 : 3}>
            {isGraphAvailable && <LeadershipAttributesGraphButton />}
          </Box>
        </Box>
        {isAyoMlNotificationVisible && (
          <Box className="ayo-maturity-levels-box__notification" pb={3}>
            <InformationalMessage
              onClick={ayoMLNotificationHandler}
              text={
                <Trans
                  components={{
                    ayoChip: <MlChip />,
                  }}
                  i18nKey="Ayo ML chip notification"
                />
              }
            />
          </Box>
        )}
        <Box>
          <Typography component="h2" variant="h2">
            {t(activeAttribute?.key)}
          </Typography>
          <Box mt={1}>
            <Typography component="p" variant="subtitle2">
              {t(activeAttribute?.definition)}
            </Typography>
          </Box>
        </Box>
        {activeAttribute && (
          <>
            <Box pt={3}>
              <Grid container>
                <Grid item md={10}>
                  {Object.keys(activeAttribute?.attributeGroups).map((maturityLevelKey, index) => (
                    <MaturityLevelsBox
                      key={`${maturityLevelKey}-${assessmentId}`}
                      accordionIndex={index}
                      attributeChangeHandler={attributeChangeHandler}
                      goals={studentGoals}
                      leadershipAttributeGroupKey={activeAttribute?.key}
                      maturityLevels={activeAttribute?.attributeGroups[maturityLevelKey]}
                      title={maturityLevelKey}
                    />
                  ))}
                </Grid>
              </Grid>
            </Box>
            <Box mb={7}>
              <BottomNavigation
                attributeKey="key"
                attributes={leadershipAttributes}
                index={+assessmentId}
                navigationHandler={navigationHandler}
              />
            </Box>
            <Box mb={isWidthUpSm ? 10 : 7}>
              <EvidenceOfGrowthBlock
                assessmentKey={activeAttribute.key}
                evidenceType={evidencesConfigByType.leadership_attribute.type}
                filteredEvidenceByEntity={activeAttribute.key}
                getChipLabelHandler={getLAChipLabel}
                headerLevel={isStudent ? 2 : 3}
                hideEmptyState={isStudent}
                isActionButtonsAvailable
                isAddEvidenceAvailable
                relatedEvidences={relatedEvidences}
                studentId={studentId}
                titleVariant={isStudent ? 'h2' : 'subtitle1'}
              />
            </Box>
            {isStudent && (
              <Box mb={isWidthUpSm ? 10 : 5}>
                <SuggestedActivitiesBlock assessmentKey={activeAttribute.key} />
              </Box>
            )}
            {isEducator && (
              <Box id={leadershipAttributesCommentsSection.replace('#', '')}>
                <CommentsBlock page={activeAttribute?.key} relatedUserId={activeStudent.id} />
              </Box>
            )}
            {isStudent && isGraphAvailable && <GoToStudentProfileBlock />}
          </>
        )}
      </Container>
      <LeadershipAttributesGraphDialog />
    </PageWrapper>
  );
};

export default LeadershipAttributesAssessmentToolPage;
