import { useTranslation } from 'react-i18next';
import { Box, Divider, Grid, useMediaQuery, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { Typography } from '../../../atoms';
import { InformationalCaption, NewFeatureHotspot } from '../../../moleculas';
import StudentsGridItemWrapper from '../components/students-grid-item-wrapper/StudentsGridItemWrapper';
import StudentsGridItem from '../components/students-grid-item/StudentsGridItem';
import { ReactComponent as RedFlagIcon } from '../../../../resources/icons/red_flag.svg';
import { NewFeaturesIdsMap } from '../../../../tours/common/NewFeaturesItemsProvider';

const InterventionTypeView = ({
  spacingBottom,
  studentAriaLabelConstructor,
  studentCardRedirectLink,
  studentStatusIcon,
  studentWrapperConstructor,
  studentsList,
  title,
  tooltipTitle,
}) => {
  const { t } = useTranslation();

  if (!studentsList.length) {
    return null;
  }

  return (
    <Box mb={spacingBottom}>
      <Box alignItems="center" display="flex" flexDirection="row" mb={1} ml={1}>
        <Typography variant="subtitle2">{t(title)}</Typography>
        <InformationalCaption showTooltip title={tooltipTitle} />
      </Box>
      <Grid container>
        {studentsList.map((studentDetails, index) => (
          <StudentsGridItemWrapper
            key={`${studentDetails.id}-${index}`}
            studentId={studentDetails.id}
            studentWrapperConstructor={studentWrapperConstructor}
          >
            <StudentsGridItem
              href={studentCardRedirectLink(studentDetails.id)}
              item={studentDetails}
              studentAriaLabel={studentAriaLabelConstructor?.(studentDetails.id)}
              studentStatusIcon={studentStatusIcon?.(studentDetails.id)}
            />
          </StudentsGridItemWrapper>
        ))}
      </Grid>
    </Box>
  );
};

InterventionTypeView.propTypes = {
  spacingBottom: PropTypes.number,
  studentAriaLabelConstructor: PropTypes.func.isRequired,
  studentCardRedirectLink: PropTypes.func.isRequired,
  studentStatusIcon: PropTypes.node.isRequired,
  studentWrapperConstructor: PropTypes.func.isRequired,
  studentsList: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  title: PropTypes.string.isRequired,
  tooltipTitle: PropTypes.string.isRequired,
};

InterventionTypeView.defaultProps = {
  spacingBottom: 0,
};

const InterventionRequiredGroupView = ({
  studentsList,
  studentCardRedirectLink,
  studentStatusIcon,
  studentWrapperConstructor,
  studentAriaLabelConstructor,
}) => {
  const { t } = useTranslation();
  const studentsCount = studentsList.length;
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  const interventionAcknowledgedGroup = useMemo(
    () => studentsList.filter(({ isInterventionAcknowledged }) => isInterventionAcknowledged),
    [studentsList],
  );

  const interventionRequiredGroup = useMemo(
    () => studentsList.filter(({ isInterventionAcknowledged }) => !isInterventionAcknowledged),
    [studentsList],
  );

  return (
    <Box className="ayo-intervention-required-group-view" mt={6} px={1} py={2}>
      <Box
        alignItems={isWidthUpSm ? 'center' : 'flex-start'}
        display="flex"
        flexDirection="row"
        mb={3}
        px={1}
      >
        <Box display="flex" flexDirection="row">
          <Box mr={isWidthUpSm ? 2 : 1}>
            <RedFlagIcon />
          </Box>
          <Typography variant="subtitle1">{t('Students seeking adult assistance')}</Typography>
          <NewFeatureHotspot
            id={NewFeaturesIdsMap.SEL_ADULT_ASSISTANCE}
            isClickable
            label={t('Adult assistance need')}
          />
        </Box>
        <Box alignItems="center" display="flex" ml={isWidthUpSm ? 2 : 4}>
          <Divider
            className="ayo-intervention-required-group-view__divider"
            flexItem
            orientation="vertical"
          />
          <Typography isLabel variant="body2">
            {`${studentsCount} ${
              isWidthUpSm ? t(studentsCount === 1 ? 'student' : 'students') : ''
            }`}
          </Typography>
        </Box>
      </Box>
      <InterventionTypeView
        spacingBottom={interventionAcknowledgedGroup?.length ? 3 : 0}
        studentAriaLabelConstructor={studentAriaLabelConstructor}
        studentCardRedirectLink={studentCardRedirectLink}
        studentsList={interventionRequiredGroup}
        studentStatusIcon={studentStatusIcon}
        studentWrapperConstructor={studentWrapperConstructor}
        title="Students who haven’t talked to an adult yet"
        tooltipTitle="These students requested adult assistance, and their need is still not acknowledged"
      />
      <InterventionTypeView
        studentAriaLabelConstructor={studentAriaLabelConstructor}
        studentCardRedirectLink={studentCardRedirectLink}
        studentsList={interventionAcknowledgedGroup}
        studentStatusIcon={studentStatusIcon}
        studentWrapperConstructor={studentWrapperConstructor}
        title="Students working with educators"
        tooltipTitle="These students requested adult assistance, and their need is already acknowledged"
      />
    </Box>
  );
};

InterventionRequiredGroupView.propTypes = {
  studentsList: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  studentCardRedirectLink: PropTypes.func.isRequired,
  studentStatusIcon: PropTypes.node,
  studentWrapperConstructor: PropTypes.func,
  studentAriaLabelConstructor: PropTypes.func,
};

InterventionRequiredGroupView.defaultProps = {
  studentStatusIcon: null,
  studentWrapperConstructor: null,
  studentAriaLabelConstructor: null,
};

export default InterventionRequiredGroupView;
