import React, { useCallback, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';

import { Button } from '../../../atoms';
import { PageIllustrationBlock, ScreenCenteredContainer } from '../../../moleculas';
import { PageWrapper } from '../../../organisms';
import { UserActions, UserContext } from '../../../../context';
import { OneTimeActionsMap, RolesMap } from '../../../../constants/enums';
import { trainAyoQuestionnaireRoute } from '../../../../constants/routes';
import { useOneTimeActionService } from '../../../../services';
import { useProfile } from '../../../../utils';
import { ReactComponent as QuestionnaireStartTeacherIllustration } from '../../../../resources/images/train_ayo_teacher.svg';
import { ReactComponent as QuestionnaireStartParentIllustration } from '../../../../resources/images/train_ayo_parent.svg';

const TrainAyoStartPage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { state: userState, dispatch: dispatchUserState } = useContext(UserContext);

  const userRole = userState?.profile?.role;

  const StartingContentMap = {
    [RolesMap.TEACHER]: {
      illustration: QuestionnaireStartTeacherIllustration,
      title: t('Train AYO'),
      mainText: t('Train AYO educator explanation'),
      buttonText: t('Start training AYO'),
      illustrationLabel: t(
        'A student and a teacher are sitting at the table and talking to each other',
      ),
      buttonGaLabel: 'Train AYO - Start',
    },
    [RolesMap.STUDENT]: {
      illustration: QuestionnaireStartParentIllustration,
      title: t('Train AYO'),
      mainText: t('Train AYO student explanation'),
      buttonText: t('Start training AYO'),
      illustrationLabel: t(
        'A scenery of nature with corn hole game boards and pillows, soccer ball, paints, books on the background and two camping tents and a camp fire on the background',
      ),
      buttonGaLabel: 'Train AYO - Start',
    },
  };

  const currentRoleContent = StartingContentMap[userRole];
  const Illustration = currentRoleContent?.illustration;

  const { getOneTimeActionStatus } = useProfile();
  const { postOneTimeAction } = useOneTimeActionService();

  const onProceed = useCallback(() => {
    postOneTimeAction(OneTimeActionsMap.TRAIN_AYO_START);
    dispatchUserState({
      type: UserActions.SET_ONE_TIME_ACTION,
      data: OneTimeActionsMap.TRAIN_AYO_START,
    });
    history.push(trainAyoQuestionnaireRoute);
  }, [dispatchUserState, history, postOneTimeAction]);

  useEffect(() => {
    if (getOneTimeActionStatus(OneTimeActionsMap.TRAIN_AYO_START)) {
      onProceed();
    }
  }, [getOneTimeActionStatus, onProceed]);

  return (
    <PageWrapper>
      <ScreenCenteredContainer>
        <PageIllustrationBlock
          Illustration={Illustration}
          illustrationAriaLabel={currentRoleContent?.illustrationLabel}
          mainHeaderText={currentRoleContent?.title}
          secondaryHeaderText={[currentRoleContent?.mainText]}
        />
        <Grid container item justifyContent="center">
          <Button
            gaLabel={currentRoleContent?.buttonGaLabel}
            isAdaptive
            onClick={onProceed}
            variant="primary"
          >
            {currentRoleContent?.buttonText}
          </Button>
        </Grid>
      </ScreenCenteredContainer>
    </PageWrapper>
  );
};

export default TrainAyoStartPage;
