import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Image } from '../../atoms';
import STTTextField from '../stt-text-field/STTTextField';
import ImageEditButtons from '../image-edit-buttons/ImageEditButtons';
import { apiRoute, attachmentsRoute } from '../../../constants/routes';
import { InputsValidationRules } from '../../../constants/enums';

const ImageFilePreview = ({ item, onRemove, withMultipleImages }) => {
  const { file } = item;

  const [imgSrc, setImgSrc] = useState('');

  useEffect(() => {
    if (file?.type?.startsWith('image/')) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setImgSrc(e.target.result);
      };
      reader.readAsDataURL(file);
    } else {
      setImgSrc(`${apiRoute}${attachmentsRoute}/${file.id}/owners/${file.ownerId}`);
    }
  }, [file]);

  return imgSrc ? (
    <Box className="ayo-image-file-previewer">
      <Image
        className={classNames('ayo-image-file-previewer__image', {
          'ayo-image-file-previewer__image__multiple': withMultipleImages,
        })}
        src={imgSrc}
      />
      <Box className="ayo-image-file-previewer__controls">
        <ImageEditButtons onDelete={() => onRemove(item)} />
      </Box>
    </Box>
  ) : null;
};

ImageFilePreview.propTypes = {
  item: PropTypes.shape({
    file: PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string,
      size: PropTypes.number,
      id: PropTypes.number,
      ownerId: PropTypes.number,
    }),
    errors: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  onRemove: PropTypes.func.isRequired,
  withMultipleImages: PropTypes.bool,
};

ImageFilePreview.defaultProps = {
  withMultipleImages: false,
};

export const ImageFilePreviewWithAltDescription = (props) => {
  const { onAltChange, inputFieldName, altTexts, item } = props;
  const { t } = useTranslation();

  return (
    <>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <ImageFilePreview {...props} />
      <Box mt={2}>
        <STTTextField
          fullWidth
          label={t('Image description')}
          maxLength={InputsValidationRules.MAX_INPUT_LENGTH}
          name={inputFieldName}
          onChange={(e) => onAltChange(e.target.value, item.file)}
          outlined
          value={altTexts[item.file.name]}
        />
      </Box>
    </>
  );
};

ImageFilePreviewWithAltDescription.propTypes = {
  onAltChange: PropTypes.func.isRequired,
  altValue: PropTypes.string.isRequired,
  inputFieldName: PropTypes.string.isRequired,
  item: PropTypes.shape({
    file: PropTypes.instanceOf(Object),
  }).isRequired,
  altTexts: PropTypes.instanceOf(Object).isRequired,
};

export default ImageFilePreview;
