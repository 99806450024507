import React, { useCallback, useState } from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Button } from '../../../../../atoms';
import { TextWithTooltip } from '../../../../../moleculas';
import { ClubStatus } from '../../../../../organisms';
import { getClubFormValidation } from '../../../../../../utils';
import { ClubKeys } from '../../../../../../constants/club-hub';
import { ReactComponent as CreateIcon } from '../../../../../../resources/icons/create.svg';
import ClubName from '../../../club-hub-components/club-name/ClubName';
import ClubEditDialog from '../club-edit-dialog/ClubEditDialog';

const ClubDetailsName = ({ currentClubName, isEditable, onSave, status }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  const [newClubName, setNewClubName] = useState(currentClubName);
  const [error, setError] = useState('');

  const nameChangeHandler = useCallback(
    (changes) => {
      const { clubName } = changes;

      setNewClubName(clubName);

      if (error) {
        setError('');
      }
    },
    [error],
  );

  const nameSaveHandler = useCallback(() => {
    const { isFormValid, validationErrors } = getClubFormValidation(
      { [ClubKeys.CLUB_NAME]: newClubName },
      t,
    );
    setError(validationErrors[ClubKeys.CLUB_NAME]);
    if (isFormValid) {
      onSave({ [ClubKeys.CLUB_NAME]: newClubName });
      setIsEditDialogOpen(false);
    }
  }, [newClubName, onSave, t]);

  return (
    <Box alignItems="flex-start" display="flex" flexDirection={isWidthUpSm ? 'row' : 'column'}>
      <Box display="flex" flexDirection="row">
        <Box mr={!isEditable ? 2 : 0}>
          <TextWithTooltip
            headerLevel={1}
            rowsCount={2}
            title={currentClubName}
            titleVariant="h1"
          />
        </Box>
        {isEditable && (
          <Box mx={1}>
            <Button
              aria-label={t('Edit - Club name')}
              gaLabel="Edit club name"
              isIconButton
              onClick={() => {
                setIsEditDialogOpen(true);
                setNewClubName(currentClubName);
              }}
            >
              <CreateIcon />
            </Button>
          </Box>
        )}
      </Box>
      {status && (
        <Box minWidth="fit-content" py={1}>
          <ClubStatus status={status} />
        </Box>
      )}
      <ClubEditDialog
        className="ayo-club-hub-details__edit-dialog__name"
        dialogContent={
          <ClubName
            error={error}
            label={t('Club name')}
            onChange={nameChangeHandler}
            outlined
            value={newClubName}
          />
        }
        dialogTitle={t('Edit - Club name')}
        isOpen={isEditDialogOpen}
        onClose={() => {
          setIsEditDialogOpen(false);
          setError('');
        }}
        onSave={nameSaveHandler}
      />
    </Box>
  );
};

ClubDetailsName.propTypes = {
  currentClubName: PropTypes.string.isRequired,
  isEditable: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  status: PropTypes.string,
};

ClubDetailsName.defaultProps = {
  status: null,
};

export default ClubDetailsName;
