import { useEffect, useState } from 'react';

import useInView from '../use-in-view/useInView';

const useLazyDataLoad = (loadDataPromise, dependencyArray = []) => {
  const [isDataLoaded, setIsDataLoaded] = useState(null);

  useEffect(() => {
    setIsDataLoaded(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencyArray);

  const { ref } = useInView(
    () => {
      if (isDataLoaded === null) {
        setIsDataLoaded(false);
        loadDataPromise().then(() => {
          setIsDataLoaded(true);
        });
      }
    },
    null,
    isDataLoaded,
  );

  return { isDataLoaded, loadActivatorRef: ref };
};

export default useLazyDataLoad;
