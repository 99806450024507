import { KeyboardMap } from '../../constants/enums';

// TODO: revise this function as it returns whether the element is UNcropped
// (element should be cropped when his width is bigger that parrent width - padding)
export const getIsDomElementCropped = (parentClientWidth, parrentPadding, stringWidth) =>
  parentClientWidth - parrentPadding > stringWidth;

export const addKeyboardPressHandler = (func) => (e) => {
  if (e.key === KeyboardMap.ENTER || e.key === KeyboardMap.SPACE) {
    func();
  }
};
