import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Box, DialogContent } from '@mui/material';

import { Dialog, DialogTitle, Typography } from '../../../../../../../../../atoms';
import RepliesForm from '../replies-form/RepliesForm';

const EditPostReplyDialog = ({
  onClose,
  isOpen,
  reply,
  editCommentHandler,
  className,
  postId,
  clubId,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog className={className} onClose={onClose} open={isOpen}>
      <DialogTitle disableTypography>
        <Typography component="h2" variant="subtitle1">
          {t('Edit reply')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box mt={2}>
          <RepliesForm
            cancelEditMode={onClose}
            clubId={clubId}
            editCommentHandler={editCommentHandler}
            postId={postId}
            replyToEdit={reply}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

EditPostReplyDialog.propTypes = {
  className: PropTypes.string,
  clubId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.func.isRequired,
  reply: PropTypes.shape({
    id: PropTypes.number,
    author: {
      id: PropTypes.number,
      name: PropTypes.string,
      role: PropTypes.string,
    },
    createdDate: PropTypes.string,
    edited: PropTypes.bool,
    comment: PropTypes.string,
  }).isRequired,
  editCommentHandler: PropTypes.func.isRequired,
  postId: PropTypes.string.isRequired,
};

EditPostReplyDialog.defaultProps = {
  className: '',
};

export default EditPostReplyDialog;
