import React, { useCallback, useContext, useState } from 'react';
import { Box, Divider, Grid, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ActionsMenu, Button, Card, Checkbox, FormControlLabel, Typography } from '../../atoms';
import { EditingButton, DisabledControlWithTooltip, TextWithTooltip } from '../../moleculas';
import EvidenceOfGrowthAddEditDialog from '../evidence-of-growth/evidence-of-growth-add-dialog/EvidenceOfGrowthAddEditDialog';
import { ActionStepEvidenceAddDialog } from '../action-step-dialogs/action-step-evidence-add-dialog/ActionStepEvidenceAddDialog';
import { ActionStepReflectionAddDialog } from '../action-step-dialogs/action-step-reflection-add-dialog/ActionStepReflectionAddDialog';
import { ActionStepReflectionViewDialog } from '../action-step-dialogs/action-step-reflection-view-dialog/ActionStepReflectionViewDialog';
import { ConfirmDialog } from '../confirm-dialog/ConfirmDialog';
import { getFormattedDate } from '../../../utils';
import useEvidenceHelpers from '../../../utils/evidence-helpers/useEvidenceHelpers';
import { UserContext } from '../../../context';
import { AttachmentsResourcesTypes, RolesMap } from '../../../constants/enums';
import { goalActionStepItem, goalDetailsItem } from '../../../constants/propTypes';
import { GoalDialogContent, GoalsMilestonesStatuses } from '../../../constants/goals';
import { ReactComponent as AddIcon } from '../../../resources/icons/add.svg';
import { ReactComponent as ChevronIcon } from '../../../resources/icons/chevron_left.svg';
import { ReactComponent as DeleteIcon } from '../../../resources/icons/delete_primary.svg';
import { ReactComponent as EditIcon } from '../../../resources/icons/create.svg';
import { ReactComponent as FolderIcon } from '../../../resources/icons/folder.svg';
import { ReactComponent as MilestoneIcon } from '../../../resources/icons/date_picker_black.svg';
import { ReactComponent as NotesIcon } from '../../../resources/icons/description.svg';

const MilestoneCard = React.forwardRef(
  (
    {
      actionStep,
      className,
      goalDetails,
      index,
      onMilestoneDelete,
      onMilestoneEdit,
      onReflectionCreate,
      onReflectionDelete,
      onStatusChange,
      showActionsButtons,
      showActionsMenu,
      showAddEvidenceButton,
      showCheckbox,
      showViewButton,
      studentId,
    },
    ref,
  ) => {
    const { t, i18n } = useTranslation();

    const theme = useTheme();
    const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));
    const { state: userState } = useContext(UserContext);
    const isStudent = userState.profile.role === RolesMap.STUDENT;
    const userRole =
      userState.profile.role === RolesMap.ADMINISTRATOR ? RolesMap.TEACHER : userState.profile.role;
    const [isActionStepEvidenceDialogOpen, setActionStepEvidenceDialogOpen] = useState(false);
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
    const [isEvidenceAddDialogOpen, setEvidenceAddDialogOpen] = useState(false);
    const [isReflectionAddDialogOpen, setIsReflectionAddDialogOpen] = useState(false);
    const [isReflectionViewDialogOpen, setIsReflectionViewDialogOpen] = useState(false);
    const [shouldOpenReflectionDialog, setShouldOpenReflectionDialog] = useState(false);

    const { evidenceChangeHandler } = useEvidenceHelpers(studentId, AttachmentsResourcesTypes.GOAL);

    const onCheckBoxChange = useCallback(
      (event) => {
        const { checked } = event.target;
        onStatusChange(event);
        if (isStudent && checked) {
          if (actionStep.evidenceIds?.length < 3 || !actionStep.evidenceIds) {
            setActionStepEvidenceDialogOpen(true);
            setShouldOpenReflectionDialog(true);
          } else {
            setIsReflectionAddDialogOpen(true);
          }
        }
        if (actionStep.reflection && !checked) {
          setIsConfirmDialogOpen(true);
        }
      },
      [onStatusChange, isStudent, actionStep.reflection, actionStep.evidenceIds],
    );
    const onReflectionDialogSubmit = useCallback(
      (reflection) => {
        onReflectionCreate(actionStep.id, reflection);
        setIsReflectionAddDialogOpen(false);
        setShouldOpenReflectionDialog(false);
      },
      [actionStep.id, onReflectionCreate],
    );

    const onActionStepEvidenceDialogClose = useCallback(() => {
      setIsReflectionAddDialogOpen(true);
      setActionStepEvidenceDialogOpen(false);
    }, []);

    const onActionStepEvidenceDialogSubmit = useCallback(() => {
      setEvidenceAddDialogOpen(true);
      setActionStepEvidenceDialogOpen(false);
    }, []);

    const onAddEvidenceDialogClose = useCallback(() => {
      setEvidenceAddDialogOpen(false);
      if (shouldOpenReflectionDialog) {
        setIsReflectionAddDialogOpen(true);
        setShouldOpenReflectionDialog(false);
      }
    }, [shouldOpenReflectionDialog]);

    const onConfirmDialogHandler = useCallback(() => {
      onReflectionDelete(actionStep.id, { shouldProceedWithDeleting: true });
      setIsConfirmDialogOpen(false);
    }, [actionStep.id, onReflectionDelete]);

    const onConfirmDialogCancelHandler = useCallback(() => {
      onReflectionDelete(actionStep.id, { shouldProceedWithDeleting: false });
      setIsConfirmDialogOpen(false);
    }, [actionStep.id, onReflectionDelete]);

    return (
      <Card
        ref={ref}
        className={classNames('ayo-goal-milestone-card', className)}
        elevation={actionStep.status === GoalsMilestonesStatuses.COMPLETED ? 0 : 1}
        mainContent={
          <Grid container>
            <Grid item xs={showActionsMenu ? 11 : 12}>
              {showCheckbox ? (
                <FormControlLabel
                  control={
                    <DisabledControlWithTooltip
                      placement="bottom-start"
                      PopperProps={{
                        style: {
                          marginLeft: 20,
                        },
                      }}
                      showTooltip={goalDetails.status === GoalsMilestonesStatuses.COMPLETED}
                      title={t('The goal can’t be edited once it’s completed')}
                    >
                      <Checkbox
                        checked={actionStep.status === GoalsMilestonesStatuses.COMPLETED}
                        disabled={goalDetails.status === GoalsMilestonesStatuses.COMPLETED}
                        gaLabel="Change action step status"
                        name={actionStep.id}
                        onChange={onCheckBoxChange}
                      />
                    </DisabledControlWithTooltip>
                  }
                  disabled={goalDetails.status === GoalsMilestonesStatuses.COMPLETED}
                  label={
                    <TextWithTooltip
                      headerLevel={3}
                      title={actionStep.name}
                      titleVariant="subtitle2"
                    />
                  }
                />
              ) : (
                <TextWithTooltip headerLevel={3} title={actionStep.name} titleVariant="subtitle2" />
              )}
              <Box ml={showCheckbox ? 5 : 0}>
                <Typography
                  align="left"
                  className="ayo-goal-milestone-card__deadline"
                  component="p"
                  noWrap
                  variant="body2"
                >
                  <MilestoneIcon />
                  {`${t('Deadline')}: ${getFormattedDate(actionStep.dueDate, i18n.languages)}`}
                </Typography>
              </Box>
              <Box ml={showCheckbox ? 5 : 0}>
                {!!actionStep.evidenceIds?.length && (
                  <Typography
                    align="left"
                    className="ayo-goal-milestone-card__deadline"
                    component="p"
                    noWrap
                    variant="body2"
                  >
                    <FolderIcon />
                    {t('Evidence of growth')} ({actionStep.evidenceIds.length})
                  </Typography>
                )}
                {actionStep.reflection && (
                  <Typography
                    align="left"
                    className="ayo-goal-milestone-card__deadline"
                    component="p"
                    noWrap
                    variant="body2"
                  >
                    <NotesIcon />
                    {t('Reflection')}
                  </Typography>
                )}
              </Box>
              {showActionsButtons && (
                <Grid
                  alignItems="center"
                  className="ayo-goal-milestone-card__controls-container"
                  container
                  direction="row"
                >
                  <EditingButton
                    gaLabel="Goal action step edit"
                    icon={<EditIcon />}
                    iconPosition="end"
                    onClick={() =>
                      onMilestoneEdit(
                        { name: actionStep.name, dueDate: new Date(actionStep.dueDate) },
                        index,
                      )
                    }
                    text={t('Edit')}
                  />
                  <Divider flexItem orientation="vertical" variant="middle" />
                  <EditingButton
                    gaLabel="Goal action step delete"
                    icon={<DeleteIcon />}
                    iconPosition="end"
                    onClick={() => onMilestoneDelete(index)}
                    text={t('Delete')}
                  />
                </Grid>
              )}
              {(showAddEvidenceButton || showViewButton) && (
                <Box display="flex" flexDirection={isWidthUpSm ? 'row' : 'column'} ml={4.5} mt={2}>
                  {showAddEvidenceButton && (
                    <Box mr={2.5}>
                      <Button
                        disableElevation
                        disableRipple
                        endIcon={<AddIcon />}
                        gaLabel="Add an evidence"
                        onClick={() => setEvidenceAddDialogOpen(true)}
                      >
                        {t('Add evidence')}
                      </Button>
                    </Box>
                  )}
                  {showViewButton && (
                    <Box>
                      <Button
                        gaLabel="View action step details"
                        onClick={() => setIsReflectionViewDialogOpen(true)}
                      >
                        {t('View details')}
                        <Box display="flex" ml={1}>
                          <ChevronIcon className="reversed" />
                        </Box>
                      </Button>
                    </Box>
                  )}
                </Box>
              )}
            </Grid>
            {showActionsMenu && (
              <Grid item xs={1}>
                <ActionsMenu
                  id="goal-actions"
                  menuItems={[
                    {
                      text: t('Edit'),
                      icon: EditIcon,
                      handler: () =>
                        onMilestoneEdit(
                          { name: actionStep.name, dueDate: new Date(actionStep.dueDate) },
                          index,
                        ),
                      id: 'edit',
                      gaLabel: 'Goal action step edit',
                    },
                    {
                      text: t('Delete'),
                      icon: DeleteIcon,
                      handler: () => onMilestoneDelete(index),
                      id: 'delete',
                      gaLabel: 'Goal action step delete',
                    },
                  ]}
                />
              </Grid>
            )}
            <ConfirmDialog
              cancelButtonTitle="I want to cancel"
              className="ayo-goal-milestone-card__confirm-dialog"
              confirmButtonTitle="Yes, I want to proceed"
              dialogGaLabel="Close uncheck confirm dialog"
              isOpen={isConfirmDialogOpen}
              onClose={onConfirmDialogCancelHandler}
              onConfirm={onConfirmDialogHandler}
              text={GoalDialogContent.CONFIRM_DIALOG_UNCHECK_ACTION_STEP[userRole]?.text}
              title={GoalDialogContent.CONFIRM_DIALOG_UNCHECK_ACTION_STEP[userRole]?.title}
              variant="confirm"
            />
            <ActionStepEvidenceAddDialog
              isOpen={isActionStepEvidenceDialogOpen}
              onClose={onActionStepEvidenceDialogClose}
              onSubmit={onActionStepEvidenceDialogSubmit}
            />
            <ActionStepReflectionAddDialog
              isOpen={isReflectionAddDialogOpen}
              onClose={onReflectionDialogSubmit}
              onSubmit={onReflectionDialogSubmit}
            />
            <ActionStepReflectionViewDialog
              actionStep={actionStep}
              goalDetails={goalDetails}
              isOpen={isReflectionViewDialogOpen}
              onClose={() => setIsReflectionViewDialogOpen(false)}
            />
            <EvidenceOfGrowthAddEditDialog
              actionSteps={[actionStep]}
              assessmentKey={goalDetails.leadershipAttributeKey}
              className="ayo-evidence-of-growth"
              evidenceChangeHandler={evidenceChangeHandler}
              goalDetails={goalDetails}
              isActionStepDisabled
              onClose={onAddEvidenceDialogClose}
              open={isEvidenceAddDialogOpen}
              title={t('Add evidence of growth')}
            />
          </Grid>
        }
      />
    );
  },
);

MilestoneCard.propTypes = {
  actionStep: goalActionStepItem,
  className: PropTypes.string,
  goalDetails: goalDetailsItem,
  index: PropTypes.number.isRequired,
  onMilestoneDelete: PropTypes.func.isRequired,
  onMilestoneEdit: PropTypes.func.isRequired,
  onReflectionCreate: PropTypes.func,
  onReflectionDelete: PropTypes.func,
  onStatusChange: PropTypes.func,
  showActionsButtons: PropTypes.bool,
  showActionsMenu: PropTypes.bool,
  showAddEvidenceButton: PropTypes.bool,
  showCheckbox: PropTypes.bool,
  showViewButton: PropTypes.bool,
  studentId: PropTypes.number.isRequired,
};

MilestoneCard.defaultProps = {
  actionStep: null,
  className: '',
  goalDetails: null,
  onReflectionCreate: () => {},
  onReflectionDelete: () => {},
  onStatusChange: () => {},
  showActionsButtons: false,
  showActionsMenu: false,
  showAddEvidenceButton: false,
  showCheckbox: false,
  showViewButton: false,
};

export default MilestoneCard;
