import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Button, DynamicGrid } from '../../../../atoms';
import { DeleteItemWrapper, InitiativeEmptyStateBlock } from '../../../../moleculas';
import { PortfolioSectionTypes, PublicationStatuses } from '../../../../../constants/enums';
import { PortfolioItemsToAddData, getEssayTitle, sortArrayByKey } from '../../../../../utils';
import { useStudentsService } from '../../../../../services';
import { portfolioSectionConfig } from '../../../../../constants/propTypes';
import { UserContext } from '../../../../../context';
import PortfolioSection from '../../components/portfolio-section/PortfolioSection';
import PortfolioFilePreviewDialog from '../../components/portfolio-file-preview-dialog/PortfolioFilePreviewDialog';
import { ReactComponent as EssaysEmptyStateIllustration } from '../../../../../resources/images/portfolio/essay_empty_state.svg';
import { ReactComponent as AddIcon } from '../../../../../resources/icons/add.svg';

import PortfolioCreateEssayDialog from './portfolio-create-essay-dialog/PortfolioCreateEssayDialog';
import EssayCard from './essay-card/EssayCard';

const PortfolioEssaysSection = ({ config, sectionIndex, isLastSection }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpMd = useMediaQuery(theme.breakpoints.up('md'));

  const { state: userState } = useContext(UserContext);
  const studentId = userState.profile?.id;

  const {
    addPortfolioItemHandler,
    deletePortfolioItemHandler,
    hasData,
    isEditMode,
    isVisible,
    metaData,
    reflectionHandler,
    sectionData,
    updatePortfolioHandler,
    updatePortfolioMetaData,
    onUpdatePositionHandler,
  } = config;
  const { entities, reflection } = sectionData;

  const { postStudentEssay, updateStudentEssay, deleteStudentEssay } = useStudentsService();

  const [portfolioEssays, setPortfolioEssays] = useState([]);
  const [isPreviewDialogOpen, setIsPreviewDialogOpen] = useState(false);
  const [previewEssayIndex, setPreviewEssayIndex] = useState(null);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [editingEssayIndex, setEditingEssayIndex] = useState(null);

  useEffect(() => {
    setPortfolioEssays(
      sortArrayByKey(
        entities.filter((item) => item.status === PublicationStatuses.PUBLISHED),
        'updatedDate',
        'date',
        'decrease',
      ),
    );
  }, [entities]);

  const essaysToAdd = useMemo(
    () =>
      PortfolioItemsToAddData[PortfolioSectionTypes.PORTFOLIO__ESSAYS](
        entities,
        metaData,
        'id',
      ).map(({ id, textJson }) => ({ id: id.toString(), name: getEssayTitle(textJson) })),
    [entities, metaData],
  );

  const handleSave = useCallback(
    (essay, essayId) => {
      if (editingEssayIndex !== null) {
        const essayIndex = entities.findIndex(
          (item) => item.id === portfolioEssays[editingEssayIndex].id,
        );
        updateStudentEssay(essay, portfolioEssays[editingEssayIndex].id, studentId).then((data) => {
          updatePortfolioHandler({
            ...sectionData,
            entities: Object.assign([...sectionData.entities], {
              [essayIndex]: {
                ...data,
              },
            }),
          });

          const editingEssayMetaDataIndex = metaData.findIndex(({ id }) => id === data.id);
          updatePortfolioMetaData(
            Object.assign([...metaData], {
              [editingEssayMetaDataIndex]: {
                ...data,
              },
            }),
          );
        });
        setEditingEssayIndex(null);
      } else if (essayId) {
        updateStudentEssay(essay, essayId, studentId).then((data) => {
          addPortfolioItemHandler(null, [data], 'id', sectionData);
          updatePortfolioMetaData([...metaData, data]);
        });
      } else {
        postStudentEssay(essay).then((data) => {
          addPortfolioItemHandler(null, [data], 'id', sectionData);
          updatePortfolioMetaData([...metaData, data]);
        });
      }
    },
    [
      addPortfolioItemHandler,
      editingEssayIndex,
      entities,
      metaData,
      portfolioEssays,
      postStudentEssay,
      sectionData,
      studentId,
      updatePortfolioHandler,
      updatePortfolioMetaData,
      updateStudentEssay,
    ],
  );

  const handleDelete = useCallback(
    (essayId) => {
      deleteStudentEssay(studentId, essayId).then(() => {
        updatePortfolioHandler({
          ...sectionData,
          entities: sectionData.entities.filter((item) => item.id !== essayId),
        });
        updatePortfolioMetaData(metaData.filter((item) => item.id !== essayId));
      });
    },
    [
      deleteStudentEssay,
      metaData,
      sectionData,
      studentId,
      updatePortfolioHandler,
      updatePortfolioMetaData,
    ],
  );

  if (!isVisible) {
    return null;
  }

  return (
    <Box>
      <PortfolioSection
        addItemsActionsConfig={
          hasData && isEditMode
            ? {
                title: 'Add essay',
                items: [
                  {
                    text: t('Create new'),
                    icon: AddIcon,
                    handler: () => setIsCreateDialogOpen(true),
                    id: 'create-new-essay',
                    gaLabel: 'Create new essay',
                  },
                ],
              }
            : null
        }
        body={
          <Box mt={3}>
            <DynamicGrid
              columnsCount={isWidthUpMd ? 3 : 2}
              gridItems={portfolioEssays.map((essay, index) => (
                <DeleteItemWrapper
                  key={`essay-${essay.id}`}
                  disabled={!isEditMode}
                  gaLabel="Remove essay"
                  mainContent={
                    <EssayCard
                      id={essay.id}
                      isEditMode={isEditMode}
                      onEdit={() => {
                        setEditingEssayIndex(index);
                        setIsCreateDialogOpen(true);
                      }}
                      onRead={() => {
                        setPreviewEssayIndex(index);
                        setIsPreviewDialogOpen(true);
                      }}
                      textJson={essay.textJson}
                    />
                  }
                  onDelete={() =>
                    deletePortfolioItemHandler(portfolioEssays, 'id', essay.id, sectionData)
                  }
                />
              ))}
              isHorizontalOrder
            />
          </Box>
        }
        className={`${classNames({
          'ayo-portfolio__essays-section': hasData,
          'ayo-portfolio__essay-background': hasData,
        })}`}
        emptyState={
          !hasData ? (
            <InitiativeEmptyStateBlock
              body={isEditMode ? t('But as soon as you need one, you can create it here!') : ''}
              customButton={
                isEditMode ? (
                  <Button
                    endIcon={<AddIcon />}
                    gaLabel="Create essay"
                    onClick={() => setIsCreateDialogOpen(() => true)}
                  >
                    {t('Create new')}
                  </Button>
                ) : null
              }
              illustration={<EssaysEmptyStateIllustration />}
              title={t('No application essays created so far')}
            />
          ) : null
        }
        isEditMode={isEditMode}
        isLastSection={isLastSection}
        itemsToAdd={essaysToAdd}
        onAddItems={(itemsToAdd) =>
          addPortfolioItemHandler(metaData, itemsToAdd, 'id', sectionData)
        }
        onChangeReflection={reflectionHandler}
        onUpdatePositionHandler={onUpdatePositionHandler}
        reflection={reflection}
        sectionIndex={sectionIndex}
        sectionKey={PortfolioSectionTypes.PORTFOLIO__ESSAYS}
        showActionsMenu={hasData && isEditMode}
        title="Application essays"
      />
      <PortfolioFilePreviewDialog
        isOpen={isPreviewDialogOpen}
        onClose={() => {
          setIsPreviewDialogOpen(false);
          setPreviewEssayIndex(null);
        }}
        textJson={portfolioEssays[previewEssayIndex]?.textJson}
        title={t('Application essay preview')}
      />
      {isEditMode && (
        <PortfolioCreateEssayDialog
          essay={portfolioEssays[editingEssayIndex]}
          isOpen={isCreateDialogOpen}
          onClose={() => {
            setIsCreateDialogOpen(false);
            setEditingEssayIndex(null);
          }}
          onDelete={handleDelete}
          onSave={handleSave}
        />
      )}
    </Box>
  );
};

PortfolioEssaysSection.propTypes = {
  config: portfolioSectionConfig(PropTypes.instanceOf(Object)),
  sectionIndex: PropTypes.number.isRequired,
  isLastSection: PropTypes.number.isRequired,
};

PortfolioEssaysSection.defaultProps = {
  config: {},
};

export default PortfolioEssaysSection;
