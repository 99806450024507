import React from 'react';
import PropTypes from 'prop-types';

import { STTTextField } from '../../../../moleculas';
import { InputsValidationRules } from '../../../../../constants/enums';
import { loginAllowedFilterRE } from '../../../../../constants/regexps';
import { ClubKeys } from '../../../../../constants/club-hub';

const ClubName = ({ error, label, onChange, outlined, value }) => (
  <STTTextField
    error={error}
    fullWidth
    gaLabel="Club name"
    helperText={error}
    inputRE={loginAllowedFilterRE}
    label={label}
    maxLength={InputsValidationRules.MAX_INPUT_LENGTH}
    name={ClubKeys.CLUB_NAME}
    onChange={(e) => onChange({ [ClubKeys.CLUB_NAME]: e.target.value })}
    outlined={outlined}
    required
    value={value}
  />
);

ClubName.propTypes = {
  error: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  outlined: PropTypes.bool,
  value: PropTypes.string.isRequired,
};

ClubName.defaultProps = {
  error: null,
  outlined: false,
};
export default ClubName;
