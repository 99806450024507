import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Container, Grid, useMediaQuery, useTheme } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { Button, Typography } from '../../../../../atoms';
import { UserContext } from '../../../../../../context';
import { RolesMap } from '../../../../../../constants/enums';
import { trainAyoOpportunitiesRoute } from '../../../../../../constants/routes';
import { ReactComponent as OpportunitiesHowItWorksBoyAndGirlIllustration } from '../../../../../../resources/images/opportunities/landing-page/opportunities-boy-and-girl.svg';
import { ReactComponent as OpportunitiesHowItWorksBoyIllustration } from '../../../../../../resources/images/opportunities/landing-page/opportunities-boy.svg';
import { ReactComponent as OpportunitiesHowItWorksChoiceDesktopIllustration } from '../../../../../../resources/images/opportunities/landing-page/opportunities-choice-desktop.svg';
import { ReactComponent as OpportunitiesHowItWorksChoiceMobileIllustration } from '../../../../../../resources/images/opportunities/landing-page/opportunities-choice-mobile.svg';
import { ReactComponent as OpportunitiesHowItWorksGirlIllustration } from '../../../../../../resources/images/opportunities/landing-page/opportunities-girl.svg';
import { ReactComponent as OpportunitiesHowItWorksKeepItDesktopIllustration } from '../../../../../../resources/images/opportunities/landing-page/opportunities-keep-it-desktop.svg';
import { ReactComponent as OpportunitiesHowItWorksKeepItMobileIllustration } from '../../../../../../resources/images/opportunities/landing-page/opportunities-keep-it-mobile.svg';
import { ReactComponent as OpportunitiesHowItWorksRevealDesktopIllustration } from '../../../../../../resources/images/opportunities/landing-page/opportunities-reveal-desktop.svg';
import { ReactComponent as OpportunitiesHowItWorksRevealMobileIllustration } from '../../../../../../resources/images/opportunities/landing-page/opportunities-reveal-mobile.svg';

const OpportunitiesHowItWorksSectionConfig = [
  {
    alt: 'A collection of mystery card of different colors under the light of projectors',
    description:
      'A board full of mystery cards of different colors and options is in your hands. Open them one by one to see which opportunities AYO has prepared for you this time',
    desktopIllustration: OpportunitiesHowItWorksRevealDesktopIllustration,
    mobileIllustration: OpportunitiesHowItWorksRevealMobileIllustration,
    title: 'Reveal a mystery',
  },
  {
    alt: 'A table with a green and a red buttons showing the voting process',
    description:
      'Have a glance at the card, flip it to learn more, and decide if you are in! Fancy reading the suggested book, trying new activity or starting another extracurricular? Select thumbs up. If it’s not to your taste—just click thumbs down and you won’t see the opportunity again',
    desktopIllustration: OpportunitiesHowItWorksChoiceDesktopIllustration,
    mobileIllustration: OpportunitiesHowItWorksChoiceMobileIllustration,
    title: 'Make your choice!',
  },
  {
    alt: 'Backstage of a talents show and opportunities depicted as stars',
    description:
      'This is how your collection grows. You can always access your opportunity cards from your profile or Train AYO and keep them on track. Browse through them to get inspiration or sharpen your skills',
    desktopIllustration: OpportunitiesHowItWorksKeepItDesktopIllustration,
    mobileIllustration: OpportunitiesHowItWorksKeepItMobileIllustration,
    title: 'Keep it with you',
  },
];

const OpportunitiesHowItWorksSection = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const isWidthUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const { state: userState } = useContext(UserContext);
  const isStudent = userState?.profile?.role === RolesMap.STUDENT;

  return (
    <Box className="ayo-opportunities-how-it-works-section">
      <Box className="ayo-opportunities-how-it-works-section__header">
        <Container>
          <Grid alignItems="flex-end" container direction="row" justifyContent="center">
            {isWidthUpMd && (
              <Grid item>
                <OpportunitiesHowItWorksGirlIllustration aria-label={t('A female student')} />
              </Grid>
            )}
            <Grid item md={5} xl={6} xs={12}>
              <Box
                alignItems="center"
                className="ayo-opportunities-how-it-works-section__header__text"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                mb={isWidthUpMd ? 0 : isWidthUpSm ? 5 : 15}
                mt={isWidthUpMd ? 0 : 5}
              >
                <Typography
                  align="center"
                  className="ayo-opportunities-page__content--typography-blue"
                  paragraph
                  variant="h2"
                >
                  {t('Are you a student wondering how the opportunities work in AYO?')}
                </Typography>
                <Typography variant="body1">
                  {t(isStudent ? 'See below or log into AYO to see!' : 'See below!')}
                </Typography>
                {isStudent && (
                  <Box mt={3}>
                    <Button
                      component={RouterLink}
                      fullWidth
                      gaLabel="Go to Train AYO"
                      to={trainAyoOpportunitiesRoute}
                      variant="primary"
                    >
                      {t('Go to Train AYO')}
                    </Button>
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item>
              {isWidthUpMd ? (
                <OpportunitiesHowItWorksBoyIllustration aria-label={t('A male student')} />
              ) : (
                <OpportunitiesHowItWorksBoyAndGirlIllustration />
              )}
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box
        className="ayo-opportunities-how-it-works-section__main-content"
        py={isWidthUpSm ? 15 : 5}
      >
        <Container>
          {OpportunitiesHowItWorksSectionConfig.map((sectionItem, index) => {
            const Illustration = isWidthUpSm
              ? sectionItem.desktopIllustration
              : sectionItem.mobileIllustration;
            return (
              <Grid
                key={sectionItem.title}
                alignItems="center"
                className="ayo-opportunities-how-it-works-section__main-content__block"
                container
                direction="row"
                justifyContent="center"
              >
                <Grid item md={4} sm={12} xl={6}>
                  <Box
                    display="flex"
                    flexDirection={isWidthUpSm ? 'row' : 'column'}
                    justifyContent="center"
                  >
                    <Box className="ayo-opportunities-how-it-works-section__main-content__circle">
                      <Typography
                        className="ayo-opportunities-page__content--typography-blue"
                        component="p"
                        variant="h2"
                      >
                        {index + 1}.
                      </Typography>
                    </Box>
                    <Box mb={isWidthUpMd ? 0 : 5} mt={isWidthUpSm ? 0 : 3} mx={isWidthUpSm ? 3 : 0}>
                      <Typography
                        className="ayo-opportunities-page__content--typography-blue"
                        component="h3"
                        paragraph
                        variant="h2"
                      >
                        {t(sectionItem.title)}
                      </Typography>
                      <Typography variant="body2">
                        <Trans components={{ br: <br /> }} i18nKey={sectionItem.description} />
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid>
                  <Illustration aria-label={t(sectionItem.alt)} />
                </Grid>
              </Grid>
            );
          })}
        </Container>
      </Box>
    </Box>
  );
};

export default OpportunitiesHowItWorksSection;
