import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Container, Grid, useMediaQuery, useTheme } from '@mui/material';

import { Image, Typography } from '../../../../../atoms';
import { LanguagesMap } from '../../../../../../constants/enums';
import { getNormalizedLanguageCode } from '../../../../../../utils';

const LessonPlannerHowItWorksSectionConfig = [
  {
    headerEl: 'Scheduling',
    bodyEl:
      'By creating a planner and setting up your semester and class schedules, you can effortlessly manage your classes and work ahead with a clear and organized calendar.',
    imageAlt: 'A desk calendar and a pen.',
    imageSrc: {
      [LanguagesMap.EN_US]: '/media/lesson-planner/how_it_works_section_scheduling_en.svg',
      [LanguagesMap.ES_US]: '/media/lesson-planner/how_it_works_section_scheduling_es.svg',
    },
    arrowImageSrc: '/media/lesson-planner/how_it_works_section_scheduling_arrow.svg',
  },
  {
    headerEl: 'TEKS',
    bodyEl: 'Before breaking ground, you should input the TEKS you plan to cover.',
    imageAlt: 'A notepad with checkmarks, some bricks, and a graduation hat.',
    imageSrc: '/media/lesson-planner/how_it_works_section_teks.svg',
    arrowImageSrc: '/media/lesson-planner/how_it_works_section_teks_arrow.svg',
  },
  {
    headerEl: 'Space for personal preparation',
    bodyEl:
      'In this section, you can lay the groundwork for your future lesson by outlining how you intend to cover the TEKS, drafting activities, and including any necessary materials for your personal use.',
    imageAlt: 'Some rolls of paper, a desk lamp, an open book, and a pinned note.',
    imageSrc: '/media/lesson-planner/how_it_works_section_preparation.svg',
    arrowImageSrc: '/media/lesson-planner/how_it_works_section_preparation_arrow.svg',
  },
  {
    headerEl: 'Content shareable with students',
    bodyEl:
      'Create exceptional academic artifacts for your students with the help of assignments synchronized to Google Classroom, an Agenda board, and personalized Choices paths.',
    imageAlt: 'A table with two chairs and two graduation hats.',
    imageSrc: '/media/lesson-planner/how_it_works_section_share.svg',
    arrowImageSrc: '/media/lesson-planner/how_it_works_section_share_arrow.svg',
  },
  {
    headerEl: 'Lessons repository',
    bodyEl:
      'Your lessons will be saved and can be easily accessed through your calendar and lessons repository.',
    imageAlt: 'A construction site with some rolled plans, a paint bucket, and a paintbrush.',
    imageSrc: '/media/lesson-planner/how_it_works_section_repository.svg',
  },
];

const LessonPlannerHowItWorksSection = () => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Box
      className="ayo-lesson-planner-landing__how-it-works-section"
      pb={isWidthUpSm ? 20 : 2}
      pt={isWidthUpSm ? 20 : 10}
    >
      <Container>
        <Grid container justifyContent="center">
          <Grid container direction="column" item md={8} xl={9} xs={12}>
            <Box mb={5}>
              <Typography align={isWidthUpSm ? 'center' : 'left'} component="h2" variant="h1">
                {t('How does it work?')}
              </Typography>
            </Box>
            {LessonPlannerHowItWorksSectionConfig.map((item, index) => {
              const isOddElement = index % 2 === 0;
              return (
                <Box key={item.headerEl}>
                  <Box
                    alignItems={isWidthUpSm ? 'center' : 'flex-start'}
                    display="flex"
                    flexDirection={
                      isOddElement && isWidthUpSm ? 'row' : isWidthUpSm ? 'row-reverse' : 'column'
                    }
                    mb={isWidthUpSm ? 0 : 7}
                  >
                    <Image
                      alt={t(item.imageAlt)}
                      className="ayo-lesson-planner-landing__how-it-works-section__image"
                      src={
                        item.imageSrc[LanguagesMap[getNormalizedLanguageCode(i18n.language)]] ||
                        item.imageSrc
                      }
                    />
                    <Box
                      ml={isOddElement && isWidthUpSm ? 16 : 0}
                      mr={!isOddElement && isWidthUpSm ? 16 : 0}
                      mt={isWidthUpSm ? 0 : 3}
                    >
                      <Box mb={1}>
                        <Typography component="h3" variant="h2">
                          {t(item.headerEl)}
                        </Typography>
                      </Box>
                      <Typography variant="body2">{t(item.bodyEl)}</Typography>
                    </Box>
                  </Box>
                  {item.arrowImageSrc && isWidthUpSm && (
                    <Box display="flex" justifyContent="center">
                      <Image src={item.arrowImageSrc} />
                    </Box>
                  )}
                </Box>
              );
            })}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default LessonPlannerHowItWorksSection;
