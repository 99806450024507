import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ActionsMenu, Button, Typography } from '../../../../../atoms';
import { Chip } from '../../../../../moleculas';
import {
  focusDialogCloseButton,
  formatTag,
  getClubFormValidation,
  isEmpty,
} from '../../../../../../utils';
import { useClubHubService } from '../../../../../../services';
import { ClubKeys } from '../../../../../../constants/club-hub';
import { ReactComponent as CreateIcon } from '../../../../../../resources/icons/create.svg';
import { ReactComponent as NavigationBackChevron } from '../../../../../../resources/icons/chevron_left.svg';
import ClubEditDialog from '../club-edit-dialog/ClubEditDialog';
import ClubDescription from '../../../club-hub-components/club-description/ClubDescription';
import { ClubTagsBlock } from '../../../club-hub-components/club-tags-dialog/ClubTagsDialog';

const ClubDetailsDescription = ({ isEditable, currentDescription, currentTags, onSave }) => {
  const { t } = useTranslation();

  const { getClubTags } = useClubHubService();

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isTagsDialogContent, setIsTagsDialogContent] = useState(false);

  const [newDescription, setNewDescription] = useState(currentDescription);
  const [error, setError] = useState('');

  const [tagsData, setTagsData] = useState(null);
  const [selectedTagsData, setSelectedTagsData] = useState(null);

  const resetTags = useCallback(() => {
    setSelectedTagsData({ ...tagsData });
  }, [tagsData]);

  const handleDeleteTag = useCallback((tag, tagGroup) => {
    setSelectedTagsData((prevState) => {
      const newState = {
        ...prevState,
        [tagGroup]: prevState[tagGroup].filter((item) => item !== tag),
      };

      if (isEmpty(newState[tagGroup])) delete newState[tagGroup];

      return newState;
    });
  }, []);

  const descriptionChangeHandler = useCallback(
    (change) => {
      const { description } = change;

      setNewDescription(description);

      if (error) {
        setError('');
      }
    },
    [error],
  );

  const descriptionSaveHandler = useCallback(() => {
    const { isFormValid, validationErrors } = getClubFormValidation(
      { [ClubKeys.DESCRIPTION]: newDescription },
      t,
    );

    setError(validationErrors[ClubKeys.DESCRIPTION]);

    if (isFormValid) {
      if (isTagsDialogContent) {
        onSave({ [ClubKeys.DESCRIPTION]: newDescription, [ClubKeys.TAGS]: selectedTagsData });
        setIsEditDialogOpen(false);
        setIsTagsDialogContent(false);
      } else {
        getClubTags(newDescription).then((data) => {
          if (!isEmpty(data)) {
            setTagsData(data);
            setIsTagsDialogContent(true);
          } else {
            onSave({ [ClubKeys.DESCRIPTION]: newDescription });
            setIsEditDialogOpen(false);
          }
        });
      }
    }
  }, [getClubTags, isTagsDialogContent, newDescription, onSave, selectedTagsData, t]);

  useEffect(() => {
    setSelectedTagsData({ ...tagsData });
  }, [tagsData]);

  return (
    <Box className="ayo-club-hub-details__description" p={3}>
      <Box display="flex" flexDirection="row" justifyContent="space-between" mb={2}>
        <Typography component="h3" variant="subtitle2">
          {t('Description')}
        </Typography>
        {isEditable && (
          <ActionsMenu
            id="club-hub-description-actions"
            menuItems={[
              {
                text: t('Edit'),
                icon: CreateIcon,
                handler: () => {
                  setIsEditDialogOpen(true);
                  setNewDescription(currentDescription);
                },
                id: 'club-description-edit',
                gaLabel: 'Edit',
              },
            ]}
          />
        )}
      </Box>
      <Typography mb={3} variant="body2">
        {currentDescription}
      </Typography>
      <Grid container spacing={1}>
        {Object.values(currentTags)
          .flat()
          .map((tag) => (
            <Grid key={tag} item>
              <Chip label={formatTag(tag)} />
            </Grid>
          ))}
      </Grid>
      <ClubEditDialog
        className={classNames(
          !isTagsDialogContent
            ? '!ayo-club-hub-details__edit-dialog__description'
            : 'ayo-club-hub-details__edit-dialog__tags',
        )}
        dialogContent={
          isTagsDialogContent ? (
            <>
              <Button
                className="ayo-edit-post-dialog__back-button"
                gaLabel="Back to previous step"
                onClick={() => {
                  setIsTagsDialogContent(false);
                  focusDialogCloseButton();
                }}
              >
                <NavigationBackChevron />
                {t('Back to description')}
              </Button>
              <Typography component="h2" mb={3} variant="h2">
                {t('Edit - About')}
              </Typography>
              <ClubTagsBlock
                allTagsData={tagsData}
                onDelete={handleDeleteTag}
                onReset={resetTags}
                selectedTagsData={selectedTagsData}
              />
            </>
          ) : (
            <Box>
              <Typography component="h3" mb={1} variant="subtitle2">
                {`${t('Description')}*`}
              </Typography>
              <ClubDescription
                error={error}
                onChange={descriptionChangeHandler}
                value={newDescription}
              />
            </Box>
          )
        }
        dialogTitle={isTagsDialogContent ? '' : t('Edit - About')}
        isOpen={isEditDialogOpen}
        onClose={() =>
          isTagsDialogContent
            ? (setIsTagsDialogContent(false), focusDialogCloseButton())
            : (setIsEditDialogOpen(false), setError(''))
        }
        onSave={descriptionSaveHandler}
        submitButtonTitle={isTagsDialogContent ? 'Save changes' : 'Next'}
      />
    </Box>
  );
};

ClubDetailsDescription.propTypes = {
  currentDescription: PropTypes.string.isRequired,
  currentTags: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  isEditable: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default ClubDetailsDescription;
