import React, { useEffect, useMemo, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  AchievementCard,
  DeleteItemWrapper,
  InitiativeEmptyStateBlock,
} from '../../../../moleculas';
import { PortfolioEntitiesData, PortfolioItemsToAddData } from '../../../../../utils';
import { portfolioSectionConfig } from '../../../../../constants/propTypes';
import { PortfolioSectionTypes } from '../../../../../constants/enums';
import { ReactComponent as AchievementsProfileImage } from '../../../../../resources/images/achievements_profile_image.svg';
import PortfolioSection from '../../components/portfolio-section/PortfolioSection';

const PortfolioAchievementsSection = ({ config, sectionIndex, isLastSection }) => {
  const [portfolioAchievements, setPortfolioAchievements] = useState([]);
  const { t } = useTranslation();
  const {
    addPortfolioItemHandler,
    deletePortfolioItemHandler,
    hasData,
    isEditMode,
    isVisible,
    metaData,
    reflectionHandler,
    sectionData,
    onUpdatePositionHandler,
  } = config;
  const { entities, reflection } = sectionData;

  useEffect(() => {
    setPortfolioAchievements(
      PortfolioEntitiesData[PortfolioSectionTypes.PORTFOLIO__ACHIEVEMENTS](
        entities,
        metaData,
        'name',
      ),
    );
  }, [entities, metaData]);

  const achievementsToAdd = useMemo(
    () =>
      PortfolioItemsToAddData[PortfolioSectionTypes.PORTFOLIO__ACHIEVEMENTS](
        entities,
        metaData,
        'name',
      ).map(({ name }) => ({ id: name, name })),
    [entities, metaData],
  );

  if (!isVisible) {
    return null;
  }

  return (
    <PortfolioSection
      body={
        <Box mt={3}>
          <Grid className="ayo-portfolio__achievements-section-content" container spacing={3}>
            {portfolioAchievements.map((achievement) => (
              <Grid key={achievement.name} item sm={4}>
                <DeleteItemWrapper
                  disabled={!isEditMode}
                  gaLabel="Remove achievement"
                  mainContent={
                    <AchievementCard
                      achievementData={achievement}
                      elevation={1}
                      tabIndex="0"
                      titleVariant="subtitle2"
                    />
                  }
                  onDelete={() => {
                    deletePortfolioItemHandler(
                      portfolioAchievements,
                      'name',
                      achievement.name,
                      sectionData,
                    );
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      }
      className={`${classNames({
        'ayo-portfolio__achievements-section': hasData,
      })}`}
      emptyState={
        !hasData && (
          <InitiativeEmptyStateBlock
            illustration={<AchievementsProfileImage alt={t('A hand holding a trophy')} />}
            title={t('No information about achievements and participation so far')}
          />
        )
      }
      isEditMode={isEditMode}
      isLastSection={isLastSection}
      itemsToAdd={achievementsToAdd}
      onAddItems={(itemsToAdd) =>
        addPortfolioItemHandler(metaData, itemsToAdd, 'name', sectionData)
      }
      onChangeReflection={reflectionHandler}
      onUpdatePositionHandler={onUpdatePositionHandler}
      reflection={reflection}
      sectionIndex={sectionIndex}
      sectionKey={PortfolioSectionTypes.PORTFOLIO__ACHIEVEMENTS}
      showActionsMenu={hasData && isEditMode}
      showAddButton={!!achievementsToAdd?.length && isEditMode}
      showAYOChip
      title={t('Achievements & participation')}
    />
  );
};

PortfolioAchievementsSection.propTypes = {
  config: portfolioSectionConfig(
    PropTypes.arrayOf(
      PropTypes.shape({
        category: PropTypes.string,
        name: PropTypes.string,
        year: PropTypes.arrayOf(PropTypes.number),
      }),
    ),
  ),
  sectionIndex: PropTypes.number.isRequired,
  isLastSection: PropTypes.number.isRequired,
};

PortfolioAchievementsSection.defaultProps = {
  config: {},
};

export default PortfolioAchievementsSection;
