import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DialogContent, Box, useTheme, useMediaQuery } from '@mui/material';

import { Dialog, DialogTitle, Typography } from '../../atoms';
import { PrivacyPolicyContent } from '../../moleculas';

const PrivacyPolicyDialog = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Dialog isTextDialog onClose={onClose} open={isOpen}>
      <DialogTitle disableTypography>
        <Box mb={isWidthUpSm ? 7 : 3}>
          <Typography component="h1" paragraph variant="h1">
            {t('Privacy Policy')}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <PrivacyPolicyContent fullWidth />
      </DialogContent>
    </Dialog>
  );
};

PrivacyPolicyDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PrivacyPolicyDialog;
