import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Box, Divider } from '@mui/material';

import { Checkbox, FormControlLabel, Radio, Typography } from '../../../atoms';
import { HighlightsInitiatives, StudentFiltersSpecialValues } from '../../../../constants/enums';
import {
  getFilterValueParts,
  isSimpleFilterSelected,
  updateSimpleFilter,
  updatePendingFilter,
  StudentFiltersStrategy,
  isFilterItemDisabled,
} from '../../../../utils';
import DisabledControlWithTooltip from '../../tooltip-wrappers/disabled-control-with-tooltip/DisabledControlWithTooltip';

const GoalsFilters = ({ menuFilters, setMenuFilters, stats, totalStudentsCount }) => {
  const goalsStats = useMemo(() => {
    const goalsStatsObject = { ...stats.goals };
    delete goalsStatsObject[StudentFiltersSpecialValues.PENDING];
    return goalsStatsObject;
  }, [stats]);

  const { filterCriterion, filtersList } = getFilterValueParts(menuFilters, stats);

  const onCheckboxChange = useCallback(
    (e) => {
      const newFilters = updateSimpleFilter(
        e.target.value,
        HighlightsInitiatives.GOALS,
        menuFilters,
        Object.keys(goalsStats),
        filtersList,
      );
      setMenuFilters(newFilters);
    },
    [filtersList, goalsStats, menuFilters, setMenuFilters],
  );

  const onPendingGoalsChange = useCallback(() => {
    const newFilters = updatePendingFilter(menuFilters, HighlightsInitiatives.GOALS, stats);
    setMenuFilters(newFilters);
  }, [stats, menuFilters, setMenuFilters]);

  const { t } = useTranslation();

  const GoalsFiltersStrategy = StudentFiltersStrategy[HighlightsInitiatives.GOALS];
  return (
    <Box>
      <Typography paragraph variant="subtitle2">
        {t('Distribution by statuses')}
      </Typography>
      <Box className="filters-controls-box" display="flex" flexDirection="column">
        {Object.entries(goalsStats).map(([goalType, statValue]) => (
          <DisabledControlWithTooltip
            key={goalType}
            showTooltip={isFilterItemDisabled(statValue, totalStudentsCount)}
            title={
              <>
                {GoalsFiltersStrategy.getFilterDisplayName(
                  filterCriterion,
                  t(`analytics.highlights.${goalType}`),
                  statValue,
                )}
                <br />
                {statValue === totalStudentsCount && t("Can't filter by all students")}
                {statValue === 0 && t("Can't filter by 0 students")}
              </>
            }
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={isSimpleFilterSelected(
                    goalType,
                    HighlightsInitiatives.GOALS,
                    menuFilters.filterCategory,
                    filtersList,
                  )}
                  disabled={isFilterItemDisabled(statValue, totalStudentsCount)}
                  gaLabel={`Student Filters - Goals - ${goalType}`}
                  onChange={onCheckboxChange}
                  value={goalType}
                />
              }
              label={`${t(`analytics.highlights.${goalType}`)} (${statValue})`}
            />
          </DisabledControlWithTooltip>
        ))}
        <Divider />
        {stats && (
          <DisabledControlWithTooltip
            showTooltip={isFilterItemDisabled(
              stats.goals[StudentFiltersSpecialValues.PENDING],
              totalStudentsCount,
            )}
            title={
              <>
                {GoalsFiltersStrategy.getFilterDisplayName(
                  filterCriterion,
                  t(`analytics.highlights.${StudentFiltersSpecialValues.PENDING}`),
                  stats.goals[StudentFiltersSpecialValues.PENDING],
                )}
                <br />
                {stats.goals[StudentFiltersSpecialValues.PENDING] === totalStudentsCount &&
                  t("Can't filter by all students")}
                {stats.goals[StudentFiltersSpecialValues.PENDING] === 0 &&
                  t("Can't filter by 0 students")}
              </>
            }
          >
            <FormControlLabel
              control={
                <Radio
                  checked={isSimpleFilterSelected(
                    StudentFiltersSpecialValues.PENDING,
                    HighlightsInitiatives.GOALS,
                    menuFilters.filterCategory,
                    filtersList,
                  )}
                  disabled={isFilterItemDisabled(
                    stats.goals[StudentFiltersSpecialValues.PENDING],
                    totalStudentsCount,
                  )}
                  gaLabel={`Student Filters - Goals - ${StudentFiltersSpecialValues.PENDING}`}
                  onChange={onPendingGoalsChange}
                  value={StudentFiltersSpecialValues.PENDING}
                />
              }
              label={`${t(
                `analytics.highlights.${StudentFiltersSpecialValues.PENDING}`,
              )} (${GoalsFiltersStrategy.getStatValue(
                stats,
                StudentFiltersSpecialValues.PENDING,
              )})`}
            />
          </DisabledControlWithTooltip>
        )}
      </Box>
    </Box>
  );
};

GoalsFilters.propTypes = {
  menuFilters: PropTypes.instanceOf(Object).isRequired,
  setMenuFilters: PropTypes.func.isRequired,
  stats: PropTypes.instanceOf(Object).isRequired,
  totalStudentsCount: PropTypes.number.isRequired,
};

export default GoalsFilters;
