import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { DialogContent, useTheme, useMediaQuery, DialogActions, Box, Grid } from '@mui/material';

import { Dialog, DialogTitle, Link, Tooltip, Typography } from '../../../../../../atoms';
import { Chip, PopupActionsButtons, SearchField } from '../../../../../../moleculas';
import { LessonPlannerContext } from '../../../../../../../context';
import { plugRoute } from '../../../../../../../constants/routes';
import { lastSymbolDotRE, teksAllowedFilterRE } from '../../../../../../../constants/regexps';
import { ReactComponent as PlusIcon } from '../../../../../../../resources/icons/plus.svg';
import { ReactComponent as CloseIcon } from '../../../../../../../resources/icons/close.svg';
import { focusDialogCloseButton, GA } from '../../../../../../../utils';
import { GaActions, GaCategories } from '../../../../../../../constants/enums';
import TeksBlock from '../teks-block/TeksBlock';

const SelectedTeksBlock = ({ teks, onDelete }) => {
  const { t } = useTranslation();
  return (
    <Box>
      {teks.length ? (
        <>
          <Typography variant="subtitle2">{t('Added(TEKS)')}</Typography>
          <Grid className="ayo-teks-dialog__chips" container spacing={1}>
            {teks.map((teksData, index) => (
              <Tooltip
                key={`${teksData.display}-${index}`}
                enterTouchDelay={0}
                leaveTouchDelay={5000}
                title={
                  <Trans
                    components={{ b: <b /> }}
                    i18nKey="TEKS chip label"
                    values={{ teksDataDisplay: teksData.display, teksDataText: teksData.text }}
                  />
                }
              >
                <Grid item xs={4}>
                  <Chip
                    ariaLabel={t('Remove item by pressing delete', {
                      item: `${teksData.display} - ${teksData.text}`,
                    })}
                    className="ayo-teks-dialog__chip"
                    deleteIcon={<CloseIcon />}
                    gaLabel="Remove TEKS"
                    label={
                      <Trans
                        components={{ b: <b /> }}
                        i18nKey="TEKS chip label"
                        values={{ teksDataDisplay: teksData.display, teksDataText: teksData.text }}
                      />
                    }
                    onDelete={() => onDelete(teksData)}
                  />
                </Grid>
              </Tooltip>
            ))}
          </Grid>
        </>
      ) : (
        <Typography variant="body2">
          {t('No added items yet. Search or manually browse for the TEKS to add.')}
        </Typography>
      )}
    </Box>
  );
};

SelectedTeksBlock.propTypes = {
  teks: PropTypes.arrayOf(
    PropTypes.shape({
      display: PropTypes.string,
      text: PropTypes.string,
    }),
  ),
  onDelete: PropTypes.func.isRequired,
};

SelectedTeksBlock.defaultProps = {
  teks: [],
};

const TeksDialog = ({ isOpen, onClose, onSave, selectedTeks }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { state: lessonPlannerState } = useContext(LessonPlannerContext);
  const { lessonData } = lessonPlannerState;
  const [searchValue, setSearchValue] = useState(null);
  const [searchedTeks, setSearchedTeks] = useState(null);
  const [innerSelectedTeks, setInnerSelectedTeks] = useState([]);
  const [allTeks, setAllTeks] = useState([]);
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const searchFieldRef = useRef();

  useEffect(() => {
    if (lessonData) {
      setInnerSelectedTeks(selectedTeks || lessonData.teksData);
      setAllTeks(lessonPlannerState.teksData?.all);
    }
  }, [isOpen, lessonData, lessonPlannerState, selectedTeks]);

  const notSelectedTeks = useMemo(() => {
    const result = allTeks
      .filter((allTeksInstance) =>
        innerSelectedTeks.every(
          (selectedTeksInstance) =>
            selectedTeksInstance.display !== allTeksInstance.display ||
            selectedTeksInstance.text !== allTeksInstance.text,
        ),
      )
      .sort((a, b) => a.display.localeCompare(b.display));
    return result;
  }, [innerSelectedTeks, allTeks]);

  const onCloseHandler = useCallback(() => {
    onClose();
    searchFieldRef.current.clearSearch();
  }, [onClose]);

  const addTeks = useCallback((newTeks) => {
    setInnerSelectedTeks((prevState) => [...prevState, newTeks]);
    focusDialogCloseButton();
  }, []);

  const onSearch = useCallback(
    (value) => {
      if (value) {
        const trimmedSearchValue = value.replace(lastSymbolDotRE, '');
        setSearchValue(trimmedSearchValue);
        if (trimmedSearchValue) {
          setSearchedTeks(
            notSelectedTeks.filter(
              (teks) =>
                teks.display.toLowerCase().includes(trimmedSearchValue.toLowerCase()) ||
                teks.text.toLowerCase().includes(trimmedSearchValue.toLowerCase()),
            ),
          );
        }
      } else {
        setSearchValue(null);
        setSearchedTeks(null);
      }
    },
    [notSelectedTeks],
  );

  const deleteTeks = useCallback(
    (teksToDelete) => {
      setInnerSelectedTeks((prevState) =>
        prevState.filter(
          (selectedTeksItem) =>
            !(
              selectedTeksItem.display === teksToDelete.display &&
              selectedTeksItem.text === teksToDelete.text
            ),
        ),
      );
      if (searchValue) {
        onSearch(searchValue);
      }
      GA.logInteraction({
        category: GaCategories.BEHAVIOR,
        action: GaActions.CHIP_DELETE_CLICK,
        label: 'Remove TEKS chip',
      });
      focusDialogCloseButton();
    },
    [searchValue, onSearch],
  );

  const onSaveHandler = useCallback(() => {
    onSave(innerSelectedTeks, true);
    onCloseHandler();
  }, [innerSelectedTeks, onCloseHandler, onSave]);

  const resetSearch = useCallback(() => {
    searchFieldRef.current.clearSearch();
  }, []);

  const plusConfig = {
    ariaLabel: 'Add TEKS',
    gaLabel: 'Add TEKS',
    icon: <PlusIcon />,
    onClick: addTeks,
  };

  useEffect(() => {
    if (searchValue) {
      onSearch(searchValue);
    }
  }, [searchValue, onSearch]);

  return (
    <>
      {isWidthUpSm && (
        <Dialog className="ayo-teks-dialog" isTextDialog onClose={onCloseHandler} open={isOpen}>
          <DialogTitle disableTypography>
            <Typography className="ayo-teks-dialog__title" variant="h2">
              {t('Add TEKS')}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Box>
              <SelectedTeksBlock onDelete={deleteTeks} teks={innerSelectedTeks} />
              <Grid container>
                <Grid className="ayo-teks-dialog__search-field" item xs={6}>
                  <SearchField
                    apiRef={searchFieldRef}
                    gaLabel="TEKS Search"
                    inputRE={teksAllowedFilterRE}
                    label={t('Search by the keywords or number of the TEKS')}
                    minInputLength={2}
                    noOptionsText
                    onSearch={onSearch}
                    searchOnChange
                  />
                </Grid>
              </Grid>
              {searchValue && !!searchedTeks?.length && (
                <Box>
                  <Typography paragraph variant="body2">
                    <Trans
                      components={{ b: <b /> }}
                      i18nKey="search result text"
                      values={{ count: searchedTeks?.length, searchValue }}
                    />
                  </Typography>
                </Box>
              )}
            </Box>
            <TeksBlock
              btnConfig={plusConfig}
              searchValue={searchValue}
              teksData={searchedTeks || notSelectedTeks}
            />
            {searchValue && !searchedTeks?.length && (
              <>
                <Typography paragraph variant="body2">
                  <Trans
                    components={{ b: <b /> }}
                    i18nKey="AYO couldn’t find any results containing searchValue. Please check your spelling and repeat your search"
                    values={{ searchValue }}
                  />
                </Typography>
                <Link
                  component="button"
                  gaLabel="Reset the search"
                  href={plugRoute}
                  onClick={resetSearch}
                >
                  {t('Reset the search and show the list of all TEKS')}
                </Link>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Grid container>
              <Grid item sm={6}>
                <PopupActionsButtons
                  className="ayo-teks-dialog__buttons"
                  primaryButtonGaLabel="Save changes"
                  primaryButtonHandler={onSaveHandler}
                  primaryButtonText={t('Save changes')}
                  secondaryButtonGaLabel="Cancel"
                  secondaryButtonHandler={onCloseHandler}
                  secondaryButtonText={t('Cancel')}
                  xsGridBreakpoint={9}
                />
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

TeksDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  selectedTeks: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
};

TeksDialog.defaultProps = {
  selectedTeks: null,
};

export default TeksDialog;
