import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { Tooltip, Typography } from '../../atoms';
import { ReactComponent as InfoIcon } from '../../../resources/icons/info.svg';

const InformationalCaption = ({
  isLight,
  showTooltip,
  title,
  tooltipMaxWidth,
  tooltipPlacement,
  withoutTranslation,
}) => {
  const { t } = useTranslation();

  return (
    <Box
      className={classnames('ayo-informational-caption', {
        'ayo-informational-caption--light': isLight,
      })}
    >
      <Tooltip
        enterTouchDelay={0}
        isDisabled={!showTooltip}
        leaveTouchDelay={5000}
        placement={tooltipPlacement}
        PopperProps={{
          style: {
            maxWidth: tooltipMaxWidth || 'inherit',
          },
        }}
        title={withoutTranslation ? title : t(title)}
      >
        {!showTooltip ? (
          <Box display="flex" flexDirection="row">
            <Box mr={1.25} pt={0.4}>
              <InfoIcon />
            </Box>
            <Typography isLabel isLightText={isLight} variant="body2">
              {withoutTranslation ? title : t(title)}
            </Typography>
          </Box>
        ) : (
          <InfoIcon aria-label={withoutTranslation ? title : t(title)} tabIndex="0" />
        )}
      </Tooltip>
    </Box>
  );
};

InformationalCaption.propTypes = {
  isLight: PropTypes.bool,
  showTooltip: PropTypes.bool,
  title: PropTypes.string.isRequired,
  tooltipMaxWidth: PropTypes.number,
  tooltipPlacement: PropTypes.string,
  withoutTranslation: PropTypes.bool,
};

InformationalCaption.defaultProps = {
  isLight: false,
  showTooltip: false,
  tooltipMaxWidth: null,
  tooltipPlacement: 'bottom-start',
  withoutTranslation: false,
};

export default InformationalCaption;
