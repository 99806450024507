/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { AccordionDetails, useControlled } from '@mui/material';

import { Accordion, AccordionSummary } from '../../atoms';

const AccordionItem = ({
  bodyEl,
  className,
  expanded: expandedProp,
  gaLabel,
  headerEl,
  iconCollapse,
  iconExpand,
  isDefaultExpanded,
  summaryLabel,
  onChange: onChangeProp,
  ...rest
}) => {
  const [expanded, setExpanded] = useControlled({
    controlled: expandedProp,
    default: isDefaultExpanded,
    name: 'Accordion',
    state: 'expanded ',
  });

  const onChange = useCallback(() => setExpanded((prevExpanded) => !prevExpanded), [setExpanded]);

  return (
    <Accordion
      className={className}
      expanded={expanded}
      gaLabel={gaLabel}
      onChange={onChangeProp || onChange}
      {...rest}
    >
      <AccordionSummary
        ariaLabel={summaryLabel}
        iconCollapse={iconCollapse}
        iconExpand={iconExpand}
      >
        {headerEl}
      </AccordionSummary>
      <AccordionDetails>{expanded && bodyEl}</AccordionDetails>
    </Accordion>
  );
};

AccordionItem.propTypes = {
  bodyEl: PropTypes.node.isRequired,
  className: PropTypes.string,
  expanded: PropTypes.bool,
  headerEl: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  gaLabel: PropTypes.string,
  iconCollapse: PropTypes.node,
  iconExpand: PropTypes.node,
  isDefaultExpanded: PropTypes.bool,
  summaryLabel: PropTypes.string,
  onChange: PropTypes.func,
};

AccordionItem.defaultProps = {
  className: '',
  expanded: undefined,
  gaLabel: '',
  iconCollapse: undefined,
  iconExpand: undefined,
  isDefaultExpanded: false,
  summaryLabel: null,
  onChange: null,
};

export default AccordionItem;
