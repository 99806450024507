import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, DialogActions, DialogContent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Dialog, DialogTitle, Typography } from '../../../../../atoms';
import { PopupActionsButtons } from '../../../../../moleculas';
import { AttachmentsResourcesTypes, PublicationStatuses } from '../../../../../../constants/enums';
import RichTextEditor from '../../../../rich-text-editor/RichTextEditor';
import { getFirstTextFromEditor } from '../../../../../../utils';
import { useStudentsService } from '../../../../../../services';

const EditorConfig = {
  FORMATTING_OPTIONS: ['headers', 'alignment', 'styling', 'lists', 'links', 'images'],
  DEFAULT_DATA: {
    textJson: '{"ops":[{"insert":"\\n"}]}',
    attachments: [],
    links: [],
    status: PublicationStatuses.DRAFT,
  },
};

const PortfolioCreateEssayDialog = ({ essay, isOpen, onClose, onDelete, onSave }) => {
  const { t } = useTranslation();
  const { postStudentEssay } = useStudentsService();

  const [newEssay, setNewEssay] = useState({ ...EditorConfig.DEFAULT_DATA });
  const [essayId, setEssayId] = useState(null);
  const [error, setError] = useState('');

  const essayRef = useRef(EditorConfig.DEFAULT_DATA);

  const handleChange = useCallback(
    (key, data) => {
      setNewEssay((prevState) => ({
        ...prevState,
        [key]: data,
      }));

      if (error) {
        setError('');
      }
    },
    [error],
  );

  const handleClose = useCallback(
    (isSave = false) => {
      if (!isSave && essayId && newEssay?.status === PublicationStatuses.DRAFT) {
        onDelete(essayId);
      }

      onClose();

      setNewEssay(EditorConfig.DEFAULT_DATA);
      setError('');
      setEssayId(null);
    },
    [essayId, newEssay?.status, onClose, onDelete],
  );

  const handleSave = useCallback(() => {
    const isValid = !!getFirstTextFromEditor(newEssay.textJson);

    if (isValid) {
      const essayToSave = {
        ...newEssay,
        status: PublicationStatuses.PUBLISHED,
      };

      onSave(essayToSave, essayId);
      handleClose(true);
    } else {
      setError(t('You have to input some text here to save your essay.'));
    }
  }, [newEssay, onSave, essayId, handleClose, t]);

  const handleBeforeUpload = useCallback(() => {
    if (essayId) {
      return;
    }

    postStudentEssay(essayRef.current, true).then((data) => {
      setEssayId(data.id);
    });
  }, [essayId, postStudentEssay]);

  useEffect(() => {
    if (essay) {
      setNewEssay(() => ({
        textJson: essay.textJson,
        attachments: essay.attachments,
        links: essay.links,
        status: essay.status,
      }));

      setEssayId(essay.id);
    }
  }, [essay, isOpen]);

  useEffect(() => {
    essayRef.current = newEssay;
  }, [newEssay]);

  return (
    <Dialog
      className="ayo-portfolio__create-essay-dialog ayo-portfolio__essay-background"
      gaLabel="Essay dialog"
      maxWidth={1032}
      onClose={() => handleClose()}
      open={isOpen}
      transitionDuration={{ exit: 0 }}
    >
      <DialogTitle disableTypography>
        <Box display="flex" flexDirection="column" rowGap={1}>
          <Typography component="h2" variant="subtitle1">
            {essay ? t('Edit application essay') : t('Application essay')}
          </Typography>
          <Typography variant="body2">
            {t(
              essay
                ? 'Here, you can edit your application essay.'
                : 'Here, you can create your application essay.',
            )}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box className="ayo-portfolio__create-essay-dialog__content" mt={3} p={3}>
          <Box mb={1}>
            <Typography component="h3" variant="subtitle1">
              {t(essay ? 'Edit essay' : 'Create new essay')}
            </Typography>
          </Box>
          <Box position="relative">
            <RichTextEditor
              attachments={newEssay?.attachments || []}
              bounds=".ayo-portfolio__create-essay-dialog__content"
              errorMsg={error}
              formattingOptions={EditorConfig.FORMATTING_OPTIONS}
              links={newEssay?.links}
              onBeforeImageUpload={handleBeforeUpload}
              onChange={(key, data) => handleChange(key, data)}
              resourceId={essayId}
              resourceType={AttachmentsResourcesTypes.PORTFOLIO_ESSAYS}
              value={newEssay?.textJson}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box mt={3} width="50%">
          <PopupActionsButtons
            primaryButtonGaLabel={essay ? 'Save changes' : 'Save'}
            primaryButtonHandler={handleSave}
            primaryButtonText={t(essay ? 'Save changes' : 'Save')}
            secondaryButtonGaLabel="Cancel"
            secondaryButtonHandler={() => handleClose()}
            secondaryButtonText={t('Cancel')}
          />
        </Box>
      </DialogActions>
    </Dialog>
  );
};

PortfolioCreateEssayDialog.propTypes = {
  essay: PropTypes.instanceOf(Object),
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

PortfolioCreateEssayDialog.defaultProps = {
  essay: null,
};

export default PortfolioCreateEssayDialog;
