import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Box, useTheme, useMediaQuery } from '@mui/material';

import { Typography } from '../../atoms';
import EditingButton from '../editing-button/EditingButton';
import { byteToMegaByteCoeff } from '../../../constants/values';
import { ReactComponent as RemoveIcon } from '../../../resources/icons/delete_primary.svg';
import { ReactComponent as SuccessIcon } from '../../../resources/icons/check_circle.svg';
import { ReactComponent as FailureIcon } from '../../../resources/icons/close_red.svg';

const FileListItem = ({ item, onRemove }) => {
  const { file, errors } = item;
  const { name, size, id } = file;
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <Box
      alignItems="start"
      className={classNames('ayo-fileupload__list-item', {
        [`ayo-fileupload__list-item--success`]: !errors.length,
        [`ayo-fileupload__list-item--error`]: errors.length,
      })}
      display="flex"
      flexDirection={isWidthUpSm ? 'row' : 'column'}
      justifyContent="space-between"
      mb={2}
      p={2}
    >
      <Box display="flex" flexDirection="column" mb={isWidthUpSm ? 0 : 2}>
        <Typography variant="subtitle2">
          <span>{name.substring(0, name.lastIndexOf('.'))}</span>
          <span>{name.substring(name.lastIndexOf('.'), name.length)}</span>
          <span>{id ? '' : `(${Math.round(size / byteToMegaByteCoeff) || 1} mb)`}</span>
        </Typography>
        {!id && (
          <Box>
            {!errors.length ? (
              <Box display="flex" pt={1}>
                <SuccessIcon />
                <Typography isLabel variant="body2">
                  {t('Ready for upload')}
                </Typography>
              </Box>
            ) : (
              <Box display="flex" pt={1}>
                <FailureIcon />
                <Typography isLabel variant="body2">
                  {errors[0]}
                </Typography>
              </Box>
            )}
          </Box>
        )}
      </Box>
      {!errors.length && (
        <EditingButton
          gaLabel="File Upload - Remove attachment"
          icon={<RemoveIcon />}
          iconPosition="end"
          onClick={() => onRemove(item)}
          text={t('Remove')}
        />
      )}
    </Box>
  );
};

FileListItem.propTypes = {
  item: PropTypes.shape({
    file: PropTypes.shape({
      name: PropTypes.string,
      size: PropTypes.number,
      id: PropTypes.number,
    }),
    errors: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default FileListItem;
