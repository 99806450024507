import { useCallback, useContext, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';

import { LessonPlannerActions, LessonPlannerContext, UserContext } from '../../context';
import { useTeachersService } from '../../services';
import {
  LessonPlannerStates,
  LessonsScheduleSettings,
  PublicationStatuses,
  LogLevelsMap,
  SchoolLevels,
} from '../../constants/enums';
import { PeriodsColors } from '../../constants/lesson-planner';
import { scheduleTypesConfig } from '../../constants/configs';
import { assignmentsSorter, loopDateRange } from '../../utils';
import useLessonsService from '../../services/lessons/useLessonsService';
import { StatusCodeMap } from '../../services/HttpClient';
import useSnackbar from '../use-snackbar/useSnackbar';

dayjs.extend(minMax);

const DEFAULT_ROTATION_COUNT = 2;

const filterPeriodsByDate = (courses, startDate, endDate) =>
  courses.filter(
    ({ periodStartDate, periodEndDate }) =>
      !dayjs
        .max(dayjs(startDate), dayjs(periodStartDate))
        .isAfter(dayjs.min(dayjs(endDate), dayjs(periodEndDate)), 'day'),
  );

const filterEntityBySemester = (entity, year, semester) =>
  entity.filter(({ semesterBySchoolYear }) => semesterBySchoolYear[year]?.includes(semester));

const defaultPeriodsData = (periods) =>
  periods.map((period, index) => ({ ...period, orderNumber: index }));

const classesSorter = (a, b) => a.orderNumber - b.orderNumber;

const getAvailableSemester = (semestersConfig) => {
  const semesters = Object.entries(semestersConfig)
    .filter(([, value]) => !dayjs(value.end).isBefore(dayjs(), 'day'))
    .map(([key]) => key);
  return semesters.length > 1 ? null : semesters.toString();
};

const useLessonPlannerData = () => {
  const match = useRouteMatch();
  const { scheduleId } = match.params;
  const { t } = useTranslation();
  const {
    putLessonScheduleCreationState,
    getLessonScheduleCreationState,
    postLessonSchedule,
    putLessonSchedule,
    putLessonPlannerClassDay,
    getPlannerDataById,
    getTeacherPeriods,
  } = useTeachersService();
  const {
    getSchoolsDaysConfig,
    postLesson,
    putLesson,
    postLessonAssignments,
    postAssignmentChoices,
    putAssignmentChoices,
    postLessonShare,
    getSchedulePeriods,
  } = useLessonsService();
  const { state: userState } = useContext(UserContext);
  const { state: lessonPlannerState, dispatch: dispatchLessonPlannerState } =
    useContext(LessonPlannerContext);

  const { setSnackBarStatus } = useSnackbar();

  const currentCampus = userState.profile?.currentCampus;
  const currentCampusSchoolLevel = userState.profile?.currentCampusSchoolLevel;

  const {
    lessonPlannerData,
    lessonPlanners,
    availablePeriods,
    teacherDatesData,
    pickerDates,
    lessonsMetadata,
    semestersDaysConfig,
    lessonData,
    scheduleAvailablePeriods,
  } = lessonPlannerState;

  const rotationDaysSchedule =
    lessonPlannerData?.stateData?.semestersSchedule[0][
      LessonsScheduleSettings.ROTATION_DAYS_SCHEDULE
    ];
  const rotationWeeksSchedule =
    lessonPlannerData?.stateData?.semestersSchedule[0][
      LessonsScheduleSettings.ROTATION_WEEKS_SCHEDULE
    ];

  const dispatchLessonPlannerData = useCallback(
    (data) => {
      dispatchLessonPlannerState({
        type: LessonPlannerActions.SET_LESSON_PLANNER_DATA,
        data,
      });
      if (data?.id) {
        const newLessonPlanners = [
          ...lessonPlanners.filter((planner) => planner.id !== data.id),
          data,
        ];
        dispatchLessonPlannerState({
          type: LessonPlannerActions.SET_LESSON_PLANNERS,
          data: newLessonPlanners,
        });
      }
    },
    [dispatchLessonPlannerState, lessonPlanners],
  );

  const setRotationDaysSchedule = useCallback(
    (rotationCount = DEFAULT_ROTATION_COUNT) => {
      const daysSchedule = [];
      const rotation = rotationCount > 1 ? rotationCount : 1;
      const scheduleConfig = scheduleTypesConfig.filter(
        ({ key }) => key === LessonsScheduleSettings.ROTATION_DAYS_SCHEDULE,
      )[0].values;
      Object.entries(scheduleConfig).forEach(([key, { value }]) => {
        if (key <= rotation) {
          daysSchedule.push({ rotationDay: value, coursesPeriods: null });
        }
      });
      return {
        daysSchedule,
        workingDays: rotationDaysSchedule?.workingDays || [],
      };
    },
    [rotationDaysSchedule?.workingDays],
  );

  const setRotationWeeksSchedule = useCallback(
    (rotationCount = DEFAULT_ROTATION_COUNT, currentWeeksSchedule = rotationWeeksSchedule) => {
      const weeksSchedule = [];
      const rotation = rotationCount > 1 ? rotationCount : 1;
      const scheduleConfig = scheduleTypesConfig.filter(
        ({ key }) => key === LessonsScheduleSettings.ROTATION_WEEKS_SCHEDULE,
      )[0].values;
      const workingDays = currentWeeksSchedule?.[0].workingDays || [];
      const defaultDaysSchedule = workingDays.map((day) => ({
        day,
        coursesPeriods: null,
      }));
      Object.entries(scheduleConfig).forEach(([key, { value }], index) => {
        const defaultWeekSchedule = {
          rotationWeekNumber: value,
          daysSchedule: defaultDaysSchedule,
          workingDays,
        };
        if (key <= rotation) {
          weeksSchedule.push(currentWeeksSchedule?.[index] || defaultWeekSchedule);
        }
      });
      return weeksSchedule;
    },
    [rotationWeeksSchedule],
  );

  const getLessonPlanners = useCallback(async () => {
    if (lessonPlanners) {
      return lessonPlanners;
    }
    const lessonPlannersData = await getLessonScheduleCreationState(
      userState.profile.currentCampus,
    );

    dispatchLessonPlannerState({
      type: LessonPlannerActions.SET_LESSON_PLANNERS,
      data: lessonPlannersData,
    });
    return lessonPlannersData;
  }, [
    dispatchLessonPlannerState,
    getLessonScheduleCreationState,
    lessonPlanners,
    userState.profile.currentCampus,
  ]);

  const setUpLessonPlannerData = useCallback(
    (isQuestTeacher) =>
      new Promise((resolve, reject) => {
        if (scheduleId) {
          getLessonPlanners().then((planners) =>
            getSchoolsDaysConfig().then((schoolsDaysConfig) => {
              const foundPlanner = planners.find((planner) => planner.id === +scheduleId);
              if (!foundPlanner) {
                reject();
              }
              dispatchLessonPlannerState({
                type: LessonPlannerActions.SET_SEMESTERS_DAYS_CONFIG,
                data: schoolsDaysConfig,
              });
              dispatchLessonPlannerData(foundPlanner);
              resolve();
            }),
          );
        } else {
          getLessonPlanners().then((planners) =>
            getSchoolsDaysConfig().then((schoolsDaysConfig) => {
              dispatchLessonPlannerState({
                type: LessonPlannerActions.SET_SEMESTERS_DAYS_CONFIG,
                data: schoolsDaysConfig,
              });
              const prePopulatedLessonPlannerName = `${t('Lesson planner for')} ${
                userState.profile.currentCampus
              }`;
              const plannerVersions = planners
                .map((planner) => {
                  const parenthesisRegex = /\(([0-9]*?)\)$/g;
                  const plannerName = planner.stateData.scheduleName;
                  const hasSameName = plannerName.startsWith(prePopulatedLessonPlannerName);
                  const versionNumber = parenthesisRegex.exec(plannerName)?.[1];
                  return hasSameName ? versionNumber ?? 0 : null;
                })
                .filter((plannerVersion) => plannerVersion !== null)
                .map(Number)
                .sort((a, b) => a - b);
              let currentPlannerVersion = 0;
              for (let i = 0; plannerVersions.includes(i); i += 1) {
                currentPlannerVersion = i + 1;
              }
              const plannerNameWithVersion = `${prePopulatedLessonPlannerName}${
                currentPlannerVersion ? ` (${currentPlannerVersion})` : ''
              }`;
              const currentSchoolYear = schoolsDaysConfig.schoolYear;
              const defaultSchedule = {
                [LessonsScheduleSettings.ROTATION_DAYS_SCHEDULE]:
                  !isQuestTeacher &&
                  (currentCampusSchoolLevel === SchoolLevels.MIDDLE ||
                    currentCampusSchoolLevel === SchoolLevels.HIGH)
                    ? setRotationDaysSchedule()
                    : null,
                [LessonsScheduleSettings.ROTATION_WEEKS_SCHEDULE]:
                  isQuestTeacher || currentCampusSchoolLevel === SchoolLevels.ELEMENTARY
                    ? setRotationWeeksSchedule()
                    : null,
              };
              const defaultLessonPlannerData = {
                state: LessonPlannerStates.CREATING_ROTATION_DATA,
                schoolName: currentCampus,
                stateData: {
                  [LessonsScheduleSettings.SCHEDULE_NAME]: plannerNameWithVersion,
                  [LessonsScheduleSettings.SCHOOL_YEAR]: currentSchoolYear,
                  [LessonsScheduleSettings.SCHOOL_NAME]: currentCampus,
                  [LessonsScheduleSettings.SEMESTERS]: [
                    {
                      ...defaultSchedule,
                      semester: getAvailableSemester(schoolsDaysConfig.semesters),
                    },
                  ],
                },
              };
              dispatchLessonPlannerData(defaultLessonPlannerData);
              resolve();
            }),
          );
        }
      }),
    [
      scheduleId,
      getLessonPlanners,
      getSchoolsDaysConfig,
      dispatchLessonPlannerState,
      dispatchLessonPlannerData,
      t,
      userState.profile.currentCampus,
      currentCampusSchoolLevel,
      setRotationDaysSchedule,
      setRotationWeeksSchedule,
      currentCampus,
    ],
  );

  const updateLessonPlanerCreationState = useCallback(
    ({ settingsKeys, settingsValues, state, snackBarData, withSave, id }) => {
      const newLessonPlannerData = {
        ...lessonPlannerData,
        state: state || lessonPlannerData.state,
        id: id || lessonPlannerData.id,
        stateData: settingsKeys
          ? {
              ...lessonPlannerData.stateData,
              ...Object.assign(...settingsKeys?.map((key, i) => ({ [key]: settingsValues[i] }))),
            }
          : lessonPlannerData.stateData,
      };
      dispatchLessonPlannerData(newLessonPlannerData);
      if (withSave) {
        putLessonScheduleCreationState(newLessonPlannerData, scheduleId)
          .then(() => {
            if (snackBarData) {
              setSnackBarStatus(snackBarData[LogLevelsMap.SUCCESS]);
            }
          })
          .catch(() => {
            if (snackBarData) {
              setSnackBarStatus(snackBarData[LogLevelsMap.ERROR]);
            }
          });
      }
      return newLessonPlannerData;
    },
    [
      dispatchLessonPlannerData,
      lessonPlannerData,
      putLessonScheduleCreationState,
      scheduleId,
      setSnackBarStatus,
    ],
  );

  const loaderText = useMemo(() => t('Preparing your schedule setup...'), [t]);

  const getAvailablePeriods = useCallback(async () => {
    if (availablePeriods[currentCampus]) {
      return availablePeriods[currentCampus].map((course) => {
        const returnedCourse = { ...course };
        delete returnedCourse.color;
        return returnedCourse;
      });
    }
    const periods = await getTeacherPeriods(currentCampus, loaderText);
    periods.sort();
    const periodsWithColors = periods.map((course, index) => ({
      ...course,
      color: PeriodsColors[index % PeriodsColors.length],
    }));
    dispatchLessonPlannerState({
      type: LessonPlannerActions.SET_AVAILABLE_PERIODS,
      data: { campus: currentCampus, periods: periodsWithColors },
    });
    return periods;
  }, [availablePeriods, dispatchLessonPlannerState, getTeacherPeriods, currentCampus, loaderText]);

  const getScheduleAvailablePeriods = useCallback(
    async (refetch = false) => {
      if (scheduleAvailablePeriods[scheduleId] && !refetch) {
        return scheduleAvailablePeriods[scheduleId].map((course) => {
          const returnedCourse = { ...course };
          delete returnedCourse.color;
          return returnedCourse;
        });
      }
      const allPeriods = await getAvailablePeriods();
      let schedulePeriods = [];
      if (scheduleId) {
        schedulePeriods = await getSchedulePeriods(scheduleId, loaderText);
      }
      const finalPeriods = allPeriods.map((schoolPeriod) => {
        const schedulePeriod =
          schedulePeriods.find((period) => period.periodName === schoolPeriod.periodName) || {};
        return { ...schoolPeriod, ...schedulePeriod };
      });
      finalPeriods.sort();
      const periodsWithColors = finalPeriods.map((course, index) => ({
        ...course,
        color: PeriodsColors[index % PeriodsColors.length],
      }));
      dispatchLessonPlannerState({
        type: LessonPlannerActions.SET_SCHEDULE_AVAILABLE_PERIODS,
        data: { scheduleId, periods: periodsWithColors },
      });
      return finalPeriods;
    },
    [
      scheduleAvailablePeriods,
      dispatchLessonPlannerState,
      getSchedulePeriods,
      loaderText,
      scheduleId,
      getAvailablePeriods,
    ],
  );

  const setUpCoursesSchedule = useCallback(
    (semester, schoolYear) => {
      const semesterData = lessonPlannerData.stateData.semestersSchedule.find(
        (stateSemester) => stateSemester.semester === semester,
      );
      const scheduleType = semesterData.rotationDaysSchedule
        ? LessonsScheduleSettings.ROTATION_DAYS_SCHEDULE
        : LessonsScheduleSettings.ROTATION_WEEKS_SCHEDULE;

      const hasInitiatedRotationDaysPeriods =
        semesterData.rotationDaysSchedule &&
        semesterData.rotationDaysSchedule.daysSchedule.every((day) => day.coursesPeriods);
      const hasInitiatedRotationWeeksPeriods =
        semesterData.rotationWeeksSchedule &&
        semesterData.rotationWeeksSchedule.every((week) =>
          week.daysSchedule.every((day) => day.coursesPeriods),
        );
      const hasInitiatedCourses =
        hasInitiatedRotationDaysPeriods || hasInitiatedRotationWeeksPeriods;

      if (hasInitiatedCourses) {
        semesterData.rotationDaysSchedule?.daysSchedule.sort((a, b) =>
          a.rotationDay.localeCompare(b.rotationDay),
        );
        getAvailablePeriods();
        return;
      }
      getAvailablePeriods().then((periods) => {
        const periodsForSemester = filterEntityBySemester(periods, schoolYear, semester);
        const scheduleData =
          scheduleType === LessonsScheduleSettings.ROTATION_DAYS_SCHEDULE
            ? {
                ...semesterData.rotationDaysSchedule,
                daysSchedule: semesterData.rotationDaysSchedule.daysSchedule.map((daySchedule) => ({
                  ...daySchedule,
                  coursesPeriods:
                    daySchedule.coursesPeriods ?? defaultPeriodsData(periodsForSemester),
                })),
              }
            : semesterData.rotationWeeksSchedule.map((weeksSchedule) => ({
                ...weeksSchedule,
                daysSchedule: weeksSchedule.daysSchedule.map((daySchedule) => ({
                  ...daySchedule,
                  coursesPeriods:
                    daySchedule.coursesPeriods ?? defaultPeriodsData(periodsForSemester),
                })),
              }));
        const semestersData = lessonPlannerData.stateData.semestersSchedule.map((stateSemester) =>
          stateSemester.semester === semester
            ? {
                ...stateSemester,
                [scheduleType]: scheduleData,
              }
            : stateSemester,
        );
        updateLessonPlanerCreationState({
          settingsKeys: [LessonsScheduleSettings.SEMESTERS],
          settingsValues: [semestersData],
          withSave: true,
        });
      });
    },
    [updateLessonPlanerCreationState, lessonPlannerData, getAvailablePeriods],
  );

  const setRotationDaysDaySchedule = useCallback(
    (periods, dayIndex, snackBarData, weekIndex, semester) => {
      const semesterSchedule = lessonPlannerData.stateData.semestersSchedule.find(
        (stateSemester) => stateSemester.semester === semester,
      ).rotationDaysSchedule;
      const scheduleData = {
        ...semesterSchedule,
        daysSchedule: Object.assign([...semesterSchedule.daysSchedule], {
          [dayIndex]: {
            ...semesterSchedule.daysSchedule[dayIndex],
            coursesPeriods: periods,
          },
        }),
      };
      updateLessonPlanerCreationState({
        settingsKeys: [LessonsScheduleSettings.SEMESTERS],
        settingsValues: [
          lessonPlannerData.stateData.semestersSchedule.map((stateSemester) =>
            stateSemester.semester === semester
              ? {
                  ...stateSemester,
                  rotationDaysSchedule: scheduleData,
                }
              : stateSemester,
          ),
        ],
        snackBarData,
        withSave: true,
      });
    },
    [updateLessonPlanerCreationState, lessonPlannerData],
  );

  const setRotationWeeksDaySchedule = useCallback(
    (periods, dayIndex, snackBarData, weekIndex, semester) => {
      const semesterSchedule = lessonPlannerData.stateData.semestersSchedule.find(
        (stateSemester) => stateSemester.semester === semester,
      ).rotationWeeksSchedule;
      const scheduleData = semesterSchedule.map((rotationWeek, rotationWeekIndex) =>
        rotationWeekIndex === weekIndex
          ? {
              ...rotationWeek,
              daysSchedule: rotationWeek.daysSchedule.map((day, index) =>
                index === dayIndex ? { ...day, coursesPeriods: periods } : day,
              ),
            }
          : rotationWeek,
      );
      const newSemesters = lessonPlannerData.stateData.semestersSchedule.map((stateSemester) =>
        stateSemester.semester === semester
          ? {
              ...stateSemester,
              rotationWeeksSchedule: scheduleData,
            }
          : stateSemester,
      );
      updateLessonPlanerCreationState({
        settingsKeys: [LessonsScheduleSettings.SEMESTERS],
        settingsValues: [newSemesters],
        snackBarData,
        withSave: true,
      });
    },
    [updateLessonPlanerCreationState, lessonPlannerData],
  );

  const saveLessonPlanerSchedule = useCallback(() => {
    const newLessonPlannerData = {
      ...lessonPlannerData,
      state: LessonPlannerStates.CREATED,
      isScheduleCreated: true,
    };
    const saveHttpMethod = lessonPlannerData.isScheduleCreated
      ? putLessonSchedule
      : postLessonSchedule;
    return new Promise((resolve, reject) => {
      saveHttpMethod(newLessonPlannerData.stateData, scheduleId, loaderText)
        .then(() => {
          dispatchLessonPlannerData(newLessonPlannerData);
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  }, [
    dispatchLessonPlannerData,
    lessonPlannerData,
    postLessonSchedule,
    scheduleId,
    putLessonSchedule,
    loaderText,
  ]);

  const isLessonPlannerNameUnique = useCallback(
    (name, id) => {
      const lessonPlanerWithName = lessonPlanners.find(
        (planner) => planner.stateData.scheduleName.trim() === name.trim(),
      );

      return !lessonPlanerWithName || (lessonPlanerWithName && lessonPlanerWithName.id === id);
    },
    [lessonPlanners],
  );

  const getDateData = useCallback(
    (plannerId, dateTime) => teacherDatesData[plannerId]?.[dateTime],
    [teacherDatesData],
  );

  const getDateRangeData = (plannerId, rangeStart, rangeEnd) => {
    const resultMap = {};

    loopDateRange(rangeStart, rangeEnd, (currentDate) => {
      if (currentDate.day() < 6 && currentDate.day() > 0) {
        const shortDate = currentDate.format('YYYY-MM-DD');
        resultMap[shortDate] = getDateData(plannerId, shortDate);
      }
    });

    return resultMap;
  };

  const loadDatesData = async (plannerId, startTime, endTime = startTime) => {
    // We do not load the data only if stored value is undefined
    // If value is null - the date is holiday
    let shouldLoadDateInfo = false;
    loopDateRange(startTime, endTime, (currentDate) => {
      if (getDateData(plannerId, currentDate.format('YYYY-MM-DD')) === undefined) {
        shouldLoadDateInfo = true;
      }
    });
    if (!shouldLoadDateInfo) return;
    const plannerDates = await getPlannerDataById(plannerId, startTime, endTime);
    plannerDates.daysSchedule.forEach((date) => {
      date.classes?.sort(classesSorter);
    });
    const datesMap = {};
    loopDateRange(startTime, endTime, (currentDate) => {
      const shortDate = currentDate.format('YYYY-MM-DD');
      datesMap[shortDate] =
        plannerDates.daysSchedule.find((date) => date.classDate === shortDate) ?? null;
    });
    dispatchLessonPlannerState({
      type: LessonPlannerActions.ADD_TEACHER_DATE_DATA,
      data: { plannerId, teacherDatesData: datesMap },
    });
    const { firstDayOfSchedule, lastDayOfSchedule } = plannerDates;
    dispatchLessonPlannerState({
      type: LessonPlannerActions.SET_PICKER_DATES,
      data: { plannerId, dates: { firstDayOfSchedule, lastDayOfSchedule } },
    });
  };

  const updateLessonInDateData = useCallback(
    (id, createdDate, title, status, classMetadata, isDelete = false) => {
      let editingScheduleId;

      const dateData = Object.entries(teacherDatesData).find(([key, value]) => {
        const editingClassDate = Object.values(value).find(
          (y) => !!y?.classes?.find((x) => x.classId === classMetadata.classId),
        );

        if (editingClassDate) {
          editingScheduleId = key;
        }

        return !!editingClassDate;
      })?.[1]?.[classMetadata.classDate];

      if (dateData) {
        const foundClass = dateData.classes.find((item) => item.classId === classMetadata.classId);

        const lessonIndex = foundClass.lessonsMetadata.findIndex((lesson) => lesson.id === id);

        const newLessonData = {
          id,
          title,
          status,
          // defensive in case backend response with `createdDate: null`
          createdDate: createdDate ?? new Date().toISOString(),
          classData: classMetadata,
        };

        const newLessonsMetadata =
          lessonIndex !== -1
            ? foundClass.lessonsMetadata
                .fill(!isDelete && newLessonData, lessonIndex, lessonIndex + 1)
                .filter(Boolean)
            : [...foundClass.lessonsMetadata, !isDelete && newLessonData].filter(Boolean);

        const newClass = {
          ...foundClass,
          lessonsMetadata: newLessonsMetadata,
        };

        const classIndex = dateData.classes.findIndex(
          (item) => item.classId === classMetadata.classId,
        );
        const newClasses = dateData.classes.fill(newClass, classIndex, classIndex + 1);

        const newDay = {
          ...dateData,
          classes: newClasses,
        };

        dispatchLessonPlannerState({
          type: LessonPlannerActions.ADD_TEACHER_DATE_DATA,
          data: {
            plannerId: scheduleId || editingScheduleId,
            teacherDatesData: { [newDay.classDate]: newDay },
          },
        });
      }
    },
    [teacherDatesData, dispatchLessonPlannerState, scheduleId],
  );

  const updateLessonsMetadata = useCallback(
    (id, title, status, classData, sharedWith, isDelete) => {
      const lessonIndex = lessonsMetadata.findIndex((lesson) => lesson.id === id);
      const updatedLesson = {
        ...lessonsMetadata[lessonIndex],
        id,
        title,
        status,
        classData,
        sharedWith,
      };

      const newLessonsMetadata =
        lessonIndex !== -1
          ? [...lessonsMetadata.fill(!isDelete && updatedLesson, lessonIndex, lessonIndex + 1)]
          : [...lessonsMetadata, updatedLesson];

      dispatchLessonPlannerState({
        type: LessonPlannerActions.SET_LESSONS_METADATA,
        data: newLessonsMetadata.filter(Boolean),
      });
    },
    [dispatchLessonPlannerState, lessonsMetadata],
  );

  const reorderLessonPlanner = useCallback(
    (plannerId, day) =>
      putLessonPlannerClassDay(plannerId, day)
        .then((updatedDateData) => {
          updatedDateData.classes?.sort(classesSorter);
          dispatchLessonPlannerState({
            type: LessonPlannerActions.ADD_TEACHER_DATE_DATA,
            data: {
              plannerId,
              teacherDatesData: { [day.classDate]: updatedDateData },
            },
          });

          setSnackBarStatus({
            text: t('The schedule for the day has been adjusted and saved'),
            type: 'success',
          });
        })
        .catch(() => {
          setSnackBarStatus({
            text: t('Failed to adjust and save the schedule'),
            type: 'error',
          });
        }),
    [putLessonPlannerClassDay, setSnackBarStatus, t, dispatchLessonPlannerState],
  );

  const markAsDayOffOrWorkingDay = useCallback(
    (plannerId, day) => {
      putLessonPlannerClassDay(plannerId, day)
        .then((updatedDateData) => {
          updatedDateData.classes?.sort(classesSorter);
          dispatchLessonPlannerState({
            type: LessonPlannerActions.ADD_TEACHER_DATE_DATA,
            data: { plannerId, teacherDatesData: { [day.classDate]: updatedDateData } },
          });

          getScheduleAvailablePeriods(true);

          if (day.isDayOff) {
            setSnackBarStatus({
              text: t('The day off has been marked'),
              type: 'success',
            });
          }
        })
        .catch(() => {
          if (day.isDayOff) {
            setSnackBarStatus({
              text: t('Failed to mark as a day off'),
              type: 'error',
            });
          }
        });
    },
    [
      putLessonPlannerClassDay,
      setSnackBarStatus,
      t,
      dispatchLessonPlannerState,
      getScheduleAvailablePeriods,
    ],
  );

  const saveLesson = useCallback(
    (body, assignments, choicesMap, lessonId, silent, snackbarData, onSuccess, onFail) => {
      const { status } = body;
      const wasLessonSaved = lessonId !== undefined;
      const lessonUpdateMethod = wasLessonSaved ? putLesson : postLesson;
      const lessonUpdateParams = wasLessonSaved ? [lessonId, body, silent] : [body, silent];
      const shouldUpdateAssignments = !!assignments?.length;

      const handleError = () => {
        if (onFail) onFail();
        setSnackBarStatus(snackbarData?.fail);
      };

      const saveChoices = (assignmentsData) =>
        new Promise((resolve, reject) => {
          const assignmentWithChoicesIds = assignmentsData
            .map((assignment) => assignment.id)
            .filter((assignmentId) => choicesMap[assignmentId]);
          let hasFailed = false;
          Promise.allSettled(
            assignmentWithChoicesIds.map((assignmentId) => {
              const saveMethod = choicesMap[assignmentId].id
                ? putAssignmentChoices
                : postAssignmentChoices;
              return saveMethod(lessonId, assignmentId, choicesMap[assignmentId], silent);
            }),
          ).then((newChoices) => {
            const updatedChoicesMap = newChoices.reduce(
              (acc, newChoice, idx) => {
                if (newChoice.status === 'fulfilled') {
                  const choiceAssignmentId = assignmentWithChoicesIds[idx];
                  acc[choiceAssignmentId] = newChoice.value;
                } else {
                  hasFailed = true;
                }
                return acc;
              },
              { ...choicesMap },
            );
            if (!hasFailed) {
              resolve(updatedChoicesMap);
            } else {
              reject(updatedChoicesMap);
            }
          });
        });

      if (status === PublicationStatuses.DELETED || !shouldUpdateAssignments) {
        lessonUpdateMethod(...lessonUpdateParams)
          .then((data) => {
            if (onSuccess) onSuccess(data, assignments, choicesMap);
            setSnackBarStatus(snackbarData?.success);
          })
          .catch(handleError);
        return;
      }
      if (status === PublicationStatuses.DRAFT) {
        lessonUpdateMethod(...lessonUpdateParams)
          .then((data) => {
            postLessonAssignments(data.id, assignments, silent)
              .then((assignmentsData) => {
                assignmentsData.sort(assignmentsSorter);
                saveChoices(assignmentsData)
                  .then((updatedChoicesMap) => {
                    if (onSuccess) onSuccess(data, assignmentsData, updatedChoicesMap);
                    setSnackBarStatus(snackbarData?.success);
                  })
                  .catch(handleError);
              })
              .catch(handleError);
          })
          .catch(handleError);
      }
      if (status === PublicationStatuses.PUBLISHED) {
        if (wasLessonSaved) {
          postLessonAssignments(lessonId, assignments, silent)
            .then((assignmentsData) => {
              lessonUpdateMethod(...lessonUpdateParams).then((data) => {
                assignmentsData.sort(assignmentsSorter);
                saveChoices(assignmentsData)
                  .then((updatedChoicesMap) => {
                    if (onSuccess) onSuccess(data, assignmentsData, updatedChoicesMap);
                    setSnackBarStatus(snackbarData?.success);
                  })
                  .catch(handleError);
              });
            })
            .catch(handleError);
        } else {
          saveLesson(
            { ...body, status: PublicationStatuses.DRAFT },
            assignments,
            choicesMap,
            lessonId,
            silent,
            snackbarData,
            (data, assignmentsData) => {
              saveLesson(body, assignmentsData, data.id, silent, snackbarData, onSuccess, onFail);
            },
            onFail,
          );
        }
      }
    },
    [
      postLesson,
      putLesson,
      setSnackBarStatus,
      postLessonAssignments,
      postAssignmentChoices,
      putAssignmentChoices,
    ],
  );

  const shareLesson = useCallback(
    (lessonId, sharedWith) => {
      const body = sharedWith.map((teacher) => teacher.id);

      postLessonShare(lessonId, body)
        .then((response) => {
          if (response.status === StatusCodeMap.OK) {
            setSnackBarStatus({
              text: t('The lesson access changes have been applied!'),
              type: 'success',
            });

            const updatedLessonMetadata = {
              ...lessonsMetadata.find((lesson) => lesson.id === +lessonId),
              sharedWith,
            };

            const newData = [
              ...lessonsMetadata.filter((lesson) => lesson.id !== +lessonId),
              updatedLessonMetadata,
            ];

            if (lessonData) {
              updatedLessonMetadata.classData = lessonData.classMetadata;

              const updatedLesson = { ...lessonData, sharedWith };

              dispatchLessonPlannerState({
                type: LessonPlannerActions.SET_LESSON_DATA,
                data: updatedLesson,
              });
            }

            dispatchLessonPlannerState({
              type: LessonPlannerActions.SET_LESSONS_METADATA,
              data: newData,
            });
          }
        })
        .catch(() => {
          setSnackBarStatus({
            text: t('Failed to apply the lesson access changes. Please try once more.'),
            type: 'error',
          });
        });
    },
    [
      dispatchLessonPlannerState,
      lessonData,
      lessonsMetadata,
      postLessonShare,
      setSnackBarStatus,
      t,
    ],
  );

  return {
    availablePeriods: availablePeriods[currentCampus],
    scheduleAvailablePeriods: scheduleAvailablePeriods[scheduleId],
    getScheduleAvailablePeriods,
    dispatchLessonPlannerData,
    lessonPlannerData,
    semestersDaysConfig,
    saveLessonPlanerSchedule,
    rotationDaysSchedule,
    rotationWeeksSchedule,
    scheduleId,
    setRotationDaysDaySchedule,
    setRotationDaysSchedule,
    setRotationWeeksDaySchedule,
    setRotationWeeksSchedule,
    setUpCoursesSchedule,
    setUpLessonPlannerData,
    updateLessonPlanerCreationState,
    isLessonPlannerNameUnique,
    getDateData,
    loadDatesData,
    updateLessonInDateData,
    updateLessonsMetadata,
    defaultPeriodsData,
    getAvailablePeriods,
    reorderLessonPlanner,
    markAsDayOffOrWorkingDay,
    pickerDates,
    getDateRangeData,
    saveLesson,
    assignmentsSorter,
    filterPeriodsByDate,
    filterEntityBySemester,
    shareLesson,
  };
};

export default useLessonPlannerData;
