import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Container, useMediaQuery, useTheme } from '@mui/material';

import { EndorsementsSection, PageWrapper } from '../../../organisms';
import { trainAyoRoute } from '../../../../constants/routes';
import { GoToStudentProfileBlock } from '../../../moleculas';
import { UserActions, UserContext } from '../../../../context';
import { OneTimeActionsMap } from '../../../../constants/enums';
import { useOneTimeActionService } from '../../../../services';
import { Typography } from '../../../atoms';

const EndorsementsPage = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  const { state: userState, dispatch: dispatchUserState } = useContext(UserContext);
  const { postOneTimeAction } = useOneTimeActionService();

  useEffect(() => {
    if (!userState.oneTimeActions.includes(OneTimeActionsMap.EXPLORE_ENDORSEMENTS_OPEN)) {
      postOneTimeAction(OneTimeActionsMap.EXPLORE_ENDORSEMENTS_OPEN);
      dispatchUserState({
        type: UserActions.SET_ONE_TIME_ACTION,
        data: OneTimeActionsMap.EXPLORE_ENDORSEMENTS_OPEN,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper backToLink={trainAyoRoute} backToText={t('Go to Train AYO')}>
      <Container>
        <Box>
          <Typography component="h1" paragraph variant="h1">
            {t('Endorsements')}
          </Typography>
          <Box maxWidth={isWidthUpSm ? '50%' : '100%'} mb={5}>
            <Typography variant="body2">
              {t(
                'Here, you can provide the reasoning behind your change choice and share it with AYO',
              )}
            </Typography>
          </Box>
          <EndorsementsSection />
        </Box>
        <Box mt={10}>
          <GoToStudentProfileBlock />
        </Box>
      </Container>
    </PageWrapper>
  );
};

export default EndorsementsPage;
