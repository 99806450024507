import { useCallback, useContext } from 'react';
import dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';

import {
  LessonPlannerActions,
  LessonPlannerContext,
  UserContext,
  UserActions,
} from '../../context';
import { PeriodsColors } from '../../constants/lesson-planner';
import { assignmentsSorter, getCourseAggregationKey, loopDateRange } from '../../utils';
import { useStudentsService } from '../../services';
import useLessonsService from '../../services/lessons/useLessonsService';

dayjs.extend(minMax);

const classesSorter = (a, b) => a.className.localeCompare(b.className);

const useLessonPlannerStudentData = () => {
  const { getSchoolsDaysConfig } = useLessonsService();
  const { getStudentSchedule } = useStudentsService();
  const { state: lessonPlannerState, dispatch: dispatchLessonPlannerState } =
    useContext(LessonPlannerContext);
  const { state: userState, dispatch: dispatchUserState } = useContext(UserContext);

  const { studentDatesData, pickerDates, semestersDaysConfig } = lessonPlannerState;

  const setSchoolName = useCallback(
    (schoolName) => {
      if (!userState.profile?.currentCampus) {
        dispatchUserState({
          type: UserActions.SET_USER_PROFILE_CAMPUS,
          data: { schoolName },
        });
      }
    },
    [dispatchUserState, userState.profile?.currentCampus],
  );

  const setSchoolDaysConfig = useCallback(() => {
    if (!Object.keys(semestersDaysConfig).length) {
      getSchoolsDaysConfig().then((data) => {
        dispatchLessonPlannerState({
          type: LessonPlannerActions.SET_SEMESTERS_DAYS_CONFIG,
          data: data.semesters,
        });

        const now = dayjs();
        let first;
        let last;

        if (now.isBefore(data.semesters.FALL.end)) {
          first = data.semesters.FALL.start;
          last = data.semesters.FALL.end;
        } else {
          first = data.semesters.SPRING.start;
          last = data.semesters.SPRING.end;
        }

        dispatchLessonPlannerState({
          type: LessonPlannerActions.SET_PICKER_DATES,
          data: {
            firstDayOfSchedule: first,
            lastDayOfSchedule: last,
          },
        });
      });
    }
  }, [dispatchLessonPlannerState, getSchoolsDaysConfig, semestersDaysConfig]);

  const getDateData = useCallback((dateTime) => studentDatesData[dateTime], [studentDatesData]);

  const getDateRangeData = (rangeStart, rangeEnd) => {
    const resultMap = {};

    loopDateRange(rangeStart, rangeEnd, (currentDate) => {
      if (currentDate.day() < 6 && currentDate.day() > 0) {
        const shortDate = currentDate.format('YYYY-MM-DD');
        resultMap[shortDate] = getDateData(shortDate);
      }
    });

    return resultMap;
  };

  const loadDatesData = async (startTime, endTime = startTime) => {
    let shouldLoadDateInfo = false;

    loopDateRange(startTime, endTime, (currentDate) => {
      if (getDateData(currentDate.format('YYYY-MM-DD')) === undefined) {
        shouldLoadDateInfo = true;
      }
    });

    if (!shouldLoadDateInfo) return;

    const schedule = await getStudentSchedule(startTime, endTime);

    schedule.daysSchedule = schedule.daysSchedule.map((daySchedule) => {
      if (!daySchedule) return daySchedule;
      const newDaySchedule = { ...daySchedule };

      newDaySchedule.classes = newDaySchedule.classes.reduce((acc, dayClass) => {
        const classesForAllCourses = dayClass.courses.map((course) => ({
          ...dayClass,
          ...course,
          lessonsMetadata: dayClass.lessonsMetadata.filter((lesson) =>
            lesson.classData.classesPeriod.courses.some(
              (lessonCourse) => lessonCourse.courseNumber === course.courseNumber,
            ),
          ),
        }));
        return [
          ...acc,
          ...classesForAllCourses.filter((finalDayClass) => finalDayClass.lessonsMetadata.length),
        ];
      }, []);

      return newDaySchedule;
    });

    const classColors = schedule.daysSchedule.reduce((accum, date) => {
      const newAccum = { ...accum };

      date.classes?.forEach((item) => {
        if (!newAccum[getCourseAggregationKey(item)]) {
          newAccum[getCourseAggregationKey(item)] =
            PeriodsColors[Object.keys(newAccum).length % PeriodsColors.length];
        }
      });

      return newAccum;
    }, {});

    schedule.daysSchedule.forEach((date) => {
      date.classes?.sort(classesSorter).forEach((item) => {
        // eslint-disable-next-line no-param-reassign
        item.color = classColors[getCourseAggregationKey(item)];
      });
    });

    setSchoolName(schedule.schoolName);

    const datesMap = {};

    loopDateRange(startTime, endTime, (currentDate) => {
      const shortDate = currentDate.format('YYYY-MM-DD');
      datesMap[shortDate] =
        schedule.daysSchedule.find((date) => date.classDate === shortDate) ?? null;
    });

    dispatchLessonPlannerState({
      type: LessonPlannerActions.ADD_STUDENT_DATE_DATA,
      data: datesMap,
    });
  };

  return {
    setSchoolDaysConfig,
    semestersDaysConfig,
    getDateData,
    loadDatesData,
    pickerDates,
    getDateRangeData,
    assignmentsSorter,
  };
};

export default useLessonPlannerStudentData;
