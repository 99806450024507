import React, { useEffect, useState, useCallback } from 'react';
import { useRouteMatch, useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button, Link } from '../../../atoms';
import { PageIllustrationBlock, ScreenCenteredContainer } from '../../../moleculas';
import { PageWrapper } from '../../../organisms';
import { useAdminService } from '../../../../services';
import { StatusCodeMap } from '../../../../services/HttpClient';
import { useLogin } from '../../../../utils';
import { LocalStorageKeysMap, LoginProvidersMap } from '../../../../constants/enums';
import { ReactComponent as Admin404Image } from '../../../../resources/images/error_404_admin.svg';
import { ReactComponent as DownloadFilesImage } from '../../../../resources/images/export_download_files.svg';

const ExportNotFound = () => {
  const history = useHistory();
  const goToUserManagementHandler = useCallback(() => history.push('/admin'), [history]);
  const { t } = useTranslation();

  return (
    <PageWrapper noi18n noNav>
      <ScreenCenteredContainer>
        <PageIllustrationBlock
          Illustration={Admin404Image}
          illustrationAriaLabel={t('UFO is looking for something')}
          mainHeaderText={t('404 Operation or archive not found')}
          secondaryHeaderText={[
            t('The requested operation or archive was deleted, expired or doesn’t exist'),
          ]}
        />
        <Button isAdaptive onClick={goToUserManagementHandler} variant="primary">
          {t('Go to User management')}
        </Button>
      </ScreenCenteredContainer>
    </PageWrapper>
  );
};

const DownloadExportPage = () => {
  const { getExportData } = useAdminService();
  const { params } = useRouteMatch();
  const { proceedToLogin } = useLogin();
  const [isNotFound, setIsNotFound] = useState(false);
  const location = useLocation();
  const { t } = useTranslation();
  const [isDownloadAvailable, setIsDownloadAvailable] = useState(false);

  const handleExportDownload = () => {
    getExportData(params.jobId)
      .then((res) => {
        const a = document.createElement('a');
        const link = res.headers.location;
        const fileName = `${params.jobId}.zip`;
        a.href = link;
        a.download = fileName;
        a.click();
        setIsDownloadAvailable(true);
      })
      .catch((err) => {
        if (err.response?.status === StatusCodeMap.UNAUTHORIZED) {
          sessionStorage.setItem(
            LocalStorageKeysMap.CLASSLINK_LOGIN_REDIRECT_URL,
            location.pathname,
          );
          proceedToLogin(LoginProvidersMap.CLASSLINK);
        }
        if (
          err.response?.status === StatusCodeMap.NOT_FOUND ||
          err.response?.status === StatusCodeMap.BAD_REQUEST
        ) {
          setIsNotFound(true);
        }
      });
  };

  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  useEffect(handleExportDownload, []);

  return isNotFound ? (
    <ExportNotFound />
  ) : (
    <PageWrapper noi18n noNav>
      {isDownloadAvailable && (
        <ScreenCenteredContainer>
          <PageIllustrationBlock
            Illustration={DownloadFilesImage}
            illustrationAriaLabel={t('A folder with files inside it and the download sign nearby')}
            mainHeaderText={t('Downloading should start soon')}
            secondaryHeaderText={[
              t('Download hasn’t started?'),
              <>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link isContained onClick={handleExportDownload}>
                  {t('Click here')}
                </Link>{' '}
                {t('to initiate it once again')}
              </>,
            ]}
          />
        </ScreenCenteredContainer>
      )}
    </PageWrapper>
  );
};

export default DownloadExportPage;
