import { Grid } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { DragDropContext } from '../../../atoms';
import { getUniqueStudents } from '../../../../utils';
import { KeyboardMap } from '../../../../constants/enums';

import DragDropStudentGroup from './components/drag-drop-student-group/DragDropStudentGroup';

// is needed for react-beautiful-dnd to correctly detect that group is on screen
const DRAG_DETECTION_PADDING = 50;

const ScrollDirections = {
  DOWN: 'down',
  UP: 'UP',
};

const GroupClassView = ({
  students,
  teacherGroups,
  handleGroupSetChange,
  studentCardRedirectLink,
}) => {
  const [currentDragItemDroppableInfo, setCurrentDragItemDroppableInfo] = useState(null);

  const [currentDroppableInfo, setCurrentDroppableInfo] = useState(null);

  const scrollGroupIntoView = useCallback(
    (direction) => {
      const { courseKey, droppableId } = currentDroppableInfo || currentDragItemDroppableInfo;
      const { groups } = teacherGroups[courseKey];
      const sourceGroupIndex = groups.findIndex((group) => group.name === droppableId);
      if (
        direction === ScrollDirections.DOWN
          ? sourceGroupIndex <= groups.length - 2
          : sourceGroupIndex > 0
      ) {
        const neededGroupName =
          groups[direction === ScrollDirections.DOWN ? sourceGroupIndex + 1 : sourceGroupIndex - 1]
            .name;
        const neededGroup = document.querySelector(
          `[data-droppable-id="${neededGroupName} - ${courseKey}"]`,
        );
        const html = document.querySelector('html');
        const { offsetTop: y, clientHeight: height } = neededGroup;
        const isViewableCriteriaMet =
          direction === ScrollDirections.DOWN
            ? y + height + DRAG_DETECTION_PADDING < html.scrollTop + html.clientHeight
            : y - DRAG_DETECTION_PADDING > html.scrollTop;
        if (!isViewableCriteriaMet) {
          neededGroup.scrollIntoView(direction !== ScrollDirections.DOWN);
          html.scroll(
            0,
            direction === ScrollDirections.DOWN
              ? html.scrollTop + DRAG_DETECTION_PADDING
              : html.scrollTop - DRAG_DETECTION_PADDING,
          );
        }
      }
    },
    [currentDragItemDroppableInfo, currentDroppableInfo, teacherGroups],
  );

  const scrollOnKeydown = useCallback(
    (e) => {
      if (e.key === KeyboardMap.ARROW_DOWN) {
        scrollGroupIntoView(ScrollDirections.DOWN);
      }
      if (e.key === KeyboardMap.ARROW_UP) {
        scrollGroupIntoView(ScrollDirections.UP);
      }
    },
    [scrollGroupIntoView],
  );

  const onDragEndHandler = useCallback(
    ({ destination, source, draggableId }, courseKey) => {
      setCurrentDragItemDroppableInfo(null);
      setCurrentDroppableInfo(null);
      if (!destination || source.droppableId === destination.droppableId) return;
      const movedItemSourceGroupIndex = teacherGroups[courseKey].groups.findIndex(
        (group) => group.name === source.droppableId,
      );
      const movedItemDestinationGroupIndex = teacherGroups[courseKey].groups.findIndex(
        (group) => group.name === destination.droppableId,
      );
      const currentCourseGroups = { ...teacherGroups[courseKey] };
      currentCourseGroups.groups[movedItemSourceGroupIndex] = {
        ...currentCourseGroups.groups[movedItemSourceGroupIndex],
        studentIds: currentCourseGroups.groups[movedItemSourceGroupIndex].studentIds.filter(
          (item) => item !== +draggableId,
        ),
      };
      currentCourseGroups.groups[movedItemDestinationGroupIndex] = {
        ...currentCourseGroups.groups[movedItemDestinationGroupIndex],
        studentIds: [
          ...currentCourseGroups.groups[movedItemDestinationGroupIndex].studentIds,
          +draggableId,
        ],
      };
      handleGroupSetChange(currentCourseGroups, courseKey);
    },
    [handleGroupSetChange, teacherGroups],
  );

  const onDragStart = useCallback(({ source }, courseKey) => {
    setCurrentDragItemDroppableInfo({ droppableId: source.droppableId, courseKey });
  }, []);

  const onDragUpdate = useCallback((update, courseKey) => {
    if (!update.destination) return;
    setCurrentDroppableInfo({
      droppableId: update.destination.droppableId,
      courseKey,
    });
  }, []);

  useEffect(() => {
    if (currentDragItemDroppableInfo) {
      window.addEventListener('keydown', scrollOnKeydown);
    }
    return () => {
      window.removeEventListener('keydown', scrollOnKeydown);
    };
  }, [scrollOnKeydown, currentDragItemDroppableInfo, currentDroppableInfo]);
  return (
    <Grid container direction="column" wrap="nowrap">
      {Object.keys(students.length ? { students } : students).map((period) => (
        <DragDropContext
          key={period}
          onDragEnd={(dragInfo) => onDragEndHandler(dragInfo, period)}
          onDragStart={(dragInfo) => onDragStart(dragInfo, period)}
          onDragUpdate={(update) => onDragUpdate(update, period)}
        >
          <DragDropStudentGroup
            currentDragItemDroppableId={currentDragItemDroppableInfo?.droppableId}
            groupSet={teacherGroups && teacherGroups[period]}
            onGroupSetChange={handleGroupSetChange}
            period={period}
            studentCardRedirectLink={studentCardRedirectLink}
            students={getUniqueStudents(
              students.length ? students : Object.values(students[period]).flat(),
            )}
          />
        </DragDropContext>
      ))}
    </Grid>
  );
};

GroupClassView.propTypes = {
  students: PropTypes.instanceOf(Object).isRequired,
  teacherGroups: PropTypes.instanceOf(Object).isRequired,
  handleGroupSetChange: PropTypes.func.isRequired,
  studentCardRedirectLink: PropTypes.func.isRequired,
};

export default GroupClassView;
