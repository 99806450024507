import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Typography } from '../../../../../atoms';
import { STTTextField } from '../../../../../moleculas';
import AssignmentNoEditTooltip from '../../assignment-no-edit-tooltip/AssignmentNoEditTooltip';
import { InputsValidationRules } from '../../../../../../constants/enums';

const AssignmentTitle = ({
  fieldLabel,
  gaLabel,
  value,
  errorMessage,
  title,
  handleChange,
  disabled,
  rowsCount,
}) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography aria-label={`${title} (${t('required')})`} paragraph variant="subtitle2">
        {title}*
      </Typography>
      <AssignmentNoEditTooltip showTooltip={disabled}>
        <STTTextField
          disabled={disabled}
          error={errorMessage !== ''}
          fullWidth
          gaLabel={gaLabel}
          helperText={errorMessage}
          label={fieldLabel}
          maxLength={InputsValidationRules.MAX_INPUT_LENGTH}
          multiline={rowsCount > 1}
          onChange={(e) => handleChange(e.target.value)}
          outlined
          required
          rowsCount={rowsCount}
          value={value}
        />
      </AssignmentNoEditTooltip>
    </Box>
  );
};

AssignmentTitle.propTypes = {
  gaLabel: PropTypes.string,
  errorMessage: PropTypes.string,
  fieldLabel: PropTypes.string,
  value: PropTypes.string,
  title: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  rowsCount: PropTypes.number,
};

AssignmentTitle.defaultProps = {
  errorMessage: '',
  gaLabel: null,
  value: null,
  title: 'Title',
  fieldLabel: 'Add your title here',
  rowsCount: 1,
};

export default React.memo(AssignmentTitle);
