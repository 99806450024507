import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { Card, Typography } from '../../atoms';
import BadgesRating from '../badges-rating/BadgesRating';
import BadgesImage from '../../pages/badges/components/badge-image/BadgeImage';

const BadgeCard = ({ badge, index, isReadOnlyMode, onCardClickHandler }) => {
  const { t } = useTranslation();

  return (
    <Card
      className={classnames('ayo-badge-card', {
        'ayo-badge-card--clickable': !isReadOnlyMode,
      })}
      component={!isReadOnlyMode ? 'button' : 'div'}
      elevation={!isReadOnlyMode ? 1 : 0}
      mainContent={
        <Box className="ayo-badge-card__content">
          <BadgesImage badgeData={badge} />
          <Typography className="ayo-badge-card__title" variant="subtitle2">
            {t(badge.nameKey)}
          </Typography>
          <BadgesRating badge={badge} />
        </Box>
      }
      onClick={() => (isReadOnlyMode ? null : onCardClickHandler(index))}
    />
  );
};

BadgeCard.propTypes = {
  badge: PropTypes.instanceOf(Object).isRequired,
  isReadOnlyMode: PropTypes.bool,
  index: PropTypes.string.isRequired,
  onCardClickHandler: PropTypes.func,
};

BadgeCard.defaultProps = {
  isReadOnlyMode: false,
  onCardClickHandler: () => {},
};

export default BadgeCard;
