import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Button, SkeletonMask, Typography } from '../../atoms';
import {
  GoToStudentProfileBlock,
  InitiativeEmptyStateBlock,
  NewFeatureHotspot,
  ClickableCard,
} from '../../moleculas';
import FeedbackPopup from '../feedback/feedback-popup/FeedbackPopup';
import EvidenceOfGrowthBlock from '../evidence-of-growth/evidence-of-growth-block/EvidenceOfGrowthBlock';
import { UserContext } from '../../../context';
import { useLazyDataLoad, useEvidencesData } from '../../../hooks';
import { useStudentsService } from '../../../services';
import { goalsRoute, trainAyoRoute, createRoute } from '../../../constants/routes';
import { FeedbackFunctionalityKeysMap, RolesMap } from '../../../constants/enums';
import { RolesMainPages } from '../../../constants/pages';
import { GoalsMilestonesStatuses, GoalsStatuses } from '../../../constants/goals';
import { evidencesConfigByType } from '../../../constants/configs';
import { ReactComponent as VectorIcon } from '../../../resources/icons/vector.svg';
import { ReactComponent as SuccessIcon } from '../../../resources/icons/check_circle.svg';
import { ReactComponent as FeedbackGoalsImage } from '../../../resources/images/feedback_goals.svg';
import { ReactComponent as GoalsEmptyStateImage } from '../../../resources/images/goals-empty-state-image.svg';
import { ReactComponent as AddIcon } from '../../../resources/icons/add.svg';
import { NewFeaturesIdsMap } from '../../../tours/common/NewFeaturesItemsProvider';

const goalsProgressCounter = (milestones) =>
  milestones.length
    ? (milestones.filter((el) => el.status === 'COMPLETED').length / milestones.length) * 100
    : 0;

const goalsSortingHelper = (goals) => {
  const sortedByDate = goals.sort((a, b) => new Date(b.updatedDate) - new Date(a.updatedDate));
  return [
    ...sortedByDate.filter((goal) => goal.status !== GoalsMilestonesStatuses.COMPLETED),
    ...sortedByDate.filter((goal) => goal.status === GoalsMilestonesStatuses.COMPLETED),
  ].map((el) => ({ progress: goalsProgressCounter(el.milestones), ...el }));
};

const GoalsBlock = ({
  checkAvailableGoals,
  isEditable,
  isLazyLoad,
  isEvidenceEditable,
  headerLevel,
  headerVariant,
  showHeaderAndEmptyState,
  studentId,
}) => {
  const { getStudentGoals } = useStudentsService();
  const [studentGoals, setStudentGoals] = useState(null);
  const [hasFeedbackPopup, setHasFeedbackPopup] = useState(false);
  const { state: userState } = useContext(UserContext);
  const { relatedEvidences } = useEvidencesData(studentId, evidencesConfigByType.goal.type);
  const { t } = useTranslation();
  const history = useHistory();
  const isStudent = userState.profile.role === RolesMap.STUDENT;
  const isParent = userState.profile.role === RolesMap.GUARDIAN;

  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  const loadStudentGoals = useCallback(
    (onLoad) => {
      if (studentId) {
        getStudentGoals(studentId, isLazyLoad).then((goals) => {
          setStudentGoals(goalsSortingHelper(goals));
          if (onLoad) {
            onLoad();
          }
        });
      }
    },
    [getStudentGoals, isLazyLoad, studentId],
  );

  const { isDataLoaded, loadActivatorRef } = useLazyDataLoad(
    () =>
      new Promise((resolve) => {
        loadStudentGoals(resolve);
      }),
  );

  useEffect(() => {
    if (!isLazyLoad) {
      loadStudentGoals();
      const urlParams = new URLSearchParams(window.location.search);
      const showFeedbackPopup = urlParams.get('showFeedbackPopup');
      if (showFeedbackPopup) {
        setHasFeedbackPopup(true);
      }
    }
  }, [isLazyLoad, loadStudentGoals, studentId]);

  useEffect(() => {
    if (checkAvailableGoals && studentGoals) {
      checkAvailableGoals(studentGoals);
    }
  }, [checkAvailableGoals, studentGoals]);

  const onCardClickHandler = useCallback(
    (goalId) => {
      if (isEditable) {
        history.push(
          isParent
            ? `${RolesMainPages[userState.profile.role]}/${studentId}${goalsRoute}/${goalId}`
            : `${trainAyoRoute}/${studentId}${goalsRoute}/${goalId}`,
        );
      }
    },
    [history, isEditable, isParent, studentId, userState],
  );

  const getChipLabel = useCallback(
    (evidence) =>
      studentGoals?.find(
        (goal) => goal.id === parseInt(evidence.evidenceResourcesByType.GOAL.parentResourceId, 10),
      )?.name,
    [studentGoals],
  );

  return (
    <>
      <Box className="ayo-my-goals-block" mb={isWidthUpSm ? 10 : 5}>
        {showHeaderAndEmptyState && (
          <Box
            alignItems={isWidthUpSm ? 'center' : 'flex-start'}
            display="flex"
            flexDirection={isWidthUpSm ? 'row' : 'column'}
            justifyContent="space-between"
            mb={3}
          >
            <Box
              ref={isLazyLoad ? loadActivatorRef : null}
              alignItems="center"
              display="flex"
              flexDirection="row"
            >
              <Typography component={`h${headerLevel}`} variant={headerVariant}>
                {isStudent ? t('My goals') : t('Goals')}
              </Typography>
              <NewFeatureHotspot
                id={
                  isStudent || isParent
                    ? NewFeaturesIdsMap.GOALS
                    : NewFeaturesIdsMap.GOALS_CREATE_EDUCATORS
                }
                isClickable
              />
            </Box>
            {!!studentGoals?.length && !isParent && (
              <Button
                component={RouterLink}
                endIcon={<VectorIcon />}
                gaLabel="Track goals click"
                to={`${trainAyoRoute}/${studentId}${goalsRoute}`}
              >
                {t('Manage in Train AYO')}
              </Button>
            )}
          </Box>
        )}
        {!!studentGoals?.length && (
          <Grid container direction="row" spacing={3}>
            {studentGoals.map((goal) => (
              <Grid key={goal.id} item sm={4} xs={12}>
                <ClickableCard
                  gaLabel="Goal card click"
                  onClick={isEditable && (() => onCardClickHandler(goal.id))}
                  progressValue={goal.progress}
                  statusIcon={goal.status === GoalsMilestonesStatuses.COMPLETED && <SuccessIcon />}
                  statusText={t(GoalsStatuses[goal.status])}
                  title={goal.name}
                />
              </Grid>
            ))}
          </Grid>
        )}
        {!studentGoals?.length && isLazyLoad && !isDataLoaded && <SkeletonMask />}
        {!studentGoals?.length &&
          showHeaderAndEmptyState &&
          ((isLazyLoad && isDataLoaded) || !isLazyLoad) && (
            <InitiativeEmptyStateBlock
              body={
                isParent
                  ? null
                  : t(isStudent ? 'But it’s never too late!' : 'But you can help out now!')
              }
              buttonContent={t('Set goal in Train AYO')}
              buttonGaLabel="Set goal in Train AYO"
              buttonLink={`${trainAyoRoute}/${studentId}${goalsRoute}`}
              illustration={<GoalsEmptyStateImage />}
              showNavigationButton={!isParent}
              title={t(
                isStudent ? 'Seems that you haven’t set any goals yet' : 'No goals set so far',
              )}
            />
          )}
        {!showHeaderAndEmptyState && (
          <Box mt={studentGoals?.length ? 3 : 0}>
            <Button
              className="ayo-my-goals-block__add-button"
              component={RouterLink}
              disableElevation
              disableRipple
              endIcon={<AddIcon />}
              gaLabel="Create a goal"
              to={`${trainAyoRoute}/${studentId}${goalsRoute}${createRoute}`}
            >
              {t('Create a goal')}
            </Button>
          </Box>
        )}
        {studentGoals && (
          <Box mt={5}>
            <EvidenceOfGrowthBlock
              evidenceType={evidencesConfigByType.goal.type}
              getChipLabelHandler={getChipLabel}
              goals={studentGoals}
              headerLevel={3}
              isActionButtonsAvailable
              isLazyLoad={isLazyLoad}
              readOnlyMode={!isEvidenceEditable}
              relatedEvidences={relatedEvidences}
              studentId={studentId}
              titleVariant="subtitle1"
            />
          </Box>
        )}
      </Box>
      {!!studentGoals?.length && !showHeaderAndEmptyState && isStudent && (
        <GoToStudentProfileBlock />
      )}
      {hasFeedbackPopup && (
        <FeedbackPopup
          CustomMainContent={<FeedbackGoalsImage />}
          functionalityKey={FeedbackFunctionalityKeysMap.MY_GOALS}
          isAutoShow
          title={t('Are My goals helpful?')}
          type="yesno"
          variant="dark"
        />
      )}
    </>
  );
};

GoalsBlock.propTypes = {
  checkAvailableGoals: PropTypes.func,
  isEditable: PropTypes.bool,
  isEvidenceEditable: PropTypes.bool,
  isLazyLoad: PropTypes.bool,
  headerLevel: PropTypes.number,
  headerVariant: PropTypes.string,
  showHeaderAndEmptyState: PropTypes.bool,
  studentId: PropTypes.number,
};

GoalsBlock.defaultProps = {
  checkAvailableGoals: null,
  isEditable: false,
  isEvidenceEditable: false,
  isLazyLoad: false,
  headerLevel: 2,
  headerVariant: 'h2',
  showHeaderAndEmptyState: false,
  studentId: null,
};
export default GoalsBlock;
