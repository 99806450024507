import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

import { Typography } from '../../../../atoms';
import { ReactComponent as UsersIcon } from '../../../../../resources/icons/users.svg';

const SharedLessonBadge = ({ ariaLabel, count }) => (
  <Box
    alignItems="center"
    aria-label={ariaLabel || `Shared with ${count} teacher(s)`}
    columnGap={1}
    display="flex"
    tabIndex={0}
  >
    <UsersIcon height="16px" width="16px" />
    <Typography aria-hidden isLabel variant="label-highlighted">
      {count}
    </Typography>
  </Box>
);

SharedLessonBadge.propTypes = {
  ariaLabel: PropTypes.string,
  count: PropTypes.number.isRequired,
};

SharedLessonBadge.defaultProps = {
  ariaLabel: '',
};

export default SharedLessonBadge;
