import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

import { Card, Typography } from '../../../../atoms';
import { ReactComponent as ConsentsIllustration } from '../../../../../resources/images/analytics_consents.svg';
import { ReactComponent as LoggedInIllustration } from '../../../../../resources/images/analytics_logged_in.svg';
import { ReactComponent as QuestionnaireIllustration } from '../../../../../resources/images/analytics_questionnaire.svg';
import { ReactComponent as TermsAndConditionsIllustration } from '../../../../../resources/images/analytics_terms_and_conditions.svg';
import AnalyticsStatValue from '../analytics-stat-value/AnalyticsStatValue';

const StatIllustrations = {
  withConsent: { image: ConsentsIllustration, label: 'A key with AYO label on the ring' },
  loggedIn: { image: LoggedInIllustration, label: 'An open door with a person running through it' },
  questionnaireResponded: { image: QuestionnaireIllustration, label: 'A note with question marks' },
  skippedAgreement: {
    image: TermsAndConditionsIllustration,
    label: 'An open door with Welcome words',
  },
};

const IllustratedStatCard = ({ statKey, statsCategory, statValue }) => {
  const { t } = useTranslation();
  const { image: Illustration, label } = StatIllustrations[statKey] || {};

  return (
    <Card
      key={statKey}
      elevation={0}
      mainContent={
        <Box alignItems="center" display="flex" justifyContent="space-between" position="relative">
          <Box minWidth="45%">
            <Box mb={1}>
              <AnalyticsStatValue
                filterCategory={statKey}
                statsCategory={statsCategory}
                statsValue={statValue}
              />
            </Box>
            <Box>
              <Typography isLabel variant="caption">
                <Trans components={{ br: <br /> }} i18nKey={`analytics.${statKey}`} />
              </Typography>
            </Box>
          </Box>
          <Box minWidth="20%">
            {Illustration && <Illustration aria-label={t(label)} role="img" />}
          </Box>
        </Box>
      }
    />
  );
};

IllustratedStatCard.propTypes = {
  statKey: PropTypes.string.isRequired,
  statsCategory: PropTypes.string.isRequired,
  statValue: PropTypes.number.isRequired,
};

export default IllustratedStatCard;
