import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Box, FormControl, FormLabel, RadioGroup } from '@mui/material';

import { FormControlLabel, Radio, TextField, Typography } from '../../../../../atoms';
import AssignmentNoEditTooltip from '../../assignment-no-edit-tooltip/AssignmentNoEditTooltip';
import { getNumberInputDigitsRE } from '../../../../../../constants/regexps';
import { DisabledControlWithTooltip } from '../../../../../moleculas';

const MAX_POINTS_DIGITS = 4;
const pointsValidationRE = getNumberInputDigitsRE(MAX_POINTS_DIGITS);

const AssignmentPoints = ({ value, handleChange, disabled, errorMessage, notGradable }) => {
  const { t } = useTranslation();

  const areFieldsDisabled = disabled || notGradable;

  return (
    <Box display="flex">
      <FormControl>
        <FormLabel id="assignment-radio-buttons-group-label">
          <Typography paragraph variant="subtitle2">
            {t('Points')}
          </Typography>
        </FormLabel>
        <RadioGroup
          aria-labelledby="assignment-radio-buttons-group-label"
          name="assignment-radio-buttons"
          onChange={(e) => handleChange(e.target.value === 'ungraded' ? null : e.target.value)}
          row
        >
          <AssignmentNoEditTooltip showTooltip={disabled}>
            <DisabledControlWithTooltip
              showTooltip={!disabled && notGradable}
              title={t('Not gradable assignment')}
            >
              <Box alignItems="flex-end" display="flex" mr={3}>
                <FormControlLabel
                  control={
                    <Radio
                      checked={value !== null && !notGradable}
                      disabled={areFieldsDisabled}
                      gaLabel="Graded"
                    />
                  }
                  label={
                    <Typography paragraph variant="body2">
                      {t('Graded')}
                    </Typography>
                  }
                  value={100}
                />
                {!notGradable && (
                  <Box ml={2}>
                    <TextField
                      disabled={areFieldsDisabled || value === null}
                      error={!!errorMessage}
                      fullWidth
                      helperText={errorMessage}
                      onChange={(e) => {
                        if (pointsValidationRE.test(e.target.value) || e.target.value === '') {
                          handleChange(e.target.value);
                        }
                      }}
                      outlined
                      value={value}
                    />
                  </Box>
                )}
              </Box>
            </DisabledControlWithTooltip>
          </AssignmentNoEditTooltip>
          <Box alignItems="flex-end" display="flex">
            <AssignmentNoEditTooltip showTooltip={disabled}>
              <DisabledControlWithTooltip
                showTooltip={!disabled && notGradable}
                title={t('Not gradable assignment')}
              >
                <FormControlLabel
                  control={
                    <Radio
                      checked={value === null || notGradable}
                      disabled={areFieldsDisabled}
                      gaLabel="Ungraded"
                    />
                  }
                  label={
                    <Typography paragraph variant="body2">
                      {t('Ungraded')}
                    </Typography>
                  }
                  value="ungraded"
                />
              </DisabledControlWithTooltip>
            </AssignmentNoEditTooltip>
          </Box>
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

AssignmentPoints.propTypes = {
  value: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  notGradable: PropTypes.bool,
};

AssignmentPoints.defaultProps = {
  errorMessage: '',
  notGradable: false,
};

export default AssignmentPoints;
