import { useCallback } from 'react';
import { Client } from '@stomp/stompjs';
import SockJS from 'sockjs-client';

import useAxios, { StatusCodeMap } from '../HttpClient';
import {
  alertsRoute,
  apiRoute,
  notificationsConnectionRoute,
  notificationsRoute,
  notificationsSubscriptionRoute,
  readRoute,
  studentsRoute,
} from '../../constants/routes';
import { NotificationSourcesMap } from '../../constants/enums';

let stompClient;

const openConnection = async (connectionUrl, subscriptionUrl, onMessage) => {
  try {
    stompClient = new Client();
    stompClient.configure({
      webSocketFactory: () =>
        new SockJS(connectionUrl, null, {
          transports: ['websocket'],
        }),
      onConnect: () => {
        console.log('onConnect');

        stompClient.subscribe(subscriptionUrl, onMessage);
      },
      onDisconnect: () => {
        console.log('onDisconnect');
      },
    });

    await stompClient.activate();

    console.log(`client connected = ${stompClient}`);
  } catch (e) {
    console.log(e);
  }
};

const closeConnection = () => {
  if (stompClient) {
    stompClient.deactivate();
    console.log(`client disconnected = ${stompClient}`);

    stompClient = null;
  }
};

const useNotificationsService = () => {
  const { get, put } = useAxios();

  const getNotificationsList = useCallback(async () => {
    const { data } = await get(
      { enabled: true },
      `${apiRoute}${notificationsRoute}?notificationSource=${NotificationSourcesMap.WEB_HIDDEN},${NotificationSourcesMap.WEB_GENERAL}`,
      {
        skipDefaultErrorHandlers: [StatusCodeMap.NOT_FOUND, StatusCodeMap.SERVER_ERROR],
      },
    );
    return data.entity;
  }, [get]);

  const putReadNotifications = useCallback(
    (idsArray) =>
      put(
        { enabled: false },
        `${apiRoute}${notificationsRoute}`,
        { ids: idsArray },
        {
          skipDefaultErrorHandlers: [StatusCodeMap.NOT_FOUND, StatusCodeMap.SERVER_ERROR],
        },
      ),
    [put],
  );

  const openNotificationsSocket = (onMessage) =>
    openConnection(notificationsConnectionRoute, notificationsSubscriptionRoute, onMessage);

  const closeNotificationsSocket = () => closeConnection();

  const getNotificationsAlerts = useCallback(
    async (userId) => {
      const { data } = await get(
        { enabled: true },
        `${apiRoute}${notificationsRoute}${alertsRoute}${studentsRoute}/${userId}`,
      );
      return data.entity.alerts;
    },
    [get],
  );

  const putReadNotificationsAlert = useCallback(
    (alertId) =>
      put(
        { enabled: false },
        `${apiRoute}${notificationsRoute}${alertsRoute}/${alertId}${readRoute}`,
      ),
    [put],
  );

  return {
    closeNotificationsSocket,
    getNotificationsAlerts,
    getNotificationsList,
    openNotificationsSocket,
    putReadNotifications,
    putReadNotificationsAlert,
  };
};

export default useNotificationsService;
