/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { DataGrid as MUIDataGrid } from '@mui/x-data-grid';
import { Pagination } from '@mui/lab';
import { useTheme, useMediaQuery, Box, Divider } from '@mui/material';

import { Button, Tooltip, TextField, Typography } from '../../atoms';
import { Highlighter } from '../../../utils';
import { digitsOnly } from '../../../constants/regexps';
import { KeyboardMap } from '../../../constants/enums';

export const CustomPagination = ({ rowCount, pageSize, onPageChange, selectedPage }) => {
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState(null);
  const { t } = useTranslation();

  const totalPages = useMemo(() => Math.ceil(rowCount / pageSize), [rowCount, pageSize]);

  const handleInputChange = useCallback((e) => {
    const inputNumber = parseInt(e.target.value, 10);

    setInputValue(Number.isNaN(inputNumber) ? '' : inputNumber);
  }, []);

  const validateInput = useCallback(
    (pageNumber) => {
      const page = pageNumber ?? Number(inputValue);
      let isValid;

      if (digitsOnly.test(`${page}`) && page >= 1 && page <= totalPages) {
        isValid = true;
        setError(null);
      } else {
        isValid = false;
        setError(`${t('Put a number from')} 1 ${t('to')} ${totalPages}`);
      }

      return isValid ? page : null;
    },
    [inputValue, totalPages, t],
  );

  const handlePageChange = useCallback(
    (_e, pageNumber) => {
      const page = validateInput(pageNumber);

      if (page) {
        onPageChange(page);

        setInputValue('');
      }
    },
    [onPageChange, validateInput],
  );

  useEffect(() => {
    if (!inputValue) {
      setError(null);
    } else {
      validateInput();
    }
  }, [inputValue, validateInput]);

  return (
    <Box
      alignItems="center"
      className="ayo-pagination"
      display="flex"
      justifyContent="space-between"
    >
      <Pagination
        className="ayo-pagination__pages"
        count={totalPages}
        onChange={handlePageChange}
        page={selectedPage}
      />
      <Box alignItems="center" display="flex">
        <Typography variant="body2">{t('Total number pages', { number: totalPages })}</Typography>
        <Divider className="ayo-pagination__divider" orientation="vertical" />
        <Typography variant="body2">{t('Go to page')}</Typography>
        <TextField
          error={!!error}
          helperText={error ?? ''}
          onChange={handleInputChange}
          onKeyUp={(e) => {
            if (e.key === KeyboardMap.ENTER) {
              handlePageChange();
            }
          }}
          outlined
          placeholder={`${selectedPage}`}
          value={inputValue}
        />
        <Button gaLabel="Go To Page" onClick={handlePageChange} variant="text">
          {t('Go')}
        </Button>
      </Box>
    </Box>
  );
};

CustomPagination.propTypes = {
  rowCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  selectedPage: PropTypes.number.isRequired,
};

const getTooltip = (tooltipCfg, rowData) => {
  const values = tooltipCfg?.transform(rowData[tooltipCfg.field]);

  return values ? (
    <Tooltip
      className="ayo-data-grid__cell-tooltip"
      enterTouchDelay={0}
      leaveTouchDelay={5000}
      title={<Trans components={{ b: <b /> }} i18nKey={tooltipCfg.text} values={{ values }} />}
    >
      <Box display="flex" ml={1}>
        <tooltipCfg.icon />
      </Box>
    </Tooltip>
  ) : null;
};

const DEFAULT_PAGE_SIZE = 12;

export const DataGrid = ({
  className,
  columns,
  searchValue,
  onPageChange,
  rowCount,
  selectedPage,
  ...props
}) => {
  const theme = useTheme();
  const isWidthUpXl = useMediaQuery(theme.breakpoints.up('xl'));

  const extendedColumns = columns.map((colDef) => ({
    sortable: false,
    renderCell: (params) => (
      <>
        <Typography noWrap variant="body2">
          {colDef.searchable
            ? Highlighter(params.value, searchValue ? searchValue.split(' ') : [])
            : params.value}
        </Typography>
        {colDef.tooltipCfg && getTooltip(colDef.tooltipCfg, params.row)}
      </>
    ),
    renderHeader: (params) => (
      <Typography isLabel variant="caption">
        {params.colDef.headerName}
      </Typography>
    ),
    ...colDef,
  }));

  return (
    <MUIDataGrid
      autoHeight
      className="ayo-data-grid"
      columns={extendedColumns}
      components={{ Pagination: CustomPagination }}
      componentsProps={{
        pagination: {
          pageSize: DEFAULT_PAGE_SIZE,
          onPageChange,
          rowCount,
          selectedPage,
        },
      }}
      disableColumnMenu
      disableColumnResize
      headerHeight={23}
      hideFooterRowCount
      hideFooterSelectedRowCount
      pageSize={12}
      pagination
      paginationMode="server"
      rowHeight={isWidthUpXl ? 64 : 56}
      {...props}
    />
  );
};

DataGrid.propTypes = {
  className: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  searchValue: PropTypes.string,
  rowCount: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  selectedPage: PropTypes.number.isRequired,
};

DataGrid.defaultProps = {
  className: '',
  searchValue: '',
};

export default { DataGrid, CustomPagination };
