import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  PageIllustrationBlock,
  PreviousPageButton,
  ScreenCenteredContainer,
} from '../../../moleculas';
import { PageWrapper } from '../../../organisms';
import { ReactComponent as AccessForbiddenImage } from '../../../../resources/images/error_restricted_access.svg';

const ForbiddenPage = () => {
  const { t } = useTranslation();

  return (
    <PageWrapper noNav>
      <ScreenCenteredContainer>
        <PageIllustrationBlock
          Illustration={AccessForbiddenImage}
          illustrationAriaLabel={t('Red and white construction barrier with a Road closed sign')}
          mainHeaderText={t('Access is denied')}
          secondaryHeaderText={[t('Sorry, you are not allowed to view this page')]}
        />
        <PreviousPageButton />
      </ScreenCenteredContainer>
    </PageWrapper>
  );
};

export default ForbiddenPage;
