/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, Snackbar as MUISnackbar } from '@mui/material';
import classnames from 'classnames';

import Typography from '../typography/Typography';
import { ReactComponent as checkCircle24px } from '../../../resources/icons/check_circle.svg';
import { ReactComponent as close24pxRed } from '../../../resources/icons/close_red.svg';
import { ReactComponent as errorWhite } from '../../../resources/icons/error_white.svg';
import { ReactComponent as deleteWhite } from '../../../resources/icons/delete_white.svg';
import { ReactComponent as removeWhite } from '../../../resources/icons/close_white.svg';
import { ReactComponent as reorderWhite } from '../../../resources/icons/drag_and_drop_light.svg';
import { ReactComponent as CheckOutlinedIcon } from '../../../resources/icons/check_outline.svg';
import { ReactComponent as addWhite } from '../../../resources/icons/add_white.svg';
import { ReactComponent as commentWhite } from '../../../resources/icons/comment_white.svg';

export const SnackbarVariantMap = {
  ADD: 'add',
  COMMENT: 'comment',
  DELETE: 'delete',
  ERROR: 'error',
  INFO: 'info',
  REMOVE: 'remove',
  REORDER: 'reorder',
  SUCCESS: 'success',
  SUCCESS_OUTLINED: 'success_outlined',
  WARNING: 'warning',
};

const Snackbar = ({ className, text, typographyVariant, onClose, open, variant, ...rest }) => {
  const SnackbarIconRoleMap = useMemo(
    () => ({
      [SnackbarVariantMap.ADD]: addWhite,
      [SnackbarVariantMap.COMMENT]: commentWhite,
      [SnackbarVariantMap.DELETE]: deleteWhite,
      [SnackbarVariantMap.ERROR]: close24pxRed,
      [SnackbarVariantMap.INFO]: null,
      [SnackbarVariantMap.REMOVE]: removeWhite,
      [SnackbarVariantMap.REORDER]: reorderWhite,
      [SnackbarVariantMap.SUCCESS]: checkCircle24px,
      [SnackbarVariantMap.SUCCESS_OUTLINED]: () => <CheckOutlinedIcon className="check-outlined" />,
      [SnackbarVariantMap.WARNING]: errorWhite,
    }),
    [],
  );

  const CurrentIcon = useMemo(
    () => SnackbarIconRoleMap[variant] || React.Fragment,
    [SnackbarIconRoleMap, variant],
  );

  return (
    <MUISnackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      autoHideDuration={10000}
      className={classnames('ayo-snackbar', `ayo-snackbar--${variant}`, className)}
      message={
        <Box alignItems="top" display="flex">
          <CurrentIcon />
          <Typography className="ayo-snackbar--text" variant={typographyVariant}>
            {text}
          </Typography>
        </Box>
      }
      onClose={onClose}
      open={open}
      {...rest}
    />
  );
};

Snackbar.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  typographyVariant: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  variant: PropTypes.oneOf([
    SnackbarVariantMap.INFO,
    SnackbarVariantMap.SUCCESS,
    SnackbarVariantMap.SUCCESS_OUTLINED,
    SnackbarVariantMap.ERROR,
    SnackbarVariantMap.WARNING,
    SnackbarVariantMap.DELETE,
  ]),
};

Snackbar.defaultProps = {
  className: '',
  typographyVariant: 'body2',
  onClose: () => {},
  open: false,
  variant: SnackbarVariantMap.INFO,
};

export default Snackbar;
