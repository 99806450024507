import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Container, Divider, Grid, useMediaQuery, useTheme } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import { DynamicGrid, Image, Typography } from '../../../../../atoms';
import { InformationalMessage } from '../../../../../moleculas';
import { UserActions, UserContext } from '../../../../../../context';
import { GA, getNormalizedLanguageCode, useProfile } from '../../../../../../utils';
import { useOneTimeActionService } from '../../../../../../services';
import {
  GaActions,
  GaCategories,
  KeyboardMap,
  LanguagesMap,
  OneTimeActionsMap,
} from '../../../../../../constants/enums';
import { ReactComponent as BackButtonEn } from '../../../../../../resources/images/lesson-planner/go_back_button_en.svg';
import { ReactComponent as BackButtonEs } from '../../../../../../resources/images/lesson-planner/go_back_button_es.svg';
import { ReactComponent as CalendarCardGreen } from '../../../../../../resources/images/lesson-planner/calendar_green_green.svg';
import { ReactComponent as CalendarCardOrange } from '../../../../../../resources/images/lesson-planner/calendar_card_orange.svg';
import { ReactComponent as CalendarCardOrangeMobile } from '../../../../../../resources/images/lesson-planner/calendar_card_orange_mobile.svg';
import { ReactComponent as CalendarCardPurple } from '../../../../../../resources/images/lesson-planner/calendar_card_purple.svg';
import { ReactComponent as CalendarCardPurpleMobile } from '../../../../../../resources/images/lesson-planner/calendar_card_purple_mobile.svg';
import { ReactComponent as CalendarDots } from '../../../../../../resources/images/lesson-planner/calendar_dots.svg';

const SectionViewTypes = {
  CALENDAR_VIEW: 'CALENDAR_VIEW',
  PLANNER_VIEW: 'PLANNER_VIEW',
};

const WhatIsItSectionConfig = {
  [SectionViewTypes.CALENDAR_VIEW]: {
    title: {
      alt: 'My planner',
      src: {
        [LanguagesMap.EN_US]: '/media/lesson-planner/what_it_is_section_calendar_title_en.svg',
        [LanguagesMap.ES_US]: '/media/lesson-planner/what_it_is_section_calendar_title_es.svg',
      },
    },
    hint: {
      alt: 'Select me',
      src: {
        [LanguagesMap.EN_US]: '/media/lesson-planner/what_it_is_section_calendar_hint_en.svg',
        [LanguagesMap.ES_US]: '/media/lesson-planner/what_it_is_section_calendar_hint_es.svg',
      },
    },
  },
  [SectionViewTypes.PLANNER_VIEW]: {
    button: {
      alt: 'Go back',
      component: {
        [LanguagesMap.EN_US]: BackButtonEn,
        [LanguagesMap.ES_US]: BackButtonEs,
      },
    },
    title: {
      alt: 'lesson',
      src: {
        [LanguagesMap.EN_US]: '/media/lesson-planner/what_it_is_section_planner_title_en.svg',
        [LanguagesMap.ES_US]: '/media/lesson-planner/what_it_is_section_planner_title_es.svg',
      },
    },
    hint: {
      alt: 'Navigate back',
      src: {
        [LanguagesMap.EN_US]: '/media/lesson-planner/what_it_is_section_planner_hint_en.svg',
        [LanguagesMap.ES_US]: '/media/lesson-planner/what_it_is_section_planner_hint_es.svg',
      },
    },
    planner: {
      alt: 'Some empty fields from different blocks of a lesson in the planner.',
      src: {
        [LanguagesMap.EN_US]: {
          desktop: '/media/lesson-planner/what_it_is_section_planner_en.svg',
          mobile: '/media/lesson-planner/what_it_is_section_planner_mobile_en.svg',
        },
        [LanguagesMap.ES_US]: {
          desktop: '/media/lesson-planner/what_it_is_section_planner_es.svg',
          mobile: '/media/lesson-planner/what_it_is_section_planner_mobile_es.svg',
        },
      },
    },
  },
};

const getCalendarCardsConfig = (isWidthUpSm) => {
  const elementInRowCount = 5;
  const focusableCardIndex = 2;
  const firstPattern = Array(elementInRowCount)
    .fill({ component: isWidthUpSm ? CalendarCardPurple : CalendarCardPurpleMobile })
    .map((element, index) =>
      index % 2 === 0
        ? element
        : { component: isWidthUpSm ? CalendarCardOrange : CalendarCardOrangeMobile },
    );
  const secondPattern = Array(elementInRowCount)
    .fill({ component: CalendarCardGreen })
    .map((element, index) => (index % 2 === 0 ? element : { component: CalendarCardPurple }));
  const thirdPattern = Array(elementInRowCount).fill({ component: CalendarCardPurpleMobile });

  const cardsConfig = isWidthUpSm
    ? [...firstPattern, ...secondPattern, ...firstPattern].flat()
    : [...firstPattern, ...thirdPattern, ...thirdPattern].flat();

  return cardsConfig.map((item, index) => ({
    ...item,
    id: uuidv4(),
    isFocusable: index === focusableCardIndex,
  }));
};

const LessonPlannerWhatIsItSection = () => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const { state: userState, dispatch: dispatchUserState } = useContext(UserContext);
  const { getOneTimeActionStatus } = useProfile();
  const { postOneTimeAction } = useOneTimeActionService();
  const [sectionView, setSectionView] = useState(SectionViewTypes.CALENDAR_VIEW);

  const informationalMessageHidden = useMemo(
    () => getOneTimeActionStatus(OneTimeActionsMap.LESSON_PLANNER_LANDING_NOTIFICATION),
    [getOneTimeActionStatus],
  );

  const informationalMessageHandler = useCallback(() => {
    postOneTimeAction(OneTimeActionsMap.LESSON_PLANNER_LANDING_NOTIFICATION);
    dispatchUserState({
      type: UserActions.SET_ONE_TIME_ACTION,
      data: OneTimeActionsMap.LESSON_PLANNER_LANDING_NOTIFICATION,
    });
  }, [dispatchUserState, postOneTimeAction]);

  const activeLang = useMemo(
    () => LanguagesMap[getNormalizedLanguageCode(i18n.language)],
    [i18n.language],
  );

  const BackButton = useMemo(
    () =>
      sectionView === SectionViewTypes.PLANNER_VIEW &&
      WhatIsItSectionConfig[sectionView].button.component[activeLang],
    [activeLang, sectionView],
  );

  const viewOnClickHandler = useCallback((view, gaLabel, gaActions) => {
    setSectionView(view);
    GA.logInteraction({
      category: GaCategories.BEHAVIOR,
      action: gaActions,
      label: gaLabel,
    });
  }, []);

  const viewOnKeyDownHandler = useCallback(
    (e, view, gaLabel, gaActions) => {
      if (e.key === KeyboardMap.ENTER) {
        viewOnClickHandler(view, gaLabel, gaActions);
      }
    },
    [viewOnClickHandler],
  );

  return (
    <Box className="ayo-lesson-planner-landing__what-is-it-section" py={isWidthUpSm ? 20 : 10}>
      <Container>
        <Grid container justifyContent="center">
          <Grid container item justifyContent="center" md={6} px={2} sm={8} xs={12}>
            <Typography component="h2" variant="h1">
              {t('What is it?')}
            </Typography>
            <Box mb={5} mt={3}>
              <Typography align="center" variant="body2">
                {t(
                  'Lesson planner is an AYO tool that empowers teachers and instructors to build impactful lessons that align with the TEKS.',
                )}
              </Typography>
            </Box>
            {!informationalMessageHidden && userState.oneTimeActions && (
              <Box mb={5}>
                <InformationalMessage
                  onClick={informationalMessageHandler}
                  text={t('Some elements in the planner below are clickable. Give it a try.')}
                />
              </Box>
            )}
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <Grid
            className="ayo-lesson-planner-landing__what-is-it-section__content"
            item
            md={8}
            pb={5}
            pt={3}
            px={3}
            sm={10}
            xs={12}
          >
            <CalendarDots />
            <Box mb={7} mt={3}>
              <Divider />
            </Box>
            {sectionView === SectionViewTypes.CALENDAR_VIEW ? (
              <Box
                key="calendar-view"
                className="ayo-lesson-planner-landing__what-is-it-section__content--calendar-view"
                position="relative"
              >
                <Box mb={6}>
                  <Image
                    alt={t(WhatIsItSectionConfig[sectionView].title.alt)}
                    className="ayo-lesson-planner-landing__what-is-it-section__content--calendar-view__title"
                    src={WhatIsItSectionConfig[sectionView].title.src[activeLang]}
                  />
                </Box>
                <Image
                  alt={t(WhatIsItSectionConfig[sectionView].hint.alt)}
                  className="ayo-lesson-planner-landing__what-is-it-section__content--calendar-view__hint"
                  src={WhatIsItSectionConfig[sectionView].hint.src[activeLang]}
                />
                <DynamicGrid
                  columnsCount={5}
                  gridItems={getCalendarCardsConfig(isWidthUpSm).map((item) => (
                    <item.component
                      key={item.id}
                      aria-hidden={!item.isFocusable}
                      aria-label={t('Select me')}
                      className="ayo-lesson-planner-landing__what-is-it-section__content--calendar-view__card"
                      onClick={() =>
                        viewOnClickHandler(
                          SectionViewTypes.PLANNER_VIEW,
                          'Calendar card',
                          GaActions.CARD_CLICK,
                        )
                      }
                      onKeyDown={(e) =>
                        viewOnKeyDownHandler(
                          e,
                          SectionViewTypes.PLANNER_VIEW,
                          'Calendar card',
                          GaActions.CARD_CLICK,
                        )
                      }
                      role="button"
                      tabIndex={item.isFocusable ? '0' : '-1'}
                    />
                  ))}
                  spacingX={isWidthUpSm ? 3 : 0}
                  spacingY={isWidthUpSm ? 4 : 0}
                />
              </Box>
            ) : (
              <Box
                key="planner-view"
                className="ayo-lesson-planner-landing__what-is-it-section__content--planner-view"
                position="relative"
              >
                <Box display="flex" justifyContent="center" mb={6} position="relative">
                  <BackButton
                    aria-label={t('Go back')}
                    className="ayo-lesson-planner-landing__what-is-it-section__content--planner-view__button"
                    onClick={() =>
                      viewOnClickHandler(
                        SectionViewTypes.CALENDAR_VIEW,
                        'Go back',
                        GaActions.BUTTON_CLICK,
                      )
                    }
                    onKeyDown={(e) =>
                      viewOnKeyDownHandler(
                        e,
                        SectionViewTypes.CALENDAR_VIEW,
                        'Go back',
                        GaActions.BUTTON_CLICK,
                      )
                    }
                    role="button"
                    tabIndex={0}
                  />
                  <Image
                    alt={t(WhatIsItSectionConfig[sectionView].hint.alt)}
                    className="ayo-lesson-planner-landing__what-is-it-section__content--planner-view__hint"
                    src={WhatIsItSectionConfig[sectionView].hint.src[activeLang]}
                  />
                  <Image
                    alt={t(WhatIsItSectionConfig[sectionView].title.alt)}
                    className="ayo-lesson-planner-landing__what-is-it-section__content--planner-view__title"
                    src={WhatIsItSectionConfig[sectionView].title.src[activeLang]}
                  />
                </Box>
                <Image
                  alt={t(WhatIsItSectionConfig[sectionView].planner.alt)}
                  className="ayo-lesson-planner-landing__what-is-it-section__content--planner-view__planner"
                  src={
                    isWidthUpSm
                      ? WhatIsItSectionConfig[sectionView].planner.src[activeLang].desktop
                      : WhatIsItSectionConfig[sectionView].planner.src[activeLang].mobile
                  }
                />
              </Box>
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default LessonPlannerWhatIsItSection;
