import React, { useState, useCallback, useMemo, useContext, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Box, DialogActions, DialogContent, Grid } from '@mui/material';
import PropTypes from 'prop-types';

import { Dialog, DialogTitle, TextField, Typography } from '../../../../../atoms';
import { EditingButton, PopupActionsButtons } from '../../../../../moleculas';
import { useContentModerationService } from '../../../../../../services';
import { InputsValidationErrors, InputsValidationRules } from '../../../../../../constants/enums';
import { ReactComponent as RevertIcon } from '../../../../../../resources/icons/arrows_in_circle.svg';
import { AppActions, AppContext } from '../../../../../../context';

const getUpdatedTranslations = (moderationEntity, translationValues) => {
  const moderationEntityKeys = Object.keys(moderationEntity.keys);
  const newTranslation = moderationEntityKeys.map((key) => ({
    [key]: {
      ...moderationEntity.keys[key],
      ES_US_updated:
        translationValues[`${key}Value`] === moderationEntity.keys[key].ES_US
          ? null
          : translationValues[`${key}Value`],
    },
  }));
  return Object.assign(...newTranslation);
};

const TranslationEditDialog = ({ moderationEntity, isOpen, onClose, onEntityUpdateHandler }) => {
  const { t } = useTranslation();
  const initialSpanishName =
    moderationEntity.keys.name.ES_US_updated || moderationEntity.keys.name.ES_US;
  const initialSpanishDescription =
    moderationEntity.keys.description?.ES_US_updated || moderationEntity.keys.description?.ES_US;
  const { dispatch: dispatchAppState } = useContext(AppContext);
  const { postContentTranslation } = useContentModerationService();
  const [nameValue, setNameValue] = useState('');
  const [descriptionValue, setDescriptionValue] = useState('');
  const [nameErrorMessage, setNameErrorMessage] = useState('');
  const [descriptionErrorMessage, setDescriptionErrorMessage] = useState('');

  const ValueConverterMap = useMemo(
    () => ({
      descriptionValue,
      nameValue,
    }),
    [descriptionValue, nameValue],
  );

  useEffect(() => {
    setNameValue(initialSpanishName);
    setDescriptionValue(initialSpanishDescription || '');
  }, [initialSpanishName, initialSpanishDescription, isOpen]);

  const isValid = useCallback(() => {
    let nameValidationError = '';
    let descriptionValidationError = '';

    if (nameValue.length < InputsValidationRules.MIN_INPUT_LENGTH) {
      nameValidationError = InputsValidationErrors(
        t,
        InputsValidationRules.MIN_INPUT_LENGTH,
      ).MIN_ERROR_TEXT;
    } else if (nameValue.length > InputsValidationRules.MAX_EDIT_CONTENT_INPUT_LENGTH) {
      nameValidationError = InputsValidationErrors(
        t,
        InputsValidationRules.MAX_EDIT_CONTENT_INPUT_LENGTH,
      ).MAX_ERROR_TEXT;
    }
    if (moderationEntity.keys.description?.translationKey) {
      if (descriptionValue.length < InputsValidationRules.MIN_INPUT_LENGTH) {
        descriptionValidationError = InputsValidationErrors(
          t,
          InputsValidationRules.MIN_INPUT_LENGTH,
        ).MIN_ERROR_TEXT;
      } else if (descriptionValue.length > InputsValidationRules.MAX_EDIT_CONTENT_INPUT_LENGTH) {
        descriptionValidationError = InputsValidationErrors(
          t,
          InputsValidationRules.MAX_EDIT_CONTENT_INPUT_LENGTH,
        ).MAX_ERROR_TEXT;
      }
    }
    setNameErrorMessage(nameValidationError);
    setDescriptionErrorMessage(descriptionValidationError);
    return !nameValidationError && !descriptionValidationError;
  }, [t, nameValue, descriptionValue, moderationEntity]);

  const handleNameChange = useCallback((data, isRevertToInitial) => {
    if (isRevertToInitial) {
      setNameValue(data);
    } else {
      setNameValue(data.target.value);
    }
    setNameErrorMessage('');
  }, []);

  const handleDescriptionChange = useCallback((data, isRevertToInitial) => {
    if (isRevertToInitial) {
      setDescriptionValue(data);
    } else {
      setDescriptionValue(data.target.value);
    }
    setDescriptionErrorMessage('');
  }, []);

  const ModerationVariantMap = useMemo(
    () => ({
      name: {
        typographyVariant: 'subtitle2',
        value: nameValue,
        errorMessage: nameErrorMessage,
        handler: handleNameChange,
      },
      description: {
        typographyVariant: 'body2',
        value: descriptionValue,
        errorMessage: descriptionErrorMessage,
        handler: handleDescriptionChange,
      },
    }),
    [
      descriptionValue,
      handleDescriptionChange,
      handleNameChange,
      nameValue,
      descriptionErrorMessage,
      nameErrorMessage,
    ],
  );

  const updatedTranslations = useMemo(
    () => getUpdatedTranslations(moderationEntity, ValueConverterMap),
    [moderationEntity, ValueConverterMap],
  );

  const onCloseHandler = useCallback(() => {
    onClose();
  }, [onClose]);

  const revertToInitial = useCallback(
    (keyToRevert) => {
      ModerationVariantMap[keyToRevert].handler(
        moderationEntity.keys[keyToRevert].ES_US,
        'isRevertToInitial',
      );
    },
    [moderationEntity, ModerationVariantMap],
  );

  const onSaveAndSubmitHandler = useCallback(() => {
    if (isValid()) {
      const opportunityName = moderationEntity.keys.name.EN_US;
      onCloseHandler();
      postContentTranslation(
        moderationEntity.entityKey,
        moderationEntity.keys.name.translationKey,
        'ES_US',
        updatedTranslations,
        true,
      )
        .then(() => {
          onEntityUpdateHandler(moderationEntity, {
            isApproved: true,
            keys: { ...updatedTranslations },
          });
          dispatchAppState({
            type: AppActions.SET_SNACKBAR_STATUS,
            data: {
              text: (
                <Trans
                  components={{ b: <b /> }}
                  i18nKey="Translation for {{opportunityName}} has been saved and approved"
                  values={{
                    opportunityName,
                  }}
                />
              ),
              type: 'success',
            },
          });
        })
        .catch(() => {
          dispatchAppState({
            type: AppActions.SET_SNACKBAR_STATUS,
            data: {
              text: (
                <Trans
                  components={{ b: <b /> }}
                  i18nKey="Failed to save and approve translation for {{opportunityName}}, please try once more"
                  values={{
                    opportunityName,
                  }}
                />
              ),
              type: 'error',
            },
          });
        });
    }
  }, [
    moderationEntity,
    postContentTranslation,
    updatedTranslations,
    onEntityUpdateHandler,
    dispatchAppState,
    onCloseHandler,
    isValid,
  ]);

  return (
    <Dialog className="ayo-translation-edit-dialog" onClose={onClose} open={isOpen}>
      <DialogTitle disableTypography>
        <Typography variant="subtitle1">{t('Edit translation')}</Typography>
      </DialogTitle>
      <DialogContent>
        <Box mb={5} mt={5}>
          <Box mb={1} px={2}>
            <Grid container justifyContent="space-between">
              <Grid item md={6}>
                <Typography variant="caption">{t('Original (EN)')}</Typography>
              </Grid>
              <Grid item md={6}>
                <Typography variant="caption">{t('Translation (ES)')}</Typography>
              </Grid>
            </Grid>
          </Box>
          <Box className="ayo-translation-edit-dialog__content">
            {Object.entries(moderationEntity.keys).map(
              ([entityKeysValue, entityTextData]) =>
                entityTextData.translationKey && (
                  <Grid key={moderationEntity.entityKey} container justifyContent="space-between">
                    <Grid item md={6}>
                      <Box className="ayo-translation-edit-dialog__name">
                        <Typography
                          variant={ModerationVariantMap[entityKeysValue].typographyVariant}
                        >
                          <span>{entityTextData.EN_US}</span>
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item md={6}>
                      <Box
                        className="ayo-translation-edit-dialog__editable-field"
                        mb={ModerationVariantMap[entityKeysValue].errorMessage ? 2.5 : 0}
                      >
                        <TextField
                          className={`ayo-translation-edit-dialog__editable-field__${entityKeysValue}`}
                          error={ModerationVariantMap[entityKeysValue].errorMessage}
                          fullWidth
                          helperText={ModerationVariantMap[entityKeysValue].errorMessage}
                          inputProps={{
                            'aria-label': `${t('Edit')}-${entityKeysValue}`,
                            id: `${moderationEntity.entityKey}-${entityKeysValue}`,
                            lang: 'es',
                          }}
                          multiline
                          onChange={ModerationVariantMap[entityKeysValue].handler}
                          value={ModerationVariantMap[entityKeysValue].value}
                        />
                      </Box>
                      <EditingButton
                        gaLabel={`Revert ${entityKeysValue} to initial`}
                        icon={<RevertIcon />}
                        iconPosition="end"
                        onClick={() => {
                          revertToInitial(entityKeysValue);
                        }}
                        text={t('Revert to initial')}
                      />
                    </Grid>
                  </Grid>
                ),
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <PopupActionsButtons
          className="ayo-translation-edit-dialog__buttons"
          primaryButtonGaLabel="Save and approve"
          primaryButtonHandler={onSaveAndSubmitHandler}
          primaryButtonText={t('Save and approve')}
          secondaryButtonGaLabel="Cancel"
          secondaryButtonHandler={onCloseHandler}
          secondaryButtonText={t('Cancel')}
        />
      </DialogActions>
    </Dialog>
  );
};

TranslationEditDialog.propTypes = {
  moderationEntity: PropTypes.shape({
    category: PropTypes.string,
    entityKey: PropTypes.string,
    isVisible: PropTypes.bool,
    source: PropTypes.string,
    url: PropTypes.string,
    keys: PropTypes.instanceOf(Object),
  }),
  isOpen: PropTypes.bool.isRequired,
  onEntityUpdateHandler: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

TranslationEditDialog.defaultProps = {
  moderationEntity: {},
};

export default TranslationEditDialog;
