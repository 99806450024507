import { useEffect, useRef } from 'react';
import { useInView as reactUseInView } from 'react-intersection-observer';

const useInView = (inViewCallback, timeout, stopExecution) => {
  const { ref, inView } = reactUseInView({ threshold: 1 });
  const timer = useRef();

  const clearTimer = () => {
    clearTimeout(timer.current);
    timer.current = null;
  };

  useEffect(() => {
    if (!stopExecution && inViewCallback && inView && !timer.current) {
      timer.current = setTimeout(() => {
        inViewCallback();
        timer.current = null;
      }, timeout);
    }
    // Clear previous timer if it was reset during some action
    if (timer.current && stopExecution) {
      clearTimer();
    }
  }, [inViewCallback, inView, timeout, stopExecution]);

  useEffect(
    () => () => {
      // Clear timer on unmount
      if (timer.current) {
        clearTimer();
      }
    },
    [],
  );

  return { ref };
};

export default useInView;
