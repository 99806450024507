import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { Button, Typography } from '../../atoms';
import { ScreenCenteredContainer, PageIllustrationBlock } from '../../moleculas';
import { UserContext } from '../../../context';
import { useProfile } from '../../../utils';
import { RolesMap } from '../../../constants/enums';
import { campusSelectionRoute } from '../../../constants/routes';
import { ReactComponent as NoStudentsAnalyticsImage } from '../../../resources/images/analytics_no_students.svg';
import { ReactComponent as NoStudentsImage } from '../../../resources/images/students-list-no-students.svg';
import { ReactComponent as NoStudentsImageTeacher } from '../../../resources/images/students_list_no_students_teacher.svg';

const NoStudentsScreenVariant = {
  ANALYTICS_PAGE: 'analyticsPage',
  STUDENTS_PAGE: 'studentsPage',
};

const NoStudentsScreenContent = {
  [NoStudentsScreenVariant.ANALYTICS_PAGE]: {
    [RolesMap.ADMINISTRATOR]: {
      illustration: NoStudentsAnalyticsImage,
      illustrationAriaLabel:
        'A building of a school and a student with backpack entering through the door',
      mainHeaderText: 'Analytics will appear here when the students log in',
      secondaryText: 'Once the students log in to AYO, you will see more information here',
    },
  },
  [NoStudentsScreenVariant.STUDENTS_PAGE]: {
    [RolesMap.ADMINISTRATOR]: {
      illustration: NoStudentsImage,
      illustrationAriaLabel: 'A school with AYO sign and a sidewalk sign saying open',
      mainHeaderText: 'It might take up to 24 hours before you see your students here',
      secondaryText:
        "When one or more of your students join AYO App, you'll receive an email notification.",
    },
    [RolesMap.TEACHER]: {
      illustration: NoStudentsImageTeacher,
      illustrationAriaLabel: 'A class with 3 empty desks and a blackboard',
      mainHeaderText: 'It might take up to 24 hours before you see your students here',
      secondaryText:
        "When one or more of your students join AYO App, you'll receive an email notification.",
    },
  },
};

const NoStudentsScreen = ({ variant }) => {
  const { t } = useTranslation();
  const { state: userState } = useContext(UserContext);
  const { getIsCampusSelectionAvailable } = useProfile();
  const userRole = userState?.profile?.role;

  return (
    <Box className="ayo-no-student-screen">
      <ScreenCenteredContainer>
        <PageIllustrationBlock
          Illustration={NoStudentsScreenContent[variant][userRole].illustration}
          illustrationAriaLabel={t(
            NoStudentsScreenContent[variant][userRole].illustrationAriaLabel,
          )}
          mainHeaderText={t(NoStudentsScreenContent[variant][userRole].mainHeaderText)}
        />
        <Box alignItems="center" display="flex" flexDirection="column">
          <Typography
            align="center"
            className="ayo-no-student-screen__secondary-text"
            variant="body2"
          >
            {t(NoStudentsScreenContent[variant][userRole].secondaryText)}
          </Typography>
          {getIsCampusSelectionAvailable() && (
            <Box mt={3}>
              <Button
                component={RouterLink}
                gaLabel="Go to campus selection"
                isAdaptive
                to={campusSelectionRoute}
                variant="primary"
              >
                {t('Go to campus selection')}
              </Button>
            </Box>
          )}
        </Box>
      </ScreenCenteredContainer>
    </Box>
  );
};

NoStudentsScreen.propTypes = {
  variant: PropTypes.oneOf([
    NoStudentsScreenVariant.ANALYTICS_PAGE,
    NoStudentsScreenVariant.STUDENTS_PAGE,
  ]),
};

NoStudentsScreen.defaultProps = {
  variant: NoStudentsScreenVariant.STUDENTS_PAGE,
};

export default NoStudentsScreen;
