import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

import InsightCategoryCard from '../insight-category-card/InsightCategoryCard';
import { getInsightCategoriesDistributedStudents } from '../../shared';
import { assignmentInsightsCategoryType } from '../../../../../../../constants/propTypes';

const InsightCardsGrid = ({ insights, totalStudentsCount, onCategoryClick }) => {
  const distributedStudentsByCategory = useMemo(
    () => getInsightCategoriesDistributedStudents(insights),
    [insights],
  );

  const onCategoryClickHandler = useCallback(
    (category) => onCategoryClick(category, insights),
    [insights, onCategoryClick],
  );

  return (
    <Grid container spacing={2}>
      {Object.entries(distributedStudentsByCategory)
        .sort(
          ([, categoryAStudents], [, categoryBStudents]) => categoryBStudents - categoryAStudents,
        )
        .map(([categoryName, categoryDistributedStudents], idx) => (
          <Grid key={categoryName} item xs={2}>
            <InsightCategoryCard
              categoryDistributedStudents={categoryDistributedStudents}
              categoryName={categoryName}
              isSuggested={idx === 0}
              onClick={onCategoryClickHandler}
              totalStudents={totalStudentsCount}
            />
          </Grid>
        ))}
    </Grid>
  );
};

InsightCardsGrid.propTypes = {
  insights: PropTypes.arrayOf(PropTypes.shape(assignmentInsightsCategoryType)).isRequired,
  totalStudentsCount: PropTypes.number.isRequired,
  onCategoryClick: PropTypes.func.isRequired,
};

export default InsightCardsGrid;
