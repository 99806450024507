/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Box, Divider, Grid } from '@mui/material';

import { Typography } from '../../../../../../../atoms';
import { EditingButton, InformationalCaption } from '../../../../../../../moleculas';
import { ReactComponent as ExpandIcon } from '../../../../../../../../resources/icons/chevron_down.svg';
import { ReactComponent as CollapseIcon } from '../../../../../../../../resources/icons/chevron_up.svg';
import { QuestionnaireStatusMap } from '../../../../../../../../constants/enums';
import { handleTemplateString } from '../../../../../../../../utils';
import AnswersBlock from '../answers-block/AnswersBlock';

const QuestionnaireTypeBlock = ({ questions, title, student, withDivider }) => {
  const { t } = useTranslation();
  const [isAnswersCollapsed, setIsAnswersCollapsed] = useState(true);

  return (
    <>
      {!!(
        Object.keys(questions[QuestionnaireStatusMap.OPEN]).length ||
        Object.keys(questions[QuestionnaireStatusMap.CLOSE]).length
      ) && (
        <Box>
          {withDivider && <Divider className="ayo-student-details-page__questions-divider" />}
          <Typography component="h3" variant="subtitle1">
            {title}
          </Typography>
          <Grid item sm={7} xs={12}>
            {Object.entries(questions[QuestionnaireStatusMap.OPEN]).map(([question, answers]) => (
              <>
                <Box key={question} className="ayo-student-details-page__question">
                  <Typography component="h3" variant="subtitle2">
                    {handleTemplateString(question, student)}
                  </Typography>
                </Box>
                <AnswersBlock answers={answers} />
              </>
            ))}
            {!!Object.keys(questions[QuestionnaireStatusMap.CLOSE]).length && (
              <Box ml={2} mt={6.5}>
                {isAnswersCollapsed ? (
                  <Box alignItems="center" display="flex">
                    <EditingButton
                      className="bold-btn"
                      gaLabel="Show previously answered questions"
                      icon={<ExpandIcon />}
                      iconPosition="end"
                      onClick={() => {
                        setIsAnswersCollapsed(false);
                      }}
                      text={t('Show previously answered questions')}
                    />
                    <Box ml={3}>
                      <InformationalCaption
                        showTooltip
                        title={t(
                          'Show questions from parents’ previous questionnaires that aren’t connected with the current one',
                        )}
                      />
                    </Box>
                  </Box>
                ) : (
                  <EditingButton
                    className="bold-btn"
                    gaLabel="Hide previously answered questions"
                    icon={<CollapseIcon />}
                    iconPosition="end"
                    onClick={() => {
                      setIsAnswersCollapsed(true);
                    }}
                    text={t('Hide previously answered questions')}
                  />
                )}
                {!isAnswersCollapsed &&
                  Object.entries(questions[QuestionnaireStatusMap.CLOSE]).map(
                    ([question, answers]) => (
                      <>
                        <Box key={question} className="ayo-student-details-page__question">
                          <Typography component="h3" variant="subtitle2">
                            {handleTemplateString(question, student)}
                          </Typography>
                        </Box>
                        <AnswersBlock answers={answers} />
                      </>
                    ),
                  )}
              </Box>
            )}
          </Grid>
        </Box>
      )}
    </>
  );
};

QuestionnaireTypeBlock.propTypes = {
  questions: PropTypes.shape({
    OPEN: PropTypes.instanceOf(Object),
    CLOSE: PropTypes.instanceOf(Object),
  }),
  student: PropTypes.shape({
    firstName: PropTypes.string,
    grade: PropTypes.string,
    id: PropTypes.number,
    lastName: PropTypes.string,
    schoolName: PropTypes.string,
  }).isRequired,
  withDivider: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

QuestionnaireTypeBlock.defaultProps = {
  questions: {},
  withDivider: false,
};

export default QuestionnaireTypeBlock;
