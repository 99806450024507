import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Box } from '@mui/material';

import { Card, Typography } from '../../../../atoms';
import { EmploymentTypesMap, PortfolioSectionTypes } from '../../../../../constants/enums';
import { getFormattedDate } from '../../../../../utils';
import { ReactComponent as EmploymentEmptyStateIllustration } from '../../../../../resources/images/employment_empty_state.svg';
import { ReactComponent as DatePickerIcon } from '../../../../../resources/icons/date_picker_black.svg';
import { ReactComponent as ReflectionIcon } from '../../../../../resources/icons/reflection_black.svg';
import { ReactComponent as BuildingIcon } from '../../../../../resources/icons/building_bank.svg';
import PortfolioSection from '../../components/portfolio-section/PortfolioSection';
import PortfolioAttachments from '../../components/portfolio-attachments/PortfolioAttachments';
import { InitiativeEmptyStateBlock } from '../../../../moleculas';

const PortfolioSharedEmploymentsSection = ({ sectionData, token }) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <PortfolioSection
        body={
          <Box>
            {sectionData.entities.map((employment) => (
              <Card
                key={employment.id}
                className="ayo-portfolio__employments-section__card ayo-portfolio__employments-section__card__cropped"
                mainContent={
                  <Box className="ayo-portfolio__employments-section__card__content">
                    <Box width="100%">
                      <Box alignItems="center" display="flex" justifyContent="space-between">
                        <Typography component="h3" variant="subtitle1">
                          {employment.role}
                        </Typography>
                      </Box>
                      <Box display="flex" my={2}>
                        <Box>
                          <BuildingIcon />
                        </Box>
                        <Typography
                          className="ayo-portfolio__employments-section__card__content__text"
                          component="h3"
                          variant="caption"
                        >
                          {employment.organization}
                        </Typography>
                      </Box>
                      <Box display="flex" my={2}>
                        <Box>
                          <BuildingIcon />
                        </Box>
                        <Typography
                          className="ayo-portfolio__employments-section__card__content__text"
                          component="h3"
                          variant="caption"
                        >
                          {t(EmploymentTypesMap[employment.employmentType])}
                        </Typography>
                      </Box>
                      {employment.startDate && (
                        <Box display="flex" my={2}>
                          <Box>
                            <DatePickerIcon />
                          </Box>
                          <Typography
                            className="ayo-portfolio__employments-section__card__content__text"
                            component="h3"
                            variant="caption"
                          >
                            {`${getFormattedDate(employment.startDate, i18n.language)} - ${
                              employment.currentPosition || !employment?.endDate
                                ? t('Present')
                                : getFormattedDate(employment?.endDate, i18n.language)
                            }`}
                          </Typography>
                        </Box>
                      )}
                      {employment?.description && (
                        <Box display="flex" my={2}>
                          <Box>
                            <ReflectionIcon />
                          </Box>
                          <Typography
                            className="ayo-portfolio__employments-section__card__content__text"
                            component="h3"
                            variant="caption"
                          >
                            {employment.description}
                          </Typography>
                        </Box>
                      )}
                      {!!employment?.attachments.length && (
                        <Box width="82%">
                          <PortfolioAttachments
                            attachments={employment.attachments}
                            gridItemBreakpoint={3}
                            token={token}
                          />
                        </Box>
                      )}
                    </Box>
                  </Box>
                }
              />
            ))}
          </Box>
        }
        className={`${classNames('ayo-full-screen-portfolio-section', {
          'ayo-portfolio__employments-section': sectionData.entities.length,
        })}`}
        emptyState={
          !sectionData.entities.length ? (
            <InitiativeEmptyStateBlock
              illustration={<EmploymentEmptyStateIllustration />}
              title={t('No employment added so far')}
            />
          ) : null
        }
        reflection={sectionData.reflection}
        sectionKey={PortfolioSectionTypes.PORTFOLIO__EMPLOYMENT}
        title={t('Employment')}
      />
    </>
  );
};

PortfolioSharedEmploymentsSection.propTypes = {
  sectionData: PropTypes.instanceOf(Object).isRequired,
  token: PropTypes.string.isRequired,
};

export default PortfolioSharedEmploymentsSection;
