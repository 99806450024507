/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useState } from 'react';
import { Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Loader, SearchField } from '../../../../moleculas';
import { loginAllowedFilterRE } from '../../../../../constants/regexps';
import { getNormalizedLanguageCode } from '../../../../../utils';
import { useFeedService } from '../../../../../services';

const SEARCH_MIN_LENGTH = 2;

const FamilyActivitiesSearch = ({ onSearch, pageSize, searchFieldRef, studentId }) => {
  const { t, i18n } = useTranslation();

  const { getFamilyActivitiesSearched } = useFeedService();

  const [preSearchValue, setPreSearchValue] = useState(null);
  const [activitiesSearchSuggestions, setActivitiesSearchSuggestions] = useState([]);
  const [isLoadingSearchSuggestions, setIsLoadingSearchSuggestions] = useState(false);

  const handleSearchValueChange = useCallback(
    (value) => {
      if (value) {
        if (
          value.length >= SEARCH_MIN_LENGTH &&
          value.substring(0, SEARCH_MIN_LENGTH) !== preSearchValue?.substring(0, SEARCH_MIN_LENGTH)
        ) {
          setIsLoadingSearchSuggestions(true);
          setPreSearchValue(value);
          setActivitiesSearchSuggestions([]);

          getFamilyActivitiesSearched(
            studentId,
            getNormalizedLanguageCode(i18n.language),
            1,
            pageSize,
            value,
            true,
          ).then((data) => {
            const mappedTitles = data.familyActivitiesTitles.map((title) => ({
              id: title,
              name: title,
            }));

            setActivitiesSearchSuggestions(mappedTitles);
            setIsLoadingSearchSuggestions(false);
          });
        }
      } else {
        onSearch();
        setPreSearchValue(null);
        setActivitiesSearchSuggestions([]);
      }
    },
    [getFamilyActivitiesSearched, i18n.language, onSearch, pageSize, preSearchValue, studentId],
  );

  const handleSearch = useCallback(
    (value) => {
      if (value) {
        getFamilyActivitiesSearched(
          studentId,
          getNormalizedLanguageCode(i18n.language),
          1,
          pageSize,
          value,
          false,
          true,
        ).then((data) => {
          onSearch(data.totalElements, data.familyActivities, value, 2);
        });
      } else {
        onSearch();
      }
    },
    [getFamilyActivitiesSearched, i18n.language, onSearch, pageSize, studentId],
  );

  const CustomPaper = (props) => (
    <Paper
      {...props}
      // eslint-disable-next-line react/prop-types,react/destructuring-assignment
      className={classNames(props.className, 'ayo-family-activities-page__search__paper')}
    />
  );

  return (
    <SearchField
      apiRef={searchFieldRef}
      className="ayo-family-activities-page__search"
      criterias={['name']}
      gaLabel="Search Family activity"
      inputRE={loginAllowedFilterRE}
      label={t('Search by title of the activity')}
      loading={isLoadingSearchSuggestions}
      loadingText={
        <Loader
          isLoading={isLoadingSearchSuggestions}
          labelText="Loading suggestions"
          typographyVariant="body2"
        />
      }
      maxRowsCount={5}
      minInputLength={SEARCH_MIN_LENGTH}
      onChange={handleSearchValueChange}
      onOptionClick={handleSearch}
      onSearch={handleSearch}
      PaperComponent={CustomPaper}
      suggestions={activitiesSearchSuggestions}
    />
  );
};

FamilyActivitiesSearch.propTypes = {
  onSearch: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
  searchFieldRef: PropTypes.func.isRequired,
  studentId: PropTypes.string.isRequired,
};

export default FamilyActivitiesSearch;
