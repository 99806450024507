import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

export const getYearDifference = (date1, date2) => {
  let yearDiff = date1.getFullYear() - date2.getFullYear();
  if (date2.getMonth() > date1.getMonth()) {
    yearDiff -= 1;
  } else if (date2.getMonth() === date1.getMonth()) {
    if (date2.getDate() > date1.getDate()) {
      yearDiff -= 1;
    }
  }
  return yearDiff;
};

export const getFormattedDate = (date, currentLanguage, format) => {
  const dateToShow = new Date(date);
  const defaultFormat = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };
  return dateToShow.toLocaleString(currentLanguage, format ?? defaultFormat);
};

export const getFullDateAndHoursString = (date, currentLanguage) => {
  const currentDateObj = new Date();
  const dateObj = new Date(date);
  const dateDisplayConfig = {
    date: {
      month: 'short',
      day: '2-digit',
      weekday: 'short',
    },
    time: {
      hour12: true,
      hour: 'numeric',
      minute: '2-digit',
    },
  };
  if (dateObj.getFullYear() !== currentDateObj.getFullYear()) {
    dateDisplayConfig.date = { ...dateDisplayConfig.date, year: 'numeric' };
  }
  return `${dateObj.toLocaleDateString(
    currentLanguage,
    dateDisplayConfig.date,
  )}, ${dateObj.toLocaleTimeString(currentLanguage, dateDisplayConfig.time)}`;
};

export const getFormattedDurationRange = (startDate, endDate, currentLanguage) => {
  const start = getFullDateAndHoursString(startDate, currentLanguage);
  const end = dayjs(startDate).isSame(endDate, 'date')
    ? new Date(endDate).toLocaleTimeString(currentLanguage, {
        hour12: true,
        hour: 'numeric',
        minute: '2-digit',
      })
    : getFullDateAndHoursString(endDate, currentLanguage);

  return `${start} - ${end}`;
};

export const getDuration = (startDate, endDate, lang) => {
  const durationObj = dayjs.duration(dayjs(endDate).diff(dayjs(startDate)));

  const parts = ['years', 'months', 'days', 'hours', 'minutes'];

  return parts
    .filter((unit) => durationObj.get(unit) > 0)
    .map((unit) => `${durationObj.get(unit)}${lang(`duration.${unit}`)}`)
    .join(' ');
};

export const formatSeconds = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const displaySeconds = seconds % 60;
  return `${minutes > 9 ? minutes : `0${minutes}`}:${
    displaySeconds > 9 ? displaySeconds : `0${displaySeconds}`
  }`;
};

const SCHOOL_YEAR_END_MONTH = 7;

export const getCurrentSchoolYear = () => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  return currentMonth < SCHOOL_YEAR_END_MONTH ? currentYear : currentYear + 1;
};

export const convertSchoolYearToYear = (year, semester) =>
  semester === 'SPRING' ? year : year - 1;

export const getRelativeDateTime = (dateStr, lang, t) => {
  const currentDateObj = new Date();
  const initialDateObj = new Date(dateStr);
  const fullDiff = (currentDateObj - initialDateObj) / (1000 * 60 * 60 * 24);
  const timeDisplayConfig = {
    hour12: true,
    hour: 'numeric',
    minute: '2-digit',
  };
  let timeString;
  let label;
  if (fullDiff < 2) {
    const dayDiff = currentDateObj.getDay() - initialDateObj.getDay();
    if (dayDiff === 0) {
      label = t('Today');
    } else if (dayDiff === 1 || dayDiff === -6) {
      label = t('Yesterday');
    } else if (dayDiff === 2 || dayDiff === -5) {
      label = initialDateObj.toLocaleDateString(lang, {
        weekday: 'short',
      });
    }
    timeString = `${label}, ${initialDateObj.toLocaleTimeString(lang, timeDisplayConfig)}`;
  } else if (fullDiff < 7) {
    timeString = `${initialDateObj.toLocaleDateString(lang, {
      weekday: 'short',
    })}, ${initialDateObj.toLocaleTimeString(lang, timeDisplayConfig)}`;
  } else if (initialDateObj.getFullYear() === currentDateObj.getFullYear()) {
    timeString = `${initialDateObj.toLocaleDateString(lang, {
      month: 'short',
      day: '2-digit',
    })}, ${initialDateObj.toLocaleTimeString(lang, timeDisplayConfig)}`;
  } else {
    timeString = `${initialDateObj.toLocaleDateString(lang, {
      month: 'short',
      day: '2-digit',
      year: 'numeric',
    })}, ${initialDateObj.toLocaleTimeString(lang, timeDisplayConfig)}`;
  }
  return timeString;
};

export const dateToFormattedString = (d) => {
  const date = new Date(d);
  const yyyy = date.getFullYear().toString();
  const mm = (date.getMonth() + 1).toString();
  const dd = date.getDate().toString();
  return `${yyyy}-${mm[1] ? mm : `0${mm[0]}`}-${dd[1] ? dd : `0${dd[0]}`}`;
};

export const getFormattedWeekRange = (weekStart, weekEnd, lang) => {
  const startYear = weekStart.getFullYear();
  const endYear = weekEnd.getFullYear();
  const currentYear = new Date().getFullYear();
  if (weekStart.getMonth() === weekEnd.getMonth()) {
    const isMonthInCurrentYear = startYear === currentYear;
    return `${getFormattedDate(weekStart, lang, {
      month: 'long',
    })} ${weekStart.getDate()}-${weekEnd.getDate()}${isMonthInCurrentYear ? '' : `, ${startYear}`}`;
  }
  const shouldDisplayStartYear = startYear !== endYear;
  const shouldDisplayEndYear = shouldDisplayStartYear || endYear !== currentYear;
  return `${getFormattedDate(weekStart, lang, {
    month: 'long',
    day: 'numeric',
  })}${shouldDisplayStartYear ? `, ${startYear}` : ''} - ${getFormattedDate(weekEnd, lang, {
    month: 'long',
    day: 'numeric',
  })}${shouldDisplayEndYear ? `, ${endYear}` : ''}`;
};
export const isCurrentYear = (date) => dayjs(date).isSame(dayjs(), 'year');

export const getActiveWeekBoundaryDays = (date, minDate, maxDate) => {
  const weekStart = date?.day(1);
  const weekEnd = date?.day(5);

  const activeWeekStart = weekStart?.isBefore(minDate, 'day') ? dayjs(minDate) : weekStart;
  const activeWeekEnd = weekEnd?.isAfter(maxDate, 'day') ? dayjs(maxDate) : weekEnd;

  return [activeWeekStart, activeWeekEnd];
};

export const isWeekend = (date) => {
  const day = date.day();

  return day === 0 || day === 6;
};

export const getShortDate = (date) => date.format('YYYY-MM-DD');

export const getMeridiemFromInteger = (integer) => integer && dayjs().hour(integer).format('hA');

export const getFormattedChatMessageDate = (date, lang) => {
  const dateObj = dayjs(date);
  const formatted = getFormattedDate(dateObj.toDate(), lang, {
    month: 'short',
    day: '2-digit',
    hour12: true,
    hour: 'numeric',
    minute: '2-digit',
  });

  return isCurrentYear(dateObj) ? formatted : `${formatted}, ${dateObj.get('year')}`;
};
