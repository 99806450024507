import { isObject } from '../object-helpers/objectHelpers';

export const debounce = (fn, ms) => {
  let timer;
  return () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      fn();
    }, ms);
  };
};

export const updateEmbedsSrc = (textJson, entityNameForUpdate, token) => {
  const updateEntity = (value) => {
    const updatedValue = Object.entries(value).reduce(
      (acc, [key, newValue]) => ({
        ...acc,
        [key]: !isObject(value[key])
          ? key === entityNameForUpdate
            ? `${newValue}/shared/${token}`
            : newValue
          : updateEntity(newValue),
      }),
      {},
    );
    return updatedValue;
  };
  const updatedJson = JSON.parse(textJson)?.ops.map((entity) => updateEntity(entity));
  return JSON.stringify({ ops: updatedJson });
};
