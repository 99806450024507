import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Container, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery';
import { useRouteMatch } from 'react-router-dom';

import { GoalAddEditForm } from '../components';
import { PageWrapper } from '../../../../../organisms';
import { goalsRoute, trainAyoRoute } from '../../../../../../constants/routes';
import { UserContext } from '../../../../../../context';
import { RolesMap } from '../../../../../../constants/enums';

const GoalCreatePage = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const match = useRouteMatch();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const { studentId } = match.params;
  const { state: userState } = useContext(UserContext);
  const isStudent = userState.profile.role === RolesMap.STUDENT;

  return (
    <PageWrapper
      backToLink={`${trainAyoRoute}/${studentId}${goalsRoute}`}
      backToText={t(isStudent ? 'Go to my goals' : 'Go to goals')}
      mainElementClassName="ayo-goal-create"
    >
      <Container>
        <Box mb={isWidthUpSm ? 10 : 5}>
          <GoalAddEditForm mode="create" />
        </Box>
      </Container>
    </PageWrapper>
  );
};
export default GoalCreatePage;
