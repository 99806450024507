import React from 'react';
import PropTypes from 'prop-types';

import {
  ClubsMembershipStatuses,
  ClubsMembersQueryParams,
} from '../../../../../../../../constants/club-hub';
import ClubFilters from '../../../../../club-hub-components/club-filters/ClubFilters';

const ClubMembersFiltersOptions = {
  [ClubsMembershipStatuses.ALL]: {
    status: ClubsMembershipStatuses.ALL,
  },
  [ClubsMembershipStatuses.PENDING_REQUEST]: {
    status: ClubsMembershipStatuses.PENDING_REQUEST,
    tooltipLabel: 'Club membership requests that are awaiting your approval or rejection.',
  },
  [ClubsMembershipStatuses.JOINED]: {
    status: ClubsMembershipStatuses.JOINED,
    tooltipLabel: 'Members of the club.',
  },
  [ClubsMembershipStatuses.REQUEST_REJECTED]: {
    status: ClubsMembershipStatuses.REQUEST_REJECTED,
    tooltipLabel: 'Rejected membership requests.',
  },
};
const ClubMembersFilters = ({ membershipStatus, onFiltersChange }) => (
  <ClubFilters
    filtersOptions={ClubMembersFiltersOptions}
    onFiltersChange={(value) =>
      onFiltersChange({
        [ClubsMembersQueryParams.STATUS]: value,
        [ClubsMembersQueryParams.PAGE]: 1,
      })
    }
    status={membershipStatus}
  />
);

ClubMembersFilters.propTypes = {
  membershipStatus: PropTypes.string.isRequired,
  onFiltersChange: PropTypes.func.isRequired,
};
export default ClubMembersFilters;
