/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import ReactImageGallery from 'react-image-gallery';
import FocusTrap from 'focus-trap-react';
import { Box, useMediaQuery, useTheme } from '@mui/material';

import { Button } from '../../atoms';
import { stopPlayback } from '../../../utils';
import { ReactComponent as CloseIcon } from '../../../resources/icons/close.svg';

const galleryStaticConfig = {
  disableKeyDown: true,
  showPlayButton: false,
  showFullscreenButton: false,
  thumbnailPosition: 'left',
};

const galleryMobileConfig = {
  disableKeyDown: true,
  showPlayButton: false,
  showFullscreenButton: false,
  showThumbnails: false,
};

const PreviewGallery = ({ customButton, galleryItems, isOpen, onClose }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  const galleryConfig = isWidthUpSm ? galleryStaticConfig : galleryMobileConfig;

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      stopPlayback('audio, video');
      document.body.style.overflow = 'auto';
    }
  }, [isOpen]);

  return (
    <FocusTrap active={isOpen}>
      <Box
        className={classNames('ayo-preview-gallery', { 'ayo-preview-gallery--hidden': !isOpen })}
      >
        <Button
          aria-label={t('Close')}
          autoFocus
          className="ayo-preview-gallery__close-button"
          gaLabel="File preview - close"
          isIconButton
          onClick={onClose}
        >
          <CloseIcon height="48" width="48" />
        </Button>
        <ReactImageGallery {...galleryConfig} {...galleryItems} />
        <Box
          className={classNames({
            'ayo-preview-gallery--action-buttons__desktop': isWidthUpSm,
            'ayo-preview-gallery--action-buttons__mobile': !isWidthUpSm,
          })}
          display="flex"
          flexDirection={isWidthUpSm ? 'row' : 'column'}
          gap={isWidthUpSm ? 3 : 4}
          mt={!isWidthUpSm && 10}
          px={!isWidthUpSm && 3}
        >
          {customButton}
          <Button
            className="ayo-preview-gallery__back-button"
            gaLabel="File preview - back"
            onClick={onClose}
            variant="primary"
          >
            {t('Back')}
          </Button>
        </Box>
      </Box>
    </FocusTrap>
  );
};

PreviewGallery.propTypes = {
  galleryItems: PropTypes.instanceOf(Object).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  customButton: PropTypes.node,
};
PreviewGallery.defaultProps = {
  customButton: null,
};

export default PreviewGallery;
