import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Container, useMediaQuery, useTheme } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { Typography } from '../../../../atoms';
import { EditingButton } from '../../../../moleculas';
import { EventsBlock, PageWrapper } from '../../../../organisms';
import {
  EditablePrivilegesByGroupMap,
  PrivilegesMap,
  RolesMap,
} from '../../../../../constants/enums';
import {
  familyFeedCreationCenterRoute,
  familyFeedMyFeedRoute,
  familyFeedRoute,
} from '../../../../../constants/routes';
import { EventKeys, EventResourceTypes } from '../../../../../constants/events';
import { FamilyFeedFilterCategory } from '../../../../../constants/filter-categories';
import { SharingLevels } from '../../../../../constants/family-feed';
import { FamilyFeedContext, UserContext } from '../../../../../context';
import { useEvents, useFamilyFeed, useSearchParams } from '../../../../../hooks';
import { transformSearchToObject, useProfile } from '../../../../../utils';
import { useEventsService } from '../../../../../services';
import { ReactComponent as ClubsEventsEmptyState } from '../../../../../resources/images/club_events_empty_state_owner.svg';
import { ReactComponent as ExpandIcon } from '../../../../../resources/icons/chevron_down.svg';

const NavigationConfig = {
  [RolesMap.TEACHER]: {
    backToLink: familyFeedCreationCenterRoute,
    backToText: 'Back to Family feed',
  },
  [RolesMap.ADMINISTRATOR]: {
    backToLink: familyFeedCreationCenterRoute,
    backToText: 'Back to Family feed',
  },
  [RolesMap.STUDENT]: {
    backToLink: familyFeedMyFeedRoute,
    backToText: 'Back to My feed',
  },
  [RolesMap.GUARDIAN]: {
    backToLink: familyFeedRoute,
    backToText: 'Back to Family feed',
  },
};

const EVENTS_PAGE_SIZE = 20;
const EVENTS_DEFAULT_PAGE = 1;

const FamilyFeedEventsPage = () => {
  const { t } = useTranslation();

  const [selectedEvent, setSelectedEvent] = useState(null);

  const { state: userState } = useContext(UserContext);
  const { role, privileges } = userState.profile;

  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  const { getPrivilegeStatus } = useProfile();

  const { getEventById } = useEventsService();

  const search = useSearchParams();
  const { eventId } = transformSearchToObject(search);

  const history = useHistory();

  const { state: familyFeedState } = useContext(FamilyFeedContext);
  const { classes, students } = familyFeedState;

  const { getClasses, getStudents, getAllowedSharingLevels, getEntitiesByLevel } = useFamilyFeed();

  const {
    eventPostHandler,
    eventDeleteHandler,
    eventUpdateHandler,
    events,
    hasItemToLoad,
    loadMoreHandler,
    loadEventsData,
  } = useEvents(EventResourceTypes.FAMILY_FEED, null, EVENTS_PAGE_SIZE);

  const isEventCreator = useMemo(
    () =>
      !!EditablePrivilegesByGroupMap[FamilyFeedFilterCategory].find((privilege) =>
        getPrivilegeStatus(privilege),
      )?.length,
    [getPrivilegeStatus],
  );

  const sharingLevelOptions = useMemo(
    () =>
      isEventCreator
        ? getAllowedSharingLevels().reduce(
            (obj, key) => ({
              ...obj,
              [key]: {
                [EventKeys.SHARING_LEVEL]: SharingLevels[key],
                [EventKeys.SELECTED_ENTITIES]: getEntitiesByLevel(key),
              },
            }),
            {},
          )
        : null,
    [getAllowedSharingLevels, getEntitiesByLevel, isEventCreator],
  );

  useEffect(() => {
    if (eventId) {
      getEventById(eventId).then((event) => {
        setSelectedEvent(event);
        history.replace({ search: '' });
      });
    }
  }, [eventId, getEventById, history, selectedEvent]);

  useEffect(() => {
    loadEventsData(EVENTS_DEFAULT_PAGE);
  }, [loadEventsData]);

  useEffect(() => {
    if (!classes && privileges?.includes(PrivilegesMap.FAMILY_FEED_CREATOR_CLASS)) getClasses();
  }, [classes, getClasses, isEventCreator, privileges]);

  useEffect(() => {
    if (!students && privileges?.includes(PrivilegesMap.FAMILY_FEED_CREATOR_PERSONALIZED))
      getStudents();
  }, [students, getStudents, isEventCreator, privileges]);

  return (
    <PageWrapper
      backToLink={NavigationConfig[role].backToLink}
      backToText={NavigationConfig[role].backToText}
      mainElementClassName="ayo-family-feed-events"
    >
      <Container>
        <Typography component="h1" mb={1} variant="h1">
          {t('Upcoming events')}
        </Typography>
        <Typography mb={5} variant="body2">
          {t('This is a page dedicated to all upcoming events.')}
        </Typography>
        <EventsBlock
          key={eventId}
          emptyStateConfig={{
            title: isEventCreator
              ? t('There are no upcoming events yet. Feel free to create a new one!')
              : t('No upcoming events available yet'),
            illustration: <ClubsEventsEmptyState />,
          }}
          events={events}
          fullLabel={isWidthUpSm || isEventCreator}
          isEditable={isEventCreator}
          onCreate={eventPostHandler}
          onDelete={eventDeleteHandler}
          onUpdate={eventUpdateHandler}
          resourceId={null}
          resourceType={EventResourceTypes.FAMILY_FEED}
          selectedEvent={selectedEvent}
          sharingLevelOptions={sharingLevelOptions}
          showSharingLabels
          variant="calendar"
        />
        {hasItemToLoad && (
          <Box mt={3}>
            <EditingButton
              gaLabel="Show more"
              icon={<ExpandIcon />}
              iconPosition="end"
              onClick={loadMoreHandler}
              text={t('Show more')}
            />
          </Box>
        )}
      </Container>
    </PageWrapper>
  );
};

export default FamilyFeedEventsPage;
