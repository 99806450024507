import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, useMediaQuery, useTheme } from '@mui/material';

import { Button, Typography } from '../../atoms';
import { initiativesBlock } from '../../../constants/propTypes';
import { ReactComponent as InitiativesBlockDesktopImage } from '../../../resources/images/landing_ayo-initiatives_desktop.svg';
import { ReactComponent as InitiativesBlockMobileImage } from '../../../resources/images/landing_ayo-initiatives_mobile.svg';

const InitiativesBlock = ({ config }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Box alignItems="center" display="flex" flexDirection="column" mx={3}>
      <Typography paragraph variant="h1">
        {t(config.title)}
      </Typography>
      <Box maxWidth={800} px={3}>
        <Typography variant="subtitle1">{t(config.subtitle)}</Typography>
      </Box>
      <Box py={5} width={isWidthUpSm ? 320 : 345}>
        <Button
          component={RouterLink}
          fullWidth
          gaLabel={config.initiativesButton.title}
          to={config.initiativesButton.link}
          variant="secondary"
        >
          {t(config.initiativesButton.title)}
        </Button>
      </Box>
      {isWidthUpSm ? (
        <InitiativesBlockDesktopImage className="ayo-svg-illustration" />
      ) : (
        <InitiativesBlockMobileImage className="ayo-svg-illustration" />
      )}
    </Box>
  );
};

InitiativesBlock.propTypes = {
  config: initiativesBlock,
};

InitiativesBlock.defaultProps = {
  config: {},
};

export default InitiativesBlock;
