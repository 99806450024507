import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ActionsMenu } from '../../atoms';
import { ReactComponent as SortIcon } from '../../../resources/icons/sorting.svg';

const SortButton = ({ menuItems, transformOrigin }) => {
  const { t } = useTranslation();

  return (
    <ActionsMenu
      activatorIcon={<SortIcon />}
      gaLabel="sort by"
      id="sort-by"
      label={t('Sort by')}
      menuItems={menuItems}
      noIconsRecolor
      transformOrigin={transformOrigin}
    />
  );
};

SortButton.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  transformOrigin: PropTypes.instanceOf(Object),
};

SortButton.defaultProps = {
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
};

export default SortButton;
