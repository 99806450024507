import { useCallback, useContext, useEffect, useMemo } from 'react';

import {
  NotificationResourcesMap,
  NotificationsAlowedRolesMap,
  OneTimeActionsMap,
  PortfolioNotificationSectionMap,
  ResourcesToReloadMap,
  RolesMap,
} from '../../constants/enums';
import {
  AppActions,
  AppContext,
  NotificationsActions,
  NotificationsContext,
  UserContext,
} from '../../context';
import { useNotificationsService } from '../../services';
import { useProfile } from '../../utils';
import { chatNotificationsHandlers } from '../../context/chat/ChatContext';

// Dev notes:
// 1. Websocket closing on tab inactivity
// 2. LA refetching on LA Notifications

let isLoading = false;
const useNotificationsData = () => {
  const { state: notificationsState, dispatch: dispatchNotifications } =
    useContext(NotificationsContext);
  const { state: userState } = useContext(UserContext);
  const { dispatch: dispatchAppState } = useContext(AppContext);
  const { getOneTimeActionStatus, getIsCampusSelectionAvailable } = useProfile();

  const { notificationsList, receivedNewCount } = notificationsState;

  const { isGuardianWithoutStudentConsent } = userState;

  const isNotificationsAvailable = useMemo(
    () =>
      NotificationsAlowedRolesMap.includes(userState.profile?.role) &&
      userState.profile?.policyAccepted &&
      !isGuardianWithoutStudentConsent &&
      (userState?.profile?.role !== RolesMap.TEACHER ||
        getOneTimeActionStatus(OneTimeActionsMap.TEACHER_PAGE_ROLE)) &&
      (!getIsCampusSelectionAvailable() || userState.profile?.currentCampus),
    [
      userState.profile,
      getIsCampusSelectionAvailable,
      getOneTimeActionStatus,
      isGuardianWithoutStudentConsent,
    ],
  );

  const { getNotificationsList, openNotificationsSocket, putReadNotifications } =
    useNotificationsService();

  const updateNotificationItems = useCallback(
    (items, notificationType) => {
      putReadNotifications(items.map((i) => i.id));
      dispatchNotifications({
        type: NotificationsActions.SET_NOTIFICATION_ITEMS,
        data: { items, notificationType },
      });
    },
    [dispatchNotifications, putReadNotifications],
  );

  const loadNotificationsList = useCallback(
    (onLoad) => {
      isLoading = true;
      getNotificationsList().then((notificationsListData) => {
        const groupedNotifications = notificationsListData?.reduce(
          (acc, current) => ({
            ...acc,
            [current.notificationSources[0]]: [
              ...(acc[current.notificationSources[0]] || []),
              current,
            ],
          }),
          {},
        );
        groupedNotifications?.WEB_GENERAL?.sort((a, b) => {
          if (a.markedAsRead === b.markedAsRead) {
            return Date.parse(b.createdDate) - Date.parse(a.createdDate);
          }
          return a.markedAsRead - b.markedAsRead;
        });
        isLoading = false;
        dispatchNotifications({
          type: NotificationsActions.SET_NOTIFICATIONS_LIST,
          data: groupedNotifications,
        });
        if (onLoad) {
          onLoad();
        }
      });
    },
    [dispatchNotifications, getNotificationsList],
  );

  useEffect(() => {
    if (!isNotificationsAvailable || notificationsList !== null || !userState.profile || isLoading)
      return;
    loadNotificationsList(() =>
      openNotificationsSocket((message) => {
        const response = JSON.parse(message.body);
        if (Object.keys(ResourcesToReloadMap).includes(response.resource)) {
          dispatchAppState({
            type: AppActions.ADD_SECTIONS_TO_RELOAD,
            data: ResourcesToReloadMap[response.resource],
          });
        }
        // TODO: replace this with some kind of observable action and move this logic out of notifications
        if (response.resource === NotificationResourcesMap.CONVERSATION) {
          chatNotificationsHandlers.onMessage(response);
        }
        dispatchNotifications({
          type: NotificationsActions.ADD_NOTIFICATION,
          data: response,
        });
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState.profile, isNotificationsAvailable]);

  const isPortfolioHasUpdates = useMemo(
    () =>
      isNotificationsAvailable &&
      notificationsList?.WEB_HIDDEN?.filter(({ resource }) =>
        Object.values(PortfolioNotificationSectionMap).includes(resource),
      )?.some((i) => !i?.markedAsRead),
    [isNotificationsAvailable, notificationsList?.WEB_HIDDEN],
  );
  const getUnreadNotificationsByResourceAndId = (notificationSources, resource, id) =>
    notificationsList?.[notificationSources]?.filter(
      (notification) =>
        notification.resource === resource &&
        !notification.markedAsRead &&
        (notification.metadata.info.relatedEntityId === id || notification.resourceId === id),
    );

  return {
    isNotificationsAvailable,
    notificationsList,
    receivedNewCount,
    reloadNotifications: loadNotificationsList,
    updateNotificationItems,
    isPortfolioHasUpdates,
    getUnreadNotificationsByResourceAndId,
  };
};

export default useNotificationsData;
