import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ReactLottie from 'react-lottie';

import { KeyboardMap } from '../../../constants/enums';

const Lottie = ({ ariaLabel, options, tabIndex }) => {
  const [isStopped, setIsStopped] = useState(true);
  const ref = useRef();

  useEffect(() => {
    if (tabIndex !== null && ref.current?.el) {
      ref.current.el.setAttribute('tabindex', tabIndex);
    }
  }, [tabIndex]);

  return (
    <div
      className="ayo-lottie"
      onKeyDown={(e) => {
        if (e.key === KeyboardMap.ENTER) {
          setIsStopped((state) => !state);
        }
      }}
      onMouseEnter={() => setIsStopped(false)}
      onMouseLeave={() => setIsStopped(true)}
      role="none"
    >
      <ReactLottie ref={ref} ariaLabel={ariaLabel} isStopped={isStopped} options={options} />
    </div>
  );
};

Lottie.propTypes = {
  ariaLabel: PropTypes.string.isRequired,
  options: PropTypes.shape({
    animationData: PropTypes.instanceOf(Object),
    autoplay: PropTypes.bool,
    loop: PropTypes.bool,
    rendererSettings: PropTypes.instanceOf(Object),
  }).isRequired,
  tabIndex: PropTypes.string,
};

Lottie.defaultProps = {
  tabIndex: null,
};

export default Lottie;
