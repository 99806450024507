export const format10NumToWords = (num, t) => {
  switch (num) {
    case 1:
      return t('one');
    case 2:
      return t('two');
    case 3:
      return t('three');
    case 4:
      return t('four');
    case 5:
      return t('five');
    case 6:
      return t('six');
    case 7:
      return t('seven');
    case 8:
      return t('eight');
    case 9:
      return t('nine');
    default:
      return num;
  }
};
