import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { ResponsiveContainer } from 'recharts';
import { Box, Divider } from '@mui/material';

import { Tooltip, Typography } from '../../../atoms';
import { InformationalCaption } from '../../../moleculas';
import { ReactComponent as InterestBubble } from '../../../../resources/images/interest_bubble.svg';
import { useSearchParams } from '../../../../hooks';
import {
  getFilterValueParts,
  updateSimpleFilter,
  isSimpleFilterSelected,
  transformSearchToObject,
  updateAllOtherFilter,
  updatePendingFilter,
  isFilterItemDisabled,
  GA,
} from '../../../../utils';
import {
  GaActions,
  GaCategories,
  HighlightsInitiatives,
  StudentFiltersSpecialValues,
} from '../../../../constants/enums';
import { FilterUpdateActions } from '../../../../hooks/use-filter-update-state/useFilterUpdateState';

const HighlightsInterest = ({ name, statValue, onClick, isActive, totalStudentsCount }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const interestBubbleRef = useRef();
  const isDisabled = isFilterItemDisabled(statValue, totalStudentsCount);

  const enableAnchorEl = () => setAnchorEl(interestBubbleRef.current);
  const resetAnchorEl = () => setAnchorEl(null);

  return (
    <Tooltip
      enterTouchDelay={0}
      leaveTouchDelay={5000}
      placement="right"
      PopperProps={{
        anchorEl,
      }}
      title={
        <>
          {`${name} (${statValue})`}
          <br />
          {isFilterItemDisabled(statValue, totalStudentsCount) && t("Can't filter by all students")}
        </>
      }
    >
      <button
        aria-label={isActive ? t('Selected') : null}
        className={classnames('interest-button', { active: isActive })}
        onBlur={resetAnchorEl}
        onClick={onClick}
        onFocus={enableAnchorEl}
        onMouseOut={resetAnchorEl}
        onMouseOver={enableAnchorEl}
        tabIndex={isDisabled ? null : 0}
        type="button"
      >
        <Box>
          <Typography component="p" variant="subtitle1">
            {statValue}
          </Typography>
          <InterestBubble ref={interestBubbleRef} />
          <Typography variant="body2">{name}</Typography>
        </Box>
      </button>
    </Tooltip>
  );
};

HighlightsInterest.propTypes = {
  name: PropTypes.string.isRequired,
  statValue: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  totalStudentsCount: PropTypes.number.isRequired,
};

const INFO_TOOLTIP_MAX_WIDTH = 356;

const InterestsHighlights = ({ stats, totalStudentsCount, onFiltersUpdate }) => {
  const search = useSearchParams();

  const filters = useMemo(() => transformSearchToObject(search), [search]);

  const history = useHistory();

  const interestsStats = useMemo(() => {
    const statsInterestsValues = stats ? { ...stats.interests } : {};
    delete statsInterestsValues[StudentFiltersSpecialValues.ALL_OTHER];
    delete statsInterestsValues[StudentFiltersSpecialValues.PENDING];
    return statsInterestsValues;
  }, [stats]);

  const { filtersList } = useMemo(() => getFilterValueParts(filters, stats), [filters, stats]);

  const { t } = useTranslation();

  const onInterestClick = useCallback(
    (interestKey, statValue) => {
      if (isFilterItemDisabled(statValue, totalStudentsCount)) return;
      const interestOptionsList = Object.keys(interestsStats);
      const newFilters = updateSimpleFilter(
        interestKey,
        HighlightsInitiatives.INTERESTS,
        filters,
        interestOptionsList,
        stats,
      );
      history.replace({ search: new URLSearchParams(newFilters).toString() });
      onFiltersUpdate({
        type: FilterUpdateActions.UPDATE,
        data: { filters, value: interestKey, category: HighlightsInitiatives.INTERESTS, stats, t },
      });
      GA.logInteraction({
        category: GaCategories.BEHAVIOR,
        action: GaActions.GRAPH_PART_CLICKED,
        label: `Interests Highlights Graph - ${interestKey}}`,
      });
    },
    [filters, history, interestsStats, onFiltersUpdate, stats, t, totalStudentsCount],
  );

  const onAllOtherClick = useCallback(() => {
    if (
      isFilterItemDisabled(
        stats.interests[StudentFiltersSpecialValues.ALL_OTHER].count,
        totalStudentsCount,
      )
    )
      return;
    const interestOptionsList = Object.keys(interestsStats);
    const newFilters = updateAllOtherFilter(
      filters,
      HighlightsInitiatives.INTERESTS,
      interestOptionsList,
      stats,
    );
    history.replace({ search: new URLSearchParams(newFilters).toString() });
    onFiltersUpdate({
      type: FilterUpdateActions.UPDATE,
      data: {
        filters,
        value: StudentFiltersSpecialValues.ALL_OTHER,
        category: HighlightsInitiatives.INTERESTS,
        stats,
        t,
      },
    });
    GA.logInteraction({
      category: GaCategories.BEHAVIOR,
      action: GaActions.GRAPH_PART_CLICKED,
      label: `Interests Highlights Graph - ${StudentFiltersSpecialValues.ALL_OTHER}}`,
    });
  }, [filters, history, interestsStats, onFiltersUpdate, stats, t, totalStudentsCount]);

  const onPendingClick = useCallback(() => {
    if (
      isFilterItemDisabled(
        stats.interests[StudentFiltersSpecialValues.PENDING].count,
        totalStudentsCount,
      )
    )
      return;
    const newFilters = updatePendingFilter(filters, HighlightsInitiatives.INTERESTS, stats);
    history.replace({ search: new URLSearchParams(newFilters).toString() });
    onFiltersUpdate({
      type: FilterUpdateActions.UPDATE,
      data: {
        filters,
        value: StudentFiltersSpecialValues.PENDING,
        category: HighlightsInitiatives.INTERESTS,
        stats,
        t,
      },
    });
    GA.logInteraction({
      category: GaCategories.BEHAVIOR,
      action: GaActions.GRAPH_PART_CLICKED,
      label: `Interests Highlights Graph - ${StudentFiltersSpecialValues.PENDING}}`,
    });
  }, [filters, history, onFiltersUpdate, stats, t, totalStudentsCount]);

  const interestsBarData = useMemo(
    () => Object.entries(interestsStats).sort((a, b) => b[1].count - a[1].count),
    [interestsStats],
  );
  return (
    <ResponsiveContainer height={350} width="100%">
      <Box alignItems="center" display="flex">
        <Box alignSelf="flex-start">
          <InformationalCaption
            isLight
            showTooltip
            title="Interests Highlights info"
            tooltipMaxWidth={INFO_TOOLTIP_MAX_WIDTH}
            tooltipPlacement="right"
          />
        </Box>
        <Box
          className="interest-buttons-container"
          display="flex"
          flex="1"
          flexDirection="row"
          justifyContent="center"
        >
          {stats &&
            interestsBarData.map(([interestKey, interestValue]) => (
              <HighlightsInterest
                key={interestKey}
                isActive={isSimpleFilterSelected(
                  interestKey,
                  HighlightsInitiatives.INTERESTS,
                  filters.filterCategory,
                  filtersList,
                )}
                name={interestValue.name}
                onClick={() => onInterestClick(interestKey, interestValue.count)}
                statValue={interestValue.count}
                totalStudentsCount={totalStudentsCount}
              />
            ))}
          {stats && !!Object.keys(interestsStats).length && (
            <HighlightsInterest
              isActive={isSimpleFilterSelected(
                StudentFiltersSpecialValues.ALL_OTHER,
                HighlightsInitiatives.INTERESTS,
                filters.filterCategory,
                filtersList,
              )}
              name={t(`analytics.highlights.${StudentFiltersSpecialValues.ALL_OTHER}`)}
              onClick={onAllOtherClick}
              statValue={stats.interests[StudentFiltersSpecialValues.ALL_OTHER]?.count || 0}
              totalStudentsCount={totalStudentsCount}
            />
          )}
          {stats && !!Object.keys(interestsStats).length && (
            <Divider flexItem orientation="vertical" />
          )}
          {stats && (
            <HighlightsInterest
              isActive={isSimpleFilterSelected(
                StudentFiltersSpecialValues.PENDING,
                HighlightsInitiatives.INTERESTS,
                filters.filterCategory,
                filtersList,
              )}
              name={t(`analytics.highlights.${StudentFiltersSpecialValues.PENDING}`)}
              onClick={onPendingClick}
              statValue={stats.interests[StudentFiltersSpecialValues.PENDING]?.count || 0}
              totalStudentsCount={totalStudentsCount}
            />
          )}
        </Box>
      </Box>
    </ResponsiveContainer>
  );
};

InterestsHighlights.propTypes = {
  stats: PropTypes.instanceOf(Object).isRequired,
  totalStudentsCount: PropTypes.number.isRequired,
  onFiltersUpdate: PropTypes.func.isRequired,
};

export default InterestsHighlights;
