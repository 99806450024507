/* eslint-disable no-param-reassign */
import { getQuestionnaireTemplateStringRE } from '../../constants/regexps';
import { QuestionnaireNamesByTypesMap, QuestionnaireStatusMap } from '../../constants/enums';
import { getGroupedArrayByKey } from '../array-helpers/arrayHelpers';

const NAME_KEY = 'questionName';
const YEAR_KEY = 'academicYear';

export const transformAnswerState = (guardians, type) => {
  const reducedAnswers = guardians?.reduce((acc, guardian) => {
    guardian.questionnaires.forEach((questionnaire) => {
      if (questionnaire.type === type) {
        questionnaire.answers.forEach((answer) => {
          (acc[questionnaire.state] = acc[questionnaire.state] || []).push({
            firstName: guardian.firstName,
            relationship: guardian.relationshipId,
            guardianId: guardian.id,
            answer: answer?.answers?.map((answerObject) => Object.values(answerObject)).join(', '),
            skipped: answer.skipped,
            academicYear: questionnaire.academicYear,
            questionnaireId: questionnaire.id,
            questionName: answer.questionName,
            startTime: questionnaire.startTime,
          });
        });
      }
    });
    return acc;
  }, {});

  Object.keys(QuestionnaireStatusMap).forEach((status) => {
    if (!reducedAnswers[status]) {
      reducedAnswers[status] = [];
    }
  });

  reducedAnswers[QuestionnaireStatusMap.CLOSE] = reducedAnswers[
    QuestionnaireStatusMap.CLOSE
  ].reduce((acc, question) => {
    if (
      reducedAnswers[QuestionnaireStatusMap.OPEN].find(
        (x) => x.questionName === question.questionName,
      )
    ) {
      reducedAnswers[QuestionnaireStatusMap.OPEN].push(question);
    } else {
      acc.push(question);
    }
    return acc;
  }, []);

  const groupedByQuestionsAndYear = Object.keys(reducedAnswers).reduce((accState, state) => {
    const groupedByQuestions = getGroupedArrayByKey(reducedAnswers[state], NAME_KEY);
    accState[state] = Object.keys(groupedByQuestions).reduce((accQuestions, questionName) => {
      accQuestions[questionName] = getGroupedArrayByKey(groupedByQuestions[questionName], YEAR_KEY);
      return accQuestions;
    }, {});
    return accState;
  }, {});
  return groupedByQuestionsAndYear;
};

export const isAnswersEmpty = (answers) => !Object.keys(answers).length;

export const getFirstUnansweredQuestion = (questionList) =>
  questionList?.findIndex(
    (question) => !question.skipped && !question.hidden && isAnswersEmpty(question.answers),
  );

export const getTotalQuestionsAmount = (questions) =>
  questions.filter((question) => !question.hidden && !question.dependentQuestions).length;

export const getPassedQuestionsAmount = (questions, activeQuestionIndex) =>
  questions
    .slice(0, activeQuestionIndex + 1)
    .filter((question) => !question.hidden && !question.dependentQuestions).length;

export const getQuestionnaireProgress = (questionnaire) =>
  (questionnaire[0].numberOfAnsweredQuestions / questionnaire[0].totalNumberOfQuestions) * 100;

export const isDependencyConditionMet = (dependency, answers) =>
  Boolean(Object.values(answers).find((answer) => dependency.optionIds.includes(answer.optionId)));

const findQuestionById = (questions, id) => questions.find((question) => question.id === id);

const handleDependencies = (dependeciesList, answers, questionList) =>
  dependeciesList?.forEach((dependency) => {
    const dependentQuestion = findQuestionById(questionList, dependency.questionId);
    if (!isDependencyConditionMet(dependency, answers)) {
      dependentQuestion.hidden = true;
    } else {
      dependentQuestion.hidden = false;
    }
  });

export const handleNewAnswersDependencies = (dependeciesList, answers, questions) => {
  const questionsCopy = [...questions];
  handleDependencies(dependeciesList, answers, questionsCopy);
  return questionsCopy;
};

export const hideUnmetConditionsQuestions = (questions) => {
  const questionsCopy = [...questions];
  questionsCopy.forEach((question) =>
    handleDependencies(question.dependentQuestions, question.answers, questionsCopy),
  );
  return questionsCopy;
};

export const extractSkippedQuestions = (questionList) =>
  questionList.reduce((acc, q) => {
    if (q.skipped) {
      const newQuestion = q;
      newQuestion.skipped = false;
      return [...acc, newQuestion];
    }
    return acc;
  }, []);

export const isOptionActivated = (activatorOption, answers) =>
  !activatorOption ||
  Boolean(Object.values(answers).find((answer) => answer.optionId === activatorOption));

export const getQuestionnaireType = (questionnaireTitle) =>
  Object.values(QuestionnaireNamesByTypesMap).find((type) => questionnaireTitle.includes(type));

export const getQuestionnairesByType = (questionnaires, questionnaireType) =>
  questionnaires.filter((questionnaire) =>
    questionnaire.questionnaireTitle.includes(questionnaireType),
  );

export const hasPendingQuestionnaire = (person, type) =>
  type
    ? getQuestionnairesByType(person.pendingQuestionnaires, type).length
    : person.pendingQuestionnaires?.length;

export const hasPendingChildrenQuestionnaire = (childrenList) =>
  Boolean(childrenList?.find(hasPendingQuestionnaire));

export const handleTemplateString = (templateString, dataObject) => {
  const templateRegExp = getQuestionnaireTemplateStringRE();
  let finalString = templateString;
  let templatePart = templateRegExp.exec(templateString)?.[0];
  if (dataObject) {
    while (templatePart) {
      const templateDataField = templatePart.slice(1, templatePart.length - 1);
      finalString = finalString.replace(templatePart, dataObject[templateDataField]);
      templatePart = templateRegExp.exec(templateString)?.[0];
    }
  }
  return finalString;
};

export const isQuestionnaireCompleted = (questionList) =>
  !questionList.filter((question) => !question.hidden && isAnswersEmpty(question.answers)).length;

export const isSubmittedWithSkippedQuestions = (questionList) =>
  questionList.filter((questionnaire) => questionnaire.isSubmitted).length;

export const hasSubmittedQuestionnaires = (profileData) =>
  profileData.completedQuestionnaires.length ||
  profileData.pendingQuestionnaires.some((questionnaire) => questionnaire.isSubmitted);
