import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { UserContext } from '../../../../context';
import { getQuestionnairesByType, hasSubmittedQuestionnaires } from '../../../../utils';
import { QuestionnaireNamesByTypesMap } from '../../../../constants/enums';
import { ReactComponent as GeneralQuestionsImage } from '../../../../resources/images/general_questions.svg';

import TrainAyoSelectPage from './TrainAyoSelectPage';

const FollowupQuestionnaireSelectPage = () => {
  const { t } = useTranslation();

  const { state: userState } = useContext(UserContext);
  const { dependentProfiles } = userState;

  const dependentItems = useMemo(
    () =>
      dependentProfiles
        .map((dependentProfile) =>
          hasSubmittedQuestionnaires(dependentProfile)
            ? {
                ...dependentProfile,
                completedQuestionnaires: getQuestionnairesByType(
                  dependentProfile.completedQuestionnaires,
                  QuestionnaireNamesByTypesMap.FOLLOWUP,
                ),
                pendingQuestionnaires: getQuestionnairesByType(
                  dependentProfile.pendingQuestionnaires,
                  QuestionnaireNamesByTypesMap.FOLLOWUP,
                ),
              }
            : null,
        )
        .filter(Boolean),
    [dependentProfiles],
  );

  return (
    <TrainAyoSelectPage
      dependentItems={dependentItems}
      Illustration={GeneralQuestionsImage}
      illustrationAriaLabel={t('A telescope in the countryside')}
      subtitle={t('Followup questionnaire parents explanation')}
      title={t('Tell us more about your kid(s)')}
    />
  );
};

export default FollowupQuestionnaireSelectPage;
