import React, { useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { DynamicGrid } from '../../../../atoms';
import {
  AptitudeInfoCard,
  DeleteItemWrapper,
  InitiativeEmptyStateBlock,
} from '../../../../moleculas';
import { PortfolioEntitiesData, PortfolioItemsToAddData } from '../../../../../utils';
import { portfolioSectionConfig } from '../../../../../constants/propTypes';
import { AptitudesInfo, PortfolioSectionTypes } from '../../../../../constants/enums';
import PortfolioSection from '../../components/portfolio-section/PortfolioSection';
import { ReactComponent as EmptyStateIllustration } from '../../../../../resources/images/aptitudes_empty_state.svg';

const PortfolioAptitudesSection = ({ config, sectionIndex, isLastSection }) => {
  const [portfolioAptitudes, setPortfolioAptitudes] = useState([]);
  const { t } = useTranslation();
  const {
    addPortfolioItemHandler,
    deletePortfolioItemHandler,
    hasData,
    isEditMode,
    isVisible,
    maxItemsToDisplay,
    metaData,
    reflectionHandler,
    sectionData,
    onUpdatePositionHandler,
  } = config;
  const { entities, isInitialState, reflection } = sectionData;

  useEffect(() => {
    setPortfolioAptitudes(
      PortfolioEntitiesData[PortfolioSectionTypes.PORTFOLIO__APTITUDES](
        entities,
        metaData,
        'type',
      ).sort(
        (a, b) =>
          metaData.findIndex(({ type }) => type === a.type) -
          metaData.findIndex(({ type }) => type === b.type),
      ),
    );
  }, [entities, isInitialState, maxItemsToDisplay, metaData]);

  const aptitudesToAdd = useMemo(
    () =>
      PortfolioItemsToAddData[PortfolioSectionTypes.PORTFOLIO__APTITUDES](
        entities,
        metaData,
        'type',
      ).map(({ type }) => ({ id: type, name: t(AptitudesInfo[type].name) })),
    [entities, metaData, t],
  );

  if (!isVisible) {
    return null;
  }

  return (
    <PortfolioSection
      body={
        <Box mt={3}>
          <DynamicGrid
            gridItems={portfolioAptitudes.map((aptitude) => (
              <DeleteItemWrapper
                key={aptitude.type}
                disabled={!isEditMode}
                gaLabel="Remove aptitude"
                mainContent={
                  <AptitudeInfoCard aptitude={aptitude.type} isFocusable titleComponent="h3" />
                }
                onDelete={() => {
                  deletePortfolioItemHandler(
                    portfolioAptitudes,
                    'type',
                    aptitude.type,
                    sectionData,
                  );
                }}
              />
            ))}
            isHorizontalOrder
          />
        </Box>
      }
      className={`${classNames({
        'ayo-portfolio__aptitudes-section': hasData,
      })}`}
      emptyState={
        !hasData ? (
          <InitiativeEmptyStateBlock
            body={isEditMode ? t('AYO is still looking for any!') : ''}
            illustration={<EmptyStateIllustration />}
            title={t('No aptitudes found so far')}
          />
        ) : null
      }
      isEditMode={isEditMode}
      isLastSection={isLastSection}
      itemsToAdd={aptitudesToAdd}
      onAddItems={(itemsToAdd) =>
        addPortfolioItemHandler(metaData, itemsToAdd, 'type', sectionData)
      }
      onChangeReflection={reflectionHandler}
      onUpdatePositionHandler={onUpdatePositionHandler}
      reflection={reflection}
      sectionIndex={sectionIndex}
      sectionKey={PortfolioSectionTypes.PORTFOLIO__APTITUDES}
      showActionsMenu={hasData && isEditMode}
      showAddButton={!!aptitudesToAdd?.length && isEditMode}
      showAYOChip
      title={t('Aptitudes')}
    />
  );
};

PortfolioAptitudesSection.propTypes = {
  config: portfolioSectionConfig(
    PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        score: PropTypes.number,
      }),
    ),
  ),
  sectionIndex: PropTypes.number.isRequired,
  isLastSection: PropTypes.number.isRequired,
};

PortfolioAptitudesSection.defaultProps = {
  config: {},
};

export default PortfolioAptitudesSection;
