import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Box, Divider, useMediaQuery, useTheme } from '@mui/material';

import {
  Typography,
  Card,
  DotIndicator,
  Tooltip,
  ActionsMenu,
  PostImage,
  Avatar,
  Link,
} from '../../atoms';
import {
  Carousel,
  Chip,
  DisplayAvatarEditorImage,
  EditingButton,
  TextWithTooltip,
} from '../../moleculas';
import {
  FeedPostCategoriesLabels,
  FeedPostStatuses,
  GaActions,
  GaCategories,
} from '../../../constants/enums';
import { attachmentType, authorContactInfo } from '../../../constants/propTypes';
import {
  GA,
  getFullDateAndHoursString,
  getIsDomElementCropped,
  getFullSchoolName,
} from '../../../utils';
import { getDangerouslySetInnerHtml } from '../../../utils/dom-helpers/getDangerouslySetInnerHtml';
import { ReactComponent as EditIcon } from '../../../resources/icons/create.svg';
import { ReactComponent as ExpandIcon } from '../../../resources/icons/chevron_down.svg';
import { ReactComponent as CollapseIcon } from '../../../resources/icons/chevron_up.svg';
import { ReactComponent as UsersIcon } from '../../../resources/icons/users.svg';
import { ReactComponent as DeleteIcon } from '../../../resources/icons/delete_primary.svg';
import { ReactComponent as AcademicCapIcon } from '../../../resources/icons/academic_cap.svg';
import ClickableAuthorWithPopover from '../clickable-author-with-popover/ClickableAuthorWithPopover';
import {
  postSliderSettings,
  postsSliderResponsiveConfig,
} from '../../../constants/carouselSliderConfigs';

export const PostFooter = ({
  selectedEntities,
  children,
  showSelectedEntities,
  sharingGroupsLabel,
}) => {
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Box className="ayo-post-card__footer" flexDirection={isWidthUpSm ? 'row' : 'column'}>
      <Box columnGap={1} display="flex">
        {showSelectedEntities && sharingGroupsLabel && (
          <Tooltip
            aria-label={sharingGroupsLabel}
            enterTouchDelay={0}
            leaveTouchDelay={5000}
            onOpen={(e) => {
              e.stopPropagation();
              GA.logInteraction({
                category: GaCategories.BEHAVIOR,
                action: GaActions.ICON_HOVER,
                label: 'Who can see post',
              });
            }}
            placement="bottom-start"
            tabIndex={0}
            title={
              <Typography isLightText variant="caption">
                {sharingGroupsLabel}
              </Typography>
            }
          >
            <Box className="ayo-post-card__footer__icon">
              <UsersIcon />
            </Box>
          </Tooltip>
        )}
        {showSelectedEntities && !!selectedEntities.length && (
          <Tooltip
            aria-label={selectedEntities.map(({ name }) => name).join(', ')}
            enterTouchDelay={0}
            leaveTouchDelay={5000}
            onOpen={(e) => {
              e.stopPropagation();
              GA.logInteraction({
                category: GaCategories.BEHAVIOR,
                action: GaActions.ICON_HOVER,
                label: 'List who can see post',
              });
            }}
            placement="bottom-start"
            tabIndex={0}
            title={
              <Box>
                {selectedEntities.map(({ name }) => (
                  <Typography key={name} isLightText variant="caption">
                    {getFullSchoolName(name)}
                  </Typography>
                ))}
              </Box>
            }
          >
            <Box className="ayo-post-card__footer__icon">
              <AcademicCapIcon />
            </Box>
          </Tooltip>
        )}
      </Box>
      {children && <Box>{children}</Box>}
    </Box>
  );
};

PostFooter.propTypes = {
  selectedEntities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ),
  children: PropTypes.node,
  showSelectedEntities: PropTypes.bool,
  sharingGroupsLabel: PropTypes.string,
};

PostFooter.defaultProps = {
  selectedEntities: [],
  children: null,
  showSelectedEntities: false,
  sharingGroupsLabel: null,
};

export const PostActions = {
  EDIT: 'edit',
  DELETE: 'delete',
};

export const PostHeader = ({
  availableActions,
  author,
  sourceMetaData,
  alt,
  isAuthorClickable,
  hotspot,
  status,
  withIndicator,
  editDialogHandler,
  deleteDialogHandler,
  createdDate,
  postId,
}) => {
  const { t, i18n } = useTranslation();
  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between">
      <Box>
        <Box alignItems="flex-start" display="flex">
          <Box className="ayo-post-card__logos">
            {sourceMetaData?.sourceImage && (
              <Avatar
                alt={sourceMetaData?.sourceAlt}
                className="ayo-post-card__source-logo"
                src={sourceMetaData?.sourceImage}
              />
            )}
            <Box
              className={classnames('', {
                'ayo-post-card__avatar-logo--shifted': sourceMetaData?.sourceImage,
              })}
            >
              {author?.avatar ? (
                <DisplayAvatarEditorImage
                  alt={alt}
                  avatar={author?.avatar?.image}
                  avatarSize={48}
                  avatarTransforms={author?.avatar?.transforms}
                />
              ) : (
                <Avatar
                  alt={alt}
                  className="ayo-post-card__avatar-logo"
                  src={author?.profileImage}
                />
              )}
            </Box>
          </Box>
          <Box ml={sourceMetaData?.sourceImage ? 4 : 2}>
            {sourceMetaData?.sourceLink}
            <Box alignItems="center" display="flex">
              {isAuthorClickable ? (
                <Box alignItems="center" display="flex" flexDirection="row">
                  <ClickableAuthorWithPopover
                    author={author}
                    authorName={author.name}
                    className={classnames('ayo-post-card__title', {
                      'ayo-post-card__title--bold': sourceMetaData?.sourceLink,
                    })}
                    typographyVariant={sourceMetaData?.sourceLink ? 'body3' : 'subtitle2'}
                  />
                  {hotspot}
                </Box>
              ) : (
                <Typography component="h2" variant="subtitle2">
                  {author.name}
                </Typography>
              )}
              {status === FeedPostStatuses.EDITED && (
                <Box display="flex">
                  <Box mx={2}>
                    <Divider orientation="vertical" />
                  </Box>
                  <Typography className="ayo-post-card--edited" isLabel variant="body3">
                    {t('Edited')}
                  </Typography>
                </Box>
              )}
              {withIndicator && (
                <Box ml={2}>
                  <DotIndicator />
                </Box>
              )}
            </Box>
            <Typography isLabel variant="body3">
              {getFullDateAndHoursString(createdDate, i18n.language)}
            </Typography>
          </Box>
        </Box>
      </Box>
      {!!availableActions?.length && editDialogHandler && (
        <ActionsMenu
          id="post_actions"
          menuItems={[
            {
              text: t('Edit'),
              icon: EditIcon,
              handler: () => editDialogHandler(postId),
              id: 'edit_post',
              gaLabel: 'Edit post',
              value: PostActions.EDIT,
            },
            {
              text: t('Delete'),
              icon: DeleteIcon,
              handler: () => deleteDialogHandler(postId),
              id: 'delete_post',
              gaLabel: 'Delete post',
              value: PostActions.DELETE,
            },
          ].filter((action) => availableActions.includes(action.value))}
        />
      )}
    </Box>
  );
};

PostHeader.propTypes = {
  alt: PropTypes.string,
  author: authorContactInfo,
  cardRef: PropTypes.shape({ current: PropTypes.instanceOf(Object) }),
  deleteDialogHandler: PropTypes.func,
  editDialogHandler: PropTypes.func,
  availableActions: PropTypes.arrayOf(PropTypes.oneOf(Object.values(PostActions))),
  isAuthorClickable: PropTypes.bool,
  status: PropTypes.string.isRequired,
  sourceMetaData: PropTypes.shape({
    sourceAlt: PropTypes.string,
    sourceImage: PropTypes.string,
    sourceLink: PropTypes.node,
  }),
  withIndicator: PropTypes.bool,
  hotspot: PropTypes.node,
  postId: PropTypes.number.isRequired,
  createdDate: PropTypes.string.isRequired,
};

PostHeader.defaultProps = {
  alt: null,
  author: null,
  cardRef: null,
  deleteDialogHandler: null,
  editDialogHandler: null,
  availableActions: [],
  isAuthorClickable: false,
  sourceMetaData: {
    sourceAlt: '',
    sourceImage: null,
    sourceLink: null,
  },
  withIndicator: false,
  hotspot: null,
};

export const PostBody = ({
  category,
  title,
  defaultImageUrl,
  imageInfo,
  type,
  description,
  link,
  showFullDescription,
  customIllustration,
}) => {
  const { t } = useTranslation();
  const [isDescriptionCropped, setIsDescriptionCropped] = useState(null);
  const [isDescriptionCollapsed, setIsDescriptionCollapsed] = useState(!showFullDescription);
  const descriptionBodyRef = useRef(null);

  useEffect(() => {
    setIsDescriptionCropped(
      getIsDomElementCropped(
        descriptionBodyRef.current?.offsetHeight,
        0,
        descriptionBodyRef.current?.offsetParent?.clientHeight,
      ),
    );
  }, [description]);

  const PostIllustration = customIllustration || (
    <Carousel
      buttonsVariant="text-primary"
      items={imageInfo.map((image) => (
        <PostImage
          key={image?.id}
          category={category}
          defaultImageUrl={defaultImageUrl}
          imageInfo={image}
          postType={type}
        />
      ))}
      sliderResponsiveConfig={postsSliderResponsiveConfig}
      sliderSettings={postSliderSettings}
      variant="slider"
    />
  );

  return (
    <>
      {category && (
        <Box mb={2}>
          <Chip key={category} isCaption label={t(FeedPostCategoriesLabels[category])} />
        </Box>
      )}
      <Typography component="h3" variant="subtitle2">
        {title}
      </Typography>
      <Box
        className={classnames('ayo-post-card__description', {
          'ayo-post-card__description--collapsed': isDescriptionCollapsed,
        })}
        my={1}
      >
        <Typography variant="body2">
          {typeof description === 'object' ? (
            <span ref={descriptionBodyRef}>{description}</span>
          ) : (
            <span
              ref={descriptionBodyRef}
              dangerouslySetInnerHTML={getDangerouslySetInnerHtml(description)}
            />
          )}
        </Typography>
      </Box>
      {isDescriptionCropped && (
        <Box mb={2} mt={1}>
          {isDescriptionCollapsed ? (
            <EditingButton
              gaLabel="Read more"
              icon={<ExpandIcon />}
              iconPosition="end"
              onClick={() => {
                setIsDescriptionCollapsed(false);
              }}
              text={t('Read more')}
            />
          ) : (
            <EditingButton
              gaLabel="Read less"
              icon={<CollapseIcon />}
              iconPosition="end"
              onClick={() => {
                setIsDescriptionCollapsed(true);
              }}
              text={t('Read less')}
            />
          )}
        </Box>
      )}
      {!!link && (
        <Box my={2}>
          <Link gaLabel="Post card link open" href={link} target="_blank">
            <TextWithTooltip rowsCount={1} title={link} />
          </Link>
        </Box>
      )}
      {PostIllustration}
    </>
  );
};

PostBody.propTypes = {
  cardRef: PropTypes.shape({ current: PropTypes.instanceOf(Object) }),
  category: PropTypes.string,
  defaultImageUrl: PropTypes.string,
  description: PropTypes.string.isRequired,
  imageInfo: attachmentType,
  showFullDescription: PropTypes.bool,
  sourceMetaData: PropTypes.shape({
    sourceAlt: PropTypes.string,
    sourceImage: PropTypes.string,
    sourceLink: PropTypes.node,
  }),
  title: PropTypes.string.isRequired,
  link: PropTypes.string,
  type: PropTypes.string,
  customIllustration: PropTypes.node,
};

PostBody.defaultProps = {
  cardRef: null,
  category: null,
  defaultImageUrl: null,
  imageInfo: null,
  showFullDescription: false,
  sourceMetaData: {
    sourceAlt: '',
    sourceImage: null,
    sourceLink: null,
  },
  type: '',
  link: null,
  customIllustration: null,
};

const PostCard = React.forwardRef(({ cardRef, postId, children }) => (
  <Card
    ref={cardRef}
    className="ayo-post-card"
    elevation={0}
    id={postId}
    mainContent={<Box>{children}</Box>}
  />
));

PostCard.propTypes = {
  children: PropTypes.node,
  cardRef: PropTypes.shape({ current: PropTypes.instanceOf(Object) }),
  postId: PropTypes.number.isRequired,
};

PostCard.defaultProps = {
  cardRef: null,
  children: null,
};

export default PostCard;
