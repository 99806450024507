import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

import { Button } from '../../atoms';
import { ReactComponent as AttachIcon } from '../../../resources/icons/attach.svg';
import { ReactComponent as RemoveIcon } from '../../../resources/icons/delete_primary.svg';

const ImageEditButtons = ({ onChange, onDelete, item }) => {
  const { t } = useTranslation();
  return (
    <Box display="flex" gap={1}>
      {onChange && (
        <Button
          endIcon={<AttachIcon />}
          gaLabel="Change image"
          onClick={onChange}
          variant="text-primary"
        >
          {t('Change image')}
        </Button>
      )}
      <Button
        aria-label={t('Remove image')}
        gaLabel="Remove image"
        isIconButton
        onClick={() => onDelete(item)}
        variant="text-primary"
      >
        <RemoveIcon />
      </Button>
    </Box>
  );
};

ImageEditButtons.propTypes = {
  onChange: PropTypes.func,
  onDelete: PropTypes.func.isRequired,
  item: PropTypes.instanceOf(Object).isRequired,
};

ImageEditButtons.defaultProps = {
  onChange: null,
};

export default ImageEditButtons;
