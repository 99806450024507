import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Box, DialogActions, DialogContent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';

import { Dialog, DialogTitle, Typography } from '../../../../../atoms';
import { PopupActionsButtons } from '../../../../../moleculas';
import { UserContext } from '../../../../../../context';
import { getFirstTextFromEditor, getFullName } from '../../../../../../utils';
import { useStudentsService } from '../../../../../../services';
import { useSharedStudentData } from '../../../../../../hooks';
import {
  AttachmentsResourcesTypes,
  InputsValidationErrors,
  PublicationStatuses,
} from '../../../../../../constants/enums';
import RichTextEditor from '../../../../rich-text-editor/RichTextEditor';
import PortfolioAttachments from '../../../components/portfolio-attachments/PortfolioAttachments';
import useSnackbar from '../../../../../../hooks/use-snackbar/useSnackbar';

const FORMATTING_OPTIONS = ['headers', 'alignment', 'styling', 'lists', 'links', 'images'];

const PortfolioAddRecommendationDialog = ({
  isOpen,
  onClose,
  onSave,
  recommendation,
  recommendationRequest,
}) => {
  const { t } = useTranslation();
  const match = useRouteMatch();
  const { setSnackBarStatus } = useSnackbar();
  const [recommendationData, setRecommendationData] = useState({});
  const [recommendationId, setRecommendationId] = useState(null);
  const [textEditorError, setTextEditorError] = useState('');
  const recommendationRef = useRef();

  const { state: userState } = useContext(UserContext);
  const teacherId = userState.profile?.id;
  const { studentId } = match.params;

  const { activeStudent } = useSharedStudentData(studentId);

  const { postStudentRecommendation, updateStudentRecommendation, deleteStudentRecommendation } =
    useStudentsService();

  useEffect(() => {
    setTextEditorError('');
    setRecommendationId(recommendation?.id || null);
    setRecommendationData(() => {
      const data = recommendation || {
        requestId: recommendationRequest?.id || null,
        state: PublicationStatuses.DRAFT,
        reachTextEditorData: {
          textJson: '{ "ops": [{ "insert": "\\n" }] }',
          attachments: [],
          links: [],
        },
      };
      recommendationRef.current = data;
      return data;
    });
  }, [recommendation, teacherId, isOpen, recommendationRequest?.id]);

  const handleTextEditorChange = useCallback(
    (key, data) => {
      setRecommendationData((state) => {
        const newState = {
          ...state,
          reachTextEditorData: {
            ...state.reachTextEditorData,
            [key]: data,
          },
        };
        recommendationRef.current = newState;
        return newState;
      });
      if (textEditorError) {
        setTextEditorError('');
      }
    },
    [textEditorError],
  );

  const onBeforeImageUpload = useCallback(() => {
    if (!recommendationId) {
      postStudentRecommendation(studentId, recommendationRef.current, true).then((data) => {
        setRecommendationId(data.id);
      });
    }
  }, [postStudentRecommendation, recommendationId, studentId]);

  const setSnackBar = useCallback(
    () =>
      setSnackBarStatus({
        text: t('Thank you for your input!'),
        type: 'success',
      }),
    [setSnackBarStatus, t],
  );

  const saveHandler = useCallback(() => {
    const isValid = !!getFirstTextFromEditor(recommendationData.reachTextEditorData.textJson);
    if (!isValid) {
      setTextEditorError(InputsValidationErrors(t).REQUIRED_ERROR_TEXT);
    } else {
      const recommendationPostBody = {
        ...recommendationData,
        state: PublicationStatuses.PUBLISHED,
      };
      if (recommendationId) {
        updateStudentRecommendation(studentId, recommendationId, {
          ...recommendationPostBody,
          id: recommendationId,
        }).then((data) => {
          setSnackBar();
          onSave(data);
        });
      } else {
        postStudentRecommendation(studentId, recommendationPostBody).then((data) => {
          setSnackBar();
          onSave(data);
        });
      }
      onClose();
    }
  }, [
    onClose,
    onSave,
    postStudentRecommendation,
    recommendationData,
    recommendationId,
    setSnackBar,
    studentId,
    t,
    updateStudentRecommendation,
  ]);

  const onCloseHandler = useCallback(() => {
    if (recommendationId && recommendationData.state === PublicationStatuses.DRAFT) {
      deleteStudentRecommendation(studentId, recommendationId);
    }
    onClose();
  }, [deleteStudentRecommendation, onClose, recommendationData.state, recommendationId, studentId]);

  return (
    <Dialog
      className="ayo-portfolio__add-recommendation-dialog"
      fullWidth
      gaLabel="Recommendation dialog"
      maxWidth={1032}
      onClose={onClose}
      open={isOpen}
      transitionDuration={{ exit: 0 }}
    >
      <DialogTitle disableTypography>
        <Typography component="h2" variant="subtitle1">
          {recommendation ? t('Edit recommendation') : t('Write a recommendation')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box mb={3} mt={0.5}>
          <Typography variant="body2">
            {recommendation
              ? t("Here, you can edit the recommendation that you've written")
              : t('Here, you can write a recommendation for your student')}
          </Typography>
        </Box>
        {recommendationRequest && (
          <Box container display="flex" flexDirection="row" justifyContent="space-between">
            <Box className="ayo-portfolio__add-recommendation-dialog__request-block" mr={3}>
              <Typography component="h3" variant="subtitle2">
                {t('Student’s comment')}
              </Typography>
              <Box mt={3}>
                <Typography variant="body2">{recommendationRequest.details}</Typography>
              </Box>
            </Box>
            {!!recommendationRequest.attachments.length && (
              <Box className="ayo-portfolio__add-recommendation-dialog__request-block">
                <PortfolioAttachments
                  attachments={recommendationRequest.attachments}
                  title="Student’s attachment(s)"
                  titleComponent="h3"
                />
              </Box>
            )}
          </Box>
        )}
        <Box
          className="ayo-portfolio__add-recommendation-dialog__text-editor-wrapper"
          mt={3}
          pb={textEditorError ? 4 : 2}
          pt={2}
          px={2}
        >
          <Box mb={1}>
            <Typography component="h3" variant="subtitle2">{`${t(
              'Recommendation for',
            )} ${getFullName(activeStudent)}`}</Typography>
          </Box>
          {recommendationData.reachTextEditorData && (
            <Box position="relative">
              <RichTextEditor
                attachments={recommendationData.reachTextEditorData.attachments}
                bounds=".ayo-portfolio__add-recommendation-dialog__text-editor-wrapper"
                errorMsg={textEditorError}
                formattingOptions={FORMATTING_OPTIONS}
                links={recommendationData.reachTextEditorData.links}
                onBeforeImageUpload={onBeforeImageUpload}
                onChange={(key, data) => handleTextEditorChange(key, data)}
                ownerId={+studentId}
                resourceId={recommendationId}
                resourceType={AttachmentsResourcesTypes.PORTFOLIO_RECOMMENDATION_TEXT_EDITOR}
                value={recommendationData.reachTextEditorData.textJson}
              />
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Box mt={3} width="50%">
          <PopupActionsButtons
            primaryButtonGaLabel={recommendation ? 'Save changes' : 'Submit'}
            primaryButtonHandler={saveHandler}
            primaryButtonText={recommendation ? t('Save changes') : t('Submit')}
            secondaryButtonGaLabel="Cancel"
            secondaryButtonHandler={onCloseHandler}
            secondaryButtonText={t('Cancel')}
          />
        </Box>
      </DialogActions>
    </Dialog>
  );
};

PortfolioAddRecommendationDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  recommendation: PropTypes.instanceOf(Object),
  recommendationRequest: PropTypes.instanceOf(Object),
};

PortfolioAddRecommendationDialog.defaultProps = {
  recommendation: null,
  recommendationRequest: null,
};

export default PortfolioAddRecommendationDialog;
