import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useInView as reactUseInView } from 'react-intersection-observer';
import { Box, DialogActions, DialogContent, List, ListItem } from '@mui/material';

import { Avatar, Button, Dialog, DialogTitle, DotIndicator, Typography } from '../../atoms';
import { EditingButton, TextBadge } from '../../moleculas';
import { useInView } from '../../../hooks';
import { getRelativeDateTime, Highlighter } from '../../../utils';
import { ReactComponent as ActivityLogIcon } from '../../../resources/icons/activity_log.svg';
import { ReactComponent as CheckIcon } from '../../../resources/icons/check_circle.svg';
import { ReactComponent as CheckDisabledIcon } from '../../../resources/icons/check_circle_outline.svg';
import { ReactComponent as EditIcon } from '../../../resources/icons/create.svg';
import { ReactComponent as EnvelopeIcon } from '../../../resources/icons/envelope.svg';
import { ReactComponent as ExportIcon } from '../../../resources/icons/download.svg';
import { ReactComponent as AcknowledgedIcon } from '../../../resources/icons/acknowledged.svg';
import { ReactComponent as CommentIcon } from '../../../resources/icons/comment.svg';
import { ReactComponent as ClosedIcon } from '../../../resources/icons/hand_with_heart.svg';
import { ReactComponent as RedFlagIcon } from '../../../resources/icons/red_flag.svg';
import { ReactComponent as NoActivityLogsImage } from '../../../resources/images/no_activity_logs.svg';

const ExportButton = ({ exportUrl }) => {
  const { t } = useTranslation();

  return (
    <Button
      component="a"
      download
      endIcon={<ExportIcon height={24} width={24} />}
      gaLabel="Export CSV"
      href={exportUrl}
    >
      {t('Export CSV')}
    </Button>
  );
};

ExportButton.propTypes = {
  exportUrl: PropTypes.string.isRequired,
};

const ActivityLogEmptyState = ({ hasLogHistory, exportUrl }) => {
  const { t } = useTranslation();

  return (
    <Box alignItems="center" display="flex" flexDirection="column">
      <Box pb={3.5}>
        <NoActivityLogsImage aria-label={t('A note, a pencil, and an eraser')} />
      </Box>
      <Box pb={0.5}>
        <Typography align="center" variant="subtitle2">
          {t(!hasLogHistory ? 'No activity logs so far' : 'No activity logs')}
        </Typography>
      </Box>
      <Box maxWidth="65%">
        <Typography align="center" variant="body2">
          {t(
            !hasLogHistory
              ? 'Here, you’ll see activity logs as soon as they are present.'
              : 'Last changes were made more than 3 months ago Download the report to check them',
          )}
        </Typography>
        {hasLogHistory && (
          <Box display="flex" flex={1} justifyContent="center" py={4}>
            <ExportButton exportUrl={exportUrl} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

ActivityLogEmptyState.propTypes = {
  hasLogHistory: PropTypes.bool.isRequired,
  exportUrl: PropTypes.string.isRequired,
};

const avatarConfig = {
  acknowledged: { className: 'ayo-activity-log__avatar--secondary', content: <AcknowledgedIcon /> },
  approved: { className: 'ayo-activity-log__avatar--green', content: <CheckIcon /> },
  comment: { className: 'ayo-activity-log__avatar--secondary', content: <CommentIcon /> },
  closed: { className: 'ayo-activity-log__avatar--secondary', content: <ClosedIcon /> },
  disapproved: { className: 'ayo-activity-log__avatar--grey', content: <CheckDisabledIcon /> },
  edited: {
    className: 'ayo-activity-log__avatar--secondary ayo-activity-log__avatar--secondary--filled',
    content: <EditIcon />,
  },
  hide: { className: 'ayo-activity-log__avatar--grey', content: 'OFF' },
  hide_all: { className: 'ayo-activity-log__avatar--grey', content: 'OFF' },
  requested: { className: 'ayo-activity-log__avatar--secondary', content: <RedFlagIcon /> },
  showed: { className: 'ayo-activity-log__avatar--primary', content: 'ON' },
  showed_all: { className: 'ayo-activity-log__avatar--primary', content: 'ON' },
};

const ActivityLogItem = ({ item, onItemUpdate }) => {
  const { i18n, t } = useTranslation();
  const { createdDate, initiatorFirstName, initiatorLastName, translationText } = item;
  const actionType = item.actionType.toLowerCase();
  const contentType = item.contentType.toLowerCase();

  const { ref } = useInView(() => onItemUpdate(item), 5000, item.read);

  return (
    <ListItem disableGutters tabIndex="0">
      <DotIndicator
        ref={ref}
        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
        invisible={item.read}
      >
        <Avatar className={avatarConfig[actionType]?.className}>
          {avatarConfig[actionType]?.content}
        </Avatar>
      </DotIndicator>
      <Box pl={2}>
        <Typography variant="body2">
          {Highlighter(
            t(`activityLog.${actionType}`, {
              initiatorFirstName,
              initiatorLastName,
              contentType,
              translationText,
            }),
            [initiatorFirstName, initiatorLastName, contentType, translationText],
          )}
        </Typography>
        <Box pt={1}>
          <Typography isLabel variant="caption">
            {getRelativeDateTime(createdDate, i18n.language, t)}
          </Typography>
        </Box>
      </Box>
    </ListItem>
  );
};

ActivityLogItem.propTypes = {
  item: PropTypes.shape({
    actionType: PropTypes.string,
    contentType: PropTypes.string,
    createdDate: PropTypes.string,
    id: PropTypes.number,
    initiatorFirstName: PropTypes.string,
    initiatorLastName: PropTypes.string,
    read: PropTypes.bool,
    translationText: PropTypes.string,
  }).isRequired,
  onItemUpdate: PropTypes.func.isRequired,
};

let timer;
const RELOAD_TIMEOUT = 600000;

const ActivityLog = ({
  exportUrl,
  isReadyToLoadData,
  onDataLoad,
  onUnreadCountLoad,
  onUpdateItem,
  onUpdateAllItems,
}) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const [isAlertVisible, setIsAlertVisible] = useState(false);

  const handleOpenDialogClick = () => setIsOpen(true);
  const handleCloseDialogClick = () => setIsOpen(false);

  const [activityLogList, setActivityLogList] = useState([]);
  const [hasLogHistory, setHasLogHistory] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [pageNum, setPageNum] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const { ref, inView } = reactUseInView();

  const handleUpdate = useCallback(
    (item) => {
      if (item) {
        onUpdateItem([item.id]);
        setActivityLogList((state) =>
          state.map((i) => (i.id === item.id ? { ...i, read: true } : i)),
        );
        setUnreadCount((state) => state - 1);
      } else {
        onUpdateAllItems();
        setActivityLogList((state) => state.map((i) => ({ ...i, read: true })));
        setUnreadCount(0);
      }
    },
    [onUpdateAllItems, onUpdateItem],
  );

  const handleDataLoad = useCallback(
    (page) => {
      onDataLoad(page).then((res) => {
        setActivityLogList((state) => (page === 0 ? res.content : [...state, ...res.content]));
        if (page === 0) {
          setHasLogHistory(res.hasLogHistory);
          setPageNum(0);
          setTotalPages(res.totalPages);
        }
      });

      if (page === 0) {
        onUnreadCountLoad().then((res) => {
          setUnreadCount(res);
        });
      }
    },
    [onDataLoad, onUnreadCountLoad],
  );

  useEffect(() => {
    if (isReadyToLoadData) {
      handleDataLoad(0);
      if (!timer) {
        timer = setInterval(() => {
          handleDataLoad(0);
        }, RELOAD_TIMEOUT);
      }
    }
    return () => {
      if (timer) {
        clearInterval(timer);
        timer = null;
      }
    };
  }, [handleDataLoad, isReadyToLoadData]);

  useEffect(() => {
    if (inView) {
      setPageNum((state) => (state < totalPages - 1 ? state + 1 : state));
    }
  }, [inView, totalPages]);

  useEffect(() => {
    if (pageNum > 0) {
      handleDataLoad(pageNum);
    }
  }, [pageNum, handleDataLoad]);

  useEffect(() => {
    if (unreadCount) {
      setIsAlertVisible(true);
      setTimeout(() => {
        setIsAlertVisible(false);
      }, 1000);
    }
  }, [unreadCount]);

  return (
    <>
      <Button
        aria-label={`${t('Activity log')} ${t('You have number new activity logs', {
          number: unreadCount,
        })}`}
        endIcon={
          <DotIndicator className="ayo-activity-log__button" invisible={!unreadCount}>
            <ActivityLogIcon />
          </DotIndicator>
        }
        gaLabel="Activity log"
        onClick={handleOpenDialogClick}
      >
        {t('Activity log')}
      </Button>
      <Dialog className="ayo-activity-log__dialog" onClose={handleCloseDialogClick} open={isOpen}>
        <DialogTitle disableTypography>
          <Typography component="h2" variant="subtitle1">
            {t('Activity log')}
          </Typography>
          {unreadCount > 0 && <TextBadge text={unreadCount.toString()} />}
          {activityLogList.length > 0 && <ExportButton exportUrl={exportUrl} />}
        </DialogTitle>
        <DialogContent>
          {activityLogList.length > 0 ? (
            <>
              <List disablePadding>
                {activityLogList.map((item) => (
                  <ActivityLogItem key={item.id} item={item} onItemUpdate={handleUpdate} />
                ))}
              </List>
              <div ref={ref} className="ayo-activity-log__load-next-anchor" />
            </>
          ) : (
            <ActivityLogEmptyState exportUrl={exportUrl} hasLogHistory={hasLogHistory} />
          )}
        </DialogContent>
        <DialogActions>
          {unreadCount > 0 && (
            <EditingButton
              gaLabel="Mark all as read"
              icon={<EnvelopeIcon />}
              iconPosition="end"
              onClick={() => handleUpdate()}
              text={t('Mark all as read')}
            />
          )}
        </DialogActions>
      </Dialog>
      {isAlertVisible && (
        <div className="sr-only" role="alert">
          {t('You have number new activity logs', { number: unreadCount })}
        </div>
      )}
    </>
  );
};

ActivityLog.propTypes = {
  exportUrl: PropTypes.string.isRequired,
  isReadyToLoadData: PropTypes.bool,
  onDataLoad: PropTypes.func.isRequired,
  onUnreadCountLoad: PropTypes.func.isRequired,
  onUpdateItem: PropTypes.func.isRequired,
  onUpdateAllItems: PropTypes.func.isRequired,
};

ActivityLog.defaultProps = {
  isReadyToLoadData: false,
};

export default ActivityLog;
