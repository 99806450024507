import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, DialogActions, DialogContent } from '@mui/material';
import classNames from 'classnames';

import {
  Checkbox,
  Dialog,
  DialogTitle,
  DotIndicator,
  FormControlLabel,
  Typography,
} from '../../../../atoms';
import { PopupActionsButtons } from '../../../../moleculas';
import { converterBooleanString } from '../../../../../utils';
import { PortfolioSectionSettings, PortfolioSectionTypes } from '../../../../../constants/enums';

const PortfolioAddItemsDialogConfig = {
  DEFAULT_CONFIG: {
    title: 'Add portfolio items title',
    subtitle: 'Select sectionName that you would like to add to your portfolio',
    settingsTitle: '',
    itemsTitle: '',
  },
  [PortfolioSectionTypes.PORTFOLIO__LEADERSHIP_ATTRIBUTES]: {
    title: 'Leadership Attributes',
    subtitle: 'Select sectionName that you would like to add to your portfolio',
    settingsTitle: 'Progress',
    itemsTitle: 'Leadership skills',
  },
};

const PortfolioAddItemsDialog = ({
  isOpen,
  itemsToAdd,
  notifications,
  onAdd,
  onClose,
  sectionKey,
  sectionName,
  settings,
}) => {
  const { t } = useTranslation();
  const [checkedItems, setCheckedItems] = useState([]);
  const [settingItems, setSettingItems] = useState(null);

  const checkboxHandler = useCallback((e) => {
    const { name, checked } = e.target;
    if (checked) {
      setCheckedItems((prevState) => [...prevState, name]);
    } else {
      setCheckedItems((prevState) => prevState.filter((id) => id !== name));
    }
  }, []);

  const settingsHandler = useCallback(
    (e) => {
      const { name, checked } = e.target;
      const settingIndex = settingItems.findIndex((settingItem) => settingItem.name === name);
      const newSettings = Object.assign([...settingItems], {
        [settingIndex]: {
          ...settingItems[settingIndex],
          value: converterBooleanString(checked),
        },
      });
      setSettingItems(newSettings);
      return newSettings;
    },
    [settingItems],
  );

  const dialogConfig = useMemo(
    () => PortfolioAddItemsDialogConfig[sectionKey] || PortfolioAddItemsDialogConfig.DEFAULT_CONFIG,
    [sectionKey],
  );

  const getItemName = useCallback(
    (itemName) =>
      sectionKey === PortfolioSectionTypes.PORTFOLIO__LEADERSHIP_ATTRIBUTES
        ? t(itemName)
        : itemName,
    [sectionKey, t],
  );

  useEffect(() => {
    if (!isOpen) {
      setSettingItems(settings);
      setCheckedItems([]);
    }
  }, [isOpen, settings]);

  const notificationUpdatedItems = useMemo(() => {
    const result = itemsToAdd?.map((item) => {
      let mergedItem = item;
      notifications.some((notificationItem) => {
        if (
          item.id.toString() === notificationItem.resourceId?.toString() ||
          item.name === notificationItem?.metadata?.info?.resourceName ||
          item.id === notificationItem?.metadata?.info?.resourceName
        ) {
          mergedItem = { ...item, markedAsRead: notificationItem?.markedAsRead };
          return true;
        }
        mergedItem = { ...item, markedAsRead: true };
        return false;
      });
      return mergedItem;
    });
    const sortedResult = result?.sort((a, b) => {
      if (a.markedAsRead === b.markedAsRead) {
        return Date.parse(a?.createdDate) - Date.parse(b?.createdDate);
      }
      return a?.markedAsRead - b?.markedAsRead;
    });
    return sortedResult;
  }, [notifications, itemsToAdd]);

  return (
    <Dialog
      className={`${classNames(
        'ayo-portfolio__add-dialog',
        `ayo-portfolio__add-dialog--${sectionKey}`,
      )}`}
      gaLabel="Portfolio add item dialog"
      onClose={onClose}
      open={isOpen}
      scroll="paper"
    >
      <DialogTitle disableTypography>
        <Box pb={3}>
          <Typography component="h2" variant="subtitle1">
            {t(dialogConfig?.title, {
              sectionName: t(sectionName).toLowerCase(),
            })}
          </Typography>
          <Box mt={1}>
            <Typography component="presentation" variant="body2">
              {t(dialogConfig?.subtitle, {
                sectionName:
                  sectionKey === PortfolioSectionTypes.PORTFOLIO__LEADERSHIP_ATTRIBUTES
                    ? t(sectionName)
                    : t(sectionName).toLowerCase(),
              })}
            </Typography>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box>
          {dialogConfig?.itemsTitle && (
            <Box mb={1.5}>
              <Typography component="h3" variant="subtitle2">
                {t(dialogConfig.itemsTitle)}
              </Typography>
            </Box>
          )}
          <Box display="flex" flexDirection="column">
            {notificationUpdatedItems?.map((item) => {
              const label = (
                <Box mt={0.5}>
                  <Typography variant="caption">{item.label}</Typography>
                </Box>
              );
              return (
                <Box key={item.id} mb={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkedItems?.some((id) => id === item.id)}
                        gaLabel={item.name}
                        name={item.id}
                        onChange={checkboxHandler}
                      />
                    }
                    label={
                      item.markedAsRead === false ? (
                        <Box display="flex" flexDirection="column">
                          <Box display="flex">
                            <Typography
                              className="ayo-portfolio__add-dialog__title"
                              variant="subtitle2"
                            >
                              {getItemName(item.name)}
                            </Typography>
                            <DotIndicator ariaLabel={t('New')} />
                          </Box>
                          {item.label && label}
                        </Box>
                      ) : (
                        <Box display="flex" flexDirection="column">
                          <Typography className="ayo-portfolio__add-dialog__title" variant="body2">
                            {getItemName(item.name)}
                          </Typography>
                          {item.label && label}
                        </Box>
                      )
                    }
                  />
                </Box>
              );
            })}
          </Box>
          {settingItems && (
            <Box>
              <Box mb={1.5}>
                <Typography component="h3" variant="subtitle2">
                  {t(dialogConfig?.settingsTitle)}
                </Typography>
              </Box>
              {settingItems.map((setting) => (
                <Box key={setting.name} mb={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={converterBooleanString(
                          setting.value ?? setting.defaultValue,
                          true,
                        )}
                        gaLabel={PortfolioSectionSettings[setting.name].label}
                        name={setting.name}
                        onChange={settingsHandler}
                      />
                    }
                    label={
                      <Typography variant="body2">
                        {t(PortfolioSectionSettings[setting.name].label)}
                      </Typography>
                    }
                  />
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <PopupActionsButtons
          primaryButtonGaLabel="Add"
          primaryButtonHandler={() => {
            onAdd(checkedItems, settingItems);
            onClose();
          }}
          primaryButtonText={t('Add')}
          secondaryButtonGaLabel="Cancel"
          secondaryButtonHandler={onClose}
          secondaryButtonText={t('Cancel')}
        />
      </DialogActions>
    </Dialog>
  );
};

PortfolioAddItemsDialog.propTypes = {
  itemsToAdd: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  isOpen: PropTypes.bool.isRequired,
  notifications: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  onAdd: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  sectionKey: PropTypes.string.isRequired,
  sectionName: PropTypes.string.isRequired,
  settings: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
};

PortfolioAddItemsDialog.defaultProps = {
  itemsToAdd: null,
  settings: null,
  notifications: [],
};

export default PortfolioAddItemsDialog;
