import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

import { Checkbox, FormControlLabel, Typography } from '../../../atoms';
import { OpportunitiesCategoriesMap } from '../../../../constants/enums';
import { getFilterValueParts, isSimpleFilterSelected, updateSimpleFilter } from '../../../../utils';
import { OpportunitiesFilterCategory } from '../../../../constants/filter-categories';

const OpportunitiesTypeFilters = ({ menuFilters, setMenuFilters }) => {
  const { filtersList } = getFilterValueParts(menuFilters);

  const onChange = useCallback(
    (e) => {
      const optionsList = Object.keys(OpportunitiesCategoriesMap);
      const newFilters = updateSimpleFilter(
        e.target.value,
        OpportunitiesFilterCategory,
        menuFilters,
        optionsList,
      );
      setMenuFilters(newFilters);
    },
    [menuFilters, setMenuFilters],
  );

  const { t } = useTranslation();

  return (
    <Box>
      <Box className="filters-controls-box" display="flex" flexDirection="column">
        <Typography variant="subtitle2">{t('Filter by')}</Typography>
        {Object.entries(OpportunitiesCategoriesMap).map(([key, displayName]) => (
          <FormControlLabel
            key={key}
            control={
              <Checkbox
                checked={isSimpleFilterSelected(
                  key,
                  OpportunitiesFilterCategory,
                  menuFilters.filterCategory,
                  filtersList,
                )}
                gaLabel={`Opportunitie Filters - Type - ${displayName}`}
                onChange={onChange}
                value={key}
              />
            }
            label={t(displayName)}
          />
        ))}
      </Box>
    </Box>
  );
};

OpportunitiesTypeFilters.propTypes = {
  menuFilters: PropTypes.shape({ filterValue: PropTypes.string, filterCategory: PropTypes.string })
    .isRequired,
  setMenuFilters: PropTypes.func.isRequired,
};

export default OpportunitiesTypeFilters;
