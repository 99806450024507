import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Container, useMediaQuery, useTheme } from '@mui/material';
import { useRouteMatch } from 'react-router-dom';

import { GoalAddEditForm } from '../components';
import { PageWrapper } from '../../../../../organisms';
import { goalsRoute, trainAyoRoute } from '../../../../../../constants/routes';

const GoalEditPage = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const match = useRouteMatch();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const { studentId } = match.params;

  return (
    <PageWrapper
      backToLink={`${trainAyoRoute}/${studentId}${goalsRoute}`}
      backToText={t('Go to my goals')}
      mainElementClassName="ayo-goal-edit"
    >
      <Container>
        <Box mb={isWidthUpSm ? 10 : 5}>
          <GoalAddEditForm mode="edit" />
        </Box>
      </Container>
    </PageWrapper>
  );
};
export default GoalEditPage;
