import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, DialogActions, DialogContent, Tab, useMediaQuery, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {
  Checkbox,
  Dialog,
  DialogTitle,
  DynamicGrid,
  FormControlLabel,
  TabPanel,
  Tabs,
  Typography,
} from '../../../../atoms';
import { Dropdown, PopupActionsButtons } from '../../../../moleculas';
import { UserContext } from '../../../../../context';
import { capitalize, focusDialogCloseButton, GA } from '../../../../../utils';
import { ClubKeys } from '../../../../../constants/club-hub';
import { GaActions, GaCategories, SchoolLevels } from '../../../../../constants/enums';

const CampusContent = ({ onChange, schools, selectedEntities }) => {
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <DynamicGrid
      columnsCount={isWidthUpSm ? 2 : 1}
      gridItems={schools.map(({ schoolName }) => (
        <FormControlLabel
          key={schoolName}
          control={
            <Checkbox
              checked={selectedEntities.some((entity) => entity === schoolName)}
              gaLabel="Select club visibility"
              onChange={onChange}
              value={schoolName}
            />
          }
          label={schoolName}
        />
      ))}
      spacingX={3}
      spacingY={2}
    />
  );
};

CampusContent.propTypes = {
  onChange: PropTypes.bool.isRequired,
  schools: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  selectedEntities: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const schoolsOrder = {
  [SchoolLevels.ELEMENTARY]: 0,
  [SchoolLevels.MIDDLE]: 1,
  [SchoolLevels.HIGH]: 2,
};
const schoolLevelSort = (a, b) => schoolsOrder[a] - schoolsOrder[b];

const ClubVisibilityDialog = ({ entities, isOpen, onClose, onSave, primaryButtonText, title }) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  const { state: userState } = useContext(UserContext);

  const schools = useMemo(() => userState.profile?.schools, [userState.profile?.schools]);

  const [activeTab, setActiveTab] = useState(null);
  const [selectedEntities, setSelectedEntities] = useState([]);
  const [hasError, setHasError] = useState(false);

  const tabChangeHandler = useCallback((e, value) => {
    setActiveTab(value);
    focusDialogCloseButton();
    GA.logInteraction({
      category: GaCategories.BEHAVIOR,
      action: GaActions.TAB_CLICK,
      label: 'Schools level',
    });
  }, []);

  const selectedEntitiesChangeHandler = useCallback((e) => {
    const { checked, value } = e.target;
    if (checked) {
      setSelectedEntities((prevState) => [...prevState, value]);
    } else {
      setSelectedEntities((prevState) => prevState.filter((entity) => entity !== value));
    }
    setHasError(false);
  }, []);

  const saveHandler = useCallback(() => {
    if (selectedEntities.length) {
      onSave({ [ClubKeys.SCHOOLS]: selectedEntities });
      onClose();
    } else {
      setHasError(true);
    }
  }, [onClose, onSave, selectedEntities]);

  const sortedSchoolLevels = useMemo(() => Object.keys(schools).sort(schoolLevelSort), [schools]);

  useEffect(() => {
    setHasError(false);
    setSelectedEntities(entities);
    setActiveTab(sortedSchoolLevels?.[0]);
  }, [entities, isOpen, sortedSchoolLevels]);

  return (
    <Dialog
      className={classnames('ayo-club-hub__visibility-dialog', {
        'ayo-club-hub__visibility-dialog--error': hasError,
      })}
      gaLabel="Club visibility dialog"
      onClose={onClose}
      open={isOpen}
    >
      <DialogTitle disableTypography>
        <Typography component="h2" variant="subtitle1">
          {t(title)}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography component="h3" mb={1} mt={3} variant="subtitle2">
          {t('Campuses')}
        </Typography>
        <Typography variant="body2">
          {t('Select one or multiple campuses within the tab(s).')}
        </Typography>
        <Box mb={5} mt={2}>
          {isWidthUpSm || sortedSchoolLevels?.length < 3 ? (
            <>
              <Tabs
                className="content-section-tabs ayo-tabs--outlined"
                disableRipple
                onChange={tabChangeHandler}
                value={activeTab}
                variant="fullWidth"
              >
                {sortedSchoolLevels.map((schoolLevel) => (
                  <Tab key={schoolLevel} label={t(capitalize(schoolLevel))} value={schoolLevel} />
                ))}
              </Tabs>
              <Box mt={3} px={1}>
                {Object.keys(schools).map((schoolLevel) => (
                  <TabPanel key={schoolLevel} currentTabValue={activeTab} value={schoolLevel}>
                    <CampusContent
                      onChange={selectedEntitiesChangeHandler}
                      schools={schools[schoolLevel]}
                      selectedEntities={selectedEntities}
                    />
                  </TabPanel>
                ))}
              </Box>
            </>
          ) : (
            <>
              <Box mb={2}>
                <Dropdown
                  fullWidth
                  gaLabel="Schools level"
                  handleChange={(e) => {
                    setActiveTab(e.target.value);
                    focusDialogCloseButton();
                  }}
                  options={sortedSchoolLevels.map((schoolLevel) => ({
                    value: schoolLevel,
                    label: t(capitalize(schoolLevel)),
                  }))}
                  value={activeTab}
                />
              </Box>
              <CampusContent
                onChange={selectedEntitiesChangeHandler}
                schools={schools[activeTab]}
                selectedEntities={selectedEntities}
              />
            </>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Box display="flex" flexDirection="column" width="100%">
          {hasError && (
            <Typography
              className="ayo-club-hub__visibility-dialog__helper-text"
              mt={1}
              variant="body2"
            >
              {t('Please specify for whom your club can be available to apply.')}
            </Typography>
          )}
          <Box mt={2}>
            <PopupActionsButtons
              primaryButtonGaLabel={primaryButtonText}
              primaryButtonHandler={saveHandler}
              primaryButtonText={t(primaryButtonText)}
              secondaryButtonGaLabel="Cancel"
              secondaryButtonHandler={onClose}
              secondaryButtonText={t('Cancel')}
            />
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

ClubVisibilityDialog.propTypes = {
  entities: PropTypes.arrayOf(PropTypes.string).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  primaryButtonText: PropTypes.string.isRequired,
  title: PropTypes.string,
};

ClubVisibilityDialog.defaultProps = {
  title: 'Specify club availability',
};

export default ClubVisibilityDialog;
