import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';

export const SharedDataContext = createContext();

export const SharedDataActions = {
  SET_ACTIVE_STUDENT: 'SET_ACTIVE_STUDENT',
  SET_EVIDENCES_OF_GROWTH: 'SET_EVIDENCES_OF_GROWTH',
  SET_RELATED_TO_EVIDENCE_GOAL: 'SET_RELATED_TO_EVIDENCE_GOAL',
};

const initState = {
  activeStudent: null,
  evidencesOfGrowth: [],
  relatedToEvidenceGoal: null,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case SharedDataActions.SET_ACTIVE_STUDENT:
      return { ...state, activeStudent: action.data };
    case SharedDataActions.SET_EVIDENCES_OF_GROWTH:
      return { ...state, evidencesOfGrowth: action.data };
    case SharedDataActions.SET_RELATED_TO_EVIDENCE_GOAL:
      return { ...state, relatedToEvidenceGoal: action.data };
    default:
      throw new Error();
  }
};

export const SharedDataContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initState);

  return (
    <SharedDataContext.Provider value={{ state, dispatch }}>{children}</SharedDataContext.Provider>
  );
};

SharedDataContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
