import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

import { Button, ErrorWrapper, Typography } from '../../../../../../atoms';
import { EventSharingGroupsLabel } from '../../../../../../../constants/events';
import { ReactComponent as UsersIcon } from '../../../../../../../resources/icons/users.svg';
import { ReactComponent as AcademicCapIcon } from '../../../../../../../resources/icons/academic_cap.svg';
import ChipsBlock from '../../../../../chips-block/ChipsBlock';

const EventVisibilityButton = ({
  error,
  onDelete,
  onVisibilityButtonClick,
  resourceType,
  sharedData,
}) => {
  const { t } = useTranslation();

  const { sharingGroups, selectedEntities } = sharedData;

  return (
    <Box mt={3} px={1}>
      <ChipsBlock
        actionButtons={
          <ErrorWrapper errorMessage={t(error)}>
            <Button
              endIcon={<UsersIcon />}
              gaLabel="Specify who can see the event"
              onClick={onVisibilityButtonClick}
            >
              {t('Specify who can see the event')}
            </Button>
          </ErrorWrapper>
        }
        clearAllHandler={onDelete}
        editableChipsTitle={t('Who can see the event')}
        entities={selectedEntities || []}
        icon={<AcademicCapIcon />}
        onDeleteHandler={(item) =>
          onDelete(selectedEntities.filter((x) => x.id !== item.id || x.name !== item.name))
        }
        subtitle={
          sharingGroups ? (
            <Box display="flex" gap={1}>
              <UsersIcon />
              <Typography variant="body2">
                {t('Who can see the event groups', {
                  group: EventSharingGroupsLabel[resourceType][sharingGroups.join('_')].label,
                })}
              </Typography>
            </Box>
          ) : null
        }
        withEditableChips
      />
    </Box>
  );
};

EventVisibilityButton.propTypes = {
  error: PropTypes.string,
  onDelete: PropTypes.func.isRequired,
  onVisibilityButtonClick: PropTypes.func.isRequired,
  resourceType: PropTypes.string.isRequired,
  sharedData: PropTypes.instanceOf(Object).isRequired,
};

EventVisibilityButton.defaultProps = {
  error: null,
};

export default EventVisibilityButton;
