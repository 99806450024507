import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import IntersectionObserver from 'react-intersection-observer';
import PropTypes from 'prop-types';

import { InitiativeEmptyStateBlock, Loader } from '../../../../moleculas';
import { FamilyFeedPost } from '../../../../organisms';
import { FamilyFeedContext, UserContext } from '../../../../../context';
import { useFamilyFeed, useNotificationsData } from '../../../../../hooks';
import {
  FeedPostCategories,
  NotificationResourcesMap,
  RolesMap,
} from '../../../../../constants/enums';
import { getNormalizedLanguageCode } from '../../../../../utils';
import { ReactComponent as NoFeedPostsIllustration } from '../../../../../resources/images/no_feed_posts.svg';

const FeedBlock = ({ postId, studentId, deleteDialogHandler, editDialogHandler }) => {
  const { t, i18n } = useTranslation();
  const { state: familyFeedState } = useContext(FamilyFeedContext);
  const { state: userState } = useContext(UserContext);
  const { isLoading, totalNumberOfPosts, posts, activeFlterCategory } = familyFeedState;
  const currentStudentId =
    userState.profile?.role === RolesMap.STUDENT ? userState.profile?.id : studentId;

  const { notificationsList } = useNotificationsData();

  const postsNotifications = useMemo(
    () =>
      notificationsList?.WEB_HIDDEN?.filter(
        (notification) =>
          notification.resource === NotificationResourcesMap.FEED_POST_NEW_INDICATOR,
      ),
    [notificationsList],
  );
  const { getPosts, getFilterCategories, getPageSize } = useFamilyFeed();

  const getPostsRolesMap = useMemo(
    () => ({
      [RolesMap.ADMINISTRATOR]: {
        postsHandlerArgs: [],
        morePostsHandlerArgs: [],
        emptyStateBodyText: 'But you can change this now (educator)',
      },
      [RolesMap.TEACHER]: {
        postsHandlerArgs: [],
        morePostsHandlerArgs: [],
        emptyStateBodyText: 'But you can change this now (educator)',
      },
      [RolesMap.STUDENT]: {
        postsHandlerArgs: [currentStudentId, getNormalizedLanguageCode(i18n.language), true],
        morePostsHandlerArgs: [currentStudentId, getNormalizedLanguageCode(i18n.language), false],
        emptyStateBodyText: '',
      },
      [RolesMap.GUARDIAN]: {
        postsHandlerArgs: [currentStudentId, getNormalizedLanguageCode(i18n.language), true],
        morePostsHandlerArgs: [currentStudentId, getNormalizedLanguageCode(i18n.language), false],
        emptyStateBodyText:
          'As soon as there are posts, you will see a red dot next to the name of your kid.',
      },
    }),
    [i18n.language, currentStudentId],
  );

  useEffect(() => {
    getFilterCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // initial fetch and re-fetch on studentId/language change
    if (Number.isInteger(postId)) {
      getPageSize(studentId, postId).then((pageSize) => {
        getPosts(...getPostsRolesMap[userState.profile.role].postsHandlerArgs, pageSize, true);
      });
    } else {
      getPosts(...getPostsRolesMap[userState.profile.role].postsHandlerArgs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPostsRolesMap, userState?.profile?.role, activeFlterCategory]);

  const loadMorePosts = useCallback(() => {
    if (!isLoading && posts?.length < totalNumberOfPosts) {
      getPosts(...getPostsRolesMap[userState.profile.role].morePostsHandlerArgs);
    }
  }, [
    getPosts,
    getPostsRolesMap,
    isLoading,
    posts?.length,
    totalNumberOfPosts,
    userState?.profile?.role,
  ]);

  return posts?.length ? (
    <Grid alignItems="baseline" container item>
      <Grid container item rowSpacing={2}>
        {posts.map((post, i, self) => (
          <Grid key={post.id} item xs={12}>
            {i === self.length - 1 && (
              <IntersectionObserver
                as="div"
                onChange={(inView) => {
                  if (inView) {
                    loadMorePosts();
                  }
                }}
              />
            )}
            <FamilyFeedPost
              allowConversationSelection={[RolesMap.ADMINISTRATOR, RolesMap.TEACHER].includes(
                userState.profile.role,
              )}
              allPostsNotifications={postsNotifications}
              deleteDialogHandler={deleteDialogHandler}
              editDialogHandler={editDialogHandler}
              post={post}
              studentId={studentId}
            />
          </Grid>
        ))}
        <Grid item xs={12}>
          <Loader isLoading={isLoading} labelText="Loading posts" typographyVariant="body2" />
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <Grid item xs>
      <InitiativeEmptyStateBlock
        body={
          activeFlterCategory === FeedPostCategories.ALL_UPDATES
            ? t(getPostsRolesMap[userState.profile.role].emptyStateBodyText)
            : null
        }
        className="feed-empty-state"
        illustration={<NoFeedPostsIllustration />}
        title={t(
          activeFlterCategory === FeedPostCategories.ALL_UPDATES
            ? 'No posts in the feed so far'
            : 'No posts for this category so far',
        )}
      />
    </Grid>
  );
};

FeedBlock.propTypes = {
  postId: PropTypes.number,
  studentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  editDialogHandler: PropTypes.func,
  deleteDialogHandler: PropTypes.func,
};

FeedBlock.defaultProps = {
  postId: null,
  editDialogHandler: null,
  deleteDialogHandler: null,
};

export default FeedBlock;
