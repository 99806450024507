import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import classNames from 'classnames';

import { InitiativeEmptyStateBlock } from '../../../../moleculas';
import { PortfolioSectionTypes } from '../../../../../constants/enums';
import { ReactComponent as AcademicRecordEmptyState } from '../../../../../resources/images/portfolio/academic_record_empty_state.svg';
import PortfolioSection from '../../components/portfolio-section/PortfolioSection';
import PortfolioAcademicRecordCard from '../../components/portfolio-academic-record-card/PortfolioAcademicRecordCard';

const PortfolioSharedAcademicRecordSection = ({ sectionData }) => {
  const { t } = useTranslation();

  return (
    <PortfolioSection
      body={
        <Grid columnSpacing={3} container mt={3}>
          {sectionData.entities.map(({ type, value }) => (
            <Grid key={type} item sm={3}>
              <PortfolioAcademicRecordCard type={type} value={value} />
            </Grid>
          ))}
        </Grid>
      }
      className={`${classNames('ayo-full-screen-portfolio-section', {
        'ayo-portfolio__academic-record-section': sectionData.entities.length,
      })}`}
      emptyState={
        !sectionData.entities.length ? (
          <InitiativeEmptyStateBlock
            illustration={<AcademicRecordEmptyState />}
            title={t('No information about academic record so far')}
          />
        ) : null
      }
      reflection={sectionData.reflection}
      sectionKey={PortfolioSectionTypes.PORTFOLIO__ACADEMIC_RECORD}
      theme="dark"
      title={t('Academic record')}
    />
  );
};

PortfolioSharedAcademicRecordSection.propTypes = {
  sectionData: PropTypes.instanceOf(Object).isRequired,
};

export default PortfolioSharedAcademicRecordSection;
