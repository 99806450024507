/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery';

import { roadMapBlock } from '../../../constants/propTypes';
import { RichText, TimelineContainer } from '../../moleculas';

const RoadmapBlock = ({ config }) => {
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Container>
      <Grid container direction="column">
        <Grid container item justifyContent="center">
          <Grid item sm={8} xs={12}>
            <Box mb={isWidthUpSm ? 7 : 3}>
              <RichText
                align={config.caption.align}
                className={config.caption.className}
                component={config.caption.component}
                isParagraph={config.caption.isParagraph}
                items={config.caption.textNodes}
                variant={config.caption.variant}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container item justifyContent="center">
          <Grid alignItems="center" container direction="column" item sm={5} xs={12}>
            <RichText
              align={config.titleTop.align}
              className={config.titleTop.className}
              component={config.titleTop.component}
              isParagraph={config.titleTop.isParagraph}
              items={config.titleTop.textNodes}
              variant={config.titleTop.variant}
            />
            <Box>
              <RichText
                align={config.subTitleTop.align}
                className={config.subTitleTop.className}
                component={config.subTitleTop.component}
                isParagraph={config.subTitleTop.isParagraph}
                items={config.subTitleTop.textNodes}
                variant={config.subTitleTop.variant}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container item justifyContent="center">
          <Grid container item sm={7} xs={12}>
            <TimelineContainer config={config.timeline} />
          </Grid>
        </Grid>
        <Grid container item justifyContent="center">
          <Grid alignItems="center" container direction="column" item sm={5} xs={12}>
            <RichText
              align={config.titleBottom.align}
              className={config.titleBottom.className}
              component={config.titleBottom.component}
              isParagraph={config.titleBottom.isParagraph}
              items={config.titleBottom.textNodes}
              variant={config.titleBottom.variant}
            />
            <RichText
              align={config.subTitleBottom.align}
              className={config.subTitleBottom.className}
              component={config.subTitleBottom.component}
              isParagraph={config.subTitleBottom.isParagraph}
              items={config.subTitleBottom.textNodes}
              variant={config.subTitleBottom.variant}
            />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

RoadmapBlock.propTypes = {
  config: roadMapBlock,
};

RoadmapBlock.defaultProps = {
  config: {},
};

export default RoadmapBlock;
