import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { InitiativeEmptyStateBlock } from '../../../../../moleculas';
import { ReactComponent as LessonPageTabEmptyStateImage } from '../../../../../../resources/images/lesson_page_tab_empty_state.svg';

const LessonPageTabEmptyState = ({ isOwner }) => {
  const { t } = useTranslation();

  return (
    <InitiativeEmptyStateBlock
      body={t(
        isOwner
          ? 'Feel free to add content using Editing mode if you need.'
          : 'Once the owner edits this section, the update will be shown here.',
      )}
      illustration={
        <LessonPageTabEmptyStateImage alt={t('A notebook sheet with a table in it.')} />
      }
      title={t(
        isOwner ? 'You haven’t added anything here yet' : "There's nothing added here so far",
      )}
    />
  );
};

LessonPageTabEmptyState.propTypes = {
  isOwner: PropTypes.bool,
};

LessonPageTabEmptyState.defaultProps = {
  isOwner: false,
};

export default LessonPageTabEmptyState;
