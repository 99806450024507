import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Grid, Box, useTheme, useMediaQuery, FormControl, FormLabel } from '@mui/material';

import { Radio, Typography, Button, Checkbox, FormControlLabel } from '../../atoms';
import STTTextField from '../stt-text-field/STTTextField';
import {
  getFormattedDate,
  isAnswersEmpty,
  isOptionActivated,
  handleTemplateString,
} from '../../../utils';
import { ReactComponent as BackArrowIcon } from '../../../resources/icons/go_back.svg';
import { InputsValidationRules, questionOptionTypes } from '../../../constants/enums';
import EditingButton from '../editing-button/EditingButton';

const Question = ({
  answers,
  dependentProfile,
  hasLabel,
  isLast,
  onNext,
  onPrevious,
  previousYearAnswers,
  question,
  setAnswer,
}) => {
  const { i18n, t } = useTranslation();
  const [noAnswersHelperText, setNoAnswersHelperText] = useState('');
  const theme = useTheme();
  const isWidthUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const optionsRef = useRef();

  useEffect(() => {
    if (isAnswersEmpty(answers)) {
      const requiredQuestionText = `${t('This is a required question')}.\n${t(
        'Please choose an appropriate option to proceed',
      )}.`;
      setNoAnswersHelperText(
        question.mandatory ? requiredQuestionText : t('Unsure about the answer? Just click Next'),
      );
    } else {
      setNoAnswersHelperText('');
    }
  }, [answers, question.mandatory, t]);

  useEffect(() => {
    optionsRef.current.querySelector('input, textarea').focus();
  }, [question]);

  const isValid = useCallback(() => {
    let valid = true;
    Object.values(answers).some((answer) =>
      question.options.some((option) => {
        if (option.id === answer.optionId && option.htmlType === questionOptionTypes.TEXT_AREA) {
          valid = answer.value.length <= InputsValidationRules.MAX_TEXT_AREA_LENGTH;
          return true;
        }
        return false;
      }),
    );
    return valid;
  }, [answers, question.options]);

  const setPreviousYearAnswer = useCallback(() => {
    if (question.options.length === 1) {
      setAnswer(null, {
        overrideAnswers: {
          [question.options[0].htmlName]: {
            value: previousYearAnswers.question.answers[0].value,
            optionId: +question.options[0].id,
          },
        },
      });
    } else {
      const foundOptions = question.options.filter((option) =>
        previousYearAnswers.question.answers.some(
          (oldOption) => oldOption.value === option.htmlLabel,
        ),
      );
      setAnswer(null, {
        overrideAnswers: foundOptions.reduce(
          (acc, option) => ({
            ...acc,
            [option.htmlName]: {
              value: option.htmlValue,
              optionId: +option.id,
            },
          }),
          {},
        ),
      });
    }
  }, [previousYearAnswers, question.options, setAnswer]);

  return (
    <Grid
      className={classnames('ayo-question', {
        'ayo-question--nohelper': !isAnswersEmpty(answers),
        'ayo-question--no-label': !hasLabel,
      })}
      container
      item
      xs={12}
    >
      {hasLabel && (
        <Grid item xs={12}>
          <Box className="ayo-question__description" mb={isWidthUpMd ? 5 : 4}>
            <Typography isLabel variant="body2">
              {question.htmlDescription}
            </Typography>
          </Box>
        </Grid>
      )}
      <Grid item xs={12}>
        <FormControl className="ayo-question__fieldset" component="fieldset">
          <FormLabel component="legend">
            <Box alignItems="center" display="flex" flexDirection="row">
              <Typography component="h2" variant="subtitle1">
                {handleTemplateString(question.htmlLabel, dependentProfile)}
              </Typography>
              {question.htmlLabelCaption && (
                <Typography
                  className="ayo-question__fieldset__label"
                  isLabel
                  ml={1}
                  variant="body2"
                >
                  {handleTemplateString(question.htmlLabelCaption, dependentProfile)}
                </Typography>
              )}
            </Box>
          </FormLabel>
          <Box
            ref={optionsRef}
            className="options"
            display="flex"
            flexDirection="column"
            pb={5}
            pt={3}
          >
            {question.options?.map((option) => {
              const isOptionActive = isOptionActivated(option.activatorOptionId, answers);
              const answerValue = answers[option.htmlName]?.value;
              switch (option.htmlType) {
                case questionOptionTypes.TEXT_AREA:
                  return (
                    <STTTextField
                      key={option.id}
                      disabled={!isOptionActive}
                      fullWidth
                      gaLabel="Questionnaire text input"
                      inputProps={{
                        'data-optionid': option.id,
                        name: option.htmlName,
                        'aria-label': handleTemplateString(question.htmlLabel, dependentProfile),
                      }}
                      maxLength={InputsValidationRules.MAX_TEXT_AREA_LENGTH}
                      multiline
                      onChange={setAnswer}
                      placeholder={handleTemplateString(option.htmlPlaceholder, dependentProfile)}
                      rowsCount={option.rowsAmount || 4}
                      value={answerValue || ''}
                    />
                  );
                case questionOptionTypes.RADIO:
                  return (
                    <FormControlLabel
                      key={option.id}
                      control={
                        <Radio
                          checked={answerValue === option.htmlValue}
                          disabled={!isOptionActive}
                          inputProps={{ 'data-optionid': option.id, name: option.htmlName }}
                          onChange={setAnswer}
                          value={option.htmlValue}
                        />
                      }
                      label={option.htmlLabel}
                    />
                  );
                case questionOptionTypes.CHECKBOX:
                  return (
                    <FormControlLabel
                      key={option.id}
                      control={
                        <Checkbox
                          checked={answerValue === option.htmlValue}
                          disabled={!isOptionActive}
                          inputProps={{ 'data-optionid': option.id, name: option.htmlName }}
                          onChange={setAnswer}
                          value={option.htmlValue}
                        />
                      }
                      label={option.htmlLabel}
                    />
                  );
                default:
                  return null;
              }
            })}
          </Box>
        </FormControl>
      </Grid>
      {previousYearAnswers?.question && !previousYearAnswers.question.isSkipped && (
        <Grid item xs={12}>
          <Box className="ayo-question__previous-answer-wrapper">
            <Typography isLabel paragraph variant="body3">
              {t('Your previous answer from date', {
                date: getFormattedDate(previousYearAnswers.startTime, i18n.language, {
                  month: 'short',
                  year: 'numeric',
                }),
              })}
            </Typography>
            {previousYearAnswers.question.answers?.map((answer) => (
              <Typography key={answer.value} variant="quote">
                {answer.value}
              </Typography>
            ))}
          </Box>
          <Box pb={5}>
            {!previousYearAnswers.question.answers?.some((prevAnswer) =>
              Object.values(answers).some(
                (answer) =>
                  answer.value === prevAnswer.value ||
                  question.options.find((x) => x.id === answer.optionId)?.htmlLabel ===
                    prevAnswer.value,
              ),
            ) && (
              <EditingButton
                gaLabel="Apply the same answer for this year"
                onClick={setPreviousYearAnswer}
                text={t('Apply the same answer for this year')}
              />
            )}
          </Box>
        </Grid>
      )}
      <Grid container item xs={12}>
        {onPrevious ? (
          <Grid item>
            <Box mr={2}>
              <Button
                aria-label={t('Go back to the previous question')}
                className="ayo-backbutton"
                gaLabel="Back"
                isIconButton
                onClick={onPrevious}
                variant="secondary"
              >
                <BackArrowIcon />
              </Button>
            </Box>
          </Grid>
        ) : null}
        <Grid className="ayo-question__next" item>
          <Box mb={isWidthUpMd ? 2 : 1}>
            <Button
              disabled={question.mandatory && isAnswersEmpty(answers)}
              gaLabel={isLast && !isAnswersEmpty(answers) ? t('Submit') : t('Next')}
              isAdaptive
              onClick={() => {
                if (isValid()) {
                  onNext();
                }
              }}
              variant="primary"
            >
              {isLast && !isAnswersEmpty(answers) ? t('Submit') : t('Next')}
            </Button>
          </Box>
          <Box className="ayo-question__next__helpertext">
            <Typography align="center" isLabel variant="body3">
              {noAnswersHelperText}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

Question.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number,
    htmlDescription: PropTypes.string,
    htmlLabel: PropTypes.string,
    htmlLabelCaption: PropTypes.string,
    text: PropTypes.string,
    mandatory: PropTypes.bool,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        htmlLabel: PropTypes.string,
        htmlType: PropTypes.oneOf(Object.values(questionOptionTypes)),
        htmlValue: PropTypes.string,
        htmlName: PropTypes.string,
        rowsAmount: PropTypes.number,
        activatorOptionId: PropTypes.number,
      }),
    ),
  }).isRequired,
  answers: PropTypes.instanceOf(PropTypes.instanceOf(Object)).isRequired,
  dependentProfile: PropTypes.instanceOf(Object),
  hasLabel: PropTypes.bool,
  isLast: PropTypes.bool,
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
  setAnswer: PropTypes.func,
  previousYearAnswers: PropTypes.instanceOf(Object),
};

Question.defaultProps = {
  dependentProfile: null,
  hasLabel: true,
  isLast: false,
  onPrevious: null,
  onNext: () => {},
  setAnswer: () => {},
  previousYearAnswers: null,
};

export default Question;
