import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';

import { RolesMap } from '../../constants/enums';

export const AdminGridContext = createContext();

export const AdminGridActions = {
  SET_SELECTED_PAGE: 'SET_SELECTED_PAGE',
  SET_SELECTED_USERS_ROLE: 'SET_SELECTED_USERS_ROLE',
  SET_SEARCH_PARAMETER: 'SET_SEARCH_PARAMETER',
};

const initState = {
  selectedPage: 1,
  selectedUsersRole: RolesMap.STUDENT,
  searchParameter: '',
};

export const reducer = (state, action) => {
  switch (action.type) {
    case AdminGridActions.SET_SELECTED_PAGE:
      return { ...state, selectedPage: action.data };
    case AdminGridActions.SET_SELECTED_USERS_ROLE:
      return { ...state, selectedUsersRole: action.data };
    case AdminGridActions.SET_SEARCH_PARAMETER:
      return { ...state, searchParameter: action.data };
    default:
      throw new Error();
  }
};

export const AdminGridContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initState);

  return (
    <AdminGridContext.Provider value={{ state, dispatch }}>{children}</AdminGridContext.Provider>
  );
};

AdminGridContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
