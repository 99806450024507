import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Image from '../image/Image';
import { apiRoute } from '../../../constants/routes';

const getImageSrc = (id, ownerId) => `${apiRoute}/attachments/${id}/owners/${ownerId}`;

const PostImage = ({ imageInfo, defaultImageUrl, category, postType }) => {
  const { t } = useTranslation();
  const imageSrc = imageInfo
    ? getImageSrc(imageInfo.id, imageInfo.ownerId)
    : defaultImageUrl || null;
  return (
    imageSrc && (
      <Image
        alt={imageInfo?.altText || `${t(`post_image_alt_text.${postType}`)} ${t('post image')}`}
        className={classNames('ayo-post-card__image', `ayo-post-card__image--${category}`)}
        src={imageSrc}
      />
    )
  );
};

PostImage.propTypes = {
  imageInfo: PropTypes.shape({
    id: PropTypes.string.isRequired,
    ownerId: PropTypes.string.isRequired,
    altText: PropTypes.string,
  }),
  category: PropTypes.string,
  defaultImageUrl: PropTypes.string,
  postType: PropTypes.string,
};

PostImage.defaultProps = {
  imageInfo: null,
  category: null,
  defaultImageUrl: null,
  postType: '',
};

export default PostImage;
