/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ContentTypesMap } from '../../../constants/enums';

const PreviewBox = ({ src, tabIndex, type }) => {
  const { t } = useTranslation();

  switch (type) {
    case ContentTypesMap.IMAGE:
      return (
        <img
          alt={t('Image preview')}
          src={src}
          style={{ height: '100%', maxWidth: '90%', width: 'auto' }}
          tabIndex={tabIndex}
        />
      );
    case ContentTypesMap.PDF:
      return (
        <object
          aria-label={t('PDF preview')}
          data={`${src}?contentDisposition=inline&contentType=${encodeURIComponent(
            'application/pdf',
          )}`}
          style={{ height: '100%', width: '90%' }}
          tabIndex={tabIndex}
          type="application/pdf"
        />
      );
    case ContentTypesMap.MP4:
      return (
        <video
          aria-label={t('Video preview')}
          autoPlay={false}
          controls
          preload="none"
          src={`${src}/chunks?contentDisposition=inline&contentType=${encodeURIComponent(
            'video/mp4',
          )}`}
          style={{ height: '100%', maxWidth: '90%', width: 'auto' }}
          tabIndex={tabIndex}
        />
      );
    case ContentTypesMap.MP3:
      return (
        <audio
          aria-label={t('Audio preview')}
          autoPlay={false}
          controls
          preload="none"
          src={`${src}/chunks?contentDisposition=inline&contentType=${encodeURIComponent(
            'audio/mpeg',
          )}`}
          style={{ height: '50%', width: '90%' }}
          tabIndex={tabIndex}
        />
      );
    default:
      return null;
  }
};

PreviewBox.propTypes = {
  src: PropTypes.string.isRequired,
  tabIndex: PropTypes.number,
  type: PropTypes.oneOf([
    ContentTypesMap.IMAGE,
    ContentTypesMap.PDF,
    ContentTypesMap.MP4,
    ContentTypesMap.MP3,
  ]).isRequired,
};

PreviewBox.defaultProps = {
  tabIndex: 0,
};

export default PreviewBox;
