import React, { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme } from '@mui/material';
import PropTypes from 'prop-types';

import { EventsBlock } from '../../../../organisms';
import { EditablePrivilegesByGroupMap, RolesMap } from '../../../../../constants/enums';
import { FamilyFeedFilterCategory } from '../../../../../constants/filter-categories';
import { EventKeys, EventResourceTypes } from '../../../../../constants/events';
import { SharingLevels } from '../../../../../constants/family-feed';
import {
  familyFeedCreationCenterEventsRoute,
  familyFeedEventsRoute,
  familyFeedMyFeedEventsRoute,
} from '../../../../../constants/routes';
import { UserContext } from '../../../../../context';
import { useProfile } from '../../../../../utils';
import { useEvents, useFamilyFeed } from '../../../../../hooks';
import { ReactComponent as ClubsEventsEmptyState } from '../../../../../resources/images/club_events_empty_state_owner.svg';

const EVENTS_PAGE_SIZE_DESKTOP = 5;
const EVENTS_PAGE_SIZE_MOBILE = 2;
const EVENTS_DEFAULT_PAGE = 1;

const AllEventsUrl = {
  [RolesMap.TEACHER]: familyFeedCreationCenterEventsRoute,
  [RolesMap.ADMINISTRATOR]: familyFeedCreationCenterEventsRoute,
  [RolesMap.STUDENT]: familyFeedMyFeedEventsRoute,
  [RolesMap.GUARDIAN]: familyFeedEventsRoute,
};

const FamilyFeedEvents = ({ studentId }) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });

  const { state: userState } = useContext(UserContext);
  const { role } = userState.profile;

  const { getPrivilegeStatus } = useProfile();

  const isEventCreator = useMemo(
    () =>
      !!EditablePrivilegesByGroupMap[FamilyFeedFilterCategory].find((privilege) =>
        getPrivilegeStatus(privilege),
      )?.length,
    [getPrivilegeStatus],
  );

  const { eventPostHandler, eventDeleteHandler, eventUpdateHandler, events, loadEventsData } =
    useEvents(
      EventResourceTypes.FAMILY_FEED,
      null,
      isWidthUpSm ? EVENTS_PAGE_SIZE_DESKTOP : EVENTS_PAGE_SIZE_MOBILE,
      studentId,
    );

  const { getAllowedSharingLevels, getEntitiesByLevel } = useFamilyFeed();

  const sharingLevelOptions = useMemo(
    () =>
      isEventCreator
        ? getAllowedSharingLevels().reduce(
            (obj, key) => ({
              ...obj,
              [key]: {
                [EventKeys.SHARING_LEVEL]: SharingLevels[key],
                [EventKeys.SELECTED_ENTITIES]: getEntitiesByLevel(key),
              },
            }),
            {},
          )
        : null,
    [getAllowedSharingLevels, getEntitiesByLevel, isEventCreator],
  );

  useEffect(() => {
    loadEventsData(EVENTS_DEFAULT_PAGE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <EventsBlock
      allEventUrl={AllEventsUrl[role]}
      emptyStateConfig={{
        title: isEventCreator
          ? t('There are no upcoming events yet. Feel free to create a new one!')
          : t('No upcoming events available yet'),
        illustration: <ClubsEventsEmptyState />,
      }}
      events={events}
      fullLabel={isWidthUpSm || isEventCreator}
      isEditable={isEventCreator}
      onCreate={eventPostHandler}
      onDelete={eventDeleteHandler}
      onUpdate={eventUpdateHandler}
      resourceId={null}
      resourceType={EventResourceTypes.FAMILY_FEED}
      sharingLevelOptions={sharingLevelOptions}
      showSharingLabels
    />
  );
};

FamilyFeedEvents.propTypes = {
  studentId: PropTypes.string,
};

FamilyFeedEvents.defaultProps = {
  studentId: null,
};

export default FamilyFeedEvents;
