import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useMediaQuery, useTheme } from '@mui/material';
import { Rectangle } from 'recharts';

import { Tooltip } from '../../../../atoms';
import { KeyboardMap, SingleBarVariants } from '../../../../../constants/enums';
import { isFilterItemDisabled } from '../../../../../utils';
import drilldownIcon from '../../../../../resources/icons/drill_down.svg';

const DECORATIVE_BAR_PADDING_X = 40;
const DECORATIVE_BAR_PADDING_X_TIGHT = 17;
const DECORATIVE_BAR_PADDING_X_TIGHT_XL = 33;
const DECORATIVE_BAR_PADDING_Y = 60;
const DECORATIVE_BAR_ADDITIONAL_XL_PADDING = 16;
const DRILLDOWN_ICON_SIZE = 24;
const DRILLDOWN_ICON_MARGIN = 4;
const BAR_LABEL_MARGIN = 36;
const SingleBarShape = ({
  onClick,
  getTooltipText,
  variant,
  forcedFill,
  isDotted,
  withDrilldown,
  ...props
}) => {
  const { x, y, height, width, fill, radius, isActive, payload, totalStudentsCount, index } = props;

  const [isHovered, setIsHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const { t } = useTranslation();

  const theme = useTheme();
  const isWidthUpXl = useMediaQuery(theme.breakpoints.up('xl'));

  const isBarActive = useMemo(() => isActive(payload), [isActive, payload]);

  const isBarDisabled = isFilterItemDisabled(payload.value, totalStudentsCount);

  const decorativePaddingX =
    variant === SingleBarVariants.TIGHT
      ? isWidthUpXl
        ? DECORATIVE_BAR_PADDING_X_TIGHT_XL
        : DECORATIVE_BAR_PADDING_X_TIGHT
      : DECORATIVE_BAR_PADDING_X;
  const mainColor = forcedFill || fill;
  return (
    <g>
      {isDotted && (
        <pattern
          height="20"
          id={`pattern-circles-${index}`}
          patternUnits="userSpaceOnUse"
          width="20"
          x={x}
          y={y}
        >
          <circle cx="10" cy="10" fill="#000" id="pattern-circle" r="1.6257413380501518" />
        </pattern>
      )}
      <Tooltip
        enterTouchDelay={0}
        leaveTouchDelay={5000}
        placement="right"
        title={getTooltipText(payload, totalStudentsCount, t)}
      >
        <g
          aria-label={isBarActive ? t('Selected') : null}
          className="main-rect-wrapper"
          onBlur={() => setIsFocused(false)}
          onFocus={() => setIsFocused(true)}
          onKeyDown={(e) => {
            if (e.key === KeyboardMap.ENTER || e.key === KeyboardMap.SPACE) {
              e.preventDefault();
              onClick(payload);
            }
          }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          role="button"
          tabIndex={isBarDisabled ? null : 0}
        >
          <Rectangle
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            className="main-rect"
            fill={mainColor}
          />
          {withDrilldown && (
            <image
              height={DRILLDOWN_ICON_SIZE}
              href={drilldownIcon}
              width={DRILLDOWN_ICON_SIZE}
              x={x + width + DRILLDOWN_ICON_MARGIN}
              y={payload.value ? y + height / 2 - DRILLDOWN_ICON_SIZE / 2 : y - BAR_LABEL_MARGIN}
            />
          )}
          {isDotted && (
            <Rectangle
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
              fill={`url(#pattern-circles-${index})`}
            />
          )}
        </g>
      </Tooltip>
      {height && (
        <Rectangle
          className={classnames('decorative-rect', {
            active: isBarActive,
            hover: isHovered,
            focus: isFocused,
          })}
          fill="none"
          height={
            height +
            DECORATIVE_BAR_PADDING_Y * 2 +
            (isWidthUpXl ? DECORATIVE_BAR_ADDITIONAL_XL_PADDING : 0)
          }
          radius={radius}
          stroke={mainColor}
          strokeWidth={2}
          width={width + decorativePaddingX * 2}
          x={x - decorativePaddingX}
          y={y - DECORATIVE_BAR_PADDING_Y}
        />
      )}
    </g>
  );
};

SingleBarShape.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  fill: PropTypes.string.isRequired,
  radius: PropTypes.arrayOf(PropTypes.number).isRequired,
  isActive: PropTypes.func.isRequired,
  payload: PropTypes.instanceOf(Object).isRequired,
  totalStudentsCount: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  getTooltipText: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(Object.values(SingleBarVariants)),
  forcedFill: PropTypes.string,
  isDotted: PropTypes.bool,
  withDrilldown: PropTypes.bool,
  index: PropTypes.number.isRequired,
};

SingleBarShape.defaultProps = {
  variant: SingleBarVariants.DEFAULT,
  forcedFill: null,
  isDotted: false,
  withDrilldown: false,
};

export default SingleBarShape;
