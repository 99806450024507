import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Dialog, DialogTitle, Typography } from '../../atoms';
import LeadershipAttributesGraph from '../leadership-attributes-graph/LeadershipAttributesGraph';
import { LeadershipAttributesActions, LeadershipAttributesContext } from '../../../context';

const LeadershipAttributesGraphDialog = () => {
  const { t } = useTranslation();
  const { state: leadershipAttributesState, dispatch: dispatchLeadershipAttributes } = useContext(
    LeadershipAttributesContext,
  );
  const isOpen = leadershipAttributesState.isDialogOpen;
  const onClose = () => {
    dispatchLeadershipAttributes({
      type: LeadershipAttributesActions.SET_DIALOG_OPEN_STATE,
      data: false,
    });
  };

  return (
    <Dialog gaLabel="Maturity graph" onClose={onClose} open={isOpen}>
      <DialogTitle disableTypography>
        <Typography component="h2" paragraph variant="subtitle1">
          {t('Maturity levels')}
        </Typography>
      </DialogTitle>
      <LeadershipAttributesGraph withSnackbar />
    </Dialog>
  );
};

export default LeadershipAttributesGraphDialog;
