import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Box, Divider } from '@mui/material';

import { ActionsMenu, Card, Typography } from '../../../../../../../../../atoms';
import { EditingButton } from '../../../../../../../../../moleculas';
import { ClickableAuthorWithPopover, ConfirmDialog } from '../../../../../../../../../organisms';
import { ReactComponent as ExpandIcon } from '../../../../../../../../../../resources/icons/chevron_down.svg';
import { ReactComponent as CollapseIcon } from '../../../../../../../../../../resources/icons/chevron_up.svg';
import { ReactComponent as DeleteIcon } from '../../../../../../../../../../resources/icons/delete_primary.svg';
import { ReactComponent as CreateIcon } from '../../../../../../../../../../resources/icons/create.svg';
import { UserContext } from '../../../../../../../../../../context';
import { ActionButtonsIdsMap, RolesMap } from '../../../../../../../../../../constants/enums';
import { getFullDateAndHoursString } from '../../../../../../../../../../utils';
import { useSnackbar } from '../../../../../../../../../../hooks';
import { useClubHubService } from '../../../../../../../../../../services';
import EditPostReplyDialog from '../edit-post-reply-dialog/EditPostReplyDialog';

export const PostReplyActions = {
  EDIT: 'edit',
  DELETE: 'delete',
};

const PostReplyCard = ({
  availableActions,
  reply,
  postId,
  onDeleteHandler,
  onEditHandler,
  discardButtonId,
  clubId,
}) => {
  const { t, i18n } = useTranslation();
  const replyBodyRef = useRef(null);
  const { setSnackBarStatus } = useSnackbar();
  const { deletePostReply } = useClubHubService();
  const { state: userState } = useContext(UserContext);
  const [isCommentCollapsed, setIsCommentCollapsed] = useState(true);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isTextCropped, setIsTextCropped] = useState(false);

  const isStudent = userState.profile.role === RolesMap.STUDENT;
  const isReplyOwner = reply.author.id === userState.profile.id;

  const onDeleteReply = useCallback(() => {
    deletePostReply(postId, clubId, reply.commentId)
      .then(() => {
        onDeleteHandler(postId, reply.commentId);
        setSnackBarStatus({
          text: t(
            isReplyOwner
              ? isStudent
                ? 'Your reply has been deleted(student)'
                : 'Your reply has been deleted.'
              : 'The reply has been deleted.',
          ),
          type: 'delete',
        });
      })
      .catch(() => {
        setSnackBarStatus({
          text: t(
            isReplyOwner
              ? isStudent
                ? 'AYO couldn’t delete your reply. Please try once more(student)'
                : 'AYO couldn’t delete your reply. Please try once more'
              : 'AYO couldn’t delete the reply. Please try once more.',
          ),
          type: 'error',
        });
      });
    setIsDeleteDialogOpen(false);
  }, [
    postId,
    reply,
    onDeleteHandler,
    setSnackBarStatus,
    t,
    clubId,
    deletePostReply,
    isStudent,
    isReplyOwner,
  ]);

  useEffect(() => {
    setIsTextCropped(
      replyBodyRef.current?.parentElement.scrollHeight >
        replyBodyRef.current?.parentElement.clientHeight,
    );
  }, [reply]);

  const defaultMenuItems = useMemo(
    () => [
      {
        text: t('Edit'),
        icon: CreateIcon,
        handler: () => {
          setIsCommentCollapsed(true);
          setIsEditDialogOpen(true);
        },
        id: `${ActionButtonsIdsMap.EDIT}-${reply.commentId}`,
        gaLabel: 'Edit reply',
        value: PostReplyActions.EDIT,
      },
      {
        text: t('Delete'),
        icon: DeleteIcon,
        handler: () => setIsDeleteDialogOpen(true),
        id: `${ActionButtonsIdsMap.DELETE}-${reply.commentId}`,
        gaLabel: 'Delete reply',
        value: PostReplyActions.DELETE,
      },
    ],
    [reply, t],
  );

  const menuItemsConfig = useMemo(
    () =>
      defaultMenuItems.filter(
        (buttonConfig) =>
          buttonConfig.id.split('-')[0] !== discardButtonId &&
          availableActions.includes(buttonConfig.value),
      ),
    [discardButtonId, defaultMenuItems, availableActions],
  );

  return (
    <>
      <Card
        className="ayo-comment-card"
        id={reply.commentId}
        mainContent={
          <Box display="flex" flexDirection="row" position="relative">
            <Box width="100%">
              <Box display="flex" justifyContent="space-between">
                <Box alignItems="center" display="flex" minHeight="40px">
                  <ClickableAuthorWithPopover author={reply.author} />
                  <Divider className="ayo-comment-card__divider" flexItem orientation="vertical" />
                  <Typography isLabel variant="body3">
                    {t(reply.author.position)}
                  </Typography>
                </Box>
                {!!menuItemsConfig.length && (
                  <Box id={`post_reply_${reply.commentId}`} ml={1}>
                    <ActionsMenu
                      className="ayo-comment-card__action-buttons"
                      id="Reply_actions"
                      menuItems={menuItemsConfig}
                    />
                  </Box>
                )}
              </Box>
              <Box alignItems="center" display="flex" minHeight={24}>
                {reply.edited && (
                  <Typography isLabel mr={2} variant="body3">
                    {t('Edited')}
                  </Typography>
                )}
                <Typography isLabel variant="body3">
                  {getFullDateAndHoursString(reply.createdDate, i18n.language)}
                </Typography>
              </Box>
              <Box my={2}>
                <Divider flexItem />
              </Box>
              <Box
                className={classnames('ayo-comment-card__body', {
                  'ayo-comment-card__body--collapsed': isCommentCollapsed,
                })}
                mb={1}
                mt={1}
              >
                <Typography variant="body2">
                  <span ref={replyBodyRef}>{reply.comment}</span>
                </Typography>
              </Box>
              {isTextCropped &&
                (isCommentCollapsed ? (
                  <EditingButton
                    gaLabel="Read more"
                    icon={<ExpandIcon />}
                    iconPosition="end"
                    onClick={() => {
                      setIsCommentCollapsed(false);
                    }}
                    text={t('Read more')}
                  />
                ) : (
                  <EditingButton
                    gaLabel="Read less"
                    icon={<CollapseIcon />}
                    iconPosition="end"
                    onClick={() => {
                      setIsCommentCollapsed(true);
                    }}
                    text={t('Read less')}
                  />
                ))}
            </Box>
          </Box>
        }
      />
      <ConfirmDialog
        cancelButtonTitle="Don't delete"
        className="ayo-comment-card__delete-dialog"
        confirmButtonTitle="Delete reply"
        isOpen={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        onConfirm={onDeleteReply}
        text={
          isReplyOwner
            ? 'AYO won’t be able to restore it again(educator)'
            : 'Delete user reply text'
        }
        textTranslationArgs={{ userName: reply.author.name }}
        title={
          isReplyOwner
            ? isStudent
              ? 'Should we delete your reply(student)'
              : 'Should we delete your reply?'
            : 'Delete user reply'
        }
        titleTranslationArgs={{ userName: reply.author.name }}
      />
      <EditPostReplyDialog
        clubId={clubId}
        editCommentHandler={onEditHandler}
        isOpen={isEditDialogOpen}
        onClose={() => setIsEditDialogOpen(false)}
        postId={postId}
        reply={reply}
      />
    </>
  );
};

PostReplyCard.propTypes = {
  availableActions: PropTypes.arrayOf(PropTypes.oneOf(Object.values(PostReplyActions))),
  reply: PropTypes.shape({
    commentId: PropTypes.number,
    author: {
      id: PropTypes.number,
      name: PropTypes.string,
      role: PropTypes.string,
    },
    createdDate: PropTypes.string,
    edited: PropTypes.bool,
    comment: PropTypes.string,
  }).isRequired,
  postId: PropTypes.number.isRequired,
  onDeleteHandler: PropTypes.func,
  onEditHandler: PropTypes.func,
  discardButtonId: PropTypes.string,
  clubId: PropTypes.number.isRequired,
};

PostReplyCard.defaultProps = {
  availableActions: [],
  onDeleteHandler: () => {},
  onEditHandler: () => {},
  discardButtonId: null,
};

export default PostReplyCard;
