import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Container, Grid, useMediaQuery, useTheme } from '@mui/material';

import { Typography } from '../../../atoms';
import { NewFeatureHotspot, PageEndScrollToTopButton } from '../../../moleculas';
import { ConfirmDialog, PageWrapper } from '../../../organisms';
import { FamilyFeedContext, UserContext } from '../../../../context';
import { PrivilegesMap } from '../../../../constants/enums';
import useFamilyFeed from '../../../../hooks/use-family-feed/useFamilyFeed';
import CreatePostForm from '../components/create-post-form/CreatePostForm';
import EditPostDialog from '../components/edit-post-dialog/EditPostDialog';
import { NewFeaturesIdsMap } from '../../../../tours/common/NewFeaturesItemsProvider';
import PostsFilter from '../components/posts-filter/PostsFilter';
import FeedBlock from '../components/feed-block/FeedBlock';
import FamilyFeedEvents from '../components/family-feed-events/FamilyFeedEvents';

const FamilyFeedCreationCenterPage = () => {
  const { t } = useTranslation();

  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  const { state: userState } = useContext(UserContext);
  const { state: familyFeedState } = useContext(FamilyFeedContext);
  const { posts, classes, students, postFilterCategories } = familyFeedState;

  const { getClasses, getStudents, deletePost } = useFamilyFeed();
  const [editingPost, setEditingPost] = useState(null);
  const [deletingPost, setDeletingPost] = useState(null);

  useEffect(() => {
    if (!classes && userState.profile.privileges?.includes(PrivilegesMap.FAMILY_FEED_CREATOR_CLASS))
      getClasses();
  }, [classes, getClasses, userState.profile.privileges]);

  useEffect(() => {
    if (
      !students &&
      userState.profile.privileges?.includes(PrivilegesMap.FAMILY_FEED_CREATOR_PERSONALIZED)
    )
      getStudents();
  }, [students, getStudents, userState.profile.privileges]);

  const onPostDeleteHandler = () => {
    deletePost(deletingPost);
    setDeletingPost(null);
  };

  const editDialogHandler = useCallback(
    (postId) => {
      const postToEdit = posts.find((post) => post.id === postId);
      setEditingPost(postToEdit);
    },
    [posts],
  );

  const deleteDialogHandler = useCallback(
    (postId) => {
      const postToDelete = posts.find((post) => post.id === postId);
      setDeletingPost(postToDelete);
    },
    [posts],
  );

  return (
    <PageWrapper>
      <Container>
        <Grid container mb={8}>
          <Grid alignItems="center" container flexDirection="row" item mb={5} xs={12}>
            <Typography component="h1" variant="h1">
              {t('Family feed')}
            </Typography>
            <NewFeatureHotspot
              id={NewFeaturesIdsMap.POST_MESSAGES}
              isClickable
              label={t('Messages')}
            />
          </Grid>
          <Grid item lg={8} mb={8} md={10} xs={12}>
            <Box alignItems="center" display="flex">
              <Typography variant="h2">{t('Add a new post')}</Typography>
              <NewFeatureHotspot
                id={NewFeaturesIdsMap.POST_MULTIPLE_IMAGES}
                isClickable
                label={t('nft.post_multiple_images.title')}
              />
            </Box>
            <CreatePostForm />
          </Grid>
          <Grid
            container
            flexDirection={isWidthUpSm ? 'row' : 'column-reverse'}
            item
            spacing={isWidthUpSm ? 3 : 4}
          >
            <Grid container flexDirection="column" item md={8} sm={7}>
              <Box alignItems="center" display="flex" mb={3}>
                <Typography variant="h2">{t('Posts')}</Typography>
                <NewFeatureHotspot
                  id={NewFeaturesIdsMap.STAFF_CONVERSATIONS}
                  isClickable
                  label={t('nft.staff_conversations.title')}
                />
              </Box>
              {!!postFilterCategories?.length && (
                <Box mb={4}>
                  <PostsFilter />
                </Box>
              )}
              <FeedBlock
                deleteDialogHandler={deleteDialogHandler}
                editDialogHandler={editDialogHandler}
              />
            </Grid>
            <Grid item md={4} sm={5}>
              <Box alignItems="center" display="flex" mb={3}>
                <Typography variant="h2">{t('Upcoming events')}</Typography>
                <NewFeatureHotspot
                  id={NewFeaturesIdsMap.FEED_EVENTS}
                  isClickable
                  label={t('nft.feed_events.title.creator')}
                />
              </Box>
              <FamilyFeedEvents />
            </Grid>
          </Grid>
        </Grid>
        <EditPostDialog editingPost={editingPost} onClose={() => setEditingPost(null)} />
        <ConfirmDialog
          cancelButtonTitle="Cancel"
          confirmButtonTitle="Delete post"
          isOpen={!!deletingPost}
          onClose={() => setDeletingPost(null)}
          onConfirm={onPostDeleteHandler}
          text="Family feed delete post text"
          title="Should we delete your post?"
        />
      </Container>
      <PageEndScrollToTopButton />
    </PageWrapper>
  );
};

export default FamilyFeedCreationCenterPage;
