import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';

import { Typography, Card } from '../../atoms';
import { EditingButton } from '../../moleculas';
import { useStudentsService } from '../../../services';
import { GA, getNormalizedLanguageCode } from '../../../utils';
import { MoodNames } from '../../../constants/moods';
import { GaActions, GaCategories } from '../../../constants/enums';
import { ReactComponent as NavigationBackArrow } from '../../../resources/icons/chevron_right.svg';
import { ReactComponent as ExpandIcon } from '../../../resources/icons/chevron_down.svg';
import ActivityDescriptionDialog from '../activity-description-dialog/ActivityDescriptionDialog';
import MoodActivityIllustration from '../mood-activity-illustration/MoodActivityIllustration';

const DEFAULT_ACTIVITIES_TO_SHOW_LENGTH = 4;

const MoodActivitiesBlock = ({
  activitiesCards,
  cardGridTargetSize,
  cardHeadingLevel,
  gaLabel,
  isCurrentMoodActivities,
  onCardClick,
  withCroppedActivities,
}) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const { getActivityDetails } = useStudentsService();
  const [activityToShow, setActivityToShow] = useState();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [activitiesToShowLength, setActivitiesToShowLength] = useState(
    DEFAULT_ACTIVITIES_TO_SHOW_LENGTH,
  );
  const activitiesContainerRef = useRef();

  useEffect(() => {
    setActivitiesToShowLength(DEFAULT_ACTIVITIES_TO_SHOW_LENGTH);
  }, [activitiesCards]);

  const onCardClickHandler = useCallback(
    (id) => {
      getActivityDetails(id, getNormalizedLanguageCode(i18n.language)).then((activity) => {
        setActivityToShow(activity);
        if (onCardClick) {
          onCardClick(id, activity);
        }
        GA.logInteraction({
          category: GaCategories.BEHAVIOR,
          action: GaActions.CARD_CLICK,
          label: gaLabel,
        });
        setIsDialogOpen(true);
      });
    },
    [getActivityDetails, i18n.language, onCardClick, gaLabel],
  );

  const onCloseDialogHandler = useCallback(() => {
    setIsDialogOpen(false);
    setActivityToShow(null);
  }, []);

  return (
    <Box>
      <Box>
        <Box>
          {Object.entries(activitiesCards).map(([moodName, recommendations]) => (
            <Box key={moodName} mb={isCurrentMoodActivities ? 0 : isWidthUpSm ? 10 : 5}>
              {!isCurrentMoodActivities && (
                <Typography component="h3" paragraph variant="subtitle1">
                  {t(MoodNames[moodName])}
                </Typography>
              )}
              <Grid ref={activitiesContainerRef} container spacing={3}>
                {recommendations
                  .slice(0, withCroppedActivities ? activitiesToShowLength : recommendations.length)
                  .map((recommendation, idx) => (
                    <Grid
                      key={`${recommendation.id}-${idx}`}
                      item
                      md={cardGridTargetSize}
                      sm={6}
                      xs={6}
                    >
                      <Card
                        className="ayo-clickable-card ayo-moods-page__card"
                        component="button"
                        mainContent={
                          <>
                            <Typography component={`h${cardHeadingLevel}`} variant="subtitle2">
                              {recommendation.name || recommendation.title}
                            </Typography>
                            <MoodActivityIllustration
                              activity={recommendation}
                              isImageType="true"
                            />
                            <Box alignItems="center" display="flex">
                              <Typography
                                className="ayo-moods-page__read-text-block__text"
                                variant="body2"
                              >
                                {t('View')}
                              </Typography>
                              <NavigationBackArrow />
                            </Box>
                          </>
                        }
                        onClick={() => onCardClickHandler(recommendation.id)}
                      />
                    </Grid>
                  ))}
              </Grid>
              {withCroppedActivities &&
                activitiesToShowLength < activitiesCards.currentMoodActivities.length && (
                  <Box pl={1} pt={3}>
                    <EditingButton
                      gaLabel="Show all"
                      icon={<ExpandIcon />}
                      iconPosition="end"
                      onClick={() => {
                        setTimeout(() => {
                          activitiesContainerRef.current.children[
                            DEFAULT_ACTIVITIES_TO_SHOW_LENGTH
                          ].children[0].focus();
                        }, 0);
                        setActivitiesToShowLength(activitiesCards.currentMoodActivities.length);
                      }}
                      text={t('Show all')}
                    />
                  </Box>
                )}
            </Box>
          ))}
        </Box>
      </Box>
      <ActivityDescriptionDialog
        activity={activityToShow}
        isOpen={isDialogOpen}
        onClose={onCloseDialogHandler}
      />
    </Box>
  );
};

MoodActivitiesBlock.propTypes = {
  activitiesCards: PropTypes.instanceOf(Object).isRequired,
  cardHeadingLevel: PropTypes.number,
  cardGridTargetSize: PropTypes.number,
  isCurrentMoodActivities: PropTypes.bool,
  withCroppedActivities: PropTypes.bool,
  onCardClick: PropTypes.func,
  gaLabel: PropTypes.string,
};

MoodActivitiesBlock.defaultProps = {
  isCurrentMoodActivities: false,
  withCroppedActivities: false,
  cardHeadingLevel: 5,
  cardGridTargetSize: 2,
  onCardClick: null,
  gaLabel: 'Train AYO mood recommendation',
};

export default MoodActivitiesBlock;
