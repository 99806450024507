import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

import { FormControlLabel, Radio } from '../../../atoms';
import {
  updatePendingFilter,
  updateIncludeFilter,
  StudentFiltersStrategy,
} from '../../../../utils';
import {
  HighlightsInitiatives,
  StudentFiltersSpecialValues,
  StudentFiltersTypes,
} from '../../../../constants/enums';

const OpportunitiesFilters = ({ menuFilters, setMenuFilters, stats }) => {
  const onOpportunitiesRadioButtonChange = useCallback(
    (e) => {
      const { value } = e.target;
      const newFilters =
        value === StudentFiltersTypes.INCLUDE
          ? updateIncludeFilter(menuFilters, HighlightsInitiatives.OPPORTUNITIES, stats)
          : updatePendingFilter(menuFilters, HighlightsInitiatives.OPPORTUNITIES, stats);
      setMenuFilters(newFilters);
    },
    [stats, menuFilters, setMenuFilters],
  );

  const { t } = useTranslation();

  const OpportunitiesFiltersStrategy = StudentFiltersStrategy[HighlightsInitiatives.OPPORTUNITIES];

  return (
    <Box>
      <Box className="filters-controls-box" display="flex" flexDirection="column">
        <Box mr={1}>
          <FormControlLabel
            control={
              <Radio
                checked={
                  menuFilters.filterCategory === HighlightsInitiatives.OPPORTUNITIES &&
                  menuFilters.filterValue?.includes(StudentFiltersTypes.INCLUDE)
                }
                gaLabel="Student Filters - Opportunities - Developing"
                name="opportunityType"
                onChange={onOpportunitiesRadioButtonChange}
                value={StudentFiltersTypes.INCLUDE}
              />
            }
            label={`${t(
              `analytics.highlights.${StudentFiltersSpecialValues.INCLUDE}`,
            )} (${OpportunitiesFiltersStrategy.getStatValue(
              stats,
              StudentFiltersSpecialValues.INCLUDE,
            )})`}
          />
        </Box>
        <Box mr={1}>
          <FormControlLabel
            control={
              <Radio
                checked={
                  menuFilters.filterCategory === HighlightsInitiatives.OPPORTUNITIES &&
                  menuFilters.filterValue?.includes(StudentFiltersTypes.PENDING)
                }
                gaLabel="Student Filters - Opportunities - Pending"
                name="opportunityType"
                onChange={onOpportunitiesRadioButtonChange}
                value={StudentFiltersTypes.PENDING}
              />
            }
            label={`${t(
              `analytics.highlights.${StudentFiltersSpecialValues.PENDING}`,
            )} (${OpportunitiesFiltersStrategy.getStatValue(
              stats,
              StudentFiltersSpecialValues.PENDING,
            )})`}
          />
        </Box>
      </Box>
    </Box>
  );
};

OpportunitiesFilters.propTypes = {
  menuFilters: PropTypes.instanceOf(Object).isRequired,
  setMenuFilters: PropTypes.func.isRequired,
  stats: PropTypes.instanceOf(Object).isRequired,
};

export default OpportunitiesFilters;
