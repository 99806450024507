import React, { useCallback, useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Typography, SkeletonMask } from '../../atoms';
import {
  InitiativeEmptyStateBlock,
  LearnMoreProfileBlock,
  NewFeatureHotspot,
} from '../../moleculas';
import { useStudentsService } from '../../../services';
import { useLazyDataLoad } from '../../../hooks';
import { aptitudesRoute } from '../../../constants/routes';
import { ReactComponent as EmptyStateIllustration } from '../../../resources/images/aptitudes_empty_state.svg';
import { NewFeaturesIdsMap } from '../../../tours/common/NewFeaturesItemsProvider';
import { ReactComponent as ChevronIcon } from '../../../resources/icons/chevron_left.svg';

const AptitudesEmptyState = ({ title, body }) => {
  const { t } = useTranslation();
  return (
    <InitiativeEmptyStateBlock
      body={body}
      illustration={<EmptyStateIllustration />}
      secondaryButtonContent={
        <>
          {t('Learn more')}
          <Box display="flex" ml={1}>
            <ChevronIcon className="reversed" />
          </Box>
        </>
      }
      secondaryButtonGaLabel="Learn more about Aptitudes"
      secondaryButtonLink={aptitudesRoute}
      title={title}
    />
  );
};

AptitudesEmptyState.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
};

AptitudesEmptyState.defaultProps = {
  title: null,
  body: null,
};

const AptitudesSection = ({
  headerLevel,
  titleVariant,
  title,
  CardComponent,
  studentId,
  isLazyLoad,
  emptyTitle,
  emptyBody,
}) => {
  const [aptitudes, setAptitudes] = useState(null);

  const { getStudentAptitudes } = useStudentsService();

  const loadAptitudes = useCallback(
    () =>
      getStudentAptitudes(studentId, isLazyLoad).then((aptitudesData) =>
        setAptitudes(aptitudesData),
      ),
    [getStudentAptitudes, isLazyLoad, studentId],
  );

  const { isDataLoaded, loadActivatorRef } = useLazyDataLoad(async () => loadAptitudes());

  useEffect(() => {
    if (!isLazyLoad) {
      loadAptitudes();
    }
  }, [isLazyLoad, loadAptitudes]);

  const { t } = useTranslation();

  return (
    <Box>
      <Grid container>
        <Grid item xs={12}>
          <Box
            ref={isLazyLoad ? loadActivatorRef : null}
            display="flex"
            mb={aptitudes?.length ? 1 : 3}
            width="100%"
          >
            <Typography component={`h${headerLevel}`} variant={titleVariant}>
              {t(title)}
            </Typography>
            <NewFeatureHotspot
              id={NewFeaturesIdsMap.APTITUDES}
              isClickable
              label={t('Aptitudes')}
            />
          </Box>
        </Grid>
        {aptitudes?.length ? (
          <Box width="100%">
            <Box mb={3}>
              <LearnMoreProfileBlock
                gaLabel="Learn more about Aptitudes"
                redirectRoute={aptitudesRoute}
              />
            </Box>
            <Grid container spacing={3}>
              {aptitudes.map((aptitude) => (
                <Grid key={aptitude.type} item sm={4} xs={12}>
                  <CardComponent aptitude={aptitude.type} score={aptitude.score} />
                </Grid>
              ))}
            </Grid>
          </Box>
        ) : isLazyLoad && !isDataLoaded ? (
          <SkeletonMask />
        ) : (
          <Grid item xs={12}>
            <AptitudesEmptyState body={emptyBody} title={emptyTitle} />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

AptitudesSection.propTypes = {
  headerLevel: PropTypes.number,
  titleVariant: PropTypes.string,
  title: PropTypes.string,
  CardComponent: PropTypes.elementType.isRequired,
  studentId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  isLazyLoad: PropTypes.bool,
  emptyTitle: PropTypes.string,
  emptyBody: PropTypes.string,
};

AptitudesSection.defaultProps = {
  headerLevel: 2,
  titleVariant: 'h2',
  title: 'Aptitudes',
  isLazyLoad: false,
  emptyBody: null,
  emptyTitle: null,
};

export default AptitudesSection;
