import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactGA4 from 'react-ga4';
import { Route } from 'react-router-dom';

import { AppRoutes } from '../../constants/routes';
import { checkEnvironmentVariable } from '../app-helpers/appHelpers';

class GoogleAnalytics extends Component {
  static getPageNameFromPath(pathname) {
    const pageName = Object.keys(AppRoutes).find((key) => AppRoutes[key] === pathname);
    return pageName || pathname;
  }

  componentDidMount() {
    const { location } = this.props;
    this.startTime = Date.now();
    this.logPageChange(location.pathname);
  }

  componentDidUpdate({ location: prevLocation }) {
    const { location } = this.props;
    const isDifferentPath = location.pathname !== prevLocation.pathname;

    if (isDifferentPath) {
      this.logTiming(prevLocation.pathname);
      this.logPageChange(location.pathname);
    }
  }

  logPageChange(pathname) {
    const { location } = window;
    const { options } = this.props;

    ReactGA4.set({
      pathname,
      location: `${location.origin}${pathname}`,
      ...options,
    });
    ReactGA4.pageview(pathname, [], GoogleAnalytics.getPageNameFromPath(pathname));
  }

  logTiming(pathname) {
    const currentTime = Date.now();
    ReactGA4.ga(
      'send',
      'timing',
      [],
      [],
      currentTime - this.startTime,
      GoogleAnalytics.getPageNameFromPath(pathname),
    );
    this.startTime = currentTime;
  }

  render() {
    return null;
  }
}

GoogleAnalytics.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  options: PropTypes.instanceOf(Object),
};

GoogleAnalytics.defaultProps = {
  options: {},
};

const RouteTracker = () => <Route component={GoogleAnalytics} />;

const isGAEnabled =
  !!process.env.REACT_APP_GA_TRACKING_ID && checkEnvironmentVariable('NODE_ENV', 'production');

const init = (options = {}) => {
  if (isGAEnabled) {
    ReactGA4.initialize(process.env.REACT_APP_GA_TRACKING_ID, {
      ...options,
    });
    ReactGA4.set({ anonymizeIp: true });
  }

  return isGAEnabled;
};

const logInteraction = (options) => {
  if (isGAEnabled) {
    const newOptions = options;
    const { location } = window;
    newOptions.label = `${newOptions.label} - ${GoogleAnalytics.getPageNameFromPath(
      location.pathname,
    )}`;
    ReactGA4.event(newOptions);
  }
};

const logTimeElapsed = (startTime, options) => {
  if (isGAEnabled) {
    const newOptions = options;
    newOptions.value = Date.now() - startTime;
    ReactGA4.ga('send', 'timing', [], [], newOptions.value, newOptions.label);
  }
};

export const setUserProperties = (userProperties) =>
  ReactGA4.gtag('set', 'user_properties', userProperties);

export default {
  GoogleAnalytics,
  RouteTracker,
  init,
  logInteraction,
  logTimeElapsed,
};
