import React, { useState } from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Typography } from '../../../../atoms';
import { InformationalCaption } from '../../../../moleculas';
import { ConfirmDialog } from '../../../../organisms';
import { ReactComponent as GoogleClassromIcon } from '../../../../../resources/icons/google_classrom.svg';
import AssignmentsActionButtons from '../assignments-action-buttons/AssignmentsActionButtons';
import GoogleClassroomLink from '../display-components/google-classroom-link/GoogleClassroomLink';
import AssignmentTypeBlock from '../assignment-type-block/AssignmentTypeBlock';
import AssignmentGroupnamesList from '../assignment-groupnames-list/AssignmentGroupnamesList';
import { assignmentInsightGroupnameType } from '../../../../../constants/propTypes';

const AssignmentContainer = ({
  children,
  title,
  description,
  onDelete,
  onReorder,
  currentOrder,
  isFirst,
  isLast,
  gcLink,
  isPublished,
  groupCategory,
  groupNames,
}) => {
  const { t } = useTranslation();
  const [isDeleteStarted, setIsDeleteStarted] = useState(false);
  return (
    <Box className="assignment">
      <Box mb={5}>
        <Box alignItems="center" alignmItems="center" display="flex" justifyContent="space-between">
          <Box alignItems="center" display="flex" gap={2}>
            <GoogleClassromIcon />
            <Typography variant="h2">{title}</Typography>
            <InformationalCaption showTooltip title={description} />
          </Box>
          <Box>
            <AssignmentsActionButtons
              deleteOptionHandler={() => setIsDeleteStarted(true)}
              downButtonHandler={() => onReorder(currentOrder + 1)}
              isFirst={isFirst}
              isLast={isLast}
              upButtonHandler={() => onReorder(currentOrder - 1)}
            />
          </Box>
        </Box>
        {isPublished && <GoogleClassroomLink href={gcLink} />}
        {groupCategory && (
          <Box mt={5}>
            <AssignmentTypeBlock
              content={
                <Box>
                  <AssignmentGroupnamesList categoryName={groupCategory} groupNames={groupNames} />
                </Box>
              }
              type={groupCategory}
              withMLChip
            />
          </Box>
        )}
      </Box>
      {children}
      <ConfirmDialog
        cancelButtonTitle="Cancel"
        confirmButtonTitle="Delete assignment"
        content={
          isPublished && (
            <Box>
              <Box mb={3}>
                <Typography variant="body2">{t('Synchronized assignment delete info')}</Typography>
              </Box>
              <Box mb={1.5}>
                <Typography variant="subtitle2">
                  {t('You can use the link to delete it from Google Classroom as well.')}
                </Typography>
              </Box>
              <GoogleClassroomLink href={gcLink} />
            </Box>
          )
        }
        isOpen={isDeleteStarted}
        onClose={() => setIsDeleteStarted(false)}
        onConfirm={onDelete}
        text={isPublished ? null : 'You have already added some content here'}
        title="Are you sure you want to delete the assignment?"
        variant="delete"
      />
    </Box>
  );
};

AssignmentContainer.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  onReorder: PropTypes.func.isRequired,
  currentOrder: PropTypes.number.isRequired,
  isFirst: PropTypes.bool.isRequired,
  isLast: PropTypes.bool.isRequired,
  gcLink: PropTypes.string,
  isPublished: PropTypes.bool,
  groupCategory: PropTypes.string,
  groupNames: PropTypes.arrayOf(PropTypes.shape(assignmentInsightGroupnameType)),
};

AssignmentContainer.defaultProps = {
  gcLink: null,
  isPublished: false,
  groupCategory: null,
  groupNames: null,
};

export default AssignmentContainer;
