import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Grid } from '@mui/material';
import dayjs from 'dayjs';

import { DayOffTeacher, CalendarDateDisplay, Typography } from '../../atoms';
import { classPropType } from '../../../constants/propTypes';
import CalendarDayOptions from '../calendar-day-options/CalendarDayOptions';
import CalendarClassCard from '../calendar-class-card/CalendarClassCard';

const CalendarDay = ({ date, day, scheduleId, nullDataComponent, onDateClick, withDivider }) => {
  if (day === undefined) return null;

  let DaySchedule;

  if (day === null) {
    DaySchedule = nullDataComponent;
  } else {
    DaySchedule = day.isDayOff ? (
      <DayOffTeacher />
    ) : (
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="subtitle1" />
        </Grid>
        <Grid container item rowGap={3} xs={12}>
          {day.classes?.map((classEntry) => (
            <CalendarClassCard
              key={classEntry.orderNumber}
              classEntry={classEntry}
              date={dayjs(day.classDate)}
              showSection={!!classEntry.className}
            />
          ))}
        </Grid>
      </Grid>
    );
  }

  const isDayInPast = dayjs(date).isBefore(dayjs(), 'day');

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Box className="date-display-box">
          {withDivider && <Divider className="date-display-divider" orientation="vertical" />}
          <CalendarDateDisplay date={date} onClick={onDateClick} withWeekday />
        </Box>
        {day && !isDayInPast && !!scheduleId && (
          <CalendarDayOptions day={day} scheduleId={scheduleId} />
        )}
      </Box>
      {DaySchedule}
    </Box>
  );
};

CalendarDay.propTypes = {
  day: PropTypes.shape({
    isDayOff: PropTypes.bool,
    classes: PropTypes.arrayOf(PropTypes.shape(classPropType)),
    classDate: PropTypes.string,
  }),
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]).isRequired,
  scheduleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  nullDataComponent: PropTypes.node,
  onDateClick: PropTypes.func,
  withDivider: PropTypes.bool,
};

CalendarDay.defaultProps = {
  day: undefined,
  scheduleId: null,
  nullDataComponent: null,
  onDateClick: null,
  withDivider: false,
};

export default CalendarDay;
