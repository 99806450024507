import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';

import { Typography } from '../../../../atoms';
import { ReactComponent as ExpandIcon } from '../../../../../resources/icons/chevron_down.svg';
import { ReactComponent as CollapseIcon } from '../../../../../resources/icons/chevron_up.svg';
import PortfolioSortButton from '../portfolio-sort-button/PortfolioSortButton';
import PortfolioCard from '../portfolio-card/PortfolioCard';
import { EditingButton } from '../../../../moleculas';

const DEFAULT_CARDS_QUANTITY = 3;
const PortfolioSectionByStatus = ({
  deleteHandler,
  getPublishedPortfolioActions,
  isCardsCollapsed,
  portfolios,
  sectionSubtitle,
  setIsCardsCropped,
  setSortingOptions,
  sortingOption,
  status,
  unpublishHandler,
}) => {
  const { t } = useTranslation();

  return (
    <Box mt={1}>
      <Box mt={1}>
        <Box mb={3}>
          <Typography maxWidth="50%" variant="body2">
            {sectionSubtitle}
          </Typography>
        </Box>
        {portfolios?.length > 1 && (
          <Box mb={3}>
            <PortfolioSortButton
              onOptionClick={setSortingOptions}
              sortingOption={sortingOption}
              status={status}
            />
          </Box>
        )}
        <Box>
          <Grid container spacing={3}>
            {portfolios
              .slice(0, isCardsCollapsed ? DEFAULT_CARDS_QUANTITY : portfolios?.length)
              .map((portfolio) => (
                <Grid key={portfolio.id} item xs={4}>
                  <PortfolioCard
                    deleteHandler={deleteHandler}
                    getPublishedPortfolioActions={getPublishedPortfolioActions}
                    portfolio={portfolio}
                    unpublishHandler={unpublishHandler}
                    withActions={!!deleteHandler}
                  />
                </Grid>
              ))}
          </Grid>
        </Box>
        {portfolios?.length > DEFAULT_CARDS_QUANTITY && (
          <Box display="flex" justifyContent="end" mt={3}>
            <EditingButton
              gaLabel={isCardsCollapsed ? 'Show more' : 'Show less'}
              icon={isCardsCollapsed ? <ExpandIcon /> : <CollapseIcon />}
              iconPosition="end"
              onClick={() => setIsCardsCropped((prevState) => !prevState)}
              text={t(isCardsCollapsed ? 'Show more' : 'Show less')}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

PortfolioSectionByStatus.propTypes = {
  deleteHandler: PropTypes.func,
  getPublishedPortfolioActions: PropTypes.func,
  isCardsCollapsed: PropTypes.bool,
  portfolios: PropTypes.arrayOf(Object),
  sectionSubtitle: PropTypes.string,
  setIsCardsCropped: PropTypes.func.isRequired,
  setSortingOptions: PropTypes.func.isRequired,
  sortingOption: PropTypes.instanceOf(Object).isRequired,
  status: PropTypes.string,
  unpublishHandler: PropTypes.func,
};

PortfolioSectionByStatus.defaultProps = {
  deleteHandler: null,
  getPublishedPortfolioActions: () => {},
  isCardsCollapsed: true,
  portfolios: [],
  sectionSubtitle: '',
  status: 'DRAFT',
  unpublishHandler: null,
};

export default PortfolioSectionByStatus;
