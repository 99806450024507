/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import MUICheckbox from '@mui/material/Checkbox';
import MUIFormGroup from '@mui/material/FormGroup';
import MUIFormControl from '@mui/material/FormControl';
import MUIFormLabel from '@mui/material/FormLabel';

import FormControlLabel from '../form-control-label/FormControlLabel';
import { GA } from '../../../utils';
import { ReactComponent as CheckboxSelectedIcon } from '../../../resources/icons/checkbox_selected.svg';
import { ReactComponent as CheckboxUnselectedIcon } from '../../../resources/icons/checkbox_unselected.svg';
import { GaActions, GaCategories } from '../../../constants/enums';

export const Checkbox = ({ gaLabel, onChange, className, ...rest }) => {
  const [active, setActive] = useState(false);

  return (
    <MUICheckbox
      checkedIcon={<CheckboxSelectedIcon />}
      className={classNames({ 'Mui-active': active }, 'ayo-checkbox', className)}
      color="primary"
      disableRipple
      icon={<CheckboxUnselectedIcon />}
      onChange={(e) => {
        if (gaLabel) {
          GA.logInteraction({
            category: GaCategories.BEHAVIOR,
            action: GaActions.CHECKBOX_CLICK,
            label: gaLabel,
          });
        }
        onChange(e);
      }}
      onMouseDown={() => {
        setActive(true);
      }}
      onMouseUp={() => {
        setActive(false);
      }}
      {...rest}
    />
  );
};

Checkbox.propTypes = {
  gaLabel: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
};

Checkbox.defaultProps = {
  className: null,
  gaLabel: '',
  onChange: () => {},
};

const CheckboxGroup = ({
  caption,
  className,
  onChange,
  options,
  defaultValues,
  gaLabel,
  ...rest
}) => {
  const [values, setValues] = useState(defaultValues);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.value]: e.target.checked });

    onChange(e);

    if (gaLabel) {
      GA.logInteraction({
        category: GaCategories.BEHAVIOR,
        action: `${GaActions.CHECKBOX_CLICK} - ${e.target.value}`,
        label: gaLabel,
      });
    }
  };

  return (
    <MUIFormControl
      className={`${classNames('ayo-checkboxgroup', { [`${className}`]: className })}`}
      component="fieldset"
    >
      <MUIFormLabel component="legend">{caption}</MUIFormLabel>
      <MUIFormGroup aria-label={caption} onChange={handleChange} {...rest}>
        {options.map((option) => (
          <FormControlLabel
            key={`O_${option.value}`}
            className={option.className}
            control={<Checkbox checked={values[option.value] === true} />}
            disabled={option.disabled}
            label={option.text}
            value={option.value}
          />
        ))}
      </MUIFormGroup>
    </MUIFormControl>
  );
};

CheckboxGroup.propTypes = {
  caption: PropTypes.string,
  className: PropTypes.string,
  defaultValues: PropTypes.instanceOf(Object),
  gaLabel: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      className: PropTypes.string,
      disabled: PropTypes.bool,
      text: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
};

CheckboxGroup.defaultProps = {
  caption: '',
  className: '',
  defaultValues: {},
  gaLabel: null,
  onChange: () => {},
};

export default CheckboxGroup;
