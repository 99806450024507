import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Box, Grid, Container, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Typography } from '../../../atoms';
import { GoToStudentProfileBlock, NewFeatureHotspot } from '../../../moleculas';
import { PageWrapper, InterestsPlot, FeedbackPopup } from '../../../organisms';
import { AppActions, AppContext, UserActions, UserContext } from '../../../../context';
import { useStudentsService, useOneTimeActionService } from '../../../../services';
import {
  getInterestType,
  getNormalizedLanguageCode,
  transformInterestToBubble,
} from '../../../../utils';
import {
  FeedbackFunctionalityKeysMap,
  InterestTypes,
  OneTimeActionsMap,
} from '../../../../constants/enums';
import { trainAyoRoute } from '../../../../constants/routes';
import { NewFeaturesIdsMap } from '../../../../tours/common/NewFeaturesItemsProvider';

const interestFinder = (item, newItem) => {
  const newBubble = transformInterestToBubble(newItem);
  if (item.id === newItem.id) {
    return { ...newBubble, r: newBubble.r + newBubble.spacer };
  }
  return {
    ...item,
    subInterests: item.subInterests.map((subItem) =>
      subItem.id === newItem.id ? { ...newBubble, r: newBubble.r + newBubble.spacer } : subItem,
    ),
  };
};

const ExploreInterestsPage = () => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const { getAllInterests } = useStudentsService();

  const { dispatch: dispatchAppState } = useContext(AppContext);

  const [studentInterests, setStudentInterests] = useState([]);

  const [hasLikedInterests, setHasLikedInterests] = useState(false);

  const feedbackApiRef = useRef();

  useEffect(() => {
    getAllInterests(getNormalizedLanguageCode(i18n.language)).then((allInterestsData) => {
      setStudentInterests(
        allInterestsData.interests
          .map(transformInterestToBubble)
          .map((interest) => ({ ...interest, r: interest.r + interest.spacer })),
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  useEffect(() => {
    setHasLikedInterests(
      studentInterests.some(
        (interest) =>
          getInterestType(interest) === InterestTypes.LIKED ||
          interest.subInterests.some(
            (subInterest) => getInterestType(subInterest) === InterestTypes.LIKED,
          ),
      ),
    );
  }, [studentInterests]);

  const changeItem = useCallback(
    (newItem, share) => {
      const newInterests = studentInterests.map((interest) => interestFinder(interest, newItem));
      setStudentInterests(newInterests);
      const snackBarText = share
        ? t('Thanks for reacting! The more AYO knows, the faster your profile grows')
        : t('Thanks for reacting! Your comment is visible only to you');
      dispatchAppState({
        type: AppActions.SET_SNACKBAR_STATUS,
        data: {
          text: snackBarText,
          type: 'success',
        },
      });
      if (feedbackApiRef.current) {
        feedbackApiRef.current.openPopup();
      }
    },
    [dispatchAppState, studentInterests, t],
  );

  const { state: userState, dispatch: dispatchUserState } = useContext(UserContext);

  const { postOneTimeAction } = useOneTimeActionService();

  useEffect(() => {
    if (!userState.oneTimeActions.includes(OneTimeActionsMap.EXPLORE_INTERESTS_OPEN)) {
      postOneTimeAction(OneTimeActionsMap.EXPLORE_INTERESTS_OPEN);
      dispatchUserState({
        type: UserActions.SET_ONE_TIME_ACTION,
        data: OneTimeActionsMap.EXPLORE_INTERESTS_OPEN,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper backToLink={trainAyoRoute} backToText={t('Go to Train AYO')}>
      <Container>
        <Box mb={isWidthUpSm ? 10 : 5}>
          <Grid container>
            <Grid item sm={6} xs={12}>
              <Box display="flex" flexDirection="column" pb={2}>
                <Box alignItems="center" display="flex" mb={1}>
                  <Typography component="h1" variant="h2">
                    {t('Interests universe')}
                  </Typography>
                  <NewFeatureHotspot
                    id={NewFeaturesIdsMap.INTEREST_DEFINITION}
                    isClickable
                    label={t('Interest definition')}
                  />
                  <NewFeatureHotspot
                    id={NewFeaturesIdsMap.INTEREST_COMMENT}
                    isClickable
                    label={t('Your comment (student)')}
                  />
                </Box>
                <Box maxWidth={500} mb={4}>
                  <Typography variant="body2">
                    {t(
                      'Here, you can look through the interests categories, find suggestions and react to any!',
                    )}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <Grid container item xs={12}>
                  <Grid item xs={12}>
                    <Box>
                      <InterestsPlot
                        changeItem={changeItem}
                        interests={studentInterests}
                        nextElementSelector={
                          hasLikedInterests ? '#my-profile-button' : '#start-tour-button'
                        }
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
        {hasLikedInterests && <GoToStudentProfileBlock />}
      </Container>
      <FeedbackPopup
        feedbackApiRef={feedbackApiRef}
        functionalityKey={FeedbackFunctionalityKeysMap.INTERESTS_UNIVERSE}
        title={t('How easy was to interact with the Interests universe?')}
        type="slider"
      />
    </PageWrapper>
  );
};

export default ExploreInterestsPage;
