import { AssignmentTypes } from '../../../../../constants/enums';

export const validateAssignmentsCreator = (validators) => () =>
  validators.reduce((acc, validator) => {
    const validationResult = validator();
    return validationResult.length ? [...acc, ...validationResult] : acc;
  }, []);

export const getAssignmentsOptions = (t) => [
  {
    value: AssignmentTypes.ASSIGNMENT,
    label: t('Assignment'),
  },
  {
    value: AssignmentTypes.SHORT_ANSWER,
    label: t('Short answer question'),
  },
  {
    value: AssignmentTypes.MULTIPLE_CHOICE,
    label: t('Multiple choice question'),
  },
  {
    value: AssignmentTypes.ANNOUNCEMENT,
    label: t('Announcement'),
  },
  {
    value: AssignmentTypes.MATERIAL,
    label: t('Material'),
  },
];
