import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Divider, useTheme, useMediaQuery } from '@mui/material';

import { Typography } from '../../atoms';

const AccordionClassGroupHeader = ({ count, children, label }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Box alignItems="center" display="flex" minWidth="0">
      <Typography
        className="accordion-class-group-header__displayname"
        component="h2"
        variant="subtitle1"
      >
        {children}
      </Typography>
      <Divider className="accordion-class-group-header__divider" flexItem orientation="vertical" />
      <Typography className="accordion-class-group-header__count" isLabel variant="body2">
        {isWidthUpSm ? t('accordion class group header', { context: label, count }) : count}
      </Typography>
    </Box>
  );
};

AccordionClassGroupHeader.propTypes = {
  count: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  label: PropTypes.string,
};

AccordionClassGroupHeader.defaultProps = {
  label: 'student',
};

export default AccordionClassGroupHeader;
