import React, { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { Typography, Button } from '../../atoms';
import DownloadAppButtonsBlock from '../../moleculas/download-app-buttons-block/DownloadAppButtonsBlock';
import { OneTimeActionsMap, RolesMap } from '../../../constants/enums';
import { UserActions, UserContext } from '../../../context';
import { checkEnvironmentVariable, useProfile } from '../../../utils';
import { useOneTimeActionService } from '../../../services';

const IS_MOBILE_APP_RELEASED = checkEnvironmentVariable(
  'REACT_APP_IS_MOBILE_APP_AVAILABLE',
  'true',
);

const TryMobileInformationalMessage = ({ className }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const [isTryMobileAppNotificationAcknowledged, setIsTryMobileAppNotificationAcknowledged] =
    useState(null);
  const { state: userState, dispatch: dispatchUserState } = useContext(UserContext);
  const isStudent = userState.profile.role === RolesMap.STUDENT;
  const { getOneTimeActionStatus } = useProfile();
  const { postOneTimeAction } = useOneTimeActionService();

  useEffect(() => {
    setIsTryMobileAppNotificationAcknowledged(
      getOneTimeActionStatus(OneTimeActionsMap.TRY_MOBILE_APP_NOTIFICATION),
    );
  }, [getOneTimeActionStatus, userState.oneTimeActions]);

  const gotItHandler = useCallback(() => {
    postOneTimeAction(OneTimeActionsMap.TRY_MOBILE_APP_NOTIFICATION);
    dispatchUserState({
      type: UserActions.SET_ONE_TIME_ACTION,
      data: OneTimeActionsMap.TRY_MOBILE_APP_NOTIFICATION,
    });
  }, [dispatchUserState, postOneTimeAction]);

  if (!IS_MOBILE_APP_RELEASED || !isStudent) {
    return null;
  }

  return isTryMobileAppNotificationAcknowledged === false ? (
    <Box
      className={classnames('ayo-try-mobile-informational-message', className)}
      display="flex"
      flexDirection={isWidthUpMd ? 'row' : 'column'}
    >
      <Box
        alignItems="center"
        className="ayo-try-mobile-informational-message__description"
        display="flex"
        justifyContent="space-between"
      >
        <Box mr={2}>
          <Typography className="ayo-try-mobile-informational-message__text" variant="body2">
            {t('AYO App is now available!')}
          </Typography>
        </Box>
        {isWidthUpMd && (
          <Box ml={2} mr={3}>
            <DownloadAppButtonsBlock />
          </Box>
        )}
        <Box>
          <Button gaLabel="Got it" onClick={gotItHandler} variant="text">
            {t('Got it')}
          </Button>
        </Box>
      </Box>
      {!isWidthUpMd && (
        <Box display="flex" justifyContent="center" mt={3}>
          <DownloadAppButtonsBlock />
        </Box>
      )}
    </Box>
  ) : null;
};

TryMobileInformationalMessage.propTypes = {
  className: PropTypes.string,
};

TryMobileInformationalMessage.defaultProps = {
  className: '',
};

export default TryMobileInformationalMessage;
