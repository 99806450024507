const tourStepStyleHelper = (isWidthUpSm) => ({
  initialStep: {
    tooltipContent: {
      textAlign: 'center',
      fontFamily: ['Lato', 'sans-serif'],
      fontWeight: 'bold',
      fontSize: '1.833rem',
      lineHeight: '2.75rem',
      color: 'rgba(0, 0, 0, 0.85)',
    },
    tooltipFooter: {
      flexDirection: !isWidthUpSm && 'column-reverse',
    },
    tooltipFooterSpacer: {
      marginTop: !isWidthUpSm && 10,
    },
  },
  sideMenuTourStep: {
    tooltip: {
      top: isWidthUpSm ? -10 : 0,
    },
  },
  startTourStep: {
    tooltip: {
      right: isWidthUpSm ? -10 : 0,
    },
  },
});

export default tourStepStyleHelper;
