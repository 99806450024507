import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Container, Grid, useMediaQuery, useTheme } from '@mui/material';

import { Image, Typography } from '../../../../../atoms';
import { LanguagesMap } from '../../../../../../constants/enums';
import { getNormalizedLanguageCode } from '../../../../../../utils';

const BricksImageSrc = {
  [LanguagesMap.EN_US]: '/media/lesson-planner/for_who_section_bricks_en.svg',
  [LanguagesMap.ES_US]: '/media/lesson-planner/for_who_section_bricks_es.svg',
};

const LessonPlannerForWhoSection = () => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const isWidthUpMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box
      className="ayo-lesson-planner-landing__for-who-section"
      pb={isWidthUpSm ? 20 : 10}
      pt={isWidthUpSm ? 25 : 10}
    >
      <Container>
        <Box
          className="ayo-lesson-planner-landing__for-who-section__container"
          pl={isWidthUpMd ? 5 : 2}
          pr={isWidthUpSm ? 1 : 2}
        >
          <Grid container>
            <Grid
              alignContent="center"
              className="ayo-lesson-planner-landing__for-who-section__container__teacher"
              container
              item
              position="relative"
              sm={5}
              xs={12}
            >
              <Image
                alt={t('A teacher explaining something and pointing at a large screen.')}
                src="/media/lesson-planner/for_who_section_teacher.png"
              />
            </Grid>
            <Grid
              className="ayo-lesson-planner-landing__for-who-section__container__content"
              item
              md={4}
              py={isWidthUpSm ? 14 : 2}
              sm={5}
              xs={12}
            >
              <Typography component="h2" variant="h1">
                {t('Who is this for?')}
              </Typography>
              <Box mb={1} mt={3}>
                <Typography component="h3" variant="h2">
                  {t('Educators')}
                </Typography>
              </Box>
              <Typography variant="body2">
                <Trans
                  components={{ br: <br />, b: <b /> }}
                  i18nKey="Plan and tailor your lessons to specific TEKS to make all the difference in creating a unique learning experience for your students."
                />
              </Typography>
            </Grid>
            <Grid
              className="ayo-lesson-planner-landing__for-who-section__container__bricks"
              item
              md={3}
              position="relative"
              sm={2}
              xs={12}
            >
              <Image src={BricksImageSrc[LanguagesMap[getNormalizedLanguageCode(i18n.language)]]} />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default LessonPlannerForWhoSection;
