import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import { Typography } from '../../../../../atoms';
import { AttachmentsResourcesTypes } from '../../../../../../constants/enums';
import LessonPageMaterialsContent from '../../../lesson-page/components/lesson-page-materials-content/LessonPageMaterialsContent';

const AssignmentMaterials = ({ assignmentId, disabled, handleChange, materials }) => {
  const { t } = useTranslation();
  return (
    <Box className="ayo-lesson-assignment-materials">
      <Typography paragraph variant="subtitle2">
        {t('Materials')}
      </Typography>
      <LessonPageMaterialsContent
        allowedExtensions={['jpg', 'jpeg', 'png', 'gif', 'mp3', 'mp4', 'pdf']}
        disabledTooltipText={
          <Trans components={{ br: <br /> }} i18nKey="No AYO assignment editing tooltip" />
        }
        isDisabled={disabled}
        onChange={handleChange}
        onInProgressChange={(isInProgress) => handleChange('inProgress', isInProgress)}
        resourceId={assignmentId}
        resourceType={AttachmentsResourcesTypes.ASSIGNMENT_MATERIAL}
        values={materials}
      />
    </Box>
  );
};

AssignmentMaterials.propTypes = {
  assignmentId: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  materials: PropTypes.instanceOf(Object),
};

AssignmentMaterials.defaultProps = {
  materials: null,
};

export default AssignmentMaterials;
