import React, { useState, useCallback, useMemo, useContext, useEffect } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Box, Container, Grid, Tab } from '@mui/material';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import { Tabs, Typography, TabPanel, Button, Switch } from '../../../atoms';
import { CampusLabel, NewFeatureHotspot } from '../../../moleculas';
import {
  PageWrapper,
  NoStudentsScreen,
  GroupClassView,
  SearchStudentsContainer,
  AccordionGradeView,
  FeedbackPopup,
  HighlightsBlock,
  InterventionRequiredGroupView,
  CustomPeriodLabel,
} from '../../../organisms';
import { NewFeaturesIdsMap } from '../../../../tours/common/NewFeaturesItemsProvider';
import { getNormalizedLanguageCode, useEmotionalLearning, useProfile } from '../../../../utils';
import {
  FeedbackFunctionalityKeysMap,
  HighlightsInitiatives,
  LocalStorageKeysMap,
  OneTimeActionsMap,
  StudentActivityCriterions,
} from '../../../../constants/enums';
import {
  campusSelectionRoute,
  myProfileRoute,
  myStudentsRoute,
} from '../../../../constants/routes';
import { UserActions, UserContext } from '../../../../context';
import {
  useAnalyticsService,
  useOneTimeActionService,
  useTeachersService,
} from '../../../../services';
import {
  useFiltersLastActionState,
  useSearchParams,
  useStudentsData,
  useStudentsFiltersSnackbars,
} from '../../../../hooks';
import { ReactComponent as FeedbackGreenRibbonsImage } from '../../../../resources/images/feedback_green_ribbons.svg';
import { ReactComponent as PreviewIcon } from '../../../../resources/icons/preview.svg';

import FeedbackFormContainer from './components/FeedbackFormContainer';

const ClassViewsTypes = {
  SIMPLE: 'simple',
  GROUP: 'group',
};

const FEEDBACK_MAX_STUDENT_PROFILES_VISITED = 5;

const isStudentActive = (student) => student.active;

const StudentsListPage = () => {
  const { t, i18n } = useTranslation();

  const [activeTab, setActiveTab] = useState('simple');
  const handleTabClick = (e, value) => setActiveTab(value);

  const history = useHistory();

  const [searchValue, setSearchValue] = useState('');
  const handleSearch = (value) => {
    setActiveTab(ClassViewsTypes.SIMPLE);
    setSearchValue(value);
    history.replace({ search: null });
  };

  const { getOneTimeActionStatus } = useProfile();
  const isFeedbackFormVisible = useMemo(
    () => !getOneTimeActionStatus(OneTimeActionsMap.MY_STUDENTS_FEEDBACK),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const { state: userState, dispatch: dispatchUserState } = useContext(UserContext);
  const activeUsersHintHidden = useMemo(
    () => userState.oneTimeActions?.includes(OneTimeActionsMap.STUDENTS_ACTIVITY_NOTIFICATION),
    [userState],
  );

  const { postOneTimeAction } = useOneTimeActionService();

  const handleActiveNotification = useCallback(() => {
    postOneTimeAction(OneTimeActionsMap.STUDENTS_ACTIVITY_NOTIFICATION);
    dispatchUserState({
      type: UserActions.SET_ONE_TIME_ACTION,
      data: OneTimeActionsMap.STUDENTS_ACTIVITY_NOTIFICATION,
    });
  }, [dispatchUserState, postOneTimeAction]);

  const getStudentProfileRedirectLink = (id) => `${myStudentsRoute}/${id}`;

  const [stats, setStats] = useState(null);

  const { getHighlights } = useAnalyticsService();

  useEffect(() => {
    getHighlights(
      null,
      getNormalizedLanguageCode(i18n.language),
      userState.profile?.currentCampus,
    ).then((highlightsData) => setStats(highlightsData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language, userState.profile?.currentCampus]);

  const filterParams = useSearchParams();

  const {
    filteredStudents,
    filteredStudentsCount,
    handleGroupSetChange,
    shouldRenderStudentsList,
    students,
    studentsInterventionRequire,
    studentsMoodsTrends,
    suggestions,
    teacherGroups,
    totalStudentsCount,
  } = useStudentsData({
    activityCriterion: StudentActivityCriterions.ANY,
    searchValue,
    withGroups: true,
    withMoodsTrend: true,
    filterString: filterParams.toString(),
  });

  const hasActiveStudent = useMemo(() => suggestions?.some(isStudentActive), [suggestions]);

  const [filtersUpdateState, updateFiltersLastActionState] = useFiltersLastActionState();

  useStudentsFiltersSnackbars(
    filteredStudents,
    filteredStudentsCount,
    students,
    totalStudentsCount,
    filtersUpdateState,
  );

  const [currentHighlightsInitiative, setCurrentHighlightsInitiative] = useState();

  const {
    showInterventionRequestSection,
    negativeMoodTrendIcon,
    negativeMoodTrendTooltip,
    negativeMoodTrendAriaLabel,
  } = useEmotionalLearning(currentHighlightsInitiative, studentsMoodsTrends, filterParams);

  const { getIsCampusSelectionAvailable } = useProfile();

  const { postDemoSwitch } = useTeachersService();

  const [showSections, setShowSections] = useState(true);

  const displayedStudents = filteredStudents || students;

  return (
    <PageWrapper
      backToLink={getIsCampusSelectionAvailable() ? campusSelectionRoute : null}
      backToText={getIsCampusSelectionAvailable() ? t('Go to campus selection') : ''}
      mainElementClassName={classnames('ayo-students-list-page', {
        'main-wrapper': shouldRenderStudentsList(suggestions),
      })}
    >
      {shouldRenderStudentsList(suggestions) ? (
        <Container className="ayo-students-list-page__content">
          <Box pb={5}>
            <Box
              alignItems="center"
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography component="h1" variant="h1">
                {t('My students')}
              </Typography>
              <Box alignItems="center" display="flex">
                <NewFeatureHotspot
                  id={NewFeaturesIdsMap.STUDENT_MODE}
                  isClickable
                  label={t('Student mode')}
                />
                <Button
                  endIcon={<PreviewIcon />}
                  gaLabel="Enter student demo mode"
                  onClick={() => {
                    postDemoSwitch().then(() => {
                      window.location.assign(myProfileRoute);
                    });
                  }}
                >
                  {t('View as student')}
                </Button>
              </Box>
            </Box>
            <CampusLabel />
            <Grid container direction="column">
              <Grid container item>
                <Grid item md={6} sm={8} xl={7} xs={12}>
                  <Typography isLabel paragraph variant="body2">
                    {t('Some students may not be listed here - paragraph')}
                  </Typography>
                </Grid>
              </Grid>
              <SearchStudentsContainer
                notification={{
                  handler: handleActiveNotification,
                  text: 'A green ribbon means there’s information in the profile',
                  visible: !activeUsersHintHidden,
                }}
                onSearch={handleSearch}
                searchValue={searchValue}
                studentCardRedirectLink={getStudentProfileRedirectLink}
                students={students}
                suggestions={suggestions}
              />
              {!searchValue && activeTab === ClassViewsTypes.SIMPLE && (
                <Grid item xs={12}>
                  <HighlightsBlock
                    infoMessage="Highlights graph informational message"
                    initiatives={[
                      HighlightsInitiatives.MOODS,
                      HighlightsInitiatives.INTERESTS,
                      HighlightsInitiatives.APTITUDES,
                      HighlightsInitiatives.ENDORSEMENTS,
                      HighlightsInitiatives.OPPORTUNITIES,
                      HighlightsInitiatives.LEADERSHIP_ATTRIBUTES,
                      HighlightsInitiatives.EXTRACURRICULARS,
                      HighlightsInitiatives.ACHIEVEMENTS,
                      HighlightsInitiatives.GOALS,
                      HighlightsInitiatives.PARENTS_ANSWERS,
                      HighlightsInitiatives.PORTFOLIO,
                    ]}
                    onFiltersUpdate={updateFiltersLastActionState}
                    onInitiativeChange={setCurrentHighlightsInitiative}
                    stats={stats}
                    title="Campus highlights for"
                    totalStudentsCount={totalStudentsCount}
                  />
                </Grid>
              )}
              {!searchValue && (
                <Box pt={5}>
                  <Tabs
                    className="my-students-tabs"
                    onChange={handleTabClick}
                    value={activeTab}
                    variant="standard"
                  >
                    <Tab disableRipple label={t('Periods')} value={ClassViewsTypes.SIMPLE} />
                    <Tab disableRipple label={t('Groups')} value={ClassViewsTypes.GROUP} />
                    <NewFeatureHotspot
                      id={NewFeaturesIdsMap.GROUPING}
                      isClickable
                      label={t('Groups')}
                    />
                  </Tabs>
                </Box>
              )}
              <TabPanel currentTabValue={activeTab} value={ClassViewsTypes.SIMPLE}>
                {showInterventionRequestSection &&
                  !!studentsInterventionRequire?.length &&
                  !searchValue && (
                    <InterventionRequiredGroupView
                      studentAriaLabelConstructor={negativeMoodTrendAriaLabel()}
                      studentCardRedirectLink={getStudentProfileRedirectLink}
                      studentsList={studentsInterventionRequire}
                      studentStatusIcon={negativeMoodTrendIcon()}
                      studentWrapperConstructor={negativeMoodTrendTooltip()}
                    />
                  )}
                <Box display="flex" gap={2} justifyContent="flex-end" mt={4}>
                  <Typography variant="subtitle2">
                    <b>{t('View sections')}</b>
                  </Typography>
                  <Switch
                    checkedLabel={t('On')}
                    onChange={(value) => setShowSections(value)}
                    uncheckedLabel={t('Off')}
                    value={showSections}
                  />
                </Box>
                <AccordionGradeView
                  CustomGradeLabel={CustomPeriodLabel}
                  showSubgroups={showSections}
                  studentAriaLabelConstructor={negativeMoodTrendAriaLabel()}
                  studentCardRedirectLink={getStudentProfileRedirectLink}
                  students={displayedStudents}
                  studentStatusIcon={negativeMoodTrendIcon()}
                  studentWrapperConstructor={negativeMoodTrendTooltip()}
                />
              </TabPanel>
              <TabPanel currentTabValue={activeTab} value={ClassViewsTypes.GROUP}>
                <GroupClassView
                  handleGroupSetChange={handleGroupSetChange}
                  studentCardRedirectLink={getStudentProfileRedirectLink}
                  students={students}
                  teacherGroups={teacherGroups}
                />
              </TabPanel>
            </Grid>
            {isFeedbackFormVisible && <FeedbackFormContainer />}
          </Box>
        </Container>
      ) : (
        <NoStudentsScreen />
      )}
      {+localStorage.getItem(LocalStorageKeysMap.AYO_STUDENT_PROFILES_VISITED) >=
        FEEDBACK_MAX_STUDENT_PROFILES_VISITED &&
        hasActiveStudent && (
          <FeedbackPopup
            CustomMainContent={<FeedbackGreenRibbonsImage />}
            functionalityKey={FeedbackFunctionalityKeysMap.GREEN_RIBBONS}
            isAutoShow
            title={t('Are the green ribbons helpful?')}
            type="yesno"
            variant="dark"
          />
        )}
    </PageWrapper>
  );
};

export default StudentsListPage;
