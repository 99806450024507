import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Button, Typography } from '../../../../../atoms';
import { DisabledControlWithTooltip, InitiativeEmptyStateBlock } from '../../../../../moleculas';
import { ClubHubCard } from '../../../../../organisms';
import { UserContext } from '../../../../../../context';
import { sortArrayByKey } from '../../../../../../utils';
import { clubHubRoute, createRoute } from '../../../../../../constants/routes';
import { ReactComponent as ClubHubEmptyState } from '../../../../../../resources/images/club_hub_empty_state.svg';
import { ReactComponent as AddIcon } from '../../../../../../resources/icons/add.svg';

const ClubCreateButton = () => {
  const { t } = useTranslation();
  const { state: userState } = useContext(UserContext);
  return (
    <DisabledControlWithTooltip
      showTooltip={!userState.profile?.schools}
      title={t(
        'AYO is preparing some important data. Once it’s ready, you’ll be able to create a club.',
      )}
    >
      <Button
        component={RouterLink}
        disabled={!userState.profile?.schools}
        disableElevation
        disableRipple
        endIcon={<AddIcon />}
        gaLabel="Create a club"
        to={`${clubHubRoute}${createRoute}`}
      >
        {t('Create a club')}
      </Button>
    </DisabledControlWithTooltip>
  );
};

const TeacherClubHub = ({ clubs }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography component="h2" mb={3} variant="h2">
        {t('Clubs I manage')}
      </Typography>
      {clubs.length ? (
        <Box>
          <Grid container item spacing={3}>
            {sortArrayByKey(clubs, 'updatedDate', 'date').map(
              ({ id, clubName, attachments, status, membersCount }) => (
                <Grid key={id} item md={4} sm={6} xs={12}>
                  <ClubHubCard
                    clubName={clubName}
                    imageInfo={attachments?.[0]}
                    membersCount={membersCount}
                    redirectUrl={`${clubHubRoute}/${id}`}
                    status={status}
                  />
                </Grid>
              ),
            )}
          </Grid>
          <Box mt={3}>
            <ClubCreateButton />
          </Box>
        </Box>
      ) : (
        <InitiativeEmptyStateBlock
          body={t('Start creating a club in AYO that you will be in charge of managing.')}
          customButton={<ClubCreateButton />}
          illustration={<ClubHubEmptyState />}
          title={t('No clubs created by you yet')}
        />
      )}
    </Box>
  );
};

TeacherClubHub.propTypes = {
  clubs: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
};

export default TeacherClubHub;
