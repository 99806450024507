import React from 'react';
import PropTypes from 'prop-types';
import { Drawer } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Button } from '../../atoms';
import { ReactComponent as CloseIcon } from '../../../resources/icons/close.svg';

const ChatDrawer = ({ children, gaLabel, isOpen, onClose }) => {
  const { t } = useTranslation();

  return (
    <Drawer
      anchor="right"
      className="ayo-chat-drawer"
      onClose={onClose}
      open={isOpen}
      PaperProps={{ className: 'ayo-chat-drawer__paper' }}
      style={{ zIndex: 1200 }}
    >
      <Button
        aria-label={t('Close')}
        autoFocus
        className="ayo-chat-drawer__close-icon"
        gaLabel={gaLabel ? `${gaLabel} - dialog close` : null}
        isIconButton
        onClick={onClose}
      >
        <CloseIcon height="24" width="24" />
      </Button>
      {children}
    </Drawer>
  );
};

ChatDrawer.propTypes = {
  children: PropTypes.node.isRequired,
  gaLabel: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

ChatDrawer.defaultProps = {
  gaLabel: null,
  isOpen: false,
};

export default ChatDrawer;
