import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { UserContext } from '../../../../context';
import { getQuestionnairesByType } from '../../../../utils';
import { QuestionnaireNamesByTypesMap } from '../../../../constants/enums';
import { ReactComponent as StartImage } from '../../../../resources/images/train_ayo_parent.svg';

import TrainAyoSelectPage from './TrainAyoSelectPage';

const InitialQuestionnaireSelectPage = () => {
  const { t } = useTranslation();

  const { state: userState } = useContext(UserContext);
  const { dependentProfiles } = userState;

  const dependentItems = useMemo(
    () =>
      dependentProfiles.map((dependentProfile) => ({
        ...dependentProfile,
        completedQuestionnaires: getQuestionnairesByType(
          dependentProfile.completedQuestionnaires,
          QuestionnaireNamesByTypesMap.INITIAL,
        ),
        pendingQuestionnaires: getQuestionnairesByType(
          dependentProfile.pendingQuestionnaires,
          QuestionnaireNamesByTypesMap.INITIAL,
        ),
      })),
    [dependentProfiles],
  );

  return (
    <TrainAyoSelectPage
      dependentItems={dependentItems}
      Illustration={StartImage}
      illustrationAriaLabel={t(
        'A scenery of nature with corn hole game boards and pillows, soccer ball, paints, books on the background and two camping tents and a camp fire on the background',
      )}
      subtitle={t('Train AYO parent explanation')}
      title={t('Tell us about your kid(s)')}
    />
  );
};

export default InitialQuestionnaireSelectPage;
