import { useCallback } from 'react';

import useAxios, { StatusCodeMap } from '../HttpClient';
import { apiRoute, conversationsRoute } from '../../constants/routes';

const useConversationsService = () => {
  const { get, post } = useAxios();

  const getConversation = useCallback(
    async (id, page, pageSize, enableLoaderMask) => {
      const { data } = await get(
        { enabled: enableLoaderMask },
        `${apiRoute}${conversationsRoute}/${id}?pageSize=${pageSize}${page ? `&page=${page}` : ''}`,
        {
          skipDefaultErrorHandlers: [StatusCodeMap.BAD_REQUEST, StatusCodeMap.NOT_FOUND],
        },
      );
      return data.entity;
    },
    [get],
  );

  const postChatMessage = useCallback(
    async (body) => {
      const { data } = await post({ enabled: false }, `${apiRoute}${conversationsRoute}`, body, {
        skipDefaultErrorHandlers: [StatusCodeMap.BAD_REQUEST, StatusCodeMap.NOT_FOUND],
      });

      return data.entity;
    },
    [post],
  );

  const postReadMessages = useCallback(
    (conversationId, messageIds) =>
      post(
        { enabled: false },
        `${apiRoute}${conversationsRoute}/${conversationId}/messages/read`,
        messageIds,
        { skipDefaultErrorHandlers: [StatusCodeMap.BAD_REQUEST, StatusCodeMap.NOT_FOUND] },
      ),
    [post],
  );

  return {
    getConversation,
    postChatMessage,
    postReadMessages,
  };
};

export default useConversationsService;
