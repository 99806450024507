/* eslint-disable react/jsx-props-no-spreading */
import { Box } from '@mui/material';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';

import { ActionsMenu } from '../../../../../atoms';
import { Banner } from '../../../../../moleculas';
import { onClubHubBannerDrop } from '../../../../../../utils';
import { useSnackbar } from '../../../../../../hooks';
import { ClubKeys } from '../../../../../../constants/club-hub';
import { ReactComponent as EditIcon } from '../../../../../../resources/icons/create.svg';
import { ReactComponent as RemoveIcon } from '../../../../../../resources/icons/delete_primary.svg';
import ClubBanner from '../../../club-hub-components/club-banner/ClubBanner';

const ClubDetailsBanner = ({ attachments, onChange, isEditable }) => {
  const { t } = useTranslation();

  const [clubBanner] = attachments || [];

  const { setSnackBarStatus } = useSnackbar();

  const bannerChangeHandler = useCallback(
    (change) => {
      onChange(
        change,
        { text: t('The banner has been successfully uploaded.'), type: 'success' },
        { text: t('AYO couldn’t upload the banner. Please try once more.'), type: 'error' },
      );
    },
    [onChange, t],
  );

  const onBannerDrop = (dropped) =>
    onClubHubBannerDrop(dropped, bannerChangeHandler, setSnackBarStatus, t);

  const { getInputProps, open: openFileBrowser } = useDropzone({
    onDrop: onBannerDrop,
    noClick: true,
  });

  return clubBanner ? (
    <Box mb={5} position="relative">
      <Banner image={clubBanner} />
      {isEditable && (
        <>
          <input {...getInputProps()} />
          <Box className="ayo-club-banner__actions" position="absolute" right={24} top={24}>
            <ActionsMenu
              menuItems={[
                { text: t('Change'), handler: openFileBrowser, icon: EditIcon },
                {
                  text: t('Delete'),
                  handler: () => {
                    onChange(
                      { [ClubKeys.REMOVED_ATTACHMENTS]: attachments },
                      { text: t('The banner has been successfully deleted.'), type: 'delete' },
                      {
                        text: t('AYO couldn’t delete the banner. Please try once more.'),
                        type: 'error',
                      },
                    );
                  },
                  icon: RemoveIcon,
                },
              ]}
            />
          </Box>
        </>
      )}
    </Box>
  ) : isEditable ? (
    <Box mb={5}>
      <ClubBanner onChange={bannerChangeHandler} value={attachments} />
    </Box>
  ) : null;
};

ClubDetailsBanner.propTypes = {
  attachments: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  onChange: PropTypes.func.isRequired,
  isEditable: PropTypes.bool,
};

ClubDetailsBanner.defaultProps = {
  attachments: [],
  isEditable: false,
};

export default ClubDetailsBanner;
