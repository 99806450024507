import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';

import { FeedPostCategories } from '../../constants/enums';

export const FamilyFeedContext = createContext();

export const FamilyFeedActions = {
  ADD_NEW_POST: 'ADD_NEW_POST',
  ADD_POSTS: 'ADD_POSTS',
  SET_IS_LOADING: 'SET_IS_LOADING',
  SET_PAGE: 'SET_PAGE',
  SET_POSTS: 'SET_POSTS',
  SET_TOTAL_POSTS: 'SET_TOTAL_POSTS',
  SET_CLASSES: 'SET_CLASSES',
  SET_STUDENTS: 'SET_STUDENTS',
  SET_POST_FILTER_CATEGORIES: 'SET_POST_FILTER_CATEGORIES',
  SET_ACTIVE_FILTER_CATEGORY: 'SET_ACTIVE_FILTER_CATEGORY',
  SET_FAMILY_ACTIVITIES_SUGGESTED: 'SET_FAMILY_ACTIVITIES_SUGGESTED',
};

const initState = {
  isLoading: false,
  page: 1,
  totalNumberOfPosts: null,
  posts: null,
  postFilterCategories: [],
  activeFlterCategory: FeedPostCategories.ALL_UPDATES,
  classes: null,
  students: null,
  familyActivitiesSuggested: null,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case FamilyFeedActions.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.data,
      };
    case FamilyFeedActions.SET_PAGE:
      return {
        ...state,
        page: action.data,
      };
    case FamilyFeedActions.SET_POSTS:
      return {
        ...state,
        posts: action.data,
      };
    case FamilyFeedActions.SET_TOTAL_POSTS:
      return {
        ...state,
        totalNumberOfPosts: action.data,
      };
    case FamilyFeedActions.ADD_NEW_POST:
      return {
        ...state,
        posts: [action.data, ...(state.posts || [])],
      };
    case FamilyFeedActions.ADD_POSTS:
      return {
        ...state,
        posts: [...state.posts, ...action.data],
      };
    case FamilyFeedActions.SET_CLASSES:
      return {
        ...state,
        classes: action.data,
      };
    case FamilyFeedActions.SET_STUDENTS:
      return {
        ...state,
        students: action.data,
      };
    case FamilyFeedActions.SET_POST_FILTER_CATEGORIES:
      return {
        ...state,
        postFilterCategories: action.data,
      };
    case FamilyFeedActions.SET_ACTIVE_FILTER_CATEGORY:
      return {
        ...state,
        activeFlterCategory: action.data,
      };
    case FamilyFeedActions.SET_FAMILY_ACTIVITIES_SUGGESTED:
      return {
        ...state,
        familyActivitiesSuggested: action.data,
      };
    default:
      throw new Error('Unexpected action in Family Feed');
  }
};

export const FamilyFeedContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initState);

  return (
    <FamilyFeedContext.Provider value={{ state, dispatch }}>{children}</FamilyFeedContext.Provider>
  );
};

FamilyFeedContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
