/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { Link, Typography } from '../../atoms';
import { richTextItem } from '../../../constants/propTypes';

const RichText = ({ variant, align, className, component, isParagraph, items }) => {
  const { t } = useTranslation();

  const textItems = [];

  items.forEach((item) => {
    if (item.translationText) {
      textItems.push(
        <span>{item.isBold ? <b>{t(item.translationText)}</b> : t(item.translationText)}</span>,
      );
    } else if (item.plainText) {
      textItems.push(<span>{item.isBold ? <b>{item.plainText}</b> : item.plainText}</span>);
    } else if (item.isEmpty) {
      textItems.push(<span> </span>);
    } else if (item.ulist) {
      textItems.push(
        <ul>
          {item.ulist.map((listItem, i) => (
            <li key={i}>
              <RichText
                align={align}
                className={className}
                component={component}
                isParagraph={isParagraph}
                items={listItem}
                variant={variant}
              />
            </li>
          ))}
        </ul>,
      );
    } else if (item.link) {
      if (item.link.isDownload) {
        textItems.push(
          <Link
            className={item.link.className}
            download
            gaLabel={item.link.gaLabel}
            href={item.link.route}
          >
            {item.link.translationText ? t(item.link.translationText) : item.link.plainText}
          </Link>,
        );
      } else {
        textItems.push(
          <Link
            className={item.link.className}
            component={RouterLink}
            gaLabel={item.link.gaLabel}
            to={item.link.route}
          >
            {item.link.translationText ? t(item.link.translationText) : item.link.plainText}
          </Link>,
        );
      }
    }
  });

  if (isParagraph) {
    return (
      <Typography
        align={align}
        className={className}
        component={component}
        paragraph
        variant={variant}
      >
        {textItems}
      </Typography>
    );
  }

  return (
    <Typography align={align} className={className} component={component} variant={variant}>
      {textItems}
    </Typography>
  );
};

RichText.propTypes = {
  variant: PropTypes.string,
  align: PropTypes.string,
  className: PropTypes.string,
  component: PropTypes.string,
  isParagraph: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.shape(richTextItem)).isRequired,
};

RichText.defaultProps = {
  variant: '',
  align: '',
  className: '',
  component: '',
  isParagraph: false,
};

export default RichText;
