import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Container, Grid, useMediaQuery, useTheme } from '@mui/material';

import { Typography } from '../../../atoms';
import { NewFeatureHotspot, PageEndScrollToTopButton } from '../../../moleculas';
import { PageWrapper } from '../../../organisms';
import FeedBlock from '../components/feed-block/FeedBlock';
import { FamilyFeedContext } from '../../../../context';
import PostsFilter from '../components/posts-filter/PostsFilter';
import FamilyFeedEvents from '../components/family-feed-events/FamilyFeedEvents';
import { NewFeaturesIdsMap } from '../../../../tours/common/NewFeaturesItemsProvider';

const MyFeedPage = () => {
  const { t } = useTranslation();

  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });

  const { state: familyFeedState } = useContext(FamilyFeedContext);
  const { postFilterCategories } = familyFeedState;

  return (
    <PageWrapper>
      <Box pb={isWidthUpSm ? 10 : 5}>
        <Container>
          <Grid item xs={12}>
            <Box
              alignItems="flex-start"
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              mb={5}
            >
              <Box>
                <Typography component="h1" variant="h1">
                  {t('My feed')}
                </Typography>
                <Box mt={1}>
                  <Typography variant="body2">
                    {t('Here, you can see posts from your school and your educators.')}
                  </Typography>
                  <Typography variant="body2">
                    {t(
                      'You may find information about alerts, announcements, events, and so much more.',
                    )}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Grid
              container
              flexDirection={isWidthUpSm ? 'row' : 'column-reverse'}
              item
              spacing={isWidthUpSm ? 3 : 4}
            >
              <Grid container flexDirection="column" item md={8} sm={7}>
                <Typography mb={3} variant="h2">
                  {t('Posts')}
                </Typography>
                {!!postFilterCategories?.length && (
                  <Box mb={4}>
                    <PostsFilter />
                  </Box>
                )}
                <FeedBlock />
              </Grid>
              <Grid item md={4} sm={5}>
                <Box alignItems="center" display="flex" mb={3}>
                  <Typography variant="h2">{t('Upcoming events')}</Typography>
                  <NewFeatureHotspot
                    id={NewFeaturesIdsMap.FEED_EVENTS}
                    isClickable
                    label={t('nft.feed_events.title.viewer')}
                  />
                </Box>
                <FamilyFeedEvents />
              </Grid>
            </Grid>
          </Grid>
        </Container>
        <PageEndScrollToTopButton />
      </Box>
    </PageWrapper>
  );
};

export default MyFeedPage;
