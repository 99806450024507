/* eslint-disable prefer-destructuring */
import { pack as packCircle } from './circlePacking';

export const bounds = (circles, pad = 0) => {
  const x0 = circles.reduce((v, { x, r }) => Math.min(v, x - r - pad), +Infinity);
  const x1 = circles.reduce((v, { x, r }) => Math.max(v, x + r + pad), -Infinity);
  const y0 = circles.reduce((v, { y, r }) => Math.min(v, y - r - pad), +Infinity);
  const y1 = circles.reduce((v, { y, r }) => Math.max(v, y + r + pad), -Infinity);
  return [x0, y0, x1 - x0, y1 - y0];
};

const getLine = (circles, width) => {
  let lineWidth = 0;
  let lineHeight = +Infinity;
  let line = [];
  for (let i = 0; i < circles.length; i += 1) {
    let item = circles[i];
    let itemWidth;
    let itemHeight;
    if (item.subInterests.length) {
      const interestCirclePacking = packCircle([item, ...item.subInterests]);
      const packingBounds = bounds(interestCirclePacking);
      itemWidth = packingBounds[2];
      itemHeight = packingBounds[3];
      item = {
        width: itemWidth,
        height: itemHeight,
        x0: packingBounds[0],
        y0: packingBounds[1],
        items: interestCirclePacking,
      };
    } else {
      itemWidth = item.r * 2;
      itemHeight = itemWidth;
    }
    if (lineWidth + itemWidth > width) break;
    lineWidth += itemWidth;
    line = [...line, item];
    if (itemHeight < lineHeight) {
      lineHeight = itemHeight;
    }
  }
  return { width: lineWidth, height: lineHeight, items: line };
};

const isInRectangle = (x0, y0, rectX, rectY, rectWidth, rectHeight) =>
  x0 >= rectX && x0 < rectX + rectWidth && y0 >= rectY && y0 < rectY + rectHeight;

export const intersects = (a, b) => {
  if (b.items) {
    const bDimensions = [b.x0, b.y0, b.width, b.height];
    return (
      isInRectangle(a.x0, a.y0, ...bDimensions) ||
      isInRectangle(a.x0 + a.width, a.y0, ...bDimensions) ||
      isInRectangle(a.x0, a.y0 + a.height, ...bDimensions) ||
      isInRectangle(a.x0 + a.width, a.y0 + a.height, ...bDimensions)
    );
  }
  const dr = a.r + b.r - 1e-6;
  const dx = b.x - a.x;
  const dy = b.y - a.y;
  return dr > 0 && dr * dr > dx * dx + dy * dy;
};

const placeCirclePacking = (items, x0, y0) =>
  items.map((subItem) => ({
    ...subItem,
    x: x0 + subItem.x,
    y: y0 + subItem.y,
  }));

export const pack = (
  circles,
  width,
  { disableSpaceAround, additionalLinesTransformation } = {
    disableSpaceAround: false,
    additionalLinesTransformation: null,
  },
) => {
  if (!circles.length || Number.isNaN(width)) return circles;
  const calculationsCircles = [...circles];

  let lines = [];
  while (calculationsCircles.length) {
    const nextLine = getLine(calculationsCircles, width);
    lines = [...lines, nextLine];
    calculationsCircles.splice(0, nextLine.items.length);
  }

  if (additionalLinesTransformation) {
    const newLines = additionalLinesTransformation(lines, width);
    lines = newLines;
  }

  let resultCircles = [];

  let currY = 0;
  for (let linesCounter = 0; linesCounter < lines.length; linesCounter += 1) {
    let lineX = 0;
    const lineY = currY;

    const lineXSpace = width - lines[linesCounter].width;
    const lineXItemSpacer = disableSpaceAround
      ? 0
      : lineXSpace / (lines[linesCounter].items.length + 1);
    for (
      let circleCounter = 0;
      circleCounter < lines[linesCounter].items.length;
      circleCounter += 1
    ) {
      const item = lines[linesCounter].items[circleCounter];
      let itemWidth;
      if (item.items) {
        item.items = placeCirclePacking(item.items, lineX - item.x0, lineY - item.y0);
        itemWidth = item.width;
        item.x0 = lineX;
        item.y0 = lineY;
      } else {
        item.x = lineX + item.r + lineXItemSpacer;
        item.y = lineY + item.r;
        itemWidth = item.r * 2;
      }
      for (
        let prevCircleCounter = 0;
        prevCircleCounter < (lines[linesCounter - 1]?.items || []).length;
        prevCircleCounter += 1
      ) {
        const prevCircle = lines[linesCounter - 1]?.items[prevCircleCounter];
        if (item.items) {
          if (intersects(item, prevCircle)) {
            item.items = placeCirclePacking(
              item.items,
              lineX - item.x0,
              prevCircle.y0 + prevCircle.height + 25 - item.y0,
            );
            item.y0 = prevCircle.y0 + prevCircle.height + 25;
          }
        } else if (intersects(item, prevCircle)) {
          item.y =
            (prevCircle.items ? prevCircle.y0 + prevCircle.height : prevCircle.y + prevCircle.r) +
            item.r;
        }
      }
      lineX += itemWidth + lineXItemSpacer;
      resultCircles = [
        ...resultCircles,
        ...(item.items
          ? item.items.map((subItem) => ({ ...subItem, r: subItem.r - subItem.spacer }))
          : [{ ...item, r: item.r - item.spacer }]),
      ];
    }

    const itemsWithSubinterestsLength = lines[linesCounter].items.filter((item) =>
      Object.prototype.hasOwnProperty.call(item, 'y0'),
    ).length;
    const newPlotYLevel = itemsWithSubinterestsLength
      ? lines[linesCounter].items.reduce(
          (acc, item) => (item.y0 ? acc + item.height + item.y0 : acc),
          0,
        ) / itemsWithSubinterestsLength
      : lines[linesCounter].items.reduce((acc, item) => Math.min(acc, item.y + item.r), +Infinity);
    currY = newPlotYLevel;
  }
  const groupItems = lines.reduce(
    (acc, line) => [...acc, ...line.items.filter((item) => item.items)],
    [],
  );
  return { circles: resultCircles, groups: groupItems };
};

export const randomDecoration = (d, areaWidth, areaHeight, padding) => {
  const ONE_POINT_DENSITY = 40_000;
  const quantity = (areaWidth * areaHeight) / ONE_POINT_DENSITY;
  let circles = [];
  for (let i = 0; i < quantity; i += 1) {
    const newPoint = {};
    newPoint.x = padding + d + Math.floor(Math.random() * (areaWidth - d * 2 - padding * 2));
    newPoint.y = d + Math.floor(Math.random() * (areaHeight - d * 2 - padding * 2));
    circles = [...circles, newPoint];
  }
  return circles;
};
