import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Container, Grid, useMediaQuery, useTheme } from '@mui/material';

import { Typography } from '../../../atoms';
import { ClickableCard } from '../../../moleculas';
import { MobileNotSupported, PageWrapper } from '../../../organisms';
import { SchoolLevels } from '../../../../constants/enums';
import { contentRoute } from '../../../../constants/routes';
import { ReactComponent as ElementarySchoolImg } from '../../../../resources/images/content_elementary_school.svg';
import { ReactComponent as MiddleSchoolImg } from '../../../../resources/images/content_middle_school.svg';
import { ReactComponent as HighSchoolImg } from '../../../../resources/images/content_high_school.svg';

const cardsConfig = [
  {
    level: SchoolLevels.ELEMENTARY,
    image: ElementarySchoolImg,
    title: 'Elementary schools',
  },
  {
    level: SchoolLevels.MIDDLE,
    image: MiddleSchoolImg,
    title: 'Middle schools',
  },
  {
    level: SchoolLevels.HIGH,
    image: HighSchoolImg,
    title: 'High schools',
  },
];

const ContentMainPage = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });

  return (
    <PageWrapper mainElementClassName="ayo-content-main-page">
      {isWidthUpSm ? (
        <Container>
          <Box pb={5}>
            <Typography component="h1" variant="h1">
              {t('Review AYO content for')}
            </Typography>
          </Box>
          <Grid container spacing={3}>
            {cardsConfig.map((config) => {
              const Icon = config.image;
              return (
                <Grid key={config.level} item xs={4}>
                  <ClickableCard
                    href={`${contentRoute}/${config.level.toLowerCase()}`}
                    statusIcon={<Icon />}
                    title={t(config.title)}
                    titleWrap
                  />
                </Grid>
              );
            })}
          </Grid>
        </Container>
      ) : (
        <MobileNotSupported
          explanation={t(
            'Please open the content moderation tool from a tablet or desktop version to use this functionality',
          )}
        />
      )}
    </PageWrapper>
  );
};

export default ContentMainPage;
