import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Box, Grid, Tab } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import dayjs from 'dayjs';

import { ActionsMenu, Hotspot, Link, TabPanel, Tabs, Typography } from '../../../../atoms';
import {
  AccordionClassGroupHeader,
  AccordionItem,
  InitiativeEmptyStateBlock,
  NewFeatureHotspot,
  SearchField,
  TextWithTooltip,
} from '../../../../moleculas';
import {
  capitalize,
  GA,
  getFormattedDate,
  getFullName,
  isCurrentYear,
  sortArrayByKey,
} from '../../../../../utils';
import { GaActions, GaCategories, PublicationStatuses } from '../../../../../constants/enums';
import { myLessonsLessonViewRoute, plugRoute } from '../../../../../constants/routes';
import { lessonTitlePlaceholder } from '../../../../../constants/lesson-planner';
import { NewFeaturesIdsMap } from '../../../../../tours/common/NewFeaturesItemsProvider';
import { loginAllowedFilterRE } from '../../../../../constants/regexps';
import { useLessonPlannerData } from '../../../../../hooks';
import { ReactComponent as LessonsEmptyStateImage } from '../../../../../resources/images/lessons_empty_state.svg';
import { ReactComponent as LockWithCheckmark } from '../../../../../resources/icons/lock_with_checkmark.svg';
import ManageAccessDialog from '../manage-access-dialog/ManageAccessDialog';
import SharedLessonBadge from '../shared-lesson-badge/SharedLessonBadge';
import { personPropShape } from '../../../../../constants/propTypes';

const LessonRow = ({ lessonData, onModalOpen }) => {
  const { i18n, t } = useTranslation();
  const { id, title, status, classData } = lessonData;

  const isSharedLesson = useMemo(() => !!lessonData.owner, [lessonData.owner]);

  const formattedDate = useMemo(() => {
    const date = dayjs(classData.classDate);
    const formatted = getFormattedDate(date.toDate(), i18n.language, {
      day: 'numeric',
      weekday: 'short',
      month: 'short',
    });

    return isCurrentYear(date) ? formatted : `${formatted}, ${date.get('year')}`;
  }, [classData.classDate, i18n.language]);

  const getLessonTitleCellContent = useCallback(
    () => (
      <>
        <Link
          component={RouterLink}
          gaLabel={title}
          to={myLessonsLessonViewRoute.replace(':lessonId', id)}
          underline="none"
        >
          <TextWithTooltip
            className="ayo-lesson-repository__item__link"
            title={title || t(lessonTitlePlaceholder)}
          />
        </Link>
      </>
    ),
    [id, t, title],
  );

  const getLessonStatusCellContent = useCallback(
    () => (
      <Box alignItems="center" display="flex">
        <Hotspot
          className={classNames('ayo-lesson-repository__item__hotspot', {
            'ayo-lesson-repository__item__hotspot--published':
              status === PublicationStatuses.PUBLISHED,
          })}
        />
        <Typography variant="body2">{t(capitalize(PublicationStatuses[status]))}</Typography>
      </Box>
    ),
    [status, t],
  );

  return (
    <Grid className="ayo-lesson-repository__item" container item role="row">
      {isSharedLesson ? (
        <>
          <Grid item pr={1} role="cell" xs={5}>
            {getLessonTitleCellContent()}
          </Grid>
          <Grid item role="cell" xs={3}>
            {getLessonStatusCellContent()}
          </Grid>
          <Grid item role="cell" xs={2}>
            <Typography variant="body2">{getFullName(lessonData.owner)}</Typography>
          </Grid>
          <Grid item role="cell" xs={2}>
            <Typography variant="body2">{formattedDate}</Typography>
          </Grid>
        </>
      ) : (
        <>
          <Grid item pr={1} role="cell" xs={5}>
            <Box alignItems="center" columnGap={3} display="flex">
              {getLessonTitleCellContent()}
              {!!lessonData.sharedWith?.length && (
                <SharedLessonBadge count={lessonData.sharedWith.length} />
              )}
            </Box>
          </Grid>
          <Grid item role="cell" xs={3}>
            {getLessonStatusCellContent()}
          </Grid>
          <Grid item role="cell" xs={2}>
            <Typography variant="body2">{formattedDate}</Typography>
          </Grid>
          <Grid item role="cell" xs={2}>
            <Box
              className="ayo-lesson-repository__item__actions"
              display="flex"
              justifyContent="flex-end"
              maxWidth
            >
              <ActionsMenu
                gaLabel="Action menu"
                id={`lesson-repository-actions--${id}`}
                menuItems={[
                  {
                    text: t('Manage access'),
                    icon: LockWithCheckmark,
                    handler: () => onModalOpen(lessonData.id),
                    id: 'manage access',
                    gaLabel: 'Manage access',
                  },
                ]}
              />
            </Box>
          </Grid>
        </>
      )}
    </Grid>
  );
};

LessonRow.propTypes = {
  lessonData: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    status: PropTypes.string,
    classData: PropTypes.shape({
      className: PropTypes.string,
      classDate: PropTypes.string,
      period: PropTypes.string,
    }),
    owner: personPropShape,
    sharedWith: PropTypes.arrayOf(
      PropTypes.shape({
        ...personPropShape,
        email: PropTypes.string,
      }),
    ),
  }).isRequired,
  onModalOpen: PropTypes.func,
};

LessonRow.defaultProps = {
  onModalOpen: null,
};

const LessonsBlock = ({ courseLessons, isShared, onModalOpen }) =>
  courseLessons.map((lesson) => (
    <LessonRow
      key={`${isShared ? 'shared-' : ''} ${lesson.id}`}
      isShared={isShared}
      lessonData={lesson}
      onModalOpen={onModalOpen}
    />
  ));

LessonsBlock.propTypes = {
  courseLessons: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  isShared: PropTypes.bool,
  onModalOpen: PropTypes.func,
};

LessonsBlock.defaultProps = {
  isShared: false,
  onModalOpen: null,
};

const LessonsPlainListView = ({ searchedLessons, searchValue, onModalOpen }) => (
  <>
    <Box mb={3}>
      <Typography paragraph variant="body2">
        <Trans
          components={{ b: <b /> }}
          i18nKey="search result text"
          values={{ count: searchedLessons.length, searchValue }}
        />
      </Typography>
    </Box>
    {searchedLessons.map((lesson) => (
      <LessonRow key={lesson.id} lessonData={lesson} onModalOpen={onModalOpen} />
    ))}
  </>
);

LessonsPlainListView.propTypes = {
  searchedLessons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      status: PropTypes.string,
      classData: PropTypes.shape({
        className: PropTypes.string,
        classDate: PropTypes.string,
        period: PropTypes.string,
      }),
    }),
  ).isRequired,
  searchValue: PropTypes.string.isRequired,
  onModalOpen: PropTypes.func,
};

LessonsPlainListView.defaultProps = {
  onModalOpen: null,
};

const LessonsNoSearchResultsView = ({ onResetSearch, searchValue }) => {
  const { t } = useTranslation();

  return (
    <>
      <Box maxWidth="67%" mb={3}>
        <Typography paragraph variant="body2">
          <Trans
            components={{ b: <b /> }}
            i18nKey="AYO couldn’t find any results containing searchValue. Please check your spelling and repeat your search"
            values={{ searchValue }}
          />
        </Typography>
      </Box>
      <Link component="button" gaLabel="Reset the search" href={plugRoute} onClick={onResetSearch}>
        {t('Reset the search and show the list of all lessons')}
      </Link>
    </>
  );
};

LessonsNoSearchResultsView.propTypes = {
  onResetSearch: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,
};

const LessonsAccordionView = ({ lessons, isShared, onModalOpen }) => {
  const { t } = useTranslation();

  const groupedLessons = useMemo(() => {
    const grouped = lessons.reduce((acc, current) => {
      const lessonCourses = current.classData.classesPeriod.courses.map(
        ({ className }) => className,
      );

      lessonCourses.forEach((course) => {
        acc[course] = [...(acc[course] || []), current];
      });

      return Object.fromEntries(Object.entries(acc).sort((a, b) => a[0].localeCompare(b[0])));
    }, {});

    Object.values(grouped).forEach((group) => {
      group.sort((a, b) => a.title.localeCompare(b.title));
      sortArrayByKey(group, 'classData[classDate]', 'date');
    });

    return grouped;
  }, [lessons]);

  const getSummaryLabel = useCallback(
    (courseName, lessonsCount) =>
      t('accordion class group header label', {
        context: 'lesson',
        count: lessonsCount,
        courseName,
      }),
    [t],
  );

  const getTypographyEl = useCallback(
    (text) => (
      <Typography isLabel role="columnheader" variant="caption">
        {t(text)}
      </Typography>
    ),
    [t],
  );

  const getLessonNameCell = useCallback(
    () => (
      <Grid item xs={5}>
        {getTypographyEl('Lesson name')}
      </Grid>
    ),
    [getTypographyEl],
  );

  const getLessonStatusCell = useCallback(
    () => (
      <Grid item xs={3}>
        {getTypographyEl('Status')}
      </Grid>
    ),
    [getTypographyEl],
  );

  return (
    <Grid container direction="column" wrap="nowrap">
      {Object.entries(groupedLessons).map(([courseName, courseLessons], index) => (
        <Box key={`${courseName}-${index}`} mb={5}>
          <AccordionItem
            bodyEl={
              isShared ? (
                <Box role="table">
                  <Grid container justifyContent="space-between" px={4} role="row">
                    {getLessonNameCell()}
                    {getLessonStatusCell()}
                    <Grid item xs={2}>
                      {getTypographyEl('Educator')}
                    </Grid>
                    <Grid item xs={2}>
                      {getTypographyEl('Lesson date')}
                    </Grid>
                  </Grid>
                  <LessonsBlock courseLessons={courseLessons} isShared onModalOpen={onModalOpen} />
                </Box>
              ) : (
                <Box role="table">
                  <Grid container justifyContent="space-between" px={4} role="row">
                    {getLessonNameCell()}
                    {getLessonStatusCell()}
                    <Grid item xs={2}>
                      {getTypographyEl('Planner date')}
                    </Grid>
                    <Grid item xs={2}>
                      <Box display="flex" justifyContent="flex-end" maxWidth>
                        {getTypographyEl('Actions')}
                      </Box>
                    </Grid>
                  </Grid>
                  <LessonsBlock courseLessons={courseLessons} onModalOpen={onModalOpen} />
                </Box>
              )
            }
            className="ayo-lesson-repository__accordion"
            gaLabel="Lessons"
            headerEl={
              <Box key={courseName}>
                <AccordionClassGroupHeader count={courseLessons.length} label="lesson">
                  {courseName}
                </AccordionClassGroupHeader>
              </Box>
            }
            isDefaultExpanded
            summaryLabel={getSummaryLabel(courseName, courseLessons.length)}
          />
        </Box>
      ))}
    </Grid>
  );
};

LessonsAccordionView.propTypes = {
  lessons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      status: PropTypes.string,
      classData: PropTypes.shape({
        className: PropTypes.string,
        classDate: PropTypes.string,
        period: PropTypes.string,
      }),
    }),
  ).isRequired,
  isShared: PropTypes.bool,
  onModalOpen: PropTypes.func,
};

LessonsAccordionView.defaultProps = {
  isShared: false,
  onModalOpen: null,
};

const ContentSectionTabs = {
  MY: 'Created by me',
  SHARED: 'Shared with me',
};

const LessonsRepositoryBlock = ({ myLessons, sharedLessons }) => {
  const { t } = useTranslation();

  const { shareLesson } = useLessonPlannerData();

  const [searchValue, setSearchValue] = useState(null);
  const [searchedLessons, setSearchedLessons] = useState(null);
  const [activeContentTab, setActiveContentTab] = useState(ContentSectionTabs.MY);
  const [isManageAccessDialogOpen, setIsManageAccessDialogOpen] = useState(false);
  const [editingLessonId, setEditingLessonId] = useState(null);

  const searchFieldRef = useRef();

  const formattedMyLessons = useMemo(
    () => myLessons.map((lesson) => ({ ...lesson, title: lesson.title || lessonTitlePlaceholder })),
    [myLessons],
  );

  const handleContentTabsChange = useCallback((_, newTab) => {
    GA.logInteraction({
      category: GaCategories.BEHAVIOR,
      action: GaActions.TAB_CLICK,
      label: newTab,
    });

    setActiveContentTab(newTab);
  }, []);

  const handleSearch = useCallback((value, searchIn) => {
    if (value) {
      setSearchValue(value);
      setSearchedLessons(
        searchIn
          .filter((lesson) => lesson.title.toLowerCase().includes(value.toLowerCase()))
          .sort((a, b) => a.title.localeCompare(b.title)),
      );
    } else {
      setSearchValue(null);
      setSearchedLessons(null);
    }
  }, []);

  const resetSearch = useCallback(() => {
    searchFieldRef.current.clearSearch();
  }, []);

  const handleModalOpen = useCallback((lessonId) => {
    setEditingLessonId(lessonId);
    setIsManageAccessDialogOpen(true);
  }, []);

  const handleSave = useCallback(
    (teachersWithAccess, lessonId) => {
      shareLesson(lessonId, teachersWithAccess);
    },
    [shareLesson],
  );

  const getTabPanelContent = useCallback(
    (lessons, isShared = false) =>
      lessons.length ? (
        <>
          <Box alignItems="center" display="flex" flexDirection="row" mb={5}>
            <Box width="50%">
              <SearchField
                apiRef={searchFieldRef}
                fullWidth
                gaLabel="Lesson search"
                inputRE={loginAllowedFilterRE}
                label={t('Search by the lesson name')}
                minInputLength={2}
                onSearch={(value) => handleSearch(value, lessons)}
                searchOnChange
              />
            </Box>
            <NewFeatureHotspot
              dark
              id={NewFeaturesIdsMap.LESSONS_REPOSITORY_SEARCH}
              isClickable
              label={t('Lessons repository search')}
            />
          </Box>
          {searchValue ? (
            <Box>
              {searchedLessons?.length ? (
                <LessonsPlainListView
                  onModalOpen={handleModalOpen}
                  searchedLessons={searchedLessons}
                  searchValue={searchValue}
                />
              ) : (
                <LessonsNoSearchResultsView onResetSearch={resetSearch} searchValue={searchValue} />
              )}
            </Box>
          ) : (
            <LessonsAccordionView
              isShared={isShared}
              lessons={lessons}
              onModalOpen={handleModalOpen}
            />
          )}
          {!isShared && !!editingLessonId && (
            <ManageAccessDialog
              isOpen={isManageAccessDialogOpen}
              lessonData={lessons.find((lesson) => lesson.id === editingLessonId)}
              onClose={() => setIsManageAccessDialogOpen(false)}
              onSave={handleSave}
            />
          )}
        </>
      ) : (
        <InitiativeEmptyStateBlock
          body={t(
            isShared
              ? 'If any lesson is shared with you in AYO, you’ll see it here immediately.'
              : 'Select the planner and start creating your lessons to see them here!',
          )}
          illustration={<LessonsEmptyStateImage />}
          title={t(
            isShared
              ? 'There are no lessons shared with you so far'
              : 'You have no created lessons so far',
          )}
        />
      ),
    [
      editingLessonId,
      handleModalOpen,
      handleSave,
      handleSearch,
      isManageAccessDialogOpen,
      resetSearch,
      searchValue,
      searchedLessons,
      t,
    ],
  );

  return (
    <Box className="ayo-lesson-repository" my={10}>
      <Box alignItems="center" display="flex" mb={2}>
        <Typography variant="h2">{t('My lessons repository')}</Typography>
        <NewFeatureHotspot
          dark
          id={NewFeaturesIdsMap.LESSONS_REPOSITORY}
          isClickable
          label={t('Lessons repository')}
        />
      </Box>
      <Box mb={4}>
        <Typography isLabel paragraph variant="body2">
          <Trans components={{ br: <br /> }} i18nKey="Created lessons paragraph" />
        </Typography>
      </Box>
      <Box mb={5}>
        <Tabs
          className="ayo-lesson-repository__tabs"
          onChange={handleContentTabsChange}
          value={activeContentTab}
          variant="outlined"
        >
          <Tab disableRipple label={t(ContentSectionTabs.MY)} value={ContentSectionTabs.MY} />
          <Tab
            disableRipple
            label={`${t(ContentSectionTabs.SHARED)} (${sharedLessons.length})`}
            value={ContentSectionTabs.SHARED}
          />
        </Tabs>
      </Box>
      <TabPanel currentTabValue={activeContentTab} value={ContentSectionTabs.MY}>
        {getTabPanelContent(formattedMyLessons)}
      </TabPanel>
      <TabPanel currentTabValue={activeContentTab} value={ContentSectionTabs.SHARED}>
        {getTabPanelContent(sharedLessons, true)}
      </TabPanel>
    </Box>
  );
};

LessonsRepositoryBlock.propTypes = {
  myLessons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      status: PropTypes.string,
      classData: PropTypes.shape({
        className: PropTypes.string,
        classDate: PropTypes.string,
        period: PropTypes.string,
      }),
    }),
  ).isRequired,
  sharedLessons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      status: PropTypes.string,
      owner: PropTypes.shape(personPropShape),
      lessonsDate: PropTypes.string,
    }),
  ).isRequired,
};

export default LessonsRepositoryBlock;
