import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { ClickableCard } from '../../../../moleculas';
import { ConfirmDialog } from '../../../../organisms';
import { useLessonPlannerData } from '../../../../../hooks';
import { convertSchoolYearToYear } from '../../../../../utils';
import { semestersMap } from '../../lesson-planner-create/components/basic-settings/components/academic-years/AcademicYears';
import { ReactComponent as CreateIcon } from '../../../../../resources/icons/create.svg';
import { ReactComponent as DeleteIcon } from '../../../../../resources/icons/delete_primary.svg';
import { InputsValidationErrors, InputsValidationRules } from '../../../../../constants/enums';
import RenameDialog from '../rename-dialog/RenameDialog';

const LessonPlannerCard = ({
  id,
  onClick,
  onDelete,
  onRename,
  scheduleName,
  schoolYear,
  semesters,
}) => {
  const { t } = useTranslation();

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isRenameDialogOpen, setIsRenameDialogOpen] = useState(false);
  const [error, setError] = useState(null);
  const [inputValue, setInputValue] = useState('');

  const { isLessonPlannerNameUnique } = useLessonPlannerData();

  useEffect(() => {
    setInputValue(scheduleName);
  }, [scheduleName]);

  const handleNameInputChange = useCallback((e) => {
    setInputValue(e.target.value);
  }, []);

  const handleRenameDialogClose = useCallback(() => {
    setIsRenameDialogOpen(false);
    setInputValue(scheduleName);
    setError(null);
  }, [scheduleName]);

  const handleRename = useCallback(() => {
    const trimmedInput = inputValue.trim();
    const nameExists = !isLessonPlannerNameUnique(trimmedInput, id);
    const hasNameChanged = trimmedInput !== scheduleName;

    switch (true) {
      case trimmedInput.length < InputsValidationRules.MIN_INPUT_LENGTH:
        setError(InputsValidationErrors(t, InputsValidationRules.MIN_INPUT_LENGTH).MIN_ERROR_TEXT);
        break;
      case trimmedInput.length > InputsValidationRules.MAX_TITLE_LENGTH:
        setError(InputsValidationErrors(t, InputsValidationRules.MAX_TITLE_LENGTH).MAX_ERROR_TEXT);
        break;
      case nameExists:
        setError(t('This name already exists, please change it'));
        break;
      case hasNameChanged:
        onRename(id, trimmedInput);
        handleRenameDialogClose();
        break;
      default:
        handleRenameDialogClose();
    }
  }, [
    handleRenameDialogClose,
    id,
    inputValue,
    isLessonPlannerNameUnique,
    onRename,
    scheduleName,
    t,
  ]);

  const semestersFormatted = semesters
    .map((semester) => {
      const semesterLabel = Object.values(semestersMap).find(
        (item) => item.semester === semester,
      )?.label;

      return `${t(semesterLabel)} ${convertSchoolYearToYear(schoolYear, semester)}`;
    })
    .join(', ');

  return (
    <>
      <ClickableCard
        actions={[
          {
            text: t('Rename'),
            icon: CreateIcon,
            handler: () => {
              setIsRenameDialogOpen(true);
            },
            id: 'rename',
            gaLabel: 'Rename',
          },
          {
            text: t('Delete'),
            icon: DeleteIcon,
            handler: () => {
              setIsDeleteDialogOpen(true);
            },
            id: 'delete',
            gaLabel: 'Delete',
          },
        ]}
        className="ayo-lesson-planner-card"
        onClick={() => onClick(id)}
        statusText={semestersFormatted}
        title={scheduleName}
        titleRowsCount={2}
        titleWrap
      />
      <ConfirmDialog
        cancelButtonTitle="Don't delete"
        className="ayo-lesson-planner-card__delete-dialog"
        confirmButtonTitle="Delete planner"
        isOpen={isDeleteDialogOpen}
        onClose={() => {
          setIsDeleteDialogOpen(false);
        }}
        onConfirm={() => {
          onDelete(id);
          setIsDeleteDialogOpen(false);
        }}
        text="AYO won’t be able to restore it again(educator)"
        title="Should we delete your lesson planner?"
      />
      <RenameDialog
        error={error}
        inputValue={inputValue}
        isOpen={isRenameDialogOpen}
        onClose={handleRenameDialogClose}
        onInputChange={handleNameInputChange}
        onRename={handleRename}
      />
    </>
  );
};

LessonPlannerCard.propTypes = {
  id: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onRename: PropTypes.func.isRequired,
  scheduleName: PropTypes.string.isRequired,
  schoolYear: PropTypes.number.isRequired,
  semesters: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default LessonPlannerCard;
