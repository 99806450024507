import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box } from '@mui/material';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Button, Link, Typography } from '../../../../atoms';
import { EditingButton } from '../../../../moleculas';
import { getIsDomElementCropped } from '../../../../../utils';
import { ReactComponent as LinkIcon } from '../../../../../resources/icons/link.svg';
import { ReactComponent as NoDefinitionIllustration } from '../../../../../resources/images/no_definition.svg';
import { ReactComponent as ShowMoreIcon } from '../../../../../resources/icons/chevron_down.svg';
import { ReactComponent as ShowLessIcon } from '../../../../../resources/icons/chevron_up.svg';
import { ReactComponent as ReflectionIcon } from '../../../../../resources/icons/reflection.svg';

const InterestDescriptionPreview = ({ name, definitions, onBackTo, reflection, studentName }) => {
  const { t } = useTranslation();

  const backToInterestsButton = useMemo(
    () => (
      <Button
        className="back-to-interests"
        gaLabel="Back to interests"
        onClick={onBackTo}
        variant="primary"
      >
        {t('Back to interests')}
      </Button>
    ),
    [onBackTo, t],
  );

  const hasDefinitions = useMemo(
    () => definitions?.some((definitionInfo) => definitionInfo.definition),
    [definitions],
  );

  const reflectionRef = useRef();

  const [isReflectionCroppped, setIsReflectionCropped] = useState(true);
  const [isReflectionExpanded, setIsReflectionExpanded] = useState(false);

  useEffect(() => {
    if (reflection) {
      const isCropped = getIsDomElementCropped(
        reflectionRef.current.scrollHeight,
        0,
        reflectionRef.current.clientHeight,
      );
      setIsReflectionCropped(isCropped);
    }
  }, [reflection]);

  return (
    <Box className="definition-container">
      <Box className={classnames('definition-box centered')}>
        <Box className="definition-content-box">
          {hasDefinitions || reflection ? (
            <>
              <Box mb={2}>
                <Typography variant="subtitle1">{name}</Typography>
              </Box>
              {hasDefinitions ? (
                <Box className="definitions-list">
                  {definitions.map(
                    (definitionInfo, i) =>
                      definitionInfo.definition && (
                        <Box key={definitionInfo.name} mb={i === definitions.length - 1 ? 0 : 5}>
                          <Box mb={1}>
                            <Typography variant="subtitle2">
                              {t('What is interestName?', { interestName: definitionInfo.name })}
                            </Typography>
                          </Box>
                          <Box mb={1}>
                            <Typography className="definition-body" variant="body2">
                              {definitionInfo.definition}
                            </Typography>
                          </Box>
                          <Box>
                            <Link
                              className="learn-more-link"
                              gaLabel="Learn more about interest"
                              href={definitionInfo.url}
                              target="_blank"
                            >
                              {t('Learn more at')} Wikipedia
                              <LinkIcon />
                            </Link>
                          </Box>
                        </Box>
                      ),
                  )}
                </Box>
              ) : (
                <Box>
                  <Typography variant="body2">
                    {t('AYO couldn’t find information about this interest')}
                  </Typography>
                </Box>
              )}
              {reflection && (
                <Box mt={hasDefinitions ? 5 : 3}>
                  <Box alignItems="center" display="flex" mb={1}>
                    <Box mr={1}>
                      <ReflectionIcon />
                    </Box>
                    <Typography variant="subtitle2">
                      {t("Student's thoughts about interest", { studentName, interestName: name })}
                    </Typography>
                  </Box>
                  <Box mb={1}>
                    <Typography
                      ref={reflectionRef}
                      className={classnames('reflection', {
                        cropped: isReflectionCroppped && !isReflectionExpanded,
                      })}
                      variant="body2"
                    >
                      {reflection}
                    </Typography>
                  </Box>
                  {isReflectionCroppped && !isReflectionExpanded && (
                    <Box mb={1}>
                      <EditingButton
                        gaLabel="Show more student comment"
                        icon={<ShowMoreIcon className="expand-toggle-icon" />}
                        iconPosition="end"
                        onClick={() => setIsReflectionExpanded(true)}
                        text={t('Show more')}
                      />
                    </Box>
                  )}
                  {isReflectionCroppped && isReflectionExpanded && (
                    <Box mb={1}>
                      <EditingButton
                        gaLabel="Show less student comment"
                        icon={<ShowLessIcon className="expand-toggle-icon" />}
                        iconPosition="end"
                        onClick={() => setIsReflectionExpanded(false)}
                        text={t('Show less')}
                      />
                    </Box>
                  )}
                </Box>
              )}
              <Box mt={4}>{backToInterestsButton}</Box>
            </>
          ) : (
            <>
              <Box mb={3}>
                <Typography variant="subtitle1">{name}</Typography>
              </Box>
              <Box mb={3}>
                <Typography variant="body2">
                  {t('AYO couldn’t find information about this interest')}
                </Typography>
              </Box>
              <NoDefinitionIllustration className="no-definition-illustration" />
              <Box>{backToInterestsButton}</Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

InterestDescriptionPreview.propTypes = {
  name: PropTypes.string.isRequired,
  definitions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string,
      definition: PropTypes.string,
    }),
  ),
  onBackTo: PropTypes.func.isRequired,
  reflection: PropTypes.string,
  studentName: PropTypes.string.isRequired,
};

InterestDescriptionPreview.defaultProps = {
  definitions: null,
  reflection: null,
};

export default InterestDescriptionPreview;
