import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ActionsMenu, TextField, Typography } from '../../../../../../../../../atoms';
import {
  InformationalCaption,
  NewFeatureHotspot,
  PopupActionsButtons,
  STTTextField,
} from '../../../../../../../../../moleculas';
import { ImageFilePreviewWithAltDescription } from '../../../../../../../../../moleculas/image-file-preview/ImageFilePreview';
import { FileUpload } from '../../../../../../../../../organisms';
import { formatUrl, validateStringLength } from '../../../../../../../../../../utils';
import { byteToMegaByteCoeff } from '../../../../../../../../../../constants/values';
import { UserContext } from '../../../../../../../../../../context';
import {
  ClubsPostSharingGroupsKeys,
  FeedPostKeys,
} from '../../../../../../../../../../constants/family-feed';
import {
  FeedPostStatuses,
  InputsValidationErrors,
  InputsValidationRules,
  RolesMap,
} from '../../../../../../../../../../constants/enums';
import { urlAllowedValidationRE } from '../../../../../../../../../../constants/regexps';
import {
  postSliderSettings,
  postsSliderResponsiveConfig,
} from '../../../../../../../../../../constants/carouselSliderConfigs';
import { ReactComponent as UsersIcon } from '../../../../../../../../../../resources/icons/users.svg';
import { ReactComponent as ExpandIcon } from '../../../../../../../../../../resources/icons/chevron_down.svg';
import { ReactComponent as CollapseIcon } from '../../../../../../../../../../resources/icons/chevron_up.svg';
import { NewFeaturesIdsMap } from '../../../../../../../../../../tours/common/NewFeaturesItemsProvider';

const visibilityOptions = {
  [ClubsPostSharingGroupsKeys.MEMBERS]: {
    label: 'Only club members can view',
    gaLabel: 'Only club members can view',
    icon: <UsersIcon />,
    value: ClubsPostSharingGroupsKeys.MEMBERS,
    secondaryText: 'Students who are members of the club will see this post.',
  },
  [ClubsPostSharingGroupsKeys.GUARDIANS]: {
    label: 'Only parents can view',
    gaLabel: 'Only parents can view',
    value: ClubsPostSharingGroupsKeys.GUARDIANS,
    icon: <UsersIcon />,
    secondaryText: 'Club members’ parents will see this post.',
  },
  [ClubsPostSharingGroupsKeys.ALL]: {
    label: 'Club members and parents can view',
    gaLabel: 'Club members and parents can view',
    value: ClubsPostSharingGroupsKeys.ALL,
    icon: <UsersIcon />,
    secondaryText:
      'This post will be published separately for both club members and their parents.',
    skipForEdit: true,
  },
};

const compositeVisibilityValues = {
  [ClubsPostSharingGroupsKeys.ALL]: [
    ClubsPostSharingGroupsKeys.MEMBERS,
    ClubsPostSharingGroupsKeys.GUARDIANS,
  ],
};

const roleToVisibilityMap = {
  [RolesMap.STUDENT]: visibilityOptions[ClubsPostSharingGroupsKeys.MEMBERS],
  [RolesMap.GUARDIAN]: visibilityOptions[ClubsPostSharingGroupsKeys.GUARDIANS],
};

const getFormValidation = (values) => {
  const validation = {
    [FeedPostKeys.TITLE]:
      !values[FeedPostKeys.TITLE] ||
      validateStringLength(
        values[FeedPostKeys.TITLE],
        InputsValidationRules.MAX_TITLE_LENGTH,
        'max',
      ),
    [FeedPostKeys.DESCRIPTION]:
      validateStringLength(
        values[FeedPostKeys.DESCRIPTION],
        InputsValidationRules.MIN_INPUT_LENGTH,
      ) &&
      validateStringLength(
        values[FeedPostKeys.DESCRIPTION],
        InputsValidationRules.MAX_TEXT_AREA_LENGTH,
        'max',
      ),
    [FeedPostKeys.LINK]:
      !values[FeedPostKeys.LINK]?.length ||
      (urlAllowedValidationRE.test(formatUrl(values[FeedPostKeys.LINK])) &&
        formatUrl(values[FeedPostKeys.LINK]).length <= InputsValidationRules.MAX_LINK_INPUT_LENGTH),
  };

  return {
    isFormValid: !Object.values(validation).some((value) => !value),
    minDescriptionLengthError: !validateStringLength(
      values[FeedPostKeys.DESCRIPTION],
      InputsValidationRules.MIN_INPUT_LENGTH,
    ),
    linkError: !validation[FeedPostKeys.LINK],
  };
};

const inputsDefaultData = {
  [FeedPostKeys.TITLE]: '',
  [FeedPostKeys.DESCRIPTION]: '',
  [FeedPostKeys.LINK]: '',
};

const ClubPostForm = ({
  additionalInfo,
  onClose,
  clubId,
  postDataToEdit,
  updatePost,
  addPost,
  isClubOwner,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
  const { state: userState } = useContext(UserContext);

  const { role } = userState.profile;

  const initialPostData = useMemo(() => {
    if (postDataToEdit) {
      return {
        [FeedPostKeys.TITLE]: postDataToEdit[FeedPostKeys.TITLE],
        [FeedPostKeys.DESCRIPTION]: postDataToEdit[FeedPostKeys.DESCRIPTION],
        [FeedPostKeys.LINK]: postDataToEdit[FeedPostKeys.LINK],
      };
    }
    return inputsDefaultData;
  }, [postDataToEdit]);

  const [inputFieldsData, setInputFieldsData] = useState(initialPostData);
  const [dataErrorsMessages, setDataErrorsMessages] = useState({});
  const [attachments, setAttachments] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [altTexts, setAltTexts] = useState({});
  const [removedAttachments, setRemovedAttachments] = useState([]);
  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const [activeVisibilityOption, setActiveVisibilityOption] = useState(
    ClubsPostSharingGroupsKeys.MEMBERS,
  );

  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (postDataToEdit) {
      setActiveVisibilityOption(postDataToEdit.sharingGroup);
      if (postDataToEdit.attachments.length) {
        setUploadedFiles(
          postDataToEdit?.attachments.map((el) => ({
            file: { name: el.fileName, id: el.id, ownerId: el.ownerId, src: el.src },
            errors: [],
          })),
        );
        setAltTexts(
          postDataToEdit?.attachments.reduce(
            (acc, el) => ({ ...acc, [el.fileName]: el?.altText || '' }),
            {},
          ),
        );
      }
    }
    if (!isClubOwner) {
      setActiveVisibilityOption(roleToVisibilityMap[role].value);
    }
  }, [postDataToEdit, role, isClubOwner]);

  const handleTouch = useCallback(() => {
    if (!isActive && !postDataToEdit) {
      setIsActive(() => true);
    }
  }, [isActive, postDataToEdit]);

  const handleAltText = useCallback(
    (altText, file) => {
      const altTextsCopy = { ...altTexts };
      altTextsCopy[file.name] = altText;
      setAltTexts(altTextsCopy);
      if (file.id && !attachments.some((item) => item.name === file.name)) {
        setAttachments((state) => [...state, file]);
      }
    },
    [altTexts, attachments],
  );

  const handleInputChange = useCallback(
    (e) => {
      const { name, value } = e.target;

      setInputFieldsData((state) => ({
        ...state,
        [name]: value,
      }));

      if (dataErrorsMessages[name]) {
        setDataErrorsMessages((state) => ({ ...state, [name]: '' }));
      }
    },
    [dataErrorsMessages],
  );

  const isValid = useCallback(() => {
    const formValidation = getFormValidation({
      ...inputFieldsData,
    });
    const isAltTextsValid = !Object.values(altTexts).some(
      (text) => text.length > InputsValidationRules.MAX_INPUT_LENGTH,
    );

    if (formValidation.minDescriptionLengthError) {
      setDataErrorsMessages((state) => ({
        ...state,
        [FeedPostKeys.DESCRIPTION]: InputsValidationErrors(
          t,
          InputsValidationRules.MIN_INPUT_LENGTH,
        ).MIN_ERROR_TEXT,
      }));
    }
    if (formValidation.linkError) {
      setDataErrorsMessages((state) => ({
        ...state,
        [FeedPostKeys.LINK]: InputsValidationErrors(t).LINK_ERROR_TEXT,
      }));
    }
    return formValidation.isFormValid && isAltTextsValid;
  }, [inputFieldsData, altTexts, t]);

  const handleReset = useCallback(() => {
    if (onClose) {
      onClose();
    }
    setInputFieldsData(inputsDefaultData);
    setAttachments([]);
    setDataErrorsMessages({});
    setAltTexts({});
    setIsActive(() => false);
    setUploadedFiles([]);
    setRemovedAttachments([]);
    setActiveVisibilityOption(ClubsPostSharingGroupsKeys.MEMBERS);
  }, [onClose]);

  const handleEdit = useCallback(() => {
    if (isValid()) {
      const formattedLink =
        inputFieldsData[FeedPostKeys.LINK] && formatUrl(inputFieldsData[FeedPostKeys.LINK]);
      const attachmentsBody = attachments.map((attachment) => {
        if (attachment.id) {
          return { ...attachment, altText: altTexts[attachment.name] };
        }
        return {
          file: attachment,
          altText: altTexts[attachment.name] || null,
        };
      });

      const body = {
        clubId,
        ...inputFieldsData,
        [FeedPostKeys.LINK]: formattedLink,
        id: postDataToEdit?.id,
        status: FeedPostStatuses.EDITED,
        sharingGroup: activeVisibilityOption,
        attachments: attachments.length ? attachmentsBody : null,
        removedAttachments: removedAttachments.length ? removedAttachments : null,
      };
      onClose();
      updatePost(body).then((posted) => {
        if (posted) {
          handleReset();
        }
      });
    }
  }, [
    altTexts,
    clubId,
    isValid,
    inputFieldsData,
    postDataToEdit?.id,
    attachments,
    removedAttachments,
    onClose,
    updatePost,
    handleReset,
    activeVisibilityOption,
  ]);

  const handleCreate = useCallback(() => {
    if (isValid()) {
      const formattedLink =
        inputFieldsData[FeedPostKeys.LINK] && formatUrl(inputFieldsData[FeedPostKeys.LINK]);
      const attachmentsBody = attachments.map((attachment) => ({
        file: attachment,
        altText: altTexts[attachment.name],
      }));
      const body = {
        clubId,
        ...inputFieldsData,
        [FeedPostKeys.LINK]: formattedLink,
        sharingGroups: compositeVisibilityValues[activeVisibilityOption] ?? [
          activeVisibilityOption,
        ],
        status: FeedPostStatuses.NEW,
        attachments: attachments.length ? attachmentsBody : null,
      };

      addPost(body).then((isPosted) => {
        if (isPosted) {
          handleReset();
        }
      });
    }
  }, [
    isValid,
    inputFieldsData,
    attachments,
    addPost,
    handleReset,
    clubId,
    activeVisibilityOption,
    altTexts,
  ]);

  const editModeOptionsFilter = (option) => !option.skipForEdit;

  return (
    <Box className="ayo-feed-post">
      <Box className="ayo-feed-post__form">
        <Box alignItems="center" display="flex" mb={1}>
          <Typography component={postDataToEdit ? 'h3' : 'h4'} variant="subtitle2">
            {t('Post details')}
          </Typography>
          <NewFeatureHotspot
            id={NewFeaturesIdsMap.POST_MULTIPLE_IMAGES}
            isClickable
            label={t('nft.post_multiple_images.title')}
          />
        </Box>
        <Box className="ayo-feed-post__form__inputs">
          <STTTextField
            error={!!dataErrorsMessages[FeedPostKeys.TITLE]}
            fullWidth
            gaLabel="Post title"
            helperText={dataErrorsMessages[FeedPostKeys.TITLE]}
            label={t('Title')}
            maxLength={InputsValidationRules.MAX_TITLE_LENGTH}
            name={FeedPostKeys.TITLE}
            onChange={handleInputChange}
            onFocus={handleTouch}
            outlined
            value={inputFieldsData[FeedPostKeys.TITLE]}
          />
          <STTTextField
            error={dataErrorsMessages[FeedPostKeys.DESCRIPTION]}
            fullWidth
            gaLabel="Post text"
            helperText={dataErrorsMessages[FeedPostKeys.DESCRIPTION]}
            label={t('Post text')}
            maxLength={InputsValidationRules.MAX_TEXT_AREA_LENGTH}
            minRows={3}
            multiline
            name={FeedPostKeys.DESCRIPTION}
            onChange={handleInputChange}
            onFocus={handleTouch}
            outlined
            required
            value={inputFieldsData[FeedPostKeys.DESCRIPTION]}
          />
          <TextField
            error={!!dataErrorsMessages[FeedPostKeys.LINK]}
            fullWidth
            gaLabel="Link input"
            helperText={dataErrorsMessages[FeedPostKeys.LINK]}
            InputLabelProps={{
              id: 'club-hub-post-link',
            }}
            inputProps={{ 'aria-labelledby': 'club-hub-post-link', autoComplete: 'off' }}
            label={t('Link')}
            name={FeedPostKeys.LINK}
            onChange={handleInputChange}
            onFocus={handleTouch}
            outlined
            value={inputFieldsData[FeedPostKeys.LINK]}
          />
        </Box>
        <FileUpload
          allowedExtensions={['jpg', 'jpeg', 'png']}
          FileThumbnail={ImageFilePreviewWithAltDescription}
          FileThumbnailProps={{
            onAltChange: handleAltText,
            inputFieldName: FeedPostKeys.ALT_TEXT,
            altTexts,
          }}
          label={t('Add image(s)')}
          maxAttachmentsAllowed={6}
          maxFileSize={10 * byteToMegaByteCoeff}
          onFileAdd={(newFile) => {
            handleTouch();
            setAttachments((prevState) => [...prevState, newFile]);
          }}
          onFileRemove={(file) => {
            if (file.id) {
              setRemovedAttachments((state) => [
                ...state,
                postDataToEdit.attachments.find((el) => el.id === file.id),
              ]);
            }
            setAttachments((state) => [...state.filter((x) => x !== file)]);
          }}
          sliderResponsiveConfig={postsSliderResponsiveConfig}
          sliderSettings={postSliderSettings}
          uploadedFiles={uploadedFiles}
        />
        {(isActive || postDataToEdit) && (
          <Box>
            <Box mb={1} mt={3}>
              <Typography component={postDataToEdit ? 'h3' : 'h4'} variant="subtitle2">
                {t('Post visibility')}
              </Typography>
            </Box>
            {isClubOwner ? (
              <ActionsMenu
                activatorIcon={null}
                activatorProps={{
                  ariaLabel: t(visibilityOptions[activeVisibilityOption].label),
                }}
                activatorVariant="text-primary"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                gaLabel="Post visibility"
                id={1}
                label={
                  <Box alignItems="center" display="flex">
                    <UsersIcon />
                    <Box mx={1}>{t(visibilityOptions[activeVisibilityOption].label)}</Box>
                    {isPopperOpen ? <CollapseIcon /> : <ExpandIcon />}
                  </Box>
                }
                menuItems={Object.values(visibilityOptions)
                  .filter(postDataToEdit ? editModeOptionsFilter : Boolean)
                  .map((option) => ({
                    id: option.value,
                    ariaLabel: option.label,
                    selected: option.value === activeVisibilityOption,
                    content: (
                      <Box display="flex">
                        {option.icon}
                        <Box ml={1}>
                          <Typography variant="subtitle2">{t(option.label)}</Typography>
                          <Typography
                            className="ayo-feed-post__form__drodown-items"
                            variant="caption"
                          >
                            {t(option.secondaryText)}
                          </Typography>
                        </Box>
                      </Box>
                    ),
                    handler: () => setActiveVisibilityOption(option.value),
                    gaLabel: option.gaLabel,
                  }))}
                noIconsRecolor
                onClickHandler={() => setIsPopperOpen(true)}
                onCloseHandler={() => setIsPopperOpen(false)}
                paperClassName="ayo-feed-post__form__inputs__menu"
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              />
            ) : (
              <Box alignItems="center" display="flex">
                <UsersIcon />
                <Box mx={1}>
                  <Typography variant="subtitle2">{t(roleToVisibilityMap[role].label)}</Typography>
                </Box>
                <InformationalCaption
                  showTooltip
                  title={t(roleToVisibilityMap[role].secondaryText)}
                />
              </Box>
            )}
            {additionalInfo}
          </Box>
        )}
      </Box>
      {(isActive || postDataToEdit) && (
        <Box maxWidth={isWidthUpSm && !postDataToEdit ? '60%' : '100%'} mt={5}>
          <PopupActionsButtons
            primaryButtonGaLabel={postDataToEdit ? 'Save changes' : 'Post'}
            primaryButtonHandler={postDataToEdit ? handleEdit : handleCreate}
            primaryButtonText={postDataToEdit ? t('Save changes') : t('Post')}
            secondaryButtonGaLabel={postDataToEdit ? 'Cancel' : 'Discard'}
            secondaryButtonHandler={handleReset}
            secondaryButtonText={postDataToEdit ? t('Cancel') : t('Discard')}
          />
        </Box>
      )}
    </Box>
  );
};

ClubPostForm.propTypes = {
  additionalInfo: PropTypes.node,
  onClose: PropTypes.func,
  updatePost: PropTypes.func.isRequired,
  addPost: PropTypes.func.isRequired,
  clubId: PropTypes.number.isRequired,
  isClubOwner: PropTypes.bool.isRequired,
  postDataToEdit: PropTypes.shape({
    attachments: PropTypes.arrayOf(
      PropTypes.shape({
        fileName: PropTypes.string,
        id: PropTypes.number,
        ownerId: PropTypes.number,
        updatedDate: PropTypes.string,
      }),
    ),
    createdDate: PropTypes.string,
    sharingGroup: PropTypes.string,
    description: PropTypes.string,
    id: PropTypes.number,
    sharingLevel: PropTypes.string,
    title: PropTypes.string,
  }),
};

ClubPostForm.defaultProps = {
  additionalInfo: null,
  onClose: null,
  postDataToEdit: null,
};

export default ClubPostForm;
