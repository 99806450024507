import React from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { Trans } from 'react-i18next';

import { Card, Link, Typography } from '../../../atoms';
import { mainYoutubeRoute } from '../../../../constants/routes';
import { ReactComponent as LinkIcon } from '../../../../resources/icons/link.svg';
import { ReactComponent as YouTubeLogo } from '../../../../resources/images/youtube.svg';

const YoutubeLinkButton = () => {
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Box display="flex" justifyContent="center" px={2}>
      <Box className="ayo-youtube-link-button">
        <Link gaLabel="YouTube link" href={mainYoutubeRoute} target="_blank">
          <Card
            className="ayo-clickable-card"
            component="button"
            mainContent={
              <Box display="flex" flexDirection="row">
                <Box
                  alignItems="flex-start"
                  display="flex"
                  flexDirection={isWidthUpSm ? 'row' : 'column'}
                >
                  <Box pt={0.5}>
                    <YouTubeLogo className="ayo-youtube-link-button__logo" />
                  </Box>
                  <Box mt={isWidthUpSm ? 0 : 3} mx={isWidthUpSm ? 3 : 0}>
                    <Typography align="left" variant="body2">
                      <Trans
                        components={{ b: <b /> }}
                        i18nKey="AYO is on YouTube! Go and check out the content that we prepared for you!"
                      />
                    </Typography>
                  </Box>
                </Box>
                <Box className="ayo-youtube__link-icon">
                  <LinkIcon />
                </Box>
              </Box>
            }
          />
        </Link>
      </Box>
    </Box>
  );
};

export default YoutubeLinkButton;
