import { useCallback } from 'react';

import useAxios, { StatusCodeMap } from '../HttpClient';
import { AdminActionsTypesMap } from '../../constants/enums';
import {
  apiRoute,
  adminRoute,
  adminUserDataRoute,
  privilegesRoute,
  usersRoute,
} from '../../constants/routes';

const useAdminService = () => {
  const { get, post, put } = useAxios();

  const getAdminUsersList = useCallback(
    async ({ page, pageSize, privileges, search, usersRole }) => {
      const { data } = await get(
        { enabled: true },
        `${apiRoute}${adminRoute}${usersRoute}?page=${page}&pageSize=${pageSize}${
          privileges?.length > 0 ? `&privileges=${privileges.join(',')}` : ''
        }&role=${usersRole}${search ? `&search=${search}` : ''}`,
      );
      return data;
    },
    [get],
  );

  const requestDataChange = useCallback(
    (action, userEmail, userLogin, userRole) =>
      post(
        { enabled: true },
        `${apiRoute}${adminUserDataRoute}`,
        {
          action,
          role: userRole.toUpperCase(),
          userEmail,
          userLogin,
        },
        {
          skipDefaultErrorHandlers: [StatusCodeMap.BAD_REQUEST, StatusCodeMap.SERVER_ERROR],
        },
      ),
    [post],
  );

  const requestDataDeletion = useCallback(
    (userEmail, userLogin, userRole) =>
      requestDataChange(AdminActionsTypesMap.DELETE, userEmail, userLogin, userRole),
    [requestDataChange],
  );

  const requestDataExport = useCallback(
    (userEmail, userLogin, userRole) =>
      requestDataChange(AdminActionsTypesMap.EXPORT, userEmail, userLogin, userRole),
    [requestDataChange],
  );

  const getExportData = useCallback(
    (jobId) =>
      get({ enabled: true }, `${apiRoute}${adminUserDataRoute}/${jobId}`, {
        skipDefaultErrorHandlers: [
          StatusCodeMap.UNAUTHORIZED,
          StatusCodeMap.NOT_FOUND,
          StatusCodeMap.BAD_REQUEST,
        ],
      }),
    [get],
  );

  const putUserPrivileges = useCallback(
    (userId, privileges) =>
      put({ enabled: true }, `${apiRoute}${privilegesRoute}`, {
        userId,
        privileges,
      }),
    [put],
  );

  return {
    getAdminUsersList,
    getExportData,
    putUserPrivileges,
    requestDataDeletion,
    requestDataExport,
  };
};

export default useAdminService;
