import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Snackbar } from '@mui/material';

import { Button, Checkbox, FormControlLabel, Typography } from '../../../atoms';
import { FeedbackFunctionalityKeysMap, KeyboardMap } from '../../../../constants/enums';
import { AppContext } from '../../../../context';
import { useFeedbackService } from '../../../../services';
import { ReactComponent as CloseIcon } from '../../../../resources/icons/close.svg';
import { ReactComponent as DislikeIcon } from '../../../../resources/icons/dislike.svg';
import { ReactComponent as LikeIcon } from '../../../../resources/icons/like.svg';
import { ReactComponent as SuccessImage } from '../../../../resources/images/feedback_thank_you.svg';
import { ReactComponent as DarkSuccessImage } from '../../../../resources/images/feedback_thank_you_dark.svg';

import ScaleRatingSlider from './components/scale-rating-slider/ScaleRatingSlider';

const FeedbackStatusesMap = {
  COMPLETED: 'COMPLETED',
  NOT_SHOWN: 'NOT_SHOWN',
};

const FeedbackPopup = ({
  CustomMainContent,
  feedbackApiRef,
  functionalityKey,
  isAutoShow,
  title,
  type,
  variant,
}) => {
  const { t } = useTranslation();

  const { state: appState } = useContext(AppContext);

  const [isOpen, setIsOpen] = useState(false);

  const [isFeedbackSent, setIsFeedbackSent] = useState(false);

  const feedbackFormObjRef = useRef();

  const { getFunctionalityFeedback, postFunctionalityFeedback } = useFeedbackService();

  const handlePopupOpen = useCallback(
    (response) => {
      if (!response && sessionStorage.getItem(functionalityKey) !== 'true') {
        setIsOpen(true);
        sessionStorage.setItem(functionalityKey, true);
        setTimeout(() => {
          document.querySelector('.ayo-feedback-popup__close')?.focus();
        }, 0);
      }
    },
    [functionalityKey],
  );

  useEffect(() => {
    if (functionalityKey) {
      getFunctionalityFeedback(functionalityKey).then((response) => {
        if (isAutoShow) {
          handlePopupOpen(response);
        } else {
          // eslint-disable-next-line no-param-reassign
          feedbackApiRef.current = {
            openPopup: () => handlePopupOpen(response),
          };
        }
      });
      feedbackFormObjRef.current = { key: functionalityKey };
    }
  }, [feedbackApiRef, functionalityKey, getFunctionalityFeedback, handlePopupOpen, isAutoShow]);

  const resetFeedbackFormObjProperty = (property) => {
    feedbackFormObjRef.current[property] = null;
    delete feedbackFormObjRef.current[property];
  };

  const handleValueChange = (e, newValue) => {
    feedbackFormObjRef.current.reactionValue = newValue;
  };

  const handleStatusChange = (e) => {
    if (e.target.checked) {
      feedbackFormObjRef.current.status = FeedbackStatusesMap.NOT_SHOWN;
    } else {
      resetFeedbackFormObjProperty('status');
    }
  };

  const sendFeedback = useCallback(
    (onFeedbackSent) => {
      postFunctionalityFeedback(feedbackFormObjRef.current, t('Sending your feedback')).then(() => {
        if (onFeedbackSent) {
          onFeedbackSent();
        }
      });
    },
    [postFunctionalityFeedback, t],
  );

  const handleSubmitButtonClick = useCallback(() => {
    feedbackFormObjRef.current.status = FeedbackStatusesMap.COMPLETED;
    feedbackFormObjRef.current.reactionValue = feedbackFormObjRef.current.reactionValue ?? 0;
    sendFeedback(() => {
      setIsFeedbackSent(true);
      setTimeout(() => {
        setIsOpen(false);
      }, 4000);
    });
  }, [sendFeedback]);

  const handleCloseButtonClick = useCallback(() => {
    resetFeedbackFormObjProperty('reactionValue');
    if (feedbackFormObjRef.current.status === FeedbackStatusesMap.NOT_SHOWN) {
      sendFeedback();
    }
    setIsOpen(false);
  }, [sendFeedback]);

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === KeyboardMap.ESCAPE) {
        handleCloseButtonClick();
      }
    },
    [handleCloseButtonClick],
  );

  const mainContent = useMemo(
    () => ({
      slider: <ScaleRatingSlider onChange={handleValueChange} />,
    }),
    [],
  );

  const actionsContent = useMemo(
    () => ({
      slider: (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button
              fullWidth
              gaLabel={`${functionalityKey} - Cancel`}
              onClick={handleCloseButtonClick}
            >
              {t('Cancel')}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              gaLabel={`${functionalityKey} - I’m done`}
              onClick={handleSubmitButtonClick}
              variant="primary"
            >
              {t('I’m done')}
            </Button>
          </Grid>
        </Grid>
      ),
      yesno: (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button
              endIcon={<LikeIcon />}
              fullWidth
              gaLabel={`${functionalityKey} - Yes`}
              onClick={(e) => {
                handleValueChange(e, '1');
                handleSubmitButtonClick();
              }}
            >
              {t('Yes')}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              endIcon={<DislikeIcon />}
              fullWidth
              gaLabel={`${functionalityKey} - No`}
              onClick={(e) => {
                handleValueChange(e, '0');
                handleSubmitButtonClick();
              }}
            >
              {t('No')}
            </Button>
          </Grid>
        </Grid>
      ),
    }),
    [functionalityKey, handleCloseButtonClick, handleSubmitButtonClick, t],
  );

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      className={classnames('ayo-feedback-popup', {
        'ayo-feedback-popup--success': isFeedbackSent,
        'ayo-feedback-popup--dark': variant === 'dark',
      })}
      ContentProps={{ role: 'dialog' }}
      message={
        isFeedbackSent ? (
          <>
            <Box pb={2.5}>
              <Typography align="center" role="alert" variant="subtitle2">
                {t('Thank you for your feedback!')}
              </Typography>
            </Box>
            {variant === 'dark' ? <DarkSuccessImage /> : <SuccessImage />}
          </>
        ) : (
          <>
            <Button
              aria-label={t('Close')}
              className="ayo-feedback-popup__close"
              gaLabel={`${functionalityKey} - Feedback popup close`}
              isIconButton
              onClick={handleCloseButtonClick}
            >
              <CloseIcon height="24" width="24" />
            </Button>
            <Box pb={2.5}>
              <Typography component="h2" variant="subtitle2">
                {title}
              </Typography>
            </Box>
            <Box py={2.5}>{mainContent[type] || CustomMainContent}</Box>
            <Box py={2.5}>
              <FormControlLabel
                control={
                  <Checkbox
                    gaLabel={`${functionalityKey} - Don’t show this again`}
                    onChange={handleStatusChange}
                  />
                }
                label={t('Don’t show this again')}
              />
            </Box>
            <Box pt={2.5}>{actionsContent[type]}</Box>
          </>
        )
      }
      onKeyDown={handleKeyDown}
      open={isOpen}
      style={{ transform: `translate(-50%, ${appState.snackbarStatus ? '-96px' : '0'})` }}
    />
  );
};

FeedbackPopup.propTypes = {
  CustomMainContent: PropTypes.node,
  feedbackApiRef: PropTypes.shape({ current: PropTypes.instanceOf(Object) }),
  functionalityKey: PropTypes.oneOf([
    FeedbackFunctionalityKeysMap.GREEN_RIBBONS,
    FeedbackFunctionalityKeysMap.INTERESTS_UNIVERSE,
    FeedbackFunctionalityKeysMap.MY_GOALS,
  ]).isRequired,
  isAutoShow: PropTypes.bool,
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['slider', 'yesno']).isRequired,
  variant: PropTypes.oneOf(['dark', 'light']),
};

FeedbackPopup.defaultProps = {
  CustomMainContent: null,
  feedbackApiRef: null,
  isAutoShow: false,
  variant: 'light',
};

export default FeedbackPopup;
