/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { RichText } from '../../moleculas';
import { Typography } from '../../atoms';
import { articleBlock } from '../../../constants/propTypes';

const ArticleBlock = ({ config }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpMd = useMediaQuery(theme.breakpoints.up('md'));

  const textItems = [];

  config.textItems.forEach((item) => {
    textItems.push(
      <Box mb={isWidthUpMd ? 3 : 2}>
        <RichText
          align={item.align ? item.align : ''}
          className={item.className ? item.className : ''}
          items={item.textNodes}
          variant={item.variant ? item.variant : ''}
        />
      </Box>,
    );
  });

  return (
    <Grid item md={6} xs={12}>
      <Box pb={isWidthUpMd ? 16 : 2} pt={isWidthUpMd ? 16 : 5}>
        <Box mb={isWidthUpMd ? 3 : 2}>
          <Typography variant="h1">{t(config.title)}</Typography>
        </Box>
        {textItems}
      </Box>
    </Grid>
  );
};

ArticleBlock.propTypes = {
  config: articleBlock,
};

ArticleBlock.defaultProps = {
  config: {},
};

export default ArticleBlock;
