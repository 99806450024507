import React, { useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import { staticAssignmentType } from '../../../../../../constants/propTypes';
import DisplayAssignmentDeadlines from '../../display-components/display-assignment-deadlines/DisplayAssignmentDeadlines';
import DisplayAssignmentInstruction from '../../display-components/display-assignment-instruction/DisplayAssignmentInstruction';
import DisplayAssignmentMaterials from '../../display-components/display-assignment-materials/DisplayAssignmentMaterials';
import DisplayAssignmentStudents from '../../display-components/display-assignment-students/DisplayAssignmentStudents';
import DisplayAssignmentTitle from '../../display-components/display-assignment-title/DisplayAssignmentTitle';
import GoogleClassroomLink from '../../display-components/google-classroom-link/GoogleClassroomLink';
import DisplayAssignmentOptions from '../../display-components/display-assignment-options/DisplayAssignmentOptions';
import DisplayAssignmentChoicesPath from '../../display-components/display-assignment-choices-path/DisplayAssignmentChoicesPath';
import { assignmentHash } from '../../../../../../constants/routes';

const BaseStaticAssignment = ({
  assignment,
  courseStudents,
  datesCreator,
  choices,
  isOwner,
  onChoicesChange,
  onChoicesSelectedChange,
  canSelectChoices,
  hideAssignedStudents,
}) => {
  const assignmentDates = datesCreator().map((dateConfig) => ({
    title: dateConfig.title,
    value: assignment[dateConfig.field],
  }));

  const areDatesShown = assignmentDates.some((date) => date.value);

  const { hash } = useLocation();

  useEffect(() => {
    if (hash?.includes(assignmentHash)) {
      const assignmentElement = document.getElementById(`${hash.replace('#', '')}`);
      if (assignmentElement) {
        assignmentElement.scrollIntoView();
      }
    }
  }, [hash]);

  return (
    <Grid container id={`${assignmentHash.replace('#', '')}-${assignment.id}`}>
      <Grid item xs={12}>
        <Grid item xs={8}>
          <Box mb={1}>
            <DisplayAssignmentTitle title={assignment.title} />
          </Box>
          <Box mb={3}>
            <GoogleClassroomLink href={assignment.classroomLink} />
          </Box>
          {areDatesShown && (
            <Box mb={3}>
              <DisplayAssignmentDeadlines
                deadlines={datesCreator().map((dateConfig) => ({
                  title: dateConfig.title,
                  value: assignment[dateConfig.field],
                }))}
              />
            </Box>
          )}
        </Grid>
      </Grid>
      <Grid container direction="row" item justifyContent="space-between" xs={12}>
        <Grid item xs={7}>
          {assignment.description && (
            <Box mb={3}>
              <DisplayAssignmentInstruction instruction={assignment.description} />
            </Box>
          )}
          {!!assignment.questionChoices?.filter(Boolean).length && (
            <Box mb={3}>
              <DisplayAssignmentOptions options={assignment.questionChoices} />
            </Box>
          )}
          {!hideAssignedStudents && (
            <Box mb={5}>
              <DisplayAssignmentStudents
                assignedCount={assignment.assignedStudents}
                isOwner={isOwner}
                students={courseStudents?.filter((student) =>
                  assignment.assignedStudentsEmails.includes(student.email),
                )}
              />
            </Box>
          )}
        </Grid>
        <Grid item xs={4}>
          <DisplayAssignmentMaterials
            attachments={assignment.attachments}
            links={assignment.links}
          />
        </Grid>
        <Grid xs={12}>
          <DisplayAssignmentChoicesPath
            assignmentEndDate={assignment.submissionDeadline}
            assignmentId={assignment.id}
            canSelectChoices={canSelectChoices}
            choices={choices}
            onChoicesChange={onChoicesChange}
            onChoicesSelectedChange={onChoicesSelectedChange}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

BaseStaticAssignment.propTypes = {
  ...staticAssignmentType,
  datesCreator: PropTypes.func.isRequired,
};

export default BaseStaticAssignment;
