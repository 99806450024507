import React, { useCallback, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { ActionsMenu } from '../../atoms';
import { useLessonPlannerData } from '../../../hooks';
import { ReactComponent as DayOffIcon } from '../../../resources/icons/day_off.svg';
import { ReactComponent as CreateIcon } from '../../../resources/icons/create.svg';
import AdjustScheduleDialog from '../adjust-schedule-dialog/AdjustScheduleDialog';
import { ConfirmDialog } from '../../organisms';

const CalendarDayOptions = ({ day, scheduleId }) => {
  const { t } = useTranslation();
  const { isDayOff, classes, classDate } = day;
  const {
    defaultPeriodsData,
    getScheduleAvailablePeriods,
    reorderLessonPlanner,
    markAsDayOffOrWorkingDay,
    filterPeriodsByDate,
  } = useLessonPlannerData();

  const [isAdjustScheduleDialogOpen, setIsAdjustScheduleDialogOpen] = useState(false);

  const handleMarkAsDayOff = useCallback(
    (dayOff) => {
      const newDay = {
        classDate,
        isDayOff: !!dayOff,
      };

      if (dayOff) {
        newDay.classes = [];
        markAsDayOffOrWorkingDay(scheduleId, newDay);
      } else {
        getScheduleAvailablePeriods().then((periods) => {
          newDay.classes = defaultPeriodsData(filterPeriodsByDate(periods, classDate, classDate));
          markAsDayOffOrWorkingDay(scheduleId, newDay);
        });
      }
    },
    [
      classDate,
      defaultPeriodsData,
      getScheduleAvailablePeriods,
      markAsDayOffOrWorkingDay,
      scheduleId,
      filterPeriodsByDate,
    ],
  );

  const handleClassesOrderChange = useCallback(
    (newClasses) => {
      const newDay = {
        classDate,
        classes: newClasses ?? classes,
        isDayOff: false,
      };

      reorderLessonPlanner(scheduleId, newDay).then(() => {
        getScheduleAvailablePeriods(true);
      });
      setIsAdjustScheduleDialogOpen(false);
    },
    [classes, classDate, scheduleId, reorderLessonPlanner, getScheduleAvailablePeriods],
  );

  const hasClasses = day.classes?.some((classData) => classData.lessonsMetadata.length);

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

  const actions = useMemo(
    () =>
      [
        {
          text: t(!isDayOff ? 'Mark as a day off' : 'Mark as a working day'),
          icon: DayOffIcon,
          handler: () => {
            if (hasClasses) {
              setIsConfirmDialogOpen(true);
            } else {
              handleMarkAsDayOff(!isDayOff);
            }
          },
          id: !isDayOff ? 'day off' : 'working day',
          gaLabel: !isDayOff ? 'Mark as a day off' : 'Mark as a working day',
        },
        !isDayOff && {
          text: t('Adjust schedule for this day'),
          icon: CreateIcon,
          handler: () => {
            setIsAdjustScheduleDialogOpen(true);
          },
          id: 'adjust',
          gaLabel: 'Adjust schedule for this day',
        },
      ].filter(Boolean),
    [handleMarkAsDayOff, isDayOff, t, hasClasses],
  );

  return (
    <Box className="ayo-day-lesson-planner-day-options">
      <ActionsMenu gaLabel="Day actions menu" id="day-options" menuItems={actions} />
      <AdjustScheduleDialog
        classDate={classDate}
        classes={classes}
        isOpen={isAdjustScheduleDialogOpen}
        onClose={() => {
          setIsAdjustScheduleDialogOpen(false);
        }}
        onSave={handleClassesOrderChange}
      />
      <ConfirmDialog
        cancelButtonTitle="Cancel"
        confirmButtonTitle="Mark as a day off"
        isOpen={isConfirmDialogOpen}
        onCancel={() => setIsConfirmDialogOpen(false)}
        onClose={() => setIsConfirmDialogOpen(false)}
        onConfirm={() => {
          handleMarkAsDayOff(!isDayOff);
          setIsConfirmDialogOpen(false);
        }}
        text="You have lessons scheduled for this date. Please save your content outside AYO, as it’ll be deleted from here when you mark the day as a day off."
        title="Before you mark this day as a day off..."
      />
    </Box>
  );
};

CalendarDayOptions.propTypes = {
  day: PropTypes.shape({
    classDate: PropTypes.instanceOf(Date),
    isDayOff: PropTypes.bool,
    classes: PropTypes.arrayOf(
      PropTypes.shape({
        className: PropTypes.string,
        orderNumber: PropTypes.number,
        period: PropTypes.number,
      }),
    ),
  }).isRequired,
  scheduleId: PropTypes.number.isRequired,
};

export default CalendarDayOptions;
