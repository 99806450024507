import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { Alert, Typography } from '../../atoms';
import { UserContext } from '../../../context';
import { getFullDateAndHoursString, getFullName } from '../../../utils';
import { AlertsStates } from '../../../constants/enums';
import { ReactComponent as RedFlagIcon } from '../../../resources/icons/red_flag.svg';

const InterventionAlert = ({ studentName, alertDetails, translationKey, action }) => {
  const { i18n } = useTranslation();
  const { state: userState } = useContext(UserContext);

  return (
    <Box className="ayo-intervention-alert">
      <Alert
        action={action}
        className={classnames({
          acknowledged:
            alertDetails.state === AlertsStates.ACKNOWLEDGED &&
            userState.profile.id === alertDetails.metadata?.stateUpdateInitiator?.id,
        })}
        icon={<RedFlagIcon />}
        open
        title={
          <Typography variant="caption">
            <Trans
              components={{ b: <b /> }}
              i18nKey={`${translationKey}`}
              values={{
                studentName,
                stateUpdateDate: getFullDateAndHoursString(
                  alertDetails.stateUpdateDate,
                  i18n.language,
                ),
                requestDate: getFullDateAndHoursString(alertDetails.requestDate, i18n.language),
                educatorName: getFullName(alertDetails.metadata?.stateUpdateInitiator),
              }}
            />
          </Typography>
        }
        type="warning"
      />
    </Box>
  );
};

InterventionAlert.propTypes = {
  action: PropTypes.node,
  alertDetails: PropTypes.instanceOf(Object).isRequired,
  studentName: PropTypes.string,
  translationKey: PropTypes.string.isRequired,
};

InterventionAlert.defaultProps = {
  action: null,
  studentName: '',
};

export default InterventionAlert;
