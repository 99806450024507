import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

import { Typography } from '../../../../atoms';
import { STTTextField } from '../../../../moleculas';
import { InputsValidationRules } from '../../../../../constants/enums';
import { ClubRequirementsKeys } from '../../../../../constants/club-hub';
import ClubLinks from '../club-links/ClubLinks';

const ClubRequirements = ({ description, error, label, links, onChange }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography mb={2} variant="subtitle2">
        {`${t('Text')}*`}
      </Typography>
      <STTTextField
        error={!!error}
        fullWidth
        gaLabel="Club requirements description"
        helperText={error}
        label={label}
        maxLength={InputsValidationRules.MAX_INPUT_LENGTH}
        multiline
        name={ClubRequirementsKeys.DESCRIPTION}
        onChange={(e) => onChange({ [ClubRequirementsKeys.DESCRIPTION]: e.target.value })}
        outlined
        required
        rowsCount={4}
        value={description}
      />
      <Typography mb={2} mt={3} variant="subtitle2">
        {t('Links')}
      </Typography>
      <ClubLinks
        clubValueKey={ClubRequirementsKeys.LINKS}
        gaLabel="Requirements link"
        links={links}
        onChange={onChange}
      />
    </Box>
  );
};

ClubRequirements.propTypes = {
  description: PropTypes.string.isRequired,
  error: PropTypes.string,
  label: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
};

ClubRequirements.defaultProps = {
  error: null,
};

export default ClubRequirements;
