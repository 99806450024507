import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Fab } from '@mui/material';

import { GaActions, GaCategories } from '../../../constants/enums';
import { GA } from '../../../utils';
import { ReactComponent as CollapseIcon } from '../../../resources/icons/chevron_up.svg';

const ScrollToTopButton = ({ selectorToFocus, navigationAnchor }) => {
  const { t } = useTranslation();

  const [isButtonVisible, setIsButtonVisible] = useState(false);

  const showButton = useCallback(() => setIsButtonVisible(window.pageYOffset > 0), []);

  const onClickHandler = useCallback(() => {
    GA.logInteraction({
      category: GaCategories.BEHAVIOR,
      action: GaActions.BUTTON_CLICK,
      label: 'Scroll to top',
    });
    if (navigationAnchor) {
      // react router history does not properly handle navigating from anchor link to same anchor link
      // using link creation method to properly handle this case
      const anchorLink = document.createElement('a');
      anchorLink.href = `#${navigationAnchor}`;
      anchorLink.click();
    } else {
      window.scrollTo(0, 0);
      document.querySelector(selectorToFocus)?.focus();
    }
  }, [navigationAnchor, selectorToFocus]);

  useEffect(() => {
    window.addEventListener('scroll', showButton);
    return () => {
      window.removeEventListener('scroll', showButton);
    };
  }, [showButton]);

  return (
    <Box className={classNames('ayo-scroll-fab__container', { 'button-visible': isButtonVisible })}>
      <Fab
        aria-label={t('Back to the top of the page')}
        className="ayo-scroll-fab"
        disableFocusRipple
        disableRipple
        id="scroll-to-top-button"
        onClick={onClickHandler}
        size="small"
        style={{ zIndex: 1305 }}
      >
        <CollapseIcon />
      </Fab>
    </Box>
  );
};

ScrollToTopButton.propTypes = {
  selectorToFocus: PropTypes.string,
  navigationAnchor: PropTypes.string,
};

ScrollToTopButton.defaultProps = {
  selectorToFocus: '',
  navigationAnchor: null,
};

export default ScrollToTopButton;
