import React, { useState, useRef } from 'react';
import { Box, ClickAwayListener } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Button } from '../../../../atoms';
import { SearchField } from '../../../../moleculas';
import { loginAllowedFilterRE } from '../../../../../constants/regexps';
import { ReactComponent as SearchIcon } from '../../../../../resources/icons/search.svg';

const SearchWidget = ({ onOptionClick, suggestions }) => {
  const [isActivated, setIsActivated] = useState(false);

  const searchFieldRef = useRef();

  const onOpen = () => {
    setIsActivated(true);
    setTimeout(() => {
      searchFieldRef.current.querySelector('input').focus();
    }, 0);
  };

  const onClose = () => setIsActivated(false);

  const { t } = useTranslation();

  return (
    <Box className="search-widget">
      {!isActivated ? (
        <Button
          aria-label={t('Open searchfield')}
          fullWidth
          gaLabel="Interest map Search activate"
          isIconButton
          onClick={onOpen}
          variant="dark"
        >
          <SearchIcon />
        </Button>
      ) : (
        <ClickAwayListener
          onClickAway={(e) => {
            const isEventTargetInAutocompleteDropdown = e.path.some((element) =>
              element.classList?.contains('MuiAutocomplete-listbox'),
            );
            // Checking it with event path because stt rerender happens before clickaway capturing
            const isElementInSTTTooltip = e.path.some((element) =>
              element.classList?.contains('ayo-stt-helper'),
            );
            if (!isElementInSTTTooltip && !isEventTargetInAutocompleteDropdown) {
              onClose();
            }
          }}
        >
          <SearchField
            ref={searchFieldRef}
            criterias={['name']}
            freeSolo={false}
            gaLabel="Interests map search widget"
            inputRE={loginAllowedFilterRE}
            label={t('Search')}
            maxRowsCount={5}
            minInputLength={2}
            noOptionsText={t('No interests were found')}
            onOptionClick={onOptionClick}
            suggestions={suggestions}
            variant="dark"
          />
        </ClickAwayListener>
      )}
    </Box>
  );
};

SearchWidget.propTypes = {
  onOptionClick: PropTypes.func.isRequired,
  suggestions: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })).isRequired,
};

export default SearchWidget;
