import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import PropTypes from 'prop-types';

import { Typography } from '../../../../../atoms';
import {
  EditingButton,
  InitiativeEmptyStateBlock,
  SearchField,
  SearchNoResults,
} from '../../../../../moleculas';
import { ClubHubCard } from '../../../../../organisms';
import { ClubsMembershipStatuses } from '../../../../../../constants/club-hub';
import { clubHubRoute } from '../../../../../../constants/routes';
import { loginAllowedFilterRE } from '../../../../../../constants/regexps';
import { ReactComponent as ClubHubEmptyState } from '../../../../../../resources/images/club_hub_empty_state.svg';
import { ReactComponent as ExpandIcon } from '../../../../../../resources/icons/chevron_down.svg';
import ClubJoinActions from '../../../club-hub-components/club-join-actions/ClubJoinActions';

const sortClubsByDateAndByName = (a, b) => {
  const dateA = a.membershipChangeDate ? new Date(a.membershipChangeDate) : 0;
  const dateB = b.membershipChangeDate ? new Date(b.membershipChangeDate) : 0;

  return dateB - dateA || a.clubName.localeCompare(b.clubName);
};

const DEFAULT_ELEMENTS_COUNT = 6;
const DEFAULT_ELEMENTS_COUNT_MOBILE = 3;

const ClubsList = ({ clubs, onJoin }) => (
  <Grid container item spacing={3}>
    {clubs.map(({ id, clubName, attachments, membersCount, membershipStatus, requirements }) => (
      <Grid key={id} item md={4} sm={6} xs={12}>
        <ClubHubCard
          actions={
            membershipStatus !== ClubsMembershipStatuses.JOINED ? (
              <ClubJoinActions
                clubId={id}
                clubName={clubName}
                isCardAction
                membershipStatus={membershipStatus}
                onJoin={onJoin}
                requirements={requirements}
              />
            ) : null
          }
          clubName={clubName}
          imageInfo={attachments?.[0]}
          joined={membershipStatus === ClubsMembershipStatuses.JOINED}
          membersCount={membersCount}
          redirectUrl={`${clubHubRoute}/${id}`}
          showMembershipLabel={membershipStatus === ClubsMembershipStatuses.JOINED}
        />
      </Grid>
    ))}
  </Grid>
);

ClubsList.propTypes = {
  clubs: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  onJoin: PropTypes.func,
};

ClubsList.defaultProps = {
  onJoin: () => {},
};
const StudentClubHub = ({ clubs }) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  const [clubsData, setClubsData] = useState([]);
  const [joinedClubs, setJoinedClubs] = useState([]);
  const [elementsCount, setElementsCount] = useState(0);
  const [searchValue, setSearchValue] = useState(null);
  const [searchedClubs, setSearchedClubs] = useState(null);

  const clubSearchHandler = useCallback(
    (value) => {
      if (value) {
        setSearchValue(value);
        setSearchedClubs(
          clubs
            .filter(({ clubName }) => clubName.toLowerCase().includes(value.toLowerCase()))
            .sort(sortClubsByDateAndByName),
        );
      } else {
        setSearchValue(null);
        setSearchedClubs(null);
      }
    },
    [clubs],
  );

  const searchApiRef = useRef();

  const joinHandler = useCallback(({ membershipStatus, membershipChangeDate }, id) => {
    setClubsData((prevState) =>
      prevState
        .map((item) =>
          item.id === id ? { ...item, membershipStatus, membershipChangeDate } : item,
        )
        .sort(sortClubsByDateAndByName),
    );
  }, []);

  useEffect(() => {
    setClubsData(
      clubs
        .filter(({ membershipStatus }) => membershipStatus !== ClubsMembershipStatuses.JOINED)
        .sort(sortClubsByDateAndByName),
    );
    setJoinedClubs(
      clubs
        .filter(({ membershipStatus }) => membershipStatus === ClubsMembershipStatuses.JOINED)
        .sort(sortClubsByDateAndByName),
    );
    setElementsCount(isWidthUpSm ? DEFAULT_ELEMENTS_COUNT : DEFAULT_ELEMENTS_COUNT_MOBILE);
  }, [clubs, isWidthUpSm]);

  return (
    <Box>
      {clubs.length ? (
        <Box>
          <Grid container mb={5}>
            <Grid item md={6} sm={8} xs={12}>
              <SearchField
                apiRef={searchApiRef}
                gaLabel="Club Hub search"
                inputRE={loginAllowedFilterRE}
                label={t('Search by the club name')}
                onSearch={clubSearchHandler}
              />
            </Grid>
          </Grid>
          {searchValue ? (
            <Box>
              {searchedClubs.length ? (
                <Box>
                  <Typography paragraph variant="body2">
                    <Trans
                      components={{ b: <b /> }}
                      i18nKey="search result text"
                      values={{ count: searchedClubs.length, searchValue }}
                    />
                  </Typography>
                  <ClubsList clubs={searchedClubs} onJoin={joinHandler} />
                </Box>
              ) : (
                <SearchNoResults
                  explanation="AYO couldn’t find any results containing searchValue. Please check your spelling and repeat your search"
                  onResetSearch={() => searchApiRef.current.clearSearch()}
                  resetLinkText="Reset the search and show the list of all clubs"
                  searchValue={searchValue}
                />
              )}
            </Box>
          ) : (
            <Box>
              <Typography component="h2" mb={3} variant="h2">
                {t('Clubs')}
              </Typography>
              {!!joinedClubs.length && (
                <Box mb={8}>
                  <Typography component="h3" mb={3} variant="subtitle1">
                    {t('Membership')}
                  </Typography>
                  <ClubsList clubs={joinedClubs} />
                </Box>
              )}
              <Typography component="h3" mb={3} variant="subtitle1">
                {t('Explore more')}
              </Typography>
              <ClubsList clubs={clubsData.slice(0, elementsCount)} onJoin={joinHandler} />
              {clubsData?.length > elementsCount && (
                <Box mt={3}>
                  <EditingButton
                    gaLabel="Show more"
                    icon={<ExpandIcon />}
                    iconPosition="end"
                    onClick={() =>
                      setElementsCount(
                        (prevState) =>
                          prevState +
                          (isWidthUpSm ? DEFAULT_ELEMENTS_COUNT : DEFAULT_ELEMENTS_COUNT_MOBILE),
                      )
                    }
                    text={t('Show more')}
                  />
                </Box>
              )}
            </Box>
          )}
        </Box>
      ) : (
        <InitiativeEmptyStateBlock
          body={t('You can check this page again later, as the clubs will be displayed here.')}
          illustration={<ClubHubEmptyState />}
          title={t('No clubs available for you yet')}
        />
      )}
    </Box>
  );
};

StudentClubHub.propTypes = {
  clubs: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
};

export default StudentClubHub;
