import React, { useCallback, useMemo, useState } from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Button, Slider } from '../../../../atoms';
import { ReactComponent as PlusIcon } from '../../../../../resources/icons/plus.svg';
import { ReactComponent as MinusIcon } from '../../../../../resources/icons/minus.svg';
import { GaActions, GaCategories } from '../../../../../constants/enums';
import { GA } from '../../../../../utils';

const ZoomWidget = ({ onZoomIn, onZoomOut, onZoomSliderChange, currentZoomRatio }) => {
  const { t } = useTranslation();

  const sendGoogleAnalytics = useCallback((action) => {
    GA.logInteraction({
      category: GaCategories.BEHAVIOR,
      action,
      label: 'Interests map zoom widget',
    });
  }, []);

  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  const sliderAriaMarks = useMemo(
    () => [
      { value: 0, label: '0%' },
      { value: 1, label: '20%' },
      { value: 2, label: '40%' },
      { value: 3, label: '60%' },
      { value: 4, label: '80%' },
      { value: 5, label: '100%' },
    ],
    [],
  );

  const [mobileSliderTimeoutId, setMobileSliderTimeoutId] = useState(null);

  const handleMobileButtonClick = () => {
    if (mobileSliderTimeoutId) {
      clearTimeout(mobileSliderTimeoutId);
    }
    // Doing it asyncroniously to start timer only after map rerenders
    setTimeout(
      () => setMobileSliderTimeoutId(setTimeout(() => setMobileSliderTimeoutId(null), 3000)),
      0,
    );
  };

  return (
    <Box className="zoom-widget">
      <Button
        aria-label={t('Zoom In')}
        gaLabel="Interests map Zoom in"
        isIconButton
        onClick={() => {
          handleMobileButtonClick();
          onZoomIn();
        }}
      >
        <PlusIcon />
      </Button>
      {(isWidthUpSm || mobileSliderTimeoutId) && (
        <Slider
          ariaMarks={sliderAriaMarks}
          className="zoom-slider"
          defaultValue={0}
          label={t('Zoom')}
          marks
          max={5}
          min={0}
          onChange={(e, newValue) => {
            handleMobileButtonClick();
            if (newValue !== currentZoomRatio) {
              onZoomSliderChange(e, newValue);
              sendGoogleAnalytics(GaActions.ZOOM_SLIDER_VALUE_CHANGE);
            }
          }}
          orientation="vertical"
          step={1}
          value={currentZoomRatio}
        />
      )}
      <Button
        aria-label={t('Zoom Out')}
        gaLabel="Interests map Zoom out"
        isIconButton
        onClick={() => {
          handleMobileButtonClick();
          onZoomOut();
        }}
      >
        <MinusIcon />
      </Button>
    </Box>
  );
};

ZoomWidget.propTypes = {
  onZoomIn: PropTypes.func.isRequired,
  onZoomOut: PropTypes.func.isRequired,
  onZoomSliderChange: PropTypes.func.isRequired,
  currentZoomRatio: PropTypes.oneOf([0, 1, 2, 3, 4, 5]).isRequired,
};

export default ZoomWidget;
