import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, DialogActions, DialogContent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { DialogTitle, Typography } from '../../../../atoms';
import { Dropdown, PopupActionsButtons } from '../../../../moleculas';
import {
  CampusesBlock,
  DistrictsBlock,
  ClassesBlock,
  PersonalizedBlock,
  RolesVisibilityPicker,
} from '../../../../organisms';
import { FeedPostKeys, SharingLevels } from '../../../../../constants/family-feed';
import { useFamilyFeed } from '../../../../../hooks';
import { filterUniqueValuesByKey, focusDialogCloseButton } from '../../../../../utils';

const FULL_HEIGHT_NUMBER_OF_ENTITIES = 6;

const getIsFullHeight = (entities) =>
  entities && Object.values(entities).flat().length > FULL_HEIGHT_NUMBER_OF_ENTITIES;

const PostVisibilitySettings = ({ isEditMode, onClose, onSave, sharedWithData }) => {
  const { t } = useTranslation();

  const [newSharedWithData, setNewSharedWithData] = useState(null);
  const { getAllowedSharingLevels, getEntitiesByLevel } = useFamilyFeed();

  const startData = useMemo(() => {
    const allowedSharingLevels = getAllowedSharingLevels();

    return allowedSharingLevels.includes(SharingLevels.DISTRICT.value)
      ? {
          [FeedPostKeys.SHARING_LEVEL]: SharingLevels.DISTRICT.value,
          [FeedPostKeys.SELECTED_ENTITIES]: getEntitiesByLevel(SharingLevels.DISTRICT.value),
        }
      : {
          [FeedPostKeys.SHARING_LEVEL]: SharingLevels[allowedSharingLevels[0]].value,
          [FeedPostKeys.SELECTED_ENTITIES]: [],
        };
  }, [getAllowedSharingLevels, getEntitiesByLevel]);

  useEffect(() => {
    setNewSharedWithData(
      !sharedWithData.sharingLevel && !sharedWithData.selectedEntities?.length
        ? startData
        : sharedWithData,
    );
  }, [sharedWithData, startData]);

  const handleDropdownChange = useCallback((e) => {
    setNewSharedWithData((prevState) => ({
      ...prevState,
      [FeedPostKeys.SHARING_LEVEL]: e.target.value,
      [FeedPostKeys.SELECTED_ENTITIES]: [],
      [FeedPostKeys.SHARING_GROUPS]: null,
    }));
    focusDialogCloseButton();
  }, []);

  const handleSelectedEntitiesChange = useCallback((e, value, comparisonKey = 'id') => {
    setNewSharedWithData((prevState) => {
      let newState;

      if (Array.isArray(value)) {
        newState = {
          ...prevState,
          selectedEntities: e.target.checked
            ? filterUniqueValuesByKey([...prevState.selectedEntities, ...value], comparisonKey)
            : [
                ...prevState.selectedEntities.filter(
                  (oldItem) =>
                    !value.find((newItem) => oldItem[comparisonKey] === newItem[comparisonKey]),
                ),
              ],
        };
      } else {
        newState = {
          ...prevState,
          selectedEntities: e.target.checked
            ? [...prevState.selectedEntities, value]
            : prevState.selectedEntities.filter((x) => x[comparisonKey] !== value[comparisonKey]),
        };
      }

      return newState;
    });
  }, []);

  const handleSharingGroupsChange = useCallback((value) => {
    setNewSharedWithData((prevState) => ({
      ...prevState,
      [FeedPostKeys.SHARING_GROUPS]: value,
    }));
  }, []);

  const dropdownOptions = useMemo(
    () =>
      getAllowedSharingLevels().map((level) => ({
        value: SharingLevels[level].value,
        label: t(SharingLevels[level].label),
      })),
    [getAllowedSharingLevels, t],
  );

  const selectionBlock = useMemo(() => {
    switch (newSharedWithData?.sharingLevel) {
      case SharingLevels.DISTRICT.value:
        return (
          <>
            <DistrictsBlock
              districts={getEntitiesByLevel(newSharedWithData.sharingLevel)}
              onChange={handleSelectedEntitiesChange}
              selected={newSharedWithData.selectedEntities}
            />
            <RolesVisibilityPicker
              onChange={handleSharingGroupsChange}
              sharingGroups={newSharedWithData.sharingGroups}
              sharingLevel={SharingLevels.DISTRICT.value}
            />
          </>
        );
      case SharingLevels.CAMPUS.value:
        return (
          <>
            <CampusesBlock
              onChange={handleSelectedEntitiesChange}
              schools={getEntitiesByLevel(newSharedWithData.sharingLevel)}
              selected={newSharedWithData.selectedEntities}
            />
            {!!Object.values(getEntitiesByLevel(newSharedWithData.sharingLevel) ?? {}).flat()
              .length && (
              <RolesVisibilityPicker
                onChange={handleSharingGroupsChange}
                sharingGroups={newSharedWithData.sharingGroups}
                sharingLevel={SharingLevels.CAMPUS.value}
              />
            )}
          </>
        );
      case SharingLevels.CLASS.value:
        return (
          <>
            <ClassesBlock
              classes={getEntitiesByLevel(newSharedWithData.sharingLevel)}
              onChange={handleSelectedEntitiesChange}
              selected={newSharedWithData.selectedEntities}
            />
            {!!getEntitiesByLevel(newSharedWithData.sharingLevel)?.length && (
              <RolesVisibilityPicker
                onChange={handleSharingGroupsChange}
                sharingGroups={newSharedWithData.sharingGroups}
                sharingLevel={SharingLevels.CLASS.value}
              />
            )}
          </>
        );
      case SharingLevels.PERSONALIZED.value:
        return (
          <>
            <PersonalizedBlock
              onChange={handleSelectedEntitiesChange}
              selected={newSharedWithData.selectedEntities}
              students={getEntitiesByLevel(newSharedWithData.sharingLevel)}
            />
            {!!getEntitiesByLevel(newSharedWithData.sharingLevel)?.length && (
              <RolesVisibilityPicker
                onChange={handleSharingGroupsChange}
                sharingGroups={newSharedWithData.sharingGroups}
                sharingLevel={SharingLevels.PERSONALIZED.value}
              />
            )}
          </>
        );
      default:
        return null;
    }
  }, [
    getEntitiesByLevel,
    handleSelectedEntitiesChange,
    handleSharingGroupsChange,
    newSharedWithData?.selectedEntities,
    newSharedWithData?.sharingGroups,
    newSharedWithData?.sharingLevel,
  ]);

  return (
    <Box
      className={classNames('ayo-post-visibility-settings', {
        'ayo-post-visibility-settings--full-height': getIsFullHeight(
          getEntitiesByLevel(newSharedWithData?.sharingLevel),
        ),
      })}
      height={isEditMode ? '90%' : '100%'}
    >
      <DialogTitle disableTypography>
        <Box display="flex" flexDirection="column" mb={3} rowGap={1}>
          <Typography component="h2" variant="subtitle1">
            {t(isEditMode ? 'Who can see the post' : 'Specify who can see the post')}
          </Typography>
          <Typography variant="body2">{t('Select an option from the dropdown')}</Typography>
        </Box>
      </DialogTitle>
      <DialogContent className="ayo-post-visibility-settings__content">
        <Box mb={3} pt={0.125}>
          <Dropdown
            key={newSharedWithData?.sharingLevel}
            fullWidth
            gaLabel="Share post level"
            handleChange={handleDropdownChange}
            options={dropdownOptions}
            value={newSharedWithData?.sharingLevel}
          />
        </Box>
        <Box className="ayo-post-visibility-settings__content__selection-block" mb={3}>
          {selectionBlock}
        </Box>
      </DialogContent>
      <DialogActions>
        <PopupActionsButtons
          primaryButtonGaLabel="Apply"
          primaryButtonHandler={() => onSave(newSharedWithData)}
          primaryButtonText={t('Apply')}
          secondaryButtonGaLabel="Cancel"
          secondaryButtonHandler={onClose}
          secondaryButtonText={t('Cancel')}
        />
      </DialogActions>
    </Box>
  );
};

PostVisibilitySettings.propTypes = {
  isEditMode: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  sharedWithData: PropTypes.shape({
    selectedEntities: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ),
    sharingLevel: PropTypes.string,
  }).isRequired,
};

PostVisibilitySettings.defaultProps = {
  isEditMode: false,
};

export default PostVisibilitySettings;
