import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, DialogActions, DialogContent } from '@mui/material';

import { Dialog, DialogTitle, Typography } from '../../../../../atoms';
import { PopupActionsButtons } from '../../../../../moleculas';

const SkippedConfirmDialog = ({ isOpen, onConfirm, setIsOpen }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      gaLabel="You have skipped some questions dialog"
      onClose={() => setIsOpen(false)}
      open={isOpen}
    >
      <DialogTitle disableTypography>
        <Box mb={2}>
          <Typography component="h2" variant="subtitle1">
            {t('You have skipped some questions')}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box mb={5}>
          <Typography variant="body2">
            {t('You can go back and answer them now, or Submit and give your answers later')}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <PopupActionsButtons
          primaryButtonHandler={() => {
            setIsOpen((state) => {
              onConfirm();
              return !state;
            });
          }}
          primaryButtonText={t('Submit')}
          secondaryButtonHandler={() => {
            setIsOpen(false);
          }}
          secondaryButtonText={t('Go back')}
        />
      </DialogActions>
    </Dialog>
  );
};

SkippedConfirmDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export default SkippedConfirmDialog;
