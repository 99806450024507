import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { Typography } from '../../../atoms';
import { MobileNotSupported } from '../../../organisms';
import { useClubHubService } from '../../../../services';
import { useSearchParams } from '../../../../hooks';
import { transformSearchToObject } from '../../../../utils';
import { ClubsManagementQueryParams, ClubsStatuses } from '../../../../constants/club-hub';

import ClubsFilters from './components/clubs-filters/ClubsFilters';
import ClubsSearch from './components/clubs-search/ClubsSearch';
import ClubsDataGrid from './components/clubs-data-grid/ClubsDataGrid';

const PAGE_SIZE = 12;
const DEFAULT_PAGE = 1;

const ClubHubManagement = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });

  const [clubsList, setClubsList] = useState(null);
  const [totalRowCount, setTotalRowCount] = useState(0);

  const { getClubsManagementList } = useClubHubService();

  const search = useSearchParams();

  const filters = transformSearchToObject(search);

  const loadClubsList = useCallback(
    (queryParams) => {
      getClubsManagementList({
        [ClubsManagementQueryParams.NAME]: queryParams[ClubsManagementQueryParams.NAME],
        [ClubsManagementQueryParams.PAGE]:
          queryParams[ClubsManagementQueryParams.PAGE] || DEFAULT_PAGE,
        [ClubsManagementQueryParams.PAGE_SIZE]: PAGE_SIZE,
        [ClubsManagementQueryParams.STATUS]:
          queryParams[ClubsManagementQueryParams.STATUS] || ClubsStatuses.ALL,
      }).then((data) => {
        setClubsList(data.content);
        setTotalRowCount(data.totalElements);
      });
    },
    [getClubsManagementList],
  );

  const updateQueryParamsHandler = useCallback(
    (params) => {
      const newParams = { ...filters, ...params };
      if (!newParams[ClubsManagementQueryParams.NAME]) {
        delete newParams[ClubsManagementQueryParams.NAME];
      }
      history.replace({
        search: new URLSearchParams(newParams).toString(),
      });
      loadClubsList(newParams);
    },
    [filters, history, loadClubsList],
  );

  useEffect(() => {
    loadClubsList(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isWidthUpSm ? (
        <Box className="ayo-club-hub__management">
          <Typography mb={2.5} variant="h2">
            {t('Clubs')}
          </Typography>
          <ClubsFilters
            onFiltersChange={updateQueryParamsHandler}
            status={filters[ClubsManagementQueryParams.STATUS]}
          />
          <ClubsSearch
            onSearch={updateQueryParamsHandler}
            searchValue={filters[ClubsManagementQueryParams.NAME]}
            totalItemsCount={totalRowCount}
          />
          {!!clubsList?.length && (
            <ClubsDataGrid
              clubsList={clubsList}
              onPageChange={updateQueryParamsHandler}
              onStatusChange={() => loadClubsList(filters)}
              pageSize={PAGE_SIZE}
              searchValue={filters[ClubsManagementQueryParams.NAME]}
              selectedPage={+filters[ClubsManagementQueryParams.PAGE] || DEFAULT_PAGE}
              totalRowCount={totalRowCount}
            />
          )}
        </Box>
      ) : (
        <MobileNotSupported
          explanation={t(
            'Please open Club Hub management section with a tablet or desktop version to use this functionality.',
          )}
        />
      )}
    </>
  );
};

export default ClubHubManagement;
