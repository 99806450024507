import { useState } from 'react';

const useAssignmentSettingsState = (defValue) => {
  const [settings, setSettings] = useState(defValue);

  const onChange = (key, value) => {
    const newSettings = [...settings];
    const updatedSettingIndex = newSettings.findIndex((setting) => setting.key === key);
    newSettings[updatedSettingIndex].value = value;
    setSettings(newSettings);
  };
  return { state: settings, onChange };
};

export default useAssignmentSettingsState;
