import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

import { Button } from '../../../../../atoms';
import { ReactComponent as BackArrowIcon } from '../../../../../../resources/icons/chevron_left_primary.svg';

const ContinueButton = ({ buttonName, isPreviousButtonHidden, onNext, onPrevious }) => {
  const { t } = useTranslation();

  return (
    <Box className="ayo-continue-button" display="flex" flexDirection="row">
      {!isPreviousButtonHidden && (
        <Box mr={2}>
          <Button
            aria-label={t('Go back to the previous settings')}
            gaLabel="Back"
            isIconButton
            onClick={onPrevious}
            variant="secondary"
          >
            <BackArrowIcon />
          </Button>
        </Box>
      )}
      <Button
        className="ayo-continue-button__next"
        gaLabel={buttonName}
        onClick={onNext}
        variant="primary"
      >
        {t(buttonName)}
      </Button>
    </Box>
  );
};

ContinueButton.propTypes = {
  buttonName: PropTypes.string.isRequired,
  isPreviousButtonHidden: PropTypes.bool,
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
};

ContinueButton.defaultProps = {
  isPreviousButtonHidden: false,
  onNext: () => {},
  onPrevious: () => {},
};

export default ContinueButton;
