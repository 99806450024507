/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import MUITextField from '@mui/material/TextField';

import { GaActions, GaCategories, InputsValidationErrors } from '../../../constants/enums';
import GA from '../../../utils/analytics/Analytics';

const TextField = ({
  className,
  error,
  gaLabel,
  helperText,
  isLightText,
  maxLength,
  onBlur,
  onChange,
  outlined,
  rowsCount,
  value,
  ...rest
}) => {
  const { t } = useTranslation();
  const [validationMessage, setValidationMessage] = useState('');

  const hasErrors = useMemo(() => error || validationMessage !== '', [error, validationMessage]);

  const onBlurHandler = useCallback(
    (e) => {
      onBlur(e);
      if (gaLabel) {
        GA.logInteraction({
          category: GaCategories.BEHAVIOR,
          action: GaActions.INPUT_FINISH_TYPING,
          label: gaLabel,
        });
      }
    },
    [gaLabel, onBlur],
  );

  useEffect(() => {
    if (maxLength && value) {
      if (value.length > maxLength) {
        setValidationMessage(InputsValidationErrors(t, maxLength).MAX_ERROR_TEXT);
      } else if (validationMessage) {
        setValidationMessage('');
      }
    }
    if (!value && validationMessage) {
      setValidationMessage('');
    }
  }, [maxLength, t, value, validationMessage]);

  return (
    <MUITextField
      className={`${classnames('ayo-textfield', {
        'ayo-textfield--outlined': outlined,
        'ayo-textfield--w-bottom-space': hasErrors,
        [`${className}`]: className,
      })}`}
      error={hasErrors}
      FormHelperTextProps={{
        role: 'alert',
        className: classnames({ 'Mui-error--light': isLightText }),
      }}
      helperText={helperText || validationMessage}
      onBlur={onBlurHandler}
      onChange={onChange}
      rows={rowsCount}
      value={value}
      variant="outlined"
      {...rest}
    />
  );
};

TextField.propTypes = {
  className: PropTypes.string,
  error: PropTypes.bool,
  gaLabel: PropTypes.string,
  helperText: PropTypes.string,
  isLightText: PropTypes.bool,
  maxLength: PropTypes.number,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  outlined: PropTypes.bool,
  rowsCount: PropTypes.number,
  value: PropTypes.string.isRequired,
};

TextField.defaultProps = {
  className: '',
  error: false,
  gaLabel: '',
  helperText: '',
  isLightText: false,
  maxLength: null,
  onBlur: () => {},
  outlined: false,
  rowsCount: 0,
};

export default TextField;
