import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const useSearchParams = () => {
  const { search } = useLocation();

  const searchObject = useMemo(() => new URLSearchParams(search), [search]);

  return searchObject;
};

export default useSearchParams;
