import React, { useEffect, useMemo, useCallback } from 'react';
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import {
  Button,
  DayOffTeacher,
  CalendarDateDisplay,
  Card,
  Holiday,
  Typography,
} from '../../../atoms';
import { CalendarDayOptions, MonthPicker } from '../../../moleculas';
import { useLessonPlannerData } from '../../../../hooks';
import {
  addKeyboardPressHandler,
  GA,
  getFormattedDate,
  getShortDate,
  isWeekend,
  sortArrayByKey,
  useCalendar,
} from '../../../../utils';
import { GaActions, GaCategories } from '../../../../constants/enums';
import { lessonTitlePlaceholder } from '../../../../constants/lesson-planner';
import {
  calendarViewMainProps,
  classPropType,
  lessonPropType,
} from '../../../../constants/propTypes';
import { ReactComponent as ChevronLeft } from '../../../../resources/icons/chevron_left.svg';
import { ReactComponent as ChevronRight } from '../../../../resources/icons/chevron_right.svg';

const LessonDisplayItem = ({ lesson }) => {
  const { t } = useTranslation();
  return (
    <Box className={classNames('month-day-lesson', lesson.status?.toLowerCase())}>
      <span className={classNames('lesson-status-indicator')} />
      <Typography className="lesson-title" noWrap variant="label-highlighted">
        {lesson.title || t(lessonTitlePlaceholder)}
      </Typography>
    </Box>
  );
};

LessonDisplayItem.propTypes = {
  lesson: lessonPropType.isRequired,
};

const MAX_LESSONS_DISPLAYED = 5;
const MAX_LESSONS_DISPLAYED_XL = 3;

const MonthDayCard = ({ date, day, currentDate, onDateSelect, scheduleId }) => {
  const isDifferentMonth = !dayjs(date).isSame(currentDate, 'month');

  let cardContent;

  const theme = useTheme();
  const isWidthUpXl = useMediaQuery(theme.breakpoints.up('xl'));

  const { t } = useTranslation();

  const onCardClick = useCallback(() => {
    GA.logInteraction({
      category: GaCategories.BEHAVIOR,
      action: GaActions.CARD_CLICK,
      label: 'Month day card',
    });
    onDateSelect(dayjs(date));
  }, [date, onDateSelect]);

  if (day === undefined) return null;

  const maxLessonsNumber = isWidthUpXl ? MAX_LESSONS_DISPLAYED_XL : MAX_LESSONS_DISPLAYED;

  const lessons =
    day?.classes
      ?.reduce((acc, classInfo) => [...acc, classInfo.lessonsMetadata], [])
      .filter(Boolean)
      .flat() ?? [];

  if (day === null) {
    cardContent = <Holiday />;
  } else {
    cardContent = day.isDayOff ? (
      <DayOffTeacher />
    ) : (
      <Box className="lessons-container">
        {sortArrayByKey(lessons, 'createdDate', 'date', 'increase')
          .slice(0, maxLessonsNumber)
          .map((lesson) => (
            <LessonDisplayItem key={lesson.id} lesson={lesson} />
          ))}
        {maxLessonsNumber < lessons.length && (
          <Typography isLabel variant="body3">
            + {lessons.length - maxLessonsNumber} {t('more')}...
          </Typography>
        )}
      </Box>
    );
  }

  const isDayInPast = dayjs(date).isBefore(dayjs(), 'day');

  return (
    <Box className="month-day-wrapper" position="relative">
      <Card
        className={classNames('ayo-clickable-card month-day-card', {
          'different-month': isDifferentMonth,
        })}
        mainContent={
          <Box>
            <Box mb={1}>
              <CalendarDateDisplay date={date} />
            </Box>
            <Box className="day-content">{cardContent}</Box>
          </Box>
        }
        onClick={onCardClick}
        onKeyDown={addKeyboardPressHandler(onCardClick)}
        role="button"
        tabIndex="0"
      />
      {day && !isDayInPast && (
        <Box className="day-options__container">
          <CalendarDayOptions day={day} scheduleId={scheduleId} />
        </Box>
      )}
    </Box>
  );
};

MonthDayCard.propTypes = {
  date: PropTypes.string.isRequired,
  day: PropTypes.shape({
    isDayOff: PropTypes.bool,
    classes: PropTypes.arrayOf(PropTypes.shape(classPropType)),
    classDate: PropTypes.string,
  }).isRequired,
  currentDate: PropTypes.instanceOf(Object).isRequired,
  onDateSelect: PropTypes.func,
  scheduleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

MonthDayCard.defaultProps = {
  onDateSelect: () => {},
};

const getFirstDayOfMonthView = (date) => {
  const firstDayOfMonth = date.startOf('month');
  const firstDayOfMonthDay = firstDayOfMonth.day();
  if (firstDayOfMonthDay === 0 || firstDayOfMonthDay === 6) {
    const weekIncrement = firstDayOfMonthDay === 6 ? 1 : 0;
    return firstDayOfMonth.add(weekIncrement, 'week').day(1);
  }
  return firstDayOfMonth.day(1);
};

const MonthView = ({
  currentDate,
  setCurrentDate,
  currentPickerDates,
  scheduleId,
  onDateSelect,
}) => {
  const { i18n } = useTranslation();

  const monthViewStart = getFirstDayOfMonthView(currentDate);
  const monthViewEnd = currentDate.endOf('month').day(5);

  const { loadDatesData, getDateRangeData } = useLessonPlannerData();

  useEffect(() => {
    loadDatesData(scheduleId, getShortDate(monthViewStart), getShortDate(monthViewEnd));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDate, scheduleId]);

  const monthData = getDateRangeData(scheduleId, monthViewStart, monthViewEnd);

  const dayLabels = useMemo(() => {
    let resultLabels = [];
    for (let i = 1; i < 6; i += 1) {
      resultLabels = [
        ...resultLabels,
        getFormattedDate(currentDate.day(i), i18n.language, { weekday: 'long' }),
      ];
    }
    return resultLabels;
  }, [currentDate, i18n.language]);

  const isCurrentDayInCurrentYear = currentDate.isSame(dayjs(), 'year');

  const prevMonthDate = currentDate.subtract(1, 'month');
  const isPrevMonthHidden = prevMonthDate.isBefore(currentPickerDates.firstDayOfSchedule, 'month');

  const nextMonthDate = currentDate.add(1, 'month');
  const isNextMonthHidden = nextMonthDate.isAfter(currentPickerDates.lastDayOfSchedule, 'month');

  const { isCalendarOpen, onCalendarClick } = useCalendar();

  const isTodayMonthInSchedule = dayjs().isBetween(
    currentPickerDates.firstDayOfSchedule,
    currentPickerDates.lastDayOfSchedule,
    'month',
    '[]',
  );

  const isCurrentDateInTodaysMonth = dayjs().isSame(currentDate, 'month');

  const { t } = useTranslation();

  return (
    <Grid container>
      <Grid
        alignItems="center"
        container
        item
        justifyContent="center"
        mb={5}
        position="relative"
        xs={12}
      >
        <Box alignItems="center" display="flex" justifyContent="center">
          <Button
            aria-label={t('Previous month')}
            gaLabel="Previous month"
            isHidden={isPrevMonthHidden}
            isIconButton
            onClick={() => {
              setCurrentDate(currentDate.subtract(1, 'month').date(1));
            }}
          >
            <ChevronLeft />
          </Button>
          <MonthPicker
            date={currentDate}
            gaLabel="Lesson planner month view"
            isOpen={isCalendarOpen}
            maxDate={currentPickerDates.lastDayOfSchedule}
            minDate={currentPickerDates.firstDayOfSchedule}
            onChange={(date) => setCurrentDate(dayjs(date))}
            outlined
            setIsOpen={onCalendarClick}
            shouldDisabledDate={isWeekend}
          />
          <Typography
            className="ayo-schedule-page__calendar__selected-date"
            onClick={onCalendarClick}
            variant="subtitle1"
          >
            {getFormattedDate(currentDate, i18n.language, { month: 'long' })}
            {!isCurrentDayInCurrentYear && `, ${currentDate.get('year')}`}
          </Typography>
          <Button
            aria-label={t('Next month')}
            className={classNames({ hidden: isNextMonthHidden })}
            gaLabel="Next month"
            isHidden={isNextMonthHidden}
            isIconButton
            onClick={() => {
              setCurrentDate(currentDate.add(1, 'month').date(1));
            }}
          >
            <ChevronRight />
          </Button>
        </Box>
        {isTodayMonthInSchedule && !isCurrentDateInTodaysMonth && (
          <Box position="absolute" right="0">
            <Button
              gaLabel="Show current month"
              onClick={() => setCurrentDate(dayjs().date(1))}
              variant="secondary"
            >
              {t('Show current month')}
            </Button>
          </Box>
        )}
      </Grid>
      <Grid item xs={12}>
        <Box className="month-container__header" mb={1} width="100%">
          {dayLabels.map((label) => (
            <Box key={label}>
              <Typography isLabel variant="label-highlighted">
                {label}
              </Typography>
            </Box>
          ))}
        </Box>
        <Box className="month-container" width="100%">
          {Object.entries(monthData).map(([date, day]) => (
            <Box key={date}>
              {dayjs(date).isBetween(
                currentPickerDates.firstDayOfSchedule,
                currentPickerDates.lastDayOfSchedule,
                'day',
                '[]0',
              ) && (
                <MonthDayCard
                  currentDate={currentDate}
                  date={date}
                  day={day}
                  onDateSelect={onDateSelect}
                  scheduleId={scheduleId}
                />
              )}
            </Box>
          ))}
        </Box>
      </Grid>
    </Grid>
  );
};

MonthView.propTypes = {
  ...calendarViewMainProps,
  onDateSelect: PropTypes.func,
};

MonthView.defaultProps = {
  onDateSelect: () => {},
};

export default MonthView;
