import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, DialogContent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Button, Dialog, DialogTitle, Typography } from '../../../../atoms';
import { focusDialogCloseButton } from '../../../../../utils';
import { FeedPostKeys } from '../../../../../constants/family-feed';
import { ReactComponent as NavigationBackChevron } from '../../../../../resources/icons/chevron_left.svg';
import PostForm from '../post-form/PostForm';
import PostVisibilitySettings from '../post-visibility-settings/PostVisibilitySettings';
import { InformationalCaption } from '../../../../moleculas';

const EditPostDialog = ({ editingPost, onClose }) => {
  const { t } = useTranslation();
  const [sharedWithData, setSharedWithData] = useState(null);
  const [isVisibilitySettingOpen, setIsVisibilitySettingOpen] = useState(false);

  useEffect(() => {
    if (editingPost) {
      setSharedWithData({
        [FeedPostKeys.SELECTED_ENTITIES]: editingPost[FeedPostKeys.SELECTED_ENTITIES],
        [FeedPostKeys.SHARING_LEVEL]: editingPost[FeedPostKeys.SHARING_LEVEL],
        [FeedPostKeys.SHARING_GROUPS]: editingPost[FeedPostKeys.SHARING_GROUPS],
      });
    }
  }, [editingPost]);

  const dialogCloseHandler = useCallback(() => {
    setIsVisibilitySettingOpen(false);
    onClose();
  }, [onClose]);

  return (
    <Dialog
      className={classNames('ayo-edit-post-dialog', {
        'ayo-edit-post-dialog--visibility-setting': isVisibilitySettingOpen,
      })}
      gaLabel="Edit post"
      onClose={dialogCloseHandler}
      open={!!editingPost}
      transitionDuration={{ exit: 0 }}
    >
      {!isVisibilitySettingOpen ? (
        <>
          <DialogTitle disableTypography>
            <Typography component="h2" variant="subtitle1">
              {t('Edit post')}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Box px={0.5}>
              <PostForm
                additionalInfo={
                  <Box mt={2}>
                    <InformationalCaption title={t('Edit family feed post visibility info')} />
                  </Box>
                }
                onClose={dialogCloseHandler}
                onShareWithDataChange={(value) => setSharedWithData(value)}
                onVisibilityButtonClick={() => {
                  setIsVisibilitySettingOpen(true);
                  focusDialogCloseButton();
                }}
                postDataToEdit={editingPost}
                sharedWithData={sharedWithData}
              />
            </Box>
          </DialogContent>
        </>
      ) : (
        <>
          <Button
            className="ayo-edit-post-dialog__back-button"
            gaLabel="Back to previous step"
            onClick={() => {
              setIsVisibilitySettingOpen(false);
              focusDialogCloseButton();
            }}
          >
            <NavigationBackChevron />
            {t('Back to previous step')}
          </Button>
          <PostVisibilitySettings
            isEditMode
            onClose={dialogCloseHandler}
            onSave={(value) => {
              setSharedWithData(value);
              setIsVisibilitySettingOpen(false);
              focusDialogCloseButton();
            }}
            sharedWithData={sharedWithData}
          />
        </>
      )}
    </Dialog>
  );
};

EditPostDialog.propTypes = {
  editingPost: PropTypes.shape({
    attachments: PropTypes.arrayOf(
      PropTypes.shape({
        fileName: PropTypes.string,
        id: PropTypes.number,
        ownerId: PropTypes.number,
        updatedDate: PropTypes.string,
      }),
    ),
    createdDate: PropTypes.string,
    description: PropTypes.string,
    id: PropTypes.number,
    selectedEntities: PropTypes.arrayOf(PropTypes.string),
    sharingLevel: PropTypes.string,
    title: PropTypes.string,
  }),
  onClose: PropTypes.func.isRequired,
};

EditPostDialog.defaultProps = {
  editingPost: null,
};

export default EditPostDialog;
