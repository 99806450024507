import React from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Button, Typography } from '../../../../../../atoms';
import { Highlighter } from '../../../../../../../utils';

const LabelsMap = {
  readiness: 'Readiness',
  supporting: 'Supporting',
};

const TeksLabel = ({ label }) => (
  <Typography
    className={classNames('ayo-teks-row__label', {
      'ayo-teks-row__label--readiness': label === LabelsMap.readiness,
      'ayo-teks-row__label--support': label === LabelsMap.supporting,
    })}
    component="span"
    variant="body3"
  >
    {label}
  </Typography>
);

TeksLabel.propTypes = {
  label: PropTypes.string.isRequired,
};

export const TeksRow = ({ teksData, btnConfig, searchValue }) => {
  const { t } = useTranslation();

  return (
    <Box key={teksData?.display} className="ayo-teks-row">
      <Typography className="ayo-teks-row__display" variant="subtitle2">
        {teksData?.display}
      </Typography>
      <Typography className="ayo-teks-row__text" variant="body2">
        {searchValue ? Highlighter(teksData?.text, [searchValue]) : teksData?.text}
        {teksData?.isReadiness && <TeksLabel label={LabelsMap.readiness} />}
        {teksData?.isSupporting && <TeksLabel label={LabelsMap.supporting} />}
      </Typography>
      {btnConfig && (
        <Button
          aria-label={t(btnConfig.ariaLabel)}
          gaLabel={btnConfig.gaLabel}
          isIconButton
          onClick={() => btnConfig.onClick(teksData)}
        >
          {btnConfig.icon}
        </Button>
      )}
    </Box>
  );
};

TeksRow.propTypes = {
  teksData: PropTypes.shape({
    display: PropTypes.string,
    text: PropTypes.string,
    isReadiness: PropTypes.bool,
    isSupporting: PropTypes.bool,
  }).isRequired,
  btnConfig: PropTypes.shape({
    ariaLabel: PropTypes.string,
    gaLabel: PropTypes.string,
    onClick: PropTypes.func,
    icon: PropTypes.node,
  }),
  searchValue: PropTypes.string,
};

TeksRow.defaultProps = {
  btnConfig: null,
  searchValue: null,
};

const TeksBlock = ({ teksData, btnConfig, searchValue }) => (
  <Box>
    {teksData?.map((item, index) => (
      <TeksRow
        key={`${item.display}-${index}`}
        btnConfig={btnConfig}
        searchValue={searchValue}
        teksData={item}
      />
    ))}
  </Box>
);

TeksBlock.propTypes = {
  teksData: PropTypes.arrayOf(
    PropTypes.shape({
      display: PropTypes.string,
      text: PropTypes.string,
    }),
  ).isRequired,
  btnConfig: PropTypes.shape({
    ariaLabel: PropTypes.string,
    gaLabel: PropTypes.string,
    onClick: PropTypes.func,
    icon: PropTypes.node,
  }),
  searchValue: PropTypes.string,
};

TeksBlock.defaultProps = {
  searchValue: null,
  btnConfig: null,
};

export default TeksBlock;
