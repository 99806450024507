import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Grid, useMediaQuery, useTheme } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { Button, PreviewBox, Typography } from '../../atoms';
import { DisplayAvatarEditorImage, PreviewGallery, TextWithTooltip } from '../../moleculas';
import MoodSectionTeacher from '../mood-section/teacher/MoodSectionTeacher';
import InterventionAlert from '../intervention-alert/InterventionAlert';
import { UserContext } from '../../../context';
import { useNotificationsService } from '../../../services';
import { GA, getFileTypeFromExtension, getFullName, sortArrayByKey } from '../../../utils';
import {
  apiRoute,
  familyFeedRoute,
  moodsDetailsRoute,
  portfoliosRoute,
} from '../../../constants/routes';
import {
  AlertsActions,
  AlertsStates,
  GaActions,
  GaCategories,
  RolesMap,
  StudentsPage,
} from '../../../constants/enums';
import { ReactComponent as PaletteIcon } from '../../../resources/icons/palette.svg';
import { IconsByTypeMap } from '../../../constants/fileTypes';

import RejectDialog from './components/RejectDialog';

const studentGradeMap = {
  1: 'st',
  2: 'nd',
  3: 'rd',
};

const StudentGeneralInfo = ({ student, withMood }) => {
  const { t } = useTranslation();
  const { state: userState } = useContext(UserContext);
  const [interventionsAlerts, setInterventionsAlerts] = useState([]);

  const { avatar } = student || {};

  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  const { getNotificationsAlerts, putReadNotificationsAlert } = useNotificationsService();

  useEffect(() => {
    if (student && withMood) {
      getNotificationsAlerts(student.id).then((data) =>
        setInterventionsAlerts(
          sortArrayByKey(
            data.filter(({ action }) => action === AlertsActions.INTERVENTION_REQUEST),
            'requestDate',
            'date',
          ),
        ),
      );
    }
  }, [getNotificationsAlerts, student, withMood]);

  const alertGotItHandler = useCallback(
    (alertId) => {
      putReadNotificationsAlert(alertId);
      setInterventionsAlerts((prevState) => prevState.filter(({ id }) => id !== alertId));
    },
    [putReadNotificationsAlert],
  );

  const [isRejectDialogOpen, setIsRejectDialogOpen] = useState(false);

  const [galleryActiveIndex, setGalleryActiveIndex] = useState(null);
  const galleryItems = useMemo(
    () =>
      avatar && {
        startIndex: galleryActiveIndex !== -1 ? galleryActiveIndex : 0,
        items: [
          {
            thumbnail: avatar?.image.id,
            renderThumbInner: () => {
              const Icon = IconsByTypeMap[getFileTypeFromExtension(avatar?.image.fileName)];
              return (
                <>
                  <Icon />
                  <TextWithTooltip title={avatar?.image.fileName} titleVariant="subtitle2" />
                </>
              );
            },
            renderItem: () => (
              <PreviewBox
                key={avatar?.image.id}
                src={`${apiRoute}/attachments/${avatar?.image.id}/owners/${avatar?.image.ownerId}`}
                tabIndex={0}
                type={getFileTypeFromExtension(avatar?.image.fileName)}
              />
            ),
          },
        ],
      },
    [avatar, galleryActiveIndex],
  );

  const onAttachmentClick = useCallback(
    (i) => {
      if (avatar) {
        setGalleryActiveIndex(i);
        GA.logInteraction({
          category: GaCategories.BEHAVIOR,
          action: GaActions.BUTTON_CLICK,
          label: 'Student avatar preview - item click',
        });
      }
    },
    [avatar],
  );

  const handleRejectClose = () => {
    setIsRejectDialogOpen(false);
  };

  if (!student) {
    return null;
  }

  return (
    <Box>
      <Box
        display="flex"
        flexDirection={isWidthUpSm ? 'row' : 'column'}
        justifyContent="space-between"
      >
        <Box display="flex" flexDirection={isWidthUpSm ? 'row' : 'column'} gap={2}>
          <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
            onClick={() => onAttachmentClick(0)}
          >
            <DisplayAvatarEditorImage
              avatar={student?.avatar?.image}
              avatarSize={120}
              avatarTransforms={student?.avatar?.transforms}
            />
          </Box>
          <Box alignItems={!isWidthUpSm ? 'center' : ''} display="flex" flexDirection="column">
            <Typography component="h1" variant="h1">
              {student.firstName} {student.lastName} {student.shortName && `(${student.shortName})`}
            </Typography>
            <Box display="flex">
              <Typography isLabel variant="body2">
                {student.schoolName}
              </Typography>
              <Divider
                className="ayo-student-general-info__divider"
                flexItem
                orientation="vertical"
              />
              <Typography isLabel variant="body2">
                {student.grade}
                {!Number.isNaN(parseInt(student.grade, 10)) &&
                  t(studentGradeMap[student.grade] || 'th')}{' '}
                {!Number.isNaN(parseInt(student.grade, 10)) && t('grade')}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box alignItems="start" display="flex" gap={1}>
          <Button
            component={RouterLink}
            endIcon={<PaletteIcon />}
            gaLabel="Portfolio"
            to={`${
              userState.profile.role === RolesMap.GUARDIAN
                ? familyFeedRoute
                : StudentsPage[userState.profile?.role]
            }/${student.id}${portfoliosRoute}`}
          >
            {t('Portfolio')}
          </Button>
        </Box>
      </Box>
      <Grid>
        {withMood && (
          <Box mt={5}>
            {!!interventionsAlerts.length && (
              <Box mb={2}>
                {interventionsAlerts.map((alert) => (
                  <InterventionAlert
                    key={alert.id}
                    action={
                      <Box display="flex" flexDirection="row">
                        <Button
                          component={RouterLink}
                          gaLabel="See details"
                          to={`${StudentsPage[userState.profile?.role]}/${
                            student.id
                          }${moodsDetailsRoute}?moodId=${alert.resourceId}`}
                        >
                          {t('See details')}
                        </Button>
                        {alert.state === AlertsStates.CLOSED && (
                          <Box ml={2}>
                            <Button gaLabel="Got it" onClick={() => alertGotItHandler(alert.id)}>
                              {t('Got it')}
                            </Button>
                          </Box>
                        )}
                      </Box>
                    }
                    alertDetails={alert}
                    studentName={getFullName(student)}
                    translationKey={`interventionAlerts.${
                      userState.profile.id === alert?.metadata?.stateUpdateInitiator?.id
                        ? 'administrator'
                        : 'general'
                    }.${alert.state}`}
                  />
                ))}
              </Box>
            )}
            <MoodSectionTeacher studentId={student.id} />
          </Box>
        )}
        <Box my={isWidthUpSm ? 5 : 4}>
          <Divider />
        </Box>
      </Grid>
      {avatar && galleryActiveIndex !== null && (
        <PreviewGallery
          customButton={
            <Button
              className="ayo-preview-gallery__reject-button"
              gaLabel="Reject avatar photo button "
              onClick={() => {
                setGalleryActiveIndex(-1);
                setIsRejectDialogOpen(true);
              }}
              variant="secondary"
            >
              {t('Reject photo')}
            </Button>
          }
          galleryItems={galleryItems}
          isOpen={galleryActiveIndex >= 0}
          onClose={() => setGalleryActiveIndex(-1)}
        />
      )}
      <RejectDialog
        key={isRejectDialogOpen}
        isOpen={isRejectDialogOpen}
        onClose={handleRejectClose}
        studentId={student.id}
      />
    </Box>
  );
};

StudentGeneralInfo.propTypes = {
  student: PropTypes.instanceOf(Object),
  withMood: PropTypes.bool,
};

StudentGeneralInfo.defaultProps = {
  student: {},
  withMood: false,
};

export default StudentGeneralInfo;
