import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { DialogContent, Box, useTheme, useMediaQuery } from '@mui/material';

import { Typography, Button, DialogTitle } from '../../../atoms';
import { Dropdown, STTTextField } from '../../../moleculas';
import FileUpload from '../../fileupload/FileUpload';
import { getEmailValidationError } from '../../../../utils';
import { useFeedbackService, useOneTimeActionService } from '../../../../services';
import { UserContext, UserActions } from '../../../../context';
import {
  FeedbackPrefixTypes,
  InputsValidationErrors,
  InputsValidationRules,
  OneTimeActionsMap,
  SupportOptionsMap,
} from '../../../../constants/enums';
import { byteToMegaByteCoeff } from '../../../../constants/values';

const TITLE_MAX_LENGTH = 250;

const FeedbackForm = ({
  feedbackPrefix,
  fieldLabel,
  gaLabel,
  isDarkVariant,
  isDialog,
  isOneTimeFeedback,
  mode,
  onSubmit,
  prePopulatedFormData,
  text,
  title,
  titleLevel,
  titleVariant,
  withReporting,
}) => {
  const [emailValue, setEmailValue] = useState('');
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [feedbackValue, setFeedbackValue] = useState('');
  const [issueTitleValue, setIssueTitleValue] = useState('');
  const [isIssueReporting, setIsIssueReporting] = useState(mode === SupportOptionsMap.REPORT_ISSUE);
  const [feedbackErrorMessage, setFeedbackErrorMessage] = useState('');
  const [titleErrorMessage, setTitleErrorMessage] = useState('');
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const submitButtonRef = useRef();

  const { t, i18n } = useTranslation();

  const { postGeneralFeedback, reportIssue } = useFeedbackService();

  const { postOneTimeAction } = useOneTimeActionService();

  const { state: userState, dispatch: dispatchUserState } = useContext(UserContext);

  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  const handleEmailChange = (e) => setEmailValue(e.target.value);

  const handleFeedbackChange = (e) => {
    setFeedbackValue(e.target.value);
    if (feedbackErrorMessage) {
      setFeedbackErrorMessage('');
    }
  };

  const handleIssueTitleChange = (e) => {
    setIssueTitleValue(e.target.value);
    if (titleErrorMessage) {
      setTitleErrorMessage('');
    }
  };

  const handleOptionChange = (event) => {
    setTitleErrorMessage('');
    setFeedbackErrorMessage('');
    setIsIssueReporting(event.target.value === SupportOptionsMap.REPORT_ISSUE);
  };

  const isValid = useCallback(() => {
    const emailValidationError = getEmailValidationError(emailValue);
    let feedbackValidationError = '';
    let titleValidationError = '';

    if (isIssueReporting) {
      if (issueTitleValue.length < InputsValidationRules.MIN_INPUT_LENGTH) {
        titleValidationError = InputsValidationErrors(
          t,
          InputsValidationRules.MIN_INPUT_LENGTH,
        ).MIN_ERROR_TEXT;
      } else if (issueTitleValue.length > TITLE_MAX_LENGTH) {
        titleValidationError = InputsValidationErrors(t, TITLE_MAX_LENGTH).MAX_ERROR_TEXT;
      }
    }

    if (feedbackValue.length < InputsValidationRules.MIN_INPUT_LENGTH) {
      feedbackValidationError = InputsValidationErrors(
        t,
        InputsValidationRules.MIN_INPUT_LENGTH,
      ).MIN_ERROR_TEXT;
    } else if (feedbackValue.length > InputsValidationRules.MAX_INPUT_LENGTH) {
      feedbackValidationError = InputsValidationErrors(
        t,
        InputsValidationRules.MAX_INPUT_LENGTH,
      ).MAX_ERROR_TEXT;
    }

    setEmailErrorMessage(emailValidationError);
    setTitleErrorMessage(titleValidationError);
    setFeedbackErrorMessage(feedbackValidationError);
    return (
      (userState.profile || !emailValidationError) &&
      !feedbackValidationError &&
      !titleValidationError
    );
  }, [
    emailValue,
    feedbackValue.length,
    isIssueReporting,
    issueTitleValue.length,
    t,
    userState.profile,
  ]);

  const submitIssue = useCallback(() => {
    const browserInfo = `Browser: ${window.navigator.userAgent}, lang: ${i18n.language}, width:${document.body.clientWidth}, height:${document.body.clientHeight}`;
    reportIssue(issueTitleValue, feedbackValue, browserInfo, filesToUpload, t('Sending your issue'))
      .then(() => {
        onSubmit(true);
      })
      .catch(() => {
        onSubmit(false, issueTitleValue, feedbackValue, filesToUpload);
      });
  }, [feedbackValue, filesToUpload, i18n.language, issueTitleValue, onSubmit, reportIssue, t]);

  const submitFeedback = useCallback(() => {
    postGeneralFeedback(
      feedbackPrefix ? `${feedbackPrefix}: ${feedbackValue}` : feedbackValue,
      feedbackPrefix === FeedbackPrefixTypes.QUESTION
        ? t('Sending your question')
        : t('Sending your feedback'),
    )
      .then(() => {
        if (isOneTimeFeedback) {
          postOneTimeAction(OneTimeActionsMap.MY_STUDENTS_FEEDBACK);
          dispatchUserState({
            type: UserActions.SET_ONE_TIME_ACTION,
            data: OneTimeActionsMap.MY_STUDENTS_FEEDBACK,
          });
        }
        onSubmit(true);
      })
      .catch(() => {
        onSubmit(false);
      });
  }, [
    dispatchUserState,
    feedbackPrefix,
    feedbackValue,
    isOneTimeFeedback,
    onSubmit,
    postGeneralFeedback,
    postOneTimeAction,
    t,
  ]);

  const handleSubmitClick = useCallback(() => {
    if (isValid()) {
      if (isIssueReporting) {
        submitIssue();
      } else {
        submitFeedback();
      }
    }
  }, [isIssueReporting, isValid, submitFeedback, submitIssue]);

  const TitleComponent = isDialog ? DialogTitle : React.Fragment;
  const ContentComponent = isDialog ? DialogContent : React.Fragment;

  const inputLabel =
    fieldLabel || t(isIssueReporting ? 'Your issue' : 'Your thoughts and suggestions');
  const inputTitleId = `issueTitle`;
  const inputId = `feedbackLabel-${inputLabel.split(' ').join('-')}`;

  useEffect(() => {
    if (prePopulatedFormData) {
      setIssueTitleValue(prePopulatedFormData.issueTitleValue);
      setFeedbackValue(prePopulatedFormData.feedbackValue);
      setFilesToUpload(prePopulatedFormData.filesToUpload);
      setUploadedFiles(prePopulatedFormData.filesToUpload?.map((file) => ({ file, errors: [] })));
    }
  }, [prePopulatedFormData]);

  return (
    <>
      <TitleComponent disableTypography>
        <Typography
          component={titleLevel}
          isLightText={isDarkVariant}
          paragraph
          variant={titleVariant}
        >
          {title}
        </Typography>
      </TitleComponent>
      <ContentComponent>
        {text.map((textLine, idx) => (
          <Typography
            key={textLine}
            component="h3"
            isLightText={isDarkVariant}
            paragraph={idx === text.length - 1}
            variant={withReporting ? 'subtitle2' : 'body2'}
          >
            {textLine}
          </Typography>
        ))}
        {!userState.profile && (
          <Box mb={isWidthUpSm ? 2 : 1} py={2}>
            <STTTextField
              error={emailErrorMessage !== ''}
              fullWidth
              helperText={emailErrorMessage}
              label={t('Your email')}
              onChange={handleEmailChange}
              outlined={isDialog}
              value={emailValue}
            />
          </Box>
        )}
        <Box mb={isWidthUpSm ? 3 : 4} width="100%">
          {withReporting && (
            <Box pb={3}>
              <Dropdown
                defaultValue={mode}
                fullWidth
                handleChange={handleOptionChange}
                options={[
                  { value: SupportOptionsMap.FEEDBACK, label: t('Share feedback') },
                  { value: SupportOptionsMap.REPORT_ISSUE, label: t('Report an issue') },
                ]}
                outlined={isDialog}
              />
            </Box>
          )}
          {isIssueReporting && (
            <Box pb={isWidthUpSm ? 3 : 2}>
              <STTTextField
                error={titleErrorMessage !== ''}
                fullWidth
                gaLabel="Issue title"
                helperText={titleErrorMessage}
                InputLabelProps={{ id: inputTitleId }}
                inputProps={{
                  'aria-labelledby': inputTitleId,
                  required: true,
                  autoComplete: 'off',
                }}
                label={t('Title')}
                maxLength={TITLE_MAX_LENGTH}
                name={t('Title')}
                onChange={handleIssueTitleChange}
                outlined={isDialog}
                required
                value={issueTitleValue}
              />
            </Box>
          )}
          <STTTextField
            error={feedbackErrorMessage !== ''}
            fullWidth
            gaLabel={
              feedbackPrefix === FeedbackPrefixTypes.QUESTION
                ? 'FAQ question input'
                : 'Feedback input'
            }
            helperText={feedbackErrorMessage}
            InputLabelProps={{ id: inputId }}
            inputProps={{ 'aria-labelledby': inputId, required: true }}
            isLightText={isDarkVariant}
            label={inputLabel}
            maxLength={InputsValidationRules.MAX_INPUT_LENGTH}
            multiline
            name={inputLabel}
            onChange={handleFeedbackChange}
            outlined={isDialog}
            required
            rowsCount={4}
            value={feedbackValue}
          />
          {isIssueReporting && (
            <Box pt={3}>
              <FileUpload
                allowedExtensions={['jpg', 'jpeg', 'png', 'gif']}
                focusableRef={submitButtonRef}
                maxAttachmentsAllowed={3}
                maxFileSize={5 * byteToMegaByteCoeff}
                onFileAdd={(file) => {
                  setFilesToUpload((state) => [...state, file]);
                }}
                onFileRemove={(file) => {
                  setFilesToUpload((state) => [...state.filter((x) => x !== file)]);
                }}
                uploadedFiles={uploadedFiles}
              />
            </Box>
          )}
        </Box>
        <Box width={isWidthUpSm ? '50%' : '100%'}>
          <Button
            ref={submitButtonRef}
            fullWidth
            gaLabel={gaLabel}
            onClick={handleSubmitClick}
            variant="primary"
          >
            {t('Submit')}
          </Button>
        </Box>
      </ContentComponent>
    </>
  );
};

FeedbackForm.propTypes = {
  feedbackPrefix: PropTypes.string,
  fieldLabel: PropTypes.string,
  gaLabel: PropTypes.string,
  isDarkVariant: PropTypes.bool,
  isDialog: PropTypes.bool,
  isOneTimeFeedback: PropTypes.bool,
  mode: PropTypes.oneOf([SupportOptionsMap.FEEDBACK, SupportOptionsMap.REPORT_ISSUE]),
  onSubmit: PropTypes.func,
  prePopulatedFormData: PropTypes.shape({
    issueTitleValue: PropTypes.string,
    feedbackValue: PropTypes.string,
    filesToUpload: PropTypes.arrayOf(
      PropTypes.shape({
        file: {
          name: PropTypes.string,
          size: PropTypes.number,
          type: PropTypes.string,
        },
        errors: PropTypes.arrayOf(PropTypes.string),
      }),
    ),
  }),
  text: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  titleLevel: PropTypes.string,
  titleVariant: PropTypes.string,
  withReporting: PropTypes.bool,
};

FeedbackForm.defaultProps = {
  feedbackPrefix: '',
  fieldLabel: '',
  gaLabel: '',
  isDialog: false,
  isDarkVariant: false,
  isOneTimeFeedback: false,
  mode: '',
  onSubmit: () => {},
  prePopulatedFormData: null,
  text: [],
  title: '',
  titleLevel: 'h2',
  titleVariant: 'h2',
  withReporting: false,
};

export default FeedbackForm;
