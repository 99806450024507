import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Grid, Container } from '@mui/material';

const ScreenCenteredContainer = ({ children, className }) => (
  <Container className={classnames('ayo-page__screen--centered', className)}>
    <Grid alignItems="center" container direction="column" justifyContent="center">
      {children}
    </Grid>
  </Container>
);

ScreenCenteredContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
    .isRequired,
  className: PropTypes.string,
};

ScreenCenteredContainer.defaultProps = {
  className: '',
};

export default ScreenCenteredContainer;
