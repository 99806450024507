/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';

import { ReactComponent as DownloadIcon } from '../../../resources/icons/download.svg';
import { Button, Image, Typography } from '../../atoms';
import { personalizationBlock } from '../../../constants/propTypes';

const PersonalizationBlock = ({ config }) => {
  const { t } = useTranslation();

  const downloadOptions = [];

  config.downloadOptions.forEach((option) => {
    downloadOptions.push(
      <Button
        aria-label={option.ariaLabel}
        component="a"
        download
        endIcon={<DownloadIcon />}
        gaLabel={option.gaLabel}
        href={option.link}
      >
        {option.label}
      </Button>,
    );
  });

  return (
    <Container>
      <Grid container direction="column">
        <Grid container item justifyContent="center">
          <Grid item sm={7} xs={12}>
            <Typography align="center" paragraph variant="h1">
              {t(config.mainTitle)}
            </Typography>
            <Typography align="center">
              {t(config.firstSubtitle)} <b>{t(config.firstSubtitleBold)}</b>{' '}
              {t(config.firstSubtitleEnd)}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item justifyContent="center">
          <Grid item sm={7} xs={12}>
            <Box py={2}>{downloadOptions}</Box>
          </Grid>
        </Grid>
        <Grid container item justifyContent="center">
          <Grid item sm={6} xs={12}>
            <Box py={2}>
              <Image
                alt={t(config.illustrationText)}
                className="ayo-svg-illustration"
                src={config.illustrationImg}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

PersonalizationBlock.propTypes = {
  config: personalizationBlock,
};

PersonalizationBlock.defaultProps = {
  config: {},
};

export default PersonalizationBlock;
