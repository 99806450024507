import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { Box } from '@mui/material';

import { Link, Typography } from '../../../../../atoms';
import { formatSectionId, getFocusableElement, scrollAndFocusEl } from '../../../../../../utils';
import { plugRoute } from '../../../../../../constants/routes';
import { ReactComponent as InfoIcon } from '../../../../../../resources/icons/info.svg';

const getTransformedErrors = (errorsList) =>
  errorsList.reduce((acc, error) => {
    if (acc[error.section]) {
      acc[error.section].push(error);
    } else {
      acc[error.section] = [error];
    }
    return acc;
  }, {});

const LessonPageErrorBox = ({ errorsList, setActiveTab }) => {
  const { t } = useTranslation();

  const handleErrorLinkClick = useCallback(
    (e, item) => {
      e.preventDefault();
      const el = document.getElementById(item.id);
      const focusableEl = el ? getFocusableElement(el, item.errorText) : null;

      if (!focusableEl || !focusableEl.clientWidth) {
        if (item.tab) setActiveTab(() => item.tab);

        const accordionEl = document.getElementById(formatSectionId(item.section));
        const closedAccordionElButton = accordionEl?.querySelector(
          '.ayo-accordion__summary[role="button"][aria-expanded="false"]',
        );

        closedAccordionElButton?.click();

        item.onOpen?.();

        if (focusableEl) {
          setTimeout(() => {
            scrollAndFocusEl(focusableEl);
          }, 100);
        }
      } else {
        scrollAndFocusEl(focusableEl);
      }
    },
    [setActiveTab],
  );

  return (
    <Box className="ayo-lesson-page__error-box" mb={5} pb={2} pt={5} px={3}>
      <Box alignItems="center" display="flex" mb={2}>
        <InfoIcon />
        <Typography variant="subtitle1">
          {t('Please fix num error(s) on the page to publish the lesson', {
            num: errorsList.length,
          })}
        </Typography>
      </Box>
      {Object.entries(getTransformedErrors(errorsList)).map(([key, values]) => (
        <Box key={key} mb={4}>
          <Typography paragraph variant="subtitle2">{`${key} (${values.length})`}</Typography>
          {values.map((item) => (
            <Box key={`${item.lvl0Label}${item.errorText}`} mb={3}>
              <Typography variant="body3">
                {`${item.lvl0Label}${item.lvl1Label ? ` / ${item.lvl1Label}` : ''}${
                  item.lvl2Label ? ` / ${item.lvl2Label}` : ''
                }`}
              </Typography>
              <Link
                component={RouterLink}
                onClick={(e) => {
                  handleErrorLinkClick(e, item);
                }}
                to={plugRoute}
              >
                {item.errorText}
              </Link>
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  );
};

LessonPageErrorBox.propTypes = {
  errorsList: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  setActiveTab: PropTypes.func.isRequired,
};

export default LessonPageErrorBox;
