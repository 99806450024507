import React, { useEffect, useState, useCallback, useContext, useMemo } from 'react';
import { Box, useMediaQuery, useTheme, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Typography } from '../../atoms';
import {
  Dropdown,
  InformationalMessage,
  NewFeatureHotspot,
  StudentFilters,
  TabPanelWrapper,
} from '../../moleculas';
import { UserActions, UserContext } from '../../../context';
import { useOneTimeActionService } from '../../../services';
import {
  GA,
  isFilterItemDisabled,
  useProfile,
  checkEnvironmentVariable,
  isEmpty,
} from '../../../utils';
import { useSearchParams } from '../../../hooks';
import {
  GaActions,
  GaCategories,
  HighlightsInitiatives,
  InitiativesFilterQueryParams,
  OneTimeActionsMap,
} from '../../../constants/enums';
import { NewFeaturesIdsMap } from '../../../tours/common/NewFeaturesItemsProvider';
import {
  LAFilters,
  InterestsFilters,
  GoalsFilters,
  ExtracurricularsFilters,
  OpportunitiesFilters,
  ParentsAnswersFilters,
  AchievementsFilters,
  AptitudesFilters,
  MoodsFilters,
  EndorsementsFilters,
  PortfolioFilters,
} from '../../moleculas/student-filters/filters';

import LAHighlights from './highlights/LAHighlights';
import InterestsHighlights from './highlights/InterestsHighlights';
import GoalsHighlights from './highlights/GoalsHighlights';
import OpportunitiesHighlights from './highlights/OpportunitiesHighlights';
import ParentsAnswersHighlights from './highlights/ParentsAnswersHighlights';
import ExtracurricularsHighlights from './highlights/ExtracurricularsHighlights';
import AchievementsHighlights from './highlights/AchievementsHighlights';
import AptitudesHighlights from './highlights/AptitudesHighlights';
import MoodsHighlights from './highlights/MoodsHighlights';
import EndorsementsHighlights from './highlights/EndorsementsHighlights';
import PortfolioHighlights from './highlights/PortfolioHighlights';
import MoodsHighlightsReadOnly from './highlights/MoodsHighlightsReadOnly';
import SELRecommendationsHighlights from './highlights/SELRecommendationsHighlights';

const IS_MOBILE_APP_RELEASED = checkEnvironmentVariable(
  'REACT_APP_IS_MOBILE_APP_AVAILABLE',
  'true',
);

const getHighlightsTabs = () => ({
  ...(IS_MOBILE_APP_RELEASED && {
    [HighlightsInitiatives.MOODS]: {
      title: 'Mood check-ins',
      HighlightTabComponent: MoodsHighlights,
    },
  }),
  [HighlightsInitiatives.MOODS_READ_ONLY]: {
    title: 'Mood check-ins',
    HighlightTabComponent: MoodsHighlightsReadOnly,
  },
  [HighlightsInitiatives.INTERESTS]: {
    title: 'Interests',
    HighlightTabComponent: InterestsHighlights,
  },
  [HighlightsInitiatives.APTITUDES]: {
    title: 'Aptitudes',
    HighlightTabComponent: AptitudesHighlights,
  },
  [HighlightsInitiatives.ENDORSEMENTS]: {
    title: 'Endorsements',
    HighlightTabComponent: EndorsementsHighlights,
  },
  [HighlightsInitiatives.OPPORTUNITIES]: {
    title: 'Opportunities',
    HighlightTabComponent: OpportunitiesHighlights,
  },
  [HighlightsInitiatives.LEADERSHIP_ATTRIBUTES]: {
    title: 'Leadership Attributes',
    HighlightTabComponent: LAHighlights,
  },
  [HighlightsInitiatives.EXTRACURRICULARS]: {
    title: 'Extracurriculars',
    HighlightTabComponent: ExtracurricularsHighlights,
  },
  [HighlightsInitiatives.ACHIEVEMENTS]: {
    title: 'Achievements & participation',
    HighlightTabComponent: AchievementsHighlights,
  },
  [HighlightsInitiatives.GOALS]: { title: 'Goals', HighlightTabComponent: GoalsHighlights },
  [HighlightsInitiatives.PARENTS_ANSWERS]: {
    title: 'Parents’ answers (current year)',
    HighlightTabComponent: ParentsAnswersHighlights,
  },
  [HighlightsInitiatives.PORTFOLIO]: {
    title: 'Portfolio',
    HighlightTabComponent: PortfolioHighlights,
  },
  [HighlightsInitiatives.SEL_RECOMMENDATIONS]: {
    title: 'SEL strategies',
    HighlightTabComponent: SELRecommendationsHighlights,
  },
});

const getFilters = () => ({
  ...(IS_MOBILE_APP_RELEASED && {
    [HighlightsInitiatives.MOODS]: {
      name: 'Mood check-ins',
      FilterBox: MoodsFilters,
      isDisabled: (stats, totalStudentsCount) =>
        stats.moods &&
        Object.values(stats.moods).every((statValue) =>
          isFilterItemDisabled(statValue, totalStudentsCount),
        ),
    },
  }),
  [HighlightsInitiatives.INTERESTS]: {
    name: 'Interests',
    FilterBox: InterestsFilters,
    isDisabled: (stats, totalStudentsCount) =>
      stats.interests &&
      Object.values(stats.interests).every((statValue) =>
        isFilterItemDisabled(statValue.count, totalStudentsCount),
      ),
  },
  [HighlightsInitiatives.APTITUDES]: {
    name: 'Aptitudes',
    FilterBox: AptitudesFilters,
    isDisabled: (stats, totalStudentsCount) =>
      stats.aptitudes &&
      Object.values(stats.aptitudes).every((statValue) =>
        isFilterItemDisabled(statValue, totalStudentsCount),
      ),
  },
  [HighlightsInitiatives.ENDORSEMENTS]: {
    name: 'Endorsements',
    FilterBox: EndorsementsFilters,
    isDisabled: (stats, totalStudentsCount) =>
      !stats.endorsements ||
      (isFilterItemDisabled(stats.endorsements.pending, totalStudentsCount) &&
        stats.endorsements.switchedByReason &&
        Object.values(stats.endorsements.switchedByReason).every(({ count }) => !count) &&
        stats.endorsements.switchedByType &&
        Object.values(stats.endorsements.switchedByType).every(({ count }) =>
          isFilterItemDisabled(count, totalStudentsCount),
        ) &&
        stats.endorsements.selected &&
        Object.values(stats.endorsements.selected).every(({ count }) =>
          isFilterItemDisabled(count, totalStudentsCount),
        ) &&
        stats.endorsements.interested &&
        Object.values(stats.endorsements.interested).every(({ count }) =>
          isFilterItemDisabled(count, totalStudentsCount),
        )),
  },
  [HighlightsInitiatives.OPPORTUNITIES]: {
    name: 'Opportunities',
    FilterBox: OpportunitiesFilters,
    isDisabled: (stats, totalStudentsCount) =>
      stats.opportunities &&
      Object.values(stats.opportunities).every((statValue) =>
        isFilterItemDisabled(statValue, totalStudentsCount),
      ),
  },
  [HighlightsInitiatives.LEADERSHIP_ATTRIBUTES]: {
    name: 'Leadership Attributes',
    FilterBox: LAFilters,
    isDisabled: (stats) =>
      stats.leadershipAttributes &&
      Object.values(stats.leadershipAttributes).every(
        (statValue) => statValue.have === 0 || statValue.notHave === 0,
      ),
  },
  [HighlightsInitiatives.EXTRACURRICULARS]: {
    name: 'Extracurriculars',
    FilterBox: ExtracurricularsFilters,
    isDisabled: (stats, totalStudentsCount) =>
      stats.extracurriculars &&
      Object.values(stats.extracurriculars).every((statValue) =>
        isFilterItemDisabled(statValue, totalStudentsCount),
      ),
  },
  [HighlightsInitiatives.ACHIEVEMENTS]: {
    name: 'Achievements',
    FilterBox: AchievementsFilters,
    isDisabled: (stats, totalStudentsCount) =>
      stats.achievements &&
      Object.values(stats.achievements).every((statValue) =>
        isFilterItemDisabled(statValue, totalStudentsCount),
      ),
  },
  [HighlightsInitiatives.GOALS]: {
    name: 'Goals',
    FilterBox: GoalsFilters,
    isDisabled: (stats, totalStudentsCount) =>
      stats.goals &&
      Object.values(stats.goals).every((statValue) =>
        isFilterItemDisabled(statValue, totalStudentsCount),
      ),
  },
  [HighlightsInitiatives.PARENTS_ANSWERS]: {
    name: 'Parents’ answers (current year)',
    FilterBox: ParentsAnswersFilters,
    isDisabled: (stats, totalStudentsCount) =>
      stats.parentsAnswers &&
      Object.values(stats.parentsAnswers).every((statValue) =>
        isFilterItemDisabled(statValue, totalStudentsCount),
      ),
  },
  [HighlightsInitiatives.PORTFOLIO]: {
    name: 'Portfolio',
    FilterBox: PortfolioFilters,
    isDisabled: (stats, totalStudentsCount) =>
      stats.portfolio &&
      Object.values(stats.portfolio).every((statValue) =>
        isFilterItemDisabled(statValue, totalStudentsCount),
      ),
  },
});

const FilterCategoriesTabs = {
  [HighlightsInitiatives.ENDORSEMENTS_TYPES]: HighlightsInitiatives.ENDORSEMENTS,
  [HighlightsInitiatives.ENDORSEMENTS_REASONS]: HighlightsInitiatives.ENDORSEMENTS,
  [HighlightsInitiatives.ENDORSEMENTS_INTERESTED]: HighlightsInitiatives.ENDORSEMENTS,
};

const HighlightsBlock = ({
  title,
  selectedInitiative,
  totalStudentsCount,
  stats,
  onFiltersUpdate,
  initiatives,
  onInitiativeChange,
  timePeriod,
  timePeriodOptions,
  onTimePeriodChange,
  infoMessage,
}) => {
  const search = useSearchParams();
  const { t } = useTranslation();

  const { dispatch: dispatchUserState } = useContext(UserContext);

  const filtersConfig = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(getFilters()).filter(([configInitiativeName]) =>
          initiatives.includes(configInitiativeName),
        ),
      ),
    [initiatives],
  );

  const highlightsConfig = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(getHighlightsTabs()).filter(([configInitiativeName]) =>
          initiatives.includes(configInitiativeName),
        ),
      ),
    [initiatives],
  );

  const [currentTab, setCurrentTab] = useState(
    selectedInitiative ||
      search.get(InitiativesFilterQueryParams.FILTER_CATEGORY) ||
      HighlightsInitiatives[Object.keys(highlightsConfig)[0]],
  );

  const theme = useTheme();

  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  const { getOneTimeActionStatus } = useProfile();
  const { postOneTimeAction } = useOneTimeActionService();

  const [isInfoMessageVisible, setIsInfoMessageVisible] = useState(false);
  useEffect(() => {
    if (infoMessage) {
      setIsInfoMessageVisible(
        !getOneTimeActionStatus(OneTimeActionsMap.HIGHLIGHTS_GRAPHS_NOTIFICATION),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onTabChange = useCallback((e) => {
    setCurrentTab(e.target.value);
    GA.logInteraction({
      category: GaCategories.BEHAVIOR,
      action: GaActions.DROPDOWN_CHANGE,
      label: e.target.value,
    });
  }, []);

  const onInfoMessageHide = useCallback(() => {
    setIsInfoMessageVisible(false);
    postOneTimeAction(OneTimeActionsMap.HIGHLIGHTS_GRAPHS_NOTIFICATION);
    dispatchUserState({
      type: UserActions.SET_ONE_TIME_ACTION,
      data: OneTimeActionsMap.HIGHLIGHTS_GRAPHS_NOTIFICATION,
    });
  }, [dispatchUserState, postOneTimeAction]);

  useEffect(() => {
    const filterCategory = search.get(InitiativesFilterQueryParams.FILTER_CATEGORY);
    if (filterCategory) {
      setCurrentTab(FilterCategoriesTabs[filterCategory] || filterCategory);
    }
  }, [search]);

  useEffect(() => {
    onInitiativeChange?.(currentTab);
  }, [currentTab, onInitiativeChange]);

  if (!stats) return null;

  return (
    <Box width="100%">
      {isInfoMessageVisible && isWidthUpSm && (
        <Grid container>
          <Grid item sm={7} xs={12}>
            <Box mt={2}>
              <InformationalMessage onClick={onInfoMessageHide} text={t(infoMessage)} />
            </Box>
          </Grid>
        </Grid>
      )}
      {isWidthUpSm && (
        <Box className="ayo-highlights-block">
          <Box alignItems="center" display="flex" flexDirection="row" mb={3}>
            <Box mr={3}>
              <Typography variant="subtitle1">{t(title)}</Typography>
            </Box>
            {!selectedInitiative && (
              <Dropdown
                className="highlights-selection"
                handleChange={onTabChange}
                isResponsive
                options={Object.entries(highlightsConfig).map(([initiative, config]) => ({
                  value: initiative,
                  label: t(config.title),
                }))}
                value={currentTab}
              />
            )}
            {!isEmpty(timePeriodOptions) && (
              <Dropdown
                className="highlights-selection"
                handleChange={(e) => {
                  onTimePeriodChange(e.target.value, timePeriod);
                }}
                isResponsive
                options={timePeriodOptions}
                value={timePeriod}
              />
            )}
            <NewFeatureHotspot
              dark
              id={NewFeaturesIdsMap.CAMPUS_HIGHLIGHTS_NEW_INITIATIVES}
              isClickable
              label={t('Campus Highlights: additional initiatives')}
            />
          </Box>
          {Object.entries(highlightsConfig).map(([initiative, config]) => (
            <TabPanelWrapper key={initiative} currentValue={currentTab} value={initiative}>
              <config.HighlightTabComponent
                onFiltersUpdate={onFiltersUpdate}
                stats={stats}
                totalStudentsCount={totalStudentsCount}
              />
            </TabPanelWrapper>
          ))}
        </Box>
      )}
      {!!onFiltersUpdate && (
        <Box mt={isWidthUpSm ? 5 : 6}>
          <StudentFilters
            filtersConfig={filtersConfig}
            onFiltersUpdate={onFiltersUpdate}
            selectedInitiative={selectedInitiative}
            stats={stats}
            totalStudentsCount={totalStudentsCount}
          />
        </Box>
      )}
    </Box>
  );
};

HighlightsBlock.propTypes = {
  infoMessage: PropTypes.string,
  initiatives: PropTypes.arrayOf(PropTypes.string),
  onFiltersUpdate: PropTypes.func,
  onInitiativeChange: PropTypes.func,
  onTimePeriodChange: PropTypes.func,
  selectedInitiative: PropTypes.oneOf(Object.values(HighlightsInitiatives)),
  stats: PropTypes.instanceOf(Object),
  timePeriod: PropTypes.string,
  timePeriodOptions: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.string, label: PropTypes.string }),
  ),
  title: PropTypes.string,
  totalStudentsCount: PropTypes.number,
};

HighlightsBlock.defaultProps = {
  infoMessage: '',
  initiatives: Object.values(HighlightsInitiatives),
  title: 'Campus Highlights',
  onFiltersUpdate: null,
  onInitiativeChange: null,
  onTimePeriodChange: null,
  selectedInitiative: null,
  stats: null,
  timePeriod: null,
  timePeriodOptions: null,
  totalStudentsCount: 0,
};

export default HighlightsBlock;
