import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

import { ReactComponent as LabelBottomTip } from '../../../../resources/images/label_bottom_tip.svg';
import { Typography } from '../../../atoms';

const TimelineChip = ({ title, className }) => (
  <Box className={classnames('ayo-timeline-chip', className)}>
    <Typography variant="caption">{title}</Typography>
    <LabelBottomTip />
  </Box>
);

TimelineChip.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
};

TimelineChip.defaultProps = {
  title: '',
  className: '',
};

export default TimelineChip;
