import { filterUniqueValuesByKey, sortArrayByKey } from '../array-helpers/arrayHelpers';
import { convertDataToAchievement } from '../achievements/achievementsHelper';
import { PortfolioSectionSettings, PortfolioSectionTypes } from '../../constants/enums';
import { converterBooleanString } from '../string-helpers/stringHelpers';

const MIN_REACTION_VALUE = 1;

const sortInterestsByReactionAndByName = (interest) =>
  interest?.sort((a, b) => b.reaction - a.reaction || a.name.localeCompare(b.name));

export const transformInterestsMetaData = (data) =>
  sortInterestsByReactionAndByName(
    data?.selectedInterests?.filter(({ reaction }) => reaction > MIN_REACTION_VALUE),
  );

const sortExtracurriculars = (data) =>
  data.sort((a, b) => b.year - a.year || a.name.localeCompare(b.name));

const transformExtracurricularsMetaData = (data) =>
  filterUniqueValuesByKey(sortExtracurriculars(data), 'name');

const sortEndorsements = (data) =>
  data.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));

const transformEndorsementsMetaData = (data) => sortEndorsements(data.endorsements).slice(0, 1);

const findAttributeLikes = (attributes) =>
  attributes.reduce(
    (acc, attribute) => [
      ...acc,
      {
        ...attribute,
        totalScore: Object.values(attribute.maturityLevels).reduce(
          (prevGroup, currentGroup) => prevGroup + currentGroup.completedGroups,
          0,
        ),
      },
    ],
    [],
  );

const transformLAMetaData = (data) =>
  sortArrayByKey(
    findAttributeLikes(data).filter(({ totalScore }) => totalScore),
    'totalScore',
  );

export const transformCertificateId = (certificate) =>
  `${certificate.title?.trim()}___${certificate.organization?.trim()}___${
    certificate.obtainingDate
  }`;

const transformAchievementsMetaData = (data) =>
  data.map((achievement) => convertDataToAchievement(achievement));

const setPortfolioEntitiesData = (entities, metaData, idKey) =>
  entities.map((entity) => metaData.find((item) => item[idKey] === entity[idKey]) || entity);

const setPortfolioItemsToAddData = (entities, metaData, idKey) =>
  metaData.filter((item) => !entities.some((entity) => item[idKey] === entity[idKey]));

export const getPortfolioMetadata = (section, data) => {
  switch (section) {
    case PortfolioSectionTypes.PORTFOLIO__ACHIEVEMENTS:
      return transformAchievementsMetaData(data);
    case PortfolioSectionTypes.PORTFOLIO__ENDORSEMENTS:
      return transformEndorsementsMetaData(data);
    case PortfolioSectionTypes.PORTFOLIO__EXTRACURRICULARS:
      return transformExtracurricularsMetaData(data);
    case PortfolioSectionTypes.PORTFOLIO__INTERESTS:
      return transformInterestsMetaData(data);
    case PortfolioSectionTypes.PORTFOLIO__LEADERSHIP_ATTRIBUTES:
      return transformLAMetaData(data);
    default:
      return data;
  }
};

export const PortfolioEntitiesData = {
  PORTFOLIO__ACHIEVEMENTS: (entities, metaData, idKey) =>
    transformAchievementsMetaData(
      setPortfolioEntitiesData(entities, metaData, idKey).sort(
        (a, b) => Math.max(...b.year) - Math.max(...a.year),
      ),
    ),
  PORTFOLIO__APTITUDES: (entities, metaData, idKey) =>
    setPortfolioEntitiesData(entities, metaData, idKey),
  PORTFOLIO__ENDORSEMENTS: (entities, metaData, idKey) =>
    setPortfolioEntitiesData(entities, metaData, idKey),
  PORTFOLIO__EXTRACURRICULARS: (entities, metaData, idKey) =>
    transformExtracurricularsMetaData(setPortfolioEntitiesData(entities, metaData, idKey)),
  PORTFOLIO__INTERESTS: (entities, metaData, idKey) =>
    sortInterestsByReactionAndByName(setPortfolioEntitiesData(entities, metaData, idKey)),
  PORTFOLIO__LEADERSHIP_ATTRIBUTES: (entities, metaData, idKey) =>
    transformLAMetaData(setPortfolioEntitiesData(entities, metaData, idKey)),
  PORTFOLIO__CERTIFICATES: (entities) => entities,
  PORTFOLIO__ACADEMIC_RECORD: (entities) => entities,
  PORTFOLIO__ESSAYS: (entities) => entities,
  PORTFOLIO__RECOMMENDATIONS: (entities, metaData, idKey) =>
    sortArrayByKey(setPortfolioEntitiesData(entities, metaData, idKey), 'createdDate', 'date'),
  PORTFOLIO__TEST_SCORES: (entities) => entities,
};

export const PortfolioItemsToAddData = {
  PORTFOLIO__ACHIEVEMENTS: (entities, metaData, idKey) =>
    setPortfolioItemsToAddData(entities, metaData, idKey),
  PORTFOLIO__APTITUDES: (entities, metaData, idKey) =>
    setPortfolioItemsToAddData(entities, metaData, idKey),
  PORTFOLIO__ENDORSEMENTS: (entities, metaData, idKey) =>
    sortEndorsements(setPortfolioItemsToAddData(entities, metaData, idKey)).slice(0, 1),
  PORTFOLIO__EXTRACURRICULARS: (entities, metaData, idKey) =>
    transformExtracurricularsMetaData(setPortfolioItemsToAddData(entities, metaData, idKey)),
  PORTFOLIO__INTERESTS: (entities, metaData, idKey) =>
    sortInterestsByReactionAndByName(
      setPortfolioItemsToAddData(entities, metaData, idKey).filter(
        ({ reaction }) => reaction > MIN_REACTION_VALUE,
      ),
    ),
  PORTFOLIO__LEADERSHIP_ATTRIBUTES: (entities, metaData, idKey) =>
    transformLAMetaData(setPortfolioItemsToAddData(entities, metaData, idKey)),
  PORTFOLIO__CERTIFICATES: (entities, metaData, idKey) =>
    setPortfolioItemsToAddData(
      entities.map((entity) => ({
        ...entity,
        id: entity.id ? entity.id : transformCertificateId(entity),
      })),
      metaData.map((data) => ({ ...data, id: data.id ? data.id : transformCertificateId(data) })),
      idKey,
    ),
  PORTFOLIO__EMPLOYMENT: (entities, metaData, idKey) =>
    setPortfolioItemsToAddData(entities, metaData, idKey),
  PORTFOLIO__ACADEMIC_RECORD: (entities, metaData, idKey) =>
    setPortfolioItemsToAddData(entities, metaData, idKey),
  PORTFOLIO__ESSAYS: (entities, metaData, idKey) =>
    setPortfolioItemsToAddData(entities, metaData, idKey),
  PORTFOLIO__RECOMMENDATIONS: (entities, metaData, idKey) =>
    sortArrayByKey(setPortfolioItemsToAddData(entities, metaData, idKey), 'createdDate', 'date'),
  PORTFOLIO__TEST_SCORES: (entities, metaData, idKey) =>
    setPortfolioItemsToAddData(entities, metaData, idKey),
  PORTFOLIO__VOLUNTEERING: (entities, metaData, idKey) =>
    setPortfolioItemsToAddData(entities, metaData, idKey),
};

export const transformFileUploadData = (entityData) => {
  const updatedData = { ...entityData };
  if (!updatedData.attachments.length) {
    delete updatedData.attachments;
  }
  if (!updatedData.removedAttachments?.length) {
    delete updatedData.removedAttachments;
  }
  return updatedData;
};

export const getFirstTextFromEditor = (textJson) => {
  const firstText = JSON.parse(textJson)?.ops.find((obj) =>
    Object.entries(obj).find(
      ([objKey, value]) =>
        objKey === 'insert' && typeof value === 'string' && value.replace('\n', '').length,
    ),
  );

  return firstText?.insert?.trim() ?? '';
};

const ESSAY_TITLE_MAX_LENGTH = 110;

export const getEssayTitle = (textJson) =>
  getFirstTextFromEditor(textJson).slice(0, ESSAY_TITLE_MAX_LENGTH) || 'Application essay';

export const transformRecommendationRequestBody = (recommendation) => {
  const { reachTextEditorData } = recommendation;
  const requestBody = {
    ...recommendation,
    reachTextEditorData: {
      ...reachTextEditorData,
      attachmentsIds: reachTextEditorData.attachments.map((attachment) => attachment.id),
    },
  };
  delete requestBody.reachTextEditorData.attachments;
  return requestBody;
};

export const getOrderedSectionsByVisibility = (portfolio, portfolioSettings) => {
  const visibleKeys = [];
  const hiddenKeys = [];
  const innerPortfolioSettings =
    portfolioSettings ||
    Object.entries(portfolio.sections)
      .map(([key, value]) => ({
        sectionName: key,
        sectionSettings: value?.sectionSettings?.filter(
          ({ name }) => name === PortfolioSectionSettings.DISPLAY_SECTION.type,
        ),
      }))
      .sort((a, b) => a.sectionName.localeCompare(b.sectionName));
  portfolio?.portfolioSectionsOrder?.forEach((key) => {
    const { value, defaultValue } = innerPortfolioSettings?.find(
      ({ sectionName }) => sectionName === key,
    ).sectionSettings[0];
    const isVisible = converterBooleanString(value ?? defaultValue, true);
    if (isVisible) {
      visibleKeys.push(key);
    } else {
      hiddenKeys.push(key);
    }
  });
  const result = {
    visibleKeys,
    allOrderedSections: [...visibleKeys, ...hiddenKeys],
  };
  return result;
};
