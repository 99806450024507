import React, { useContext } from 'react';
import { Box } from '@mui/material';

import { UserContext } from '../../../../../context';
import {
  OneTimeActionsMap,
  StudentActivityCriterions,
  HighlightsInitiatives,
  RolesMap,
} from '../../../../../constants/enums';
import { leadershipRoute } from '../../../../../constants/routes';
import TrainAyoStudentSelectionPage from '../components/train-ayo-student-selection/TrainAyoStudentSelectionPage';
import TrainAyoKidsSelectionPage from '../components/train-ayo-kids-selection/TrainAyoKidsSelectionPage';

const LeadershipAttributesStudentSelectionPage = () => {
  const { state: userState } = useContext(UserContext);
  const isGuardian = userState.profile.role === RolesMap.GUARDIAN;
  return (
    <Box>
      {isGuardian ? (
        <TrainAyoKidsSelectionPage
          activityCriterion={StudentActivityCriterions.LEADERSHIP_ATTRIBUTE}
          initiativeRoute={leadershipRoute}
          notificationAction={OneTimeActionsMap.ACTIVITY_LA_NOTIFICATION}
          notificationText="A green ribbon means there’s information about the Leadership Attributes in the profile"
          progressAction={OneTimeActionsMap.TRAIN_AYO_LA_OPEN}
          secondaryText="Please select one of your kids below to start sharing your observations about their Leadership Attributes"
          title="Leadership Attributes"
        />
      ) : (
        <TrainAyoStudentSelectionPage
          activityCriterion={StudentActivityCriterions.LEADERSHIP_ATTRIBUTE}
          highlightsValue={HighlightsInitiatives.LEADERSHIP_ATTRIBUTES}
          initiativeRoute={leadershipRoute}
          notificationAction={OneTimeActionsMap.ACTIVITY_LA_NOTIFICATION}
          notificationText="A green ribbon means there’s information about the Leadership Attributes in the profile"
          progressAction={OneTimeActionsMap.TRAIN_AYO_LA_OPEN}
          relatedActivity="Leadership Attributes"
          secondaryText="Please select a student or search for one to start giving your input about their Leadership Attributes"
          title="Leadership Attributes"
        />
      )}
    </Box>
  );
};

export default LeadershipAttributesStudentSelectionPage;
