import { InterestBubbleConfig, InterestTypes } from '../../constants/enums';

export const filterOutNotInterested = (selectedInterests) =>
  selectedInterests.reduce(
    (acc, interest) =>
      interest.reaction > 1
        ? { ...acc, liked: [...acc.liked, interest] }
        : { ...acc, disliked: [...acc.disliked, interest] },
    { liked: [], disliked: [] },
  );

export const getInterestType = (interest) => {
  if (interest.reaction > 1) {
    return InterestTypes.LIKED;
  }
  if (interest.reaction === 0) {
    return InterestTypes.NOT_INTERESTED;
  }
  if (interest.suggested) {
    return InterestTypes.SUGGESTED;
  }
  return InterestTypes.OTHER;
};

export const getInterestBubbleProperties = (interest) => {
  const interestType = getInterestType(interest);
  const interestTypeProperties = InterestBubbleConfig[interestType];
  return {
    ...interestTypeProperties,
    interestType,
  };
};

export const hashString = (str) => {
  let hash = 0;
  let i;
  let chr;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i += 1) {
    chr = str.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hash = (hash << 5) - hash + chr;
    // eslint-disable-next-line no-bitwise
    hash |= 0;
  }
  return hash;
};

export const filterChangedInterests = (interests) =>
  interests.reduce(
    (acc, interest) =>
      interest.reaction !== 0
        ? { ...acc, liked: [...acc.liked, interest] }
        : interest.prevReaction
        ? { disliked: [...acc.disliked, interest], liked: [...acc.liked, interest] }
        : { ...acc, disliked: [...acc.disliked, interest] },
    { liked: [], disliked: [] },
  );

const MIN_SPACER = 16;
const SPACER_RANGE = 40;
const SPACER_RANGE_MOBILE = 6;
const LIKED_INTEREST_ADDITIONAL_SPACER = 16;

export const getInterestWithSpacer = (interest, isWidthUpSm) => {
  const currenSpaceRange = isWidthUpSm ? SPACER_RANGE : SPACER_RANGE_MOBILE;
  const spacer =
    MIN_SPACER +
    (Math.abs(hashString(interest.name)) % currenSpaceRange) +
    (interest.interestType === InterestTypes.LIKED ? LIKED_INTEREST_ADDITIONAL_SPACER : 0);
  return {
    ...interest,
    r: interest.viewR + spacer,
    spacer,
  };
};

const flatten = (children, extractChildren, level) =>
  Array.prototype.concat.apply(
    children.map((x) => ({ ...x, level: level || 1 })),
    children.map((x) => flatten(extractChildren(x) || [], extractChildren, (level || 1) + 1)),
  );

const extractChildren = (x) => x.childCategories;

export const findInterestById = (interests, id) => {
  const foundInterest = interests.find((interest) => interest.id === id);

  if (foundInterest) {
    return foundInterest;
  }

  for (let i = 0; i < interests.length; i += 1) {
    const subInterests = flatten(extractChildren(interests[i]), extractChildren);
    const foundSubInterest = subInterests.find((subInterest) => subInterest.id === id);

    if (foundSubInterest) return foundSubInterest;
  }

  return null;
};

const MINIMUM_LEVEL_0_SPACER = 10;
const LEVEL_0_SPACER_RANGE = 25;
const MINIMUM_SUBCATEGORY_SPACER = 6;
const SUBCATEGORY_SPACER_RANGE = 25;

export const transformInterestToBubble = (interest) => {
  const spacer =
    MINIMUM_LEVEL_0_SPACER + (Math.abs(hashString(interest.name)) % LEVEL_0_SPACER_RANGE);
  const subInterests =
    interest.subInterests ||
    flatten(extractChildren(interest) || [], extractChildren)
      .map((subInterest) => {
        const subInterestSpacer =
          MINIMUM_SUBCATEGORY_SPACER +
          (Math.abs(hashString(subInterest.name)) % SUBCATEGORY_SPACER_RANGE);
        return {
          ...subInterest,
          ...getInterestBubbleProperties(subInterest),
          spacer: subInterestSpacer,
        };
      })
      .map((subInterest) => ({ ...subInterest, r: subInterest.r + subInterest.spacer }));
  return {
    ...interest,
    ...getInterestBubbleProperties(interest),
    level: 0,
    subInterests,
    spacer,
  };
};
