import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Box, ClickAwayListener, Popper, useMediaQuery, useTheme } from '@mui/material';

import { ActionsMenu, Button, RadioGroup, Typography } from '../../../../atoms';
import { FamilyFeedContext } from '../../../../../context';
import { useFamilyFeed } from '../../../../../hooks';
import { FeedPostCategories, FeedPostCategoriesLabels } from '../../../../../constants/enums';
import { ReactComponent as FilterIcon } from '../../../../../resources/icons/filter_list.svg';
import { ReactComponent as ExpandIcon } from '../../../../../resources/icons/chevron_down.svg';
import { ReactComponent as CollapseIcon } from '../../../../../resources/icons/chevron_up.svg';

const BUTTON_MARGIN = 8;

const PostsFilter = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
  const { state: familyFeedState } = useContext(FamilyFeedContext);
  const { activeFlterCategory, postFilterCategories, posts } = familyFeedState;

  const { setActiveFilterCategory } = useFamilyFeed();
  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const [filterButtons, setFilterButtons] = useState(null);
  const [menuItems, setMenuItems] = useState([]);
  const [radioItems, setRadioItems] = useState([]);
  const [isWithMoreButton, setIsWithMoreButton] = useState(true);
  const [isCalculating, setIsCalculating] = useState(isWidthUpSm);
  const [readyToCalculate, setReadyToCalculate] = useState(false);
  const [checkedCategory, setCheckedCategory] = useState(activeFlterCategory);
  const containerRef = useRef();
  const moreButtonRef = useRef();
  const anchorRef = useRef();

  const handlePopperToggle = useCallback(() => {
    setIsPopperOpen((prevOpen) => !prevOpen);
  }, []);

  const handlePopperCancel = useCallback(() => {
    setIsPopperOpen(false);
    setCheckedCategory(activeFlterCategory);
  }, [activeFlterCategory]);

  const applyFilterCategory = useCallback(
    (category) => {
      setActiveFilterCategory(category);
      setCheckedCategory(category);
      if (isPopperOpen) {
        setIsPopperOpen(false);
      }
    },
    [setActiveFilterCategory, setIsPopperOpen, setCheckedCategory, isPopperOpen],
  );

  const handleRadioChange = useCallback((e) => {
    setCheckedCategory(e.target.value);
  }, []);

  const getAriaLabel = useCallback(
    (category) =>
      `${t(FeedPostCategoriesLabels[category])}${
        category === activeFlterCategory ? `- ${t('selected')}` : ''
      }`,
    [activeFlterCategory, t],
  );

  const getMenuItems = useCallback(
    (items) =>
      items.map((filterCategory) => ({
        selected: filterCategory.category === activeFlterCategory,
        text: t(FeedPostCategoriesLabels[filterCategory.category]),
        ariaLabel: getAriaLabel(filterCategory.category),
        handler: () => {
          applyFilterCategory(filterCategory.category);
        },
        id: filterCategory.category,
        gaLabel: `filter by ${filterCategory.category}`,
      })),
    [activeFlterCategory, applyFilterCategory, t, getAriaLabel],
  );

  const getRadioItems = useCallback(
    (items) =>
      items.map((filterCategory) => ({
        text: t(FeedPostCategoriesLabels[filterCategory.category]),
        value: filterCategory.category,
      })),
    [t],
  );

  const calculateItems = useCallback(() => {
    const containerWidth = containerRef?.current?.offsetWidth;
    const containerChildren = containerRef?.current?.childNodes;
    const moreButtonWidth = moreButtonRef?.current?.offsetWidth;
    let sumWidth = moreButtonWidth;
    let index = 0;
    for (index; index < containerChildren?.length; index += 1) {
      sumWidth += containerChildren[index].offsetWidth + BUTTON_MARGIN;
      if (sumWidth > containerWidth) {
        setFilterButtons(postFilterCategories.slice(0, index - 1));
        break;
      }
    }
    if (index < postFilterCategories?.length) {
      setMenuItems(
        getMenuItems(postFilterCategories.slice(index - 1, postFilterCategories.length)),
      );
    } else {
      setIsWithMoreButton(false);
    }
    setIsCalculating(false);
  }, [getMenuItems, postFilterCategories, containerRef, moreButtonRef]);

  const getItems = useCallback(() => {
    if (isWidthUpSm) {
      setIsCalculating(true);
      setFilterButtons(postFilterCategories);
      setIsWithMoreButton(true);
      setTimeout(() => {
        calculateItems();
      }, 0);
    } else {
      setRadioItems(getRadioItems(postFilterCategories));
      setFilterButtons(false);
    }
  }, [isWidthUpSm, postFilterCategories, calculateItems, getRadioItems]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setActiveFilterCategory(FeedPostCategories.ALL_UPDATES), []);

  useEffect(() => {
    if (!readyToCalculate) return null;
    getItems();
    window.addEventListener('resize', getItems);
    return () => window.removeEventListener('resize', getItems);
  }, [getItems, readyToCalculate]);

  if (activeFlterCategory === FeedPostCategories.ALL_UPDATES && !posts?.length) {
    return null;
  }

  const renderedFilterButtons = filterButtons ?? postFilterCategories;

  return (
    <Box
      className={classNames('ayo-post-filter', {
        'ayo-post-filter__hidden': isCalculating,
      })}
    >
      {renderedFilterButtons && (
        <Box
          ref={(ref) => {
            containerRef.current = ref;
            setReadyToCalculate(true);
          }}
          className="ayo-post-filter__buttons-block"
        >
          {renderedFilterButtons.map((filter) => (
            <Button
              key={filter.category}
              aria-label={getAriaLabel(filter.category)}
              className={classNames('ayo-post-filter__button', {
                'ayo-post-filter__button--active': filter.category === activeFlterCategory,
              })}
              gaLabel={`filter by ${filter.category}`}
              onClick={() => {
                if (filter.category !== activeFlterCategory) applyFilterCategory(filter.category);
              }}
            >
              {t(FeedPostCategoriesLabels[filter.category])}
            </Button>
          ))}
        </Box>
      )}
      {isWidthUpSm ? (
        isWithMoreButton && (
          <Box ref={moreButtonRef} mr={isWidthUpSm ? 1 : 0}>
            <ActionsMenu
              activatorIcon={
                isWidthUpSm ? (
                  <ExpandIcon height="24" width="24" />
                ) : (
                  <FilterIcon height="24" width="24" />
                )
              }
              gaLabel="filter by"
              id="filter-by"
              label={!isWidthUpSm && t('Filter by')}
              menuItems={menuItems}
              noIconsRecolor
              paperClassName="ayo-post-filter__menu"
              secondaryIcon={<CollapseIcon height="24" width="24" />}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            />
          </Box>
        )
      ) : (
        <Box display="flex">
          <ClickAwayListener
            onClickAway={() => {
              if (isPopperOpen) {
                setIsPopperOpen(false);
              }
            }}
          >
            <div>
              <Button
                ref={anchorRef}
                endIcon={<FilterIcon height="24" width="24" />}
                gaLabel="Filter by"
                onClick={handlePopperToggle}
              >
                {t('Filter by')}
              </Button>
              <Popper
                anchorEl={anchorRef.current}
                className="filters-popover"
                disablePortal
                modifiers={{
                  flip: {
                    enabled: false,
                  },
                  preventOverflow: {
                    enabled: true,
                    boundariesElement: document.querySelector('.ayo-page'),
                  },
                }}
                onClose={handlePopperToggle}
                open={isPopperOpen}
                placement="bottom-start"
                popperOptions={{ eventsEnabled: true }}
              >
                <Typography paragraph variant="subtitle2">
                  {t('Categories')}
                </Typography>
                <RadioGroup
                  ariaLabel={t('Filter by')}
                  onChange={handleRadioChange}
                  options={radioItems}
                  value={checkedCategory}
                />
                <Button
                  className="ayo-post-filter__popover-apply-button"
                  fullWidth
                  gaLabel="Apply filters"
                  onClick={() => applyFilterCategory(checkedCategory)}
                  variant="primary"
                >
                  {t('Apply filters')}
                </Button>
                <Button fullWidth gaLabel="Cancel" onClick={handlePopperCancel}>
                  {t('Cancel')}
                </Button>
              </Popper>
            </div>
          </ClickAwayListener>
        </Box>
      )}
    </Box>
  );
};

export default PostsFilter;
