import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Container, Grid, useMediaQuery, useTheme } from '@mui/material';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { Button, Typography } from '../../../../../atoms';
import { Chip } from '../../../../../moleculas';
import { UserContext } from '../../../../../../context';
import { useLandingPageConfig } from '../../../../../../utils';
import { RolesMap } from '../../../../../../constants/enums';
import { ReactComponent as AptitudesAptitudesExploreMoreDesktop } from '../../../../../../resources/images/aptitudes/landing-page/explore-more-illustration-desktop.svg';
import { ReactComponent as AptitudesAptitudesExploreMobile } from '../../../../../../resources/images/aptitudes/landing-page/explore-more-illustration-mobile.svg';

const AptitudesExploreMoreSectionConfig = [
  {
    buttonTitle: 'Go to My profile',
    chipLabel: 'For students',
    className: 'student',
    description: 'Check how well AYO knows you!',
    title: 'Curious to know more about your aptitudes?',
    userRole: RolesMap.STUDENT,
  },
  {
    buttonTitle: 'Go to (My) students',
    chipLabel: 'For educators',
    className: 'teacher',
    description: 'Check students’ profiles to know more!',
    title: 'Wondering about your students’ aptitudes?',
    userRole: RolesMap.TEACHER,
  },
  {
    buttonTitle: 'Go to Family feed',
    chipLabel: 'For parents',
    className: 'parent',
    description: 'Check your kid(s)’ profile to know more!',
    title: 'Interested in your kid(s)’ aptitudes?',
    userRole: RolesMap.GUARDIAN,
  },
];

const AptitudesExploreMoreSectionBlock = ({
  buttonTitle,
  chipLabel,
  className,
  description,
  handler,
  title,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Box
      className={classnames(
        'ayo-aptitudes-explore-more-section',
        `ayo-aptitudes-explore-more-section--${className}`,
      )}
      display="flex"
      flexDirection={isWidthUpSm ? 'row' : 'column'}
      justifyContent="space-between"
    >
      <Box
        className="ayo-aptitudes-explore-more-section__content"
        mb={isWidthUpSm ? 8 : 14}
        ml={isWidthUpSm ? 5 : 2}
        mr={isWidthUpSm ? 0 : 2}
        mt={isWidthUpSm ? 8 : 2}
      >
        <Chip label={t(chipLabel)} />
        <Box my={1}>
          <Typography component="h2" isLightText variant="h2">
            {t(title)}
          </Typography>
        </Box>
        <Typography isLightText variant="body2">
          {t(description)}
        </Typography>
        <Box mt={5}>
          <Button
            className="ayo-aptitudes-explore-more-section__content__button"
            gaLabel={buttonTitle}
            onClick={handler}
            variant="text"
          >
            {t(buttonTitle)}
          </Button>
        </Box>
      </Box>
      <Box alignItems={isWidthUpSm ? 'flex-end' : 'center'} display="flex" justifyContent="center">
        {isWidthUpSm ? (
          <AptitudesAptitudesExploreMoreDesktop
            aria-label="A box with objects representing various aptitudes"
            className="ayo-svg-illustration"
          />
        ) : (
          <AptitudesAptitudesExploreMobile
            aria-label="A box with objects representing various aptitudes"
            className="ayo-svg-illustration"
          />
        )}
      </Box>
    </Box>
  );
};

AptitudesExploreMoreSectionBlock.propTypes = {
  buttonTitle: PropTypes.string.isRequired,
  chipLabel: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  handler: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

const AptitudesExploreMoreSection = () => {
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  const { state: userState } = useContext(UserContext);
  const userRole = userState?.profile?.role;

  const { landingPageLoginSectionConfig, landingPageRedirectHandler } = useLandingPageConfig(
    AptitudesExploreMoreSectionConfig,
  );

  if (!landingPageLoginSectionConfig?.length) {
    return null;
  }

  return (
    <Container>
      <Box py={isWidthUpSm ? 15 : 5}>
        <Grid container spacing={3}>
          {landingPageLoginSectionConfig.map((el) => (
            <Grid key={el.className} item xs={12}>
              <AptitudesExploreMoreSectionBlock
                buttonTitle={userRole ? el.buttonTitle : el.label}
                chipLabel={el.chipLabel}
                className={el.className}
                description={el.description}
                handler={() =>
                  landingPageRedirectHandler(
                    el.loginProvider,
                    el.loginRedirectKey,
                    el.aptitudesLoginRedirectUrl,
                  )
                }
                title={el.title}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default AptitudesExploreMoreSection;
