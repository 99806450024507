import React, { useState } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Box, DialogActions, DialogContent } from '@mui/material';
import PropTypes from 'prop-types';
import YouTube from 'react-youtube';

import { Button, Dialog, DialogTitle, Typography } from '../../atoms';
import { Chip, EditingButton } from '../../moleculas';
import { MoodNames } from '../../../constants/moods';
import { ReactComponent as ExpandIcon } from '../../../resources/icons/chevron_down.svg';
import { ReactComponent as CollapseIcon } from '../../../resources/icons/chevron_up.svg';
import MoodActivityIllustration from '../mood-activity-illustration/MoodActivityIllustration';

const DescriptionItemBlock = ({ descriptionItem, isDescriptionCollapsed }) => (
  <Box mt={2}>
    <Typography
      className="ayo-activity-description-dialog__description-title"
      component="h3"
      variant="caption"
    >
      {descriptionItem.title}
    </Typography>
    <Typography
      className={classnames('ayo-activity-description-dialog__details-box__description', {
        'ayo-activity-description-dialog__details-box__description--collapsed':
          !isDescriptionCollapsed,
      })}
      dangerouslySetInnerHTML={{ __html: descriptionItem.description }}
      variant="caption"
    />
  </Box>
);

DescriptionItemBlock.propTypes = {
  descriptionItem: PropTypes.instanceOf(Object).isRequired,
  isDescriptionCollapsed: PropTypes.bool.isRequired,
};

const ActivityDescriptionDialog = ({ activity, isOpen, onClose }) => {
  const { t } = useTranslation();
  const [isDescriptionCollapsed, setIsDescriptionCollapsed] = useState(true);
  if (!activity) {
    return null;
  }
  const { title, name, nameTranslationKey, moods, descriptions, description, videoId } = activity;
  const details = descriptions || description;

  return (
    <Dialog
      className="ayo-activity-description-dialog"
      gaLabel="SEL activity dialog"
      onClose={onClose}
      open={isOpen}
    >
      <DialogTitle disableTypography>
        <Box
          alignItems="center"
          className="ayo-activity-description-dialog__title-block"
          display="flex"
          justifyContent="space-between"
        >
          <Typography component="h2" variant="subtitle1">
            {title || name}
          </Typography>
          <MoodActivityIllustration
            activity={activity}
            activityKey={nameTranslationKey}
            isImageType={!nameTranslationKey}
          />
        </Box>
        {moods.map((moodTag) => (
          <Chip
            key={moodTag}
            className="ayo-activity-description-dialog__chip"
            isOutlined
            label={t(MoodNames[moodTag])}
          />
        ))}
      </DialogTitle>
      <DialogContent className="ayo-activity-description-dialog__scrollable-container" tabIndex="0">
        {videoId && (
          <Box>
            <Typography component="h3" variant="subtitle2">
              {t('Watch an educational video')}
            </Typography>
            <Box mb={3} mt={1}>
              <YouTube
                opts={{
                  width: '100%',
                  minHeight: '353px',
                }}
                videoId={videoId}
              />
            </Box>
          </Box>
        )}
        <Typography component="h3" variant="subtitle2">
          {t('Read some tips')}
        </Typography>
        <Box>
          {details.slice(0, isDescriptionCollapsed ? 1 : details.length).map((descriptionItem) => (
            <DescriptionItemBlock
              key={`${descriptionItem.description}`}
              descriptionItem={descriptionItem}
              isDescriptionCollapsed={isDescriptionCollapsed}
            />
          ))}
          <Box my={1}>
            {isDescriptionCollapsed ? (
              <EditingButton
                gaLabel="Show more"
                icon={<ExpandIcon />}
                iconPosition="end"
                onClick={() => {
                  setIsDescriptionCollapsed(false);
                }}
                text={t('Show more')}
              />
            ) : (
              <EditingButton
                gaLabel="Show less"
                icon={<CollapseIcon />}
                iconPosition="end"
                onClick={() => {
                  setIsDescriptionCollapsed(true);
                }}
                text={t('Show less')}
              />
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          className="ayo-activity-description-dialog__close-button"
          gaLabel="SEL activity dialog - close"
          onClick={onClose}
        >
          {t('Close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ActivityDescriptionDialog.propTypes = {
  activity: PropTypes.instanceOf(Object).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ActivityDescriptionDialog;
