import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import { useClubHub } from '../../../../../../../../hooks';
import { ClubsStatuses } from '../../../../../../../../constants/club-hub';
import { ReactComponent as ActiveIcon } from '../../../../../../../../resources/icons/check_circle_green.svg';
import { ReactComponent as InActiveIcon } from '../../../../../../../../resources/icons/inactive.svg';
import ClubsDeactivationDialog from '../../../../../club-hub-components/clubs-deactivation-dialog/ClubsDeactivationDialog';
import ClubDataGridActions from '../../../../../club-hub-components/club-data-grid-actions/ClubDataGridActions';

const ClubsDataGridActions = ({ clubName, id, onStatusChange, status }) => {
  const [isDeactivationDialogOpen, setIsDeactivationDialogOpen] = useState(false);
  const [deactivationDialogStatus, setDeactivationDialogStatus] = useState(null);

  const { clubStatusChangeHandler } = useClubHub();

  const deactivationDialogOpen = useCallback((dialogStatus) => {
    setDeactivationDialogStatus(dialogStatus);
    setIsDeactivationDialogOpen(true);
  }, []);

  const ClubActionsConfig = useMemo(
    () => ({
      [ClubsStatuses.ACTIVE]: {
        id: uuidv4(),
        key: ClubsStatuses.ACTIVE,
        label: 'Make inactive',
        newStatus: ClubsStatuses.INACTIVE,
        icon: InActiveIcon,
        handler: () => deactivationDialogOpen(ClubsStatuses.INACTIVE),
      },
      [ClubsStatuses.INACTIVE]: {
        id: uuidv4(),
        key: ClubsStatuses.INACTIVE,
        label: 'Make active',
        newStatus: ClubsStatuses.ACTIVE,
        icon: ActiveIcon,
        handler: () =>
          clubStatusChangeHandler(id, clubName, null, ClubsStatuses.ACTIVE, onStatusChange),
      },
      [ClubsStatuses.DECLINED]: {
        id: uuidv4(),
        key: ClubsStatuses.DECLINED,
        label: 'Make active',
        newStatus: ClubsStatuses.ACTIVE,
        icon: ActiveIcon,
        handler: () =>
          clubStatusChangeHandler(id, clubName, null, ClubsStatuses.ACTIVE, onStatusChange),
      },
      [ClubsStatuses.PENDING]: {
        isPending: true,
        activeButton: {
          label: 'Make active',
          newStatus: ClubsStatuses.ACTIVE,
          handler: () =>
            clubStatusChangeHandler(id, clubName, null, ClubsStatuses.ACTIVE, onStatusChange),
        },
        declinedButton: {
          label: 'Decline',
          newStatus: ClubsStatuses.DECLINED,
          handler: () => deactivationDialogOpen(ClubsStatuses.DECLINED),
        },
      },
    }),
    [clubName, clubStatusChangeHandler, deactivationDialogOpen, id, onStatusChange],
  );
  return (
    <>
      <ClubDataGridActions config={ClubActionsConfig[status]} />
      {deactivationDialogStatus && (
        <ClubsDeactivationDialog
          clubName={clubName}
          isOpen={isDeactivationDialogOpen}
          onClose={() => {
            setIsDeactivationDialogOpen(false);
            setDeactivationDialogStatus(null);
          }}
          onSubmit={(reason, newStatus) =>
            clubStatusChangeHandler(id, clubName, reason, newStatus, onStatusChange)
          }
          status={deactivationDialogStatus}
        />
      )}
    </>
  );
};

ClubsDataGridActions.propTypes = {
  clubName: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  onStatusChange: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
};
export default ClubsDataGridActions;
