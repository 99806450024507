export const PeriodsColors = [
  '#BEE9F1',
  '#BCE4F9',
  '#BBDEDB',
  '#CEE4CB',
  '#DFEBCB',
  '#F1F3C8',
  '#FCECB9',
  '#F7CEBF',
  '#EFBDCF',
  '#DBC0E4',
  '#C6CBE6',
  '#D5CCC9',
  '#CFD8DC',
  '#DFCCCC',
  '#FFFFFF',
  '#DAEFF3',
  '#DAF3DA',
  '#DAE3F3',
  '#E2DAF3',
  '#F3EBDA',
];

export const LessonPageBlocksConfig = {
  richTextEditor: {
    apiKey: 'reachTextEditorData',
    key: {
      id: 'lesson-block-text',
      text: 'Text',
    },
    defaultData: {
      isVisible: true,
      textJson: null,
      attachments: [],
      links: [],
    },
    confirmDialogData: {
      confirmButtonTitle: 'Delete Text',
      text: 'You have already added some content here',
      title: 'Are you sure you want to delete the Text block?',
    },
    removeSnackbarConfig: {
      successText: 'The Text block has been deleted.',
      failureText: 'AYO couldn’t delete the Text block Please try once more',
    },
  },
  materials: {
    apiKey: 'materialsData',
    key: {
      id: 'lesson-block-materials',
      text: 'Materials',
    },
    defaultData: {
      isVisible: true,
      attachments: [],
      links: [],
    },
    confirmDialogData: {
      confirmButtonTitle: 'Delete Materials',
      text: 'You have already added some content here',
      title: 'Are you sure you want to delete the Materials block?',
    },
    removeSnackbarConfig: {
      successText: 'The Materials block has been deleted',
      failureText: 'AYO couldn’t delete the Materials block Please try once more',
    },
  },
  agendaBoard: {
    apiKey: 'agendaBoardData',
    key: {
      id: 'lesson-block-agenda',
      text: 'Agenda Board',
    },
    defaultData: {
      isVisible: true,
      agenda: { textJson: null, attachments: [] },
      teks: { textJson: null, attachments: [] },
      reminders: { textJson: null, attachments: [] },
    },
    confirmDialogData: {
      confirmButtonTitle: 'Delete Agenda board',
      text: 'You have already added some content here',
      title: 'Are you sure you want to delete the Agenda board block?',
    },
    removeSnackbarConfig: {
      successText: 'The Agenda board block has been deleted',
      failureText: 'AYO couldn’t delete the Agenda board block Please try once more',
    },
  },
  assignments: {
    key: {
      id: 'lesson-block-assignments',
      text: 'Assignments',
    },
  },
};

export const lessonTitlePlaceholder = 'New lesson';

export const ContentSectionTabs = {
  PLANNING: 'Planning & preparation',
  SHARED_WITH_STUDENTS: 'Shared with students',
};

export const LessonSections = {
  AGENDA_BOARD: 'Agenda Board',
  ASSIGNMENTS: 'Assignments for students',
  PLANNING_AND_PREPARATION: 'Planning and preparation',
  PRIMARY_DETAILS: 'Primary details',
};
