import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Box, DialogContent, DialogActions, useTheme, useMediaQuery } from '@mui/material';

import { Dialog, Typography, DialogTitle, Switch } from '../../../../../atoms';
import { PopupActionsButtons } from '../../../../../moleculas';
import { SwitchLabels } from '../../../../../../constants/enums';

const ClubSettingsDialog = ({ isOpen, onClose, onSubmit, isRepliesAllowed, isPostingAllowed }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const [isPostsWithReplies, setIsPostsWithReplies] = useState(isRepliesAllowed);
  const [isPostingGranded, setIsPostingGranded] = useState(isPostingAllowed);

  const onSubmitHandler = useCallback(() => {
    const body = {
      MEMBERS_PARENTS_POSTING_GRANTED: isPostingGranded,
      COMMENTS_GRANTED: isPostsWithReplies,
    };
    onSubmit(body);
    onClose();
  }, [onSubmit, onClose, isPostsWithReplies, isPostingGranded]);

  const onCancelHandler = useCallback(() => {
    setIsPostsWithReplies(isRepliesAllowed);
    setIsPostingGranded(isPostingAllowed);
    onClose();
  }, [onClose, isRepliesAllowed, isPostingAllowed]);

  return (
    <Dialog
      className="ayo-club-hub-details__settings-dialog"
      gaLabel="Club dialog"
      onClose={onClose}
      open={isOpen}
    >
      <DialogTitle disableTypography>
        <Typography component="h2" mb={isWidthUpSm ? 5 : 10} variant="subtitle1">
          {t('Settings')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography component="h3" mb={2} variant="subtitle2">
          {t('Posts')}
        </Typography>
        <Box display="flex" flexDirection="row" justifyContent="space-between" mb={4}>
          <Typography mr={3} variant="body2">
            {t('Members and their parents can post')}
          </Typography>
          <Switch
            ariaLabel={t('Posts')}
            checkedLabel={SwitchLabels.on}
            gaLabel="Posts creation"
            onChange={(value) => {
              setIsPostingGranded(value);
            }}
            uncheckedLabel={SwitchLabels.off}
            value={isPostingGranded}
          />
        </Box>
        <Typography component="h3" mb={2} variant="subtitle2">
          {t('Posts replies')}
        </Typography>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Typography mr={3} variant="body2">
            {t('Members and their parents can reply to posts')}
          </Typography>
          <Switch
            ariaLabel={t('Posts replies')}
            checkedLabel={SwitchLabels.on}
            gaLabel="Posts replies"
            onChange={(value) => {
              setIsPostsWithReplies(value);
            }}
            uncheckedLabel={SwitchLabels.off}
            value={isPostsWithReplies}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <PopupActionsButtons
          primaryButtonGaLabel="Save"
          primaryButtonHandler={onSubmitHandler}
          primaryButtonText={t('Save')}
          secondaryButtonGaLabel="Cancel"
          secondaryButtonHandler={onCancelHandler}
          secondaryButtonText={t('Cancel')}
        />
      </DialogActions>
    </Dialog>
  );
};

ClubSettingsDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isPostingAllowed: PropTypes.bool.isRequired,
  isRepliesAllowed: PropTypes.bool.isRequired,
};

export default ClubSettingsDialog;
