import React, { useCallback, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { ActionsMenu, Button } from '../../../../atoms';
import { ConfirmDialog, PortfolioUnpublishDialog } from '../../../../organisms';
import { UserActions, UserContext } from '../../../../../context';
import { useStudentsService } from '../../../../../services';
import { useSnackbar } from '../../../../../hooks';
import { portfoliosRoute } from '../../../../../constants/routes';
import { PublicationStatuses } from '../../../../../constants/enums';
import { ReactComponent as LinkIcon } from '../../../../../resources/icons/link_dark.svg';
import { ReactComponent as ChevronRightIcon } from '../../../../../resources/icons/chevron_right.svg';
import { ReactComponent as ShareIcon } from '../../../../../resources/icons/share.svg';
import { ReactComponent as DeleteIcon } from '../../../../../resources/icons/delete_primary.svg';
import { ReactComponent as DownloadIcon } from '../../../../../resources/icons/download_black.svg';
import PortfolioGetLinkDialog from '../../../portfolio-main-page/components/portfolio-get-link-dialog/PortfolioGetLinkDialog';

const StudentPortfolioButtonsBlock = ({
  deletePortfolioHandler,
  getPublishedPortfolioActions,
  headerActions,
  isEditMode,
  portfolioData,
  portfolioId,
  portfolioTitle,
  previewModeHandler,
  printHandler,
  publishModeView,
  status,
  studentId,
  unpublishPortfolioHandler,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isConfirmEmptyDialogOpen, setConfirmEmptyDialogOpen] = useState(false);
  const [isUnpublishDialogOpen, setUnpublishDialogOpen] = useState(false);
  const [isGetLinkDialogOpen, setIsGetLinkDialogOpen] = useState(false);

  const { dispatch: dispatchUserState } = useContext(UserContext);
  const { patchStudentPortfolio } = useStudentsService();

  const isPublished = useMemo(() => status === PublicationStatuses.PUBLISHED, [status]);
  const isDraft = useMemo(() => status === PublicationStatuses.DRAFT, [status]);

  const { setSnackBarStatus } = useSnackbar();

  const onPortfolioDeleteHandler = useCallback(() => {
    deletePortfolioHandler(portfolioId, status);
    setIsDeleteDialogOpen(false);
  }, [portfolioId, status, deletePortfolioHandler]);

  const savePortfolioHandler = useCallback(() => {
    setSnackBarStatus({
      text: t(
        isPublished
          ? 'Your changes have been successfully saved.'
          : 'Your draft has been successfully saved.',
      ),
      type: 'success',
    });
    history.push(portfoliosRoute);
  }, [setSnackBarStatus, t, isPublished, history]);

  const updatePublishState = useCallback(() => {
    const requestBody = {
      status: PublicationStatuses.PUBLISHED,
    };
    patchStudentPortfolio(studentId, portfolioId, requestBody, true)
      .then(() => {
        setSnackBarStatus({
          text: t('You have published your portfolio successfully.'),
          type: 'success',
        });
        history.push(portfoliosRoute);
      })
      .catch(() => {
        setSnackBarStatus({
          text: `${t('Something went wrong')}. ${t('Please try once more')}`,
          type: 'error',
          action: (
            <Button autoFocus gaLabel="Retry" onClick={updatePublishState}>
              {t('Retry')}
            </Button>
          ),
        });
      });
  }, [patchStudentPortfolio, studentId, portfolioId, setSnackBarStatus, t, history]);

  const onPublishHandler = useCallback(() => {
    const portfolioWithoutPersonalInfo = (({ PORTFOLIO__PERSONAL_INFO, ...newObject }) =>
      newObject)(portfolioData);
    const isWithEmptySections = Object.values(portfolioWithoutPersonalInfo)
      .filter((section) => section.isVisible)
      .filter((section) => !section.sectionData.entities.length)
      .some((sectionWithEntities) => !sectionWithEntities.sectionData.reflection);

    if (isWithEmptySections) {
      setConfirmEmptyDialogOpen(true);
    } else {
      updatePublishState();
    }
  }, [portfolioData, updatePublishState]);

  const onEditPublishedHandler = useCallback(() => {
    previewModeHandler();
    dispatchUserState({
      type: UserActions.SET_USER_PORTFOLIO_EDIT_MODE,
      data: true,
    });
  }, [previewModeHandler, dispatchUserState]);

  return (
    <Box display="flex" justifyContent="space-between" width="100%">
      {isDraft ? (
        <Box display="flex">
          <Box mr={3}>
            <Button
              className="ayo-portfolio-details-page__buttons-block"
              gaLabel={publishModeView ? 'Close' : 'Save as draft'}
              onClick={publishModeView ? previewModeHandler : savePortfolioHandler}
            >
              {t(publishModeView ? 'Close' : 'Save as draft')}
            </Button>
          </Box>
          <Button
            className="ayo-portfolio-details-page__buttons-block"
            gaLabel={publishModeView ? 'Publish' : 'Preview & Publish'}
            onClick={publishModeView ? onPublishHandler : previewModeHandler}
            variant={publishModeView ? 'primary' : 'text'}
          >
            {t(publishModeView ? 'Publish' : 'Preview & Publish')}
          </Button>
        </Box>
      ) : (
        <Box display="flex">
          {!isEditMode && (
            <>
              <ActionsMenu
                id="published-portfolio-actions"
                label={t('Actions')}
                menuItems={getPublishedPortfolioActions(
                  onEditPublishedHandler,
                  () => setUnpublishDialogOpen(true),
                  () => setIsDeleteDialogOpen(true),
                )}
                transformOrigin={
                  headerActions
                    ? {
                        vertical: 'top',
                        horizontal: 'right',
                      }
                    : {
                        vertical: 'top',
                        horizontal: 'left',
                      }
                }
              />
              {!headerActions && (
                <Box ml={2}>
                  <ActionsMenu
                    activatorIcon={<ChevronRightIcon />}
                    activatorProps={{ ariaLabel: t('Share') }}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    gaLabel="Share portfolio"
                    id="share-portfolio"
                    label={
                      <Box display="flex" pt={0.5}>
                        <Box mr={1}>
                          <ShareIcon />
                        </Box>
                        {t('Share')}
                      </Box>
                    }
                    menuItems={[
                      {
                        text: t('Save as PDF'),
                        icon: DownloadIcon,
                        handler: printHandler,
                        id: 'Save-as-PDF',
                        gaLabel: 'Save as PDF',
                      },
                      {
                        text: t('Get link'),
                        icon: LinkIcon,
                        handler: () => {
                          setIsGetLinkDialogOpen(true);
                        },
                        id: 'get-link',
                        gaLabel: 'Get link',
                      },
                    ]}
                    noIconsRecolor
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                  />
                </Box>
              )}
              <PortfolioGetLinkDialog
                isDialogOpen={isGetLinkDialogOpen}
                onClose={() => setIsGetLinkDialogOpen(false)}
                portfolioId={portfolioId}
                studentId={studentId}
              />
            </>
          )}
          {isEditMode && (
            <>
              <Box mr={3}>
                <Button
                  className="ayo-portfolio-details-page__buttons-block"
                  gaLabel="Cancel"
                  onClick={previewModeHandler}
                >
                  {t('Cancel')}
                </Button>
              </Box>
              <Button
                className="ayo-portfolio-details-page__buttons-block"
                gaLabel="Save changes"
                onClick={savePortfolioHandler}
                variant="primary"
              >
                {t('Save changes')}
              </Button>
            </>
          )}
        </Box>
      )}
      {(isEditMode || (isDraft && !publishModeView)) && (
        <Button
          className="ayo-lesson-page__footer__delete-button"
          endIcon={<DeleteIcon />}
          gaLabel={isPublished ? 'Delete portfolio' : 'Delete draft'}
          onClick={setIsDeleteDialogOpen}
        >
          {t(isPublished ? 'Delete portfolio' : 'Delete draft')}
        </Button>
      )}
      {isPublished && !isEditMode && (
        <PortfolioUnpublishDialog
          isOpen={isUnpublishDialogOpen}
          onClose={() => setUnpublishDialogOpen(false)}
          onUnpublish={unpublishPortfolioHandler}
          portfolioId={portfolioId}
          portfolioTitle={portfolioTitle}
          redirectUrl={portfoliosRoute}
        />
      )}
      <ConfirmDialog
        cancelButtonTitle="Cancel"
        confirmButtonTitle={isPublished ? 'Delete portfolio' : 'Delete draft'}
        isOpen={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        onConfirm={onPortfolioDeleteHandler}
        text="AYO won’t be able to restore it"
        title={
          isPublished
            ? 'Are you sure you want to delete your portfolio?'
            : 'Are you sure you want to delete your draft?'
        }
      />
      <ConfirmDialog
        cancelButtonTitle="Publish anyway"
        confirmButtonTitle="Go back to draft"
        isOpen={isConfirmEmptyDialogOpen}
        onCancel={updatePublishState}
        onClose={() => setConfirmEmptyDialogOpen(false)}
        onConfirm={previewModeHandler}
        text="If you publish it now, these will be visible to your educators and parents in AYO. Let’s add information there?"
        title="You have empty sections in your portfolio"
        variant="primary"
      />
    </Box>
  );
};

StudentPortfolioButtonsBlock.propTypes = {
  deletePortfolioHandler: PropTypes.func.isRequired,
  getPublishedPortfolioActions: PropTypes.func.isRequired,
  headerActions: PropTypes.bool,
  isEditMode: PropTypes.bool,
  portfolioData: PropTypes.instanceOf(Object).isRequired,
  portfolioId: PropTypes.number.isRequired,
  portfolioTitle: PropTypes.string.isRequired,
  previewModeHandler: PropTypes.bool.isRequired,
  printHandler: PropTypes.func.isRequired,
  publishModeView: PropTypes.bool.isRequired,
  status: PropTypes.string,
  studentId: PropTypes.string.isRequired,
  unpublishPortfolioHandler: PropTypes.func.isRequired,
};

StudentPortfolioButtonsBlock.defaultProps = {
  headerActions: false,
  isEditMode: false,
  status: PublicationStatuses.DRAFT,
};

export default StudentPortfolioButtonsBlock;
