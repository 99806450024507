import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Box, DialogContent } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Dialog, DialogTitle, Typography } from '../../../../../../../../../atoms';
import ClubPostForm from '../club-post-form/ClubPostForm';
import { InformationalCaption } from '../../../../../../../../../moleculas';

const EditClubPostDialog = ({ clubId, postDataToEdit, onClose, updatePost, isClubOwner }) => {
  const { t } = useTranslation();

  const dialogCloseHandler = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Dialog
      className="ayo-edit-club-post-dialog"
      gaLabel="Edit club post"
      onClose={dialogCloseHandler}
      open={!!postDataToEdit}
      transitionDuration={{ exit: 0 }}
    >
      <>
        <DialogTitle disableTypography>
          <Typography component="h2" variant="subtitle1">
            {t('Edit post')}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box>
            <ClubPostForm
              additionalInfo={
                isClubOwner && (
                  <Box mt={2}>
                    <InformationalCaption title={t('Edit club hub post visibility info')} />
                  </Box>
                )
              }
              clubId={clubId}
              isClubOwner={isClubOwner}
              onClose={dialogCloseHandler}
              postDataToEdit={postDataToEdit}
              updatePost={updatePost}
            />
          </Box>
        </DialogContent>
      </>
    </Dialog>
  );
};

EditClubPostDialog.propTypes = {
  postDataToEdit: PropTypes.shape({
    attachments: PropTypes.arrayOf(
      PropTypes.shape({
        fileName: PropTypes.string,
        id: PropTypes.number,
        ownerId: PropTypes.number,
        updatedDate: PropTypes.string,
      }),
    ),
    createdDate: PropTypes.string,
    description: PropTypes.string,
    id: PropTypes.number,
    selectedEntities: PropTypes.arrayOf(PropTypes.string),
    sharingLevel: PropTypes.string,
    title: PropTypes.string,
  }),
  clubId: PropTypes.number.isRequired,
  isClubOwner: PropTypes.bool.isRequired,
  updatePost: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

EditClubPostDialog.defaultProps = {
  postDataToEdit: null,
};

export default EditClubPostDialog;
