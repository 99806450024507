/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { EditingButton } from '../../../../../../../moleculas';
import { ReactComponent as ExpandIcon } from '../../../../../../../../resources/icons/chevron_down.svg';
import { ReactComponent as CollapseIcon } from '../../../../../../../../resources/icons/chevron_up.svg';
import { getGroupedArrayByKey } from '../../../../../../../../utils';
import AnswerItem from '../answer-item/AnswerItem';

const QUESTINNAIRE_ID = 'questionnaireId';

const AnswersBlock = ({ answers }) => {
  const { t } = useTranslation();
  const years = Object.keys(answers).sort().reverse();
  const [isAnswersCollapsed, setIsAnswersCollapsed] = useState(true);
  const [currentYear, ...previousYears] = years;
  const getAnswersMapItems = (guardiansAnswers) => {
    const grouppedByQuestionnaire = getGroupedArrayByKey(guardiansAnswers, QUESTINNAIRE_ID);
    const sortedById = Object.keys(grouppedByQuestionnaire)
      .sort()
      .reverse()
      .map((key) => grouppedByQuestionnaire[key]);
    return sortedById.map((questionnaire, index) => (
      <AnswerItem key={`${questionnaire.questionnaireId}-${index}`} questionnaire={questionnaire} />
    ));
  };

  return (
    <Box>
      <Box pt={1}>{getAnswersMapItems(answers[currentYear])}</Box>
      <Box
        className={classNames('ayo-student-details-page__answer__previous-answer', {
          'ayo-student-details-page__answer__previous-answer--collapsed': isAnswersCollapsed,
        })}
      >
        {previousYears.length
          ? previousYears.map((year) => (
              <Box key={year} pt={1}>
                {getAnswersMapItems(answers[year])}
              </Box>
            ))
          : null}
      </Box>
      {years.length > 1 && (
        <Box mt={2}>
          {isAnswersCollapsed ? (
            <EditingButton
              gaLabel="Show history of all answers"
              icon={<ExpandIcon />}
              iconPosition="end"
              onClick={() => {
                setIsAnswersCollapsed(false);
              }}
              text={t('Show history of all answers')}
            />
          ) : (
            <EditingButton
              gaLabel="Hide history of all answers"
              icon={<CollapseIcon />}
              iconPosition="end"
              onClick={() => {
                setIsAnswersCollapsed(true);
              }}
              text={t('Hide history of all answers')}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

AnswersBlock.propTypes = {
  answers: PropTypes.instanceOf(Object),
};

AnswersBlock.defaultProps = {
  answers: {},
};

export default AnswersBlock;
