import { getUniqueObjectsByKey } from '../array-helpers/arrayHelpers';

export const isPeriodDisplayed = (period) => {
  if (typeof period === 'string') {
    return period.trim() && period !== '0';
  }
  if (typeof period === 'number') {
    return !!period;
  }
  return false;
};

export const getCourseAggregationKey = (course) =>
  isPeriodDisplayed(course.period) ? `${course.className} - ${course.period}` : course.className;

export const getKeyValueObjectFromArray = (configArray) =>
  configArray.reduce((acc, item) => ({ ...acc, [item.key]: item.value }), {});

export const getFullName = (person) =>
  `${person?.firstName ?? ''} ${person?.lastName ?? ''}`.trim();

export const getUniqueStudents = (students) => getUniqueObjectsByKey(students, 'id');
