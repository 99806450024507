import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import classNames from 'classnames';

import { Card, Typography } from '../../../../atoms';
import { DeleteItemWrapper, InitiativeEmptyStateBlock } from '../../../../moleculas';
import { PortfolioEntitiesData, PortfolioItemsToAddData } from '../../../../../utils';
import { portfolioSectionConfig } from '../../../../../constants/propTypes';
import { PortfolioSectionTypes } from '../../../../../constants/enums';
import { ReactComponent as EndorsementIllustration } from '../../../../../resources/images/portfolio/endorsement-image.svg';
import { ReactComponent as EndorsementsEmptyStateIllustration } from '../../../../../resources/images/endorsements_empty_state.svg';
import PortfolioSection from '../../components/portfolio-section/PortfolioSection';
import TryMobileInformationalMessage from '../../../try-mobile-informational-message/TryMobileInformationalMessage';

const PortfolioEndorsementsSection = ({ config, sectionIndex, isLastSection }) => {
  const { t } = useTranslation();
  const [portfolioEndorsements, setPortfolioEndorsements] = useState([]);
  const {
    addPortfolioItemHandler,
    deletePortfolioItemHandler,
    hasData,
    isEditMode,
    isVisible,
    metaData,
    reflectionHandler,
    sectionData,
    onUpdatePositionHandler,
  } = config;
  const { entities, reflection } = sectionData;

  useEffect(() => {
    setPortfolioEndorsements(
      PortfolioEntitiesData[PortfolioSectionTypes.PORTFOLIO__ENDORSEMENTS](
        entities,
        metaData.endorsements,
        'nameTranslationKey',
      ),
    );
  }, [entities, metaData]);

  const endorsementsToAdd = useMemo(
    () =>
      PortfolioItemsToAddData[PortfolioSectionTypes.PORTFOLIO__ENDORSEMENTS](
        entities,
        metaData.endorsements,
        'nameTranslationKey',
      ).map(({ id, endorsementName }) => ({
        id: id.toString(),
        name: endorsementName,
      })),
    [entities, metaData],
  );

  if (!isVisible) {
    return null;
  }
  return (
    <PortfolioSection
      body={
        <Box>
          {portfolioEndorsements.map(({ endorsementName, id }) => (
            <DeleteItemWrapper
              key={id}
              disabled={!isEditMode}
              gaLabel="Remove endorsement"
              mainContent={
                <Card
                  className="ayo-portfolio__endorsements-section__card"
                  mainContent={
                    <div className="ayo-portfolio__endorsements-section__card__content">
                      <EndorsementIllustration />
                      <Typography
                        className="ayo-portfolio__endorsements-section__card__content__title"
                        component="h3"
                        variant="subtitle2"
                      >
                        {endorsementName}
                      </Typography>
                    </div>
                  }
                />
              }
              onDelete={() => {
                deletePortfolioItemHandler(portfolioEndorsements, 'id', id, sectionData);
              }}
            />
          ))}
        </Box>
      }
      className={`${classNames({
        'ayo-portfolio__endorsements-section': hasData,
      })}`}
      emptyState={
        !hasData ? (
          <InitiativeEmptyStateBlock
            body={isEditMode ? t('But you can learn more about them in AYO App') : ''}
            hint={isEditMode ? <TryMobileInformationalMessage /> : null}
            illustration={<EndorsementsEmptyStateIllustration />}
            title={t('No information about endorsements so far')}
          />
        ) : null
      }
      isEditMode={isEditMode}
      isLastSection={isLastSection}
      itemsToAdd={endorsementsToAdd}
      onAddItems={(itemsToAdd) =>
        addPortfolioItemHandler(metaData.endorsements, itemsToAdd, 'id', sectionData)
      }
      onChangeReflection={reflectionHandler}
      onUpdatePositionHandler={onUpdatePositionHandler}
      reflection={reflection}
      sectionIndex={sectionIndex}
      sectionKey={PortfolioSectionTypes.PORTFOLIO__ENDORSEMENTS}
      showActionsMenu={hasData && isEditMode}
      showAddButton={!!endorsementsToAdd?.length && !portfolioEndorsements.length && isEditMode}
      showAYOChip
      title={t('Endorsements')}
    />
  );
};

PortfolioEndorsementsSection.propTypes = {
  config: portfolioSectionConfig(PropTypes.instanceOf(Object)),
  sectionIndex: PropTypes.number.isRequired,
  isLastSection: PropTypes.number.isRequired,
};

PortfolioEndorsementsSection.defaultProps = {
  config: {},
};

export default PortfolioEndorsementsSection;
