import React from 'react';
import { useTranslation } from 'react-i18next';

import { HighlightsInitiatives } from '../../constants/enums';
import { ReactComponent as AlertIcon } from '../../resources/icons/alert.svg';
import { NegativeMoodTrendTooltip } from '../../components/moleculas';
import { MoodValues, MoodColors, MoodThresholds } from '../../constants/moods';
import { isNullOrUndefined } from '../lang/lang';

const MINIMUM_NEGATIVE_MOOD_TREND_DAYS_VISIBLE = 5;

const NegativeMoods = ['ANGRY', 'FRUSTRATED', 'SAD', 'WORRIED', 'TIRED'];

const useEmotionalLearning = (currentHighlightsInitiative, studentsMoodsTrends, filterParams) => {
  const { t } = useTranslation();
  const negativeMoodTrendIcon = () =>
    currentHighlightsInitiative === HighlightsInitiatives.MOODS
      ? (id) =>
          studentsMoodsTrends?.[id] >= MINIMUM_NEGATIVE_MOOD_TREND_DAYS_VISIBLE && <AlertIcon />
      : null;

  const negativeMoodTrendTooltip = () =>
    currentHighlightsInitiative === HighlightsInitiatives.MOODS
      ? (id) =>
          studentsMoodsTrends?.[id] >= MINIMUM_NEGATIVE_MOOD_TREND_DAYS_VISIBLE
            ? ({ children }) => (
                <NegativeMoodTrendTooltip negativeMoodDaysCount={studentsMoodsTrends[id]}>
                  {children}
                </NegativeMoodTrendTooltip>
              )
            : React.Fragment
      : null;

  const negativeMoodTrendAriaLabel = () =>
    currentHighlightsInitiative === HighlightsInitiatives.MOODS
      ? (id) =>
          studentsMoodsTrends?.[id] >= MINIMUM_NEGATIVE_MOOD_TREND_DAYS_VISIBLE
            ? t('Negative mood trend for COUNT days', { daysCount: studentsMoodsTrends?.[id] })
            : ''
      : null;

  const filterString = filterParams?.get('filterValue')?.split(':')?.pop();

  const getIsNegativeMoodSelected = (selectedMood) => NegativeMoods.includes(selectedMood);

  const showInterventionRequestSection =
    currentHighlightsInitiative === HighlightsInitiatives.MOODS &&
    (!filterString || getIsNegativeMoodSelected(filterString));

  const moodsSorter = (a, b, key) => {
    const moodValueA = key ? MoodValues[a[key]] : a;
    const moodValueB = key ? MoodValues[b[key]] : b;

    if (isNullOrUndefined(moodValueA)) return 1;
    if (isNullOrUndefined(moodValueB)) return -1;

    return moodValueA - moodValueB;
  };

  const getMoodColor = (mood) => {
    const moodValue = MoodValues[mood];

    switch (true) {
      case moodValue >= MoodThresholds.POSITIVE:
        return MoodColors.POSITIVE;
      case moodValue >= MoodThresholds.NEGATIVE && moodValue < MoodThresholds.POSITIVE:
        return MoodColors.NEUTRAL;
      case moodValue < MoodThresholds.NEGATIVE:
        return MoodColors.NEGATIVE;
      default:
        return MoodColors.PENDING;
    }
  };

  return {
    getIsNegativeMoodSelected,
    negativeMoodTrendIcon,
    negativeMoodTrendTooltip,
    showInterventionRequestSection,
    negativeMoodTrendAriaLabel,
    moodsSorter,
    getMoodColor,
  };
};

export default useEmotionalLearning;
