import React, { useCallback, useEffect, useState, useMemo, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import { VariableSizeList } from 'react-window';
import { Box, Grid } from '@mui/material';

import { Button, Typography } from '../../../../../atoms';
import { AccordionItem } from '../../../../../moleculas';
import { useContentModerationService } from '../../../../../../services';
import {
  ContentModerationTypesMap,
  OpportunitiesCategoriesMap,
  SchoolLevels,
} from '../../../../../../constants/enums';
import { useAccordionToggleState } from '../../../../../../hooks';
import { getGroupedArrayByKey } from '../../../../../../utils';
import { AppActions, AppContext } from '../../../../../../context';

import OpportunitiesModerationRow from './components/OpportunitiesModerationRow';

const DEFAULT_SIZE = 50;

const OpportunitiesModerationGroup = ({
  isExpanded,
  isShowHideGranted,
  isEditApproveGranted,
  opportunitiesGroup,
  opportunitiesGroupName,
  updateLocalState,
  submitVisibility,
  toggleExpandedState,
  approveTranslationHandler,
  updateTranslationHandler,
}) => {
  const { t } = useTranslation();
  const listRef = useRef();
  const sizeMap = useRef({});

  const setSize = useCallback((index, size) => {
    sizeMap.current = { ...sizeMap.current, [index]: size };
    listRef.current.resetAfterIndex(index);
  }, []);

  const getSize = (index) => sizeMap.current[index] || DEFAULT_SIZE;

  return (
    <Box mb={1}>
      <AccordionItem
        bodyEl={
          <div aria-rowcount={opportunitiesGroup.length} role="table">
            <Box className="ayo-content-moderation-opportunities__headers">
              <Grid container justifyContent="space-between" role="row">
                <Grid item xs={4}>
                  <Typography role="columnheader" variant="caption">
                    {t('Original (EN)')}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Box ml={-1}>
                    <Typography role="columnheader" variant="caption">
                      {t('Translation (ES)')}
                    </Typography>
                  </Box>
                </Grid>
                {isEditApproveGranted && (
                  <>
                    <Grid item xs={1}>
                      <Box pl={2}>
                        <Typography role="columnheader" variant="caption">
                          {t('Approve')}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={1}>
                      <Box pl={2}>
                        <Typography role="columnheader" variant="caption">
                          {t('Edit')}
                        </Typography>
                      </Box>
                    </Grid>
                  </>
                )}
                {isShowHideGranted && (
                  <Grid item xs={1}>
                    <Typography role="columnheader" variant="caption">
                      {t('Show/Hide')}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Box>
            <VariableSizeList
              ref={listRef}
              height={500}
              itemCount={opportunitiesGroup.length}
              itemData={opportunitiesGroup}
              itemSize={getSize}
            >
              {({ data, index, style }) => (
                <div style={{ ...style, width: '99%' }}>
                  <OpportunitiesModerationRow
                    key={data[index].entityKey}
                    approveTranslationHandler={approveTranslationHandler}
                    headingLevel={2}
                    index={index}
                    isEditApproveGranted={isEditApproveGranted}
                    isShowHideGranted={isShowHideGranted}
                    opportunity={data[index]}
                    setSize={setSize}
                    submitVisibility={submitVisibility}
                    updateLocalState={updateLocalState}
                    updateTranslationHandler={updateTranslationHandler}
                  />
                </div>
              )}
            </VariableSizeList>
          </div>
        }
        expanded={isExpanded}
        gaLabel="Content moderation opportunities accordion"
        headerEl={
          <Typography component="p" variant="subtitle1">
            {t(OpportunitiesCategoriesMap[opportunitiesGroupName])}
          </Typography>
        }
        onChange={toggleExpandedState}
      />
    </Box>
  );
};

OpportunitiesModerationGroup.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  isShowHideGranted: PropTypes.bool,
  isEditApproveGranted: PropTypes.bool,
  opportunitiesGroup: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  opportunitiesGroupName: PropTypes.string.isRequired,
  updateLocalState: PropTypes.func.isRequired,
  submitVisibility: PropTypes.func.isRequired,
  approveTranslationHandler: PropTypes.func.isRequired,
  toggleExpandedState: PropTypes.func.isRequired,
  updateTranslationHandler: PropTypes.func.isRequired,
};

OpportunitiesModerationGroup.defaultProps = {
  isShowHideGranted: null,
  isEditApproveGranted: null,
};

const OpportunitiesModeration = ({ schoolLevel, isShowHideGranted, isEditApproveGranted }) => {
  const { t } = useTranslation();
  const [opportunities, setOpportunities] = useState([]);
  const [openedInterestKey, toggleOpenedInterestKey] = useAccordionToggleState();
  const { dispatch: dispatchAppState } = useContext(AppContext);
  const { getContentModerationData, postContentVisibility } = useContentModerationService();

  useEffect(() => {
    getContentModerationData(ContentModerationTypesMap.OPPORTUNITIES, schoolLevel).then((data) => {
      setOpportunities(data);
    });
  }, [getContentModerationData, schoolLevel]);

  const updateLocalState = useCallback(
    (entity, newValue) => {
      const updatedOpportunities = opportunities?.filter(
        (opportunity) => opportunity.entityKey !== entity.entityKey,
      );
      setOpportunities([...updatedOpportunities, { ...entity, ...newValue }]);
    },
    [opportunities],
  );

  const submitVisibility = useCallback(
    (entity, newValue) => {
      updateLocalState(entity, { isVisible: newValue });
      const opportunityName = entity.keys.name.EN_US;
      postContentVisibility(
        [entity.entityKey],
        newValue,
        schoolLevel,
        entity.entityKey,
        entity.keys.name.translationKey,
      )
        .then(() => {
          dispatchAppState({
            type: AppActions.SET_SNACKBAR_STATUS,
            data: {
              text: (
                <Trans
                  components={{ b: <b /> }}
                  i18nKey="{{opportunityName}} is {{status}} for students in AYO"
                  values={{
                    opportunityName,
                    status: entity.isVisible ? t('hidden') : t('shown'),
                  }}
                />
              ),
              type: 'success',
              action: (
                <Button
                  autoFocus
                  onClick={() => {
                    submitVisibility(entity, !newValue);
                  }}
                >
                  {t('Undo')}
                </Button>
              ),
            },
          });
        })
        .catch(() => {
          updateLocalState(entity, { isVisible: !newValue });
          dispatchAppState({
            type: AppActions.SET_SNACKBAR_STATUS,
            data: {
              text: (
                <Trans
                  components={{ b: <b /> }}
                  i18nKey="Failed to {{status}} {{opportunityName}}, please try once more"
                  values={{
                    opportunityName,
                    status: entity.isVisible ? t('hide') : t('show'),
                  }}
                />
              ),
              type: 'error',
              action: (
                <Button autoFocus onClick={() => submitVisibility(entity, newValue)}>
                  {t('Retry')}
                </Button>
              ),
            },
          });
        });
    },
    [dispatchAppState, postContentVisibility, schoolLevel, t, updateLocalState],
  );

  const updateTranslationHandler = useCallback(
    (entity, newValue) => {
      updateLocalState(entity, newValue);
    },
    [updateLocalState],
  );

  const approveTranslationHandler = useCallback(
    (entity, newValue) => {
      updateLocalState(entity, { isApproved: newValue });
    },
    [updateLocalState],
  );

  const groupedOpportunities = useMemo(() => {
    opportunities.sort((a, b) => a.keys.name.EN_US.localeCompare(b.keys.name.EN_US));
    const groupedOpportunitiesData = getGroupedArrayByKey(opportunities, 'category');
    return Object.entries(groupedOpportunitiesData).sort(([a], [b]) => a.localeCompare(b));
  }, [opportunities]);

  return (
    <Box className="ayo-content-moderation-opportunities" mb={19}>
      {groupedOpportunities.map(([opportunitiesGroupName, opportunitiesGroup]) => (
        <OpportunitiesModerationGroup
          key={opportunitiesGroupName}
          approveTranslationHandler={approveTranslationHandler}
          isEditApproveGranted={isEditApproveGranted}
          isExpanded={openedInterestKey === opportunitiesGroupName}
          isShowHideGranted={isShowHideGranted}
          opportunitiesGroup={opportunitiesGroup}
          opportunitiesGroupName={opportunitiesGroupName}
          schoolLevel={schoolLevel}
          submitVisibility={submitVisibility}
          toggleExpandedState={() => toggleOpenedInterestKey(opportunitiesGroupName)}
          updateLocalState={updateLocalState}
          updateTranslationHandler={updateTranslationHandler}
        />
      ))}
    </Box>
  );
};

OpportunitiesModeration.propTypes = {
  schoolLevel: PropTypes.oneOf(Object.values(SchoolLevels)),
  isShowHideGranted: PropTypes.bool,
  isEditApproveGranted: PropTypes.bool,
};

OpportunitiesModeration.defaultProps = {
  schoolLevel: SchoolLevels.ELEMENTARY,
  isShowHideGranted: null,
  isEditApproveGranted: null,
};

export default OpportunitiesModeration;
