import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Box, Container, Tab, useMediaQuery, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';

import { ActionsMenu, Button, Snackbar, TabPanel, Tabs, Typography } from '../../../../atoms';
import {
  DisabledControlWithTooltip,
  InformationalCaption,
  InformationalMessage,
  NewFeatureHotspot,
  STTTextField,
} from '../../../../moleculas';
import { ConfirmDialog, LeaveDialog, PageWrapper, RichTextEditor } from '../../../../organisms';
import {
  deepEqual,
  getFormattedDate,
  getFocusableElement,
  getNormalizedLanguageCode,
  isCurrentYear,
  reorder,
  scrollAndFocusEl,
  useProfile,
  getUniqueStudents,
} from '../../../../../utils';
import { useLessonPlannerData, useNotificationsData, useStudentsData } from '../../../../../hooks';
import {
  useLessonsService,
  useOneTimeActionService,
  useTeachersService,
} from '../../../../../services';
import {
  AppActions,
  AppContext,
  LessonPlannerActions,
  LessonPlannerContext,
  UserActions,
  UserContext,
} from '../../../../../context';
import {
  AssignmentTypes,
  AttachmentsResourcesTypes,
  CourseSources,
  InputsValidationErrors,
  InputsValidationRules,
  PublicationStatuses,
  OneTimeActionsMap,
  StudentActivityCriterions,
  NotificationResourcesMap,
  NotificationSourcesMap,
} from '../../../../../constants/enums';
import { myLessonsLessonViewRoute, myLessonsRoute } from '../../../../../constants/routes';
import {
  ContentSectionTabs,
  LessonPageBlocksConfig,
  LessonSections,
} from '../../../../../constants/lesson-planner';
import { NewFeaturesIdsMap } from '../../../../../tours/common/NewFeaturesItemsProvider';
import { ReactComponent as DeleteIcon } from '../../../../../resources/icons/delete.svg';
import { ReactComponent as GoogleClassromIcon } from '../../../../../resources/icons/google_classrom.svg';
import { getInsightsWithoutUnassigned } from '../../assignment-components/assignment-insights/shared';
import { getAssignmentsOptions } from '../../assignment-components/edit-assignments/helpers';
import AssignmentRenderer from '../../assignment-components/assignment-renderer/AssignmentRenderer';
import AssignmentInsights from '../../assignment-components/assignment-insights/AssignmentInsights';
import LessonPageErrorBox from '../components/lesson-page-error-box/LessonPageErrorBox';
import LessonPageSection from '../components/lesson-page-section/LessonPageSection';
import LessonPageRemovableBlock from '../components/lesson-page-removable-block/LessonPageRemovableBlock';
import LessonPageMaterialsContent from '../components/lesson-page-materials-content/LessonPageMaterialsContent';
import LessonPageTeksContent from '../components/lesson-page-teks-content/LessonPageTeksContent';
import LessonPageAgendaBoard from '../components/lesson-page-agenda-board/LessonPageAgendaBoard';
import LessonPageTabEmptyState from '../components/lesson-page-tab-empty-state/LessonPageTabEmptyState';

const AUTO_SAVING_DELAY = 1000 * 60;

const MAX_ASSIGNMENT_NUMBER = 30;

const agendaKeysToLabelsMap = {
  agenda: 'Agenda',
  reminders: 'Reminders',
  teks: 'TEKS',
};

const agendaValidationInitState = {
  agenda: '',
  reminders: '',
  teks: '',
};

const scrollToFirstError = () => {
  setTimeout(() => {
    const el = document.querySelector('.ayo-lesson-page__error-box .ayo-link');
    if (el) {
      el.focus();
    }
  }, 100);
};

const sortTeks = (teks) =>
  teks.sort((a, b) => a.display.localeCompare(b.display) || a.text.localeCompare(b.text));

const createAssignment = (assignmentType, students, order, defaultFields = {}) => ({
  tempId: uuid(),
  status: PublicationStatuses.DRAFT,
  assignmentOrder: order,
  workType: assignmentType,
  title: '',
  description: '',
  points: 100,
  submissionDeadline: null,
  postingDate: null,
  assignedStudentsEmails: students?.map((student) => student.email),
  questionChoices: ['', ''],
  attachments: [],
  links: [],
  studentGroupCategory: null,
  studentGroupNames: null,
  ...defaultFields,
});

const markAssignmentsOrder = (assignmentsToMark) =>
  assignmentsToMark.map((assignment, index) => ({ ...assignment, assignmentOrder: index }));

const makeAssignmentAddAction = (title, description, handler) => ({
  text: (
    <Box alignItems="center" display="flex">
      {title}
      <Box ml={1}>
        <InformationalCaption showTooltip title={description} />
      </Box>
    </Box>
  ),
  icon: GoogleClassromIcon,
  handler,
});

const getPublishingAssignments = (assignments) =>
  assignments.map((assignment) => ({
    ...assignment,
    status: PublicationStatuses.PUBLISHED,
    questionChoices: assignment.questionChoices?.filter(Boolean),
  }));

const hasUnPublishedAssignments = (assignments) =>
  assignments.some(({ status }) => status === PublicationStatuses.DRAFT);

const LessonPageTeacher = ({ Header, redirectTo }) => {
  const { state: appState, dispatch: dispatchAppState } = useContext(AppContext);
  const { state: userState, dispatch: dispatchUserState } = useContext(UserContext);
  const { state: lessonPlannerState, dispatch: dispatchLessonPlannerState } =
    useContext(LessonPlannerContext);
  const { lessonData, teksData, teachersList } = lessonPlannerState;
  const { i18n, t } = useTranslation();
  const history = useHistory();
  const { params } = useRouteMatch();
  const { lessonId } = params;
  const previousParamsRef = useRef(params);
  const { getTeachersList } = useTeachersService();
  const {
    getTeks,
    getSuggestedTeks,
    getLessonById,
    getLessonAssignments,
    deleteLessonAssignment,
    getLessonAssignmentInsights,
    getAssignmentChoices,
    deleteAssignmentChoices,
    deleteAssignmentChoicesLayer,
    postAssignmentChoices,
    putAssignmentChoices,
  } = useLessonsService();
  const { saveLesson, updateLessonInDateData, assignmentsSorter, updateLessonsMetadata } =
    useLessonPlannerData();
  const { getOneTimeActionStatus } = useProfile();
  const { postOneTimeAction } = useOneTimeActionService();
  const { updateNotificationItems, getUnreadNotificationsByResourceAndId } = useNotificationsData();

  const theme = useTheme();
  const isWidthUpMd = useMediaQuery(theme.breakpoints.up('md'), { noSsr: true });

  const isOwner = useMemo(
    () => lessonData?.owner?.id === userState.profile.id,
    [lessonData?.owner?.id, userState.profile?.id],
  );

  const isPublished = useMemo(
    () => lessonData?.status === PublicationStatuses.PUBLISHED,
    [lessonData],
  );
  const [isEditable, setIsEditable] = useState(true);

  const lessonIdNumber = useMemo(() => {
    const converted = parseInt(lessonId, 10);

    return Number.isSafeInteger(converted) ? converted : lessonId;
  }, [lessonId]);

  const unreadLessonUpdateNotifications = useMemo(
    () =>
      getUnreadNotificationsByResourceAndId(
        NotificationSourcesMap.WEB_HIDDEN,
        NotificationResourcesMap.LESSON_SHARE_UPDATE,
        lessonIdNumber,
      ),
    [getUnreadNotificationsByResourceAndId, lessonIdNumber],
  );

  const [isUnsavedChanges, setIsUnsavedChanges] = useState(false);
  const [isConfirmDeleteLessonDialogOpen, setIsConfirmDeleteLessonDialogOpen] = useState(false);

  const [title, setTitle] = useState('');
  const [titleError, setTitleError] = useState('');
  const [teksError, setTeksError] = useState('');
  const [textError, setTextError] = useState('');
  const [agendaErrors, setAgendaErrors] = useState(agendaValidationInitState);
  const [lessonErrors, setLessonErrors] = useState([]);

  const [newTeksData, setNewTeksData] = useState({
    tekses: [],
  });
  const [suggestedTeksToDisplay, setSuggestedTeksToDisplay] = useState([]);
  const [richTextData, setRichTextData] = useState(
    LessonPageBlocksConfig.richTextEditor.defaultData,
  );
  const [materialsData, setMaterialsData] = useState(LessonPageBlocksConfig.materials.defaultData);
  const [agendaBoardData, setAgendaBoardData] = useState(
    LessonPageBlocksConfig.agendaBoard.defaultData,
  );

  const [isMaterialsUploadInProgress, setIsMaterialsUploadInProgress] = useState(false);

  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);

  const [shouldRefreshLessonData, setShouldRefreshLessonData] = useState(false);

  const timeoutRef = useRef(null);
  const lessonBodyRef = useRef({});

  const assignmentsDeleteListRef = useRef([]);

  const putAssignmentInDeleteList = (_, assignmentId) =>
    new Promise((resolve) => {
      assignmentsDeleteListRef.current = [...assignmentsDeleteListRef.current, assignmentId];
      resolve();
    });

  const formattedDate = useMemo(() => {
    const date = dayjs(lessonData?.classMetadata.classDate);
    const formatted = getFormattedDate(date.toDate(), i18n.language, {
      day: 'numeric',
      weekday: 'short',
      month: 'long',
    });

    return isCurrentYear(date) ? formatted : `${formatted}, ${date.get('year')}`;
  }, [i18n.language, lessonData?.classMetadata.classDate]);

  const isInformationalMessageHidden = useMemo(
    () => getOneTimeActionStatus(OneTimeActionsMap.CREATE_LESSON_NOTIFICATION),
    [getOneTimeActionStatus],
  );

  const handleInformationalMessage = useCallback(() => {
    postOneTimeAction(OneTimeActionsMap.CREATE_LESSON_NOTIFICATION);
    dispatchUserState({
      type: UserActions.SET_ONE_TIME_ACTION,
      data: OneTimeActionsMap.CREATE_LESSON_NOTIFICATION,
    });
  }, [dispatchUserState, postOneTimeAction]);

  const [assignments, setAssignments] = useState([]);
  const assignmentsRef = useRef();
  assignmentsRef.current = assignments;

  const [choices, setChoices] = useState({});
  const choicesRef = useRef();
  choicesRef.current = choices;

  const lessonIdRef = useRef(lessonIdNumber);
  lessonIdRef.current = lessonIdNumber;

  const silentSave = useCallback(
    (autoSaveSnackbar = false) => {
      if (!lessonBodyRef.current.status) {
        lessonBodyRef.current.status = PublicationStatuses.DRAFT;
      }

      return saveLesson(
        lessonBodyRef.current,
        assignmentsRef.current,
        choicesRef.current,
        lessonIdRef.current,
        true,
        null,
        (savedLesson, assignmentsData, choicesData) => {
          if (autoSaveSnackbar) {
            setIsSnackbarOpen(true);
          }

          setChoices(choicesData);

          if (!lessonId) {
            const { scrollTop } = document.documentElement;
            history.replace(myLessonsLessonViewRoute.replace(':lessonId', savedLesson.id));
            setTimeout(() => window.scroll(0, scrollTop), 0);
          }

          setAssignments(
            assignmentsData.filter(
              (assignment) => !assignmentsDeleteListRef.current.includes(assignment.id),
            ),
          );

          updateLessonInDateData(
            savedLesson.id ?? lessonIdNumber,
            savedLesson.createdDate,
            lessonBodyRef.current.title,
            lessonBodyRef.current.status,
            lessonBodyRef.current.classMetadata,
            false,
          );

          updateLessonsMetadata(
            savedLesson.id ?? lessonIdNumber,
            savedLesson.title,
            savedLesson.status,
            savedLesson.classMetadata,
            savedLesson.sharedWith,
          );
        },
      );
    },
    [history, lessonId, lessonIdNumber, saveLesson, updateLessonInDateData, updateLessonsMetadata],
  );

  const clearAutoSave = useCallback((isChanges = false) => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = null;
    setIsUnsavedChanges(isChanges);
  }, []);

  const getAutoSaveTimeout = useCallback(() => {
    if (!lessonBodyRef.current.status) {
      lessonBodyRef.current.status = PublicationStatuses.DRAFT;
    }

    return setTimeout(() => {
      silentSave(true);
      clearAutoSave();
    }, AUTO_SAVING_DELAY);
  }, [clearAutoSave, silentSave]);

  const setAutoSave = useCallback(() => {
    if (isPublished) return;

    if (!timeoutRef.current) {
      timeoutRef.current = getAutoSaveTimeout();
    }
    if (!isUnsavedChanges) {
      setIsUnsavedChanges(true);
    }
  }, [getAutoSaveTimeout, isPublished, isUnsavedChanges]);

  const validatorRef = useRef({});

  const assignmentUpdater = (assignmentIndex) => (assignment) => {
    setAssignments((prevAssignments) => {
      const newAssignments = [...prevAssignments];
      newAssignments.splice(assignmentIndex, 1, assignment);
      return newAssignments;
    });
    setAutoSave();
  };

  const assignmentDeleter = (assignmentIndex) => () => {
    const showSuccessSnackbar = () =>
      dispatchAppState({
        type: AppActions.SET_SNACKBAR_STATUS,
        data: { type: 'delete', text: t('The assignment has been deleted.') },
      });

    const showFailSnackbar = () =>
      dispatchAppState({
        type: AppActions.SET_SNACKBAR_STATUS,
        data: {
          type: 'error',
          text: t('AYO couldn’t delete the assignment.'),
          action: (
            <Button autoFocus gaLabel="Retry" onClick={assignmentDeleter(assignmentIndex)}>
              {t('Retry')}
            </Button>
          ),
        },
      });

    setAssignments((prevAssignments) => {
      const newAssignments = [...prevAssignments];
      newAssignments.splice(assignmentIndex, 1);
      if (!hasUnPublishedAssignments(newAssignments)) {
        dispatchAppState({ type: AppActions.SET_IS_DIRTY, data: false });
      }
      return markAssignmentsOrder(newAssignments);
    });
    const deletedItem = assignments[assignmentIndex];
    const isAssignmentPublished = deletedItem.status === PublicationStatuses.PUBLISHED;
    const deleteMethod = isAssignmentPublished ? putAssignmentInDeleteList : deleteLessonAssignment;
    if (lessonId && deletedItem.id) {
      deleteMethod(lessonId, deletedItem.id).then(showSuccessSnackbar).catch(showFailSnackbar);
    } else {
      showSuccessSnackbar();
    }
    setAutoSave();
  };

  const assignmentReorderer = (assignmentIndex) => (targetIndex) => {
    setAssignments((prevAssignments) => {
      const newAssignments = reorder(prevAssignments, assignmentIndex, targetIndex);
      return markAssignmentsOrder(newAssignments);
    });
    setAutoSave();
  };

  const choicesDeleteListRef = useRef([]);

  const putChoicesInDeleteList = (_, assignmentId, choicesId) =>
    new Promise((resolve) => {
      choicesDeleteListRef.current = [
        ...choicesDeleteListRef.current.filter(
          ({ choicesId: deleteItemChoicesId }) => deleteItemChoicesId !== choicesId,
        ),
        { assignmentId, choicesId },
      ];
      resolve();
    });

  const putChoicesLayerInDeleteList = (_, assignmentId, choicesId, layerId) =>
    new Promise((resolve) => {
      choicesDeleteListRef.current = [
        ...choicesDeleteListRef.current,
        { assignmentId, choicesId, layerId },
      ];
      resolve();
    });

  const choicesDeleter = (assignmentId) => (choicesId) => {
    const updateChoices = () => setChoices({ ...choices, [assignmentId]: null });

    const deleteMethod = isPublished ? putChoicesInDeleteList : deleteAssignmentChoices;

    if (choicesId) {
      deleteMethod(lessonId, assignmentId, choicesId).then(() => {
        updateChoices();
      });
    } else {
      updateChoices();
    }
  };

  const choicesLayerDeleter = (assignmentId) => (choicesId, layerId, level) => {
    const updateChoices = () => {
      const newChoices = {
        ...choices[assignmentId],
        layers: choices[assignmentId].layers
          .filter((layer) => layer.layer !== level)
          .map((layer, idx) => ({ ...layer, layer: idx })),
      };
      setChoices({ ...choices, [assignmentId]: newChoices });
      // POST to update layers order
      if (!isPublished && choicesId) {
        const updateChoicesMethod = newChoices.id ? putAssignmentChoices : postAssignmentChoices;
        updateChoicesMethod(lessonId, assignmentId, newChoices);
      }
    };
    const deleteMethod = isPublished ? putChoicesLayerInDeleteList : deleteAssignmentChoicesLayer;

    if (choicesId && layerId) {
      deleteMethod(lessonId, assignmentId, choicesId, layerId).then(() => {
        updateChoices();
      });
    } else {
      updateChoices();
    }
  };

  const handleLeave = useCallback(
    (savedLesson, lessonDeleted) => {
      updateLessonInDateData(
        savedLesson.id ?? lessonIdNumber,
        savedLesson.createdDate,
        lessonBodyRef.current.title,
        lessonBodyRef.current.status,
        lessonBodyRef.current.classMetadata,
        lessonDeleted,
      );

      updateLessonsMetadata(
        savedLesson.id ?? lessonIdNumber,
        lessonBodyRef.current.title,
        savedLesson.status,
        savedLesson.classMetadata,
        savedLesson.sharedWith,
        lessonDeleted,
      );
    },
    [updateLessonInDateData, lessonIdNumber, updateLessonsMetadata],
  );

  const backToLink = redirectTo ?? myLessonsRoute;

  const getSaveLessonSnackbarData = useCallback(
    (failCallback, skipSuccessSnackbar, isPublish, isEdit) => ({
      success: skipSuccessSnackbar
        ? null
        : {
            type: 'success',
            text: (
              <Trans
                i18nKey={
                  isPublish
                    ? isEdit
                      ? 'The changes have been successfully saved.'
                      : 'The lesson has been successfully published for classDate.'
                    : 'The latest changes in the draft have been successfully saved.'
                }
                values={{ classDate: formattedDate }}
              />
            ),
          },
      fail: {
        type: 'error',
        text: (
          <Trans
            i18nKey={
              isPublish
                ? isEdit
                  ? 'AYO couldn’t save the changes. Please try once more.'
                  : 'AYO couldn’t publish the lesson for classDate. Please try once more.'
                : 'AYO couldn’t save the latest changes in the draft Please try once more.'
            }
            values={{ classDate: formattedDate }}
          />
        ),
        action: (
          <Button
            autoFocus
            gaLabel="Retry"
            onClick={() => {
              failCallback(isPublish);
            }}
          >
            {t('Retry')}
          </Button>
        ),
      },
    }),
    [formattedDate, t],
  );

  const shouldSaveOnUnmountRef = useRef(true);

  // Used to revert changes on edit cancel
  const assignmentsCopyRef = useRef();
  const choicesCopyRef = useRef();

  const handleSave = useCallback(
    (options) => {
      const defaultOptions = {
        isPublish: false,
        isEdit: false,
        skipSuccessSnackbar: false,
        updateContext: true,
        skipAssignmentsUpdate: false,
      };

      const saveOptions = {
        ...defaultOptions,
        ...options,
      };

      const { isPublish, isEdit, skipSuccessSnackbar, updateContext, skipAssignmentsUpdate } =
        saveOptions;

      if (isPublish) {
        lessonBodyRef.current.status = PublicationStatuses.PUBLISHED;

        assignmentsRef.current = getPublishingAssignments(assignmentsRef.current);

        if (
          deepEqual(
            lessonBodyRef.current.reachTextEditorData,
            LessonPageBlocksConfig.richTextEditor.defaultData,
          ) &&
          !isPublished
        ) {
          lessonBodyRef.current.reachTextEditorData.isVisible = false;
        }

        if (
          deepEqual(
            lessonBodyRef.current.materialsData,
            LessonPageBlocksConfig.materials.defaultData,
            ['createdDate', 'updatedDate'],
          ) &&
          !isPublished
        ) {
          lessonBodyRef.current.materialsData.isVisible = false;
        }

        if (
          deepEqual(
            lessonBodyRef.current.agendaBoardData,
            LessonPageBlocksConfig.agendaBoard.defaultData,
            ['updatedDate'],
          ) &&
          !isPublished
        ) {
          lessonBodyRef.current.agendaBoardData.isVisible = false;
        }
      } else {
        lessonBodyRef.current.status = PublicationStatuses.DRAFT;
      }

      clearAutoSave();

      shouldSaveOnUnmountRef.current = false;

      const choicesToSave = Object.entries(choicesRef.current).reduce(
        (acc, [assignmentId, choice]) => {
          const prevChoicesVersion = choicesCopyRef.current?.[assignmentId];
          if (prevChoicesVersion) {
            const isSameVersion = deepEqual(choice, prevChoicesVersion);
            return isSameVersion ? acc : { ...acc, [assignmentId]: choice };
          }
          return { ...acc, [assignmentId]: choice };
        },
        {},
      );

      saveLesson(
        lessonBodyRef.current,
        skipAssignmentsUpdate ? null : assignmentsRef.current,
        choicesToSave,
        lessonIdNumber,
        false,
        getSaveLessonSnackbarData(
          () => {
            handleSave(saveOptions);
          },
          skipSuccessSnackbar,
          isPublish,
          isEdit,
        ),
        (savedLesson, assignmentsData, choicesData) => {
          handleLeave(savedLesson, false);

          if (!skipAssignmentsUpdate) {
            setAssignments(assignmentsData);
          }

          setChoices((prevChoices) => ({ ...prevChoices, ...choicesData }));

          if (updateContext) {
            dispatchLessonPlannerState({
              type: LessonPlannerActions.SET_LESSON_DATA,
              data: savedLesson,
            });
          }

          if (isPublished) {
            setIsEditable(false);
            dispatchAppState({ type: AppActions.SET_IS_DIRTY, data: false });
          } else if (lessonId) {
            history.push(backToLink);
          } else {
            history.replace(backToLink);
          }
        },
      );
    },
    [
      backToLink,
      clearAutoSave,
      dispatchAppState,
      dispatchLessonPlannerState,
      getSaveLessonSnackbarData,
      handleLeave,
      history,
      isPublished,
      lessonId,
      lessonIdNumber,
      saveLesson,
    ],
  );

  const handleDelete = useCallback(() => {
    const body = {
      ...lessonBodyRef.current,
      status: PublicationStatuses.DELETED,
    };

    clearAutoSave();
    setIsConfirmDeleteLessonDialogOpen(false);

    if (appState.isBackgroundSync) {
      dispatchAppState({ type: AppActions.SET_IS_BACKGROUND_SYNC, data: false });
    }

    saveLesson(
      body,
      assignmentsRef.current,
      choicesRef.current,
      lessonIdNumber,
      false,
      {
        success: {
          type: 'delete',
          text: t(isPublished ? 'The lesson has been deleted.' : 'The draft has been deleted.'),
          action: (
            <Button
              autoFocus
              gaLabel="Undo"
              onClick={() =>
                handleSave({
                  isPublish: isPublished,
                  skipSuccessSnackbar: true,
                  skipAssignmentsUpdate: true,
                  updateContext: false,
                })
              }
            >
              {t('Undo')}
            </Button>
          ),
        },
        fail: {
          type: 'error',
          text: t(
            isPublished
              ? 'AYO couldn’t delete the lesson. Please try once more.'
              : 'AYO couldn’t delete the draft. Please try once more.',
          ),
          action: (
            <Button
              autoFocus
              gaLabel="Retry"
              onClick={() => {
                handleDelete();
              }}
            >
              {t('Retry')}
            </Button>
          ),
        },
      },
      (savedLesson) => {
        handleLeave(savedLesson, true);
        dispatchAppState({ type: AppActions.SET_IS_DIRTY, data: false });
        history.replace(backToLink);
      },
    );
  }, [
    appState.isBackgroundSync,
    backToLink,
    clearAutoSave,
    dispatchAppState,
    handleLeave,
    handleSave,
    history,
    isPublished,
    lessonIdNumber,
    saveLesson,
    t,
  ]);

  const validateTitle = useCallback(
    (value) => {
      let errorText = '';
      if (value.trim().length < InputsValidationRules.MIN_INPUT_LENGTH) {
        errorText = InputsValidationErrors(
          t,
          InputsValidationRules.MIN_INPUT_LENGTH,
        ).MIN_ERROR_TEXT;
      }
      if (value.trim().length > InputsValidationRules.MAX_TITLE_LENGTH) {
        errorText = InputsValidationErrors(
          t,
          InputsValidationRules.MAX_TITLE_LENGTH,
        ).MAX_ERROR_TEXT;
      }
      setTitleError(errorText);
      return errorText;
    },
    [t],
  );

  const validateTeks = useCallback(
    (teks) => {
      let errorText = '';
      if (teksData?.all?.length && !teks.length) {
        errorText = t('Please add TEKS that you need to cover.');
      }
      setTeksError(errorText);
      return errorText;
    },
    [t, teksData?.all?.length],
  );

  const validateText = useCallback(
    (rawText) => {
      let errorText = '';
      if (rawText?.length > InputsValidationRules.MAX_DESCRIPTION_LENGTH) {
        errorText = InputsValidationErrors(
          t,
          InputsValidationRules.MAX_DESCRIPTION_LENGTH,
        ).MAX_ERROR_TEXT;
      }
      setTextError(errorText);
      return errorText;
    },
    [t],
  );

  const validateAgendaBoard = useCallback(
    (component) => {
      const overflowCorrectionValue = 256;
      const agendaEl = document.getElementById(LessonPageBlocksConfig.agendaBoard.key.id);
      const tabPanelEl = document.getElementById(
        `simple-tabpanel-${ContentSectionTabs.SHARED_WITH_STUDENTS}`,
      );
      const isHidden = agendaEl.clientHeight === 0;
      if (isHidden) {
        tabPanelEl.style.display = 'block';
      }
      if (agendaEl.clientHeight > window.innerHeight + overflowCorrectionValue) {
        if (component && !agendaErrors[component]) {
          setAgendaErrors((state) => ({
            ...state,
            [component]: t('Please decrease your input to fit into 1 slide'),
          }));
        }
      } else if (agendaErrors[component]) {
        setAgendaErrors(agendaValidationInitState);
      }
      if (isHidden) {
        tabPanelEl.style.display = 'none';
      }
    },
    [agendaErrors, t],
  );

  const validateAssignments = useCallback(
    () =>
      assignments.reduce((acc, assignment) => {
        const id = assignment.id || assignment.tempId;
        const validationResult = validatorRef.current[id]?.();

        const lvl1Label = getAssignmentsOptions(t).filter((x) => x.value === assignment.workType)[0]
          .label;
        return validationResult && validationResult.length !== 0
          ? [
              ...acc,
              ...validationResult.map((item) => ({ ...item, lvl1Label, id: `assignment-${id}` })),
            ]
          : acc;
      }, []),
    [assignments, t],
  );

  // store callback to be executed right after section has been opened
  const handleSectionOpenedRef = useRef();

  const validateLesson = useCallback(() => {
    let errorsList = [];
    const titleValidationError = validateTitle(title);
    const teksValidationError = validateTeks(newTeksData.tekses);
    const agendaValidationErrors = Object.entries(agendaErrors).reduce(
      (acc, [key, value]) =>
        value
          ? [
              ...acc,
              {
                errorText: value,
                lvl2Label: t(agendaKeysToLabelsMap[key]),
              },
            ]
          : acc,
      [],
    );
    const assignmentsValidationErrors = validateAssignments();

    if (titleValidationError !== '') {
      errorsList = [
        ...errorsList,
        {
          errorText: titleValidationError,
          lvl0Label: t('Title'),
          section: LessonSections.PRIMARY_DETAILS,
          id: 'lesson-title',
        },
      ];
    }
    if (teksValidationError !== '') {
      errorsList = [
        ...errorsList,
        {
          errorText: teksValidationError,
          lvl0Label: t('TEKS'),
          section: LessonSections.PRIMARY_DETAILS,
          id: 'lesson-teks',
        },
      ];
    }
    if (textError !== '') {
      errorsList = [
        ...errorsList,
        {
          errorText: textError,
          lvl0Label: t('Text'),
          section: LessonSections.PLANNING_AND_PREPARATION,
          tab: ContentSectionTabs.PLANNING,
          id: LessonPageBlocksConfig.richTextEditor.key.id,
          onOpen: () => {
            handleSectionOpenedRef.current = () => {
              const focusableEl = getFocusableElement(
                document.getElementById(LessonPageBlocksConfig.richTextEditor.key.id),
              );
              scrollAndFocusEl(focusableEl);
              handleSectionOpenedRef.current = null;
            };
          },
        },
      ];
    }
    if (isMaterialsUploadInProgress) {
      errorsList = [
        ...errorsList,
        {
          errorText: t('You have some files that are loading to the lesson'),
          lvl0Label: t('Materials'),
          section: LessonSections.PLANNING_AND_PREPARATION,
          tab: ContentSectionTabs.PLANNING,
          id: LessonPageBlocksConfig.materials.key.id,
        },
      ];
    }
    if (agendaValidationErrors.length) {
      errorsList = [
        ...errorsList,
        ...agendaValidationErrors.map((item) => ({
          ...item,
          lvl1Label: t(LessonPageBlocksConfig.agendaBoard.key.text),
          lvl0Label: t(LessonPageBlocksConfig.agendaBoard.key.text),
          section: LessonSections.AGENDA_BOARD,
          tab: ContentSectionTabs.SHARED_WITH_STUDENTS,
          id: LessonPageBlocksConfig.agendaBoard.key.id,
        })),
      ];
    }
    if (assignmentsValidationErrors.length) {
      errorsList = [
        ...errorsList,
        ...assignmentsValidationErrors.map((item) => ({
          ...item,
          lvl0Label: t(LessonSections.ASSIGNMENTS),
          section: LessonSections.ASSIGNMENTS,
          tab: ContentSectionTabs.SHARED_WITH_STUDENTS,
          onOpen: () => {
            handleSectionOpenedRef.current = () => {
              const focusableEl = getFocusableElement(
                document.getElementById(item.id),
                item.errorText,
              );
              scrollAndFocusEl(focusableEl);
              handleSectionOpenedRef.current = null;
            };
          },
        })),
      ];
    }
    return errorsList;
  }, [
    agendaErrors,
    validateAssignments,
    isMaterialsUploadInProgress,
    newTeksData.tekses,
    t,
    textError,
    title,
    validateTeks,
    validateTitle,
  ]);

  const handleAssignmentsSectionOpen = useCallback(() => {
    if (lessonErrors.find((error) => error.section === LessonSections.ASSIGNMENTS)) {
      validateAssignments();
    }
  }, [lessonErrors, validateAssignments]);

  const handleTitleChange = useCallback(
    (e) => {
      setTitle(e.target.value);

      if (titleError) {
        validateTitle(e.target.value);
      }

      setAutoSave();
    },
    [setAutoSave, titleError, validateTitle],
  );

  const handleTeksChange = useCallback(
    (data, isOverride, isRemove) => {
      let newTeks;

      if (isOverride) {
        newTeks = sortTeks(data);

        setSuggestedTeksToDisplay((prevState) =>
          sortTeks(
            prevState.filter(
              (suggestedTeks) =>
                !data.find(
                  (addedTeks) =>
                    addedTeks.display === suggestedTeks.display &&
                    addedTeks.text === suggestedTeks.text,
                ),
            ),
          ),
        );

        setNewTeksData(() => ({ tekses: newTeks }));
      } else {
        setNewTeksData((prevState) => {
          newTeks = sortTeks(
            isRemove
              ? prevState.tekses.filter(
                  (x) => !(x.display === data.display && x.text === data.text),
                )
              : [...prevState.tekses, data],
          );

          return {
            tekses: newTeks,
          };
        });

        if (
          teksData?.suggested?.find(
            (item) => item.display === data.display && item.text === data.text,
          )
        ) {
          setSuggestedTeksToDisplay((prevState) =>
            sortTeks(
              isRemove
                ? [...prevState, data]
                : prevState.filter((x) => !(x.display === data.display && x.text === data.text)),
            ),
          );
        }
      }

      if (teksError) {
        validateTeks(newTeks);
      }

      setAutoSave();
    },
    [setAutoSave, teksData?.suggested, teksError, validateTeks],
  );

  const handleTextEditorChange = useCallback(
    (key, data, rawData) => {
      setRichTextData((state) => ({ ...state, [key]: data }));

      validateText(rawData);

      setAutoSave();
    },
    [setAutoSave, validateText],
  );

  const handleMaterialsChange = useCallback(
    (key, addedItems, allItems) => {
      setMaterialsData((state) => ({
        ...state,
        [key]: addedItems ? [...state[key], ...addedItems] : allItems,
      }));

      setAutoSave();
    },
    [setAutoSave],
  );

  const handleAgendaChange = useCallback(
    (key, property, data) => {
      validateAgendaBoard(key);

      setAgendaBoardData((state) => ({
        ...state,
        [key]: {
          ...state[key],
          [property]: data,
        },
      }));

      setAutoSave();
    },
    [setAutoSave, validateAgendaBoard],
  );

  const handleSectionAdd = useCallback(
    (key, onAdd) => {
      onAdd(LessonPageBlocksConfig[key].defaultData);

      setAutoSave();

      setTimeout(() => {
        const container = document.getElementById(LessonPageBlocksConfig[key].key.id);
        if (container) {
          container.querySelector('.ayo-button--icon')?.focus();
        }
      }, 100);
    },
    [setAutoSave],
  );

  const handleSectionRemove = useCallback(
    (key, onRemove, rollbackData) => {
      if (!lessonBodyRef.current.status) {
        lessonBodyRef.current.status = PublicationStatuses.DRAFT;
      }

      lessonBodyRef.current[LessonPageBlocksConfig[key].apiKey] = {
        ...LessonPageBlocksConfig[key].defaultData,
        isVisible: false,
      };

      if (!isPublished) {
        saveLesson(
          lessonBodyRef.current,
          assignmentsRef.current,
          choicesRef.current,
          lessonIdNumber,
          false,
          {
            success: {
              type: 'delete',
              text: t(LessonPageBlocksConfig[key].removeSnackbarConfig.successText),
            },
            fail: {
              type: 'error',
              text: t(LessonPageBlocksConfig[key].removeSnackbarConfig.failureText),
              action: (
                <Button
                  autoFocus
                  gaLabel="Retry"
                  onClick={() => handleSectionRemove(key, onRemove, rollbackData)}
                >
                  {t('Retry')}
                </Button>
              ),
            },
          },
          (savedLesson, assignmentsData, choicesData) => {
            if (!lessonId) {
              history.replace(myLessonsLessonViewRoute.replace(':lessonId', savedLesson.id));
            }

            const container = document.getElementById(
              LessonPageBlocksConfig[key].key.id,
            )?.parentNode;
            if (container) {
              setTimeout(() => {
                container.querySelector('.ayo-expandable-menu-title')?.focus();
              }, 100);
            }

            setAssignments(assignmentsData);
            setChoices(choicesData);

            onRemove({ ...LessonPageBlocksConfig[key].defaultData, isVisible: false });

            updateLessonInDateData(
              savedLesson.id ?? lessonIdNumber,
              savedLesson.createdDate,
              lessonBodyRef.current.title,
              lessonBodyRef.current.status,
              lessonBodyRef.current.classMetadata,
            );

            updateLessonsMetadata(
              savedLesson.id ?? lessonIdNumber,
              savedLesson.title,
              savedLesson.status,
              savedLesson.classMetadata,
              savedLesson.sharedWith,
            );
          },
          () => {
            lessonBodyRef.current[LessonPageBlocksConfig[key].apiKey] = rollbackData;
          },
        );
      } else {
        const container = document.getElementById(LessonPageBlocksConfig[key].key.id)?.parentNode;
        if (container) {
          setTimeout(() => {
            container.querySelector('.ayo-expandable-menu-title')?.focus();
          }, 100);
        }

        onRemove({ ...LessonPageBlocksConfig[key].defaultData, isVisible: false });
      }

      setIsUnsavedChanges(false);
      clearAutoSave(true);
    },
    [
      clearAutoSave,
      history,
      isPublished,
      lessonId,
      lessonIdNumber,
      saveLesson,
      t,
      updateLessonInDateData,
      updateLessonsMetadata,
    ],
  );

  const handleCancelEditing = useCallback(() => {
    setTitle(lessonData.title);

    if (lessonData.teksData) {
      setNewTeksData({ ...lessonData.teksData });
    }

    if (lessonData.reachTextEditorData) {
      setRichTextData({ ...lessonData.reachTextEditorData });
    }

    if (lessonData.materialsData) {
      setMaterialsData({ ...lessonData.materialsData });
    }

    if (lessonData.agendaBoardData) {
      setAgendaBoardData({ ...lessonData.agendaBoardData });
    }

    if (hasUnPublishedAssignments(assignments)) {
      assignments
        .filter(({ status }) => status === PublicationStatuses.DRAFT)
        .forEach(({ id }) => deleteLessonAssignment(lessonId, id));
    }

    setAssignments(assignmentsCopyRef.current || []);
    setChoices(choicesCopyRef.current);

    assignmentsDeleteListRef.current = [];
    choicesDeleteListRef.current = [];

    setIsEditable(false);
    setTitleError('');
    setTeksError('');
    if (lessonErrors.length) {
      setLessonErrors([]);
    }
  }, [lessonErrors.length, lessonData, assignments, deleteLessonAssignment, lessonId]);

  const handleCancelBtnClick = useCallback(() => {
    if (appState.isDirty) {
      dispatchAppState({
        type: AppActions.SET_IS_LEAVE_DIALOG_OPEN,
        data: true,
      });
    } else {
      handleCancelEditing();
    }
  }, [appState.isDirty, dispatchAppState, handleCancelEditing]);

  const handleSetLessonData = useCallback(
    (action) => {
      getLessonById(lessonIdNumber).then((data) => {
        dispatchLessonPlannerState({
          type: LessonPlannerActions.SET_LESSON_DATA,
          data,
        });

        setTitle(data.title);

        if (data.teksData) {
          setNewTeksData({ ...data.teksData });
        }

        if (data.reachTextEditorData) {
          setRichTextData({
            ...data.reachTextEditorData,
          });
        }

        if (data.materialsData) {
          setMaterialsData({
            ...data.materialsData,
          });
        }

        if (data.agendaBoardData) {
          setAgendaBoardData({
            ...data.agendaBoardData,
          });
        }
        if (action) {
          action();
        }
      });
    },
    [dispatchLessonPlannerState, getLessonById, lessonIdNumber],
  );

  const handleSetAssignmentData = useCallback(
    (action) => {
      if (lessonIdNumber) {
        getLessonAssignments(lessonIdNumber).then((assignmentsData) => {
          const assignmentsIds = assignmentsData.map((assignment) => assignment.id);
          Promise.all(
            assignmentsIds.map((assignmentId) =>
              getAssignmentChoices(lessonIdNumber, assignmentId),
            ),
          ).then((choicesData) => {
            setChoices(
              choicesData.reduce(
                (acc, choice, idx) => ({ ...acc, [assignmentsIds[idx]]: choice }),
                {},
              ),
            );
          });
          setAssignments(assignmentsData.sort(assignmentsSorter));
          if (action) {
            action();
          }
        });
      }
    },
    [assignmentsSorter, getAssignmentChoices, getLessonAssignments, lessonIdNumber],
  );

  useEffect(() => {
    if (!lessonData && !lessonIdNumber) {
      history.push(myLessonsRoute);
    } else if (!lessonData) {
      handleSetLessonData();
    }
    handleSetAssignmentData();

    if (!teachersList) {
      getTeachersList(userState.profile.currentCampus).then((data) => {
        dispatchLessonPlannerState({
          type: LessonPlannerActions.SET_TEACHERS_LIST,
          data,
        });
      });
    }
    previousParamsRef.current = params;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const previousParams = previousParamsRef.current;
    if (shouldRefreshLessonData || previousParams.lessonId !== lessonId) {
      handleSetLessonData(
        handleSetAssignmentData(() => {
          setShouldRefreshLessonData(false);
        }),
      );
    }
    previousParamsRef.current = params;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRefreshLessonData, previousParamsRef, lessonId]);

  useEffect(() => {
    const coursesWithTeksNumbers = lessonData?.classMetadata.classesPeriod.courses.reduce(
      (acc, course) => {
        if (course.courseSource === CourseSources.COURSE) {
          return [...acc, course.courseNumber];
        }
        return acc;
      },
      [],
    );
    if (!teksData && coursesWithTeksNumbers?.length) {
      getTeks(coursesWithTeksNumbers).then((data) =>
        dispatchLessonPlannerState({
          type: LessonPlannerActions.SET_TEKS_DATA,
          data: {
            all: data,
          },
        }),
      );

      if (isOwner) {
        getSuggestedTeks(coursesWithTeksNumbers, lessonData.classMetadata.classDate).then(
          (data) => {
            dispatchLessonPlannerState({
              type: LessonPlannerActions.SET_TEKS_DATA,
              data: {
                suggested: data,
              },
            });

            setSuggestedTeksToDisplay(
              data.filter(
                (suggestedTeks) =>
                  !lessonData.teksData?.tekses?.find(
                    (addedTeks) =>
                      addedTeks.display === suggestedTeks.display &&
                      addedTeks.text === suggestedTeks.text,
                  ),
              ),
            );
          },
        );
      }
    }
  }, [dispatchLessonPlannerState, getSuggestedTeks, getTeks, lessonData, teksData, isOwner]);

  useEffect(() => {
    if (!isOwner || lessonData?.status === PublicationStatuses.PUBLISHED) {
      setIsEditable(false);
    } else {
      setIsEditable(true);
    }
  }, [isOwner, lessonData?.status]);

  useEffect(() => {
    lessonBodyRef.current = {
      ...lessonBodyRef.current,
      classId: lessonData?.classMetadata.classId,
      classMetadata: lessonData?.classMetadata,
      materialsData: { ...materialsData },
      teksData: { ...newTeksData },
      title,
      reachTextEditorData: { ...richTextData },
      agendaBoardData: { ...agendaBoardData },
    };

    if (!lessonBodyRef.current.status && lessonData?.status) {
      lessonBodyRef.current.status = lessonData.status;
    }
  }, [
    agendaBoardData,
    isUnsavedChanges,
    lessonData,
    materialsData,
    newTeksData,
    richTextData,
    title,
  ]);

  useEffect(() => {
    if (isPublished) {
      if (
        appState.isDirty &&
        lessonBodyRef.current.title === lessonData?.title &&
        deepEqual(lessonBodyRef.current.reachTextEditorData, lessonData?.reachTextEditorData) &&
        deepEqual(lessonBodyRef.current.materialsData, lessonData?.materialsData, [
          'createdDate',
          'updatedDate',
        ]) &&
        deepEqual(lessonBodyRef.current.teksData, lessonData?.teksData) &&
        deepEqual(lessonBodyRef.current.agendaBoardData, lessonData?.agendaBoardData, [
          'updatedDate',
        ]) &&
        !hasUnPublishedAssignments(assignmentsRef.current)
      ) {
        dispatchAppState({ type: AppActions.SET_IS_DIRTY, data: false });
      } else if (
        !appState.isDirty &&
        (lessonBodyRef.current?.title !== lessonData?.title ||
          !deepEqual(lessonBodyRef.current.reachTextEditorData, lessonData?.reachTextEditorData) ||
          !deepEqual(lessonBodyRef.current.materialsData, lessonData?.materialsData, [
            'createdDate',
            'updatedDate',
          ]) ||
          !deepEqual(lessonBodyRef.current.teksData, lessonData?.teksData) ||
          !deepEqual(lessonBodyRef.current.agendaBoardData, lessonData?.agendaBoardData, [
            'updatedDate',
          ]))
      ) {
        dispatchAppState({ type: AppActions.SET_IS_DIRTY, data: true });
      }
    }
  }, [
    appState.isDirty,
    dispatchAppState,
    lessonData,
    title,
    richTextData,
    materialsData,
    newTeksData,
    agendaBoardData,
    isPublished,
  ]);

  useEffect(
    () => () => {
      if (timeoutRef.current) {
        clearAutoSave();

        if (shouldSaveOnUnmountRef.current) {
          saveLesson(
            lessonBodyRef.current,
            assignmentsRef.current,
            choicesRef.current,
            lessonIdRef.current,
            false,
            getSaveLessonSnackbarData(handleSave),
            (savedLesson) => {
              handleLeave(savedLesson, false);
            },
          );
        }
      }

      dispatchAppState({ type: AppActions.SET_IS_DIRTY, data: false });
      dispatchLessonPlannerState({
        type: LessonPlannerActions.SET_LESSON_DATA,
        data: null,
      });

      dispatchLessonPlannerState({
        type: LessonPlannerActions.SET_TEKS_DATA,
        data: null,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      getUnreadNotificationsByResourceAndId(
        NotificationSourcesMap.WEB_HIDDEN,
        NotificationResourcesMap.LESSON_SHARE_UPDATE,
        lessonIdNumber,
      )?.forEach((el) => {
        updateNotificationItems([{ ...el, markedAsRead: true }], NotificationSourcesMap.WEB_HIDDEN);
      });
      return;
    }
    if (unreadLessonUpdateNotifications?.length) {
      unreadLessonUpdateNotifications.forEach((item) => {
        updateNotificationItems(
          [{ ...item, markedAsRead: true }],
          NotificationSourcesMap.WEB_HIDDEN,
        );
        dispatchAppState({
          type: AppActions.SET_SNACKBAR_STATUS,
          data: {
            text: t(
              'The content has just been updated by the creator. Refresh the page to see the updated lesson.',
            ),
            type: 'info',
            action: (
              <Button
                autoFocus
                gaLabel="Refresh "
                onClick={() => {
                  setShouldRefreshLessonData(true);
                  dispatchAppState({ type: AppActions.SET_SNACKBAR_STATUS, data: null });
                }}
              >
                {t('Refresh')}
              </Button>
            ),
          },
        });
      });
    }
  }, [
    dispatchAppState,
    getUnreadNotificationsByResourceAndId,
    lessonIdNumber,
    t,
    unreadLessonUpdateNotifications,
    updateNotificationItems,
  ]);

  const onPublish = useCallback(() => {
    const errorsList = validateLesson();
    if (errorsList.length === 0) {
      assignmentsDeleteListRef.current.forEach((assignmentId) => {
        deleteLessonAssignment(lessonId, assignmentId);
      });
      assignmentsDeleteListRef.current = [];
      choicesDeleteListRef.current.forEach(({ assignmentId, choicesId, layerId }) => {
        if (layerId) {
          deleteAssignmentChoicesLayer(lessonId, assignmentId, choicesId, layerId);
        } else {
          deleteAssignmentChoices(lessonId, assignmentId, choicesId);
        }
      });
      choicesDeleteListRef.current = [];
      if (lessonErrors.length) {
        setLessonErrors([]);
      }
      handleSave({ isPublish: true, isEdit: isPublished });
    } else {
      setLessonErrors(errorsList);
      scrollToFirstError();
    }
  }, [
    deleteAssignmentChoices,
    deleteAssignmentChoicesLayer,
    deleteLessonAssignment,
    handleSave,
    isPublished,
    lessonErrors,
    lessonId,
    validateLesson,
  ]);

  const getFooter = useCallback(
    () => (
      <Box className="ayo-lesson-page__footer" py={5}>
        <Container>
          <Box alignItems="center" display="flex" justifyContent="space-between">
            <Box alignItems="center" columnGap={3} display="flex">
              <Button
                gaLabel={isPublished ? 'Cancel' : 'Save as draft'}
                onClick={
                  isPublished
                    ? handleCancelBtnClick
                    : () => {
                        handleSave();
                      }
                }
                variant="text"
              >
                {t(isPublished ? 'Cancel' : 'Save as draft')}
              </Button>
              <Button
                className="ayo-lesson-page__footer__primary-button"
                gaLabel={isPublished ? 'Save changes' : 'Publish'}
                onClick={onPublish}
                variant="primary"
              >
                {t(isPublished ? 'Save changes' : 'Publish')}
              </Button>
            </Box>
            <Button
              className="ayo-lesson-page__footer__delete-button"
              endIcon={<DeleteIcon />}
              gaLabel={isPublished ? 'Delete lesson' : 'Delete draft'}
              onClick={() => {
                if (lessonId || timeoutRef.current) {
                  setIsConfirmDeleteLessonDialogOpen(true);
                } else {
                  clearAutoSave();
                  history.replace(backToLink);
                }
              }}
            >
              {t(isPublished ? 'Delete lesson' : 'Delete draft')}
            </Button>
          </Box>
        </Container>
      </Box>
    ),
    [
      isPublished,
      handleCancelBtnClick,
      t,
      handleSave,
      lessonId,
      clearAutoSave,
      history,
      backToLink,
      onPublish,
    ],
  );

  const { students } = useStudentsData({
    activityCriterion: StudentActivityCriterions.ANY,
  });

  const period = lessonData?.classMetadata.classesPeriod.periodName;
  const courses = lessonData?.classMetadata.classesPeriod.courses.map(({ className }) => className);
  const currentSectionsStudents =
    students[period] &&
    getUniqueStudents(
      Object.entries(students[period]).reduce((acc, [currentClassname, currentStudents]) => {
        if (courses.includes(currentClassname)) {
          return [...acc, ...currentStudents];
        }
        return acc;
      }, []),
    );

  const addAssignments = useCallback(
    (addedAssignments) => {
      setAssignments((prevAssignments) => {
        const newAssignments = [...prevAssignments, ...addedAssignments];
        assignmentsRef.current = newAssignments;
        silentSave();

        if (isPublished) {
          dispatchAppState({ type: AppActions.SET_IS_DIRTY, data: true });
        }
        return newAssignments;
      });
    },
    [dispatchAppState, isPublished, silentSave],
  );

  const getAssignmentAdder = (assignmentType) => () => {
    addAssignments([createAssignment(assignmentType, currentSectionsStudents, assignments.length)]);
  };

  const onChoicesChange = (assignmentId) => (newChoices) => {
    if (assignmentId) {
      setChoices({ ...choices, [assignmentId]: newChoices });
    }
    setAutoSave();
  };

  const [assignmentsInsights, setAssignmentsInsights] = useState(null);

  useEffect(() => {
    if (lessonData && isOwner) {
      getLessonAssignmentInsights(
        userState.profile.currentCampus,
        lessonData.classMetadata.classesPeriod.courses.map(({ courseNumber }) => courseNumber),
        lessonData.classMetadata.classesPeriod.periodName,
        getNormalizedLanguageCode(i18n.language),
      ).then((assignmentsInsightsData) => setAssignmentsInsights(assignmentsInsightsData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getLessonAssignmentInsights, i18n.language, lessonData?.classMetadata]);

  const onInsightsAssignmentsAdd = useCallback(
    (insightsAssignments) => {
      const newAssignments = insightsAssignments
        .filter((insightsAssignment) => insightsAssignment.students.length)
        .map((insightsAssignment, idx) =>
          createAssignment(
            insightsAssignment.assignmentType,
            [...insightsAssignment.students, ...(insightsAssignment.movedStudents ?? [])],
            assignments.length + idx,
            {
              studentGroupCategory: insightsAssignment.categoryName,
              studentGroupNames: insightsAssignment.groupNames,
            },
          ),
        );
      addAssignments(newAssignments);
      dispatchAppState({
        type: AppActions.SET_SNACKBAR_STATUS,
        data: { text: t('Assignments from insights created snackbar'), type: 'success' },
      });
    },
    [addAssignments, assignments, dispatchAppState, t],
  );

  const hasTailoredAssignment = assignments?.some((assignment) => assignment.studentGroupCategory);

  const [activeContentSectionTab, setActiveContentSectionTab] = useState(
    ContentSectionTabs.PLANNING,
  );

  const onContentSectionTabsChange = useCallback(
    (_, newTab) => setActiveContentSectionTab(newTab),
    [],
  );

  const getHasTabContent = useCallback(
    (sections, ...rest) =>
      sections.some((section) => section?.isVisible) ||
      (rest.length ? rest.some((item) => !!item) : false),
    [],
  );

  return (
    <PageWrapper
      className="ayo-lesson-page"
      customFooter={isEditable && getFooter()}
      customHeader={
        <Header
          backToLink={backToLink}
          isEditable={isEditable}
          isOwner={isOwner}
          isPublished={isPublished}
          lessonData={lessonData}
          lessonId={lessonIdNumber}
          onEditClick={() => {
            setIsEditable(true);
            assignmentsCopyRef.current = assignments;
            choicesCopyRef.current = choices;
          }}
          redirectTo={redirectTo}
        />
      }
      mainElementClassName="ayo-lesson-page__main"
      noNav
    >
      {!!lessonData && (
        <Container>
          <Box position="relative" pt={5}>
            <Box maxWidth={isWidthUpMd ? '75%' : '100%'}>
              {lessonErrors.length > 0 && (
                <LessonPageErrorBox
                  errorsList={lessonErrors}
                  setActiveTab={setActiveContentSectionTab}
                />
              )}
              {isOwner && !isPublished && (
                <>
                  <Box className="ayo-lesson-page-snackbar-container">
                    <Snackbar
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                      autoHideDuration={5000}
                      onClose={() => setIsSnackbarOpen(false)}
                      open={isSnackbarOpen}
                      text={t('Your changes have just been auto-saved.')}
                      typographyVariant="caption"
                    />
                  </Box>
                  {!isInformationalMessageHidden && !!userState.oneTimeActions && (
                    <Box mb={5}>
                      <InformationalMessage
                        onClick={handleInformationalMessage}
                        text={
                          <Trans
                            components={{ b: <b />, br: <br /> }}
                            i18nKey="Primary details are obligatory for every lesson paragraph"
                          />
                        }
                      />
                    </Box>
                  )}
                </>
              )}
              <LessonPageSection
                isStatic={!isEditable}
                sectionConfig={{
                  content: [
                    {
                      body: (
                        <Box className="ayo-lesson-page__section__content" id="lesson-title">
                          <Box alignItems="center" display="flex" pb={3}>
                            <Typography variant="h2">{`${t('Title')}*`}</Typography>
                          </Box>
                          <STTTextField
                            error={!!titleError}
                            fullWidth
                            gaLabel="Title"
                            helperText={titleError}
                            label={t('Add the title of the lesson')}
                            onChange={handleTitleChange}
                            outlined
                            required
                            value={title}
                          />
                        </Box>
                      ),
                      isVisible: isEditable,
                    },
                    {
                      body: (
                        <LessonPageTeksContent
                          allTeks={teksData?.all}
                          chosenTeks={newTeksData.tekses}
                          error={teksError}
                          id="lesson-teks"
                          isStatic={!isEditable}
                          onChange={handleTeksChange}
                          suggestedTeks={suggestedTeksToDisplay}
                        />
                      ),
                      isVisible: !!(
                        teksData?.all?.length &&
                        (newTeksData.tekses.length || isEditable)
                      ),
                    },
                  ],
                }}
                title={LessonSections.PRIMARY_DETAILS}
                wrapperType="standard"
              />
              <Box mb={5}>
                <Tabs
                  className="content-section-tabs"
                  onChange={onContentSectionTabsChange}
                  value={activeContentSectionTab}
                  variant="outlined"
                >
                  <Tab
                    disableRipple
                    label={t('Planning & preparation')}
                    value={ContentSectionTabs.PLANNING}
                  />
                  <Tab
                    disableRipple
                    label={t('Shared with students')}
                    value={ContentSectionTabs.SHARED_WITH_STUDENTS}
                  />
                </Tabs>
              </Box>
            </Box>
            <Box
              maxWidth={
                isWidthUpMd &&
                getHasTabContent([
                  lessonBodyRef.current.reachTextEditorData,
                  lessonBodyRef.current.materialsData,
                ])
                  ? '75%'
                  : '100%'
              }
            >
              <TabPanel
                alwaysRender
                currentTabValue={activeContentSectionTab}
                value={ContentSectionTabs.PLANNING}
              >
                {!isEditable &&
                !getHasTabContent([
                  lessonBodyRef.current.reachTextEditorData,
                  lessonBodyRef.current.materialsData,
                ]) ? (
                  <LessonPageTabEmptyState isOwner={isOwner} />
                ) : (
                  <LessonPageSection
                    isStatic={!isEditable}
                    sectionConfig={{
                      content: [
                        {
                          body: (
                            <LessonPageRemovableBlock
                              key="textEditor"
                              blockContent={
                                <RichTextEditor
                                  attachments={richTextData.attachments}
                                  errorMsg={textError}
                                  isStatic={!isEditable}
                                  links={richTextData.links}
                                  onBeforeImageUpload={!lessonId ? silentSave : null}
                                  onChange={handleTextEditorChange}
                                  resourceId={lessonId}
                                  resourceType={AttachmentsResourcesTypes.LESSON_TEXT_EDITOR}
                                  value={richTextData.textJson || t('Lesson default text content')}
                                  withLinksExtraction
                                />
                              }
                              confirmDialogData={
                                LessonPageBlocksConfig.richTextEditor.confirmDialogData
                              }
                              id={LessonPageBlocksConfig.richTextEditor.key.id}
                              isDefaultContent={!richTextData.textJson}
                              isStatic={!isEditable}
                              isVisible={richTextData.isVisible}
                              onRemove={() =>
                                handleSectionRemove('richTextEditor', setRichTextData, richTextData)
                              }
                              title={t(LessonPageBlocksConfig.richTextEditor.key.text)}
                            />
                          ),
                          isRemovable: true,
                          isVisible: richTextData.isVisible,
                          onAdd: () => handleSectionAdd('richTextEditor', setRichTextData),
                          text: LessonPageBlocksConfig.richTextEditor.key.text,
                        },
                        {
                          body: (
                            <LessonPageRemovableBlock
                              key="materials"
                              blockContent={
                                <LessonPageMaterialsContent
                                  allowedExtensions={[
                                    'jpg',
                                    'jpeg',
                                    'png',
                                    'gif',
                                    'mp3',
                                    'mp4',
                                    'pdf',
                                  ]}
                                  isStatic={!isEditable}
                                  onBeforeUpload={!lessonId ? silentSave : null}
                                  onChange={handleMaterialsChange}
                                  onInProgressChange={(isInProgress) =>
                                    setIsMaterialsUploadInProgress(isInProgress)
                                  }
                                  resourceId={lessonId}
                                  values={materialsData}
                                />
                              }
                              confirmDialogData={LessonPageBlocksConfig.materials.confirmDialogData}
                              id={LessonPageBlocksConfig.materials.key.id}
                              isDefaultContent={
                                !materialsData.links.length &&
                                !materialsData.attachments.length &&
                                !isMaterialsUploadInProgress
                              }
                              isStatic={!isEditable}
                              isVisible={materialsData.isVisible}
                              onRemove={() =>
                                handleSectionRemove('materials', setMaterialsData, materialsData)
                              }
                              title={t(LessonPageBlocksConfig.materials.key.text)}
                            />
                          ),
                          isRemovable: true,
                          isVisible: !isEditable
                            ? !!materialsData.attachments.length || !!materialsData.links.length
                            : materialsData.isVisible,
                          onAdd: () => handleSectionAdd('materials', setMaterialsData),
                          text: LessonPageBlocksConfig.materials.key.text,
                        },
                      ],
                      onOpened: handleSectionOpenedRef.current,
                    }}
                    title={LessonSections.PLANNING_AND_PREPARATION}
                  />
                )}
              </TabPanel>
            </Box>
            <TabPanel
              alwaysRender
              currentTabValue={activeContentSectionTab}
              value={ContentSectionTabs.SHARED_WITH_STUDENTS}
            >
              {!isEditable &&
              !getHasTabContent([lessonBodyRef.current.agendaBoardData], assignments?.length) ? (
                <LessonPageTabEmptyState isOwner={isOwner} />
              ) : (
                <>
                  <LessonPageSection
                    isStatic={!isEditable}
                    sectionConfig={{
                      content: [
                        {
                          body: (
                            <LessonPageRemovableBlock
                              key="agenda"
                              blockContent={
                                <LessonPageAgendaBoard
                                  errors={agendaErrors}
                                  isStatic={!isEditable}
                                  lessonId={lessonId}
                                  metaData={{
                                    classDate: formattedDate,
                                    title,
                                    teksData: newTeksData?.tekses,
                                  }}
                                  onBeforeImageUpload={!lessonId ? silentSave : null}
                                  onChange={handleAgendaChange}
                                  teksData={newTeksData}
                                  values={agendaBoardData}
                                />
                              }
                              confirmDialogData={
                                LessonPageBlocksConfig.agendaBoard.confirmDialogData
                              }
                              id={LessonPageBlocksConfig.agendaBoard.key.id}
                              isDefaultContent={
                                !agendaBoardData.agenda?.textJson &&
                                !agendaBoardData.teks?.textJson &&
                                !agendaBoardData.reminders?.textJson
                              }
                              isStatic={!isEditable}
                              isVisible={agendaBoardData.isVisible}
                              onRemove={() =>
                                handleSectionRemove(
                                  'agendaBoard',
                                  setAgendaBoardData,
                                  agendaBoardData,
                                )
                              }
                              title={t(LessonPageBlocksConfig.agendaBoard.key.text)}
                            />
                          ),
                          isRemovable: true,
                          isVisible: agendaBoardData.isVisible,
                          onAdd: () => handleSectionAdd('agendaBoard', setAgendaBoardData),
                          text: LessonPageBlocksConfig.agendaBoard.key.text,
                        },
                      ],
                    }}
                    title={LessonPageBlocksConfig.agendaBoard.key.text}
                    wrapperType="standard"
                  />
                  <LessonPageSection
                    isStatic={!isEditable}
                    sectionConfig={{
                      content: [
                        {
                          body: (
                            <Box>
                              {!hasTailoredAssignment &&
                                assignmentsInsights &&
                                getInsightsWithoutUnassigned(assignmentsInsights).some(
                                  (insightCategory) =>
                                    insightCategory.groups.some((group) => group.studentIds.length),
                                ) &&
                                isEditable &&
                                currentSectionsStudents && (
                                  <Box mb={4}>
                                    <AssignmentInsights
                                      courseNumbers={lessonData.classMetadata.classesPeriod.courses.map(
                                        ({ courseNumber }) => courseNumber,
                                      )}
                                      courseStudents={currentSectionsStudents}
                                      insights={assignmentsInsights}
                                      onInsightsAssignmentsAdd={onInsightsAssignmentsAdd}
                                      period={lessonData.classMetadata.classesPeriod.periodName}
                                    />
                                  </Box>
                                )}
                              <Box>
                                {assignments?.map((assignment, index) => {
                                  const id = `assignment-${assignment.id || assignment.tempId}`;
                                  return (
                                    <Box key={id} id={id} mb={5}>
                                      <AssignmentRenderer
                                        assignment={assignment}
                                        choices={choices[assignment.id]}
                                        courseStudents={currentSectionsStudents}
                                        isFirst={!index}
                                        isLast={index === assignments.length - 1}
                                        isOwner={isOwner}
                                        isStatic={!isEditable}
                                        onChange={assignmentUpdater(index)}
                                        onChoicesChange={onChoicesChange(assignment.id)}
                                        onChoicesDelete={choicesDeleter(assignment.id)}
                                        onChoicesLayerDelete={choicesLayerDeleter(assignment.id)}
                                        onDelete={assignmentDeleter(index)}
                                        onReorder={assignmentReorderer(index)}
                                        validatorRef={validatorRef}
                                      />
                                    </Box>
                                  );
                                })}
                                {isEditable && currentSectionsStudents && (
                                  <Box alignItems="center" display="flex">
                                    <ActionsMenu
                                      activatorIcon={null}
                                      activatorVariant="secondary"
                                      ActivatorWrapper={({ children }) => (
                                        <DisabledControlWithTooltip
                                          showTooltip={assignments.length >= MAX_ASSIGNMENT_NUMBER}
                                          title={
                                            <Trans
                                              components={{ br: <br /> }}
                                              i18nKey="Max assignments error"
                                            />
                                          }
                                          wrapperClassName="fit-content-container"
                                        >
                                          {children}
                                        </DisabledControlWithTooltip>
                                      )}
                                      anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                      }}
                                      disabled={assignments.length >= MAX_ASSIGNMENT_NUMBER}
                                      gaLabel="Add a block"
                                      id="add-assignment"
                                      label={t('Add a block')}
                                      menuItems={[
                                        makeAssignmentAddAction(
                                          t('Assignment'),
                                          'Assignment description',
                                          getAssignmentAdder(AssignmentTypes.ASSIGNMENT),
                                        ),
                                        makeAssignmentAddAction(
                                          t('Short answer question'),
                                          'Short answer question description',
                                          getAssignmentAdder(AssignmentTypes.SHORT_ANSWER),
                                        ),
                                        makeAssignmentAddAction(
                                          t('Multiple choice question'),
                                          'Multiple choice question description',
                                          getAssignmentAdder(AssignmentTypes.MULTIPLE_CHOICE),
                                        ),
                                        makeAssignmentAddAction(
                                          t('Announcement'),
                                          'Announcement description',
                                          getAssignmentAdder(AssignmentTypes.ANNOUNCEMENT),
                                        ),
                                        makeAssignmentAddAction(
                                          t('Material'),
                                          'Material assignment description',
                                          getAssignmentAdder(AssignmentTypes.MATERIAL),
                                        ),
                                      ]}
                                      noIconsRecolor
                                      transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                      }}
                                    />
                                    <NewFeatureHotspot
                                      id={NewFeaturesIdsMap.LESSON_DESIGNER_NEW_ASSIGNMENT_TYPES}
                                      isClickable
                                      label={t('Assignment types')}
                                    />
                                  </Box>
                                )}
                              </Box>
                            </Box>
                          ),
                          isVisible:
                            isEditable || !!(assignments.length || assignmentsInsights?.length),
                          isRemovable: true,
                        },
                      ],
                      onOpen: handleAssignmentsSectionOpen,
                      onOpened: handleSectionOpenedRef.current,
                    }}
                    title={LessonSections.ASSIGNMENTS}
                  />
                </>
              )}
            </TabPanel>
          </Box>
          <ConfirmDialog
            cancelButtonTitle="Cancel"
            confirmButtonTitle={isPublished ? 'Delete lesson' : 'Delete draft'}
            isOpen={isConfirmDeleteLessonDialogOpen}
            onClose={() => setIsConfirmDeleteLessonDialogOpen(false)}
            onConfirm={handleDelete}
            text={
              isPublished
                ? 'The lesson won’t be restored and will be deleted forever.'
                : "You've already added some content to the draft."
            }
            title={
              isPublished
                ? 'Are you sure you want to delete the lesson?'
                : 'Are you sure you want to delete the draft?'
            }
          />
          {appState.isBackgroundSync ? (
            <LeaveDialog
              onPrimaryClick={handleCancelEditing}
              onSecondaryClick={() => {
                const { resolver } = appState.onLogout;
                if (resolver) {
                  resolver(false);
                }
              }}
              primaryButtonTitle="Leave the page"
              secondaryButtonTitle="Cancel"
              shouldResetNextLocationRef
              text="You have some files that are loading to the lesson (leave dialog)"
              title="Are you sure you want to leave?"
            />
          ) : (
            <LeaveDialog onPrimaryClick={onPublish} onSecondaryClick={handleCancelEditing} />
          )}
        </Container>
      )}
    </PageWrapper>
  );
};

LessonPageTeacher.propTypes = {
  Header: PropTypes.node.isRequired,
  redirectTo: PropTypes.string,
};

LessonPageTeacher.defaultProps = {
  redirectTo: null,
};

export default LessonPageTeacher;
