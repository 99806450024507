import React, { useCallback, useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, DialogActions, DialogContent } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { Dialog, DialogTitle, Typography } from '../../../../../atoms';
import {
  DialogErrorContent,
  InformationalCaption,
  PopupActionsButtons,
  STTTextField,
} from '../../../../../moleculas';
import { useProfileService, useReflectionsService } from '../../../../../../services';
import { focusDialogCloseButton, useEmotionalLearning } from '../../../../../../utils';
import {
  moodsRoute,
  recommendationsRoute,
  trainAyoRoute,
} from '../../../../../../constants/routes';
import {
  AnalyticTypesMap,
  InputsValidationRules,
  ReflectionTypes,
} from '../../../../../../constants/enums';
import {
  MoodsAndSupportIllustrations,
  MoodRecommendationsPageConfig,
} from '../../../../../../constants/moods';

const MoodDialogConfig = {
  NEGATIVE_MOOD: {
    FIRST_STAGE: {
      note: 'Nothing to add? Just click Save',
      primaryButtonText: 'Save',
      secondaryButtonText: 'Cancel',
      subtitle: 'Your mood has been submitted. Anything else to share with us',
    },
    SECOND_STAGE: {
      description: 'We prepared some questions to understand your feelings better',
      primaryButtonText: 'Go to questionnaire',
      secondaryButtonText: 'Close',
    },
  },
  POSITIVE_MOOD: {
    FIRST_STAGE: {
      note: 'Nothing to add? Just click Save',
      primaryButtonText: 'Save',
      secondaryButtonText: 'Cancel',
      subtitle: 'Your mood has been submitted. Anything else to share with us',
    },
    SECOND_STAGE: {
      description: 'Click Take a look to see some fun activities that will help you keep it up',
      primaryButtonText: 'Take a look',
      secondaryButtonText: 'Close',
    },
  },
};

const DialogActionButtons = ({
  actionsConfig,
  isFormSubmitted,
  onClose,
  onExploreMoreHandler,
  onSubmitHandler,
}) => {
  const { t } = useTranslation();
  return (
    <DialogActions>
      <Box mt={3} width="100%">
        <PopupActionsButtons
          primaryButtonGaLabel={actionsConfig.primaryButtonText}
          primaryButtonHandler={!isFormSubmitted ? onSubmitHandler : onExploreMoreHandler}
          primaryButtonText={t(actionsConfig.primaryButtonText)}
          secondaryButtonGaLabel={actionsConfig.secondaryButtonText}
          secondaryButtonHandler={onClose}
          secondaryButtonText={t(actionsConfig.secondaryButtonText)}
        />
      </Box>
    </DialogActions>
  );
};

DialogActionButtons.propTypes = {
  actionsConfig: PropTypes.shape({
    primaryButtonText: PropTypes.string,
    secondaryButtonText: PropTypes.string,
  }).isRequired,
  isFormSubmitted: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onExploreMoreHandler: PropTypes.func.isRequired,
  onSubmitHandler: PropTypes.func.isRequired,
};

const MoodDialog = ({ isOpen, moodDetails, onClose, onQuestionsDialogOpen }) => {
  const { t } = useTranslation();
  const { sendAyoAnalytic } = useProfileService();
  const history = useHistory();
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isFormErrored, setIsFormErrored] = useState(false);
  const [moodComment, setMoodComment] = useState('');
  const { id, mood } = moodDetails;

  const { getIsNegativeMoodSelected } = useEmotionalLearning();
  const { postReflection } = useReflectionsService();

  const isNegativeMoodSelected = useMemo(
    () => getIsNegativeMoodSelected(mood),
    [getIsNegativeMoodSelected, mood],
  );
  const config = useMemo(
    () =>
      isNegativeMoodSelected ? MoodDialogConfig.NEGATIVE_MOOD : MoodDialogConfig.POSITIVE_MOOD,
    [isNegativeMoodSelected],
  );

  const dialogFirstStageConfig = useMemo(() => config?.FIRST_STAGE, [config?.FIRST_STAGE]);
  const dialogSecondStageConfig = useMemo(() => config?.SECOND_STAGE, [config?.SECOND_STAGE]);

  const MoodIllustration = MoodsAndSupportIllustrations[mood]?.illustration;

  useEffect(() => {
    if (!isOpen) {
      setIsFormSubmitted(false);
      setIsFormErrored(false);
      setMoodComment('');
    }
  }, [isOpen]);

  const reflectionPostBody = useMemo(
    () => ({
      resourceId: id,
      resourceType: ReflectionTypes.MOOD,
      reflection: moodComment.trim(),
      visible: true,
    }),
    [id, moodComment],
  );

  const onSubmitHandler = useCallback(() => {
    if (!moodComment.trim()) {
      setIsFormSubmitted(true);
      focusDialogCloseButton();
    } else if (moodComment.length <= InputsValidationRules.MAX_COMMENT_LENGTH) {
      postReflection(reflectionPostBody, true, true)
        .then(() => {
          sendAyoAnalytic(AnalyticTypesMap.MOOD_COMMENT);
        })
        .catch(() => setIsFormErrored(true))
        .finally(() => {
          setIsFormSubmitted(true);
          focusDialogCloseButton();
        });
    }
  }, [moodComment, postReflection, reflectionPostBody, sendAyoAnalytic]);

  const onExploreMoreHandler = useCallback(() => {
    if (isNegativeMoodSelected) {
      onClose();
      onQuestionsDialogOpen();
    } else {
      onClose();
      history.push(`${trainAyoRoute}${moodsRoute}/${id}/${mood}${recommendationsRoute}`);
    }
  }, [history, id, isNegativeMoodSelected, mood, onClose, onQuestionsDialogOpen]);

  const onRetryHandler = useCallback(() => {
    postReflection(reflectionPostBody, true, true).then(() => setIsFormErrored(false));
  }, [postReflection, reflectionPostBody]);

  const DialogFirstStageContent = (
    <>
      <Typography component="h3" variant="subtitle2">
        {t(dialogFirstStageConfig.subtitle)}
      </Typography>
      <Box mt={2}>
        <STTTextField
          fullWidth
          gaLabel="Mood - comment"
          InputLabelProps={{ id: 'mood-comment' }}
          inputProps={{
            'aria-labelledby': 'mood-comment',
          }}
          label={t('Your comment')}
          maxLength={InputsValidationRules.MAX_COMMENT_LENGTH}
          multiline
          onChange={(e) => setMoodComment(e.target.value)}
          outlined
          rowsCount={4}
          value={moodComment}
        />
      </Box>
      <Box mb={3} mt={moodComment.length > InputsValidationRules.MAX_COMMENT_LENGTH ? 2 : 1}>
        <Typography variant="body3">{t(dialogFirstStageConfig.note)}</Typography>
      </Box>
      <InformationalCaption title="Your comment is visible for your educators in AYO" />
    </>
  );

  const DialogSecondStageContent = (
    <>
      <Box>
        <Typography component="h3" variant="subtitle2">
          {mood && t(MoodRecommendationsPageConfig[mood].title)}
        </Typography>
        <Box mt={2}>
          <Typography variant="body2">{t(dialogSecondStageConfig.description)}</Typography>
        </Box>
      </Box>
    </>
  );

  return (
    <Dialog
      className="ayo-mood-dialog"
      gaLabel="Mood support dialog"
      maxWidth={700}
      onClose={onClose}
      open={isOpen}
      scroll="paper"
    >
      {!isFormErrored ? (
        <>
          <DialogTitle disableTypography>
            <Typography component="h2" variant="subtitle1">
              {t('Mood check-in')}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Box display="flex" justifyContent="center" mb={3} mt={2}>
              <MoodIllustration
                aria-label={t(MoodsAndSupportIllustrations[mood]?.alt)}
                className="ayo-recommendations-page__mood-illustration"
              />
            </Box>
            {!isFormSubmitted ? DialogFirstStageContent : DialogSecondStageContent}
          </DialogContent>
          <DialogActionButtons
            actionsConfig={!isFormSubmitted ? dialogFirstStageConfig : dialogSecondStageConfig}
            isFormSubmitted={isFormSubmitted}
            onClose={onClose}
            onExploreMoreHandler={onExploreMoreHandler}
            onSubmitHandler={onSubmitHandler}
          />
        </>
      ) : (
        <DialogErrorContent
          gaLabel="Retry submit mood comment"
          isErrored={isFormErrored}
          isSubmitted={isFormSubmitted}
          onRetry={onRetryHandler}
          text={`${t('We couldn’t send your message')} ${t('Please try once more')}`}
        />
      )}
    </Dialog>
  );
};

MoodDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  moodDetails: PropTypes.shape({
    id: PropTypes.number,
    studentId: PropTypes.number,
    mood: PropTypes.string,
    createdDate: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onQuestionsDialogOpen: PropTypes.func.isRequired,
};

export default MoodDialog;
