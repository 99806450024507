import React from 'react';
import { Box, DialogActions, DialogContent, Fade, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Button, Typography } from '../../atoms';

const DialogSuccessContent = ({
  gaLabel,
  text,
  title,
  buttonClickHandler,
  buttonText,
  isFormSubmitted,
  successImage,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <>
      <Fade in={isFormSubmitted} timeout={1000}>
        <Box pb={3} pt={isWidthUpSm ? 3 : 0}>
          <DialogContent>
            {successImage}
            <Box pb={1} pt={3}>
              <Typography component="h2" variant="subtitle1">
                {t(title)}
              </Typography>
            </Box>
            <Typography variant="body2">{t(text)}</Typography>
          </DialogContent>
        </Box>
      </Fade>
      <Fade in={isFormSubmitted} timeout={1000}>
        <DialogActions>
          <Box width={isWidthUpSm ? '50%' : '100%'}>
            <Button fullWidth gaLabel={gaLabel} onClick={buttonClickHandler} variant="primary">
              {t(buttonText)}
            </Button>
          </Box>
        </DialogActions>
      </Fade>
    </>
  );
};

DialogSuccessContent.propTypes = {
  gaLabel: PropTypes.string.isRequired,
  text: PropTypes.func,
  title: PropTypes.string.isRequired,
  buttonClickHandler: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
  isFormSubmitted: PropTypes.bool,
  successImage: PropTypes.node.isRequired,
};

DialogSuccessContent.defaultProps = {
  isFormSubmitted: true,
  text: '',
};

export default DialogSuccessContent;
