import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

import { InformationalCaption } from '../../../moleculas';
import { AchievementsCategories, HighlightsInitiatives } from '../../../../constants/enums';
import HighlightsBarChart from '../components/highlights-bar-chart/HighlightsBarChart';

const defaultAchievementsStats = Object.values(AchievementsCategories).reduce(
  (acc, category) => ({ ...acc, [category]: 0 }),
  {},
);

const AchievementsHighlights = ({ stats, totalStudentsCount, onFiltersUpdate }) => (
  <Box display="flex" height={350}>
    <InformationalCaption
      isLight
      showTooltip
      title="Achievements Highlights info"
      tooltipPlacement="right"
    />
    <HighlightsBarChart
      defaultStats={defaultAchievementsStats}
      gaLabel="Achievements Highlights Graph"
      initiative={HighlightsInitiatives.ACHIEVEMENTS}
      mainChartColor="#2EC0C4"
      onFiltersUpdate={onFiltersUpdate}
      pendingChartColor="#CDFBFE"
      shouldSortMainStats
      stats={stats}
      totalStudentsCount={totalStudentsCount}
    />
  </Box>
);

AchievementsHighlights.propTypes = {
  stats: PropTypes.instanceOf(Object).isRequired,
  totalStudentsCount: PropTypes.number.isRequired,
  onFiltersUpdate: PropTypes.func.isRequired,
};

export default AchievementsHighlights;
