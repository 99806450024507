import { ReactComponent as CollaborationBadge } from '../resources/images/badges/collaboration.svg';
import { ReactComponent as CommunicationBadge } from '../resources/images/badges/communication.svg';
import { ReactComponent as CourageBadge } from '../resources/images/badges/courage.svg';
import { ReactComponent as DeterminationBadge } from '../resources/images/badges/determination.svg';
import { ReactComponent as DriveBadge } from '../resources/images/badges/drive.svg';
import { ReactComponent as EmpathyBadge } from '../resources/images/badges/empathy.svg';
import { ReactComponent as EncouragementBadge } from '../resources/images/badges/encouragement.svg';
import { ReactComponent as GoalOrientationBadge } from '../resources/images/badges/goal-orientation.svg';
import { ReactComponent as GrowthMindsetBadge } from '../resources/images/badges/growth-mindset.svg';
import { ReactComponent as IntegrityBadge } from '../resources/images/badges/integrity.svg';
import { ReactComponent as OpenMindednessBadge } from '../resources/images/badges/open-mindness.svg';
import { ReactComponent as PassionBadge } from '../resources/images/badges/passion.svg';
import { ReactComponent as ResourcefulnessBadge } from '../resources/images/badges/resoursfulness.svg';
import { ReactComponent as ServiceBadge } from '../resources/images/badges/service.svg';
import { ReactComponent as VisionBadge } from '../resources/images/badges/vision.svg';
import { ReactComponent as FocusModeBadge } from '../resources/images/badges/faithful-focuser.svg';
import { ReactComponent as MasterfulMoodCheckerBadge } from '../resources/images/badges/masterful-mood-checker.svg';
import { ReactComponent as CaptivatingCommenterBadge } from '../resources/images/badges/captivating-commenter.svg';
import { ReactComponent as EmotionalExplorerBadge } from '../resources/images/badges/emotional-explorer.svg';
import { ReactComponent as PersistentPaithfinderBadge } from '../resources/images/badges/persistent-paithfinder.svg';
import { ReactComponent as DigitalDiscovererBadge } from '../resources/images/badges/digital-discoverer.svg';
import { ReactComponent as EagerExplorerBadge } from '../resources/images/badges/eager-explorer.svg';

export const BADGES_STATIC_DATA = [
  {
    nameKey: 'collaboration',
    type: 'LA_COLLABORATION',
    image: CollaborationBadge,
    achivementMessage: 'To win this badge, ask your teachers to assess your Leadership Attributes',
    alt: 'Two hands solving a puzzle',
    definition: 'Collaboration-definition',
    defaultLevels: [
      {
        levelKey: 'leadingSelf',
        completionDate: null,
        details: [
          'i-am-willing-to-listen-to-others-and-value-their-input-in-group-settings',
          'i-actively-participate-and-contribute-ideas-in-group-settings',
        ],
      },
      {
        levelKey: 'leadingOthers',
        completionDate: null,
        details: [
          'i-work-with-others-to-create-collective-solutions-or-products-related-to-common-goals',
          'i-seek-and-accept-input-from-global-sources-to-advance-the-creation-of-collective-solutions-and-problems',
        ],
      },
      {
        levelKey: 'leadingChange',
        completionDate: null,
        details: [
          'i-leverage-the-strengths-and-ideas-of-myself-and-others-when-partnering-across-global-platforms-to-create-collective-solutions-or-products',
        ],
      },
    ],
  },
  {
    nameKey: 'communication',
    type: 'LA_COMMUNICATION',
    image: CommunicationBadge,
    achivementMessage: 'To win this badge, ask your teachers to assess your Leadership Attributes',
    alt: 'Two chat bubbles',
    definition: 'Communication-definition',
    defaultLevels: [
      {
        levelKey: 'leadingSelf',
        completionDate: null,
        details: [
          'i-share-accurate-and-relevant-information-and-ideas',
          'i-take-an-active-role-in-discussions-using-agreed-upon-norms',
          'i-give-examples-and-evidence-to-support-my-thinking',
          'i-ask-questions-to-better-understand',
          'i-patiently-and-actively-listen-to-gain-knowledge',
          'i-take-turns-when-expressing-ideas',
          'i-make-eye-contact-and-speak-clearly',
        ],
      },
      {
        levelKey: 'leadingOthers',
        completionDate: null,
        details: [
          'i-ask-questions-to-understand-the-viewpoints-of-others',
          'i-build-on-ideas-shared-by-others',
          'i-encourage-everyone-to-contribute-their-ideas',
          'i-listen-in-order-to-integrate-others-ideas',
          'i-use-eye-contact-and-body-language-to-encourage-others-to-engage-in-conversation',
        ],
      },
      {
        levelKey: 'leadingChange',
        completionDate: null,
        details: [
          'i-use-my-voice-to-contribute-to-my-community',
          'i-lead-conversations-to-look-for-new-ideas-and-solutions',
          'i-ask-questions-and-reconsider-my-thinking',
          'i-listen-in-order-to-integrate-others',
          'i-constructively-exchange-ideas-with-a-variety-of-audiences',
        ],
      },
    ],
  },
  {
    nameKey: 'courage',
    type: 'LA_COURAGE',
    image: CourageBadge,
    achivementMessage: 'To win this badge, ask your teachers to assess your Leadership Attributes',
    alt: 'A person who is ready to jump in the water',
    definition: 'Courage-definition',
    defaultLevels: [
      {
        levelKey: 'leadingSelf',
        completionDate: null,
        details: [
          'i-seek-out-opportunities-to-try-new-things-in-order-to-reach-my-goal',
          'i-take-risks-in-the-face-of-challenges',
          'i-use-experiences-as-opportunities-to-grow-my-confidence',
        ],
      },
      {
        levelKey: 'leadingOthers',
        completionDate: null,
        details: [
          'i-encourage-others-to-seek-out-positive-influences',
          'i-coach-others-to-take-risks-in-the-face-of-challenges',
          'i-handle-conflict-in-a-positive-manner-which-will-allow-for-progress',
          'i-seek-feedback-and-respond-accordingly',
        ],
      },
      {
        levelKey: 'leadingChange',
        completionDate: null,
        details: [
          'i-advocate-for-myself-and-others',
          'i-stand-up-for-my-beliefs',
          'i-have-the-confidence-to-move-forward-even-when-the-outcome-is-uncertain',
        ],
      },
    ],
  },
  {
    nameKey: 'determination',
    type: 'LA_DETERMINATION',
    image: DeterminationBadge,
    achivementMessage: 'To win this badge, ask your teachers to assess your Leadership Attributes',
    alt: 'A hand reaching for the stars',
    definition: 'Determination-definition',
    defaultLevels: [
      {
        levelKey: 'leadingSelf',
        completionDate: null,
        details: [
          'i-dedicate-the-time-and-effort-needed-to-achieve-a-goal',
          'i-review-my-goals-regularly-to-determine-progress',
          'i-maintain-a-clear-focus-on-my-goal',
          'i-continue-working-even-when-challenges-arise',
        ],
      },
      {
        levelKey: 'leadingOthers',
        completionDate: null,
        details: [
          'i-work-with-others-to-achieve-a-goal',
          'i-coach-others-to-work-through-challenges-to-achieve-goals',
          'i-use-feedback-as-an-opportunity-to-help-others-work-towards-a-goal',
        ],
      },
      {
        levelKey: 'leadingChange',
        completionDate: null,
        details: [
          'i-use-adversity-as-an-opportunity-to-help-create-lasting-change',
          'i-persist-in-order-to-lead-change',
        ],
      },
    ],
  },
  {
    nameKey: 'drive',
    type: 'LA_DRIVE',
    image: DriveBadge,
    achivementMessage: 'To win this badge, ask your teachers to assess your Leadership Attributes',
    alt: 'A wall hammer',
    definition: 'Drive-definition',
    defaultLevels: [
      {
        levelKey: 'leadingSelf',
        completionDate: null,
        details: [
          'i-use-my-curiosity-passions-and-aptitudes-to-create-goals',
          'i-can-describe-the-end-picture-of-a-goal',
          'i-have-a-strong-desire-and-determination-to-succeed',
          'i-am-able-to-create-a-detailed-plan-for-goal-achievement-and-stick-to-it',
        ],
      },
      {
        levelKey: 'leadingOthers',
        completionDate: null,
        details: [
          'i-can-share-my-curiosity-passions-and-aptitudes-with-others-to-develop-a-common-goal',
          'i-can-motivate-others-to-share-join-in-the-pursuit-of-a-goal',
          'i-can-delegate-tasks-to-accomplish-a-common-goal',
          'i-can-monitor-and-monitor-progress-and-make-adjustments-towards-completion-of-a-common-goal',
        ],
      },
      {
        levelKey: 'leadingChange',
        completionDate: null,
        details: [
          'i-share-how-a-common-goal-leads-to-a-positive-change',
          'i-inspire-others-to-share-a-common-purpose-and-vision',
        ],
      },
    ],
  },
  {
    nameKey: 'empathy',
    type: 'LA_EMPATHY',
    image: EmpathyBadge,
    achivementMessage: 'To win this badge, ask your teachers to assess your Leadership Attributes',
    alt: 'Two chairs next to each other',
    definition: 'Empathy-definition',
    defaultLevels: [
      {
        levelKey: 'leadingSelf',
        completionDate: null,
        details: [
          'i-cultivate-curiosity-about-others',
          'i-seek-opportunities-to-learn-the-stories-of-others',
          'i-challenge-my-own-biases',
          'i-discover-commonalities-that-i-have-with-others',
        ],
      },
      {
        levelKey: 'leadingOthers',
        completionDate: null,
        details: [
          'i-encourage-the-people-around-me-to-cultivate-curiosity-about-others',
          'i-encourage-those-around-me-to-share-their-stories',
          'i-model-listening-to-understand',
          'i-encourage-others-to-challenge-their-own-biases',
          'i-celebrate-differences-and-commonalities-that-i-have-with-others',
        ],
      },
      {
        levelKey: 'leadingChange',
        completionDate: null,
        details: [
          'i-advocate-for-others-based-on-their-stories',
          'i-collaborate-with-others-from-multiple-perspective-to-affect-change',
        ],
      },
    ],
  },
  {
    nameKey: 'encouragement',
    type: 'LA_ENCOURAGEMENT',
    image: EncouragementBadge,
    achivementMessage: 'To win this badge, ask your teachers to assess your Leadership Attributes',
    alt: 'A giant sports fan’s foam hand',
    definition: 'Encouragement-definition',
    defaultLevels: [
      {
        levelKey: 'leadingSelf',
        completionDate: null,
        details: [
          'i-reflect-on-how-my-words-and-actions-affect-others',
          'i-motivate-myself-by-believing-in-my-abilities',
          'i-model-a-positive-attitude',
        ],
      },
      {
        levelKey: 'leadingOthers',
        completionDate: null,
        details: [
          'i-notice-others-efforts-and-praise-them',
          'i-ask-others-about-their-work-and-look-for-opportunities-to-encourage-them',
          'i-ask-my-peers-about-how-they-feel-about-their-own-success',
          'i-motivate-my-peers-by-showing-belief-and-confidence-in-their-abilities',
          'i-help-others-build-on-their-existing-interests-and-skills',
          'i-provide-informative-helpful-feedback-to-others',
        ],
      },
      {
        levelKey: 'leadingChange',
        completionDate: null,
        details: [
          'i-support-and-facilitate-growth-through-failure-and-success-in-a-variety-of-audiences',
          'i-reflect-on-how-my-words-and-actions-can-bring-about-change',
        ],
      },
    ],
  },
  {
    nameKey: 'goal-orientation',
    type: 'LA_GOAL_ORIENTATION',
    image: GoalOrientationBadge,
    achivementMessage: 'To win this badge, ask your teachers to assess your Leadership Attributes',
    alt: 'A runner’s shoe and a timer',
    definition: 'Goal-orientation-definition',
    defaultLevels: [
      {
        levelKey: 'leadingSelf',
        completionDate: null,
        details: [
          'i-have-a-vision-of-my-goal-and-write-it-down',
          'i-create-a-plan-and-follow-it-to-achieve-my-goals',
          'i-develop-habits-that-allow-me-to-reach-my-goals',
          'i-have-high-expectations-for-myself',
          'i-focus-on-my-tasks-and-track-my-progress',
          'i-reflect-on-my-plan-and-make-necessary-adjustments',
          'i-accept-feedback-as-an-opportunity-to-improve-my-goal',
        ],
      },
      {
        levelKey: 'leadingOthers',
        completionDate: null,
        details: [
          'i-help-others-develop-follow-and-reflect-on-their-plans-with-an-end-goal-in-mind',
          'i-encourage-others-as-they-are-working-toward-their-goals',
          'i-celebrate-others-when-they-accomplish-their-goals',
          'i-help-others-develop-habits-that-allow-them-to-reach-their-goals',
          'i-encourage-others-to-seek-feedback-as-an-opportunity-to-improve-a-goal',
        ],
      },
      {
        levelKey: 'leadingChange',
        completionDate: null,
        details: [
          'i-influence-others-to-collaborate-as-we-work-towards-common-goals',
          'goal-orientation-i-build-a-sense-of-shared-purpose-among-others',
          'i-look-for-opportunities-to-align-goals-to-bigger-causes',
        ],
      },
    ],
  },
  {
    nameKey: 'growth-mindset',
    type: 'LA_GROWTH_MINDSET',
    image: GrowthMindsetBadge,
    achivementMessage: 'To win this badge, ask your teachers to assess your Leadership Attributes',
    alt: 'A rope bridge',
    definition: 'Growth-mindset-definition',
    defaultLevels: [
      {
        levelKey: 'leadingSelf',
        completionDate: null,
        details: [
          'i-embrace-the-challenge-of-taking-risks',
          'i-use-my-challenges-and-failures-as-part-of-the-learning-process',
          'i-learn-through-adversity',
          'i-understand-that-there-are-no-limits-to-achievement',
        ],
      },
      {
        levelKey: 'leadingOthers',
        completionDate: null,
        details: [
          'i-encourage-others-to-seek-out-new-challenges-and-ideas',
          'i-help-others-succeed-in-their-pursuit-of-growth',
          'i-encourage-others-to-embrace-challenges-and-failures-as-an-opportunity-to-persevere-and-grow',
        ],
      },
      {
        levelKey: 'leadingChange',
        completionDate: null,
        details: [
          'challenges-and-failures-drive-me-to-look-for-possibilities-for-change-within-the-community',
          'i-initiate-and-foster-an-environment-in-which-others-value-the-growth-made-by-persevering-through-challenges-and-failures',
        ],
      },
    ],
  },
  {
    nameKey: 'integrity',
    type: 'LA_INTEGRITY',
    image: IntegrityBadge,
    achivementMessage: 'To win this badge, ask your teachers to assess your Leadership Attributes',
    alt: 'A lifebelt floating in the water',
    definition: 'Integrity-definition',
    defaultLevels: [
      {
        levelKey: 'leadingSelf',
        completionDate: null,
        details: [
          'i-do-the-right-thing-even-when-no-one-is-watching',
          'i-accept-responsibility-for-my-words-and-actions',
          'i-do-not-share-private-information-about-others',
          'i-am-honest-with-myself-and-others',
        ],
      },
      {
        levelKey: 'leadingOthers',
        completionDate: null,
        details: [
          'i-encourage-others-to-do-the-right-thing-even-when-no-one-is-watching',
          'i-encourage-others-to-take-responsibility-of-their-words-and-actions',
          'i-discourage-others-from-sharing-private-information-about-others',
          'i-help-create-spaces-where-people-can-be-open-and-honest-in-sharing-ideas',
        ],
      },
      {
        levelKey: 'leadingChange',
        completionDate: null,
        details: [
          'i-tell-the-truth-and-align-my-actions-to-improve-my-community',
          'i-lead-change-through-honest-communication-and-the-exchange-of-ideas-with-others',
          'i-promote-change-in-my-community-by-leading-by-example',
          'i-build-a-community-that-values-trust',
        ],
      },
    ],
  },
  {
    nameKey: 'open-mindedness',
    type: 'LA_OPEN_MINDEDNESS',
    image: OpenMindednessBadge,
    achivementMessage: 'To win this badge, ask your teachers to assess your Leadership Attributes',
    alt: 'A bright light bulb',
    definition: 'Open-mindedness-definition',
    defaultLevels: [
      {
        levelKey: 'leadingSelf',
        completionDate: null,
        details: [
          'i-try-new-things',
          'i-listen-accept-and-reflect-on-feedback',
          'i-see-things-from-multiple-perspectives',
          'i-ask-questions-in-order-to-experience-new-ideas',
        ],
      },
      {
        levelKey: 'leadingOthers',
        completionDate: null,
        details: [
          'i-create-an-environment-that-encourages-others-to-identify-their-interest-passions-or-aptitudes',
          'i-support-others-as-they-explore-and-pursue-their-interest',
          'i-encourage-others-to-try-new-things',
          'i-encourage-others-to-see-things-from-multiple-perspectives',
        ],
      },
      {
        levelKey: 'leadingChange',
        completionDate: null,
        details: [
          'i-put-new-ideas-into-action',
          'i-bring-others-together-from-diverse-perspectives-in-order-to-impact-change',
        ],
      },
    ],
  },
  {
    nameKey: 'passion',
    type: 'LA_PASSION',
    image: PassionBadge,
    achivementMessage: 'To win this badge, ask your teachers to assess your Leadership Attributes',
    alt: 'A painting and a first place medal',
    definition: 'Passion-definition',
    defaultLevels: [
      {
        levelKey: 'leadingSelf',
        completionDate: null,
        details: [
          'i-am-enthusiastic-about-my-interests',
          'i-take-the-time-and-effort-to-explore-my-interests',
          'i-identify-and-connect-to-things-that-interest-me',
        ],
      },
      {
        levelKey: 'leadingOthers',
        completionDate: null,
        details: [
          'i-encourages-others-to-share-their-interest',
          'i-support-others-as-they-explore-and-act-on-their-interests',
        ],
      },
      {
        levelKey: 'leadingChange',
        completionDate: null,
        details: [
          'i-foster-an-environment-that-connects-people-with-diverse-passions-in-order-to-support-change',
          'i-use-my-interests-and-aptitudes-for-a-greater-cause-within-a-community',
        ],
      },
    ],
  },
  {
    nameKey: 'resourcefulness',
    type: 'LA_RESOURCEFULNESS',
    image: ResourcefulnessBadge,
    achivementMessage: 'To win this badge, ask your teachers to assess your Leadership Attributes',
    alt: 'A collage of a lady made from vegetables',
    definition: 'Resourcefulness-definition',
    defaultLevels: [
      {
        levelKey: 'leadingSelf',
        completionDate: null,
        details: [
          'i-take-initiative',
          'i-seek-out-resources-to-solve-problems',
          'i-am-flexible',
          'i-take-risks-and-think-creatively',
          'i-am-persistent',
        ],
      },
      {
        levelKey: 'leadingOthers',
        completionDate: null,
        details: [
          'i-encourage-others-to-take-initiative',
          'i-urge-others-to-seek-out-help',
          'i-support-creative-thinking-and-risk-taking',
          'i-encourage-others-to-be-adaptable-in-situations',
        ],
      },
      {
        levelKey: 'leadingChange',
        completionDate: null,
        details: [
          'i-celebrate-resourcefulness-in-others',
          'i-create-opportunities-for-others-to-find-effective-ways-to-approach-a-challenge',
          'i-encourage-creative-thinking',
        ],
      },
    ],
  },
  {
    nameKey: 'service',
    type: 'LA_SERVICE',
    image: ServiceBadge,
    achivementMessage: 'To win this badge, ask your teachers to assess your Leadership Attributes',
    alt: 'A hand passing a binoculars to other hand',
    definition: 'service-definition',
    defaultLevels: [
      {
        levelKey: 'leadingSelf',
        completionDate: null,
        details: [
          'leading-self-i-anticipate-others-needs-and-actively-support-them',
          'leading-self-i-encourage-and-support-others-in-the-pursuit-of-their-goals',
          'leading-self-i-lead-others-to-find-compassionate-solutions-to-those-in-need',
          'i-care-about-the-well-being-and-success-of-others',
        ],
      },
      {
        levelKey: 'leadingOthers',
        completionDate: null,
        details: [
          'leading-others-i-anticipate-others-needs-and-actively-support-them',
          'leading-others-i-encourage-and-support-others-in-the-pursuit-of-their-goals',
          'leading-others-i-lead-others-to-find-compassionate-solutions-to-those-in-need',
        ],
      },
      {
        levelKey: 'leadingChange',
        completionDate: null,
        details: [
          'i-connect-with-others-in-order-to-help-the-members-of-the-community-create-positive-change',
          'i-actively-find-opportunities-for-my-community-to-seek-solutions-to-a-need',
          'i-take-action-for-the-greater-good-of-my-community-or-the-world',
        ],
      },
    ],
  },
  {
    nameKey: 'vision',
    type: 'LA_VISION',
    image: VisionBadge,
    achivementMessage: 'To win this badge, ask your teachers to assess your Leadership Attributes',
    alt: 'Binoculars looking faraway',
    definition: 'Vision-definition',
    defaultLevels: [
      {
        levelKey: 'leadingSelf',
        completionDate: null,
        details: [
          'i-can-develop-a-purpose-based-on-my-curiosity-passions-and-aptitudes',
          'i-plan-with-the-end-in-mind',
          'i-understand-the-steps-to-fulfill-my-purpose',
          'i-share-my-vision-with-others',
        ],
      },
      {
        levelKey: 'leadingOthers',
        completionDate: null,
        details: [
          'i-help-others-develop-a-plan-to-fulfill-a-purpose',
          'i-recognize-and-encourage-the-value-each-person-can-contribute-toward-a-vision',
          'i-anticipate-challenges-and-provide-support-throughout-the-implementation-of-the-vision-through-reflection',
          'i-celebrate-incremental-successes-and-actions-that-align-with-and-contribute-to-the-overall-fulfillment-of-the-vision',
        ],
      },
      {
        levelKey: 'leadingChange',
        completionDate: null,
        details: [
          'vision-i-build-a-sense-of-shared-purpose-among-others',
          'i-look-for-opportunities-to-align-my-vision-to-a-bigger-cause',
        ],
      },
    ],
  },
  {
    nameKey: 'login-first',
    type: 'USER_ACTION_LOGIN_FIRST',
    image: DigitalDiscovererBadge,
    achivementMessage: 'To win this badge, download AYO App',
    alt: 'Welcome message with confetti',
    definition: 'digital-discoverer-definition',
    defaultLevels: [
      {
        levelKey: 'once',
        completionDate: null,
        details: [],
      },
    ],
  },
  {
    nameKey: 'login',
    type: 'USER_ACTION_LOGIN',
    image: EagerExplorerBadge,
    achivementMessage: 'To win this badge, open AYO Mobile App on your phone and start exploring',
    alt: 'A boy hiking a mountain',
    definition: 'eager-explorer-definition',
    defaultLevels: [
      {
        levelKey: 'oneWeek',
        completionDate: null,
        details: ['number days visiting AYO Mobile!/7'],
      },
      {
        levelKey: 'twoWeeks',
        completionDate: null,
        details: ['number days visiting AYO Mobile!/14'],
      },
      {
        levelKey: 'oneMonth',
        completionDate: null,
        details: ['number days visiting AYO Mobile!/30'],
      },
      {
        levelKey: 'threeMonths',
        completionDate: null,
        details: ['number days visiting AYO Mobile!/90'],
      },
      {
        levelKey: 'year',
        completionDate: null,
        details: ['number days visiting AYO Mobile!/365'],
      },
    ],
  },
  {
    nameKey: 'focus-mode',
    type: 'USER_ACTION_FOCUS',
    image: FocusModeBadge,
    achivementMessage: 'To win this badge, open AYO Mobile App on your phone and start exploring',
    alt: 'A pomodoro timer in the form of a tomato',
    definition: 'faithful-focuser-definition',
    defaultLevels: [
      {
        levelKey: 'once',
        completionDate: null,
        details: ['I’ve completed 1 focus round with the Focus Timer!'],
      },
      {
        levelKey: 'tenTimes',
        completionDate: null,
        details: ['I’ve completed number focus rounds with the Focus Timer!/10'],
      },
      {
        levelKey: 'twentyFiveTimes',
        completionDate: null,
        details: ['I’ve completed number focus rounds with the Focus Timer!/25'],
      },
      {
        levelKey: 'fiftyTimes',
        completionDate: null,
        details: ['I’ve completed number focus rounds with the Focus Timer!/50'],
      },
      {
        levelKey: 'twoHundredAndFiftyTimes',
        completionDate: null,
        details: ['I’ve completed number focus rounds with the Focus Timer!/250'],
      },
    ],
  },
  {
    nameKey: 'mood',
    type: 'USER_ACTION_MOOD',
    image: MasterfulMoodCheckerBadge,
    achivementMessage:
      'It’s never too late to start! Go to Train AYO and explore the Mood Check-in and the mood regulation exercises now!',
    alt: 'A smiley emoji, a sad emoji, and two angry faces',
    definition: 'masterful-mood-checker-definition',
    defaultLevels: [
      {
        levelKey: 'once',
        completionDate: null,
        details: ['I’ve shared my mood 1 time!'],
      },
      {
        levelKey: 'tenTimes',
        completionDate: null,
        details: ['I’ve shared my mood number times!/10'],
      },
      {
        levelKey: 'fiftyTimes',
        completionDate: null,
        details: ['I’ve shared my mood number times!/50'],
      },
      {
        levelKey: 'oneHundredTimes',
        completionDate: null,
        details: ['I’ve shared my mood number times!/100'],
      },
      {
        levelKey: 'fiveHundredTimes',
        completionDate: null,
        details: ['I’ve shared my mood number times!/500'],
      },
    ],
  },
  {
    nameKey: 'mood-comment',
    type: 'USER_ACTION_MOOD_COMMENT',
    image: CaptivatingCommenterBadge,
    achivementMessage:
      'It’s never too late to start! Go to Train AYO and explore the Mood Check-in and the mood regulation exercises now!',
    alt: 'A chat bubble',
    definition: 'captivating-commenter-definition',
    defaultLevels: [
      {
        levelKey: 'once',
        completionDate: null,
        details: ['I’ve commented on my mood 1 time!'],
      },
      {
        levelKey: 'tenTimes',
        completionDate: null,
        details: ['I’ve commented on my mood number times!/10'],
      },
      {
        levelKey: 'fiftyTimes',
        completionDate: null,
        details: ['I’ve commented on my mood number times!/50'],
      },
      {
        levelKey: 'oneHundredTimes',
        completionDate: null,
        details: ['I’ve commented on my mood number times!/100'],
      },
      {
        levelKey: 'twoHundredTimes',
        completionDate: null,
        details: ['I’ve commented on my mood number times!/200'],
      },
    ],
  },
  {
    nameKey: 'sel-recommendation',
    type: 'USER_ACTION_SEL_RECOMMENDATION',
    image: EmotionalExplorerBadge,
    achivementMessage:
      'It’s never too late to start! Go to Train AYO and explore the Mood Check-in and the mood regulation exercises now!',
    alt: 'A smiled human face with sun over his head',
    definition: 'Emotional-explorer-definition',
    defaultLevels: [
      {
        levelKey: 'once',
        completionDate: null,
        details: ['I’ve applied the SEL Recommendations 1 time!'],
      },
      {
        levelKey: 'fiveTimes',
        completionDate: null,
        details: ['I’ve applied the SEL Recommendations number times!/5'],
      },
      {
        levelKey: 'tenTimes',
        completionDate: null,
        details: ['I’ve applied the SEL Recommendations number times!/10'],
      },
      {
        levelKey: 'twentyFiveTimes',
        completionDate: null,
        details: ['I’ve applied the SEL Recommendations number times!/25'],
      },
      {
        levelKey: 'fiftyTimes',
        completionDate: null,
        details: ['I’ve applied the SEL Recommendations number times!/50'],
      },
    ],
  },
  {
    nameKey: 'endorsement',
    type: 'USER_ACTION_ENDORSEMENT',
    image: PersistentPaithfinderBadge,
    achivementMessage: 'To win this badge, open AYO Mobile App on your phone and start exploring',
    alt: 'A compass',
    definition: 'Persistent-Pathfinder-definition',
    defaultLevels: [
      {
        levelKey: 'once',
        completionDate: null,
        details: [],
      },
    ],
  },
];

export const BadgesLevelNamesMap = {
  leadingSelf: 'Leading self',
  leadingOthers: 'Leading others',
  leadingChange: 'Leading change',
  once_USER_ACTION_FOCUS: '1 focus round completed!',
  tenTimes_USER_ACTION_FOCUS: 'number focus round completed!/10',
  twentyFiveTimes_USER_ACTION_FOCUS: 'number focus round completed!/25',
  fiftyTimes_USER_ACTION_FOCUS: 'number focus round completed!/50',
  twoHundredAndFiftyTimes_USER_ACTION_FOCUS: 'number focus round completed!/250',
  oneWeek_USER_ACTION_LOGIN: 'I visited AYO Mobile number days!/7',
  twoWeeks_USER_ACTION_LOGIN: 'I visited AYO Mobile number days!/14',
  oneMonth_USER_ACTION_LOGIN: 'I visited AYO Mobile number days!/30',
  threeMonths_USER_ACTION_LOGIN: 'I visited AYO Mobile number days!/90',
  year_USER_ACTION_LOGIN: 'I visited AYO Mobile number days!/365',
  once_USER_ACTION_MOOD: '1 Mood Check-in!',
  tenTimes_USER_ACTION_MOOD: 'number Mood Check-ins!/10',
  fiftyTimes_USER_ACTION_MOOD: 'number Mood Check-ins!/50',
  oneHundredTimes_USER_ACTION_MOOD: 'number Mood Check-ins!/100',
  fiveHundredTimes_USER_ACTION_MOOD: 'number Mood Check-ins!/500',
  once_USER_ACTION_MOOD_COMMENT: '+ 1 comment!',
  tenTimes_USER_ACTION_MOOD_COMMENT: '+ number comments!/10',
  fiftyTimes_USER_ACTION_MOOD_COMMENT: '+ number comments!/50',
  oneHundredTimes_USER_ACTION_MOOD_COMMENT: '+ number comments!/100',
  twoHundredTimes_USER_ACTION_MOOD_COMMENT: '+ number comments!/200',
  once_USER_ACTION_SEL_RECOMMENDATION: '+ 1 read! Keep on reading!',
  fiveTimes_USER_ACTION_SEL_RECOMMENDATION: '+ number reads! Keep on reading!/5',
  tenTimes_USER_ACTION_SEL_RECOMMENDATION: '+ number reads! Keep on reading!/10',
  twentyFiveTimes_USER_ACTION_SEL_RECOMMENDATION: '+ number reads! Keep on reading!/25',
  fiftyTimes_USER_ACTION_SEL_RECOMMENDATION: '+ number reads! Keep on reading!/50',
};

export const BadgesStatusMap = {
  achieved: 'achieved',
  partiallyAchived: 'partiallyAchived',
  notAchived: 'notAchived',
};

export default {
  BADGES_STATIC_DATA,
  BadgesLevelNamesMap,
  BadgesStatusMap,
};
