import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

import {
  PageIllustrationBlock,
  ScreenCenteredContainer,
  LinkWithTooltip,
} from '../../../moleculas';
import { PageWrapper } from '../../../organisms';
import {
  QuestionnaireStateActions,
  QuestionnaireStateContext,
  UserActions,
  UserContext,
} from '../../../../context';
import { OneTimeActionsMap } from '../../../../constants/enums';
import { QuestionnaireProgressMap } from '../../../../constants/questionnaire';
import { trainAyoQuestionnaireRoute, trainAyoRoute } from '../../../../constants/routes';
import { useOneTimeActionService } from '../../../../services';
import {
  getQuestionnaireProgress,
  hasPendingQuestionnaire,
  isSubmittedWithSkippedQuestions,
  useProfile,
} from '../../../../utils';

import KidsQuestionnaireBlock from './components/kids-questionnaire-block/KidsQuestionnaireBlock';

const COMPLETED_VALUE = 100;

const TrainAyoSelectPage = ({
  dependentItems,
  title,
  subtitle,
  Illustration,
  illustrationAriaLabel,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const { dispatch: dispatchUserState } = useContext(UserContext);
  const { state: questionnaireState, dispatch: dispatchQuestionnaireState } =
    useContext(QuestionnaireStateContext);

  const [dependentQuestionnaires, setDependentQuestionnaires] = useState({});

  const { getOneTimeActionStatus, getProfileState } = useProfile();
  const { postOneTimeAction } = useOneTimeActionService();

  const onDependentItemClick = useCallback(
    (profile) => {
      dispatchQuestionnaireState({
        type: QuestionnaireStateActions.SET_QUESTIONNAIRE_ID,
        data: {
          questionnaireId:
            profile.pendingQuestionnaires[0]?.id || profile.completedQuestionnaires[0].id,
        },
      });
      dispatchQuestionnaireState({
        type: QuestionnaireStateActions.SET_ACTIVE_DEPENDENT_PROFILE,
        data: { profile },
      });
      history.push(
        `${trainAyoQuestionnaireRoute}?redirectTo=${encodeURIComponent(location.pathname)}`,
      );
    },
    [dispatchQuestionnaireState, history, location.pathname],
  );

  useEffect(() => {
    const processDependentQuestionnaires = () => {
      let questionnairesMap = {};

      dependentItems.forEach((dependentItem) => {
        if (hasPendingQuestionnaire(dependentItem)) {
          const questionnaireProgress = dependentItem.pendingQuestionnaires.length
            ? getQuestionnaireProgress(dependentItem.pendingQuestionnaires)
            : COMPLETED_VALUE;
          const isQuestionnaireSubmitted = isSubmittedWithSkippedQuestions(
            dependentItem.pendingQuestionnaires,
          );
          questionnairesMap = {
            ...questionnairesMap,
            [dependentItem.id]: {
              progress: questionnaireProgress,
              order: isQuestionnaireSubmitted
                ? QuestionnaireProgressMap.HAS_SKIPPED
                : questionnaireProgress > 0
                ? QuestionnaireProgressMap.IN_PROGRESS
                : QuestionnaireProgressMap.START_TRAINING,
            },
          };
        } else {
          questionnairesMap = {
            ...questionnairesMap,
            [dependentItem.id]: {
              progress: 100,
              order: QuestionnaireProgressMap.COMPLETED,
            },
          };
        }
      });
      setDependentQuestionnaires(questionnairesMap);
    };
    if (dependentItems?.length) {
      if (questionnaireState.shouldReloadQuestionnaireState) {
        getProfileState().then(() => {
          dispatchQuestionnaireState({
            type: QuestionnaireStateActions.SET_SHOULD_RELOAD_QUESTIONNAIRE_STATE,
            data: false,
          });
        });
      } else {
        processDependentQuestionnaires();
      }
    }
    if (questionnaireState.activeQuestionIds) {
      dispatchQuestionnaireState({
        type: QuestionnaireStateActions.SET_ACTIVE_QUESTION_IDS,
        data: null,
      });
    }
    if (!getOneTimeActionStatus(OneTimeActionsMap.TRAIN_AYO_START)) {
      postOneTimeAction(OneTimeActionsMap.TRAIN_AYO_START);
      dispatchUserState({
        type: UserActions.SET_ONE_TIME_ACTION,
        data: OneTimeActionsMap.TRAIN_AYO_START,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionnaireState.shouldReloadQuestionnaireState]);

  return (
    <PageWrapper
      backToLink={trainAyoRoute}
      backToText={t('Go to Train AYO')}
      mainElementClassName="ayo-select-page"
    >
      <ScreenCenteredContainer>
        <PageIllustrationBlock
          Illustration={Illustration}
          illustrationAriaLabel={illustrationAriaLabel}
          mainHeaderText={title}
          secondaryHeaderText={[subtitle]}
        />
        <KidsQuestionnaireBlock
          dependentProfiles={dependentItems}
          dependentQuestionnaires={dependentQuestionnaires}
          onClick={onDependentItemClick}
        />
        <Box pt={4}>
          <LinkWithTooltip />
        </Box>
      </ScreenCenteredContainer>
    </PageWrapper>
  );
};

TrainAyoSelectPage.propTypes = {
  dependentItems: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  Illustration: PropTypes.node.isRequired,
  illustrationAriaLabel: PropTypes.string.isRequired,
};

export default TrainAyoSelectPage;
