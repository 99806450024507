import { getValueByKey } from '../object-helpers/objectHelpers';

export const shuffle = (array) => {
  const shuffledArray = [].concat(array);
  for (let i = shuffledArray.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
};

// Returns 0 if arrays have different items, -1 if they have same items in different order, 1 - same items in same order
export const compareItems = (array1, array2) => {
  if (array1.length !== array2.length) return 0;
  let isOrderChanged = false;
  for (let i = 0; i < array1.length; i += 1) {
    const foundIndex = array2.findIndex((item) => item === array1[i]);
    if (foundIndex === -1) {
      return 0;
    }
    if (foundIndex !== i) {
      isOrderChanged = true;
    }
  }
  return isOrderChanged ? -1 : 1;
};

export const mergeArrayOfObjectsHelper = (arrayInner, arrayOuter, mergeKey) => {
  const map = new Map();
  arrayInner.forEach((item) => map.set(item[mergeKey], item));
  arrayOuter.forEach((item) => map.set(item[mergeKey], { ...map.get(item[mergeKey]), ...item }));
  const mergedArr = Array.from(map.values());
  return mergedArr;
};

export const findCompletedLevels = (data) =>
  data.some((attribute) =>
    Object.values(attribute?.maturityLevels).some(
      (maturityLevel) => maturityLevel.completedGroups > 0,
    ),
  );

export const sortArrayByKey = (array, key, type, order = 'decrease') => {
  if (type === 'date') {
    return order === 'decrease'
      ? array.sort((a, b) => new Date(getValueByKey(b, key)) - new Date(getValueByKey(a, key)))
      : array.sort((a, b) => new Date(getValueByKey(a, key)) - new Date(getValueByKey(b, key)));
  }
  return order === 'decrease'
    ? array.sort((a, b) => getValueByKey(b, key) - getValueByKey(a, key))
    : array.sort((a, b) => getValueByKey(a, key) - getValueByKey(b, key));
};

export const getTruncatedArrayString = (arr, maxDisplayItems) => {
  const displayedPart = arr.slice(0, maxDisplayItems).join(', ');
  const truncatedPart = arr.length > maxDisplayItems ? ` +${arr.length - maxDisplayItems}` : '';
  return displayedPart + truncatedPart;
};

export const getGroupedArrayByKey = (arr, key) => {
  const groupedArray = arr?.reduce(
    (acc, current) => ({
      ...acc,
      [current[key]]: [...(acc[current[key]] || []), current],
    }),
    {},
  );
  return groupedArray;
};

export const filterUniqueValuesByKey = (arr, key) =>
  arr.filter((value, index, self) => index === self.findIndex((item) => item[key] === value[key]));

export const getUniqueObjectsByKey = (array, key) => {
  const uniqueKeys = new Set();
  return array.filter((item) => {
    const isInArray = uniqueKeys.has(item[key]);
    uniqueKeys.add(item[key]);
    return !isInArray;
  });
};
