import React from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

import { Link } from '../../../../../atoms';
import { ReactComponent as LinkIcon } from '../../../../../../resources/icons/link.svg';
import { DisabledControlWithTooltip } from '../../../../../moleculas';

const GoogleClassroomLink = ({ href }) => {
  const { t } = useTranslation();

  return (
    <DisabledControlWithTooltip
      showTooltip={!href}
      title={<Trans components={{ br: <br /> }} i18nKey="GC Link publishing in progress" />}
      wrapperClassName="fit-content-container"
    >
      <Link
        disabled={!href}
        gaLabel="Google classroom link"
        href={href}
        icon={<LinkIcon />}
        target="_blank"
        underline="none"
      >
        {t('Open in Google Classroom')}
      </Link>
    </DisabledControlWithTooltip>
  );
};

GoogleClassroomLink.propTypes = {
  href: PropTypes.string.isRequired,
};

export default GoogleClassroomLink;
