import React, { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, IconButton } from '@mui/material';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import { Button, DayOffStudent, Holiday, Typography } from '../../../atoms';
import { CalendarDay, WeekPicker } from '../../../moleculas';
import { useLessonPlannerData, useLessonPlannerStudentData } from '../../../../hooks';
import {
  getActiveWeekBoundaryDays,
  getFormattedWeekRange,
  getShortDate,
  isWeekend,
  useCalendar,
} from '../../../../utils';
import { ReactComponent as ChevronLeft } from '../../../../resources/icons/chevron_left.svg';
import { ReactComponent as ChevronRight } from '../../../../resources/icons/chevron_right.svg';
import { ReactComponent as DatePickerIcon } from '../../../../resources/icons/date_picker.svg';
import { RolesMap } from '../../../../constants/enums';
import { UserContext } from '../../../../context';
import { calendarViewMainProps } from '../../../../constants/propTypes';

const WeekView = ({
  currentDate,
  setCurrentDate,
  scheduleId,
  onDateSelect,
  currentPickerDates,
}) => {
  const { i18n } = useTranslation();

  const { state: userState } = useContext(UserContext);
  const isStudent = useMemo(
    () => userState.profile?.role === RolesMap.STUDENT,
    [userState.profile?.role],
  );

  const weekStart = currentDate.day(1);
  const weekEnd = currentDate.day(5);

  const [weekHeadingStart, weekHeadingEnd] = getActiveWeekBoundaryDays(
    currentDate,
    currentPickerDates.firstDayOfSchedule,
    currentPickerDates.lastDayOfSchedule,
  );

  const plannerDataHook = isStudent ? useLessonPlannerStudentData : useLessonPlannerData;
  const { loadDatesData, getDateRangeData } = plannerDataHook();

  useEffect(() => {
    loadDatesData(
      ...[!isStudent && scheduleId, getShortDate(weekStart), getShortDate(weekEnd)].filter(Boolean),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDate, scheduleId]);

  const nextStart = weekStart.add(1, 'week');
  const prevEnd = weekEnd.subtract(1, 'week');

  const weekData = getDateRangeData(
    ...[!isStudent && scheduleId, weekStart, weekEnd].filter(Boolean),
  );

  useEffect(() => {
    if (
      currentPickerDates.firstDayOfSchedule &&
      currentPickerDates.lastDayOfSchedule &&
      !currentDate.isBetween(
        currentPickerDates.firstDayOfSchedule,
        currentPickerDates.lastDayOfSchedule,
        'week',
        '[]',
      )
    ) {
      setCurrentDate(dayjs(currentPickerDates.firstDayOfSchedule));
    }
  }, [
    currentDate,
    currentPickerDates.firstDayOfSchedule,
    currentPickerDates.lastDayOfSchedule,
    setCurrentDate,
  ]);

  const { t } = useTranslation();

  const { isCalendarOpen, onCalendarClick } = useCalendar();

  const isTodayInSchedule = dayjs().isBetween(
    currentPickerDates.firstDayOfSchedule,
    currentPickerDates.lastDayOfSchedule,
    'week',
    '[]',
  );

  const isCurrentDateInTodaysWeek = dayjs().isSame(currentDate, 'week');

  return (
    <Grid container>
      <Grid
        alignItems="center"
        container
        item
        justifyContent="center"
        mb={5}
        position="relative"
        xs={12}
      >
        <Box alignItems="center" display="flex" justifyContent="center">
          <Button
            aria-label={t('Previous week')}
            gaLabel="Previous week"
            isHidden={prevEnd.isBefore(currentPickerDates.firstDayOfSchedule, 'day')}
            isIconButton
            onClick={() => {
              setCurrentDate(currentDate.subtract(1, 'week').day(1));
            }}
          >
            <ChevronLeft />
          </Button>
          <WeekPicker
            disabledDayText={t('You can’t select this day.')}
            gaLabel="Lesson planner week view"
            isOpen={isCalendarOpen}
            label="Date"
            maxDate={currentPickerDates.lastDayOfSchedule}
            minDate={currentPickerDates.firstDayOfSchedule}
            onChange={(date) => setCurrentDate(dayjs(date))}
            outlined
            renderInput={(props) => (
              <IconButton
                // eslint-disable-next-line react/prop-types
                ref={props.inputRef}
                // eslint-disable-next-line react/prop-types, react/jsx-props-no-spreading
                {...props.InputProps.endAdornment.props.children.props}
                aria-label={`Choose date, current date is ${currentDate.format('MMM DD, YYYY')}`}
                className="ayo-icon-button"
                disableRipple
              >
                <DatePickerIcon />
              </IconButton>
            )}
            setIsOpen={onCalendarClick}
            shouldDisabledDate={isWeekend}
            showDaysOutsideCurrentMonth
            value={currentDate}
          />
          <Typography
            className="ayo-schedule-page__calendar__selected-date"
            component="h2"
            onClick={onCalendarClick}
            variant="subtitle1"
          >
            {getFormattedWeekRange(
              weekHeadingStart.toDate(),
              weekHeadingEnd.toDate(),
              i18n.language,
            )}
          </Typography>
          <Button
            aria-label={t('Next week')}
            gaLabel="Next week"
            isHidden={nextStart.isAfter(currentPickerDates.lastDayOfSchedule, 'day')}
            isIconButton
            onClick={() => {
              setCurrentDate(currentDate.add(1, 'week').day(1));
            }}
          >
            <ChevronRight />
          </Button>
        </Box>
        {isTodayInSchedule && !isCurrentDateInTodaysWeek && (
          <Box position="absolute" right="0">
            <Button
              gaLabel="Show current week"
              onClick={() => setCurrentDate(dayjs().day(1))}
              variant="secondary"
            >
              {t('Show current week')}
            </Button>
          </Box>
        )}
      </Grid>
      <Box display="flex" gap={3} width="100%">
        {Object.entries(weekData).map(([date, day], idx) => (
          <Box key={date} flexBasis="20%">
            {dayjs(date).isBetween(
              currentPickerDates.firstDayOfSchedule,
              currentPickerDates.lastDayOfSchedule,
              'day',
              '[]',
            ) ? (
              <CalendarDay
                date={dayjs(date)}
                day={day}
                nullDataComponent={isStudent ? <DayOffStudent /> : <Holiday />}
                onDateClick={!isStudent ? (clickedDate) => onDateSelect(clickedDate) : null}
                scheduleId={scheduleId}
                withDivider={!!idx}
              />
            ) : (
              <Box />
            )}
          </Box>
        ))}
      </Box>
    </Grid>
  );
};

WeekView.propTypes = {
  ...calendarViewMainProps,
  onDateSelect: PropTypes.func,
};

WeekView.defaultProps = {
  onDateSelect: null,
};

export default WeekView;
