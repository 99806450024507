import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { MenuItem, useControlled } from '@mui/material';
import classNames from 'classnames';

import { TextField } from '../../atoms';
import { GA } from '../../../utils';
import { GaActions, GaCategories } from '../../../constants/enums';
import { ReactComponent as ExpandIcon } from '../../../resources/icons/chevron_down.svg';
import { ReactComponent as CollapseIcon } from '../../../resources/icons/chevron_up.svg';
import TextWithTooltip from '../tooltip-wrappers/text-with-tooltip/TextWithTooltip';

const DROPDOWN_MENU_MARGIN = 60;

const Dropdown = ({
  className,
  defaultValue,
  disabled,
  fullWidth,
  gaLabel,
  handleChange,
  isResponsive,
  label,
  name,
  options,
  outlined,
  value,
  helperText,
  error,
  required,
}) => {
  const [open] = useState(false);
  const [dropdownMaxHeight, setDropdownMaxHeight] = useState(null);

  const [currentValue, setCurrentValue] = useControlled({
    controlled: value,
    default: defaultValue || options[0].value,
    name: 'Dropdown',
    state: 'value',
  });
  const dropdownIcon = !open ? ExpandIcon : CollapseIcon;

  const inputId = `dropdown-${label.split(' ').join('-')}`;

  const optionChange = (e) => {
    setCurrentValue(e.target.value);
    if (gaLabel) {
      GA.logInteraction({
        category: GaCategories.BEHAVIOR,
        action: GaActions.DROPDOWN_CHANGE,
        label: gaLabel,
      });
    }
    setTimeout(() => {
      handleChange(e);
    }, 0);
  };

  const onDropdownOpen = useCallback(
    (e) => {
      if (isResponsive) {
        setDropdownMaxHeight(
          window.innerHeight - e.target?.getBoundingClientRect()?.bottom - DROPDOWN_MENU_MARGIN ||
            null,
        );
      }
      if (gaLabel) {
        GA.logInteraction({
          category: GaCategories.BEHAVIOR,
          action: GaActions.DROPDOWN_OPEN,
          label: gaLabel,
        });
      }
    },
    [isResponsive, gaLabel],
  );

  return (
    <TextField
      className="ayo-dropdown"
      disabled={disabled}
      error={error}
      fullWidth={fullWidth}
      helperText={helperText}
      label={label}
      name={name}
      onChange={optionChange}
      open={open}
      outlined={outlined}
      required={required}
      select
      SelectProps={{
        IconComponent: dropdownIcon,
        id: inputId,
        inputProps: {
          'aria-label':
            (currentValue && options.filter((x) => x.value === currentValue)[0]?.label) || label,
        },
        onOpen: onDropdownOpen,
        MenuProps: {
          PopoverClasses: {
            root: classNames('ayo-dropdown__popover', className, {
              'ayo-dropdown__popover--responsive': isResponsive,
            }),
          },
          MenuListProps: {
            style: {
              maxHeight: dropdownMaxHeight,
            },
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'bottom',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        },
      }}
      value={currentValue}
    >
      {options.map((option) => (
        <MenuItem
          key={option.value}
          className="ayo-dropdown__menu-item"
          disableRipple
          value={option.value}
        >
          <TextWithTooltip title={option.label} titleVariant="body2" />
        </MenuItem>
      ))}
    </TextField>
  );
};

Dropdown.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  gaLabel: PropTypes.string,
  handleChange: PropTypes.func,
  isResponsive: PropTypes.bool,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  outlined: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string, label: PropTypes.string }))
    .isRequired,
  value: PropTypes.string,
};

Dropdown.defaultProps = {
  className: null,
  defaultValue: '',
  required: false,
  disabled: false,
  fullWidth: false,
  gaLabel: '',
  handleChange: () => {},
  isResponsive: false,
  error: false,
  label: '',
  helperText: '',
  name: '',
  outlined: false,
  value: undefined,
};

export default Dropdown;
