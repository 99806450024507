/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { DesktopDateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

import { RadioGroup, TextField, Typography } from '../../../../../../atoms';
import { STTTextField } from '../../../../../../moleculas';
import { eventType } from '../../../../../../../constants/propTypes';
import { loginAllowedFilterRE } from '../../../../../../../constants/regexps';
import {
  GaActions,
  GaCategories,
  InputsValidationRules,
} from '../../../../../../../constants/enums';
import { EventKeys, EventsLocationTypes } from '../../../../../../../constants/events';
import { GA, getDuration } from '../../../../../../../utils';
import { ReactComponent as DatePickerIcon } from '../../../../../../../resources/icons/date_picker.svg';

const EventDatePicker = ({ disabled, errorMessage, gaLabel, label, onAccept, onChange, value }) => {
  const isCurrentDate = dayjs(value).isSame(dayjs(), 'day');
  const minTime = isCurrentDate ? dayjs() : undefined;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDateTimePicker
        disabled={disabled}
        disablePast
        minTime={minTime}
        onAccept={(newValue) => onAccept(newValue)}
        onChange={(newValue) => {
          onChange(newValue);
          GA.logInteraction({
            category: GaCategories.BEHAVIOR,
            action: GaActions.DATE_PIKER_DATE_CLICK,
            label: gaLabel,
          });
        }}
        PaperProps={{
          className: classNames('ayo-date-picker__calendar'),
        }}
        PopperProps={{
          className: classNames('ayo-events-block__dialog__date-picker'),
        }}
        renderInput={(props) => (
          <TextField
            {...props}
            error={!!errorMessage}
            fullWidth
            helperText={errorMessage}
            InputProps={{
              autoComplete: 'off',
              endAdornment: (
                <IconButton
                  // eslint-disable-next-line react/prop-types
                  {...props.InputProps.endAdornment?.props.children.props}
                  className="ayo-icon-button"
                  disableRipple
                >
                  <DatePickerIcon />
                </IconButton>
              ),
            }}
            label={label}
            onBlur={(e) => onAccept(e.target.value)}
            outlined
            required
          />
        )}
        value={value}
        views={['day', 'hours', 'minutes']}
      />
    </LocalizationProvider>
  );
};

EventDatePicker.propTypes = {
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string.isRequired,
  gaLabel: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onAccept: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

EventDatePicker.defaultProps = {
  disabled: false,
};

const EventForm = ({
  dateValidation,
  errors,
  event,
  isEventInProgress,
  onChange,
  visibilityButton,
}) => {
  const { t } = useTranslation();

  const showDuration = useMemo(
    () =>
      event[EventKeys.START_DATE] &&
      event[EventKeys.END_DATE] &&
      !errors[EventKeys.START_DATE] &&
      !errors[EventKeys.END_DATE],
    [errors, event],
  );

  return (
    <Box my={3}>
      <Typography component="h3" mb={2} variant="subtitle2">
        {`${t('Event name')}*`}
      </Typography>
      <STTTextField
        error={errors[EventKeys.NAME]}
        fullWidth
        gaLabel="Event name"
        helperText={t(errors[EventKeys.NAME])}
        inputRE={loginAllowedFilterRE}
        label={t('Add name')}
        maxLength={InputsValidationRules.MAX_INPUT_LENGTH}
        name={EventKeys.NAME}
        onChange={(e) => onChange(EventKeys.NAME, e.target.value)}
        outlined
        required
        value={event[EventKeys.NAME]}
      />
      <Typography component="h3" mb={2} mt={3} variant="subtitle2">
        {`${t('Location')}*`}
      </Typography>
      <RadioGroup
        onChange={(e) => {
          onChange(EventKeys.LOCATION_TYPE, e.target.value);
          GA.logInteraction({
            category: GaCategories.BEHAVIOR,
            action: GaActions.RADIOBUTTON_CLICK,
            label: e.target.value,
          });
        }}
        options={[
          { text: t('Offline'), value: EventsLocationTypes.OFFLINE },
          { text: t('Online'), value: EventsLocationTypes.ONLINE },
        ]}
        row
        value={event[EventKeys.LOCATION_TYPE]}
      />
      <Box mb={3} mt={2}>
        <TextField
          error={errors[EventKeys.LOCATION_PLACEMENT]}
          fullWidth
          gaLabel="Event location placement"
          helperText={t(errors[EventKeys.LOCATION_PLACEMENT])}
          inputRE={loginAllowedFilterRE}
          label={t('Add a link or the name of the place')}
          maxLength={InputsValidationRules.MAX_INPUT_LENGTH}
          name={[EventKeys.LOCATION_PLACEMENT]}
          onChange={(e) => onChange(EventKeys.LOCATION_PLACEMENT, e.target.value)}
          outlined
          required
          value={event[EventKeys.LOCATION_PLACEMENT]}
        />
      </Box>
      <Typography component="h3" mb={2} variant="subtitle2">
        {`${t('Start date and time')}*`}
      </Typography>
      <EventDatePicker
        disabled={isEventInProgress}
        errorMessage={t(errors[EventKeys.START_DATE])}
        gaLabel="Start date and time"
        label={t('mm/dd/yyyy, hh:mm AM/PM')}
        onAccept={(value) => {
          dateValidation(EventKeys.START_DATE, value);
        }}
        onChange={(value) => onChange(EventKeys.START_DATE, value)}
        value={event[EventKeys.START_DATE]}
      />
      <Typography
        component="h3"
        mb={2}
        mt={errors[EventKeys.START_DATE] ? 4 : 3}
        variant="subtitle2"
      >
        {`${t('End date and time')}*`}
      </Typography>
      <EventDatePicker
        errorMessage={t(errors[EventKeys.END_DATE])}
        gaLabel="End date and time"
        label={t('mm/dd/yyyy, hh:mm AM/PM')}
        onAccept={(value) => {
          dateValidation(EventKeys.END_DATE, value);
        }}
        onChange={(value) => onChange(EventKeys.END_DATE, value)}
        value={event[EventKeys.END_DATE]}
      />
      {showDuration && (
        <Typography isLabel mt={3} variant="body2">{`${t('Duration')}: (${getDuration(
          event[EventKeys.START_DATE],
          event[EventKeys.END_DATE],
          t,
        )})`}</Typography>
      )}
      <Typography component="h3" mb={2} mt={errors[EventKeys.END_DATE] ? 4 : 3} variant="subtitle2">
        {t('Description')}
      </Typography>
      <STTTextField
        error={t(errors[EventKeys.DESCRIPTION])}
        fullWidth
        gaLabel="Event description"
        helperText={errors[EventKeys.DESCRIPTION]}
        inputRE={loginAllowedFilterRE}
        label={t('Add a description')}
        maxLength={InputsValidationRules.MAX_INPUT_LENGTH}
        multiline
        name={EventKeys.DESCRIPTION}
        onChange={(e) => onChange(EventKeys.DESCRIPTION, e.target.value)}
        outlined
        rowsCount={4}
        value={event[EventKeys.DESCRIPTION]}
      />
      {visibilityButton}
    </Box>
  );
};

EventForm.propTypes = {
  dateValidation: PropTypes.func.isRequired,
  errors: PropTypes.instanceOf(Object).isRequired,
  event: eventType.isRequired,
  isEventInProgress: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  visibilityButton: PropTypes.node,
};

EventForm.defaultProps = {
  isEventInProgress: false,
  visibilityButton: null,
};

export default EventForm;
