import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { Typography } from '../../../../../atoms';
import { ReactComponent as LinkIcon } from '../../../../../../resources/icons/link.svg';
import { myStudentsRoute } from '../../../../../../constants/routes';
import { ClickableCard } from '../../../../../moleculas';
import UnavailableStudentsMessage from '../../unavailable-students-message/UnavailableStudentsMessage';

const DisplayAssignmentStudents = ({ students, isOwner, assignedCount }) => {
  const { t } = useTranslation();

  const sortedStudents = useMemo(
    () => students?.sort((a, b) => a.name.localeCompare(b.name)),
    [students],
  );

  return (
    <Box>
      <Typography paragraph variant="subtitle2">
        {t('Assigned to (number)', { number: assignedCount })}
      </Typography>
      {students && isOwner ? (
        <Box className="ayo-lesson-assignment-display-students">
          {sortedStudents.map((student) => (
            <Box key={student.id} pb={1} pr={1}>
              <ClickableCard
                className="ayo-lesson-assignment-display-students__card"
                gaLabel="Assigned student"
                href={`${myStudentsRoute}/${student.id}`}
                isNewTab
                statusIcon={<LinkIcon />}
                title={student.name}
              />
            </Box>
          ))}
        </Box>
      ) : (
        <UnavailableStudentsMessage />
      )}
    </Box>
  );
};

DisplayAssignmentStudents.propTypes = {
  students: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  isOwner: PropTypes.bool,
  assignedCount: PropTypes.number,
};

DisplayAssignmentStudents.defaultProps = {
  students: null,
  isOwner: false,
  assignedCount: 1,
};

export default DisplayAssignmentStudents;
