import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';

import { Question, ScreenCenteredContainer } from '../../moleculas';
import { RolesMap } from '../../../constants/enums';

const RoleQuestion = ({ onProceed }) => {
  const { t } = useTranslation();

  const questionConfig = useMemo(
    () => ({
      htmlLabel: t('What is your role?'),
      htmlLabelCaption: t('required'),
      htmlDescription: t('Great to see you here!'),
      options: [
        {
          id: 1,
          htmlType: 'RADIO',
          htmlName: 'role',
          htmlLabel: t('Teacher / assistant principal / principal / counselor'),
          htmlValue: RolesMap.TEACHER,
        },
        {
          id: 2,
          htmlType: 'RADIO',
          htmlName: 'role',
          htmlLabel: t('Other staff member'),
          htmlValue: RolesMap.OTHER,
        },
      ],
      mandatory: true,
      visible: true,
    }),
    [t],
  );

  const [roleAnswer, setRoleAnswer] = useState({});

  const onNext = useCallback(() => {
    onProceed(roleAnswer.role.value);
  }, [onProceed, roleAnswer]);

  return (
    <ScreenCenteredContainer>
      <Grid container item justifyContent="center">
        <Grid item md={4} xs={12}>
          <Question
            answers={roleAnswer}
            onNext={onNext}
            question={questionConfig}
            setAnswer={(e) => {
              setRoleAnswer({
                [e.target.name]: {
                  value: e.target.value,
                },
              });
            }}
          />
        </Grid>
      </Grid>
    </ScreenCenteredContainer>
  );
};

RoleQuestion.propTypes = {
  onProceed: PropTypes.func.isRequired,
};

export default RoleQuestion;
