import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const useAssignmentPointsState = (defaultValue) => {
  const [points, setPoints] = useState(defaultValue);
  const [error, setError] = useState('');

  const onChange = (newPoints) => {
    setPoints(newPoints);
    setError('');
  };

  const { t } = useTranslation();
  const validate = () => {
    if (points === '') {
      const errorText = t('Please insert a number between 1 and 9999.');
      setError(errorText);
      return [{ errorText, lvl3Label: t('Points') }];
    }
    return [];
  };

  return { state: points, onChange, error, validate };
};

export default useAssignmentPointsState;
