import React, { useEffect, useState } from 'react';
import { Box, DialogActions, DialogContent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Button, Dialog, DialogTitle, Typography } from '../../atoms';
import { ReactComponent as DatePickerIcon } from '../../../resources/icons/date_picker.svg';
import { getFormattedDate } from '../../../utils';
import DaySchedule from '../day-schedule/DaySchedule';
import PopupActionsButtons from '../popup-actions-buttons/PopupActionsButtons';
import InformationalCaption from '../informational-caption/InformationalCaption';

const AdjustScheduleDialog = ({ classes, classDate, isOpen, onClose, onSave }) => {
  const { i18n, t } = useTranslation();
  const [newClasses, setNewClasses] = useState(null);

  useEffect(() => {
    setNewClasses(null);
  }, [isOpen]);

  const hasLessons = classes?.some((classData) => classData.lessonsMetadata.length);

  return (
    <Dialog
      className="ayo-adjust-schedule-dialog"
      gaLabel="Adjust your schedule"
      onClose={onClose}
      open={isOpen}
    >
      <DialogTitle disableTypography>
        <Box pb={4}>
          <Typography className="ayo-adjust-schedule-dialog__title" variant="h2">
            {t('Adjust your schedule')}
          </Typography>
          <Typography variant="body2">
            {t('Adjust your classes and their order for the day below')}
          </Typography>
        </Box>
        <Box
          alignItems="center"
          className="ayo-adjust-schedule-dialog__date"
          display="flex"
          justifyContent="start"
          pb={3}
        >
          <DatePickerIcon />
          <Box pl={2.5}>
            <Typography variant="subtitle1">
              {getFormattedDate(classDate, i18n.language, { month: 'long', day: 'numeric' })}
            </Typography>
            <Typography isLabel variant="label-highlighted">
              {getFormattedDate(classDate, i18n.language, { weekday: 'long' })}
            </Typography>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DaySchedule
          date={classDate}
          dayIndex={1}
          onChange={(classesToSet) => {
            setNewClasses(classesToSet);
          }}
          periods={newClasses ?? classes}
        />
        {hasLessons && (
          <InformationalCaption title="You have lessons scheduled for this date. Please copy the content and save it somewhere, as it’ll be removed from AYO as soon as you delete any period from this date." />
        )}
      </DialogContent>
      <DialogActions>
        <PopupActionsButtons
          className="ayo-adjust-schedule-dialog__actions"
          primaryButtonComponent={
            <Button
              fullWidth
              gaLabel="Save schedule"
              onClick={() => {
                onSave(newClasses);
              }}
              variant="primary"
            >
              {t('Save schedule')}
            </Button>
          }
          secondaryButtonGaLabel="Cancel"
          secondaryButtonHandler={onClose}
          secondaryButtonText={t('Cancel')}
        />
      </DialogActions>
    </Dialog>
  );
};

AdjustScheduleDialog.propTypes = {
  classes: PropTypes.arrayOf(
    PropTypes.shape({
      className: PropTypes.string,
      orderNumber: PropTypes.number,
      period: PropTypes.number,
    }),
  ),
  classDate: PropTypes.instanceOf(Date),
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

AdjustScheduleDialog.defaultProps = {
  classes: [],
  classDate: null,
  isOpen: false,
  onClose: () => {},
  onSave: () => {},
};

export default AdjustScheduleDialog;
