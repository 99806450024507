/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Typography } from '../../../atoms';
import { InformationalCaption } from '../../../moleculas';
import { AnalyticsStatsKeys } from '../../../../constants/enums';
import { useEmotionalLearning } from '../../../../utils';
import { MOOD_DIDNT_SHARE, MoodColors } from '../../../../constants/moods';
import PieChartComponent from '../components/pie-chart/PieChartComponent';

const LegendColorsToLabelMap = {
  [MoodColors.NEGATIVE]: 'Students in the red zone',
  [MoodColors.NEUTRAL]: 'Students in the yellow zone',
  [MoodColors.POSITIVE]: 'Students in the green zone',
  [MoodColors.PENDING]: MOOD_DIDNT_SHARE,
};

const MoodsHighlightsReadOnly = ({ stats }) => {
  const { t } = useTranslation();

  const { getMoodColor, moodsSorter } = useEmotionalLearning();

  const renderLegend = (data, colorsToLabelsMap) => {
    const { iconSize, payload } = data;

    const colorsCount = payload.reduce((acc, item) => {
      const { color } = item;
      acc[color] = (acc[color] || 0) + item.payload.value;
      return acc;
    }, {});

    return (
      <Box alignItems="center" className="ayo-highlights-legend" display="flex">
        <Typography className="ayo-highlights-legend__title" mr={3} variant="caption">{`${t(
          'Legend',
        )}:`}</Typography>
        <ul className="ayo-highlights-legend__list">
          {Object.entries(colorsToLabelsMap).map(([color, label]) => (
            <li key={`legend-item-${label}`} className="ayo-highlights-legend__list__item">
              <svg
                className="recharts-surface"
                height={iconSize}
                style={{
                  borderRadius: '4px',
                  marginRight: '8px',
                }}
                viewBox={`0 0 ${iconSize * 2} ${iconSize * 2}`}
                width={iconSize}
              >
                <path
                  className="recharts-symbols"
                  cx={iconSize}
                  cy={iconSize}
                  d={`M-${iconSize},-${iconSize}h${iconSize * 2}v${iconSize * 2}h-${iconSize * 2}Z`}
                  fill={color}
                  radius="4px"
                  stroke="none"
                  transform={`translate(${iconSize}, ${iconSize})`}
                />
              </svg>
              <Typography component="span" variant="caption">{`${t(label)} (${
                colorsCount[color]
              })`}</Typography>
            </li>
          ))}
        </ul>
      </Box>
    );
  };

  return (
    <Box className="ayo-moods-highlights">
      <InformationalCaption
        isLight
        showTooltip
        title={t('This chart represents read-only information.')}
        tooltipPlacement="right"
      />
      <PieChartComponent
        chartSettings={{
          cornerRadius: 4,
          innerRadius: 100,
          outerRadius: 140,
          paddingAngle: 3,
          legendIconSize: 16,
        }}
        colorFormatter={getMoodColor}
        getLabelTextComponent={(value, name, textAnchor, x, y) => (
          <text
            className="ayo-typography--caption"
            fill="#FFFFFF"
            textAnchor={textAnchor}
            x={x}
            y={y}
          >
            {`${t(`analytics.highlights.${name}`)} (${value})`}
          </text>
        )}
        height={460}
        legendContent={(data) => renderLegend(data, LegendColorsToLabelMap)}
        statKey={AnalyticsStatsKeys.MOODS}
        stats={stats}
        statsSorter={(a, b) => moodsSorter(a, b, 'name')}
      />
    </Box>
  );
};

MoodsHighlightsReadOnly.propTypes = {
  stats: PropTypes.instanceOf(Object).isRequired,
};

export default MoodsHighlightsReadOnly;
