import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';

import LEADERSHIP_ATTRIBUTES_GALERY from '../../constants/leadership-attributes-galery';
import { mergeArrayOfObjectsHelper } from '../../utils';

export const LeadershipAttributesContext = createContext();

export const LeadershipAttributesActions = {
  SET_LEADERSHIP_ATTRIBUTES: 'SET_LEADERSHIP_ATTRIBUTES',
  SET_ACTIVE_ASSESSMENT_IDX: 'SET_ACTIVE_ASSESSMENT_IDX',
  SET_LEADERSHIP_ATTRIBUTES_GRAPH_DATA: 'SET_LEADERSHIP_ATTRIBUTES_GRAPH_DATA',
  SET_DIALOG_OPEN_STATE: 'SET_DIALOG_OPEN_STATE',
  SET_LEADERSHIP_ATTRIBUTES_ASSESSMENT_DATA: 'SET_LEADERSHIP_ATTRIBUTES_ASSESSMENT_DATA',
  SET_GOAL_RELATED_RUBRIC_DATA: 'SET_GOAL_RELATED_RUBRIC_DATA',
};

const initState = {
  suggestedActivities: [],
  leadershipAttributes: [],
  graphData: [],
  assessmentId: null,
  isDialogOpen: false,
  goalRelatedRubricData: null,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case LeadershipAttributesActions.SET_LEADERSHIP_ATTRIBUTES:
      return {
        ...state,
        leadershipAttributes: mergeArrayOfObjectsHelper(
          LEADERSHIP_ATTRIBUTES_GALERY,
          action.data,
          'key',
        ),
      };
    case LeadershipAttributesActions.SET_LEADERSHIP_ATTRIBUTES_ASSESSMENT_DATA:
      return { ...state, ...action.data };
    case LeadershipAttributesActions.SET_ACTIVE_ASSESSMENT_IDX:
      return { ...state, assessmentId: action.data };
    case LeadershipAttributesActions.SET_LEADERSHIP_ATTRIBUTES_GRAPH_DATA:
      return { ...state, graphData: action.data };
    case LeadershipAttributesActions.SET_DIALOG_OPEN_STATE:
      return { ...state, isDialogOpen: action.data };
    case LeadershipAttributesActions.SET_GOAL_RELATED_RUBRIC_DATA:
      return { ...state, goalRelatedRubricData: action.data };
    default:
      throw new Error();
  }
};

export const LeadershipAttributesContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initState);

  return (
    <LeadershipAttributesContext.Provider value={{ state, dispatch }}>
      {children}
    </LeadershipAttributesContext.Provider>
  );
};

LeadershipAttributesContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
