import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

import { InformationalCaption } from '../../../moleculas';
import { HighlightsInitiatives } from '../../../../constants/enums';
import HighlightsBarChart from '../components/highlights-bar-chart/HighlightsBarChart';

const ExtracurricularsHighlights = ({ stats, totalStudentsCount, onFiltersUpdate }) => (
  <Box display="flex" height={350}>
    <InformationalCaption
      isLight
      showTooltip
      title="Extracurriculars Highlights info"
      tooltipPlacement="right"
    />
    <HighlightsBarChart
      displayAllOther
      gaLabel="Extracurriculars Highlights Graph"
      initiative={HighlightsInitiatives.EXTRACURRICULARS}
      mainChartColor="#F45B69"
      onFiltersUpdate={onFiltersUpdate}
      pendingChartColor="#FECDD2"
      shouldSortMainStats
      stats={stats}
      totalStudentsCount={totalStudentsCount}
    />
  </Box>
);

ExtracurricularsHighlights.propTypes = {
  stats: PropTypes.instanceOf(Object).isRequired,
  totalStudentsCount: PropTypes.number.isRequired,
  onFiltersUpdate: PropTypes.func.isRequired,
};

export default ExtracurricularsHighlights;
