import artsAndHumanities from '../resources/images/endorsement_arts_humanities_image.jpg';
import multiDisciplinary from '../resources/images/endorsement_multi_disciplinary_image.jpg';
import endorsementBusiness from '../resources/images/endorsement_business_image.jpg';
import publicServices from '../resources/images/endorsement_public_services_image.jpg';
import stem from '../resources/images/endorsement_stem_image.jpg';

export const getSortedAndTrimmedEndorsements = (entity, key, maxLength) =>
  entity?.sort((a, b) => new Date(b[key]) - new Date(a[key])).slice(0, maxLength);

export const EndorsementTitles = {
  STEM: 'stem_2295e0750a84297532f2a8197bb76b5e',
  MULTI_DISCIPLINARY_STUDIES: 'multi_disciplinary_studies_15ea7138bc11b04e40053afe437c9362',
  BUSINESS_AND_INDUSTRY: 'business_and_industry_394179f5df664678763c82308e82270d',
  PUBLIC_SERVICES: 'public_services_e87838e76b32c3c3a6b45a43c20f286d',
  ARTS_AND_HUMANITIES: 'arts_and_humanities_67c873488bb06f39da407e40d55efca8',
};

export const EndorsementIllustrationsByTitle = {
  [EndorsementTitles.ARTS_AND_HUMANITIES]: {
    image: artsAndHumanities,
    text: 'A group of youth dancing on the stage',
  },
  [EndorsementTitles.MULTI_DISCIPLINARY_STUDIES]: {
    image: multiDisciplinary,
    text: 'A girl sitting in front of her laptop',
  },
  [EndorsementTitles.BUSINESS_AND_INDUSTRY]: {
    image: endorsementBusiness,
    text: 'A reporter and a cameraman',
  },
  [EndorsementTitles.PUBLIC_SERVICES]: {
    image: publicServices,
    text: 'A doctor seeing a child patient',
  },
  [EndorsementTitles.STEM]: {
    image: stem,
    text: 'A boy in VR glasses',
  },
};
