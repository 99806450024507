// Gives false positive lint error for React.memo component
/* eslint-disable react/no-unused-prop-types */
import React, { useRef, useState, useEffect, useCallback, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTheme, useMediaQuery, Box, Grid } from '@mui/material';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import ReactDOM from 'react-dom';

import { Button, Typography, BubbleCircles, Link } from '../../atoms';
import { TextWithTooltip } from '../../moleculas';
import {
  bounds,
  debounce,
  findInterestById,
  GA,
  getInterestBubbleProperties,
  pack,
  useProfile,
} from '../../../utils';
import { UserActions, UserContext } from '../../../context';
import { useOneTimeActionService } from '../../../services';
import {
  GaActions,
  GaCategories,
  InterestReactionNames,
  InterestTypes,
  KeyboardMap,
  OneTimeActionsMap,
} from '../../../constants/enums';
import { ReactComponent as FullScreenIcon } from '../../../resources/icons/fullscreen.svg';
import { ReactComponent as FullScreenExitIcon } from '../../../resources/icons/fullscreen_exit.svg';
import { ReactComponent as LinkIcon } from '../../../resources/icons/link.svg';
import arrowTwisted from '../../../resources/icons/arrow_twisted.svg';

import { ZoomWidget, FilterWidget, SearchWidget, ReactionWidget } from './widgets';

const PLOT_PADDING = 40;
const PLOT_PADDING_MOBILE = 24;

const PLOT_HEIGHT = 650;
const MAX_ZOOM_LEVEL = 5;
const SCALE_FACTOR = 1.8;
const SEARCH_BUBBLE_DISTANCE_Y = 16;
const BUBBLE_UPPERTEXT_INITIAL_HEIGHT = 24;
const ACTIVE_CATEGORY_AREA_THRESHOLD = 0.5;
const SELECTED_INTEREST_BUFF_RADIUS_INCREASE = 1.75;
const SELECTED_INTEREST_BUFF_STROKE_WIDTH = 0.3;

// Safari transform translate and position relative on svg foreignElement is broken, adding some temporary fixes for now
const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const getZoomedDimension = (dimension, zoomRatio) => dimension / SCALE_FACTOR ** zoomRatio;

const normalizePointInBounds = (x, y, width, height, mapBounds) => {
  let resultX = Math.max(x, mapBounds.x);
  let resultY = Math.max(y, mapBounds.y);
  if (x + width > mapBounds.x + mapBounds.width) {
    resultX = mapBounds.x + mapBounds.width - width;
  }
  if (y + height > mapBounds.y + mapBounds.height) {
    resultY = mapBounds.y + mapBounds.height - height;
  }
  return {
    x: resultX,
    y: resultY,
  };
};

const LARGE_REFLECTION_ICON_SIZE = 56;
const MEDIUM_REFLECTION_ICON_SIZE = 40;
const SMALL_REFLECTION_ICON_SIZE = 24;
const getReflectionIconSize = (interestType, zoomRatio) => {
  if (interestType === InterestTypes.LIKED) {
    if (zoomRatio === MAX_ZOOM_LEVEL) {
      return LARGE_REFLECTION_ICON_SIZE;
    }
    if (zoomRatio === MAX_ZOOM_LEVEL - 1) {
      return MEDIUM_REFLECTION_ICON_SIZE;
    }
  }
  if (zoomRatio === MAX_ZOOM_LEVEL) {
    return MEDIUM_REFLECTION_ICON_SIZE;
  }
  return SMALL_REFLECTION_ICON_SIZE;
};

const InterestListItem = ({
  categoryVisibility,
  circle,
  currentViewBox,
  openReactionHandler,
  zoomRatio,
}) => {
  const { t } = useTranslation();
  let statusLabel = InterestReactionNames[circle.reaction]
    ? `${t('current reaction')}: ${InterestReactionNames[circle.reaction]}`
    : circle.suggested
    ? t('suggested interest')
    : '';

  if (circle.reflection) {
    statusLabel += ` ${t('Has a comment')}`;
  }

  const categoryTabIndex = categoryVisibility[circle.interestType] ? '0' : null;

  const reflectionSize = getReflectionIconSize(circle.interestType, zoomRatio);

  const zoomedReflectionSize = getZoomedDimension(reflectionSize, zoomRatio);

  return zoomRatio >= circle.level &&
    circle.x > currentViewBox.x - circle.r &&
    circle.x < currentViewBox.x + currentViewBox.width + circle.r &&
    circle.y > currentViewBox.y - circle.r &&
    circle.y < currentViewBox.y + currentViewBox.height + circle.r ? (
    <>
      <g
        key={`${circle.id}`}
        aria-hidden={!categoryVisibility[circle.interestType]}
        aria-label={`${t('React to')} ${circle.name} ${statusLabel}`}
        className={classnames(
          'interest',
          `interest-level-${circle.level} interest-${circle.interestType}`,
          {
            displayed: categoryVisibility[circle.interestType],
          },
        )}
        data-interest-id={circle.id}
        onClick={() => openReactionHandler(circle)}
        onKeyUp={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            openReactionHandler(circle);
          }
        }}
        role="button"
        tabIndex={categoryTabIndex}
      >
        <BubbleCircles
          buffRadiusIncrease={SELECTED_INTEREST_BUFF_RADIUS_INCREASE}
          buffStrokeWidth={SELECTED_INTEREST_BUFF_STROKE_WIDTH}
          circle={circle}
          commentPosition={zoomRatio > 1 ? 'top-right' : 'outside-right'}
          commentSize={zoomedReflectionSize}
          withComment={circle.reflection}
        />
        <foreignObject
          className={classnames('text', {
            displayed: zoomRatio >= circle.textZoomRatio,
          })}
          height={circle.r * 2}
          width={circle.r * 2}
          x={circle.x - circle.r}
          y={circle.y - circle.r}
        >
          <Box
            alignItems="center"
            display="flex"
            height="100%"
            justifyContent="center"
            width="100%"
          >
            <Typography
              align="center"
              className={`main interest-bubble-text interest-bubble-text-${circle.interestType} zoom-level-${zoomRatio}`}
              component="p"
              variant="subtitle2"
            >
              {circle.name}
            </Typography>
          </Box>
        </foreignObject>
      </g>
      <foreignObject
        aria-hidden="true"
        className={classnames('uppertext-object', {
          displayed: categoryVisibility[circle.interestType],
        })}
        height={BUBBLE_UPPERTEXT_INITIAL_HEIGHT}
        width={circle.r * 2}
        x={circle.x - (isSafari ? circle.r + 5 : 0)}
        y={circle.y - circle.r - BUBBLE_UPPERTEXT_INITIAL_HEIGHT}
      >
        <Box
          alignItems="center"
          className={`uppertext-block zoom-level-${zoomRatio}`}
          display="flex"
          height="100%"
          justifyContent="center"
          width="100%"
        >
          <TextWithTooltip
            key={zoomRatio}
            className={`uppertext interest-level-${circle.level} ${circle.interestType}`}
            containerProps={
              zoomRatio
                ? {
                    width: '100%',
                  }
                : null
            }
            rowsCount={2}
            title={circle.name}
            titleVariant="subtitle2"
            typographyProps={{ align: 'center' }}
          />
        </Box>
      </foreignObject>
    </>
  ) : null;
};

InterestListItem.propTypes = {
  categoryVisibility: PropTypes.instanceOf(Object).isRequired,
  circle: PropTypes.instanceOf(Object).isRequired,
  currentViewBox: PropTypes.instanceOf(Object).isRequired,
  openReactionHandler: PropTypes.func.isRequired,
  zoomRatio: PropTypes.number.isRequired,
};

const InterestsList = React.memo(
  ({ categoryVisibility, circles, currentViewBox, openReactionHandler, zoomRatio }) =>
    !!circles.length &&
    circles.map((circle) => (
      <InterestListItem
        key={circle.id}
        categoryVisibility={categoryVisibility}
        circle={circle}
        currentViewBox={currentViewBox}
        openReactionHandler={openReactionHandler}
        zoomRatio={zoomRatio}
      />
    )),
);

InterestsList.propTypes = {
  categoryVisibility: PropTypes.instanceOf(Object).isRequired,
  circles: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  currentViewBox: PropTypes.instanceOf(Object).isRequired,
  openReactionHandler: PropTypes.func.isRequired,
  zoomRatio: PropTypes.number.isRequired,
};

const REACTION_BUBBLE_RADIUS = 94;
const InterestsPlot = ({ interests, changeItem, nextElementSelector }) => {
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  const currentSizePlotPadding = isWidthUpSm ? PLOT_PADDING : PLOT_PADDING_MOBILE;
  const svgRef = useRef();
  const [circles, setCircles] = useState([]);

  // Is used for rerendering component when screen width has changed
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [isFullScreenEntered, setIsFullScreenEntered] = useState(false);

  const [actualBounds, setActualBounds] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  const [currentViewBox, setViewBox] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    init: true,
  });

  const [currentCategoriesBounds, setCurrentCategoriesBounds] = useState();
  const [activeCategory, setActiveCategory] = useState();

  const getCurrentCategory = useCallback((functionBounds) => {
    const viewBox = svgRef.current.viewBox.baseVal;
    const currentViewBoxArea = viewBox.width * viewBox.height;
    const newCategory = functionBounds?.find((category) => {
      const categoryX1 = category.x0 + category.width;
      const categoryY1 = category.y0 + category.height;
      const calculationX0 = Math.max(category.x0, viewBox.x);
      const calculationX1 = Math.min(categoryX1, viewBox.x + viewBox.width);
      const calculationY0 = Math.max(category.y0, viewBox.y);
      const calculationY1 = Math.min(categoryY1, viewBox.y + viewBox.height);
      if (calculationX1 < calculationX0 || calculationY1 < calculationY0) {
        return false;
      }
      const categoryVisibleArea = (calculationX1 - calculationX0) * (calculationY1 - calculationY0);
      return categoryVisibleArea / currentViewBoxArea > ACTIVE_CATEGORY_AREA_THRESHOLD;
    });
    return newCategory?.items[0].name;
  }, []);

  const setCurrentViewbox = useCallback(
    (newViewbox, newActiveCategory, categoriesBounds = currentCategoriesBounds) => {
      setViewBox(newViewbox);
      setActiveCategory(newActiveCategory || getCurrentCategory(categoriesBounds));
    },
    [currentCategoriesBounds, getCurrentCategory],
  );

  const [zoomRatio, setZoomRatio] = useState(0);
  const [selectedInterest, setSelectedInterest] = useState();

  const [hasReaction, setHasReaction] = useState(false);
  const [reactionTipInterest, setReactionTipInterest] = useState(null);

  const { getOneTimeActionStatus } = useProfile();

  useEffect(() => {
    if (!interests.length || selectedInterest) return;
    const svgWidth = svgRef.current?.clientWidth;
    const packingWidth = Math.max(svgWidth - currentSizePlotPadding * 2, 600);
    const packingResult = pack(interests, packingWidth);
    let { circles: newPacking } = packingResult;
    const { groups: newCategoriesBounds } = packingResult;
    const packingBounds = bounds(newPacking, currentSizePlotPadding);
    const newBounds = {
      x: packingBounds[0],
      y: packingBounds[1],
      width: isWidthUpSm ? svgRef.current?.clientWidth : packingBounds[2],
      height: packingBounds[3],
      packingWidth,
      initialWidth: svgWidth,
    };
    setCurrentCategoriesBounds(newCategoriesBounds);
    if (currentViewBox.init || actualBounds.packingWidth !== packingWidth) {
      const hasOpenedReactionWidget = getOneTimeActionStatus(
        OneTimeActionsMap.INTERESTS_UNIVERSE_REACTION,
      );
      const highestConfidenceInterest = newPacking.reduce(
        (acc, interest) => (interest.confidence > (acc.confidence || 0) ? interest : acc),
        newPacking[0],
      );
      newPacking = newPacking.filter((interest) => interest !== highestConfidenceInterest);
      newPacking.unshift(highestConfidenceInterest);
      const zoomedWidth = getZoomedDimension(svgWidth, 3);
      const zoomedHeight = getZoomedDimension(PLOT_HEIGHT, 3);
      const defaultViewbox = {
        x: packingBounds[0],
        y: packingBounds[1],
        width: svgWidth,
        height: PLOT_HEIGHT,
      };
      const zoomedViewboxStartPoint = normalizePointInBounds(
        highestConfidenceInterest.x - zoomedWidth / 2,
        highestConfidenceInterest.y - zoomedHeight / 2,
        zoomedWidth,
        zoomedHeight,
        newBounds,
      );
      const defaultZoomedViewbox = {
        x: zoomedViewboxStartPoint.x,
        y: zoomedViewboxStartPoint.y,
        width: zoomedWidth,
        height: zoomedHeight,
      };
      setCurrentViewbox(
        hasOpenedReactionWidget ? defaultViewbox : defaultZoomedViewbox,
        null,
        newCategoriesBounds,
      );
      setZoomRatio(hasOpenedReactionWidget ? 0 : 3);
      setSelectedInterest(null);
      setReactionTipInterest(highestConfidenceInterest);
      setHasReaction(hasOpenedReactionWidget);
    }
    setActualBounds(newBounds);
    setCircles(newPacking);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSizePlotPadding, interests, isWidthUpSm, selectedInterest, windowWidth]);

  useEffect(() => {
    if (interests && selectedInterest) {
      const foundInterest = findInterestById(interests, selectedInterest.id);
      const foundInterestCircle = {
        ...foundInterest,
        ...getInterestBubbleProperties(foundInterest),
      };

      setSelectedInterest({
        ...foundInterestCircle,
        reaction: selectedInterest.reaction,
        interestType: selectedInterest.interestType,
        r: REACTION_BUBBLE_RADIUS,
        x: selectedInterest.x,
        y: selectedInterest.y,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interests]);

  const toggleFullScreen = useCallback(() => {
    setIsFullScreenEntered((prevValue) => !prevValue);
    const fullWidth = window.innerWidth;
    const zoomedFullWidth = getZoomedDimension(fullWidth, zoomRatio);
    const fullHeight = window.innerHeight;
    const zoomedFullHeight = getZoomedDimension(fullHeight, zoomRatio);
    const { x, y, width } = currentViewBox;
    const xSpacingAvailable = (zoomedFullWidth - width) / 2;
    const actualEndY = actualBounds.y + actualBounds.height;
    if (!isFullScreenEntered) {
      setCurrentViewbox({
        x: Math.max(x - xSpacingAvailable, actualBounds.x),
        y: y + zoomedFullHeight > actualEndY ? actualEndY - zoomedFullHeight : y,
        width: zoomedFullWidth,
        height: zoomedFullHeight,
      });
    } else {
      // Wait until component rerenders and we can check actual width of svg
      setTimeout(() => {
        setCurrentViewbox({
          x,
          y,
          width: getZoomedDimension(svgRef.current.clientWidth, zoomRatio),
          height: getZoomedDimension(PLOT_HEIGHT, zoomRatio),
        });
      }, 0);
    }
  }, [actualBounds, currentViewBox, zoomRatio, isFullScreenEntered, setCurrentViewbox]);

  useEffect(() => {
    const rerender = debounce(() => {
      setWindowWidth(window.innerWidth);
      if (isFullScreenEntered) {
        setCurrentViewbox({
          ...currentViewBox,
          height: getZoomedDimension(window.innerHeight, zoomRatio),
        });
        svgRef.current.setAttribute('height', window.innerHeight);
      }
    }, 300);
    window.addEventListener('resize', rerender);

    return () => window.removeEventListener('resize', rerender);
  }, [isFullScreenEntered, windowWidth.width, currentViewBox, setCurrentViewbox, zoomRatio]);

  const { t } = useTranslation();

  const mouseState = useRef();

  const getZoomedViewbox = useCallback(
    (startPoint, isZoomIn, viewBox = currentViewBox) => {
      const scaleDelta = isZoomIn ? 1 / SCALE_FACTOR : SCALE_FACTOR;
      const newX = viewBox.x - (startPoint.x - viewBox.x) * (scaleDelta - 1);
      const newY = viewBox.y - (startPoint.y - viewBox.y) * (scaleDelta - 1);
      const newWidth = viewBox.width * scaleDelta;
      const newHeight = viewBox.height * scaleDelta;
      const { x, y } = normalizePointInBounds(newX, newY, newWidth, newHeight, actualBounds);
      return {
        newX: x,
        newY: y,
        newWidth,
        newHeight,
      };
    },
    [actualBounds, currentViewBox],
  );

  const lastTouchRef = useRef();

  const getNewPositionWithBounds = useCallback(
    (targetX, targetY) => {
      let newX;
      let newY;
      if (
        targetX > actualBounds.x &&
        targetX + currentViewBox.width < actualBounds.x + actualBounds.width
      ) {
        newX = targetX;
      } else if (targetX <= actualBounds.x) {
        newX = actualBounds.x;
      } else {
        newX = actualBounds.x + actualBounds.width - currentViewBox.width;
      }
      if (
        targetY > actualBounds.y &&
        targetY + currentViewBox.height < actualBounds.y + actualBounds.height
      ) {
        newY = targetY;
      } else if (targetY <= actualBounds.y) {
        newY = actualBounds.y;
      } else {
        newY = actualBounds.y + actualBounds.height - currentViewBox.height;
      }
      return [newX, newY];
    },
    [actualBounds.height, actualBounds.width, actualBounds.x, actualBounds.y, currentViewBox],
  );

  useEffect(() => {
    const ref = svgRef.current;
    if (selectedInterest) return () => {};
    const zoomHandler = (e) => {
      if (selectedInterest) return;
      e.preventDefault();
      const isZoomIn = e.deltaY < 0;
      const point = svgRef.current.createSVGPoint();
      point.x = e.clientX;
      point.y = e.clientY;
      const startPoint = point.matrixTransform(svgRef.current.getScreenCTM().inverse());
      const { newX, newY, newWidth, newHeight } = getZoomedViewbox(startPoint, isZoomIn);

      if (!isZoomIn && (newWidth - actualBounds.width > 1 || zoomRatio <= 0)) {
        return;
      }
      if (
        isZoomIn &&
        (newWidth - actualBounds.initialWidth / SCALE_FACTOR ** MAX_ZOOM_LEVEL < -1 ||
          zoomRatio >= MAX_ZOOM_LEVEL)
      ) {
        return;
      }

      setCurrentViewbox({
        x: newX,
        y: newY,
        width: newWidth,
        height: newHeight,
      });
      setZoomRatio((currentZoom) => (isZoomIn ? currentZoom + 1 : currentZoom - 1));
    };
    const moveStart = (e) => {
      mouseState.current = true;
      lastTouchRef.current = e.touches?.[0];
    };
    const moveEnd = () => {
      mouseState.current = false;
      lastTouchRef.current = null;
    };
    const move = (e) => {
      e.preventDefault();
      if (selectedInterest) return;
      if (mouseState.current) {
        const viewBox = svgRef.current.viewBox.baseVal;
        const movementX = e.touches ? e.touches[0].pageX - lastTouchRef.current.pageX : e.movementX;
        const movementY = e.touches ? e.touches[0].pageY - lastTouchRef.current.pageY : e.movementY;
        lastTouchRef.current = e.touches?.[0];
        const targetX = viewBox.x - movementX / SCALE_FACTOR ** zoomRatio;
        const targetY = viewBox.y - movementY / SCALE_FACTOR ** zoomRatio;
        const [newX, newY] = getNewPositionWithBounds(targetX, targetY);
        setCurrentViewbox({
          x: newX,
          y: newY,
          width: viewBox.width,
          height: viewBox.height,
        });
      }
    };
    ref.addEventListener('wheel', zoomHandler);
    ref.addEventListener('mousedown', moveStart);
    ref.addEventListener('mouseup', moveEnd);
    ref.addEventListener('mousemove', move);
    ref.addEventListener('touchstart', moveStart);
    ref.addEventListener('touchend', moveEnd);
    ref.addEventListener('touchmove', move);
    return () => {
      ref.removeEventListener('wheel', zoomHandler);
      ref.removeEventListener('mousedown', moveStart);
      ref.removeEventListener('mouseup', moveEnd);
      ref.removeEventListener('mousemove', move);
      ref.removeEventListener('touchstart', moveStart);
      ref.removeEventListener('touchend', moveEnd);
      ref.removeEventListener('touchmove', move);
    };
  }, [
    actualBounds,
    currentCategoriesBounds,
    getNewPositionWithBounds,
    getZoomedViewbox,
    selectedInterest,
    setCurrentViewbox,
    zoomRatio,
  ]);

  const closeReactionWidget = useCallback(() => {
    setSelectedInterest(null);
    setReactionTipInterest(null);
  }, []);

  const onZoomSliderChange = useCallback(
    (e, newValue) => {
      if (newValue === zoomRatio) return;
      let zoomedViewBox = currentViewBox;
      for (let i = 0; i < Math.abs(newValue - zoomRatio); i += 1) {
        const { newX, newY, newWidth, newHeight } = getZoomedViewbox(
          {
            x: currentViewBox.x + currentViewBox.width / 2,
            y: currentViewBox.y + currentViewBox.height / 2,
          },
          newValue > zoomRatio,
          zoomedViewBox,
        );
        zoomedViewBox = { x: newX, y: newY, width: newWidth, height: newHeight };
      }
      setCurrentViewbox(zoomedViewBox);
      setZoomRatio(newValue);
    },
    [currentViewBox, getZoomedViewbox, setCurrentViewbox, zoomRatio],
  );

  const [lastReactedInterest, setLastReactedInterest] = useState(null);

  const [categoryVisibility, setCategoryVisibility] = useState(
    Object.values(InterestTypes).reduce(
      (acc, interestType) => ({ ...acc, [interestType]: true }),
      {},
    ),
  );

  const onZoomIn = useCallback(() => {
    if (zoomRatio < MAX_ZOOM_LEVEL) {
      const { newX, newY, newWidth, newHeight } = getZoomedViewbox(
        {
          x: currentViewBox.x + currentViewBox.width / 2,
          y: currentViewBox.y + currentViewBox.height / 2,
        },
        true,
      );
      setCurrentViewbox({ x: newX, y: newY, width: newWidth, height: newHeight });
      setZoomRatio((prevValue) => prevValue + 1);
    }
  }, [currentViewBox, getZoomedViewbox, setCurrentViewbox, zoomRatio]);

  const onZoomOut = useCallback(() => {
    if (zoomRatio > 0) {
      const { newX, newY, newWidth, newHeight } = getZoomedViewbox(
        {
          x: currentViewBox.x + currentViewBox.width / 2,
          y: currentViewBox.y + currentViewBox.height / 2,
        },
        false,
      );
      setCurrentViewbox({ x: newX, y: newY, width: newWidth, height: newHeight });
      setZoomRatio((prevValue) => prevValue - 1);
    }
  }, [currentViewBox, getZoomedViewbox, setCurrentViewbox, zoomRatio]);

  const onSearchOptionClick = useCallback(
    (option) => {
      const fullZoomWidth = actualBounds.initialWidth / SCALE_FACTOR ** 4;
      const fullZoomHeight = PLOT_HEIGHT / SCALE_FACTOR ** 4;
      const optionCircle = circles.find((circle) => circle.id === option);
      setCurrentViewbox({
        x: optionCircle.x - fullZoomWidth / 2,
        y: optionCircle.y - SEARCH_BUBBLE_DISTANCE_Y,
        width: fullZoomWidth,
        height: fullZoomHeight,
      });
      setZoomRatio(4);
      // Timeout to prevent keyup triggering on Bubble
      setTimeout(() => document.querySelector(`[data-interest-id="${option}"]`).focus(), 0);
    },
    [actualBounds.initialWidth, circles, setCurrentViewbox],
  );

  const sendGoogleAnalytics = useCallback((action) => {
    GA.logInteraction({
      category: GaCategories.BEHAVIOR,
      action,
      label: 'Interests map',
    });
  }, []);

  const { postOneTimeAction } = useOneTimeActionService();

  const { dispatch: dispatchUserState } = useContext(UserContext);

  const openReactionHandler = useCallback(
    (circle) => {
      sendGoogleAnalytics(GaActions.BUBBLE_CLICK);
      setSelectedInterest({ ...circle, r: REACTION_BUBBLE_RADIUS });
      const hasOpenedReactionWidget = getOneTimeActionStatus(
        OneTimeActionsMap.INTERESTS_UNIVERSE_REACTION,
      );
      if (!hasOpenedReactionWidget) {
        postOneTimeAction(OneTimeActionsMap.INTERESTS_UNIVERSE_REACTION);
        dispatchUserState({
          type: UserActions.SET_ONE_TIME_ACTION,
          data: OneTimeActionsMap.INTERESTS_UNIVERSE_REACTION,
        });
      }
    },
    [dispatchUserState, getOneTimeActionStatus, postOneTimeAction, sendGoogleAnalytics],
  );

  const onCircleFocus = (circle) => {
    // Target only keyboard focus
    if (mouseState.current) return;
    const [newX, newY] = getNewPositionWithBounds(
      circle.x - currentViewBox.width / 2,
      circle.y - currentViewBox.height / 2,
    );
    setCurrentViewbox(
      {
        x: newX,
        y: newY,
        width: currentViewBox.width,
        height: currentViewBox.height,
      },
      currentCategoriesBounds?.find((category) =>
        category.items.some((item) => item.id === circle.id),
      )?.items[0].name || null,
    );
  };

  const isReactionTipInterestVisible =
    reactionTipInterest &&
    zoomRatio >= reactionTipInterest.level &&
    categoryVisibility[reactionTipInterest.interestType];

  useEffect(() => {
    const escapeHandler = (e) => {
      if (e.key === KeyboardMap.ESCAPE) {
        closeReactionWidget();
      }
    };
    if (selectedInterest) {
      document.addEventListener('keydown', escapeHandler);
    }
    return () => document.removeEventListener('keydown', escapeHandler);
  }, [closeReactionWidget, selectedInterest]);

  const definitionBoxRef = useRef(null);

  const hasSelectedInterestDefinition = useMemo(
    () => selectedInterest?.definitions?.some((definitionInfo) => definitionInfo.definition),
    [selectedInterest],
  );

  return (
    <Box
      className={classnames('ayo-interests-map', { 'full-screen': isFullScreenEntered })}
      position="relative"
    >
      <Button
        className="ayo-skip-to-next-section"
        onClick={() => {
          document.querySelector(nextElementSelector).focus();
        }}
        variant="primary"
      >
        {t('Skip to the next section')}
      </Button>
      {selectedInterest ? (
        <Box className="definition-container" px={2} py={5}>
          <Box
            ref={definitionBoxRef}
            className="interest-universe-definition-container"
            width="100%"
          >
            <Grid container justifyContent="center">
              <Grid item sm={6} xs={12}>
                <Box className="definition-content">
                  <Box mb={3}>
                    <Typography variant="subtitle1">{selectedInterest.name}</Typography>
                  </Box>
                  <Box className="definitions-list">
                    {hasSelectedInterestDefinition ? (
                      selectedInterest.definitions.map(
                        (definitionInfo) =>
                          definitionInfo.definition && (
                            <Box key={definitionInfo.key}>
                              <Box mb={1}>
                                <Typography variant="subtitle2">
                                  {t('What is interestName?', {
                                    interestName: definitionInfo.name,
                                  })}
                                </Typography>
                              </Box>
                              <Box mb={1}>
                                <Typography className="definition-body" variant="body2">
                                  {definitionInfo.definition}
                                </Typography>
                              </Box>
                              {definitionInfo.url && (
                                <Link
                                  className="no-definition-link"
                                  gaLabel="Learn more about interest"
                                  href={definitionInfo.url}
                                  target="_blank"
                                >
                                  {t('Learn more at')} Wikipedia
                                  <LinkIcon />
                                </Link>
                              )}
                            </Box>
                          ),
                      )
                    ) : (
                      <Box mb={1}>
                        <Typography className="definition-body" variant="body2">
                          {t('AYO couldn’t find information about this interest')}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box className="reaction-widget" width="100%">
            <ReactionWidget
              changeItem={changeItem}
              changeReactedItem={setSelectedInterest}
              close={closeReactionWidget}
              interest={selectedInterest}
              setLastReactedInterest={setLastReactedInterest}
            />
          </Box>
        </Box>
      ) : (
        <>
          {!actualBounds.init && !!circles?.length && (
            <Box
              alignItems="flex-start"
              className="interests-widgets"
              display="flex"
              left="16px"
              position="absolute"
              top="16px"
            >
              {!isWidthUpSm && (
                <Button
                  aria-label={
                    isFullScreenEntered
                      ? t('Exit interests universe full screen mode')
                      : t('Enter interests universe full screen mode')
                  }
                  className="widget-button full-screen-toggle"
                  gaLabel={
                    isFullScreenEntered
                      ? 'Exit interests universe full screen mode'
                      : 'Enter interests universe full screen mode'
                  }
                  isIconButton
                  onClick={toggleFullScreen}
                  variant="dark"
                >
                  {isFullScreenEntered ? <FullScreenExitIcon /> : <FullScreenIcon />}
                </Button>
              )}
              {/* This div is needed for background to be hidden when window height is changed because of navigation collapsing */}
              {isFullScreenEntered &&
                ReactDOM.createPortal(
                  <div className="ayo-interests-map__full-screen-overlay" />,
                  document.querySelector('#root'),
                )}
              <ZoomWidget
                currentZoomRatio={zoomRatio}
                onZoomIn={onZoomIn}
                onZoomOut={onZoomOut}
                onZoomSliderChange={onZoomSliderChange}
              />
              <Box display="flex" flex="1" flexDirection={isWidthUpSm ? 'row' : 'row-reverse'}>
                <FilterWidget
                  categoryVisibility={categoryVisibility}
                  changeCategoryVisibility={setCategoryVisibility}
                />
                <SearchWidget onOptionClick={onSearchOptionClick} suggestions={circles} />
              </Box>
            </Box>
          )}
          <svg
            ref={svgRef}
            className={classnames('ayo-interests-area', { safari: isSafari })}
            height={isFullScreenEntered ? window.innerHeight : PLOT_HEIGHT}
            onFocus={(e) => {
              const { interestId } = e.target.dataset;
              if (!interestId) return;
              const focusedCircle = circles.find((circle) => +circle.id === +interestId);
              onCircleFocus(focusedCircle);
            }}
            preserveAspectRatio="xMidYMid meet"
            viewBox={`${currentViewBox.x} ${currentViewBox.y} ${currentViewBox.width} ${currentViewBox.height}`}
            width={isFullScreenEntered ? '100vw' : '100%'}
          >
            <desc>{t('Space with your interests')}</desc>
            <defs>
              <filter filterUnits="objectBoundingBox" height="1" id="blur" width="1" x="0" y="0">
                <feGaussianBlur in="BackgroundImage" result="blurred" stdDeviation="1.1" />
              </filter>
              <linearGradient
                gradientUnits="objectBoundingBox"
                id="primary_gradient_0"
                x1="0.5"
                x2="0.5"
                y1="0"
                y2="1"
              >
                <stop stopColor="#6065A8" />
                <stop offset="1" stopColor="#5C43A2" stopOpacity="0.84" />
              </linearGradient>
              <linearGradient
                gradientUnits="objectBoundingBox"
                id="primary_gradient_1"
                x1="0.7"
                x2="0.2"
                y1="0"
                y2="1"
              >
                <stop stopColor="#8E3078" stopOpacity="0.41" />
                <stop offset="1" stopOpacity="0.15" />
              </linearGradient>
              <linearGradient
                gradientUnits="objectBoundingBox"
                id="secondary_gradient"
                x1="0"
                x2="1"
                y1="0"
                y2="0"
              >
                <stop stopColor="#F6D365" />
                <stop offset="1" stopColor="#FDA085" />
              </linearGradient>
            </defs>
            <g fill="none" id="mainContent" stroke="none">
              <rect
                fill="#1E152A"
                height={actualBounds.height}
                width={actualBounds.width}
                x={actualBounds.x}
                y={actualBounds.y}
              />
              <g>
                <path
                  d="M-300.5 119.402C-300.5 110.96 -294.057 103.991 -285.627 103.548C-230.302 100.64 -32.3813 92.2061 107.861 111.511C227.976 128.046 353.07 185.506 484.155 184.899C583.003 184.441 663.328 131.73 795.878 104.388C964.261 69.655 1249.13 96.0064 1315.42 102.871C1323.51 103.708 1329.5 110.52 1329.5 118.647V312.192C1329.5 314.696 1330.82 317.259 1330.44 319.735C1327.51 339.096 1138.01 239.647 986.252 239.647C790.201 239.647 602.248 349.38 436.283 382.748C260.788 418.033 114.911 341.02 -102.552 341.02C-241.942 352.134 -263.878 372.759 -284.805 380.083C-293.145 383.002 -300.5 375.585 -300.5 366.748V119.402Z"
                  fill="#241932"
                />
                <path
                  d="M1329.5 576.203C1329.5 567.483 1322.64 560.378 1313.92 560.266C1247.79 559.409 987.853 557.441 859.232 577.07C734.943 591.501 638.484 650.916 520.475 650.31C431.489 649.853 366.119 594.978 238.846 574.203C114.62 553.924 -212.353 571.797 -285.739 576.165C-294.16 576.666 -300.5 583.634 -300.5 592.07V710.53C-300.5 712.817 -301.646 715.28 -300.747 717.383C-290.638 741.032 9.4563 668.98 61.45 668.98C237.943 668.98 414.163 814.464 563.572 847.766C721.56 882.981 852.885 806.121 1048.66 806.121C1182.05 817.911 1272.62 856.661 1313.79 866.965C1322.36 869.111 1329.5 862.337 1329.5 853.5V576.203Z"
                  fill="#241932"
                />
              </g>
              {!hasReaction && isReactionTipInterestVisible && (
                <g>
                  <image
                    className="reaction-tip-arrow"
                    href={arrowTwisted}
                    x={reactionTipInterest.x + reactionTipInterest.r}
                    y={reactionTipInterest.y + reactionTipInterest.r}
                  />
                  <text
                    className="reaction-tip-text"
                    x={reactionTipInterest.x}
                    y={reactionTipInterest.y + reactionTipInterest.r + 10}
                  >
                    {t('Select me to react!')}
                  </text>
                </g>
              )}
              <InterestsList
                categoryVisibility={categoryVisibility}
                circles={circles}
                currentViewBox={currentViewBox}
                openReactionHandler={openReactionHandler}
                zoomRatio={zoomRatio}
              />
            </g>
          </svg>
          <Box
            aria-live="assertive"
            bottom="16px"
            className="current-category"
            left={!isWidthUpSm && '50%'}
            position="absolute"
            right={isWidthUpSm && '16px'}
          >
            {activeCategory && (
              <>
                <Typography variant="body3">{t('You’re browsing at')}:</Typography>
                <Typography component="p" variant="subtitle2">
                  {activeCategory}
                </Typography>
              </>
            )}
          </Box>
        </>
      )}

      <div className="sr-only" role="alert">
        {lastReactedInterest &&
          t('Reaction to interest was changed', { interestName: lastReactedInterest.name })}
      </div>
    </Box>
  );
};

InterestsPlot.propTypes = {
  interests: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      r: PropTypes.number,
      randomSpacer: PropTypes.number,
      reaction: PropTypes.number,
    }),
  ).isRequired,
  changeItem: PropTypes.func.isRequired,
  nextElementSelector: PropTypes.string.isRequired,
};

export default React.memo(InterestsPlot);
