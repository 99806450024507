import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Box, Tab, useMediaQuery, useTheme } from '@mui/material';
import classnames from 'classnames';

import { Button, TabPanel, Tabs } from '../../../../atoms';
import { InformationalMessage, TextBadge, TextWithTooltip } from '../../../../moleculas';
import {
  ClubRights,
  ClubsMembershipStatuses,
  ClubsMembersQueryParams,
  ClubsStatuses,
} from '../../../../../constants/club-hub';
import {
  GaActions,
  GaCategories,
  OneTimeActionsMap,
  RolesMap,
} from '../../../../../constants/enums';
import { useOneTimeAction } from '../../../../../hooks';
import { deepEqual, GA } from '../../../../../utils';
import { UserContext } from '../../../../../context';
import { useClubHubService } from '../../../../../services';
import { ReactComponent as GearIcon } from '../../../../../resources/icons/gear.svg';
import ClubSettingsDialog from '../components/club-settngs-dialog/ClubSettingsDialog';

import ClubAboutSection from './club-about-section/ClubAboutSection';
import ClubPostAndEventsSection from './club-post-and-events-section/ClubPostAndEventsSection';
import ClubsMembersSection from './clubs-members-section/ClubsMembersSection';

const ClubSections = ({
  clubData,
  clubId,
  clubRights,
  getIsFieldEditable,
  onSaveClubSettings,
  updateClubHandler,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  const { state: userState } = useContext(UserContext);

  const isEducator = [RolesMap.TEACHER, RolesMap.ADMINISTRATOR].includes(userState.profile.role);

  const defaultMembersQueryParams = useMemo(
    () => ({
      [ClubsMembersQueryParams.ClUB_ID]: clubId,
      [ClubsMembersQueryParams.PAGE]: 1,
      [ClubsMembersQueryParams.PAGE_SIZE]: 12,
      [ClubsMembersQueryParams.NAME]: '',
      [ClubsMembersQueryParams.STATUS]: ClubsMembershipStatuses.ALL,
    }),
    [clubId],
  );

  const ClubSectionsTabsConfig = useMemo(
    () => ({
      CLUB_POSTS: {
        value: 'CLUB_POSTS',
        label: clubRights[ClubRights.VIEW_EVENTS] ? 'Posts & Events' : 'Posts',
      },
      CLUB_MEMBERS: {
        value: 'CLUB_MEMBERS',
        label: 'Members',
      },
      CLUB_ABOUT: {
        value: 'CLUB_ABOUT',
        label: 'About',
      },
    }),
    [clubRights],
  );

  const [activeTab, setActiveTab] = useState(
    !isEducator && clubData.membershipStatus !== ClubsMembershipStatuses.JOINED
      ? ClubSectionsTabsConfig.CLUB_ABOUT.value
      : ClubSectionsTabsConfig.CLUB_POSTS.value,
  );
  const [membersData, setMembersData] = useState({});
  const [membersQueryParams, setMembersQueryParams] = useState(defaultMembersQueryParams);
  const [isSettingsDialogOpen, setIsSettingsDialogOpen] = useState(false);

  const { studentsInPendingStatus, studentsTotal, totalElements, content: members } = membersData;

  const { getClubsMembersList } = useClubHubService();

  const showTabs = useMemo(
    () => [ClubsStatuses.ACTIVE, ClubsStatuses.INACTIVE].includes(clubData.status),
    [clubData.status],
  );

  const isClubActive = useMemo(() => clubData.status === ClubsStatuses.ACTIVE, [clubData]);

  const tabsChangeHandler = useCallback(
    (e, value) => {
      setActiveTab(value);
      if (
        value === ClubSectionsTabsConfig.CLUB_MEMBERS.value &&
        !deepEqual(defaultMembersQueryParams, membersQueryParams)
      ) {
        setMembersQueryParams(defaultMembersQueryParams);
      }
      GA.logInteraction({
        category: GaCategories.BEHAVIOR,
        action: GaActions.TAB_CLICK,
        label: ClubSectionsTabsConfig[value].label,
      });
    },
    [ClubSectionsTabsConfig, defaultMembersQueryParams, membersQueryParams],
  );

  const loudClubMembersList = useCallback(
    () => getClubsMembersList(membersQueryParams).then((data) => setMembersData(data)),
    [getClubsMembersList, membersQueryParams],
  );

  useEffect(() => {
    if (clubRights[ClubRights.VIEW_MEMBERS_TABLE] && showTabs) {
      loudClubMembersList();
    }
  }, [clubRights, loudClubMembersList, showTabs]);

  const { actionState: isRepliesMessageHidden, onAction: handleReplieslMessage } = useOneTimeAction(
    OneTimeActionsMap.CLUB_SETTINGS_REPLIES,
  );

  const { actionState: isPostsMessageHidden, onAction: handlePostsMessage } = useOneTimeAction(
    OneTimeActionsMap.CLUB_SETTINGS_POSTS,
  );

  const isClubOwner = userState.profile.id === clubData.ownerId;

  return showTabs ? (
    <Box
      className={classnames('ayo-club-hub__tabs', {
        'ayo-club-hub__tabs__educator': clubRights[ClubRights.VIEW_MEMBERS_TABLE],
      })}
      mt={isWidthUpSm ? 8 : 3}
    >
      <Box
        alignItems={isWidthUpSm ? 'center' : 'flex-start'}
        display="flex"
        flexDirection={isWidthUpSm ? 'row' : 'column-reverse'}
        justifyContent="space-between"
      >
        <Tabs onChange={tabsChangeHandler} value={activeTab} variant="outlined">
          <Tab
            disableRipple
            label={<TextWithTooltip title={t(ClubSectionsTabsConfig.CLUB_POSTS.label)} />}
            value={ClubSectionsTabsConfig.CLUB_POSTS.value}
          />
          {clubRights[ClubRights.VIEW_MEMBERS_TABLE] && (
            <Tab
              aria-label={
                studentsInPendingStatus
                  ? `${ClubSectionsTabsConfig.CLUB_MEMBERS.label} ${studentsInPendingStatus} ${t(
                      'Pending approval',
                    )}`
                  : ClubSectionsTabsConfig.CLUB_MEMBERS.label
              }
              disableRipple
              icon={
                studentsInPendingStatus && clubRights[ClubRights.EDIT_CLUB] && isClubActive ? (
                  <TextBadge text={studentsInPendingStatus.toString()} />
                ) : null
              }
              label={t(ClubSectionsTabsConfig.CLUB_MEMBERS.label)}
              value={ClubSectionsTabsConfig.CLUB_MEMBERS.value}
            />
          )}
          <Tab
            disableRipple
            label={t(ClubSectionsTabsConfig.CLUB_ABOUT.label)}
            value={ClubSectionsTabsConfig.CLUB_ABOUT.value}
          />
        </Tabs>
        {clubRights[ClubRights.CHANGE_SETTINGS] && (
          <Box mb={isWidthUpSm ? 0 : 3}>
            <Button
              endIcon={<GearIcon />}
              gaLabel="Club settings"
              onClick={() => setIsSettingsDialogOpen(true)}
            >
              {t('Club settings')}
            </Button>
          </Box>
        )}
      </Box>
      {!isRepliesMessageHidden && clubRights[ClubRights.CHANGE_SETTINGS] && (
        <Box mt={5}>
          <InformationalMessage
            onClick={handleReplieslMessage}
            text={
              <Trans
                components={{ b: <b /> }}
                i18nKey="Switch on the option in Club settings to allow members and their parents to reply to the posts."
              />
            }
          />
        </Box>
      )}
      {!isPostsMessageHidden && clubRights[ClubRights.CHANGE_SETTINGS] && (
        <Box mt={5}>
          <InformationalMessage
            onClick={handlePostsMessage}
            text={
              <Trans
                components={{ b: <b /> }}
                i18nKey="Switch on the option in Club settings to allow members and their parents to post."
              />
            }
          />
        </Box>
      )}
      <Box mt={3}>
        <TabPanel currentTabValue={activeTab} value={ClubSectionsTabsConfig.CLUB_POSTS.value}>
          <ClubPostAndEventsSection
            clubId={clubId}
            clubRights={clubRights}
            isClubOwner={isClubOwner}
          />
        </TabPanel>
        {clubRights[ClubRights.VIEW_MEMBERS_TABLE] && (
          <TabPanel currentTabValue={activeTab} value={ClubSectionsTabsConfig.CLUB_MEMBERS.value}>
            <ClubsMembersSection
              clubId={clubId}
              clubName={clubData.clubName}
              isEditable={clubRights[ClubRights.EDIT_CLUB] && isClubActive}
              members={members}
              membersQueryParams={membersQueryParams}
              onQueryParamsChange={(params) =>
                setMembersQueryParams((state) => ({ ...state, ...params }))
              }
              onStatusChange={loudClubMembersList}
              studentsTotal={studentsTotal}
              totalElements={totalElements}
            />
          </TabPanel>
        )}
        <TabPanel currentTabValue={activeTab} value={ClubSectionsTabsConfig.CLUB_ABOUT.value}>
          <ClubAboutSection
            clubData={clubData}
            clubId={clubId}
            getIsFieldEditable={getIsFieldEditable}
            updateClubHandler={updateClubHandler}
          />
        </TabPanel>
      </Box>
      <ClubSettingsDialog
        isOpen={isSettingsDialogOpen}
        isPostingAllowed={clubData.settings.MEMBERS_PARENTS_POSTING_GRANTED && isClubActive}
        isRepliesAllowed={clubData.settings.COMMENTS_GRANTED && isClubActive}
        onClose={() => setIsSettingsDialogOpen(false)}
        onSubmit={onSaveClubSettings}
      />
    </Box>
  ) : (
    <ClubAboutSection
      clubData={clubData}
      clubId={clubId}
      getIsFieldEditable={getIsFieldEditable}
      updateClubHandler={updateClubHandler}
    />
  );
};

ClubSections.propTypes = {
  clubData: PropTypes.instanceOf(Object).isRequired,
  clubId: PropTypes.number.isRequired,
  clubRights: PropTypes.instanceOf(Object).isRequired,
  getIsFieldEditable: PropTypes.func.isRequired,
  updateClubHandler: PropTypes.func.isRequired,
  onSaveClubSettings: PropTypes.func,
};

ClubSections.defaultProps = {
  onSaveClubSettings: () => {},
};

export default ClubSections;
