import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

import { Card, Typography } from '../../../../atoms';
import { TestTypeNameMap } from '../../../../../constants/enums';

const PortfolioTestScoreCard = ({ examName, score }) => (
  <Card
    className="ayo-portfolio__test-scores-card"
    elevation={0}
    mainContent={
      <Box alignItems="center" display="flex" flexDirection="column" rowGap={1}>
        <Typography align="center" component="h3" variant="body2">
          {TestTypeNameMap[examName]}
        </Typography>
        <Typography component="p" variant="subtitle1">
          {score}
        </Typography>
      </Box>
    }
  />
);

PortfolioTestScoreCard.propTypes = {
  examName: PropTypes.string.isRequired,
  score: PropTypes.string.isRequired,
};

export default PortfolioTestScoreCard;
