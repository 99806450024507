import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { Button } from '../../../../../atoms';
import TranslationEditDialog from '../translation-edit-dialog/TranslationEditDialog';
import { ReactComponent as TranslateIcon } from '../../../../../../resources/icons/create.svg';

const TranslateModerationButton = ({ entity, onTranslationChange }) => {
  const [isDialogOpened, setIsDialogOpened] = useState(false);

  const closeDialog = useCallback(() => {
    setIsDialogOpened(false);
  }, []);

  const openDialog = useCallback(() => {
    setIsDialogOpened(true);
  }, []);

  return (
    <>
      <Button
        aria-label={`Edit ${entity.keys.name.EN_US}`}
        className="translate-button"
        isIconButton
        onClick={openDialog}
      >
        <TranslateIcon />
      </Button>
      <TranslationEditDialog
        isOpen={isDialogOpened}
        moderationEntity={entity}
        onClose={closeDialog}
        onEntityUpdateHandler={onTranslationChange}
      />
    </>
  );
};

TranslateModerationButton.propTypes = {
  entity: PropTypes.shape({ keys: PropTypes.instanceOf(Object) }).isRequired,
  onTranslationChange: PropTypes.func.isRequired,
};

export default TranslateModerationButton;
