import React, { useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Timeline,
  TimelineConnector,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab';
import { Box } from '@mui/material';

import { Button } from '../../../../../../../../../atoms';
import { UserContext } from '../../../../../../../../../../context';
import { ReactComponent as ExpandIcon } from '../../../../../../../../../../resources/icons/chevron_down.svg';
import { ReactComponent as CollapseIcon } from '../../../../../../../../../../resources/icons/chevron_up.svg';
import { ReactComponent as ReplyIcon } from '../../../../../../../../../../resources/icons/reply_arrow.svg';
import RepliesForm from '../replies-form/RepliesForm';
import PostReplyCard, { PostReplyActions } from '../post-reply-card/PostReplyCard';

const PostRepliesBlock = ({
  isRepliesAllowed,
  replies,
  onSave,
  onEdit,
  onDelete,
  postId,
  clubId,
  isClubOwner,
}) => {
  const { t } = useTranslation();
  const [isRepliesCollapsed, setIsRepliesCollapsed] = useState(true);
  const [isReplyFormVisible, setIsReplyFormVisible] = useState(false);

  const replyHandler = useCallback(() => {
    setIsRepliesCollapsed(false);
    setIsReplyFormVisible(!isReplyFormVisible);
  }, [isReplyFormVisible]);

  const { state: userState } = useContext(UserContext);

  return (
    <Box>
      {!isRepliesCollapsed && (
        <Timeline>
          {replies.map((reply) => {
            const isReplyOwner = reply.author.id === userState.profile.id;
            return (
              <TimelineItem key={reply.id}>
                <TimelineSeparator>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineOppositeContent>
                  <Box className="ayo-comment-card__timeline" />
                  <PostReplyCard
                    availableActions={[
                      isReplyOwner && PostReplyActions.EDIT,
                      (isClubOwner || isReplyOwner) && PostReplyActions.DELETE,
                    ].filter(Boolean)}
                    clubId={clubId}
                    hideFormHandler={() => setIsReplyFormVisible(false)}
                    onDeleteHandler={onDelete}
                    onEditHandler={onEdit}
                    postId={postId}
                    reply={reply}
                  />
                </TimelineOppositeContent>
              </TimelineItem>
            );
          })}
          {isReplyFormVisible && (
            <TimelineItem>
              <TimelineSeparator>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineOppositeContent>
                <Box className="ayo-comment-card__timeline" />
                <RepliesForm
                  clubId={clubId}
                  hideFormHandler={() => setIsReplyFormVisible(false)}
                  onSave={onSave}
                  postId={postId}
                  replyHandler={replyHandler}
                />
              </TimelineOppositeContent>
            </TimelineItem>
          )}
        </Timeline>
      )}
      <Box pt={1}>
        {!isReplyFormVisible && (
          <Box display="flex">
            {isRepliesAllowed && (
              <Box mr={2}>
                <Button
                  endIcon={<ReplyIcon />}
                  gaLabel="Reply"
                  onClick={replyHandler}
                  variant="text"
                >
                  {t('Reply')}
                </Button>
              </Box>
            )}
            {!!replies.length && (
              <Button
                endIcon={isRepliesCollapsed ? <ExpandIcon /> : <CollapseIcon />}
                gaLabel={isRepliesCollapsed ? 'View replies' : 'Hide replies'}
                onClick={() => {
                  setIsRepliesCollapsed(!isRepliesCollapsed);
                  setIsReplyFormVisible(false);
                }}
              >
                {isRepliesCollapsed
                  ? `${t('View replies')} (${replies.length})`
                  : t('Hide replies')}
              </Button>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

PostRepliesBlock.propTypes = {
  onSave: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  postId: PropTypes.number.isRequired,
  clubId: PropTypes.number.isRequired,
  isRepliesAllowed: PropTypes.bool,
  replies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      author: {
        id: PropTypes.number,
        name: PropTypes.string,
        role: PropTypes.string,
      },
      createdDate: PropTypes.string,
      edited: PropTypes.bool,
      comment: PropTypes.string,
    }),
  ),
  isClubOwner: PropTypes.bool,
};

PostRepliesBlock.defaultProps = {
  replies: [],
  isRepliesAllowed: false,
  onSave: () => {},
  onEdit: () => {},
  onDelete: () => {},
  isClubOwner: false,
};

export default PostRepliesBlock;
