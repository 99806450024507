import React from 'react';
import { Trans } from 'react-i18next';
import PropTypes from 'prop-types';

import { DisabledControlWithTooltip } from '../../../../moleculas';

const AssignmentNoEditTooltip = ({ children, showTooltip }) => (
  <DisabledControlWithTooltip
    showTooltip={showTooltip}
    title={<Trans components={{ br: <br /> }} i18nKey="No AYO assignment editing tooltip" />}
  >
    {children}
  </DisabledControlWithTooltip>
);

AssignmentNoEditTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  showTooltip: PropTypes.bool,
};

AssignmentNoEditTooltip.defaultProps = {
  showTooltip: false,
};

export default AssignmentNoEditTooltip;
