import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Button, TextField, Typography } from '../../../../atoms';
import { DisplayAvatarEditorImage, STTTextField } from '../../../../moleculas';
import { AvatarEditDialog } from '../../..';
import PortfolioSection from '../../components/portfolio-section/PortfolioSection';
import {
  AttachmentsResourcesTypes,
  InputsValidationErrors,
  InputsValidationRules,
} from '../../../../../constants/enums';
import { emailRE, loginAllowedFilterRE } from '../../../../../constants/regexps';
import { portfolioSectionConfig } from '../../../../../constants/propTypes';
import { useStudentsService } from '../../../../../services';
import { ReactComponent as AcademicCapIcon } from '../../../../../resources/icons/academic_cap.svg';
import { ReactComponent as EnvelopeIcon } from '../../../../../resources/icons/envelope_dark.svg';
import { ReactComponent as PlusIcon } from '../../../../../resources/icons/plus.svg';
import { ReactComponent as CreateIcon } from '../../../../../resources/icons/create.svg';
import defaultAvatarImage from '../../../../../resources/images/default_avatar.svg';

const PortfolioPersonalInformationSection = ({ config, sectionIndex, isLastSection }) => {
  const { t } = useTranslation();
  const [editedPersonalInfo, setEditedPersonalInfo] = useState(null);
  const [personalInfo, setPersonalInfo] = useState(null);
  const [personalDataErrors, setPersonalDataErrors] = useState(null);

  const { updateStudentPortfolioAvatar, deleteStudentPortfolioAvatar } = useStudentsService();

  const {
    onUpdatePositionHandler,
    updatePortfolioHandler,
    isEditMode,
    isVisible,
    reflectionHandler,
    sectionData,
  } = config;

  const personalDataErrorsHandler = useCallback((name, value) => {
    setPersonalDataErrors((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    return value;
  }, []);

  const PersonalInformationInputs = useMemo(
    () => ({
      NAME: {
        id: 'personal-information-name',
        label: 'Your name',
        name: 'name',
        validationHandler: (name, value) =>
          personalDataErrorsHandler(name, value.length > InputsValidationRules.MAX_TITLE_LENGTH),
      },
      EMAIL: {
        id: 'personal-information-email',
        label: 'Email',
        name: 'email',
        validationHandler: (name, value) =>
          personalDataErrorsHandler(
            name,
            (value && !emailRE.test(value)) ||
              value.length > InputsValidationRules.MAX_TITLE_LENGTH,
          ),
      },
      SCHOOL: {
        id: 'personal-information-school',
        label: 'School',
        name: 'school',
      },
      PHONE: {
        id: 'personal-information-phone',
        label: 'Phone number',
        name: 'phone',
        validationHandler: (name, value) =>
          personalDataErrorsHandler(
            name,
            value && value.replace(/\D/g, '').length < InputsValidationRules.MIN_PHONE_LENGTH,
          ),
      },
      SUMMARY: {
        id: 'personal-information-summary',
        label: 'Add a few words about yourself',
        name: 'summary',
        validationHandler: (name, value) =>
          personalDataErrorsHandler(name, value.length > InputsValidationRules.MAX_INPUT_LENGTH),
      },
    }),
    [personalDataErrorsHandler],
  );

  useEffect(() => {
    setEditedPersonalInfo(sectionData);
    setPersonalInfo(sectionData);
  }, [sectionData]);

  const onPersonalInformationChange = useCallback(
    (e) => {
      const { value, name } = e.target;
      const newValue =
        name === PersonalInformationInputs.EMAIL.name ? value.trim() : value.trimStart();
      if (personalDataErrors && personalDataErrors[name]) {
        PersonalInformationInputs[name.toUpperCase()].validationHandler(name, value);
      }
      setEditedPersonalInfo((prevState) => ({
        ...prevState,
        [name]: newValue,
      }));
    },
    [PersonalInformationInputs, personalDataErrors],
  );

  const onPersonalInformationSave = useCallback(
    (e) => {
      const { name, value } = e.target;
      const isValid = !PersonalInformationInputs[name.toUpperCase()].validationHandler(name, value);
      if (
        sectionData[name] !== editedPersonalInfo[name] &&
        isValid &&
        (sectionData[name] || editedPersonalInfo[name])
      ) {
        const personalData = {
          ...personalInfo,
          [name]: value,
        };
        setPersonalInfo(personalData);
        updatePortfolioHandler({
          ...personalData,
          entities: [],
          studentName: personalData.name,
        });
      }
    },
    [
      PersonalInformationInputs,
      editedPersonalInfo,
      personalInfo,
      sectionData,
      updatePortfolioHandler,
    ],
  );

  const [isAvatarEdit, setIsAvatarEdit] = useState(false);

  const {
    metaData: { avatar, updateAvatar, studentId, portfolioId },
  } = config;

  const onAvatarSubmit = useCallback(
    (newAvatar, newAvatarTransforms) => {
      updateAvatar({ image: newAvatar, transforms: newAvatarTransforms });
    },
    [updateAvatar],
  );

  const onAvatarEditClose = useCallback(() => setIsAvatarEdit(false), []);

  if (!isVisible) {
    return null;
  }

  return (
    <Box>
      <PortfolioSection
        body={
          isEditMode ? (
            editedPersonalInfo && (
              <Box mt={2}>
                <Box mb={5}>
                  <Typography component="h3" paragraph variant="subtitle2">
                    {t('Personal information')}
                  </Typography>
                  <Grid container direction="column" spacing={3} xs={6}>
                    <Grid item>
                      <STTTextField
                        error={personalDataErrors?.name}
                        fullWidth
                        gaLabel={`Portfolio personal information - ${PersonalInformationInputs.NAME.name}`}
                        InputLabelProps={{ id: PersonalInformationInputs.NAME.id }}
                        inputProps={{
                          'aria-labelledby': PersonalInformationInputs.NAME.id,
                          autoComplete: 'off',
                        }}
                        inputRE={loginAllowedFilterRE}
                        label={t(PersonalInformationInputs.NAME.label)}
                        maxLength={InputsValidationRules.MAX_TITLE_LENGTH}
                        name={PersonalInformationInputs.NAME.name}
                        onBlur={onPersonalInformationSave}
                        onChange={onPersonalInformationChange}
                        value={editedPersonalInfo.name || ''}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        disabled
                        fullWidth
                        InputLabelProps={{ id: PersonalInformationInputs.SCHOOL.id }}
                        inputProps={{
                          'aria-labelledby': PersonalInformationInputs.SCHOOL.id,
                        }}
                        label={t(PersonalInformationInputs.SCHOOL.label)}
                        value={editedPersonalInfo.school}
                      />
                    </Grid>
                    <Grid item>
                      <Box mt={2}>
                        <Typography component="h3" paragraph variant="subtitle2">
                          {t('Contacts')}
                        </Typography>
                      </Box>
                      <TextField
                        error={personalDataErrors?.email}
                        fullWidth
                        gaLabel={`Portfolio personal information - ${PersonalInformationInputs.EMAIL.name}`}
                        helperText={
                          personalDataErrors?.email &&
                          (!emailRE.test(editedPersonalInfo.email)
                            ? InputsValidationErrors(t).EMAIL_ERROR_TEXT
                            : InputsValidationErrors(t, InputsValidationRules.MAX_TITLE_LENGTH)
                                .MAX_ERROR_TEXT)
                        }
                        InputLabelProps={{ id: PersonalInformationInputs.EMAIL.id }}
                        inputProps={{
                          'aria-labelledby': PersonalInformationInputs.EMAIL.id,
                          autoComplete: 'off',
                        }}
                        label={t(PersonalInformationInputs.EMAIL.label)}
                        name={PersonalInformationInputs.EMAIL.name}
                        onBlur={onPersonalInformationSave}
                        onChange={onPersonalInformationChange}
                        value={editedPersonalInfo.email || ''}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box>
                  <Box>
                    <Typography component="h4" paragraph variant="subtitle2">
                      {t('Summary')}
                    </Typography>
                    <Box
                      alignItems="flex-end"
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                    >
                      <Box width="100%">
                        <STTTextField
                          error={personalDataErrors?.summary}
                          fullWidth
                          gaLabel={`Portfolio personal information - ${PersonalInformationInputs.SUMMARY.name}`}
                          InputLabelProps={{ id: PersonalInformationInputs.SUMMARY.id }}
                          inputProps={{
                            'aria-labelledby': PersonalInformationInputs.SUMMARY.id,
                            autoComplete: 'off',
                          }}
                          label={t(PersonalInformationInputs.SUMMARY.label)}
                          maxLength={InputsValidationRules.MAX_INPUT_LENGTH}
                          multiline
                          name={PersonalInformationInputs.SUMMARY.name}
                          onBlur={onPersonalInformationSave}
                          onChange={onPersonalInformationChange}
                          rowsCount={4}
                          value={editedPersonalInfo.summary || ''}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            )
          ) : (
            <Box>
              <Box alignItems="center" display="flex" flexDirection="row">
                <Box mr={1}>
                  <AcademicCapIcon />
                </Box>
                <Typography variant="body2">{sectionData?.school}</Typography>
              </Box>
              <Box my={5}>
                <Typography component="h3" paragraph variant="subtitle2">
                  {t('Contacts')}
                </Typography>
                {sectionData?.email ? (
                  <Box alignItems="center" display="flex" flexDirection="row">
                    <Box mr={1}>
                      <EnvelopeIcon />
                    </Box>
                    <Typography variant="body2">{sectionData?.email}</Typography>
                  </Box>
                ) : (
                  <Typography variant="body2">{t('No contacts provided so far')}</Typography>
                )}
              </Box>
              <Typography component="h3" paragraph variant="subtitle2">
                {t('Summary')}
              </Typography>
              {sectionData?.summary ? (
                <Typography variant="body2">{sectionData?.summary}</Typography>
              ) : (
                <Typography variant="body2">{t('No summary provided so far')}</Typography>
              )}
            </Box>
          )
        }
        className={`${classNames('ayo-personal-information-section', {
          'ayo-personal-information-section--view-mode': !isEditMode,
        })}`}
        isEditMode={isEditMode}
        isLastSection={isLastSection}
        mediaContent={
          <Box>
            <DisplayAvatarEditorImage
              avatar={avatar?.image}
              avatarPlaceholder={defaultAvatarImage}
              avatarSize={250}
              avatarTransforms={avatar?.transforms}
            />
            {isEditMode && (
              <Box mt={2}>
                <Button
                  endIcon={avatar?.image ? <CreateIcon /> : <PlusIcon />}
                  fullWidth
                  gaLabel={avatar?.image ? 'Edit photo' : 'Add photo'}
                  onClick={() => setIsAvatarEdit(true)}
                >
                  {avatar?.image ? t('Edit photo') : t('Add photo')}
                </Button>
              </Box>
            )}
          </Box>
        }
        onChangeReflection={reflectionHandler}
        onUpdatePositionHandler={onUpdatePositionHandler}
        reflection={sectionData.reflection}
        sectionIndex={sectionIndex}
        title={isEditMode ? 'About' : sectionData?.name}
      />
      <AvatarEditDialog
        avatarType="Portfolio"
        currentImage={avatar?.image}
        currentTransforms={avatar?.transforms}
        deleteAvatarHandler={() => deleteStudentPortfolioAvatar(studentId, portfolioId)}
        isOpen={isAvatarEdit}
        onClose={onAvatarEditClose}
        onSubmit={onAvatarSubmit}
        ownerId={studentId}
        resourceId={portfolioId}
        resourceType={AttachmentsResourcesTypes.PORTFOLIO_AVATAR}
        showGuidelines
        studentId={studentId}
        updateAvatarHandler={(imageData) =>
          updateStudentPortfolioAvatar(studentId, portfolioId, imageData)
        }
        visibilityInfoCaptionTitle="Your photo is visible for your parents and educators in AYO."
      />
    </Box>
  );
};

PortfolioPersonalInformationSection.propTypes = {
  config: portfolioSectionConfig(
    PropTypes.number,
    PropTypes.shape({
      email: PropTypes.string,
      isInitialState: PropTypes.bool,
      name: PropTypes.string,
      phone: PropTypes.string,
      reflection: PropTypes.string,
      school: PropTypes.string,
      settings: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
      summary: PropTypes.string,
    }),
  ),
  sectionIndex: PropTypes.number.isRequired,
  isLastSection: PropTypes.number.isRequired,
};

PortfolioPersonalInformationSection.defaultProps = {
  config: null,
};

export default PortfolioPersonalInformationSection;
