import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

import { Typography } from '../../../../../../atoms';
import { Dropdown } from '../../../../../../moleculas';
import { EventKeys } from '../../../../../../../constants/events';
import { SharingLevels } from '../../../../../../../constants/family-feed';
import DistrictsBlock from '../../../../../sharing-groups/districts-block/DistrictsBlock';
import RolesVisibilityPicker from '../../../../../sharing-groups/roles-visibility-picker/RolesVisibilityPicker';
import CampusesBlock from '../../../../../sharing-groups/campuses-block/CampusesBlock';
import ClassesBlock from '../../../../../sharing-groups/classes-block/ClassesBlock';
import PersonalizedBlock from '../../../../../sharing-groups/personalized-block/PersonalizedBlock';

const checkEntitiesOptions = (entities) =>
  entities && Array.isArray(entities) ? !!entities.length : !!Object.values(entities).flat().length;
const EventVisibilitySettings = ({
  onSelectedEntitiesChange,
  onSharingGroupsChange,
  onSharingLevelChange,
  sharedData,
  sharingLevelOptions,
}) => {
  const { t } = useTranslation();

  const dropDropdownOptions = useMemo(
    () =>
      Object.values(sharingLevelOptions).map(({ sharingLevel }) => ({
        label: t(sharingLevel.label),
        value: sharingLevel.value,
      })),
    [sharingLevelOptions, t],
  );

  const selectionBlock = useMemo(
    () => ({
      [SharingLevels.DISTRICT.value]: (
        <DistrictsBlock
          columnsCount={1}
          districts={sharingLevelOptions[sharedData[EventKeys.SHARING_LEVEL]].selectedEntities}
          onChange={onSelectedEntitiesChange}
          selected={sharedData[EventKeys.SELECTED_ENTITIES]}
        />
      ),
      [SharingLevels.CAMPUS.value]: (
        <CampusesBlock
          columnsCount={1}
          onChange={onSelectedEntitiesChange}
          outlinedDropdown
          schools={sharingLevelOptions[sharedData[EventKeys.SHARING_LEVEL]].selectedEntities}
          selected={sharedData[EventKeys.SELECTED_ENTITIES]}
        />
      ),
      [SharingLevels.CLASS.value]: (
        <ClassesBlock
          classes={sharingLevelOptions[sharedData[EventKeys.SHARING_LEVEL]].selectedEntities}
          columnsCount={1}
          onChange={onSelectedEntitiesChange}
          selected={sharedData[EventKeys.SELECTED_ENTITIES]}
        />
      ),
      [SharingLevels.PERSONALIZED.value]: (
        <PersonalizedBlock
          columnsCount={1}
          onChange={onSelectedEntitiesChange}
          outlinedSearch
          selected={sharedData[EventKeys.SELECTED_ENTITIES]}
          students={sharingLevelOptions[sharedData[EventKeys.SHARING_LEVEL]].selectedEntities}
        />
      ),
    }),
    [onSelectedEntitiesChange, sharedData, sharingLevelOptions],
  );

  return (
    <Box className="ayo-events-block__dialog__visibility-settings" px={0.5}>
      <Typography variant="body2">{t('Select an option from the dropdown')}</Typography>
      <Box mb={5} mt={2}>
        <Dropdown
          fullWidth
          gaLabel="Event sharing level"
          handleChange={(e) => onSharingLevelChange(e.target.value)}
          options={dropDropdownOptions}
          outlined
          value={sharedData[EventKeys.SHARING_LEVEL]}
        />
      </Box>
      {sharedData[EventKeys.SHARING_LEVEL] && selectionBlock[sharedData[EventKeys.SHARING_LEVEL]]}
      {checkEntitiesOptions(
        sharingLevelOptions[sharedData[EventKeys.SHARING_LEVEL]].selectedEntities,
      ) && (
        <RolesVisibilityPicker
          onChange={onSharingGroupsChange}
          sharingGroups={sharedData[EventKeys.SHARING_GROUPS]}
          sharingLevel={sharedData[EventKeys.SHARING_LEVEL]}
          title="Who should be able to see the event"
        />
      )}
    </Box>
  );
};

EventVisibilitySettings.propTypes = {
  onSelectedEntitiesChange: PropTypes.func.isRequired,
  onSharingGroupsChange: PropTypes.func.isRequired,
  onSharingLevelChange: PropTypes.func.isRequired,
  sharedData: PropTypes.instanceOf(Object).isRequired,
  sharingLevelOptions: PropTypes.instanceOf(Object).isRequired,
};

export default EventVisibilitySettings;
