import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Box } from '@mui/material';

import { Card, Typography, Button, ActionsMenu } from '../../../../atoms';
import { DeleteItemWrapper, InitiativeEmptyStateBlock } from '../../../../moleculas';
import { useStudentsService } from '../../../../../services';
import { AppActions, AppContext, UserContext } from '../../../../../context';
import { portfolioSectionConfig } from '../../../../../constants/propTypes';
import { EmploymentTypesMap, PortfolioSectionTypes } from '../../../../../constants/enums';
import {
  getFormattedDate,
  PortfolioItemsToAddData,
  sortArrayByKey,
  transformFileUploadData,
} from '../../../../../utils';
import { ReactComponent as EmploymentEmptyStateIllustration } from '../../../../../resources/images/employment_empty_state.svg';
import { ReactComponent as PlusIcon } from '../../../../../resources/icons/plus.svg';
import { ReactComponent as EditIcon } from '../../../../../resources/icons/create.svg';
import { ReactComponent as DatePickerIcon } from '../../../../../resources/icons/date_picker_black.svg';
import { ReactComponent as ReflectionIcon } from '../../../../../resources/icons/reflection_black.svg';
import { ReactComponent as BuildingIcon } from '../../../../../resources/icons/building_bank.svg';
import { ReactComponent as AddIcon } from '../../../../../resources/icons/add.svg';
import PortfolioSection from '../../components/portfolio-section/PortfolioSection';
import PortfolioAttachments from '../../components/portfolio-attachments/PortfolioAttachments';

import PortfolioCreateEmploymentDialog from './portfolio-create-employment-dialog/PortfolioCreateEmploymentDialog';

const AddEmploymentButton = ({ setIsDialogOpen }) => {
  const { t } = useTranslation();
  return (
    <Button endIcon={<PlusIcon />} gaLabel="Add employment" onClick={() => setIsDialogOpen(true)}>
      {t('Add employment')}
    </Button>
  );
};

AddEmploymentButton.propTypes = {
  setIsDialogOpen: PropTypes.func.isRequired,
};

const PortfolioEmploymentsSection = ({ config, sectionIndex, isLastSection }) => {
  const { t, i18n } = useTranslation();
  const {
    addPortfolioItemHandler,
    deletePortfolioItemHandler,
    updatePortfolioHandler,
    hasData,
    isEditMode,
    isVisible,
    reflectionHandler,
    sectionData,
    metaData,
    updatePortfolioMetaData,
    onUpdatePositionHandler,
  } = config;
  const { entities, reflection } = sectionData;
  const [portfolioEmployments, setPortfolioEmployments] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [employmentToEdit, setEmploymentToEdit] = useState(null);

  const { postStudentEmployment, updateStudentEmployment } = useStudentsService();

  const { state: userState } = useContext(UserContext);
  const { dispatch: dispatchAppState } = useContext(AppContext);
  const studentId = userState.profile?.id;

  const saveEmploymentHandler = useCallback(
    (requestBody) => {
      postStudentEmployment(transformFileUploadData(requestBody), studentId)
        .then((data) => {
          addPortfolioItemHandler(null, [data], 'id', sectionData);
          updatePortfolioMetaData([...metaData, data]);
        })
        .catch(() => {
          dispatchAppState({
            type: AppActions.SET_SNACKBAR_STATUS,
            data: {
              text: t('AYO couldn’t save your input Please try once more'),
              type: 'error',
            },
          });
        });
    },
    [
      dispatchAppState,
      t,
      addPortfolioItemHandler,
      metaData,
      postStudentEmployment,
      sectionData,
      studentId,
      updatePortfolioMetaData,
    ],
  );

  const updateEmploymentHandler = useCallback(
    (requestBody, idToEdit) => {
      updateStudentEmployment(transformFileUploadData(requestBody), studentId, idToEdit)
        .then((data) => {
          const employmentIndex = entities.findIndex((employment) => employment.id === data.id);
          updatePortfolioHandler({
            ...sectionData,
            entities: Object.assign([...sectionData.entities], {
              [employmentIndex]: {
                ...data,
              },
            }),
          });
          const employmentMetaDataIndex = metaData.findIndex(({ id }) => id === data.id);
          updatePortfolioMetaData(
            Object.assign([...metaData], {
              [employmentMetaDataIndex]: {
                ...data,
              },
            }),
          );
        })
        .catch(() => {
          dispatchAppState({
            type: AppActions.SET_SNACKBAR_STATUS,
            data: {
              text: t('AYO couldn’t save your input Please try once more'),
              type: 'error',
            },
          });
        });
    },
    [
      dispatchAppState,
      t,
      entities,
      metaData,
      sectionData,
      studentId,
      updatePortfolioHandler,
      updatePortfolioMetaData,
      updateStudentEmployment,
    ],
  );

  useEffect(() => {
    setPortfolioEmployments(sortArrayByKey(entities, 'startDate', 'date', 'increase'));
    setEmploymentToEdit(null);
  }, [entities]);

  const employmentsToAdd = useMemo(
    () =>
      PortfolioItemsToAddData[PortfolioSectionTypes.PORTFOLIO__EMPLOYMENT](
        entities,
        metaData,
        'id',
      ).map(({ id, role }) => ({ id: id.toString(), name: role })),
    [entities, metaData],
  );

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <PortfolioSection
        addItemsActionsConfig={
          hasData && isEditMode
            ? {
                title: 'Add employment',
                items: [
                  {
                    text: t('Add new'),
                    icon: AddIcon,
                    handler: () => setIsDialogOpen(true),
                    id: 'add-new-employment',
                    gaLabel: 'Add new employment',
                  },
                ],
              }
            : null
        }
        body={
          <Box>
            {portfolioEmployments.map((employment) => (
              <DeleteItemWrapper
                key={employment.id}
                disabled={!isEditMode}
                gaLabel="Remove employment"
                mainContent={
                  <Card
                    className={`${classNames('ayo-portfolio__employments-section__card', {
                      'ayo-portfolio__employments-section__card__cropped': !isEditMode,
                    })}`}
                    mainContent={
                      <Box className="ayo-portfolio__employments-section__card__content">
                        <Box width="100%">
                          <Box alignItems="center" display="flex" justifyContent="space-between">
                            <Typography component="h3" variant="subtitle1">
                              {employment.role}
                            </Typography>
                            <Box>
                              {isEditMode && (
                                <ActionsMenu
                                  gaLabel="Employment actions"
                                  id={`employment-actions-${employment.id}`}
                                  menuItems={[
                                    {
                                      text: t('Edit'),
                                      icon: EditIcon,
                                      handler: () => {
                                        setEmploymentToEdit(employment);
                                        setIsDialogOpen(true);
                                      },
                                      id: `edit-${employment.id}`,
                                      gaLabel: 'Edit employment',
                                    },
                                  ]}
                                />
                              )}
                            </Box>
                          </Box>
                          <Box display="flex" my={2}>
                            <Box>
                              <BuildingIcon />
                            </Box>
                            <Typography
                              className="ayo-portfolio__employments-section__card__content__text"
                              variant="caption"
                            >
                              {employment.organization}
                            </Typography>
                          </Box>
                          <Box display="flex" my={2}>
                            <Box>
                              <BuildingIcon />
                            </Box>
                            <Typography
                              className="ayo-portfolio__employments-section__card__content__text"
                              variant="caption"
                            >
                              {t(EmploymentTypesMap[employment.employmentType])}
                            </Typography>
                          </Box>
                          {employment.startDate && (
                            <Box display="flex" my={2}>
                              <Box>
                                <DatePickerIcon />
                              </Box>
                              <Typography
                                className="ayo-portfolio__employments-section__card__content__text"
                                variant="caption"
                              >
                                {`${getFormattedDate(employment.startDate, i18n.language)} - ${
                                  employment.currentPosition || !employment?.endDate
                                    ? t('Present')
                                    : getFormattedDate(employment?.endDate, i18n.language)
                                }`}
                              </Typography>
                            </Box>
                          )}
                          {employment?.description && (
                            <Box display="flex" my={2}>
                              <Box>
                                <ReflectionIcon />
                              </Box>
                              <Typography
                                className="ayo-portfolio__employments-section__card__content__text"
                                variant="caption"
                              >
                                {employment.description}
                              </Typography>
                            </Box>
                          )}
                          {!!employment?.attachments.length && (
                            <Box width="82%">
                              <PortfolioAttachments
                                attachments={employment.attachments}
                                gridItemBreakpoint={3}
                              />
                            </Box>
                          )}
                        </Box>
                      </Box>
                    }
                  />
                }
                onDelete={() => {
                  deletePortfolioItemHandler(
                    portfolioEmployments,
                    'id',
                    employment.id,
                    sectionData,
                  );
                }}
              />
            ))}
          </Box>
        }
        className={`${classNames({
          'ayo-portfolio__employments-section': hasData,
        })}`}
        customButtom={<AddEmploymentButton setIsDialogOpen={setIsDialogOpen} />}
        description={isEditMode ? 'Here, you can add your work experience' : ''}
        emptyState={
          !hasData ? (
            <InitiativeEmptyStateBlock
              body={isEditMode && t('But you can change this now')}
              customButton={isEditMode && <AddEmploymentButton setIsDialogOpen={setIsDialogOpen} />}
              illustration={<EmploymentEmptyStateIllustration />}
              title={t('No employment added so far')}
            />
          ) : null
        }
        isEditMode={isEditMode}
        isLastSection={isLastSection}
        itemsToAdd={employmentsToAdd}
        onAddItems={(itemsToAdd) =>
          addPortfolioItemHandler(metaData, itemsToAdd, 'id', sectionData)
        }
        onChangeReflection={reflectionHandler}
        onUpdatePositionHandler={onUpdatePositionHandler}
        reflection={reflection}
        sectionIndex={sectionIndex}
        sectionKey={PortfolioSectionTypes.PORTFOLIO__EMPLOYMENT}
        showActionsMenu={hasData && isEditMode}
        title={t('Employment')}
      />
      <PortfolioCreateEmploymentDialog
        addPortfolioItemHandler={addPortfolioItemHandler}
        isOpen={isDialogOpen}
        onClose={() => {
          setIsDialogOpen(false);
          setEmploymentToEdit(null);
        }}
        onEdit={updateEmploymentHandler}
        onSave={saveEmploymentHandler}
        valuesToEdit={employmentToEdit}
      />
    </>
  );
};

PortfolioEmploymentsSection.propTypes = {
  config: portfolioSectionConfig(PropTypes.instanceOf(Object)),
  sectionIndex: PropTypes.number.isRequired,
  isLastSection: PropTypes.number.isRequired,
};

PortfolioEmploymentsSection.defaultProps = {
  config: {},
};

export default PortfolioEmploymentsSection;
