import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import PropTypes from 'prop-types';

import { Typography } from '../../../../../../atoms';
import { Dropdown, InformationalCaption, STTTextField } from '../../../../../../moleculas';
import { InputsValidationRules, LeadershipAttributes } from '../../../../../../../constants/enums';

const GoalInputsId = {
  NAME: 'goal-name',
  DESCRIPTION: 'goal-description',
  LEADERSHIP_ATTRIBUTE_KEY: 'leadership-attribute-key',
};

const GoalInputs = ({
  name,
  description,
  nameErrorMessage,
  descriptionErrorMessage,
  onInputsChange,
  isCreating,
  showDescriptionInfo,
  isAttributeDropdownDisabled,
  leadershipAttributeKey,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const options = Object.values(LeadershipAttributes).map((attribute) => ({
    value: attribute,
    label: t(attribute),
  }));

  return (
    <Box
      alignItems="flex-end"
      className="ayo-goal-inputs"
      display="flex"
      flexDirection="row"
      mb={2}
    >
      <Box width="100%">
        <Typography component="h2" paragraph variant="subtitle1">
          {t('Goal Statement')}
        </Typography>
        <Box
          maxWidth={!isWidthUpSm && showDescriptionInfo ? '90%' : '100%'}
          pb={isWidthUpSm ? 3 : 2}
        >
          <STTTextField
            className="ayo-goal-inputs__text-field"
            error={nameErrorMessage !== ''}
            gaLabel={isCreating ? 'Create goal name' : 'Edit goal name'}
            helperText={nameErrorMessage}
            InputLabelProps={{ id: GoalInputsId.NAME }}
            inputProps={{
              'aria-labelledby': GoalInputsId.NAME,
              autoComplete: 'off',
            }}
            label={t(showDescriptionInfo ? 'The goal is' : 'My goal is')}
            maxLength={InputsValidationRules.MAX_TITLE_LENGTH}
            name="name"
            onChange={onInputsChange}
            required
            value={name}
          />
        </Box>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          pb={isWidthUpSm ? 3 : 2}
        >
          <Box minWidth={!isWidthUpSm && showDescriptionInfo ? '90%' : '100%'}>
            <STTTextField
              className="ayo-goal-inputs__text-field"
              error={descriptionErrorMessage !== ''}
              gaLabel={isCreating ? 'Create goal description' : 'Edit goal description'}
              helperText={t(descriptionErrorMessage)}
              InputLabelProps={{ id: GoalInputsId.DESCRIPTION }}
              inputProps={{
                'aria-labelledby': GoalInputsId.DESCRIPTION,
                autoComplete: 'off',
              }}
              label={t('Description')}
              maxLength={InputsValidationRules.MAX_INPUT_LENGTH}
              name="description"
              onChange={onInputsChange}
              value={description}
            />
          </Box>
          {showDescriptionInfo && (
            <Box ml={1} zIndex={1}>
              <InformationalCaption
                showTooltip
                title="Description will help the student to understand what is the expected result of the goal"
              />
            </Box>
          )}
        </Box>
        <Box pb={isWidthUpSm ? 3 : 2}>
          {isAttributeDropdownDisabled ? (
            <Typography variant="body2">
              {t('The goal is already linked to a Leadership Attribute')}
            </Typography>
          ) : (
            <Typography variant="body2">
              {t('If the goal is related to a Leadership Attribute select it here')}
            </Typography>
          )}
          <Box pb={3} pt={2}>
            <Dropdown
              defaultValue={leadershipAttributeKey}
              disabled={isAttributeDropdownDisabled}
              fullWidth
              gaLabel="Goal related Attribute change"
              handleChange={onInputsChange}
              label={t('Select an Attribute')}
              name="leadershipAttributeKey"
              options={[
                {
                  value: null,
                  label: t('None'),
                },
                ...options,
              ]}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

GoalInputs.propTypes = {
  leadershipAttributeKey: PropTypes.string,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  nameErrorMessage: PropTypes.string.isRequired,
  descriptionErrorMessage: PropTypes.string.isRequired,
  onInputsChange: PropTypes.func.isRequired,
  isCreating: PropTypes.bool.isRequired,
  showDescriptionInfo: PropTypes.bool.isRequired,
  isAttributeDropdownDisabled: PropTypes.bool,
};

GoalInputs.defaultProps = {
  leadershipAttributeKey: null,
  isAttributeDropdownDisabled: null,
};

export default GoalInputs;
