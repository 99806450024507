import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Tab, useMediaQuery, useTheme } from '@mui/material';
import IntersectionObserver from 'react-intersection-observer';
import PropTypes from 'prop-types';

import { Button, Tabs, Typography } from '../../../../../../../atoms';
import {
  InitiativeEmptyStateBlock,
  Loader,
  NewFeatureHotspot,
} from '../../../../../../../moleculas';
import { ConfirmDialog, PostCard } from '../../../../../../../organisms';
import { AppActions, AppContext, UserContext } from '../../../../../../../../context';
import { GaActions, GaCategories, RolesMap } from '../../../../../../../../constants/enums';
import {
  ClubPostSharingGroups,
  ClubsPostSharingGroupsKeys,
} from '../../../../../../../../constants/family-feed';
import { ClubRights } from '../../../../../../../../constants/club-hub';
import { useClubHubService } from '../../../../../../../../services';
import { GA, getFormDataObject, getSharingGroupsLabel } from '../../../../../../../../utils';
import { ReactComponent as ClubsPostEmptyState } from '../../../../../../../../resources/images/club_hub_posts_empty_state.svg';
import {
  PostActions,
  PostBody,
  PostFooter,
  PostHeader,
} from '../../../../../../../organisms/post-card/PostCard';
import { NewFeaturesIdsMap } from '../../../../../../../../tours/common/NewFeaturesItemsProvider';

import ClubPostForm from './components/club-post-form/ClubPostForm';
import EditClubPostDialog from './components/edit-club-post-dialog/EditClubPostDialog';
import PostRepliesBlock from './components/post-replies-block/PostRepliesBlock';

const DEFAULT_PAGE_SIZE = 20;

const clubPostsFilters = [
  {
    label: 'All',
    labelMobile: 'All',
    value: ClubsPostSharingGroupsKeys.ALL,
  },
  {
    label: 'Members only',
    labelMobile: 'Members',
    value: ClubsPostSharingGroupsKeys.MEMBERS,
  },
  {
    label: 'Parents only',
    labelMobile: 'Parents',
    value: ClubsPostSharingGroupsKeys.GUARDIANS,
  },
];

const EmptyStateTitleConfig = {
  [RolesMap.STUDENT]: 'No posts created for this club yet',
  [RolesMap.TEACHER]: 'No posts created yet',
  [RolesMap.ADMINISTRATOR]: 'No posts created yet',
  [RolesMap.GUARDIAN]: 'No public posts available for this club yet. Stay tuned for some!',
};

const ClubPostAndEventsSection = ({ clubId, clubRights, isClubOwner }) => {
  const { getClubsPosts, deleteClubsPost, postClubsPost, updateClubPost } = useClubHubService();
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const [posts, setClubPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPosts, setTotalPosts] = useState(null);
  const [deletingPost, setDeletingPost] = useState(null);
  const [postToEdit, setPostToEdit] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [activeFilter, setActiveFilter] = useState(ClubsPostSharingGroupsKeys.ALL);

  const { state: userState } = useContext(UserContext);
  const { dispatch: dispatchAppState } = useContext(AppContext);

  const isStudent = userState?.profile.role === RolesMap.STUDENT;

  useEffect(() => {
    getClubsPosts(clubId, 1, DEFAULT_PAGE_SIZE, true, activeFilter).then((data) => {
      setClubPosts(data.clubPosts);
      setTotalPosts(data.totalElements);
    });
  }, [getClubsPosts, clubId, activeFilter]);

  const editDialogHandler = useCallback(
    (postId) => setPostToEdit(posts.find((post) => post.id === postId)),
    [posts],
  );

  const isDeletingOwnPost = deletingPost?.author.id === userState.profile.id;

  const onPostDeleteHandler = useCallback(() => {
    deleteClubsPost(deletingPost.id, clubId)
      .then(() => {
        const updatedPosts = posts.filter((post) => post.id !== deletingPost.id);
        setClubPosts(updatedPosts);
        setTotalPosts((prevState) => prevState - 1);
        dispatchAppState({
          type: AppActions.SET_SNACKBAR_STATUS,
          data: {
            text: t('The post has been deleted.'),
            type: 'delete',
          },
        });
      })
      .catch(() => {
        dispatchAppState({
          type: AppActions.SET_SNACKBAR_STATUS,
          data: {
            text: t(
              isDeletingOwnPost
                ? isStudent
                  ? 'Failed to delete your post. Please try once more(student)'
                  : 'Failed to delete your post. Please try once more.'
                : 'AYO couldn’t delete the post. Please try once more.',
            ),
            type: 'error',
          },
        });
      });
    setDeletingPost(null);
  }, [
    posts,
    deletingPost,
    clubId,
    deleteClubsPost,
    dispatchAppState,
    t,
    isStudent,
    isDeletingOwnPost,
  ]);

  const onUpdatePostHandler = useCallback(
    (postBody) =>
      updateClubPost(getFormDataObject(postBody), postBody.id, clubId)
        .then((updatedPost) => {
          const updatedPostIndex = posts.findIndex((data) => data.id === postBody.id);
          const newPosts = [...posts];
          newPosts[updatedPostIndex] = updatedPost;
          setClubPosts(newPosts);
          dispatchAppState({
            type: AppActions.SET_SNACKBAR_STATUS,
            data: {
              text: t(
                isStudent
                  ? 'Thanks for your changes! Your input has been saved(student)'
                  : 'Thanks for your changes! Your input has been saved(teacher)',
              ),
              type: 'success',
            },
          });
          return true;
        })
        .catch(() => {
          dispatchAppState({
            type: AppActions.SET_SNACKBAR_STATUS,
            data: {
              text: t(
                isStudent
                  ? 'AYO couldn’t save your edits. Please try once more(student)'
                  : 'AYO couldn’t save your edits. Please try once more(teacher)',
              ),
              type: 'error',
            },
          });
          return false;
        }),
    [posts, clubId, dispatchAppState, t, updateClubPost, isStudent],
  );

  const addPost = useCallback(
    (body) =>
      postClubsPost(getFormDataObject(body), clubId)
        .then((createdPosts) => {
          setClubPosts((prevState) => [...createdPosts, ...prevState]);
          setTotalPosts((prevState) => prevState + createdPosts.length);
          dispatchAppState({
            type: AppActions.SET_SNACKBAR_STATUS,
            data: {
              text: t('Your post has been successfully published.'),
              type: 'success',
            },
          });
          return true;
        })
        .catch(() => {
          dispatchAppState({
            type: AppActions.SET_SNACKBAR_STATUS,
            data: {
              text: `${t('Something went wrong')}. ${t('Please try once more')}`,
              type: 'error',
              action: (
                <Button autoFocus gaLabel="Retry" onClick={() => addPost(body)}>
                  {t('Retry')}
                </Button>
              ),
            },
          });

          return false;
        }),
    [clubId, dispatchAppState, postClubsPost, t],
  );

  const loadMorePosts = useCallback(() => {
    if (posts?.length < totalPosts) {
      setIsLoading(true);
      getClubsPosts(clubId, currentPage + 1, DEFAULT_PAGE_SIZE, false, activeFilter).then(
        (data) => {
          setClubPosts((prevPosts) => [...prevPosts, ...data.clubPosts]);
          setCurrentPage((prevPage) => prevPage + 1);
          setIsLoading(false);
        },
      );
    }
  }, [clubId, getClubsPosts, posts?.length, totalPosts, currentPage, activeFilter]);

  const tabsChangeHandler = useCallback((e, value) => {
    setActiveFilter(value);
    GA.logInteraction({
      category: GaCategories.BEHAVIOR,
      action: GaActions.TAB_CLICK,
      label: `club post filter applied as ${value}`,
    });
  }, []);

  const showFilterTabs = useMemo(
    () =>
      (!!posts?.length && activeFilter === ClubsPostSharingGroupsKeys.ALL) ||
      activeFilter !== ClubsPostSharingGroupsKeys.ALL,
    [posts, activeFilter],
  );

  const updatePostsLocalState = useCallback(
    (postId, updatedPost) => {
      const updatedPosts = posts.map((post) => (post.id === postId ? updatedPost : post));
      setClubPosts(updatedPosts);
    },
    [posts],
  );

  const onSaveReply = useCallback(
    (postId, newReply) => {
      const updatedPosts = posts.map((post) =>
        post.id === postId ? { ...post, comments: [newReply, ...post?.comments] } : post,
      );
      setClubPosts(updatedPosts);
    },
    [posts],
  );

  const onDeleteReply = useCallback(
    (postId, replyId) => {
      const postWithDeletedReply = posts[posts.findIndex((post) => post.id === postId)];
      const updatedPost = {
        ...postWithDeletedReply,
        comments: postWithDeletedReply.comments.filter((reply) => reply.commentId !== replyId),
      };
      updatePostsLocalState(postId, updatedPost);
    },
    [posts, updatePostsLocalState],
  );

  const onEditReply = useCallback(
    (postId, replyId, updatedReply) => {
      const postWithUpdatedReply = posts[posts.findIndex((post) => post.id === postId)];
      const updatedReplies = postWithUpdatedReply.comments.map((reply) =>
        reply.commentId === replyId ? updatedReply : reply,
      );
      const updatedPost = { ...postWithUpdatedReply, comments: updatedReplies };
      updatePostsLocalState(postId, updatedPost);
    },
    [posts, updatePostsLocalState],
  );

  return (
    <>
      <Typography mb={3} variant="h2">
        {t('Posts')}
      </Typography>
      <Box>
        {!posts?.length && activeFilter === ClubsPostSharingGroupsKeys.ALL && (
          <Box mb={5}>
            {clubRights[ClubRights.CREATE_POST] && (
              <Typography component="h4" mb={2} variant="subtitle1">
                {t('Club’s posts')}
              </Typography>
            )}
            <InitiativeEmptyStateBlock
              body={
                clubRights[ClubRights.CREATE_POST]
                  ? t(
                      'Let’s post your first message for the club members in the space provided below!',
                    )
                  : ''
              }
              illustration={<ClubsPostEmptyState />}
              title={t(
                clubRights[ClubRights.VIEW_POSTS]
                  ? EmptyStateTitleConfig[userState.profile.role]
                  : 'No public posts available for this club yet. Stay tuned for some!',
              )}
            />
          </Box>
        )}
        {clubRights[ClubRights.CREATE_POST] && (
          <Box>
            <Box alignItems="center" display="flex" mb={2}>
              <Typography variant="subtitle1">{t('Add a new post')}</Typography>
              {!isClubOwner && (
                <NewFeatureHotspot
                  id={NewFeaturesIdsMap.CLUB_HUB_CREATE_POSTS}
                  isClickable
                  label={t('nft.club_post.title')}
                />
              )}
            </Box>
            <ClubPostForm addPost={addPost} clubId={clubId} isClubOwner={isClubOwner} />
          </Box>
        )}
        {showFilterTabs && (
          <Box mt={5} width="100%">
            {clubRights[ClubRights.CREATE_POST] && (
              <Typography mb={2} variant="subtitle1">
                {t('Club’s posts')}
              </Typography>
            )}
            {clubRights[ClubRights.VIEW_POSTS_FILTERS] && (
              <Box className="ayo-club-hub__tabs" mb={3}>
                <Tabs
                  onChange={tabsChangeHandler}
                  value={activeFilter}
                  variant={isWidthUpSm && 'outlined'}
                >
                  {clubPostsFilters.map((filter) => (
                    <Tab
                      key={filter.label}
                      disableRipple
                      label={t(isWidthUpSm ? filter.label : filter.labelMobile)}
                      value={filter.value}
                    />
                  ))}
                </Tabs>
              </Box>
            )}
            {posts.length ? (
              <Box className="ayo-comments-block">
                {posts.map((post, i, self) => {
                  const isPostAuthor = post.author.id === userState.profile.id;
                  return (
                    <Box key={post.id} mb={3}>
                      {i === self.length - 1 && (
                        <IntersectionObserver
                          as="div"
                          onChange={(inView) => {
                            if (inView) {
                              loadMorePosts();
                            }
                          }}
                        />
                      )}
                      <PostCard postId={post.id}>
                        <PostHeader
                          alt={t('author avatar image alt', { authorName: post.author.name })}
                          author={post.author}
                          availableActions={[
                            (isPostAuthor || isClubOwner) && PostActions.DELETE,
                            isPostAuthor && PostActions.EDIT,
                          ].filter(Boolean)}
                          createdDate={post?.updatedDate || post.createdDate}
                          deleteDialogHandler={() => setDeletingPost(post)}
                          editDialogHandler={editDialogHandler}
                          isAuthorClickable
                          postId={post.id}
                          status={post.status}
                        />
                        <Box my={2}>
                          <Divider flexItem />
                        </Box>
                        <PostBody
                          description={post.description}
                          imageInfo={post.attachments}
                          link={post.link}
                          title={post.title}
                        />
                        <PostFooter
                          sharingGroupsLabel={getSharingGroupsLabel(
                            [post.sharingGroup],
                            ClubPostSharingGroups,
                          )}
                          showSelectedEntities
                        />
                      </PostCard>
                      <PostRepliesBlock
                        clubId={clubId}
                        isClubOwner={isClubOwner}
                        isRepliesAllowed={clubRights[ClubRights.POST_REPLIES]}
                        onDelete={onDeleteReply}
                        onEdit={onEditReply}
                        onSave={onSaveReply}
                        postId={post.id}
                        replies={post?.comments}
                      />
                    </Box>
                  );
                })}
                <Box mt={2}>
                  <Loader
                    isLoading={isLoading}
                    labelText="Loading posts"
                    typographyVariant="body2"
                  />
                </Box>
              </Box>
            ) : (
              <InitiativeEmptyStateBlock
                illustration={<ClubsPostEmptyState />}
                title={t('No posts created yet')}
              />
            )}
            <EditClubPostDialog
              clubId={clubId}
              isClubOwner={isClubOwner}
              onClose={() => setPostToEdit(null)}
              postDataToEdit={postToEdit}
              updatePost={onUpdatePostHandler}
            />
            <ConfirmDialog
              cancelButtonTitle="Cancel"
              confirmButtonTitle="Delete post"
              isOpen={!!deletingPost}
              onClose={() => setDeletingPost(null)}
              onConfirm={onPostDeleteHandler}
              text={
                isDeletingOwnPost
                  ? isStudent
                    ? 'Club hub delete post text(student)'
                    : 'Club hub delete post text'
                  : 'Delete user post text'
              }
              textTranslationArgs={{ userName: deletingPost?.author.name }}
              title={
                isDeletingOwnPost
                  ? isStudent
                    ? 'Should we delete your post?(student)'
                    : 'Should we delete your post?'
                  : 'Delete user post'
              }
              titleTranslationArgs={{ userName: deletingPost?.author.name }}
            />
          </Box>
        )}
      </Box>
    </>
  );
};

ClubPostAndEventsSection.propTypes = {
  clubId: PropTypes.string.isRequired,
  clubRights: PropTypes.instanceOf(Object).isRequired,
  isClubOwner: PropTypes.bool.isRequired,
};

export default ClubPostAndEventsSection;
