import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { Typography, Switch } from '../../../../atoms';
import { NotificationNamesMap, SwitchLabels } from '../../../../../constants/enums';
import { useNotificationsData } from '../../../../../hooks';

const SettingsSwitchesBlock = ({ onChangeHandler, settings, title }) => {
  const { t } = useTranslation();
  const { reloadNotifications } = useNotificationsData();
  const settingsType = settings[0]?.name.split('__')[0];

  const onAfterChangeMap = useMemo(
    () => ({
      UI_NOTIFICATIONS: reloadNotifications,
      EMAIL_NOTIFICATION: null,
    }),
    [reloadNotifications],
  );

  return settings.length ? (
    <Box className="ayo-settings-page__switches-block">
      <Box mb={3}>
        <Typography component="h3" variant="subtitle2">
          {title}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Box>
          {settings.map((setting) => (
            <Box key={setting.name} mb={2}>
              <Typography variant="body2">
                {t(NotificationNamesMap.settings[setting.name])}
              </Typography>
            </Box>
          ))}
        </Box>
        <Box>
          {settings.map((setting) => (
            <Box key={setting.name} mb={2}>
              <Switch
                ariaLabel={`${t('Notifications for')} ${
                  NotificationNamesMap.settings[setting.name]
                }`}
                checkedLabel={SwitchLabels.on}
                gaLabel={NotificationNamesMap.settings[setting.name]}
                name={setting.name}
                onChange={(value, name) =>
                  onChangeHandler(value, name, settingsType, onAfterChangeMap[settingsType])
                }
                uncheckedLabel={SwitchLabels.off}
                value={setting.value}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  ) : null;
};

SettingsSwitchesBlock.propTypes = {
  settings: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  onChangeHandler: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

SettingsSwitchesBlock.defaultProps = {
  settings: [],
};

export default SettingsSwitchesBlock;
