import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { STTTextField } from '../../../../moleculas';
import { InputsValidationRules } from '../../../../../constants/enums';
import { loginAllowedFilterRE } from '../../../../../constants/regexps';
import { ClubKeys } from '../../../../../constants/club-hub';

const ClubDescription = ({ error, onChange, value }) => {
  const { t } = useTranslation();
  return (
    <STTTextField
      error={error}
      fullWidth
      gaLabel="Description"
      helperText={error}
      inputRE={loginAllowedFilterRE}
      label={t('Add a few words about the club')}
      maxLength={InputsValidationRules.MAX_INPUT_LENGTH}
      multiline
      name={ClubKeys.DESCRIPTION}
      onChange={(e) => onChange({ [ClubKeys.DESCRIPTION]: e.target.value })}
      outlined
      required
      rowsCount={4}
      value={value}
    />
  );
};

ClubDescription.propTypes = {
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

ClubDescription.defaultProps = {
  error: null,
};
export default ClubDescription;
