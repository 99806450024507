import dayjs from 'dayjs';

export const assignmentsSorter = (a, b) => a.assignmentOrder - b.assignmentOrder;

export const loopDateRange = (startTime, endTime, fn) => {
  for (
    let currentDate = dayjs(startTime);
    currentDate.isBefore(dayjs(endTime).endOf('day'));
    currentDate = currentDate.add(1, 'day')
  ) {
    fn(currentDate);
  }
};

export const getIsScheduleAvailable = (semesters) =>
  Object.values(semesters).some((value) => !dayjs(value.end).isBefore(dayjs(), 'day'));

export const formatSectionId = (title) =>
  `lesson-page-section-${title.replaceAll(' ', '-').toLowerCase()}`;

export const getFocusableElement = (el, errorText) => {
  if (!el) return null;

  const allNodesWithErrors = el.querySelectorAll('.Mui-error, .ayo-validation-error');
  const itemErrorNode = allNodesWithErrors
    ? Array.from(allNodesWithErrors).find((x) => x.textContent.includes(errorText))
    : null;
  const itemErrorParentNode = itemErrorNode?.parentNode;

  return (
    itemErrorParentNode?.querySelector('input, button, .clickable') ||
    el.querySelector('input, button') ||
    el
  );
};

export const scrollAndFocusEl = (el, scrollConfig = { block: 'center' }) => {
  el.scrollIntoView(scrollConfig);
  el.focus();
};
