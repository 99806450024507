/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment,react/prop-types */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Box, DialogActions, DialogContent, IconButton } from '@mui/material';

import {
  Checkbox,
  DatePicker,
  Dialog,
  DialogTitle,
  FormControlLabel,
  TextField,
  Typography,
} from '../../../../../atoms';
import {
  DisabledControlWithTooltip,
  Dropdown,
  PopupActionsButtons,
  STTTextField,
} from '../../../../../moleculas';
import {
  EmploymentTypesMap,
  GaActions,
  GaCategories,
  InputsValidationErrors,
  InputsValidationRules,
  minProperDate,
} from '../../../../../../constants/enums';
import { byteToMegaByteCoeff } from '../../../../../../constants/values';
import { ReactComponent as DatePickerIcon } from '../../../../../../resources/icons/date_picker.svg';
import FileUpload from '../../../../fileupload/FileUpload';
import { GA } from '../../../../../../utils';

const EmploymentNamesMap = {
  ROLE: 'role',
  ORGANIZATION: 'organization',
  EMPLOYMENT_TYPE: 'employmentType',
  START_DATE: 'startDate',
  END_DATE: 'endDate',
  CURRENTPOSITION: 'currentPosition',
  DESCRIPTION: 'description',
  ATTACHMENTS: 'attachments',
};

const initialEmploymentState = {
  [EmploymentNamesMap.ROLE]: '',
  [EmploymentNamesMap.ORGANIZATION]: '',
  [EmploymentNamesMap.EMPLOYMENT_TYPE]: '',
  [EmploymentNamesMap.START_DATE]: null,
  [EmploymentNamesMap.END_DATE]: null,
  [EmploymentNamesMap.CURRENTPOSITION]: false,
  [EmploymentNamesMap.DESCRIPTION]: '',
};

const initialEmploymentErrorMessages = {
  [EmploymentNamesMap.ROLE]: '',
  [EmploymentNamesMap.ORGANIZATION]: '',
  [EmploymentNamesMap.EMPLOYMENT_TYPE]: '',
  [EmploymentNamesMap.DESCRIPTION]: '',
};

const PortfolioCreateEmploymentDialog = ({ isOpen, onSave, onEdit, onClose, valuesToEdit }) => {
  const { t } = useTranslation();

  const [employmentData, setEmploymentData] = useState(initialEmploymentState);
  const [employmentErrorMessages, setEmploymentErrorMessages] = useState(
    initialEmploymentErrorMessages,
  );
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState(null);
  const [removedAttachments, setRemovedAttachments] = useState([]);

  const submitButtonRef = useRef();

  const employmentChangeHandler = useCallback(
    (e) => {
      const { name, value } = e.target;
      setEmploymentData((state) => ({
        ...state,
        [name]: value,
      }));
      if (employmentErrorMessages[name]) {
        setEmploymentErrorMessages((state) => ({ ...state, [name]: '' }));
      }
      GA.logInteraction({
        category: GaCategories.BEHAVIOR,
        action: GaActions.DROPDOWN_CHANGE,
        label: value,
      });
    },
    [employmentErrorMessages],
  );

  const employmentCheckboxHandler = useCallback(() => {
    setEmploymentData((state) => ({
      ...state,
      currentPosition: !state.currentPosition,
      endDate: null,
    }));
  }, []);

  const employmentDateHandler = useCallback(
    (e, key) => {
      setEmploymentData((state) => ({
        ...state,
        [key]: e ? dayjs(e).format('YYYY-MM-DD') : null,
      }));
      if (employmentErrorMessages[key]) {
        setEmploymentErrorMessages((state) => ({ ...state, [key]: '' }));
      }
    },
    [employmentErrorMessages],
  );

  useEffect(() => {
    setEmploymentData(valuesToEdit || initialEmploymentState);
    setEmploymentErrorMessages(initialEmploymentErrorMessages);
    setFilesToUpload([]);
    setRemovedAttachments([]);
    if (valuesToEdit?.[EmploymentNamesMap.ATTACHMENTS].length) {
      setUploadedFiles(
        valuesToEdit[EmploymentNamesMap.ATTACHMENTS].map((el) => ({
          file: { name: el.fileName, id: el.id },
          errors: [],
        })),
      );
    } else {
      setUploadedFiles(null);
    }
  }, [valuesToEdit, isOpen]);

  const isValid = useCallback(() => {
    const errors = { ...initialEmploymentErrorMessages };
    let isDateValid = true;

    const roleInputLength = employmentData[EmploymentNamesMap.ROLE]?.trim().length;
    const organisationInputLength = employmentData[EmploymentNamesMap.ORGANIZATION]?.trim().length;
    const descriptionInputLength = employmentData[EmploymentNamesMap.DESCRIPTION]?.trim().length;
    const startDate = employmentData[EmploymentNamesMap.START_DATE];
    const endDate = employmentData[EmploymentNamesMap.END_DATE];

    if (roleInputLength < InputsValidationRules.MIN_INPUT_LENGTH) {
      errors[EmploymentNamesMap.ROLE] = InputsValidationErrors(
        t,
        InputsValidationRules.MIN_INPUT_LENGTH,
      ).MIN_ERROR_TEXT;
    } else if (roleInputLength > InputsValidationRules.MAX_TITLE_LENGTH) {
      errors[EmploymentNamesMap.ROLE] = InputsValidationErrors(
        t,
        InputsValidationRules.MAX_TITLE_LENGTH,
      ).MAX_ERROR_TEXT;
    }

    if (organisationInputLength < InputsValidationRules.MIN_INPUT_LENGTH) {
      errors[EmploymentNamesMap.ORGANIZATION] = InputsValidationErrors(
        t,
        InputsValidationRules.MIN_INPUT_LENGTH,
      ).MIN_ERROR_TEXT;
    } else if (organisationInputLength > InputsValidationRules.MAX_TITLE_LENGTH) {
      errors[EmploymentNamesMap.ORGANIZATION] = InputsValidationErrors(
        t,
        InputsValidationRules.MAX_TITLE_LENGTH,
      ).MAX_ERROR_TEXT;
    }

    if (descriptionInputLength > InputsValidationRules.MAX_INPUT_LENGTH) {
      errors[EmploymentNamesMap.DESCRIPTION] = InputsValidationErrors(
        t,
        InputsValidationRules.MAX_INPUT_LENGTH,
      ).MAX_ERROR_TEXT;
    }

    if (!employmentData[EmploymentNamesMap.EMPLOYMENT_TYPE]) {
      errors[EmploymentNamesMap.EMPLOYMENT_TYPE] = InputsValidationErrors(t).REQUIRED_ERROR_TEXT;
    }

    if (
      startDate &&
      (!dayjs(startDate).isValid() || dayjs(startDate).isBefore(minProperDate, 'day'))
    ) {
      isDateValid = false;
    }

    if (
      !employmentData[EmploymentNamesMap.CURRENTPOSITION] &&
      endDate &&
      (!dayjs(endDate).isValid() || dayjs(endDate).isBefore(minProperDate, 'day'))
    ) {
      isDateValid = false;
    }

    setEmploymentErrorMessages(errors);
    const isFormValid = !Object.values(errors).some((error) => error) && isDateValid;
    return isFormValid;
  }, [t, employmentData]);

  const handleSubmit = useCallback(() => {
    if (isValid()) {
      const requestBody = {
        ...employmentData,
        attachments: filesToUpload,
        removedAttachments,
      };
      if (valuesToEdit) {
        onEdit(requestBody, valuesToEdit.id);
      } else {
        onSave(requestBody);
      }
      onClose();
    }
  }, [
    valuesToEdit,
    isValid,
    filesToUpload,
    onClose,
    onEdit,
    onSave,
    removedAttachments,
    employmentData,
  ]);

  return (
    <Dialog
      className="ayo-portfolio-add-employment-dialog"
      gaLabel="Portfolio create employment dialog"
      onClose={onClose}
      open={isOpen}
      transitionDuration={{ exit: 0 }}
    >
      <DialogTitle disableTypography>
        <Box mb={2}>
          <Typography component="h2" variant="h2">
            {valuesToEdit ? t('Edit employment') : t('Add employment')}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box>
          <Box mb={3} mt={1}>
            <STTTextField
              error={employmentErrorMessages[EmploymentNamesMap.ROLE]}
              fullWidth
              helperText={employmentErrorMessages[EmploymentNamesMap.ROLE]}
              inputProps={{
                autoComplete: 'off',
              }}
              label={t('Role(employment)')}
              name={EmploymentNamesMap.ROLE}
              onChange={employmentChangeHandler}
              outlined
              required
              value={employmentData[EmploymentNamesMap.ROLE]}
            />
          </Box>
          <Box mb={3}>
            <STTTextField
              error={employmentErrorMessages[EmploymentNamesMap.ORGANIZATION]}
              fullWidth
              helperText={employmentErrorMessages[EmploymentNamesMap.ORGANIZATION]}
              inputProps={{
                autoComplete: 'off',
              }}
              label={t('Organization')}
              name={EmploymentNamesMap.ORGANIZATION}
              onChange={employmentChangeHandler}
              outlined
              required
              value={employmentData[EmploymentNamesMap.ORGANIZATION]}
            />
          </Box>
          <Box mb={employmentErrorMessages[EmploymentNamesMap.EMPLOYMENT_TYPE] ? 5 : 3}>
            <Dropdown
              error={!!employmentErrorMessages[EmploymentNamesMap.EMPLOYMENT_TYPE]}
              fullWidth
              handleChange={employmentChangeHandler}
              helperText={employmentErrorMessages[EmploymentNamesMap.EMPLOYMENT_TYPE]}
              label={t('Employment type')}
              name={EmploymentNamesMap.EMPLOYMENT_TYPE}
              options={Object.entries(EmploymentTypesMap).map(([value, label]) => ({
                value,
                label: t(label),
              }))}
              outlined
              required
              value={employmentData[EmploymentNamesMap.EMPLOYMENT_TYPE]}
            />
          </Box>
          <Box display="flex" mb={3}>
            <Box mr={3}>
              <DatePicker
                gaLabel="Employment start date"
                label={t('Start date')}
                onChange={(e) => employmentDateHandler(e, EmploymentNamesMap.START_DATE)}
                outlined
                renderInput={(props) => (
                  <Box mb={props.error}>
                    <TextField
                      {...props}
                      error={props.error}
                      fullWidth
                      helperText={props.error && InputsValidationErrors(t).DATE_ERROR_TEXT}
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            ref={props.inputRef}
                            {...props.InputProps.endAdornment.props.children.props}
                            className="ayo-icon-button"
                            disableRipple
                          >
                            <DatePickerIcon />
                          </IconButton>
                        ),
                      }}
                      outlined
                    />
                  </Box>
                )}
                value={employmentData[EmploymentNamesMap.START_DATE]}
              />
            </Box>
            <DisabledControlWithTooltip
              gaLabel="Disabled employment end date"
              showTooltip={employmentData[EmploymentNamesMap.CURRENTPOSITION]}
              title={t('The end date isnt available when youre currently in this role')}
            >
              <DatePicker
                disabled={employmentData[EmploymentNamesMap.CURRENTPOSITION]}
                gaLabel="Employment end date"
                label={`${t('End date')}`}
                onChange={(e) => employmentDateHandler(e, EmploymentNamesMap.END_DATE)}
                outlined
                renderInput={(props) => (
                  <Box mb={props.error && 3}>
                    <TextField
                      {...props}
                      disabled={employmentData[EmploymentNamesMap.CURRENTPOSITION]}
                      error={props.error}
                      fullWidth
                      helperText={props.error && InputsValidationErrors(t).DATE_ERROR_TEXT}
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            ref={props.inputRef}
                            {...props.InputProps.endAdornment.props.children.props}
                            className="ayo-icon-button"
                            disableRipple
                          >
                            <DatePickerIcon />
                          </IconButton>
                        ),
                      }}
                      outlined
                    />
                  </Box>
                )}
                value={employmentData[EmploymentNamesMap.END_DATE]}
              />
            </DisabledControlWithTooltip>
          </Box>
          <Box mb={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={employmentData[EmploymentNamesMap.CURRENTPOSITION]}
                  gaLabel="is current employment"
                  name={EmploymentNamesMap.CURRENTPOSITION}
                  onChange={employmentCheckboxHandler}
                />
              }
              label={<Typography variant="body2">{t('I am currently in this role')}</Typography>}
            />
          </Box>
          <Box mb={3}>
            <STTTextField
              error={!!employmentErrorMessages[EmploymentNamesMap.DESCRIPTION]}
              fullWidth
              helperText={employmentErrorMessages[EmploymentNamesMap.DESCRIPTION]}
              label={t('Description')}
              multiline
              name={EmploymentNamesMap.DESCRIPTION}
              onChange={employmentChangeHandler}
              outlined
              rowsCount={2}
              value={employmentData[EmploymentNamesMap.DESCRIPTION]}
            />
          </Box>
          <Box mb={3}>
            <Box mb={1}>
              <Typography component="h3" variant="subtitle2">
                {t('Attachments')}
              </Typography>
            </Box>
            <FileUpload
              allowedExtensions={['jpg', 'jpeg', 'png', 'pdf']}
              focusableRef={submitButtonRef}
              maxAttachmentsAllowed={3}
              maxFileSize={5 * byteToMegaByteCoeff}
              onFileAdd={(file) => {
                setFilesToUpload((state) => [...state, file]);
              }}
              onFileRemove={(file) => {
                if (file.id) {
                  setRemovedAttachments((state) => [
                    ...state,
                    valuesToEdit?.attachments.find((el) => el.id === file.id),
                  ]);
                }
                setFilesToUpload((state) => [...state.filter((x) => x !== file)]);
              }}
              uploadedFiles={uploadedFiles}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <PopupActionsButtons
          primaryButtonGaLabel={valuesToEdit ? 'Save changes' : 'Add'}
          primaryButtonHandler={handleSubmit}
          primaryButtonText={valuesToEdit ? t('Save changes') : t('Add')}
          secondaryButtonGaLabel="Cancel"
          secondaryButtonHandler={onClose}
          secondaryButtonText={t('Cancel')}
        />
      </DialogActions>
    </Dialog>
  );
};

PortfolioCreateEmploymentDialog.propTypes = {
  valuesToEdit: PropTypes.shape({
    id: PropTypes.string,
    attachments: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
    description: PropTypes.string,
    employmentType: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    organization: PropTypes.string,
    role: PropTypes.string,
    currentPosition: PropTypes.bool,
  }),
  isOpen: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

PortfolioCreateEmploymentDialog.defaultProps = {
  valuesToEdit: null,
};

export default PortfolioCreateEmploymentDialog;
