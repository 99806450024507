/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Dialog as MUIDialog, DialogTitle as MUIDialogTitle, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Button from '../button/Button';
import { ReactComponent as CloseIcon } from '../../../resources/icons/close.svg';

export const DialogTitle = ({ children, disableTypography, ...props }) => (
  <MUIDialogTitle component={disableTypography ? 'div' : 'h2'} {...props}>
    {children}
  </MUIDialogTitle>
);

DialogTitle.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
    .isRequired,
  disableTypography: PropTypes.bool,
};

DialogTitle.defaultProps = {
  disableTypography: false,
};

const Dialog = ({
  backDropClassName,
  center,
  children,
  className,
  containerClassName,
  gaLabel,
  isTextDialog,
  maxWidth,
  onClose,
  open,
  ...props
}) => {
  const { t } = useTranslation();

  const handleClose = useCallback(
    (e, reason) => {
      if (reason !== 'backdropClick') {
        onClose(e);
      }
    },
    [onClose],
  );

  return (
    <MUIDialog
      BackdropProps={{
        className: classnames(backDropClassName),
      }}
      className={classnames('ayo-dialog')}
      fullWidth
      onClose={handleClose}
      open={open}
      PaperProps={{
        className: classnames(
          'ayo-dialog__paper',
          `ayo-dialog__paper-width-${maxWidth}`,
          className,
          {
            'ayo-dialog__paper-center': center,
          },
        ),
      }}
      {...props}
    >
      <Button
        aria-label={t('Close')}
        autoFocus
        className="ayo-dialog__close-button"
        gaLabel={gaLabel ? `${gaLabel} - dialog close` : null}
        isIconButton
        onClick={onClose}
      >
        <CloseIcon height="48" width="48" />
      </Button>
      <Box
        className={classnames('ayo-dialog__container', containerClassName, {
          'ayo-dialog__container-text': isTextDialog,
        })}
      >
        {children}
      </Box>
    </MUIDialog>
  );
};

Dialog.propTypes = {
  backDropClassName: PropTypes.string,
  center: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  gaLabel: PropTypes.string,
  isTextDialog: PropTypes.bool,
  maxWidth: PropTypes.number,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
};

Dialog.defaultProps = {
  backDropClassName: '',
  center: true,
  children: null,
  containerClassName: '',
  gaLabel: null,
  isTextDialog: false,
  maxWidth: 900,
  onClose: () => {},
  className: '',
};

export default Dialog;
