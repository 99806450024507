import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Box } from '@mui/material';

import { Card, Typography, Button } from '../../../../atoms';
import { InitiativeEmptyStateBlock } from '../../../../moleculas';
import { PortfolioSectionTypes } from '../../../../../constants/enums';
import { getFormattedDate } from '../../../../../utils';
import { ReactComponent as VolunteeringEmptyStateIllustration } from '../../../../../resources/images/portfolio/volunteering_empty_state.svg';
import { ReactComponent as PlusIcon } from '../../../../../resources/icons/plus.svg';
import { ReactComponent as DatePickerIcon } from '../../../../../resources/icons/date_picker_black.svg';
import { ReactComponent as ReflectionIcon } from '../../../../../resources/icons/reflection_black.svg';
import { ReactComponent as BuildingIcon } from '../../../../../resources/icons/building_bank.svg';
import PortfolioSection from '../../components/portfolio-section/PortfolioSection';
import PortfolioAttachments from '../../components/portfolio-attachments/PortfolioAttachments';

const AddVolunteeringButton = ({ setIsDialogOpen }) => {
  const { t } = useTranslation();
  return (
    <Button endIcon={<PlusIcon />} gaLabel="Add volunteering" onClick={() => setIsDialogOpen(true)}>
      {t('Add volunteering')}
    </Button>
  );
};

AddVolunteeringButton.propTypes = {
  setIsDialogOpen: PropTypes.func.isRequired,
};

const PortfolioSharedVolunteeringSection = ({ sectionData, token }) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <PortfolioSection
        body={
          <Box>
            {sectionData.entities.map((volunteering) => (
              <Card
                key={volunteering.id}
                className="ayo-portfolio__volunteering-section__card ayo-portfolio__volunteering-section__card__cropped"
                mainContent={
                  <Box className="ayo-portfolio__volunteering-section__card__content">
                    <Box width="100%">
                      <Box alignItems="center" display="flex" justifyContent="space-between">
                        <Typography component="h3" variant="subtitle1">
                          {volunteering.role}
                        </Typography>
                      </Box>
                      <Box display="flex" my={2}>
                        <Box>
                          <BuildingIcon />
                        </Box>
                        <Typography
                          className="ayo-portfolio__volunteering-section__card__content__text"
                          component="h3"
                          variant="caption"
                        >
                          {volunteering.organization}
                        </Typography>
                      </Box>
                      {volunteering.startDate && (
                        <Box display="flex" my={2}>
                          <Box>
                            <DatePickerIcon />
                          </Box>
                          <Typography
                            className="ayo-portfolio__volunteering-section__card__content__text"
                            component="h3"
                            variant="caption"
                          >
                            {`${getFormattedDate(volunteering.startDate, i18n.language)} - ${
                              volunteering.currentPosition || !volunteering?.endDate
                                ? t('Present')
                                : getFormattedDate(volunteering?.endDate, i18n.language)
                            }`}
                          </Typography>
                        </Box>
                      )}
                      {volunteering?.description && (
                        <Box display="flex" my={2}>
                          <Box>
                            <ReflectionIcon />
                          </Box>
                          <Typography
                            className="ayo-portfolio__volunteering-section__card__content__text"
                            component="h3"
                            variant="caption"
                          >
                            {volunteering.description}
                          </Typography>
                        </Box>
                      )}
                      {!!volunteering?.attachments.length && (
                        <Box width="82%">
                          <PortfolioAttachments
                            attachments={volunteering.attachments}
                            gridItemBreakpoint={3}
                            token={token}
                          />
                        </Box>
                      )}
                    </Box>
                  </Box>
                }
              />
            ))}
          </Box>
        }
        className={`${classNames('ayo-full-screen-portfolio-section', {
          'ayo-portfolio__volunteering-section': sectionData.entities.length,
        })}`}
        emptyState={
          !sectionData.entities.length ? (
            <InitiativeEmptyStateBlock
              illustration={<VolunteeringEmptyStateIllustration />}
              title={t('No volunteering added so far')}
            />
          ) : null
        }
        reflection={sectionData.reflection}
        sectionKey={PortfolioSectionTypes.PORTFOLIO__VOLUNTEERING}
        title={t('Volunteering')}
      />
    </>
  );
};

PortfolioSharedVolunteeringSection.propTypes = {
  sectionData: PropTypes.instanceOf(Object),
  token: PropTypes.string.isRequired,
};

PortfolioSharedVolunteeringSection.defaultProps = {
  sectionData: {},
};

export default PortfolioSharedVolunteeringSection;
