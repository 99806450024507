import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Box, DialogActions, DialogContent } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Button, Dialog, DialogTitle, Link, Typography } from '../../../../atoms';
import { Chip, PopupActionsButtons, SearchField } from '../../../../moleculas';
import { ReactComponent as PlusIcon } from '../../../../../resources/icons/plus.svg';
import { focusDialogCloseButton, GA, getFullName, Highlighter } from '../../../../../utils';
import { loginAllowedFilterRE } from '../../../../../constants/regexps';
import { plugRoute } from '../../../../../constants/routes';
import { LessonPlannerContext, UserContext } from '../../../../../context';
import { GaActions, GaCategories } from '../../../../../constants/enums';
import { ReactComponent as CloseIcon } from '../../../../../resources/icons/close.svg';
import { personPropShape } from '../../../../../constants/propTypes';

const TeacherRow = ({ teacherData, onAdd, searchValue }) => {
  const { t } = useTranslation();

  const fullName = useMemo(() => getFullName(teacherData), [teacherData]);

  return (
    <Box
      alignItems="center"
      className="ayo-manage-lesson-access-dialog__teacher-row"
      display="flex"
    >
      <Box flexBasis="50%">
        <Typography variant="body2">
          {searchValue ? Highlighter(fullName, [searchValue]) : fullName}
        </Typography>
      </Box>
      <Box flexBasis="50%">
        <Typography variant="body2">
          {searchValue ? Highlighter(teacherData.email, [searchValue]) : teacherData.email}
        </Typography>
      </Box>
      <Box>
        <Button
          aria-label={t('Add teacher')}
          className="ayo-manage-lesson-access-dialog__teacher-row__add-btn"
          gaLabel="add teacher"
          isIconButton
          onClick={() => onAdd(teacherData)}
        >
          <PlusIcon />
        </Button>
      </Box>
    </Box>
  );
};

TeacherRow.propTypes = {
  teacherData: PropTypes.shape(personPropShape).isRequired,
  onAdd: PropTypes.func.isRequired,
  searchValue: PropTypes.string,
};

TeacherRow.defaultProps = {
  searchValue: null,
};

const TeachersBlock = ({ teachers, onAdd, searchValue }) => (
  <Box display="flex" flexDirection="column" rowGap={1}>
    {teachers?.map((teacher) => (
      <TeacherRow
        key={teacher.email}
        onAdd={onAdd}
        searchValue={searchValue}
        teacherData={teacher}
      />
    ))}
  </Box>
);

TeachersBlock.propTypes = {
  teachers: PropTypes.arrayOf(PropTypes.shape(personPropShape)).isRequired,
  onAdd: PropTypes.func.isRequired,
  searchValue: PropTypes.string,
};

TeachersBlock.defaultProps = {
  searchValue: null,
};

const SelectedTeachersBlock = ({ teachers, onDelete }) => {
  const { t } = useTranslation();
  return (
    <Box>
      {teachers.length ? (
        <>
          <Typography component="h3" variant="subtitle2">
            {t('Access given')}
          </Typography>
          <Box
            className="ayo-manage-lesson-access-dialog__chips"
            display="flex"
            flexWrap="wrap"
            gap={1}
            mt={1}
          >
            {teachers.map((teacher) => (
              <Box key={`${teacher.email}`}>
                <Chip
                  ariaLabel={t('Remove item by pressing delete', { item: getFullName(teacher) })}
                  className="ayo-manage-lesson-access-dialog__chip"
                  deleteIcon={<CloseIcon />}
                  gaLabel="Remove teacher"
                  label={`${getFullName(teacher)} <${teacher.email}>`}
                  onDelete={() => onDelete(teacher)}
                />
              </Box>
            ))}
          </Box>
        </>
      ) : (
        <Typography variant="body2">
          {t(
            'No one has access to this lesson. Search or manually browse for the teacher to grant access.',
          )}
        </Typography>
      )}
    </Box>
  );
};

SelectedTeachersBlock.propTypes = {
  teachers: PropTypes.arrayOf(PropTypes.shape(personPropShape)),
  onDelete: PropTypes.func.isRequired,
};

SelectedTeachersBlock.defaultProps = {
  teachers: [],
};

const TeachersNoSearchResultsView = ({ onResetSearch, searchValue }) => {
  const { t } = useTranslation();

  return (
    <>
      <Typography paragraph variant="body2">
        <Trans
          components={{ b: <b /> }}
          i18nKey="AYO couldn’t find any results containing searchValue. Please check your spelling and repeat your search"
          values={{ searchValue }}
        />
      </Typography>
      <Link component="button" gaLabel="Reset the search" href={plugRoute} onClick={onResetSearch}>
        {t('Reset the search and show the list of all teachers')}
      </Link>
    </>
  );
};

TeachersNoSearchResultsView.propTypes = {
  onResetSearch: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,
};

const ManageAccessDialog = ({ isOpen, lessonData, onClose, onSave }) => {
  const { t } = useTranslation();

  const { state: userState } = useContext(UserContext);
  const { state: lessonPlannerState } = useContext(LessonPlannerContext);
  const { teachersList: allTeachersList } = lessonPlannerState;

  const [searchValue, setSearchValue] = useState(null);
  const [searchedTeachers, setSearchedTeachers] = useState(null);
  const [innerSelectedTeachers, setInnerSelectedTeachers] = useState([]);

  const searchFieldRef = useRef();

  useEffect(() => {
    setInnerSelectedTeachers(lessonData?.sharedWith || []);
  }, [lessonData?.sharedWith, isOpen]);

  const notSelectedTeachers = useMemo(
    () =>
      allTeachersList
        ?.filter(
          (teacher) =>
            teacher.id !== userState.profile.id &&
            innerSelectedTeachers.every((selectedTeacher) => selectedTeacher.id !== teacher.id),
        )
        .sort((a, b) => a.firstName.localeCompare(b.firstName)),
    [allTeachersList, innerSelectedTeachers, userState.profile.id],
  );

  const handleAddTeacher = useCallback((newTeacher) => {
    setInnerSelectedTeachers((prevState) => [...prevState, newTeacher]);
    focusDialogCloseButton();
  }, []);

  const handleSearch = useCallback(
    (value) => {
      if (value) {
        setSearchValue(value);
        setSearchedTeachers(
          notSelectedTeachers.filter(
            (teacher) =>
              getFullName(teacher).toLowerCase().includes(value.toLowerCase()) ||
              teacher.email.toLowerCase().includes(value.toLowerCase()),
          ),
        );
      } else {
        setSearchValue(null);
        setSearchedTeachers(null);
      }
    },
    [notSelectedTeachers],
  );

  const handleDelete = useCallback(
    (teacher) => {
      setInnerSelectedTeachers((prevState) =>
        prevState.filter((selectedTeacher) => !(selectedTeacher.id === teacher.id)),
      );
      if (searchValue) {
        handleSearch(searchValue);
      }
      GA.logInteraction({
        category: GaCategories.BEHAVIOR,
        action: GaActions.CHIP_DELETE_CLICK,
        label: 'Remove teacher chip',
      });
      focusDialogCloseButton();
    },
    [handleSearch, searchValue],
  );

  const resetSearch = useCallback(() => {
    searchFieldRef.current.clearSearch();
  }, []);

  const handleClose = useCallback(() => {
    onClose();
    resetSearch();
  }, [onClose, resetSearch]);

  const handleSave = useCallback(() => {
    onSave(innerSelectedTeachers, lessonData?.id);
    handleClose();
  }, [handleClose, innerSelectedTeachers, lessonData?.id, onSave]);

  return (
    <Dialog
      className="ayo-manage-lesson-access-dialog"
      isTextDialog
      onClose={handleClose}
      open={isOpen}
    >
      <DialogTitle disableTypography>
        <Typography className="ayo-manage-lesson-access-dialog__title" variant="h2">
          {`${t('Manage access')}${lessonData?.title ? ` - ${lessonData.title}` : ''}`}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box>
          <SelectedTeachersBlock onDelete={handleDelete} teachers={innerSelectedTeachers} />
          <Box className="ayo-manage-lesson-access-dialog__search-field">
            <SearchField
              apiRef={searchFieldRef}
              gaLabel="Educator Search"
              inputRE={loginAllowedFilterRE}
              label={t('Search for an educator by name or email')}
              minInputLength={2}
              onSearch={handleSearch}
              searchOnChange
            />
          </Box>
          {!!searchValue && !!searchedTeachers?.length && (
            <Box>
              <Typography paragraph variant="body2">
                <Trans
                  components={{ b: <b /> }}
                  i18nKey="search result text"
                  values={{ count: searchedTeachers?.length, searchValue }}
                />
              </Typography>
            </Box>
          )}
          <TeachersBlock
            onAdd={handleAddTeacher}
            searchValue={searchValue}
            teachers={searchedTeachers || notSelectedTeachers}
          />
          {!!searchValue && !searchedTeachers?.length && (
            <>
              <Typography paragraph variant="body2">
                <Trans
                  components={{ b: <b /> }}
                  i18nKey="AYO couldn’t find any results containing searchValue. Please check your spelling and repeat your search"
                  values={{ searchValue }}
                />
              </Typography>
              <Link
                component="button"
                gaLabel="Reset the search"
                href={plugRoute}
                onClick={resetSearch}
              >
                {t('Reset the search and show the list of all teachers')}
              </Link>
            </>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <PopupActionsButtons
          className="ayo-manage-lesson-access-dialog__buttons"
          primaryButtonGaLabel="Apply changes"
          primaryButtonHandler={handleSave}
          primaryButtonText={t('Apply changes')}
          secondaryButtonGaLabel="Cancel"
          secondaryButtonHandler={handleClose}
          secondaryButtonText={t('Cancel')}
        />
      </DialogActions>
    </Dialog>
  );
};

ManageAccessDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  lessonData: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    status: PropTypes.string,
    classData: PropTypes.shape({
      className: PropTypes.string,
      classDate: PropTypes.string,
      period: PropTypes.string,
    }),
    sharedWith: PropTypes.arrayOf(PropTypes.shape(personPropShape)),
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default ManageAccessDialog;
