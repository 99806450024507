import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import classNames from 'classnames';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import { addKeyboardPressHandler, GA, getFormattedDate } from '../../../utils';
import { GaActions, GaCategories } from '../../../constants/enums';
import Typography from '../typography/Typography';

const CalendarDateDisplay = ({ date, onClick, withWeekday }) => {
  const { i18n } = useTranslation();
  const isDateToday = dayjs(date).isToday();

  const onDateClick = useCallback(() => {
    GA.logInteraction({
      category: GaCategories.BEHAVIOR,
      action: GaActions.BUTTON_CLICK,
      label: 'Week day heading',
    });
    onClick?.(date);
  }, [date, onClick]);
  return (
    <Box
      className={classNames('calendar-date-display', {
        'calendar-date-display--clickable': !!onClick,
      })}
      onClick={onClick && onDateClick}
      onKeyDown={onClick && addKeyboardPressHandler(onDateClick)}
      role={onClick && 'button'}
      tabIndex={onClick ? '0' : null}
    >
      <Typography
        className={classNames('date-display', { today: isDateToday })}
        variant="subtitle1"
      >
        {dayjs(date).format('D')}
      </Typography>
      {withWeekday && (
        <Typography isLabel variant="label-highlighted">
          {getFormattedDate(date.toDate(), i18n.language, { weekday: 'long' })}
        </Typography>
      )}
    </Box>
  );
};

CalendarDateDisplay.propTypes = {
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]).isRequired,
  onClick: PropTypes.func,
  withWeekday: PropTypes.bool,
};

CalendarDateDisplay.defaultProps = {
  onClick: null,
  withWeekday: false,
};

export default CalendarDateDisplay;
