import React, { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Box, DialogActions, DialogContent } from '@mui/material';

import { Dialog, DialogTitle, Typography } from '../../../atoms';
import { PopupActionsButtons, STTTextField } from '../../../moleculas';
import { InputsValidationErrors, InputsValidationRules } from '../../../../constants/enums';
import { useTeachersService } from '../../../../services';
import { SharedDataActions, SharedDataContext } from '../../../../context';
import { useSnackbar } from '../../../../hooks';

const RejectDialog = ({ isOpen, onClose, studentId }) => {
  const { t } = useTranslation();
  const { postTeacherRejectAvatarReason } = useTeachersService();
  const [error, setError] = useState(null);
  const [rejectReason, setRejectReason] = useState('');

  const { setSnackBarStatus } = useSnackbar();

  const { state: sharedState, dispatch: dispatchSharedState } = useContext(SharedDataContext);
  const { activeStudent } = sharedState;

  const isValid = useCallback(() => {
    let newError = '';

    if (rejectReason.trim().length < InputsValidationRules.MIN_INPUT_LENGTH) {
      newError = InputsValidationErrors(t, InputsValidationRules.MIN_INPUT_LENGTH).MIN_ERROR_TEXT;
    }

    if (rejectReason.trim().length > InputsValidationRules.MAX_INPUT_LENGTH) {
      newError = InputsValidationErrors(t, InputsValidationRules.MAX_INPUT_LENGTH).MAX_ERROR_TEXT;
    }
    setError(newError);

    return !newError;
  }, [rejectReason, t]);

  const onReasonChange = useCallback((value) => {
    setRejectReason(value);
    setError(null);
  }, []);

  const onRejectSuccess = useCallback(() => {
    setSnackBarStatus({
      text: t('You rejected the photo successfully. The student will be notified.'),
      type: 'success',
    });
  }, [setSnackBarStatus, t]);

  const onRejectFail = useCallback(() => {
    setSnackBarStatus({
      text: t('Failed to reject the photo. Please try once more.'),
      type: 'error',
    });
  }, [setSnackBarStatus, t]);

  const onSubmit = useCallback(() => {
    if (isValid()) {
      postTeacherRejectAvatarReason(rejectReason, studentId)
        .then(() => {
          dispatchSharedState({
            type: SharedDataActions.SET_ACTIVE_STUDENT,
            data: { ...activeStudent, avatar: null },
          });
          setRejectReason('');
          onClose();
          onRejectSuccess();
        })
        .catch(onRejectFail);
    }
  }, [
    activeStudent,
    dispatchSharedState,
    isValid,
    onClose,
    onRejectFail,
    onRejectSuccess,
    postTeacherRejectAvatarReason,
    rejectReason,
    studentId,
  ]);

  return (
    <Dialog gaLabel="Avatar reject dialog" onClose={onClose} open={isOpen}>
      <DialogTitle disableTypography>
        <Box>
          <Typography component="h2" variant="subtitle1">
            {t('Reason for rejecting')}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body2">
            {t('Please provide a reason for rejecting the photo.')}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box mt={2}>
          <STTTextField
            error={error}
            fullWidth
            helperText={error}
            label={t('Reason for rejecting')}
            maxLength={InputsValidationRules.MAX_INPUT_LENGTH}
            minRows={2}
            multiline
            name="reject"
            onChange={(e) => {
              onReasonChange(e.target.value);
            }}
            outlined
            required
            value={rejectReason}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <PopupActionsButtons
          primaryButtonGaLabel="Submit"
          primaryButtonHandler={onSubmit}
          primaryButtonText={t('Submit')}
          secondaryButtonGaLabel="Cancel"
          secondaryButtonHandler={onClose}
          secondaryButtonText={t('Cancel')}
        />
      </DialogActions>
    </Dialog>
  );
};

RejectDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  studentId: PropTypes.number.isRequired,
};

export default RejectDialog;
