import React, { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AppActions, AppContext, UserContext } from '../../context';
import { tourStepStyleHelper, useProfile } from '../../utils';
import { EditablePrivilegesByGroupMap, PrivilegesMap, RolesMap } from '../../constants/enums';
import { ReactComponent as TourWelcomeIllustration } from '../../resources/images/tour_welcome.svg';
import { FamilyFeedFilterCategory } from '../../constants/filter-categories';
import { DropdownNavItemValues } from '../../constants/pages';

const MainTourItemsProvider = (isWidthUpLg, isWidthUpSm) => {
  const { t } = useTranslation();
  const { dispatch: dispatchAppState } = useContext(AppContext);
  const { state: userState } = useContext(UserContext);

  const { getPrivilegeStatus } = useProfile();

  const isUserPrivilegesManager = getPrivilegeStatus(PrivilegesMap.USER_PERMISSION_MANAGER);
  const isUserDataManager = getPrivilegeStatus(PrivilegesMap.USER_DATA_MANAGER);
  const isLessonPlanerAvailable = getPrivilegeStatus(PrivilegesMap.LESSON_PLANNER_CREATE_PUBLISH);
  const isFamilyFeedAvailable = EditablePrivilegesByGroupMap[FamilyFeedFilterCategory].find(
    (privilege) => getPrivilegeStatus(privilege),
  );

  const navigationOpener = useCallback(
    () =>
      new Promise((resolve) => {
        dispatchAppState({ type: AppActions.SET_NAV_DRAWER_OPEN, data: true });
        setTimeout(resolve, isWidthUpLg ? 0 : 300);
      }),
    [dispatchAppState, isWidthUpLg],
  );

  const navigationCloser = useCallback(
    () =>
      new Promise((resolve) => {
        dispatchAppState({ type: AppActions.SET_NAV_DRAWER_OPEN, data: false });
        setTimeout(resolve, isWidthUpLg ? 0 : 300);
      }),
    [dispatchAppState, isWidthUpLg],
  );

  const administrationDropdownOpener = useCallback(
    () =>
      new Promise((resolve) => {
        navigationOpener().then(() => {
          dispatchAppState({
            type: AppActions.SET_OPENED_NAV_ITEM,
            data: DropdownNavItemValues.ADMINISTRATION,
          });
          setTimeout(resolve, isWidthUpLg ? 0 : 300);
        });
      }),
    [dispatchAppState, isWidthUpLg, navigationOpener],
  );

  const tourItems = useMemo(() => {
    switch (userState.profile?.role) {
      case RolesMap.STUDENT:
        return [
          {
            target: 'body',
            content: (
              <>
                <TourWelcomeIllustration className="ayo-svg-illustration" />
                {t('Let’s walk through the main features of AYO')}
              </>
            ),
            locale: {
              next: t('Yes, let’s walk through'),
            },
            placement: 'center',
            id: 1,
            styles: tourStepStyleHelper(isWidthUpSm).initialStep,
          },
          !isWidthUpLg && {
            target: '#nav-drawer-toggle',
            title: t('Menu'),
            content: t('Menu tour step'),
            placement: 'bottom',
            hideBackButton: true,
            onNextButton: navigationOpener,
            id: 2,
          },
          {
            target: '#nav-drawer-my-profile',
            title: t('My profile'),
            content: t('My profile tour step'),
            placement: isWidthUpLg ? 'right-start' : 'bottom',
            hideBackButton: isWidthUpLg,
            id: 3,
            stepInit: navigationOpener,
            onBackButton: navigationCloser,
            styles: tourStepStyleHelper(isWidthUpSm).sideMenuTourStep,
          },
          {
            target: '#nav-drawer-my-portfolio',
            title: t('My portfolio'),
            content: t('My portfolio tour step'),
            placement: isWidthUpLg ? 'right-start' : 'bottom',
            id: 11,
            stepInit: navigationOpener,
            styles: tourStepStyleHelper(isWidthUpSm).sideMenuTourStep,
          },
          {
            target: '#nav-drawer-my-classes',
            title: t('My classes'),
            content: t('My classes main tour step'),
            placement: isWidthUpLg ? 'right-start' : 'bottom',
            id: 12,
            stepInit: navigationOpener,
            styles: tourStepStyleHelper(isWidthUpSm).sideMenuTourStep,
          },
          {
            target: '#nav-drawer-my-feed',
            title: t('My feed'),
            content: t('My feed main tour step'),
            placement: isWidthUpLg ? 'right-start' : 'bottom',
            id: 13,
            stepInit: navigationOpener,
            styles: tourStepStyleHelper(isWidthUpSm).sideMenuTourStep,
          },
          {
            target: '#nav-drawer-club-hub',
            title: t('Club Hub'),
            content: t('Club Hub main tour step(students)'),
            placement: isWidthUpLg ? 'right-start' : 'bottom',
            id: 14,
            stepInit: navigationOpener,
            styles: tourStepStyleHelper(isWidthUpSm).sideMenuTourStep,
          },
          {
            target: '#nav-drawer-train-ayo',
            title: t('Train AYO'),
            content: t('Train AYO tour step (student)'),
            placement: isWidthUpLg ? 'right-start' : 'bottom',
            id: 7,
            stepInit: navigationOpener,
            styles: tourStepStyleHelper(isWidthUpSm).sideMenuTourStep,
          },
          {
            target: '#nav-drawer-settings',
            title: t('Settings'),
            content: t('Settings tour step (Student)'),
            placement: 'top',
            id: 10,
            stepInit: navigationOpener,
            onNextButton: navigationCloser,
            styles: tourStepStyleHelper(isWidthUpSm).sideMenuTourStep,
          },
          {
            target: '#start-tour-button',
            title: t('AYO tour'),
            content: t('AYO start tour step'),
            placement: isWidthUpLg ? 'right' : 'top',
            id: 9,
            onBackButton: navigationOpener,
            styles: tourStepStyleHelper(isWidthUpSm).startTourStep,
          },
        ].filter(Boolean);
      case RolesMap.TEACHER:
        return [
          {
            target: 'body',
            content: (
              <>
                <TourWelcomeIllustration className="ayo-svg-illustration" />
                {t('Let’s walk through the main features of AYO')}
              </>
            ),
            locale: {
              next: t('Yes, let’s walk through'),
            },
            placement: 'center',
            id: 1,
            styles: tourStepStyleHelper(isWidthUpSm).initialStep,
            onNextButton: isWidthUpLg && administrationDropdownOpener,
          },
          !isWidthUpLg && {
            target: '#nav-drawer-toggle',
            title: t('Menu'),
            content: t('Menu tour step'),
            placement: 'bottom',
            hideBackButton: true,
            onNextButton: administrationDropdownOpener,
            id: 2,
          },
          {
            target: '#nav-drawer-my-students',
            title: t('My students'),
            content: t('My students tour step'),
            placement: isWidthUpLg ? 'right-start' : 'bottom',
            hideBackButton: isWidthUpLg && !isFamilyFeedAvailable,
            id: 3,
            onBackButton: navigationCloser,
            stepInit: administrationDropdownOpener,
            styles: tourStepStyleHelper(isWidthUpSm).sideMenuTourStep,
          },
          isLessonPlanerAvailable && {
            target: '#nav-drawer-my-lessons',
            title: t('My lessons'),
            content: t('My lessons tour step'),
            placement: isWidthUpLg ? 'right-start' : 'bottom',
            id: 10,
            stepInit: navigationOpener,
            styles: tourStepStyleHelper(isWidthUpSm).sideMenuTourStep,
            onBackButton: isWidthUpLg && administrationDropdownOpener,
          },
          isFamilyFeedAvailable && {
            target: '#nav-drawer-family-feed-creation-center',
            title: t('Family feed'),
            content: t('Family feed main tour step (teacher)'),
            placement: isWidthUpLg ? 'right-start' : 'bottom',
            id: 11,
            stepInit: navigationOpener,
            styles: tourStepStyleHelper(isWidthUpSm).startTourStep,
          },
          {
            target: '#nav-drawer-club-hub',
            title: t('Club Hub'),
            content: t('Club Hub main tour step(educators)'),
            placement: isWidthUpLg ? 'right-start' : 'bottom',
            id: 13,
            stepInit: navigationOpener,
            styles: tourStepStyleHelper(isWidthUpSm).sideMenuTourStep,
          },
          {
            target: '#nav-drawer-train-ayo',
            title: t('Train AYO'),
            content: t('Train AYO tour step (teacher)'),
            placement: isWidthUpLg ? 'right-start' : 'bottom',
            id: 6,
            stepInit: navigationOpener,
            styles: tourStepStyleHelper(isWidthUpSm).sideMenuTourStep,
          },
          {
            target: '#nav-drawer-my-profile-teacher',
            title: t('My profile'),
            content: t('My profile teacher tour step'),
            placement: 'top',
            id: 12,
            stepInit: navigationOpener,
            styles: tourStepStyleHelper(isWidthUpSm).sideMenuTourStep,
          },
          {
            target: '#nav-drawer-settings',
            title: t('Settings'),
            content: t('Settings tour step'),
            placement: 'top',
            id: 9,
            stepInit: navigationOpener,
            onNextButton: navigationCloser,
            styles: tourStepStyleHelper(isWidthUpSm).sideMenuTourStep,
          },
          {
            target: '#start-tour-button',
            title: t('AYO tour'),
            content: t('AYO start tour step'),
            placement: isWidthUpLg ? 'right' : 'bottom',
            id: 8,
            onBackButton: navigationOpener,
            styles: tourStepStyleHelper(isWidthUpSm).startTourStep,
          },
        ].filter(Boolean);
      case RolesMap.GUARDIAN:
        return [
          {
            target: 'body',
            content: (
              <>
                <TourWelcomeIllustration className="ayo-svg-illustration" />
                {t('Let’s walk through the main features of AYO')}
              </>
            ),
            locale: {
              next: t('Yes, let’s walk through'),
            },
            placement: 'center',
            id: 1,
            styles: tourStepStyleHelper(isWidthUpSm).initialStep,
          },
          !isWidthUpLg && {
            target: '#nav-drawer-toggle',
            title: t('Menu'),
            content: t('Menu tour step'),
            placement: 'bottom',
            hideBackButton: true,
            onNextButton: navigationOpener,
            id: 2,
          },
          {
            target: '#nav-drawer-my-kids',
            title: t('Family feed'),
            content: t('Family feed main tour step (parent)'),
            placement: isWidthUpLg ? 'right-start' : 'bottom',
            hideBackButton: isWidthUpLg,
            onBackButton: navigationCloser,
            id: 3,
            stepInit: navigationOpener,
            styles: tourStepStyleHelper(isWidthUpSm).sideMenuTourStep,
          },
          {
            target: '#nav-drawer-train-ayo',
            title: t('Train AYO'),
            content: t('Train AYO tour step (parent)'),
            placement: isWidthUpLg ? 'right-start' : 'bottom',
            id: 4,
            stepInit: navigationOpener,
            styles: tourStepStyleHelper(isWidthUpSm).sideMenuTourStep,
          },
          {
            target: '#nav-drawer-settings',
            title: t('Settings'),
            content: t('Settings tour step'),
            placement: 'top',
            id: 7,
            stepInit: navigationOpener,
            onNextButton: navigationCloser,
            styles: tourStepStyleHelper(isWidthUpSm).sideMenuTourStep,
          },
          {
            target: '#start-tour-button',
            title: t('AYO tour'),
            content: t('AYO start tour step'),
            placement: isWidthUpLg ? 'right' : 'bottom',
            id: 6,
            onBackButton: navigationOpener,
            styles: tourStepStyleHelper(isWidthUpSm).startTourStep,
          },
        ].filter(Boolean);
      case RolesMap.ADMINISTRATOR:
        return [
          {
            target: 'body',
            content: (
              <>
                <TourWelcomeIllustration className="ayo-svg-illustration" />
                {t('Let’s walk through the main features of AYO')}
              </>
            ),
            locale: {
              next: t('Yes, let’s walk through'),
            },
            placement: 'center',
            id: 1,
            styles: tourStepStyleHelper(isWidthUpSm).initialStep,
            onNextButton: isWidthUpSm && administrationDropdownOpener,
          },
          !isWidthUpLg && {
            target: '#nav-drawer-toggle',
            title: t('Menu'),
            content: t('Menu tour step'),
            placement: 'bottom',
            hideBackButton: true,
            onNextButton: administrationDropdownOpener,
            id: 2,
          },
          (isUserDataManager || isUserPrivilegesManager) && {
            target: '#nav-drawer-users',
            title: t('Users'),
            content: t('Users tour step'),
            placement: isWidthUpLg ? 'right-start' : 'bottom',
            hideBackButton: isWidthUpLg,
            onBackButton: navigationCloser,
            id: 9,
            stepInit: administrationDropdownOpener,
            styles: tourStepStyleHelper(isWidthUpSm).sideMenuTourStep,
          },
          {
            target: '#nav-drawer-analytics',
            title: t('Analytics'),
            content: t('Analytics tour step'),
            placement: isWidthUpLg ? 'right-start' : 'bottom',
            hideBackButton: isWidthUpLg && !(isUserDataManager || isUserPrivilegesManager),
            onBackButton: !(isUserDataManager || isUserPrivilegesManager) && navigationCloser,
            id: 7,
            stepInit: administrationDropdownOpener,
            styles: tourStepStyleHelper(isWidthUpSm).sideMenuTourStep,
          },
          {
            target: '#nav-drawer-students',
            title: t('Students'),
            content: t('Students tour step'),
            placement: isWidthUpLg ? 'right-start' : 'bottom',
            id: 3,
            stepInit: administrationDropdownOpener,
            styles: tourStepStyleHelper(isWidthUpSm).sideMenuTourStep,
          },
          isFamilyFeedAvailable && {
            target: '#nav-drawer-family-feed-creation-center',
            title: t('Family feed'),
            content: t('Family feed main tour step (teacher)'),
            placement: isWidthUpLg ? 'right' : 'bottom',
            id: 10,
            stepInit: navigationOpener,
            styles: tourStepStyleHelper(isWidthUpSm).startTourStep,
            onBackButton: isWidthUpLg && administrationDropdownOpener,
          },
          {
            target: '#nav-drawer-club-hub',
            title: t('Club Hub'),
            content: t('Club Hub main tour step(educators)'),
            placement: isWidthUpLg ? 'right-start' : 'bottom',
            id: 13,
            stepInit: navigationOpener,
            styles: tourStepStyleHelper(isWidthUpSm).sideMenuTourStep,
            onBackButton: !isFamilyFeedAvailable && isWidthUpLg && administrationDropdownOpener,
          },
          {
            target: '#nav-drawer-train-ayo',
            title: t('Train AYO'),
            content: t('Train AYO tour step (administrator)'),
            placement: isWidthUpLg ? 'right-start' : 'bottom',
            id: 4,
            stepInit: navigationOpener,
            styles: tourStepStyleHelper(isWidthUpSm).sideMenuTourStep,
          },
          {
            target: '#nav-drawer-my-profile-teacher',
            title: t('My profile'),
            content: t('My profile teacher tour step'),
            placement: 'top',
            id: 12,
            stepInit: navigationOpener,
            styles: tourStepStyleHelper(isWidthUpSm).sideMenuTourStep,
          },
          {
            target: '#nav-drawer-settings',
            title: t('Settings'),
            content: t('Settings tour step'),
            placement: 'top',
            id: 8,
            stepInit: navigationOpener,
            onNextButton: navigationCloser,
            styles: tourStepStyleHelper(isWidthUpSm).sideMenuTourStep,
          },
          {
            target: '#start-tour-button',
            title: t('AYO tour'),
            content: t('AYO start tour step'),
            placement: isWidthUpLg ? 'right' : 'bottom',
            id: 6,
            onBackButton: navigationOpener,
            styles: tourStepStyleHelper(isWidthUpSm).startTourStep,
          },
        ].filter(Boolean);
      default:
        return [];
    }
  }, [
    isFamilyFeedAvailable,
    isLessonPlanerAvailable,
    isUserDataManager,
    isUserPrivilegesManager,
    isWidthUpLg,
    isWidthUpSm,
    navigationCloser,
    navigationOpener,
    t,
    userState.profile?.role,
    administrationDropdownOpener,
  ]);

  const tourName = useMemo(() => {
    switch (userState.profile?.role) {
      case RolesMap.STUDENT:
        return 'my-profile-tour';
      case RolesMap.TEACHER:
        return 'my-students-tour';
      case RolesMap.GUARDIAN:
        return 'guardian-navigation-tour';
      case RolesMap.ADMINISTRATOR:
        return 'administrator-main-tour';
      default:
        return '';
    }
  }, [userState.profile?.role]);

  return { tourName, tourItems };
};

export default MainTourItemsProvider;
