import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { DotIndicator } from '../../../atoms';
import { Chip, InformationalCaption } from '../../../moleculas';

const ClubStatus = ({ reason, status }) => {
  const { t } = useTranslation();
  return (
    <Box
      alignItems="center"
      className={classnames('ayo-club-hub__status', `ayo-club-hub__status--${status}`)}
      display="flex"
      flexDirection="row"
    >
      <Chip icon={<DotIndicator />} label={t(`club_hub_statuses.${status}`)} />
      {reason && (
        <Box ml={1}>
          <InformationalCaption showTooltip title={reason} withoutTranslation />
        </Box>
      )}
    </Box>
  );
};

ClubStatus.propTypes = {
  reason: PropTypes.string,
  status: PropTypes.string.isRequired,
};

ClubStatus.defaultProps = {
  reason: '',
};

export default ClubStatus;
