import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Button } from '../../components/atoms';
import { FamilyFeedActions, FamilyFeedContext, UserContext } from '../../context';
import { useAdministratorsService, useFeedService, useTeachersService } from '../../services';
import { deepEqual, getFormDataObject } from '../../utils';
import {
  mesquiteDistrictLabel,
  PostsSharingGroupsBySourceMap,
  SharingLevels,
} from '../../constants/family-feed';
import {
  FeedPostCategories,
  FeedPostStatuses,
  RolesMap,
  StudentActivityCriterions,
} from '../../constants/enums';
import { myStudentsRoute, studentsRoute } from '../../constants/routes';
import useSnackbar from '../use-snackbar/useSnackbar';

export const DEFAULT_PAGE_SIZE = 20;

const useFamilyFeed = () => {
  const { t } = useTranslation();

  const history = useHistory();

  const { state: userState } = useContext(UserContext);
  const { profile } = userState;

  const { state: familyFeedState, dispatch: dispatchFamilyFeedState } =
    useContext(FamilyFeedContext);
  const { page, posts, totalNumberOfPosts, activeFlterCategory } = familyFeedState;

  const { getTeacherCourses } = useTeachersService();
  const {
    getAuthorFeedPosts,
    getStudentRelatedPosts,
    getGuardianGeneralPosts,
    postFamilyFeedPost,
    updateFamilyFeedPost,
    getFeedPostsMetadata,
    getPostsOrder,
  } = useFeedService();
  const { getStudents: getAdministratorStudents } = useAdministratorsService();
  const { setSnackBarStatus } = useSnackbar();

  const addPost = useCallback(
    (body) =>
      postFamilyFeedPost(getFormDataObject(body))
        .then((data) => {
          if (
            data.category === activeFlterCategory ||
            activeFlterCategory === FeedPostCategories.ALL_UPDATES
          ) {
            dispatchFamilyFeedState({
              type: FamilyFeedActions.ADD_NEW_POST,
              data,
            });
          }
          setSnackBarStatus({
            text: t('Your post has been successfully published.'),
            type: 'success',
          });

          return true;
        })
        .catch(() => {
          setSnackBarStatus({
            text: `${t('Something went wrong')}. ${t('Please try once more')}.`,
            type: 'error',
            action: (
              <Button autoFocus gaLabel="Retry" onClick={() => addPost(body)}>
                {t('Retry')}
              </Button>
            ),
          });

          return false;
        }),
    [dispatchFamilyFeedState, postFamilyFeedPost, setSnackBarStatus, t, activeFlterCategory],
  );

  const updatePost = useCallback(
    (updatedPost) =>
      updateFamilyFeedPost(getFormDataObject(updatedPost), updatedPost.id)
        .then((newPost) => {
          const updatedPosts = posts.map((post) => (post.id === newPost.id ? newPost : post));
          dispatchFamilyFeedState({
            type: FamilyFeedActions.SET_POSTS,
            data: updatedPosts,
          });
          setSnackBarStatus({
            text: t('Thanks for your changes! Your input has been saved(teacher)'),
            type: 'success',
          });
          return true;
        })
        .catch(() => {
          setSnackBarStatus({
            text: t('AYO couldn’t save your edits. Please try once more(teacher)'),
            type: 'error',
          });
          return false;
        }),
    [dispatchFamilyFeedState, posts, setSnackBarStatus, t, updateFamilyFeedPost],
  );

  const undoDeletion = useCallback(
    (deletedPost, postsBackup) => {
      updateFamilyFeedPost(getFormDataObject(deletedPost), deletedPost.id)
        .then(() => {
          dispatchFamilyFeedState({
            type: FamilyFeedActions.SET_POSTS,
            data: postsBackup,
          });
        })
        .catch(() => {
          setSnackBarStatus({
            text: t('AYO couldn’t save your edits. Please try once more(teacher)'),
            type: 'error',
          });
        });
    },
    [dispatchFamilyFeedState, setSnackBarStatus, t, updateFamilyFeedPost],
  );

  const deletePost = useCallback(
    (postToDelete) => {
      const postsBackup = [...posts];
      const body = {
        title: postToDelete.title,
        description: postToDelete.description,
        status: FeedPostStatuses.DELETED,
        category: postToDelete.category,
        sharingGroups: postToDelete.sharingGroups,
        sharingLevel: postToDelete.sharingLevel,
        selectedEntities: postToDelete.selectedEntities,
      };
      updateFamilyFeedPost(getFormDataObject(body), postToDelete.id)
        .then((newPost) => {
          const updatedPosts = posts.filter((post) => post.id !== newPost.id);
          dispatchFamilyFeedState({
            type: FamilyFeedActions.SET_POSTS,
            data: updatedPosts,
          });
          setSnackBarStatus({
            text: t('The post has been deleted.'),
            type: 'delete',
            action: (
              <Button
                autoFocus
                gaLabel="Undo"
                onClick={() =>
                  undoDeletion(
                    { ...body, id: postToDelete.id, status: postToDelete.status },
                    postsBackup,
                  )
                }
              >
                {t('Undo')}
              </Button>
            ),
          });
        })
        .catch(() => {
          setSnackBarStatus({
            text: t('AYO couldn’t delete the post. Please try once more.'),
            type: 'error',
            action: (
              <Button autoFocus gaLabel="Retry" onClick={() => deletePost(postToDelete)}>
                {t('Retry')}
              </Button>
            ),
          });
        });
    },
    [dispatchFamilyFeedState, posts, setSnackBarStatus, t, updateFamilyFeedPost, undoDeletion],
  );

  const setIsLoading = useCallback(
    (data) =>
      dispatchFamilyFeedState({
        type: FamilyFeedActions.SET_IS_LOADING,
        data,
      }),
    [dispatchFamilyFeedState],
  );

  const getPosts = useCallback(
    (studentId, lang, isReset = false, pageSize = DEFAULT_PAGE_SIZE, resetSearchParams = false) => {
      const currentPage = isReset ? 1 : page;
      const isInitialLoad = currentPage === 1;

      const isEducator =
        profile.role === RolesMap.TEACHER || profile.role === RolesMap.ADMINISTRATOR;

      const fetchMethod = isEducator
        ? getAuthorFeedPosts
        : studentId
        ? getStudentRelatedPosts
        : getGuardianGeneralPosts;

      if (!isInitialLoad) setIsLoading(true);

      fetchMethod(
        currentPage,
        pageSize,
        isInitialLoad,
        activeFlterCategory,
        studentId ?? profile.id,
        lang,
      ).then((data) => {
        if (isInitialLoad) {
          dispatchFamilyFeedState({
            type: FamilyFeedActions.SET_TOTAL_POSTS,
            data: data.totalElements,
          });
        }

        dispatchFamilyFeedState({
          type: isInitialLoad ? FamilyFeedActions.SET_POSTS : FamilyFeedActions.ADD_POSTS,
          data: data.feedPosts,
        });

        const totalFetchedPosts = isInitialLoad
          ? data.feedPosts.length
          : posts.length + data.feedPosts.length;

        const hasMore =
          totalFetchedPosts < (isInitialLoad ? data.totalElements : totalNumberOfPosts);

        const newPage = pageSize !== DEFAULT_PAGE_SIZE ? pageSize / DEFAULT_PAGE_SIZE : currentPage;

        dispatchFamilyFeedState({
          type: FamilyFeedActions.SET_PAGE,
          data: hasMore ? newPage + 1 : newPage,
        });

        if (resetSearchParams) {
          history.replace({ search: '' });
        }

        setIsLoading(false);
      });
    },
    [
      page,
      profile.role,
      profile.id,
      getAuthorFeedPosts,
      getStudentRelatedPosts,
      getGuardianGeneralPosts,
      setIsLoading,
      activeFlterCategory,
      dispatchFamilyFeedState,
      posts?.length,
      totalNumberOfPosts,
      history,
    ],
  );

  const setActiveFilterCategory = useCallback(
    (data) => {
      if (page !== 1) {
        dispatchFamilyFeedState({
          type: FamilyFeedActions.SET_PAGE,
          data: 1,
        });
      }

      dispatchFamilyFeedState({
        type: FamilyFeedActions.SET_ACTIVE_FILTER_CATEGORY,
        data,
      });
    },
    [dispatchFamilyFeedState, page],
  );

  const getPageSize = useCallback(
    async (studentId, postId) => {
      const { postOrder } = await getPostsOrder(studentId, postId);
      return Math.ceil(postOrder / DEFAULT_PAGE_SIZE) * DEFAULT_PAGE_SIZE || DEFAULT_PAGE_SIZE;
    },
    [getPostsOrder],
  );

  const getClasses = useCallback(() => {
    getTeacherCourses().then((data) => {
      dispatchFamilyFeedState({
        type: FamilyFeedActions.SET_CLASSES,
        data,
      });
    });
  }, [getTeacherCourses, dispatchFamilyFeedState]);

  const getStudents = useCallback(
    () =>
      getAdministratorStudents(StudentActivityCriterions.ANY).then((data) => {
        const sortedStudents =
          data.students?.sort((a, b) => a.firstName.localeCompare(b.firstName)) || [];

        dispatchFamilyFeedState({
          type: FamilyFeedActions.SET_STUDENTS,
          data: sortedStudents,
        });
      }),
    [dispatchFamilyFeedState, getAdministratorStudents],
  );

  const getAllowedSharingLevels = useCallback(() => {
    const privilegeTemplate = 'FAMILY_FEED_CREATOR_{LEVEL}';

    return Object.values(SharingLevels)
      .map((level) =>
        profile.privileges.includes(privilegeTemplate.replace('{LEVEL}', level.value))
          ? level.value
          : null,
      )
      .filter(Boolean);
  }, [profile.privileges]);

  const getEntitiesByLevel = useCallback(
    (level) => {
      switch (level) {
        case SharingLevels.DISTRICT.value:
          return [
            {
              id: 0,
              name: mesquiteDistrictLabel,
            },
          ];
        case SharingLevels.CAMPUS.value:
          return profile.schools || [];
        case SharingLevels.CLASS.value:
          return familyFeedState.classes;
        case SharingLevels.PERSONALIZED.value:
          return familyFeedState.students;
        default:
          return null;
      }
    },
    [familyFeedState.classes, familyFeedState.students, profile.schools],
  );

  const formatEntities = useCallback(
    (entitiesArray, keys) =>
      entitiesArray.map((item, index) =>
        Object.entries(keys).reduce(
          (acum, [currentKey, currentValue]) => ({
            ...acum,
            [currentKey]: currentValue === 'index' ? index : item[currentValue],
          }),
          {},
        ),
      ),
    [],
  );

  // classes is a special case, needs different formatting
  const formatClasses = useCallback(
    (classes) =>
      classes
        .map(({ courseNumber, className, schoolName, period }) => ({
          id: courseNumber,
          name: `${className} - ${period}`,
          label: schoolName,
        }))
        .sort((a, b) => a.name.localeCompare(b.name)),
    [],
  );

  const getStudentRedirectLink = useCallback(
    (id) =>
      userState.profile.role === RolesMap.TEACHER
        ? `${myStudentsRoute}/${id}`
        : `${studentsRoute}/${id}`,
    [userState.profile.role],
  );

  const getFilterCategories = useCallback(() => {
    getFeedPostsMetadata().then((postsFilterCategories) => {
      dispatchFamilyFeedState({
        type: FamilyFeedActions.SET_POST_FILTER_CATEGORIES,
        data: postsFilterCategories,
      });
    });
  }, [getFeedPostsMetadata, dispatchFamilyFeedState]);

  const getSharingGroupsText = (sharingGroups, source) =>
    Object.values(PostsSharingGroupsBySourceMap[source]).find((item) =>
      deepEqual(item.value.sort(), sharingGroups?.sort()),
    )?.text;

  const showCampusInfoHotSpot = useCallback(
    (postId) => {
      const firstClickableCampus = posts?.find(({ sharingLevel }) =>
        [SharingLevels.DISTRICT.value, SharingLevels.CAMPUS.value].includes(sharingLevel),
      );
      return firstClickableCampus?.id === postId;
    },
    [posts],
  );

  return {
    addPost,
    updatePost,
    deletePost,
    getPosts,
    setIsLoading,
    getClasses,
    getStudents,
    getAllowedSharingLevels,
    getEntitiesByLevel,
    getPageSize,
    formatEntities,
    formatClasses,
    getStudentRedirectLink,
    getFilterCategories,
    setActiveFilterCategory,
    getSharingGroupsText,
    showCampusInfoHotSpot,
  };
};

export default useFamilyFeed;
