import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Image, Typography } from '../../../../../atoms';
import { MoodFollowUpsIllustrations } from '../../../../../../constants/moods';

// For all old moods that don't have imageUrl or altText
const getIllustrationData = (question, field) => {
  const illustrationData = MoodFollowUpsIllustrations.find(({ keys }) =>
    keys.some((key) => key === question),
  );
  return illustrationData[field];
};

const FollowUpQuestionsBlock = ({ questions, firstname }) => {
  const { t } = useTranslation();
  return (
    <Box className="ayo-sel-follow-up-questions">
      {questions.map(
        ({ question, answer, imageUrl, altText }) =>
          answer && (
            <Box className="ayo-sel-follow-up-questions__question-item">
              <Image
                alt={altText || t(getIllustrationData(question, 'alt'))}
                className="ayo-sel-follow-up-questions__image"
                src={imageUrl || getIllustrationData(question, 'imageUrl')}
              />
              <Box ml={3}>
                <Box mb={1}>
                  <Typography component="h5" variant="subtitle2">
                    {question}
                  </Typography>
                </Box>
                <Typography variant="body2">
                  {firstname}: {answer}
                </Typography>
              </Box>
            </Box>
          ),
      )}
    </Box>
  );
};

FollowUpQuestionsBlock.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  firstname: PropTypes.string.isRequired,
};

export default FollowUpQuestionsBlock;
