import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Box, Container, Grid } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import { TabPanel, Typography } from '../../../atoms';
import {
  EditingButton,
  Loader,
  NewFeatureHotspot,
  PageEndScrollToTopButton,
  SearchNoResults,
} from '../../../moleculas';
import { PageWrapper } from '../../../organisms';
import { useDidUpdate, useSearchParams } from '../../../../hooks';
import { AnalyticTypesMap } from '../../../../constants/enums';
import { familyFeedRoute } from '../../../../constants/routes';
import { FamilyFeedActions, FamilyFeedContext, UserContext } from '../../../../context';
import { useFeedService, useProfileService } from '../../../../services';
import { getNormalizedLanguageCode, transformSearchToObject } from '../../../../utils';
import { ReactComponent as ExpandIcon } from '../../../../resources/icons/chevron_down.svg';
import FamilyActivitiesBlock from '../components/family-activities-block/FamilyActivitiesBlock';
import FamilyActivityDialog from '../components/family-activity-dialog/FamilyActivityDialog';
import FamilyActivitiesFilter from '../components/family-activities-filter/FamilyActivitiesFilter';
import FamilyActivityCard from '../components/family-activity-card/FamilyActivityCard';
import FamilyActivitiesSearch from '../components/family-activities-search/FamilyActivitiesSearch';
import { NewFeaturesIdsMap } from '../../../../tours/common/NewFeaturesItemsProvider';

const ContentSectionTabs = {
  ALL: 'All',
  MY_COLLECTION: 'My collection',
};
const PAGE_SIZE = 12;

const FamilyActivitiesPage = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const {
    params: { studentId },
  } = useRouteMatch();
  const search = useSearchParams();
  const { activeCategory, activeCategoryGroup, view } = transformSearchToObject(search);

  const { sendAyoAnalytic } = useProfileService();
  const {
    getFamilyActivities,
    getFamilyActivitiesCategories,
    getFamilyActivitiesSuggested,
    getFamilyActivitiesSearched,
  } = useFeedService();

  const { state: familyFeedState, dispatch: dispatchFamilyFeedState } =
    useContext(FamilyFeedContext);
  const { state: userState } = useContext(UserContext);

  const activeChild = useMemo(
    () => userState.dependentProfiles.find((profile) => profile.id === +studentId),
    [studentId, userState.dependentProfiles],
  );

  const [activeContentTab] = useState(ContentSectionTabs.ALL);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [totalElements, setTotalElements] = useState(null);
  const [familyActivities, setFamilyActivities] = useState(null);
  const [familyActivitiesCategories, setFamilyActivitiesCategories] = useState(null);
  const [learningMoreItem, setLearningMoreItem] = useState(null);

  const [searchValue, setSearchValue] = useState(null);
  const [searchedActivities, setSearchedActivities] = useState(null);
  const [totalSearchedElements, setTotalSearchedElements] = useState(null);
  const [searchPage, setSearchPage] = useState(1);

  useEffect(() => {
    // initial fetch and re-fetch on studentId/language change
    getFamilyActivities(
      studentId,
      getNormalizedLanguageCode(i18n.language),
      1,
      PAGE_SIZE,
      activeCategoryGroup,
      activeCategory,
      true,
    ).then((data) => {
      setTotalElements(data.totalElements);
      setFamilyActivities(data.familyActivities);
      setPage(2);
    });

    getFamilyActivitiesCategories(studentId, getNormalizedLanguageCode(i18n.language)).then(
      (data) => {
        setFamilyActivitiesCategories(data);
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCategory, studentId, i18n.language]);

  useEffect(() => {
    if (!familyFeedState.familyActivitiesSuggested) {
      getFamilyActivitiesSuggested(studentId, getNormalizedLanguageCode(i18n.language), true).then(
        (data) => {
          dispatchFamilyFeedState({
            type: FamilyFeedActions.SET_FAMILY_ACTIVITIES_SUGGESTED,
            data,
          });
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useDidUpdate(() => {
    // re-fetch on studentId/language change
    getFamilyActivitiesSuggested(studentId, getNormalizedLanguageCode(i18n.language), true).then(
      (data) => {
        dispatchFamilyFeedState({
          type: FamilyFeedActions.SET_FAMILY_ACTIVITIES_SUGGESTED,
          data,
        });
      },
    );
  }, [studentId, i18n.language]);

  const handleFetchMore = useCallback(() => {
    if (familyActivities.length < totalElements) {
      setIsLoading(true);

      getFamilyActivities(
        studentId,
        getNormalizedLanguageCode(i18n.language),
        page,
        PAGE_SIZE,
        activeCategoryGroup,
        activeCategory,
      ).then((data) => {
        setFamilyActivities((prevState) => [...prevState, ...data.familyActivities]);
        setPage((prevState) => prevState + 1);
        setIsLoading(false);
      });
    }
  }, [
    activeCategory,
    activeCategoryGroup,
    familyActivities?.length,
    getFamilyActivities,
    i18n.language,
    page,
    studentId,
    totalElements,
  ]);

  const handleFamilyActivityDialogOpen = useCallback(
    (activities, id, orderNumber, isSuggested = false) => {
      setLearningMoreItem(activities.find((activity) => activity.id === id));

      sendAyoAnalytic(
        AnalyticTypesMap.FAMILY_ACTIVITY_VIEW_CLICK,
        JSON.stringify({
          selectedCategory: activeCategory || 'all',
          studentId,
          id,
          section: isSuggested ? 'Suggested activities' : 'All activities',
          orderNumber,
          date: new Date(),
        }),
      );
    },
    [activeCategory, sendAyoAnalytic, studentId],
  );

  useEffect(() => {
    if (view && familyFeedState.familyActivitiesSuggested)
      setLearningMoreItem(
        familyFeedState.familyActivitiesSuggested.find((activity) => activity.id === view),
      );
  }, [familyFeedState.familyActivitiesSuggested, view]);

  const handleCategoryClick = useCallback(
    (group, item, isSelected) => {
      if (group && item) {
        history.replace({
          search: !isSelected
            ? new URLSearchParams({
                activeCategoryGroup: group,
                activeCategory: item.nameKey,
              }).toString()
            : null,
        });
      }

      sendAyoAnalytic(
        AnalyticTypesMap.FAMILY_ACTIVITY_CATEGORY_CLICK,
        JSON.stringify({
          category: activeCategory,
        }),
      );
    },
    [activeCategory, history, sendAyoAnalytic],
  );

  const handleFamilyActivityDialogClose = useCallback(() => {
    setLearningMoreItem(null);
    if (search.has('view')) search.delete('view');

    history.replace({
      search: search.toString(),
    });
  }, [history, search]);

  const handleFetchMoreSearchedActivities = useCallback(() => {
    if (searchedActivities.length < totalSearchedElements) {
      setIsLoading(true);

      getFamilyActivitiesSearched(
        studentId,
        getNormalizedLanguageCode(i18n.language),
        searchPage,
        PAGE_SIZE,
        searchValue,
        false,
      ).then((data) => {
        setSearchedActivities((prevState) => [...prevState, ...data.familyActivities]);
        setSearchPage((prevState) => prevState + 1);
        setIsLoading(false);
      });
    }
  }, [
    getFamilyActivitiesSearched,
    i18n.language,
    searchPage,
    searchValue,
    searchedActivities?.length,
    studentId,
    totalSearchedElements,
  ]);

  const handleSearch = useCallback(
    (activitiesLength = null, activities = null, value = null, searchActivitiesPage = 1) => {
      setTotalSearchedElements(activitiesLength);
      setSearchedActivities(activities);
      setSearchValue(value);
      setSearchPage(searchActivitiesPage);
    },
    [],
  );

  const searchFieldRef = useRef();

  const resetSearch = useCallback(() => {
    searchFieldRef.current.clearSearch();
  }, []);

  return (
    <PageWrapper
      backToLink={familyFeedRoute}
      backToText={t('Back to Family feed')}
      mainElementClassName="ayo-family-activities-page"
    >
      <Container>
        <Grid container mb={5}>
          <Grid item lg={6} mb={5} md={8} position="relative" xs={12}>
            <Box display="flex" flexDirection="column">
              <Typography component="h1" mb={1} variant="h1">
                {t('Family activities')}
              </Typography>
              <Typography mb={3} variant="body2">
                {t(
                  'Welcome to the place where you can browse through various activities that you and your child(ren) might enjoy doing together.',
                )}
              </Typography>
              <Box alignItems="center" display="flex">
                <FamilyActivitiesSearch
                  onSearch={handleSearch}
                  pageSize={PAGE_SIZE}
                  searchFieldRef={searchFieldRef}
                  studentId={studentId}
                />
                <NewFeatureHotspot
                  id={NewFeaturesIdsMap.ACTIVITIES_SEARCH}
                  isClickable
                  label={t('Activities search')}
                />
              </Box>
            </Box>
          </Grid>
          <Grid container item xs={12}>
            <Grid container item xs={12}>
              {searchValue ? (
                searchedActivities?.length ? (
                  <Box>
                    <Typography mb={5} variant="body2">
                      <Trans
                        components={{ b: <b /> }}
                        i18nKey="search result text"
                        values={{ count: totalSearchedElements, searchValue }}
                      />
                    </Typography>
                    <Box>
                      <Grid container item mb={3} spacing={3} xs={12}>
                        {searchedActivities.map(
                          ({ id, title, description, imageAltText, imageUrl, suggested }, i) => (
                            <FamilyActivityCard
                              key={id}
                              description={description}
                              imgAlt={imageAltText}
                              imgSrc={imageUrl}
                              isSuggested={suggested}
                              onLearnMoreClick={() =>
                                handleFamilyActivityDialogOpen(searchedActivities, id, i, suggested)
                              }
                              title={title}
                            />
                          ),
                        )}
                      </Grid>
                      {!isLoading ? (
                        searchedActivities?.length < totalSearchedElements && (
                          <EditingButton
                            gaLabel="Show more"
                            icon={<ExpandIcon />}
                            iconPosition="end"
                            onClick={handleFetchMoreSearchedActivities}
                            text={t('Show more')}
                          />
                        )
                      ) : (
                        <Loader isLoading={isLoading} labelText={t('Loading activities')} />
                      )}
                    </Box>
                  </Box>
                ) : (
                  <Box>
                    <SearchNoResults
                      explanation="AYO couldn’t find any results containing searchValue. Please check your spelling and repeat your search"
                      onResetSearch={resetSearch}
                      resetLinkText="Reset the search and show all Family activities"
                      searchValue={searchValue}
                    />
                  </Box>
                )
              ) : (
                <TabPanel currentTabValue={activeContentTab} value={ContentSectionTabs.ALL}>
                  {!!familyFeedState.familyActivitiesSuggested?.length && (
                    <FamilyActivitiesBlock
                      isSuggested
                      items={familyFeedState.familyActivitiesSuggested}
                      onLearnMoreClick={handleFamilyActivityDialogOpen}
                      title={t('Suggestions for you and', {
                        childName: activeChild.firstName,
                      })}
                    />
                  )}
                  {!!familyActivities?.length && (
                    <FamilyActivitiesBlock
                      isLoading={isLoading}
                      items={familyActivities}
                      onLearnMoreClick={handleFamilyActivityDialogOpen}
                      subheader={
                        familyActivitiesCategories?.length ? (
                          <FamilyActivitiesFilter
                            activeCategory={activeCategory}
                            activeCategoryGroup={activeCategoryGroup}
                            categories={familyActivitiesCategories}
                            onClick={handleCategoryClick}
                          />
                        ) : null
                      }
                      title={t('Explore more')}
                    />
                  )}
                  {!isLoading ? (
                    familyActivities?.length < totalElements && (
                      <EditingButton
                        gaLabel="Show more"
                        icon={<ExpandIcon />}
                        iconPosition="end"
                        onClick={handleFetchMore}
                        text={t('Show more')}
                      />
                    )
                  ) : (
                    <Loader isLoading={isLoading} labelText={t('Loading activities')} />
                  )}
                </TabPanel>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <PageEndScrollToTopButton />
      <FamilyActivityDialog
        isOpen={!!learningMoreItem}
        item={learningMoreItem}
        onClose={handleFamilyActivityDialogClose}
        onLike={() => {}}
      />
    </PageWrapper>
  );
};

export default FamilyActivitiesPage;
