/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { getKeyValueObjectFromArray } from '../../../../../utils';
import { AssignmentFields, PublicationStatuses } from '../../../../../constants/enums';
import AssignmentDeadlines from '../edit-components/assignment-deadlines/AssignmentDeadlines';
import AssignmentPoints from '../edit-components/assignment-points/AssignmentPoints';
import AssignmentSettings from '../edit-components/assignment-settings/AssignmentSettings';
import AssignmentStudents from '../edit-components/assignment-students/AssignmentStudents';
import AssignmentTitle from '../edit-components/assignment-title/AssignmentTitle';
import AssignmentInstruction from '../edit-components/assignment-instruction/AssignmentInstruction';
import AssignmentMaterials from '../edit-components/assignment-materials/AssignmentMaterials';
import AssignmentOptions from '../edit-components/assignment-options/AssignmentOptions';
import AssignmentChoicesPath from '../edit-components/assignment-choices-path/AssignmentChoicesPath';
import useAssignmentDeadlinesState from '../state-hooks/use-assignment-deadlines-state/useAssignmentDeadlinesState';
import useAssignmentPointsState from '../state-hooks/use-assignment-points-state/useAssignmentPointsState';
import useAssignmentStudentsState from '../state-hooks/use-assignment-students-state/useAssignmentStudentsState';
import useAssignmentTitleState from '../state-hooks/use-assignment-title-state/useAssignmentTitleState';
import useAssignmentSettingsState from '../state-hooks/use-assignment-settings-state/useAssignmentSettingsState';
import useAssignmentInstructionState from '../state-hooks/use-assignment-instruction-state/useAssignmentInstructionState';
import useAssignmentMaterialsState from '../state-hooks/use-assignment-materials-state/useAssignmentMaterialsState';
import useAssignmentOptionsState from '../state-hooks/use-assignment-options-state/useAssignmentOptionsState';
import useAssignmentChoicePathsState from '../state-hooks/use-assignment-choices-state/useAssignmentChoicesState';
import { validateAssignmentsCreator } from '../edit-assignments/helpers';

const createCustomizableComponent =
  (element) =>
  (props = {}) => {
    const elementArray = React.Children.toArray(element);
    const overridedElement = React.cloneElement(elementArray[0], props);
    return overridedElement;
  };

const getValidators = (validatedFields, availableValidators) =>
  validatedFields.map((field) => availableValidators[field]).filter(Boolean);

const useAssignment = (
  assignment,
  choices,
  courseStudents,
  onChange,
  onChoicesChange,
  onChoicesDelete,
  onChoicesLayerDelete,
  validatorRef,
  validatedFields,
  fieldsConfig = {},
) => {
  const { t } = useTranslation();

  const { instructionText, isInstructionRequired, studentsText, titleText } = fieldsConfig;

  const {
    state: title,
    onChange: onTitleChange,
    validate: validateTitle,
    error: titleError,
  } = useAssignmentTitleState(assignment.title, titleText);

  const {
    state: students,
    onChange: onStudentsChange,
    validate: validateStudents,
    error: studentsError,
  } = useAssignmentStudentsState(courseStudents, assignment.assignedStudentsEmails, studentsText);

  const {
    state: points,
    onChange: onPointsChange,
    error: pointsError,
    validate: validatePoints,
  } = useAssignmentPointsState(assignment.points);

  const {
    state: deadlines,
    onChange: onDeadlinesChange,
    validate: validateDeadlines,
  } = useAssignmentDeadlinesState([
    {
      key: 'submissionDeadline',
      value: assignment.submissionDeadline ? dayjs(assignment.submissionDeadline) : null,
      title: t('Deadline for submission'),
      fieldLabel: t('Deadline in mm/dd/yyyy, hh:mm AM/PM'),
      formatError: t('Deadline format error'),
      pastTimeError: t('Deadline past time error'),
    },
  ]);

  const { state: settings, onChange: onSettingsChange } = useAssignmentSettingsState([
    {
      key: 'submissionEditable',
      title: t('Students can edit answer in Google Classroom'),
      tooltip: t('Each student can edit their already submitted answer.'),
      value: assignment.submissionEditable ?? false,
    },
  ]);

  const {
    state: instruction,
    onChange: onInstructionChange,
    error: instructionError,
    validate: validateInstruction,
  } = useAssignmentInstructionState(assignment.description, instructionText, isInstructionRequired);

  const {
    state: materials,
    onChange: onMaterialsChange,
    validate: validateMaterials,
  } = useAssignmentMaterialsState({
    attachments: assignment.attachments,
    links: assignment.links,
  });

  const {
    state: options,
    onChange: onOptionsChange,
    error: optionsError,
    validate: validateOptions,
  } = useAssignmentOptionsState(assignment.questionChoices);

  const {
    state: choicesState,
    onChoicesChange: onChoiceStateChange,
    onLayerChange,
    validate: validateChoices,
    errors: choicesErrors,
  } = useAssignmentChoicePathsState(choices);

  useEffect(() => {
    onChange({
      ...assignment,
      title,
      assignedStudentsEmails: courseStudents
        ? students.reduce(
            (acc, student) => (student.value ? [...acc, student.email] : [...acc]),
            [],
          )
        : assignment.assignedStudentsEmails,
      points,
      ...getKeyValueObjectFromArray(deadlines),
      ...getKeyValueObjectFromArray(settings),
      description: instruction,
      attachments: materials.attachments,
      links: materials.links,
      questionChoices: options.map((option) => option.value),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title, students, points, deadlines, settings, instruction, materials, options]);

  const allValidators = {};
  const publishedValidators = {};
  const registerValidator = (name, fn, registerForPublished) => {
    allValidators[name] = fn;
    if (registerForPublished) {
      publishedValidators[name] = fn;
    }
  };

  registerValidator(AssignmentFields.TITLE, validateTitle);
  registerValidator(AssignmentFields.INSTRUCTION, validateInstruction);
  registerValidator(AssignmentFields.DEADLINES, validateDeadlines);
  registerValidator(AssignmentFields.STUDENTS, validateStudents);
  registerValidator(AssignmentFields.POINTS, validatePoints);
  registerValidator(AssignmentFields.OPTIONS, validateOptions);
  registerValidator(AssignmentFields.MATERIALS, validateMaterials);
  registerValidator(AssignmentFields.CHOICES, validateChoices, true);

  const isPublished = assignment.status === PublicationStatuses.PUBLISHED;

  const getAssignmentValidators = () => getValidators(validatedFields, allValidators);

  const getPublishedAssignmentValidators = () =>
    getValidators(validatedFields, publishedValidators);

  if (assignment.id || assignment.tempId) {
    const validator = isPublished
      ? validateAssignmentsCreator(getPublishedAssignmentValidators())
      : validateAssignmentsCreator(getAssignmentValidators());
    // eslint-disable-next-line no-param-reassign
    validatorRef.current[assignment.id || assignment.tempId] = validator;
  }

  const renderTitle = createCustomizableComponent(
    <AssignmentTitle
      disabled={isPublished}
      errorMessage={titleError}
      fieldLabel={t('Add title here')}
      handleChange={onTitleChange}
      title={t('Title')}
      value={title}
    />,
  );

  const renderInstruction = createCustomizableComponent(
    <AssignmentInstruction
      disabled={isPublished}
      errorMessage={instructionError}
      gaLabel="Assignment instruction"
      handleChange={onInstructionChange}
      required={isInstructionRequired}
      value={instruction}
    />,
  );

  const renderMaterials = createCustomizableComponent(
    <AssignmentMaterials
      assignmentId={assignment.id}
      disabled={isPublished}
      handleChange={onMaterialsChange}
      materials={materials}
    />,
  );

  const renderStudents = createCustomizableComponent(
    <AssignmentStudents
      disabled={isPublished}
      errorMessage={studentsError}
      handleChange={onStudentsChange}
      students={students}
      title={t('Assigned students')}
    />,
  );

  const renderPoints = createCustomizableComponent(
    <AssignmentPoints
      disabled={isPublished}
      errorMessage={pointsError}
      handleChange={onPointsChange}
      value={points}
    />,
  );

  const renderSettings = createCustomizableComponent(
    <AssignmentSettings
      disabled={isPublished}
      onSettingsChange={onSettingsChange}
      settings={settings}
    />,
  );

  const renderDeadline = createCustomizableComponent(
    <AssignmentDeadlines
      deadlines={deadlines}
      disabled={isPublished}
      handleChange={onDeadlinesChange}
      validateDeadline={validateDeadlines}
    />,
  );

  const renderOptions = createCustomizableComponent(
    <AssignmentOptions
      disabled={isPublished}
      errorMesssage={optionsError}
      onChange={onOptionsChange}
      options={options}
    />,
  );

  const renderChoices = createCustomizableComponent(
    <AssignmentChoicesPath
      choices={choicesState}
      errors={choicesErrors}
      isPublished={isPublished}
      onChoicesChange={(value) => onChoicesChange(onChoiceStateChange(value))}
      onChoicesDelete={onChoicesDelete}
      onChoicesLayerDelete={onChoicesLayerDelete}
      onLayerChange={(level, value) => onChoicesChange(onLayerChange(level, value))}
    />,
  );

  return {
    renderers: {
      renderTitle,
      renderInstruction,
      renderMaterials,
      renderStudents,
      renderSettings,
      renderDeadline,
      renderPoints,
      renderOptions,
      renderChoices,
    },
  };
};

export default useAssignment;
