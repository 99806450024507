import React, { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Box, List, ListItem, Popover } from '@mui/material';

import { Button, DotIndicator, Typography } from '../../atoms';
import { Chip, EditingButton, TextBadge } from '../../moleculas';
import { NavItemsMap } from '../../../constants/pages';
import {
  AppRoutes,
  defaultMainRoute,
  extracurricularsRoute,
  goalsRoute,
  interestsRoute,
  leadershipAttributesCommentsSection,
  leadershipRoute,
  myClassesLessonRoute,
  myClassesRoute,
  commentsDialog,
  myLessonsLessonViewRoute,
  myLessonsRoute,
  myProfileAchievementsSection,
  myProfileRoute,
  myStudentsAchievementsSection,
  myStudentsExtracurricularSection,
  myStudentsGoalsSection,
  myStudentsInterestsSection,
  myStudentsOpportunitiesSection,
  opportunitiesRoute,
  portfolioRecommendationsSection,
  portfoliosRoute,
  settingsPageRoute,
  trainAyoRoute,
  trainAyoSelectInitialRoute,
  assignmentHash,
  trainAyoSelectFollowupRoute,
  familyFeedRoute,
  clubHubDetailsRoute,
  clubHubRoute,
  familyFeedCreationCenterRoute,
  eventsRoute,
  familyFeedMyFeedRoute,
} from '../../../constants/routes';
import { ChatActions, ChatContext, UserContext } from '../../../context';
import { useInView, useNotificationsData } from '../../../hooks';
import { GA, getFullDateAndHoursString, getRelativeDateTime } from '../../../utils';
import {
  GaActions,
  GaCategories,
  NotificationSourcesMap,
  NotificationResourcesMap,
  RolesMap,
  StudentsPage,
  NotificationInitiatorsMap,
} from '../../../constants/enums';
import LEADERSHIP_ATTRIBUTES_GALERY from '../../../constants/leadership-attributes-galery';
import { ReactComponent as NotificationIcon } from '../../../resources/icons/notification.svg';
import { ReactComponent as NoNotificationsImage } from '../../../resources/images/no_notifications.svg';
import { ReactComponent as EnvelopeIcon } from '../../../resources/icons/envelope.svg';
import { ReactComponent as GearIcon } from '../../../resources/icons/gear.svg';
import { ReactComponent as RedFlagIcon } from '../../../resources/icons/red_flag.svg';
import { QuestionnaireActionsMap } from '../../../constants/questionnaire';
import { notificationType } from '../../../constants/propTypes';
import { FamilyFeedPages } from '../../../constants/family-feed';

const NotificationsEmptyState = () => {
  const { t } = useTranslation();

  return (
    <Box alignItems="center" display="flex" flexDirection="column" py={2}>
      <Box py={3}>
        <NoNotificationsImage aria-label={t('A bell and a zero notifications sign')} />
      </Box>
      <Box pb={0.5}>
        <Typography align="center" variant="subtitle2">
          {t('No notifications so far')}
        </Typography>
      </Box>
      <Box maxWidth="80%">
        <Typography align="center" variant="body2">
          {t('Seems that you’re up-to-date with the latest updates in AYO')}
        </Typography>
      </Box>
    </Box>
  );
};

const NotificationItem = ({ closeHandler, item }) => {
  const { i18n, t } = useTranslation();
  const history = useHistory();
  const { state: userState } = useContext(UserContext);
  const { updateNotificationItems } = useNotificationsData();

  const { action, createdDate, initiator, markedAsRead, metadata, resource, resourceId } = item;
  const initiatorRole = metadata?.initiatorInfo?.role;
  const initiatorFirstName = metadata?.initiatorInfo?.firstName;
  const initiatorLastName = metadata?.initiatorInfo?.lastName;
  const initiatorEmail = metadata?.info?.initiatorEmail;
  const relatedUserFirstName = metadata?.relatedUserInfo?.firstName;
  const relatedUserLastName = metadata?.relatedUserInfo?.lastName;
  const relatedUserId = metadata?.relatedUserInfo?.id;
  const goalName = metadata?.info?.goalName;
  const goalId = metadata?.info?.goalId;
  const lessonTitle = metadata?.info?.lessonTitle;
  const reason = metadata?.info?.reason;
  const resourceName = metadata?.info?.resourceName;
  const laName = metadata?.info?.laName ? t(metadata?.info?.laName) : null;
  const laType = metadata?.info?.laType ? t(metadata?.info?.laType) : null;
  const clubId = metadata?.info?.clubId;
  const notificationText = metadata?.info?.notificationText;
  const adultRequestDate = getFullDateAndHoursString(
    metadata?.info?.interventionRequestDate,
    i18n.language,
  );
  const acknowlegementDate = getFullDateAndHoursString(
    metadata?.info?.interventionAcknowledgmentDate,
    i18n.language,
  );
  const closeAcknowlegementDate = getFullDateAndHoursString(
    metadata?.info?.interventionCloseDate,
    i18n.language,
  );
  const isTrendNotification = resource === NotificationResourcesMap.MOOD_INTERVENTION;

  const markAsRead = useCallback(() => {
    if (!item.markedAsRead) {
      updateNotificationItems(
        [{ ...item, markedAsRead: true }],
        NotificationSourcesMap.WEB_GENERAL,
      );
    }
  }, [item, updateNotificationItems]);

  const { ref } = useInView(markAsRead, 3000, item.markedAsRead);

  const onNotificationClick = useCallback(
    (url) => {
      GA.logInteraction({
        category: GaCategories.BEHAVIOR,
        action: GaActions.BUTTON_CLICK,
        label: 'Notification item',
      });
      markAsRead();
      closeHandler();
      history.push(url);
    },
    [closeHandler, history, markAsRead],
  );

  const getPageCategoryByRoute = useCallback(
    (route) => {
      let roleItems = [];
      const addToRoleItems = (items) => {
        if (!items) return;
        roleItems = [...roleItems, ...items];
        items.forEach(({ subItems }) => {
          if (subItems) {
            addToRoleItems(subItems);
          }
        });
      };
      addToRoleItems(NavItemsMap[userState.profile.role].main);
      return roleItems.find((i) => i.value === route);
    },
    [userState.profile],
  );

  const getLAIndexByLAName = useCallback(
    (currentLaName) => LEADERSHIP_ATTRIBUTES_GALERY.findIndex((i) => i.key === currentLaName),
    [],
  );

  const { dispatch: dispatchChatState } = useContext(ChatContext);

  const resourceToPageConverter = useCallback(() => {
    const myProfile = getPageCategoryByRoute(myProfileRoute);
    const familyFeed = getPageCategoryByRoute(FamilyFeedPages[userState.profile?.role]);
    const trainAyo = getPageCategoryByRoute(trainAyoRoute);
    const myStudents = getPageCategoryByRoute(StudentsPage[userState.profile?.role]);
    const myClassesCategory = getPageCategoryByRoute(myClassesRoute);
    const myLessons = getPageCategoryByRoute(myLessonsRoute);
    const clubHub = getPageCategoryByRoute(clubHubRoute);

    const educatorConfig = {
      [NotificationResourcesMap.GOAL]: {
        url: `${trainAyoRoute}/${metadata?.initiatorInfo?.id}${goalsRoute}/${resourceId}`,
        category: trainAyo,
      },
      [NotificationResourcesMap.MOOD_INTERVENTION]: {
        url: `${AppRoutes.STUDENTS}/${relatedUserId}${AppRoutes.MOOD_DETAILS}?moodId=${resourceId}`,
        category: null,
      },
      [NotificationResourcesMap.LA_COMMENT]: {
        url: `${trainAyoRoute}/${relatedUserId}${leadershipRoute}/${getLAIndexByLAName(
          metadata?.info?.laName,
        )}${leadershipAttributesCommentsSection}`,
        category: trainAyo,
      },
      [NotificationResourcesMap.MILESTONE]: {
        url: `${trainAyoRoute}/${
          (metadata || {})[initiatorRole === RolesMap.STUDENT ? 'initiatorInfo' : 'relatedUserInfo']
            ?.id
        }${goalsRoute}/${goalId}`,
        category: trainAyo,
      },
      [NotificationResourcesMap.RECOMMENDATION_REQUEST]: {
        url: `${StudentsPage[userState.profile?.role]}/${
          metadata?.initiatorInfo?.id
        }${portfoliosRoute}/${resourceId}${portfolioRecommendationsSection}`,
        category: myStudents,
      },
      [NotificationResourcesMap.LESSON_SHARE]: {
        url: `${myLessonsLessonViewRoute.replace(':lessonId', resourceId)}`,
        category: myLessons,
      },
      [NotificationResourcesMap.COMMENT_TO_LESSON_OWNER]: {
        url: `${myLessonsLessonViewRoute.replace(
          ':lessonId',
          metadata?.info?.relatedEntityId,
        )}${commentsDialog}-${resourceId}`,
        category: myLessons,
      },
      [NotificationResourcesMap.COMMENT_REPLY_TO_LESSON_OWNER]: {
        url: `${myLessonsLessonViewRoute.replace(
          ':lessonId',
          metadata?.info?.relatedEntityId,
        )}${commentsDialog}-${resourceId}`,
        category: myLessons,
      },
      [NotificationResourcesMap.REPLY_TO_COMMENT_OWNER]: {
        url: `${myLessonsLessonViewRoute.replace(
          ':lessonId',
          metadata?.info?.relatedEntityId,
        )}${commentsDialog}-${resourceId}`,
        category: myLessons,
      },
      [NotificationResourcesMap.REPLY_TO_ALL_THREAD]: {
        url: `${myLessonsLessonViewRoute.replace(
          ':lessonId',
          metadata?.info?.relatedEntityId,
        )}${commentsDialog}-${resourceId}`,
        category: myLessons,
      },
      [NotificationResourcesMap.CLUB_HUB_MANAGE]: {
        url: `${clubHubDetailsRoute.replace(':clubId', resourceId)}`,
        category: clubHub,
      },
      [NotificationResourcesMap.CONVERSATION]: {
        category: familyFeed,
        handler: () => {
          dispatchChatState({
            type: ChatActions.SET_ACTIVE_CONVERSATION_METADATA,
            data: { id: resourceId },
          });
          dispatchChatState({ type: ChatActions.SET_IS_CHAT_DRAWER_OPENED, data: true });
          closeHandler();
        },
      },
      [NotificationResourcesMap.CLUB_POST]: {
        url: `${clubHubDetailsRoute.replace(':clubId', clubId)}`,
        category: clubHub,
      },
      [NotificationResourcesMap.CLUB_POST_COMMENT]: {
        url: `${clubHubDetailsRoute.replace(':clubId', clubId)}`,
        category: clubHub,
      },
      [NotificationResourcesMap.FAMILY_FEED_EVENT]: {
        url: `${familyFeedCreationCenterRoute}${eventsRoute}?eventId=${resourceId}`,
        category: familyFeed,
      },
    };

    return {
      [RolesMap.STUDENT]: {
        [NotificationResourcesMap.ACHIEVEMENT]: {
          url: `${myProfileRoute}${myProfileAchievementsSection}`,
          category: myProfile,
        },
        [NotificationResourcesMap.AVATAR]: {
          url: `${myProfileRoute}`,
          category: myProfile,
        },
        [NotificationResourcesMap.EXTRACURRICULAR]: {
          url: `${trainAyoRoute}${extracurricularsRoute}`,
          category: trainAyo,
        },
        [NotificationResourcesMap.GOAL]: {
          url: `${trainAyoRoute}/${userState.profile.id}${goalsRoute}/${resourceId}`,
          category: trainAyo,
        },
        [NotificationResourcesMap.INTEREST]: {
          url: `${trainAyoRoute}${interestsRoute}`,
          category: trainAyo,
        },
        [NotificationResourcesMap.LAG]: {
          url: `${trainAyoRoute}/${userState.profile.id}${leadershipRoute}/${getLAIndexByLAName(
            metadata?.info?.laName,
          )}`,
          category: trainAyo,
        },
        [NotificationResourcesMap.MATURITY_GRAPH]: {
          url: `${trainAyoRoute}/${userState.profile.id}${leadershipRoute}`,
          category: trainAyo,
        },
        [NotificationResourcesMap.MILESTONE]: {
          url: `${trainAyoRoute}/${userState.profile.id}${goalsRoute}/${goalId}`,
          category: trainAyo,
        },
        [NotificationResourcesMap.OPPORTUNITY]: {
          url: `${trainAyoRoute}${opportunitiesRoute}`,
          category: trainAyo,
        },
        [NotificationResourcesMap.CHOICE_BOARDS]: {
          url: `${myClassesLessonRoute.replace(
            ':lessonId',
            metadata?.info?.relatedEntityId,
          )}${assignmentHash}-${resourceId}`,
          category: myClassesCategory,
        },
        [NotificationResourcesMap.CLUB_HUB_MANAGE_MEMBER]: {
          url: `${clubHubDetailsRoute.replace(':clubId', resourceId)}`,
          category: clubHub,
        },
        [NotificationResourcesMap.FAMILY_FEED_EVENT]: {
          url: `${familyFeedMyFeedRoute}${eventsRoute}?eventId=${resourceId}`,
          category: familyFeed,
        },
        [NotificationResourcesMap.CLUB_POST]: {
          url: `${clubHubDetailsRoute.replace(':clubId', clubId)}`,
          category: clubHub,
        },
        [NotificationResourcesMap.CLUB_POST_COMMENT]: {
          url: `${clubHubDetailsRoute.replace(':clubId', clubId)}`,
          category: clubHub,
        },
      },
      [RolesMap.TEACHER]: educatorConfig,
      [RolesMap.ADMINISTRATOR]: educatorConfig,
      [RolesMap.GUARDIAN]: {
        [NotificationResourcesMap.ACHIEVEMENT]: {
          url: `${familyFeedRoute}/${metadata?.relatedUserInfo?.id}${myStudentsAchievementsSection}`,
          category: familyFeed,
        },
        [NotificationResourcesMap.EXTRACURRICULAR]: {
          url: `${familyFeedRoute}/${
            (metadata || {})[
              initiatorRole === RolesMap.STUDENT ? 'initiatorInfo' : 'relatedUserInfo'
            ]?.id
          }${myStudentsExtracurricularSection}`,
          category: familyFeed,
        },
        [NotificationResourcesMap.GOAL]: {
          url: `${familyFeedRoute}/${
            (metadata || {})[
              initiatorRole === RolesMap.STUDENT ? 'initiatorInfo' : 'relatedUserInfo'
            ]?.id
          }${myStudentsGoalsSection}`,
          category: familyFeed,
        },
        [NotificationResourcesMap.INTEREST]: {
          url: `${familyFeedRoute}/${metadata?.initiatorInfo?.id}${myStudentsInterestsSection}`,
          category: familyFeed,
        },
        [NotificationResourcesMap.OPPORTUNITY]: {
          url: `${familyFeedRoute}/${metadata?.initiatorInfo?.id}${myStudentsOpportunitiesSection}`,
          category: familyFeed,
        },
        [NotificationResourcesMap.QUESTIONNAIRE]: {
          url:
            action === QuestionnaireActionsMap.END_ACADEMIC_YEAR_REMINDER_FOLLOWUP_QUESTIONNAIRE
              ? trainAyoSelectFollowupRoute
              : trainAyoSelectInitialRoute,
          category: trainAyo,
        },
        [NotificationResourcesMap.CONVERSATION]: {
          category: familyFeed,
          handler: () => {
            dispatchChatState({
              type: ChatActions.SET_ACTIVE_CONVERSATION_METADATA,
              data: { id: resourceId },
            });
            dispatchChatState({ type: ChatActions.SET_IS_CHAT_DRAWER_OPENED, data: true });
            closeHandler();
          },
        },
        [NotificationResourcesMap.FAMILY_FEED_EVENT]: {
          url: `${familyFeedRoute}${eventsRoute}?eventId=${resourceId}`,
          category: familyFeed,
        },
        [NotificationResourcesMap.CLUB_POST]: {
          url: `${clubHubDetailsRoute.replace(':clubId', clubId)}`,
          category: clubHub,
        },
        [NotificationResourcesMap.CLUB_POST_COMMENT]: {
          url: `${clubHubDetailsRoute.replace(':clubId', clubId)}`,
          category: clubHub,
        },
      },
    };
  }, [
    action,
    getLAIndexByLAName,
    getPageCategoryByRoute,
    goalId,
    initiatorRole,
    metadata,
    resourceId,
    userState.profile,
    relatedUserId,
    dispatchChatState,
    closeHandler,
    clubId,
  ]);

  const relatedPageConfig = resourceToPageConverter()[userState.profile.role][resource] || {
    url: defaultMainRoute,
    category: getPageCategoryByRoute(defaultMainRoute),
  };

  const Icon = relatedPageConfig.category?.icon;

  return (
    <ListItem
      button
      className={classnames('ayo-notifications', {
        'ayo-notifications__trend': isTrendNotification,
      })}
      disableRipple
      onClick={relatedPageConfig.handler ?? (() => onNotificationClick(relatedPageConfig.url))}
    >
      <DotIndicator invisible={markedAsRead} overlap="circular" />
      <Box display="flex">
        {isTrendNotification && (
          <Box mr={1}>
            <RedFlagIcon />
          </Box>
        )}
        <Typography variant="body2">
          <Trans
            components={{ b: <b />, leftAngelBracket: '<', rightAngelBracket: '>' }}
            i18nKey={`notifications.${resource.toLowerCase()}.${action.toLowerCase()}.${(initiator !==
            NotificationInitiatorsMap.USER
              ? initiator
              : initiatorRole
            )?.toLowerCase()}.${userState.profile.role.toLowerCase()}`}
            values={{
              acknowlegementDate,
              adultRequestDate,
              closeAcknowlegementDate,
              goalName,
              initiatorEmail,
              initiatorFirstName,
              initiatorLastName,
              laName,
              laType,
              lessonTitle: lessonTitle ? `"${lessonTitle}"` : '',
              relatedUserFirstName,
              relatedUserLastName,
              reason,
              resourceName,
              notificationText,
            }}
          />
        </Typography>
      </Box>
      <Box
        ref={ref}
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        pt={1}
        width="100%"
      >
        {isTrendNotification ? (
          <Typography className="ayo-notifications__item-text" variant="subtitle2">
            {t('See details')}
          </Typography>
        ) : (
          <Chip
            className={`${relatedPageConfig.category.text.toLowerCase().split(' ').join('-')}`}
            icon={<Icon />}
            label={t(relatedPageConfig.category.text)}
          />
        )}
        <Typography isLabel variant="caption">
          {getRelativeDateTime(createdDate, i18n.language, t)}
        </Typography>
      </Box>
    </ListItem>
  );
};

NotificationItem.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  item: PropTypes.shape(notificationType).isRequired,
};

const NotificationsPopover = () => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isAlertVisible, setIsAlertVisible] = useState(null);
  const history = useHistory();

  const handleOpenClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    if (anchorEl) {
      setAnchorEl(null);
    }
  }, [anchorEl]);

  const { notificationsList, receivedNewCount, updateNotificationItems } = useNotificationsData();

  const unreadNotifications = notificationsList?.WEB_GENERAL?.filter((i) => !i.markedAsRead);
  const unreadNotificationsLength = unreadNotifications?.length || 0;

  const markAllAsRead = useCallback(() => {
    updateNotificationItems(
      unreadNotifications.map((item) => ({
        ...item,
        markedAsRead: true,
      })),
      NotificationSourcesMap.WEB_GENERAL,
    );
  }, [unreadNotifications, updateNotificationItems]);

  useEffect(() => {
    if (receivedNewCount) {
      setIsAlertVisible(true);
      setTimeout(() => {
        setIsAlertVisible(false);
      }, 1000);
    }
  }, [receivedNewCount]);

  return (
    <>
      <Button
        aria-label={`${t('Notifications')} ${t('You have number new notifications', {
          number: unreadNotificationsLength,
        })}`}
        gaLabel="Notifications"
        isIconButton
        onClick={handleOpenClick}
      >
        <DotIndicator invisible={!unreadNotificationsLength} overlap="circular">
          <NotificationIcon />
        </DotIndicator>
      </Button>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        className="ayo-notifications__popover"
        onClose={handleClose}
        open={Boolean(anchorEl)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box alignItems="center" display="flex" justifyContent="space-between" p={3}>
          <Box alignItems="center" display="flex">
            <Typography variant="subtitle1">{t('Notifications')}</Typography>
            {unreadNotificationsLength > 0 && (
              <TextBadge text={unreadNotificationsLength.toString()} />
            )}
          </Box>
          <Box>
            <EditingButton
              aria-label={t('Settings')}
              gaLabel="Settings"
              icon={<GearIcon />}
              iconPosition="end"
              onClick={() => history.push(settingsPageRoute)}
              text={t('Settings')}
            />
          </Box>
        </Box>
        <List>
          {notificationsList?.WEB_GENERAL?.map((item) => (
            <NotificationItem key={item.id} closeHandler={handleClose} item={item} />
          ))}
          {(!notificationsList?.WEB_GENERAL || !notificationsList?.WEB_GENERAL.length) && (
            <NotificationsEmptyState />
          )}
        </List>
        {unreadNotificationsLength > 0 && (
          <Box pb={3} px={3}>
            <EditingButton
              gaLabel="Mark all as read"
              icon={<EnvelopeIcon />}
              iconPosition="end"
              onClick={markAllAsRead}
              text={t('Mark all as read')}
            />
          </Box>
        )}
      </Popover>
      {isAlertVisible && (
        <div className="sr-only" role="alert">
          {t('You’ve got a new notification!')}
        </div>
      )}
    </>
  );
};

export default NotificationsPopover;
