import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { ActivityLog } from '../../../../../organisms';
import { activityLogRoute, apiRoute, contentRoute } from '../../../../../../constants/routes';
import { useContentModerationService } from '../../../../../../services';

const ContentModerationActivityLog = ({ schoolLevel, type }) => {
  const {
    getActivityLogData,
    getActivityLogUnreadCount,
    putReadActivityLogItem,
    putReadAllActivityLogItems,
  } = useContentModerationService();

  const handleDataLoad = useCallback(
    (page) => getActivityLogData(type, schoolLevel, page),
    [getActivityLogData, schoolLevel, type],
  );

  const handleUnreadCountLoad = useCallback(
    () => getActivityLogUnreadCount(type, schoolLevel),
    [getActivityLogUnreadCount, schoolLevel, type],
  );

  return (
    <ActivityLog
      exportUrl={`${apiRoute}${activityLogRoute}${contentRoute}/report?contentType=${type}&schoolLevel=${schoolLevel}`}
      isReadyToLoadData={!!(schoolLevel && type)}
      onDataLoad={handleDataLoad}
      onUnreadCountLoad={handleUnreadCountLoad}
      onUpdateAllItems={() => putReadAllActivityLogItems(type, schoolLevel)}
      onUpdateItem={putReadActivityLogItem}
    />
  );
};

ContentModerationActivityLog.propTypes = {
  schoolLevel: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default ContentModerationActivityLog;
