import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Typography } from '../../atoms';
import { GA } from '../../../utils';
import { GaActions, GaCategories } from '../../../constants/enums';

const EditingButton = ({
  className,
  disabled,
  icon,
  iconPosition,
  gaLabel,
  onClick,
  text,
  onFocus,
  ariaLabel,
  tabIndex,
}) => {
  const clickHandler = useCallback(() => {
    onClick();
    if (gaLabel) {
      GA.logInteraction({
        category: GaCategories.BEHAVIOR,
        action: GaActions.BUTTON_CLICK,
        label: gaLabel,
      });
    }
  }, [gaLabel, onClick]);

  return (
    <button
      aria-label={ariaLabel}
      className={`${classNames('ayo-editing-button', {
        'Mui-disabled': disabled,
        [`${className}`]: className,
      })}`}
      disabled={disabled}
      onClick={clickHandler}
      onFocus={onFocus}
      tabIndex={tabIndex}
      type="button"
    >
      {iconPosition === 'start' && icon}
      {text && <Typography variant="body2">{text}</Typography>}
      {iconPosition === 'end' && icon}
    </button>
  );
};

EditingButton.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.node.isRequired,
  iconPosition: PropTypes.oneOf(['start', 'end']),
  onClick: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  text: PropTypes.string,
  gaLabel: PropTypes.string,
  ariaLabel: PropTypes.string,
  tabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

EditingButton.defaultProps = {
  className: '',
  disabled: false,
  iconPosition: 'start',
  gaLabel: '',
  onFocus: () => {},
  text: '',
  ariaLabel: null,
  tabIndex: '0',
};

export default EditingButton;
