import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

import { Typography } from '../../../../../atoms';
import { getFullDateAndHoursString } from '../../../../../../utils';

const DisplayAssignmentDeadlines = ({ deadlines }) => {
  const { t, i18n } = useTranslation();

  return (
    !!deadlines.length && (
      <Box display="flex">
        {deadlines.map(
          (deadline) =>
            deadline.value && (
              <Box key={deadline.title} mr={3}>
                <Typography variant="subtitle2">{t(deadline.title)}</Typography>
                <Box mt={1}>
                  <Typography variant="body2">
                    {getFullDateAndHoursString(deadline.value, i18n.language)}
                  </Typography>
                </Box>
              </Box>
            ),
        )}
      </Box>
    )
  );
};

DisplayAssignmentDeadlines.propTypes = {
  deadlines: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
};

DisplayAssignmentDeadlines.defaultProps = {
  deadlines: [],
};

export default DisplayAssignmentDeadlines;
