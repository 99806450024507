import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Container, useMediaQuery, useTheme } from '@mui/material';
import classNames from 'classnames';

import { Typography } from '../../../../../atoms';
import { ReactComponent as AptitudesHowToLearnAnalysisBlock } from '../../../../../../resources/images/aptitudes/landing-page/how-to-learn-aptitudes-analysis-block.svg';
import { ReactComponent as AptitudesHowToLearnPredictionsBlock } from '../../../../../../resources/images/aptitudes/landing-page/how-to-learn-aptitudes-predictions-block.svg';
import { ReactComponent as AptitudesHowToLearnSharesResultsBlock } from '../../../../../../resources/images/aptitudes/landing-page/how-to-learn-aptitudes-shares-results-block.svg';

const AptitudesHowToLearnSectionConfig = [
  {
    alt: 'Graphs representing test results, participating in debates, and achievements',
    description:
      'AYO analyses students’ success in particular fields during their studies, such as extracurriculars, test results, achievements and others',
    illustration: AptitudesHowToLearnAnalysisBlock,
    title: 'Analysis first',
  },
  {
    alt: 'A fortune teller ball with a pair of hands over it',
    description: 'AYO predicts which skills students may potentially have based on these insights',
    illustration: AptitudesHowToLearnPredictionsBlock,
    title: 'Predictions and assumptions',
  },
  {
    alt: 'A laptop with AYO sharing aptitude results',
    description: 'AYO shares results in the students’ profiles',
    illustration: AptitudesHowToLearnSharesResultsBlock,
    title: 'Results',
  },
];

const AptitudesHowToLearnSection = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const isWidthUpMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box mt={isWidthUpSm ? 20 : 5}>
      <Container>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          mb={5}
        >
          <Typography align="center" component="h2" paragraph variant="h2">
            {t('How AYO learns about aptitudes?')}
          </Typography>
          <Typography align="center" variant="body2">
            {t('Learn how AYO gets knowledge about what your strengths and abilities are')}
          </Typography>
        </Box>
      </Container>
      <Box className="ayo-aptitudes-how-to-learn-section">
        <Container>
          {AptitudesHowToLearnSectionConfig.map((item) => (
            <Box
              key={item.title}
              alignItems="center"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              mb={isWidthUpSm ? 20 : 5}
            >
              <item.illustration
                aria-label={t(item.alt)}
                className={`${classNames({
                  'ayo-svg-illustration': !isWidthUpMd,
                })}`}
              />
              <Box mb={1} mt={3}>
                <Typography component="h3" variant="subtitle1">
                  {t(item.title)}
                </Typography>
              </Box>
              <Typography
                align="center"
                className="ayo-aptitudes-how-to-learn-section__description"
                component="p"
                variant="subtitle2"
              >
                {t(item.description)}
              </Typography>
            </Box>
          ))}
        </Container>
      </Box>
    </Box>
  );
};

export default AptitudesHowToLearnSection;
