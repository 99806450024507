import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { Box, Container, Tab, useMediaQuery, useTheme } from '@mui/material';

import { TabPanel, Tabs, Typography } from '../../../atoms';
import { PageEndScrollToTopButton } from '../../../moleculas';
import { MobileNotSupported, PageWrapper } from '../../../organisms';
import { useProfile } from '../../../../utils';
import {
  ContentModerationTypesMap,
  PrivilegesMap,
  SchoolLevels,
} from '../../../../constants/enums';
import { contentRoute } from '../../../../constants/routes';

import InterestsModeration from './components/interests-moderation/InterestsModeration';
import OpportunitiesModeration from './components/opportunities-moderation/OpportunitiesModeration';
import ContentModerationActivityLog from './components/activity-log/ContentModerationActivityLog';

const PageTitlesMap = {
  [SchoolLevels.ELEMENTARY]: 'Elementary schools',
  [SchoolLevels.MIDDLE]: 'Middle schools',
  [SchoolLevels.HIGH]: 'High schools',
};

const ContentEditPage = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
  const { getPrivilegeStatus } = useProfile();
  const isEditApproveGranted = getPrivilegeStatus(PrivilegesMap.CONTENT_MODERATION_EDIT_APPROVE);
  const isShowHideGranted = getPrivilegeStatus(PrivilegesMap.CONTENT_MODERATION_SHOW_HIDE);

  const match = useRouteMatch();
  const { level } = match.params;

  const [activeTab, setActiveTab] = useState(ContentModerationTypesMap.INTERESTS);

  const handleTabClick = (e, value) => setActiveTab(value);

  return (
    <PageWrapper backToLink={contentRoute} backToText={t('Back to schools')}>
      {isWidthUpSm ? (
        <>
          <Container>
            <Box pb={5}>
              <Typography component="h1" variant="h1">
                {t(PageTitlesMap[level.toUpperCase()])}
              </Typography>
              <Typography isLabel variant="body2">
                {t('Here you can')}
                <ul>
                  {isEditApproveGranted && (
                    <li>
                      {t(
                        'Approve or edit Spanish translations that will be applied on all school levels',
                      )}
                    </li>
                  )}
                  {isShowHideGranted && (
                    <li>
                      {t('Show or hide a particular piece of content for students in AYO UI')}
                    </li>
                  )}
                </ul>
              </Typography>
            </Box>
            <Tabs onChange={handleTabClick} value={activeTab} variant="standard">
              <Tab
                disableRipple
                label={t(ContentModerationTypesMap.INTERESTS)}
                value={ContentModerationTypesMap.INTERESTS}
              />
              <Tab
                disableRipple
                label={t(ContentModerationTypesMap.OPPORTUNITIES)}
                value={ContentModerationTypesMap.OPPORTUNITIES}
              />
            </Tabs>
            <Box display="flex" flex={1} justifyContent="flex-end" pt={3}>
              <ContentModerationActivityLog
                schoolLevel={level.toUpperCase()}
                type={activeTab.toUpperCase()}
              />
            </Box>
            <Box pt={4}>
              <TabPanel currentTabValue={activeTab} value={ContentModerationTypesMap.INTERESTS}>
                <InterestsModeration
                  isEditApproveGranted={isEditApproveGranted}
                  isShowHideGranted={isShowHideGranted}
                  schoolLevel={level.toUpperCase()}
                />
              </TabPanel>
              <TabPanel currentTabValue={activeTab} value={ContentModerationTypesMap.OPPORTUNITIES}>
                <OpportunitiesModeration
                  isEditApproveGranted={isEditApproveGranted}
                  isShowHideGranted={isShowHideGranted}
                  schoolLevel={level.toUpperCase()}
                />
              </TabPanel>
            </Box>
          </Container>
          <PageEndScrollToTopButton />
        </>
      ) : (
        <MobileNotSupported
          explanation={t(
            'Please open the content moderation tool from a tablet or desktop version to use this functionality',
          )}
        />
      )}
    </PageWrapper>
  );
};

export default ContentEditPage;
