import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Container, Grid, useMediaQuery, useTheme } from '@mui/material';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { Button, Image, Typography } from '../../../../../atoms';
import { Chip } from '../../../../../moleculas';
import { UserContext } from '../../../../../../context';
import { useLandingPageConfig } from '../../../../../../utils';
import { RolesMap } from '../../../../../../constants/enums';

const LessonPlannerExploreMoreSectionConfig = [
  {
    buttonTitle: 'Go to My lessons',
    chipLabel: 'For educators',
    className: 'teacher',
    description: {
      loggedIn: 'Check AYO Lesson planner in My lessons!',
      loggedOut:
        'Are you ready to enhance your lesson planning strategy with innovative ideas and engage students in the learning process?',
    },
    imageAlt: 'A teacher and a student with a laptop in a classroom.',
    imageSrc: '/media/lesson-planner/explore_more_section_teacher.png',
    infoCaption:
      '200+ educators have already explored this tool in AYO (since May 2023). You too can be a part of this innovative movement by trying it out!',
    userRole: RolesMap.TEACHER,
  },
  {
    buttonTitle: 'Go to My classes',
    chipLabel: 'For students',
    className: 'student',
    description: {
      loggedIn:
        'Are you ready to embark on the exciting learning adventure that your educators have already planned for you?',
      loggedOut: 'Check what educators have already planned for your learning adventure!',
    },
    imageAlt: 'Close-up of a student with a pencil.',
    imageSrc: '/media/lesson-planner/explore_more_section_student.png',
    userRole: RolesMap.STUDENT,
  },
  {
    buttonTitle: 'Explore initiatives',
    chipLabel: 'For parents',
    className: 'parent',
    description: {
      loggedIn:
        'This tool is primarily for teachers, but you can also learn more about other AYO initiatives',
      loggedOut:
        'Log in to AYO to stay informed about how this tool is changing your kid’s educational journey!',
    },
    imageAlt: 'Two smiling adults.',
    imageSrc: '/media/lesson-planner/explore_more_section_parent.png',
    userRole: RolesMap.GUARDIAN,
  },
];

const LessonPlannerExploreMoreBlock = ({
  buttonTitle,
  chipLabel,
  className,
  description,
  handler,
  imageAlt,
  imageSrc,
  infoCaption,
  userRole,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const isWidthUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const isWidthUpLg = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Grid
      className={classnames(
        'ayo-lesson-planner-landing__explore-more-section__block',
        `ayo-lesson-planner-landing__explore-more-section__block--${className}`,
      )}
      container
      direction={isWidthUpSm ? 'row' : 'column-reverse'}
      justifyContent={isWidthUpSm ? 'space-between' : 'center'}
      px={isWidthUpMd ? 9 : 3}
      py={isWidthUpSm ? 6.5 : 10}
    >
      <Grid
        alignContent="center"
        container
        item
        justifyContent="center"
        lg={6}
        mt={isWidthUpSm ? 0 : 7}
        pr={isWidthUpLg ? 6 : 0}
        sm={6}
        xl={7}
        xs={12}
      >
        {chipLabel && <Chip label={t(chipLabel)} />}
        <Typography align="center" component="h2" isLightText variant="h2">
          {t(description)}
        </Typography>
        <Box mt={5}>
          <Button
            gaLabel={buttonTitle}
            onClick={handler}
            variant={userRole === RolesMap.TEACHER ? 'primary' : 'text'}
          >
            {t(buttonTitle)}
          </Button>
        </Box>
        {infoCaption && (
          <Box mt={5} px={isWidthUpLg ? 8 : 0}>
            <Typography align="center" isLightText variant="caption">
              {t(infoCaption)}
            </Typography>
          </Box>
        )}
      </Grid>
      <Grid
        alignContent="center"
        container
        item
        justifyContent={isWidthUpSm ? 'right' : 'center'}
        lg={5}
        maxWidth="100%"
        pl={isWidthUpLg ? 6 : isWidthUpSm ? 3 : 0}
        sm={6}
        xs={12}
      >
        <Image alt={t(imageAlt)} src={imageSrc} />
      </Grid>
    </Grid>
  );
};

LessonPlannerExploreMoreBlock.propTypes = {
  buttonTitle: PropTypes.string.isRequired,
  chipLabel: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  handler: PropTypes.func.isRequired,
  imageAlt: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
  infoCaption: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
};

const LessonPlannerExploreMoreSection = () => {
  const { state: userState } = useContext(UserContext);
  const userRole = userState?.profile?.role;

  const { landingPageLoginSectionConfig, landingPageRedirectHandler } = useLandingPageConfig(
    LessonPlannerExploreMoreSectionConfig,
  );

  if (!landingPageLoginSectionConfig?.length) {
    return null;
  }
  return (
    <Box className="ayo-lesson-planner-landing__explore-more-section" py={10}>
      <Container>
        <Grid container direction="column" spacing={3}>
          {landingPageLoginSectionConfig.map((item) => (
            <Grid key={item.className} item xs={12}>
              <LessonPlannerExploreMoreBlock
                buttonTitle={userRole ? item.buttonTitle : item.label}
                chipLabel={!userRole ? item.chipLabel : ''}
                className={item.className}
                description={userRole ? item.description.loggedIn : item.description.loggedOut}
                handler={() =>
                  landingPageRedirectHandler(
                    item.loginProvider,
                    item.loginRedirectKey,
                    item.lessonPlannerRedirectUrl,
                  )
                }
                imageAlt={item.imageAlt}
                imageSrc={item.imageSrc}
                infoCaption={!userRole && item.infoCaption ? item.infoCaption : ''}
                userRole={item.userRole}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default LessonPlannerExploreMoreSection;
