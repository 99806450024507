import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { ActionsMenu, Button, Card, Typography } from '../../../../atoms';
import { getIsDomElementCropped } from '../../../../../utils';
import { EndorsementIllustrationsByTitle } from '../../../../../constants/endorsements';
import { EditingButton } from '../../../../moleculas';
import { ReactComponent as DeleteIcon } from '../../../../../resources/icons/delete_primary.svg';
import { ReactComponent as CreateIcon } from '../../../../../resources/icons/create.svg';
import { ReactComponent as ExpandIcon } from '../../../../../resources/icons/chevron_down.svg';
import { ReactComponent as CollapseIcon } from '../../../../../resources/icons/chevron_up.svg';

const DESCRIPTION_PADDING = 16;
const EndorsementCard = React.forwardRef(
  (
    {
      endorsement,
      isActive,
      isInterestedEndorsements,
      isStudent,
      isReadOnlyMode,
      onDeleteReason,
      setActiveEndorsementConfig,
      setIsDialogOpen,
    },
    ref,
  ) => {
    const { t, i18n } = useTranslation();
    const [isDescriptionCropped, setIsDescriptionCropped] = useState(null);
    const [isDescriptionCollapsed, setIsDescriptionCollapsed] = useState(true);
    const detailsRef = useRef(null);
    const details = useMemo(() => {
      const detailsStrings = [];
      const ativatorQuestions = [];
      endorsement.sharedDetails?.forEach((detail) => {
        if (
          endorsement.sharedDetails.some((option) => option.activatorOptionId === detail.optionId)
        ) {
          ativatorQuestions.push(detail.optionId);
        }
        if (
          detail.value &&
          detail.selected !== false &&
          !ativatorQuestions.includes(detail.optionId)
        ) {
          detailsStrings.push(
            detail.activatorOptionId
              ? `${
                  endorsement.sharedDetails.find(
                    (option) => option.optionId === detail.activatorOptionId,
                  ).value
                }: ${detail.value}`
              : detail.value,
          );
        }
      });
      return detailsStrings;
    }, [endorsement]);

    useEffect(() => {
      setIsDescriptionCollapsed(true);
      setIsDescriptionCropped(
        getIsDomElementCropped(
          detailsRef.current?.offsetHeight,
          DESCRIPTION_PADDING,
          detailsRef.current?.offsetParent?.clientHeight,
        ),
      );
    }, [details, i18n.language]);
    const onEditReasonHandler = useCallback(
      (isEdit) => {
        setActiveEndorsementConfig({ endorsement, isEditMode: isEdit });
        setIsDescriptionCollapsed(true);
        setIsDialogOpen(true);
      },
      [endorsement, setActiveEndorsementConfig, setIsDialogOpen],
    );

    const onDeleteReasonHandler = useCallback(() => {
      onDeleteReason(endorsement.questionId, endorsement.id);
    }, [endorsement, onDeleteReason]);

    return (
      <Card
        ref={ref}
        className={classnames('ayo-endorsement-card', {
          'ayo-endorsement-card--inactive': !isActive,
          'ayo-endorsement-card--interested': isInterestedEndorsements,
        })}
        elevation={!isActive && !isReadOnlyMode ? 1 : 0}
        imageSrc={EndorsementIllustrationsByTitle[endorsement.nameTranslationKey].image}
        mainContent={
          <Box>
            <Typography variant="subtitle2">{endorsement.endorsementName}</Typography>
            {!isActive && (!!details.length || !isReadOnlyMode) && (
              <Box className="ayo-endorsement-card__details-box" mt={2}>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="subtitle2">
                    {t(isStudent ? 'Why did you switch from it?' : 'Why was it switched from?')}
                  </Typography>
                  {!isReadOnlyMode && !!details.length && (
                    <Box className="ayo-endorsement-card__details-box__action-buttons" ml={1}>
                      <ActionsMenu
                        id="Endorsements actions"
                        menuItems={[
                          {
                            text: t('Edit'),
                            icon: CreateIcon,
                            handler: () => {
                              onEditReasonHandler(true);
                            },
                            id: 'edit',
                            gaLabel: 'Edit comment',
                          },
                          {
                            text: t('Delete'),
                            icon: DeleteIcon,
                            handler: onDeleteReasonHandler,
                            id: 'delete',
                            gaLabel: 'Delete comment',
                          },
                        ]}
                      />
                    </Box>
                  )}
                </Box>
                {details.length ? (
                  <Box mt={1}>
                    <Typography
                      className={classnames('ayo-endorsement-card__details-box__description', {
                        'ayo-endorsement-card__details-box__description--collapsed':
                          !isDescriptionCollapsed,
                      })}
                      variant="body2"
                    >
                      <span ref={detailsRef}>{details?.join('\n')}</span>
                    </Typography>
                    {isDescriptionCropped && (
                      <Box my={1}>
                        {isDescriptionCollapsed ? (
                          <EditingButton
                            gaLabel="Show more"
                            icon={<ExpandIcon />}
                            iconPosition="end"
                            onClick={() => {
                              setIsDescriptionCollapsed(false);
                            }}
                            text={t('Show more')}
                          />
                        ) : (
                          <EditingButton
                            gaLabel="Show less"
                            icon={<CollapseIcon />}
                            iconPosition="end"
                            onClick={() => {
                              setIsDescriptionCollapsed(true);
                            }}
                            text={t('Show less')}
                          />
                        )}
                      </Box>
                    )}
                  </Box>
                ) : (
                  <Box mt={2}>
                    <Button
                      gaLabel="Share with AYO!"
                      onClick={() => onEditReasonHandler(false)}
                      variant="secondary"
                    >
                      {t('Share with AYO!')}
                    </Button>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        }
        mediaContent="img"
        mediaContentAriaLabel={EndorsementIllustrationsByTitle[endorsement.nameTranslationKey].text}
      />
    );
  },
);

EndorsementCard.propTypes = {
  endorsement: PropTypes.instanceOf(Object).isRequired,
  isActive: PropTypes.bool,
  isInterestedEndorsements: PropTypes.bool,
  isStudent: PropTypes.bool,
  isReadOnlyMode: PropTypes.bool,
  onDeleteReason: PropTypes.func,
  setActiveEndorsementConfig: PropTypes.func,
  setIsDialogOpen: PropTypes.bool,
};

EndorsementCard.defaultProps = {
  isActive: true,
  isInterestedEndorsements: false,
  isStudent: false,
  isReadOnlyMode: false,
  onDeleteReason: () => {},
  setActiveEndorsementConfig: () => {},
  setIsDialogOpen: false,
};

export default EndorsementCard;
