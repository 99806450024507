import React, { useState } from 'react';
import { Box } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Button, ErrorWrapper, Tooltip, Typography } from '../../../../../atoms';
import { AccordionItem, MlChip } from '../../../../../moleculas';
import { ReactComponent as AddIcon } from '../../../../../../resources/icons/add.svg';
import { ReactComponent as MinusIcon } from '../../../../../../resources/icons/minus.svg';

import TeksBlock from './teks-block/TeksBlock';
import TeksDialog from './teks-dialog/TeksDialog';

const LessonPageTeksContent = ({
  allTeks,
  chosenTeks,
  error,
  id,
  isStatic,
  onChange,
  suggestedTeks,
}) => {
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  return !!allTeks?.length && (chosenTeks.length || !isStatic) ? (
    <ErrorWrapper errorMessage={error} isAbsolutePosition typographyVariant="body2">
      <Box
        className={classNames('ayo-lesson-page__section__content', {
          'ayo-lesson-page__section__content--static': isStatic,
        })}
        id={id}
      >
        <Box alignItems="center" display="flex" mb={3}>
          <Typography variant="h2">{isStatic ? 'TEKS' : `${t('TEKS to cover')}*`}</Typography>
        </Box>
        {!!suggestedTeks.length && !isStatic && (
          <Box mb={3}>
            <AccordionItem
              bodyEl={
                <TeksBlock
                  btnConfig={{
                    ariaLabel: 'Add TEKS',
                    gaLabel: 'Add TEKS',
                    onClick: (item) => {
                      onChange(item);
                    },
                    icon: <AddIcon />,
                  }}
                  teksData={suggestedTeks}
                />
              }
              className="ayo-lesson-page-teks__suggested"
              gaLabel="TEKS insights"
              headerEl={
                <Box
                  alignItems="center"
                  className="ayo-lesson-page-teks__suggested__header"
                  display="flex"
                  flex={1}
                  justifyContent="space-between"
                >
                  <Typography variant="subtitle2">
                    {t('Are you covering any of the TEKS below today?')}
                  </Typography>
                  <Tooltip title={t('TEKS insights badge tooltip')}>
                    <MlChip text={t('AYO insight')} />
                  </Tooltip>
                </Box>
              }
              isDefaultExpanded
            />
          </Box>
        )}
        {!!chosenTeks.length && (
          <Box className="ayo-lesson-page__subcontainer" p={isStatic ? 0 : 3}>
            {!isStatic && (
              <Typography
                className="ayo-lesson-page__subcontainer__added-title"
                variant="subtitle2"
              >
                {t('Added')}
              </Typography>
            )}
            <TeksBlock
              btnConfig={
                !isStatic
                  ? {
                      ariaLabel: 'Remove TEKS',
                      gaLabel: 'Remove TEKS',
                      onClick: (item) => {
                        onChange(item, false, true);
                      },
                      icon: <MinusIcon />,
                    }
                  : null
              }
              teksData={chosenTeks}
            />
          </Box>
        )}
        {!isStatic && allTeks.length > chosenTeks.length && (
          <>
            <Box mb={3} mt={4}>
              <Typography variant="body2">
                <Trans
                  components={{ br: <br /> }}
                  i18nKey="Haven’t found above the TEKS that you need to cover today with this lesson?"
                />
              </Typography>
            </Box>
            <Button
              aria-label={t('Add TEKS')}
              endIcon={<AddIcon />}
              gaLabel="Add TEKS"
              onClick={() => setIsModalOpen(true)}
            >
              {t('Add TEKS')}
            </Button>
          </>
        )}
        <TeksDialog
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onSave={onChange}
          selectedTeks={chosenTeks}
        />
      </Box>
    </ErrorWrapper>
  ) : null;
};

LessonPageTeksContent.propTypes = {
  allTeks: PropTypes.arrayOf(PropTypes.shape({ display: PropTypes.string, text: PropTypes.string }))
    .isRequired,
  error: PropTypes.string,
  id: PropTypes.string,
  isStatic: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  chosenTeks: PropTypes.arrayOf(
    PropTypes.shape({ display: PropTypes.string, text: PropTypes.string }),
  ).isRequired,
  suggestedTeks: PropTypes.arrayOf(
    PropTypes.shape({ display: PropTypes.string, text: PropTypes.string }),
  ).isRequired,
};

LessonPageTeksContent.defaultProps = {
  error: '',
  id: null,
};

export default LessonPageTeksContent;
