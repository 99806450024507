/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Container, Grid, useMediaQuery, useTheme } from '@mui/material';

import { Typography, Button, Tooltip, DotIndicator } from '../../atoms';
import {
  AptitudeInfoCard,
  DisplayAvatarEditorImage,
  InitiativeEmptyStateBlock,
  NewFeatureHotspot,
  PageEndScrollToTopButton,
} from '../../moleculas';
import {
  AchievementsSection,
  AptitudesSection,
  AvatarEditDialog,
  BadgesProfileBlock,
  ClubHubSection,
  EndorsementsSection,
  ExtracurricularsSection,
  GoalsBlock,
  InterestsViewmap,
  LeadershipProfileSection,
  MoodSectionStudent,
  OpportunitiesBlock,
  PageWrapper,
} from '../../organisms';
import { useStudentsService } from '../../../services';
import { UserContext } from '../../../context';
import {
  filterOutNotInterested,
  getInterestBubbleProperties,
  getInterestWithSpacer,
  getNormalizedLanguageCode,
  shuffle,
} from '../../../utils';
import { useNotificationsData } from '../../../hooks';
import { AttachmentsResourcesTypes, InterestsTypeMap } from '../../../constants/enums';
import {
  myProfileAchievementsSection,
  portfoliosRoute,
  trainAyoInterestsAppRoute,
} from '../../../constants/routes';
import { ReactComponent as InterestsMapEmptyStateIllustration } from '../../../resources/images/interests_map_with_wave.svg';
import { ReactComponent as VectorIcon } from '../../../resources/icons/vector.svg';
import { ReactComponent as PaletteIcon } from '../../../resources/icons/palette.svg';
import { ReactComponent as CreateIcon } from '../../../resources/icons/create.svg';
import { ReactComponent as PlusIcon } from '../../../resources/icons/plus.svg';
import { NewFeaturesIdsMap } from '../../../tours/common/NewFeaturesItemsProvider';

const MyProfilePage = () => {
  const { t, i18n } = useTranslation();
  const { state: userState } = useContext(UserContext);
  const { getStudentInterests, getStudentBaseInfo, deleteStudentAvatar, postStudentAvatar } =
    useStudentsService();
  const { isPortfolioHasUpdates } = useNotificationsData();

  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });

  const student = userState.profile;

  const [studentInterests, setStudentInterests] = useState([]);
  const [isAvatarEdit, setIsAvatarEdit] = useState(false);
  const [studentInfo, setStudentInfo] = useState({});

  const { avatar, id: studentId } = studentInfo;

  const onAvatarEditClose = useCallback(() => setIsAvatarEdit(false), []);

  useEffect(() => {
    getStudentInterests(student.id, getNormalizedLanguageCode(i18n.language)).then(
      (studentInterestsData) => {
        const likedInterests = filterOutNotInterested(
          studentInterestsData[InterestsTypeMap.SELECTED_INTERESTS],
        ).liked;
        getStudentBaseInfo(student.id).then((data) => setStudentInfo(data));
        const allInterests = shuffle(likedInterests);
        const bubbleInterests = allInterests
          .map((interest) => ({
            ...interest,
            ...getInterestBubbleProperties(interest),
            subInterests: [],
          }))
          .map((interest) => getInterestWithSpacer(interest, isWidthUpSm));
        setStudentInterests(bubbleInterests);
      },
    );
  }, [getStudentInterests, isWidthUpSm, student.id, i18n.language, getStudentBaseInfo]);

  const onAvatarSubmit = (attachment, positionAndZoom) => {
    setStudentInfo({ ...studentInfo, avatar: { image: attachment, transforms: positionAndZoom } });
  };

  const InterestsEmptyState = (
    <InitiativeEmptyStateBlock
      body={t('It should be fun!')}
      buttonContent={t('Explore in Train AYO')}
      buttonGaLabel="Explore in Train AYO - Interests"
      buttonLink={trainAyoInterestsAppRoute}
      illustration={<InterestsMapEmptyStateIllustration />}
      showNavigationButton
      title={t('Let’s explore your interests')}
    />
  );

  const portfolioButton = (
    <Tooltip
      enterTouchDelay={200}
      isDisabled={!isPortfolioHasUpdates}
      placement="right"
      title={t('You have new updates')}
    >
      <Button
        aria-label={`${t('Portfolio')} ${isPortfolioHasUpdates ? t('You have new updates') : ''}`}
        component={RouterLink}
        endIcon={
          <Box alignItems="center" display="flex" flexDirection="row">
            <Box mr={0.5}>
              <PaletteIcon />
            </Box>
            <DotIndicator invisible={!isPortfolioHasUpdates} overlap="circular" />
          </Box>
        }
        gaLabel="Portfolio"
        to={portfoliosRoute}
      >
        {t('Portfolio')}
      </Button>
    </Tooltip>
  );

  return (
    <PageWrapper mainElementClassName="ayo-my-profile-page">
      <Box pb={isWidthUpSm ? 10 : 5}>
        <Container>
          <Grid item xs={12}>
            <Box
              alignItems="flex-start"
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              mb={5}
            >
              <Box display="flex" flexDirection={isWidthUpSm ? 'row' : 'column'} gap={3}>
                <Box alignItems="center" display="flex" flexDirection="column">
                  <DisplayAvatarEditorImage
                    avatar={avatar?.image}
                    avatarSize={120}
                    avatarTransforms={avatar?.transforms}
                  />
                  <Box alignItems="center" display="flex" mt={2}>
                    <Button
                      endIcon={avatar?.image ? <CreateIcon /> : <PlusIcon />}
                      fullWidth
                      gaLabel={avatar?.image ? 'Edit photo' : 'Add photo'}
                      onClick={() => setIsAvatarEdit(true)}
                    >
                      {avatar?.image ? t('Edit photo') : t('Add photo')}
                    </Button>
                    <NewFeatureHotspot
                      id={NewFeaturesIdsMap.STUDENT_AVATAR}
                      isClickable
                      label={t('nft.student_avatar.title')}
                    />
                  </Box>
                </Box>
                <Box>
                  <Typography component="h1" variant="h1">
                    {t('My profile')}
                  </Typography>
                  <Box mt={1}>
                    <Typography variant="body2">
                      {t('Here, you can see how your profile looks like now')}.
                    </Typography>
                    <Typography variant="body2">
                      {t('Do not hesitate to adjust it as soon as you feel the need!')}
                    </Typography>
                  </Box>
                  {!isWidthUpSm && portfolioButton}
                </Box>
              </Box>
              {isWidthUpSm && portfolioButton}
            </Box>
            <Box mb={7}>
              <MoodSectionStudent />
            </Box>
            <ClubHubSection studentId={userState.profile.id} />
            <Box mb={7}>
              <Box
                alignItems={isWidthUpSm ? 'center' : 'flex-start'}
                display="flex"
                flexDirection={isWidthUpSm ? 'row' : 'column'}
                justifyContent="space-between"
                mb={3}
              >
                <Typography component="h2" variant="h2">
                  {t('My interests')}
                </Typography>
                {studentInterests.length ? (
                  <Button
                    component={RouterLink}
                    endIcon={<VectorIcon />}
                    gaLabel="Explore in Train AYO - Interests"
                    to={trainAyoInterestsAppRoute}
                  >
                    {t('Explore in Train AYO')}
                  </Button>
                ) : null}
              </Box>
              {studentInterests.length ? (
                <Box>
                  <Grid container item xs={12}>
                    <Grid item xs={12}>
                      <Box>
                        <InterestsViewmap interests={studentInterests} />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              ) : (
                InterestsEmptyState
              )}
            </Box>
            <Box mb={7}>
              <AptitudesSection
                CardComponent={AptitudeInfoCard}
                emptyBody={t('AYO is still looking for any!')}
                emptyTitle={t('No aptitudes found so far')}
                isLazyLoad
                studentId={userState.profile.id}
                title="My aptitudes"
              />
            </Box>
            <Box mb={7}>
              <EndorsementsSection
                isLazyLoad
                isReadOnlyMode
                isWithNavigationButton
                title="My endorsements"
              />
            </Box>
            <Box mb={7}>
              <OpportunitiesBlock isLazyLoad title="My opportunities" titleVariant="h2" />
            </Box>
            <Box mb={7}>
              <LeadershipProfileSection
                isLazyLoad
                studentId={userState.profile.id}
                titleVariant="h2"
              />
            </Box>
            <Box mb={7}>
              <Grid item xs={12}>
                <ExtracurricularsSection
                  isLazyLoad
                  studentId={userState.profile.id}
                  title={t('My extracurriculars')}
                  titleVariant="h2"
                  withAdjustButton
                />
              </Grid>
            </Box>
            <Box id={myProfileAchievementsSection.replace('#', '')}>
              <AchievementsSection
                isLazyLoad
                studentId={student.id}
                title="My achievements & participation"
                titleVariant="h2"
              />
            </Box>
            <Box>
              <BadgesProfileBlock isLazyLoad />
            </Box>
            <Box>
              <GoalsBlock isLazyLoad showHeaderAndEmptyState studentId={userState.profile.id} />
            </Box>
          </Grid>
        </Container>
        <PageEndScrollToTopButton />
      </Box>
      <AvatarEditDialog
        avatarType="Student"
        currentImage={avatar?.image}
        currentTransforms={avatar?.transforms}
        deleteAvatarHandler={deleteStudentAvatar}
        isOpen={isAvatarEdit}
        onClose={onAvatarEditClose}
        onSubmit={onAvatarSubmit}
        ownerId={studentId}
        resourceId={studentId}
        resourceType={AttachmentsResourcesTypes.USER_AVATAR}
        showGuidelines
        updateAvatarHandler={(imageData) => postStudentAvatar(imageData)}
        visibilityInfoCaptionTitle="Your photo is visible for your parents and educators in AYO."
      />
    </PageWrapper>
  );
};

export default MyProfilePage;
