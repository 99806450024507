import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Box, Container, Grid, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

import { Typography } from '../../../../../atoms';
import { EditingButton } from '../../../../../moleculas';
import { EventsBlock, PageWrapper } from '../../../../../organisms';
import { ClubRights, ClubsMembershipStatuses } from '../../../../../../constants/club-hub';
import {
  clubHubProfileDetailsRoute,
  clubHubRoute,
  familyFeedRoute,
} from '../../../../../../constants/routes';
import { RolesMap, StudentsPage } from '../../../../../../constants/enums';
import { EventResourceTypes } from '../../../../../../constants/events';
import { UserContext } from '../../../../../../context';
import { useClubHubService } from '../../../../../../services';
import { useBreadcrumbsConfig, useClubHub, useEvents } from '../../../../../../hooks';
import { ReactComponent as ClubsEventsEmptyStateOwner } from '../../../../../../resources/images/club_events_empty_state_owner.svg';
import { ReactComponent as ClubsEventsEmptyStateVisitor } from '../../../../../../resources/images/club_events_empty_state_visitor.svg';
import { ReactComponent as ExpandIcon } from '../../../../../../resources/icons/chevron_down.svg';

const EVENTS_PAGE_SIZE = 20;
const EVENTS_DEFAULT_PAGE = 1;

const ClubEventsPage = () => {
  const { t } = useTranslation();

  const match = useRouteMatch();
  const clubId = Number(match.params.clubId);
  const studentId = Number(match.params.studentId);

  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  const { studentProfileBreadcrumbs } = useBreadcrumbsConfig(studentId);

  const { state: userState } = useContext(UserContext);
  const { role } = userState.profile;

  const isStudent = role === RolesMap.STUDENT;

  const [clubData, setClubData] = useState(null);

  const { getClubRights } = useClubHub();

  const { getClub } = useClubHubService();

  const {
    eventPostHandler,
    eventDeleteHandler,
    eventUpdateHandler,
    events,
    hasItemToLoad,
    loadMoreHandler,
    loadEventsData,
    totalElements,
  } = useEvents(EventResourceTypes.CLUB_HUB, clubId, EVENTS_PAGE_SIZE);

  const { clubName, membershipStatus } = clubData || {};

  const clubRights = useMemo(() => getClubRights(clubData), [clubData, getClubRights]);

  const isEventCreator = useMemo(() => clubRights[ClubRights.CREATE_EVENTS], [clubRights]);

  const isClubMember = useMemo(
    () => membershipStatus === ClubsMembershipStatuses.JOINED,
    [membershipStatus],
  );

  useEffect(() => {
    getClub(clubId).then((data) => {
      setClubData(data);
      loadEventsData(EVENTS_DEFAULT_PAGE);
    });
  }, [clubId, getClub, loadEventsData]);

  const BreadcrumbsConfig = useMemo(
    () =>
      studentId
        ? [
            ...studentProfileBreadcrumbs[role],
            {
              cropped: true,
              gaLabel: 'Club name',
              label: clubName,
              redirectUrl: `${
                role === RolesMap.GUARDIAN ? familyFeedRoute : StudentsPage[role]
              }/${studentId}${clubHubProfileDetailsRoute}/${clubId}`,
            },
            {
              gaLabel: null,
              label: isStudent ? t('Events') : t('Upcoming events'),
              redirectUrl: null,
            },
          ]
        : [
            { gaLabel: 'Club Hub', label: t('Club Hub'), redirectUrl: `${clubHubRoute}` },
            {
              cropped: true,
              gaLabel: 'Club name',
              label: clubName,
              redirectUrl: `${clubHubRoute}/${clubId}`,
            },
            {
              gaLabel: null,
              label: isStudent ? t('Events') : t('Upcoming events'),
              redirectUrl: null,
            },
          ],
    [clubId, clubName, isStudent, role, studentId, studentProfileBreadcrumbs, t],
  );

  return (
    <PageWrapper breadcrumbsConfig={BreadcrumbsConfig} mainElementClassName="ayo-club-hub-events">
      <Container>
        <Typography component="h1" mb={1} variant="h1">
          {isEventCreator ? t('Upcoming events') : t('Events')}
        </Typography>
        <Typography variant="body2">
          {t('This is a page dedicated to all upcoming events of the clubName', {
            clubName,
          })}
        </Typography>
        <Grid
          container
          item
          mb={8}
          md={events.length ? 6 : 12}
          mt={5}
          sm={events.length ? 8 : 12}
          xs={12}
        >
          {isClubMember && (
            <Typography mb={isWidthUpSm ? 5 : 3} variant="h2">{`${t(
              'All',
            )} (${totalElements})`}</Typography>
          )}
          <EventsBlock
            emptyStateConfig={{
              title: isEventCreator
                ? t('There are no upcoming events yet. Feel free to create a new one!')
                : t('No upcoming events available yet'),
              illustration: isEventCreator ? (
                <ClubsEventsEmptyStateOwner />
              ) : (
                <ClubsEventsEmptyStateVisitor />
              ),
            }}
            events={events}
            fullLabel={isWidthUpSm || isEventCreator}
            isEditable={isEventCreator}
            onCreate={eventPostHandler}
            onDelete={eventDeleteHandler}
            onUpdate={eventUpdateHandler}
            resourceId={clubId}
            resourceType={EventResourceTypes.CLUB_HUB}
            variant="calendar"
          />
          {hasItemToLoad && (
            <Box mt={3}>
              <EditingButton
                gaLabel="Show more"
                icon={<ExpandIcon />}
                iconPosition="end"
                onClick={loadMoreHandler}
                text={t('Show more')}
              />
            </Box>
          )}
        </Grid>
      </Container>
    </PageWrapper>
  );
};

export default ClubEventsPage;
