import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';

import { Typography } from '../../../../atoms';
import { NewFeatureHotspot } from '../../../../moleculas';

const InitiativeContainer = ({ children, id, title, newFeatureId, newFeatureLabel }) => (
  <Grid id={id} item xs={12}>
    <Box mb={7}>
      {title && (
        <Box alignItems="center" display="flex" mb={3}>
          <Typography component="h3" variant="subtitle1">
            {title}
          </Typography>
          {newFeatureId && (
            <NewFeatureHotspot id={newFeatureId} isClickable label={newFeatureLabel} />
          )}
        </Box>
      )}
      {children}
    </Box>
  </Grid>
);

InitiativeContainer.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
  title: PropTypes.string,
  newFeatureId: PropTypes.string,
  newFeatureLabel: PropTypes.string,
};

InitiativeContainer.defaultProps = {
  id: '',
  title: '',
  newFeatureId: null,
  newFeatureLabel: null,
};

export default InitiativeContainer;
