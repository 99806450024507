/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import classnames from 'classnames';

import { Tooltip, Typography } from '../../../atoms';
import { getIsDomElementCropped } from '../../../../utils';

const TextWithTooltip = ({
  className,
  containerProps,
  headerLevel,
  noWrap,
  rowsCount,
  title,
  titleProps,
  titleVariant,
  tooltipClassName,
  typographyProps,
}) => {
  const titleRef = useRef(null);
  const [isTitleTooltipDisabled, setIsTitleTooltipDisabled] = useState(true);

  const tooltipHandler = useCallback(() => {
    if (!title?.length) {
      setIsTitleTooltipDisabled(true);
    } else {
      setTimeout(() => {
        setIsTitleTooltipDisabled(
          getIsDomElementCropped(
            titleRef.current?.offsetParent?.clientHeight,
            0,
            titleRef.current?.offsetHeight,
          ),
        );
      }, 0);
    }
  }, [title]);

  useEffect(() => {
    tooltipHandler();
    window.addEventListener('resize', tooltipHandler);
    return () => window.removeEventListener('resize', tooltipHandler);
  }, [tooltipHandler, title, titleRef.current?.offsetParent?.clientHeight]);

  return (
    <Tooltip
      className={tooltipClassName}
      disableFocusListener={isTitleTooltipDisabled}
      disableHoverListener={isTitleTooltipDisabled}
      disableTouchListener={isTitleTooltipDisabled}
      enterTouchDelay={0}
      leaveTouchDelay={5000}
      placement="bottom"
      title={title}
    >
      <Box position="relative" {...containerProps}>
        <Typography
          className={classnames('ayo-text-with-tooltip-content', className, {
            'ayo-text-with-tooltip-content--no-wrap': noWrap,
            'ayo-text-with-tooltip-content--one-line': rowsCount === 1,
          })}
          component={headerLevel ? `h${headerLevel}` : 'p'}
          style={{
            WebkitLineClamp: rowsCount,
          }}
          tabIndex={!isTitleTooltipDisabled ? 0 : -1}
          variant={titleVariant}
          {...typographyProps}
        >
          <span ref={titleRef} {...titleProps}>
            {title}
          </span>
        </Typography>
      </Box>
    </Tooltip>
  );
};

TextWithTooltip.propTypes = {
  className: PropTypes.string,
  containerProps: PropTypes.instanceOf(Object),
  headerLevel: PropTypes.number,
  noWrap: PropTypes.bool,
  rowsCount: PropTypes.number,
  title: PropTypes.string.isRequired,
  titleProps: PropTypes.instanceOf(Object),
  titleVariant: PropTypes.string,
  tooltipClassName: PropTypes.string,
  typographyProps: PropTypes.instanceOf(Object),
};

TextWithTooltip.defaultProps = {
  className: '',
  containerProps: null,
  rowsCount: 1,
  headerLevel: null,
  noWrap: false,
  titleProps: null,
  titleVariant: 'inherit',
  tooltipClassName: '',
  typographyProps: null,
};

export default TextWithTooltip;
