import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Typography from '../typography/Typography';

const ErrorWrapper = ({
  children,
  className,
  errorMessage,
  isAbsolutePosition,
  typographyVariant,
}) => (
  <Box
    className={classNames('ayo-error-wrapper', className, {
      'ayo-error-wrapper--error': !!errorMessage,
    })}
  >
    {children}
    {!!errorMessage && (
      <Typography
        className={classNames('ayo-error-wrapper__label', {
          'ayo-error-wrapper__label--absolute': !!isAbsolutePosition,
        })}
        variant={typographyVariant}
      >
        {errorMessage}
      </Typography>
    )}
  </Box>
);

ErrorWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  isAbsolutePosition: PropTypes.bool,
  typographyVariant: PropTypes.string,
};

ErrorWrapper.defaultProps = {
  className: '',
  errorMessage: '',
  isAbsolutePosition: false,
  typographyVariant: 'body3',
};

export default ErrorWrapper;
