import React from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { AssignmentFields, PublicationStatuses } from '../../../../../../constants/enums';
import { editAssignmentType } from '../../../../../../constants/propTypes';
import AssignmentContainer from '../../assignment-container/AssignmentContainer';
import useAssignment from '../../use-assignment/useAssignment';

const ShortAnswerAssignment = ({
  assignment,
  onChange,
  validatorRef,
  courseStudents,
  onDelete,
  onReorder,
  isFirst,
  isLast,
  choices,
  onChoicesChange,
  onChoicesDelete,
  onChoicesLayerDelete,
}) => {
  const { t } = useTranslation();
  const titleText = t('Question');

  const {
    renderers: {
      renderTitle,
      renderInstruction,
      renderMaterials,
      renderStudents,
      renderSettings,
      renderPoints,
      renderDeadline,
      renderChoices,
    },
  } = useAssignment(
    assignment,
    choices,
    courseStudents,
    onChange,
    onChoicesChange,
    onChoicesDelete,
    onChoicesLayerDelete,
    validatorRef,
    [
      AssignmentFields.TITLE,
      AssignmentFields.INSTRUCTION,
      AssignmentFields.STUDENTS,
      AssignmentFields.POINTS,
      AssignmentFields.DEADLINES,
      AssignmentFields.MATERIALS,
      AssignmentFields.CHOICES,
    ],
    { titleText },
  );

  return (
    <AssignmentContainer
      currentOrder={assignment.assignmentOrder}
      description="Short answer question description"
      gcLink={assignment.classroomLink}
      groupCategory={assignment.studentGroupCategory}
      groupNames={assignment.studentGroupNames}
      id={assignment.id}
      isFirst={isFirst}
      isLast={isLast}
      isPublished={assignment.status === PublicationStatuses.PUBLISHED}
      onDelete={onDelete}
      onReorder={onReorder}
      title={t('Short answer question')}
    >
      <Box mb={5}>{renderTitle({ title: titleText, fieldLabel: t('Add question here') })}</Box>
      <Box mb={5}>{renderInstruction()}</Box>
      <Box mb={5}>{renderMaterials()}</Box>
      <Box mb={5}>{renderStudents()}</Box>
      <Box mb={5}>{renderPoints()}</Box>
      <Box mb={5}>{renderSettings()}</Box>
      <Box mb={5}>{renderDeadline()}</Box>
      <Box mb={3}>{renderChoices()}</Box>
    </AssignmentContainer>
  );
};

ShortAnswerAssignment.propTypes = editAssignmentType;

export default ShortAnswerAssignment;
