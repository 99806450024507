import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Container, useMediaQuery, useTheme } from '@mui/material';
import classnames from 'classnames';

import { Typography } from '../../../../../atoms';
import { ReactComponent as OpportunitiesPurposeIllustration } from '../../../../../../resources/images/opportunities/landing-page/opportunities-purpose.svg';

const OpportunitiesPurposeSection = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const isWidthUpXl = useMediaQuery(theme.breakpoints.up('xl'));

  return (
    <Box pb={isWidthUpSm ? 15.5 : 5} pt={isWidthUpSm ? 20 : 5}>
      <Container>
        <Box
          alignItems="center"
          display="flex"
          flexDirection={isWidthUpSm ? 'row' : 'column'}
          justifyContent="center"
        >
          <OpportunitiesPurposeIllustration
            aria-label={t('A superhero standing in the light of a projector')}
            className={classnames({
              'ayo-svg-illustration': !isWidthUpSm || isWidthUpXl,
            })}
          />
          <Box
            className="ayo-opportunities-page__content__text"
            ml={isWidthUpSm ? 3 : 0}
            mt={isWidthUpSm ? 0 : 5}
          >
            <Typography
              className="ayo-opportunities-page__content--typography-blue"
              component="h2"
              paragraph
              variant="h2"
            >
              {t('How can it be useful?')}
            </Typography>
            <Typography paragraph variant="body2">
              {t(
                'The opportunities shed light on the areas where students can put to use their skills and curiosity to become successful in school and beyond',
              )}
            </Typography>
            <Typography paragraph variant="body2">
              {t(
                'The students are accountable for their choices and will discover more and more about their passions. After all, AYO will shape a gallery based on students’ choices, showcasing books, activities, videos and many more!',
              )}
            </Typography>
            <Typography variant="body2">
              {t(
                'This aims to help students understand what they are passionate about and try out new things',
              )}
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default OpportunitiesPurposeSection;
