import PropTypes from 'prop-types';

import {
  AssignmentTypes,
  AttachmentsResourcesTypes,
  PublicationStatuses,
  NotificationInitiatorsMap,
} from '../enums';

export const personPropShape = {
  id: PropTypes.number,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
};

export const loginOptionItem = PropTypes.shape({
  loginProvider: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  loginRedirectKey: PropTypes.string,
  leadershipLoginRedirectUrl: PropTypes.string,
  caption: PropTypes.string,
});

export const landingBlock = PropTypes.shape({
  imageTitle: PropTypes.string,
  titleStart: PropTypes.string,
  titleEnd: PropTypes.string,
  titleBottom: PropTypes.string,
  loginOptions: PropTypes.arrayOf(loginOptionItem),
  instructionText: PropTypes.string,
  mainImg: PropTypes.node.isRequired,
});

export const personalizationBlock = PropTypes.shape({
  mainTitle: PropTypes.string,
  firstSubtitle: PropTypes.string,
  firstSubtitleBold: PropTypes.string,
  firstSubtitleEnd: PropTypes.string,
  downloadOptions: PropTypes.arrayOf(
    PropTypes.shape({
      gaLabel: PropTypes.string,
      label: PropTypes.string,
      link: PropTypes.string,
    }),
  ),
  illustrationText: PropTypes.string,
  illustrationImg: PropTypes.node.isRequired,
});

export const personasBlock = PropTypes.shape({
  title: PropTypes.string,
  personaItems: PropTypes.arrayOf(
    PropTypes.shape({
      img: PropTypes.node.isRequired,
      avatarTxt: PropTypes.string,
      title: PropTypes.string,
      text: PropTypes.string,
    }),
  ),
});

export const richTextLinkItem = {
  route: PropTypes.string,
  isDownload: PropTypes.bool,
  className: PropTypes.string,
  gaLabel: PropTypes.string,
  translationText: PropTypes.string,
  plainText: PropTypes.string,
};

export const richTextItem = {
  translationText: PropTypes.string,
  plainText: PropTypes.string,
  isEmpty: PropTypes.bool,
  isBold: PropTypes.bool,
  link: PropTypes.shape(richTextLinkItem),
};

export const articleTextItem = {
  variant: PropTypes.string,
  align: PropTypes.string,
  className: PropTypes.string,
  textNodes: PropTypes.arrayOf(richTextItem),
  component: PropTypes.string,
  isParagraph: PropTypes.bool,
};

export const articleBlock = {
  title: PropTypes.string,
  textItems: PropTypes.arrayOf(articleTextItem),
};

export const evidenceOfGrowthItem = {
  attachments: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    }),
  ),
  evidenceResourcesByType: PropTypes.shape({
    [AttachmentsResourcesTypes.GOAL]: PropTypes.shape({
      parentResourceId: PropTypes.string,
      resourceType: PropTypes.string,
      resourceId: PropTypes.string,
    }),
    [AttachmentsResourcesTypes.LEADERSHIP_ATTRIBUTE]: PropTypes.shape({
      parentResourceId: PropTypes.string,
      resourceType: PropTypes.string,
    }),
  }),
  id: PropTypes.number,
  link: PropTypes.string,
  ownerId: PropTypes.number,
  reflection: PropTypes.string,
  studentId: PropTypes.number,
  title: PropTypes.string.isRequired,
};

export const imgWithCaption = {
  src: PropTypes.node.isRequired,
  altTxt: PropTypes.string,
  className: PropTypes.string,
  caption: PropTypes.shape(articleTextItem),
};

export const timelineItem = {
  content: PropTypes.shape({
    variant: PropTypes.string,
    className: PropTypes.string,
    text: PropTypes.string,
  }),
  opposite: PropTypes.shape({
    variant: PropTypes.string,
    className: PropTypes.string,
    text: PropTypes.string,
  }),
};

export const timelineBlock = {
  items: PropTypes.shape(timelineItem),
};

export const quoteBlock = {
  avatarTxt: PropTypes.string,
  avatarImg: PropTypes.node.isRequired,
  name: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
};

export const initiativesBlock = {
  title: PropTypes.string,
  subtitleStart: PropTypes.string,
  subtitleEnd: PropTypes.string,
  initiativesButton: PropTypes.shape({
    title: PropTypes.string,
    link: PropTypes.string,
    gaLabel: PropTypes.string,
  }),
};

export const tryMobileAppBlock = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export const goalActionStepItem = PropTypes.shape({
  createdDate: PropTypes.string,
  dueDate: PropTypes.string,
  evidenceIds: PropTypes.arrayOf(PropTypes.string),
  id: PropTypes.number,
  name: PropTypes.string,
  reflection: PropTypes.string,
  reporterId: PropTypes.number,
  status: PropTypes.string,
  updatedDate: PropTypes.string,
});

export const goalItem = PropTypes.shape({
  createdDate: PropTypes.string,
  description: PropTypes.string,
  id: PropTypes.number,
  leadershipAttributeGroupKey: PropTypes.string,
  leadershipAttributeKey: PropTypes.string,
  milestones: PropTypes.arrayOf(PropTypes.shape({ goalActionStepItem })),
  name: PropTypes.string,
  reporter: PropTypes.shape({
    ...personPropShape,
    role: PropTypes.string,
  }),
  status: PropTypes.string,
  studentId: PropTypes.number,
  updatedDate: PropTypes.string,
});

export const goalDetailsItem = PropTypes.shape({
  id: PropTypes.number,
  leadershipAttributeKey: PropTypes.string,
  name: PropTypes.string,
  status: PropTypes.string,
  studentId: PropTypes.number,
});

export const mediaItem = {
  link: PropTypes.string,
  actionTxt: PropTypes.string,
  img: PropTypes.node.isRequired,
  imgAlt: PropTypes.string,
  content: PropTypes.string,
  contentLabel: PropTypes.string,
  date: PropTypes.string,
  sourceName: PropTypes.string,
  title: PropTypes.string,
};

export const mediaBlock = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  items: PropTypes.arrayOf(mediaItem),
};

export const roadMapBlock = {
  timeline: PropTypes.shape(timelineBlock),
  caption: PropTypes.shape(articleTextItem),
  titleTop: PropTypes.shape(articleTextItem),
  subTitleTop: PropTypes.shape(articleTextItem),
  titleBottom: PropTypes.shape(articleTextItem),
  subTitleBottom: PropTypes.shape(articleTextItem),
};

export const guideStepperStep = {
  name: PropTypes.string,
  content: PropTypes.array,
  video: PropTypes.string,
};

export const detailedGuideSteps = PropTypes.arrayOf(PropTypes.shape(guideStepperStep));

export const portfolioSectionData = {
  id: PropTypes.number,
  reflection: PropTypes.string,
  isEmpty: PropTypes.bool,
  entitiesIds: PropTypes.arrayOf(PropTypes.string),
  settings: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
};

export const portfolioSectionConfig = (metaData, sectionData) =>
  PropTypes.shape({
    addPortfolioItemHandler: PropTypes.func,
    deletePortfolioItemHandler: PropTypes.func,
    hasData: PropTypes.bool,
    isEditMode: PropTypes.bool,
    isVisible: PropTypes.bool,
    metaData,
    reflectionHandler: PropTypes.func,
    sectionData:
      sectionData ||
      PropTypes.shape({
        entities: PropTypes.arrayOf(PropTypes.string),
        id: PropTypes.number,
        isInitialState: PropTypes.bool,
        reflection: PropTypes.string,
        settings: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
      }),
    updatePortfolioHandler: PropTypes.func,
  });

export const assignmentType = PropTypes.shape({
  id: PropTypes.number,
  assignmentOrder: PropTypes.number,
  workType: PropTypes.oneOf(Object.values(AssignmentTypes)),
  status: PropTypes.oneOf(Object.values(PublicationStatuses)),
  title: PropTypes.string,
  description: PropTypes.string,
  points: PropTypes.number,
  submissionEditable: PropTypes.bool,
  submissionDeadline: PropTypes.string,
  postingDate: PropTypes.string,
  assignedStudentsEmails: PropTypes.arrayOf(PropTypes.string),
  classroomLink: PropTypes.string,
});

export const assignmentStudentType = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  email: PropTypes.string,
});

export const choicesPathLayerType = {
  id: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string,
  layer: PropTypes.number,
};

export const choicesPathType = {
  isMultipleOptions: PropTypes.bool,
  layers: PropTypes.arrayOf(PropTypes.shape(choicesPathLayerType)),
};

export const editAssignmentType = PropTypes.shape({
  assignment: assignmentType,
  onChange: PropTypes.func,
  validatorRef: PropTypes.shape({ current: PropTypes.func }),
  courseStudents: PropTypes.arrayOf(assignmentStudentType),
  onDelete: PropTypes.func,
  onReorder: PropTypes.func,
  isFirst: PropTypes.bool.isRequired,
  isLast: PropTypes.bool.isRequired,
  isOwner: PropTypes.bool,
  choices: PropTypes.shape(choicesPathType),
  onChoicesChange: PropTypes.func.isRequired,
  onChoicesLayerDelete: PropTypes.func.isRequired,
  onChoicesDelete: PropTypes.func.isRequired,
  hideAssignedStudents: PropTypes.bool,
});

export const staticAssignmentType = {
  assignment: assignmentType.isRequired,
  courseStudents: PropTypes.arrayOf(assignmentStudentType),
  choices: choicesPathType,
  isOwner: PropTypes.bool,
  onChoicesChange: PropTypes.func,
  onChoicesSelectedChange: PropTypes.func,
  canSelectChoices: PropTypes.bool,
};

export const assignmentInsightGroupnameType = {
  nameKey: PropTypes.string,
  value: PropTypes.string,
};

export const assignmentInsightGroupType = {
  groupNames: PropTypes.arrayOf(PropTypes.shape(assignmentInsightGroupnameType)),
  studentIds: PropTypes.arrayOf(PropTypes.number),
};

export const assignmentInsightsCategoryType = {
  categoryName: PropTypes.string.isRequired,
  groups: PropTypes.arrayOf(PropTypes.shape(assignmentInsightGroupType)),
};

export const attachmentType = {
  ownerId: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  fileName: PropTypes.string.isRequired,
  updatedDate: PropTypes.string,
};

export const avatarTransformsType = {
  x: PropTypes.number,
  y: PropTypes.number,
  zoom: PropTypes.number,
};

export const lessonPropType = PropTypes.shape({
  createdDate: PropTypes.string,
  id: PropTypes.number,
  title: PropTypes.string,
  status: PropTypes.oneOf(Object.values(PublicationStatuses)),
});

export const classPropType = {
  className: PropTypes.string.isRequired,
  period: PropTypes.number,
  orderNumber: PropTypes.number,
  classesMetadata: PropTypes.arrayOf(lessonPropType),
};

export const calendarViewMainProps = {
  currentDate: PropTypes.instanceOf(Object).isRequired,
  setCurrentDate: PropTypes.func.isRequired,
  currentPickerDates: PropTypes.shape({
    firstDayOfSchedule: PropTypes.string,
    lastDayOfSchedule: PropTypes.string,
  }),
  scheduleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export const notificationType = {
  action: PropTypes.string,
  createdDate: PropTypes.string,
  initiator: PropTypes.oneOf([
    NotificationInitiatorsMap.AYO,
    NotificationInitiatorsMap.SYSTEM,
    NotificationInitiatorsMap.USER,
  ]),
  markedAsRead: PropTypes.bool,
  metadata: PropTypes.instanceOf(Object),
  resource: PropTypes.instanceOf(Object),
  resourceId: PropTypes.string,
};

export const clubMemberType = {
  userId: PropTypes.number,
  userName: PropTypes.string,
  membershipChangeDate: PropTypes.string,
  membershipStatus: PropTypes.string,
  declineReason: PropTypes.string,
  requirements: PropTypes.shape({
    notes: PropTypes.string,
    links: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  }),
};

export const conversationType = {
  members: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  lastMessage: PropTypes.instanceOf(Object),
};

export const clubMetadataType = {
  attachments: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  clubName: PropTypes.string,
  createdDate: PropTypes.string,
  description: PropTypes.string,
  id: PropTypes.number,
  membersCount: PropTypes.number,
  membershipChangeDate: PropTypes.string,
  membershipStatus: PropTypes.string,
  reason: PropTypes.string,
  requirements: PropTypes.shape({
    isVisible: PropTypes.bool,
    notes: PropTypes.string,
    links: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  }),
  status: PropTypes.string,
  updatedDate: PropTypes.string,
};

export const authorContactInfo = PropTypes.shape({
  avatar: PropTypes.shape({
    image: attachmentType,
    transforms: avatarTransformsType,
  }),
  email: PropTypes.string,
  phone: PropTypes.string,
  position: PropTypes.string,
  profileImage: PropTypes.string,
});

export const eventType = PropTypes.shape({
  description: PropTypes.string,
  endDate: PropTypes.string,
  goingCount: PropTypes.number,
  id: PropTypes.number,
  interestedCount: PropTypes.number,
  locationType: PropTypes.string,
  locationPlacement: PropTypes.string,
  name: PropTypes.string,
  recourseId: PropTypes.number,
  recourseName: PropTypes.string,
  sharingLevel: PropTypes.arrayOf(PropTypes.string),
  startDate: PropTypes.string,
});

export default {
  articleBlock,
  clubMetadataType,
  clubMemberType,
  eventType,
  evidenceOfGrowthItem,
  goalActionStepItem,
  goalDetailsItem,
  goalItem,
  imgWithCaption,
  initiativesBlock,
  landingBlock,
  mediaBlock,
  personalizationBlock,
  personasBlock,
  portfolioSectionConfig,
  quoteBlock,
  richTextItem,
  richTextLinkItem,
  roadMapBlock,
  timelineBlock,
  tryMobileAppBlock,
  personPropShape,
  authorContactInfo,
};
