import React from 'react';
import { Box, Divider, useMediaQuery, useTheme } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Button, Typography } from '../../atoms';
import { ReactComponent as VectorIcon } from '../../../resources/icons/vector.svg';

const InitiativeEmptyStateBlock = ({
  body,
  buttonContent,
  buttonGaLabel,
  buttonLink,
  className,
  customButton,
  hint,
  illustration,
  newFeaturesTour,
  secondaryButtonContent,
  secondaryButtonGaLabel,
  secondaryButtonLink,
  secondaryButtonNewFeaturesTour,
  showNavigationButton,
  title,
}) => {
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <Box
      alignItems="center"
      boxSizing="border-box"
      className={classNames('ayo-initiative-empty-state', className)}
      display="flex"
      flexDirection="column"
      pb={6}
      pt={6.5}
      px={2}
      width="100%"
    >
      <Box mb={3}>{illustration}</Box>
      <Box alignItems="center" display="flex" mb={0.5}>
        <Typography align="center" component="p" variant="subtitle2">
          {title}
        </Typography>
        {newFeaturesTour}
      </Box>
      {body && (
        <Box
          className="ayo-initiative-empty-state__body"
          maxWidth={isWidthUpSm ? '40%' : '100%'}
          mb={3}
        >
          <Typography align="center" variant="body2">
            {body}
          </Typography>
        </Box>
      )}
      {hint}
      <Box className="ayo-initiative-empty-state__buttons-box" display="flex">
        {secondaryButtonLink && (
          <Box alignItems="center" display="flex">
            <Button
              component={RouterLink}
              gaLabel={secondaryButtonGaLabel}
              to={secondaryButtonLink}
            >
              {secondaryButtonContent}
            </Button>
            {secondaryButtonNewFeaturesTour}
            {showNavigationButton && <Divider flexItem orientation="vertical" />}
          </Box>
        )}
        {showNavigationButton && (
          <Button
            component={RouterLink}
            endIcon={<VectorIcon />}
            gaLabel={buttonGaLabel}
            to={buttonLink}
          >
            {buttonContent}
          </Button>
        )}
        {customButton}
      </Box>
    </Box>
  );
};

InitiativeEmptyStateBlock.propTypes = {
  body: PropTypes.string,
  buttonContent: PropTypes.node,
  buttonGaLabel: PropTypes.string,
  buttonLink: PropTypes.string,
  className: PropTypes.string,
  customButton: PropTypes.element,
  hint: PropTypes.element,
  illustration: PropTypes.node.isRequired,
  newFeaturesTour: PropTypes.node,
  secondaryButtonContent: PropTypes.node,
  secondaryButtonGaLabel: PropTypes.string,
  secondaryButtonLink: PropTypes.string,
  secondaryButtonNewFeaturesTour: PropTypes.node,
  showNavigationButton: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

InitiativeEmptyStateBlock.defaultProps = {
  body: '',
  buttonContent: null,
  buttonGaLabel: '',
  buttonLink: '',
  className: '',
  customButton: null,
  hint: null,
  newFeaturesTour: null,
  secondaryButtonContent: null,
  secondaryButtonGaLabel: '',
  secondaryButtonLink: '',
  secondaryButtonNewFeaturesTour: null,
  showNavigationButton: false,
};

export default InitiativeEmptyStateBlock;
