export const defaultSliderResponsiveConfig = {
  largeDesktop: {
    breakpoint: { max: 4000, min: 1920 },
    items: 4,
    partialVisibilityGutter: 30,
  },
  desktop: {
    breakpoint: { max: 1919, min: 1280 },
    items: 3,
    partialVisibilityGutter: 30,
  },
  tablet: {
    breakpoint: { max: 1279, min: 600 },
    items: 2,
    partialVisibilityGutter: 50,
  },
  mobile: {
    breakpoint: { max: 599, min: 0 },
    items: 1,
    partialVisibilityGutter: 60,
  },
};

export const endorsementsSliderResponsiveConfig = {
  largeDesktop: {
    breakpoint: { max: 4000, min: 1920 },
    items: 2,
    partialVisibilityGutter: 200,
  },
  desktop: {
    breakpoint: { max: 1919, min: 1280 },
    items: 2,
    partialVisibilityGutter: 100,
  },
  tablet: {
    breakpoint: { max: 1279, min: 600 },
    items: 2,
    partialVisibilityGutter: 50,
  },
  mobile: {
    breakpoint: { max: 599, min: 0 },
    items: 1,
    partialVisibilityGutter: 60,
  },
};

export const postsSliderResponsiveConfig = {
  largeDesktop: {
    breakpoint: { max: 4000, min: 1920 },
    items: 1,
    partialVisibilityGutter: 0,
  },
  desktop: {
    breakpoint: { max: 1919, min: 1280 },
    items: 1,
    partialVisibilityGutter: 0,
  },
  tablet: {
    breakpoint: { max: 1279, min: 600 },
    items: 1,
    partialVisibilityGutter: 0,
  },
  mobile: {
    breakpoint: { max: 599, min: 0 },
    items: 1,
    partialVisibilityGutter: 0,
  },
};

export const modsSliderConfig = {
  largeDesktop: {
    breakpoint: { max: 4000, min: 1920 },
    items: 7,
    partialVisibilityGutter: 10,
  },
  desktop: {
    breakpoint: { max: 1919, min: 1280 },
    items: 5,
    partialVisibilityGutter: 10,
  },
  tablet: {
    breakpoint: { max: 1279, min: 600 },
    items: 4,
    partialVisibilityGutter: 5,
  },
  mobile: {
    breakpoint: { max: 599, min: 0 },
    items: 3,
    partialVisibilityGutter: 5,
  },
};

export const postSliderSettings = {
  infinite: true,
  showDots: true,
  renderDotsOutside: true,
  keyBoardControl: false,
};
