import React, { useContext, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Container, Grid, useTheme, useMediaQuery } from '@mui/material';

import { Typography } from '../../../atoms';
import {
  InitiativeEmptyStateBlock,
  PageEndScrollToTopButton,
  AptitudeProgressCard,
} from '../../../moleculas';
import {
  GradesSection,
  PageWrapper,
  ExtracurricularsSection,
  GoalsBlock,
  LeadershipProfileSection,
  InterestsViewmap,
  OpportunitiesBlock,
  AchievementsSection,
  AptitudesSection,
  StudentGeneralInfo,
  EndorsementsSection,
  ClubHubSection,
} from '../../../organisms';
import { UserContext } from '../../../../context';
import { useSharedStudentData } from '../../../../hooks';
import {
  filterOutNotInterested,
  getInterestBubbleProperties,
  shuffle,
  getInterestWithSpacer,
  getNormalizedLanguageCode,
  checkEnvironmentVariable,
} from '../../../../utils';
import { useStudentsService } from '../../../../services';
import { LocalStorageKeysMap, InterestsTypeMap, RolesMap } from '../../../../constants/enums';
import {
  familyFeedRoute,
  myStudentsAchievementsSection,
  myStudentsAptitudesSection,
  myStudentsExtracurricularSection,
  myStudentsGoalsSection,
  myStudentsInterestsSection,
  myStudentsOpportunitiesSection,
  myStudentsRoute,
  studentsRoute,
} from '../../../../constants/routes';
import { ReactComponent as InterestsMapEmptyStateIllustration } from '../../../../resources/images/interests_map_with_wave.svg';
import { NewFeaturesIdsMap } from '../../../../tours/common/NewFeaturesItemsProvider';

import InitiativeContainer from './components/InitiativeContainer';
import QuestionAnswerBlock from './components/question-answer-block/QuestionAnswerBlock';

const IS_MOBILE_APP_RELEASED = checkEnvironmentVariable(
  'REACT_APP_IS_MOBILE_APP_AVAILABLE',
  'true',
);

const StudentDetailsPage = () => {
  const match = useRouteMatch();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
  const { getStudentInterests, getStudentExtracurriculars, getStudentGrades } =
    useStudentsService();
  const { t, i18n } = useTranslation();
  const personId = match.params.id;
  const { state: userState } = useContext(UserContext);
  const { activeStudent } = useSharedStudentData(personId);
  const [studentInterests, setStudentInterests] = useState([]);
  const [studentExtracurriculars, setStudentExtracurriculars] = useState([]);
  const [studentGrades, setStudentGrades] = useState([]);
  const isGuardian = userState.profile?.role === RolesMap.GUARDIAN;
  const isEducator =
    userState.profile?.role === RolesMap.ADMINISTRATOR ||
    userState.profile?.role === RolesMap.TEACHER;

  const backToMap = {
    [RolesMap.TEACHER]: {
      text: t('Back to my students'),
      route: myStudentsRoute,
    },
    [RolesMap.GUARDIAN]: {
      text: t('Back to Family feed'),
      route: familyFeedRoute,
    },
    [RolesMap.ADMINISTRATOR]: {
      text: t('Back to Students'),
      route: studentsRoute,
    },
  };

  useEffect(() => {
    getStudentExtracurriculars(personId).then((studentExtracurricularsData) => {
      setStudentExtracurriculars(studentExtracurricularsData);
    });
    if (!isGuardian) {
      getStudentGrades(personId).then((grades) => {
        setStudentGrades(grades);
      });
      const num = +localStorage.getItem(LocalStorageKeysMap.AYO_STUDENT_PROFILES_VISITED);
      localStorage.setItem(LocalStorageKeysMap.AYO_STUDENT_PROFILES_VISITED, num + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personId]);

  useEffect(() => {
    getStudentInterests(personId, getNormalizedLanguageCode(i18n.language)).then(
      (studentInterestsData) => {
        const likedInterests = filterOutNotInterested(
          studentInterestsData[InterestsTypeMap.SELECTED_INTERESTS],
        ).liked;
        const suggestedInterests = isGuardian
          ? []
          : studentInterestsData[InterestsTypeMap.SUGGESTED_INTERESTS]?.map((interest) => ({
              ...interest,
              suggested: true,
            }));
        const allInterests = shuffle([...likedInterests, ...suggestedInterests]);
        const bubbleInterests = allInterests
          .map((interest) => ({
            ...interest,
            ...getInterestBubbleProperties(interest),
            subInterests: [],
          }))
          .map((interest) => getInterestWithSpacer(interest, isWidthUpSm));
        setStudentInterests(bubbleInterests);
      },
    );
  }, [personId, i18n.language, getStudentInterests, isGuardian, isWidthUpSm]);

  return (
    <PageWrapper
      backToLink={backToMap[userState.profile?.role]?.route}
      backToText={backToMap[userState.profile?.role]?.text}
      mainElementClassName="ayo-student-details-page"
    >
      <Box pb={isWidthUpSm ? 10 : 5}>
        <Container>
          <StudentGeneralInfo
            student={+personId === activeStudent?.id ? activeStudent : null}
            withMood={IS_MOBILE_APP_RELEASED && isEducator}
            withPortfolio
          />
          <Grid container>
            <Grid item xs={12}>
              <Box mb={isWidthUpSm ? 3 : 2}>
                <Typography component="h2" variant="h2">
                  {t('Student’s story so far', {
                    firstName: activeStudent?.firstName || '',
                  })}
                </Typography>
              </Box>
            </Grid>
            <InitiativeContainer>
              <ClubHubSection studentId={personId} />
            </InitiativeContainer>
            <InitiativeContainer
              id={myStudentsInterestsSection.replace('#', '')}
              newFeatureId={NewFeaturesIdsMap.INTEREST_COMMENT}
              newFeatureLabel={t("Student's comments")}
              title={t('Interests')}
            >
              {studentInterests?.length ? (
                <InterestsViewmap
                  interests={studentInterests}
                  studentName={activeStudent?.firstName}
                  withDescriptions
                />
              ) : (
                <InitiativeEmptyStateBlock
                  illustration={<InterestsMapEmptyStateIllustration />}
                  title={t('No shared interests so far')}
                />
              )}
            </InitiativeContainer>
            <InitiativeContainer id={myStudentsAptitudesSection.replace('#', '')}>
              <AptitudesSection
                CardComponent={AptitudeProgressCard}
                emptyTitle={t('No information about aptitudes so far')}
                headerLevel={3}
                studentId={personId}
                titleVariant="subtitle1"
              />
            </InitiativeContainer>
            <InitiativeContainer>
              <EndorsementsSection
                headerLevel={3}
                isReadOnlyMode
                title="Endorsements"
                titleVariant="subtitle1"
              />
            </InitiativeContainer>
            <InitiativeContainer id={myStudentsOpportunitiesSection.replace('#', '')}>
              <OpportunitiesBlock
                headerLevel={3}
                studentId={personId}
                title="Opportunities"
                titleVariant="subtitle1"
              />
            </InitiativeContainer>
            <InitiativeContainer>
              <LeadershipProfileSection studentId={personId} titleVariant="h2" />
            </InitiativeContainer>
            <InitiativeContainer id={myStudentsExtracurricularSection.replace('#', '')}>
              <ExtracurricularsSection
                extracurriculars={studentExtracurriculars}
                headerLevel={3}
                title={t('Extracurriculars')}
                titleVariant="subtitle1"
              />
            </InitiativeContainer>
            <InitiativeContainer id={myStudentsAchievementsSection.replace('#', '')}>
              <AchievementsSection
                studentId={personId}
                title="Achievements & participation"
                titleVariant="subtitle1"
              />
            </InitiativeContainer>
            <InitiativeContainer id={myStudentsGoalsSection.replace('#', '')}>
              <GoalsBlock
                headerLevel={3}
                headerVariant="subtitle1"
                isEditable={isGuardian}
                showHeaderAndEmptyState
                studentId={personId}
              />
            </InitiativeContainer>
            {!isGuardian && (
              <>
                <InitiativeContainer>
                  <QuestionAnswerBlock
                    student={+personId === activeStudent?.id ? activeStudent : null}
                  />
                </InitiativeContainer>
                <InitiativeContainer>
                  <GradesSection grades={studentGrades} />
                </InitiativeContainer>
              </>
            )}
          </Grid>
        </Container>
      </Box>
      <PageEndScrollToTopButton />
    </PageWrapper>
  );
};

export default StudentDetailsPage;
