import { Box, useMediaQuery, useTheme } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Button, Typography } from '../../atoms';
import { ReactComponent as ChevronIcon } from '../../../resources/icons/chevron_left.svg';

const LearnMoreProfileBlock = ({ gaLabel, newFeatureHotspot, redirectRoute }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <Box
      alignItems={isWidthUpSm ? 'center' : 'flex-start'}
      display="flex"
      flexDirection={isWidthUpSm ? 'row' : 'column'}
      mb={isWidthUpSm ? 0 : 2}
    >
      <Box mb={isWidthUpSm ? 0 : 2} mr={2}>
        <Typography variant="body2">{t('Learn more on the Dedicated page')}</Typography>
      </Box>
      <Box alignItems="center" display="flex">
        <Button component={RouterLink} gaLabel={gaLabel} to={redirectRoute}>
          {t('Learn more')}
          <Box display="flex" ml={1}>
            <ChevronIcon className="reversed" />
          </Box>
        </Button>
        {newFeatureHotspot}
      </Box>
    </Box>
  );
};

LearnMoreProfileBlock.propTypes = {
  gaLabel: PropTypes.string.isRequired,
  newFeatureHotspot: PropTypes.node,
  redirectRoute: PropTypes.string.isRequired,
};

LearnMoreProfileBlock.defaultProps = {
  newFeatureHotspot: null,
};

export default LearnMoreProfileBlock;
