import React from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { DynamicGrid } from '../../../../atoms';
import { InitiativeEmptyStateBlock } from '../../../../moleculas';
import { PortfolioSectionTypes } from '../../../../../constants/enums';
import { ReactComponent as CertificatesEmptyStateIllustration } from '../../../../../resources/images/portfolio/certificates_emty_state.svg';
import PortfolioSection from '../../components/portfolio-section/PortfolioSection';

import CertificateCard from './components/CertificateCard';

const PortfolioSharedCertificatesSection = ({ sectionData, token }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box>
      <PortfolioSection
        body={
          <Box mt={3}>
            <DynamicGrid
              columnsCount={isWidthUpMd ? 3 : 2}
              gridItems={sectionData.entities.map((certificate) => (
                <CertificateCard key={certificate.id} certificate={certificate} token={token} />
              ))}
              isHorizontalOrder
            />
          </Box>
        }
        className={`${classNames('ayo-full-screen-portfolio-section', {
          'ayo-portfolio-certificates-section': sectionData.entities.length,
        })}`}
        emptyState={
          !sectionData.entities.length ? (
            <InitiativeEmptyStateBlock
              illustration={<CertificatesEmptyStateIllustration />}
              title={t('No information about certificates so far')}
            />
          ) : null
        }
        reflection={sectionData.reflection}
        sectionKey={PortfolioSectionTypes.PORTFOLIO__CERTIFICATES}
        title="Certificates"
      />
    </Box>
  );
};

PortfolioSharedCertificatesSection.propTypes = {
  sectionData: PropTypes.instanceOf(Object).isRequired,
  token: PropTypes.string.isRequired,
};

export default PortfolioSharedCertificatesSection;
