import React, { useReducer, createContext } from 'react';
import PropTypes from 'prop-types';

import { mergeArrayOfObjectsHelper } from '../../utils';
import { useConversationsService } from '../../services';

export const ChatContext = createContext();

export const ChatActions = {
  SET_IS_CHAT_DRAWER_OPENED: 'SET_IS_CHAT_DRAWER_OPENED',
  SET_AVAILABLE_CONVERSATIONS: 'SET_AVAILABLE_CONVERSATIONS',
  SET_ACTIVE_CONVERSATION_METADATA: 'SET_ACTIVE_CONVERSATION_METADATA',
  SET_ACTIVE_CONVERSATION_DATA: 'SET_ACTIVE_CONVERSATION_DATA',
  SET_ACTIVE_CONVERSATION_PAGE: 'SET_ACTIVE_CONVERSATION_PAGE',
  ADD_ACTIVE_CONVERSATION_MESSAGE: 'ADD_ACTIVE_CONVERSATION_MESSAGE',
  ADD_PAGE_MESSAGES: 'ADD_PAGE_MESSAGES',
  SET_IS_LOADING: 'SET_IS_LOADING',
  UPDATE_ACTIVE_CONVERSATION_METADATA: 'UPDATE_ACTIVE_CONVERSATION_METADATA',
  MARK_MESSAGE_READ: 'MARK_MESSAGE_READ',
  ADD_PUSH_NEW_CONVERSATION: 'ADD_PUSH_NEW_CONVERSATION',
};

const initState = {
  isOpened: false,
  availableConversations: null,
  // Used for general info about conversation and displaying conversation view
  activeConversationMetadata: null,
  // Used for detailed data about conversation
  activeConversationData: null,
  activeConversationPage: null,
  isLoading: false,
  conversationsCache: [],
  pushNewConversation: () => {},
};

// TODO: Create observable in the notifications and subscribe through it
export const chatNotificationsHandlers = {};

export const reducer = (state, action) => {
  const updateConversationsCache = (conversationsToStore) => {
    if (!conversationsToStore) return state.conversationsCache;
    const newCache = mergeArrayOfObjectsHelper(
      state.conversationsCache,
      conversationsToStore,
      'id',
    );
    return newCache;
  };
  let currentConversation;
  if (state.availableConversations && state.activeConversationMetadata) {
    currentConversation = state.availableConversations.find(
      (conversation) => conversation.id === state.activeConversationMetadata.id,
    );
  } else if (state.activeConversationMetadata?.id) {
    currentConversation = state.activeConversationMetadata;
  }
  switch (action.type) {
    case ChatActions.SET_IS_CHAT_DRAWER_OPENED:
      return { ...state, isOpened: action.data };
    case ChatActions.SET_AVAILABLE_CONVERSATIONS: {
      const newAvailableConversations = action.data?.map((conversation) => {
        const cacheConversation = state.conversationsCache.find(
          (cacheEntry) => cacheEntry.id === conversation.id,
        );
        return cacheConversation ?? conversation;
      });
      return { ...state, availableConversations: newAvailableConversations };
    }
    case ChatActions.SET_ACTIVE_CONVERSATION_METADATA:
      return { ...state, activeConversationMetadata: action.data, activeConversationData: null };
    case ChatActions.SET_ACTIVE_CONVERSATION_DATA: {
      if (currentConversation) {
        [currentConversation.lastMessage] = action.data.messages;
      }
      return {
        ...state,
        activeConversationData: action.data,
        activeConversationPage: action.page,
        conversationsCache: updateConversationsCache(
          currentConversation ? [currentConversation] : null,
        ),
        activeConversationMetadata: {
          ...state.activeConversationMetadata,
          members: action.data.members ?? state.activeConversationMetadata.members,
          conversationDescription:
            action.data.conversationDescription ??
            state.activeConversationMetadata.conversationDescription,
          conversationName:
            action.data.conversationName ?? state.activeConversationMetadata.conversationName,
          conversationImage:
            action.data.conversationImage ?? state.activeConversationMetadata.conversationImage,
        },
      };
    }
    case ChatActions.SET_ACTIVE_CONVERSATION_PAGE:
      return { ...state, activeConversationPage: action.data };
    case ChatActions.ADD_ACTIVE_CONVERSATION_MESSAGE:
      if (currentConversation) {
        currentConversation.lastMessage = action.data;
      }
      return {
        ...state,
        activeConversationData: {
          ...state.activeConversationData,
          totalElements: state.activeConversationData.totalElements + 1,
          messages: [action.data, ...state.activeConversationData.messages],
        },
        conversationsCache: updateConversationsCache(
          currentConversation ? [currentConversation] : null,
        ),
      };
    case ChatActions.ADD_PAGE_MESSAGES:
      return {
        ...state,
        activeConversationData: {
          ...state.activeConversationData,
          messages: [...state.activeConversationData.messages, ...action.data],
        },
        activeConversationPage: action.page,
      };
    case ChatActions.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.data,
      };
    case ChatActions.UPDATE_ACTIVE_CONVERSATION_METADATA:
      return { ...state, activeConversationMetadata: action.data };
    case ChatActions.MARK_MESSAGE_READ: {
      const updatedMessages = state.activeConversationData.messages.map((message) =>
        message.id === action.data ? { ...message, isRead: true } : message,
      );
      if (currentConversation) {
        [currentConversation.lastMessage] = updatedMessages;
      }
      return {
        ...state,
        activeConversationData: { ...state.activeConversationData, messages: updatedMessages },
        conversationsCache: updateConversationsCache(
          currentConversation ? [currentConversation] : null,
        ),
      };
    }
    case ChatActions.ADD_PUSH_NEW_CONVERSATION:
      return { ...state, pushNewConversation: action.data };
    default:
      throw new Error();
  }
};

export const ChatContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initState);

  const { getConversation } = useConversationsService();

  chatNotificationsHandlers.onMessage = (response) => {
    if (state.activeConversationMetadata?.id === response.resourceId) {
      getConversation(response.resourceId, 1, 1000).then((data) => {
        dispatch({ type: ChatActions.SET_ACTIVE_CONVERSATION_DATA, data, page: 1 });
      });
    }
  };

  return <ChatContext.Provider value={{ state, dispatch }}>{children}</ChatContext.Provider>;
};

ChatContextProvider.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
};
