import React from 'react';
import { useTranslation } from 'react-i18next';

import { PageIllustrationBlock, ScreenCenteredContainer } from '../../../moleculas';
import { PageWrapper } from '../../../organisms';
import { ReactComponent as ServerSleepingImage } from '../../../../resources/images/error_503_server_sleeping.svg';

const ServiceUnavailablePage = () => {
  const { t } = useTranslation();

  return (
    <PageWrapper noNav>
      <ScreenCenteredContainer>
        <PageIllustrationBlock
          Illustration={ServerSleepingImage}
          illustrationAriaLabel={t(
            'A red hammock hung between two green trees, and a sound of snoring coming out of it',
          )}
          mainHeaderText={t('Oops, looks like the server is sleeping')}
          secondaryHeaderText={[t('We’re trying to wake it up'), t('Please come back later')]}
        />
      </ScreenCenteredContainer>
    </PageWrapper>
  );
};

export default ServiceUnavailablePage;
