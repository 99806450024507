/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import { Box, Divider, useMediaQuery, useTheme } from '@mui/material';
import PropTypes from 'prop-types';

import {
  Banner,
  DragContent,
  DropContent,
  ImageEditButtons,
  InformationalCaption,
} from '../../../../moleculas';
import { onClubHubBannerDrop } from '../../../../../utils';
import { useSnackbar } from '../../../../../hooks';
import { ClubKeys } from '../../../../../constants/club-hub';
import { Typography } from '../../../../atoms';

const ClubBanner = ({ isEditMode, onChange, value }) => {
  const { t } = useTranslation();
  const { setSnackBarStatus } = useSnackbar();

  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const onBannerDrop = (dropped) =>
    onClubHubBannerDrop(dropped, onChange, setSnackBarStatus, t, !isEditMode);

  const onBannerDelete = useCallback(() => {
    onChange({ [ClubKeys.ATTACHMENTS]: [] });
    if (!isEditMode) {
      setSnackBarStatus({ text: t('The banner has been successfully deleted.'), type: 'delete' });
    }
  }, [isEditMode, onChange, setSnackBarStatus, t]);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open: openFileBrowser,
  } = useDropzone({
    onDrop: onBannerDrop,
    noClick: true,
  });

  const [currentFile] = value || [];

  return (
    <Box>
      <input {...getInputProps()} />
      {currentFile ? (
        <Box position="relative">
          <Banner image={currentFile} />
          <Box position="absolute" right={24} top={24}>
            <ImageEditButtons onChange={openFileBrowser} onDelete={onBannerDelete} />
          </Box>
        </Box>
      ) : (
        <>
          <Box
            {...getRootProps()}
            className="club-banner__upload-container"
            mb={2}
            position="relative"
            py={8}
          >
            <DragContent
              body={
                isWidthUpSm ? (
                  <Box alignItems="center" display="flex" flexDirection="row" mb={2}>
                    <Divider className="club-banner__upload-container__divider" />
                    <Typography isLabel mx={1} variant="body2">
                      {t('or')}
                    </Typography>
                    <Divider className="club-banner__upload-container__divider" />
                  </Box>
                ) : null
              }
              hideDragAndDrop={!isWidthUpSm}
              openFileBrowserHandler={openFileBrowser}
              titleHeaderLevel={3}
            />
            {isDragActive && <DropContent />}
          </Box>
          <InformationalCaption title="Max. 1 attachment up to 10 MB in JPG, JPEG and PNG formats." />
        </>
      )}
    </Box>
  );
};

ClubBanner.propTypes = {
  isEditMode: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.instanceOf(File)),
};

ClubBanner.defaultProps = {
  isEditMode: false,
  value: [],
};

export default ClubBanner;
