import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, DialogActions, DialogContent } from '@mui/material';
import PropTypes from 'prop-types';

import { Dialog, DialogTitle, Link, Typography } from '../../../../atoms';
import {
  AccordionItem,
  PopupActionsButtons,
  STTTextField,
  TextWithTooltip,
} from '../../../../moleculas';
import { loginAllowedFilterRE } from '../../../../../constants/regexps';
import { InputsValidationRules } from '../../../../../constants/enums';
import { ClubJoinRequestKeys } from '../../../../../constants/club-hub';
import { clubLinkValidation, formatUrl } from '../../../../../utils';
import { ReactComponent as LinkIcon } from '../../../../../resources/icons/add_link.svg';
import ClubLinks from '../club-links/ClubLinks';

const joinRequestEmptyData = {
  [ClubJoinRequestKeys.NOTES]: '',
  [ClubJoinRequestKeys.LINKS]: [''],
};

const ClubJoinDialog = ({ clubName, isOpen, onClose, onSubmit, requirements }) => {
  const { t } = useTranslation();

  const [joinRequestData, setJoinRequestData] = useState(joinRequestEmptyData);

  const { description, links } = requirements;

  const joinRequestChangeHandler = useCallback((change) => {
    setJoinRequestData((prevState) => ({ ...prevState, ...change }));
  }, []);

  const onRequestSubmitHandler = useCallback(() => {
    const postData = {
      ...joinRequestData,
      [ClubJoinRequestKeys.LINKS]: joinRequestData[ClubJoinRequestKeys.LINKS]
        .filter((link) => link)
        .map((link) => formatUrl(link)),
    };
    const isValid =
      postData[ClubJoinRequestKeys.NOTES].length <= InputsValidationRules.MAX_INPUT_LENGTH &&
      clubLinkValidation(postData[ClubJoinRequestKeys.LINKS]);
    if (isValid) {
      onSubmit(postData);
      onClose();
    }
  }, [joinRequestData, onClose, onSubmit]);

  useEffect(() => {
    setJoinRequestData(joinRequestEmptyData);
  }, [isOpen]);

  return (
    <Dialog
      className="ayo-club-hub__join-dialog"
      gaLabel="Club join dialog"
      onClick={(e) => e.stopPropagation()}
      onClose={onClose}
      open={isOpen}
    >
      <DialogTitle disableTypography>
        <Typography component="h2" variant="subtitle1">
          {`${t('Apply for joining')} ${clubName}`}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography my={3} variant="body2">
          {t(
            "The owner of the club has added some requirements for joining. You may include notes and links to demonstrate why you'd be a suitable addition to the club. It is optional, and you may leave this empty.",
          )}
        </Typography>
        <AccordionItem
          bodyEl={
            <Box>
              <Typography variant="body2">{description}</Typography>
              {!!links.length && (
                <>
                  <Typography component="h4" mb={1} mt={3} variant="subtitle2">
                    {`${t('Link')}(s)`}
                  </Typography>
                  {links.map((link) => (
                    <Link
                      key={link.url}
                      className="ayo-club-requirements-link"
                      gaLabel="Requirements link"
                      href={link.url}
                      icon={<LinkIcon />}
                      iconPosition="start"
                      target="_blank"
                    >
                      <TextWithTooltip rowsCount={3} title={link.url} />
                    </Link>
                  ))}
                </>
              )}
            </Box>
          }
          gaLabel="Read the requirements for joining and staying"
          headerEl={
            <Typography component="h3" variant="subtitle2">
              {t('Read the requirements for joining and staying')}
            </Typography>
          }
        />
        <Typography mb={2} mt={3} variant="subtitle2">
          {t('Note')}
        </Typography>
        <STTTextField
          fullWidth
          gaLabel="Note"
          inputRE={loginAllowedFilterRE}
          label={t('Add a note to highlight why you’re a good match')}
          maxLength={InputsValidationRules.MAX_INPUT_LENGTH}
          multiline
          name={ClubJoinRequestKeys.NOTES}
          onChange={(e) =>
            joinRequestChangeHandler({ [ClubJoinRequestKeys.NOTES]: e.target.value })
          }
          outlined
          rowsCount={5}
          value={joinRequestData[ClubJoinRequestKeys.NOTES]}
        />
        <Box mb={5} mt={3}>
          <Typography mb={2} variant="subtitle2">
            {t('Links')}
          </Typography>
          <ClubLinks
            clubValueKey={ClubJoinRequestKeys.LINKS}
            gaLabel="Requirements link"
            links={joinRequestData[ClubJoinRequestKeys.LINKS]}
            onChange={joinRequestChangeHandler}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <PopupActionsButtons
          primaryButtonGaLabel="Apply"
          primaryButtonHandler={onRequestSubmitHandler}
          primaryButtonText={t('Apply')}
          secondaryButtonGaLabel="Cancel"
          secondaryButtonHandler={onClose}
          secondaryButtonText={t('Cancel')}
        />
      </DialogActions>
    </Dialog>
  );
};

ClubJoinDialog.propTypes = {
  clubName: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  requirements: PropTypes.shape({
    isVisible: PropTypes.bool,
    description: PropTypes.string,
    links: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  }).isRequired,
};

export default ClubJoinDialog;
