import React, { useContext } from 'react';

import { Dialog } from '../../atoms';
import { ClassLinkLogoutContent } from '../../moleculas/logout-dialogs-content/LogoutDialogsContent';
import { AppActions, AppContext } from '../../../context';
import { GaActions, GaCategories } from '../../../constants/enums';
import { GA } from '../../../utils';

const ClassLinkLogoutDialog = () => {
  const { state: appState, dispatch: dispatchAppState } = useContext(AppContext);

  const handleCloseReminderClick = () => {
    dispatchAppState({ type: AppActions.SET_CLASSLINK_REMINDER_OPEN, data: false });
    GA.logInteraction({
      category: GaCategories.BEHAVIOR,
      action: GaActions.BUTTON_CLICK,
      label: 'Close Logout dialog',
    });
  };

  return (
    <Dialog onClose={handleCloseReminderClick} open={!!appState.classLinkReminderOpen}>
      <ClassLinkLogoutContent />
    </Dialog>
  );
};

export default ClassLinkLogoutDialog;
