import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, DialogContent } from '@mui/material';
import PropTypes from 'prop-types';

import { Typography, Dialog, DialogTitle } from '../../../atoms';
import { Chip } from '../../../moleculas';
import EvidenceOfGrowthForm from '../evidence-of-growth-form/EvidenceOfGrowthForm';
import { goalActionStepItem, goalDetailsItem } from '../../../../constants/propTypes';

const DIALOG_MAX_WIDTH = 700;

const EvidenceOfGrowthAddEditDialog = ({
  actionSteps,
  assessmentKey,
  className,
  evidenceChangeHandler,
  goalDetails,
  isActionStepDisabled,
  onClose,
  open,
  title,
  valuesToEdit,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog className={className} maxWidth={DIALOG_MAX_WIDTH} onClose={onClose} open={open}>
      <DialogTitle disableTypography>
        <Box mb={3}>
          <Typography variant="h2">{title}</Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        {!goalDetails && (
          <Box mb={3}>
            <Box mb={1}>
              <Typography component="h3" variant="subtitle2">
                {t('Related Attribute')}
              </Typography>
            </Box>
            <Chip isCaption label={t(assessmentKey)} />
          </Box>
        )}
        <Box mb={1}>
          <Typography component="h3" variant="subtitle2">
            {t('Summary')}
          </Typography>
        </Box>
        <EvidenceOfGrowthForm
          actionSteps={actionSteps}
          assessmentKey={assessmentKey}
          closeDialog={onClose}
          evidenceChangeHandler={evidenceChangeHandler}
          goalDetails={goalDetails}
          isActionStepDisabled={isActionStepDisabled}
          valuesToEdit={valuesToEdit}
        />
      </DialogContent>
    </Dialog>
  );
};

EvidenceOfGrowthAddEditDialog.propTypes = {
  actionSteps: PropTypes.arrayOf(goalActionStepItem),
  assessmentKey: PropTypes.string,
  className: PropTypes.string,
  evidenceChangeHandler: PropTypes.func,
  goalDetails: goalDetailsItem,
  isActionStepDisabled: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  valuesToEdit: PropTypes.shape({
    id: PropTypes.number,
    link: PropTypes.string,
    reflection: PropTypes.string,
    title: PropTypes.string,
  }),
};

EvidenceOfGrowthAddEditDialog.defaultProps = {
  actionSteps: null,
  assessmentKey: '',
  className: '',
  evidenceChangeHandler: () => {},
  goalDetails: null,
  isActionStepDisabled: false,
  valuesToEdit: null,
};

export default EvidenceOfGrowthAddEditDialog;
