import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { ActionsMenu, Card, Typography } from '../../../../../atoms';
import { EditingButton } from '../../../../../moleculas';
import { usePrint } from '../../../../../../hooks';
import { getFirstTextFromEditor, updateEmbedsSrc } from '../../../../../../utils';
import { ReactComponent as ChevronRightIcon } from '../../../../../../resources/icons/chevron_right.svg';
import { ReactComponent as DownloadIcon } from '../../../../../../resources/icons/download.svg';
import { ReactComponent as EditIcon } from '../../../../../../resources/icons/create.svg';
import { ReactComponent as RecommendationCardIllustration } from '../../../../../../resources/images/portfolio/recommendations_card_image.svg';
import PortfolioPrintComponent from '../../../components/portfolio-print-component/PortfoloiPrintComponent';

const RecommendationCard = ({
  editHandler,
  isEditable,
  readHandler,
  recommendation,
  showActionMenu,
  token,
}) => {
  const { t } = useTranslation();
  const printComponentRef = useRef();

  const { printHandler } = usePrint(printComponentRef);

  const textEditorData = useMemo(
    () =>
      token
        ? updateEmbedsSrc(recommendation.reachTextEditorData.textJson, 'image', token)
        : recommendation.reachTextEditorData.textJson,
    [token, recommendation],
  );

  return (
    <Card
      mainContent={
        <Box display="flex" flexDirection="row">
          <Box height="100%">
            <RecommendationCardIllustration />
          </Box>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Box ml={2}>
              <Box mb={2}>
                <Typography
                  className="ayo-portfolio-recommendations-section__title"
                  component="h3"
                  variant="subtitle2"
                >
                  {getFirstTextFromEditor(recommendation.reachTextEditorData.textJson)}
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography variant="caption">{`${t('Written by')} ${
                  recommendation.teacherInfo?.firstName
                } ${recommendation.teacherInfo?.lastName}`}</Typography>
              </Box>
              <EditingButton
                className="ayo-portfolio-recommendations-section__read_button"
                gaLabel="Read recommendation"
                icon={<ChevronRightIcon />}
                iconPosition="end"
                onClick={readHandler}
                text={t('Read')}
              />
            </Box>
            {showActionMenu && (
              <Box ml={2}>
                <ActionsMenu
                  gaLabel="Recommendation actions"
                  id={`recommendation-actions-${recommendation.id}`}
                  menuItems={[
                    {
                      text: t('Save as PDF'),
                      icon: DownloadIcon,
                      handler: printHandler,
                      id: `save-as-PDF-${recommendation.id}`,
                      gaLabel: 'Save as PDF',
                    },
                    isEditable && {
                      text: t('Edit'),
                      icon: EditIcon,
                      handler: editHandler,
                      id: `edit-${recommendation.id}`,
                      gaLabel: 'Edit recommendation',
                    },
                  ].filter(Boolean)}
                  paperClassName="ayo-portfolio-recommendations-card__action-menu"
                />
              </Box>
            )}
          </Box>
          <PortfolioPrintComponent ref={printComponentRef} value={textEditorData} />
        </Box>
      }
    />
  );
};

RecommendationCard.propTypes = {
  editHandler: PropTypes.func,
  isEditable: PropTypes.bool,
  readHandler: PropTypes.func.isRequired,
  recommendation: PropTypes.instanceOf(Object).isRequired,
  showActionMenu: PropTypes.bool,
  token: PropTypes.string,
};

RecommendationCard.defaultProps = {
  editHandler: () => {},
  showActionMenu: false,
  isEditable: false,
  token: null,
};

export default RecommendationCard;
