import React, { useContext, useEffect, useState } from 'react';

import { UserContext } from '../../../../context';
import { useClubHubService } from '../../../../services';
import { RolesMap } from '../../../../constants/enums';

import StudentClubHub from './components/student-club-hub/StudentClubHub';
import TeacherClubHub from './components/teacher-club-hub/TeacherClubHub';

const ClubHubMainContent = () => {
  const { state: userState } = useContext(UserContext);
  const isStudent = userState.profile.role === RolesMap.STUDENT;

  const [clubs, setClubs] = useState([]);

  const { getClubsList } = useClubHubService();

  useEffect(() => {
    getClubsList().then((data) => {
      setClubs(data);
    });
  }, [getClubsList, isStudent]);

  return isStudent ? <StudentClubHub clubs={clubs} /> : <TeacherClubHub clubs={clubs} />;
};

export default ClubHubMainContent;
