/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FormControlLabel as MUIFormControlLabel } from '@mui/material';

const FormControlLabel = ({ className, ...props }) => (
  <MUIFormControlLabel className={classnames('ayo-form-control-label', className)} {...props} />
);

FormControlLabel.propTypes = {
  className: PropTypes.string,
};

FormControlLabel.defaultProps = {
  className: '',
};

export default FormControlLabel;
