import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Divider } from '@mui/material';
import PropTypes from 'prop-types';

import { Card, Typography, Link } from '../../atoms';
import { Chip, MlChip, TagsBlock, TextWithTooltip } from '../../moleculas';
import { ReactComponent as LinkIcon } from '../../../resources/icons/link.svg';
import { ReactComponent as ActivitiesImage } from '../../../resources/images/suggested_activities_noteboock.svg';
import { ReactComponent as BooksImage } from '../../../resources/images/suggested_activities_books.svg';
import { ReactComponent as CoursesImage } from '../../../resources/images/suggested_activities_laptop.svg';
import { ReactComponent as VideosImage } from '../../../resources/images/suggested_activities_videos.svg';

const ActivityTypeImagesMap = {
  ACTIVITIES: {
    image: ActivitiesImage,
    alt: 'A sketchbook with a drawing on the page and a pencil on the side',
  },
  BOOKS: {
    image: BooksImage,
    alt: 'Three books one upon another',
  },
  COURSES: {
    image: CoursesImage,
    alt: 'An opened laptop with a player',
  },
  VIDEOS: {
    image: VideosImage,
    alt: 'A tablet with an opened video player',
  },
};

const SuggestedActivitiesCard = ({ suggestedActivity }) => {
  const { t } = useTranslation();
  const activityTitle = suggestedActivity.details.title;
  const activityAuthor = suggestedActivity.details.author;
  const activityGenre = suggestedActivity.details.genre;
  const activityDescription = suggestedActivity.details.description;
  const activityWebLinks = suggestedActivity.details.webLinks;
  const activityTags = suggestedActivity.details.tags;

  const activityTypeName = useMemo(
    () => suggestedActivity.resource[0] + suggestedActivity.resource.slice(1).toLowerCase(),
    [suggestedActivity],
  );
  const ActivityTypeImage = useMemo(
    () => ActivityTypeImagesMap[suggestedActivity.resource].image,
    [suggestedActivity],
  );
  const activityTypeAlt = useMemo(
    () => ActivityTypeImagesMap[suggestedActivity.resource].alt,
    [suggestedActivity],
  );

  return (
    <Card
      className="ayo-suggested-activities"
      elevation={1}
      mainContent={
        <Box>
          <Box className={`ayo-suggested-activities__header ${activityTypeName.toLowerCase()}`}>
            <Box display="flex" justifyContent="space-between" pb={1}>
              <ActivityTypeImage aria-label={t(activityTypeAlt)} role="img" />
              <MlChip />
            </Box>
            <Typography component="h3" variant="subtitle2">
              {t(activityTypeName)}
            </Typography>
          </Box>
          <Box className="ayo-suggested-activities__body">
            <Chip isCaption label={t(suggestedActivity.leadershipAttributeKey.toLowerCase())} />
            <Box mb={1} mt={3}>
              <TextWithTooltip headerLevel={4} title={activityTitle} titleVariant="subtitle2" />
            </Box>
            <Box display="flex" mb={2}>
              {activityAuthor && (
                <Typography isLabel variant="body3">
                  {activityAuthor}
                </Typography>
              )}
              {activityGenre && (
                <>
                  <Divider
                    className="ayo-suggested-activities__divider"
                    flexItem
                    isLabel
                    orientation="vertical"
                  />
                  <Typography isLabel variant="body3">
                    {activityGenre}
                  </Typography>
                </>
              )}
            </Box>
            {activityDescription && (
              <TextWithTooltip rowsCount={3} title={activityDescription} titleVariant="body2" />
            )}
            {activityTags && (
              <Box pb={1} pt={3}>
                <TagsBlock label="Related video tags" tagsList={activityTags.split(',')} />
              </Box>
            )}
            {activityWebLinks &&
              activityWebLinks.map((webLink, idx) => (
                <Box key={idx} pt={3}>
                  <Link
                    gaLabel={`Learn more at ${webLink.linkName}`}
                    href={webLink.linkUrl}
                    icon={<LinkIcon />}
                    target="_blank"
                  >
                    <TextWithTooltip title={`${t('Learn more at')} ${webLink.linkName}`} />
                  </Link>
                </Box>
              ))}
          </Box>
        </Box>
      }
    />
  );
};

SuggestedActivitiesCard.propTypes = {
  suggestedActivity: PropTypes.shape({
    leadershipAttributeKey: PropTypes.string,
    resource: PropTypes.string,
    details: PropTypes.shape({
      title: PropTypes.string,
      author: PropTypes.string,
      genre: PropTypes.string,
      description: PropTypes.string,
      webLinks: PropTypes.arrayOf(
        PropTypes.shape({
          linkName: PropTypes.string,
          linkUrl: PropTypes.string,
        }),
      ),
      tags: PropTypes.string,
    }),
  }).isRequired,
};

export default SuggestedActivitiesCard;
