import React, { useCallback, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, Container, Toolbar } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from 'react-i18next';

import { Button } from '../../atoms';
import {
  BackNavigationLink,
  LanguageButtons,
  LogoutButton,
  LogoLink,
  NewFeatureHotspot,
  BreadcrumbsNavigation,
} from '../../moleculas';
import NotificationsPopover from '../notifications-popover/NotificationsPopover';
import { AppActions, AppContext, UserContext } from '../../../context';
import { ReactComponent as MenuToggle } from '../../../resources/icons/hamburger.svg';
import { NewFeaturesIdsMap } from '../../../tours/common/NewFeaturesItemsProvider';
import { useNotificationsData } from '../../../hooks';

const TopBar = ({
  backToLink,
  backToText,
  breadcrumbsConfig,
  disabledLanguageSelectorText,
  noLogo,
  noLogOut,
  noNav,
  noNotifications,
  noi18n,
}) => {
  const { dispatch: dispatchAppState } = useContext(AppContext);
  const { state: userState } = useContext(UserContext);
  const theme = useTheme();
  const { t } = useTranslation();
  const isWidthUpLg = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });

  const profileNoNav = !userState.profile || userState.noNavigation;
  const isNavigationDisabled = useMemo(() => profileNoNav || noNav, [profileNoNav, noNav]);
  const { isNotificationsAvailable } = useNotificationsData();

  const isNotificationsVisible = useMemo(
    () => isNotificationsAvailable && !noNotifications,
    [isNotificationsAvailable, noNotifications],
  );

  const handleOpenNavDrawerClick = useCallback(() => {
    dispatchAppState({
      type: AppActions.SET_NAV_DRAWER_OPEN,
      data: true,
    });
  }, [dispatchAppState]);

  const navigationComponent = useMemo(
    () =>
      breadcrumbsConfig ? (
        <BreadcrumbsNavigation navItems={breadcrumbsConfig} />
      ) : backToText ? (
        <BackNavigationLink backToLink={backToLink} text={backToText} />
      ) : null,
    [backToLink, backToText, breadcrumbsConfig],
  );

  return (
    <Container>
      <Toolbar className="ayo-top-bar" disableGutters>
        {isNavigationDisabled && !noLogo && <LogoLink />}
        {!isNavigationDisabled && !isWidthUpLg && (
          <Button
            aria-label={t('Open navigation menu')}
            gaLabel="Open navigation menu"
            id="nav-drawer-toggle"
            isIconButton
            onClick={handleOpenNavDrawerClick}
          >
            <MenuToggle />
          </Button>
        )}
        {isWidthUpLg && navigationComponent}
        <Box
          alignItems="center"
          display="flex"
          flex={1}
          justifyContent="flex-end"
          minWidth="fit-content"
        >
          {isNotificationsVisible && (
            <Box className="ayo-notifications-buttons-container" mr={isWidthUpSm ? 6 : 2}>
              <NotificationsPopover />
              <NewFeatureHotspot id={NewFeaturesIdsMap.NOTIFICATIONS} isClickable />
            </Box>
          )}
          {!noi18n && (
            <Box mr={isWidthUpSm ? 10 : 1}>
              <LanguageButtons disabledLanguageSelectorText={disabledLanguageSelectorText} />
            </Box>
          )}
          {!noLogOut && userState.profile && <LogoutButton />}
        </Box>
      </Toolbar>
      {!isWidthUpLg && <Box mb={3}>{navigationComponent}</Box>}
    </Container>
  );
};

TopBar.propTypes = {
  backToLink: PropTypes.string,
  backToText: PropTypes.string,
  breadcrumbsConfig: PropTypes.arrayOf(
    PropTypes.shape({
      gaLabel: PropTypes.string,
      label: PropTypes.string,
      redirectUrl: PropTypes.string,
    }),
  ),
  disabledLanguageSelectorText: PropTypes.string,
  noLogo: PropTypes.bool,
  noLogOut: PropTypes.bool,
  noNav: PropTypes.bool,
  noNotifications: PropTypes.bool,
  noi18n: PropTypes.bool,
};

TopBar.defaultProps = {
  backToLink: null,
  backToText: '',
  breadcrumbsConfig: null,
  disabledLanguageSelectorText: null,
  noLogo: false,
  noLogOut: false,
  noNav: false,
  noNotifications: false,
  noi18n: false,
};

export default TopBar;
