/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Dialog } from '../../atoms';
import { DialogErrorContent, DialogSuccessContent } from '../../moleculas';
import { SupportOptionsMap } from '../../../constants/enums';
import { ReactComponent as SuccessImage } from '../../../resources/images/modal_feedback_sent.svg';
import FeedbackForm from '../feedback/feedback-form/FeedbackForm';

const SupportDialog = ({
  feedbackPrefix,
  fieldLabel,
  hideText,
  isOpen,
  mode,
  onClose,
  title,
  withReporting,
  ...props
}) => {
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isFormErrored, setIsFormErrored] = useState(false);

  const { t } = useTranslation();

  const resetFormIfSubmitted = useCallback(() => {
    if (!isFormSubmitted) return;
    setIsFormSubmitted(false);
    setIsFormErrored(false);
  }, [isFormSubmitted]);

  const handleSubmitClick = (success) => {
    if (!success) {
      setIsFormErrored(true);
    }
    setIsFormSubmitted(true);
  };

  const handleCloseClick = useCallback(() => {
    onClose();
    resetFormIfSubmitted();
  }, [onClose, resetFormIfSubmitted]);

  useEffect(() => {
    if (isFormSubmitted || isFormErrored) {
      document.querySelector('.ayo-dialog__close-button').focus();
    }
  }, [isFormErrored, isFormSubmitted]);

  const DialogFormContent = (
    <FeedbackForm
      feedbackPrefix={feedbackPrefix}
      fieldLabel={fieldLabel}
      gaLabel="Submit feedback at dialog"
      isDialog
      mode={mode}
      onSubmit={handleSubmitClick}
      text={!hideText ? [t('What do you want to do?')] : []}
      title={title || t('Support')}
      titleVariant="subtitle1"
      withReporting={withReporting}
    />
  );

  return (
    <Dialog
      className={`${classNames('ayo-feedback-dialog', {
        'ayo-feedback-dialog--submited': isFormSubmitted,
      })}`}
      onClose={handleCloseClick}
      open={isOpen}
      {...props}
    >
      {!isFormSubmitted ? (
        DialogFormContent
      ) : !isFormErrored ? (
        <DialogSuccessContent
          buttonClickHandler={handleCloseClick}
          buttonText="Close"
          gaLabel="Close"
          isFormSubmitted={isFormSubmitted}
          successImage={
            <SuccessImage aria-label={t('A green envelope with a message Sent')} role="img" />
          }
          text="AYO will check it as soon as possible"
          title="Thank you for your message!"
        />
      ) : (
        <DialogErrorContent
          gaLabel="Retry submit feedback at dialog"
          isErrored={isFormErrored}
          isSubmitted={isFormSubmitted}
          onRetry={resetFormIfSubmitted}
          text={`${t('We couldn’t send your message')} ${t('Please try once more')}`}
        />
      )}
    </Dialog>
  );
};

SupportDialog.propTypes = {
  feedbackPrefix: PropTypes.string,
  fieldLabel: PropTypes.string,
  hideText: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  mode: PropTypes.oneOf([SupportOptionsMap.FEEDBACK, SupportOptionsMap.REPORT_ISSUE]),
  onClose: PropTypes.func,
  title: PropTypes.string,
  withReporting: PropTypes.bool,
};

SupportDialog.defaultProps = {
  feedbackPrefix: '',
  fieldLabel: '',
  hideText: false,
  mode: '',
  onClose: () => {},
  title: '',
  withReporting: true,
};

export default SupportDialog;
