import { useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { UserContext, UserActions, TourContext, TourActions } from '../../context';
import { useOneTimeActionService, useProfileService } from '../../services';
import { StatusCodeMap } from '../../services/HttpClient';
import {
  GaUserProperties,
  LanguagesMap,
  LocalStorageKeysMap,
  RolesMap,
  StudentActivityCriterions,
  StudentsPage,
} from '../../constants/enums';
import { RolesMainPages } from '../../constants/pages';
import { campusSelectionRoute, defaultMainRoute, myStudentsRoute } from '../../constants/routes';
import { getYearDifference } from '../locale-helpers/date-helpers/dateHelpers';
import { getNormalizedLanguageCode } from '../locale-helpers/language-helpers/languageHelpers';
import { fillInterpolationValue } from '../string-helpers/stringHelpers';
import { setUserProperties } from '../analytics/Analytics';

const getCurrentCampusSchoolLevel = (currentCampus, schools) =>
  Object.keys(schools).find(
    (key) =>
      JSON.stringify(schools[key]) ===
      JSON.stringify(
        Object.values(schools).find((value) =>
          value.some(({ schoolName }) => schoolName === currentCampus),
        ),
      ),
  );

export const getInnerAppRedirectUrl = (profile) =>
  fillInterpolationValue(
    sessionStorage.getItem(LocalStorageKeysMap.SKYWARD_LOGIN_REDIRECT_URL) ||
      sessionStorage.getItem(LocalStorageKeysMap.CLASSLINK_LOGIN_REDIRECT_URL) ||
      RolesMainPages[profile.role] ||
      defaultMainRoute,
    { userId: profile.id, studentsPage: StudentsPage[profile.role] || myStudentsRoute },
  );

export const useProfile = () => {
  const { i18n: i18nLocal } = useTranslation();

  const history = useHistory();

  const { state: userState, dispatch: dispatchUserState } = useContext(UserContext);

  const userProfile = userState?.profile;

  const { getProfile, getParentDependentProfiles, getTourStatus, getUserSettings, patchLanguage } =
    useProfileService();

  const { getOneTimeActions } = useOneTimeActionService();

  const { dispatch: dispatchTourState } = useContext(TourContext);

  const getIsCampusSelectionAvailable = useCallback(
    (profile) => {
      const schools = profile?.schools || userProfile?.schools;
      if (schools) {
        const allSchoolsArray = Object.values(schools).flat();
        return allSchoolsArray.length > 1 || allSchoolsArray[0].rolesInSchool.length > 1;
      }
      return false;
    },
    [userProfile?.schools],
  );

  const changeLanguage = useCallback(
    (lng) => {
      i18nLocal.changeLanguage(lng);
    },
    [i18nLocal],
  );

  const getIsUnder18 = (profile) => {
    if (
      (profile?.role === RolesMap.STUDENT && !profile?.birthDate) ||
      getYearDifference(new Date(), new Date(profile?.birthDate)) < 18
    ) {
      return true;
    }
    return false;
  };

  const getDependentProfiles = async (role) => {
    if (role === RolesMap.GUARDIAN) {
      return getParentDependentProfiles(StudentActivityCriterions.ANY);
    }
    return null;
  };

  const getProfileState = async (
    { skipAuthCheck, skipDefaultErrorHandlers } = { skipAuthCheck: false },
  ) => {
    const profile = await getProfile(
      { skipAuthCheck, skipDefaultErrorHandlers },
      localStorage.getItem(LocalStorageKeysMap.AYO_CURRENT_CAMPUS),
      sessionStorage.getItem(LocalStorageKeysMap.AYO_ROLE_IN_SCHOOL),
    );
    sessionStorage.removeItem(LocalStorageKeysMap.AYO_ROLE_IN_SCHOOL);
    if (profile.lang) {
      const language = LanguagesMap[profile.lang];
      changeLanguage(language);
    } else {
      const value = getNormalizedLanguageCode(i18nLocal.language);
      await patchLanguage(value);
      profile.lang = value;
    }
    getTourStatus().then((status) => {
      dispatchTourState({ type: TourActions.SET_TOUR_STATUS, data: status });
    });
    let oneTimeActions;
    if (profile.role !== RolesMap.AYO_ADMIN) {
      oneTimeActions = await getOneTimeActions();
    }
    const dependentProfiles = await getDependentProfiles(profile.role);
    const settings = await getUserSettings();
    if (profile.schools) {
      if (
        Object.keys(profile.schools).length === 1 &&
        profile.schools[Object.keys(profile.schools)[0]]?.length === 1
      ) {
        profile.currentCampus = profile.schools[Object.keys(profile.schools)[0]][0].schoolName;
      } else {
        profile.currentCampus = localStorage.getItem(LocalStorageKeysMap.AYO_CURRENT_CAMPUS);
      }
      const currentCampusSchoolLevel = getCurrentCampusSchoolLevel(
        profile.currentCampus,
        profile.schools,
      );
      profile.currentCampusSchoolLevel = currentCampusSchoolLevel;
      setUserProperties({
        [GaUserProperties.SCHOOL_LEVEL]: currentCampusSchoolLevel,
        [GaUserProperties.CAMPUS]: profile.currentCampus,
      });
    }
    if (profile.grade) {
      setUserProperties({
        [GaUserProperties.GRADE]: profile.grade,
        [GaUserProperties.CAMPUS]: profile.schoolName,
      });
    }

    setUserProperties({
      [GaUserProperties.USER_ROLE]: profile.role,
    });

    const isGuardianWithoutStudentConsent =
      profile.role === RolesMap.GUARDIAN && !dependentProfiles.length;

    if (profile.role === RolesMap.GUARDIAN) {
      setUserProperties({
        [GaUserProperties.CHILDREN_COUNT]: dependentProfiles.length,
      });
    }

    dispatchUserState({
      type: UserActions.SET_USER_PROFILE,
      data: {
        profile,
        oneTimeActions,
        dependentProfiles,
        settings,
        isGuardianWithoutStudentConsent,
      },
    });

    return {
      ...profile,
      isGuardianWithoutStudentConsent,
    };
  };

  const loadProfileAndProceed = useCallback(
    (
      { skipStorageReset, shouldProceed, shouldReplaceHistory } = {
        skipStorageReset: false,
        shouldProceed: false,
        shouldReplaceHistory: false,
      },
    ) => {
      getProfileState({
        skipAuthCheck: true,
        skipDefaultErrorHandlers: [skipStorageReset && StatusCodeMap.UNAUTHORIZED],
      })
        .then((profile) => {
          if (shouldProceed) {
            const redirectURL =
              getIsCampusSelectionAvailable(profile) && !profile?.currentCampus
                ? campusSelectionRoute
                : getInnerAppRedirectUrl(profile);
            if (shouldReplaceHistory) {
              history.replace(redirectURL);
            } else {
              history.push(redirectURL);
            }
          }
          return profile;
        })
        .then((profile) => {
          if (!skipStorageReset && !getIsCampusSelectionAvailable(profile)) {
            sessionStorage.removeItem(LocalStorageKeysMap.SKYWARD_LOGIN_REDIRECT_URL);
            sessionStorage.removeItem(LocalStorageKeysMap.CLASSLINK_LOGIN_REDIRECT_URL);
          }
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history, getIsCampusSelectionAvailable],
  );

  const getOneTimeActionStatus = useCallback(
    (action) => userState.oneTimeActions?.includes(action),
    [userState.oneTimeActions],
  );

  const getPrivilegeStatus = useCallback(
    (privilege) => userState.profile?.privileges?.includes(privilege),
    [userState.profile],
  );

  return {
    changeLanguage,
    getIsCampusSelectionAvailable,
    getIsUnder18,
    getOneTimeActionStatus,
    getPrivilegeStatus,
    getProfileState,
    loadProfileAndProceed,
  };
};
