export const QuestionnaireProgressMap = {
  IN_PROGRESS: {
    value: 0,
    text: 'In progress',
  },
  HAS_SKIPPED: {
    value: 1,
    text: 'You skipped some questions',
  },
  START_TRAINING: {
    value: 2,
    text: 'Start training AYO',
  },
  COMPLETED: {
    value: 3,
    text: 'Completed',
  },
};

export const QuestionnaireActionsMap = {
  END_ACADEMIC_YEAR_REMINDER: 'END_ACADEMIC_YEAR_REMINDER',
  END_ACADEMIC_YEAR_REMINDER_NO_ANSWERS: 'END_ACADEMIC_YEAR_REMINDER_NO_ANSWERS',
  END_ACADEMIC_YEAR_REMINDER_SKIPPED_ANSWERS: 'END_ACADEMIC_YEAR_REMINDER_SKIPPED_ANSWERS',
  END_ACADEMIC_YEAR_REMINDER_FOLLOWUP_QUESTIONNAIRE:
    'END_ACADEMIC_YEAR_REMINDER_FOLLOWUP_QUESTIONNAIRE',
};

export default {
  QuestionnaireProgressMap,
  QuestionnaireActionsMap,
};
