/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Table as MUITable, TableBody, TableRow, TableCell, TableHead } from '@mui/material';
import PropTypes from 'prop-types';

import { Typography } from '../../atoms';

const Table = ({ columns, rows }) => (
  <MUITable className="ayo-table">
    <TableHead>
      <TableRow>
        {columns.map(({ id, label, ...headerProps }) => (
          <TableCell key={id} {...headerProps}>
            <Typography isLabel variant="body2">
              {label}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
    <TableBody>{rows}</TableBody>
  </MUITable>
);

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      headerProps: PropTypes.instanceOf(Object),
    }),
  ).isRequired,
  rows: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export default Table;
