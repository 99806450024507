import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Container, useMediaQuery, useTheme } from '@mui/material';
import { useLocation, useRouteMatch } from 'react-router-dom';

import { Typography } from '../../../atoms';
import { PageWrapper } from '../../../organisms';
import { UserContext } from '../../../../context';
import {
  useBreadcrumbsConfig,
  useClubHub,
  useNotificationsData,
  useSnackbar,
} from '../../../../hooks';
import { useClubHubService } from '../../../../services';
import {
  clubHubProfileDetailsRoute,
  clubHubRoute,
  familyFeedRoute,
} from '../../../../constants/routes';
import {
  ClubDotNotationKeys,
  ClubKeys,
  ClubRights,
  ClubsStatuses,
} from '../../../../constants/club-hub';
import {
  NotificationResourcesMap,
  NotificationSourcesMap,
  RolesMap,
} from '../../../../constants/enums';
import ClubJoinActions from '../club-hub-components/club-join-actions/ClubJoinActions';

import ClubDetailsName from './components/club-details-name/ClubDetailsName';
import ClubDetailsStatus from './components/club-details-status/ClubDetailsStatus';
import ClubDetailsVisibility from './components/club-details-visibility/ClubDetailsVisibility';
import ClubDetailsBanner from './components/club-details-banner/ClubDetailsBanner';
import ClubModeratorActions from './components/club-moderator-actions/ClubModeratorActions';
import ClubSections from './sections/ClubSections';

const ClubHubDetailsPage = () => {
  const { t } = useTranslation();

  const { pathname } = useLocation();

  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  const match = useRouteMatch();
  const clubId = Number(match.params.clubId);
  const studentId = Number(match.params.studentId);

  const { studentProfileBreadcrumbs } = useBreadcrumbsConfig(studentId);

  const { getClub, updateClub, postClubSettings } = useClubHubService();
  const { setSnackBarStatus } = useSnackbar();

  const [clubData, setClubData] = useState(null);

  const { state: userState } = useContext(UserContext);

  const { role } = userState.profile;

  const isStudent = role === RolesMap.STUDENT;
  const isGuardian = role === RolesMap.GUARDIAN;

  const { getClubRights } = useClubHub();

  const clubRights = useMemo(() => getClubRights(clubData), [clubData, getClubRights]);

  const { getUnreadNotificationsByResourceAndId, updateNotificationItems } = useNotificationsData();

  const onSaveClubSettings = useCallback(
    (body) => {
      postClubSettings(body, clubId).then((response) => {
        setClubData({ ...clubData, settings: response });
      });
    },
    [clubData, clubId, postClubSettings],
  );

  const unreadUpdates = useMemo(
    () =>
      getUnreadNotificationsByResourceAndId(
        NotificationSourcesMap.WEB_HIDDEN,
        NotificationResourcesMap.CLUB_HUB_NEW_INDICATOR,
        clubId,
      ),
    [clubId, getUnreadNotificationsByResourceAndId],
  );

  useEffect(() => {
    unreadUpdates?.forEach((item) => {
      updateNotificationItems([{ ...item, markedAsRead: true }], NotificationSourcesMap.WEB_HIDDEN);
    });
  });

  useEffect(() => {
    getClub(clubId).then((data) => setClubData(data));
  }, [clubId, getClub]);

  const updateClubHandler = useCallback(
    (changes, successSnackBarStatus, errorSnackBarStatus) => {
      const clubRequestBody = { ...changes };

      if (ClubKeys.ATTACHMENTS in changes && clubData.attachments?.length) {
        clubRequestBody[ClubKeys.REMOVED_ATTACHMENTS] = clubData.attachments;
      }

      updateClub(clubRequestBody, clubId, ClubDotNotationKeys)
        .then((data) => {
          setClubData(data);
          setSnackBarStatus(
            successSnackBarStatus || {
              text: t('Thanks for your changes! Your input has been saved'),
              type: 'success',
            },
          );
        })
        .catch(() => {
          setSnackBarStatus(
            errorSnackBarStatus || {
              text: 'AYO couldn’t save your edits Please try once more',
              type: 'error',
            },
          );
        });
    },
    [clubData?.attachments, clubId, setSnackBarStatus, t, updateClub],
  );

  const getIsFieldEditable = useCallback(
    (key) =>
      [ClubKeys.CLUB_NAME, ClubKeys.SCHOOLS].includes(key)
        ? clubRights[ClubRights.EDIT_CLUB] && clubData.status === ClubsStatuses.PENDING
        : clubRights[ClubRights.EDIT_CLUB] &&
          [ClubsStatuses.PENDING, ClubsStatuses.ACTIVE].includes(clubData?.status),
    [clubData?.status, clubRights],
  );

  const showBreadcrumbsNavigation = useMemo(
    () => pathname.includes(clubHubProfileDetailsRoute),
    [pathname],
  );

  return (
    <PageWrapper
      backToLink={isGuardian ? familyFeedRoute : clubHubRoute}
      backToText={isGuardian ? t('Go to Family feed') : t('Back to Club Hub')}
      breadcrumbsConfig={
        showBreadcrumbsNavigation
          ? [
              ...studentProfileBreadcrumbs[role],
              { gaLabel: null, label: clubData?.clubName, redirectUrl: null, cropped: true },
            ]
          : null
      }
      mainElementClassName="ayo-club-hub-details"
    >
      {clubData && (
        <Container>
          {clubRights[ClubRights.VIEW_CLUB_STATUS_BANNER] && (
            <Box mb={5}>
              <ClubDetailsStatus
                reason={isStudent ? clubData.declineReason : clubData.reason}
                status={isStudent ? clubData.membershipStatus : clubData.status}
              />
            </Box>
          )}
          <ClubDetailsBanner
            attachments={clubData?.attachments}
            isEditable={getIsFieldEditable(ClubKeys.ATTACHMENTS)}
            onChange={updateClubHandler}
          />
          <Box
            display="flex"
            flexDirection={isWidthUpSm ? 'row' : 'column'}
            justifyContent="space-between"
          >
            <Box mb={isStudent && !isWidthUpSm ? 3 : 0}>
              <Typography component="p" isLabel mb={0.5} variant="subtitle2">
                {t(`club_hub_types.${clubData.type}`).toUpperCase()}
              </Typography>
              <ClubDetailsName
                currentClubName={clubData.clubName}
                id={clubId}
                isEditable={getIsFieldEditable(ClubKeys.CLUB_NAME)}
                onSave={updateClubHandler}
                status={clubRights[ClubRights.VIEW_CLUB_STATUS] ? clubData.status : ''}
              />
            </Box>
            {clubRights[ClubRights.CHANGE_MEMBERSHIP_STATUS] && (
              <ClubJoinActions
                clubId={clubId}
                clubName={clubData.clubName}
                membershipStatus={clubData.membershipStatus}
                onJoin={({ membershipStatus }) =>
                  setClubData((prevState) => ({
                    ...prevState,
                    membershipStatus,
                    declineReason: null,
                  }))
                }
                requirements={clubData.requirements}
              />
            )}
          </Box>
          {clubRights[ClubRights.VIEW_CLUB_VISIBILITY] && (
            <ClubDetailsVisibility
              availability={clubData.availability}
              id={clubId}
              isEditable={getIsFieldEditable(ClubKeys.SCHOOLS)}
              onSave={updateClubHandler}
            />
          )}
          <ClubSections
            clubData={clubData}
            clubId={clubId}
            clubRights={clubRights}
            getIsFieldEditable={getIsFieldEditable}
            onSaveClubSettings={onSaveClubSettings}
            updateClubHandler={updateClubHandler}
          />
          {clubRights[ClubRights.MODERATE_CLUB] && (
            <ClubModeratorActions
              clubId={clubId}
              clubName={clubData.clubName}
              status={clubData.status}
            />
          )}
        </Container>
      )}
    </PageWrapper>
  );
};

export default ClubHubDetailsPage;
