import React, { useCallback, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Typography } from '../../../../atoms';
import { EditingButton, StudentAptitudeChip, StudentInterestItem } from '../../../../moleculas';
import { AptitudesInfo, FamilyActivitiesCategoriesTypes } from '../../../../../constants/enums';
import { formatObjKeysToWords } from '../../../../../utils';
import { ReactComponent as ExpandIcon } from '../../../../../resources/icons/chevron_down.svg';
import { ReactComponent as CollapseIcon } from '../../../../../resources/icons/chevron_up.svg';

const formatLabel = (name, count) => `${name} (${count})`;

const CategoryTypesInfo = {
  [FamilyActivitiesCategoriesTypes.INTEREST]: {
    sectionTitle: 'Interests',
    defaultNumberOfCategoriesToDisplay: 5,
  },
  [FamilyActivitiesCategoriesTypes.APTITUDE]: {
    sectionTitle: 'Aptitudes',
    defaultNumberOfCategoriesToDisplay: 2,
  },
};

const FILTER_CATEGORY_TYPES_ORDER = [
  FamilyActivitiesCategoriesTypes.INTEREST,
  FamilyActivitiesCategoriesTypes.APTITUDE,
];

const FamilyActivitiesFilter = ({ activeCategory, activeCategoryGroup, categories, onClick }) => {
  const { t } = useTranslation();

  const [isShowAllCategories, setIsShowAllCategories] = useState(false);

  const formatAriaLabel = useCallback(
    (name) => t('Filter by family activity category', { category: name }),
    [t],
  );

  const formattedCategories = useMemo(
    () =>
      categories.reduce((acc, currentValue) => {
        const newCategories = [...currentValue.categories];

        if (currentValue.categoryGroup === activeCategoryGroup) {
          const selectedCategory = newCategories.find(
            (element) => element.nameKey === activeCategory,
          );

          if (selectedCategory) {
            newCategories.splice(newCategories.indexOf(selectedCategory), 1);
            newCategories.unshift(selectedCategory);
          }
        }

        return {
          ...acc,
          [currentValue.categoryGroup]: newCategories,
        };
      }, {}),
    [activeCategory, activeCategoryGroup, categories],
  );

  const hasMoreCategories = useMemo(
    () =>
      formattedCategories[FamilyActivitiesCategoriesTypes.INTEREST]?.length >
        CategoryTypesInfo[FamilyActivitiesCategoriesTypes.INTEREST]
          .defaultNumberOfCategoriesToDisplay ||
      formattedCategories[FamilyActivitiesCategoriesTypes.APTITUDE]?.length >
        CategoryTypesInfo[FamilyActivitiesCategoriesTypes.APTITUDE]
          .defaultNumberOfCategoriesToDisplay,
    [formattedCategories],
  );

  const getCategoryChip = useCallback(
    (item, categoryType) => {
      switch (categoryType) {
        case FamilyActivitiesCategoriesTypes.INTEREST:
          return (
            <StudentInterestItem
              key={item.name}
              ariaLabel={formatAriaLabel(item.name)}
              interest={item}
              isSelected={item.nameKey === activeCategory}
              isWithEmoji
              label={formatLabel(item.name, item.totalElements)}
              onClick={() => onClick(categoryType, item, item.nameKey === activeCategory)}
            />
          );
        case FamilyActivitiesCategoriesTypes.APTITUDE:
          return (
            <StudentAptitudeChip
              key={item.name}
              aptitude={item.name}
              ariaLabel={formatAriaLabel(formatObjKeysToWords(item.name))}
              isSelected={item.nameKey === activeCategory}
              label={formatLabel(t(AptitudesInfo[item.name].name), item.totalElements)}
              onClick={() => onClick(categoryType, item, item.nameKey === activeCategory)}
            />
          );
        default:
          return null;
      }
    },
    [formatAriaLabel, activeCategory, t, onClick],
  );

  const getCategoriesChips = useCallback(
    (categoriesArr, type) =>
      categoriesArr.map((item, i) =>
        isShowAllCategories || i < CategoryTypesInfo[type].defaultNumberOfCategoriesToDisplay
          ? getCategoryChip(item, type)
          : null,
      ),
    [getCategoryChip, isShowAllCategories],
  );

  return (
    <Box>
      {FILTER_CATEGORY_TYPES_ORDER.map(
        (item) =>
          !!formattedCategories[item]?.length && (
            <Box key={item} mb={2}>
              <Typography mb={1} variant="subtitle2">
                {t(CategoryTypesInfo[item].sectionTitle)}
              </Typography>
              {getCategoriesChips(formattedCategories[item], item)}
            </Box>
          ),
      )}
      {!!hasMoreCategories && (
        <Box mb={3}>
          <EditingButton
            gaLabel={!isShowAllCategories ? 'Show more' : 'Show less'}
            icon={!isShowAllCategories ? <ExpandIcon /> : <CollapseIcon />}
            iconPosition="end"
            onClick={() => {
              setIsShowAllCategories((prevState) => !prevState);
            }}
            text={t(!isShowAllCategories ? 'Show more' : 'Show less')}
          />
        </Box>
      )}
    </Box>
  );
};

FamilyActivitiesFilter.propTypes = {
  activeCategory: PropTypes.string,
  activeCategoryGroup: PropTypes.oneOf(Object.values(FamilyActivitiesCategoriesTypes)),
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      nameKey: PropTypes.string,
    }),
  ).isRequired,
  onClick: PropTypes.func.isRequired,
};

FamilyActivitiesFilter.defaultProps = {
  activeCategory: null,
  activeCategoryGroup: null,
};

export default FamilyActivitiesFilter;
