import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Container, Grid, useMediaQuery, useTheme } from '@mui/material';

import { Image, Typography } from '../../../../../atoms';

const LessonPlannerDefinitionSection = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpLg = useMediaQuery(theme.breakpoints.up('lg'));
  return (
    <Box className="ayo-lesson-planner-landing__definition-section" py={3}>
      <Container>
        <Grid container justifyContent="space-between" spacing={isWidthUpLg ? 0 : 3}>
          <Grid
            alignContent="center"
            container
            direction="column"
            item
            justifyContent="center"
            pb={isWidthUpLg ? 12 : 0}
            sm={6}
            xs={12}
          >
            <Typography component="h1" paragraph variant="display">
              {t('Lesson planner')}
            </Typography>
            <Typography component="p" variant="subtitle1">
              {t(
                'Discover how to seamlessly design, build, and nail down your lessons to fully engage your students in the learning experience!',
              )}
            </Typography>
          </Grid>
          <Grid alignContent="center" container item sm={6} xs={12}>
            <Image
              alt={t('A smiling woman and illustrations of a level and a paint roller.')}
              src="/media/lesson-planner/definition-section-img.png"
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default LessonPlannerDefinitionSection;
