import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Container, Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { Typography } from '../../../atoms';
import { Chip, ClickableCard } from '../../../moleculas';
import { BottomBar, PageWrapper } from '../../../organisms';
import {
  aptitudesRoute,
  leadershipRoute,
  lessonPlannerRoute,
  opportunitiesRoute,
  rootRoute,
} from '../../../../constants/routes';
import { ReactComponent as AptitudesInitiativesCard } from '../../../../resources/images/aptitudes_initiatives_card.svg';
import { ReactComponent as LeadershipInitiativesCard } from '../../../../resources/images/leadership_initiatives_card.svg';
import { ReactComponent as OpportunitiesInitiativesCard } from '../../../../resources/images/opportunities_initiatives_card.svg';
import { ReactComponent as LessonPlannerInitiativesCard } from '../../../../resources/images/lesson_planner_initiatives_card.svg';

const InitiativesPage = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const cardsConfig = useMemo(
    () => [
      {
        id: 'leadership-attributes',
        gaLabel: 'Leadership Attributes initiatives card',
        image: LeadershipInitiativesCard,
        title: 'Leadership Attributes',
        handler: () => history.push(leadershipRoute),
      },
      {
        id: 'opportunities',
        gaLabel: 'Opportunities initiatives card',
        image: OpportunitiesInitiativesCard,
        title: 'Opportunities',
        handler: () => history.push(opportunitiesRoute),
      },
      {
        id: 'aptitudes',
        gaLabel: 'Aptitudes initiatives card',
        image: AptitudesInitiativesCard,
        title: 'Aptitudes',
        handler: () => history.push(aptitudesRoute),
      },
      {
        id: 'lesson-planner',
        gaLabel: 'Lesson planner initiatives card',
        cardHeader: <Chip label={t('For educators')} />,
        image: LessonPlannerInitiativesCard,
        title: 'Lesson planner',
        handler: () => history.push(lessonPlannerRoute),
      },
    ],
    [history, t],
  );

  return (
    <PageWrapper
      backToLink={rootRoute}
      backToText={t('Back to main page')}
      className="ayo-initiatives-page"
      customFooter={<BottomBar noNav />}
      noNav
    >
      <Container>
        <Typography component="h1" paragraph variant="h1">
          {t('AYO’s initiatives')}
        </Typography>
        <Box className="ayo-initiatives-page__text-block" mb={5}>
          <Typography component="p" variant="body2">
            {t(
              'These are the programs and frameworks that have been initiated in AYO. Select any to see the Dedicated page with detailed information',
            )}
          </Typography>
        </Box>
        <Grid container mb={5} spacing={3}>
          {cardsConfig.map((initiativesCard) => (
            <Grid
              key={initiativesCard.id}
              className="ayo-initiatives-page__card"
              item
              md={3}
              sm={4}
              xl={4}
              xs={12}
            >
              <ClickableCard
                cardHeader={initiativesCard.cardHeader || null}
                className={`ayo-initiatives-page__card--${initiativesCard.id}`}
                gaLabel={initiativesCard.gaLabel}
                mediaContent={initiativesCard.image}
                onClick={initiativesCard.handler}
                title={t(initiativesCard.title)}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </PageWrapper>
  );
};

export default InitiativesPage;
