/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Container, Grid, useMediaQuery, useTheme } from '@mui/material';

import { Button, Image, Typography } from '../../atoms';
import { LoginButton } from '../../moleculas';
import { useLogin } from '../../../utils';
import { UserContext } from '../../../context';
import { landingBlock } from '../../../constants/propTypes';
import { RolesMainPages } from '../../../constants/pages';
import { ReactComponent as TwistedArrow } from '../../../resources/icons/arrow_twisted_right.svg';

const LandingBlock = ({ config, className }) => {
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const { t } = useTranslation();
  const { state: userState } = useContext(UserContext);
  const loginOptions = [];
  const { signInHandler } = useLogin();

  if (userState.profile) {
    loginOptions.push(
      <Grid item md={4} sm={7} xs={9}>
        <Box display="flex" justifyContent="center">
          <Button
            component={RouterLink}
            gaLabel="Back to app"
            isAdaptive
            to={RolesMainPages[userState.profile.role]}
            variant="primary"
          >
            {t('Back to app')}
          </Button>
        </Box>
      </Grid>,
    );
  } else {
    config.loginOptions.forEach((option) => {
      loginOptions.push(
        <Grid className="login-option__container" item md={3} sm={4} xl={4} xs={9}>
          <LoginButton
            disabled={option.disabled}
            label={option.label}
            onClick={() => signInHandler(option.loginProvider)}
          />
          {option.caption && (
            <Box className="login-option__caption">
              <TwistedArrow />
              <Typography align="center" isLabel variant="caption">
                {t(option.caption)}
              </Typography>
            </Box>
          )}
        </Grid>,
      );
    });
  }

  return (
    <Container className={className}>
      <Box pb={isWidthUpSm ? 10 : 5}>
        <Grid container direction="column">
          <Grid container item justifyContent="center">
            <Grid item md={5} sm={8} xs={12}>
              <Box display="flex" justifyContent="center" py={2}>
                <Image
                  alt={t(config.imageTitle)}
                  className="ayo-svg-illustration ayo-main-illustration"
                  src={config.mainImg}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container item justifyContent="center">
            <Grid item xs={12}>
              <Typography align="center" paragraph variant="display">
                {`${t(config.titleStart)}\n${t(config.titleEnd)}`}
              </Typography>
              <Typography align="center" paragraph>
                {t(config.titleBottom)}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item justifyContent="center">
            <Grid container direction="row" item justifyContent="center" lg={10} xs={12}>
              {loginOptions}
            </Grid>
            {!userState.profile && (
              <Grid item sm={8} xs={12}>
                <Typography align="center" className="instruction-text" isLabel variant="body2">
                  {t(config.instructionText)}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

LandingBlock.propTypes = {
  className: PropTypes.string,
  config: landingBlock,
};

LandingBlock.defaultProps = {
  className: '',
  config: {},
};

export default LandingBlock;
