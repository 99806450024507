import React, { useMemo } from 'react';
import { Box, DialogContent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Button, DialogTitle, Link, Typography } from '../../../../atoms';
import {
  SideDialog,
  StudentAptitudeChip,
  StudentInterestItem,
  TextWithTooltip,
} from '../../../../moleculas';
import { FamilyActivitiesCategoriesTypes } from '../../../../../constants/enums';

const FamilyActivityDialog = ({ isOpen, item, onClose }) => {
  const { t } = useTranslation();

  const isInterest = useMemo(
    () => item?.insightType === FamilyActivitiesCategoriesTypes.INTEREST,
    [item?.insightType],
  );

  return (
    !!item && (
      <SideDialog
        className="ayo-family-activity-dialog"
        gaLabel="Family activity dialog"
        isOpen={isOpen}
        onClose={onClose}
      >
        <DialogTitle disableTypography>
          <Typography component="h2" variant="subtitle1">
            {item?.title}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column" height="100%" justifyContent="space-between">
            <Box display="flex" flexDirection="column" mt={3} rowGap={3}>
              {!!item?.activityUrl && (
                <Link
                  className="ayo-family-activity-dialog__link"
                  gaLabel="Family activity link open"
                  href={item.activityUrl}
                  target="_blank"
                >
                  <TextWithTooltip rowsCount={3} title={item.activityUrl} />
                </Link>
              )}
              <Typography variant="body2">{item?.description}</Typography>
              <Box alignItems="center" columnGap={2} display="flex">
                <Typography
                  className="ayo-family-activity-dialog__insight-type"
                  isLabel
                  variant="body3"
                >
                  {t(`Common ${item?.insightType.toLowerCase()}`)}
                </Typography>
                {isInterest ? (
                  <StudentInterestItem
                    interest={{
                      name: item.insightValue.name,
                      nameKey: item.insightValue.nameKey,
                    }}
                    isWithEmoji
                  />
                ) : (
                  <StudentAptitudeChip aptitude={item.insightValue.name} />
                )}
              </Box>
            </Box>
            <Box columnGap={3} display="flex" mx="auto" width="50%">
              <Button fullWidth gaLabel="Close" onClick={onClose}>
                {t('Close')}
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </SideDialog>
    )
  );
};

FamilyActivityDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  item: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    activityUrl: PropTypes.string,
    insightType: PropTypes.string,
    insightValue: PropTypes.shape({
      name: PropTypes.string,
      nameKey: PropTypes.string,
    }),
  }),
  onClose: PropTypes.func.isRequired,
};

FamilyActivityDialog.defaultProps = {
  item: null,
};

export default FamilyActivityDialog;
