import React, { useEffect, useMemo } from 'react';
import { Box, Grid, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { Button, DatePicker, Typography } from '../../../atoms';
import { CalendarDay } from '../../../moleculas';
import { useLessonPlannerData } from '../../../../hooks';
import { getFormattedDate, isCurrentYear, isWeekend, useCalendar } from '../../../../utils';
import { ReactComponent as ChevronLeft } from '../../../../resources/icons/chevron_left.svg';
import { ReactComponent as ChevronRight } from '../../../../resources/icons/chevron_right.svg';
import { ReactComponent as DatePickerIcon } from '../../../../resources/icons/date_picker.svg';
import { calendarViewMainProps } from '../../../../constants/propTypes';

const REGULAR_DATE_STEP = 1;
const WEEKEND_DATE_STEP = 3;

const DayView = ({ currentDate, setCurrentDate, currentPickerDates, scheduleId }) => {
  const isCurrentDateMonday = currentDate.day() === 1;
  const previousWorkingDay = currentDate.subtract(
    isCurrentDateMonday ? WEEKEND_DATE_STEP : REGULAR_DATE_STEP,
    'day',
  );

  const isCurrentDateFriday = currentDate.day() === 5;
  const nextWorkingDay = currentDate.add(
    isCurrentDateFriday ? WEEKEND_DATE_STEP : REGULAR_DATE_STEP,
    'day',
  );

  const isTodaySaturday = dayjs().day() === 6;

  const isCurrentDayToday = currentDate.isToday();

  const { isCalendarOpen, onCalendarClick } = useCalendar();

  const isTodayDateInPlanner = dayjs().isBetween(
    currentPickerDates.firstDayOfSchedule,
    currentPickerDates.lastDayOfSchedule,
  );

  const { getDateData, loadDatesData } = useLessonPlannerData();

  const shortDate = useMemo(() => currentDate.format('YYYY-MM-DD'), [currentDate]);

  const currentDateData = getDateData(scheduleId, shortDate);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    loadDatesData(scheduleId, shortDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleId, shortDate]);

  useEffect(() => {
    if (
      currentPickerDates.firstDayOfSchedule &&
      currentPickerDates.lastDayOfSchedule &&
      (currentDate.isBefore(dayjs(currentPickerDates.firstDayOfSchedule), 'day') ||
        currentDate.isAfter(dayjs(currentPickerDates.lastDayOfSchedule), 'day'))
    ) {
      setCurrentDate(dayjs(currentPickerDates.firstDayOfSchedule));
    }
  }, [
    currentDate,
    currentPickerDates.firstDayOfSchedule,
    currentPickerDates.lastDayOfSchedule,
    setCurrentDate,
  ]);

  return (
    <Grid container>
      <Grid
        alignItems="center"
        container
        item
        justifyContent="center"
        mb={5}
        position="relative"
        xs={12}
      >
        <Box alignItems="center" display="flex" justifyContent="center">
          <Button
            aria-label={t('Previous day')}
            gaLabel="Previous day"
            isHidden={previousWorkingDay.isBefore(
              dayjs(currentPickerDates.firstDayOfSchedule),
              'day',
            )}
            isIconButton
            onClick={() => {
              setCurrentDate(previousWorkingDay);
            }}
          >
            <ChevronLeft />
          </Button>
          <DatePicker
            disabledDayText={t('You can’t select this day.')}
            gaLabel="Lesson planner day view"
            isOpen={isCalendarOpen}
            label="Date"
            maxDate={currentPickerDates.lastDayOfSchedule}
            minDate={currentPickerDates.firstDayOfSchedule}
            onChange={(date) => setCurrentDate(dayjs(date))}
            outlined
            renderInput={(props) => (
              <IconButton
                // eslint-disable-next-line react/prop-types
                ref={props.inputRef}
                // eslint-disable-next-line react/prop-types, react/jsx-props-no-spreading
                {...props.InputProps.endAdornment.props.children.props}
                className="ayo-icon-button"
                disableRipple
              >
                <DatePickerIcon />
              </IconButton>
            )}
            setIsOpen={onCalendarClick}
            shouldDisabledDate={isWeekend}
            value={currentDate.toDate()}
          />
          <Typography
            className="ayo-schedule-page__calendar__selected-date"
            onClick={onCalendarClick}
            variant="subtitle1"
          >
            {getFormattedDate(currentDate.toDate(), i18n.language, {
              day: 'numeric',
              month: 'long',
            })}
            {!isCurrentYear(currentDate) && `, ${currentDate.get('year')}`}
          </Typography>
          <Button
            aria-label={t('Next day')}
            gaLabel="Next day"
            isHidden={nextWorkingDay.isAfter(dayjs(currentPickerDates.lastDayOfSchedule), 'day')}
            isIconButton
            onClick={() => {
              setCurrentDate(nextWorkingDay);
            }}
          >
            <ChevronRight />
          </Button>
        </Box>
        {!isCurrentDayToday && isTodayDateInPlanner && !isTodaySaturday && (
          <Box position="absolute" right="0">
            <Button
              gaLabel="Show today"
              onClick={() => setCurrentDate(dayjs())}
              variant="secondary"
            >
              {t('Show today')}
            </Button>
          </Box>
        )}
      </Grid>
      <Grid item xs={12}>
        <CalendarDay date={currentDate} day={currentDateData} scheduleId={scheduleId} />
      </Grid>
    </Grid>
  );
};

DayView.propTypes = calendarViewMainProps;

DayView.defaultProps = {
  currentPickerDates: {},
};

export default DayView;
