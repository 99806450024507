import {
  ActivitiesFilterCategories,
  ActivitiesTypes,
  Aptitudes,
  HighlightsInitiatives,
  LeadershipAttributes,
  OpportunitiesCategoriesMap,
  StudentFiltersSpecialValues,
  StudentFiltersTypes,
} from '../../../constants/enums';
import { Moods } from '../../../constants/moods';
import {
  ClubHubModerationFilterCategory,
  ContentModerationFilterCategory,
  FamilyFeedFilterCategory,
  OpportunitiesFilterCategory,
} from '../../../constants/filter-categories';
import { filtersListSeparator } from '../../../constants/values';

export const getFilterValueExcludeList = (valueList, optionsList) =>
  optionsList.filter((option) => !valueList.includes(option));

export const transformToFilterValueString = (filterCriterion, valuesList, optionsList) => {
  let finalValuesList = [...valuesList];
  if (filterCriterion === StudentFiltersTypes.EXCLUDE) {
    finalValuesList = getFilterValueExcludeList(finalValuesList, optionsList);
  } else {
    finalValuesList = valuesList.filter((value) => optionsList.includes(value));
  }
  return `${filterCriterion}:${finalValuesList.join(filtersListSeparator)}`;
};

export const isLAFilterEnabled = (stats, attribute) =>
  stats.leadershipAttributes[attribute].have && stats.leadershipAttributes[attribute].notHave;

export const transformSearchToObject = (search) =>
  [...search.entries()].reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

const defaultGetFilterDisplayName = (filterCriterion, filterName, filterStat) =>
  `${filterName} (${filterStat})`;

export const getFilterValueParts = (filters, stats) => {
  const { filterValue, filterCategory } = filters;
  const [filterCriterion, filtersListString] = filterValue ? filterValue.split(':') : [null, ''];
  let filtersList = filtersListString ? filtersListString.split(filtersListSeparator) : [];
  if (filterCriterion === StudentFiltersTypes.EXCLUDE) {
    // eslint-disable-next-line no-use-before-define
    const optionsList = StudentFiltersStrategy[filterCategory].getFilterOptionsList(stats);
    filtersList = [
      ...getFilterValueExcludeList(filtersList, optionsList),
      StudentFiltersSpecialValues.ALL_OTHER,
    ];
  }
  if (filterCriterion === StudentFiltersTypes.PENDING && filtersList.length === 0) {
    filtersList = [StudentFiltersSpecialValues.PENDING];
  }
  if (filterCriterion === StudentFiltersTypes.INCLUDE && filtersList.length === 0) {
    if (
      filterCategory === HighlightsInitiatives.PARENTS_ANSWERS ||
      filterCategory === HighlightsInitiatives.PORTFOLIO
    ) {
      filtersList = [StudentFiltersSpecialValues.HAVE];
    } else if (filterCategory === HighlightsInitiatives.OPPORTUNITIES) {
      filtersList = [StudentFiltersSpecialValues.INCLUDE];
    }
  }
  return {
    filterCriterion,
    filtersList,
  };
};

export const StudentFiltersStrategy = {
  [HighlightsInitiatives.LEADERSHIP_ATTRIBUTES]: {
    getStatValue: (stats, filterName, filterCriterion) =>
      stats.leadershipAttributes[filterName][
        filterCriterion === StudentFiltersTypes.INCLUDE ? 'have' : 'notHave'
      ],
    validateFilters: (filterValue, stats) => {
      const { filterCriterion, filtersList } = getFilterValueParts(filterValue, stats);
      const specialValues = Object.values(StudentFiltersSpecialValues);
      return (
        filterCriterion &&
        filtersList.filter((filter) => !specialValues.includes(filter)).length > 0
      );
    },
    getFilterDisplayName: (filterCriterion, filterName, filterStat) =>
      `${filterCriterion}: ${filterName} (${filterStat})`,
  },
  [HighlightsInitiatives.INTERESTS]: {
    getFilterDisplayName: (filterCriterion, filterName, filterStat, stats) => {
      const displayName = Object.keys(stats.interests).includes(filterName)
        ? stats.interests[filterName].name
        : filterName;
      return `${displayName} (${filterStat})`;
    },
    getStatValue: (stats, filterName) => stats.interests[filterName].count,
    validateFilters: (filterValue, stats) => {
      const { filterCriterion, filtersList } = getFilterValueParts(filterValue, stats);
      return (
        filterCriterion === StudentFiltersTypes.EXCLUDE ||
        filterCriterion === StudentFiltersTypes.PENDING ||
        (filterCriterion === StudentFiltersTypes.INCLUDE && filtersList.length > 0)
      );
    },
    getFilterOptionsList: (stats) => {
      const statsInterestsValues = stats ? { ...stats.interests } : {};
      delete statsInterestsValues[StudentFiltersSpecialValues.ALL_OTHER];
      delete statsInterestsValues[StudentFiltersSpecialValues.PENDING];
      return Object.keys(statsInterestsValues);
    },
  },
  [HighlightsInitiatives.GOALS]: {
    getFilterDisplayName: defaultGetFilterDisplayName,
    getStatValue: (stats, filterName) => stats.goals[filterName],
    validateFilters: (filterValue, stats) => {
      const { filterCriterion, filtersList } = getFilterValueParts(filterValue, stats);
      return (
        filterCriterion === StudentFiltersTypes.PENDING ||
        (filterCriterion === StudentFiltersTypes.INCLUDE && filtersList.length > 0)
      );
    },
    getFilterOptionsList: (stats) => {
      const statsInterestsValues = stats ? { ...stats.goals } : {};
      delete statsInterestsValues[StudentFiltersSpecialValues.PENDING];
      return Object.keys(statsInterestsValues);
    },
  },
  [HighlightsInitiatives.EXTRACURRICULARS]: {
    getFilterDisplayName: defaultGetFilterDisplayName,
    getStatValue: (stats, filterName) => stats.extracurriculars[filterName],
    validateFilters: (filterValue, stats) => {
      const { filterCriterion, filtersList } = getFilterValueParts(filterValue, stats);
      return (
        filterCriterion === StudentFiltersTypes.EXCLUDE ||
        filterCriterion === StudentFiltersTypes.PENDING ||
        (filterCriterion === StudentFiltersTypes.INCLUDE && filtersList.length > 0)
      );
    },
    getFilterOptionsList: (stats) => {
      const statsExtracurriculars = stats ? { ...stats.extracurriculars } : {};
      delete statsExtracurriculars[StudentFiltersSpecialValues.ALL_OTHER];
      delete statsExtracurriculars[StudentFiltersSpecialValues.PENDING];
      return Object.keys(statsExtracurriculars);
    },
  },
  [HighlightsInitiatives.OPPORTUNITIES]: {
    getFilterDisplayName: defaultGetFilterDisplayName,
    getStatValue: (stats, filterName) => stats.opportunities[filterName],
    validateFilters: (filterValue, stats) => {
      const { filterCriterion } = getFilterValueParts(filterValue, stats);
      return (
        filterCriterion === StudentFiltersTypes.PENDING ||
        filterCriterion === StudentFiltersTypes.INCLUDE
      );
    },
    getFilterOptionsList: (stats) => {
      const statsOpportunitiesValues = stats ? { ...stats.opportunities } : {};
      return Object.keys(statsOpportunitiesValues);
    },
  },
  [HighlightsInitiatives.PARENTS_ANSWERS]: {
    getFilterDisplayName: (filterCriterion, filterName, filterStat, stats, t) => {
      if (filterName === t('analytics.highlights.have')) {
        return `${t('analytics.highlights.SUBMITTED')} (${filterStat})`;
      }
      return `${filterName} (${filterStat})`;
    },
    getStatValue: (stats, filterName) => stats.parentsAnswers[filterName],
    validateFilters: (filterValue, stats) => {
      const { filterCriterion } = getFilterValueParts(filterValue, stats);
      return (
        filterCriterion === StudentFiltersTypes.PENDING ||
        filterCriterion === StudentFiltersTypes.INCLUDE
      );
    },
    getFilterOptionsList: (stats) => {
      const statsParentsAnswersValues = stats ? { ...stats.parentsAnswers } : {};
      return Object.keys(statsParentsAnswersValues);
    },
  },
  [HighlightsInitiatives.PORTFOLIO]: {
    getFilterDisplayName: defaultGetFilterDisplayName,
    getStatValue: (stats, filterName) => stats.portfolio[filterName],
    validateFilters: (filterValue, stats) => {
      const { filterCriterion } = getFilterValueParts(filterValue, stats);
      return (
        filterCriterion === StudentFiltersTypes.PENDING ||
        filterCriterion === StudentFiltersTypes.INCLUDE
      );
    },
    getFilterOptionsList: (stats) => {
      const statsPortfolioValues = stats ? { ...stats.portfolio } : {};
      return Object.keys(statsPortfolioValues);
    },
  },
  [HighlightsInitiatives.ACHIEVEMENTS]: {
    getFilterDisplayName: defaultGetFilterDisplayName,
    getStatValue: (stats, filterName) => stats.achievements[filterName],
    validateFilters: (filterValue, stats) => {
      const { filterCriterion, filtersList } = getFilterValueParts(filterValue, stats);
      return filterCriterion === StudentFiltersTypes.PENDING || filtersList.length !== 0;
    },
    getFilterOptionsList: (stats) => {
      const achievementStats = stats ? { ...stats.achievements } : {};
      delete achievementStats[StudentFiltersSpecialValues.PENDING];
      delete achievementStats[StudentFiltersSpecialValues.ALL_OTHER];
      return Object.keys(achievementStats);
    },
  },
  [ActivitiesFilterCategories.TYPE]: {
    getFilterDisplayName: defaultGetFilterDisplayName,
    getStatValue: (stats, filterName) => stats[ActivitiesFilterCategories.TYPE][filterName],
    validateFilters: (filterValue, stats) => {
      const { filterCriterion, filtersList } = getFilterValueParts(filterValue, stats);
      return filterCriterion === StudentFiltersTypes.INCLUDE && filtersList.length > 0;
    },
    getFilterOptionsList: () => Object.values(ActivitiesTypes),
  },
  [ActivitiesFilterCategories.LEADERSHIP_ATTRIBUTES]: {
    getFilterDisplayName: defaultGetFilterDisplayName,
    getStatValue: (stats, filterName) =>
      stats[ActivitiesFilterCategories.LEADERSHIP_ATTRIBUTES][filterName],
    validateFilters: (filterValue, stats) => {
      const { filterCriterion, filtersList } = getFilterValueParts(filterValue, stats);
      return filterCriterion === StudentFiltersTypes.INCLUDE && filtersList.length > 0;
    },
    getFilterOptionsList: () => Object.values(LeadershipAttributes),
  },
  [HighlightsInitiatives.APTITUDES]: {
    getFilterDisplayName: defaultGetFilterDisplayName,
    getStatValue: (stats, filterName) =>
      filterName === StudentFiltersSpecialValues.PENDING
        ? stats.aptitudes[StudentFiltersSpecialValues.NOT_DISCOVERED]
        : stats.aptitudes[filterName],
    validateFilters: (filterValue, stats) => {
      const { filterCriterion, filtersList } = getFilterValueParts(filterValue, stats);
      return (
        (filterCriterion === StudentFiltersTypes.INCLUDE && filtersList.length > 0) ||
        filterCriterion === StudentFiltersTypes.PENDING
      );
    },
    getPendingDisplayName: (stat, t) =>
      `${t(`analytics.highlights.${StudentFiltersSpecialValues.NOT_DISCOVERED}`)} (${stat})`,
    getFilterOptionsList: () => Object.values(Aptitudes),
  },
  [OpportunitiesFilterCategory]: {
    getFilterDisplayName: (filterCriterion, filterName) => filterName,
    validateFilters: (filterValue) => {
      const { filterCriterion, filtersList } = getFilterValueParts(filterValue);
      return filterCriterion === StudentFiltersTypes.INCLUDE && filtersList.length > 0;
    },
    getFilterOptionsList: () => Object.keys(OpportunitiesCategoriesMap),
  },
  [HighlightsInitiatives.MOODS]: {
    getFilterDisplayName: defaultGetFilterDisplayName,
    validateFilters: (filterValue) => {
      const { filterCriterion, filtersList } = getFilterValueParts(filterValue);
      return (
        filterCriterion === StudentFiltersTypes.PENDING ||
        (filterCriterion === StudentFiltersTypes.INCLUDE && filtersList.length > 0)
      );
    },
    getFilterOptionsList: () => Object.keys(Moods),
    getStatValue: (stats, filterName) => stats.moods[filterName],
  },
  [ContentModerationFilterCategory]: {
    getFilterDisplayName: (filterCriterion, filterName, filterStat, stats, t) =>
      `${t(`privileges.${filterStat.group}`)}: ${t(`privileges.${filterStat.value}`)}`,
    getFilterOptionsList: (stats) => Object.values(stats[ContentModerationFilterCategory]),
    getStatValue: (stats, filterName) => ({
      group: ContentModerationFilterCategory,
      value: filterName,
    }),
    validateFilters: (filterValue) => {
      const { filterCriterion, filtersList } = getFilterValueParts(filterValue);
      return filterCriterion === StudentFiltersTypes.INCLUDE && filtersList.length > 0;
    },
  },
  [FamilyFeedFilterCategory]: {
    getFilterDisplayName: (filterCriterion, filterName, filterStat, stats, t) =>
      `${t(`privileges.family_feed.chip_labels.general`)}: ${t(
        `privileges.family_feed.chip_labels.${filterStat.value}`,
      )}`,
    getFilterOptionsList: (stats) => Object.values(stats[FamilyFeedFilterCategory]),
    getStatValue: (stats, filterName) => ({
      group: FamilyFeedFilterCategory,
      value: filterName,
    }),
    validateFilters: (filterValue) => {
      const { filterCriterion, filtersList } = getFilterValueParts(filterValue);
      return filterCriterion === StudentFiltersTypes.INCLUDE && filtersList.length > 0;
    },
  },
  [ClubHubModerationFilterCategory]: {
    getFilterDisplayName: (filterCriterion, filterName, filterStat, stats, t) =>
      t('moderate the clubs'),
    getFilterOptionsList: (stats) => Object.values(stats[ClubHubModerationFilterCategory]),
    getStatValue: (stats, filterName) => ({
      group: ClubHubModerationFilterCategory,
      value: filterName,
    }),
    validateFilters: (filterValue) => {
      const { filterCriterion, filtersList } = getFilterValueParts(filterValue);
      return filterCriterion === StudentFiltersTypes.INCLUDE && filtersList.length > 0;
    },
  },
  [HighlightsInitiatives.ENDORSEMENTS]: {
    getFilterDisplayName: (filterCriterion, filterName, filterStat, stats) =>
      `${
        Object.keys(stats.endorsements.selected).includes(filterName)
          ? stats.endorsements.selected[filterName].name
          : filterName
      } (${filterStat})`,
    validateFilters: (filterValue) => {
      const { filterCriterion, filtersList } = getFilterValueParts(filterValue);
      return (
        filterCriterion === StudentFiltersTypes.PENDING ||
        (filterCriterion === StudentFiltersTypes.INCLUDE && filtersList.length > 0)
      );
    },
    getFilterOptionsList: (stats) => Object.keys(stats.endorsements.selected),
    getStatValue: (stats, filterName) =>
      filterName === StudentFiltersSpecialValues.PENDING
        ? stats.endorsements.pending
        : stats.endorsements.selected[filterName]?.count,
    getChipsLabel: (filterCrtiterion, t) =>
      filterCrtiterion === StudentFiltersTypes.INCLUDE
        ? `${t('analytics.highlights.endorsements.SELECTED')}:`
        : null,
  },
  [HighlightsInitiatives.ENDORSEMENTS_TYPES]: {
    getFilterDisplayName: (filterCriterion, filterName, filterStat, stats) =>
      `${stats.endorsements.switchedByType[filterName]?.name} (${filterStat})`,
    validateFilters: (filterValue) => {
      const { filterCriterion, filtersList } = getFilterValueParts(filterValue);
      return (
        filterCriterion === StudentFiltersTypes.PENDING ||
        (filterCriterion === StudentFiltersTypes.INCLUDE && filtersList.length > 0)
      );
    },
    getFilterOptionsList: (stats) => Object.keys(stats.endorsements.switchedByType),
    getStatValue: (stats, filterName) => stats.endorsements.switchedByType[filterName]?.count,
    getChipsLabel: (filterCrtiterion, t) =>
      `${t('analytics.highlights.endorsements.TYPE').replace('...', '')}:`,
  },
  [HighlightsInitiatives.ENDORSEMENTS_REASONS]: {
    getFilterDisplayName: (filterCriterion, filterName, filterStat, stats) =>
      `${stats.endorsements.switchedByReason[filterName]?.name} (${filterStat})`,
    validateFilters: (filterValue) => {
      const { filterCriterion, filtersList } = getFilterValueParts(filterValue);
      return (
        filterCriterion === StudentFiltersTypes.PENDING ||
        (filterCriterion === StudentFiltersTypes.INCLUDE && filtersList.length > 0)
      );
    },
    getFilterOptionsList: (stats) => Object.keys(stats.endorsements.switchedByReason),
    getStatValue: (stats, filterName) => stats.endorsements.switchedByReason[filterName]?.count,
    getChipsLabel: (filterCriterion, t) =>
      `${t('analytics.highlights.endorsements.REASON').replace('...', '')}:`,
  },
  [HighlightsInitiatives.ENDORSEMENTS_INTERESTED]: {
    getFilterDisplayName: (filterCriterion, filterName, filterStat, stats) =>
      `${stats.endorsements.interested[filterName]?.name} (${filterStat})`,
    validateFilters: (filterValue) => {
      const { filterCriterion, filtersList } = getFilterValueParts(filterValue);
      return filterCriterion === StudentFiltersTypes.INCLUDE && filtersList.length > 0;
    },
    getFilterOptionsList: (stats) => Object.keys(stats.endorsements.interested),
    getStatValue: (stats, filterName) => stats.endorsements.interested[filterName]?.count,
    getChipsLabel: (filterCriterion, t) =>
      `${t('analytics.highlights.endorsements.INTERESTED').replace('...', '')}:`,
  },
};

export const isSimpleFilterSelected = (
  item,
  targetFilterCategory,
  currentFilterCategory,
  filtersList,
) => currentFilterCategory === targetFilterCategory && filtersList.includes(item);

export const updateSimpleFilter = (newFilter, targetInitiative, filters, optionsList, stats) => {
  let newFilters;
  const { filterCriterion, filtersList } = getFilterValueParts(filters, stats);
  if (filters.filterCategory === targetInitiative) {
    let newFiltersList;
    if (filtersList.includes(newFilter)) {
      newFiltersList = filtersList.filter((filter) => filter !== newFilter);
    } else {
      newFiltersList = [...filtersList, newFilter];
    }
    newFilters = newFiltersList.length
      ? {
          ...filters,
          filterValue: transformToFilterValueString(
            filterCriterion !== StudentFiltersTypes.PENDING
              ? filterCriterion
              : StudentFiltersTypes.INCLUDE,
            newFiltersList,
            optionsList,
          ),
        }
      : {};
  } else {
    newFilters = {
      filterCategory: targetInitiative,
      filterValue: `${StudentFiltersTypes.INCLUDE}:${newFilter}`,
    };
  }
  return newFilters;
};

export const updateRadioFilter = (newFilter, targetInitiative, filters, stats) => {
  let newFilters;
  const { filtersList } = getFilterValueParts(filters, stats);
  if (filters.filterCategory === targetInitiative && filtersList.includes(newFilter)) {
    newFilters = {};
  } else {
    newFilters = {
      filterCategory: targetInitiative,
      filterValue: `${StudentFiltersTypes.INCLUDE}:${newFilter}`,
    };
  }
  return newFilters;
};

export const updateAllOtherFilter = (filters, targetInitiative, interestOptionsList, stats) => {
  let newFilters;
  const { filterCriterion, filtersList } = getFilterValueParts(filters, stats);
  const interestFiltersList = filtersList.filter(
    (filter) => filter !== StudentFiltersSpecialValues.ALL_OTHER,
  );
  if (filters.filterCategory === targetInitiative) {
    if (filterCriterion === StudentFiltersTypes.EXCLUDE && interestFiltersList.length === 0) {
      newFilters = {};
    } else {
      newFilters = {
        ...filters,
        filterValue: transformToFilterValueString(
          filterCriterion === StudentFiltersTypes.EXCLUDE
            ? StudentFiltersTypes.INCLUDE
            : StudentFiltersTypes.EXCLUDE,
          interestFiltersList,
          interestOptionsList,
        ),
      };
    }
  } else {
    newFilters = {
      filterCategory: targetInitiative,
      filterValue: transformToFilterValueString(
        StudentFiltersTypes.EXCLUDE,
        [],
        interestOptionsList,
      ),
    };
  }
  return newFilters;
};

export const updateIncludeFilter = (filters, targetInitiative, stats) => {
  let newFilters;
  const { filterCriterion } = getFilterValueParts(filters, stats);
  if (
    filters.filterCategory === targetInitiative &&
    filterCriterion === StudentFiltersTypes.INCLUDE
  ) {
    newFilters = {};
  } else {
    newFilters = {
      filterCategory: targetInitiative,
      filterValue: StudentFiltersTypes.INCLUDE,
    };
  }
  return newFilters;
};

export const updatePendingFilter = (filters, targetInitiative, stats) => {
  let newFilters;
  const { filterCriterion } = getFilterValueParts(filters, stats);
  if (
    filters.filterCategory === targetInitiative &&
    filterCriterion === StudentFiltersTypes.PENDING
  ) {
    newFilters = {};
  } else {
    newFilters = {
      filterCategory: targetInitiative,
      filterValue: StudentFiltersTypes.PENDING,
    };
  }
  return newFilters;
};

export const isFilterItemDisabled = (statValue, totalStudentsCount) =>
  !statValue || statValue === totalStudentsCount;

export const clearFilter = (filter, filters, stats, filterOptions) => {
  let updatedFilters = { ...filters };
  const { filterCriterion, filtersList } = getFilterValueParts(filters, stats);
  if (filter === StudentFiltersSpecialValues.ALL_OTHER) {
    updatedFilters = updateAllOtherFilter(
      updatedFilters,
      filters.filterCategory,
      filterOptions,
      stats,
    );
  } else if (filter === StudentFiltersSpecialValues.PENDING) {
    updatedFilters = updatePendingFilter(updatedFilters, updatedFilters.filterCategory, stats);
  } else if (filters.filterCategory === HighlightsInitiatives.LEADERSHIP_ATTRIBUTES) {
    updatedFilters = {
      ...updatedFilters,
      filterValue: `${filterCriterion}:${filtersList
        .filter((attribute) => attribute !== filter)
        .join(filtersListSeparator)}`,
    };
  } else {
    updatedFilters = updateSimpleFilter(
      filter,
      updatedFilters.filterCategory,
      updatedFilters,
      filterOptions,
      stats,
    );
  }
  return updatedFilters;
};

export const sanitizeFilters = (filters, stats, totalStudentsCount, history) => {
  if (!stats || !totalStudentsCount) return {};
  let { filterCriterion, filtersList } = getFilterValueParts(filters, stats);
  const CurrentStudentFiltersStrategy = StudentFiltersStrategy[filters.filterCategory];
  let updatedFilters = { ...filters };
  if (filtersList.length === 1 && filtersList[0] === 'ALL') {
    updatedFilters.filterValue = `${filterCriterion}:${CurrentStudentFiltersStrategy.getFilterOptionsList(
      stats,
    ).join(filtersListSeparator)}`;
    const newFilterParts = getFilterValueParts(updatedFilters, stats);
    filterCriterion = newFilterParts.filterCriterion;
    filtersList = newFilterParts.filtersList;
  }
  delete updatedFilters.sanitize;
  const filterOptions = CurrentStudentFiltersStrategy.getFilterOptionsList?.(stats) || [];
  filtersList.forEach((filter) => {
    const statValue = CurrentStudentFiltersStrategy.getStatValue(stats, filter, filterCriterion);
    if (!statValue || statValue === totalStudentsCount) {
      updatedFilters = clearFilter(filter, updatedFilters, stats, filterOptions);
    }
  });
  updatedFilters = CurrentStudentFiltersStrategy.validateFilters(updatedFilters, stats)
    ? updatedFilters
    : {};
  history.replace({ search: new URLSearchParams(updatedFilters).toString() });
  return updatedFilters;
};
