import React, { useCallback, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Divider } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { DotIndicator, Link, PostImage, Typography } from '../../atoms';
import { BadgesRating, EditingButton, TextWithTooltip } from '../../moleculas';
import PostCard, { PostActions, PostBody, PostFooter, PostHeader } from '../post-card/PostCard';
import {
  convertDataToAchievement,
  getFullSchoolName,
  getNormalizedLanguageCode,
  getSharingGroupsLabel,
  mergeArrayOfObjectsHelper,
  sortArrayByKey,
} from '../../../utils';
import { BADGES_STATIC_DATA, BadgesLevelNamesMap } from '../../../constants/badges';
import {
  AvatarTypes,
  FeedPostCategories,
  FeedPostTypes,
  NotificationSourcesMap,
  RolesMap,
} from '../../../constants/enums';
import { useInView, useNotificationsData } from '../../../hooks';
import { ChatActions, ChatContext, UserContext } from '../../../context';
import {
  AppRoutes,
  apiRoute,
  clubHubRoute,
  familyFeedRoute,
  myStudentsAchievementsSection,
  myStudentsInterestsSection,
  myStudentsOpportunitiesSection,
  portfoliosRoute,
} from '../../../constants/routes';
import {
  FeedPostSharingGroups,
  FeedPostSharingGroupsKeys,
  SharingLevels,
} from '../../../constants/family-feed';
import { ReactComponent as LinkIcon } from '../../../resources/icons/link.svg';
import { ReactComponent as SendMessageIcon } from '../../../resources/icons/send_message.svg';
import { attachmentType, conversationType } from '../../../constants/propTypes';

const BadgesIllustration = ({ metadata }) => {
  const badgeInfo = BADGES_STATIC_DATA.find((badge) => badge.type === metadata.type);
  const normalizedMetadataCompletionLevels = metadata.completionLevels.map((level) => ({
    ...level,
    completionDate: level.completionDate ?? new Date().toISOString(),
  }));
  const fullBadgeWithLevels = {
    ...badgeInfo,
    rating: mergeArrayOfObjectsHelper(
      badgeInfo.defaultLevels,
      normalizedMetadataCompletionLevels,
      'levelKey',
    ),
  };

  const { t } = useTranslation();

  return (
    <Box className="family-feed-post__badges">
      <fullBadgeWithLevels.image />
      <Typography py={2} variant="subtitle1">
        {t(fullBadgeWithLevels.nameKey)}
      </Typography>
      <BadgesRating badge={fullBadgeWithLevels} />
    </Box>
  );
};

BadgesIllustration.propTypes = {
  metadata: PropTypes.instanceOf(Object).isRequired,
};

const AchievementIllustration = ({ metadata }) => {
  const { type } = metadata;
  const { t } = useTranslation();
  const achievementImage = convertDataToAchievement({ category: type });

  return achievementImage?.image ? (
    <Box
      aria-label={`${t(`post_image_alt_text.${type}`)} ${t('post image')}`}
      className="family-feed-post__achievements"
      role="img"
    >
      <achievementImage.image />
    </Box>
  ) : null;
};

AchievementIllustration.propTypes = {
  metadata: PropTypes.instanceOf(Object).isRequired,
};

const CustomLink = ({ gaLabel, href, children }) => (
  <Link gaLabel={gaLabel} href={href} target="_blank">
    {children}
  </Link>
);

CustomLink.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string.isRequired,
  gaLabel: PropTypes.string.isRequired,
};

CustomLink.defaultProps = {
  children: null,
};

const ClubsIllustration = ({ metadata, language }) => (
  <PostImage
    defaultImageUrl={`/media/family-feed/${metadata.type}_${language}.svg`}
    postType={metadata.type}
  />
);

ClubsIllustration.propTypes = {
  language: PropTypes.string.isRequired,
  metadata: PropTypes.instanceOf(Object).isRequired,
};

const InterestIllustration = ({ postType }) => (
  <PostImage defaultImageUrl="/media/family-feed/interests.svg" postType={postType} />
);

InterestIllustration.propTypes = {
  postType: PropTypes.string.isRequired,
};

const PortfolioIllustration = ({ postType }) => (
  <PostImage defaultImageUrl="/media/family-feed/portfolio.svg" postType={postType} />
);

PortfolioIllustration.propTypes = {
  postType: PropTypes.string.isRequired,
};
const OpportunitiesIllustration = ({ metadata }) => (
  <PostImage
    defaultImageUrl={`/media/family-feed/${metadata.opportunities[0].type}.svg`}
    postType={metadata.opportunities[0].type}
  />
);

OpportunitiesIllustration.propTypes = {
  metadata: PropTypes.instanceOf(Object).isRequired,
};

const CustomPostIllustrationByType = {
  [FeedPostCategories.BADGES]: BadgesIllustration,
  [FeedPostCategories.CLUBS]: ClubsIllustration,
  [FeedPostCategories.INTERESTS]: InterestIllustration,
  [FeedPostCategories.ACHIEVEMENTS]: AchievementIllustration,
  [FeedPostCategories.PORTFOLIOS]: PortfolioIllustration,
  [FeedPostCategories.OPPORTUNITIES]: OpportunitiesIllustration,
};

const multiLevelBadgePostTranslationKey = 'multi_level_badge_post_description';
const singleLevelBadgePostTranslationKey = 'single_level_badge_post_description';

const CustomPostDescriptionGetters = {
  [FeedPostCategories.BADGES]: (metadata, t) => {
    const { childrenName, type, completionLevels } = metadata;
    const latestCompletedLevel =
      completionLevels.find((level) => !level.completionDate) ??
      sortArrayByKey(completionLevels, 'completionDate', 'date')[0];
    const badgeInfo = BADGES_STATIC_DATA.find((badge) => badge.type === metadata.type);
    const isLARelatedBadge = type.startsWith('LA_');
    const { levelKey: latestCompletedLevelKey } = latestCompletedLevel;
    const latestCompletedLevelLabel =
      BadgesLevelNamesMap[
        isLARelatedBadge ? latestCompletedLevelKey : `${latestCompletedLevelKey}_${type}`
      ];
    const [latestCompletedLevelLabelKey, latestCompletedLevelLabelCount] =
      latestCompletedLevelLabel?.split('/') ?? [];
    const isMultiLevelBadge = badgeInfo.defaultLevels.length > 1;
    const badgePostTranslationKey = isMultiLevelBadge
      ? multiLevelBadgePostTranslationKey
      : singleLevelBadgePostTranslationKey;
    return t(badgePostTranslationKey, {
      childrenName,
      type: badgeInfo.nameKey,
      level: t(latestCompletedLevelLabelKey, { numberOfTimes: latestCompletedLevelLabelCount }),
    });
  },
  [FeedPostCategories.ACHIEVEMENTS]: (metadata) => {
    const { childrenName, type, subType, studentId } = metadata;
    const studentsProfileLink = (
      <CustomLink
        gaLabel="Family feed post achievement link"
        href={`${familyFeedRoute}/${studentId}${myStudentsAchievementsSection}`}
      >
        {childrenName}
      </CustomLink>
    );
    return (
      <Trans
        components={{
          profileLink: studentsProfileLink,
        }}
        i18nKey="feed_post.achievements.description"
        values={{
          achievementsType: type,
          achievementsSubType: subType,
          childrenName,
        }}
      />
    );
  },
  [FeedPostCategories.CLUBS]: (metadata) => {
    const { childrenName, type, entityName, studentId, clubId } = metadata;
    const clubLink = (
      <CustomLink
        gaLabel="Family feed post club link"
        href={`${familyFeedRoute}/${studentId}${AppRoutes.CLUB_HUB}/${clubId}`}
      >
        {childrenName}
      </CustomLink>
    );
    return (
      <Trans
        components={{
          clubLink,
        }}
        i18nKey={`feed_post.clubs.${type}.description`}
        values={{
          childrenName,
          entityName,
        }}
      />
    );
  },
  [FeedPostCategories.INTERESTS]: (metadata) => {
    const { interests, childrenName, studentId } = metadata;
    const interestsList = interests
      .map(
        (interest) =>
          `👉 <interestLink href="${familyFeedRoute}/${studentId}?interestId=${interest.id}${myStudentsInterestsSection}">${interest.name}</interestLink>`,
      )
      .join('\n');

    return (
      <Trans
        components={{
          interestLink: <CustomLink gaLabel="Family feed post interest link" />,
        }}
        i18nKey="feed_post.interests.description"
        values={{ interests: interestsList, childrenName }}
      />
    );
  },
  [FeedPostCategories.OPPORTUNITIES]: (metadata) => {
    const { opportunities, childrenName, studentId } = metadata;
    const opportunityEntity = opportunities.map((el) => el.name).join('", "');
    const nounQuantity = opportunities.length > 1 ? 'plural' : 'single';
    const opportunityType = opportunities[0].type;
    const studentsProfileLink = (
      <CustomLink
        gaLabel="Family feed post opportunity link"
        href={`${familyFeedRoute}/${studentId}${myStudentsOpportunitiesSection}`}
      >
        {childrenName}
      </CustomLink>
    );
    return (
      <Trans
        components={{
          profileLink: studentsProfileLink,
        }}
        i18nKey={`feed_post.opportunities.${nounQuantity}.description.${opportunityType}`}
        values={{
          childrenName,
          itemName: opportunityEntity,
        }}
      />
    );
  },
  [FeedPostCategories.PORTFOLIOS]: (metadata) => {
    const { childrenName, entityId, entityName, studentId } = metadata;
    const studentPortfolioLink = (
      <CustomLink
        gaLabel="Family feed post portfolio link"
        href={`${familyFeedRoute}/${studentId}/${portfoliosRoute}/${entityId}`}
      >
        {entityName}
      </CustomLink>
    );
    return (
      <Trans
        components={{
          portfolioLink: studentPortfolioLink,
        }}
        i18nKey="feed_post.portfolios.description"
        values={{
          childrenName,
          entityName,
        }}
      />
    );
  },
};

const generateCustomPostTitle = (metadata, category) => {
  const { childrenName, entityName, opportunities, type } = metadata;
  if (opportunities) {
    const nounQuantity = opportunities.length > 1 ? 'plural' : 'single';
    return (
      <Trans
        i18nKey={`feed_post.${category.toLowerCase()}.${nounQuantity}.title.${
          opportunities[0].type
        }`}
        values={{ childrenName, entityName }}
      />
    );
  }
  if (category === FeedPostCategories.CLUBS && metadata.type) {
    return (
      <Trans
        i18nKey={`feed_post.${category.toLowerCase()}.${type}.title`}
        values={{ childrenName, entityName }}
      />
    );
  }
  return (
    <Trans
      i18nKey={`feed_post.${category.toLowerCase()}.title`}
      values={{ childrenName, entityName }}
    />
  );
};

const CustomPostTitleGetters = {
  [FeedPostCategories.INTERESTS]: (metadata) =>
    generateCustomPostTitle(metadata, FeedPostCategories.INTERESTS),
  [FeedPostCategories.ACHIEVEMENTS]: (metadata) =>
    generateCustomPostTitle(metadata, FeedPostCategories.ACHIEVEMENTS),
  [FeedPostCategories.PORTFOLIOS]: (metadata) =>
    generateCustomPostTitle(metadata, FeedPostCategories.PORTFOLIOS),
  [FeedPostCategories.OPPORTUNITIES]: (metadata) =>
    generateCustomPostTitle(metadata, FeedPostCategories.OPPORTUNITIES),
  [FeedPostCategories.CLUBS]: (metadata) =>
    generateCustomPostTitle(metadata, FeedPostCategories.CLUBS),
};

const CLUBS_SHARING_LEVEL = 'CLUB';

const getIsEntitiesAvailable = (post, userRole) =>
  !(
    (userRole === RolesMap.STUDENT || userRole === RolesMap.GUARDIAN) &&
    (post.sharingLevel !== SharingLevels.PERSONALIZED.value ||
      post.postType !== FeedPostTypes.CREATED_BY_USER)
  );

const FamilyFeedPost = React.forwardRef(
  ({
    allPostsNotifications,
    deleteDialogHandler,
    studentId,
    editDialogHandler,
    allowConversationSelection,
    post,
  }) => {
    const {
      id: postId,
      author,
      category,
      title,
      link,
      defaultImageUrl,
      description,
      selectedEntities,
      sharingGroups,
      createdDate,
      status,
      metadata,
      postType: type,
      conversations: propConversations,
      sharingLevel,
    } = post;
    const imageInfo = post?.attachments;
    const { t, i18n } = useTranslation();
    const { state: userState } = useContext(UserContext);
    const showSelectedEntities =
      post.sharingLevel === CLUBS_SHARING_LEVEL ||
      getIsEntitiesAvailable(post, userState.profile.role);

    const language = getNormalizedLanguageCode(i18n.language);
    const isNotCreatedByUser = type !== FeedPostTypes.CREATED_BY_USER;

    const CustomPostIllustration = isNotCreatedByUser && CustomPostIllustrationByType[category];

    const customPostDescription =
      isNotCreatedByUser && CustomPostDescriptionGetters[category]?.(metadata, t);

    const isAuthor = useMemo(
      () => userState.profile.id === author.id,
      [author, userState.profile.id],
    );

    const { role: userRole } = userState.profile;

    const postAuthorFullName = getFullSchoolName(author.name);
    const customPostTitle = isNotCreatedByUser && CustomPostTitleGetters[category]?.(metadata);

    const postNotifications = allPostsNotifications?.filter(
      (notification) => notification.resourceId === postId,
    );

    const { updateNotificationItems } = useNotificationsData();

    const markPostAsRead = useCallback(() => {
      if (!postNotifications?.length) return;
      updateNotificationItems(
        postNotifications.map((notification) => ({ ...notification, markedAsRead: true })),
        NotificationSourcesMap.WEB_HIDDEN,
      );
    }, [updateNotificationItems, postNotifications]);

    const hasUnreadNotification = postNotifications?.some(
      (notification) => !notification.markedAsRead,
    );

    const clubRoutesByRole = useMemo(
      () => ({
        [RolesMap.GUARDIAN]: `${familyFeedRoute}/${studentId}${clubHubRoute}/${metadata?.clubId}`,
        [RolesMap.TEACHER]: `${clubHubRoute}/${metadata?.clubId}`,
        [RolesMap.ADMINISTRATOR]: `${clubHubRoute}/${metadata?.clubId}`,
        [RolesMap.STUDENT]: `${clubHubRoute}/${metadata?.clubId}`,
      }),
      [metadata, studentId],
    );

    const sourceMetaData = useMemo(() => {
      if (category === FeedPostCategories.CLUBS && metadata.clubName) {
        const sourceLink = (
          <Link
            component={RouterLink}
            gaLabel="Club link"
            icon={<LinkIcon />}
            target="_blank"
            to={clubRoutesByRole[userRole]}
            underline="none"
          >
            <TextWithTooltip
              className="ayo-link"
              title={
                <Typography className="ayo-club-link" variant="subtitle2">
                  {metadata.clubName}
                </Typography>
              }
            />
          </Link>
        );
        const sourceImage = `${apiRoute}/attachments/${metadata.clubImage?.id}/owners/${metadata?.clubImage?.ownerId}`;
        const sourceAlt = t('Club image', { clubName: metadata.clubName });
        return { sourceLink, sourceImage, sourceAlt };
      }
      return null;
    }, [category, metadata, clubRoutesByRole, t, userRole]);

    const { ref } = useInView(markPostAsRead, 500, !hasUnreadNotification);

    const { state: chatState, dispatch: dispatchChatState } = useContext(ChatContext);

    const [newConversations, setNewConversations] = useState([]);
    const conversations = useMemo(
      () => [...newConversations, ...(propConversations ?? [])],
      [newConversations, propConversations],
    );

    const getConversationDefaultMetadata = () => {
      if (
        (sharingLevel === SharingLevels.CLASS.value ||
          sharingLevel === SharingLevels.PERSONALIZED.value) &&
        type === FeedPostTypes.CREATED_BY_USER &&
        userRole === RolesMap.GUARDIAN
      ) {
        return {
          members: [author],
          resourceType: 'FEED_POST',
          resourceId: postId,
          resourceName: title,
        };
      }
      if (
        sharingLevel === SharingLevels.CAMPUS.value &&
        sharingGroups.includes(FeedPostSharingGroupsKeys.STAFF)
      ) {
        return {
          resourceType: 'FEED_POST',
          resourceId: postId,
          resourceName: title,
          conversationName: selectedEntities.map(({ name }) => name).join(', '),
          conversationDescription: author.type,
          conversationImage: author.profileImage,
        };
      }
      return null;
    };
    const defaultConversationMetadata = getConversationDefaultMetadata();
    const allowConversationStart = !!defaultConversationMetadata;
    const openChat = useCallback(() => {
      if (allowConversationSelection && !allowConversationStart) {
        dispatchChatState({ type: ChatActions.SET_AVAILABLE_CONVERSATIONS, data: conversations });
      } else {
        dispatchChatState({
          type: ChatActions.SET_ACTIVE_CONVERSATION_METADATA,
          data: conversations?.[0] ?? defaultConversationMetadata,
        });
        dispatchChatState({
          type: ChatActions.ADD_PUSH_NEW_CONVERSATION,
          data: (newConversation) =>
            setNewConversations((prevValue) => [...prevValue, newConversation]),
        });
      }
      dispatchChatState({ type: ChatActions.SET_IS_CHAT_DRAWER_OPENED, data: true });
    }, [
      allowConversationSelection,
      allowConversationStart,
      dispatchChatState,
      conversations,
      defaultConversationMetadata,
    ]);

    const hasUnreadConversations = !!conversations?.some((conversation) => {
      const updatedConversation = chatState.conversationsCache.find(
        (cacheConversation) => cacheConversation.id === conversation.id,
      );
      if (updatedConversation) {
        return !updatedConversation.lastMessage.isRead;
      }
      return !conversation.lastMessage.isRead;
    });

    return (
      <PostCard cardRef={ref} postId={postId}>
        <PostHeader
          alt={t('author avatar image alt', { authorName: postAuthorFullName })}
          author={author}
          availableActions={
            isAuthor && category !== FeedPostCategories.CLUBS ? Object.values(PostActions) : []
          }
          createdDate={createdDate}
          deleteDialogHandler={deleteDialogHandler}
          editDialogHandler={editDialogHandler}
          isAuthorClickable={author?.type !== AvatarTypes.AYO}
          postId={postId}
          sourceMetaData={sourceMetaData}
          status={status}
          withIndicator={hasUnreadNotification}
        />
        <Box my={2}>
          <Divider flexItem />
        </Box>
        <PostBody
          category={category}
          customIllustration={
            CustomPostIllustration && (
              <CustomPostIllustration language={language} metadata={metadata} postType={type} />
            )
          }
          defaultImageUrl={defaultImageUrl}
          description={customPostDescription || description}
          imageInfo={imageInfo}
          link={link}
          showFullDescription={type !== FeedPostTypes.CREATED_BY_USER}
          title={customPostTitle || title}
          type={type}
        />
        <PostFooter
          selectedEntities={selectedEntities}
          sharingGroupsLabel={getSharingGroupsLabel(
            sharingGroups,
            FeedPostSharingGroups,
            category === FeedPostCategories.CLUBS ? 'clubLabel' : 'label',
          )}
          showSelectedEntities={showSelectedEntities}
        >
          <Box display="flex" gap={2}>
            {allowConversationStart && !isAuthor && (
              <EditingButton
                gaLabel="Message the author"
                icon={<SendMessageIcon />}
                iconPosition="end"
                onClick={openChat}
                text={t('Message the author')}
              />
            )}
            {(!!conversations?.length || (allowConversationStart && isAuthor)) && (
              <DotIndicator invisible={!hasUnreadConversations} overlap="circular">
                <EditingButton
                  className="family-feed-post__conversations-opener"
                  gaLabel="Open conversations"
                  icon={<SendMessageIcon />}
                  iconPosition="end"
                  onClick={openChat}
                  text={conversations.length || null}
                />
              </DotIndicator>
            )}
          </Box>
        </PostFooter>
      </PostCard>
    );
  },
);

FamilyFeedPost.propTypes = {
  post: PropTypes.shape({
    id: PropTypes.string,
    author: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      type: PropTypes.string,
      avatar: PropTypes.instanceOf(Object),
      profileImage: PropTypes.string,
    }),
    attachments: PropTypes.arrayOf(PropTypes.shape(attachmentType)),
    category: PropTypes.string,
    title: PropTypes.string.isRequired,
    link: PropTypes.string,
    defaultImageUrl: PropTypes.string,
    description: PropTypes.string.isRequired,
    selectedEntities: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        position: PropTypes.string,
        profileImage: PropTypes.string,
      }),
    ),
    sharingGroups: PropTypes.arrayOf(PropTypes.string),
    createdDate: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    metadata: PropTypes.instanceOf(Object),
    postType: PropTypes.string,
    conversations: PropTypes.arrayOf(PropTypes.shape(conversationType)),
    sharingLevel: PropTypes.string.isRequired,
  }).isRequired,
  allPostsNotifications: PropTypes.arrayOf(
    PropTypes.shape({
      resourceId: PropTypes.number,
    }),
  ),
  studentId: PropTypes.number,
  editDialogHandler: PropTypes.func,
  deleteDialogHandler: PropTypes.func,
  allowConversationSelection: PropTypes.bool,
};

FamilyFeedPost.defaultProps = {
  editDialogHandler: null,
  studentId: null,
  deleteDialogHandler: null,
  allPostsNotifications: [],
  allowConversationSelection: false,
};

export default FamilyFeedPost;
