import React, { useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Typography } from '../../../../atoms';
import { DeleteItemWrapper, InitiativeEmptyStateBlock } from '../../../../moleculas';
import { PortfolioEntitiesData, PortfolioItemsToAddData } from '../../../../../utils';
import { portfolioSectionConfig } from '../../../../../constants/propTypes';
import { PortfolioSectionTypes } from '../../../../../constants/enums';
import { trainAyoInterestsAppRoute } from '../../../../../constants/routes';
import { PortfolioInterestsIllustrations } from '../../../../../constants/portfolio-interests';
import { ReactComponent as InterestsMapEmptyStateIllustration } from '../../../../../resources/images/interests_map_with_wave.svg';
import PortfolioSection from '../../components/portfolio-section/PortfolioSection';

const PortfolioInterestsSection = ({ config, sectionIndex, isLastSection }) => {
  const { t } = useTranslation();
  const [portfolioInterests, setPortfolioInterests] = useState([]);
  const {
    addPortfolioItemHandler,
    deletePortfolioItemHandler,
    hasData,
    isEditMode,
    isVisible,
    metaData,
    reflectionHandler,
    sectionData,
    onUpdatePositionHandler,
  } = config;
  const { entities, reflection } = sectionData;

  const interestsToAdd = useMemo(
    () =>
      PortfolioItemsToAddData[PortfolioSectionTypes.PORTFOLIO__INTERESTS](
        entities,
        metaData?.selectedInterests,
        'id',
      ).map(({ id, name }) => ({
        id: id.toString(),
        name,
      })),
    [entities, metaData?.selectedInterests],
  );

  useEffect(() => {
    setPortfolioInterests(
      PortfolioEntitiesData[PortfolioSectionTypes.PORTFOLIO__INTERESTS](
        entities,
        metaData?.selectedInterests,
        'id',
      ),
    );
  }, [entities, metaData?.selectedInterests]);

  if (!isVisible) {
    return null;
  }

  return (
    <PortfolioSection
      body={
        <Box className="ayo-portfolio-interests-section__container" mt={3}>
          {portfolioInterests?.map((interest, index) => {
            const illustration = PortfolioInterestsIllustrations[interest.parentNameKey];
            return (
              <Box key={interest.id} pt={index % 2 ? 4 : 0}>
                <DeleteItemWrapper
                  disabled={!isEditMode}
                  gaLabel="Remove interest"
                  mainContent={
                    <Box
                      alignItems="center"
                      className="ayo-portfolio-interests-section__card"
                      display="flex"
                      flexDirection="column"
                      p={1}
                    >
                      {illustration && (
                        <Box mb={1}>
                          <illustration.image aria-label={t(illustration.alt)} />
                        </Box>
                      )}
                      <Typography align="center" component="h3" isLightText variant="body2">
                        {interest.name}
                      </Typography>
                    </Box>
                  }
                  onDelete={() => {
                    deletePortfolioItemHandler(portfolioInterests, 'id', interest.id, sectionData);
                  }}
                />
              </Box>
            );
          })}
        </Box>
      }
      className={`${classNames({
        'ayo-portfolio-interests-section': hasData,
      })}`}
      emptyState={
        !hasData ? (
          <InitiativeEmptyStateBlock
            body={isEditMode ? t('It should be fun!') : ''}
            buttonContent={t('Explore in Train AYO')}
            buttonGaLabel="Explore in Train AYO - Interests"
            buttonLink={trainAyoInterestsAppRoute}
            illustration={<InterestsMapEmptyStateIllustration />}
            showNavigationButton={isEditMode}
            title={isEditMode ? t('Let’s explore your interests') : t('No shared interests so far')}
          />
        ) : null
      }
      isEditMode={isEditMode}
      isLastSection={isLastSection}
      itemsToAdd={interestsToAdd}
      onAddItems={(itemsToAdd) =>
        addPortfolioItemHandler(metaData?.selectedInterests, itemsToAdd, 'id', sectionData)
      }
      onChangeReflection={reflectionHandler}
      onUpdatePositionHandler={onUpdatePositionHandler}
      reflection={reflection}
      sectionIndex={sectionIndex}
      sectionKey={PortfolioSectionTypes.PORTFOLIO__INTERESTS}
      showActionsMenu={hasData && isEditMode}
      showAddButton={!!interestsToAdd?.length && isEditMode}
      theme={hasData ? 'dark' : 'light'}
      title="Interests"
    />
  );
};

PortfolioInterestsSection.propTypes = {
  config: portfolioSectionConfig(PropTypes.instanceOf(Object)),
  sectionIndex: PropTypes.number.isRequired,
  isLastSection: PropTypes.number.isRequired,
};

PortfolioInterestsSection.defaultProps = {
  config: null,
};

export default PortfolioInterestsSection;
