import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { LinearProgress } from '../../../atoms';
import AptitudeCardBase from '../aptitude-card-base/AptitudeCardBase';
import { Aptitudes, AptitudesInfo } from '../../../../constants/enums';
import { ReactComponent as ArtisticIllustration } from '../../../../resources/images/aptitudes/artistic_mono.svg';
import { ReactComponent as LanguageIllustration } from '../../../../resources/images/aptitudes/language_mono.svg';
import { ReactComponent as MechanicalIllustration } from '../../../../resources/images/aptitudes/mechanical_mono.svg';
import { ReactComponent as NumericalIllustration } from '../../../../resources/images/aptitudes/numerical_mono.svg';
import { ReactComponent as OrganizationalIllustration } from '../../../../resources/images/aptitudes/organizational_mono.svg';
import { ReactComponent as PerceptualIllustration } from '../../../../resources/images/aptitudes/perceptual_mono.svg';
import { ReactComponent as PhysicalIllustration } from '../../../../resources/images/aptitudes/physical_mono.svg';
import { ReactComponent as SpatiallIllustration } from '../../../../resources/images/aptitudes/spatial_mono.svg';
import { ReactComponent as VerbalIllustration } from '../../../../resources/images/aptitudes/verbal_mono.svg';
import { ReactComponent as AbstractReasoningIllustration } from '../../../../resources/images/aptitudes/abstract_reasoning_mono.svg';

const AptitudesMonocolorIllustrations = {
  [Aptitudes.ARTISTIC]: ArtisticIllustration,
  [Aptitudes.LANGUAGE]: LanguageIllustration,
  [Aptitudes.MECHANICAL]: MechanicalIllustration,
  [Aptitudes.NUMERICAL]: NumericalIllustration,
  [Aptitudes.ORGANIZATIONAL]: OrganizationalIllustration,
  [Aptitudes.PERCEPTUAL]: PerceptualIllustration,
  [Aptitudes.PHYSICAL]: PhysicalIllustration,
  [Aptitudes.SPATIAL]: SpatiallIllustration,
  [Aptitudes.VERBAL]: VerbalIllustration,
  [Aptitudes.ABSTRACT_REASONING]: AbstractReasoningIllustration,
};

const AptitudeProgressCard = ({ aptitude, score }) => {
  const { t } = useTranslation();
  return (
    <AptitudeCardBase
      className="ayo-aptitude-progress-card"
      description={t(AptitudesInfo[aptitude].description)}
      footer={<LinearProgress value={score * 100} />}
      IllustrationComponent={AptitudesMonocolorIllustrations[aptitude]}
      name={t(AptitudesInfo[aptitude].name)}
    />
  );
};

AptitudeProgressCard.propTypes = {
  aptitude: PropTypes.oneOf(Object.values(Aptitudes)).isRequired,
  score: PropTypes.number.isRequired,
};

export default AptitudeProgressCard;
