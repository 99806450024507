import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { AptitudesColorIllustrations } from '../../../constants/aptitudes';
import { formatObjKeysToWords } from '../../../utils';
import { ReactComponent as CheckCircle } from '../../../resources/icons/check_circle.svg';
import EditableChip from '../chips/editable-chip/EditableChip';

const StudentAptitudeChip = ({ aptitude, ariaLabel, isSelected, label, onClick }) => {
  const { t } = useTranslation();

  const aptitudeLabel = useMemo(
    () => label || t(formatObjKeysToWords(aptitude)),
    [aptitude, label, t],
  );

  const AptitudeIcon = AptitudesColorIllustrations[aptitude].illustration;
  const aptitudeIconAriaLabel = useMemo(
    () => ariaLabel || t(AptitudesColorIllustrations[aptitude].alt),
    [aptitude, ariaLabel, t],
  );

  return (
    <Box
      className="ayo-aptitude-chip-container"
      display="inline-block"
      maxWidth="100%"
      pb={1}
      pr={1}
    >
      <EditableChip
        ariaLabel={ariaLabel || aptitudeLabel}
        className={classNames('ayo-aptitude-chip ayo-clickable-chip', {
          'ayo-clickable-chip--selected': isSelected,
        })}
        icon={
          isSelected ? (
            <CheckCircle />
          ) : (
            <AptitudeIcon
              aria-label={aptitudeIconAriaLabel}
              className="ayo-aptitude-chip__icon"
              height="24px"
              width="24px"
            />
          )
        }
        label={aptitudeLabel}
        onClick={onClick}
      />
    </Box>
  );
};

StudentAptitudeChip.propTypes = {
  ariaLabel: PropTypes.string,
  aptitude: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func,
};

StudentAptitudeChip.defaultProps = {
  ariaLabel: '',
  isSelected: false,
  label: '',
  onClick: null,
};

export default StudentAptitudeChip;
