import React from 'react';
import { Grid, Box, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Typography, Button } from '../../atoms';

const InformationalMessage = ({ icon, text, onClick }) => {
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const { t } = useTranslation();
  return (
    <Grid item xs={12}>
      <Box className="ayo-informational-message" display="flex">
        <Box alignItems="center" className="ayo-informational-message__description" display="flex">
          {isWidthUpSm && icon && (
            <Box alignItems="center" display="flex" mr={1}>
              {icon}
            </Box>
          )}
          <Box mr={2}>
            <Typography variant="body2">{text}</Typography>
          </Box>
          <Button
            className="ayo-informational-message__description__button"
            gaLabel="Got it"
            onClick={onClick}
            variant="text"
          >
            {t('Got it')}
          </Button>
        </Box>
      </Box>
    </Grid>
  );
};

InformationalMessage.propTypes = {
  icon: PropTypes.node,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

InformationalMessage.defaultProps = {
  icon: null,
  onClick: () => {},
};

export default InformationalMessage;
