import React from 'react';
import PropTypes from 'prop-types';

import { ClubsManagementQueryParams, ClubsStatuses } from '../../../../../../constants/club-hub';
import ClubFilters from '../../../club-hub-components/club-filters/ClubFilters';

const ClubsFiltersOptions = {
  [ClubsStatuses.ALL]: {
    status: ClubsStatuses.ALL,
  },
  [ClubsStatuses.PENDING]: {
    status: ClubsStatuses.PENDING,
    tooltipLabel: 'Clubs’ requests that are awaiting your approval or decline.',
  },
  [ClubsStatuses.ACTIVE]: {
    status: ClubsStatuses.ACTIVE,
    tooltipLabel: 'Up and running clubs. Students can see them and be active.',
  },
  [ClubsStatuses.INACTIVE]: {
    status: ClubsStatuses.INACTIVE,
    tooltipLabel:
      'Clubs that you made inactive. These clubs aren’t available to students anymore. Inactive clubs will remain in the moderation table permanently.',
  },
  [ClubsStatuses.DECLINED]: {
    status: ClubsStatuses.DECLINED,
    tooltipLabel:
      'Clubs’ requests that weren’t accepted by you. Declined clubs can be stored in the table for a month, afterward, will be deleted forever.',
  },
};

const ClubsFilters = ({ onFiltersChange, status }) => (
  <ClubFilters
    filtersOptions={ClubsFiltersOptions}
    onFiltersChange={(value) =>
      onFiltersChange({
        [ClubsManagementQueryParams.STATUS]: value,
        [ClubsManagementQueryParams.PAGE]: 1,
      })
    }
    status={status}
  />
);

ClubsFilters.propTypes = {
  onFiltersChange: PropTypes.func.isRequired,
  status: PropTypes.string,
};

ClubsFilters.defaultProps = {
  status: '',
};

export default ClubsFilters;
