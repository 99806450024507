import React, { useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { TourContext, TourActions, UserContext } from '../../../context';
import { Hotspot } from '../../atoms';
import { GaActions, GaCategories } from '../../../constants/enums';
import { GA } from '../../../utils';
import NewFeaturesItemsProvider from '../../../tours/common/NewFeaturesItemsProvider';

export const NewFeatureHotspot = ({ className, dark, id, isClickable, label }) => {
  const { state: tourState, dispatch: dispatchTourState } = useContext(TourContext);

  const openNewFeaturesTour = useCallback(
    (e) => {
      e.stopPropagation();
      dispatchTourState({
        type: TourActions.SET_NEW_FEATURES_TOUR_STATE,
        data: { isNewFeaturesTourOpened: true, newFeaturesFocusId: id },
      });
      GA.logInteraction({
        category: GaCategories.BEHAVIOR,
        action: GaActions.NEW_FEATURES_HOTSPOT_CLICK,
        label,
      });
    },
    [dispatchTourState, id, label],
  );

  const { t } = useTranslation();
  const { state: userState } = useContext(UserContext);

  const NewFeatureTourItems = NewFeaturesItemsProvider();

  return (NewFeatureTourItems.find((item) => item?.id === id)?.showHotspot || !isClickable) &&
    !tourState.newFeaturesSeen.some((item) => item === id) &&
    userState.profile.policyAccepted ? (
    <Hotspot
      className={className}
      dark={dark}
      label={isClickable ? t('Open Whats new for feature', { feature: label }) : t('Not read icon')}
      onClick={isClickable ? openNewFeaturesTour : undefined}
      withAnimation={isClickable}
    />
  ) : null;
};

NewFeatureHotspot.propTypes = {
  className: PropTypes.string,
  dark: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  label: PropTypes.string,
  isClickable: PropTypes.bool,
};

NewFeatureHotspot.defaultProps = {
  className: '',
  dark: false,
  label: '',
  isClickable: false,
};

export default NewFeatureHotspot;
