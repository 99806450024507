import React, { useEffect, useMemo, useState, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Grid, Box } from '@mui/material';
import { Skeleton } from '@mui/lab';

import Tooltip from '../../atoms/tooltip/Tooltip';
import Link from '../../atoms/link/Link';
import Typography from '../../atoms/typography/Typography';
import { GA } from '../../../utils';
import { GaCategories, GaActions, STTErrorsMap } from '../../../constants/enums';
import { ReactComponent as ActiveMicrophoneIcon } from '../../../resources/icons/microphone_active.svg';
import { ReactComponent as MicrophoneIcon } from '../../../resources/icons/microphone.svg';
import { microphoneSettingsHelpRoute } from '../../../constants/routes';

const MicrophoneSettingLink = () => {
  const { t, i18n } = useTranslation();
  return (
    <Link
      className="ayo-stt-helper__settings-link"
      gaLabel="STT Help"
      href={microphoneSettingsHelpRoute(i18n)}
      isContained
      target="_blank"
    >
      {t('here')}
    </Link>
  );
};

const ErrorMainContent = ({ description, showLink }) => {
  const { t } = useTranslation();
  return (
    <Typography align="center" variant="body2">
      {t(description)}
      {showLink ? <MicrophoneSettingLink /> : null}.
    </Typography>
  );
};

ErrorMainContent.propTypes = {
  description: PropTypes.string.isRequired,
  showLink: PropTypes.bool,
};

ErrorMainContent.defaultProps = {
  showLink: false,
};

const errorMessages = {
  [STTErrorsMap.SERVER_ERROR]: {
    header: 'Something went not as planned',
    mainContent: <ErrorMainContent description="Please refresh the page or come back later" />,
  },
  [STTErrorsMap.NOT_ALLOWED_ERROR]: {
    header: 'No speech detected',
    mainContent: (
      <ErrorMainContent description="AYO needs your permission to use the microphone" showLink />
    ),
  },
  [STTErrorsMap.NOT_FOUND_ERROR]: {
    header: 'No speech detected',
    mainContent: <ErrorMainContent description="Help for microphone settings is" showLink />,
  },
  [STTErrorsMap.NOT_READABLE_ERROR]: {
    header: 'No speech detected',
    mainContent: (
      <ErrorMainContent description="Please close all other applications or browser tabs that may be using your microphone and try again" />
    ),
  },
};

const STTStates = {
  CONNECTING: 'CONNECTING',
  START: 'START',
  TALKING: 'TALKING',
  NO_SPEECH_DETECTED: 'NO_SPEECH_DETECTED',
  NO_TEXT_RECOGNIZED: 'NO_TEXT_RECOGNIZED',
};

const FIRST_CIRCLE_MAX_RADIUS = 20;
const FIRST_CIRCLE_MIN_RADIUS = 10;
const SECOND_CIRCLE_MAX_RADIUS = 28;
const SECOND_CIRCLE_MIN_RADIUS = 14;

const UINT8MAX = 256;

const VOICE_SET_MINIMUM_RELATIVE_VALUE = 20;

const STTTooltip = ({
  analyzer,
  children,
  gaLabel,
  hasRecognizedText,
  isConnected,
  isRecording,
  isRecordingActivated,
  resetText,
  startRecording,
  stopAndClose,
  error,
}) => {
  const { t } = useTranslation();

  const [isAnimating, setIsAnimating] = useState(false);

  const drawRef = useRef();

  const hasSpoken = useRef(false);

  const [speakState, setSpeakState] = useState(false);

  const setHasSpokenWithDependency = useCallback((value) => {
    setSpeakState(value);
    hasSpoken.current = value;
  }, []);

  const visualize = useCallback(
    (analyzerNode) => {
      // eslint-disable-next-line no-param-reassign
      const bufferLength = analyzerNode.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);

      const draw = () => {
        drawRef.current = requestAnimationFrame(draw);
        analyzerNode.getByteFrequencyData(dataArray);
        const arraySum = dataArray.reduce((sum, val) => sum + val, 0);
        const medium = arraySum / bufferLength;
        if (!hasSpoken.current && medium > VOICE_SET_MINIMUM_RELATIVE_VALUE) {
          setHasSpokenWithDependency(true);
        }
        const relativeValue = medium / UINT8MAX;
        const firstCircle = document.querySelector('.voice-animation .outer-1');
        const secondCircle = document.querySelector('.voice-animation .outer-2');
        if (!firstCircle) return;
        firstCircle.style.r = `${
          FIRST_CIRCLE_MIN_RADIUS +
          (FIRST_CIRCLE_MAX_RADIUS - FIRST_CIRCLE_MIN_RADIUS) * relativeValue
        }px`;
        secondCircle.style.r = `${
          SECOND_CIRCLE_MIN_RADIUS +
          (SECOND_CIRCLE_MAX_RADIUS - SECOND_CIRCLE_MIN_RADIUS) * relativeValue
        }px`;
      };

      draw();
      drawRef.current = draw;
      setIsAnimating(true);
    },
    [setHasSpokenWithDependency],
  );

  useEffect(
    () => () => {
      if (drawRef.current) {
        cancelAnimationFrame(drawRef.current);
      }
    },
    [],
  );

  useEffect(() => {
    if (analyzer && !isAnimating) {
      visualize(analyzer);
      setIsAnimating(true);
    }
    if (!analyzer && isAnimating) {
      cancelAnimationFrame(drawRef.current);
      setIsAnimating(false);
    }
  }, [analyzer, isAnimating, visualize]);

  const [currentSTTState, setCurrentSTTState] = useState(STTStates.CONNECTING);

  const [isOpen, setIsOpen] = useState(false);

  const resetAndStart = useCallback(() => {
    setHasSpokenWithDependency(false);
    setCurrentSTTState(STTStates.CONNECTING);
    startRecording();
  }, [setHasSpokenWithDependency, startRecording]);

  const getErrorMessage = useCallback(
    () => errorMessages[error] || errorMessages[STTErrorsMap.NOT_FOUND_ERROR],
    [error],
  );

  const STTStatesContent = useMemo(
    () => ({
      [STTStates.START]: {
        header: t('Please speak'),
        helperText: t('click when done'),
        onIconClick: stopAndClose,
        Icon: MicrophoneIcon,
      },
      [STTStates.TALKING]: {
        header: t('Listening'),
        helperText: t('click when done'),
        onIconClick: stopAndClose,
        Icon: ActiveMicrophoneIcon,
      },
      [STTStates.NO_TEXT_RECOGNIZED]: {
        header: t('Didn’t get what you said'),
        helperText: t('click to try again'),
        onIconClick: resetAndStart,
        Icon: MicrophoneIcon,
      },
      [STTStates.NO_SPEECH_DETECTED]: {
        header: t(getErrorMessage().header || ''),
        mainContent: getErrorMessage().mainContent || '',
        helperText: t('click to try again'),
        onIconClick: resetAndStart,
        Icon: MicrophoneIcon,
      },
    }),
    [getErrorMessage, resetAndStart, stopAndClose, t],
  );

  useEffect(() => {
    if (isRecordingActivated && !isOpen) {
      setIsOpen(true);
    }
    if (!isRecordingActivated && isOpen) {
      setIsOpen(false);
      setHasSpokenWithDependency(false);
      setCurrentSTTState(STTStates.CONNECTING);
      return;
    }
    if (isRecordingActivated && isConnected && currentSTTState === STTStates.CONNECTING) {
      setCurrentSTTState(STTStates.START);
    }

    if (currentSTTState === STTStates.START && hasSpoken.current) {
      setCurrentSTTState(STTStates.TALKING);
    }
    if (!isRecording && isOpen) {
      if (!hasRecognizedText) {
        if (hasSpoken.current) {
          if (currentSTTState === STTStates.NO_TEXT_RECOGNIZED) return;
          setCurrentSTTState(STTStates.NO_TEXT_RECOGNIZED);
        } else {
          if (currentSTTState === STTStates.NO_SPEECH_DETECTED) return;
          setCurrentSTTState(STTStates.NO_SPEECH_DETECTED);
        }
      } else {
        setHasSpokenWithDependency(false);
        setCurrentSTTState(STTStates.CONNECTING);
      }
    }
  }, [
    currentSTTState,
    hasRecognizedText,
    isConnected,
    isOpen,
    isRecording,
    isRecordingActivated,
    setHasSpokenWithDependency,
    startRecording,
    speakState,
  ]);

  const onClickAway = useCallback(() => {
    if (!isRecordingActivated) return;
    stopAndClose();
    resetText();
  }, [isRecordingActivated, resetText, stopAndClose]);

  const CurrentIcon = STTStatesContent[currentSTTState]?.Icon;

  useEffect(() => {
    if (
      gaLabel &&
      currentSTTState !== STTStates.CONNECTING &&
      currentSTTState !== STTStates.START
    ) {
      GA.logInteraction({
        category: GaCategories.BEHAVIOR,
        action: `${GaActions.STT} ${currentSTTState}`,
        label: gaLabel,
      });
    }
  }, [currentSTTState, gaLabel]);

  return (
    <Box className="ayo-stt-wrapper" position="relative">
      <Tooltip
        className="ayo-stt-helper"
        onClickAway={onClickAway}
        open={isOpen}
        PopperProps={{
          disablePortal: true,
          style: {
            width: '100%',
          },
        }}
        title={
          <Grid container justifyContent="center">
            <Grid alignItems="center" container direction="column" item xs={8}>
              <Box aria-live={currentSTTState !== STTStates.TALKING ? 'assertive' : 'off'}>
                <Box mb={1} width="100%">
                  <Typography align="center" variant="subtitle2">
                    {currentSTTState === STTStates.CONNECTING ? (
                      <Skeleton />
                    ) : (
                      STTStatesContent[currentSTTState].header
                    )}
                  </Typography>
                </Box>
                <Box mb={5} width="100%">
                  {STTStatesContent[currentSTTState]?.mainContent}
                </Box>
              </Box>
              {currentSTTState === STTStates.CONNECTING ? (
                <Skeleton height={56} variant="rect" width={56} />
              ) : (
                <CurrentIcon
                  aria-label={STTStatesContent[currentSTTState].helperText}
                  className={classnames({
                    'voice-animation': currentSTTState === STTStates.TALKING,
                  })}
                  onClick={STTStatesContent[currentSTTState].onIconClick}
                  role="button"
                  tabindex="0"
                />
              )}
              <Box width="100%">
                <Typography align="center" isLabel variant="caption">
                  {currentSTTState === STTStates.CONNECTING ? (
                    <Skeleton />
                  ) : (
                    STTStatesContent[currentSTTState].helperText
                  )}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        }
        variant="light"
      >
        {children}
      </Tooltip>
    </Box>
  );
};

STTTooltip.propTypes = {
  analyzer: PropTypes.instanceOf(Object),
  children: PropTypes.node.isRequired,
  gaLabel: PropTypes.string,
  hasRecognizedText: PropTypes.bool.isRequired,
  isConnected: PropTypes.bool.isRequired,
  isRecording: PropTypes.bool.isRequired,
  isRecordingActivated: PropTypes.bool.isRequired,
  resetText: PropTypes.func.isRequired,
  startRecording: PropTypes.func.isRequired,
  stopAndClose: PropTypes.func.isRequired,
  error: PropTypes.string,
};

STTTooltip.defaultProps = {
  analyzer: null,
  gaLabel: null,
  error: '',
};

export default STTTooltip;
