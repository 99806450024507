import React, { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { Button, SkeletonMask, Typography } from '../../atoms';
import {
  Carousel,
  InitiativeEmptyStateBlock,
  LearnMoreProfileBlock,
  NewFeatureHotspot,
} from '../../moleculas';
import OpportunityCard from '../opportunity-card/OpportunityCard';
import { UserContext } from '../../../context';
import { useStudentsService } from '../../../services';
import { getNormalizedLanguageCode, sortArrayByKey } from '../../../utils';
import { opportunitiesRoute, trainAyoOpportunitiesRoute } from '../../../constants/routes';
import { RolesMap } from '../../../constants/enums';
import { NewFeaturesIdsMap } from '../../../tours/common/NewFeaturesItemsProvider';
import { useLazyDataLoad } from '../../../hooks';
import { ReactComponent as VectorIcon } from '../../../resources/icons/vector.svg';
import { ReactComponent as OpportunitiesEmptyIllustration } from '../../../resources/images/opportunities_empty_state.svg';
import { ReactComponent as ChevronIcon } from '../../../resources/icons/chevron_left.svg';

const DedicatedPageNewFeatureHotspot = () => {
  const { t } = useTranslation();
  return (
    <NewFeatureHotspot
      id={NewFeaturesIdsMap.OPPORTUNITIES_DEDICATED_PAGE}
      isClickable
      label={t('Opportunities - Dedicated page')}
    />
  );
};

const OpportunitiesBlock = ({ headerLevel, isLazyLoad, studentId, title, titleVariant }) => {
  const { t, i18n } = useTranslation();
  const { state: userState } = useContext(UserContext);
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const isStudent = userState.profile.role === RolesMap.STUDENT;
  const currentStudentId = isStudent ? userState.profile.id : studentId;

  const [selectedOpportunities, setSelectedOpportunities] = useState(null);
  const { getStudentOpportunities } = useStudentsService();

  const loadStudentOpportunities = useCallback(
    (onLoad) => {
      getStudentOpportunities(
        currentStudentId,
        isLazyLoad,
        getNormalizedLanguageCode(i18n.language),
      ).then((opportunities) => {
        setSelectedOpportunities(
          sortArrayByKey(
            opportunities.selectedOpportunities.filter((opportunity) => opportunity.interested),
            'updatedDate',
            'date',
          ),
        );
        if (onLoad) {
          onLoad();
        }
      });
    },
    [currentStudentId, getStudentOpportunities, i18n.language, isLazyLoad],
  );

  const { isDataLoaded, loadActivatorRef } = useLazyDataLoad(
    () =>
      new Promise((resolve) => {
        loadStudentOpportunities(resolve);
      }),
    [i18n.language],
  );

  useEffect(() => {
    if (!isLazyLoad) {
      loadStudentOpportunities();
    }
  }, [isLazyLoad, loadStudentOpportunities]);

  return (
    <Box className="ayo-opportunities-block">
      <Box
        ref={isLazyLoad ? loadActivatorRef : null}
        alignItems="center"
        display="flex"
        flexDirection="row"
        mb={!selectedOpportunities?.length ? 3 : 1}
      >
        <Typography variant={titleVariant}>{t(title)}</Typography>
        <NewFeatureHotspot id={NewFeaturesIdsMap.POTENTIAL} isClickable />
      </Box>
      {!!selectedOpportunities?.length && (
        <Box
          alignItems="flex-start"
          display="flex"
          flexDirection={isWidthUpSm ? 'row' : 'column-reverse'}
          justifyContent={isWidthUpSm ? 'space-between' : 'flex-start'}
          mb={3}
        >
          <LearnMoreProfileBlock
            gaLabel="Learn more about Opportunities"
            newFeatureHotspot={<DedicatedPageNewFeatureHotspot />}
            redirectRoute={opportunitiesRoute}
          />

          {isStudent && (
            <Box mb={isWidthUpSm ? 0 : 2}>
              <Button
                component={RouterLink}
                endIcon={<VectorIcon />}
                gaLabel="Explore in Train AYO - Opportunities"
                to={trainAyoOpportunitiesRoute}
              >
                {t('Explore in Train AYO')}
              </Button>
            </Box>
          )}
        </Box>
      )}
      {selectedOpportunities?.length ? (
        <Box mr={isWidthUpSm ? 0 : 2}>
          <Carousel
            items={selectedOpportunities.map((opportunity) => (
              <OpportunityCard
                key={opportunity.name}
                headerLevel={headerLevel + 1}
                opportunity={opportunity}
              />
            ))}
            variant="slider"
          />
        </Box>
      ) : isLazyLoad && !isDataLoaded ? (
        <SkeletonMask />
      ) : (
        <InitiativeEmptyStateBlock
          body={isStudent ? t('It’s easy and quick') : null}
          buttonContent={t('Explore in Train AYO')}
          buttonGaLabel="Explore in Train AYO - Opportunities"
          buttonLink={trainAyoOpportunitiesRoute}
          illustration={<OpportunitiesEmptyIllustration />}
          secondaryButtonContent={
            <>
              {t('Learn more')}
              <Box display="flex" ml={1}>
                <ChevronIcon className="reversed" />
              </Box>
            </>
          }
          secondaryButtonGaLabel="Learn more about Opportunities"
          secondaryButtonLink={opportunitiesRoute}
          secondaryButtonNewFeaturesTour={<DedicatedPageNewFeatureHotspot />}
          showNavigationButton={isStudent}
          title={
            isStudent
              ? t('Let’s look deeper into your opportunities!')
              : t('No information about opportunities so far')
          }
        />
      )}
    </Box>
  );
};

OpportunitiesBlock.propTypes = {
  headerLevel: PropTypes.number,
  isLazyLoad: PropTypes.bool,
  studentId: PropTypes.number,
  title: PropTypes.string.isRequired,
  titleVariant: PropTypes.string,
};

OpportunitiesBlock.defaultProps = {
  headerLevel: 2,
  isLazyLoad: false,
  studentId: null,
  titleVariant: 'subtitle1',
};

export default OpportunitiesBlock;
