import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { RolesMap } from '../../constants/enums';
import { familyFeedRoute, myStudentsRoute, studentsRoute } from '../../constants/routes';
import useSharedStudentData from '../use-shared-student-data/useSharedStudentData';

const useBreadcrumbsConfig = (studentId) => {
  const { t } = useTranslation();

  const { activeStudent } = useSharedStudentData(studentId);

  const studentProfileBreadcrumbs = useMemo(
    () => ({
      [RolesMap.GUARDIAN]: [
        { gaLabel: 'Family feed', label: t('Family feed'), redirectUrl: `${familyFeedRoute}` },
        {
          gaLabel: 'Kid profile',
          label: t('studentName profile', {
            studentName: activeStudent?.firstName,
          }),
          redirectUrl: `${familyFeedRoute}/${studentId}`,
        },
      ],
      [RolesMap.TEACHER]: [
        { gaLabel: 'My students', label: t('My students'), redirectUrl: `${myStudentsRoute}` },
        {
          gaLabel: 'Student profile',
          label: t('studentName profile', {
            studentName: activeStudent?.firstName,
          }),
          redirectUrl: `${myStudentsRoute}/${studentId}`,
        },
      ],
      [RolesMap.ADMINISTRATOR]: [
        { gaLabel: 'Students', label: t('Students'), redirectUrl: `${studentsRoute}` },
        {
          gaLabel: 'Student profile',
          label: t('studentName profile', {
            studentName: activeStudent?.firstName,
          }),
          redirectUrl: `${studentsRoute}/${studentId}`,
        },
      ],
    }),
    [activeStudent?.firstName, studentId, t],
  );

  return {
    studentProfileBreadcrumbs,
  };
};

export default useBreadcrumbsConfig;
