import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { Link as RouterLink, useRouteMatch } from 'react-router-dom';

import { Typography, Link } from '../../../atoms';
import { Chip, EditingButton, TextWithTooltip } from '../../../moleculas';
import { UserContext } from '../../../../context';
import {
  apiRoute,
  attachmentsRoute,
  familyFeedRoute,
  goalsRoute,
  trainAyoRoute,
} from '../../../../constants/routes';
import {
  evidenceOfGrowthItem,
  goalActionStepItem,
  goalDetailsItem,
} from '../../../../constants/propTypes';
import { AttachmentsResourcesTypes, RolesMap } from '../../../../constants/enums';
import { ReactComponent as AttachIcon } from '../../../../resources/icons/attach_black.svg';
import { ReactComponent as DescriptionIcon } from '../../../../resources/icons/description.svg';
import { ReactComponent as DownloadIcon } from '../../../../resources/icons/download.svg';
import { ReactComponent as ExportIcon } from '../../../../resources/icons/export_bold.svg';

const downloadHandler = (href, targetName) => {
  const link = document.createElement('a');
  link.setAttribute('href', href);
  link.setAttribute('download', targetName);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const EvidenceOfGrowthDetailsVariant = {
  ACCORDION: 'accordion',
  DIALOG: 'dialog',
};

const EvidenceOfGrowthDetails = ({
  actionStep,
  evidenceOfGrowth,
  evidenceType,
  goalDetails,
  variant,
}) => {
  const { t } = useTranslation();
  const match = useRouteMatch();
  const { state: userState } = useContext(UserContext);
  const isDialogVariant = variant === EvidenceOfGrowthDetailsVariant.DIALOG;
  const leaderShipKey =
    evidenceOfGrowth.evidenceResourcesByType[AttachmentsResourcesTypes.LEADERSHIP_ATTRIBUTE]
      ?.parentResourceId;
  const { goalId } = match.params;

  const goalRedirectionRoute = useMemo(
    () =>
      userState.profile.role === RolesMap.GUARDIAN
        ? `${familyFeedRoute}/${evidenceOfGrowth.studentId}${goalsRoute}/${goalDetails?.id}`
        : `${trainAyoRoute}/${evidenceOfGrowth.studentId}${goalsRoute}/${goalDetails?.id}`,
    [evidenceOfGrowth.studentId, goalDetails?.id, userState.profile.role],
  );

  return (
    <Box className="ayo-evidence-of-growth">
      {goalDetails && isDialogVariant && (
        <Box mt={3}>
          <Typography variant="subtitle1">{evidenceOfGrowth.title}</Typography>
        </Box>
      )}
      <Box display="flex" mt={isDialogVariant ? 3 : 0}>
        <Box pr={1}>
          <DescriptionIcon />
        </Box>
        <Typography component="h4" variant="subtitle2">
          {t('Notes')}
        </Typography>
      </Box>
      <Typography variant="body2">
        {evidenceOfGrowth.reflection
          ? `${evidenceOfGrowth.reflection}`
          : t('No notes provided so far')}
      </Typography>
      <Box display="flex" mt={isDialogVariant ? 5 : 3}>
        <Box pr={1}>
          <ExportIcon />
        </Box>
        <Typography component="h4" variant="subtitle2">
          {t('Link')}
        </Typography>
      </Box>
      {evidenceOfGrowth.link ? (
        <Link
          className="ayo-evidence-of-growth__title"
          gaLabel={`Evidence link: ${evidenceOfGrowth.link}`}
          href={evidenceOfGrowth.link}
          target="_blank"
        >
          <TextWithTooltip className="ayo-link" title={evidenceOfGrowth.link} />
        </Link>
      ) : (
        <Typography variant="body2">{t('No link provided so far')}</Typography>
      )}
      {goalDetails && leaderShipKey && (
        <Box mt={isDialogVariant ? 5 : 3}>
          <Box mb={1}>
            <Typography component="h4" variant="subtitle2">
              {t('Related Attribute')}
            </Typography>
          </Box>
          <Chip isCaption label={t(leaderShipKey)} />
        </Box>
      )}
      {goalDetails && (
        <Box mt={isDialogVariant ? 5 : 3}>
          <Box mb={1}>
            <Typography component="h4" variant="subtitle2">
              {t('Related goal')}
            </Typography>
          </Box>
          {!goalId ? (
            <Link
              className="ayo-evidence-of-growth__title"
              component={RouterLink}
              gaLabel={`Evidence related goal: ${goalDetails.name}`}
              target="_self"
              to={goalRedirectionRoute}
            >
              <TextWithTooltip className="ayo-link" title={goalDetails.name} />
            </Link>
          ) : (
            <Typography component="p" variant="body2">
              {goalDetails.name}
            </Typography>
          )}
        </Box>
      )}
      {actionStep && (
        <Box mt={isDialogVariant ? 5 : 3}>
          <Box mb={1}>
            <Typography component="h4" variant="subtitle2">
              {t('Related action step')}
            </Typography>
          </Box>
          {!goalId ? (
            <Link
              className="ayo-evidence-of-growth__title"
              component={RouterLink}
              gaLabel={`Evidence related action step: ${actionStep.name}`}
              target="_self"
              to={goalRedirectionRoute}
            >
              <TextWithTooltip className="ayo-link" title={actionStep.name} />
            </Link>
          ) : (
            <Typography component="p" variant="body2">
              {actionStep.name}
            </Typography>
          )}
        </Box>
      )}
      <Box display="flex" justifyContent="space-between" mt={isDialogVariant ? 5 : 3}>
        <Box display="flex">
          <Box pr={1}>
            <AttachIcon />
          </Box>
          <Typography component="h4" variant="subtitle2">
            {t('Attachments')}
          </Typography>
        </Box>
        {evidenceOfGrowth.attachments.length > 1 && (
          <EditingButton
            aria-label="Download all attachments"
            gaLabel="Download all attachments"
            icon={<DownloadIcon />}
            iconPosition="end"
            onClick={() =>
              downloadHandler(
                `${apiRoute}${attachmentsRoute}/owners/${evidenceOfGrowth.studentId}?resourceType=${evidenceType}&resourceId=${evidenceOfGrowth.id}`,
                'All evidences',
              )
            }
            text={t('Download all')}
          />
        )}
      </Box>
      {evidenceOfGrowth.attachments.length ? (
        <Box>
          {evidenceOfGrowth.attachments.map((attachment) => (
            <Box key={attachment.id} className="ayo-evidence-of-growth__view-details">
              <Typography component="h5" variant="subtitle2">
                <Box display="flex" flexDirection="row" overflow="hidden" width="100%">
                  <span className="ayo-evidence-of-growth__title">
                    {attachment.fileName.substring(0, attachment.fileName.lastIndexOf('.'))}
                  </span>
                  <span>
                    {attachment.fileName.substring(
                      attachment.fileName.lastIndexOf('.'),
                      attachment.fileName.length,
                    )}
                  </span>
                </Box>
              </Typography>
              <Box ml={2}>
                <EditingButton
                  aria-label="Download"
                  gaLabel="Download attribute"
                  icon={<DownloadIcon />}
                  iconPosition="end"
                  onClick={() =>
                    downloadHandler(
                      `${apiRoute}${attachmentsRoute}/${attachment.id}/owners/${attachment.ownerId}?resourceType=${evidenceType}`,
                      attachment.fileName,
                    )
                  }
                  text={t('Download')}
                />
              </Box>
            </Box>
          ))}
        </Box>
      ) : (
        <Typography variant="body2">{t('No attachments provided so far')}</Typography>
      )}
    </Box>
  );
};

EvidenceOfGrowthDetails.propTypes = {
  actionStep: goalActionStepItem,
  evidenceOfGrowth: PropTypes.shape(evidenceOfGrowthItem).isRequired,
  evidenceType: PropTypes.string,
  goalDetails: goalDetailsItem,
  variant: PropTypes.oneOf([
    EvidenceOfGrowthDetailsVariant.ACCORDION,
    EvidenceOfGrowthDetailsVariant.DIALOG,
  ]),
};

EvidenceOfGrowthDetails.defaultProps = {
  actionStep: null,
  evidenceType: '',
  goalDetails: null,
  variant: EvidenceOfGrowthDetailsVariant.DIALOG,
};

export default EvidenceOfGrowthDetails;
