import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PieChart, Pie, ResponsiveContainer, Legend, Cell, Sector } from 'recharts';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import { Tooltip, Typography } from '../../../../atoms';
import { useSearchParams } from '../../../../../hooks';
import { FilterUpdateActions } from '../../../../../hooks/use-filter-update-state/useFilterUpdateState';
import {
  GA,
  getFilterValueParts,
  isFilterItemDisabled,
  transformSearchToObject,
  updateIncludeFilter,
  updatePendingFilter,
} from '../../../../../utils';
import {
  GaActions,
  GaCategories,
  KeyboardMap,
  StudentFiltersSpecialValues,
} from '../../../../../constants/enums';

const RADIAN = Math.PI / 180;

const RenderCustomizedLabel = ({ ...props }) => {
  const { t } = useTranslation();
  const {
    cx,
    cy,
    index,
    midAngle,
    name,
    onBlur,
    onFocus,
    onValueClick,
    outerRadius,
    percent,
    selectedIndex,
    value,
    getLabelTextComponent,
    labelPrefix,
  } = props;

  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + outerRadius * cos;
  const sy = cy + outerRadius * sin;
  const mx = cx + (outerRadius + (percent >= 0.4 && percent <= 0.6 ? 80 : 30)) * cos;
  const my = cy + (outerRadius + 32) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * (percent === 0 || percent === 1 ? 10 : 30);
  const ey = my;
  const x = ex + (cos >= 0 ? 1 : -1) * -20;
  const y = ey - 5;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  const label = `${t(`analytics.highlights.${labelPrefix}${name}`)} (${value}) ${
    percent === 1 ? t("Can't filter by all students") : ''
  }`;

  return (
    !!value && (
      <g
        aria-label={selectedIndex === index ? `${label} ${t('Selected')}` : label}
        onBlur={onBlur}
        onFocus={() => onFocus(index)}
        onKeyDown={(e) => {
          if (e.key === KeyboardMap.ENTER || e.key === KeyboardMap.SPACE) {
            e.preventDefault();
            onValueClick({ name, value });
          }
        }}
        role="button"
        style={{
          outline: 'none',
        }}
        tabIndex={0}
      >
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} fill="none" stroke="#FFFFFF" />
        {getLabelTextComponent ? (
          getLabelTextComponent(value, name, textAnchor, x, y)
        ) : (
          <text
            className="ayo-typography--subtitle1"
            fill="#FFFFFF"
            onClick={() => onValueClick({ name, value })}
            textAnchor={textAnchor}
            x={x}
            y={y}
          >
            {value}
          </text>
        )}
      </g>
    )
  );
};

RenderCustomizedLabel.propTypes = {
  cx: PropTypes.number.isRequired,
  cy: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  midAngle: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  onValueClick: PropTypes.func.isRequired,
  outerRadius: PropTypes.number.isRequired,
  percent: PropTypes.number.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  getLabelTextComponent: PropTypes.func,
  labelPrefix: PropTypes.string,
};

RenderCustomizedLabel.defaultProps = {
  getLabelTextComponent: null,
  labelPrefix: '',
};

const SECTOR_OUTER = 10;
const STROKE_WIDTH = 2;

const RenderActiveShape = ({ ...props }) => {
  const {
    cornerRadius,
    cx,
    cy,
    fill,
    endAngle,
    innerRadius,
    midAngle,
    name,
    outerRadius,
    percent,
    startAngle,
    value,
    labelPrefix,
  } = props;

  const { t } = useTranslation();
  const [isFocused, setIsFocused] = useState(false);

  return (
    <Tooltip
      enterTouchDelay={0}
      leaveTouchDelay={5000}
      title={
        <>
          {`${t(`analytics.highlights.${labelPrefix}${name}`)} (${value})`}
          <br />
          {percent === 1 && t("Can't filter by all students")}
        </>
      }
    >
      <g>
        <Sector
          cornerRadius={cornerRadius + SECTOR_OUTER}
          cx={cx}
          cy={cy}
          endAngle={endAngle + SECTOR_OUTER}
          fill="none"
          forceCornerRadius
          innerRadius={innerRadius - SECTOR_OUTER}
          midAngle={midAngle + SECTOR_OUTER}
          outerRadius={outerRadius + SECTOR_OUTER}
          startAngle={percent === 1 ? 0 : startAngle - SECTOR_OUTER}
          stroke={isFocused ? '#ffa726' : fill}
          strokeLinecap="round"
          strokeWidth={percent === 1 ? STROKE_WIDTH * 2 : STROKE_WIDTH}
        />
        {percent === 1 && (
          <Sector
            cx={cx}
            cy={cy}
            endAngle={endAngle + SECTOR_OUTER}
            fill="#1E152A"
            innerRadius={innerRadius - SECTOR_OUTER}
            outerRadius={outerRadius + SECTOR_OUTER}
            stroke="none"
          />
        )}
        <Sector
          cornerRadius={cornerRadius}
          cx={cx}
          cy={cy}
          endAngle={endAngle}
          fill={fill}
          innerRadius={innerRadius}
          onMouseDown={() => setIsFocused(true)}
          outerRadius={outerRadius}
          startAngle={startAngle}
        />
      </g>
    </Tooltip>
  );
};

RenderActiveShape.propTypes = {
  cornerRadius: PropTypes.number.isRequired,
  cx: PropTypes.number.isRequired,
  cy: PropTypes.number.isRequired,
  fill: PropTypes.string.isRequired,
  endAngle: PropTypes.number.isRequired,
  innerRadius: PropTypes.number.isRequired,
  midAngle: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  outerRadius: PropTypes.number.isRequired,
  percent: PropTypes.number.isRequired,
  startAngle: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  labelPrefix: PropTypes.string,
};

RenderActiveShape.defaultProps = {
  labelPrefix: '',
};

const PieChartComponent = ({
  colorFormatter,
  gaLabel,
  height,
  filterCategory,
  onFiltersUpdate,
  pieColors,
  statKey,
  stats,
  statsSorter,
  totalStudentsCount,
  labelPrefix,
  legendContent,
  getLabelTextComponent,
  chartSettings,
}) => {
  const { t } = useTranslation();
  const [pieData, setPieData] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const onPieEnter = (_, index) => setActiveIndex(index);
  const onPieOut = () => setActiveIndex(null);

  const search = useSearchParams();
  const history = useHistory();

  const filters = useMemo(() => transformSearchToObject(search), [search]);
  const { filtersList } = getFilterValueParts(filters, stats);

  const onPieClick = useCallback(
    (payload) => {
      const { name, value } = payload;
      if (!onFiltersUpdate || isFilterItemDisabled(value, totalStudentsCount)) return;
      const newFilters =
        name === StudentFiltersSpecialValues.PENDING
          ? updatePendingFilter(filters, filterCategory, stats)
          : updateIncludeFilter(filters, filterCategory, stats);
      GA.logInteraction({
        category: GaCategories.BEHAVIOR,
        action: GaActions.GRAPH_PART_CLICKED,
        label: `${gaLabel} Graph - ${name}`,
      });
      onFiltersUpdate({
        type: FilterUpdateActions.UPDATE,
        data: {
          filters,
          value: name,
          category: filterCategory,
          stats,
          t,
        },
      });
      history.replace({ search: new URLSearchParams(newFilters).toString() });
    },
    [filterCategory, filters, gaLabel, history, onFiltersUpdate, stats, t, totalStudentsCount],
  );

  useEffect(() => {
    if (stats) {
      const data = Object.entries(stats[statKey]).reduce(
        (acc, [name, value]) => [...acc, { name, value }],
        [],
      );

      if (statsSorter) {
        data.sort(statsSorter);
      }

      setPieData(data);

      if (filters.filterCategory === filterCategory && data) {
        setSelectedIndex(data.findIndex((el) => el.name === filtersList.toString()));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterCategory, filters.filterCategory, statKey, stats]);

  if (!pieData) {
    return null;
  }

  const { cornerRadius, innerRadius, outerRadius, legendIconSize, paddingAngle } = chartSettings;

  return (
    <ResponsiveContainer
      className={classNames('ayo-pie-chart-component ayo-chart-legend', {
        'ayo-pie-chart-component--read-only': !onFiltersUpdate,
      })}
      height={height}
      width="100%"
    >
      <PieChart>
        <Pie
          activeIndex={[activeIndex, selectedIndex]}
          activeShape={onFiltersUpdate ? <RenderActiveShape labelPrefix={labelPrefix} /> : null}
          cornerRadius={cornerRadius}
          data={pieData}
          dataKey="value"
          innerRadius={innerRadius}
          isAnimationActive={false}
          label={
            <RenderCustomizedLabel
              getLabelTextComponent={getLabelTextComponent}
              labelPrefix={labelPrefix}
              onBlur={onPieOut}
              onFocus={(index) => setActiveIndex(index)}
              onValueClick={onPieClick}
              selectedIndex={selectedIndex}
            />
          }
          labelLine={false}
          onClick={(payload) => onPieClick(payload)}
          onMouseEnter={onPieEnter}
          onMouseOut={onPieOut}
          outerRadius={outerRadius}
          paddingAngle={pieData?.filter((el) => el.value > 0).length === 1 ? 0 : paddingAngle}
        >
          {pieData.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={colorFormatter ? colorFormatter(entry.name) : pieColors[index]}
              stroke="none"
            />
          ))}
        </Pie>
        <Legend
          content={legendContent}
          displayName="Legend"
          formatter={(value) => (
            <Box display="inline-flex">
              <Typography variant="caption">
                {t(`analytics.highlights.${labelPrefix}${value}`)}
              </Typography>
            </Box>
          )}
          iconSize={legendIconSize}
          iconType="square"
          verticalAlign="top"
        />
      </PieChart>
    </ResponsiveContainer>
  );
};

PieChartComponent.propTypes = {
  chartSettings: PropTypes.instanceOf(Object),
  colorFormatter: PropTypes.func,
  filterCategory: PropTypes.string,
  gaLabel: PropTypes.string,
  getLabelTextComponent: PropTypes.func,
  height: PropTypes.number,
  labelPrefix: PropTypes.string,
  legendContent: PropTypes.func,
  onFiltersUpdate: PropTypes.func,
  pieColors: PropTypes.arrayOf(PropTypes.string),
  statKey: PropTypes.string.isRequired,
  stats: PropTypes.instanceOf(Object).isRequired,
  statsSorter: PropTypes.func,
  totalStudentsCount: PropTypes.number,
};

PieChartComponent.defaultProps = {
  chartSettings: {
    cornerRadius: 12,
    innerRadius: 60,
    outerRadius: 80,
    legendIconSize: 16,
    paddingAngle: 10,
  },
  colorFormatter: null,
  filterCategory: null,
  gaLabel: '',
  getLabelTextComponent: null,
  height: 350,
  labelPrefix: '',
  legendContent: null,
  onFiltersUpdate: null,
  pieColors: [],
  statsSorter: null,
  totalStudentsCount: 0,
};

export default PieChartComponent;
