import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Card, Tooltip, Typography } from '../../atoms';
import { PublicationStatusBadge } from '../../organisms';
import { lessonTitlePlaceholder } from '../../../constants/lesson-planner';
import { PublicationStatuses } from '../../../constants/enums';
import { ReactComponent as AgendaBoardIcon } from '../../../resources/icons/agenda_board.svg';
import { ReactComponent as AssignmentsIcon } from '../../../resources/icons/assignments.svg';
import { ReactComponent as ChoicePathIcon } from '../../../resources/icons/choice_path.svg';
import TextWithTooltip from '../tooltip-wrappers/text-with-tooltip/TextWithTooltip';

const LessonInfoMap = {
  agendaBoard: {
    icon: AgendaBoardIcon,
    tooltip: 'This lesson includes Agenda board.',
  },
  assigment: {
    icon: AssignmentsIcon,
    tooltip: 'This lesson includes Assignment(s).',
  },
  choicePath: {
    icon: ChoicePathIcon,
    tooltip: 'This lesson includes Choices path(s).',
  },
};

const LessonCard = ({
  isStudent,
  lessonInfo,
  onClick,
  status,
  title,
  courses,
  availableCourses,
}) => {
  const { t } = useTranslation();

  const lessonInfoItemsToDisplay = useMemo(
    () =>
      lessonInfo
        ? Object.entries(lessonInfo)
            .filter(([, value]) => value)
            .sort(([aKey], [bKey]) => aKey.localeCompare(bKey))
            .map(([key]) => {
              const Icon = LessonInfoMap[key].icon;
              return (
                <Tooltip key={key} title={t(LessonInfoMap[key].tooltip)}>
                  <Icon />
                </Tooltip>
              );
            })
        : [],
    [lessonInfo, t],
  );

  const isClickable = useMemo(
    () => !!onClick && (!isStudent || lessonInfoItemsToDisplay.length),

    [lessonInfoItemsToDisplay, isStudent, onClick],
  );

  const CardContent = (
    <Card
      className={classNames('ayo-lesson-card', {
        'ayo-lesson-card--draft': status === PublicationStatuses.DRAFT,
        'ayo-clickable-card': isClickable,
      })}
      component="button"
      mainContent={
        <Box
          alignItems="flex-start"
          display="flex"
          flexDirection="column"
          minHeight={21.5}
          px={2}
          py={isStudent ? 2 : 5.5}
          rowGap={isStudent ? 3 : 2}
        >
          {!!status && <PublicationStatusBadge variant={status} />}
          <TextWithTooltip
            rowsCount={2}
            title={title || t(lessonTitlePlaceholder)}
            titleVariant="subtitle2"
          >
            {title || t(lessonTitlePlaceholder)}
          </TextWithTooltip>
          {courses && availableCourses && (
            <Box>
              <Typography mb={1} variant="label-highlighted">
                {t('Sections')} - {courses.length}/{availableCourses.length}
              </Typography>
              <Typography isLabel variant="body3">
                {courses.map((course) => course.className).join(', ')}
              </Typography>
            </Box>
          )}
          {!!lessonInfoItemsToDisplay.length && (
            <Box alignItems="center" columnGap={1} display="flex">
              {lessonInfoItemsToDisplay}
            </Box>
          )}
        </Box>
      }
      onClick={isClickable ? onClick : null}
    />
  );

  return isClickable ? (
    CardContent
  ) : (
    <Tooltip
      title={t(
        'This is the topic of your upcoming lesson. The teacher hasn’t prepared anything for you to review yet.',
      )}
    >
      {CardContent}
    </Tooltip>
  );
};

LessonCard.propTypes = {
  lessonInfo: PropTypes.instanceOf(Object),
  isStudent: PropTypes.bool,
  onClick: PropTypes.func,
  status: PropTypes.string,
  title: PropTypes.string.isRequired,
  courses: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  availableCourses: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
};

LessonCard.defaultProps = {
  lessonInfo: null,
  isStudent: false,
  onClick: null,
  status: null,
};

export default LessonCard;
