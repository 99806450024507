/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useMemo } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Bar, BarChart, Cell, Label, Legend, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import PropTypes from 'prop-types';

import { Typography } from '../../../atoms';
import { InformationalCaption } from '../../../moleculas';
import { AnalyticsStatsKeys } from '../../../../constants/enums';
import { MOOD_DIDNT_SHARE, MoodColors } from '../../../../constants/moods';

const BAR_CHART_MARGIN = { right: 24 };
const MAX_Y_AXIS_WIDTH = 150;
const SEL_STRATEGY_COLOR = '#6DD3D6';

const SkipRecommendationsDataKeys = ['studentsNumber'];

const LegendColorsToLabelMap = {
  [SEL_STRATEGY_COLOR]: {
    label: 'Students who engaged with SEL strategies',
    valueKey: 'studentsNumber',
  },
  [MoodColors.PENDING]: {
    label: MOOD_DIDNT_SHARE,
    valueKey: MOOD_DIDNT_SHARE,
  },
};

const getChartHeight = (itemsCount, itemSize, itemGap) => {
  const minHeight = 240;
  const maxHeight = 710;
  const additionalHeight = 100; // paddings, margins...

  const sizeCalculated = itemsCount * (itemSize + itemGap) + additionalHeight;

  switch (true) {
    case sizeCalculated < minHeight:
      return minHeight;
    case sizeCalculated > maxHeight:
      return maxHeight;
    default:
      return sizeCalculated;
  }
};

const getYAxisWidth = (data, dataKey) => {
  const charWidth = 5;
  const longestLabel =
    data.reduce(
      (longest, cur) => (cur[dataKey].length > longest ? cur[dataKey].length : longest),
      0,
    ) * charWidth;

  return MAX_Y_AXIS_WIDTH > longestLabel ? longestLabel : MAX_Y_AXIS_WIDTH;
};

const BarLabel = ({ content, ...restProps }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Label {...restProps} aria-label={`${restProps.name} (${restProps.value})`} tabIndex={0} />
);

BarLabel.propTypes = {
  content: PropTypes.element.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
};

const SELRecommendationsHighlights = ({ stats }) => {
  const { t } = useTranslation();

  const getBarColor = useCallback(
    (name) =>
      name === t(MOOD_DIDNT_SHARE) || name === MOOD_DIDNT_SHARE
        ? MoodColors.PENDING
        : SEL_STRATEGY_COLOR,
    [t],
  );

  const recommendationsData = useMemo(() => {
    let data = null;

    if (stats[AnalyticsStatsKeys.SEL_RECOMMENDATIONS]) {
      data = Object.entries(stats[AnalyticsStatsKeys.SEL_RECOMMENDATIONS]).reduce(
        (acc, [name, value]) =>
          value > 0 ? [...acc, { name, value, color: getBarColor(name) }] : acc,
        [],
      );

      data.sort((a, b) => b.value - a.value);
    }

    return data;
  }, [getBarColor, stats]);

  const chartData = useMemo(
    () => recommendationsData.filter(({ name }) => !SkipRecommendationsDataKeys.includes(name)),
    [recommendationsData],
  );

  const tickFormatter = useCallback((value) => t(`SELStrategies.${value}`), [t]);

  const renderLegend = (legendData, values, colorsToLabelsMap) => {
    const { iconSize } = legendData;

    const valueKeys = Object.values(colorsToLabelsMap).map(({ valueKey }) => valueKey);
    const legendValues = values.reduce(
      (acc, item) => (valueKeys.includes(item.name) ? { ...acc, [item.name]: item.value } : acc),
      {},
    );

    const legendItemsMap = Object.entries(colorsToLabelsMap).reduce(
      (acc, [color, item]) => ({
        ...acc,
        [color]: {
          label: item.label,
          value: legendValues[item.valueKey] || 0,
        },
      }),
      {},
    );

    return (
      <Box alignItems="center" className="ayo-highlights-legend" display="flex" mb={0} pb={3}>
        <Typography className="ayo-highlights-legend__title" mr={3} variant="caption">{`${t(
          'Legend',
        )}:`}</Typography>
        <ul className="ayo-highlights-legend__list">
          {Object.entries(legendItemsMap).map(([color, item]) => (
            <li key={`legend-item-${item.label}`} className="ayo-highlights-legend__list__item">
              <svg
                className="recharts-surface"
                height={iconSize}
                style={{
                  borderRadius: '4px',
                  marginRight: '8px',
                  overflow: 'hidden',
                }}
                viewBox={`0 0 ${iconSize * 2} ${iconSize * 2}`}
                width={iconSize}
              >
                <path
                  className="recharts-symbols"
                  cx={iconSize}
                  cy={iconSize}
                  d={`M-${iconSize},-${iconSize}h${iconSize * 2}v${iconSize * 2}h-${iconSize * 2}Z`}
                  fill={color}
                  radius="4px"
                  stroke="none"
                  transform={`translate(${iconSize}, ${iconSize})`}
                />
              </svg>
              <Typography component="span" variant="caption">{`${t(item.label)} (${
                item.value
              })`}</Typography>
            </li>
          ))}
        </ul>
      </Box>
    );
  };

  if (!chartData) {
    return null;
  }

  return (
    <Box>
      <InformationalCaption
        isLight
        showTooltip
        title={t('This chart represents read-only information.')}
        tooltipPlacement="right"
      />
      <Box height={getChartHeight(chartData?.length, 20, 4)}>
        <ResponsiveContainer height="100%" width="100%">
          <BarChart
            barCategoryGap={2}
            className="ayo-sel-recommendations-barchart"
            data={chartData}
            layout="vertical"
            margin={BAR_CHART_MARGIN}
          >
            <XAxis className="ayo-chart-axis" tickLine={false} type="number" />
            <YAxis
              className="ayo-chart-axis"
              dataKey="name"
              interval={0}
              tickFormatter={tickFormatter}
              tickLine={false}
              type="category"
              width={getYAxisWidth(chartData, 'name')}
            />
            <Legend
              content={(data) => renderLegend(data, recommendationsData, LegendColorsToLabelMap)}
              iconSize={16}
              verticalAlign="top"
            />
            <Bar
              data={chartData}
              dataKey="value"
              isAnimationActive={false}
              label={<BarLabel position="right" />}
              maxBarSize={24}
            >
              {chartData.map((entry) => (
                <Cell key={`cell-${entry.name}`} fill={entry.color} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};

SELRecommendationsHighlights.propTypes = {
  stats: PropTypes.instanceOf(Object).isRequired,
};

export default SELRecommendationsHighlights;
