import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, DialogContent, useTheme, useMediaQuery } from '@mui/material';

import { Dialog, DialogTitle, Typography } from '../../../../atoms';
import { AccordionItem, BadgesRating } from '../../../../moleculas';
import { BottomNavigation } from '../../../../organisms';
import { getFullDateAndHoursString } from '../../../../../utils';
import { BadgesLevelNamesMap, BadgesStatusMap } from '../../../../../constants/badges';
import { ReactComponent as AchivedStarImage } from '../../../../../resources/images/yellow_star.svg';
import { ReactComponent as DefaultStarImage } from '../../../../../resources/images/hollow_star.svg';
import BadgesImage from '../badge-image/BadgeImage';

const BadgesDialogHeader = ({ activeBadgeData, isFullyAchieved }) => {
  const { t } = useTranslation();
  return (
    <Box
      display="flex"
      flexDirection={isFullyAchieved ? 'column-reverse' : 'row'}
      justifyContent={isFullyAchieved ? 'center' : 'space-between'}
    >
      <Box textAlign={isFullyAchieved && 'center'}>
        <Typography component="h2" paragraph variant="subtitle1">
          {t(activeBadgeData.nameKey)}
        </Typography>
        <Typography paragraph variant="body2">
          {t(activeBadgeData.definition)}
        </Typography>
      </Box>
      <Box ml={isFullyAchieved ? 0 : 5}>
        <Box
          className={classNames('ayo-badges-dialog__badge-image', {
            'ayo-badges-dialog__badge-image--achieved': isFullyAchieved,
          })}
        >
          <BadgesImage badgeData={activeBadgeData} />
        </Box>
        <BadgesRating badge={activeBadgeData} />
      </Box>
    </Box>
  );
};

BadgesDialogHeader.propTypes = {
  activeBadgeData: PropTypes.instanceOf(Object).isRequired,
  isFullyAchieved: PropTypes.bool.isRequired,
};

BadgesDialogHeader.defaultProps = {};

const BadgesDialog = ({ badges, index, isOpen, navigationHandler, onClose }) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const titleRef = useRef();

  const activeBadgeData = badges?.[index];
  const isLARelated = activeBadgeData?.type?.startsWith('LA_');
  const isFullyAchieved = useMemo(
    () => activeBadgeData?.achievementStatus === BadgesStatusMap.achieved,
    [activeBadgeData],
  );
  const isAccordionVisible = useMemo(() => activeBadgeData?.rating.length > 1, [activeBadgeData]);

  const getDetailsTranslation = useCallback(
    (translationString, isAccordionName) => {
      const data = isAccordionName
        ? BadgesLevelNamesMap[translationString].split('/')
        : translationString.split('/');
      return t(data[0], { numberOfTimes: data[1] });
    },
    [t],
  );

  useEffect(() => {
    const titleElement = titleRef.current;
    if (titleElement) {
      titleElement.scrollIntoView({ block: 'start' });
    }
  }, [index]);

  if (index === null) {
    return null;
  }

  return (
    <Dialog
      className={classNames('ayo-badges-dialog', {
        'ayo-badges-dialog--achieved': isFullyAchieved,
      })}
      onClose={onClose}
      open={isOpen}
      scroll="paper"
    >
      <DialogTitle disableTypography>
        <Box px={isWidthUpSm ? 5 : 2}>
          <BadgesDialogHeader activeBadgeData={activeBadgeData} isFullyAchieved={isFullyAchieved} />
        </Box>
      </DialogTitle>
      <DialogContent>
        {isAccordionVisible && (
          <Box ref={titleRef} className="ayo-badges-dialog__text">
            {activeBadgeData.rating.map((level, itemIndex) => (
              <AccordionItem
                key={`${index}-${level.levelKey}`}
                bodyEl={
                  <Box>
                    {level.completionDate && (
                      <Box alignItems="start" display="flex" justifyContent="space-between" mb={3}>
                        <Typography className="ayo-badges-dialog__school" isLabel variant="body3">
                          {level?.metadata?.schoolName}
                        </Typography>
                        <Typography className="ayo-badges-dialog__date" isLabel variant="body3">
                          {getFullDateAndHoursString(level.completionDate, i18n.language)}
                        </Typography>
                      </Box>
                    )}
                    {level.details.map((detail) => (
                      <>
                        {isLARelated ? (
                          <ul>
                            <li>
                              <Typography variant="body2">
                                {getDetailsTranslation(detail)}
                              </Typography>
                            </li>
                          </ul>
                        ) : (
                          <Typography variant="body2">{getDetailsTranslation(detail)}</Typography>
                        )}
                      </>
                    ))}
                  </Box>
                }
                className="ayo-badges-dialog__accordion"
                gaLabel="Badge level"
                headerEl={
                  <Box alignItems="center" display="flex" justifyContent="space-between">
                    <Typography component="h3" variant="subtitle2">
                      {getDetailsTranslation(
                        isLARelated ? level.levelKey : `${level.levelKey}_${activeBadgeData.type}`,
                        true,
                      )}
                    </Typography>
                    {level.completionDate ? (
                      <AchivedStarImage aria-label={t('Yellow star')} role="img" />
                    ) : (
                      <DefaultStarImage aria-label={t('Gray star')} role="img" />
                    )}
                  </Box>
                }
                isDefaultExpanded={!isFullyAchieved && itemIndex === 0}
              />
            ))}
          </Box>
        )}
      </DialogContent>
      {!isFullyAchieved && (
        <Box mt={3} px={isWidthUpSm ? 5 : 2}>
          <Typography variant="body2">{t(activeBadgeData.achivementMessage)}</Typography>
        </Box>
      )}
      <Box mt={isAccordionVisible ? (isWidthUpSm ? 3 : 2) : 5} px={isWidthUpSm ? 5 : 2}>
        <BottomNavigation
          attributeKey="nameKey"
          attributes={badges}
          index={index}
          navigationHandler={navigationHandler}
          nextButtonTitle="Next badge"
          previousButtonTitle="Previous badge"
        />
      </Box>
    </Dialog>
  );
};

BadgesDialog.propTypes = {
  badges: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  index: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  navigationHandler: PropTypes.func.isRequired,
};

BadgesDialog.defaultProps = {};

export default BadgesDialog;
