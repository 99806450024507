/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import MUIAvatar from '@mui/material/Avatar';

const Avatar = ({ children, className, size, ...rest }) => (
  <MUIAvatar
    className={`${classNames(`ayo-avatar--${size}`, { [`${className}`]: className })}`}
    {...rest}
  >
    {children}
  </MUIAvatar>
);

Avatar.propTypes = {
  children: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.oneOf(['full', '4x', '3x', 'normal']),
};

Avatar.defaultProps = {
  children: '',
  className: '',
  size: 'normal',
};

export default Avatar;
