import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import DownloadAppButtonsBlock from '../../components/moleculas/download-app-buttons-block/DownloadAppButtonsBlock';
import { UserContext } from '../../context';
import {
  EditablePrivilegesByGroupMap,
  PrivilegesMap,
  RolesMap,
  SchoolLevels,
} from '../../constants/enums';
import { Typography } from '../../components/atoms';
import { checkEnvironmentVariable, getFormattedDate, useProfile } from '../../utils';
import { FamilyFeedFilterCategory } from '../../constants/filter-categories';

export const NewFeaturesIdsMap = {
  ACHIEVEMENTS: 'achievements',
  ACTIVITIES_SEARCH: 'activities_search',
  ANALYTICS_INITIATIVES: 'analytics_initiatives',
  APTITUDES: 'aptitudes',
  APTITUDES_HIGHLIGHTS: 'aptitudes_highlights',
  ASSESSMENT_TOOL: 'assessment_tool',
  BADGES: 'badges',
  CAMPUS_HIGHLIGHTS: 'campus_highlights',
  CAMPUS_HIGHLIGHTS_NEW_INITIATIVES: 'campus_highlights_new_initiatives',
  CAMPUS_HIGHLIGHTS_PORTFOLIO: 'campus_highlights_portfolio',
  CHOICES_PATH: 'choices_path',
  CLUB_HUB_EVENTS: 'club_hub_events',
  CLUB_HUB_CREATE_POSTS: 'club_hub_create_posts',
  CLUB_HUB_STUDENT_PROFILE: 'club_hub_student_profile',
  CLUB_HUB_TAGS: 'club_hub_tags',
  ENDORSEMENTS_ANALYTICS_WIDGET: 'endorsements_analytics_widget',
  ENDORSEMENTS_HIGHLIGHTS: 'endorsements_highlights',
  ENDORSEMENTS_PROFILE_INTERESTED: 'endorsements_profile_interested',
  ENDORSEMENTS_PROFILE_SELECTED: 'endorsements_profile_selected',
  EVIDENCES: 'evidences',
  EXTRACURRICULARS: 'extracurriculars',
  FAMILY_FEED: 'family_feed',
  FEED_EVENTS: 'feed_events',
  GOALS: 'goals',
  GOALS_CREATE_EDUCATORS: 'goals_create_educators',
  GROUPING: 'grouping',
  GROUPING_INTERESTS: 'grouping_interests',
  INTERESTS_MAP: 'interests_map',
  INTERESTS_MAP_V2: 'interests_map_v2',
  INTEREST_COMMENT: 'interest_comment',
  INTEREST_DEFINITION: 'interest_definition',
  LEADERSHIP_ATTRIBUTES: 'leadership_attributes',
  LEADERSHIP_ATTRIBUTES_COMMENTS: 'leadership_attributes_comments',
  LEADERSHIP_ATTRIBUTES_GOALS: 'leadership_attributes_goals',
  LEADERSHIP_ATTRIBUTES_SUGGESTIONS: 'leadership_attributes_suggestions',
  LESSONS_REPOSITORY: 'lessons_repository',
  LESSONS_REPOSITORY_SEARCH: 'lessons_repository_search',
  LESSON_ADD_COMMENT: 'lesson_add_comment',
  LESSON_DESIGNER: 'lesson_designer',
  LESSON_DESIGNER_AGENDA_BOARDS: 'lesson_designer_agenda_boards',
  LESSON_DESIGNER_ASSIGNMENTS: 'lesson_designer_assignments',
  LESSON_DESIGNER_MATERIALS: 'lesson_designer_materials',
  LESSON_DESIGNER_NEW_ASSIGNMENT_TYPES: 'lesson_designer_new_assignment_types',
  LESSON_DESIGNER_PUBLISH_EDIT_LESSON: 'lesson_designer_publish_edit_lesson',
  LESSON_DESIGNER_TEKS: 'lesson_designer_teks',
  LESSON_PLANNER_ALL: 'lesson_planner_all',
  LESSON_PLANNER_WEEK_MONTH_VIEW: 'lesson_planner_week_month_view',
  LESSON_SHARE: 'lesson_share',
  MOBILE_APP_AVAILABLE: 'mobile_app_available',
  MOOD_CHECK_INS: 'mood_check_ins',
  MULTI_CAMPUS_SUPPORT: 'multi_campus_support',
  MY_CLASSES: 'my_classes',
  NOTIFICATIONS: 'notifications',
  OPPORTUNITIES: 'opportunities',
  OPPORTUNITIES_DEDICATED_PAGE: 'opportunities_dedicated_page',
  PARENT_QUESTIONNAIRE: 'parent_questionnaire',
  PORTFOLIO_GRADUATION_TOOLKIT: 'portfolio_graduation_toolkit',
  PORTFOLIO_MULTIPLE: 'portfolio_multiple',
  PORTFOLIO_NOTIFICATIONS: 'portfolio_notifications',
  PORTFOLIO_VISIBILITY: 'portfolio_visibility',
  PORTFOLIO_RECOMMENDATIONS: 'portfolio_recommendations',
  POST_CAMPUS_CONTACTS: 'post_campus_contacts',
  POST_LINK: 'post_link',
  POST_MESSAGES: 'post_messages',
  POST_MULTIPLE_IMAGES: 'post_multiple_images',
  POTENTIAL: 'potential',
  SEL: 'sel',
  SEL_ADULT_ASSISTANCE: 'sel_adult_assistance',
  SEL_DISTRICT_REPORT: 'sel_district_report',
  STAFF_CONVERSATIONS: 'staff_conversations',
  STUDENT_AVATAR: 'student_avatar',
  SUPPORT: 'support',
  SUPPORT_ATTACHMENTS: 'support_attachments',
  STUDENT_MODE: 'student_mode',
  USER_MANAGEMENT: 'user_management',
};

const NewFeatureItem = ({ alt, actionButtons, src, subtitle, text }) => (
  <>
    <Box mb={2}>
      <Typography variant="body2">{text}</Typography>
    </Box>
    {subtitle && (
      <Box mb={1}>
        <Typography component="h4" variant="subtitle2">
          {subtitle}
        </Typography>
      </Box>
    )}
    {actionButtons}
    {src && (
      <Box pb="56.25%" position="relative" width="100%">
        <img alt={alt} className="ayo-features-tour__illustration" src={src} width="100%" />
      </Box>
    )}
  </>
);

NewFeatureItem.propTypes = {
  alt: PropTypes.string.isRequired,
  actionButtons: PropTypes.node,
  src: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  text: PropTypes.string,
};

NewFeatureItem.defaultProps = {
  actionButtons: null,
  subtitle: '',
  text: '',
};

const NewFeaturesItemsProvider = () => {
  const { i18n, t } = useTranslation();
  const { state: userState } = useContext(UserContext);

  const { getIsCampusSelectionAvailable, getPrivilegeStatus } = useProfile();
  const currentCampusSchoolLevel = userState?.profile?.currentCampusSchoolLevel;

  const isUserPrivilegesManager = getPrivilegeStatus(PrivilegesMap.USER_PERMISSION_MANAGER);
  const isUserDataManager = getPrivilegeStatus(PrivilegesMap.USER_DATA_MANAGER);
  const isLessonPlanerAvailable = getPrivilegeStatus(PrivilegesMap.LESSON_PLANNER_CREATE_PUBLISH);
  const isFamilyFeedAvailable = EditablePrivilegesByGroupMap[FamilyFeedFilterCategory].find(
    (privilege) => getPrivilegeStatus(privilege),
  );

  const tourItems = useMemo(() => {
    switch (userState.profile?.role) {
      case RolesMap.STUDENT:
        return [
          {
            mainContent: (
              <NewFeatureItem
                alt={t('nft.club_post.alt.student')}
                src={t('nft.club_post.src.student')}
                text={t('nft.club_post.text.student')}
              />
            ),
            title: t('nft.club_post.title'),
            time: getFormattedDate('20 December, 2023', i18n.language),
            id: NewFeaturesIdsMap.CLUB_HUB_CREATE_POSTS,
            showHotspot: true,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('nft.post_multiple_images.alt')}
                src={t('nft.post_multiple_images.src')}
                text={t('nft.post_multiple_images.text.student')}
              />
            ),
            title: t('nft.post_multiple_images.title'),
            time: getFormattedDate('20 December, 2023', i18n.language),
            id: NewFeaturesIdsMap.POST_MULTIPLE_IMAGES,
            showHotspot: true,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('nft.student_avatar.alt')}
                src={t('nft.student_avatar.src')}
                text={t('nft.student_avatar.text')}
              />
            ),
            title: t('nft.student_avatar.title'),
            time: getFormattedDate('20 December, 2023', i18n.language),
            id: NewFeaturesIdsMap.STUDENT_AVATAR,
            showHotspot: true,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('nft.feed_events.alt.student')}
                src={t('nft.feed_events.src.student')}
                text={t('nft.feed_events.text.student')}
              />
            ),
            title: t('nft.feed_events.title.viewer'),
            time: getFormattedDate('20 December, 2023', i18n.language),
            id: NewFeaturesIdsMap.FEED_EVENTS,
            showHotspot: true,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('nft.club_events.alt')}
                src={t('nft.club_events.src')}
                text={t('nft.club_events.text')}
              />
            ),
            title: t('nft.club_events.title'),
            time: getFormattedDate('20 December, 2023', i18n.language),
            id: NewFeaturesIdsMap.CLUB_HUB_EVENTS,
            showHotspot: true,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour choices path alt (student)')}
                src={t('Assets tour choices path filename (student)')}
                text={t('Choices path tour step (student)')}
              />
            ),
            title: t('Choices path'),
            time: getFormattedDate('24 May, 2023', i18n.language),
            id: NewFeaturesIdsMap.CHOICES_PATH,
            showHotspot: false,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour my classes alt')}
                src={t('Assets tour my classes filename')}
                text={t('My classes tour step')}
              />
            ),
            title: t('My classes'),
            time: getFormattedDate('24 May, 2023', i18n.language),
            id: NewFeaturesIdsMap.MY_CLASSES,
            showHotspot: false,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour portfolio multiple alt')}
                src={t('Assets tour portfolio multiple filename')}
                text={t('Create multiple portfolios tour step')}
              />
            ),
            title: t('Create multiple portfolios'),
            time: getFormattedDate('24 May, 2023', i18n.language),
            id: NewFeaturesIdsMap.PORTFOLIO_MULTIPLE,
            showHotspot: false,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour recommendations alt (student)')}
                src={t('Assets tour recommendations filename (student)')}
                text={t('Recommendations tour step (student)')}
              />
            ),
            title: t('Request a recommendation'),
            time: getFormattedDate('24 May, 2023', i18n.language),
            id: NewFeaturesIdsMap.PORTFOLIO_RECOMMENDATIONS,
            showHotspot: false,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour portfolio Graduation toolkit alt')}
                src={t('Assets tour portfolio Graduation toolkit filename')}
                text={t('Graduation toolkit tour step')}
              />
            ),
            title: t('Graduation toolkit'),
            time: getFormattedDate('26 April, 2023', i18n.language),
            id: NewFeaturesIdsMap.PORTFOLIO_GRADUATION_TOOLKIT,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour portfolio notifications alt')}
                src={t('Assets tour portfolio notifications filename')}
                text={t('Portfolio notifications tour step')}
              />
            ),
            title: t('Portfolio'),
            time: getFormattedDate('4 January, 2023', i18n.language),
            id: NewFeaturesIdsMap.PORTFOLIO_NOTIFICATIONS,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour mood check-ins alt(Student)')}
                src={t('Assets tour mood check-ins filename(Student)')}
                text={t('Mood check-ins tour step(student)')}
              />
            ),
            title: t('Mood check-in'),
            time: getFormattedDate('7 December, 2022', i18n.language),
            id: NewFeaturesIdsMap.SEL,
          },
          userState?.isEndorsementsAvailable === false && {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour endorsements student interested alt')}
                src={t('Assets tour endorsements student interested filename')}
                text={t('Endorsements student tour interested step')}
              />
            ),
            title: t('My endorsements'),
            time: getFormattedDate('7 December, 2022', i18n.language),
            id: NewFeaturesIdsMap.ENDORSEMENTS_PROFILE_INTERESTED,
          },
          checkEnvironmentVariable('REACT_APP_IS_MOBILE_APP_AVAILABLE', 'true') && {
            mainContent: (
              <NewFeatureItem
                actionButtons={<DownloadAppButtonsBlock />}
                alt={t('Assets tour mobile app alt')}
                src={t('Assets tour mobile app filename')}
                text={t('Mobile app tour step student')}
              />
            ),
            title: t('AYO App is now available!'),
            time: getFormattedDate('15 November, 2022', i18n.language),
            id: NewFeaturesIdsMap.MOBILE_APP_AVAILABLE,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour badges alt')}
                src={t('Assets tour badges filename')}
                text={t('Badges tour step')}
              />
            ),
            title: t('My badges'),
            time: getFormattedDate('12 October 2022', i18n.language),
            id: NewFeaturesIdsMap.BADGES,
          },
          userState?.isEndorsementsAvailable && {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour endorsements student alt')}
                src={t('Assets tour endorsements student filename')}
                text={t('Endorsements student tour step')}
              />
            ),
            title: t('My endorsements'),
            time: getFormattedDate('14 September, 2022', i18n.language),
            id: NewFeaturesIdsMap.ENDORSEMENTS_PROFILE_SELECTED,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour interest comment alt')}
                src={t('Assets tour interest comment filename')}
                text={t('Your comment tour step')}
              />
            ),
            title: t('Your comment (student)'),
            time: getFormattedDate('17 August, 2022', i18n.language),
            id: NewFeaturesIdsMap.INTEREST_COMMENT,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour opportunities dedicated page alt(Student)')}
                src={t('Assets tour opportunities dedicated page filename(Student)')}
                text={t('Opportunities dedicated page tour step')}
              />
            ),
            title: t('My opportunities - Dedicated page'),
            time: getFormattedDate('17 August, 2022', i18n.language),
            id: NewFeaturesIdsMap.OPPORTUNITIES_DEDICATED_PAGE,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour opportunities alt')}
                src={t('Assets tour opportunities filename')}
                text={t('Opportunities tour step')}
              />
            ),
            title: t('Opportunities'),
            time: getFormattedDate('20 July, 2022', i18n.language),
            id: NewFeaturesIdsMap.OPPORTUNITIES,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour aptitudes alt')}
                src={t('Assets tour aptitudes filename student')}
                text={t('Aptitudes tour step student')}
              />
            ),
            title: t('My aptitudes'),
            time: getFormattedDate('20 July, 2022', i18n.language),
            id: NewFeaturesIdsMap.APTITUDES,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour interest definition alt')}
                src={t('Assets tour interest definition filename student')}
                text={t('Interest definition tour step')}
              />
            ),
            title: t('Interest definition'),
            time: getFormattedDate('21 June, 2022', i18n.language),
            id: NewFeaturesIdsMap.INTEREST_DEFINITION,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour suggestions alt')}
                src={t('Assets tour suggestions filename')}
                text={t('Suggestions tour step')}
              />
            ),
            title: t('AYO suggestions'),
            time: getFormattedDate('30 March, 2022', i18n.language),
            id: NewFeaturesIdsMap.LEADERSHIP_ATTRIBUTES_SUGGESTIONS,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour Notifications alt')}
                src={t('Assets tour Notifications filename(Student)')}
                text={t('Notifications tour step')}
              />
            ),
            title: t('Notifications'),
            time: getFormattedDate('2 March, 2022', i18n.language),
            id: NewFeaturesIdsMap.NOTIFICATIONS,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour LA-goals alt')}
                src={t('Assets tour LA-goals filename')}
                text={t('LA-goals tour step')}
              />
            ),
            title: t('Create a goal out of the rubric'),
            time: getFormattedDate('2 February, 2022', i18n.language),
            id: NewFeaturesIdsMap.LEADERSHIP_ATTRIBUTES_GOALS,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour achievements alt')}
                src={t('Assets tour achievements filename')}
                text={t('Achievements student tour step')}
              />
            ),
            title: t('Achievements & participation'),
            time: getFormattedDate('2 February, 2022', i18n.language),
            id: NewFeaturesIdsMap.ACHIEVEMENTS,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour evidences student alt')}
                src={t('Assets tour evidences student filename')}
                text={t('Evidences student tour step')}
              />
            ),
            title: t('Showing my growth'),
            time: getFormattedDate('5 January, 2022', i18n.language),
            id: NewFeaturesIdsMap.EVIDENCES,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour assessment tool alt')}
                src={t('Assets tour assessment tool filename')}
                text={t('Assessment tool tour step')}
              />
            ),
            title: t('Assessment'),
            time: getFormattedDate('8 December, 2021', i18n.language),
            id: NewFeaturesIdsMap.ASSESSMENT_TOOL,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour interests map alt')}
                src={t('Assets tour interests map filename')}
                text={t('Interests map tour step')}
              />
            ),
            title: t('Explore interests'),
            time: getFormattedDate('13 October, 2021', i18n.language),
            id: NewFeaturesIdsMap.INTERESTS_MAP_V2,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour potential student alt')}
                src={t('Assets tour potential student filename')}
                text={t('Potential tour student text')}
              />
            ),
            title: t('Potential'),
            time: getFormattedDate('13 October, 2021', i18n.language),
            id: NewFeaturesIdsMap.POTENTIAL,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour goals student alt')}
                src={t('Assets tour goals student filename')}
                text={t('Goals tour student text')}
              />
            ),
            title: t('Goals'),
            time: getFormattedDate('13 October, 2021', i18n.language),
            id: NewFeaturesIdsMap.GOALS,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour leadership attributes alt')}
                src={t('Assets tour leadership attributes filename')}
                text={t('Leadership attributes tour text')}
              />
            ),
            title: t('Leadership Attributes'),
            time: getFormattedDate('15 September, 2021', i18n.language),
            id: NewFeaturesIdsMap.LEADERSHIP_ATTRIBUTES,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour attachments alt')}
                src={t('Assets tour attachments filename')}
                text={t('Now you can add up to 3 attachments - paragraph')}
              />
            ),
            title: t('Add attachment(s) to your issue reports'),
            time: getFormattedDate('15 September, 2021', i18n.language),
            id: NewFeaturesIdsMap.SUPPORT_ATTACHMENTS,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour extracurriculars student alt')}
                src={t('Assets tour extracurriculars student filename')}
                text={t('Extracurriculars student tour text')}
              />
            ),
            title: t('Extracurriculars'),
            time: getFormattedDate('18 August, 2021', i18n.language),
            id: NewFeaturesIdsMap.EXTRACURRICULARS,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour support alt')}
                src={t('Assets tour support filename')}
                text={t('Support tour text')}
              />
            ),
            title: t('Support'),
            time: getFormattedDate('18 August, 2021', i18n.language),
            id: NewFeaturesIdsMap.SUPPORT,
          },
        ];
      case RolesMap.TEACHER:
        return [
          {
            mainContent: (
              <NewFeatureItem
                alt={t('nft.post_multiple_images.alt')}
                src={t('nft.post_multiple_images.src')}
                text={t('nft.post_multiple_images.text.educator')}
              />
            ),
            title: t('nft.post_multiple_images.title'),
            time: getFormattedDate('20 December, 2023', i18n.language),
            id: NewFeaturesIdsMap.POST_MULTIPLE_IMAGES,
            showHotspot: true,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('nft.staff_conversations.alt')}
                src={t('nft.staff_conversations.src')}
                text={t('nft.staff_conversations.text')}
              />
            ),
            title: t('nft.staff_conversations.title'),
            time: getFormattedDate('20 December, 2023', i18n.language),
            id: NewFeaturesIdsMap.STAFF_CONVERSATIONS,
            showHotspot: true,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('nft.club_tags.alt')}
                src={t('nft.club_tags.src')}
                text={t('nft.club_tags.text')}
              />
            ),
            title: t('nft.club_tags.title'),
            time: getFormattedDate('20 December, 2023', i18n.language),
            id: NewFeaturesIdsMap.CLUB_HUB_TAGS,
            showHotspot: true,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('nft.feed_events.alt.educator')}
                src={t('nft.feed_events.src.creator')}
                text={t('nft.feed_events.text.educator')}
              />
            ),
            title: t('nft.feed_events.title.creator'),
            time: getFormattedDate('20 December, 2023', i18n.language),
            id: NewFeaturesIdsMap.FEED_EVENTS,
            showHotspot: true,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('nft.club_events_creation.alt')}
                src={t('nft.club_events_creation.src')}
                text={t('nft.club_events_creation.text')}
              />
            ),
            title: t('nft.club_events_creation.title'),
            time: getFormattedDate('20 December, 2023', i18n.language),
            id: NewFeaturesIdsMap.CLUB_HUB_EVENTS,
            showHotspot: true,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour clubs student profile alt (teacher)')}
                src={t('Assets tour clubs student profile filename (teacher)')}
                text={t('Clubs student profile tour step (teacher)')}
              />
            ),
            title: t('Clubs'),
            time: getFormattedDate('22 November, 2023', i18n.language),
            id: NewFeaturesIdsMap.CLUB_HUB_STUDENT_PROFILE,
            showHotspot: false,
          },
          isFamilyFeedAvailable && {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour messages alt (teacher)')}
                src={t('Assets tour messages filename (teacher)')}
                text={t('Messages tour step (teacher)')}
              />
            ),
            title: t('Messages'),
            time: getFormattedDate('22 November, 2023', i18n.language),
            id: NewFeaturesIdsMap.POST_MESSAGES,
            showHotspot: false,
          },
          isFamilyFeedAvailable && {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour campus contact information alt (teacher)')}
                src={t('Assets tour campus contact information filename (teacher)')}
                text={t('Campus contact information tour step (teacher)')}
              />
            ),
            title: t('Campus contact information'),
            time: getFormattedDate('22 November, 2023', i18n.language),
            id: NewFeaturesIdsMap.POST_CAMPUS_CONTACTS,
            showHotspot: false,
          },
          isFamilyFeedAvailable && {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour post link alt')}
                src={t('Assets tour post link filename')}
                text={t('Post link tour step')}
              />
            ),
            title: t('Link in a post'),
            time: getFormattedDate('22 November, 2023', i18n.language),
            id: NewFeaturesIdsMap.POST_LINK,
            showHotspot: false,
          },
          isFamilyFeedAvailable && {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour family feed alt (teacher)')}
                src={t('Assets tour family feed filename (teacher)')}
                text={t('Family feed tour step (teacher)')}
              />
            ),
            title: t('Family feed'),
            time: getFormattedDate('2 August, 2023', i18n.language),
            id: NewFeaturesIdsMap.FAMILY_FEED,
            showHotspot: false,
          },
          isLessonPlanerAvailable && {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour lesson comment alt')}
                src={t('Assets tour lesson comment filename')}
                text={t('Add and receive comments tour step')}
              />
            ),
            title: t('Add and receive comments'),
            time: getFormattedDate('24 May, 2023', i18n.language),
            id: NewFeaturesIdsMap.LESSON_ADD_COMMENT,
            showHotspot: false,
          },
          isLessonPlanerAvailable && {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour lesson share alt')}
                src={t('Assets tour lesson share filename')}
                text={t('Share your lessons tour step')}
              />
            ),
            title: t('Share your lessons'),
            time: getFormattedDate('24 May, 2023', i18n.language),
            id: NewFeaturesIdsMap.LESSON_SHARE,
            showHotspot: false,
          },
          isLessonPlanerAvailable && {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour choices path alt (teacher)')}
                src={t('Assets tour choices path filename (teacher)')}
                text={t('Choices path tour step (teacher)')}
              />
            ),
            title: t('Choices path'),
            time: getFormattedDate('24 May, 2023', i18n.language),
            id: NewFeaturesIdsMap.CHOICES_PATH,
            showHotspot: false,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour recommendations alt (educator)')}
                src={t('Assets tour recommendations filename (educator)')}
                text={t('Recommendations tour step (educator)')}
              />
            ),
            title: t('Recommendation requests'),
            time: getFormattedDate('24 May, 2023', i18n.language),
            id: NewFeaturesIdsMap.PORTFOLIO_RECOMMENDATIONS,
            showHotspot: false,
          },
          isLessonPlanerAvailable && {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour lesson designer TEKS alt')}
                src={t('Assets tour lesson designer TEKS filename')}
                text={t('Lesson designer TEKS tour step')}
              />
            ),
            title: 'TEKS',
            time: getFormattedDate('26 April, 2023', i18n.language),
            id: NewFeaturesIdsMap.LESSON_DESIGNER_TEKS,
          },
          isLessonPlanerAvailable && {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour lesson designer Agenda boards alt')}
                src={t('Assets tour lesson designer Agenda boards filename')}
                text={t('Lesson designer Agenda boards tour step')}
              />
            ),
            title: t('Agenda Board'),
            time: getFormattedDate('26 April, 2023', i18n.language),
            id: NewFeaturesIdsMap.LESSON_DESIGNER_AGENDA_BOARDS,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour student mode alt')}
                src={t('Assets tour student mode filename')}
                text={t('Student mode tour step')}
              />
            ),
            title: t('Student mode'),
            time: getFormattedDate('26 April, 2023', i18n.language),
            id: NewFeaturesIdsMap.STUDENT_MODE,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour SEL adult assistance alt (teacher)')}
                src={t('Assets tour SEL adult assistance filename (teacher)')}
                text={t('SEL adult assistance a tour step (teacher)')}
              />
            ),
            title: t('Adult assistance need'),
            time: getFormattedDate('29 March, 2023', i18n.language),
            id: NewFeaturesIdsMap.SEL_ADULT_ASSISTANCE,
          },
          isLessonPlanerAvailable && {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour lessons repository search alt')}
                src={t('Assets tour lessons repository search filename')}
                text={t('Lessons repository search tour step')}
              />
            ),
            title: t('Lessons repository search'),
            time: getFormattedDate('29 March, 2023', i18n.language),
            id: NewFeaturesIdsMap.LESSONS_REPOSITORY_SEARCH,
          },
          isLessonPlanerAvailable && {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour lessons repository alt')}
                src={t('Assets tour lessons repository filename')}
                text={t('Lessons repository tour step')}
              />
            ),
            title: t('Lessons repository'),
            time: getFormattedDate('29 March, 2023', i18n.language),
            id: NewFeaturesIdsMap.LESSONS_REPOSITORY,
          },
          isLessonPlanerAvailable && {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour lesson designer new assignment types alt')}
                src={t('Assets tour lesson designer new assignment types filename')}
                text={t('Lesson designer new assignment types tour step')}
              />
            ),
            title: t('Assignment types'),
            time: getFormattedDate('29 March, 2023', i18n.language),
            id: NewFeaturesIdsMap.LESSON_DESIGNER_NEW_ASSIGNMENT_TYPES,
          },
          isLessonPlanerAvailable && {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour lesson designer publish and edit alt')}
                src={t('Assets tour lesson designer publish and edit filename')}
                text={t('Lesson designer publish and edit tour step')}
              />
            ),
            title: t('Publish and Edit'),
            time: getFormattedDate('29 March, 2023', i18n.language),
            id: NewFeaturesIdsMap.LESSON_DESIGNER_PUBLISH_EDIT_LESSON,
          },
          isLessonPlanerAvailable && {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour lesson designer materials alt')}
                src={t('Assets tour lesson designer materials filename')}
                text={t('Lesson designer materials tour step')}
              />
            ),
            title: t('Materials'),
            time: getFormattedDate('29 March, 2023', i18n.language),
            id: NewFeaturesIdsMap.LESSON_DESIGNER_MATERIALS,
          },
          isLessonPlanerAvailable && {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour lesson designer assignments alt')}
                src={t('Assets tour lesson designer assignments filename')}
                text={t('Lesson designer assignments tour step')}
              />
            ),
            title: t('Assignments'),
            time: getFormattedDate('29 March, 2023', i18n.language),
            id: NewFeaturesIdsMap.LESSON_DESIGNER_ASSIGNMENTS,
          },
          isLessonPlanerAvailable && {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour lesson designer alt')}
                src={t('Assets tour lesson designer filename')}
                text={t('Lesson designer tour step')}
              />
            ),
            title: t('Lesson designer'),
            time: getFormattedDate('29 March, 2023', i18n.language),
            id: NewFeaturesIdsMap.LESSON_DESIGNER,
          },
          isLessonPlanerAvailable && {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour lesson planner week month view alt')}
                src={t('Assets tour lesson planner week month view filename')}
                text={t('Lesson planner week month view tour step')}
              />
            ),
            title: t('Lesson planner views'),
            time: getFormattedDate('29 March, 2023', i18n.language),
            id: NewFeaturesIdsMap.LESSON_PLANNER_WEEK_MONTH_VIEW,
          },
          isLessonPlanerAvailable && {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour lesson planner alt')}
                src={t('Assets tour lesson planner filename')}
                text={t('Lesson planner tour step')}
              />
            ),
            title: t('Lesson planner'),
            time: getFormattedDate('29 March, 2023', i18n.language),
            id: NewFeaturesIdsMap.LESSON_PLANNER_ALL,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour campus highlights new initiatives alt')}
                src={t('Assets tour campus highlights new initiatives filename')}
                text={t('Campus highlights new initiatives tour step')}
              />
            ),
            title: t('Campus Highlights: additional initiatives'),
            time: getFormattedDate('1 March, 2023', i18n.language),
            id: NewFeaturesIdsMap.CAMPUS_HIGHLIGHTS_NEW_INITIATIVES,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour portfolio visibility alt (teacher)')}
                src={t('Assets tour portfolio visibility filename (teacher)')}
                text={t('Portfolio visibility tour step (teacher)')}
              />
            ),
            title: t('Portfolio'),
            time: getFormattedDate('1 February, 2023', i18n.language),
            id: NewFeaturesIdsMap.PORTFOLIO_VISIBILITY,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour Campus Highlights: Portfolio alt (teacher)')}
                src={t('Assets tour Campus Highlights: Portfolio filename (teacher)')}
                text={t('Campus Highlights: Portfolio tour step (teacher)')}
              />
            ),
            title: t('Campus Highlights: Portfolio'),
            time: getFormattedDate('1 February, 2023', i18n.language),
            id: NewFeaturesIdsMap.CAMPUS_HIGHLIGHTS_PORTFOLIO,
          },
          checkEnvironmentVariable('REACT_APP_IS_MOBILE_APP_AVAILABLE', 'true') && {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour mood check-ins alt(Teacher)')}
                src={t('Assets tour mood check-ins filename(Teacher)')}
                text={t('Mood check-ins tour step')}
              />
            ),
            title: t('Mood check-ins'),
            time: getFormattedDate('15 November, 2022', i18n.language),
            id: NewFeaturesIdsMap.MOOD_CHECK_INS,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour endorsements not student alt')}
                src={t('Assets tour endorsements not student filename')}
                text={t('Endorsements not student tour step')}
              />
            ),
            title: t('Endorsements'),
            time: getFormattedDate('14 September, 2022', i18n.language),
            id: NewFeaturesIdsMap.ENDORSEMENTS,
          },
          getIsCampusSelectionAvailable() && {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour multi campus support alt(Teacher)')}
                src={t('Assets tour multi campus support filename(Teacher)')}
                text={t('Multi campus support tour step')}
              />
            ),
            title: t('My campuses'),
            time: getFormattedDate('14 September, 2022', i18n.language),
            id: NewFeaturesIdsMap.MULTI_CAMPUS_SUPPORT,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour interest comment view alt')}
                src={t('Assets tour interest comment view filename')}
                text={t("Student's comments tour step")}
              />
            ),
            title: t("Student's comments"),
            time: getFormattedDate('14 September, 2022', i18n.language),
            id: NewFeaturesIdsMap.INTEREST_COMMENT,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour opportunities dedicated page alt')}
                src={t('Assets tour opportunities dedicated page filename')}
                text={t('Opportunities dedicated page tour step')}
              />
            ),
            title: t('Opportunities - Dedicated page'),
            time: getFormattedDate('17 August, 2022', i18n.language),
            id: NewFeaturesIdsMap.OPPORTUNITIES_DEDICATED_PAGE,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour aptitudes highlights alt')}
                src={t('Assets tour campus highlights aptitudes filename')}
                text={t('Aptitudes highlights tour step')}
              />
            ),
            title: t('Campus Highlights - Aptitudes'),
            time: getFormattedDate('20 July, 2022', i18n.language),
            id: NewFeaturesIdsMap.APTITUDES_HIGHLIGHTS,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour aptitudes alt')}
                src={t('Assets tour aptitudes filename parent educator')}
                text={t('Aptitudes tour step parent educator')}
              />
            ),
            title: t('Aptitudes'),
            time: getFormattedDate('20 July, 2022', i18n.language),
            id: NewFeaturesIdsMap.APTITUDES,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour interest definition alt')}
                src={t('Assets tour interest definition filename')}
                text={t('Interest definition tour step')}
              />
            ),
            title: t('Interest definition'),
            time: getFormattedDate('21 June, 2022', i18n.language),
            id: NewFeaturesIdsMap.INTEREST_DEFINITION,
          },
          {
            mainContent: (
              <Box>
                <NewFeatureItem
                  alt={t('Assets tour campus highlights alt')}
                  src={t('Assets tour campus highlights LA filename(Teacher)')}
                  subtitle={t('Leadership Attributes')}
                  text={t('Campus highlights tour step')}
                />
              </Box>
            ),
            title: t('Campus highlights'),
            time: getFormattedDate('2 March, 2022', i18n.language),
            id: NewFeaturesIdsMap.CAMPUS_HIGHLIGHTS,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour Notifications alt')}
                src={t('Assets tour Notifications filename(Teacher)')}
                text={t('Notifications tour step')}
              />
            ),
            title: t('Notifications'),
            time: getFormattedDate('2 March, 2022', i18n.language),
            id: NewFeaturesIdsMap.NOTIFICATIONS,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour goals educator alt')}
                src={t('Assets tour goals educator filename')}
                text={t('Goals tour educator text')}
              />
            ),
            title: t('Create goals'),
            time: getFormattedDate('2 February, 2022', i18n.language),
            id: NewFeaturesIdsMap.GOALS_CREATE_EDUCATORS,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour LA-goals alt')}
                src={t('Assets tour LA-goals filename (Teacher)')}
                text={t('LA-goals tour step')}
              />
            ),
            title: t('Create a goal out of the rubric'),
            time: getFormattedDate('2 February, 2022', i18n.language),
            id: NewFeaturesIdsMap.LEADERSHIP_ATTRIBUTES_GOALS,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour achievements alt')}
                src={t('Assets tour achievements filename')}
                text={t('Achievements teacher tour step')}
              />
            ),
            title: t('Achievements & participation'),
            time: getFormattedDate('2 February, 2022', i18n.language),
            id: NewFeaturesIdsMap.ACHIEVEMENTS,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour view evidences alt')}
                src={t('Assets tour view evidences filename')}
                text={t('View Evidences tour step')}
              />
            ),
            title: t('Evidence of growth'),
            time: getFormattedDate('5 January, 2022', i18n.language),
            id: NewFeaturesIdsMap.EVIDENCES,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour assessment tool alt')}
                src={t('Assets tour assessment tool filename')}
                text={t('Assessment tool tour step (Teacher)')}
              />
            ),
            title: t('Assessment'),
            time: getFormattedDate('8 December, 2021', i18n.language),
            id: NewFeaturesIdsMap.ASSESSMENT_TOOL,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour leadership attributes comments alt (Teacher)')}
                src={t('Assets tour leadership attributes comments filename (Teacher)')}
                text={t('Leadership attributes comments tour text (Teacher)')}
              />
            ),
            title: t('Comments'),
            time: getFormattedDate('10 November, 2021', i18n.language),
            id: NewFeaturesIdsMap.LEADERSHIP_ATTRIBUTES_COMMENTS,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour leadership attributes alt (Teacher)')}
                src={t('Assets tour leadership attributes filename (Teacher)')}
                text={t('Leadership attributes tour text (Teacher)')}
              />
            ),
            title: t('Leadership Attributes'),
            time: getFormattedDate('13 October, 2021', i18n.language),
            id: NewFeaturesIdsMap.LEADERSHIP_ATTRIBUTES,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour attachments alt')}
                src={t('Assets tour attachments filename')}
                text={t('Now you can add up to 3 attachments - paragraph')}
              />
            ),
            title: t('Add attachment(s) to your issue reports'),
            time: getFormattedDate('15 September, 2021', i18n.language),
            id: NewFeaturesIdsMap.SUPPORT_ATTACHMENTS,
          },
          {
            mainContent: <NewFeatureItem text={t('Extracurriculars teacher tour text')} />,
            title: t('Extracurriculars'),
            time: getFormattedDate('18 August, 2021', i18n.language),
            id: NewFeaturesIdsMap.EXTRACURRICULARS,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour support alt')}
                src={t('Assets tour support filename')}
                text={t('Support tour text')}
              />
            ),
            title: t('Support'),
            time: getFormattedDate('18 August, 2021', i18n.language),
            id: NewFeaturesIdsMap.SUPPORT,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour grouping naming interests alt')}
                src={t('Assets tour grouping naming interests filename')}
                text={t('Group naming and similar interests tour text')}
              />
            ),
            title: t('Group naming and similar interests'),
            time: getFormattedDate('18 August, 2021', i18n.language),
            id: NewFeaturesIdsMap.GROUPING_INTERESTS,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t(
                  "Select Actions, choose Edit groups, move student's profile card from one group to another, Save changes",
                )}
                src={t('Assets tour grouping filename')}
                text={t('Grouping allows you to create groups within a class - paragraph')}
              />
            ),
            title: t('Create and manage groups within your classes'),
            time: getFormattedDate('21 July, 2021', i18n.language),
            id: NewFeaturesIdsMap.GROUPING,
          },
        ];
      case RolesMap.GUARDIAN:
        return [
          {
            mainContent: (
              <NewFeatureItem
                alt={t('nft.club_post.alt.guardian')}
                src={t('nft.club_post.src.guardian')}
                text={t('nft.club_post.text.guardian')}
              />
            ),
            title: t('nft.club_post.title'),
            time: getFormattedDate('20 December, 2023', i18n.language),
            id: NewFeaturesIdsMap.CLUB_HUB_CREATE_POSTS,
            showHotspot: true,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('nft.post_multiple_images.alt')}
                src={t('nft.post_multiple_images.src')}
                text={t('nft.post_multiple_images.text.guardian')}
              />
            ),
            title: t('nft.post_multiple_images.title'),
            time: getFormattedDate('20 December, 2023', i18n.language),
            id: NewFeaturesIdsMap.POST_MULTIPLE_IMAGES,
            showHotspot: true,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('nft.feed_events.alt.guardian')}
                src={t('nft.feed_events.src.guardian')}
                text={t('nft.feed_events.text.guardian')}
              />
            ),
            title: t('nft.feed_events.title.viewer'),
            time: getFormattedDate('20 December, 2023', i18n.language),
            id: NewFeaturesIdsMap.FEED_EVENTS,
            showHotspot: true,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour clubs student profile alt (parent)')}
                src={t('Assets tour clubs student profile filename (parent)')}
                text={t('Clubs student profile tour step (parent)')}
              />
            ),
            title: t('Clubs'),
            time: getFormattedDate('22 November, 2023', i18n.language),
            id: NewFeaturesIdsMap.CLUB_HUB_STUDENT_PROFILE,
            showHotspot: false,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour activities search alt')}
                src={t('Assets tour activities search filename')}
                text={t('Activities search tour step')}
              />
            ),
            title: t('Activities search'),
            time: getFormattedDate('22 November, 2023', i18n.language),
            id: NewFeaturesIdsMap.ACTIVITIES_SEARCH,
            showHotspot: false,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour messages alt (parent)')}
                src={t('Assets tour messages filename (parent)')}
                text={t('Messages tour step (parent)')}
              />
            ),
            title: t('Messages'),
            time: getFormattedDate('22 November, 2023', i18n.language),
            id: NewFeaturesIdsMap.POST_MESSAGES,
            showHotspot: false,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour campus contact information alt (parent)')}
                src={t('Assets tour campus contact information filename (parent)')}
                text={t('Campus contact information tour step (parent)')}
              />
            ),
            title: t('Campus contact information'),
            time: getFormattedDate('22 November, 2023', i18n.language),
            id: NewFeaturesIdsMap.POST_CAMPUS_CONTACTS,
            showHotspot: false,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour family feed alt (parent)')}
                src={t('Assets tour family feed filename (parent)')}
                text={t('Family feed tour step (parent)')}
              />
            ),
            title: t('Family feed'),
            time: getFormattedDate('2 August, 2023', i18n.language),
            id: NewFeaturesIdsMap.FAMILY_FEED,
            showHotspot: false,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour portfolio visibility alt (parent)')}
                src={t('Assets tour portfolio visibility filename (parent)')}
                text={t('Portfolio visibility tour step (parent)')}
              />
            ),
            title: t('Portfolio'),
            time: getFormattedDate('1 February, 2023', i18n.language),
            id: NewFeaturesIdsMap.PORTFOLIO_VISIBILITY,
            showHotspot: false,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour endorsements not student alt')}
                src={t('Assets tour endorsements not student filename')}
                text={t('Endorsements not student tour step')}
              />
            ),
            title: t('Endorsements'),
            time: getFormattedDate('14 September, 2022', i18n.language),
            id: NewFeaturesIdsMap.ENDORSEMENTS,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour interest comment view alt')}
                src={t('Assets tour interest comment view filename')}
                text={t("Student's comments tour step")}
              />
            ),
            title: t("Student's comments"),
            time: getFormattedDate('14 September, 2022', i18n.language),
            id: NewFeaturesIdsMap.INTEREST_COMMENT,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour opportunities dedicated page alt')}
                src={t('Assets tour opportunities dedicated page filename')}
                text={t('Opportunities dedicated page tour step')}
              />
            ),
            title: t('Opportunities - Dedicated page'),
            time: getFormattedDate('17 August, 2022', i18n.language),
            id: NewFeaturesIdsMap.OPPORTUNITIES_DEDICATED_PAGE,
            showHotspot: false,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour aptitudes alt')}
                src={t('Assets tour aptitudes filename parent educator')}
                text={t('Aptitudes tour step parent educator')}
              />
            ),
            title: t('Aptitudes'),
            time: getFormattedDate('20 July, 2022', i18n.language),
            id: NewFeaturesIdsMap.APTITUDES,
            showHotspot: false,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour interest definition alt')}
                src={t('Assets tour interest definition filename')}
                text={t('Interest definition tour step')}
              />
            ),
            title: t('Interest definition'),
            time: getFormattedDate('21 June, 2022', i18n.language),
            id: NewFeaturesIdsMap.INTEREST_DEFINITION,
            showHotspot: false,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour leadership attributes alt(Parent)')}
                src={t('Assets tour leadership attributes filename(Parent)')}
                text={t('Leadership attributes tour text (Parent)')}
              />
            ),
            title: t('Leadership Attributes'),
            time: getFormattedDate('25 May, 2022', i18n.language),
            id: NewFeaturesIdsMap.ASSESSMENT_TOOL,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour view evidences alt')}
                src={t('Assets tour view evidences filename')}
                text={t('View Evidences tour step')}
              />
            ),
            title: t('Evidence of growth'),
            time: getFormattedDate('27 April, 2022', i18n.language),
            id: NewFeaturesIdsMap.EVIDENCES,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour Notifications alt')}
                src={t('Assets tour Notifications filename(Parent)')}
                text={t('Notifications tour step')}
              />
            ),
            title: t('Notifications'),
            time: getFormattedDate('2 March, 2022', i18n.language),
            id: NewFeaturesIdsMap.NOTIFICATIONS,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour achievements alt')}
                src={t('Assets tour achievements filename')}
                text={t('Achievements parent tour step')}
              />
            ),
            title: t('Achievements & participation'),
            time: getFormattedDate('2 February, 2022', i18n.language),
            id: NewFeaturesIdsMap.ACHIEVEMENTS,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour goals parent alt')}
                src={t('Assets tour goals parent filename')}
                text={t('Goals tour parent text')}
              />
            ),
            title: t('Goals'),
            time: getFormattedDate('13 October, 2021', i18n.language),
            id: NewFeaturesIdsMap.GOALS,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour leadership attributes alt')}
                src={t('Assets tour leadership attributes filename')}
                text={t('Leadership attributes tour text')}
              />
            ),
            title: t('Leadership Attributes'),
            time: getFormattedDate('15 September, 2021', i18n.language),
            id: NewFeaturesIdsMap.LEADERSHIP_ATTRIBUTES,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour attachments alt')}
                src={t('Assets tour attachments filename')}
                text={t('Now you can add up to 3 attachments - paragraph')}
              />
            ),
            title: t('Add attachment(s) to your issue reports'),
            time: getFormattedDate('15 September, 2021', i18n.language),
            id: NewFeaturesIdsMap.SUPPORT_ATTACHMENTS,
          },
          {
            mainContent: <NewFeatureItem text={t('Extracurriculars parent tour text')} />,
            title: t('Extracurriculars'),
            time: getFormattedDate('18 August, 2021', i18n.language),
            id: NewFeaturesIdsMap.EXTRACURRICULARS,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour support alt')}
                src={t('Assets tour support filename')}
                text={t('Support tour text')}
              />
            ),
            title: t('Support'),
            time: getFormattedDate('18 August, 2021', i18n.language),
            id: NewFeaturesIdsMap.SUPPORT,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t(
                  "Select Train AYO, select kid's profile, answer questions about the kid, we appreciate your input",
                )}
                src={t('Assets tour parent questionnaire filename')}
                text={t(
                  'Now you can go back to Train AYO and answer the questions that you previously skipped - paragraph',
                )}
              />
            ),
            title: t('Answer skipped questions in Train AYO'),
            time: getFormattedDate('21 July, 2021', i18n.language),
            id: NewFeaturesIdsMap.PARENT_QUESTIONNAIRE,
          },
        ];
      case RolesMap.ADMINISTRATOR:
        return [
          {
            mainContent: (
              <NewFeatureItem
                alt={t('nft.sel_district.alt')}
                src={t('nft.sel_district.src')}
                text={t('nft.sel_district.text')}
              />
            ),
            title: t('nft.sel_district.title'),
            time: getFormattedDate('20 December, 2023', i18n.language),
            id: NewFeaturesIdsMap.SEL_DISTRICT_REPORT,
            showHotspot: true,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('nft.post_multiple_images.alt')}
                src={t('nft.post_multiple_images.src')}
                text={t('nft.post_multiple_images.text.educator')}
              />
            ),
            title: t('nft.post_multiple_images.title'),
            time: getFormattedDate('20 December, 2023', i18n.language),
            id: NewFeaturesIdsMap.POST_MULTIPLE_IMAGES,
            showHotspot: true,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('nft.staff_conversations.alt')}
                src={t('nft.staff_conversations.src')}
                text={t('nft.staff_conversations.text')}
              />
            ),
            title: t('nft.staff_conversations.title'),
            time: getFormattedDate('20 December, 2023', i18n.language),
            id: NewFeaturesIdsMap.STAFF_CONVERSATIONS,
            showHotspot: true,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('nft.club_tags.alt')}
                src={t('nft.club_tags.src')}
                text={t('nft.club_tags.text')}
              />
            ),
            title: t('nft.club_tags.title'),
            time: getFormattedDate('20 December, 2023', i18n.language),
            id: NewFeaturesIdsMap.CLUB_HUB_TAGS,
            showHotspot: true,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('nft.feed_events.alt.educator')}
                src={t('nft.feed_events.src.creator')}
                text={t('nft.feed_events.text.educator')}
              />
            ),
            title: t('nft.feed_events.title.creator'),
            time: getFormattedDate('20 December, 2023', i18n.language),
            id: NewFeaturesIdsMap.FEED_EVENTS,
            showHotspot: true,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('nft.club_events_creation.alt')}
                src={t('nft.club_events_creation.src')}
                text={t('nft.club_events_creation.text')}
              />
            ),
            title: t('nft.club_events_creation.title'),
            time: getFormattedDate('20 December, 2023', i18n.language),
            id: NewFeaturesIdsMap.CLUB_HUB_EVENTS,
            showHotspot: true,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour clubs student profile alt (teacher)')}
                src={t('Assets tour clubs student profile filename (teacher)')}
                text={t('Clubs student profile tour step (teacher)')}
              />
            ),
            title: t('Clubs'),
            time: getFormattedDate('22 November, 2023', i18n.language),
            id: NewFeaturesIdsMap.CLUB_HUB_STUDENT_PROFILE,
            showHotspot: false,
          },
          isFamilyFeedAvailable && {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour messages alt (teacher)')}
                src={t('Assets tour messages filename (teacher)')}
                text={t('Messages tour step (teacher)')}
              />
            ),
            title: t('Messages'),
            time: getFormattedDate('22 November, 2023', i18n.language),
            id: NewFeaturesIdsMap.POST_MESSAGES,
            showHotspot: false,
          },
          isFamilyFeedAvailable && {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour campus contact information alt (teacher)')}
                src={t('Assets tour campus contact information filename (teacher)')}
                text={t('Campus contact information tour step (teacher)')}
              />
            ),
            title: t('Campus contact information'),
            time: getFormattedDate('22 November, 2023', i18n.language),
            id: NewFeaturesIdsMap.POST_CAMPUS_CONTACTS,
            showHotspot: false,
          },
          isFamilyFeedAvailable && {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour post link alt')}
                src={t('Assets tour post link filename')}
                text={t('Post link tour step')}
              />
            ),
            title: t('Link in a post'),
            time: getFormattedDate('22 November, 2023', i18n.language),
            id: NewFeaturesIdsMap.POST_LINK,
            showHotspot: false,
          },
          isFamilyFeedAvailable && {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour family feed alt (teacher)')}
                src={t('Assets tour family feed filename (teacher)')}
                text={t('Family feed tour step (teacher)')}
              />
            ),
            title: t('Family feed'),
            time: getFormattedDate('2 August, 2023', i18n.language),
            id: NewFeaturesIdsMap.FAMILY_FEED,
            showHotspot: false,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour recommendations alt (educator)')}
                src={t('Assets tour recommendations filename (educator)')}
                text={t('Recommendations tour step (educator)')}
              />
            ),
            title: t('Recommendation requests'),
            time: getFormattedDate('24 May, 2023', i18n.language),
            id: NewFeaturesIdsMap.PORTFOLIO_RECOMMENDATIONS,
            showHotspot: false,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour SEL adult assistance alt (administrator)')}
                src={t('Assets tour SEL adult assistance filename (administrator)')}
                text={t('SEL adult assistance a tour step (administrator)')}
              />
            ),
            title: t('Adult assistance need'),
            time: getFormattedDate('29 March, 2023', i18n.language),
            id: NewFeaturesIdsMap.SEL_ADULT_ASSISTANCE,
            showHotspot: false,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour portfolio visibility alt (admin)')}
                src={t('Assets tour portfolio visibility filename (admin)')}
                text={t('Portfolio visibility tour step (admin)')}
              />
            ),
            title: t('Portfolio'),
            time: getFormattedDate('1 February, 2023', i18n.language),
            id: NewFeaturesIdsMap.PORTFOLIO_VISIBILITY,
            showHotspot: false,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour Campus Highlights: Portfolio alt (admin)')}
                src={t('Assets tour Campus Highlights: Portfolio filename (admin)')}
                text={t('Campus Highlights: Portfolio tour step (admin)')}
              />
            ),
            title: t('Campus Highlights: Portfolio'),
            time: getFormattedDate('1 February, 2023', i18n.language),
            id: NewFeaturesIdsMap.CAMPUS_HIGHLIGHTS_PORTFOLIO,
            showHotspot: false,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour Campus Highlights: Portfolio alt (admin)')}
                src={t('Assets tour Campus Highlights: Portfolio filename (admin)')}
                text={t('Campus Highlights: Portfolio tour step (admin)')}
              />
            ),
            title: t('Campus Highlights: Portfolio'),
            time: getFormattedDate('1 February, 2023', i18n.language),
            id: NewFeaturesIdsMap.CAMPUS_HIGHLIGHTS_PORTFOLIO,
            showHotspot: false,
          },
          currentCampusSchoolLevel === SchoolLevels.ELEMENTARY && {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour endorsements highlights (Elementary) alt')}
                src={t('Assets tour endorsements highlights (Elementary) filename')}
                text={t('Endorsements highlights tour step')}
              />
            ),
            title: t('Campus highlights - Endorsements'),
            time: getFormattedDate('7 December, 2022', i18n.language),
            id: NewFeaturesIdsMap.ENDORSEMENTS_HIGHLIGHTS,
          },
          checkEnvironmentVariable('REACT_APP_IS_MOBILE_APP_AVAILABLE', 'true') && {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour mood check-ins alt(Administrator)')}
                src={t('Assets tour mood check-ins filename(Administrator)')}
                text={t('Mood check-ins tour step')}
              />
            ),
            title: t('Mood check-ins'),
            time: getFormattedDate('15 November, 2022', i18n.language),
            id: NewFeaturesIdsMap.MOOD_CHECK_INS,
            showHotspot: false,
          },
          (isUserDataManager || isUserPrivilegesManager) && {
            mainContent: (
              <NewFeatureItem
                alt={t('Manage user rights alt')}
                src={t('Manage user rights filename')}
                text={t('Manage user rights tour step')}
              />
            ),
            title: t('Manage user rights'),
            time: getFormattedDate('14 September, 2022', i18n.language),
            id: NewFeaturesIdsMap.USER_MANAGEMENT,
          },
          (currentCampusSchoolLevel === SchoolLevels.MIDDLE ||
            currentCampusSchoolLevel === SchoolLevels.HIGH) && {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour endorsements highlights alt')}
                src={t('Assets tour endorsements highlights filename')}
                text={t('Endorsements highlights tour step')}
              />
            ),
            title: t('Campus highlights - Endorsements'),
            time: getFormattedDate('14 September, 2022', i18n.language),
            id: NewFeaturesIdsMap.ENDORSEMENTS_HIGHLIGHTS,
            showHotspot: false,
          },
          currentCampusSchoolLevel === SchoolLevels.MIDDLE && {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour endorsements analytics widget alt')}
                src={t('Assets tour endorsements analytics widget filename(Middle school)')}
                text={t('Endorsements analytics widget tour step')}
              />
            ),
            title: t('Analytics - Endorsements'),
            time: getFormattedDate('14 September, 2022', i18n.language),
            id: NewFeaturesIdsMap.ENDORSEMENTS_ANALYTICS_WIDGET,
          },
          currentCampusSchoolLevel === SchoolLevels.HIGH && {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour endorsements analytics widget alt')}
                src={t('Assets tour endorsements analytics widget filename(High school)')}
                text={t('Endorsements analytics widget tour step')}
              />
            ),
            title: t('Analytics - Endorsements'),
            time: getFormattedDate('14 September, 2022', i18n.language),
            id: NewFeaturesIdsMap.ENDORSEMENTS_ANALYTICS_WIDGET,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour endorsements not student alt')}
                src={t('Assets tour endorsements not student filename')}
                text={t('Endorsements not student tour step')}
              />
            ),
            title: t("Student's profile - Endorsements"),
            time: getFormattedDate('14 September, 2022', i18n.language),
            id: NewFeaturesIdsMap.ENDORSEMENTS,
          },
          getIsCampusSelectionAvailable() && {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour multi campus support alt(Administrator)')}
                src={t('Assets tour multi campus support filename(Administrator)')}
                text={t('Multi campus support tour step')}
              />
            ),
            title: t('My campuses'),
            time: getFormattedDate('14 September, 2022', i18n.language),
            id: NewFeaturesIdsMap.MULTI_CAMPUS_SUPPORT,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour interest comment view alt')}
                src={t('Assets tour interest comment view filename')}
                text={t("Student's comments tour step")}
              />
            ),
            title: t("Student's comments"),
            time: getFormattedDate('14 September, 2022', i18n.language),
            id: NewFeaturesIdsMap.INTEREST_COMMENT,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour opportunities dedicated page alt')}
                src={t('Assets tour opportunities dedicated page filename')}
                text={t('Opportunities dedicated page tour step')}
              />
            ),
            title: t('Opportunities - Dedicated page'),
            time: getFormattedDate('17 August, 2022', i18n.language),
            id: NewFeaturesIdsMap.OPPORTUNITIES_DEDICATED_PAGE,
            showHotspot: false,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour aptitudes highlights alt')}
                src={t('Assets tour campus highlights aptitudes filename')}
                text={t('Aptitudes highlights tour step')}
              />
            ),
            title: t('Campus Highlights - Aptitudes'),
            time: getFormattedDate('20 July, 2022', i18n.language),
            id: NewFeaturesIdsMap.APTITUDES_HIGHLIGHTS,
            showHotspot: false,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour aptitudes alt')}
                src={t('Assets tour aptitudes filename parent educator')}
                text={t('Aptitudes tour step parent educator')}
              />
            ),
            title: t('Aptitudes'),
            time: getFormattedDate('20 July, 2022', i18n.language),
            id: NewFeaturesIdsMap.APTITUDES,
            showHotspot: false,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour interest definition alt')}
                src={t('Assets tour interest definition filename')}
                text={t('Interest definition tour step')}
              />
            ),
            title: t('Interest definition'),
            time: getFormattedDate('21 June, 2022', i18n.language),
            id: NewFeaturesIdsMap.INTEREST_DEFINITION,
            showHotspot: false,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour view evidences alt')}
                src={t('Assets tour view evidences filename')}
                text={t('View Evidences tour step')}
              />
            ),
            title: t('Evidence of growth'),
            time: getFormattedDate('27 April, 2022', i18n.language),
            id: NewFeaturesIdsMap.EVIDENCES,
          },
          {
            mainContent: (
              <Box>
                <NewFeatureItem
                  alt={t('Assets tour campus highlights alt')}
                  src={t('Assets tour campus highlights interests filename(Administrator)')}
                  subtitle={t('Interests')}
                  text={t('Campus highlights tour step')}
                />
                <NewFeatureItem
                  alt={t('Assets tour campus highlights alt')}
                  src={t('Assets tour campus highlights LA filename(Administrator)')}
                  subtitle={t('Leadership Attributes')}
                />
                <NewFeatureItem
                  alt={t('Assets tour campus highlights alt')}
                  src={t('Assets tour campus highlights goals filename')}
                  subtitle={t('Goals')}
                />
              </Box>
            ),
            title: t('Campus highlights'),
            time: getFormattedDate('2 March, 2022', i18n.language),
            id: NewFeaturesIdsMap.CAMPUS_HIGHLIGHTS,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour analytics initiatives alt')}
                src={t('Assets tour analytics initiatives filename')}
                text={t('Analytics initiatives tour step')}
              />
            ),
            title: t('analytics.initiatives'),
            time: getFormattedDate('2 March, 2022', i18n.language),
            id: NewFeaturesIdsMap.ANALYTICS_INITIATIVES,
          },
          {
            mainContent: (
              <NewFeatureItem
                alt={t('Assets tour Notifications alt')}
                src={t('Assets tour Notifications filename(Administrator)')}
                text={t('Notifications tour step')}
              />
            ),
            title: t('Notifications'),
            time: getFormattedDate('2 March, 2022', i18n.language),
            id: NewFeaturesIdsMap.NOTIFICATIONS,
          },
        ];
      default:
        return [];
    }
  }, [
    userState.profile?.role,
    userState?.isEndorsementsAvailable,
    t,
    i18n.language,
    isFamilyFeedAvailable,
    isLessonPlanerAvailable,
    getIsCampusSelectionAvailable,
    currentCampusSchoolLevel,
    isUserDataManager,
    isUserPrivilegesManager,
  ]);

  return tourItems;
};

export default NewFeaturesItemsProvider;
