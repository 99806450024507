import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Box, DialogContent, DialogActions, useMediaQuery, useTheme } from '@mui/material';

import { Dialog, Typography } from '../../../atoms';
import { PopupActionsButtons } from '../../../moleculas';
import { ReactComponent as EvidenceImage } from '../../../../resources/images/evidence_of_growth_goal.svg';

export const ActionStepEvidenceAddDialog = ({ isOpen, onClose, onSubmit }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const isWidthUpXl = useMediaQuery(theme.breakpoints.up('xl'));

  const onSubmitHandler = useCallback(() => {
    onSubmit();
  }, [onSubmit]);

  const onCloseHandler = useCallback(() => {
    onClose(null);
  }, [onClose]);

  return (
    <Dialog
      className="ayo-action-step-reflection-add-dialog"
      fullScreen={!isWidthUpSm}
      gaLabel="Action step evidence add dialog"
      maxWidth={isWidthUpXl && 700}
      onClose={onCloseHandler}
      open={isOpen}
    >
      <DialogContent>
        <Box alignItems="center" display="flex" flexDirection="column" mb={3}>
          <EvidenceImage />
          <Box
            alignItems="flex-start"
            display="flex"
            flexDirection="column"
            mb={2}
            mt={3}
            width="100%"
          >
            <Typography align="left" component="h2" paragraph variant="h2">
              {t('Good job!')}
            </Typography>
            <Typography align="left" component="p" variant="body2">
              {t('Would you like to add evidence of your success?')}
            </Typography>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <PopupActionsButtons
          primaryButtonGaLabel="Add evidence"
          primaryButtonHandler={onSubmitHandler}
          primaryButtonText={t('Add evidence')}
          secondaryButtonGaLabel="Cancel"
          secondaryButtonHandler={onCloseHandler}
          secondaryButtonText={t('Cancel')}
        />
      </DialogActions>
    </Dialog>
  );
};

ActionStepEvidenceAddDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default ActionStepEvidenceAddDialog;
