/* eslint-disable react/destructuring-assignment */
import { Box, Divider } from '@mui/material';
import classNames from 'classnames';
import React, { useMemo, useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Bar, BarChart, LabelList, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import PropTypes from 'prop-types';

import {
  GaActions,
  GaCategories,
  HighlightsInitiatives,
  KeyboardMap,
  SchoolLevels,
  StudentFiltersSpecialValues,
  StudentFiltersTypes,
} from '../../../../constants/enums';
import { useSearchParams } from '../../../../hooks';
import { FilterUpdateActions } from '../../../../hooks/use-filter-update-state/useFilterUpdateState';
import {
  GA,
  getFilterValueParts,
  isFilterItemDisabled,
  isSimpleFilterSelected,
  transformSearchToObject,
  updatePendingFilter,
  updateSimpleFilter,
} from '../../../../utils';
import { Button, Tooltip, Typography } from '../../../atoms';
import { InformationalCaption } from '../../../moleculas';
import MultilineAxisLabel from '../components/multiline-axis-label/MultilineAxisLabel';
import TooltipedLabel from '../components/single-bar-label/TooltipedLabel';
import SingleBarShape from '../components/single-bar-shape/SingleBarShape';
import { ReactComponent as BackIcon } from '../../../../resources/icons/chevron_left.svg';
import { UserContext } from '../../../../context';

const BAR_MARGIN = { top: 64, bottom: 64, left: 16 };

const getTooltipText = (withDrilldown) => (payload, totalStudentsCount, t) =>
  (
    <>
      {`${payload.name} (${payload.value})`}
      <br />
      {!!totalStudentsCount &&
        !withDrilldown &&
        payload.value === totalStudentsCount &&
        t("Can't filter by all students")}
      {!withDrilldown && payload.value === 0 && t("Can't filter by 0 students")}
    </>
  );

const EndorsementsChart = ({
  chartData,
  onBarClick,
  onLabelClick,
  totalStudentsCount,
  isActive,
  width,
  isDotted,
  withDrilldown,
  color,
}) => (
  <ResponsiveContainer width={width}>
    <BarChart data={chartData} margin={BAR_MARGIN}>
      <XAxis
        axisLine={false}
        dataKey="name"
        interval={0}
        tick={<MultilineAxisLabel />}
        tickLine={false}
        type="category"
      />
      <YAxis hide />
      <Bar
        dataKey="value"
        fill={color}
        isAnimationActive={false}
        maxBarSize={40}
        onClick={onBarClick}
        radius={6}
        shape={
          <SingleBarShape
            getTooltipText={getTooltipText(withDrilldown)}
            isActive={isActive}
            isDotted={isDotted}
            onClick={onBarClick}
            totalStudentsCount={totalStudentsCount}
            withDrilldown={withDrilldown}
          />
        }
      >
        <LabelList
          className="bar-label-outside"
          content={
            <TooltipedLabel
              getTooltipText={getTooltipText(withDrilldown)}
              onClick={onLabelClick}
              totalStudentsCount={totalStudentsCount}
            />
          }
          offset={16}
          position="top"
        />
      </Bar>
    </BarChart>
  </ResponsiveContainer>
);

EndorsementsChart.propTypes = {
  chartData: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  onBarClick: PropTypes.func.isRequired,
  onLabelClick: PropTypes.func.isRequired,
  totalStudentsCount: PropTypes.number.isRequired,
  isActive: PropTypes.func,
  width: PropTypes.string,
  isDotted: PropTypes.bool,
  withDrilldown: PropTypes.bool,
  color: PropTypes.string,
};

EndorsementsChart.defaultProps = {
  color: '#F45BD2',
  isActive: () => false,
  width: '100%',
  isDotted: false,
  withDrilldown: false,
};

const EndorsementsDrilldownChart = ({
  drillDownStats,
  stats,
  totalStudentsCount,
  filterCategory,
  isDotted,
  onFiltersUpdate,
  gaLabel,
}) => {
  const search = useSearchParams();

  const filters = useMemo(() => transformSearchToObject(search), [search]);

  const history = useHistory();

  const { t } = useTranslation();

  const onBarActivation = (selectedKey) => {
    if (isFilterItemDisabled(drillDownStats[selectedKey].count, totalStudentsCount)) return;
    const newFilters = updateSimpleFilter(
      selectedKey,
      filterCategory,
      filters,
      Object.keys(drillDownStats),
      stats,
    );
    history.replace({ search: new URLSearchParams(newFilters).toString() });
    onFiltersUpdate({
      type: FilterUpdateActions.UPDATE,
      data: {
        filters,
        value: selectedKey,
        category: filterCategory,
        stats,
        t,
      },
    });
    GA.logInteraction({
      category: GaCategories.BEHAVIOR,
      action: GaActions.GRAPH_PART_CLICKED,
      label: `Endorsements Highlights Graph - ${gaLabel} - ${selectedKey}`,
    });
  };

  const onBarClick = (barData) => onBarActivation(barData.key);

  const onLabelClick = (name) => {
    const clickedKey = Object.keys(drillDownStats).find(
      (selectedKey) => drillDownStats[selectedKey].name === name,
    );
    onBarActivation(clickedKey);
    GA.logInteraction({
      category: GaCategories.BEHAVIOR,
      action: GaActions.GRAPH_PART_CLICKED,
      label: `Endorsements Highlights Graph - ${gaLabel} - ${clickedKey}`,
    });
  };

  const barsData = Object.entries(drillDownStats).reduce(
    (acc, [selectedKey, selectedValue]) => [
      ...acc,
      { name: selectedValue.name.split('(')[0], value: selectedValue.count, key: selectedKey },
    ],
    [],
  );

  const { filtersList } = useMemo(() => getFilterValueParts(filters, stats), [filters, stats]);

  return (
    <EndorsementsChart
      chartData={barsData}
      filterCategory={filterCategory}
      isActive={(payload) =>
        isSimpleFilterSelected(payload.key, filterCategory, filters.filterCategory, filtersList)
      }
      isDotted={isDotted}
      onBarClick={onBarClick}
      onLabelClick={onLabelClick}
      totalStudentsCount={totalStudentsCount}
      width="100%"
    />
  );
};

EndorsementsDrilldownChart.propTypes = {
  drillDownStats: PropTypes.instanceOf(Object).isRequired,
  stats: PropTypes.instanceOf(Object).isRequired,
  totalStudentsCount: PropTypes.number.isRequired,
  filterCategory: PropTypes.string.isRequired,
  isDotted: PropTypes.bool,
  onFiltersUpdate: PropTypes.func.isRequired,
  gaLabel: PropTypes.string.isRequired,
};

EndorsementsDrilldownChart.defaultProps = {
  isDotted: false,
};

const InterestedHighlights = ({ stats, onFiltersUpdate, totalStudentsCount }) => {
  const interestedStats = stats.endorsements.interested;

  return (
    <EndorsementsDrilldownChart
      drillDownStats={interestedStats}
      filterCategory={HighlightsInitiatives.ENDORSEMENTS_INTERESTED}
      gaLabel="Interested"
      onFiltersUpdate={onFiltersUpdate}
      stats={stats}
      totalStudentsCount={totalStudentsCount}
    />
  );
};

InterestedHighlights.propTypes = {
  stats: PropTypes.instanceOf(Object).isRequired,
  totalStudentsCount: PropTypes.number.isRequired,
  onFiltersUpdate: PropTypes.func.isRequired,
};

const SelectedHighlights = ({ stats, onFiltersUpdate, totalStudentsCount }) => {
  const selectedStats = stats.endorsements.selected;

  return (
    <EndorsementsDrilldownChart
      drillDownStats={selectedStats}
      filterCategory={HighlightsInitiatives.ENDORSEMENTS}
      gaLabel="Selected"
      onFiltersUpdate={onFiltersUpdate}
      stats={stats}
      totalStudentsCount={totalStudentsCount}
    />
  );
};

SelectedHighlights.propTypes = {
  stats: PropTypes.instanceOf(Object).isRequired,
  totalStudentsCount: PropTypes.number.isRequired,
  onFiltersUpdate: PropTypes.func.isRequired,
};

const TypeHighlights = ({ stats, onFiltersUpdate, totalStudentsCount }) => {
  const typeStats = stats.endorsements.switchedByType;

  return (
    <EndorsementsDrilldownChart
      drillDownStats={typeStats}
      filterCategory={HighlightsInitiatives.ENDORSEMENTS_TYPES}
      gaLabel="Switched by type"
      isDotted
      onFiltersUpdate={onFiltersUpdate}
      stats={stats}
      totalStudentsCount={totalStudentsCount}
    />
  );
};

TypeHighlights.propTypes = {
  stats: PropTypes.instanceOf(Object).isRequired,
  totalStudentsCount: PropTypes.number.isRequired,
  onFiltersUpdate: PropTypes.func.isRequired,
};

const ReasonsHighlights = ({ stats, onFiltersUpdate }) => {
  const reasonStats = stats.endorsements.switchedByReason;
  const search = useSearchParams();

  const filters = useMemo(() => transformSearchToObject(search), [search]);
  const { filtersList } = useMemo(() => getFilterValueParts(filters, stats), [filters, stats]);
  const history = useHistory();
  const { t } = useTranslation();
  const onClick = (key, stat) => () => {
    if (isFilterItemDisabled(stat, 0)) return;
    const newFilters = updateSimpleFilter(
      key,
      HighlightsInitiatives.ENDORSEMENTS_REASONS,
      filters,
      Object.keys(reasonStats),
    );
    history.replace({ search: new URLSearchParams(newFilters).toString() });
    GA.logInteraction({
      category: GaCategories.BEHAVIOR,
      action: GaActions.GRAPH_PART_CLICKED,
      label: `Endorsements Highlights Graph - Reasons - ${key}`,
    });
    onFiltersUpdate({
      type: FilterUpdateActions.UPDATE,
      data: {
        filters,
        value: key,
        category: HighlightsInitiatives.ENDORSEMENTS_REASONS,
        stats,
        t,
      },
    });
  };
  return (
    <Box className="reasons-grid">
      {Object.entries(reasonStats).map(([key, value]) => {
        const isDisabled = !value.count;
        return (
          <Tooltip key={key} title={isDisabled ? t("Can't filter by 0 students") : ''}>
            <Box
              aria-disabled={isDisabled}
              className={classNames('reason', {
                active: isSimpleFilterSelected(
                  key,
                  HighlightsInitiatives.ENDORSEMENTS_REASONS,
                  filters.filterCategory,
                  filtersList,
                ),
                disabled: isDisabled,
              })}
              onClick={onClick(key, value.count)}
              onKeyDown={(e) => {
                if (e.key === KeyboardMap.ENTER || e.key === KeyboardMap.SPACE) {
                  e.preventDefault();
                  onClick(key, value.count)();
                }
              }}
              role="button"
              tabIndex={isDisabled ? null : 0}
            >
              <Box className="reason__content">
                <Typography className="reason__stat" variant="h2">
                  {value.count}
                </Typography>
                <Typography className="reason__text" variant="body2">
                  {value.name}
                </Typography>
              </Box>
            </Box>
          </Tooltip>
        );
      })}
    </Box>
  );
};

ReasonsHighlights.propTypes = {
  stats: PropTypes.instanceOf(Object).isRequired,
  onFiltersUpdate: PropTypes.func.isRequired,
};

const EndorsementsDrilldowns = {
  SELECTED: 'SELECTED',
  TYPE: 'TYPE',
  REASON: 'REASON',
  INTERESTED: 'INTERESTED',
};

const EndorsementsHighlights = ({ stats, totalStudentsCount, onFiltersUpdate }) => {
  const endorsementsStats = stats.endorsements;
  const { selectedStudents, switchedByReasonStudents, switchedByTypeStudents, interestedStudents } =
    endorsementsStats;

  const search = useSearchParams();

  const filters = useMemo(() => transformSearchToObject(search), [search]);
  const { filtersList, filterCriterion } = useMemo(
    () => getFilterValueParts(filters, stats),
    [filters, stats],
  );

  const { t } = useTranslation();

  const interestedBarLabel = t('Interested');
  const selectedBarLabel = t('analytics.highlights.endorsements.SELECTED');
  const switchedByTypeLabel = t('Switched (by type)');
  const switchedByReasonLabel = t('Switched (by reason)');

  const labelsDrilldowns = {
    [interestedBarLabel]: EndorsementsDrilldowns.INTERESTED,
    [selectedBarLabel]: EndorsementsDrilldowns.SELECTED,
    [switchedByTypeLabel]: EndorsementsDrilldowns.TYPE,
    [switchedByReasonLabel]: EndorsementsDrilldowns.REASON,
  };

  const { state: userState } = useContext(UserContext);

  const isElementarySchoolEducator =
    userState.profile?.currentCampusSchoolLevel === SchoolLevels.ELEMENTARY;

  const switchedBarData = [
    {
      name: interestedBarLabel,
      value: interestedStudents,
      drilldown: EndorsementsDrilldowns.INTERESTED,
    },
    !isElementarySchoolEducator && {
      name: selectedBarLabel,
      value: selectedStudents,
      drilldown: EndorsementsDrilldowns.SELECTED,
    },
    !isElementarySchoolEducator && {
      name: switchedByTypeLabel,
      value: switchedByTypeStudents,
      drilldown: EndorsementsDrilldowns.TYPE,
    },
    !isElementarySchoolEducator && {
      name: switchedByReasonLabel,
      value: switchedByReasonStudents,
      drilldown: EndorsementsDrilldowns.REASON,
    },
  ].filter(Boolean);

  const pendingBarData = [{ name: t('Pending'), value: endorsementsStats.pending }];

  const history = useHistory();

  const onPendingBarClick = () => {
    if (isFilterItemDisabled(endorsementsStats.pending, totalStudentsCount)) return;
    const newFilters = updatePendingFilter(filters, HighlightsInitiatives.ENDORSEMENTS, stats);
    history.replace({ search: new URLSearchParams(newFilters).toString() });
    onFiltersUpdate({
      type: FilterUpdateActions.UPDATE,
      data: {
        filters,
        value: StudentFiltersSpecialValues.PENDING,
        category: HighlightsInitiatives.ENDORSEMENTS,
        stats,
        t,
      },
    });
    GA.logInteraction({
      category: GaCategories.BEHAVIOR,
      action: GaActions.GRAPH_PART_CLICKED,
      label: `Endorsements Highlights Graph - ${StudentFiltersSpecialValues.PENDING}}`,
    });
  };

  const [activeDrilldown, setActiveDrilldown] = useState(null);

  const onSwitchedBarClick = (payload) => {
    setActiveDrilldown(payload.drilldown);
    GA.logInteraction({
      category: GaCategories.BEHAVIOR,
      action: GaActions.GRAPH_PART_CLICKED,
      label: `Endorsements Highlights Graph - ${payload.drilldown}`,
    });
  };

  const onSwitchedLabelClick = (label) => {
    const clickedDrilldown = labelsDrilldowns[label];
    setActiveDrilldown(clickedDrilldown);
    GA.logInteraction({
      category: GaCategories.BEHAVIOR,
      action: GaActions.GRAPH_PART_CLICKED,
      label: `Endorsements Highlights Graph - ${clickedDrilldown}}`,
    });
  };

  useEffect(() => {
    if (
      filters.filterCategory === HighlightsInitiatives.ENDORSEMENTS &&
      filterCriterion === StudentFiltersTypes.INCLUDE
    ) {
      setActiveDrilldown(EndorsementsDrilldowns.SELECTED);
    }
    if (filters.filterCategory === HighlightsInitiatives.ENDORSEMENTS_TYPES) {
      setActiveDrilldown(EndorsementsDrilldowns.TYPE);
    }
    if (filters.filterCategory === HighlightsInitiatives.ENDORSEMENTS_REASONS) {
      setActiveDrilldown(EndorsementsDrilldowns.REASON);
    }
    if (filters.filterCategory === HighlightsInitiatives.ENDORSEMENTS_INTERESTED) {
      setActiveDrilldown(EndorsementsDrilldowns.INTERESTED);
    }
  }, [filters, filterCriterion]);

  return (
    <div className="endorsements-highlights">
      {!activeDrilldown && (
        <Box display="flex">
          <InformationalCaption
            isLight
            showTooltip
            title={
              isElementarySchoolEducator
                ? 'Endorsements Highlights info elementary'
                : 'Endorsements Highlights info full'
            }
            tooltipPlacement="right"
          />
          <Box display="flex" height={350} justifyContent="space-between" width="100%">
            <Box display="flex" flexBasis="80%" height="100%" justifyContent="space-between">
              <EndorsementsChart
                chartData={switchedBarData.filter(
                  ({ drilldown }) =>
                    drilldown === EndorsementsDrilldowns.INTERESTED ||
                    drilldown === EndorsementsDrilldowns.SELECTED,
                )}
                onBarClick={onSwitchedBarClick}
                onLabelClick={onSwitchedLabelClick}
                totalStudentsCount={0}
                width={isElementarySchoolEducator ? '100%' : '50%'}
                withDrilldown
              />
              {!isElementarySchoolEducator && (
                <EndorsementsChart
                  chartData={switchedBarData.filter(
                    ({ drilldown }) =>
                      drilldown === EndorsementsDrilldowns.TYPE ||
                      drilldown === EndorsementsDrilldowns.REASON,
                  )}
                  isDotted
                  onBarClick={onSwitchedBarClick}
                  onLabelClick={onSwitchedLabelClick}
                  totalStudentsCount={0}
                  width="50%"
                  withDrilldown
                />
              )}
            </Box>
            {!isElementarySchoolEducator && (
              <>
                <Divider flexItem orientation="vertical" />
                <EndorsementsChart
                  chartData={pendingBarData}
                  color="#FABBEC"
                  isActive={() =>
                    isSimpleFilterSelected(
                      StudentFiltersSpecialValues.PENDING,
                      HighlightsInitiatives.ENDORSEMENTS,
                      filters.filterCategory,
                      filtersList,
                    )
                  }
                  onBarClick={onPendingBarClick}
                  onLabelClick={onPendingBarClick}
                  totalStudentsCount={totalStudentsCount}
                  width="20%"
                />
              </>
            )}
          </Box>
        </Box>
      )}
      {activeDrilldown && (
        <Box display="flex" flexDirection="column" height={350}>
          <Box alignItems="center" display="flex" mb={2} position="relative">
            <Button
              className="drilldown__back"
              gaLabel="Back to all endorsements highlights"
              onClick={() => setActiveDrilldown(null)}
              startIcon={<BackIcon />}
            >
              {t('Back to all endorsements')}
            </Button>
            <Typography className="drilldown__title" variant="subtitle2">
              {t(`analytics.highlights.endorsements.${activeDrilldown}`)}
            </Typography>
          </Box>
          {activeDrilldown === EndorsementsDrilldowns.INTERESTED && (
            <InterestedHighlights
              onFiltersUpdate={onFiltersUpdate}
              stats={stats}
              totalStudentsCount={totalStudentsCount}
            />
          )}
          {activeDrilldown === EndorsementsDrilldowns.SELECTED && (
            <SelectedHighlights
              onFiltersUpdate={onFiltersUpdate}
              stats={stats}
              totalStudentsCount={totalStudentsCount}
            />
          )}
          {activeDrilldown === EndorsementsDrilldowns.TYPE && (
            <TypeHighlights
              onFiltersUpdate={onFiltersUpdate}
              stats={stats}
              totalStudentsCount={totalStudentsCount}
            />
          )}
          {activeDrilldown === EndorsementsDrilldowns.REASON && (
            <ReasonsHighlights onFiltersUpdate={onFiltersUpdate} stats={stats} />
          )}
        </Box>
      )}
    </div>
  );
};

EndorsementsHighlights.propTypes = {
  stats: PropTypes.instanceOf(Object).isRequired,
  totalStudentsCount: PropTypes.number.isRequired,
  onFiltersUpdate: PropTypes.func.isRequired,
};

export default EndorsementsHighlights;
