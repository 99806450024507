import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Tab, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { TabPanel, Tabs } from '../../../atoms';
import { Dropdown, InitiativeEmptyStateBlock } from '../../../moleculas';
import { SharingLevels } from '../../../../constants/family-feed';
import { GaActions, GaCategories, SchoolLevels } from '../../../../constants/enums';
import { GA } from '../../../../utils';
import { useFamilyFeed } from '../../../../hooks';
import { ReactComponent as CampusLevelEmptyState } from '../../../../resources/images/family_feed_selected_entities_empty_state.svg';
import BlockHeader from '../block-header/BlockHeader';
import SelectAllEntities from '../select-all-entities/SelectAllEntities';
import SelectableEntities from '../selectable-entities/SelectableEntities';

const ContentTabsMap = {
  [SchoolLevels.ELEMENTARY]: 'Elementary',
  [SchoolLevels.MIDDLE]: 'Middle',
  [SchoolLevels.HIGH]: 'High',
};

const CampusesBlock = ({ columnsCount, onChange, outlinedDropdown, schools, selected }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpMd = useMediaQuery(theme.breakpoints.up('md'), { noSsr: true });

  const { formatEntities } = useFamilyFeed();

  const [activeSchoolLevel, setActiveSchoolLevel] = useState();

  const sortedSchoolLevels = useMemo(
    () => Object.keys(schools).sort((a, b) => a.localeCompare(b)),
    [schools],
  );

  useEffect(() => {
    setActiveSchoolLevel(() => sortedSchoolLevels[0]);
  }, [sortedSchoolLevels]);

  const onContentSectionTabsChange = useCallback((_, newTab) => {
    setActiveSchoolLevel(newTab);

    GA.logInteraction({
      category: GaCategories.BEHAVIOR,
      action: GaActions.TAB_CLICK,
      label: newTab,
    });
  }, []);

  const onDropdownChange = useCallback((e) => {
    setActiveSchoolLevel(e.target.value);
  }, []);

  const dropdownOptions = useMemo(
    () =>
      sortedSchoolLevels.map((level) => ({
        value: level,
        label: t(ContentTabsMap[level]),
      })),
    [sortedSchoolLevels, t],
  );

  const getSelectableEntities = useCallback(
    (schoolLevel) =>
      schools[schoolLevel || activeSchoolLevel]
        ? formatEntities(schools[schoolLevel || activeSchoolLevel], {
            id: 'index',
            name: 'schoolName',
          }).sort((a, b) => a.name.localeCompare(b.name))
        : [],
    [activeSchoolLevel, formatEntities, schools],
  );

  const handleChange = useCallback(
    (e, value) => {
      onChange(e, value, 'name');
    },
    [onChange],
  );

  const getIsAllSelected = useCallback(
    (schoolLevel) =>
      schools[schoolLevel].every(({ schoolName }) =>
        selected.some((item) => item.name === schoolName),
      ),
    [schools, selected],
  );

  return Object.values(schools).flat().length ? (
    <Box>
      <BlockHeader subtitle="Select one or multiple campuses" title={SharingLevels.CAMPUS.title} />
      {isWidthUpMd ? (
        <>
          <Box mb={2}>
            <Tabs
              className="content-section-tabs ayo-tabs--outlined"
              onChange={onContentSectionTabsChange}
              value={activeSchoolLevel}
              variant="fullWidth"
            >
              {sortedSchoolLevels.map((level) => (
                <Tab key={level} disableRipple label={t(ContentTabsMap[level])} value={level} />
              ))}
            </Tabs>
          </Box>
          {Object.entries(schools).map(([schoolLevel, schoolsList]) => (
            <TabPanel key={schoolLevel} currentTabValue={activeSchoolLevel} value={schoolLevel}>
              <SelectableEntities
                columnsCount={columnsCount}
                entities={getSelectableEntities(schoolLevel)}
                firstComponent={
                  schoolsList?.length > 1 && (
                    <SelectAllEntities
                      isChecked={getIsAllSelected(schoolLevel)}
                      label={`All ${ContentTabsMap[schoolLevel].toLowerCase()} schools`}
                      onChange={(e) => handleChange(e, [...getSelectableEntities(schoolLevel)])}
                    />
                  )
                }
                onChange={handleChange}
                selected={selected}
              />
            </TabPanel>
          ))}
        </>
      ) : (
        <>
          <Box mb={2}>
            <Dropdown
              fullWidth
              gaLabel="Schools level"
              handleChange={onDropdownChange}
              options={dropdownOptions}
              outlined={outlinedDropdown}
              value={activeSchoolLevel}
            />
          </Box>
          <SelectableEntities
            entities={getSelectableEntities(activeSchoolLevel)}
            firstComponent={
              schools[activeSchoolLevel]?.length > 1 && (
                <SelectAllEntities
                  isChecked={getIsAllSelected(activeSchoolLevel)}
                  label={`All ${ContentTabsMap[activeSchoolLevel].toLowerCase()} schools`}
                  onChange={(e) => handleChange(e, [...getSelectableEntities(activeSchoolLevel)])}
                />
              )
            }
            onChange={handleChange}
            selected={selected}
          />
        </>
      )}
    </Box>
  ) : (
    <InitiativeEmptyStateBlock
      illustration={<CampusLevelEmptyState />}
      title={t('You don’t have any campuses yet')}
    />
  );
};

CampusesBlock.propTypes = {
  columnsCount: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  outlinedDropdown: PropTypes.bool,
  schools: PropTypes.shape({
    [SchoolLevels.ELEMENTARY]: PropTypes.arrayOf(
      PropTypes.shape({
        schoolName: PropTypes.string,
      }),
    ),
    [SchoolLevels.MIDDLE]: PropTypes.arrayOf(
      PropTypes.shape({
        schoolName: PropTypes.string,
      }),
    ),
    [SchoolLevels.HIGH]: PropTypes.arrayOf(
      PropTypes.shape({
        schoolName: PropTypes.string,
      }),
    ),
  }).isRequired,
  selected: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ).isRequired,
};

CampusesBlock.defaultProps = {
  columnsCount: 2,
  outlinedDropdown: false,
};

export default CampusesBlock;
