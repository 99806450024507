import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Box, useTheme, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Alert } from '@mui/lab';

import { Button, Link, Snackbar } from '../../atoms';
import { plugRoute } from '../../../constants/routes';
import { ReactComponent as CloseIcon } from '../../../resources/icons/close_dark.svg';

const LinkWithSnackbar = ({ className, linkAnchor, snackbarBody }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const [showSnackbar, setShowSnackbar] = useState(false);

  return (
    <>
      <Link
        className={`${classNames('ayo-link-with-snackbar', {
          [`${className}`]: className,
        })}`}
        gaLabel={linkAnchor}
        href={plugRoute}
        onClick={(e) => {
          e.preventDefault();
          setShowSnackbar(true);
        }}
      >
        {t(linkAnchor)}
      </Link>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        className="ayo-link-with-snackbar"
        onClose={() => {
          setShowSnackbar(false);
        }}
        open={showSnackbar}
      >
        <Alert elevation={6} icon={false} severity="info">
          <Box direction="row" display="flex">
            <Box>{snackbarBody}</Box>
            <Box pl={isWidthUpMd ? 3 : 2}>
              <Button
                aria-label={t('Close')}
                autoFocus
                onClick={() => {
                  setShowSnackbar(false);
                }}
              >
                <CloseIcon />
              </Button>
            </Box>
          </Box>
        </Alert>
      </Snackbar>
    </>
  );
};

LinkWithSnackbar.propTypes = {
  className: PropTypes.string,
  linkAnchor: PropTypes.string.isRequired,
  snackbarBody: PropTypes.node.isRequired,
};

LinkWithSnackbar.defaultProps = {
  className: '',
};

export default LinkWithSnackbar;
