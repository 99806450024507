import React, { useReducer, createContext } from 'react';
import PropTypes from 'prop-types';

import { LocalStorageKeysMap } from '../../constants/enums';

export const AppContext = createContext();

export const AppActions = {
  SET_CLASSLINK_REMINDER_OPEN: 'SET_CLASSLINK_REMINDER_OPEN',
  SET_DEVICE_TYPE: 'SET_DEVICE_TYPE',
  SET_IDLE_TIMEOUT_EXPIRED: 'SET_IDLE_TIMEOUT_EXPIRED',
  SET_LOADER_CONFIG: 'SET_LOADER_CONFIG',
  SET_LOGIN_PROVIDER: 'SET_LOGIN_PROVIDER',
  SET_NAV_DRAWER_OPEN: 'SET_NAV_DRAWER_OPEN',
  SET_IS_BACKGROUND_SYNC: 'SET_IS_BACKGROUND_SYNC',
  SET_IS_DIRTY: 'SET_IS_DIRTY',
  SET_IS_LEAVE_DIALOG_OPEN: 'SET_IS_LEAVE_DIALOG_OPEN',
  SET_LOGOUT_HANDLER: 'SET_LOGOUT_HANDLER',
  SET_SNACKBAR_STATUS: 'SET_SNACKBAR_STATUS',
  ADD_SECTIONS_TO_RELOAD: 'ADD_SECTIONS_TO_RELOAD',
  UPDATE_SECTIONS_TO_RELOAD: 'UPDATE_SECTIONS_TO_RELOAD',
  SET_OPENED_NAV_ITEM: 'SET_OPENED_NAV_ITEM',
};

const initState = {
  classLinkReminderOpen: false,
  deviceType: localStorage.getItem(LocalStorageKeysMap.AYO_DEVICE_TYPE),
  idleTimeoutExpired: false,
  loaderConfig: {
    loaderText: '',
  },
  loginProvider: localStorage.getItem(LocalStorageKeysMap.AYO_LOGIN_PROVIDER),
  navDrawerOpen: false,
  isBackgroundSync: false,
  isDirty: false,
  sectionsToReload: [],
  isLeaveDialogOpen: null,
  snackbarStatus: null,
  onLogout: {},
  openedNavItem: null,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case AppActions.SET_CLASSLINK_REMINDER_OPEN:
      return { ...state, classLinkReminderOpen: action.data };
    case AppActions.SET_DEVICE_TYPE:
      if (action.data) {
        localStorage.setItem(LocalStorageKeysMap.AYO_DEVICE_TYPE, action.data);
      }
      return { ...state, deviceType: action.data };
    case AppActions.SET_IDLE_TIMEOUT_EXPIRED:
      return { ...state, idleTimeoutExpired: action.data };
    case AppActions.SET_LOADER_CONFIG:
      return { ...state, loaderConfig: action.data };
    case AppActions.SET_LOGIN_PROVIDER:
      if (action.data) {
        localStorage.setItem(LocalStorageKeysMap.AYO_LOGIN_PROVIDER, action.data);
      }
      return { ...state, loginProvider: action.data };
    case AppActions.SET_NAV_DRAWER_OPEN:
      return { ...state, navDrawerOpen: action.data };
    case AppActions.SET_IS_LEAVE_DIALOG_OPEN:
      return { ...state, isLeaveDialogOpen: action.data };
    case AppActions.SET_IS_BACKGROUND_SYNC:
      return { ...state, isBackgroundSync: action.data };
    case AppActions.SET_IS_DIRTY:
      return { ...state, isDirty: action.data };
    case AppActions.SET_LOGOUT_HANDLER:
      return { ...state, onLogout: { ...state.onLogout, ...action.data } };
    case AppActions.SET_SNACKBAR_STATUS:
      return { ...state, snackbarStatus: action.data };
    case AppActions.ADD_SECTIONS_TO_RELOAD:
      return { ...state, sectionsToReload: [action.data, ...state.sectionsToReload] };
    case AppActions.UPDATE_SECTIONS_TO_RELOAD:
      return { ...state, sectionsToReload: action.data };
    case AppActions.SET_OPENED_NAV_ITEM:
      return { ...state, openedNavItem: action.data };
    default:
      throw new Error();
  }
};

export const AppContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initState);

  return <AppContext.Provider value={{ state, dispatch }}>{children}</AppContext.Provider>;
};

AppContextProvider.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
};
