/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { DragDropContext, PeriodDisplayName, Typography } from '../../atoms';
import { reorder } from '../../../utils';
import { ReactComponent as DraggableIcon } from '../../../resources/icons/draggable-dark.svg';
import { ReactComponent as CloseIcon } from '../../../resources/icons/close_red.svg';
import { useLessonPlannerData } from '../../../hooks';
import { LogLevelsMap, Semesters } from '../../../constants/enums';
import AddCoursesDropdown from '../add-courses-dropdown/AddCoursesDropdown';
import EditingButton from '../editing-button/EditingButton';

const getItemStyle = (isDragging, draggableStyle, backgroundColor) => ({
  '--background-main': isDragging ? '#FFF3E0' : backgroundColor,
  ...draggableStyle,
});

const getDraggedDom = (draggableId) => {
  const queryAttr = 'data-rbd-drag-handle-draggable-id';
  const domQuery = `[${queryAttr}='${draggableId}']`;
  const draggedDOM = document.querySelector(domQuery);
  return draggedDOM;
};

const DaySchedule = ({ periods, dayIndex, onChange, weekIndex, semester, schoolYear, date }) => {
  const { t } = useTranslation();
  const [activeItemIndex, setActiveItemIndex] = useState(null);

  const {
    scheduleAvailablePeriods,
    availablePeriods,
    filterPeriodsByDate,
    filterEntityBySemester,
  } = useLessonPlannerData();

  const [placeholderProps, setPlaceholderProps] = useState({});

  const onDragEndHandler = useCallback(
    (dragInfo) => {
      setPlaceholderProps({});
      const { destination, source } = dragInfo;
      if (!destination) {
        return;
      }
      onChange(
        reorder(periods, source.index, destination.index).map((period, index) => ({
          ...period,
          orderNumber: index,
        })),
        dayIndex,
        {
          [LogLevelsMap.SUCCESS]: {
            text: t('The periods order has been changed.'),
            type: 'reorder',
          },
          [LogLevelsMap.ERROR]: {
            text: t('Failed to change the order of the periods. Please try once more'),
            type: 'error',
          },
        },
        weekIndex,
        semester,
      );
    },
    [onChange, periods, dayIndex, weekIndex, semester, t],
  );

  const onCourseDelete = useCallback(() => {
    onChange(
      periods
        .filter((period, index) => index !== activeItemIndex)
        .map((period, idx) => ({ ...period, orderNumber: idx })),
      dayIndex,
      {
        [LogLevelsMap.SUCCESS]: {
          text: t('The period has been removed.'),
          type: 'remove',
        },
        [LogLevelsMap.ERROR]: {
          text: t('Failed to remove the period. Please try once more'),
          type: 'error',
        },
      },
      weekIndex,
      semester,
    );
  }, [activeItemIndex, periods, dayIndex, onChange, weekIndex, semester, t]);

  const onDragUpdate = (event) => {
    if (!event.destination) {
      return;
    }
    const draggedDOM = getDraggedDom(event.draggableId);

    if (!draggedDOM) {
      return;
    }

    const { clientHeight, clientWidth } = draggedDOM;
    const destinationIndex = event.destination.index;
    const sourceIndex = event.source.index;

    const childrenArray = [...draggedDOM.parentNode.children];
    const movedItem = childrenArray[sourceIndex];
    childrenArray.splice(sourceIndex, 1);

    const updatedArray = [
      ...childrenArray.slice(0, destinationIndex),
      movedItem,
      ...childrenArray.slice(destinationIndex + 1),
    ];

    const clientY =
      parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
      updatedArray.slice(0, destinationIndex).reduce((total, curr) => {
        const style = curr.currentStyle || window.getComputedStyle(curr);
        const marginBottom = parseFloat(style.marginBottom);
        return total + curr.clientHeight + marginBottom;
      }, 0);

    setPlaceholderProps({
      clientHeight,
      clientWidth,
      clientY,
      clientX: parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingLeft),
    });
  };

  const onDragStart = (event) => {
    const draggedDOM = getDraggedDom(event.draggableId);

    if (!draggedDOM) {
      return;
    }

    const { clientHeight, clientWidth } = draggedDOM;
    const sourceIndex = event.source.index;
    const clientY =
      parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
      [...draggedDOM.parentNode.children].slice(0, sourceIndex).reduce((total, curr) => {
        const style = curr.currentStyle || window.getComputedStyle(curr);
        const marginBottom = parseFloat(style.marginBottom);
        return total + curr.clientHeight + marginBottom;
      }, 0);

    setPlaceholderProps({
      clientHeight,
      clientWidth,
      clientY,
      clientX: parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingLeft),
    });
  };

  const periodsToAdd = useMemo(() => {
    if (!scheduleAvailablePeriods) return [];
    const dayAvailablePeriods = semester
      ? filterEntityBySemester(scheduleAvailablePeriods, schoolYear, semester)
      : filterPeriodsByDate(scheduleAvailablePeriods, date, date);
    return dayAvailablePeriods.filter(
      (availableCourse) =>
        !(periods || []).some((course) => course.periodName === availableCourse.periodName),
    );
  }, [
    periods,
    date,
    filterPeriodsByDate,
    filterEntityBySemester,
    semester,
    schoolYear,
    scheduleAvailablePeriods,
  ]);

  return (
    <Box className="ayo-day-schedule">
      <Box>
        {periods && (
          <DragDropContext
            instructionText={t('Lesson planner SR instruction')}
            onDragEnd={(dragInfo) => onDragEndHandler(dragInfo)}
            onDragStart={onDragStart}
            onDragUpdate={onDragUpdate}
          >
            <Droppable droppableId={`droppable-${dayIndex}`}>
              {(provided, droppableSnapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="classes-container"
                >
                  {periods.map((period, index) => (
                    <Draggable
                      key={`${period.periodName}-${dayIndex}-${index}`}
                      draggableId={`${period.periodName}-${dayIndex}-${index}`}
                      index={index}
                    >
                      {(dragProvided, dragSnapshot) => (
                        <Box
                          ref={dragProvided.innerRef}
                          {...dragProvided.draggableProps}
                          {...dragProvided.dragHandleProps}
                          className={classnames('ayo-day-schedule__item', {
                            'ayo-day-schedule__item--draggable': dragSnapshot.isDragging,
                          })}
                          mb={1}
                          onBlur={() => setActiveItemIndex(null)}
                          onFocus={() =>
                            setActiveItemIndex(!dragSnapshot.isDragging ? index : null)
                          }
                          onKeyDown={(e) => {
                            if (e.key === 'Delete') {
                              onCourseDelete();
                            }
                          }}
                          style={getItemStyle(
                            dragSnapshot.isDragging,
                            dragProvided.draggableProps.style,
                            (scheduleAvailablePeriods || availablePeriods)?.find(
                              ({ periodName }) => periodName === period.periodName,
                            )?.color,
                          )}
                        >
                          <Box
                            alignItems="center"
                            className="ayo-day-schedule__item__container"
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-between"
                            onMouseLeave={() => setActiveItemIndex(null)}
                            onMouseOver={() =>
                              setActiveItemIndex(!dragSnapshot.isDragging ? index : null)
                            }
                            position="relative"
                            px={3}
                            py={2}
                            width="100%"
                          >
                            <Typography variant="subtitle2">
                              <PeriodDisplayName period={period.periodName} />
                            </Typography>
                            <Box ml={1}>
                              <DraggableIcon />
                            </Box>
                            {activeItemIndex === index && !dragSnapshot.isDragging && (
                              <Box className="ayo-day-schedule__delete-button">
                                <EditingButton
                                  gaLabel="Remove course"
                                  icon={<CloseIcon />}
                                  iconPosition="end"
                                  onClick={onCourseDelete}
                                  tabIndex={-1}
                                />
                              </Box>
                            )}
                          </Box>
                        </Box>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                  {!!Object.keys(placeholderProps).length && droppableSnapshot.isDraggingOver && (
                    <div
                      className="drop-placeholder"
                      style={{
                        top: placeholderProps.clientY,
                        left: placeholderProps.clientX,
                        height: placeholderProps.clientHeight,
                        width: placeholderProps.clientWidth,
                      }}
                    >
                      <div style={{ backgroundColor: 'red' }} />
                    </div>
                  )}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
        {!!periodsToAdd.length && (
          <Box key={dayIndex} className="ayo-day-schedule__add-classes" height="100%">
            <AddCoursesDropdown
              dayIndex={dayIndex}
              onChange={onChange}
              periodsToAdd={periodsToAdd}
              selectedPeriods={periods}
              semester={semester}
              weekIndex={weekIndex}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

DaySchedule.propTypes = {
  periods: PropTypes.arrayOf(
    PropTypes.shape({
      periodName: PropTypes.string,
      orderNumber: PropTypes.number,
    }),
  ).isRequired,
  dayIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  weekIndex: PropTypes.number,
  semester: PropTypes.oneOf(Object.values(Semesters)),
  schoolYear: PropTypes.number,
  date: PropTypes.string,
};

DaySchedule.defaultProps = {
  weekIndex: null,
  semester: '',
  schoolYear: null,
  date: null,
};

export default DaySchedule;
