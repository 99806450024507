import { useCallback } from 'react';

import useAxios, { StatusCodeMap } from '../HttpClient';
import { apiRoute, commentsRoute } from '../../constants/routes';

const useCommentsService = () => {
  const { get, httpDelete, post, put } = useAxios();

  const getComments = useCallback(
    async (page, relatedUserId, resourceId) => {
      const { data } = await get(
        { enabled: true },
        `${apiRoute}${commentsRoute}?page=${page}${
          relatedUserId ? `&relatedUserId=${relatedUserId}` : ''
        }${resourceId ? `&resourceId=${resourceId}` : ''}`,
      );
      return data.entity;
    },
    [get],
  );

  const postComment = useCallback(
    async (commentRequestBody, withErrorHandlers) => {
      const { data } = await post(
        { enabled: true },
        `${apiRoute}${commentsRoute}`,
        commentRequestBody,
        {
          skipDefaultErrorHandlers: withErrorHandlers && [
            StatusCodeMap.NOT_FOUND,
            StatusCodeMap.SERVER_ERROR,
          ],
        },
      );
      return data.entity;
    },
    [post],
  );

  const deleteComment = useCallback(
    (commentId) => httpDelete({ enabled: true }, `${apiRoute}${commentsRoute}/${commentId}`),
    [httpDelete],
  );

  const updateComment = useCallback(
    async (commentId, commentRequestBody) => {
      const { data } = await put(
        { enabled: true },
        `${apiRoute}${commentsRoute}/${commentId}`,
        commentRequestBody,
      );
      return data.entity;
    },
    [put],
  );

  return {
    getComments,
    deleteComment,
    postComment,
    updateComment,
  };
};

export default useCommentsService;
