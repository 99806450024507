import React from 'react';
import PropTypes from 'prop-types';

const Image = ({ src, alt, className, height, width }) => {
  const getSizeProps = (h, w) => {
    const size = {};
    if (h) {
      size.height = `${h}px`;
    }
    if (w) {
      size.width = `${w}px`;
    }
    return size;
  };

  return <img alt={alt} className={className} src={src} style={getSizeProps(height, width)} />;
};

Image.propTypes = {
  src: PropTypes.node.isRequired,
  alt: PropTypes.string,
  className: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

Image.defaultProps = {
  alt: '',
  className: '',
  height: 0,
  width: 0,
};

export default Image;
