import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@mui/material';

import TextField from '../textfield/TextField';
import { ReactComponent as MinusIcon } from '../../../resources/icons/minus.svg';
import { ReactComponent as PlusIcon } from '../../../resources/icons/plus.svg';

const NumericField = ({ defaultValue, maxLength, maxValue, minValue, onChange, outlined }) => {
  const { t } = useTranslation();

  const [value, setValue] = useState(defaultValue);

  const [isValid, setIsValid] = useState(true);

  const increment = useCallback(() => {
    if (maxValue !== null && value < maxValue) {
      setValue((value || 0) + 1);
    }
  }, [maxValue, value]);

  const decrement = useCallback(() => {
    if (minValue !== null && value > minValue) {
      setValue((value || 0) - 1);
    }
  }, [minValue, value]);

  const handleChange = (e) => {
    const inputValue = parseInt(e.target.value, 10);
    setValue(Number.isNaN(inputValue) ? '' : inputValue);
  };

  useEffect(() => {
    const inputValue = parseInt(value, 10);
    if (
      maxValue !== null &&
      minValue !== null &&
      (Number.isNaN(inputValue) || inputValue > maxValue || inputValue < minValue)
    ) {
      setIsValid(false);
      onChange(null);
    } else {
      setIsValid(true);
      onChange(inputValue);
    }
  }, [maxValue, minValue, onChange, value]);

  return (
    <TextField
      className="ayo-numericfield"
      error={!isValid}
      helperText={!isValid ? `${t('Put a number from')} ${minValue} ${t('to')} ${maxValue}` : ''}
      InputProps={{
        endAdornment: (
          <IconButton
            aria-disabled={value >= maxValue}
            className="ayo-icon-button"
            disableRipple
            onClick={increment}
            title={t('Increase the quantity')}
          >
            <PlusIcon />
          </IconButton>
        ),
        startAdornment: (
          <IconButton
            aria-disabled={value <= minValue}
            className="ayo-icon-button"
            disableRipple
            onClick={decrement}
            title={t('Decrease the quantity')}
          >
            <MinusIcon />
          </IconButton>
        ),
      }}
      // For some reason MaterialUI has two props with different cases,
      // which are applied to input html element and to TextField component itself
      // eslint-disable-next-line react/jsx-no-duplicate-props
      inputProps={{ maxlength: maxLength }}
      onChange={handleChange}
      outlined={outlined}
      value={value}
    />
  );
};

NumericField.propTypes = {
  defaultValue: PropTypes.number,
  maxLength: PropTypes.number,
  maxValue: PropTypes.number,
  minValue: PropTypes.number,
  onChange: PropTypes.func,
  outlined: PropTypes.bool,
};

NumericField.defaultProps = {
  defaultValue: 0,
  maxLength: null,
  maxValue: null,
  minValue: null,
  onChange: () => {},
  outlined: false,
};

export default NumericField;
