import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const useAssignmentMaterialsState = (defaultValue) => {
  const { t } = useTranslation();
  const [materials, setMaterials] = useState(defaultValue);
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);

  const onChange = (key, addedItems, allItems) => {
    switch (key) {
      case 'inProgress':
        setIsLoadInProgress(addedItems);
        break;
      default:
        setMaterials((state) => ({
          ...state,
          [key]: addedItems ? [...state[key], ...addedItems] : allItems,
        }));
    }
  };

  const validate = () =>
    isLoadInProgress
      ? [
          {
            errorText: t('You have some files that are loading to the lesson'),
            lvl3Label: t('Materials'),
          },
        ]
      : [];

  return { state: materials, onChange, validate };
};

export default useAssignmentMaterialsState;
