import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Checkbox, FormControlLabel, Typography } from '../../../atoms';

const SelectAllEntities = ({ onChange, label, isChecked }) => {
  const { t } = useTranslation();

  return (
    <FormControlLabel
      className="share-with-item"
      control={
        <Checkbox
          checked={isChecked}
          gaLabel={label}
          name={label}
          onChange={onChange}
          value={label}
        />
      }
      label={
        <Typography component="p" variant="subtitle2">
          {t(label)}
        </Typography>
      }
    />
  );
};

SelectAllEntities.propTypes = {
  isChecked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

SelectAllEntities.defaultProps = {
  isChecked: false,
};

export default SelectAllEntities;
