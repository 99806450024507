import React, { useCallback } from 'react';
import { Grid, Box, Divider, useTheme, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { FormControlLabel, Radio, Checkbox } from '../../../atoms';
import {
  HighlightsInitiatives,
  LeadershipAttributes,
  StudentFiltersTypes,
} from '../../../../constants/enums';
import { filtersListSeparator } from '../../../../constants/values';
import { getFilterValueParts, isLAFilterEnabled } from '../../../../utils';
import DisabledControlWithTooltip from '../../tooltip-wrappers/disabled-control-with-tooltip/DisabledControlWithTooltip';

const LAFilters = ({ menuFilters, setMenuFilters, stats }) => {
  const onChange = useCallback(
    (e) => {
      if (menuFilters.filterCategory?.includes(HighlightsInitiatives.LEADERSHIP_ATTRIBUTES)) {
        const { filtersList } = getFilterValueParts(menuFilters, stats);
        setMenuFilters({
          ...menuFilters,
          filterValue: `${e.target.value}:${filtersList.join(filtersListSeparator)}`,
        });
      } else {
        setMenuFilters({
          filterCategory: HighlightsInitiatives.LEADERSHIP_ATTRIBUTES,
          filterValue: `${e.target.value}:`,
        });
      }
    },
    [stats, menuFilters, setMenuFilters],
  );

  const { t } = useTranslation();

  const onAttributeSelection = useCallback(
    (e) => {
      if (e.target.checked) {
        if (menuFilters.filterCategory?.includes(HighlightsInitiatives.LEADERSHIP_ATTRIBUTES)) {
          const { filterCriterion, filtersList } = getFilterValueParts(menuFilters, stats);
          filtersList.push(e.target.value);
          const LeadershipAttributesList = Object.values(LeadershipAttributes);
          setMenuFilters({
            filterCategory: HighlightsInitiatives.LEADERSHIP_ATTRIBUTES,
            filterValue: `${filterCriterion}:${filtersList
              .filter((filter) => LeadershipAttributesList.includes(filter))
              .join(filtersListSeparator)}`,
          });
        } else {
          setMenuFilters({
            filterCategory: HighlightsInitiatives.LEADERSHIP_ATTRIBUTES,
            filterValue: `${StudentFiltersTypes.INCLUDE}:${e.target.value}`,
          });
        }
      } else {
        const { filterCriterion, filtersList } = getFilterValueParts(menuFilters, stats);
        setMenuFilters({
          ...menuFilters,
          filterValue: `${filterCriterion}:${filtersList
            .filter((attribute) => attribute !== e.target.value)
            .join(filtersListSeparator)}`,
        });
      }
    },
    [stats, menuFilters, setMenuFilters],
  );

  const onAllAttributesChange = useCallback(
    (e) => {
      const { filterCriterion } = getFilterValueParts(menuFilters, stats);
      const filterAttributes = e.target.checked
        ? Object.values(LeadershipAttributes).filter((attribute) =>
            isLAFilterEnabled(stats, attribute),
          )
        : [];
      setMenuFilters({
        filterCategory: HighlightsInitiatives.LEADERSHIP_ATTRIBUTES,
        filterValue: `${filterCriterion || StudentFiltersTypes.INCLUDE}:${filterAttributes.join(
          filtersListSeparator,
        )}`,
      });
    },
    [stats, menuFilters, setMenuFilters],
  );

  const isAllAttributesChecked = Object.values(LeadershipAttributes)
    .filter((attribute) => isLAFilterEnabled(stats, attribute))
    .every((attribute) => menuFilters.filterValue?.includes(attribute));

  const { filtersList, filterCriterion } = getFilterValueParts(menuFilters, stats);

  const theme = useTheme();

  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Box>
      <Box display="flex" flexDirection={isWidthUpSm ? 'row' : 'column'}>
        <Box mb={isWidthUpSm ? 0 : 1} mr={1}>
          <FormControlLabel
            control={
              <Radio
                checked={menuFilters.filterValue?.includes(StudentFiltersTypes.INCLUDE) ?? false}
                gaLabel="Student Filters - LA - Developing"
                name="activitytype"
                onChange={onChange}
                value={StudentFiltersTypes.INCLUDE}
              />
            }
            label={t('Developing')}
          />
        </Box>
        <Box mb={isWidthUpSm ? 0 : 1} mr={1}>
          <FormControlLabel
            control={
              <Radio
                checked={menuFilters.filterValue?.includes(StudentFiltersTypes.PENDING) ?? false}
                gaLabel="Student Filters - LA - Pending"
                name="activitytype"
                onChange={onChange}
                value={StudentFiltersTypes.PENDING}
              />
            }
            label={t('Pending')}
          />
        </Box>
      </Box>
      <Divider />
      <Grid className="scrollable-filters" container>
        <Grid item sm={6} xs={12}>
          <FormControlLabel
            className="filters-checkbox"
            control={
              <Checkbox
                checked={isAllAttributesChecked}
                gaLabel="Student Filters - LA - All Attributes"
                onChange={onAllAttributesChange}
                value="ALL"
              />
            }
            label={t('All Attributes')}
          />
        </Grid>
        {Object.values(LeadershipAttributes).map((attribute) => {
          const currentAttributeStatValue =
            stats.leadershipAttributes[attribute][
              filterCriterion === StudentFiltersTypes.PENDING ? 'notHave' : 'have'
            ];

          const isDisabled = !isLAFilterEnabled(stats, attribute);

          return (
            <Grid key={attribute} item sm={6} xs={12}>
              <DisabledControlWithTooltip
                showTooltip={isDisabled}
                title={
                  <>
                    {`${t(attribute)} (${currentAttributeStatValue})`}
                    <br />
                    {currentAttributeStatValue === 0
                      ? t("Can't filter by 0 students")
                      : t("Can't filter by all students")}
                  </>
                }
              >
                <FormControlLabel
                  className="filters-checkbox"
                  control={
                    <Checkbox
                      checked={filtersList?.includes(attribute) ?? false}
                      disabled={isDisabled}
                      gaLabel={`Student Filters - LA - ${attribute}`}
                      onChange={onAttributeSelection}
                      value={attribute}
                    />
                  }
                  label={`${t(attribute)} (${currentAttributeStatValue})`}
                />
              </DisabledControlWithTooltip>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

LAFilters.propTypes = {
  menuFilters: PropTypes.shape({ filterValue: PropTypes.string, filterCategory: PropTypes.string })
    .isRequired,
  setMenuFilters: PropTypes.func.isRequired,
  stats: PropTypes.instanceOf(Object).isRequired,
};

export default LAFilters;
