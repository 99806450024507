/* eslint-disable no-param-reassign */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';

import { Button, Typography } from '../../../../../atoms';
import { EditingButton, InformationalCaption } from '../../../../../moleculas';
import { RichTextEditor } from '../../../../../organisms';
import { AttachmentsResourcesTypes } from '../../../../../../constants/enums';
import { ReactComponent as FullscreenExitIcon } from '../../../../../../resources/icons/fullscreen_exit_blue.svg';
import { ReactComponent as FullscreenOpenIcon } from '../../../../../../resources/icons/fullscreen_blue.svg';
import { ReactComponent as PreviewIcon } from '../../../../../../resources/icons/preview.svg';
import { ReactComponent as CalendarIcon } from '../../../../../../resources/icons/date_picker.svg';

const FlexWrapper = ({ action, className, children, title }) => (
  <Box
    className={className}
    display="flex"
    flex={1}
    flexDirection="column"
    pb={action ? 3 : 4}
    pt={2}
    px={2}
  >
    <Typography component="h4" variant="h2">
      {title.toUpperCase()}
    </Typography>
    <Box flex={1} position="relative" pt={1}>
      {children}
    </Box>
    {action && <Box pt={3}>{action}</Box>}
  </Box>
);

FlexWrapper.propTypes = {
  action: PropTypes.node,
  className: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

FlexWrapper.defaultProps = {
  action: null,
};

const toggleFullScreen = () => {
  if (document.fullscreenElement) {
    document.exitFullscreen();
  } else {
    document.getElementById('lesson-block-agenda').requestFullscreen();
  }
};

const EditorConfig = {
  EMPTY_TEXT: '{ "ops": [{ "insert": "\\n" }] }',
  FORMATTING_OPTIONS: ['styling', 'lists', 'links', 'images'],
};

const LessonPageAgendaBoard = ({
  errors,
  showFullScreenButton,
  isStatic,
  lessonId,
  metaData,
  onBeforeImageUpload,
  onChange,
  values,
}) => {
  const { t } = useTranslation();

  const [isPreviewMode, setIsPreviewMode] = useState(false);

  const handleFullscreenChange = useCallback(() => {
    setIsPreviewMode(!!document.fullscreenElement);
  }, []);

  useEffect(() => {
    const el = document.getElementById('lesson-block-agenda');
    el?.addEventListener('fullscreenchange', handleFullscreenChange);
    return () => {
      el?.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, [handleFullscreenChange]);

  const handleTextEditorChange = useCallback(
    (key, data, component) => {
      onChange(component, key, data);
    },
    [onChange],
  );

  const handlePasteTeks = useCallback(() => {
    const parsedText = { ops: [] };
    metaData.teksData.forEach((teks) => {
      parsedText.ops.push({ insert: teks.text, attributes: { bold: true } });
      parsedText.ops.push({ insert: '\n' });
    });
    handleTextEditorChange('textJson', JSON.stringify(parsedText), 'teks');
  }, [handleTextEditorChange, metaData]);

  return (
    <Box className="agenda-board-container" p={5}>
      <Box pb={5} position="relative">
        <Box alignItems="center" display="flex" mb={2}>
          <Typography component="h3" variant="h1">
            {metaData.title || t('Add the title of the lesson')}
          </Typography>
          {!metaData.title && (
            <InformationalCaption
              showTooltip
              title="Add the title of the lesson in the Primary details to see it here"
            />
          )}
        </Box>
        <Box alignItems="center" display="flex">
          <CalendarIcon className="calendar-icon" />
          <Typography isLabel variant="subtitle1">
            {metaData.classDate}
          </Typography>
        </Box>
        {showFullScreenButton && (
          <Button
            className="preview-btn"
            endIcon={
              isPreviewMode ? (
                <FullscreenExitIcon />
              ) : isStatic ? (
                <FullscreenOpenIcon />
              ) : (
                <PreviewIcon />
              )
            }
            gaLabel={isPreviewMode ? 'Exit fullscreen' : isStatic ? 'Open fullscreen' : 'Preview'}
            onClick={toggleFullScreen}
          >
            {t(isPreviewMode ? 'Exit fullscreen' : isStatic ? 'Open fullscreen' : 'Preview')}
          </Button>
        )}
      </Box>
      <Grid container spacing={2}>
        <Grid item sm={6}>
          <FlexWrapper className="green-wrapper" title={t('Agenda')}>
            <RichTextEditor
              attachments={values.agenda?.attachments || []}
              bounds=".green-wrapper"
              defaultFormat="bold"
              errorMsg={!isPreviewMode && !isStatic ? errors.agenda : null}
              formattingOptions={EditorConfig.FORMATTING_OPTIONS}
              isStatic={isPreviewMode || isStatic}
              onBeforeImageUpload={onBeforeImageUpload}
              onChange={(key, data) => handleTextEditorChange(key, data, 'agenda')}
              placeholder={t(
                'Describe what you’re going to cover in the scope of classroom activities',
              )}
              resourceId={lessonId}
              resourceType={AttachmentsResourcesTypes.LESSON_AGENDA_BOARD_AGENDA}
              value={values.agenda?.textJson || EditorConfig.EMPTY_TEXT}
            />
          </FlexWrapper>
        </Grid>
        <Grid container direction="column" item sm={6} spacing={2}>
          <Grid item>
            <FlexWrapper
              action={
                metaData.teksData?.length > 0 && !isPreviewMode && !isStatic ? (
                  <EditingButton onClick={handlePasteTeks} text={t('Paste from added TEKS')} />
                ) : null
              }
              className="yellow-wrapper"
              title={t('TEKS')}
            >
              <RichTextEditor
                attachments={values.teks?.attachments || []}
                bounds=".yellow-wrapper"
                defaultFormat="bold"
                errorMsg={!isPreviewMode && !isStatic ? errors.teks : null}
                formattingOptions={EditorConfig.FORMATTING_OPTIONS}
                isStatic={isPreviewMode || isStatic}
                onBeforeImageUpload={onBeforeImageUpload}
                onChange={(key, data) => handleTextEditorChange(key, data, 'teks')}
                placeholder={t('Start describing your TEKS for students here')}
                resourceId={lessonId}
                resourceType={AttachmentsResourcesTypes.LESSON_AGENDA_BOARD_TEKS}
                value={values.teks?.textJson || EditorConfig.EMPTY_TEXT}
              />
            </FlexWrapper>
          </Grid>
          <Grid item>
            <FlexWrapper className="blue-wrapper" title={t('Reminders')}>
              <RichTextEditor
                attachments={values.reminders?.attachments || []}
                bounds=".blue-wrapper"
                defaultFormat="bold"
                errorMsg={!isPreviewMode && !isStatic ? errors.reminders : null}
                formattingOptions={EditorConfig.FORMATTING_OPTIONS}
                isStatic={isPreviewMode || isStatic}
                onBeforeImageUpload={onBeforeImageUpload}
                onChange={(key, data) => handleTextEditorChange(key, data, 'reminders')}
                placeholder={t('Add due dates for the assignments or projects')}
                resourceId={lessonId}
                resourceType={AttachmentsResourcesTypes.LESSON_AGENDA_BOARD_REMINDERS}
                value={values.reminders?.textJson || EditorConfig.EMPTY_TEXT}
              />
            </FlexWrapper>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

LessonPageAgendaBoard.propTypes = {
  errors: PropTypes.instanceOf(Object),
  isStatic: PropTypes.bool.isRequired,
  lessonId: PropTypes.string.isRequired,
  metaData: PropTypes.instanceOf(Object).isRequired,
  onBeforeImageUpload: PropTypes.func,
  onChange: PropTypes.func,
  showFullScreenButton: PropTypes.bool,
  values: PropTypes.shape({
    agenda: PropTypes.instanceOf(Object),
    teks: PropTypes.instanceOf(Object),
    reminders: PropTypes.instanceOf(Object),
  }).isRequired,
};

LessonPageAgendaBoard.defaultProps = {
  errors: {},
  onBeforeImageUpload: null,
  onChange: null,
  showFullScreenButton: true,
};

export default LessonPageAgendaBoard;
