import { ReactComponent as EvidenceEmptyStateIllustrationLA } from '../resources/images/evidence_of_growth_epmty_screen_la.svg';
import { ReactComponent as EvidenceEmptyStateIllustrationGoals } from '../resources/images/evidence_of_growth_epmty_screen_goals.svg';
import visionImage from '../resources/images/landing_vision.svg';
import mainImage from '../resources/images/landing_main_illustration.svg';
import studentImage from '../resources/images/landing_student.svg';
import educatorImage from '../resources/images/landing_educator.svg';
import parentImage from '../resources/images/landing_parent.svg';
import inspirationBookPhoto from '../resources/images/landing_inspiration_book.png';
import riveraImage from '../resources/images/dr_rivera.svg';
import telemundodallasVideoThumbnail from '../resources/images/landing_media_preview_telemundodallas.png';
import dallasNewsImage from '../resources/images/landing_media_preview_dallas_news.png';
import { checkEnvironmentVariable } from '../utils/app-helpers/appHelpers';

import {
  dallasNewsArticleRoute,
  downloadInspirationBookEnglishRoute,
  downloadInspirationBookSpanishRoute,
  faqRoute,
  privacyPolicyRoute,
  telemundodallasVideoRoute,
} from './routes';
import { LessonsScheduleSettings, LoginProvidersMap, RolesMap, SchoolLevels } from './enums';

export const landingBlockConfig = {
  imageTitle: 'Two students and a teacher, at a crossroads',
  titleStart: 'A unique journey',
  titleEnd: 'in the educational landscape',
  titleBottom: `If you're a student, parent, teacher, coach or counselor - start your journey with AYO`,
  loginOptions: [
    {
      loginProvider: LoginProvidersMap.CLASSLINK,
      userRole: RolesMap.STUDENT,
      label: 'Student login',
      disabled: checkEnvironmentVariable('REACT_APP_DISABLE_STUDENT_LOGIN', 'true'),
    },
    {
      loginProvider: LoginProvidersMap.SKYWARD,
      userRole: RolesMap.GUARDIAN,
      label: 'Parent login',
      disabled: checkEnvironmentVariable('REACT_APP_DISABLE_GUARDIAN_LOGIN', 'true'),
    },
    {
      loginProvider: LoginProvidersMap.CLASSLINK,
      userRole: RolesMap.TEACHER,
      label: 'Educator login',
      disabled: checkEnvironmentVariable('REACT_APP_DISABLE_TEACHER_LOGIN', 'true'),
    },
  ],
  instructionText:
    'For other members of Mesquite ISD community please scroll down and find the Inspiration Book',
  mainImg: mainImage,
};

export const personalizationBlockConfig = {
  mainTitle: 'Individualize your learning',
  firstSubtitle:
    'AYO is here to help students discover their potential by asking questions and then providing insights to inform student choices and help them succeed Download our',
  firstSubtitleBold: 'Inspiration Book',
  firstSubtitleEnd: 'to learn more about AYO',
  downloadOptions: [
    {
      ariaLabel: 'Download Inspiration Book in English',
      gaLabel: 'Download Inspiration Book in English',
      label: 'Download in English',
      link: downloadInspirationBookEnglishRoute,
    },
    {
      ariaLabel: 'Descargarlo Libro de inspiración en Español',
      gaLabel: 'Download Inspiration Book in Spanish',
      label: 'Descargarlo en Español',
      link: downloadInspirationBookSpanishRoute,
    },
  ],
  illustrationText:
    'Three recent high school graduates are jumping and celebrating their graduation',
  illustrationImg: visionImage,
};

export const personasBlockConfig = {
  title: 'Who is AYO for',
  personaItems: [
    {
      img: studentImage,
      avatarTxt: 'A portrait of a student',
      title: 'Students',
      text: 'AYO focuses on students as individuals by asking questions and encouraging them to learn more about themselves. AYO helps students understand their interests, passions, aptitudes and talents and shows them where they align',
    },
    {
      img: educatorImage,
      avatarTxt: 'A portrait of a teacher',
      title: 'Educators',
      text: 'AYO is also built for educators to help them individualize their teaching approach to meet the needs of each student',
    },
    {
      img: parentImage,
      avatarTxt: 'A portrait of a parent',
      title: 'Parents',
      text: 'AYO helps parents support their children throughout their school years by providing an objective overview of their child’s aptitudes and passions.',
    },
  ],
};

export const visionArticleConfig = {
  title: 'Our Vision',
  textItems: [
    {
      variant: 'body1',
      textNodes: [
        {
          translationText:
            'AYO is here to help students reveal their potential so that each student could experience an individualized learning approach',
        },
      ],
    },
    {
      variant: 'body1',
      textNodes: [
        {
          translationText:
            'To uncover students’ potential, AYO communicates with students, teachers, & parents as well as gathers information about individual students to understand students’ passions and aptitudes and find where they overlap',
        },
      ],
    },
    {
      variant: 'body1',
      textNodes: [
        {
          translationText:
            'AYO never intrudes into the learning process or makes decisions for any of its users',
        },
        {
          isEmpty: true,
        },
        {
          translationText: 'AYO neither judges any of its users nor makes any conclusions',
        },
        {
          isEmpty: true,
        },
        {
          translationText: 'Please see our',
        },
        {
          isEmpty: true,
        },
        {
          link: {
            route: privacyPolicyRoute,
            className: 'ayo-home-page__contained-link',
            gaLabel: 'Privacy Policy',
            translationText: 'Privacy Policy',
          },
        },
        {
          isEmpty: true,
        },
        {
          translationText: 'and',
        },
        {
          isEmpty: true,
        },
        {
          link: {
            route: faqRoute,
            className: 'ayo-home-page__contained-link',
            gaLabel: 'Frequently Asked Questions',
            translationText: 'FAQ',
          },
        },
        {
          isEmpty: true,
        },
        {
          translationText: 'for additional details',
        },
      ],
    },
  ],
};

export const homeInspirationBookImg = {
  src: inspirationBookPhoto,
  altTxt: 'Inspiration book',
  className: 'ayo-home-page__vision-block--inspiration-book',
  caption: {
    variant: 'body2',
    className: 'ayo-home-page__instruction-text',
    // align: isWidthUpMd ? 'center' : 'left', - TODO - think how to make it configurable
    textNodes: [
      {
        translationText: 'Download the',
      },
      {
        isEmpty: true,
      },
      {
        translationText: 'Inspiration Book',
        isBold: true,
      },
      {
        isEmpty: true,
      },
      {
        link: {
          route: downloadInspirationBookEnglishRoute,
          isDownload: true,
          className: 'ayo-home-page__contained-link',
          gaLabel: 'Download Inspiration Book in English',
          plainText: 'in English',
        },
      },
      {
        isEmpty: true,
      },
      {
        translationText: 'or',
      },
      {
        isEmpty: true,
      },
      {
        link: {
          route: downloadInspirationBookSpanishRoute,
          isDownload: true,
          className: 'ayo-home-page__contained-link',
          gaLabel: 'Download Inspiration Book in Spanish',
          plainText: 'en Español',
        },
      },
      {
        isEmpty: true,
      },
      {
        translationText: 'to learn more about our vision',
      },
    ],
  },
};

export const timelineBlock = {
  items: [
    {
      content: {
        variant: 'body3',
        className: 'ayo-timeline--current',
        text: 'This is happening now',
      },
      opposite: {
        variant: 'body2',
        text: 'AYO is getting to know each student personally to learn about their individual passions and aptitudes',
      },
    },
    {
      content: {
        variant: 'body2',
        text: 'AYO will learn and understand each individual student as they grow up and change to help them build their own educational path',
      },
    },
    {
      opposite: {
        variant: 'body2',
        text: 'AYO assists students in their learning by helping them make informed choices to maximize their potential',
      },
    },
  ],
};

export const quoteBlock = {
  avatarTxt: 'Dr. Rivera',
  avatarImg: riveraImage,
  name: 'Dr. Rivera',
  title: 'Mesquite ISD Superintendant',
  text: 'The education landscape has to evolve from a standardized system, designed to create workers who manage fixed processes to one that creates owners who operate in dynamic and evolving landscapes',
};

export const mediaBlock = {
  title: 'AYO in media',
  subTitle: 'Check out the latest articles and videos from the media around us',
  items: [
    {
      link: telemundodallasVideoRoute,
      actionTxt: 'Watch the video',
      img: telemundodallasVideoThumbnail,
      imgAlt: 'A picture with Great Joy words and the AYO logo, which is a video preview',
      content:
        'El Distrito Escolar de Mesquite ofrece un innovador sistema que le ayuda a los estudiantes a descubrir sus pasiones y aptitudes Aquí los detalles',
      contentLabel: 'Video content',
      date: '2020-11-23',
      sourceName: 'Telemundodallas',
      title: 'Innovador sistema de educación en Mesquite',
      mediaClickable: true,
    },
    {
      link: dallasNewsArticleRoute,
      actionTxt: 'Read the article',
      img: dallasNewsImage,
      imgAlt: 'A woman is helping a student child to study by using a laptop',
      content:
        'The tool, in partnership with Google and SoftServe, aims to show students where their abilities and interests intersect to help them stay engaged in the learning process It has been a long time since fundamental changes have been made to the way',
      contentLabel: '',
      date: '2020-09-14',
      sourceName: 'Dallas News',
      title: 'Mesquite ISD looks to bring education into the digital age with AYO project',
    },
  ],
};

export const roadMapBlock = {
  timeline: timelineBlock,
  caption: {
    variant: 'h2',
    align: 'center',
    component: 'h2',
    textNodes: [
      {
        translationText: 'AYO launches this year and is going to unfold in the years to come',
      },
      {
        isEmpty: true,
      },
      {
        translationText: 'Here is how AYO is going to affect learning in the near future',
      },
    ],
  },
  titleTop: {
    variant: 'subtitle1',
    align: 'h3',
    isParagraph: true,
    textNodes: [
      {
        translationText: 'Before AYO',
      },
    ],
  },
  subTitleTop: {
    variant: 'body2',
    align: 'center',
    textNodes: [
      {
        translationText:
          'Students studied in the traditional standardized way, where everyone learns the same material in the same way, regardless of their potential',
      },
    ],
  },
  titleBottom: {
    variant: 'subtitle1',
    align: 'h3',
    isParagraph: true,
    textNodes: [
      {
        translationText: 'With AYO',
      },
    ],
  },
  subTitleBottom: {
    variant: 'body2',
    align: 'center',
    textNodes: [
      {
        translationText: 'Each student receives a tailored learning experience',
      },
      {
        isEmpty: true,
      },
      {
        translationText:
          'Students are able to make informed choices as they understand where their potential lies',
      },
    ],
  },
};

export const leadershipAttributesGraphConfig = {
  axisLineColor: '#C0C0C0',
  datasets: [
    {
      backgroundColor: 'transparent',
      borderColor: '#B455BC',
      borderWidth: 3,
      key: 'LEADING_SELF',
      label: 'Leading self',
      pointBackgroundColor: '#B455BC',
      pointRadius: 2,
      order: 3,
    },
    {
      backgroundColor: 'transparent',
      borderColor: '#5D82E0',
      borderDash: [12, 5],
      key: 'LEADING_OTHERS',
      label: 'Leading others',
      pointBackgroundColor: '#5D82E0',
      pointRadius: 4,
      pointStyle: 'rect',
      order: 2,
    },
    {
      backgroundColor: 'transparent',
      borderColor: '#1F224A',
      borderDash: [8, 3],
      borderWidth: 3,
      key: 'LEADING_CHANGE',
      label: 'Leading change',
      pointBackgroundColor: '#1F224A',
      pointRadius: 0,
      order: 1,
    },
  ],
  maxValue: 10,
  minValue: 0,
};

export const evidencesConfigByType = {
  leadership_attribute: {
    type: 'LEADERSHIP_ATTRIBUTE',
    emptyStateImg: EvidenceEmptyStateIllustrationLA,
    emptyStateText:
      'But you can assess any of your Leadership Attribute(s) yourself and add your materials there',
    emptyStateAlt: 'A file, a note and two pencils',
  },
  goal: {
    type: 'GOAL',
    emptyStateImg: EvidenceEmptyStateIllustrationGoals,
    emptyStateText: 'But you can add evidence of growth to any of your goals!',
    emptyStateAlt: 'A file, a note and two pencils',
  },
};

export const scheduleTypesConfig = [
  {
    key: LessonsScheduleSettings.ROTATION_DAYS_SCHEDULE,
    defaultSchoolLevels: [SchoolLevels.MIDDLE, SchoolLevels.HIGH],
    description:
      'Select as many repetitive days as you need. If your schedule repeats every day, select A day only',
    label: 'Rotation days',
    title: 'Schedules differ from day to day (A-B days)',
    values: {
      1: {
        value: 'A',
        label: 'Day',
      },
      2: {
        value: 'B',
        label: 'Day',
      },
    },
    valuesLabel: 'day(s)',
  },
  {
    key: LessonsScheduleSettings.ROTATION_WEEKS_SCHEDULE,
    defaultSchoolLevels: [SchoolLevels.ELEMENTARY],
    description:
      'Select as many repetitive weeks as you need. If your schedule repeats every week, select 1 week only',
    label: 'Rotation weeks',
    title: 'Schedules differ from week to week (week 1 - week 5)',
    values: {
      1: {
        value: 1,
        label: 'Week',
      },
      2: {
        value: 2,
        label: 'Week',
      },
      3: {
        value: 3,
        label: 'Week',
      },
      4: {
        value: 4,
        label: 'Week',
      },
      5: {
        value: 5,
        label: 'Week',
      },
    },
    valuesLabel: 'week(s)',
  },
];

export const workingDaysConfig = [
  {
    label: 'Mon',
    value: 'MONDAY',
  },
  {
    label: 'Tue',
    value: 'TUESDAY',
  },
  {
    label: 'Wed',
    value: 'WEDNESDAY',
  },
  {
    label: 'Thu',
    value: 'THURSDAY',
  },
  {
    label: 'Fri',
    value: 'FRIDAY',
  },
];

export default {
  evidencesConfigByType,
  homeInspirationBookImg,
  landingBlockConfig,
  leadershipAttributesGraphConfig,
  mediaBlock,
  personalizationBlockConfig,
  personasBlockConfig,
  quoteBlock,
  roadMapBlock,
  scheduleTypesConfig,
  workingDaysConfig,
};
