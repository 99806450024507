/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery';
import Box from '@mui/material/Box';

import { RichText } from '../../moleculas';
import { imgWithCaption } from '../../../constants/propTypes';

const ImgWithCaption = ({ config }) => {
  const theme = useTheme();
  const isWidthUpMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box className={config.className} mb={2} pl={isWidthUpMd ? 15 : 0}>
      <img alt={config.altTxt} src={config.src} width="100%" />
      <RichText
        align={config.caption.align}
        className={config.caption.className}
        items={config.caption.textNodes}
        variant={config.caption.variant}
      />
    </Box>
  );
};

ImgWithCaption.propTypes = {
  config: imgWithCaption,
};

ImgWithCaption.defaultProps = {
  config: {},
};

export default ImgWithCaption;
