import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Typography } from '../../../../atoms';
import { DisplayAvatarEditorImage } from '../../../../moleculas';
import { useInView } from '../../../../../hooks';
import { getFormattedChatMessageDate, getFullName } from '../../../../../utils';

const ChatMessage = ({ author, isOwnMessage, markAsRead, message }) => {
  const { i18n, t } = useTranslation();

  const authorName = useMemo(() => author.name || getFullName(author), [author]);

  const label = useMemo(() => {
    const formattedDate = getFormattedChatMessageDate(message.createdDate, i18n.language);

    return `${!isOwnMessage && authorName ? authorName : ''} ${formattedDate}`;
  }, [authorName, i18n.language, isOwnMessage, message.createdDate]);

  const { ref } = useInView(markAsRead, 0, message.isRead);

  return (
    <Box
      className={classNames('family-feed-chat-message', {
        'family-feed-chat-message--own': isOwnMessage,
      })}
      data-message-id={message.id}
    >
      <Typography className="family-feed-chat-message__label" isLabel variant="caption">
        {label}
      </Typography>
      <Box className="family-feed-chat-message__body">
        <Box>
          <DisplayAvatarEditorImage
            alt={t('author avatar image alt', { authorName })}
            avatar={author?.avatar?.image}
            avatarSize={48}
            avatarTransforms={author?.avatar?.transforms}
          />
        </Box>
        <Typography className="family-feed-chat-message__body__text" p={2} variant="body2">
          {message.text}
        </Typography>
        <span ref={ref} />
      </Box>
    </Box>
  );
};

ChatMessage.propTypes = {
  author: PropTypes.instanceOf(Object).isRequired,
  markAsRead: PropTypes.func.isRequired,
  message: PropTypes.shape({
    id: PropTypes.number,
    userId: PropTypes.number,
    text: PropTypes.string,
    isRead: PropTypes.bool,
    createdDate: PropTypes.string,
  }).isRequired,
  isOwnMessage: PropTypes.string.isRequired,
};

export default ChatMessage;
