import React, { useReducer, createContext } from 'react';
import PropTypes from 'prop-types';

import { NotificationSourcesMap } from '../../constants/enums';

export const NotificationsContext = createContext();

export const NotificationsActions = {
  SET_NOTIFICATIONS_LIST: 'SET_NOTIFICATIONS_LIST',
  SET_NOTIFICATION_ITEMS: 'SET_NOTIFICATION_ITEMS',
  ADD_NOTIFICATION: 'ADD_NOTIFICATION',
};

const initState = {
  notificationsList: null,
  receivedNewCount: 0,
};

export const reducer = (state, action) => {
  let updatedList;
  switch (action.type) {
    case NotificationsActions.SET_NOTIFICATIONS_LIST:
      return { ...state, notificationsList: action.data };
    case NotificationsActions.SET_NOTIFICATION_ITEMS:
      updatedList = JSON.parse(
        JSON.stringify(state.notificationsList[action.data.notificationType]),
      );
      action.data.items.forEach((item) => {
        updatedList[updatedList.findIndex((i) => i.id === item.id)] = item;
      });
      return {
        ...state,
        notificationsList: {
          ...state.notificationsList,
          [action.data.notificationType]: [...updatedList],
        },
      };
    case NotificationsActions.ADD_NOTIFICATION:
      return {
        ...state,
        notificationsList: {
          ...state.notificationsList,
          [action.data.notificationSources[0]]: [
            action.data,
            ...(state.notificationsList[action.data.notificationSources[0]] || []),
          ],
        },
        receivedNewCount:
          action.data.notificationSources[0] === NotificationSourcesMap.WEB_GENERAL
            ? state.receivedNewCount + 1
            : state.receivedNewCount,
      };
    default:
      throw new Error();
  }
};

export const NotificationsContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initState);

  return (
    <NotificationsContext.Provider value={{ state, dispatch }}>
      {children}
    </NotificationsContext.Provider>
  );
};

NotificationsContextProvider.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
};
