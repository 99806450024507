import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { BottomBar, PageWrapper } from '../../organisms';
import { initiativesRoute } from '../../../constants/routes';

import {
  LessonPlannerAboutSection,
  LessonPlannerDefinitionSection,
  LessonPlannerExploreMoreSection,
  LessonPlannerFaqSection,
  LessonPlannerForWhoSection,
  LessonPlannerHowItWorksSection,
  LessonPlannerWhatIsItSection,
} from './components';

const LessonPlannerPage = () => {
  const { t } = useTranslation();

  return (
    <PageWrapper
      backToLink={initiativesRoute}
      backToText={t('Go to AYO’s initiatives page')}
      className="ayo-lesson-planner-landing"
      customFooter={<BottomBar noNav />}
      noNav
    >
      <Box className="ayo-lesson-planner-landing__sections">
        <LessonPlannerDefinitionSection />
        <LessonPlannerWhatIsItSection />
        <LessonPlannerForWhoSection />
        <LessonPlannerHowItWorksSection />
        <LessonPlannerAboutSection />
        <LessonPlannerFaqSection />
        <LessonPlannerExploreMoreSection />
      </Box>
    </PageWrapper>
  );
};

export default LessonPlannerPage;
