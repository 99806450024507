import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { Rating } from '../../atoms';
import { ReactComponent as AchivedStarImage } from '../../../resources/images/yellow_star.svg';
import { ReactComponent as DefaultStarImage } from '../../../resources/images/hollow_star.svg';

const BadgesRating = ({ badge }) => {
  const { t } = useTranslation();

  const badgeRate = useMemo(
    () =>
      badge?.rating.reduce(
        (acc, rateItem, currentIndex) => ({
          ...acc,
          [currentIndex + 1]: {
            icon: rateItem?.completionDate ? <AchivedStarImage /> : <DefaultStarImage />,
          },
        }),
        {},
      ),
    [badge],
  );

  const getIconContainer = useCallback(({ value }) => <>{badgeRate[value]?.icon}</>, [badgeRate]);

  const isOneTimeachievement = useMemo(() => Object.keys(badgeRate).length > 1, [badgeRate]);

  return (
    <Box className="ayo-badge-card__rating">
      {isOneTimeachievement && (
        <Rating
          aria-label={t('Completed out of default stars achieved', {
            completed: badge.completionLevels?.length || 0,
            default: badge.defaultLevels.length,
          })}
          IconContainerComponent={getIconContainer}
          readOnly
        />
      )}
    </Box>
  );
};

BadgesRating.propTypes = {
  badge: PropTypes.instanceOf(Object).isRequired,
};

export default BadgesRating;
