import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';

import { Button } from '../../../atoms';
import {
  PageIllustrationBlock,
  PreviousPageButton,
  ScreenCenteredContainer,
} from '../../../moleculas';
import { SupportDialog, PageWrapper } from '../../../organisms';
import { ReactComponent as ServerSleepingImage } from '../../../../resources/images/error_500_something_not_right.svg';
import { SupportOptionsMap } from '../../../../constants/enums';
import { UserContext } from '../../../../context';

const ServerErrorPage = () => {
  const { t } = useTranslation();

  const { state: userState } = useContext(UserContext);

  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);

  const toggleFeedbackModal = () => {
    setIsFeedbackModalOpen((value) => !value);
  };

  return (
    <PageWrapper noNav>
      <ScreenCenteredContainer>
        <PageIllustrationBlock
          Illustration={ServerSleepingImage}
          illustrationAriaLabel={t(
            'A nest on the tree with a server hatching eggs and two colorful birds flying around',
          )}
          mainHeaderText={t('Oops! Something is not right')}
          secondaryHeaderText={[
            t('The server can’t process your request now'),
            t('You can report an issue, and we’ll try to bring it back to its duties'),
          ]}
        />
        <Grid container direction="column" spacing={2}>
          {userState.profile && (
            <Grid container item justifyContent="center">
              <Button
                gaLabel="Report an issue"
                isAdaptive
                onClick={toggleFeedbackModal}
                variant="primary"
              >
                {t('Report an issue')}
              </Button>
            </Grid>
          )}
          <Grid container item justifyContent="center">
            <PreviousPageButton variant={userState.profile ? 'text' : 'primary'} />
          </Grid>
        </Grid>
      </ScreenCenteredContainer>
      <SupportDialog
        isOpen={isFeedbackModalOpen}
        mode={SupportOptionsMap.REPORT_ISSUE}
        onClose={toggleFeedbackModal}
      />
    </PageWrapper>
  );
};

export default ServerErrorPage;
