/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Dialog } from '../../atoms';

const SideDialog = ({
  backDropClassName,
  children,
  className,
  gaLabel,
  onClose,
  isOpen,
  ...props
}) => (
  <Dialog
    backDropClassName={backDropClassName}
    className={classNames('ayo-side-dialog', className)}
    fullScreen
    gaLabel={gaLabel}
    onClose={onClose}
    open={isOpen}
    {...props}
  >
    {children}
  </Dialog>
);

SideDialog.propTypes = {
  backDropClassName: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  className: PropTypes.string,
  gaLabel: PropTypes.string,
  onClose: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
};

SideDialog.defaultProps = {
  backDropClassName: '',
  children: null,
  className: '',
  gaLabel: null,
  onClose: () => {},
};

export default SideDialog;
