import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DialogContentText, Box, DialogContent } from '@mui/material';

import { Dialog, DialogTitle, Typography } from '../../atoms';
import BottomNavigation from '../bottom-navigation/BottomNavigation';

const LeadershipAttributesDialog = ({ attributes, index, isOpen, navigationHandler, onClose }) => {
  const { t } = useTranslation();

  if (index === null) {
    return null;
  }

  return (
    <Dialog
      className="ayo-leadership-attributes-dialog"
      onClose={onClose}
      open={isOpen}
      scroll="paper"
    >
      <DialogTitle disableTypography>
        <Box mb={4}>
          <Typography component="h2" paragraph variant="subtitle1">
            {t(attributes[index]?.key)}
          </Typography>
          <Typography variant="body2">{t(attributes[index]?.definition)}</Typography>
        </Box>
        <Typography component="h3" paragraph variant="subtitle2">
          {t('Maturity levels')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Box className="ayo-leadership-attributes-dialog--text">
            <Box mb={4}>
              <Typography variant="subtitle2">{t('Leading self')}</Typography>
              {attributes[index]?.details.leadingSelf.map((definition) => (
                <Typography key={definition} variant="body2">
                  {t(definition)}
                </Typography>
              ))}
            </Box>
            <Box mb={4}>
              <Typography paragraph variant="subtitle2">
                {t('Leading others')}
              </Typography>
              {attributes[index]?.details.leadingOthers.map((definition) => (
                <Typography key={definition} variant="body2">
                  {t(definition)}
                </Typography>
              ))}
            </Box>
            <Box mb={4}>
              <Typography paragraph variant="subtitle2">
                {t('Leading change')}
              </Typography>
              {attributes[index]?.details.leadingChange.map((definition) => (
                <Typography key={definition} variant="body2">
                  {t(definition)}
                </Typography>
              ))}
            </Box>
          </Box>
        </DialogContentText>
      </DialogContent>
      <Box mt={5}>
        <BottomNavigation
          attributeKey="key"
          attributes={attributes}
          index={index}
          navigationHandler={navigationHandler}
        />
      </Box>
    </Dialog>
  );
};

LeadershipAttributesDialog.propTypes = {
  attributes: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string,
      definition: PropTypes.string,
      details: PropTypes.shape({
        leadingSelf: PropTypes.string,
        leadingOthers: PropTypes.string,
        leadingChange: PropTypes.string,
      }),
    }),
  ).isRequired,
  index: PropTypes.number,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  navigationHandler: PropTypes.func.isRequired,
};

LeadershipAttributesDialog.defaultProps = {
  index: 0,
};

export default LeadershipAttributesDialog;
