import React, { useCallback, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { FormControlLabel, Radio } from '../../../../atoms';
import { InformationalCaption } from '../../../../moleculas';

const ClubFilters = ({ filtersOptions, onFiltersChange, status }) => {
  const { t } = useTranslation();

  const [filterValue, setFilterValue] = useState(
    filtersOptions[status]?.status || filtersOptions.ALL.status,
  );

  const filtersChangeHandler = useCallback(
    (e) => {
      const { value } = e.target;
      setFilterValue(value);
      onFiltersChange(value);
    },
    [onFiltersChange],
  );

  return (
    <Grid container spacing={4} xs={12}>
      {Object.values(filtersOptions).map((value) => (
        <Grid key={value.status} item>
          <FormControlLabel
            control={
              <Radio
                checked={filterValue === value.status}
                gaLabel={`Clubs filter: ${value.status}`}
                name={value.status}
                onChange={filtersChangeHandler}
                value={value.status}
              />
            }
            label={
              <Box alignItems="center" display="flex" flexDirection="row">
                {t(`club_hub_filters.${value.status}`)}
                {value.tooltipLabel && (
                  <Box ml={1}>
                    <InformationalCaption showTooltip title={value.tooltipLabel} />
                  </Box>
                )}
              </Box>
            }
          />
        </Grid>
      ))}
    </Grid>
  );
};

ClubFilters.propTypes = {
  filtersOptions: PropTypes.instanceOf(Object).isRequired,
  onFiltersChange: PropTypes.func.isRequired,
  status: PropTypes.string,
};

ClubFilters.defaultProps = {
  status: null,
};

export default ClubFilters;
