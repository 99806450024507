import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Collapse, TableCell, TableRow } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { Link, Typography } from '../../../../../../../atoms';
import {
  EditingButton,
  TextWithTooltip,
  Table,
  CustomPagination,
} from '../../../../../../../moleculas';
import { ClubStatus } from '../../../../../../../organisms';
import {
  ClubsMembershipStatuses,
  ClubsMembersQueryParams,
} from '../../../../../../../../constants/club-hub';
import { StudentsPage } from '../../../../../../../../constants/enums';
import { clubMemberType } from '../../../../../../../../constants/propTypes';
import { UserContext } from '../../../../../../../../context';
import { getRelativeDateTime } from '../../../../../../../../utils';
import { ReactComponent as ExpandIcon } from '../../../../../../../../resources/icons/chevron_down.svg';
import { ReactComponent as CollapseIcon } from '../../../../../../../../resources/icons/chevron_up.svg';
import { ReactComponent as LinkIcon } from '../../../../../../../../resources/icons/link.svg';
import { ReactComponent as LinkSecondaryIcon } from '../../../../../../../../resources/icons/add_link.svg';

import ClubMembersTableActions from './components/club-members-table-actions/ClubMembersTableActions';

const ClubTableRow = ({ clubId, clubName, isEditable, onStatusChange, rowData }) => {
  const { t, i18n } = useTranslation();

  const { state: userState } = useContext(UserContext);

  const { userId, userName, declineReason, membershipChangeDate, membershipStatus, requirements } =
    rowData;

  const { notes, links } = requirements;

  const [open, setOpen] = useState(false);

  const withRequirements = useMemo(() => !!(notes || links?.length), [links?.length, notes]);

  useEffect(() => {
    setOpen(membershipStatus === ClubsMembershipStatuses.PENDING_REQUEST);
  }, [membershipStatus]);

  const CellsConfig = useMemo(
    () =>
      [
        {
          id: uuidv4(),
          renderCell: (
            <Link
              className="ayo-club-hub-details__members-section__cell__link"
              gaLabel="Student link"
              href={`${StudentsPage[userState.profile?.role]}/${userId}`}
              icon={<LinkIcon />}
              target="_blank"
              underline="none"
            >
              <TextWithTooltip className="ayo-link" title={userName} />
            </Link>
          ),
        },
        {
          id: uuidv4(),
          renderCell: (
            <TextWithTooltip
              title={getRelativeDateTime(membershipChangeDate, i18n.language, t)}
              titleVariant="body2"
            />
          ),
        },
        {
          id: uuidv4(),
          renderCell: <ClubStatus reason={declineReason} status={membershipStatus} />,
        },
        isEditable && {
          id: uuidv4(),
          renderCell: (
            <ClubMembersTableActions
              clubId={clubId}
              clubName={clubName}
              membershipStatus={membershipStatus}
              onStatusChange={onStatusChange}
              studentId={userId}
              studentName={userName}
            />
          ),
        },
      ].filter((item) => item),
    [
      clubId,
      clubName,
      declineReason,
      i18n.language,
      isEditable,
      membershipChangeDate,
      membershipStatus,
      onStatusChange,
      t,
      userId,
      userName,
      userState.profile?.role,
    ],
  );

  return (
    <>
      <TableRow>
        {CellsConfig.map(({ id, renderCell }) => (
          <TableCell key={id}>
            <Box
              className={classnames('ayo-club-hub-details__members-section__cell', {
                'ayo-club-hub-details__members-section__cell--simple': !withRequirements,
              })}
              mb={withRequirements ? 0 : 1}
              px={2}
            >
              {renderCell}
            </Box>
          </TableCell>
        ))}
      </TableRow>
      {withRequirements && (
        <TableRow>
          <TableCell className="test" colSpan={4}>
            <Box className="ayo-club-hub-details__members-section__requirements" mb={1}>
              <Collapse in={open}>
                <Box
                  className="ayo-club-hub-details__members-section__requirements__content"
                  gap={3}
                  mb={2}
                  mx={1}
                  p={3}
                >
                  <Typography isLabel mb={1} variant="body3">
                    {t('Shared note')}
                  </Typography>
                  {notes && (
                    <Typography mb={links.length ? 2 : 0} variant="body2">
                      {notes}
                    </Typography>
                  )}
                  {!!links.length &&
                    links.map(({ url }) => (
                      <Link
                        key={url}
                        className="ayo-club-requirements-link"
                        href={url}
                        icon={<LinkSecondaryIcon />}
                        iconPosition="start"
                        target="_blank"
                      >
                        <TextWithTooltip title={url} />
                      </Link>
                    ))}
                </Box>
              </Collapse>
              <EditingButton
                gaLabel={open ? 'Show less' : 'Show more'}
                icon={!open ? <ExpandIcon /> : <CollapseIcon />}
                iconPosition="end"
                onClick={() => setOpen((prevState) => !prevState)}
                text={t(open ? 'Show less' : 'Show more')}
              />
            </Box>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

ClubTableRow.propTypes = {
  clubId: PropTypes.number.isRequired,
  clubName: PropTypes.string.isRequired,
  isEditable: PropTypes.bool.isRequired,
  onStatusChange: PropTypes.func.isRequired,
  rowData: PropTypes.shape(clubMemberType).isRequired,
};
const ClubMembersTable = ({
  clubId,
  clubName,
  isEditable,
  members,
  onQueryParamsChange,
  onStatusChange,
  page,
  pageSize,
  totalElements,
}) => {
  const { t } = useTranslation();

  const TableHeaderItems = useMemo(
    () =>
      [
        { label: t('Student'), id: uuidv4() },
        { label: t('Date requested'), id: uuidv4() },
        { label: t('Status'), id: uuidv4() },
        isEditable && { label: t('Actions'), id: uuidv4(), align: 'right' },
      ].filter((item) => item),
    [isEditable, t],
  );

  return (
    <>
      {!!members?.length && (
        <Table
          columns={TableHeaderItems.map((item) => ({
            ...item,
            width: `${100 / TableHeaderItems.length}%`,
          }))}
          rows={members.map((member) => (
            <ClubTableRow
              key={member.userId}
              clubId={clubId}
              clubName={clubName}
              isEditable={isEditable}
              onStatusChange={onStatusChange}
              rowData={member}
            />
          ))}
        />
      )}
      {totalElements > pageSize && (
        <CustomPagination
          onPageChange={(currentPage) =>
            onQueryParamsChange({ [ClubsMembersQueryParams.PAGE]: currentPage })
          }
          pageSize={pageSize}
          rowCount={totalElements}
          selectedPage={page}
        />
      )}
    </>
  );
};

ClubMembersTable.propTypes = {
  clubId: PropTypes.number.isRequired,
  clubName: PropTypes.string.isRequired,
  isEditable: PropTypes.bool.isRequired,
  members: PropTypes.arrayOf(PropTypes.shape(clubMemberType)).isRequired,
  onQueryParamsChange: PropTypes.func.isRequired,
  onStatusChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  totalElements: PropTypes.number.isRequired,
};
export default ClubMembersTable;
