import React, { useCallback, useState } from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

import { eventType } from '../../../constants/propTypes';
import { ConfirmDialog } from '../confirm-dialog/ConfirmDialog';
import { EventDialogModes } from '../../../constants/events';

import EventDialog from './components/events-dialog/EventDialog';
import EventsSideBlock from './events-side-block/EventsSideBlock';
import EventsCalendar from './events-calendar/EventsCalendar';

const EventsVariant = {
  SIDE_BLOCK: 'side-block',
  CALENDAR: 'calendar',
};

const EventsBlock = ({
  allEventUrl,
  emptyStateConfig,
  events,
  fullLabel,
  isEditable,
  onCreate,
  onDelete,
  onUpdate,
  resourceId,
  resourceType,
  selectedEvent,
  sharingLevelOptions,
  showSharingLabels,
  variant,
}) => {
  const [activeEvent, setActiveEvent] = useState(selectedEvent);
  const [isEventDialogOpen, setIsEventDialogOpen] = useState(!!selectedEvent);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [eventDialogMode, setEventDialogMode] = useState(
    selectedEvent ? EventDialogModes.VIEW : null,
  );

  const evenDialogHandler = useCallback((mode, event = null) => {
    setIsEventDialogOpen(true);
    setEventDialogMode(mode);
    setActiveEvent(event);
  }, []);

  const deleteDialogHandler = useCallback((event) => {
    setIsDeleteDialogOpen(true);
    setIsEventDialogOpen(false);
    setActiveEvent(event);
  }, []);

  return (
    <Box className="ayo-events-block" width="100%">
      {variant === EventsVariant.SIDE_BLOCK ? (
        <EventsSideBlock
          allEventUrl={allEventUrl}
          emptyStateConfig={emptyStateConfig}
          events={events}
          fullLabel={fullLabel}
          isEditable={isEditable}
          onDelete={deleteDialogHandler}
          onEventDialogAction={evenDialogHandler}
        />
      ) : (
        <EventsCalendar
          emptyStateConfig={emptyStateConfig}
          events={events}
          fullLabel={fullLabel}
          isEditable={isEditable}
          onDelete={deleteDialogHandler}
          onEventDialogAction={evenDialogHandler}
        />
      )}
      <EventDialog
        key={eventDialogMode}
        event={activeEvent}
        isEditable={isEditable}
        isOpen={isEventDialogOpen}
        mode={eventDialogMode}
        onClose={() => {
          setIsEventDialogOpen(false);
          setEventDialogMode(null);
        }}
        onCreate={onCreate}
        onDelete={deleteDialogHandler}
        onDialogModeChange={(mode) => setEventDialogMode(mode)}
        onUpdate={onUpdate}
        resourceId={resourceId}
        resourceType={resourceType}
        sharingLevelOptions={sharingLevelOptions}
        showSharingLabels={showSharingLabels}
      />
      <ConfirmDialog
        cancelButtonTitle="Cancel"
        confirmButtonTitle="Delete event"
        isOpen={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        onConfirm={() => {
          setIsDeleteDialogOpen(false);
          onDelete(activeEvent);
        }}
        text="Once you delete the event, it’ll be removed from the Club’s page, and no members won’t be able to see it and attend."
        title="Are you sure you want to delete the event?"
      />
    </Box>
  );
};

EventsBlock.propTypes = {
  allEventUrl: PropTypes.string,
  emptyStateConfig: PropTypes.instanceOf(Object),
  events: PropTypes.arrayOf(eventType).isRequired,
  fullLabel: PropTypes.bool,
  isEditable: PropTypes.bool,
  onCreate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  resourceId: PropTypes.number.isRequired,
  resourceType: PropTypes.string.isRequired,
  selectedEvent: eventType,
  sharingLevelOptions: PropTypes.instanceOf(Object),
  showSharingLabels: PropTypes.bool,
  variant: PropTypes.oneOf([EventsVariant.CALENDAR, EventsVariant.SIDE_BLOCK]),
};

EventsBlock.defaultProps = {
  allEventUrl: '',
  emptyStateConfig: null,
  fullLabel: false,
  isEditable: false,
  selectedEvent: null,
  sharingLevelOptions: null,
  showSharingLabels: false,
  variant: EventsVariant.SIDE_BLOCK,
};

export default EventsBlock;
