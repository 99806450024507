import { useCallback } from 'react';

import useAxios, { StatusCodeMap } from '../HttpClient';
import { apiRoute, oneTimeActionRoute } from '../../constants/routes';

const oneTimeActionUrl = `${apiRoute}${oneTimeActionRoute}`;

const useOneTimeActionService = () => {
  const { get, post } = useAxios();

  const getOneTimeActions = useCallback(async () => {
    const { data } = await get({ enabled: true }, oneTimeActionUrl);
    return data.entity.actions;
  }, [get]);

  const postOneTimeAction = useCallback(
    (action) =>
      post(
        { enabled: true },
        oneTimeActionUrl,
        { userAction: action },
        {
          skipDefaultErrorHandlers: [
            StatusCodeMap.UNAUTHORIZED,
            StatusCodeMap.NOT_FOUND,
            StatusCodeMap.BAD_REQUEST,
          ],
        },
      ),
    [post],
  );

  return {
    getOneTimeActions,
    postOneTimeAction,
  };
};

export default useOneTimeActionService;
