import React, { useState, useCallback, useRef, useMemo } from 'react';
import {
  Box,
  ClickAwayListener,
  DialogActions,
  DialogContent,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { CheckboxGroup, Button, Dialog, Typography } from '../../../../atoms';
import { InterestTypes } from '../../../../../constants/enums';
import { getTruncatedArrayString } from '../../../../../utils';
import { ReactComponent as ChevronUp } from '../../../../../resources/icons/chevron_up.svg';
import { ReactComponent as ChevronDown } from '../../../../../resources/icons/chevron_down.svg';
import { ReactComponent as FilterIcon } from '../../../../../resources/icons/filter_list.svg';

const InterestTypesText = {
  [InterestTypes.LIKED]: 'Selected',
  [InterestTypes.SUGGESTED]: 'Suggested',
  [InterestTypes.OTHER]: 'Other',
  [InterestTypes.NOT_INTERESTED]: 'Not interested',
};

const getFilterOptions = (t) => [
  {
    value: InterestTypes.LIKED,
    text: (
      <>
        <span className="ayo-legend-spot selected" />
        {t(InterestTypesText[InterestTypes.LIKED])}
      </>
    ),
  },
  {
    value: InterestTypes.SUGGESTED,
    text: (
      <>
        <span className="ayo-legend-spot suggested" />
        {t(InterestTypesText[InterestTypes.SUGGESTED])}
      </>
    ),
  },
  {
    value: InterestTypes.OTHER,
    text: (
      <>
        <span className="ayo-legend-spot other" />
        {t(InterestTypesText[InterestTypes.OTHER])}
      </>
    ),
  },
  {
    value: InterestTypes.NOT_INTERESTED,
    text: (
      <>
        <span className="ayo-legend-spot not-interested" />
        {t(InterestTypesText[InterestTypes.NOT_INTERESTED])}
      </>
    ),
  },
];

const FiltersCheckboxes = ({ variant, categoryVisibility, onCategoryVisibilityChange }) => {
  const { t } = useTranslation();

  const filterOptions = useMemo(() => getFilterOptions(t), [t]);
  return (
    <CheckboxGroup
      className={classnames('filter-checkboxgroup', variant)}
      defaultValues={categoryVisibility}
      gaLabel="Interests universe filters"
      onChange={(e) => {
        onCategoryVisibilityChange(e);
      }}
      options={filterOptions}
    />
  );
};

FiltersCheckboxes.propTypes = {
  variant: PropTypes.oneOf(['dark', null]),
  categoryVisibility: PropTypes.instanceOf(Object).isRequired,
  onCategoryVisibilityChange: PropTypes.func.isRequired,
};

FiltersCheckboxes.defaultProps = {
  variant: null,
};

const FilterWidget = ({ categoryVisibility, changeCategoryVisibility }) => {
  const [isOpened, setIsOpened] = useState(false);

  const savedFiltersState = useRef(null);

  const toggleOpen = useCallback(() => {
    if (isOpened) {
      savedFiltersState.current = null;
    } else {
      savedFiltersState.current = categoryVisibility;
    }
    setIsOpened((prevOpened) => !prevOpened);
  }, [categoryVisibility, isOpened]);

  const { t } = useTranslation();

  const onClickAway = useCallback(() => {
    if (isOpened) {
      toggleOpen();
    }
  }, [isOpened, toggleOpen]);

  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  const onCategoriesReset = useCallback(
    () => changeCategoryVisibility(savedFiltersState.current),
    [changeCategoryVisibility],
  );

  const onCategoryVisibilityChange = useCallback(
    (e) => {
      changeCategoryVisibility((currentVisibility) => ({
        ...currentVisibility,
        [e.target.value]: e.target.checked,
      }));
    },
    [changeCategoryVisibility],
  );

  const onDialogCancel = useCallback(() => {
    onCategoriesReset();
    toggleOpen();
  }, [onCategoriesReset, toggleOpen]);

  const headerText = useMemo(() => {
    const selectedCategories = getFilterOptions(t)
      .map((filter) => filter.value)
      .filter((category) => categoryVisibility[category])
      .map((category) => t(InterestTypesText[category]));
    return getTruncatedArrayString(selectedCategories, 2);
  }, [categoryVisibility, t]);

  const FilterDialog = (
    <Dialog className="filter-dialog" onClose={onDialogCancel} open={isOpened}>
      <DialogContent>
        <Box mb={3}>
          <Typography variant="subtitle1">{t('View')}</Typography>
        </Box>
        <Box mb={5}>
          <FiltersCheckboxes
            categoryVisibility={categoryVisibility}
            onCategoryVisibilityChange={onCategoryVisibilityChange}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          gaLabel="Interests universe filters edit save"
          onClick={toggleOpen}
          variant="primary"
        >
          {t('Save')}
        </Button>
        <Button
          fullWidth
          gaLabel="Interests universe filters edit cancel"
          onClick={() => {
            onCategoriesReset();
            onDialogCancel();
          }}
        >
          {t('Cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );

  const buttonAriaLabel = `${t('Filters (Selected filters:)')} ${headerText || t('none')})`;

  return isWidthUpSm ? (
    <ClickAwayListener onClickAway={onClickAway}>
      <Box className="filter-widget--container">
        <Button
          aria-expanded={isOpened}
          aria-label={buttonAriaLabel}
          fullWidth
          gaLabel="Interests universe filters dropdown"
          onClick={toggleOpen}
          variant="dark"
        >
          <Box alignItems="center" display="flex" justifyContent="space-between" width="100%">
            {headerText || t('View')}
            {isOpened ? <ChevronUp /> : <ChevronDown />}
          </Box>
        </Button>
        {isOpened && (
          <Box className="filter-widget">
            <FiltersCheckboxes
              categoryVisibility={categoryVisibility}
              onCategoryVisibilityChange={onCategoryVisibilityChange}
              variant="dark"
            />
          </Box>
        )}
      </Box>
    </ClickAwayListener>
  ) : (
    <>
      <Button
        aria-expanded={isOpened}
        aria-label={buttonAriaLabel}
        className="widget-button"
        gaLabel="Open interests map filters"
        isIconButton
        onClick={toggleOpen}
        variant="dark"
      >
        <FilterIcon />
      </Button>
      {FilterDialog}
    </>
  );
};

FilterWidget.propTypes = {
  categoryVisibility: PropTypes.instanceOf(Object).isRequired,
  changeCategoryVisibility: PropTypes.func.isRequired,
};

export default FilterWidget;
