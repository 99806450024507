import React, { useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { Typography, Button, Checkbox, Link, FormControlLabel } from '../../atoms';
import {
  PageIllustrationBlock,
  ScreenCenteredContainer,
  StudentConsentButton,
} from '../../moleculas';
import PrivacyPolicyDialog from '../privacy-policy-dialog/PrivacyPolicyDialog';
import TermsConditionsDialog from '../terms-conditions-dialog/TermsConditionsDialog';
import { UserContext } from '../../../context';
import {
  LanguagesMap,
  LocalStorageKeysMap,
  OneTimeActionsMap,
  RolesMap,
} from '../../../constants/enums';
import { useAgreementService } from '../../../services';
import { useProfile } from '../../../utils';
import { familyFeedRoute, misdAllSchoolsRoute } from '../../../constants/routes';
import { useOneTimeAction } from '../../../hooks';
import { ReactComponent as WelcomeStudentImage } from '../../../resources/images/profiling_welcome_student.svg';
import { ReactComponent as WelcomeEducatorImage } from '../../../resources/images/profiling_welcome_teacher.svg';
import { ReactComponent as NoConsentImageEn } from '../../../resources/images/wellcome_consent_en.svg';
import { ReactComponent as NoConsentImageEs } from '../../../resources/images/wellcome_consent_es.svg';
import { ReactComponent as AccesRestrictedImage } from '../../../resources/images/error_restricted_access.svg';
import { ReactComponent as WelcomeGuardianImage } from '../../../resources/images/profiling_welcome_guardian.svg';
import { ReactComponent as NoConsentTipOfIcebergImageEng } from '../../../resources/images/no_consent_tip_of_iceberg_en.svg';
import { ReactComponent as NoConsentTipOfIcebergImageEsp } from '../../../resources/images/no_consent_tip_of_iceberg_es.svg';
import PageWrapper from '../page-wrapper/PageWrapper';

const WelcomeImageRoleMap = {
  [RolesMap.TEACHER]: WelcomeEducatorImage,
  [RolesMap.STUDENT]: WelcomeStudentImage,
  [RolesMap.GUARDIAN]: WelcomeGuardianImage,
  [RolesMap.ADMINISTRATOR]: WelcomeEducatorImage,
};

const IcebergIllustrationsMap = {
  [LanguagesMap.EN_US]: NoConsentTipOfIcebergImageEng,
  [LanguagesMap.ES_US]: NoConsentTipOfIcebergImageEsp,
};

const WelcomeMessage = ({ onProceed }) => {
  const { state: userState } = useContext(UserContext);
  const { profile } = userState;
  const { t } = useTranslation();
  const { postAgreement } = useAgreementService();
  const [isConsentChecked, setIsConsentChecked] = useState(false);
  const handleConsentChange = (e) => setIsConsentChecked(e.target.checked);

  const isEducator = useMemo(
    () => profile?.role === RolesMap.TEACHER || profile?.role === RolesMap.ADMINISTRATOR,
    [profile],
  );

  const [isPrivacyDialogOpen, setIsPrivacyDialogOpen] = useState(false);

  const [isTermsConditionsDialogOpen, setIsTermsConditionsDialogOpen] = useState(false);

  const ImageComponent = useMemo(() => WelcomeImageRoleMap[profile?.role], [profile]);

  const ImageAccessAbilityLabels = useMemo(
    () => ({
      [RolesMap.STUDENT]: t('Cracker and confetti'),
      [RolesMap.TEACHER]: t(
        'A magnifying glass, an apple on the books, a globe, paints with brush and a pencil',
      ),
      [RolesMap.GUARDIAN]: t('A parent is teaching his child to walk'),
      [RolesMap.ADMINISTRATOR]: t(
        'A magnifying glass, an apple on the books, a globe, paints with brush and a pencil',
      ),
    }),
    [t],
  );

  const HeaderTextRoleMap = useMemo(
    () => ({
      [RolesMap.STUDENT]: `${t('We’re happy to see you')}${profile?.firstName ? ', ' : ''}${
        profile?.firstName || ''
      }!`,
      [RolesMap.TEACHER]: t('Welcome to AYO!'),
      [RolesMap.GUARDIAN]: t('Welcome to AYO!'),
      [RolesMap.ADMINISTRATOR]: t('Welcome to AYO!'),
    }),
    [profile, t],
  );
  const MainContentRoleMap = useMemo(
    () => ({
      [RolesMap.STUDENT]: t(
        'This platform is meant to learn from you and later start helping you with connecting the dots so that you’d know yourself better',
      ),
      [RolesMap.TEACHER]: t(
        'AYO is here to help you tailor learning for students by getting to know them better',
      ),
      [RolesMap.GUARDIAN]: `${t(
        'AYO is here to help you support your children through their school years by providing an unbiased overview of talents and passions',
      )} ${t('Help AYO learn about students by answering a few short questions')}.`,
      [RolesMap.ADMINISTRATOR]: t(
        'AYO is here to help you tailor learning for students by getting to know them better',
      ),
    }),
    [t],
  );
  const ProceedButtonsText = useMemo(
    () => ({
      [RolesMap.STUDENT]: t("Let's go"),
      [RolesMap.TEACHER]: t('Proceed'),
      [RolesMap.GUARDIAN]: t('Proceed'),
      [RolesMap.ADMINISTRATOR]: t('Proceed'),
    }),
    [t],
  );

  const isAgreementNeeded = useMemo(() => !profile?.policyAccepted, [profile]);

  const agreeAndProceed = () => {
    postAgreement(true, `${t('Please wait a bit')}. ${t('We are preparing AYO for you')}...`).then(
      () => onProceed(),
    );
  };

  return profile ? (
    <ScreenCenteredContainer className={`ayo-page__screen--centered--${profile.role}`}>
      <PageIllustrationBlock
        Illustration={ImageComponent}
        illustrationAriaLabel={ImageAccessAbilityLabels[profile.role]}
        mainHeaderText={HeaderTextRoleMap[profile.role]}
        secondaryHeaderText={[MainContentRoleMap[profile.role]]}
      />
      <Grid container item justifyContent="center" md={6} xs={12}>
        {isAgreementNeeded && !isEducator && (
          <Grid container item justifyContent="center" xs={12}>
            <Box mb={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isConsentChecked}
                    gaLabel={`Consent checked: ${!isConsentChecked}`}
                    onChange={handleConsentChange}
                    value="consent"
                  />
                }
                label={
                  <Typography variant="body2">
                    {t('I agree to')} {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <Link
                      component="span"
                      gaLabel="Terms and Conditions"
                      onClick={(e) => {
                        e.preventDefault();
                        setIsTermsConditionsDialogOpen(true);
                      }}
                      role="button"
                    >
                      {t('Terms & Conditions')}
                    </Link>{' '}
                    {t('and')} {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <Link
                      component="span"
                      gaLabel="Privacy Policy"
                      onClick={(e) => {
                        e.preventDefault();
                        setIsPrivacyDialogOpen(true);
                      }}
                      role="button"
                    >
                      {t('Privacy Policy')}
                    </Link>
                  </Typography>
                }
              />
              <PrivacyPolicyDialog
                isOpen={isPrivacyDialogOpen}
                onClose={() => setIsPrivacyDialogOpen(false)}
              />
              <TermsConditionsDialog
                isOpen={isTermsConditionsDialogOpen}
                onClose={() => setIsTermsConditionsDialogOpen(false)}
              />
            </Box>
          </Grid>
        )}
      </Grid>
      <Grid className="ayo-welcome__proceed" item md={4} xs={10}>
        <Box display="flex" justifyContent="center" mb={1}>
          <Button
            disabled={isAgreementNeeded && !isEducator && !isConsentChecked}
            gaLabel={`Welcome - ${ProceedButtonsText[profile.role]}`}
            isAdaptive
            onClick={isAgreementNeeded ? agreeAndProceed : onProceed}
            variant="primary"
          >
            {ProceedButtonsText[profile.role]}
          </Button>
        </Box>
        {isAgreementNeeded && !isEducator && !isConsentChecked && (
          <Box position="absolute" width="100%">
            <Typography align="center" isLabel variant="body3">
              {t('Please agree to Terms & Conditions, Privacy Policy')}
            </Typography>
          </Box>
        )}
      </Grid>
    </ScreenCenteredContainer>
  ) : null;
};

WelcomeMessage.propTypes = {
  onProceed: PropTypes.func,
};

WelcomeMessage.defaultProps = {
  onProceed: () => {},
};

const ProvideConsentLaterButton = () => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <Button
      fullWidth
      gaLabel="Maybe later"
      onClick={() => {
        history.replace(familyFeedRoute);
        sessionStorage.setItem(LocalStorageKeysMap.GUARDIAN_DELAYED_CONSENT, true);
      }}
    >
      {t('Maybe later')}
    </Button>
  );
};
const PendingStudentAgreement = () => {
  const { t, i18n } = useTranslation();
  const { state: userState } = useContext(UserContext);
  const { profile } = userState;
  const ConsentHeaderTextRoleMap = useMemo(
    () => ({
      [RolesMap.STUDENT]: t('Before you start, we need parental consent'),
      [RolesMap.GUARDIAN]: t('Before you start, we need to get your consent'),
    }),
    [t],
  );
  const ConsentSecondaryTextRoleMap = useMemo(
    () => ({
      [RolesMap.STUDENT]: (
        <Trans
          components={{
            // eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content
            a: (
              <Link
                gaLabel="Please visit MISD Campus Directory"
                href={misdAllSchoolsRoute}
                rel="noreferrer"
                target="_blank"
              />
            ),
          }}
          i18nKey="Please ask your parents to visit the MISD Campus Directory and contact your campus to inform that they give their consent for you to use AYO."
        />
      ),
      [RolesMap.GUARDIAN]: (
        <Trans
          components={{
            // eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content
            a: (
              <Link
                gaLabel="Please visit MISD Campus Directory"
                href={misdAllSchoolsRoute}
                rel="noreferrer"
                target="_blank"
              />
            ),
          }}
          i18nKey="Please visit MISD Campus Directory and contact your child(ren)’s campus to inform that you wish to give your consent for them to use AYO."
        />
      ),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t],
  );

  return (
    <ScreenCenteredContainer className="ayo-welcome">
      <PageIllustrationBlock
        Illustration={i18n.language === LanguagesMap.EN_US ? NoConsentImageEn : NoConsentImageEs}
        illustrationAriaLabel={t(
          'Two smiling teachers are sitting in front of a desk, and a sign Welcome! is hanging on the wall.',
        )}
        mainHeaderText={t(ConsentHeaderTextRoleMap[profile.role])}
        secondaryHeaderText={[ConsentSecondaryTextRoleMap[profile.role]]}
      />
      {profile.role === RolesMap.GUARDIAN && (
        <Grid container item justifyContent="center" md={6} xs={12}>
          <Box>
            <Typography align="center" isLabel variant="body3">
              {t(
                'It can take up to several days before the information about the consent is updated in the system and you can log in to AYO',
              )}
            </Typography>
          </Box>
        </Grid>
      )}
      {profile.role === RolesMap.GUARDIAN && (
        <Grid container justifyContent="center" mt={2}>
          <Grid
            container
            flexDirection="row-reverse"
            item
            justifyContent="center"
            md={6}
            spacing={2}
            xs={12}
          >
            <Grid item sm={6} xs={12}>
              <StudentConsentButton fullWidth />
            </Grid>
            <Grid item sm={6} xs={12}>
              <ProvideConsentLaterButton />
            </Grid>
          </Grid>
        </Grid>
      )}
    </ScreenCenteredContainer>
  );
};

const MainGuardianPendingAgreementScreen = () => {
  const { i18n, t } = useTranslation();

  return (
    <ScreenCenteredContainer>
      <PageIllustrationBlock
        Illustration={IcebergIllustrationsMap[i18n.language]}
        illustrationAriaLabel={t('Iceberg illustration alt')}
        mainHeaderText={t('This is just the tip of the iceberg!')}
        secondaryHeaderText={[
          t(
            'There is so much more to discover in AYO! You can see personalized updates on your kid(s)’s activities, interests, goals, and the list goes on!',
          ),
          <Trans
            key="Please visit MISD Campus Directory"
            components={{
              // eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content
              a: (
                <Link
                  gaLabel="Please visit MISD Campus Directory"
                  href={misdAllSchoolsRoute}
                  rel="noreferrer"
                  target="_blank"
                />
              ),
            }}
            i18nKey="Please visit MISD Campus Directory and contact your child(ren)’s campus to inform that you wish to give your consent for them to use AYO."
          />,
        ]}
      />
      <Grid container item justifyContent="center" md={6} xs={12}>
        <Box>
          <Typography align="center" isLabel variant="body3">
            {t(
              'It can take up to several days before the information about the consent is updated in the system and you can log in to AYO',
            )}
          </Typography>
        </Box>
      </Grid>
      <Grid container justifyContent="center" mt={2}>
        <Grid container item justifyContent="center" md={6} spacing={2} xs={12}>
          <Grid item sm={6} xs={12}>
            <ProvideConsentLaterButton />
          </Grid>
          <Grid item sm={6} xs={12}>
            <StudentConsentButton fullWidth />
          </Grid>
        </Grid>
      </Grid>
    </ScreenCenteredContainer>
  );
};

const Under18RejectedAgreement = () => {
  const { t } = useTranslation();
  return (
    <ScreenCenteredContainer>
      <PageIllustrationBlock
        Illustration={AccesRestrictedImage}
        illustrationAriaLabel={t('Red and white construction barrier with a Road closed sign')}
        mainHeaderText={t('Your parent(s) restricted AYO access for you')}
        secondaryHeaderText={[t('Currently, AYO is not available for you')]}
      />
    </ScreenCenteredContainer>
  );
};

const Welcome = ({ onProceed }) => {
  const { state: userState } = useContext(UserContext);
  const { getIsUnder18 } = useProfile();

  const handleUnder18StudentWithoutAgreement = () => {
    if (userState?.profile?.policyAccepted === null) {
      return <PendingStudentAgreement />;
    }
    return <Under18RejectedAgreement />;
  };

  const { isGuardianWithoutStudentConsent } = userState;

  const isGuardianPolicyAcceptedWithoutStudentConsent =
    isGuardianWithoutStudentConsent && userState.profile?.policyAccepted;

  const { actionState: noKidsConsentScreenSeen, onAction: onNoKidsConsentScreenSeen } =
    useOneTimeAction(OneTimeActionsMap.NO_KIDS_CONSENT_SCREEN);

  const [showFirstPendingScreen, setShowFirstPendingScreen] = useState(null);

  useEffect(() => {
    if (isGuardianPolicyAcceptedWithoutStudentConsent) {
      if (!noKidsConsentScreenSeen && !showFirstPendingScreen) {
        setShowFirstPendingScreen(true);
        onNoKidsConsentScreenSeen();
      }
      if (noKidsConsentScreenSeen && showFirstPendingScreen === null) {
        setShowFirstPendingScreen(false);
      }
    }
  }, [
    isGuardianPolicyAcceptedWithoutStudentConsent,
    noKidsConsentScreenSeen,
    onNoKidsConsentScreenSeen,
    showFirstPendingScreen,
  ]);

  if (getIsUnder18(userState?.profile) && !userState?.profile?.policyAccepted) {
    return handleUnder18StudentWithoutAgreement();
  }
  if (isGuardianPolicyAcceptedWithoutStudentConsent) {
    if (showFirstPendingScreen === null) return <PageWrapper />;
    return showFirstPendingScreen ? (
      <PendingStudentAgreement />
    ) : (
      <MainGuardianPendingAgreementScreen />
    );
  }
  return <WelcomeMessage onProceed={onProceed} />;
};

Welcome.propTypes = {
  onProceed: PropTypes.func,
};

Welcome.defaultProps = {
  onProceed: () => {},
};

export default Welcome;
