import React, { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Container, Fab, Menu } from '@mui/material';
import classnames from 'classnames';

import { Button, Typography, Hotspot } from '../../atoms';
import { TourActions, TourContext, UserContext } from '../../../context';
import { GaActions, GaCategories } from '../../../constants/enums';
import { GA } from '../../../utils';
import { ReactComponent as OpenTourIcon } from '../../../resources/icons/question_mark.svg';
import NewFeaturesTour from '../../../tours/NewFeaturesTour';
import NewFeaturesItemsProvider from '../../../tours/common/NewFeaturesItemsProvider';

const ResetTourMenu = () => {
  const { t } = useTranslation();

  const { state: tourState, dispatch: dispatchTourState } = useContext(TourContext);

  const NewFeatureTourItems = NewFeaturesItemsProvider();

  const hasNewItems = NewFeatureTourItems.length > (tourState.newFeaturesSeen?.length || 0);

  const [anchorEl, setAnchorEl] = useState(null);

  const openResetTourMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const closeResetTourMenu = () => {
    setAnchorEl(null);
  };

  const resetTourState = useCallback(() => {
    GA.logInteraction({
      category: GaCategories.BEHAVIOR,
      action: GaActions.BUTTON_CLICK,
      label: 'Show AYO tour',
    });
    closeResetTourMenu();
    dispatchTourState({ type: TourActions.SET_FORCE_TOUR_OPEN, data: true });
  }, [dispatchTourState]);

  const openNewFeaturesTour = useCallback(() => {
    dispatchTourState({
      type: TourActions.SET_NEW_FEATURES_TOUR_STATE,
      data: { isNewFeaturesTourOpened: true, newFeaturesFocusId: null },
    });
    closeResetTourMenu();
  }, [dispatchTourState]);

  const { state: userState } = useContext(UserContext);

  const { isGuardianWithoutStudentConsent } = userState;

  return (
    <Box className="ayo-tours-menu__container">
      <Container className="ayo-tours-fab__wrapper">
        <Box
          className={classnames('ayo-tours-fab__container', { 'with-new-features': hasNewItems })}
        >
          <Fab
            aria-label={t('AYO features walkthrough')}
            className="ayo-tours-fab"
            disableFocusRipple
            disableRipple
            id="start-tour-button"
            onClick={!anchorEl ? openResetTourMenu : closeResetTourMenu}
            size="small"
            style={{ zIndex: anchorEl ? 1301 : 2 }}
            title={t('AYO features walkthrough')}
          >
            <OpenTourIcon />
          </Fab>
          <div className="ayo-tours-fab--outer" />
        </Box>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          className="ayo-tours-menu"
          keepMounted
          onClose={closeResetTourMenu}
          open={!!anchorEl}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          variant="menu"
        >
          <Box pb={3}>
            <Typography variant="subtitle1">{t('Need any assistance?')}</Typography>
          </Box>
          {!isGuardianWithoutStudentConsent && (
            <Button
              className="ayo-tours-menu__button"
              fullWidth
              gaLabel="Take AYO tour"
              onClick={resetTourState}
            >
              {t('Take AYO tour')}
            </Button>
          )}
          {!!NewFeatureTourItems.length && (
            <Box alignItems="center" display="flex" flexDirection="row" tabIndex="-1" width="100%">
              <Button
                aria-label={t(`What's new${hasNewItems ? ' (You have new updates)' : ''}`)}
                className="ayo-tours-menu__button"
                fullWidth
                gaLabel="What’s new"
                onClick={openNewFeaturesTour}
              >
                {t("What's new")}
              </Button>
              {hasNewItems && <Hotspot withAnimation />}
            </Box>
          )}
        </Menu>
        <NewFeaturesTour />
      </Container>
    </Box>
  );
};

export default ResetTourMenu;
