import React, { useState, useMemo, useCallback } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  BarChart,
  Legend,
  Bar,
  XAxis,
  Rectangle,
  ResponsiveContainer,
  LabelList,
  Label,
} from 'recharts';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Tooltip, Typography } from '../../../atoms';
import { useSearchParams } from '../../../../hooks';
import {
  GaActions,
  GaCategories,
  HighlightsInitiatives,
  LeadershipAttributes,
  StudentFiltersTypes,
  KeyboardMap,
} from '../../../../constants/enums';
import { filtersListSeparator } from '../../../../constants/values';
import { GA, getFilterValueParts, transformSearchToObject } from '../../../../utils';
import { FilterUpdateActions } from '../../../../hooks/use-filter-update-state/useFilterUpdateState';

const X_AXIS_LABEL_PADDING = 16;

const CustomizedAxisTick = ({ x, y, payload }) => {
  const { t } = useTranslation();
  return (
    <g aria-hidden="true" transform={`translate(${x},${y})`}>
      <text
        className="axis-text"
        dy={X_AXIS_LABEL_PADDING}
        fill="#666"
        textAnchor="end"
        transform="rotate(-45)"
        x={0}
        y={0}
      >
        {t(payload.value)}
      </text>
    </g>
  );
};

CustomizedAxisTick.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  payload: PropTypes.instanceOf(Object).isRequired,
};

const BAR_RADIUS = 6;
const DECORATIVE_BAR_PADDING = 4;
const DECORATIVE_BAR_STROKE_WIDTH = 2;
const CustomShape = ({ onClick, ...props }) => {
  const { x, y, width, height, radius, fill, type, name, isActive, valueAccessor, payload } = props;

  const { t } = useTranslation();
  const [isHovered, setIsHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const value = valueAccessor(payload);

  const isDisabled = payload.have === 0 || payload.notHave === 0;
  const isFullRound = isDisabled;

  const finalRadius = isFullRound ? [BAR_RADIUS, BAR_RADIUS, BAR_RADIUS, BAR_RADIUS] : radius;
  const mainLabel = `${t(`analytics.highlights.${type}`)}: ${t(name)} (${value})`;

  const isBarActive = useMemo(() => isActive(type, name), [isActive, type, name]);
  return (
    <g>
      <Tooltip
        enterTouchDelay={0}
        leaveTouchDelay={5000}
        title={
          <>
            {mainLabel}
            <br />
            {isDisabled && t("Can't filter by all students")}
          </>
        }
      >
        <g
          aria-label={isBarActive ? t('Selected') : null}
          className="main-rect-wrapper"
          onBlur={() => setIsFocused(false)}
          onFocus={() => setIsFocused(true)}
          onKeyDown={(e) => {
            if (e.key === KeyboardMap.ENTER || e.key === KeyboardMap.SPACE) {
              onClick(payload);
            }
          }}
          role="button"
          tabIndex={isDisabled ? null : 0}
        >
          <Rectangle
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            className="main-rect"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            radius={finalRadius}
          />
        </g>
      </Tooltip>
      {height && (
        <Rectangle
          className={classnames('decorative-rect', {
            active: isBarActive,
            hover: isHovered,
            focus: isFocused,
          })}
          fill="none"
          height={height + DECORATIVE_BAR_PADDING * 2}
          id={`rect-${type}-${name}`}
          radius={finalRadius}
          stroke={fill}
          strokeWidth={DECORATIVE_BAR_STROKE_WIDTH}
          width={width + DECORATIVE_BAR_PADDING * 2}
          x={x - DECORATIVE_BAR_PADDING}
          y={y - DECORATIVE_BAR_PADDING}
        />
      )}
    </g>
  );
};

CustomShape.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  radius: PropTypes.arrayOf(PropTypes.number).isRequired,
  fill: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isActive: PropTypes.func.isRequired,
  valueAccessor: PropTypes.func.isRequired,
  payload: PropTypes.instanceOf(Object).isRequired,
  onClick: PropTypes.func.isRequired,
};

const MAX_BAR_WIDTH = 40;
const CHART_MARGIN = { left: 24, right: 24, top: 16 };
const CHART_HEIGHT = 350;
const CHART_X_AXIS_HEIGHT = 150;
const BAR_LABEL_OFFSET = 12;
const LEGEND_ICON_SIZE = 16;

const AccessibilityHiddenLabel = ({ content, filterCriterion, ...props }) => {
  const { name } = props;
  return (
    <g aria-hidden="true" id={`bar-label-${filterCriterion}-${name}`}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Label {...props} />
    </g>
  );
};

AccessibilityHiddenLabel.propTypes = {
  content: PropTypes.element.isRequired,
  filterCriterion: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

const LAHighlights = ({ stats, onFiltersUpdate }) => {
  const chartData =
    stats &&
    Object.entries(stats.leadershipAttributes).reduce(
      (acc, [key, value]) => [...acc, { name: key, ...value }],
      [],
    );

  const search = useSearchParams();

  const history = useHistory();

  const filters = useMemo(() => transformSearchToObject(search), [search]);

  const { t } = useTranslation();

  const onBarClick = useCallback(
    (barData, type) => {
      if (
        stats.leadershipAttributes[barData.name].have === 0 ||
        stats.leadershipAttributes[barData.name].notHave === 0
      )
        return;
      let newFilters = { ...filters };
      newFilters.filterCategory = HighlightsInitiatives.LEADERSHIP_ATTRIBUTES;
      let previousFilterAtrributes;
      let previousFilterType;
      if (filters.filterCategory === HighlightsInitiatives.LEADERSHIP_ATTRIBUTES) {
        [previousFilterType, previousFilterAtrributes] = filters.filterValue.split(':');

        if (previousFilterType !== type) {
          newFilters.filterValue = `${type}:${barData.name}`;
        } else {
          let newAttributesList = previousFilterAtrributes.split(filtersListSeparator);
          if (newAttributesList.includes(barData.name)) {
            newAttributesList = newAttributesList.filter((attribute) => attribute !== barData.name);
          } else {
            newAttributesList.push(barData.name);
          }
          if (newAttributesList.length > 0) {
            newFilters.filterValue = `${type}:${newAttributesList.join(filtersListSeparator)}`;
          } else {
            newFilters = {};
          }
        }
      } else {
        newFilters.filterValue = `${type}:${barData.name}`;
      }

      const newSearchString = new URLSearchParams(newFilters).toString();
      history.replace({ search: newSearchString });
      onFiltersUpdate({
        type: FilterUpdateActions.UPDATE,
        data: {
          filters,
          value: barData.name,
          criterion: type,
          category: HighlightsInitiatives.LEADERSHIP_ATTRIBUTES,
          stats,
          t,
        },
      });
      GA.logInteraction({
        category: GaCategories.BEHAVIOR,
        action: GaActions.GRAPH_PART_CLICKED,
        label: `LA Highlights Graph - ${type}:${barData.name}`,
      });
    },
    [filters, history, onFiltersUpdate, stats, t],
  );

  const activeBarSelector = (type, attribute) => {
    const { filtersList, filterCriterion } = getFilterValueParts(filters, stats);
    const isCorrectCategory =
      filters.filterCategory === HighlightsInitiatives.LEADERSHIP_ATTRIBUTES;
    const isSameType = filterCriterion === type;
    const isAttributeEnabled = filtersList.includes(attribute);
    return isCorrectCategory && isSameType && isAttributeEnabled;
  };

  return (
    <ResponsiveContainer className="ayo-chart-legend" height={CHART_HEIGHT} width="100%">
      <BarChart data={chartData} margin={CHART_MARGIN} reverseStackOrder>
        <Legend
          formatter={(value) => (
            <Box display="inline-flex">
              <Typography variant="caption">{value}</Typography>
            </Box>
          )}
          iconSize={LEGEND_ICON_SIZE}
          iconType="square"
        />
        <XAxis
          axisLine={false}
          dataKey="name"
          height={CHART_X_AXIS_HEIGHT}
          interval={0}
          tick={<CustomizedAxisTick />}
          tickLine={false}
          type="category"
        />
        <Bar
          dataKey="notHave"
          fill="#f1fecd"
          isAnimationActive={false}
          maxBarSize={MAX_BAR_WIDTH}
          name={t('Pending')}
          onClick={(barData) => onBarClick(barData, StudentFiltersTypes.PENDING)}
          shape={
            <CustomShape
              isActive={activeBarSelector}
              onClick={(barData) => onBarClick(barData, StudentFiltersTypes.PENDING)}
              radius={[BAR_RADIUS, BAR_RADIUS, 0, 0]}
              type={StudentFiltersTypes.PENDING}
              valueAccessor={(payload) => payload.notHave}
            />
          }
          stackId="la"
        >
          <LabelList
            className="bar-label"
            content={<AccessibilityHiddenLabel filterCriterion={StudentFiltersTypes.PENDING} />}
            offset={BAR_LABEL_OFFSET}
            position="insideTop"
            valueAccessor={(payload) => payload.notHave || ''}
          />
        </Bar>
        <Bar
          dataKey="have"
          fill="#b0d475"
          isAnimationActive={false}
          maxBarSize={MAX_BAR_WIDTH}
          name={t('Developing')}
          onClick={(barData) => onBarClick(barData, StudentFiltersTypes.INCLUDE)}
          shape={
            <CustomShape
              isActive={activeBarSelector}
              onClick={(barData) => onBarClick(barData, StudentFiltersTypes.INCLUDE)}
              radius={[0, 0, BAR_RADIUS, BAR_RADIUS]}
              type={StudentFiltersTypes.INCLUDE}
              valueAccessor={(payload) => payload.have || ''}
            />
          }
          stackId="la"
        >
          <LabelList
            className="bar-label"
            content={<AccessibilityHiddenLabel filterCriterion={StudentFiltersTypes.INCLUDE} />}
            offset={BAR_LABEL_OFFSET}
            position="insideBottom"
            valueAccessor={(payload) => payload.have || ''}
          />
        </Bar>
        <g>
          {Object.values(LeadershipAttributes).map((attribute) => (
            <React.Fragment key={attribute}>
              <use href={`#rect-${StudentFiltersTypes.PENDING}-${attribute}`} />
              <use href={`#rect-${StudentFiltersTypes.INCLUDE}-${attribute}`} />
              <Tooltip
                enterTouchDelay={0}
                leaveTouchDelay={5000}
                title={
                  <>
                    {`${t(`analytics.highlights.${StudentFiltersTypes.PENDING}`)}: ${t(
                      attribute,
                    )} (${stats.leadershipAttributes[attribute].notHave})`}
                    <br />
                    {(stats.leadershipAttributes[attribute].have === 0 ||
                      stats.leadershipAttributes[attribute].notHave === 0) &&
                      t("Can't filter by all students")}
                  </>
                }
              >
                <use
                  href={`#bar-label-${StudentFiltersTypes.PENDING}-${attribute}`}
                  onClick={() => onBarClick({ name: attribute }, StudentFiltersTypes.PENDING)}
                />
              </Tooltip>
              <Tooltip
                enterTouchDelay={0}
                leaveTouchDelay={5000}
                title={
                  <>
                    {`${t(`analytics.highlights.${StudentFiltersTypes.INCLUDE}`)}: ${t(
                      attribute,
                    )} (${stats.leadershipAttributes[attribute].have})`}
                    <br />
                    {(stats.leadershipAttributes[attribute].have === 0 ||
                      stats.leadershipAttributes[attribute].notHave === 0) &&
                      t("Can't filter by all students")}
                  </>
                }
              >
                <use
                  href={`#bar-label-${StudentFiltersTypes.INCLUDE}-${attribute}`}
                  onClick={() => onBarClick({ name: attribute }, StudentFiltersTypes.INCLUDE)}
                />
              </Tooltip>
            </React.Fragment>
          ))}
        </g>
      </BarChart>
    </ResponsiveContainer>
  );
};

LAHighlights.propTypes = {
  stats: PropTypes.instanceOf(Object).isRequired,
  onFiltersUpdate: PropTypes.func.isRequired,
};

export default LAHighlights;
