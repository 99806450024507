import { useCallback } from 'react';

import useAxios, { StatusCodeMap } from '../HttpClient';
import {
  answersPost,
  apiRoute,
  guardiansGetAnswersRoute,
  questionnairesRoute,
} from '../../constants/routes';
import { getNormalizedLanguageCode } from '../../utils/locale-helpers/language-helpers/languageHelpers';

const useQuestionnaireService = () => {
  const { get, post } = useAxios();

  const getQuestions = useCallback(
    async (questionnaireId, language, personId) => {
      const { data } = await get(
        { enabled: true },
        `${apiRoute}${questionnairesRoute}/${questionnaireId}?lang=${getNormalizedLanguageCode(
          language,
        )}${personId ? `&personId=${personId}` : ''}`,
      );
      return { ...data, questions: data.questions.filter((question) => question.visible) };
    },
    [get],
  );

  const getQuestionsAnswers = useCallback(
    async (language, personId) => {
      const { data } = await get(
        { enabled: true },
        `${apiRoute}${questionnairesRoute}${guardiansGetAnswersRoute}?lang=${getNormalizedLanguageCode(
          language,
        )}${personId ? `&personId=${personId}` : ''}`,
      );
      return data;
    },
    [get],
  );

  const postQuestion = useCallback(
    (questionnaireId, answerRequestBody) =>
      post(
        { enabled: true },
        `${apiRoute}${questionnairesRoute}/${questionnaireId}${answersPost}`,
        answerRequestBody,
        { skipDefaultErrorHandlers: [StatusCodeMap.NOT_FOUND, StatusCodeMap.SERVER_ERROR] },
      ),
    [post],
  );

  return { getQuestions, postQuestion, getQuestionsAnswers };
};

export default useQuestionnaireService;
