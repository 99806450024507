import React, { useCallback, useContext, useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Link, Typography } from '../../../../atoms';
import { UserContext } from '../../../../../context';
import {
  AchievementsCategories,
  AnalyticsStatsKeys,
  Aptitudes,
  GoalsHighlightsKeys,
  HighlightsInitiatives,
  InitiativesFilterQueryParams,
  LeadershipAttributes,
  RolesMap,
  StudentFiltersTypes,
} from '../../../../../constants/enums';
import {
  myStudentsRoute,
  studentsRoute,
  trainAyoGoalsStudentSelectionRoute,
  trainAyoLeadershipAttributesStudentSelectionRoute,
} from '../../../../../constants/routes';
import { filtersListSeparator } from '../../../../../constants/values';

const InitiativesFilterValue = {
  [HighlightsInitiatives.LEADERSHIP_ATTRIBUTES]: {
    have: `${StudentFiltersTypes.INCLUDE}:${Object.values(LeadershipAttributes).join(
      filtersListSeparator,
    )}`,
    notHave: `${StudentFiltersTypes.PENDING}:${Object.values(LeadershipAttributes).join(
      filtersListSeparator,
    )}`,
  },
  [HighlightsInitiatives.INTERESTS]: {
    have: `${StudentFiltersTypes.EXCLUDE}:`,
    notHave: `${StudentFiltersTypes.PENDING}`,
  },
  [HighlightsInitiatives.GOALS]: {
    have: `${StudentFiltersTypes.INCLUDE}:${Object.values(GoalsHighlightsKeys).join(
      filtersListSeparator,
    )}`,
    notHave: `${StudentFiltersTypes.PENDING}`,
  },
  [HighlightsInitiatives.EXTRACURRICULARS]: {
    have: `${StudentFiltersTypes.EXCLUDE}:`,
    notHave: `${StudentFiltersTypes.PENDING}`,
  },
  [HighlightsInitiatives.PARENTS_ANSWERS]: {
    have: `${StudentFiltersTypes.INCLUDE}`,
    notHave: `${StudentFiltersTypes.PENDING}`,
  },
  [HighlightsInitiatives.PORTFOLIO]: {
    have: `${StudentFiltersTypes.INCLUDE}`,
    notHave: `${StudentFiltersTypes.PENDING}`,
  },
  [HighlightsInitiatives.OPPORTUNITIES]: {
    have: `${StudentFiltersTypes.INCLUDE}`,
    notHave: `${StudentFiltersTypes.PENDING}`,
  },
  [HighlightsInitiatives.ACHIEVEMENTS]: {
    have: `${StudentFiltersTypes.INCLUDE}:${Object.values(AchievementsCategories).join(
      filtersListSeparator,
    )}`,
    notHave: `${StudentFiltersTypes.PENDING}:`,
  },
  [HighlightsInitiatives.APTITUDES]: {
    have: `${StudentFiltersTypes.INCLUDE}:${Object.values(Aptitudes).join(filtersListSeparator)}`,
    notHave: `${StudentFiltersTypes.PENDING}:`,
  },
  [HighlightsInitiatives.MOODS]: {
    have: '',
    notHave: `${StudentFiltersTypes.PENDING}:`,
  },
  [HighlightsInitiatives.ENDORSEMENTS]: {
    selected: `${StudentFiltersTypes.INCLUDE}:ALL`,
    pending: `${StudentFiltersTypes.PENDING}:`,
  },
  [HighlightsInitiatives.ENDORSEMENTS_TYPES]: {
    switchedByType: `${StudentFiltersTypes.INCLUDE}:ALL`,
  },
  [HighlightsInitiatives.ENDORSEMENTS_REASONS]: {
    switchedByReason: `${StudentFiltersTypes.INCLUDE}:ALL`,
  },
  [HighlightsInitiatives.ENDORSEMENTS_INTERESTED]: {
    interested: `${StudentFiltersTypes.INCLUDE}:ALL`,
  },
};

const ActiveClickableStatsKeys = {
  students: [AnalyticsStatsKeys.LOGGED_IN],
  initiatives: [
    AnalyticsStatsKeys.LEADERSHIP_ATTRIBUTES,
    AnalyticsStatsKeys.GOALS,
    AnalyticsStatsKeys.INTERESTS,
    AnalyticsStatsKeys.EXTRACURRICULARS,
    AnalyticsStatsKeys.PARENTS_ANSWERS,
    AnalyticsStatsKeys.PORTFOLIO,
    AnalyticsStatsKeys.OPPORTUNITIES,
    AnalyticsStatsKeys.ACHIEVEMENTS,
    AnalyticsStatsKeys.APTITUDES,
    AnalyticsStatsKeys.MOODS,
    AnalyticsStatsKeys.ENDORSEMENTS,
    AnalyticsStatsKeys.ENDORSEMENTS_INTERESTED,
    AnalyticsStatsKeys.ENDORSEMENTS_REASONS,
    AnalyticsStatsKeys.ENDORSEMENTS_TYPES,
  ],
};

const AnalyticsStatValue = ({
  statsValue,
  statsCategory,
  filterCategory,
  filterValue,
  withFilters,
}) => {
  const { state: userState } = useContext(UserContext);
  const isAdministrator = userState.profile.role === RolesMap.ADMINISTRATOR;
  const rootStudentsRoute = isAdministrator ? studentsRoute : myStudentsRoute;
  const isClickable = ActiveClickableStatsKeys[statsCategory]?.some((x) => x === filterCategory);

  const InitiativesRedirectRoutes = useMemo(
    () => ({
      [AnalyticsStatsKeys.ACHIEVEMENTS]: rootStudentsRoute,
      [AnalyticsStatsKeys.EXTRACURRICULARS]: rootStudentsRoute,
      [AnalyticsStatsKeys.GOALS]: trainAyoGoalsStudentSelectionRoute,
      [AnalyticsStatsKeys.INTERESTS]: rootStudentsRoute,
      [AnalyticsStatsKeys.LEADERSHIP_ATTRIBUTES]: trainAyoLeadershipAttributesStudentSelectionRoute,
      [AnalyticsStatsKeys.LOGGED_IN]: rootStudentsRoute,
      [AnalyticsStatsKeys.PARENTS_ANSWERS]: rootStudentsRoute,
      [AnalyticsStatsKeys.PORTFOLIO]: rootStudentsRoute,
      [AnalyticsStatsKeys.APTITUDES]: rootStudentsRoute,
      [AnalyticsStatsKeys.OPPORTUNITIES]: rootStudentsRoute,
      [AnalyticsStatsKeys.MOODS]: rootStudentsRoute,
      [AnalyticsStatsKeys.ENDORSEMENTS]: rootStudentsRoute,
      [AnalyticsStatsKeys.ENDORSEMENTS_INTERESTED]: rootStudentsRoute,
      [AnalyticsStatsKeys.ENDORSEMENTS_TYPES]: rootStudentsRoute,
      [AnalyticsStatsKeys.ENDORSEMENTS_REASONS]: rootStudentsRoute,
    }),
    [rootStudentsRoute],
  );

  const redirectUrl = useCallback(() => {
    if (filterValue) {
      const queryFilterCategory = Object.keys(AnalyticsStatsKeys).find(
        (key) => AnalyticsStatsKeys[key] === filterCategory,
      );
      return `${InitiativesRedirectRoutes[filterCategory]}${
        withFilters
          ? `?${InitiativesFilterQueryParams.FILTER_CATEGORY}=${queryFilterCategory}&${InitiativesFilterQueryParams.FILTER_VALUE}=${InitiativesFilterValue[queryFilterCategory][filterValue]}&sanitize=true`
          : `?${InitiativesFilterQueryParams.FILTER_CATEGORY}=${queryFilterCategory}&sanitize=true`
      }`;
    }
    return InitiativesRedirectRoutes[filterCategory];
  }, [InitiativesRedirectRoutes, filterCategory, filterValue, withFilters]);

  return (
    <Typography className="ayo-analytics-stat-value" component="p" variant="h1">
      {isClickable ? (
        <Link
          component={RouterLink}
          gaLabel={`${filterCategory} ${filterValue || ''}`}
          isContained
          to={redirectUrl}
        >
          {statsValue}
        </Link>
      ) : (
        statsValue
      )}
    </Typography>
  );
};

AnalyticsStatValue.propTypes = {
  statsValue: PropTypes.number.isRequired,
  statsCategory: PropTypes.string.isRequired,
  filterCategory: PropTypes.string,
  filterValue: PropTypes.string,
  withFilters: PropTypes.bool,
};

AnalyticsStatValue.defaultProps = {
  filterCategory: '',
  filterValue: '',
  withFilters: false,
};

export default AnalyticsStatValue;
