import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Box, List, ListItem, ListItemIcon, ListItemText, Popover } from '@mui/material';

import { Typography } from '../../../atoms';
import { ReactComponent as AddIcon } from '../../../../resources/icons/add_black.svg';
import { ReactComponent as DeleteIcon } from '../../../../resources/icons/delete.svg';
import { KeyboardMap } from '../../../../constants/enums';

const MAX_ROWS_COLUMNS = 10;

const TableActionsGroupsMap = {
  INSERT_COLUMN: '0',
  INSERT_ROW: '1',
  DELETE: '2',
};

const tableActions = [
  {
    type: TableActionsGroupsMap.INSERT_COLUMN,
    text: 'Insert column to the right',
    icon: <AddIcon />,
    handler: 'insertColumnRight',
  },
  {
    type: TableActionsGroupsMap.INSERT_COLUMN,
    text: 'Insert column to the left',
    icon: <AddIcon />,
    handler: 'insertColumnLeft',
  },
  {
    type: TableActionsGroupsMap.INSERT_ROW,
    text: 'Insert row above',
    icon: <AddIcon />,
    handler: 'insertRowAbove',
  },
  {
    type: TableActionsGroupsMap.INSERT_ROW,
    text: 'Insert row below',
    icon: <AddIcon />,
    handler: 'insertRowBelow',
  },
  {
    type: TableActionsGroupsMap.DELETE,
    text: 'Delete column',
    icon: <DeleteIcon />,
    handler: 'deleteColumn',
  },
  {
    type: TableActionsGroupsMap.DELETE,
    text: 'Delete row',
    icon: <DeleteIcon />,
    handler: 'deleteRow',
  },
  {
    type: TableActionsGroupsMap.DELETE,
    text: 'Delete table',
    icon: <DeleteIcon />,
    handler: 'deleteTable',
  },
];

const initDimensions = { x: 0, y: 0 };

const AddEditTablePopover = ({ anchorEl, editorRef, isEditing, onClose }) => {
  const { t } = useTranslation();
  const [dimensions, setDimensions] = useState(initDimensions);

  const handleCreateTable = useCallback(() => {
    const editor = editorRef.current.getEditor();
    editor.focus();
    editor.getModule('table').insertTable(dimensions.x + 1, dimensions.y + 1);
    onClose();
  }, [dimensions, editorRef, onClose]);

  const handleEditTable = useCallback(
    (action) => {
      const tableModule = editorRef.current.getEditor().getModule('table');
      tableModule[action.handler]();
      onClose();
    },
    [editorRef, onClose],
  );

  useEffect(() => {
    if (anchorEl) {
      setDimensions(initDimensions);
    }
  }, [anchorEl]);

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      className="table-create-popup"
      onClose={onClose}
      open={Boolean(anchorEl)}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      {isEditing ? (
        <List>
          {tableActions.map((item) => {
            const tableModule = editorRef.current.getEditor()?.getModule('table');
            if (!tableModule) {
              return null;
            }
            const [, row, cell] = tableModule.getTable();
            if (
              (item.type === TableActionsGroupsMap.INSERT_ROW &&
                row?.parent.children.length < MAX_ROWS_COLUMNS) ||
              (item.type === TableActionsGroupsMap.INSERT_COLUMN &&
                cell?.parent.children.length < MAX_ROWS_COLUMNS) ||
              item.type === TableActionsGroupsMap.DELETE
            ) {
              return (
                <ListItem
                  key={item.text}
                  button
                  disableRipple
                  onClick={() => handleEditTable(item)}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText>
                    <Typography variant="body2">
                      {t(`richTextEditorLabels.${item.text}`)}
                    </Typography>
                  </ListItemText>
                </ListItem>
              );
            }
            return null;
          })}
        </List>
      ) : (
        <List>
          <Typography variant="subtitle2">{t('richTextEditorLabels.Insert a table')}</Typography>
          <Box py={1}>
            {Array(6)
              .fill()
              .map((_, i) => (
                <Box key={`row${i}`} display="flex" flexDirection="row">
                  {Array(6)
                    .fill()
                    .map((__, j) => (
                      <Box
                        key={`cell${i}${j}`}
                        aria-label={t('richTextEditorLabels.X columns and Y rows', {
                          X: i + 1,
                          Y: j + 1,
                        })}
                        className={classNames('table-create-popup__cell', {
                          'table-create-popup__cell--hovered':
                            i <= dimensions.x && j <= dimensions.y,
                        })}
                        onClick={handleCreateTable}
                        onFocus={() => setDimensions({ x: i, y: j })}
                        onKeyDown={(e) => {
                          if (e.key === KeyboardMap.ENTER) {
                            handleCreateTable();
                          }
                        }}
                        onMouseOver={() => setDimensions({ x: i, y: j })}
                        tabIndex="0"
                      />
                    ))}
                </Box>
              ))}
          </Box>
          <Typography variant="body2">
            {dimensions.x + 1} x {dimensions.y + 1}
          </Typography>
        </List>
      )}
    </Popover>
  );
};

AddEditTablePopover.propTypes = {
  anchorEl: PropTypes.instanceOf(Object),
  editorRef: PropTypes.instanceOf(Object).isRequired,
  isEditing: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

AddEditTablePopover.defaultProps = {
  anchorEl: null,
};

export default AddEditTablePopover;
