import React, { useCallback, useContext, useRef, useState } from 'react';
import { flushSync } from 'react-dom';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Virtuoso } from 'react-virtuoso';

import { Avatar, Button, Typography } from '../../../../atoms';
import {
  DisplayAvatarEditorImage,
  InitiativeEmptyStateBlock,
  STTTextField,
  TextWithTooltip,
} from '../../../../moleculas';
import { InputsValidationErrors, InputsValidationRules } from '../../../../../constants/enums';
import { UserContext } from '../../../../../context';
import { ReactComponent as ChevronLeftIcon } from '../../../../../resources/icons/chevron_left.svg';
import { ReactComponent as SendMessageIcon } from '../../../../../resources/icons/send_message.svg';
import { ReactComponent as FamilyFeedChatEmptyStateImage } from '../../../../../resources/images/family_feed_chat_empty_state.svg';
import ChatMessage from '../chat-message/ChatMessage';

const ChatContent = ({
  members,
  messages,
  onGoBack,
  onPostAdd,
  onMessageRead,
  chatInfo,
  totalPostsNumber,
  loadMore,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpMd = useMediaQuery(theme.breakpoints.up('md'), { noSsr: true });

  const { state: userState } = useContext(UserContext);

  const [newMessage, setNewMessage] = useState('');

  const getMessageAuthor = useCallback(
    (messageAuthorId) =>
      members?.find((member) => member.id === messageAuthorId) || userState.profile,
    [members, userState.profile],
  );

  const chatWith = members?.filter((member) => member.id !== userState.profile.id)[0];

  const messagesRef = useRef();
  const [inputError, setInputError] = useState('');

  const validate = () => {
    let hasError = false;
    if (newMessage.trim().length < InputsValidationRules.MIN_MESSAGE_LENGTH) {
      setInputError(
        InputsValidationErrors(t, InputsValidationRules.MIN_MESSAGE_LENGTH).MIN_ERROR_TEXT,
      );
      hasError = true;
    }
    if (newMessage.length > InputsValidationRules.MAX_TEXT_AREA_LENGTH) {
      setInputError(
        InputsValidationErrors(t, InputsValidationRules.MAX_TEXT_AREA_LENGTH).MAX_ERROR_TEXT,
      );
      hasError = true;
    }
    return !hasError;
  };

  const firstUnreadImageIndex = messages?.toReversed().findIndex((item) => !item.isRead) ?? 0;

  return (
    <Box className="family-feed-chat" display="flex" flex={1} flexDirection="column">
      <Box className="family-feed-chat__header">
        {!!onGoBack && (
          <Button
            aria-label={t('Go back')}
            className="family-feed-chat__header__go-back-icon"
            gaLabel="Go back"
            isIconButton
            onClick={onGoBack}
          >
            <ChevronLeftIcon />
          </Button>
        )}
        {chatInfo.conversationImage ? (
          <Avatar sizes={48} src={chatInfo.conversationImage} />
        ) : (
          <DisplayAvatarEditorImage
            alt={t('author avatar image alt', { authorName: chatWith?.name })}
            avatar={chatWith?.avatar?.image}
            avatarSize={48}
            avatarTransforms={chatWith?.avatar?.transforms}
          />
        )}
        <Box ml={2}>
          <TextWithTooltip
            title={chatInfo.conversationName ?? chatWith?.name}
            titleVariant="subtitle2"
          />
          <Typography isLabel variant="caption">
            {t(
              chatInfo.conversationDescription
                ? `conversations.descriptions.${chatInfo.conversationDescription}`
                : chatWith?.position,
            )}
          </Typography>
        </Box>
      </Box>
      <Box className="family-feed-chat__body" p={isWidthUpMd ? 3 : 2}>
        {messages?.length ? (
          <Virtuoso
            data={messages.toReversed()}
            firstItemIndex={totalPostsNumber - messages.length}
            followOutput="auto"
            initialTopMostItemIndex={
              firstUnreadImageIndex !== -1 ? firstUnreadImageIndex : messages.length - 1
            }
            itemContent={(index, message) => {
              const author = getMessageAuthor(message.userId);
              return (
                <ChatMessage
                  key={message.id ?? message.createdDate}
                  author={author}
                  isOwnMessage={author.id === userState.profile.id}
                  markAsRead={() => onMessageRead(message)}
                  message={message}
                />
              );
            }}
            overscan={300}
            scrollerRef={(ref) => {
              messagesRef.current = ref;
            }}
            startReached={() => {
              loadMore();
            }}
          />
        ) : (
          <InitiativeEmptyStateBlock
            body={t('Type your message below.')}
            illustration={<FamilyFeedChatEmptyStateImage alt={t('Two speech bubbles.')} />}
            title={t('You are starting a new conversation')}
          />
        )}
      </Box>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (!validate()) return;
          flushSync(() => {
            onPostAdd(newMessage);
            setNewMessage('');
          });
          messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
        }}
      >
        <Box
          className="family-feed-chat__footer"
          columnGap={2}
          display="flex"
          justifyContent="space-between"
          p={isWidthUpMd ? 3 : 2}
        >
          <Box flex={1}>
            <STTTextField
              className="family-feed-chat__footer__input"
              error={!!inputError}
              fullWidth
              gaLabel="Chat add a message"
              helperText={inputError}
              InputLabelProps={{ id: 'new-message' }}
              inputProps={{ 'aria-labelledby': 'new-message' }}
              label={t('Add a message...')}
              maxLength={InputsValidationRules.MAX_TEXT_AREA_LENGTH}
              name={t('Add a message...')}
              onChange={(e) => {
                setNewMessage(e.target.value);
                setInputError('');
              }}
              outlined
              value={newMessage}
            />
          </Box>
          <Button isIconButton type="submit">
            <SendMessageIcon />
          </Button>
        </Box>
      </form>
    </Box>
  );
};

ChatContent.propTypes = {
  chatInfo: PropTypes.shape({
    conversationDescription: PropTypes.string,
    conversationName: PropTypes.string,
    conversationImage: PropTypes.string,
  }).isRequired,
  members: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      profileImage: PropTypes.string,
      position: PropTypes.string,
      email: PropTypes.string,
      avatar: PropTypes.shape({
        image: PropTypes.shape({
          id: PropTypes.number,
          ownerId: PropTypes.number,
          fileName: PropTypes.string,
          updatedDate: PropTypes.string,
        }),
        transforms: PropTypes.shape({
          x: PropTypes.number,
          Y: PropTypes.number,
          zoom: PropTypes.number,
        }),
      }),
      type: PropTypes.string,
    }),
  ).isRequired,
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      userId: PropTypes.number,
      text: PropTypes.string,
      isRead: PropTypes.bool,
      createdDate: PropTypes.string,
    }),
  ).isRequired,
  onGoBack: PropTypes.func,
  onPostAdd: PropTypes.func.isRequired,
  onMessageRead: PropTypes.func.isRequired,
  totalPostsNumber: PropTypes.number,
  loadMore: PropTypes.func,
};

ChatContent.defaultProps = {
  onGoBack: null,
  totalPostsNumber: 0,
  loadMore: () => {},
};

export default ChatContent;
