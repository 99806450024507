import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Button } from '../../atoms';

const SelectableIconButton = ({ ariaLabel, children, className, gaLabel, isSelected, onClick }) => (
  <Button
    aria-label={ariaLabel}
    className={classNames('ayo-selectable-icon-button', className, {
      'ayo-selectable-icon-button--selected': isSelected,
    })}
    gaLabel={gaLabel}
    isIconButton
    onClick={onClick}
  >
    {children}
  </Button>
);

SelectableIconButton.propTypes = {
  ariaLabel: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  gaLabel: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

SelectableIconButton.defaultProps = {
  className: '',
  isSelected: false,
};

export default SelectableIconButton;
