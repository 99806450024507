import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Tabs as MUITabs } from '@mui/material';

export const Tabs = ({ className, children, onChange, value, variant }) => (
  <MUITabs
    className={classnames('ayo-tabs', `ayo-tabs--${variant}`, className)}
    onChange={onChange}
    value={value}
    variant={variant}
  >
    {children}
  </MUITabs>
);

Tabs.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string,
  variant: PropTypes.oneOf(['fullWidth', 'scrollable', 'standard', 'outlined']),
};

Tabs.defaultProps = {
  className: '',
  onChange: null,
  value: '',
  variant: 'fullWidth',
};

export const TabPanel = ({ children, className, currentTabValue, value, alwaysRender }) => {
  const isShown = value === currentTabValue;
  const isRendered = alwaysRender || isShown;
  return (
    isRendered && (
      <div
        aria-labelledby={`simple-tab-${value}`}
        className={className}
        id={`simple-tabpanel-${value}`}
        role="tabpanel"
        style={alwaysRender && !isShown ? { display: 'none' } : null}
      >
        {children}
      </div>
    )
  );
};

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  currentTabValue: PropTypes.string,
  value: PropTypes.string,
  alwaysRender: PropTypes.bool,
};

TabPanel.defaultProps = {
  className: '',
  currentTabValue: '',
  value: '',
  alwaysRender: false,
};
