import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { Link } from '../../atoms';
import { rootRoute } from '../../../constants/routes';
import { ReactComponent as LogoImg } from '../../../resources/images/app_logo.svg';

export const LogoLink = () => {
  const { t } = useTranslation();
  return (
    <Link component={RouterLink} to={rootRoute}>
      <LogoImg aria-label={t('AYO Logo')} height={44} role="img" />
    </Link>
  );
};

export default LogoLink;
