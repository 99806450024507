import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Divider, useMediaQuery, useTheme } from '@mui/material';
import classNames from 'classnames';

import { ActionsMenu, Button, Typography } from '../../../../atoms';
import {
  DisabledControlWithTooltip,
  EditingButton,
  MlChip,
  NewFeatureHotspot,
  STTTextField,
} from '../../../../moleculas';
import { useNotificationsData } from '../../../../../hooks';
import { getGroupedArrayByKey, getIsDomElementCropped } from '../../../../../utils';
import {
  Directions,
  InputsValidationRules,
  NotificationSourcesMap,
  PortfolioNotificationSectionMap,
  PortfolioSectionTypes,
} from '../../../../../constants/enums';
import { ReactComponent as AddIcon } from '../../../../../resources/icons/add.svg';
import { ReactComponent as ReflectionIcon } from '../../../../../resources/icons/reflection_primary.svg';
import { ReactComponent as ShowLessIcon } from '../../../../../resources/icons/chevron_up.svg';
import { ReactComponent as ShowMoreIcon } from '../../../../../resources/icons/chevron_down.svg';
import { ReactComponent as ChevronRightIcon } from '../../../../../resources/icons/chevron_right.svg';
import PortfolioAddItemsDialog from '../portfolio-add-item-dialog/PortfolioAddItemsDialog';
import PortfolioUpdateNotification from '../portfolio-update-notification/PortfolioUpdateNotification';

const PortfolioSectionThemes = {
  LIGHT: 'light',
  DARK: 'dark',
};

const PortfolioSection = ({
  addItemsActionsConfig,
  body,
  className,
  description,
  emptyState,
  isEditMode,
  itemsToAdd,
  mediaContent,
  newFeaturesTourCfg,
  onAddItems,
  onUpdatePositionHandler,
  onChangeReflection,
  onSectionUpdate,
  reflection,
  sectionKey,
  settings,
  showAYOChip,
  showActionsMenu,
  showAddButton,
  theme,
  title,
  sectionIndex,
  isLastSection,
}) => {
  const { t } = useTranslation();
  const [reflectionValue, setReflectionValue] = useState('');
  const [isReflectionInputVisible, setIsReflectionInputVisible] = useState(false);
  const [isAddItemsDialogOpen, setIsAddItemsDialogOpen] = useState(false);
  const materialTheme = useTheme();
  const isWidthUpLg = useMediaQuery(materialTheme.breakpoints.up('lg'));
  const reflectionInputId = `portfolio-${title?.split(' ').join('-')}`;
  const { notificationsList, updateNotificationItems } = useNotificationsData();

  const portfolioNotifications = useMemo(
    () => getGroupedArrayByKey(notificationsList?.WEB_HIDDEN, 'resource') || [],
    [notificationsList?.WEB_HIDDEN],
  );
  const sectionNotifications = useMemo(
    () => portfolioNotifications[PortfolioNotificationSectionMap[sectionKey]],
    [sectionKey, portfolioNotifications],
  );
  const unreadNotifications = sectionNotifications?.filter((i) => !i.markedAsRead);
  const isWithNotifications = useMemo(
    () =>
      Object.keys(portfolioNotifications).includes(PortfolioNotificationSectionMap[sectionKey]) &&
      unreadNotifications.length,
    [sectionKey, portfolioNotifications, unreadNotifications],
  );

  const reflectionRef = useRef();
  const [isReflectionCropped, setIsReflectionCropped] = useState(true);
  const [isReflectionExpanded, setIsReflectionExpanded] = useState(false);

  useEffect(() => {
    if (reflection && !isEditMode && reflectionRef.current) {
      const isCropped = getIsDomElementCropped(
        reflectionRef.current.scrollHeight,
        0,
        reflectionRef.current.clientHeight,
      );
      setIsReflectionCropped(isCropped);
    }
  }, [isEditMode, reflection]);

  useEffect(() => {
    setReflectionValue(reflection);
    setIsReflectionInputVisible(!!reflection);
  }, [reflection]);

  const onCloseHandler = useCallback(() => {
    setIsAddItemsDialogOpen(false);
    if (unreadNotifications?.length) {
      updateNotificationItems(
        unreadNotifications.map((item) => ({ ...item, markedAsRead: true })),
        NotificationSourcesMap.WEB_HIDDEN,
      );
    }
  }, [unreadNotifications, updateNotificationItems]);

  const onReflectionInputChange = useCallback((e) => {
    setReflectionValue(e.target.value.toString().trimStart());
  }, []);

  const onReflectionInputBlur = useCallback(
    (e) => {
      const { value } = e.target;
      if (
        value !== reflection &&
        (reflection || value) &&
        value.length <= InputsValidationRules.MAX_EDIT_CONTENT_INPUT_LENGTH
      ) {
        onChangeReflection(reflection, value);
      }
    },
    [onChangeReflection, reflection],
  );

  const reflectionHideHandler = useCallback(() => {
    setIsReflectionInputVisible(!isReflectionInputVisible);
    if (reflectionValue) {
      onChangeReflection(reflection, null);
    }
  }, [isReflectionInputVisible, onChangeReflection, reflection, reflectionValue]);

  return (
    <Box
      className={`${classNames('ayo-portfolio-section', `ayo-portfolio-section--${theme}`, {
        [className]: className,
      })}`}
      display="flex"
      flexDirection="row"
      p={3}
    >
      {mediaContent && (!isEditMode || isWidthUpLg) && <Box mr={3}>{mediaContent}</Box>}
      <Box width="100%">
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box alignItems="center" display="flex" flexDirection="row">
            <Typography
              className="ayo-portfolio-section__title"
              component="h2"
              isLightText={theme === PortfolioSectionThemes.DARK}
              variant="subtitle1"
            >
              {t(title)}
            </Typography>
            {showAYOChip && (
              <Box ml={1}>
                <MlChip />
              </Box>
            )}
            {newFeaturesTourCfg && (
              <NewFeatureHotspot
                id={newFeaturesTourCfg.id}
                isClickable
                label={newFeaturesTourCfg.label}
              />
            )}
          </Box>
          <Box display="flex">
            {showActionsMenu && (
              <Box alignItems="center" display="flex" flexDirection="row">
                <Box mr={3}>
                  <ActionsMenu
                    gaLabel="Actions"
                    id="portfolio-actions"
                    label={t('Actions')}
                    menuItems={[
                      {
                        text: t(isReflectionInputVisible ? 'Hide reflection' : 'Add reflection'),
                        icon: ReflectionIcon,
                        handler: reflectionHideHandler,
                        id: 'add-reflection',
                        gaLabel: isReflectionInputVisible ? 'Hide reflection' : 'Add reflection',
                      },
                    ]}
                    paperClassName="ayo-portfolio-section__actions-menu"
                  />
                </Box>
                <Divider className="ayo-portfolio-section__divider" orientation="vertical" />
              </Box>
            )}
            {isEditMode && (
              <Box display="flex">
                <Box mr={2}>
                  <DisabledControlWithTooltip
                    gaLabel="disabled down button"
                    showTooltip={isLastSection}
                    title={t('You can’t move the block down from this position.')}
                  >
                    <Button
                      aria-label={t('Move down')}
                      className="ayo-portfolio-section__position-button"
                      disabled={isLastSection}
                      gaLabel="Move section down"
                      isIconButton
                      onClick={() =>
                        onUpdatePositionHandler(sectionIndex, sectionIndex + 1, Directions.DOWN)
                      }
                      variant="secondary"
                    >
                      <ShowMoreIcon />
                    </Button>
                  </DisabledControlWithTooltip>
                </Box>
                <DisabledControlWithTooltip
                  gaLabel="disabled down button"
                  showTooltip={sectionIndex === 0}
                  title={t('You can’t move the block up from this position.')}
                >
                  <Button
                    aria-label={t('Move up')}
                    className="ayo-portfolio-section__position-button"
                    disabled={sectionIndex === 0}
                    gaLabel="Move section up"
                    isIconButton
                    onClick={() =>
                      onUpdatePositionHandler(sectionIndex, sectionIndex - 1, Directions.UP)
                    }
                    variant="secondary"
                  >
                    <ShowLessIcon />
                  </Button>
                </DisabledControlWithTooltip>
              </Box>
            )}
          </Box>
        </Box>
        {mediaContent && !isWidthUpLg && isEditMode && <Box mb={3}>{mediaContent}</Box>}
        {description && !emptyState && (
          <Box mb={3}>
            <Typography isLightText={theme === PortfolioSectionThemes.DARK} variant="body2">
              {t(description)}
            </Typography>
          </Box>
        )}
        {isReflectionInputVisible && !emptyState && isEditMode && (
          <Box maxWidth="50%" mb={3} mt={2}>
            <STTTextField
              className={theme}
              fullWidth
              gaLabel={`Portfolio ${title} - reflection`}
              InputLabelProps={{ id: reflectionInputId }}
              inputProps={{
                'aria-labelledby': reflectionInputId,
              }}
              label={t('Add your reflection here')}
              maxLength={InputsValidationRules.MAX_EDIT_CONTENT_INPUT_LENGTH}
              multiline
              onBlur={onReflectionInputBlur}
              onChange={onReflectionInputChange}
              outlined
              rowsCount={4}
              value={reflectionValue || ''}
            />
          </Box>
        )}
        {reflection && !isEditMode && !emptyState && (
          <Box maxWidth="50%" mt={3}>
            <Typography
              ref={reflectionRef}
              className={`${classNames('ayo-portfolio-section__reflection', {
                'ayo-portfolio-section__reflection--cropped':
                  isReflectionCropped && !isReflectionExpanded,
              })}`}
              isLightText={theme === PortfolioSectionThemes.DARK}
              variant="body2"
            >
              {reflection}
            </Typography>
            {isReflectionCropped && (
              <Box mb={1}>
                <EditingButton
                  className="expand-button"
                  gaLabel={!isReflectionExpanded ? 'Show more' : 'Show less'}
                  icon={!isReflectionExpanded ? <ShowMoreIcon /> : <ShowLessIcon />}
                  iconPosition="end"
                  onClick={() => setIsReflectionExpanded((prevState) => !prevState)}
                  text={!isReflectionExpanded ? t('Show more') : t('Show less')}
                />
              </Box>
            )}
          </Box>
        )}
        {isWithNotifications && isEditMode ? (
          <PortfolioUpdateNotification
            notifications={unreadNotifications}
            onUpdate={
              onSectionUpdate
                ? () => {
                    onSectionUpdate();
                    onCloseHandler();
                  }
                : () => setIsAddItemsDialogOpen(true)
            }
            sectionKey={sectionKey}
            title={title}
            updateNotificationItems={updateNotificationItems}
          />
        ) : null}
        {emptyState || body}
        {showAddButton && (
          <Box mt={3}>
            <Button
              endIcon={<AddIcon />}
              gaLabel={`Add ${title}`}
              onClick={() => setIsAddItemsDialogOpen(true)}
            >
              {t('Add portfolio items title', {
                sectionName:
                  sectionKey === PortfolioSectionTypes.PORTFOLIO__LEADERSHIP_ATTRIBUTES
                    ? t(title)
                    : t(title).toLowerCase(),
              })}
            </Button>
          </Box>
        )}
        {addItemsActionsConfig && (
          <Box mt={3}>
            <ActionsMenu
              activatorIcon={<ChevronRightIcon />}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              gaLabel={addItemsActionsConfig.title}
              id={addItemsActionsConfig.title.split(' ').join('-')}
              label={t(addItemsActionsConfig.title)}
              menuItems={[
                ...addItemsActionsConfig.items,
                !!itemsToAdd?.length && {
                  text: t('Choose from existing'),
                  icon: AddIcon,
                  handler: () => setIsAddItemsDialogOpen(true),
                  id: `add-from-list-${addItemsActionsConfig.title.split(' ').join('-')}`,
                  gaLabel: `${addItemsActionsConfig.title} from your list`,
                },
              ].filter((item) => item)}
              noIconsRecolor
              paperClassName="ayo-portfolio-add-actions-menu"
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            />
          </Box>
        )}
      </Box>
      {isEditMode && (
        <PortfolioAddItemsDialog
          isOpen={isAddItemsDialogOpen}
          itemsToAdd={itemsToAdd}
          notifications={unreadNotifications}
          onAdd={onAddItems}
          onClose={onSectionUpdate ? () => setIsAddItemsDialogOpen(false) : onCloseHandler}
          sectionKey={sectionKey}
          sectionName={title}
          settings={settings}
        />
      )}
    </Box>
  );
};

PortfolioSection.propTypes = {
  addItemsActionsConfig: PropTypes.instanceOf(Object),
  body: PropTypes.node,
  className: PropTypes.string,
  description: PropTypes.string,
  emptyState: PropTypes.node,
  isEditMode: PropTypes.bool.isRequired,
  itemsToAdd: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  mediaContent: PropTypes.node,
  newFeaturesTourCfg: PropTypes.instanceOf(Object),
  onAddItems: PropTypes.func,
  onChangeReflection: PropTypes.func,
  onSectionUpdate: PropTypes.func,
  onUpdatePositionHandler: PropTypes.func,
  reflection: PropTypes.string,
  sectionKey: PropTypes.string,
  settings: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  showAYOChip: PropTypes.bool,
  showActionsMenu: PropTypes.bool,
  showAddButton: PropTypes.bool,
  theme: PropTypes.string,
  title: PropTypes.string.isRequired,
  sectionIndex: PropTypes.number.isRequired,
  isLastSection: PropTypes.bool,
};

PortfolioSection.defaultProps = {
  addItemsActionsConfig: null,
  body: null,
  className: '',
  description: '',
  emptyState: null,
  itemsToAdd: null,
  mediaContent: null,
  newFeaturesTourCfg: null,
  onAddItems: () => {},
  onChangeReflection: () => {},
  onUpdatePositionHandler: () => {},
  onSectionUpdate: null,
  reflection: '',
  sectionKey: '',
  settings: null,
  showAYOChip: false,
  showActionsMenu: false,
  showAddButton: false,
  theme: PortfolioSectionThemes.LIGHT,
  isLastSection: false,
};

export default PortfolioSection;
