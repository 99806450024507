import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  PageIllustrationBlock,
  PreviousPageButton,
  ScreenCenteredContainer,
} from '../../../moleculas';
import { PageWrapper } from '../../../organisms';
import { ReactComponent as ErrorRoadWithSign } from '../../../../resources/images/error_404_not_found.svg';

const NotFoundPage = () => {
  const { t } = useTranslation();

  return (
    <PageWrapper noNav>
      <ScreenCenteredContainer>
        <PageIllustrationBlock
          Illustration={ErrorRoadWithSign}
          illustrationAriaLabel={t('A road with footprints and a yellow No Outlet sign at the end')}
          mainHeaderText={t('Whoops, looks like youve wandered too far')}
          secondaryHeaderText={[t('The page you were looking for is not here')]}
        />
        <PreviousPageButton />
      </ScreenCenteredContainer>
    </PageWrapper>
  );
};

export default NotFoundPage;
