import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { ActivityLog } from '../../../../../organisms';
import { activityLogRoute, apiRoute, studentsRoute } from '../../../../../../constants/routes';
import { ActivityLogContentTypesMap } from '../../../../../../constants/moods';
import { useAdministratorsService } from '../../../../../../services';

const MoodActivityLog = ({ studentId }) => {
  const {
    getActivityLogData,
    getActivityLogUnreadCount,
    putReadActivityLogItem,
    putReadAllActivityLogItems,
  } = useAdministratorsService();

  const contentType = `${ActivityLogContentTypesMap.MOOD_INTERVENTION_CONTENT_TYPE},${ActivityLogContentTypesMap.MOOD_DETAILS_COMMENT_CONTENT_TYPE}`;

  const handleDataLoad = useCallback(
    (page) => getActivityLogData(studentId, page, contentType),
    [contentType, getActivityLogData, studentId],
  );

  const handleUnreadCountLoad = useCallback(
    () => getActivityLogUnreadCount(studentId, contentType),
    [contentType, getActivityLogUnreadCount, studentId],
  );

  return (
    <ActivityLog
      exportUrl={`${apiRoute}${activityLogRoute}${studentsRoute}/${studentId}/report?contentType=${contentType}`}
      isReadyToLoadData={!!studentId}
      onDataLoad={handleDataLoad}
      onUnreadCountLoad={handleUnreadCountLoad}
      onUpdateAllItems={() => putReadAllActivityLogItems(studentId, contentType)}
      onUpdateItem={(items) => putReadActivityLogItem(studentId, items, contentType)}
    />
  );
};

MoodActivityLog.propTypes = {
  studentId: PropTypes.string.isRequired,
};

export default MoodActivityLog;
