import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { useSearchParams } from '../../../../hooks';
import { transformSearchToObject } from '../../../../utils';
import { AnalyticsStatsKeys, HighlightsInitiatives } from '../../../../constants/enums';
import PieChartComponent from '../components/pie-chart/PieChartComponent';

const PortfolioHighlights = ({ stats, totalStudentsCount, onFiltersUpdate }) => {
  const search = useSearchParams();

  const filters = useMemo(() => transformSearchToObject(search), [search]);

  return (
    <PieChartComponent
      key={filters.filterValue}
      filterCategory={HighlightsInitiatives.PORTFOLIO}
      gaLabel="Portfolio"
      onFiltersUpdate={onFiltersUpdate}
      pieColors={['#4CB963', '#DFFFE6']}
      statKey={AnalyticsStatsKeys.PORTFOLIO}
      stats={stats}
      totalStudentsCount={totalStudentsCount}
    />
  );
};

PortfolioHighlights.propTypes = {
  stats: PropTypes.instanceOf(Object).isRequired,
  totalStudentsCount: PropTypes.number.isRequired,
  onFiltersUpdate: PropTypes.func.isRequired,
};

export default PortfolioHighlights;
