import React from 'react';
import { Box, Container, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Typography } from '../../../../../../atoms';
import { ReactComponent as LADefinitionImageDesktop } from '../../../../../../../resources/images/leadership-attributes-definition-gallery-desktop.svg';
import { ReactComponent as LADefinitionImageMobile } from '../../../../../../../resources/images/leadership-attributes-definition-gallery-mobile.svg';

const LeadershipAttributesDefinitionSection = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });

  return (
    <Box className="ayo-leadership-attributes-definition">
      {isWidthUpSm ? (
        <LADefinitionImageDesktop
          aria-label={t(
            'Pictures of the Leadership Attributes hanging on the picture gallery wall',
          )}
          role="img"
        />
      ) : (
        <LADefinitionImageMobile
          aria-label={t(
            'Pictures of the Leadership Attributes hanging on the picture gallery wall',
          )}
          className="ayo-svg-illustration--secondary"
          role="img"
        />
      )}
      <Container className="ayo-leadership-attributes-definition__text-block">
        <Typography component="h1" paragraph variant="h1">
          {t('Leadership Attributes')}
        </Typography>
        <Typography variant="body2">
          {t(
            'Leadership attributes is a personal development path that discovers students’ leadership skills It helps a student to evolve and become a leader of themself, leader of others, and leader of change',
          )}
        </Typography>
      </Container>
    </Box>
  );
};

export default LeadershipAttributesDefinitionSection;
