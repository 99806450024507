import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';
import PropTypes from 'prop-types';

import { Typography } from '../../../../../atoms';
import { Chip } from '../../../../../moleculas';
import { clubMetadataType } from '../../../../../../constants/propTypes';

const ChipBlock = ({ clubs, title }) => {
  const { t } = useTranslation();
  return (
    <Grid item sm={6} xs={12}>
      <Box className="ayo-club-sections__chip-block" p={3}>
        <Typography component="h3" mb={3} variant="subtitle2">
          {t(title)}
        </Typography>
        {clubs.length ? (
          <Box display="flex" flexDirection="row" flexWrap="wrap" gap={1}>
            {clubs.map(({ clubName, id }) => (
              <Chip key={id} label={clubName} />
            ))}
          </Box>
        ) : (
          <Typography variant="body2">{t('No information so far')}</Typography>
        )}
      </Box>
    </Grid>
  );
};

ChipBlock.propTypes = {
  clubs: PropTypes.arrayOf(PropTypes.shape(clubMetadataType)).isRequired,
  title: PropTypes.string.isRequired,
};

const ClubsChipsBlocks = ({ joinedClubs, requestedClubs }) => (
  <Grid container spacing={3}>
    <ChipBlock clubs={joinedClubs} title="Membership" />
    <ChipBlock clubs={requestedClubs} title="Applied / Requested to join (student)" />
  </Grid>
);

ClubsChipsBlocks.propTypes = {
  joinedClubs: PropTypes.arrayOf(PropTypes.shape(clubMetadataType)).isRequired,
  requestedClubs: PropTypes.arrayOf(PropTypes.shape(clubMetadataType)).isRequired,
};

export default ClubsChipsBlocks;
