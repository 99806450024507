import { useState, useCallback } from 'react';

import { GaActions, GaCategories } from '../../constants/enums';
import GA from '../analytics/Analytics';

const useCalendar = () => {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const onCalendarClick = useCallback(() => {
    setIsCalendarOpen(!isCalendarOpen);
    if (!isCalendarOpen) {
      GA.logInteraction({
        category: GaCategories.BEHAVIOR,
        action: GaActions.BUTTON_CLICK,
        label: `Open calendar`,
      });
    }
  }, [isCalendarOpen]);

  return {
    isCalendarOpen,
    onCalendarClick,
  };
};

export default useCalendar;
