import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Button, DynamicGrid } from '../../../../atoms';
import { DeleteItemWrapper, InitiativeEmptyStateBlock } from '../../../../moleculas';
import {
  PortfolioEntitiesData,
  PortfolioItemsToAddData,
  transformCertificateId,
} from '../../../../../utils';
import { useStudentsService } from '../../../../../services';
import { AppActions, AppContext, UserContext } from '../../../../../context';
import { portfolioSectionConfig } from '../../../../../constants/propTypes';
import { ReactComponent as CertificatesEmptyStateIllustration } from '../../../../../resources/images/portfolio/certificates_emty_state.svg';
import { ReactComponent as AddIcon } from '../../../../../resources/icons/add.svg';
import PortfolioSection from '../../components/portfolio-section/PortfolioSection';
import PortfolioAddCertificateDialog from '../../components/portfolio-add-certificate-dialog/PortfolioAddCertificateDialog';
import { PortfolioSectionTypes } from '../../../../../constants/enums';

import CertificateCard from './components/CertificateCard';

const PortfolioCertificatesSection = ({ config, sectionIndex, isLastSection }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const {
    addPortfolioItemHandler,
    deletePortfolioItemHandler,
    hasData,
    isEditMode,
    isVisible,
    maxItemsToDisplay,
    metaData,
    reflectionHandler,
    sectionData,
    updatePortfolioHandler,
    updatePortfolioMetaData,
    onUpdatePositionHandler,
  } = config;
  const { entities, isInitialState, reflection } = sectionData;

  const [portfolioCertificates, setPortfolioCertificates] = useState([]);
  const [isCertificateDialogOpen, setIsCertificateDialogOpen] = useState(false);
  const [editingCertificateIndex, setEditingCertificateIndex] = useState(null);

  const { postStudentCertificate, updateStudentCertificate } = useStudentsService();

  const { state: userState } = useContext(UserContext);
  const { dispatch: dispatchAppState } = useContext(AppContext);

  const studentId = userState.profile?.id;

  useEffect(() => {
    setPortfolioCertificates(
      PortfolioEntitiesData[PortfolioSectionTypes.PORTFOLIO__CERTIFICATES](entities),
    );
  }, [entities, isInitialState, maxItemsToDisplay, metaData]);

  const certificatesToAdd = useMemo(
    () =>
      PortfolioItemsToAddData[PortfolioSectionTypes.PORTFOLIO__CERTIFICATES](
        entities,
        [...metaData.suggestedCertificates, ...metaData.submittedCertificates],
        'id',
      ).map(({ id, title }) => ({ id: id.toString(), name: title })),
    [entities, metaData],
  );

  const certificateSaveHandler = useCallback(
    (certificate) => {
      if (editingCertificateIndex !== null) {
        updateStudentCertificate(certificate, studentId)
          .then((data) => {
            updatePortfolioHandler({
              ...sectionData,
              entities: Object.assign([...sectionData.entities], {
                [editingCertificateIndex]: {
                  ...data,
                },
              }),
            });
            const certificateMetaDataIndex = metaData.submittedCertificates.findIndex(
              ({ id }) => id === data.id,
            );
            updatePortfolioMetaData({
              ...metaData,
              submittedCertificates: Object.assign([...metaData.submittedCertificates], {
                [certificateMetaDataIndex]: {
                  ...data,
                },
              }),
            });
          })
          .catch(() => {
            dispatchAppState({
              type: AppActions.SET_SNACKBAR_STATUS,
              data: {
                text: t('AYO couldn’t save your input Please try once more'),
                type: 'error',
              },
            });
          });
        setEditingCertificateIndex(null);
      } else {
        postStudentCertificate(certificate, studentId)
          .then((data) => {
            addPortfolioItemHandler(null, [data], 'id', sectionData);
            updatePortfolioMetaData({
              ...metaData,
              submittedCertificates: [...metaData.submittedCertificates, data],
            });
          })
          .catch(() => {
            dispatchAppState({
              type: AppActions.SET_SNACKBAR_STATUS,
              data: {
                text: t('AYO couldn’t save your input Please try once more'),
                type: 'error',
              },
            });
          });
      }
    },
    [
      dispatchAppState,
      t,
      addPortfolioItemHandler,
      editingCertificateIndex,
      metaData,
      postStudentCertificate,
      sectionData,
      studentId,
      updatePortfolioHandler,
      updatePortfolioMetaData,
      updateStudentCertificate,
    ],
  );

  if (!isVisible) {
    return null;
  }

  return (
    <Box>
      <PortfolioSection
        addItemsActionsConfig={
          hasData && isEditMode
            ? {
                title: 'Add certificate',
                items: [
                  {
                    text: t('Add new'),
                    icon: AddIcon,
                    handler: () => setIsCertificateDialogOpen(true),
                    id: 'add-new-certificate',
                    gaLabel: 'Add new certificate',
                  },
                ],
              }
            : null
        }
        body={
          <Box mt={3}>
            <DynamicGrid
              columnsCount={isWidthUpMd ? 3 : 2}
              gridItems={portfolioCertificates.map((certificate, index) => {
                const isSuggested = !Number.isInteger(certificate.id);
                return (
                  <DeleteItemWrapper
                    key={certificate.id}
                    ariaLabel={`${certificate.title}${certificate.organization}`}
                    disabled={!isEditMode}
                    gaLabel="Remove certificate"
                    mainContent={
                      <CertificateCard
                        certificate={certificate}
                        editHandler={() => {
                          setEditingCertificateIndex(index);
                          setIsCertificateDialogOpen(true);
                        }}
                        isEditMode={isEditMode}
                        isSuggested={isSuggested}
                      />
                    }
                    onDelete={() => {
                      deletePortfolioItemHandler(
                        portfolioCertificates,
                        'id',
                        certificate.id,
                        sectionData,
                      );
                    }}
                  />
                );
              })}
              isHorizontalOrder
            />
          </Box>
        }
        className={`${classNames({
          'ayo-portfolio-certificates-section': hasData,
        })}`}
        description={
          isEditMode
            ? 'Here, you can add your certificates and see the ones that AYO discovered for you'
            : ''
        }
        emptyState={
          !hasData ? (
            <InitiativeEmptyStateBlock
              body={isEditMode ? t('But you can add one now!') : ''}
              customButton={
                isEditMode ? (
                  <Button
                    endIcon={<AddIcon />}
                    gaLabel="Add certificate"
                    onClick={() => setIsCertificateDialogOpen(true)}
                  >
                    {t('Add certificate')}
                  </Button>
                ) : null
              }
              illustration={<CertificatesEmptyStateIllustration />}
              title={t('No information about certificates so far')}
            />
          ) : null
        }
        isEditMode={isEditMode}
        isLastSection={isLastSection}
        itemsToAdd={certificatesToAdd}
        onAddItems={(itemsToAdd) =>
          addPortfolioItemHandler(
            [
              ...metaData?.suggestedCertificates.map((certificate) => ({
                ...certificate,
                id: transformCertificateId(certificate),
              })),
              ...metaData?.submittedCertificates,
            ],
            itemsToAdd,
            'id',
            sectionData,
          )
        }
        onChangeReflection={reflectionHandler}
        onUpdatePositionHandler={onUpdatePositionHandler}
        reflection={reflection}
        sectionIndex={sectionIndex}
        sectionKey={PortfolioSectionTypes.PORTFOLIO__CERTIFICATES}
        showActionsMenu={hasData && isEditMode}
        showAYOChip
        title="Certificates"
      />
      {isEditMode && (
        <PortfolioAddCertificateDialog
          certificate={portfolioCertificates[editingCertificateIndex]}
          isOpen={isCertificateDialogOpen}
          onClose={() => {
            setIsCertificateDialogOpen(false);
            setEditingCertificateIndex(null);
          }}
          onSave={certificateSaveHandler}
        />
      )}
    </Box>
  );
};

PortfolioCertificatesSection.propTypes = {
  config: portfolioSectionConfig(PropTypes.instanceOf(Object)),
  sectionIndex: PropTypes.number.isRequired,
  isLastSection: PropTypes.number.isRequired,
};

PortfolioCertificatesSection.defaultProps = {
  config: {},
};

export default PortfolioCertificatesSection;
