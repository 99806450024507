import React, { useCallback, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

import { ActionsMenu, Button } from '../../../../atoms';
import { ClubsMembershipStatuses } from '../../../../../constants/club-hub';
import { useSnackbar } from '../../../../../hooks';
import { useClubHubService } from '../../../../../services';
import { ReactComponent as InActiveIcon } from '../../../../../resources/icons/inactive.svg';
import { ReactComponent as ExpandIcon } from '../../../../../resources/icons/arrow_drop_down.svg';
import { ReactComponent as CollapseIcon } from '../../../../../resources/icons/arrow_drop_up.svg';
import { ReactComponent as MoreHorizIcon } from '../../../../../resources/icons/more_horiz.svg';
import ClubJoinDialog from '../club-join-dialog/ClubJoinDialog';

const SnackBarStatuses = {
  [ClubsMembershipStatuses.PENDING_REQUEST]: {
    REQUESTED: {
      success: 'Your request for joining the club has been sent.',
      error: 'AYO couldn’t send your request for joining the club. Please try once more.',
    },
    APPLIED: {
      success: 'Your application for joining the club has been sent.',
      error: 'AYO couldn’t send your application for joining the club. Please try once more.',
    },
  },
  [ClubsMembershipStatuses.NOT_REQUESTED]: {
    [ClubsMembershipStatuses.JOINED]: {
      success: 'You left clubName and are no longer a member.',
      error: 'AYO couldn’t process your request to leave the club. Please try once more.',
    },
    [ClubsMembershipStatuses.PENDING_REQUEST]: {
      success: 'Your request has been withdrawn.',
      error: 'AYO couldn’t withdraw your request. Please try once more.',
    },
  },
};
const ClubJoinActions = ({
  clubId,
  clubName,
  isCardAction,
  membershipStatus,
  onJoin,
  requirements,
}) => {
  const { t } = useTranslation();

  const [isJoinDialogOpen, setIsJoinDialogOpen] = useState(false);

  const { isVisible: withRequirements } = requirements;

  const { postStudentJoinRequest } = useClubHubService();

  const { setSnackBarStatus } = useSnackbar();

  const postStudentRequestHandler = useCallback(
    (newMembershipStatus, notes = null, links = []) => {
      const snackBarData =
        newMembershipStatus === ClubsMembershipStatuses.PENDING_REQUEST
          ? withRequirements
            ? SnackBarStatuses[newMembershipStatus].APPLIED
            : SnackBarStatuses[newMembershipStatus].REQUESTED
          : SnackBarStatuses[newMembershipStatus][membershipStatus];
      postStudentJoinRequest(clubId, newMembershipStatus, notes, links)
        .then((data) => {
          setSnackBarStatus({
            text: (
              <Trans
                components={{ b: <strong /> }}
                i18nKey={snackBarData.success}
                values={{ clubName }}
              />
            ),
            type: 'success',
          });
          onJoin(data, clubId);
        })
        .catch(() => {
          setSnackBarStatus({
            text: t(snackBarData.error),
            type: 'error',
          });
        });
    },
    [
      clubId,
      clubName,
      membershipStatus,
      onJoin,
      postStudentJoinRequest,
      setSnackBarStatus,
      t,
      withRequirements,
    ],
  );

  const joinHandler = useCallback(
    (event) => {
      event.stopPropagation();
      if (withRequirements) {
        setIsJoinDialogOpen(true);
      } else {
        postStudentRequestHandler(ClubsMembershipStatuses.PENDING_REQUEST);
      }
    },
    [postStudentRequestHandler, withRequirements],
  );

  const ActionsMenuConfig = useMemo(
    () => ({
      [ClubsMembershipStatuses.PENDING_REQUEST]: {
        label: withRequirements ? 'Applied to join' : 'Requested to join',
        activatorIcon: <ExpandIcon />,
        secondaryIcon: <CollapseIcon />,
        menuItems: [
          {
            text: t('Withdraw request'),
            icon: InActiveIcon,
            handler: () => postStudentRequestHandler(ClubsMembershipStatuses.NOT_REQUESTED),
            id: `withdraw-request-actions--${clubId}`,
            gaLabel: 'Withdraw request',
          },
        ],
      },
      [ClubsMembershipStatuses.JOINED]: {
        label: 'Actions',
        activatorIcon: <MoreHorizIcon />,
        secondaryIcon: null,
        menuItems: [
          {
            text: t('Leave the club'),
            icon: InActiveIcon,
            handler: () => postStudentRequestHandler(ClubsMembershipStatuses.NOT_REQUESTED),
            id: `leave-actions--${clubId}`,
            gaLabel: 'Leave the club',
          },
        ],
      },
    }),
    [clubId, withRequirements, postStudentRequestHandler, t],
  );

  return (
    <>
      <Box className="ayo-club-hub__join-actions" minWidth="max-content">
        {[ClubsMembershipStatuses.NOT_REQUESTED, ClubsMembershipStatuses.REQUEST_REJECTED].includes(
          membershipStatus,
        ) ? (
          <Button
            className="ayo-club-hub__join-actions__join-button"
            gaLabel={withRequirements ? 'Apply' : isCardAction ? 'Join' : 'Join club'}
            onClick={(e) => {
              joinHandler(e);
            }}
            variant={isCardAction ? 'text' : 'primary'}
          >
            {t(withRequirements ? 'Apply' : isCardAction ? 'Join' : 'Join club')}
          </Button>
        ) : (
          <ActionsMenu
            activatorIcon={ActionsMenuConfig[membershipStatus].activatorIcon}
            gaLabel={ActionsMenuConfig[membershipStatus].label}
            id={`clubs-membership-actions--${clubId}`}
            label={t(ActionsMenuConfig[membershipStatus].label)}
            menuItems={ActionsMenuConfig[membershipStatus].menuItems}
            noIconsRecolor
            secondaryIcon={ActionsMenuConfig[membershipStatus].secondaryIcon}
          />
        )}
      </Box>
      {withRequirements && (
        <ClubJoinDialog
          clubName={clubName}
          isOpen={isJoinDialogOpen}
          onClose={() => setIsJoinDialogOpen(false)}
          onSubmit={({ notes, links }) =>
            postStudentRequestHandler(ClubsMembershipStatuses.PENDING_REQUEST, notes, links)
          }
          requirements={requirements}
        />
      )}
    </>
  );
};

ClubJoinActions.propTypes = {
  clubId: PropTypes.number.isRequired,
  clubName: PropTypes.string.isRequired,
  isCardAction: PropTypes.bool,
  membershipStatus: PropTypes.string.isRequired,
  onJoin: PropTypes.func.isRequired,
  requirements: PropTypes.shape({
    isVisible: PropTypes.bool,
    description: PropTypes.string,
    links: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  }).isRequired,
};

ClubJoinActions.defaultProps = {
  isCardAction: false,
};
export default ClubJoinActions;
