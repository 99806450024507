import React, { useEffect, useMemo, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

import ContinueButton from '../continue-button/ContinueButton';
import { Typography } from '../../../../../atoms';
import { ReactComponent as DragAndDropIcon } from '../../../../../../resources/icons/drag_and_drop.svg';
import { InformationalMessage } from '../../../../../moleculas';
import {
  LessonPlannerStates,
  OneTimeActionsMap,
  Semesters,
} from '../../../../../../constants/enums';
import { useLessonPlannerData } from '../../../../../../hooks';
import { useProfile } from '../../../../../../utils';
import { useOneTimeActionService } from '../../../../../../services';
import { AppActions, AppContext, UserActions, UserContext } from '../../../../../../context';

import RotationDaysSchedule from './components/rotation-days-schedule/RotationDaysSchedule';
import RotationWeeksSchedule from './components/rotation-weeks-schedule/RotationWeeksSchedule';

const ROTATION_DAYS_LABELS = ['A', 'B'];

const CoursesSettings = ({ semester, semesterName, year, schoolYear }) => {
  const { t } = useTranslation();

  const {
    setUpCoursesSchedule,
    updateLessonPlanerCreationState,
    saveLessonPlanerSchedule,
    rotationDaysSchedule,
    rotationWeeksSchedule,
    lessonPlannerData,
    getScheduleAvailablePeriods,
  } = useLessonPlannerData();

  useEffect(() => {
    setUpCoursesSchedule(semester, schoolYear);
    getScheduleAvailablePeriods();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasNextSemesterSetup =
    semester === Semesters.FALL && lessonPlannerData.stateData.semestersSchedule.length === 2;

  const hasPreviousSemesterSetup =
    semester === Semesters.SPRING && lessonPlannerData.stateData.semestersSchedule.length === 2;

  const { getOneTimeActionStatus } = useProfile();

  const { postOneTimeAction } = useOneTimeActionService();

  const { dispatch: dispatchUserState } = useContext(UserContext);

  const isInformationalMessageVisible = useMemo(
    () => !getOneTimeActionStatus(OneTimeActionsMap.LESSONS_PLANNER_DRAG_DROP),
    [getOneTimeActionStatus],
  );

  const onInformationMessageClick = useCallback(() => {
    postOneTimeAction(OneTimeActionsMap.LESSONS_PLANNER_DRAG_DROP);
    dispatchUserState({
      type: UserActions.SET_ONE_TIME_ACTION,
      data: OneTimeActionsMap.LESSONS_PLANNER_DRAG_DROP,
    });
  }, [dispatchUserState, postOneTimeAction]);

  const getRotationDaysLabel = (daysCount) => {
    const daysLabel = ROTATION_DAYS_LABELS.slice(0, daysCount).join(', ');
    return `${t('Rotation days')} (${daysLabel})`;
  };

  const getRotationWeeksLabel = (weeksCount) => {
    const weeksLabel = Array.from(Array(weeksCount), (_, i) => i + 1).join(', ');
    return `${t('Rotation weeks')} (${weeksLabel})`;
  };

  const { dispatch: dispatchAppState } = useContext(AppContext);

  return (
    <Box className="ayo-courses-settings">
      <Box alignItems="center" display="flex" flexDirection="row" mb={3}>
        <Typography variant="subtitle1">
          {`${t('Your schedule for')} ${t(semesterName)} ${year}`}
        </Typography>
        <Box className="ayo-courses-settings__label" ml={3} mr={2} px={1}>
          <Typography variant="subtitle2">
            {rotationDaysSchedule
              ? getRotationDaysLabel(rotationDaysSchedule.daysSchedule.length)
              : getRotationWeeksLabel(rotationWeeksSchedule.length)}
          </Typography>
        </Box>
      </Box>
      <Box>
        <Typography variant="body2">
          {t(
            'Put your classes in the right schedule order. Don’t pay attention to any periods, just the order.',
          )}
        </Typography>
      </Box>
      {isInformationalMessageVisible && (
        <Box maxWidth="50%" pt={2}>
          <InformationalMessage
            icon={<DragAndDropIcon />}
            onClick={onInformationMessageClick}
            text={t(
              'You can remove the class(es) by hovering the card and clicking X and change the classes order by dragging the card.',
            )}
          />
        </Box>
      )}
      <Box mb={10} mt={5}>
        {rotationDaysSchedule && (
          <RotationDaysSchedule schoolYear={schoolYear} semester={semester} />
        )}
        {rotationWeeksSchedule && (
          <RotationWeeksSchedule schoolYear={schoolYear} semester={semester} />
        )}
      </Box>
      <ContinueButton
        buttonName={hasNextSemesterSetup ? 'Next' : 'Done'}
        onNext={() => {
          if (hasNextSemesterSetup) {
            updateLessonPlanerCreationState({
              state: LessonPlannerStates.CREATING_COURSE_SCHEDULE_SPRING,
            });
          } else {
            saveLessonPlanerSchedule()
              .then(() => {
                dispatchAppState({
                  type: AppActions.SET_SNACKBAR_STATUS,
                  data: {
                    text: t('The lesson planner has been created.'),
                    type: 'success',
                  },
                });
                getScheduleAvailablePeriods(true);
              })
              .catch(() => {
                dispatchAppState({
                  type: AppActions.SET_SNACKBAR_STATUS,
                  data: {
                    text: t('Failed to create a lesson planner. Please try once more.'),
                    type: 'error',
                  },
                });
              });
          }
        }}
        onPrevious={() =>
          updateLessonPlanerCreationState({
            state: hasPreviousSemesterSetup
              ? LessonPlannerStates.CREATING_COURSE_SCHEDULE_FALL
              : LessonPlannerStates.CREATING_ROTATION_DATA,
          })
        }
      />
    </Box>
  );
};

CoursesSettings.propTypes = {
  semester: PropTypes.oneOf(Object.values(Semesters)).isRequired,
  semesterName: PropTypes.string.isRequired,
  year: PropTypes.number.isRequired,
  schoolYear: PropTypes.number.isRequired,
};

export default CoursesSettings;
