/* eslint-disable  react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation, Trans } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { Link, Typography } from '../../atoms';
import {
  rootRoute,
  emailAyoMisdRoute,
  misdCoreValuesRoute,
  softserveDataPrivacyRoute,
  googleTrustRoute,
} from '../../../constants/routes';

const PrivacyPolicyContent = ({ fullWidth }) => {
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const { t } = useTranslation();

  return (
    <Grid container item>
      <Grid item sm={fullWidth ? 12 : 7} xs={12}>
        <Box mb={isWidthUpSm ? 3 : 2}>
          <Typography component="h2" variant="h3">
            {t('About AYO')}
          </Typography>
        </Box>
        <Box mb={isWidthUpSm ? 7 : 4}>
          <Typography paragraph variant="body2">
            {t('About AYO paragraph 1')}
          </Typography>
          <Typography variant="body2">
            {t('About AYO paragraph 2')}
            <Link component={RouterLink} to={rootRoute}>
              myayo.world
            </Link>
            .
          </Typography>
        </Box>
        <Box mb={isWidthUpSm ? 3 : 2}>
          <Typography component="h2" variant="h3">
            {t('Why Does AYO Collect information from its users?')}
          </Typography>
        </Box>
        <Box mb={isWidthUpSm ? 7 : 4}>
          <Typography variant="body2">
            {t('Why Does AYO Collect information from its users? paragraph')}
          </Typography>
        </Box>
        <Box mb={isWidthUpSm ? 3 : 2}>
          <Typography component="h2" variant="h3">
            {t('What types of information does AYO collect?')}
          </Typography>
        </Box>
        <Box mb={isWidthUpSm ? 7 : 4}>
          <Typography paragraph variant="body2">
            {t('What types of information does AYO collect? paragraph 1')}
          </Typography>
          <Typography paragraph variant="body2">
            <ol>
              <li>{t('What types of information does AYO collect? paragraph 1 li_1')}</li>
              <li>{t('What types of information does AYO collect? paragraph 1 li_2')}</li>
              <li>{t('What types of information does AYO collect? paragraph 1 li_3')}</li>
            </ol>
          </Typography>
          <Typography paragraph variant="body2">
            <Trans
              components={{ b: <b /> }}
              i18nKey="What types of information does AYO collect? paragraph 2"
            />
          </Typography>
          <Typography paragraph variant="body2">
            <Trans
              components={{ b: <b /> }}
              i18nKey="What types of information does AYO collect? paragraph 3 with tags"
            />
          </Typography>
          <Typography variant="body2">
            {t('What types of information does AYO collect? paragraph 4 part 1')}
            <Link component={RouterLink} to={rootRoute}>
              myayo.world
            </Link>
            {t('What types of information does AYO collect? paragraph 4 part 2')}
            <Link component={RouterLink} to={rootRoute}>
              myayo.world
            </Link>
            {t('What types of information does AYO collect? paragraph 4 part 3')}
          </Typography>
        </Box>
        <Box mb={isWidthUpSm ? 3 : 2}>
          <Typography component="h2" variant="h3">
            {t('How does AYO collect information from its users?')}
          </Typography>
        </Box>
        <Box mb={isWidthUpSm ? 7 : 4}>
          <Typography paragraph variant="body2">
            {t('How does AYO collect information from its users? paragraph 1')}
          </Typography>
          <Typography paragraph variant="body2">
            <ul>
              <li>{t('How does AYO collect information from its users? paragraph 1 li_1')}</li>
              <li>{t('How does AYO collect information from its users? paragraph 1 li_2')}</li>
              <li>{t('How does AYO collect information from its users? paragraph 1 li_3')}</li>
              <li>{t('How does AYO collect information from its users? paragraph 1 li_4')}</li>
              <li>{t('How does AYO collect information from its users? paragraph 1 li_5')}</li>
              <li>{t('How does AYO collect information from its users? paragraph 1 li_6')}</li>
            </ul>
          </Typography>
          <Typography paragraph variant="body2">
            {t('How does AYO collect information from its users? paragraph 2')}
          </Typography>
          <Typography paragraph variant="body2">
            <ul>
              <li>{t('How does AYO collect information from its users? paragraph 2 li_1')}</li>
              <li>{t('How does AYO collect information from its users? paragraph 2 li_2')}</li>
              <li>{t('How does AYO collect information from its users? paragraph 2 li_3')}</li>
              <li>{t('How does AYO collect information from its users? paragraph 2 li_4')}</li>
              <li>{t('How does AYO collect information from its users? paragraph 2 li_5')}</li>
            </ul>
          </Typography>
          <Typography paragraph variant="body2">
            {t('How does AYO collect information from its users? paragraph 3')}
          </Typography>
          <Typography variant="body2">
            <ul>
              <li>{t('How does AYO collect information from its users? paragraph 3 li_1')}</li>
              <li>{t('How does AYO collect information from its users? paragraph 3 li_2')}</li>
              <li>{t('How does AYO collect information from its users? paragraph 3 li_3')}</li>
              <li>{t('How does AYO collect information from its users? paragraph 3 li_4')}</li>
              <li>{t('How does AYO collect information from its users? paragraph 3 li_5')}</li>
            </ul>
          </Typography>
        </Box>
        <Box mb={isWidthUpSm ? 3 : 2}>
          <Typography component="h2" variant="h3">
            {t('What laws and regulations are applied to data collection and storage?')}
          </Typography>
        </Box>
        <Box mb={isWidthUpSm ? 7 : 4}>
          <Typography paragraph variant="body2">
            {t('What laws and regulations are applied to data collection and storage? paragraph 4')}
          </Typography>
          <Typography variant="body2">
            <ul>
              <li>
                <Trans
                  components={{ b: <b /> }}
                  i18nKey="What laws and regulations are applied to data collection and storage? paragraph 4 li_1 with tags"
                />
              </li>
              <li>
                <Trans
                  components={{ b: <b /> }}
                  i18nKey="What laws and regulations are applied to data collection and storage? paragraph 4 li_2 with tags"
                />
              </li>
              <li>
                <Trans
                  components={{ b: <b /> }}
                  i18nKey="What laws and regulations are applied to data collection and storage? paragraph 4 li_3 with tags"
                />
              </li>
              <li>
                <Trans
                  components={{ b: <b /> }}
                  i18nKey="What laws and regulations are applied to data collection and storage? paragraph 4 li_4 with tags"
                />
              </li>
              <li>
                <Trans
                  components={{ b: <b /> }}
                  i18nKey="What laws and regulations are applied to data collection and storage? paragraph 4 li_5 with tags"
                />
              </li>
              <li>
                <Trans
                  components={{ b: <b /> }}
                  i18nKey="What laws and regulations are applied to data collection and storage? paragraph 4 li_6 with tags"
                />
              </li>
              <li>
                <Trans
                  components={{ b: <b /> }}
                  i18nKey="What laws and regulations are applied to data collection and storage? paragraph 4 li_7 with tags"
                />
              </li>
              <li>
                <Trans
                  components={{ b: <b /> }}
                  i18nKey="What laws and regulations are applied to data collection and storage? paragraph 4 li_8 with tags"
                />
              </li>
            </ul>
          </Typography>
        </Box>
        <Box mb={isWidthUpSm ? 3 : 2}>
          <Typography component="h2" variant="h3">
            {t('How Does AYO Treat Student Records?')}
          </Typography>
        </Box>
        <Box mb={isWidthUpSm ? 7 : 4}>
          <Typography variant="body2">
            {t('How Does AYO Treat Student Records? paragraph part 1')}
          </Typography>
        </Box>
        <Box mb={isWidthUpSm ? 3 : 2}>
          <Typography component="h2" variant="h3">
            {t('How Does AYO Treat Information About Children Under 13 Years Old?')}
          </Typography>
        </Box>
        <Box mb={isWidthUpSm ? 7 : 4}>
          <Typography paragraph variant="body2">
            {t('How Does AYO Treat Information About Children Under 13 Years Old? paragraph 1')}
          </Typography>
          <Typography paragraph variant="body2">
            {t('How Does AYO Treat Information About Children Under 13 Years Old? paragraph 2')}
          </Typography>
          <Typography paragraph variant="body2">
            {t('How Does AYO Treat Information About Children Under 13 Years Old? paragraph 3')}
          </Typography>
          <Typography paragraph variant="body2">
            <Trans
              components={{
                Link: <Link href={emailAyoMisdRoute} />,
              }}
              i18nKey="How Does AYO Treat Information About Children Under 13 Years Old? paragraph 4 part_1"
            />
          </Typography>
          <Typography paragraph variant="body2">
            <Trans
              components={{
                Link: <Link href={emailAyoMisdRoute} />,
              }}
              i18nKey="How Does AYO Treat Information About Children Under 13 Years Old? paragraph 4 part_2"
            />
          </Typography>
          <Typography variant="body2">
            <Trans
              components={{
                Link: <Link href={emailAyoMisdRoute} />,
              }}
              i18nKey="How Does AYO Treat Information About Children Under 13 Years Old? paragraph 5"
            />
          </Typography>
        </Box>
        <Box mb={isWidthUpSm ? 3 : 2}>
          <Typography component="h2" variant="h3">
            {t('How Does Mesquite ISD Use Personally Identifiable Information?')}
          </Typography>
        </Box>
        <Box mb={isWidthUpSm ? 7 : 4}>
          <Typography paragraph variant="body2">
            {t('How Does Mesquite ISD Use Personally Identifiable Information? paragraph 1')}
          </Typography>
          <Typography paragraph variant="body2">
            {t('How Does Mesquite ISD Use Personally Identifiable Information? paragraph 2')}
          </Typography>
          <Typography paragraph variant="body2">
            {t('How Does Mesquite ISD Use Personally Identifiable Information? paragraph 3')}
          </Typography>
          <Typography paragraph variant="body2">
            {t('How Does Mesquite ISD Use Personally Identifiable Information? paragraph 4')}
          </Typography>
          <Typography variant="body2">
            {t('How Does Mesquite ISD Use Personally Identifiable Information? paragraph 5')}
          </Typography>
        </Box>
        <Box mb={isWidthUpSm ? 3 : 2}>
          <Typography component="h2" variant="h3">
            {t('Principles We Follow Concerning The Data Storage And Processing?')}
          </Typography>
        </Box>
        <Box mb={isWidthUpSm ? 7 : 4}>
          <Typography paragraph variant="body2">
            {t('Principles We Follow Concerning The Data Storage And Processing? paragraph 1')}
          </Typography>
          <Typography paragraph variant="body2">
            <ul>
              <li>
                {t(
                  'Principles We Follow Concerning The Data Storage And Processing? paragraph 2 li_1',
                )}
              </li>
              <li>
                {t(
                  'Principles We Follow Concerning The Data Storage And Processing? paragraph 2 li_2',
                )}
              </li>
              <li>
                {t(
                  'Principles We Follow Concerning The Data Storage And Processing? paragraph 2 li_3',
                )}
              </li>
              <li>
                {t(
                  'Principles We Follow Concerning The Data Storage And Processing? paragraph 2 li_4',
                )}
              </li>
              <li>
                {t(
                  'Principles We Follow Concerning The Data Storage And Processing? paragraph 2 li_5',
                )}
              </li>
              <li>
                {t(
                  'Principles We Follow Concerning The Data Storage And Processing? paragraph 2 li_6 part_1',
                )}
                &nbsp;
                <Link href={misdCoreValuesRoute}>
                  {t(
                    'Principles We Follow Concerning The Data Storage And Processing? paragraph 2 li_6 part_2',
                  )}
                </Link>
              </li>
              <li>
                {t(
                  'Principles We Follow Concerning The Data Storage And Processing? paragraph 2 li_7',
                )}
              </li>
              <li>
                {t(
                  'Principles We Follow Concerning The Data Storage And Processing? paragraph 2 li_8',
                )}
              </li>
            </ul>
          </Typography>
          <Typography paragraph variant="body2">
            {t('Principles We Follow Concerning The Data Storage And Processing? paragraph 3')}
          </Typography>
          <Typography variant="body2">
            <ul>
              <li>
                {t(
                  'Principles We Follow Concerning The Data Storage And Processing? paragraph 3 li_1',
                )}
              </li>
              <li>
                {t(
                  'Principles We Follow Concerning The Data Storage And Processing? paragraph 3 li_2',
                )}
              </li>
              <li>
                {t(
                  'Principles We Follow Concerning The Data Storage And Processing? paragraph 3 li_3',
                )}
              </li>
            </ul>
          </Typography>
        </Box>
        <Box mb={isWidthUpSm ? 3 : 2}>
          <Typography component="h2" variant="h3">
            {t('Does Mesquite ISD Share Your Information?')}
          </Typography>
        </Box>
        <Box mb={isWidthUpSm ? 7 : 4}>
          <Typography variant="body2">
            {t('Does Mesquite ISD Share Your Information? paragraph part_1')}{' '}
            <Link href={softserveDataPrivacyRoute} target="_blank">
              SoftServe
            </Link>{' '}
            {t('and')} <Link href={googleTrustRoute}>Google LLC</Link>{' '}
            {t('Does Mesquite ISD Share Your Information? paragraph part_2')}
          </Typography>
        </Box>
        <Box mb={isWidthUpSm ? 3 : 2}>
          <Typography component="h2" variant="h3">
            {t('What Happens When I Link To Other Websites From myayoworld?')}
          </Typography>
        </Box>
        <Box mb={isWidthUpSm ? 7 : 4}>
          <Typography variant="body2">
            {t('What Happens When I Link To Other Websites From myayoworld? paragraph part_1')}
            (&quot;
            {t('What Happens When I Link To Other Websites From myayoworld? paragraph part_2')}
            &quot;).
            {t('What Happens When I Link To Other Websites From myayoworld? paragraph part_3')}
          </Typography>
        </Box>
        <Box mb={isWidthUpSm ? 3 : 2}>
          <Typography component="h2" variant="h3">
            {t('Will This Privacy Policy Change?')}
          </Typography>
        </Box>
        <Box mb={isWidthUpSm ? 7 : 4}>
          <Typography variant="body2">
            {t('Will This Privacy Policy Change? paragraph')}
            &nbsp;
            <Link href={emailAyoMisdRoute}>myayo@mesquiteisd.org</Link>.
          </Typography>
        </Box>
        <Box mb={isWidthUpSm ? 10 : 5}>
          <Typography isLabel variant="body2">
            {t('The effective date of this Privacy Policy is documented as September 8, 2020')}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

PrivacyPolicyContent.propTypes = {
  fullWidth: PropTypes.bool,
};

PrivacyPolicyContent.defaultProps = {
  fullWidth: false,
};

export default PrivacyPolicyContent;
