import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, DialogActions, DialogContent, Grid, useTheme, useMediaQuery } from '@mui/material';

import { Button, DialogTitle, Typography } from '../../atoms';
import { classlinkLaunchpadRoute } from '../../../constants/routes';
import { LogoutButton } from '../profile-actions-buttons/ProfileActionsButtons';
import PopupActionsButtons from '../popup-actions-buttons/PopupActionsButtons';

const openClassLink = () => {
  window.open(classlinkLaunchpadRoute);
};

export const ClassLinkLogoutContent = React.forwardRef((props, ref) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <div ref={ref}>
      <DialogTitle>
        <Typography paragraph variant="subtitle1">
          {t('You’re about to log out from AYO')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box mb={isWidthUpSm ? 4 : 3}>
          <Typography paragraph variant="body2">
            {t('You will remain logged in to ClassLink')}
          </Typography>
          <Typography paragraph variant="subtitle2">
            {t('Please do not forget to logout from ClassLink to protect your data')}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Grid container direction="column">
          <Box mb={isWidthUpSm ? 2 : 1}>
            <Button
              fullWidth
              gaLabel="Open ClassLink in a new tab"
              onClick={openClassLink}
              variant="primary"
            >
              {t('Open ClassLink in a new tab')}
            </Button>
          </Box>
          <Box mb={isWidthUpSm ? 2 : 1}>
            <LogoutButton buttonText="Close anyway" fullWidth />
          </Box>
        </Grid>
      </DialogActions>
    </div>
  );
});

export const SessionTimeoutContent = React.forwardRef(({ onLoginButtonClick, timeLeft }, ref) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <div ref={ref}>
      <DialogTitle>
        <Box mb={3}>
          <Typography component="h2" paragraph variant="subtitle1">
            {t('Your session is about to expire due to inactivity')}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box mb={isWidthUpSm ? 5 : 3}>
          <Typography variant="body2">
            {t('You will be logged out of AYO in')} <b>{timeLeft}</b> {t('sec')}.
          </Typography>
          <Typography variant="body2">{t('Do you want to stay logged in')}</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <PopupActionsButtons
          primaryButtonHandler={onLoginButtonClick}
          primaryButtonText={t('Keep me logged in')}
          secondaryButtonComponent={<LogoutButton buttonText="Log me out" fullWidth />}
        />
      </DialogActions>
    </div>
  );
});

SessionTimeoutContent.propTypes = {
  onLoginButtonClick: PropTypes.func,
  timeLeft: PropTypes.number.isRequired,
};

SessionTimeoutContent.defaultProps = {
  onLoginButtonClick: () => {},
};

export const SessionExpiredContent = React.forwardRef(
  ({ isPublicClassLinkDevice, onGoToLandingButtonClick }, ref) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

    return (
      <div ref={ref}>
        <DialogTitle>
          <Box mb={3}>
            <Typography component="h2" paragraph variant="subtitle1">
              {t('Your session has expired')}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box mb={isWidthUpSm ? 5 : 3}>
            <Typography paragraph variant="body2">
              {t('Please go to the landing page and log in once more to use AYO')}
            </Typography>
            {isPublicClassLinkDevice && (
              <Typography paragraph variant="subtitle2">
                {t('Don’t forget to log out from ClassLink to protect your data')}
              </Typography>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Grid container direction="column">
            <Box mb={isWidthUpSm ? 2 : 1}>
              <Button
                fullWidth={isPublicClassLinkDevice}
                onClick={isPublicClassLinkDevice ? openClassLink : onGoToLandingButtonClick}
                variant="primary"
              >
                {t(
                  isPublicClassLinkDevice
                    ? 'Open ClassLink in a new tab'
                    : 'Go to the landing page',
                )}
              </Button>
            </Box>
            {isPublicClassLinkDevice && (
              <Box mb={isWidthUpSm ? 2 : 1}>
                <Button fullWidth onClick={onGoToLandingButtonClick}>
                  {t('Go to the landing page')}
                </Button>
              </Box>
            )}
          </Grid>
        </DialogActions>
      </div>
    );
  },
);

SessionExpiredContent.propTypes = {
  isPublicClassLinkDevice: PropTypes.bool.isRequired,
  onGoToLandingButtonClick: PropTypes.func.isRequired,
};

export default {
  SessionExpiredContent,
  SessionTimeoutContent,
};
