import React, { useContext, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { usePromiseTracker } from 'react-promise-tracker';

import { Button, Snackbar } from '../../atoms';
import { Loader } from '../../moleculas';
import BottomBar from '../bottom-bar/BottomBar';
import ClassLinkLogoutDialog from '../classlink-logout-dialog/ClassLinkLogoutDialog';
import MainTour from '../../../tours/MainTour';
import NavDrawer from '../nav-drawer/NavDrawer';
import ResetTourMenu from '../reset-tour-menu/ResetTourMenu';
import SessionTimeoutDialog from '../session-timeout-dialog/SessionTimeoutDialog';
import TopBar from '../top-bar/TopBar';
import { AppActions, AppContext, UserContext } from '../../../context';
import DemoUserSnackbar from '../demo-user-snackbar/DemoUserSnackbar';
import ConversationsChat from '../chat/ConversationsChat';

const PageWrapper = ({
  backToLink,
  backToText,
  breadcrumbsConfig,
  children,
  className,
  customFooter,
  customHeader,
  disabledLanguageSelectorText,
  mainElementClassName,
  noLogo,
  noLogOut,
  noNav,
  noNotifications,
  noi18n,
  showTourMenu,
  title,
}) => {
  const { t, i18n } = useTranslation();
  const { hash, pathname } = useLocation();

  const focusResetRef = useRef();

  const { state: userState } = useContext(UserContext);
  const { state: appState, dispatch: dispatchAppState } = useContext(AppContext);
  const profileNoNav = !userState.profile || userState.noNavigation;
  const isNavigationDisabled = useMemo(() => profileNoNav || noNav, [profileNoNav, noNav]);
  const { promiseInProgress } = usePromiseTracker();

  useEffect(() => {
    document.documentElement.lang = i18n.language?.split('-')[0];
  }, [i18n.language]);

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    window.scrollTo(0, 0);
    focusResetRef.current.focus();
  }, [pathname]);

  useEffect(() => {
    if (hash && !promiseInProgress) {
      setTimeout(() => {
        const el = document.getElementById(hash.replace('#', ''));
        if (el) {
          el.scrollIntoView();
          el.focus();
        }
      }, 0);
    }
  }, [hash, promiseInProgress]);

  const { isGuardianWithoutStudentConsent } = userState;

  const isMainTourAvailable = !isGuardianWithoutStudentConsent;

  return (
    <>
      <ClassLinkLogoutDialog />
      <SessionTimeoutDialog />
      <Loader isGlobal />
      {!isNavigationDisabled && isMainTourAvailable && <MainTour />}
      <div
        ref={focusResetRef}
        aria-label={t('Page has just been updated', { pageTitle: t(title) })}
        id="focusReset"
        role="alert"
        tabIndex="-1"
      />
      <Button
        className="ayo-skip-to-main-content"
        component="a"
        gaLabel="skip to main content (a11y)"
        href="#main-content"
        variant="primary"
      >
        {t('Skip to main content')}
      </Button>
      {!noNav && <NavDrawer />}
      <div
        className={`${classNames('ayo-page', {
          [`${className}`]: className,
        })}`}
      >
        {customHeader || (
          <TopBar
            backToLink={backToLink}
            backToText={backToText}
            breadcrumbsConfig={breadcrumbsConfig}
            disabledLanguageSelectorText={disabledLanguageSelectorText}
            noi18n={noi18n}
            noLogo={noLogo}
            noLogOut={noLogOut}
            noNav={noNav}
            noNotifications={noNotifications}
          />
        )}
        <div id="main-content" />
        <main className={mainElementClassName} style={{ position: 'relative' }}>
          <DemoUserSnackbar />
          {children}
        </main>
        {userState.profile && <ConversationsChat />}
        <div>
          {(!isNavigationDisabled || showTourMenu) && <ResetTourMenu />}
          {customFooter || <BottomBar noNav={noNav} simple />}
        </div>
      </div>
      <Snackbar
        action={appState.snackbarStatus?.action}
        ClickAwayListenerProps={{
          mouseEvent: 'onMousedown',
          touchEvent: 'onTouchEnd',
        }}
        onClose={() => dispatchAppState({ type: AppActions.SET_SNACKBAR_STATUS, data: null })}
        open={appState.snackbarStatus}
        text={appState.snackbarStatus?.text || ''}
        transitionDuration={0}
        variant={appState.snackbarStatus?.type}
      />
    </>
  );
};

PageWrapper.propTypes = {
  backToText: PropTypes.string,
  backToLink: PropTypes.string,
  breadcrumbsConfig: PropTypes.arrayOf(
    PropTypes.shape({
      gaLabel: PropTypes.string,
      label: PropTypes.string,
      redirectUrl: PropTypes.string,
    }),
  ),
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
    .isRequired,
  className: PropTypes.string,
  customFooter: PropTypes.node,
  customHeader: PropTypes.node,
  disabledLanguageSelectorText: PropTypes.string,
  mainElementClassName: PropTypes.string,
  noLogo: PropTypes.bool,
  noLogOut: PropTypes.bool,
  noNav: PropTypes.bool,
  noNotifications: PropTypes.bool,
  noi18n: PropTypes.bool,
  showTourMenu: PropTypes.bool,
  title: PropTypes.string,
};

PageWrapper.defaultProps = {
  backToText: '',
  backToLink: null,
  breadcrumbsConfig: null,
  className: '',
  customFooter: null,
  customHeader: null,
  disabledLanguageSelectorText: null,
  mainElementClassName: '',
  noLogo: false,
  noLogOut: false,
  noNav: false,
  noNotifications: false,
  noi18n: false,
  showTourMenu: false,
  title: 'AYO',
};

export default PageWrapper;
