/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import MUIButton from '@mui/material/Button';

import { GaActions, GaCategories } from '../../../constants/enums';
import { GA } from '../../../utils';

const BUTTON_TYPE_MAP = {
  primary: 'contained',
  danger: 'danger',
  secondary: 'outlined',
  text: 'text',
  dark: 'contained',
  'text-primary': 'text-primary',
};

const Button = React.forwardRef(
  (
    { children, className, gaLabel, isAdaptive, isHidden, isIconButton, onClick, variant, ...rest },
    ref,
  ) => {
    const [active, setActive] = useState(false);

    return (
      <MUIButton
        ref={ref}
        className={`${classNames(
          'ayo-button',
          `ayo-button--${BUTTON_TYPE_MAP[variant]} ${variant}`,
          {
            [`${className}`]: className,
            'Mui-active': active,
            'ayo-button--icon': isIconButton,
            'ayo-button--adaptive': isAdaptive,
            'ayo-button--hidden': isHidden,
          },
        )}`}
        color="primary"
        disableElevation
        disableRipple
        onClick={(e) => {
          if (onClick && (!e.detail || e.detail === 1)) {
            if (gaLabel) {
              GA.logInteraction({
                category: GaCategories.BEHAVIOR,
                action: GaActions.BUTTON_CLICK,
                label: gaLabel,
              });
            }
            onClick(e);
          }
        }}
        onKeyDown={(e) => {
          if (e.key === ' ') {
            setActive(true);
          }
        }}
        onKeyUp={(e) => {
          if (e.key === ' ') {
            setActive(false);
          }
        }}
        onMouseDown={() => {
          setActive(true);
        }}
        onMouseUp={() => {
          setActive(false);
        }}
        variant={BUTTON_TYPE_MAP[variant]}
        {...rest}
      >
        {children}
      </MUIButton>
    );
  },
);

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.node]).isRequired,
  className: PropTypes.string,
  gaLabel: PropTypes.string,
  isAdaptive: PropTypes.bool,
  isHidden: PropTypes.bool,
  isIconButton: PropTypes.bool,
  onClick: PropTypes.func,
  variant: PropTypes.oneOf(['primary', 'danger', 'secondary', 'text']),
};

Button.defaultProps = {
  className: '',
  gaLabel: '',
  isAdaptive: false,
  isHidden: false,
  isIconButton: false,
  onClick: () => {},
  variant: 'text',
};

export default Button;
