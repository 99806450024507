import React, { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Container, useMediaQuery, useTheme } from '@mui/material';

import { Typography } from '../../../atoms';
import { GoToStudentProfileBlock } from '../../../moleculas';
import { MoodActivitiesBlock, PageWrapper } from '../../../organisms';
import { useStudentsService } from '../../../../services';
import { getMeridiemFromInteger, getNormalizedLanguageCode } from '../../../../utils';
import { trainAyoRoute } from '../../../../constants/routes';
import { Moods } from '../../../../constants/moods';
import { UserContext } from '../../../../context';

import MoodsBlock from './components/moods-block/MoodsBlock';

const MoodsPage = () => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const { state: userState } = useContext(UserContext);
  const { getIsMoodAllowed, getSELRecommendations, getLatestMood } = useStudentsService();
  const [workingHours, setWorkingHours] = useState();
  const [selRecommendations, setSelRecommendations] = useState({});
  const [isMoodAvailable, setIsMoodAvailable] = useState();

  useEffect(() => {
    getIsMoodAllowed().then((data) => setWorkingHours(data));
    getLatestMood(userState.profile.id).then((moodInfo) => setIsMoodAvailable(moodInfo?.mood));
    getSELRecommendations(getNormalizedLanguageCode(i18n.language)).then((recommendations) => {
      const groupedRecommendations = Object.keys(Moods)?.reduce((acc, moodName) => {
        const currentMoodActivities = [];
        recommendations.forEach((recommendation) => {
          if (recommendation.moods.includes(moodName)) {
            currentMoodActivities.push(recommendation);
          }
        });
        acc[moodName] = currentMoodActivities;
        return acc;
      }, {});
      setSelRecommendations(groupedRecommendations);
    });
  }, [
    getIsMoodAllowed,
    getSELRecommendations,
    getLatestMood,
    setSelRecommendations,
    i18n.language,
    userState.profile.id,
  ]);

  return (
    <PageWrapper backToLink={trainAyoRoute} backToText={t('Go to Train AYO')}>
      <Container>
        <Box>
          <Typography component="h1" paragraph variant="h1">
            {t(workingHours?.allowed ? 'Mood check-in' : 'Your mood is important')}
          </Typography>
          <Box maxWidth={isWidthUpSm ? '50%' : '100%'} mb={5}>
            <Typography variant="body2">
              <Trans
                components={{ b: <b /> }}
                i18nKey={
                  workingHours?.allowed
                    ? 'Here, you can share with AYO and your educators how are you feeling today'
                    : 'Check-in is available from Monday to Friday fromHour to toHour, but mood regulation exercises and crisis support are always available for you below'
                }
                values={{
                  from: getMeridiemFromInteger(workingHours?.fromHour),
                  to: getMeridiemFromInteger(workingHours?.toHour),
                }}
              />
            </Typography>
          </Box>
          <Box mb={isWidthUpSm ? 10 : 5}>
            <MoodsBlock isWorkingHours={workingHours?.allowed} />
          </Box>
          <Typography component="h2" variant="h2">
            {t('Mood regulation exercises')}
          </Typography>
          <Box mt={3}>
            <MoodActivitiesBlock activitiesCards={selRecommendations} cardHeadingLevel={4} />
          </Box>
        </Box>
        <Box mt={10}>{isMoodAvailable && <GoToStudentProfileBlock />}</Box>
      </Container>
    </PageWrapper>
  );
};

export default MoodsPage;
