import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';

import { Checkbox, FormControlLabel, Typography } from '../../../atoms';
import { DisabledControlWithTooltip } from '../../../moleculas';
import { getFilterValueParts, updateSimpleFilter } from '../../../../utils';
import {
  ActivitiesFilterCategories,
  LeadershipAttributes,
  StudentFiltersTypes,
} from '../../../../constants/enums';
import { filtersListSeparator } from '../../../../constants/values';

const ActivitiesLAFilters = ({ menuFilters, setMenuFilters, stats }) => {
  const { t } = useTranslation();

  const { filtersList } = useMemo(
    () => getFilterValueParts(menuFilters, stats),
    [menuFilters, stats],
  );

  const isAllAttributesChecked = useMemo(
    () =>
      Object.keys(stats[ActivitiesFilterCategories.LEADERSHIP_ATTRIBUTES]).every((attribute) =>
        filtersList.includes(attribute),
      ),
    [stats, filtersList],
  );

  const onAllAttributesChange = useCallback(
    (e) => {
      const attributesList = e.target.checked
        ? Object.keys(stats[ActivitiesFilterCategories.LEADERSHIP_ATTRIBUTES])
        : [];
      setMenuFilters({
        filterCategory: ActivitiesFilterCategories.LEADERSHIP_ATTRIBUTES,
        filterValue: `${StudentFiltersTypes.INCLUDE}:${attributesList.join(filtersListSeparator)}`,
      });
    },
    [setMenuFilters, stats],
  );

  const onAttributeChange = useCallback(
    (e) => {
      const newFilters = updateSimpleFilter(
        e.target.value,
        ActivitiesFilterCategories.LEADERSHIP_ATTRIBUTES,
        menuFilters,
        Object.values(LeadershipAttributes),
        stats,
      );
      setMenuFilters(newFilters);
    },
    [menuFilters, setMenuFilters, stats],
  );

  return (
    <Box>
      <Box mb={2}>
        <Typography variant="subtitle2">{t('Filter by related Leadership Attribute')}</Typography>
      </Box>
      <Grid className="scrollable-filters" container>
        <Grid item sm={6} xs={12}>
          <FormControlLabel
            className="filters-checkbox"
            control={
              <Checkbox
                checked={isAllAttributesChecked}
                gaLabel="Activities Filters - LA - All Attributes"
                onChange={onAllAttributesChange}
                value="ALL"
              />
            }
            label={t('All Attributes')}
          />
        </Grid>
        {Object.values(LeadershipAttributes).map((attribute) => {
          const currentAttributeStatValue =
            stats[ActivitiesFilterCategories.LEADERSHIP_ATTRIBUTES][attribute] ?? 0;

          const isDisabled = !currentAttributeStatValue;

          return (
            <Grid key={attribute} item sm={6} xs={12}>
              <DisabledControlWithTooltip
                showTooltip={isDisabled}
                title={
                  <>
                    {`${t(attribute)} (${currentAttributeStatValue})`}
                    <br />
                    {currentAttributeStatValue === 0 && t("Can't filter by 0 suggestions")}
                  </>
                }
              >
                <FormControlLabel
                  className="filters-checkbox"
                  control={
                    <Checkbox
                      checked={filtersList?.includes(attribute) ?? false}
                      disabled={isDisabled}
                      gaLabel={`Activities Filters - LA - ${attribute}`}
                      onChange={onAttributeChange}
                      value={attribute}
                    />
                  }
                  label={`${t(attribute)} (${currentAttributeStatValue})`}
                />
              </DisabledControlWithTooltip>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

ActivitiesLAFilters.propTypes = {
  menuFilters: PropTypes.shape({ filterValue: PropTypes.string, filterCategory: PropTypes.string })
    .isRequired,
  setMenuFilters: PropTypes.func.isRequired,
  stats: PropTypes.instanceOf(Object).isRequired,
};

export default ActivitiesLAFilters;
