import React, { useCallback, useMemo, useContext } from 'react';
import { Box, Divider, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Checkbox, FormControlLabel, Radio } from '../../../atoms';
import DisabledControlWithTooltip from '../../tooltip-wrappers/disabled-control-with-tooltip/DisabledControlWithTooltip';
import {
  getFilterValueParts,
  isFilterItemDisabled,
  isSimpleFilterSelected,
  updateSimpleFilter,
} from '../../../../utils';
import {
  HighlightsInitiatives,
  SchoolLevels,
  StudentFiltersTypes,
} from '../../../../constants/enums';
import { filtersListSeparator } from '../../../../constants/values';
import { UserContext } from '../../../../context';

const DrilldownOptions = ({
  drillDownStats,
  drilldownCategory,
  menuFilters,
  stats,
  colCount,
  setMenuFilters,
  totalStudentsCount,
  gaLabel,
}) => {
  const { filtersList } = getFilterValueParts(menuFilters, stats);
  const { t } = useTranslation();

  const enabledDrilldownStats = useMemo(
    () =>
      Object.keys(drillDownStats).filter(
        (key) => !isFilterItemDisabled(drillDownStats[key].count, totalStudentsCount),
      ),
    [drillDownStats, totalStudentsCount],
  );

  const isAllChecked =
    enabledDrilldownStats.length &&
    enabledDrilldownStats.every((statKey) => filtersList.includes(statKey));

  const onAllCheckboxChange = useCallback(
    (e) => {
      const newFilters = e.target.checked
        ? {
            filterCategory: drilldownCategory,
            filterValue: `${StudentFiltersTypes.INCLUDE}:${enabledDrilldownStats.join(
              filtersListSeparator,
            )}`,
          }
        : {
            filterCategory: drilldownCategory,
            filterValue: `${StudentFiltersTypes.INCLUDE}:`,
          };
      setMenuFilters(newFilters);
    },
    [drilldownCategory, enabledDrilldownStats, setMenuFilters],
  );

  const onStatCheckboxChange = useCallback(
    (statKey) => () => {
      let newFilters = updateSimpleFilter(
        statKey,
        drilldownCategory,
        menuFilters,
        Object.keys(drillDownStats),
        stats,
      );
      if (!newFilters.filterCategory) {
        newFilters = {
          filterCategory: drilldownCategory,
          filterValue: `${StudentFiltersTypes.INCLUDE}`,
        };
      }
      setMenuFilters(newFilters);
    },
    [drillDownStats, drilldownCategory, menuFilters, setMenuFilters, stats],
  );
  return (
    <Box overflow="auto">
      <Grid className="drilldown-options__grid" container>
        <Grid item sm={12 / colCount} xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isAllChecked}
                disabled={!enabledDrilldownStats.length}
                gaLabel={`Endorsements filters - ${gaLabel} - All`}
                onChange={onAllCheckboxChange}
              />
            }
            label={t('All')}
          />
        </Grid>
        {Object.entries(drillDownStats).map(([statKey, statValue]) => (
          <Grid key={statKey} item sm={12 / colCount} xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isSimpleFilterSelected(
                    statKey,
                    drilldownCategory,
                    menuFilters.filterCategory,
                    filtersList,
                  )}
                  disabled={isFilterItemDisabled(statValue.count, totalStudentsCount)}
                  gaLabel={`Endorsements filters - ${gaLabel} - ${statKey}`}
                  onChange={onStatCheckboxChange(statKey)}
                />
              }
              label={`${statValue.name} (${statValue.count})`}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

DrilldownOptions.propTypes = {
  drillDownStats: PropTypes.instanceOf(Object).isRequired,
  drilldownCategory: PropTypes.string.isRequired,
  menuFilters: PropTypes.instanceOf(Object).isRequired,
  stats: PropTypes.instanceOf(Object).isRequired,
  colCount: PropTypes.number,
  setMenuFilters: PropTypes.func.isRequired,
  totalStudentsCount: PropTypes.number,
  gaLabel: PropTypes.string.isRequired,
};

DrilldownOptions.defaultProps = {
  colCount: 1,
  totalStudentsCount: 0,
};

const EndorsementsFilters = ({ menuFilters, setMenuFilters, stats, totalStudentsCount }) => {
  const endorsementStats = stats.endorsements;

  const { selectedStudents, switchedByReasonStudents, switchedByTypeStudents, interestedStudents } =
    endorsementStats;

  const { filterCriterion } = getFilterValueParts(menuFilters, stats);

  const getIsDrilldownBaseChecked = (drilldownCategory) =>
    menuFilters.filterCategory === drilldownCategory &&
    filterCriterion === StudentFiltersTypes.INCLUDE;

  const onDrilldownOptionCheck = (drilldownCategory) => () =>
    setMenuFilters({
      filterCategory: drilldownCategory,
      filterValue: `${StudentFiltersTypes.INCLUDE}:`,
    });

  const isInterestedChecked = getIsDrilldownBaseChecked(
    HighlightsInitiatives.ENDORSEMENTS_INTERESTED,
  );

  const isSelectedChecked = getIsDrilldownBaseChecked(HighlightsInitiatives.ENDORSEMENTS);

  const isTypesChecked = getIsDrilldownBaseChecked(HighlightsInitiatives.ENDORSEMENTS_TYPES);

  const isReasonsCheked = getIsDrilldownBaseChecked(HighlightsInitiatives.ENDORSEMENTS_REASONS);

  const hasDrilldown =
    isSelectedChecked || isTypesChecked || isReasonsCheked || isInterestedChecked;

  const isPendingChecked =
    menuFilters.filterCategory === HighlightsInitiatives.ENDORSEMENTS &&
    filterCriterion === StudentFiltersTypes.PENDING;

  const onPendingCheck = () =>
    setMenuFilters({
      filterCategory: HighlightsInitiatives.ENDORSEMENTS,
      filterValue: `${StudentFiltersTypes.PENDING}:`,
    });

  const { t } = useTranslation();

  const isPendingDisabled = isFilterItemDisabled(endorsementStats.pending, totalStudentsCount);

  const { state: userState } = useContext(UserContext);

  const isElementarySchoolEducator =
    userState.profile?.currentCampusSchoolLevel === SchoolLevels.ELEMENTARY;

  return (
    <Box className="endorsements-filters">
      <Box className="main-options__grid" display="flex" flexDirection="column">
        <FormControlLabel
          control={
            <Radio
              checked={isInterestedChecked}
              gaLabel="Endorsements filters - Interested"
              onChange={onDrilldownOptionCheck(HighlightsInitiatives.ENDORSEMENTS_INTERESTED)}
            />
          }
          label={`${t('analytics.highlights.endorsements.INTERESTED')} (${interestedStudents})`}
        />
        {!isElementarySchoolEducator && (
          <>
            <FormControlLabel
              control={
                <Radio
                  checked={isSelectedChecked}
                  gaLabel="Endorsements filters - Selected"
                  onChange={onDrilldownOptionCheck(HighlightsInitiatives.ENDORSEMENTS)}
                />
              }
              label={`${t('analytics.highlights.endorsements.SELECTED')} (${selectedStudents})`}
            />
            <FormControlLabel
              control={
                <Radio
                  checked={isTypesChecked}
                  gaLabel="Endorsements filters - Switched by type"
                  onChange={onDrilldownOptionCheck(HighlightsInitiatives.ENDORSEMENTS_TYPES)}
                />
              }
              label={`${t('analytics.highlights.endorsements.TYPE')} (${switchedByTypeStudents})`}
            />
            <FormControlLabel
              control={
                <Radio
                  checked={isReasonsCheked}
                  gaLabel="Endorsements filters - Switched by reason"
                  onChange={onDrilldownOptionCheck(HighlightsInitiatives.ENDORSEMENTS_REASONS)}
                />
              }
              label={`${t(
                'analytics.highlights.endorsements.REASON',
              )} (${switchedByReasonStudents})`}
            />
            <DisabledControlWithTooltip
              showTooltip={isPendingDisabled}
              title={
                <>
                  {`${t(`analytics.highlights.${StudentFiltersTypes.PENDING}`)} (${
                    endorsementStats.pending
                  })`}
                  <br />
                  {!endorsementStats.pending && t("Can't filter by 0 students")}
                  {endorsementStats.pending === totalStudentsCount &&
                    t("Can't filter by all students")}
                </>
              }
            >
              <FormControlLabel
                control={
                  <Radio
                    checked={isPendingChecked}
                    disabled={isPendingDisabled}
                    gaLabel="Endorsements filters - Pending"
                    onChange={onPendingCheck}
                  />
                }
                label={`${t('Pending')} (${endorsementStats.pending})`}
              />
            </DisabledControlWithTooltip>
          </>
        )}
      </Box>
      {hasDrilldown && <Divider />}
      {isInterestedChecked && (
        <DrilldownOptions
          colCount={2}
          drilldownCategory={HighlightsInitiatives.ENDORSEMENTS_INTERESTED}
          drillDownStats={endorsementStats.interested}
          gaLabel="Interested"
          menuFilters={menuFilters}
          setMenuFilters={setMenuFilters}
          stats={stats}
          totalStudentsCount={totalStudentsCount}
        />
      )}
      {isSelectedChecked && (
        <DrilldownOptions
          colCount={2}
          drilldownCategory={HighlightsInitiatives.ENDORSEMENTS}
          drillDownStats={endorsementStats.selected}
          gaLabel="Selected"
          menuFilters={menuFilters}
          setMenuFilters={setMenuFilters}
          stats={stats}
          totalStudentsCount={totalStudentsCount}
        />
      )}
      {isTypesChecked && (
        <DrilldownOptions
          colCount={2}
          drilldownCategory={HighlightsInitiatives.ENDORSEMENTS_TYPES}
          drillDownStats={endorsementStats.switchedByType}
          gaLabel="Switched by type"
          menuFilters={menuFilters}
          setMenuFilters={setMenuFilters}
          stats={stats}
          totalStudentsCount={totalStudentsCount}
        />
      )}
      {isReasonsCheked && (
        <DrilldownOptions
          colCount={1}
          drilldownCategory={HighlightsInitiatives.ENDORSEMENTS_REASONS}
          drillDownStats={endorsementStats.switchedByReason}
          gaLabel="Switched by reason"
          menuFilters={menuFilters}
          setMenuFilters={setMenuFilters}
          stats={stats}
          totalStudentsCount={totalStudentsCount}
        />
      )}
    </Box>
  );
};

EndorsementsFilters.propTypes = {
  menuFilters: PropTypes.instanceOf(Object).isRequired,
  setMenuFilters: PropTypes.func.isRequired,
  stats: PropTypes.instanceOf(Object).isRequired,
  totalStudentsCount: PropTypes.number.isRequired,
};

export default EndorsementsFilters;
