/* eslint-disable react/destructuring-assignment */
import React from 'react';

import { regexpEscapeCharactersRE } from '../../constants/regexps';

const Highlighter = (label, keyWords) => {
  if (!label || !keyWords?.length) {
    return label;
  }
  const delimiterPattern = keyWords
    .filter(Boolean)
    .map((keyWord) => `(${keyWord.replace(regexpEscapeCharactersRE, '\\$&')})`)
    .join('|');
  const delimiterRE = new RegExp(delimiterPattern, 'gi');
  const parts = label.split(delimiterRE).filter(Boolean);

  return (
    <>{parts.map((part) => (part.match(delimiterRE) ? <b>{part}</b> : <span>{part}</span>))}</>
  );
};

export default Highlighter;
