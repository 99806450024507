import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Container, Grid, useMediaQuery, useTheme } from '@mui/material';

import { Button, Card, Link, Typography } from '../../atoms';
import { DisplayAvatarEditorImage } from '../../moleculas';
import { AvatarEditDialog, PageWrapper } from '../../organisms';
import { AttachmentsResourcesTypes } from '../../../constants/enums';
import { useTeachersService } from '../../../services';
import { ReactComponent as CreateIcon } from '../../../resources/icons/create.svg';
import { ReactComponent as EnvelopeIcon } from '../../../resources/icons/envelope_dark.svg';
import { ReactComponent as PlusIcon } from '../../../resources/icons/plus.svg';

const MyProfileTeacherPage = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  const [isAvatarEdit, setIsAvatarEdit] = useState(false);
  const [teacherInfo, setTeacherInfo] = useState({});
  const { id: userId, name, email, avatar, position } = teacherInfo;

  const onAvatarEditClose = useCallback(() => setIsAvatarEdit(false), []);
  const { getTeacherData, deleteTeacherAvatar, postTeacherAvatar } = useTeachersService();

  useEffect(() => {
    getTeacherData().then((data) => {
      setTeacherInfo(data);
    });
  }, [getTeacherData]);

  const onAvatarSubmit = (attachment, positionAndZoom) => {
    setTeacherInfo({ ...teacherInfo, avatar: { image: attachment, transforms: positionAndZoom } });
  };

  return (
    <PageWrapper mainElementClassName="ayo-my-profile-teacher-page">
      <Container>
        <Typography component="h1" mb={3} variant="h1">
          {t('My profile')}
        </Typography>
        <Grid container md={7} pb={5} sm={8} xs={12}>
          <Card
            cardHeader={
              <Typography component="h2" variant="h2">
                {t('About')}
              </Typography>
            }
            className="ayo-my-profile-teacher-page__card"
            mainContent={
              <Box display="flex" flexDirection={isWidthUpSm ? 'row' : 'column'} gap={5}>
                <Box display="flex" flexDirection="column">
                  <Box alignItems="center" display="flex" flexDirection="column">
                    <DisplayAvatarEditorImage
                      avatar={avatar?.image}
                      avatarSize={150}
                      avatarTransforms={avatar?.transforms}
                    />
                    <Box mt={2}>
                      <Button
                        endIcon={avatar?.image ? <CreateIcon /> : <PlusIcon />}
                        fullWidth
                        gaLabel={avatar?.image ? 'Edit photo' : 'Add photo'}
                        onClick={() => setIsAvatarEdit(true)}
                      >
                        {avatar?.image ? t('Edit photo') : t('Add photo')}
                      </Button>
                    </Box>
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column" gap={5}>
                  <Box>
                    <Typography component="h3" variant="subtitle2">
                      {t('Personal information')}
                    </Typography>
                    <Typography py={1} variant="body2">
                      {name}
                    </Typography>
                    <Typography variant="body2">{t(`${position}`)}</Typography>
                  </Box>
                  <Box>
                    <Typography component="h3" pb={1} variant="subtitle2">
                      {t('Contacts')}
                    </Typography>
                    <Box display="flex" gap={1}>
                      <EnvelopeIcon />
                      <Typography variant="body2">
                        <Link href={`mailto:${email}`}>{email}</Link>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            }
          />
        </Grid>
        <AvatarEditDialog
          avatarType="Teacher"
          currentImage={avatar?.image}
          currentTransforms={avatar?.transforms}
          deleteAvatarHandler={deleteTeacherAvatar}
          isOpen={isAvatarEdit}
          onClose={onAvatarEditClose}
          onSubmit={onAvatarSubmit}
          ownerId={userId}
          resourceId={userId}
          resourceType={AttachmentsResourcesTypes.USER_AVATAR}
          showGuidelines={false}
          updateAvatarHandler={(imageData) => postTeacherAvatar(imageData)}
          visibilityInfoCaptionTitle="Your photo is visible to students and their parents in AYO."
        />
      </Container>
    </PageWrapper>
  );
};
export default MyProfileTeacherPage;
