/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme, useMediaQuery, Box } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import { Typography } from '../../atoms';
import { LinkWithSnackbar, RadarChart } from '../../moleculas';
import { LeadershipAttributesContext } from '../../../context';
import { leadershipAttributesGraphConfig } from '../../../constants/configs';

const LeadershipAttributesGraph = ({ withSnackbar, readOnly, outterGraphData }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { state: leadershipAttributesState } = useContext(LeadershipAttributesContext);
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const { graphData } = leadershipAttributesState;

  const graphEntities = useMemo(
    () => (outterGraphData.length ? outterGraphData : graphData),
    [graphData, outterGraphData],
  );

  const [radarChartConfig, setRadarChartConfig] = useState(leadershipAttributesGraphConfig);

  useEffect(() => {
    const mergedConfig = { ...leadershipAttributesGraphConfig };
    mergedConfig.labels = [];
    mergedConfig.datasets.forEach((dataset) => {
      const initDataset = dataset;
      initDataset.data = [];
    });
    graphEntities.forEach((attribute) => {
      mergedConfig.labels = [
        ...mergedConfig.labels,
        t(isWidthUpSm ? attribute.key : `${attribute.key}(Mobile)`),
      ];
      mergedConfig.datasets.forEach((dataset) => {
        const level = attribute.maturityLevels[dataset.key];
        dataset.data.push(Math.round((level.completedGroups / level.totalGroups) * 10));
      });
    });
    setRadarChartConfig(mergedConfig);
  }, [graphEntities, isWidthUpSm, t]);
  const howToReadGraphText = 'How to read LA graph';
  const snackbarBody = (
    <Typography variant="caption">
      <Trans components={{ b: <b />, br: <br /> }} i18nKey={howToReadGraphText} />
    </Typography>
  );

  return (
    <Box className="ayo-leadership-attributes-graph">
      <RadarChart {...radarChartConfig} readOnly={readOnly} />
      {!withSnackbar ? (
        <Box>
          {!readOnly && (
            <LinkWithSnackbar linkAnchor="How to read this graph?" snackbarBody={snackbarBody} />
          )}
        </Box>
      ) : (
        <Box pt={isWidthUpSm ? 6 : 4}>
          <Typography component="h3" paragraph variant="subtitle2">
            {t('How to read this graph?')}
          </Typography>
          <Typography variant="body2">
            <Trans components={{ b: <b />, br: <br /> }} i18nKey={howToReadGraphText} />
          </Typography>
        </Box>
      )}
    </Box>
  );
};

LeadershipAttributesGraph.propTypes = {
  readOnly: PropTypes.bool,
  withSnackbar: PropTypes.bool,
  outterGraphData: PropTypes.arrayOf(Object),
};

LeadershipAttributesGraph.defaultProps = {
  readOnly: false,
  withSnackbar: false,
  outterGraphData: [],
};
export default LeadershipAttributesGraph;
