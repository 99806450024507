import React from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import PropTypes from 'prop-types';

import { Checkbox, DynamicGrid, FormControlLabel, Typography } from '../../../atoms';
import { TextWithTooltip } from '../../../moleculas';

const SelectableEntities = ({
  columnsCount,
  entities,
  EntityComponent,
  firstComponent,
  selected,
  onChange,
}) => {
  const theme = useTheme();
  const isWidthUpMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <DynamicGrid
      columnsCount={isWidthUpMd ? columnsCount : 1}
      gridItems={[
        !!firstComponent && (
          <Box key="all selector" className="ayo-selectable-entities">
            {firstComponent}
          </Box>
        ),
        ...entities.map(({ id, name, label }) => (
          <Box key={`${name} - ${id}`} className="ayo-selectable-entities">
            {EntityComponent ? (
              <EntityComponent
                id={id}
                isSelected={selected.some((x) =>
                  x.id === null ? x.name === name : x.id === id && x.name === name,
                )}
                name={name}
                onChange={(e) => onChange(e, { id, name })}
              />
            ) : (
              <FormControlLabel
                className="share-with-item"
                control={
                  <Checkbox
                    checked={selected.some((x) =>
                      x.id === null ? x.name === name : x.id === id && x.name === name,
                    )}
                    gaLabel="Share data with entity"
                    name={name}
                    onChange={(e) => onChange(e, { id, name })}
                    value={id}
                  />
                }
                label={
                  <Box>
                    <TextWithTooltip title={name} titleVariant="subtitle2" />
                    {!!label && (
                      <Typography isLabel mt={0.5} variant="body3">
                        {label}
                      </Typography>
                    )}
                  </Box>
                }
              />
            )}
          </Box>
        )),
      ].filter(Boolean)}
      isHorizontalOrder
      spacingX={3}
      spacingY={1}
    />
  );
};

SelectableEntities.propTypes = {
  columnsCount: PropTypes.number,
  entities: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      label: PropTypes.string,
    }),
  ).isRequired,
  EntityComponent: PropTypes.elementType,
  firstComponent: PropTypes.node,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    }),
  ).isRequired,
};

SelectableEntities.defaultProps = {
  columnsCount: 2,
  EntityComponent: null,
  firstComponent: null,
};

export default SelectableEntities;
