import { useState, useCallback } from 'react';

const useAccordionToggleState = () => {
  const [openedItem, setOpenedSubInterest] = useState(null);

  const toggleOpenedItem = useCallback(
    (newOpenedItem) => {
      setOpenedSubInterest(openedItem === newOpenedItem ? null : newOpenedItem);
    },
    [openedItem],
  );

  return [openedItem, toggleOpenedItem];
};

export default useAccordionToggleState;
