import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Box,
  useTheme,
  useMediaQuery,
  Tab,
  Popover,
  List,
  ListItem,
} from '@mui/material';

import { DynamicGrid, Tabs, Typography } from '../../atoms';
import { CampusLabel, NewFeatureHotspot, TabPanelWrapper } from '../../moleculas';
import {
  HighlightsBlock,
  MobileNotSupported,
  NoStudentsScreen,
  PageWrapper,
} from '../../organisms';
import { UserContext } from '../../../context';
import { useAdministratorsService, useAnalyticsService } from '../../../services';
import { checkEnvironmentVariable, GA, isEmpty, useProfile } from '../../../utils';
import {
  AnalyticsStatsKeys,
  AnalyticsStatsCategories,
  GaActions,
  GaCategories,
  HighlightsInitiatives,
  PrivilegesMap,
  KeyboardMap,
  AnalyticTypesMap,
} from '../../../constants/enums';
import { campusSelectionRoute } from '../../../constants/routes';
import { checkinFiltersValuesMap } from '../../../constants/moods';
import { NewFeaturesIdsMap } from '../../../tours/common/NewFeaturesItemsProvider';
import { ReactComponent as InfoIcon } from '../../../resources/icons/info.svg';
import { ReactComponent as CloseDarkIcon } from '../../../resources/icons/close_dark.svg';

import { HeatMapStatCard, IllustratedStatCard, MultipleStatCard } from './components';

const IS_MOBILE_APP_RELEASED = checkEnvironmentVariable(
  'REACT_APP_IS_MOBILE_APP_AVAILABLE',
  'true',
);

const preProcessStats = (stats) => {
  if (stats) {
    const filteredInitiatives = Object.fromEntries(
      Object.entries(stats.initiatives).filter(
        ([statName]) => statName !== AnalyticsStatsKeys.MOODS || IS_MOBILE_APP_RELEASED,
      ),
    );
    return { ...stats, initiatives: filteredInitiatives };
  }
  return null;
};

const AnalyticsTabsConfig = {
  DISTRICT: {
    value: 'DISTRICT',
    label: 'District',
  },
  CAMPUSES: {
    value: 'CAMPUSES',
    label: 'Campuses',
  },
};

const emptyMoodData = {
  [checkinFiltersValuesMap.TODAY]: {
    [AnalyticsStatsKeys.MOODS]: {},
    [AnalyticsStatsKeys.SEL_RECOMMENDATIONS]: {},
  },
  [checkinFiltersValuesMap.LAST_WEEK]: {
    [AnalyticsStatsKeys.MOODS]: {},
    [AnalyticsStatsKeys.SEL_RECOMMENDATIONS]: {},
  },
  [checkinFiltersValuesMap.LAST_MONTH]: {
    [AnalyticsStatsKeys.MOODS]: {},
    [AnalyticsStatsKeys.SEL_RECOMMENDATIONS]: {},
  },
  [checkinFiltersValuesMap.LAST_YEAR]: {
    [AnalyticsStatsKeys.MOODS]: {},
    [AnalyticsStatsKeys.SEL_RECOMMENDATIONS]: {},
  },
  [checkinFiltersValuesMap.ALL]: {
    [AnalyticsStatsKeys.MOODS]: {},
    [AnalyticsStatsKeys.SEL_RECOMMENDATIONS]: {},
  },
};

const defaultTimePeriods = {
  [AnalyticsStatsKeys.MOODS]: checkinFiltersValuesMap.TODAY,
  [AnalyticsStatsKeys.SEL_RECOMMENDATIONS]: checkinFiltersValuesMap.TODAY,
};

// TODO: extract to reusable component and reuse it on Family Feed
const InformationalPopover = () => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    if (event.key === KeyboardMap.ENTER || !event.key) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isOpen = Boolean(anchorEl);

  return (
    <>
      <InfoIcon onClick={handleClick} onKeyDown={handleClick} tabIndex={0} />
      <Popover
        anchorEl={anchorEl}
        className="ayo-analytics__district-popover"
        onClose={handleClose}
        open={isOpen}
      >
        <Box
          className="ayo-analytics__district-popover__card"
          columnGap={3}
          display="flex"
          justifyContent="space-between"
        >
          <Box>
            <Typography variant="body2">
              {t('Here, you can see read-only information about:')}
            </Typography>
            <List disablePadding>
              <ListItem disablePadding>
                <Typography variant="body2">
                  {t('The distribution of students per mood for a specific period of time.')}
                </Typography>
              </ListItem>
              <ListItem disablePadding>
                <Typography variant="body2">
                  {t(
                    'The distribution of students per SEL strategies engagement for a specific period of time.',
                  )}
                </Typography>
              </ListItem>
            </List>
          </Box>
          <CloseDarkIcon onClick={handleClose} />
        </Box>
      </Popover>
    </>
  );
};

const AnalyticsPage = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const isWidthUpMd = useMediaQuery(theme.breakpoints.up('md'));

  const { getDistrictReport } = useAnalyticsService();
  const { getAnalytics } = useAdministratorsService();
  const { getIsCampusSelectionAvailable, getPrivilegeStatus } = useProfile();

  const { state: userState } = useContext(UserContext);
  const hasDistrictLevelAccess = getPrivilegeStatus(PrivilegesMap.DISTRICT_ACCESS);

  const currentCampus = userState.profile?.currentCampus;
  const currentCampusSchoolLevel = userState.profile?.currentCampusSchoolLevel;

  const [stats, setStats] = useState();
  const [activeTab, setActiveTab] = useState(AnalyticsTabsConfig.DISTRICT.value);

  const [moodData, setMoodData] = useState(emptyMoodData);
  const [moodDataTimePeriods, setMoodDataTimePeriods] = useState(defaultTimePeriods);

  // TODO: find a better place for this???
  const timePeriodOptions = useMemo(
    () => [
      { value: checkinFiltersValuesMap.TODAY, label: t('Today') },
      { value: checkinFiltersValuesMap.LAST_WEEK, label: t('Last week') },
      { value: checkinFiltersValuesMap.LAST_MONTH, label: t('Last month') },
      { value: checkinFiltersValuesMap.LAST_YEAR, label: t('Last year') },
      { value: checkinFiltersValuesMap.ALL, label: t('All') },
    ],
    [t],
  );

  const handleTabChange = (e, value) => {
    GA.logInteraction({
      category: GaCategories.BEHAVIOR,
      action: GaActions.TAB_CLICK,
      label: value,
    });
    setActiveTab(value);
  };

  const handleFetchMoodData = useCallback(
    (analyticType, timePeriod, statKey) => {
      getDistrictReport(analyticType, timePeriod).then((data) => {
        setMoodData((prevState) => ({
          ...prevState,
          [timePeriod]: {
            ...prevState[timePeriod],
            [statKey]: data[statKey],
          },
        }));
      });
    },
    [getDistrictReport],
  );

  const handleTimePeriodChange = useCallback(
    (analyticType, timePeriod, statKey) => {
      setMoodDataTimePeriods((prevState) => ({
        ...prevState,
        [statKey]: timePeriod,
      }));

      if (isEmpty(moodData?.[timePeriod]?.[statKey])) {
        handleFetchMoodData(analyticType, timePeriod, statKey);
      }
    },
    [handleFetchMoodData, moodData],
  );

  useEffect(() => {
    document.title = t('Analytics');

    if (getPrivilegeStatus(PrivilegesMap.DISTRICT_ACCESS)) {
      Promise.all([
        handleFetchMoodData(
          AnalyticTypesMap.MOOD,
          checkinFiltersValuesMap.TODAY,
          AnalyticsStatsKeys.MOODS,
        ),
        handleFetchMoodData(
          AnalyticTypesMap.SEL_RECOMMENDATION,
          checkinFiltersValuesMap.TODAY,
          AnalyticsStatsKeys.SEL_RECOMMENDATIONS,
        ),
      ]);
    }

    getAnalytics(currentCampus).then((data) => {
      setStats(preProcessStats(data.stats));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // TODO: refactor to make more readable
  return (
    <PageWrapper
      backToLink={getIsCampusSelectionAvailable() ? campusSelectionRoute : null}
      backToText={getIsCampusSelectionAvailable() ? t('Go to campus selection') : ''}
      mainElementClassName="ayo-analytics"
    >
      <Container>
        <Box mb={10}>
          <Typography component="h1" mb={2} variant="h1">
            {t('Analytics')}
          </Typography>
          {!hasDistrictLevelAccess && <CampusLabel />}
          {hasDistrictLevelAccess && (
            <Tabs
              className="ayo-analytics__tabs"
              onChange={handleTabChange}
              value={activeTab}
              variant="standard"
            >
              <Tab
                disableRipple
                label={t(AnalyticsTabsConfig.DISTRICT.label)}
                value={AnalyticsTabsConfig.DISTRICT.value}
              />
              <Tab
                disableRipple
                label={t(AnalyticsTabsConfig.CAMPUSES.label)}
                value={AnalyticsTabsConfig.CAMPUSES.value}
              />
            </Tabs>
          )}
          {hasDistrictLevelAccess && (
            <TabPanelWrapper currentValue={activeTab} value={AnalyticsTabsConfig.DISTRICT.value}>
              {isWidthUpSm ? (
                <Box display="flex" flexDirection="column" rowGap={3}>
                  <Box alignItems="center" columnGap={1} display="flex">
                    <Typography variant="h2">{t('District overview')}</Typography>
                    <NewFeatureHotspot
                      id={NewFeaturesIdsMap.SEL_DISTRICT_REPORT}
                      isClickable
                      label={t('nft.sel_district.title')}
                    />
                    <InformationalPopover />
                  </Box>
                  <Typography variant="subtitle1">{t('Mood check-ins')}</Typography>
                  <HighlightsBlock
                    onTimePeriodChange={(timePeriod) =>
                      handleTimePeriodChange(
                        AnalyticTypesMap.MOOD,
                        timePeriod,
                        AnalyticsStatsKeys.MOODS,
                      )
                    }
                    selectedInitiative={HighlightsInitiatives.MOODS_READ_ONLY}
                    stats={moodData[moodDataTimePeriods[AnalyticsStatsKeys.MOODS]]}
                    timePeriod={moodDataTimePeriods[AnalyticsStatsKeys.MOODS]}
                    timePeriodOptions={timePeriodOptions}
                    title={t('Total number of students who shared their mood for')}
                  />
                  <HighlightsBlock
                    onTimePeriodChange={(timePeriod) =>
                      handleTimePeriodChange(
                        AnalyticTypesMap.SEL_RECOMMENDATION,
                        timePeriod,
                        AnalyticsStatsKeys.SEL_RECOMMENDATIONS,
                      )
                    }
                    selectedInitiative={HighlightsInitiatives.SEL_RECOMMENDATIONS}
                    stats={moodData[moodDataTimePeriods[AnalyticsStatsKeys.SEL_RECOMMENDATIONS]]}
                    timePeriod={moodDataTimePeriods[AnalyticsStatsKeys.SEL_RECOMMENDATIONS]}
                    timePeriodOptions={timePeriodOptions}
                    title={t('Distribution of students per SEL strategies engagement for')}
                  />
                </Box>
              ) : (
                <MobileNotSupported
                  explanation={t(
                    'Please open the District section with a tablet or desktop version to use this functionality',
                  )}
                />
              )}
            </TabPanelWrapper>
          )}
          <TabPanelWrapper
            currentValue={activeTab}
            isWrap={hasDistrictLevelAccess}
            value={AnalyticsTabsConfig.CAMPUSES.value}
          >
            {stats ? (
              Object.entries(stats).map(([statsCategory, statsValues], i) => {
                const isInitiatives = statsCategory === AnalyticsStatsCategories.INITIATIVES;
                const isStudentCategory = statsCategory === AnalyticsStatsCategories.STUDENTS;
                const isFirstBlock = i === 0;

                return (
                  <Box
                    key={statsCategory}
                    className="ayo-analytics__stats"
                    mb={isStudentCategory ? 5 : 10}
                  >
                    <Box mb={isInitiatives ? 2 : 3}>
                      <Box display="flex">
                        <Typography
                          component={isInitiatives ? 'h3' : 'h2'}
                          variant={isInitiatives ? 'subtitle1' : 'h2'}
                        >
                          {t(`analytics.${statsCategory}`)}
                        </Typography>
                        {isInitiatives && (
                          <NewFeatureHotspot
                            id={NewFeaturesIdsMap.ANALYTICS_INITIATIVES}
                            isClickable
                          />
                        )}
                      </Box>
                      {hasDistrictLevelAccess && isFirstBlock && <CampusLabel />}
                    </Box>
                    {isStudentCategory && (
                      <Typography component="h3" paragraph variant="subtitle1">
                        {t(`System overview`)}
                      </Typography>
                    )}
                    <DynamicGrid
                      columnsCount={isWidthUpMd ? 3 : isWidthUpSm ? 2 : 1}
                      gridItems={Object.entries(statsValues).map(([statKey, statValue]) =>
                        isInitiatives && statKey === AnalyticsStatsKeys.ENDORSEMENTS ? (
                          <HeatMapStatCard
                            key={statKey}
                            statKey={statKey}
                            statsCategory={statsCategory}
                            statValue={statValue}
                          />
                        ) : isInitiatives ? (
                          <MultipleStatCard
                            key={statKey}
                            className={`ayo-analytics__stats--${statKey}--${currentCampusSchoolLevel}`}
                            newFeatureId={
                              statKey === AnalyticsStatsKeys.MOODS &&
                              NewFeaturesIdsMap.MOOD_CHECK_INS
                            }
                            newFeatureLabel="Mood check-ins"
                            statKey={statKey}
                            statsCategory={statsCategory}
                            statValue={statValue}
                          />
                        ) : (
                          <IllustratedStatCard
                            key={statKey}
                            statKey={statKey}
                            statsCategory={statsCategory}
                            statValue={statValue}
                          />
                        ),
                      )}
                    />
                  </Box>
                );
              })
            ) : (
              <NoStudentsScreen variant="analyticsPage" />
            )}
          </TabPanelWrapper>
        </Box>
      </Container>
    </PageWrapper>
  );
};

export default AnalyticsPage;
