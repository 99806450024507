import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Container, Grid, useMediaQuery, useTheme } from '@mui/material';

import { Typography } from '../../../atoms';
import { Chip, ClickableCard, DisabledControlWithTooltip } from '../../../moleculas';
import { PageWrapper } from '../../../organisms';
import {
  QuestionnaireStateActions,
  QuestionnaireStateContext,
  UserActions,
  UserContext,
} from '../../../../context';
import {
  getQuestionnairesByType,
  getQuestionnaireProgress,
  hasSubmittedQuestionnaires,
  isSubmittedWithSkippedQuestions,
  useProfile,
} from '../../../../utils';
import { useStudentsService } from '../../../../services';
import {
  trainAyoStartRoute,
  trainAyoRoute,
  trainAyoOpportunitiesRoute,
  trainAyoQuestionnaireRoute,
  trainAyoInterestsAppRoute,
  trainAyoExtracurricularsAppRoute,
  trainAyoLeadershipAttributesStudentSelectionRoute,
  leadershipRoute,
  goalsRoute,
  trainAyoGoalsStudentSelectionRoute,
  trainAyoEndorsementsRoute,
  campusSelectionRoute,
  moodsRoute,
  trainAyoSelectFollowupRoute,
  trainAyoSelectInitialRoute,
} from '../../../../constants/routes';
import {
  OneTimeActionsMap,
  QuestionnaireNamesByTypesMap,
  RolesMap,
} from '../../../../constants/enums';
import { ReactComponent as StudentTellUsAboutImage } from '../../../../resources/images/student_initial_profiling_card.svg';
import { ReactComponent as GuardianInitialQuestionnaireCardImage } from '../../../../resources/images/guardian_initial_questionnaire_card.svg';
import { ReactComponent as GuardianFollowUpQuestionnaireCardImage } from '../../../../resources/images/guardian_follow_up_questionnaire_card.svg';
import { ReactComponent as StudentRevealInterestsImage } from '../../../../resources/images/student_progressive_profiling_card.svg';
import { ReactComponent as ExploreInterestImage } from '../../../../resources/images/explore_interests_profiling_card.svg';
import { ReactComponent as AdjustExtracurricularsImage } from '../../../../resources/images/extracurriculars_profiling_card.svg';
import { ReactComponent as ExploreOpportunitiesImage } from '../../../../resources/images/explore_opportunities_profiling_card.svg';
import { ReactComponent as LeadershipAttributesImage } from '../../../../resources/images/leadership_attributes_profiling_card.svg';
import { ReactComponent as MoodCheckInImage } from '../../../../resources/images/mood_check_in_profiling_card.svg';
import { ReactComponent as ExploreGoalsImage } from '../../../../resources/images/explore_goals_profiling_card.svg';
import { ReactComponent as ExploreEndorsementsImage } from '../../../../resources/images/endorsemens_profiling_card.svg';
import { ReactComponent as SuccessIcon } from '../../../../resources/icons/check_circle.svg';
import { ReactComponent as OutlineSuccessIcon } from '../../../../resources/icons/check_circle_outline_green.svg';
import { NewFeaturesIdsMap } from '../../../../tours/common/NewFeaturesItemsProvider';

const COMPLETED_VALUE = 100;

const PageTitlesMap = {
  [RolesMap.STUDENT]:
    'This is the place where you can help AYO get to know yourself better and control your data',
  [RolesMap.TEACHER]:
    'This is the place where you can help AYO get to know students better and control their data',
  [RolesMap.GUARDIAN]:
    'This is the place where you can share with AYO some valuable information about your kid',
  [RolesMap.ADMINISTRATOR]:
    'This is the place where you can help AYO get to know students better and control their data',
};

const QuestionnaireStatuses = {
  COMPLETED: 'Completed num%',
  HAS_SKIPPED: 'You skipped some questions',
  IN_PROGRESS: 'In progress',
  NEW: 'New',
};

const TrainAyoPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const { state: userState, dispatch: dispatchUserState } = useContext(UserContext);
  const { state: questionnaireState, dispatch: dispatchQuestionnaireState } =
    useContext(QuestionnaireStateContext);
  const { getProfileState, getOneTimeActionStatus } = useProfile();
  const { getStudentEndorsements, getLatestMood } = useStudentsService();
  const profileData = userState.profile;
  const isStudent = profileData.role === RolesMap.STUDENT;
  const [isMoodAvailable, setIsMoodAvailable] = useState();

  const getAvailableQuestionnaireProgress = useCallback(
    (questionnaireType) => {
      const pendingQuestionnairesData = questionnaireType
        ? getQuestionnairesByType(profileData.pendingQuestionnaires, questionnaireType)
        : profileData.pendingQuestionnaires;

      const questionnaireProgress = pendingQuestionnairesData.length
        ? getQuestionnaireProgress(pendingQuestionnairesData)
        : COMPLETED_VALUE;

      const getQuestionnaireStatus = () => {
        if (questionnaireProgress === COMPLETED_VALUE) {
          return QuestionnaireStatuses.COMPLETED;
        }
        if (
          isSubmittedWithSkippedQuestions(pendingQuestionnairesData) ||
          userState.dependentProfiles?.some(({ pendingQuestionnaires }) =>
            isSubmittedWithSkippedQuestions(
              getQuestionnairesByType(pendingQuestionnaires, questionnaireType),
            ),
          )
        ) {
          return QuestionnaireStatuses.HAS_SKIPPED;
        }
        if (questionnaireProgress > 0) {
          return `${QuestionnaireStatuses.IN_PROGRESS} num%`;
        }
        if (
          questionnaireProgress === 0 &&
          profileData.role !== RolesMap.GUARDIAN &&
          getOneTimeActionStatus(
            questionnaireType === QuestionnaireNamesByTypesMap.PROGRESSIVE
              ? OneTimeActionsMap.PROGRESSIVE_PROFILING_OPEN
              : OneTimeActionsMap.TRAIN_AYO_START,
          )
        ) {
          return QuestionnaireStatuses.IN_PROGRESS;
        }
        return QuestionnaireStatuses.NEW;
      };

      return {
        value: questionnaireProgress,
        text: getQuestionnaireStatus(),
      };
    },
    [
      getOneTimeActionStatus,
      profileData.pendingQuestionnaires,
      profileData.role,
      userState.dependentProfiles,
    ],
  );

  const goToFirstAvailableQuestionnaire = useCallback(
    (url, questionnaireType = QuestionnaireNamesByTypesMap.INITIAL) => {
      dispatchQuestionnaireState({
        type: QuestionnaireStateActions.SET_QUESTIONNAIRE_ID,
        data: {
          questionnaireId: getQuestionnairesByType(
            profileData.pendingQuestionnaires,
            questionnaireType,
          )[0]?.id,
        },
      });
      history.push(url);
    },
    [dispatchQuestionnaireState, history, profileData?.pendingQuestionnaires],
  );

  const trainAyoCardStatus = useCallback(
    (oneTimeAction) => (getOneTimeActionStatus(oneTimeAction) ? 'Ongoing' : 'New'),
    [getOneTimeActionStatus],
  );

  const shouldRenderGuardianFollowupQuestionnaireCard = useMemo(
    () => userState.dependentProfiles?.some((profile) => hasSubmittedQuestionnaires(profile)),
    [userState.dependentProfiles],
  );

  const cardsConfig = useMemo(
    () => ({
      [RolesMap.STUDENT]: [
        {
          image: StudentTellUsAboutImage,
          title: 'Tell us about yourself',
          progress: getAvailableQuestionnaireProgress(QuestionnaireNamesByTypesMap.INITIAL),
          handler: () => goToFirstAvailableQuestionnaire(trainAyoQuestionnaireRoute),
        },
        {
          image: StudentRevealInterestsImage,
          title: 'Reveal interests',
          progress: getAvailableQuestionnaireProgress(QuestionnaireNamesByTypesMap.PROGRESSIVE),
          handler: () =>
            goToFirstAvailableQuestionnaire(
              trainAyoQuestionnaireRoute,
              QuestionnaireNamesByTypesMap.PROGRESSIVE,
            ),
        },
        {
          image: ExploreInterestImage,
          title: 'Explore interests',
          progress: {
            text: trainAyoCardStatus(OneTimeActionsMap.EXPLORE_INTERESTS_OPEN),
            value: 0,
          },
          handler: () => history.push(trainAyoInterestsAppRoute),
        },
        userState.isEndorsementsAvailable && {
          image: ExploreEndorsementsImage,
          newFeatureId: NewFeaturesIdsMap.ENDORSEMENTS,
          title: 'Track endorsements',
          progress: {
            text: trainAyoCardStatus(OneTimeActionsMap.EXPLORE_ENDORSEMENTS_OPEN),
            value: 0,
          },
          handler: () => history.push(trainAyoEndorsementsRoute),
        },
        {
          image: ExploreOpportunitiesImage,
          title: 'Explore opportunities',
          progress: {
            text: trainAyoCardStatus(OneTimeActionsMap.EXPLORE_OPPORTUNITY_OPEN),
            value: 0,
          },
          handler: () => history.push(trainAyoOpportunitiesRoute),
        },
        {
          image: AdjustExtracurricularsImage,
          title: 'Share extracurriculars',
          progress: {
            text: trainAyoCardStatus(OneTimeActionsMap.ADJUST_EXTRACURRICULARS_OPEN),
            value: 0,
          },
          handler: () => history.push(trainAyoExtracurricularsAppRoute),
        },
        {
          image: ExploreGoalsImage,
          title: 'Manage goals',
          progress: {
            text: trainAyoCardStatus(OneTimeActionsMap.EXPLORE_GOALS_OPEN),
            value: 0,
          },
          handler: () => history.push(`${trainAyoRoute}/${profileData.id}${goalsRoute}`),
        },
        {
          image: LeadershipAttributesImage,
          title: 'Leadership Attributes',
          progress: {
            text: trainAyoCardStatus(OneTimeActionsMap.TRAIN_AYO_LA_OPEN),
            value: 0,
          },
          handler: () => history.push(`${trainAyoRoute}/${profileData.id}${leadershipRoute}`),
        },
        {
          image: MoodCheckInImage,
          title: 'Mood check-in',
          newFeatureId: NewFeaturesIdsMap.SEL,
          progress: {
            text: isMoodAvailable ? 'Ongoing' : 'New',
            value: 0,
          },
          handler: () => history.push(`${trainAyoRoute}${moodsRoute}`),
        },
      ],
      [RolesMap.TEACHER]: [
        {
          image: LeadershipAttributesImage,
          title: 'Leadership Attributes',
          progress: {
            text: trainAyoCardStatus(OneTimeActionsMap.TRAIN_AYO_LA_OPEN),
            value: 0,
          },
          handler: () => history.push(trainAyoLeadershipAttributesStudentSelectionRoute),
        },
        {
          image: ExploreGoalsImage,
          title: 'Track goals',
          progress: {
            text: trainAyoCardStatus(OneTimeActionsMap.EXPLORE_GOALS_OPEN),
            value: 0,
          },
          handler: () => history.push(trainAyoGoalsStudentSelectionRoute),
        },
      ],
      [RolesMap.GUARDIAN]: [
        {
          image: LeadershipAttributesImage,
          newFeatureId: NewFeaturesIdsMap.ASSESSMENT_TOOL,
          newFeatureLabel: 'Leadership Attributes',
          title: 'Leadership Attributes',
          titleWrap: true,
          progress: {
            text: trainAyoCardStatus(OneTimeActionsMap.TRAIN_AYO_LA_OPEN),
            value: 0,
          },
          handler: () => history.push(trainAyoLeadershipAttributesStudentSelectionRoute),
        },
        {
          image: GuardianInitialQuestionnaireCardImage,
          title: 'Tell us about your kid(s)',
          titleWrap: true,
          chipLabel: 'Questionnaire',
          progress: getAvailableQuestionnaireProgress(QuestionnaireNamesByTypesMap.INITIAL),
          handler: () => history.push(trainAyoSelectInitialRoute),
          alwaysEnabled: true,
        },
        shouldRenderGuardianFollowupQuestionnaireCard && {
          image: GuardianFollowUpQuestionnaireCardImage,
          title: 'Tell us more about your kid(s)',
          titleWrap: true,
          chipLabel: 'Questionnaire',
          progress: getAvailableQuestionnaireProgress(QuestionnaireNamesByTypesMap.FOLLOWUP),
          handler: () => history.push(trainAyoSelectFollowupRoute),
          alwaysEnabled: true,
        },
      ],
      [RolesMap.ADMINISTRATOR]: [
        {
          image: LeadershipAttributesImage,
          title: 'Leadership Attributes',
          progress: {
            text: trainAyoCardStatus(OneTimeActionsMap.TRAIN_AYO_LA_OPEN),
            value: 0,
          },
          handler: () => history.push(trainAyoLeadershipAttributesStudentSelectionRoute),
        },
        {
          image: ExploreGoalsImage,
          title: 'Track goals',
          progress: {
            text: trainAyoCardStatus(OneTimeActionsMap.EXPLORE_GOALS_OPEN),
            value: 0,
          },
          handler: () => history.push(trainAyoGoalsStudentSelectionRoute),
        },
      ],
    }),
    [
      getAvailableQuestionnaireProgress,
      shouldRenderGuardianFollowupQuestionnaireCard,
      goToFirstAvailableQuestionnaire,
      history,
      isMoodAvailable,
      profileData.id,
      trainAyoCardStatus,
      userState.isEndorsementsAvailable,
    ],
  );

  const [trainAyoCards, setTrainAyoCards] = useState([]);
  useEffect(() => {
    const cards = cardsConfig[profileData.role].filter(Boolean);
    if (cards) {
      setTrainAyoCards(
        cards.sort((a, b) => {
          if (a.progress.value === COMPLETED_VALUE) {
            return 1;
          }
          if (b.progress.value === COMPLETED_VALUE) {
            return -1;
          }
          return 0;
        }),
      );
    }
  }, [cardsConfig, profileData.role]);

  useEffect(() => {
    if (isStudent) {
      if (userState.isEndorsementsAvailable === null) {
        getStudentEndorsements(profileData.id).then((endorsementsData) =>
          dispatchUserState({
            type: UserActions.SET_IS_ENDORSEMENTS_AVAILABLE,
            data: endorsementsData.endorsements.length > 1,
          }),
        );
      }
      getLatestMood(profileData.id).then((moodInfo) => setIsMoodAvailable(moodInfo?.mood));
    }
  }, [
    dispatchUserState,
    getLatestMood,
    getStudentEndorsements,
    isStudent,
    profileData,
    userState?.isEndorsementsAvailable,
  ]);

  useEffect(() => {
    if (questionnaireState.activeQuestionIds) {
      dispatchQuestionnaireState({
        type: QuestionnaireStateActions.SET_ACTIVE_QUESTION_IDS,
        data: null,
      });
    }
    if (questionnaireState.shouldReloadQuestionnaireState) {
      getProfileState().then(() => {
        dispatchQuestionnaireState({
          type: QuestionnaireStateActions.SET_SHOULD_RELOAD_QUESTIONNAIRE_STATE,
          data: false,
        });
      });
    } else if (
      !profileData?.completedQuestionnaires?.length &&
      !isSubmittedWithSkippedQuestions(profileData?.pendingQuestionnaires) &&
      isStudent
    ) {
      goToFirstAvailableQuestionnaire(trainAyoStartRoute);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionnaireState.shouldReloadQuestionnaireState]);

  const { getIsCampusSelectionAvailable } = useProfile();

  return (
    <PageWrapper
      backToLink={getIsCampusSelectionAvailable() ? campusSelectionRoute : null}
      backToText={getIsCampusSelectionAvailable() ? t('Go to campus selection') : ''}
      mainElementClassName="ayo-train-ayo-page"
    >
      <Container>
        <Box pb={1}>
          <Typography component="h1" paragraph variant="h1">
            {t('Train AYO')}
          </Typography>
        </Box>
        <Box pb={3}>
          <Grid container item md={6} xs={12}>
            <Typography paragraph variant="body2">
              {t(PageTitlesMap[profileData?.role])}
            </Typography>
          </Grid>
        </Box>
        <Box pb={isWidthUpSm ? 10 : 5}>
          <Grid container direction="row" item justifyContent="flex-start" spacing={3} xs={12}>
            {trainAyoCards.map((item, i) => (
              <Grid key={i} item md={3} sm={6} xs={12}>
                <DisabledControlWithTooltip
                  showTooltip={item.progress.value === COMPLETED_VALUE && !item.alwaysEnabled}
                  title={t('You have completed the questionnaire')}
                >
                  <ClickableCard
                    cardHeader={
                      item.chipLabel ? <Chip isCaption label={t(item.chipLabel)} /> : null
                    }
                    gaLabel={item.title}
                    isDisabled={item.progress.value === COMPLETED_VALUE && !item.alwaysEnabled}
                    mediaContent={item.image}
                    newFeatureId={item.newFeatureId}
                    newFeatureLabel={item.newFeatureLabel}
                    onClick={item.handler}
                    progressValue={item.progress.value}
                    statusIcon={
                      (item.progress.value === COMPLETED_VALUE && <SuccessIcon />) ||
                      (item.progress.text === QuestionnaireStatuses.HAS_SKIPPED && (
                        <OutlineSuccessIcon />
                      ))
                    }
                    statusText={t(item.progress.text, { num: item.progress.value?.toFixed(0) })}
                    title={t(item.title)}
                    titleWrap={!!item.titleWrap}
                  />
                </DisabledControlWithTooltip>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </PageWrapper>
  );
};

export default TrainAyoPage;
