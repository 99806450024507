import { useContext, useEffect } from 'react';

import { SharedDataActions, SharedDataContext } from '../../context';
import { useStudentsService } from '../../services';

let isLoading = false;

const useSharedStudentData = (studentId) => {
  const { state: sharedState, dispatch: dispatchSharedState } = useContext(SharedDataContext);
  const { activeStudent } = sharedState;

  const { getStudentBaseInfo } = useStudentsService();

  useEffect(() => {
    if (!studentId || isLoading) return;
    if (!sharedState.activeStudent || +studentId !== sharedState.activeStudent?.id) {
      isLoading = true;
      getStudentBaseInfo(studentId).then((baseInfo) => {
        isLoading = false;
        dispatchSharedState({
          type: SharedDataActions.SET_ACTIVE_STUDENT,
          data: baseInfo,
        });
      });
    }
  }, [dispatchSharedState, getStudentBaseInfo, sharedState.activeStudent, studentId]);

  return { activeStudent };
};

export default useSharedStudentData;
