import React, { useContext, useEffect, useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Button, Typography } from '../../../atoms';
import { Carousel, MoodCard, MoodGetHelpCard, NewFeatureHotspot } from '../../../moleculas';
import { useStudentsService } from '../../../../services';
import { UserContext } from '../../../../context';
import { SupportTypesMap, MoodNames, Moods } from '../../../../constants/moods';
import { moodsRoute, trainAyoRoute } from '../../../../constants/routes';
import { ReactComponent as VectorIcon } from '../../../../resources/icons/vector.svg';
import { NewFeaturesIdsMap } from '../../../../tours/common/NewFeaturesItemsProvider';
import { modsSliderConfig } from '../../../../constants/carouselSliderConfigs';

const MoodSectionStudent = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { state: userState } = useContext(UserContext);
  const userId = userState.profile?.id;
  const { getIsMoodAllowed, getLatestMood } = useStudentsService();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  const [workingHours, setWorkingHours] = useState();
  const [chosenMood, setChosenMood] = useState(null);

  useEffect(() => {
    getIsMoodAllowed().then((moodConfig) => {
      setWorkingHours(moodConfig);

      if (moodConfig?.allowed) {
        getLatestMood(userId).then((data) => {
          if (data?.mood) {
            setChosenMood(data.mood);
          }
        });
      }
    });
  }, [getIsMoodAllowed, getLatestMood, userId]);

  return (
    <Box className="ayo-mood-section-student">
      <Box
        alignItems={isWidthUpSm ? 'center' : 'flex-start'}
        className="ayo-mood-section-student__header"
        display="flex"
        flexDirection={isWidthUpSm ? 'row' : 'column'}
        justifyContent="space-between"
        mb={1}
      >
        <Box mb={1}>
          <Box alignItems="center" display="flex" mb={1}>
            <Typography variant="h2">{t('Mood check-in')}</Typography>
            <NewFeatureHotspot id={NewFeaturesIdsMap.SEL} isClickable label={t('Mood check-in')} />
          </Box>
          {workingHours?.allowed ? (
            <Typography variant="body2">
              {chosenMood
                ? `${t('Your recent choice')} ${t(MoodNames[chosenMood])}`
                : t('How are you feeling?')}
            </Typography>
          ) : null}
        </Box>
        <Box mb={3}>
          <Button
            component={RouterLink}
            endIcon={<VectorIcon />}
            gaLabel="Check-in in Train AYO"
            to={`${trainAyoRoute}${moodsRoute}`}
          >
            {t(workingHours?.allowed ? 'Check-in in Train AYO' : 'See more in Train AYO')}
          </Button>
        </Box>
      </Box>
      <Box mt={1}>
        {workingHours?.allowed ? (
          chosenMood ? (
            <Grid container spacing={isWidthUpSm ? 3 : 1}>
              <Grid item md={2} xs={4}>
                <MoodCard mood={chosenMood} />
              </Grid>
            </Grid>
          ) : (
            <Carousel
              items={Object.values({ ...Moods, ...SupportTypesMap }).map((mood) => (
                <MoodCard
                  key={mood}
                  gaLabel={MoodNames[mood]}
                  isSupport={mood === SupportTypesMap.GET_SUPPORT}
                  mood={mood}
                  onClick={() => {
                    history.push(`${trainAyoRoute}${moodsRoute}`);
                  }}
                />
              ))}
              sliderResponsiveConfig={modsSliderConfig}
              variant="slider"
            />
          )
        ) : (
          <Box>
            <MoodGetHelpCard />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default MoodSectionStudent;
