/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useMemo } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Box, Divider } from '@mui/material';
import PropTypes from 'prop-types';

import { Link, Lottie, Tooltip, Typography } from '../../atoms';
import { FlippableCard, MlChip, TagsBlock, TextWithTooltip } from '../../moleculas';
import { UserContext } from '../../../context';
import { GA } from '../../../utils';
import { GaActions, GaCategories, RolesMap } from '../../../constants/enums';
import { ReactComponent as LinkIcon } from '../../../resources/icons/link.svg';
import { ReactComponent as MysteryActivityImg } from '../../../resources/images/mystery_activity.svg';
import { ReactComponent as MysteryCourseImg } from '../../../resources/images/mystery_course.svg';
import { ReactComponent as MysteryExtracurricularImg } from '../../../resources/images/mystery_extracurricular.svg';
import { ReactComponent as MysteryLibraryBookImg } from '../../../resources/images/mystery_library_book.svg';
import { ReactComponent as MysteryVideoImg } from '../../../resources/images/mystery_video.svg';
import { ReactComponent as MysteryStudiesImg } from '../../../resources/images/studies.svg';
import { ReactComponent as MysteryVolunteeringImg } from '../../../resources/images/mystery_volunteering.svg';
import * as animationDataActivities from '../../../resources/animations/activities.json';
import * as animationDataCertifications from '../../../resources/animations/certifications.json';
import * as animationDataCourses from '../../../resources/animations/courses.json';
import * as animationDataExtracurriculars from '../../../resources/animations/extracurriculars.json';
import * as animationDataLibraryBooks from '../../../resources/animations/library_books.json';
import * as animationDataLibraryVideos from '../../../resources/animations/library_videos.json';
import * as animationDataStudies from '../../../resources/animations/studies.json';
import * as animationDataVolunteering from '../../../resources/animations/volunteering.json';

const TargetsConfig = {
  Activity: {
    animation: animationDataActivities.default,
    ariaLabel:
      'The animation for activities displaying a dog getting a bone, wool turning into a sweater, and paintbrushes turning into a painting',
    className: 'activity',
    mysteryImage: MysteryActivityImg,
  },
  Certification: {
    animation: animationDataCertifications.default,
    ariaLabel:
      'The animation for certifications displaying a folder getting an award and cool pixel sun glasses',
    className: 'certification',
    mysteryImage: MysteryCourseImg,
  },
  Course: {
    animation: animationDataCourses.default,
    ariaLabel:
      'The animation for courses displaying a folder getting a graduation cap, and professor’s glasses',
    className: 'course',
    mysteryImage: MysteryCourseImg,
  },
  Extracurricular: {
    animation: animationDataExtracurriculars.default,
    ariaLabel:
      'The animation for extracurriculars displaying theater masks getting facial expressions, a football bouncing, and a guitar playing',
    className: 'extracurricular',
    mysteryImage: MysteryExtracurricularImg,
  },
  'Library Book': {
    animation: animationDataLibraryBooks.default,
    ariaLabel:
      'The animation for a library book displaying a half-painted fence with a brush, and two boys sailing away on a raft',
    className: 'librarybook',
    mysteryImage: MysteryLibraryBookImg,
  },
  'Library Video': {
    animation: animationDataLibraryVideos.default,
    ariaLabel:
      'The animation for library videos displaying a video player and a house silhouette with a surprised boy in front of it',
    className: 'libraryvideo',
    mysteryImage: MysteryVideoImg,
  },
  Studies: {
    animation: animationDataStudies.default,
    ariaLabel:
      'The animation for studies displaying a graduation cap being thrown in the air, a piano keyboard, an ancient amphora, and a human DNA',
    className: 'studies',
    mysteryImage: MysteryStudiesImg,
  },
  Volunteering: {
    animation: animationDataVolunteering.default,
    ariaLabel:
      'The animation for volunteering displaying hearts arising out of the box full of clothes, food, and toys',
    className: 'volunteering',
    mysteryImage: MysteryVolunteeringImg,
  },
};

const defaultAnimationsConfig = {
  autoplay: false,
  loop: false,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const OpportunityCard = ({ headerLevel, isActiveCard, opportunity, showMysteryCard, ...rest }) => {
  const { t } = useTranslation();
  const { state: userState } = useContext(UserContext);
  const isStudent = userState.profile.role === RolesMap.STUDENT;
  const primaryReason = opportunity?.reasonPrimary;
  const secondaryReason = opportunity?.reasonSecondary;
  const primaryReasonType = opportunity?.typePrimary;
  const secondaryReasonType = opportunity?.typeSecondary;

  const MysteryImage = TargetsConfig[opportunity.category]?.mysteryImage;

  const tooltipTitle = useMemo(
    () =>
      `${t('Suggestion based on')} ${t(`suggestion.${primaryReasonType}`, {
        reason: primaryReason,
      })}${
        secondaryReasonType
          ? ` ${t('and')} ${t(`suggestion.${secondaryReasonType}`, { reason: secondaryReason })}`
          : ''
      }.`,
    [primaryReason, primaryReasonType, secondaryReason, secondaryReasonType, t],
  );

  return !showMysteryCard || (showMysteryCard && opportunity.viewed) ? (
    <FlippableCard
      className={classnames('ayo-opportunity-card', TargetsConfig[opportunity.category]?.className)}
      contentBack={{
        main: (isFocusable) => (
          <>
            <Box className="ayo-opportunity-card__scrollable-container">
              <Typography align="left" isLabel variant="caption">
                {opportunity.description}
              </Typography>
              {opportunity.tags && (
                <Box mt={3}>
                  <TagsBlock
                    label="Related video tags"
                    tagsList={opportunity.tags.split(',').slice(0, 10)}
                  />
                </Box>
              )}
            </Box>
            {opportunity.url && (
              <Box ml={1} mt={3}>
                <Link
                  gaLabel={`Learn more at ${opportunity.source}`}
                  href={opportunity.url}
                  icon={<LinkIcon />}
                  tabIndex={isFocusable ? 0 : -1}
                  target="_blank"
                >
                  <TextWithTooltip className="ayo-link" title={opportunity.source} />
                </Link>
              </Box>
            )}
          </>
        ),
      }}
      contentFront={{
        main: () => (
          <Box alignItems="flex-start" display="flex" flexDirection="column">
            <TextWithTooltip
              headerLevel={headerLevel}
              rowsCount={2}
              title={opportunity.name}
              titleVariant="subtitle2"
              typographyProps={{ align: 'left' }}
            />
            <Box alignItems="center" display="flex">
              {opportunity.author && (
                <Typography isLabel variant="body3">
                  {opportunity.author}
                </Typography>
              )}
              {opportunity.author && opportunity.genre && (
                <Divider flexItem orientation="vertical" />
              )}
              {opportunity.genre && (
                <Typography isLabel variant="body3">
                  {t(`videoGenres.${opportunity.genre.toLowerCase()}`)}
                </Typography>
              )}
            </Box>
          </Box>
        ),
        media: (isFocusable) => (
          <>
            {isStudent && primaryReasonType && (
              <Tooltip
                enterTouchDelay={0}
                leaveTouchDelay={5000}
                onOpen={(e) => {
                  e.stopPropagation();
                  GA.logInteraction({
                    category: GaCategories.BEHAVIOR,
                    action: GaActions.ICON_HOVER,
                    label: 'Opportunity reason',
                  });
                }}
                title={tooltipTitle}
              >
                <Box
                  className="ayo-opportunity-card__info-bubble"
                  tabIndex={isActiveCard && isFocusable ? 0 : -1}
                >
                  <MlChip ariaLabel={`AYO: ${tooltipTitle}`} />
                </Box>
              </Tooltip>
            )}
            <Lottie
              ariaLabel={t(TargetsConfig[opportunity.category]?.ariaLabel)}
              options={{
                ...defaultAnimationsConfig,
                animationData: TargetsConfig[opportunity.category]?.animation,
              }}
              tabIndex={isActiveCard && isFocusable ? 0 : -1}
            />
          </>
        ),
      }}
      disableFlip={!opportunity.description || !isActiveCard}
      {...rest}
    />
  ) : (
    <MysteryImage height={280} width={240} {...rest} />
  );
};

OpportunityCard.propTypes = {
  headerLevel: PropTypes.number,
  isActiveCard: PropTypes.bool,
  opportunity: PropTypes.shape({
    author: PropTypes.string,
    category: PropTypes.string,
    description: PropTypes.string,
    genre: PropTypes.string,
    interested: PropTypes.bool,
    name: PropTypes.string,
    reasonPrimary: PropTypes.string,
    reasonSecondary: PropTypes.string,
    source: PropTypes.string,
    tags: PropTypes.string,
    typePrimary: PropTypes.string,
    typeSecondary: PropTypes.string,
    viewed: PropTypes.bool,
    url: PropTypes.string,
  }),
  showMysteryCard: PropTypes.bool,
};

OpportunityCard.defaultProps = {
  headerLevel: 3,
  isActiveCard: false,
  opportunity: {},
  showMysteryCard: false,
};

export default OpportunityCard;
