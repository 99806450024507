import React from 'react';
import { Box, Container, Grid, useTheme } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery';
import PropTypes from 'prop-types';

import { Typography } from '../../../../../../atoms';
import { ReactComponent as LeadershipAttributesWhoEnjoyItStudent } from '../../../../../../../resources/images/leadership-who-enjoy-it-student.svg';
import { ReactComponent as LeadershipAttributesWhoEnjoyItEducator } from '../../../../../../../resources/images/leadership-who-enjoy-it-educator.svg';
import { ReactComponent as LeadershipAttributesWhoEnjoyItParent } from '../../../../../../../resources/images/leadership-who-enjoy-it-parent.svg';

const LEADERSHIP_ATTRIBUTES_WHO_ENJOY_IT = [
  {
    id: 1,
    content: 'For students, they will gain confidence through growth in leadership competencies',
    image: {
      component: (ariaLabel) => (
        <LeadershipAttributesWhoEnjoyItStudent
          aria-label={ariaLabel}
          className="ayo-leadership-attributes-who-enjoy-it__illustration"
        />
      ),
      ariaLabel: 'A portrait of a student sitting in a gallery',
    },
  },
  {
    id: 2,
    content:
      'For educators, this pathway will help them better discover leadership potential in their students',
    image: {
      component: (ariaLabel) => (
        <LeadershipAttributesWhoEnjoyItEducator
          aria-label={ariaLabel}
          className="ayo-leadership-attributes-who-enjoy-it__illustration"
        />
      ),
      ariaLabel: 'A portrait of an educator standing in a gallery',
    },
  },
  {
    id: 3,
    content:
      'For parents, they will enjoy the transparency that Leadership Attributes provides in assessing, monitoring and sharing with them their student’s leadership skills and progress',
    image: {
      component: (ariaLabel) => (
        <LeadershipAttributesWhoEnjoyItParent
          aria-label={ariaLabel}
          className="ayo-leadership-attributes-who-enjoy-it__illustration"
        />
      ),
      ariaLabel: 'A portrait of a parent standing in a gallery',
    },
  },
];

const LeadershipAttributesWhoEnjoyItSectionContentBlock = ({ content, image }) => {
  const theme = useTheme();
  const isWidthUpMd = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <Grid alignItems="center" container spacing={isWidthUpMd ? 3 : 2}>
      <Grid container item justifyContent={isWidthUpMd ? 'flex-end' : 'flex-start'} sm={6} xs={12}>
        {image}
      </Grid>
      <Grid item sm={6} xs={12}>
        <Typography variant="body2">
          <Trans components={{ b: <b /> }} i18nKey={content} />
        </Typography>
      </Grid>
    </Grid>
  );
};

LeadershipAttributesWhoEnjoyItSectionContentBlock.propTypes = {
  content: PropTypes.string.isRequired,
  image: PropTypes.shape({
    component: PropTypes.node.isRequired,
    ariaLabel: PropTypes.string.isRequired,
  }).isRequired,
};

const LeadershipAttributesWhoEnjoyItSection = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box className="ayo-leadership-attributes-who-enjoy-it">
      <Container>
        <Box py={isWidthUpMd ? 10 : 5}>
          <Grid container justifyContent="center">
            <Grid
              alignItems="center"
              className="ayo-leadership-attributes-who-enjoy-it__headline"
              container
              direction="column"
              justifyContent="center"
            >
              <Typography paragraph variant="h2">
                {t('Who will enjoy the Leadership Attributes pathway?')}
              </Typography>
              <Typography variant="body2">
                {t(
                  'As an important byproduct of the Leadership Attributes pathway, the quest is intended to make the learning process more enjoyable for students as well as their teachers and parents who support them',
                )}
              </Typography>
            </Grid>
            <Grid className="ayo-leadership-attributes-who-enjoy-it__content">
              {LEADERSHIP_ATTRIBUTES_WHO_ENJOY_IT.map((el) => (
                <LeadershipAttributesWhoEnjoyItSectionContentBlock
                  key={el.id}
                  content={el.content}
                  image={el.image.component(t(el.image.ariaLabel))}
                />
              ))}
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default LeadershipAttributesWhoEnjoyItSection;
