import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Box, DialogContent, DialogActions, useMediaQuery, useTheme } from '@mui/material';

import { Dialog, DialogTitle, Typography } from '../../../atoms';
import { PopupActionsButtons, STTTextField } from '../../../moleculas';
import { InputsValidationRules } from '../../../../constants/enums';
import { ReactComponent as ReflectionImage } from '../../../../resources/images/goal_notes_dialog_image.svg';

export const ActionStepReflectionAddDialog = ({ isOpen, onClose, onSubmit }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const isWidthUpXl = useMediaQuery(theme.breakpoints.up('xl'));
  const [reflectionValue, setReflectionValue] = useState('');

  const onReflectionValueChange = useCallback((e) => {
    setReflectionValue(e.target.value);
  }, []);

  const onSubmitHandler = useCallback(() => {
    if (reflectionValue.length <= InputsValidationRules.MAX_TEXT_AREA_LENGTH) {
      onSubmit(reflectionValue);
      setReflectionValue('');
    }
  }, [onSubmit, reflectionValue]);

  const onCloseHandler = useCallback(() => {
    onClose(null);
    setReflectionValue('');
  }, [onClose]);

  return (
    <Dialog
      className="ayo-action-step-reflection-add-dialog"
      fullScreen={!isWidthUpSm}
      gaLabel="Action step reflection add dialog"
      maxWidth={isWidthUpXl && 700}
      onClose={onCloseHandler}
      open={isOpen}
    >
      <DialogTitle disableTypography>
        <Box mb={2}>
          <Typography align="center" component="h2" variant="h2">
            {t('Great job indeed')}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box alignItems="center" display="flex" flexDirection="column" mb={3}>
          <ReflectionImage />
          <Box mt={2}>
            <Typography align="center" component="h3" variant="subtitle2">
              {t('One small step for man, one giant leap for mankind! How do you feel about it')}
            </Typography>
          </Box>
          <Box mt={2} width="100%">
            <STTTextField
              fullWidth
              gaLabel="Reflection"
              InputLabelProps={{ id: 'reflection' }}
              inputProps={{ 'aria-labelledby': 'reflection' }}
              label={t('Reflection')}
              maxLength={InputsValidationRules.MAX_TEXT_AREA_LENGTH}
              multiline
              name={t('Reflection')}
              onChange={onReflectionValueChange}
              outlined
              rowsCount={3}
              value={reflectionValue}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <PopupActionsButtons
          primaryButtonGaLabel="Submit"
          primaryButtonHandler={onSubmitHandler}
          primaryButtonText={t('Submit')}
          secondaryButtonGaLabel="Cancel"
          secondaryButtonHandler={onCloseHandler}
          secondaryButtonText={t('Cancel')}
        />
      </DialogActions>
    </Dialog>
  );
};

ActionStepReflectionAddDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default ActionStepReflectionAddDialog;
