import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Tab } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';

import { TabPanel, Tabs } from '../../../../atoms';
import { GA } from '../../../../../utils';
import { GaActions, GaCategories } from '../../../../../constants/enums';
import { clubHubRoute, managementRoute } from '../../../../../constants/routes';
import ClubHubManagement from '../../club-hub-management/ClubHubManagement';
import ClubHubMainContent from '../../club-hub-main-content/ClubHubMainContent';

const ClubHubTabsConfig = {
  CLUB_HUB_MANAGEMENT: {
    value: 'CLUB_HUB_MANAGEMENT',
    label: 'Clubs moderation',
    route: `${clubHubRoute}${managementRoute}`,
  },
  CLUB_HUB_MAIN_PAGE: {
    value: 'CLUB_HUB_MAIN_PAGE',
    label: 'Clubs I manage',
    route: clubHubRoute,
  },
};
const ClubHubTabs = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const history = useHistory();

  const [activeTab, setActiveTab] = useState(
    pathname.includes(managementRoute)
      ? ClubHubTabsConfig.CLUB_HUB_MANAGEMENT.value
      : ClubHubTabsConfig.CLUB_HUB_MAIN_PAGE.value,
  );

  const handleTabClick = (e, value) => {
    GA.logInteraction({
      category: GaCategories.BEHAVIOR,
      action: GaActions.TAB_CLICK,
      label: value,
    });
    history.push(ClubHubTabsConfig[value].route);
    setActiveTab(value);
  };

  return (
    <Box className="ayo-club-hub__tabs">
      <Tabs onChange={handleTabClick} value={activeTab} variant="outlined">
        <Tab
          disableRipple
          label={t(ClubHubTabsConfig.CLUB_HUB_MAIN_PAGE.label)}
          value={ClubHubTabsConfig.CLUB_HUB_MAIN_PAGE.value}
        />
        <Tab
          disableRipple
          label={t(ClubHubTabsConfig.CLUB_HUB_MANAGEMENT.label)}
          value={ClubHubTabsConfig.CLUB_HUB_MANAGEMENT.value}
        />
      </Tabs>
      <Box mt={3}>
        <TabPanel currentTabValue={activeTab} value={ClubHubTabsConfig.CLUB_HUB_MAIN_PAGE.value}>
          <ClubHubMainContent />
        </TabPanel>
        <TabPanel currentTabValue={activeTab} value={ClubHubTabsConfig.CLUB_HUB_MANAGEMENT.value}>
          <ClubHubManagement />
        </TabPanel>
      </Box>
    </Box>
  );
};

export default ClubHubTabs;
