import { useCallback } from 'react';

import useAxios, { StatusCodeMap } from '../HttpClient';
import { apiRoute, evidencesRoute, studentsRoute } from '../../constants/routes';
import { AttachmentsResourcesTypes } from '../../constants/enums';

const evidenceResourcesItem = (parentResourceId, type, resourceId) => ({
  parentResourceId,
  resourceId: resourceId || null,
  type,
});

const evidenceResources = (attributeKey, goalParentResourceId, goalResourceId) => {
  const evidenceResourcesArray = [];
  if (attributeKey) {
    evidenceResourcesArray.push(
      evidenceResourcesItem(attributeKey, AttachmentsResourcesTypes.LEADERSHIP_ATTRIBUTE),
    );
  }
  if (goalParentResourceId) {
    evidenceResourcesArray.push(
      evidenceResourcesItem(goalParentResourceId, AttachmentsResourcesTypes.GOAL, goalResourceId),
    );
  }
  return evidenceResourcesArray;
};

const appendEvidenceResources = (formData, attributeKey, goalParentResourceId, goalResourceId) => {
  evidenceResources(attributeKey, goalParentResourceId, goalResourceId).forEach(
    (evidenceResource, index) => {
      formData.append(
        `evidenceResources[${index}].parentResourceId`,
        evidenceResource.parentResourceId,
      );
      formData.append(`evidenceResources[${index}].resourceId`, evidenceResource.resourceId);
      formData.append(`evidenceResources[${index}].resourceType`, evidenceResource.type);
    },
  );
};

const appendRemovedEvidenceResources = (
  formData,
  attributeKey,
  goalParentResourceId,
  goalResourceId,
) => {
  evidenceResources(attributeKey, goalParentResourceId, goalResourceId).forEach(
    (evidenceResource, index) => {
      formData.append(
        `removedEvidenceResources[${index}].parentResourceId`,
        evidenceResource.parentResourceId,
      );
      formData.append(`removedEvidenceResources[${index}].resourceId`, evidenceResource.resourceId);
      formData.append(`removedEvidenceResources[${index}].resourceType`, evidenceResource.type);
    },
  );
};

const useEvidencesService = () => {
  const { httpDelete, post, put } = useAxios();

  const postEvidence = useCallback(
    async (
      { attachments, attributeKey, goalParentResourceId, goalResourceId, link, reflection, title },
      relatedUserId,
    ) => {
      const formData = new FormData();
      formData.append('title', title);
      formData.append('reflection', reflection);
      formData.append('link', link);
      appendEvidenceResources(formData, attributeKey, goalParentResourceId, goalResourceId);
      attachments.forEach((file) => formData.append('attachments', file));
      const { data } = await post(
        { enabled: true },
        `${apiRoute}${studentsRoute}/${relatedUserId}${evidencesRoute}`,
        formData,
        {
          'Content-Type': 'multipart/form-data',
          skipDefaultErrorHandlers: [
            StatusCodeMap.BAD_REQUEST,
            StatusCodeMap.SERVER_ERROR,
            StatusCodeMap.NOT_FOUND,
          ],
        },
      );
      return data.entity;
    },
    [post],
  );

  const updateEvidence = useCallback(
    async (
      {
        attachments,
        attributeKey,
        goalParentResourceId,
        goalResourceId,
        link,
        reflection,
        removedAttachments,
        removedGoalResourceId,
        title,
      },
      id,
      relatedUserId,
    ) => {
      const formData = new FormData();
      formData.append('id', id);
      formData.append('title', title);
      formData.append('reflection', reflection);
      formData.append('link', link);
      appendEvidenceResources(formData, attributeKey, goalParentResourceId, goalResourceId);
      if (removedGoalResourceId) {
        const relatedAttribute = null;
        appendRemovedEvidenceResources(
          formData,
          relatedAttribute,
          goalParentResourceId,
          removedGoalResourceId,
        );
      }
      removedAttachments.forEach((attachment, index) => {
        formData.append(`removedAttachments[${index}].id`, attachment.id);
        formData.append(`removedAttachments[${index}].resourceId`, attachment.resourceId);
        formData.append(`removedAttachments[${index}].ownerId`, attachment.ownerId);
        formData.append(`removedAttachments[${index}].fileName`, attachment.fileName);
      });
      attachments.forEach((file) => formData.append('attachments', file));

      const { data } = await put(
        { enabled: true },
        `${apiRoute}${studentsRoute}/${relatedUserId}${evidencesRoute}/${id}`,
        formData,
        {
          'Content-Type': 'multipart/form-data',
          skipDefaultErrorHandlers: [
            StatusCodeMap.BAD_REQUEST,
            StatusCodeMap.SERVER_ERROR,
            StatusCodeMap.NOT_FOUND,
          ],
        },
      );
      return data.entity;
    },
    [put],
  );

  const deleteEvidence = useCallback(
    (evidenceId, relatedUserId, evidenceType) =>
      httpDelete(
        { enabled: true },
        `${apiRoute}${studentsRoute}/${relatedUserId}${evidencesRoute}/${evidenceId}?resourceType=${evidenceType}`,
        undefined,
        {
          skipDefaultErrorHandlers: [
            StatusCodeMap.BAD_REQUEST,
            StatusCodeMap.SERVER_ERROR,
            StatusCodeMap.NOT_FOUND,
          ],
        },
      ),
    [httpDelete],
  );

  return {
    postEvidence,
    updateEvidence,
    deleteEvidence,
  };
};

export default useEvidencesService;
