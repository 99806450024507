import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Button } from '../../../atoms';
import { PageIllustrationBlock, ScreenCenteredContainer } from '../../../moleculas';
import { PageWrapper } from '../../../organisms';
import { rootRoute } from '../../../../constants/routes';
import { ReactComponent as AunthorizationRequiredImage } from '../../../../resources/images/error_401_unauthorized.svg';

const UnauthorizedPage = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleButtonClick = useCallback(() => {
    history.push(rootRoute);
  }, [history]);

  return (
    <PageWrapper noNav>
      <ScreenCenteredContainer>
        <PageIllustrationBlock
          Illustration={AunthorizationRequiredImage}
          illustrationAriaLabel={t(
            'A small child’s suitcase and a big red suitcase with a brown hat on top',
          )}
          mainHeaderText={t('Going somewhere?')}
          secondaryHeaderText={[
            t('You must have a valid AYO passport to proceed'),
            t('Log in again to continue'),
          ]}
        />
        <Button isAdaptive onClick={handleButtonClick} variant="primary">
          {t('Back to landing page')}
        </Button>
      </ScreenCenteredContainer>
    </PageWrapper>
  );
};

export default UnauthorizedPage;
