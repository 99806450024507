import React from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Card, Typography } from '../../../../atoms';
import { AcademicRecordNamesMap } from '../../../../../constants/enums';

const PortfolioAcademicRecordCard = ({ type, value }) => {
  const { t } = useTranslation();

  return (
    <Card
      className="ayo-portfolio__academic-record-card"
      elevation={0}
      mainContent={
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          rowGap={1}
        >
          <Typography component="h3" variant="body2">
            {`${t(AcademicRecordNamesMap[type].title)}:`}
          </Typography>
          <Typography align="center" component="p" variant={value ? 'subtitle1' : 'caption'}>
            {value ? value.replace('-', t('of')) : t(AcademicRecordNamesMap[type].emptyState)}
          </Typography>
        </Box>
      }
    />
  );
};

PortfolioAcademicRecordCard.propTypes = {
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default PortfolioAcademicRecordCard;
