import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Container, Grid, useMediaQuery, useTheme } from '@mui/material';

import { Typography } from '../../atoms';
import { tryMobileAppBlock } from '../../../constants/propTypes';
import { ReactComponent as InitiativesBlockMobileImage } from '../../../resources/images/download_mobile_app_bg_mobile.svg';
import { DownloadAppButtonsBlock } from '../../moleculas';

const TryMobileAppBlock = ({ config }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box>
      <Container>
        <Grid container>
          <Grid item md={6} xl={7}>
            <Typography paragraph variant="h1">
              {t(config.title)}
            </Typography>
            <Typography variant="body1">{t(config.description)}</Typography>
            <Box pt={3}>
              <DownloadAppButtonsBlock />
            </Box>
          </Grid>
        </Grid>
      </Container>
      {!isWidthUpSm && <InitiativesBlockMobileImage className="ayo-svg-illustration" />}
    </Box>
  );
};

TryMobileAppBlock.propTypes = {
  config: tryMobileAppBlock,
};

TryMobileAppBlock.defaultProps = {
  config: {},
};

export default TryMobileAppBlock;
