import React, { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DialogContent, DialogActions, Box } from '@mui/material';

import { Dialog, DialogTitle, Switch, Typography } from '../../../../../atoms';
import { PopupActionsButtons } from '../../../../../moleculas';
import { EditablePrivilegesByGroupMap } from '../../../../../../constants/enums';
import { AppContext, AppActions } from '../../../../../../context';
import { useAdminService } from '../../../../../../services';

const ManageUserPrivilegesDialog = ({ isOpen, onPrivilegesUpdate, onClose, person }) => {
  const { t } = useTranslation();
  const { putUserPrivileges } = useAdminService();
  const closeDialogHandler = useCallback(() => {
    onClose();
  }, [onClose]);

  const { dispatch: dispatchAppState } = useContext(AppContext);

  const [userPrivileges, setUserPrivileges] = useState();

  useEffect(() => {
    if (isOpen) {
      if (person.privileges?.length > 0) {
        setUserPrivileges(person.privileges);
      } else {
        setUserPrivileges([]);
      }
    }
  }, [isOpen, person.privileges]);

  const submitDialogHandler = useCallback(() => {
    onClose();
    putUserPrivileges(person.id, userPrivileges)
      .then(() => {
        dispatchAppState({
          type: AppActions.SET_SNACKBAR_STATUS,
          data: {
            text: t('The rights for name were changed succesfully', { name: person.fullName }),
            type: 'success',
          },
        });
        onPrivilegesUpdate();
      })
      .catch(() => {
        dispatchAppState({
          type: AppActions.SET_SNACKBAR_STATUS,
          data: {
            text: t('Failed to change the rights for name', { name: person.fullName }),
            type: 'error',
          },
        });
      });
  }, [
    dispatchAppState,
    person.id,
    person.fullName,
    onClose,
    onPrivilegesUpdate,
    putUserPrivileges,
    t,
    userPrivileges,
  ]);

  return (
    <Dialog onClose={closeDialogHandler} open={isOpen}>
      <DialogTitle disableTypography>
        <Box pb={1} pr={4}>
          <Typography component="h2" variant="subtitle1">
            {t('Manage user rights')}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box mb={5}>
          <Typography isLabel variant="body2">
            {person.fullName}, {person.role}
          </Typography>
        </Box>
        {Object.keys(EditablePrivilegesByGroupMap).map((groupName) => (
          <Box
            key={groupName}
            className="ayo-manage-user-privileges-dialog__privileges-group"
            mb={3}
            p={2}
          >
            <Typography component="h3" variant="subtitle2">
              {t(`privileges.${groupName}`)}
            </Typography>
            <Typography isLabel variant="body2">
              {t('Actions')}
            </Typography>
            {EditablePrivilegesByGroupMap[groupName].map((privilege) => (
              <>
                <Box key={privilege} mt={1}>
                  <Typography variant="body2">{t(`privileges.${privilege}`)}</Typography>
                </Box>
                <Box key={privilege} mt={1}>
                  <Switch
                    ariaLabel={t(`privileges.${privilege}`)}
                    checkedLabel="On"
                    gaLabel={t(`privileges.${privilege}`)}
                    name={privilege}
                    onChange={(checked, value) => {
                      if (!userPrivileges) return;
                      if (checked) {
                        setUserPrivileges((state) => [...state, value]);
                      } else {
                        setUserPrivileges((state) => state.filter((i) => i !== value));
                      }
                    }}
                    uncheckedLabel="Off"
                    value={userPrivileges?.includes(privilege)}
                  />
                </Box>
              </>
            ))}
          </Box>
        ))}
      </DialogContent>
      <DialogActions>
        <PopupActionsButtons
          primaryButtonHandler={submitDialogHandler}
          primaryButtonText={t('Save changes')}
          secondaryButtonHandler={closeDialogHandler}
          secondaryButtonText={t('Cancel')}
        />
      </DialogActions>
    </Dialog>
  );
};

ManageUserPrivilegesDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onPrivilegesUpdate: PropTypes.func.isRequired,
  person: PropTypes.instanceOf(Object).isRequired,
};

export default ManageUserPrivilegesDialog;
