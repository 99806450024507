import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';

import { Typography, Card } from '../../atoms';
import { InitiativeEmptyStateBlock, TextWithTooltip } from '../../moleculas';
import { ReactComponent as GradesEmptyStateIllustration } from '../../../resources/images/grades_empty_state.svg';

const GradesSection = ({ grades }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });

  return (
    <>
      <Box pb={isWidthUpSm ? 3 : 2}>
        <Typography component="h2" variant="h2">
          {t('Academic performance')}
        </Typography>
      </Box>
      <Box mx={grades.length ? -1.5 : 0}>
        <Grid container>
          {grades.length ? (
            grades.map((grade) => (
              <Grid key={grade.courseNumber} item lg={3} md={4} sm={6} xs={12}>
                <Box px={1.5} py={isWidthUpSm ? 1 : 0.5}>
                  <Card
                    elevation="0"
                    mainContent={
                      <Box
                        alignItems="center"
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                      >
                        <TextWithTooltip
                          containerProps={{ mr: 1 }}
                          title={grade.courseName}
                          titleVariant="body2"
                        />
                        <Typography
                          component="p"
                          variant="subtitle1"
                        >{`${grade.averageGrade}%`}</Typography>
                      </Box>
                    }
                  />
                </Box>
              </Grid>
            ))
          ) : (
            <InitiativeEmptyStateBlock
              illustration={<GradesEmptyStateIllustration />}
              title={t('No information about academic performance so far')}
            />
          )}
        </Grid>
      </Box>
    </>
  );
};

GradesSection.propTypes = {
  grades: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
};

export default GradesSection;
