import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Button, SkeletonMask, Typography } from '../../atoms';
import { BadgeCard, InitiativeEmptyStateBlock, NewFeatureHotspot } from '../../moleculas';
import { useLazyDataLoad } from '../../../hooks';
import { sortArrayByKey } from '../../../utils';
import { badgesRoute } from '../../../constants/routes';
import { UserContext } from '../../../context';
import { useStudentsService } from '../../../services';
import { NewFeaturesIdsMap } from '../../../tours/common/NewFeaturesItemsProvider';
import { ReactComponent as ChevronIcon } from '../../../resources/icons/chevron_left.svg';
import { ReactComponent as ProfileEmptyState } from '../../../resources/images/badges/profile_empty_state.svg';

const PROFILE_BADGES_LENGTH = 6;
const PROFILE_BADGES_LENGTH_MOBILE = 2;

const BadgesProfileBlock = ({ isLazyLoad }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [badges, setBadges] = useState(null);
  const { state: userState } = useContext(UserContext);
  const studentId = userState.profile?.id;
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
  const { getStudentBadges } = useStudentsService();

  const loadBadges = useCallback(
    () =>
      getStudentBadges(studentId, isLazyLoad).then((badgesData) => {
        const achivedBadges = badgesData?.filter((badgeItem) => badgeItem.createdDate);
        setBadges(sortArrayByKey(achivedBadges, 'lastAchievedDate', 'date'));
      }),
    [getStudentBadges, isLazyLoad, studentId],
  );

  const { isDataLoaded, loadActivatorRef } = useLazyDataLoad(
    () =>
      new Promise((resolve) => {
        loadBadges(resolve);
      }),
  );

  useEffect(() => {
    if (!isLazyLoad) {
      getStudentBadges();
    }
  }, [getStudentBadges, isLazyLoad, studentId, badges]);

  return (
    <>
      <Box mb={isWidthUpSm ? 10 : 5}>
        <Box
          alignItems={isWidthUpSm ? 'center' : 'flex-start'}
          display="flex"
          flexDirection={isWidthUpSm ? 'row' : 'column'}
          justifyContent="space-between"
          mb={!badges?.length ? 3 : 0}
        >
          <Box ref={isLazyLoad ? loadActivatorRef : null} display="flex">
            <Typography component="h2" variant="h2">
              {t('My badges')}
            </Typography>
            <NewFeatureHotspot id={NewFeaturesIdsMap.BADGES} isClickable label={t('My badges')} />
          </Box>
          {!!badges?.length && isWidthUpSm && (
            <Button
              component={RouterLink}
              endIcon={<ChevronIcon className="reversed" />}
              gaLabel="View all"
              to={badgesRoute}
            >
              {t('View all')}
            </Button>
          )}
        </Box>
        {badges?.length ? (
          <Box>
            <Typography variant="body2">{t('Recently awarded badges')}</Typography>
            {!isWidthUpSm && (
              <Box mt={3}>
                <Button
                  component={RouterLink}
                  endIcon={<ChevronIcon className="reversed" />}
                  gaLabel="View all"
                  to={badgesRoute}
                >
                  {t('View all')}
                </Button>
              </Box>
            )}
            <Box mt={3}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                maxWidth="initial"
                spacing={3}
                xs={12}
              >
                {badges
                  .slice(0, isWidthUpSm ? PROFILE_BADGES_LENGTH : PROFILE_BADGES_LENGTH_MOBILE)
                  .map((badge) => (
                    <Grid key={badge.id} item md={2} xs={6}>
                      <BadgeCard badge={badge} isReadOnlyMode />
                    </Grid>
                  ))}
              </Grid>
            </Box>
          </Box>
        ) : isLazyLoad && !isDataLoaded && !badges ? (
          <SkeletonMask />
        ) : (
          <InitiativeEmptyStateBlock
            body={t('But you can learn how to achieve them!')}
            buttonGaLabel="Learn more"
            illustration={<ProfileEmptyState />}
            secondaryButtonContent={
              <>
                {t('Learn more')}
                <Box display="flex" ml={1}>
                  <ChevronIcon className="reversed" />
                </Box>
              </>
            }
            secondaryButtonLink={badgesRoute}
            title={t('No information about awarded badges so far')}
          />
        )}
      </Box>
    </>
  );
};

BadgesProfileBlock.propTypes = {
  isLazyLoad: PropTypes.bool,
};

BadgesProfileBlock.defaultProps = {
  isLazyLoad: false,
};
export default BadgesProfileBlock;
