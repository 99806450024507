/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useRef, useEffect } from 'react';
import { Slider as MUISlider, SliderThumb } from '@mui/material';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// eslint-disable-next-line react/prop-types
const Thumb = ({ children, ThumbComponent, ...rest }) => (
  <SliderThumb {...rest}>
    {children}
    {ThumbComponent ? <ThumbComponent /> : null}
  </SliderThumb>
);

const Slider = ({
  ariaMarks,
  className,
  defaultValue,
  label,
  marks,
  max,
  min,
  onChange,
  orientation,
  step,
  ThumbComponents,
  value: propValue,
}) => {
  const [value, setValue] = useState(propValue);

  useEffect(() => {
    setValue(propValue);
  }, [propValue]);

  const sliderRef = useRef();
  const ThumbComponent = ThumbComponents?.[value];

  return (
    <MUISlider
      ref={sliderRef}
      aria-label={label}
      className={classnames('ayo-slider', className)}
      components={{
        Thumb,
      }}
      componentsProps={{
        thumb: {
          className: !!ThumbComponent && 'with-thumb-icon',
          ThumbComponent,
        },
      }}
      defaultValue={defaultValue}
      getAriaValueText={
        ariaMarks && ((currentValue) => ariaMarks.find((mark) => mark.value === currentValue).label)
      }
      marks={marks}
      max={max}
      min={min}
      onChange={(e, newValue) => {
        setValue(newValue);
        if (onChange) {
          onChange(e, newValue);
        }
        setTimeout(() => sliderRef.current.querySelector('.MuiSlider-thumb input').focus(), 0);
      }}
      orientation={orientation}
      step={step}
      value={value}
    />
  );
};

Slider.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.number,
  max: PropTypes.number,
  min: PropTypes.number,
  onChange: PropTypes.func,
  step: PropTypes.number,
  ThumbComponents: PropTypes.instanceOf(Object),
  ariaMarks: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.number, label: PropTypes.string }),
  ).isRequired,
  marks: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.number, label: PropTypes.string })),
  value: PropTypes.number,
  label: PropTypes.string,
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
};

Slider.defaultProps = {
  className: '',
  defaultValue: 0,
  max: 100,
  min: 0,
  onChange: () => {},
  step: 1,
  marks: null,
  value: undefined, // should be undefined by default to make defaultValue prop working
  ThumbComponents: null,
  label: '',
  orientation: 'horizontal',
};

export default Slider;
