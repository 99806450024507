import { useCallback } from 'react';

import { apiRoute, reflectionsRoute, resourcesRoute } from '../../constants/routes';
import useAxios, { StatusCodeMap } from '../HttpClient';

const useReflectionsService = () => {
  const { post, put, httpDelete } = useAxios();

  const postReflection = useCallback(
    async (reflection, hideLoadMask, withErrorHandlers) => {
      const { data } = await post(
        { enabled: !hideLoadMask },
        `${apiRoute}${reflectionsRoute}`,
        reflection,
        {
          skipDefaultErrorHandlers: withErrorHandlers && [
            StatusCodeMap.NOT_FOUND,
            StatusCodeMap.SERVER_ERROR,
          ],
        },
      );
      return data;
    },
    [post],
  );
  const putReflection = useCallback(
    async (reflection, hideLoadMask) => {
      const { data } = await put(
        { enabled: !hideLoadMask },
        `${apiRoute}${reflectionsRoute}`,
        reflection,
      );
      return data;
    },
    [put],
  );
  const deleteReflection = useCallback(
    (resourceId, resourceType, hideLoadMask) =>
      httpDelete(
        { enabled: !hideLoadMask },
        `${apiRoute}${reflectionsRoute}${resourcesRoute}/${resourceId}?resourceType=${resourceType}`,
      ),
    [httpDelete],
  );

  return { postReflection, putReflection, deleteReflection };
};

export default useReflectionsService;
