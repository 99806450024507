/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useCallback, useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Box, IconButton } from '@mui/material';

import { TextField, SpeechButton } from '../../atoms';
import STTTooltip from '../stt-tooltip/STTTooltip';
import useSTT from '../../../services/speech-to-text/useSTT';
import { GA } from '../../../utils';
import { GaActions, GaCategories } from '../../../constants/enums';
import { ReactComponent as CloseIcon } from '../../../resources/icons/close_light.svg';

const STTTextField = ({ InputLabelProps, disabled, gaLabel, onBlur, onChange, value, ...rest }) => {
  const inputRef = useRef();

  const {
    startRecording,
    stopRecording,
    isRecording,
    identifiedText: plainIdentifiedText,
    analyzer,
    socket,
    error,
  } = useSTT();

  const { multiline } = rest;

  const identifiedText = useMemo(
    () =>
      plainIdentifiedText
        .map((sentence) => sentence.trimStart()[0].toUpperCase() + sentence.trimStart().slice(1))
        .join(' '),
    [plainIdentifiedText],
  );

  const [isRecordingActivated, setIsRecordingActivated] = useState(false);

  const sendGoogleAnalytics = useCallback(
    (action) => {
      GA.logInteraction({
        category: GaCategories.BEHAVIOR,
        action,
        label: gaLabel,
      });
    },
    [gaLabel],
  );

  const onRecordingStart = useCallback(() => {
    startRecording();
    if (!isRecordingActivated) {
      setIsRecordingActivated(true);
      sendGoogleAnalytics(GaActions.STT_OPEN);
      inputRef.current.focus();
    }
  }, [isRecordingActivated, sendGoogleAnalytics, startRecording]);

  const onRecordingEnd = useCallback(() => {
    stopRecording();
    setIsRecordingActivated(false);
    sendGoogleAnalytics(GaActions.STT_CLOSE);
    if (identifiedText) inputRef.current.focus();
  }, [identifiedText, sendGoogleAnalytics, stopRecording]);

  const modifiedOnChange = useCallback(
    (e) => {
      if (!e.isSTT && isRecording) {
        onRecordingEnd();
      }
      onChange(e);
      inputRef.current.scrollTop = inputRef.current.scrollHeight;
    },
    [isRecording, onChange, onRecordingEnd],
  );
  const onBlurHandler = (e) => {
    onBlur(e);
    if (e.target.value) {
      sendGoogleAnalytics(GaActions.INPUT_FINISH_TYPING);
    }
  };

  const onRecognitionChange = useCallback(
    (recognizedText) => {
      if (value === recognizedText) return;
      inputRef.current.value = recognizedText;
      modifiedOnChange({ target: inputRef.current, isSTT: true });
    },
    [modifiedOnChange, value],
  );

  useEffect(() => {
    if (isRecording) {
      onRecognitionChange(identifiedText);
    }
  }, [identifiedText, isRecording, onRecognitionChange]);

  useEffect(() => {
    if (!socket && identifiedText && isRecordingActivated) {
      setIsRecordingActivated(false);
    }
  }, [identifiedText, isRecordingActivated, socket]);

  const resetText = useCallback(() => onRecognitionChange(''), [onRecognitionChange]);

  const closeTooltips = useCallback(() => setIsRecordingActivated(false), []);

  const onResetClick = useCallback(() => {
    onRecordingEnd();
    resetText();
  }, [onRecordingEnd, resetText]);

  const { t } = useTranslation();

  return (
    <STTTooltip
      analyzer={analyzer}
      closeTooltips={closeTooltips}
      error={error}
      gaLabel={gaLabel}
      hasRecognizedText={!!identifiedText}
      isConnected={!!socket}
      isRecording={isRecording}
      isRecordingActivated={isRecordingActivated}
      resetText={resetText}
      startRecording={onRecordingStart}
      stopAndClose={onRecordingEnd}
    >
      <div>
        <TextField
          disabled={disabled}
          InputLabelProps={{
            ...InputLabelProps,
            className: classnames(
              'ayo-textfield__input-label with-stt',
              InputLabelProps.className,
              { 'with-reset': isRecordingActivated },
            ),
          }}
          InputProps={{
            endAdornment: (
              <Box display="flex" flexDirection="row" mt={multiline ? 1 : 0}>
                {isRecordingActivated && (
                  <IconButton
                    className="ayo-icon-button"
                    disableRipple
                    onClick={onResetClick}
                    title={t('Clear')}
                  >
                    <CloseIcon />
                  </IconButton>
                )}
                <SpeechButton
                  disabled={disabled}
                  isActive={isRecordingActivated}
                  onClick={isRecordingActivated ? onRecordingEnd : onRecordingStart}
                />
              </Box>
            ),
          }}
          inputRef={inputRef}
          onBlur={onBlurHandler}
          onChange={modifiedOnChange}
          value={value}
          {...rest}
        />
      </div>
    </STTTooltip>
  );
};

STTTextField.propTypes = {
  InputLabelProps: PropTypes.instanceOf(Object),
  disabled: PropTypes.bool,
  gaLabel: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

STTTextField.defaultProps = {
  InputLabelProps: {},
  disabled: false,
  gaLabel: null,
  onBlur: () => {},
  onChange: () => {},
  value: '',
};

export default STTTextField;
