import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { Box, Container, useMediaQuery, useTheme } from '@mui/material';

import { Typography } from '../../../atoms';
import { GoalsBlock, PageWrapper, StudentGeneralInfo } from '../../../organisms';
import { UserActions, UserContext } from '../../../../context';
import { useOneTimeActionService } from '../../../../services';
import { trainAyoGoalsStudentSelectionRoute, trainAyoRoute } from '../../../../constants/routes';
import { OneTimeActionsMap, RolesMap } from '../../../../constants/enums';
import { useSharedStudentData } from '../../../../hooks';

const GoalsPage = () => {
  const { t } = useTranslation();
  const match = useRouteMatch();
  const { state: userState, dispatch: dispatchUserState } = useContext(UserContext);
  const isStudent = userState.profile.role === RolesMap.STUDENT;
  const activeStudentId = match.params.studentId;
  const { activeStudent } = useSharedStudentData(!isStudent ? activeStudentId : null);
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const { postOneTimeAction } = useOneTimeActionService();
  const [isGoalsAvailable, setIsGoalsAvailable] = useState(false);

  useEffect(() => {
    if (!userState.oneTimeActions.includes(OneTimeActionsMap.EXPLORE_GOALS_OPEN)) {
      postOneTimeAction(OneTimeActionsMap.EXPLORE_GOALS_OPEN);
      dispatchUserState({
        type: UserActions.SET_ONE_TIME_ACTION,
        data: OneTimeActionsMap.EXPLORE_GOALS_OPEN,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGoalsData = (goals) => {
    setIsGoalsAvailable(!!goals.length);
  };

  return (
    <PageWrapper
      backToLink={isStudent ? trainAyoRoute : trainAyoGoalsStudentSelectionRoute}
      backToText={t(isStudent ? 'Go to Train AYO' : 'Go to all students')}
      mainElementClassName="ayo-goals"
    >
      <Container>
        {!isStudent && <StudentGeneralInfo student={activeStudent} />}
        <Box maxWidth={500} mb={isWidthUpSm ? 5 : 3}>
          <Typography
            component={isStudent ? 'h1' : 'h2'}
            paragraph
            variant={isStudent ? 'h1' : 'h2'}
          >
            {t(isStudent ? 'My goals' : 'Goals')}
          </Typography>
          {isGoalsAvailable ? (
            <Typography variant="body2">
              {t(
                isStudent
                  ? 'These are your goals Here you can track the progress and see how successful you are at meeting them'
                  : 'These are the student’s goals Here, you can track the progress and see how successful the student is at meeting them',
              )}
            </Typography>
          ) : (
            <Typography variant="body2">
              {t('There are no goals created so far. But you can change that!')}
            </Typography>
          )}
        </Box>
        <GoalsBlock
          checkAvailableGoals={handleGoalsData}
          gaLabel="Goal card click"
          headerLevel={1}
          isEditable
          isEvidenceEditable
          studentId={activeStudentId}
        />
      </Container>
    </PageWrapper>
  );
};

export default GoalsPage;
