import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import ClickableCard from '../clickable-card/ClickableCard';

const ClickableGalleryItem = ({
  attribute,
  index,
  clickHandler,
  className,
  section,
  titleWrap,
}) => {
  const { t } = useTranslation();
  const imageComponent = attribute[section];

  return (
    <Grid
      key={attribute.key}
      className={classnames('ayo-clickable-gallery-item', className)}
      container
      item
      justifyContent="center"
      md={3}
      xs={4}
    >
      <ClickableCard
        gaLabel={`Gallery item - ${attribute.key}`}
        mediaContent={imageComponent.imageDesktop}
        onClick={() => {
          if (clickHandler) {
            clickHandler(index);
          }
        }}
        statusText={t(attribute.status)}
        title={t(attribute.key)}
        titleWrap={titleWrap}
      />
    </Grid>
  );
};

export default ClickableGalleryItem;

ClickableGalleryItem.propTypes = {
  attribute: PropTypes.shape({
    key: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  clickHandler: PropTypes.func,
  className: PropTypes.string.isRequired,
  section: PropTypes.string.isRequired,
  titleWrap: PropTypes.bool,
};

ClickableGalleryItem.defaultProps = {
  clickHandler: () => {},
  titleWrap: true,
};
