import { useCallback } from 'react';

import {
  activityLogRoute,
  administratorsRoute,
  apiRoute,
  studentsRoute,
} from '../../constants/routes';
import useAxios, { StatusCodeMap } from '../HttpClient';
import { getFullName } from '../../utils/data-transformation-helpers/dataTransformationHelpers';

const useAdministratorsService = () => {
  const { get, put } = useAxios();

  const getStudents = useCallback(
    async (activityCriterion, filterParams, currentCampus) => {
      const { data } = await get(
        { enabled: true },
        `${apiRoute}${administratorsRoute}${studentsRoute}${
          activityCriterion ? `?activityCriterion=${activityCriterion}` : ''
        }${filterParams ? `&${filterParams}` : ''}${
          currentCampus ? `&schoolName=${currentCampus}` : ''
        }`,
      );
      return {
        schoolName: data.entity?.schoolName,
        students: data.entity?.students.map((item) => ({
          ...item,
          name: getFullName(item),
        })),
      };
    },
    [get],
  );

  const getAnalytics = useCallback(
    async (currentCampus) => {
      const { data } = await get(
        { enabled: true },
        `${apiRoute}${administratorsRoute}/analytics?schoolName=${currentCampus}`,
      );
      return data.entity || {};
    },
    [get],
  );

  const getActivityLogData = useCallback(
    async (studentId, page, contentType) => {
      const { data } = await get(
        { enabled: true },
        `${apiRoute}${activityLogRoute}${studentsRoute}/${studentId}?page=${page}&size=20&contentType=${contentType}`,
      );
      return data.entity;
    },
    [get],
  );

  const getActivityLogUnreadCount = useCallback(
    async (studentId, contentType) => {
      const { data } = await get(
        { enabled: true },
        `${apiRoute}${activityLogRoute}${studentsRoute}/${studentId}/countUnread?contentType=${contentType}`,
      );
      return data.entity.count;
    },
    [get],
  );

  const putReadActivityLogItem = useCallback(
    (studentId, idsArray, contentType) =>
      put(
        { enabled: false },
        `${apiRoute}${activityLogRoute}${studentsRoute}/${studentId}/markRead?contentType=${contentType}`,
        { ids: idsArray },
        {
          skipDefaultErrorHandlers: [StatusCodeMap.NOT_FOUND, StatusCodeMap.SERVER_ERROR],
        },
      ),
    [put],
  );

  const putReadAllActivityLogItems = useCallback(
    (studentId, contentType) =>
      put(
        { enabled: false },
        `${apiRoute}${activityLogRoute}${studentsRoute}/${studentId}/markAllRead?contentType=${contentType}`,
        null,
        {
          skipDefaultErrorHandlers: [StatusCodeMap.NOT_FOUND, StatusCodeMap.SERVER_ERROR],
        },
      ),
    [put],
  );

  return {
    getStudents,
    getAnalytics,
    getActivityLogData,
    getActivityLogUnreadCount,
    putReadActivityLogItem,
    putReadAllActivityLogItems,
  };
};

export default useAdministratorsService;
