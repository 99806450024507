import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { BottomBar, PageWrapper } from '../../organisms';
import { initiativesRoute } from '../../../constants/routes';

import {
  AptitudesBenefitSection,
  AptitudesDefinitionSection,
  AptitudesExploreMoreSection,
  AptitudesGallerySection,
  AptitudesHowToLearnSection,
  AptitudesPresentationSection,
} from './components';

const AptitudesPage = () => {
  const { t } = useTranslation();

  return (
    <PageWrapper
      backToLink={initiativesRoute}
      backToText={t('Go to AYO’s initiatives page')}
      className="ayo-aptitudes-page"
      customFooter={<BottomBar noNav />}
      noNav
    >
      <Box className="ayo-aptitudes-page__content">
        <AptitudesPresentationSection />
        <AptitudesDefinitionSection />
        <AptitudesBenefitSection />
        <AptitudesHowToLearnSection />
        <AptitudesGallerySection />
        <AptitudesExploreMoreSection />
      </Box>
    </PageWrapper>
  );
};

export default AptitudesPage;
