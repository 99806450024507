import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import { Box, DialogContent, DialogActions, useTheme, useMediaQuery } from '@mui/material';

import { Button, Dialog, DialogTitle, Typography } from '../../atoms';
import { PopupActionsButtons } from '../../moleculas';

const ConfirmDialogVariant = {
  DELETE: 'delete',
  CONFIRM: 'confirm',
};

export const ConfirmDialog = ({
  className,
  text,
  title,
  confirmButtonTitle,
  cancelButtonTitle,
  content,
  isOpen,
  onCancel,
  onClose,
  onConfirm,
  variant,
  dialogGaLabel,
  titleTranslationArgs,
  textTranslationArgs,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpXl = useMediaQuery(theme.breakpoints.up('xl'));

  return (
    <Dialog
      className={className}
      gaLabel={dialogGaLabel}
      maxWidth={isWidthUpXl ? 700 : undefined}
      onClose={onClose}
      open={isOpen}
    >
      <DialogTitle disableTypography>
        <Box mb={2}>
          <Typography component="h2" variant="subtitle1">
            {t(title, titleTranslationArgs)}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box mb={5}>
          {text && (
            <Typography variant="body2">
              <Trans components={{ b: <b /> }} i18nKey={text} values={textTranslationArgs} />
            </Typography>
          )}
          {content}
        </Box>
      </DialogContent>
      <DialogActions>
        <PopupActionsButtons
          primaryButtonComponent={
            <Button
              fullWidth
              gaLabel={confirmButtonTitle}
              onClick={onConfirm}
              variant={variant === ConfirmDialogVariant.DELETE ? 'danger' : 'primary'}
            >
              {t(confirmButtonTitle)}
            </Button>
          }
          secondaryButtonGaLabel={cancelButtonTitle}
          secondaryButtonHandler={onCancel ?? onClose}
          secondaryButtonText={t(cancelButtonTitle)}
        />
      </DialogActions>
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  className: PropTypes.string,
  onCancel: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  text: PropTypes.string,
  title: PropTypes.string.isRequired,
  confirmButtonTitle: PropTypes.string,
  cancelButtonTitle: PropTypes.string,
  dialogGaLabel: PropTypes.string,
  variant: PropTypes.oneOf([ConfirmDialogVariant.DELETE, ConfirmDialogVariant.CONFIRM]),
  content: PropTypes.node,
  titleTranslationArgs: PropTypes.instanceOf(Object),
  textTranslationArgs: PropTypes.instanceOf(Object),
};

ConfirmDialog.defaultProps = {
  className: '',
  onCancel: null,
  isOpen: false,
  text: 'AYO will not be able to restore it again.',
  confirmButtonTitle: 'Save and leave',
  cancelButtonTitle: 'Do not save and leave',
  variant: ConfirmDialogVariant.DELETE,
  dialogGaLabel: 'Close delete dialog',
  content: null,
  titleTranslationArgs: {},
  textTranslationArgs: {},
};

export default ConfirmDialog;
