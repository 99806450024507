import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Container, useMediaQuery, useTheme } from '@mui/material';
import classnames from 'classnames';

import { Typography } from '../../../../../atoms';
import { ReactComponent as OpportunitiesGoalIllustration } from '../../../../../../resources/images/opportunities/landing-page/opportunities-goal.svg';

const OpportunitiesGoalSectionPage = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const isWidthUpXl = useMediaQuery(theme.breakpoints.up('xl'));

  return (
    <Box className="ayo-opportunities-goal-section" py={isWidthUpXl ? 20 : 10}>
      <Container>
        <Box
          alignItems="center"
          display="flex"
          flexDirection={isWidthUpSm ? 'row' : 'column'}
          justifyContent="center"
        >
          <Box
            className="ayo-opportunities-page__content__text"
            mb={isWidthUpSm ? 0 : 3}
            mr={isWidthUpSm ? 3 : 0}
          >
            <Typography component="h2" isLightText paragraph variant="h2">
              {t('The goal')}
            </Typography>
            <Typography isLightText variant="body2">
              {t(
                'With this initiative, AYO provides students with the fun and interactive way for self-development: they explore new opportunities by uncovering the cards prepared by AYO based on analysis of their data',
              )}
            </Typography>
          </Box>
          <OpportunitiesGoalIllustration
            aria-label={t('Three mystery cards on the stage between the curtains')}
            className={classnames({
              'ayo-svg-illustration': !isWidthUpSm || isWidthUpXl,
            })}
          />
        </Box>
      </Container>
    </Box>
  );
};

export default OpportunitiesGoalSectionPage;
