import React from 'react';
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Button, Card, Typography } from '../../../../atoms';
import { MlChip, TextWithTooltip } from '../../../../moleculas';
import { GA } from '../../../../../utils';
import { GaActions, GaCategories } from '../../../../../constants/enums';

export const SuggestedFamilyActivityCardPreview = ({ title, imgAlt, imgSrc, onLearnMoreClick }) => {
  const { t } = useTranslation();

  return (
    <Card
      className={classNames(
        'suggested-family-activity-card-preview suggested-family-activity-background',
        {
          'family-activity-card-default-image': !imgSrc,
        },
      )}
      elevation={0}
      imageSrc={imgSrc}
      mainContent={
        <Box>
          <TextWithTooltip
            className="suggested-family-activity-card-preview__title"
            title={title}
            titleVariant="subtitle2"
            typographyProps={{
              align: 'left',
            }}
          />
          <Box alignItems="center" columnGap={1} display="flex" mt={2}>
            <Button gaLabel="View" onClick={onLearnMoreClick} variant="text-primary">
              {t('View')}
            </Button>
          </Box>
        </Box>
      }
      mediaContent={imgSrc ? 'img' : 'div'}
      mediaContentAriaLabel={imgAlt}
    />
  );
};

SuggestedFamilyActivityCardPreview.propTypes = {
  title: PropTypes.string.isRequired,
  imgAlt: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  onLearnMoreClick: PropTypes.func.isRequired,
};

const FamilyActivityCard = ({
  title,
  description,
  imgAlt,
  imgSrc,
  isSuggested,
  onLearnMoreClick,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpMd = useMediaQuery(theme.breakpoints.up('md'), { noSsr: true });
  const isWidthUpLg = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });

  return (
    <Grid item sm={3} xs={4}>
      <Card
        className={classNames('family-activity-card', {
          'family-activity-card--md': isWidthUpMd,
          'family-activity-card--suggested': isSuggested,
          'suggested-family-activity-background': isSuggested,
          'ayo-clickable-card': !isWidthUpMd,
          'family-activity-card-default-image': !imgSrc,
        })}
        component={isWidthUpMd ? 'div' : 'button'}
        elevation={isWidthUpMd ? 0 : 1}
        imageSrc={imgSrc}
        mainContent={
          <Box>
            <TextWithTooltip
              className="family-activity-card__title"
              rowsCount={isWidthUpMd ? 1 : 2}
              title={title}
              titleVariant={isWidthUpMd ? 'subtitle2' : 'caption'}
              typographyProps={{
                align: 'left',
              }}
            />
            {isSuggested && (
              <MlChip
                className="family-activity-card--suggested__chip"
                text={isWidthUpMd ? 'AYO' : ''}
              />
            )}
            {isWidthUpMd && (
              <>
                <Typography className="family-activity-card__description" mb={2} variant="caption">
                  {description}
                </Typography>
                <Box alignItems="center" columnGap={isWidthUpLg ? 2 : 1} display="flex">
                  <Button gaLabel="View" onClick={onLearnMoreClick} variant="text-primary">
                    {t('View')}
                  </Button>
                </Box>
              </>
            )}
          </Box>
        }
        mediaContent={imgSrc ? 'img' : 'div'}
        mediaContentAriaLabel={
          imgAlt || t('A box labeled "AYO" with a ball and some books inside.')
        }
        onClick={
          isWidthUpMd
            ? null
            : () => {
                GA.logInteraction({
                  category: GaCategories.BEHAVIOR,
                  action: GaActions.CARD_CLICK,
                  label: 'Family activity card',
                });

                onLearnMoreClick();
              }
        }
      />
    </Grid>
  );
};

FamilyActivityCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imgAlt: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  isSuggested: PropTypes.bool,
  onLearnMoreClick: PropTypes.func.isRequired,
};

FamilyActivityCard.defaultProps = {
  isSuggested: false,
};

export default FamilyActivityCard;
