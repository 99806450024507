/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FormControlLabel, FormGroup, Switch as MUISwitch } from '@mui/material';

import { GA } from '../../../utils';
import { GaActions, GaCategories } from '../../../constants/enums';
import Typography from '../typography/Typography';

export const Switch = ({
  className,
  gaLabel,
  onChange,
  value,
  checkedLabel,
  uncheckedLabel,
  labelPlacement,
  name,
  ariaLabel,
  ...rest
}) => {
  const { t } = useTranslation();

  const label = useMemo(
    () => (value ? checkedLabel : uncheckedLabel),
    [checkedLabel, uncheckedLabel, value],
  );

  const switchHandler = useCallback(
    (event) => {
      if (gaLabel) {
        GA.logInteraction({
          category: GaCategories.BEHAVIOR,
          action: GaActions.SWITCH_TOGLE,
          label: gaLabel,
        });
      }
      onChange(event.target.checked, name, event);
    },
    [gaLabel, name, onChange],
  );

  return (
    <FormGroup className={className}>
      <FormControlLabel
        control={
          <MUISwitch
            aria-label={ariaLabel}
            checked={value}
            className="ayo-switch"
            disableRipple
            onChange={switchHandler}
            {...rest}
          />
        }
        label={
          <Typography aria-hidden variant="body2">
            {t(label)}
          </Typography>
        }
        labelPlacement={labelPlacement}
      />
    </FormGroup>
  );
};

Switch.propTypes = {
  ariaLabel: PropTypes.string,
  gaLabel: PropTypes.string,
  labelPlacement: PropTypes.string,
  checkedLabel: PropTypes.string,
  uncheckedLabel: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.bool,
  name: PropTypes.string,
  className: PropTypes.string,
};

Switch.defaultProps = {
  ariaLabel: '',
  gaLabel: '',
  labelPlacement: '',
  checkedLabel: '',
  uncheckedLabel: '',
  onChange: () => {},
  value: true,
  name: '',
  className: '',
};

export default Switch;
