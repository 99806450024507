/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Alert as MUIAlert, AlertTitle, Collapse } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { GA } from '../../../utils';
import { GaActions, GaCategories } from '../../../constants/enums';

const ALERT_TYPES = {
  error: 'error',
  info: 'info',
  success: 'success',
  warning: 'warning',
};

const Alert = ({ body, className, gaLabel, onClose, open, title, type, ...rest }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  return (
    <Collapse className="ayo-alert-wrapper" in={isOpen}>
      <MUIAlert
        className={`${classNames('ayo-alert', `ayo-alert--${ALERT_TYPES[type]} ${type}`, {
          [`${className}`]: className,
          action: rest.action,
        })}`}
        closeText={t('Close')}
        onClose={() => {
          if (onClose) {
            onClose();
          }
          setIsOpen(false);
          if (gaLabel) {
            GA.logInteraction({
              category: GaCategories.BEHAVIOR,
              action: GaActions.ALERT_CLICK,
              label: gaLabel,
            });
          }
        }}
        {...rest}
      >
        <AlertTitle className="ayo-alert__title">{title}</AlertTitle>
        {body}
      </MUIAlert>
    </Collapse>
  );
};

Alert.propTypes = {
  body: PropTypes.node,
  className: PropTypes.string,
  gaLabel: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.node,
  type: PropTypes.oneOf(Object.values(ALERT_TYPES)),
};

Alert.defaultProps = {
  body: null,
  className: '',
  gaLabel: '',
  onClose: null,
  open: false,
  title: null,
  type: 'info',
};

export default Alert;
