import React from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Typography } from '../../../../atoms';
import { AssignmentInsightsCategories } from '../../../../../constants/enums';
import { ReactComponent as UncategorizedIllustration } from '../../../../../resources/images/assignment-insights/uncategorized.svg';
import {
  AssignmentInsightsCategoriesIllustrations,
  UNASSIGNED_GROUP_NAME,
} from '../assignment-insights/shared';
import { MlChip } from '../../../../moleculas';

const CommonTitles = {
  [AssignmentInsightsCategories.INTERESTS]: 'Common interest',
  [AssignmentInsightsCategories.EXTRACURRICULARS]: 'Common extracurricular',
  [AssignmentInsightsCategories.ENDORSEMENTS]: 'Common endorsement',
  [UNASSIGNED_GROUP_NAME]: 'Uncategorized(lesson)',
};

const AssignmentTypeBlock = ({ type, content, withMLChip }) => {
  const IllustrationComponent =
    AssignmentInsightsCategoriesIllustrations[type] ?? UncategorizedIllustration;
  const typeTitle = CommonTitles[type];
  const { t } = useTranslation();
  return (
    <Box className="assignment-type-block" mb={4}>
      <Box display="flex" justifyContent="space-between">
        <Box alignItems="center" display="flex" gap={2} mb={1}>
          <IllustrationComponent />
          <Typography variant="subtitle2">{t(typeTitle)}</Typography>
        </Box>
        {withMLChip && <MlChip text={t('AYO insight')} />}
      </Box>
      {content}
    </Box>
  );
};

AssignmentTypeBlock.propTypes = {
  type: PropTypes.string.isRequired,
  content: PropTypes.node,
  withMLChip: PropTypes.bool,
};

AssignmentTypeBlock.defaultProps = {
  content: null,
  withMLChip: false,
};

export default AssignmentTypeBlock;
