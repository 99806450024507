export const ClubsManagementQueryParams = {
  NAME: 'name',
  STATUS: 'status',
  PAGE: 'page',
  PAGE_SIZE: 'pageSize',
};

export const ClubsMembersQueryParams = {
  ClUB_ID: 'clubId',
  NAME: 'name',
  STATUS: 'status',
  PAGE: 'page',
  PAGE_SIZE: 'pageSize',
};

export const ClubsStatuses = {
  ACTIVE: 'ACTIVE',
  ALL: 'ALL',
  DECLINED: 'DECLINED',
  INACTIVE: 'INACTIVE',
  PENDING: 'PENDING',
};

export const ClubsMembershipStatuses = {
  ALL: 'ALL',
  JOINED: 'JOINED',
  NOT_REQUESTED: 'NOT_REQUESTED',
  PENDING_REQUEST: 'PENDING_REQUEST',
  REQUEST_REJECTED: 'REQUEST_REJECTED',
};

export const ClubKeys = {
  ATTACHMENTS: 'attachments',
  CLUB_NAME: 'clubName',
  DESCRIPTION: 'description',
  LINKS: 'links',
  REMOVED_ATTACHMENTS: 'removedAttachments',
  REQUIREMENTS: 'requirements',
  SCHOOLS: 'schools',
  TAGS: 'tags',
  TYPE: 'type',
};

export const ClubRequirementsKeys = {
  DESCRIPTION: 'description',
  IS_VISIBLE: 'isVisible',
  LINKS: 'links',
};

export const ClubTypes = {
  ATHLETICS: 'ATHLETICS',
  CLUB: 'CLUB',
  FINE_ARTS: 'FINE_ARTS',
  ORGANIZATION: 'ORGANIZATION',
};

export const ClubJoinRequestKeys = {
  LINKS: 'links',
  NOTES: 'notes',
};

export const ClubRights = {
  CHANGE_MEMBERSHIP_STATUS: 'CHANGE_MEMBERSHIP_STATUS',
  CHANGE_SETTINGS: 'CHANGE_SETTINGS',
  CREATE_EVENTS: 'CREATE_EVENTS',
  CREATE_POST: 'CREATE_POST',
  EDIT_CLUB: 'EDIT_CLUB',
  MODERATE_CLUB: 'MODERATE_CLUB',
  POST_REPLIES: 'POST_REPLIES',
  VIEW_CLUB_STATUS: 'VIEW_CLUB_STATUS',
  VIEW_CLUB_STATUS_BANNER: 'VIEW_CLUB_STATUS_BANNER',
  VIEW_CLUB_VISIBILITY: 'VIEW_CLUB_VISIBILITY',
  VIEW_MEMBERS_TABLE: 'VIEW_MEMBERS_TABLE',
  VIEW_POSTS: 'VIEW_POSTS',
  VIEW_EVENTS: 'VIEW_EVENTS',
  VIEW_POSTS_FILTERS: 'VIEW_POSTS_FILTERS',
};

export const ClubDotNotationKeys = [ClubKeys.REQUIREMENTS];
