import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { Link } from '../../atoms';
import {
  downloadMobileAppAndroidRoute,
  downloadMobileAppAppleRoute,
} from '../../../constants/routes';
import { ReactComponent as GooglePlayLogo } from '../../../resources/images/download_google_play_logo.svg';
import { ReactComponent as AppStoreLogo } from '../../../resources/images/download_app_store_logo.svg';

const DownloadAppButtonsBlock = () => {
  const { t } = useTranslation();

  return (
    <Box className="ayo-try-mobile__block" display="flex">
      <Link
        className="ayo-try-mobile-informational-message__download-button__app-store"
        gaLabel="Download app from App Store"
        href={downloadMobileAppAppleRoute}
        icon={<AppStoreLogo aria-label={t('Download app from App Store')} role="img" />}
        target="_blank"
      />
      <Link
        className="ayo-try-mobile-informational-message__download-button"
        gaLabel="Download app from Google Play"
        href={downloadMobileAppAndroidRoute}
        target="_blank"
      >
        <GooglePlayLogo aria-label={t('Download app from Google Play')} />
      </Link>
    </Box>
  );
};

export default DownloadAppButtonsBlock;
