import { useState, useEffect, useCallback, useRef } from 'react';
import SockJS from 'sockjs-client';
import RecordRTC, { StereoAudioRecorder } from 'recordrtc';

import { sttRoute } from '../../constants/routes';
import { STTErrorsMap } from '../../constants/enums';

const openSocket = (url) =>
  new Promise((resolve, reject) => {
    const socket = new SockJS(url, null, {
      transports: ['websocket'],
    });

    socket.onopen = () => {
      resolve(socket);
    };

    socket.onclose = (error) => {
      reject(error);
    };
  });

const useSTT = () => {
  const [identifiedText, setIdentifiedText] = useState(new Array(1));

  const [socket, setSocket] = useState();

  const mediaRecorder = useRef();

  const [isRecording, setIsRecording] = useState(false);

  const [analyzer, setAnalyzer] = useState(null);

  const [isStopped, setIsStopped] = useState(false);

  const [error, setError] = useState('');

  const startRecording = useCallback(async () => {
    setIsRecording(true);
    setIdentifiedText(new Array(1));
    setIsStopped(false);
    setError('');
    let connection;
    try {
      connection = await openSocket(sttRoute);

      setSocket(connection);

      connection.onmessage = (message) => {
        const data = JSON.parse(message.data);
        if (data.result) {
          if (data.isFinal) {
            setIdentifiedText((text) => {
              const textCopy = [...text];
              textCopy[textCopy.length - 1] = data.result;
              textCopy.length += 1;
              return textCopy;
            });
          } else {
            setIdentifiedText((text) => {
              const textCopy = [...text];
              textCopy[textCopy.length - 1] = data.result;
              return textCopy;
            });
          }
        }
      };

      connection.onclose = () => {
        if (mediaRecorder.current) {
          mediaRecorder.current.stopRecording();
          mediaRecorder.current.mediaStream.stop();
        }
        setSocket(null);
        setIsRecording(false);
        setAnalyzer(null);
      };
    } catch (e) {
      setError(STTErrorsMap.SERVER_ERROR);
      setIsRecording(false);
      return;
    }

    const audioCtx = new AudioContext();
    const audioAnalyzer = audioCtx.createAnalyser();
    audioAnalyzer.minDecibels = -100;
    audioAnalyzer.maxDecibels = -95;
    audioAnalyzer.smoothingTimeConstant = 0.1;
    audioAnalyzer.fftSize = 256;

    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        const recordAudio = new RecordRTC(stream, {
          type: 'audio',
          mimeType: 'audio/webm',
          sampleRate: 44100,
          desiredSampRate: 16000,
          recorderType: StereoAudioRecorder,
          numberOfAudioChannels: 1,
          timeSlice: 1000,
          ondataavailable: (blob) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => connection.send(reader.result.split(',')[1]);
          },
        });
        recordAudio.startRecording();
        mediaRecorder.current = recordAudio;
        mediaRecorder.current.mediaStream = stream;
        const streamSource = audioCtx.createMediaStreamSource(stream);
        streamSource.connect(audioAnalyzer);
        setAnalyzer(audioAnalyzer);
      })
      .catch((e) => {
        setError(e.name);
        setIsStopped(true);
      });
  }, []);

  const stopRecording = useCallback(() => {
    if (!isRecording) return;
    setIsStopped(true);
    setIsRecording(false);
  }, [isRecording]);

  useEffect(() => {
    if (isStopped && socket) {
      socket.close();
    }
  }, [isStopped, socket]);

  return {
    isRecording,
    analyzer,
    startRecording,
    stopRecording,
    identifiedText: identifiedText.filter(Boolean),
    socket,
    error,
  };
};

export default useSTT;
