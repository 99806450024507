/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from '../../../atoms';
import { GA } from '../../../../utils';
import { GaActions, GaCategories } from '../../../../constants/enums';

const DisabledControlWithTooltip = ({
  children,
  gaLabel,
  title,
  showTooltip,
  wrapperClassName,
  ...rest
}) =>
  showTooltip ? (
    <Tooltip
      enterTouchDelay={0}
      leaveTouchDelay={5000}
      onOpen={(e) => {
        e.stopPropagation();
        if (gaLabel) {
          GA.logInteraction({
            category: GaCategories.BEHAVIOR,
            action: GaActions.ICON_HOVER,
            label: gaLabel,
          });
        }
      }}
      title={title}
      {...rest}
    >
      <div className={wrapperClassName}>{children}</div>
    </Tooltip>
  ) : (
    <>{children}</>
  );

DisabledControlWithTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  gaLabel: PropTypes.string,
  title: PropTypes.string,
  showTooltip: PropTypes.bool,
  wrapperClassName: PropTypes.string,
};

DisabledControlWithTooltip.defaultProps = {
  gaLabel: '',
  title: '',
  showTooltip: false,
  wrapperClassName: null,
};

export default DisabledControlWithTooltip;
