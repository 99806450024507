/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../../atoms';
import { misdAllSchoolsRoute } from '../../../constants/routes';

const StudentConsentButton = ({ ...props }) => {
  const { t } = useTranslation();
  return (
    <Button
      component="a"
      gaLabel="Contact campus"
      href={misdAllSchoolsRoute}
      target="_blank"
      variant="primary"
      {...props}
    >
      {t('Contact campus')}
    </Button>
  );
};

export default StudentConsentButton;
