import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Container, Box, Grid, useTheme, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { Typography } from '../../../../atoms';
import { PopupActionsButtons } from '../../../../moleculas';
import { MoodActivitiesBlock, PageWrapper } from '../../../../organisms';
import { UserContext } from '../../../../../context';
import { useProfileService, useStudentsService } from '../../../../../services';
import { getNormalizedLanguageCode } from '../../../../../utils';
import { moodsRoute, trainAyoRoute } from '../../../../../constants/routes';
import {
  MoodsAndSupportIllustrations,
  MoodRecommendationsPageConfig,
} from '../../../../../constants/moods';
import { AnalyticTypesMap } from '../../../../../constants/enums';

const MoodRecommendationsPage = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const { moodId, mood } = match.params;
  const { state: userState } = useContext(UserContext);
  const [selRecommendations, setSelRecommendations] = useState({});
  const { sendAyoAnalytic } = useProfileService();

  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  const { getSELRecommendations, postSELRecommendationsSelected } = useStudentsService();

  const onSelectedActivitiesHandler = useCallback(
    (selRecommendationId, recommendationDetails) => {
      postSELRecommendationsSelected({
        id: recommendationDetails.id,
        studentId: userState.profile.id,
        studentMoodId: moodId,
        recommendationDetails,
        selRecommendationId,
      }).then(() => sendAyoAnalytic(AnalyticTypesMap.SEL_RECOMMENDATION));
    },
    [moodId, postSELRecommendationsSelected, userState.profile.id, sendAyoAnalytic],
  );

  useEffect(() => {
    if (mood) {
      getSELRecommendations(getNormalizedLanguageCode(i18n.language), mood).then((data) => {
        setSelRecommendations({ [mood]: data });
      });
    }
  }, [getSELRecommendations, i18n.language, mood]);

  const MoodIllustration = MoodsAndSupportIllustrations[mood].illustration;

  return (
    <PageWrapper
      backToLink={trainAyoRoute}
      backToText={t('Go to Train AYO')}
      mainElementClassName="ayo-recommendations-page"
    >
      <Container>
        <Box mb={isWidthUpSm ? 0 : 5}>
          <Typography component="h1" variant="h2">
            {t(MoodRecommendationsPageConfig[mood].title)}
          </Typography>
          <Box mb={5} mt={2}>
            <MoodIllustration
              aria-label={t(MoodsAndSupportIllustrations[mood].alt)}
              className="ayo-recommendations-page__mood-illustration"
            />
          </Box>
          <Grid container>
            <Grid item lg={6} sm={9} xs={12}>
              <Typography variant="body2">
                {t(MoodRecommendationsPageConfig[mood].description)}
              </Typography>
            </Grid>
          </Grid>
          <Box mb={isWidthUpSm ? 5 : 3} mt={3}>
            <MoodActivitiesBlock
              activitiesCards={selRecommendations}
              cardHeadingLevel={2}
              isCurrentMoodActivities
              onCardClick={onSelectedActivitiesHandler}
            />
          </Box>
          <Grid container>
            <Grid item lg={6} sm={9} xs={12}>
              <PopupActionsButtons
                primaryButtonGaLabel="Go to Train AYO"
                primaryButtonHandler={() => history.push(trainAyoRoute)}
                primaryButtonText={t('Go to Train AYO')}
                secondaryButtonGaLabel="Other recommendations"
                secondaryButtonHandler={() => history.push(`${trainAyoRoute}${moodsRoute}`)}
                secondaryButtonText={t('Other recommendations')}
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </PageWrapper>
  );
};

export default MoodRecommendationsPage;
