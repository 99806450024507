import React, { useMemo } from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ClickableCard } from '../../../../../moleculas';
import { QuestionnaireProgressMap } from '../../../../../../constants/questionnaire';
import { ReactComponent as SuccessIcon } from '../../../../../../resources/icons/check_circle.svg';
import { ReactComponent as OutlineSuccessIcon } from '../../../../../../resources/icons/check_circle_outline_green.svg';

const KidsQuestionnaireBlock = ({ dependentProfiles, dependentQuestionnaires, onClick }) => {
  const { t } = useTranslation();

  const sortedDependentProfiles = useMemo(
    () =>
      dependentProfiles.sort(
        (a, b) =>
          dependentQuestionnaires[a.id]?.order.value - dependentQuestionnaires[b.id]?.order.value ||
          dependentQuestionnaires[b.id]?.progress - dependentQuestionnaires[a.id]?.progress,
      ),
    [dependentProfiles, dependentQuestionnaires],
  );

  return sortedDependentProfiles.length ? (
    <Grid container item justifyContent="center">
      <Grid container direction="row" item justifyContent="center" md={9} spacing={2} xs={12}>
        {sortedDependentProfiles.map((item, i) => {
          const dependentQuestionnaire = dependentQuestionnaires[item.id];
          return (
            <Grid key={item.id} item md={4} sm={6} xs={12}>
              <ClickableCard
                gaLabel={`Kid card ${i + 1} out of ${sortedDependentProfiles.length} - ${
                  dependentQuestionnaire?.order.text
                }`}
                onClick={() => onClick(item)}
                progressValue={dependentQuestionnaire?.progress}
                statusIcon={
                  (dependentQuestionnaire?.order === QuestionnaireProgressMap.COMPLETED && (
                    <SuccessIcon height="24px" width="24px" />
                  )) ||
                  (dependentQuestionnaire?.order === QuestionnaireProgressMap.HAS_SKIPPED && (
                    <OutlineSuccessIcon height="24px" width="24px" />
                  ))
                }
                statusText={t(dependentQuestionnaire?.order.text)}
                title={item.firstName}
              />
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  ) : null;
};

KidsQuestionnaireBlock.propTypes = {
  dependentProfiles: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  dependentQuestionnaires: PropTypes.instanceOf(Object).isRequired,
  onClick: PropTypes.func.isRequired,
};

KidsQuestionnaireBlock.defaultProps = {
  dependentProfiles: [],
};

export default KidsQuestionnaireBlock;
