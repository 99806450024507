import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

import { Button, DialogTitle, Typography } from '../../../../../../atoms';
import { TextWithTooltip } from '../../../../../../moleculas';
import { EventDialogModes } from '../../../../../../../constants/events';
import { ReactComponent as NavigationBackChevron } from '../../../../../../../resources/icons/chevron_left.svg';
import EventsActions from '../../../events-actions/EventsActions';

const EventDialogTitle = ({
  isEditable,
  isVisibilitySettingOpen,
  mode,
  name,
  onBackButtonClick,
  onDelete,
  onEdit,
}) => {
  const { t } = useTranslation();

  const titleConfig = useMemo(
    () => ({
      [EventDialogModes.CREATE]: t('Create an event'),
      [EventDialogModes.EDIT]: `${t('Edit')} - ${name}`,
      [EventDialogModes.VIEW]: name,
      [EventDialogModes.VISIBILITY_SETTINGS]: t('Who can see the event'),
    }),
    [name, t],
  );

  return (
    <DialogTitle disableTypography>
      {isVisibilitySettingOpen ? (
        <Box>
          <Button
            className="ayo-back-navigation-link"
            gaLabel="Back to the previous step"
            onClick={onBackButtonClick}
            startIcon={<NavigationBackChevron />}
          >
            {t('Back to previous step')}
          </Button>
          <Typography component="h2" mt={3} variant="subtitle2">
            {t('Who can see the event')}
          </Typography>
        </Box>
      ) : (
        <Box display="flex" flexDirection="row" gap={2} justifyContent="space-between" mt={1}>
          <TextWithTooltip
            headerLevel={2}
            rowsCount={3}
            title={titleConfig[mode]}
            titleVariant="h2"
          />
          {isEditable && mode === EventDialogModes.VIEW && (
            <EventsActions onDelete={onDelete} onEdit={onEdit} />
          )}
        </Box>
      )}
    </DialogTitle>
  );
};

EventDialogTitle.propTypes = {
  isEditable: PropTypes.bool,
  isVisibilitySettingOpen: PropTypes.bool,
  mode: PropTypes.string.isRequired,
  name: PropTypes.string,
  onBackButtonClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

EventDialogTitle.defaultProps = {
  isEditable: false,
  isVisibilitySettingOpen: false,
  name: null,
};

export default EventDialogTitle;
