import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import { Typography } from '../../../../../../../atoms';
import { EventsBlock } from '../../../../../../../organisms';
import { ClubRights } from '../../../../../../../../constants/club-hub';
import { eventsRoute } from '../../../../../../../../constants/routes';
import { EventResourceTypes } from '../../../../../../../../constants/events';
import { useEvents } from '../../../../../../../../hooks';
import { ReactComponent as ClubsEventsEmptyStateOwner } from '../../../../../../../../resources/images/club_events_empty_state_owner.svg';
import { ReactComponent as ClubsEventsEmptyStateVisitor } from '../../../../../../../../resources/images/club_events_empty_state_visitor.svg';
import { NewFeatureHotspot } from '../../../../../../../moleculas';
import { NewFeaturesIdsMap } from '../../../../../../../../tours/common/NewFeaturesItemsProvider';

const EVENTS_PAGE_SIZE = 5;
const EVENTS_DEFAULT_PAGE = 1;
const ClubEventsSection = ({ clubId, clubRights }) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  const location = useLocation();

  const { eventPostHandler, eventDeleteHandler, eventUpdateHandler, events, loadEventsData } =
    useEvents(EventResourceTypes.CLUB_HUB, clubId, EVENTS_PAGE_SIZE);

  useEffect(() => {
    loadEventsData(EVENTS_DEFAULT_PAGE);
  }, [loadEventsData]);

  const isEventCreator = useMemo(() => clubRights[ClubRights.CREATE_EVENTS], [clubRights]);

  return (
    <Box className="ayo-club-hub-details__events-section">
      <Box alignItems="center" display="flex" mb={3}>
        <Typography variant="h2">{t('Upcoming events')}</Typography>
        <NewFeatureHotspot
          id={NewFeaturesIdsMap.CLUB_HUB_EVENTS}
          isClickable
          label={t(isEventCreator ? 'nft.club_events_creation.title' : 'nft.club_events.title')}
        />
      </Box>
      <EventsBlock
        allEventUrl={`${location.pathname}${eventsRoute}`}
        emptyStateConfig={{
          title: isEventCreator
            ? t('There are no upcoming events yet. Feel free to create a new one!')
            : t('No upcoming events available yet'),
          illustration: isEventCreator ? (
            <ClubsEventsEmptyStateOwner />
          ) : (
            <ClubsEventsEmptyStateVisitor />
          ),
        }}
        events={events}
        fullLabel={!isWidthUpSm && isEventCreator}
        isEditable={isEventCreator}
        onCreate={eventPostHandler}
        onDelete={eventDeleteHandler}
        onUpdate={eventUpdateHandler}
        resourceId={clubId}
        resourceType={EventResourceTypes.CLUB_HUB}
      />
    </Box>
  );
};

ClubEventsSection.propTypes = {
  clubId: PropTypes.string.isRequired,
  clubRights: PropTypes.instanceOf(Object).isRequired,
};

export default ClubEventsSection;
