import { AssignmentInsightsCategories } from '../../../../../constants/enums';
import { ReactComponent as ExtracurricularsIllustration } from '../../../../../resources/images/assignment-insights/extracurriculars.svg';
import { ReactComponent as EndorsementsIllustration } from '../../../../../resources/images/assignment-insights/endorsements.svg';
import { ReactComponent as InterestsIllustration } from '../../../../../resources/images/assignment-insights/interests.svg';

export const UNASSIGNED_GROUP_NAME = 'Unassigned';

export const filterInsightCategoryFromUnassigned = (insightCategory) => ({
  ...insightCategory,
  groups: insightCategory.groups.filter(
    (group) => !group.groupNames.find((groupName) => groupName.value === UNASSIGNED_GROUP_NAME),
  ),
});

export const getInsightsWithoutUnassigned = (insights) =>
  insights.map(filterInsightCategoryFromUnassigned);

export const countInsightCategoryGroupsStudents = (groups, studentsFieldName = 'studentIds') =>
  groups.reduce((acc, group) => acc + group[studentsFieldName].length, 0);

export const getInsightCategoriesDistributedStudents = (insights) =>
  getInsightsWithoutUnassigned(insights).reduce(
    (acc, insightCategory) => ({
      ...acc,
      [insightCategory.categoryName]: countInsightCategoryGroupsStudents(insightCategory.groups),
    }),
    {},
  );

export const AssignmentInsightsCategoriesIllustrations = {
  [AssignmentInsightsCategories.INTERESTS]: InterestsIllustration,
  [AssignmentInsightsCategories.EXTRACURRICULARS]: ExtracurricularsIllustration,
  [AssignmentInsightsCategories.ENDORSEMENTS]: EndorsementsIllustration,
};

export const ChoiceOptionsColorVariants = {
  '#F6A962': 'dark',
  '#F9DD6E': 'dark',
  '#96C882': 'dark',
  '#91D5F2': 'dark',
  '#DABFD2': 'dark',
  '#FEF8B8': 'dark',
  '#F9D5D2': 'dark',
  '#EE7C82': 'dark',
  '#4141A5': 'light',
  '#395DF5': 'light',
  '#A84AA4': 'light',
  '#6B6983': 'light',
};
export const convertChoiceColorToShadowColor = (hexColor, opacity = 0.25) => {
  const colorRed = parseInt(hexColor.slice(1, 3), 16);
  const colorGreen = parseInt(hexColor.slice(3, 5), 16);
  const colorBlue = parseInt(hexColor.slice(5, 7), 16);

  return `rgba(${colorRed}, ${colorGreen}, ${colorBlue}, ${opacity})`;
};
