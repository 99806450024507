import { useHistory } from 'react-router-dom';
import { useCallback, useContext, useEffect, useState } from 'react';

import { UserContext } from '../../context';
import { useCmsService } from '../../services';
import { RolesMap, StudentsPage } from '../../constants/enums';
import { campusSelectionRoute, myStudentsRoute } from '../../constants/routes';
import { fillInterpolationValue } from '../string-helpers/stringHelpers';
import useLogin from '../login-helpers/loginHelpers';
import { mergeArrayOfObjectsHelper } from '../array-helpers/arrayHelpers';
import { useProfile } from '../profile-helpers/profileHelpers';

const useLandingPageConfig = (customConfig) => {
  const history = useHistory();
  const { state: userState } = useContext(UserContext);
  const userRole = userState?.profile?.role;
  const { signInHandler } = useLogin();
  const { getBlockConfig } = useCmsService();
  const { getIsCampusSelectionAvailable } = useProfile();

  const [landingPageLoginSectionConfig, setLandingPageLoginSectionConfig] = useState(null);

  const landingPageRedirectHandler = useCallback(
    (loginProvider, loginRedirectKey, loginRedirectUrl) => {
      if (userRole) {
        const currentCampus = userState?.profile?.currentCampus;
        const redirectUrl =
          getIsCampusSelectionAvailable(userState?.profile) && !currentCampus
            ? campusSelectionRoute
            : fillInterpolationValue(loginRedirectUrl, {
                studentsPage: StudentsPage[userRole] || myStudentsRoute,
                userId: userState?.profile?.id,
              });
        if (getIsCampusSelectionAvailable(userState?.profile) && !currentCampus) {
          sessionStorage.setItem(loginRedirectKey, loginRedirectUrl);
        }
        history.push(redirectUrl);
      } else {
        signInHandler(loginProvider, loginRedirectKey, loginRedirectUrl);
      }
    },
    [getIsCampusSelectionAvailable, history, signInHandler, userRole, userState?.profile],
  );

  useEffect(() => {
    getBlockConfig('landing-block').then(({ data }) => {
      const { loginOptions } = data.config;
      const sectionConfig = customConfig
        ? mergeArrayOfObjectsHelper(customConfig, loginOptions, 'userRole')
        : loginOptions;
      const currentUserConfig = () => {
        if (!sectionConfig.some((item) => item.userRole === userRole)) {
          return sectionConfig.filter((item) => item.userRole === RolesMap.TEACHER);
        }
        return sectionConfig.filter((item) => item.userRole === userRole);
      };
      setLandingPageLoginSectionConfig(userRole ? currentUserConfig : sectionConfig);
    });
  }, [getBlockConfig, customConfig, userRole]);

  return {
    landingPageRedirectHandler,
    landingPageLoginSectionConfig,
  };
};

export default useLandingPageConfig;
