/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, DialogActions, DialogContent, Stack, useMediaQuery, useTheme } from '@mui/material';
import classNames from 'classnames';
import AvatarEditor from 'react-avatar-editor';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { Button, Dialog, DialogTitle, Slider, Typography } from '../../atoms';
import {
  DragContent,
  DropContent,
  FileLinkRemovableItem,
  FileListItem,
  InformationalCaption,
  PopupActionsButtons,
} from '../../moleculas';
import { attachmentType, avatarTransformsType } from '../../../constants/propTypes';
import { apiRoute, attachmentsRoute } from '../../../constants/routes';
import { byteToMegaByteCoeff } from '../../../constants/values';
import { KeyboardMap } from '../../../constants/enums';
import { useSnackbar } from '../../../hooks';
import { useAttachmentsService } from '../../../services';
import { focusDialogCloseButton, getFileErrors } from '../../../utils';
import { ReactComponent as PlusIcon } from '../../../resources/icons/plus.svg';
import { ReactComponent as MinusIcon } from '../../../resources/icons/minus.svg';
import { ReactComponent as AttachIcon } from '../../../resources/icons/attach.svg';
import { ReactComponent as CursorMoveIcon } from '../../../resources/icons/cursor_move.svg';

const MAX_FILE_SIZE = 10 * byteToMegaByteCoeff;
const MIN_ZOOM = 1;
const MAX_ZOOM = 2;
const ZOOM_STEP = 0.2;
const KEYBOARD_POSITION_STEP = 0.05;

const SLIDER_MARKS = [
  { value: 1, label: '100%' },
  { value: 1.2, label: '120%' },
  { value: 1.4, label: '140%' },
  { value: 1.6, label: '160%' },
  { value: 1.8, label: '180%' },
  { value: 2, label: '200%' },
];

const AvatarEditDialog = ({
  avatarType,
  currentImage,
  currentTransforms,
  deleteAvatarHandler,
  isOpen,
  onClose,
  onSubmit,
  ownerId,
  resourceId,
  resourceType,
  showGuidelines,
  updateAvatarHandler,
  visibilityInfoCaptionTitle,
}) => {
  const [zoomValue, setZoomValue] = useState(currentTransforms?.zoom ?? MIN_ZOOM);

  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  const [image, setImage] = useState(currentImage);

  const [errors, setErrors] = useState([]);
  const [hasDragged, setHasDragged] = useState(false);

  const [position, setPosition] = useState({
    x: currentTransforms?.x ?? 0.5,
    y: currentTransforms?.y ?? 0.5,
  });

  const { t } = useTranslation();

  const onDrop = (dropped) => {
    const [droppedFile] = dropped;
    setImage(droppedFile);
    const fileErrors = getFileErrors(
      droppedFile,
      { allowedExtensions: ['jpg', 'jpeg', 'png'], maxFileSize: MAX_FILE_SIZE },
      t,
    );
    if (fileErrors.length) {
      setErrors(fileErrors);
      return;
    }
    setErrors([]);
    setPosition({ x: 0.5, y: 0.5 });
    setZoomValue(1);

    focusDialogCloseButton();
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open: openFileBrowser,
  } = useDropzone({
    onDrop,
    noClick: true,
  });

  const { postChunk, patchChunk } = useAttachmentsService();

  const isNewPhoto = image instanceof File;

  const { setSnackBarStatus } = useSnackbar();

  const onUpdateSuccess = useCallback(() => {
    setSnackBarStatus({
      text: t(
        currentImage
          ? 'The changes have been successfully saved.'
          : 'You added your photo successfully.',
      ),
      type: 'success',
    });
  }, [currentImage, setSnackBarStatus, t]);

  const onUpdateFail = useCallback(() => {
    setSnackBarStatus({
      text: t('AYO couldn’t save your edits Please try once more'),
      type: 'error',
    });
  }, [setSnackBarStatus, t]);

  const uploadPhoto = useCallback(async () => {
    let chunkId;
    try {
      const newChunk = await postChunk({ fileName: image.name, fileSize: image.size }, true);
      chunkId = newChunk.id;
    } catch {
      onUpdateFail();
      return null;
    }
    try {
      return await patchChunk(
        chunkId,
        {
          chunk: image,
          range: `0-${image.size}`,
          resourceId,
          resourceType,
        },
        true,
      );
    } catch {
      onUpdateFail();
      return null;
    }
  }, [image, onUpdateFail, patchChunk, postChunk, resourceId, resourceType]);

  const updateAvatar = useCallback(
    (attachment = image) => {
      if (!image && !currentImage) return;
      const requestAvatarUpdate =
        !image && currentImage
          ? () => deleteAvatarHandler()
          : () =>
              updateAvatarHandler({
                avatarImageId: attachment.id,
                transforms: { ...position, zoom: zoomValue },
              });
      requestAvatarUpdate()
        .then(() => {
          onSubmit(attachment, { ...position, zoom: zoomValue });
          onUpdateSuccess();
        })
        .catch(onUpdateFail);
    },
    [
      currentImage,
      deleteAvatarHandler,
      image,
      onSubmit,
      onUpdateFail,
      onUpdateSuccess,
      position,
      updateAvatarHandler,
      zoomValue,
    ],
  );

  const hasErrors = !!errors.length;

  const onPhotoSubmit = useCallback(() => {
    if (isNewPhoto && !hasErrors) {
      uploadPhoto().then(({ fileName, updatedDate, attachmentId }) => {
        updateAvatar({
          fileName,
          id: attachmentId,
          ownerId,
          updatedDate,
        });
      });
    } else {
      updateAvatar(hasErrors ? null : image);
    }
    onClose();
  }, [hasErrors, image, isNewPhoto, onClose, ownerId, updateAvatar, uploadPhoto]);

  useEffect(() => {
    setHasDragged(false);
    setImage(currentImage);
    setZoomValue(currentTransforms?.zoom ?? MIN_ZOOM);
    setPosition({ x: currentTransforms?.x ?? 0.5, y: currentTransforms?.y ?? 0.5 });
  }, [currentImage, currentTransforms, isOpen]);

  const dropContent = <DropContent avatarType={avatarType} />;

  const onImageRemove = () => setImage(null);

  const fileItem = isNewPhoto ? (
    <FileListItem item={{ file: image, errors }} onRemove={onImageRemove} />
  ) : (
    <FileLinkRemovableItem
      className="uploaded-file"
      createdLabel={t('Added')}
      item={image}
      onRemoveClick={onImageRemove}
      type="file"
      withIcon={false}
    />
  );

  const visibilityInfoCaption = (
    <Box mt={3}>
      <InformationalCaption title={t(visibilityInfoCaptionTitle)} />
    </Box>
  );

  const editorRef = useRef();

  const mainContent = (
    <>
      {(!image || hasErrors) && (
        <>
          {showGuidelines && (
            <Box className="guidelines-zone">
              <Box>
                <Typography component="h3" mb={1} variant="subtitle2">
                  {t('Guidelines')}
                </Typography>
              </Box>
              <Box>
                <ul>
                  <li>
                    {t('Photos should be appropriate and not contain any offensive material.')}
                  </li>
                  <li>{t('Photos should be of you and not include any other people.')}</li>
                  <li>{t("Photos shouldn't be overly edited or altered.")}</li>
                </ul>
              </Box>
            </Box>
          )}
          <Box>
            <Box mb={1}>
              <Typography component="h3" variant="subtitle2">
                {t('Upload your photo')}
              </Typography>
            </Box>
            <Box mb={2}>
              <Typography isLabel variant="body2">
                {t('Max. 1 attachment up to 10 MB in JPG, JPEG and PNG formats.')}
              </Typography>
            </Box>
            <Box>
              {!isWidthUpSm ? (
                <Button
                  className="drop-zone__open-button"
                  endIcon={<AttachIcon />}
                  gaLabel={`${avatarType} avatar - Add an attachment`}
                  onClick={openFileBrowser}
                  variant="text"
                >
                  {t('Add an attachment')}
                </Button>
              ) : (
                <DragContent
                  className="large-screen-content"
                  openFileBrowserHandler={openFileBrowser}
                />
              )}
            </Box>
            {image && <Box mt={1}>{fileItem}</Box>}
            {visibilityInfoCaption}
          </Box>
        </>
      )}
      {image && !hasErrors && (
        <>
          <Box display="flex" justifyContent="center">
            <AvatarEditor
              ref={editorRef}
              borderRadius={250}
              className="avatar-editor"
              color={[255, 255, 255, 0.8]}
              height={250}
              image={
                isNewPhoto
                  ? image
                  : `${apiRoute}${attachmentsRoute}/${image.id}/owners/${image.ownerId}`
              }
              onKeyDown={(e) => {
                if (e.key !== KeyboardMap.TAB) {
                  e.preventDefault();
                  const { x, y, width, height } = editorRef.current.getCroppingRect();
                  let { x: newX, y: newY } = position;
                  const yEndAvailableSpace = 1 - height - y;
                  const yStartAvailableSpace = y;
                  const xEndAvailableSpace = 1 - width - x;
                  const xStartAvailableSpace = x;
                  const zoomedStep = KEYBOARD_POSITION_STEP / zoomValue;
                  if (e.key === KeyboardMap.ARROW_DOWN) {
                    newY += Math.min(zoomedStep, yEndAvailableSpace);
                  }
                  if (e.key === KeyboardMap.ARROW_UP) {
                    newY -= Math.min(zoomedStep, yStartAvailableSpace);
                  }
                  if (e.key === KeyboardMap.ARROW_LEFT) {
                    newX -= Math.min(zoomedStep, xStartAvailableSpace);
                  }
                  if (e.key === KeyboardMap.ARROW_RIGHT) {
                    newX += Math.min(zoomedStep, xEndAvailableSpace);
                  }
                  if (newX === position.x && newY === position.y) return;
                  setPosition({ x: newX, y: newY });
                }
              }}
              onMouseMove={() => setHasDragged(true)}
              onPositionChange={(newPosition) => setPosition(newPosition)}
              position={position}
              scale={zoomValue}
              tabIndex="0"
              width={250}
            />
            {!hasDragged && (
              <div className="reposition-instruction">
                <CursorMoveIcon />
                <Typography variant="body3">{t('Drag to reposition')}</Typography>
              </div>
            )}
          </Box>
          <Stack alignItems="center" direction="horizontal" gap={2} mb={4} mt={2}>
            <Button
              aria-label={t('Zoom Out')}
              className="control-button"
              gaLabel={`${avatarType} avatar - Minus zoom`}
              isIconButton
              onClick={() => {
                if (zoomValue > MIN_ZOOM) {
                  setZoomValue(parseFloat((zoomValue - ZOOM_STEP).toFixed(1)));
                }
              }}
            >
              <MinusIcon />
            </Button>
            <Slider
              ariaMarks={SLIDER_MARKS}
              label={t('Zoom')}
              marks
              max={MAX_ZOOM}
              min={MIN_ZOOM}
              onChange={(e, newZoom) => setZoomValue(newZoom)}
              step={ZOOM_STEP}
              value={zoomValue}
            />
            <Button
              aria-label={t('Zoom In')}
              className="control-button"
              gaLabel={`${avatarType} avatar - Plus zoom`}
              isIconButton
              onClick={() => {
                if (zoomValue < MAX_ZOOM) {
                  setZoomValue(parseFloat((zoomValue + ZOOM_STEP).toFixed(1)));
                }
              }}
            >
              <PlusIcon />
            </Button>
          </Stack>
          <Box mb={1}>{fileItem}</Box>
          <Box mb={1}>
            <Button
              endIcon={<AttachIcon />}
              gaLabel={`${avatarType} avatar - Change photo`}
              onClick={openFileBrowser}
            >
              {t('Change photo')}
            </Button>
          </Box>
          {visibilityInfoCaption}
        </>
      )}
    </>
  );

  return (
    <Dialog
      className="avatar-dialog"
      gaLabel={`Edit ${avatarType} avatar`}
      maxWidth={700}
      onClose={onClose}
      open={isOpen}
    >
      <DialogTitle disableTypography>
        <Box mb={3}>
          <Typography component="h2" variant="subtitle1">
            {currentImage ? t('Edit photo') : t('Add a photo')}
          </Typography>
        </Box>
      </DialogTitle>
      <div
        {...getRootProps()}
        className={classNames('avatar-dialog__main-zone', { 'drop-active': isDragActive })}
        tabIndex={null}
      >
        {isDragActive && dropContent}
        <DialogContent>
          <input {...getInputProps()} />
          {mainContent}
        </DialogContent>
        <DialogActions>
          <PopupActionsButtons
            primaryButtonGaLabel={`${avatarType} avatar - Submit`}
            primaryButtonHandler={onPhotoSubmit}
            primaryButtonText={t(currentImage ? 'Save changes' : 'Save')}
            secondaryButtonGaLabel={`${avatarType} avatar - Cancel`}
            secondaryButtonHandler={onClose}
            secondaryButtonText={t('Cancel')}
          />
        </DialogActions>
      </div>
    </Dialog>
  );
};

AvatarEditDialog.propTypes = {
  avatarType: PropTypes.string.isRequired,
  currentImage: PropTypes.shape(attachmentType),
  currentTransforms: PropTypes.shape(avatarTransformsType),
  deleteAvatarHandler: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  ownerId: PropTypes.number.isRequired,
  resourceId: PropTypes.number.isRequired,
  resourceType: PropTypes.string.isRequired,
  showGuidelines: PropTypes.bool.isRequired,
  updateAvatarHandler: PropTypes.func.isRequired,
  visibilityInfoCaptionTitle: PropTypes.string.isRequired,
};

AvatarEditDialog.defaultProps = {
  currentImage: null,
  currentTransforms: null,
};
export default AvatarEditDialog;
