import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

import {
  HighlightsInitiatives,
  SingleBarVariants,
  StudentFiltersSpecialValues,
} from '../../../../constants/enums';
import HighlightsBarChart from '../components/highlights-bar-chart/HighlightsBarChart';

const AptitudesHighlights = ({ stats, totalStudentsCount, onFiltersUpdate }) => (
  <Box display="flex" height={350}>
    <HighlightsBarChart
      gaLabel="Aptitudes Highlights Graph"
      initiative={HighlightsInitiatives.APTITUDES}
      mainBarVariant={SingleBarVariants.TIGHT}
      mainChartColor="#F4752D"
      onFiltersUpdate={onFiltersUpdate}
      pendingChartColor="#F5D3BF"
      pendingKey={StudentFiltersSpecialValues.NOT_DISCOVERED}
      shouldSortMainStats
      stats={stats}
      totalStudentsCount={totalStudentsCount}
    />
  </Box>
);

AptitudesHighlights.propTypes = {
  stats: PropTypes.instanceOf(Object).isRequired,
  totalStudentsCount: PropTypes.number.isRequired,
  onFiltersUpdate: PropTypes.func.isRequired,
};

export default AptitudesHighlights;
