import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Container, useMediaQuery, useTheme } from '@mui/material';

import { Typography } from '../../../../../atoms';

const AptitudesPresentationSection = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });

  return (
    <Box className="ayo-aptitudes-presentation-section">
      <Container>
        <Box
          alignItems="center"
          className="ayo-aptitudes-presentation-section__content"
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Typography component="h1" paragraph variant="h1">
            {t('Aptitudes')}
          </Typography>
          <Typography align="center" component="p" variant="subtitle1">
            {t('Work together with AYO to find out what you’re good at!')}
          </Typography>
          <Box mb={5} mt={4}>
            <img
              alt={t('Assets aptitudes presentation section alt')}
              src={t(
                isWidthUpSm
                  ? 'Assets aptitudes presentation section desktop illustration'
                  : 'Assets aptitudes presentation section mobile illustration',
              )}
            />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default AptitudesPresentationSection;
