import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';
import PropTypes from 'prop-types';

import { Typography } from '../../../../../atoms';
import { EditingButton } from '../../../../../moleculas';
import { clubHubProfileDetailsRoute, familyFeedRoute } from '../../../../../../constants/routes';
import { clubMetadataType } from '../../../../../../constants/propTypes';
import { ClubsMembershipStatuses } from '../../../../../../constants/club-hub';
import { RolesMap, StudentsPage } from '../../../../../../constants/enums';
import { UserContext } from '../../../../../../context';
import { ReactComponent as ExpandIcon } from '../../../../../../resources/icons/chevron_down.svg';
import { ReactComponent as ColspanIcon } from '../../../../../../resources/icons/chevron_up.svg';
import ClubHubCard from '../../../club-card/ClubHubCard';

const ClubsList = ({ clubs, marginBottom, studentId, title }) => {
  const { t } = useTranslation();

  const { state: userState } = useContext(UserContext);
  const { role } = userState.profile;

  return (
    <Box mb={marginBottom}>
      <Typography mb={2} variant="subtitle2">
        {t(title)}
      </Typography>
      <Grid container item spacing={3}>
        {clubs.map(({ id, clubName, attachments, membersCount, membershipStatus }) => (
          <Grid key={id} item md={4} sm={6} xs={12}>
            <ClubHubCard
              clubName={clubName}
              imageInfo={attachments?.[0]}
              joined={membershipStatus === ClubsMembershipStatuses.JOINED}
              membersCount={membersCount}
              redirectUrl={`${
                role === RolesMap.GUARDIAN ? familyFeedRoute : StudentsPage[role]
              }/${studentId}${clubHubProfileDetailsRoute}/${id}`}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

ClubsList.propTypes = {
  clubs: PropTypes.arrayOf(PropTypes.shape(clubMetadataType)).isRequired,
  marginBottom: PropTypes.number.isRequired,
  studentId: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
};

const ClubsCardsBlocks = ({ joinedClubs, requestedClubs, studentId }) => {
  const { t } = useTranslation();
  const [isRequestedClubsVisible, setIsRequestedClubsVisible] = useState(false);

  useEffect(() => {
    setIsRequestedClubsVisible(!joinedClubs.length && !!requestedClubs.length);
  }, [joinedClubs.length, requestedClubs.length]);

  return (
    <Box>
      {!!joinedClubs.length && (
        <ClubsList
          clubs={joinedClubs}
          marginBottom={requestedClubs.length ? (isRequestedClubsVisible ? 4 : 2) : 0}
          studentId={studentId}
          title="Membership"
        />
      )}
      {isRequestedClubsVisible && (
        <ClubsList
          clubs={requestedClubs}
          marginBottom={joinedClubs.length ? 2 : 0}
          studentId={studentId}
          title="Applied / Requested to join"
        />
      )}
      {!!joinedClubs.length && !!requestedClubs.length && (
        <EditingButton
          gaLabel={isRequestedClubsVisible ? 'Show less' : 'Show more'}
          icon={isRequestedClubsVisible ? <ColspanIcon /> : <ExpandIcon />}
          iconPosition="end"
          onClick={() => setIsRequestedClubsVisible((prevState) => !prevState)}
          text={t(isRequestedClubsVisible ? 'Show less' : 'Show more')}
        />
      )}
    </Box>
  );
};

ClubsCardsBlocks.propTypes = {
  joinedClubs: PropTypes.arrayOf(PropTypes.shape(clubMetadataType)).isRequired,
  requestedClubs: PropTypes.arrayOf(PropTypes.shape(clubMetadataType)).isRequired,
  studentId: PropTypes.number.isRequired,
};

export default ClubsCardsBlocks;
