import React, { useCallback, useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button } from '../../../atoms';
import { PageIllustrationBlock, ScreenCenteredContainer } from '../../../moleculas';
import { PageWrapper } from '../../../organisms';
import { UserContext } from '../../../../context';
import { rootRoute } from '../../../../constants/routes';
import { RolesMainPages } from '../../../../constants/pages';
import { formatSeconds } from '../../../../utils';
import { ReactComponent as ErrorTimeout } from '../../../../resources/images/error_timeout.svg';

const TIME_LEFT = 60;
let timer;

const clearTimer = () => {
  clearInterval(timer);
  timer = null;
};

const TimeoutErrorPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { state: userState } = useContext(UserContext);
  const [countdown, setCountdown] = useState(TIME_LEFT);

  const reloadPrevPageHandler = useCallback(() => {
    if (history.length > 2) {
      history.goBack();
    } else if (userState.profile) {
      history.push(RolesMainPages[userState.profile.role]);
    } else {
      history.push(rootRoute);
    }
  }, [history, userState.profile]);

  useEffect(() => {
    if (countdown === TIME_LEFT) {
      timer = setInterval(() => {
        setCountdown((time) => time - 1);
      }, 1000);
    }
    if (timer && countdown < 1) {
      clearTimer();
      reloadPrevPageHandler();
    }
  }, [countdown, reloadPrevPageHandler]);

  return (
    <PageWrapper noNav>
      <ScreenCenteredContainer>
        <PageIllustrationBlock
          Illustration={ErrorTimeout}
          illustrationAriaLabel={t('A man in the fishing boat catching an old boot')}
          mainHeaderText={t('Oops! Something went not as planned')}
          secondaryHeaderText={[
            t('It’s not you, it’s us'),
            t('Please reload the page or just wait for a moment'),
          ]}
        />
        <Button gaLabel="Refresh Page" isAdaptive onClick={reloadPrevPageHandler} variant="primary">
          {t('Reload the page')} {formatSeconds(countdown)}
        </Button>
      </ScreenCenteredContainer>
    </PageWrapper>
  );
};

export default TimeoutErrorPage;
