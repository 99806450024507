import React from 'react';

import {
  OneTimeActionsMap,
  StudentActivityCriterions,
  HighlightsInitiatives,
} from '../../../../../constants/enums';
import { goalsRoute } from '../../../../../constants/routes';
import TrainAyoStudentSelectionPage from '../components/train-ayo-student-selection/TrainAyoStudentSelectionPage';

const GoalsStudentSelectionPage = () => (
  <TrainAyoStudentSelectionPage
    activityCriterion={StudentActivityCriterions.GOAL}
    highlightsValue={HighlightsInitiatives.GOALS}
    initiativeRoute={goalsRoute}
    notificationAction={OneTimeActionsMap.ACTIVITY_GOALS_NOTIFICATION}
    notificationText="A green ribbon means there’s information about the goals in the profile"
    progressAction={OneTimeActionsMap.EXPLORE_GOALS_OPEN}
    relatedActivity="Goals"
    secondaryText="Please select a student or search for one to create a goal"
    title="Goals"
  />
);

export default GoalsStudentSelectionPage;
