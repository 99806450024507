import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

import { Image } from '../../atoms';
import { MoodsActivitiesIllustrationsByTitle } from '../../../constants/moods';

const MoodActivityIllustration = ({ activityKey, activity, isImageType }) => {
  const { t } = useTranslation();
  const Illustration = MoodsActivitiesIllustrationsByTitle[activityKey]?.image;
  const illustrationAriaLabel = MoodsActivitiesIllustrationsByTitle[activityKey]?.text;
  return (
    <Box>
      {isImageType ? (
        <Image
          alt={activity?.alt}
          className="ayo-svg-illustration ayo-main-illustration"
          src={activity.imageUrl}
        />
      ) : (
        <Illustration
          aria-label={t(illustrationAriaLabel)}
          className="ayo-sel-activities__illustration"
          role="img"
        />
      )}
    </Box>
  );
};

MoodActivityIllustration.propTypes = {
  activityKey: PropTypes.string.isRequired,
  activity: PropTypes.instanceOf(Object),
  isImageType: PropTypes.bool,
};

MoodActivityIllustration.defaultProps = {
  activity: {},
  isImageType: false,
};

export default MoodActivityIllustration;
