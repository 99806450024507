import React from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { LanguagesMap } from '../../../constants/enums';
import { ReactComponent as DayOffIllustration } from '../../../resources/images/day_off.svg';
import { ReactComponent as DayOffIllustrationSpanish } from '../../../resources/images/day_off_es.svg';
import { ReactComponent as HolidayIllustration } from '../../../resources/images/holiday.svg';
import { ReactComponent as HolidayIllustrationSpanish } from '../../../resources/images/holiday_es.svg';
import { ReactComponent as NoItemsToReviewIllustration } from '../../../resources/images/no_items_to_review_illustration.svg';
import { ReactComponent as NoItemsToReviewIllustrationSpanish } from '../../../resources/images/no_items_to_review_illustration_sp.svg';

const DayOffIllustrations = {
  teacher: {
    [LanguagesMap.EN_US]: DayOffIllustration,
    [LanguagesMap.ES_US]: DayOffIllustrationSpanish,
  },
  student: {
    [LanguagesMap.EN_US]: NoItemsToReviewIllustration,
    [LanguagesMap.ES_US]: NoItemsToReviewIllustrationSpanish,
  },
};

const HolidayIllustrations = {
  [LanguagesMap.EN_US]: HolidayIllustration,
  [LanguagesMap.ES_US]: HolidayIllustrationSpanish,
};

const NonWorkingDayWrapper = ({ children, className }) => (
  <Box className={classNames('non-working-day__item', className)}>{children}</Box>
);

NonWorkingDayWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

NonWorkingDayWrapper.defaultProps = {
  className: '',
};

export const DayOffTeacher = () => {
  const { t, i18n } = useTranslation();
  const LocalizedDayOffIllustration = DayOffIllustrations.teacher[i18n.language];
  return (
    <NonWorkingDayWrapper>
      <LocalizedDayOffIllustration aria-label={t('Day off')} />
    </NonWorkingDayWrapper>
  );
};

export const DayOffStudent = () => {
  const { t, i18n } = useTranslation();
  const LocalizedDayOffIllustration = DayOffIllustrations.student[i18n.language];
  return (
    <NonWorkingDayWrapper className="non-working-day__item--student">
      <LocalizedDayOffIllustration aria-label={t('No items to review')} className="icon" />
    </NonWorkingDayWrapper>
  );
};

export const Holiday = () => {
  const { t, i18n } = useTranslation();
  const LocalizedHolidayIllustration = HolidayIllustrations[i18n.language];
  return (
    <NonWorkingDayWrapper>
      <LocalizedHolidayIllustration aria-label={t('Holiday')} />
    </NonWorkingDayWrapper>
  );
};
