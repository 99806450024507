import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';

import { Button } from '../../../../../../../../../atoms';
import { ClubsMembershipStatuses } from '../../../../../../../../../../constants/club-hub';
import { useClubHubService } from '../../../../../../../../../../services';
import { useSnackbar } from '../../../../../../../../../../hooks';
import { ReactComponent as ActiveIcon } from '../../../../../../../../../../resources/icons/check_circle_green.svg';
import { ReactComponent as DeclineIcon } from '../../../../../../../../../../resources/icons/close_red.svg';
import ClubDataGridActions from '../../../../../../../club-hub-components/club-data-grid-actions/ClubDataGridActions';
import ClubsDeactivationDialog from '../../../../../../../club-hub-components/clubs-deactivation-dialog/ClubsDeactivationDialog';

const MembershipStatusSnackBars = {
  [ClubsMembershipStatuses.JOINED]: {
    [ClubsMembershipStatuses.PENDING_REQUEST]: {
      success: 'The access request has been approved.',
      error: 'AYO couldn’t approve the access request. Please try once more.',
    },
    [ClubsMembershipStatuses.REQUEST_REJECTED]: {
      success: 'The access to join the club has been given.',
      error: 'AYO couldn’t give access to join the club. Please try once more.',
    },
  },
  [ClubsMembershipStatuses.REQUEST_REJECTED]: {
    [ClubsMembershipStatuses.PENDING_REQUEST]: {
      success:
        'The request for membership has been rejected, and the reason for it has been sent to the student.',
      error: 'AYO couldn’t reject the request for membership. Please try once more.',
    },
    [ClubsMembershipStatuses.JOINED]: {
      success:
        'The access to the club has been rejected, and the reason for it has been sent to the student.',
      error: 'AYO couldn’t reject the access to the club. Please try once more.',
    },
  },
};

const ClubMembersTableActions = ({
  clubId,
  clubName,
  membershipStatus,
  onStatusChange,
  studentId,
  studentName,
}) => {
  const { t } = useTranslation();

  const [isDeactivationDialogOpen, setIsDeactivationDialogOpen] = useState(false);

  const { setSnackBarStatus } = useSnackbar();

  const { updateClubMembershipStatus } = useClubHubService();

  const membershipStatusChangeHandler = useCallback(
    (newStatus, reason = null) => {
      updateClubMembershipStatus(clubId, studentId, newStatus, reason)
        .then(() => {
          setSnackBarStatus({
            text: t(MembershipStatusSnackBars[newStatus][membershipStatus].success),
            type: 'success',
          });
          onStatusChange();
        })
        .catch(() => {
          setSnackBarStatus({
            text: t(MembershipStatusSnackBars[newStatus][membershipStatus].error),
            type: 'error',
            action: (
              <Button
                gaLabel="Retry"
                onClick={() => membershipStatusChangeHandler(newStatus, reason)}
              >
                {t('Retry')}
              </Button>
            ),
          });
        });
    },
    [
      clubId,
      membershipStatus,
      onStatusChange,
      setSnackBarStatus,
      studentId,
      t,
      updateClubMembershipStatus,
    ],
  );

  const MembersActionsConfig = useMemo(
    () => ({
      [ClubsMembershipStatuses.JOINED]: {
        id: uuidv4(),
        label: 'Reject access',
        newStatus: ClubsMembershipStatuses.REQUEST_REJECTED,
        icon: DeclineIcon,
        handler: () => setIsDeactivationDialogOpen(true),
      },
      [ClubsMembershipStatuses.REQUEST_REJECTED]: {
        id: uuidv4(),
        label: 'Give access',
        newStatus: ClubsMembershipStatuses.JOINED,
        icon: ActiveIcon,
        handler: () => membershipStatusChangeHandler(ClubsMembershipStatuses.JOINED),
      },
      [ClubsMembershipStatuses.PENDING_REQUEST]: {
        isPending: true,
        activeButton: {
          label: 'Approve',
          newStatus: ClubsMembershipStatuses.JOINED,
          handler: () => membershipStatusChangeHandler(ClubsMembershipStatuses.JOINED),
        },
        declinedButton: {
          label: 'Reject',
          newStatus: ClubsMembershipStatuses.REQUEST_REJECTED,
          handler: () => setIsDeactivationDialogOpen(true),
        },
      },
    }),
    [membershipStatusChangeHandler],
  );

  return (
    <>
      <ClubDataGridActions config={MembersActionsConfig[membershipStatus]} />
      <ClubsDeactivationDialog
        clubName={clubName}
        isOpen={isDeactivationDialogOpen}
        onClose={() => setIsDeactivationDialogOpen(false)}
        onSubmit={(reason, newStatus) => membershipStatusChangeHandler(newStatus, reason)}
        status={ClubsMembershipStatuses.REQUEST_REJECTED}
        studentName={studentName}
      />
    </>
  );
};

ClubMembersTableActions.propTypes = {
  clubId: PropTypes.number.isRequired,
  clubName: PropTypes.string.isRequired,
  membershipStatus: PropTypes.string.isRequired,
  onStatusChange: PropTypes.func.isRequired,
  studentId: PropTypes.number.isRequired,
  studentName: PropTypes.string.isRequired,
};

export default ClubMembersTableActions;
