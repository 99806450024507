import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Tooltip } from '../../../../atoms';

const NavItemTooltipWrapper = ({ children, isVisible, itemText }) => {
  const { t } = useTranslation();

  return (
    <Tooltip
      aria-label={`${itemText}${isVisible ? t('You have new updates') : ''}`}
      enterTouchDelay={200}
      placement="right"
      title={isVisible ? t('You have new updates') : ''}
    >
      {children}
    </Tooltip>
  );
};

NavItemTooltipWrapper.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
  itemText: PropTypes.string.isRequired,
  isVisible: PropTypes.bool,
};

NavItemTooltipWrapper.defaultProps = {
  isVisible: false,
};

export default NavItemTooltipWrapper;
