import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Label } from 'recharts';

import { Tooltip } from '../../../../atoms';

const TooltipedLabel = ({ content, totalStudentsCount, onClick, getTooltipText, ...props }) => {
  const { t } = useTranslation();
  return (
    <Tooltip
      enterTouchDelay={0}
      leaveTouchDelay={5000}
      placement="right"
      title={getTooltipText(props, totalStudentsCount, t)}
    >
      <g aria-hidden="true">
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Label {...props} onClick={() => onClick(props.name)} />
      </g>
    </Tooltip>
  );
};

TooltipedLabel.propTypes = {
  content: PropTypes.element.isRequired,
  totalStudentsCount: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  getTooltipText: PropTypes.func.isRequired,
};

export default TooltipedLabel;
