import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ReactComponent as LokedBadgeImage } from '../../../../../resources/images/badges/locked_badge.svg';

const BadgesImage = ({ badgeData }) => {
  const { t } = useTranslation();
  const DefaultBadgeImage = badgeData.image;

  return badgeData.createdDate ? (
    <DefaultBadgeImage aria-label={t('Achieved badge: badgeAlt', { badgeAlt: t(badgeData.alt) })} />
  ) : (
    <LokedBadgeImage aria-label={t('Unchieved badge: Lock')} />
  );
};

BadgesImage.propTypes = {
  badgeData: PropTypes.instanceOf(Object).isRequired,
};

export default BadgesImage;
