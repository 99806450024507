import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

import { AppActions, AppContext, SharedDataActions, SharedDataContext } from '../../context';
import { useEvidencesService, useStudentsService } from '../../services';
import { StatusCodeMap } from '../../services/HttpClient';
import { AttachmentsResourcesTypes, LeadershipAttributesStatuses } from '../../constants/enums';
import { useEvidencesData, useLeadershipAttributesData } from '../../hooks';

const removeKey = (key, { [key]: _, ...rest }) => rest;

const useEvidenceHelpers = (studentId, evidenceType) => {
  const { t } = useTranslation();
  const match = useRouteMatch();
  const { state: sharedDataState, dispatch: dispatchSharedData } = useContext(SharedDataContext);
  const { dispatch: dispatchAppState } = useContext(AppContext);
  const { deleteEvidence } = useEvidencesService();
  const allEvidences = sharedDataState.evidencesOfGrowth;
  const { leadershipAttributes, updateLeadershipAttributeEntity } =
    useLeadershipAttributesData(studentId);
  const { loadEvidences } = useEvidencesData(studentId, evidenceType, { skipLoadData: true });
  const { getStudentGoalDetails } = useStudentsService();

  const { goalId } = match.params;

  const updateRelatedGoal = useCallback(
    (evidenceId) => {
      if (evidenceType === AttachmentsResourcesTypes.GOAL && goalId) {
        getStudentGoalDetails(studentId, goalId).then((goal) => {
          if (!evidenceId) {
            dispatchSharedData({
              type: SharedDataActions.SET_RELATED_TO_EVIDENCE_GOAL,
              data: goal,
            });
          } else {
            dispatchSharedData({
              type: SharedDataActions.SET_RELATED_TO_EVIDENCE_GOAL,
              data: {
                ...goal,
                milestones: goal.milestones.map((milestone) => ({
                  ...milestone,
                  evidenceIds: milestone.evidenceIds?.filter((evidence) => evidence !== evidenceId),
                })),
              },
            });
          }
        });
      }
    },
    [dispatchSharedData, evidenceType, getStudentGoalDetails, goalId, studentId],
  );

  const evidenceChangeHandler = useCallback(
    (evidenceData, parentId) => {
      const assessmentKey =
        evidenceData.evidenceResourcesByType[AttachmentsResourcesTypes.LEADERSHIP_ATTRIBUTE]
          ?.parentResourceId;
      const goalResourceId =
        evidenceData.evidenceResourcesByType[AttachmentsResourcesTypes.GOAL]?.parentResourceId;
      const shouldUpdate = allEvidences.some(
        (el) =>
          el.evidenceResourcesByType[AttachmentsResourcesTypes.GOAL]?.parentResourceId ===
            goalResourceId &&
          el.evidenceResourcesByType[AttachmentsResourcesTypes.LEADERSHIP_ATTRIBUTE]
            ?.parentResourceId !== assessmentKey,
      );

      if (parentId) {
        const updatedEvidences = allEvidences.filter((evidence) => evidence.id !== parentId);
        dispatchSharedData({
          type: SharedDataActions.SET_EVIDENCES_OF_GROWTH,
          data: [evidenceData, ...updatedEvidences],
        });
      } else {
        dispatchSharedData({
          type: SharedDataActions.SET_EVIDENCES_OF_GROWTH,
          data: [evidenceData, ...allEvidences],
        });

        if (assessmentKey) {
          const currentLeadershipAttribute = leadershipAttributes.find(
            (attribute) => attribute.key === assessmentKey,
          );
          currentLeadershipAttribute.status = LeadershipAttributesStatuses.ONGOING;
          updateLeadershipAttributeEntity(
            'leadershipAttributes',
            currentLeadershipAttribute,
            'key',
          );
        }
      }
      dispatchAppState({
        type: AppActions.SET_SNACKBAR_STATUS,
        data: {
          text: t(
            parentId
              ? 'Thanks for your changes! Your input has been saved'
              : 'Thanks for your contribution! Your input has been saved',
          ),
          type: 'success',
        },
      });
      updateRelatedGoal();
      if (shouldUpdate) {
        loadEvidences();
      }
    },
    [
      allEvidences,
      dispatchAppState,
      t,
      updateRelatedGoal,
      dispatchSharedData,
      leadershipAttributes,
      updateLeadershipAttributeEntity,
      loadEvidences,
    ],
  );

  const evidenceDeleteHandler = useCallback(
    (evidenceId) => {
      deleteEvidence(evidenceId, studentId, evidenceType)
        .then((response) => {
          if (response.status === StatusCodeMap.NO_CONTENT) {
            const currentEvidence = allEvidences.find((evidence) => evidence.id === evidenceId);
            const updatedEvidences = [
              ...allEvidences.filter((evidence) => evidence.id !== evidenceId),
              {
                ...currentEvidence,

                evidenceResourcesByType: removeKey(
                  evidenceType,
                  currentEvidence.evidenceResourcesByType,
                ),
              },
            ];
            dispatchSharedData({
              type: SharedDataActions.SET_EVIDENCES_OF_GROWTH,
              data: updatedEvidences,
            });
            dispatchAppState({
              type: AppActions.SET_SNACKBAR_STATUS,
              data: {
                text: t('The evidence of growth has been deleted'),
                type: 'success',
              },
            });
          }
        })
        .catch(() => {
          dispatchAppState({
            type: AppActions.SET_SNACKBAR_STATUS,
            data: {
              text: t('AYO couldn’t save your changes Please try once more'),
              type: 'error',
            },
          });
        });
      updateRelatedGoal(evidenceId);
    },
    [
      allEvidences,
      deleteEvidence,
      dispatchAppState,
      dispatchSharedData,
      evidenceType,
      studentId,
      t,
      updateRelatedGoal,
    ],
  );

  return {
    evidenceChangeHandler,
    evidenceDeleteHandler,
  };
};

export default useEvidenceHelpers;
