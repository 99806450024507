import { useReducer } from 'react';

import {
  FilterActions,
  HighlightsInitiatives,
  StudentFiltersSpecialValues,
} from '../../constants/enums';
import { getFilterValueParts, isSimpleFilterSelected, StudentFiltersStrategy } from '../../utils';

export const FilterUpdateActions = {
  ADD_GENERIC: 'ADD_GENERIC',
  CLEAR_ALL: 'CLEAR_ALL',
  CLEAR: 'CLEAR',
  UPDATE: 'UPDATE',
};

const reducer = (state, action) => {
  switch (action.type) {
    case FilterUpdateActions.ADD_GENERIC:
      return { action: FilterActions.ADD, value: null };
    case FilterUpdateActions.CLEAR_ALL:
      return { action: FilterActions.CLEAR, value: null };
    case FilterUpdateActions.CLEAR:
      return { action: FilterActions.CLEAR, value: action.data };
    case FilterUpdateActions.UPDATE: {
      const { filters, value, criterion, category, stats, t } = action.data;
      const { filterCriterion, filtersList } = getFilterValueParts(filters, stats);
      const initiativeStrategy = StudentFiltersStrategy[category];
      const displayName = initiativeStrategy.getFilterDisplayName(
        t(`analytics.highlights.${criterion}`),
        Object.values(StudentFiltersSpecialValues).includes(value)
          ? t(`analytics.highlights.${value}`)
          : t(value),
        initiativeStrategy.getStatValue(stats, value, criterion),
        stats,
        t,
      );
      let filterAction = FilterActions.ADD;
      if (
        isSimpleFilterSelected(value, category, filters.filterCategory, filtersList) &&
        (category !== HighlightsInitiatives.LEADERSHIP_ATTRIBUTES || filterCriterion === criterion)
      ) {
        filterAction = FilterActions.CLEAR;
      }
      return { action: filterAction, value: displayName };
    }
    default:
      throw new Error();
  }
};

const useFiltersLastActionState = () => {
  const [updateState, dispatchUpdateState] = useReducer(reducer, null);

  const stateUpdater = dispatchUpdateState;

  return [updateState, stateUpdater];
};

export default useFiltersLastActionState;
