import React, { useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';

import { Button } from '../../../../../atoms';
import { InitiativeEmptyStateBlock } from '../../../../../moleculas';
import { CommentsDialog } from '../../../../../organisms';
import { useNotificationsData } from '../../../../../../hooks';
import { sortArrayByKey } from '../../../../../../utils';
import { AppActions, AppContext } from '../../../../../../context';
import {
  NotificationResourcesMap,
  NotificationSourcesMap,
} from '../../../../../../constants/enums';
import { commentsDialog } from '../../../../../../constants/routes';
import { ReactComponent as CommentsEmptyStateImage } from '../../../../../../resources/images/lesson_page_comments_empty_state.svg';

const LessonPageComments = ({ isOwner, lessonId }) => {
  const { t } = useTranslation();
  const { getUnreadNotificationsByResourceAndId } = useNotificationsData();
  const { dispatch: dispatchAppState } = useContext(AppContext);
  const [sessionComments] = useState(
    getUnreadNotificationsByResourceAndId(
      NotificationSourcesMap.WEB_HIDDEN,
      NotificationResourcesMap.LESSON_COMMENT_NEW_INDICATOR,
      +lessonId,
    ),
  );

  const { pathname } = useLocation();
  const history = useHistory();

  const unreadComments = useMemo(() => {
    const unreadCommentsByResourceAndId = getUnreadNotificationsByResourceAndId(
      NotificationSourcesMap.WEB_HIDDEN,
      NotificationResourcesMap.LESSON_COMMENT_NEW_INDICATOR,
      +lessonId,
    );
    return unreadCommentsByResourceAndId
      ? sortArrayByKey(unreadCommentsByResourceAndId, 'createdDate', 'date')
      : [];
  }, [getUnreadNotificationsByResourceAndId, lessonId]);

  const newSessionComment = useMemo(
    () =>
      unreadComments?.filter(
        (comment) => !sessionComments?.some(({ id }) => id === comment.id),
      )?.[0] || null,
    [sessionComments, unreadComments],
  );

  useEffect(() => {
    if (newSessionComment) {
      dispatchAppState({
        type: AppActions.SET_SNACKBAR_STATUS,
        data: {
          text: t(
            newSessionComment.metadata?.info?.relatedCommentId
              ? 'A new reply has just been added to this lesson.'
              : 'A new comment has just been added to this lesson.',
          ),
          type: 'comment',
          action: (
            <Button
              autoFocus
              gaLabel="Show"
              onClick={() => {
                history.replace({
                  ...pathname,
                  hash: `${commentsDialog}-${newSessionComment.resourceId}`,
                });
                dispatchAppState({ type: AppActions.SET_SNACKBAR_STATUS, data: null });
              }}
            >
              {t('Show')}
            </Button>
          ),
        },
      });
    }
  }, [dispatchAppState, history, isOwner, newSessionComment, pathname, t]);

  if (!lessonId) {
    return null;
  }

  return (
    <Box>
      <CommentsDialog
        emptyState={
          <InitiativeEmptyStateBlock
            body={t(
              'If you need to share your thoughts or suggestions with the owner of the lesson, please add a comment to start a discussion!',
            )}
            illustration={<CommentsEmptyStateImage />}
            title={t('There are no comments for this lesson yet')}
          />
        }
        page="teacher-lesson"
        resourceId={lessonId}
        unreadItems={unreadComments}
      />
    </Box>
  );
};

LessonPageComments.propTypes = {
  isOwner: PropTypes.bool.isRequired,
  lessonId: PropTypes.number.isRequired,
};

export default LessonPageComments;
