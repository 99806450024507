import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, useMediaQuery, useTheme } from '@mui/material';

import { Button, DynamicGrid } from '../../../../atoms';
import { DeleteItemWrapper, InitiativeEmptyStateBlock } from '../../../../moleculas';
import { UserContext } from '../../../../../context';
import { useNotificationsData } from '../../../../../hooks';
import {
  getFirstTextFromEditor,
  PortfolioEntitiesData,
  PortfolioItemsToAddData,
  sortArrayByKey,
} from '../../../../../utils';
import { portfolioSectionConfig } from '../../../../../constants/propTypes';
import {
  NotificationResourcesMap,
  NotificationSourcesMap,
  PortfolioSectionTypes,
  RolesMap,
} from '../../../../../constants/enums';
import { NewFeaturesIdsMap } from '../../../../../tours/common/NewFeaturesItemsProvider';
import { ReactComponent as AddIcon } from '../../../../../resources/icons/add.svg';
import { ReactComponent as RecommendationsEmptyStateIllustration } from '../../../../../resources/images/portfolio/recommendations_emty_state.svg';
import PortfolioSection from '../../components/portfolio-section/PortfolioSection';
import PortfolioFilePreviewDialog from '../../components/portfolio-file-preview-dialog/PortfolioFilePreviewDialog';

import PortfolioAddRecommendationDialog from './portfolio-add-recommendation-dialog/PortfolioAddRecommendationDialog';
import PortfolioAddRecommendationRequestDialog from './portfolio-add-recommendation-request-dialog/PortfolioAddRecommendationRequestDialog';
import PortfolioRecommendationRequestDialog from './portfolio-recommendation-requests-dialog/PortfolioRecommendationRequestDialog';
import RecommendationCard from './portfolio-recommendation-card/RecommendationCard';

const WriteRecommendationButton = ({ writeRecommendationHandler }) => {
  const { t } = useTranslation();
  return (
    <Button
      endIcon={<AddIcon />}
      gaLabel="Write a recommendation"
      onClick={writeRecommendationHandler}
    >
      {t('Write a recommendation')}
    </Button>
  );
};

WriteRecommendationButton.propTypes = {
  writeRecommendationHandler: PropTypes.func.isRequired,
};

const PortfolioRecommendationsSection = ({
  config,
  publishModeView,
  sectionIndex,
  isLastSection,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const {
    addPortfolioItemHandler,
    deletePortfolioItemHandler,
    updatePortfolioHandler,
    hasData,
    isEditMode,
    isVisible,
    reflectionHandler,
    sectionData,
    metaData,
    updatePortfolioMetaData,
    studentTeachers,
    recommendationsRequests,
    portfolioId,
    isPortfolioVisible,
    changePortfolioVisibilityHandler,
    onUpdatePositionHandler,
  } = config;
  const { entities, reflection } = sectionData;

  const [portfolioRecommendations, setPortfolioRecommendations] = useState([]);
  const [openedRecommendationsRequests, setOpenedRecommendationsRequests] = useState([]);
  const [isPreviewDialogOpen, setIsPreviewDialogOpen] = useState(false);
  const [isRecommendationsDialogOpen, setIsRecommendationsDialogOpen] = useState(false);
  const [isRecommendationsRequestsDialogOpen, setIsRecommendationsRequestsDialogOpen] =
    useState(false);
  const [isRequestRecommendationsDialogOpen, setIsRequestRecommendationsDialogOpen] =
    useState(false);
  const [activeRecommendationIndex, setActiveRecommendationIndex] = useState(null);
  const [activeRecommendationRequest, setActiveRecommendationRequest] = useState(null);

  const { notificationsList, updateNotificationItems } = useNotificationsData();

  const { state: userState } = useContext(UserContext);
  const userId = userState.profile?.id;

  const isEducator =
    userState.profile?.role === RolesMap.ADMINISTRATOR ||
    userState.profile?.role === RolesMap.TEACHER;

  useEffect(() => {
    setPortfolioRecommendations(
      PortfolioEntitiesData[PortfolioSectionTypes.PORTFOLIO__RECOMMENDATIONS](
        entities,
        metaData,
        'id',
      ),
    );
  }, [entities, metaData]);

  useEffect(() => {
    setOpenedRecommendationsRequests(
      sortArrayByKey(
        recommendationsRequests.filter(
          (request) => request.portfolioId === portfolioId && request.state === 'OPEN',
        ),
        'requestedOn',
        'date',
      ),
    );
  }, [portfolioId, recommendationsRequests]);

  const recommendationsToAdd = useMemo(
    () =>
      PortfolioItemsToAddData[PortfolioSectionTypes.PORTFOLIO__RECOMMENDATIONS](
        entities,
        metaData,
        'id',
      ).map((recommendation) => ({
        id: recommendation.id.toString(),
        name: getFirstTextFromEditor(recommendation.reachTextEditorData.textJson),
        label: `${t('Written by')} ${recommendation.teacherInfo?.firstName} ${
          recommendation.teacherInfo?.lastName
        }`,
      })),
    [entities, metaData, t],
  );

  const unreadRecommendationRequestNotifications = useMemo(
    () =>
      notificationsList?.[NotificationSourcesMap.WEB_HIDDEN]?.filter(
        (notification) =>
          notification.resource === NotificationResourcesMap.RECOMMENDATION_REQUEST_NEW_INDICATOR &&
          !notification.markedAsRead,
      ),
    [notificationsList],
  );

  const updateNotificationHandler = useCallback(() => {
    if (unreadRecommendationRequestNotifications?.length) {
      updateNotificationItems(
        unreadRecommendationRequestNotifications.map((item) => ({ ...item, markedAsRead: true })),
        NotificationSourcesMap.WEB_HIDDEN,
      );
    }
  }, [unreadRecommendationRequestNotifications, updateNotificationItems]);

  const writeRecommendationHandler = useCallback(() => {
    if (openedRecommendationsRequests?.length === 1) {
      setActiveRecommendationRequest(openedRecommendationsRequests[0]);
      updateNotificationHandler();
    }
    return openedRecommendationsRequests?.length > 1
      ? setIsRecommendationsRequestsDialogOpen(true)
      : setIsRecommendationsDialogOpen(true);
  }, [openedRecommendationsRequests, updateNotificationHandler]);

  const requestSelectHandler = useCallback(
    (requestId) => {
      updateNotificationHandler();
      setActiveRecommendationRequest(recommendationsRequests.find(({ id }) => requestId === id));
      setIsRecommendationsRequestsDialogOpen(false);
      setIsRecommendationsDialogOpen(true);
    },
    [recommendationsRequests, updateNotificationHandler],
  );

  const recommendationSaveHandler = useCallback(
    (recommendation) => {
      if (activeRecommendationRequest) {
        setOpenedRecommendationsRequests((state) =>
          state.filter((request) => request.id !== activeRecommendationRequest.id),
        );
      }
      if (activeRecommendationIndex !== null) {
        const recommendationMetaDataIndex = metaData.findIndex(
          ({ id }) => id === recommendation.id,
        );
        const recommendationIndex = sectionData.entities.findIndex(
          ({ id }) => id === recommendation.id,
        );
        updatePortfolioHandler({
          ...sectionData,
          entities: Object.assign([...sectionData.entities], {
            [recommendationIndex]: {
              ...recommendation,
            },
          }),
        });
        updatePortfolioMetaData(
          Object.assign([...metaData], {
            [recommendationMetaDataIndex]: {
              ...recommendation,
            },
          }),
        );
      } else {
        updatePortfolioMetaData([...metaData, recommendation]);
      }
    },
    [
      activeRecommendationIndex,
      activeRecommendationRequest,
      metaData,
      sectionData,
      updatePortfolioHandler,
      updatePortfolioMetaData,
    ],
  );

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <PortfolioSection
        addItemsActionsConfig={
          hasData && isEditMode && !!studentTeachers?.length
            ? {
                title: 'Add recommendation',
                items: [
                  {
                    text: t('Request a recommendation'),
                    icon: AddIcon,
                    handler: () => setIsRequestRecommendationsDialogOpen(true),
                    id: 'request-recommendation',
                    gaLabel: 'Request a recommendation',
                  },
                ],
              }
            : null
        }
        body={
          <Box mt={3}>
            <DynamicGrid
              columnsCount={isWidthUpMd ? 3 : 2}
              gridItems={portfolioRecommendations.map((recommendation, index) => (
                <DeleteItemWrapper
                  key={recommendation.id}
                  disabled={!isEditMode}
                  gaLabel="Remove recommendation"
                  mainContent={
                    <RecommendationCard
                      editHandler={() => {
                        setActiveRecommendationIndex(index);
                        setIsRecommendationsDialogOpen(true);
                        if (recommendation.requestId) {
                          setActiveRecommendationRequest(
                            recommendationsRequests.find(
                              ({ id }) => id === recommendation.requestId,
                            ),
                          );
                        }
                      }}
                      isEditable={userId === recommendation.teacherInfo?.id}
                      readHandler={() => {
                        setActiveRecommendationIndex(index);
                        setIsPreviewDialogOpen(true);
                      }}
                      recommendation={recommendation}
                      showActionMenu={isEducator || isEditMode}
                    />
                  }
                  onDelete={() => {
                    deletePortfolioItemHandler(
                      portfolioRecommendations,
                      'id',
                      recommendation.id,
                      sectionData,
                    );
                  }}
                />
              ))}
              isHorizontalOrder
            />
            {isEducator && (
              <Box mt={3}>
                <WriteRecommendationButton
                  writeRecommendationHandler={writeRecommendationHandler}
                />
              </Box>
            )}
          </Box>
        }
        className="ayo-portfolio-recommendations-section"
        description={
          isEditMode
            ? 'Here, you can adjust which recommendations to display in your portfolio'
            : ''
        }
        emptyState={
          !hasData ? (
            <InitiativeEmptyStateBlock
              body={
                isEducator
                  ? t('But you can write one when it’s needed!')
                  : isEditMode
                  ? t('But as soon as you need one, you can request it here!')
                  : ''
              }
              customButton={
                isEducator ? (
                  <WriteRecommendationButton
                    writeRecommendationHandler={writeRecommendationHandler}
                  />
                ) : isEditMode && !!studentTeachers?.length ? (
                  <Button
                    endIcon={<AddIcon />}
                    gaLabel="Request a recommendation"
                    onClick={() => setIsRequestRecommendationsDialogOpen(true)}
                  >
                    {t('Request a recommendation')}
                  </Button>
                ) : null
              }
              illustration={<RecommendationsEmptyStateIllustration />}
              title={t('No information about recommendations so far')}
            />
          ) : null
        }
        isEditMode={isEditMode}
        isLastSection={isLastSection}
        itemsToAdd={recommendationsToAdd}
        newFeaturesTourCfg={
          !publishModeView
            ? {
                id: NewFeaturesIdsMap.PORTFOLIO_RECOMMENDATIONS,
                label: t(isEducator ? 'Recommendation requests' : 'Request a recommendation'),
              }
            : null
        }
        onAddItems={(itemsToAdd) =>
          addPortfolioItemHandler(metaData, itemsToAdd, 'id', sectionData)
        }
        onChangeReflection={reflectionHandler}
        onUpdatePositionHandler={onUpdatePositionHandler}
        reflection={reflection}
        sectionIndex={sectionIndex}
        sectionKey={PortfolioSectionTypes.PORTFOLIO__RECOMMENDATIONS}
        showActionsMenu={hasData && isEditMode}
        title={t('Recommendations')}
      />
      <PortfolioFilePreviewDialog
        isOpen={isPreviewDialogOpen}
        onClose={() => {
          setIsPreviewDialogOpen(false);
          setActiveRecommendationIndex(null);
        }}
        showSaveAsPDFButton={isEditMode}
        textJson={portfolioRecommendations[activeRecommendationIndex]?.reachTextEditorData.textJson}
        title="Recommendation preview"
      />
      {isEducator && (
        <PortfolioAddRecommendationDialog
          action={() => setActiveRecommendationIndex(null)}
          isOpen={isRecommendationsDialogOpen}
          onClose={() => {
            setIsRecommendationsDialogOpen(false);
            setActiveRecommendationIndex(null);
            setActiveRecommendationRequest(null);
          }}
          onSave={recommendationSaveHandler}
          recommendation={portfolioRecommendations[activeRecommendationIndex]}
          recommendationRequest={activeRecommendationRequest}
        />
      )}
      {isEducator && (
        <PortfolioRecommendationRequestDialog
          isOpen={isRecommendationsRequestsDialogOpen}
          notificationsList={unreadRecommendationRequestNotifications}
          onClose={() => {
            setIsRecommendationsRequestsDialogOpen(false);
            updateNotificationHandler();
          }}
          requestSelectHandler={requestSelectHandler}
          studentRequests={openedRecommendationsRequests}
        />
      )}
      {isEditMode && (
        <PortfolioAddRecommendationRequestDialog
          changePortfolioVisibilityHandler={changePortfolioVisibilityHandler}
          isOpen={isRequestRecommendationsDialogOpen}
          isPortfolioVisible={isPortfolioVisible}
          onClose={() => {
            setIsRequestRecommendationsDialogOpen(false);
          }}
          portfolioId={portfolioId}
          studentTeachers={studentTeachers}
        />
      )}
    </>
  );
};

PortfolioRecommendationsSection.propTypes = {
  config: portfolioSectionConfig(PropTypes.instanceOf(Object)),
  publishModeView: PropTypes.string.isRequired,
  sectionIndex: PropTypes.number.isRequired,
  isLastSection: PropTypes.number.isRequired,
};

PortfolioRecommendationsSection.defaultProps = {
  config: {},
};

export default PortfolioRecommendationsSection;
