import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

import { InformationalCaption } from '../../../moleculas';
import { HighlightsInitiatives } from '../../../../constants/enums';
import HighlightsBarChart from '../components/highlights-bar-chart/HighlightsBarChart';

const GoalsHighlights = ({ stats, totalStudentsCount, onFiltersUpdate }) => (
  <Box display="flex" height={350} width="100%">
    <InformationalCaption
      isLight
      showTooltip
      title="Goals Highlights info"
      tooltipPlacement="right"
    />
    <HighlightsBarChart
      gaLabel="Goals Highlights Graph"
      initiative={HighlightsInitiatives.GOALS}
      mainChartColor="#5D82E0"
      onFiltersUpdate={onFiltersUpdate}
      pendingChartColor="#CDE1FE"
      stats={stats}
      totalStudentsCount={totalStudentsCount}
    />
  </Box>
);

GoalsHighlights.propTypes = {
  stats: PropTypes.instanceOf(Object).isRequired,
  totalStudentsCount: PropTypes.number.isRequired,
  onFiltersUpdate: PropTypes.func.isRequired,
};

export default GoalsHighlights;
