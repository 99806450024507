import { useCallback } from 'react';

import useAxios, { StatusCodeMap } from '../HttpClient';
import {
  apiRoute,
  familyActivitiesRoute,
  feedRoute,
  paginationRoute,
  positionRoute,
  studentsRoute,
} from '../../constants/routes';

const useFeedService = () => {
  const { get, post, put } = useAxios();

  // all 3 `get` methods use params `page, pageSize, enabledLoadMask, activeFilterCategory` that's why this params go first
  const getStudentRelatedPosts = useCallback(
    async (page, pageSize, enableLoaderMask, activeFilterCategory, studentId, lang) => {
      const { data } = await get(
        { enabled: enableLoaderMask },
        `${apiRoute}/feed/students/${studentId}?lang=${lang}&page=${page}&pageSize=${pageSize}&category=${activeFilterCategory}`,
      );

      return data.entity;
    },
    [get],
  );

  const getGuardianGeneralPosts = useCallback(
    async (page, pageSize, enableLoaderMask, activeFilterCategory) => {
      const { data } = await get(
        { enabled: enableLoaderMask },
        `${apiRoute}${feedRoute}?page=${page}&pageSize=${pageSize}&category=${activeFilterCategory}`,
      );
      return data.entity;
    },
    [get],
  );

  const getAuthorFeedPosts = useCallback(
    async (page, pageSize, enableLoadMask, activeFilterCategory) => {
      const { data } = await get(
        { enabled: enableLoadMask },
        `${apiRoute}${feedRoute}/educators?page=${page}&pageSize=${
          pageSize || 20
        }&category=${activeFilterCategory}`,
      );

      return data.entity;
    },
    [get],
  );

  const getPostsOrder = useCallback(
    async (studentId, postId) => {
      const { data } = await get(
        { enabled: true },
        `${apiRoute}${feedRoute}/${postId}${studentsRoute}/${studentId}${paginationRoute}${positionRoute}`,
      );

      return data.entity;
    },
    [get],
  );

  const getFeedPostsMetadata = useCallback(async () => {
    const { data } = await get({ enabled: true }, `${apiRoute}${feedRoute}/metadata`);
    return data.entity;
  }, [get]);

  const getFamilyActivities = useCallback(
    async (studentId, lang, page, pageSize, filterCategory, filterValue, enableLoadMask) => {
      const { data } = await get(
        { enabled: enableLoadMask },
        `${apiRoute}${familyActivitiesRoute}${studentsRoute}/${studentId}?page=${page}&pageSize=${
          pageSize || 12
        }${filterCategory ? `&filterCategory=${filterCategory}` : ''}${
          filterValue ? `&filterValue=${filterValue}` : ''
        }${lang ? `&lang=${lang}` : ''}`,
      );

      return data.entity;
    },
    [get],
  );

  const getFamilyActivitiesCategories = useCallback(
    async (studentId, lang, enableLoadMask = true) => {
      const { data } = await get(
        { enabled: enableLoadMask },
        `${apiRoute}${familyActivitiesRoute}${studentsRoute}/${studentId}/categories?lang=${lang}`,
      );

      return data.entity;
    },
    [get],
  );

  const getFamilyActivitiesSuggested = useCallback(
    async (studentId, lang, enableLoadMask) => {
      const { data } = await get(
        { enabled: enableLoadMask },
        `${apiRoute}${familyActivitiesRoute}${studentsRoute}/${studentId}/suggestions?lang=${lang}`,
      );

      return data.entity;
    },
    [get],
  );

  const getFamilyActivitiesSearched = useCallback(
    async (
      studentId,
      lang,
      page,
      pageSize,
      searchValue,
      searchSuggestions = false,
      enableLoadMask = false,
    ) => {
      const { data } = await get(
        { enabled: enableLoadMask },
        `${apiRoute}${familyActivitiesRoute}${studentsRoute}/${studentId}/search?page=${page}&pageSize=${pageSize}&&searchValue=${searchValue}&searchSuggestions=${searchSuggestions}${
          lang ? `&lang=${lang}` : ''
        }`,
      );

      return data.entity;
    },
    [get],
  );

  const postFamilyFeedPost = useCallback(
    async (body) => {
      const { data } = await post({ enabled: true }, `${apiRoute}${feedRoute}`, body, {
        'Content-Type': 'multipart/form-data',
        skipDefaultErrorHandlers: [StatusCodeMap.BAD_REQUEST, StatusCodeMap.NOT_FOUND],
      });

      return data.entity;
    },
    [post],
  );

  const updateFamilyFeedPost = useCallback(
    async (body, postId) => {
      const { data } = await put({ enabled: true }, `${apiRoute}${feedRoute}/${postId}`, body, {
        'Content-Type': 'multipart/form-data',
        skipDefaultErrorHandlers: [StatusCodeMap.BAD_REQUEST, StatusCodeMap.NOT_FOUND],
      });
      return data.entity;
    },
    [put],
  );

  return {
    getStudentRelatedPosts,
    getGuardianGeneralPosts,
    getAuthorFeedPosts,
    getFeedPostsMetadata,
    getFamilyActivities,
    getFamilyActivitiesCategories,
    getFamilyActivitiesSuggested,
    getFamilyActivitiesSearched,
    getPostsOrder,
    postFamilyFeedPost,
    updateFamilyFeedPost,
  };
};

export default useFeedService;
