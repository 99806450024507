import { InputsValidationErrors, InputsValidationRules } from '../../constants/enums';
import { ClubKeys, ClubRequirementsKeys } from '../../constants/club-hub';
import { urlAllowedValidationRE } from '../../constants/regexps';
import { getFileErrors } from '../file-helpers/fileHelpers';
import { byteToMegaByteCoeff } from '../../constants/values';

export const clubLinkValidation = (links) =>
  !links.length ||
  links.every(
    (link) =>
      urlAllowedValidationRE.test(link) &&
      link.length <= InputsValidationRules.MAX_LINK_INPUT_LENGTH &&
      links.filter((url) => url === link).length === 1,
  );
const validateFormValues = (key, value) => {
  switch (key) {
    case ClubKeys.CLUB_NAME:
      return (
        value.trim().length >= InputsValidationRules.MIN_INPUT_LENGTH &&
        value.length <= InputsValidationRules.MAX_INPUT_LENGTH
      );
    case ClubKeys.DESCRIPTION:
      return (
        value.trim().length >= InputsValidationRules.MIN_INPUT_LENGTH &&
        value.length <= InputsValidationRules.MAX_INPUT_LENGTH
      );
    case ClubKeys.SCHOOLS:
      return !!value.length;
    case ClubKeys.LINKS:
      return clubLinkValidation(value);
    case ClubKeys.ATTACHMENTS:
      return true;
    case ClubKeys.TYPE:
      return !!value;
    case ClubKeys.REQUIREMENTS:
      return (
        !value[ClubRequirementsKeys.IS_VISIBLE] ||
        (clubLinkValidation(value[ClubRequirementsKeys.LINKS]) &&
          value[ClubRequirementsKeys.DESCRIPTION]?.trim().length >=
            InputsValidationRules.MIN_INPUT_LENGTH &&
          value[ClubRequirementsKeys.DESCRIPTION].length <= InputsValidationRules.MAX_INPUT_LENGTH)
      );
    case ClubKeys.TAGS:
      return true;
    default:
      return false;
  }
};

export const getClubFormValidation = (values, t) => {
  const validation = {};
  Object.entries(values).forEach(([key, value]) => {
    validation[key] = validateFormValues(key, value);
  });

  const validationErrors = {};

  if (!validation[ClubKeys.CLUB_NAME]) {
    validationErrors[ClubKeys.CLUB_NAME] = !values[ClubKeys.CLUB_NAME]
      ? t('Please add the name of the club.')
      : values[ClubKeys.CLUB_NAME].length < InputsValidationRules.MIN_INPUT_LENGTH
      ? InputsValidationErrors(t, InputsValidationRules.MIN_INPUT_LENGTH).MIN_ERROR_TEXT
      : InputsValidationErrors(t, InputsValidationRules.MAX_INPUT_LENGTH).MAX_ERROR_TEXT;
  }

  if (!validation[ClubKeys.DESCRIPTION]) {
    validationErrors[ClubKeys.DESCRIPTION] = !values[ClubKeys.DESCRIPTION]
      ? t('Please add a few words about your club.')
      : values[ClubKeys.DESCRIPTION].length < InputsValidationRules.MIN_INPUT_LENGTH
      ? InputsValidationErrors(t, InputsValidationRules.MIN_INPUT_LENGTH).MIN_ERROR_TEXT
      : InputsValidationErrors(t, InputsValidationRules.MAX_INPUT_LENGTH).MAX_ERROR_TEXT;
  }

  if (!validation[ClubKeys.SCHOOLS]) {
    validationErrors[ClubKeys.SCHOOLS] = t(
      'Please specify for whom your club can be available to apply.',
    );
  }

  if (!validation[ClubKeys.REQUIREMENTS]) {
    validationErrors[ClubKeys.REQUIREMENTS] = {
      [ClubRequirementsKeys.DESCRIPTION]: !values[ClubKeys.REQUIREMENTS]?.[
        ClubRequirementsKeys.DESCRIPTION
      ]
        ? t('Please share the requirements for joining and staying with the students.')
        : values[ClubKeys.REQUIREMENTS][ClubRequirementsKeys.DESCRIPTION].length <
          InputsValidationRules.MIN_INPUT_LENGTH
        ? InputsValidationErrors(t, InputsValidationRules.MIN_INPUT_LENGTH).MIN_ERROR_TEXT
        : '',
    };
  }

  return {
    isFormValid: !Object.values(validation).some((value) => !value),
    validationErrors,
  };
};

const MAX_FILE_SIZE = 10 * byteToMegaByteCoeff;

export const onClubHubBannerDrop = (dropped, onChange, setSnackBarStatus, t, showSuccessStatus) => {
  const [droppedFile] = dropped;
  const fileErrors = getFileErrors(
    droppedFile,
    { allowedExtensions: ['jpg', 'jpeg', 'png'], maxFileSize: MAX_FILE_SIZE },
    t,
  );
  if (fileErrors.length) {
    setSnackBarStatus({ text: fileErrors[0], type: 'error' });
    return;
  }
  if (showSuccessStatus) {
    setSnackBarStatus({ text: t('The banner has been successfully uploaded.'), type: 'success' });
  }
  onChange({ [ClubKeys.ATTACHMENTS]: [droppedFile] });
};
