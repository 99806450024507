import React, { useContext } from 'react';
import { Container, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Typography } from '../../atoms';
import { PageWrapper } from '../../organisms';
import { useProfile } from '../../../utils';
import { PrivilegesMap, RolesMap } from '../../../constants/enums';
import { UserContext } from '../../../context';

import ClubHubTabs from './club-hub-components/club-hub-tabs/ClubHubTabs';
import ClubHubMainContent from './club-hub-main-content/ClubHubMainContent';

const ClubHubPage = () => {
  const { t } = useTranslation();

  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  const { state: userState } = useContext(UserContext);
  const isStudent = userState.profile.role === RolesMap.STUDENT;

  const { getPrivilegeStatus } = useProfile();

  const isClubsManagerPrivileges = getPrivilegeStatus(PrivilegesMap.CLUB_HUB_MANAGER);

  return (
    <PageWrapper mainElementClassName="ayo-club-hub">
      <Container>
        <Typography component="h1" variant="h1">
          {t('Club Hub')}
        </Typography>
        <Typography
          maxWidth={isWidthUpSm ? '50%' : '100%'}
          mb={isStudent ? 3 : 5}
          mt={1}
          variant="body2"
        >
          {t(
            isStudent
              ? 'Welcome to the place where you can view your clubs membership and explore new clubs to join!'
              : isClubsManagerPrivileges
              ? "This page is dedicated to the clubs' creation and management, as well as the clubs' moderation, within all your campuses."
              : "This page is dedicated to the clubs' creation and management within all your campuses.",
          )}
        </Typography>
        {isClubsManagerPrivileges ? <ClubHubTabs /> : <ClubHubMainContent />}
      </Container>
    </PageWrapper>
  );
};

export default ClubHubPage;
