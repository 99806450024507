import React, { useCallback, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import PropTypes from 'prop-types';

import { Typography } from '../../../../../atoms';
import { InitiativeEmptyStateBlock, SearchField, SearchNoResults } from '../../../../../moleculas';
import { MobileNotSupported } from '../../../../../organisms';
import { clubMemberType } from '../../../../../../constants/propTypes';
import { ClubsMembersQueryParams } from '../../../../../../constants/club-hub';
import { loginAllowedFilterRE } from '../../../../../../constants/regexps';
import { ReactComponent as ClubsMembersEmptyState } from '../../../../../../resources/images/club_hub_members_empty_state.svg';

import ClubMembersTable from './components/club-members-table/ClubMembersTable';
import ClubMembersFilters from './components/club-members-filters/ClubMembersFilters';

const ClubsMembersSection = ({
  clubName,
  isEditable,
  members,
  membersQueryParams,
  onQueryParamsChange,
  onStatusChange,
  studentsTotal,
  totalElements,
}) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  const {
    clubId,
    name: searchValue,
    page,
    pageSize,
    status: membershipStatus,
  } = membersQueryParams;

  const searchApiRef = useRef();

  const searchHandler = useCallback(
    (value) => {
      onQueryParamsChange({
        [ClubsMembersQueryParams.NAME]: value?.trim(),
        [ClubsMembersQueryParams.PAGE]: 1,
      });
    },
    [onQueryParamsChange],
  );

  return isWidthUpSm ? (
    <Box className="ayo-club-hub-details__members-section">
      <Typography mb={3} mt={5} variant="h2">
        {t('Members')}
      </Typography>
      {studentsTotal ? (
        <Box>
          <ClubMembersFilters
            membershipStatus={membershipStatus}
            onFiltersChange={onQueryParamsChange}
          />
          <Grid container mb={3} mt={5}>
            <Grid item md={6} sm={5}>
              <SearchField
                apiRef={searchApiRef}
                gaLabel="Club members search"
                inputRE={loginAllowedFilterRE}
                label={t('Search by name')}
                onSearch={searchHandler}
              />
              {searchValue &&
                (totalElements ? (
                  <Typography mt={3} variant="body2">
                    <Trans
                      components={{ b: <b /> }}
                      i18nKey="search result text"
                      values={{ count: totalElements, searchValue }}
                    />
                  </Typography>
                ) : (
                  <Box mt={3}>
                    <SearchNoResults
                      explanation="AYO couldn’t find any students with the name searchValue. Please check your spelling and repeat your search."
                      onResetSearch={() => searchApiRef.current.clearSearch()}
                      resetLinkText="Reset the search results to see all students"
                      searchValue={searchValue}
                    />
                  </Box>
                ))}
            </Grid>
          </Grid>
          {!!totalElements && (
            <ClubMembersTable
              clubId={clubId}
              clubName={clubName}
              isEditable={isEditable}
              members={members}
              onQueryParamsChange={onQueryParamsChange}
              onStatusChange={onStatusChange}
              page={page}
              pageSize={pageSize}
              totalElements={totalElements}
            />
          )}
        </Box>
      ) : (
        <InitiativeEmptyStateBlock
          body={t(
            'As soon as the students apply for joining the club, you’ll see the requests here.',
          )}
          illustration={<ClubsMembersEmptyState />}
          title={t('There are no members yet')}
        />
      )}
    </Box>
  ) : (
    <MobileNotSupported
      explanation={t(
        'Please open Club Hub management section with a tablet or desktop version to use this functionality.',
      )}
    />
  );
};

ClubsMembersSection.propTypes = {
  clubName: PropTypes.string.isRequired,
  isEditable: PropTypes.bool.isRequired,
  members: PropTypes.arrayOf(PropTypes.shape(clubMemberType)),
  membersQueryParams: PropTypes.instanceOf(Object).isRequired,
  onQueryParamsChange: PropTypes.func.isRequired,
  onStatusChange: PropTypes.func.isRequired,
  studentsTotal: PropTypes.number,
  totalElements: PropTypes.number,
};

ClubsMembersSection.defaultProps = {
  members: null,
  studentsTotal: null,
  totalElements: null,
};

export default ClubsMembersSection;
