import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Link, Typography } from '../../atoms';
import { moodsRoute, trainAyoRoute } from '../../../constants/routes';
import { MoodsAndSupportIllustrations, SupportTypesMap } from '../../../constants/moods';
import { ReactComponent as ChevronRightIcon } from '../../../resources/icons/chevron_right.svg';
import { ReactComponent as SupportGirl } from '../../../resources/images/moods/support_girl.svg';
import EditingButton from '../editing-button/EditingButton';

const MoodGetHelpCard = ({ needToTalk, onClick }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Box
      className={classNames(
        'ayo-get-help-card',
        { 'ayo-get-help-card--need-to-talk': needToTalk },
        { 'ayo-get-help-card--need-to-talk-sm': needToTalk && !isWidthUpSm },
      )}
    >
      <Box>
        {needToTalk && !isWidthUpSm && (
          <Box display="flex" justifyContent="center" pb={3}>
            <SupportGirl
              aria-label={t(MoodsAndSupportIllustrations[SupportTypesMap.GET_SUPPORT].alt)}
              height="50%"
              role="img"
              width="50%"
            />
          </Box>
        )}
        <Typography component={needToTalk ? 'h2' : 'h3'} pb={1} variant="subtitle1">
          {t(needToTalk ? 'Need to talk now?' : 'How are you feeling?')}
        </Typography>
        <Typography
          className="ayo-get-help-card__paragraph"
          pb={1}
          variant={needToTalk || isWidthUpSm ? 'body2' : 'body3'}
        >
          {t(
            needToTalk
              ? 'If you or someone you know needs support now, the information below or talking to a trusted adult might help'
              : 'Counselors are off right now, but you may find something useful here',
          )}
        </Typography>
        {needToTalk ? (
          <EditingButton
            className="ayo-get-help-card__link"
            gaLabel="24/7 support"
            icon={<ChevronRightIcon />}
            iconPosition="end"
            onClick={onClick}
            text={t('24/7 support')}
          />
        ) : (
          <Link
            className="ayo-get-help-card__link"
            component={RouterLink}
            gaLabel="See more"
            icon={<ChevronRightIcon />}
            to={`${trainAyoRoute}${moodsRoute}`}
            underline="none"
          >
            {t('See more')}
          </Link>
        )}
      </Box>
    </Box>
  );
};

MoodGetHelpCard.propTypes = {
  needToTalk: PropTypes.bool,
  onClick: PropTypes.func,
};

MoodGetHelpCard.defaultProps = {
  needToTalk: false,
  onClick: () => {},
};

export default MoodGetHelpCard;
