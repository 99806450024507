import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Box, DialogContent } from '@mui/material';

import { Dialog, DialogTitle, Typography } from '../../../../atoms';
import CommentsForm from '../comments-form/CommentsForm';

const CommentsCreateDialog = ({
  addNewCommentHandler,
  className,
  onClose,
  isOpen,
  title,
  helperText,
  resourceId,
  page,
  relatedUserId,
  submitButtonText,
  onAfterSubmit,
  onCancel,
}) => {
  const { t } = useTranslation();
  return (
    <Dialog className={className} onClose={onClose} open={isOpen}>
      <DialogTitle disableTypography>
        <Typography variant="subtitle1">{t(title)}</Typography>
      </DialogTitle>
      <DialogContent>
        {helperText && (
          <Box mb={3} mt={1}>
            <Typography variant="body2">{t(helperText)}</Typography>
          </Box>
        )}
        <CommentsForm
          addNewCommentHandler={addNewCommentHandler}
          isButtonsFullWidth
          onAfterSubmit={onAfterSubmit}
          onCancel={onCancel}
          outlined
          page={page}
          relatedUserId={relatedUserId}
          resourceId={resourceId}
          submitButtonText={submitButtonText}
        />
      </DialogContent>
    </Dialog>
  );
};

CommentsCreateDialog.propTypes = {
  addNewCommentHandler: PropTypes.func.isRequired,
  className: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  resourceId: PropTypes.number.isRequired,
  page: PropTypes.string.isRequired,
  relatedUserId: PropTypes.number.isRequired,
  submitButtonText: PropTypes.string.isRequired,
  onAfterSubmit: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
};

CommentsCreateDialog.defaultProps = {
  className: '',
  helperText: '',
  onAfterSubmit: null,
};

export default CommentsCreateDialog;
