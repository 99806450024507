/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment,react/prop-types */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Box, DialogActions, DialogContent, IconButton } from '@mui/material';

import {
  Checkbox,
  DatePicker,
  Dialog,
  DialogTitle,
  FormControlLabel,
  TextField,
  Typography,
} from '../../../../../atoms';
import {
  DisabledControlWithTooltip,
  PopupActionsButtons,
  STTTextField,
} from '../../../../../moleculas';
import {
  GaActions,
  GaCategories,
  InputsValidationErrors,
  InputsValidationRules,
  minProperDate,
} from '../../../../../../constants/enums';
import { byteToMegaByteCoeff } from '../../../../../../constants/values';
import { ReactComponent as DatePickerIcon } from '../../../../../../resources/icons/date_picker.svg';
import FileUpload from '../../../../fileupload/FileUpload';
import { GA } from '../../../../../../utils';

const VolunteeringNamesMap = {
  ROLE: 'role',
  ORGANIZATION: 'organization',
  START_DATE: 'startDate',
  END_DATE: 'endDate',
  CURRENTPOSITION: 'currentPosition',
  DESCRIPTION: 'description',
  ATTACHMENTS: 'attachments',
};

const initialVolunteeringState = {
  [VolunteeringNamesMap.ROLE]: '',
  [VolunteeringNamesMap.ORGANIZATION]: '',
  [VolunteeringNamesMap.START_DATE]: null,
  [VolunteeringNamesMap.END_DATE]: null,
  [VolunteeringNamesMap.CURRENTPOSITION]: false,
  [VolunteeringNamesMap.DESCRIPTION]: '',
};

const initialVolunteeringErrorMessages = {
  [VolunteeringNamesMap.ROLE]: '',
  [VolunteeringNamesMap.ORGANIZATION]: '',
  [VolunteeringNamesMap.START_DATE]: null,
  [VolunteeringNamesMap.END_DATE]: null,
  [VolunteeringNamesMap.DESCRIPTION]: '',
};

const PortfolioCreateVolunteeringDialog = ({ isOpen, onSave, onEdit, onClose, valuesToEdit }) => {
  const { t } = useTranslation();

  const [volunteeringData, setVolunteeringData] = useState(initialVolunteeringState);
  const [volunteeringErrorMessages, setVolunteeringErrorMessages] = useState(
    initialVolunteeringErrorMessages,
  );
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState(null);
  const [removedAttachments, setRemovedAttachments] = useState([]);

  const submitButtonRef = useRef();

  const volunteeringChangeHandler = useCallback(
    (e) => {
      const { name, value } = e.target;
      setVolunteeringData((state) => ({
        ...state,
        [name]: value,
      }));
      if (volunteeringErrorMessages[name]) {
        setVolunteeringErrorMessages((state) => ({ ...state, [name]: '' }));
      }
      GA.logInteraction({
        category: GaCategories.BEHAVIOR,
        action: GaActions.DROPDOWN_CHANGE,
        label: value,
      });
    },
    [volunteeringErrorMessages],
  );

  const volunteeringCheckboxHandler = useCallback(() => {
    setVolunteeringData((state) => ({
      ...state,
      currentPosition: !state.currentPosition,
      endDate: null,
    }));
  }, []);

  const volunteeringDateHandler = useCallback(
    (e, key) => {
      setVolunteeringData((state) => ({
        ...state,
        [key]: e ? dayjs(e).format('YYYY-MM-DD') : null,
      }));
      if (volunteeringErrorMessages[key]) {
        setVolunteeringErrorMessages((state) => ({ ...state, [key]: '' }));
      }
    },
    [volunteeringErrorMessages],
  );

  useEffect(() => {
    setVolunteeringData(valuesToEdit || initialVolunteeringState);
    setVolunteeringErrorMessages(initialVolunteeringErrorMessages);
    setFilesToUpload([]);
    setRemovedAttachments([]);
    if (valuesToEdit?.[VolunteeringNamesMap.ATTACHMENTS].length) {
      setUploadedFiles(
        valuesToEdit[VolunteeringNamesMap.ATTACHMENTS].map((el) => ({
          file: { name: el.fileName, id: el.id },
          errors: [],
        })),
      );
    } else {
      setUploadedFiles(null);
    }
  }, [valuesToEdit, isOpen]);

  const isValid = useCallback(() => {
    const errors = { ...initialVolunteeringErrorMessages };
    let isDateValid = true;

    const roleInputLength = volunteeringData[VolunteeringNamesMap.ROLE]?.trim().length;
    const organisationInputLength =
      volunteeringData[VolunteeringNamesMap.ORGANIZATION]?.trim().length;
    const descriptionInputLength =
      volunteeringData[VolunteeringNamesMap.DESCRIPTION]?.trim().length;
    const startDate = volunteeringData[VolunteeringNamesMap.START_DATE];
    const endDate = volunteeringData[VolunteeringNamesMap.END_DATE];

    if (roleInputLength < InputsValidationRules.MIN_INPUT_LENGTH) {
      errors[VolunteeringNamesMap.ROLE] = InputsValidationErrors(
        t,
        InputsValidationRules.MIN_INPUT_LENGTH,
      ).MIN_ERROR_TEXT;
    } else if (roleInputLength > InputsValidationRules.MAX_TITLE_LENGTH) {
      errors[VolunteeringNamesMap.ROLE] = InputsValidationErrors(
        t,
        InputsValidationRules.MAX_TITLE_LENGTH,
      ).MAX_ERROR_TEXT;
    }

    if (organisationInputLength < InputsValidationRules.MIN_INPUT_LENGTH) {
      errors[VolunteeringNamesMap.ORGANIZATION] = InputsValidationErrors(
        t,
        InputsValidationRules.MIN_INPUT_LENGTH,
      ).MIN_ERROR_TEXT;
    } else if (organisationInputLength > InputsValidationRules.MAX_TITLE_LENGTH) {
      errors[VolunteeringNamesMap.ORGANIZATION] = InputsValidationErrors(
        t,
        InputsValidationRules.MAX_TITLE_LENGTH,
      ).MAX_ERROR_TEXT;
    }

    if (descriptionInputLength > InputsValidationRules.MAX_INPUT_LENGTH) {
      errors[VolunteeringNamesMap.DESCRIPTION] = InputsValidationErrors(
        t,
        InputsValidationRules.MAX_INPUT_LENGTH,
      ).MAX_ERROR_TEXT;
    }

    if (
      startDate &&
      (!dayjs(startDate).isValid() || dayjs(startDate).isBefore(minProperDate, 'day'))
    ) {
      isDateValid = false;
    }

    if (
      !volunteeringData[VolunteeringNamesMap.CURRENTPOSITION] &&
      endDate &&
      (!dayjs(endDate).isValid() || dayjs(endDate).isBefore(minProperDate, 'day'))
    ) {
      isDateValid = false;
    }

    setVolunteeringErrorMessages(errors);
    const isFormValid = !Object.values(errors).some((error) => error) && isDateValid;
    return isFormValid;
  }, [t, volunteeringData]);

  const handleSubmit = useCallback(() => {
    if (isValid()) {
      const requestBody = {
        ...volunteeringData,
        attachments: filesToUpload,
        removedAttachments,
      };
      if (valuesToEdit) {
        onEdit(requestBody, valuesToEdit.id);
      } else {
        onSave(requestBody);
      }
      onClose();
    }
  }, [
    valuesToEdit,
    isValid,
    filesToUpload,
    onClose,
    onEdit,
    onSave,
    removedAttachments,
    volunteeringData,
  ]);

  return (
    <Dialog
      className="ayo-portfolio-add-volunteering-dialog"
      gaLabel="Portfolio create volunteering dialog"
      onClose={onClose}
      open={isOpen}
    >
      <DialogTitle disableTypography>
        <Box mb={2}>
          <Typography component="h2" variant="h2">
            {valuesToEdit ? t('Edit volunteering') : t('Add volunteering')}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box>
          <Box mb={3} mt={1}>
            <STTTextField
              error={!!volunteeringErrorMessages[VolunteeringNamesMap.ROLE]}
              fullWidth
              helperText={volunteeringErrorMessages[VolunteeringNamesMap.ROLE]}
              inputProps={{
                autoComplete: 'off',
              }}
              label={t('Role(employment)')}
              name={VolunteeringNamesMap.ROLE}
              onChange={volunteeringChangeHandler}
              outlined
              required
              value={volunteeringData[VolunteeringNamesMap.ROLE]}
            />
          </Box>
          <Box mb={3}>
            <STTTextField
              error={!!volunteeringErrorMessages[VolunteeringNamesMap.ORGANIZATION]}
              fullWidth
              helperText={volunteeringErrorMessages[VolunteeringNamesMap.ORGANIZATION]}
              inputProps={{
                autoComplete: 'off',
              }}
              label={t('Organization')}
              name={VolunteeringNamesMap.ORGANIZATION}
              onChange={volunteeringChangeHandler}
              outlined
              required
              value={volunteeringData[VolunteeringNamesMap.ORGANIZATION]}
            />
          </Box>
          <Box display="flex" mb={3}>
            <Box mr={3}>
              <DatePicker
                gaLabel="Volunteering start date"
                label={t('Start date')}
                onChange={(e) => volunteeringDateHandler(e, VolunteeringNamesMap.START_DATE)}
                outlined
                renderInput={(props) => (
                  <Box mb={props.error}>
                    <TextField
                      {...props}
                      error={props.error}
                      fullWidth
                      helperText={props.error && InputsValidationErrors(t).DATE_ERROR_TEXT}
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            ref={props.inputRef}
                            {...props.InputProps.endAdornment.props.children.props}
                            className="ayo-icon-button"
                            disableRipple
                          >
                            <DatePickerIcon />
                          </IconButton>
                        ),
                      }}
                      outlined
                    />
                  </Box>
                )}
                value={volunteeringData[VolunteeringNamesMap.START_DATE]}
              />
            </Box>
            <DisabledControlWithTooltip
              gaLabel="Disabled volunttering end date"
              showTooltip={volunteeringData[VolunteeringNamesMap.CURRENTPOSITION]}
              title={t('The end date isnt available when youre currently in this role')}
            >
              <DatePicker
                disabled={volunteeringData[VolunteeringNamesMap.CURRENTPOSITION]}
                gaLabel="volunttering end date"
                label={`${t('End date')}`}
                onChange={(e) => volunteeringDateHandler(e, VolunteeringNamesMap.END_DATE)}
                outlined
                renderInput={(props) => (
                  <Box mb={props.error && 3}>
                    <TextField
                      {...props}
                      disabled={volunteeringData[VolunteeringNamesMap.CURRENTPOSITION]}
                      error={props.error}
                      fullWidth
                      helperText={props.error && InputsValidationErrors(t).DATE_ERROR_TEXT}
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            ref={props.inputRef}
                            {...props.InputProps.endAdornment.props.children.props}
                            className="ayo-icon-button"
                            disableRipple
                          >
                            <DatePickerIcon />
                          </IconButton>
                        ),
                      }}
                      outlined
                    />
                  </Box>
                )}
                value={volunteeringData[VolunteeringNamesMap.END_DATE]}
              />
            </DisabledControlWithTooltip>
          </Box>
          <Box mb={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={volunteeringData[VolunteeringNamesMap.CURRENTPOSITION]}
                  gaLabel="is current volunteering position"
                  name={VolunteeringNamesMap.CURRENTPOSITION}
                  onChange={volunteeringCheckboxHandler}
                />
              }
              label={<Typography variant="body2">{t('I am currently in this role')}</Typography>}
            />
          </Box>
          <Box mb={3}>
            <STTTextField
              error={!!volunteeringErrorMessages[VolunteeringNamesMap.DESCRIPTION]}
              fullWidth
              helperText={volunteeringErrorMessages[VolunteeringNamesMap.DESCRIPTION]}
              label={t('Description')}
              multiline
              name={VolunteeringNamesMap.DESCRIPTION}
              onChange={volunteeringChangeHandler}
              outlined
              rowsCount={2}
              value={volunteeringData[VolunteeringNamesMap.DESCRIPTION]}
            />
          </Box>
          <Box mb={3}>
            <Box mb={1}>
              <Typography component="h3" variant="subtitle2">
                {t('Attachments')}
              </Typography>
            </Box>
            <FileUpload
              allowedExtensions={['jpg', 'jpeg', 'png', 'pdf']}
              focusableRef={submitButtonRef}
              maxAttachmentsAllowed={3}
              maxFileSize={5 * byteToMegaByteCoeff}
              onFileAdd={(file) => {
                setFilesToUpload((state) => [...state, file]);
              }}
              onFileRemove={(file) => {
                if (file.id) {
                  setRemovedAttachments((state) => [
                    ...state,
                    valuesToEdit?.attachments.find((el) => el.id === file.id),
                  ]);
                }
                setFilesToUpload((state) => [...state.filter((x) => x !== file)]);
              }}
              uploadedFiles={uploadedFiles}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <PopupActionsButtons
          primaryButtonGaLabel={valuesToEdit ? 'Save changes' : 'Add'}
          primaryButtonHandler={handleSubmit}
          primaryButtonText={valuesToEdit ? t('Save changes') : t('Add')}
          secondaryButtonGaLabel="Cancel"
          secondaryButtonHandler={onClose}
          secondaryButtonText={t('Cancel')}
        />
      </DialogActions>
    </Dialog>
  );
};

PortfolioCreateVolunteeringDialog.propTypes = {
  valuesToEdit: PropTypes.shape({
    id: PropTypes.string,
    attachments: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
    description: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    organization: PropTypes.string,
    role: PropTypes.string,
    currentPosition: PropTypes.bool,
  }),
  isOpen: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

PortfolioCreateVolunteeringDialog.defaultProps = {
  valuesToEdit: null,
};

export default PortfolioCreateVolunteeringDialog;
