import React, { useCallback } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Container, CardMedia, CardActions, Box, Card, Divider, Grid } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

import { Link, Typography } from '../../atoms';
import { ReactComponent as GoArrow } from '../../../resources/icons/go_back.svg';
import { getFormattedDate } from '../../../utils';

const MediaCard = ({
  articleLink,
  cardAction,
  cardMediaImage,
  cardMediaImageAlt,
  content,
  contentLabel,
  date,
  mediaClickable,
  sourceLink,
  sourceName,
  title,
}) => {
  const theme = useTheme();
  const isWidthUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const articleDate = getFormattedDate(date, currentLanguage);
  const handleClick = useCallback(() => {
    window.open(articleLink, '_blank');
  }, [articleLink]);

  return (
    <Box position="relative">
      {isWidthUpMd && (
        <Container className="ayo-media-card__bgContainer">
          <Box className="ayo-media-card__background" />
        </Container>
      )}
      <Container className="ayo-media-card--container">
        <Card className="ayo-card ayo-media-card" variant="outlined">
          <Grid container>
            <Box
              display="flex"
              flexDirection={isWidthUpMd ? 'row' : 'column-reverse'}
              px={isWidthUpMd ? 5 : 2}
              py={isWidthUpMd ? 5 : 3}
            >
              <Grid item md={7} sm={12}>
                <Box pr={isWidthUpMd ? 15 : 0} pt={isWidthUpMd ? 0 : 3}>
                  <CardActions className="ayo-media-card__actions">
                    {sourceLink ? (
                      <Link href={sourceLink} target="_blank">
                        {sourceName}
                      </Link>
                    ) : (
                      <Typography isLabel variant="body2">
                        {sourceName}
                      </Typography>
                    )}
                    <Divider className="ayo-media-card__divider" flexItem orientation="vertical" />
                    <Typography isLabel variant="body2">
                      {articleDate}
                    </Typography>
                  </CardActions>
                  <Box py={isWidthUpMd ? 3.5 : 2}>
                    <Typography
                      className="ayo-media-card__header"
                      component="h3"
                      variant="subtitle1"
                    >
                      {title}
                    </Typography>
                    <Typography className="ayo-media-card__text" variant="body2">
                      {content}
                    </Typography>
                  </Box>
                  <CardActions className="ayo-media-card__actions">
                    <Link href={articleLink} target="_blank">
                      {cardAction}
                    </Link>
                    <GoArrow className="ayo-media-card__arrow" />
                  </CardActions>
                </Box>
              </Grid>
              <Grid item md={5} sm={12}>
                <CardMedia
                  className={classnames('ayo-media-card__image', {
                    Clickable: mediaClickable,
                  })}
                  image={cardMediaImage}
                  onClick={mediaClickable ? handleClick : undefined}
                  title={cardMediaImageAlt}
                >
                  {contentLabel && (
                    <Box className="ayo-media-card__content-label" px={1} py={0.25}>
                      <Typography variant="body2">{contentLabel}</Typography>
                    </Box>
                  )}
                </CardMedia>
              </Grid>
            </Box>
          </Grid>
        </Card>
      </Container>
    </Box>
  );
};

MediaCard.propTypes = {
  articleLink: PropTypes.string.isRequired,
  cardAction: PropTypes.string,
  cardMediaImage: PropTypes.string.isRequired,
  cardMediaImageAlt: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  contentLabel: PropTypes.string,
  date: PropTypes.number.isRequired,
  mediaClickable: PropTypes.bool,
  sourceLink: PropTypes.string,
  sourceName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

MediaCard.defaultProps = {
  cardAction: 'Read the article',
  contentLabel: '',
  sourceLink: '',
  mediaClickable: false,
};

export default MediaCard;
