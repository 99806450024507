import React, { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link as RouterLink, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, useMediaQuery, useTheme } from '@mui/material';

import { Typography, SkeletonMask, Button } from '../../atoms';
import { InitiativeEmptyStateBlock, NewFeatureHotspot } from '../../moleculas';
import { useEndorsementsService, useStudentsService } from '../../../services';
import { useLazyDataLoad } from '../../../hooks';
import { NewFeaturesIdsMap } from '../../../tours/common/NewFeaturesItemsProvider';
import { trainAyoEndorsementsRoute } from '../../../constants/routes';
import { RolesMap } from '../../../constants/enums';
import { AppActions, AppContext, UserActions, UserContext } from '../../../context';
import { ReactComponent as EndorsementsEmptyStateIllustration } from '../../../resources/images/endorsements_empty_state.svg';
import { ReactComponent as VectorIcon } from '../../../resources/icons/vector.svg';
import TryMobileInformationalMessage from '../try-mobile-informational-message/TryMobileInformationalMessage';

import EndorsementDialog from './components/endorsement-dialog/EndorsementDialog';
import SelectedEndorsements from './components/selected-endorsements-block/SelectedEndorsements';
import InterestedEndorsements from './components/interested-endorsements-block/InterestedEndorsements';

const EndorsementsSection = ({
  headerLevel,
  titleVariant,
  title,
  isLazyLoad,
  isReadOnlyMode,
  isWithNavigationButton,
}) => {
  const { t, i18n } = useTranslation();
  const match = useRouteMatch();
  const personId = match.params.id;
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const { getStudentEndorsements } = useStudentsService();
  const { deleteEndorsementReason, postEndorsementReason } = useEndorsementsService();
  const { state: userState, dispatch: dispatchUserState } = useContext(UserContext);
  const { dispatch: dispatchAppState } = useContext(AppContext);
  const [endorsements, setEndorsements] = useState(null);
  const [interestedEndorsements, setInterestedEndorsements] = useState(null);
  const [activeEndorsementConfig, setActiveEndorsementConfig] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const isStudent = userState.profile.role === RolesMap.STUDENT;
  const studentId = isStudent ? userState.profile.id : personId;

  const loadEndorsements = useCallback(
    () =>
      getStudentEndorsements(studentId, isLazyLoad).then((endorsementsData) => {
        setEndorsements(endorsementsData.endorsements);
        setInterestedEndorsements(endorsementsData.interestedEndorsements);
        if (userState?.isEndorsementsAvailable === null) {
          dispatchUserState({
            type: UserActions.SET_IS_ENDORSEMENTS_AVAILABLE,
            data: endorsementsData.endorsements.length > 1,
          });
        }
      }),
    [getStudentEndorsements, isLazyLoad, studentId, dispatchUserState, userState],
  );
  const { isDataLoaded, loadActivatorRef } = useLazyDataLoad(
    () =>
      new Promise((resolve) => {
        loadEndorsements(resolve);
      }),
    [i18n.language],
  );

  const updateLocalState = useCallback(
    (endorsementId, newSharedDetails) => {
      const endorsementToUpdate = endorsements.find(
        (endorsement) => endorsement.id === endorsementId,
      );
      const filteredEndorsements = endorsements.filter(
        (endorsement) => endorsement.id !== endorsementId,
      );
      setEndorsements([
        ...filteredEndorsements,
        { ...endorsementToUpdate, sharedDetails: newSharedDetails || [] },
      ]);
    },
    [endorsements],
  );

  const updateReflectionHandler = useCallback(
    (questionId, endorsementId, endorsementSharedDetails, isEditing) => {
      postEndorsementReason(questionId, endorsementId, endorsementSharedDetails)
        .then(() => {
          updateLocalState(endorsementId, endorsementSharedDetails);
          dispatchAppState({
            type: AppActions.SET_SNACKBAR_STATUS,
            data: {
              text: t(
                isEditing
                  ? 'Thanks for your changes! Your input has been saved'
                  : 'Thanks for your contribution! Your input has been saved',
              ),
              type: 'success',
            },
          });
        })
        .catch(() => {
          dispatchAppState({
            type: AppActions.SET_SNACKBAR_STATUS,
            data: {
              text: t(
                isEditing
                  ? 'AYO couldn’t save your edits Please try once more'
                  : 'AYO couldn’t save your input Please try once more',
              ),
              type: 'error',
            },
          });
        });
    },
    [postEndorsementReason, dispatchAppState, t, updateLocalState],
  );

  const deleteReflectionHandler = useCallback(
    (questionId, endorsementId) => {
      const previousEndorsementSharedDetails = endorsements.find(
        (endorsement) => endorsement.id === endorsementId,
      ).sharedDetails;
      deleteEndorsementReason(questionId, endorsementId)
        .then(() => {
          updateLocalState(endorsementId);
          dispatchAppState({
            type: AppActions.SET_SNACKBAR_STATUS,
            data: {
              text: t('The reasoning for changed endorsement was deleted'),
              type: 'delete',
              action: (
                <Button
                  autoFocus
                  gaLabel="Undo"
                  onClick={() =>
                    updateReflectionHandler(
                      questionId,
                      endorsementId,
                      previousEndorsementSharedDetails,
                    )
                  }
                >
                  {t('Undo')}
                </Button>
              ),
            },
          });
        })
        .catch(() => {
          dispatchAppState({
            type: AppActions.SET_SNACKBAR_STATUS,
            data: {
              text: t(
                'Failed to delete the reasoning for changed endorsement, please try once more',
              ),
              type: 'error',
              action: (
                <Button
                  autoFocus
                  gaLabel="Retry"
                  onClick={() => deleteReflectionHandler(questionId, endorsementId)}
                >
                  {t('Retry')}
                </Button>
              ),
            },
          });
        });
    },
    [
      deleteEndorsementReason,
      updateReflectionHandler,
      dispatchAppState,
      endorsements,
      t,
      updateLocalState,
    ],
  );

  useEffect(() => {
    if (!isLazyLoad) {
      loadEndorsements();
    }
  }, [i18n.language, isLazyLoad, loadEndorsements]);

  return (
    <Box>
      <Box
        alignItems={isWidthUpSm ? 'center' : 'flex-start'}
        display="flex"
        flexDirection={isWidthUpSm ? 'row' : 'column'}
        justifyContent="space-between"
        mb={isWidthUpSm ? 3 : 1}
      >
        <Box ref={isLazyLoad ? loadActivatorRef : null} display="flex">
          <Typography component={`h${headerLevel}`} variant={titleVariant}>
            {t(title)}
          </Typography>
          <NewFeatureHotspot
            id={
              endorsements?.length
                ? NewFeaturesIdsMap.ENDORSEMENTS_PROFILE_SELECTED
                : NewFeaturesIdsMap.ENDORSEMENTS_PROFILE_INTERESTED
            }
            isClickable
            label={t('Endorsements')}
          />
        </Box>
        {isStudent && endorsements?.length > 1 && isWithNavigationButton && (
          <Button
            className="ayo-endorsements__train-ayo-button"
            component={RouterLink}
            endIcon={<VectorIcon />}
            gaLabel="Track in Train AYO - Endorsements"
            to={trainAyoEndorsementsRoute}
          >
            {t('Track in Train AYO')}
          </Button>
        )}
      </Box>
      {endorsements?.length || interestedEndorsements?.length ? (
        <Box>
          {endorsements?.length ? (
            <SelectedEndorsements
              deleteReflectionHandler={deleteReflectionHandler}
              endorsements={endorsements}
              isReadOnlyMode={isReadOnlyMode}
              isStudent={isStudent}
              setActiveEndorsementConfig={setActiveEndorsementConfig}
              setIsDialogOpen={setIsDialogOpen}
            />
          ) : (
            <InterestedEndorsements endorsements={interestedEndorsements} isStudent={isStudent} />
          )}
        </Box>
      ) : isLazyLoad && !isDataLoaded && !endorsements ? (
        <SkeletonMask />
      ) : (
        <InitiativeEmptyStateBlock
          illustration={<EndorsementsEmptyStateIllustration />}
          title={t('No information about endorsements so far')}
        />
      )}
      <TryMobileInformationalMessage
        className={`${classNames('ayo-endorsements__try-mobile-message', {
          'read-only-mode': isReadOnlyMode,
        })}`}
      />
      <EndorsementDialog
        endorsementConfig={activeEndorsementConfig}
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        updateReflectionHandler={updateReflectionHandler}
      />
    </Box>
  );
};

EndorsementsSection.propTypes = {
  headerLevel: PropTypes.number,
  titleVariant: PropTypes.string,
  title: PropTypes.string,
  isLazyLoad: PropTypes.bool,
  isReadOnlyMode: PropTypes.bool,
  isWithNavigationButton: PropTypes.bool,
};

EndorsementsSection.defaultProps = {
  headerLevel: 2,
  titleVariant: 'h2',
  title: 'My endorsements',
  isLazyLoad: false,
  isReadOnlyMode: false,
  isWithNavigationButton: false,
};

export default EndorsementsSection;
