import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Collapse } from '@mui/material';
import PropTypes from 'prop-types';

import { Switch, Typography } from '../../../../../atoms';
import { SwitchLabels } from '../../../../../../constants/enums';
import { ClubKeys, ClubRequirementsKeys } from '../../../../../../constants/club-hub';
import ClubRequirements from '../../../club-hub-components/club-requirements/ClubRequirements';

const ClubCreateRequirements = ({ error, onChange, requirements }) => {
  const { t } = useTranslation();

  const [clubRequirements, setClubRequirements] = useState(requirements);

  const visibilityChangeHandler = useCallback(
    (isVisible) => {
      if (isVisible) {
        onChange({
          [ClubKeys.REQUIREMENTS]: {
            ...clubRequirements,
            [ClubRequirementsKeys.IS_VISIBLE]: isVisible,
          },
        });
      } else {
        onChange({
          [ClubKeys.REQUIREMENTS]: {
            [ClubRequirementsKeys.DESCRIPTION]: '',
            [ClubRequirementsKeys.IS_VISIBLE]: isVisible,
            [ClubRequirementsKeys.LINKS]: [''],
          },
        });
      }
      setClubRequirements((prevState) => ({
        ...prevState,
        [ClubRequirementsKeys.IS_VISIBLE]: isVisible,
      }));
    },
    [clubRequirements, onChange],
  );

  const requirementsChangeHandler = useCallback(
    (change) => {
      setClubRequirements((prevState) => {
        const newRequirements = { ...prevState, ...change };

        onChange({ [ClubKeys.REQUIREMENTS]: newRequirements });

        return newRequirements;
      });
    },
    [onChange],
  );

  return (
    <Box className="ayo-club-hub-create-page__requirements" p={3}>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Typography component="h3" variant="subtitle1">
          {t('Requirements for joining and staying')}
        </Typography>
        <Switch
          ariaLabel={t('Requirements')}
          checkedLabel={SwitchLabels.on}
          gaLabel="Visibility"
          onChange={(value) => {
            visibilityChangeHandler(value);
          }}
          uncheckedLabel={SwitchLabels.off}
          value={clubRequirements[ClubRequirementsKeys.IS_VISIBLE]}
        />
      </Box>
      <Typography mt={2} variant="body2">
        {t(
          'Defining requirements is optional. If you see the need to add them in a separate card, please switch this section on.',
        )}
      </Typography>
      <Collapse in={clubRequirements[ClubRequirementsKeys.IS_VISIBLE]}>
        <Box mt={3}>
          <ClubRequirements
            description={clubRequirements[ClubRequirementsKeys.DESCRIPTION]}
            error={error}
            label={t('Add requirements for joining and staying')}
            links={clubRequirements[ClubRequirementsKeys.LINKS]}
            onChange={requirementsChangeHandler}
          />
        </Box>
      </Collapse>
    </Box>
  );
};

ClubCreateRequirements.propTypes = {
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  requirements: PropTypes.shape({
    description: PropTypes.string,
    isVisible: PropTypes.bool,
    links: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

ClubCreateRequirements.defaultProps = {
  error: null,
};

export default ClubCreateRequirements;
