import React, { useReducer, createContext } from 'react';
import PropTypes from 'prop-types';

export const QuestionnaireStateContext = createContext();

export const QuestionnaireStateActions = {
  SET_QUESTIONNAIRE_ID: 'SET_QUESTIONNAIRE_ID',
  SET_ACTIVE_QUESTION_INDEX: 'SET_ACTIVE_QUESTION_INDEX',
  SET_ACTIVE_QUESTION_ANSWERS: 'SET_ANSWERS',
  SET_ACTIVE_QUESTION_IDS: 'SET_ACTIVE_QUESTION_IDS',
  SET_ACTIVE_DEPENDENT_PROFILE: 'SET_ACTIVE_DEPENDENT_PROFILE',
  SET_SHOULD_RELOAD_QUESTIONNAIRE_STATE: 'SET_SHOULD_RELOAD_QUESTIONNAIRE_STATE',
};

const initState = {
  activeDependentProfile: null,
  activeQuestionAnswers: {},
  activeQuestionIds: null,
  activeQuestionIndex: null,
  questionnaireId: null,
  shouldReloadQuestionnaireState: null,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case QuestionnaireStateActions.SET_QUESTIONNAIRE_ID:
      return {
        ...state,
        questionnaireId: action.data.questionnaireId,
        activeQuestionIndex: null,
        activeQuestionAnswers: {},
      };
    case QuestionnaireStateActions.SET_ACTIVE_QUESTION_INDEX:
      return {
        ...state,
        activeQuestionIndex: action.data.activeQuestionIndex,
        activeQuestionAnswers: {},
      };
    case QuestionnaireStateActions.SET_ACTIVE_QUESTION_ANSWERS:
      return { ...state, activeQuestionAnswers: action.data.activeQuestionAnswers };
    case QuestionnaireStateActions.SET_ACTIVE_DEPENDENT_PROFILE:
      return { ...state, activeDependentProfile: action.data.profile };
    case QuestionnaireStateActions.SET_SHOULD_RELOAD_QUESTIONNAIRE_STATE:
      return { ...state, shouldReloadQuestionnaireState: action.data };
    case QuestionnaireStateActions.SET_ACTIVE_QUESTION_IDS:
      return { ...state, activeQuestionIds: action.data };
    default:
      throw new Error();
  }
};

export const QuestionnaireStateContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initState);

  return (
    <QuestionnaireStateContext.Provider value={{ state, dispatch }}>
      {children}
    </QuestionnaireStateContext.Provider>
  );
};

QuestionnaireStateContextProvider.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
};
