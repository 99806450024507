import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const SPECIAL_PERIOD_NAMES = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];

const PeriodDisplayName = ({ period }) => {
  const { t } = useTranslation();

  return <span>{SPECIAL_PERIOD_NAMES.includes(period) ? period : t('Period n', { period })}</span>;
};

PeriodDisplayName.propTypes = {
  period: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

PeriodDisplayName.defaultProps = {
  period: null,
};

export default PeriodDisplayName;
