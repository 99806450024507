import { Box, Container, Grid, Tab, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useMemo, useCallback } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import isToday from 'dayjs/plugin/isToday';
import isBetween from 'dayjs/plugin/isBetween';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { TabPanel, Tabs, Typography } from '../../../atoms';
import { CampusLabel, NewFeatureHotspot } from '../../../moleculas';
import { DayView, MobileNotSupported, MonthView, PageWrapper, WeekView } from '../../../organisms';
import { useLessonPlannerData, useSearchParams } from '../../../../hooks';
import { GA, getShortDate, isWeekend, transformSearchToObject } from '../../../../utils';
import { myLessonsRoute } from '../../../../constants/routes';
import { GaActions, GaCategories } from '../../../../constants/enums';
import { NewFeaturesIdsMap } from '../../../../tours/common/NewFeaturesItemsProvider';

dayjs.extend(isToday);
dayjs.extend(isBetween);

const CalendarViews = {
  DAY: 'Day',
  WEEK: 'Week',
  MONTH: 'Month',
};

const PlannerSchedulePage = () => {
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });

  const history = useHistory();

  const search = useSearchParams();

  const { currentDate: plainCurrentDate, currentView } = transformSearchToObject(search);

  const currentDate = useMemo(
    () => plainCurrentDate && dayjs(plainCurrentDate),
    [plainCurrentDate],
  );

  const setCurrentView = useCallback(
    (newView) =>
      history.replace({
        search: new URLSearchParams({
          currentDate: plainCurrentDate,
          currentView: newView,
        }).toString(),
      }),
    [history, plainCurrentDate],
  );

  const setCurrentDate = useCallback(
    (date) => {
      history.replace({
        search: new URLSearchParams({
          currentDate: getShortDate(dayjs(date)),
          currentView,
        }).toString(),
      });
    },
    [history, currentView],
  );

  const {
    params: { scheduleId },
  } = useRouteMatch();

  const { lessonPlannerData, getScheduleAvailablePeriods, pickerDates } = useLessonPlannerData();

  useEffect(() => {
    getScheduleAvailablePeriods();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!currentDate || !currentView) {
      history.replace({
        search: new URLSearchParams({
          currentDate: plainCurrentDate ?? getShortDate(dayjs()),
          currentView: currentView ?? CalendarViews.WEEK,
        }).toString(),
      });
    }
  }, [currentDate, history, currentView, plainCurrentDate]);

  useEffect(() => {
    if (currentDate && isWeekend(currentDate)) {
      const weekIncrement = currentDate.day() === 6 ? 1 : 0;
      setCurrentDate(currentDate.add(weekIncrement, 'week').day(1));
    }
  }, [setCurrentDate, currentDate]);

  const currentPickerDates = useMemo(
    () => pickerDates[scheduleId] ?? {},
    [pickerDates, scheduleId],
  );

  const { t } = useTranslation();

  const onDateSelect = (date) => {
    history.replace({
      search: new URLSearchParams({
        currentDate: getShortDate(date),
        currentView: CalendarViews.DAY,
      }).toString(),
    });
  };

  const onTabChange = useCallback(
    (_, newView) => {
      setCurrentView(newView);
      GA.logInteraction({
        category: GaCategories.BEHAVIOR,
        action: GaActions.TAB_CLICK,
        label: newView,
      });
    },
    [setCurrentView],
  );

  if (!currentDate) return null;

  return isWidthUpSm ? (
    <PageWrapper
      backToLink={myLessonsRoute}
      backToText={t('Back to My planners')}
      className="ayo-schedule-page"
      mainElementClassName="ayo-schedule-page__main"
      noNav
    >
      <Container>
        <Grid className="ayo-schedule-page__header" container>
          <Grid item xs={12}>
            <Box mb={1}>
              <Typography variant="h1">{lessonPlannerData.stateData.scheduleName}</Typography>
            </Box>
            <CampusLabel />
            <Box alignItems="flex-end" display="flex" justifyContent="space-between">
              <Box>
                <Typography variant="body2">
                  {t('This is how your schedule looks like.')}
                </Typography>
                <Typography variant="body2">
                  {t('Add and design your lessons here in a simple and convenient way!')}
                </Typography>
              </Box>
              <Tabs onChange={onTabChange} value={currentView} variant="outlined">
                <Tab disableRipple label={t(CalendarViews.DAY)} value={CalendarViews.DAY} />
                <Tab disableRipple label={t(CalendarViews.WEEK)} value={CalendarViews.WEEK} />
                <Tab
                  disableRipple
                  icon={
                    <NewFeatureHotspot
                      key="student-portfolio-new-feature-tour"
                      id={NewFeaturesIdsMap.LESSON_PLANNER_WEEK_MONTH_VIEW}
                      isClickable
                      label={t('Lesson planner views')}
                    />
                  }
                  iconPosition="end"
                  label={t(CalendarViews.MONTH)}
                  value={CalendarViews.MONTH}
                />
              </Tabs>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Box className="ayo-schedule-page__calendar__wrapper">
        <Container className="ayo-schedule-page__calendar">
          <TabPanel currentTabValue={currentView} value={CalendarViews.DAY}>
            <DayView
              currentDate={currentDate}
              currentPickerDates={currentPickerDates}
              scheduleId={scheduleId}
              setCurrentDate={setCurrentDate}
            />
          </TabPanel>
          <TabPanel currentTabValue={currentView} value={CalendarViews.WEEK}>
            <WeekView
              currentDate={currentDate}
              currentPickerDates={currentPickerDates}
              onDateSelect={onDateSelect}
              scheduleId={scheduleId}
              setCurrentDate={setCurrentDate}
            />
          </TabPanel>
          <TabPanel currentTabValue={currentView} value={CalendarViews.MONTH}>
            <MonthView
              currentDate={currentDate}
              currentPickerDates={currentPickerDates}
              onDateSelect={onDateSelect}
              scheduleId={scheduleId}
              setCurrentDate={setCurrentDate}
            />
          </TabPanel>
        </Container>
      </Box>
    </PageWrapper>
  ) : (
    <MobileNotSupported
      explanation={t(
        'Please open My lessons section with a tablet or desktop version to use this functionality',
      )}
    />
  );
};

export default PlannerSchedulePage;
