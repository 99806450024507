import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Card, Typography } from '../../../../atoms';
import { InitiativeEmptyStateBlock } from '../../../../moleculas';
import { LeadershipAttributesGraph } from '../../..';
import { converterBooleanString, mergeArrayOfObjectsHelper } from '../../../../../utils';
import { PortfolioSectionSettings, PortfolioSectionTypes } from '../../../../../constants/enums';
import { ReactComponent as EmptyLAIllustration } from '../../../../../resources/images/no_la_illustration.svg';
import PortfolioSection from '../../components/portfolio-section/PortfolioSection';
import LEADERSHIP_ATTRIBUTES_GRAPH from '../../../../../constants/leadership-attributes-graph';
import LEADERSHIP_ATTRIBUTES_GALERY from '../../../../../constants/leadership-attributes-galery';

const PortfolioSharedLeadershipAttributesSection = ({ sectionData }) => {
  const { t } = useTranslation();
  const graphData = useMemo(() => {
    const data = sectionData.entities.map((level) => ({
      key: level.key,
      maturityLevels: level.maturityLevels,
    }));
    return mergeArrayOfObjectsHelper(LEADERSHIP_ATTRIBUTES_GRAPH, data, 'key');
  }, [sectionData]);
  const isGraphVisible = useMemo(() => {
    const graphSettings = sectionData.sectionSettings.find(
      ({ name }) => name === PortfolioSectionSettings.DISPLAY_MATURITY_GRAPH.type,
    );
    return converterBooleanString(graphSettings.value ?? graphSettings.defaultValue, true);
  }, [sectionData]);

  return (
    <PortfolioSection
      body={
        <Box>
          {!!sectionData.entities.length && (
            <Box mt={5}>
              <Box mb={3}>
                <Typography component="h3" variant="subtitle2">
                  {t('Leadership skills')}
                </Typography>
              </Box>
              <Grid
                className="ayo-portfolio__leadership-attributes-section__images-block"
                container
                spacing={3}
              >
                {sectionData.entities.map((attribute) => {
                  const Illustration = LEADERSHIP_ATTRIBUTES_GALERY.find(
                    (defaultData) => defaultData.key === attribute.key,
                  ).landingBlockConfig?.imageDesktop;
                  return (
                    attribute && (
                      <Grid key={attribute.key} item md={2} sm={4}>
                        <Card
                          key={attribute.key}
                          className="ayo-portfolio__leadership-attributes-section__card"
                          mainContent={
                            <Box>
                              {Illustration && <Illustration aria-label={attribute.alt} />}
                              <Typography
                                className="ayo-portfolio__leadership-attributes-section__card__title"
                                component="h4"
                                variant="subtitle2"
                              >
                                {t(attribute.key)}
                              </Typography>
                            </Box>
                          }
                        />
                      </Grid>
                    )
                  );
                })}
              </Grid>
            </Box>
          )}
          {isGraphVisible && (
            <Box mt={5}>
              <Typography component="h3" variant="subtitle2">
                {t('Maturity graph')}
              </Typography>
              <Box className="ayo-portfolio__leadership-attributes-section__graph">
                <LeadershipAttributesGraph outterGraphData={graphData} readOnly />
              </Box>
            </Box>
          )}
        </Box>
      }
      className={`${classNames('ayo-full-screen-portfolio-section', {
        'ayo-portfolio__leadership-attributes-section': sectionData.entities.length,
      })}`}
      emptyState={
        !sectionData.entities.length ? (
          <InitiativeEmptyStateBlock
            illustration={<EmptyLAIllustration />}
            title={t('No Leadership Attributes assessed so far')}
          />
        ) : null
      }
      reflection={sectionData.reflection}
      sectionKey={PortfolioSectionTypes.PORTFOLIO__LEADERSHIP_ATTRIBUTES}
      settings={sectionData.sectionSettings.filter(
        ({ name }) => name === PortfolioSectionSettings.DISPLAY_MATURITY_GRAPH.type,
      )}
      title={t('Leadership Attributes')}
    />
  );
};

PortfolioSharedLeadershipAttributesSection.propTypes = {
  sectionData: PropTypes.instanceOf(Object).isRequired,
};

export default PortfolioSharedLeadershipAttributesSection;
