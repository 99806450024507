import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Box } from '@mui/material';

import { PortfolioSectionTypes } from '../../../constants/enums';
import { getOrderedSectionsByVisibility } from '../../../utils';

import {
  PortfolioAcademicRecordSection,
  PortfolioAchievementsSection,
  PortfolioAptitudesSection,
  PortfolioCertificatesSection,
  PortfolioEmploymentsSection,
  PortfolioEssaysSection,
  PortfolioEndorsementsSection,
  PortfolioExtracurricularsSection,
  PortfolioInterestsSection,
  PortfolioLeadershipAttributesSection,
  PortfolioPersonalInformationSection,
  PortfolioRecommendationsSection,
  PortfolioTestScoresSection,
  PortfolioVolunteeringSection,
} from './sections';
import PortfolioLoadingWrapper from './components/portfolio-loading-wrapper/PortfolioLoadingWrapper';

const PortfolioComponents = {
  [PortfolioSectionTypes.PORTFOLIO__ACADEMIC_RECORD]: PortfolioAcademicRecordSection,
  [PortfolioSectionTypes.PORTFOLIO__ACHIEVEMENTS]: PortfolioAchievementsSection,
  [PortfolioSectionTypes.PORTFOLIO__APTITUDES]: PortfolioAptitudesSection,
  [PortfolioSectionTypes.PORTFOLIO__CERTIFICATES]: PortfolioCertificatesSection,
  [PortfolioSectionTypes.PORTFOLIO__EMPLOYMENT]: PortfolioEmploymentsSection,
  [PortfolioSectionTypes.PORTFOLIO__ENDORSEMENTS]: PortfolioEndorsementsSection,
  [PortfolioSectionTypes.PORTFOLIO__ESSAYS]: PortfolioEssaysSection,
  [PortfolioSectionTypes.PORTFOLIO__EXTRACURRICULARS]: PortfolioExtracurricularsSection,
  [PortfolioSectionTypes.PORTFOLIO__INTERESTS]: PortfolioInterestsSection,
  [PortfolioSectionTypes.PORTFOLIO__LEADERSHIP_ATTRIBUTES]: PortfolioLeadershipAttributesSection,
  [PortfolioSectionTypes.PORTFOLIO__PERSONAL_INFO]: PortfolioPersonalInformationSection,
  [PortfolioSectionTypes.PORTFOLIO__RECOMMENDATIONS]: PortfolioRecommendationsSection,
  [PortfolioSectionTypes.PORTFOLIO__TEST_SCORES]: PortfolioTestScoresSection,
  [PortfolioSectionTypes.PORTFOLIO__VOLUNTEERING]: PortfolioVolunteeringSection,
};

const PortfolioSections = ({
  loadSectionDataHandler,
  portfolioData,
  portfolioSettings,
  publishModeView,
  portfolio,
  sectionsToAnimate,
}) => {
  const visiblePortfolioSectionKeys = useMemo(
    () => getOrderedSectionsByVisibility(portfolio, portfolioSettings).visibleKeys,
    [portfolio, portfolioSettings],
  );

  return (
    <Box>
      {visiblePortfolioSectionKeys.map((key, index) => {
        const PortfolioComponent = PortfolioComponents[key];
        return (
          <Box
            key={key}
            className={classNames({
              [`${sectionsToAnimate?.animations?.[0]}`]: index === sectionsToAnimate?.sections?.[0],
              [`${sectionsToAnimate?.animations?.[1]}`]: index === sectionsToAnimate?.sections?.[1],
            })}
          >
            <PortfolioLoadingWrapper
              hasData={portfolioData[key]}
              isLazyLoad={index}
              loadSectionDataHandler={loadSectionDataHandler}
              portfolioSection={
                <Box>
                  <PortfolioComponent
                    config={portfolioData[key]}
                    isLastSection={index === visiblePortfolioSectionKeys.length - 1}
                    publishModeView={publishModeView}
                    sectionIndex={index}
                  />
                </Box>
              }
              sectionKey={PortfolioSectionTypes[key]}
            />
          </Box>
        );
      })}
    </Box>
  );
};

PortfolioSections.propTypes = {
  loadSectionDataHandler: PropTypes.func.isRequired,
  publishModeView: PropTypes.bool.isRequired,
  portfolioData: PropTypes.instanceOf(Object).isRequired,
  portfolioSettings: PropTypes.instanceOf(Object).isRequired,
  portfolio: PropTypes.instanceOf(Object).isRequired,
  sectionsToAnimate: PropTypes.shape({
    sections: PropTypes.arrayOf(PropTypes.string),
    animations: PropTypes.arrayOf(PropTypes.string),
  }),
};

PortfolioSections.defaultProps = {
  sectionsToAnimate: {
    sections: [],
    animations: [],
  },
};

export default PortfolioSections;
