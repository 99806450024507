import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Container, Grid, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery';
import { useRouteMatch } from 'react-router-dom';

import { GoalViewForm } from '../components';
import { PageWrapper } from '../../../../../organisms';
import { UserContext } from '../../../../../../context';
import { RolesMap } from '../../../../../../constants/enums';
import { goalsRoute, trainAyoRoute } from '../../../../../../constants/routes';
import { RolesMainPages } from '../../../../../../constants/pages';
import { useSharedStudentData } from '../../../../../../hooks';

const GoalDetailsPage = () => {
  const { t } = useTranslation();
  const match = useRouteMatch();
  const { state: userState } = useContext(UserContext);
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const isStudent = userState.profile.role === RolesMap.STUDENT;
  const isParent = userState.profile.role === RolesMap.GUARDIAN;
  const { studentId } = match.params;
  const { activeStudent } = useSharedStudentData(!isStudent ? studentId : null);

  return (
    <PageWrapper
      backToLink={
        isParent
          ? `${RolesMainPages[userState.profile.role]}/${studentId}`
          : `${trainAyoRoute}/${studentId}${goalsRoute}`
      }
      backToText={t(
        isParent ? `Back to Username´s profile` : isStudent ? 'Go to my goals' : 'Go to goals',
        {
          username: activeStudent?.firstName,
        },
      )}
      mainElementClassName="ayo-goal-details"
    >
      <Container>
        <Box mb={isWidthUpSm ? 10 : 5}>
          <Grid>
            <GoalViewForm />
          </Grid>
          <Grid />
        </Box>
      </Container>
    </PageWrapper>
  );
};

export default GoalDetailsPage;
