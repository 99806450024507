import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DialogContent, Box, useTheme, useMediaQuery } from '@mui/material';

import { Dialog, DialogTitle, Typography } from '../../atoms';
import { TermsConditionsContent } from '../../moleculas';

const TermsConditionsDialog = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Dialog isTextDialog onClose={onClose} open={isOpen}>
      <DialogTitle disableTypography>
        <Box mb={isWidthUpSm ? 3 : 1.5}>
          <Typography component="h1" paragraph variant="h1">
            {t('Terms & Conditions')}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <TermsConditionsContent fullWidth />
      </DialogContent>
    </Dialog>
  );
};

TermsConditionsDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default TermsConditionsDialog;
