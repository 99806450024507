import React, { useCallback, useRef } from 'react';
import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { SearchField, SearchNoResults } from '../../../../../moleculas';
import { ClubsManagementQueryParams } from '../../../../../../constants/club-hub';
import { loginAllowedFilterRE } from '../../../../../../constants/regexps';

const ClubsSearch = ({ onSearch, searchValue, totalItemsCount }) => {
  const { t } = useTranslation();
  const searchApiRef = useRef();

  const searchHandler = useCallback(
    (value) => {
      onSearch({
        [ClubsManagementQueryParams.NAME]: value?.trim(),
        [ClubsManagementQueryParams.PAGE]: 1,
      });
    },
    [onSearch],
  );

  return (
    <Grid container mt={5}>
      <Grid item md={6} sm={5}>
        <SearchField
          apiRef={searchApiRef}
          defaultValue={searchValue}
          gaLabel="Club Hub search"
          inputRE={loginAllowedFilterRE}
          label={t('Search by club name')}
          onSearch={searchHandler}
        />
        {searchValue && !totalItemsCount && (
          <Box mt={3}>
            <SearchNoResults
              explanation="AYO couldn’t find any clubs with the name searchValue. Please check your spelling and repeat your search."
              onResetSearch={() => searchApiRef.current.clearSearch()}
              resetLinkText="Reset the search and show the list of all clubs"
              searchValue={searchValue}
            />
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

ClubsSearch.propTypes = {
  onSearch: PropTypes.func.isRequired,
  searchValue: PropTypes.string,
  totalItemsCount: PropTypes.number.isRequired,
};

ClubsSearch.defaultProps = {
  searchValue: '',
};

export default ClubsSearch;
