import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Container, Grid, useMediaQuery, useTheme } from '@mui/material';

import { Typography } from '../../../../../atoms';
import { AccordionItem } from '../../../../../moleculas';

const LessonPlannerFaqSectionConfig = [
  {
    headerEl: 'What are the lesson statuses, and why do you need them?',
    bodyEl: 'Lesson statuses can be either a draft or published.',
  },
  {
    headerEl: 'How are your materials stored in AYO?',
    bodyEl: "When you upload a file in AYO, it's only visible to you.",
  },
  {
    headerEl: 'How will students see the lessons you created?',
    bodyEl: 'As a teacher, you have control over what content your students see.',
  },
  {
    headerEl: 'What if your schedule for the next week changed? Or you have an unplanned day off?',
    bodyEl:
      'To edit your classes for a specific day, simply select the "three dots" button located to the right of the date in either the day or the week view.',
  },
  {
    headerEl: 'How to use an Agenda board during the lesson?',
    bodyEl:
      'The Agenda board is visible to your students in AYO, and it helps them know the upcoming topic and structure of the lesson.',
  },
  {
    headerEl: 'What if the scope of your calendar ended? How to add semester(s)?',
    bodyEl:
      'You can add either a current or upcoming semester to the existing Lesson planner and proceed to work.',
  },
  {
    headerEl: 'How does Google Classroom synchronization work?',
    bodyEl:
      "When you create a draft lesson in AYO, only you and the educators you've shared it with can see the assignments.",
  },
  {
    headerEl: 'Will you have access to the lessons created a long time ago?',
    bodyEl:
      'Yes, you can find the lessons by either checking your calendar for a specific day or in the lessons repository for the corresponding class.',
  },
];

const LessonPlannerFaqSection = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Box className="ayo-lesson-planner-landing__faq-section" py={isWidthUpSm ? 20 : 10}>
      <Container>
        <Grid container justifyContent="center">
          <Grid item md={6} px={isWidthUpSm ? 1 : 0} sm={10} xl={7} xs={12}>
            <Typography align="center" component="h2" variant="h1">
              {t('FAQ')}
            </Typography>
            <Box mb={5} mt={3}>
              <Typography align="center" variant="body2">
                {t('Below is a list of questions you may have before and while using this tool.')}
              </Typography>
            </Box>
            {LessonPlannerFaqSectionConfig.map((item, index) => (
              <Box key={item.headerEl} mb={isWidthUpSm ? 2 : 1}>
                <AccordionItem
                  bodyEl={
                    <Typography component="p" variant="body2">
                      {t(item.bodyEl)}
                    </Typography>
                  }
                  gaLabel="FAQ item"
                  headerEl={
                    <Typography component="p" variant="subtitle2">
                      {t(item.headerEl)}
                    </Typography>
                  }
                  isDefaultExpanded={index === 0}
                />
              </Box>
            ))}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default LessonPlannerFaqSection;
