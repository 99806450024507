import React, { useCallback, useContext, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

import { Button, Tooltip, Typography } from '../../../../atoms';
import { AccordionItem, InformationalMessage, MlChip } from '../../../../moleculas';
import { AssignmentTypes, OneTimeActionsMap } from '../../../../../constants/enums';
import {
  assignmentInsightsCategoryType,
  assignmentStudentType,
} from '../../../../../constants/propTypes';
import { AppActions, AppContext, UserContext } from '../../../../../context';
import { useOneTimeAction } from '../../../../../hooks';
import { useLessonsService } from '../../../../../services';
import { ReactComponent as ChevronLeft } from '../../../../../resources/icons/chevron_left.svg';
import AssignmentGroupnamesList from '../assignment-groupnames-list/AssignmentGroupnamesList';
import { getNormalizedLanguageCode } from '../../../../../utils';

import InsightCardsGrid from './components/insight-cards-grid/InsightCardsGrid';
import {
  countInsightCategoryGroupsStudents,
  filterInsightCategoryFromUnassigned,
  UNASSIGNED_GROUP_NAME,
} from './shared';
import InsightAssignmentGroup from './components/insight-assignment-group/InsightAssignmentGroup';

const MAX_GROUPING_STEPS = 2;

const AssignmentInsights = ({
  courseStudents,
  insights,
  courseNumbers,
  period,
  onInsightsAssignmentsAdd,
}) => {
  const { state: userState } = useContext(UserContext);
  const { actionState: insightStatInstructionState, onAction: onInsightStatInstructionAction } =
    useOneTimeAction(OneTimeActionsMap.ASSIGNMENTS_INSIGHTS_NOTIFICATION);

  const { t, i18n } = useTranslation();

  const [selectedCategories, setSelectedCategories] = useState([]);

  const [unassignedGroup, setUnassignedGroup] = useState([]);

  const [nextInsights, setNextInsights] = useState([]);
  const { getLessonAssignmentInsights } = useLessonsService();

  const onCategoryClick = useCallback(
    (category, targetInsights) => {
      const insightsCategory = targetInsights.find((insight) => insight.categoryName === category);

      const categoryUnassignedGroup = insightsCategory.groups.find((group) =>
        group.groupNames.find((groupName) => groupName.value === UNASSIGNED_GROUP_NAME),
      );

      const transformStudentIdsToStudents = (studentIds) =>
        studentIds
          .map((studentId) => courseStudents.find((student) => student.id === studentId))
          .filter(Boolean);

      const transformToSelectedGroup = ({ groupNames, studentIds }) => ({
        groupNames,
        assignmentType: AssignmentTypes.ASSIGNMENT,
        students: transformStudentIdsToStudents(studentIds),
        movedStudents: [],
        id: uuid(),
      });

      const transformToSelectedCategory = ({ categoryName, groups }) => ({
        categoryName,
        groups: groups.map(transformToSelectedGroup),
      });

      const selectedCategory = transformToSelectedCategory(
        filterInsightCategoryFromUnassigned(insightsCategory),
      );

      getLessonAssignmentInsights(
        userState.profile.currentCampus,
        courseNumbers,
        period,
        getNormalizedLanguageCode(i18n.language),
        category,
      ).then((insightsData) => setNextInsights(insightsData));

      setSelectedCategories([...selectedCategories, selectedCategory]);
      setUnassignedGroup(transformToSelectedGroup(categoryUnassignedGroup));
    },
    [
      courseNumbers,
      courseStudents,
      getLessonAssignmentInsights,
      i18n.language,
      period,
      selectedCategories,
      userState.profile?.currentCampus,
    ],
  );

  const hasSelected = Object.keys(selectedCategories).length;

  const stopSelecting = selectedCategories.length === MAX_GROUPING_STEPS || !nextInsights.length;

  const initialScreen = (
    <Box>
      <Box mb={3}>
        <Typography variant="body2">
          {t('Select the category for tailoring assignments:')}
        </Typography>
      </Box>
      {!insightStatInstructionState && (
        <Box mb={4}>
          <Grid container>
            <Grid item xs={6}>
              <InformationalMessage
                onClick={onInsightStatInstructionAction}
                text={t(
                  'The numbers in the cards display how many students can be grouped for assignments if you select a particular category.',
                )}
              />
            </Grid>
          </Grid>
        </Box>
      )}
      <InsightCardsGrid
        insights={insights}
        onCategoryClick={onCategoryClick}
        totalStudentsCount={courseStudents.length}
      />
    </Box>
  );

  const selectedGroups = selectedCategories.flatMap((category) =>
    category.groups.map((group) => ({ ...group, categoryName: category.categoryName })),
  );

  const editSelectedGroup = useCallback(
    (category, groupId, editFn) => {
      const categoryIndex = selectedCategories.findIndex(
        ({ categoryName }) => categoryName === category,
      );
      const newCategory = { ...selectedCategories[categoryIndex] };
      const groupIndex = newCategory.groups.findIndex((group) => group.id === groupId);
      const newGroup = editFn(newCategory.groups[groupIndex]);
      newCategory.groups[groupIndex] = newGroup;
      const newSelectedCategories = [...selectedCategories];
      newSelectedCategories[categoryIndex] = newCategory;
      setSelectedCategories(newSelectedCategories);
    },
    [selectedCategories],
  );

  const { dispatch: dispatchAppState } = useContext(AppContext);

  const showStudentSuccessMoveSnackbar = () =>
    dispatchAppState({
      type: AppActions.SET_SNACKBAR_STATUS,
      data: { text: t('Assignments insights move student success snackbar'), type: 'success' },
    });

  const onStudentsGroupDelete = (category, groupId, studentId) => {
    editSelectedGroup(category, groupId, (oldGroup) => ({
      ...oldGroup,
      movedStudents: oldGroup.movedStudents.filter((student) => student.id !== studentId),
    }));
    setUnassignedGroup({
      ...unassignedGroup,
      students: [
        ...unassignedGroup.students,
        courseStudents.find((student) => student.id === studentId),
      ],
    });
    showStudentSuccessMoveSnackbar();
  };

  const onStudentsGroupChange = (category, groupId, studentId) => {
    editSelectedGroup(category, groupId, (oldGroup) => ({
      ...oldGroup,
      movedStudents: [
        ...oldGroup.movedStudents,
        courseStudents.find((student) => student.id === studentId),
      ],
    }));
    setUnassignedGroup({
      ...unassignedGroup,
      students: unassignedGroup.students.filter((student) => student.id !== studentId),
    });
    showStudentSuccessMoveSnackbar();
  };

  const onAssignmentTypeChange = useCallback(
    (category, groupId, newAssignmentType) => {
      if (category === UNASSIGNED_GROUP_NAME) {
        setUnassignedGroup({ ...unassignedGroup, assignmentType: newAssignmentType });
      } else {
        editSelectedGroup(category, groupId, (oldGroup) => ({
          ...oldGroup,
          assignmentType: newAssignmentType,
        }));
      }
    },
    [editSelectedGroup, unassignedGroup],
  );

  const onCategoriesReset = useCallback(() => {
    setSelectedCategories([]);
    setUnassignedGroup({});
  }, []);

  const { actionState: uncategorisedInstructionState, onAction: onUncategorisedInstructionAction } =
    useOneTimeAction(OneTimeActionsMap.UNCATEGORISED_INSIGHT_INSTRUCTION);

  const selectedGroupsStudentCount = countInsightCategoryGroupsStudents(selectedGroups, 'students');

  const selectedCategoriesScreen = (
    <Box className="insights-content">
      <Box mb={2}>
        <Button
          gaLabel="Back to assignment tailor categories"
          onClick={onCategoriesReset}
          startIcon={<ChevronLeft />}
        >
          {t('Back to categories')}
        </Button>
      </Box>
      <Box display="flex" flexDirection="column" gap={2} mb={5}>
        {selectedGroups.map(
          ({ categoryName, groupNames, students, assignmentType, id, movedStudents }, idx) => (
            <InsightAssignmentGroup
              key={id}
              assignmentType={assignmentType}
              groupNames={groupNames}
              id={id}
              movedStudents={movedStudents}
              onAssignmentTypeChange={onAssignmentTypeChange}
              onStudentsGroupDelete={onStudentsGroupDelete}
              order={idx + 1}
              students={students}
              type={categoryName}
              typeBlockContent={
                <AssignmentGroupnamesList categoryName={categoryName} groupNames={groupNames} />
              }
            />
          ),
        )}
        <InsightAssignmentGroup
          assignmentType={unassignedGroup.assignmentType}
          availableGroups={selectedGroups}
          onAssignmentTypeChange={onAssignmentTypeChange}
          onStudentsGroupChange={stopSelecting ? onStudentsGroupChange : undefined}
          order={selectedGroups.length + 1}
          students={unassignedGroup.students}
          type={UNASSIGNED_GROUP_NAME}
          typeBlockContent={
            stopSelecting ? (
              <Grid container>
                <Grid item xs={6}>
                  {!uncategorisedInstructionState && (
                    <InformationalMessage
                      onClick={onUncategorisedInstructionAction}
                      text={t('Uncategorized insight instruction')}
                    />
                  )}
                </Grid>
              </Grid>
            ) : (
              <Box>
                <Box mb={2}>
                  <Typography variant="body2">
                    {t(
                      'You can select a category for proceeding with tailoring assignments for the remaining students.',
                    )}
                  </Typography>
                </Box>
                <InsightCardsGrid
                  insights={nextInsights}
                  onCategoryClick={onCategoryClick}
                  totalStudentsCount={courseStudents.length - selectedGroupsStudentCount}
                />
              </Box>
            )
          }
        />
      </Box>
      <Box display="flex" gap={2}>
        <Button
          className="cancel-button"
          gaLabel="Cancel tailor assignments"
          onClick={onCategoriesReset}
        >
          {t('Cancel')}
        </Button>
        <Button
          className="tailor-button"
          gaLabel="Tailor assignments"
          onClick={() =>
            onInsightsAssignmentsAdd([
              ...selectedGroups,
              { ...unassignedGroup, categoryName: null, groupNames: null },
            ])
          }
          variant="text"
        >
          {t('Tailor assignments')}
        </Button>
      </Box>
    </Box>
  );

  return (
    <AccordionItem
      bodyEl={hasSelected ? selectedCategoriesScreen : initialScreen}
      className="ayo-insight-accordion"
      gaLabel="Assignments insights"
      headerEl={
        <Box display="flex" justifyContent="space-between" width="100%">
          <Typography variant="subtitle2">
            {t('Wondering how to group students for assignments?')}
          </Typography>
          <Tooltip title={t('AYO insight assignments tooltip')}>
            <MlChip tabIndex={0} text={t('AYO insight')} />
          </Tooltip>
        </Box>
      }
      summaryLabel={t('Wondering how to group students for assignments?')}
    />
  );
};

AssignmentInsights.propTypes = {
  insights: PropTypes.arrayOf(PropTypes.shape(assignmentInsightsCategoryType)).isRequired,
  courseStudents: assignmentStudentType.isRequired,
  onInsightsAssignmentsAdd: PropTypes.func.isRequired,
  courseNumbers: PropTypes.arrayOf(PropTypes.number).isRequired,
  period: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

AssignmentInsights.defaultProps = {
  period: null,
};

export default AssignmentInsights;
