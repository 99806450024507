import React, { useState } from 'react';
import { Box } from '@mui/material';

import { FeedPostKeys } from '../../../../../constants/family-feed';
import SharePostWithDialog from '../share-post-with-dialog/SharePostWithDialog';
import PostForm from '../post-form/PostForm';

const CreatePostForm = () => {
  const [sharedWithData, setSharedWithData] = useState({
    [FeedPostKeys.SELECTED_ENTITIES]: [],
    [FeedPostKeys.SHARING_LEVEL]: null,
    [FeedPostKeys.SHARING_GROUPS]: null,
  });

  const [isShareWithDialogOpen, setIsShareWithDialogOpen] = useState(false);

  return (
    <Box>
      <PostForm
        onShareWithDataChange={(value) => setSharedWithData(value)}
        onVisibilityButtonClick={() => {
          setIsShareWithDialogOpen(true);
        }}
        sharedWithData={sharedWithData}
      />
      <SharePostWithDialog
        isOpen={isShareWithDialogOpen}
        onClose={() => {
          setIsShareWithDialogOpen(false);
        }}
        onSave={(value) => {
          setSharedWithData(value);
          setIsShareWithDialogOpen(false);
        }}
        sharedWithData={sharedWithData}
      />
    </Box>
  );
};

export default CreatePostForm;
