import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const EmojiDisplay = ({ emoji, className }) => {
  const { t } = useTranslation();
  return (
    emoji && (
      <span aria-label={`${t(emoji.ariaLabel)} ${t('emoji')}`} className={className} role="img">
        {emoji.symbol}
      </span>
    )
  );
};

EmojiDisplay.propTypes = {
  className: PropTypes.string,
  emoji: PropTypes.shape({ ariaLabel: PropTypes.string, symbol: PropTypes.string }),
};

EmojiDisplay.defaultProps = {
  className: '',
  emoji: null,
};

export default EmojiDisplay;
