/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { Card, Button } from '../../atoms';
import { UserActions, UserContext } from '../../../context';
import { GaActions, GaCategories, OneTimeActionsMap, RolesMap } from '../../../constants/enums';
import { useOneTimeActionService } from '../../../services';
import { GA, useProfile } from '../../../utils';
import { ReactComponent as FlipIcon } from '../../../resources/icons/flip.svg';

const FlippableCard = ({ className, contentBack, contentFront, disableFlip, ...rest }) => {
  const { t } = useTranslation();
  const { state: userState, dispatch: dispatchUserState } = useContext(UserContext);
  const { getOneTimeActionStatus } = useProfile();
  const { postOneTimeAction } = useOneTimeActionService();

  const [isFlipped, setIsFlipped] = useState(false);
  const handleFlipButtonClick = useCallback(() => {
    if (
      userState.profile.role === RolesMap.STUDENT &&
      !getOneTimeActionStatus(OneTimeActionsMap.EXPLORE_OPPORTUNITY_FLIP_CARD)
    ) {
      postOneTimeAction(OneTimeActionsMap.EXPLORE_OPPORTUNITY_FLIP_CARD);
      dispatchUserState({
        type: UserActions.SET_ONE_TIME_ACTION,
        data: OneTimeActionsMap.EXPLORE_OPPORTUNITY_FLIP_CARD,
      });
    }
    setIsFlipped((state) => {
      GA.logInteraction({
        category: GaCategories.BEHAVIOR,
        action: GaActions.BUTTON_CLICK,
        label: state ? 'Flip back' : 'Flip',
      });
      return !state;
    });
  }, [dispatchUserState, getOneTimeActionStatus, postOneTimeAction, userState.profile.role]);

  useEffect(() => {
    if (disableFlip) {
      setIsFlipped(false);
    }
  }, [disableFlip]);

  return (
    <Box className={classnames('ayo-flippable-card', className)} {...rest}>
      <Box
        className={classnames('ayo-flippable-card__inner', {
          'ayo-flippable-card__inner--flipped': isFlipped,
        })}
      >
        <Card
          actions={
            !disableFlip ? (
              <Button
                aria-label={t('Flip')}
                isIconButton
                onClick={handleFlipButtonClick}
                tabIndex={!isFlipped ? 0 : -1}
              >
                <FlipIcon />
              </Button>
            ) : null
          }
          className="ayo-flippable-card__front"
          mainContent={
            <>
              <Box pb={2}>{contentFront.media(!isFlipped)}</Box>
              <Box px={1}>{contentFront.main(!isFlipped)}</Box>
            </>
          }
        />
        <Card
          actions={
            !disableFlip ? (
              <Button
                aria-label={t('Flip')}
                isIconButton
                onClick={handleFlipButtonClick}
                tabIndex={isFlipped ? 0 : -1}
              >
                <FlipIcon />
              </Button>
            ) : null
          }
          className="ayo-flippable-card__back"
          mainContent={contentBack.main(isFlipped)}
        />
      </Box>
    </Box>
  );
};

FlippableCard.propTypes = {
  className: PropTypes.string,
  contentBack: PropTypes.shape({
    main: PropTypes.func,
  }).isRequired,
  contentFront: PropTypes.shape({
    main: PropTypes.func,
    media: PropTypes.func,
  }).isRequired,
  disableFlip: PropTypes.bool,
};

FlippableCard.defaultProps = {
  className: '',
  disableFlip: false,
};

export default FlippableCard;
