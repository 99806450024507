import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { AppActions, AppContext } from '../../context';
import { FilterActions } from '../../constants/enums';

const useStudentsFiltersSnackbars = (
  filteredStudents,
  filteredStudentsCount,
  students,
  totalStudentsCount,
  filtersUpdateState,
) => {
  const { dispatch: dispatchAppState } = useContext(AppContext);

  const { t } = useTranslation();

  const history = useHistory();
  useEffect(() => {
    if (filteredStudents !== undefined) {
      if (totalStudentsCount === filteredStudentsCount) {
        history.replace({ search: null });
        dispatchAppState({
          type: AppActions.SET_SNACKBAR_STATUS,
          data: { text: t('Reset students filters snackbar'), type: 'error' },
        });
      } else {
        if (!filtersUpdateState) return;
        let snackbarText;
        if (filtersUpdateState.action === FilterActions.ADD) {
          if (filtersUpdateState.value) {
            snackbarText = t('Apply one filter snackbar', { value: filtersUpdateState.value });
          } else {
            snackbarText = t('Apply filters snackbar');
          }
        }
        if (filtersUpdateState.action === FilterActions.CLEAR) {
          if (filtersUpdateState.value) {
            snackbarText = t('Clear one filter snackbar', { value: filtersUpdateState.value });
          } else {
            snackbarText = t('Clear filters snackbar');
          }
        }
        dispatchAppState({
          type: AppActions.SET_SNACKBAR_STATUS,
          data: { text: snackbarText, type: 'success' },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchAppState, filteredStudents, history, students, filtersUpdateState]);
};

export default useStudentsFiltersSnackbars;
