import React, { useCallback, useContext, useMemo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Container, Grid, Box } from '@mui/material';

import { Typography } from '../../atoms';
import { PageWrapper } from '../../organisms';
import { useProfileService } from '../../../services';
import { AppActions, AppContext, UserActions, UserContext } from '../../../context';
import { NotificationNamesMap, NotificationTypesMap } from '../../../constants/enums';
import { converterBooleanString } from '../../../utils';

import SettingsSwitchesBlock from './components/settings-switches-block/SettingsSwitchesBlock';

const SettingsPage = () => {
  const { t } = useTranslation();
  const { state: userState, dispatch: dispatchUserState } = useContext(UserContext);
  const { settings } = userState;
  const { dispatch: dispatchAppState } = useContext(AppContext);
  const { updateUserSetting } = useProfileService();

  const onChangeHandler = useCallback(
    (value, name, settingsType, onAfterChange) => {
      updateUserSetting([{ name, value: converterBooleanString(value) }]).then(() => {
        dispatchUserState({
          type: UserActions.UPDATE_USER_SETTINGS,
          data: {
            parentCategory: settingsType,
            settigsToChange: [{ name, value }],
          },
        });
        if (onAfterChange) {
          onAfterChange();
        }
        dispatchAppState({
          type: AppActions.SET_SNACKBAR_STATUS,
          data: {
            text: (
              <Trans
                components={{ b: <b /> }}
                i18nKey={`notificationSettings.${settingsType}`}
                values={{
                  notification: t(NotificationNamesMap.statuses[name]),
                  value: value ? t('on') : t('off'),
                }}
              />
            ),
            type: 'success',
          },
        });
      });
    },
    [dispatchAppState, dispatchUserState, updateUserSetting, t],
  );

  const uiNotificationSettings = useMemo(
    () =>
      settings[NotificationTypesMap.UI_NOTIFICATIONS].sort((a, b) => {
        const orderedKeys = Object.keys(NotificationNamesMap.settings);

        return orderedKeys.indexOf(a.name) - orderedKeys.indexOf(b.name);
      }),
    [settings],
  );

  const emailNotificationSettings = useMemo(
    () => settings[NotificationTypesMap.EMAIL_NOTIFICATION],
    [settings],
  );

  return (
    <PageWrapper mainElementClassName="ayo-settings-page">
      <Container>
        <Grid container>
          <Grid item sm={5} xs={12}>
            <Typography component="h1" variant="h1">
              {t('Settings')}
            </Typography>
            <Box mb={1} mt={6}>
              <Typography component="h2" variant="subtitle1">
                {t('Notifications')}
              </Typography>
            </Box>
            <Typography variant="body2">
              {t('Control your notifications related to AYO')}
            </Typography>
            <Box mb={5} mt={3}>
              <SettingsSwitchesBlock
                onChangeHandler={onChangeHandler}
                settings={uiNotificationSettings}
                title={t('AYO notifications')}
              />
            </Box>
            <Box mb={5} mt={3}>
              <SettingsSwitchesBlock
                onChangeHandler={onChangeHandler}
                settings={emailNotificationSettings}
                title={t('Email notifications')}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </PageWrapper>
  );
};

export default SettingsPage;
