import React, { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Box, DialogActions, DialogContent } from '@mui/material';

import { Button, Dialog, DialogTitle, Typography } from '../../../../atoms';
import { InformationalCaption, PopupActionsButtons, STTTextField } from '../../../../moleculas';
import { InputsValidationErrors, InputsValidationRules } from '../../../../../constants/enums';
import { ClubsMembershipStatuses, ClubsStatuses } from '../../../../../constants/club-hub';

const DeactivationDialogConfig = {
  [ClubsStatuses.DECLINED]: {
    title: 'Decline',
    subtitle: "Please provide a reason why this club request can't be approved.",
    description: 'You’re about to decline the club’s clubName request.',
    placeholder: 'Add a reason for declining the request here',
    infoCaption:
      'Even if the request is declined, it’ll remain in the moderation table for 1 month before being permanently deleted.',
    confirmButtonTitle: 'Decline request',
  },
  [ClubsStatuses.INACTIVE]: {
    title: 'Make inactive',
    subtitle: 'Please provide a reason why this club should be inactive.',
    description: 'You’re about to make the club clubName inactive.',
    placeholder: 'Add a reason for making the club inactive here',
    infoCaption:
      'Even if the club becomes inactive, it’ll remain in the moderation table permanently.',
    confirmButtonTitle: 'Make inactive',
  },
  [ClubsMembershipStatuses.REQUEST_REJECTED]: {
    title: 'Reject access',
    subtitle: 'Please provide a reason why studentName can’t be a member of clubName.',
    description: 'You’re about to reject membership access for studentName.',
    placeholder: 'Add a reason for rejecting access here',
    confirmButtonTitle: 'Reject access',
  },
};

const ClubsDeactivationDialog = ({ clubName, isOpen, onClose, onSubmit, status, studentName }) => {
  const { t } = useTranslation();

  const [reason, setReason] = useState('');
  const [reasonError, setReasonError] = useState('');

  const reasonChangeHandler = useCallback(
    (e) => {
      setReason(e.target.value);
      if (reasonError) {
        setReasonError('');
      }
    },
    [reasonError],
  );

  const closeHandler = useCallback(() => {
    setReason('');
    setReasonError('');
    onClose();
  }, [onClose]);

  const isValid = useCallback(() => {
    if (reason.trim().length <= InputsValidationRules.MIN_INPUT_LENGTH) {
      setReasonError(
        InputsValidationErrors(t, InputsValidationRules.MIN_INPUT_LENGTH).MIN_ERROR_TEXT,
      );
    }
    return (
      reason.trim().length >= InputsValidationRules.MIN_INPUT_LENGTH &&
      reason.length <= InputsValidationRules.MAX_INPUT_LENGTH
    );
  }, [reason, t]);

  const submitHandler = useCallback(() => {
    if (isValid()) {
      closeHandler();
      onSubmit(reason, status);
    }
  }, [closeHandler, isValid, onSubmit, reason, status]);

  return (
    <Dialog
      className="ayo-club-hub__deactivation-dialog"
      gaLabel="Club hub deactivation dialog"
      onClose={closeHandler}
      open={isOpen}
    >
      <DialogTitle disableTypography>
        <Typography component="h2" variant="h2">
          {`${t(DeactivationDialogConfig[status].title)} - ${studentName || clubName}`}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography my={3} variant="body2">
          <Trans
            components={{ b: <strong /> }}
            i18nKey={DeactivationDialogConfig[status].description}
            values={{ clubName, studentName }}
          />
        </Typography>
        <Typography component="h3" mb={1.5} variant="subtitle2">
          <Trans
            components={{ b: <strong /> }}
            i18nKey={DeactivationDialogConfig[status].subtitle}
            values={{ clubName, studentName }}
          />
        </Typography>
        <STTTextField
          error={reasonError}
          fullWidth
          gaLabel={`${status} - Reason`}
          helperText={reasonError}
          InputLabelProps={{ id: 'reason' }}
          inputProps={{ 'aria-labelledby': 'reason' }}
          label={t(DeactivationDialogConfig[status].placeholder)}
          maxLength={InputsValidationRules.MAX_INPUT_LENGTH}
          multiline
          onChange={reasonChangeHandler}
          outlined
          required
          rowsCount={5}
          value={reason}
        />
        {DeactivationDialogConfig[status].infoCaption && (
          <Box mb={5} mt={2}>
            <InformationalCaption title={DeactivationDialogConfig[status].infoCaption} />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <PopupActionsButtons
          primaryButtonComponent={
            <Button
              fullWidth
              gaLabel={DeactivationDialogConfig[status].confirmButtonTitle}
              onClick={submitHandler}
              variant="danger"
            >
              {t(DeactivationDialogConfig[status].confirmButtonTitle)}
            </Button>
          }
          secondaryButtonGaLabel="Cancel"
          secondaryButtonHandler={closeHandler}
          secondaryButtonText={t('Cancel')}
        />
      </DialogActions>
    </Dialog>
  );
};

ClubsDeactivationDialog.propTypes = {
  clubName: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  studentName: PropTypes.string,
};

ClubsDeactivationDialog.defaultProps = {
  studentName: null,
};
export default ClubsDeactivationDialog;
