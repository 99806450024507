import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { useSearchParams } from '../../../../hooks';
import { transformSearchToObject } from '../../../../utils';
import { AnalyticsStatsKeys, HighlightsInitiatives } from '../../../../constants/enums';
import PieChartComponent from '../components/pie-chart/PieChartComponent';

const ParentsAnswersHighlights = ({ stats, totalStudentsCount, onFiltersUpdate }) => {
  const search = useSearchParams();
  const filters = useMemo(() => transformSearchToObject(search), [search]);

  return (
    <PieChartComponent
      key={filters.filterValue}
      filterCategory={HighlightsInitiatives.PARENTS_ANSWERS}
      gaLabel="Parents answers"
      labelPrefix="parentsAnswers."
      onFiltersUpdate={onFiltersUpdate}
      pieColors={['#9F7DE1', '#F2F3FF']}
      statKey={AnalyticsStatsKeys.PARENTS_ANSWERS}
      stats={stats}
      totalStudentsCount={totalStudentsCount}
    />
  );
};

ParentsAnswersHighlights.propTypes = {
  stats: PropTypes.instanceOf(Object).isRequired,
  totalStudentsCount: PropTypes.number.isRequired,
  onFiltersUpdate: PropTypes.func.isRequired,
};

export default ParentsAnswersHighlights;
