import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Typography } from '../../../../../atoms';
import LessonPageMaterialsContent from '../../../lesson-page/components/lesson-page-materials-content/LessonPageMaterialsContent';

const DisplayAssignmentMaterials = ({ attachments, links }) => {
  const { t } = useTranslation();
  return (
    (!!attachments?.length || !!links?.length) && (
      <Box className="ayo-lesson-assignment-materials">
        <Typography paragraph variant="subtitle2">
          {t('Materials')}
        </Typography>
        <LessonPageMaterialsContent
          allowedExtensions={['jpg', 'jpeg', 'png', 'gif', 'mp3', 'mp4', 'pdf']}
          isStatic
          values={{ attachments, links }}
        />
      </Box>
    )
  );
};

DisplayAssignmentMaterials.propTypes = {
  attachments: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  links: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
};

DisplayAssignmentMaterials.defaultProps = {
  attachments: null,
  links: null,
};

export default DisplayAssignmentMaterials;
