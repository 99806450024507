import React, { useContext, useMemo, useCallback, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';
import { Grid, Box, Card, Container, Toolbar } from '@mui/material';
import { useInView } from 'react-intersection-observer';

import { Radio, FormControlLabel, Typography, Button, Tooltip } from '../../atoms';
import { PageWrapper, DataProcessingFooter } from '../../organisms';
import { AppContext, AppActions } from '../../../context';
import {
  LoginProvidersMap,
  DeviceTypesMap,
  GaCategories,
  GaActions,
} from '../../../constants/enums';
import { useLogin, GA } from '../../../utils';
import { AppRoutes } from '../../../constants/routes';
import { useProfile } from '../../../utils/profile-helpers/profileHelpers';
import { ReactComponent as PublicDeviceIllustration } from '../../../resources/images/public_device_illustration.svg';
import { ReactComponent as PrivateDeivceIllustration } from '../../../resources/images/private_device_illustration.svg';
import { ReactComponent as InfoIcon } from '../../../resources/icons/info.svg';

const LoginPage = () => {
  const { state: appState, dispatch } = useContext(AppContext);
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const isClassLinkLogin = appState.loginProvider === LoginProvidersMap.CLASSLINK;
  const { proceedToLogin } = useLogin();

  const { loadProfileAndProceed } = useProfile();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => loadProfileAndProceed({ skipStorageReset: true, shouldProceed: true }), []);

  const deviceOptions = useMemo(
    () => [
      {
        text: t('I’m using a public device'),
        value: DeviceTypesMap.PUBLIC,
        tooltip: `${t(
          isClassLinkLogin
            ? 'Do not forget to log out of ClassLink after you’ve finished working with AYO'
            : 'To protect your data, do not forget to log out of AYO after you’ve finished',
        )}`,
        illustration: PublicDeviceIllustration,
      },
      {
        text: t('I’m using my own device'),
        value: DeviceTypesMap.PRIVATE,
        illustration: PrivateDeivceIllustration,
      },
    ],
    [isClassLinkLogin, t],
  );

  const switchLanguageCache = useCallback(
    (deviceType) => {
      const { options } = i18n.services.languageDetector;
      if (deviceType === DeviceTypesMap.PUBLIC) {
        options.caches = ['sessionStorage'];
        sessionStorage.setItem(options.lookupSessionStorage, i18n.language);
        localStorage.removeItem(options.lookupLocalStorage);
      } else {
        options.caches = ['localStorage'];
        localStorage.setItem(options.lookupLocalStorage, i18n.language);
        sessionStorage.removeItem(options.lookupSessionStorage);
      }
    },
    [i18n],
  );

  const handleDeviceType = useCallback(
    (e) => {
      switchLanguageCache(e.target.value);
      dispatch({ type: AppActions.SET_DEVICE_TYPE, data: e.target.value });
    },
    [dispatch, switchLanguageCache],
  );

  const loginButtonHandler = useCallback(() => proceedToLogin(), [proceedToLogin]);

  useEffect(() => {
    if (!appState.deviceType) {
      dispatch({ type: AppActions.SET_DEVICE_TYPE, data: DeviceTypesMap.PUBLIC });
    }
    if (!appState.loginProvider) {
      history.push(AppRoutes.HOME);
    }
  }, [history, appState.loginProvider, appState.deviceType, dispatch]);

  const deviceSelectionStep = useMemo(
    () => ({
      title: 'Select your device type (you’re here now)',
      content: (
        <Grid container item xs={12}>
          {deviceOptions.map((option) => (
            <Grid
              key={option.value}
              className="ayo-login-page__device-option-container"
              item
              sm={6}
              xs={12}
            >
              <Card
                className={classnames(
                  'ayo-card ayo-clickable-card ayo-login-page__device-option-card',
                  {
                    selected: appState.deviceType === option.value,
                  },
                )}
                onClick={() => handleDeviceType({ target: { value: option.value } })}
              >
                <FormControlLabel
                  control={
                    <Radio
                      checked={appState.deviceType === option.value}
                      gaLabel={`Device type: ${option.value}`}
                      name="devicetype"
                      onChange={handleDeviceType}
                      value={option.value}
                    />
                  }
                  label={
                    <>
                      <Box display="inline" pr={1} style={{ verticalAlign: 'top' }}>
                        {option.text}
                      </Box>
                      {option.tooltip ? (
                        <Tooltip
                          enterTouchDelay={0}
                          leaveTouchDelay={5000}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                          }}
                          onOpen={() => {
                            GA.logInteraction({
                              category: GaCategories.BEHAVIOR,
                              action: GaActions.ICON_HOVER,
                              label: 'Instruction text',
                            });
                          }}
                          title={option.tooltip}
                        >
                          <Box
                            alignItems="center"
                            component="span"
                            display="inline-flex"
                            style={{ verticalAlign: 'text-bottom' }}
                          >
                            <InfoIcon aria-label={option.tooltip} tabIndex="-1" />
                          </Box>
                        </Tooltip>
                      ) : (
                        <Grid item>
                          <Box width="21px" />
                        </Grid>
                      )}
                    </>
                  }
                />
                <option.illustration className="ayo-login-page__device-option-card__illustration" />
              </Card>
            </Grid>
          ))}
        </Grid>
      ),
    }),
    [appState.deviceType, deviceOptions, handleDeviceType],
  );

  const content = useMemo(
    () => ({
      [LoginProvidersMap.SKYWARD]: {
        steps: [
          deviceSelectionStep,
          {
            title: 'Log in to your Skyward account',
            content: (
              <Box>
                <Typography variant="body2">
                  {t('You’re going to be re-directed to Skyward')}.
                </Typography>
                <Typography variant="body2">
                  <Trans
                    components={{ b: <b /> }}
                    i18nKey="Enter your login and password and select Parent from the drop-down roles list"
                  />
                  .
                </Typography>
              </Box>
            ),
          },
        ],
        proceedLabel: 'Proceed to Skyward login',
      },
      [LoginProvidersMap.CLASSLINK]: {
        steps: [
          deviceSelectionStep,
          {
            title: 'Log in to your ClassLink account',
            content: (
              <Box>
                <Typography variant="body2">
                  {t('You’re going to be re-directed to ClassLink')}.
                </Typography>
              </Box>
            ),
          },
        ],
        proceedLabel: 'Proceed to ClassLink login',
      },
      [LoginProvidersMap.GOOGLE]: {
        steps: [
          deviceSelectionStep,
          {
            title: 'Log in to your Google account',
            content: (
              <Box>
                <Typography variant="body2">
                  {t('You’re going to be re-directed to Google')}.
                </Typography>
              </Box>
            ),
          },
        ],
        proceedLabel: 'Proceed to Google login',
      },
    }),
    [deviceSelectionStep, t],
  );

  const { ref: proceedButtonRef, inView } = useInView({ threshold: 0.25 });

  if (!appState.loginProvider) return null;

  return (
    <PageWrapper className="ayo-login-page" customFooter={<DataProcessingFooter />} noNav>
      <Container>
        <Grid container justifyContent="center">
          <Grid container item md={6} sm={8} xl={8} xs={12}>
            <Grid item xs={12}>
              <Box mb={3}>
                <Typography component="h1" paragraph variant="subtitle1">
                  {t('Here are the things you need to go through to log in to AYO')}
                </Typography>
              </Box>
            </Grid>
            {content[appState.loginProvider].steps.map((step, idx) => (
              <Grid
                key={idx}
                className="ayo-login-step__container"
                container
                direction="column"
                item
                xs={12}
              >
                <Grid item>
                  <Typography component="h2" paragraph variant="subtitle2">
                    {`${idx + 1} ${t(step.title)}`}
                  </Typography>
                </Grid>
                <Grid item>{step.content}</Grid>
              </Grid>
            ))}
            <Grid item xs={12}>
              <Box ref={proceedButtonRef} mb={4}>
                <Button isAdaptive onClick={loginButtonHandler} variant="primary">
                  {t(content[appState.loginProvider].proceedLabel)}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        {!inView && (
          <Toolbar className="ayo-bottom-bar helper" disableGutters>
            <Container>
              <Box py={1.5}>
                <Grid container>
                  <Grid item xs={2} />
                  <Grid item md={5} xs={12}>
                    <Button isAdaptive onClick={loginButtonHandler} variant="primary">
                      {t(content[appState.loginProvider].proceedLabel)}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Container>
          </Toolbar>
        )}
      </Container>
    </PageWrapper>
  );
};

export default LoginPage;
