import { useCallback } from 'react';

import useAxios, { StatusCodeMap } from '../HttpClient';
import {
  apiRoute,
  feedbacksRoute,
  functionalityRoute,
  reportIssueRoute,
} from '../../constants/routes';

const generalFeedbackUrl = `${apiRoute}${feedbacksRoute}`;
const functionalityFeedbackUrl = `${apiRoute}${feedbacksRoute}${functionalityRoute}`;
const reportIssueUrl = `${apiRoute}${reportIssueRoute}`;

const useFeedbackService = () => {
  const { get, post } = useAxios();

  const getFunctionalityFeedback = useCallback(
    async (key) => {
      const { data } = await get({ enabled: true }, functionalityFeedbackUrl, {
        params: { key },
      });
      return data?.entity;
    },
    [get],
  );

  const postGeneralFeedback = useCallback(
    (value, loaderText) =>
      post(
        { enabled: true, loaderText },
        generalFeedbackUrl,
        { text: value },
        { skipDefaultErrorHandlers: [StatusCodeMap.BAD_REQUEST, StatusCodeMap.SERVER_ERROR] },
      ),
    [post],
  );

  const postFunctionalityFeedback = useCallback(
    (feedbackObj, loaderText) =>
      post({ enabled: true, loaderText }, functionalityFeedbackUrl, feedbackObj),
    [post],
  );

  const reportIssue = useCallback(
    (title, description, browserInfo, filesToUpload, loaderText) => {
      const formData = new FormData();
      formData.append('title', title);
      formData.append('description', description);
      formData.append('browserInfo', browserInfo);
      filesToUpload.forEach((file) => formData.append('files', file));
      return post({ enabled: true, loaderText }, reportIssueUrl, formData, {
        'Content-Type': 'multipart/form-data',
        skipDefaultErrorHandlers: [StatusCodeMap.BAD_REQUEST, StatusCodeMap.SERVER_ERROR],
      });
    },
    [post],
  );

  return { getFunctionalityFeedback, postGeneralFeedback, postFunctionalityFeedback, reportIssue };
};

export default useFeedbackService;
