import React from 'react';
import PropTypes from 'prop-types';
import { Grid, useTheme, useMediaQuery } from '@mui/material';

import { Button } from '../../atoms';

const PopupActionsButtons = ({
  className,
  primaryButtonComponent,
  primaryButtonGaLabel,
  primaryButtonHandler,
  primaryButtonText,
  secondaryButtonComponent,
  secondaryButtonGaLabel,
  secondaryButtonHandler,
  secondaryButtonText,
  xsGridBreakpoint,
}) => {
  const theme = useTheme();
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Grid
      className={className}
      container
      direction={isWidthUpSm ? 'row' : 'column-reverse'}
      mt={1}
      spacing={2}
    >
      <Grid item sm={6} xs={xsGridBreakpoint}>
        {secondaryButtonComponent || (
          <Button
            fullWidth
            gaLabel={secondaryButtonGaLabel || secondaryButtonText}
            onClick={secondaryButtonHandler}
          >
            {secondaryButtonText}
          </Button>
        )}
      </Grid>
      <Grid item sm={6} xs={xsGridBreakpoint}>
        {primaryButtonComponent || (
          <Button
            fullWidth
            gaLabel={primaryButtonGaLabel || primaryButtonText}
            onClick={primaryButtonHandler}
            variant="primary"
          >
            {primaryButtonText}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

PopupActionsButtons.propTypes = {
  className: PropTypes.string,
  primaryButtonComponent: PropTypes.node,
  primaryButtonGaLabel: PropTypes.string,
  primaryButtonHandler: PropTypes.func,
  primaryButtonText: PropTypes.string,
  secondaryButtonComponent: PropTypes.node,
  secondaryButtonGaLabel: PropTypes.string,
  secondaryButtonHandler: PropTypes.func,
  secondaryButtonText: PropTypes.string,
  xsGridBreakpoint: PropTypes.number,
};

PopupActionsButtons.defaultProps = {
  className: '',
  primaryButtonComponent: null,
  primaryButtonGaLabel: '',
  primaryButtonHandler: () => {},
  primaryButtonText: '',
  secondaryButtonComponent: null,
  secondaryButtonGaLabel: '',
  secondaryButtonHandler: () => {},
  secondaryButtonText: '',
  xsGridBreakpoint: 12,
};

export default PopupActionsButtons;
