import { ReactComponent as LinkFacebook } from '../resources/icons/link_facebook.svg';
import { ReactComponent as LinkGeneral } from '../resources/icons/link_social_media_general.svg';
import { ReactComponent as LinkInstagram } from '../resources/icons/link_instagram.svg';
import { ReactComponent as LinkTwitter } from '../resources/icons/link_twitter.svg';
import { ReactComponent as LinkYoutube } from '../resources/icons/link_media_youtube.svg';

export const SocialMediaTypes = {
  FACEBOOK: 'FACEBOOK',
  GENERAL: 'GENERAL',
  INSTAGRAM: 'INSTAGRAM',
  TWITTER: 'TWITTER',
  YOUTUBE: 'YOUTUBE',
};
export const SocialMediaIcons = {
  [SocialMediaTypes.FACEBOOK]: LinkFacebook,
  [SocialMediaTypes.GENERAL]: LinkGeneral,
  [SocialMediaTypes.INSTAGRAM]: LinkInstagram,
  [SocialMediaTypes.TWITTER]: LinkTwitter,
  [SocialMediaTypes.YOUTUBE]: LinkYoutube,
};

export const SocialMediaLabels = {
  [SocialMediaTypes.FACEBOOK]: 'Facebook',
  [SocialMediaTypes.INSTAGRAM]: 'Instagram',
  [SocialMediaTypes.TWITTER]: 'Twitter',
  [SocialMediaTypes.YOUTUBE]: 'YouTube',
};

export default { SocialMediaTypes, SocialMediaIcons, SocialMediaLabels };
