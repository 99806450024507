import React, { useContext } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { DotIndicator, Typography } from '../../../../atoms';
import { DisplayAvatarEditorImage, TextWithTooltip } from '../../../../moleculas';
import { UserContext } from '../../../../../context';
import { GA, addKeyboardPressHandler, getFormattedChatMessageDate } from '../../../../../utils';
import { conversationType } from '../../../../../constants/propTypes';
import { GaActions, GaCategories } from '../../../../../constants/enums';

const ConversationItem = ({ conversation, onItemClick }) => {
  const { state: userState } = useContext(UserContext);

  const lastMessageAuthor = conversation.members.find(
    (member) => member.id === conversation.lastMessage.userId,
  );

  const chatWith = conversation.members.filter((member) => member.id !== userState.profile.id)[0];

  const { t, i18n } = useTranslation();

  return (
    <Box
      className="ayo-chat-list__conversation"
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      onClick={() => {
        onItemClick(conversation);
        GA.logInteraction({
          category: GaCategories.BEHAVIOR,
          action: GaActions.BUTTON_CLICK,
          label: 'Open chat',
        });
      }}
      onKeyDown={addKeyboardPressHandler(() => onItemClick(conversation))}
      role="button"
      tabIndex="0"
    >
      <Box mr={2}>
        <DisplayAvatarEditorImage
          alt={t('author avatar image alt', { authorName: chatWith.name })}
          avatar={chatWith?.avatar?.image}
          avatarSize={48}
          avatarTransforms={chatWith?.avatar?.transforms}
        />
      </Box>
      <Box flex="1">
        <Box display="flex" gap={2}>
          <TextWithTooltip title={chatWith.name} titleVariant="subtitle2" />
          {!conversation.lastMessage.isRead && <DotIndicator />}
        </Box>
        <TextWithTooltip
          title={`${lastMessageAuthor.name}: ${conversation.lastMessage.text}`}
          titleVariant="caption"
        />
      </Box>
      <Box ml={2}>
        <Typography variant="caption">
          {getFormattedChatMessageDate(conversation.lastMessage.createdDate, i18n.language)}
        </Typography>
      </Box>
    </Box>
  );
};

ConversationItem.propTypes = {
  conversation: PropTypes.shape(conversationType).isRequired,
  onItemClick: PropTypes.func.isRequired,
};

const ListOfChats = ({ conversations, onItemClick }) => {
  const { t } = useTranslation();
  return (
    <Box p={1}>
      <Typography mb={4} pt={2} px={2} variant="subtitle1">
        {t('My messages')}
      </Typography>
      <Box display="flex" flexDirection="column" gap={2}>
        {conversations?.map((conversation) => (
          <ConversationItem
            key={conversation.id}
            conversation={conversation}
            onItemClick={onItemClick}
          />
        ))}
      </Box>
    </Box>
  );
};

ListOfChats.propTypes = {
  conversations: PropTypes.arrayOf(PropTypes.shape(conversationType)),
  onItemClick: PropTypes.func.isRequired,
};

ListOfChats.defaultProps = {
  conversations: null,
};

export default ListOfChats;
