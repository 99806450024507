import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';

import { PageWrapper, RoleQuestion } from '../../organisms';
import { useOneTimeActionService, useProfileService } from '../../../services';
import { UserActions, UserContext } from '../../../context';
import { OneTimeActionsMap } from '../../../constants/enums';
import { RolesMainPages } from '../../../constants/pages';
import { useProfile } from '../../../utils';

const RoleQuestionPage = () => {
  const { patchRole } = useProfileService();
  const { postOneTimeAction } = useOneTimeActionService();
  const { state: userState, dispatch: dispatchUserState } = useContext(UserContext);

  const { getOneTimeActionStatus } = useProfile();

  const {
    profile: { role: userRole },
  } = userState;

  const isRoleChangeNeeded = !getOneTimeActionStatus(OneTimeActionsMap.TEACHER_PAGE_ROLE);

  return isRoleChangeNeeded ? (
    <PageWrapper noNav>
      <RoleQuestion
        onProceed={(role) => {
          patchRole(role).then(() => {
            postOneTimeAction(OneTimeActionsMap.TEACHER_PAGE_ROLE).then(() => {
              dispatchUserState({ type: UserActions.SET_USER_PROFILE_ROLE, data: role });
              dispatchUserState({
                type: UserActions.SET_ONE_TIME_ACTION,
                data: OneTimeActionsMap.TEACHER_PAGE_ROLE,
              });
            });
          });
        }}
      />
    </PageWrapper>
  ) : (
    <Redirect to={RolesMainPages[userRole]} />
  );
};

export default RoleQuestionPage;
